// pythonStyleData.js
const pythonStyleRows = [
  {
    "uniprot": "A0A077YYX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYX3"
  },
  {
    "uniprot": "A0A077ZLV5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLV5"
  },
  {
    "uniprot": "A0A077Z612",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z612"
  },
  {
    "uniprot": "A0A077ZLG4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLG4"
  },
  {
    "uniprot": "A0A077ZA54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA54"
  },
  {
    "uniprot": "A0A077Z6Z4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Z4"
  },
  {
    "uniprot": "A0A077Z099",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z099"
  },
  {
    "uniprot": "A0A077ZEL1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEL1"
  },
  {
    "uniprot": "A0A077ZDG1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDG1"
  },
  {
    "uniprot": "A0A077ZJB1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJB1"
  },
  {
    "uniprot": "A0A077ZLB3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLB3"
  },
  {
    "uniprot": "A0A077Z1E2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1E2"
  },
  {
    "uniprot": "A0A077YYV0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYV0"
  },
  {
    "uniprot": "A0A077Z2C6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2C6"
  },
  {
    "uniprot": "A0A077ZD60",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD60"
  },
  {
    "uniprot": "A0A077Z791",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z791"
  },
  {
    "uniprot": "A0A077Z258",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z258"
  },
  {
    "uniprot": "A0A077Z320",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z320"
  },
  {
    "uniprot": "A0A077ZFN0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFN0"
  },
  {
    "uniprot": "A0A077Z2F1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2F1"
  },
  {
    "uniprot": "A0A077ZR59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR59"
  },
  {
    "uniprot": "A0A077YZ92",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ92"
  },
  {
    "uniprot": "A0A077YYA6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYA6"
  },
  {
    "uniprot": "A0A077Z635",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z635"
  },
  {
    "uniprot": "A0A077ZCG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCG3"
  },
  {
    "uniprot": "A0A077Z8V5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8V5"
  },
  {
    "uniprot": "A0A077ZC49",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC49"
  },
  {
    "uniprot": "A0A077ZL91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL91"
  },
  {
    "uniprot": "A0A077ZCF6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCF6"
  },
  {
    "uniprot": "A0A077Z461",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z461"
  },
  {
    "uniprot": "A0A077Z8C0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8C0"
  },
  {
    "uniprot": "A0A077ZIF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIF9"
  },
  {
    "uniprot": "A0A077YYF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYF0"
  },
  {
    "uniprot": "A0A077YW54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW54"
  },
  {
    "uniprot": "A0A077ZEH7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEH7"
  },
  {
    "uniprot": "A0A077Z763",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z763"
  },
  {
    "uniprot": "A0A077YVR2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVR2"
  },
  {
    "uniprot": "A0A077ZFD9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFD9"
  },
  {
    "uniprot": "A0A077YWR2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWR2"
  },
  {
    "uniprot": "A0A077ZH32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH32"
  },
  {
    "uniprot": "A0A077ZDB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDB2"
  },
  {
    "uniprot": "A0A077ZB70",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB70"
  },
  {
    "uniprot": "A0A077Z3G2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3G2"
  },
  {
    "uniprot": "A0A077Z0G2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0G2"
  },
  {
    "uniprot": "A0A077ZCF5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCF5"
  },
  {
    "uniprot": "A0A077ZKB1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKB1"
  },
  {
    "uniprot": "A0A077ZA51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA51"
  },
  {
    "uniprot": "A0A077ZAL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAL6"
  },
  {
    "uniprot": "A0A077Z4V6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4V6"
  },
  {
    "uniprot": "A0A077ZI61",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI61"
  },
  {
    "uniprot": "A0A077YWP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWP0"
  },
  {
    "uniprot": "A0A077YYD0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYD0"
  },
  {
    "uniprot": "A0A077Z2R0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2R0"
  },
  {
    "uniprot": "A0A077Z651",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z651"
  },
  {
    "uniprot": "A0A077Z4R1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4R1"
  },
  {
    "uniprot": "A0A077Z6P1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6P1"
  },
  {
    "uniprot": "A0A077Z4T9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4T9"
  },
  {
    "uniprot": "A0A077Z893",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z893"
  },
  {
    "uniprot": "A0A077ZEP6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEP6"
  },
  {
    "uniprot": "A0A077Z225",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z225"
  },
  {
    "uniprot": "A0A077ZJ55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ55"
  },
  {
    "uniprot": "A0A077Z5Z3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Z3"
  },
  {
    "uniprot": "A0A077ZCL8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCL8"
  },
  {
    "uniprot": "A0A077Z656",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z656"
  },
  {
    "uniprot": "A0A077Z8K7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8K7"
  },
  {
    "uniprot": "A0A077Z407",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z407"
  },
  {
    "uniprot": "A0A077YZU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZU7"
  },
  {
    "uniprot": "A0A077ZHT5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHT5"
  },
  {
    "uniprot": "A0A077Z093",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z093"
  },
  {
    "uniprot": "A0A077ZD11",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD11"
  },
  {
    "uniprot": "A0A077ZAL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAL7"
  },
  {
    "uniprot": "A0A077ZAL4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAL4"
  },
  {
    "uniprot": "A0A077ZMA4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMA4"
  },
  {
    "uniprot": "A0A077Z4B5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4B5"
  },
  {
    "uniprot": "A0A077ZFT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFT9"
  },
  {
    "uniprot": "A0A077Z349",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z349"
  },
  {
    "uniprot": "A0A077ZA18",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA18"
  },
  {
    "uniprot": "A0A077ZKM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKM7"
  },
  {
    "uniprot": "A0A077Z5F7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5F7"
  },
  {
    "uniprot": "A0A077YZP6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZP6"
  },
  {
    "uniprot": "A0A077YYW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYW5"
  },
  {
    "uniprot": "A0A077YWB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWB9"
  },
  {
    "uniprot": "A0A077ZDJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDJ5"
  },
  {
    "uniprot": "A0A077ZJL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJL6"
  },
  {
    "uniprot": "A0A077YXV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXV6"
  },
  {
    "uniprot": "A0A077YVU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVU2"
  },
  {
    "uniprot": "A0A077Z767",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z767"
  },
  {
    "uniprot": "A0A077YVH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVH8"
  },
  {
    "uniprot": "A0A077ZDT1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDT1"
  },
  {
    "uniprot": "A0A077YWU0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWU0"
  },
  {
    "uniprot": "A0A077Z110",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z110"
  },
  {
    "uniprot": "A0A077YYS2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYS2"
  },
  {
    "uniprot": "A0A077ZKE0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKE0"
  },
  {
    "uniprot": "A0A077ZIF6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIF6"
  },
  {
    "uniprot": "A0A077Z519",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z519"
  },
  {
    "uniprot": "A0A077ZKU3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKU3"
  },
  {
    "uniprot": "A0A077ZHL4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHL4"
  },
  {
    "uniprot": "A0A077ZLK1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLK1"
  },
  {
    "uniprot": "A0A077ZIT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIT8"
  },
  {
    "uniprot": "A0A077ZDR7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDR7"
  },
  {
    "uniprot": "A0A077ZLP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLP3"
  },
  {
    "uniprot": "A0A077ZL79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL79"
  },
  {
    "uniprot": "A0A077ZEZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEZ9"
  },
  {
    "uniprot": "A0A077Z4T2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4T2"
  },
  {
    "uniprot": "A0A077Z9M3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9M3"
  },
  {
    "uniprot": "A0A077ZLV2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLV2"
  },
  {
    "uniprot": "A0A077YX74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX74"
  },
  {
    "uniprot": "A0A077YW53",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW53"
  },
  {
    "uniprot": "A0A077Z360",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z360"
  },
  {
    "uniprot": "A0A077ZFY2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFY2"
  },
  {
    "uniprot": "A0A077ZIZ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIZ2"
  },
  {
    "uniprot": "A0A077Z7W7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7W7"
  },
  {
    "uniprot": "A0A077Z4X7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4X7"
  },
  {
    "uniprot": "A0A077ZKY2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKY2"
  },
  {
    "uniprot": "A0A077ZHL5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHL5"
  },
  {
    "uniprot": "A0A077Z133",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z133"
  },
  {
    "uniprot": "A0A077ZBJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBJ2"
  },
  {
    "uniprot": "A0A077ZMX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMX6"
  },
  {
    "uniprot": "A0A077Z9Y7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Y7"
  },
  {
    "uniprot": "A0A077ZK27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK27"
  },
  {
    "uniprot": "A0A077YWN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWN4"
  },
  {
    "uniprot": "A0A077Z7Y6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Y6"
  },
  {
    "uniprot": "A0A077ZJ39",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ39"
  },
  {
    "uniprot": "A0A077ZQR5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQR5"
  },
  {
    "uniprot": "A0A077Z5R2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5R2"
  },
  {
    "uniprot": "A0A077ZEK8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEK8"
  },
  {
    "uniprot": "A0A077ZLE1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLE1"
  },
  {
    "uniprot": "A0A077Z6M1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6M1"
  },
  {
    "uniprot": "A0A077ZCU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCU7"
  },
  {
    "uniprot": "A0A077ZNJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNJ2"
  },
  {
    "uniprot": "A0A077Z3H7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3H7"
  },
  {
    "uniprot": "A0A077Z732",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z732"
  },
  {
    "uniprot": "A0A077ZLT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLT8"
  },
  {
    "uniprot": "A0A077ZEY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEY0"
  },
  {
    "uniprot": "A0A077Z0C0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0C0"
  },
  {
    "uniprot": "A0A077Z7N3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7N3"
  },
  {
    "uniprot": "A0A077YZ30",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ30"
  },
  {
    "uniprot": "A0A077YYU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYU2"
  },
  {
    "uniprot": "A0A077ZC47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC47"
  },
  {
    "uniprot": "A0A077YZT5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZT5"
  },
  {
    "uniprot": "A0A077ZND5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZND5"
  },
  {
    "uniprot": "A0A077ZH67",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH67"
  },
  {
    "uniprot": "A0A077Z6U5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6U5"
  },
  {
    "uniprot": "A0A077ZBF1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBF1"
  },
  {
    "uniprot": "A0A077Z0M0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0M0"
  },
  {
    "uniprot": "A0A077ZE86",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE86"
  },
  {
    "uniprot": "A0A077Z141",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z141"
  },
  {
    "uniprot": "A0A077Z708",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z708"
  },
  {
    "uniprot": "A0A077Z6J1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6J1"
  },
  {
    "uniprot": "A0A077ZLK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLK6"
  },
  {
    "uniprot": "A0A077ZG62",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG62"
  },
  {
    "uniprot": "A0A077ZML6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZML6"
  },
  {
    "uniprot": "A0A077ZDI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDI3"
  },
  {
    "uniprot": "A0A077ZJ59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ59"
  },
  {
    "uniprot": "A0A077Z1A3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1A3"
  },
  {
    "uniprot": "A0A077YWD5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWD5"
  },
  {
    "uniprot": "A0A077YZH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZH1"
  },
  {
    "uniprot": "A0A077Z344",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z344"
  },
  {
    "uniprot": "A0A077Z5B8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5B8"
  },
  {
    "uniprot": "A0A077Z5U0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5U0"
  },
  {
    "uniprot": "A0A077Z1S2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1S2"
  },
  {
    "uniprot": "A0A077Z8P9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8P9"
  },
  {
    "uniprot": "A0A077ZIL1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIL1"
  },
  {
    "uniprot": "A0A077Z8H2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8H2"
  },
  {
    "uniprot": "A0A077Z3G9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3G9"
  },
  {
    "uniprot": "A0A077ZD66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD66"
  },
  {
    "uniprot": "A0A077ZA56",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA56"
  },
  {
    "uniprot": "A0A077YZW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZW1"
  },
  {
    "uniprot": "A0A077ZJF3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJF3"
  },
  {
    "uniprot": "A0A077ZHA2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHA2"
  },
  {
    "uniprot": "A0A077YYS6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYS6"
  },
  {
    "uniprot": "A0A077Z8V4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8V4"
  },
  {
    "uniprot": "A0A077ZL28",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL28"
  },
  {
    "uniprot": "A0A077ZL90",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL90"
  },
  {
    "uniprot": "A0A077ZAN5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAN5"
  },
  {
    "uniprot": "A0A077ZA44",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA44"
  },
  {
    "uniprot": "A0A077ZDC1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDC1"
  },
  {
    "uniprot": "A0A077ZQP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQP2"
  },
  {
    "uniprot": "A0A077ZC88",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC88"
  },
  {
    "uniprot": "A0A077ZEW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEW5"
  },
  {
    "uniprot": "A0A077Z606",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z606"
  },
  {
    "uniprot": "A0A077Z4Y6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Y6"
  },
  {
    "uniprot": "A0A077ZQJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQJ3"
  },
  {
    "uniprot": "A0A077Z4P6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4P6"
  },
  {
    "uniprot": "A0A077Z9U9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9U9"
  },
  {
    "uniprot": "A0A077YW58",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW58"
  },
  {
    "uniprot": "A0A077ZJD1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJD1"
  },
  {
    "uniprot": "A0A077ZFI1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFI1"
  },
  {
    "uniprot": "A0A077Z3W9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3W9"
  },
  {
    "uniprot": "A0A077YZZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZZ9"
  },
  {
    "uniprot": "A0A077ZK37",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK37"
  },
  {
    "uniprot": "A0A077ZIN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIN9"
  },
  {
    "uniprot": "A0A077ZB38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB38"
  },
  {
    "uniprot": "A0A077ZQ22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ22"
  },
  {
    "uniprot": "A0A077Z8B6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8B6"
  },
  {
    "uniprot": "A0A077ZEI0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEI0"
  },
  {
    "uniprot": "A0A077ZFA0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFA0"
  },
  {
    "uniprot": "A0A077ZFT5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFT5"
  },
  {
    "uniprot": "A0A077YV96",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV96"
  },
  {
    "uniprot": "A0A077Z8G1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8G1"
  },
  {
    "uniprot": "A0A077YXH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXH1"
  },
  {
    "uniprot": "A0A077YYY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYY4"
  },
  {
    "uniprot": "A0A077ZE72",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE72"
  },
  {
    "uniprot": "A0A077Z3D8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3D8"
  },
  {
    "uniprot": "A0A077ZFD7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFD7"
  },
  {
    "uniprot": "A0A077Z283",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z283"
  },
  {
    "uniprot": "A0A077YWE4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWE4"
  },
  {
    "uniprot": "A0A077ZQB5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQB5"
  },
  {
    "uniprot": "A0A077YYH7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYH7"
  },
  {
    "uniprot": "A0A077Z1R8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1R8"
  },
  {
    "uniprot": "A0A077ZML5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZML5"
  },
  {
    "uniprot": "A0A077Z699",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z699"
  },
  {
    "uniprot": "A0A077Z7C0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7C0"
  },
  {
    "uniprot": "A0A077ZBR3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBR3"
  },
  {
    "uniprot": "A0A077ZL89",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL89"
  },
  {
    "uniprot": "A0A077ZAN8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAN8"
  },
  {
    "uniprot": "A0A077ZLX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLX9"
  },
  {
    "uniprot": "A0A077ZL08",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL08"
  },
  {
    "uniprot": "A0A077ZIU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIU5"
  },
  {
    "uniprot": "A0A077Z1Q2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Q2"
  },
  {
    "uniprot": "A0A077YXG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXG8"
  },
  {
    "uniprot": "A0A077ZBH2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBH2"
  },
  {
    "uniprot": "A0A077Z4L8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4L8"
  },
  {
    "uniprot": "A0A077Z0L3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0L3"
  },
  {
    "uniprot": "A0A077Z4D3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4D3"
  },
  {
    "uniprot": "A0A077ZC74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC74"
  },
  {
    "uniprot": "A0A077ZDB1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDB1"
  },
  {
    "uniprot": "A0A077ZBR8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBR8"
  },
  {
    "uniprot": "A0A077Z6S2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6S2"
  },
  {
    "uniprot": "A0A077ZGR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGR6"
  },
  {
    "uniprot": "A0A077Z7S9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7S9"
  },
  {
    "uniprot": "A0A077Z8V3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8V3"
  },
  {
    "uniprot": "A0A077ZIU3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIU3"
  },
  {
    "uniprot": "A0A077Z124",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z124"
  },
  {
    "uniprot": "A0A077ZPP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPP0"
  },
  {
    "uniprot": "A0A077Z2J6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2J6"
  },
  {
    "uniprot": "A0A077ZIP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIP0"
  },
  {
    "uniprot": "A0A077ZFU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFU6"
  },
  {
    "uniprot": "A0A077Z4M0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4M0"
  },
  {
    "uniprot": "A0A077Z4Q9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Q9"
  },
  {
    "uniprot": "A0A077ZH04",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH04"
  },
  {
    "uniprot": "A0A077Z830",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z830"
  },
  {
    "uniprot": "A0A077ZH88",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH88"
  },
  {
    "uniprot": "A0A077ZQ69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ69"
  },
  {
    "uniprot": "A0A077Z8A1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8A1"
  },
  {
    "uniprot": "A0A077ZHA7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHA7"
  },
  {
    "uniprot": "A0A077ZEY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEY6"
  },
  {
    "uniprot": "A0A077ZNR4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNR4"
  },
  {
    "uniprot": "A0A077ZFC7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFC7"
  },
  {
    "uniprot": "A0A077ZFQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFQ4"
  },
  {
    "uniprot": "A0A077ZG71",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG71"
  },
  {
    "uniprot": "A0A077Z479",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z479"
  },
  {
    "uniprot": "A0A077ZEB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEB2"
  },
  {
    "uniprot": "A0A077ZK97",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK97"
  },
  {
    "uniprot": "A0A077YVW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVW8"
  },
  {
    "uniprot": "A0A077ZAG6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAG6"
  },
  {
    "uniprot": "A0A077Z0A8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0A8"
  },
  {
    "uniprot": "A0A077ZI14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI14"
  },
  {
    "uniprot": "A0A077ZCP6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCP6"
  },
  {
    "uniprot": "A0A077ZG40",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG40"
  },
  {
    "uniprot": "A0A077ZC21",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC21"
  },
  {
    "uniprot": "A0A077Z8L1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8L1"
  },
  {
    "uniprot": "A0A077Z6N0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6N0"
  },
  {
    "uniprot": "A0A077YYC6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYC6"
  },
  {
    "uniprot": "A0A077Z4L1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4L1"
  },
  {
    "uniprot": "A0A077ZMM0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMM0"
  },
  {
    "uniprot": "A0A077ZB37",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB37"
  },
  {
    "uniprot": "A0A077Z4F4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4F4"
  },
  {
    "uniprot": "A0A077YZH9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZH9"
  },
  {
    "uniprot": "A0A077Z888",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z888"
  },
  {
    "uniprot": "A0A077YWN8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWN8"
  },
  {
    "uniprot": "A0A077ZFX1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFX1"
  },
  {
    "uniprot": "A0A077Z623",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z623"
  },
  {
    "uniprot": "A0A077Z881",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z881"
  },
  {
    "uniprot": "A0A077ZGB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGB2"
  },
  {
    "uniprot": "A0A077Z347",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z347"
  },
  {
    "uniprot": "A0A077ZR09",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR09"
  },
  {
    "uniprot": "A0A077Z5V9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5V9"
  },
  {
    "uniprot": "A0A077ZLM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLM7"
  },
  {
    "uniprot": "A0A077ZPB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPB6"
  },
  {
    "uniprot": "A0A077Z7F6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7F6"
  },
  {
    "uniprot": "A0A077Z1H6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1H6"
  },
  {
    "uniprot": "A0A077YZT7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZT7"
  },
  {
    "uniprot": "A0A077ZPI6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPI6"
  },
  {
    "uniprot": "A0A077YYV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYV9"
  },
  {
    "uniprot": "A0A077ZHH6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHH6"
  },
  {
    "uniprot": "A0A077Z3A5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3A5"
  },
  {
    "uniprot": "A0A077Z8M5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8M5"
  },
  {
    "uniprot": "A0A077Z4K0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4K0"
  },
  {
    "uniprot": "A0A077Z3S7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3S7"
  },
  {
    "uniprot": "A0A077ZG98",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG98"
  },
  {
    "uniprot": "A0A077ZCH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCH3"
  },
  {
    "uniprot": "A0A077Z5E5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5E5"
  },
  {
    "uniprot": "A0A077ZLI7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLI7"
  },
  {
    "uniprot": "A0A077Z530",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z530"
  },
  {
    "uniprot": "A0A077Z111",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z111"
  },
  {
    "uniprot": "A0A077ZJN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJN9"
  },
  {
    "uniprot": "A0A077ZKM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKM1"
  },
  {
    "uniprot": "A0A077Z750",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z750"
  },
  {
    "uniprot": "A0A077ZNM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNM6"
  },
  {
    "uniprot": "A0A077Z954",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z954"
  },
  {
    "uniprot": "A0A077ZKP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKP4"
  },
  {
    "uniprot": "A0A077Z8B4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8B4"
  },
  {
    "uniprot": "A0A077Z7C1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7C1"
  },
  {
    "uniprot": "A0A077ZBG2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBG2"
  },
  {
    "uniprot": "A0A077Z7P3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7P3"
  },
  {
    "uniprot": "A0A077ZN62",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN62"
  },
  {
    "uniprot": "A0A077Z1U7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1U7"
  },
  {
    "uniprot": "A0A077Z703",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z703"
  },
  {
    "uniprot": "A0A077ZFE9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFE9"
  },
  {
    "uniprot": "A0A077ZL86",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL86"
  },
  {
    "uniprot": "A0A077ZGE0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGE0"
  },
  {
    "uniprot": "A0A077ZAP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAP9"
  },
  {
    "uniprot": "A0A077ZK19",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK19"
  },
  {
    "uniprot": "A0A077ZER2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZER2"
  },
  {
    "uniprot": "A0A077ZF15",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF15"
  },
  {
    "uniprot": "A0A077YZH7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZH7"
  },
  {
    "uniprot": "A0A077Z4J1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4J1"
  },
  {
    "uniprot": "A0A077YXC8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXC8"
  },
  {
    "uniprot": "A0A077ZEF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEF4"
  },
  {
    "uniprot": "A0A077ZI93",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI93"
  },
  {
    "uniprot": "A0A077Z2T1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2T1"
  },
  {
    "uniprot": "A0A077ZKP6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKP6"
  },
  {
    "uniprot": "A0A077ZB77",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB77"
  },
  {
    "uniprot": "A0A077Z8I6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8I6"
  },
  {
    "uniprot": "A0A077ZHJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHJ3"
  },
  {
    "uniprot": "A0A077Z2P1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2P1"
  },
  {
    "uniprot": "A0A077Z4D6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4D6"
  },
  {
    "uniprot": "A0A077ZF70",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF70"
  },
  {
    "uniprot": "A0A077ZE11",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE11"
  },
  {
    "uniprot": "A0A077Z6G7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6G7"
  },
  {
    "uniprot": "A0A077ZC38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC38"
  },
  {
    "uniprot": "A0A077Z2Z5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Z5"
  },
  {
    "uniprot": "A0A077ZDE7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDE7"
  },
  {
    "uniprot": "A0A077Z123",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z123"
  },
  {
    "uniprot": "A0A077ZL12",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL12"
  },
  {
    "uniprot": "A0A077ZNN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNN6"
  },
  {
    "uniprot": "A0A077Z2U7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2U7"
  },
  {
    "uniprot": "A0A077ZDB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDB9"
  },
  {
    "uniprot": "A0A077ZQF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQF9"
  },
  {
    "uniprot": "A0A077Z042",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z042"
  },
  {
    "uniprot": "A0A077ZEV7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEV7"
  },
  {
    "uniprot": "A0A077ZLK5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLK5"
  },
  {
    "uniprot": "A0A077Z7W6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7W6"
  },
  {
    "uniprot": "A0A077ZFV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFV6"
  },
  {
    "uniprot": "A0A077Z0J9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0J9"
  },
  {
    "uniprot": "A0A077ZHF8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHF8"
  },
  {
    "uniprot": "A0A077ZJM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJM7"
  },
  {
    "uniprot": "A0A077ZE57",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE57"
  },
  {
    "uniprot": "A0A077ZA57",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA57"
  },
  {
    "uniprot": "A0A077YVH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVH1"
  },
  {
    "uniprot": "A0A077YZZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZZ4"
  },
  {
    "uniprot": "A0A077Z0V2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0V2"
  },
  {
    "uniprot": "A0A077ZIS3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIS3"
  },
  {
    "uniprot": "A0A077YXU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXU5"
  },
  {
    "uniprot": "A0A077ZGB4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGB4"
  },
  {
    "uniprot": "A0A077Z8H7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8H7"
  },
  {
    "uniprot": "A0A077YYA7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYA7"
  },
  {
    "uniprot": "A0A077ZHE9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHE9"
  },
  {
    "uniprot": "A0A077Z741",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z741"
  },
  {
    "uniprot": "A0A077Z7K0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7K0"
  },
  {
    "uniprot": "A0A077YYR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYR6"
  },
  {
    "uniprot": "A0A077ZI54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI54"
  },
  {
    "uniprot": "A0A077ZDI1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDI1"
  },
  {
    "uniprot": "A0A077Z9R9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9R9"
  },
  {
    "uniprot": "A0A077ZA24",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA24"
  },
  {
    "uniprot": "A0A077YWU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWU2"
  },
  {
    "uniprot": "A0A077ZH92",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH92"
  },
  {
    "uniprot": "A0A077ZHQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHQ2"
  },
  {
    "uniprot": "A0A077ZC98",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC98"
  },
  {
    "uniprot": "A0A077ZAJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAJ0"
  },
  {
    "uniprot": "A0A077ZDH4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDH4"
  },
  {
    "uniprot": "A0A077Z8Y3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Y3"
  },
  {
    "uniprot": "A0A077ZDP7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDP7"
  },
  {
    "uniprot": "A0A077Z4F6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4F6"
  },
  {
    "uniprot": "A0A077Z5N0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5N0"
  },
  {
    "uniprot": "A0A077Z8F7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8F7"
  },
  {
    "uniprot": "A0A077Z769",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z769"
  },
  {
    "uniprot": "A0A077ZH48",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH48"
  },
  {
    "uniprot": "A0A077ZNK2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNK2"
  },
  {
    "uniprot": "A0A077ZLY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLY8"
  },
  {
    "uniprot": "A0A077ZA32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA32"
  },
  {
    "uniprot": "A0A077ZKP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKP0"
  },
  {
    "uniprot": "A0A077Z808",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z808"
  },
  {
    "uniprot": "A0A077ZCI1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCI1"
  },
  {
    "uniprot": "A0A077ZE55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE55"
  },
  {
    "uniprot": "A0A077ZFR5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFR5"
  },
  {
    "uniprot": "A0A077Z9N5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9N5"
  },
  {
    "uniprot": "A0A077Z291",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z291"
  },
  {
    "uniprot": "A0A077YYV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYV3"
  },
  {
    "uniprot": "A0A077ZJU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJU7"
  },
  {
    "uniprot": "A0A077Z754",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z754"
  },
  {
    "uniprot": "A0A077Z5Z7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Z7"
  },
  {
    "uniprot": "A0A077Z511",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z511"
  },
  {
    "uniprot": "A0A077ZGH4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGH4"
  },
  {
    "uniprot": "A0A077YWE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWE2"
  },
  {
    "uniprot": "A0A077ZQ52",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ52"
  },
  {
    "uniprot": "A0A077Z284",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z284"
  },
  {
    "uniprot": "A0A077ZH34",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH34"
  },
  {
    "uniprot": "A0A077Z424",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z424"
  },
  {
    "uniprot": "A0A077Z4I8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4I8"
  },
  {
    "uniprot": "A0A077ZED9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZED9"
  },
  {
    "uniprot": "A0A077ZA37",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA37"
  },
  {
    "uniprot": "A0A077ZBN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBN1"
  },
  {
    "uniprot": "A0A077ZA58",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA58"
  },
  {
    "uniprot": "A0A077Z3I4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3I4"
  },
  {
    "uniprot": "A0A077Z5B1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5B1"
  },
  {
    "uniprot": "A0A077ZGJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGJ0"
  },
  {
    "uniprot": "A0A077Z945",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z945"
  },
  {
    "uniprot": "A0A077ZCA0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCA0"
  },
  {
    "uniprot": "A0A077ZI74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI74"
  },
  {
    "uniprot": "A0A077YZT0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZT0"
  },
  {
    "uniprot": "A0A077ZM63",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM63"
  },
  {
    "uniprot": "A0A077ZMF7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMF7"
  },
  {
    "uniprot": "A0A077ZEY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEY8"
  },
  {
    "uniprot": "A0A077YY61",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY61"
  },
  {
    "uniprot": "A0A077Z8L9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8L9"
  },
  {
    "uniprot": "A0A077ZJG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJG7"
  },
  {
    "uniprot": "A0A077YZ06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ06"
  },
  {
    "uniprot": "A0A077ZH64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH64"
  },
  {
    "uniprot": "A0A077ZL78",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL78"
  },
  {
    "uniprot": "A0A077Z9M0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9M0"
  },
  {
    "uniprot": "A0A077ZIR4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIR4"
  },
  {
    "uniprot": "A0A077ZCL3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCL3"
  },
  {
    "uniprot": "A0A077Z1K0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1K0"
  },
  {
    "uniprot": "A0A077YXT5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXT5"
  },
  {
    "uniprot": "A0A077Z1G2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1G2"
  },
  {
    "uniprot": "A0A077ZIJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIJ8"
  },
  {
    "uniprot": "A0A077ZFE0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFE0"
  },
  {
    "uniprot": "A0A077ZM48",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM48"
  },
  {
    "uniprot": "A0A077Z6W3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6W3"
  },
  {
    "uniprot": "A0A077Z7I4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7I4"
  },
  {
    "uniprot": "A0A077ZK03",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK03"
  },
  {
    "uniprot": "A0A077ZE93",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE93"
  },
  {
    "uniprot": "A0A077Z9N9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9N9"
  },
  {
    "uniprot": "A0A077Z0Y4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Y4"
  },
  {
    "uniprot": "A0A077ZE64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE64"
  },
  {
    "uniprot": "A0A077YXY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXY6"
  },
  {
    "uniprot": "A0A077YWC9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWC9"
  },
  {
    "uniprot": "A0A077YZI1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZI1"
  },
  {
    "uniprot": "A0A077Z927",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z927"
  },
  {
    "uniprot": "A0A077ZMS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMS1"
  },
  {
    "uniprot": "A0A077Z3C6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3C6"
  },
  {
    "uniprot": "A0A077ZBX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBX9"
  },
  {
    "uniprot": "A0A077ZJU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJU6"
  },
  {
    "uniprot": "A0A077Z3D4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3D4"
  },
  {
    "uniprot": "A0A077Z1C5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1C5"
  },
  {
    "uniprot": "A0A077YVZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVZ8"
  },
  {
    "uniprot": "A0A077YWB1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWB1"
  },
  {
    "uniprot": "A0A077Z8G4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8G4"
  },
  {
    "uniprot": "A0A077ZEJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEJ5"
  },
  {
    "uniprot": "A0A077YX96",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX96"
  },
  {
    "uniprot": "A0A077ZIM3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIM3"
  },
  {
    "uniprot": "A0A077Z993",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z993"
  },
  {
    "uniprot": "A0A077Z4D2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4D2"
  },
  {
    "uniprot": "A0A077Z3N3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3N3"
  },
  {
    "uniprot": "A0A077YVP1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVP1"
  },
  {
    "uniprot": "A0A077ZET5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZET5"
  },
  {
    "uniprot": "A0A077Z222",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z222"
  },
  {
    "uniprot": "A0A077Z3U4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3U4"
  },
  {
    "uniprot": "A0A077YYF5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYF5"
  },
  {
    "uniprot": "A0A077ZEC9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEC9"
  },
  {
    "uniprot": "A0A077YXF7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXF7"
  },
  {
    "uniprot": "A0A077Z7A9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7A9"
  },
  {
    "uniprot": "A0A077ZHY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHY3"
  },
  {
    "uniprot": "A0A077ZCC8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCC8"
  },
  {
    "uniprot": "A0A077ZJ97",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ97"
  },
  {
    "uniprot": "A0A077YWQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWQ8"
  },
  {
    "uniprot": "A0A077ZCW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCW8"
  },
  {
    "uniprot": "A0A077ZLN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLN9"
  },
  {
    "uniprot": "A0A077ZFV5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFV5"
  },
  {
    "uniprot": "A0A077YV93",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV93"
  },
  {
    "uniprot": "A0A077Z627",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z627"
  },
  {
    "uniprot": "A0A077ZL66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL66"
  },
  {
    "uniprot": "A0A077YZC8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZC8"
  },
  {
    "uniprot": "A0A077ZN34",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN34"
  },
  {
    "uniprot": "A0A077Z8T5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8T5"
  },
  {
    "uniprot": "A0A077Z709",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z709"
  },
  {
    "uniprot": "A0A077Z303",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z303"
  },
  {
    "uniprot": "A0A077Z3T6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3T6"
  },
  {
    "uniprot": "A0A077Z3F7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3F7"
  },
  {
    "uniprot": "A0A077ZM44",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM44"
  },
  {
    "uniprot": "A0A077Z717",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z717"
  },
  {
    "uniprot": "A0A077ZN25",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN25"
  },
  {
    "uniprot": "A0A077Z8S5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8S5"
  },
  {
    "uniprot": "A0A077ZJ83",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ83"
  },
  {
    "uniprot": "A0A077YXP7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXP7"
  },
  {
    "uniprot": "A0A077ZFG4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFG4"
  },
  {
    "uniprot": "A0A077ZJU8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJU8"
  },
  {
    "uniprot": "A0A077ZKW4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKW4"
  },
  {
    "uniprot": "A0A077ZGQ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGQ1"
  },
  {
    "uniprot": "A0A077ZLG2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLG2"
  },
  {
    "uniprot": "A0A077Z0L4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0L4"
  },
  {
    "uniprot": "A0A077YZE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZE2"
  },
  {
    "uniprot": "A0A077YXZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXZ8"
  },
  {
    "uniprot": "A0A077ZCG2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCG2"
  },
  {
    "uniprot": "A0A077Z6L2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6L2"
  },
  {
    "uniprot": "A0A077ZAV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAV6"
  },
  {
    "uniprot": "A0A077ZCL2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCL2"
  },
  {
    "uniprot": "A0A077ZAR8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAR8"
  },
  {
    "uniprot": "A0A077ZH07",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH07"
  },
  {
    "uniprot": "A0A077ZGW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGW5"
  },
  {
    "uniprot": "A0A077ZLB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLB2"
  },
  {
    "uniprot": "A0A077ZEQ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEQ9"
  },
  {
    "uniprot": "A0A077ZLY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLY1"
  },
  {
    "uniprot": "A0A077Z352",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z352"
  },
  {
    "uniprot": "A0A077Z483",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z483"
  },
  {
    "uniprot": "A0A077ZHY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHY9"
  },
  {
    "uniprot": "A0A077Z190",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z190"
  },
  {
    "uniprot": "A0A077ZKM9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKM9"
  },
  {
    "uniprot": "A0A077Z504",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z504"
  },
  {
    "uniprot": "A0A077ZL54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL54"
  },
  {
    "uniprot": "A0A077ZJZ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJZ7"
  },
  {
    "uniprot": "A0A077ZAD9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAD9"
  },
  {
    "uniprot": "A0A077Z4P0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4P0"
  },
  {
    "uniprot": "A0A077ZNQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNQ4"
  },
  {
    "uniprot": "A0A077YWX4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWX4"
  },
  {
    "uniprot": "A0A077Z143",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z143"
  },
  {
    "uniprot": "A0A077Z0H5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0H5"
  },
  {
    "uniprot": "A0A077ZK28",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK28"
  },
  {
    "uniprot": "A0A077ZBW4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBW4"
  },
  {
    "uniprot": "A0A077ZLP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLP2"
  },
  {
    "uniprot": "A0A077Z643",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z643"
  },
  {
    "uniprot": "A0A077Z6C0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6C0"
  },
  {
    "uniprot": "A0A077ZHY2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHY2"
  },
  {
    "uniprot": "A0A077ZH87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH87"
  },
  {
    "uniprot": "A0A077ZA29",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA29"
  },
  {
    "uniprot": "A0A077ZMH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMH5"
  },
  {
    "uniprot": "A0A077ZFB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFB6"
  },
  {
    "uniprot": "A0A077YZ78",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ78"
  },
  {
    "uniprot": "A0A077Z321",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z321"
  },
  {
    "uniprot": "A0A077YVJ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVJ6"
  },
  {
    "uniprot": "A0A077ZM30",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM30"
  },
  {
    "uniprot": "A0A077ZB29",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB29"
  },
  {
    "uniprot": "A0A077Z979",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z979"
  },
  {
    "uniprot": "A0A077Z1L6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1L6"
  },
  {
    "uniprot": "A0A077ZHP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHP0"
  },
  {
    "uniprot": "A0A077ZBM4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBM4"
  },
  {
    "uniprot": "A0A077Z3W8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3W8"
  },
  {
    "uniprot": "A0A077Z1Z4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Z4"
  },
  {
    "uniprot": "A0A077YYT3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYT3"
  },
  {
    "uniprot": "A0A077Z5J6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5J6"
  },
  {
    "uniprot": "A0A077Z2I7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2I7"
  },
  {
    "uniprot": "A0A077ZB65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB65"
  },
  {
    "uniprot": "A0A077ZGQ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGQ7"
  },
  {
    "uniprot": "A0A077ZL45",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL45"
  },
  {
    "uniprot": "A0A077Z033",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z033"
  },
  {
    "uniprot": "A0A077ZDJ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDJ1"
  },
  {
    "uniprot": "A0A077Z5K1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5K1"
  },
  {
    "uniprot": "A0A077ZFU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFU2"
  },
  {
    "uniprot": "A0A077ZAJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAJ7"
  },
  {
    "uniprot": "A0A077ZKU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKU1"
  },
  {
    "uniprot": "A0A077YYK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYK6"
  },
  {
    "uniprot": "A0A077Z8N3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8N3"
  },
  {
    "uniprot": "A0A077YY12",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY12"
  },
  {
    "uniprot": "A0A077ZIV5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIV5"
  },
  {
    "uniprot": "A0A077ZAA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAA8"
  },
  {
    "uniprot": "A0A077YVT6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVT6"
  },
  {
    "uniprot": "A0A077ZPN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPN1"
  },
  {
    "uniprot": "A0A077ZGN5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGN5"
  },
  {
    "uniprot": "A0A077YWV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWV3"
  },
  {
    "uniprot": "A0A077Z550",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z550"
  },
  {
    "uniprot": "A0A077Z109",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z109"
  },
  {
    "uniprot": "A0A077Z8B2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8B2"
  },
  {
    "uniprot": "A0A077ZJM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJM8"
  },
  {
    "uniprot": "A0A077ZF78",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF78"
  },
  {
    "uniprot": "A0A077Z300",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z300"
  },
  {
    "uniprot": "A0A077ZGM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGM2"
  },
  {
    "uniprot": "A0A077ZIU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIU6"
  },
  {
    "uniprot": "A0A077ZIU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIU7"
  },
  {
    "uniprot": "A0A077ZH26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH26"
  },
  {
    "uniprot": "A0A077Z9S1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9S1"
  },
  {
    "uniprot": "A0A077ZB42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB42"
  },
  {
    "uniprot": "A0A077ZIF1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIF1"
  },
  {
    "uniprot": "A0A077ZLJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLJ2"
  },
  {
    "uniprot": "A0A077ZA68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA68"
  },
  {
    "uniprot": "A0A077YYD1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYD1"
  },
  {
    "uniprot": "A0A077ZHN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHN7"
  },
  {
    "uniprot": "A0A077ZA55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA55"
  },
  {
    "uniprot": "A0A077ZIT1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIT1"
  },
  {
    "uniprot": "A0A077Z0A3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0A3"
  },
  {
    "uniprot": "A0A077ZMY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMY6"
  },
  {
    "uniprot": "A0A077Z6Z7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Z7"
  },
  {
    "uniprot": "A0A077ZKA2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKA2"
  },
  {
    "uniprot": "A0A077ZIH6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIH6"
  },
  {
    "uniprot": "A0A077ZM88",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM88"
  },
  {
    "uniprot": "A0A077Z078",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z078"
  },
  {
    "uniprot": "A0A077ZCK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCK7"
  },
  {
    "uniprot": "A0A077Z0Z7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Z7"
  },
  {
    "uniprot": "A0A077Z2M1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2M1"
  },
  {
    "uniprot": "A0A077ZFG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFG5"
  },
  {
    "uniprot": "A0A077YVV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVV3"
  },
  {
    "uniprot": "A0A077Z2K4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2K4"
  },
  {
    "uniprot": "A0A077Z7D3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7D3"
  },
  {
    "uniprot": "A0A077Z1W6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1W6"
  },
  {
    "uniprot": "A0A077Z552",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z552"
  },
  {
    "uniprot": "A0A077ZDA9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDA9"
  },
  {
    "uniprot": "A0A077Z0A6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0A6"
  },
  {
    "uniprot": "A0A077YXH4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXH4"
  },
  {
    "uniprot": "A0A077Z996",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z996"
  },
  {
    "uniprot": "A0A077ZE22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE22"
  },
  {
    "uniprot": "A0A077Z5E6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5E6"
  },
  {
    "uniprot": "A0A077ZJ14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ14"
  },
  {
    "uniprot": "A0A077ZGC1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGC1"
  },
  {
    "uniprot": "A0A077ZHW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHW7"
  },
  {
    "uniprot": "A0A077ZLS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLS1"
  },
  {
    "uniprot": "A0A077ZCE1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCE1"
  },
  {
    "uniprot": "A0A077ZF12",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF12"
  },
  {
    "uniprot": "A0A077ZAC1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAC1"
  },
  {
    "uniprot": "A0A077ZGM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGM6"
  },
  {
    "uniprot": "A0A077ZP32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP32"
  },
  {
    "uniprot": "A0A077ZGH0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGH0"
  },
  {
    "uniprot": "A0A077ZB25",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB25"
  },
  {
    "uniprot": "A0A077ZNI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNI3"
  },
  {
    "uniprot": "A0A077ZKI5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKI5"
  },
  {
    "uniprot": "A0A077Z5V0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5V0"
  },
  {
    "uniprot": "A0A077Z6D9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6D9"
  },
  {
    "uniprot": "A0A077ZKW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKW9"
  },
  {
    "uniprot": "A0A077ZLL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLL6"
  },
  {
    "uniprot": "A0A077Z068",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z068"
  },
  {
    "uniprot": "A0A077ZCU8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCU8"
  },
  {
    "uniprot": "A0A077ZBD9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBD9"
  },
  {
    "uniprot": "A0A077ZNF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNF4"
  },
  {
    "uniprot": "A0A077ZIK1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIK1"
  },
  {
    "uniprot": "A0A077ZB19",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB19"
  },
  {
    "uniprot": "A0A077Z318",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z318"
  },
  {
    "uniprot": "A0A077YZ19",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ19"
  },
  {
    "uniprot": "A0A077ZE83",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE83"
  },
  {
    "uniprot": "A0A077ZBW3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBW3"
  },
  {
    "uniprot": "A0A077Z1F3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1F3"
  },
  {
    "uniprot": "A0A077ZCX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCX0"
  },
  {
    "uniprot": "A0A077ZJ44",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ44"
  },
  {
    "uniprot": "A0A077ZKG2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKG2"
  },
  {
    "uniprot": "A0A077Z6I6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6I6"
  },
  {
    "uniprot": "A0A077Z3D9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3D9"
  },
  {
    "uniprot": "A0A077ZF39",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF39"
  },
  {
    "uniprot": "A0A077ZLL3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLL3"
  },
  {
    "uniprot": "A0A077ZL04",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL04"
  },
  {
    "uniprot": "A0A077ZP66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP66"
  },
  {
    "uniprot": "A0A077ZAV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAV4"
  },
  {
    "uniprot": "A0A077ZKK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKK9"
  },
  {
    "uniprot": "A0A077ZIY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIY4"
  },
  {
    "uniprot": "A0A077ZJG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJG8"
  },
  {
    "uniprot": "A0A077ZFU8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFU8"
  },
  {
    "uniprot": "A0A077ZI09",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI09"
  },
  {
    "uniprot": "A0A077ZAV0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAV0"
  },
  {
    "uniprot": "A0A077ZMX2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMX2"
  },
  {
    "uniprot": "A0A077Z441",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z441"
  },
  {
    "uniprot": "A0A077ZD51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD51"
  },
  {
    "uniprot": "A0A077ZM38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM38"
  },
  {
    "uniprot": "A0A077YZH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZH5"
  },
  {
    "uniprot": "A0A077Z379",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z379"
  },
  {
    "uniprot": "A0A077Z0M9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0M9"
  },
  {
    "uniprot": "A0A077ZLZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLZ3"
  },
  {
    "uniprot": "A0A077Z3S5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3S5"
  },
  {
    "uniprot": "A0A077ZDE5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDE5"
  },
  {
    "uniprot": "A0A077Z269",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z269"
  },
  {
    "uniprot": "A0A077YY47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY47"
  },
  {
    "uniprot": "A0A077ZCS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCS1"
  },
  {
    "uniprot": "A0A077Z1K9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1K9"
  },
  {
    "uniprot": "A0A077ZI62",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI62"
  },
  {
    "uniprot": "A0A077YV30",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV30"
  },
  {
    "uniprot": "A0A077Z8K4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8K4"
  },
  {
    "uniprot": "A0A077Z857",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z857"
  },
  {
    "uniprot": "A0A077Z3X9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3X9"
  },
  {
    "uniprot": "A0A077ZB21",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB21"
  },
  {
    "uniprot": "A0A077ZEV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEV3"
  },
  {
    "uniprot": "A0A077Z546",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z546"
  },
  {
    "uniprot": "A0A077ZG58",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG58"
  },
  {
    "uniprot": "A0A077ZIQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIQ8"
  },
  {
    "uniprot": "A0A077ZGE3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGE3"
  },
  {
    "uniprot": "A0A077Z564",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z564"
  },
  {
    "uniprot": "A0A077ZKY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKY8"
  },
  {
    "uniprot": "A0A077ZA85",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA85"
  },
  {
    "uniprot": "A0A077ZBD1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBD1"
  },
  {
    "uniprot": "A0A077ZKV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKV1"
  },
  {
    "uniprot": "A0A077ZKC4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKC4"
  },
  {
    "uniprot": "A0A077ZF07",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF07"
  },
  {
    "uniprot": "A0A077YXG0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXG0"
  },
  {
    "uniprot": "A0A077ZI87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI87"
  },
  {
    "uniprot": "A0A077ZGD2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGD2"
  },
  {
    "uniprot": "A0A077Z5T0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5T0"
  },
  {
    "uniprot": "A0A077ZC56",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC56"
  },
  {
    "uniprot": "A0A077YVS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVS1"
  },
  {
    "uniprot": "A0A077ZCP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCP4"
  },
  {
    "uniprot": "A0A077YYF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYF9"
  },
  {
    "uniprot": "A0A077ZM26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM26"
  },
  {
    "uniprot": "A0A077ZA00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA00"
  },
  {
    "uniprot": "A0A077ZHL1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHL1"
  },
  {
    "uniprot": "A0A077ZAJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAJ3"
  },
  {
    "uniprot": "A0A077ZGJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGJ7"
  },
  {
    "uniprot": "A0A077Z642",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z642"
  },
  {
    "uniprot": "A0A077Z8A3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8A3"
  },
  {
    "uniprot": "A0A077ZJN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJN3"
  },
  {
    "uniprot": "A0A077Z9J4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9J4"
  },
  {
    "uniprot": "A0A077YY54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY54"
  },
  {
    "uniprot": "A0A077ZJ40",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ40"
  },
  {
    "uniprot": "A0A077YZI4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZI4"
  },
  {
    "uniprot": "A0A077ZK57",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK57"
  },
  {
    "uniprot": "A0A077ZII3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZII3"
  },
  {
    "uniprot": "A0A077YXQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXQ3"
  },
  {
    "uniprot": "A0A077ZPR3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPR3"
  },
  {
    "uniprot": "A0A077ZI78",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI78"
  },
  {
    "uniprot": "A0A077Z5X1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5X1"
  },
  {
    "uniprot": "A0A077ZHG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHG7"
  },
  {
    "uniprot": "A0A077ZIR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIR6"
  },
  {
    "uniprot": "A0A077ZDS7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDS7"
  },
  {
    "uniprot": "A0A077ZCX2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCX2"
  },
  {
    "uniprot": "A0A077Z9R1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9R1"
  },
  {
    "uniprot": "A0A077ZC05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC05"
  },
  {
    "uniprot": "A0A077ZKC5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKC5"
  },
  {
    "uniprot": "A0A077ZBZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBZ5"
  },
  {
    "uniprot": "A0A077ZDW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDW9"
  },
  {
    "uniprot": "A0A077ZEI5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEI5"
  },
  {
    "uniprot": "A0A077YWW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWW9"
  },
  {
    "uniprot": "A0A077ZL46",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL46"
  },
  {
    "uniprot": "A0A077Z3P5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3P5"
  },
  {
    "uniprot": "A0A077Z7B3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7B3"
  },
  {
    "uniprot": "A0A077ZB31",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB31"
  },
  {
    "uniprot": "A0A077Z289",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z289"
  },
  {
    "uniprot": "A0A077Z2H1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2H1"
  },
  {
    "uniprot": "A0A077ZMI0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMI0"
  },
  {
    "uniprot": "A0A077ZEW6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEW6"
  },
  {
    "uniprot": "A0A077ZCL9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCL9"
  },
  {
    "uniprot": "A0A077ZAI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAI3"
  },
  {
    "uniprot": "A0A077ZID3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZID3"
  },
  {
    "uniprot": "A0A077ZPY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPY3"
  },
  {
    "uniprot": "A0A077Z4U4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4U4"
  },
  {
    "uniprot": "A0A077Z4A5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4A5"
  },
  {
    "uniprot": "A0A077ZAM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAM7"
  },
  {
    "uniprot": "A0A077ZJI1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJI1"
  },
  {
    "uniprot": "A0A077YZW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZW0"
  },
  {
    "uniprot": "A0A077ZE05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE05"
  },
  {
    "uniprot": "A0A077ZFZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFZ4"
  },
  {
    "uniprot": "A0A077Z7G8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7G8"
  },
  {
    "uniprot": "A0A077ZLH9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLH9"
  },
  {
    "uniprot": "A0A077ZCM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCM2"
  },
  {
    "uniprot": "A0A077Z9B2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9B2"
  },
  {
    "uniprot": "A0A077ZED4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZED4"
  },
  {
    "uniprot": "A0A077Z4T4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4T4"
  },
  {
    "uniprot": "A0A077ZNB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNB0"
  },
  {
    "uniprot": "A0A077ZM18",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM18"
  },
  {
    "uniprot": "A0A077Z6V0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6V0"
  },
  {
    "uniprot": "A0A077ZLZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLZ5"
  },
  {
    "uniprot": "A0A077Z5H9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5H9"
  },
  {
    "uniprot": "A0A077ZAZ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAZ6"
  },
  {
    "uniprot": "A0A077Z0E4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0E4"
  },
  {
    "uniprot": "A0A077ZIV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIV3"
  },
  {
    "uniprot": "A0A077Z7J6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7J6"
  },
  {
    "uniprot": "A0A077Z6X1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6X1"
  },
  {
    "uniprot": "A0A077ZIR5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIR5"
  },
  {
    "uniprot": "A0A077ZAW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAW2"
  },
  {
    "uniprot": "A0A077ZBU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBU1"
  },
  {
    "uniprot": "A0A077YXF6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXF6"
  },
  {
    "uniprot": "A0A077Z1F6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1F6"
  },
  {
    "uniprot": "A0A077Z686",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z686"
  },
  {
    "uniprot": "A0A077ZMJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMJ8"
  },
  {
    "uniprot": "A0A077ZLL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLL7"
  },
  {
    "uniprot": "A0A077YY16",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY16"
  },
  {
    "uniprot": "A0A077Z398",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z398"
  },
  {
    "uniprot": "A0A077YZX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZX7"
  },
  {
    "uniprot": "A0A077ZG88",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG88"
  },
  {
    "uniprot": "A0A077ZLK2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLK2"
  },
  {
    "uniprot": "A0A077ZL34",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL34"
  },
  {
    "uniprot": "A0A077YX66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX66"
  },
  {
    "uniprot": "A0A077ZMC4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMC4"
  },
  {
    "uniprot": "A0A077ZFT6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFT6"
  },
  {
    "uniprot": "A0A077Z1L0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1L0"
  },
  {
    "uniprot": "A0A077ZG05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG05"
  },
  {
    "uniprot": "A0A077ZBW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBW5"
  },
  {
    "uniprot": "A0A077Z2V5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2V5"
  },
  {
    "uniprot": "A0A077Z3U9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3U9"
  },
  {
    "uniprot": "A0A077Z3K2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3K2"
  },
  {
    "uniprot": "A0A077ZA02",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA02"
  },
  {
    "uniprot": "A0A077Z8D6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8D6"
  },
  {
    "uniprot": "A0A077ZKN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKN4"
  },
  {
    "uniprot": "A0A077Z456",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z456"
  },
  {
    "uniprot": "A0A077ZQ36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ36"
  },
  {
    "uniprot": "A0A077ZFQ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFQ7"
  },
  {
    "uniprot": "A0A077ZJZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJZ4"
  },
  {
    "uniprot": "A0A077Z4M5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4M5"
  },
  {
    "uniprot": "A0A077ZFK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFK4"
  },
  {
    "uniprot": "A0A077Z7H4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7H4"
  },
  {
    "uniprot": "A0A077Z6J3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6J3"
  },
  {
    "uniprot": "A0A077ZJA4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJA4"
  },
  {
    "uniprot": "A0A077Z718",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z718"
  },
  {
    "uniprot": "A0A077Z210",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z210"
  },
  {
    "uniprot": "A0A077Z0J8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0J8"
  },
  {
    "uniprot": "A0A077YZE1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZE1"
  },
  {
    "uniprot": "A0A077ZLI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLI8"
  },
  {
    "uniprot": "A0A077ZAJ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAJ9"
  },
  {
    "uniprot": "A0A077ZHL2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHL2"
  },
  {
    "uniprot": "A0A077Z5D9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5D9"
  },
  {
    "uniprot": "A0A077ZNG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNG5"
  },
  {
    "uniprot": "A0A077Z4C8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4C8"
  },
  {
    "uniprot": "A0A077ZF53",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF53"
  },
  {
    "uniprot": "A0A077ZKP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKP8"
  },
  {
    "uniprot": "A0A077ZIA9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIA9"
  },
  {
    "uniprot": "A0A077Z9V5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9V5"
  },
  {
    "uniprot": "A0A077YYX2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYX2"
  },
  {
    "uniprot": "A0A077Z1F1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1F1"
  },
  {
    "uniprot": "A0A077Z9K2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9K2"
  },
  {
    "uniprot": "A0A077Z576",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z576"
  },
  {
    "uniprot": "A0A077YZV2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZV2"
  },
  {
    "uniprot": "A0A077YWH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWH1"
  },
  {
    "uniprot": "A0A077ZR35",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR35"
  },
  {
    "uniprot": "A0A077ZFH9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFH9"
  },
  {
    "uniprot": "A0A077Z5A5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5A5"
  },
  {
    "uniprot": "A0A077ZKW6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKW6"
  },
  {
    "uniprot": "A0A077ZIA0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIA0"
  },
  {
    "uniprot": "A0A077Z2Q8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Q8"
  },
  {
    "uniprot": "A0A077Z9P8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9P8"
  },
  {
    "uniprot": "A0A077ZET4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZET4"
  },
  {
    "uniprot": "A0A077Z0D1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0D1"
  },
  {
    "uniprot": "A0A077ZGC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGC3"
  },
  {
    "uniprot": "A0A077ZFQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFQ8"
  },
  {
    "uniprot": "A0A077YV06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV06"
  },
  {
    "uniprot": "A0A077ZL61",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL61"
  },
  {
    "uniprot": "A0A077ZM00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM00"
  },
  {
    "uniprot": "A0A077Z055",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z055"
  },
  {
    "uniprot": "A0A077ZFL4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFL4"
  },
  {
    "uniprot": "A0A077Z8H9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8H9"
  },
  {
    "uniprot": "A0A077Z843",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z843"
  },
  {
    "uniprot": "A0A077ZF67",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF67"
  },
  {
    "uniprot": "A0A077Z8A2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8A2"
  },
  {
    "uniprot": "A0A077YWX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWX6"
  },
  {
    "uniprot": "A0A077ZG87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG87"
  },
  {
    "uniprot": "A0A077ZIQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIQ5"
  },
  {
    "uniprot": "A0A077ZLB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLB9"
  },
  {
    "uniprot": "A0A077Z8B0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8B0"
  },
  {
    "uniprot": "A0A077ZBT4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBT4"
  },
  {
    "uniprot": "A0A077Z8S4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8S4"
  },
  {
    "uniprot": "A0A077Z8X3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8X3"
  },
  {
    "uniprot": "A0A077YX19",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX19"
  },
  {
    "uniprot": "A0A077YYD6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYD6"
  },
  {
    "uniprot": "A0A077Z4Z6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Z6"
  },
  {
    "uniprot": "A0A077ZPG1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPG1"
  },
  {
    "uniprot": "A0A077ZI63",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI63"
  },
  {
    "uniprot": "A0A077ZFR1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFR1"
  },
  {
    "uniprot": "A0A077ZKL9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKL9"
  },
  {
    "uniprot": "A0A077ZMZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMZ9"
  },
  {
    "uniprot": "A0A077Z6X2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6X2"
  },
  {
    "uniprot": "A0A077ZCV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCV9"
  },
  {
    "uniprot": "A0A077YX70",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX70"
  },
  {
    "uniprot": "A0A077Z0B5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0B5"
  },
  {
    "uniprot": "A0A077Z1Z8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Z8"
  },
  {
    "uniprot": "A0A077ZLP6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLP6"
  },
  {
    "uniprot": "A0A077ZCT5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCT5"
  },
  {
    "uniprot": "A0A077ZA63",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA63"
  },
  {
    "uniprot": "A0A077YZG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZG7"
  },
  {
    "uniprot": "A0A077ZDS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDS1"
  },
  {
    "uniprot": "A0A077ZH93",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH93"
  },
  {
    "uniprot": "A0A077ZBH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBH3"
  },
  {
    "uniprot": "A0A077ZG72",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG72"
  },
  {
    "uniprot": "A0A077ZF90",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF90"
  },
  {
    "uniprot": "A0A077ZDS6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDS6"
  },
  {
    "uniprot": "A0A077Z2L6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2L6"
  },
  {
    "uniprot": "A0A077ZJY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJY9"
  },
  {
    "uniprot": "A0A077ZDF8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDF8"
  },
  {
    "uniprot": "A0A077ZDX5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDX5"
  },
  {
    "uniprot": "A0A077ZG91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG91"
  },
  {
    "uniprot": "A0A077ZJH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJH3"
  },
  {
    "uniprot": "A0A077ZIZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIZ9"
  },
  {
    "uniprot": "A0A077ZDY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDY4"
  },
  {
    "uniprot": "A0A077Z4I6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4I6"
  },
  {
    "uniprot": "A0A077Z6G2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6G2"
  },
  {
    "uniprot": "A0A077ZJK0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJK0"
  },
  {
    "uniprot": "A0A077ZET9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZET9"
  },
  {
    "uniprot": "A0A077YVM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVM6"
  },
  {
    "uniprot": "A0A077YY03",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY03"
  },
  {
    "uniprot": "A0A077ZE77",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE77"
  },
  {
    "uniprot": "A0A077ZJT6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJT6"
  },
  {
    "uniprot": "A0A077ZF10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF10"
  },
  {
    "uniprot": "A0A077ZHY7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHY7"
  },
  {
    "uniprot": "A0A077Z1S4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1S4"
  },
  {
    "uniprot": "A0A077Z256",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z256"
  },
  {
    "uniprot": "A0A077ZCL1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCL1"
  },
  {
    "uniprot": "A0A077Z931",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z931"
  },
  {
    "uniprot": "A0A077YZS8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZS8"
  },
  {
    "uniprot": "A0A077ZNC2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNC2"
  },
  {
    "uniprot": "A0A077ZLF8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLF8"
  },
  {
    "uniprot": "A0A077YVQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVQ0"
  },
  {
    "uniprot": "A0A077ZAK1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAK1"
  },
  {
    "uniprot": "A0A077Z0C9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0C9"
  },
  {
    "uniprot": "A0A077Z5D0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5D0"
  },
  {
    "uniprot": "A0A077ZB61",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB61"
  },
  {
    "uniprot": "A0A077Z3T5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3T5"
  },
  {
    "uniprot": "A0A077ZBY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBY9"
  },
  {
    "uniprot": "A0A077ZJM3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJM3"
  },
  {
    "uniprot": "A0A077ZC11",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC11"
  },
  {
    "uniprot": "A0A077Z910",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z910"
  },
  {
    "uniprot": "A0A077YWM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWM6"
  },
  {
    "uniprot": "A0A077Z0X6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0X6"
  },
  {
    "uniprot": "A0A077Z9K3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9K3"
  },
  {
    "uniprot": "A0A077YYU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYU6"
  },
  {
    "uniprot": "A0A077ZMM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMM1"
  },
  {
    "uniprot": "A0A077ZGM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGM7"
  },
  {
    "uniprot": "A0A077Z3Q2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Q2"
  },
  {
    "uniprot": "A0A077YWS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWS1"
  },
  {
    "uniprot": "A0A077YX32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX32"
  },
  {
    "uniprot": "A0A077Z1A2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1A2"
  },
  {
    "uniprot": "A0A077ZCE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCE2"
  },
  {
    "uniprot": "A0A077Z203",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z203"
  },
  {
    "uniprot": "A0A077Z6J2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6J2"
  },
  {
    "uniprot": "A0A077ZDM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDM7"
  },
  {
    "uniprot": "A0A077ZFR3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFR3"
  },
  {
    "uniprot": "A0A077ZIA7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIA7"
  },
  {
    "uniprot": "A0A077ZGX8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGX8"
  },
  {
    "uniprot": "A0A077Z5L8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5L8"
  },
  {
    "uniprot": "A0A077ZHU0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHU0"
  },
  {
    "uniprot": "A0A077Z3S3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3S3"
  },
  {
    "uniprot": "A0A077ZGK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGK7"
  },
  {
    "uniprot": "A0A077ZL24",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL24"
  },
  {
    "uniprot": "A0A077YW68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW68"
  },
  {
    "uniprot": "A0A077Z8D2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8D2"
  },
  {
    "uniprot": "A0A077Z0S5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0S5"
  },
  {
    "uniprot": "A0A077Z7L5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7L5"
  },
  {
    "uniprot": "A0A077YVQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVQ8"
  },
  {
    "uniprot": "A0A077ZM53",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM53"
  },
  {
    "uniprot": "A0A077Z7R3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7R3"
  },
  {
    "uniprot": "A0A077ZK31",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK31"
  },
  {
    "uniprot": "A0A077Z427",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z427"
  },
  {
    "uniprot": "A0A077ZDH9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDH9"
  },
  {
    "uniprot": "A0A077YWP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWP2"
  },
  {
    "uniprot": "A0A077ZK65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK65"
  },
  {
    "uniprot": "A0A077ZH40",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH40"
  },
  {
    "uniprot": "A0A077Z8J7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8J7"
  },
  {
    "uniprot": "A0A077ZIM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIM1"
  },
  {
    "uniprot": "A0A077YZU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZU5"
  },
  {
    "uniprot": "A0A077Z0W7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0W7"
  },
  {
    "uniprot": "A0A077ZLZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLZ8"
  },
  {
    "uniprot": "A0A077Z9J7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9J7"
  },
  {
    "uniprot": "A0A077ZF05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF05"
  },
  {
    "uniprot": "A0A077ZC75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC75"
  },
  {
    "uniprot": "A0A077Z7F5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7F5"
  },
  {
    "uniprot": "A0A077Z3Y5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Y5"
  },
  {
    "uniprot": "A0A077ZA15",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA15"
  },
  {
    "uniprot": "A0A077ZQV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQV6"
  },
  {
    "uniprot": "A0A077ZCX4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCX4"
  },
  {
    "uniprot": "A0A077Z3P6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3P6"
  },
  {
    "uniprot": "A0A077Z513",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z513"
  },
  {
    "uniprot": "A0A077Z7J9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7J9"
  },
  {
    "uniprot": "A0A077ZAA4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAA4"
  },
  {
    "uniprot": "A0A077ZHX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHX6"
  },
  {
    "uniprot": "A0A077ZLE4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLE4"
  },
  {
    "uniprot": "A0A077YZQ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZQ6"
  },
  {
    "uniprot": "A0A077YXG4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXG4"
  },
  {
    "uniprot": "A0A077YVS0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVS0"
  },
  {
    "uniprot": "A0A077YX90",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX90"
  },
  {
    "uniprot": "A0A077Z8K0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8K0"
  },
  {
    "uniprot": "A0A077YWG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWG8"
  },
  {
    "uniprot": "A0A077ZBL5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBL5"
  },
  {
    "uniprot": "A0A077Z452",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z452"
  },
  {
    "uniprot": "A0A077Z689",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z689"
  },
  {
    "uniprot": "A0A077Z9C7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9C7"
  },
  {
    "uniprot": "A0A077YVN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVN4"
  },
  {
    "uniprot": "A0A077ZN59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN59"
  },
  {
    "uniprot": "A0A077ZDK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDK7"
  },
  {
    "uniprot": "A0A077Z963",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z963"
  },
  {
    "uniprot": "A0A077Z3H4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3H4"
  },
  {
    "uniprot": "A0A077Z739",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z739"
  },
  {
    "uniprot": "A0A077Z019",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z019"
  },
  {
    "uniprot": "A0A077ZPZ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPZ2"
  },
  {
    "uniprot": "A0A077ZJK8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJK8"
  },
  {
    "uniprot": "A0A077ZIW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIW1"
  },
  {
    "uniprot": "A0A077ZCI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCI8"
  },
  {
    "uniprot": "A0A077Z448",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z448"
  },
  {
    "uniprot": "A0A077ZMQ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMQ9"
  },
  {
    "uniprot": "A0A077ZGI4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGI4"
  },
  {
    "uniprot": "A0A077ZLC4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLC4"
  },
  {
    "uniprot": "A0A077Z9X0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9X0"
  },
  {
    "uniprot": "A0A077Z995",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z995"
  },
  {
    "uniprot": "A0A077Z451",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z451"
  },
  {
    "uniprot": "A0A077YZQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZQ3"
  },
  {
    "uniprot": "A0A077Z7F0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7F0"
  },
  {
    "uniprot": "A0A077ZEA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEA1"
  },
  {
    "uniprot": "A0A077ZCW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCW1"
  },
  {
    "uniprot": "A0A077YW98",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW98"
  },
  {
    "uniprot": "A0A077ZCC0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCC0"
  },
  {
    "uniprot": "A0A077YX53",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX53"
  },
  {
    "uniprot": "A0A077Z9L4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9L4"
  },
  {
    "uniprot": "A0A077ZJK1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJK1"
  },
  {
    "uniprot": "A0A077ZED0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZED0"
  },
  {
    "uniprot": "A0A077Z1J4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1J4"
  },
  {
    "uniprot": "A0A077YZD3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZD3"
  },
  {
    "uniprot": "A0A077ZHB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHB6"
  },
  {
    "uniprot": "A0A077ZMF8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMF8"
  },
  {
    "uniprot": "A0A077Z568",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z568"
  },
  {
    "uniprot": "A0A077ZCI0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCI0"
  },
  {
    "uniprot": "A0A077ZBB3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBB3"
  },
  {
    "uniprot": "A0A077ZBT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBT8"
  },
  {
    "uniprot": "A0A077YZS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZS1"
  },
  {
    "uniprot": "A0A077Z4C1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4C1"
  },
  {
    "uniprot": "A0A077ZB20",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB20"
  },
  {
    "uniprot": "A0A077ZG43",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG43"
  },
  {
    "uniprot": "A0A077ZD65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD65"
  },
  {
    "uniprot": "A0A077Z1T8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1T8"
  },
  {
    "uniprot": "A0A077Z052",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z052"
  },
  {
    "uniprot": "A0A077ZAY2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAY2"
  },
  {
    "uniprot": "A0A077Z8J3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8J3"
  },
  {
    "uniprot": "A0A077ZEL8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEL8"
  },
  {
    "uniprot": "A0A077YZ16",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ16"
  },
  {
    "uniprot": "A0A077Z003",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z003"
  },
  {
    "uniprot": "A0A077ZKB8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKB8"
  },
  {
    "uniprot": "A0A077ZE96",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE96"
  },
  {
    "uniprot": "A0A077ZBL3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBL3"
  },
  {
    "uniprot": "A0A077ZD90",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD90"
  },
  {
    "uniprot": "A0A077Z0T7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0T7"
  },
  {
    "uniprot": "A0A077ZM20",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM20"
  },
  {
    "uniprot": "A0A077Z146",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z146"
  },
  {
    "uniprot": "A0A077ZEE4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEE4"
  },
  {
    "uniprot": "A0A077ZA30",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA30"
  },
  {
    "uniprot": "A0A077Z8D7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8D7"
  },
  {
    "uniprot": "A0A077ZHD0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHD0"
  },
  {
    "uniprot": "A0A077YWI0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWI0"
  },
  {
    "uniprot": "A0A077Z392",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z392"
  },
  {
    "uniprot": "A0A077Z4E8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4E8"
  },
  {
    "uniprot": "A0A077ZL47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL47"
  },
  {
    "uniprot": "A0A077ZF40",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF40"
  },
  {
    "uniprot": "A0A077Z8Z7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Z7"
  },
  {
    "uniprot": "A0A077ZJR9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJR9"
  },
  {
    "uniprot": "A0A077Z7Q5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Q5"
  },
  {
    "uniprot": "A0A077ZB32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB32"
  },
  {
    "uniprot": "A0A077Z7B1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7B1"
  },
  {
    "uniprot": "A0A077Z6N3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6N3"
  },
  {
    "uniprot": "A0A077YWH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWH5"
  },
  {
    "uniprot": "A0A077ZIH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIH8"
  },
  {
    "uniprot": "A0A077ZJJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJJ7"
  },
  {
    "uniprot": "A0A077Z6A0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6A0"
  },
  {
    "uniprot": "A0A077Z7U1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7U1"
  },
  {
    "uniprot": "A0A077ZF04",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF04"
  },
  {
    "uniprot": "A0A077ZK36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK36"
  },
  {
    "uniprot": "A0A077Z9R0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9R0"
  },
  {
    "uniprot": "A0A077YWM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWM7"
  },
  {
    "uniprot": "A0A077Z1G8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1G8"
  },
  {
    "uniprot": "A0A077ZA95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA95"
  },
  {
    "uniprot": "A0A077YYH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYH8"
  },
  {
    "uniprot": "A0A077Z242",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z242"
  },
  {
    "uniprot": "A0A077ZHL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHL6"
  },
  {
    "uniprot": "A0A077ZLE9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLE9"
  },
  {
    "uniprot": "A0A077Z9I8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9I8"
  },
  {
    "uniprot": "A0A077Z962",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z962"
  },
  {
    "uniprot": "A0A077Z727",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z727"
  },
  {
    "uniprot": "A0A077Z4S4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4S4"
  },
  {
    "uniprot": "A0A077Z9L5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9L5"
  },
  {
    "uniprot": "A0A077ZE54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE54"
  },
  {
    "uniprot": "A0A077YXG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXG9"
  },
  {
    "uniprot": "A0A077Z9H5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9H5"
  },
  {
    "uniprot": "A0A077YZ49",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ49"
  },
  {
    "uniprot": "A0A077Z5H0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5H0"
  },
  {
    "uniprot": "A0A077ZHH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHH5"
  },
  {
    "uniprot": "A0A077ZL55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL55"
  },
  {
    "uniprot": "A0A077ZBU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBU9"
  },
  {
    "uniprot": "A0A077ZGU0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGU0"
  },
  {
    "uniprot": "A0A077YVY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVY3"
  },
  {
    "uniprot": "A0A077ZAH6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAH6"
  },
  {
    "uniprot": "A0A077Z375",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z375"
  },
  {
    "uniprot": "A0A077ZQG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQG9"
  },
  {
    "uniprot": "A0A077Z3A1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3A1"
  },
  {
    "uniprot": "A0A077Z2U3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2U3"
  },
  {
    "uniprot": "A0A077ZFN8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFN8"
  },
  {
    "uniprot": "A0A077Z395",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z395"
  },
  {
    "uniprot": "A0A077Z8F4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8F4"
  },
  {
    "uniprot": "A0A077ZQM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQM1"
  },
  {
    "uniprot": "A0A077Z3X7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3X7"
  },
  {
    "uniprot": "A0A077ZF48",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF48"
  },
  {
    "uniprot": "A0A077ZDH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDH1"
  },
  {
    "uniprot": "A0A077Z829",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z829"
  },
  {
    "uniprot": "A0A077ZBQ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBQ1"
  },
  {
    "uniprot": "A0A077ZH12",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH12"
  },
  {
    "uniprot": "A0A077ZCX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCX7"
  },
  {
    "uniprot": "A0A077YZB8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZB8"
  },
  {
    "uniprot": "A0A077YWW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWW1"
  },
  {
    "uniprot": "A0A077Z1E4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1E4"
  },
  {
    "uniprot": "A0A077Z8I2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8I2"
  },
  {
    "uniprot": "A0A077Z6F5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6F5"
  },
  {
    "uniprot": "A0A077Z0F1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0F1"
  },
  {
    "uniprot": "A0A077ZEW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEW1"
  },
  {
    "uniprot": "A0A077ZJ26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ26"
  },
  {
    "uniprot": "A0A077ZCM4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCM4"
  },
  {
    "uniprot": "A0A077ZHC1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHC1"
  },
  {
    "uniprot": "A0A077Z4X8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4X8"
  },
  {
    "uniprot": "A0A077ZLN8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLN8"
  },
  {
    "uniprot": "A0A077ZI84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI84"
  },
  {
    "uniprot": "A0A077ZH85",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH85"
  },
  {
    "uniprot": "A0A077Z7D8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7D8"
  },
  {
    "uniprot": "A0A077YXX4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXX4"
  },
  {
    "uniprot": "A0A077Z545",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z545"
  },
  {
    "uniprot": "A0A077ZDW6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDW6"
  },
  {
    "uniprot": "A0A077ZIJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIJ0"
  },
  {
    "uniprot": "A0A077Z026",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z026"
  },
  {
    "uniprot": "A0A077ZEW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEW9"
  },
  {
    "uniprot": "A0A077ZKX4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKX4"
  },
  {
    "uniprot": "A0A077ZJ95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ95"
  },
  {
    "uniprot": "A0A077ZAH9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAH9"
  },
  {
    "uniprot": "A0A077ZKR3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKR3"
  },
  {
    "uniprot": "A0A077Z2Z1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Z1"
  },
  {
    "uniprot": "A0A077ZI18",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI18"
  },
  {
    "uniprot": "A0A077ZES9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZES9"
  },
  {
    "uniprot": "A0A077Z2B2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2B2"
  },
  {
    "uniprot": "A0A077Z0F8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0F8"
  },
  {
    "uniprot": "A0A077Z9Y5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Y5"
  },
  {
    "uniprot": "A0A077Z066",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z066"
  },
  {
    "uniprot": "A0A077YY80",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY80"
  },
  {
    "uniprot": "A0A077Z7F2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7F2"
  },
  {
    "uniprot": "A0A077ZKN0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKN0"
  },
  {
    "uniprot": "A0A077ZI05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI05"
  },
  {
    "uniprot": "A0A077ZGM9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGM9"
  },
  {
    "uniprot": "A0A077ZE81",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE81"
  },
  {
    "uniprot": "A0A077ZAF3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAF3"
  },
  {
    "uniprot": "A0A077Z9N8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9N8"
  },
  {
    "uniprot": "A0A077ZMD5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMD5"
  },
  {
    "uniprot": "A0A077YZ26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ26"
  },
  {
    "uniprot": "A0A077Z6Z3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Z3"
  },
  {
    "uniprot": "A0A077ZA25",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA25"
  },
  {
    "uniprot": "A0A077YWG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWG7"
  },
  {
    "uniprot": "A0A077Z4T5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4T5"
  },
  {
    "uniprot": "A0A077Z9S2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9S2"
  },
  {
    "uniprot": "A0A077ZC84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC84"
  },
  {
    "uniprot": "A0A077YZF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZF0"
  },
  {
    "uniprot": "A0A077ZJ61",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ61"
  },
  {
    "uniprot": "A0A077Z328",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z328"
  },
  {
    "uniprot": "A0A077Z474",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z474"
  },
  {
    "uniprot": "A0A077ZKS7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKS7"
  },
  {
    "uniprot": "A0A077Z9Z4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Z4"
  },
  {
    "uniprot": "A0A077Z0S7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0S7"
  },
  {
    "uniprot": "A0A077ZFT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFT8"
  },
  {
    "uniprot": "A0A077ZLU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLU7"
  },
  {
    "uniprot": "A0A077ZGX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGX6"
  },
  {
    "uniprot": "A0A077Z9B1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9B1"
  },
  {
    "uniprot": "A0A077ZAN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAN1"
  },
  {
    "uniprot": "A0A077Z4Q1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Q1"
  },
  {
    "uniprot": "A0A077ZHN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHN6"
  },
  {
    "uniprot": "A0A077Z0B7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0B7"
  },
  {
    "uniprot": "A0A077ZDU8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDU8"
  },
  {
    "uniprot": "A0A077ZEB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEB6"
  },
  {
    "uniprot": "A0A077YWF7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWF7"
  },
  {
    "uniprot": "A0A077Z898",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z898"
  },
  {
    "uniprot": "A0A077Z5C4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5C4"
  },
  {
    "uniprot": "A0A077ZD00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD00"
  },
  {
    "uniprot": "A0A077ZBW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBW2"
  },
  {
    "uniprot": "A0A077ZA36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA36"
  },
  {
    "uniprot": "A0A077ZKF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKF0"
  },
  {
    "uniprot": "A0A077ZGB1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGB1"
  },
  {
    "uniprot": "A0A077ZDW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDW0"
  },
  {
    "uniprot": "A0A077ZGF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGF4"
  },
  {
    "uniprot": "A0A077ZFN5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFN5"
  },
  {
    "uniprot": "A0A077ZJ87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ87"
  },
  {
    "uniprot": "A0A077ZKN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKN2"
  },
  {
    "uniprot": "A0A077ZIA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIA1"
  },
  {
    "uniprot": "A0A077Z853",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z853"
  },
  {
    "uniprot": "A0A077Z8C6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8C6"
  },
  {
    "uniprot": "A0A077YY56",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY56"
  },
  {
    "uniprot": "A0A077ZLL9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLL9"
  },
  {
    "uniprot": "A0A077Z9P9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9P9"
  },
  {
    "uniprot": "A0A077YX29",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX29"
  },
  {
    "uniprot": "A0A077Z6H8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6H8"
  },
  {
    "uniprot": "A0A077ZCL5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCL5"
  },
  {
    "uniprot": "A0A077ZJX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJX3"
  },
  {
    "uniprot": "A0A077Z9T6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9T6"
  },
  {
    "uniprot": "A0A077ZLA0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLA0"
  },
  {
    "uniprot": "A0A077ZBX5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBX5"
  },
  {
    "uniprot": "A0A077ZI39",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI39"
  },
  {
    "uniprot": "A0A077Z7C3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7C3"
  },
  {
    "uniprot": "A0A077ZKH2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKH2"
  },
  {
    "uniprot": "A0A077Z9A3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9A3"
  },
  {
    "uniprot": "A0A077ZEW3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEW3"
  },
  {
    "uniprot": "A0A077YXK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXK6"
  },
  {
    "uniprot": "A0A077Z4H2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4H2"
  },
  {
    "uniprot": "A0A077ZAK0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAK0"
  },
  {
    "uniprot": "A0A077ZJW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJW5"
  },
  {
    "uniprot": "A0A077ZCU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCU1"
  },
  {
    "uniprot": "A0A077YXE1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXE1"
  },
  {
    "uniprot": "A0A077ZA34",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA34"
  },
  {
    "uniprot": "A0A077ZF87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF87"
  },
  {
    "uniprot": "A0A077YZE4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZE4"
  },
  {
    "uniprot": "A0A077ZCN8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCN8"
  },
  {
    "uniprot": "A0A077ZGQ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGQ6"
  },
  {
    "uniprot": "A0A077Z5F1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5F1"
  },
  {
    "uniprot": "A0A077Z8L2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8L2"
  },
  {
    "uniprot": "A0A077Z589",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z589"
  },
  {
    "uniprot": "A0A077Z3P2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3P2"
  },
  {
    "uniprot": "A0A077ZD85",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD85"
  },
  {
    "uniprot": "A0A077ZKI0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKI0"
  },
  {
    "uniprot": "A0A077Z752",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z752"
  },
  {
    "uniprot": "A0A077Z595",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z595"
  },
  {
    "uniprot": "A0A077ZJ78",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ78"
  },
  {
    "uniprot": "A0A077ZIT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIT9"
  },
  {
    "uniprot": "A0A077ZEM4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEM4"
  },
  {
    "uniprot": "A0A077Z662",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z662"
  },
  {
    "uniprot": "A0A077ZE13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE13"
  },
  {
    "uniprot": "A0A077ZDI7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDI7"
  },
  {
    "uniprot": "A0A077ZCV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCV3"
  },
  {
    "uniprot": "A0A077ZLM4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLM4"
  },
  {
    "uniprot": "A0A077ZDY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDY3"
  },
  {
    "uniprot": "A0A077ZI31",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI31"
  },
  {
    "uniprot": "A0A077ZC06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC06"
  },
  {
    "uniprot": "A0A077ZFR2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFR2"
  },
  {
    "uniprot": "A0A077Z4A3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4A3"
  },
  {
    "uniprot": "A0A077YZZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZZ8"
  },
  {
    "uniprot": "A0A077YZ23",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ23"
  },
  {
    "uniprot": "A0A077ZFT4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFT4"
  },
  {
    "uniprot": "A0A077ZCJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCJ3"
  },
  {
    "uniprot": "A0A077ZKR2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKR2"
  },
  {
    "uniprot": "A0A077ZK87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK87"
  },
  {
    "uniprot": "A0A077ZDL5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDL5"
  },
  {
    "uniprot": "A0A077ZLD9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLD9"
  },
  {
    "uniprot": "A0A077ZB97",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB97"
  },
  {
    "uniprot": "A0A077ZDM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDM5"
  },
  {
    "uniprot": "A0A077ZIX5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIX5"
  },
  {
    "uniprot": "A0A077ZH54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH54"
  },
  {
    "uniprot": "A0A077ZAF5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAF5"
  },
  {
    "uniprot": "A0A077YXS8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXS8"
  },
  {
    "uniprot": "A0A077Z491",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z491"
  },
  {
    "uniprot": "A0A077ZAT6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAT6"
  },
  {
    "uniprot": "A0A077ZIM9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIM9"
  },
  {
    "uniprot": "A0A077ZC10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC10"
  },
  {
    "uniprot": "A0A077Z6D6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6D6"
  },
  {
    "uniprot": "A0A077Z9K7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9K7"
  },
  {
    "uniprot": "A0A077ZLW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLW9"
  },
  {
    "uniprot": "A0A077ZLW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLW0"
  },
  {
    "uniprot": "A0A077Z8F8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8F8"
  },
  {
    "uniprot": "A0A077Z6A5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6A5"
  },
  {
    "uniprot": "A0A077YY97",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY97"
  },
  {
    "uniprot": "A0A077Z1D7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1D7"
  },
  {
    "uniprot": "A0A077Z148",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z148"
  },
  {
    "uniprot": "A0A077Z1C3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1C3"
  },
  {
    "uniprot": "A0A077ZCW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCW9"
  },
  {
    "uniprot": "A0A077ZFQ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFQ6"
  },
  {
    "uniprot": "A0A077Z4H6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4H6"
  },
  {
    "uniprot": "A0A077ZCV7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCV7"
  },
  {
    "uniprot": "A0A077YXA7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXA7"
  },
  {
    "uniprot": "A0A077Z6I5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6I5"
  },
  {
    "uniprot": "A0A077ZBA2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBA2"
  },
  {
    "uniprot": "A0A077Z929",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z929"
  },
  {
    "uniprot": "A0A077Z5F9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5F9"
  },
  {
    "uniprot": "A0A077ZMQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMQ5"
  },
  {
    "uniprot": "A0A077Z1R3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1R3"
  },
  {
    "uniprot": "A0A077ZKB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKB2"
  },
  {
    "uniprot": "A0A077Z1P2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1P2"
  },
  {
    "uniprot": "A0A077Z3T3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3T3"
  },
  {
    "uniprot": "A0A077ZFQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFQ5"
  },
  {
    "uniprot": "A0A077Z4S1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4S1"
  },
  {
    "uniprot": "A0A077Z614",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z614"
  },
  {
    "uniprot": "A0A077ZA98",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA98"
  },
  {
    "uniprot": "A0A077YZQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZQ5"
  },
  {
    "uniprot": "A0A077ZJH6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJH6"
  },
  {
    "uniprot": "A0A077YXA6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXA6"
  },
  {
    "uniprot": "A0A077Z580",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z580"
  },
  {
    "uniprot": "A0A077ZHI1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHI1"
  },
  {
    "uniprot": "A0A077ZQT2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQT2"
  },
  {
    "uniprot": "A0A077Z1T4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1T4"
  },
  {
    "uniprot": "A0A077ZEM0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEM0"
  },
  {
    "uniprot": "A0A077ZEN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEN6"
  },
  {
    "uniprot": "A0A077ZJI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJI3"
  },
  {
    "uniprot": "A0A077ZI23",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI23"
  },
  {
    "uniprot": "A0A077ZF23",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF23"
  },
  {
    "uniprot": "A0A077Z7K1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7K1"
  },
  {
    "uniprot": "A0A077Z9L8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9L8"
  },
  {
    "uniprot": "A0A077ZD64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD64"
  },
  {
    "uniprot": "A0A077ZBT1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBT1"
  },
  {
    "uniprot": "A0A077YX73",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX73"
  },
  {
    "uniprot": "A0A077ZKN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKN3"
  },
  {
    "uniprot": "A0A077ZHU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHU7"
  },
  {
    "uniprot": "A0A077Z779",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z779"
  },
  {
    "uniprot": "A0A077Z0C8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0C8"
  },
  {
    "uniprot": "A0A077ZN21",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN21"
  },
  {
    "uniprot": "A0A077Z1Z7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Z7"
  },
  {
    "uniprot": "A0A077YZJ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZJ4"
  },
  {
    "uniprot": "A0A077ZFH6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFH6"
  },
  {
    "uniprot": "A0A077Z4F5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4F5"
  },
  {
    "uniprot": "A0A077Z7Q4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Q4"
  },
  {
    "uniprot": "A0A077ZLU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLU6"
  },
  {
    "uniprot": "A0A077Z9S6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9S6"
  },
  {
    "uniprot": "A0A077YXE7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXE7"
  },
  {
    "uniprot": "A0A077ZGN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGN3"
  },
  {
    "uniprot": "A0A077YYU0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYU0"
  },
  {
    "uniprot": "A0A077ZML4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZML4"
  },
  {
    "uniprot": "A0A077Z247",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z247"
  },
  {
    "uniprot": "A0A077Z435",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z435"
  },
  {
    "uniprot": "A0A077ZGD8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGD8"
  },
  {
    "uniprot": "A0A077ZMU8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMU8"
  },
  {
    "uniprot": "A0A077YY30",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY30"
  },
  {
    "uniprot": "A0A077Z6Y3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Y3"
  },
  {
    "uniprot": "A0A077YX02",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX02"
  },
  {
    "uniprot": "A0A077ZC17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC17"
  },
  {
    "uniprot": "A0A077Z7W4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7W4"
  },
  {
    "uniprot": "A0A077Z6Z6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Z6"
  },
  {
    "uniprot": "A0A077ZF01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF01"
  },
  {
    "uniprot": "A0A077ZPD2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPD2"
  },
  {
    "uniprot": "A0A077YWW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWW5"
  },
  {
    "uniprot": "A0A077Z975",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z975"
  },
  {
    "uniprot": "A0A077Z8J6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8J6"
  },
  {
    "uniprot": "A0A077YY75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY75"
  },
  {
    "uniprot": "A0A077ZHY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHY8"
  },
  {
    "uniprot": "A0A077ZKL5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKL5"
  },
  {
    "uniprot": "A0A077ZPY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPY6"
  },
  {
    "uniprot": "A0A077Z6J0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6J0"
  },
  {
    "uniprot": "A0A077ZBH9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBH9"
  },
  {
    "uniprot": "A0A077ZB81",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB81"
  },
  {
    "uniprot": "A0A077Z205",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z205"
  },
  {
    "uniprot": "A0A077ZH28",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH28"
  },
  {
    "uniprot": "A0A077ZGH6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGH6"
  },
  {
    "uniprot": "A0A077ZLF7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLF7"
  },
  {
    "uniprot": "A0A077Z405",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z405"
  },
  {
    "uniprot": "A0A077ZE44",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE44"
  },
  {
    "uniprot": "A0A077Z5C1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5C1"
  },
  {
    "uniprot": "A0A077ZH65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH65"
  },
  {
    "uniprot": "A0A077Z8S9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8S9"
  },
  {
    "uniprot": "A0A077YWL8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWL8"
  },
  {
    "uniprot": "A0A077Z345",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z345"
  },
  {
    "uniprot": "A0A077ZIX8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIX8"
  },
  {
    "uniprot": "A0A077ZLK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLK4"
  },
  {
    "uniprot": "A0A077ZJ30",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ30"
  },
  {
    "uniprot": "A0A077ZMV7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMV7"
  },
  {
    "uniprot": "A0A077Z9S7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9S7"
  },
  {
    "uniprot": "A0A077ZB36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB36"
  },
  {
    "uniprot": "A0A077Z6E3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6E3"
  },
  {
    "uniprot": "A0A077ZFZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFZ9"
  },
  {
    "uniprot": "A0A077Z2P9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2P9"
  },
  {
    "uniprot": "A0A077ZGP1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGP1"
  },
  {
    "uniprot": "A0A077ZMX4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMX4"
  },
  {
    "uniprot": "A0A077YZU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZU4"
  },
  {
    "uniprot": "A0A077ZI10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI10"
  },
  {
    "uniprot": "A0A077Z4Z5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Z5"
  },
  {
    "uniprot": "A0A077Z1M1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1M1"
  },
  {
    "uniprot": "A0A077ZC62",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC62"
  },
  {
    "uniprot": "A0A077Z7Q6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Q6"
  },
  {
    "uniprot": "A0A077Z016",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z016"
  },
  {
    "uniprot": "A0A077ZAC2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAC2"
  },
  {
    "uniprot": "A0A077ZE91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE91"
  },
  {
    "uniprot": "A0A077Z6X7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6X7"
  },
  {
    "uniprot": "A0A077YXU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXU7"
  },
  {
    "uniprot": "A0A077Z701",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z701"
  },
  {
    "uniprot": "A0A077Z2K9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2K9"
  },
  {
    "uniprot": "A0A077YW73",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW73"
  },
  {
    "uniprot": "A0A077Z835",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z835"
  },
  {
    "uniprot": "A0A077ZHP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHP3"
  },
  {
    "uniprot": "A0A077ZAS8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAS8"
  },
  {
    "uniprot": "A0A077Z410",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z410"
  },
  {
    "uniprot": "A0A077Z6K3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6K3"
  },
  {
    "uniprot": "A0A077Z6P5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6P5"
  },
  {
    "uniprot": "A0A077ZJN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJN2"
  },
  {
    "uniprot": "A0A077Z3M7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3M7"
  },
  {
    "uniprot": "A0A077ZIM0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIM0"
  },
  {
    "uniprot": "A0A077ZG65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG65"
  },
  {
    "uniprot": "A0A077ZEE5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEE5"
  },
  {
    "uniprot": "A0A077ZHW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHW0"
  },
  {
    "uniprot": "A0A077ZM37",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM37"
  },
  {
    "uniprot": "A0A077Z7Z0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Z0"
  },
  {
    "uniprot": "A0A077YZX5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZX5"
  },
  {
    "uniprot": "A0A077Z8P4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8P4"
  },
  {
    "uniprot": "A0A077ZBA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBA8"
  },
  {
    "uniprot": "A0A077ZQR2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQR2"
  },
  {
    "uniprot": "A0A077Z3H6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3H6"
  },
  {
    "uniprot": "A0A077ZAS3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAS3"
  },
  {
    "uniprot": "A0A077ZK73",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK73"
  },
  {
    "uniprot": "A0A077YZM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZM1"
  },
  {
    "uniprot": "A0A077Z8M6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8M6"
  },
  {
    "uniprot": "A0A077ZJU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJU5"
  },
  {
    "uniprot": "A0A077ZP54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP54"
  },
  {
    "uniprot": "A0A077ZG85",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG85"
  },
  {
    "uniprot": "A0A077ZMG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMG7"
  },
  {
    "uniprot": "A0A077Z4B7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4B7"
  },
  {
    "uniprot": "A0A077Z688",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z688"
  },
  {
    "uniprot": "A0A077ZE06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE06"
  },
  {
    "uniprot": "A0A077Z1G0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1G0"
  },
  {
    "uniprot": "A0A077ZAD2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAD2"
  },
  {
    "uniprot": "A0A077ZF91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF91"
  },
  {
    "uniprot": "A0A077ZEC7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEC7"
  },
  {
    "uniprot": "A0A077ZAG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAG3"
  },
  {
    "uniprot": "A0A077Z4U0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4U0"
  },
  {
    "uniprot": "A0A077Z6B0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6B0"
  },
  {
    "uniprot": "A0A077ZD15",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD15"
  },
  {
    "uniprot": "A0A077ZR38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR38"
  },
  {
    "uniprot": "A0A077Z6L8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6L8"
  },
  {
    "uniprot": "A0A077ZDD0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDD0"
  },
  {
    "uniprot": "A0A077ZHP7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHP7"
  },
  {
    "uniprot": "A0A077Z730",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z730"
  },
  {
    "uniprot": "A0A077YZQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZQ0"
  },
  {
    "uniprot": "A0A077ZB86",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB86"
  },
  {
    "uniprot": "A0A077Z2L3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2L3"
  },
  {
    "uniprot": "A0A077YWC5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWC5"
  },
  {
    "uniprot": "A0A077ZBF3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBF3"
  },
  {
    "uniprot": "A0A077ZM11",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM11"
  },
  {
    "uniprot": "A0A077Z488",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z488"
  },
  {
    "uniprot": "A0A077YUM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUM8"
  },
  {
    "uniprot": "A0A077YVP6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVP6"
  },
  {
    "uniprot": "A0A077Z7A6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7A6"
  },
  {
    "uniprot": "A0A077YWM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWM1"
  },
  {
    "uniprot": "A0A077YWP6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWP6"
  },
  {
    "uniprot": "A0A077ZBH0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBH0"
  },
  {
    "uniprot": "A0A077Z5J8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5J8"
  },
  {
    "uniprot": "A0A077ZB15",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB15"
  },
  {
    "uniprot": "A0A077Z0K7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0K7"
  },
  {
    "uniprot": "A0A077ZHL3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHL3"
  },
  {
    "uniprot": "A0A077ZF09",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF09"
  },
  {
    "uniprot": "A0A077YZU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZU1"
  },
  {
    "uniprot": "A0A077ZBP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBP5"
  },
  {
    "uniprot": "A0A077Z381",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z381"
  },
  {
    "uniprot": "A0A077ZN29",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN29"
  },
  {
    "uniprot": "A0A077YWY7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWY7"
  },
  {
    "uniprot": "A0A077ZH09",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH09"
  },
  {
    "uniprot": "A0A077Z964",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z964"
  },
  {
    "uniprot": "A0A077ZBJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBJ5"
  },
  {
    "uniprot": "A0A077Z277",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z277"
  },
  {
    "uniprot": "A0A077Z377",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z377"
  },
  {
    "uniprot": "A0A077Z8J8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8J8"
  },
  {
    "uniprot": "A0A077Z088",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z088"
  },
  {
    "uniprot": "A0A077Z987",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z987"
  },
  {
    "uniprot": "A0A077Z9Y4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Y4"
  },
  {
    "uniprot": "A0A077Z4N1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4N1"
  },
  {
    "uniprot": "A0A077Z691",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z691"
  },
  {
    "uniprot": "A0A077Z290",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z290"
  },
  {
    "uniprot": "A0A077ZED3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZED3"
  },
  {
    "uniprot": "A0A077ZFT1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFT1"
  },
  {
    "uniprot": "A0A077Z5I8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5I8"
  },
  {
    "uniprot": "A0A077Z2I6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2I6"
  },
  {
    "uniprot": "A0A077Z1N8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1N8"
  },
  {
    "uniprot": "A0A077Z8D5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8D5"
  },
  {
    "uniprot": "A0A077ZHX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHX9"
  },
  {
    "uniprot": "A0A077YZ67",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ67"
  },
  {
    "uniprot": "A0A077Z0R1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0R1"
  },
  {
    "uniprot": "A0A077Z664",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z664"
  },
  {
    "uniprot": "A0A077Z8C1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8C1"
  },
  {
    "uniprot": "A0A077ZI01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI01"
  },
  {
    "uniprot": "A0A077Z6S3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6S3"
  },
  {
    "uniprot": "A0A077YX79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX79"
  },
  {
    "uniprot": "A0A077ZIG1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIG1"
  },
  {
    "uniprot": "A0A077ZJR5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJR5"
  },
  {
    "uniprot": "A0A077Z3F5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3F5"
  },
  {
    "uniprot": "A0A077ZRB8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZRB8"
  },
  {
    "uniprot": "A0A077Z926",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z926"
  },
  {
    "uniprot": "A0A077Z0C3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0C3"
  },
  {
    "uniprot": "A0A077ZI75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI75"
  },
  {
    "uniprot": "A0A077Z574",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z574"
  },
  {
    "uniprot": "A0A077Z2S9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2S9"
  },
  {
    "uniprot": "A0A077Z818",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z818"
  },
  {
    "uniprot": "A0A077ZPL4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPL4"
  },
  {
    "uniprot": "A0A077YYB3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYB3"
  },
  {
    "uniprot": "A0A077YZF1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZF1"
  },
  {
    "uniprot": "A0A077Z0B4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0B4"
  },
  {
    "uniprot": "A0A077Z2X2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2X2"
  },
  {
    "uniprot": "A0A077ZQT5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQT5"
  },
  {
    "uniprot": "A0A077YYL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYL6"
  },
  {
    "uniprot": "A0A077ZIQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIQ0"
  },
  {
    "uniprot": "A0A077ZAH7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAH7"
  },
  {
    "uniprot": "A0A077YWN5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWN5"
  },
  {
    "uniprot": "A0A077Z4T7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4T7"
  },
  {
    "uniprot": "A0A077ZBE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBE2"
  },
  {
    "uniprot": "A0A077ZFU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFU1"
  },
  {
    "uniprot": "A0A077ZGP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGP2"
  },
  {
    "uniprot": "A0A077Z4X2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4X2"
  },
  {
    "uniprot": "A0A077Z0H2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0H2"
  },
  {
    "uniprot": "A0A077ZFE6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFE6"
  },
  {
    "uniprot": "A0A077Z229",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z229"
  },
  {
    "uniprot": "A0A077ZE37",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE37"
  },
  {
    "uniprot": "A0A077ZLP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLP9"
  },
  {
    "uniprot": "A0A077YZK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZK3"
  },
  {
    "uniprot": "A0A077Z0Z4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Z4"
  },
  {
    "uniprot": "A0A077Z9J8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9J8"
  },
  {
    "uniprot": "A0A077Z1X1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1X1"
  },
  {
    "uniprot": "A0A077Z1L2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1L2"
  },
  {
    "uniprot": "A0A077Z0U3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0U3"
  },
  {
    "uniprot": "A0A077Z0Q1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Q1"
  },
  {
    "uniprot": "A0A077ZI80",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI80"
  },
  {
    "uniprot": "A0A077ZK50",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK50"
  },
  {
    "uniprot": "A0A077Z137",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z137"
  },
  {
    "uniprot": "A0A077Z0J7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0J7"
  },
  {
    "uniprot": "A0A077Z1E8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1E8"
  },
  {
    "uniprot": "A0A077ZL00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL00"
  },
  {
    "uniprot": "A0A077ZCC1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCC1"
  },
  {
    "uniprot": "A0A077ZAN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAN3"
  },
  {
    "uniprot": "A0A077Z2A9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2A9"
  },
  {
    "uniprot": "A0A077ZHC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHC3"
  },
  {
    "uniprot": "A0A077ZHZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHZ1"
  },
  {
    "uniprot": "A0A077ZGV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGV6"
  },
  {
    "uniprot": "A0A077ZDX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDX9"
  },
  {
    "uniprot": "A0A077ZEU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEU5"
  },
  {
    "uniprot": "A0A077ZF26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF26"
  },
  {
    "uniprot": "A0A077YYQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYQ8"
  },
  {
    "uniprot": "A0A077ZAU0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAU0"
  },
  {
    "uniprot": "A0A077Z3M3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3M3"
  },
  {
    "uniprot": "A0A077ZJS2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJS2"
  },
  {
    "uniprot": "A0A077Z402",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z402"
  },
  {
    "uniprot": "A0A077Z916",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z916"
  },
  {
    "uniprot": "A0A077Z721",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z721"
  },
  {
    "uniprot": "A0A077Z5P2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5P2"
  },
  {
    "uniprot": "A0A077ZDT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDT8"
  },
  {
    "uniprot": "A0A077ZN68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN68"
  },
  {
    "uniprot": "A0A077ZMJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMJ2"
  },
  {
    "uniprot": "A0A077Z7U4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7U4"
  },
  {
    "uniprot": "A0A077ZA76",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA76"
  },
  {
    "uniprot": "A0A077ZH00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH00"
  },
  {
    "uniprot": "A0A077ZJC8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJC8"
  },
  {
    "uniprot": "A0A077Z4U5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4U5"
  },
  {
    "uniprot": "A0A077Z8R7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8R7"
  },
  {
    "uniprot": "A0A077Z7Z5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Z5"
  },
  {
    "uniprot": "A0A077ZH70",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH70"
  },
  {
    "uniprot": "A0A077YXJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXJ0"
  },
  {
    "uniprot": "A0A077YZB7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZB7"
  },
  {
    "uniprot": "A0A077Z903",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z903"
  },
  {
    "uniprot": "A0A077ZLU3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLU3"
  },
  {
    "uniprot": "A0A077ZDA2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDA2"
  },
  {
    "uniprot": "A0A077Z4J8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4J8"
  },
  {
    "uniprot": "A0A077ZD12",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD12"
  },
  {
    "uniprot": "A0A077Z5N6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5N6"
  },
  {
    "uniprot": "A0A077Z8K2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8K2"
  },
  {
    "uniprot": "A0A077Z2S5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2S5"
  },
  {
    "uniprot": "A0A077ZGS4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGS4"
  },
  {
    "uniprot": "A0A077ZIF7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIF7"
  },
  {
    "uniprot": "A0A077Z3P7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3P7"
  },
  {
    "uniprot": "A0A077Z6V5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6V5"
  },
  {
    "uniprot": "A0A077Z3G6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3G6"
  },
  {
    "uniprot": "A0A077Z5P6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5P6"
  },
  {
    "uniprot": "A0A077Z163",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z163"
  },
  {
    "uniprot": "A0A077Z387",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z387"
  },
  {
    "uniprot": "A0A077Z5W7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5W7"
  },
  {
    "uniprot": "A0A077ZA33",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA33"
  },
  {
    "uniprot": "A0A077ZMK8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMK8"
  },
  {
    "uniprot": "A0A077ZCT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCT9"
  },
  {
    "uniprot": "A0A077YZ68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ68"
  },
  {
    "uniprot": "A0A077ZEQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEQ3"
  },
  {
    "uniprot": "A0A077ZJG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJG3"
  },
  {
    "uniprot": "A0A077ZH38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH38"
  },
  {
    "uniprot": "A0A077Z223",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z223"
  },
  {
    "uniprot": "A0A077ZL68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL68"
  },
  {
    "uniprot": "A0A077ZAS2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAS2"
  },
  {
    "uniprot": "A0A077YWH9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWH9"
  },
  {
    "uniprot": "A0A077Z3B4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3B4"
  },
  {
    "uniprot": "A0A077YXI7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXI7"
  },
  {
    "uniprot": "A0A077ZMC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMC3"
  },
  {
    "uniprot": "A0A077YVA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVA1"
  },
  {
    "uniprot": "A0A077ZJG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJG5"
  },
  {
    "uniprot": "A0A077ZKR7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKR7"
  },
  {
    "uniprot": "A0A077Z3Q0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Q0"
  },
  {
    "uniprot": "A0A077ZDW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDW2"
  },
  {
    "uniprot": "A0A077YVI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVI3"
  },
  {
    "uniprot": "A0A077Z921",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z921"
  },
  {
    "uniprot": "A0A077ZK04",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK04"
  },
  {
    "uniprot": "A0A077ZI85",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI85"
  },
  {
    "uniprot": "A0A077ZAZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAZ9"
  },
  {
    "uniprot": "A0A077ZR75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR75"
  },
  {
    "uniprot": "A0A077Z3Z1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Z1"
  },
  {
    "uniprot": "A0A077ZEZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEZ3"
  },
  {
    "uniprot": "A0A077Z9B9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9B9"
  },
  {
    "uniprot": "A0A077ZD74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD74"
  },
  {
    "uniprot": "A0A077Z248",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z248"
  },
  {
    "uniprot": "A0A077ZKZ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKZ7"
  },
  {
    "uniprot": "A0A077ZII0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZII0"
  },
  {
    "uniprot": "A0A077Z7N8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7N8"
  },
  {
    "uniprot": "A0A077ZHM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHM7"
  },
  {
    "uniprot": "A0A077ZNM4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNM4"
  },
  {
    "uniprot": "A0A077ZK49",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK49"
  },
  {
    "uniprot": "A0A077YW50",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW50"
  },
  {
    "uniprot": "A0A077ZMG2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMG2"
  },
  {
    "uniprot": "A0A077ZD98",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD98"
  },
  {
    "uniprot": "A0A077YYR0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYR0"
  },
  {
    "uniprot": "A0A077Z844",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z844"
  },
  {
    "uniprot": "A0A077Z8V9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8V9"
  },
  {
    "uniprot": "A0A077ZHK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHK7"
  },
  {
    "uniprot": "A0A077Z2I3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2I3"
  },
  {
    "uniprot": "A0A077Z0R7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0R7"
  },
  {
    "uniprot": "A0A077ZNS5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNS5"
  },
  {
    "uniprot": "A0A077Z5K5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5K5"
  },
  {
    "uniprot": "A0A077ZMB4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMB4"
  },
  {
    "uniprot": "A0A077ZGX5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGX5"
  },
  {
    "uniprot": "A0A077ZDK8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDK8"
  },
  {
    "uniprot": "A0A077ZJI4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJI4"
  },
  {
    "uniprot": "A0A077ZLC9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLC9"
  },
  {
    "uniprot": "A0A077Z0P7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0P7"
  },
  {
    "uniprot": "A0A077Z051",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z051"
  },
  {
    "uniprot": "A0A077ZKL1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKL1"
  },
  {
    "uniprot": "A0A077ZNN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNN9"
  },
  {
    "uniprot": "A0A077ZIU0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIU0"
  },
  {
    "uniprot": "A0A077Z5Z6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Z6"
  },
  {
    "uniprot": "A0A077Z118",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z118"
  },
  {
    "uniprot": "A0A077Z1W4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1W4"
  },
  {
    "uniprot": "A0A077ZCZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCZ3"
  },
  {
    "uniprot": "A0A077ZDQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDQ0"
  },
  {
    "uniprot": "A0A077Z3V2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3V2"
  },
  {
    "uniprot": "A0A077ZE21",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE21"
  },
  {
    "uniprot": "A0A077ZA39",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA39"
  },
  {
    "uniprot": "A0A077YXC0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXC0"
  },
  {
    "uniprot": "A0A077ZMI6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMI6"
  },
  {
    "uniprot": "A0A077Z4Q5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Q5"
  },
  {
    "uniprot": "A0A077ZJT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJT8"
  },
  {
    "uniprot": "A0A077ZRC8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZRC8"
  },
  {
    "uniprot": "A0A077Z0T4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0T4"
  },
  {
    "uniprot": "A0A077ZBT3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBT3"
  },
  {
    "uniprot": "A0A077Z1E0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1E0"
  },
  {
    "uniprot": "A0A077Z8U0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8U0"
  },
  {
    "uniprot": "A0A077Z2V0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2V0"
  },
  {
    "uniprot": "A0A077Z3W3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3W3"
  },
  {
    "uniprot": "A0A077ZPX5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPX5"
  },
  {
    "uniprot": "A0A077ZBC2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBC2"
  },
  {
    "uniprot": "A0A077ZLF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLF0"
  },
  {
    "uniprot": "A0A077YZ59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ59"
  },
  {
    "uniprot": "A0A077ZEP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEP4"
  },
  {
    "uniprot": "A0A077Z3M8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3M8"
  },
  {
    "uniprot": "A0A077Z6H1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6H1"
  },
  {
    "uniprot": "A0A077ZIN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIN1"
  },
  {
    "uniprot": "A0A077ZJR1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJR1"
  },
  {
    "uniprot": "A0A077ZHR7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHR7"
  },
  {
    "uniprot": "A0A077ZBS4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBS4"
  },
  {
    "uniprot": "A0A077YYI4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYI4"
  },
  {
    "uniprot": "A0A077ZLC5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLC5"
  },
  {
    "uniprot": "A0A077ZE49",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE49"
  },
  {
    "uniprot": "A0A077Z620",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z620"
  },
  {
    "uniprot": "A0A077Z852",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z852"
  },
  {
    "uniprot": "A0A077ZET3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZET3"
  },
  {
    "uniprot": "A0A077ZI41",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI41"
  },
  {
    "uniprot": "A0A077ZFI2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFI2"
  },
  {
    "uniprot": "A0A077ZP11",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP11"
  },
  {
    "uniprot": "A0A077Z8G2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8G2"
  },
  {
    "uniprot": "A0A077ZJU3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJU3"
  },
  {
    "uniprot": "A0A077Z896",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z896"
  },
  {
    "uniprot": "A0A077Z3K4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3K4"
  },
  {
    "uniprot": "A0A077Z9U0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9U0"
  },
  {
    "uniprot": "A0A077Z577",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z577"
  },
  {
    "uniprot": "A0A077ZG32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG32"
  },
  {
    "uniprot": "A0A077ZH41",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH41"
  },
  {
    "uniprot": "A0A077ZID7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZID7"
  },
  {
    "uniprot": "A0A077ZIL9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIL9"
  },
  {
    "uniprot": "A0A077ZGN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGN9"
  },
  {
    "uniprot": "A0A077ZKJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKJ8"
  },
  {
    "uniprot": "A0A077Z0D5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0D5"
  },
  {
    "uniprot": "A0A077ZHD1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHD1"
  },
  {
    "uniprot": "A0A077Z6M6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6M6"
  },
  {
    "uniprot": "A0A077ZBP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBP3"
  },
  {
    "uniprot": "A0A077ZBG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBG7"
  },
  {
    "uniprot": "A0A077YX07",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX07"
  },
  {
    "uniprot": "A0A077Z2A4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2A4"
  },
  {
    "uniprot": "A0A077ZLV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLV4"
  },
  {
    "uniprot": "A0A077ZPS0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPS0"
  },
  {
    "uniprot": "A0A077YWT2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWT2"
  },
  {
    "uniprot": "A0A077YZS2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZS2"
  },
  {
    "uniprot": "A0A077YZ25",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ25"
  },
  {
    "uniprot": "A0A077ZN97",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN97"
  },
  {
    "uniprot": "A0A077Z443",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z443"
  },
  {
    "uniprot": "A0A077YY81",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY81"
  },
  {
    "uniprot": "A0A077ZM77",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM77"
  },
  {
    "uniprot": "A0A077YV47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV47"
  },
  {
    "uniprot": "A0A077ZCG1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCG1"
  },
  {
    "uniprot": "A0A077Z9G5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9G5"
  },
  {
    "uniprot": "A0A077ZDZ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDZ0"
  },
  {
    "uniprot": "A0A077Z0D9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0D9"
  },
  {
    "uniprot": "A0A077ZLR5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLR5"
  },
  {
    "uniprot": "A0A077Z3I9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3I9"
  },
  {
    "uniprot": "A0A077Z5D3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5D3"
  },
  {
    "uniprot": "A0A077ZB34",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB34"
  },
  {
    "uniprot": "A0A077Z4Q6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Q6"
  },
  {
    "uniprot": "A0A077ZI28",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI28"
  },
  {
    "uniprot": "A0A077ZHZ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHZ0"
  },
  {
    "uniprot": "A0A077YYL2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYL2"
  },
  {
    "uniprot": "A0A077Z4A8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4A8"
  },
  {
    "uniprot": "A0A077Z509",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z509"
  },
  {
    "uniprot": "A0A077ZJ68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ68"
  },
  {
    "uniprot": "A0A077ZMU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMU5"
  },
  {
    "uniprot": "A0A077ZQN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQN1"
  },
  {
    "uniprot": "A0A077YXN5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXN5"
  },
  {
    "uniprot": "A0A077ZD75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD75"
  },
  {
    "uniprot": "A0A077Z941",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z941"
  },
  {
    "uniprot": "A0A077Z4D1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4D1"
  },
  {
    "uniprot": "A0A077Z6F8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6F8"
  },
  {
    "uniprot": "A0A077YXF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXF9"
  },
  {
    "uniprot": "A0A077ZG14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG14"
  },
  {
    "uniprot": "A0A077Z6Y4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Y4"
  },
  {
    "uniprot": "A0A077ZHU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHU5"
  },
  {
    "uniprot": "A0A077ZHH2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHH2"
  },
  {
    "uniprot": "A0A077ZH66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH66"
  },
  {
    "uniprot": "A0A077ZNU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNU9"
  },
  {
    "uniprot": "A0A077Z4V9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4V9"
  },
  {
    "uniprot": "A0A077ZGD5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGD5"
  },
  {
    "uniprot": "A0A077Z2W6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2W6"
  },
  {
    "uniprot": "A0A077ZLN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLN3"
  },
  {
    "uniprot": "A0A077Z5L2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5L2"
  },
  {
    "uniprot": "A0A077ZD93",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD93"
  },
  {
    "uniprot": "A0A077ZDP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDP4"
  },
  {
    "uniprot": "A0A077Z050",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z050"
  },
  {
    "uniprot": "A0A077Z3T7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3T7"
  },
  {
    "uniprot": "A0A077Z1X8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1X8"
  },
  {
    "uniprot": "A0A077Z7D0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7D0"
  },
  {
    "uniprot": "A0A077Z9T9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9T9"
  },
  {
    "uniprot": "A0A077Z9P3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9P3"
  },
  {
    "uniprot": "A0A077Z045",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z045"
  },
  {
    "uniprot": "A0A077Z8S0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8S0"
  },
  {
    "uniprot": "A0A077Z1Q0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Q0"
  },
  {
    "uniprot": "A0A077Z4C0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4C0"
  },
  {
    "uniprot": "A0A077Z8J1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8J1"
  },
  {
    "uniprot": "A0A077ZN75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN75"
  },
  {
    "uniprot": "A0A077Z707",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z707"
  },
  {
    "uniprot": "A0A077Z512",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z512"
  },
  {
    "uniprot": "A0A077ZIY2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIY2"
  },
  {
    "uniprot": "A0A077Z1K5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1K5"
  },
  {
    "uniprot": "A0A077Z481",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z481"
  },
  {
    "uniprot": "A0A077ZCI4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCI4"
  },
  {
    "uniprot": "A0A077ZBL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBL6"
  },
  {
    "uniprot": "A0A077Z3P1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3P1"
  },
  {
    "uniprot": "A0A077YXL8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXL8"
  },
  {
    "uniprot": "A0A077Z4S7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4S7"
  },
  {
    "uniprot": "A0A077ZH27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH27"
  },
  {
    "uniprot": "A0A077YYB8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYB8"
  },
  {
    "uniprot": "A0A077Z8R1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8R1"
  },
  {
    "uniprot": "A0A077ZLY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLY0"
  },
  {
    "uniprot": "A0A077YWQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWQ4"
  },
  {
    "uniprot": "A0A077ZFS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFS1"
  },
  {
    "uniprot": "A0A077Z897",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z897"
  },
  {
    "uniprot": "A0A077ZCZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCZ9"
  },
  {
    "uniprot": "A0A077Z5M1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5M1"
  },
  {
    "uniprot": "A0A077ZN94",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN94"
  },
  {
    "uniprot": "A0A077Z0W9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0W9"
  },
  {
    "uniprot": "A0A077ZAY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAY9"
  },
  {
    "uniprot": "A0A077ZAF2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAF2"
  },
  {
    "uniprot": "A0A077ZGU8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGU8"
  },
  {
    "uniprot": "A0A077Z241",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z241"
  },
  {
    "uniprot": "A0A077YZ03",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ03"
  },
  {
    "uniprot": "A0A077Z2C3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2C3"
  },
  {
    "uniprot": "A0A077ZKK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKK7"
  },
  {
    "uniprot": "A0A077ZHM3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHM3"
  },
  {
    "uniprot": "A0A077Z2Z7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Z7"
  },
  {
    "uniprot": "A0A077ZCQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCQ3"
  },
  {
    "uniprot": "A0A077ZGY5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGY5"
  },
  {
    "uniprot": "A0A077Z477",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z477"
  },
  {
    "uniprot": "A0A077ZHJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHJ5"
  },
  {
    "uniprot": "A0A077Z8M2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8M2"
  },
  {
    "uniprot": "A0A077ZCP1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCP1"
  },
  {
    "uniprot": "A0A077ZEQ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEQ7"
  },
  {
    "uniprot": "A0A077ZB69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB69"
  },
  {
    "uniprot": "A0A077YZG1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZG1"
  },
  {
    "uniprot": "A0A077Z6R7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6R7"
  },
  {
    "uniprot": "A0A077Z0K6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0K6"
  },
  {
    "uniprot": "A0A077Z7X0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7X0"
  },
  {
    "uniprot": "A0A077ZFS7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFS7"
  },
  {
    "uniprot": "A0A077Z8J0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8J0"
  },
  {
    "uniprot": "A0A077ZKJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKJ2"
  },
  {
    "uniprot": "A0A077ZFL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFL7"
  },
  {
    "uniprot": "A0A077ZBE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBE8"
  },
  {
    "uniprot": "A0A077ZAT0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAT0"
  },
  {
    "uniprot": "A0A077Z323",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z323"
  },
  {
    "uniprot": "A0A077YWR4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWR4"
  },
  {
    "uniprot": "A0A077ZI34",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI34"
  },
  {
    "uniprot": "A0A077Z6P9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6P9"
  },
  {
    "uniprot": "A0A077ZMI4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMI4"
  },
  {
    "uniprot": "A0A077ZIH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIH5"
  },
  {
    "uniprot": "A0A077ZC85",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC85"
  },
  {
    "uniprot": "A0A077Z4E7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4E7"
  },
  {
    "uniprot": "A0A077ZD50",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD50"
  },
  {
    "uniprot": "A0A077ZFC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFC3"
  },
  {
    "uniprot": "A0A077ZAD8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAD8"
  },
  {
    "uniprot": "A0A077ZLG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLG9"
  },
  {
    "uniprot": "A0A077ZDE0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDE0"
  },
  {
    "uniprot": "A0A077YVR1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVR1"
  },
  {
    "uniprot": "A0A077ZB62",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB62"
  },
  {
    "uniprot": "A0A077ZE14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE14"
  },
  {
    "uniprot": "A0A077Z5W6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5W6"
  },
  {
    "uniprot": "A0A077Z809",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z809"
  },
  {
    "uniprot": "A0A077ZLY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLY6"
  },
  {
    "uniprot": "A0A077ZKT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKT8"
  },
  {
    "uniprot": "A0A077ZL88",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL88"
  },
  {
    "uniprot": "A0A077Z8D8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8D8"
  },
  {
    "uniprot": "A0A077ZC23",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC23"
  },
  {
    "uniprot": "A0A077Z2C2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2C2"
  },
  {
    "uniprot": "A0A077Z0Q8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Q8"
  },
  {
    "uniprot": "A0A077Z6E1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6E1"
  },
  {
    "uniprot": "A0A077Z7J2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7J2"
  },
  {
    "uniprot": "A0A077ZHZ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHZ7"
  },
  {
    "uniprot": "A0A077ZN22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN22"
  },
  {
    "uniprot": "A0A077ZJT1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJT1"
  },
  {
    "uniprot": "A0A077ZFA3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFA3"
  },
  {
    "uniprot": "A0A077Z341",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z341"
  },
  {
    "uniprot": "A0A077ZNI0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNI0"
  },
  {
    "uniprot": "A0A077YZH0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZH0"
  },
  {
    "uniprot": "A0A077ZCU0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCU0"
  },
  {
    "uniprot": "A0A077ZAU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAU7"
  },
  {
    "uniprot": "A0A077ZAQ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAQ7"
  },
  {
    "uniprot": "A0A077ZC83",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC83"
  },
  {
    "uniprot": "A0A077Z745",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z745"
  },
  {
    "uniprot": "A0A077YZB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZB9"
  },
  {
    "uniprot": "A0A077ZLR4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLR4"
  },
  {
    "uniprot": "A0A077Z0V8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0V8"
  },
  {
    "uniprot": "A0A077ZA27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA27"
  },
  {
    "uniprot": "A0A077Z131",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z131"
  },
  {
    "uniprot": "A0A077Z4V2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4V2"
  },
  {
    "uniprot": "A0A077Z8T2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8T2"
  },
  {
    "uniprot": "A0A077YZ41",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ41"
  },
  {
    "uniprot": "A0A077ZJ80",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ80"
  },
  {
    "uniprot": "A0A077Z100",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z100"
  },
  {
    "uniprot": "A0A077ZCG4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCG4"
  },
  {
    "uniprot": "A0A077ZGX2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGX2"
  },
  {
    "uniprot": "A0A077ZM76",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM76"
  },
  {
    "uniprot": "A0A077Z3S4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3S4"
  },
  {
    "uniprot": "A0A077ZE28",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE28"
  },
  {
    "uniprot": "A0A077YWX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWX3"
  },
  {
    "uniprot": "A0A077YUL5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUL5"
  },
  {
    "uniprot": "A0A077Z9M7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9M7"
  },
  {
    "uniprot": "A0A077ZNP1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNP1"
  },
  {
    "uniprot": "A0A077YXG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXG7"
  },
  {
    "uniprot": "A0A077ZFM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFM7"
  },
  {
    "uniprot": "A0A077Z4L9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4L9"
  },
  {
    "uniprot": "A0A077Z4K7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4K7"
  },
  {
    "uniprot": "A0A077Z4V5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4V5"
  },
  {
    "uniprot": "A0A077ZCD3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCD3"
  },
  {
    "uniprot": "A0A077ZDD3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDD3"
  },
  {
    "uniprot": "A0A077ZEE7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEE7"
  },
  {
    "uniprot": "A0A077ZAP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAP4"
  },
  {
    "uniprot": "A0A077ZNU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNU5"
  },
  {
    "uniprot": "A0A077ZJ62",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ62"
  },
  {
    "uniprot": "A0A077Z7D7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7D7"
  },
  {
    "uniprot": "A0A077ZAX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAX0"
  },
  {
    "uniprot": "A0A077ZBU8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBU8"
  },
  {
    "uniprot": "A0A077ZL48",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL48"
  },
  {
    "uniprot": "A0A077ZGH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGH5"
  },
  {
    "uniprot": "A0A077Z6S9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6S9"
  },
  {
    "uniprot": "A0A077ZP74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP74"
  },
  {
    "uniprot": "A0A077ZC67",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC67"
  },
  {
    "uniprot": "A0A077Z6Q9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Q9"
  },
  {
    "uniprot": "A0A077YV57",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV57"
  },
  {
    "uniprot": "A0A077ZM99",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM99"
  },
  {
    "uniprot": "A0A077Z5F8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5F8"
  },
  {
    "uniprot": "A0A077ZK71",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK71"
  },
  {
    "uniprot": "A0A077Z1A6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1A6"
  },
  {
    "uniprot": "A0A077ZC00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC00"
  },
  {
    "uniprot": "A0A077ZG25",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG25"
  },
  {
    "uniprot": "A0A077YZC7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZC7"
  },
  {
    "uniprot": "A0A077ZMF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMF0"
  },
  {
    "uniprot": "A0A077Z1F0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1F0"
  },
  {
    "uniprot": "A0A077ZE43",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE43"
  },
  {
    "uniprot": "A0A077Z4X0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4X0"
  },
  {
    "uniprot": "A0A077Z3K7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3K7"
  },
  {
    "uniprot": "A0A077ZJG2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJG2"
  },
  {
    "uniprot": "A0A077ZK05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK05"
  },
  {
    "uniprot": "A0A077ZEV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEV4"
  },
  {
    "uniprot": "A0A077Z6X3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6X3"
  },
  {
    "uniprot": "A0A077ZF42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF42"
  },
  {
    "uniprot": "A0A077ZPD7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPD7"
  },
  {
    "uniprot": "A0A077ZE53",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE53"
  },
  {
    "uniprot": "A0A077YYQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYQ4"
  },
  {
    "uniprot": "A0A077ZDQ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDQ9"
  },
  {
    "uniprot": "A0A077Z001",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z001"
  },
  {
    "uniprot": "A0A077YZL4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZL4"
  },
  {
    "uniprot": "A0A077YXR8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXR8"
  },
  {
    "uniprot": "A0A077ZJP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJP0"
  },
  {
    "uniprot": "A0A077Z6H7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6H7"
  },
  {
    "uniprot": "A0A077ZD03",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD03"
  },
  {
    "uniprot": "A0A077Z476",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z476"
  },
  {
    "uniprot": "A0A077Z6Z9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Z9"
  },
  {
    "uniprot": "A0A077ZD40",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD40"
  },
  {
    "uniprot": "A0A077ZEK5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEK5"
  },
  {
    "uniprot": "A0A077Z2M2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2M2"
  },
  {
    "uniprot": "A0A077ZH36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH36"
  },
  {
    "uniprot": "A0A077ZE78",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE78"
  },
  {
    "uniprot": "A0A077Z3U5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3U5"
  },
  {
    "uniprot": "A0A077ZGC8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGC8"
  },
  {
    "uniprot": "A0A077YY65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY65"
  },
  {
    "uniprot": "A0A077YW46",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW46"
  },
  {
    "uniprot": "A0A077Z864",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z864"
  },
  {
    "uniprot": "A0A077ZD47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD47"
  },
  {
    "uniprot": "A0A077ZBF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBF4"
  },
  {
    "uniprot": "A0A077ZDA7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDA7"
  },
  {
    "uniprot": "A0A077Z9D2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9D2"
  },
  {
    "uniprot": "A0A077Z7S0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7S0"
  },
  {
    "uniprot": "A0A077ZIB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIB2"
  },
  {
    "uniprot": "A0A077Z422",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z422"
  },
  {
    "uniprot": "A0A077Z8W9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8W9"
  },
  {
    "uniprot": "A0A077ZFH0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFH0"
  },
  {
    "uniprot": "A0A077ZLH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLH3"
  },
  {
    "uniprot": "A0A077YW33",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW33"
  },
  {
    "uniprot": "A0A077ZLF3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLF3"
  },
  {
    "uniprot": "A0A077YX01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX01"
  },
  {
    "uniprot": "A0A077ZH84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH84"
  },
  {
    "uniprot": "A0A077Z766",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z766"
  },
  {
    "uniprot": "A0A077ZB48",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB48"
  },
  {
    "uniprot": "A0A077ZHG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHG9"
  },
  {
    "uniprot": "A0A077Z6B4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6B4"
  },
  {
    "uniprot": "A0A077ZBL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBL7"
  },
  {
    "uniprot": "A0A077YXL4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXL4"
  },
  {
    "uniprot": "A0A077ZG90",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG90"
  },
  {
    "uniprot": "A0A077ZCC6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCC6"
  },
  {
    "uniprot": "A0A077Z8N9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8N9"
  },
  {
    "uniprot": "A0A077Z282",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z282"
  },
  {
    "uniprot": "A0A077Z913",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z913"
  },
  {
    "uniprot": "A0A077ZKP1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKP1"
  },
  {
    "uniprot": "A0A077ZKD9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKD9"
  },
  {
    "uniprot": "A0A077Z4V3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4V3"
  },
  {
    "uniprot": "A0A077ZGE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGE8"
  },
  {
    "uniprot": "A0A077Z7L7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7L7"
  },
  {
    "uniprot": "A0A077ZAK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAK9"
  },
  {
    "uniprot": "A0A077ZKT2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKT2"
  },
  {
    "uniprot": "A0A077ZDY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDY6"
  },
  {
    "uniprot": "A0A077Z411",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z411"
  },
  {
    "uniprot": "A0A077ZME2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZME2"
  },
  {
    "uniprot": "A0A077Z3Y8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Y8"
  },
  {
    "uniprot": "A0A077Z7K5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7K5"
  },
  {
    "uniprot": "A0A077ZFG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFG3"
  },
  {
    "uniprot": "A0A077ZAL0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAL0"
  },
  {
    "uniprot": "A0A077ZEY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEY1"
  },
  {
    "uniprot": "A0A077YY48",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY48"
  },
  {
    "uniprot": "A0A077ZN77",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN77"
  },
  {
    "uniprot": "A0A077ZMM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMM7"
  },
  {
    "uniprot": "A0A077Z5U4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5U4"
  },
  {
    "uniprot": "A0A077YXH7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXH7"
  },
  {
    "uniprot": "A0A077ZCE4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCE4"
  },
  {
    "uniprot": "A0A077ZFZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFZ5"
  },
  {
    "uniprot": "A0A077ZA97",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA97"
  },
  {
    "uniprot": "A0A077Z9H4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9H4"
  },
  {
    "uniprot": "A0A077ZBV7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBV7"
  },
  {
    "uniprot": "A0A077ZG75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG75"
  },
  {
    "uniprot": "A0A077ZNE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNE2"
  },
  {
    "uniprot": "A0A077Z6E9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6E9"
  },
  {
    "uniprot": "A0A077YZY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZY4"
  },
  {
    "uniprot": "A0A077Z7T6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7T6"
  },
  {
    "uniprot": "A0A077ZFW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFW0"
  },
  {
    "uniprot": "A0A077YY60",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY60"
  },
  {
    "uniprot": "A0A077ZPB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPB0"
  },
  {
    "uniprot": "A0A077Z084",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z084"
  },
  {
    "uniprot": "A0A077Z1B4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1B4"
  },
  {
    "uniprot": "A0A077ZBU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBU5"
  },
  {
    "uniprot": "A0A077ZJY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJY0"
  },
  {
    "uniprot": "A0A077YY69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY69"
  },
  {
    "uniprot": "A0A077Z3R4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3R4"
  },
  {
    "uniprot": "A0A077Z2V6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2V6"
  },
  {
    "uniprot": "A0A077Z5W0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5W0"
  },
  {
    "uniprot": "A0A077YYZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYZ5"
  },
  {
    "uniprot": "A0A077ZR95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR95"
  },
  {
    "uniprot": "A0A077Z0F0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0F0"
  },
  {
    "uniprot": "A0A077Z3V8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3V8"
  },
  {
    "uniprot": "A0A077YZH2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZH2"
  },
  {
    "uniprot": "A0A077YUW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUW2"
  },
  {
    "uniprot": "A0A077ZGZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGZ3"
  },
  {
    "uniprot": "A0A077YVL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVL6"
  },
  {
    "uniprot": "A0A077ZBZ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBZ6"
  },
  {
    "uniprot": "A0A077ZCK1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCK1"
  },
  {
    "uniprot": "A0A077ZJY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJY4"
  },
  {
    "uniprot": "A0A077Z5U5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5U5"
  },
  {
    "uniprot": "A0A077ZH72",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH72"
  },
  {
    "uniprot": "A0A077ZCZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCZ1"
  },
  {
    "uniprot": "A0A077ZAW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAW5"
  },
  {
    "uniprot": "A0A077YXH6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXH6"
  },
  {
    "uniprot": "A0A077ZDA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDA5"
  },
  {
    "uniprot": "A0A077Z444",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z444"
  },
  {
    "uniprot": "A0A077Z5A1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5A1"
  },
  {
    "uniprot": "A0A077ZAI9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAI9"
  },
  {
    "uniprot": "A0A077ZLR9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLR9"
  },
  {
    "uniprot": "A0A077Z823",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z823"
  },
  {
    "uniprot": "A0A077Z3Z7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Z7"
  },
  {
    "uniprot": "A0A077YXT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXT9"
  },
  {
    "uniprot": "A0A077Z0Z3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Z3"
  },
  {
    "uniprot": "A0A077Z985",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z985"
  },
  {
    "uniprot": "A0A077Z6N7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6N7"
  },
  {
    "uniprot": "A0A077Z8B5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8B5"
  },
  {
    "uniprot": "A0A077Z1Q9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Q9"
  },
  {
    "uniprot": "A0A077Z0P2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0P2"
  },
  {
    "uniprot": "A0A077ZP71",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP71"
  },
  {
    "uniprot": "A0A077Z8R8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8R8"
  },
  {
    "uniprot": "A0A077Z3F8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3F8"
  },
  {
    "uniprot": "A0A077Z3Y3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Y3"
  },
  {
    "uniprot": "A0A077ZHJ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHJ6"
  },
  {
    "uniprot": "A0A077Z2E2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2E2"
  },
  {
    "uniprot": "A0A077ZM47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM47"
  },
  {
    "uniprot": "A0A077Z2Y0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Y0"
  },
  {
    "uniprot": "A0A077Z4M1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4M1"
  },
  {
    "uniprot": "A0A077Z1U0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1U0"
  },
  {
    "uniprot": "A0A077ZBU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBU7"
  },
  {
    "uniprot": "A0A077ZBB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBB2"
  },
  {
    "uniprot": "A0A077YUQ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUQ7"
  },
  {
    "uniprot": "A0A077Z8E2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8E2"
  },
  {
    "uniprot": "A0A077Z4B4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4B4"
  },
  {
    "uniprot": "A0A077ZL16",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL16"
  },
  {
    "uniprot": "A0A077ZCI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCI3"
  },
  {
    "uniprot": "A0A077Z7J5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7J5"
  },
  {
    "uniprot": "A0A077Z7P4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7P4"
  },
  {
    "uniprot": "A0A077Z3G3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3G3"
  },
  {
    "uniprot": "A0A077ZD06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD06"
  },
  {
    "uniprot": "A0A077ZHG1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHG1"
  },
  {
    "uniprot": "A0A077Z1M3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1M3"
  },
  {
    "uniprot": "A0A077Z944",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z944"
  },
  {
    "uniprot": "A0A077ZIN0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIN0"
  },
  {
    "uniprot": "A0A077Z2H9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2H9"
  },
  {
    "uniprot": "A0A077Z058",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z058"
  },
  {
    "uniprot": "A0A077ZJF5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJF5"
  },
  {
    "uniprot": "A0A077ZCN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCN1"
  },
  {
    "uniprot": "A0A077ZBX4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBX4"
  },
  {
    "uniprot": "A0A077Z2I4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2I4"
  },
  {
    "uniprot": "A0A077ZJ13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ13"
  },
  {
    "uniprot": "A0A077Z0J6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0J6"
  },
  {
    "uniprot": "A0A077ZF45",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF45"
  },
  {
    "uniprot": "A0A077Z6Q4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Q4"
  },
  {
    "uniprot": "A0A077ZGC9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGC9"
  },
  {
    "uniprot": "A0A077ZCK0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCK0"
  },
  {
    "uniprot": "A0A077Z715",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z715"
  },
  {
    "uniprot": "A0A077YZR9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZR9"
  },
  {
    "uniprot": "A0A077ZFL8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFL8"
  },
  {
    "uniprot": "A0A077Z692",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z692"
  },
  {
    "uniprot": "A0A077Z4M8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4M8"
  },
  {
    "uniprot": "A0A077YZM4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZM4"
  },
  {
    "uniprot": "A0A077Z5X2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5X2"
  },
  {
    "uniprot": "A0A077ZBA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBA1"
  },
  {
    "uniprot": "A0A077ZH62",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH62"
  },
  {
    "uniprot": "A0A077Z5W1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5W1"
  },
  {
    "uniprot": "A0A077Z9L3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9L3"
  },
  {
    "uniprot": "A0A077ZJW6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJW6"
  },
  {
    "uniprot": "A0A077YWL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWL6"
  },
  {
    "uniprot": "A0A077Z0G9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0G9"
  },
  {
    "uniprot": "A0A077ZHQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHQ5"
  },
  {
    "uniprot": "A0A077ZGM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGM8"
  },
  {
    "uniprot": "A0A077ZAU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAU4"
  },
  {
    "uniprot": "A0A077Z522",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z522"
  },
  {
    "uniprot": "A0A077Z4S2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4S2"
  },
  {
    "uniprot": "A0A077Z9N6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9N6"
  },
  {
    "uniprot": "A0A077ZKR1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKR1"
  },
  {
    "uniprot": "A0A077ZNY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNY4"
  },
  {
    "uniprot": "A0A077Z4I0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4I0"
  },
  {
    "uniprot": "A0A077YXF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXF4"
  },
  {
    "uniprot": "A0A077Z5L4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5L4"
  },
  {
    "uniprot": "A0A077YYX8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYX8"
  },
  {
    "uniprot": "A0A077Z295",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z295"
  },
  {
    "uniprot": "A0A077ZCH6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCH6"
  },
  {
    "uniprot": "A0A077Z5J4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5J4"
  },
  {
    "uniprot": "A0A077Z2F3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2F3"
  },
  {
    "uniprot": "A0A077Z6I2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6I2"
  },
  {
    "uniprot": "A0A077YW94",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW94"
  },
  {
    "uniprot": "A0A077Z4B2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4B2"
  },
  {
    "uniprot": "A0A077ZFR8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFR8"
  },
  {
    "uniprot": "A0A077Z8N0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8N0"
  },
  {
    "uniprot": "A0A077ZNU8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNU8"
  },
  {
    "uniprot": "A0A077Z2Y7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Y7"
  },
  {
    "uniprot": "A0A077Z192",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z192"
  },
  {
    "uniprot": "A0A077Z7E4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7E4"
  },
  {
    "uniprot": "A0A077ZEU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEU4"
  },
  {
    "uniprot": "A0A077Z6P2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6P2"
  },
  {
    "uniprot": "A0A077ZEK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEK3"
  },
  {
    "uniprot": "A0A077ZI37",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI37"
  },
  {
    "uniprot": "A0A077ZJ17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ17"
  },
  {
    "uniprot": "A0A077ZER9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZER9"
  },
  {
    "uniprot": "A0A077ZDX2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDX2"
  },
  {
    "uniprot": "A0A077Z3T9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3T9"
  },
  {
    "uniprot": "A0A077Z0Z1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Z1"
  },
  {
    "uniprot": "A0A077YYB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYB6"
  },
  {
    "uniprot": "A0A077Z908",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z908"
  },
  {
    "uniprot": "A0A077ZJR8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJR8"
  },
  {
    "uniprot": "A0A077Z4R9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4R9"
  },
  {
    "uniprot": "A0A077ZH42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH42"
  },
  {
    "uniprot": "A0A077ZL73",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL73"
  },
  {
    "uniprot": "A0A077ZDW4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDW4"
  },
  {
    "uniprot": "A0A077ZFF8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFF8"
  },
  {
    "uniprot": "A0A077YUZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUZ3"
  },
  {
    "uniprot": "A0A077ZKE1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKE1"
  },
  {
    "uniprot": "A0A077ZAX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAX6"
  },
  {
    "uniprot": "A0A077ZJK5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJK5"
  },
  {
    "uniprot": "A0A077ZGT7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGT7"
  },
  {
    "uniprot": "A0A077ZKI9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKI9"
  },
  {
    "uniprot": "A0A077ZM46",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM46"
  },
  {
    "uniprot": "A0A077Z4G1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4G1"
  },
  {
    "uniprot": "A0A077ZCY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCY0"
  },
  {
    "uniprot": "A0A077ZLU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLU5"
  },
  {
    "uniprot": "A0A077ZEV8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEV8"
  },
  {
    "uniprot": "A0A077ZNC7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNC7"
  },
  {
    "uniprot": "A0A077ZE69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE69"
  },
  {
    "uniprot": "A0A077Z679",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z679"
  },
  {
    "uniprot": "A0A077ZLJ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLJ4"
  },
  {
    "uniprot": "A0A077ZG29",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG29"
  },
  {
    "uniprot": "A0A077YYZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYZ4"
  },
  {
    "uniprot": "A0A077ZFH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFH3"
  },
  {
    "uniprot": "A0A077ZKP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKP9"
  },
  {
    "uniprot": "A0A077Z1K7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1K7"
  },
  {
    "uniprot": "A0A077YWP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWP8"
  },
  {
    "uniprot": "A0A077Z8M3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8M3"
  },
  {
    "uniprot": "A0A077Z1Y3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Y3"
  },
  {
    "uniprot": "A0A077ZB68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB68"
  },
  {
    "uniprot": "A0A077ZGL1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGL1"
  },
  {
    "uniprot": "A0A077YXW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXW2"
  },
  {
    "uniprot": "A0A077ZGY7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGY7"
  },
  {
    "uniprot": "A0A077Z5T7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5T7"
  },
  {
    "uniprot": "A0A077Z3Q5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Q5"
  },
  {
    "uniprot": "A0A077ZFE3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFE3"
  },
  {
    "uniprot": "A0A077ZF31",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF31"
  },
  {
    "uniprot": "A0A077ZLD4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLD4"
  },
  {
    "uniprot": "A0A077ZHV0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHV0"
  },
  {
    "uniprot": "A0A077ZL26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL26"
  },
  {
    "uniprot": "A0A077ZN84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN84"
  },
  {
    "uniprot": "A0A077Z0N9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0N9"
  },
  {
    "uniprot": "A0A077ZC30",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC30"
  },
  {
    "uniprot": "A0A077ZCP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCP0"
  },
  {
    "uniprot": "A0A077ZK23",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK23"
  },
  {
    "uniprot": "A0A077ZEX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEX7"
  },
  {
    "uniprot": "A0A077ZB92",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB92"
  },
  {
    "uniprot": "A0A077ZFG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFG7"
  },
  {
    "uniprot": "A0A077YVE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVE8"
  },
  {
    "uniprot": "A0A077Z8V2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8V2"
  },
  {
    "uniprot": "A0A077Z607",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z607"
  },
  {
    "uniprot": "A0A077ZBV0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBV0"
  },
  {
    "uniprot": "A0A077ZB17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB17"
  },
  {
    "uniprot": "A0A077YWN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWN7"
  },
  {
    "uniprot": "A0A077ZGF2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGF2"
  },
  {
    "uniprot": "A0A077YYM0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYM0"
  },
  {
    "uniprot": "A0A077Z0P9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0P9"
  },
  {
    "uniprot": "A0A077YYD8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYD8"
  },
  {
    "uniprot": "A0A077ZMX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMX0"
  },
  {
    "uniprot": "A0A077ZK08",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK08"
  },
  {
    "uniprot": "A0A077ZEF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEF9"
  },
  {
    "uniprot": "A0A077YWW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWW0"
  },
  {
    "uniprot": "A0A077Z3J2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3J2"
  },
  {
    "uniprot": "A0A077Z249",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z249"
  },
  {
    "uniprot": "A0A077ZI48",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI48"
  },
  {
    "uniprot": "A0A077ZBU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBU4"
  },
  {
    "uniprot": "A0A077YUZ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUZ7"
  },
  {
    "uniprot": "A0A077YY43",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY43"
  },
  {
    "uniprot": "A0A077Z2Y2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Y2"
  },
  {
    "uniprot": "A0A077ZHH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHH8"
  },
  {
    "uniprot": "A0A077Z316",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z316"
  },
  {
    "uniprot": "A0A077Z7H9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7H9"
  },
  {
    "uniprot": "A0A077ZG81",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG81"
  },
  {
    "uniprot": "A0A077YX24",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX24"
  },
  {
    "uniprot": "A0A077Z255",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z255"
  },
  {
    "uniprot": "A0A077Z046",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z046"
  },
  {
    "uniprot": "A0A077ZEH6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEH6"
  },
  {
    "uniprot": "A0A077ZHB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHB2"
  },
  {
    "uniprot": "A0A077ZGS3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGS3"
  },
  {
    "uniprot": "A0A077Z1N0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1N0"
  },
  {
    "uniprot": "A0A077Z9R4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9R4"
  },
  {
    "uniprot": "A0A077ZKH7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKH7"
  },
  {
    "uniprot": "A0A077ZG69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG69"
  },
  {
    "uniprot": "A0A077Z4H4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4H4"
  },
  {
    "uniprot": "A0A077Z2A1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2A1"
  },
  {
    "uniprot": "A0A077ZD27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD27"
  },
  {
    "uniprot": "A0A077Z2G3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2G3"
  },
  {
    "uniprot": "A0A077Z8J9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8J9"
  },
  {
    "uniprot": "A0A077ZMZ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMZ2"
  },
  {
    "uniprot": "A0A077ZGS5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGS5"
  },
  {
    "uniprot": "A0A077ZGH2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGH2"
  },
  {
    "uniprot": "A0A077ZI30",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI30"
  },
  {
    "uniprot": "A0A077ZHD7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHD7"
  },
  {
    "uniprot": "A0A077Z9Y9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Y9"
  },
  {
    "uniprot": "A0A077ZK06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK06"
  },
  {
    "uniprot": "A0A077Z5X3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5X3"
  },
  {
    "uniprot": "A0A077YWG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWG3"
  },
  {
    "uniprot": "A0A077ZB53",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB53"
  },
  {
    "uniprot": "A0A077YVM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVM8"
  },
  {
    "uniprot": "A0A077ZBY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBY4"
  },
  {
    "uniprot": "A0A077ZDA4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDA4"
  },
  {
    "uniprot": "A0A077ZH35",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH35"
  },
  {
    "uniprot": "A0A077Z7G2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7G2"
  },
  {
    "uniprot": "A0A077ZBE9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBE9"
  },
  {
    "uniprot": "A0A077YWU8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWU8"
  },
  {
    "uniprot": "A0A077ZGA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGA1"
  },
  {
    "uniprot": "A0A077YX56",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX56"
  },
  {
    "uniprot": "A0A077ZBE7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBE7"
  },
  {
    "uniprot": "A0A077Z2X7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2X7"
  },
  {
    "uniprot": "A0A077Z2J3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2J3"
  },
  {
    "uniprot": "A0A077ZH52",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH52"
  },
  {
    "uniprot": "A0A077YV83",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV83"
  },
  {
    "uniprot": "A0A077Z095",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z095"
  },
  {
    "uniprot": "A0A077YWG4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWG4"
  },
  {
    "uniprot": "A0A077Z281",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z281"
  },
  {
    "uniprot": "A0A077Z816",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z816"
  },
  {
    "uniprot": "A0A077YXI9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXI9"
  },
  {
    "uniprot": "A0A077ZBC0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBC0"
  },
  {
    "uniprot": "A0A077ZLH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLH1"
  },
  {
    "uniprot": "A0A077Z4J2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4J2"
  },
  {
    "uniprot": "A0A077ZFK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFK6"
  },
  {
    "uniprot": "A0A077Z936",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z936"
  },
  {
    "uniprot": "A0A077Z1B1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1B1"
  },
  {
    "uniprot": "A0A077ZA45",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA45"
  },
  {
    "uniprot": "A0A077ZLN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLN7"
  },
  {
    "uniprot": "A0A077ZDA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDA8"
  },
  {
    "uniprot": "A0A077YY83",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY83"
  },
  {
    "uniprot": "A0A077Z9W2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9W2"
  },
  {
    "uniprot": "A0A077YZR5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZR5"
  },
  {
    "uniprot": "A0A077YVR5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVR5"
  },
  {
    "uniprot": "A0A077ZG61",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG61"
  },
  {
    "uniprot": "A0A077Z5Y9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Y9"
  },
  {
    "uniprot": "A0A077ZDM4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDM4"
  },
  {
    "uniprot": "A0A077ZGD0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGD0"
  },
  {
    "uniprot": "A0A077ZIJ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIJ9"
  },
  {
    "uniprot": "A0A077Z1R5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1R5"
  },
  {
    "uniprot": "A0A077Z797",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z797"
  },
  {
    "uniprot": "A0A077ZMY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMY1"
  },
  {
    "uniprot": "A0A077ZAT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAT9"
  },
  {
    "uniprot": "A0A077ZC48",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC48"
  },
  {
    "uniprot": "A0A077Z611",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z611"
  },
  {
    "uniprot": "A0A077ZA05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA05"
  },
  {
    "uniprot": "A0A077ZBR4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBR4"
  },
  {
    "uniprot": "A0A077Z839",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z839"
  },
  {
    "uniprot": "A0A077ZK17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK17"
  },
  {
    "uniprot": "A0A077ZEF8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEF8"
  },
  {
    "uniprot": "A0A077Z3U2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3U2"
  },
  {
    "uniprot": "A0A077ZCS3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCS3"
  },
  {
    "uniprot": "A0A077Z2D9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2D9"
  },
  {
    "uniprot": "A0A077ZM90",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM90"
  },
  {
    "uniprot": "A0A077ZFA2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFA2"
  },
  {
    "uniprot": "A0A077ZIC5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIC5"
  },
  {
    "uniprot": "A0A077Z639",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z639"
  },
  {
    "uniprot": "A0A077ZIK5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIK5"
  },
  {
    "uniprot": "A0A077Z056",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z056"
  },
  {
    "uniprot": "A0A077YYN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYN9"
  },
  {
    "uniprot": "A0A077YZ53",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ53"
  },
  {
    "uniprot": "A0A077Z6V7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6V7"
  },
  {
    "uniprot": "A0A077Z5I2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5I2"
  },
  {
    "uniprot": "A0A077ZPC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPC3"
  },
  {
    "uniprot": "A0A077ZFA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFA5"
  },
  {
    "uniprot": "A0A077Z188",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z188"
  },
  {
    "uniprot": "A0A077ZNX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNX7"
  },
  {
    "uniprot": "A0A077ZET8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZET8"
  },
  {
    "uniprot": "A0A077Z9E1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9E1"
  },
  {
    "uniprot": "A0A077Z9G3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9G3"
  },
  {
    "uniprot": "A0A077Z2L8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2L8"
  },
  {
    "uniprot": "A0A077ZAZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAZ5"
  },
  {
    "uniprot": "A0A077ZLI4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLI4"
  },
  {
    "uniprot": "A0A077YXI5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXI5"
  },
  {
    "uniprot": "A0A077ZDS5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDS5"
  },
  {
    "uniprot": "A0A077Z1N6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1N6"
  },
  {
    "uniprot": "A0A077ZLV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLV9"
  },
  {
    "uniprot": "A0A077Z8U8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8U8"
  },
  {
    "uniprot": "A0A077Z450",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z450"
  },
  {
    "uniprot": "A0A077Z633",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z633"
  },
  {
    "uniprot": "A0A077Z859",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z859"
  },
  {
    "uniprot": "A0A077YWY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWY1"
  },
  {
    "uniprot": "A0A077Z157",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z157"
  },
  {
    "uniprot": "A0A077YVJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVJ0"
  },
  {
    "uniprot": "A0A077ZQI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQI8"
  },
  {
    "uniprot": "A0A077ZFX2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFX2"
  },
  {
    "uniprot": "A0A077Z9A0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9A0"
  },
  {
    "uniprot": "A0A077Z075",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z075"
  },
  {
    "uniprot": "A0A077ZGV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGV3"
  },
  {
    "uniprot": "A0A077Z098",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z098"
  },
  {
    "uniprot": "A0A077Z7I3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7I3"
  },
  {
    "uniprot": "A0A077ZF22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF22"
  },
  {
    "uniprot": "A0A077YZ05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ05"
  },
  {
    "uniprot": "A0A077Z254",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z254"
  },
  {
    "uniprot": "A0A077ZKW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKW5"
  },
  {
    "uniprot": "A0A077ZJW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJW2"
  },
  {
    "uniprot": "A0A077ZIX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIX6"
  },
  {
    "uniprot": "A0A077ZAE9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAE9"
  },
  {
    "uniprot": "A0A077YUL9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUL9"
  },
  {
    "uniprot": "A0A077Z4N6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4N6"
  },
  {
    "uniprot": "A0A077ZD38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD38"
  },
  {
    "uniprot": "A0A077Z775",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z775"
  },
  {
    "uniprot": "A0A077ZBU0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBU0"
  },
  {
    "uniprot": "A0A077ZM33",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM33"
  },
  {
    "uniprot": "A0A077YZ52",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ52"
  },
  {
    "uniprot": "A0A077Z2Q0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Q0"
  },
  {
    "uniprot": "A0A077YY68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY68"
  },
  {
    "uniprot": "A0A077ZCS8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCS8"
  },
  {
    "uniprot": "A0A077ZD55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD55"
  },
  {
    "uniprot": "A0A077ZCU3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCU3"
  },
  {
    "uniprot": "A0A077YVE1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVE1"
  },
  {
    "uniprot": "A0A077YY52",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY52"
  },
  {
    "uniprot": "A0A077YZF3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZF3"
  },
  {
    "uniprot": "A0A077Z342",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z342"
  },
  {
    "uniprot": "A0A077ZJQ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJQ7"
  },
  {
    "uniprot": "A0A077ZMZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMZ3"
  },
  {
    "uniprot": "A0A077Z8C2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8C2"
  },
  {
    "uniprot": "A0A077ZEZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEZ4"
  },
  {
    "uniprot": "A0A077ZH61",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH61"
  },
  {
    "uniprot": "A0A077Z507",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z507"
  },
  {
    "uniprot": "A0A077ZMD0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMD0"
  },
  {
    "uniprot": "A0A077Z1J3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1J3"
  },
  {
    "uniprot": "A0A077ZMT0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMT0"
  },
  {
    "uniprot": "A0A077ZJV0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJV0"
  },
  {
    "uniprot": "A0A077ZNP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNP5"
  },
  {
    "uniprot": "A0A077Z0L8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0L8"
  },
  {
    "uniprot": "A0A077ZDG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDG5"
  },
  {
    "uniprot": "A0A077ZKZ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKZ0"
  },
  {
    "uniprot": "A0A077Z0N3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0N3"
  },
  {
    "uniprot": "A0A077Z3D5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3D5"
  },
  {
    "uniprot": "A0A077Z6R1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6R1"
  },
  {
    "uniprot": "A0A077ZHC5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHC5"
  },
  {
    "uniprot": "A0A077ZMY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMY0"
  },
  {
    "uniprot": "A0A077ZEK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEK9"
  },
  {
    "uniprot": "A0A077ZL44",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL44"
  },
  {
    "uniprot": "A0A077ZAY7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAY7"
  },
  {
    "uniprot": "A0A077ZH22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH22"
  },
  {
    "uniprot": "A0A077ZBJ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBJ9"
  },
  {
    "uniprot": "A0A077Z5R4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5R4"
  },
  {
    "uniprot": "A0A077ZJS6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJS6"
  },
  {
    "uniprot": "A0A077Z7Z6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Z6"
  },
  {
    "uniprot": "A0A077ZK11",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK11"
  },
  {
    "uniprot": "A0A077ZDU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDU9"
  },
  {
    "uniprot": "A0A077ZH29",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH29"
  },
  {
    "uniprot": "A0A077Z529",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z529"
  },
  {
    "uniprot": "A0A077ZKC7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKC7"
  },
  {
    "uniprot": "A0A077YUR7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUR7"
  },
  {
    "uniprot": "A0A077Z2H0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2H0"
  },
  {
    "uniprot": "A0A077ZKU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKU5"
  },
  {
    "uniprot": "A0A077YYQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYQ0"
  },
  {
    "uniprot": "A0A077Z2R7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2R7"
  },
  {
    "uniprot": "A0A077Z644",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z644"
  },
  {
    "uniprot": "A0A077ZH79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH79"
  },
  {
    "uniprot": "A0A077ZGN8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGN8"
  },
  {
    "uniprot": "A0A077Z8A6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8A6"
  },
  {
    "uniprot": "A0A077ZI71",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI71"
  },
  {
    "uniprot": "A0A077ZKB7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKB7"
  },
  {
    "uniprot": "A0A077ZF24",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF24"
  },
  {
    "uniprot": "A0A077Z263",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z263"
  },
  {
    "uniprot": "A0A077ZCQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCQ2"
  },
  {
    "uniprot": "A0A077Z1J0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1J0"
  },
  {
    "uniprot": "A0A077Z7I7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7I7"
  },
  {
    "uniprot": "A0A077Z5D1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5D1"
  },
  {
    "uniprot": "A0A077ZDF7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDF7"
  },
  {
    "uniprot": "A0A077Z8V6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8V6"
  },
  {
    "uniprot": "A0A077ZI08",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI08"
  },
  {
    "uniprot": "A0A077ZKL2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKL2"
  },
  {
    "uniprot": "A0A077Z5T9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5T9"
  },
  {
    "uniprot": "A0A077YWN0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWN0"
  },
  {
    "uniprot": "A0A077ZJ10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ10"
  },
  {
    "uniprot": "A0A077ZKR9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKR9"
  },
  {
    "uniprot": "A0A077ZJ99",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ99"
  },
  {
    "uniprot": "A0A077ZF43",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF43"
  },
  {
    "uniprot": "A0A077Z3C2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3C2"
  },
  {
    "uniprot": "A0A077ZG95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG95"
  },
  {
    "uniprot": "A0A077Z2P8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2P8"
  },
  {
    "uniprot": "A0A077Z1L5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1L5"
  },
  {
    "uniprot": "A0A077ZL06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL06"
  },
  {
    "uniprot": "A0A077ZB76",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB76"
  },
  {
    "uniprot": "A0A077ZAC7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAC7"
  },
  {
    "uniprot": "A0A077YW85",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW85"
  },
  {
    "uniprot": "A0A077ZJR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJR6"
  },
  {
    "uniprot": "A0A077ZG36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG36"
  },
  {
    "uniprot": "A0A077ZB93",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB93"
  },
  {
    "uniprot": "A0A077ZHS3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHS3"
  },
  {
    "uniprot": "A0A077ZDD5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDD5"
  },
  {
    "uniprot": "A0A077YZY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZY6"
  },
  {
    "uniprot": "A0A077Z8G8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8G8"
  },
  {
    "uniprot": "A0A077ZCB7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCB7"
  },
  {
    "uniprot": "A0A077Z677",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z677"
  },
  {
    "uniprot": "A0A077ZIX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIX0"
  },
  {
    "uniprot": "A0A077ZDU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDU2"
  },
  {
    "uniprot": "A0A077ZNW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNW5"
  },
  {
    "uniprot": "A0A077ZET6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZET6"
  },
  {
    "uniprot": "A0A077YXP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXP0"
  },
  {
    "uniprot": "A0A077ZJK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJK4"
  },
  {
    "uniprot": "A0A077Z3R8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3R8"
  },
  {
    "uniprot": "A0A077Z9F3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9F3"
  },
  {
    "uniprot": "A0A077Z8I1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8I1"
  },
  {
    "uniprot": "A0A077Z6R2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6R2"
  },
  {
    "uniprot": "A0A077YZI9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZI9"
  },
  {
    "uniprot": "A0A077ZF84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF84"
  },
  {
    "uniprot": "A0A077ZJR0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJR0"
  },
  {
    "uniprot": "A0A077Z990",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z990"
  },
  {
    "uniprot": "A0A077ZKX8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKX8"
  },
  {
    "uniprot": "A0A077Z9P0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9P0"
  },
  {
    "uniprot": "A0A077Z3I2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3I2"
  },
  {
    "uniprot": "A0A077Z442",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z442"
  },
  {
    "uniprot": "A0A077YV64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV64"
  },
  {
    "uniprot": "A0A077ZH05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH05"
  },
  {
    "uniprot": "A0A077Z5P9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5P9"
  },
  {
    "uniprot": "A0A077ZFX4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFX4"
  },
  {
    "uniprot": "A0A077YWI1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWI1"
  },
  {
    "uniprot": "A0A077ZCS2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCS2"
  },
  {
    "uniprot": "A0A077YZC9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZC9"
  },
  {
    "uniprot": "A0A077ZA65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA65"
  },
  {
    "uniprot": "A0A077Z7W3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7W3"
  },
  {
    "uniprot": "A0A077ZPK1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPK1"
  },
  {
    "uniprot": "A0A077ZQE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQE2"
  },
  {
    "uniprot": "A0A077ZKD4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKD4"
  },
  {
    "uniprot": "A0A077Z7I5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7I5"
  },
  {
    "uniprot": "A0A077Z2E1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2E1"
  },
  {
    "uniprot": "A0A077YY74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY74"
  },
  {
    "uniprot": "A0A077YVN8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVN8"
  },
  {
    "uniprot": "A0A077Z8U1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8U1"
  },
  {
    "uniprot": "A0A077ZBG0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBG0"
  },
  {
    "uniprot": "A0A077ZM72",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM72"
  },
  {
    "uniprot": "A0A077Z6N1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6N1"
  },
  {
    "uniprot": "A0A077ZKK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKK4"
  },
  {
    "uniprot": "A0A077Z7V3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7V3"
  },
  {
    "uniprot": "A0A077ZA70",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA70"
  },
  {
    "uniprot": "A0A077ZNA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNA5"
  },
  {
    "uniprot": "A0A077YXR5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXR5"
  },
  {
    "uniprot": "A0A077ZCS0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCS0"
  },
  {
    "uniprot": "A0A077ZF92",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF92"
  },
  {
    "uniprot": "A0A077ZEP1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEP1"
  },
  {
    "uniprot": "A0A077Z9U6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9U6"
  },
  {
    "uniprot": "A0A077Z214",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z214"
  },
  {
    "uniprot": "A0A077YZF2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZF2"
  },
  {
    "uniprot": "A0A077ZBR2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBR2"
  },
  {
    "uniprot": "A0A077ZGL3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGL3"
  },
  {
    "uniprot": "A0A077Z6V9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6V9"
  },
  {
    "uniprot": "A0A077Z720",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z720"
  },
  {
    "uniprot": "A0A077YW34",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW34"
  },
  {
    "uniprot": "A0A077Z8I9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8I9"
  },
  {
    "uniprot": "A0A077ZD82",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD82"
  },
  {
    "uniprot": "A0A077Z6L6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6L6"
  },
  {
    "uniprot": "A0A077ZEN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEN4"
  },
  {
    "uniprot": "A0A077ZAL9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAL9"
  },
  {
    "uniprot": "A0A077Z828",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z828"
  },
  {
    "uniprot": "A0A077YZN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZN6"
  },
  {
    "uniprot": "A0A077ZKF3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKF3"
  },
  {
    "uniprot": "A0A077Z9W3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9W3"
  },
  {
    "uniprot": "A0A077ZKS3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKS3"
  },
  {
    "uniprot": "A0A077Z211",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z211"
  },
  {
    "uniprot": "A0A077Z3B3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3B3"
  },
  {
    "uniprot": "A0A077Z6W4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6W4"
  },
  {
    "uniprot": "A0A077Z0R5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0R5"
  },
  {
    "uniprot": "A0A077Z5N9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5N9"
  },
  {
    "uniprot": "A0A077Z1X5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1X5"
  },
  {
    "uniprot": "A0A077YYD7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYD7"
  },
  {
    "uniprot": "A0A077Z8R6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8R6"
  },
  {
    "uniprot": "A0A077Z8H5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8H5"
  },
  {
    "uniprot": "A0A077ZGB5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGB5"
  },
  {
    "uniprot": "A0A077ZHB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHB0"
  },
  {
    "uniprot": "A0A077ZIB7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIB7"
  },
  {
    "uniprot": "A0A077Z9P2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9P2"
  },
  {
    "uniprot": "A0A077ZH86",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH86"
  },
  {
    "uniprot": "A0A077Z1Y9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Y9"
  },
  {
    "uniprot": "A0A077Z3M4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3M4"
  },
  {
    "uniprot": "A0A077Z6R3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6R3"
  },
  {
    "uniprot": "A0A077YZE9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZE9"
  },
  {
    "uniprot": "A0A077Z9L6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9L6"
  },
  {
    "uniprot": "A0A077Z6W6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6W6"
  },
  {
    "uniprot": "A0A077ZDZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDZ9"
  },
  {
    "uniprot": "A0A077YY23",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY23"
  },
  {
    "uniprot": "A0A077Z1A5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1A5"
  },
  {
    "uniprot": "A0A077ZHX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHX7"
  },
  {
    "uniprot": "A0A077ZLB5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLB5"
  },
  {
    "uniprot": "A0A077YZJ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZJ1"
  },
  {
    "uniprot": "A0A077YX60",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX60"
  },
  {
    "uniprot": "A0A077ZAZ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAZ2"
  },
  {
    "uniprot": "A0A077Z7Y9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Y9"
  },
  {
    "uniprot": "A0A077Z1W0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1W0"
  },
  {
    "uniprot": "A0A077ZJ52",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ52"
  },
  {
    "uniprot": "A0A077ZCT0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCT0"
  },
  {
    "uniprot": "A0A077YWE1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWE1"
  },
  {
    "uniprot": "A0A077YV01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV01"
  },
  {
    "uniprot": "A0A077ZH23",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH23"
  },
  {
    "uniprot": "A0A077YW87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW87"
  },
  {
    "uniprot": "A0A077ZNJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNJ5"
  },
  {
    "uniprot": "A0A077Z0V1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0V1"
  },
  {
    "uniprot": "A0A077ZA09",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA09"
  },
  {
    "uniprot": "A0A077YWZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWZ8"
  },
  {
    "uniprot": "A0A077Z573",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z573"
  },
  {
    "uniprot": "A0A077Z5P5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5P5"
  },
  {
    "uniprot": "A0A077Z924",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z924"
  },
  {
    "uniprot": "A0A077YZN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZN2"
  },
  {
    "uniprot": "A0A077Z894",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z894"
  },
  {
    "uniprot": "A0A077ZKZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKZ5"
  },
  {
    "uniprot": "A0A077ZME1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZME1"
  },
  {
    "uniprot": "A0A077ZBH7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBH7"
  },
  {
    "uniprot": "A0A077YXP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXP3"
  },
  {
    "uniprot": "A0A077ZAB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAB0"
  },
  {
    "uniprot": "A0A077Z0X5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0X5"
  },
  {
    "uniprot": "A0A077Z7P1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7P1"
  },
  {
    "uniprot": "A0A077ZAX5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAX5"
  },
  {
    "uniprot": "A0A077ZF52",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF52"
  },
  {
    "uniprot": "A0A077Z279",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z279"
  },
  {
    "uniprot": "A0A077Z1D8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1D8"
  },
  {
    "uniprot": "A0A077Z2L5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2L5"
  },
  {
    "uniprot": "A0A077ZGE7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGE7"
  },
  {
    "uniprot": "A0A077ZPB3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPB3"
  },
  {
    "uniprot": "A0A077ZK40",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK40"
  },
  {
    "uniprot": "A0A077Z4Q0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Q0"
  },
  {
    "uniprot": "A0A077ZED5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZED5"
  },
  {
    "uniprot": "A0A077ZNS3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNS3"
  },
  {
    "uniprot": "A0A077Z9T2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9T2"
  },
  {
    "uniprot": "A0A077Z4P2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4P2"
  },
  {
    "uniprot": "A0A077Z2Z0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Z0"
  },
  {
    "uniprot": "A0A077ZFF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFF0"
  },
  {
    "uniprot": "A0A077Z3U0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3U0"
  },
  {
    "uniprot": "A0A077Z9V1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9V1"
  },
  {
    "uniprot": "A0A077ZKM3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKM3"
  },
  {
    "uniprot": "A0A077ZIE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIE8"
  },
  {
    "uniprot": "A0A077Z6Q3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Q3"
  },
  {
    "uniprot": "A0A077YWK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWK6"
  },
  {
    "uniprot": "A0A077YX52",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX52"
  },
  {
    "uniprot": "A0A077ZIQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIQ3"
  },
  {
    "uniprot": "A0A077ZJZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJZ1"
  },
  {
    "uniprot": "A0A077ZFE4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFE4"
  },
  {
    "uniprot": "A0A077Z2E6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2E6"
  },
  {
    "uniprot": "A0A077ZFF3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFF3"
  },
  {
    "uniprot": "A0A077ZKW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKW1"
  },
  {
    "uniprot": "A0A077ZI53",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI53"
  },
  {
    "uniprot": "A0A077ZCW6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCW6"
  },
  {
    "uniprot": "A0A077ZFW4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFW4"
  },
  {
    "uniprot": "A0A077Z9C5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9C5"
  },
  {
    "uniprot": "A0A077Z5J9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5J9"
  },
  {
    "uniprot": "A0A077ZM65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM65"
  },
  {
    "uniprot": "A0A077ZNA9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNA9"
  },
  {
    "uniprot": "A0A077ZE09",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE09"
  },
  {
    "uniprot": "A0A077Z736",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z736"
  },
  {
    "uniprot": "A0A077ZAV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAV3"
  },
  {
    "uniprot": "A0A077ZFD5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFD5"
  },
  {
    "uniprot": "A0A077ZJY5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJY5"
  },
  {
    "uniprot": "A0A077ZJZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJZ9"
  },
  {
    "uniprot": "A0A077ZG31",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG31"
  },
  {
    "uniprot": "A0A077Z3W1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3W1"
  },
  {
    "uniprot": "A0A077ZCG6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCG6"
  },
  {
    "uniprot": "A0A077ZI06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI06"
  },
  {
    "uniprot": "A0A077Z6Y0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Y0"
  },
  {
    "uniprot": "A0A077YXV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXV9"
  },
  {
    "uniprot": "A0A077ZJ21",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ21"
  },
  {
    "uniprot": "A0A077YWJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWJ5"
  },
  {
    "uniprot": "A0A077ZD71",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD71"
  },
  {
    "uniprot": "A0A077ZDV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDV3"
  },
  {
    "uniprot": "A0A077Z866",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z866"
  },
  {
    "uniprot": "A0A077ZHG2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHG2"
  },
  {
    "uniprot": "A0A077YXU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXU2"
  },
  {
    "uniprot": "A0A077ZH81",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH81"
  },
  {
    "uniprot": "A0A077YWS3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWS3"
  },
  {
    "uniprot": "A0A077Z298",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z298"
  },
  {
    "uniprot": "A0A077YZL0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZL0"
  },
  {
    "uniprot": "A0A077ZBL4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBL4"
  },
  {
    "uniprot": "A0A077ZFI5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFI5"
  },
  {
    "uniprot": "A0A077ZG02",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG02"
  },
  {
    "uniprot": "A0A077ZFL2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFL2"
  },
  {
    "uniprot": "A0A077Z5H1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5H1"
  },
  {
    "uniprot": "A0A077ZMA9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMA9"
  },
  {
    "uniprot": "A0A077ZK99",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK99"
  },
  {
    "uniprot": "A0A077ZLR8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLR8"
  },
  {
    "uniprot": "A0A077Z2W3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2W3"
  },
  {
    "uniprot": "A0A077Z1B5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1B5"
  },
  {
    "uniprot": "A0A077ZF74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF74"
  },
  {
    "uniprot": "A0A077Z5K7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5K7"
  },
  {
    "uniprot": "A0A077YV51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV51"
  },
  {
    "uniprot": "A0A077YVX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVX7"
  },
  {
    "uniprot": "A0A077ZBQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBQ2"
  },
  {
    "uniprot": "A0A077ZHN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHN1"
  },
  {
    "uniprot": "A0A077ZLE6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLE6"
  },
  {
    "uniprot": "A0A077ZLM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLM8"
  },
  {
    "uniprot": "A0A077Z7N4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7N4"
  },
  {
    "uniprot": "A0A077ZG77",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG77"
  },
  {
    "uniprot": "A0A077ZFG6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFG6"
  },
  {
    "uniprot": "A0A077ZAY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAY6"
  },
  {
    "uniprot": "A0A077ZCQ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCQ1"
  },
  {
    "uniprot": "A0A077ZLY5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLY5"
  },
  {
    "uniprot": "A0A077ZIW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIW0"
  },
  {
    "uniprot": "A0A077ZJ36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ36"
  },
  {
    "uniprot": "A0A077ZKE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKE2"
  },
  {
    "uniprot": "A0A077Z8Z8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Z8"
  },
  {
    "uniprot": "A0A077Z772",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z772"
  },
  {
    "uniprot": "A0A077Z2J7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2J7"
  },
  {
    "uniprot": "A0A077Z454",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z454"
  },
  {
    "uniprot": "A0A077ZBH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBH1"
  },
  {
    "uniprot": "A0A077Z415",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z415"
  },
  {
    "uniprot": "A0A077ZCD1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCD1"
  },
  {
    "uniprot": "A0A077Z5W4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5W4"
  },
  {
    "uniprot": "A0A077ZCP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCP3"
  },
  {
    "uniprot": "A0A077Z3E1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3E1"
  },
  {
    "uniprot": "A0A077ZB13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB13"
  },
  {
    "uniprot": "A0A077YYX4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYX4"
  },
  {
    "uniprot": "A0A077ZLR2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLR2"
  },
  {
    "uniprot": "A0A077ZHI5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHI5"
  },
  {
    "uniprot": "A0A077ZBI7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBI7"
  },
  {
    "uniprot": "A0A077ZCT7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCT7"
  },
  {
    "uniprot": "A0A077Z213",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z213"
  },
  {
    "uniprot": "A0A077YXL0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXL0"
  },
  {
    "uniprot": "A0A077YZL2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZL2"
  },
  {
    "uniprot": "A0A077Z9F4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9F4"
  },
  {
    "uniprot": "A0A077ZEF7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEF7"
  },
  {
    "uniprot": "A0A077ZB73",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB73"
  },
  {
    "uniprot": "A0A077ZJD2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJD2"
  },
  {
    "uniprot": "A0A077Z600",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z600"
  },
  {
    "uniprot": "A0A077YWG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWG5"
  },
  {
    "uniprot": "A0A077ZB58",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB58"
  },
  {
    "uniprot": "A0A077ZC27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC27"
  },
  {
    "uniprot": "A0A077ZJ35",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ35"
  },
  {
    "uniprot": "A0A077ZJL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJL7"
  },
  {
    "uniprot": "A0A077ZLT3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLT3"
  },
  {
    "uniprot": "A0A077Z2A5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2A5"
  },
  {
    "uniprot": "A0A077ZEX2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEX2"
  },
  {
    "uniprot": "A0A077ZFS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFS9"
  },
  {
    "uniprot": "A0A077ZBP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBP8"
  },
  {
    "uniprot": "A0A077ZE80",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE80"
  },
  {
    "uniprot": "A0A077ZAL1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAL1"
  },
  {
    "uniprot": "A0A077Z3R6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3R6"
  },
  {
    "uniprot": "A0A077Z6Q6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Q6"
  },
  {
    "uniprot": "A0A077ZA60",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA60"
  },
  {
    "uniprot": "A0A077YXK5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXK5"
  },
  {
    "uniprot": "A0A077ZBD2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBD2"
  },
  {
    "uniprot": "A0A077ZG23",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG23"
  },
  {
    "uniprot": "A0A077ZJB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJB0"
  },
  {
    "uniprot": "A0A077Z7V7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7V7"
  },
  {
    "uniprot": "A0A077ZLM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLM6"
  },
  {
    "uniprot": "A0A077YY59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY59"
  },
  {
    "uniprot": "A0A077ZHE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHE8"
  },
  {
    "uniprot": "A0A077ZKH4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKH4"
  },
  {
    "uniprot": "A0A077ZDZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDZ5"
  },
  {
    "uniprot": "A0A077Z3K0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3K0"
  },
  {
    "uniprot": "A0A077ZCC4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCC4"
  },
  {
    "uniprot": "A0A077ZJX2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJX2"
  },
  {
    "uniprot": "A0A077ZAQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAQ5"
  },
  {
    "uniprot": "A0A077Z842",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z842"
  },
  {
    "uniprot": "A0A077ZGL5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGL5"
  },
  {
    "uniprot": "A0A077ZMX1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMX1"
  },
  {
    "uniprot": "A0A077Z6X9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6X9"
  },
  {
    "uniprot": "A0A077YY85",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY85"
  },
  {
    "uniprot": "A0A077Z413",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z413"
  },
  {
    "uniprot": "A0A077Z591",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z591"
  },
  {
    "uniprot": "A0A077Z3N6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3N6"
  },
  {
    "uniprot": "A0A077ZIN5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIN5"
  },
  {
    "uniprot": "A0A077ZHA6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHA6"
  },
  {
    "uniprot": "A0A077ZHE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHE2"
  },
  {
    "uniprot": "A0A077ZG92",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG92"
  },
  {
    "uniprot": "A0A077ZI72",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI72"
  },
  {
    "uniprot": "A0A077ZJG0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJG0"
  },
  {
    "uniprot": "A0A077ZD08",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD08"
  },
  {
    "uniprot": "A0A077ZG53",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG53"
  },
  {
    "uniprot": "A0A077ZLH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLH8"
  },
  {
    "uniprot": "A0A077ZLU8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLU8"
  },
  {
    "uniprot": "A0A077ZHP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHP9"
  },
  {
    "uniprot": "A0A077Z6F6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6F6"
  },
  {
    "uniprot": "A0A077ZIA4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIA4"
  },
  {
    "uniprot": "A0A077Z7J0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7J0"
  },
  {
    "uniprot": "A0A077Z275",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z275"
  },
  {
    "uniprot": "A0A077ZB56",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB56"
  },
  {
    "uniprot": "A0A077ZCA2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCA2"
  },
  {
    "uniprot": "A0A077Z9G1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9G1"
  },
  {
    "uniprot": "A0A077ZBB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBB9"
  },
  {
    "uniprot": "A0A077ZLC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLC3"
  },
  {
    "uniprot": "A0A077YY58",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY58"
  },
  {
    "uniprot": "A0A077Z521",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z521"
  },
  {
    "uniprot": "A0A077ZKN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKN1"
  },
  {
    "uniprot": "A0A077YXD5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXD5"
  },
  {
    "uniprot": "A0A077ZAS0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAS0"
  },
  {
    "uniprot": "A0A077Z8X5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8X5"
  },
  {
    "uniprot": "A0A077ZAY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAY4"
  },
  {
    "uniprot": "A0A077ZF44",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF44"
  },
  {
    "uniprot": "A0A077Z264",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z264"
  },
  {
    "uniprot": "A0A077Z5T4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5T4"
  },
  {
    "uniprot": "A0A077ZF75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF75"
  },
  {
    "uniprot": "A0A077ZJH9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJH9"
  },
  {
    "uniprot": "A0A077ZDA3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDA3"
  },
  {
    "uniprot": "A0A077YX84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX84"
  },
  {
    "uniprot": "A0A077YYG4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYG4"
  },
  {
    "uniprot": "A0A077Z5A7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5A7"
  },
  {
    "uniprot": "A0A077Z1Q6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Q6"
  },
  {
    "uniprot": "A0A077ZB45",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB45"
  },
  {
    "uniprot": "A0A077ZF41",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF41"
  },
  {
    "uniprot": "A0A077YZB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZB0"
  },
  {
    "uniprot": "A0A077ZCM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCM1"
  },
  {
    "uniprot": "A0A077ZJZ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJZ6"
  },
  {
    "uniprot": "A0A077YVS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVS9"
  },
  {
    "uniprot": "A0A077Z3S6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3S6"
  },
  {
    "uniprot": "A0A077Z7N9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7N9"
  },
  {
    "uniprot": "A0A077ZH68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH68"
  },
  {
    "uniprot": "A0A077ZJP7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJP7"
  },
  {
    "uniprot": "A0A077ZNT3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNT3"
  },
  {
    "uniprot": "A0A077Z902",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z902"
  },
  {
    "uniprot": "A0A077ZD30",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD30"
  },
  {
    "uniprot": "A0A077Z4D7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4D7"
  },
  {
    "uniprot": "A0A077ZH90",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH90"
  },
  {
    "uniprot": "A0A077YVJ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVJ4"
  },
  {
    "uniprot": "A0A077ZN88",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN88"
  },
  {
    "uniprot": "A0A077ZFH2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFH2"
  },
  {
    "uniprot": "A0A077ZIU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIU1"
  },
  {
    "uniprot": "A0A077ZG60",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG60"
  },
  {
    "uniprot": "A0A077ZIE6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIE6"
  },
  {
    "uniprot": "A0A077ZBF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBF9"
  },
  {
    "uniprot": "A0A077ZBW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBW7"
  },
  {
    "uniprot": "A0A077Z8C5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8C5"
  },
  {
    "uniprot": "A0A077ZAM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAM8"
  },
  {
    "uniprot": "A0A077ZNT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNT8"
  },
  {
    "uniprot": "A0A077ZAT2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAT2"
  },
  {
    "uniprot": "A0A077ZLN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLN6"
  },
  {
    "uniprot": "A0A077Z3M2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3M2"
  },
  {
    "uniprot": "A0A077YZP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZP8"
  },
  {
    "uniprot": "A0A077Z1X2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1X2"
  },
  {
    "uniprot": "A0A077Z648",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z648"
  },
  {
    "uniprot": "A0A077ZEV0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEV0"
  },
  {
    "uniprot": "A0A077ZP42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP42"
  },
  {
    "uniprot": "A0A077ZE76",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE76"
  },
  {
    "uniprot": "A0A077ZD76",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD76"
  },
  {
    "uniprot": "A0A077ZBR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBR6"
  },
  {
    "uniprot": "A0A077Z9X2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9X2"
  },
  {
    "uniprot": "A0A077ZG20",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG20"
  },
  {
    "uniprot": "A0A077ZAB5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAB5"
  },
  {
    "uniprot": "A0A077ZDN8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDN8"
  },
  {
    "uniprot": "A0A077Z4W1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4W1"
  },
  {
    "uniprot": "A0A077Z917",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z917"
  },
  {
    "uniprot": "A0A077ZDZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDZ8"
  },
  {
    "uniprot": "A0A077ZI16",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI16"
  },
  {
    "uniprot": "A0A077ZIK8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIK8"
  },
  {
    "uniprot": "A0A077YZF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZF4"
  },
  {
    "uniprot": "A0A077Z5C3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5C3"
  },
  {
    "uniprot": "A0A077ZCJ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCJ6"
  },
  {
    "uniprot": "A0A077Z9H0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9H0"
  },
  {
    "uniprot": "A0A077ZAY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAY1"
  },
  {
    "uniprot": "A0A077ZME5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZME5"
  },
  {
    "uniprot": "A0A077ZHI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHI8"
  },
  {
    "uniprot": "A0A077Z5K9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5K9"
  },
  {
    "uniprot": "A0A077YXZ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXZ6"
  },
  {
    "uniprot": "A0A077Z9Z8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Z8"
  },
  {
    "uniprot": "A0A077Z605",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z605"
  },
  {
    "uniprot": "A0A077ZBX1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBX1"
  },
  {
    "uniprot": "A0A077ZD39",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD39"
  },
  {
    "uniprot": "A0A077ZQH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQH3"
  },
  {
    "uniprot": "A0A077Z338",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z338"
  },
  {
    "uniprot": "A0A077Z4C5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4C5"
  },
  {
    "uniprot": "A0A077ZHC7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHC7"
  },
  {
    "uniprot": "A0A077Z4R5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4R5"
  },
  {
    "uniprot": "A0A077Z634",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z634"
  },
  {
    "uniprot": "A0A077ZIA3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIA3"
  },
  {
    "uniprot": "A0A077ZFC8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFC8"
  },
  {
    "uniprot": "A0A077Z8G7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8G7"
  },
  {
    "uniprot": "A0A077Z8P2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8P2"
  },
  {
    "uniprot": "A0A077Z9P6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9P6"
  },
  {
    "uniprot": "A0A077YVM9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVM9"
  },
  {
    "uniprot": "A0A077Z759",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z759"
  },
  {
    "uniprot": "A0A077ZBH4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBH4"
  },
  {
    "uniprot": "A0A077ZBY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBY8"
  },
  {
    "uniprot": "A0A077ZDV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDV9"
  },
  {
    "uniprot": "A0A077ZGI2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGI2"
  },
  {
    "uniprot": "A0A077Z872",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z872"
  },
  {
    "uniprot": "A0A077Z5E9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5E9"
  },
  {
    "uniprot": "A0A077ZGQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGQ0"
  },
  {
    "uniprot": "A0A077Z503",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z503"
  },
  {
    "uniprot": "A0A077Z3H3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3H3"
  },
  {
    "uniprot": "A0A077Z2N7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2N7"
  },
  {
    "uniprot": "A0A077ZGG4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGG4"
  },
  {
    "uniprot": "A0A077ZNT7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNT7"
  },
  {
    "uniprot": "A0A077ZK85",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK85"
  },
  {
    "uniprot": "A0A077Z107",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z107"
  },
  {
    "uniprot": "A0A077ZHJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHJ0"
  },
  {
    "uniprot": "A0A077Z4F8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4F8"
  },
  {
    "uniprot": "A0A077Z4A6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4A6"
  },
  {
    "uniprot": "A0A077Z8X4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8X4"
  },
  {
    "uniprot": "A0A077YUS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUS1"
  },
  {
    "uniprot": "A0A077YVP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVP9"
  },
  {
    "uniprot": "A0A077Z1G4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1G4"
  },
  {
    "uniprot": "A0A077ZEP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEP8"
  },
  {
    "uniprot": "A0A077Z7H0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7H0"
  },
  {
    "uniprot": "A0A077Z154",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z154"
  },
  {
    "uniprot": "A0A077Z0Q7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Q7"
  },
  {
    "uniprot": "A0A077ZI32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI32"
  },
  {
    "uniprot": "A0A077ZM10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM10"
  },
  {
    "uniprot": "A0A077ZFF1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFF1"
  },
  {
    "uniprot": "A0A077ZGH9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGH9"
  },
  {
    "uniprot": "A0A077ZHI7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHI7"
  },
  {
    "uniprot": "A0A077ZCX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCX9"
  },
  {
    "uniprot": "A0A077ZCD9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCD9"
  },
  {
    "uniprot": "A0A077ZE03",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE03"
  },
  {
    "uniprot": "A0A077ZAU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAU9"
  },
  {
    "uniprot": "A0A077YYK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYK9"
  },
  {
    "uniprot": "A0A077Z8Q2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Q2"
  },
  {
    "uniprot": "A0A077YXA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXA5"
  },
  {
    "uniprot": "A0A077YZT3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZT3"
  },
  {
    "uniprot": "A0A077Z1T2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1T2"
  },
  {
    "uniprot": "A0A077Z2L1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2L1"
  },
  {
    "uniprot": "A0A077ZBB5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBB5"
  },
  {
    "uniprot": "A0A077ZBA6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBA6"
  },
  {
    "uniprot": "A0A077Z8P7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8P7"
  },
  {
    "uniprot": "A0A077Z8F6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8F6"
  },
  {
    "uniprot": "A0A077ZG04",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG04"
  },
  {
    "uniprot": "A0A077YY32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY32"
  },
  {
    "uniprot": "A0A077ZDX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDX7"
  },
  {
    "uniprot": "A0A077Z571",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z571"
  },
  {
    "uniprot": "A0A077YXX2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXX2"
  },
  {
    "uniprot": "A0A077ZMT7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMT7"
  },
  {
    "uniprot": "A0A077ZGC6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGC6"
  },
  {
    "uniprot": "A0A077ZE94",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE94"
  },
  {
    "uniprot": "A0A077YY46",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY46"
  },
  {
    "uniprot": "A0A077Z8H6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8H6"
  },
  {
    "uniprot": "A0A077Z5Z8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Z8"
  },
  {
    "uniprot": "A0A077Z548",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z548"
  },
  {
    "uniprot": "A0A077Z2V3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2V3"
  },
  {
    "uniprot": "A0A077ZI12",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI12"
  },
  {
    "uniprot": "A0A077YV88",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV88"
  },
  {
    "uniprot": "A0A077Z065",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z065"
  },
  {
    "uniprot": "A0A077Z447",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z447"
  },
  {
    "uniprot": "A0A077ZMB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMB2"
  },
  {
    "uniprot": "A0A077YZ08",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ08"
  },
  {
    "uniprot": "A0A077Z7I0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7I0"
  },
  {
    "uniprot": "A0A077ZE17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE17"
  },
  {
    "uniprot": "A0A077Z7P8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7P8"
  },
  {
    "uniprot": "A0A077YXU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXU1"
  },
  {
    "uniprot": "A0A077ZCX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCX6"
  },
  {
    "uniprot": "A0A077ZJK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJK7"
  },
  {
    "uniprot": "A0A077Z632",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z632"
  },
  {
    "uniprot": "A0A077Z319",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z319"
  },
  {
    "uniprot": "A0A077YWJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWJ3"
  },
  {
    "uniprot": "A0A077Z5A2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5A2"
  },
  {
    "uniprot": "A0A077ZH58",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH58"
  },
  {
    "uniprot": "A0A077ZJW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJW7"
  },
  {
    "uniprot": "A0A077Z2W4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2W4"
  },
  {
    "uniprot": "A0A077ZGZ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGZ2"
  },
  {
    "uniprot": "A0A077ZMR4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMR4"
  },
  {
    "uniprot": "A0A077ZF00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF00"
  },
  {
    "uniprot": "A0A077ZIC0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIC0"
  },
  {
    "uniprot": "A0A077Z762",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z762"
  },
  {
    "uniprot": "A0A077ZJS4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJS4"
  },
  {
    "uniprot": "A0A077ZDC6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDC6"
  },
  {
    "uniprot": "A0A077ZN08",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN08"
  },
  {
    "uniprot": "A0A077ZE73",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE73"
  },
  {
    "uniprot": "A0A077YVV0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVV0"
  },
  {
    "uniprot": "A0A077Z008",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z008"
  },
  {
    "uniprot": "A0A077ZFN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFN9"
  },
  {
    "uniprot": "A0A077YYU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYU5"
  },
  {
    "uniprot": "A0A077ZGS8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGS8"
  },
  {
    "uniprot": "A0A077Z3F4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3F4"
  },
  {
    "uniprot": "A0A077Z5P0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5P0"
  },
  {
    "uniprot": "A0A077Z9E5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9E5"
  },
  {
    "uniprot": "A0A077ZHA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHA8"
  },
  {
    "uniprot": "A0A077YYD4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYD4"
  },
  {
    "uniprot": "A0A077ZHJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHJ8"
  },
  {
    "uniprot": "A0A077ZM79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM79"
  },
  {
    "uniprot": "A0A077ZB46",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB46"
  },
  {
    "uniprot": "A0A077Z748",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z748"
  },
  {
    "uniprot": "A0A077YVS7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVS7"
  },
  {
    "uniprot": "A0A077Z2U9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2U9"
  },
  {
    "uniprot": "A0A077ZC39",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC39"
  },
  {
    "uniprot": "A0A077YZK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZK4"
  },
  {
    "uniprot": "A0A077ZPP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPP3"
  },
  {
    "uniprot": "A0A077ZCY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCY8"
  },
  {
    "uniprot": "A0A077YWL4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWL4"
  },
  {
    "uniprot": "A0A077ZLQ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLQ6"
  },
  {
    "uniprot": "A0A077YZ90",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ90"
  },
  {
    "uniprot": "A0A077ZIQ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIQ9"
  },
  {
    "uniprot": "A0A077Z397",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z397"
  },
  {
    "uniprot": "A0A077ZBS6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBS6"
  },
  {
    "uniprot": "A0A077YVK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVK9"
  },
  {
    "uniprot": "A0A077ZKQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKQ0"
  },
  {
    "uniprot": "A0A077Z7K7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7K7"
  },
  {
    "uniprot": "A0A077Z8M7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8M7"
  },
  {
    "uniprot": "A0A077Z6H3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6H3"
  },
  {
    "uniprot": "A0A077ZDZ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDZ2"
  },
  {
    "uniprot": "A0A077Z6Q1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Q1"
  },
  {
    "uniprot": "A0A077YVK8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVK8"
  },
  {
    "uniprot": "A0A077Z4I4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4I4"
  },
  {
    "uniprot": "A0A077ZA62",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA62"
  },
  {
    "uniprot": "A0A077YWR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWR6"
  },
  {
    "uniprot": "A0A077Z6R4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6R4"
  },
  {
    "uniprot": "A0A077ZGL9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGL9"
  },
  {
    "uniprot": "A0A077Z5G2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5G2"
  },
  {
    "uniprot": "A0A077ZJX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJX9"
  },
  {
    "uniprot": "A0A077Z8Q1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Q1"
  },
  {
    "uniprot": "A0A077ZQ26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ26"
  },
  {
    "uniprot": "A0A077ZG64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG64"
  },
  {
    "uniprot": "A0A077Z7I9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7I9"
  },
  {
    "uniprot": "A0A077ZCE9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCE9"
  },
  {
    "uniprot": "A0A077Z353",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z353"
  },
  {
    "uniprot": "A0A077Z4W4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4W4"
  },
  {
    "uniprot": "A0A077Z356",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z356"
  },
  {
    "uniprot": "A0A077Z490",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z490"
  },
  {
    "uniprot": "A0A077ZJ33",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ33"
  },
  {
    "uniprot": "A0A077ZJ82",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ82"
  },
  {
    "uniprot": "A0A077ZC09",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC09"
  },
  {
    "uniprot": "A0A077Z7A1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7A1"
  },
  {
    "uniprot": "A0A077YW15",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW15"
  },
  {
    "uniprot": "A0A077YZZ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZZ0"
  },
  {
    "uniprot": "A0A077Z807",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z807"
  },
  {
    "uniprot": "A0A077Z9X8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9X8"
  },
  {
    "uniprot": "A0A077ZDS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDS9"
  },
  {
    "uniprot": "A0A077ZQK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQK3"
  },
  {
    "uniprot": "A0A077ZC72",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC72"
  },
  {
    "uniprot": "A0A077Z753",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z753"
  },
  {
    "uniprot": "A0A077Z5Y7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Y7"
  },
  {
    "uniprot": "A0A077ZDU3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDU3"
  },
  {
    "uniprot": "A0A077ZID1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZID1"
  },
  {
    "uniprot": "A0A077ZC19",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC19"
  },
  {
    "uniprot": "A0A077ZE75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE75"
  },
  {
    "uniprot": "A0A077Z4E5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4E5"
  },
  {
    "uniprot": "A0A077YZ22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ22"
  },
  {
    "uniprot": "A0A077ZLX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLX6"
  },
  {
    "uniprot": "A0A077YXL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXL7"
  },
  {
    "uniprot": "A0A077ZPZ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPZ7"
  },
  {
    "uniprot": "A0A077YY95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY95"
  },
  {
    "uniprot": "A0A077ZFX8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFX8"
  },
  {
    "uniprot": "A0A077ZAV2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAV2"
  },
  {
    "uniprot": "A0A077ZGQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGQ2"
  },
  {
    "uniprot": "A0A077ZEZ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEZ6"
  },
  {
    "uniprot": "A0A077ZBL2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBL2"
  },
  {
    "uniprot": "A0A077ZCV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCV6"
  },
  {
    "uniprot": "A0A077ZK95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK95"
  },
  {
    "uniprot": "A0A077Z8T9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8T9"
  },
  {
    "uniprot": "A0A077ZJQ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJQ1"
  },
  {
    "uniprot": "A0A077ZMK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMK9"
  },
  {
    "uniprot": "A0A077ZDQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDQ3"
  },
  {
    "uniprot": "A0A077Z1F7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1F7"
  },
  {
    "uniprot": "A0A077ZF47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF47"
  },
  {
    "uniprot": "A0A077ZAK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAK4"
  },
  {
    "uniprot": "A0A077YZ91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ91"
  },
  {
    "uniprot": "A0A077ZJ11",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ11"
  },
  {
    "uniprot": "A0A077YX27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX27"
  },
  {
    "uniprot": "A0A077ZG82",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG82"
  },
  {
    "uniprot": "A0A077Z4Y2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Y2"
  },
  {
    "uniprot": "A0A077Z8Z5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Z5"
  },
  {
    "uniprot": "A0A077Z6D1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6D1"
  },
  {
    "uniprot": "A0A077ZH10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH10"
  },
  {
    "uniprot": "A0A077ZJP1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJP1"
  },
  {
    "uniprot": "A0A077ZCJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCJ7"
  },
  {
    "uniprot": "A0A077ZDB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDB6"
  },
  {
    "uniprot": "A0A077Z433",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z433"
  },
  {
    "uniprot": "A0A077Z301",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z301"
  },
  {
    "uniprot": "A0A077ZCZ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCZ0"
  },
  {
    "uniprot": "A0A077YZT2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZT2"
  },
  {
    "uniprot": "A0A077YW18",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW18"
  },
  {
    "uniprot": "A0A077ZLM3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLM3"
  },
  {
    "uniprot": "A0A077Z302",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z302"
  },
  {
    "uniprot": "A0A077Z3P0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3P0"
  },
  {
    "uniprot": "A0A077YZU3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZU3"
  },
  {
    "uniprot": "A0A077ZHL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHL7"
  },
  {
    "uniprot": "A0A077Z8S2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8S2"
  },
  {
    "uniprot": "A0A077ZJ34",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ34"
  },
  {
    "uniprot": "A0A077Z5L1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5L1"
  },
  {
    "uniprot": "A0A077Z622",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z622"
  },
  {
    "uniprot": "A0A077YYH4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYH4"
  },
  {
    "uniprot": "A0A077ZJU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJU4"
  },
  {
    "uniprot": "A0A077ZEE9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEE9"
  },
  {
    "uniprot": "A0A077ZBE3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBE3"
  },
  {
    "uniprot": "A0A077Z8D0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8D0"
  },
  {
    "uniprot": "A0A077Z3A0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3A0"
  },
  {
    "uniprot": "A0A077Z1M0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1M0"
  },
  {
    "uniprot": "A0A077ZD25",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD25"
  },
  {
    "uniprot": "A0A077YXC5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXC5"
  },
  {
    "uniprot": "A0A077ZAB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAB2"
  },
  {
    "uniprot": "A0A077ZJJ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJJ9"
  },
  {
    "uniprot": "A0A077ZEF6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEF6"
  },
  {
    "uniprot": "A0A077Z702",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z702"
  },
  {
    "uniprot": "A0A077ZEA0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEA0"
  },
  {
    "uniprot": "A0A077ZKP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKP3"
  },
  {
    "uniprot": "A0A077ZEZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEZ5"
  },
  {
    "uniprot": "A0A077ZLV0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLV0"
  },
  {
    "uniprot": "A0A077Z7C4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7C4"
  },
  {
    "uniprot": "A0A077ZND1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZND1"
  },
  {
    "uniprot": "A0A077ZF94",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF94"
  },
  {
    "uniprot": "A0A077YX47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX47"
  },
  {
    "uniprot": "A0A077ZER1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZER1"
  },
  {
    "uniprot": "A0A077Z6V8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6V8"
  },
  {
    "uniprot": "A0A077ZDI0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDI0"
  },
  {
    "uniprot": "A0A077ZAW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAW1"
  },
  {
    "uniprot": "A0A077ZHE3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHE3"
  },
  {
    "uniprot": "A0A077ZIN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIN4"
  },
  {
    "uniprot": "A0A077Z7N2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7N2"
  },
  {
    "uniprot": "A0A077YX91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX91"
  },
  {
    "uniprot": "A0A077ZFB5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFB5"
  },
  {
    "uniprot": "A0A077ZPC9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPC9"
  },
  {
    "uniprot": "A0A077Z6I4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6I4"
  },
  {
    "uniprot": "A0A077Z0L2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0L2"
  },
  {
    "uniprot": "A0A077ZN17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN17"
  },
  {
    "uniprot": "A0A077ZIM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIM2"
  },
  {
    "uniprot": "A0A077ZB79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB79"
  },
  {
    "uniprot": "A0A077YXQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXQ2"
  },
  {
    "uniprot": "A0A077Z361",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z361"
  },
  {
    "uniprot": "A0A077Z1H7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1H7"
  },
  {
    "uniprot": "A0A077ZD10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD10"
  },
  {
    "uniprot": "A0A077Z2S7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2S7"
  },
  {
    "uniprot": "A0A077ZKB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKB0"
  },
  {
    "uniprot": "A0A077ZEV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEV1"
  },
  {
    "uniprot": "A0A077Z608",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z608"
  },
  {
    "uniprot": "A0A077ZGU3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGU3"
  },
  {
    "uniprot": "A0A077Z4P5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4P5"
  },
  {
    "uniprot": "A0A077Z3E8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3E8"
  },
  {
    "uniprot": "A0A077ZII9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZII9"
  },
  {
    "uniprot": "A0A077ZA83",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA83"
  },
  {
    "uniprot": "A0A077YX33",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX33"
  },
  {
    "uniprot": "A0A077ZCN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCN2"
  },
  {
    "uniprot": "A0A077Z5L0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5L0"
  },
  {
    "uniprot": "A0A077YZR4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZR4"
  },
  {
    "uniprot": "A0A077YYG0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYG0"
  },
  {
    "uniprot": "A0A077YUT0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUT0"
  },
  {
    "uniprot": "A0A077YXC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXC3"
  },
  {
    "uniprot": "A0A077Z3G4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3G4"
  },
  {
    "uniprot": "A0A077Z0J5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0J5"
  },
  {
    "uniprot": "A0A077Z2F0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2F0"
  },
  {
    "uniprot": "A0A077YXD6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXD6"
  },
  {
    "uniprot": "A0A077Z8G9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8G9"
  },
  {
    "uniprot": "A0A077ZK89",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK89"
  },
  {
    "uniprot": "A0A077Z6H2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6H2"
  },
  {
    "uniprot": "A0A077ZJY2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJY2"
  },
  {
    "uniprot": "A0A077ZHV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHV1"
  },
  {
    "uniprot": "A0A077ZC42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC42"
  },
  {
    "uniprot": "A0A077ZEM9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEM9"
  },
  {
    "uniprot": "A0A077Z5N8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5N8"
  },
  {
    "uniprot": "A0A077YZJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZJ0"
  },
  {
    "uniprot": "A0A077ZMS4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMS4"
  },
  {
    "uniprot": "A0A077Z122",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z122"
  },
  {
    "uniprot": "A0A077ZHZ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHZ2"
  },
  {
    "uniprot": "A0A077Z335",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z335"
  },
  {
    "uniprot": "A0A077YWS6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWS6"
  },
  {
    "uniprot": "A0A077Z7T9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7T9"
  },
  {
    "uniprot": "A0A077Z1L3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1L3"
  },
  {
    "uniprot": "A0A077YX55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX55"
  },
  {
    "uniprot": "A0A077ZGT4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGT4"
  },
  {
    "uniprot": "A0A077Z4E3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4E3"
  },
  {
    "uniprot": "A0A077Z6A6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6A6"
  },
  {
    "uniprot": "A0A077ZHX4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHX4"
  },
  {
    "uniprot": "A0A077Z8G3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8G3"
  },
  {
    "uniprot": "A0A077Z0P4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0P4"
  },
  {
    "uniprot": "A0A077Z2U4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2U4"
  },
  {
    "uniprot": "A0A077ZGA9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGA9"
  },
  {
    "uniprot": "A0A077Z5B2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5B2"
  },
  {
    "uniprot": "A0A077Z189",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z189"
  },
  {
    "uniprot": "A0A077Z821",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z821"
  },
  {
    "uniprot": "A0A077YZ62",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ62"
  },
  {
    "uniprot": "A0A077ZJN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJN4"
  },
  {
    "uniprot": "A0A077YZM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZM6"
  },
  {
    "uniprot": "A0A077ZHR9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHR9"
  },
  {
    "uniprot": "A0A077ZNZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNZ8"
  },
  {
    "uniprot": "A0A077Z5J2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5J2"
  },
  {
    "uniprot": "A0A077ZL63",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL63"
  },
  {
    "uniprot": "A0A077YUJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUJ0"
  },
  {
    "uniprot": "A0A077Z3K9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3K9"
  },
  {
    "uniprot": "A0A077Z8E3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8E3"
  },
  {
    "uniprot": "A0A077Z1P7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1P7"
  },
  {
    "uniprot": "A0A077Z1T6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1T6"
  },
  {
    "uniprot": "A0A077ZCE7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCE7"
  },
  {
    "uniprot": "A0A077ZQV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQV9"
  },
  {
    "uniprot": "A0A077Z0V9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0V9"
  },
  {
    "uniprot": "A0A077ZMV5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMV5"
  },
  {
    "uniprot": "A0A077Z2U6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2U6"
  },
  {
    "uniprot": "A0A077ZJM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJM5"
  },
  {
    "uniprot": "A0A077ZAL2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAL2"
  },
  {
    "uniprot": "A0A077Z5V4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5V4"
  },
  {
    "uniprot": "A0A077Z3Y7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Y7"
  },
  {
    "uniprot": "A0A077YXK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXK3"
  },
  {
    "uniprot": "A0A077ZFP6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFP6"
  },
  {
    "uniprot": "A0A077Z731",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z731"
  },
  {
    "uniprot": "A0A077ZL93",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL93"
  },
  {
    "uniprot": "A0A077YYN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYN3"
  },
  {
    "uniprot": "A0A077YZU0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZU0"
  },
  {
    "uniprot": "A0A077Z616",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z616"
  },
  {
    "uniprot": "A0A077ZGK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGK6"
  },
  {
    "uniprot": "A0A077ZQ40",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ40"
  },
  {
    "uniprot": "A0A077ZHY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHY6"
  },
  {
    "uniprot": "A0A077Z0D6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0D6"
  },
  {
    "uniprot": "A0A077YY10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY10"
  },
  {
    "uniprot": "A0A077ZI69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI69"
  },
  {
    "uniprot": "A0A077Z3E5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3E5"
  },
  {
    "uniprot": "A0A077YX48",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX48"
  },
  {
    "uniprot": "A0A077Z140",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z140"
  },
  {
    "uniprot": "A0A077ZA12",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA12"
  },
  {
    "uniprot": "A0A077Z4T3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4T3"
  },
  {
    "uniprot": "A0A077YZ12",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ12"
  },
  {
    "uniprot": "A0A077YWD8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWD8"
  },
  {
    "uniprot": "A0A077YWV0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWV0"
  },
  {
    "uniprot": "A0A077ZCY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCY6"
  },
  {
    "uniprot": "A0A077Z7T4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7T4"
  },
  {
    "uniprot": "A0A077ZGP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGP5"
  },
  {
    "uniprot": "A0A077Z1D4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1D4"
  },
  {
    "uniprot": "A0A077Z4L0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4L0"
  },
  {
    "uniprot": "A0A077YXW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXW7"
  },
  {
    "uniprot": "A0A077ZDP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDP2"
  },
  {
    "uniprot": "A0A077ZMJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMJ7"
  },
  {
    "uniprot": "A0A077ZK15",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK15"
  },
  {
    "uniprot": "A0A077Z1R2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1R2"
  },
  {
    "uniprot": "A0A077ZFU0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFU0"
  },
  {
    "uniprot": "A0A077Z9B7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9B7"
  },
  {
    "uniprot": "A0A077ZGR7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGR7"
  },
  {
    "uniprot": "A0A077ZGG1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGG1"
  },
  {
    "uniprot": "A0A077Z040",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z040"
  },
  {
    "uniprot": "A0A077Z9C2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9C2"
  },
  {
    "uniprot": "A0A077ZHN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHN2"
  },
  {
    "uniprot": "A0A077ZI45",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI45"
  },
  {
    "uniprot": "A0A077Z0C2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0C2"
  },
  {
    "uniprot": "A0A077ZHH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHH3"
  },
  {
    "uniprot": "A0A077ZEZ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEZ7"
  },
  {
    "uniprot": "A0A077ZG18",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG18"
  },
  {
    "uniprot": "A0A077Z5P3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5P3"
  },
  {
    "uniprot": "A0A077ZAY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAY8"
  },
  {
    "uniprot": "A0A077Z1B7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1B7"
  },
  {
    "uniprot": "A0A077ZA91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA91"
  },
  {
    "uniprot": "A0A077ZEH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEH3"
  },
  {
    "uniprot": "A0A077Z960",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z960"
  },
  {
    "uniprot": "A0A077ZPF6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPF6"
  },
  {
    "uniprot": "A0A077YZS3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZS3"
  },
  {
    "uniprot": "A0A077Z232",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z232"
  },
  {
    "uniprot": "A0A077Z2T2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2T2"
  },
  {
    "uniprot": "A0A077Z5N7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5N7"
  },
  {
    "uniprot": "A0A077YZ79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ79"
  },
  {
    "uniprot": "A0A077Z6R9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6R9"
  },
  {
    "uniprot": "A0A077ZIH7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIH7"
  },
  {
    "uniprot": "A0A077Z0N8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0N8"
  },
  {
    "uniprot": "A0A077ZLG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLG7"
  },
  {
    "uniprot": "A0A077YYZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYZ1"
  },
  {
    "uniprot": "A0A077YWI9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWI9"
  },
  {
    "uniprot": "A0A077ZL87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL87"
  },
  {
    "uniprot": "A0A077ZDL2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDL2"
  },
  {
    "uniprot": "A0A077YY22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY22"
  },
  {
    "uniprot": "A0A077ZIH9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIH9"
  },
  {
    "uniprot": "A0A077ZC70",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC70"
  },
  {
    "uniprot": "A0A077ZN01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN01"
  },
  {
    "uniprot": "A0A077ZH91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH91"
  },
  {
    "uniprot": "A0A077ZDX4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDX4"
  },
  {
    "uniprot": "A0A077Z1Z0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Z0"
  },
  {
    "uniprot": "A0A077YX94",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX94"
  },
  {
    "uniprot": "A0A077ZQU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQU6"
  },
  {
    "uniprot": "A0A077Z3X0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3X0"
  },
  {
    "uniprot": "A0A077ZE95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE95"
  },
  {
    "uniprot": "A0A077ZF62",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF62"
  },
  {
    "uniprot": "A0A077ZEB7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEB7"
  },
  {
    "uniprot": "A0A077ZHH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHH1"
  },
  {
    "uniprot": "A0A077ZEY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEY4"
  },
  {
    "uniprot": "A0A077ZDU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDU7"
  },
  {
    "uniprot": "A0A077ZGT6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGT6"
  },
  {
    "uniprot": "A0A077YXP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXP8"
  },
  {
    "uniprot": "A0A077ZHV5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHV5"
  },
  {
    "uniprot": "A0A077ZMR9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMR9"
  },
  {
    "uniprot": "A0A077YW71",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW71"
  },
  {
    "uniprot": "A0A077ZLD6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLD6"
  },
  {
    "uniprot": "A0A077ZHJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHJ7"
  },
  {
    "uniprot": "A0A077Z130",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z130"
  },
  {
    "uniprot": "A0A077ZGH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGH1"
  },
  {
    "uniprot": "A0A077ZDP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDP9"
  },
  {
    "uniprot": "A0A077ZAR4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAR4"
  },
  {
    "uniprot": "A0A077ZDT7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDT7"
  },
  {
    "uniprot": "A0A077ZIG0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIG0"
  },
  {
    "uniprot": "A0A077YY72",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY72"
  },
  {
    "uniprot": "A0A077ZEY5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEY5"
  },
  {
    "uniprot": "A0A077Z4L6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4L6"
  },
  {
    "uniprot": "A0A077Z9Z2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Z2"
  },
  {
    "uniprot": "A0A077Z5K0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5K0"
  },
  {
    "uniprot": "A0A077Z0S8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0S8"
  },
  {
    "uniprot": "A0A077Z7X5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7X5"
  },
  {
    "uniprot": "A0A077Z3V0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3V0"
  },
  {
    "uniprot": "A0A077ZB52",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB52"
  },
  {
    "uniprot": "A0A077YW03",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW03"
  },
  {
    "uniprot": "A0A077Z3N5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3N5"
  },
  {
    "uniprot": "A0A077ZCD0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCD0"
  },
  {
    "uniprot": "A0A077ZG55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG55"
  },
  {
    "uniprot": "A0A077ZDH2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDH2"
  },
  {
    "uniprot": "A0A077ZBE1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBE1"
  },
  {
    "uniprot": "A0A077Z9Q5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Q5"
  },
  {
    "uniprot": "A0A077YXI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXI8"
  },
  {
    "uniprot": "A0A077YXV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXV1"
  },
  {
    "uniprot": "A0A077Z0U2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0U2"
  },
  {
    "uniprot": "A0A077Z6G6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6G6"
  },
  {
    "uniprot": "A0A077ZAJ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAJ4"
  },
  {
    "uniprot": "A0A077ZMB7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMB7"
  },
  {
    "uniprot": "A0A077Z3C5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3C5"
  },
  {
    "uniprot": "A0A077YZC2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZC2"
  },
  {
    "uniprot": "A0A077Z8W7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8W7"
  },
  {
    "uniprot": "A0A077ZH03",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH03"
  },
  {
    "uniprot": "A0A077ZFH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFH1"
  },
  {
    "uniprot": "A0A077YZP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZP2"
  },
  {
    "uniprot": "A0A077ZCB4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCB4"
  },
  {
    "uniprot": "A0A077Z784",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z784"
  },
  {
    "uniprot": "A0A077Z0T8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0T8"
  },
  {
    "uniprot": "A0A077ZPT3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPT3"
  },
  {
    "uniprot": "A0A077Z4N8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4N8"
  },
  {
    "uniprot": "A0A077ZJQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJQ3"
  },
  {
    "uniprot": "A0A077ZGV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGV1"
  },
  {
    "uniprot": "A0A077YVR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVR6"
  },
  {
    "uniprot": "A0A077YWD7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWD7"
  },
  {
    "uniprot": "A0A077Z4A9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4A9"
  },
  {
    "uniprot": "A0A077ZC07",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC07"
  },
  {
    "uniprot": "A0A077ZLQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLQ0"
  },
  {
    "uniprot": "A0A077Z575",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z575"
  },
  {
    "uniprot": "A0A077ZBK8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBK8"
  },
  {
    "uniprot": "A0A077Z841",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z841"
  },
  {
    "uniprot": "A0A077YXQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXQ0"
  },
  {
    "uniprot": "A0A077ZJ64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ64"
  },
  {
    "uniprot": "A0A077Z5U9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5U9"
  },
  {
    "uniprot": "A0A077ZFV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFV1"
  },
  {
    "uniprot": "A0A077Z7Q8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Q8"
  },
  {
    "uniprot": "A0A077Z9V6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9V6"
  },
  {
    "uniprot": "A0A077YYP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYP0"
  },
  {
    "uniprot": "A0A077Z0F9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0F9"
  },
  {
    "uniprot": "A0A077Z2V7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2V7"
  },
  {
    "uniprot": "A0A077ZJ70",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ70"
  },
  {
    "uniprot": "A0A077ZD07",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD07"
  },
  {
    "uniprot": "A0A077ZJL2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJL2"
  },
  {
    "uniprot": "A0A077Z7Z1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Z1"
  },
  {
    "uniprot": "A0A077Z2C1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2C1"
  },
  {
    "uniprot": "A0A077Z132",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z132"
  },
  {
    "uniprot": "A0A077Z8S1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8S1"
  },
  {
    "uniprot": "A0A077ZE62",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE62"
  },
  {
    "uniprot": "A0A077ZNM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNM1"
  },
  {
    "uniprot": "A0A077ZJS7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJS7"
  },
  {
    "uniprot": "A0A077ZID4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZID4"
  },
  {
    "uniprot": "A0A077ZFS5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFS5"
  },
  {
    "uniprot": "A0A077YYL1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYL1"
  },
  {
    "uniprot": "A0A077ZQ97",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ97"
  },
  {
    "uniprot": "A0A077ZIL2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIL2"
  },
  {
    "uniprot": "A0A077Z610",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z610"
  },
  {
    "uniprot": "A0A077Z4V1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4V1"
  },
  {
    "uniprot": "A0A077ZP45",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP45"
  },
  {
    "uniprot": "A0A077YY91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY91"
  },
  {
    "uniprot": "A0A077YXE5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXE5"
  },
  {
    "uniprot": "A0A077Z3Q1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Q1"
  },
  {
    "uniprot": "A0A077ZE31",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE31"
  },
  {
    "uniprot": "A0A077ZK43",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK43"
  },
  {
    "uniprot": "A0A077ZDZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDZ4"
  },
  {
    "uniprot": "A0A077ZHE0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHE0"
  },
  {
    "uniprot": "A0A077Z1M2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1M2"
  },
  {
    "uniprot": "A0A077ZLN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLN4"
  },
  {
    "uniprot": "A0A077ZLI6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLI6"
  },
  {
    "uniprot": "A0A077ZAU8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAU8"
  },
  {
    "uniprot": "A0A077Z1N9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1N9"
  },
  {
    "uniprot": "A0A077ZED7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZED7"
  },
  {
    "uniprot": "A0A077Z6G3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6G3"
  },
  {
    "uniprot": "A0A077YXK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXK7"
  },
  {
    "uniprot": "A0A077Z1G5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1G5"
  },
  {
    "uniprot": "A0A077Z1V2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1V2"
  },
  {
    "uniprot": "A0A077YVK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVK3"
  },
  {
    "uniprot": "A0A077Z2R6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2R6"
  },
  {
    "uniprot": "A0A077Z5F2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5F2"
  },
  {
    "uniprot": "A0A077ZCR2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCR2"
  },
  {
    "uniprot": "A0A077YXB7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXB7"
  },
  {
    "uniprot": "A0A077Z8L0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8L0"
  },
  {
    "uniprot": "A0A077Z8R4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8R4"
  },
  {
    "uniprot": "A0A077Z8S8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8S8"
  },
  {
    "uniprot": "A0A077ZEM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEM5"
  },
  {
    "uniprot": "A0A077ZMF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMF9"
  },
  {
    "uniprot": "A0A077Z6I3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6I3"
  },
  {
    "uniprot": "A0A077ZC55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC55"
  },
  {
    "uniprot": "A0A077ZNQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNQ0"
  },
  {
    "uniprot": "A0A077ZE61",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE61"
  },
  {
    "uniprot": "A0A077YX82",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX82"
  },
  {
    "uniprot": "A0A077ZI22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI22"
  },
  {
    "uniprot": "A0A077ZDX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDX3"
  },
  {
    "uniprot": "A0A077ZJS8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJS8"
  },
  {
    "uniprot": "A0A077Z5X6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5X6"
  },
  {
    "uniprot": "A0A077ZBJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBJ8"
  },
  {
    "uniprot": "A0A077Z2J8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2J8"
  },
  {
    "uniprot": "A0A077Z2L7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2L7"
  },
  {
    "uniprot": "A0A077ZFY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFY3"
  },
  {
    "uniprot": "A0A077ZM51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM51"
  },
  {
    "uniprot": "A0A077Z6Y1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Y1"
  },
  {
    "uniprot": "A0A077ZNQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNQ5"
  },
  {
    "uniprot": "A0A077ZGB8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGB8"
  },
  {
    "uniprot": "A0A077Z3D6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3D6"
  },
  {
    "uniprot": "A0A077ZDW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDW1"
  },
  {
    "uniprot": "A0A077YXQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXQ4"
  },
  {
    "uniprot": "A0A077YWQ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWQ6"
  },
  {
    "uniprot": "A0A077YY00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY00"
  },
  {
    "uniprot": "A0A077Z8V1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8V1"
  },
  {
    "uniprot": "A0A077Z9G8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9G8"
  },
  {
    "uniprot": "A0A077Z1B9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1B9"
  },
  {
    "uniprot": "A0A077Z5H4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5H4"
  },
  {
    "uniprot": "A0A077ZF17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF17"
  },
  {
    "uniprot": "A0A077YVW4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVW4"
  },
  {
    "uniprot": "A0A077YZI0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZI0"
  },
  {
    "uniprot": "A0A077Z3R5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3R5"
  },
  {
    "uniprot": "A0A077YY50",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY50"
  },
  {
    "uniprot": "A0A077ZGL4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGL4"
  },
  {
    "uniprot": "A0A077ZBD4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBD4"
  },
  {
    "uniprot": "A0A077ZDB3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDB3"
  },
  {
    "uniprot": "A0A077YX99",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX99"
  },
  {
    "uniprot": "A0A077Z604",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z604"
  },
  {
    "uniprot": "A0A077ZMB8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMB8"
  },
  {
    "uniprot": "A0A077Z9B0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9B0"
  },
  {
    "uniprot": "A0A077Z5F6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5F6"
  },
  {
    "uniprot": "A0A077Z0D3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0D3"
  },
  {
    "uniprot": "A0A077Z4U6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4U6"
  },
  {
    "uniprot": "A0A077YZ60",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ60"
  },
  {
    "uniprot": "A0A077ZIT3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIT3"
  },
  {
    "uniprot": "A0A077ZHN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHN3"
  },
  {
    "uniprot": "A0A077Z053",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z053"
  },
  {
    "uniprot": "A0A077ZJH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJH5"
  },
  {
    "uniprot": "A0A077ZAA7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAA7"
  },
  {
    "uniprot": "A0A077Z5G8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5G8"
  },
  {
    "uniprot": "A0A077YZM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZM5"
  },
  {
    "uniprot": "A0A077ZNK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNK6"
  },
  {
    "uniprot": "A0A077YY87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY87"
  },
  {
    "uniprot": "A0A077YZ87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ87"
  },
  {
    "uniprot": "A0A077Z7S3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7S3"
  },
  {
    "uniprot": "A0A077Z294",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z294"
  },
  {
    "uniprot": "A0A077Z3R1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3R1"
  },
  {
    "uniprot": "A0A077Z3F6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3F6"
  },
  {
    "uniprot": "A0A077Z4Q4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Q4"
  },
  {
    "uniprot": "A0A077Z2G6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2G6"
  },
  {
    "uniprot": "A0A077ZCD2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCD2"
  },
  {
    "uniprot": "A0A077ZRA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZRA1"
  },
  {
    "uniprot": "A0A077ZM12",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM12"
  },
  {
    "uniprot": "A0A077ZI70",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI70"
  },
  {
    "uniprot": "A0A077ZIS6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIS6"
  },
  {
    "uniprot": "A0A077YYT4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYT4"
  },
  {
    "uniprot": "A0A077YWL3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWL3"
  },
  {
    "uniprot": "A0A077Z191",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z191"
  },
  {
    "uniprot": "A0A077YVU8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVU8"
  },
  {
    "uniprot": "A0A077ZE50",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE50"
  },
  {
    "uniprot": "A0A077Z7X2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7X2"
  },
  {
    "uniprot": "A0A077ZBX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBX6"
  },
  {
    "uniprot": "A0A077ZAA3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAA3"
  },
  {
    "uniprot": "A0A077Z3B9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3B9"
  },
  {
    "uniprot": "A0A077ZJ02",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ02"
  },
  {
    "uniprot": "A0A077Z1S7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1S7"
  },
  {
    "uniprot": "A0A077ZD61",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD61"
  },
  {
    "uniprot": "A0A077ZKG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKG5"
  },
  {
    "uniprot": "A0A077ZKU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKU9"
  },
  {
    "uniprot": "A0A077ZLK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLK7"
  },
  {
    "uniprot": "A0A077Z9Z7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Z7"
  },
  {
    "uniprot": "A0A077Z0I9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0I9"
  },
  {
    "uniprot": "A0A077ZBY5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBY5"
  },
  {
    "uniprot": "A0A077Z5I9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5I9"
  },
  {
    "uniprot": "A0A077Z539",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z539"
  },
  {
    "uniprot": "A0A077Z8F0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8F0"
  },
  {
    "uniprot": "A0A077ZKB4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKB4"
  },
  {
    "uniprot": "A0A077ZFM3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFM3"
  },
  {
    "uniprot": "A0A077YXS3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXS3"
  },
  {
    "uniprot": "A0A077ZMD7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMD7"
  },
  {
    "uniprot": "A0A077Z868",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z868"
  },
  {
    "uniprot": "A0A077ZAF6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAF6"
  },
  {
    "uniprot": "A0A077Z5L5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5L5"
  },
  {
    "uniprot": "A0A077YXK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXK9"
  },
  {
    "uniprot": "A0A077Z9G6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9G6"
  },
  {
    "uniprot": "A0A077ZEM3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEM3"
  },
  {
    "uniprot": "A0A077YVK1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVK1"
  },
  {
    "uniprot": "A0A077ZK07",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK07"
  },
  {
    "uniprot": "A0A077Z128",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z128"
  },
  {
    "uniprot": "A0A077ZAQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAQ0"
  },
  {
    "uniprot": "A0A077ZBV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBV3"
  },
  {
    "uniprot": "A0A077ZHK5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHK5"
  },
  {
    "uniprot": "A0A077Z5X9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5X9"
  },
  {
    "uniprot": "A0A077ZGJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGJ3"
  },
  {
    "uniprot": "A0A077Z9R2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9R2"
  },
  {
    "uniprot": "A0A077Z9U2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9U2"
  },
  {
    "uniprot": "A0A077Z0H4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0H4"
  },
  {
    "uniprot": "A0A077ZCU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCU5"
  },
  {
    "uniprot": "A0A077Z3D2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3D2"
  },
  {
    "uniprot": "A0A077Z031",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z031"
  },
  {
    "uniprot": "A0A077Z2V8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2V8"
  },
  {
    "uniprot": "A0A077ZI46",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI46"
  },
  {
    "uniprot": "A0A077YW66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW66"
  },
  {
    "uniprot": "A0A077Z2Y5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Y5"
  },
  {
    "uniprot": "A0A077ZF51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF51"
  },
  {
    "uniprot": "A0A077YWS0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWS0"
  },
  {
    "uniprot": "A0A077Z2H7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2H7"
  },
  {
    "uniprot": "A0A077ZA23",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA23"
  },
  {
    "uniprot": "A0A077ZAD4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAD4"
  },
  {
    "uniprot": "A0A077Z0F4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0F4"
  },
  {
    "uniprot": "A0A077ZN82",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN82"
  },
  {
    "uniprot": "A0A077Z877",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z877"
  },
  {
    "uniprot": "A0A077Z0H8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0H8"
  },
  {
    "uniprot": "A0A077ZDV7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDV7"
  },
  {
    "uniprot": "A0A077ZBI6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBI6"
  },
  {
    "uniprot": "A0A077ZAD6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAD6"
  },
  {
    "uniprot": "A0A077Z164",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z164"
  },
  {
    "uniprot": "A0A077YZG0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZG0"
  },
  {
    "uniprot": "A0A077Z3M5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3M5"
  },
  {
    "uniprot": "A0A077Z6U6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6U6"
  },
  {
    "uniprot": "A0A077ZCT6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCT6"
  },
  {
    "uniprot": "A0A077Z9H8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9H8"
  },
  {
    "uniprot": "A0A077ZJZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJZ8"
  },
  {
    "uniprot": "A0A077Z668",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z668"
  },
  {
    "uniprot": "A0A077Z1P8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1P8"
  },
  {
    "uniprot": "A0A077Z9A6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9A6"
  },
  {
    "uniprot": "A0A077Z5U3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5U3"
  },
  {
    "uniprot": "A0A077ZHU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHU1"
  },
  {
    "uniprot": "A0A077Z7K4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7K4"
  },
  {
    "uniprot": "A0A077YXA0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXA0"
  },
  {
    "uniprot": "A0A077Z0I8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0I8"
  },
  {
    "uniprot": "A0A077YYU3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYU3"
  },
  {
    "uniprot": "A0A077Z0B1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0B1"
  },
  {
    "uniprot": "A0A077ZGT0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGT0"
  },
  {
    "uniprot": "A0A077ZDY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDY1"
  },
  {
    "uniprot": "A0A077ZFJ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFJ6"
  },
  {
    "uniprot": "A0A077Z9H2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9H2"
  },
  {
    "uniprot": "A0A077Z8E5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8E5"
  },
  {
    "uniprot": "A0A077Z1Y7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Y7"
  },
  {
    "uniprot": "A0A077Z4M4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4M4"
  },
  {
    "uniprot": "A0A077ZGI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGI8"
  },
  {
    "uniprot": "A0A077ZDT5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDT5"
  },
  {
    "uniprot": "A0A077ZII6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZII6"
  },
  {
    "uniprot": "A0A077ZN47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN47"
  },
  {
    "uniprot": "A0A077YYC4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYC4"
  },
  {
    "uniprot": "A0A077Z0Y7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Y7"
  },
  {
    "uniprot": "A0A077ZNM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNM8"
  },
  {
    "uniprot": "A0A077Z744",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z744"
  },
  {
    "uniprot": "A0A077ZK46",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK46"
  },
  {
    "uniprot": "A0A077ZFN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFN3"
  },
  {
    "uniprot": "A0A077YVY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVY8"
  },
  {
    "uniprot": "A0A077Z9R7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9R7"
  },
  {
    "uniprot": "A0A077YX45",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX45"
  },
  {
    "uniprot": "A0A077Z0W0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0W0"
  },
  {
    "uniprot": "A0A077YXH0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXH0"
  },
  {
    "uniprot": "A0A077ZFQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFQ0"
  },
  {
    "uniprot": "A0A077ZLV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLV6"
  },
  {
    "uniprot": "A0A077Z201",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z201"
  },
  {
    "uniprot": "A0A077ZD36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD36"
  },
  {
    "uniprot": "A0A077Z462",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z462"
  },
  {
    "uniprot": "A0A077ZFM4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFM4"
  },
  {
    "uniprot": "A0A077Z3L6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3L6"
  },
  {
    "uniprot": "A0A077ZKV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKV3"
  },
  {
    "uniprot": "A0A077Z6A9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6A9"
  },
  {
    "uniprot": "A0A077YY33",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY33"
  },
  {
    "uniprot": "A0A077ZEZ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEZ0"
  },
  {
    "uniprot": "A0A077Z027",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z027"
  },
  {
    "uniprot": "A0A077ZGP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGP0"
  },
  {
    "uniprot": "A0A077YWM4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWM4"
  },
  {
    "uniprot": "A0A077ZDF3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDF3"
  },
  {
    "uniprot": "A0A077Z185",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z185"
  },
  {
    "uniprot": "A0A077ZF60",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF60"
  },
  {
    "uniprot": "A0A077ZGL8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGL8"
  },
  {
    "uniprot": "A0A077ZJI0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJI0"
  },
  {
    "uniprot": "A0A077ZEN5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEN5"
  },
  {
    "uniprot": "A0A077YY64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY64"
  },
  {
    "uniprot": "A0A077ZBX2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBX2"
  },
  {
    "uniprot": "A0A077ZLB4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLB4"
  },
  {
    "uniprot": "A0A077ZED8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZED8"
  },
  {
    "uniprot": "A0A077ZDJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDJ2"
  },
  {
    "uniprot": "A0A077Z682",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z682"
  },
  {
    "uniprot": "A0A077YYG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYG3"
  },
  {
    "uniprot": "A0A077YVT2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVT2"
  },
  {
    "uniprot": "A0A077Z6G1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6G1"
  },
  {
    "uniprot": "A0A077ZMN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMN2"
  },
  {
    "uniprot": "A0A077ZLT2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLT2"
  },
  {
    "uniprot": "A0A077ZHD6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHD6"
  },
  {
    "uniprot": "A0A077YYH9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYH9"
  },
  {
    "uniprot": "A0A077ZL02",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL02"
  },
  {
    "uniprot": "A0A077Z1C9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1C9"
  },
  {
    "uniprot": "A0A077YZD4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZD4"
  },
  {
    "uniprot": "A0A077Z495",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z495"
  },
  {
    "uniprot": "A0A077ZKA0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKA0"
  },
  {
    "uniprot": "A0A077ZB67",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB67"
  },
  {
    "uniprot": "A0A077ZP59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP59"
  },
  {
    "uniprot": "A0A077Z4J0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4J0"
  },
  {
    "uniprot": "A0A077Z197",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z197"
  },
  {
    "uniprot": "A0A077Z9F5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9F5"
  },
  {
    "uniprot": "A0A077YZK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZK6"
  },
  {
    "uniprot": "A0A077ZIX1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIX1"
  },
  {
    "uniprot": "A0A077ZI00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI00"
  },
  {
    "uniprot": "A0A077ZGD7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGD7"
  },
  {
    "uniprot": "A0A077ZCR3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCR3"
  },
  {
    "uniprot": "A0A077ZAX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAX3"
  },
  {
    "uniprot": "A0A077ZDV2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDV2"
  },
  {
    "uniprot": "A0A077ZDH7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDH7"
  },
  {
    "uniprot": "A0A077ZEC1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEC1"
  },
  {
    "uniprot": "A0A077Z2E7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2E7"
  },
  {
    "uniprot": "A0A077ZHJ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHJ4"
  },
  {
    "uniprot": "A0A077ZGH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGH8"
  },
  {
    "uniprot": "A0A077ZEL3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEL3"
  },
  {
    "uniprot": "A0A077ZAD3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAD3"
  },
  {
    "uniprot": "A0A077ZJ56",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ56"
  },
  {
    "uniprot": "A0A077ZF16",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF16"
  },
  {
    "uniprot": "A0A077YX04",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX04"
  },
  {
    "uniprot": "A0A077ZJE6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJE6"
  },
  {
    "uniprot": "A0A077ZIY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIY6"
  },
  {
    "uniprot": "A0A077ZA77",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA77"
  },
  {
    "uniprot": "A0A077Z9S9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9S9"
  },
  {
    "uniprot": "A0A077ZBZ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBZ7"
  },
  {
    "uniprot": "A0A077Z7T5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7T5"
  },
  {
    "uniprot": "A0A077YWZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWZ9"
  },
  {
    "uniprot": "A0A077YW19",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW19"
  },
  {
    "uniprot": "A0A077YVU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVU1"
  },
  {
    "uniprot": "A0A077YXD1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXD1"
  },
  {
    "uniprot": "A0A077ZKU8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKU8"
  },
  {
    "uniprot": "A0A077ZEX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEX9"
  },
  {
    "uniprot": "A0A077Z7M4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7M4"
  },
  {
    "uniprot": "A0A077ZR05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR05"
  },
  {
    "uniprot": "A0A077ZCA4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCA4"
  },
  {
    "uniprot": "A0A077ZIE3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIE3"
  },
  {
    "uniprot": "A0A077Z2R4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2R4"
  },
  {
    "uniprot": "A0A077Z517",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z517"
  },
  {
    "uniprot": "A0A077ZFW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFW1"
  },
  {
    "uniprot": "A0A077ZD02",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD02"
  },
  {
    "uniprot": "A0A077ZJE0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJE0"
  },
  {
    "uniprot": "A0A077Z6X8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6X8"
  },
  {
    "uniprot": "A0A077Z951",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z951"
  },
  {
    "uniprot": "A0A077ZED1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZED1"
  },
  {
    "uniprot": "A0A077Z7I1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7I1"
  },
  {
    "uniprot": "A0A077ZKJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKJ3"
  },
  {
    "uniprot": "A0A077ZMP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMP8"
  },
  {
    "uniprot": "A0A077ZJQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJQ8"
  },
  {
    "uniprot": "A0A077Z7K9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7K9"
  },
  {
    "uniprot": "A0A077ZJE4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJE4"
  },
  {
    "uniprot": "A0A077ZH17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH17"
  },
  {
    "uniprot": "A0A077ZHZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHZ9"
  },
  {
    "uniprot": "A0A077YZJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZJ3"
  },
  {
    "uniprot": "A0A077YX50",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX50"
  },
  {
    "uniprot": "A0A077Z4D8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4D8"
  },
  {
    "uniprot": "A0A077ZK90",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK90"
  },
  {
    "uniprot": "A0A077ZAM0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAM0"
  },
  {
    "uniprot": "A0A077YYZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYZ8"
  },
  {
    "uniprot": "A0A077ZH53",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH53"
  },
  {
    "uniprot": "A0A077ZFL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFL6"
  },
  {
    "uniprot": "A0A077ZBM3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBM3"
  },
  {
    "uniprot": "A0A077ZBX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBX0"
  },
  {
    "uniprot": "A0A077YYL8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYL8"
  },
  {
    "uniprot": "A0A077Z3I1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3I1"
  },
  {
    "uniprot": "A0A077ZJ04",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ04"
  },
  {
    "uniprot": "A0A077Z308",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z308"
  },
  {
    "uniprot": "A0A077ZJZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJZ5"
  },
  {
    "uniprot": "A0A077Z9S8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9S8"
  },
  {
    "uniprot": "A0A077Z6M3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6M3"
  },
  {
    "uniprot": "A0A077ZH25",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH25"
  },
  {
    "uniprot": "A0A077Z527",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z527"
  },
  {
    "uniprot": "A0A077ZHL8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHL8"
  },
  {
    "uniprot": "A0A077Z6M9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6M9"
  },
  {
    "uniprot": "A0A077YX58",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX58"
  },
  {
    "uniprot": "A0A077YZP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZP5"
  },
  {
    "uniprot": "A0A077Z935",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z935"
  },
  {
    "uniprot": "A0A077Z786",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z786"
  },
  {
    "uniprot": "A0A077ZEV2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEV2"
  },
  {
    "uniprot": "A0A077ZFY5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFY5"
  },
  {
    "uniprot": "A0A077ZES7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZES7"
  },
  {
    "uniprot": "A0A077Z1Y0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Y0"
  },
  {
    "uniprot": "A0A077ZMB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMB6"
  },
  {
    "uniprot": "A0A077ZKB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKB9"
  },
  {
    "uniprot": "A0A077ZJ66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ66"
  },
  {
    "uniprot": "A0A077ZLH2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLH2"
  },
  {
    "uniprot": "A0A077Z670",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z670"
  },
  {
    "uniprot": "A0A077YVU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVU7"
  },
  {
    "uniprot": "A0A077ZBK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBK3"
  },
  {
    "uniprot": "A0A077Z837",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z837"
  },
  {
    "uniprot": "A0A077YWJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWJ2"
  },
  {
    "uniprot": "A0A077ZLE5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLE5"
  },
  {
    "uniprot": "A0A077ZCQ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCQ9"
  },
  {
    "uniprot": "A0A077YYR3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYR3"
  },
  {
    "uniprot": "A0A077ZHZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHZ3"
  },
  {
    "uniprot": "A0A077YYV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYV4"
  },
  {
    "uniprot": "A0A077ZHD2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHD2"
  },
  {
    "uniprot": "A0A077Z891",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z891"
  },
  {
    "uniprot": "A0A077ZFM9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFM9"
  },
  {
    "uniprot": "A0A077Z1E7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1E7"
  },
  {
    "uniprot": "A0A077ZC20",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC20"
  },
  {
    "uniprot": "A0A077Z0X8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0X8"
  },
  {
    "uniprot": "A0A077Z9T1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9T1"
  },
  {
    "uniprot": "A0A077Z506",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z506"
  },
  {
    "uniprot": "A0A077ZAB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAB6"
  },
  {
    "uniprot": "A0A077ZFC2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFC2"
  },
  {
    "uniprot": "A0A077ZKM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKM5"
  },
  {
    "uniprot": "A0A077ZI24",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI24"
  },
  {
    "uniprot": "A0A077Z1H1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1H1"
  },
  {
    "uniprot": "A0A077Z337",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z337"
  },
  {
    "uniprot": "A0A077ZM55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM55"
  },
  {
    "uniprot": "A0A077ZD19",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD19"
  },
  {
    "uniprot": "A0A077Z2Y9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Y9"
  },
  {
    "uniprot": "A0A077Z0Y0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Y0"
  },
  {
    "uniprot": "A0A077YZA0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZA0"
  },
  {
    "uniprot": "A0A077Z2N2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2N2"
  },
  {
    "uniprot": "A0A077Z5C5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5C5"
  },
  {
    "uniprot": "A0A077YXY5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXY5"
  },
  {
    "uniprot": "A0A077ZKR5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKR5"
  },
  {
    "uniprot": "A0A077YYR1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYR1"
  },
  {
    "uniprot": "A0A077YUN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUN7"
  },
  {
    "uniprot": "A0A077Z685",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z685"
  },
  {
    "uniprot": "A0A077YYI2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYI2"
  },
  {
    "uniprot": "A0A077ZDM9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDM9"
  },
  {
    "uniprot": "A0A077ZKV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKV4"
  },
  {
    "uniprot": "A0A077ZBN8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBN8"
  },
  {
    "uniprot": "A0A077Z540",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z540"
  },
  {
    "uniprot": "A0A077YXA3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXA3"
  },
  {
    "uniprot": "A0A077Z7Y0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Y0"
  },
  {
    "uniprot": "A0A077Z8M0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8M0"
  },
  {
    "uniprot": "A0A077ZHU8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHU8"
  },
  {
    "uniprot": "A0A077YXI1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXI1"
  },
  {
    "uniprot": "A0A077ZJ08",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ08"
  },
  {
    "uniprot": "A0A077ZKJ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKJ9"
  },
  {
    "uniprot": "A0A077ZE23",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE23"
  },
  {
    "uniprot": "A0A077ZLF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLF4"
  },
  {
    "uniprot": "A0A077ZE30",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE30"
  },
  {
    "uniprot": "A0A077Z175",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z175"
  },
  {
    "uniprot": "A0A077ZES8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZES8"
  },
  {
    "uniprot": "A0A077YYA9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYA9"
  },
  {
    "uniprot": "A0A077Z6N8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6N8"
  },
  {
    "uniprot": "A0A077Z561",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z561"
  },
  {
    "uniprot": "A0A077Z5V1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5V1"
  },
  {
    "uniprot": "A0A077YUY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUY8"
  },
  {
    "uniprot": "A0A077YZX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZX9"
  },
  {
    "uniprot": "A0A077ZMV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMV1"
  },
  {
    "uniprot": "A0A077YXI2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXI2"
  },
  {
    "uniprot": "A0A077Z326",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z326"
  },
  {
    "uniprot": "A0A077ZP93",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP93"
  },
  {
    "uniprot": "A0A077YZE3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZE3"
  },
  {
    "uniprot": "A0A077ZFJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFJ7"
  },
  {
    "uniprot": "A0A077Z194",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z194"
  },
  {
    "uniprot": "A0A077Z749",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z749"
  },
  {
    "uniprot": "A0A077ZC61",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC61"
  },
  {
    "uniprot": "A0A077ZL70",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL70"
  },
  {
    "uniprot": "A0A077Z0B2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0B2"
  },
  {
    "uniprot": "A0A077ZF38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF38"
  },
  {
    "uniprot": "A0A077ZHA9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHA9"
  },
  {
    "uniprot": "A0A077ZQD0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQD0"
  },
  {
    "uniprot": "A0A077YUX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUX6"
  },
  {
    "uniprot": "A0A077ZM25",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM25"
  },
  {
    "uniprot": "A0A077ZKE7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKE7"
  },
  {
    "uniprot": "A0A077Z1Z9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Z9"
  },
  {
    "uniprot": "A0A077Z309",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z309"
  },
  {
    "uniprot": "A0A077Z1V0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1V0"
  },
  {
    "uniprot": "A0A077ZKG6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKG6"
  },
  {
    "uniprot": "A0A077Z657",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z657"
  },
  {
    "uniprot": "A0A077ZF55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF55"
  },
  {
    "uniprot": "A0A077ZEK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEK7"
  },
  {
    "uniprot": "A0A077ZLA2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLA2"
  },
  {
    "uniprot": "A0A077Z7F4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7F4"
  },
  {
    "uniprot": "A0A077Z8E6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8E6"
  },
  {
    "uniprot": "A0A077ZM95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM95"
  },
  {
    "uniprot": "A0A077ZQA2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQA2"
  },
  {
    "uniprot": "A0A077Z890",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z890"
  },
  {
    "uniprot": "A0A077YZF7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZF7"
  },
  {
    "uniprot": "A0A077ZHI2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHI2"
  },
  {
    "uniprot": "A0A077ZKS2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKS2"
  },
  {
    "uniprot": "A0A077ZK72",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK72"
  },
  {
    "uniprot": "A0A077Z4A4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4A4"
  },
  {
    "uniprot": "A0A077ZE98",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE98"
  },
  {
    "uniprot": "A0A077ZCA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCA8"
  },
  {
    "uniprot": "A0A077ZKK8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKK8"
  },
  {
    "uniprot": "A0A077Z6F7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6F7"
  },
  {
    "uniprot": "A0A077YYG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYG7"
  },
  {
    "uniprot": "A0A077ZGW6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGW6"
  },
  {
    "uniprot": "A0A077Z6M8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6M8"
  },
  {
    "uniprot": "A0A077ZCW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCW5"
  },
  {
    "uniprot": "A0A077Z815",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z815"
  },
  {
    "uniprot": "A0A077Z7P6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7P6"
  },
  {
    "uniprot": "A0A077ZH16",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH16"
  },
  {
    "uniprot": "A0A077YYY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYY1"
  },
  {
    "uniprot": "A0A077Z280",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z280"
  },
  {
    "uniprot": "A0A077YZ01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ01"
  },
  {
    "uniprot": "A0A077Z139",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z139"
  },
  {
    "uniprot": "A0A077ZMH2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMH2"
  },
  {
    "uniprot": "A0A077ZF20",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF20"
  },
  {
    "uniprot": "A0A077Z8C8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8C8"
  },
  {
    "uniprot": "A0A077YYG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYG9"
  },
  {
    "uniprot": "A0A077YYV8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYV8"
  },
  {
    "uniprot": "A0A077Z1I0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1I0"
  },
  {
    "uniprot": "A0A077Z2Q2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Q2"
  },
  {
    "uniprot": "A0A077Z9Z0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Z0"
  },
  {
    "uniprot": "A0A077Z2Z2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Z2"
  },
  {
    "uniprot": "A0A077ZLJ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLJ6"
  },
  {
    "uniprot": "A0A077Z081",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z081"
  },
  {
    "uniprot": "A0A077ZK59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK59"
  },
  {
    "uniprot": "A0A077ZA88",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA88"
  },
  {
    "uniprot": "A0A077ZBM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBM2"
  },
  {
    "uniprot": "A0A077Z4T8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4T8"
  },
  {
    "uniprot": "A0A077Z2T4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2T4"
  },
  {
    "uniprot": "A0A077ZK45",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK45"
  },
  {
    "uniprot": "A0A077ZL49",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL49"
  },
  {
    "uniprot": "A0A077ZM94",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM94"
  },
  {
    "uniprot": "A0A077Z798",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z798"
  },
  {
    "uniprot": "A0A077Z3A3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3A3"
  },
  {
    "uniprot": "A0A077Z1K6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1K6"
  },
  {
    "uniprot": "A0A077ZMR7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMR7"
  },
  {
    "uniprot": "A0A077ZE90",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE90"
  },
  {
    "uniprot": "A0A077ZNG2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNG2"
  },
  {
    "uniprot": "A0A077Z4K5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4K5"
  },
  {
    "uniprot": "A0A077Z998",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z998"
  },
  {
    "uniprot": "A0A077ZHQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHQ0"
  },
  {
    "uniprot": "A0A077Z209",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z209"
  },
  {
    "uniprot": "A0A077ZEB3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEB3"
  },
  {
    "uniprot": "A0A077Z9R8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9R8"
  },
  {
    "uniprot": "A0A077ZKT3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKT3"
  },
  {
    "uniprot": "A0A077Z6B1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6B1"
  },
  {
    "uniprot": "A0A077ZMH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMH1"
  },
  {
    "uniprot": "A0A077ZDN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDN2"
  },
  {
    "uniprot": "A0A077ZI64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI64"
  },
  {
    "uniprot": "A0A077YZM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZM2"
  },
  {
    "uniprot": "A0A077Z6Z5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Z5"
  },
  {
    "uniprot": "A0A077ZB64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB64"
  },
  {
    "uniprot": "A0A077ZH80",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH80"
  },
  {
    "uniprot": "A0A077ZK96",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK96"
  },
  {
    "uniprot": "A0A077Z4S5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4S5"
  },
  {
    "uniprot": "A0A077Z3X5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3X5"
  },
  {
    "uniprot": "A0A077ZFN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFN2"
  },
  {
    "uniprot": "A0A077ZPA6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPA6"
  },
  {
    "uniprot": "A0A077ZIE5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIE5"
  },
  {
    "uniprot": "A0A077ZE04",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE04"
  },
  {
    "uniprot": "A0A077ZJN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJN7"
  },
  {
    "uniprot": "A0A077Z5C8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5C8"
  },
  {
    "uniprot": "A0A077YXI6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXI6"
  },
  {
    "uniprot": "A0A077ZEY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEY3"
  },
  {
    "uniprot": "A0A077YZP7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZP7"
  },
  {
    "uniprot": "A0A077ZKG1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKG1"
  },
  {
    "uniprot": "A0A077Z6C5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6C5"
  },
  {
    "uniprot": "A0A077Z274",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z274"
  },
  {
    "uniprot": "A0A077ZIJ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIJ1"
  },
  {
    "uniprot": "A0A077ZHT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHT9"
  },
  {
    "uniprot": "A0A077Z7X9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7X9"
  },
  {
    "uniprot": "A0A077Z235",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z235"
  },
  {
    "uniprot": "A0A077ZK42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK42"
  },
  {
    "uniprot": "A0A077ZDG6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDG6"
  },
  {
    "uniprot": "A0A077YYX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYX6"
  },
  {
    "uniprot": "A0A077Z0E6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0E6"
  },
  {
    "uniprot": "A0A077Z2B4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2B4"
  },
  {
    "uniprot": "A0A077ZBI9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBI9"
  },
  {
    "uniprot": "A0A077Z1M6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1M6"
  },
  {
    "uniprot": "A0A077Z647",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z647"
  },
  {
    "uniprot": "A0A077Z0U0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0U0"
  },
  {
    "uniprot": "A0A077YWG1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWG1"
  },
  {
    "uniprot": "A0A077ZH83",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH83"
  },
  {
    "uniprot": "A0A077ZGX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGX0"
  },
  {
    "uniprot": "A0A077YWG0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWG0"
  },
  {
    "uniprot": "A0A077Z085",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z085"
  },
  {
    "uniprot": "A0A077Z8K6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8K6"
  },
  {
    "uniprot": "A0A077ZCB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCB0"
  },
  {
    "uniprot": "A0A077ZGX4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGX4"
  },
  {
    "uniprot": "A0A077YZT4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZT4"
  },
  {
    "uniprot": "A0A077ZHH9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHH9"
  },
  {
    "uniprot": "A0A077Z2H3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2H3"
  },
  {
    "uniprot": "A0A077ZBQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBQ8"
  },
  {
    "uniprot": "A0A077ZE63",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE63"
  },
  {
    "uniprot": "A0A077Z4G9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4G9"
  },
  {
    "uniprot": "A0A077ZA72",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA72"
  },
  {
    "uniprot": "A0A077ZAE4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAE4"
  },
  {
    "uniprot": "A0A077ZFT3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFT3"
  },
  {
    "uniprot": "A0A077Z4T6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4T6"
  },
  {
    "uniprot": "A0A077ZL31",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL31"
  },
  {
    "uniprot": "A0A077ZE74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE74"
  },
  {
    "uniprot": "A0A077ZAP6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAP6"
  },
  {
    "uniprot": "A0A077ZDB5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDB5"
  },
  {
    "uniprot": "A0A077ZM09",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM09"
  },
  {
    "uniprot": "A0A077ZLA4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLA4"
  },
  {
    "uniprot": "A0A077ZKW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKW2"
  },
  {
    "uniprot": "A0A077ZQ55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ55"
  },
  {
    "uniprot": "A0A077ZE26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE26"
  },
  {
    "uniprot": "A0A077ZNM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNM5"
  },
  {
    "uniprot": "A0A077YYE4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYE4"
  },
  {
    "uniprot": "A0A077Z2V4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2V4"
  },
  {
    "uniprot": "A0A077Z675",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z675"
  },
  {
    "uniprot": "A0A077ZBE6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBE6"
  },
  {
    "uniprot": "A0A077ZDF5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDF5"
  },
  {
    "uniprot": "A0A077Z0P0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0P0"
  },
  {
    "uniprot": "A0A077ZDM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDM6"
  },
  {
    "uniprot": "A0A077Z3L8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3L8"
  },
  {
    "uniprot": "A0A077ZLK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLK3"
  },
  {
    "uniprot": "A0A077Z5E7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5E7"
  },
  {
    "uniprot": "A0A077Z5D8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5D8"
  },
  {
    "uniprot": "A0A077ZPJ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPJ1"
  },
  {
    "uniprot": "A0A077ZNJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNJ8"
  },
  {
    "uniprot": "A0A077Z637",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z637"
  },
  {
    "uniprot": "A0A077Z4G7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4G7"
  },
  {
    "uniprot": "A0A077ZKQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKQ3"
  },
  {
    "uniprot": "A0A077Z6A7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6A7"
  },
  {
    "uniprot": "A0A077ZAB4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAB4"
  },
  {
    "uniprot": "A0A077ZH49",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH49"
  },
  {
    "uniprot": "A0A077ZLX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLX0"
  },
  {
    "uniprot": "A0A077ZIE9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIE9"
  },
  {
    "uniprot": "A0A077Z8Z4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Z4"
  },
  {
    "uniprot": "A0A077ZBN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBN7"
  },
  {
    "uniprot": "A0A077Z9X4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9X4"
  },
  {
    "uniprot": "A0A077ZMR3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMR3"
  },
  {
    "uniprot": "A0A077ZJA3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJA3"
  },
  {
    "uniprot": "A0A077Z510",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z510"
  },
  {
    "uniprot": "A0A077Z5Z2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Z2"
  },
  {
    "uniprot": "A0A077ZP27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP27"
  },
  {
    "uniprot": "A0A077Z9I5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9I5"
  },
  {
    "uniprot": "A0A077Z981",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z981"
  },
  {
    "uniprot": "A0A077ZPE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPE2"
  },
  {
    "uniprot": "A0A077ZF68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF68"
  },
  {
    "uniprot": "A0A077ZJB3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJB3"
  },
  {
    "uniprot": "A0A077ZJN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJN6"
  },
  {
    "uniprot": "A0A077Z061",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z061"
  },
  {
    "uniprot": "A0A077ZDF6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDF6"
  },
  {
    "uniprot": "A0A077YYF1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYF1"
  },
  {
    "uniprot": "A0A077ZB91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB91"
  },
  {
    "uniprot": "A0A077YWU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWU7"
  },
  {
    "uniprot": "A0A077ZJY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJY1"
  },
  {
    "uniprot": "A0A077ZFQ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFQ9"
  },
  {
    "uniprot": "A0A077YYF6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYF6"
  },
  {
    "uniprot": "A0A077Z8D3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8D3"
  },
  {
    "uniprot": "A0A077Z2R2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2R2"
  },
  {
    "uniprot": "A0A077Z0N7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0N7"
  },
  {
    "uniprot": "A0A077Z3N0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3N0"
  },
  {
    "uniprot": "A0A077ZJT5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJT5"
  },
  {
    "uniprot": "A0A077ZLL0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLL0"
  },
  {
    "uniprot": "A0A077Z7E2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7E2"
  },
  {
    "uniprot": "A0A077Z4W0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4W0"
  },
  {
    "uniprot": "A0A077Z628",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z628"
  },
  {
    "uniprot": "A0A077ZK39",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK39"
  },
  {
    "uniprot": "A0A077Z710",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z710"
  },
  {
    "uniprot": "A0A077ZA67",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA67"
  },
  {
    "uniprot": "A0A077ZIY7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIY7"
  },
  {
    "uniprot": "A0A077Z0Y2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Y2"
  },
  {
    "uniprot": "A0A077ZMQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMQ4"
  },
  {
    "uniprot": "A0A077ZJA0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJA0"
  },
  {
    "uniprot": "A0A077Z4J7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4J7"
  },
  {
    "uniprot": "A0A077Z2V9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2V9"
  },
  {
    "uniprot": "A0A077Z4W2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4W2"
  },
  {
    "uniprot": "A0A077Z4R2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4R2"
  },
  {
    "uniprot": "A0A077Z0U5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0U5"
  },
  {
    "uniprot": "A0A077ZEW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEW7"
  },
  {
    "uniprot": "A0A077YWP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWP4"
  },
  {
    "uniprot": "A0A077ZG83",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG83"
  },
  {
    "uniprot": "A0A077ZAI6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAI6"
  },
  {
    "uniprot": "A0A077YXH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXH3"
  },
  {
    "uniprot": "A0A077YZJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZJ2"
  },
  {
    "uniprot": "A0A077Z090",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z090"
  },
  {
    "uniprot": "A0A077ZIE4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIE4"
  },
  {
    "uniprot": "A0A077ZFX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFX9"
  },
  {
    "uniprot": "A0A077Z991",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z991"
  },
  {
    "uniprot": "A0A077Z765",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z765"
  },
  {
    "uniprot": "A0A077Z9U4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9U4"
  },
  {
    "uniprot": "A0A077ZG13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG13"
  },
  {
    "uniprot": "A0A077YWT0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWT0"
  },
  {
    "uniprot": "A0A077YXU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXU6"
  },
  {
    "uniprot": "A0A077ZJX5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJX5"
  },
  {
    "uniprot": "A0A077Z486",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z486"
  },
  {
    "uniprot": "A0A077ZJA6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJA6"
  },
  {
    "uniprot": "A0A077Z8E1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8E1"
  },
  {
    "uniprot": "A0A077ZHE5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHE5"
  },
  {
    "uniprot": "A0A077ZKD2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKD2"
  },
  {
    "uniprot": "A0A077ZBP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBP4"
  },
  {
    "uniprot": "A0A077Z2E5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2E5"
  },
  {
    "uniprot": "A0A077ZAI1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAI1"
  },
  {
    "uniprot": "A0A077ZCN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCN4"
  },
  {
    "uniprot": "A0A077YYM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYM1"
  },
  {
    "uniprot": "A0A077Z5Q1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Q1"
  },
  {
    "uniprot": "A0A077Z1J8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1J8"
  },
  {
    "uniprot": "A0A077ZDQ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDQ1"
  },
  {
    "uniprot": "A0A077Z5W2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5W2"
  },
  {
    "uniprot": "A0A077ZKN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKN9"
  },
  {
    "uniprot": "A0A077YZD8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZD8"
  },
  {
    "uniprot": "A0A077Z884",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z884"
  },
  {
    "uniprot": "A0A077YZA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZA8"
  },
  {
    "uniprot": "A0A077ZJP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJP5"
  },
  {
    "uniprot": "A0A077Z3Y4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Y4"
  },
  {
    "uniprot": "A0A077ZKM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKM6"
  },
  {
    "uniprot": "A0A077Z7L4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7L4"
  },
  {
    "uniprot": "A0A077YW79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW79"
  },
  {
    "uniprot": "A0A077Z658",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z658"
  },
  {
    "uniprot": "A0A077YXM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXM7"
  },
  {
    "uniprot": "A0A077YYE7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYE7"
  },
  {
    "uniprot": "A0A077YYM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYM2"
  },
  {
    "uniprot": "A0A077Z396",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z396"
  },
  {
    "uniprot": "A0A077Z5X5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5X5"
  },
  {
    "uniprot": "A0A077Z932",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z932"
  },
  {
    "uniprot": "A0A077Z9D6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9D6"
  },
  {
    "uniprot": "A0A077Z285",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z285"
  },
  {
    "uniprot": "A0A077Z8H8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8H8"
  },
  {
    "uniprot": "A0A077YXY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXY4"
  },
  {
    "uniprot": "A0A077YVB4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVB4"
  },
  {
    "uniprot": "A0A077Z0P3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0P3"
  },
  {
    "uniprot": "A0A077Z4S9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4S9"
  },
  {
    "uniprot": "A0A077ZLX5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLX5"
  },
  {
    "uniprot": "A0A077Z1Y4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Y4"
  },
  {
    "uniprot": "A0A077Z2X5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2X5"
  },
  {
    "uniprot": "A0A077YX16",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX16"
  },
  {
    "uniprot": "A0A077ZHD9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHD9"
  },
  {
    "uniprot": "A0A077Z6B5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6B5"
  },
  {
    "uniprot": "A0A077Z7R7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7R7"
  },
  {
    "uniprot": "A0A077Z7N1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7N1"
  },
  {
    "uniprot": "A0A077Z3X1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3X1"
  },
  {
    "uniprot": "A0A077Z5S2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5S2"
  },
  {
    "uniprot": "A0A077ZK21",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK21"
  },
  {
    "uniprot": "A0A077ZHF6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHF6"
  },
  {
    "uniprot": "A0A077ZI66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI66"
  },
  {
    "uniprot": "A0A077Z6B6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6B6"
  },
  {
    "uniprot": "A0A077YVW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVW9"
  },
  {
    "uniprot": "A0A077ZDH0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDH0"
  },
  {
    "uniprot": "A0A077Z4K9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4K9"
  },
  {
    "uniprot": "A0A077ZFZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFZ3"
  },
  {
    "uniprot": "A0A077Z2W9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2W9"
  },
  {
    "uniprot": "A0A077ZCH0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCH0"
  },
  {
    "uniprot": "A0A077Z9J0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9J0"
  },
  {
    "uniprot": "A0A077Z1J1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1J1"
  },
  {
    "uniprot": "A0A077Z723",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z723"
  },
  {
    "uniprot": "A0A077ZI76",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI76"
  },
  {
    "uniprot": "A0A077Z6P3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6P3"
  },
  {
    "uniprot": "A0A077ZNS0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNS0"
  },
  {
    "uniprot": "A0A077ZGW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGW7"
  },
  {
    "uniprot": "A0A077Z216",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z216"
  },
  {
    "uniprot": "A0A077Z4L2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4L2"
  },
  {
    "uniprot": "A0A077ZNP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNP4"
  },
  {
    "uniprot": "A0A077Z7E6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7E6"
  },
  {
    "uniprot": "A0A077YZ51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ51"
  },
  {
    "uniprot": "A0A077Z228",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z228"
  },
  {
    "uniprot": "A0A077Z8X7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8X7"
  },
  {
    "uniprot": "A0A077Z6M4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6M4"
  },
  {
    "uniprot": "A0A077ZEE1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEE1"
  },
  {
    "uniprot": "A0A077Z3E4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3E4"
  },
  {
    "uniprot": "A0A077Z1Q4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Q4"
  },
  {
    "uniprot": "A0A077ZFC6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFC6"
  },
  {
    "uniprot": "A0A077YVN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVN7"
  },
  {
    "uniprot": "A0A077ZGL0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGL0"
  },
  {
    "uniprot": "A0A077Z208",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z208"
  },
  {
    "uniprot": "A0A077ZHX8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHX8"
  },
  {
    "uniprot": "A0A077ZHC2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHC2"
  },
  {
    "uniprot": "A0A077YZV8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZV8"
  },
  {
    "uniprot": "A0A077ZKB5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKB5"
  },
  {
    "uniprot": "A0A077ZD24",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD24"
  },
  {
    "uniprot": "A0A077Z9L1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9L1"
  },
  {
    "uniprot": "A0A077ZKS4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKS4"
  },
  {
    "uniprot": "A0A077ZQZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQZ4"
  },
  {
    "uniprot": "A0A077ZIP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIP3"
  },
  {
    "uniprot": "A0A077Z0G6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0G6"
  },
  {
    "uniprot": "A0A077YVX2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVX2"
  },
  {
    "uniprot": "A0A077ZAI7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAI7"
  },
  {
    "uniprot": "A0A077ZFW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFW5"
  },
  {
    "uniprot": "A0A077ZC35",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC35"
  },
  {
    "uniprot": "A0A077YVL4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVL4"
  },
  {
    "uniprot": "A0A077Z5A9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5A9"
  },
  {
    "uniprot": "A0A077Z6D5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6D5"
  },
  {
    "uniprot": "A0A077ZE87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE87"
  },
  {
    "uniprot": "A0A077YWB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWB6"
  },
  {
    "uniprot": "A0A077Z4E9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4E9"
  },
  {
    "uniprot": "A0A077YZC0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZC0"
  },
  {
    "uniprot": "A0A077ZB89",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB89"
  },
  {
    "uniprot": "A0A077ZFF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFF9"
  },
  {
    "uniprot": "A0A077Z183",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z183"
  },
  {
    "uniprot": "A0A077YWD3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWD3"
  },
  {
    "uniprot": "A0A077ZL39",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL39"
  },
  {
    "uniprot": "A0A077Z2R5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2R5"
  },
  {
    "uniprot": "A0A077Z1V5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1V5"
  },
  {
    "uniprot": "A0A077ZHR5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHR5"
  },
  {
    "uniprot": "A0A077ZDC8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDC8"
  },
  {
    "uniprot": "A0A077Z5F0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5F0"
  },
  {
    "uniprot": "A0A077ZEG0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEG0"
  },
  {
    "uniprot": "A0A077ZBC9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBC9"
  },
  {
    "uniprot": "A0A077Z0K3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0K3"
  },
  {
    "uniprot": "A0A077ZGB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGB9"
  },
  {
    "uniprot": "A0A077Z3D7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3D7"
  },
  {
    "uniprot": "A0A077Z7F7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7F7"
  },
  {
    "uniprot": "A0A077ZHM0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHM0"
  },
  {
    "uniprot": "A0A077ZIL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIL7"
  },
  {
    "uniprot": "A0A077ZHW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHW9"
  },
  {
    "uniprot": "A0A077ZMK0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMK0"
  },
  {
    "uniprot": "A0A077YYR9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYR9"
  },
  {
    "uniprot": "A0A077YXT2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXT2"
  },
  {
    "uniprot": "A0A077ZIW6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIW6"
  },
  {
    "uniprot": "A0A077ZNZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNZ3"
  },
  {
    "uniprot": "A0A077ZHP6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHP6"
  },
  {
    "uniprot": "A0A077Z6D8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6D8"
  },
  {
    "uniprot": "A0A077Z4K8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4K8"
  },
  {
    "uniprot": "A0A077YVY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVY0"
  },
  {
    "uniprot": "A0A077ZJM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJM6"
  },
  {
    "uniprot": "A0A077ZJ54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ54"
  },
  {
    "uniprot": "A0A077ZIV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIV9"
  },
  {
    "uniprot": "A0A077ZKV2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKV2"
  },
  {
    "uniprot": "A0A077Z0Q2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Q2"
  },
  {
    "uniprot": "A0A077ZK38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK38"
  },
  {
    "uniprot": "A0A077YVW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVW2"
  },
  {
    "uniprot": "A0A077ZC91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC91"
  },
  {
    "uniprot": "A0A077Z3Y2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Y2"
  },
  {
    "uniprot": "A0A077ZDD8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDD8"
  },
  {
    "uniprot": "A0A077YW89",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW89"
  },
  {
    "uniprot": "A0A077Z0A4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0A4"
  },
  {
    "uniprot": "A0A077ZF89",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF89"
  },
  {
    "uniprot": "A0A077ZIB4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIB4"
  },
  {
    "uniprot": "A0A077Z324",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z324"
  },
  {
    "uniprot": "A0A077ZGL2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGL2"
  },
  {
    "uniprot": "A0A077Z373",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z373"
  },
  {
    "uniprot": "A0A077Z3I0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3I0"
  },
  {
    "uniprot": "A0A077ZDI2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDI2"
  },
  {
    "uniprot": "A0A077ZJG1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJG1"
  },
  {
    "uniprot": "A0A077Z2Z6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Z6"
  },
  {
    "uniprot": "A0A077ZCY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCY1"
  },
  {
    "uniprot": "A0A077ZJH4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJH4"
  },
  {
    "uniprot": "A0A077Z1S8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1S8"
  },
  {
    "uniprot": "A0A077Z6I0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6I0"
  },
  {
    "uniprot": "A0A077Z9P7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9P7"
  },
  {
    "uniprot": "A0A077ZH50",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH50"
  },
  {
    "uniprot": "A0A077ZLZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLZ1"
  },
  {
    "uniprot": "A0A077ZDX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDX0"
  },
  {
    "uniprot": "A0A077Z2R3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2R3"
  },
  {
    "uniprot": "A0A077Z8N6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8N6"
  },
  {
    "uniprot": "A0A077YW22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW22"
  },
  {
    "uniprot": "A0A077Z3H8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3H8"
  },
  {
    "uniprot": "A0A077ZL95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL95"
  },
  {
    "uniprot": "A0A077Z5I1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5I1"
  },
  {
    "uniprot": "A0A077Z2Q3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Q3"
  },
  {
    "uniprot": "A0A077ZB10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB10"
  },
  {
    "uniprot": "A0A077Z7S8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7S8"
  },
  {
    "uniprot": "A0A077Z4R4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4R4"
  },
  {
    "uniprot": "A0A077YV23",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV23"
  },
  {
    "uniprot": "A0A077Z5L6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5L6"
  },
  {
    "uniprot": "A0A077ZMN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMN4"
  },
  {
    "uniprot": "A0A077YYY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYY6"
  },
  {
    "uniprot": "A0A077Z6F3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6F3"
  },
  {
    "uniprot": "A0A077Z0B6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0B6"
  },
  {
    "uniprot": "A0A077ZHS0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHS0"
  },
  {
    "uniprot": "A0A077Z4B9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4B9"
  },
  {
    "uniprot": "A0A077ZED2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZED2"
  },
  {
    "uniprot": "A0A077Z9G2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9G2"
  },
  {
    "uniprot": "A0A077YX63",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX63"
  },
  {
    "uniprot": "A0A077ZKI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKI3"
  },
  {
    "uniprot": "A0A077YWZ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWZ0"
  },
  {
    "uniprot": "A0A077Z159",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z159"
  },
  {
    "uniprot": "A0A077ZQZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQZ1"
  },
  {
    "uniprot": "A0A077ZNT2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNT2"
  },
  {
    "uniprot": "A0A077ZKQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKQ8"
  },
  {
    "uniprot": "A0A077Z7M5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7M5"
  },
  {
    "uniprot": "A0A077ZIC8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIC8"
  },
  {
    "uniprot": "A0A077Z4X1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4X1"
  },
  {
    "uniprot": "A0A077Z733",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z733"
  },
  {
    "uniprot": "A0A077ZMP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMP9"
  },
  {
    "uniprot": "A0A077Z240",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z240"
  },
  {
    "uniprot": "A0A077ZHQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHQ4"
  },
  {
    "uniprot": "A0A077Z7I6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7I6"
  },
  {
    "uniprot": "A0A077ZD22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD22"
  },
  {
    "uniprot": "A0A077ZDG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDG8"
  },
  {
    "uniprot": "A0A077ZCZ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCZ6"
  },
  {
    "uniprot": "A0A077ZC31",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC31"
  },
  {
    "uniprot": "A0A077Z700",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z700"
  },
  {
    "uniprot": "A0A077Z1A4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1A4"
  },
  {
    "uniprot": "A0A077YX95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX95"
  },
  {
    "uniprot": "A0A077ZGS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGS1"
  },
  {
    "uniprot": "A0A077ZN65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN65"
  },
  {
    "uniprot": "A0A077ZCJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCJ0"
  },
  {
    "uniprot": "A0A077Z6K1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6K1"
  },
  {
    "uniprot": "A0A077ZII7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZII7"
  },
  {
    "uniprot": "A0A077ZN03",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN03"
  },
  {
    "uniprot": "A0A077YZ34",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ34"
  },
  {
    "uniprot": "A0A077ZKI1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKI1"
  },
  {
    "uniprot": "A0A077YWZ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWZ6"
  },
  {
    "uniprot": "A0A077ZK92",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK92"
  },
  {
    "uniprot": "A0A077ZH31",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH31"
  },
  {
    "uniprot": "A0A077YZF5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZF5"
  },
  {
    "uniprot": "A0A077YYP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYP5"
  },
  {
    "uniprot": "A0A077ZLC7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLC7"
  },
  {
    "uniprot": "A0A077ZGN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGN6"
  },
  {
    "uniprot": "A0A077Z044",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z044"
  },
  {
    "uniprot": "A0A077ZJU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJU2"
  },
  {
    "uniprot": "A0A077Z135",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z135"
  },
  {
    "uniprot": "A0A077ZHB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHB9"
  },
  {
    "uniprot": "A0A077ZC51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC51"
  },
  {
    "uniprot": "A0A077Z1P9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1P9"
  },
  {
    "uniprot": "A0A077Z2U8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2U8"
  },
  {
    "uniprot": "A0A077Z202",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z202"
  },
  {
    "uniprot": "A0A077ZF50",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF50"
  },
  {
    "uniprot": "A0A077ZDE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDE2"
  },
  {
    "uniprot": "A0A077ZKZ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKZ2"
  },
  {
    "uniprot": "A0A077ZBS2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBS2"
  },
  {
    "uniprot": "A0A077Z1E3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1E3"
  },
  {
    "uniprot": "A0A077ZIH2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIH2"
  },
  {
    "uniprot": "A0A077ZKQ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKQ1"
  },
  {
    "uniprot": "A0A077Z2Z9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Z9"
  },
  {
    "uniprot": "A0A077ZCN5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCN5"
  },
  {
    "uniprot": "A0A077ZEG2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEG2"
  },
  {
    "uniprot": "A0A077ZES4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZES4"
  },
  {
    "uniprot": "A0A077Z9N7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9N7"
  },
  {
    "uniprot": "A0A077ZLD8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLD8"
  },
  {
    "uniprot": "A0A077ZJC6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJC6"
  },
  {
    "uniprot": "A0A077YVZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVZ1"
  },
  {
    "uniprot": "A0A077YXU0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXU0"
  },
  {
    "uniprot": "A0A077YZY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZY1"
  },
  {
    "uniprot": "A0A077ZMJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMJ5"
  },
  {
    "uniprot": "A0A077Z3L5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3L5"
  },
  {
    "uniprot": "A0A077ZG28",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG28"
  },
  {
    "uniprot": "A0A077ZK20",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK20"
  },
  {
    "uniprot": "A0A077ZJS5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJS5"
  },
  {
    "uniprot": "A0A077ZD83",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD83"
  },
  {
    "uniprot": "A0A077ZL67",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL67"
  },
  {
    "uniprot": "A0A077ZD48",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD48"
  },
  {
    "uniprot": "A0A077Z3N4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3N4"
  },
  {
    "uniprot": "A0A077ZAB3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAB3"
  },
  {
    "uniprot": "A0A077Z6T1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6T1"
  },
  {
    "uniprot": "A0A077ZE71",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE71"
  },
  {
    "uniprot": "A0A077ZAQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAQ2"
  },
  {
    "uniprot": "A0A077YYI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYI3"
  },
  {
    "uniprot": "A0A077Z9J5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9J5"
  },
  {
    "uniprot": "A0A077ZN99",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN99"
  },
  {
    "uniprot": "A0A077Z6G5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6G5"
  },
  {
    "uniprot": "A0A077YW05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW05"
  },
  {
    "uniprot": "A0A077Z567",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z567"
  },
  {
    "uniprot": "A0A077Z368",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z368"
  },
  {
    "uniprot": "A0A077ZDI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDI8"
  },
  {
    "uniprot": "A0A077ZBR5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBR5"
  },
  {
    "uniprot": "A0A077ZBE5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBE5"
  },
  {
    "uniprot": "A0A077ZAB1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAB1"
  },
  {
    "uniprot": "A0A077Z846",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z846"
  },
  {
    "uniprot": "A0A077Z3X8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3X8"
  },
  {
    "uniprot": "A0A077Z943",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z943"
  },
  {
    "uniprot": "A0A077ZL62",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL62"
  },
  {
    "uniprot": "A0A077Z484",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z484"
  },
  {
    "uniprot": "A0A077YZN0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZN0"
  },
  {
    "uniprot": "A0A077YZL5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZL5"
  },
  {
    "uniprot": "A0A077ZKC6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKC6"
  },
  {
    "uniprot": "A0A077ZJF1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJF1"
  },
  {
    "uniprot": "A0A077ZQF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQF4"
  },
  {
    "uniprot": "A0A077ZCQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCQ0"
  },
  {
    "uniprot": "A0A077ZF19",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF19"
  },
  {
    "uniprot": "A0A077ZFL3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFL3"
  },
  {
    "uniprot": "A0A077Z7E1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7E1"
  },
  {
    "uniprot": "A0A077Z557",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z557"
  },
  {
    "uniprot": "A0A077Z5F3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5F3"
  },
  {
    "uniprot": "A0A077ZE68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE68"
  },
  {
    "uniprot": "A0A077ZJJ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJJ6"
  },
  {
    "uniprot": "A0A077ZHE7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHE7"
  },
  {
    "uniprot": "A0A077YVM0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVM0"
  },
  {
    "uniprot": "A0A077ZLR0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLR0"
  },
  {
    "uniprot": "A0A077Z5N2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5N2"
  },
  {
    "uniprot": "A0A077Z6A1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6A1"
  },
  {
    "uniprot": "A0A077ZD96",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD96"
  },
  {
    "uniprot": "A0A077ZGW3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGW3"
  },
  {
    "uniprot": "A0A077Z4N2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4N2"
  },
  {
    "uniprot": "A0A077Z7R5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7R5"
  },
  {
    "uniprot": "A0A077Z079",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z079"
  },
  {
    "uniprot": "A0A077YW27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW27"
  },
  {
    "uniprot": "A0A077ZGU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGU6"
  },
  {
    "uniprot": "A0A077YZ07",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ07"
  },
  {
    "uniprot": "A0A077Z5U2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5U2"
  },
  {
    "uniprot": "A0A077ZCJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCJ5"
  },
  {
    "uniprot": "A0A077ZP50",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP50"
  },
  {
    "uniprot": "A0A077ZL27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL27"
  },
  {
    "uniprot": "A0A077Z5X7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5X7"
  },
  {
    "uniprot": "A0A077ZIB5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIB5"
  },
  {
    "uniprot": "A0A077ZM42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM42"
  },
  {
    "uniprot": "A0A077ZI55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI55"
  },
  {
    "uniprot": "A0A077ZJN0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJN0"
  },
  {
    "uniprot": "A0A077ZF99",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF99"
  },
  {
    "uniprot": "A0A077Z4I2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4I2"
  },
  {
    "uniprot": "A0A077ZJE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJE2"
  },
  {
    "uniprot": "A0A077ZIF2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIF2"
  },
  {
    "uniprot": "A0A077YZZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZZ3"
  },
  {
    "uniprot": "A0A077ZJ69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ69"
  },
  {
    "uniprot": "A0A077ZKZ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKZ6"
  },
  {
    "uniprot": "A0A077ZH43",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH43"
  },
  {
    "uniprot": "A0A077YYI1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYI1"
  },
  {
    "uniprot": "A0A077ZG44",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG44"
  },
  {
    "uniprot": "A0A077ZB54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB54"
  },
  {
    "uniprot": "A0A077Z048",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z048"
  },
  {
    "uniprot": "A0A077Z313",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z313"
  },
  {
    "uniprot": "A0A077ZCL4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCL4"
  },
  {
    "uniprot": "A0A077ZLV7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLV7"
  },
  {
    "uniprot": "A0A077Z6S8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6S8"
  },
  {
    "uniprot": "A0A077YXZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXZ1"
  },
  {
    "uniprot": "A0A077ZAS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAS1"
  },
  {
    "uniprot": "A0A077ZPQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPQ5"
  },
  {
    "uniprot": "A0A077ZJ46",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ46"
  },
  {
    "uniprot": "A0A077Z8L6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8L6"
  },
  {
    "uniprot": "A0A077ZLM0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLM0"
  },
  {
    "uniprot": "A0A077YZE7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZE7"
  },
  {
    "uniprot": "A0A077YWA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWA1"
  },
  {
    "uniprot": "A0A077ZM17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM17"
  },
  {
    "uniprot": "A0A077YXF2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXF2"
  },
  {
    "uniprot": "A0A077Z120",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z120"
  },
  {
    "uniprot": "A0A077Z8A4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8A4"
  },
  {
    "uniprot": "A0A077Z7N6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7N6"
  },
  {
    "uniprot": "A0A077ZIJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIJ5"
  },
  {
    "uniprot": "A0A077Z0I4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0I4"
  },
  {
    "uniprot": "A0A077Z1M5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1M5"
  },
  {
    "uniprot": "A0A077ZM71",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM71"
  },
  {
    "uniprot": "A0A077Z348",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z348"
  },
  {
    "uniprot": "A0A077YYZ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYZ7"
  },
  {
    "uniprot": "A0A077Z9F9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9F9"
  },
  {
    "uniprot": "A0A077ZA10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA10"
  },
  {
    "uniprot": "A0A077ZDC7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDC7"
  },
  {
    "uniprot": "A0A077ZEJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEJ2"
  },
  {
    "uniprot": "A0A077ZC15",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC15"
  },
  {
    "uniprot": "A0A077ZAR5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAR5"
  },
  {
    "uniprot": "A0A077Z3G5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3G5"
  },
  {
    "uniprot": "A0A077Z4H3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4H3"
  },
  {
    "uniprot": "A0A077ZMJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMJ0"
  },
  {
    "uniprot": "A0A077ZL97",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL97"
  },
  {
    "uniprot": "A0A077ZCK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCK9"
  },
  {
    "uniprot": "A0A077ZCC9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCC9"
  },
  {
    "uniprot": "A0A077Z199",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z199"
  },
  {
    "uniprot": "A0A077ZDK5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDK5"
  },
  {
    "uniprot": "A0A077ZEU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEU7"
  },
  {
    "uniprot": "A0A077YXV5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXV5"
  },
  {
    "uniprot": "A0A077ZBF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBF0"
  },
  {
    "uniprot": "A0A077ZJL0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJL0"
  },
  {
    "uniprot": "A0A077ZBX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBX7"
  },
  {
    "uniprot": "A0A077Z5S0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5S0"
  },
  {
    "uniprot": "A0A077Z6V1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6V1"
  },
  {
    "uniprot": "A0A077ZEH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEH5"
  },
  {
    "uniprot": "A0A077Z502",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z502"
  },
  {
    "uniprot": "A0A077ZLW3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLW3"
  },
  {
    "uniprot": "A0A077ZDB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDB0"
  },
  {
    "uniprot": "A0A077Z638",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z638"
  },
  {
    "uniprot": "A0A077Z3J0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3J0"
  },
  {
    "uniprot": "A0A077YYV2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYV2"
  },
  {
    "uniprot": "A0A077ZIF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIF4"
  },
  {
    "uniprot": "A0A077ZJ19",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ19"
  },
  {
    "uniprot": "A0A077ZJ18",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ18"
  },
  {
    "uniprot": "A0A077Z400",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z400"
  },
  {
    "uniprot": "A0A077ZBH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBH8"
  },
  {
    "uniprot": "A0A077Z097",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z097"
  },
  {
    "uniprot": "A0A077ZLU0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLU0"
  },
  {
    "uniprot": "A0A077Z619",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z619"
  },
  {
    "uniprot": "A0A077ZNB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNB6"
  },
  {
    "uniprot": "A0A077YY14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY14"
  },
  {
    "uniprot": "A0A077ZDG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDG7"
  },
  {
    "uniprot": "A0A077Z3D0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3D0"
  },
  {
    "uniprot": "A0A077Z5D5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5D5"
  },
  {
    "uniprot": "A0A077ZBQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBQ5"
  },
  {
    "uniprot": "A0A077YYA0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYA0"
  },
  {
    "uniprot": "A0A077ZI04",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI04"
  },
  {
    "uniprot": "A0A077Z2T7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2T7"
  },
  {
    "uniprot": "A0A077ZMU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMU6"
  },
  {
    "uniprot": "A0A077Z3R7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3R7"
  },
  {
    "uniprot": "A0A077Z5U1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5U1"
  },
  {
    "uniprot": "A0A077Z1X6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1X6"
  },
  {
    "uniprot": "A0A077Z013",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z013"
  },
  {
    "uniprot": "A0A077Z259",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z259"
  },
  {
    "uniprot": "A0A077ZGY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGY1"
  },
  {
    "uniprot": "A0A077Z0I6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0I6"
  },
  {
    "uniprot": "A0A077Z5L7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5L7"
  },
  {
    "uniprot": "A0A077Z5I5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5I5"
  },
  {
    "uniprot": "A0A077YY79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY79"
  },
  {
    "uniprot": "A0A077ZLM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLM5"
  },
  {
    "uniprot": "A0A077ZAK5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAK5"
  },
  {
    "uniprot": "A0A077YX62",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX62"
  },
  {
    "uniprot": "A0A077ZDA6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDA6"
  },
  {
    "uniprot": "A0A077ZBY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBY0"
  },
  {
    "uniprot": "A0A077Z7A7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7A7"
  },
  {
    "uniprot": "A0A077ZM83",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM83"
  },
  {
    "uniprot": "A0A077ZBX8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBX8"
  },
  {
    "uniprot": "A0A077Z5Z1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Z1"
  },
  {
    "uniprot": "A0A077ZEG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEG9"
  },
  {
    "uniprot": "A0A077YZZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZZ5"
  },
  {
    "uniprot": "A0A077ZID6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZID6"
  },
  {
    "uniprot": "A0A077ZB83",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB83"
  },
  {
    "uniprot": "A0A077YWJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWJ0"
  },
  {
    "uniprot": "A0A077ZB41",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB41"
  },
  {
    "uniprot": "A0A077Z1H8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1H8"
  },
  {
    "uniprot": "A0A077YZX1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZX1"
  },
  {
    "uniprot": "A0A077Z6C1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6C1"
  },
  {
    "uniprot": "A0A077Z8L7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8L7"
  },
  {
    "uniprot": "A0A077Z4G6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4G6"
  },
  {
    "uniprot": "A0A077ZFK2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFK2"
  },
  {
    "uniprot": "A0A077Z858",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z858"
  },
  {
    "uniprot": "A0A077ZE38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE38"
  },
  {
    "uniprot": "A0A077ZFK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFK9"
  },
  {
    "uniprot": "A0A077Z1A8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1A8"
  },
  {
    "uniprot": "A0A077Z4G5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4G5"
  },
  {
    "uniprot": "A0A077ZD97",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD97"
  },
  {
    "uniprot": "A0A077ZCT3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCT3"
  },
  {
    "uniprot": "A0A077ZES0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZES0"
  },
  {
    "uniprot": "A0A077Z6N4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6N4"
  },
  {
    "uniprot": "A0A077ZGQ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGQ9"
  },
  {
    "uniprot": "A0A077Z2Q9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Q9"
  },
  {
    "uniprot": "A0A077Z3K5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3K5"
  },
  {
    "uniprot": "A0A077Z0R0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0R0"
  },
  {
    "uniprot": "A0A077ZIZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIZ1"
  },
  {
    "uniprot": "A0A077Z329",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z329"
  },
  {
    "uniprot": "A0A077ZH94",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH94"
  },
  {
    "uniprot": "A0A077Z687",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z687"
  },
  {
    "uniprot": "A0A077ZKV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKV6"
  },
  {
    "uniprot": "A0A077Z3T0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3T0"
  },
  {
    "uniprot": "A0A077Z646",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z646"
  },
  {
    "uniprot": "A0A077Z802",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z802"
  },
  {
    "uniprot": "A0A077YZU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZU9"
  },
  {
    "uniprot": "A0A077Z4C6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4C6"
  },
  {
    "uniprot": "A0A077ZJH2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJH2"
  },
  {
    "uniprot": "A0A077ZAQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAQ4"
  },
  {
    "uniprot": "A0A077ZEF3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEF3"
  },
  {
    "uniprot": "A0A077Z671",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z671"
  },
  {
    "uniprot": "A0A077ZCA7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCA7"
  },
  {
    "uniprot": "A0A077Z4Y9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Y9"
  },
  {
    "uniprot": "A0A077ZCK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCK4"
  },
  {
    "uniprot": "A0A077ZFJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFJ3"
  },
  {
    "uniprot": "A0A077ZM84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM84"
  },
  {
    "uniprot": "A0A077Z022",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z022"
  },
  {
    "uniprot": "A0A077ZG12",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG12"
  },
  {
    "uniprot": "A0A077ZAM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAM5"
  },
  {
    "uniprot": "A0A077Z0B8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0B8"
  },
  {
    "uniprot": "A0A077Z9N2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9N2"
  },
  {
    "uniprot": "A0A077Z1H0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1H0"
  },
  {
    "uniprot": "A0A077YVS4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVS4"
  },
  {
    "uniprot": "A0A077ZB08",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB08"
  },
  {
    "uniprot": "A0A077Z6L4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6L4"
  },
  {
    "uniprot": "A0A077ZGN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGN2"
  },
  {
    "uniprot": "A0A077ZGY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGY8"
  },
  {
    "uniprot": "A0A077ZFN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFN7"
  },
  {
    "uniprot": "A0A077Z0C1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0C1"
  },
  {
    "uniprot": "A0A077ZLE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLE2"
  },
  {
    "uniprot": "A0A077Z0Q3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Q3"
  },
  {
    "uniprot": "A0A077Z057",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z057"
  },
  {
    "uniprot": "A0A077ZE08",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE08"
  },
  {
    "uniprot": "A0A077ZIG6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIG6"
  },
  {
    "uniprot": "A0A077ZL40",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL40"
  },
  {
    "uniprot": "A0A077Z0W3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0W3"
  },
  {
    "uniprot": "A0A077YZS6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZS6"
  },
  {
    "uniprot": "A0A077YYY7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYY7"
  },
  {
    "uniprot": "A0A077YXS4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXS4"
  },
  {
    "uniprot": "A0A077ZL35",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL35"
  },
  {
    "uniprot": "A0A077YW24",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW24"
  },
  {
    "uniprot": "A0A077YWK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWK7"
  },
  {
    "uniprot": "A0A077ZE40",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE40"
  },
  {
    "uniprot": "A0A077Z0P8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0P8"
  },
  {
    "uniprot": "A0A077Z1E5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1E5"
  },
  {
    "uniprot": "A0A077YWL1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWL1"
  },
  {
    "uniprot": "A0A077ZEA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEA5"
  },
  {
    "uniprot": "A0A077YZR8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZR8"
  },
  {
    "uniprot": "A0A077Z431",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z431"
  },
  {
    "uniprot": "A0A077Z2Q4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Q4"
  },
  {
    "uniprot": "A0A077Z2Z4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Z4"
  },
  {
    "uniprot": "A0A077YXJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXJ8"
  },
  {
    "uniprot": "A0A077Z0E2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0E2"
  },
  {
    "uniprot": "A0A077ZHB1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHB1"
  },
  {
    "uniprot": "A0A077Z6J7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6J7"
  },
  {
    "uniprot": "A0A077Z0W2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0W2"
  },
  {
    "uniprot": "A0A077YYH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYH3"
  },
  {
    "uniprot": "A0A077ZFA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFA8"
  },
  {
    "uniprot": "A0A077Z6U4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6U4"
  },
  {
    "uniprot": "A0A077ZL59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL59"
  },
  {
    "uniprot": "A0A077ZQL0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQL0"
  },
  {
    "uniprot": "A0A077ZBV8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBV8"
  },
  {
    "uniprot": "A0A077ZLK8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLK8"
  },
  {
    "uniprot": "A0A077ZJB7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJB7"
  },
  {
    "uniprot": "A0A077ZH89",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH89"
  },
  {
    "uniprot": "A0A077YZA2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZA2"
  },
  {
    "uniprot": "A0A077Z145",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z145"
  },
  {
    "uniprot": "A0A077YYK5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYK5"
  },
  {
    "uniprot": "A0A077ZBG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBG5"
  },
  {
    "uniprot": "A0A077ZD04",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD04"
  },
  {
    "uniprot": "A0A077ZBK1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBK1"
  },
  {
    "uniprot": "A0A077YZ04",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ04"
  },
  {
    "uniprot": "A0A077ZKF8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKF8"
  },
  {
    "uniprot": "A0A077Z0T5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0T5"
  },
  {
    "uniprot": "A0A077ZKJ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKJ1"
  },
  {
    "uniprot": "A0A077Z0V4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0V4"
  },
  {
    "uniprot": "A0A077ZA13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA13"
  },
  {
    "uniprot": "A0A077ZPW3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPW3"
  },
  {
    "uniprot": "A0A077Z286",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z286"
  },
  {
    "uniprot": "A0A077Z4P4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4P4"
  },
  {
    "uniprot": "A0A077ZJD0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJD0"
  },
  {
    "uniprot": "A0A077YZJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZJ5"
  },
  {
    "uniprot": "A0A077Z4V0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4V0"
  },
  {
    "uniprot": "A0A077ZKR0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKR0"
  },
  {
    "uniprot": "A0A077Z0G5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0G5"
  },
  {
    "uniprot": "A0A077Z3T8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3T8"
  },
  {
    "uniprot": "A0A077ZPX1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPX1"
  },
  {
    "uniprot": "A0A077ZG42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG42"
  },
  {
    "uniprot": "A0A077ZPV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPV6"
  },
  {
    "uniprot": "A0A077ZM39",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM39"
  },
  {
    "uniprot": "A0A077ZG17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG17"
  },
  {
    "uniprot": "A0A077Z028",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z028"
  },
  {
    "uniprot": "A0A077YXI0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXI0"
  },
  {
    "uniprot": "A0A077ZLW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLW1"
  },
  {
    "uniprot": "A0A077YZN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZN9"
  },
  {
    "uniprot": "A0A077YYZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYZ3"
  },
  {
    "uniprot": "A0A077ZB24",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB24"
  },
  {
    "uniprot": "A0A077Z4K6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4K6"
  },
  {
    "uniprot": "A0A077YWT1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWT1"
  },
  {
    "uniprot": "A0A077Z915",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z915"
  },
  {
    "uniprot": "A0A077ZKW3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKW3"
  },
  {
    "uniprot": "A0A077Z706",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z706"
  },
  {
    "uniprot": "A0A077ZBD6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBD6"
  },
  {
    "uniprot": "A0A077ZD99",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD99"
  },
  {
    "uniprot": "A0A077ZBJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBJ3"
  },
  {
    "uniprot": "A0A077ZG39",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG39"
  },
  {
    "uniprot": "A0A077YWI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWI3"
  },
  {
    "uniprot": "A0A077Z5T6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5T6"
  },
  {
    "uniprot": "A0A077ZFW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFW9"
  },
  {
    "uniprot": "A0A077Z885",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z885"
  },
  {
    "uniprot": "A0A077ZJ32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ32"
  },
  {
    "uniprot": "A0A077ZB51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB51"
  },
  {
    "uniprot": "A0A077YYL3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYL3"
  },
  {
    "uniprot": "A0A077ZB04",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB04"
  },
  {
    "uniprot": "A0A077ZDV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDV6"
  },
  {
    "uniprot": "A0A077ZBE4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBE4"
  },
  {
    "uniprot": "A0A077YXV0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXV0"
  },
  {
    "uniprot": "A0A077ZMP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMP5"
  },
  {
    "uniprot": "A0A077YZL9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZL9"
  },
  {
    "uniprot": "A0A077ZQ02",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ02"
  },
  {
    "uniprot": "A0A077YYJ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYJ4"
  },
  {
    "uniprot": "A0A077YVJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVJ3"
  },
  {
    "uniprot": "A0A077Z2C5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2C5"
  },
  {
    "uniprot": "A0A077Z3K6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3K6"
  },
  {
    "uniprot": "A0A077ZJU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJU9"
  },
  {
    "uniprot": "A0A077ZBS5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBS5"
  },
  {
    "uniprot": "A0A077Z7C7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7C7"
  },
  {
    "uniprot": "A0A077ZGM4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGM4"
  },
  {
    "uniprot": "A0A077YWX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWX9"
  },
  {
    "uniprot": "A0A077YZA3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZA3"
  },
  {
    "uniprot": "A0A077Z673",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z673"
  },
  {
    "uniprot": "A0A077Z4P9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4P9"
  },
  {
    "uniprot": "A0A077Z6K6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6K6"
  },
  {
    "uniprot": "A0A077Z578",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z578"
  },
  {
    "uniprot": "A0A077ZAD7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAD7"
  },
  {
    "uniprot": "A0A077Z6T8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6T8"
  },
  {
    "uniprot": "A0A077Z581",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z581"
  },
  {
    "uniprot": "A0A077ZCW4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCW4"
  },
  {
    "uniprot": "A0A077ZFY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFY1"
  },
  {
    "uniprot": "A0A077ZCQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCQ4"
  },
  {
    "uniprot": "A0A077ZAG0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAG0"
  },
  {
    "uniprot": "A0A077YY17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY17"
  },
  {
    "uniprot": "A0A077ZH95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH95"
  },
  {
    "uniprot": "A0A077Z636",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z636"
  },
  {
    "uniprot": "A0A077Z184",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z184"
  },
  {
    "uniprot": "A0A077ZQ64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ64"
  },
  {
    "uniprot": "A0A077ZDE1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDE1"
  },
  {
    "uniprot": "A0A077Z1B2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1B2"
  },
  {
    "uniprot": "A0A077Z478",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z478"
  },
  {
    "uniprot": "A0A077ZGH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGH3"
  },
  {
    "uniprot": "A0A077Z966",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z966"
  },
  {
    "uniprot": "A0A077ZKT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKT9"
  },
  {
    "uniprot": "A0A077ZCM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCM6"
  },
  {
    "uniprot": "A0A077ZG52",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG52"
  },
  {
    "uniprot": "A0A077ZG73",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG73"
  },
  {
    "uniprot": "A0A077ZFV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFV9"
  },
  {
    "uniprot": "A0A077Z4U7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4U7"
  },
  {
    "uniprot": "A0A077YYK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYK3"
  },
  {
    "uniprot": "A0A077ZFJ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFJ4"
  },
  {
    "uniprot": "A0A077Z565",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z565"
  },
  {
    "uniprot": "A0A077ZNA3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNA3"
  },
  {
    "uniprot": "A0A077Z475",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z475"
  },
  {
    "uniprot": "A0A077Z409",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z409"
  },
  {
    "uniprot": "A0A077ZHG0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHG0"
  },
  {
    "uniprot": "A0A077Z1H5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1H5"
  },
  {
    "uniprot": "A0A077Z2J5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2J5"
  },
  {
    "uniprot": "A0A077ZH74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH74"
  },
  {
    "uniprot": "A0A077ZDL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDL6"
  },
  {
    "uniprot": "A0A077Z4A1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4A1"
  },
  {
    "uniprot": "A0A077YWK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWK9"
  },
  {
    "uniprot": "A0A077Z1H4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1H4"
  },
  {
    "uniprot": "A0A077ZIY5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIY5"
  },
  {
    "uniprot": "A0A077Z473",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z473"
  },
  {
    "uniprot": "A0A077ZFG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFG8"
  },
  {
    "uniprot": "A0A077Z1R4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1R4"
  },
  {
    "uniprot": "A0A077YXS6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXS6"
  },
  {
    "uniprot": "A0A077ZKJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKJ0"
  },
  {
    "uniprot": "A0A077ZLW6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLW6"
  },
  {
    "uniprot": "A0A077YYU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYU7"
  },
  {
    "uniprot": "A0A077ZEG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEG5"
  },
  {
    "uniprot": "A0A077YWS5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWS5"
  },
  {
    "uniprot": "A0A077YYR7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYR7"
  },
  {
    "uniprot": "A0A077YXJ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXJ4"
  },
  {
    "uniprot": "A0A077Z304",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z304"
  },
  {
    "uniprot": "A0A077ZGS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGS9"
  },
  {
    "uniprot": "A0A077ZEA7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEA7"
  },
  {
    "uniprot": "A0A077ZG86",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG86"
  },
  {
    "uniprot": "A0A077Z863",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z863"
  },
  {
    "uniprot": "A0A077ZMD2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMD2"
  },
  {
    "uniprot": "A0A077ZJV7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJV7"
  },
  {
    "uniprot": "A0A077Z4P8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4P8"
  },
  {
    "uniprot": "A0A077Z6J5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6J5"
  },
  {
    "uniprot": "A0A077ZML8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZML8"
  },
  {
    "uniprot": "A0A077YVQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVQ3"
  },
  {
    "uniprot": "A0A077ZFP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFP5"
  },
  {
    "uniprot": "A0A077ZEQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEQ2"
  },
  {
    "uniprot": "A0A077ZL71",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL71"
  },
  {
    "uniprot": "A0A077ZDF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDF4"
  },
  {
    "uniprot": "A0A077ZL76",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL76"
  },
  {
    "uniprot": "A0A077YYM9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYM9"
  },
  {
    "uniprot": "A0A077ZKT4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKT4"
  },
  {
    "uniprot": "A0A077ZF28",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF28"
  },
  {
    "uniprot": "A0A077ZHY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHY1"
  },
  {
    "uniprot": "A0A077ZBT2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBT2"
  },
  {
    "uniprot": "A0A077Z458",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z458"
  },
  {
    "uniprot": "A0A077YWT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWT9"
  },
  {
    "uniprot": "A0A077ZQU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQU7"
  },
  {
    "uniprot": "A0A077YWP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWP9"
  },
  {
    "uniprot": "A0A077YXH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXH8"
  },
  {
    "uniprot": "A0A077ZNN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNN7"
  },
  {
    "uniprot": "A0A077ZHC0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHC0"
  },
  {
    "uniprot": "A0A077ZCU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCU2"
  },
  {
    "uniprot": "A0A077ZJH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJH1"
  },
  {
    "uniprot": "A0A077YVU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVU4"
  },
  {
    "uniprot": "A0A077Z3T4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3T4"
  },
  {
    "uniprot": "A0A077ZCF3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCF3"
  },
  {
    "uniprot": "A0A077YXE3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXE3"
  },
  {
    "uniprot": "A0A077ZLZ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLZ0"
  },
  {
    "uniprot": "A0A077Z1H2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1H2"
  },
  {
    "uniprot": "A0A077ZJR2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJR2"
  },
  {
    "uniprot": "A0A077ZH71",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH71"
  },
  {
    "uniprot": "A0A077ZNI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNI8"
  },
  {
    "uniprot": "A0A077ZE47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE47"
  },
  {
    "uniprot": "A0A077ZG07",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG07"
  },
  {
    "uniprot": "A0A077ZEM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEM6"
  },
  {
    "uniprot": "A0A077ZIG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIG9"
  },
  {
    "uniprot": "A0A077ZCY2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCY2"
  },
  {
    "uniprot": "A0A077Z3Z3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Z3"
  },
  {
    "uniprot": "A0A077YYM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYM7"
  },
  {
    "uniprot": "A0A077ZL83",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL83"
  },
  {
    "uniprot": "A0A077ZEV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEV6"
  },
  {
    "uniprot": "A0A077Z7N0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7N0"
  },
  {
    "uniprot": "A0A077ZD78",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD78"
  },
  {
    "uniprot": "A0A077ZA53",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA53"
  },
  {
    "uniprot": "A0A077ZEK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEK6"
  },
  {
    "uniprot": "A0A077YV77",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV77"
  },
  {
    "uniprot": "A0A077YZM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZM8"
  },
  {
    "uniprot": "A0A077ZBC5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBC5"
  },
  {
    "uniprot": "A0A077Z8M9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8M9"
  },
  {
    "uniprot": "A0A077Z613",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z613"
  },
  {
    "uniprot": "A0A077YY55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY55"
  },
  {
    "uniprot": "A0A077ZL58",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL58"
  },
  {
    "uniprot": "A0A077ZH98",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH98"
  },
  {
    "uniprot": "A0A077Z2S3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2S3"
  },
  {
    "uniprot": "A0A077ZAI0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAI0"
  },
  {
    "uniprot": "A0A077ZHN5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHN5"
  },
  {
    "uniprot": "A0A077ZI17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI17"
  },
  {
    "uniprot": "A0A077YYH2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYH2"
  },
  {
    "uniprot": "A0A077Z2Y4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Y4"
  },
  {
    "uniprot": "A0A077ZDS4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDS4"
  },
  {
    "uniprot": "A0A077Z3M1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3M1"
  },
  {
    "uniprot": "A0A077Z887",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z887"
  },
  {
    "uniprot": "A0A077ZEH2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEH2"
  },
  {
    "uniprot": "A0A077Z0W5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0W5"
  },
  {
    "uniprot": "A0A077Z366",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z366"
  },
  {
    "uniprot": "A0A077ZBV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBV1"
  },
  {
    "uniprot": "A0A077Z3E3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3E3"
  },
  {
    "uniprot": "A0A077ZKF1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKF1"
  },
  {
    "uniprot": "A0A077Z952",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z952"
  },
  {
    "uniprot": "A0A077Z3N7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3N7"
  },
  {
    "uniprot": "A0A077ZMX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMX7"
  },
  {
    "uniprot": "A0A077Z3J6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3J6"
  },
  {
    "uniprot": "A0A077Z7H2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7H2"
  },
  {
    "uniprot": "A0A077Z1C2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1C2"
  },
  {
    "uniprot": "A0A077Z7U0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7U0"
  },
  {
    "uniprot": "A0A077ZAK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAK6"
  },
  {
    "uniprot": "A0A077ZJZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJZ3"
  },
  {
    "uniprot": "A0A077YZ27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ27"
  },
  {
    "uniprot": "A0A077Z9E4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9E4"
  },
  {
    "uniprot": "A0A077ZGE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGE2"
  },
  {
    "uniprot": "A0A077ZJP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJP8"
  },
  {
    "uniprot": "A0A077YVX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVX6"
  },
  {
    "uniprot": "A0A077ZB71",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB71"
  },
  {
    "uniprot": "A0A077ZBY7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBY7"
  },
  {
    "uniprot": "A0A077ZG41",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG41"
  },
  {
    "uniprot": "A0A077ZI52",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI52"
  },
  {
    "uniprot": "A0A077YUJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUJ5"
  },
  {
    "uniprot": "A0A077ZJL8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJL8"
  },
  {
    "uniprot": "A0A077ZCB8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCB8"
  },
  {
    "uniprot": "A0A077Z3C1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3C1"
  },
  {
    "uniprot": "A0A077ZFH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFH5"
  },
  {
    "uniprot": "A0A077ZDT2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDT2"
  },
  {
    "uniprot": "A0A077ZE82",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE82"
  },
  {
    "uniprot": "A0A077Z1W2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1W2"
  },
  {
    "uniprot": "A0A077ZBQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBQ4"
  },
  {
    "uniprot": "A0A077Z9E0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9E0"
  },
  {
    "uniprot": "A0A077Z296",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z296"
  },
  {
    "uniprot": "A0A077ZMS2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMS2"
  },
  {
    "uniprot": "A0A077ZIS7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIS7"
  },
  {
    "uniprot": "A0A077ZMW4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMW4"
  },
  {
    "uniprot": "A0A077ZGF7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGF7"
  },
  {
    "uniprot": "A0A077ZJX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJX6"
  },
  {
    "uniprot": "A0A077ZHR2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHR2"
  },
  {
    "uniprot": "A0A077ZFD3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFD3"
  },
  {
    "uniprot": "A0A077YVM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVM5"
  },
  {
    "uniprot": "A0A077YZI2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZI2"
  },
  {
    "uniprot": "A0A077Z5I0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5I0"
  },
  {
    "uniprot": "A0A077Z5G4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5G4"
  },
  {
    "uniprot": "A0A077YVP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVP2"
  },
  {
    "uniprot": "A0A077ZM19",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM19"
  },
  {
    "uniprot": "A0A077YZV5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZV5"
  },
  {
    "uniprot": "A0A077Z6S4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6S4"
  },
  {
    "uniprot": "A0A077Z236",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z236"
  },
  {
    "uniprot": "A0A077ZG97",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG97"
  },
  {
    "uniprot": "A0A077YXH2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXH2"
  },
  {
    "uniprot": "A0A077Z5R9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5R9"
  },
  {
    "uniprot": "A0A077ZBC6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBC6"
  },
  {
    "uniprot": "A0A077ZHW3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHW3"
  },
  {
    "uniprot": "A0A077ZF29",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF29"
  },
  {
    "uniprot": "A0A077ZJ84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ84"
  },
  {
    "uniprot": "A0A077Z6Y6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Y6"
  },
  {
    "uniprot": "A0A077YX69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX69"
  },
  {
    "uniprot": "A0A077YWJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWJ7"
  },
  {
    "uniprot": "A0A077ZGL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGL7"
  },
  {
    "uniprot": "A0A077ZL30",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL30"
  },
  {
    "uniprot": "A0A077Z3L3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3L3"
  },
  {
    "uniprot": "A0A077Z5Z5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Z5"
  },
  {
    "uniprot": "A0A077Z150",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z150"
  },
  {
    "uniprot": "A0A077Z9L0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9L0"
  },
  {
    "uniprot": "A0A077Z9J6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9J6"
  },
  {
    "uniprot": "A0A077ZD91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD91"
  },
  {
    "uniprot": "A0A077ZGA4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGA4"
  },
  {
    "uniprot": "A0A077ZKT1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKT1"
  },
  {
    "uniprot": "A0A077ZD79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD79"
  },
  {
    "uniprot": "A0A077Z0D8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0D8"
  },
  {
    "uniprot": "A0A077ZLQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLQ4"
  },
  {
    "uniprot": "A0A077Z2Y1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Y1"
  },
  {
    "uniprot": "A0A077Z696",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z696"
  },
  {
    "uniprot": "A0A077Z922",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z922"
  },
  {
    "uniprot": "A0A077ZKE6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKE6"
  },
  {
    "uniprot": "A0A077YYS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYS1"
  },
  {
    "uniprot": "A0A077ZKE3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKE3"
  },
  {
    "uniprot": "A0A077ZK63",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK63"
  },
  {
    "uniprot": "A0A077Z5Z4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Z4"
  },
  {
    "uniprot": "A0A077ZGR1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGR1"
  },
  {
    "uniprot": "A0A077ZFJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFJ2"
  },
  {
    "uniprot": "A0A077Z4K2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4K2"
  },
  {
    "uniprot": "A0A077ZFD8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFD8"
  },
  {
    "uniprot": "A0A077ZF81",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF81"
  },
  {
    "uniprot": "A0A077ZCW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCW0"
  },
  {
    "uniprot": "A0A077ZD86",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD86"
  },
  {
    "uniprot": "A0A077YX81",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX81"
  },
  {
    "uniprot": "A0A077Z0S4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0S4"
  },
  {
    "uniprot": "A0A077Z840",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z840"
  },
  {
    "uniprot": "A0A077Z7M6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7M6"
  },
  {
    "uniprot": "A0A077Z742",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z742"
  },
  {
    "uniprot": "A0A077Z4L7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4L7"
  },
  {
    "uniprot": "A0A077Z4B1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4B1"
  },
  {
    "uniprot": "A0A077YXW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXW0"
  },
  {
    "uniprot": "A0A077ZCV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCV4"
  },
  {
    "uniprot": "A0A077ZA20",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA20"
  },
  {
    "uniprot": "A0A077ZGU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGU4"
  },
  {
    "uniprot": "A0A077ZHA3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHA3"
  },
  {
    "uniprot": "A0A077Z7D4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7D4"
  },
  {
    "uniprot": "A0A077Z6H9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6H9"
  },
  {
    "uniprot": "A0A077Z3J9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3J9"
  },
  {
    "uniprot": "A0A077Z4Y1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Y1"
  },
  {
    "uniprot": "A0A077Z3E2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3E2"
  },
  {
    "uniprot": "A0A077ZM07",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM07"
  },
  {
    "uniprot": "A0A077ZKU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKU4"
  },
  {
    "uniprot": "A0A077ZI58",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI58"
  },
  {
    "uniprot": "A0A077Z0J0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0J0"
  },
  {
    "uniprot": "A0A077Z832",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z832"
  },
  {
    "uniprot": "A0A077ZL50",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL50"
  },
  {
    "uniprot": "A0A077Z0I7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0I7"
  },
  {
    "uniprot": "A0A077Z5Y2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Y2"
  },
  {
    "uniprot": "A0A077Z2I5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2I5"
  },
  {
    "uniprot": "A0A077Z388",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z388"
  },
  {
    "uniprot": "A0A077YVY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVY9"
  },
  {
    "uniprot": "A0A077ZFK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFK7"
  },
  {
    "uniprot": "A0A077YY19",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY19"
  },
  {
    "uniprot": "A0A077ZKR4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKR4"
  },
  {
    "uniprot": "A0A077Z593",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z593"
  },
  {
    "uniprot": "A0A077Z860",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z860"
  },
  {
    "uniprot": "A0A077ZIG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIG7"
  },
  {
    "uniprot": "A0A077ZCI2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCI2"
  },
  {
    "uniprot": "A0A077Z6X5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6X5"
  },
  {
    "uniprot": "A0A077ZDF2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDF2"
  },
  {
    "uniprot": "A0A077Z698",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z698"
  },
  {
    "uniprot": "A0A077ZFP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFP3"
  },
  {
    "uniprot": "A0A077Z914",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z914"
  },
  {
    "uniprot": "A0A077Z1W5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1W5"
  },
  {
    "uniprot": "A0A077ZBB7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBB7"
  },
  {
    "uniprot": "A0A077Z1Z2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Z2"
  },
  {
    "uniprot": "A0A077ZAG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAG8"
  },
  {
    "uniprot": "A0A077YZ32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ32"
  },
  {
    "uniprot": "A0A077Z4Z2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Z2"
  },
  {
    "uniprot": "A0A077ZA93",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA93"
  },
  {
    "uniprot": "A0A077Z549",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z549"
  },
  {
    "uniprot": "A0A077Z021",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z021"
  },
  {
    "uniprot": "A0A077Z1G6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1G6"
  },
  {
    "uniprot": "A0A077Z7P0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7P0"
  },
  {
    "uniprot": "A0A077Z9B3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9B3"
  },
  {
    "uniprot": "A0A077ZEU8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEU8"
  },
  {
    "uniprot": "A0A077Z1D5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1D5"
  },
  {
    "uniprot": "A0A077YZZ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZZ6"
  },
  {
    "uniprot": "A0A077Z3S1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3S1"
  },
  {
    "uniprot": "A0A077ZJ53",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ53"
  },
  {
    "uniprot": "A0A077ZM82",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM82"
  },
  {
    "uniprot": "A0A077Z788",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z788"
  },
  {
    "uniprot": "A0A077Z997",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z997"
  },
  {
    "uniprot": "A0A077YUM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUM5"
  },
  {
    "uniprot": "A0A077ZL23",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL23"
  },
  {
    "uniprot": "A0A077ZJ16",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ16"
  },
  {
    "uniprot": "A0A077ZDR5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDR5"
  },
  {
    "uniprot": "A0A077Z6I1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6I1"
  },
  {
    "uniprot": "A0A077Z0D0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0D0"
  },
  {
    "uniprot": "A0A077ZG45",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG45"
  },
  {
    "uniprot": "A0A077ZDU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDU1"
  },
  {
    "uniprot": "A0A077YYM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYM8"
  },
  {
    "uniprot": "A0A077Z9R3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9R3"
  },
  {
    "uniprot": "A0A077ZDP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDP3"
  },
  {
    "uniprot": "A0A077ZL42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL42"
  },
  {
    "uniprot": "A0A077ZK58",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK58"
  },
  {
    "uniprot": "A0A077ZMN5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMN5"
  },
  {
    "uniprot": "A0A077YVW3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVW3"
  },
  {
    "uniprot": "A0A077Z7Y8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Y8"
  },
  {
    "uniprot": "A0A077Z6M5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6M5"
  },
  {
    "uniprot": "A0A077YYV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYV6"
  },
  {
    "uniprot": "A0A077ZDY7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDY7"
  },
  {
    "uniprot": "A0A077ZH60",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH60"
  },
  {
    "uniprot": "A0A077ZGI9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGI9"
  },
  {
    "uniprot": "A0A077ZQ92",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ92"
  },
  {
    "uniprot": "A0A077Z725",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z725"
  },
  {
    "uniprot": "A0A077YUK2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUK2"
  },
  {
    "uniprot": "A0A077Z9W7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9W7"
  },
  {
    "uniprot": "A0A077YWY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWY6"
  },
  {
    "uniprot": "A0A077Z1J9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1J9"
  },
  {
    "uniprot": "A0A077Z3P8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3P8"
  },
  {
    "uniprot": "A0A077YYQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYQ2"
  },
  {
    "uniprot": "A0A077ZAV7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAV7"
  },
  {
    "uniprot": "A0A077ZM81",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM81"
  },
  {
    "uniprot": "A0A077ZR13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR13"
  },
  {
    "uniprot": "A0A077Z3W0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3W0"
  },
  {
    "uniprot": "A0A077ZC12",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC12"
  },
  {
    "uniprot": "A0A077Z1L8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1L8"
  },
  {
    "uniprot": "A0A077ZBM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBM5"
  },
  {
    "uniprot": "A0A077ZN66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN66"
  },
  {
    "uniprot": "A0A077ZAA6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAA6"
  },
  {
    "uniprot": "A0A077Z1J2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1J2"
  },
  {
    "uniprot": "A0A077Z464",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z464"
  },
  {
    "uniprot": "A0A077ZC28",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC28"
  },
  {
    "uniprot": "A0A077ZIA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIA5"
  },
  {
    "uniprot": "A0A077YX06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX06"
  },
  {
    "uniprot": "A0A077ZAI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAI8"
  },
  {
    "uniprot": "A0A077Z3C4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3C4"
  },
  {
    "uniprot": "A0A077ZJV8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJV8"
  },
  {
    "uniprot": "A0A077YVK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVK6"
  },
  {
    "uniprot": "A0A077YYQ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYQ1"
  },
  {
    "uniprot": "A0A077Z231",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z231"
  },
  {
    "uniprot": "A0A077Z9W9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9W9"
  },
  {
    "uniprot": "A0A077ZFP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFP4"
  },
  {
    "uniprot": "A0A077Z3N1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3N1"
  },
  {
    "uniprot": "A0A077ZF77",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF77"
  },
  {
    "uniprot": "A0A077Z7Y2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Y2"
  },
  {
    "uniprot": "A0A077Z3M0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3M0"
  },
  {
    "uniprot": "A0A077YZY2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZY2"
  },
  {
    "uniprot": "A0A077Z855",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z855"
  },
  {
    "uniprot": "A0A077Z266",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z266"
  },
  {
    "uniprot": "A0A077Z980",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z980"
  },
  {
    "uniprot": "A0A077ZJN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJN1"
  },
  {
    "uniprot": "A0A077Z0A2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0A2"
  },
  {
    "uniprot": "A0A077ZBH6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBH6"
  },
  {
    "uniprot": "A0A077YYF3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYF3"
  },
  {
    "uniprot": "A0A077ZHE1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHE1"
  },
  {
    "uniprot": "A0A077Z364",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z364"
  },
  {
    "uniprot": "A0A077Z523",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z523"
  },
  {
    "uniprot": "A0A077YX20",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX20"
  },
  {
    "uniprot": "A0A077YWX5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWX5"
  },
  {
    "uniprot": "A0A077ZEL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEL6"
  },
  {
    "uniprot": "A0A077Z3R2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3R2"
  },
  {
    "uniprot": "A0A077ZEL5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEL5"
  },
  {
    "uniprot": "A0A077ZNF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNF9"
  },
  {
    "uniprot": "A0A077ZBN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBN3"
  },
  {
    "uniprot": "A0A077YYW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYW0"
  },
  {
    "uniprot": "A0A077ZG78",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG78"
  },
  {
    "uniprot": "A0A077ZNH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNH8"
  },
  {
    "uniprot": "A0A077ZA64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA64"
  },
  {
    "uniprot": "A0A077Z6T7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6T7"
  },
  {
    "uniprot": "A0A077ZD18",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD18"
  },
  {
    "uniprot": "A0A077Z6T2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6T2"
  },
  {
    "uniprot": "A0A077Z1D2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1D2"
  },
  {
    "uniprot": "A0A077ZNX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNX3"
  },
  {
    "uniprot": "A0A077Z233",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z233"
  },
  {
    "uniprot": "A0A077ZLQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLQ2"
  },
  {
    "uniprot": "A0A077Z6E2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6E2"
  },
  {
    "uniprot": "A0A077ZI88",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI88"
  },
  {
    "uniprot": "A0A077ZKF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKF9"
  },
  {
    "uniprot": "A0A077Z7J8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7J8"
  },
  {
    "uniprot": "A0A077Z2D1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2D1"
  },
  {
    "uniprot": "A0A077ZGT5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGT5"
  },
  {
    "uniprot": "A0A077Z0C7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0C7"
  },
  {
    "uniprot": "A0A077Z121",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z121"
  },
  {
    "uniprot": "A0A077YYK8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYK8"
  },
  {
    "uniprot": "A0A077ZBB4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBB4"
  },
  {
    "uniprot": "A0A077ZQ74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ74"
  },
  {
    "uniprot": "A0A077Z596",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z596"
  },
  {
    "uniprot": "A0A077ZNL9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNL9"
  },
  {
    "uniprot": "A0A077YVG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVG8"
  },
  {
    "uniprot": "A0A077ZFY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFY8"
  },
  {
    "uniprot": "A0A077ZCA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCA1"
  },
  {
    "uniprot": "A0A077YX64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX64"
  },
  {
    "uniprot": "A0A077ZAH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAH1"
  },
  {
    "uniprot": "A0A077Z0S1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0S1"
  },
  {
    "uniprot": "A0A077ZK84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK84"
  },
  {
    "uniprot": "A0A077Z7A4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7A4"
  },
  {
    "uniprot": "A0A077ZBD8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBD8"
  },
  {
    "uniprot": "A0A077Z5C2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5C2"
  },
  {
    "uniprot": "A0A077Z8N8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8N8"
  },
  {
    "uniprot": "A0A077Z3B6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3B6"
  },
  {
    "uniprot": "A0A077ZN74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN74"
  },
  {
    "uniprot": "A0A077ZHG4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHG4"
  },
  {
    "uniprot": "A0A077Z0H9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0H9"
  },
  {
    "uniprot": "A0A077ZKX2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKX2"
  },
  {
    "uniprot": "A0A077Z640",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z640"
  },
  {
    "uniprot": "A0A077ZD81",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD81"
  },
  {
    "uniprot": "A0A077Z5M0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5M0"
  },
  {
    "uniprot": "A0A077Z351",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z351"
  },
  {
    "uniprot": "A0A077ZFD2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFD2"
  },
  {
    "uniprot": "A0A077Z4N5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4N5"
  },
  {
    "uniprot": "A0A077YVX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVX9"
  },
  {
    "uniprot": "A0A077ZCT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCT8"
  },
  {
    "uniprot": "A0A077Z8P6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8P6"
  },
  {
    "uniprot": "A0A077Z1B3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1B3"
  },
  {
    "uniprot": "A0A077ZCA9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCA9"
  },
  {
    "uniprot": "A0A077Z034",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z034"
  },
  {
    "uniprot": "A0A077ZJZ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJZ0"
  },
  {
    "uniprot": "A0A077Z9H9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9H9"
  },
  {
    "uniprot": "A0A077ZK76",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK76"
  },
  {
    "uniprot": "A0A077ZLI2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLI2"
  },
  {
    "uniprot": "A0A077Z9Q2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Q2"
  },
  {
    "uniprot": "A0A077ZLN5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLN5"
  },
  {
    "uniprot": "A0A077YY31",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY31"
  },
  {
    "uniprot": "A0A077YY93",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY93"
  },
  {
    "uniprot": "A0A077Z2K5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2K5"
  },
  {
    "uniprot": "A0A077ZFC5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFC5"
  },
  {
    "uniprot": "A0A077ZA47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA47"
  },
  {
    "uniprot": "A0A077YY70",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY70"
  },
  {
    "uniprot": "A0A077ZN50",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN50"
  },
  {
    "uniprot": "A0A077Z0T0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0T0"
  },
  {
    "uniprot": "A0A077Z9Y3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Y3"
  },
  {
    "uniprot": "A0A077Z106",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z106"
  },
  {
    "uniprot": "A0A077YVU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVU9"
  },
  {
    "uniprot": "A0A077ZKV7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKV7"
  },
  {
    "uniprot": "A0A077ZAE1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAE1"
  },
  {
    "uniprot": "A0A077ZHF1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHF1"
  },
  {
    "uniprot": "A0A077YYG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYG8"
  },
  {
    "uniprot": "A0A077ZLR1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLR1"
  },
  {
    "uniprot": "A0A077ZEK1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEK1"
  },
  {
    "uniprot": "A0A077Z2P2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2P2"
  },
  {
    "uniprot": "A0A077ZC73",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC73"
  },
  {
    "uniprot": "A0A077ZME6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZME6"
  },
  {
    "uniprot": "A0A077Z8C9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8C9"
  },
  {
    "uniprot": "A0A077Z5Q4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Q4"
  },
  {
    "uniprot": "A0A077YUS6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUS6"
  },
  {
    "uniprot": "A0A077ZI36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI36"
  },
  {
    "uniprot": "A0A077YWL9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWL9"
  },
  {
    "uniprot": "A0A077ZJF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJF9"
  },
  {
    "uniprot": "A0A077Z1C0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1C0"
  },
  {
    "uniprot": "A0A077ZD29",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD29"
  },
  {
    "uniprot": "A0A077Z680",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z680"
  },
  {
    "uniprot": "A0A077Z160",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z160"
  },
  {
    "uniprot": "A0A077Z609",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z609"
  },
  {
    "uniprot": "A0A077ZMW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMW9"
  },
  {
    "uniprot": "A0A077YVT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVT8"
  },
  {
    "uniprot": "A0A077ZN46",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN46"
  },
  {
    "uniprot": "A0A077ZDR9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDR9"
  },
  {
    "uniprot": "A0A077Z8P1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8P1"
  },
  {
    "uniprot": "A0A077ZDR0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDR0"
  },
  {
    "uniprot": "A0A077YYJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYJ5"
  },
  {
    "uniprot": "A0A077ZFS8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFS8"
  },
  {
    "uniprot": "A0A077ZIS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIS1"
  },
  {
    "uniprot": "A0A077ZM57",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM57"
  },
  {
    "uniprot": "A0A077Z0L0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0L0"
  },
  {
    "uniprot": "A0A077ZNG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNG8"
  },
  {
    "uniprot": "A0A077ZQL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQL6"
  },
  {
    "uniprot": "A0A077Z8M8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8M8"
  },
  {
    "uniprot": "A0A077ZNF1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNF1"
  },
  {
    "uniprot": "A0A077ZPU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPU1"
  },
  {
    "uniprot": "A0A077Z729",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z729"
  },
  {
    "uniprot": "A0A077ZLF1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLF1"
  },
  {
    "uniprot": "A0A077YWM9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWM9"
  },
  {
    "uniprot": "A0A077ZE02",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE02"
  },
  {
    "uniprot": "A0A077Z6P4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6P4"
  },
  {
    "uniprot": "A0A077ZBQ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBQ6"
  },
  {
    "uniprot": "A0A077ZJD7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJD7"
  },
  {
    "uniprot": "A0A077Z1S0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1S0"
  },
  {
    "uniprot": "A0A077ZDI6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDI6"
  },
  {
    "uniprot": "A0A077ZJB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJB6"
  },
  {
    "uniprot": "A0A077Z4C2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4C2"
  },
  {
    "uniprot": "A0A077ZFA4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFA4"
  },
  {
    "uniprot": "A0A077ZGI7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGI7"
  },
  {
    "uniprot": "A0A077Z7Z3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Z3"
  },
  {
    "uniprot": "A0A077ZPE6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPE6"
  },
  {
    "uniprot": "A0A077ZD88",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD88"
  },
  {
    "uniprot": "A0A077ZBD0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBD0"
  },
  {
    "uniprot": "A0A077Z4U2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4U2"
  },
  {
    "uniprot": "A0A077Z9W4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9W4"
  },
  {
    "uniprot": "A0A077ZG84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG84"
  },
  {
    "uniprot": "A0A077ZC43",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC43"
  },
  {
    "uniprot": "A0A077Z1Q7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Q7"
  },
  {
    "uniprot": "A0A077YZ18",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ18"
  },
  {
    "uniprot": "A0A077Z7R1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7R1"
  },
  {
    "uniprot": "A0A077Z5N4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5N4"
  },
  {
    "uniprot": "A0A077ZIT5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIT5"
  },
  {
    "uniprot": "A0A077ZEU0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEU0"
  },
  {
    "uniprot": "A0A077ZAX4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAX4"
  },
  {
    "uniprot": "A0A077Z2S1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2S1"
  },
  {
    "uniprot": "A0A077Z3V7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3V7"
  },
  {
    "uniprot": "A0A077ZJB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJB2"
  },
  {
    "uniprot": "A0A077ZAT4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAT4"
  },
  {
    "uniprot": "A0A077ZG66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG66"
  },
  {
    "uniprot": "A0A077ZH24",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH24"
  },
  {
    "uniprot": "A0A077ZNK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNK7"
  },
  {
    "uniprot": "A0A077Z5B0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5B0"
  },
  {
    "uniprot": "A0A077ZGB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGB0"
  },
  {
    "uniprot": "A0A077ZKY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKY1"
  },
  {
    "uniprot": "A0A077ZII5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZII5"
  },
  {
    "uniprot": "A0A077Z2A8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2A8"
  },
  {
    "uniprot": "A0A077YZH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZH8"
  },
  {
    "uniprot": "A0A077YUY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUY4"
  },
  {
    "uniprot": "A0A077ZHK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHK4"
  },
  {
    "uniprot": "A0A077Z401",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z401"
  },
  {
    "uniprot": "A0A077ZEH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEH8"
  },
  {
    "uniprot": "A0A077Z5V2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5V2"
  },
  {
    "uniprot": "A0A077Z1Z3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Z3"
  },
  {
    "uniprot": "A0A077YXT3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXT3"
  },
  {
    "uniprot": "A0A077Z4T1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4T1"
  },
  {
    "uniprot": "A0A077Z8W0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8W0"
  },
  {
    "uniprot": "A0A077ZKN8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKN8"
  },
  {
    "uniprot": "A0A077Z5F4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5F4"
  },
  {
    "uniprot": "A0A077ZIS0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIS0"
  },
  {
    "uniprot": "A0A077Z224",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z224"
  },
  {
    "uniprot": "A0A077ZAV8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAV8"
  },
  {
    "uniprot": "A0A077Z9G9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9G9"
  },
  {
    "uniprot": "A0A077ZH11",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH11"
  },
  {
    "uniprot": "A0A077ZEX4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEX4"
  },
  {
    "uniprot": "A0A077Z4F2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4F2"
  },
  {
    "uniprot": "A0A077ZKD0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKD0"
  },
  {
    "uniprot": "A0A077ZKW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKW7"
  },
  {
    "uniprot": "A0A077Z9H7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9H7"
  },
  {
    "uniprot": "A0A077Z796",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z796"
  },
  {
    "uniprot": "A0A077ZGR9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGR9"
  },
  {
    "uniprot": "A0A077ZFY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFY9"
  },
  {
    "uniprot": "A0A077ZKG4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKG4"
  },
  {
    "uniprot": "A0A077ZB80",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB80"
  },
  {
    "uniprot": "A0A077Z9W5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9W5"
  },
  {
    "uniprot": "A0A077ZLB7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLB7"
  },
  {
    "uniprot": "A0A077ZK75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK75"
  },
  {
    "uniprot": "A0A077ZHU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHU6"
  },
  {
    "uniprot": "A0A077ZBA4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBA4"
  },
  {
    "uniprot": "A0A077ZL21",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL21"
  },
  {
    "uniprot": "A0A077ZAK2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAK2"
  },
  {
    "uniprot": "A0A077Z9Y0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Y0"
  },
  {
    "uniprot": "A0A077Z466",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z466"
  },
  {
    "uniprot": "A0A077ZLP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLP4"
  },
  {
    "uniprot": "A0A077Z426",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z426"
  },
  {
    "uniprot": "A0A077YXG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXG3"
  },
  {
    "uniprot": "A0A077ZJY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJY6"
  },
  {
    "uniprot": "A0A077ZEL9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEL9"
  },
  {
    "uniprot": "A0A077Z5S8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5S8"
  },
  {
    "uniprot": "A0A077Z983",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z983"
  },
  {
    "uniprot": "A0A077ZIN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIN3"
  },
  {
    "uniprot": "A0A077ZCK8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCK8"
  },
  {
    "uniprot": "A0A077Z9I4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9I4"
  },
  {
    "uniprot": "A0A077ZJ23",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ23"
  },
  {
    "uniprot": "A0A077Z0Q6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Q6"
  },
  {
    "uniprot": "A0A077Z5G9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5G9"
  },
  {
    "uniprot": "A0A077Z8Q0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Q0"
  },
  {
    "uniprot": "A0A077YZ54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ54"
  },
  {
    "uniprot": "A0A077ZAC9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAC9"
  },
  {
    "uniprot": "A0A077Z569",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z569"
  },
  {
    "uniprot": "A0A077ZQX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQX7"
  },
  {
    "uniprot": "A0A077Z8Y2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Y2"
  },
  {
    "uniprot": "A0A077ZFA6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFA6"
  },
  {
    "uniprot": "A0A077ZCW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCW7"
  },
  {
    "uniprot": "A0A077Z3H0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3H0"
  },
  {
    "uniprot": "A0A077ZFF7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFF7"
  },
  {
    "uniprot": "A0A077Z7A0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7A0"
  },
  {
    "uniprot": "A0A077ZIW3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIW3"
  },
  {
    "uniprot": "A0A077Z854",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z854"
  },
  {
    "uniprot": "A0A077ZG03",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG03"
  },
  {
    "uniprot": "A0A077Z0G3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0G3"
  },
  {
    "uniprot": "A0A077YZ36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ36"
  },
  {
    "uniprot": "A0A077ZLQ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLQ9"
  },
  {
    "uniprot": "A0A077Z2T0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2T0"
  },
  {
    "uniprot": "A0A077ZCG0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCG0"
  },
  {
    "uniprot": "A0A077Z2H4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2H4"
  },
  {
    "uniprot": "A0A077ZIX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIX7"
  },
  {
    "uniprot": "A0A077Z020",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z020"
  },
  {
    "uniprot": "A0A077ZLH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLH5"
  },
  {
    "uniprot": "A0A077ZIF8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIF8"
  },
  {
    "uniprot": "A0A077ZJF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJF4"
  },
  {
    "uniprot": "A0A077YXL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXL6"
  },
  {
    "uniprot": "A0A077ZGF6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGF6"
  },
  {
    "uniprot": "A0A077YW08",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW08"
  },
  {
    "uniprot": "A0A077ZI83",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI83"
  },
  {
    "uniprot": "A0A077ZDH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDH8"
  },
  {
    "uniprot": "A0A077ZJG6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJG6"
  },
  {
    "uniprot": "A0A077Z4E1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4E1"
  },
  {
    "uniprot": "A0A077ZGF8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGF8"
  },
  {
    "uniprot": "A0A077ZEP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEP5"
  },
  {
    "uniprot": "A0A077ZHI9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHI9"
  },
  {
    "uniprot": "A0A077YW56",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW56"
  },
  {
    "uniprot": "A0A077ZFW3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFW3"
  },
  {
    "uniprot": "A0A077Z7E5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7E5"
  },
  {
    "uniprot": "A0A077Z819",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z819"
  },
  {
    "uniprot": "A0A077YVV7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVV7"
  },
  {
    "uniprot": "A0A077YXB3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXB3"
  },
  {
    "uniprot": "A0A077ZKF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKF4"
  },
  {
    "uniprot": "A0A077ZG93",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG93"
  },
  {
    "uniprot": "A0A077Z900",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z900"
  },
  {
    "uniprot": "A0A077ZGI6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGI6"
  },
  {
    "uniprot": "A0A077Z0M2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0M2"
  },
  {
    "uniprot": "A0A077ZN72",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN72"
  },
  {
    "uniprot": "A0A077Z8M1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8M1"
  },
  {
    "uniprot": "A0A077Z273",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z273"
  },
  {
    "uniprot": "A0A077Z746",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z746"
  },
  {
    "uniprot": "A0A077Z7D6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7D6"
  },
  {
    "uniprot": "A0A077YYE0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYE0"
  },
  {
    "uniprot": "A0A077ZPR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPR6"
  },
  {
    "uniprot": "A0A077Z386",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z386"
  },
  {
    "uniprot": "A0A077Z2N9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2N9"
  },
  {
    "uniprot": "A0A077ZMK2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMK2"
  },
  {
    "uniprot": "A0A077YZ13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ13"
  },
  {
    "uniprot": "A0A077Z0T1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0T1"
  },
  {
    "uniprot": "A0A077ZC82",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC82"
  },
  {
    "uniprot": "A0A077YZV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZV4"
  },
  {
    "uniprot": "A0A077ZG15",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG15"
  },
  {
    "uniprot": "A0A077Z7Q0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Q0"
  },
  {
    "uniprot": "A0A077Z3F1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3F1"
  },
  {
    "uniprot": "A0A077ZLE0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLE0"
  },
  {
    "uniprot": "A0A077Z2M4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2M4"
  },
  {
    "uniprot": "A0A077ZCH2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCH2"
  },
  {
    "uniprot": "A0A077Z7H3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7H3"
  },
  {
    "uniprot": "A0A077ZB33",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB33"
  },
  {
    "uniprot": "A0A077ZGX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGX7"
  },
  {
    "uniprot": "A0A077Z3J4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3J4"
  },
  {
    "uniprot": "A0A077Z5K6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5K6"
  },
  {
    "uniprot": "A0A077Z0G4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0G4"
  },
  {
    "uniprot": "A0A077ZBM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBM8"
  },
  {
    "uniprot": "A0A077ZEG6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEG6"
  },
  {
    "uniprot": "A0A077Z6Q2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Q2"
  },
  {
    "uniprot": "A0A077YWK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWK3"
  },
  {
    "uniprot": "A0A077Z0L9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0L9"
  },
  {
    "uniprot": "A0A077ZHM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHM6"
  },
  {
    "uniprot": "A0A077Z4I7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4I7"
  },
  {
    "uniprot": "A0A077YZQ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZQ1"
  },
  {
    "uniprot": "A0A077YVV8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVV8"
  },
  {
    "uniprot": "A0A077Z8B7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8B7"
  },
  {
    "uniprot": "A0A077ZI57",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI57"
  },
  {
    "uniprot": "A0A077YWW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWW8"
  },
  {
    "uniprot": "A0A077ZE01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE01"
  },
  {
    "uniprot": "A0A077ZG38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG38"
  },
  {
    "uniprot": "A0A077Z423",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z423"
  },
  {
    "uniprot": "A0A077ZAT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAT8"
  },
  {
    "uniprot": "A0A077Z586",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z586"
  },
  {
    "uniprot": "A0A077Z1G1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1G1"
  },
  {
    "uniprot": "A0A077YYL0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYL0"
  },
  {
    "uniprot": "A0A077ZP85",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP85"
  },
  {
    "uniprot": "A0A077Z0F5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0F5"
  },
  {
    "uniprot": "A0A077ZL09",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL09"
  },
  {
    "uniprot": "A0A077Z7W2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7W2"
  },
  {
    "uniprot": "A0A077ZGG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGG3"
  },
  {
    "uniprot": "A0A077Z738",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z738"
  },
  {
    "uniprot": "A0A077ZDV0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDV0"
  },
  {
    "uniprot": "A0A077Z7X8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7X8"
  },
  {
    "uniprot": "A0A077Z1X0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1X0"
  },
  {
    "uniprot": "A0A077ZQ87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ87"
  },
  {
    "uniprot": "A0A077ZRD2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZRD2"
  },
  {
    "uniprot": "A0A077Z532",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z532"
  },
  {
    "uniprot": "A0A077Z412",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z412"
  },
  {
    "uniprot": "A0A077YWK1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWK1"
  },
  {
    "uniprot": "A0A077Z6Z2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Z2"
  },
  {
    "uniprot": "A0A077Z219",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z219"
  },
  {
    "uniprot": "A0A077ZJV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJV3"
  },
  {
    "uniprot": "A0A077ZME9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZME9"
  },
  {
    "uniprot": "A0A077ZB02",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB02"
  },
  {
    "uniprot": "A0A077Z0Y3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Y3"
  },
  {
    "uniprot": "A0A077ZJV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJV4"
  },
  {
    "uniprot": "A0A077ZGJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGJ8"
  },
  {
    "uniprot": "A0A077Z8U4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8U4"
  },
  {
    "uniprot": "A0A077Z6D2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6D2"
  },
  {
    "uniprot": "A0A077Z6W2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6W2"
  },
  {
    "uniprot": "A0A077ZIW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIW7"
  },
  {
    "uniprot": "A0A077Z1S1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1S1"
  },
  {
    "uniprot": "A0A077Z8Q9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Q9"
  },
  {
    "uniprot": "A0A077YVD3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVD3"
  },
  {
    "uniprot": "A0A077YWY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWY8"
  },
  {
    "uniprot": "A0A077ZAY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAY0"
  },
  {
    "uniprot": "A0A077ZMJ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMJ1"
  },
  {
    "uniprot": "A0A077Z9G0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9G0"
  },
  {
    "uniprot": "A0A077ZCQ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCQ7"
  },
  {
    "uniprot": "A0A077Z974",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z974"
  },
  {
    "uniprot": "A0A077ZIZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIZ4"
  },
  {
    "uniprot": "A0A077ZII1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZII1"
  },
  {
    "uniprot": "A0A077ZBH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBH5"
  },
  {
    "uniprot": "A0A077ZNB7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNB7"
  },
  {
    "uniprot": "A0A077ZDD1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDD1"
  },
  {
    "uniprot": "A0A077ZKC2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKC2"
  },
  {
    "uniprot": "A0A077ZKL4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKL4"
  },
  {
    "uniprot": "A0A077YWH2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWH2"
  },
  {
    "uniprot": "A0A077Z728",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z728"
  },
  {
    "uniprot": "A0A077Z0G1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0G1"
  },
  {
    "uniprot": "A0A077YY35",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY35"
  },
  {
    "uniprot": "A0A077Z072",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z072"
  },
  {
    "uniprot": "A0A077Z6D7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6D7"
  },
  {
    "uniprot": "A0A077ZGL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGL6"
  },
  {
    "uniprot": "A0A077Z252",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z252"
  },
  {
    "uniprot": "A0A077Z0E3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0E3"
  },
  {
    "uniprot": "A0A077YXK2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXK2"
  },
  {
    "uniprot": "A0A077ZBC7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBC7"
  },
  {
    "uniprot": "A0A077ZMU3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMU3"
  },
  {
    "uniprot": "A0A077ZEY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEY9"
  },
  {
    "uniprot": "A0A077Z909",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z909"
  },
  {
    "uniprot": "A0A077ZKA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKA1"
  },
  {
    "uniprot": "A0A077Z0A0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0A0"
  },
  {
    "uniprot": "A0A077Z1M7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1M7"
  },
  {
    "uniprot": "A0A077Z3W6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3W6"
  },
  {
    "uniprot": "A0A077Z1P4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1P4"
  },
  {
    "uniprot": "A0A077Z9Z5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Z5"
  },
  {
    "uniprot": "A0A077ZJQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJQ2"
  },
  {
    "uniprot": "A0A077Z7B4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7B4"
  },
  {
    "uniprot": "A0A077ZD14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD14"
  },
  {
    "uniprot": "A0A077ZHK2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHK2"
  },
  {
    "uniprot": "A0A077ZEN8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEN8"
  },
  {
    "uniprot": "A0A077YY20",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY20"
  },
  {
    "uniprot": "A0A077ZAV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAV9"
  },
  {
    "uniprot": "A0A077ZKV8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKV8"
  },
  {
    "uniprot": "A0A077ZGA2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGA2"
  },
  {
    "uniprot": "A0A077Z429",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z429"
  },
  {
    "uniprot": "A0A077ZJH7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJH7"
  },
  {
    "uniprot": "A0A077YZ58",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ58"
  },
  {
    "uniprot": "A0A077ZJ45",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ45"
  },
  {
    "uniprot": "A0A077Z7X4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7X4"
  },
  {
    "uniprot": "A0A077ZCH9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCH9"
  },
  {
    "uniprot": "A0A077ZC37",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC37"
  },
  {
    "uniprot": "A0A077ZL10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL10"
  },
  {
    "uniprot": "A0A077Z856",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z856"
  },
  {
    "uniprot": "A0A077ZM61",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM61"
  },
  {
    "uniprot": "A0A077ZHX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHX0"
  },
  {
    "uniprot": "A0A077ZFW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFW2"
  },
  {
    "uniprot": "A0A077Z1T0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1T0"
  },
  {
    "uniprot": "A0A077Z1F2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1F2"
  },
  {
    "uniprot": "A0A077Z5S3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5S3"
  },
  {
    "uniprot": "A0A077ZG70",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG70"
  },
  {
    "uniprot": "A0A077ZC71",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC71"
  },
  {
    "uniprot": "A0A077YXZ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXZ2"
  },
  {
    "uniprot": "A0A077YWN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWN9"
  },
  {
    "uniprot": "A0A077Z0V7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0V7"
  },
  {
    "uniprot": "A0A077YZ70",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ70"
  },
  {
    "uniprot": "A0A077ZMJ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMJ6"
  },
  {
    "uniprot": "A0A077ZA06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA06"
  },
  {
    "uniprot": "A0A077Z5U7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5U7"
  },
  {
    "uniprot": "A0A077ZK80",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK80"
  },
  {
    "uniprot": "A0A077ZAS4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAS4"
  },
  {
    "uniprot": "A0A077ZHM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHM5"
  },
  {
    "uniprot": "A0A077Z7T0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7T0"
  },
  {
    "uniprot": "A0A077Z374",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z374"
  },
  {
    "uniprot": "A0A077Z942",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z942"
  },
  {
    "uniprot": "A0A077ZF80",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF80"
  },
  {
    "uniprot": "A0A077Z9X3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9X3"
  },
  {
    "uniprot": "A0A077ZPM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPM2"
  },
  {
    "uniprot": "A0A077ZKB3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKB3"
  },
  {
    "uniprot": "A0A077Z4J5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4J5"
  },
  {
    "uniprot": "A0A077ZEI4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEI4"
  },
  {
    "uniprot": "A0A077Z0I1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0I1"
  },
  {
    "uniprot": "A0A077ZEB8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEB8"
  },
  {
    "uniprot": "A0A077Z5F5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5F5"
  },
  {
    "uniprot": "A0A077ZAJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAJ8"
  },
  {
    "uniprot": "A0A077Z3R0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3R0"
  },
  {
    "uniprot": "A0A077Z652",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z652"
  },
  {
    "uniprot": "A0A077ZG35",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG35"
  },
  {
    "uniprot": "A0A077ZPN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPN6"
  },
  {
    "uniprot": "A0A077ZHV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHV4"
  },
  {
    "uniprot": "A0A077ZLS2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLS2"
  },
  {
    "uniprot": "A0A077Z601",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z601"
  },
  {
    "uniprot": "A0A077ZEZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEZ1"
  },
  {
    "uniprot": "A0A077ZE20",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE20"
  },
  {
    "uniprot": "A0A077ZHF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHF9"
  },
  {
    "uniprot": "A0A077YUR3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUR3"
  },
  {
    "uniprot": "A0A077Z5W5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5W5"
  },
  {
    "uniprot": "A0A077Z8L5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8L5"
  },
  {
    "uniprot": "A0A077Z9F6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9F6"
  },
  {
    "uniprot": "A0A077ZJ67",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ67"
  },
  {
    "uniprot": "A0A077ZE99",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE99"
  },
  {
    "uniprot": "A0A077YVH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVH3"
  },
  {
    "uniprot": "A0A077ZL75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL75"
  },
  {
    "uniprot": "A0A077ZL05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL05"
  },
  {
    "uniprot": "A0A077ZLT0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLT0"
  },
  {
    "uniprot": "A0A077Z1Z1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Z1"
  },
  {
    "uniprot": "A0A077ZK34",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK34"
  },
  {
    "uniprot": "A0A077ZEI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEI3"
  },
  {
    "uniprot": "A0A077ZKJ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKJ4"
  },
  {
    "uniprot": "A0A077ZGT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGT9"
  },
  {
    "uniprot": "A0A077ZE84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE84"
  },
  {
    "uniprot": "A0A077ZIH4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIH4"
  },
  {
    "uniprot": "A0A077ZDN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDN3"
  },
  {
    "uniprot": "A0A077Z2V2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2V2"
  },
  {
    "uniprot": "A0A077ZGZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGZ4"
  },
  {
    "uniprot": "A0A077ZEQ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEQ6"
  },
  {
    "uniprot": "A0A077Z2N4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2N4"
  },
  {
    "uniprot": "A0A077Z693",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z693"
  },
  {
    "uniprot": "A0A077ZCP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCP5"
  },
  {
    "uniprot": "A0A077Z6N9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6N9"
  },
  {
    "uniprot": "A0A077YZ31",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ31"
  },
  {
    "uniprot": "A0A077Z114",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z114"
  },
  {
    "uniprot": "A0A077Z6E8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6E8"
  },
  {
    "uniprot": "A0A077ZNV5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNV5"
  },
  {
    "uniprot": "A0A077ZNC8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNC8"
  },
  {
    "uniprot": "A0A077ZK14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK14"
  },
  {
    "uniprot": "A0A077Z153",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z153"
  },
  {
    "uniprot": "A0A077ZA69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA69"
  },
  {
    "uniprot": "A0A077ZFK8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFK8"
  },
  {
    "uniprot": "A0A077YZV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZV9"
  },
  {
    "uniprot": "A0A077ZCE6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCE6"
  },
  {
    "uniprot": "A0A077Z582",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z582"
  },
  {
    "uniprot": "A0A077Z1V6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1V6"
  },
  {
    "uniprot": "A0A077YXX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXX9"
  },
  {
    "uniprot": "A0A077ZH69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH69"
  },
  {
    "uniprot": "A0A077ZBT0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBT0"
  },
  {
    "uniprot": "A0A077Z1W8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1W8"
  },
  {
    "uniprot": "A0A077Z3F2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3F2"
  },
  {
    "uniprot": "A0A077Z8Y4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Y4"
  },
  {
    "uniprot": "A0A077ZIH0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIH0"
  },
  {
    "uniprot": "A0A077Z0L1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0L1"
  },
  {
    "uniprot": "A0A077ZFG0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFG0"
  },
  {
    "uniprot": "A0A077ZA03",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA03"
  },
  {
    "uniprot": "A0A077ZKT0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKT0"
  },
  {
    "uniprot": "A0A077ZAQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAQ3"
  },
  {
    "uniprot": "A0A077ZQZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQZ9"
  },
  {
    "uniprot": "A0A077Z333",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z333"
  },
  {
    "uniprot": "A0A077Z526",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z526"
  },
  {
    "uniprot": "A0A077YZK1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZK1"
  },
  {
    "uniprot": "A0A077ZEJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEJ3"
  },
  {
    "uniprot": "A0A077YY36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY36"
  },
  {
    "uniprot": "A0A077ZHR4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHR4"
  },
  {
    "uniprot": "A0A077ZGZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGZ9"
  },
  {
    "uniprot": "A0A077Z5S7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5S7"
  },
  {
    "uniprot": "A0A077Z989",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z989"
  },
  {
    "uniprot": "A0A077ZK02",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK02"
  },
  {
    "uniprot": "A0A077ZCI5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCI5"
  },
  {
    "uniprot": "A0A077ZLI1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLI1"
  },
  {
    "uniprot": "A0A077Z7N5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7N5"
  },
  {
    "uniprot": "A0A077ZEH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEH1"
  },
  {
    "uniprot": "A0A077YYW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYW2"
  },
  {
    "uniprot": "A0A077YXE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXE8"
  },
  {
    "uniprot": "A0A077ZK86",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK86"
  },
  {
    "uniprot": "A0A077Z3H5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3H5"
  },
  {
    "uniprot": "A0A077YYW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYW7"
  },
  {
    "uniprot": "A0A077ZHT2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHT2"
  },
  {
    "uniprot": "A0A077Z8E8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8E8"
  },
  {
    "uniprot": "A0A077ZLA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLA8"
  },
  {
    "uniprot": "A0A077Z8B3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8B3"
  },
  {
    "uniprot": "A0A077ZGV5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGV5"
  },
  {
    "uniprot": "A0A077ZLZ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLZ2"
  },
  {
    "uniprot": "A0A077Z8U6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8U6"
  },
  {
    "uniprot": "A0A077ZMZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMZ5"
  },
  {
    "uniprot": "A0A077ZM08",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM08"
  },
  {
    "uniprot": "A0A077Z5M4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5M4"
  },
  {
    "uniprot": "A0A077YXW4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXW4"
  },
  {
    "uniprot": "A0A077Z036",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z036"
  },
  {
    "uniprot": "A0A077YYT7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYT7"
  },
  {
    "uniprot": "A0A077ZJL4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJL4"
  },
  {
    "uniprot": "A0A077Z6D0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6D0"
  },
  {
    "uniprot": "A0A077ZDT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDT9"
  },
  {
    "uniprot": "A0A077Z3P4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3P4"
  },
  {
    "uniprot": "A0A077Z3A7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3A7"
  },
  {
    "uniprot": "A0A077YW63",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW63"
  },
  {
    "uniprot": "A0A077Z494",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z494"
  },
  {
    "uniprot": "A0A077Z5A6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5A6"
  },
  {
    "uniprot": "A0A077Z365",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z365"
  },
  {
    "uniprot": "A0A077Z331",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z331"
  },
  {
    "uniprot": "A0A077YZX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZX6"
  },
  {
    "uniprot": "A0A077Z8J4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8J4"
  },
  {
    "uniprot": "A0A077ZIR9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIR9"
  },
  {
    "uniprot": "A0A077Z6T0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6T0"
  },
  {
    "uniprot": "A0A077YYB5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYB5"
  },
  {
    "uniprot": "A0A077ZCJ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCJ9"
  },
  {
    "uniprot": "A0A077Z103",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z103"
  },
  {
    "uniprot": "A0A077Z678",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z678"
  },
  {
    "uniprot": "A0A077Z895",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z895"
  },
  {
    "uniprot": "A0A077Z2A2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2A2"
  },
  {
    "uniprot": "A0A077Z955",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z955"
  },
  {
    "uniprot": "A0A077ZEL4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEL4"
  },
  {
    "uniprot": "A0A077Z2A6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2A6"
  },
  {
    "uniprot": "A0A077Z5E1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5E1"
  },
  {
    "uniprot": "A0A077Z226",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z226"
  },
  {
    "uniprot": "A0A077ZBK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBK7"
  },
  {
    "uniprot": "A0A077ZFW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFW8"
  },
  {
    "uniprot": "A0A077Z3Z2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Z2"
  },
  {
    "uniprot": "A0A077Z0E8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0E8"
  },
  {
    "uniprot": "A0A077Z363",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z363"
  },
  {
    "uniprot": "A0A077ZBZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBZ3"
  },
  {
    "uniprot": "A0A077ZE89",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE89"
  },
  {
    "uniprot": "A0A077ZB98",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB98"
  },
  {
    "uniprot": "A0A077ZBG6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBG6"
  },
  {
    "uniprot": "A0A077Z3I6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3I6"
  },
  {
    "uniprot": "A0A077Z6J8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6J8"
  },
  {
    "uniprot": "A0A077ZM15",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM15"
  },
  {
    "uniprot": "A0A077ZIR7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIR7"
  },
  {
    "uniprot": "A0A077Z167",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z167"
  },
  {
    "uniprot": "A0A077Z814",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z814"
  },
  {
    "uniprot": "A0A077ZDZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDZ3"
  },
  {
    "uniprot": "A0A077ZE07",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE07"
  },
  {
    "uniprot": "A0A077ZMW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMW1"
  },
  {
    "uniprot": "A0A077ZI77",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI77"
  },
  {
    "uniprot": "A0A077Z2W1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2W1"
  },
  {
    "uniprot": "A0A077ZCZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCZ4"
  },
  {
    "uniprot": "A0A077Z9D7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9D7"
  },
  {
    "uniprot": "A0A077Z9U5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9U5"
  },
  {
    "uniprot": "A0A077YY86",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY86"
  },
  {
    "uniprot": "A0A077ZDL4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDL4"
  },
  {
    "uniprot": "A0A077Z6E5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6E5"
  },
  {
    "uniprot": "A0A077Z7H1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7H1"
  },
  {
    "uniprot": "A0A077YW48",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW48"
  },
  {
    "uniprot": "A0A077ZMT4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMT4"
  },
  {
    "uniprot": "A0A077Z2C4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2C4"
  },
  {
    "uniprot": "A0A077Z876",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z876"
  },
  {
    "uniprot": "A0A077ZMB1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMB1"
  },
  {
    "uniprot": "A0A077Z757",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z757"
  },
  {
    "uniprot": "A0A077YZG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZG5"
  },
  {
    "uniprot": "A0A077ZEA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEA8"
  },
  {
    "uniprot": "A0A077ZM87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM87"
  },
  {
    "uniprot": "A0A077ZMW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMW5"
  },
  {
    "uniprot": "A0A077Z3Z0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Z0"
  },
  {
    "uniprot": "A0A077YZG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZG8"
  },
  {
    "uniprot": "A0A077ZHG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHG5"
  },
  {
    "uniprot": "A0A077YWX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWX0"
  },
  {
    "uniprot": "A0A077ZKI2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKI2"
  },
  {
    "uniprot": "A0A077Z9E9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9E9"
  },
  {
    "uniprot": "A0A077ZHL0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHL0"
  },
  {
    "uniprot": "A0A077ZKH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKH3"
  },
  {
    "uniprot": "A0A077Z270",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z270"
  },
  {
    "uniprot": "A0A077ZGP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGP8"
  },
  {
    "uniprot": "A0A077Z3J5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3J5"
  },
  {
    "uniprot": "A0A077Z6E7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6E7"
  },
  {
    "uniprot": "A0A077ZBA0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBA0"
  },
  {
    "uniprot": "A0A077ZGD3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGD3"
  },
  {
    "uniprot": "A0A077YVW6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVW6"
  },
  {
    "uniprot": "A0A077Z253",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z253"
  },
  {
    "uniprot": "A0A077ZJC9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJC9"
  },
  {
    "uniprot": "A0A077YXN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXN6"
  },
  {
    "uniprot": "A0A077Z3L0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3L0"
  },
  {
    "uniprot": "A0A077ZH55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH55"
  },
  {
    "uniprot": "A0A077ZF36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF36"
  },
  {
    "uniprot": "A0A077Z724",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z724"
  },
  {
    "uniprot": "A0A077YXY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXY0"
  },
  {
    "uniprot": "A0A077Z0U4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0U4"
  },
  {
    "uniprot": "A0A077Z4C7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4C7"
  },
  {
    "uniprot": "A0A077Z1F9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1F9"
  },
  {
    "uniprot": "A0A077ZHQ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHQ7"
  },
  {
    "uniprot": "A0A077Z9X1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9X1"
  },
  {
    "uniprot": "A0A077ZP89",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP89"
  },
  {
    "uniprot": "A0A077Z525",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z525"
  },
  {
    "uniprot": "A0A077Z6Q8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Q8"
  },
  {
    "uniprot": "A0A077Z0K9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0K9"
  },
  {
    "uniprot": "A0A077ZL64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL64"
  },
  {
    "uniprot": "A0A077ZFS0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFS0"
  },
  {
    "uniprot": "A0A077ZFY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFY6"
  },
  {
    "uniprot": "A0A077Z3P3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3P3"
  },
  {
    "uniprot": "A0A077ZMS8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMS8"
  },
  {
    "uniprot": "A0A077Z9Y1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Y1"
  },
  {
    "uniprot": "A0A077ZAT7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAT7"
  },
  {
    "uniprot": "A0A077ZBV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBV6"
  },
  {
    "uniprot": "A0A077ZLT1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLT1"
  },
  {
    "uniprot": "A0A077ZEJ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEJ9"
  },
  {
    "uniprot": "A0A077ZF32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF32"
  },
  {
    "uniprot": "A0A077Z8G5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8G5"
  },
  {
    "uniprot": "A0A077ZKL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKL6"
  },
  {
    "uniprot": "A0A077ZM13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM13"
  },
  {
    "uniprot": "A0A077ZGY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGY3"
  },
  {
    "uniprot": "A0A077Z7H5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7H5"
  },
  {
    "uniprot": "A0A077ZES2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZES2"
  },
  {
    "uniprot": "A0A077Z6B2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6B2"
  },
  {
    "uniprot": "A0A077ZN51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN51"
  },
  {
    "uniprot": "A0A077Z0N1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0N1"
  },
  {
    "uniprot": "A0A077YZ86",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ86"
  },
  {
    "uniprot": "A0A077Z8K1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8K1"
  },
  {
    "uniprot": "A0A077Z1A9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1A9"
  },
  {
    "uniprot": "A0A077Z6Z8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Z8"
  },
  {
    "uniprot": "A0A077YXS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXS9"
  },
  {
    "uniprot": "A0A077ZLN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLN1"
  },
  {
    "uniprot": "A0A077ZBU3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBU3"
  },
  {
    "uniprot": "A0A077YZ61",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ61"
  },
  {
    "uniprot": "A0A077ZGR8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGR8"
  },
  {
    "uniprot": "A0A077Z4F3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4F3"
  },
  {
    "uniprot": "A0A077Z7C8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7C8"
  },
  {
    "uniprot": "A0A077Z0R2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0R2"
  },
  {
    "uniprot": "A0A077ZC03",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC03"
  },
  {
    "uniprot": "A0A077ZM54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM54"
  },
  {
    "uniprot": "A0A077Z831",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z831"
  },
  {
    "uniprot": "A0A077Z8Y0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Y0"
  },
  {
    "uniprot": "A0A077ZHP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHP8"
  },
  {
    "uniprot": "A0A077ZFE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFE2"
  },
  {
    "uniprot": "A0A077ZC95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC95"
  },
  {
    "uniprot": "A0A077ZLA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLA1"
  },
  {
    "uniprot": "A0A077ZL03",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL03"
  },
  {
    "uniprot": "A0A077Z505",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z505"
  },
  {
    "uniprot": "A0A077ZAC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAC3"
  },
  {
    "uniprot": "A0A077ZKS8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKS8"
  },
  {
    "uniprot": "A0A077ZJ15",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ15"
  },
  {
    "uniprot": "A0A077ZEI1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEI1"
  },
  {
    "uniprot": "A0A077YYP7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYP7"
  },
  {
    "uniprot": "A0A077ZJ01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ01"
  },
  {
    "uniprot": "A0A077ZBC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBC3"
  },
  {
    "uniprot": "A0A077YWA6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWA6"
  },
  {
    "uniprot": "A0A077ZN02",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN02"
  },
  {
    "uniprot": "A0A077ZMA0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMA0"
  },
  {
    "uniprot": "A0A077ZFU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFU5"
  },
  {
    "uniprot": "A0A077Z1P0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1P0"
  },
  {
    "uniprot": "A0A077ZAP1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAP1"
  },
  {
    "uniprot": "A0A077ZEA4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEA4"
  },
  {
    "uniprot": "A0A077Z9W8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9W8"
  },
  {
    "uniprot": "A0A077YX11",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX11"
  },
  {
    "uniprot": "A0A077ZKH0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKH0"
  },
  {
    "uniprot": "A0A077ZBN5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBN5"
  },
  {
    "uniprot": "A0A077Z2B6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2B6"
  },
  {
    "uniprot": "A0A077ZKP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKP5"
  },
  {
    "uniprot": "A0A077YUL1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUL1"
  },
  {
    "uniprot": "A0A077Z824",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z824"
  },
  {
    "uniprot": "A0A077Z7W8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7W8"
  },
  {
    "uniprot": "A0A077YWE9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWE9"
  },
  {
    "uniprot": "A0A077Z9M4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9M4"
  },
  {
    "uniprot": "A0A077Z2I2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2I2"
  },
  {
    "uniprot": "A0A077ZEX1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEX1"
  },
  {
    "uniprot": "A0A077Z9Z1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Z1"
  },
  {
    "uniprot": "A0A077YWE3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWE3"
  },
  {
    "uniprot": "A0A077ZEB1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEB1"
  },
  {
    "uniprot": "A0A077YVZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVZ9"
  },
  {
    "uniprot": "A0A077YZ38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ38"
  },
  {
    "uniprot": "A0A077Z7C6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7C6"
  },
  {
    "uniprot": "A0A077YVW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVW7"
  },
  {
    "uniprot": "A0A077Z2F6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2F6"
  },
  {
    "uniprot": "A0A077ZGE1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGE1"
  },
  {
    "uniprot": "A0A077Z8B9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8B9"
  },
  {
    "uniprot": "A0A077Z166",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z166"
  },
  {
    "uniprot": "A0A077ZGP7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGP7"
  },
  {
    "uniprot": "A0A077ZHJ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHJ1"
  },
  {
    "uniprot": "A0A077ZGP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGP9"
  },
  {
    "uniprot": "A0A077Z162",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z162"
  },
  {
    "uniprot": "A0A077YWT5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWT5"
  },
  {
    "uniprot": "A0A077ZHS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHS1"
  },
  {
    "uniprot": "A0A077ZI26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI26"
  },
  {
    "uniprot": "A0A077Z624",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z624"
  },
  {
    "uniprot": "A0A077Z9C3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9C3"
  },
  {
    "uniprot": "A0A077Z5M2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5M2"
  },
  {
    "uniprot": "A0A077Z091",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z091"
  },
  {
    "uniprot": "A0A077Z336",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z336"
  },
  {
    "uniprot": "A0A077ZDR2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDR2"
  },
  {
    "uniprot": "A0A077ZLG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLG8"
  },
  {
    "uniprot": "A0A077YZD2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZD2"
  },
  {
    "uniprot": "A0A077ZNS7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNS7"
  },
  {
    "uniprot": "A0A077Z8Q8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Q8"
  },
  {
    "uniprot": "A0A077ZE58",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE58"
  },
  {
    "uniprot": "A0A077ZK48",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK48"
  },
  {
    "uniprot": "A0A077ZDJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDJ8"
  },
  {
    "uniprot": "A0A077YZI5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZI5"
  },
  {
    "uniprot": "A0A077ZEJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEJ0"
  },
  {
    "uniprot": "A0A077ZJE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJE8"
  },
  {
    "uniprot": "A0A077ZEB5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEB5"
  },
  {
    "uniprot": "A0A077ZP79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP79"
  },
  {
    "uniprot": "A0A077Z2D2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2D2"
  },
  {
    "uniprot": "A0A077ZLQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLQ8"
  },
  {
    "uniprot": "A0A077ZG10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG10"
  },
  {
    "uniprot": "A0A077ZH51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH51"
  },
  {
    "uniprot": "A0A077ZMP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMP0"
  },
  {
    "uniprot": "A0A077Z4S6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4S6"
  },
  {
    "uniprot": "A0A077ZKE5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKE5"
  },
  {
    "uniprot": "A0A077Z3B2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3B2"
  },
  {
    "uniprot": "A0A077ZEJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEJ8"
  },
  {
    "uniprot": "A0A077ZB99",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB99"
  },
  {
    "uniprot": "A0A077ZM67",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM67"
  },
  {
    "uniprot": "A0A077ZJQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJQ0"
  },
  {
    "uniprot": "A0A077YYU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYU4"
  },
  {
    "uniprot": "A0A077ZPV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPV1"
  },
  {
    "uniprot": "A0A077ZEC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEC3"
  },
  {
    "uniprot": "A0A077ZLJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLJ0"
  },
  {
    "uniprot": "A0A077Z563",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z563"
  },
  {
    "uniprot": "A0A077Z267",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z267"
  },
  {
    "uniprot": "A0A077Z2G9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2G9"
  },
  {
    "uniprot": "A0A077ZDL3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDL3"
  },
  {
    "uniprot": "A0A077Z3U7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3U7"
  },
  {
    "uniprot": "A0A077ZA96",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA96"
  },
  {
    "uniprot": "A0A077Z399",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z399"
  },
  {
    "uniprot": "A0A077Z3L9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3L9"
  },
  {
    "uniprot": "A0A077ZG30",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG30"
  },
  {
    "uniprot": "A0A077ZGM0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGM0"
  },
  {
    "uniprot": "A0A077ZKJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKJ5"
  },
  {
    "uniprot": "A0A077Z221",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z221"
  },
  {
    "uniprot": "A0A077ZMH9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMH9"
  },
  {
    "uniprot": "A0A077Z179",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z179"
  },
  {
    "uniprot": "A0A077ZCF8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCF8"
  },
  {
    "uniprot": "A0A077Z7E3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7E3"
  },
  {
    "uniprot": "A0A077Z7R9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7R9"
  },
  {
    "uniprot": "A0A077ZAF7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAF7"
  },
  {
    "uniprot": "A0A077ZF13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF13"
  },
  {
    "uniprot": "A0A077YZN5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZN5"
  },
  {
    "uniprot": "A0A077YVL0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVL0"
  },
  {
    "uniprot": "A0A077ZEM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEM7"
  },
  {
    "uniprot": "A0A077Z3G7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3G7"
  },
  {
    "uniprot": "A0A077ZFV2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFV2"
  },
  {
    "uniprot": "A0A077Z238",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z238"
  },
  {
    "uniprot": "A0A077Z528",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z528"
  },
  {
    "uniprot": "A0A077ZJL3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJL3"
  },
  {
    "uniprot": "A0A077Z6C3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6C3"
  },
  {
    "uniprot": "A0A077Z173",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z173"
  },
  {
    "uniprot": "A0A077Z3V9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3V9"
  },
  {
    "uniprot": "A0A077ZEC8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEC8"
  },
  {
    "uniprot": "A0A077YW28",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW28"
  },
  {
    "uniprot": "A0A077ZHN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHN4"
  },
  {
    "uniprot": "A0A077ZB28",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB28"
  },
  {
    "uniprot": "A0A077ZHK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHK9"
  },
  {
    "uniprot": "A0A077ZCY7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCY7"
  },
  {
    "uniprot": "A0A077ZB11",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB11"
  },
  {
    "uniprot": "A0A077ZJZ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJZ2"
  },
  {
    "uniprot": "A0A077ZQU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQU4"
  },
  {
    "uniprot": "A0A077ZE16",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE16"
  },
  {
    "uniprot": "A0A077Z7U7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7U7"
  },
  {
    "uniprot": "A0A077YZ15",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ15"
  },
  {
    "uniprot": "A0A077Z7G4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7G4"
  },
  {
    "uniprot": "A0A077Z2I0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2I0"
  },
  {
    "uniprot": "A0A077ZJA9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJA9"
  },
  {
    "uniprot": "A0A077ZFU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFU4"
  },
  {
    "uniprot": "A0A077Z690",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z690"
  },
  {
    "uniprot": "A0A077ZBS3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBS3"
  },
  {
    "uniprot": "A0A077ZHV2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHV2"
  },
  {
    "uniprot": "A0A077ZB55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB55"
  },
  {
    "uniprot": "A0A077Z8I4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8I4"
  },
  {
    "uniprot": "A0A077Z8T4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8T4"
  },
  {
    "uniprot": "A0A077Z1K1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1K1"
  },
  {
    "uniprot": "A0A077Z6X6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6X6"
  },
  {
    "uniprot": "A0A077ZDU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDU6"
  },
  {
    "uniprot": "A0A077Z237",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z237"
  },
  {
    "uniprot": "A0A077ZGP6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGP6"
  },
  {
    "uniprot": "A0A077ZMV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMV3"
  },
  {
    "uniprot": "A0A077YYM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYM6"
  },
  {
    "uniprot": "A0A077YZ09",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ09"
  },
  {
    "uniprot": "A0A077Z9E2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9E2"
  },
  {
    "uniprot": "A0A077YYH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYH1"
  },
  {
    "uniprot": "A0A077Z1R9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1R9"
  },
  {
    "uniprot": "A0A077Z384",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z384"
  },
  {
    "uniprot": "A0A077ZAJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAJ2"
  },
  {
    "uniprot": "A0A077ZHR1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHR1"
  },
  {
    "uniprot": "A0A077ZM34",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM34"
  },
  {
    "uniprot": "A0A077ZCS7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCS7"
  },
  {
    "uniprot": "A0A077Z4Y7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Y7"
  },
  {
    "uniprot": "A0A077Z3X3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3X3"
  },
  {
    "uniprot": "A0A077ZJN5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJN5"
  },
  {
    "uniprot": "A0A077ZBI0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBI0"
  },
  {
    "uniprot": "A0A077YZ20",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ20"
  },
  {
    "uniprot": "A0A077ZBN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBN2"
  },
  {
    "uniprot": "A0A077ZLD2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLD2"
  },
  {
    "uniprot": "A0A077Z795",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z795"
  },
  {
    "uniprot": "A0A077Z9J3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9J3"
  },
  {
    "uniprot": "A0A077Z306",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z306"
  },
  {
    "uniprot": "A0A077YY01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY01"
  },
  {
    "uniprot": "A0A077ZCJ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCJ1"
  },
  {
    "uniprot": "A0A077ZEN0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEN0"
  },
  {
    "uniprot": "A0A077ZDN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDN4"
  },
  {
    "uniprot": "A0A077Z7B5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7B5"
  },
  {
    "uniprot": "A0A077Z810",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z810"
  },
  {
    "uniprot": "A0A077YW31",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW31"
  },
  {
    "uniprot": "A0A077Z049",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z049"
  },
  {
    "uniprot": "A0A077ZQY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQY3"
  },
  {
    "uniprot": "A0A077ZC08",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC08"
  },
  {
    "uniprot": "A0A077ZGW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGW1"
  },
  {
    "uniprot": "A0A077Z4P3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4P3"
  },
  {
    "uniprot": "A0A077ZJT2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJT2"
  },
  {
    "uniprot": "A0A077ZHJ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHJ9"
  },
  {
    "uniprot": "A0A077ZPX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPX9"
  },
  {
    "uniprot": "A0A077Z7G0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7G0"
  },
  {
    "uniprot": "A0A077YWM3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWM3"
  },
  {
    "uniprot": "A0A077Z5D6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5D6"
  },
  {
    "uniprot": "A0A077Z7P5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7P5"
  },
  {
    "uniprot": "A0A077Z4H7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4H7"
  },
  {
    "uniprot": "A0A077ZIR1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIR1"
  },
  {
    "uniprot": "A0A077YZM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZM7"
  },
  {
    "uniprot": "A0A077ZBP1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBP1"
  },
  {
    "uniprot": "A0A077YZA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZA1"
  },
  {
    "uniprot": "A0A077ZD43",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD43"
  },
  {
    "uniprot": "A0A077Z0F6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0F6"
  },
  {
    "uniprot": "A0A077ZHM9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHM9"
  },
  {
    "uniprot": "A0A077YWI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWI8"
  },
  {
    "uniprot": "A0A077ZEI9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEI9"
  },
  {
    "uniprot": "A0A077YZR1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZR1"
  },
  {
    "uniprot": "A0A077YZK0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZK0"
  },
  {
    "uniprot": "A0A077ZA50",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA50"
  },
  {
    "uniprot": "A0A077Z4X3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4X3"
  },
  {
    "uniprot": "A0A077ZFN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFN1"
  },
  {
    "uniprot": "A0A077Z659",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z659"
  },
  {
    "uniprot": "A0A077ZGN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGN1"
  },
  {
    "uniprot": "A0A077Z023",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z023"
  },
  {
    "uniprot": "A0A077ZKR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKR6"
  },
  {
    "uniprot": "A0A077YXG1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXG1"
  },
  {
    "uniprot": "A0A077Z119",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z119"
  },
  {
    "uniprot": "A0A077ZC26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC26"
  },
  {
    "uniprot": "A0A077Z4N7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4N7"
  },
  {
    "uniprot": "A0A077ZMI7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMI7"
  },
  {
    "uniprot": "A0A077YY90",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY90"
  },
  {
    "uniprot": "A0A077Z7B8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7B8"
  },
  {
    "uniprot": "A0A077Z2N3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2N3"
  },
  {
    "uniprot": "A0A077Z5X8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5X8"
  },
  {
    "uniprot": "A0A077Z7V2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7V2"
  },
  {
    "uniprot": "A0A077ZG68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG68"
  },
  {
    "uniprot": "A0A077ZHC4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHC4"
  },
  {
    "uniprot": "A0A077YWC2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWC2"
  },
  {
    "uniprot": "A0A077ZK82",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK82"
  },
  {
    "uniprot": "A0A077ZNA4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNA4"
  },
  {
    "uniprot": "A0A077ZCV8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCV8"
  },
  {
    "uniprot": "A0A077YYW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYW9"
  },
  {
    "uniprot": "A0A077ZDS8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDS8"
  },
  {
    "uniprot": "A0A077YVG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVG3"
  },
  {
    "uniprot": "A0A077Z2R8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2R8"
  },
  {
    "uniprot": "A0A077YWN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWN3"
  },
  {
    "uniprot": "A0A077ZNH4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNH4"
  },
  {
    "uniprot": "A0A077ZF14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF14"
  },
  {
    "uniprot": "A0A077ZEN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEN9"
  },
  {
    "uniprot": "A0A077ZJ47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ47"
  },
  {
    "uniprot": "A0A077Z6V3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6V3"
  },
  {
    "uniprot": "A0A077ZK33",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK33"
  },
  {
    "uniprot": "A0A077ZAW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAW0"
  },
  {
    "uniprot": "A0A077ZCD6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCD6"
  },
  {
    "uniprot": "A0A077Z3G8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3G8"
  },
  {
    "uniprot": "A0A077Z2M7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2M7"
  },
  {
    "uniprot": "A0A077Z9A5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9A5"
  },
  {
    "uniprot": "A0A077Z8D4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8D4"
  },
  {
    "uniprot": "A0A077ZNC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNC3"
  },
  {
    "uniprot": "A0A077ZJE7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJE7"
  },
  {
    "uniprot": "A0A077ZA75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA75"
  },
  {
    "uniprot": "A0A077YZJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZJ7"
  },
  {
    "uniprot": "A0A077YY92",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY92"
  },
  {
    "uniprot": "A0A077ZKN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKN7"
  },
  {
    "uniprot": "A0A077YZ02",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ02"
  },
  {
    "uniprot": "A0A077Z8H1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8H1"
  },
  {
    "uniprot": "A0A077Z4J3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4J3"
  },
  {
    "uniprot": "A0A077YYM3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYM3"
  },
  {
    "uniprot": "A0A077ZI15",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI15"
  },
  {
    "uniprot": "A0A077Z0X2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0X2"
  },
  {
    "uniprot": "A0A077Z5J1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5J1"
  },
  {
    "uniprot": "A0A077Z5E2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5E2"
  },
  {
    "uniprot": "A0A077ZA11",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA11"
  },
  {
    "uniprot": "A0A077Z6H5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6H5"
  },
  {
    "uniprot": "A0A077ZJK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJK9"
  },
  {
    "uniprot": "A0A077ZDY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDY0"
  },
  {
    "uniprot": "A0A077ZBA3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBA3"
  },
  {
    "uniprot": "A0A077YZ21",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ21"
  },
  {
    "uniprot": "A0A077ZLA7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLA7"
  },
  {
    "uniprot": "A0A077YZI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZI3"
  },
  {
    "uniprot": "A0A077Z087",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z087"
  },
  {
    "uniprot": "A0A077ZA40",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA40"
  },
  {
    "uniprot": "A0A077Z394",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z394"
  },
  {
    "uniprot": "A0A077Z7G5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7G5"
  },
  {
    "uniprot": "A0A077Z5U6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5U6"
  },
  {
    "uniprot": "A0A077ZBS7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBS7"
  },
  {
    "uniprot": "A0A077ZIS5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIS5"
  },
  {
    "uniprot": "A0A077ZF85",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF85"
  },
  {
    "uniprot": "A0A077YXA2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXA2"
  },
  {
    "uniprot": "A0A077ZDW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDW5"
  },
  {
    "uniprot": "A0A077Z3W7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3W7"
  },
  {
    "uniprot": "A0A077ZGK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGK4"
  },
  {
    "uniprot": "A0A077Z874",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z874"
  },
  {
    "uniprot": "A0A077YYZ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYZ6"
  },
  {
    "uniprot": "A0A077YUP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUP8"
  },
  {
    "uniprot": "A0A077ZMN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMN7"
  },
  {
    "uniprot": "A0A077Z533",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z533"
  },
  {
    "uniprot": "A0A077Z471",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z471"
  },
  {
    "uniprot": "A0A077Z2L4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2L4"
  },
  {
    "uniprot": "A0A077ZKU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKU2"
  },
  {
    "uniprot": "A0A077Z9I3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9I3"
  },
  {
    "uniprot": "A0A077ZMB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMB0"
  },
  {
    "uniprot": "A0A077ZBD7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBD7"
  },
  {
    "uniprot": "A0A077Z6W8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6W8"
  },
  {
    "uniprot": "A0A077Z674",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z674"
  },
  {
    "uniprot": "A0A077Z062",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z062"
  },
  {
    "uniprot": "A0A077Z8R9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8R9"
  },
  {
    "uniprot": "A0A077ZQE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQE8"
  },
  {
    "uniprot": "A0A077ZJY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJY8"
  },
  {
    "uniprot": "A0A077ZCB5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCB5"
  },
  {
    "uniprot": "A0A077YY08",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY08"
  },
  {
    "uniprot": "A0A077ZK69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK69"
  },
  {
    "uniprot": "A0A077YZN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZN7"
  },
  {
    "uniprot": "A0A077ZD23",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD23"
  },
  {
    "uniprot": "A0A077ZE42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE42"
  },
  {
    "uniprot": "A0A077YUU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUU6"
  },
  {
    "uniprot": "A0A077Z566",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z566"
  },
  {
    "uniprot": "A0A077ZJD4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJD4"
  },
  {
    "uniprot": "A0A077Z9K8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9K8"
  },
  {
    "uniprot": "A0A077ZKM0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKM0"
  },
  {
    "uniprot": "A0A077Z2G4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2G4"
  },
  {
    "uniprot": "A0A077Z2E9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2E9"
  },
  {
    "uniprot": "A0A077ZGJ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGJ9"
  },
  {
    "uniprot": "A0A077Z1T9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1T9"
  },
  {
    "uniprot": "A0A077YW59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW59"
  },
  {
    "uniprot": "A0A077ZFX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFX7"
  },
  {
    "uniprot": "A0A077ZKZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKZ4"
  },
  {
    "uniprot": "A0A077ZHG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHG3"
  },
  {
    "uniprot": "A0A077ZMR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMR6"
  },
  {
    "uniprot": "A0A077Z7Y4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Y4"
  },
  {
    "uniprot": "A0A077ZC65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC65"
  },
  {
    "uniprot": "A0A077ZLZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLZ4"
  },
  {
    "uniprot": "A0A077ZJI9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJI9"
  },
  {
    "uniprot": "A0A077Z1X4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1X4"
  },
  {
    "uniprot": "A0A077Z977",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z977"
  },
  {
    "uniprot": "A0A077Z7Y5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Y5"
  },
  {
    "uniprot": "A0A077ZI99",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI99"
  },
  {
    "uniprot": "A0A077ZD16",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD16"
  },
  {
    "uniprot": "A0A077ZGW4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGW4"
  },
  {
    "uniprot": "A0A077ZGV0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGV0"
  },
  {
    "uniprot": "A0A077ZLQ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLQ7"
  },
  {
    "uniprot": "A0A077ZLN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLN2"
  },
  {
    "uniprot": "A0A077ZQE6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQE6"
  },
  {
    "uniprot": "A0A077ZFV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFV3"
  },
  {
    "uniprot": "A0A077ZGW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGW9"
  },
  {
    "uniprot": "A0A077ZCR0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCR0"
  },
  {
    "uniprot": "A0A077Z711",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z711"
  },
  {
    "uniprot": "A0A077ZNY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNY1"
  },
  {
    "uniprot": "A0A077ZQ06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ06"
  },
  {
    "uniprot": "A0A077YUV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUV9"
  },
  {
    "uniprot": "A0A077YWF5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWF5"
  },
  {
    "uniprot": "A0A077ZC93",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC93"
  },
  {
    "uniprot": "A0A077Z2P3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2P3"
  },
  {
    "uniprot": "A0A077ZKZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKZ8"
  },
  {
    "uniprot": "A0A077Z4Z4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Z4"
  },
  {
    "uniprot": "A0A077Z6U7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6U7"
  },
  {
    "uniprot": "A0A077ZDC5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDC5"
  },
  {
    "uniprot": "A0A077ZN57",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN57"
  },
  {
    "uniprot": "A0A077Z498",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z498"
  },
  {
    "uniprot": "A0A077ZDY2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDY2"
  },
  {
    "uniprot": "A0A077Z9S4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9S4"
  },
  {
    "uniprot": "A0A077ZJ74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ74"
  },
  {
    "uniprot": "A0A077ZG48",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG48"
  },
  {
    "uniprot": "A0A077Z6C7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6C7"
  },
  {
    "uniprot": "A0A077ZGK0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGK0"
  },
  {
    "uniprot": "A0A077ZGE5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGE5"
  },
  {
    "uniprot": "A0A077ZIW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIW9"
  },
  {
    "uniprot": "A0A077ZMD6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMD6"
  },
  {
    "uniprot": "A0A077Z094",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z094"
  },
  {
    "uniprot": "A0A077ZK55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK55"
  },
  {
    "uniprot": "A0A077Z8A9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8A9"
  },
  {
    "uniprot": "A0A077ZBG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBG3"
  },
  {
    "uniprot": "A0A077YZ84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ84"
  },
  {
    "uniprot": "A0A077YYP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYP3"
  },
  {
    "uniprot": "A0A077ZAB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAB9"
  },
  {
    "uniprot": "A0A077ZIP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIP5"
  },
  {
    "uniprot": "A0A077ZDA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDA1"
  },
  {
    "uniprot": "A0A077Z590",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z590"
  },
  {
    "uniprot": "A0A077ZFH4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFH4"
  },
  {
    "uniprot": "A0A077ZDR1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDR1"
  },
  {
    "uniprot": "A0A077Z2B9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2B9"
  },
  {
    "uniprot": "A0A077Z0Z5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Z5"
  },
  {
    "uniprot": "A0A077Z925",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z925"
  },
  {
    "uniprot": "A0A077ZFL9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFL9"
  },
  {
    "uniprot": "A0A077ZD54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD54"
  },
  {
    "uniprot": "A0A077YW10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW10"
  },
  {
    "uniprot": "A0A077ZD49",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD49"
  },
  {
    "uniprot": "A0A077Z9Q1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Q1"
  },
  {
    "uniprot": "A0A077YXT1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXT1"
  },
  {
    "uniprot": "A0A077Z4H8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4H8"
  },
  {
    "uniprot": "A0A077YZK5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZK5"
  },
  {
    "uniprot": "A0A077ZII4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZII4"
  },
  {
    "uniprot": "A0A077ZFB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFB2"
  },
  {
    "uniprot": "A0A077ZJL1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJL1"
  },
  {
    "uniprot": "A0A077ZKF6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKF6"
  },
  {
    "uniprot": "A0A077ZK47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK47"
  },
  {
    "uniprot": "A0A077Z5R6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5R6"
  },
  {
    "uniprot": "A0A077Z8T6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8T6"
  },
  {
    "uniprot": "A0A077YX12",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX12"
  },
  {
    "uniprot": "A0A077ZEE0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEE0"
  },
  {
    "uniprot": "A0A077ZAD5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAD5"
  },
  {
    "uniprot": "A0A077Z463",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z463"
  },
  {
    "uniprot": "A0A077Z2F4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2F4"
  },
  {
    "uniprot": "A0A077ZL15",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL15"
  },
  {
    "uniprot": "A0A077ZLW4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLW4"
  },
  {
    "uniprot": "A0A077ZCB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCB6"
  },
  {
    "uniprot": "A0A077Z3N2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3N2"
  },
  {
    "uniprot": "A0A077ZA80",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA80"
  },
  {
    "uniprot": "A0A077ZJD3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJD3"
  },
  {
    "uniprot": "A0A077Z9U8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9U8"
  },
  {
    "uniprot": "A0A077Z5G1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5G1"
  },
  {
    "uniprot": "A0A077Z112",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z112"
  },
  {
    "uniprot": "A0A077YYR8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYR8"
  },
  {
    "uniprot": "A0A077YWK8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWK8"
  },
  {
    "uniprot": "A0A077ZL20",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL20"
  },
  {
    "uniprot": "A0A077Z7Y3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Y3"
  },
  {
    "uniprot": "A0A077ZGD1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGD1"
  },
  {
    "uniprot": "A0A077ZEP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEP2"
  },
  {
    "uniprot": "A0A077Z1U8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1U8"
  },
  {
    "uniprot": "A0A077ZN44",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN44"
  },
  {
    "uniprot": "A0A077Z8D1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8D1"
  },
  {
    "uniprot": "A0A077ZCG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCG9"
  },
  {
    "uniprot": "A0A077Z086",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z086"
  },
  {
    "uniprot": "A0A077YXY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXY1"
  },
  {
    "uniprot": "A0A077Z5E8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5E8"
  },
  {
    "uniprot": "A0A077Z307",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z307"
  },
  {
    "uniprot": "A0A077ZC34",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC34"
  },
  {
    "uniprot": "A0A077ZG06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG06"
  },
  {
    "uniprot": "A0A077YYA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYA1"
  },
  {
    "uniprot": "A0A077Z681",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z681"
  },
  {
    "uniprot": "A0A077ZR64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR64"
  },
  {
    "uniprot": "A0A077Z5W3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5W3"
  },
  {
    "uniprot": "A0A077ZMP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMP4"
  },
  {
    "uniprot": "A0A077ZM89",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM89"
  },
  {
    "uniprot": "A0A077Z655",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z655"
  },
  {
    "uniprot": "A0A077ZG76",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG76"
  },
  {
    "uniprot": "A0A077YYN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYN6"
  },
  {
    "uniprot": "A0A077ZEW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEW0"
  },
  {
    "uniprot": "A0A077ZD84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD84"
  },
  {
    "uniprot": "A0A077ZA01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA01"
  },
  {
    "uniprot": "A0A077YYX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYX9"
  },
  {
    "uniprot": "A0A077YXB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXB0"
  },
  {
    "uniprot": "A0A077Z3M9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3M9"
  },
  {
    "uniprot": "A0A077ZCZ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCZ7"
  },
  {
    "uniprot": "A0A077ZAU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAU5"
  },
  {
    "uniprot": "A0A077Z7C5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7C5"
  },
  {
    "uniprot": "A0A077ZB35",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB35"
  },
  {
    "uniprot": "A0A077ZKH9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKH9"
  },
  {
    "uniprot": "A0A077ZIS8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIS8"
  },
  {
    "uniprot": "A0A077Z2Q6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Q6"
  },
  {
    "uniprot": "A0A077Z0G7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0G7"
  },
  {
    "uniprot": "A0A077ZAJ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAJ6"
  },
  {
    "uniprot": "A0A077Z7B6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7B6"
  },
  {
    "uniprot": "A0A077ZI20",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI20"
  },
  {
    "uniprot": "A0A077YZA6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZA6"
  },
  {
    "uniprot": "A0A077ZGI0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGI0"
  },
  {
    "uniprot": "A0A077YXQ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXQ6"
  },
  {
    "uniprot": "A0A077ZCL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCL7"
  },
  {
    "uniprot": "A0A077Z9M5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9M5"
  },
  {
    "uniprot": "A0A077ZGS0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGS0"
  },
  {
    "uniprot": "A0A077Z9L2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9L2"
  },
  {
    "uniprot": "A0A077Z297",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z297"
  },
  {
    "uniprot": "A0A077ZAF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAF9"
  },
  {
    "uniprot": "A0A077YVT0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVT0"
  },
  {
    "uniprot": "A0A077Z0M4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0M4"
  },
  {
    "uniprot": "A0A077Z8F9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8F9"
  },
  {
    "uniprot": "A0A077ZF72",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF72"
  },
  {
    "uniprot": "A0A077Z6T4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6T4"
  },
  {
    "uniprot": "A0A077Z8K5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8K5"
  },
  {
    "uniprot": "A0A077Z0I2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0I2"
  },
  {
    "uniprot": "A0A077YXG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXG5"
  },
  {
    "uniprot": "A0A077ZKP7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKP7"
  },
  {
    "uniprot": "A0A077ZNX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNX0"
  },
  {
    "uniprot": "A0A077ZC22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC22"
  },
  {
    "uniprot": "A0A077Z783",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z783"
  },
  {
    "uniprot": "A0A077ZD70",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD70"
  },
  {
    "uniprot": "A0A077ZHU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHU9"
  },
  {
    "uniprot": "A0A077ZAC8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAC8"
  },
  {
    "uniprot": "A0A077Z1I9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1I9"
  },
  {
    "uniprot": "A0A077Z0V5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0V5"
  },
  {
    "uniprot": "A0A077ZIN8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIN8"
  },
  {
    "uniprot": "A0A077ZDY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDY9"
  },
  {
    "uniprot": "A0A077YZS7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZS7"
  },
  {
    "uniprot": "A0A077ZA46",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA46"
  },
  {
    "uniprot": "A0A077Z879",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z879"
  },
  {
    "uniprot": "A0A077ZAS6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAS6"
  },
  {
    "uniprot": "A0A077ZJL9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJL9"
  },
  {
    "uniprot": "A0A077Z9V2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9V2"
  },
  {
    "uniprot": "A0A077Z082",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z082"
  },
  {
    "uniprot": "A0A077YWI5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWI5"
  },
  {
    "uniprot": "A0A077YWJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWJ8"
  },
  {
    "uniprot": "A0A077ZK16",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK16"
  },
  {
    "uniprot": "A0A077ZH96",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH96"
  },
  {
    "uniprot": "A0A077Z2D8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2D8"
  },
  {
    "uniprot": "A0A077Z1C6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1C6"
  },
  {
    "uniprot": "A0A077Z2G8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2G8"
  },
  {
    "uniprot": "A0A077ZK52",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK52"
  },
  {
    "uniprot": "A0A077YXW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXW1"
  },
  {
    "uniprot": "A0A077Z0L7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0L7"
  },
  {
    "uniprot": "A0A077YXN0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXN0"
  },
  {
    "uniprot": "A0A077ZB23",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB23"
  },
  {
    "uniprot": "A0A077ZFU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFU9"
  },
  {
    "uniprot": "A0A077Z7T1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7T1"
  },
  {
    "uniprot": "A0A077ZB84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB84"
  },
  {
    "uniprot": "A0A077Z9Q9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Q9"
  },
  {
    "uniprot": "A0A077Z2H6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2H6"
  },
  {
    "uniprot": "A0A077Z9S0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9S0"
  },
  {
    "uniprot": "A0A077ZDL1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDL1"
  },
  {
    "uniprot": "A0A077Z6F2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6F2"
  },
  {
    "uniprot": "A0A077ZI65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI65"
  },
  {
    "uniprot": "A0A077Z630",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z630"
  },
  {
    "uniprot": "A0A077YYF8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYF8"
  },
  {
    "uniprot": "A0A077Z5Q2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Q2"
  },
  {
    "uniprot": "A0A077ZHT7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHT7"
  },
  {
    "uniprot": "A0A077Z8R5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8R5"
  },
  {
    "uniprot": "A0A077ZJ86",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ86"
  },
  {
    "uniprot": "A0A077Z817",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z817"
  },
  {
    "uniprot": "A0A077ZR49",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR49"
  },
  {
    "uniprot": "A0A077Z1B8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1B8"
  },
  {
    "uniprot": "A0A077ZK13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK13"
  },
  {
    "uniprot": "A0A077YXY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXY9"
  },
  {
    "uniprot": "A0A077ZCI7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCI7"
  },
  {
    "uniprot": "A0A077ZBZ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBZ0"
  },
  {
    "uniprot": "A0A077ZJ93",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ93"
  },
  {
    "uniprot": "A0A077Z2B8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2B8"
  },
  {
    "uniprot": "A0A077Z6Y7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Y7"
  },
  {
    "uniprot": "A0A077ZH18",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH18"
  },
  {
    "uniprot": "A0A077ZCE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCE8"
  },
  {
    "uniprot": "A0A077ZCI9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCI9"
  },
  {
    "uniprot": "A0A077Z330",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z330"
  },
  {
    "uniprot": "A0A077ZF33",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF33"
  },
  {
    "uniprot": "A0A077ZI21",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI21"
  },
  {
    "uniprot": "A0A077ZJ37",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ37"
  },
  {
    "uniprot": "A0A077Z697",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z697"
  },
  {
    "uniprot": "A0A077ZAH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAH5"
  },
  {
    "uniprot": "A0A077ZIZ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIZ0"
  },
  {
    "uniprot": "A0A077Z0T9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0T9"
  },
  {
    "uniprot": "A0A077ZEA3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEA3"
  },
  {
    "uniprot": "A0A077Z029",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z029"
  },
  {
    "uniprot": "A0A077ZG16",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG16"
  },
  {
    "uniprot": "A0A077Z9F1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9F1"
  },
  {
    "uniprot": "A0A077ZLD0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLD0"
  },
  {
    "uniprot": "A0A077ZDP6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDP6"
  },
  {
    "uniprot": "A0A077ZEP7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEP7"
  },
  {
    "uniprot": "A0A077Z7X1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7X1"
  },
  {
    "uniprot": "A0A077Z6T9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6T9"
  },
  {
    "uniprot": "A0A077ZG26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG26"
  },
  {
    "uniprot": "A0A077Z524",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z524"
  },
  {
    "uniprot": "A0A077ZNI7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNI7"
  },
  {
    "uniprot": "A0A077YXU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXU9"
  },
  {
    "uniprot": "A0A077ZL01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL01"
  },
  {
    "uniprot": "A0A077YZ56",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ56"
  },
  {
    "uniprot": "A0A077YW81",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW81"
  },
  {
    "uniprot": "A0A077ZCD5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCD5"
  },
  {
    "uniprot": "A0A077Z067",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z067"
  },
  {
    "uniprot": "A0A077ZI44",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI44"
  },
  {
    "uniprot": "A0A077ZMH7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMH7"
  },
  {
    "uniprot": "A0A077Z6L1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6L1"
  },
  {
    "uniprot": "A0A077Z1V9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1V9"
  },
  {
    "uniprot": "A0A077YZL3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZL3"
  },
  {
    "uniprot": "A0A077ZKN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKN6"
  },
  {
    "uniprot": "A0A077YX43",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX43"
  },
  {
    "uniprot": "A0A077Z5I4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5I4"
  },
  {
    "uniprot": "A0A077ZCU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCU6"
  },
  {
    "uniprot": "A0A077ZAU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAU1"
  },
  {
    "uniprot": "A0A077Z9D9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9D9"
  },
  {
    "uniprot": "A0A077Z310",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z310"
  },
  {
    "uniprot": "A0A077YX85",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX85"
  },
  {
    "uniprot": "A0A077ZBM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBM6"
  },
  {
    "uniprot": "A0A077ZKK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKK3"
  },
  {
    "uniprot": "A0A077ZGZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGZ5"
  },
  {
    "uniprot": "A0A077YX65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX65"
  },
  {
    "uniprot": "A0A077ZLW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLW5"
  },
  {
    "uniprot": "A0A077ZGR4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGR4"
  },
  {
    "uniprot": "A0A077Z5K4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5K4"
  },
  {
    "uniprot": "A0A077ZF30",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF30"
  },
  {
    "uniprot": "A0A077ZIS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIS9"
  },
  {
    "uniprot": "A0A077Z3Y1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Y1"
  },
  {
    "uniprot": "A0A077Z804",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z804"
  },
  {
    "uniprot": "A0A077Z8X6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8X6"
  },
  {
    "uniprot": "A0A077ZBK2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBK2"
  },
  {
    "uniprot": "A0A077Z8P8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8P8"
  },
  {
    "uniprot": "A0A077ZEJ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEJ4"
  },
  {
    "uniprot": "A0A077ZHH7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHH7"
  },
  {
    "uniprot": "A0A077Z113",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z113"
  },
  {
    "uniprot": "A0A077Z358",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z358"
  },
  {
    "uniprot": "A0A077Z1R7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1R7"
  },
  {
    "uniprot": "A0A077YZP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZP9"
  },
  {
    "uniprot": "A0A077ZCH7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCH7"
  },
  {
    "uniprot": "A0A077ZKM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKM2"
  },
  {
    "uniprot": "A0A077YXF5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXF5"
  },
  {
    "uniprot": "A0A077ZKC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKC3"
  },
  {
    "uniprot": "A0A077ZLL2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLL2"
  },
  {
    "uniprot": "A0A077ZC32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC32"
  },
  {
    "uniprot": "A0A077Z7F8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7F8"
  },
  {
    "uniprot": "A0A077ZAF8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAF8"
  },
  {
    "uniprot": "A0A077ZNL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNL6"
  },
  {
    "uniprot": "A0A077ZD57",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD57"
  },
  {
    "uniprot": "A0A077Z467",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z467"
  },
  {
    "uniprot": "A0A077Z187",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z187"
  },
  {
    "uniprot": "A0A077ZNG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNG3"
  },
  {
    "uniprot": "A0A077ZER6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZER6"
  },
  {
    "uniprot": "A0A077Z553",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z553"
  },
  {
    "uniprot": "A0A077ZA48",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA48"
  },
  {
    "uniprot": "A0A077Z8I0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8I0"
  },
  {
    "uniprot": "A0A077Z8D9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8D9"
  },
  {
    "uniprot": "A0A077ZF25",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF25"
  },
  {
    "uniprot": "A0A077ZH06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH06"
  },
  {
    "uniprot": "A0A077YX35",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX35"
  },
  {
    "uniprot": "A0A077ZBM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBM7"
  },
  {
    "uniprot": "A0A077ZI81",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI81"
  },
  {
    "uniprot": "A0A077ZN42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN42"
  },
  {
    "uniprot": "A0A077Z799",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z799"
  },
  {
    "uniprot": "A0A077ZLP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLP5"
  },
  {
    "uniprot": "A0A077ZMW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMW7"
  },
  {
    "uniprot": "A0A077Z0Y9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Y9"
  },
  {
    "uniprot": "A0A077YX86",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX86"
  },
  {
    "uniprot": "A0A077YYJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYJ8"
  },
  {
    "uniprot": "A0A077ZE00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE00"
  },
  {
    "uniprot": "A0A077Z5N5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5N5"
  },
  {
    "uniprot": "A0A077ZIB3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIB3"
  },
  {
    "uniprot": "A0A077ZHW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHW2"
  },
  {
    "uniprot": "A0A077Z9M2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9M2"
  },
  {
    "uniprot": "A0A077YUP1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUP1"
  },
  {
    "uniprot": "A0A077YYI9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYI9"
  },
  {
    "uniprot": "A0A077Z293",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z293"
  },
  {
    "uniprot": "A0A077ZKY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKY4"
  },
  {
    "uniprot": "A0A077ZLU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLU2"
  },
  {
    "uniprot": "A0A077ZEW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEW8"
  },
  {
    "uniprot": "A0A077ZJR3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJR3"
  },
  {
    "uniprot": "A0A077Z6I7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6I7"
  },
  {
    "uniprot": "A0A077ZMF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMF4"
  },
  {
    "uniprot": "A0A077ZJT0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJT0"
  },
  {
    "uniprot": "A0A077ZB95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB95"
  },
  {
    "uniprot": "A0A077ZF18",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF18"
  },
  {
    "uniprot": "A0A077ZAR0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAR0"
  },
  {
    "uniprot": "A0A077Z8P5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8P5"
  },
  {
    "uniprot": "A0A077ZNA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNA8"
  },
  {
    "uniprot": "A0A077Z953",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z953"
  },
  {
    "uniprot": "A0A077ZFE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFE8"
  },
  {
    "uniprot": "A0A077Z9D1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9D1"
  },
  {
    "uniprot": "A0A077Z480",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z480"
  },
  {
    "uniprot": "A0A077YYI7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYI7"
  },
  {
    "uniprot": "A0A077YWL2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWL2"
  },
  {
    "uniprot": "A0A077ZIF5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIF5"
  },
  {
    "uniprot": "A0A077Z940",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z940"
  },
  {
    "uniprot": "A0A077ZD21",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD21"
  },
  {
    "uniprot": "A0A077Z261",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z261"
  },
  {
    "uniprot": "A0A077Z2Z8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Z8"
  },
  {
    "uniprot": "A0A077ZJ03",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ03"
  },
  {
    "uniprot": "A0A077Z8I3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8I3"
  },
  {
    "uniprot": "A0A077Z518",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z518"
  },
  {
    "uniprot": "A0A077Z1F4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1F4"
  },
  {
    "uniprot": "A0A077Z2M9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2M9"
  },
  {
    "uniprot": "A0A077Z618",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z618"
  },
  {
    "uniprot": "A0A077ZAJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAJ5"
  },
  {
    "uniprot": "A0A077YY37",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY37"
  },
  {
    "uniprot": "A0A077Z0W4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0W4"
  },
  {
    "uniprot": "A0A077Z911",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z911"
  },
  {
    "uniprot": "A0A077ZHM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHM2"
  },
  {
    "uniprot": "A0A077Z2F7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2F7"
  },
  {
    "uniprot": "A0A077Z705",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z705"
  },
  {
    "uniprot": "A0A077Z2K6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2K6"
  },
  {
    "uniprot": "A0A077ZQW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQW1"
  },
  {
    "uniprot": "A0A077Z4R6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4R6"
  },
  {
    "uniprot": "A0A077Z9V8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9V8"
  },
  {
    "uniprot": "A0A077ZJ72",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ72"
  },
  {
    "uniprot": "A0A077YWZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWZ4"
  },
  {
    "uniprot": "A0A077ZAI5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAI5"
  },
  {
    "uniprot": "A0A077Z9V9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9V9"
  },
  {
    "uniprot": "A0A077ZNR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNR6"
  },
  {
    "uniprot": "A0A077Z339",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z339"
  },
  {
    "uniprot": "A0A077Z7L0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7L0"
  },
  {
    "uniprot": "A0A077Z7D2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7D2"
  },
  {
    "uniprot": "A0A077ZDQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDQ4"
  },
  {
    "uniprot": "A0A077Z2Y8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Y8"
  },
  {
    "uniprot": "A0A077YWV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWV1"
  },
  {
    "uniprot": "A0A077ZBK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBK4"
  },
  {
    "uniprot": "A0A077ZPU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPU6"
  },
  {
    "uniprot": "A0A077Z1B6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1B6"
  },
  {
    "uniprot": "A0A077Z2W2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2W2"
  },
  {
    "uniprot": "A0A077ZAG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAG9"
  },
  {
    "uniprot": "A0A077Z5E0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5E0"
  },
  {
    "uniprot": "A0A077ZNR8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNR8"
  },
  {
    "uniprot": "A0A077ZJF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJF0"
  },
  {
    "uniprot": "A0A077ZIV2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIV2"
  },
  {
    "uniprot": "A0A077ZFP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFP0"
  },
  {
    "uniprot": "A0A077Z169",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z169"
  },
  {
    "uniprot": "A0A077ZAY5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAY5"
  },
  {
    "uniprot": "A0A077ZC81",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC81"
  },
  {
    "uniprot": "A0A077ZKA6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKA6"
  },
  {
    "uniprot": "A0A077Z3L1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3L1"
  },
  {
    "uniprot": "A0A077ZFD1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFD1"
  },
  {
    "uniprot": "A0A077ZC01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC01"
  },
  {
    "uniprot": "A0A077Z3V4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3V4"
  },
  {
    "uniprot": "A0A077Z930",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z930"
  },
  {
    "uniprot": "A0A077Z0K4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0K4"
  },
  {
    "uniprot": "A0A077Z417",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z417"
  },
  {
    "uniprot": "A0A077ZD09",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD09"
  },
  {
    "uniprot": "A0A077Z4G3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4G3"
  },
  {
    "uniprot": "A0A077ZJB5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJB5"
  },
  {
    "uniprot": "A0A077ZJP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJP3"
  },
  {
    "uniprot": "A0A077ZAR3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAR3"
  },
  {
    "uniprot": "A0A077ZMY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMY3"
  },
  {
    "uniprot": "A0A077Z7V5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7V5"
  },
  {
    "uniprot": "A0A077ZHS5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHS5"
  },
  {
    "uniprot": "A0A077ZAY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAY3"
  },
  {
    "uniprot": "A0A077Z1M8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1M8"
  },
  {
    "uniprot": "A0A077ZHY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHY0"
  },
  {
    "uniprot": "A0A077ZGN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGN7"
  },
  {
    "uniprot": "A0A077Z6M7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6M7"
  },
  {
    "uniprot": "A0A077Z761",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z761"
  },
  {
    "uniprot": "A0A077ZIP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIP8"
  },
  {
    "uniprot": "A0A077Z1K4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1K4"
  },
  {
    "uniprot": "A0A077ZCU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCU9"
  },
  {
    "uniprot": "A0A077ZHM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHM8"
  },
  {
    "uniprot": "A0A077YVG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVG5"
  },
  {
    "uniprot": "A0A077Z9R5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9R5"
  },
  {
    "uniprot": "A0A077Z5V8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5V8"
  },
  {
    "uniprot": "A0A077Z631",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z631"
  },
  {
    "uniprot": "A0A077Z0J2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0J2"
  },
  {
    "uniprot": "A0A077ZGW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGW0"
  },
  {
    "uniprot": "A0A077Z937",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z937"
  },
  {
    "uniprot": "A0A077Z7E0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7E0"
  },
  {
    "uniprot": "A0A077Z4X5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4X5"
  },
  {
    "uniprot": "A0A077ZAQ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAQ9"
  },
  {
    "uniprot": "A0A077ZEL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEL7"
  },
  {
    "uniprot": "A0A077ZKJ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKJ6"
  },
  {
    "uniprot": "A0A077ZGC4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGC4"
  },
  {
    "uniprot": "A0A077Z125",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z125"
  },
  {
    "uniprot": "A0A077Z8K8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8K8"
  },
  {
    "uniprot": "A0A077ZA38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA38"
  },
  {
    "uniprot": "A0A077YZT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZT9"
  },
  {
    "uniprot": "A0A077ZK10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK10"
  },
  {
    "uniprot": "A0A077Z1G7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1G7"
  },
  {
    "uniprot": "A0A077YVK0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVK0"
  },
  {
    "uniprot": "A0A077ZLT4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLT4"
  },
  {
    "uniprot": "A0A077Z9I9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9I9"
  },
  {
    "uniprot": "A0A077ZJ71",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ71"
  },
  {
    "uniprot": "A0A077Z3E9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3E9"
  },
  {
    "uniprot": "A0A077ZLW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLW7"
  },
  {
    "uniprot": "A0A077ZDX8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDX8"
  },
  {
    "uniprot": "A0A077Z4B8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4B8"
  },
  {
    "uniprot": "A0A077ZCR1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCR1"
  },
  {
    "uniprot": "A0A077Z1D3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1D3"
  },
  {
    "uniprot": "A0A077YUU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUU2"
  },
  {
    "uniprot": "A0A077ZER0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZER0"
  },
  {
    "uniprot": "A0A077ZL80",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL80"
  },
  {
    "uniprot": "A0A077ZBS8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBS8"
  },
  {
    "uniprot": "A0A077YZN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZN3"
  },
  {
    "uniprot": "A0A077ZF34",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF34"
  },
  {
    "uniprot": "A0A077Z7L1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7L1"
  },
  {
    "uniprot": "A0A077ZI47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI47"
  },
  {
    "uniprot": "A0A077Z9E6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9E6"
  },
  {
    "uniprot": "A0A077ZNB5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNB5"
  },
  {
    "uniprot": "A0A077Z5P7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5P7"
  },
  {
    "uniprot": "A0A077Z4Q3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Q3"
  },
  {
    "uniprot": "A0A077ZLM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLM1"
  },
  {
    "uniprot": "A0A077YUV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUV4"
  },
  {
    "uniprot": "A0A077Z8F1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8F1"
  },
  {
    "uniprot": "A0A077ZGQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGQ8"
  },
  {
    "uniprot": "A0A077ZM68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM68"
  },
  {
    "uniprot": "A0A077YXF3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXF3"
  },
  {
    "uniprot": "A0A077Z1S5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1S5"
  },
  {
    "uniprot": "A0A077ZML0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZML0"
  },
  {
    "uniprot": "A0A077Z3I3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3I3"
  },
  {
    "uniprot": "A0A077ZHP1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHP1"
  },
  {
    "uniprot": "A0A077ZM14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM14"
  },
  {
    "uniprot": "A0A077YXQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXQ8"
  },
  {
    "uniprot": "A0A077Z6A2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6A2"
  },
  {
    "uniprot": "A0A077Z9K4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9K4"
  },
  {
    "uniprot": "A0A077ZP22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP22"
  },
  {
    "uniprot": "A0A077YZI7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZI7"
  },
  {
    "uniprot": "A0A077Z0X4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0X4"
  },
  {
    "uniprot": "A0A077Z492",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z492"
  },
  {
    "uniprot": "A0A077ZJ06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ06"
  },
  {
    "uniprot": "A0A077ZLJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLJ5"
  },
  {
    "uniprot": "A0A077Z8H0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8H0"
  },
  {
    "uniprot": "A0A077Z4M6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4M6"
  },
  {
    "uniprot": "A0A077ZB88",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB88"
  },
  {
    "uniprot": "A0A077Z178",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z178"
  },
  {
    "uniprot": "A0A077Z2M8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2M8"
  },
  {
    "uniprot": "A0A077ZH56",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH56"
  },
  {
    "uniprot": "A0A077ZF98",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF98"
  },
  {
    "uniprot": "A0A077ZHR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHR6"
  },
  {
    "uniprot": "A0A077ZJI5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJI5"
  },
  {
    "uniprot": "A0A077ZF83",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF83"
  },
  {
    "uniprot": "A0A077YX25",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX25"
  },
  {
    "uniprot": "A0A077ZDL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDL7"
  },
  {
    "uniprot": "A0A077ZMS6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMS6"
  },
  {
    "uniprot": "A0A077Z9Q0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Q0"
  },
  {
    "uniprot": "A0A077ZDN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDN7"
  },
  {
    "uniprot": "A0A077Z7S4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7S4"
  },
  {
    "uniprot": "A0A077Z1F5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1F5"
  },
  {
    "uniprot": "A0A077ZE59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE59"
  },
  {
    "uniprot": "A0A077ZRB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZRB2"
  },
  {
    "uniprot": "A0A077ZM69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM69"
  },
  {
    "uniprot": "A0A077Z3I5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3I5"
  },
  {
    "uniprot": "A0A077Z1A7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1A7"
  },
  {
    "uniprot": "A0A077ZFC4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFC4"
  },
  {
    "uniprot": "A0A077YVY2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVY2"
  },
  {
    "uniprot": "A0A077ZNG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNG7"
  },
  {
    "uniprot": "A0A077YY78",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY78"
  },
  {
    "uniprot": "A0A077ZG08",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG08"
  },
  {
    "uniprot": "A0A077Z6W9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6W9"
  },
  {
    "uniprot": "A0A077ZCA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCA5"
  },
  {
    "uniprot": "A0A077ZEE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEE2"
  },
  {
    "uniprot": "A0A077Z6U2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6U2"
  },
  {
    "uniprot": "A0A077ZCM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCM8"
  },
  {
    "uniprot": "A0A077Z1P5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1P5"
  },
  {
    "uniprot": "A0A077Z4G4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4G4"
  },
  {
    "uniprot": "A0A077YX87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX87"
  },
  {
    "uniprot": "A0A077YZT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZT8"
  },
  {
    "uniprot": "A0A077Z543",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z543"
  },
  {
    "uniprot": "A0A077ZD68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD68"
  },
  {
    "uniprot": "A0A077ZGA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGA8"
  },
  {
    "uniprot": "A0A077Z071",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z071"
  },
  {
    "uniprot": "A0A077ZEI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEI8"
  },
  {
    "uniprot": "A0A077ZCS6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCS6"
  },
  {
    "uniprot": "A0A077ZK91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK91"
  },
  {
    "uniprot": "A0A077ZGX1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGX1"
  },
  {
    "uniprot": "A0A077ZA74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA74"
  },
  {
    "uniprot": "A0A077ZNH7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNH7"
  },
  {
    "uniprot": "A0A077Z5R5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5R5"
  },
  {
    "uniprot": "A0A077ZBS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBS1"
  },
  {
    "uniprot": "A0A077Z018",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z018"
  },
  {
    "uniprot": "A0A077ZLD3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLD3"
  },
  {
    "uniprot": "A0A077ZBL1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBL1"
  },
  {
    "uniprot": "A0A077YVI1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVI1"
  },
  {
    "uniprot": "A0A077ZG54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG54"
  },
  {
    "uniprot": "A0A077Z4T0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4T0"
  },
  {
    "uniprot": "A0A077ZFG1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFG1"
  },
  {
    "uniprot": "A0A077Z3V3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3V3"
  },
  {
    "uniprot": "A0A077Z973",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z973"
  },
  {
    "uniprot": "A0A077Z6N2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6N2"
  },
  {
    "uniprot": "A0A077ZD45",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD45"
  },
  {
    "uniprot": "A0A077ZAV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAV1"
  },
  {
    "uniprot": "A0A077Z7J7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7J7"
  },
  {
    "uniprot": "A0A077YZP1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZP1"
  },
  {
    "uniprot": "A0A077Z465",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z465"
  },
  {
    "uniprot": "A0A077Z2X4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2X4"
  },
  {
    "uniprot": "A0A077ZDN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDN1"
  },
  {
    "uniprot": "A0A077Z5U8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5U8"
  },
  {
    "uniprot": "A0A077ZAX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAX9"
  },
  {
    "uniprot": "A0A077Z9G7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9G7"
  },
  {
    "uniprot": "A0A077YWR8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWR8"
  },
  {
    "uniprot": "A0A077ZI73",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI73"
  },
  {
    "uniprot": "A0A077ZAL5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAL5"
  },
  {
    "uniprot": "A0A077ZP06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP06"
  },
  {
    "uniprot": "A0A077Z459",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z459"
  },
  {
    "uniprot": "A0A077ZEE3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEE3"
  },
  {
    "uniprot": "A0A077ZE12",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE12"
  },
  {
    "uniprot": "A0A077Z4I3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4I3"
  },
  {
    "uniprot": "A0A077ZH33",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH33"
  },
  {
    "uniprot": "A0A077ZDE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDE8"
  },
  {
    "uniprot": "A0A077ZMD1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMD1"
  },
  {
    "uniprot": "A0A077Z0R6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0R6"
  },
  {
    "uniprot": "A0A077Z5W8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5W8"
  },
  {
    "uniprot": "A0A077ZF61",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF61"
  },
  {
    "uniprot": "A0A077Z6R5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6R5"
  },
  {
    "uniprot": "A0A077ZM16",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM16"
  },
  {
    "uniprot": "A0A077Z060",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z060"
  },
  {
    "uniprot": "A0A077Z6W1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6W1"
  },
  {
    "uniprot": "A0A077Z0E9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0E9"
  },
  {
    "uniprot": "A0A077Z136",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z136"
  },
  {
    "uniprot": "A0A077YVT4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVT4"
  },
  {
    "uniprot": "A0A077Z8A7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8A7"
  },
  {
    "uniprot": "A0A077ZGU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGU2"
  },
  {
    "uniprot": "A0A077ZGG6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGG6"
  },
  {
    "uniprot": "A0A077Z3P9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3P9"
  },
  {
    "uniprot": "A0A077YXM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXM5"
  },
  {
    "uniprot": "A0A077ZEX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEX6"
  },
  {
    "uniprot": "A0A077ZIU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIU9"
  },
  {
    "uniprot": "A0A077Z7V1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7V1"
  },
  {
    "uniprot": "A0A077Z969",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z969"
  },
  {
    "uniprot": "A0A077Z8C4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8C4"
  },
  {
    "uniprot": "A0A077ZBT7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBT7"
  },
  {
    "uniprot": "A0A077Z7S7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7S7"
  },
  {
    "uniprot": "A0A077ZHX1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHX1"
  },
  {
    "uniprot": "A0A077Z0R8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0R8"
  },
  {
    "uniprot": "A0A077ZIW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIW8"
  },
  {
    "uniprot": "A0A077Z0S3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0S3"
  },
  {
    "uniprot": "A0A077Z0U1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0U1"
  },
  {
    "uniprot": "A0A077ZQ14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ14"
  },
  {
    "uniprot": "A0A077Z2S8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2S8"
  },
  {
    "uniprot": "A0A077ZE66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE66"
  },
  {
    "uniprot": "A0A077Z878",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z878"
  },
  {
    "uniprot": "A0A077ZEY2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEY2"
  },
  {
    "uniprot": "A0A077ZC54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC54"
  },
  {
    "uniprot": "A0A077Z9Z6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Z6"
  },
  {
    "uniprot": "A0A077Z054",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z054"
  },
  {
    "uniprot": "A0A077ZN38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN38"
  },
  {
    "uniprot": "A0A077ZLF5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLF5"
  },
  {
    "uniprot": "A0A077ZCY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCY9"
  },
  {
    "uniprot": "A0A077ZBB1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBB1"
  },
  {
    "uniprot": "A0A077Z5B5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5B5"
  },
  {
    "uniprot": "A0A077ZIZ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIZ7"
  },
  {
    "uniprot": "A0A077ZBQ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBQ9"
  },
  {
    "uniprot": "A0A077ZE85",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE85"
  },
  {
    "uniprot": "A0A077ZKE4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKE4"
  },
  {
    "uniprot": "A0A077Z5G5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5G5"
  },
  {
    "uniprot": "A0A077YXU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXU4"
  },
  {
    "uniprot": "A0A077Z7Z9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Z9"
  },
  {
    "uniprot": "A0A077YZX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZX3"
  },
  {
    "uniprot": "A0A077YXR9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXR9"
  },
  {
    "uniprot": "A0A077ZDF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDF9"
  },
  {
    "uniprot": "A0A077Z9A7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9A7"
  },
  {
    "uniprot": "A0A077YX78",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX78"
  },
  {
    "uniprot": "A0A077YY24",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY24"
  },
  {
    "uniprot": "A0A077ZIU8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIU8"
  },
  {
    "uniprot": "A0A077Z2K3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2K3"
  },
  {
    "uniprot": "A0A077Z3S9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3S9"
  },
  {
    "uniprot": "A0A077ZLZ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLZ7"
  },
  {
    "uniprot": "A0A077ZDQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDQ8"
  },
  {
    "uniprot": "A0A077Z6F1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6F1"
  },
  {
    "uniprot": "A0A077ZC63",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC63"
  },
  {
    "uniprot": "A0A077ZN33",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN33"
  },
  {
    "uniprot": "A0A077YWZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWZ3"
  },
  {
    "uniprot": "A0A077Z4X6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4X6"
  },
  {
    "uniprot": "A0A077ZHA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHA5"
  },
  {
    "uniprot": "A0A077ZLJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLJ7"
  },
  {
    "uniprot": "A0A077Z6M0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6M0"
  },
  {
    "uniprot": "A0A077ZNF5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNF5"
  },
  {
    "uniprot": "A0A077Z889",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z889"
  },
  {
    "uniprot": "A0A077YZS5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZS5"
  },
  {
    "uniprot": "A0A077YY63",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY63"
  },
  {
    "uniprot": "A0A077ZIX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIX3"
  },
  {
    "uniprot": "A0A077ZLT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLT9"
  },
  {
    "uniprot": "A0A077YZ82",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ82"
  },
  {
    "uniprot": "A0A077YWZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWZ1"
  },
  {
    "uniprot": "A0A077ZKI7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKI7"
  },
  {
    "uniprot": "A0A077Z5R8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5R8"
  },
  {
    "uniprot": "A0A077Z873",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z873"
  },
  {
    "uniprot": "A0A077ZE35",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE35"
  },
  {
    "uniprot": "A0A077ZG01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG01"
  },
  {
    "uniprot": "A0A077ZC24",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC24"
  },
  {
    "uniprot": "A0A077Z1Y1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Y1"
  },
  {
    "uniprot": "A0A077YZE6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZE6"
  },
  {
    "uniprot": "A0A077ZGN0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGN0"
  },
  {
    "uniprot": "A0A077Z793",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z793"
  },
  {
    "uniprot": "A0A077ZFP7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFP7"
  },
  {
    "uniprot": "A0A077Z9N1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9N1"
  },
  {
    "uniprot": "A0A077ZJ09",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ09"
  },
  {
    "uniprot": "A0A077YZR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZR6"
  },
  {
    "uniprot": "A0A077ZPS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPS9"
  },
  {
    "uniprot": "A0A077Z2H8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2H8"
  },
  {
    "uniprot": "A0A077ZKE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKE8"
  },
  {
    "uniprot": "A0A077YZ97",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ97"
  },
  {
    "uniprot": "A0A077Z198",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z198"
  },
  {
    "uniprot": "A0A077Z8C3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8C3"
  },
  {
    "uniprot": "A0A077ZBI5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBI5"
  },
  {
    "uniprot": "A0A077Z312",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z312"
  },
  {
    "uniprot": "A0A077ZJD8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJD8"
  },
  {
    "uniprot": "A0A077ZGR3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGR3"
  },
  {
    "uniprot": "A0A077ZE32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE32"
  },
  {
    "uniprot": "A0A077YXJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXJ5"
  },
  {
    "uniprot": "A0A077ZKX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKX7"
  },
  {
    "uniprot": "A0A077Z9Z3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Z3"
  },
  {
    "uniprot": "A0A077Z8Y7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Y7"
  },
  {
    "uniprot": "A0A077ZAK8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAK8"
  },
  {
    "uniprot": "A0A077ZHT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHT8"
  },
  {
    "uniprot": "A0A077ZGG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGG8"
  },
  {
    "uniprot": "A0A077Z2S4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2S4"
  },
  {
    "uniprot": "A0A077ZQU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQU2"
  },
  {
    "uniprot": "A0A077ZBZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBZ8"
  },
  {
    "uniprot": "A0A077ZCH4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCH4"
  },
  {
    "uniprot": "A0A077Z9Y2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Y2"
  },
  {
    "uniprot": "A0A077Z1U6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1U6"
  },
  {
    "uniprot": "A0A077ZR91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR91"
  },
  {
    "uniprot": "A0A077Z694",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z694"
  },
  {
    "uniprot": "A0A077Z8T3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8T3"
  },
  {
    "uniprot": "A0A077ZH46",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH46"
  },
  {
    "uniprot": "A0A077ZNW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNW0"
  },
  {
    "uniprot": "A0A077ZH02",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH02"
  },
  {
    "uniprot": "A0A077ZM43",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM43"
  },
  {
    "uniprot": "A0A077ZCF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCF9"
  },
  {
    "uniprot": "A0A077ZGD9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGD9"
  },
  {
    "uniprot": "A0A077ZK64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK64"
  },
  {
    "uniprot": "A0A077ZGY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGY4"
  },
  {
    "uniprot": "A0A077Z5Y0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Y0"
  },
  {
    "uniprot": "A0A077YWA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWA8"
  },
  {
    "uniprot": "A0A077ZLG0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLG0"
  },
  {
    "uniprot": "A0A077YYB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYB9"
  },
  {
    "uniprot": "A0A077ZBG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBG8"
  },
  {
    "uniprot": "A0A077ZNU3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNU3"
  },
  {
    "uniprot": "A0A077ZIP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIP2"
  },
  {
    "uniprot": "A0A077ZF27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF27"
  },
  {
    "uniprot": "A0A077YZY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZY9"
  },
  {
    "uniprot": "A0A077Z4W9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4W9"
  },
  {
    "uniprot": "A0A077ZK41",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK41"
  },
  {
    "uniprot": "A0A077ZBJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBJ0"
  },
  {
    "uniprot": "A0A077ZBY2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBY2"
  },
  {
    "uniprot": "A0A077Z534",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z534"
  },
  {
    "uniprot": "A0A077YVM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVM1"
  },
  {
    "uniprot": "A0A077Z2T3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2T3"
  },
  {
    "uniprot": "A0A077ZFC0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFC0"
  },
  {
    "uniprot": "A0A077ZCQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCQ8"
  },
  {
    "uniprot": "A0A077ZEU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEU2"
  },
  {
    "uniprot": "A0A077ZDK1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDK1"
  },
  {
    "uniprot": "A0A077YUJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUJ8"
  },
  {
    "uniprot": "A0A077Z1Y2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Y2"
  },
  {
    "uniprot": "A0A077Z0Z0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Z0"
  },
  {
    "uniprot": "A0A077ZGC5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGC5"
  },
  {
    "uniprot": "A0A077Z1M9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1M9"
  },
  {
    "uniprot": "A0A077ZH20",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH20"
  },
  {
    "uniprot": "A0A077Z4Q7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Q7"
  },
  {
    "uniprot": "A0A077ZMT3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMT3"
  },
  {
    "uniprot": "A0A077Z059",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z059"
  },
  {
    "uniprot": "A0A077ZKK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKK6"
  },
  {
    "uniprot": "A0A077Z743",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z743"
  },
  {
    "uniprot": "A0A077Z8Z2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Z2"
  },
  {
    "uniprot": "A0A077Z2I1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2I1"
  },
  {
    "uniprot": "A0A077YX14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX14"
  },
  {
    "uniprot": "A0A077Z5P1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5P1"
  },
  {
    "uniprot": "A0A077Z2D5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2D5"
  },
  {
    "uniprot": "A0A077ZL92",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL92"
  },
  {
    "uniprot": "A0A077ZF06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF06"
  },
  {
    "uniprot": "A0A077ZAF1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAF1"
  },
  {
    "uniprot": "A0A077ZF96",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF96"
  },
  {
    "uniprot": "A0A077ZG47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG47"
  },
  {
    "uniprot": "A0A077ZLN0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLN0"
  },
  {
    "uniprot": "A0A077ZBC1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBC1"
  },
  {
    "uniprot": "A0A077ZQG4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQG4"
  },
  {
    "uniprot": "A0A077YZN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZN1"
  },
  {
    "uniprot": "A0A077YXN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXN2"
  },
  {
    "uniprot": "A0A077ZIQ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIQ6"
  },
  {
    "uniprot": "A0A077Z4Z7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Z7"
  },
  {
    "uniprot": "A0A077ZFM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFM8"
  },
  {
    "uniprot": "A0A077ZK32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK32"
  },
  {
    "uniprot": "A0A077Z726",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z726"
  },
  {
    "uniprot": "A0A077Z359",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z359"
  },
  {
    "uniprot": "A0A077Z272",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z272"
  },
  {
    "uniprot": "A0A077ZNS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNS9"
  },
  {
    "uniprot": "A0A077ZC36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC36"
  },
  {
    "uniprot": "A0A077Z2B3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2B3"
  },
  {
    "uniprot": "A0A077Z1R6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1R6"
  },
  {
    "uniprot": "A0A077Z2D6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2D6"
  },
  {
    "uniprot": "A0A077YX00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX00"
  },
  {
    "uniprot": "A0A077Z7A8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7A8"
  },
  {
    "uniprot": "A0A077ZP16",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP16"
  },
  {
    "uniprot": "A0A077Z905",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z905"
  },
  {
    "uniprot": "A0A077YW13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW13"
  },
  {
    "uniprot": "A0A077Z6S5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6S5"
  },
  {
    "uniprot": "A0A077ZCJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCJ8"
  },
  {
    "uniprot": "A0A077YVU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVU5"
  },
  {
    "uniprot": "A0A077Z171",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z171"
  },
  {
    "uniprot": "A0A077Z7D1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7D1"
  },
  {
    "uniprot": "A0A077ZIV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIV1"
  },
  {
    "uniprot": "A0A077ZL32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL32"
  },
  {
    "uniprot": "A0A077ZH13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH13"
  },
  {
    "uniprot": "A0A077Z9K0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9K0"
  },
  {
    "uniprot": "A0A077ZHX2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHX2"
  },
  {
    "uniprot": "A0A077ZDJ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDJ6"
  },
  {
    "uniprot": "A0A077Z7U3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7U3"
  },
  {
    "uniprot": "A0A077YWG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWG9"
  },
  {
    "uniprot": "A0A077Z4I1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4I1"
  },
  {
    "uniprot": "A0A077Z8E7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8E7"
  },
  {
    "uniprot": "A0A077ZJP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJP9"
  },
  {
    "uniprot": "A0A077ZL22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL22"
  },
  {
    "uniprot": "A0A077ZLT7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLT7"
  },
  {
    "uniprot": "A0A077Z2R9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2R9"
  },
  {
    "uniprot": "A0A077ZD53",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD53"
  },
  {
    "uniprot": "A0A077YVZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVZ5"
  },
  {
    "uniprot": "A0A077Z773",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z773"
  },
  {
    "uniprot": "A0A077Z756",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z756"
  },
  {
    "uniprot": "A0A077YYB4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYB4"
  },
  {
    "uniprot": "A0A077Z5M6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5M6"
  },
  {
    "uniprot": "A0A077ZEC0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEC0"
  },
  {
    "uniprot": "A0A077Z9K5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9K5"
  },
  {
    "uniprot": "A0A077ZEC5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEC5"
  },
  {
    "uniprot": "A0A077ZDN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDN9"
  },
  {
    "uniprot": "A0A077YXS5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXS5"
  },
  {
    "uniprot": "A0A077Z789",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z789"
  },
  {
    "uniprot": "A0A077Z645",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z645"
  },
  {
    "uniprot": "A0A077ZGF5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGF5"
  },
  {
    "uniprot": "A0A077ZQY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQY0"
  },
  {
    "uniprot": "A0A077Z6B7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6B7"
  },
  {
    "uniprot": "A0A077Z4R0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4R0"
  },
  {
    "uniprot": "A0A077YXT7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXT7"
  },
  {
    "uniprot": "A0A077ZJD9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJD9"
  },
  {
    "uniprot": "A0A077YXJ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXJ9"
  },
  {
    "uniprot": "A0A077ZM96",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM96"
  },
  {
    "uniprot": "A0A077Z0G8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0G8"
  },
  {
    "uniprot": "A0A077Z391",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z391"
  },
  {
    "uniprot": "A0A077ZCX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCX3"
  },
  {
    "uniprot": "A0A077ZA66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA66"
  },
  {
    "uniprot": "A0A077YYS7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYS7"
  },
  {
    "uniprot": "A0A077Z3X6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3X6"
  },
  {
    "uniprot": "A0A077Z9A2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9A2"
  },
  {
    "uniprot": "A0A077YZ24",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ24"
  },
  {
    "uniprot": "A0A077ZM75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM75"
  },
  {
    "uniprot": "A0A077Z6W0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6W0"
  },
  {
    "uniprot": "A0A077Z9F8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9F8"
  },
  {
    "uniprot": "A0A077ZNQ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNQ9"
  },
  {
    "uniprot": "A0A077ZI94",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI94"
  },
  {
    "uniprot": "A0A077ZD13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD13"
  },
  {
    "uniprot": "A0A077Z740",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z740"
  },
  {
    "uniprot": "A0A077ZEI2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEI2"
  },
  {
    "uniprot": "A0A077ZGE6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGE6"
  },
  {
    "uniprot": "A0A077Z4B6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4B6"
  },
  {
    "uniprot": "A0A077Z0Z6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Z6"
  },
  {
    "uniprot": "A0A077Z920",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z920"
  },
  {
    "uniprot": "A0A077Z8E9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8E9"
  },
  {
    "uniprot": "A0A077ZHB4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHB4"
  },
  {
    "uniprot": "A0A077Z8Z9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Z9"
  },
  {
    "uniprot": "A0A077ZFV7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFV7"
  },
  {
    "uniprot": "A0A077ZBA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBA5"
  },
  {
    "uniprot": "A0A077YWF8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWF8"
  },
  {
    "uniprot": "A0A077ZDC9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDC9"
  },
  {
    "uniprot": "A0A077ZFJ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFJ9"
  },
  {
    "uniprot": "A0A077ZQ49",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ49"
  },
  {
    "uniprot": "A0A077ZFP1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFP1"
  },
  {
    "uniprot": "A0A077Z2G1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2G1"
  },
  {
    "uniprot": "A0A077YYC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYC3"
  },
  {
    "uniprot": "A0A077Z5M8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5M8"
  },
  {
    "uniprot": "A0A077ZIR8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIR8"
  },
  {
    "uniprot": "A0A077ZFR9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFR9"
  },
  {
    "uniprot": "A0A077Z2G0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2G0"
  },
  {
    "uniprot": "A0A077ZBZ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBZ2"
  },
  {
    "uniprot": "A0A077ZM02",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM02"
  },
  {
    "uniprot": "A0A077YWJ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWJ9"
  },
  {
    "uniprot": "A0A077Z0M7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0M7"
  },
  {
    "uniprot": "A0A077ZA07",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA07"
  },
  {
    "uniprot": "A0A077ZKF7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKF7"
  },
  {
    "uniprot": "A0A077ZME3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZME3"
  },
  {
    "uniprot": "A0A077ZIV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIV4"
  },
  {
    "uniprot": "A0A077Z8S7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8S7"
  },
  {
    "uniprot": "A0A077YX30",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX30"
  },
  {
    "uniprot": "A0A077ZP00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP00"
  },
  {
    "uniprot": "A0A077ZF66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF66"
  },
  {
    "uniprot": "A0A077YZQ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZQ7"
  },
  {
    "uniprot": "A0A077ZCG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCG5"
  },
  {
    "uniprot": "A0A077Z587",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z587"
  },
  {
    "uniprot": "A0A077ZGB7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGB7"
  },
  {
    "uniprot": "A0A077YZJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZJ8"
  },
  {
    "uniprot": "A0A077Z6P6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6P6"
  },
  {
    "uniprot": "A0A077ZAZ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAZ0"
  },
  {
    "uniprot": "A0A077ZIY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIY3"
  },
  {
    "uniprot": "A0A077Z350",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z350"
  },
  {
    "uniprot": "A0A077ZII8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZII8"
  },
  {
    "uniprot": "A0A077Z487",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z487"
  },
  {
    "uniprot": "A0A077ZID8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZID8"
  },
  {
    "uniprot": "A0A077ZIB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIB9"
  },
  {
    "uniprot": "A0A077ZDK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDK3"
  },
  {
    "uniprot": "A0A077Z848",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z848"
  },
  {
    "uniprot": "A0A077Z4W5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4W5"
  },
  {
    "uniprot": "A0A077YWH6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWH6"
  },
  {
    "uniprot": "A0A077ZAW3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAW3"
  },
  {
    "uniprot": "A0A077Z7W1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7W1"
  },
  {
    "uniprot": "A0A077YY73",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY73"
  },
  {
    "uniprot": "A0A077YZI6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZI6"
  },
  {
    "uniprot": "A0A077ZL37",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL37"
  },
  {
    "uniprot": "A0A077ZL72",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL72"
  },
  {
    "uniprot": "A0A077ZGT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGT8"
  },
  {
    "uniprot": "A0A077Z2G7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2G7"
  },
  {
    "uniprot": "A0A077ZEZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEZ8"
  },
  {
    "uniprot": "A0A077Z572",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z572"
  },
  {
    "uniprot": "A0A077ZNI5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNI5"
  },
  {
    "uniprot": "A0A077ZH97",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH97"
  },
  {
    "uniprot": "A0A077ZQ78",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ78"
  },
  {
    "uniprot": "A0A077ZPQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPQ3"
  },
  {
    "uniprot": "A0A077ZL82",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL82"
  },
  {
    "uniprot": "A0A077Z1R0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1R0"
  },
  {
    "uniprot": "A0A077ZEX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEX0"
  },
  {
    "uniprot": "A0A077YZI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZI8"
  },
  {
    "uniprot": "A0A077Z6J9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6J9"
  },
  {
    "uniprot": "A0A077ZFU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFU7"
  },
  {
    "uniprot": "A0A077ZFR0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFR0"
  },
  {
    "uniprot": "A0A077YZV7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZV7"
  },
  {
    "uniprot": "A0A077ZFP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFP2"
  },
  {
    "uniprot": "A0A077ZBU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBU6"
  },
  {
    "uniprot": "A0A077ZFW6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFW6"
  },
  {
    "uniprot": "A0A077Z9V0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9V0"
  },
  {
    "uniprot": "A0A077ZJM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJM1"
  },
  {
    "uniprot": "A0A077ZMC9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMC9"
  },
  {
    "uniprot": "A0A077Z6F0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6F0"
  },
  {
    "uniprot": "A0A077ZLC8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLC8"
  },
  {
    "uniprot": "A0A077ZL52",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL52"
  },
  {
    "uniprot": "A0A077Z8K9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8K9"
  },
  {
    "uniprot": "A0A077ZG00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG00"
  },
  {
    "uniprot": "A0A077Z2T5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2T5"
  },
  {
    "uniprot": "A0A077Z4N3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4N3"
  },
  {
    "uniprot": "A0A077Z4H0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4H0"
  },
  {
    "uniprot": "A0A077YZW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZW9"
  },
  {
    "uniprot": "A0A077ZBP6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBP6"
  },
  {
    "uniprot": "A0A077ZLU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLU9"
  },
  {
    "uniprot": "A0A077Z4M2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4M2"
  },
  {
    "uniprot": "A0A077Z6A8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6A8"
  },
  {
    "uniprot": "A0A077Z554",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z554"
  },
  {
    "uniprot": "A0A077ZDE4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDE4"
  },
  {
    "uniprot": "A0A077YWK2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWK2"
  },
  {
    "uniprot": "A0A077YXZ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXZ0"
  },
  {
    "uniprot": "A0A077ZCB1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCB1"
  },
  {
    "uniprot": "A0A077ZMA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMA8"
  },
  {
    "uniprot": "A0A077ZAQ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAQ1"
  },
  {
    "uniprot": "A0A077ZAE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAE8"
  },
  {
    "uniprot": "A0A077Z403",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z403"
  },
  {
    "uniprot": "A0A077Z7W0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7W0"
  },
  {
    "uniprot": "A0A077Z0R4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0R4"
  },
  {
    "uniprot": "A0A077YXR1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXR1"
  },
  {
    "uniprot": "A0A077ZNC5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNC5"
  },
  {
    "uniprot": "A0A077ZEP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEP3"
  },
  {
    "uniprot": "A0A077Z5R1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5R1"
  },
  {
    "uniprot": "A0A077Z666",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z666"
  },
  {
    "uniprot": "A0A077YYX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYX7"
  },
  {
    "uniprot": "A0A077YWP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWP5"
  },
  {
    "uniprot": "A0A077Z3X4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3X4"
  },
  {
    "uniprot": "A0A077YW95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW95"
  },
  {
    "uniprot": "A0A077ZBN0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBN0"
  },
  {
    "uniprot": "A0A077ZD35",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD35"
  },
  {
    "uniprot": "A0A077ZCS5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCS5"
  },
  {
    "uniprot": "A0A077ZGJ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGJ1"
  },
  {
    "uniprot": "A0A077YUY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUY0"
  },
  {
    "uniprot": "A0A077ZBP7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBP7"
  },
  {
    "uniprot": "A0A077ZH78",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH78"
  },
  {
    "uniprot": "A0A077ZLQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLQ5"
  },
  {
    "uniprot": "A0A077ZM05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM05"
  },
  {
    "uniprot": "A0A077ZI56",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI56"
  },
  {
    "uniprot": "A0A077Z538",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z538"
  },
  {
    "uniprot": "A0A077ZIK0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIK0"
  },
  {
    "uniprot": "A0A077Z865",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z865"
  },
  {
    "uniprot": "A0A077Z9F0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9F0"
  },
  {
    "uniprot": "A0A077ZD95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD95"
  },
  {
    "uniprot": "A0A077Z6D4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6D4"
  },
  {
    "uniprot": "A0A077ZDP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDP5"
  },
  {
    "uniprot": "A0A077ZH47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH47"
  },
  {
    "uniprot": "A0A077Z4Z1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Z1"
  },
  {
    "uniprot": "A0A077ZLS5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLS5"
  },
  {
    "uniprot": "A0A077YUV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUV1"
  },
  {
    "uniprot": "A0A077ZDK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDK9"
  },
  {
    "uniprot": "A0A077ZNH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNH1"
  },
  {
    "uniprot": "A0A077ZLC6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLC6"
  },
  {
    "uniprot": "A0A077ZKK5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKK5"
  },
  {
    "uniprot": "A0A077ZBD5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBD5"
  },
  {
    "uniprot": "A0A077ZJF6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJF6"
  },
  {
    "uniprot": "A0A077Z2N5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2N5"
  },
  {
    "uniprot": "A0A077Z0F3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0F3"
  },
  {
    "uniprot": "A0A077ZFS3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFS3"
  },
  {
    "uniprot": "A0A077Z6Z0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Z0"
  },
  {
    "uniprot": "A0A077ZAW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAW9"
  },
  {
    "uniprot": "A0A077ZJ49",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ49"
  },
  {
    "uniprot": "A0A077ZAI4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAI4"
  },
  {
    "uniprot": "A0A077YY27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY27"
  },
  {
    "uniprot": "A0A077ZL57",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL57"
  },
  {
    "uniprot": "A0A077ZKA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKA5"
  },
  {
    "uniprot": "A0A077ZA49",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA49"
  },
  {
    "uniprot": "A0A077ZDQ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDQ7"
  },
  {
    "uniprot": "A0A077ZA35",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA35"
  },
  {
    "uniprot": "A0A077YWU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWU1"
  },
  {
    "uniprot": "A0A077ZDH6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDH6"
  },
  {
    "uniprot": "A0A077YYP1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYP1"
  },
  {
    "uniprot": "A0A077Z8Q7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Q7"
  },
  {
    "uniprot": "A0A077Z1D9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1D9"
  },
  {
    "uniprot": "A0A077Z5H6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5H6"
  },
  {
    "uniprot": "A0A077Z7Z4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Z4"
  },
  {
    "uniprot": "A0A077Z1U9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1U9"
  },
  {
    "uniprot": "A0A077YYY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYY3"
  },
  {
    "uniprot": "A0A077ZEK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEK4"
  },
  {
    "uniprot": "A0A077ZR69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR69"
  },
  {
    "uniprot": "A0A077Z6V6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6V6"
  },
  {
    "uniprot": "A0A077Z2N8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2N8"
  },
  {
    "uniprot": "A0A077Z1E9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1E9"
  },
  {
    "uniprot": "A0A077ZPS5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPS5"
  },
  {
    "uniprot": "A0A077ZKQ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKQ7"
  },
  {
    "uniprot": "A0A077YYJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYJ3"
  },
  {
    "uniprot": "A0A077ZAA0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAA0"
  },
  {
    "uniprot": "A0A077ZI60",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI60"
  },
  {
    "uniprot": "A0A077ZD34",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD34"
  },
  {
    "uniprot": "A0A077YXR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXR6"
  },
  {
    "uniprot": "A0A077ZFI0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFI0"
  },
  {
    "uniprot": "A0A077Z4C4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4C4"
  },
  {
    "uniprot": "A0A077Z317",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z317"
  },
  {
    "uniprot": "A0A077ZA19",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA19"
  },
  {
    "uniprot": "A0A077ZJ73",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ73"
  },
  {
    "uniprot": "A0A077Z1N4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1N4"
  },
  {
    "uniprot": "A0A077Z939",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z939"
  },
  {
    "uniprot": "A0A077YYB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYB0"
  },
  {
    "uniprot": "A0A077ZA94",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA94"
  },
  {
    "uniprot": "A0A077ZGJ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGJ6"
  },
  {
    "uniprot": "A0A077ZFL0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFL0"
  },
  {
    "uniprot": "A0A077Z2Q1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Q1"
  },
  {
    "uniprot": "A0A077ZE33",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE33"
  },
  {
    "uniprot": "A0A077Z4R3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4R3"
  },
  {
    "uniprot": "A0A077YZW3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZW3"
  },
  {
    "uniprot": "A0A077ZFT7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFT7"
  },
  {
    "uniprot": "A0A077ZAF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAF4"
  },
  {
    "uniprot": "A0A077YWW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWW7"
  },
  {
    "uniprot": "A0A077ZKS6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKS6"
  },
  {
    "uniprot": "A0A077ZLQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLQ3"
  },
  {
    "uniprot": "A0A077Z158",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z158"
  },
  {
    "uniprot": "A0A077ZCN0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCN0"
  },
  {
    "uniprot": "A0A077ZFI9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFI9"
  },
  {
    "uniprot": "A0A077YXC4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXC4"
  },
  {
    "uniprot": "A0A077Z4N0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4N0"
  },
  {
    "uniprot": "A0A077ZJE1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJE1"
  },
  {
    "uniprot": "A0A077Z1F8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1F8"
  },
  {
    "uniprot": "A0A077YZ46",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ46"
  },
  {
    "uniprot": "A0A077YXJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXJ2"
  },
  {
    "uniprot": "A0A077ZQB1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQB1"
  },
  {
    "uniprot": "A0A077Z5J5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5J5"
  },
  {
    "uniprot": "A0A077Z0N4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0N4"
  },
  {
    "uniprot": "A0A077YZQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZQ4"
  },
  {
    "uniprot": "A0A077ZGQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGQ4"
  },
  {
    "uniprot": "A0A077YW51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW51"
  },
  {
    "uniprot": "A0A077ZAN0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAN0"
  },
  {
    "uniprot": "A0A077ZDE6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDE6"
  },
  {
    "uniprot": "A0A077ZMM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMM8"
  },
  {
    "uniprot": "A0A077Z5M3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5M3"
  },
  {
    "uniprot": "A0A077ZMM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMM6"
  },
  {
    "uniprot": "A0A077ZDW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDW7"
  },
  {
    "uniprot": "A0A077ZEL2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEL2"
  },
  {
    "uniprot": "A0A077Z7R6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7R6"
  },
  {
    "uniprot": "A0A077ZB49",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB49"
  },
  {
    "uniprot": "A0A077ZBV2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBV2"
  },
  {
    "uniprot": "A0A077ZMV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMV6"
  },
  {
    "uniprot": "A0A077Z421",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z421"
  },
  {
    "uniprot": "A0A077ZFL5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFL5"
  },
  {
    "uniprot": "A0A077ZBE0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBE0"
  },
  {
    "uniprot": "A0A077ZIB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIB0"
  },
  {
    "uniprot": "A0A077Z9P1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9P1"
  },
  {
    "uniprot": "A0A077ZFF2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFF2"
  },
  {
    "uniprot": "A0A077ZFJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFJ8"
  },
  {
    "uniprot": "A0A077ZDS3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDS3"
  },
  {
    "uniprot": "A0A077ZGA6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGA6"
  },
  {
    "uniprot": "A0A077ZEX5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEX5"
  },
  {
    "uniprot": "A0A077YW04",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW04"
  },
  {
    "uniprot": "A0A077Z5G7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5G7"
  },
  {
    "uniprot": "A0A077YXJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXJ7"
  },
  {
    "uniprot": "A0A077ZDD6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDD6"
  },
  {
    "uniprot": "A0A077YZC5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZC5"
  },
  {
    "uniprot": "A0A077YX59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX59"
  },
  {
    "uniprot": "A0A077YXG6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXG6"
  },
  {
    "uniprot": "A0A077YY07",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY07"
  },
  {
    "uniprot": "A0A077YZW6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZW6"
  },
  {
    "uniprot": "A0A077Z8X1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8X1"
  },
  {
    "uniprot": "A0A077Z497",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z497"
  },
  {
    "uniprot": "A0A077Z5R0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5R0"
  },
  {
    "uniprot": "A0A077YZB3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZB3"
  },
  {
    "uniprot": "A0A077Z265",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z265"
  },
  {
    "uniprot": "A0A077YW61",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW61"
  },
  {
    "uniprot": "A0A077ZFR4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFR4"
  },
  {
    "uniprot": "A0A077YZ33",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ33"
  },
  {
    "uniprot": "A0A077ZKZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKZ9"
  },
  {
    "uniprot": "A0A077ZLX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLX3"
  },
  {
    "uniprot": "A0A077ZAA9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAA9"
  },
  {
    "uniprot": "A0A077ZFQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFQ2"
  },
  {
    "uniprot": "A0A077ZNE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNE8"
  },
  {
    "uniprot": "A0A077Z246",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z246"
  },
  {
    "uniprot": "A0A077ZJR4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJR4"
  },
  {
    "uniprot": "A0A077Z355",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z355"
  },
  {
    "uniprot": "A0A077ZC33",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC33"
  },
  {
    "uniprot": "A0A077ZE34",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE34"
  },
  {
    "uniprot": "A0A077YX41",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX41"
  },
  {
    "uniprot": "A0A077ZBX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBX3"
  },
  {
    "uniprot": "A0A077ZIV0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIV0"
  },
  {
    "uniprot": "A0A077YYG1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYG1"
  },
  {
    "uniprot": "A0A077Z206",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z206"
  },
  {
    "uniprot": "A0A077YVS5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVS5"
  },
  {
    "uniprot": "A0A077ZQA7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQA7"
  },
  {
    "uniprot": "A0A077ZDJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDJ0"
  },
  {
    "uniprot": "A0A077Z1X9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1X9"
  },
  {
    "uniprot": "A0A077ZCN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCN3"
  },
  {
    "uniprot": "A0A077ZEL0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEL0"
  },
  {
    "uniprot": "A0A077YX68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX68"
  },
  {
    "uniprot": "A0A077Z8F3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8F3"
  },
  {
    "uniprot": "A0A077ZG46",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG46"
  },
  {
    "uniprot": "A0A077ZHD4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHD4"
  },
  {
    "uniprot": "A0A077ZA31",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA31"
  },
  {
    "uniprot": "A0A077Z0Q9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Q9"
  },
  {
    "uniprot": "A0A077ZL56",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL56"
  },
  {
    "uniprot": "A0A077ZPG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPG5"
  },
  {
    "uniprot": "A0A077Z2J1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2J1"
  },
  {
    "uniprot": "A0A077YW00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW00"
  },
  {
    "uniprot": "A0A077YYE3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYE3"
  },
  {
    "uniprot": "A0A077YYJ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYJ1"
  },
  {
    "uniprot": "A0A077ZH19",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH19"
  },
  {
    "uniprot": "A0A077Z4S8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4S8"
  },
  {
    "uniprot": "A0A077ZJP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJP2"
  },
  {
    "uniprot": "A0A077Z047",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z047"
  },
  {
    "uniprot": "A0A077ZFH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFH8"
  },
  {
    "uniprot": "A0A077ZL69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL69"
  },
  {
    "uniprot": "A0A077ZIX2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIX2"
  },
  {
    "uniprot": "A0A077ZHD8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHD8"
  },
  {
    "uniprot": "A0A077Z089",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z089"
  },
  {
    "uniprot": "A0A077ZLL5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLL5"
  },
  {
    "uniprot": "A0A077YZL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZL6"
  },
  {
    "uniprot": "A0A077ZLI5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLI5"
  },
  {
    "uniprot": "A0A077YXP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXP2"
  },
  {
    "uniprot": "A0A077ZBN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBN9"
  },
  {
    "uniprot": "A0A077ZHH4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHH4"
  },
  {
    "uniprot": "A0A077ZBI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBI3"
  },
  {
    "uniprot": "A0A077Z5D4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5D4"
  },
  {
    "uniprot": "A0A077Z928",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z928"
  },
  {
    "uniprot": "A0A077Z2X0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2X0"
  },
  {
    "uniprot": "A0A077Z870",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z870"
  },
  {
    "uniprot": "A0A077ZQ60",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ60"
  },
  {
    "uniprot": "A0A077Z305",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z305"
  },
  {
    "uniprot": "A0A077ZLX1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLX1"
  },
  {
    "uniprot": "A0A077YYM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYM5"
  },
  {
    "uniprot": "A0A077Z4V4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4V4"
  },
  {
    "uniprot": "A0A077Z882",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z882"
  },
  {
    "uniprot": "A0A077Z992",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z992"
  },
  {
    "uniprot": "A0A077Z7S2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7S2"
  },
  {
    "uniprot": "A0A077Z1J7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1J7"
  },
  {
    "uniprot": "A0A077YZW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZW8"
  },
  {
    "uniprot": "A0A077YXX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXX0"
  },
  {
    "uniprot": "A0A077Z7E9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7E9"
  },
  {
    "uniprot": "A0A077Z919",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z919"
  },
  {
    "uniprot": "A0A077Z9I6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9I6"
  },
  {
    "uniprot": "A0A077Z650",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z650"
  },
  {
    "uniprot": "A0A077ZB27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB27"
  },
  {
    "uniprot": "A0A077ZHF5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHF5"
  },
  {
    "uniprot": "A0A077ZK62",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK62"
  },
  {
    "uniprot": "A0A077Z7H7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7H7"
  },
  {
    "uniprot": "A0A077Z6T3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6T3"
  },
  {
    "uniprot": "A0A077Z239",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z239"
  },
  {
    "uniprot": "A0A077Z4P7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4P7"
  },
  {
    "uniprot": "A0A077Z6H0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6H0"
  },
  {
    "uniprot": "A0A077Z5W9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5W9"
  },
  {
    "uniprot": "A0A077ZK00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK00"
  },
  {
    "uniprot": "A0A077Z2D7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2D7"
  },
  {
    "uniprot": "A0A077ZQC4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQC4"
  },
  {
    "uniprot": "A0A077ZLS7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLS7"
  },
  {
    "uniprot": "A0A077Z3L7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3L7"
  },
  {
    "uniprot": "A0A077Z5M7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5M7"
  },
  {
    "uniprot": "A0A077ZKX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKX9"
  },
  {
    "uniprot": "A0A077ZLJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLJ8"
  },
  {
    "uniprot": "A0A077ZIU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIU2"
  },
  {
    "uniprot": "A0A077ZCU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCU4"
  },
  {
    "uniprot": "A0A077ZD62",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD62"
  },
  {
    "uniprot": "A0A077Z7Z2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Z2"
  },
  {
    "uniprot": "A0A077Z7L6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7L6"
  },
  {
    "uniprot": "A0A077Z2A7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2A7"
  },
  {
    "uniprot": "A0A077YX26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX26"
  },
  {
    "uniprot": "A0A077YZ39",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ39"
  },
  {
    "uniprot": "A0A077Z0M1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0M1"
  },
  {
    "uniprot": "A0A077ZKU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKU6"
  },
  {
    "uniprot": "A0A077ZBG1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBG1"
  },
  {
    "uniprot": "A0A077Z558",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z558"
  },
  {
    "uniprot": "A0A077Z9U7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9U7"
  },
  {
    "uniprot": "A0A077YX17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX17"
  },
  {
    "uniprot": "A0A077Z9K6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9K6"
  },
  {
    "uniprot": "A0A077Z5K8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5K8"
  },
  {
    "uniprot": "A0A077YX23",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX23"
  },
  {
    "uniprot": "A0A077Z684",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z684"
  },
  {
    "uniprot": "A0A077ZLK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLK9"
  },
  {
    "uniprot": "A0A077Z3T1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3T1"
  },
  {
    "uniprot": "A0A077ZD73",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD73"
  },
  {
    "uniprot": "A0A077ZIT6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIT6"
  },
  {
    "uniprot": "A0A077YZG4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZG4"
  },
  {
    "uniprot": "A0A077ZBT6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBT6"
  },
  {
    "uniprot": "A0A077ZA04",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA04"
  },
  {
    "uniprot": "A0A077ZND3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZND3"
  },
  {
    "uniprot": "A0A077YXL1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXL1"
  },
  {
    "uniprot": "A0A077ZA90",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA90"
  },
  {
    "uniprot": "A0A077ZHY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHY4"
  },
  {
    "uniprot": "A0A077ZCZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCZ8"
  },
  {
    "uniprot": "A0A077ZNE6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNE6"
  },
  {
    "uniprot": "A0A077Z346",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z346"
  },
  {
    "uniprot": "A0A077Z2D3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2D3"
  },
  {
    "uniprot": "A0A077Z751",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z751"
  },
  {
    "uniprot": "A0A077Z1T3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1T3"
  },
  {
    "uniprot": "A0A077ZDM3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDM3"
  },
  {
    "uniprot": "A0A077YWE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWE8"
  },
  {
    "uniprot": "A0A077Z1Q3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Q3"
  },
  {
    "uniprot": "A0A077ZL17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL17"
  },
  {
    "uniprot": "A0A077Z598",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z598"
  },
  {
    "uniprot": "A0A077ZL25",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL25"
  },
  {
    "uniprot": "A0A077Z6Y8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Y8"
  },
  {
    "uniprot": "A0A077ZHW4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHW4"
  },
  {
    "uniprot": "A0A077YZF6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZF6"
  },
  {
    "uniprot": "A0A077YX28",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX28"
  },
  {
    "uniprot": "A0A077Z419",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z419"
  },
  {
    "uniprot": "A0A077ZJ79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ79"
  },
  {
    "uniprot": "A0A077ZI89",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI89"
  },
  {
    "uniprot": "A0A077ZLE7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLE7"
  },
  {
    "uniprot": "A0A077YYT0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYT0"
  },
  {
    "uniprot": "A0A077Z127",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z127"
  },
  {
    "uniprot": "A0A077ZMH6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMH6"
  },
  {
    "uniprot": "A0A077Z3D1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3D1"
  },
  {
    "uniprot": "A0A077Z4D5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4D5"
  },
  {
    "uniprot": "A0A077Z7T8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7T8"
  },
  {
    "uniprot": "A0A077YX51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX51"
  },
  {
    "uniprot": "A0A077ZFG2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFG2"
  },
  {
    "uniprot": "A0A077ZE45",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE45"
  },
  {
    "uniprot": "A0A077YWU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWU4"
  },
  {
    "uniprot": "A0A077Z9I1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9I1"
  },
  {
    "uniprot": "A0A077Z1I8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1I8"
  },
  {
    "uniprot": "A0A077Z6Y9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Y9"
  },
  {
    "uniprot": "A0A077ZGY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGY9"
  },
  {
    "uniprot": "A0A077ZJ51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ51"
  },
  {
    "uniprot": "A0A077ZDT6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDT6"
  },
  {
    "uniprot": "A0A077ZF37",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF37"
  },
  {
    "uniprot": "A0A077ZDX1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDX1"
  },
  {
    "uniprot": "A0A077Z440",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z440"
  },
  {
    "uniprot": "A0A077ZG51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG51"
  },
  {
    "uniprot": "A0A077ZDH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDH5"
  },
  {
    "uniprot": "A0A077ZES6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZES6"
  },
  {
    "uniprot": "A0A077ZLG1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLG1"
  },
  {
    "uniprot": "A0A077Z370",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z370"
  },
  {
    "uniprot": "A0A077ZMC5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMC5"
  },
  {
    "uniprot": "A0A077YZ75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ75"
  },
  {
    "uniprot": "A0A077Z8B1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8B1"
  },
  {
    "uniprot": "A0A077Z6U3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6U3"
  },
  {
    "uniprot": "A0A077ZDK0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDK0"
  },
  {
    "uniprot": "A0A077ZLD5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLD5"
  },
  {
    "uniprot": "A0A077Z037",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z037"
  },
  {
    "uniprot": "A0A077ZMN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMN9"
  },
  {
    "uniprot": "A0A077ZAG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAG5"
  },
  {
    "uniprot": "A0A077YZT1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZT1"
  },
  {
    "uniprot": "A0A077Z8X9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8X9"
  },
  {
    "uniprot": "A0A077ZD20",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD20"
  },
  {
    "uniprot": "A0A077ZEQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEQ5"
  },
  {
    "uniprot": "A0A077Z8U5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8U5"
  },
  {
    "uniprot": "A0A077ZIV8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIV8"
  },
  {
    "uniprot": "A0A077YWM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWM2"
  },
  {
    "uniprot": "A0A077YZQ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZQ9"
  },
  {
    "uniprot": "A0A077YXE0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXE0"
  },
  {
    "uniprot": "A0A077ZC90",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC90"
  },
  {
    "uniprot": "A0A077Z826",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z826"
  },
  {
    "uniprot": "A0A077ZI90",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI90"
  },
  {
    "uniprot": "A0A077YZV0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZV0"
  },
  {
    "uniprot": "A0A077Z508",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z508"
  },
  {
    "uniprot": "A0A077Z734",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z734"
  },
  {
    "uniprot": "A0A077ZFB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFB9"
  },
  {
    "uniprot": "A0A077Z4W6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4W6"
  },
  {
    "uniprot": "A0A077Z4J6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4J6"
  },
  {
    "uniprot": "A0A077ZBL8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBL8"
  },
  {
    "uniprot": "A0A077YXN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXN9"
  },
  {
    "uniprot": "A0A077ZIQ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIQ7"
  },
  {
    "uniprot": "A0A077ZJV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJV1"
  },
  {
    "uniprot": "A0A077YX76",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX76"
  },
  {
    "uniprot": "A0A077ZBY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBY6"
  },
  {
    "uniprot": "A0A077ZGV2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGV2"
  },
  {
    "uniprot": "A0A077ZC92",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC92"
  },
  {
    "uniprot": "A0A077ZBF2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBF2"
  },
  {
    "uniprot": "A0A077Z0X0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0X0"
  },
  {
    "uniprot": "A0A077Z181",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z181"
  },
  {
    "uniprot": "A0A077ZGU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGU1"
  },
  {
    "uniprot": "A0A077Z1P1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1P1"
  },
  {
    "uniprot": "A0A077ZEC4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEC4"
  },
  {
    "uniprot": "A0A077ZNN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNN1"
  },
  {
    "uniprot": "A0A077YY05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY05"
  },
  {
    "uniprot": "A0A077ZH73",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH73"
  },
  {
    "uniprot": "A0A077YZF8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZF8"
  },
  {
    "uniprot": "A0A077ZGG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGG9"
  },
  {
    "uniprot": "A0A077Z436",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z436"
  },
  {
    "uniprot": "A0A077ZM03",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM03"
  },
  {
    "uniprot": "A0A077ZGZ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGZ7"
  },
  {
    "uniprot": "A0A077Z4Q8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Q8"
  },
  {
    "uniprot": "A0A077ZC89",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC89"
  },
  {
    "uniprot": "A0A077ZJV2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJV2"
  },
  {
    "uniprot": "A0A077Z7P7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7P7"
  },
  {
    "uniprot": "A0A077ZK66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK66"
  },
  {
    "uniprot": "A0A077ZJC5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJC5"
  },
  {
    "uniprot": "A0A077Z314",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z314"
  },
  {
    "uniprot": "A0A077Z5Y3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Y3"
  },
  {
    "uniprot": "A0A077ZND4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZND4"
  },
  {
    "uniprot": "A0A077ZBC8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBC8"
  },
  {
    "uniprot": "A0A077ZBA9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBA9"
  },
  {
    "uniprot": "A0A077YXB1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXB1"
  },
  {
    "uniprot": "A0A077ZLD7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLD7"
  },
  {
    "uniprot": "A0A077YWA0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWA0"
  },
  {
    "uniprot": "A0A077ZBZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBZ4"
  },
  {
    "uniprot": "A0A077ZCD7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCD7"
  },
  {
    "uniprot": "A0A077ZF49",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF49"
  },
  {
    "uniprot": "A0A077ZEQ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEQ1"
  },
  {
    "uniprot": "A0A077Z5Q6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Q6"
  },
  {
    "uniprot": "A0A077Z5Q9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Q9"
  },
  {
    "uniprot": "A0A077YXB8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXB8"
  },
  {
    "uniprot": "A0A077YWY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWY4"
  },
  {
    "uniprot": "A0A077ZC86",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC86"
  },
  {
    "uniprot": "A0A077ZA99",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA99"
  },
  {
    "uniprot": "A0A077ZKI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKI8"
  },
  {
    "uniprot": "A0A077ZIY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIY9"
  },
  {
    "uniprot": "A0A077ZKK0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKK0"
  },
  {
    "uniprot": "A0A077ZAP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAP0"
  },
  {
    "uniprot": "A0A077ZAX1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAX1"
  },
  {
    "uniprot": "A0A077YX05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX05"
  },
  {
    "uniprot": "A0A077Z7G6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7G6"
  },
  {
    "uniprot": "A0A077ZES1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZES1"
  },
  {
    "uniprot": "A0A077Z625",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z625"
  },
  {
    "uniprot": "A0A077Z9D5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9D5"
  },
  {
    "uniprot": "A0A077ZJA7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJA7"
  },
  {
    "uniprot": "A0A077Z186",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z186"
  },
  {
    "uniprot": "A0A077ZJC4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJC4"
  },
  {
    "uniprot": "A0A077Z1Z6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Z6"
  },
  {
    "uniprot": "A0A077ZG99",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG99"
  },
  {
    "uniprot": "A0A077Z1M4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1M4"
  },
  {
    "uniprot": "A0A077YWI7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWI7"
  },
  {
    "uniprot": "A0A077YVZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVZ3"
  },
  {
    "uniprot": "A0A077Z8L3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8L3"
  },
  {
    "uniprot": "A0A077Z180",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z180"
  },
  {
    "uniprot": "A0A077Z1W9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1W9"
  },
  {
    "uniprot": "A0A077Z3B1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3B1"
  },
  {
    "uniprot": "A0A077ZKL8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKL8"
  },
  {
    "uniprot": "A0A077ZHF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHF0"
  },
  {
    "uniprot": "A0A077ZKH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKH5"
  },
  {
    "uniprot": "A0A077Z747",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z747"
  },
  {
    "uniprot": "A0A077Z1D0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1D0"
  },
  {
    "uniprot": "A0A077ZNR2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNR2"
  },
  {
    "uniprot": "A0A077YXV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXV3"
  },
  {
    "uniprot": "A0A077YZZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZZ1"
  },
  {
    "uniprot": "A0A077Z014",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z014"
  },
  {
    "uniprot": "A0A077Z1G9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1G9"
  },
  {
    "uniprot": "A0A077ZPF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPF0"
  },
  {
    "uniprot": "A0A077Z2P4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2P4"
  },
  {
    "uniprot": "A0A077ZKX1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKX1"
  },
  {
    "uniprot": "A0A077Z453",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z453"
  },
  {
    "uniprot": "A0A077Z002",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z002"
  },
  {
    "uniprot": "A0A077Z9V4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9V4"
  },
  {
    "uniprot": "A0A077ZFM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFM5"
  },
  {
    "uniprot": "A0A077ZJG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJG9"
  },
  {
    "uniprot": "A0A077ZC58",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC58"
  },
  {
    "uniprot": "A0A077ZFB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFB0"
  },
  {
    "uniprot": "A0A077Z500",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z500"
  },
  {
    "uniprot": "A0A077YZB4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZB4"
  },
  {
    "uniprot": "A0A077ZAR9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAR9"
  },
  {
    "uniprot": "A0A077ZIM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIM8"
  },
  {
    "uniprot": "A0A077YXD9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXD9"
  },
  {
    "uniprot": "A0A077Z482",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z482"
  },
  {
    "uniprot": "A0A077Z6V2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6V2"
  },
  {
    "uniprot": "A0A077Z3J3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3J3"
  },
  {
    "uniprot": "A0A077YVL3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVL3"
  },
  {
    "uniprot": "A0A077Z7G1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7G1"
  },
  {
    "uniprot": "A0A077ZG79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG79"
  },
  {
    "uniprot": "A0A077Z9C1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9C1"
  },
  {
    "uniprot": "A0A077ZE39",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE39"
  },
  {
    "uniprot": "A0A077Z713",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z713"
  },
  {
    "uniprot": "A0A077ZH15",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH15"
  },
  {
    "uniprot": "A0A077ZFI4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFI4"
  },
  {
    "uniprot": "A0A077Z6B9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6B9"
  },
  {
    "uniprot": "A0A077Z994",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z994"
  },
  {
    "uniprot": "A0A077Z080",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z080"
  },
  {
    "uniprot": "A0A077Z3L2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3L2"
  },
  {
    "uniprot": "A0A077YYT5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYT5"
  },
  {
    "uniprot": "A0A077ZID0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZID0"
  },
  {
    "uniprot": "A0A077Z4B0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4B0"
  },
  {
    "uniprot": "A0A077ZHF3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHF3"
  },
  {
    "uniprot": "A0A077ZNY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNY9"
  },
  {
    "uniprot": "A0A077ZFR7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFR7"
  },
  {
    "uniprot": "A0A077Z9N4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9N4"
  },
  {
    "uniprot": "A0A077Z3H1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3H1"
  },
  {
    "uniprot": "A0A077ZB00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB00"
  },
  {
    "uniprot": "A0A077Z579",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z579"
  },
  {
    "uniprot": "A0A077ZI33",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI33"
  },
  {
    "uniprot": "A0A077Z0X9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0X9"
  },
  {
    "uniprot": "A0A077ZMU0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMU0"
  },
  {
    "uniprot": "A0A077Z536",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z536"
  },
  {
    "uniprot": "A0A077Z6S1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6S1"
  },
  {
    "uniprot": "A0A077ZKN5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKN5"
  },
  {
    "uniprot": "A0A077Z3B7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3B7"
  },
  {
    "uniprot": "A0A077ZBJ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBJ4"
  },
  {
    "uniprot": "A0A077Z315",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z315"
  },
  {
    "uniprot": "A0A077Z665",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z665"
  },
  {
    "uniprot": "A0A077ZAZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAZ4"
  },
  {
    "uniprot": "A0A077YXS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXS1"
  },
  {
    "uniprot": "A0A077ZHA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHA1"
  },
  {
    "uniprot": "A0A077Z8W4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8W4"
  },
  {
    "uniprot": "A0A077ZB66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB66"
  },
  {
    "uniprot": "A0A077ZDN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDN6"
  },
  {
    "uniprot": "A0A077Z8Z6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Z6"
  },
  {
    "uniprot": "A0A077ZQT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQT8"
  },
  {
    "uniprot": "A0A077Z6X0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6X0"
  },
  {
    "uniprot": "A0A077Z7S1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7S1"
  },
  {
    "uniprot": "A0A077Z3S0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3S0"
  },
  {
    "uniprot": "A0A077ZGA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGA5"
  },
  {
    "uniprot": "A0A077Z9J1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9J1"
  },
  {
    "uniprot": "A0A077ZK77",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK77"
  },
  {
    "uniprot": "A0A077YXJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXJ3"
  },
  {
    "uniprot": "A0A077ZJ25",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ25"
  },
  {
    "uniprot": "A0A077ZMK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMK6"
  },
  {
    "uniprot": "A0A077ZDL0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDL0"
  },
  {
    "uniprot": "A0A077ZC50",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC50"
  },
  {
    "uniprot": "A0A077Z6R8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6R8"
  },
  {
    "uniprot": "A0A077Z560",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z560"
  },
  {
    "uniprot": "A0A077ZL51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL51"
  },
  {
    "uniprot": "A0A077ZGM3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGM3"
  },
  {
    "uniprot": "A0A077Z7J4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7J4"
  },
  {
    "uniprot": "A0A077YWC1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWC1"
  },
  {
    "uniprot": "A0A077Z2U0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2U0"
  },
  {
    "uniprot": "A0A077ZEA9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEA9"
  },
  {
    "uniprot": "A0A077ZJT7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJT7"
  },
  {
    "uniprot": "A0A077YWV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWV9"
  },
  {
    "uniprot": "A0A077YVJ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVJ9"
  },
  {
    "uniprot": "A0A077Z108",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z108"
  },
  {
    "uniprot": "A0A077Z6K0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6K0"
  },
  {
    "uniprot": "A0A077Z8K3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8K3"
  },
  {
    "uniprot": "A0A077Z6I9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6I9"
  },
  {
    "uniprot": "A0A077YZB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZB2"
  },
  {
    "uniprot": "A0A077Z0Z2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Z2"
  },
  {
    "uniprot": "A0A077YZK8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZK8"
  },
  {
    "uniprot": "A0A077ZFJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFJ5"
  },
  {
    "uniprot": "A0A077YWX8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWX8"
  },
  {
    "uniprot": "A0A077Z760",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z760"
  },
  {
    "uniprot": "A0A077ZCN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCN6"
  },
  {
    "uniprot": "A0A077YZM9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZM9"
  },
  {
    "uniprot": "A0A077ZEC6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEC6"
  },
  {
    "uniprot": "A0A077ZIC9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIC9"
  },
  {
    "uniprot": "A0A077Z800",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z800"
  },
  {
    "uniprot": "A0A077ZEU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEU9"
  },
  {
    "uniprot": "A0A077Z0K8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0K8"
  },
  {
    "uniprot": "A0A077ZCG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCG7"
  },
  {
    "uniprot": "A0A077ZJ48",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ48"
  },
  {
    "uniprot": "A0A077YYU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYU9"
  },
  {
    "uniprot": "A0A077ZLZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLZ9"
  },
  {
    "uniprot": "A0A077ZN80",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN80"
  },
  {
    "uniprot": "A0A077YY26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY26"
  },
  {
    "uniprot": "A0A077YXW6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXW6"
  },
  {
    "uniprot": "A0A077YZ42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ42"
  },
  {
    "uniprot": "A0A077Z4W7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4W7"
  },
  {
    "uniprot": "A0A077ZJJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJJ5"
  },
  {
    "uniprot": "A0A077ZAN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAN2"
  },
  {
    "uniprot": "A0A077ZQX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQX3"
  },
  {
    "uniprot": "A0A077ZFV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFV4"
  },
  {
    "uniprot": "A0A077ZJW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJW8"
  },
  {
    "uniprot": "A0A077Z1A0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1A0"
  },
  {
    "uniprot": "A0A077YWS7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWS7"
  },
  {
    "uniprot": "A0A077Z3F3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3F3"
  },
  {
    "uniprot": "A0A077ZD56",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD56"
  },
  {
    "uniprot": "A0A077Z0M5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0M5"
  },
  {
    "uniprot": "A0A077ZDF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDF0"
  },
  {
    "uniprot": "A0A077Z603",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z603"
  },
  {
    "uniprot": "A0A077ZDT3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDT3"
  },
  {
    "uniprot": "A0A077ZM23",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM23"
  },
  {
    "uniprot": "A0A077ZAX8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAX8"
  },
  {
    "uniprot": "A0A077YYG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYG5"
  },
  {
    "uniprot": "A0A077Z230",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z230"
  },
  {
    "uniprot": "A0A077ZNA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNA1"
  },
  {
    "uniprot": "A0A077ZD58",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD58"
  },
  {
    "uniprot": "A0A077Z6R6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6R6"
  },
  {
    "uniprot": "A0A077Z906",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z906"
  },
  {
    "uniprot": "A0A077ZDK2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDK2"
  },
  {
    "uniprot": "A0A077ZCL0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCL0"
  },
  {
    "uniprot": "A0A077Z357",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z357"
  },
  {
    "uniprot": "A0A077ZGN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGN4"
  },
  {
    "uniprot": "A0A077YYW4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYW4"
  },
  {
    "uniprot": "A0A077ZCX5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCX5"
  },
  {
    "uniprot": "A0A077ZC41",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC41"
  },
  {
    "uniprot": "A0A077ZKG0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKG0"
  },
  {
    "uniprot": "A0A077YXR4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXR4"
  },
  {
    "uniprot": "A0A077Z3C3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3C3"
  },
  {
    "uniprot": "A0A077YZG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZG9"
  },
  {
    "uniprot": "A0A077ZIZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIZ3"
  },
  {
    "uniprot": "A0A077ZNU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNU2"
  },
  {
    "uniprot": "A0A077ZJS0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJS0"
  },
  {
    "uniprot": "A0A077ZDU0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDU0"
  },
  {
    "uniprot": "A0A077ZQV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQV4"
  },
  {
    "uniprot": "A0A077ZKC9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKC9"
  },
  {
    "uniprot": "A0A077ZMV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMV4"
  },
  {
    "uniprot": "A0A077YZY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZY0"
  },
  {
    "uniprot": "A0A077ZDD7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDD7"
  },
  {
    "uniprot": "A0A077Z9U3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9U3"
  },
  {
    "uniprot": "A0A077ZAH4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAH4"
  },
  {
    "uniprot": "A0A077ZCM9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCM9"
  },
  {
    "uniprot": "A0A077ZFV0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFV0"
  },
  {
    "uniprot": "A0A077ZDS2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDS2"
  },
  {
    "uniprot": "A0A077ZC79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC79"
  },
  {
    "uniprot": "A0A077ZBJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBJ7"
  },
  {
    "uniprot": "A0A077ZRC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZRC3"
  },
  {
    "uniprot": "A0A077ZMD9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMD9"
  },
  {
    "uniprot": "A0A077Z1L9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1L9"
  },
  {
    "uniprot": "A0A077ZGJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGJ2"
  },
  {
    "uniprot": "A0A077Z4N4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4N4"
  },
  {
    "uniprot": "A0A077ZKX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKX3"
  },
  {
    "uniprot": "A0A077ZEG4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEG4"
  },
  {
    "uniprot": "A0A077ZFU3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFU3"
  },
  {
    "uniprot": "A0A077Z774",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z774"
  },
  {
    "uniprot": "A0A077ZC59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC59"
  },
  {
    "uniprot": "A0A077YX22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX22"
  },
  {
    "uniprot": "A0A077ZNH0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNH0"
  },
  {
    "uniprot": "A0A077Z7U2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7U2"
  },
  {
    "uniprot": "A0A077YY88",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY88"
  },
  {
    "uniprot": "A0A077ZQY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQY8"
  },
  {
    "uniprot": "A0A077ZA79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA79"
  },
  {
    "uniprot": "A0A077Z5V3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5V3"
  },
  {
    "uniprot": "A0A077ZB78",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB78"
  },
  {
    "uniprot": "A0A077Z3E7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3E7"
  },
  {
    "uniprot": "A0A077ZBT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBT9"
  },
  {
    "uniprot": "A0A077YZK2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZK2"
  },
  {
    "uniprot": "A0A077Z0R3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0R3"
  },
  {
    "uniprot": "A0A077Z777",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z777"
  },
  {
    "uniprot": "A0A077ZFX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFX0"
  },
  {
    "uniprot": "A0A077YWF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWF0"
  },
  {
    "uniprot": "A0A077Z9I0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9I0"
  },
  {
    "uniprot": "A0A077Z584",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z584"
  },
  {
    "uniprot": "A0A077ZJ38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ38"
  },
  {
    "uniprot": "A0A077ZHB5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHB5"
  },
  {
    "uniprot": "A0A077Z9H6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9H6"
  },
  {
    "uniprot": "A0A077Z4Z0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Z0"
  },
  {
    "uniprot": "A0A077Z4E0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4E0"
  },
  {
    "uniprot": "A0A077YUN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUN2"
  },
  {
    "uniprot": "A0A077ZDN0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDN0"
  },
  {
    "uniprot": "A0A077ZJT4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJT4"
  },
  {
    "uniprot": "A0A077Z7U6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7U6"
  },
  {
    "uniprot": "A0A077ZDF1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDF1"
  },
  {
    "uniprot": "A0A077Z3G0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3G0"
  },
  {
    "uniprot": "A0A077ZDR4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDR4"
  },
  {
    "uniprot": "A0A077Z6U1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6U1"
  },
  {
    "uniprot": "A0A077YY42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY42"
  },
  {
    "uniprot": "A0A077YZS4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZS4"
  },
  {
    "uniprot": "A0A077YWU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWU6"
  },
  {
    "uniprot": "A0A077ZJY7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJY7"
  },
  {
    "uniprot": "A0A077YW39",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW39"
  },
  {
    "uniprot": "A0A077Z0S0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0S0"
  },
  {
    "uniprot": "A0A077YXA9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXA9"
  },
  {
    "uniprot": "A0A077Z1Y5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Y5"
  },
  {
    "uniprot": "A0A077ZEN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEN3"
  },
  {
    "uniprot": "A0A077ZFA7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFA7"
  },
  {
    "uniprot": "A0A077ZGV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGV9"
  },
  {
    "uniprot": "A0A077ZGE9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGE9"
  },
  {
    "uniprot": "A0A077YYX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYX0"
  },
  {
    "uniprot": "A0A077ZM06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM06"
  },
  {
    "uniprot": "A0A077ZAN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAN9"
  },
  {
    "uniprot": "A0A077Z1I2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1I2"
  },
  {
    "uniprot": "A0A077ZA86",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA86"
  },
  {
    "uniprot": "A0A077Z372",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z372"
  },
  {
    "uniprot": "A0A077ZLA6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLA6"
  },
  {
    "uniprot": "A0A077ZHS7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHS7"
  },
  {
    "uniprot": "A0A077YXP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXP5"
  },
  {
    "uniprot": "A0A077ZE51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE51"
  },
  {
    "uniprot": "A0A077YXC7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXC7"
  },
  {
    "uniprot": "A0A077Z117",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z117"
  },
  {
    "uniprot": "A0A077YXQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXQ5"
  },
  {
    "uniprot": "A0A077ZAG2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAG2"
  },
  {
    "uniprot": "A0A077Z250",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z250"
  },
  {
    "uniprot": "A0A077ZI42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI42"
  },
  {
    "uniprot": "A0A077Z8I8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8I8"
  },
  {
    "uniprot": "A0A077ZHP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHP5"
  },
  {
    "uniprot": "A0A077ZAD1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAD1"
  },
  {
    "uniprot": "A0A077ZMY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMY9"
  },
  {
    "uniprot": "A0A077Z621",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z621"
  },
  {
    "uniprot": "A0A077ZNJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNJ3"
  },
  {
    "uniprot": "A0A077ZKI6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKI6"
  },
  {
    "uniprot": "A0A077ZG49",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG49"
  },
  {
    "uniprot": "A0A077ZES3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZES3"
  },
  {
    "uniprot": "A0A077YX36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX36"
  },
  {
    "uniprot": "A0A077Z362",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z362"
  },
  {
    "uniprot": "A0A077ZJM9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJM9"
  },
  {
    "uniprot": "A0A077ZDW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDW8"
  },
  {
    "uniprot": "A0A077Z0Q4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Q4"
  },
  {
    "uniprot": "A0A077Z9T8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9T8"
  },
  {
    "uniprot": "A0A077YYN5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYN5"
  },
  {
    "uniprot": "A0A077Z7A2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7A2"
  },
  {
    "uniprot": "A0A077YW14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW14"
  },
  {
    "uniprot": "A0A077ZHN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHN9"
  },
  {
    "uniprot": "A0A077Z5T1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5T1"
  },
  {
    "uniprot": "A0A077ZQQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQQ2"
  },
  {
    "uniprot": "A0A077Z6C4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6C4"
  },
  {
    "uniprot": "A0A077ZI50",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI50"
  },
  {
    "uniprot": "A0A077Z063",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z063"
  },
  {
    "uniprot": "A0A077ZAE6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAE6"
  },
  {
    "uniprot": "A0A077ZNI9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNI9"
  },
  {
    "uniprot": "A0A077ZMG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMG9"
  },
  {
    "uniprot": "A0A077Z790",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z790"
  },
  {
    "uniprot": "A0A077ZIS4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIS4"
  },
  {
    "uniprot": "A0A077ZGM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGM5"
  },
  {
    "uniprot": "A0A077Z2N6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2N6"
  },
  {
    "uniprot": "A0A077Z025",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z025"
  },
  {
    "uniprot": "A0A077Z6J4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6J4"
  },
  {
    "uniprot": "A0A077YXD0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXD0"
  },
  {
    "uniprot": "A0A077ZA61",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA61"
  },
  {
    "uniprot": "A0A077Z7M7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7M7"
  },
  {
    "uniprot": "A0A077Z667",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z667"
  },
  {
    "uniprot": "A0A077ZNL2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNL2"
  },
  {
    "uniprot": "A0A077Z7G7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7G7"
  },
  {
    "uniprot": "A0A077Z669",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z669"
  },
  {
    "uniprot": "A0A077ZA26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA26"
  },
  {
    "uniprot": "A0A077YYP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYP8"
  },
  {
    "uniprot": "A0A077ZAT1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAT1"
  },
  {
    "uniprot": "A0A077ZR86",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR86"
  },
  {
    "uniprot": "A0A077Z4S3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4S3"
  },
  {
    "uniprot": "A0A077ZLS0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLS0"
  },
  {
    "uniprot": "A0A077ZET0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZET0"
  },
  {
    "uniprot": "A0A077Z2Y3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Y3"
  },
  {
    "uniprot": "A0A077ZBG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBG9"
  },
  {
    "uniprot": "A0A077YVX4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVX4"
  },
  {
    "uniprot": "A0A077ZG24",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG24"
  },
  {
    "uniprot": "A0A077Z9J2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9J2"
  },
  {
    "uniprot": "A0A077ZDL8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDL8"
  },
  {
    "uniprot": "A0A077ZKS0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKS0"
  },
  {
    "uniprot": "A0A077ZBN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBN6"
  },
  {
    "uniprot": "A0A077YVV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVV9"
  },
  {
    "uniprot": "A0A077Z2E3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2E3"
  },
  {
    "uniprot": "A0A077ZL60",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL60"
  },
  {
    "uniprot": "A0A077ZHB8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHB8"
  },
  {
    "uniprot": "A0A077Z0E7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0E7"
  },
  {
    "uniprot": "A0A077Z1Y8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Y8"
  },
  {
    "uniprot": "A0A077Z5R3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5R3"
  },
  {
    "uniprot": "A0A077ZC76",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC76"
  },
  {
    "uniprot": "A0A077YYY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYY9"
  },
  {
    "uniprot": "A0A077Z5A4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5A4"
  },
  {
    "uniprot": "A0A077ZK09",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK09"
  },
  {
    "uniprot": "A0A077ZGT2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGT2"
  },
  {
    "uniprot": "A0A077ZQT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQT9"
  },
  {
    "uniprot": "A0A077ZJC0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJC0"
  },
  {
    "uniprot": "A0A077Z801",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z801"
  },
  {
    "uniprot": "A0A077ZF82",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF82"
  },
  {
    "uniprot": "A0A077Z0J3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0J3"
  },
  {
    "uniprot": "A0A077ZIP7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIP7"
  },
  {
    "uniprot": "A0A077Z9H3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9H3"
  },
  {
    "uniprot": "A0A077ZKU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKU7"
  },
  {
    "uniprot": "A0A077ZGT1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGT1"
  },
  {
    "uniprot": "A0A077ZHU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHU4"
  },
  {
    "uniprot": "A0A077Z5V6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5V6"
  },
  {
    "uniprot": "A0A077Z6M2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6M2"
  },
  {
    "uniprot": "A0A077Z7L2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7L2"
  },
  {
    "uniprot": "A0A077ZCQ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCQ6"
  },
  {
    "uniprot": "A0A077ZMA3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMA3"
  },
  {
    "uniprot": "A0A077Z785",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z785"
  },
  {
    "uniprot": "A0A077ZFE7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFE7"
  },
  {
    "uniprot": "A0A077ZJJ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJJ1"
  },
  {
    "uniprot": "A0A077ZFS2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFS2"
  },
  {
    "uniprot": "A0A077ZBF8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBF8"
  },
  {
    "uniprot": "A0A077Z7B2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7B2"
  },
  {
    "uniprot": "A0A077Z1X3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1X3"
  },
  {
    "uniprot": "A0A077YXR2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXR2"
  },
  {
    "uniprot": "A0A077Z5V7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5V7"
  },
  {
    "uniprot": "A0A077YZY7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZY7"
  },
  {
    "uniprot": "A0A077ZE36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE36"
  },
  {
    "uniprot": "A0A077ZCC5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCC5"
  },
  {
    "uniprot": "A0A077ZIL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIL6"
  },
  {
    "uniprot": "A0A077ZCJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCJ2"
  },
  {
    "uniprot": "A0A077YZS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZS9"
  },
  {
    "uniprot": "A0A077YXA4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXA4"
  },
  {
    "uniprot": "A0A077YVN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVN3"
  },
  {
    "uniprot": "A0A077ZLS3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLS3"
  },
  {
    "uniprot": "A0A077Z9K9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9K9"
  },
  {
    "uniprot": "A0A077ZJ94",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ94"
  },
  {
    "uniprot": "A0A077ZA73",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA73"
  },
  {
    "uniprot": "A0A077ZLW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLW2"
  },
  {
    "uniprot": "A0A077YY38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY38"
  },
  {
    "uniprot": "A0A077Z287",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z287"
  },
  {
    "uniprot": "A0A077ZDG4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDG4"
  },
  {
    "uniprot": "A0A077ZH01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH01"
  },
  {
    "uniprot": "A0A077ZER3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZER3"
  },
  {
    "uniprot": "A0A077Z6U8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6U8"
  },
  {
    "uniprot": "A0A077YWY2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWY2"
  },
  {
    "uniprot": "A0A077ZAG4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAG4"
  },
  {
    "uniprot": "A0A077ZNE5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNE5"
  },
  {
    "uniprot": "A0A077Z956",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z956"
  },
  {
    "uniprot": "A0A077Z489",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z489"
  },
  {
    "uniprot": "A0A077YXM9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXM9"
  },
  {
    "uniprot": "A0A077Z794",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z794"
  },
  {
    "uniprot": "A0A077Z8R0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8R0"
  },
  {
    "uniprot": "A0A077ZF59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF59"
  },
  {
    "uniprot": "A0A077YZ96",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ96"
  },
  {
    "uniprot": "A0A077Z7D9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7D9"
  },
  {
    "uniprot": "A0A077Z390",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z390"
  },
  {
    "uniprot": "A0A077ZH75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH75"
  },
  {
    "uniprot": "A0A077ZCX8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCX8"
  },
  {
    "uniprot": "A0A077Z3Q6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Q6"
  },
  {
    "uniprot": "A0A077ZFZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFZ8"
  },
  {
    "uniprot": "A0A077Z2K2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2K2"
  },
  {
    "uniprot": "A0A077ZFY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFY4"
  },
  {
    "uniprot": "A0A077Z327",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z327"
  },
  {
    "uniprot": "A0A077Z8N5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8N5"
  },
  {
    "uniprot": "A0A077YVK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVK4"
  },
  {
    "uniprot": "A0A077ZAW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAW8"
  },
  {
    "uniprot": "A0A077ZHT1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHT1"
  },
  {
    "uniprot": "A0A077YZZ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZZ2"
  },
  {
    "uniprot": "A0A077YWT6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWT6"
  },
  {
    "uniprot": "A0A077Z9E3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9E3"
  },
  {
    "uniprot": "A0A077Z2S0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2S0"
  },
  {
    "uniprot": "A0A077ZND9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZND9"
  },
  {
    "uniprot": "A0A077ZKS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKS1"
  },
  {
    "uniprot": "A0A077Z1T7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1T7"
  },
  {
    "uniprot": "A0A077YW62",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW62"
  },
  {
    "uniprot": "A0A077ZAW4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAW4"
  },
  {
    "uniprot": "A0A077Z3Z5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Z5"
  },
  {
    "uniprot": "A0A077Z2E4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2E4"
  },
  {
    "uniprot": "A0A077ZJV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJV9"
  },
  {
    "uniprot": "A0A077Z6A4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6A4"
  },
  {
    "uniprot": "A0A077ZPL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPL7"
  },
  {
    "uniprot": "A0A077ZM52",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM52"
  },
  {
    "uniprot": "A0A077Z0Y6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Y6"
  },
  {
    "uniprot": "A0A077YWF1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWF1"
  },
  {
    "uniprot": "A0A077ZIE1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIE1"
  },
  {
    "uniprot": "A0A077ZAF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAF0"
  },
  {
    "uniprot": "A0A077Z6C2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6C2"
  },
  {
    "uniprot": "A0A077Z1D1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1D1"
  },
  {
    "uniprot": "A0A077YXJ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXJ1"
  },
  {
    "uniprot": "A0A077ZHI4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHI4"
  },
  {
    "uniprot": "A0A077ZCV5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCV5"
  },
  {
    "uniprot": "A0A077ZEW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEW2"
  },
  {
    "uniprot": "A0A077ZC02",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC02"
  },
  {
    "uniprot": "A0A077ZI59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI59"
  },
  {
    "uniprot": "A0A077Z970",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z970"
  },
  {
    "uniprot": "A0A077Z660",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z660"
  },
  {
    "uniprot": "A0A077ZIK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIK6"
  },
  {
    "uniprot": "A0A077Z520",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z520"
  },
  {
    "uniprot": "A0A077Z3Z8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Z8"
  },
  {
    "uniprot": "A0A077ZC29",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC29"
  },
  {
    "uniprot": "A0A077ZMH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMH3"
  },
  {
    "uniprot": "A0A077Z5C6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5C6"
  },
  {
    "uniprot": "A0A077Z3V5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3V5"
  },
  {
    "uniprot": "A0A077Z408",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z408"
  },
  {
    "uniprot": "A0A077ZM22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM22"
  },
  {
    "uniprot": "A0A077YZG2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZG2"
  },
  {
    "uniprot": "A0A077ZF63",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF63"
  },
  {
    "uniprot": "A0A077ZHA0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHA0"
  },
  {
    "uniprot": "A0A077Z8F5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8F5"
  },
  {
    "uniprot": "A0A077ZCT1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCT1"
  },
  {
    "uniprot": "A0A077Z174",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z174"
  },
  {
    "uniprot": "A0A077Z719",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z719"
  },
  {
    "uniprot": "A0A077YVH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVH5"
  },
  {
    "uniprot": "A0A077YUK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUK6"
  },
  {
    "uniprot": "A0A077ZR22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR22"
  },
  {
    "uniprot": "A0A077ZL36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL36"
  },
  {
    "uniprot": "A0A077ZPK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPK3"
  },
  {
    "uniprot": "A0A077ZCV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCV1"
  },
  {
    "uniprot": "A0A077Z1Q5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Q5"
  },
  {
    "uniprot": "A0A077Z343",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z343"
  },
  {
    "uniprot": "A0A077YWS4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWS4"
  },
  {
    "uniprot": "A0A077YV38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV38"
  },
  {
    "uniprot": "A0A077YXM3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXM3"
  },
  {
    "uniprot": "A0A077Z883",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z883"
  },
  {
    "uniprot": "A0A077YWQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWQ3"
  },
  {
    "uniprot": "A0A077ZK01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK01"
  },
  {
    "uniprot": "A0A077ZEM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEM2"
  },
  {
    "uniprot": "A0A077Z0C6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0C6"
  },
  {
    "uniprot": "A0A077Z7V0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7V0"
  },
  {
    "uniprot": "A0A077ZF57",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF57"
  },
  {
    "uniprot": "A0A077ZR54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR54"
  },
  {
    "uniprot": "A0A077ZJR7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJR7"
  },
  {
    "uniprot": "A0A077YZD7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZD7"
  },
  {
    "uniprot": "A0A077Z0B3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0B3"
  },
  {
    "uniprot": "A0A077ZMT5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMT5"
  },
  {
    "uniprot": "A0A077ZJS1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJS1"
  },
  {
    "uniprot": "A0A077YW75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW75"
  },
  {
    "uniprot": "A0A077Z8B8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8B8"
  },
  {
    "uniprot": "A0A077ZBW6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBW6"
  },
  {
    "uniprot": "A0A077Z7G3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7G3"
  },
  {
    "uniprot": "A0A077ZHV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHV6"
  },
  {
    "uniprot": "A0A077ZCC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCC3"
  },
  {
    "uniprot": "A0A077Z683",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z683"
  },
  {
    "uniprot": "A0A077YVJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVJ2"
  },
  {
    "uniprot": "A0A077ZAP7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAP7"
  },
  {
    "uniprot": "A0A077ZIU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIU4"
  },
  {
    "uniprot": "A0A077ZG11",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG11"
  },
  {
    "uniprot": "A0A077ZG19",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG19"
  },
  {
    "uniprot": "A0A077YYN8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYN8"
  },
  {
    "uniprot": "A0A077Z755",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z755"
  },
  {
    "uniprot": "A0A077ZNL3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNL3"
  },
  {
    "uniprot": "A0A077Z764",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z764"
  },
  {
    "uniprot": "A0A077YY45",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY45"
  },
  {
    "uniprot": "A0A077YZJ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZJ6"
  },
  {
    "uniprot": "A0A077Z2K8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2K8"
  },
  {
    "uniprot": "A0A077ZBW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBW1"
  },
  {
    "uniprot": "A0A077ZLE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLE8"
  },
  {
    "uniprot": "A0A077ZBL0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBL0"
  },
  {
    "uniprot": "A0A077ZBR9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBR9"
  },
  {
    "uniprot": "A0A077ZIT0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIT0"
  },
  {
    "uniprot": "A0A077ZD05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD05"
  },
  {
    "uniprot": "A0A077ZHA4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHA4"
  },
  {
    "uniprot": "A0A077ZGY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGY6"
  },
  {
    "uniprot": "A0A077Z7Q9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Q9"
  },
  {
    "uniprot": "A0A077YWD9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWD9"
  },
  {
    "uniprot": "A0A077Z2X1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2X1"
  },
  {
    "uniprot": "A0A077ZJ77",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ77"
  },
  {
    "uniprot": "A0A077ZKQ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKQ6"
  },
  {
    "uniprot": "A0A077YYS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYS9"
  },
  {
    "uniprot": "A0A077ZIJ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIJ6"
  },
  {
    "uniprot": "A0A077ZK44",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK44"
  },
  {
    "uniprot": "A0A077ZGU7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGU7"
  },
  {
    "uniprot": "A0A077Z9Q6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Q6"
  },
  {
    "uniprot": "A0A077Z4D4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4D4"
  },
  {
    "uniprot": "A0A077ZBS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBS9"
  },
  {
    "uniprot": "A0A077Z7L8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7L8"
  },
  {
    "uniprot": "A0A077ZQI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQI3"
  },
  {
    "uniprot": "A0A077ZEE6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEE6"
  },
  {
    "uniprot": "A0A077Z2K0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2K0"
  },
  {
    "uniprot": "A0A077ZKY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKY3"
  },
  {
    "uniprot": "A0A077Z515",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z515"
  },
  {
    "uniprot": "A0A077Z4X4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4X4"
  },
  {
    "uniprot": "A0A077ZFE5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFE5"
  },
  {
    "uniprot": "A0A077ZNV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNV3"
  },
  {
    "uniprot": "A0A077ZB43",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB43"
  },
  {
    "uniprot": "A0A077ZE52",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE52"
  },
  {
    "uniprot": "A0A077ZBJ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBJ1"
  },
  {
    "uniprot": "A0A077ZQB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQB9"
  },
  {
    "uniprot": "A0A077ZKY5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKY5"
  },
  {
    "uniprot": "A0A077ZCR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCR6"
  },
  {
    "uniprot": "A0A077ZGG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGG7"
  },
  {
    "uniprot": "A0A077Z514",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z514"
  },
  {
    "uniprot": "A0A077Z378",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z378"
  },
  {
    "uniprot": "A0A077ZD42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD42"
  },
  {
    "uniprot": "A0A077Z2M6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2M6"
  },
  {
    "uniprot": "A0A077ZJX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJX0"
  },
  {
    "uniprot": "A0A077Z1L7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1L7"
  },
  {
    "uniprot": "A0A077Z7P9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7P9"
  },
  {
    "uniprot": "A0A077Z8U7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8U7"
  },
  {
    "uniprot": "A0A077ZJI6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJI6"
  },
  {
    "uniprot": "A0A077ZFX5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFX5"
  },
  {
    "uniprot": "A0A077YW84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW84"
  },
  {
    "uniprot": "A0A077YUQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUQ3"
  },
  {
    "uniprot": "A0A077YV18",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV18"
  },
  {
    "uniprot": "A0A077YYW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYW8"
  },
  {
    "uniprot": "A0A077Z3L4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3L4"
  },
  {
    "uniprot": "A0A077Z3U1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3U1"
  },
  {
    "uniprot": "A0A077ZKG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKG3"
  },
  {
    "uniprot": "A0A077ZDP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDP0"
  },
  {
    "uniprot": "A0A077ZAP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAP2"
  },
  {
    "uniprot": "A0A077ZIV7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIV7"
  },
  {
    "uniprot": "A0A077Z325",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z325"
  },
  {
    "uniprot": "A0A077ZCF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCF4"
  },
  {
    "uniprot": "A0A077Z0J1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0J1"
  },
  {
    "uniprot": "A0A077ZIC1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIC1"
  },
  {
    "uniprot": "A0A077ZHD3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHD3"
  },
  {
    "uniprot": "A0A077ZJA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJA5"
  },
  {
    "uniprot": "A0A077YV35",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV35"
  },
  {
    "uniprot": "A0A077Z4G8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4G8"
  },
  {
    "uniprot": "A0A077Z4M3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4M3"
  },
  {
    "uniprot": "A0A077YXW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXW9"
  },
  {
    "uniprot": "A0A077ZEH9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEH9"
  },
  {
    "uniprot": "A0A077ZJK2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJK2"
  },
  {
    "uniprot": "A0A077ZHE4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHE4"
  },
  {
    "uniprot": "A0A077Z0I3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0I3"
  },
  {
    "uniprot": "A0A077Z196",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z196"
  },
  {
    "uniprot": "A0A077ZLG6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLG6"
  },
  {
    "uniprot": "A0A077YWQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWQ0"
  },
  {
    "uniprot": "A0A077ZIC7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIC7"
  },
  {
    "uniprot": "A0A077YYN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYN4"
  },
  {
    "uniprot": "A0A077ZLG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLG3"
  },
  {
    "uniprot": "A0A077ZFJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFJ0"
  },
  {
    "uniprot": "A0A077Z9Q8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Q8"
  },
  {
    "uniprot": "A0A077ZL77",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL77"
  },
  {
    "uniprot": "A0A077YZD9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZD9"
  },
  {
    "uniprot": "A0A077Z5B3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5B3"
  },
  {
    "uniprot": "A0A077Z3A6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3A6"
  },
  {
    "uniprot": "A0A077ZI95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI95"
  },
  {
    "uniprot": "A0A077ZLY2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLY2"
  },
  {
    "uniprot": "A0A077ZFM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFM6"
  },
  {
    "uniprot": "A0A077YWV2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWV2"
  },
  {
    "uniprot": "A0A077Z6P7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6P7"
  },
  {
    "uniprot": "A0A077ZGX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGX9"
  },
  {
    "uniprot": "A0A077Z215",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z215"
  },
  {
    "uniprot": "A0A077Z262",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z262"
  },
  {
    "uniprot": "A0A077ZJ24",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ24"
  },
  {
    "uniprot": "A0A077Z6S6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6S6"
  },
  {
    "uniprot": "A0A077ZLC0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLC0"
  },
  {
    "uniprot": "A0A077Z4L5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4L5"
  },
  {
    "uniprot": "A0A077ZHS8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHS8"
  },
  {
    "uniprot": "A0A077ZM04",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM04"
  },
  {
    "uniprot": "A0A077Z3U8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3U8"
  },
  {
    "uniprot": "A0A077YVP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVP5"
  },
  {
    "uniprot": "A0A077YYF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYF4"
  },
  {
    "uniprot": "A0A077Z3Q7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Q7"
  },
  {
    "uniprot": "A0A077YZB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZB6"
  },
  {
    "uniprot": "A0A077Z7W5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7W5"
  },
  {
    "uniprot": "A0A077ZJX1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJX1"
  },
  {
    "uniprot": "A0A077ZAQ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAQ6"
  },
  {
    "uniprot": "A0A077ZE79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE79"
  },
  {
    "uniprot": "A0A077YXR0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXR0"
  },
  {
    "uniprot": "A0A077YYR5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYR5"
  },
  {
    "uniprot": "A0A077Z168",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z168"
  },
  {
    "uniprot": "A0A077Z170",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z170"
  },
  {
    "uniprot": "A0A077Z083",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z083"
  },
  {
    "uniprot": "A0A077Z2X6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2X6"
  },
  {
    "uniprot": "A0A077Z9T5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9T5"
  },
  {
    "uniprot": "A0A077Z649",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z649"
  },
  {
    "uniprot": "A0A077Z7S5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7S5"
  },
  {
    "uniprot": "A0A077Z9Y8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Y8"
  },
  {
    "uniprot": "A0A077ZM31",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM31"
  },
  {
    "uniprot": "A0A077YYE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYE2"
  },
  {
    "uniprot": "A0A077Z6K4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6K4"
  },
  {
    "uniprot": "A0A077Z7M0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7M0"
  },
  {
    "uniprot": "A0A077ZHP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHP4"
  },
  {
    "uniprot": "A0A077Z592",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z592"
  },
  {
    "uniprot": "A0A077ZH77",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH77"
  },
  {
    "uniprot": "A0A077Z1C8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1C8"
  },
  {
    "uniprot": "A0A077Z0N2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0N2"
  },
  {
    "uniprot": "A0A077Z5N3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5N3"
  },
  {
    "uniprot": "A0A077Z8L4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8L4"
  },
  {
    "uniprot": "A0A077ZLZ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLZ6"
  },
  {
    "uniprot": "A0A077Z4X9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4X9"
  },
  {
    "uniprot": "A0A077ZDV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDV1"
  },
  {
    "uniprot": "A0A077Z1G3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1G3"
  },
  {
    "uniprot": "A0A077YYE1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYE1"
  },
  {
    "uniprot": "A0A077ZLX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLX7"
  },
  {
    "uniprot": "A0A077Z416",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z416"
  },
  {
    "uniprot": "A0A077YX75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX75"
  },
  {
    "uniprot": "A0A077ZJQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJQ5"
  },
  {
    "uniprot": "A0A077Z770",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z770"
  },
  {
    "uniprot": "A0A077Z449",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z449"
  },
  {
    "uniprot": "A0A077Z7U5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7U5"
  },
  {
    "uniprot": "A0A077ZF88",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF88"
  },
  {
    "uniprot": "A0A077ZHC6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHC6"
  },
  {
    "uniprot": "A0A077Z4I5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4I5"
  },
  {
    "uniprot": "A0A077Z629",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z629"
  },
  {
    "uniprot": "A0A077ZFD0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFD0"
  },
  {
    "uniprot": "A0A077ZBK0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBK0"
  },
  {
    "uniprot": "A0A077ZGF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGF0"
  },
  {
    "uniprot": "A0A077Z8Q5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Q5"
  },
  {
    "uniprot": "A0A077YXX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXX7"
  },
  {
    "uniprot": "A0A077ZF03",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF03"
  },
  {
    "uniprot": "A0A077Z594",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z594"
  },
  {
    "uniprot": "A0A077Z7L3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7L3"
  },
  {
    "uniprot": "A0A077ZD94",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD94"
  },
  {
    "uniprot": "A0A077ZNQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNQ8"
  },
  {
    "uniprot": "A0A077ZLI0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLI0"
  },
  {
    "uniprot": "A0A077Z0M3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0M3"
  },
  {
    "uniprot": "A0A077ZDR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDR6"
  },
  {
    "uniprot": "A0A077ZLH0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLH0"
  },
  {
    "uniprot": "A0A077ZK70",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK70"
  },
  {
    "uniprot": "A0A077Z3B5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3B5"
  },
  {
    "uniprot": "A0A077YVR7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVR7"
  },
  {
    "uniprot": "A0A077ZE25",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE25"
  },
  {
    "uniprot": "A0A077ZID5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZID5"
  },
  {
    "uniprot": "A0A077ZFZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFZ1"
  },
  {
    "uniprot": "A0A077Z5Y1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Y1"
  },
  {
    "uniprot": "A0A077ZCM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCM7"
  },
  {
    "uniprot": "A0A077Z3E6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3E6"
  },
  {
    "uniprot": "A0A077Z9E7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9E7"
  },
  {
    "uniprot": "A0A077Z0U6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0U6"
  },
  {
    "uniprot": "A0A077Z0F2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0F2"
  },
  {
    "uniprot": "A0A077Z3K8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3K8"
  },
  {
    "uniprot": "A0A077ZGF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGF9"
  },
  {
    "uniprot": "A0A077Z6B8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6B8"
  },
  {
    "uniprot": "A0A077Z7K3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7K3"
  },
  {
    "uniprot": "A0A077ZJ42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ42"
  },
  {
    "uniprot": "A0A077ZIQ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIQ1"
  },
  {
    "uniprot": "A0A077YXX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXX6"
  },
  {
    "uniprot": "A0A077Z3A9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3A9"
  },
  {
    "uniprot": "A0A077Z0P1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0P1"
  },
  {
    "uniprot": "A0A077ZAM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAM1"
  },
  {
    "uniprot": "A0A077ZB39",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB39"
  },
  {
    "uniprot": "A0A077ZD80",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD80"
  },
  {
    "uniprot": "A0A077ZJQ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJQ6"
  },
  {
    "uniprot": "A0A077ZA87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA87"
  },
  {
    "uniprot": "A0A077YZH4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZH4"
  },
  {
    "uniprot": "A0A077ZDJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDJ7"
  },
  {
    "uniprot": "A0A077ZJV5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJV5"
  },
  {
    "uniprot": "A0A077Z6L3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6L3"
  },
  {
    "uniprot": "A0A077ZHT4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHT4"
  },
  {
    "uniprot": "A0A077Z2S2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2S2"
  },
  {
    "uniprot": "A0A077ZIS2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIS2"
  },
  {
    "uniprot": "A0A077Z4F7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4F7"
  },
  {
    "uniprot": "A0A077Z7R2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7R2"
  },
  {
    "uniprot": "A0A077ZA89",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA89"
  },
  {
    "uniprot": "A0A077ZI97",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI97"
  },
  {
    "uniprot": "A0A077Z1S9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1S9"
  },
  {
    "uniprot": "A0A077ZA84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA84"
  },
  {
    "uniprot": "A0A077ZKL0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKL0"
  },
  {
    "uniprot": "A0A077ZAC5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAC5"
  },
  {
    "uniprot": "A0A077Z6P0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6P0"
  },
  {
    "uniprot": "A0A077ZJX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJX7"
  },
  {
    "uniprot": "A0A077ZCF1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCF1"
  },
  {
    "uniprot": "A0A077ZI27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI27"
  },
  {
    "uniprot": "A0A077ZDK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDK6"
  },
  {
    "uniprot": "A0A077Z334",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z334"
  },
  {
    "uniprot": "A0A077Z5Q8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Q8"
  },
  {
    "uniprot": "A0A077Z5I7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5I7"
  },
  {
    "uniprot": "A0A077YZF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZF9"
  },
  {
    "uniprot": "A0A077ZCT2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCT2"
  },
  {
    "uniprot": "A0A077Z6D3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6D3"
  },
  {
    "uniprot": "A0A077ZFT2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFT2"
  },
  {
    "uniprot": "A0A077ZAA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAA1"
  },
  {
    "uniprot": "A0A077ZHV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHV9"
  },
  {
    "uniprot": "A0A077ZAD0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAD0"
  },
  {
    "uniprot": "A0A077ZD87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD87"
  },
  {
    "uniprot": "A0A077Z6Q7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Q7"
  },
  {
    "uniprot": "A0A077Z244",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z244"
  },
  {
    "uniprot": "A0A077Z0M6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0M6"
  },
  {
    "uniprot": "A0A077ZBQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBQ3"
  },
  {
    "uniprot": "A0A077Z383",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z383"
  },
  {
    "uniprot": "A0A077ZLQ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLQ1"
  },
  {
    "uniprot": "A0A077Z485",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z485"
  },
  {
    "uniprot": "A0A077ZEQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEQ8"
  },
  {
    "uniprot": "A0A077ZLR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLR6"
  },
  {
    "uniprot": "A0A077ZFB3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFB3"
  },
  {
    "uniprot": "A0A077ZI25",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI25"
  },
  {
    "uniprot": "A0A077Z0N6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0N6"
  },
  {
    "uniprot": "A0A077ZEE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEE8"
  },
  {
    "uniprot": "A0A077Z978",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z978"
  },
  {
    "uniprot": "A0A077Z2J9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2J9"
  },
  {
    "uniprot": "A0A077Z8H3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8H3"
  },
  {
    "uniprot": "A0A077Z3U6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3U6"
  },
  {
    "uniprot": "A0A077Z102",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z102"
  },
  {
    "uniprot": "A0A077ZH63",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH63"
  },
  {
    "uniprot": "A0A077Z9T4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9T4"
  },
  {
    "uniprot": "A0A077ZJC7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJC7"
  },
  {
    "uniprot": "A0A077ZC64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC64"
  },
  {
    "uniprot": "A0A077YWA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWA5"
  },
  {
    "uniprot": "A0A077ZBL9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBL9"
  },
  {
    "uniprot": "A0A077Z2I9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2I9"
  },
  {
    "uniprot": "A0A077ZM29",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM29"
  },
  {
    "uniprot": "A0A077YUT4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUT4"
  },
  {
    "uniprot": "A0A077Z5S5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5S5"
  },
  {
    "uniprot": "A0A077ZNE0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNE0"
  },
  {
    "uniprot": "A0A077YWI4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWI4"
  },
  {
    "uniprot": "A0A077ZKJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKJ7"
  },
  {
    "uniprot": "A0A077ZAE5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAE5"
  },
  {
    "uniprot": "A0A077Z7Y7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Y7"
  },
  {
    "uniprot": "A0A077ZJC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJC3"
  },
  {
    "uniprot": "A0A077Z845",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z845"
  },
  {
    "uniprot": "A0A077YZ72",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ72"
  },
  {
    "uniprot": "A0A077Z0A9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0A9"
  },
  {
    "uniprot": "A0A077ZET2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZET2"
  },
  {
    "uniprot": "A0A077ZKC1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKC1"
  },
  {
    "uniprot": "A0A077Z278",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z278"
  },
  {
    "uniprot": "A0A077ZFP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFP9"
  },
  {
    "uniprot": "A0A077ZJI7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJI7"
  },
  {
    "uniprot": "A0A077ZHZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHZ8"
  },
  {
    "uniprot": "A0A077Z5K2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5K2"
  },
  {
    "uniprot": "A0A077Z9A1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9A1"
  },
  {
    "uniprot": "A0A077ZC18",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC18"
  },
  {
    "uniprot": "A0A077ZDY5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDY5"
  },
  {
    "uniprot": "A0A077ZKG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKG7"
  },
  {
    "uniprot": "A0A077ZPC0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPC0"
  },
  {
    "uniprot": "A0A077ZLB8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLB8"
  },
  {
    "uniprot": "A0A077Z8W3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8W3"
  },
  {
    "uniprot": "A0A077Z0S9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0S9"
  },
  {
    "uniprot": "A0A077YYN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYN2"
  },
  {
    "uniprot": "A0A077ZMK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMK4"
  },
  {
    "uniprot": "A0A077ZL18",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL18"
  },
  {
    "uniprot": "A0A077ZGD4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGD4"
  },
  {
    "uniprot": "A0A077ZA21",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA21"
  },
  {
    "uniprot": "A0A077ZIA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIA8"
  },
  {
    "uniprot": "A0A077ZAN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAN7"
  },
  {
    "uniprot": "A0A077ZD26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD26"
  },
  {
    "uniprot": "A0A077ZGI5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGI5"
  },
  {
    "uniprot": "A0A077YX80",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX80"
  },
  {
    "uniprot": "A0A077ZCL6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCL6"
  },
  {
    "uniprot": "A0A077Z9W1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9W1"
  },
  {
    "uniprot": "A0A077ZGP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGP4"
  },
  {
    "uniprot": "A0A077ZC57",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC57"
  },
  {
    "uniprot": "A0A077Z430",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z430"
  },
  {
    "uniprot": "A0A077Z5K3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5K3"
  },
  {
    "uniprot": "A0A077ZDU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDU5"
  },
  {
    "uniprot": "A0A077ZHL9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHL9"
  },
  {
    "uniprot": "A0A077ZN55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN55"
  },
  {
    "uniprot": "A0A077ZJJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJJ2"
  },
  {
    "uniprot": "A0A077Z875",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z875"
  },
  {
    "uniprot": "A0A077ZAU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAU6"
  },
  {
    "uniprot": "A0A077Z2P7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2P7"
  },
  {
    "uniprot": "A0A077Z9Q7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Q7"
  },
  {
    "uniprot": "A0A077ZIW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIW5"
  },
  {
    "uniprot": "A0A077Z4Y3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Y3"
  },
  {
    "uniprot": "A0A077YXD4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXD4"
  },
  {
    "uniprot": "A0A077YZY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZY3"
  },
  {
    "uniprot": "A0A077ZH45",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH45"
  },
  {
    "uniprot": "A0A077ZGK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGK3"
  },
  {
    "uniprot": "A0A077ZH21",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH21"
  },
  {
    "uniprot": "A0A077Z472",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z472"
  },
  {
    "uniprot": "A0A077YVQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVQ4"
  },
  {
    "uniprot": "A0A077YYQ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYQ6"
  },
  {
    "uniprot": "A0A077Z8N1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8N1"
  },
  {
    "uniprot": "A0A077Z9W6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9W6"
  },
  {
    "uniprot": "A0A077ZFE1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFE1"
  },
  {
    "uniprot": "A0A077ZCI6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCI6"
  },
  {
    "uniprot": "A0A077ZEV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEV9"
  },
  {
    "uniprot": "A0A077Z3A4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3A4"
  },
  {
    "uniprot": "A0A077Z712",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z712"
  },
  {
    "uniprot": "A0A077ZPP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPP8"
  },
  {
    "uniprot": "A0A077ZJ12",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ12"
  },
  {
    "uniprot": "A0A077ZLJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLJ3"
  },
  {
    "uniprot": "A0A077Z2A0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2A0"
  },
  {
    "uniprot": "A0A077Z7E7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7E7"
  },
  {
    "uniprot": "A0A077Z871",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z871"
  },
  {
    "uniprot": "A0A077Z0L6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0L6"
  },
  {
    "uniprot": "A0A077ZCH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCH5"
  },
  {
    "uniprot": "A0A077Z4F1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4F1"
  },
  {
    "uniprot": "A0A077Z005",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z005"
  },
  {
    "uniprot": "A0A077YVL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVL7"
  },
  {
    "uniprot": "A0A077ZJK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJK3"
  },
  {
    "uniprot": "A0A077ZFG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFG9"
  },
  {
    "uniprot": "A0A077ZLP7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLP7"
  },
  {
    "uniprot": "A0A077Z8U3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8U3"
  },
  {
    "uniprot": "A0A077ZGS6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGS6"
  },
  {
    "uniprot": "A0A077ZAG1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAG1"
  },
  {
    "uniprot": "A0A077Z6K8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6K8"
  },
  {
    "uniprot": "A0A077Z8S3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8S3"
  },
  {
    "uniprot": "A0A077ZAL3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAL3"
  },
  {
    "uniprot": "A0A077ZJA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJA8"
  },
  {
    "uniprot": "A0A077Z3W4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3W4"
  },
  {
    "uniprot": "A0A077Z260",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z260"
  },
  {
    "uniprot": "A0A077ZA28",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA28"
  },
  {
    "uniprot": "A0A077ZIT7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIT7"
  },
  {
    "uniprot": "A0A077Z2A3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2A3"
  },
  {
    "uniprot": "A0A077ZMW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMW2"
  },
  {
    "uniprot": "A0A077Z9D3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9D3"
  },
  {
    "uniprot": "A0A077ZIJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIJ3"
  },
  {
    "uniprot": "A0A077Z7B9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7B9"
  },
  {
    "uniprot": "A0A077ZHS6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHS6"
  },
  {
    "uniprot": "A0A077Z2T8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2T8"
  },
  {
    "uniprot": "A0A077YXM0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXM0"
  },
  {
    "uniprot": "A0A077Z3S2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3S2"
  },
  {
    "uniprot": "A0A077ZQD9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQD9"
  },
  {
    "uniprot": "A0A077YWM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWM8"
  },
  {
    "uniprot": "A0A077ZD32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD32"
  },
  {
    "uniprot": "A0A077ZIC2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIC2"
  },
  {
    "uniprot": "A0A077ZMF3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMF3"
  },
  {
    "uniprot": "A0A077ZA22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA22"
  },
  {
    "uniprot": "A0A077ZIT4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIT4"
  },
  {
    "uniprot": "A0A077Z2I8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2I8"
  },
  {
    "uniprot": "A0A077YW45",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW45"
  },
  {
    "uniprot": "A0A077YXL2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXL2"
  },
  {
    "uniprot": "A0A077ZB47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB47"
  },
  {
    "uniprot": "A0A077ZBR1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBR1"
  },
  {
    "uniprot": "A0A077ZAS5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAS5"
  },
  {
    "uniprot": "A0A077Z886",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z886"
  },
  {
    "uniprot": "A0A077Z6K2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6K2"
  },
  {
    "uniprot": "A0A077ZLL4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLL4"
  },
  {
    "uniprot": "A0A077ZG34",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG34"
  },
  {
    "uniprot": "A0A077Z0P6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0P6"
  },
  {
    "uniprot": "A0A077ZBG4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBG4"
  },
  {
    "uniprot": "A0A077ZLV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLV3"
  },
  {
    "uniprot": "A0A077ZGZ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGZ6"
  },
  {
    "uniprot": "A0A077YZN8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZN8"
  },
  {
    "uniprot": "A0A077YZY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZY8"
  },
  {
    "uniprot": "A0A077ZCK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCK3"
  },
  {
    "uniprot": "A0A077Z5G6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5G6"
  },
  {
    "uniprot": "A0A077YZ66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ66"
  },
  {
    "uniprot": "A0A077ZKX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKX6"
  },
  {
    "uniprot": "A0A077ZAE7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAE7"
  },
  {
    "uniprot": "A0A077ZKI4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKI4"
  },
  {
    "uniprot": "A0A077ZM24",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM24"
  },
  {
    "uniprot": "A0A077ZIG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIG5"
  },
  {
    "uniprot": "A0A077ZEF1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEF1"
  },
  {
    "uniprot": "A0A077ZHF2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHF2"
  },
  {
    "uniprot": "A0A077ZFL1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFL1"
  },
  {
    "uniprot": "A0A077ZGI1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGI1"
  },
  {
    "uniprot": "A0A077ZJ75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ75"
  },
  {
    "uniprot": "A0A077YXY7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXY7"
  },
  {
    "uniprot": "A0A077ZGK8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGK8"
  },
  {
    "uniprot": "A0A077ZB60",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB60"
  },
  {
    "uniprot": "A0A077Z4U1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4U1"
  },
  {
    "uniprot": "A0A077ZF35",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF35"
  },
  {
    "uniprot": "A0A077Z6S7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6S7"
  },
  {
    "uniprot": "A0A077ZAG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAG7"
  },
  {
    "uniprot": "A0A077Z074",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z074"
  },
  {
    "uniprot": "A0A077ZA81",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA81"
  },
  {
    "uniprot": "A0A077ZHR0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHR0"
  },
  {
    "uniprot": "A0A077ZH57",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH57"
  },
  {
    "uniprot": "A0A077ZGK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGK9"
  },
  {
    "uniprot": "A0A077ZFT0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFT0"
  },
  {
    "uniprot": "A0A077ZM74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM74"
  },
  {
    "uniprot": "A0A077ZEY7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEY7"
  },
  {
    "uniprot": "A0A077Z3R9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3R9"
  },
  {
    "uniprot": "A0A077ZBV5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBV5"
  },
  {
    "uniprot": "A0A077ZF65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF65"
  },
  {
    "uniprot": "A0A077YZQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZQ8"
  },
  {
    "uniprot": "A0A077Z8F2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8F2"
  },
  {
    "uniprot": "A0A077ZER7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZER7"
  },
  {
    "uniprot": "A0A077Z2E0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2E0"
  },
  {
    "uniprot": "A0A077ZGP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGP3"
  },
  {
    "uniprot": "A0A077ZKD6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKD6"
  },
  {
    "uniprot": "A0A077Z8V7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8V7"
  },
  {
    "uniprot": "A0A077ZCA6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCA6"
  },
  {
    "uniprot": "A0A077ZBQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBQ0"
  },
  {
    "uniprot": "A0A077Z3N8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3N8"
  },
  {
    "uniprot": "A0A077ZK60",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK60"
  },
  {
    "uniprot": "A0A077ZA43",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA43"
  },
  {
    "uniprot": "A0A077Z3H9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3H9"
  },
  {
    "uniprot": "A0A077ZQX1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQX1"
  },
  {
    "uniprot": "A0A077ZJF2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJF2"
  },
  {
    "uniprot": "A0A077Z243",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z243"
  },
  {
    "uniprot": "A0A077ZDK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDK4"
  },
  {
    "uniprot": "A0A077Z6G8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6G8"
  },
  {
    "uniprot": "A0A077ZA71",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA71"
  },
  {
    "uniprot": "A0A077YWK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWK4"
  },
  {
    "uniprot": "A0A077ZEQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEQ0"
  },
  {
    "uniprot": "A0A077Z1J6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1J6"
  },
  {
    "uniprot": "A0A077ZI49",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI49"
  },
  {
    "uniprot": "A0A077ZMU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMU2"
  },
  {
    "uniprot": "A0A077ZI86",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI86"
  },
  {
    "uniprot": "A0A077ZK81",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK81"
  },
  {
    "uniprot": "A0A077ZHI0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHI0"
  },
  {
    "uniprot": "A0A077YW42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW42"
  },
  {
    "uniprot": "A0A077YY96",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY96"
  },
  {
    "uniprot": "A0A077Z0C4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0C4"
  },
  {
    "uniprot": "A0A077Z9F7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9F7"
  },
  {
    "uniprot": "A0A077Z7Z8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Z8"
  },
  {
    "uniprot": "A0A077YXB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXB6"
  },
  {
    "uniprot": "A0A077YWV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWV6"
  },
  {
    "uniprot": "A0A077Z6E4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6E4"
  },
  {
    "uniprot": "A0A077ZJ41",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ41"
  },
  {
    "uniprot": "A0A077Z011",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z011"
  },
  {
    "uniprot": "A0A077ZDV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDV4"
  },
  {
    "uniprot": "A0A077ZHF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHF4"
  },
  {
    "uniprot": "A0A077ZCP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCP2"
  },
  {
    "uniprot": "A0A077Z425",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z425"
  },
  {
    "uniprot": "A0A077ZBP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBP2"
  },
  {
    "uniprot": "A0A077ZD37",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD37"
  },
  {
    "uniprot": "A0A077ZG94",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG94"
  },
  {
    "uniprot": "A0A077YZH6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZH6"
  },
  {
    "uniprot": "A0A077ZIQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIQ2"
  },
  {
    "uniprot": "A0A077ZIX4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIX4"
  },
  {
    "uniprot": "A0A077ZLS8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLS8"
  },
  {
    "uniprot": "A0A077ZN79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN79"
  },
  {
    "uniprot": "A0A077ZHK1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHK1"
  },
  {
    "uniprot": "A0A077Z9T3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9T3"
  },
  {
    "uniprot": "A0A077ZQK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQK6"
  },
  {
    "uniprot": "A0A077ZBF5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBF5"
  },
  {
    "uniprot": "A0A077YYJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYJ2"
  },
  {
    "uniprot": "A0A077ZR80",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR80"
  },
  {
    "uniprot": "A0A077ZEP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEP9"
  },
  {
    "uniprot": "A0A077ZBR7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBR7"
  },
  {
    "uniprot": "A0A077ZK68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK68"
  },
  {
    "uniprot": "A0A077ZEN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEN2"
  },
  {
    "uniprot": "A0A077ZDD2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDD2"
  },
  {
    "uniprot": "A0A077Z6U9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6U9"
  },
  {
    "uniprot": "A0A077ZEF5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEF5"
  },
  {
    "uniprot": "A0A077Z3Z4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Z4"
  },
  {
    "uniprot": "A0A077ZGR0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGR0"
  },
  {
    "uniprot": "A0A077ZM91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM91"
  },
  {
    "uniprot": "A0A077ZKA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKA8"
  },
  {
    "uniprot": "A0A077Z0Q5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Q5"
  },
  {
    "uniprot": "A0A077ZB50",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB50"
  },
  {
    "uniprot": "A0A077ZEU3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEU3"
  },
  {
    "uniprot": "A0A077YZG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZG3"
  },
  {
    "uniprot": "A0A077ZLD1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLD1"
  },
  {
    "uniprot": "A0A077ZM21",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM21"
  },
  {
    "uniprot": "A0A077Z938",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z938"
  },
  {
    "uniprot": "A0A077ZC53",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC53"
  },
  {
    "uniprot": "A0A077ZLS6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLS6"
  },
  {
    "uniprot": "A0A077Z234",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z234"
  },
  {
    "uniprot": "A0A077ZIW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIW2"
  },
  {
    "uniprot": "A0A077ZJT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJT9"
  },
  {
    "uniprot": "A0A077Z4M7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4M7"
  },
  {
    "uniprot": "A0A077ZRA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZRA8"
  },
  {
    "uniprot": "A0A077ZDG2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDG2"
  },
  {
    "uniprot": "A0A077ZJP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJP4"
  },
  {
    "uniprot": "A0A077ZNB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNB9"
  },
  {
    "uniprot": "A0A077Z6H4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6H4"
  },
  {
    "uniprot": "A0A077YZ17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ17"
  },
  {
    "uniprot": "A0A077ZAP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAP8"
  },
  {
    "uniprot": "A0A077Z6L7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6L7"
  },
  {
    "uniprot": "A0A077Z8W8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8W8"
  },
  {
    "uniprot": "A0A077YYC0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYC0"
  },
  {
    "uniprot": "A0A077ZLJ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLJ9"
  },
  {
    "uniprot": "A0A077ZC13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC13"
  },
  {
    "uniprot": "A0A077ZHU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHU2"
  },
  {
    "uniprot": "A0A077Z2V1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2V1"
  },
  {
    "uniprot": "A0A077ZIY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIY8"
  },
  {
    "uniprot": "A0A077Z5D2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5D2"
  },
  {
    "uniprot": "A0A077YYB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYB2"
  },
  {
    "uniprot": "A0A077ZPK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPK9"
  },
  {
    "uniprot": "A0A077Z7E8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7E8"
  },
  {
    "uniprot": "A0A077ZDP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDP8"
  },
  {
    "uniprot": "A0A077ZH14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH14"
  },
  {
    "uniprot": "A0A077ZL33",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL33"
  },
  {
    "uniprot": "A0A077ZED6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZED6"
  },
  {
    "uniprot": "A0A077YZP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZP0"
  },
  {
    "uniprot": "A0A077YW44",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW44"
  },
  {
    "uniprot": "A0A077ZH82",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH82"
  },
  {
    "uniprot": "A0A077Z1U3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1U3"
  },
  {
    "uniprot": "A0A077ZG67",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG67"
  },
  {
    "uniprot": "A0A077ZI98",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI98"
  },
  {
    "uniprot": "A0A077YX57",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX57"
  },
  {
    "uniprot": "A0A077Z371",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z371"
  },
  {
    "uniprot": "A0A077ZGV7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGV7"
  },
  {
    "uniprot": "A0A077Z7Q7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Q7"
  },
  {
    "uniprot": "A0A077ZI51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI51"
  },
  {
    "uniprot": "A0A077Z6U0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6U0"
  },
  {
    "uniprot": "A0A077ZC96",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC96"
  },
  {
    "uniprot": "A0A077YXI4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXI4"
  },
  {
    "uniprot": "A0A077ZCR5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCR5"
  },
  {
    "uniprot": "A0A077ZDA0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDA0"
  },
  {
    "uniprot": "A0A077ZC66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC66"
  },
  {
    "uniprot": "A0A077ZKA7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKA7"
  },
  {
    "uniprot": "A0A077Z8T0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8T0"
  },
  {
    "uniprot": "A0A077Z457",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z457"
  },
  {
    "uniprot": "A0A077Z1X7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1X7"
  },
  {
    "uniprot": "A0A077ZMS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMS9"
  },
  {
    "uniprot": "A0A077ZL38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL38"
  },
  {
    "uniprot": "A0A077YZQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZQ2"
  },
  {
    "uniprot": "A0A077ZC77",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC77"
  },
  {
    "uniprot": "A0A077ZH08",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH08"
  },
  {
    "uniprot": "A0A077Z5B4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5B4"
  },
  {
    "uniprot": "A0A077ZL13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL13"
  },
  {
    "uniprot": "A0A077ZIN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIN2"
  },
  {
    "uniprot": "A0A077ZFB7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFB7"
  },
  {
    "uniprot": "A0A077ZI96",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI96"
  },
  {
    "uniprot": "A0A077Z144",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z144"
  },
  {
    "uniprot": "A0A077Z207",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z207"
  },
  {
    "uniprot": "A0A077YXB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXB9"
  },
  {
    "uniprot": "A0A077ZJ76",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ76"
  },
  {
    "uniprot": "A0A077ZEH4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEH4"
  },
  {
    "uniprot": "A0A077ZH44",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH44"
  },
  {
    "uniprot": "A0A077Z4H9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4H9"
  },
  {
    "uniprot": "A0A077Z6N5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6N5"
  },
  {
    "uniprot": "A0A077YVN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVN1"
  },
  {
    "uniprot": "A0A077ZBW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBW9"
  },
  {
    "uniprot": "A0A077ZNG0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNG0"
  },
  {
    "uniprot": "A0A077YXK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXK4"
  },
  {
    "uniprot": "A0A077Z134",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z134"
  },
  {
    "uniprot": "A0A077ZK25",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK25"
  },
  {
    "uniprot": "A0A077ZL81",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL81"
  },
  {
    "uniprot": "A0A077YZ44",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ44"
  },
  {
    "uniprot": "A0A077Z912",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z912"
  },
  {
    "uniprot": "A0A077ZBM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBM1"
  },
  {
    "uniprot": "A0A077ZFP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFP8"
  },
  {
    "uniprot": "A0A077Z8J5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8J5"
  },
  {
    "uniprot": "A0A077YW38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW38"
  },
  {
    "uniprot": "A0A077ZKA9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKA9"
  },
  {
    "uniprot": "A0A077ZL14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL14"
  },
  {
    "uniprot": "A0A077Z4Y4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Y4"
  },
  {
    "uniprot": "A0A077ZI35",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI35"
  },
  {
    "uniprot": "A0A077ZQW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQW8"
  },
  {
    "uniprot": "A0A077Z5J7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5J7"
  },
  {
    "uniprot": "A0A077ZCQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCQ5"
  },
  {
    "uniprot": "A0A077ZCW3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCW3"
  },
  {
    "uniprot": "A0A077ZL96",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL96"
  },
  {
    "uniprot": "A0A077ZAC0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAC0"
  },
  {
    "uniprot": "A0A077YZ95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ95"
  },
  {
    "uniprot": "A0A077Z5C9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5C9"
  },
  {
    "uniprot": "A0A077ZBI1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBI1"
  },
  {
    "uniprot": "A0A077ZKM4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKM4"
  },
  {
    "uniprot": "A0A077Z933",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z933"
  },
  {
    "uniprot": "A0A077ZEX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEX3"
  },
  {
    "uniprot": "A0A077ZB74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB74"
  },
  {
    "uniprot": "A0A077ZPA4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPA4"
  },
  {
    "uniprot": "A0A077Z2B7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2B7"
  },
  {
    "uniprot": "A0A077YXN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXN3"
  },
  {
    "uniprot": "A0A077Z8Z1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Z1"
  },
  {
    "uniprot": "A0A077Z583",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z583"
  },
  {
    "uniprot": "A0A077Z0V0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0V0"
  },
  {
    "uniprot": "A0A077ZHK8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHK8"
  },
  {
    "uniprot": "A0A077ZBZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBZ9"
  },
  {
    "uniprot": "A0A077ZB90",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB90"
  },
  {
    "uniprot": "A0A077ZIP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIP9"
  },
  {
    "uniprot": "A0A077ZA41",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA41"
  },
  {
    "uniprot": "A0A077ZM40",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM40"
  },
  {
    "uniprot": "A0A077ZC69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC69"
  },
  {
    "uniprot": "A0A077ZL98",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL98"
  },
  {
    "uniprot": "A0A077Z7I2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7I2"
  },
  {
    "uniprot": "A0A077ZEU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEU6"
  },
  {
    "uniprot": "A0A077YWU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWU5"
  },
  {
    "uniprot": "A0A077Z7B7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7B7"
  },
  {
    "uniprot": "A0A077YW41",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW41"
  },
  {
    "uniprot": "A0A077ZMT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMT9"
  },
  {
    "uniprot": "A0A077YWN2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWN2"
  },
  {
    "uniprot": "A0A077ZLE3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLE3"
  },
  {
    "uniprot": "A0A077YX93",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX93"
  },
  {
    "uniprot": "A0A077ZDG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDG3"
  },
  {
    "uniprot": "A0A077ZHF7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHF7"
  },
  {
    "uniprot": "A0A077YZD1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZD1"
  },
  {
    "uniprot": "A0A077Z880",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z880"
  },
  {
    "uniprot": "A0A077ZBI4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBI4"
  },
  {
    "uniprot": "A0A077YXD3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXD3"
  },
  {
    "uniprot": "A0A077ZLH4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLH4"
  },
  {
    "uniprot": "A0A077Z7X7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7X7"
  },
  {
    "uniprot": "A0A077Z6S0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6S0"
  },
  {
    "uniprot": "A0A077Z704",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z704"
  },
  {
    "uniprot": "A0A077ZDJ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDJ9"
  },
  {
    "uniprot": "A0A077Z6C6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6C6"
  },
  {
    "uniprot": "A0A077Z1R1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1R1"
  },
  {
    "uniprot": "A0A077ZHI6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHI6"
  },
  {
    "uniprot": "A0A077Z901",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z901"
  },
  {
    "uniprot": "A0A077Z2F5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2F5"
  },
  {
    "uniprot": "A0A077Z617",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z617"
  },
  {
    "uniprot": "A0A077ZJ22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ22"
  },
  {
    "uniprot": "A0A077Z7N7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7N7"
  },
  {
    "uniprot": "A0A077ZJ27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ27"
  },
  {
    "uniprot": "A0A077Z806",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z806"
  },
  {
    "uniprot": "A0A077YZC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZC3"
  },
  {
    "uniprot": "A0A077ZB63",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB63"
  },
  {
    "uniprot": "A0A077ZD72",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD72"
  },
  {
    "uniprot": "A0A077ZCM3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCM3"
  },
  {
    "uniprot": "A0A077ZE29",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE29"
  },
  {
    "uniprot": "A0A077YZ98",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ98"
  },
  {
    "uniprot": "A0A077YZ71",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ71"
  },
  {
    "uniprot": "A0A077Z8J2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8J2"
  },
  {
    "uniprot": "A0A077ZLY7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLY7"
  },
  {
    "uniprot": "A0A077Z165",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z165"
  },
  {
    "uniprot": "A0A077Z212",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z212"
  },
  {
    "uniprot": "A0A077ZKY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKY9"
  },
  {
    "uniprot": "A0A077ZGR5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGR5"
  },
  {
    "uniprot": "A0A077ZBI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBI8"
  },
  {
    "uniprot": "A0A077ZLA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLA5"
  },
  {
    "uniprot": "A0A077Z6Q0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Q0"
  },
  {
    "uniprot": "A0A077ZAZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAZ1"
  },
  {
    "uniprot": "A0A077YYU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYU1"
  },
  {
    "uniprot": "A0A077ZKL3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKL3"
  },
  {
    "uniprot": "A0A077ZBS0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBS0"
  },
  {
    "uniprot": "A0A077ZE19",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE19"
  },
  {
    "uniprot": "A0A077Z1I3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1I3"
  },
  {
    "uniprot": "A0A077ZPC6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPC6"
  },
  {
    "uniprot": "A0A077ZGR2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGR2"
  },
  {
    "uniprot": "A0A077ZKP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKP2"
  },
  {
    "uniprot": "A0A077Z5Q5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Q5"
  },
  {
    "uniprot": "A0A077ZGV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGV4"
  },
  {
    "uniprot": "A0A077ZHH0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHH0"
  },
  {
    "uniprot": "A0A077ZDE3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDE3"
  },
  {
    "uniprot": "A0A077ZQP7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQP7"
  },
  {
    "uniprot": "A0A077Z4E4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4E4"
  },
  {
    "uniprot": "A0A077Z2P0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2P0"
  },
  {
    "uniprot": "A0A077Z1V3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1V3"
  },
  {
    "uniprot": "A0A077ZET1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZET1"
  },
  {
    "uniprot": "A0A077Z551",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z551"
  },
  {
    "uniprot": "A0A077Z299",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z299"
  },
  {
    "uniprot": "A0A077ZCW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCW2"
  },
  {
    "uniprot": "A0A077ZN70",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN70"
  },
  {
    "uniprot": "A0A077YXV2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXV2"
  },
  {
    "uniprot": "A0A077ZJ57",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ57"
  },
  {
    "uniprot": "A0A077Z156",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z156"
  },
  {
    "uniprot": "A0A077Z6P8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6P8"
  },
  {
    "uniprot": "A0A077Z3V6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3V6"
  },
  {
    "uniprot": "A0A077ZA14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA14"
  },
  {
    "uniprot": "A0A077YYI6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYI6"
  },
  {
    "uniprot": "A0A077Z043",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z043"
  },
  {
    "uniprot": "A0A077Z2G5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2G5"
  },
  {
    "uniprot": "A0A077Z3Y0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Y0"
  },
  {
    "uniprot": "A0A077ZHR3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHR3"
  },
  {
    "uniprot": "A0A077Z6F9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6F9"
  },
  {
    "uniprot": "A0A077ZLK0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLK0"
  },
  {
    "uniprot": "A0A077YV43",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV43"
  },
  {
    "uniprot": "A0A077ZC87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC87"
  },
  {
    "uniprot": "A0A077Z2K7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2K7"
  },
  {
    "uniprot": "A0A077Z822",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z822"
  },
  {
    "uniprot": "A0A077Z4G2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4G2"
  },
  {
    "uniprot": "A0A077ZLI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLI3"
  },
  {
    "uniprot": "A0A077Z009",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z009"
  },
  {
    "uniprot": "A0A077Z039",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z039"
  },
  {
    "uniprot": "A0A077ZDV8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDV8"
  },
  {
    "uniprot": "A0A077ZB85",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB85"
  },
  {
    "uniprot": "A0A077YY28",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY28"
  },
  {
    "uniprot": "A0A077ZIH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIH1"
  },
  {
    "uniprot": "A0A077ZM64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM64"
  },
  {
    "uniprot": "A0A077ZAH0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAH0"
  },
  {
    "uniprot": "A0A077ZHT3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHT3"
  },
  {
    "uniprot": "A0A077ZAA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAA5"
  },
  {
    "uniprot": "A0A077ZJD6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJD6"
  },
  {
    "uniprot": "A0A077Z9D8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9D8"
  },
  {
    "uniprot": "A0A077ZPJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPJ7"
  },
  {
    "uniprot": "A0A077ZCB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCB9"
  },
  {
    "uniprot": "A0A077ZEZ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEZ2"
  },
  {
    "uniprot": "A0A077Z5P8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5P8"
  },
  {
    "uniprot": "A0A077ZCV0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCV0"
  },
  {
    "uniprot": "A0A077Z988",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z988"
  },
  {
    "uniprot": "A0A077Z5L3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5L3"
  },
  {
    "uniprot": "A0A077YZW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZW5"
  },
  {
    "uniprot": "A0A077Z0M8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0M8"
  },
  {
    "uniprot": "A0A077ZBW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBW8"
  },
  {
    "uniprot": "A0A077Z8X8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8X8"
  },
  {
    "uniprot": "A0A077YX89",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX89"
  },
  {
    "uniprot": "A0A077ZJ07",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ07"
  },
  {
    "uniprot": "A0A077ZAW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAW7"
  },
  {
    "uniprot": "A0A077ZB75",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB75"
  },
  {
    "uniprot": "A0A077Z1T1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1T1"
  },
  {
    "uniprot": "A0A077Z695",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z695"
  },
  {
    "uniprot": "A0A077Z9S5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9S5"
  },
  {
    "uniprot": "A0A077YWW3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWW3"
  },
  {
    "uniprot": "A0A077ZGH7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGH7"
  },
  {
    "uniprot": "A0A077Z4C9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4C9"
  },
  {
    "uniprot": "A0A077ZIC4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIC4"
  },
  {
    "uniprot": "A0A077Z1K2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1K2"
  },
  {
    "uniprot": "A0A077ZMN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMN1"
  },
  {
    "uniprot": "A0A077ZGU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGU9"
  },
  {
    "uniprot": "A0A077ZLP1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLP1"
  },
  {
    "uniprot": "A0A077ZGZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGZ8"
  },
  {
    "uniprot": "A0A077ZDR3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDR3"
  },
  {
    "uniprot": "A0A077ZQQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQQ5"
  },
  {
    "uniprot": "A0A077ZG80",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG80"
  },
  {
    "uniprot": "A0A077ZJA2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJA2"
  },
  {
    "uniprot": "A0A077Z0T3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0T3"
  },
  {
    "uniprot": "A0A077ZGK5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGK5"
  },
  {
    "uniprot": "A0A077ZLW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLW8"
  },
  {
    "uniprot": "A0A077ZDB7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDB7"
  },
  {
    "uniprot": "A0A077ZHK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHK6"
  },
  {
    "uniprot": "A0A077ZJS3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJS3"
  },
  {
    "uniprot": "A0A077YZW4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZW4"
  },
  {
    "uniprot": "A0A077Z3G1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3G1"
  },
  {
    "uniprot": "A0A077YYC7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYC7"
  },
  {
    "uniprot": "A0A077ZIP6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIP6"
  },
  {
    "uniprot": "A0A077YYY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYY8"
  },
  {
    "uniprot": "A0A077ZDI4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDI4"
  },
  {
    "uniprot": "A0A077ZFK5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFK5"
  },
  {
    "uniprot": "A0A077ZK26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK26"
  },
  {
    "uniprot": "A0A077ZF93",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF93"
  },
  {
    "uniprot": "A0A077Z3Q3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Q3"
  },
  {
    "uniprot": "A0A077YYL5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYL5"
  },
  {
    "uniprot": "A0A077YXN8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXN8"
  },
  {
    "uniprot": "A0A077Z6F4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6F4"
  },
  {
    "uniprot": "A0A077YXW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXW5"
  },
  {
    "uniprot": "A0A077ZL29",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL29"
  },
  {
    "uniprot": "A0A077ZFK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFK3"
  },
  {
    "uniprot": "A0A077Z5S9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5S9"
  },
  {
    "uniprot": "A0A077Z5B6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5B6"
  },
  {
    "uniprot": "A0A077ZDI9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDI9"
  },
  {
    "uniprot": "A0A077ZDZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDZ1"
  },
  {
    "uniprot": "A0A077YZX2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZX2"
  },
  {
    "uniprot": "A0A077Z432",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z432"
  },
  {
    "uniprot": "A0A077ZPT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPT8"
  },
  {
    "uniprot": "A0A077Z4A7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4A7"
  },
  {
    "uniprot": "A0A077ZD41",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD41"
  },
  {
    "uniprot": "A0A077YZ55",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ55"
  },
  {
    "uniprot": "A0A077YWL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWL7"
  },
  {
    "uniprot": "A0A077Z0K1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0K1"
  },
  {
    "uniprot": "A0A077ZQ31",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ31"
  },
  {
    "uniprot": "A0A077YY40",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY40"
  },
  {
    "uniprot": "A0A077Z737",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z737"
  },
  {
    "uniprot": "A0A077ZME7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZME7"
  },
  {
    "uniprot": "A0A077ZK79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK79"
  },
  {
    "uniprot": "A0A077Z5S1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5S1"
  },
  {
    "uniprot": "A0A077YX42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX42"
  },
  {
    "uniprot": "A0A077ZR17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR17"
  },
  {
    "uniprot": "A0A077Z6Y5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Y5"
  },
  {
    "uniprot": "A0A077YY15",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY15"
  },
  {
    "uniprot": "A0A077ZB44",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB44"
  },
  {
    "uniprot": "A0A077Z3Z6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Z6"
  },
  {
    "uniprot": "A0A077Z934",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z934"
  },
  {
    "uniprot": "A0A077Z8P0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8P0"
  },
  {
    "uniprot": "A0A077YY84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY84"
  },
  {
    "uniprot": "A0A077ZEI6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEI6"
  },
  {
    "uniprot": "A0A077ZK22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK22"
  },
  {
    "uniprot": "A0A077ZDV5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDV5"
  },
  {
    "uniprot": "A0A077YXE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXE2"
  },
  {
    "uniprot": "A0A077ZGY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGY0"
  },
  {
    "uniprot": "A0A077ZDZ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDZ7"
  },
  {
    "uniprot": "A0A077ZAJ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAJ1"
  },
  {
    "uniprot": "A0A077Z4S0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4S0"
  },
  {
    "uniprot": "A0A077ZKA4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKA4"
  },
  {
    "uniprot": "A0A077ZMA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMA1"
  },
  {
    "uniprot": "A0A077Z967",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z967"
  },
  {
    "uniprot": "A0A077ZFF5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFF5"
  },
  {
    "uniprot": "A0A077ZIG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIG3"
  },
  {
    "uniprot": "A0A077YYV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYV1"
  },
  {
    "uniprot": "A0A077ZJ89",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ89"
  },
  {
    "uniprot": "A0A077ZE15",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE15"
  },
  {
    "uniprot": "A0A077Z9D0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9D0"
  },
  {
    "uniprot": "A0A077YY51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY51"
  },
  {
    "uniprot": "A0A077ZJ60",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ60"
  },
  {
    "uniprot": "A0A077ZFX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFX6"
  },
  {
    "uniprot": "A0A077ZKG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKG9"
  },
  {
    "uniprot": "A0A077Z9T0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9T0"
  },
  {
    "uniprot": "A0A077ZMI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMI3"
  },
  {
    "uniprot": "A0A077Z0A5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0A5"
  },
  {
    "uniprot": "A0A077Z805",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z805"
  },
  {
    "uniprot": "A0A077ZDS0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDS0"
  },
  {
    "uniprot": "A0A077YZU8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZU8"
  },
  {
    "uniprot": "A0A077ZDD4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDD4"
  },
  {
    "uniprot": "A0A077ZAI2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAI2"
  },
  {
    "uniprot": "A0A077Z5Z9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Z9"
  },
  {
    "uniprot": "A0A077Z7A3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7A3"
  },
  {
    "uniprot": "A0A077ZKX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKX0"
  },
  {
    "uniprot": "A0A077Z030",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z030"
  },
  {
    "uniprot": "A0A077ZI68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI68"
  },
  {
    "uniprot": "A0A077Z547",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z547"
  },
  {
    "uniprot": "A0A077Z986",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z986"
  },
  {
    "uniprot": "A0A077ZG74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG74"
  },
  {
    "uniprot": "A0A077ZKF5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKF5"
  },
  {
    "uniprot": "A0A077YVT7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVT7"
  },
  {
    "uniprot": "A0A077ZJM4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJM4"
  },
  {
    "uniprot": "A0A077ZE46",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE46"
  },
  {
    "uniprot": "A0A077YWQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWQ2"
  },
  {
    "uniprot": "A0A077ZG21",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG21"
  },
  {
    "uniprot": "A0A077ZQR8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQR8"
  },
  {
    "uniprot": "A0A077YXE9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXE9"
  },
  {
    "uniprot": "A0A077ZLJ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLJ1"
  },
  {
    "uniprot": "A0A077ZJ96",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ96"
  },
  {
    "uniprot": "A0A077Z588",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z588"
  },
  {
    "uniprot": "A0A077ZGW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGW8"
  },
  {
    "uniprot": "A0A077ZBY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBY1"
  },
  {
    "uniprot": "A0A077ZFN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFN6"
  },
  {
    "uniprot": "A0A077ZG59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG59"
  },
  {
    "uniprot": "A0A077YWY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWY3"
  },
  {
    "uniprot": "A0A077Z716",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z716"
  },
  {
    "uniprot": "A0A077ZJD5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJD5"
  },
  {
    "uniprot": "A0A077ZA78",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA78"
  },
  {
    "uniprot": "A0A077ZA08",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA08"
  },
  {
    "uniprot": "A0A077Z849",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z849"
  },
  {
    "uniprot": "A0A077ZN05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN05"
  },
  {
    "uniprot": "A0A077ZAR1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAR1"
  },
  {
    "uniprot": "A0A077ZKU0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKU0"
  },
  {
    "uniprot": "A0A077ZI07",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI07"
  },
  {
    "uniprot": "A0A077Z1W7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1W7"
  },
  {
    "uniprot": "A0A077ZC80",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC80"
  },
  {
    "uniprot": "A0A077Z834",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z834"
  },
  {
    "uniprot": "A0A077ZJ91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ91"
  },
  {
    "uniprot": "A0A077YZP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZP3"
  },
  {
    "uniprot": "A0A077Z2B0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2B0"
  },
  {
    "uniprot": "A0A077Z8N7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8N7"
  },
  {
    "uniprot": "A0A077Z0Y8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Y8"
  },
  {
    "uniprot": "A0A077Z7C9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7C9"
  },
  {
    "uniprot": "A0A077Z5I3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5I3"
  },
  {
    "uniprot": "A0A077ZLR7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLR7"
  },
  {
    "uniprot": "A0A077Z4L3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4L3"
  },
  {
    "uniprot": "A0A077ZII2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZII2"
  },
  {
    "uniprot": "A0A077Z367",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z367"
  },
  {
    "uniprot": "A0A077Z2J0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2J0"
  },
  {
    "uniprot": "A0A077ZJJ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJJ4"
  },
  {
    "uniprot": "A0A077YXB4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXB4"
  },
  {
    "uniprot": "A0A077ZMQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMQ8"
  },
  {
    "uniprot": "A0A077ZKZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKZ3"
  },
  {
    "uniprot": "A0A077ZHW6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHW6"
  },
  {
    "uniprot": "A0A077ZJ29",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ29"
  },
  {
    "uniprot": "A0A077Z7J1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7J1"
  },
  {
    "uniprot": "A0A077ZMG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMG3"
  },
  {
    "uniprot": "A0A077Z0E0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0E0"
  },
  {
    "uniprot": "A0A077Z7M8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7M8"
  },
  {
    "uniprot": "A0A077Z5Y4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Y4"
  },
  {
    "uniprot": "A0A077ZJ50",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ50"
  },
  {
    "uniprot": "A0A077ZLG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLG5"
  },
  {
    "uniprot": "A0A077Z6L9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6L9"
  },
  {
    "uniprot": "A0A077ZB94",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB94"
  },
  {
    "uniprot": "A0A077Z531",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z531"
  },
  {
    "uniprot": "A0A077Z861",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z861"
  },
  {
    "uniprot": "A0A077ZF02",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF02"
  },
  {
    "uniprot": "A0A077Z1N7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1N7"
  },
  {
    "uniprot": "A0A077Z1V7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1V7"
  },
  {
    "uniprot": "A0A077ZC99",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC99"
  },
  {
    "uniprot": "A0A077ZN61",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN61"
  },
  {
    "uniprot": "A0A077ZIF3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIF3"
  },
  {
    "uniprot": "A0A077Z984",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z984"
  },
  {
    "uniprot": "A0A077YUT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUT9"
  },
  {
    "uniprot": "A0A077ZD69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD69"
  },
  {
    "uniprot": "A0A077ZEK0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEK0"
  },
  {
    "uniprot": "A0A077Z597",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z597"
  },
  {
    "uniprot": "A0A077ZA17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA17"
  },
  {
    "uniprot": "A0A077ZGZ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGZ0"
  },
  {
    "uniprot": "A0A077Z5T2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5T2"
  },
  {
    "uniprot": "A0A077ZER5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZER5"
  },
  {
    "uniprot": "A0A077Z9M8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9M8"
  },
  {
    "uniprot": "A0A077ZLH6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLH6"
  },
  {
    "uniprot": "A0A077ZHZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHZ4"
  },
  {
    "uniprot": "A0A077Z193",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z193"
  },
  {
    "uniprot": "A0A077ZCH1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCH1"
  },
  {
    "uniprot": "A0A077ZAM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAM6"
  },
  {
    "uniprot": "A0A077Z9Q4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Q4"
  },
  {
    "uniprot": "A0A077Z5L9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5L9"
  },
  {
    "uniprot": "A0A077ZH37",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH37"
  },
  {
    "uniprot": "A0A077Z4Y0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Y0"
  },
  {
    "uniprot": "A0A077YYN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYN1"
  },
  {
    "uniprot": "A0A077ZBF7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBF7"
  },
  {
    "uniprot": "A0A077Z949",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z949"
  },
  {
    "uniprot": "A0A077Z813",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z813"
  },
  {
    "uniprot": "A0A077ZNJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNJ7"
  },
  {
    "uniprot": "A0A077Z6K7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6K7"
  },
  {
    "uniprot": "A0A077ZA92",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA92"
  },
  {
    "uniprot": "A0A077ZJB8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJB8"
  },
  {
    "uniprot": "A0A077ZMM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMM2"
  },
  {
    "uniprot": "A0A077YZR0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZR0"
  },
  {
    "uniprot": "A0A077ZJW3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJW3"
  },
  {
    "uniprot": "A0A077YZR7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZR7"
  },
  {
    "uniprot": "A0A077YVM4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVM4"
  },
  {
    "uniprot": "A0A077ZLX4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLX4"
  },
  {
    "uniprot": "A0A077ZGB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGB6"
  },
  {
    "uniprot": "A0A077Z468",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z468"
  },
  {
    "uniprot": "A0A077Z3Y9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Y9"
  },
  {
    "uniprot": "A0A077ZCP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCP8"
  },
  {
    "uniprot": "A0A077ZAM9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAM9"
  },
  {
    "uniprot": "A0A077ZMY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMY4"
  },
  {
    "uniprot": "A0A077Z7T7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7T7"
  },
  {
    "uniprot": "A0A077ZL74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL74"
  },
  {
    "uniprot": "A0A077YWH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWH8"
  },
  {
    "uniprot": "A0A077Z7G9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7G9"
  },
  {
    "uniprot": "A0A077Z541",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z541"
  },
  {
    "uniprot": "A0A077ZFD4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFD4"
  },
  {
    "uniprot": "A0A077ZD92",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD92"
  },
  {
    "uniprot": "A0A077ZDR8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDR8"
  },
  {
    "uniprot": "A0A077ZHS4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHS4"
  },
  {
    "uniprot": "A0A077YV27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV27"
  },
  {
    "uniprot": "A0A077Z428",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z428"
  },
  {
    "uniprot": "A0A077YYS5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYS5"
  },
  {
    "uniprot": "A0A077ZQQ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQQ9"
  },
  {
    "uniprot": "A0A077Z1L1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1L1"
  },
  {
    "uniprot": "A0A077Z4D0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4D0"
  },
  {
    "uniprot": "A0A077YYL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYL7"
  },
  {
    "uniprot": "A0A077ZEU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEU1"
  },
  {
    "uniprot": "A0A077ZG57",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG57"
  },
  {
    "uniprot": "A0A077YXV7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXV7"
  },
  {
    "uniprot": "A0A077Z115",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z115"
  },
  {
    "uniprot": "A0A077Z923",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z923"
  },
  {
    "uniprot": "A0A077Z438",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z438"
  },
  {
    "uniprot": "A0A077Z5Q0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Q0"
  },
  {
    "uniprot": "A0A077ZGK2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGK2"
  },
  {
    "uniprot": "A0A077YWD1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWD1"
  },
  {
    "uniprot": "A0A077ZDX6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDX6"
  },
  {
    "uniprot": "A0A077YX18",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX18"
  },
  {
    "uniprot": "A0A077Z434",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z434"
  },
  {
    "uniprot": "A0A077Z5C7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5C7"
  },
  {
    "uniprot": "A0A077ZJW4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJW4"
  },
  {
    "uniprot": "A0A077ZD46",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD46"
  },
  {
    "uniprot": "A0A077ZIG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIG8"
  },
  {
    "uniprot": "A0A077Z904",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z904"
  },
  {
    "uniprot": "A0A077Z9L9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9L9"
  },
  {
    "uniprot": "A0A077YWZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWZ5"
  },
  {
    "uniprot": "A0A077Z9W0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9W0"
  },
  {
    "uniprot": "A0A077ZD44",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD44"
  },
  {
    "uniprot": "A0A077YYE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYE8"
  },
  {
    "uniprot": "A0A077Z3H2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3H2"
  },
  {
    "uniprot": "A0A077Z0J4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0J4"
  },
  {
    "uniprot": "A0A077YXZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXZ5"
  },
  {
    "uniprot": "A0A077Z0R9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0R9"
  },
  {
    "uniprot": "A0A077ZE92",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE92"
  },
  {
    "uniprot": "A0A077Z8M4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8M4"
  },
  {
    "uniprot": "A0A077Z7C2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7C2"
  },
  {
    "uniprot": "A0A077ZDG9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDG9"
  },
  {
    "uniprot": "A0A077ZBB8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBB8"
  },
  {
    "uniprot": "A0A077ZIK2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIK2"
  },
  {
    "uniprot": "A0A077YVC4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVC4"
  },
  {
    "uniprot": "A0A077Z4A0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4A0"
  },
  {
    "uniprot": "A0A077YZU6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZU6"
  },
  {
    "uniprot": "A0A077Z1V8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1V8"
  },
  {
    "uniprot": "A0A077ZJN8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJN8"
  },
  {
    "uniprot": "A0A077Z1W1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1W1"
  },
  {
    "uniprot": "A0A077ZAK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAK3"
  },
  {
    "uniprot": "A0A077Z7Y1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Y1"
  },
  {
    "uniprot": "A0A077ZM97",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM97"
  },
  {
    "uniprot": "A0A077ZQ10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ10"
  },
  {
    "uniprot": "A0A077YW91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW91"
  },
  {
    "uniprot": "A0A077Z6T6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6T6"
  },
  {
    "uniprot": "A0A077Z9I2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9I2"
  },
  {
    "uniprot": "A0A077ZHE6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHE6"
  },
  {
    "uniprot": "A0A077YYA4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYA4"
  },
  {
    "uniprot": "A0A077YZ37",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ37"
  },
  {
    "uniprot": "A0A077ZJ63",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ63"
  },
  {
    "uniprot": "A0A077YYY5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYY5"
  },
  {
    "uniprot": "A0A077ZF79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF79"
  },
  {
    "uniprot": "A0A077Z9A4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9A4"
  },
  {
    "uniprot": "A0A077Z959",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z959"
  },
  {
    "uniprot": "A0A077ZHT6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHT6"
  },
  {
    "uniprot": "A0A077ZC94",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC94"
  },
  {
    "uniprot": "A0A077ZAN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAN6"
  },
  {
    "uniprot": "A0A077ZDQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDQ5"
  },
  {
    "uniprot": "A0A077Z5H8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5H8"
  },
  {
    "uniprot": "A0A077YV69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV69"
  },
  {
    "uniprot": "A0A077ZN10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN10"
  },
  {
    "uniprot": "A0A077ZHZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHZ5"
  },
  {
    "uniprot": "A0A077Z7S6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7S6"
  },
  {
    "uniprot": "A0A077YV15",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV15"
  },
  {
    "uniprot": "A0A077Z3V1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3V1"
  },
  {
    "uniprot": "A0A077YXZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXZ4"
  },
  {
    "uniprot": "A0A077ZEM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEM1"
  },
  {
    "uniprot": "A0A077ZM32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM32"
  },
  {
    "uniprot": "A0A077Z559",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z559"
  },
  {
    "uniprot": "A0A077Z0U9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0U9"
  },
  {
    "uniprot": "A0A077ZFF6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFF6"
  },
  {
    "uniprot": "A0A077Z3K3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3K3"
  },
  {
    "uniprot": "A0A077ZD33",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD33"
  },
  {
    "uniprot": "A0A077ZAX2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAX2"
  },
  {
    "uniprot": "A0A077Z6X4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6X4"
  },
  {
    "uniprot": "A0A077ZL53",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL53"
  },
  {
    "uniprot": "A0A077YYD2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYD2"
  },
  {
    "uniprot": "A0A077ZJI2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJI2"
  },
  {
    "uniprot": "A0A077Z7U8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7U8"
  },
  {
    "uniprot": "A0A077ZJG4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJG4"
  },
  {
    "uniprot": "A0A077YZN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZN4"
  },
  {
    "uniprot": "A0A077ZJM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJM2"
  },
  {
    "uniprot": "A0A077ZN54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN54"
  },
  {
    "uniprot": "A0A077Z847",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z847"
  },
  {
    "uniprot": "A0A077Z5S6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5S6"
  },
  {
    "uniprot": "A0A077ZIK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIK7"
  },
  {
    "uniprot": "A0A077Z8E4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8E4"
  },
  {
    "uniprot": "A0A077Z0U8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0U8"
  },
  {
    "uniprot": "A0A077ZGQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGQ3"
  },
  {
    "uniprot": "A0A077Z792",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z792"
  },
  {
    "uniprot": "A0A077ZCE5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCE5"
  },
  {
    "uniprot": "A0A077Z172",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z172"
  },
  {
    "uniprot": "A0A077ZDC4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDC4"
  },
  {
    "uniprot": "A0A077YWT4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWT4"
  },
  {
    "uniprot": "A0A077Z8A5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8A5"
  },
  {
    "uniprot": "A0A077ZE10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE10"
  },
  {
    "uniprot": "A0A077ZHQ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHQ6"
  },
  {
    "uniprot": "A0A077ZDJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDJ3"
  },
  {
    "uniprot": "A0A077YW25",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW25"
  },
  {
    "uniprot": "A0A077Z420",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z420"
  },
  {
    "uniprot": "A0A077ZJQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJQ4"
  },
  {
    "uniprot": "A0A077Z182",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z182"
  },
  {
    "uniprot": "A0A077Z4L4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4L4"
  },
  {
    "uniprot": "A0A077Z2Y6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Y6"
  },
  {
    "uniprot": "A0A077ZGS2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGS2"
  },
  {
    "uniprot": "A0A077Z776",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z776"
  },
  {
    "uniprot": "A0A077ZLL8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLL8"
  },
  {
    "uniprot": "A0A077YXM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXM2"
  },
  {
    "uniprot": "A0A077ZC16",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC16"
  },
  {
    "uniprot": "A0A077Z2D0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2D0"
  },
  {
    "uniprot": "A0A077ZE65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE65"
  },
  {
    "uniprot": "A0A077Z6K5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6K5"
  },
  {
    "uniprot": "A0A077ZEC2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEC2"
  },
  {
    "uniprot": "A0A077ZLA9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLA9"
  },
  {
    "uniprot": "A0A077Z5Q3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Q3"
  },
  {
    "uniprot": "A0A077Z501",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z501"
  },
  {
    "uniprot": "A0A077ZAZ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAZ7"
  },
  {
    "uniprot": "A0A077ZAP5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAP5"
  },
  {
    "uniprot": "A0A077Z6Y2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Y2"
  },
  {
    "uniprot": "A0A077YX09",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX09"
  },
  {
    "uniprot": "A0A077ZLT6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLT6"
  },
  {
    "uniprot": "A0A077Z1N5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1N5"
  },
  {
    "uniprot": "A0A077ZMG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMG8"
  },
  {
    "uniprot": "A0A077ZIY1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIY1"
  },
  {
    "uniprot": "A0A077YZM0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZM0"
  },
  {
    "uniprot": "A0A077YX10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX10"
  },
  {
    "uniprot": "A0A077YVY5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVY5"
  },
  {
    "uniprot": "A0A077ZJJ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJJ0"
  },
  {
    "uniprot": "A0A077Z3J8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3J8"
  },
  {
    "uniprot": "A0A077Z3J7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3J7"
  },
  {
    "uniprot": "A0A077ZBK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBK6"
  },
  {
    "uniprot": "A0A077ZEQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEQ4"
  },
  {
    "uniprot": "A0A077ZID2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZID2"
  },
  {
    "uniprot": "A0A077ZEG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEG8"
  },
  {
    "uniprot": "A0A077ZC04",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC04"
  },
  {
    "uniprot": "A0A077ZK54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK54"
  },
  {
    "uniprot": "A0A077ZR26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR26"
  },
  {
    "uniprot": "A0A077Z1L4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1L4"
  },
  {
    "uniprot": "A0A077Z8U9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8U9"
  },
  {
    "uniprot": "A0A077Z8X0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8X0"
  },
  {
    "uniprot": "A0A077YWQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWQ5"
  },
  {
    "uniprot": "A0A077Z101",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z101"
  },
  {
    "uniprot": "A0A077ZMK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMK3"
  },
  {
    "uniprot": "A0A077ZQN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQN6"
  },
  {
    "uniprot": "A0A077ZF56",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF56"
  },
  {
    "uniprot": "A0A077YYD5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYD5"
  },
  {
    "uniprot": "A0A077Z2Q7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Q7"
  },
  {
    "uniprot": "A0A077ZNB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNB2"
  },
  {
    "uniprot": "A0A077Z9P4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9P4"
  },
  {
    "uniprot": "A0A077Z257",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z257"
  },
  {
    "uniprot": "A0A077ZDQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDQ2"
  },
  {
    "uniprot": "A0A077ZBM9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBM9"
  },
  {
    "uniprot": "A0A077Z176",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z176"
  },
  {
    "uniprot": "A0A077YZV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZV6"
  },
  {
    "uniprot": "A0A077YYP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYP2"
  },
  {
    "uniprot": "A0A077Z833",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z833"
  },
  {
    "uniprot": "A0A077ZAU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAU2"
  },
  {
    "uniprot": "A0A077Z1P3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1P3"
  },
  {
    "uniprot": "A0A077ZB22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB22"
  },
  {
    "uniprot": "A0A077ZIE7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIE7"
  },
  {
    "uniprot": "A0A077Z1I7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1I7"
  },
  {
    "uniprot": "A0A077Z0B0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0B0"
  },
  {
    "uniprot": "A0A077YXI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXI3"
  },
  {
    "uniprot": "A0A077ZIT2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIT2"
  },
  {
    "uniprot": "A0A077Z151",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z151"
  },
  {
    "uniprot": "A0A077Z968",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z968"
  },
  {
    "uniprot": "A0A077Z3Y6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Y6"
  },
  {
    "uniprot": "A0A077YWT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWT8"
  },
  {
    "uniprot": "A0A077ZA16",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA16"
  },
  {
    "uniprot": "A0A077ZKE9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKE9"
  },
  {
    "uniprot": "A0A077Z7F3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7F3"
  },
  {
    "uniprot": "A0A077YZY5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZY5"
  },
  {
    "uniprot": "A0A077ZK53",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK53"
  },
  {
    "uniprot": "A0A077Z5D7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5D7"
  },
  {
    "uniprot": "A0A077Z4U3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4U3"
  },
  {
    "uniprot": "A0A077YWB5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWB5"
  },
  {
    "uniprot": "A0A077YZK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZK9"
  },
  {
    "uniprot": "A0A077ZMC8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMC8"
  },
  {
    "uniprot": "A0A077Z9N3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9N3"
  },
  {
    "uniprot": "A0A077YYT9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYT9"
  },
  {
    "uniprot": "A0A077Z3Q9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Q9"
  },
  {
    "uniprot": "A0A077YVL5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVL5"
  },
  {
    "uniprot": "A0A077Z204",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z204"
  },
  {
    "uniprot": "A0A077Z376",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z376"
  },
  {
    "uniprot": "A0A077ZIM4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIM4"
  },
  {
    "uniprot": "A0A077Z4E6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4E6"
  },
  {
    "uniprot": "A0A077ZGJ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGJ5"
  },
  {
    "uniprot": "A0A077Z8L8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8L8"
  },
  {
    "uniprot": "A0A077YWY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWY0"
  },
  {
    "uniprot": "A0A077YVM3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVM3"
  },
  {
    "uniprot": "A0A077ZDC2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDC2"
  },
  {
    "uniprot": "A0A077Z6N6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6N6"
  },
  {
    "uniprot": "A0A077Z9S3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9S3"
  },
  {
    "uniprot": "A0A077ZN32",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN32"
  },
  {
    "uniprot": "A0A077Z073",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z073"
  },
  {
    "uniprot": "A0A077Z3I8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3I8"
  },
  {
    "uniprot": "A0A077Z155",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z155"
  },
  {
    "uniprot": "A0A077Z0C5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0C5"
  },
  {
    "uniprot": "A0A077YX34",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX34"
  },
  {
    "uniprot": "A0A077ZDN5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDN5"
  },
  {
    "uniprot": "A0A077Z389",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z389"
  },
  {
    "uniprot": "A0A077Z418",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z418"
  },
  {
    "uniprot": "A0A077ZL99",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL99"
  },
  {
    "uniprot": "A0A077ZPM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPM6"
  },
  {
    "uniprot": "A0A077ZNE4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNE4"
  },
  {
    "uniprot": "A0A077Z6K9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6K9"
  },
  {
    "uniprot": "A0A077ZJL5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJL5"
  },
  {
    "uniprot": "A0A077YXP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXP9"
  },
  {
    "uniprot": "A0A077Z218",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z218"
  },
  {
    "uniprot": "A0A077YXN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXN4"
  },
  {
    "uniprot": "A0A077Z7M1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7M1"
  },
  {
    "uniprot": "A0A077Z4W8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4W8"
  },
  {
    "uniprot": "A0A077ZAS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAS9"
  },
  {
    "uniprot": "A0A077Z6L5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6L5"
  },
  {
    "uniprot": "A0A077Z7J3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7J3"
  },
  {
    "uniprot": "A0A077YZG6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZG6"
  },
  {
    "uniprot": "A0A077Z4F9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4F9"
  },
  {
    "uniprot": "A0A077ZKW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKW8"
  },
  {
    "uniprot": "A0A077ZGA7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGA7"
  },
  {
    "uniprot": "A0A077YVX8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVX8"
  },
  {
    "uniprot": "A0A077Z092",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z092"
  },
  {
    "uniprot": "A0A077ZL41",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL41"
  },
  {
    "uniprot": "A0A077YVF6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVF6"
  },
  {
    "uniprot": "A0A077Z827",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z827"
  },
  {
    "uniprot": "A0A077ZDI5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDI5"
  },
  {
    "uniprot": "A0A077YX21",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX21"
  },
  {
    "uniprot": "A0A077Z5C0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5C0"
  },
  {
    "uniprot": "A0A077Z149",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z149"
  },
  {
    "uniprot": "A0A077Z5G3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5G3"
  },
  {
    "uniprot": "A0A077ZLM9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLM9"
  },
  {
    "uniprot": "A0A077ZJ92",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ92"
  },
  {
    "uniprot": "A0A077ZAH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAH3"
  },
  {
    "uniprot": "A0A077ZL84",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL84"
  },
  {
    "uniprot": "A0A077YXH9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXH9"
  },
  {
    "uniprot": "A0A077ZD17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD17"
  },
  {
    "uniprot": "A0A077YYT6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYT6"
  },
  {
    "uniprot": "A0A077Z8Z0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Z0"
  },
  {
    "uniprot": "A0A077Z626",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z626"
  },
  {
    "uniprot": "A0A077YWJ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWJ6"
  },
  {
    "uniprot": "A0A077ZCE0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCE0"
  },
  {
    "uniprot": "A0A077YWE7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWE7"
  },
  {
    "uniprot": "A0A077ZKH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKH8"
  },
  {
    "uniprot": "A0A077ZD01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD01"
  },
  {
    "uniprot": "A0A077ZB87",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB87"
  },
  {
    "uniprot": "A0A077YYH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYH5"
  },
  {
    "uniprot": "A0A077Z1Q1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Q1"
  },
  {
    "uniprot": "A0A077Z161",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z161"
  },
  {
    "uniprot": "A0A077Z1Y6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Y6"
  },
  {
    "uniprot": "A0A077Z4J9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4J9"
  },
  {
    "uniprot": "A0A077ZAE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAE2"
  },
  {
    "uniprot": "A0A077ZBV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBV4"
  },
  {
    "uniprot": "A0A077Z439",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z439"
  },
  {
    "uniprot": "A0A077Z1H9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1H9"
  },
  {
    "uniprot": "A0A077ZGZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGZ1"
  },
  {
    "uniprot": "A0A077ZIC6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIC6"
  },
  {
    "uniprot": "A0A077Z8X2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8X2"
  },
  {
    "uniprot": "A0A077ZJS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJS9"
  },
  {
    "uniprot": "A0A077ZDC0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDC0"
  },
  {
    "uniprot": "A0A077Z4Z9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Z9"
  },
  {
    "uniprot": "A0A077Z3U3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3U3"
  },
  {
    "uniprot": "A0A077ZI02",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI02"
  },
  {
    "uniprot": "A0A077ZJB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJB9"
  },
  {
    "uniprot": "A0A077Z227",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z227"
  },
  {
    "uniprot": "A0A077ZKT7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKT7"
  },
  {
    "uniprot": "A0A077Z771",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z771"
  },
  {
    "uniprot": "A0A077ZQV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQV1"
  },
  {
    "uniprot": "A0A077ZJ00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ00"
  },
  {
    "uniprot": "A0A077Z5Q7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Q7"
  },
  {
    "uniprot": "A0A077ZBB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBB6"
  },
  {
    "uniprot": "A0A077ZFY7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFY7"
  },
  {
    "uniprot": "A0A077ZIZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIZ5"
  },
  {
    "uniprot": "A0A077ZAS7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAS7"
  },
  {
    "uniprot": "A0A077Z758",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z758"
  },
  {
    "uniprot": "A0A077ZAL8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAL8"
  },
  {
    "uniprot": "A0A077ZGQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGQ5"
  },
  {
    "uniprot": "A0A077Z0H6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0H6"
  },
  {
    "uniprot": "A0A077ZBN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBN4"
  },
  {
    "uniprot": "A0A077YV10",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YV10"
  },
  {
    "uniprot": "A0A077ZK93",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK93"
  },
  {
    "uniprot": "A0A077Z446",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z446"
  },
  {
    "uniprot": "A0A077ZHQ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHQ9"
  },
  {
    "uniprot": "A0A077Z7R4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7R4"
  },
  {
    "uniprot": "A0A077Z778",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z778"
  },
  {
    "uniprot": "A0A077ZCG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCG8"
  },
  {
    "uniprot": "A0A077Z8N2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8N2"
  },
  {
    "uniprot": "A0A077ZHK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHK3"
  },
  {
    "uniprot": "A0A077Z1W3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1W3"
  },
  {
    "uniprot": "A0A077ZAE3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAE3"
  },
  {
    "uniprot": "A0A077YVV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVV6"
  },
  {
    "uniprot": "A0A077YZ29",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ29"
  },
  {
    "uniprot": "A0A077ZH99",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH99"
  },
  {
    "uniprot": "A0A077ZJ90",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ90"
  },
  {
    "uniprot": "A0A077ZCK5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCK5"
  },
  {
    "uniprot": "A0A077Z3B8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3B8"
  },
  {
    "uniprot": "A0A077ZPK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPK7"
  },
  {
    "uniprot": "A0A077ZEN1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEN1"
  },
  {
    "uniprot": "A0A077ZEJ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEJ1"
  },
  {
    "uniprot": "A0A077Z8P3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8P3"
  },
  {
    "uniprot": "A0A077Z9B6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9B6"
  },
  {
    "uniprot": "A0A077Z5S4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5S4"
  },
  {
    "uniprot": "A0A077ZCK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCK6"
  },
  {
    "uniprot": "A0A077Z780",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z780"
  },
  {
    "uniprot": "A0A077YZA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZA5"
  },
  {
    "uniprot": "A0A077ZJ85",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ85"
  },
  {
    "uniprot": "A0A077ZBQ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBQ7"
  },
  {
    "uniprot": "A0A077ZLR3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLR3"
  },
  {
    "uniprot": "A0A077ZIY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIY0"
  },
  {
    "uniprot": "A0A077Z9Z9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Z9"
  },
  {
    "uniprot": "A0A077ZDU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDU4"
  },
  {
    "uniprot": "A0A077YZ47",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ47"
  },
  {
    "uniprot": "A0A077Z654",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z654"
  },
  {
    "uniprot": "A0A077Z4D9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4D9"
  },
  {
    "uniprot": "A0A077ZGA0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGA0"
  },
  {
    "uniprot": "A0A077ZES5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZES5"
  },
  {
    "uniprot": "A0A077ZCM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCM5"
  },
  {
    "uniprot": "A0A077Z3C9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3C9"
  },
  {
    "uniprot": "A0A077ZJE9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJE9"
  },
  {
    "uniprot": "A0A077ZNP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNP9"
  },
  {
    "uniprot": "A0A077ZKZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKZ1"
  },
  {
    "uniprot": "A0A077Z3I7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3I7"
  },
  {
    "uniprot": "A0A077ZI40",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI40"
  },
  {
    "uniprot": "A0A077ZJI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJI8"
  },
  {
    "uniprot": "A0A077Z7T3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7T3"
  },
  {
    "uniprot": "A0A077Z147",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z147"
  },
  {
    "uniprot": "A0A077ZEM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEM8"
  },
  {
    "uniprot": "A0A077ZEJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEJ7"
  },
  {
    "uniprot": "A0A077ZCZ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCZ2"
  },
  {
    "uniprot": "A0A077Z116",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z116"
  },
  {
    "uniprot": "A0A077Z653",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z653"
  },
  {
    "uniprot": "A0A077ZE18",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE18"
  },
  {
    "uniprot": "A0A077Z414",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z414"
  },
  {
    "uniprot": "A0A077Z8U2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8U2"
  },
  {
    "uniprot": "A0A077YZ43",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ43"
  },
  {
    "uniprot": "A0A077Z251",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z251"
  },
  {
    "uniprot": "A0A077YWY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWY9"
  },
  {
    "uniprot": "A0A077ZMU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMU9"
  },
  {
    "uniprot": "A0A077YXK0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXK0"
  },
  {
    "uniprot": "A0A077ZIR3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIR3"
  },
  {
    "uniprot": "A0A077ZHV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHV3"
  },
  {
    "uniprot": "A0A077ZID9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZID9"
  },
  {
    "uniprot": "A0A077ZGC0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGC0"
  },
  {
    "uniprot": "A0A077ZHB3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHB3"
  },
  {
    "uniprot": "A0A077ZB30",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB30"
  },
  {
    "uniprot": "A0A077ZMF2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMF2"
  },
  {
    "uniprot": "A0A077Z3F9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3F9"
  },
  {
    "uniprot": "A0A077ZJ98",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ98"
  },
  {
    "uniprot": "A0A077ZF73",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF73"
  },
  {
    "uniprot": "A0A077ZJX4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJX4"
  },
  {
    "uniprot": "A0A077Z8R3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8R3"
  },
  {
    "uniprot": "A0A077ZD89",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD89"
  },
  {
    "uniprot": "A0A077YVQ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVQ7"
  },
  {
    "uniprot": "A0A077Z0D2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0D2"
  },
  {
    "uniprot": "A0A077ZIJ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIJ4"
  },
  {
    "uniprot": "A0A077Z3S8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3S8"
  },
  {
    "uniprot": "A0A077ZKX5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKX5"
  },
  {
    "uniprot": "A0A077Z9C0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9C0"
  },
  {
    "uniprot": "A0A077YXD2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXD2"
  },
  {
    "uniprot": "A0A077ZBP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBP9"
  },
  {
    "uniprot": "A0A077Z0T6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0T6"
  },
  {
    "uniprot": "A0A077Z0A1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0A1"
  },
  {
    "uniprot": "A0A077Z012",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z012"
  },
  {
    "uniprot": "A0A077ZL85",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL85"
  },
  {
    "uniprot": "A0A077YWQ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWQ9"
  },
  {
    "uniprot": "A0A077YVL9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVL9"
  },
  {
    "uniprot": "A0A077Z041",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z041"
  },
  {
    "uniprot": "A0A077ZIR2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIR2"
  },
  {
    "uniprot": "A0A077ZFB1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFB1"
  },
  {
    "uniprot": "A0A077Z6I8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6I8"
  },
  {
    "uniprot": "A0A077ZH59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH59"
  },
  {
    "uniprot": "A0A077ZMZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMZ8"
  },
  {
    "uniprot": "A0A077Z096",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z096"
  },
  {
    "uniprot": "A0A077Z9J9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9J9"
  },
  {
    "uniprot": "A0A077ZF97",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF97"
  },
  {
    "uniprot": "A0A077ZFY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFY0"
  },
  {
    "uniprot": "A0A077ZE60",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE60"
  },
  {
    "uniprot": "A0A077Z8A8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8A8"
  },
  {
    "uniprot": "A0A077Z006",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z006"
  },
  {
    "uniprot": "A0A077ZGC7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGC7"
  },
  {
    "uniprot": "A0A077Z0A7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0A7"
  },
  {
    "uniprot": "A0A077ZD67",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD67"
  },
  {
    "uniprot": "A0A077Z3B0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3B0"
  },
  {
    "uniprot": "A0A077Z8Y5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Y5"
  },
  {
    "uniprot": "A0A077Z7Q2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Q2"
  },
  {
    "uniprot": "A0A077Z907",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z907"
  },
  {
    "uniprot": "A0A077ZI38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI38"
  },
  {
    "uniprot": "A0A077ZHY5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHY5"
  },
  {
    "uniprot": "A0A077ZDG0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDG0"
  },
  {
    "uniprot": "A0A077Z2M3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2M3"
  },
  {
    "uniprot": "A0A077ZGI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGI3"
  },
  {
    "uniprot": "A0A077ZDB8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDB8"
  },
  {
    "uniprot": "A0A077Z8Q3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Q3"
  },
  {
    "uniprot": "A0A077Z8S6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8S6"
  },
  {
    "uniprot": "A0A077Z276",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z276"
  },
  {
    "uniprot": "A0A077ZB01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB01"
  },
  {
    "uniprot": "A0A077ZDP1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDP1"
  },
  {
    "uniprot": "A0A077ZN18",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN18"
  },
  {
    "uniprot": "A0A077Z0Q0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Q0"
  },
  {
    "uniprot": "A0A077Z1U1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1U1"
  },
  {
    "uniprot": "A0A077ZFI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFI8"
  },
  {
    "uniprot": "A0A077Z4G0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4G0"
  },
  {
    "uniprot": "A0A077ZJ28",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ28"
  },
  {
    "uniprot": "A0A077YYZ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYZ0"
  },
  {
    "uniprot": "A0A077ZLP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLP0"
  },
  {
    "uniprot": "A0A077ZL65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL65"
  },
  {
    "uniprot": "A0A077ZGA3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGA3"
  },
  {
    "uniprot": "A0A077Z676",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z676"
  },
  {
    "uniprot": "A0A077ZCY5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCY5"
  },
  {
    "uniprot": "A0A077ZI92",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI92"
  },
  {
    "uniprot": "A0A077Z965",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z965"
  },
  {
    "uniprot": "A0A077Z0P5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0P5"
  },
  {
    "uniprot": "A0A077Z0Y1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Y1"
  },
  {
    "uniprot": "A0A077Z1N1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1N1"
  },
  {
    "uniprot": "A0A077ZHM4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHM4"
  },
  {
    "uniprot": "A0A077ZFR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFR6"
  },
  {
    "uniprot": "A0A077Z493",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z493"
  },
  {
    "uniprot": "A0A077ZDT0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDT0"
  },
  {
    "uniprot": "A0A077ZIE0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIE0"
  },
  {
    "uniprot": "A0A077ZAH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAH8"
  },
  {
    "uniprot": "A0A077ZIL4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIL4"
  },
  {
    "uniprot": "A0A077Z869",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z869"
  },
  {
    "uniprot": "A0A077ZJJ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJJ3"
  },
  {
    "uniprot": "A0A077YXD7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXD7"
  },
  {
    "uniprot": "A0A077YWV5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWV5"
  },
  {
    "uniprot": "A0A077ZJE3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJE3"
  },
  {
    "uniprot": "A0A077Z9K1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9K1"
  },
  {
    "uniprot": "A0A077Z469",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z469"
  },
  {
    "uniprot": "A0A077Z3A8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3A8"
  },
  {
    "uniprot": "A0A077ZI43",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI43"
  },
  {
    "uniprot": "A0A077ZN92",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN92"
  },
  {
    "uniprot": "A0A077Z7R8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7R8"
  },
  {
    "uniprot": "A0A077YYT8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYT8"
  },
  {
    "uniprot": "A0A077Z271",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z271"
  },
  {
    "uniprot": "A0A077ZC25",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC25"
  },
  {
    "uniprot": "A0A077ZI29",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI29"
  },
  {
    "uniprot": "A0A077ZNN3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNN3"
  },
  {
    "uniprot": "A0A077ZJA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJA1"
  },
  {
    "uniprot": "A0A077Z382",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z382"
  },
  {
    "uniprot": "A0A077ZMC0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMC0"
  },
  {
    "uniprot": "A0A077Z8E0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8E0"
  },
  {
    "uniprot": "A0A077ZFF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFF4"
  },
  {
    "uniprot": "A0A077Z1K3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1K3"
  },
  {
    "uniprot": "A0A077YZ88",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ88"
  },
  {
    "uniprot": "A0A077ZIC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIC3"
  },
  {
    "uniprot": "A0A077YZR3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZR3"
  },
  {
    "uniprot": "A0A077Z516",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z516"
  },
  {
    "uniprot": "A0A077Z2E8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2E8"
  },
  {
    "uniprot": "A0A077Z1I6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1I6"
  },
  {
    "uniprot": "A0A077ZIP1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIP1"
  },
  {
    "uniprot": "A0A077ZCE3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCE3"
  },
  {
    "uniprot": "A0A077Z9Y6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Y6"
  },
  {
    "uniprot": "A0A077Z811",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z811"
  },
  {
    "uniprot": "A0A077Z8T1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8T1"
  },
  {
    "uniprot": "A0A077Z0I5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0I5"
  },
  {
    "uniprot": "A0A077ZIN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIN7"
  },
  {
    "uniprot": "A0A077ZD63",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD63"
  },
  {
    "uniprot": "A0A077ZJ88",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ88"
  },
  {
    "uniprot": "A0A077Z0G0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0G0"
  },
  {
    "uniprot": "A0A077Z3D3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3D3"
  },
  {
    "uniprot": "A0A077ZBU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBU2"
  },
  {
    "uniprot": "A0A077Z0X3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0X3"
  },
  {
    "uniprot": "A0A077ZJW9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJW9"
  },
  {
    "uniprot": "A0A077Z9B8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9B8"
  },
  {
    "uniprot": "A0A077ZLY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLY3"
  },
  {
    "uniprot": "A0A077Z2G2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2G2"
  },
  {
    "uniprot": "A0A077ZFC9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFC9"
  },
  {
    "uniprot": "A0A077Z1D6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1D6"
  },
  {
    "uniprot": "A0A077Z562",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z562"
  },
  {
    "uniprot": "A0A077ZHN0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHN0"
  },
  {
    "uniprot": "A0A077ZCA3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCA3"
  },
  {
    "uniprot": "A0A077ZK98",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK98"
  },
  {
    "uniprot": "A0A077Z9L7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9L7"
  },
  {
    "uniprot": "A0A077Z311",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z311"
  },
  {
    "uniprot": "A0A077YVI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVI8"
  },
  {
    "uniprot": "A0A077YW20",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW20"
  },
  {
    "uniprot": "A0A077ZK29",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK29"
  },
  {
    "uniprot": "A0A077Z5H5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5H5"
  },
  {
    "uniprot": "A0A077Z7H8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7H8"
  },
  {
    "uniprot": "A0A077ZB05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB05"
  },
  {
    "uniprot": "A0A077ZGJ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGJ4"
  },
  {
    "uniprot": "A0A077ZCT4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCT4"
  },
  {
    "uniprot": "A0A077Z0X7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0X7"
  },
  {
    "uniprot": "A0A077ZLI9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLI9"
  },
  {
    "uniprot": "A0A077ZAR7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAR7"
  },
  {
    "uniprot": "A0A077Z142",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z142"
  },
  {
    "uniprot": "A0A077ZB82",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB82"
  },
  {
    "uniprot": "A0A077Z5J0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5J0"
  },
  {
    "uniprot": "A0A077YVV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVV4"
  },
  {
    "uniprot": "A0A077YWF4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWF4"
  },
  {
    "uniprot": "A0A077ZKW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKW0"
  },
  {
    "uniprot": "A0A077YZD5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZD5"
  },
  {
    "uniprot": "A0A077ZIB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIB6"
  },
  {
    "uniprot": "A0A077ZKQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKQ2"
  },
  {
    "uniprot": "A0A077Z076",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z076"
  },
  {
    "uniprot": "A0A077Z4F0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4F0"
  },
  {
    "uniprot": "A0A077Z1Q8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Q8"
  },
  {
    "uniprot": "A0A077ZIL5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIL5"
  },
  {
    "uniprot": "A0A077ZHU3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHU3"
  },
  {
    "uniprot": "A0A077Z4H1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4H1"
  },
  {
    "uniprot": "A0A077ZCD8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCD8"
  },
  {
    "uniprot": "A0A077Z0K0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0K0"
  },
  {
    "uniprot": "A0A077ZCH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCH8"
  },
  {
    "uniprot": "A0A077ZAU3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAU3"
  },
  {
    "uniprot": "A0A077ZKG8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKG8"
  },
  {
    "uniprot": "A0A077ZKT6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKT6"
  },
  {
    "uniprot": "A0A077ZE48",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE48"
  },
  {
    "uniprot": "A0A077ZIP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIP4"
  },
  {
    "uniprot": "A0A077ZEK2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEK2"
  },
  {
    "uniprot": "A0A077Z4E2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4E2"
  },
  {
    "uniprot": "A0A077ZET7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZET7"
  },
  {
    "uniprot": "A0A077ZCC7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCC7"
  },
  {
    "uniprot": "A0A077Z3C0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3C0"
  },
  {
    "uniprot": "A0A077YZE8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZE8"
  },
  {
    "uniprot": "A0A077Z9M9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9M9"
  },
  {
    "uniprot": "A0A077ZHB7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHB7"
  },
  {
    "uniprot": "A0A077ZK30",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK30"
  },
  {
    "uniprot": "A0A077Z5T8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5T8"
  },
  {
    "uniprot": "A0A077Z0Y5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Y5"
  },
  {
    "uniprot": "A0A077ZHZ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHZ6"
  },
  {
    "uniprot": "A0A077ZH30",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH30"
  },
  {
    "uniprot": "A0A077ZND8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZND8"
  },
  {
    "uniprot": "A0A077Z404",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z404"
  },
  {
    "uniprot": "A0A077ZB09",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB09"
  },
  {
    "uniprot": "A0A077Z8Q6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Q6"
  },
  {
    "uniprot": "A0A077ZN35",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN35"
  },
  {
    "uniprot": "A0A077ZB72",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB72"
  },
  {
    "uniprot": "A0A077Z8A0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8A0"
  },
  {
    "uniprot": "A0A077YXC6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXC6"
  },
  {
    "uniprot": "A0A077Z0S6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0S6"
  },
  {
    "uniprot": "A0A077YZ14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ14"
  },
  {
    "uniprot": "A0A077YWC6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWC6"
  },
  {
    "uniprot": "A0A077YVZ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVZ4"
  },
  {
    "uniprot": "A0A077Z322",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z322"
  },
  {
    "uniprot": "A0A077ZAB7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAB7"
  },
  {
    "uniprot": "A0A077Z9C9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9C9"
  },
  {
    "uniprot": "A0A077Z5E4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5E4"
  },
  {
    "uniprot": "A0A077Z8N4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8N4"
  },
  {
    "uniprot": "A0A077ZGC2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGC2"
  },
  {
    "uniprot": "A0A077Z2C9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2C9"
  },
  {
    "uniprot": "A0A077Z069",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z069"
  },
  {
    "uniprot": "A0A077Z838",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z838"
  },
  {
    "uniprot": "A0A077ZBM0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBM0"
  },
  {
    "uniprot": "A0A077ZG96",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG96"
  },
  {
    "uniprot": "A0A077Z1C7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1C7"
  },
  {
    "uniprot": "A0A077ZHI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHI3"
  },
  {
    "uniprot": "A0A077YZD6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZD6"
  },
  {
    "uniprot": "A0A077Z982",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z982"
  },
  {
    "uniprot": "A0A077Z4Q2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Q2"
  },
  {
    "uniprot": "A0A077ZF58",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF58"
  },
  {
    "uniprot": "A0A077Z787",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z787"
  },
  {
    "uniprot": "A0A077ZJ58",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ58"
  },
  {
    "uniprot": "A0A077ZLL1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLL1"
  },
  {
    "uniprot": "A0A077YUX2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUX2"
  },
  {
    "uniprot": "A0A077Z138",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z138"
  },
  {
    "uniprot": "A0A077ZMI1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMI1"
  },
  {
    "uniprot": "A0A077Z0L5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0L5"
  },
  {
    "uniprot": "A0A077ZMQ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMQ0"
  },
  {
    "uniprot": "A0A077Z2D4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2D4"
  },
  {
    "uniprot": "A0A077Z8Z3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Z3"
  },
  {
    "uniprot": "A0A077ZCN9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCN9"
  },
  {
    "uniprot": "A0A077ZG27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG27"
  },
  {
    "uniprot": "A0A077ZCR8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCR8"
  },
  {
    "uniprot": "A0A077Z5Y8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Y8"
  },
  {
    "uniprot": "A0A077ZLF2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLF2"
  },
  {
    "uniprot": "A0A077ZLV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLV1"
  },
  {
    "uniprot": "A0A077YVI5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVI5"
  },
  {
    "uniprot": "A0A077ZBT5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBT5"
  },
  {
    "uniprot": "A0A077Z3A2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3A2"
  },
  {
    "uniprot": "A0A077Z3W5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3W5"
  },
  {
    "uniprot": "A0A077Z2U5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2U5"
  },
  {
    "uniprot": "A0A077Z950",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z950"
  },
  {
    "uniprot": "A0A077ZKQ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKQ9"
  },
  {
    "uniprot": "A0A077ZLC2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLC2"
  },
  {
    "uniprot": "A0A077ZBB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBB0"
  },
  {
    "uniprot": "A0A077Z3X2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3X2"
  },
  {
    "uniprot": "A0A077Z1I1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1I1"
  },
  {
    "uniprot": "A0A077ZI67",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI67"
  },
  {
    "uniprot": "A0A077Z9A9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9A9"
  },
  {
    "uniprot": "A0A077ZG63",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG63"
  },
  {
    "uniprot": "A0A077YW78",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW78"
  },
  {
    "uniprot": "A0A077ZMQ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMQ7"
  },
  {
    "uniprot": "A0A077ZK61",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK61"
  },
  {
    "uniprot": "A0A077ZHM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHM1"
  },
  {
    "uniprot": "A0A077ZEP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEP0"
  },
  {
    "uniprot": "A0A077Z2W0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2W0"
  },
  {
    "uniprot": "A0A077ZJC1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJC1"
  },
  {
    "uniprot": "A0A077Z6E6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6E6"
  },
  {
    "uniprot": "A0A077ZK24",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK24"
  },
  {
    "uniprot": "A0A077YY66",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY66"
  },
  {
    "uniprot": "A0A077ZKT5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKT5"
  },
  {
    "uniprot": "A0A077Z217",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z217"
  },
  {
    "uniprot": "A0A077Z245",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z245"
  },
  {
    "uniprot": "A0A077ZMX8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMX8"
  },
  {
    "uniprot": "A0A077ZE70",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE70"
  },
  {
    "uniprot": "A0A077ZAB8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAB8"
  },
  {
    "uniprot": "A0A077Z825",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z825"
  },
  {
    "uniprot": "A0A077ZIX9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIX9"
  },
  {
    "uniprot": "A0A077Z0S2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0S2"
  },
  {
    "uniprot": "A0A077Z5A0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5A0"
  },
  {
    "uniprot": "A0A077Z2U2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2U2"
  },
  {
    "uniprot": "A0A077Z7V6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7V6"
  },
  {
    "uniprot": "A0A077ZMW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMW0"
  },
  {
    "uniprot": "A0A077ZPW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPW7"
  },
  {
    "uniprot": "A0A077Z3N9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3N9"
  },
  {
    "uniprot": "A0A077Z946",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z946"
  },
  {
    "uniprot": "A0A077Z5I6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5I6"
  },
  {
    "uniprot": "A0A077YVX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVX3"
  },
  {
    "uniprot": "A0A077Z340",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z340"
  },
  {
    "uniprot": "A0A077ZCD4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCD4"
  },
  {
    "uniprot": "A0A077Z2L9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2L9"
  },
  {
    "uniprot": "A0A077ZC14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC14"
  },
  {
    "uniprot": "A0A077Z3E0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3E0"
  },
  {
    "uniprot": "A0A077ZC44",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC44"
  },
  {
    "uniprot": "A0A077ZML1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZML1"
  },
  {
    "uniprot": "A0A077Z7T2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7T2"
  },
  {
    "uniprot": "A0A077YX46",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX46"
  },
  {
    "uniprot": "A0A077ZFM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFM2"
  },
  {
    "uniprot": "A0A077Z0F7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0F7"
  },
  {
    "uniprot": "A0A077Z9V7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9V7"
  },
  {
    "uniprot": "A0A077ZDC3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDC3"
  },
  {
    "uniprot": "A0A077YXG2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXG2"
  },
  {
    "uniprot": "A0A077ZKA3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKA3"
  },
  {
    "uniprot": "A0A077YXH5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXH5"
  },
  {
    "uniprot": "A0A077ZK35",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK35"
  },
  {
    "uniprot": "A0A077Z8I5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8I5"
  },
  {
    "uniprot": "A0A077ZIF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIF0"
  },
  {
    "uniprot": "A0A077ZJW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJW1"
  },
  {
    "uniprot": "A0A077ZFM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFM1"
  },
  {
    "uniprot": "A0A077ZJB4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJB4"
  },
  {
    "uniprot": "A0A077Z781",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z781"
  },
  {
    "uniprot": "A0A077Z1H3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1H3"
  },
  {
    "uniprot": "A0A077Z3Z9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Z9"
  },
  {
    "uniprot": "A0A077ZHJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHJ2"
  },
  {
    "uniprot": "A0A077ZKY6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKY6"
  },
  {
    "uniprot": "A0A077ZND0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZND0"
  },
  {
    "uniprot": "A0A077YZ63",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ63"
  },
  {
    "uniprot": "A0A077ZAA2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAA2"
  },
  {
    "uniprot": "A0A077Z7P2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7P2"
  },
  {
    "uniprot": "A0A077Z7B0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7B0"
  },
  {
    "uniprot": "A0A077YXE6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXE6"
  },
  {
    "uniprot": "A0A077ZEA6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEA6"
  },
  {
    "uniprot": "A0A077Z2X9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2X9"
  },
  {
    "uniprot": "A0A077ZEX8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEX8"
  },
  {
    "uniprot": "A0A077ZB40",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB40"
  },
  {
    "uniprot": "A0A077ZN93",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN93"
  },
  {
    "uniprot": "A0A077ZJH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJH8"
  },
  {
    "uniprot": "A0A077Z0H7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0H7"
  },
  {
    "uniprot": "A0A077Z7K2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7K2"
  },
  {
    "uniprot": "A0A077ZPI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPI8"
  },
  {
    "uniprot": "A0A077ZI19",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI19"
  },
  {
    "uniprot": "A0A077Z5A3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5A3"
  },
  {
    "uniprot": "A0A077Z070",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z070"
  },
  {
    "uniprot": "A0A077Z9B4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9B4"
  },
  {
    "uniprot": "A0A077ZJV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJV6"
  },
  {
    "uniprot": "A0A077ZIA6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIA6"
  },
  {
    "uniprot": "A0A077Z0E5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0E5"
  },
  {
    "uniprot": "A0A077Z5H3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5H3"
  },
  {
    "uniprot": "A0A077ZKV0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKV0"
  },
  {
    "uniprot": "A0A077ZKY7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKY7"
  },
  {
    "uniprot": "A0A077ZEI7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEI7"
  },
  {
    "uniprot": "A0A077Z369",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z369"
  },
  {
    "uniprot": "A0A077ZDH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDH3"
  },
  {
    "uniprot": "A0A077YZJ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZJ9"
  },
  {
    "uniprot": "A0A077ZBR0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBR0"
  },
  {
    "uniprot": "A0A077Z6Z1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Z1"
  },
  {
    "uniprot": "A0A077ZEF2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEF2"
  },
  {
    "uniprot": "A0A077Z460",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z460"
  },
  {
    "uniprot": "A0A077ZQM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQM7"
  },
  {
    "uniprot": "A0A077Z1N3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1N3"
  },
  {
    "uniprot": "A0A077Z2T9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2T9"
  },
  {
    "uniprot": "A0A077ZNF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNF0"
  },
  {
    "uniprot": "A0A077ZJ31",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ31"
  },
  {
    "uniprot": "A0A077YX38",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX38"
  },
  {
    "uniprot": "A0A077Z4H5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4H5"
  },
  {
    "uniprot": "A0A077ZFK1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFK1"
  },
  {
    "uniprot": "A0A077ZKF2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKF2"
  },
  {
    "uniprot": "A0A077ZK74",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK74"
  },
  {
    "uniprot": "A0A077ZA52",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA52"
  },
  {
    "uniprot": "A0A077ZB18",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB18"
  },
  {
    "uniprot": "A0A077YXB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXB2"
  },
  {
    "uniprot": "A0A077ZB07",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB07"
  },
  {
    "uniprot": "A0A077ZG37",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG37"
  },
  {
    "uniprot": "A0A077YWR3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWR3"
  },
  {
    "uniprot": "A0A077YYZ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYZ9"
  },
  {
    "uniprot": "A0A077ZGX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGX3"
  },
  {
    "uniprot": "A0A077Z6H6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6H6"
  },
  {
    "uniprot": "A0A077YUP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUP4"
  },
  {
    "uniprot": "A0A077YWH4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWH4"
  },
  {
    "uniprot": "A0A077Z4K4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4K4"
  },
  {
    "uniprot": "A0A077Z0U7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0U7"
  },
  {
    "uniprot": "A0A077YYV7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYV7"
  },
  {
    "uniprot": "A0A077YZS0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZS0"
  },
  {
    "uniprot": "A0A077YXK1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXK1"
  },
  {
    "uniprot": "A0A077ZC78",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC78"
  },
  {
    "uniprot": "A0A077Z972",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z972"
  },
  {
    "uniprot": "A0A077Z8G6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8G6"
  },
  {
    "uniprot": "A0A077ZFI7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFI7"
  },
  {
    "uniprot": "A0A077ZAR2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAR2"
  },
  {
    "uniprot": "A0A077YYJ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYJ9"
  },
  {
    "uniprot": "A0A077Z8W1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8W1"
  },
  {
    "uniprot": "A0A077Z2C7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2C7"
  },
  {
    "uniprot": "A0A077Z7Z7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Z7"
  },
  {
    "uniprot": "A0A077Z8C7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8C7"
  },
  {
    "uniprot": "A0A077Z1N2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1N2"
  },
  {
    "uniprot": "A0A077Z2U1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2U1"
  },
  {
    "uniprot": "A0A077ZAN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAN4"
  },
  {
    "uniprot": "A0A077ZIR0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIR0"
  },
  {
    "uniprot": "A0A077ZMW6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMW6"
  },
  {
    "uniprot": "A0A077Z017",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z017"
  },
  {
    "uniprot": "A0A077ZNB1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNB1"
  },
  {
    "uniprot": "A0A077ZHQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHQ3"
  },
  {
    "uniprot": "A0A077Z5N1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5N1"
  },
  {
    "uniprot": "A0A077YVV2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVV2"
  },
  {
    "uniprot": "A0A077ZMI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMI8"
  },
  {
    "uniprot": "A0A077ZFQ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFQ1"
  },
  {
    "uniprot": "A0A077ZEB9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEB9"
  },
  {
    "uniprot": "A0A077ZDJ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDJ4"
  },
  {
    "uniprot": "A0A077ZGV8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGV8"
  },
  {
    "uniprot": "A0A077Z000",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z000"
  },
  {
    "uniprot": "A0A077ZIM6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIM6"
  },
  {
    "uniprot": "A0A077ZJH0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJH0"
  },
  {
    "uniprot": "A0A077ZDZ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDZ6"
  },
  {
    "uniprot": "A0A077ZFS4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFS4"
  },
  {
    "uniprot": "A0A077ZJ05",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ05"
  },
  {
    "uniprot": "A0A077Z496",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z496"
  },
  {
    "uniprot": "A0A077Z1J5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1J5"
  },
  {
    "uniprot": "A0A077ZFD6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFD6"
  },
  {
    "uniprot": "A0A077Z899",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z899"
  },
  {
    "uniprot": "A0A077YZE5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZE5"
  },
  {
    "uniprot": "A0A077Z126",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z126"
  },
  {
    "uniprot": "A0A077ZBF6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBF6"
  },
  {
    "uniprot": "A0A077Z2L2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2L2"
  },
  {
    "uniprot": "A0A077ZC52",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC52"
  },
  {
    "uniprot": "A0A077Z9C4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9C4"
  },
  {
    "uniprot": "A0A077YZT6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZT6"
  },
  {
    "uniprot": "A0A077Z7H6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7H6"
  },
  {
    "uniprot": "A0A077ZAZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAZ8"
  },
  {
    "uniprot": "A0A077ZE88",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE88"
  },
  {
    "uniprot": "A0A077ZAE0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAE0"
  },
  {
    "uniprot": "A0A077Z010",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z010"
  },
  {
    "uniprot": "A0A077ZKR8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKR8"
  },
  {
    "uniprot": "A0A077ZB96",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB96"
  },
  {
    "uniprot": "A0A077ZJ81",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ81"
  },
  {
    "uniprot": "A0A077YY06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY06"
  },
  {
    "uniprot": "A0A077Z961",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z961"
  },
  {
    "uniprot": "A0A077ZCZ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCZ5"
  },
  {
    "uniprot": "A0A077YYK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYK4"
  },
  {
    "uniprot": "A0A077Z3Q4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Q4"
  },
  {
    "uniprot": "A0A077ZLB1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLB1"
  },
  {
    "uniprot": "A0A077YXX1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXX1"
  },
  {
    "uniprot": "A0A077ZIK3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIK3"
  },
  {
    "uniprot": "A0A077ZCS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCS9"
  },
  {
    "uniprot": "A0A077Z2C0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2C0"
  },
  {
    "uniprot": "A0A077ZM60",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM60"
  },
  {
    "uniprot": "A0A077ZEB4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEB4"
  },
  {
    "uniprot": "A0A077YYQ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYQ7"
  },
  {
    "uniprot": "A0A077Z1A1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1A1"
  },
  {
    "uniprot": "A0A077ZJF7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJF7"
  },
  {
    "uniprot": "A0A077ZCC2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCC2"
  },
  {
    "uniprot": "A0A077Z1U2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1U2"
  },
  {
    "uniprot": "A0A077Z1S6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1S6"
  },
  {
    "uniprot": "A0A077YWE5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWE5"
  },
  {
    "uniprot": "A0A077ZE24",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE24"
  },
  {
    "uniprot": "A0A077ZDB4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDB4"
  },
  {
    "uniprot": "A0A077ZCS4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCS4"
  },
  {
    "uniprot": "A0A077Z4Y5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Y5"
  },
  {
    "uniprot": "A0A077Z0Z8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Z8"
  },
  {
    "uniprot": "A0A077ZHW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHW1"
  },
  {
    "uniprot": "A0A077ZK12",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK12"
  },
  {
    "uniprot": "A0A077YWR0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWR0"
  },
  {
    "uniprot": "A0A077Z0V3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0V3"
  },
  {
    "uniprot": "A0A077Z499",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z499"
  },
  {
    "uniprot": "A0A077Z9U1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9U1"
  },
  {
    "uniprot": "A0A077Z947",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z947"
  },
  {
    "uniprot": "A0A077YX13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX13"
  },
  {
    "uniprot": "A0A077ZD52",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD52"
  },
  {
    "uniprot": "A0A077Z6A3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6A3"
  },
  {
    "uniprot": "A0A077ZHQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHQ8"
  },
  {
    "uniprot": "A0A077ZE27",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE27"
  },
  {
    "uniprot": "A0A077ZE56",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE56"
  },
  {
    "uniprot": "A0A077Z570",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z570"
  },
  {
    "uniprot": "A0A077Z9M1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9M1"
  },
  {
    "uniprot": "A0A077YWV4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWV4"
  },
  {
    "uniprot": "A0A077ZFZ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFZ7"
  },
  {
    "uniprot": "A0A077ZK67",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK67"
  },
  {
    "uniprot": "A0A077ZFA1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFA1"
  },
  {
    "uniprot": "A0A077ZHS2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHS2"
  },
  {
    "uniprot": "A0A077YZ65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ65"
  },
  {
    "uniprot": "A0A077Z8W5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8W5"
  },
  {
    "uniprot": "A0A077YZL1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZL1"
  },
  {
    "uniprot": "A0A077Z722",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z722"
  },
  {
    "uniprot": "A0A077Z0H0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0H0"
  },
  {
    "uniprot": "A0A077Z7K8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7K8"
  },
  {
    "uniprot": "A0A077ZBK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBK9"
  },
  {
    "uniprot": "A0A077Z5G0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5G0"
  },
  {
    "uniprot": "A0A077ZLB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLB6"
  },
  {
    "uniprot": "A0A077Z7K6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7K6"
  },
  {
    "uniprot": "A0A077ZIN6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIN6"
  },
  {
    "uniprot": "A0A077ZDY8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDY8"
  },
  {
    "uniprot": "A0A077Z5E3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5E3"
  },
  {
    "uniprot": "A0A077YXX5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXX5"
  },
  {
    "uniprot": "A0A077YUW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YUW8"
  },
  {
    "uniprot": "A0A077ZB14",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB14"
  },
  {
    "uniprot": "A0A077Z9H1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9H1"
  },
  {
    "uniprot": "A0A077ZFA9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFA9"
  },
  {
    "uniprot": "A0A077ZH76",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZH76"
  },
  {
    "uniprot": "A0A077ZD31",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD31"
  },
  {
    "uniprot": "A0A077ZER4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZER4"
  },
  {
    "uniprot": "A0A077ZLF9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLF9"
  },
  {
    "uniprot": "A0A077Z957",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z957"
  },
  {
    "uniprot": "A0A077Z9M6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9M6"
  },
  {
    "uniprot": "A0A077YYI8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYI8"
  },
  {
    "uniprot": "A0A077YZW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZW7"
  },
  {
    "uniprot": "A0A077Z380",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z380"
  },
  {
    "uniprot": "A0A077ZE67",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE67"
  },
  {
    "uniprot": "A0A077Z8V8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8V8"
  },
  {
    "uniprot": "A0A077YXC1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXC1"
  },
  {
    "uniprot": "A0A077Z8W2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8W2"
  },
  {
    "uniprot": "A0A077ZC68",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC68"
  },
  {
    "uniprot": "A0A077Z641",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z641"
  },
  {
    "uniprot": "A0A077Z3C8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3C8"
  },
  {
    "uniprot": "A0A077ZLV8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLV8"
  },
  {
    "uniprot": "A0A077ZDE9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDE9"
  },
  {
    "uniprot": "A0A077ZKQ5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKQ5"
  },
  {
    "uniprot": "A0A077Z1V4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1V4"
  },
  {
    "uniprot": "A0A077Z2B5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2B5"
  },
  {
    "uniprot": "A0A077ZKD1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKD1"
  },
  {
    "uniprot": "A0A077Z1V1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1V1"
  },
  {
    "uniprot": "A0A077Z2P5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2P5"
  },
  {
    "uniprot": "A0A077YZA9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZA9"
  },
  {
    "uniprot": "A0A077Z5H7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5H7"
  },
  {
    "uniprot": "A0A077Z782",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z782"
  },
  {
    "uniprot": "A0A077ZLU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLU1"
  },
  {
    "uniprot": "A0A077ZA42",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA42"
  },
  {
    "uniprot": "A0A077Z354",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z354"
  },
  {
    "uniprot": "A0A077Z177",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z177"
  },
  {
    "uniprot": "A0A077Z5Y5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Y5"
  },
  {
    "uniprot": "A0A077Z5X4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5X4"
  },
  {
    "uniprot": "A0A077ZGB3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGB3"
  },
  {
    "uniprot": "A0A077ZKD7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKD7"
  },
  {
    "uniprot": "A0A077ZGD6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGD6"
  },
  {
    "uniprot": "A0A077Z5R7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5R7"
  },
  {
    "uniprot": "A0A077ZLB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLB0"
  },
  {
    "uniprot": "A0A077Z867",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z867"
  },
  {
    "uniprot": "A0A077ZAP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAP3"
  },
  {
    "uniprot": "A0A077Z948",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z948"
  },
  {
    "uniprot": "A0A077ZBK5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBK5"
  },
  {
    "uniprot": "A0A077YWP7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWP7"
  },
  {
    "uniprot": "A0A077Z4J4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4J4"
  },
  {
    "uniprot": "A0A077ZL43",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL43"
  },
  {
    "uniprot": "A0A077Z535",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z535"
  },
  {
    "uniprot": "A0A077ZAW6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAW6"
  },
  {
    "uniprot": "A0A077ZDD9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDD9"
  },
  {
    "uniprot": "A0A077Z7V9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7V9"
  },
  {
    "uniprot": "A0A077YZZ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZZ7"
  },
  {
    "uniprot": "A0A077Z0E1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0E1"
  },
  {
    "uniprot": "A0A077ZGF3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGF3"
  },
  {
    "uniprot": "A0A077Z032",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z032"
  },
  {
    "uniprot": "A0A077YXM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXM1"
  },
  {
    "uniprot": "A0A077Z1U4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1U4"
  },
  {
    "uniprot": "A0A077ZEG1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEG1"
  },
  {
    "uniprot": "A0A077Z4K1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4K1"
  },
  {
    "uniprot": "A0A077ZR44",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZR44"
  },
  {
    "uniprot": "A0A077Z0N5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0N5"
  },
  {
    "uniprot": "A0A077Z4R8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4R8"
  },
  {
    "uniprot": "A0A077Z5M5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5M5"
  },
  {
    "uniprot": "A0A077ZJU1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJU1"
  },
  {
    "uniprot": "A0A077ZEV5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEV5"
  },
  {
    "uniprot": "A0A077Z836",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z836"
  },
  {
    "uniprot": "A0A077Z976",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z976"
  },
  {
    "uniprot": "A0A077ZKS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKS9"
  },
  {
    "uniprot": "A0A077ZCB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCB2"
  },
  {
    "uniprot": "A0A077Z0D7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0D7"
  },
  {
    "uniprot": "A0A077ZCN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCN7"
  },
  {
    "uniprot": "A0A077Z393",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z393"
  },
  {
    "uniprot": "A0A077Z1T5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1T5"
  },
  {
    "uniprot": "A0A077Z7V8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7V8"
  },
  {
    "uniprot": "A0A077ZGG0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGG0"
  },
  {
    "uniprot": "A0A077Z812",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z812"
  },
  {
    "uniprot": "A0A077ZGG2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGG2"
  },
  {
    "uniprot": "A0A077ZJW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJW0"
  },
  {
    "uniprot": "A0A077Z9X9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9X9"
  },
  {
    "uniprot": "A0A077ZM59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM59"
  },
  {
    "uniprot": "A0A077ZLS4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLS4"
  },
  {
    "uniprot": "A0A077Z024",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z024"
  },
  {
    "uniprot": "A0A077ZLC1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLC1"
  },
  {
    "uniprot": "A0A077Z6V4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6V4"
  },
  {
    "uniprot": "A0A077ZN98",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN98"
  },
  {
    "uniprot": "A0A077YYR2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYR2"
  },
  {
    "uniprot": "A0A077ZB57",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB57"
  },
  {
    "uniprot": "A0A077YYC2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYC2"
  },
  {
    "uniprot": "A0A077ZJP6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJP6"
  },
  {
    "uniprot": "A0A077ZCR9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCR9"
  },
  {
    "uniprot": "A0A077ZE97",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE97"
  },
  {
    "uniprot": "A0A077Z8Y9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Y9"
  },
  {
    "uniprot": "A0A077ZI79",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI79"
  },
  {
    "uniprot": "A0A077Z1C4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1C4"
  },
  {
    "uniprot": "A0A077ZKD8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKD8"
  },
  {
    "uniprot": "A0A077ZLS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLS9"
  },
  {
    "uniprot": "A0A077ZMC2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMC2"
  },
  {
    "uniprot": "A0A077Z2W7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2W7"
  },
  {
    "uniprot": "A0A077YVY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVY4"
  },
  {
    "uniprot": "A0A077ZMA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMA5"
  },
  {
    "uniprot": "A0A077YWQ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWQ1"
  },
  {
    "uniprot": "A0A077Z1C1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1C1"
  },
  {
    "uniprot": "A0A077Z105",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z105"
  },
  {
    "uniprot": "A0A077Z820",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z820"
  },
  {
    "uniprot": "A0A077Z2F9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2F9"
  },
  {
    "uniprot": "A0A077YZL8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZL8"
  },
  {
    "uniprot": "A0A077Z200",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z200"
  },
  {
    "uniprot": "A0A077YY41",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY41"
  },
  {
    "uniprot": "A0A077Z8V0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8V0"
  },
  {
    "uniprot": "A0A077ZGS7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGS7"
  },
  {
    "uniprot": "A0A077Z2W8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2W8"
  },
  {
    "uniprot": "A0A077ZEN7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEN7"
  },
  {
    "uniprot": "A0A077Z735",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z735"
  },
  {
    "uniprot": "A0A077Z1I5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1I5"
  },
  {
    "uniprot": "A0A077YZU2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZU2"
  },
  {
    "uniprot": "A0A077ZJ20",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ20"
  },
  {
    "uniprot": "A0A077Z8R2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8R2"
  },
  {
    "uniprot": "A0A077ZMQ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMQ2"
  },
  {
    "uniprot": "A0A077YWT3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWT3"
  },
  {
    "uniprot": "A0A077Z129",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z129"
  },
  {
    "uniprot": "A0A077ZIZ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIZ8"
  },
  {
    "uniprot": "A0A077YYC8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYC8"
  },
  {
    "uniprot": "A0A077YZ76",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ76"
  },
  {
    "uniprot": "A0A077Z7F1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7F1"
  },
  {
    "uniprot": "A0A077ZQ83",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ83"
  },
  {
    "uniprot": "A0A077Z0N0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0N0"
  },
  {
    "uniprot": "A0A077ZFB8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFB8"
  },
  {
    "uniprot": "A0A077Z4Y8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Y8"
  },
  {
    "uniprot": "A0A077ZKC0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKC0"
  },
  {
    "uniprot": "A0A077Z0D4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0D4"
  },
  {
    "uniprot": "A0A077ZM45",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM45"
  },
  {
    "uniprot": "A0A077Z6W5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6W5"
  },
  {
    "uniprot": "A0A077ZGE4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGE4"
  },
  {
    "uniprot": "A0A077ZLY9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLY9"
  },
  {
    "uniprot": "A0A077ZC97",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC97"
  },
  {
    "uniprot": "A0A077Z2C8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2C8"
  },
  {
    "uniprot": "A0A077YZK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZK7"
  },
  {
    "uniprot": "A0A077ZBA7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBA7"
  },
  {
    "uniprot": "A0A077ZGT3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGT3"
  },
  {
    "uniprot": "A0A077ZAR6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAR6"
  },
  {
    "uniprot": "A0A077Z0V6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0V6"
  },
  {
    "uniprot": "A0A077Z9E8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9E8"
  },
  {
    "uniprot": "A0A077ZIM7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIM7"
  },
  {
    "uniprot": "A0A077ZFZ0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFZ0"
  },
  {
    "uniprot": "A0A077ZK88",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK88"
  },
  {
    "uniprot": "A0A077ZB26",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB26"
  },
  {
    "uniprot": "A0A077ZFW7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFW7"
  },
  {
    "uniprot": "A0A077Z862",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z862"
  },
  {
    "uniprot": "A0A077ZHP2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHP2"
  },
  {
    "uniprot": "A0A077Z9X7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9X7"
  },
  {
    "uniprot": "A0A077YXP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXP4"
  },
  {
    "uniprot": "A0A077ZHC8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHC8"
  },
  {
    "uniprot": "A0A077Z1I4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1I4"
  },
  {
    "uniprot": "A0A077ZJC2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJC2"
  },
  {
    "uniprot": "A0A077Z7I8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7I8"
  },
  {
    "uniprot": "A0A077Z2J2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2J2"
  },
  {
    "uniprot": "A0A077YWW6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWW6"
  },
  {
    "uniprot": "A0A077ZGG5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGG5"
  },
  {
    "uniprot": "A0A077Z1B0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1B0"
  },
  {
    "uniprot": "A0A077ZF95",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF95"
  },
  {
    "uniprot": "A0A077ZD77",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD77"
  },
  {
    "uniprot": "A0A077YW65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW65"
  },
  {
    "uniprot": "A0A077ZF69",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF69"
  },
  {
    "uniprot": "A0A077Z3C7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3C7"
  },
  {
    "uniprot": "A0A077Z6W7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6W7"
  },
  {
    "uniprot": "A0A077YYZ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYZ2"
  },
  {
    "uniprot": "A0A077ZP36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZP36"
  },
  {
    "uniprot": "A0A077Z152",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z152"
  },
  {
    "uniprot": "A0A077Z385",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z385"
  },
  {
    "uniprot": "A0A077Z2M5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2M5"
  },
  {
    "uniprot": "A0A077ZM36",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM36"
  },
  {
    "uniprot": "A0A077ZF54",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF54"
  },
  {
    "uniprot": "A0A077Z542",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z542"
  },
  {
    "uniprot": "A0A077Z2M0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2M0"
  },
  {
    "uniprot": "A0A077ZIH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIH3"
  },
  {
    "uniprot": "A0A077ZKB6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKB6"
  },
  {
    "uniprot": "A0A077YZV1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZV1"
  },
  {
    "uniprot": "A0A077ZEH0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEH0"
  },
  {
    "uniprot": "A0A077ZC45",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC45"
  },
  {
    "uniprot": "A0A077YWV8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWV8"
  },
  {
    "uniprot": "A0A077ZIQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIQ4"
  },
  {
    "uniprot": "A0A077Z4U9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4U9"
  },
  {
    "uniprot": "A0A077Z537",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z537"
  },
  {
    "uniprot": "A0A077ZM01",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZM01"
  },
  {
    "uniprot": "A0A077ZEG7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEG7"
  },
  {
    "uniprot": "A0A077ZIL8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIL8"
  },
  {
    "uniprot": "A0A077ZAQ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAQ8"
  },
  {
    "uniprot": "A0A077Z292",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z292"
  },
  {
    "uniprot": "A0A077ZDM0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDM0"
  },
  {
    "uniprot": "A0A077Z9C8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9C8"
  },
  {
    "uniprot": "A0A077ZKQ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKQ4"
  },
  {
    "uniprot": "A0A077YZ73",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ73"
  },
  {
    "uniprot": "A0A077Z004",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z004"
  },
  {
    "uniprot": "A0A077YZ94",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ94"
  },
  {
    "uniprot": "A0A077ZJY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJY3"
  },
  {
    "uniprot": "A0A077ZIV6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIV6"
  },
  {
    "uniprot": "A0A077ZHT0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHT0"
  },
  {
    "uniprot": "A0A077YZV3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZV3"
  },
  {
    "uniprot": "A0A077ZAM4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAM4"
  },
  {
    "uniprot": "A0A077ZCP9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCP9"
  },
  {
    "uniprot": "A0A077YYA2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYA2"
  },
  {
    "uniprot": "A0A077ZCK2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCK2"
  },
  {
    "uniprot": "A0A077Z1S3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1S3"
  },
  {
    "uniprot": "A0A077ZQ17",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQ17"
  },
  {
    "uniprot": "A0A077Z0W1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0W1"
  },
  {
    "uniprot": "A0A077ZIW4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIW4"
  },
  {
    "uniprot": "A0A077Z9Q3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9Q3"
  },
  {
    "uniprot": "A0A077Z2R1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2R1"
  },
  {
    "uniprot": "A0A077Z2L0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2L0"
  },
  {
    "uniprot": "A0A077Z035",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z035"
  },
  {
    "uniprot": "A0A077YZM3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZM3"
  },
  {
    "uniprot": "A0A077ZGU5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGU5"
  },
  {
    "uniprot": "A0A077ZK83",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK83"
  },
  {
    "uniprot": "A0A077Z6G4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6G4"
  },
  {
    "uniprot": "A0A077ZHS9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHS9"
  },
  {
    "uniprot": "A0A077Z4Z8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Z8"
  },
  {
    "uniprot": "A0A077ZCJ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCJ4"
  },
  {
    "uniprot": "A0A077Z0Z9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0Z9"
  },
  {
    "uniprot": "A0A077Z4B3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4B3"
  },
  {
    "uniprot": "A0A077Z9X5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9X5"
  },
  {
    "uniprot": "A0A077Z3R3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3R3"
  },
  {
    "uniprot": "A0A077ZIG4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIG4"
  },
  {
    "uniprot": "A0A077ZK94",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK94"
  },
  {
    "uniprot": "A0A077ZBV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBV9"
  },
  {
    "uniprot": "A0A077ZCY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCY3"
  },
  {
    "uniprot": "A0A077YVK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVK7"
  },
  {
    "uniprot": "A0A077ZIK4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIK4"
  },
  {
    "uniprot": "A0A077YWB2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWB2"
  },
  {
    "uniprot": "A0A077Z104",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z104"
  },
  {
    "uniprot": "A0A077ZCM0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCM0"
  },
  {
    "uniprot": "A0A077Z8G0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8G0"
  },
  {
    "uniprot": "A0A077ZLT5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLT5"
  },
  {
    "uniprot": "A0A077Z5M9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5M9"
  },
  {
    "uniprot": "A0A077Z8I7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8I7"
  },
  {
    "uniprot": "A0A077ZDM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDM1"
  },
  {
    "uniprot": "A0A077ZIG2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIG2"
  },
  {
    "uniprot": "A0A077ZBZ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBZ1"
  },
  {
    "uniprot": "A0A077ZAZ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAZ3"
  },
  {
    "uniprot": "A0A077ZIE2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIE2"
  },
  {
    "uniprot": "A0A077YW09",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YW09"
  },
  {
    "uniprot": "A0A077ZAX7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAX7"
  },
  {
    "uniprot": "A0A077Z0T2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0T2"
  },
  {
    "uniprot": "A0A077ZB16",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB16"
  },
  {
    "uniprot": "A0A077YX39",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX39"
  },
  {
    "uniprot": "A0A077Z4M9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4M9"
  },
  {
    "uniprot": "A0A077ZJ65",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJ65"
  },
  {
    "uniprot": "A0A077YZW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZW2"
  },
  {
    "uniprot": "A0A077ZEA2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEA2"
  },
  {
    "uniprot": "A0A077Z2F8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2F8"
  },
  {
    "uniprot": "A0A077Z288",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z288"
  },
  {
    "uniprot": "A0A077Z9G4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9G4"
  },
  {
    "uniprot": "A0A077YWS8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWS8"
  },
  {
    "uniprot": "A0A077Z8Y1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Y1"
  },
  {
    "uniprot": "A0A077ZJK6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJK6"
  },
  {
    "uniprot": "A0A077YZ48",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ48"
  },
  {
    "uniprot": "A0A077ZBD3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBD3"
  },
  {
    "uniprot": "A0A077ZJT3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJT3"
  },
  {
    "uniprot": "A0A077Z5V5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5V5"
  },
  {
    "uniprot": "A0A077Z672",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z672"
  },
  {
    "uniprot": "A0A077Z220",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z220"
  },
  {
    "uniprot": "A0A077Z9R6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9R6"
  },
  {
    "uniprot": "A0A077Z803",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z803"
  },
  {
    "uniprot": "A0A077ZA59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA59"
  },
  {
    "uniprot": "A0A077Z6C9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6C9"
  },
  {
    "uniprot": "A0A077ZLU4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLU4"
  },
  {
    "uniprot": "A0A077Z9N0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9N0"
  },
  {
    "uniprot": "A0A077ZGY2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGY2"
  },
  {
    "uniprot": "A0A077Z5H2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5H2"
  },
  {
    "uniprot": "A0A077Z6G9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6G9"
  },
  {
    "uniprot": "A0A077Z851",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z851"
  },
  {
    "uniprot": "A0A077ZLY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLY4"
  },
  {
    "uniprot": "A0A077Z2B1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2B1"
  },
  {
    "uniprot": "A0A077ZHW8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHW8"
  },
  {
    "uniprot": "A0A077Z5X0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5X0"
  },
  {
    "uniprot": "A0A077YZL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZL7"
  },
  {
    "uniprot": "A0A077ZHQ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHQ1"
  },
  {
    "uniprot": "A0A077Z5T3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5T3"
  },
  {
    "uniprot": "A0A077Z6C8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6C8"
  },
  {
    "uniprot": "A0A077YY76",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY76"
  },
  {
    "uniprot": "A0A077Z7Q3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Q3"
  },
  {
    "uniprot": "A0A077Z958",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z958"
  },
  {
    "uniprot": "A0A077ZFS6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFS6"
  },
  {
    "uniprot": "A0A077ZFB4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFB4"
  },
  {
    "uniprot": "A0A077Z195",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z195"
  },
  {
    "uniprot": "A0A077ZC60",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZC60"
  },
  {
    "uniprot": "A0A077ZHV8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHV8"
  },
  {
    "uniprot": "A0A077Z5J3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5J3"
  },
  {
    "uniprot": "A0A077Z1K8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1K8"
  },
  {
    "uniprot": "A0A077ZFZ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFZ2"
  },
  {
    "uniprot": "A0A077ZBI2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBI2"
  },
  {
    "uniprot": "A0A077Z2X3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2X3"
  },
  {
    "uniprot": "A0A077Z8Y6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Y6"
  },
  {
    "uniprot": "A0A077ZHG6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHG6"
  },
  {
    "uniprot": "A0A077Z5T5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5T5"
  },
  {
    "uniprot": "A0A077Z2J4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2J4"
  },
  {
    "uniprot": "A0A077ZIB1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIB1"
  },
  {
    "uniprot": "A0A077ZHX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHX3"
  },
  {
    "uniprot": "A0A077Z585",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z585"
  },
  {
    "uniprot": "A0A077Z437",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z437"
  },
  {
    "uniprot": "A0A077ZFK0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFK0"
  },
  {
    "uniprot": "A0A077Z038",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z038"
  },
  {
    "uniprot": "A0A077Z3T2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3T2"
  },
  {
    "uniprot": "A0A077ZAK7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAK7"
  },
  {
    "uniprot": "A0A077ZIA2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIA2"
  },
  {
    "uniprot": "A0A077Z8Q4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8Q4"
  },
  {
    "uniprot": "A0A077ZIK9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIK9"
  },
  {
    "uniprot": "A0A077Z015",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z015"
  },
  {
    "uniprot": "A0A077YWR5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWR5"
  },
  {
    "uniprot": "A0A077Z3F0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3F0"
  },
  {
    "uniprot": "A0A077ZGF1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGF1"
  },
  {
    "uniprot": "A0A077ZFI3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFI3"
  },
  {
    "uniprot": "A0A077ZCR4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCR4"
  },
  {
    "uniprot": "A0A077Z5Z0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Z0"
  },
  {
    "uniprot": "A0A077Z556",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z556"
  },
  {
    "uniprot": "A0A077Z2W5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2W5"
  },
  {
    "uniprot": "A0A077YXM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXM8"
  },
  {
    "uniprot": "A0A077ZK56",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK56"
  },
  {
    "uniprot": "A0A077Z8T7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8T7"
  },
  {
    "uniprot": "A0A077Z768",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z768"
  },
  {
    "uniprot": "A0A077Z0K5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0K5"
  },
  {
    "uniprot": "A0A077Z9B5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9B5"
  },
  {
    "uniprot": "A0A077YX72",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX72"
  },
  {
    "uniprot": "A0A077YZ00",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ00"
  },
  {
    "uniprot": "A0A077Z2Z3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Z3"
  },
  {
    "uniprot": "A0A077Z4Z3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4Z3"
  },
  {
    "uniprot": "A0A077ZFQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFQ3"
  },
  {
    "uniprot": "A0A077ZK51",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK51"
  },
  {
    "uniprot": "A0A077ZG09",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG09"
  },
  {
    "uniprot": "A0A077ZER8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZER8"
  },
  {
    "uniprot": "A0A077ZQH8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQH8"
  },
  {
    "uniprot": "A0A077Z7U9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7U9"
  },
  {
    "uniprot": "A0A077ZB59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB59"
  },
  {
    "uniprot": "A0A077ZJX8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJX8"
  },
  {
    "uniprot": "A0A077ZFJ1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFJ1"
  },
  {
    "uniprot": "A0A077YXL5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXL5"
  },
  {
    "uniprot": "A0A077ZJJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJJ8"
  },
  {
    "uniprot": "A0A077YVA8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVA8"
  },
  {
    "uniprot": "A0A077YZE0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZE0"
  },
  {
    "uniprot": "A0A077Z999",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z999"
  },
  {
    "uniprot": "A0A077Z1U5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1U5"
  },
  {
    "uniprot": "A0A077Z0I0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0I0"
  },
  {
    "uniprot": "A0A077Z602",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z602"
  },
  {
    "uniprot": "A0A077Z8T8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8T8"
  },
  {
    "uniprot": "A0A077ZD28",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD28"
  },
  {
    "uniprot": "A0A077YYT1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYT1"
  },
  {
    "uniprot": "A0A077Z6L0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6L0"
  },
  {
    "uniprot": "A0A077ZHX5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHX5"
  },
  {
    "uniprot": "A0A077ZAM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAM2"
  },
  {
    "uniprot": "A0A077Z064",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z064"
  },
  {
    "uniprot": "A0A077Z7F9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7F9"
  },
  {
    "uniprot": "A0A077ZEF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEF0"
  },
  {
    "uniprot": "A0A077ZA82",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZA82"
  },
  {
    "uniprot": "A0A077Z4I9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4I9"
  },
  {
    "uniprot": "A0A077Z9C6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9C6"
  },
  {
    "uniprot": "A0A077Z1Z5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1Z5"
  },
  {
    "uniprot": "A0A077ZB03",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB03"
  },
  {
    "uniprot": "A0A077ZLX8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLX8"
  },
  {
    "uniprot": "A0A077Z4U8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4U8"
  },
  {
    "uniprot": "A0A077Z268",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z268"
  },
  {
    "uniprot": "A0A077Z4K3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4K3"
  },
  {
    "uniprot": "A0A077ZGM1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGM1"
  },
  {
    "uniprot": "A0A077ZIJ2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIJ2"
  },
  {
    "uniprot": "A0A077ZBJ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBJ6"
  },
  {
    "uniprot": "A0A077Z7M9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7M9"
  },
  {
    "uniprot": "A0A077YXF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXF0"
  },
  {
    "uniprot": "A0A077ZHN8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHN8"
  },
  {
    "uniprot": "A0A077ZB06",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZB06"
  },
  {
    "uniprot": "A0A077ZIM5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIM5"
  },
  {
    "uniprot": "A0A077ZE41",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZE41"
  },
  {
    "uniprot": "A0A077ZBW0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBW0"
  },
  {
    "uniprot": "A0A077ZCR7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCR7"
  },
  {
    "uniprot": "A0A077YY11",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY11"
  },
  {
    "uniprot": "A0A077ZD59",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZD59"
  },
  {
    "uniprot": "A0A077ZKM8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKM8"
  },
  {
    "uniprot": "A0A077ZFH7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFH7"
  },
  {
    "uniprot": "A0A077ZHR8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHR8"
  },
  {
    "uniprot": "A0A077Z6Q5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6Q5"
  },
  {
    "uniprot": "A0A077ZF21",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF21"
  },
  {
    "uniprot": "A0A077Z714",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z714"
  },
  {
    "uniprot": "A0A077YYP6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYP6"
  },
  {
    "uniprot": "A0A077Z5P4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5P4"
  },
  {
    "uniprot": "A0A077ZJM0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJM0"
  },
  {
    "uniprot": "A0A077ZGW2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGW2"
  },
  {
    "uniprot": "A0A077ZCF2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCF2"
  },
  {
    "uniprot": "A0A077ZN41",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN41"
  },
  {
    "uniprot": "A0A077Z850",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z850"
  },
  {
    "uniprot": "A0A077Z4P1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4P1"
  },
  {
    "uniprot": "A0A077ZF71",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF71"
  },
  {
    "uniprot": "A0A077YXY2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXY2"
  },
  {
    "uniprot": "A0A077Z6E0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6E0"
  },
  {
    "uniprot": "A0A077Z6B3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6B3"
  },
  {
    "uniprot": "A0A077Z8H4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z8H4"
  },
  {
    "uniprot": "A0A077Z4R7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4R7"
  },
  {
    "uniprot": "A0A077ZN13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN13"
  },
  {
    "uniprot": "A0A077Z918",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z918"
  },
  {
    "uniprot": "A0A077ZFN4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFN4"
  },
  {
    "uniprot": "A0A077ZKL7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKL7"
  },
  {
    "uniprot": "A0A077YZ11",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ11"
  },
  {
    "uniprot": "A0A077Z0K2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0K2"
  },
  {
    "uniprot": "A0A077ZG22",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG22"
  },
  {
    "uniprot": "A0A077Z4W3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4W3"
  },
  {
    "uniprot": "A0A077YZ80",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ80"
  },
  {
    "uniprot": "A0A077ZI13",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI13"
  },
  {
    "uniprot": "A0A077ZLF6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLF6"
  },
  {
    "uniprot": "A0A077Z7X3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7X3"
  },
  {
    "uniprot": "A0A077ZAT5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAT5"
  },
  {
    "uniprot": "A0A077ZF46",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF46"
  },
  {
    "uniprot": "A0A077ZJQ9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJQ9"
  },
  {
    "uniprot": "A0A077ZJE5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZJE5"
  },
  {
    "uniprot": "A0A077ZEW4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEW4"
  },
  {
    "uniprot": "A0A077ZKV9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKV9"
  },
  {
    "uniprot": "A0A077YYJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYJ7"
  },
  {
    "uniprot": "A0A077Z077",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z077"
  },
  {
    "uniprot": "A0A077YYQ3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYQ3"
  },
  {
    "uniprot": "A0A077ZIL3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIL3"
  },
  {
    "uniprot": "A0A077YVW1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVW1"
  },
  {
    "uniprot": "A0A077Z971",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z971"
  },
  {
    "uniprot": "A0A077Z2N0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2N0"
  },
  {
    "uniprot": "A0A077ZKY0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKY0"
  },
  {
    "uniprot": "A0A077ZN86",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN86"
  },
  {
    "uniprot": "A0A077Z406",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z406"
  },
  {
    "uniprot": "A0A077Z5Y6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5Y6"
  },
  {
    "uniprot": "A0A077Z3Q8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3Q8"
  },
  {
    "uniprot": "A0A077ZKH6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZKH6"
  },
  {
    "uniprot": "A0A077YWP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWP3"
  },
  {
    "uniprot": "A0A077ZMZ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMZ6"
  },
  {
    "uniprot": "A0A077Z2P6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2P6"
  },
  {
    "uniprot": "A0A077ZL11",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL11"
  },
  {
    "uniprot": "A0A077ZFZ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFZ6"
  },
  {
    "uniprot": "A0A077ZGK1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZGK1"
  },
  {
    "uniprot": "A0A077YVU0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVU0"
  },
  {
    "uniprot": "A0A077YZP4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZP4"
  },
  {
    "uniprot": "A0A077ZNF7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNF7"
  },
  {
    "uniprot": "A0A077ZEB0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEB0"
  },
  {
    "uniprot": "A0A077YZH3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZH3"
  },
  {
    "uniprot": "A0A077Z0H1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0H1"
  },
  {
    "uniprot": "A0A077Z7Q1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7Q1"
  },
  {
    "uniprot": "A0A077ZNI2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNI2"
  },
  {
    "uniprot": "A0A077ZIJ7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIJ7"
  },
  {
    "uniprot": "A0A077ZCF0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCF0"
  },
  {
    "uniprot": "A0A077ZIB8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIB8"
  },
  {
    "uniprot": "A0A077Z2H2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2H2"
  },
  {
    "uniprot": "A0A077ZDL9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDL9"
  },
  {
    "uniprot": "A0A077ZCF7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCF7"
  },
  {
    "uniprot": "A0A077YWR9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWR9"
  },
  {
    "uniprot": "A0A077ZHK0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHK0"
  },
  {
    "uniprot": "A0A077YZ83",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZ83"
  },
  {
    "uniprot": "A0A077Z4A2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4A2"
  },
  {
    "uniprot": "A0A077Z6T5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6T5"
  },
  {
    "uniprot": "A0A077ZLA3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLA3"
  },
  {
    "uniprot": "A0A077ZLM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLM2"
  },
  {
    "uniprot": "A0A077ZG56",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG56"
  },
  {
    "uniprot": "A0A077YWF3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YWF3"
  },
  {
    "uniprot": "A0A077Z0H3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0H3"
  },
  {
    "uniprot": "A0A077YVJ8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YVJ8"
  },
  {
    "uniprot": "A0A077ZFX3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFX3"
  },
  {
    "uniprot": "A0A077ZIL0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIL0"
  },
  {
    "uniprot": "A0A077Z4N9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4N9"
  },
  {
    "uniprot": "A0A077ZAC6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAC6"
  },
  {
    "uniprot": "A0A077ZG89",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG89"
  },
  {
    "uniprot": "A0A077YXT6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YXT6"
  },
  {
    "uniprot": "A0A077ZDM2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDM2"
  },
  {
    "uniprot": "A0A077Z1P6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1P6"
  },
  {
    "uniprot": "A0A077Z7D5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7D5"
  },
  {
    "uniprot": "A0A077ZHV7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZHV7"
  },
  {
    "uniprot": "A0A077ZBY3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBY3"
  },
  {
    "uniprot": "A0A077Z7V4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7V4"
  },
  {
    "uniprot": "A0A077Z2N1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2N1"
  },
  {
    "uniprot": "A0A077ZMP3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZMP3"
  },
  {
    "uniprot": "A0A077Z892",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z892"
  },
  {
    "uniprot": "A0A077YYA5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YYA5"
  },
  {
    "uniprot": "A0A077ZIZ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZIZ6"
  },
  {
    "uniprot": "A0A077Z7W9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7W9"
  },
  {
    "uniprot": "A0A077Z4V7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4V7"
  },
  {
    "uniprot": "A0A077ZQD5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQD5"
  },
  {
    "uniprot": "A0A077Z544",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z544"
  },
  {
    "uniprot": "A0A077YY99",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YY99"
  },
  {
    "uniprot": "A0A077Z2K1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2K1"
  },
  {
    "uniprot": "A0A077ZN07",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN07"
  },
  {
    "uniprot": "A0A077Z0X1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0X1"
  },
  {
    "uniprot": "A0A077ZCV2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCV2"
  },
  {
    "uniprot": "A0A077Z2S6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2S6"
  },
  {
    "uniprot": "A0A077ZLP8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLP8"
  },
  {
    "uniprot": "A0A077ZF64",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF64"
  },
  {
    "uniprot": "A0A077ZFC1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFC1"
  },
  {
    "uniprot": "A0A077YZC4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZC4"
  },
  {
    "uniprot": "A0A077Z5A8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z5A8"
  },
  {
    "uniprot": "A0A077YZX0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZX0"
  },
  {
    "uniprot": "A0A077ZCX1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCX1"
  },
  {
    "uniprot": "A0A077ZEJ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEJ6"
  },
  {
    "uniprot": "A0A077ZF86",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF86"
  },
  {
    "uniprot": "A0A077Z4V8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4V8"
  },
  {
    "uniprot": "A0A077Z4C3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z4C3"
  },
  {
    "uniprot": "A0A077Z3M6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3M6"
  },
  {
    "uniprot": "A0A077ZCP7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCP7"
  },
  {
    "uniprot": "A0A077ZQW5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQW5"
  },
  {
    "uniprot": "A0A077ZAH2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZAH2"
  },
  {
    "uniprot": "A0A077ZN89",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZN89"
  },
  {
    "uniprot": "A0A077Z9A8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9A8"
  },
  {
    "uniprot": "A0A077YX98",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YX98"
  },
  {
    "uniprot": "A0A077ZI11",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI11"
  },
  {
    "uniprot": "A0A077Z7M2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7M2"
  },
  {
    "uniprot": "A0A077Z0W8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z0W8"
  },
  {
    "uniprot": "A0A077Z6G0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6G0"
  },
  {
    "uniprot": "A0A077ZK78",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZK78"
  },
  {
    "uniprot": "A0A077ZFV8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFV8"
  },
  {
    "uniprot": "A0A077Z2Q5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2Q5"
  },
  {
    "uniprot": "A0A077ZQU9",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZQU9"
  },
  {
    "uniprot": "A0A077Z9I7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9I7"
  },
  {
    "uniprot": "A0A077Z455",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z455"
  },
  {
    "uniprot": "A0A077Z3K1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3K1"
  },
  {
    "uniprot": "A0A077ZEG3",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZEG3"
  },
  {
    "uniprot": "A0A077YZX8",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077YZX8"
  },
  {
    "uniprot": "A0A077Z663",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z663"
  },
  {
    "uniprot": "A0A077ZL94",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZL94"
  },
  {
    "uniprot": "A0A077Z6J6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6J6"
  },
  {
    "uniprot": "A0A077ZFM0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZFM0"
  },
  {
    "uniprot": "A0A077Z2H5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2H5"
  },
  {
    "uniprot": "A0A077ZG50",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZG50"
  },
  {
    "uniprot": "A0A077Z9F2",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9F2"
  },
  {
    "uniprot": "A0A077ZI91",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZI91"
  },
  {
    "uniprot": "A0A077Z2T6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z2T6"
  },
  {
    "uniprot": "A0A077Z6R0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z6R0"
  },
  {
    "uniprot": "A0A077ZDT4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDT4"
  },
  {
    "uniprot": "A0A077ZDQ6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZDQ6"
  },
  {
    "uniprot": "A0A077ZF08",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZF08"
  },
  {
    "uniprot": "A0A077ZNH6",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNH6"
  },
  {
    "uniprot": "A0A077ZBP0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZBP0"
  },
  {
    "uniprot": "A0A077Z1E1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z1E1"
  },
  {
    "uniprot": "A0A077Z3J1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z3J1"
  },
  {
    "uniprot": "A0A077Z7R0",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z7R0"
  },
  {
    "uniprot": "A0A077ZNC1",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZNC1"
  },
  {
    "uniprot": "A0A077Z9P5",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z9P5"
  },
  {
    "uniprot": "A0A077ZLH7",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZLH7"
  },
  {
    "uniprot": "A0A077ZPJ4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZPJ4"
  },
  {
    "uniprot": "A0A077Z332",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077Z332"
  },
  {
    "uniprot": "A0A077ZCY4",
    "species": "Trichuris trichiura",
    "disease": "Trichuriasis",
    "results": "/results/A0A077ZCY4"
  },
  {
    "uniprot": "Q4DT51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT51"
  },
  {
    "uniprot": "Q4CVW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVW7"
  },
  {
    "uniprot": "Q4D407",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D407"
  },
  {
    "uniprot": "Q4DIZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIZ9"
  },
  {
    "uniprot": "Q4CKT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKT4"
  },
  {
    "uniprot": "Q4DYL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYL7"
  },
  {
    "uniprot": "Q4DSP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSP6"
  },
  {
    "uniprot": "Q4CKI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKI9"
  },
  {
    "uniprot": "Q4DM16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM16"
  },
  {
    "uniprot": "Q4CLK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLK3"
  },
  {
    "uniprot": "Q4CL19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL19"
  },
  {
    "uniprot": "Q4DYJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYJ1"
  },
  {
    "uniprot": "Q4D5R0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5R0"
  },
  {
    "uniprot": "Q4DS34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS34"
  },
  {
    "uniprot": "Q4DTT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTT9"
  },
  {
    "uniprot": "Q4DJX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJX8"
  },
  {
    "uniprot": "Q4CSP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSP2"
  },
  {
    "uniprot": "Q4CV86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV86"
  },
  {
    "uniprot": "Q4CSF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSF4"
  },
  {
    "uniprot": "Q4DG80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG80"
  },
  {
    "uniprot": "Q4DQB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQB6"
  },
  {
    "uniprot": "Q4DF59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF59"
  },
  {
    "uniprot": "Q4DFJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFJ8"
  },
  {
    "uniprot": "Q4DKN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKN1"
  },
  {
    "uniprot": "Q4DEI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEI3"
  },
  {
    "uniprot": "Q4DJX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJX6"
  },
  {
    "uniprot": "Q4DDV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDV5"
  },
  {
    "uniprot": "Q4CVN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVN6"
  },
  {
    "uniprot": "Q4D4D0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4D0"
  },
  {
    "uniprot": "Q4DDQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDQ1"
  },
  {
    "uniprot": "Q4DLL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLL6"
  },
  {
    "uniprot": "Q4CNN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNN9"
  },
  {
    "uniprot": "Q4DNS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNS2"
  },
  {
    "uniprot": "Q4DHB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHB3"
  },
  {
    "uniprot": "Q4D178",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D178"
  },
  {
    "uniprot": "Q4D4G9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4G9"
  },
  {
    "uniprot": "Q4E191",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E191"
  },
  {
    "uniprot": "Q4CXD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXD5"
  },
  {
    "uniprot": "Q4E051",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E051"
  },
  {
    "uniprot": "Q4D198",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D198"
  },
  {
    "uniprot": "Q4DLY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLY0"
  },
  {
    "uniprot": "Q4DL11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL11"
  },
  {
    "uniprot": "Q4D7Q0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Q0"
  },
  {
    "uniprot": "Q4E3T6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3T6"
  },
  {
    "uniprot": "Q4DDH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDH3"
  },
  {
    "uniprot": "Q4E452",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E452"
  },
  {
    "uniprot": "Q4CX24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX24"
  },
  {
    "uniprot": "Q4DYN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYN1"
  },
  {
    "uniprot": "Q4CU30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU30"
  },
  {
    "uniprot": "Q4D5K7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5K7"
  },
  {
    "uniprot": "Q4DMY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMY8"
  },
  {
    "uniprot": "Q4CSQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSQ4"
  },
  {
    "uniprot": "Q4CXN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXN7"
  },
  {
    "uniprot": "Q4DTP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTP2"
  },
  {
    "uniprot": "Q4CNA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNA3"
  },
  {
    "uniprot": "Q4DED0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DED0"
  },
  {
    "uniprot": "Q4E2G1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2G1"
  },
  {
    "uniprot": "Q4D5G4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5G4"
  },
  {
    "uniprot": "Q4CLV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLV5"
  },
  {
    "uniprot": "Q4CUU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUU5"
  },
  {
    "uniprot": "Q4DHK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHK2"
  },
  {
    "uniprot": "Q4CXE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXE8"
  },
  {
    "uniprot": "Q4DIF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIF8"
  },
  {
    "uniprot": "Q4DIT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIT3"
  },
  {
    "uniprot": "Q4DFA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFA7"
  },
  {
    "uniprot": "Q4D907",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D907"
  },
  {
    "uniprot": "Q4CKS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKS9"
  },
  {
    "uniprot": "Q4DS53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS53"
  },
  {
    "uniprot": "Q4CZT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZT3"
  },
  {
    "uniprot": "Q4CSC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSC9"
  },
  {
    "uniprot": "Q4CRX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRX4"
  },
  {
    "uniprot": "Q4D0K6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0K6"
  },
  {
    "uniprot": "Q4CUZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUZ1"
  },
  {
    "uniprot": "Q4DHQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHQ8"
  },
  {
    "uniprot": "Q4D786",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D786"
  },
  {
    "uniprot": "Q4DCB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCB0"
  },
  {
    "uniprot": "Q4D853",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D853"
  },
  {
    "uniprot": "Q4DNK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNK0"
  },
  {
    "uniprot": "Q4D5H9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5H9"
  },
  {
    "uniprot": "Q4D9Y5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Y5"
  },
  {
    "uniprot": "Q4CS71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS71"
  },
  {
    "uniprot": "Q4CVG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVG6"
  },
  {
    "uniprot": "Q4D059",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D059"
  },
  {
    "uniprot": "Q4DKX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKX0"
  },
  {
    "uniprot": "Q4DU54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU54"
  },
  {
    "uniprot": "Q4DJ72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ72"
  },
  {
    "uniprot": "Q4CZR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZR8"
  },
  {
    "uniprot": "Q4DZF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZF2"
  },
  {
    "uniprot": "Q4DLK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLK9"
  },
  {
    "uniprot": "Q4E386",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E386"
  },
  {
    "uniprot": "Q4DKF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKF6"
  },
  {
    "uniprot": "Q4DDB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDB8"
  },
  {
    "uniprot": "Q4CT64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT64"
  },
  {
    "uniprot": "Q4E2V1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2V1"
  },
  {
    "uniprot": "Q4CYK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYK9"
  },
  {
    "uniprot": "Q4DPM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPM7"
  },
  {
    "uniprot": "Q4DIQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIQ0"
  },
  {
    "uniprot": "Q4CR13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR13"
  },
  {
    "uniprot": "Q4D5S3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5S3"
  },
  {
    "uniprot": "Q4D2N4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2N4"
  },
  {
    "uniprot": "Q4CQQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQQ4"
  },
  {
    "uniprot": "Q4DND6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DND6"
  },
  {
    "uniprot": "Q4CNX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNX5"
  },
  {
    "uniprot": "Q4DH11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH11"
  },
  {
    "uniprot": "Q4DG75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG75"
  },
  {
    "uniprot": "Q4DXC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXC2"
  },
  {
    "uniprot": "Q4E0L9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0L9"
  },
  {
    "uniprot": "Q4DIM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIM4"
  },
  {
    "uniprot": "Q4DKS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKS4"
  },
  {
    "uniprot": "Q4CYR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYR9"
  },
  {
    "uniprot": "Q4DY65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY65"
  },
  {
    "uniprot": "Q4DNW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNW4"
  },
  {
    "uniprot": "Q4DNZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNZ2"
  },
  {
    "uniprot": "Q4CLL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLL7"
  },
  {
    "uniprot": "Q4CMU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMU1"
  },
  {
    "uniprot": "Q4DS33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS33"
  },
  {
    "uniprot": "Q4CPY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPY0"
  },
  {
    "uniprot": "Q4DKN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKN0"
  },
  {
    "uniprot": "Q4D0B8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0B8"
  },
  {
    "uniprot": "Q4CVW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVW4"
  },
  {
    "uniprot": "Q4DGA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGA9"
  },
  {
    "uniprot": "Q4DG90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG90"
  },
  {
    "uniprot": "Q4CRV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRV7"
  },
  {
    "uniprot": "Q4D442",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D442"
  },
  {
    "uniprot": "Q4DLF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLF2"
  },
  {
    "uniprot": "Q4DFC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFC3"
  },
  {
    "uniprot": "Q4D1W3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1W3"
  },
  {
    "uniprot": "Q4DVE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVE3"
  },
  {
    "uniprot": "Q4DWJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWJ3"
  },
  {
    "uniprot": "Q4DTP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTP6"
  },
  {
    "uniprot": "Q4DBI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBI7"
  },
  {
    "uniprot": "Q4DR07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR07"
  },
  {
    "uniprot": "Q4CMP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMP3"
  },
  {
    "uniprot": "Q4CYD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYD6"
  },
  {
    "uniprot": "Q4E266",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E266"
  },
  {
    "uniprot": "Q4DHJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHJ5"
  },
  {
    "uniprot": "Q4DC94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC94"
  },
  {
    "uniprot": "Q4CXK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXK8"
  },
  {
    "uniprot": "Q4D4W7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4W7"
  },
  {
    "uniprot": "Q4E1H4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1H4"
  },
  {
    "uniprot": "Q4CTC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTC0"
  },
  {
    "uniprot": "Q4E1U2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1U2"
  },
  {
    "uniprot": "Q4E1I2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1I2"
  },
  {
    "uniprot": "Q4CZU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZU3"
  },
  {
    "uniprot": "Q4DSZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSZ1"
  },
  {
    "uniprot": "Q4DYE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYE5"
  },
  {
    "uniprot": "Q4CW03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW03"
  },
  {
    "uniprot": "Q4DRK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRK1"
  },
  {
    "uniprot": "Q4DWB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWB4"
  },
  {
    "uniprot": "Q4D122",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D122"
  },
  {
    "uniprot": "Q4DBW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBW6"
  },
  {
    "uniprot": "Q4DI25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI25"
  },
  {
    "uniprot": "Q4E405",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E405"
  },
  {
    "uniprot": "Q4CQK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQK8"
  },
  {
    "uniprot": "Q4DHR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHR3"
  },
  {
    "uniprot": "Q4D841",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D841"
  },
  {
    "uniprot": "Q4CXN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXN1"
  },
  {
    "uniprot": "Q4E0S2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0S2"
  },
  {
    "uniprot": "Q4D8C9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8C9"
  },
  {
    "uniprot": "Q4DG72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG72"
  },
  {
    "uniprot": "Q4DGS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGS1"
  },
  {
    "uniprot": "Q4CU32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU32"
  },
  {
    "uniprot": "Q4DU28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU28"
  },
  {
    "uniprot": "Q4CMV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMV5"
  },
  {
    "uniprot": "Q4DP92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP92"
  },
  {
    "uniprot": "Q4D190",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D190"
  },
  {
    "uniprot": "Q4D4F0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4F0"
  },
  {
    "uniprot": "Q4E162",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E162"
  },
  {
    "uniprot": "Q4CNM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNM4"
  },
  {
    "uniprot": "Q4CZU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZU8"
  },
  {
    "uniprot": "Q4DA46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA46"
  },
  {
    "uniprot": "Q4D2N3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2N3"
  },
  {
    "uniprot": "Q4CVT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVT8"
  },
  {
    "uniprot": "Q4DEI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEI5"
  },
  {
    "uniprot": "Q4DP86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP86"
  },
  {
    "uniprot": "Q4D574",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D574"
  },
  {
    "uniprot": "Q4DEK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEK9"
  },
  {
    "uniprot": "Q4E4W0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4W0"
  },
  {
    "uniprot": "Q4DEM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEM0"
  },
  {
    "uniprot": "Q4E1M3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1M3"
  },
  {
    "uniprot": "Q4DPS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPS8"
  },
  {
    "uniprot": "Q4D584",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D584"
  },
  {
    "uniprot": "Q4E109",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E109"
  },
  {
    "uniprot": "Q4DE86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE86"
  },
  {
    "uniprot": "Q4DRJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRJ0"
  },
  {
    "uniprot": "Q4DXR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXR8"
  },
  {
    "uniprot": "Q4DXE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXE6"
  },
  {
    "uniprot": "Q4DRG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRG6"
  },
  {
    "uniprot": "Q4CLH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLH0"
  },
  {
    "uniprot": "Q4DMS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMS9"
  },
  {
    "uniprot": "Q4D3D5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3D5"
  },
  {
    "uniprot": "Q4CM52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM52"
  },
  {
    "uniprot": "Q4E046",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E046"
  },
  {
    "uniprot": "Q4CRR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRR9"
  },
  {
    "uniprot": "Q4CW00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW00"
  },
  {
    "uniprot": "Q4DF72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF72"
  },
  {
    "uniprot": "Q4D2S9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2S9"
  },
  {
    "uniprot": "Q4DP64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP64"
  },
  {
    "uniprot": "Q4DZV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZV3"
  },
  {
    "uniprot": "Q4CNL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNL4"
  },
  {
    "uniprot": "Q4DSG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSG9"
  },
  {
    "uniprot": "Q4DX42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX42"
  },
  {
    "uniprot": "Q4E3J9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3J9"
  },
  {
    "uniprot": "Q4DSG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSG1"
  },
  {
    "uniprot": "Q4D6D8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6D8"
  },
  {
    "uniprot": "Q4D3S6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3S6"
  },
  {
    "uniprot": "Q4E0X4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0X4"
  },
  {
    "uniprot": "Q4DIN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIN5"
  },
  {
    "uniprot": "Q4CU35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU35"
  },
  {
    "uniprot": "Q4DI13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI13"
  },
  {
    "uniprot": "Q4DTG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTG0"
  },
  {
    "uniprot": "Q4DC97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC97"
  },
  {
    "uniprot": "Q4DDR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDR9"
  },
  {
    "uniprot": "Q4DQG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQG9"
  },
  {
    "uniprot": "Q4DUL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUL1"
  },
  {
    "uniprot": "Q4DFR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFR3"
  },
  {
    "uniprot": "Q4DPB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPB5"
  },
  {
    "uniprot": "Q4CS07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS07"
  },
  {
    "uniprot": "Q4E1R4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1R4"
  },
  {
    "uniprot": "Q4DQ30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ30"
  },
  {
    "uniprot": "Q4D873",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D873"
  },
  {
    "uniprot": "Q4DV85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV85"
  },
  {
    "uniprot": "Q4DNZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNZ7"
  },
  {
    "uniprot": "Q4DJ89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ89"
  },
  {
    "uniprot": "Q4DNH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNH1"
  },
  {
    "uniprot": "Q4CMX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMX5"
  },
  {
    "uniprot": "Q4DUC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUC1"
  },
  {
    "uniprot": "Q4DKV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKV1"
  },
  {
    "uniprot": "Q4CZS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZS0"
  },
  {
    "uniprot": "Q4E3Q6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Q6"
  },
  {
    "uniprot": "Q4CRQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRQ8"
  },
  {
    "uniprot": "Q4D956",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D956"
  },
  {
    "uniprot": "Q4DS23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS23"
  },
  {
    "uniprot": "Q4DTZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTZ4"
  },
  {
    "uniprot": "Q4D227",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D227"
  },
  {
    "uniprot": "Q4DNF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNF0"
  },
  {
    "uniprot": "Q4CL95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL95"
  },
  {
    "uniprot": "Q4DC85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC85"
  },
  {
    "uniprot": "Q4D4B4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4B4"
  },
  {
    "uniprot": "Q4D167",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D167"
  },
  {
    "uniprot": "Q4DU24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU24"
  },
  {
    "uniprot": "Q4DRC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRC2"
  },
  {
    "uniprot": "Q4E426",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E426"
  },
  {
    "uniprot": "Q4CNW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNW1"
  },
  {
    "uniprot": "Q4DZD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZD3"
  },
  {
    "uniprot": "Q4D6A1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6A1"
  },
  {
    "uniprot": "Q4D4G4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4G4"
  },
  {
    "uniprot": "Q4DWB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWB7"
  },
  {
    "uniprot": "Q4CKA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKA4"
  },
  {
    "uniprot": "Q4DAN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAN4"
  },
  {
    "uniprot": "Q4DXZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXZ1"
  },
  {
    "uniprot": "Q4CV44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV44"
  },
  {
    "uniprot": "Q4CLA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLA3"
  },
  {
    "uniprot": "Q4D4V3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4V3"
  },
  {
    "uniprot": "Q4DH38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH38"
  },
  {
    "uniprot": "Q4DE07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE07"
  },
  {
    "uniprot": "Q4DNI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNI8"
  },
  {
    "uniprot": "Q4DW61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW61"
  },
  {
    "uniprot": "Q4DDU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDU6"
  },
  {
    "uniprot": "Q4CSU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSU2"
  },
  {
    "uniprot": "Q4DXN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXN4"
  },
  {
    "uniprot": "Q4DSE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSE9"
  },
  {
    "uniprot": "Q4D8R4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8R4"
  },
  {
    "uniprot": "Q4CL78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL78"
  },
  {
    "uniprot": "Q4D4L5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4L5"
  },
  {
    "uniprot": "Q4CM87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM87"
  },
  {
    "uniprot": "Q4DWT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWT0"
  },
  {
    "uniprot": "Q4DQI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQI5"
  },
  {
    "uniprot": "Q4DGT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGT5"
  },
  {
    "uniprot": "Q4DZF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZF6"
  },
  {
    "uniprot": "Q4DUU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUU4"
  },
  {
    "uniprot": "Q4DSY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSY3"
  },
  {
    "uniprot": "Q4E4Q4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Q4"
  },
  {
    "uniprot": "Q4DLV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLV5"
  },
  {
    "uniprot": "Q4DLC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLC1"
  },
  {
    "uniprot": "Q4E0Y9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Y9"
  },
  {
    "uniprot": "Q4CVE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVE9"
  },
  {
    "uniprot": "Q4DW34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW34"
  },
  {
    "uniprot": "Q4CX38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX38"
  },
  {
    "uniprot": "Q4CKF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKF7"
  },
  {
    "uniprot": "Q4DQU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQU6"
  },
  {
    "uniprot": "Q4CQ45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ45"
  },
  {
    "uniprot": "Q4DCP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCP8"
  },
  {
    "uniprot": "Q4DMB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMB6"
  },
  {
    "uniprot": "Q4CMB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMB4"
  },
  {
    "uniprot": "Q4DSG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSG5"
  },
  {
    "uniprot": "Q4E3I7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3I7"
  },
  {
    "uniprot": "Q4CME7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CME7"
  },
  {
    "uniprot": "Q4DL68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL68"
  },
  {
    "uniprot": "Q4D6C8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6C8"
  },
  {
    "uniprot": "Q4CWZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWZ5"
  },
  {
    "uniprot": "Q4DRK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRK2"
  },
  {
    "uniprot": "Q4DGE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGE6"
  },
  {
    "uniprot": "Q4E3F0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3F0"
  },
  {
    "uniprot": "Q4DEM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEM6"
  },
  {
    "uniprot": "Q4CTD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTD5"
  },
  {
    "uniprot": "Q4D1G4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1G4"
  },
  {
    "uniprot": "Q4E430",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E430"
  },
  {
    "uniprot": "Q4D734",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D734"
  },
  {
    "uniprot": "Q4DVL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVL5"
  },
  {
    "uniprot": "Q4DXU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXU6"
  },
  {
    "uniprot": "Q4D4N0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4N0"
  },
  {
    "uniprot": "Q4CLJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLJ5"
  },
  {
    "uniprot": "Q4CN01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN01"
  },
  {
    "uniprot": "Q4DQB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQB7"
  },
  {
    "uniprot": "Q4DBK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBK6"
  },
  {
    "uniprot": "Q4DKB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKB8"
  },
  {
    "uniprot": "Q4CKP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKP6"
  },
  {
    "uniprot": "Q4CVM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVM7"
  },
  {
    "uniprot": "Q4E4Z0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Z0"
  },
  {
    "uniprot": "Q4DI20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI20"
  },
  {
    "uniprot": "Q4CSD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSD0"
  },
  {
    "uniprot": "Q4DKT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKT3"
  },
  {
    "uniprot": "Q4DNF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNF1"
  },
  {
    "uniprot": "Q4DZP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZP3"
  },
  {
    "uniprot": "Q4DTI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTI7"
  },
  {
    "uniprot": "Q4E1G6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1G6"
  },
  {
    "uniprot": "Q4D6Q0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Q0"
  },
  {
    "uniprot": "Q4DR75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR75"
  },
  {
    "uniprot": "Q4DF58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF58"
  },
  {
    "uniprot": "Q4E0E4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0E4"
  },
  {
    "uniprot": "Q4DWX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWX0"
  },
  {
    "uniprot": "Q4D559",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D559"
  },
  {
    "uniprot": "Q4CWB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWB3"
  },
  {
    "uniprot": "Q4D486",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D486"
  },
  {
    "uniprot": "Q4DL96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL96"
  },
  {
    "uniprot": "Q4CR45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR45"
  },
  {
    "uniprot": "Q4DQF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQF0"
  },
  {
    "uniprot": "Q4DQ40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ40"
  },
  {
    "uniprot": "Q4D6F4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6F4"
  },
  {
    "uniprot": "Q4DC52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC52"
  },
  {
    "uniprot": "Q4E280",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E280"
  },
  {
    "uniprot": "Q4DIH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIH9"
  },
  {
    "uniprot": "Q4CT87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT87"
  },
  {
    "uniprot": "Q4DFV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFV0"
  },
  {
    "uniprot": "Q4E2K2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2K2"
  },
  {
    "uniprot": "Q4DZL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZL6"
  },
  {
    "uniprot": "Q4DCE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCE2"
  },
  {
    "uniprot": "Q4DB38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB38"
  },
  {
    "uniprot": "Q4D049",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D049"
  },
  {
    "uniprot": "Q4CRU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRU3"
  },
  {
    "uniprot": "Q4DUJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUJ7"
  },
  {
    "uniprot": "Q4CLP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLP6"
  },
  {
    "uniprot": "Q4CLU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLU1"
  },
  {
    "uniprot": "Q4CVU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVU7"
  },
  {
    "uniprot": "Q4CPA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPA2"
  },
  {
    "uniprot": "Q4CSP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSP7"
  },
  {
    "uniprot": "Q4D2Q3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Q3"
  },
  {
    "uniprot": "Q4CXV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXV4"
  },
  {
    "uniprot": "Q4DA00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA00"
  },
  {
    "uniprot": "Q4CVW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVW8"
  },
  {
    "uniprot": "Q4D4R0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4R0"
  },
  {
    "uniprot": "Q4DUT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUT2"
  },
  {
    "uniprot": "Q4D2N0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2N0"
  },
  {
    "uniprot": "Q4E5J0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5J0"
  },
  {
    "uniprot": "Q4E1I0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1I0"
  },
  {
    "uniprot": "Q4D6F2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6F2"
  },
  {
    "uniprot": "Q4DIZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIZ0"
  },
  {
    "uniprot": "Q4E4N2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4N2"
  },
  {
    "uniprot": "Q4CPI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPI3"
  },
  {
    "uniprot": "Q4DY02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY02"
  },
  {
    "uniprot": "Q4DYW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYW2"
  },
  {
    "uniprot": "Q4CTG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTG3"
  },
  {
    "uniprot": "Q4D725",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D725"
  },
  {
    "uniprot": "Q4CTS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTS9"
  },
  {
    "uniprot": "Q4DSZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSZ8"
  },
  {
    "uniprot": "Q4DDA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDA7"
  },
  {
    "uniprot": "Q4DAV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAV1"
  },
  {
    "uniprot": "Q4CQV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQV1"
  },
  {
    "uniprot": "Q4E4M8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4M8"
  },
  {
    "uniprot": "Q4E0Q8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Q8"
  },
  {
    "uniprot": "Q4CMR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMR8"
  },
  {
    "uniprot": "Q4CWW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWW5"
  },
  {
    "uniprot": "Q4D0A7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0A7"
  },
  {
    "uniprot": "Q4DFD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFD0"
  },
  {
    "uniprot": "Q4CYS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYS2"
  },
  {
    "uniprot": "Q4D804",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D804"
  },
  {
    "uniprot": "Q4CQI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQI3"
  },
  {
    "uniprot": "Q4D9V6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9V6"
  },
  {
    "uniprot": "Q4CX29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX29"
  },
  {
    "uniprot": "Q4DIA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIA0"
  },
  {
    "uniprot": "Q4E4D2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4D2"
  },
  {
    "uniprot": "Q4CWV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWV3"
  },
  {
    "uniprot": "Q4DGE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGE5"
  },
  {
    "uniprot": "Q4DRD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRD3"
  },
  {
    "uniprot": "Q4DLD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLD4"
  },
  {
    "uniprot": "Q4DP17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP17"
  },
  {
    "uniprot": "Q4CRQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRQ4"
  },
  {
    "uniprot": "Q4CQY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQY3"
  },
  {
    "uniprot": "Q4D405",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D405"
  },
  {
    "uniprot": "Q4DD82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD82"
  },
  {
    "uniprot": "Q4CV47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV47"
  },
  {
    "uniprot": "Q4E5C4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5C4"
  },
  {
    "uniprot": "Q4DTG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTG9"
  },
  {
    "uniprot": "Q4DM17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM17"
  },
  {
    "uniprot": "Q4DX52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX52"
  },
  {
    "uniprot": "Q4E1H8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1H8"
  },
  {
    "uniprot": "Q4CM31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM31"
  },
  {
    "uniprot": "Q4CMW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMW4"
  },
  {
    "uniprot": "Q4DKF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKF1"
  },
  {
    "uniprot": "Q4DHD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHD5"
  },
  {
    "uniprot": "Q4D402",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D402"
  },
  {
    "uniprot": "Q4CNC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNC0"
  },
  {
    "uniprot": "Q4DRB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRB3"
  },
  {
    "uniprot": "Q4D9I6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9I6"
  },
  {
    "uniprot": "Q4D3T0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3T0"
  },
  {
    "uniprot": "Q4E139",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E139"
  },
  {
    "uniprot": "Q4E644",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E644"
  },
  {
    "uniprot": "Q4CQW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQW1"
  },
  {
    "uniprot": "Q4D2T4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2T4"
  },
  {
    "uniprot": "Q4D3M8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3M8"
  },
  {
    "uniprot": "Q4DK71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK71"
  },
  {
    "uniprot": "Q4DLB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLB5"
  },
  {
    "uniprot": "Q4CYM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYM5"
  },
  {
    "uniprot": "Q4DAA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAA1"
  },
  {
    "uniprot": "Q4CPU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPU5"
  },
  {
    "uniprot": "Q4D940",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D940"
  },
  {
    "uniprot": "Q4DUC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUC8"
  },
  {
    "uniprot": "Q4DPR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPR2"
  },
  {
    "uniprot": "Q4D771",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D771"
  },
  {
    "uniprot": "Q4CQC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQC8"
  },
  {
    "uniprot": "Q4E0K7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0K7"
  },
  {
    "uniprot": "Q4E2G9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2G9"
  },
  {
    "uniprot": "Q4DC23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC23"
  },
  {
    "uniprot": "Q4DI47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI47"
  },
  {
    "uniprot": "Q4CZL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZL6"
  },
  {
    "uniprot": "Q4CXM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXM5"
  },
  {
    "uniprot": "Q4D9M8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9M8"
  },
  {
    "uniprot": "Q4D2E1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2E1"
  },
  {
    "uniprot": "Q4DAU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAU8"
  },
  {
    "uniprot": "Q4DA01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA01"
  },
  {
    "uniprot": "Q4DGU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGU3"
  },
  {
    "uniprot": "Q4E1Z9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Z9"
  },
  {
    "uniprot": "Q4DZ34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ34"
  },
  {
    "uniprot": "Q4DG03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG03"
  },
  {
    "uniprot": "Q4DWH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWH3"
  },
  {
    "uniprot": "Q4CRC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRC4"
  },
  {
    "uniprot": "Q4DQX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQX7"
  },
  {
    "uniprot": "Q4CUX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUX7"
  },
  {
    "uniprot": "Q4CW10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW10"
  },
  {
    "uniprot": "Q4DS76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS76"
  },
  {
    "uniprot": "Q4DZF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZF0"
  },
  {
    "uniprot": "Q4DMH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMH1"
  },
  {
    "uniprot": "Q4D4A8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4A8"
  },
  {
    "uniprot": "Q4DML5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DML5"
  },
  {
    "uniprot": "Q4DM91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM91"
  },
  {
    "uniprot": "Q4DN33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN33"
  },
  {
    "uniprot": "Q4CKE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKE5"
  },
  {
    "uniprot": "Q4DR40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR40"
  },
  {
    "uniprot": "Q4CX72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX72"
  },
  {
    "uniprot": "Q4E2M8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2M8"
  },
  {
    "uniprot": "Q4E0N3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0N3"
  },
  {
    "uniprot": "Q4D7U9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7U9"
  },
  {
    "uniprot": "Q4E0J4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0J4"
  },
  {
    "uniprot": "Q4CLQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLQ4"
  },
  {
    "uniprot": "Q4D014",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D014"
  },
  {
    "uniprot": "Q4CTA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTA5"
  },
  {
    "uniprot": "Q4E190",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E190"
  },
  {
    "uniprot": "Q4DQL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQL5"
  },
  {
    "uniprot": "Q4CNJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNJ1"
  },
  {
    "uniprot": "Q4DXD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXD6"
  },
  {
    "uniprot": "Q4DKP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKP9"
  },
  {
    "uniprot": "Q4DL51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL51"
  },
  {
    "uniprot": "Q4DB92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB92"
  },
  {
    "uniprot": "Q4DNE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNE5"
  },
  {
    "uniprot": "Q4CV48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV48"
  },
  {
    "uniprot": "Q4CPC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPC7"
  },
  {
    "uniprot": "Q4DAP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAP8"
  },
  {
    "uniprot": "Q4DWQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWQ4"
  },
  {
    "uniprot": "Q4E301",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E301"
  },
  {
    "uniprot": "Q4D3Y4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Y4"
  },
  {
    "uniprot": "Q4D2Y3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Y3"
  },
  {
    "uniprot": "Q4DYG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYG6"
  },
  {
    "uniprot": "Q4E261",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E261"
  },
  {
    "uniprot": "Q4DP26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP26"
  },
  {
    "uniprot": "Q4D7X7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7X7"
  },
  {
    "uniprot": "Q4DJK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJK0"
  },
  {
    "uniprot": "Q4CKK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKK3"
  },
  {
    "uniprot": "Q4DSP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSP2"
  },
  {
    "uniprot": "Q4D3P4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3P4"
  },
  {
    "uniprot": "Q4CNW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNW7"
  },
  {
    "uniprot": "Q4DJW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJW3"
  },
  {
    "uniprot": "Q4DDU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDU0"
  },
  {
    "uniprot": "Q4CVK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVK4"
  },
  {
    "uniprot": "Q4E491",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E491"
  },
  {
    "uniprot": "Q4DR05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR05"
  },
  {
    "uniprot": "Q4CYP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYP9"
  },
  {
    "uniprot": "Q4DNI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNI0"
  },
  {
    "uniprot": "Q4DR02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR02"
  },
  {
    "uniprot": "Q4DRF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRF8"
  },
  {
    "uniprot": "Q4E0G0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0G0"
  },
  {
    "uniprot": "Q4D6A0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6A0"
  },
  {
    "uniprot": "Q4D9T7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9T7"
  },
  {
    "uniprot": "Q4DHL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHL1"
  },
  {
    "uniprot": "Q4CLA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLA6"
  },
  {
    "uniprot": "Q4CM61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM61"
  },
  {
    "uniprot": "Q4D2U9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2U9"
  },
  {
    "uniprot": "Q4CW11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW11"
  },
  {
    "uniprot": "Q4DAM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAM6"
  },
  {
    "uniprot": "Q4DEA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEA3"
  },
  {
    "uniprot": "Q4DQD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQD1"
  },
  {
    "uniprot": "Q4DH62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH62"
  },
  {
    "uniprot": "Q4D8N7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8N7"
  },
  {
    "uniprot": "Q4E594",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E594"
  },
  {
    "uniprot": "Q4DNE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNE1"
  },
  {
    "uniprot": "Q4E0M2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0M2"
  },
  {
    "uniprot": "Q4DUR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUR8"
  },
  {
    "uniprot": "Q4DQ83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ83"
  },
  {
    "uniprot": "Q4DC89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC89"
  },
  {
    "uniprot": "Q4CZ17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ17"
  },
  {
    "uniprot": "Q4E0Z8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Z8"
  },
  {
    "uniprot": "Q4DVR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVR6"
  },
  {
    "uniprot": "Q4DC71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC71"
  },
  {
    "uniprot": "Q4CR16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR16"
  },
  {
    "uniprot": "Q4D836",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D836"
  },
  {
    "uniprot": "Q4CKP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKP5"
  },
  {
    "uniprot": "Q4DEZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEZ4"
  },
  {
    "uniprot": "Q4DL33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL33"
  },
  {
    "uniprot": "Q4DWN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWN9"
  },
  {
    "uniprot": "Q4DMN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMN4"
  },
  {
    "uniprot": "Q4CM08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM08"
  },
  {
    "uniprot": "Q4DKC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKC4"
  },
  {
    "uniprot": "Q4E422",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E422"
  },
  {
    "uniprot": "Q4D884",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D884"
  },
  {
    "uniprot": "Q4DQR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQR3"
  },
  {
    "uniprot": "Q4CZ10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ10"
  },
  {
    "uniprot": "Q4DMG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMG7"
  },
  {
    "uniprot": "Q4D325",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D325"
  },
  {
    "uniprot": "Q4CRP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRP5"
  },
  {
    "uniprot": "Q4CUZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUZ4"
  },
  {
    "uniprot": "Q4DZ08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ08"
  },
  {
    "uniprot": "Q4DNI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNI5"
  },
  {
    "uniprot": "Q4DKF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKF5"
  },
  {
    "uniprot": "Q4DUU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUU6"
  },
  {
    "uniprot": "Q4D492",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D492"
  },
  {
    "uniprot": "Q4CRR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRR7"
  },
  {
    "uniprot": "Q4DJJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJJ6"
  },
  {
    "uniprot": "Q4DYS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYS1"
  },
  {
    "uniprot": "Q4CPV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPV0"
  },
  {
    "uniprot": "Q4E0W0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0W0"
  },
  {
    "uniprot": "Q4D0T7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0T7"
  },
  {
    "uniprot": "Q4DQW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQW9"
  },
  {
    "uniprot": "Q4DRG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRG3"
  },
  {
    "uniprot": "Q4E323",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E323"
  },
  {
    "uniprot": "Q4CL06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL06"
  },
  {
    "uniprot": "Q4DG73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG73"
  },
  {
    "uniprot": "Q4CT78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT78"
  },
  {
    "uniprot": "Q4E2H4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2H4"
  },
  {
    "uniprot": "Q4CR65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR65"
  },
  {
    "uniprot": "Q4D684",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D684"
  },
  {
    "uniprot": "Q4DBX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBX5"
  },
  {
    "uniprot": "Q4DRN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRN6"
  },
  {
    "uniprot": "Q4DDP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDP6"
  },
  {
    "uniprot": "Q4D4U4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4U4"
  },
  {
    "uniprot": "Q4E2X8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2X8"
  },
  {
    "uniprot": "Q4DLH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLH3"
  },
  {
    "uniprot": "Q4DWR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWR0"
  },
  {
    "uniprot": "Q4DWG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWG0"
  },
  {
    "uniprot": "Q4DVY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVY6"
  },
  {
    "uniprot": "Q4E628",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E628"
  },
  {
    "uniprot": "Q4CXS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXS0"
  },
  {
    "uniprot": "Q4DWD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWD1"
  },
  {
    "uniprot": "Q4D020",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D020"
  },
  {
    "uniprot": "Q4CLX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLX0"
  },
  {
    "uniprot": "Q4CPF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPF2"
  },
  {
    "uniprot": "Q4CZ60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ60"
  },
  {
    "uniprot": "Q4D4B7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4B7"
  },
  {
    "uniprot": "Q4DC75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC75"
  },
  {
    "uniprot": "Q4DP70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP70"
  },
  {
    "uniprot": "Q4D9Q2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Q2"
  },
  {
    "uniprot": "Q4CNP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNP7"
  },
  {
    "uniprot": "Q4D8B0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8B0"
  },
  {
    "uniprot": "Q4DBX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBX8"
  },
  {
    "uniprot": "Q4CXB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXB4"
  },
  {
    "uniprot": "Q4CR05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR05"
  },
  {
    "uniprot": "Q4CK85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CK85"
  },
  {
    "uniprot": "Q4D8S6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8S6"
  },
  {
    "uniprot": "Q4E0L8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0L8"
  },
  {
    "uniprot": "Q4CWP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWP4"
  },
  {
    "uniprot": "Q4E4W6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4W6"
  },
  {
    "uniprot": "Q4DY78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY78"
  },
  {
    "uniprot": "Q4D6M1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6M1"
  },
  {
    "uniprot": "Q4DH24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH24"
  },
  {
    "uniprot": "Q4CY51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY51"
  },
  {
    "uniprot": "Q4DY05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY05"
  },
  {
    "uniprot": "Q4D6K7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6K7"
  },
  {
    "uniprot": "Q4D377",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D377"
  },
  {
    "uniprot": "Q4CV04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV04"
  },
  {
    "uniprot": "Q4DU02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU02"
  },
  {
    "uniprot": "Q4DUK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUK6"
  },
  {
    "uniprot": "Q4D6A9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6A9"
  },
  {
    "uniprot": "Q4CYX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYX1"
  },
  {
    "uniprot": "Q4CSG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSG0"
  },
  {
    "uniprot": "Q4CW28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW28"
  },
  {
    "uniprot": "Q4D439",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D439"
  },
  {
    "uniprot": "Q4D1A8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1A8"
  },
  {
    "uniprot": "Q4DIJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIJ6"
  },
  {
    "uniprot": "Q4DT90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT90"
  },
  {
    "uniprot": "Q4E2L6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2L6"
  },
  {
    "uniprot": "Q4DBI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBI3"
  },
  {
    "uniprot": "Q4DAG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAG0"
  },
  {
    "uniprot": "Q4E000",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E000"
  },
  {
    "uniprot": "Q4DRM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRM1"
  },
  {
    "uniprot": "Q4E4K5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4K5"
  },
  {
    "uniprot": "Q4D959",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D959"
  },
  {
    "uniprot": "Q4DE83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE83"
  },
  {
    "uniprot": "Q4CXW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXW6"
  },
  {
    "uniprot": "Q4DWN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWN7"
  },
  {
    "uniprot": "Q4DKL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKL9"
  },
  {
    "uniprot": "Q4CQ73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ73"
  },
  {
    "uniprot": "Q4D1P7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1P7"
  },
  {
    "uniprot": "Q4CW45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW45"
  },
  {
    "uniprot": "Q4DWM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWM6"
  },
  {
    "uniprot": "Q4DW30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW30"
  },
  {
    "uniprot": "Q4DRL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRL2"
  },
  {
    "uniprot": "Q4D8J5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8J5"
  },
  {
    "uniprot": "Q4E2Q4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Q4"
  },
  {
    "uniprot": "Q4CQ74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ74"
  },
  {
    "uniprot": "Q4DFW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFW6"
  },
  {
    "uniprot": "Q4D3F0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3F0"
  },
  {
    "uniprot": "Q4E1T4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1T4"
  },
  {
    "uniprot": "Q4CNF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNF5"
  },
  {
    "uniprot": "Q4DZI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZI3"
  },
  {
    "uniprot": "Q4DIN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIN7"
  },
  {
    "uniprot": "Q4DH70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH70"
  },
  {
    "uniprot": "Q4CUT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUT8"
  },
  {
    "uniprot": "Q4E3P1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3P1"
  },
  {
    "uniprot": "Q4CYT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYT1"
  },
  {
    "uniprot": "Q4CY54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY54"
  },
  {
    "uniprot": "Q4D041",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D041"
  },
  {
    "uniprot": "Q4D8B2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8B2"
  },
  {
    "uniprot": "Q4E1X0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1X0"
  },
  {
    "uniprot": "Q4DT06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT06"
  },
  {
    "uniprot": "Q4D2K6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2K6"
  },
  {
    "uniprot": "Q4CKU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKU6"
  },
  {
    "uniprot": "Q4D0C5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0C5"
  },
  {
    "uniprot": "Q4DA75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA75"
  },
  {
    "uniprot": "Q4DKC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKC3"
  },
  {
    "uniprot": "Q4DL09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL09"
  },
  {
    "uniprot": "Q4D3R8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3R8"
  },
  {
    "uniprot": "Q4DA48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA48"
  },
  {
    "uniprot": "Q4D7Q8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Q8"
  },
  {
    "uniprot": "Q4CR43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR43"
  },
  {
    "uniprot": "Q4DCY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCY0"
  },
  {
    "uniprot": "Q4CPP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPP1"
  },
  {
    "uniprot": "Q4DDI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDI7"
  },
  {
    "uniprot": "Q4DF54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF54"
  },
  {
    "uniprot": "Q4DJP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJP5"
  },
  {
    "uniprot": "Q4E1B8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1B8"
  },
  {
    "uniprot": "Q4DQ45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ45"
  },
  {
    "uniprot": "Q4DN48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN48"
  },
  {
    "uniprot": "Q4DNU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNU4"
  },
  {
    "uniprot": "Q4CUZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUZ0"
  },
  {
    "uniprot": "Q4E2I1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2I1"
  },
  {
    "uniprot": "Q4D084",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D084"
  },
  {
    "uniprot": "Q4DAH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAH0"
  },
  {
    "uniprot": "Q4CNP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNP8"
  },
  {
    "uniprot": "Q4D4I6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4I6"
  },
  {
    "uniprot": "Q4DRD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRD9"
  },
  {
    "uniprot": "Q4CMS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMS9"
  },
  {
    "uniprot": "Q4CNH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNH1"
  },
  {
    "uniprot": "Q4DPE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPE2"
  },
  {
    "uniprot": "Q4DYV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYV0"
  },
  {
    "uniprot": "Q4DMD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMD5"
  },
  {
    "uniprot": "Q4E5T5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5T5"
  },
  {
    "uniprot": "Q4DW79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW79"
  },
  {
    "uniprot": "Q4CUB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUB1"
  },
  {
    "uniprot": "Q4D6Y0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Y0"
  },
  {
    "uniprot": "Q4D0Q8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Q8"
  },
  {
    "uniprot": "Q4D0L4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0L4"
  },
  {
    "uniprot": "Q4DW60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW60"
  },
  {
    "uniprot": "Q4CPR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPR4"
  },
  {
    "uniprot": "Q4D2A1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2A1"
  },
  {
    "uniprot": "Q4DVF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVF8"
  },
  {
    "uniprot": "Q4CWZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWZ6"
  },
  {
    "uniprot": "Q4DZM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZM8"
  },
  {
    "uniprot": "Q4E5B4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5B4"
  },
  {
    "uniprot": "Q4DT02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT02"
  },
  {
    "uniprot": "Q4CL41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL41"
  },
  {
    "uniprot": "Q4CNA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNA9"
  },
  {
    "uniprot": "Q4CRD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRD4"
  },
  {
    "uniprot": "Q4DWD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWD7"
  },
  {
    "uniprot": "Q4CR60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR60"
  },
  {
    "uniprot": "Q4DHB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHB0"
  },
  {
    "uniprot": "Q4D5D7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5D7"
  },
  {
    "uniprot": "Q4CM84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM84"
  },
  {
    "uniprot": "Q4E0T5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0T5"
  },
  {
    "uniprot": "Q4DRJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRJ9"
  },
  {
    "uniprot": "Q4CY38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY38"
  },
  {
    "uniprot": "Q4DU56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU56"
  },
  {
    "uniprot": "Q4E2N5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2N5"
  },
  {
    "uniprot": "Q4DDD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDD8"
  },
  {
    "uniprot": "Q4CS33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS33"
  },
  {
    "uniprot": "Q4DNV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNV0"
  },
  {
    "uniprot": "Q4DP23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP23"
  },
  {
    "uniprot": "Q4CZP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZP8"
  },
  {
    "uniprot": "Q4DRF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRF9"
  },
  {
    "uniprot": "Q4DDF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDF8"
  },
  {
    "uniprot": "Q4DH29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH29"
  },
  {
    "uniprot": "Q4D8L5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8L5"
  },
  {
    "uniprot": "Q4CQJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQJ9"
  },
  {
    "uniprot": "Q4CWK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWK7"
  },
  {
    "uniprot": "Q4CXW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXW7"
  },
  {
    "uniprot": "Q4DKW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKW4"
  },
  {
    "uniprot": "Q4CQD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQD3"
  },
  {
    "uniprot": "Q4E3S5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3S5"
  },
  {
    "uniprot": "Q4CQY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQY7"
  },
  {
    "uniprot": "Q4DQJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQJ7"
  },
  {
    "uniprot": "Q4D6X4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6X4"
  },
  {
    "uniprot": "Q4CSE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSE3"
  },
  {
    "uniprot": "Q4D086",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D086"
  },
  {
    "uniprot": "Q4E3V0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3V0"
  },
  {
    "uniprot": "Q4DNQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNQ1"
  },
  {
    "uniprot": "Q4DBL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBL4"
  },
  {
    "uniprot": "Q4DA52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA52"
  },
  {
    "uniprot": "Q4D8I2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8I2"
  },
  {
    "uniprot": "Q4E3Z3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Z3"
  },
  {
    "uniprot": "Q4CZX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZX4"
  },
  {
    "uniprot": "Q4DBA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBA5"
  },
  {
    "uniprot": "Q4D2X0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2X0"
  },
  {
    "uniprot": "Q4CT32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT32"
  },
  {
    "uniprot": "Q4DJS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJS2"
  },
  {
    "uniprot": "Q4CKI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKI1"
  },
  {
    "uniprot": "Q4E572",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E572"
  },
  {
    "uniprot": "Q4DYL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYL9"
  },
  {
    "uniprot": "Q4DPY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPY7"
  },
  {
    "uniprot": "Q4E5E6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5E6"
  },
  {
    "uniprot": "Q4DXM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXM7"
  },
  {
    "uniprot": "Q4CP40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP40"
  },
  {
    "uniprot": "Q4CP36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP36"
  },
  {
    "uniprot": "Q4DMD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMD7"
  },
  {
    "uniprot": "Q4CS02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS02"
  },
  {
    "uniprot": "Q4D3D8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3D8"
  },
  {
    "uniprot": "Q4D3Z1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Z1"
  },
  {
    "uniprot": "Q4DQ81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ81"
  },
  {
    "uniprot": "Q4DK72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK72"
  },
  {
    "uniprot": "Q4DZH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZH5"
  },
  {
    "uniprot": "Q4CNW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNW0"
  },
  {
    "uniprot": "Q4D0U4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0U4"
  },
  {
    "uniprot": "Q4CR23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR23"
  },
  {
    "uniprot": "Q4DES5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DES5"
  },
  {
    "uniprot": "Q4DNF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNF8"
  },
  {
    "uniprot": "Q4D154",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D154"
  },
  {
    "uniprot": "Q4DNM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNM9"
  },
  {
    "uniprot": "Q4D2K1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2K1"
  },
  {
    "uniprot": "Q4D2T6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2T6"
  },
  {
    "uniprot": "Q4E4H1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4H1"
  },
  {
    "uniprot": "Q4DPQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPQ9"
  },
  {
    "uniprot": "Q4DNP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNP9"
  },
  {
    "uniprot": "Q4D7C8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7C8"
  },
  {
    "uniprot": "Q4D2M0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2M0"
  },
  {
    "uniprot": "Q4CZ62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ62"
  },
  {
    "uniprot": "Q4E1S3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1S3"
  },
  {
    "uniprot": "Q4DD69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD69"
  },
  {
    "uniprot": "Q4DCK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCK9"
  },
  {
    "uniprot": "Q4DNA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNA5"
  },
  {
    "uniprot": "Q4D2Q2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Q2"
  },
  {
    "uniprot": "Q4CZU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZU0"
  },
  {
    "uniprot": "Q4D1L4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1L4"
  },
  {
    "uniprot": "Q4CM35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM35"
  },
  {
    "uniprot": "Q4DVP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVP6"
  },
  {
    "uniprot": "Q4CU94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU94"
  },
  {
    "uniprot": "Q4DTZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTZ0"
  },
  {
    "uniprot": "Q4D8W0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8W0"
  },
  {
    "uniprot": "Q4D796",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D796"
  },
  {
    "uniprot": "Q4DGN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGN7"
  },
  {
    "uniprot": "Q4CXC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXC3"
  },
  {
    "uniprot": "Q4D1N6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1N6"
  },
  {
    "uniprot": "Q4DH18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH18"
  },
  {
    "uniprot": "Q4CMP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMP6"
  },
  {
    "uniprot": "Q4CTD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTD4"
  },
  {
    "uniprot": "Q4CR67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR67"
  },
  {
    "uniprot": "Q4DFD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFD1"
  },
  {
    "uniprot": "Q4CLS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLS3"
  },
  {
    "uniprot": "Q4CSB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSB8"
  },
  {
    "uniprot": "Q4DCQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCQ1"
  },
  {
    "uniprot": "Q4DZ52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ52"
  },
  {
    "uniprot": "Q4DES1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DES1"
  },
  {
    "uniprot": "Q4DP25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP25"
  },
  {
    "uniprot": "Q4CSK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSK7"
  },
  {
    "uniprot": "Q4DX43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX43"
  },
  {
    "uniprot": "Q4DNG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNG6"
  },
  {
    "uniprot": "Q4CUU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUU3"
  },
  {
    "uniprot": "Q4DT29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT29"
  },
  {
    "uniprot": "Q4CVT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVT5"
  },
  {
    "uniprot": "Q4E5T7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5T7"
  },
  {
    "uniprot": "Q4DQE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQE0"
  },
  {
    "uniprot": "Q4DFJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFJ9"
  },
  {
    "uniprot": "Q4DWR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWR2"
  },
  {
    "uniprot": "Q4DD72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD72"
  },
  {
    "uniprot": "Q4DTZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTZ2"
  },
  {
    "uniprot": "Q4DIB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIB3"
  },
  {
    "uniprot": "Q4DT87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT87"
  },
  {
    "uniprot": "Q4CYK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYK6"
  },
  {
    "uniprot": "Q4E400",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E400"
  },
  {
    "uniprot": "Q4CQW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQW6"
  },
  {
    "uniprot": "Q4CTV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTV0"
  },
  {
    "uniprot": "Q4DY71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY71"
  },
  {
    "uniprot": "Q4DHI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHI0"
  },
  {
    "uniprot": "Q4DTG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTG3"
  },
  {
    "uniprot": "Q4CQJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQJ4"
  },
  {
    "uniprot": "Q4CSL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSL9"
  },
  {
    "uniprot": "Q4D1E8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1E8"
  },
  {
    "uniprot": "Q4DPW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPW3"
  },
  {
    "uniprot": "Q4E0K3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0K3"
  },
  {
    "uniprot": "Q4DFC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFC9"
  },
  {
    "uniprot": "Q4CU84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU84"
  },
  {
    "uniprot": "Q4DML8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DML8"
  },
  {
    "uniprot": "Q4CK89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CK89"
  },
  {
    "uniprot": "Q4D6S1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6S1"
  },
  {
    "uniprot": "Q4E2U4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2U4"
  },
  {
    "uniprot": "Q4D7I7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7I7"
  },
  {
    "uniprot": "Q4CU73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU73"
  },
  {
    "uniprot": "Q4CPB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPB6"
  },
  {
    "uniprot": "Q4D3Q8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Q8"
  },
  {
    "uniprot": "Q4DBH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBH0"
  },
  {
    "uniprot": "Q4DU94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU94"
  },
  {
    "uniprot": "Q4CL31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL31"
  },
  {
    "uniprot": "Q4CYI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYI4"
  },
  {
    "uniprot": "Q4DBT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBT5"
  },
  {
    "uniprot": "Q4DZN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZN8"
  },
  {
    "uniprot": "Q4DY94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY94"
  },
  {
    "uniprot": "Q4CXI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXI3"
  },
  {
    "uniprot": "Q4DRZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRZ0"
  },
  {
    "uniprot": "Q4CMA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMA2"
  },
  {
    "uniprot": "Q4DLW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLW2"
  },
  {
    "uniprot": "Q4D078",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D078"
  },
  {
    "uniprot": "Q4DJ50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ50"
  },
  {
    "uniprot": "Q4DLX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLX8"
  },
  {
    "uniprot": "Q4CXJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXJ8"
  },
  {
    "uniprot": "Q4D1F5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1F5"
  },
  {
    "uniprot": "Q4DUM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUM9"
  },
  {
    "uniprot": "Q4D489",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D489"
  },
  {
    "uniprot": "Q4DJY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJY2"
  },
  {
    "uniprot": "Q4D695",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D695"
  },
  {
    "uniprot": "Q4CVH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVH7"
  },
  {
    "uniprot": "Q4CY64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY64"
  },
  {
    "uniprot": "Q4DA66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA66"
  },
  {
    "uniprot": "Q4CLM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLM6"
  },
  {
    "uniprot": "Q4DQ44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ44"
  },
  {
    "uniprot": "Q4DAK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAK0"
  },
  {
    "uniprot": "Q4E0N9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0N9"
  },
  {
    "uniprot": "Q4D5N5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5N5"
  },
  {
    "uniprot": "Q4CUA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUA2"
  },
  {
    "uniprot": "Q4D056",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D056"
  },
  {
    "uniprot": "Q4DQE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQE9"
  },
  {
    "uniprot": "Q4CKZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKZ0"
  },
  {
    "uniprot": "Q4DIA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIA8"
  },
  {
    "uniprot": "Q4DZZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZZ2"
  },
  {
    "uniprot": "Q4E5Q2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Q2"
  },
  {
    "uniprot": "Q4DT15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT15"
  },
  {
    "uniprot": "Q4DRF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRF3"
  },
  {
    "uniprot": "Q4D446",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D446"
  },
  {
    "uniprot": "Q4CVQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVQ1"
  },
  {
    "uniprot": "Q4DY21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY21"
  },
  {
    "uniprot": "Q4DPA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPA5"
  },
  {
    "uniprot": "Q4E401",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E401"
  },
  {
    "uniprot": "Q4D0H2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0H2"
  },
  {
    "uniprot": "Q4E2Q3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Q3"
  },
  {
    "uniprot": "Q4DW63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW63"
  },
  {
    "uniprot": "Q4DKB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKB4"
  },
  {
    "uniprot": "Q4CR76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR76"
  },
  {
    "uniprot": "Q4DM13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM13"
  },
  {
    "uniprot": "Q4D2X9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2X9"
  },
  {
    "uniprot": "Q4DSV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSV2"
  },
  {
    "uniprot": "Q4DLI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLI2"
  },
  {
    "uniprot": "Q4CKF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKF5"
  },
  {
    "uniprot": "Q4CSH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSH5"
  },
  {
    "uniprot": "Q4DP33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP33"
  },
  {
    "uniprot": "Q4DLW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLW8"
  },
  {
    "uniprot": "Q4DKP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKP3"
  },
  {
    "uniprot": "Q4DJG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJG7"
  },
  {
    "uniprot": "Q4DQC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQC0"
  },
  {
    "uniprot": "Q4DM62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM62"
  },
  {
    "uniprot": "Q4CM70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM70"
  },
  {
    "uniprot": "Q4DZ43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ43"
  },
  {
    "uniprot": "Q4D2L6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2L6"
  },
  {
    "uniprot": "Q4DHF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHF6"
  },
  {
    "uniprot": "Q4CUB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUB8"
  },
  {
    "uniprot": "Q4D3Z0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Z0"
  },
  {
    "uniprot": "Q4DA91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA91"
  },
  {
    "uniprot": "Q4DJU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJU2"
  },
  {
    "uniprot": "Q4D3K7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3K7"
  },
  {
    "uniprot": "Q4D437",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D437"
  },
  {
    "uniprot": "Q4CWL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWL2"
  },
  {
    "uniprot": "Q4CPU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPU8"
  },
  {
    "uniprot": "Q4D754",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D754"
  },
  {
    "uniprot": "Q4D331",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D331"
  },
  {
    "uniprot": "Q4CZE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZE0"
  },
  {
    "uniprot": "Q4E202",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E202"
  },
  {
    "uniprot": "Q4D5W5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5W5"
  },
  {
    "uniprot": "Q4CVQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVQ9"
  },
  {
    "uniprot": "Q4DTF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTF1"
  },
  {
    "uniprot": "Q4D126",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D126"
  },
  {
    "uniprot": "Q4CMV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMV3"
  },
  {
    "uniprot": "Q4CXT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXT5"
  },
  {
    "uniprot": "Q4CKX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKX4"
  },
  {
    "uniprot": "Q4DZE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZE0"
  },
  {
    "uniprot": "Q4D6I3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6I3"
  },
  {
    "uniprot": "Q4DSZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSZ2"
  },
  {
    "uniprot": "Q4DGB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGB0"
  },
  {
    "uniprot": "Q4D9T1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9T1"
  },
  {
    "uniprot": "Q4CSK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSK1"
  },
  {
    "uniprot": "Q4DKG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKG7"
  },
  {
    "uniprot": "Q4DKS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKS9"
  },
  {
    "uniprot": "Q4CUK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUK9"
  },
  {
    "uniprot": "Q4CWQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWQ1"
  },
  {
    "uniprot": "Q4DWX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWX1"
  },
  {
    "uniprot": "Q4DM85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM85"
  },
  {
    "uniprot": "Q4E0A9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0A9"
  },
  {
    "uniprot": "Q4D8W9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8W9"
  },
  {
    "uniprot": "Q4D8F9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8F9"
  },
  {
    "uniprot": "Q4DJ84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ84"
  },
  {
    "uniprot": "Q4DNH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNH3"
  },
  {
    "uniprot": "Q4CXS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXS8"
  },
  {
    "uniprot": "Q4DIT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIT1"
  },
  {
    "uniprot": "Q4CXX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXX3"
  },
  {
    "uniprot": "Q4CMZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMZ5"
  },
  {
    "uniprot": "Q4E2T6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2T6"
  },
  {
    "uniprot": "Q4DT41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT41"
  },
  {
    "uniprot": "Q4DAE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAE7"
  },
  {
    "uniprot": "Q4DTW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTW2"
  },
  {
    "uniprot": "Q4DNL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNL5"
  },
  {
    "uniprot": "Q4DF53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF53"
  },
  {
    "uniprot": "Q4D772",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D772"
  },
  {
    "uniprot": "Q4D5I5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5I5"
  },
  {
    "uniprot": "Q4E136",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E136"
  },
  {
    "uniprot": "Q4DDP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDP9"
  },
  {
    "uniprot": "Q4DK40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK40"
  },
  {
    "uniprot": "Q4D4H2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4H2"
  },
  {
    "uniprot": "Q4E2N7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2N7"
  },
  {
    "uniprot": "Q4D2F9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2F9"
  },
  {
    "uniprot": "Q4E1N1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1N1"
  },
  {
    "uniprot": "Q4D883",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D883"
  },
  {
    "uniprot": "Q4E4Q8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Q8"
  },
  {
    "uniprot": "Q4DSK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSK6"
  },
  {
    "uniprot": "Q4D233",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D233"
  },
  {
    "uniprot": "Q4DHG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHG9"
  },
  {
    "uniprot": "Q4CRV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRV1"
  },
  {
    "uniprot": "Q4D4S3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4S3"
  },
  {
    "uniprot": "Q4CS57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS57"
  },
  {
    "uniprot": "Q4CTU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTU1"
  },
  {
    "uniprot": "Q4E5T6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5T6"
  },
  {
    "uniprot": "Q4CKD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKD8"
  },
  {
    "uniprot": "Q4DJC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJC4"
  },
  {
    "uniprot": "Q4D292",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D292"
  },
  {
    "uniprot": "Q4DF64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF64"
  },
  {
    "uniprot": "Q4DRR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRR2"
  },
  {
    "uniprot": "Q4CKU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKU0"
  },
  {
    "uniprot": "Q4E5E9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5E9"
  },
  {
    "uniprot": "Q4DPY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPY9"
  },
  {
    "uniprot": "Q4D2L7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2L7"
  },
  {
    "uniprot": "Q4DGX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGX1"
  },
  {
    "uniprot": "Q4DF60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF60"
  },
  {
    "uniprot": "Q4D9X3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9X3"
  },
  {
    "uniprot": "Q4CTN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTN3"
  },
  {
    "uniprot": "Q4DNR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNR9"
  },
  {
    "uniprot": "Q4D9X6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9X6"
  },
  {
    "uniprot": "Q4D3Z4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Z4"
  },
  {
    "uniprot": "Q4CSV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSV1"
  },
  {
    "uniprot": "Q4CVU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVU8"
  },
  {
    "uniprot": "Q4DJX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJX4"
  },
  {
    "uniprot": "Q4DHE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHE9"
  },
  {
    "uniprot": "Q4DBP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBP1"
  },
  {
    "uniprot": "Q4DSK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSK7"
  },
  {
    "uniprot": "Q4D000",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D000"
  },
  {
    "uniprot": "Q4D9W7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9W7"
  },
  {
    "uniprot": "Q4D3G7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3G7"
  },
  {
    "uniprot": "Q4DZW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZW7"
  },
  {
    "uniprot": "Q4E1I9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1I9"
  },
  {
    "uniprot": "Q4DVW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVW1"
  },
  {
    "uniprot": "Q4D763",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D763"
  },
  {
    "uniprot": "Q4DXV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXV3"
  },
  {
    "uniprot": "Q4DN73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN73"
  },
  {
    "uniprot": "Q4CZR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZR3"
  },
  {
    "uniprot": "Q4CV09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV09"
  },
  {
    "uniprot": "Q4DMX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMX2"
  },
  {
    "uniprot": "Q4E3X1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3X1"
  },
  {
    "uniprot": "Q4DPN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPN2"
  },
  {
    "uniprot": "Q4CVI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVI7"
  },
  {
    "uniprot": "Q4CUT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUT0"
  },
  {
    "uniprot": "Q4DDE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDE9"
  },
  {
    "uniprot": "Q4DKW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKW7"
  },
  {
    "uniprot": "Q4CLV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLV4"
  },
  {
    "uniprot": "Q4CMR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMR6"
  },
  {
    "uniprot": "Q4CQP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQP7"
  },
  {
    "uniprot": "Q4CU62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU62"
  },
  {
    "uniprot": "Q4E1Y3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Y3"
  },
  {
    "uniprot": "Q4CMT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMT2"
  },
  {
    "uniprot": "Q4CVI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVI3"
  },
  {
    "uniprot": "Q4DXP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXP0"
  },
  {
    "uniprot": "Q4DID0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DID0"
  },
  {
    "uniprot": "Q4DC78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC78"
  },
  {
    "uniprot": "Q4DWH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWH1"
  },
  {
    "uniprot": "Q4CQX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQX1"
  },
  {
    "uniprot": "Q4DNI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNI9"
  },
  {
    "uniprot": "Q4D536",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D536"
  },
  {
    "uniprot": "Q4DL07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL07"
  },
  {
    "uniprot": "Q4DMY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMY3"
  },
  {
    "uniprot": "Q4CR73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR73"
  },
  {
    "uniprot": "Q4CMV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMV8"
  },
  {
    "uniprot": "Q4DSW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSW3"
  },
  {
    "uniprot": "Q4DPG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPG8"
  },
  {
    "uniprot": "Q4D631",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D631"
  },
  {
    "uniprot": "Q4DIT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIT5"
  },
  {
    "uniprot": "Q4DW16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW16"
  },
  {
    "uniprot": "Q4CT14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT14"
  },
  {
    "uniprot": "Q4CYS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYS5"
  },
  {
    "uniprot": "Q4DAF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAF4"
  },
  {
    "uniprot": "Q4DPM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPM4"
  },
  {
    "uniprot": "Q4E331",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E331"
  },
  {
    "uniprot": "Q4DRZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRZ1"
  },
  {
    "uniprot": "Q4D9U5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9U5"
  },
  {
    "uniprot": "Q4DUW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUW2"
  },
  {
    "uniprot": "Q4DDG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDG4"
  },
  {
    "uniprot": "Q4DKQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKQ1"
  },
  {
    "uniprot": "Q4CNC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNC1"
  },
  {
    "uniprot": "Q4D7S0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7S0"
  },
  {
    "uniprot": "Q4DWN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWN5"
  },
  {
    "uniprot": "Q4DBX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBX9"
  },
  {
    "uniprot": "Q4DRX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRX3"
  },
  {
    "uniprot": "Q4DJE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJE5"
  },
  {
    "uniprot": "Q4DER4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DER4"
  },
  {
    "uniprot": "Q4D4R1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4R1"
  },
  {
    "uniprot": "Q4CWK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWK4"
  },
  {
    "uniprot": "Q4DW93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW93"
  },
  {
    "uniprot": "Q4E012",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E012"
  },
  {
    "uniprot": "Q4E383",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E383"
  },
  {
    "uniprot": "Q4E4F2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4F2"
  },
  {
    "uniprot": "Q4DNP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNP1"
  },
  {
    "uniprot": "Q4CQH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQH3"
  },
  {
    "uniprot": "Q4DSK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSK1"
  },
  {
    "uniprot": "Q4E5J1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5J1"
  },
  {
    "uniprot": "Q4D4K4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4K4"
  },
  {
    "uniprot": "Q4CZV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZV5"
  },
  {
    "uniprot": "Q4D2U6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2U6"
  },
  {
    "uniprot": "Q4CVY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVY3"
  },
  {
    "uniprot": "Q4DAC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAC6"
  },
  {
    "uniprot": "Q4DYZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYZ4"
  },
  {
    "uniprot": "Q4DS51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS51"
  },
  {
    "uniprot": "Q4DDI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDI4"
  },
  {
    "uniprot": "Q4DYU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYU6"
  },
  {
    "uniprot": "Q4CNS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNS2"
  },
  {
    "uniprot": "Q4D812",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D812"
  },
  {
    "uniprot": "Q4E3U1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3U1"
  },
  {
    "uniprot": "Q4DMI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMI6"
  },
  {
    "uniprot": "Q4CYK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYK5"
  },
  {
    "uniprot": "Q4DIC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIC6"
  },
  {
    "uniprot": "Q4D5T4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5T4"
  },
  {
    "uniprot": "Q4DUN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUN2"
  },
  {
    "uniprot": "Q4E2V7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2V7"
  },
  {
    "uniprot": "Q4DS82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS82"
  },
  {
    "uniprot": "Q4D0T5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0T5"
  },
  {
    "uniprot": "Q4DNV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNV1"
  },
  {
    "uniprot": "Q4CMG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMG7"
  },
  {
    "uniprot": "Q4D8D9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8D9"
  },
  {
    "uniprot": "Q4DBN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBN2"
  },
  {
    "uniprot": "Q4DTD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTD5"
  },
  {
    "uniprot": "Q4CY35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY35"
  },
  {
    "uniprot": "Q4DCV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCV2"
  },
  {
    "uniprot": "Q4D1V5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1V5"
  },
  {
    "uniprot": "Q4CR97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR97"
  },
  {
    "uniprot": "Q4DFQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFQ0"
  },
  {
    "uniprot": "Q4DI95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI95"
  },
  {
    "uniprot": "Q4DNG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNG8"
  },
  {
    "uniprot": "Q4DVD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVD3"
  },
  {
    "uniprot": "Q4DW52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW52"
  },
  {
    "uniprot": "Q4DBM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBM7"
  },
  {
    "uniprot": "Q4CQZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQZ9"
  },
  {
    "uniprot": "Q4D7M4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7M4"
  },
  {
    "uniprot": "Q4E5K6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5K6"
  },
  {
    "uniprot": "Q4CPD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPD1"
  },
  {
    "uniprot": "Q4D6X8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6X8"
  },
  {
    "uniprot": "Q4CU92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU92"
  },
  {
    "uniprot": "Q4DLM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLM5"
  },
  {
    "uniprot": "Q4CU74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU74"
  },
  {
    "uniprot": "Q4DE98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE98"
  },
  {
    "uniprot": "Q4CXX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXX6"
  },
  {
    "uniprot": "Q4DLV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLV8"
  },
  {
    "uniprot": "Q4CLM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLM2"
  },
  {
    "uniprot": "Q4CVS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVS2"
  },
  {
    "uniprot": "Q4CL90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL90"
  },
  {
    "uniprot": "Q4DT82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT82"
  },
  {
    "uniprot": "Q4CUP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUP2"
  },
  {
    "uniprot": "Q4DZ92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ92"
  },
  {
    "uniprot": "Q4CXI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXI9"
  },
  {
    "uniprot": "Q4D4M3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4M3"
  },
  {
    "uniprot": "Q4D932",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D932"
  },
  {
    "uniprot": "Q4DJ18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ18"
  },
  {
    "uniprot": "Q4CTI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTI6"
  },
  {
    "uniprot": "Q4CXW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXW2"
  },
  {
    "uniprot": "Q4DP96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP96"
  },
  {
    "uniprot": "Q4DFP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFP7"
  },
  {
    "uniprot": "Q4E1K5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1K5"
  },
  {
    "uniprot": "Q4CPB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPB5"
  },
  {
    "uniprot": "Q4CKX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKX5"
  },
  {
    "uniprot": "Q4D7U7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7U7"
  },
  {
    "uniprot": "Q4E5G5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5G5"
  },
  {
    "uniprot": "Q4CL20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL20"
  },
  {
    "uniprot": "Q4CL71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL71"
  },
  {
    "uniprot": "Q4D3C1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3C1"
  },
  {
    "uniprot": "Q4CNE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNE7"
  },
  {
    "uniprot": "Q4DPT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPT1"
  },
  {
    "uniprot": "Q4DG08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG08"
  },
  {
    "uniprot": "Q4CLJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLJ7"
  },
  {
    "uniprot": "Q4D1X7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1X7"
  },
  {
    "uniprot": "Q4CKX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKX1"
  },
  {
    "uniprot": "Q4DVT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVT1"
  },
  {
    "uniprot": "Q4DDW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDW7"
  },
  {
    "uniprot": "Q4DMS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMS5"
  },
  {
    "uniprot": "Q4D0E7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0E7"
  },
  {
    "uniprot": "Q4CR46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR46"
  },
  {
    "uniprot": "Q4DPD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPD5"
  },
  {
    "uniprot": "Q4DTM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTM7"
  },
  {
    "uniprot": "Q4DYC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYC0"
  },
  {
    "uniprot": "Q4DSE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSE0"
  },
  {
    "uniprot": "Q4DVV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVV0"
  },
  {
    "uniprot": "Q4DTC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTC5"
  },
  {
    "uniprot": "Q4E4M9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4M9"
  },
  {
    "uniprot": "Q4DKX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKX9"
  },
  {
    "uniprot": "Q4D188",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D188"
  },
  {
    "uniprot": "Q4DKI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKI8"
  },
  {
    "uniprot": "Q4DYM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYM5"
  },
  {
    "uniprot": "Q4D5L8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5L8"
  },
  {
    "uniprot": "Q4CR83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR83"
  },
  {
    "uniprot": "Q4DMH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMH3"
  },
  {
    "uniprot": "Q4E3Y4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Y4"
  },
  {
    "uniprot": "Q4CLN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLN3"
  },
  {
    "uniprot": "Q4CZR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZR2"
  },
  {
    "uniprot": "Q4D5R8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5R8"
  },
  {
    "uniprot": "Q4DA58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA58"
  },
  {
    "uniprot": "Q4DYQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYQ4"
  },
  {
    "uniprot": "Q4DE48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE48"
  },
  {
    "uniprot": "Q4CXR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXR2"
  },
  {
    "uniprot": "Q4E5U4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5U4"
  },
  {
    "uniprot": "Q4CVF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVF1"
  },
  {
    "uniprot": "Q4E5A8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5A8"
  },
  {
    "uniprot": "Q4E1L7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1L7"
  },
  {
    "uniprot": "Q4E0G8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0G8"
  },
  {
    "uniprot": "Q4DQG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQG6"
  },
  {
    "uniprot": "Q4DXR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXR7"
  },
  {
    "uniprot": "Q4D6E0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6E0"
  },
  {
    "uniprot": "Q4DX64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX64"
  },
  {
    "uniprot": "Q4E091",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E091"
  },
  {
    "uniprot": "Q4CUN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUN4"
  },
  {
    "uniprot": "Q4DGQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGQ6"
  },
  {
    "uniprot": "Q4D5G7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5G7"
  },
  {
    "uniprot": "Q4DNA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNA0"
  },
  {
    "uniprot": "Q4D9Z9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Z9"
  },
  {
    "uniprot": "Q4DKJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKJ1"
  },
  {
    "uniprot": "Q4DEE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEE0"
  },
  {
    "uniprot": "Q4E368",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E368"
  },
  {
    "uniprot": "Q4CXK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXK3"
  },
  {
    "uniprot": "Q4DYV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYV8"
  },
  {
    "uniprot": "Q4CR90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR90"
  },
  {
    "uniprot": "Q4D8N6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8N6"
  },
  {
    "uniprot": "Q4E197",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E197"
  },
  {
    "uniprot": "Q4E2L4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2L4"
  },
  {
    "uniprot": "Q4DMV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMV3"
  },
  {
    "uniprot": "Q4DZ79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ79"
  },
  {
    "uniprot": "Q4DR42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR42"
  },
  {
    "uniprot": "Q4CY16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY16"
  },
  {
    "uniprot": "Q4DPH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPH4"
  },
  {
    "uniprot": "Q4CWL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWL3"
  },
  {
    "uniprot": "Q4CPB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPB3"
  },
  {
    "uniprot": "Q4DRM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRM8"
  },
  {
    "uniprot": "Q4D3L9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3L9"
  },
  {
    "uniprot": "Q4CUF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUF9"
  },
  {
    "uniprot": "Q4CV98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV98"
  },
  {
    "uniprot": "Q4E4L5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4L5"
  },
  {
    "uniprot": "Q4DFB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFB4"
  },
  {
    "uniprot": "Q4DJI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJI1"
  },
  {
    "uniprot": "Q4DVM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVM2"
  },
  {
    "uniprot": "Q4CZ96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ96"
  },
  {
    "uniprot": "Q4DXK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXK7"
  },
  {
    "uniprot": "Q4DQ48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ48"
  },
  {
    "uniprot": "Q4CN95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN95"
  },
  {
    "uniprot": "Q4DZG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZG3"
  },
  {
    "uniprot": "Q4DGG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGG2"
  },
  {
    "uniprot": "Q4D418",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D418"
  },
  {
    "uniprot": "Q4DM90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM90"
  },
  {
    "uniprot": "Q4E577",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E577"
  },
  {
    "uniprot": "Q4DXH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXH5"
  },
  {
    "uniprot": "Q4DEY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEY5"
  },
  {
    "uniprot": "Q4E4I6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4I6"
  },
  {
    "uniprot": "Q4DIJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIJ8"
  },
  {
    "uniprot": "Q4CR91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR91"
  },
  {
    "uniprot": "Q4DJ95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ95"
  },
  {
    "uniprot": "Q4E322",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E322"
  },
  {
    "uniprot": "Q4D365",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D365"
  },
  {
    "uniprot": "Q4D2E9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2E9"
  },
  {
    "uniprot": "Q4CQG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQG5"
  },
  {
    "uniprot": "Q4E0I9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0I9"
  },
  {
    "uniprot": "Q4DI65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI65"
  },
  {
    "uniprot": "Q4E2F6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2F6"
  },
  {
    "uniprot": "Q4DCZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCZ5"
  },
  {
    "uniprot": "Q4D737",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D737"
  },
  {
    "uniprot": "Q4DB83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB83"
  },
  {
    "uniprot": "Q4CPT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPT5"
  },
  {
    "uniprot": "Q4E4S0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4S0"
  },
  {
    "uniprot": "Q4DI91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI91"
  },
  {
    "uniprot": "Q4D186",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D186"
  },
  {
    "uniprot": "Q4E293",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E293"
  },
  {
    "uniprot": "Q4DJX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJX9"
  },
  {
    "uniprot": "Q4CYD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYD9"
  },
  {
    "uniprot": "Q4CQA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQA8"
  },
  {
    "uniprot": "Q4E659",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E659"
  },
  {
    "uniprot": "Q4DWF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWF2"
  },
  {
    "uniprot": "Q4DYB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYB9"
  },
  {
    "uniprot": "Q4DG94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG94"
  },
  {
    "uniprot": "Q4E4A9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4A9"
  },
  {
    "uniprot": "Q4DTX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTX3"
  },
  {
    "uniprot": "Q4DTX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTX9"
  },
  {
    "uniprot": "Q4DTD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTD9"
  },
  {
    "uniprot": "Q4E3J5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3J5"
  },
  {
    "uniprot": "Q4DSU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSU8"
  },
  {
    "uniprot": "Q4E3M7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3M7"
  },
  {
    "uniprot": "Q4DVG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVG9"
  },
  {
    "uniprot": "Q4E4X7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4X7"
  },
  {
    "uniprot": "Q4DHY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHY9"
  },
  {
    "uniprot": "Q4DQQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQQ0"
  },
  {
    "uniprot": "Q4DCH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCH2"
  },
  {
    "uniprot": "Q4DJI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJI3"
  },
  {
    "uniprot": "Q4DSU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSU0"
  },
  {
    "uniprot": "Q4D8J0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8J0"
  },
  {
    "uniprot": "Q4D2H7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2H7"
  },
  {
    "uniprot": "Q4DHZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHZ7"
  },
  {
    "uniprot": "Q4DXV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXV9"
  },
  {
    "uniprot": "Q4CZG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZG8"
  },
  {
    "uniprot": "Q4DDU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDU3"
  },
  {
    "uniprot": "Q4DJT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJT0"
  },
  {
    "uniprot": "Q4E1Y5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Y5"
  },
  {
    "uniprot": "Q4DYH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYH6"
  },
  {
    "uniprot": "Q4D8E1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8E1"
  },
  {
    "uniprot": "Q4D1S2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1S2"
  },
  {
    "uniprot": "Q4CVT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVT9"
  },
  {
    "uniprot": "Q4DXM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXM2"
  },
  {
    "uniprot": "Q4D5Y3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Y3"
  },
  {
    "uniprot": "Q4DKG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKG1"
  },
  {
    "uniprot": "Q4DYD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYD6"
  },
  {
    "uniprot": "Q4CPV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPV1"
  },
  {
    "uniprot": "Q4DM63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM63"
  },
  {
    "uniprot": "Q4CLS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLS7"
  },
  {
    "uniprot": "Q4DCA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCA5"
  },
  {
    "uniprot": "Q4D7Y0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Y0"
  },
  {
    "uniprot": "Q4D3L4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3L4"
  },
  {
    "uniprot": "Q4DQH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQH9"
  },
  {
    "uniprot": "Q4D1P6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1P6"
  },
  {
    "uniprot": "Q4E4N1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4N1"
  },
  {
    "uniprot": "Q4D1F0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1F0"
  },
  {
    "uniprot": "Q4E2N3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2N3"
  },
  {
    "uniprot": "Q4D9G5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9G5"
  },
  {
    "uniprot": "Q4DW55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW55"
  },
  {
    "uniprot": "Q4DR22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR22"
  },
  {
    "uniprot": "Q4D7B0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7B0"
  },
  {
    "uniprot": "Q4CR00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR00"
  },
  {
    "uniprot": "Q4CT55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT55"
  },
  {
    "uniprot": "Q4CZX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZX5"
  },
  {
    "uniprot": "Q4D9B0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9B0"
  },
  {
    "uniprot": "Q4DJK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJK7"
  },
  {
    "uniprot": "Q4D4V9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4V9"
  },
  {
    "uniprot": "Q4E0H6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0H6"
  },
  {
    "uniprot": "Q4CMH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMH8"
  },
  {
    "uniprot": "Q4CUF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUF8"
  },
  {
    "uniprot": "Q4DV52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV52"
  },
  {
    "uniprot": "Q4E4U9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4U9"
  },
  {
    "uniprot": "Q4DXC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXC6"
  },
  {
    "uniprot": "Q4DNA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNA9"
  },
  {
    "uniprot": "Q4DZM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZM6"
  },
  {
    "uniprot": "Q4DU61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU61"
  },
  {
    "uniprot": "Q4DU75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU75"
  },
  {
    "uniprot": "Q4E4A7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4A7"
  },
  {
    "uniprot": "Q4DSG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSG3"
  },
  {
    "uniprot": "Q4CKD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKD5"
  },
  {
    "uniprot": "Q4CXX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXX4"
  },
  {
    "uniprot": "Q4DWW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWW7"
  },
  {
    "uniprot": "Q4CVM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVM3"
  },
  {
    "uniprot": "Q4D287",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D287"
  },
  {
    "uniprot": "Q4DTI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTI1"
  },
  {
    "uniprot": "Q4CRW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRW1"
  },
  {
    "uniprot": "Q4D8J1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8J1"
  },
  {
    "uniprot": "Q4CXW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXW1"
  },
  {
    "uniprot": "Q4CZG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZG6"
  },
  {
    "uniprot": "Q4E284",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E284"
  },
  {
    "uniprot": "Q4CMF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMF9"
  },
  {
    "uniprot": "Q4CW58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW58"
  },
  {
    "uniprot": "Q4DRA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRA8"
  },
  {
    "uniprot": "Q4E439",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E439"
  },
  {
    "uniprot": "Q4DS49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS49"
  },
  {
    "uniprot": "Q4DYY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYY3"
  },
  {
    "uniprot": "Q4CY48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY48"
  },
  {
    "uniprot": "Q4CTW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTW1"
  },
  {
    "uniprot": "Q4DIF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIF3"
  },
  {
    "uniprot": "Q4DAQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAQ0"
  },
  {
    "uniprot": "Q4D7P9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7P9"
  },
  {
    "uniprot": "Q4D3C6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3C6"
  },
  {
    "uniprot": "Q4CPI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPI4"
  },
  {
    "uniprot": "Q4CKT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKT5"
  },
  {
    "uniprot": "Q4D0I6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0I6"
  },
  {
    "uniprot": "Q4CSJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSJ7"
  },
  {
    "uniprot": "Q4CX16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX16"
  },
  {
    "uniprot": "Q4DN42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN42"
  },
  {
    "uniprot": "Q4DRV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRV3"
  },
  {
    "uniprot": "Q4DGU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGU6"
  },
  {
    "uniprot": "Q4DS47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS47"
  },
  {
    "uniprot": "Q4DEV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEV0"
  },
  {
    "uniprot": "Q4DIB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIB4"
  },
  {
    "uniprot": "Q4D4Z5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Z5"
  },
  {
    "uniprot": "Q4DTN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTN0"
  },
  {
    "uniprot": "Q4E5V3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5V3"
  },
  {
    "uniprot": "Q4DT48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT48"
  },
  {
    "uniprot": "Q4D3V2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3V2"
  },
  {
    "uniprot": "Q4D6J6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6J6"
  },
  {
    "uniprot": "Q4D9P1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9P1"
  },
  {
    "uniprot": "Q4D8M0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8M0"
  },
  {
    "uniprot": "Q4E691",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E691"
  },
  {
    "uniprot": "Q4DTW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTW1"
  },
  {
    "uniprot": "Q4DPD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPD8"
  },
  {
    "uniprot": "Q4D2V4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2V4"
  },
  {
    "uniprot": "Q4CND4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CND4"
  },
  {
    "uniprot": "Q4CLU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLU7"
  },
  {
    "uniprot": "Q4DSS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSS3"
  },
  {
    "uniprot": "Q4DG98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG98"
  },
  {
    "uniprot": "Q4E3Y0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Y0"
  },
  {
    "uniprot": "Q4D3G1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3G1"
  },
  {
    "uniprot": "Q4CNA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNA7"
  },
  {
    "uniprot": "Q4DT94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT94"
  },
  {
    "uniprot": "Q4E4L7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4L7"
  },
  {
    "uniprot": "Q4CPU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPU6"
  },
  {
    "uniprot": "Q4E3L7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3L7"
  },
  {
    "uniprot": "Q4CY12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY12"
  },
  {
    "uniprot": "Q4D3L3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3L3"
  },
  {
    "uniprot": "Q4DR18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR18"
  },
  {
    "uniprot": "Q4DEH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEH0"
  },
  {
    "uniprot": "Q4CYV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYV5"
  },
  {
    "uniprot": "Q4E262",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E262"
  },
  {
    "uniprot": "Q4CNU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNU8"
  },
  {
    "uniprot": "Q4DDN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDN2"
  },
  {
    "uniprot": "Q4D1B7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1B7"
  },
  {
    "uniprot": "Q4D1P2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1P2"
  },
  {
    "uniprot": "Q4CM49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM49"
  },
  {
    "uniprot": "Q4E083",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E083"
  },
  {
    "uniprot": "Q4DJ63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ63"
  },
  {
    "uniprot": "Q4E2U3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2U3"
  },
  {
    "uniprot": "Q4DBH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBH2"
  },
  {
    "uniprot": "Q4DXU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXU7"
  },
  {
    "uniprot": "Q4E5Q1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Q1"
  },
  {
    "uniprot": "Q4DRP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRP3"
  },
  {
    "uniprot": "Q4CR80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR80"
  },
  {
    "uniprot": "Q4DB20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB20"
  },
  {
    "uniprot": "Q4CXS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXS3"
  },
  {
    "uniprot": "Q4DT95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT95"
  },
  {
    "uniprot": "Q4CSU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSU8"
  },
  {
    "uniprot": "Q4E0M4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0M4"
  },
  {
    "uniprot": "Q4D8L6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8L6"
  },
  {
    "uniprot": "Q4CR68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR68"
  },
  {
    "uniprot": "Q4DDE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDE7"
  },
  {
    "uniprot": "Q4D2W1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2W1"
  },
  {
    "uniprot": "Q4DTE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTE9"
  },
  {
    "uniprot": "Q4DIY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIY0"
  },
  {
    "uniprot": "Q4D5J7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5J7"
  },
  {
    "uniprot": "Q4D4U9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4U9"
  },
  {
    "uniprot": "Q4D8R0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8R0"
  },
  {
    "uniprot": "Q4CV92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV92"
  },
  {
    "uniprot": "Q4DEH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEH4"
  },
  {
    "uniprot": "Q4D8I5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8I5"
  },
  {
    "uniprot": "Q4D4C5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4C5"
  },
  {
    "uniprot": "Q4DIR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIR1"
  },
  {
    "uniprot": "Q4D6Y7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Y7"
  },
  {
    "uniprot": "Q4D9Q7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Q7"
  },
  {
    "uniprot": "Q4CT01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT01"
  },
  {
    "uniprot": "Q4DJR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJR8"
  },
  {
    "uniprot": "Q4DTY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTY7"
  },
  {
    "uniprot": "Q4DBB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBB2"
  },
  {
    "uniprot": "Q4DFK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFK2"
  },
  {
    "uniprot": "Q4CW13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW13"
  },
  {
    "uniprot": "Q4CLZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLZ5"
  },
  {
    "uniprot": "Q4DUN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUN0"
  },
  {
    "uniprot": "Q4CQ81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ81"
  },
  {
    "uniprot": "Q4E5X0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5X0"
  },
  {
    "uniprot": "Q4CY85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY85"
  },
  {
    "uniprot": "Q4D8D1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8D1"
  },
  {
    "uniprot": "Q4CKV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKV7"
  },
  {
    "uniprot": "Q4CWC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWC6"
  },
  {
    "uniprot": "Q4CUD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUD4"
  },
  {
    "uniprot": "Q4CPZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPZ5"
  },
  {
    "uniprot": "Q4CS79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS79"
  },
  {
    "uniprot": "Q4CNL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNL8"
  },
  {
    "uniprot": "Q4DAX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAX9"
  },
  {
    "uniprot": "Q4D032",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D032"
  },
  {
    "uniprot": "Q4D641",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D641"
  },
  {
    "uniprot": "Q4DTP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTP5"
  },
  {
    "uniprot": "Q4E132",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E132"
  },
  {
    "uniprot": "Q4CZD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZD2"
  },
  {
    "uniprot": "Q4DA60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA60"
  },
  {
    "uniprot": "Q4DWI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWI2"
  },
  {
    "uniprot": "Q4D4W1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4W1"
  },
  {
    "uniprot": "Q4D0Z7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Z7"
  },
  {
    "uniprot": "Q4E626",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E626"
  },
  {
    "uniprot": "Q4DSC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSC8"
  },
  {
    "uniprot": "Q4D5B0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5B0"
  },
  {
    "uniprot": "Q4D755",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D755"
  },
  {
    "uniprot": "Q4D4K6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4K6"
  },
  {
    "uniprot": "Q4CV25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV25"
  },
  {
    "uniprot": "Q4CZG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZG9"
  },
  {
    "uniprot": "Q4E3N5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3N5"
  },
  {
    "uniprot": "Q4E1L6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1L6"
  },
  {
    "uniprot": "Q4CVF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVF5"
  },
  {
    "uniprot": "Q4DMA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMA8"
  },
  {
    "uniprot": "Q4DB61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB61"
  },
  {
    "uniprot": "Q4D9Y3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Y3"
  },
  {
    "uniprot": "Q4D2G5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2G5"
  },
  {
    "uniprot": "Q4D464",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D464"
  },
  {
    "uniprot": "Q4E1T8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1T8"
  },
  {
    "uniprot": "Q4CKP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKP9"
  },
  {
    "uniprot": "Q4DSQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSQ7"
  },
  {
    "uniprot": "Q4E2N2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2N2"
  },
  {
    "uniprot": "Q4DUU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUU2"
  },
  {
    "uniprot": "Q4E0U0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0U0"
  },
  {
    "uniprot": "Q4D0I2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0I2"
  },
  {
    "uniprot": "Q4DF02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF02"
  },
  {
    "uniprot": "Q4D7D8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7D8"
  },
  {
    "uniprot": "Q4DWC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWC6"
  },
  {
    "uniprot": "Q4DG09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG09"
  },
  {
    "uniprot": "Q4DC83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC83"
  },
  {
    "uniprot": "Q4CMU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMU3"
  },
  {
    "uniprot": "Q4DX21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX21"
  },
  {
    "uniprot": "Q4E1E8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1E8"
  },
  {
    "uniprot": "Q4DCK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCK2"
  },
  {
    "uniprot": "Q4E4K0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4K0"
  },
  {
    "uniprot": "Q4D5X7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5X7"
  },
  {
    "uniprot": "Q4DCW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCW0"
  },
  {
    "uniprot": "Q4CNZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNZ9"
  },
  {
    "uniprot": "Q4CQ48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ48"
  },
  {
    "uniprot": "Q4E5F7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5F7"
  },
  {
    "uniprot": "Q4DDK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDK9"
  },
  {
    "uniprot": "Q4D4M1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4M1"
  },
  {
    "uniprot": "Q4CKH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKH5"
  },
  {
    "uniprot": "Q4CPW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPW1"
  },
  {
    "uniprot": "Q4CPS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPS7"
  },
  {
    "uniprot": "Q4DCV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCV1"
  },
  {
    "uniprot": "Q4DDQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDQ9"
  },
  {
    "uniprot": "Q4DF08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF08"
  },
  {
    "uniprot": "Q4DPW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPW8"
  },
  {
    "uniprot": "Q4DMD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMD6"
  },
  {
    "uniprot": "Q4DNN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNN3"
  },
  {
    "uniprot": "Q4E3U8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3U8"
  },
  {
    "uniprot": "Q4E502",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E502"
  },
  {
    "uniprot": "Q4D2Q1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Q1"
  },
  {
    "uniprot": "Q4DKE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKE4"
  },
  {
    "uniprot": "Q4D1W7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1W7"
  },
  {
    "uniprot": "Q4CKC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKC9"
  },
  {
    "uniprot": "Q4CZH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZH6"
  },
  {
    "uniprot": "Q4CRM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRM1"
  },
  {
    "uniprot": "Q4DU79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU79"
  },
  {
    "uniprot": "Q4DQ78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ78"
  },
  {
    "uniprot": "Q4CWY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWY9"
  },
  {
    "uniprot": "Q4E3M9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3M9"
  },
  {
    "uniprot": "Q4DRK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRK9"
  },
  {
    "uniprot": "Q4CW87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW87"
  },
  {
    "uniprot": "Q4CYL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYL2"
  },
  {
    "uniprot": "Q4DUS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUS9"
  },
  {
    "uniprot": "Q4E434",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E434"
  },
  {
    "uniprot": "Q4DM37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM37"
  },
  {
    "uniprot": "Q4CTG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTG0"
  },
  {
    "uniprot": "Q4D9B2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9B2"
  },
  {
    "uniprot": "Q4E1I5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1I5"
  },
  {
    "uniprot": "Q4DMT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMT5"
  },
  {
    "uniprot": "Q4DEN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEN2"
  },
  {
    "uniprot": "Q4DW29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW29"
  },
  {
    "uniprot": "Q4DSQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSQ1"
  },
  {
    "uniprot": "Q4DRR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRR8"
  },
  {
    "uniprot": "Q4DVX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVX6"
  },
  {
    "uniprot": "Q4DZU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZU8"
  },
  {
    "uniprot": "Q4D8B8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8B8"
  },
  {
    "uniprot": "Q4D3B0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3B0"
  },
  {
    "uniprot": "Q4CLY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLY8"
  },
  {
    "uniprot": "Q4D8J2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8J2"
  },
  {
    "uniprot": "Q4DCY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCY3"
  },
  {
    "uniprot": "Q4CP30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP30"
  },
  {
    "uniprot": "Q4DR68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR68"
  },
  {
    "uniprot": "Q4DXJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXJ4"
  },
  {
    "uniprot": "Q4DDA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDA0"
  },
  {
    "uniprot": "Q4DT46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT46"
  },
  {
    "uniprot": "Q4DQU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQU8"
  },
  {
    "uniprot": "Q4DXZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXZ8"
  },
  {
    "uniprot": "Q4D6L3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6L3"
  },
  {
    "uniprot": "Q4DK28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK28"
  },
  {
    "uniprot": "Q4DD78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD78"
  },
  {
    "uniprot": "Q4E2N6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2N6"
  },
  {
    "uniprot": "Q4DS18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS18"
  },
  {
    "uniprot": "Q4DIZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIZ8"
  },
  {
    "uniprot": "Q4CLU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLU9"
  },
  {
    "uniprot": "Q4D3P7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3P7"
  },
  {
    "uniprot": "Q4DTN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTN6"
  },
  {
    "uniprot": "Q4DB44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB44"
  },
  {
    "uniprot": "Q4CZZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZZ0"
  },
  {
    "uniprot": "Q4CLK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLK0"
  },
  {
    "uniprot": "Q4CVV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVV0"
  },
  {
    "uniprot": "Q4D0K4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0K4"
  },
  {
    "uniprot": "Q4CTH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTH4"
  },
  {
    "uniprot": "Q4D638",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D638"
  },
  {
    "uniprot": "Q4CT77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT77"
  },
  {
    "uniprot": "Q4DHR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHR0"
  },
  {
    "uniprot": "Q4D7Q7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Q7"
  },
  {
    "uniprot": "Q4D2X3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2X3"
  },
  {
    "uniprot": "Q4E613",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E613"
  },
  {
    "uniprot": "Q4E4B2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4B2"
  },
  {
    "uniprot": "Q4DER3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DER3"
  },
  {
    "uniprot": "Q4DK86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK86"
  },
  {
    "uniprot": "Q4CLN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLN5"
  },
  {
    "uniprot": "Q4CVR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVR9"
  },
  {
    "uniprot": "Q4E2W5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2W5"
  },
  {
    "uniprot": "Q4CQ86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ86"
  },
  {
    "uniprot": "Q4DKH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKH5"
  },
  {
    "uniprot": "Q4DYF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYF3"
  },
  {
    "uniprot": "Q4CPY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPY4"
  },
  {
    "uniprot": "Q4DFB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFB0"
  },
  {
    "uniprot": "Q4CXE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXE3"
  },
  {
    "uniprot": "Q4DKZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKZ7"
  },
  {
    "uniprot": "Q4DR01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR01"
  },
  {
    "uniprot": "Q4DSK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSK9"
  },
  {
    "uniprot": "Q4DPT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPT2"
  },
  {
    "uniprot": "Q4D6Y8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Y8"
  },
  {
    "uniprot": "Q4DYG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYG7"
  },
  {
    "uniprot": "Q4CSM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSM1"
  },
  {
    "uniprot": "Q4D371",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D371"
  },
  {
    "uniprot": "Q4CZP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZP6"
  },
  {
    "uniprot": "Q4DCL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCL0"
  },
  {
    "uniprot": "Q4D3P3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3P3"
  },
  {
    "uniprot": "Q4E060",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E060"
  },
  {
    "uniprot": "Q4D7N8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7N8"
  },
  {
    "uniprot": "Q4CLE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLE6"
  },
  {
    "uniprot": "Q4D5K2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5K2"
  },
  {
    "uniprot": "Q4D5S9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5S9"
  },
  {
    "uniprot": "Q4DA27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA27"
  },
  {
    "uniprot": "Q4CZB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZB6"
  },
  {
    "uniprot": "Q4CU79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU79"
  },
  {
    "uniprot": "Q4DEG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEG2"
  },
  {
    "uniprot": "Q4DIB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIB6"
  },
  {
    "uniprot": "Q4D4S0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4S0"
  },
  {
    "uniprot": "Q4CLK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLK1"
  },
  {
    "uniprot": "Q4D9G7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9G7"
  },
  {
    "uniprot": "Q4CNA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNA1"
  },
  {
    "uniprot": "Q4DA20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA20"
  },
  {
    "uniprot": "Q4DIJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIJ3"
  },
  {
    "uniprot": "Q4CX74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX74"
  },
  {
    "uniprot": "Q4D2W0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2W0"
  },
  {
    "uniprot": "Q4CMS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMS8"
  },
  {
    "uniprot": "Q4CYG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYG2"
  },
  {
    "uniprot": "Q4DY90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY90"
  },
  {
    "uniprot": "Q4D311",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D311"
  },
  {
    "uniprot": "Q4DUD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUD8"
  },
  {
    "uniprot": "Q4CZD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZD3"
  },
  {
    "uniprot": "Q4CYB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYB1"
  },
  {
    "uniprot": "Q4CT92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT92"
  },
  {
    "uniprot": "Q4DR34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR34"
  },
  {
    "uniprot": "Q4DID2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DID2"
  },
  {
    "uniprot": "Q4DZN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZN1"
  },
  {
    "uniprot": "Q4CWY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWY1"
  },
  {
    "uniprot": "Q4CT07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT07"
  },
  {
    "uniprot": "Q4DJQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJQ1"
  },
  {
    "uniprot": "Q4CX18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX18"
  },
  {
    "uniprot": "Q4D8T8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8T8"
  },
  {
    "uniprot": "Q4D8I8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8I8"
  },
  {
    "uniprot": "Q4D7Z4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Z4"
  },
  {
    "uniprot": "Q4E2B1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2B1"
  },
  {
    "uniprot": "Q4DX62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX62"
  },
  {
    "uniprot": "Q4E2S6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2S6"
  },
  {
    "uniprot": "Q4DZW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZW1"
  },
  {
    "uniprot": "Q4D859",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D859"
  },
  {
    "uniprot": "Q4DS97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS97"
  },
  {
    "uniprot": "Q4D993",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D993"
  },
  {
    "uniprot": "Q4DTL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTL2"
  },
  {
    "uniprot": "Q4D2S6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2S6"
  },
  {
    "uniprot": "Q4E0T3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0T3"
  },
  {
    "uniprot": "Q4CPI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPI7"
  },
  {
    "uniprot": "Q4DUB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUB9"
  },
  {
    "uniprot": "Q4DUJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUJ3"
  },
  {
    "uniprot": "Q4E4W2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4W2"
  },
  {
    "uniprot": "Q4CPN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPN7"
  },
  {
    "uniprot": "Q4DME1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DME1"
  },
  {
    "uniprot": "Q4DYF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYF0"
  },
  {
    "uniprot": "Q4E318",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E318"
  },
  {
    "uniprot": "Q4CTP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTP5"
  },
  {
    "uniprot": "Q4DTA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTA3"
  },
  {
    "uniprot": "Q4CWC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWC8"
  },
  {
    "uniprot": "Q4DQE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQE5"
  },
  {
    "uniprot": "Q4DHG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHG3"
  },
  {
    "uniprot": "Q4DCK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCK7"
  },
  {
    "uniprot": "Q4D4D8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4D8"
  },
  {
    "uniprot": "Q4DXU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXU5"
  },
  {
    "uniprot": "Q4D2W5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2W5"
  },
  {
    "uniprot": "Q4DW17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW17"
  },
  {
    "uniprot": "Q4DIT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIT2"
  },
  {
    "uniprot": "Q4DMH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMH5"
  },
  {
    "uniprot": "Q4CWZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWZ9"
  },
  {
    "uniprot": "Q4E2Y7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Y7"
  },
  {
    "uniprot": "Q4DJS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJS1"
  },
  {
    "uniprot": "Q4CQV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQV7"
  },
  {
    "uniprot": "Q4CSM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSM2"
  },
  {
    "uniprot": "Q4D4G8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4G8"
  },
  {
    "uniprot": "Q4CM86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM86"
  },
  {
    "uniprot": "Q4E2E3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2E3"
  },
  {
    "uniprot": "Q4CLE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLE9"
  },
  {
    "uniprot": "Q4CZC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZC8"
  },
  {
    "uniprot": "Q4CYA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYA7"
  },
  {
    "uniprot": "Q4CN80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN80"
  },
  {
    "uniprot": "Q4DE68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE68"
  },
  {
    "uniprot": "Q4CMU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMU9"
  },
  {
    "uniprot": "Q4E399",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E399"
  },
  {
    "uniprot": "Q4E4B1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4B1"
  },
  {
    "uniprot": "Q4DLD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLD9"
  },
  {
    "uniprot": "Q4DB15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB15"
  },
  {
    "uniprot": "Q4D671",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D671"
  },
  {
    "uniprot": "Q4CT97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT97"
  },
  {
    "uniprot": "Q4CWU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWU5"
  },
  {
    "uniprot": "Q4DJK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJK9"
  },
  {
    "uniprot": "Q4DEH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEH3"
  },
  {
    "uniprot": "Q4DKC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKC5"
  },
  {
    "uniprot": "Q4DI62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI62"
  },
  {
    "uniprot": "Q4D0M5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0M5"
  },
  {
    "uniprot": "Q4DUY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUY4"
  },
  {
    "uniprot": "Q4DNX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNX3"
  },
  {
    "uniprot": "Q4DYT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYT8"
  },
  {
    "uniprot": "Q4CN11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN11"
  },
  {
    "uniprot": "Q4DVW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVW6"
  },
  {
    "uniprot": "Q4DBM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBM1"
  },
  {
    "uniprot": "Q4D6Z7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Z7"
  },
  {
    "uniprot": "Q4DS81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS81"
  },
  {
    "uniprot": "Q4DND5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DND5"
  },
  {
    "uniprot": "Q4E5U0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5U0"
  },
  {
    "uniprot": "Q4DNW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNW5"
  },
  {
    "uniprot": "Q4DCD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCD7"
  },
  {
    "uniprot": "Q4DPM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPM0"
  },
  {
    "uniprot": "Q4DJ80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ80"
  },
  {
    "uniprot": "Q4DQI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQI8"
  },
  {
    "uniprot": "Q4DTE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTE4"
  },
  {
    "uniprot": "Q4DKY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKY2"
  },
  {
    "uniprot": "Q4E1Y8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Y8"
  },
  {
    "uniprot": "Q4DJX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJX7"
  },
  {
    "uniprot": "Q4CXF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXF8"
  },
  {
    "uniprot": "Q4DDS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDS2"
  },
  {
    "uniprot": "Q4CYM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYM3"
  },
  {
    "uniprot": "Q4DBZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBZ5"
  },
  {
    "uniprot": "Q4D0V9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0V9"
  },
  {
    "uniprot": "Q4D2Y8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Y8"
  },
  {
    "uniprot": "Q4DCH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCH6"
  },
  {
    "uniprot": "Q4D1J7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1J7"
  },
  {
    "uniprot": "Q4CQK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQK6"
  },
  {
    "uniprot": "Q4CLV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLV7"
  },
  {
    "uniprot": "Q4D4C3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4C3"
  },
  {
    "uniprot": "Q4DM93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM93"
  },
  {
    "uniprot": "Q4DA39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA39"
  },
  {
    "uniprot": "Q4DRX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRX8"
  },
  {
    "uniprot": "Q4DEB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEB3"
  },
  {
    "uniprot": "Q4D918",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D918"
  },
  {
    "uniprot": "Q4DT35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT35"
  },
  {
    "uniprot": "Q4DKW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKW1"
  },
  {
    "uniprot": "Q4DMH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMH2"
  },
  {
    "uniprot": "Q4CYC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYC5"
  },
  {
    "uniprot": "Q4DFN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFN3"
  },
  {
    "uniprot": "Q4DLA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLA6"
  },
  {
    "uniprot": "Q4E2H9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2H9"
  },
  {
    "uniprot": "Q4CPZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPZ1"
  },
  {
    "uniprot": "Q4DGS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGS5"
  },
  {
    "uniprot": "Q4E5D3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5D3"
  },
  {
    "uniprot": "Q4DC51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC51"
  },
  {
    "uniprot": "Q4CRB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRB6"
  },
  {
    "uniprot": "Q4CT04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT04"
  },
  {
    "uniprot": "Q4CYQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYQ8"
  },
  {
    "uniprot": "Q4E5A7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5A7"
  },
  {
    "uniprot": "Q4DYT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYT4"
  },
  {
    "uniprot": "Q4DCY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCY5"
  },
  {
    "uniprot": "Q4D2J4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2J4"
  },
  {
    "uniprot": "Q4DXA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXA3"
  },
  {
    "uniprot": "Q4DFT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFT1"
  },
  {
    "uniprot": "Q4E5I7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5I7"
  },
  {
    "uniprot": "Q4DSM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSM8"
  },
  {
    "uniprot": "Q4D5J0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5J0"
  },
  {
    "uniprot": "Q4DZ51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ51"
  },
  {
    "uniprot": "Q4DXJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXJ3"
  },
  {
    "uniprot": "Q4E3L1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3L1"
  },
  {
    "uniprot": "Q4D2K0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2K0"
  },
  {
    "uniprot": "Q4DGH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGH2"
  },
  {
    "uniprot": "Q4DKV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKV6"
  },
  {
    "uniprot": "Q4CRN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRN6"
  },
  {
    "uniprot": "Q4D6R4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6R4"
  },
  {
    "uniprot": "Q4DLE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLE7"
  },
  {
    "uniprot": "Q4DH88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH88"
  },
  {
    "uniprot": "Q4DIL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIL6"
  },
  {
    "uniprot": "Q4DJ30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ30"
  },
  {
    "uniprot": "Q4E0E7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0E7"
  },
  {
    "uniprot": "Q4DU55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU55"
  },
  {
    "uniprot": "Q4E5D1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5D1"
  },
  {
    "uniprot": "Q4DB72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB72"
  },
  {
    "uniprot": "Q4DLK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLK8"
  },
  {
    "uniprot": "Q4CP43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP43"
  },
  {
    "uniprot": "Q4DZC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZC6"
  },
  {
    "uniprot": "Q4E096",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E096"
  },
  {
    "uniprot": "Q4CL17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL17"
  },
  {
    "uniprot": "Q4CZI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZI9"
  },
  {
    "uniprot": "Q4DB93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB93"
  },
  {
    "uniprot": "Q4DPT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPT7"
  },
  {
    "uniprot": "Q4DQP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQP9"
  },
  {
    "uniprot": "Q4D3N5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3N5"
  },
  {
    "uniprot": "Q4DAI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAI0"
  },
  {
    "uniprot": "Q4DAB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAB8"
  },
  {
    "uniprot": "Q4CX36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX36"
  },
  {
    "uniprot": "Q4D5L0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5L0"
  },
  {
    "uniprot": "Q4CSH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSH3"
  },
  {
    "uniprot": "Q4CXL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXL3"
  },
  {
    "uniprot": "Q4D4L2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4L2"
  },
  {
    "uniprot": "Q4DU08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU08"
  },
  {
    "uniprot": "Q4DC35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC35"
  },
  {
    "uniprot": "Q4DKN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKN4"
  },
  {
    "uniprot": "Q4D3S0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3S0"
  },
  {
    "uniprot": "Q4CUJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUJ7"
  },
  {
    "uniprot": "Q4CXK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXK1"
  },
  {
    "uniprot": "Q4DSP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSP9"
  },
  {
    "uniprot": "Q4CZP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZP3"
  },
  {
    "uniprot": "Q4E3K6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3K6"
  },
  {
    "uniprot": "Q4DFD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFD4"
  },
  {
    "uniprot": "Q4DN47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN47"
  },
  {
    "uniprot": "Q4DTI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTI3"
  },
  {
    "uniprot": "Q4D054",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D054"
  },
  {
    "uniprot": "Q4CL66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL66"
  },
  {
    "uniprot": "Q4DIF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIF6"
  },
  {
    "uniprot": "Q4DG77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG77"
  },
  {
    "uniprot": "Q4CWU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWU7"
  },
  {
    "uniprot": "Q4DYX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYX4"
  },
  {
    "uniprot": "Q4CS91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS91"
  },
  {
    "uniprot": "Q4E5Z4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Z4"
  },
  {
    "uniprot": "Q4D2T9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2T9"
  },
  {
    "uniprot": "Q4DQZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQZ6"
  },
  {
    "uniprot": "Q4DYU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYU8"
  },
  {
    "uniprot": "Q4DH85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH85"
  },
  {
    "uniprot": "Q4CUA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUA3"
  },
  {
    "uniprot": "Q4DA05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA05"
  },
  {
    "uniprot": "Q4DU19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU19"
  },
  {
    "uniprot": "Q4CNB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNB4"
  },
  {
    "uniprot": "Q4E1F4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1F4"
  },
  {
    "uniprot": "Q4DA94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA94"
  },
  {
    "uniprot": "Q4DPX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPX7"
  },
  {
    "uniprot": "Q4E5Y4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Y4"
  },
  {
    "uniprot": "Q4DM26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM26"
  },
  {
    "uniprot": "Q4CVY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVY9"
  },
  {
    "uniprot": "Q4DKX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKX4"
  },
  {
    "uniprot": "Q4CQI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQI6"
  },
  {
    "uniprot": "Q4DIX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIX3"
  },
  {
    "uniprot": "Q4DP38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP38"
  },
  {
    "uniprot": "Q4DMG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMG3"
  },
  {
    "uniprot": "Q4E599",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E599"
  },
  {
    "uniprot": "Q4D320",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D320"
  },
  {
    "uniprot": "Q4DSJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSJ7"
  },
  {
    "uniprot": "Q4CPG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPG5"
  },
  {
    "uniprot": "Q4CL09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL09"
  },
  {
    "uniprot": "Q4CTS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTS1"
  },
  {
    "uniprot": "Q4D4F9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4F9"
  },
  {
    "uniprot": "Q4D6Q8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Q8"
  },
  {
    "uniprot": "Q4DZL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZL7"
  },
  {
    "uniprot": "Q4CNE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNE8"
  },
  {
    "uniprot": "Q4DUQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUQ1"
  },
  {
    "uniprot": "Q4D129",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D129"
  },
  {
    "uniprot": "Q4D8T9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8T9"
  },
  {
    "uniprot": "Q4DVA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVA1"
  },
  {
    "uniprot": "Q4CLR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLR8"
  },
  {
    "uniprot": "Q4E0E5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0E5"
  },
  {
    "uniprot": "Q4E3P6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3P6"
  },
  {
    "uniprot": "Q4D667",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D667"
  },
  {
    "uniprot": "Q4DPI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPI0"
  },
  {
    "uniprot": "Q4CMW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMW0"
  },
  {
    "uniprot": "Q4D958",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D958"
  },
  {
    "uniprot": "Q4E073",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E073"
  },
  {
    "uniprot": "Q4DX14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX14"
  },
  {
    "uniprot": "Q4DUP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUP1"
  },
  {
    "uniprot": "Q4D9V7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9V7"
  },
  {
    "uniprot": "Q4DG07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG07"
  },
  {
    "uniprot": "Q4DJN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJN3"
  },
  {
    "uniprot": "Q4DBV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBV7"
  },
  {
    "uniprot": "Q4DKJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKJ4"
  },
  {
    "uniprot": "Q4D252",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D252"
  },
  {
    "uniprot": "Q4CPP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPP8"
  },
  {
    "uniprot": "Q4DV41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV41"
  },
  {
    "uniprot": "Q4DRV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRV2"
  },
  {
    "uniprot": "Q4CYW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYW5"
  },
  {
    "uniprot": "Q4DVJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVJ7"
  },
  {
    "uniprot": "Q4DGB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGB9"
  },
  {
    "uniprot": "Q4D043",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D043"
  },
  {
    "uniprot": "Q4D0T4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0T4"
  },
  {
    "uniprot": "Q4DGY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGY2"
  },
  {
    "uniprot": "Q4CNJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNJ5"
  },
  {
    "uniprot": "Q4DQY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQY2"
  },
  {
    "uniprot": "Q4DFY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFY4"
  },
  {
    "uniprot": "Q4DHF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHF8"
  },
  {
    "uniprot": "Q4DEN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEN5"
  },
  {
    "uniprot": "Q4D9I1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9I1"
  },
  {
    "uniprot": "Q4CX25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX25"
  },
  {
    "uniprot": "Q4CW44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW44"
  },
  {
    "uniprot": "Q4CQB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQB2"
  },
  {
    "uniprot": "Q4CUB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUB0"
  },
  {
    "uniprot": "Q4CSG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSG2"
  },
  {
    "uniprot": "Q4DFL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFL2"
  },
  {
    "uniprot": "Q4DCC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCC7"
  },
  {
    "uniprot": "Q4D9J6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9J6"
  },
  {
    "uniprot": "Q4E390",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E390"
  },
  {
    "uniprot": "Q4D4D6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4D6"
  },
  {
    "uniprot": "Q4CR82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR82"
  },
  {
    "uniprot": "Q4D465",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D465"
  },
  {
    "uniprot": "Q4DCG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCG1"
  },
  {
    "uniprot": "Q4CZ52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ52"
  },
  {
    "uniprot": "Q4D897",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D897"
  },
  {
    "uniprot": "Q4DK00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK00"
  },
  {
    "uniprot": "Q4D2Y6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Y6"
  },
  {
    "uniprot": "Q4CZ71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ71"
  },
  {
    "uniprot": "Q4CP00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP00"
  },
  {
    "uniprot": "Q4DRU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRU0"
  },
  {
    "uniprot": "Q4E3M8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3M8"
  },
  {
    "uniprot": "Q4DT75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT75"
  },
  {
    "uniprot": "Q4DHC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHC6"
  },
  {
    "uniprot": "Q4CW91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW91"
  },
  {
    "uniprot": "Q4DP46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP46"
  },
  {
    "uniprot": "Q4DF27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF27"
  },
  {
    "uniprot": "Q4DCG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCG8"
  },
  {
    "uniprot": "Q4D4Q6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Q6"
  },
  {
    "uniprot": "Q4D5U9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5U9"
  },
  {
    "uniprot": "Q4E1K3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1K3"
  },
  {
    "uniprot": "Q4E0C0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0C0"
  },
  {
    "uniprot": "Q4E0C6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0C6"
  },
  {
    "uniprot": "Q4CU15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU15"
  },
  {
    "uniprot": "Q4D3J5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3J5"
  },
  {
    "uniprot": "Q4DV35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV35"
  },
  {
    "uniprot": "Q4CZC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZC0"
  },
  {
    "uniprot": "Q4D7P8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7P8"
  },
  {
    "uniprot": "Q4D6Y9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Y9"
  },
  {
    "uniprot": "Q4D090",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D090"
  },
  {
    "uniprot": "Q4CYI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYI2"
  },
  {
    "uniprot": "Q4D1E0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1E0"
  },
  {
    "uniprot": "Q4CZ91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ91"
  },
  {
    "uniprot": "Q4D5Z4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Z4"
  },
  {
    "uniprot": "Q4CL00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL00"
  },
  {
    "uniprot": "Q4E5Q7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Q7"
  },
  {
    "uniprot": "Q4E171",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E171"
  },
  {
    "uniprot": "Q4DE12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE12"
  },
  {
    "uniprot": "Q4DYX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYX0"
  },
  {
    "uniprot": "Q4D221",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D221"
  },
  {
    "uniprot": "Q4D2B8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2B8"
  },
  {
    "uniprot": "Q4DIT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIT0"
  },
  {
    "uniprot": "Q4D789",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D789"
  },
  {
    "uniprot": "Q4DZ12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ12"
  },
  {
    "uniprot": "Q4E075",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E075"
  },
  {
    "uniprot": "Q4DA26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA26"
  },
  {
    "uniprot": "Q4DPX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPX0"
  },
  {
    "uniprot": "Q4DIJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIJ7"
  },
  {
    "uniprot": "Q4CYM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYM4"
  },
  {
    "uniprot": "Q4E392",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E392"
  },
  {
    "uniprot": "Q4DEJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEJ2"
  },
  {
    "uniprot": "Q4E3K5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3K5"
  },
  {
    "uniprot": "Q4DPR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPR1"
  },
  {
    "uniprot": "Q4DPI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPI3"
  },
  {
    "uniprot": "Q4DSF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSF6"
  },
  {
    "uniprot": "Q4DZQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZQ1"
  },
  {
    "uniprot": "Q4DJY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJY1"
  },
  {
    "uniprot": "Q4CMS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMS1"
  },
  {
    "uniprot": "Q4DJE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJE7"
  },
  {
    "uniprot": "Q4E3D3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3D3"
  },
  {
    "uniprot": "Q4CTH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTH9"
  },
  {
    "uniprot": "Q4D0M7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0M7"
  },
  {
    "uniprot": "Q4E1J7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1J7"
  },
  {
    "uniprot": "Q4DJI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJI6"
  },
  {
    "uniprot": "Q4CKW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKW5"
  },
  {
    "uniprot": "Q4DEK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEK0"
  },
  {
    "uniprot": "Q4E292",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E292"
  },
  {
    "uniprot": "Q4DHI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHI6"
  },
  {
    "uniprot": "Q4DXC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXC0"
  },
  {
    "uniprot": "Q4E0J1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0J1"
  },
  {
    "uniprot": "Q4E4S4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4S4"
  },
  {
    "uniprot": "Q4CYP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYP5"
  },
  {
    "uniprot": "Q4D4L8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4L8"
  },
  {
    "uniprot": "Q4D0G5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0G5"
  },
  {
    "uniprot": "Q4DG05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG05"
  },
  {
    "uniprot": "Q4E4W1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4W1"
  },
  {
    "uniprot": "Q4DIY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIY6"
  },
  {
    "uniprot": "Q4DUB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUB8"
  },
  {
    "uniprot": "Q4DWJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWJ7"
  },
  {
    "uniprot": "Q4DLG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLG8"
  },
  {
    "uniprot": "Q4DYP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYP1"
  },
  {
    "uniprot": "Q4CKS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKS3"
  },
  {
    "uniprot": "Q4E1G1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1G1"
  },
  {
    "uniprot": "Q4DQZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQZ8"
  },
  {
    "uniprot": "Q4DW68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW68"
  },
  {
    "uniprot": "Q4DFU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFU3"
  },
  {
    "uniprot": "Q4DKS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKS2"
  },
  {
    "uniprot": "Q4D4X1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4X1"
  },
  {
    "uniprot": "Q4CQW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQW7"
  },
  {
    "uniprot": "Q4D247",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D247"
  },
  {
    "uniprot": "Q4D9H9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9H9"
  },
  {
    "uniprot": "Q4CKA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKA1"
  },
  {
    "uniprot": "Q4DWE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWE8"
  },
  {
    "uniprot": "Q4D0C0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0C0"
  },
  {
    "uniprot": "Q4DGW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGW9"
  },
  {
    "uniprot": "Q4DPC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPC3"
  },
  {
    "uniprot": "Q4DGC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGC4"
  },
  {
    "uniprot": "Q4E4L1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4L1"
  },
  {
    "uniprot": "Q4CT47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT47"
  },
  {
    "uniprot": "Q4D317",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D317"
  },
  {
    "uniprot": "Q4D9C0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9C0"
  },
  {
    "uniprot": "Q4CMD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMD6"
  },
  {
    "uniprot": "Q4D0M2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0M2"
  },
  {
    "uniprot": "Q4CRE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRE4"
  },
  {
    "uniprot": "Q4CSI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSI3"
  },
  {
    "uniprot": "Q4CL83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL83"
  },
  {
    "uniprot": "Q4DUM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUM2"
  },
  {
    "uniprot": "Q4D5E2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5E2"
  },
  {
    "uniprot": "Q4D5R5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5R5"
  },
  {
    "uniprot": "Q4DKC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKC9"
  },
  {
    "uniprot": "Q4DIN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIN1"
  },
  {
    "uniprot": "Q4DHX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHX4"
  },
  {
    "uniprot": "Q4CYC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYC1"
  },
  {
    "uniprot": "Q4DQB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQB2"
  },
  {
    "uniprot": "Q4DUN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUN5"
  },
  {
    "uniprot": "Q4DX89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX89"
  },
  {
    "uniprot": "Q4D644",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D644"
  },
  {
    "uniprot": "Q4CKS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKS4"
  },
  {
    "uniprot": "Q4DWT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWT4"
  },
  {
    "uniprot": "Q4D206",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D206"
  },
  {
    "uniprot": "Q4DF65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF65"
  },
  {
    "uniprot": "Q4D0X3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0X3"
  },
  {
    "uniprot": "Q4CXP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXP4"
  },
  {
    "uniprot": "Q4CUX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUX5"
  },
  {
    "uniprot": "Q4DYI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYI3"
  },
  {
    "uniprot": "Q4CS27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS27"
  },
  {
    "uniprot": "Q4DME7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DME7"
  },
  {
    "uniprot": "Q4DJG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJG5"
  },
  {
    "uniprot": "Q4D915",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D915"
  },
  {
    "uniprot": "Q4CPM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPM9"
  },
  {
    "uniprot": "Q4CUS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUS0"
  },
  {
    "uniprot": "Q4DNP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNP7"
  },
  {
    "uniprot": "Q4E5W0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5W0"
  },
  {
    "uniprot": "Q4DSU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSU7"
  },
  {
    "uniprot": "Q4D0Z1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Z1"
  },
  {
    "uniprot": "Q4D3G5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3G5"
  },
  {
    "uniprot": "Q4CNM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNM1"
  },
  {
    "uniprot": "Q4DDV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDV9"
  },
  {
    "uniprot": "Q4DEW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEW6"
  },
  {
    "uniprot": "Q4DHB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHB1"
  },
  {
    "uniprot": "Q4D938",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D938"
  },
  {
    "uniprot": "Q4CMY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMY3"
  },
  {
    "uniprot": "Q4DED1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DED1"
  },
  {
    "uniprot": "Q4DY96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY96"
  },
  {
    "uniprot": "Q4CRV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRV9"
  },
  {
    "uniprot": "Q4DJZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJZ7"
  },
  {
    "uniprot": "Q4CYR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYR6"
  },
  {
    "uniprot": "Q4DSF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSF7"
  },
  {
    "uniprot": "Q4CS97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS97"
  },
  {
    "uniprot": "Q4DM38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM38"
  },
  {
    "uniprot": "Q4DU96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU96"
  },
  {
    "uniprot": "Q4D7N1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7N1"
  },
  {
    "uniprot": "Q4DXM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXM1"
  },
  {
    "uniprot": "Q4DWD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWD2"
  },
  {
    "uniprot": "Q4D742",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D742"
  },
  {
    "uniprot": "Q4DRN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRN3"
  },
  {
    "uniprot": "Q4DPB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPB4"
  },
  {
    "uniprot": "Q4D9U0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9U0"
  },
  {
    "uniprot": "Q4CXZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXZ6"
  },
  {
    "uniprot": "Q4D7L0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7L0"
  },
  {
    "uniprot": "Q4D3H0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3H0"
  },
  {
    "uniprot": "Q4CLV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLV1"
  },
  {
    "uniprot": "Q4DF89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF89"
  },
  {
    "uniprot": "Q4D5Q7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Q7"
  },
  {
    "uniprot": "Q4DFZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFZ4"
  },
  {
    "uniprot": "Q4CPG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPG6"
  },
  {
    "uniprot": "Q4E443",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E443"
  },
  {
    "uniprot": "Q4D0D2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0D2"
  },
  {
    "uniprot": "Q4D077",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D077"
  },
  {
    "uniprot": "Q4DJJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJJ0"
  },
  {
    "uniprot": "Q4DX87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX87"
  },
  {
    "uniprot": "Q4DFX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFX9"
  },
  {
    "uniprot": "Q4E174",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E174"
  },
  {
    "uniprot": "Q4E377",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E377"
  },
  {
    "uniprot": "Q4D9D6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9D6"
  },
  {
    "uniprot": "Q4DXJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXJ5"
  },
  {
    "uniprot": "Q4D8K6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8K6"
  },
  {
    "uniprot": "Q4CMQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMQ9"
  },
  {
    "uniprot": "Q4DVT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVT2"
  },
  {
    "uniprot": "Q4D9M4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9M4"
  },
  {
    "uniprot": "Q4DI22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI22"
  },
  {
    "uniprot": "Q4DMK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMK7"
  },
  {
    "uniprot": "Q4DR00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR00"
  },
  {
    "uniprot": "Q4CNI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNI6"
  },
  {
    "uniprot": "Q4D659",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D659"
  },
  {
    "uniprot": "Q4CUU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUU8"
  },
  {
    "uniprot": "Q4DVV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVV1"
  },
  {
    "uniprot": "Q4DEK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEK6"
  },
  {
    "uniprot": "Q4DQD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQD7"
  },
  {
    "uniprot": "Q4CVV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVV5"
  },
  {
    "uniprot": "Q4CQ88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ88"
  },
  {
    "uniprot": "Q4DQG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQG5"
  },
  {
    "uniprot": "Q4CVX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVX3"
  },
  {
    "uniprot": "Q4DCF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCF2"
  },
  {
    "uniprot": "Q4D408",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D408"
  },
  {
    "uniprot": "Q4DMS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMS7"
  },
  {
    "uniprot": "Q4CST4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CST4"
  },
  {
    "uniprot": "Q4DGC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGC0"
  },
  {
    "uniprot": "Q4DX40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX40"
  },
  {
    "uniprot": "Q4DQF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQF4"
  },
  {
    "uniprot": "Q4CML7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CML7"
  },
  {
    "uniprot": "Q4DAG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAG6"
  },
  {
    "uniprot": "Q4DR87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR87"
  },
  {
    "uniprot": "Q4DNV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNV5"
  },
  {
    "uniprot": "Q4CN69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN69"
  },
  {
    "uniprot": "Q4DXD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXD5"
  },
  {
    "uniprot": "Q4D2J1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2J1"
  },
  {
    "uniprot": "Q4CND8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CND8"
  },
  {
    "uniprot": "Q4CLF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLF6"
  },
  {
    "uniprot": "Q4D4W4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4W4"
  },
  {
    "uniprot": "Q4DIN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIN9"
  },
  {
    "uniprot": "Q4D2J6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2J6"
  },
  {
    "uniprot": "Q4DX09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX09"
  },
  {
    "uniprot": "Q4CVE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVE2"
  },
  {
    "uniprot": "Q4D299",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D299"
  },
  {
    "uniprot": "Q4DIP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIP6"
  },
  {
    "uniprot": "Q4CRQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRQ6"
  },
  {
    "uniprot": "Q4DE66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE66"
  },
  {
    "uniprot": "Q4DWY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWY0"
  },
  {
    "uniprot": "Q4D1A2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1A2"
  },
  {
    "uniprot": "Q4CV83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV83"
  },
  {
    "uniprot": "Q4CVZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVZ6"
  },
  {
    "uniprot": "Q4DB53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB53"
  },
  {
    "uniprot": "Q4DD94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD94"
  },
  {
    "uniprot": "Q4CU04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU04"
  },
  {
    "uniprot": "Q4CU91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU91"
  },
  {
    "uniprot": "Q4CPC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPC3"
  },
  {
    "uniprot": "Q4CP69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP69"
  },
  {
    "uniprot": "Q4DG70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG70"
  },
  {
    "uniprot": "Q4DTF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTF9"
  },
  {
    "uniprot": "Q4DKY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKY8"
  },
  {
    "uniprot": "Q4D057",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D057"
  },
  {
    "uniprot": "Q4DLQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLQ3"
  },
  {
    "uniprot": "Q4DBG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBG1"
  },
  {
    "uniprot": "Q4CSL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSL5"
  },
  {
    "uniprot": "Q4CPS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPS0"
  },
  {
    "uniprot": "Q4DEQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEQ8"
  },
  {
    "uniprot": "Q4CT65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT65"
  },
  {
    "uniprot": "Q4DS12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS12"
  },
  {
    "uniprot": "Q4CP26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP26"
  },
  {
    "uniprot": "Q4CKY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKY7"
  },
  {
    "uniprot": "Q4DPM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPM6"
  },
  {
    "uniprot": "Q4D2M8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2M8"
  },
  {
    "uniprot": "Q4CYL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYL1"
  },
  {
    "uniprot": "Q4E335",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E335"
  },
  {
    "uniprot": "Q4D230",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D230"
  },
  {
    "uniprot": "Q4CQY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQY2"
  },
  {
    "uniprot": "Q4E4T0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4T0"
  },
  {
    "uniprot": "Q4DBU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBU9"
  },
  {
    "uniprot": "Q4D2T1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2T1"
  },
  {
    "uniprot": "Q4DM87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM87"
  },
  {
    "uniprot": "Q4DI55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI55"
  },
  {
    "uniprot": "Q4D881",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D881"
  },
  {
    "uniprot": "Q4CU64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU64"
  },
  {
    "uniprot": "Q4DZ71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ71"
  },
  {
    "uniprot": "Q4D2D1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2D1"
  },
  {
    "uniprot": "Q4DLH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLH9"
  },
  {
    "uniprot": "Q4CYG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYG9"
  },
  {
    "uniprot": "Q4DIX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIX6"
  },
  {
    "uniprot": "Q4DHB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHB9"
  },
  {
    "uniprot": "Q4DRV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRV8"
  },
  {
    "uniprot": "Q4CYN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYN6"
  },
  {
    "uniprot": "Q4DIR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIR6"
  },
  {
    "uniprot": "Q4CW24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW24"
  },
  {
    "uniprot": "Q4DAJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAJ1"
  },
  {
    "uniprot": "Q4E303",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E303"
  },
  {
    "uniprot": "Q4E4E1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4E1"
  },
  {
    "uniprot": "Q4DDG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDG6"
  },
  {
    "uniprot": "Q4CU80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU80"
  },
  {
    "uniprot": "Q4CM95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM95"
  },
  {
    "uniprot": "Q4DZ48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ48"
  },
  {
    "uniprot": "Q4E2N4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2N4"
  },
  {
    "uniprot": "Q4DN76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN76"
  },
  {
    "uniprot": "Q4DCS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCS6"
  },
  {
    "uniprot": "Q4DW90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW90"
  },
  {
    "uniprot": "Q4CU06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU06"
  },
  {
    "uniprot": "Q4CZ14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ14"
  },
  {
    "uniprot": "Q4DXW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXW0"
  },
  {
    "uniprot": "Q4CZ06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ06"
  },
  {
    "uniprot": "Q4DLD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLD2"
  },
  {
    "uniprot": "Q4D4Q3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Q3"
  },
  {
    "uniprot": "Q4CKQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKQ4"
  },
  {
    "uniprot": "Q4D4C2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4C2"
  },
  {
    "uniprot": "Q4CKH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKH1"
  },
  {
    "uniprot": "Q4D9S4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9S4"
  },
  {
    "uniprot": "Q4DIW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIW0"
  },
  {
    "uniprot": "Q4CZP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZP9"
  },
  {
    "uniprot": "Q4D6X7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6X7"
  },
  {
    "uniprot": "Q4DGA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGA3"
  },
  {
    "uniprot": "Q4DWH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWH9"
  },
  {
    "uniprot": "Q4DLY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLY1"
  },
  {
    "uniprot": "Q4DIG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIG6"
  },
  {
    "uniprot": "Q4DFL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFL6"
  },
  {
    "uniprot": "Q4D847",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D847"
  },
  {
    "uniprot": "Q4DLE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLE2"
  },
  {
    "uniprot": "Q4DR27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR27"
  },
  {
    "uniprot": "Q4CX33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX33"
  },
  {
    "uniprot": "Q4CWM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWM2"
  },
  {
    "uniprot": "Q4E5B9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5B9"
  },
  {
    "uniprot": "Q4DD56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD56"
  },
  {
    "uniprot": "Q4DFF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFF1"
  },
  {
    "uniprot": "Q4DWU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWU1"
  },
  {
    "uniprot": "Q4DV47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV47"
  },
  {
    "uniprot": "Q4D016",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D016"
  },
  {
    "uniprot": "Q4E5X7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5X7"
  },
  {
    "uniprot": "Q4DED7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DED7"
  },
  {
    "uniprot": "Q4CRB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRB8"
  },
  {
    "uniprot": "Q4D528",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D528"
  },
  {
    "uniprot": "Q4DUH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUH6"
  },
  {
    "uniprot": "Q4CLC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLC7"
  },
  {
    "uniprot": "Q4CYT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYT9"
  },
  {
    "uniprot": "Q4D0N5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0N5"
  },
  {
    "uniprot": "Q4E4I4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4I4"
  },
  {
    "uniprot": "Q4CZ99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ99"
  },
  {
    "uniprot": "Q4CY43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY43"
  },
  {
    "uniprot": "Q4CMB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMB0"
  },
  {
    "uniprot": "Q4CTK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTK1"
  },
  {
    "uniprot": "Q4CT42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT42"
  },
  {
    "uniprot": "Q4DTH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTH3"
  },
  {
    "uniprot": "Q4CR84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR84"
  },
  {
    "uniprot": "Q4D688",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D688"
  },
  {
    "uniprot": "Q4D6T1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6T1"
  },
  {
    "uniprot": "Q4CQB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQB7"
  },
  {
    "uniprot": "Q4DNA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNA4"
  },
  {
    "uniprot": "Q4DR60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR60"
  },
  {
    "uniprot": "Q4CLP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLP4"
  },
  {
    "uniprot": "Q4DDP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDP7"
  },
  {
    "uniprot": "Q4DG54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG54"
  },
  {
    "uniprot": "Q4CW98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW98"
  },
  {
    "uniprot": "Q4DB88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB88"
  },
  {
    "uniprot": "Q4DEU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEU6"
  },
  {
    "uniprot": "Q4D045",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D045"
  },
  {
    "uniprot": "Q4DPK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPK2"
  },
  {
    "uniprot": "Q4DID4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DID4"
  },
  {
    "uniprot": "Q4DLB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLB8"
  },
  {
    "uniprot": "Q4DNQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNQ9"
  },
  {
    "uniprot": "Q4DU15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU15"
  },
  {
    "uniprot": "Q4D6N9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6N9"
  },
  {
    "uniprot": "Q4DF90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF90"
  },
  {
    "uniprot": "Q4DFU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFU2"
  },
  {
    "uniprot": "Q4D7V6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7V6"
  },
  {
    "uniprot": "Q4D792",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D792"
  },
  {
    "uniprot": "Q4DNC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNC8"
  },
  {
    "uniprot": "Q4DG55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG55"
  },
  {
    "uniprot": "Q4DZ76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ76"
  },
  {
    "uniprot": "Q4DYN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYN2"
  },
  {
    "uniprot": "Q4DJ56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ56"
  },
  {
    "uniprot": "Q4DKI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKI4"
  },
  {
    "uniprot": "Q4DCV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCV6"
  },
  {
    "uniprot": "Q4DN23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN23"
  },
  {
    "uniprot": "Q4DZV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZV7"
  },
  {
    "uniprot": "Q4D6K3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6K3"
  },
  {
    "uniprot": "Q4DLD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLD3"
  },
  {
    "uniprot": "Q4D4B9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4B9"
  },
  {
    "uniprot": "Q4DBU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBU5"
  },
  {
    "uniprot": "Q4D8I6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8I6"
  },
  {
    "uniprot": "Q4DG32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG32"
  },
  {
    "uniprot": "Q4D6T8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6T8"
  },
  {
    "uniprot": "Q4E367",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E367"
  },
  {
    "uniprot": "Q4DZP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZP0"
  },
  {
    "uniprot": "Q4D0P0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0P0"
  },
  {
    "uniprot": "Q4D3T4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3T4"
  },
  {
    "uniprot": "Q4CS99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS99"
  },
  {
    "uniprot": "Q4E5V5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5V5"
  },
  {
    "uniprot": "Q4E0M9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0M9"
  },
  {
    "uniprot": "Q4CRR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRR3"
  },
  {
    "uniprot": "Q4E503",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E503"
  },
  {
    "uniprot": "Q4DC98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC98"
  },
  {
    "uniprot": "Q4CYV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYV9"
  },
  {
    "uniprot": "Q4DSK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSK5"
  },
  {
    "uniprot": "Q4DWA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWA3"
  },
  {
    "uniprot": "Q4D0V1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0V1"
  },
  {
    "uniprot": "Q4DV51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV51"
  },
  {
    "uniprot": "Q4DPJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPJ5"
  },
  {
    "uniprot": "Q4DGN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGN6"
  },
  {
    "uniprot": "Q4DLA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLA4"
  },
  {
    "uniprot": "Q4DKE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKE9"
  },
  {
    "uniprot": "Q4D315",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D315"
  },
  {
    "uniprot": "Q4DE96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE96"
  },
  {
    "uniprot": "Q4E573",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E573"
  },
  {
    "uniprot": "Q4CMH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMH6"
  },
  {
    "uniprot": "Q4E5M5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5M5"
  },
  {
    "uniprot": "Q4D9S9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9S9"
  },
  {
    "uniprot": "Q4DBS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBS7"
  },
  {
    "uniprot": "Q4E2I2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2I2"
  },
  {
    "uniprot": "Q4DBX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBX7"
  },
  {
    "uniprot": "Q4DSR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSR5"
  },
  {
    "uniprot": "Q4DYG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYG8"
  },
  {
    "uniprot": "Q4DCU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCU5"
  },
  {
    "uniprot": "Q4D788",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D788"
  },
  {
    "uniprot": "Q4DCU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCU2"
  },
  {
    "uniprot": "Q4E2U9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2U9"
  },
  {
    "uniprot": "Q4E152",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E152"
  },
  {
    "uniprot": "Q4DXC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXC1"
  },
  {
    "uniprot": "Q4DNI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNI3"
  },
  {
    "uniprot": "Q4D7N5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7N5"
  },
  {
    "uniprot": "Q4CPC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPC1"
  },
  {
    "uniprot": "Q4D3B6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3B6"
  },
  {
    "uniprot": "Q4DE05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE05"
  },
  {
    "uniprot": "Q4E3Y7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Y7"
  },
  {
    "uniprot": "Q4DPE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPE7"
  },
  {
    "uniprot": "Q4DKJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKJ6"
  },
  {
    "uniprot": "Q4D467",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D467"
  },
  {
    "uniprot": "Q4DVV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVV5"
  },
  {
    "uniprot": "Q4D332",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D332"
  },
  {
    "uniprot": "Q4DVQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVQ8"
  },
  {
    "uniprot": "Q4D2Q6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Q6"
  },
  {
    "uniprot": "Q4D8Y2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Y2"
  },
  {
    "uniprot": "Q4E1Z8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Z8"
  },
  {
    "uniprot": "Q4DMS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMS0"
  },
  {
    "uniprot": "Q4CQH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQH0"
  },
  {
    "uniprot": "Q4CQV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQV5"
  },
  {
    "uniprot": "Q4CN59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN59"
  },
  {
    "uniprot": "Q4DV23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV23"
  },
  {
    "uniprot": "Q4DBZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBZ0"
  },
  {
    "uniprot": "Q4D2G0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2G0"
  },
  {
    "uniprot": "Q4D9C5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9C5"
  },
  {
    "uniprot": "Q4D2L3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2L3"
  },
  {
    "uniprot": "Q4DY61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY61"
  },
  {
    "uniprot": "Q4DAV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAV0"
  },
  {
    "uniprot": "Q4CUM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUM6"
  },
  {
    "uniprot": "Q4CXB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXB2"
  },
  {
    "uniprot": "Q4CLT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLT6"
  },
  {
    "uniprot": "Q4DPD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPD2"
  },
  {
    "uniprot": "Q4D7D6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7D6"
  },
  {
    "uniprot": "Q4DYT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYT7"
  },
  {
    "uniprot": "Q4CXT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXT7"
  },
  {
    "uniprot": "Q4D8R7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8R7"
  },
  {
    "uniprot": "Q4CRD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRD5"
  },
  {
    "uniprot": "Q4CPE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPE6"
  },
  {
    "uniprot": "Q4DS68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS68"
  },
  {
    "uniprot": "Q4DFI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFI0"
  },
  {
    "uniprot": "Q4E232",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E232"
  },
  {
    "uniprot": "Q4CNH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNH8"
  },
  {
    "uniprot": "Q4DE88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE88"
  },
  {
    "uniprot": "Q4D1Z3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Z3"
  },
  {
    "uniprot": "Q4CRY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRY3"
  },
  {
    "uniprot": "Q4DIE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIE2"
  },
  {
    "uniprot": "Q4CVB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVB9"
  },
  {
    "uniprot": "Q4DZW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZW9"
  },
  {
    "uniprot": "Q4DVB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVB8"
  },
  {
    "uniprot": "Q4DTX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTX4"
  },
  {
    "uniprot": "Q4E4B4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4B4"
  },
  {
    "uniprot": "Q4DI88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI88"
  },
  {
    "uniprot": "Q4CWC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWC7"
  },
  {
    "uniprot": "Q4CYF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYF2"
  },
  {
    "uniprot": "Q4DEL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEL9"
  },
  {
    "uniprot": "Q4D802",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D802"
  },
  {
    "uniprot": "Q4CW96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW96"
  },
  {
    "uniprot": "Q4DV14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV14"
  },
  {
    "uniprot": "Q4E314",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E314"
  },
  {
    "uniprot": "Q4D5C2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5C2"
  },
  {
    "uniprot": "Q4DL55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL55"
  },
  {
    "uniprot": "Q4DD26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD26"
  },
  {
    "uniprot": "Q4E2L5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2L5"
  },
  {
    "uniprot": "Q4DCJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCJ2"
  },
  {
    "uniprot": "Q4CXR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXR8"
  },
  {
    "uniprot": "Q4D990",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D990"
  },
  {
    "uniprot": "Q4E1L0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1L0"
  },
  {
    "uniprot": "Q4DBA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBA7"
  },
  {
    "uniprot": "Q4DPK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPK0"
  },
  {
    "uniprot": "Q4CP79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP79"
  },
  {
    "uniprot": "Q4D0G2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0G2"
  },
  {
    "uniprot": "Q4DGM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGM8"
  },
  {
    "uniprot": "Q4D169",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D169"
  },
  {
    "uniprot": "Q4DYS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYS2"
  },
  {
    "uniprot": "Q4E2Q2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Q2"
  },
  {
    "uniprot": "Q4DMN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMN0"
  },
  {
    "uniprot": "Q4DX55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX55"
  },
  {
    "uniprot": "Q4DHS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHS7"
  },
  {
    "uniprot": "Q4E0D2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0D2"
  },
  {
    "uniprot": "Q4E532",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E532"
  },
  {
    "uniprot": "Q4DAY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAY5"
  },
  {
    "uniprot": "Q4CU85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU85"
  },
  {
    "uniprot": "Q4CXF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXF1"
  },
  {
    "uniprot": "Q4DR12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR12"
  },
  {
    "uniprot": "Q4DEW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEW9"
  },
  {
    "uniprot": "Q4DMF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMF3"
  },
  {
    "uniprot": "Q4CUS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUS1"
  },
  {
    "uniprot": "Q4CWJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWJ3"
  },
  {
    "uniprot": "Q4DHQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHQ5"
  },
  {
    "uniprot": "Q4CQU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQU3"
  },
  {
    "uniprot": "Q4DUS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUS7"
  },
  {
    "uniprot": "Q4DBM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBM3"
  },
  {
    "uniprot": "Q4DJ99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ99"
  },
  {
    "uniprot": "Q4DS57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS57"
  },
  {
    "uniprot": "Q4D860",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D860"
  },
  {
    "uniprot": "Q4CY17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY17"
  },
  {
    "uniprot": "Q4DP35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP35"
  },
  {
    "uniprot": "Q4E024",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E024"
  },
  {
    "uniprot": "Q4CL40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL40"
  },
  {
    "uniprot": "Q4CUV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUV2"
  },
  {
    "uniprot": "Q4DHF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHF3"
  },
  {
    "uniprot": "Q4E086",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E086"
  },
  {
    "uniprot": "Q4DMZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMZ2"
  },
  {
    "uniprot": "Q4E0I0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0I0"
  },
  {
    "uniprot": "Q4CMZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMZ2"
  },
  {
    "uniprot": "Q4DG15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG15"
  },
  {
    "uniprot": "Q4E2T0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2T0"
  },
  {
    "uniprot": "Q4DVI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVI8"
  },
  {
    "uniprot": "Q4DYC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYC5"
  },
  {
    "uniprot": "Q4DWT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWT9"
  },
  {
    "uniprot": "Q4DLB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLB4"
  },
  {
    "uniprot": "Q4CZL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZL3"
  },
  {
    "uniprot": "Q4CM20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM20"
  },
  {
    "uniprot": "Q4CSS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSS1"
  },
  {
    "uniprot": "Q4CSZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSZ6"
  },
  {
    "uniprot": "Q4DBJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBJ0"
  },
  {
    "uniprot": "Q4DUJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUJ8"
  },
  {
    "uniprot": "Q4DZP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZP5"
  },
  {
    "uniprot": "Q4E533",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E533"
  },
  {
    "uniprot": "Q4CVC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVC4"
  },
  {
    "uniprot": "Q4CYN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYN8"
  },
  {
    "uniprot": "Q4D5X0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5X0"
  },
  {
    "uniprot": "Q4CZF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZF1"
  },
  {
    "uniprot": "Q4CXZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXZ5"
  },
  {
    "uniprot": "Q4DIX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIX9"
  },
  {
    "uniprot": "Q4CPR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPR9"
  },
  {
    "uniprot": "Q4CTV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTV1"
  },
  {
    "uniprot": "Q4CT62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT62"
  },
  {
    "uniprot": "Q4D100",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D100"
  },
  {
    "uniprot": "Q4DSR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSR9"
  },
  {
    "uniprot": "Q4DT30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT30"
  },
  {
    "uniprot": "Q4DPI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPI2"
  },
  {
    "uniprot": "Q4CP94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP94"
  },
  {
    "uniprot": "Q4DUA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUA3"
  },
  {
    "uniprot": "Q4DV82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV82"
  },
  {
    "uniprot": "Q4CLA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLA5"
  },
  {
    "uniprot": "Q4DCG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCG4"
  },
  {
    "uniprot": "Q4DRA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRA5"
  },
  {
    "uniprot": "Q4CMN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMN8"
  },
  {
    "uniprot": "Q4DLJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLJ0"
  },
  {
    "uniprot": "Q4CW64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW64"
  },
  {
    "uniprot": "Q4D027",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D027"
  },
  {
    "uniprot": "Q4DZQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZQ2"
  },
  {
    "uniprot": "Q4CN78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN78"
  },
  {
    "uniprot": "Q4CND3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CND3"
  },
  {
    "uniprot": "Q4E3V6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3V6"
  },
  {
    "uniprot": "Q4DD74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD74"
  },
  {
    "uniprot": "Q4E4M7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4M7"
  },
  {
    "uniprot": "Q4D3H9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3H9"
  },
  {
    "uniprot": "Q4DF18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF18"
  },
  {
    "uniprot": "Q4DBE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBE7"
  },
  {
    "uniprot": "Q4CUB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUB5"
  },
  {
    "uniprot": "Q4DRZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRZ7"
  },
  {
    "uniprot": "Q4CP50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP50"
  },
  {
    "uniprot": "Q4DAV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAV7"
  },
  {
    "uniprot": "Q4E1L1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1L1"
  },
  {
    "uniprot": "Q4DMU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMU7"
  },
  {
    "uniprot": "Q4CLD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLD2"
  },
  {
    "uniprot": "Q4DDG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDG9"
  },
  {
    "uniprot": "Q4CX13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX13"
  },
  {
    "uniprot": "Q4E482",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E482"
  },
  {
    "uniprot": "Q4D140",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D140"
  },
  {
    "uniprot": "Q4CZI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZI6"
  },
  {
    "uniprot": "Q4D0M1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0M1"
  },
  {
    "uniprot": "Q4DIY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIY1"
  },
  {
    "uniprot": "Q4DDB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDB6"
  },
  {
    "uniprot": "Q4CPZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPZ2"
  },
  {
    "uniprot": "Q4DDK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDK6"
  },
  {
    "uniprot": "Q4DNY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNY5"
  },
  {
    "uniprot": "Q4CQR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQR5"
  },
  {
    "uniprot": "Q4DUZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUZ4"
  },
  {
    "uniprot": "Q4CNH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNH7"
  },
  {
    "uniprot": "Q4DJP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJP6"
  },
  {
    "uniprot": "Q4CT75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT75"
  },
  {
    "uniprot": "Q4DKM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKM3"
  },
  {
    "uniprot": "Q4DX80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX80"
  },
  {
    "uniprot": "Q4D5K0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5K0"
  },
  {
    "uniprot": "Q4DY34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY34"
  },
  {
    "uniprot": "Q4CPJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPJ7"
  },
  {
    "uniprot": "Q4D8X7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8X7"
  },
  {
    "uniprot": "Q4CLY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLY7"
  },
  {
    "uniprot": "Q4DEF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEF1"
  },
  {
    "uniprot": "Q4E3S3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3S3"
  },
  {
    "uniprot": "Q4D7Q5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Q5"
  },
  {
    "uniprot": "Q4DX18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX18"
  },
  {
    "uniprot": "Q4DHY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHY8"
  },
  {
    "uniprot": "Q4DAW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAW0"
  },
  {
    "uniprot": "Q4DJT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJT4"
  },
  {
    "uniprot": "Q4D184",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D184"
  },
  {
    "uniprot": "Q4E2M0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2M0"
  },
  {
    "uniprot": "Q4DJV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJV1"
  },
  {
    "uniprot": "Q4CUI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUI3"
  },
  {
    "uniprot": "Q4CYL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYL7"
  },
  {
    "uniprot": "Q4DYE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYE6"
  },
  {
    "uniprot": "Q4D4F4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4F4"
  },
  {
    "uniprot": "Q4CNY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNY6"
  },
  {
    "uniprot": "Q4E5N6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5N6"
  },
  {
    "uniprot": "Q4E431",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E431"
  },
  {
    "uniprot": "Q4CLE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLE3"
  },
  {
    "uniprot": "Q4DV31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV31"
  },
  {
    "uniprot": "Q4DYS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYS3"
  },
  {
    "uniprot": "Q4DYX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYX5"
  },
  {
    "uniprot": "Q4CXG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXG6"
  },
  {
    "uniprot": "Q4DR03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR03"
  },
  {
    "uniprot": "Q4CLI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLI4"
  },
  {
    "uniprot": "Q4DH40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH40"
  },
  {
    "uniprot": "Q4DRL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRL4"
  },
  {
    "uniprot": "Q4D4Q7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Q7"
  },
  {
    "uniprot": "Q4DSR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSR7"
  },
  {
    "uniprot": "Q4E5H8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5H8"
  },
  {
    "uniprot": "Q4D5H5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5H5"
  },
  {
    "uniprot": "Q4CNQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNQ5"
  },
  {
    "uniprot": "Q4E4M2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4M2"
  },
  {
    "uniprot": "Q4CMP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMP7"
  },
  {
    "uniprot": "Q4D6Y4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Y4"
  },
  {
    "uniprot": "Q4D2W2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2W2"
  },
  {
    "uniprot": "Q4DQI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQI6"
  },
  {
    "uniprot": "Q4E3H0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3H0"
  },
  {
    "uniprot": "Q4D0J3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0J3"
  },
  {
    "uniprot": "Q4CP13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP13"
  },
  {
    "uniprot": "Q4DHY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHY0"
  },
  {
    "uniprot": "Q4DTI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTI5"
  },
  {
    "uniprot": "Q4CMG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMG2"
  },
  {
    "uniprot": "Q4DAG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAG5"
  },
  {
    "uniprot": "Q4DW40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW40"
  },
  {
    "uniprot": "Q4DC55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC55"
  },
  {
    "uniprot": "Q4D4J1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4J1"
  },
  {
    "uniprot": "Q4CUT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUT6"
  },
  {
    "uniprot": "Q4CRX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRX3"
  },
  {
    "uniprot": "Q4D5E6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5E6"
  },
  {
    "uniprot": "Q4E0M5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0M5"
  },
  {
    "uniprot": "Q4CTS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTS4"
  },
  {
    "uniprot": "Q4DC31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC31"
  },
  {
    "uniprot": "Q4DRG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRG7"
  },
  {
    "uniprot": "Q4D0Z2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Z2"
  },
  {
    "uniprot": "Q4CLX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLX3"
  },
  {
    "uniprot": "Q4E1G8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1G8"
  },
  {
    "uniprot": "Q4DAZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAZ5"
  },
  {
    "uniprot": "Q4DAU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAU5"
  },
  {
    "uniprot": "Q4DR24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR24"
  },
  {
    "uniprot": "Q4DXQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXQ6"
  },
  {
    "uniprot": "Q4D551",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D551"
  },
  {
    "uniprot": "Q4CVS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVS7"
  },
  {
    "uniprot": "Q4CZ66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ66"
  },
  {
    "uniprot": "Q4CLN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLN1"
  },
  {
    "uniprot": "Q4CVB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVB2"
  },
  {
    "uniprot": "Q4DHT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHT3"
  },
  {
    "uniprot": "Q4CTF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTF5"
  },
  {
    "uniprot": "Q4DKH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKH0"
  },
  {
    "uniprot": "Q4CYA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYA0"
  },
  {
    "uniprot": "Q4CKU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKU7"
  },
  {
    "uniprot": "Q4CMP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMP8"
  },
  {
    "uniprot": "Q4E1Y6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Y6"
  },
  {
    "uniprot": "Q4D7J8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7J8"
  },
  {
    "uniprot": "Q4DIE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIE4"
  },
  {
    "uniprot": "Q4DWY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWY6"
  },
  {
    "uniprot": "Q4CQ49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ49"
  },
  {
    "uniprot": "Q4CRR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRR4"
  },
  {
    "uniprot": "Q4DQ55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ55"
  },
  {
    "uniprot": "Q4DGZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGZ0"
  },
  {
    "uniprot": "Q4DEU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEU7"
  },
  {
    "uniprot": "Q4DJ11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ11"
  },
  {
    "uniprot": "Q4DEK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEK2"
  },
  {
    "uniprot": "Q4DX07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX07"
  },
  {
    "uniprot": "Q4CQE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQE0"
  },
  {
    "uniprot": "Q4D2M1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2M1"
  },
  {
    "uniprot": "Q4DFT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFT3"
  },
  {
    "uniprot": "Q4DJI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJI5"
  },
  {
    "uniprot": "Q4E4W8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4W8"
  },
  {
    "uniprot": "Q4E574",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E574"
  },
  {
    "uniprot": "Q4CRB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRB3"
  },
  {
    "uniprot": "Q4DYP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYP0"
  },
  {
    "uniprot": "Q4CZS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZS4"
  },
  {
    "uniprot": "Q4DVZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVZ6"
  },
  {
    "uniprot": "Q4D425",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D425"
  },
  {
    "uniprot": "Q4E0H2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0H2"
  },
  {
    "uniprot": "Q4DWS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWS5"
  },
  {
    "uniprot": "Q4CWN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWN2"
  },
  {
    "uniprot": "Q4DVS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVS1"
  },
  {
    "uniprot": "Q4DHD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHD9"
  },
  {
    "uniprot": "Q4DHG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHG4"
  },
  {
    "uniprot": "Q4DWA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWA5"
  },
  {
    "uniprot": "Q4CL07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL07"
  },
  {
    "uniprot": "Q4DH69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH69"
  },
  {
    "uniprot": "Q4CUV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUV3"
  },
  {
    "uniprot": "Q4E511",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E511"
  },
  {
    "uniprot": "Q4E2H6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2H6"
  },
  {
    "uniprot": "Q4D6R1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6R1"
  },
  {
    "uniprot": "Q4DCS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCS7"
  },
  {
    "uniprot": "Q4DWW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWW8"
  },
  {
    "uniprot": "Q4DRV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRV9"
  },
  {
    "uniprot": "Q4DR97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR97"
  },
  {
    "uniprot": "Q4DIR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIR5"
  },
  {
    "uniprot": "Q4DSD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSD9"
  },
  {
    "uniprot": "Q4D7M0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7M0"
  },
  {
    "uniprot": "Q4D2R5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2R5"
  },
  {
    "uniprot": "Q4DUT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUT6"
  },
  {
    "uniprot": "Q4DMV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMV9"
  },
  {
    "uniprot": "Q4DNX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNX1"
  },
  {
    "uniprot": "Q4DL87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL87"
  },
  {
    "uniprot": "Q4CWS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWS7"
  },
  {
    "uniprot": "Q4CLA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLA2"
  },
  {
    "uniprot": "Q4CKF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKF2"
  },
  {
    "uniprot": "Q4CZK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZK7"
  },
  {
    "uniprot": "Q4CLG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLG2"
  },
  {
    "uniprot": "Q4DSB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSB5"
  },
  {
    "uniprot": "Q4CS30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS30"
  },
  {
    "uniprot": "Q4DK50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK50"
  },
  {
    "uniprot": "Q4CKU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKU8"
  },
  {
    "uniprot": "Q4CME5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CME5"
  },
  {
    "uniprot": "Q4E0N1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0N1"
  },
  {
    "uniprot": "Q4CTI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTI0"
  },
  {
    "uniprot": "Q4DM07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM07"
  },
  {
    "uniprot": "Q4D834",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D834"
  },
  {
    "uniprot": "Q4DA35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA35"
  },
  {
    "uniprot": "Q4CNI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNI2"
  },
  {
    "uniprot": "Q4CNG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNG1"
  },
  {
    "uniprot": "Q4E544",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E544"
  },
  {
    "uniprot": "Q4DCJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCJ8"
  },
  {
    "uniprot": "Q4D4K5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4K5"
  },
  {
    "uniprot": "Q4CSP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSP4"
  },
  {
    "uniprot": "Q4CPT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPT8"
  },
  {
    "uniprot": "Q4CQ15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ15"
  },
  {
    "uniprot": "Q4CN86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN86"
  },
  {
    "uniprot": "Q4DE58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE58"
  },
  {
    "uniprot": "Q4DHN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHN0"
  },
  {
    "uniprot": "Q4DMA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMA1"
  },
  {
    "uniprot": "Q4DC11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC11"
  },
  {
    "uniprot": "Q4CWP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWP2"
  },
  {
    "uniprot": "Q4CQR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQR9"
  },
  {
    "uniprot": "Q4CQY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQY8"
  },
  {
    "uniprot": "Q4DND8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DND8"
  },
  {
    "uniprot": "Q4CWJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWJ5"
  },
  {
    "uniprot": "Q4DC93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC93"
  },
  {
    "uniprot": "Q4DG53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG53"
  },
  {
    "uniprot": "Q4DQ74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ74"
  },
  {
    "uniprot": "Q4DFT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFT0"
  },
  {
    "uniprot": "Q4CVR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVR8"
  },
  {
    "uniprot": "Q4DLL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLL2"
  },
  {
    "uniprot": "Q4CLD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLD5"
  },
  {
    "uniprot": "Q4D8T5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8T5"
  },
  {
    "uniprot": "Q4D6J5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6J5"
  },
  {
    "uniprot": "Q4CVW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVW9"
  },
  {
    "uniprot": "Q4DR19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR19"
  },
  {
    "uniprot": "Q4D6V6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6V6"
  },
  {
    "uniprot": "Q4E319",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E319"
  },
  {
    "uniprot": "Q4DI32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI32"
  },
  {
    "uniprot": "Q4DDR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDR5"
  },
  {
    "uniprot": "Q4CW95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW95"
  },
  {
    "uniprot": "Q4D7T8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7T8"
  },
  {
    "uniprot": "Q4DVL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVL6"
  },
  {
    "uniprot": "Q4CML6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CML6"
  },
  {
    "uniprot": "Q4DXH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXH4"
  },
  {
    "uniprot": "Q4CLT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLT3"
  },
  {
    "uniprot": "Q4DWD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWD3"
  },
  {
    "uniprot": "Q4CR62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR62"
  },
  {
    "uniprot": "Q4D420",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D420"
  },
  {
    "uniprot": "Q4CVM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVM6"
  },
  {
    "uniprot": "Q4DGP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGP3"
  },
  {
    "uniprot": "Q4DKP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKP6"
  },
  {
    "uniprot": "Q4DEP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEP0"
  },
  {
    "uniprot": "Q4CMP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMP9"
  },
  {
    "uniprot": "Q4DP34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP34"
  },
  {
    "uniprot": "Q4DZQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZQ7"
  },
  {
    "uniprot": "Q4D2U4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2U4"
  },
  {
    "uniprot": "Q4DIU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIU2"
  },
  {
    "uniprot": "Q4D7B1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7B1"
  },
  {
    "uniprot": "Q4DIU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIU0"
  },
  {
    "uniprot": "Q4D843",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D843"
  },
  {
    "uniprot": "Q4E3K3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3K3"
  },
  {
    "uniprot": "Q4DCT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCT9"
  },
  {
    "uniprot": "Q4CQD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQD4"
  },
  {
    "uniprot": "Q4DMT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMT6"
  },
  {
    "uniprot": "Q4CKW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKW4"
  },
  {
    "uniprot": "Q4CZY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZY7"
  },
  {
    "uniprot": "Q4D711",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D711"
  },
  {
    "uniprot": "Q4DSR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSR3"
  },
  {
    "uniprot": "Q4CST3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CST3"
  },
  {
    "uniprot": "Q4CTA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTA1"
  },
  {
    "uniprot": "Q4DGE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGE3"
  },
  {
    "uniprot": "Q4DKC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKC6"
  },
  {
    "uniprot": "Q4D499",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D499"
  },
  {
    "uniprot": "Q4DDT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDT9"
  },
  {
    "uniprot": "Q4DTU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTU6"
  },
  {
    "uniprot": "Q4DU17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU17"
  },
  {
    "uniprot": "Q4DGM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGM1"
  },
  {
    "uniprot": "Q4CNV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNV2"
  },
  {
    "uniprot": "Q4DGB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGB2"
  },
  {
    "uniprot": "Q4CUU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUU7"
  },
  {
    "uniprot": "Q4DP78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP78"
  },
  {
    "uniprot": "Q4DHS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHS2"
  },
  {
    "uniprot": "Q4CXH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXH0"
  },
  {
    "uniprot": "Q4D6P1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6P1"
  },
  {
    "uniprot": "Q4DGV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGV2"
  },
  {
    "uniprot": "Q4DV04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV04"
  },
  {
    "uniprot": "Q4DVK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVK2"
  },
  {
    "uniprot": "Q4E671",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E671"
  },
  {
    "uniprot": "Q4DKS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKS7"
  },
  {
    "uniprot": "Q4CQN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQN2"
  },
  {
    "uniprot": "Q4CN83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN83"
  },
  {
    "uniprot": "Q4DV08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV08"
  },
  {
    "uniprot": "Q4CUZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUZ2"
  },
  {
    "uniprot": "Q4D7F6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7F6"
  },
  {
    "uniprot": "Q4DEE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEE8"
  },
  {
    "uniprot": "Q4DXB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXB1"
  },
  {
    "uniprot": "Q4CUH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUH1"
  },
  {
    "uniprot": "Q4D6T3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6T3"
  },
  {
    "uniprot": "Q4DVV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVV4"
  },
  {
    "uniprot": "Q4D527",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D527"
  },
  {
    "uniprot": "Q4D1G8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1G8"
  },
  {
    "uniprot": "Q4DN37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN37"
  },
  {
    "uniprot": "Q4CQ21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ21"
  },
  {
    "uniprot": "Q4DXZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXZ7"
  },
  {
    "uniprot": "Q4DPM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPM8"
  },
  {
    "uniprot": "Q4DBN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBN4"
  },
  {
    "uniprot": "Q4CQL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQL5"
  },
  {
    "uniprot": "Q4DTC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTC1"
  },
  {
    "uniprot": "Q4CUB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUB6"
  },
  {
    "uniprot": "Q4DZ40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ40"
  },
  {
    "uniprot": "Q4DB32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB32"
  },
  {
    "uniprot": "Q4DBM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBM5"
  },
  {
    "uniprot": "Q4DAU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAU1"
  },
  {
    "uniprot": "Q4DN96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN96"
  },
  {
    "uniprot": "Q4CMX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMX1"
  },
  {
    "uniprot": "Q4CLM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLM9"
  },
  {
    "uniprot": "Q4D6I4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6I4"
  },
  {
    "uniprot": "Q4D508",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D508"
  },
  {
    "uniprot": "Q4D6I8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6I8"
  },
  {
    "uniprot": "Q4CY98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY98"
  },
  {
    "uniprot": "Q4CSC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSC5"
  },
  {
    "uniprot": "Q4CTF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTF0"
  },
  {
    "uniprot": "Q4CPE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPE7"
  },
  {
    "uniprot": "Q4DLU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLU0"
  },
  {
    "uniprot": "Q4D8Y8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Y8"
  },
  {
    "uniprot": "Q4CSA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSA6"
  },
  {
    "uniprot": "Q4DAT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAT0"
  },
  {
    "uniprot": "Q4CUT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUT3"
  },
  {
    "uniprot": "Q4DWR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWR3"
  },
  {
    "uniprot": "Q4DJ64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ64"
  },
  {
    "uniprot": "Q4CU88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU88"
  },
  {
    "uniprot": "Q4D9T2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9T2"
  },
  {
    "uniprot": "Q4D9R9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9R9"
  },
  {
    "uniprot": "Q4DEC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEC6"
  },
  {
    "uniprot": "Q4DU76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU76"
  },
  {
    "uniprot": "Q4D807",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D807"
  },
  {
    "uniprot": "Q4DMM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMM5"
  },
  {
    "uniprot": "Q4DKU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKU7"
  },
  {
    "uniprot": "Q4DW32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW32"
  },
  {
    "uniprot": "Q4CQN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQN3"
  },
  {
    "uniprot": "Q4DFV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFV6"
  },
  {
    "uniprot": "Q4E4X2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4X2"
  },
  {
    "uniprot": "Q4D193",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D193"
  },
  {
    "uniprot": "Q4DC86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC86"
  },
  {
    "uniprot": "Q4DZQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZQ0"
  },
  {
    "uniprot": "Q4DS91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS91"
  },
  {
    "uniprot": "Q4D5M7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5M7"
  },
  {
    "uniprot": "Q4DMB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMB2"
  },
  {
    "uniprot": "Q4DK45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK45"
  },
  {
    "uniprot": "Q4CWZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWZ7"
  },
  {
    "uniprot": "Q4DW69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW69"
  },
  {
    "uniprot": "Q4CYF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYF8"
  },
  {
    "uniprot": "Q4CNK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNK4"
  },
  {
    "uniprot": "Q4D900",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D900"
  },
  {
    "uniprot": "Q4DNB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNB2"
  },
  {
    "uniprot": "Q4D6T6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6T6"
  },
  {
    "uniprot": "Q4D0J9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0J9"
  },
  {
    "uniprot": "Q4DST6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DST6"
  },
  {
    "uniprot": "Q4CYA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYA3"
  },
  {
    "uniprot": "Q4DKR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKR4"
  },
  {
    "uniprot": "Q4CQZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQZ3"
  },
  {
    "uniprot": "Q4DV28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV28"
  },
  {
    "uniprot": "Q4CKJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKJ5"
  },
  {
    "uniprot": "Q4DMR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMR5"
  },
  {
    "uniprot": "Q4DRX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRX2"
  },
  {
    "uniprot": "Q4DB51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB51"
  },
  {
    "uniprot": "Q4DH78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH78"
  },
  {
    "uniprot": "Q4DWH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWH5"
  },
  {
    "uniprot": "Q4D0H6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0H6"
  },
  {
    "uniprot": "Q4CWE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWE4"
  },
  {
    "uniprot": "Q4D6L7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6L7"
  },
  {
    "uniprot": "Q4CV56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV56"
  },
  {
    "uniprot": "Q4CUY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUY6"
  },
  {
    "uniprot": "Q4D6R7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6R7"
  },
  {
    "uniprot": "Q4DM44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM44"
  },
  {
    "uniprot": "Q4CKK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKK4"
  },
  {
    "uniprot": "Q4DDL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDL1"
  },
  {
    "uniprot": "Q4DCE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCE0"
  },
  {
    "uniprot": "Q4CSQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSQ5"
  },
  {
    "uniprot": "Q4CSW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSW4"
  },
  {
    "uniprot": "Q4CLN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLN9"
  },
  {
    "uniprot": "Q4D023",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D023"
  },
  {
    "uniprot": "Q4CME6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CME6"
  },
  {
    "uniprot": "Q4CS10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS10"
  },
  {
    "uniprot": "Q4DT34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT34"
  },
  {
    "uniprot": "Q4DT16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT16"
  },
  {
    "uniprot": "Q4DI89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI89"
  },
  {
    "uniprot": "Q4D2J3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2J3"
  },
  {
    "uniprot": "Q4DW56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW56"
  },
  {
    "uniprot": "Q4E5M9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5M9"
  },
  {
    "uniprot": "Q4D4W0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4W0"
  },
  {
    "uniprot": "Q4CS85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS85"
  },
  {
    "uniprot": "Q4DE51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE51"
  },
  {
    "uniprot": "Q4DXC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXC5"
  },
  {
    "uniprot": "Q4CPB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPB1"
  },
  {
    "uniprot": "Q4CVF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVF6"
  },
  {
    "uniprot": "Q4CL61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL61"
  },
  {
    "uniprot": "Q4D9Z7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Z7"
  },
  {
    "uniprot": "Q4DKD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKD4"
  },
  {
    "uniprot": "Q4D849",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D849"
  },
  {
    "uniprot": "Q4DIY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIY9"
  },
  {
    "uniprot": "Q4DPK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPK5"
  },
  {
    "uniprot": "Q4DAQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAQ9"
  },
  {
    "uniprot": "Q4DCD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCD5"
  },
  {
    "uniprot": "Q4DN93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN93"
  },
  {
    "uniprot": "Q4CWA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWA3"
  },
  {
    "uniprot": "Q4CVF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVF4"
  },
  {
    "uniprot": "Q4DWC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWC1"
  },
  {
    "uniprot": "Q4DPH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPH5"
  },
  {
    "uniprot": "Q4DUR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUR1"
  },
  {
    "uniprot": "Q4E2B0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2B0"
  },
  {
    "uniprot": "Q4CXC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXC2"
  },
  {
    "uniprot": "Q4DXA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXA8"
  },
  {
    "uniprot": "Q4D6G5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6G5"
  },
  {
    "uniprot": "Q4DLW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLW0"
  },
  {
    "uniprot": "Q4DF13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF13"
  },
  {
    "uniprot": "Q4CS29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS29"
  },
  {
    "uniprot": "Q4DWL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWL0"
  },
  {
    "uniprot": "Q4E1C9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1C9"
  },
  {
    "uniprot": "Q4DK06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK06"
  },
  {
    "uniprot": "Q4DA21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA21"
  },
  {
    "uniprot": "Q4CMB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMB2"
  },
  {
    "uniprot": "Q4E244",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E244"
  },
  {
    "uniprot": "Q4DGY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGY4"
  },
  {
    "uniprot": "Q4DQF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQF9"
  },
  {
    "uniprot": "Q4E471",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E471"
  },
  {
    "uniprot": "Q4DZD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZD1"
  },
  {
    "uniprot": "Q4CLL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLL8"
  },
  {
    "uniprot": "Q4D019",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D019"
  },
  {
    "uniprot": "Q4CRY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRY0"
  },
  {
    "uniprot": "Q4CM93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM93"
  },
  {
    "uniprot": "Q4DSU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSU2"
  },
  {
    "uniprot": "Q4DY15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY15"
  },
  {
    "uniprot": "Q4D3R9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3R9"
  },
  {
    "uniprot": "Q4DN77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN77"
  },
  {
    "uniprot": "Q4DC99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC99"
  },
  {
    "uniprot": "Q4E4J8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4J8"
  },
  {
    "uniprot": "Q4E3I1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3I1"
  },
  {
    "uniprot": "Q4DAF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAF3"
  },
  {
    "uniprot": "Q4CWN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWN6"
  },
  {
    "uniprot": "Q4DED4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DED4"
  },
  {
    "uniprot": "Q4E0Z1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Z1"
  },
  {
    "uniprot": "Q4CU13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU13"
  },
  {
    "uniprot": "Q4DPT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPT0"
  },
  {
    "uniprot": "Q4CQJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQJ6"
  },
  {
    "uniprot": "Q4CRJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRJ8"
  },
  {
    "uniprot": "Q4D6W9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6W9"
  },
  {
    "uniprot": "Q4CZ51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ51"
  },
  {
    "uniprot": "Q4CZV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZV1"
  },
  {
    "uniprot": "Q4DQ34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ34"
  },
  {
    "uniprot": "Q4DAD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAD9"
  },
  {
    "uniprot": "Q4D5A9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5A9"
  },
  {
    "uniprot": "Q4DA36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA36"
  },
  {
    "uniprot": "Q4DDI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDI6"
  },
  {
    "uniprot": "Q4DAH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAH9"
  },
  {
    "uniprot": "Q4DNY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNY9"
  },
  {
    "uniprot": "Q4DHJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHJ2"
  },
  {
    "uniprot": "Q4CL13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL13"
  },
  {
    "uniprot": "Q4DK95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK95"
  },
  {
    "uniprot": "Q4D4S7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4S7"
  },
  {
    "uniprot": "Q4DC15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC15"
  },
  {
    "uniprot": "Q4DZ97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ97"
  },
  {
    "uniprot": "Q4DCZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCZ3"
  },
  {
    "uniprot": "Q4DK51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK51"
  },
  {
    "uniprot": "Q4DAS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAS2"
  },
  {
    "uniprot": "Q4DUD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUD6"
  },
  {
    "uniprot": "Q4DR20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR20"
  },
  {
    "uniprot": "Q4E0X2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0X2"
  },
  {
    "uniprot": "Q4DN57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN57"
  },
  {
    "uniprot": "Q4DZB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZB2"
  },
  {
    "uniprot": "Q4CX27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX27"
  },
  {
    "uniprot": "Q4DDA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDA5"
  },
  {
    "uniprot": "Q4DFX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFX8"
  },
  {
    "uniprot": "Q4D643",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D643"
  },
  {
    "uniprot": "Q4DG57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG57"
  },
  {
    "uniprot": "Q4DCW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCW1"
  },
  {
    "uniprot": "Q4D4W2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4W2"
  },
  {
    "uniprot": "Q4D564",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D564"
  },
  {
    "uniprot": "Q4DU10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU10"
  },
  {
    "uniprot": "Q4DHL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHL9"
  },
  {
    "uniprot": "Q4CRX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRX7"
  },
  {
    "uniprot": "Q4D7L3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7L3"
  },
  {
    "uniprot": "Q4D2J7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2J7"
  },
  {
    "uniprot": "Q4DNM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNM2"
  },
  {
    "uniprot": "Q4DIQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIQ4"
  },
  {
    "uniprot": "Q4D3A7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3A7"
  },
  {
    "uniprot": "Q4DWW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWW4"
  },
  {
    "uniprot": "Q4CXE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXE2"
  },
  {
    "uniprot": "Q4CSB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSB5"
  },
  {
    "uniprot": "Q4CWW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWW4"
  },
  {
    "uniprot": "Q4DCF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCF0"
  },
  {
    "uniprot": "Q4D854",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D854"
  },
  {
    "uniprot": "Q4CYH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYH5"
  },
  {
    "uniprot": "Q4D7E2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7E2"
  },
  {
    "uniprot": "Q4DXT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXT5"
  },
  {
    "uniprot": "Q4E2D6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2D6"
  },
  {
    "uniprot": "Q4CR26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR26"
  },
  {
    "uniprot": "Q4DD33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD33"
  },
  {
    "uniprot": "Q4DG86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG86"
  },
  {
    "uniprot": "Q4CYB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYB8"
  },
  {
    "uniprot": "Q4DVP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVP5"
  },
  {
    "uniprot": "Q4E555",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E555"
  },
  {
    "uniprot": "Q4E1P3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1P3"
  },
  {
    "uniprot": "Q4DPX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPX4"
  },
  {
    "uniprot": "Q4DBV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBV6"
  },
  {
    "uniprot": "Q4DLX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLX1"
  },
  {
    "uniprot": "Q4DQ82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ82"
  },
  {
    "uniprot": "Q4E636",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E636"
  },
  {
    "uniprot": "Q4DEI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEI2"
  },
  {
    "uniprot": "Q4CUR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUR4"
  },
  {
    "uniprot": "Q4E1B5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1B5"
  },
  {
    "uniprot": "Q4CVG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVG2"
  },
  {
    "uniprot": "Q4DVZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVZ5"
  },
  {
    "uniprot": "Q4DUZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUZ3"
  },
  {
    "uniprot": "Q4CPD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPD3"
  },
  {
    "uniprot": "Q4DV18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV18"
  },
  {
    "uniprot": "Q4D6I1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6I1"
  },
  {
    "uniprot": "Q4CWJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWJ9"
  },
  {
    "uniprot": "Q4DUK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUK7"
  },
  {
    "uniprot": "Q4DBF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBF4"
  },
  {
    "uniprot": "Q4DUH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUH9"
  },
  {
    "uniprot": "Q4CRN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRN7"
  },
  {
    "uniprot": "Q4D168",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D168"
  },
  {
    "uniprot": "Q4DH71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH71"
  },
  {
    "uniprot": "Q4DNU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNU0"
  },
  {
    "uniprot": "Q4DB18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB18"
  },
  {
    "uniprot": "Q4DGF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGF0"
  },
  {
    "uniprot": "Q4DXC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXC9"
  },
  {
    "uniprot": "Q4CMC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMC6"
  },
  {
    "uniprot": "Q4D137",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D137"
  },
  {
    "uniprot": "Q4DKZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKZ4"
  },
  {
    "uniprot": "Q4DD40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD40"
  },
  {
    "uniprot": "Q4DLZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLZ7"
  },
  {
    "uniprot": "Q4DG66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG66"
  },
  {
    "uniprot": "Q4DWT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWT6"
  },
  {
    "uniprot": "Q4E2I6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2I6"
  },
  {
    "uniprot": "Q4CUJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUJ9"
  },
  {
    "uniprot": "Q4DXN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXN7"
  },
  {
    "uniprot": "Q4CUH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUH0"
  },
  {
    "uniprot": "Q4CYA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYA8"
  },
  {
    "uniprot": "Q4D576",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D576"
  },
  {
    "uniprot": "Q4E3A5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3A5"
  },
  {
    "uniprot": "Q4DLQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLQ7"
  },
  {
    "uniprot": "Q4CT89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT89"
  },
  {
    "uniprot": "Q4DIP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIP3"
  },
  {
    "uniprot": "Q4DT67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT67"
  },
  {
    "uniprot": "Q4DB57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB57"
  },
  {
    "uniprot": "Q4DN45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN45"
  },
  {
    "uniprot": "Q4DFS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFS9"
  },
  {
    "uniprot": "Q4D0R9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0R9"
  },
  {
    "uniprot": "Q4CPP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPP2"
  },
  {
    "uniprot": "Q4E235",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E235"
  },
  {
    "uniprot": "Q4E451",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E451"
  },
  {
    "uniprot": "Q4CZW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZW3"
  },
  {
    "uniprot": "Q4DZ00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ00"
  },
  {
    "uniprot": "Q4E413",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E413"
  },
  {
    "uniprot": "Q4DBI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBI5"
  },
  {
    "uniprot": "Q4DFR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFR5"
  },
  {
    "uniprot": "Q4CY34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY34"
  },
  {
    "uniprot": "Q4CLV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLV2"
  },
  {
    "uniprot": "Q4DYJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYJ3"
  },
  {
    "uniprot": "Q4DT55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT55"
  },
  {
    "uniprot": "Q4CML3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CML3"
  },
  {
    "uniprot": "Q4DMY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMY4"
  },
  {
    "uniprot": "Q4CPM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPM6"
  },
  {
    "uniprot": "Q4CZN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZN1"
  },
  {
    "uniprot": "Q4DC69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC69"
  },
  {
    "uniprot": "Q4CZZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZZ1"
  },
  {
    "uniprot": "Q4DJF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJF0"
  },
  {
    "uniprot": "Q4DM65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM65"
  },
  {
    "uniprot": "Q4DT31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT31"
  },
  {
    "uniprot": "Q4D5J3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5J3"
  },
  {
    "uniprot": "Q4DN05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN05"
  },
  {
    "uniprot": "Q4CKR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKR0"
  },
  {
    "uniprot": "Q4D9F2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9F2"
  },
  {
    "uniprot": "Q4DH03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH03"
  },
  {
    "uniprot": "Q4E3M2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3M2"
  },
  {
    "uniprot": "Q4E2R4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2R4"
  },
  {
    "uniprot": "Q4D7I4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7I4"
  },
  {
    "uniprot": "Q4D8V6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8V6"
  },
  {
    "uniprot": "Q4DZ21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ21"
  },
  {
    "uniprot": "Q4DFZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFZ1"
  },
  {
    "uniprot": "Q4DCT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCT3"
  },
  {
    "uniprot": "Q4DLB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLB7"
  },
  {
    "uniprot": "Q4CTB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTB3"
  },
  {
    "uniprot": "Q4DXR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXR1"
  },
  {
    "uniprot": "Q4DLX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLX7"
  },
  {
    "uniprot": "Q4CZN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZN2"
  },
  {
    "uniprot": "Q4DYH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYH0"
  },
  {
    "uniprot": "Q4DM47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM47"
  },
  {
    "uniprot": "Q4DM24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM24"
  },
  {
    "uniprot": "Q4DRZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRZ2"
  },
  {
    "uniprot": "Q4CMR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMR0"
  },
  {
    "uniprot": "Q4E2W3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2W3"
  },
  {
    "uniprot": "Q4DTB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTB2"
  },
  {
    "uniprot": "Q4CWR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWR0"
  },
  {
    "uniprot": "Q4DSN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSN8"
  },
  {
    "uniprot": "Q4CLL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLL6"
  },
  {
    "uniprot": "Q4D3U4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3U4"
  },
  {
    "uniprot": "Q4DKS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKS8"
  },
  {
    "uniprot": "Q4DT21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT21"
  },
  {
    "uniprot": "Q4E5Y9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Y9"
  },
  {
    "uniprot": "Q4CZB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZB1"
  },
  {
    "uniprot": "Q4DHP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHP8"
  },
  {
    "uniprot": "Q4CSF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSF5"
  },
  {
    "uniprot": "Q4D0D9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0D9"
  },
  {
    "uniprot": "Q4CSY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSY9"
  },
  {
    "uniprot": "Q4DAL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAL3"
  },
  {
    "uniprot": "Q4E166",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E166"
  },
  {
    "uniprot": "Q4E4L3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4L3"
  },
  {
    "uniprot": "Q4CRH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRH4"
  },
  {
    "uniprot": "Q4D4F1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4F1"
  },
  {
    "uniprot": "Q4E3S4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3S4"
  },
  {
    "uniprot": "Q4DSN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSN1"
  },
  {
    "uniprot": "Q4CNC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNC8"
  },
  {
    "uniprot": "Q4DZ39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ39"
  },
  {
    "uniprot": "Q4CL99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL99"
  },
  {
    "uniprot": "Q4DBA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBA9"
  },
  {
    "uniprot": "Q4D2M9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2M9"
  },
  {
    "uniprot": "Q4CZF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZF9"
  },
  {
    "uniprot": "Q4DQP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQP2"
  },
  {
    "uniprot": "Q4DMW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMW6"
  },
  {
    "uniprot": "Q4DK17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK17"
  },
  {
    "uniprot": "Q4DRA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRA2"
  },
  {
    "uniprot": "Q4CKN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKN6"
  },
  {
    "uniprot": "Q4CYC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYC9"
  },
  {
    "uniprot": "Q4DS25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS25"
  },
  {
    "uniprot": "Q4D1L2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1L2"
  },
  {
    "uniprot": "Q4DPZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPZ6"
  },
  {
    "uniprot": "Q4D5H1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5H1"
  },
  {
    "uniprot": "Q4E033",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E033"
  },
  {
    "uniprot": "Q4CS14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS14"
  },
  {
    "uniprot": "Q4CWH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWH1"
  },
  {
    "uniprot": "Q4CYS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYS4"
  },
  {
    "uniprot": "Q4DSC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSC0"
  },
  {
    "uniprot": "Q4CLZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLZ9"
  },
  {
    "uniprot": "Q4DTV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTV5"
  },
  {
    "uniprot": "Q4D229",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D229"
  },
  {
    "uniprot": "Q4DQK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQK1"
  },
  {
    "uniprot": "Q4E0X3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0X3"
  },
  {
    "uniprot": "Q4CY77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY77"
  },
  {
    "uniprot": "Q4DY03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY03"
  },
  {
    "uniprot": "Q4DYG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYG5"
  },
  {
    "uniprot": "Q4DHD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHD7"
  },
  {
    "uniprot": "Q4DYM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYM0"
  },
  {
    "uniprot": "Q4D5T0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5T0"
  },
  {
    "uniprot": "Q4CNL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNL1"
  },
  {
    "uniprot": "Q4DI56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI56"
  },
  {
    "uniprot": "Q4D6M8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6M8"
  },
  {
    "uniprot": "Q4DVU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVU1"
  },
  {
    "uniprot": "Q4D1N1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1N1"
  },
  {
    "uniprot": "Q4CVY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVY5"
  },
  {
    "uniprot": "Q4DY81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY81"
  },
  {
    "uniprot": "Q4E4A4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4A4"
  },
  {
    "uniprot": "Q4DF76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF76"
  },
  {
    "uniprot": "Q4CLV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLV0"
  },
  {
    "uniprot": "Q4DC13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC13"
  },
  {
    "uniprot": "Q4DLX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLX9"
  },
  {
    "uniprot": "Q4CW61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW61"
  },
  {
    "uniprot": "Q4DK03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK03"
  },
  {
    "uniprot": "Q4CZR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZR6"
  },
  {
    "uniprot": "Q4DFC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFC8"
  },
  {
    "uniprot": "Q4DN03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN03"
  },
  {
    "uniprot": "Q4DJX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJX1"
  },
  {
    "uniprot": "Q4D664",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D664"
  },
  {
    "uniprot": "Q4DP07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP07"
  },
  {
    "uniprot": "Q4DXE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXE9"
  },
  {
    "uniprot": "Q4D8C6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8C6"
  },
  {
    "uniprot": "Q4DR84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR84"
  },
  {
    "uniprot": "Q4DV15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV15"
  },
  {
    "uniprot": "Q4DLS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLS9"
  },
  {
    "uniprot": "Q4D5V3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5V3"
  },
  {
    "uniprot": "Q4DK44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK44"
  },
  {
    "uniprot": "Q4CN64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN64"
  },
  {
    "uniprot": "Q4DRY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRY7"
  },
  {
    "uniprot": "Q4D7R4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7R4"
  },
  {
    "uniprot": "Q4E125",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E125"
  },
  {
    "uniprot": "Q4DS50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS50"
  },
  {
    "uniprot": "Q4CQ13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ13"
  },
  {
    "uniprot": "Q4DW53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW53"
  },
  {
    "uniprot": "Q4DH32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH32"
  },
  {
    "uniprot": "Q4E1L9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1L9"
  },
  {
    "uniprot": "Q4DPN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPN7"
  },
  {
    "uniprot": "Q4DTB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTB8"
  },
  {
    "uniprot": "Q4DE32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE32"
  },
  {
    "uniprot": "Q4DEM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEM7"
  },
  {
    "uniprot": "Q4CZC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZC6"
  },
  {
    "uniprot": "Q4CV30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV30"
  },
  {
    "uniprot": "Q4CTN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTN7"
  },
  {
    "uniprot": "Q4D827",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D827"
  },
  {
    "uniprot": "Q4D1H2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1H2"
  },
  {
    "uniprot": "Q4E0Q0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Q0"
  },
  {
    "uniprot": "Q4DZ89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ89"
  },
  {
    "uniprot": "Q4E4C6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4C6"
  },
  {
    "uniprot": "Q4D1X3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1X3"
  },
  {
    "uniprot": "Q4DA22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA22"
  },
  {
    "uniprot": "Q4D0R8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0R8"
  },
  {
    "uniprot": "Q4CQ92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ92"
  },
  {
    "uniprot": "Q4E5Z2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Z2"
  },
  {
    "uniprot": "Q4DWI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWI1"
  },
  {
    "uniprot": "Q4DPQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPQ2"
  },
  {
    "uniprot": "Q4CXW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXW3"
  },
  {
    "uniprot": "Q4DJ47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ47"
  },
  {
    "uniprot": "Q4DSB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSB8"
  },
  {
    "uniprot": "Q4CXK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXK6"
  },
  {
    "uniprot": "Q4CM56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM56"
  },
  {
    "uniprot": "Q4DW45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW45"
  },
  {
    "uniprot": "Q4E2Y4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Y4"
  },
  {
    "uniprot": "Q4CRH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRH0"
  },
  {
    "uniprot": "Q4DQF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQF8"
  },
  {
    "uniprot": "Q4DKT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKT1"
  },
  {
    "uniprot": "Q4DUK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUK3"
  },
  {
    "uniprot": "Q4CWH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWH9"
  },
  {
    "uniprot": "Q4D258",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D258"
  },
  {
    "uniprot": "Q4CPC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPC0"
  },
  {
    "uniprot": "Q4CZU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZU1"
  },
  {
    "uniprot": "Q4E4F7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4F7"
  },
  {
    "uniprot": "Q4D652",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D652"
  },
  {
    "uniprot": "Q4CQE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQE4"
  },
  {
    "uniprot": "Q4DCL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCL3"
  },
  {
    "uniprot": "Q4DTA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTA4"
  },
  {
    "uniprot": "Q4E263",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E263"
  },
  {
    "uniprot": "Q4CUQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUQ6"
  },
  {
    "uniprot": "Q4DJD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJD3"
  },
  {
    "uniprot": "Q4CS00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS00"
  },
  {
    "uniprot": "Q4DWH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWH7"
  },
  {
    "uniprot": "Q4E0X8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0X8"
  },
  {
    "uniprot": "Q4CPP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPP0"
  },
  {
    "uniprot": "Q4CWV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWV6"
  },
  {
    "uniprot": "Q4E4P5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4P5"
  },
  {
    "uniprot": "Q4CZ46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ46"
  },
  {
    "uniprot": "Q4CYQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYQ0"
  },
  {
    "uniprot": "Q4CKN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKN2"
  },
  {
    "uniprot": "Q4DN38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN38"
  },
  {
    "uniprot": "Q4DUY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUY3"
  },
  {
    "uniprot": "Q4DGY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGY8"
  },
  {
    "uniprot": "Q4E3B3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3B3"
  },
  {
    "uniprot": "Q4D180",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D180"
  },
  {
    "uniprot": "Q4D704",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D704"
  },
  {
    "uniprot": "Q4E5J7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5J7"
  },
  {
    "uniprot": "Q4DSF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSF4"
  },
  {
    "uniprot": "Q4CVG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVG7"
  },
  {
    "uniprot": "Q4DAZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAZ3"
  },
  {
    "uniprot": "Q4DTA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTA1"
  },
  {
    "uniprot": "Q4CTB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTB0"
  },
  {
    "uniprot": "Q4DX75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX75"
  },
  {
    "uniprot": "Q4D8V3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8V3"
  },
  {
    "uniprot": "Q4CRY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRY8"
  },
  {
    "uniprot": "Q4CRC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRC3"
  },
  {
    "uniprot": "Q4E1S8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1S8"
  },
  {
    "uniprot": "Q4DA29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA29"
  },
  {
    "uniprot": "Q4CLA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLA4"
  },
  {
    "uniprot": "Q4E108",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E108"
  },
  {
    "uniprot": "Q4E4V9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4V9"
  },
  {
    "uniprot": "Q4CV27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV27"
  },
  {
    "uniprot": "Q4CUE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUE6"
  },
  {
    "uniprot": "Q4DHH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHH5"
  },
  {
    "uniprot": "Q4CZC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZC4"
  },
  {
    "uniprot": "Q4D8Y1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Y1"
  },
  {
    "uniprot": "Q4DKB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKB2"
  },
  {
    "uniprot": "Q4D8Y0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Y0"
  },
  {
    "uniprot": "Q4E3M0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3M0"
  },
  {
    "uniprot": "Q4D9T5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9T5"
  },
  {
    "uniprot": "Q4DXT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXT6"
  },
  {
    "uniprot": "Q4DEZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEZ2"
  },
  {
    "uniprot": "Q4DJR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJR9"
  },
  {
    "uniprot": "Q4CNV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNV1"
  },
  {
    "uniprot": "Q4E3M3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3M3"
  },
  {
    "uniprot": "Q4DSM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSM3"
  },
  {
    "uniprot": "Q4DUL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUL5"
  },
  {
    "uniprot": "Q4DHH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHH2"
  },
  {
    "uniprot": "Q4E582",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E582"
  },
  {
    "uniprot": "Q4CLP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLP8"
  },
  {
    "uniprot": "Q4DPD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPD0"
  },
  {
    "uniprot": "Q4DQJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQJ2"
  },
  {
    "uniprot": "Q4D4U1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4U1"
  },
  {
    "uniprot": "Q4CVH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVH8"
  },
  {
    "uniprot": "Q4DAM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAM8"
  },
  {
    "uniprot": "Q4DHP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHP0"
  },
  {
    "uniprot": "Q4CQ17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ17"
  },
  {
    "uniprot": "Q4D7A6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7A6"
  },
  {
    "uniprot": "Q4E641",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E641"
  },
  {
    "uniprot": "Q4DEL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEL8"
  },
  {
    "uniprot": "Q4DVF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVF9"
  },
  {
    "uniprot": "Q4DJ31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ31"
  },
  {
    "uniprot": "Q4CNM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNM5"
  },
  {
    "uniprot": "Q4CQG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQG0"
  },
  {
    "uniprot": "Q4CP03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP03"
  },
  {
    "uniprot": "Q4DMM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMM4"
  },
  {
    "uniprot": "Q4CPD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPD8"
  },
  {
    "uniprot": "Q4DER8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DER8"
  },
  {
    "uniprot": "Q4DXI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXI2"
  },
  {
    "uniprot": "Q4DXX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXX3"
  },
  {
    "uniprot": "Q4DPI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPI8"
  },
  {
    "uniprot": "Q4CVX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVX9"
  },
  {
    "uniprot": "Q4DJR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJR0"
  },
  {
    "uniprot": "Q4CQQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQQ2"
  },
  {
    "uniprot": "Q4D2D6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2D6"
  },
  {
    "uniprot": "Q4DB36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB36"
  },
  {
    "uniprot": "Q4E672",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E672"
  },
  {
    "uniprot": "Q4DZQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZQ5"
  },
  {
    "uniprot": "Q4DM19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM19"
  },
  {
    "uniprot": "Q4CXC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXC9"
  },
  {
    "uniprot": "Q4DW13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW13"
  },
  {
    "uniprot": "Q4DT72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT72"
  },
  {
    "uniprot": "Q4D3T8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3T8"
  },
  {
    "uniprot": "Q4DWZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWZ3"
  },
  {
    "uniprot": "Q4CW27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW27"
  },
  {
    "uniprot": "Q4CLQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLQ3"
  },
  {
    "uniprot": "Q4DTW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTW9"
  },
  {
    "uniprot": "Q4DRC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRC3"
  },
  {
    "uniprot": "Q4DS20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS20"
  },
  {
    "uniprot": "Q4DZH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZH8"
  },
  {
    "uniprot": "Q4DN88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN88"
  },
  {
    "uniprot": "Q4DNU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNU2"
  },
  {
    "uniprot": "Q4DPV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPV1"
  },
  {
    "uniprot": "Q4CM06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM06"
  },
  {
    "uniprot": "Q4D8L0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8L0"
  },
  {
    "uniprot": "Q4DYU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYU9"
  },
  {
    "uniprot": "Q4DXE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXE1"
  },
  {
    "uniprot": "Q4D0X0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0X0"
  },
  {
    "uniprot": "Q4CMK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMK1"
  },
  {
    "uniprot": "Q4DIG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIG3"
  },
  {
    "uniprot": "Q4CWG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWG1"
  },
  {
    "uniprot": "Q4DJH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJH2"
  },
  {
    "uniprot": "Q4CMI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMI1"
  },
  {
    "uniprot": "Q4D214",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D214"
  },
  {
    "uniprot": "Q4DLX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLX0"
  },
  {
    "uniprot": "Q4DI94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI94"
  },
  {
    "uniprot": "Q4DWX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWX2"
  },
  {
    "uniprot": "Q4DX00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX00"
  },
  {
    "uniprot": "Q4DT47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT47"
  },
  {
    "uniprot": "Q4CXY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXY8"
  },
  {
    "uniprot": "Q4D7U6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7U6"
  },
  {
    "uniprot": "Q4DE11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE11"
  },
  {
    "uniprot": "Q4CWD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWD4"
  },
  {
    "uniprot": "Q4D3F1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3F1"
  },
  {
    "uniprot": "Q4E658",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E658"
  },
  {
    "uniprot": "Q4CKG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKG9"
  },
  {
    "uniprot": "Q4E475",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E475"
  },
  {
    "uniprot": "Q4CLV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLV6"
  },
  {
    "uniprot": "Q4DLT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLT3"
  },
  {
    "uniprot": "Q4DAL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAL5"
  },
  {
    "uniprot": "Q4D4Y2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Y2"
  },
  {
    "uniprot": "Q4DEG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEG4"
  },
  {
    "uniprot": "Q4D4R7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4R7"
  },
  {
    "uniprot": "Q4D8X2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8X2"
  },
  {
    "uniprot": "Q4DSV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSV1"
  },
  {
    "uniprot": "Q4D369",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D369"
  },
  {
    "uniprot": "Q4DUA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUA5"
  },
  {
    "uniprot": "Q4CNK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNK3"
  },
  {
    "uniprot": "Q4CN43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN43"
  },
  {
    "uniprot": "Q4DWG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWG6"
  },
  {
    "uniprot": "Q4D0T6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0T6"
  },
  {
    "uniprot": "Q4CM44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM44"
  },
  {
    "uniprot": "Q4E5I9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5I9"
  },
  {
    "uniprot": "Q4DJL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJL2"
  },
  {
    "uniprot": "Q4CYR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYR3"
  },
  {
    "uniprot": "Q4DAX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAX0"
  },
  {
    "uniprot": "Q4DGP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGP8"
  },
  {
    "uniprot": "Q4DQC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQC4"
  },
  {
    "uniprot": "Q4E0W8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0W8"
  },
  {
    "uniprot": "Q4DGP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGP5"
  },
  {
    "uniprot": "Q4CXZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXZ8"
  },
  {
    "uniprot": "Q4DNF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNF2"
  },
  {
    "uniprot": "Q4DBU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBU4"
  },
  {
    "uniprot": "Q4DD31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD31"
  },
  {
    "uniprot": "Q4D984",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D984"
  },
  {
    "uniprot": "Q4DTL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTL7"
  },
  {
    "uniprot": "Q4D5N9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5N9"
  },
  {
    "uniprot": "Q4DUQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUQ8"
  },
  {
    "uniprot": "Q4DNB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNB5"
  },
  {
    "uniprot": "Q4DYW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYW3"
  },
  {
    "uniprot": "Q4DQE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQE7"
  },
  {
    "uniprot": "Q4DB07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB07"
  },
  {
    "uniprot": "Q4DU37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU37"
  },
  {
    "uniprot": "Q4D6H1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6H1"
  },
  {
    "uniprot": "Q4DSY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSY4"
  },
  {
    "uniprot": "Q4DJ44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ44"
  },
  {
    "uniprot": "Q4CQ72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ72"
  },
  {
    "uniprot": "Q4E523",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E523"
  },
  {
    "uniprot": "Q4CXS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXS6"
  },
  {
    "uniprot": "Q4CXX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXX1"
  },
  {
    "uniprot": "Q4DBH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBH8"
  },
  {
    "uniprot": "Q4DJV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJV3"
  },
  {
    "uniprot": "Q4CVV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVV2"
  },
  {
    "uniprot": "Q4DK25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK25"
  },
  {
    "uniprot": "Q4DS24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS24"
  },
  {
    "uniprot": "Q4DCB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCB3"
  },
  {
    "uniprot": "Q4CKJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKJ9"
  },
  {
    "uniprot": "Q4CKL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKL3"
  },
  {
    "uniprot": "Q4DGM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGM7"
  },
  {
    "uniprot": "Q4DTK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTK9"
  },
  {
    "uniprot": "Q4CX69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX69"
  },
  {
    "uniprot": "Q4CT39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT39"
  },
  {
    "uniprot": "Q4DHF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHF9"
  },
  {
    "uniprot": "Q4E269",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E269"
  },
  {
    "uniprot": "Q4DYA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYA4"
  },
  {
    "uniprot": "Q4DIL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIL8"
  },
  {
    "uniprot": "Q4DAN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAN8"
  },
  {
    "uniprot": "Q4E3W6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3W6"
  },
  {
    "uniprot": "Q4CW01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW01"
  },
  {
    "uniprot": "Q4E0R5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0R5"
  },
  {
    "uniprot": "Q4D414",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D414"
  },
  {
    "uniprot": "Q4CQ54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ54"
  },
  {
    "uniprot": "Q4DYB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYB5"
  },
  {
    "uniprot": "Q4DW18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW18"
  },
  {
    "uniprot": "Q4DQC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQC5"
  },
  {
    "uniprot": "Q4CY92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY92"
  },
  {
    "uniprot": "Q4E0G1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0G1"
  },
  {
    "uniprot": "Q4CV81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV81"
  },
  {
    "uniprot": "Q4DUB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUB5"
  },
  {
    "uniprot": "Q4DZP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZP9"
  },
  {
    "uniprot": "Q4CL62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL62"
  },
  {
    "uniprot": "Q4DX39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX39"
  },
  {
    "uniprot": "Q4E645",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E645"
  },
  {
    "uniprot": "Q4D3H7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3H7"
  },
  {
    "uniprot": "Q4CRY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRY9"
  },
  {
    "uniprot": "Q4D2N9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2N9"
  },
  {
    "uniprot": "Q4DKF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKF7"
  },
  {
    "uniprot": "Q4DIX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIX5"
  },
  {
    "uniprot": "Q4CQ77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ77"
  },
  {
    "uniprot": "Q4DL72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL72"
  },
  {
    "uniprot": "Q4E1Q8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Q8"
  },
  {
    "uniprot": "Q4CXB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXB7"
  },
  {
    "uniprot": "Q4D4U6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4U6"
  },
  {
    "uniprot": "Q4DA92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA92"
  },
  {
    "uniprot": "Q4CVM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVM1"
  },
  {
    "uniprot": "Q4D1T0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1T0"
  },
  {
    "uniprot": "Q4CQR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQR2"
  },
  {
    "uniprot": "Q4DKU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKU8"
  },
  {
    "uniprot": "Q4D5R6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5R6"
  },
  {
    "uniprot": "Q4DRD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRD5"
  },
  {
    "uniprot": "Q4E0B4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0B4"
  },
  {
    "uniprot": "Q4DZC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZC5"
  },
  {
    "uniprot": "Q4CUN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUN7"
  },
  {
    "uniprot": "Q4DZ02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ02"
  },
  {
    "uniprot": "Q4E3J8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3J8"
  },
  {
    "uniprot": "Q4E2U1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2U1"
  },
  {
    "uniprot": "Q4DJ92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ92"
  },
  {
    "uniprot": "Q4DFY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFY8"
  },
  {
    "uniprot": "Q4DU52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU52"
  },
  {
    "uniprot": "Q4DQN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQN6"
  },
  {
    "uniprot": "Q4D7B8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7B8"
  },
  {
    "uniprot": "Q4DB84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB84"
  },
  {
    "uniprot": "Q4DAV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAV8"
  },
  {
    "uniprot": "Q4DGZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGZ7"
  },
  {
    "uniprot": "Q4CYM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYM2"
  },
  {
    "uniprot": "Q4DC44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC44"
  },
  {
    "uniprot": "Q4DYN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYN8"
  },
  {
    "uniprot": "Q4DMQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMQ4"
  },
  {
    "uniprot": "Q4D9R7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9R7"
  },
  {
    "uniprot": "Q4DV66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV66"
  },
  {
    "uniprot": "Q4CLD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLD0"
  },
  {
    "uniprot": "Q4DYN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYN3"
  },
  {
    "uniprot": "Q4DK05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK05"
  },
  {
    "uniprot": "Q4CLD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLD7"
  },
  {
    "uniprot": "Q4DF12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF12"
  },
  {
    "uniprot": "Q4CU96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU96"
  },
  {
    "uniprot": "Q4DHH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHH8"
  },
  {
    "uniprot": "Q4D8G1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8G1"
  },
  {
    "uniprot": "Q4DPP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPP7"
  },
  {
    "uniprot": "Q4DH21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH21"
  },
  {
    "uniprot": "Q4DS72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS72"
  },
  {
    "uniprot": "Q4CXG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXG1"
  },
  {
    "uniprot": "Q4DZP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZP8"
  },
  {
    "uniprot": "Q4DFN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFN1"
  },
  {
    "uniprot": "Q4DKH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKH8"
  },
  {
    "uniprot": "Q4DAW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAW2"
  },
  {
    "uniprot": "Q4DLG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLG3"
  },
  {
    "uniprot": "Q4D733",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D733"
  },
  {
    "uniprot": "Q4DYL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYL2"
  },
  {
    "uniprot": "Q4CZU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZU7"
  },
  {
    "uniprot": "Q4CV91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV91"
  },
  {
    "uniprot": "Q4CRV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRV3"
  },
  {
    "uniprot": "Q4DFD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFD3"
  },
  {
    "uniprot": "Q4DUE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUE3"
  },
  {
    "uniprot": "Q4CM00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM00"
  },
  {
    "uniprot": "Q4D8Q6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Q6"
  },
  {
    "uniprot": "Q4DYA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYA5"
  },
  {
    "uniprot": "Q4CNF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNF1"
  },
  {
    "uniprot": "Q4DN98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN98"
  },
  {
    "uniprot": "Q4DZ36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ36"
  },
  {
    "uniprot": "Q4CP47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP47"
  },
  {
    "uniprot": "Q4DJ07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ07"
  },
  {
    "uniprot": "Q4CMG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMG3"
  },
  {
    "uniprot": "Q4D1U4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1U4"
  },
  {
    "uniprot": "Q4DHN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHN6"
  },
  {
    "uniprot": "Q4CUP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUP5"
  },
  {
    "uniprot": "Q4CND2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CND2"
  },
  {
    "uniprot": "Q4DL06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL06"
  },
  {
    "uniprot": "Q4DD34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD34"
  },
  {
    "uniprot": "Q4DCR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCR2"
  },
  {
    "uniprot": "Q4DU45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU45"
  },
  {
    "uniprot": "Q4CN16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN16"
  },
  {
    "uniprot": "Q4CZ80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ80"
  },
  {
    "uniprot": "Q4D9H3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9H3"
  },
  {
    "uniprot": "Q4E1S7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1S7"
  },
  {
    "uniprot": "Q4CTT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTT3"
  },
  {
    "uniprot": "Q4DL70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL70"
  },
  {
    "uniprot": "Q4D0K3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0K3"
  },
  {
    "uniprot": "Q4E0D5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0D5"
  },
  {
    "uniprot": "Q4DSR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSR6"
  },
  {
    "uniprot": "Q4E3H3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3H3"
  },
  {
    "uniprot": "Q4DQ39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ39"
  },
  {
    "uniprot": "Q4CQN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQN9"
  },
  {
    "uniprot": "Q4D4D2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4D2"
  },
  {
    "uniprot": "Q4E1N7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1N7"
  },
  {
    "uniprot": "Q4DDG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDG7"
  },
  {
    "uniprot": "Q4CKU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKU4"
  },
  {
    "uniprot": "Q4CVZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVZ8"
  },
  {
    "uniprot": "Q4DTB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTB9"
  },
  {
    "uniprot": "Q4DN22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN22"
  },
  {
    "uniprot": "Q4CS81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS81"
  },
  {
    "uniprot": "Q4DDF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDF2"
  },
  {
    "uniprot": "Q4CWS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWS1"
  },
  {
    "uniprot": "Q4CYG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYG5"
  },
  {
    "uniprot": "Q4E259",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E259"
  },
  {
    "uniprot": "Q4D2J0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2J0"
  },
  {
    "uniprot": "Q4CQF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQF4"
  },
  {
    "uniprot": "Q4D4P5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4P5"
  },
  {
    "uniprot": "Q4DM82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM82"
  },
  {
    "uniprot": "Q4CRC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRC1"
  },
  {
    "uniprot": "Q4CV37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV37"
  },
  {
    "uniprot": "Q4DLA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLA1"
  },
  {
    "uniprot": "Q4D327",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D327"
  },
  {
    "uniprot": "Q4CYJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYJ7"
  },
  {
    "uniprot": "Q4DL13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL13"
  },
  {
    "uniprot": "Q4D0B1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0B1"
  },
  {
    "uniprot": "Q4CV79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV79"
  },
  {
    "uniprot": "Q4D7L4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7L4"
  },
  {
    "uniprot": "Q4DS66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS66"
  },
  {
    "uniprot": "Q4D768",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D768"
  },
  {
    "uniprot": "Q4D171",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D171"
  },
  {
    "uniprot": "Q4DBD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBD2"
  },
  {
    "uniprot": "Q4DC54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC54"
  },
  {
    "uniprot": "Q4DDJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDJ4"
  },
  {
    "uniprot": "Q4DQZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQZ7"
  },
  {
    "uniprot": "Q4DK24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK24"
  },
  {
    "uniprot": "Q4CYY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYY6"
  },
  {
    "uniprot": "Q4DFG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFG9"
  },
  {
    "uniprot": "Q4DEB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEB9"
  },
  {
    "uniprot": "Q4DQ36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ36"
  },
  {
    "uniprot": "Q4DL31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL31"
  },
  {
    "uniprot": "Q4CU65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU65"
  },
  {
    "uniprot": "Q4DWP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWP7"
  },
  {
    "uniprot": "Q4CUH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUH2"
  },
  {
    "uniprot": "Q4CRA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRA9"
  },
  {
    "uniprot": "Q4D0S3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0S3"
  },
  {
    "uniprot": "Q4CS98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS98"
  },
  {
    "uniprot": "Q4DZK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZK9"
  },
  {
    "uniprot": "Q4E4R2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4R2"
  },
  {
    "uniprot": "Q4DEJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEJ6"
  },
  {
    "uniprot": "Q4DLH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLH1"
  },
  {
    "uniprot": "Q4CUN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUN6"
  },
  {
    "uniprot": "Q4CTW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTW4"
  },
  {
    "uniprot": "Q4DRN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRN8"
  },
  {
    "uniprot": "Q4D6I6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6I6"
  },
  {
    "uniprot": "Q4CPV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPV6"
  },
  {
    "uniprot": "Q4D0D6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0D6"
  },
  {
    "uniprot": "Q4D4P8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4P8"
  },
  {
    "uniprot": "Q4CSQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSQ3"
  },
  {
    "uniprot": "Q4DZ30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ30"
  },
  {
    "uniprot": "Q4CTX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTX6"
  },
  {
    "uniprot": "Q4D9N6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9N6"
  },
  {
    "uniprot": "Q4DJR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJR6"
  },
  {
    "uniprot": "Q4DAN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAN6"
  },
  {
    "uniprot": "Q4DV97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV97"
  },
  {
    "uniprot": "Q4DR13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR13"
  },
  {
    "uniprot": "Q4D7T4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7T4"
  },
  {
    "uniprot": "Q4DHP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHP7"
  },
  {
    "uniprot": "Q4CNR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNR7"
  },
  {
    "uniprot": "Q4D931",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D931"
  },
  {
    "uniprot": "Q4D261",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D261"
  },
  {
    "uniprot": "Q4D5D8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5D8"
  },
  {
    "uniprot": "Q4DDY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDY4"
  },
  {
    "uniprot": "Q4CYL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYL6"
  },
  {
    "uniprot": "Q4DQ62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ62"
  },
  {
    "uniprot": "Q4CV51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV51"
  },
  {
    "uniprot": "Q4DU11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU11"
  },
  {
    "uniprot": "Q4CS80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS80"
  },
  {
    "uniprot": "Q4DDJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDJ7"
  },
  {
    "uniprot": "Q4DVL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVL3"
  },
  {
    "uniprot": "Q4CPJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPJ1"
  },
  {
    "uniprot": "Q4DMP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMP4"
  },
  {
    "uniprot": "Q4DVT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVT8"
  },
  {
    "uniprot": "Q4CMD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMD7"
  },
  {
    "uniprot": "Q4D6Q1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Q1"
  },
  {
    "uniprot": "Q4E3D8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3D8"
  },
  {
    "uniprot": "Q4E600",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E600"
  },
  {
    "uniprot": "Q4DH14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH14"
  },
  {
    "uniprot": "Q4DFF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFF5"
  },
  {
    "uniprot": "Q4CVE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVE0"
  },
  {
    "uniprot": "Q4CY04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY04"
  },
  {
    "uniprot": "Q4DV40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV40"
  },
  {
    "uniprot": "Q4DKL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKL4"
  },
  {
    "uniprot": "Q4E0M7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0M7"
  },
  {
    "uniprot": "Q4DZC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZC1"
  },
  {
    "uniprot": "Q4DV01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV01"
  },
  {
    "uniprot": "Q4DZH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZH9"
  },
  {
    "uniprot": "Q4E5J4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5J4"
  },
  {
    "uniprot": "Q4E5M8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5M8"
  },
  {
    "uniprot": "Q4CW80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW80"
  },
  {
    "uniprot": "Q4E196",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E196"
  },
  {
    "uniprot": "Q4CZ34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ34"
  },
  {
    "uniprot": "Q4D9Y1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Y1"
  },
  {
    "uniprot": "Q4CMY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMY2"
  },
  {
    "uniprot": "Q4DP02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP02"
  },
  {
    "uniprot": "Q4DHQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHQ3"
  },
  {
    "uniprot": "Q4DKS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKS0"
  },
  {
    "uniprot": "Q4E3Z2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Z2"
  },
  {
    "uniprot": "Q4DAI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAI7"
  },
  {
    "uniprot": "Q4E500",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E500"
  },
  {
    "uniprot": "Q4CMU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMU5"
  },
  {
    "uniprot": "Q4CWX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWX7"
  },
  {
    "uniprot": "Q4DI33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI33"
  },
  {
    "uniprot": "Q4DVX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVX9"
  },
  {
    "uniprot": "Q4CYX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYX5"
  },
  {
    "uniprot": "Q4CQR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQR0"
  },
  {
    "uniprot": "Q4DX04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX04"
  },
  {
    "uniprot": "Q4CWP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWP3"
  },
  {
    "uniprot": "Q4DRY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRY2"
  },
  {
    "uniprot": "Q4E3X6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3X6"
  },
  {
    "uniprot": "Q4DY16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY16"
  },
  {
    "uniprot": "Q4DG27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG27"
  },
  {
    "uniprot": "Q4D1A9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1A9"
  },
  {
    "uniprot": "Q4DR56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR56"
  },
  {
    "uniprot": "Q4D0Y2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Y2"
  },
  {
    "uniprot": "Q4E376",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E376"
  },
  {
    "uniprot": "Q4E5T2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5T2"
  },
  {
    "uniprot": "Q4D5B4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5B4"
  },
  {
    "uniprot": "Q4CLW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLW2"
  },
  {
    "uniprot": "Q4DEH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEH7"
  },
  {
    "uniprot": "Q4DUL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUL7"
  },
  {
    "uniprot": "Q4DYV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYV3"
  },
  {
    "uniprot": "Q4CXS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXS1"
  },
  {
    "uniprot": "Q4D957",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D957"
  },
  {
    "uniprot": "Q4CX65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX65"
  },
  {
    "uniprot": "Q4CXI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXI4"
  },
  {
    "uniprot": "Q4CUC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUC0"
  },
  {
    "uniprot": "Q4CQJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQJ5"
  },
  {
    "uniprot": "Q4D665",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D665"
  },
  {
    "uniprot": "Q4DMK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMK0"
  },
  {
    "uniprot": "Q4DLJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLJ8"
  },
  {
    "uniprot": "Q4E524",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E524"
  },
  {
    "uniprot": "Q4D2K2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2K2"
  },
  {
    "uniprot": "Q4DY38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY38"
  },
  {
    "uniprot": "Q4DXL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXL7"
  },
  {
    "uniprot": "Q4DEF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEF4"
  },
  {
    "uniprot": "Q4DSE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSE5"
  },
  {
    "uniprot": "Q4CMS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMS0"
  },
  {
    "uniprot": "Q4DN68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN68"
  },
  {
    "uniprot": "Q4E1R3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1R3"
  },
  {
    "uniprot": "Q4DG20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG20"
  },
  {
    "uniprot": "Q4DZA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZA9"
  },
  {
    "uniprot": "Q4DS61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS61"
  },
  {
    "uniprot": "Q4DML9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DML9"
  },
  {
    "uniprot": "Q4DF93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF93"
  },
  {
    "uniprot": "Q4DBU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBU2"
  },
  {
    "uniprot": "Q4DB89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB89"
  },
  {
    "uniprot": "Q4D0W3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0W3"
  },
  {
    "uniprot": "Q4D7H2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7H2"
  },
  {
    "uniprot": "Q4DP58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP58"
  },
  {
    "uniprot": "Q4DPM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPM3"
  },
  {
    "uniprot": "Q4DMM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMM8"
  },
  {
    "uniprot": "Q4DBJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBJ9"
  },
  {
    "uniprot": "Q4D3V9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3V9"
  },
  {
    "uniprot": "Q4CM25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM25"
  },
  {
    "uniprot": "Q4DGK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGK7"
  },
  {
    "uniprot": "Q4DFG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFG3"
  },
  {
    "uniprot": "Q4CR31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR31"
  },
  {
    "uniprot": "Q4CKK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKK6"
  },
  {
    "uniprot": "Q4CU87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU87"
  },
  {
    "uniprot": "Q4DYH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYH2"
  },
  {
    "uniprot": "Q4E2D0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2D0"
  },
  {
    "uniprot": "Q4DBW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBW1"
  },
  {
    "uniprot": "Q4DHU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHU9"
  },
  {
    "uniprot": "Q4E1W1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1W1"
  },
  {
    "uniprot": "Q4DLQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLQ5"
  },
  {
    "uniprot": "Q4DCA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCA3"
  },
  {
    "uniprot": "Q4DDL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDL4"
  },
  {
    "uniprot": "Q4DJU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJU5"
  },
  {
    "uniprot": "Q4D260",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D260"
  },
  {
    "uniprot": "Q4D970",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D970"
  },
  {
    "uniprot": "Q4E2C1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2C1"
  },
  {
    "uniprot": "Q4E1B1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1B1"
  },
  {
    "uniprot": "Q4DQD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQD9"
  },
  {
    "uniprot": "Q4D7V7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7V7"
  },
  {
    "uniprot": "Q4DZA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZA5"
  },
  {
    "uniprot": "Q4DLJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLJ3"
  },
  {
    "uniprot": "Q4D4K1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4K1"
  },
  {
    "uniprot": "Q4CRA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRA4"
  },
  {
    "uniprot": "Q4DFC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFC1"
  },
  {
    "uniprot": "Q4CP32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP32"
  },
  {
    "uniprot": "Q4CLH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLH9"
  },
  {
    "uniprot": "Q4DLX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLX5"
  },
  {
    "uniprot": "Q4D350",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D350"
  },
  {
    "uniprot": "Q4D8X5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8X5"
  },
  {
    "uniprot": "Q4DIS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIS2"
  },
  {
    "uniprot": "Q4CMM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMM9"
  },
  {
    "uniprot": "Q4CP54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP54"
  },
  {
    "uniprot": "Q4DKP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKP0"
  },
  {
    "uniprot": "Q4DNV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNV7"
  },
  {
    "uniprot": "Q4CTD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTD7"
  },
  {
    "uniprot": "Q4DZJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZJ0"
  },
  {
    "uniprot": "Q4D1A7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1A7"
  },
  {
    "uniprot": "Q4D152",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D152"
  },
  {
    "uniprot": "Q4DP88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP88"
  },
  {
    "uniprot": "Q4DQ15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ15"
  },
  {
    "uniprot": "Q4E4G9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4G9"
  },
  {
    "uniprot": "Q4DNP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNP8"
  },
  {
    "uniprot": "Q4CXL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXL6"
  },
  {
    "uniprot": "Q4DHM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHM3"
  },
  {
    "uniprot": "Q4CKF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKF6"
  },
  {
    "uniprot": "Q4CQ14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ14"
  },
  {
    "uniprot": "Q4DV22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV22"
  },
  {
    "uniprot": "Q4D3G3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3G3"
  },
  {
    "uniprot": "Q4CY67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY67"
  },
  {
    "uniprot": "Q4DC02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC02"
  },
  {
    "uniprot": "Q4CXU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXU1"
  },
  {
    "uniprot": "Q4D398",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D398"
  },
  {
    "uniprot": "Q4D3D0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3D0"
  },
  {
    "uniprot": "Q4CP88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP88"
  },
  {
    "uniprot": "Q4DFP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFP0"
  },
  {
    "uniprot": "Q4D2W7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2W7"
  },
  {
    "uniprot": "Q4CPX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPX1"
  },
  {
    "uniprot": "Q4DY10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY10"
  },
  {
    "uniprot": "Q4CWU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWU2"
  },
  {
    "uniprot": "Q4DF44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF44"
  },
  {
    "uniprot": "Q4DX97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX97"
  },
  {
    "uniprot": "Q4DSE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSE1"
  },
  {
    "uniprot": "Q4D5A6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5A6"
  },
  {
    "uniprot": "Q4CVR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVR4"
  },
  {
    "uniprot": "Q4DKE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKE8"
  },
  {
    "uniprot": "Q4E580",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E580"
  },
  {
    "uniprot": "Q4DAY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAY9"
  },
  {
    "uniprot": "Q4E0V0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0V0"
  },
  {
    "uniprot": "Q4DFE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFE5"
  },
  {
    "uniprot": "Q4CZA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZA5"
  },
  {
    "uniprot": "Q4CR92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR92"
  },
  {
    "uniprot": "Q4CKR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKR9"
  },
  {
    "uniprot": "Q4CMQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMQ0"
  },
  {
    "uniprot": "Q4DEP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEP5"
  },
  {
    "uniprot": "Q4DN08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN08"
  },
  {
    "uniprot": "Q4DZC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZC0"
  },
  {
    "uniprot": "Q4E2J2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2J2"
  },
  {
    "uniprot": "Q4D6C9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6C9"
  },
  {
    "uniprot": "Q4DG48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG48"
  },
  {
    "uniprot": "Q4DI44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI44"
  },
  {
    "uniprot": "Q4DQT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQT3"
  },
  {
    "uniprot": "Q4DHK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHK6"
  },
  {
    "uniprot": "Q4CXW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXW8"
  },
  {
    "uniprot": "Q4DP77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP77"
  },
  {
    "uniprot": "Q4E137",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E137"
  },
  {
    "uniprot": "Q4E4Y5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Y5"
  },
  {
    "uniprot": "Q4CTE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTE9"
  },
  {
    "uniprot": "Q4D6L0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6L0"
  },
  {
    "uniprot": "Q4E278",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E278"
  },
  {
    "uniprot": "Q4CN93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN93"
  },
  {
    "uniprot": "Q4DUS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUS4"
  },
  {
    "uniprot": "Q4E4Y3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Y3"
  },
  {
    "uniprot": "Q4E0U3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0U3"
  },
  {
    "uniprot": "Q4CQ02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ02"
  },
  {
    "uniprot": "Q4CKH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKH7"
  },
  {
    "uniprot": "Q4D413",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D413"
  },
  {
    "uniprot": "Q4DWN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWN4"
  },
  {
    "uniprot": "Q4DXU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXU9"
  },
  {
    "uniprot": "Q4DN02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN02"
  },
  {
    "uniprot": "Q4CLS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLS6"
  },
  {
    "uniprot": "Q4D6N4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6N4"
  },
  {
    "uniprot": "Q4E649",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E649"
  },
  {
    "uniprot": "Q4CP70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP70"
  },
  {
    "uniprot": "Q4DXK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXK6"
  },
  {
    "uniprot": "Q4D589",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D589"
  },
  {
    "uniprot": "Q4DWW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWW5"
  },
  {
    "uniprot": "Q4D6K0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6K0"
  },
  {
    "uniprot": "Q4E5V2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5V2"
  },
  {
    "uniprot": "Q4CTF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTF1"
  },
  {
    "uniprot": "Q4E670",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E670"
  },
  {
    "uniprot": "Q4CYY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYY2"
  },
  {
    "uniprot": "Q4DH96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH96"
  },
  {
    "uniprot": "Q4DVX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVX4"
  },
  {
    "uniprot": "Q4D9B1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9B1"
  },
  {
    "uniprot": "Q4DA09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA09"
  },
  {
    "uniprot": "Q4CM10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM10"
  },
  {
    "uniprot": "Q4E1K8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1K8"
  },
  {
    "uniprot": "Q4E3J3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3J3"
  },
  {
    "uniprot": "Q4DI80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI80"
  },
  {
    "uniprot": "Q4D663",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D663"
  },
  {
    "uniprot": "Q4CMI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMI3"
  },
  {
    "uniprot": "Q4DMI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMI2"
  },
  {
    "uniprot": "Q4DB81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB81"
  },
  {
    "uniprot": "Q4CTR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTR6"
  },
  {
    "uniprot": "Q4DVM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVM4"
  },
  {
    "uniprot": "Q4DLP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLP0"
  },
  {
    "uniprot": "Q4CS42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS42"
  },
  {
    "uniprot": "Q4CKQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKQ3"
  },
  {
    "uniprot": "Q4DR59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR59"
  },
  {
    "uniprot": "Q4DCW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCW3"
  },
  {
    "uniprot": "Q4E625",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E625"
  },
  {
    "uniprot": "Q4DVR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVR3"
  },
  {
    "uniprot": "Q4D3W7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3W7"
  },
  {
    "uniprot": "Q4DJZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJZ2"
  },
  {
    "uniprot": "Q4DGJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGJ9"
  },
  {
    "uniprot": "Q4CVK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVK6"
  },
  {
    "uniprot": "Q4CS82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS82"
  },
  {
    "uniprot": "Q4DWH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWH6"
  },
  {
    "uniprot": "Q4DAF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAF0"
  },
  {
    "uniprot": "Q4DYE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYE1"
  },
  {
    "uniprot": "Q4D363",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D363"
  },
  {
    "uniprot": "Q4E1Y2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Y2"
  },
  {
    "uniprot": "Q4DA45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA45"
  },
  {
    "uniprot": "Q4E3F7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3F7"
  },
  {
    "uniprot": "Q4DD59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD59"
  },
  {
    "uniprot": "Q4D9J5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9J5"
  },
  {
    "uniprot": "Q4E5A0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5A0"
  },
  {
    "uniprot": "Q4D1L6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1L6"
  },
  {
    "uniprot": "Q4DY08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY08"
  },
  {
    "uniprot": "Q4DS67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS67"
  },
  {
    "uniprot": "Q4D2J9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2J9"
  },
  {
    "uniprot": "Q4CZE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZE3"
  },
  {
    "uniprot": "Q4D5U1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5U1"
  },
  {
    "uniprot": "Q4D658",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D658"
  },
  {
    "uniprot": "Q4DHI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHI9"
  },
  {
    "uniprot": "Q4D1X2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1X2"
  },
  {
    "uniprot": "Q4DA57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA57"
  },
  {
    "uniprot": "Q4CMW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMW7"
  },
  {
    "uniprot": "Q4CLF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLF1"
  },
  {
    "uniprot": "Q4CP68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP68"
  },
  {
    "uniprot": "Q4CN28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN28"
  },
  {
    "uniprot": "Q4DUJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUJ0"
  },
  {
    "uniprot": "Q4DMZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMZ9"
  },
  {
    "uniprot": "Q4D650",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D650"
  },
  {
    "uniprot": "Q4CKQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKQ1"
  },
  {
    "uniprot": "Q4D3U1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3U1"
  },
  {
    "uniprot": "Q4DDG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDG0"
  },
  {
    "uniprot": "Q4CNB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNB2"
  },
  {
    "uniprot": "Q4D249",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D249"
  },
  {
    "uniprot": "Q4DGV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGV4"
  },
  {
    "uniprot": "Q4DCM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCM5"
  },
  {
    "uniprot": "Q4D3J7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3J7"
  },
  {
    "uniprot": "Q4E2G0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2G0"
  },
  {
    "uniprot": "Q4DX37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX37"
  },
  {
    "uniprot": "Q4CSM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSM3"
  },
  {
    "uniprot": "Q4CWJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWJ2"
  },
  {
    "uniprot": "Q4DJM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJM2"
  },
  {
    "uniprot": "Q4DNY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNY8"
  },
  {
    "uniprot": "Q4E656",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E656"
  },
  {
    "uniprot": "Q4DKT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKT5"
  },
  {
    "uniprot": "Q4DCV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCV5"
  },
  {
    "uniprot": "Q4E5K7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5K7"
  },
  {
    "uniprot": "Q4CNR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNR4"
  },
  {
    "uniprot": "Q4D3J0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3J0"
  },
  {
    "uniprot": "Q4DFX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFX4"
  },
  {
    "uniprot": "Q4DNY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNY7"
  },
  {
    "uniprot": "Q4DTW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTW7"
  },
  {
    "uniprot": "Q4CNB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNB8"
  },
  {
    "uniprot": "Q4D9U4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9U4"
  },
  {
    "uniprot": "Q4CV29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV29"
  },
  {
    "uniprot": "Q4CWE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWE3"
  },
  {
    "uniprot": "Q4CM38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM38"
  },
  {
    "uniprot": "Q4DNS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNS8"
  },
  {
    "uniprot": "Q4DAT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAT3"
  },
  {
    "uniprot": "Q4CQ84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ84"
  },
  {
    "uniprot": "Q4DXR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXR9"
  },
  {
    "uniprot": "Q4CVP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVP0"
  },
  {
    "uniprot": "Q4CV42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV42"
  },
  {
    "uniprot": "Q4DPH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPH6"
  },
  {
    "uniprot": "Q4CVG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVG4"
  },
  {
    "uniprot": "Q4DH28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH28"
  },
  {
    "uniprot": "Q4E4X3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4X3"
  },
  {
    "uniprot": "Q4E036",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E036"
  },
  {
    "uniprot": "Q4CP51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP51"
  },
  {
    "uniprot": "Q4CQ76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ76"
  },
  {
    "uniprot": "Q4E088",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E088"
  },
  {
    "uniprot": "Q4D9I9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9I9"
  },
  {
    "uniprot": "Q4CQQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQQ7"
  },
  {
    "uniprot": "Q4D5E1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5E1"
  },
  {
    "uniprot": "Q4E4X9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4X9"
  },
  {
    "uniprot": "Q4CYR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYR7"
  },
  {
    "uniprot": "Q4DMZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMZ0"
  },
  {
    "uniprot": "Q4D7K1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7K1"
  },
  {
    "uniprot": "Q4CNK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNK6"
  },
  {
    "uniprot": "Q4CLJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLJ6"
  },
  {
    "uniprot": "Q4DML6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DML6"
  },
  {
    "uniprot": "Q4DNT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNT8"
  },
  {
    "uniprot": "Q4CNI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNI3"
  },
  {
    "uniprot": "Q4DMU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMU6"
  },
  {
    "uniprot": "Q4DTJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTJ7"
  },
  {
    "uniprot": "Q4D211",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D211"
  },
  {
    "uniprot": "Q4E1S5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1S5"
  },
  {
    "uniprot": "Q4CSC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSC2"
  },
  {
    "uniprot": "Q4CMX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMX2"
  },
  {
    "uniprot": "Q4CWI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWI3"
  },
  {
    "uniprot": "Q4D3S9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3S9"
  },
  {
    "uniprot": "Q4DZX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZX2"
  },
  {
    "uniprot": "Q4DNW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNW0"
  },
  {
    "uniprot": "Q4DM53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM53"
  },
  {
    "uniprot": "Q4DWP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWP5"
  },
  {
    "uniprot": "Q4DU32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU32"
  },
  {
    "uniprot": "Q4D5G3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5G3"
  },
  {
    "uniprot": "Q4D0C7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0C7"
  },
  {
    "uniprot": "Q4CQ51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ51"
  },
  {
    "uniprot": "Q4DVG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVG8"
  },
  {
    "uniprot": "Q4D7E0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7E0"
  },
  {
    "uniprot": "Q4DX81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX81"
  },
  {
    "uniprot": "Q4DEC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEC4"
  },
  {
    "uniprot": "Q4D8M5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8M5"
  },
  {
    "uniprot": "Q4CXH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXH6"
  },
  {
    "uniprot": "Q4DZ68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ68"
  },
  {
    "uniprot": "Q4E0R7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0R7"
  },
  {
    "uniprot": "Q4DW07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW07"
  },
  {
    "uniprot": "Q4D2X8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2X8"
  },
  {
    "uniprot": "Q4DVQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVQ1"
  },
  {
    "uniprot": "Q4D6N8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6N8"
  },
  {
    "uniprot": "Q4DEV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEV2"
  },
  {
    "uniprot": "Q4DKF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKF4"
  },
  {
    "uniprot": "Q4DF95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF95"
  },
  {
    "uniprot": "Q4D5D4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5D4"
  },
  {
    "uniprot": "Q4DI04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI04"
  },
  {
    "uniprot": "Q4D912",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D912"
  },
  {
    "uniprot": "Q4DGT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGT3"
  },
  {
    "uniprot": "Q4CVU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVU0"
  },
  {
    "uniprot": "Q4DMW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMW4"
  },
  {
    "uniprot": "Q4CP77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP77"
  },
  {
    "uniprot": "Q4DXH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXH6"
  },
  {
    "uniprot": "Q4DS86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS86"
  },
  {
    "uniprot": "Q4DD24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD24"
  },
  {
    "uniprot": "Q4E5Z7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Z7"
  },
  {
    "uniprot": "Q4CKD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKD0"
  },
  {
    "uniprot": "Q4DKL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKL8"
  },
  {
    "uniprot": "Q4DKQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKQ9"
  },
  {
    "uniprot": "Q4E3A9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3A9"
  },
  {
    "uniprot": "Q4DPQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPQ3"
  },
  {
    "uniprot": "Q4CYQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYQ2"
  },
  {
    "uniprot": "Q4CKW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKW7"
  },
  {
    "uniprot": "Q4CMC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMC5"
  },
  {
    "uniprot": "Q4CKY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKY9"
  },
  {
    "uniprot": "Q4DXG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXG2"
  },
  {
    "uniprot": "Q4E0D0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0D0"
  },
  {
    "uniprot": "Q4DL39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL39"
  },
  {
    "uniprot": "Q4CRH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRH7"
  },
  {
    "uniprot": "Q4CWF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWF3"
  },
  {
    "uniprot": "Q4CQD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQD2"
  },
  {
    "uniprot": "Q4CSB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSB0"
  },
  {
    "uniprot": "Q4DLE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLE5"
  },
  {
    "uniprot": "Q4CKY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKY2"
  },
  {
    "uniprot": "Q4D7T1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7T1"
  },
  {
    "uniprot": "Q4DVL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVL4"
  },
  {
    "uniprot": "Q4DBZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBZ2"
  },
  {
    "uniprot": "Q4D992",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D992"
  },
  {
    "uniprot": "Q4D713",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D713"
  },
  {
    "uniprot": "Q4E0A2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0A2"
  },
  {
    "uniprot": "Q4DIY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIY2"
  },
  {
    "uniprot": "Q4D895",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D895"
  },
  {
    "uniprot": "Q4CZP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZP0"
  },
  {
    "uniprot": "Q4DPS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPS2"
  },
  {
    "uniprot": "Q4DTC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTC4"
  },
  {
    "uniprot": "Q4CYD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYD5"
  },
  {
    "uniprot": "Q4DPF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPF4"
  },
  {
    "uniprot": "Q4CLF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLF9"
  },
  {
    "uniprot": "Q4DKT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKT9"
  },
  {
    "uniprot": "Q4CUM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUM7"
  },
  {
    "uniprot": "Q4CYE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYE3"
  },
  {
    "uniprot": "Q4DH31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH31"
  },
  {
    "uniprot": "Q4DNR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNR3"
  },
  {
    "uniprot": "Q4DX16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX16"
  },
  {
    "uniprot": "Q4CW05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW05"
  },
  {
    "uniprot": "Q4E251",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E251"
  },
  {
    "uniprot": "Q4D1I2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1I2"
  },
  {
    "uniprot": "Q4DKG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKG3"
  },
  {
    "uniprot": "Q4CT71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT71"
  },
  {
    "uniprot": "Q4DUF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUF6"
  },
  {
    "uniprot": "Q4CVR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVR2"
  },
  {
    "uniprot": "Q4CS73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS73"
  },
  {
    "uniprot": "Q4CZE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZE8"
  },
  {
    "uniprot": "Q4DV80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV80"
  },
  {
    "uniprot": "Q4CZL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZL4"
  },
  {
    "uniprot": "Q4DYH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYH1"
  },
  {
    "uniprot": "Q4DG06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG06"
  },
  {
    "uniprot": "Q4DSV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSV3"
  },
  {
    "uniprot": "Q4D518",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D518"
  },
  {
    "uniprot": "Q4DPU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPU8"
  },
  {
    "uniprot": "Q4D1Z6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Z6"
  },
  {
    "uniprot": "Q4D7W5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7W5"
  },
  {
    "uniprot": "Q4CR11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR11"
  },
  {
    "uniprot": "Q4DLU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLU3"
  },
  {
    "uniprot": "Q4DJ88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ88"
  },
  {
    "uniprot": "Q4CZI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZI1"
  },
  {
    "uniprot": "Q4DLM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLM2"
  },
  {
    "uniprot": "Q4CYN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYN1"
  },
  {
    "uniprot": "Q4DRE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRE0"
  },
  {
    "uniprot": "Q4DXY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXY6"
  },
  {
    "uniprot": "Q4CLQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLQ8"
  },
  {
    "uniprot": "Q4CXX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXX8"
  },
  {
    "uniprot": "Q4DS16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS16"
  },
  {
    "uniprot": "Q4DUC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUC2"
  },
  {
    "uniprot": "Q4E1W5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1W5"
  },
  {
    "uniprot": "Q4CUL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUL1"
  },
  {
    "uniprot": "Q4E380",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E380"
  },
  {
    "uniprot": "Q4E0Y2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Y2"
  },
  {
    "uniprot": "Q4E1A1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1A1"
  },
  {
    "uniprot": "Q4E669",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E669"
  },
  {
    "uniprot": "Q4CM74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM74"
  },
  {
    "uniprot": "Q4CTG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTG4"
  },
  {
    "uniprot": "Q4D818",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D818"
  },
  {
    "uniprot": "Q4D5X4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5X4"
  },
  {
    "uniprot": "Q4DV98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV98"
  },
  {
    "uniprot": "Q4D1F2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1F2"
  },
  {
    "uniprot": "Q4D2B2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2B2"
  },
  {
    "uniprot": "Q4CTS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTS3"
  },
  {
    "uniprot": "Q4CKC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKC5"
  },
  {
    "uniprot": "Q4CQ09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ09"
  },
  {
    "uniprot": "Q4DGT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGT0"
  },
  {
    "uniprot": "Q4CTA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTA2"
  },
  {
    "uniprot": "Q4E0Q7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Q7"
  },
  {
    "uniprot": "Q4DD84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD84"
  },
  {
    "uniprot": "Q4CVD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVD9"
  },
  {
    "uniprot": "Q4E5R1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5R1"
  },
  {
    "uniprot": "Q4E2S9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2S9"
  },
  {
    "uniprot": "Q4DWS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWS7"
  },
  {
    "uniprot": "Q4CSD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSD2"
  },
  {
    "uniprot": "Q4D268",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D268"
  },
  {
    "uniprot": "Q4E3L0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3L0"
  },
  {
    "uniprot": "Q4D630",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D630"
  },
  {
    "uniprot": "Q4CQ66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ66"
  },
  {
    "uniprot": "Q4DYD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYD0"
  },
  {
    "uniprot": "Q4DYX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYX8"
  },
  {
    "uniprot": "Q4CWN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWN0"
  },
  {
    "uniprot": "Q4DAQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAQ7"
  },
  {
    "uniprot": "Q4E1Y0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Y0"
  },
  {
    "uniprot": "Q4D7N7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7N7"
  },
  {
    "uniprot": "Q4DAG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAG9"
  },
  {
    "uniprot": "Q4DU87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU87"
  },
  {
    "uniprot": "Q4CMH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMH9"
  },
  {
    "uniprot": "Q4DUC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUC5"
  },
  {
    "uniprot": "Q4E218",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E218"
  },
  {
    "uniprot": "Q4E5A4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5A4"
  },
  {
    "uniprot": "Q4E5F8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5F8"
  },
  {
    "uniprot": "Q4CPY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPY3"
  },
  {
    "uniprot": "Q4DZX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZX4"
  },
  {
    "uniprot": "Q4DRI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRI6"
  },
  {
    "uniprot": "Q4CP05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP05"
  },
  {
    "uniprot": "Q4CT08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT08"
  },
  {
    "uniprot": "Q4D0F3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0F3"
  },
  {
    "uniprot": "Q4DJ17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ17"
  },
  {
    "uniprot": "Q4DDL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDL8"
  },
  {
    "uniprot": "Q4D769",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D769"
  },
  {
    "uniprot": "Q4DWC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWC8"
  },
  {
    "uniprot": "Q4E1M8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1M8"
  },
  {
    "uniprot": "Q4DBE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBE9"
  },
  {
    "uniprot": "Q4DJI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJI2"
  },
  {
    "uniprot": "Q4DAS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAS8"
  },
  {
    "uniprot": "Q4CM75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM75"
  },
  {
    "uniprot": "Q4DH81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH81"
  },
  {
    "uniprot": "Q4CM98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM98"
  },
  {
    "uniprot": "Q4CVT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVT1"
  },
  {
    "uniprot": "Q4D396",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D396"
  },
  {
    "uniprot": "Q4CNQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNQ0"
  },
  {
    "uniprot": "Q4CSN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSN2"
  },
  {
    "uniprot": "Q4DBF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBF0"
  },
  {
    "uniprot": "Q4E517",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E517"
  },
  {
    "uniprot": "Q4CZ70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ70"
  },
  {
    "uniprot": "Q4DQG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQG4"
  },
  {
    "uniprot": "Q4DZQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZQ9"
  },
  {
    "uniprot": "Q4DIQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIQ5"
  },
  {
    "uniprot": "Q4DVB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVB3"
  },
  {
    "uniprot": "Q4DKN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKN6"
  },
  {
    "uniprot": "Q4DKP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKP5"
  },
  {
    "uniprot": "Q4E020",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E020"
  },
  {
    "uniprot": "Q4DLM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLM3"
  },
  {
    "uniprot": "Q4E1V7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1V7"
  },
  {
    "uniprot": "Q4DKI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKI1"
  },
  {
    "uniprot": "Q4CXC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXC8"
  },
  {
    "uniprot": "Q4D2A8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2A8"
  },
  {
    "uniprot": "Q4DGP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGP9"
  },
  {
    "uniprot": "Q4D3S2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3S2"
  },
  {
    "uniprot": "Q4CQR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQR7"
  },
  {
    "uniprot": "Q4E4J2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4J2"
  },
  {
    "uniprot": "Q4DGN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGN4"
  },
  {
    "uniprot": "Q4CS04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS04"
  },
  {
    "uniprot": "Q4E120",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E120"
  },
  {
    "uniprot": "Q4D4R6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4R6"
  },
  {
    "uniprot": "Q4DF35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF35"
  },
  {
    "uniprot": "Q4DS64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS64"
  },
  {
    "uniprot": "Q4DXU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXU4"
  },
  {
    "uniprot": "Q4D975",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D975"
  },
  {
    "uniprot": "Q4CZN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZN3"
  },
  {
    "uniprot": "Q4CLC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLC3"
  },
  {
    "uniprot": "Q4CTY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTY2"
  },
  {
    "uniprot": "Q4CKB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKB8"
  },
  {
    "uniprot": "Q4DNQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNQ7"
  },
  {
    "uniprot": "Q4D9B7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9B7"
  },
  {
    "uniprot": "Q4DAT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAT5"
  },
  {
    "uniprot": "Q4CL28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL28"
  },
  {
    "uniprot": "Q4DIV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIV3"
  },
  {
    "uniprot": "Q4CL39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL39"
  },
  {
    "uniprot": "Q4DMC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMC1"
  },
  {
    "uniprot": "Q4DG96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG96"
  },
  {
    "uniprot": "Q4E0L0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0L0"
  },
  {
    "uniprot": "Q4DTE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTE5"
  },
  {
    "uniprot": "Q4DJ65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ65"
  },
  {
    "uniprot": "Q4E2D8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2D8"
  },
  {
    "uniprot": "Q4DYN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYN9"
  },
  {
    "uniprot": "Q4D813",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D813"
  },
  {
    "uniprot": "Q4E0H1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0H1"
  },
  {
    "uniprot": "Q4D3E4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3E4"
  },
  {
    "uniprot": "Q4DRQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRQ9"
  },
  {
    "uniprot": "Q4DN09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN09"
  },
  {
    "uniprot": "Q4DVL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVL1"
  },
  {
    "uniprot": "Q4CYZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYZ1"
  },
  {
    "uniprot": "Q4CST6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CST6"
  },
  {
    "uniprot": "Q4CZF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZF3"
  },
  {
    "uniprot": "Q4DXA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXA1"
  },
  {
    "uniprot": "Q4DH06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH06"
  },
  {
    "uniprot": "Q4DIQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIQ9"
  },
  {
    "uniprot": "Q4D8E8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8E8"
  },
  {
    "uniprot": "Q4CUP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUP1"
  },
  {
    "uniprot": "Q4E4K1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4K1"
  },
  {
    "uniprot": "Q4DID6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DID6"
  },
  {
    "uniprot": "Q4E4Q2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Q2"
  },
  {
    "uniprot": "Q4E4A0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4A0"
  },
  {
    "uniprot": "Q4DF29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF29"
  },
  {
    "uniprot": "Q4E4Z3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Z3"
  },
  {
    "uniprot": "Q4DN31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN31"
  },
  {
    "uniprot": "Q4CRJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRJ6"
  },
  {
    "uniprot": "Q4E3S0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3S0"
  },
  {
    "uniprot": "Q4DHJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHJ6"
  },
  {
    "uniprot": "Q4DT28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT28"
  },
  {
    "uniprot": "Q4DEQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEQ6"
  },
  {
    "uniprot": "Q4DW25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW25"
  },
  {
    "uniprot": "Q4CVZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVZ0"
  },
  {
    "uniprot": "Q4DQJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQJ0"
  },
  {
    "uniprot": "Q4DPK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPK8"
  },
  {
    "uniprot": "Q4D0R3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0R3"
  },
  {
    "uniprot": "Q4E1F7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1F7"
  },
  {
    "uniprot": "Q4CNK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNK8"
  },
  {
    "uniprot": "Q4D4E1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4E1"
  },
  {
    "uniprot": "Q4E3Y1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Y1"
  },
  {
    "uniprot": "Q4DIC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIC7"
  },
  {
    "uniprot": "Q4D979",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D979"
  },
  {
    "uniprot": "Q4E4T9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4T9"
  },
  {
    "uniprot": "Q4DJH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJH9"
  },
  {
    "uniprot": "Q4CKX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKX0"
  },
  {
    "uniprot": "Q4DVR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVR0"
  },
  {
    "uniprot": "Q4CPT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPT6"
  },
  {
    "uniprot": "Q4CXU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXU9"
  },
  {
    "uniprot": "Q4D3T7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3T7"
  },
  {
    "uniprot": "Q4E209",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E209"
  },
  {
    "uniprot": "Q4CT28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT28"
  },
  {
    "uniprot": "Q4DEQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEQ9"
  },
  {
    "uniprot": "Q4D434",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D434"
  },
  {
    "uniprot": "Q4D0R7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0R7"
  },
  {
    "uniprot": "Q4CTE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTE2"
  },
  {
    "uniprot": "Q4CV19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV19"
  },
  {
    "uniprot": "Q4DAS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAS9"
  },
  {
    "uniprot": "Q4CLC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLC8"
  },
  {
    "uniprot": "Q4D5H4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5H4"
  },
  {
    "uniprot": "Q4DEU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEU3"
  },
  {
    "uniprot": "Q4D2P7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2P7"
  },
  {
    "uniprot": "Q4CL46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL46"
  },
  {
    "uniprot": "Q4D7Z0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Z0"
  },
  {
    "uniprot": "Q4DET4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DET4"
  },
  {
    "uniprot": "Q4DWK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWK4"
  },
  {
    "uniprot": "Q4DMS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMS1"
  },
  {
    "uniprot": "Q4D308",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D308"
  },
  {
    "uniprot": "Q4D0S4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0S4"
  },
  {
    "uniprot": "Q4DWL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWL7"
  },
  {
    "uniprot": "Q4D9M9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9M9"
  },
  {
    "uniprot": "Q4DJB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJB1"
  },
  {
    "uniprot": "Q4CQY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQY0"
  },
  {
    "uniprot": "Q4DN28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN28"
  },
  {
    "uniprot": "Q4DIL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIL2"
  },
  {
    "uniprot": "Q4DLT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLT1"
  },
  {
    "uniprot": "Q4D015",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D015"
  },
  {
    "uniprot": "Q4D4J7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4J7"
  },
  {
    "uniprot": "Q4CSU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSU0"
  },
  {
    "uniprot": "Q4CLS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLS8"
  },
  {
    "uniprot": "Q4DZU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZU0"
  },
  {
    "uniprot": "Q4E2M4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2M4"
  },
  {
    "uniprot": "Q4E2W9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2W9"
  },
  {
    "uniprot": "Q4CRX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRX0"
  },
  {
    "uniprot": "Q4D4Y5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Y5"
  },
  {
    "uniprot": "Q4DIT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIT8"
  },
  {
    "uniprot": "Q4CXC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXC5"
  },
  {
    "uniprot": "Q4DL57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL57"
  },
  {
    "uniprot": "Q4D6Y5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Y5"
  },
  {
    "uniprot": "Q4CW84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW84"
  },
  {
    "uniprot": "Q4DP87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP87"
  },
  {
    "uniprot": "Q4CTR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTR0"
  },
  {
    "uniprot": "Q4DCS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCS4"
  },
  {
    "uniprot": "Q4CTB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTB7"
  },
  {
    "uniprot": "Q4DN00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN00"
  },
  {
    "uniprot": "Q4DT54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT54"
  },
  {
    "uniprot": "Q4DYA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYA2"
  },
  {
    "uniprot": "Q4CYR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYR2"
  },
  {
    "uniprot": "Q4DHN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHN7"
  },
  {
    "uniprot": "Q4DMV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMV6"
  },
  {
    "uniprot": "Q4DKH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKH6"
  },
  {
    "uniprot": "Q4E2V0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2V0"
  },
  {
    "uniprot": "Q4CTH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTH1"
  },
  {
    "uniprot": "Q4DES2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DES2"
  },
  {
    "uniprot": "Q4DEJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEJ3"
  },
  {
    "uniprot": "Q4CSA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSA7"
  },
  {
    "uniprot": "Q4DE97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE97"
  },
  {
    "uniprot": "Q4DWK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWK6"
  },
  {
    "uniprot": "Q4CQE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQE5"
  },
  {
    "uniprot": "Q4CU02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU02"
  },
  {
    "uniprot": "Q4CMT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMT9"
  },
  {
    "uniprot": "Q4D0X7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0X7"
  },
  {
    "uniprot": "Q4D7R3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7R3"
  },
  {
    "uniprot": "Q4DDU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDU5"
  },
  {
    "uniprot": "Q4CSI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSI8"
  },
  {
    "uniprot": "Q4D8J6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8J6"
  },
  {
    "uniprot": "Q4DMZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMZ4"
  },
  {
    "uniprot": "Q4CQK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQK1"
  },
  {
    "uniprot": "Q4DAK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAK1"
  },
  {
    "uniprot": "Q4DP67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP67"
  },
  {
    "uniprot": "Q4CXA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXA2"
  },
  {
    "uniprot": "Q4DZD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZD7"
  },
  {
    "uniprot": "Q4DPP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPP0"
  },
  {
    "uniprot": "Q4D4E8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4E8"
  },
  {
    "uniprot": "Q4DW43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW43"
  },
  {
    "uniprot": "Q4DJK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJK1"
  },
  {
    "uniprot": "Q4DIH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIH1"
  },
  {
    "uniprot": "Q4CUA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUA5"
  },
  {
    "uniprot": "Q4CUL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUL6"
  },
  {
    "uniprot": "Q4CZR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZR4"
  },
  {
    "uniprot": "Q4DJN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJN9"
  },
  {
    "uniprot": "Q4CWK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWK6"
  },
  {
    "uniprot": "Q4E4N9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4N9"
  },
  {
    "uniprot": "Q4CNV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNV9"
  },
  {
    "uniprot": "Q4DSW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSW5"
  },
  {
    "uniprot": "Q4CNY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNY7"
  },
  {
    "uniprot": "Q4D1E6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1E6"
  },
  {
    "uniprot": "Q4DZI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZI4"
  },
  {
    "uniprot": "Q4DMI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMI0"
  },
  {
    "uniprot": "Q4DFR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFR1"
  },
  {
    "uniprot": "Q4D3N7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3N7"
  },
  {
    "uniprot": "Q4DHY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHY3"
  },
  {
    "uniprot": "Q4CWW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWW1"
  },
  {
    "uniprot": "Q4DRW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRW6"
  },
  {
    "uniprot": "Q4CS60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS60"
  },
  {
    "uniprot": "Q4E616",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E616"
  },
  {
    "uniprot": "Q4D0M6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0M6"
  },
  {
    "uniprot": "Q4DRM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRM3"
  },
  {
    "uniprot": "Q4CMF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMF8"
  },
  {
    "uniprot": "Q4E4M5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4M5"
  },
  {
    "uniprot": "Q4DA07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA07"
  },
  {
    "uniprot": "Q4D845",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D845"
  },
  {
    "uniprot": "Q4E447",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E447"
  },
  {
    "uniprot": "Q4D491",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D491"
  },
  {
    "uniprot": "Q4D817",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D817"
  },
  {
    "uniprot": "Q4DUE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUE0"
  },
  {
    "uniprot": "Q4D7D7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7D7"
  },
  {
    "uniprot": "Q4DPB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPB7"
  },
  {
    "uniprot": "Q4CP72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP72"
  },
  {
    "uniprot": "Q4DI85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI85"
  },
  {
    "uniprot": "Q4DX65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX65"
  },
  {
    "uniprot": "Q4CQ71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ71"
  },
  {
    "uniprot": "Q4DYA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYA1"
  },
  {
    "uniprot": "Q4CUA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUA6"
  },
  {
    "uniprot": "Q4CRD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRD6"
  },
  {
    "uniprot": "Q4CL60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL60"
  },
  {
    "uniprot": "Q4DYW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYW9"
  },
  {
    "uniprot": "Q4DYV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYV5"
  },
  {
    "uniprot": "Q4CUM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUM2"
  },
  {
    "uniprot": "Q4D1D6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1D6"
  },
  {
    "uniprot": "Q4DMN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMN3"
  },
  {
    "uniprot": "Q4D157",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D157"
  },
  {
    "uniprot": "Q4CZR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZR1"
  },
  {
    "uniprot": "Q4D661",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D661"
  },
  {
    "uniprot": "Q4D2T0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2T0"
  },
  {
    "uniprot": "Q4CYB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYB4"
  },
  {
    "uniprot": "Q4DU07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU07"
  },
  {
    "uniprot": "Q4DMJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMJ1"
  },
  {
    "uniprot": "Q4DEK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEK8"
  },
  {
    "uniprot": "Q4DR76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR76"
  },
  {
    "uniprot": "Q4DHS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHS5"
  },
  {
    "uniprot": "Q4DIU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIU9"
  },
  {
    "uniprot": "Q4DRY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRY9"
  },
  {
    "uniprot": "Q4CKX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKX2"
  },
  {
    "uniprot": "Q4DGT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGT8"
  },
  {
    "uniprot": "Q4E2L7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2L7"
  },
  {
    "uniprot": "Q4DSL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSL6"
  },
  {
    "uniprot": "Q4D8P0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8P0"
  },
  {
    "uniprot": "Q4DZ69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ69"
  },
  {
    "uniprot": "Q4DX20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX20"
  },
  {
    "uniprot": "Q4DR28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR28"
  },
  {
    "uniprot": "Q4CW72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW72"
  },
  {
    "uniprot": "Q4CVB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVB4"
  },
  {
    "uniprot": "Q4DQZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQZ2"
  },
  {
    "uniprot": "Q4E332",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E332"
  },
  {
    "uniprot": "Q4DV26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV26"
  },
  {
    "uniprot": "Q4DLI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLI8"
  },
  {
    "uniprot": "Q4DKE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKE7"
  },
  {
    "uniprot": "Q4CRE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRE2"
  },
  {
    "uniprot": "Q4E4G5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4G5"
  },
  {
    "uniprot": "Q4DVC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVC4"
  },
  {
    "uniprot": "Q4D7J4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7J4"
  },
  {
    "uniprot": "Q4D7U1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7U1"
  },
  {
    "uniprot": "Q4D9L3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9L3"
  },
  {
    "uniprot": "Q4DW91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW91"
  },
  {
    "uniprot": "Q4DBB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBB5"
  },
  {
    "uniprot": "Q4CYD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYD3"
  },
  {
    "uniprot": "Q4DZJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZJ4"
  },
  {
    "uniprot": "Q4CQC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQC5"
  },
  {
    "uniprot": "Q4D2I6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2I6"
  },
  {
    "uniprot": "Q4CU28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU28"
  },
  {
    "uniprot": "Q4D356",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D356"
  },
  {
    "uniprot": "Q4CPS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPS8"
  },
  {
    "uniprot": "Q4DIQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIQ6"
  },
  {
    "uniprot": "Q4DFT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFT5"
  },
  {
    "uniprot": "Q4DIK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIK2"
  },
  {
    "uniprot": "Q4D4V7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4V7"
  },
  {
    "uniprot": "Q4E453",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E453"
  },
  {
    "uniprot": "Q4DH94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH94"
  },
  {
    "uniprot": "Q4E4G8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4G8"
  },
  {
    "uniprot": "Q4DIJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIJ4"
  },
  {
    "uniprot": "Q4CVD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVD5"
  },
  {
    "uniprot": "Q4DQM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQM0"
  },
  {
    "uniprot": "Q4CZ78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ78"
  },
  {
    "uniprot": "Q4DM33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM33"
  },
  {
    "uniprot": "Q4DQY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQY4"
  },
  {
    "uniprot": "Q4DGA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGA2"
  },
  {
    "uniprot": "Q4DVR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVR1"
  },
  {
    "uniprot": "Q4DHS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHS1"
  },
  {
    "uniprot": "Q4DZ96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ96"
  },
  {
    "uniprot": "Q4DAP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAP3"
  },
  {
    "uniprot": "Q4DNW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNW3"
  },
  {
    "uniprot": "Q4DAZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAZ4"
  },
  {
    "uniprot": "Q4D1Q0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Q0"
  },
  {
    "uniprot": "Q4DZG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZG5"
  },
  {
    "uniprot": "Q4DL14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL14"
  },
  {
    "uniprot": "Q4D1W9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1W9"
  },
  {
    "uniprot": "Q4D5J2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5J2"
  },
  {
    "uniprot": "Q4DFE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFE9"
  },
  {
    "uniprot": "Q4CWX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWX2"
  },
  {
    "uniprot": "Q4E1X7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1X7"
  },
  {
    "uniprot": "Q4CU42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU42"
  },
  {
    "uniprot": "Q4CXB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXB9"
  },
  {
    "uniprot": "Q4CZ81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ81"
  },
  {
    "uniprot": "Q4DMA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMA0"
  },
  {
    "uniprot": "Q4D591",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D591"
  },
  {
    "uniprot": "Q4DZ86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ86"
  },
  {
    "uniprot": "Q4CU99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU99"
  },
  {
    "uniprot": "Q4DIS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIS9"
  },
  {
    "uniprot": "Q4CZ42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ42"
  },
  {
    "uniprot": "Q4E0H0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0H0"
  },
  {
    "uniprot": "Q4D096",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D096"
  },
  {
    "uniprot": "Q4DM40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM40"
  },
  {
    "uniprot": "Q4CPU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPU2"
  },
  {
    "uniprot": "Q4E485",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E485"
  },
  {
    "uniprot": "Q4DCM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCM1"
  },
  {
    "uniprot": "Q4D9B5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9B5"
  },
  {
    "uniprot": "Q4D9T9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9T9"
  },
  {
    "uniprot": "Q4CLG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLG1"
  },
  {
    "uniprot": "Q4DQM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQM4"
  },
  {
    "uniprot": "Q4DTQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTQ6"
  },
  {
    "uniprot": "Q4DG58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG58"
  },
  {
    "uniprot": "Q4DFP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFP9"
  },
  {
    "uniprot": "Q4DCK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCK1"
  },
  {
    "uniprot": "Q4CVV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVV7"
  },
  {
    "uniprot": "Q4DZY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZY4"
  },
  {
    "uniprot": "Q4DHP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHP3"
  },
  {
    "uniprot": "Q4DNS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNS4"
  },
  {
    "uniprot": "Q4DIA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIA6"
  },
  {
    "uniprot": "Q4DKK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKK8"
  },
  {
    "uniprot": "Q4CW46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW46"
  },
  {
    "uniprot": "Q4CTC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTC2"
  },
  {
    "uniprot": "Q4DTU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTU1"
  },
  {
    "uniprot": "Q4DNM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNM4"
  },
  {
    "uniprot": "Q4D6U8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6U8"
  },
  {
    "uniprot": "Q4CPT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPT0"
  },
  {
    "uniprot": "Q4CS68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS68"
  },
  {
    "uniprot": "Q4D1J8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1J8"
  },
  {
    "uniprot": "Q4D2M4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2M4"
  },
  {
    "uniprot": "Q4DPS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPS5"
  },
  {
    "uniprot": "Q4CYZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYZ9"
  },
  {
    "uniprot": "Q4DWQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWQ7"
  },
  {
    "uniprot": "Q4D8L1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8L1"
  },
  {
    "uniprot": "Q4DF43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF43"
  },
  {
    "uniprot": "Q4DSU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSU5"
  },
  {
    "uniprot": "Q4CKW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKW3"
  },
  {
    "uniprot": "Q4CLF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLF8"
  },
  {
    "uniprot": "Q4E140",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E140"
  },
  {
    "uniprot": "Q4DBT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBT8"
  },
  {
    "uniprot": "Q4D3W8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3W8"
  },
  {
    "uniprot": "Q4E3Z0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Z0"
  },
  {
    "uniprot": "Q4E639",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E639"
  },
  {
    "uniprot": "Q4E1N4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1N4"
  },
  {
    "uniprot": "Q4D9V9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9V9"
  },
  {
    "uniprot": "Q4DFS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFS7"
  },
  {
    "uniprot": "Q4D9L8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9L8"
  },
  {
    "uniprot": "Q4DXR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXR3"
  },
  {
    "uniprot": "Q4DIR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIR9"
  },
  {
    "uniprot": "Q4CX04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX04"
  },
  {
    "uniprot": "Q4D3R5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3R5"
  },
  {
    "uniprot": "Q4DCT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCT4"
  },
  {
    "uniprot": "Q4E1S9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1S9"
  },
  {
    "uniprot": "Q4CPH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPH9"
  },
  {
    "uniprot": "Q4CRV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRV6"
  },
  {
    "uniprot": "Q4D038",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D038"
  },
  {
    "uniprot": "Q4DN14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN14"
  },
  {
    "uniprot": "Q4DHW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHW0"
  },
  {
    "uniprot": "Q4DRK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRK8"
  },
  {
    "uniprot": "Q4CUY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUY0"
  },
  {
    "uniprot": "Q4D7A9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7A9"
  },
  {
    "uniprot": "Q4E2D4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2D4"
  },
  {
    "uniprot": "Q4DXY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXY4"
  },
  {
    "uniprot": "Q4DTZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTZ1"
  },
  {
    "uniprot": "Q4D6P6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6P6"
  },
  {
    "uniprot": "Q4D3T3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3T3"
  },
  {
    "uniprot": "Q4CXQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXQ2"
  },
  {
    "uniprot": "Q4D339",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D339"
  },
  {
    "uniprot": "Q4CZL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZL2"
  },
  {
    "uniprot": "Q4DGW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGW6"
  },
  {
    "uniprot": "Q4CME9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CME9"
  },
  {
    "uniprot": "Q4DB59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB59"
  },
  {
    "uniprot": "Q4CL25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL25"
  },
  {
    "uniprot": "Q4CL56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL56"
  },
  {
    "uniprot": "Q4DR09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR09"
  },
  {
    "uniprot": "Q4CMU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMU2"
  },
  {
    "uniprot": "Q4DCY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCY2"
  },
  {
    "uniprot": "Q4D4N7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4N7"
  },
  {
    "uniprot": "Q4DDV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDV8"
  },
  {
    "uniprot": "Q4D0B7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0B7"
  },
  {
    "uniprot": "Q4CYI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYI1"
  },
  {
    "uniprot": "Q4CX14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX14"
  },
  {
    "uniprot": "Q4DXC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXC7"
  },
  {
    "uniprot": "Q4CV39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV39"
  },
  {
    "uniprot": "Q4DUG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUG0"
  },
  {
    "uniprot": "Q4CQB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQB1"
  },
  {
    "uniprot": "Q4DL00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL00"
  },
  {
    "uniprot": "Q4CPF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPF1"
  },
  {
    "uniprot": "Q4DIW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIW1"
  },
  {
    "uniprot": "Q4DDU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDU8"
  },
  {
    "uniprot": "Q4DRM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRM6"
  },
  {
    "uniprot": "Q4E3X9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3X9"
  },
  {
    "uniprot": "Q4CNV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNV5"
  },
  {
    "uniprot": "Q4DPP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPP8"
  },
  {
    "uniprot": "Q4CWI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWI9"
  },
  {
    "uniprot": "Q4DCK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCK5"
  },
  {
    "uniprot": "Q4CTQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTQ1"
  },
  {
    "uniprot": "Q4CX52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX52"
  },
  {
    "uniprot": "Q4CNG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNG5"
  },
  {
    "uniprot": "Q4DRY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRY1"
  },
  {
    "uniprot": "Q4D4K9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4K9"
  },
  {
    "uniprot": "Q4DZX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZX6"
  },
  {
    "uniprot": "Q4DI72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI72"
  },
  {
    "uniprot": "Q4D6Q9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Q9"
  },
  {
    "uniprot": "Q4D0R1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0R1"
  },
  {
    "uniprot": "Q4DFI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFI4"
  },
  {
    "uniprot": "Q4D4L0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4L0"
  },
  {
    "uniprot": "Q4DE15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE15"
  },
  {
    "uniprot": "Q4E133",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E133"
  },
  {
    "uniprot": "Q4DJQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJQ9"
  },
  {
    "uniprot": "Q4CRB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRB2"
  },
  {
    "uniprot": "Q4D0L3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0L3"
  },
  {
    "uniprot": "Q4DQB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQB1"
  },
  {
    "uniprot": "Q4CSS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSS4"
  },
  {
    "uniprot": "Q4CY65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY65"
  },
  {
    "uniprot": "Q4CSF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSF6"
  },
  {
    "uniprot": "Q4DRG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRG1"
  },
  {
    "uniprot": "Q4D811",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D811"
  },
  {
    "uniprot": "Q4DXQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXQ1"
  },
  {
    "uniprot": "Q4DHJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHJ7"
  },
  {
    "uniprot": "Q4E229",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E229"
  },
  {
    "uniprot": "Q4D6X5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6X5"
  },
  {
    "uniprot": "Q4CRQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRQ1"
  },
  {
    "uniprot": "Q4DW62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW62"
  },
  {
    "uniprot": "Q4CUE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUE3"
  },
  {
    "uniprot": "Q4DMY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMY5"
  },
  {
    "uniprot": "Q4CW92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW92"
  },
  {
    "uniprot": "Q4DBM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBM2"
  },
  {
    "uniprot": "Q4CRY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRY5"
  },
  {
    "uniprot": "Q4D6E8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6E8"
  },
  {
    "uniprot": "Q4DLC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLC0"
  },
  {
    "uniprot": "Q4CMI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMI4"
  },
  {
    "uniprot": "Q4D4M8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4M8"
  },
  {
    "uniprot": "Q4DWD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWD6"
  },
  {
    "uniprot": "Q4DUG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUG7"
  },
  {
    "uniprot": "Q4CSN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSN9"
  },
  {
    "uniprot": "Q4D025",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D025"
  },
  {
    "uniprot": "Q4CM55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM55"
  },
  {
    "uniprot": "Q4DJL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJL0"
  },
  {
    "uniprot": "Q4E1V6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1V6"
  },
  {
    "uniprot": "Q4DIL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIL9"
  },
  {
    "uniprot": "Q4DG34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG34"
  },
  {
    "uniprot": "Q4CPF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPF7"
  },
  {
    "uniprot": "Q4CWB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWB5"
  },
  {
    "uniprot": "Q4DGW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGW5"
  },
  {
    "uniprot": "Q4CR59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR59"
  },
  {
    "uniprot": "Q4DZ73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ73"
  },
  {
    "uniprot": "Q4DAJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAJ9"
  },
  {
    "uniprot": "Q4CUM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUM4"
  },
  {
    "uniprot": "Q4CKJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKJ7"
  },
  {
    "uniprot": "Q4E1E0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1E0"
  },
  {
    "uniprot": "Q4D1W1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1W1"
  },
  {
    "uniprot": "Q4D476",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D476"
  },
  {
    "uniprot": "Q4D8K2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8K2"
  },
  {
    "uniprot": "Q4DKR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKR8"
  },
  {
    "uniprot": "Q4D8R6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8R6"
  },
  {
    "uniprot": "Q4CXB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXB3"
  },
  {
    "uniprot": "Q4D4F6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4F6"
  },
  {
    "uniprot": "Q4CZZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZZ3"
  },
  {
    "uniprot": "Q4E3E9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3E9"
  },
  {
    "uniprot": "Q4CWE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWE0"
  },
  {
    "uniprot": "Q4E584",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E584"
  },
  {
    "uniprot": "Q4CSS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSS5"
  },
  {
    "uniprot": "Q4DW85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW85"
  },
  {
    "uniprot": "Q4DPQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPQ7"
  },
  {
    "uniprot": "Q4DDS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDS1"
  },
  {
    "uniprot": "Q4E5I0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5I0"
  },
  {
    "uniprot": "Q4CPX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPX2"
  },
  {
    "uniprot": "Q4DDR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDR7"
  },
  {
    "uniprot": "Q4DP93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP93"
  },
  {
    "uniprot": "Q4DJB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJB9"
  },
  {
    "uniprot": "Q4D865",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D865"
  },
  {
    "uniprot": "Q4E141",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E141"
  },
  {
    "uniprot": "Q4DFG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFG7"
  },
  {
    "uniprot": "Q4D5Y2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Y2"
  },
  {
    "uniprot": "Q4CQ03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ03"
  },
  {
    "uniprot": "Q4DRX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRX6"
  },
  {
    "uniprot": "Q4DFP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFP2"
  },
  {
    "uniprot": "Q4E0G2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0G2"
  },
  {
    "uniprot": "Q4CKB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKB6"
  },
  {
    "uniprot": "Q4DIZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIZ5"
  },
  {
    "uniprot": "Q4CU43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU43"
  },
  {
    "uniprot": "Q4D231",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D231"
  },
  {
    "uniprot": "Q4DVN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVN4"
  },
  {
    "uniprot": "Q4CRF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRF5"
  },
  {
    "uniprot": "Q4D1M1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1M1"
  },
  {
    "uniprot": "Q4DV61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV61"
  },
  {
    "uniprot": "Q4CPK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPK8"
  },
  {
    "uniprot": "Q4CYQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYQ4"
  },
  {
    "uniprot": "Q4DCG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCG2"
  },
  {
    "uniprot": "Q4DJG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJG2"
  },
  {
    "uniprot": "Q4DUG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUG3"
  },
  {
    "uniprot": "Q4DDH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDH9"
  },
  {
    "uniprot": "Q4DPU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPU7"
  },
  {
    "uniprot": "Q4DGW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGW4"
  },
  {
    "uniprot": "Q4D3I8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3I8"
  },
  {
    "uniprot": "Q4DSB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSB6"
  },
  {
    "uniprot": "Q4D380",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D380"
  },
  {
    "uniprot": "Q4CV53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV53"
  },
  {
    "uniprot": "Q4DCE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCE6"
  },
  {
    "uniprot": "Q4D2E5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2E5"
  },
  {
    "uniprot": "Q4DMA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMA4"
  },
  {
    "uniprot": "Q4CYJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYJ6"
  },
  {
    "uniprot": "Q4DWB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWB0"
  },
  {
    "uniprot": "Q4CPE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPE4"
  },
  {
    "uniprot": "Q4CMK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMK2"
  },
  {
    "uniprot": "Q4DTJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTJ8"
  },
  {
    "uniprot": "Q4DM55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM55"
  },
  {
    "uniprot": "Q4CPY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPY9"
  },
  {
    "uniprot": "Q4DYC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYC2"
  },
  {
    "uniprot": "Q4D4M5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4M5"
  },
  {
    "uniprot": "Q4E3Z5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Z5"
  },
  {
    "uniprot": "Q4DQJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQJ4"
  },
  {
    "uniprot": "Q4CNJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNJ3"
  },
  {
    "uniprot": "Q4DCT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCT2"
  },
  {
    "uniprot": "Q4DG85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG85"
  },
  {
    "uniprot": "Q4CN12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN12"
  },
  {
    "uniprot": "Q4DGH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGH1"
  },
  {
    "uniprot": "Q4DP60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP60"
  },
  {
    "uniprot": "Q4DZM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZM7"
  },
  {
    "uniprot": "Q4CQM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQM1"
  },
  {
    "uniprot": "Q4D9X5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9X5"
  },
  {
    "uniprot": "Q4CZM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZM9"
  },
  {
    "uniprot": "Q4DU98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU98"
  },
  {
    "uniprot": "Q4E5E3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5E3"
  },
  {
    "uniprot": "Q4D830",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D830"
  },
  {
    "uniprot": "Q4DA73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA73"
  },
  {
    "uniprot": "Q4CQT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQT2"
  },
  {
    "uniprot": "Q4DJY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJY7"
  },
  {
    "uniprot": "Q4DD23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD23"
  },
  {
    "uniprot": "Q4D5S1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5S1"
  },
  {
    "uniprot": "Q4E1I8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1I8"
  },
  {
    "uniprot": "Q4DEE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEE9"
  },
  {
    "uniprot": "Q4CVG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVG3"
  },
  {
    "uniprot": "Q4D501",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D501"
  },
  {
    "uniprot": "Q4D2B5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2B5"
  },
  {
    "uniprot": "Q4DG88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG88"
  },
  {
    "uniprot": "Q4E2T4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2T4"
  },
  {
    "uniprot": "Q4E0M3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0M3"
  },
  {
    "uniprot": "Q4DV02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV02"
  },
  {
    "uniprot": "Q4DI84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI84"
  },
  {
    "uniprot": "Q4DE71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE71"
  },
  {
    "uniprot": "Q4DM43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM43"
  },
  {
    "uniprot": "Q4CU49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU49"
  },
  {
    "uniprot": "Q4DYZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYZ6"
  },
  {
    "uniprot": "Q4DJT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJT5"
  },
  {
    "uniprot": "Q4DMN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMN2"
  },
  {
    "uniprot": "Q4E5C9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5C9"
  },
  {
    "uniprot": "Q4DBA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBA4"
  },
  {
    "uniprot": "Q4CP57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP57"
  },
  {
    "uniprot": "Q4CMI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMI6"
  },
  {
    "uniprot": "Q4DRU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRU3"
  },
  {
    "uniprot": "Q4CTN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTN0"
  },
  {
    "uniprot": "Q4D5N4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5N4"
  },
  {
    "uniprot": "Q4CW22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW22"
  },
  {
    "uniprot": "Q4E0R2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0R2"
  },
  {
    "uniprot": "Q4D6F7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6F7"
  },
  {
    "uniprot": "Q4DZY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZY8"
  },
  {
    "uniprot": "Q4DWU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWU8"
  },
  {
    "uniprot": "Q4D4H1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4H1"
  },
  {
    "uniprot": "Q4DYE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYE7"
  },
  {
    "uniprot": "Q4E3E5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3E5"
  },
  {
    "uniprot": "Q4CW30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW30"
  },
  {
    "uniprot": "Q4CS20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS20"
  },
  {
    "uniprot": "Q4D719",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D719"
  },
  {
    "uniprot": "Q4CZY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZY3"
  },
  {
    "uniprot": "Q4DYS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYS8"
  },
  {
    "uniprot": "Q4DGC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGC2"
  },
  {
    "uniprot": "Q4E2I8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2I8"
  },
  {
    "uniprot": "Q4CQS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQS9"
  },
  {
    "uniprot": "Q4DFC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFC6"
  },
  {
    "uniprot": "Q4DDH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDH1"
  },
  {
    "uniprot": "Q4DCZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCZ9"
  },
  {
    "uniprot": "Q4DFV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFV4"
  },
  {
    "uniprot": "Q4DYK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYK3"
  },
  {
    "uniprot": "Q4DRU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRU8"
  },
  {
    "uniprot": "Q4D9W0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9W0"
  },
  {
    "uniprot": "Q4DLN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLN0"
  },
  {
    "uniprot": "Q4DGQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGQ7"
  },
  {
    "uniprot": "Q4CQA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQA7"
  },
  {
    "uniprot": "Q4E3U7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3U7"
  },
  {
    "uniprot": "Q4CKV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKV5"
  },
  {
    "uniprot": "Q4DCK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCK3"
  },
  {
    "uniprot": "Q4DEJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEJ0"
  },
  {
    "uniprot": "Q4CKV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKV3"
  },
  {
    "uniprot": "Q4CTM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTM8"
  },
  {
    "uniprot": "Q4D4X2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4X2"
  },
  {
    "uniprot": "Q4DA71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA71"
  },
  {
    "uniprot": "Q4D417",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D417"
  },
  {
    "uniprot": "Q4CS01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS01"
  },
  {
    "uniprot": "Q4DHD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHD3"
  },
  {
    "uniprot": "Q4DBB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBB0"
  },
  {
    "uniprot": "Q4DNP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNP4"
  },
  {
    "uniprot": "Q4DI71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI71"
  },
  {
    "uniprot": "Q4DH61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH61"
  },
  {
    "uniprot": "Q4E167",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E167"
  },
  {
    "uniprot": "Q4CUQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUQ7"
  },
  {
    "uniprot": "Q4D2R2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2R2"
  },
  {
    "uniprot": "Q4DSS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSS0"
  },
  {
    "uniprot": "Q4DUP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUP8"
  },
  {
    "uniprot": "Q4CZF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZF8"
  },
  {
    "uniprot": "Q4DZH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZH4"
  },
  {
    "uniprot": "Q4CT05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT05"
  },
  {
    "uniprot": "Q4DXW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXW9"
  },
  {
    "uniprot": "Q4CVL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVL9"
  },
  {
    "uniprot": "Q4CQH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQH9"
  },
  {
    "uniprot": "Q4DKM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKM4"
  },
  {
    "uniprot": "Q4CPC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPC2"
  },
  {
    "uniprot": "Q4DFU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFU8"
  },
  {
    "uniprot": "Q4DTJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTJ9"
  },
  {
    "uniprot": "Q4CXL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXL4"
  },
  {
    "uniprot": "Q4D3C5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3C5"
  },
  {
    "uniprot": "Q4DND9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DND9"
  },
  {
    "uniprot": "Q4CVF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVF3"
  },
  {
    "uniprot": "Q4D4A2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4A2"
  },
  {
    "uniprot": "Q4CT35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT35"
  },
  {
    "uniprot": "Q4D3I2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3I2"
  },
  {
    "uniprot": "Q4DA04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA04"
  },
  {
    "uniprot": "Q4DTW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTW8"
  },
  {
    "uniprot": "Q4DMF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMF9"
  },
  {
    "uniprot": "Q4CT57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT57"
  },
  {
    "uniprot": "Q4DDD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDD7"
  },
  {
    "uniprot": "Q4DRE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRE7"
  },
  {
    "uniprot": "Q4DZU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZU4"
  },
  {
    "uniprot": "Q4DDT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDT3"
  },
  {
    "uniprot": "Q4D189",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D189"
  },
  {
    "uniprot": "Q4DUR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUR2"
  },
  {
    "uniprot": "Q4DZY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZY7"
  },
  {
    "uniprot": "Q4DFR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFR6"
  },
  {
    "uniprot": "Q4DLT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLT6"
  },
  {
    "uniprot": "Q4DHM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHM7"
  },
  {
    "uniprot": "Q4DWJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWJ9"
  },
  {
    "uniprot": "Q4D0P8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0P8"
  },
  {
    "uniprot": "Q4DVL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVL9"
  },
  {
    "uniprot": "Q4CND0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CND0"
  },
  {
    "uniprot": "Q4CQH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQH2"
  },
  {
    "uniprot": "Q4DNW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNW8"
  },
  {
    "uniprot": "Q4DBC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBC3"
  },
  {
    "uniprot": "Q4D6I7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6I7"
  },
  {
    "uniprot": "Q4CZX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZX3"
  },
  {
    "uniprot": "Q4DJW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJW9"
  },
  {
    "uniprot": "Q4DXK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXK4"
  },
  {
    "uniprot": "Q4CZQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZQ7"
  },
  {
    "uniprot": "Q4CMQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMQ5"
  },
  {
    "uniprot": "Q4CN13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN13"
  },
  {
    "uniprot": "Q4E0N7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0N7"
  },
  {
    "uniprot": "Q4CKS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKS1"
  },
  {
    "uniprot": "Q4CVC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVC0"
  },
  {
    "uniprot": "Q4E2L8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2L8"
  },
  {
    "uniprot": "Q4CWN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWN3"
  },
  {
    "uniprot": "Q4E3Q8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Q8"
  },
  {
    "uniprot": "Q4D4N4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4N4"
  },
  {
    "uniprot": "Q4D0X9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0X9"
  },
  {
    "uniprot": "Q4DBD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBD0"
  },
  {
    "uniprot": "Q4CM79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM79"
  },
  {
    "uniprot": "Q4DTH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTH0"
  },
  {
    "uniprot": "Q4DI41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI41"
  },
  {
    "uniprot": "Q4DVH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVH0"
  },
  {
    "uniprot": "Q4DNJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNJ8"
  },
  {
    "uniprot": "Q4DMJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMJ7"
  },
  {
    "uniprot": "Q4CSF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSF8"
  },
  {
    "uniprot": "Q4CSD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSD5"
  },
  {
    "uniprot": "Q4DBR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBR7"
  },
  {
    "uniprot": "Q4DUV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUV3"
  },
  {
    "uniprot": "Q4DBN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBN7"
  },
  {
    "uniprot": "Q4D9D7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9D7"
  },
  {
    "uniprot": "Q4DAX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAX8"
  },
  {
    "uniprot": "Q4DB28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB28"
  },
  {
    "uniprot": "Q4CLK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLK6"
  },
  {
    "uniprot": "Q4CWD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWD0"
  },
  {
    "uniprot": "Q4DKY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKY7"
  },
  {
    "uniprot": "Q4CVD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVD2"
  },
  {
    "uniprot": "Q4CMQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMQ3"
  },
  {
    "uniprot": "Q4D6D4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6D4"
  },
  {
    "uniprot": "Q4E5S1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5S1"
  },
  {
    "uniprot": "Q4D270",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D270"
  },
  {
    "uniprot": "Q4E0L3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0L3"
  },
  {
    "uniprot": "Q4DQA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQA4"
  },
  {
    "uniprot": "Q4CRK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRK0"
  },
  {
    "uniprot": "Q4E143",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E143"
  },
  {
    "uniprot": "Q4DMM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMM3"
  },
  {
    "uniprot": "Q4D4B2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4B2"
  },
  {
    "uniprot": "Q4DSH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSH7"
  },
  {
    "uniprot": "Q4CN36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN36"
  },
  {
    "uniprot": "Q4CTF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTF3"
  },
  {
    "uniprot": "Q4CW09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW09"
  },
  {
    "uniprot": "Q4CVL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVL6"
  },
  {
    "uniprot": "Q4DTX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTX0"
  },
  {
    "uniprot": "Q4E076",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E076"
  },
  {
    "uniprot": "Q4DXR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXR2"
  },
  {
    "uniprot": "Q4DLA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLA0"
  },
  {
    "uniprot": "Q4E4B9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4B9"
  },
  {
    "uniprot": "Q4D997",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D997"
  },
  {
    "uniprot": "Q4E032",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E032"
  },
  {
    "uniprot": "Q4E5Y2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Y2"
  },
  {
    "uniprot": "Q4D7H4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7H4"
  },
  {
    "uniprot": "Q4DK56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK56"
  },
  {
    "uniprot": "Q4E2C7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2C7"
  },
  {
    "uniprot": "Q4CZM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZM0"
  },
  {
    "uniprot": "Q4CT76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT76"
  },
  {
    "uniprot": "Q4CLH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLH8"
  },
  {
    "uniprot": "Q4CXD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXD2"
  },
  {
    "uniprot": "Q4DLF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLF0"
  },
  {
    "uniprot": "Q4CNG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNG9"
  },
  {
    "uniprot": "Q4CNC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNC7"
  },
  {
    "uniprot": "Q4CL97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL97"
  },
  {
    "uniprot": "Q4D5P7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5P7"
  },
  {
    "uniprot": "Q4DMP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMP8"
  },
  {
    "uniprot": "Q4DFV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFV9"
  },
  {
    "uniprot": "Q4DDE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDE4"
  },
  {
    "uniprot": "Q4D9Y0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Y0"
  },
  {
    "uniprot": "Q4DSL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSL3"
  },
  {
    "uniprot": "Q4DGR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGR9"
  },
  {
    "uniprot": "Q4CZQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZQ3"
  },
  {
    "uniprot": "Q4E3P8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3P8"
  },
  {
    "uniprot": "Q4CW71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW71"
  },
  {
    "uniprot": "Q4D9X8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9X8"
  },
  {
    "uniprot": "Q4CX50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX50"
  },
  {
    "uniprot": "Q4DBY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBY9"
  },
  {
    "uniprot": "Q4D416",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D416"
  },
  {
    "uniprot": "Q4E1N5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1N5"
  },
  {
    "uniprot": "Q4DEP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEP3"
  },
  {
    "uniprot": "Q4DZS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZS3"
  },
  {
    "uniprot": "Q4DMX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMX8"
  },
  {
    "uniprot": "Q4D682",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D682"
  },
  {
    "uniprot": "Q4DWX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWX8"
  },
  {
    "uniprot": "Q4D5W8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5W8"
  },
  {
    "uniprot": "Q4CY36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY36"
  },
  {
    "uniprot": "Q4D716",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D716"
  },
  {
    "uniprot": "Q4CPI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPI9"
  },
  {
    "uniprot": "Q4DQ69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ69"
  },
  {
    "uniprot": "Q4DJL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJL3"
  },
  {
    "uniprot": "Q4DWF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWF7"
  },
  {
    "uniprot": "Q4D3C2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3C2"
  },
  {
    "uniprot": "Q4D9M3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9M3"
  },
  {
    "uniprot": "Q4D8A5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8A5"
  },
  {
    "uniprot": "Q4CYM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYM9"
  },
  {
    "uniprot": "Q4DYU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYU4"
  },
  {
    "uniprot": "Q4CUG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUG4"
  },
  {
    "uniprot": "Q4D5M1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5M1"
  },
  {
    "uniprot": "Q4E0B8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0B8"
  },
  {
    "uniprot": "Q4CKQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKQ7"
  },
  {
    "uniprot": "Q4E0K5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0K5"
  },
  {
    "uniprot": "Q4E186",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E186"
  },
  {
    "uniprot": "Q4DIQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIQ7"
  },
  {
    "uniprot": "Q4D9J8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9J8"
  },
  {
    "uniprot": "Q4D248",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D248"
  },
  {
    "uniprot": "Q4DXF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXF1"
  },
  {
    "uniprot": "Q4CTS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTS2"
  },
  {
    "uniprot": "Q4CNT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNT8"
  },
  {
    "uniprot": "Q4D766",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D766"
  },
  {
    "uniprot": "Q4DG18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG18"
  },
  {
    "uniprot": "Q4DGF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGF4"
  },
  {
    "uniprot": "Q4D6P5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6P5"
  },
  {
    "uniprot": "Q4DZ04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ04"
  },
  {
    "uniprot": "Q4E3W1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3W1"
  },
  {
    "uniprot": "Q4DFG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFG4"
  },
  {
    "uniprot": "Q4CTK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTK2"
  },
  {
    "uniprot": "Q4CQR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQR3"
  },
  {
    "uniprot": "Q4CVN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVN8"
  },
  {
    "uniprot": "Q4DN69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN69"
  },
  {
    "uniprot": "Q4DT77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT77"
  },
  {
    "uniprot": "Q4DIL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIL0"
  },
  {
    "uniprot": "Q4E414",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E414"
  },
  {
    "uniprot": "Q4DQ90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ90"
  },
  {
    "uniprot": "Q4DRU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRU1"
  },
  {
    "uniprot": "Q4DAC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAC5"
  },
  {
    "uniprot": "Q4CSR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSR0"
  },
  {
    "uniprot": "Q4CS24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS24"
  },
  {
    "uniprot": "Q4DBJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBJ8"
  },
  {
    "uniprot": "Q4DSQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSQ3"
  },
  {
    "uniprot": "Q4CW62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW62"
  },
  {
    "uniprot": "Q4CKN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKN4"
  },
  {
    "uniprot": "Q4DDV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDV6"
  },
  {
    "uniprot": "Q4CZJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZJ0"
  },
  {
    "uniprot": "Q4DGT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGT6"
  },
  {
    "uniprot": "Q4CXP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXP8"
  },
  {
    "uniprot": "Q4D1B1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1B1"
  },
  {
    "uniprot": "Q4D660",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D660"
  },
  {
    "uniprot": "Q4DIK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIK4"
  },
  {
    "uniprot": "Q4CTW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTW9"
  },
  {
    "uniprot": "Q4DWV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWV8"
  },
  {
    "uniprot": "Q4DYG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYG9"
  },
  {
    "uniprot": "Q4DS89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS89"
  },
  {
    "uniprot": "Q4DGR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGR4"
  },
  {
    "uniprot": "Q4CP65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP65"
  },
  {
    "uniprot": "Q4DER5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DER5"
  },
  {
    "uniprot": "Q4D888",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D888"
  },
  {
    "uniprot": "Q4D0D7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0D7"
  },
  {
    "uniprot": "Q4CWQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWQ0"
  },
  {
    "uniprot": "Q4DEQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEQ3"
  },
  {
    "uniprot": "Q4D264",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D264"
  },
  {
    "uniprot": "Q4DIZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIZ1"
  },
  {
    "uniprot": "Q4CZ41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ41"
  },
  {
    "uniprot": "Q4CT81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT81"
  },
  {
    "uniprot": "Q4DKA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKA0"
  },
  {
    "uniprot": "Q4DEF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEF6"
  },
  {
    "uniprot": "Q4DSY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSY0"
  },
  {
    "uniprot": "Q4DFJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFJ2"
  },
  {
    "uniprot": "Q4CTV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTV4"
  },
  {
    "uniprot": "Q4DTD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTD1"
  },
  {
    "uniprot": "Q4DED8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DED8"
  },
  {
    "uniprot": "Q4DHH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHH9"
  },
  {
    "uniprot": "Q4D4I4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4I4"
  },
  {
    "uniprot": "Q4DJ34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ34"
  },
  {
    "uniprot": "Q4DUP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUP6"
  },
  {
    "uniprot": "Q4DR16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR16"
  },
  {
    "uniprot": "Q4CU26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU26"
  },
  {
    "uniprot": "Q4D8H1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8H1"
  },
  {
    "uniprot": "Q4DLJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLJ2"
  },
  {
    "uniprot": "Q4DXX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXX8"
  },
  {
    "uniprot": "Q4E0C2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0C2"
  },
  {
    "uniprot": "Q4DMQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMQ7"
  },
  {
    "uniprot": "Q4D515",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D515"
  },
  {
    "uniprot": "Q4DFR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFR4"
  },
  {
    "uniprot": "Q4E0K0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0K0"
  },
  {
    "uniprot": "Q4DK74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK74"
  },
  {
    "uniprot": "Q4E2P6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2P6"
  },
  {
    "uniprot": "Q4CVD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVD4"
  },
  {
    "uniprot": "Q4CTM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTM1"
  },
  {
    "uniprot": "Q4D4Y7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Y7"
  },
  {
    "uniprot": "Q4D8S4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8S4"
  },
  {
    "uniprot": "Q4CT13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT13"
  },
  {
    "uniprot": "Q4D9R1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9R1"
  },
  {
    "uniprot": "Q4D7X8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7X8"
  },
  {
    "uniprot": "Q4DRY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRY3"
  },
  {
    "uniprot": "Q4CPN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPN6"
  },
  {
    "uniprot": "Q4DWC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWC4"
  },
  {
    "uniprot": "Q4DH37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH37"
  },
  {
    "uniprot": "Q4D4I9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4I9"
  },
  {
    "uniprot": "Q4CV12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV12"
  },
  {
    "uniprot": "Q4DS99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS99"
  },
  {
    "uniprot": "Q4D9M2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9M2"
  },
  {
    "uniprot": "Q4D9L2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9L2"
  },
  {
    "uniprot": "Q4E3J6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3J6"
  },
  {
    "uniprot": "Q4CTL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTL4"
  },
  {
    "uniprot": "Q4DV05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV05"
  },
  {
    "uniprot": "Q4DU90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU90"
  },
  {
    "uniprot": "Q4D794",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D794"
  },
  {
    "uniprot": "Q4D1Z1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Z1"
  },
  {
    "uniprot": "Q4D3Y3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Y3"
  },
  {
    "uniprot": "Q4DEP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEP1"
  },
  {
    "uniprot": "Q4DMU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMU1"
  },
  {
    "uniprot": "Q4DLR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLR1"
  },
  {
    "uniprot": "Q4DV25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV25"
  },
  {
    "uniprot": "Q4CLW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLW3"
  },
  {
    "uniprot": "Q4DMI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMI8"
  },
  {
    "uniprot": "Q4DWG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWG9"
  },
  {
    "uniprot": "Q4DEI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEI9"
  },
  {
    "uniprot": "Q4CRX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRX8"
  },
  {
    "uniprot": "Q4DE06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE06"
  },
  {
    "uniprot": "Q4E156",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E156"
  },
  {
    "uniprot": "Q4DXF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXF0"
  },
  {
    "uniprot": "Q4DMZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMZ6"
  },
  {
    "uniprot": "Q4CY15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY15"
  },
  {
    "uniprot": "Q4DH58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH58"
  },
  {
    "uniprot": "Q4D6E1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6E1"
  },
  {
    "uniprot": "Q4D3H4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3H4"
  },
  {
    "uniprot": "Q4CRE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRE5"
  },
  {
    "uniprot": "Q4DAI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAI8"
  },
  {
    "uniprot": "Q4E204",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E204"
  },
  {
    "uniprot": "Q4E0H3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0H3"
  },
  {
    "uniprot": "Q4DY11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY11"
  },
  {
    "uniprot": "Q4DFF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFF7"
  },
  {
    "uniprot": "Q4CYF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYF7"
  },
  {
    "uniprot": "Q4CKN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKN1"
  },
  {
    "uniprot": "Q4CRJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRJ4"
  },
  {
    "uniprot": "Q4DBL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBL9"
  },
  {
    "uniprot": "Q4CPM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPM0"
  },
  {
    "uniprot": "Q4CND1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CND1"
  },
  {
    "uniprot": "Q4CS89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS89"
  },
  {
    "uniprot": "Q4DMN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMN6"
  },
  {
    "uniprot": "Q4DYM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYM1"
  },
  {
    "uniprot": "Q4E1D3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1D3"
  },
  {
    "uniprot": "Q4D608",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D608"
  },
  {
    "uniprot": "Q4DK70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK70"
  },
  {
    "uniprot": "Q4CYF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYF5"
  },
  {
    "uniprot": "Q4CXB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXB8"
  },
  {
    "uniprot": "Q4CVY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVY0"
  },
  {
    "uniprot": "Q4E0B9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0B9"
  },
  {
    "uniprot": "Q4DBV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBV8"
  },
  {
    "uniprot": "Q4D730",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D730"
  },
  {
    "uniprot": "Q4CUI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUI6"
  },
  {
    "uniprot": "Q4DH17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH17"
  },
  {
    "uniprot": "Q4D4W8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4W8"
  },
  {
    "uniprot": "Q4DTQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTQ5"
  },
  {
    "uniprot": "Q4DVC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVC0"
  },
  {
    "uniprot": "Q4DRK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRK3"
  },
  {
    "uniprot": "Q4D916",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D916"
  },
  {
    "uniprot": "Q4D7F5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7F5"
  },
  {
    "uniprot": "Q4DYP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYP7"
  },
  {
    "uniprot": "Q4DNU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNU8"
  },
  {
    "uniprot": "Q4E160",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E160"
  },
  {
    "uniprot": "Q4DDS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDS6"
  },
  {
    "uniprot": "Q4DEM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEM5"
  },
  {
    "uniprot": "Q4DEX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEX8"
  },
  {
    "uniprot": "Q4DUM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUM6"
  },
  {
    "uniprot": "Q4DCA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCA4"
  },
  {
    "uniprot": "Q4DVC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVC6"
  },
  {
    "uniprot": "Q4DPZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPZ9"
  },
  {
    "uniprot": "Q4CWD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWD5"
  },
  {
    "uniprot": "Q4DK57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK57"
  },
  {
    "uniprot": "Q4CKG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKG0"
  },
  {
    "uniprot": "Q4D561",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D561"
  },
  {
    "uniprot": "Q4DIE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIE6"
  },
  {
    "uniprot": "Q4CNA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNA4"
  },
  {
    "uniprot": "Q4DJ46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ46"
  },
  {
    "uniprot": "Q4DHZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHZ8"
  },
  {
    "uniprot": "Q4DGI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGI9"
  },
  {
    "uniprot": "Q4CNP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNP1"
  },
  {
    "uniprot": "Q4DDR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDR8"
  },
  {
    "uniprot": "Q4DVA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVA9"
  },
  {
    "uniprot": "Q4CVA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVA8"
  },
  {
    "uniprot": "Q4CVA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVA1"
  },
  {
    "uniprot": "Q4DWI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWI9"
  },
  {
    "uniprot": "Q4DR44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR44"
  },
  {
    "uniprot": "Q4CPX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPX6"
  },
  {
    "uniprot": "Q4DE72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE72"
  },
  {
    "uniprot": "Q4CL87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL87"
  },
  {
    "uniprot": "Q4CYL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYL0"
  },
  {
    "uniprot": "Q4CUK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUK2"
  },
  {
    "uniprot": "Q4DFK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFK1"
  },
  {
    "uniprot": "Q4DYC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYC6"
  },
  {
    "uniprot": "Q4CLH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLH5"
  },
  {
    "uniprot": "Q4DGY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGY0"
  },
  {
    "uniprot": "Q4DEA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEA0"
  },
  {
    "uniprot": "Q4DPN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPN6"
  },
  {
    "uniprot": "Q4E3E7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3E7"
  },
  {
    "uniprot": "Q4DHP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHP5"
  },
  {
    "uniprot": "Q4CWQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWQ9"
  },
  {
    "uniprot": "Q4DBL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBL0"
  },
  {
    "uniprot": "Q4DH02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH02"
  },
  {
    "uniprot": "Q4CQY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQY1"
  },
  {
    "uniprot": "Q4CM94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM94"
  },
  {
    "uniprot": "Q4DQL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQL8"
  },
  {
    "uniprot": "Q4CXR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXR5"
  },
  {
    "uniprot": "Q4DF41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF41"
  },
  {
    "uniprot": "Q4DER9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DER9"
  },
  {
    "uniprot": "Q4CXI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXI2"
  },
  {
    "uniprot": "Q4DS56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS56"
  },
  {
    "uniprot": "Q4CWA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWA4"
  },
  {
    "uniprot": "Q4CQU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQU6"
  },
  {
    "uniprot": "Q4D141",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D141"
  },
  {
    "uniprot": "Q4DCF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCF8"
  },
  {
    "uniprot": "Q4CNZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNZ4"
  },
  {
    "uniprot": "Q4DZE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZE2"
  },
  {
    "uniprot": "Q4CRK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRK4"
  },
  {
    "uniprot": "Q4CV05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV05"
  },
  {
    "uniprot": "Q4DI36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI36"
  },
  {
    "uniprot": "Q4D889",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D889"
  },
  {
    "uniprot": "Q4DI09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI09"
  },
  {
    "uniprot": "Q4DSG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSG8"
  },
  {
    "uniprot": "Q4DA59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA59"
  },
  {
    "uniprot": "Q4DPU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPU9"
  },
  {
    "uniprot": "Q4E090",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E090"
  },
  {
    "uniprot": "Q4DJ35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ35"
  },
  {
    "uniprot": "Q4D3M0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3M0"
  },
  {
    "uniprot": "Q4D451",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D451"
  },
  {
    "uniprot": "Q4CKA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKA0"
  },
  {
    "uniprot": "Q4DCK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCK0"
  },
  {
    "uniprot": "Q4DGC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGC3"
  },
  {
    "uniprot": "Q4CM14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM14"
  },
  {
    "uniprot": "Q4CTY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTY3"
  },
  {
    "uniprot": "Q4D9Q5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Q5"
  },
  {
    "uniprot": "Q4CXY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXY5"
  },
  {
    "uniprot": "Q4DCB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCB7"
  },
  {
    "uniprot": "Q4E0Z7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Z7"
  },
  {
    "uniprot": "Q4D994",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D994"
  },
  {
    "uniprot": "Q4D0B9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0B9"
  },
  {
    "uniprot": "Q4CW04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW04"
  },
  {
    "uniprot": "Q4DVQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVQ6"
  },
  {
    "uniprot": "Q4DDY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDY3"
  },
  {
    "uniprot": "Q4CTD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTD3"
  },
  {
    "uniprot": "Q4D598",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D598"
  },
  {
    "uniprot": "Q4DGD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGD9"
  },
  {
    "uniprot": "Q4CXZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXZ1"
  },
  {
    "uniprot": "Q4D7J6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7J6"
  },
  {
    "uniprot": "Q4E5C2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5C2"
  },
  {
    "uniprot": "Q4DVM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVM1"
  },
  {
    "uniprot": "Q4E372",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E372"
  },
  {
    "uniprot": "Q4D910",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D910"
  },
  {
    "uniprot": "Q4DXK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXK8"
  },
  {
    "uniprot": "Q4DTE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTE0"
  },
  {
    "uniprot": "Q4CLU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLU8"
  },
  {
    "uniprot": "Q4D876",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D876"
  },
  {
    "uniprot": "Q4DXI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXI0"
  },
  {
    "uniprot": "Q4CYE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYE7"
  },
  {
    "uniprot": "Q4DIA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIA3"
  },
  {
    "uniprot": "Q4CND7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CND7"
  },
  {
    "uniprot": "Q4DDN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDN4"
  },
  {
    "uniprot": "Q4E578",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E578"
  },
  {
    "uniprot": "Q4CNP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNP0"
  },
  {
    "uniprot": "Q4CVJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVJ1"
  },
  {
    "uniprot": "Q4E2K1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2K1"
  },
  {
    "uniprot": "Q4CPH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPH4"
  },
  {
    "uniprot": "Q4CX34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX34"
  },
  {
    "uniprot": "Q4D6M7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6M7"
  },
  {
    "uniprot": "Q4DV81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV81"
  },
  {
    "uniprot": "Q4DRL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRL5"
  },
  {
    "uniprot": "Q4CMD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMD3"
  },
  {
    "uniprot": "Q4DJW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJW7"
  },
  {
    "uniprot": "Q4E4C9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4C9"
  },
  {
    "uniprot": "Q4DFF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFF2"
  },
  {
    "uniprot": "Q4CQ24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ24"
  },
  {
    "uniprot": "Q4DGK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGK1"
  },
  {
    "uniprot": "Q4DMH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMH9"
  },
  {
    "uniprot": "Q4DRS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRS2"
  },
  {
    "uniprot": "Q4CRW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRW6"
  },
  {
    "uniprot": "Q4D2I3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2I3"
  },
  {
    "uniprot": "Q4CKU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKU9"
  },
  {
    "uniprot": "Q4CLK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLK5"
  },
  {
    "uniprot": "Q4DUQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUQ7"
  },
  {
    "uniprot": "Q4CXD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXD8"
  },
  {
    "uniprot": "Q4DR95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR95"
  },
  {
    "uniprot": "Q4DHV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHV4"
  },
  {
    "uniprot": "Q4DBW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBW2"
  },
  {
    "uniprot": "Q4DPF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPF7"
  },
  {
    "uniprot": "Q4CUF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUF7"
  },
  {
    "uniprot": "Q4DX38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX38"
  },
  {
    "uniprot": "Q4DQG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQG3"
  },
  {
    "uniprot": "Q4CVC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVC3"
  },
  {
    "uniprot": "Q4DBR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBR1"
  },
  {
    "uniprot": "Q4DNP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNP0"
  },
  {
    "uniprot": "Q4DX50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX50"
  },
  {
    "uniprot": "Q4DUN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUN4"
  },
  {
    "uniprot": "Q4D4S4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4S4"
  },
  {
    "uniprot": "Q4DZX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZX0"
  },
  {
    "uniprot": "Q4CZT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZT8"
  },
  {
    "uniprot": "Q4DUH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUH2"
  },
  {
    "uniprot": "Q4D558",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D558"
  },
  {
    "uniprot": "Q4CZY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZY6"
  },
  {
    "uniprot": "Q4DF26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF26"
  },
  {
    "uniprot": "Q4D1E7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1E7"
  },
  {
    "uniprot": "Q4CYH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYH8"
  },
  {
    "uniprot": "Q4D336",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D336"
  },
  {
    "uniprot": "Q4DCH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCH3"
  },
  {
    "uniprot": "Q4DXJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXJ2"
  },
  {
    "uniprot": "Q4DR83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR83"
  },
  {
    "uniprot": "Q4D909",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D909"
  },
  {
    "uniprot": "Q4D430",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D430"
  },
  {
    "uniprot": "Q4DKP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKP1"
  },
  {
    "uniprot": "Q4CVM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVM2"
  },
  {
    "uniprot": "Q4CUK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUK4"
  },
  {
    "uniprot": "Q4CKM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKM0"
  },
  {
    "uniprot": "Q4E225",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E225"
  },
  {
    "uniprot": "Q4DSX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSX8"
  },
  {
    "uniprot": "Q4CZG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZG5"
  },
  {
    "uniprot": "Q4DWM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWM5"
  },
  {
    "uniprot": "Q4DJL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJL5"
  },
  {
    "uniprot": "Q4DQU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQU1"
  },
  {
    "uniprot": "Q4E0Y6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Y6"
  },
  {
    "uniprot": "Q4DBT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBT2"
  },
  {
    "uniprot": "Q4DXD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXD0"
  },
  {
    "uniprot": "Q4CS47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS47"
  },
  {
    "uniprot": "Q4D6G8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6G8"
  },
  {
    "uniprot": "Q4DUA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUA9"
  },
  {
    "uniprot": "Q4DST9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DST9"
  },
  {
    "uniprot": "Q4DYR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYR1"
  },
  {
    "uniprot": "Q4E1Z4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Z4"
  },
  {
    "uniprot": "Q4D6G9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6G9"
  },
  {
    "uniprot": "Q4DPW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPW6"
  },
  {
    "uniprot": "Q4DJY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJY6"
  },
  {
    "uniprot": "Q4D4I7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4I7"
  },
  {
    "uniprot": "Q4CYE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYE4"
  },
  {
    "uniprot": "Q4CKE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKE0"
  },
  {
    "uniprot": "Q4D3F4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3F4"
  },
  {
    "uniprot": "Q4DYI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYI4"
  },
  {
    "uniprot": "Q4DPR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPR4"
  },
  {
    "uniprot": "Q4CY80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY80"
  },
  {
    "uniprot": "Q4E274",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E274"
  },
  {
    "uniprot": "Q4D2F4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2F4"
  },
  {
    "uniprot": "Q4D0B6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0B6"
  },
  {
    "uniprot": "Q4CM96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM96"
  },
  {
    "uniprot": "Q4E2R2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2R2"
  },
  {
    "uniprot": "Q4CVD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVD3"
  },
  {
    "uniprot": "Q4CMI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMI7"
  },
  {
    "uniprot": "Q4D2S8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2S8"
  },
  {
    "uniprot": "Q4CUS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUS4"
  },
  {
    "uniprot": "Q4CLI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLI0"
  },
  {
    "uniprot": "Q4DHR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHR9"
  },
  {
    "uniprot": "Q4DNR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNR0"
  },
  {
    "uniprot": "Q4E3D9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3D9"
  },
  {
    "uniprot": "Q4D7B5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7B5"
  },
  {
    "uniprot": "Q4D4M2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4M2"
  },
  {
    "uniprot": "Q4DLH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLH8"
  },
  {
    "uniprot": "Q4E2H5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2H5"
  },
  {
    "uniprot": "Q4E2K7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2K7"
  },
  {
    "uniprot": "Q4DXX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXX1"
  },
  {
    "uniprot": "Q4DQL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQL9"
  },
  {
    "uniprot": "Q4DR06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR06"
  },
  {
    "uniprot": "Q4CTM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTM0"
  },
  {
    "uniprot": "Q4CXL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXL2"
  },
  {
    "uniprot": "Q4DJ78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ78"
  },
  {
    "uniprot": "Q4CV50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV50"
  },
  {
    "uniprot": "Q4DNS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNS7"
  },
  {
    "uniprot": "Q4E5E8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5E8"
  },
  {
    "uniprot": "Q4DDW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDW4"
  },
  {
    "uniprot": "Q4DC48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC48"
  },
  {
    "uniprot": "Q4DAF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAF7"
  },
  {
    "uniprot": "Q4CM39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM39"
  },
  {
    "uniprot": "Q4DJA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJA2"
  },
  {
    "uniprot": "Q4CZN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZN8"
  },
  {
    "uniprot": "Q4DVF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVF2"
  },
  {
    "uniprot": "Q4DZE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZE5"
  },
  {
    "uniprot": "Q4CRD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRD0"
  },
  {
    "uniprot": "Q4D4M9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4M9"
  },
  {
    "uniprot": "Q4DFN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFN0"
  },
  {
    "uniprot": "Q4DNR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNR1"
  },
  {
    "uniprot": "Q4DX10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX10"
  },
  {
    "uniprot": "Q4D0K8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0K8"
  },
  {
    "uniprot": "Q4E468",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E468"
  },
  {
    "uniprot": "Q4DX27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX27"
  },
  {
    "uniprot": "Q4D324",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D324"
  },
  {
    "uniprot": "Q4CZ55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ55"
  },
  {
    "uniprot": "Q4D6U6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6U6"
  },
  {
    "uniprot": "Q4E4U6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4U6"
  },
  {
    "uniprot": "Q4DLT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLT7"
  },
  {
    "uniprot": "Q4D6F8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6F8"
  },
  {
    "uniprot": "Q4CP62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP62"
  },
  {
    "uniprot": "Q4DEL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEL5"
  },
  {
    "uniprot": "Q4CLA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLA0"
  },
  {
    "uniprot": "Q4D2Z0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Z0"
  },
  {
    "uniprot": "Q4DIS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIS6"
  },
  {
    "uniprot": "Q4E0G4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0G4"
  },
  {
    "uniprot": "Q4D0Y0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Y0"
  },
  {
    "uniprot": "Q4DDV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDV3"
  },
  {
    "uniprot": "Q4D5F1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5F1"
  },
  {
    "uniprot": "Q4E3R4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3R4"
  },
  {
    "uniprot": "Q4D872",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D872"
  },
  {
    "uniprot": "Q4D127",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D127"
  },
  {
    "uniprot": "Q4DHR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHR2"
  },
  {
    "uniprot": "Q4CUY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUY2"
  },
  {
    "uniprot": "Q4E4D1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4D1"
  },
  {
    "uniprot": "Q4DDH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDH6"
  },
  {
    "uniprot": "Q4D3E1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3E1"
  },
  {
    "uniprot": "Q4DKR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKR7"
  },
  {
    "uniprot": "Q4E462",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E462"
  },
  {
    "uniprot": "Q4DRX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRX7"
  },
  {
    "uniprot": "Q4DH55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH55"
  },
  {
    "uniprot": "Q4CZG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZG2"
  },
  {
    "uniprot": "Q4DD29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD29"
  },
  {
    "uniprot": "Q4E0B1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0B1"
  },
  {
    "uniprot": "Q4CXH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXH7"
  },
  {
    "uniprot": "Q4E365",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E365"
  },
  {
    "uniprot": "Q4DRJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRJ8"
  },
  {
    "uniprot": "Q4D541",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D541"
  },
  {
    "uniprot": "Q4DZU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZU1"
  },
  {
    "uniprot": "Q4CX00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX00"
  },
  {
    "uniprot": "Q4DAE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAE4"
  },
  {
    "uniprot": "Q4DTN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTN7"
  },
  {
    "uniprot": "Q4DXX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXX2"
  },
  {
    "uniprot": "Q4DBZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBZ3"
  },
  {
    "uniprot": "Q4DHB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHB7"
  },
  {
    "uniprot": "Q4D2W4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2W4"
  },
  {
    "uniprot": "Q4D4H4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4H4"
  },
  {
    "uniprot": "Q4DEV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEV4"
  },
  {
    "uniprot": "Q4D1C1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1C1"
  },
  {
    "uniprot": "Q4DPH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPH1"
  },
  {
    "uniprot": "Q4DHT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHT8"
  },
  {
    "uniprot": "Q4D7Y5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Y5"
  },
  {
    "uniprot": "Q4DQT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQT2"
  },
  {
    "uniprot": "Q4D8Q0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Q0"
  },
  {
    "uniprot": "Q4DTK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTK3"
  },
  {
    "uniprot": "Q4DHG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHG1"
  },
  {
    "uniprot": "Q4DU35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU35"
  },
  {
    "uniprot": "Q4D6D1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6D1"
  },
  {
    "uniprot": "Q4E1H5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1H5"
  },
  {
    "uniprot": "Q4E466",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E466"
  },
  {
    "uniprot": "Q4D196",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D196"
  },
  {
    "uniprot": "Q4DB50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB50"
  },
  {
    "uniprot": "Q4CQW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQW5"
  },
  {
    "uniprot": "Q4DFY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFY6"
  },
  {
    "uniprot": "Q4DV45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV45"
  },
  {
    "uniprot": "Q4DIV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIV7"
  },
  {
    "uniprot": "Q4DQZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQZ1"
  },
  {
    "uniprot": "Q4DBG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBG6"
  },
  {
    "uniprot": "Q4DIN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIN6"
  },
  {
    "uniprot": "Q4CSL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSL4"
  },
  {
    "uniprot": "Q4D925",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D925"
  },
  {
    "uniprot": "Q4CLM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLM7"
  },
  {
    "uniprot": "Q4DW58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW58"
  },
  {
    "uniprot": "Q4DLE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLE1"
  },
  {
    "uniprot": "Q4CV60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV60"
  },
  {
    "uniprot": "Q4DL44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL44"
  },
  {
    "uniprot": "Q4E5Y0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Y0"
  },
  {
    "uniprot": "Q4CNQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNQ1"
  },
  {
    "uniprot": "Q4E4T7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4T7"
  },
  {
    "uniprot": "Q4DJG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJG9"
  },
  {
    "uniprot": "Q4CTY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTY4"
  },
  {
    "uniprot": "Q4D6C0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6C0"
  },
  {
    "uniprot": "Q4E2J1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2J1"
  },
  {
    "uniprot": "Q4DFN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFN7"
  },
  {
    "uniprot": "Q4DF70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF70"
  },
  {
    "uniprot": "Q4CYC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYC2"
  },
  {
    "uniprot": "Q4DFR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFR2"
  },
  {
    "uniprot": "Q4DVF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVF1"
  },
  {
    "uniprot": "Q4DLC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLC2"
  },
  {
    "uniprot": "Q4CSH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSH1"
  },
  {
    "uniprot": "Q4CZH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZH0"
  },
  {
    "uniprot": "Q4E621",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E621"
  },
  {
    "uniprot": "Q4E435",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E435"
  },
  {
    "uniprot": "Q4CYZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYZ0"
  },
  {
    "uniprot": "Q4E282",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E282"
  },
  {
    "uniprot": "Q4DAM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAM7"
  },
  {
    "uniprot": "Q4E4T5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4T5"
  },
  {
    "uniprot": "Q4CZX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZX9"
  },
  {
    "uniprot": "Q4DVU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVU7"
  },
  {
    "uniprot": "Q4D358",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D358"
  },
  {
    "uniprot": "Q4CPI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPI2"
  },
  {
    "uniprot": "Q4E5D6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5D6"
  },
  {
    "uniprot": "Q4E255",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E255"
  },
  {
    "uniprot": "Q4E549",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E549"
  },
  {
    "uniprot": "Q4E1V1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1V1"
  },
  {
    "uniprot": "Q4DQB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQB3"
  },
  {
    "uniprot": "Q4CT19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT19"
  },
  {
    "uniprot": "Q4DWZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWZ6"
  },
  {
    "uniprot": "Q4CV75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV75"
  },
  {
    "uniprot": "Q4DHS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHS4"
  },
  {
    "uniprot": "Q4DV36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV36"
  },
  {
    "uniprot": "Q4DCG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCG5"
  },
  {
    "uniprot": "Q4E1H0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1H0"
  },
  {
    "uniprot": "Q4CT12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT12"
  },
  {
    "uniprot": "Q4D1Q5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Q5"
  },
  {
    "uniprot": "Q4DRR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRR0"
  },
  {
    "uniprot": "Q4DJH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJH3"
  },
  {
    "uniprot": "Q4DVM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVM6"
  },
  {
    "uniprot": "Q4DCD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCD3"
  },
  {
    "uniprot": "Q4DHH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHH4"
  },
  {
    "uniprot": "Q4CT68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT68"
  },
  {
    "uniprot": "Q4E2R1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2R1"
  },
  {
    "uniprot": "Q4CV41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV41"
  },
  {
    "uniprot": "Q4DKE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKE0"
  },
  {
    "uniprot": "Q4D121",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D121"
  },
  {
    "uniprot": "Q4DI03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI03"
  },
  {
    "uniprot": "Q4D1X8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1X8"
  },
  {
    "uniprot": "Q4D5I9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5I9"
  },
  {
    "uniprot": "Q4CT93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT93"
  },
  {
    "uniprot": "Q4D0C3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0C3"
  },
  {
    "uniprot": "Q4DT96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT96"
  },
  {
    "uniprot": "Q4CL82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL82"
  },
  {
    "uniprot": "Q4D6D2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6D2"
  },
  {
    "uniprot": "Q4CMS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMS3"
  },
  {
    "uniprot": "Q4DB87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB87"
  },
  {
    "uniprot": "Q4CRZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRZ5"
  },
  {
    "uniprot": "Q4CVG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVG8"
  },
  {
    "uniprot": "Q4DBS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBS4"
  },
  {
    "uniprot": "Q4E4L8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4L8"
  },
  {
    "uniprot": "Q4DP27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP27"
  },
  {
    "uniprot": "Q4CMY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMY8"
  },
  {
    "uniprot": "Q4CPZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPZ3"
  },
  {
    "uniprot": "Q4CXT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXT4"
  },
  {
    "uniprot": "Q4CSH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSH8"
  },
  {
    "uniprot": "Q4E029",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E029"
  },
  {
    "uniprot": "Q4CS49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS49"
  },
  {
    "uniprot": "Q4CNE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNE6"
  },
  {
    "uniprot": "Q4DFD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFD2"
  },
  {
    "uniprot": "Q4CV67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV67"
  },
  {
    "uniprot": "Q4CSF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSF9"
  },
  {
    "uniprot": "Q4CZW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZW1"
  },
  {
    "uniprot": "Q4DA63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA63"
  },
  {
    "uniprot": "Q4CTH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTH5"
  },
  {
    "uniprot": "Q4CRJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRJ3"
  },
  {
    "uniprot": "Q4DN35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN35"
  },
  {
    "uniprot": "Q4CNN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNN3"
  },
  {
    "uniprot": "Q4DEH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEH1"
  },
  {
    "uniprot": "Q4DEJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEJ1"
  },
  {
    "uniprot": "Q4DCY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCY8"
  },
  {
    "uniprot": "Q4CY60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY60"
  },
  {
    "uniprot": "Q4DM35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM35"
  },
  {
    "uniprot": "Q4DXI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXI6"
  },
  {
    "uniprot": "Q4DYE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYE3"
  },
  {
    "uniprot": "Q4CZE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZE6"
  },
  {
    "uniprot": "Q4E5H4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5H4"
  },
  {
    "uniprot": "Q4DFJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFJ6"
  },
  {
    "uniprot": "Q4E2A4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2A4"
  },
  {
    "uniprot": "Q4DQS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQS3"
  },
  {
    "uniprot": "Q4D099",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D099"
  },
  {
    "uniprot": "Q4D2G3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2G3"
  },
  {
    "uniprot": "Q4E1T6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1T6"
  },
  {
    "uniprot": "Q4CUJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUJ8"
  },
  {
    "uniprot": "Q4D1R2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1R2"
  },
  {
    "uniprot": "Q4CKX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKX8"
  },
  {
    "uniprot": "Q4D0B4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0B4"
  },
  {
    "uniprot": "Q4CMJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMJ2"
  },
  {
    "uniprot": "Q4DYH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYH8"
  },
  {
    "uniprot": "Q4E006",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E006"
  },
  {
    "uniprot": "Q4DQC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQC8"
  },
  {
    "uniprot": "Q4DAM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAM2"
  },
  {
    "uniprot": "Q4DVM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVM7"
  },
  {
    "uniprot": "Q4CVS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVS4"
  },
  {
    "uniprot": "Q4DRS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRS7"
  },
  {
    "uniprot": "Q4CXD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXD3"
  },
  {
    "uniprot": "Q4CL52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL52"
  },
  {
    "uniprot": "Q4DB94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB94"
  },
  {
    "uniprot": "Q4DRP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRP4"
  },
  {
    "uniprot": "Q4D8S3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8S3"
  },
  {
    "uniprot": "Q4CUS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUS5"
  },
  {
    "uniprot": "Q4DZY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZY6"
  },
  {
    "uniprot": "Q4E683",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E683"
  },
  {
    "uniprot": "Q4DT27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT27"
  },
  {
    "uniprot": "Q4DT04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT04"
  },
  {
    "uniprot": "Q4DQG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQG2"
  },
  {
    "uniprot": "Q4DR46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR46"
  },
  {
    "uniprot": "Q4DFJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFJ3"
  },
  {
    "uniprot": "Q4DU39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU39"
  },
  {
    "uniprot": "Q4DC10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC10"
  },
  {
    "uniprot": "Q4DPW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPW5"
  },
  {
    "uniprot": "Q4DSY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSY7"
  },
  {
    "uniprot": "Q4CTW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTW7"
  },
  {
    "uniprot": "Q4DAW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAW7"
  },
  {
    "uniprot": "Q4DUQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUQ4"
  },
  {
    "uniprot": "Q4DQD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQD8"
  },
  {
    "uniprot": "Q4DMW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMW1"
  },
  {
    "uniprot": "Q4CZL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZL5"
  },
  {
    "uniprot": "Q4DBC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBC9"
  },
  {
    "uniprot": "Q4CQ11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ11"
  },
  {
    "uniprot": "Q4E2X4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2X4"
  },
  {
    "uniprot": "Q4D6M4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6M4"
  },
  {
    "uniprot": "Q4D6C5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6C5"
  },
  {
    "uniprot": "Q4E3M6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3M6"
  },
  {
    "uniprot": "Q4CXG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXG4"
  },
  {
    "uniprot": "Q4CP45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP45"
  },
  {
    "uniprot": "Q4E378",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E378"
  },
  {
    "uniprot": "Q4DBB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBB3"
  },
  {
    "uniprot": "Q4CYD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYD1"
  },
  {
    "uniprot": "Q4DVK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVK3"
  },
  {
    "uniprot": "Q4DSN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSN0"
  },
  {
    "uniprot": "Q4DGF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGF2"
  },
  {
    "uniprot": "Q4DAJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAJ4"
  },
  {
    "uniprot": "Q4CPY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPY8"
  },
  {
    "uniprot": "Q4D4F3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4F3"
  },
  {
    "uniprot": "Q4E253",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E253"
  },
  {
    "uniprot": "Q4D0G6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0G6"
  },
  {
    "uniprot": "Q4CWI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWI4"
  },
  {
    "uniprot": "Q4DEF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEF0"
  },
  {
    "uniprot": "Q4D5M4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5M4"
  },
  {
    "uniprot": "Q4DPL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPL7"
  },
  {
    "uniprot": "Q4DUE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUE7"
  },
  {
    "uniprot": "Q4DEH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEH2"
  },
  {
    "uniprot": "Q4DX54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX54"
  },
  {
    "uniprot": "Q4E647",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E647"
  },
  {
    "uniprot": "Q4E2X6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2X6"
  },
  {
    "uniprot": "Q4E079",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E079"
  },
  {
    "uniprot": "Q4DSA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSA2"
  },
  {
    "uniprot": "Q4DW26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW26"
  },
  {
    "uniprot": "Q4DBX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBX4"
  },
  {
    "uniprot": "Q4CQB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQB4"
  },
  {
    "uniprot": "Q4DW87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW87"
  },
  {
    "uniprot": "Q4CYI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYI5"
  },
  {
    "uniprot": "Q4DYZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYZ2"
  },
  {
    "uniprot": "Q4CMZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMZ9"
  },
  {
    "uniprot": "Q4DTS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTS5"
  },
  {
    "uniprot": "Q4E425",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E425"
  },
  {
    "uniprot": "Q4DAN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAN5"
  },
  {
    "uniprot": "Q4DE67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE67"
  },
  {
    "uniprot": "Q4CKS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKS8"
  },
  {
    "uniprot": "Q4DVA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVA7"
  },
  {
    "uniprot": "Q4CNG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNG2"
  },
  {
    "uniprot": "Q4CPJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPJ3"
  },
  {
    "uniprot": "Q4CKD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKD3"
  },
  {
    "uniprot": "Q4CRN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRN4"
  },
  {
    "uniprot": "Q4DET7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DET7"
  },
  {
    "uniprot": "Q4DUE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUE5"
  },
  {
    "uniprot": "Q4D745",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D745"
  },
  {
    "uniprot": "Q4DSD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSD4"
  },
  {
    "uniprot": "Q4D092",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D092"
  },
  {
    "uniprot": "Q4DC53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC53"
  },
  {
    "uniprot": "Q4DR35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR35"
  },
  {
    "uniprot": "Q4DFZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFZ5"
  },
  {
    "uniprot": "Q4D3T5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3T5"
  },
  {
    "uniprot": "Q4CZY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZY4"
  },
  {
    "uniprot": "Q4DDW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDW2"
  },
  {
    "uniprot": "Q4DMT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMT1"
  },
  {
    "uniprot": "Q4DQR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQR6"
  },
  {
    "uniprot": "Q4DLI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLI5"
  },
  {
    "uniprot": "Q4DT43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT43"
  },
  {
    "uniprot": "Q4E1U4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1U4"
  },
  {
    "uniprot": "Q4DBL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBL6"
  },
  {
    "uniprot": "Q4DPA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPA8"
  },
  {
    "uniprot": "Q4CLI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLI6"
  },
  {
    "uniprot": "Q4DXL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXL4"
  },
  {
    "uniprot": "Q4CNV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNV7"
  },
  {
    "uniprot": "Q4CM43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM43"
  },
  {
    "uniprot": "Q4DIR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIR4"
  },
  {
    "uniprot": "Q4DKD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKD7"
  },
  {
    "uniprot": "Q4DY29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY29"
  },
  {
    "uniprot": "Q4E0R0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0R0"
  },
  {
    "uniprot": "Q4CSX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSX7"
  },
  {
    "uniprot": "Q4CTK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTK3"
  },
  {
    "uniprot": "Q4E3K4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3K4"
  },
  {
    "uniprot": "Q4CN67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN67"
  },
  {
    "uniprot": "Q4DFH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFH4"
  },
  {
    "uniprot": "Q4D774",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D774"
  },
  {
    "uniprot": "Q4DJ49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ49"
  },
  {
    "uniprot": "Q4DKU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKU3"
  },
  {
    "uniprot": "Q4DV58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV58"
  },
  {
    "uniprot": "Q4CWQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWQ3"
  },
  {
    "uniprot": "Q4DUD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUD1"
  },
  {
    "uniprot": "Q4CMQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMQ2"
  },
  {
    "uniprot": "Q4D4A7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4A7"
  },
  {
    "uniprot": "Q4D9I0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9I0"
  },
  {
    "uniprot": "Q4CY37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY37"
  },
  {
    "uniprot": "Q4CWW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWW3"
  },
  {
    "uniprot": "Q4D7R5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7R5"
  },
  {
    "uniprot": "Q4DQK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQK2"
  },
  {
    "uniprot": "Q4CLZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLZ3"
  },
  {
    "uniprot": "Q4CS59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS59"
  },
  {
    "uniprot": "Q4CVZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVZ9"
  },
  {
    "uniprot": "Q4DG92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG92"
  },
  {
    "uniprot": "Q4E610",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E610"
  },
  {
    "uniprot": "Q4DKU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKU0"
  },
  {
    "uniprot": "Q4D6C3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6C3"
  },
  {
    "uniprot": "Q4DAW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAW6"
  },
  {
    "uniprot": "Q4DK98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK98"
  },
  {
    "uniprot": "Q4CZ33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ33"
  },
  {
    "uniprot": "Q4CPI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPI0"
  },
  {
    "uniprot": "Q4DN70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN70"
  },
  {
    "uniprot": "Q4DFF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFF6"
  },
  {
    "uniprot": "Q4DEB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEB0"
  },
  {
    "uniprot": "Q4D341",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D341"
  },
  {
    "uniprot": "Q4CS08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS08"
  },
  {
    "uniprot": "Q4DVY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVY0"
  },
  {
    "uniprot": "Q4E464",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E464"
  },
  {
    "uniprot": "Q4CS16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS16"
  },
  {
    "uniprot": "Q4CPA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPA1"
  },
  {
    "uniprot": "Q4E3C8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3C8"
  },
  {
    "uniprot": "Q4CUJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUJ2"
  },
  {
    "uniprot": "Q4DNJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNJ2"
  },
  {
    "uniprot": "Q4E044",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E044"
  },
  {
    "uniprot": "Q4DSN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSN9"
  },
  {
    "uniprot": "Q4CPC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPC4"
  },
  {
    "uniprot": "Q4CN92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN92"
  },
  {
    "uniprot": "Q4CXR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXR6"
  },
  {
    "uniprot": "Q4DF62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF62"
  },
  {
    "uniprot": "Q4E2V5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2V5"
  },
  {
    "uniprot": "Q4CRN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRN9"
  },
  {
    "uniprot": "Q4DE01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE01"
  },
  {
    "uniprot": "Q4CQX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQX7"
  },
  {
    "uniprot": "Q4DUM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUM1"
  },
  {
    "uniprot": "Q4D6Y3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Y3"
  },
  {
    "uniprot": "Q4DBH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBH5"
  },
  {
    "uniprot": "Q4CY70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY70"
  },
  {
    "uniprot": "Q4E5T1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5T1"
  },
  {
    "uniprot": "Q4E637",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E637"
  },
  {
    "uniprot": "Q4DH82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH82"
  },
  {
    "uniprot": "Q4E0F1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0F1"
  },
  {
    "uniprot": "Q4E3V8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3V8"
  },
  {
    "uniprot": "Q4CNZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNZ1"
  },
  {
    "uniprot": "Q4CY31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY31"
  },
  {
    "uniprot": "Q4DB65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB65"
  },
  {
    "uniprot": "Q4DAZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAZ8"
  },
  {
    "uniprot": "Q4DEG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEG3"
  },
  {
    "uniprot": "Q4D319",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D319"
  },
  {
    "uniprot": "Q4CYT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYT7"
  },
  {
    "uniprot": "Q4CL03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL03"
  },
  {
    "uniprot": "Q4CYN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYN7"
  },
  {
    "uniprot": "Q4D4U8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4U8"
  },
  {
    "uniprot": "Q4DAF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAF1"
  },
  {
    "uniprot": "Q4DL04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL04"
  },
  {
    "uniprot": "Q4DPE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPE3"
  },
  {
    "uniprot": "Q4E2B5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2B5"
  },
  {
    "uniprot": "Q4DVT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVT3"
  },
  {
    "uniprot": "Q4E4H9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4H9"
  },
  {
    "uniprot": "Q4DU83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU83"
  },
  {
    "uniprot": "Q4DZ82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ82"
  },
  {
    "uniprot": "Q4DC26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC26"
  },
  {
    "uniprot": "Q4E5J6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5J6"
  },
  {
    "uniprot": "Q4D8F2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8F2"
  },
  {
    "uniprot": "Q4D6L5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6L5"
  },
  {
    "uniprot": "Q4CSH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSH4"
  },
  {
    "uniprot": "Q4CW19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW19"
  },
  {
    "uniprot": "Q4CWS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWS4"
  },
  {
    "uniprot": "Q4DK89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK89"
  },
  {
    "uniprot": "Q4E5S7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5S7"
  },
  {
    "uniprot": "Q4CMV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMV9"
  },
  {
    "uniprot": "Q4D4A1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4A1"
  },
  {
    "uniprot": "Q4CVW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVW0"
  },
  {
    "uniprot": "Q4CPT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPT2"
  },
  {
    "uniprot": "Q4CT31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT31"
  },
  {
    "uniprot": "Q4DR26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR26"
  },
  {
    "uniprot": "Q4DV55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV55"
  },
  {
    "uniprot": "Q4DQ85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ85"
  },
  {
    "uniprot": "Q4CZG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZG7"
  },
  {
    "uniprot": "Q4E4J0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4J0"
  },
  {
    "uniprot": "Q4E4W5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4W5"
  },
  {
    "uniprot": "Q4CV11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV11"
  },
  {
    "uniprot": "Q4D8S9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8S9"
  },
  {
    "uniprot": "Q4DBI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBI1"
  },
  {
    "uniprot": "Q4DLD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLD5"
  },
  {
    "uniprot": "Q4CT18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT18"
  },
  {
    "uniprot": "Q4DS98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS98"
  },
  {
    "uniprot": "Q4DX49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX49"
  },
  {
    "uniprot": "Q4CW21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW21"
  },
  {
    "uniprot": "Q4E0M0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0M0"
  },
  {
    "uniprot": "Q4DGF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGF9"
  },
  {
    "uniprot": "Q4D0J7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0J7"
  },
  {
    "uniprot": "Q4CZV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZV4"
  },
  {
    "uniprot": "Q4E5K5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5K5"
  },
  {
    "uniprot": "Q4DR32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR32"
  },
  {
    "uniprot": "Q4DJJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJJ8"
  },
  {
    "uniprot": "Q4DD55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD55"
  },
  {
    "uniprot": "Q4D103",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D103"
  },
  {
    "uniprot": "Q4DIW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIW3"
  },
  {
    "uniprot": "Q4D9X2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9X2"
  },
  {
    "uniprot": "Q4DZ05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ05"
  },
  {
    "uniprot": "Q4D8Z2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Z2"
  },
  {
    "uniprot": "Q4CYV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYV2"
  },
  {
    "uniprot": "Q4CYK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYK0"
  },
  {
    "uniprot": "Q4D2I1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2I1"
  },
  {
    "uniprot": "Q4CXR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXR7"
  },
  {
    "uniprot": "Q4DVC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVC7"
  },
  {
    "uniprot": "Q4DYC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYC3"
  },
  {
    "uniprot": "Q4CPW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPW6"
  },
  {
    "uniprot": "Q4DJ86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ86"
  },
  {
    "uniprot": "Q4DFA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFA3"
  },
  {
    "uniprot": "Q4DL30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL30"
  },
  {
    "uniprot": "Q4DC77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC77"
  },
  {
    "uniprot": "Q4D7L6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7L6"
  },
  {
    "uniprot": "Q4DRB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRB5"
  },
  {
    "uniprot": "Q4DPF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPF9"
  },
  {
    "uniprot": "Q4DI97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI97"
  },
  {
    "uniprot": "Q4DS55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS55"
  },
  {
    "uniprot": "Q4CWW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWW9"
  },
  {
    "uniprot": "Q4E3I6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3I6"
  },
  {
    "uniprot": "Q4E3N3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3N3"
  },
  {
    "uniprot": "Q4CTK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTK4"
  },
  {
    "uniprot": "Q4CNT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNT4"
  },
  {
    "uniprot": "Q4D1Z2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Z2"
  },
  {
    "uniprot": "Q4DDR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDR1"
  },
  {
    "uniprot": "Q4DZJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZJ5"
  },
  {
    "uniprot": "Q4D8N0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8N0"
  },
  {
    "uniprot": "Q4D930",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D930"
  },
  {
    "uniprot": "Q4D8H0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8H0"
  },
  {
    "uniprot": "Q4DSI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSI6"
  },
  {
    "uniprot": "Q4CZN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZN5"
  },
  {
    "uniprot": "Q4DJE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJE3"
  },
  {
    "uniprot": "Q4D2J5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2J5"
  },
  {
    "uniprot": "Q4D228",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D228"
  },
  {
    "uniprot": "Q4CKW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKW8"
  },
  {
    "uniprot": "Q4CXY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXY0"
  },
  {
    "uniprot": "Q4DQQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQQ4"
  },
  {
    "uniprot": "Q4CTC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTC6"
  },
  {
    "uniprot": "Q4CKD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKD6"
  },
  {
    "uniprot": "Q4CYF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYF3"
  },
  {
    "uniprot": "Q4DW44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW44"
  },
  {
    "uniprot": "Q4CYG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYG1"
  },
  {
    "uniprot": "Q4DNI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNI4"
  },
  {
    "uniprot": "Q4DZN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZN7"
  },
  {
    "uniprot": "Q4DSP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSP3"
  },
  {
    "uniprot": "Q4CPM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPM4"
  },
  {
    "uniprot": "Q4DN01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN01"
  },
  {
    "uniprot": "Q4CYH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYH6"
  },
  {
    "uniprot": "Q4DTR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTR7"
  },
  {
    "uniprot": "Q4DGJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGJ2"
  },
  {
    "uniprot": "Q4D7I2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7I2"
  },
  {
    "uniprot": "Q4D4J0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4J0"
  },
  {
    "uniprot": "Q4E2G2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2G2"
  },
  {
    "uniprot": "Q4CVZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVZ7"
  },
  {
    "uniprot": "Q4CP60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP60"
  },
  {
    "uniprot": "Q4CKA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKA3"
  },
  {
    "uniprot": "Q4DFL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFL1"
  },
  {
    "uniprot": "Q4E419",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E419"
  },
  {
    "uniprot": "Q4DVD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVD7"
  },
  {
    "uniprot": "Q4E1I6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1I6"
  },
  {
    "uniprot": "Q4D5M9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5M9"
  },
  {
    "uniprot": "Q4CPQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPQ0"
  },
  {
    "uniprot": "Q4E2D7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2D7"
  },
  {
    "uniprot": "Q4DTI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTI6"
  },
  {
    "uniprot": "Q4DDX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDX8"
  },
  {
    "uniprot": "Q4DGC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGC6"
  },
  {
    "uniprot": "Q4DES8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DES8"
  },
  {
    "uniprot": "Q4DZ56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ56"
  },
  {
    "uniprot": "Q4DQ75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ75"
  },
  {
    "uniprot": "Q4DC57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC57"
  },
  {
    "uniprot": "Q4CKD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKD1"
  },
  {
    "uniprot": "Q4DJN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJN2"
  },
  {
    "uniprot": "Q4CUR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUR8"
  },
  {
    "uniprot": "Q4CKM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKM1"
  },
  {
    "uniprot": "Q4CLC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLC4"
  },
  {
    "uniprot": "Q4DQV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQV6"
  },
  {
    "uniprot": "Q4CQ64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ64"
  },
  {
    "uniprot": "Q4E5F6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5F6"
  },
  {
    "uniprot": "Q4DB86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB86"
  },
  {
    "uniprot": "Q4DKZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKZ3"
  },
  {
    "uniprot": "Q4DKA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKA2"
  },
  {
    "uniprot": "Q4DMX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMX7"
  },
  {
    "uniprot": "Q4DS48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS48"
  },
  {
    "uniprot": "Q4D0F1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0F1"
  },
  {
    "uniprot": "Q4E3Z4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Z4"
  },
  {
    "uniprot": "Q4CV93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV93"
  },
  {
    "uniprot": "Q4DZ46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ46"
  },
  {
    "uniprot": "Q4CT33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT33"
  },
  {
    "uniprot": "Q4DY97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY97"
  },
  {
    "uniprot": "Q4DH92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH92"
  },
  {
    "uniprot": "Q4DUH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUH5"
  },
  {
    "uniprot": "Q4DXV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXV2"
  },
  {
    "uniprot": "Q4DXF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXF4"
  },
  {
    "uniprot": "Q4D6V7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6V7"
  },
  {
    "uniprot": "Q4DGX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGX7"
  },
  {
    "uniprot": "Q4CTZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTZ0"
  },
  {
    "uniprot": "Q4DWN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWN3"
  },
  {
    "uniprot": "Q4DAZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAZ6"
  },
  {
    "uniprot": "Q4E2Z8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Z8"
  },
  {
    "uniprot": "Q4CPI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPI6"
  },
  {
    "uniprot": "Q4E406",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E406"
  },
  {
    "uniprot": "Q4D7H5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7H5"
  },
  {
    "uniprot": "Q4E1Y9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Y9"
  },
  {
    "uniprot": "Q4CN17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN17"
  },
  {
    "uniprot": "Q4DH48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH48"
  },
  {
    "uniprot": "Q4CZ01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ01"
  },
  {
    "uniprot": "Q4CKC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKC1"
  },
  {
    "uniprot": "Q4D497",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D497"
  },
  {
    "uniprot": "Q4DFW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFW9"
  },
  {
    "uniprot": "Q4E1V8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1V8"
  },
  {
    "uniprot": "Q4D469",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D469"
  },
  {
    "uniprot": "Q4DEN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEN4"
  },
  {
    "uniprot": "Q4DK54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK54"
  },
  {
    "uniprot": "Q4CYE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYE0"
  },
  {
    "uniprot": "Q4D5F4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5F4"
  },
  {
    "uniprot": "Q4DJW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJW2"
  },
  {
    "uniprot": "Q4DT66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT66"
  },
  {
    "uniprot": "Q4DUF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUF2"
  },
  {
    "uniprot": "Q4CSL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSL3"
  },
  {
    "uniprot": "Q4CXF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXF2"
  },
  {
    "uniprot": "Q4DTL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTL0"
  },
  {
    "uniprot": "Q4DF48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF48"
  },
  {
    "uniprot": "Q4D8M2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8M2"
  },
  {
    "uniprot": "Q4E4A6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4A6"
  },
  {
    "uniprot": "Q4DGZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGZ9"
  },
  {
    "uniprot": "Q4D7D1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7D1"
  },
  {
    "uniprot": "Q4D1C3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1C3"
  },
  {
    "uniprot": "Q4D1C6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1C6"
  },
  {
    "uniprot": "Q4E2F7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2F7"
  },
  {
    "uniprot": "Q4D1P1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1P1"
  },
  {
    "uniprot": "Q4E4M1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4M1"
  },
  {
    "uniprot": "Q4DSI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSI2"
  },
  {
    "uniprot": "Q4CWY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWY8"
  },
  {
    "uniprot": "Q4DJH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJH7"
  },
  {
    "uniprot": "Q4DG81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG81"
  },
  {
    "uniprot": "Q4DCC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCC9"
  },
  {
    "uniprot": "Q4DKB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKB0"
  },
  {
    "uniprot": "Q4CM77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM77"
  },
  {
    "uniprot": "Q4DZE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZE1"
  },
  {
    "uniprot": "Q4DUI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUI2"
  },
  {
    "uniprot": "Q4CS58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS58"
  },
  {
    "uniprot": "Q4DGL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGL6"
  },
  {
    "uniprot": "Q4DS74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS74"
  },
  {
    "uniprot": "Q4CUY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUY7"
  },
  {
    "uniprot": "Q4E130",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E130"
  },
  {
    "uniprot": "Q4D587",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D587"
  },
  {
    "uniprot": "Q4CNP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNP3"
  },
  {
    "uniprot": "Q4CPZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPZ4"
  },
  {
    "uniprot": "Q4D219",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D219"
  },
  {
    "uniprot": "Q4DEV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEV5"
  },
  {
    "uniprot": "Q4E3L4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3L4"
  },
  {
    "uniprot": "Q4DTY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTY0"
  },
  {
    "uniprot": "Q4E0J3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0J3"
  },
  {
    "uniprot": "Q4CZ32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ32"
  },
  {
    "uniprot": "Q4D1L5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1L5"
  },
  {
    "uniprot": "Q4DJF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJF3"
  },
  {
    "uniprot": "Q4DYJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYJ8"
  },
  {
    "uniprot": "Q4CW81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW81"
  },
  {
    "uniprot": "Q4DRD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRD8"
  },
  {
    "uniprot": "Q4D5N7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5N7"
  },
  {
    "uniprot": "Q4DM34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM34"
  },
  {
    "uniprot": "Q4DH45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH45"
  },
  {
    "uniprot": "Q4DDN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDN3"
  },
  {
    "uniprot": "Q4CN06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN06"
  },
  {
    "uniprot": "Q4D0W7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0W7"
  },
  {
    "uniprot": "Q4D441",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D441"
  },
  {
    "uniprot": "Q4D1J0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1J0"
  },
  {
    "uniprot": "Q4DMA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMA5"
  },
  {
    "uniprot": "Q4CKZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKZ6"
  },
  {
    "uniprot": "Q4E123",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E123"
  },
  {
    "uniprot": "Q4DYB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYB7"
  },
  {
    "uniprot": "Q4D389",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D389"
  },
  {
    "uniprot": "Q4DHV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHV8"
  },
  {
    "uniprot": "Q4DH26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH26"
  },
  {
    "uniprot": "Q4DDZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDZ5"
  },
  {
    "uniprot": "Q4DJQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJQ2"
  },
  {
    "uniprot": "Q4D8C8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8C8"
  },
  {
    "uniprot": "Q4D926",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D926"
  },
  {
    "uniprot": "Q4CZ58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ58"
  },
  {
    "uniprot": "Q4DCP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCP6"
  },
  {
    "uniprot": "Q4DLE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLE6"
  },
  {
    "uniprot": "Q4CLJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLJ4"
  },
  {
    "uniprot": "Q4D2B3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2B3"
  },
  {
    "uniprot": "Q4CQ29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ29"
  },
  {
    "uniprot": "Q4DLN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLN8"
  },
  {
    "uniprot": "Q4DRC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRC6"
  },
  {
    "uniprot": "Q4DQ89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ89"
  },
  {
    "uniprot": "Q4DRA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRA1"
  },
  {
    "uniprot": "Q4D2S7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2S7"
  },
  {
    "uniprot": "Q4CXX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXX5"
  },
  {
    "uniprot": "Q4DC88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC88"
  },
  {
    "uniprot": "Q4DRP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRP5"
  },
  {
    "uniprot": "Q4D2P1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2P1"
  },
  {
    "uniprot": "Q4DSQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSQ8"
  },
  {
    "uniprot": "Q4CME1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CME1"
  },
  {
    "uniprot": "Q4D361",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D361"
  },
  {
    "uniprot": "Q4E0I3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0I3"
  },
  {
    "uniprot": "Q4CLM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLM0"
  },
  {
    "uniprot": "Q4D2R4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2R4"
  },
  {
    "uniprot": "Q4DJ09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ09"
  },
  {
    "uniprot": "Q4D8B5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8B5"
  },
  {
    "uniprot": "Q4DHR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHR8"
  },
  {
    "uniprot": "Q4CPZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPZ7"
  },
  {
    "uniprot": "Q4CPC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPC8"
  },
  {
    "uniprot": "Q4E2B3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2B3"
  },
  {
    "uniprot": "Q4DPN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPN5"
  },
  {
    "uniprot": "Q4DYT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYT2"
  },
  {
    "uniprot": "Q4DLW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLW4"
  },
  {
    "uniprot": "Q4D8T3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8T3"
  },
  {
    "uniprot": "Q4D5V7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5V7"
  },
  {
    "uniprot": "Q4DQQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQQ1"
  },
  {
    "uniprot": "Q4DXF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXF8"
  },
  {
    "uniprot": "Q4D3S3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3S3"
  },
  {
    "uniprot": "Q4CS90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS90"
  },
  {
    "uniprot": "Q4CML9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CML9"
  },
  {
    "uniprot": "Q4DMP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMP9"
  },
  {
    "uniprot": "Q4DLJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLJ5"
  },
  {
    "uniprot": "Q4E0Z6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Z6"
  },
  {
    "uniprot": "Q4DVT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVT5"
  },
  {
    "uniprot": "Q4CRS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRS1"
  },
  {
    "uniprot": "Q4DNG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNG4"
  },
  {
    "uniprot": "Q4D747",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D747"
  },
  {
    "uniprot": "Q4CUU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUU1"
  },
  {
    "uniprot": "Q4DNG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNG3"
  },
  {
    "uniprot": "Q4E3L5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3L5"
  },
  {
    "uniprot": "Q4D5G8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5G8"
  },
  {
    "uniprot": "Q4CKK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKK7"
  },
  {
    "uniprot": "Q4CKK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKK1"
  },
  {
    "uniprot": "Q4DYS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYS6"
  },
  {
    "uniprot": "Q4DZM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZM3"
  },
  {
    "uniprot": "Q4DD18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD18"
  },
  {
    "uniprot": "Q4CVY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVY8"
  },
  {
    "uniprot": "Q4E3D5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3D5"
  },
  {
    "uniprot": "Q4DUZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUZ8"
  },
  {
    "uniprot": "Q4CKL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKL7"
  },
  {
    "uniprot": "Q4CRI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRI4"
  },
  {
    "uniprot": "Q4CMK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMK9"
  },
  {
    "uniprot": "Q4E1U0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1U0"
  },
  {
    "uniprot": "Q4CZ04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ04"
  },
  {
    "uniprot": "Q4DHI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHI3"
  },
  {
    "uniprot": "Q4CW83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW83"
  },
  {
    "uniprot": "Q4CZW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZW6"
  },
  {
    "uniprot": "Q4DWK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWK5"
  },
  {
    "uniprot": "Q4D087",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D087"
  },
  {
    "uniprot": "Q4DFK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFK6"
  },
  {
    "uniprot": "Q4E663",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E663"
  },
  {
    "uniprot": "Q4DLZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLZ5"
  },
  {
    "uniprot": "Q4DBT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBT0"
  },
  {
    "uniprot": "Q4CUU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUU0"
  },
  {
    "uniprot": "Q4DV03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV03"
  },
  {
    "uniprot": "Q4CX60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX60"
  },
  {
    "uniprot": "Q4DXG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXG4"
  },
  {
    "uniprot": "Q4DFL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFL4"
  },
  {
    "uniprot": "Q4CL81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL81"
  },
  {
    "uniprot": "Q4D0T1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0T1"
  },
  {
    "uniprot": "Q4E363",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E363"
  },
  {
    "uniprot": "Q4D274",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D274"
  },
  {
    "uniprot": "Q4CN74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN74"
  },
  {
    "uniprot": "Q4D4V4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4V4"
  },
  {
    "uniprot": "Q4E3Z9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Z9"
  },
  {
    "uniprot": "Q4DWY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWY5"
  },
  {
    "uniprot": "Q4CTU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTU6"
  },
  {
    "uniprot": "Q4DQ20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ20"
  },
  {
    "uniprot": "Q4CTT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTT0"
  },
  {
    "uniprot": "Q4E5M0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5M0"
  },
  {
    "uniprot": "Q4CR12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR12"
  },
  {
    "uniprot": "Q4DHP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHP4"
  },
  {
    "uniprot": "Q4DC49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC49"
  },
  {
    "uniprot": "Q4DC66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC66"
  },
  {
    "uniprot": "Q4D1U5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1U5"
  },
  {
    "uniprot": "Q4DP57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP57"
  },
  {
    "uniprot": "Q4DG45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG45"
  },
  {
    "uniprot": "Q4E324",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E324"
  },
  {
    "uniprot": "Q4DVG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVG7"
  },
  {
    "uniprot": "Q4D800",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D800"
  },
  {
    "uniprot": "Q4DK14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK14"
  },
  {
    "uniprot": "Q4E5A6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5A6"
  },
  {
    "uniprot": "Q4DQ17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ17"
  },
  {
    "uniprot": "Q4DUB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUB3"
  },
  {
    "uniprot": "Q4D753",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D753"
  },
  {
    "uniprot": "Q4CXP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXP5"
  },
  {
    "uniprot": "Q4DRF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRF5"
  },
  {
    "uniprot": "Q4E5J5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5J5"
  },
  {
    "uniprot": "Q4D4S2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4S2"
  },
  {
    "uniprot": "Q4D4C4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4C4"
  },
  {
    "uniprot": "Q4CTM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTM5"
  },
  {
    "uniprot": "Q4D8J4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8J4"
  },
  {
    "uniprot": "Q4D1B9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1B9"
  },
  {
    "uniprot": "Q4DKW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKW2"
  },
  {
    "uniprot": "Q4DW48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW48"
  },
  {
    "uniprot": "Q4DQ11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ11"
  },
  {
    "uniprot": "Q4E187",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E187"
  },
  {
    "uniprot": "Q4E370",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E370"
  },
  {
    "uniprot": "Q4DJ25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ25"
  },
  {
    "uniprot": "Q4DBG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBG8"
  },
  {
    "uniprot": "Q4D6E2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6E2"
  },
  {
    "uniprot": "Q4DKJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKJ8"
  },
  {
    "uniprot": "Q4DUI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUI4"
  },
  {
    "uniprot": "Q4DPG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPG4"
  },
  {
    "uniprot": "Q4DXY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXY1"
  },
  {
    "uniprot": "Q4E5G8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5G8"
  },
  {
    "uniprot": "Q4DGU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGU5"
  },
  {
    "uniprot": "Q4DP24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP24"
  },
  {
    "uniprot": "Q4E1C2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1C2"
  },
  {
    "uniprot": "Q4DUU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUU5"
  },
  {
    "uniprot": "Q4CUZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUZ5"
  },
  {
    "uniprot": "Q4D1E2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1E2"
  },
  {
    "uniprot": "Q4D3X1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3X1"
  },
  {
    "uniprot": "Q4D5L6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5L6"
  },
  {
    "uniprot": "Q4CTU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTU9"
  },
  {
    "uniprot": "Q4CNW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNW9"
  },
  {
    "uniprot": "Q4D4P0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4P0"
  },
  {
    "uniprot": "Q4DCW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCW2"
  },
  {
    "uniprot": "Q4CZ67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ67"
  },
  {
    "uniprot": "Q4DUD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUD7"
  },
  {
    "uniprot": "Q4DFN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFN2"
  },
  {
    "uniprot": "Q4DWQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWQ5"
  },
  {
    "uniprot": "Q4E001",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E001"
  },
  {
    "uniprot": "Q4CPQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPQ8"
  },
  {
    "uniprot": "Q4E5N0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5N0"
  },
  {
    "uniprot": "Q4DXF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXF9"
  },
  {
    "uniprot": "Q4DXC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXC4"
  },
  {
    "uniprot": "Q4CKS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKS7"
  },
  {
    "uniprot": "Q4DX53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX53"
  },
  {
    "uniprot": "Q4CPB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPB8"
  },
  {
    "uniprot": "Q4D3V5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3V5"
  },
  {
    "uniprot": "Q4D9R0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9R0"
  },
  {
    "uniprot": "Q4E2R9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2R9"
  },
  {
    "uniprot": "Q4DG71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG71"
  },
  {
    "uniprot": "Q4DT84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT84"
  },
  {
    "uniprot": "Q4CQ75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ75"
  },
  {
    "uniprot": "Q4DU33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU33"
  },
  {
    "uniprot": "Q4E1D2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1D2"
  },
  {
    "uniprot": "Q4CZK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZK5"
  },
  {
    "uniprot": "Q4DHV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHV2"
  },
  {
    "uniprot": "Q4CKB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKB4"
  },
  {
    "uniprot": "Q4DR15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR15"
  },
  {
    "uniprot": "Q4DG16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG16"
  },
  {
    "uniprot": "Q4DWD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWD9"
  },
  {
    "uniprot": "Q4CS35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS35"
  },
  {
    "uniprot": "Q4D460",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D460"
  },
  {
    "uniprot": "Q4CZI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZI2"
  },
  {
    "uniprot": "Q4DTP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTP4"
  },
  {
    "uniprot": "Q4CYS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYS3"
  },
  {
    "uniprot": "Q4DQV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQV8"
  },
  {
    "uniprot": "Q4DPC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPC7"
  },
  {
    "uniprot": "Q4D3W6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3W6"
  },
  {
    "uniprot": "Q4DX78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX78"
  },
  {
    "uniprot": "Q4CS66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS66"
  },
  {
    "uniprot": "Q4CPP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPP5"
  },
  {
    "uniprot": "Q4DZQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZQ8"
  },
  {
    "uniprot": "Q4D7F0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7F0"
  },
  {
    "uniprot": "Q4DPY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPY0"
  },
  {
    "uniprot": "Q4CXE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXE5"
  },
  {
    "uniprot": "Q4DAG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAG4"
  },
  {
    "uniprot": "Q4D4K7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4K7"
  },
  {
    "uniprot": "Q4DEK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEK7"
  },
  {
    "uniprot": "Q4DPA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPA0"
  },
  {
    "uniprot": "Q4DG99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG99"
  },
  {
    "uniprot": "Q4D7D4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7D4"
  },
  {
    "uniprot": "Q4DDT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDT1"
  },
  {
    "uniprot": "Q4CMY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMY9"
  },
  {
    "uniprot": "Q4CZT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZT4"
  },
  {
    "uniprot": "Q4D053",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D053"
  },
  {
    "uniprot": "Q4DS05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS05"
  },
  {
    "uniprot": "Q4DF38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF38"
  },
  {
    "uniprot": "Q4CRA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRA0"
  },
  {
    "uniprot": "Q4DN07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN07"
  },
  {
    "uniprot": "Q4DG11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG11"
  },
  {
    "uniprot": "Q4DSI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSI7"
  },
  {
    "uniprot": "Q4CP82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP82"
  },
  {
    "uniprot": "Q4CMZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMZ6"
  },
  {
    "uniprot": "Q4DBE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBE2"
  },
  {
    "uniprot": "Q4DCM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCM0"
  },
  {
    "uniprot": "Q4DTR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTR6"
  },
  {
    "uniprot": "Q4DDN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDN6"
  },
  {
    "uniprot": "Q4CXX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXX2"
  },
  {
    "uniprot": "Q4DB49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB49"
  },
  {
    "uniprot": "Q4CWT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWT1"
  },
  {
    "uniprot": "Q4DTM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTM4"
  },
  {
    "uniprot": "Q4CN51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN51"
  },
  {
    "uniprot": "Q4E3X5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3X5"
  },
  {
    "uniprot": "Q4E1H1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1H1"
  },
  {
    "uniprot": "Q4D0J0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0J0"
  },
  {
    "uniprot": "Q4DT79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT79"
  },
  {
    "uniprot": "Q4CS41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS41"
  },
  {
    "uniprot": "Q4CMT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMT1"
  },
  {
    "uniprot": "Q4DW14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW14"
  },
  {
    "uniprot": "Q4DBZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBZ9"
  },
  {
    "uniprot": "Q4DN30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN30"
  },
  {
    "uniprot": "Q4CQY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQY5"
  },
  {
    "uniprot": "Q4DDC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDC4"
  },
  {
    "uniprot": "Q4DGV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGV0"
  },
  {
    "uniprot": "Q4CUV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUV1"
  },
  {
    "uniprot": "Q4D1G3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1G3"
  },
  {
    "uniprot": "Q4CS45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS45"
  },
  {
    "uniprot": "Q4DWF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWF9"
  },
  {
    "uniprot": "Q4DEW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEW2"
  },
  {
    "uniprot": "Q4CUQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUQ1"
  },
  {
    "uniprot": "Q4CKN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKN7"
  },
  {
    "uniprot": "Q4DPA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPA7"
  },
  {
    "uniprot": "Q4CQJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQJ1"
  },
  {
    "uniprot": "Q4E1A4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1A4"
  },
  {
    "uniprot": "Q4D3I0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3I0"
  },
  {
    "uniprot": "Q4DRZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRZ9"
  },
  {
    "uniprot": "Q4CX94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX94"
  },
  {
    "uniprot": "Q4CQ80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ80"
  },
  {
    "uniprot": "Q4DPF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPF3"
  },
  {
    "uniprot": "Q4D1T9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1T9"
  },
  {
    "uniprot": "Q4CUX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUX1"
  },
  {
    "uniprot": "Q4CUK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUK1"
  },
  {
    "uniprot": "Q4CYZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYZ2"
  },
  {
    "uniprot": "Q4CNU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNU7"
  },
  {
    "uniprot": "Q4D987",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D987"
  },
  {
    "uniprot": "Q4CXM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXM0"
  },
  {
    "uniprot": "Q4D1N4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1N4"
  },
  {
    "uniprot": "Q4E551",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E551"
  },
  {
    "uniprot": "Q4E095",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E095"
  },
  {
    "uniprot": "Q4DE59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE59"
  },
  {
    "uniprot": "Q4DTB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTB4"
  },
  {
    "uniprot": "Q4E343",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E343"
  },
  {
    "uniprot": "Q4D5E4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5E4"
  },
  {
    "uniprot": "Q4DN84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN84"
  },
  {
    "uniprot": "Q4DDX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDX2"
  },
  {
    "uniprot": "Q4D9V8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9V8"
  },
  {
    "uniprot": "Q4CU25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU25"
  },
  {
    "uniprot": "Q4CWV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWV9"
  },
  {
    "uniprot": "Q4CRG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRG8"
  },
  {
    "uniprot": "Q4CYZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYZ4"
  },
  {
    "uniprot": "Q4CKM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKM6"
  },
  {
    "uniprot": "Q4DCR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCR0"
  },
  {
    "uniprot": "Q4DC82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC82"
  },
  {
    "uniprot": "Q4DUC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUC0"
  },
  {
    "uniprot": "Q4DAV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAV6"
  },
  {
    "uniprot": "Q4D594",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D594"
  },
  {
    "uniprot": "Q4DNC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNC2"
  },
  {
    "uniprot": "Q4DB82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB82"
  },
  {
    "uniprot": "Q4DWE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWE6"
  },
  {
    "uniprot": "Q4CM81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM81"
  },
  {
    "uniprot": "Q4D6N0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6N0"
  },
  {
    "uniprot": "Q4CU14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU14"
  },
  {
    "uniprot": "Q4CTH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTH2"
  },
  {
    "uniprot": "Q4CPD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPD6"
  },
  {
    "uniprot": "Q4DIW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIW7"
  },
  {
    "uniprot": "Q4DUV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUV0"
  },
  {
    "uniprot": "Q4CXD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXD4"
  },
  {
    "uniprot": "Q4DKY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKY4"
  },
  {
    "uniprot": "Q4CTG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTG5"
  },
  {
    "uniprot": "Q4CPW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPW0"
  },
  {
    "uniprot": "Q4DSX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSX6"
  },
  {
    "uniprot": "Q4CXN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXN2"
  },
  {
    "uniprot": "Q4E4H4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4H4"
  },
  {
    "uniprot": "Q4D9H2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9H2"
  },
  {
    "uniprot": "Q4DLG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLG4"
  },
  {
    "uniprot": "Q4E2Y6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Y6"
  },
  {
    "uniprot": "Q4DI96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI96"
  },
  {
    "uniprot": "Q4E5F3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5F3"
  },
  {
    "uniprot": "Q4DA34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA34"
  },
  {
    "uniprot": "Q4DK39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK39"
  },
  {
    "uniprot": "Q4DV38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV38"
  },
  {
    "uniprot": "Q4DE78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE78"
  },
  {
    "uniprot": "Q4DEE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEE2"
  },
  {
    "uniprot": "Q4D3A9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3A9"
  },
  {
    "uniprot": "Q4CKK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKK8"
  },
  {
    "uniprot": "Q4D406",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D406"
  },
  {
    "uniprot": "Q4DDK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDK1"
  },
  {
    "uniprot": "Q4D1F3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1F3"
  },
  {
    "uniprot": "Q4DQX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQX4"
  },
  {
    "uniprot": "Q4DZW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZW4"
  },
  {
    "uniprot": "Q4CR32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR32"
  },
  {
    "uniprot": "Q4D4B3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4B3"
  },
  {
    "uniprot": "Q4D1P8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1P8"
  },
  {
    "uniprot": "Q4CKG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKG2"
  },
  {
    "uniprot": "Q4DKN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKN8"
  },
  {
    "uniprot": "Q4DBW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBW4"
  },
  {
    "uniprot": "Q4DUD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUD4"
  },
  {
    "uniprot": "Q4DGL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGL1"
  },
  {
    "uniprot": "Q4D1H9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1H9"
  },
  {
    "uniprot": "Q4E5E2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5E2"
  },
  {
    "uniprot": "Q4DXG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXG0"
  },
  {
    "uniprot": "Q4D0D3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0D3"
  },
  {
    "uniprot": "Q4CM63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM63"
  },
  {
    "uniprot": "Q4DX23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX23"
  },
  {
    "uniprot": "Q4DBT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBT9"
  },
  {
    "uniprot": "Q4DFJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFJ1"
  },
  {
    "uniprot": "Q4CPF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPF9"
  },
  {
    "uniprot": "Q4CUE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUE9"
  },
  {
    "uniprot": "Q4CUJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUJ6"
  },
  {
    "uniprot": "Q4DCD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCD6"
  },
  {
    "uniprot": "Q4DE17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE17"
  },
  {
    "uniprot": "Q4E486",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E486"
  },
  {
    "uniprot": "Q4D3K9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3K9"
  },
  {
    "uniprot": "Q4DGG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGG6"
  },
  {
    "uniprot": "Q4DZ58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ58"
  },
  {
    "uniprot": "Q4D1J1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1J1"
  },
  {
    "uniprot": "Q4D284",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D284"
  },
  {
    "uniprot": "Q4DX74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX74"
  },
  {
    "uniprot": "Q4E3S9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3S9"
  },
  {
    "uniprot": "Q4DFF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFF8"
  },
  {
    "uniprot": "Q4E098",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E098"
  },
  {
    "uniprot": "Q4DWS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWS3"
  },
  {
    "uniprot": "Q4E135",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E135"
  },
  {
    "uniprot": "Q4DVZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVZ9"
  },
  {
    "uniprot": "Q4CVP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVP7"
  },
  {
    "uniprot": "Q4DSE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSE2"
  },
  {
    "uniprot": "Q4D4C9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4C9"
  },
  {
    "uniprot": "Q4CYV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYV7"
  },
  {
    "uniprot": "Q4DHM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHM1"
  },
  {
    "uniprot": "Q4DQN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQN5"
  },
  {
    "uniprot": "Q4CZU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZU4"
  },
  {
    "uniprot": "Q4CQN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQN5"
  },
  {
    "uniprot": "Q4D392",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D392"
  },
  {
    "uniprot": "Q4DWS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWS2"
  },
  {
    "uniprot": "Q4CXY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXY7"
  },
  {
    "uniprot": "Q4DCM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCM9"
  },
  {
    "uniprot": "Q4DHK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHK7"
  },
  {
    "uniprot": "Q4D5D6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5D6"
  },
  {
    "uniprot": "Q4CL70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL70"
  },
  {
    "uniprot": "Q4DSM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSM0"
  },
  {
    "uniprot": "Q4D0I5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0I5"
  },
  {
    "uniprot": "Q4DFF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFF3"
  },
  {
    "uniprot": "Q4DV76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV76"
  },
  {
    "uniprot": "Q4DBF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBF5"
  },
  {
    "uniprot": "Q4DL92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL92"
  },
  {
    "uniprot": "Q4DK77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK77"
  },
  {
    "uniprot": "Q4DWP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWP6"
  },
  {
    "uniprot": "Q4D7S8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7S8"
  },
  {
    "uniprot": "Q4CN53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN53"
  },
  {
    "uniprot": "Q4D8E9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8E9"
  },
  {
    "uniprot": "Q4CWA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWA5"
  },
  {
    "uniprot": "Q4CPA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPA3"
  },
  {
    "uniprot": "Q4E5D5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5D5"
  },
  {
    "uniprot": "Q4D3G4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3G4"
  },
  {
    "uniprot": "Q4DCX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCX6"
  },
  {
    "uniprot": "Q4DXQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXQ4"
  },
  {
    "uniprot": "Q4CQ96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ96"
  },
  {
    "uniprot": "Q4DJ55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ55"
  },
  {
    "uniprot": "Q4DBP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBP2"
  },
  {
    "uniprot": "Q4CY72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY72"
  },
  {
    "uniprot": "Q4DCQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCQ7"
  },
  {
    "uniprot": "Q4CT34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT34"
  },
  {
    "uniprot": "Q4DB39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB39"
  },
  {
    "uniprot": "Q4D8U1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8U1"
  },
  {
    "uniprot": "Q4DV17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV17"
  },
  {
    "uniprot": "Q4DYZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYZ3"
  },
  {
    "uniprot": "Q4D7N0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7N0"
  },
  {
    "uniprot": "Q4D0A2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0A2"
  },
  {
    "uniprot": "Q4DX96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX96"
  },
  {
    "uniprot": "Q4CN08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN08"
  },
  {
    "uniprot": "Q4E3G5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3G5"
  },
  {
    "uniprot": "Q4D877",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D877"
  },
  {
    "uniprot": "Q4DW28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW28"
  },
  {
    "uniprot": "Q4DGQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGQ0"
  },
  {
    "uniprot": "Q4DMX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMX9"
  },
  {
    "uniprot": "Q4D2G6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2G6"
  },
  {
    "uniprot": "Q4CS64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS64"
  },
  {
    "uniprot": "Q4CKY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKY8"
  },
  {
    "uniprot": "Q4D0D1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0D1"
  },
  {
    "uniprot": "Q4DLC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLC5"
  },
  {
    "uniprot": "Q4CNH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNH4"
  },
  {
    "uniprot": "Q4CYX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYX6"
  },
  {
    "uniprot": "Q4DMQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMQ3"
  },
  {
    "uniprot": "Q4DVS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVS4"
  },
  {
    "uniprot": "Q4DFG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFG6"
  },
  {
    "uniprot": "Q4DKX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKX5"
  },
  {
    "uniprot": "Q4DUE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUE6"
  },
  {
    "uniprot": "Q4DRH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRH1"
  },
  {
    "uniprot": "Q4DC21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC21"
  },
  {
    "uniprot": "Q4D395",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D395"
  },
  {
    "uniprot": "Q4E4Q7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Q7"
  },
  {
    "uniprot": "Q4CN39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN39"
  },
  {
    "uniprot": "Q4E107",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E107"
  },
  {
    "uniprot": "Q4E4X8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4X8"
  },
  {
    "uniprot": "Q4CW48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW48"
  },
  {
    "uniprot": "Q4CKD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKD7"
  },
  {
    "uniprot": "Q4CZW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZW0"
  },
  {
    "uniprot": "Q4DFM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFM1"
  },
  {
    "uniprot": "Q4DPM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPM5"
  },
  {
    "uniprot": "Q4DCF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCF1"
  },
  {
    "uniprot": "Q4DBB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBB9"
  },
  {
    "uniprot": "Q4DS32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS32"
  },
  {
    "uniprot": "Q4D1X0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1X0"
  },
  {
    "uniprot": "Q4DQU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQU3"
  },
  {
    "uniprot": "Q4CYK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYK3"
  },
  {
    "uniprot": "Q4DC12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC12"
  },
  {
    "uniprot": "Q4D3L5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3L5"
  },
  {
    "uniprot": "Q4CLB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLB0"
  },
  {
    "uniprot": "Q4E183",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E183"
  },
  {
    "uniprot": "Q4E374",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E374"
  },
  {
    "uniprot": "Q4DLI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLI6"
  },
  {
    "uniprot": "Q4E1E4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1E4"
  },
  {
    "uniprot": "Q4DJ66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ66"
  },
  {
    "uniprot": "Q4CYF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYF0"
  },
  {
    "uniprot": "Q4CXT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXT6"
  },
  {
    "uniprot": "Q4DFK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFK3"
  },
  {
    "uniprot": "Q4DI18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI18"
  },
  {
    "uniprot": "Q4DW33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW33"
  },
  {
    "uniprot": "Q4D543",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D543"
  },
  {
    "uniprot": "Q4CUC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUC4"
  },
  {
    "uniprot": "Q4DJV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJV5"
  },
  {
    "uniprot": "Q4CU86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU86"
  },
  {
    "uniprot": "Q4CRI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRI2"
  },
  {
    "uniprot": "Q4CYY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYY7"
  },
  {
    "uniprot": "Q4DZ64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ64"
  },
  {
    "uniprot": "Q4DU09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU09"
  },
  {
    "uniprot": "Q4D0F5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0F5"
  },
  {
    "uniprot": "Q4CZ31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ31"
  },
  {
    "uniprot": "Q4E4G4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4G4"
  },
  {
    "uniprot": "Q4E4J9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4J9"
  },
  {
    "uniprot": "Q4D8C5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8C5"
  },
  {
    "uniprot": "Q4E2W1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2W1"
  },
  {
    "uniprot": "Q4D3U5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3U5"
  },
  {
    "uniprot": "Q4DSZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSZ7"
  },
  {
    "uniprot": "Q4D633",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D633"
  },
  {
    "uniprot": "Q4DHM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHM0"
  },
  {
    "uniprot": "Q4CWW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWW6"
  },
  {
    "uniprot": "Q4DXE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXE4"
  },
  {
    "uniprot": "Q4D463",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D463"
  },
  {
    "uniprot": "Q4D3N2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3N2"
  },
  {
    "uniprot": "Q4CZD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZD0"
  },
  {
    "uniprot": "Q4CZZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZZ5"
  },
  {
    "uniprot": "Q4E5H3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5H3"
  },
  {
    "uniprot": "Q4DSI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSI8"
  },
  {
    "uniprot": "Q4DC80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC80"
  },
  {
    "uniprot": "Q4D3H8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3H8"
  },
  {
    "uniprot": "Q4CRB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRB1"
  },
  {
    "uniprot": "Q4CZA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZA8"
  },
  {
    "uniprot": "Q4D9M7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9M7"
  },
  {
    "uniprot": "Q4DZ09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ09"
  },
  {
    "uniprot": "Q4D117",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D117"
  },
  {
    "uniprot": "Q4CWS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWS2"
  },
  {
    "uniprot": "Q4DGD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGD4"
  },
  {
    "uniprot": "Q4DQV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQV2"
  },
  {
    "uniprot": "Q4CPA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPA9"
  },
  {
    "uniprot": "Q4D604",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D604"
  },
  {
    "uniprot": "Q4DCB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCB2"
  },
  {
    "uniprot": "Q4DH20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH20"
  },
  {
    "uniprot": "Q4DQK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQK0"
  },
  {
    "uniprot": "Q4D8M9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8M9"
  },
  {
    "uniprot": "Q4CPS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPS3"
  },
  {
    "uniprot": "Q4DFX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFX2"
  },
  {
    "uniprot": "Q4DXT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXT9"
  },
  {
    "uniprot": "Q4DBZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBZ6"
  },
  {
    "uniprot": "Q4DG36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG36"
  },
  {
    "uniprot": "Q4DXP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXP8"
  },
  {
    "uniprot": "Q4DG10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG10"
  },
  {
    "uniprot": "Q4CVK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVK8"
  },
  {
    "uniprot": "Q4E493",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E493"
  },
  {
    "uniprot": "Q4D074",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D074"
  },
  {
    "uniprot": "Q4DHJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHJ3"
  },
  {
    "uniprot": "Q4DP37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP37"
  },
  {
    "uniprot": "Q4D5J8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5J8"
  },
  {
    "uniprot": "Q4DLR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLR7"
  },
  {
    "uniprot": "Q4CP28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP28"
  },
  {
    "uniprot": "Q4DRH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRH2"
  },
  {
    "uniprot": "Q4CUY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUY9"
  },
  {
    "uniprot": "Q4DVC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVC8"
  },
  {
    "uniprot": "Q4DL98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL98"
  },
  {
    "uniprot": "Q4D135",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D135"
  },
  {
    "uniprot": "Q4DSI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSI3"
  },
  {
    "uniprot": "Q4DTT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTT4"
  },
  {
    "uniprot": "Q4DQR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQR8"
  },
  {
    "uniprot": "Q4D9N8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9N8"
  },
  {
    "uniprot": "Q4CVZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVZ2"
  },
  {
    "uniprot": "Q4DYB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYB3"
  },
  {
    "uniprot": "Q4CX99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX99"
  },
  {
    "uniprot": "Q4DY09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY09"
  },
  {
    "uniprot": "Q4D7L7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7L7"
  },
  {
    "uniprot": "Q4CYD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYD7"
  },
  {
    "uniprot": "Q4E568",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E568"
  },
  {
    "uniprot": "Q4E085",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E085"
  },
  {
    "uniprot": "Q4DZZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZZ5"
  },
  {
    "uniprot": "Q4D2F3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2F3"
  },
  {
    "uniprot": "Q4CRF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRF3"
  },
  {
    "uniprot": "Q4CQU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQU7"
  },
  {
    "uniprot": "Q4E5P0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5P0"
  },
  {
    "uniprot": "Q4CXV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXV3"
  },
  {
    "uniprot": "Q4CQQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQQ8"
  },
  {
    "uniprot": "Q4E4L0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4L0"
  },
  {
    "uniprot": "Q4DAU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAU4"
  },
  {
    "uniprot": "Q4D0G7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0G7"
  },
  {
    "uniprot": "Q4DNJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNJ7"
  },
  {
    "uniprot": "Q4DKM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKM9"
  },
  {
    "uniprot": "Q4DRS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRS8"
  },
  {
    "uniprot": "Q4DK94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK94"
  },
  {
    "uniprot": "Q4DBP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBP9"
  },
  {
    "uniprot": "Q4DLH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLH4"
  },
  {
    "uniprot": "Q4D4Y3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Y3"
  },
  {
    "uniprot": "Q4CVS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVS0"
  },
  {
    "uniprot": "Q4DWT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWT3"
  },
  {
    "uniprot": "Q4CXE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXE7"
  },
  {
    "uniprot": "Q4D952",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D952"
  },
  {
    "uniprot": "Q4D3F9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3F9"
  },
  {
    "uniprot": "Q4DM15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM15"
  },
  {
    "uniprot": "Q4DY62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY62"
  },
  {
    "uniprot": "Q4DM69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM69"
  },
  {
    "uniprot": "Q4DXT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXT0"
  },
  {
    "uniprot": "Q4D164",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D164"
  },
  {
    "uniprot": "Q4DSX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSX3"
  },
  {
    "uniprot": "Q4CQW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQW9"
  },
  {
    "uniprot": "Q4DUQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUQ3"
  },
  {
    "uniprot": "Q4CZD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZD7"
  },
  {
    "uniprot": "Q4DXK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXK0"
  },
  {
    "uniprot": "Q4CQR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQR6"
  },
  {
    "uniprot": "Q4D1J2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1J2"
  },
  {
    "uniprot": "Q4CKY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKY5"
  },
  {
    "uniprot": "Q4CNY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNY2"
  },
  {
    "uniprot": "Q4DEE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEE4"
  },
  {
    "uniprot": "Q4CT98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT98"
  },
  {
    "uniprot": "Q4CPQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPQ7"
  },
  {
    "uniprot": "Q4DLQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLQ9"
  },
  {
    "uniprot": "Q4CXA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXA8"
  },
  {
    "uniprot": "Q4DNL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNL9"
  },
  {
    "uniprot": "Q4DMZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMZ3"
  },
  {
    "uniprot": "Q4D480",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D480"
  },
  {
    "uniprot": "Q4DQ71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ71"
  },
  {
    "uniprot": "Q4DVI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVI6"
  },
  {
    "uniprot": "Q4E3J0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3J0"
  },
  {
    "uniprot": "Q4DB41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB41"
  },
  {
    "uniprot": "Q4DYT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYT0"
  },
  {
    "uniprot": "Q4D511",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D511"
  },
  {
    "uniprot": "Q4E2B4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2B4"
  },
  {
    "uniprot": "Q4E3D6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3D6"
  },
  {
    "uniprot": "Q4E5V8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5V8"
  },
  {
    "uniprot": "Q4DLH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLH7"
  },
  {
    "uniprot": "Q4D1A5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1A5"
  },
  {
    "uniprot": "Q4DCJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCJ1"
  },
  {
    "uniprot": "Q4CNU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNU2"
  },
  {
    "uniprot": "Q4DJS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJS0"
  },
  {
    "uniprot": "Q4DJH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJH6"
  },
  {
    "uniprot": "Q4CST2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CST2"
  },
  {
    "uniprot": "Q4D973",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D973"
  },
  {
    "uniprot": "Q4DQ58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ58"
  },
  {
    "uniprot": "Q4CRX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRX6"
  },
  {
    "uniprot": "Q4CKY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKY6"
  },
  {
    "uniprot": "Q4DR45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR45"
  },
  {
    "uniprot": "Q4DU26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU26"
  },
  {
    "uniprot": "Q4CPR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPR3"
  },
  {
    "uniprot": "Q4CKZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKZ7"
  },
  {
    "uniprot": "Q4DA98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA98"
  },
  {
    "uniprot": "Q4DY01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY01"
  },
  {
    "uniprot": "Q4DVQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVQ7"
  },
  {
    "uniprot": "Q4CZL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZL1"
  },
  {
    "uniprot": "Q4CLF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLF7"
  },
  {
    "uniprot": "Q4E514",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E514"
  },
  {
    "uniprot": "Q4CVH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVH3"
  },
  {
    "uniprot": "Q4DL18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL18"
  },
  {
    "uniprot": "Q4CKT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKT7"
  },
  {
    "uniprot": "Q4D674",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D674"
  },
  {
    "uniprot": "Q4E5Z6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Z6"
  },
  {
    "uniprot": "Q4DJA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJA5"
  },
  {
    "uniprot": "Q4E2J7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2J7"
  },
  {
    "uniprot": "Q4D5W2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5W2"
  },
  {
    "uniprot": "Q4CKK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKK2"
  },
  {
    "uniprot": "Q4DDZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDZ8"
  },
  {
    "uniprot": "Q4DLM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLM0"
  },
  {
    "uniprot": "Q4CVM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVM5"
  },
  {
    "uniprot": "Q4E144",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E144"
  },
  {
    "uniprot": "Q4DFN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFN5"
  },
  {
    "uniprot": "Q4CZK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZK4"
  },
  {
    "uniprot": "Q4DHU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHU5"
  },
  {
    "uniprot": "Q4D8A9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8A9"
  },
  {
    "uniprot": "Q4E4C8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4C8"
  },
  {
    "uniprot": "Q4E420",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E420"
  },
  {
    "uniprot": "Q4CZB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZB7"
  },
  {
    "uniprot": "Q4D928",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D928"
  },
  {
    "uniprot": "Q4CU34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU34"
  },
  {
    "uniprot": "Q4D2X1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2X1"
  },
  {
    "uniprot": "Q4E2C5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2C5"
  },
  {
    "uniprot": "Q4D805",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D805"
  },
  {
    "uniprot": "Q4DES3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DES3"
  },
  {
    "uniprot": "Q4E238",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E238"
  },
  {
    "uniprot": "Q4D2H2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2H2"
  },
  {
    "uniprot": "Q4CVX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVX2"
  },
  {
    "uniprot": "Q4D1I4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1I4"
  },
  {
    "uniprot": "Q4DYI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYI8"
  },
  {
    "uniprot": "Q4DIL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIL4"
  },
  {
    "uniprot": "Q4DKL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKL0"
  },
  {
    "uniprot": "Q4DE60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE60"
  },
  {
    "uniprot": "Q4DXV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXV5"
  },
  {
    "uniprot": "Q4DSM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSM4"
  },
  {
    "uniprot": "Q4DU64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU64"
  },
  {
    "uniprot": "Q4DCE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCE1"
  },
  {
    "uniprot": "Q4CN72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN72"
  },
  {
    "uniprot": "Q4E5C7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5C7"
  },
  {
    "uniprot": "Q4D498",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D498"
  },
  {
    "uniprot": "Q4DP40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP40"
  },
  {
    "uniprot": "Q4DWY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWY9"
  },
  {
    "uniprot": "Q4E5K3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5K3"
  },
  {
    "uniprot": "Q4E3Q1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Q1"
  },
  {
    "uniprot": "Q4DG28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG28"
  },
  {
    "uniprot": "Q4CWD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWD1"
  },
  {
    "uniprot": "Q4DA28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA28"
  },
  {
    "uniprot": "Q4DGJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGJ8"
  },
  {
    "uniprot": "Q4D1E9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1E9"
  },
  {
    "uniprot": "Q4DUU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUU0"
  },
  {
    "uniprot": "Q4DXR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXR0"
  },
  {
    "uniprot": "Q4CNN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNN5"
  },
  {
    "uniprot": "Q4E648",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E648"
  },
  {
    "uniprot": "Q4E3Z7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Z7"
  },
  {
    "uniprot": "Q4DZB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZB9"
  },
  {
    "uniprot": "Q4E334",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E334"
  },
  {
    "uniprot": "Q4DY51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY51"
  },
  {
    "uniprot": "Q4DFG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFG8"
  },
  {
    "uniprot": "Q4CP15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP15"
  },
  {
    "uniprot": "Q4DDQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDQ7"
  },
  {
    "uniprot": "Q4E394",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E394"
  },
  {
    "uniprot": "Q4CXS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXS4"
  },
  {
    "uniprot": "Q4D1C7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1C7"
  },
  {
    "uniprot": "Q4DCA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCA1"
  },
  {
    "uniprot": "Q4D5C5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5C5"
  },
  {
    "uniprot": "Q4DQB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQB4"
  },
  {
    "uniprot": "Q4DII4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DII4"
  },
  {
    "uniprot": "Q4DPJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPJ0"
  },
  {
    "uniprot": "Q4E3W2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3W2"
  },
  {
    "uniprot": "Q4DSJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSJ4"
  },
  {
    "uniprot": "Q4CST0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CST0"
  },
  {
    "uniprot": "Q4D1P5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1P5"
  },
  {
    "uniprot": "Q4CKQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKQ8"
  },
  {
    "uniprot": "Q4DX85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX85"
  },
  {
    "uniprot": "Q4D9C8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9C8"
  },
  {
    "uniprot": "Q4DVA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVA5"
  },
  {
    "uniprot": "Q4DB90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB90"
  },
  {
    "uniprot": "Q4DPL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPL2"
  },
  {
    "uniprot": "Q4D269",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D269"
  },
  {
    "uniprot": "Q4DSP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSP4"
  },
  {
    "uniprot": "Q4DGN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGN0"
  },
  {
    "uniprot": "Q4CN91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN91"
  },
  {
    "uniprot": "Q4DJV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJV6"
  },
  {
    "uniprot": "Q4CMW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMW1"
  },
  {
    "uniprot": "Q4DUC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUC6"
  },
  {
    "uniprot": "Q4E074",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E074"
  },
  {
    "uniprot": "Q4E151",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E151"
  },
  {
    "uniprot": "Q4CRF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRF1"
  },
  {
    "uniprot": "Q4CSH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSH0"
  },
  {
    "uniprot": "Q4D8T6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8T6"
  },
  {
    "uniprot": "Q4DM94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM94"
  },
  {
    "uniprot": "Q4CXJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXJ1"
  },
  {
    "uniprot": "Q4E212",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E212"
  },
  {
    "uniprot": "Q4E4B0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4B0"
  },
  {
    "uniprot": "Q4DBG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBG7"
  },
  {
    "uniprot": "Q4DQW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQW2"
  },
  {
    "uniprot": "Q4E595",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E595"
  },
  {
    "uniprot": "Q4DBD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBD1"
  },
  {
    "uniprot": "Q4D1J9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1J9"
  },
  {
    "uniprot": "Q4D821",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D821"
  },
  {
    "uniprot": "Q4DNV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNV3"
  },
  {
    "uniprot": "Q4D472",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D472"
  },
  {
    "uniprot": "Q4DG62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG62"
  },
  {
    "uniprot": "Q4CM66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM66"
  },
  {
    "uniprot": "Q4DTI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTI9"
  },
  {
    "uniprot": "Q4CWL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWL5"
  },
  {
    "uniprot": "Q4CKZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKZ8"
  },
  {
    "uniprot": "Q4DAK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAK9"
  },
  {
    "uniprot": "Q4CQ25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ25"
  },
  {
    "uniprot": "Q4D0L1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0L1"
  },
  {
    "uniprot": "Q4D9D4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9D4"
  },
  {
    "uniprot": "Q4DTU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTU7"
  },
  {
    "uniprot": "Q4CK87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CK87"
  },
  {
    "uniprot": "Q4DI76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI76"
  },
  {
    "uniprot": "Q4DWW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWW9"
  },
  {
    "uniprot": "Q4DRY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRY5"
  },
  {
    "uniprot": "Q4CQS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQS7"
  },
  {
    "uniprot": "Q4DLC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLC6"
  },
  {
    "uniprot": "Q4CLN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLN7"
  },
  {
    "uniprot": "Q4CPY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPY7"
  },
  {
    "uniprot": "Q4D5M8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5M8"
  },
  {
    "uniprot": "Q4D4L1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4L1"
  },
  {
    "uniprot": "Q4CTA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTA8"
  },
  {
    "uniprot": "Q4E4U8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4U8"
  },
  {
    "uniprot": "Q4CWN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWN1"
  },
  {
    "uniprot": "Q4DA82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA82"
  },
  {
    "uniprot": "Q4D6F0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6F0"
  },
  {
    "uniprot": "Q4CQA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQA5"
  },
  {
    "uniprot": "Q4CRB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRB7"
  },
  {
    "uniprot": "Q4DT09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT09"
  },
  {
    "uniprot": "Q4DDS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDS5"
  },
  {
    "uniprot": "Q4CP37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP37"
  },
  {
    "uniprot": "Q4DG93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG93"
  },
  {
    "uniprot": "Q4CKC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKC8"
  },
  {
    "uniprot": "Q4CWR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWR1"
  },
  {
    "uniprot": "Q4DKV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKV4"
  },
  {
    "uniprot": "Q4CX54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX54"
  },
  {
    "uniprot": "Q4DZ74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ74"
  },
  {
    "uniprot": "Q4D1Y8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Y8"
  },
  {
    "uniprot": "Q4D9U7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9U7"
  },
  {
    "uniprot": "Q4DZ78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ78"
  },
  {
    "uniprot": "Q4E021",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E021"
  },
  {
    "uniprot": "Q4CTV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTV9"
  },
  {
    "uniprot": "Q4DI40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI40"
  },
  {
    "uniprot": "Q4E465",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E465"
  },
  {
    "uniprot": "Q4D2M5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2M5"
  },
  {
    "uniprot": "Q4CTQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTQ6"
  },
  {
    "uniprot": "Q4CN73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN73"
  },
  {
    "uniprot": "Q4DQP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQP6"
  },
  {
    "uniprot": "Q4DHY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHY6"
  },
  {
    "uniprot": "Q4DSK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSK8"
  },
  {
    "uniprot": "Q4DCZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCZ6"
  },
  {
    "uniprot": "Q4DWS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWS8"
  },
  {
    "uniprot": "Q4E2S3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2S3"
  },
  {
    "uniprot": "Q4D2C4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2C4"
  },
  {
    "uniprot": "Q4DD32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD32"
  },
  {
    "uniprot": "Q4DEV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEV9"
  },
  {
    "uniprot": "Q4D001",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D001"
  },
  {
    "uniprot": "Q4DZU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZU7"
  },
  {
    "uniprot": "Q4CMY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMY7"
  },
  {
    "uniprot": "Q4CQH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQH1"
  },
  {
    "uniprot": "Q4CL93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL93"
  },
  {
    "uniprot": "Q4CXN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXN8"
  },
  {
    "uniprot": "Q4E3A3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3A3"
  },
  {
    "uniprot": "Q4DCD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCD2"
  },
  {
    "uniprot": "Q4DGV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGV3"
  },
  {
    "uniprot": "Q4DMY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMY2"
  },
  {
    "uniprot": "Q4DND2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DND2"
  },
  {
    "uniprot": "Q4DHC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHC2"
  },
  {
    "uniprot": "Q4DZF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZF8"
  },
  {
    "uniprot": "Q4DE37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE37"
  },
  {
    "uniprot": "Q4DUV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUV8"
  },
  {
    "uniprot": "Q4E4I3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4I3"
  },
  {
    "uniprot": "Q4DHV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHV5"
  },
  {
    "uniprot": "Q4CVS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVS3"
  },
  {
    "uniprot": "Q4DEM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEM3"
  },
  {
    "uniprot": "Q4D199",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D199"
  },
  {
    "uniprot": "Q4DKI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKI5"
  },
  {
    "uniprot": "Q4DH41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH41"
  },
  {
    "uniprot": "Q4DM83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM83"
  },
  {
    "uniprot": "Q4DAM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAM0"
  },
  {
    "uniprot": "Q4D1X5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1X5"
  },
  {
    "uniprot": "Q4DF68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF68"
  },
  {
    "uniprot": "Q4DEP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEP4"
  },
  {
    "uniprot": "Q4CTU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTU7"
  },
  {
    "uniprot": "Q4E341",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E341"
  },
  {
    "uniprot": "Q4DWF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWF3"
  },
  {
    "uniprot": "Q4DZA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZA2"
  },
  {
    "uniprot": "Q4E4I1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4I1"
  },
  {
    "uniprot": "Q4CR24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR24"
  },
  {
    "uniprot": "Q4CM05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM05"
  },
  {
    "uniprot": "Q4DGN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGN2"
  },
  {
    "uniprot": "Q4CW40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW40"
  },
  {
    "uniprot": "Q4E609",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E609"
  },
  {
    "uniprot": "Q4DNR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNR6"
  },
  {
    "uniprot": "Q4CZ27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ27"
  },
  {
    "uniprot": "Q4DY23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY23"
  },
  {
    "uniprot": "Q4D246",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D246"
  },
  {
    "uniprot": "Q4DBV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBV5"
  },
  {
    "uniprot": "Q4DD60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD60"
  },
  {
    "uniprot": "Q4DT65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT65"
  },
  {
    "uniprot": "Q4DD85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD85"
  },
  {
    "uniprot": "Q4D7K5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7K5"
  },
  {
    "uniprot": "Q4CZ13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ13"
  },
  {
    "uniprot": "Q4DBL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBL1"
  },
  {
    "uniprot": "Q4DJN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJN5"
  },
  {
    "uniprot": "Q4DKQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKQ6"
  },
  {
    "uniprot": "Q4CZZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZZ7"
  },
  {
    "uniprot": "Q4CZR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZR9"
  },
  {
    "uniprot": "Q4D082",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D082"
  },
  {
    "uniprot": "Q4DG43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG43"
  },
  {
    "uniprot": "Q4DFP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFP4"
  },
  {
    "uniprot": "Q4CVW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVW5"
  },
  {
    "uniprot": "Q4CMS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMS6"
  },
  {
    "uniprot": "Q4DAE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAE5"
  },
  {
    "uniprot": "Q4DVY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVY3"
  },
  {
    "uniprot": "Q4DG50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG50"
  },
  {
    "uniprot": "Q4DD48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD48"
  },
  {
    "uniprot": "Q4CRJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRJ9"
  },
  {
    "uniprot": "Q4CYM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYM7"
  },
  {
    "uniprot": "Q4D3M4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3M4"
  },
  {
    "uniprot": "Q4CSV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSV4"
  },
  {
    "uniprot": "Q4CMT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMT4"
  },
  {
    "uniprot": "Q4DC84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC84"
  },
  {
    "uniprot": "Q4DD41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD41"
  },
  {
    "uniprot": "Q4CM67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM67"
  },
  {
    "uniprot": "Q4D3B3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3B3"
  },
  {
    "uniprot": "Q4CPK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPK1"
  },
  {
    "uniprot": "Q4CPB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPB2"
  },
  {
    "uniprot": "Q4D7E7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7E7"
  },
  {
    "uniprot": "Q4E3V9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3V9"
  },
  {
    "uniprot": "Q4CUG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUG5"
  },
  {
    "uniprot": "Q4D1B2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1B2"
  },
  {
    "uniprot": "Q4CRW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRW0"
  },
  {
    "uniprot": "Q4CSI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSI9"
  },
  {
    "uniprot": "Q4CYA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYA2"
  },
  {
    "uniprot": "Q4DTP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTP9"
  },
  {
    "uniprot": "Q4DFU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFU4"
  },
  {
    "uniprot": "Q4DZX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZX9"
  },
  {
    "uniprot": "Q4D7G4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7G4"
  },
  {
    "uniprot": "Q4DGB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGB7"
  },
  {
    "uniprot": "Q4DM56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM56"
  },
  {
    "uniprot": "Q4E1D5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1D5"
  },
  {
    "uniprot": "Q4DK79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK79"
  },
  {
    "uniprot": "Q4D0E5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0E5"
  },
  {
    "uniprot": "Q4CUP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUP0"
  },
  {
    "uniprot": "Q4E2H8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2H8"
  },
  {
    "uniprot": "Q4DK76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK76"
  },
  {
    "uniprot": "Q4DAI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAI1"
  },
  {
    "uniprot": "Q4CSE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSE6"
  },
  {
    "uniprot": "Q4CQ62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ62"
  },
  {
    "uniprot": "Q4E5W7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5W7"
  },
  {
    "uniprot": "Q4E252",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E252"
  },
  {
    "uniprot": "Q4DRH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRH8"
  },
  {
    "uniprot": "Q4DTS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTS0"
  },
  {
    "uniprot": "Q4CU78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU78"
  },
  {
    "uniprot": "Q4DGE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGE9"
  },
  {
    "uniprot": "Q4DG04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG04"
  },
  {
    "uniprot": "Q4CQI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQI2"
  },
  {
    "uniprot": "Q4CQ07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ07"
  },
  {
    "uniprot": "Q4D4F7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4F7"
  },
  {
    "uniprot": "Q4DP12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP12"
  },
  {
    "uniprot": "Q4CVB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVB0"
  },
  {
    "uniprot": "Q4DJ39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ39"
  },
  {
    "uniprot": "Q4DAP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAP5"
  },
  {
    "uniprot": "Q4CZ16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ16"
  },
  {
    "uniprot": "Q4CMR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMR5"
  },
  {
    "uniprot": "Q4D0C1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0C1"
  },
  {
    "uniprot": "Q4DNE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNE0"
  },
  {
    "uniprot": "Q4D0P2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0P2"
  },
  {
    "uniprot": "Q4DLM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLM1"
  },
  {
    "uniprot": "Q4DVG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVG5"
  },
  {
    "uniprot": "Q4D9D2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9D2"
  },
  {
    "uniprot": "Q4DTG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTG2"
  },
  {
    "uniprot": "Q4CQJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQJ0"
  },
  {
    "uniprot": "Q4DL78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL78"
  },
  {
    "uniprot": "Q4E4X1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4X1"
  },
  {
    "uniprot": "Q4DUH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUH4"
  },
  {
    "uniprot": "Q4CM37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM37"
  },
  {
    "uniprot": "Q4DJ42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ42"
  },
  {
    "uniprot": "Q4DX01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX01"
  },
  {
    "uniprot": "Q4DRC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRC5"
  },
  {
    "uniprot": "Q4CRX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRX9"
  },
  {
    "uniprot": "Q4DEV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEV6"
  },
  {
    "uniprot": "Q4DFF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFF0"
  },
  {
    "uniprot": "Q4CZJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZJ2"
  },
  {
    "uniprot": "Q4DVN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVN9"
  },
  {
    "uniprot": "Q4DNH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNH2"
  },
  {
    "uniprot": "Q4CZV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZV6"
  },
  {
    "uniprot": "Q4D9N4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9N4"
  },
  {
    "uniprot": "Q4CVQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVQ8"
  },
  {
    "uniprot": "Q4CQ52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ52"
  },
  {
    "uniprot": "Q4CRG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRG1"
  },
  {
    "uniprot": "Q4DA17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA17"
  },
  {
    "uniprot": "Q4CMI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMI5"
  },
  {
    "uniprot": "Q4E2W8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2W8"
  },
  {
    "uniprot": "Q4DBI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBI2"
  },
  {
    "uniprot": "Q4DFN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFN9"
  },
  {
    "uniprot": "Q4CR09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR09"
  },
  {
    "uniprot": "Q4DBP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBP3"
  },
  {
    "uniprot": "Q4DM96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM96"
  },
  {
    "uniprot": "Q4DGR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGR2"
  },
  {
    "uniprot": "Q4E522",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E522"
  },
  {
    "uniprot": "Q4D6Y6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Y6"
  },
  {
    "uniprot": "Q4DEU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEU9"
  },
  {
    "uniprot": "Q4D7A8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7A8"
  },
  {
    "uniprot": "Q4E4N4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4N4"
  },
  {
    "uniprot": "Q4DZI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZI1"
  },
  {
    "uniprot": "Q4DRU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRU2"
  },
  {
    "uniprot": "Q4D163",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D163"
  },
  {
    "uniprot": "Q4DZH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZH2"
  },
  {
    "uniprot": "Q4CSJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSJ4"
  },
  {
    "uniprot": "Q4DP06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP06"
  },
  {
    "uniprot": "Q4DN75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN75"
  },
  {
    "uniprot": "Q4CUD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUD8"
  },
  {
    "uniprot": "Q4CSK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSK8"
  },
  {
    "uniprot": "Q4D971",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D971"
  },
  {
    "uniprot": "Q4DQH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQH6"
  },
  {
    "uniprot": "Q4E5P4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5P4"
  },
  {
    "uniprot": "Q4DTN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTN4"
  },
  {
    "uniprot": "Q4DTK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTK6"
  },
  {
    "uniprot": "Q4DP10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP10"
  },
  {
    "uniprot": "Q4DGG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGG7"
  },
  {
    "uniprot": "Q4CKP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKP2"
  },
  {
    "uniprot": "Q4D9R5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9R5"
  },
  {
    "uniprot": "Q4CUH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUH8"
  },
  {
    "uniprot": "Q4DTT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTT3"
  },
  {
    "uniprot": "Q4CQY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQY9"
  },
  {
    "uniprot": "Q4CMX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMX3"
  },
  {
    "uniprot": "Q4DSR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSR1"
  },
  {
    "uniprot": "Q4D4E0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4E0"
  },
  {
    "uniprot": "Q4D5Q9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Q9"
  },
  {
    "uniprot": "Q4DXW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXW7"
  },
  {
    "uniprot": "Q4DJJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJJ7"
  },
  {
    "uniprot": "Q4D9Z3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Z3"
  },
  {
    "uniprot": "Q4CQW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQW2"
  },
  {
    "uniprot": "Q4D3D9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3D9"
  },
  {
    "uniprot": "Q4DAQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAQ5"
  },
  {
    "uniprot": "Q4DDI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDI5"
  },
  {
    "uniprot": "Q4CV88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV88"
  },
  {
    "uniprot": "Q4DPL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPL8"
  },
  {
    "uniprot": "Q4E058",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E058"
  },
  {
    "uniprot": "Q4CW76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW76"
  },
  {
    "uniprot": "Q4DW24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW24"
  },
  {
    "uniprot": "Q4DU12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU12"
  },
  {
    "uniprot": "Q4D3X0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3X0"
  },
  {
    "uniprot": "Q4DGA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGA7"
  },
  {
    "uniprot": "Q4DAR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAR4"
  },
  {
    "uniprot": "Q4E5Z5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Z5"
  },
  {
    "uniprot": "Q4DDY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDY2"
  },
  {
    "uniprot": "Q4CZ57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ57"
  },
  {
    "uniprot": "Q4DQR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQR5"
  },
  {
    "uniprot": "Q4DMT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMT8"
  },
  {
    "uniprot": "Q4CPY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPY1"
  },
  {
    "uniprot": "Q4D0L5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0L5"
  },
  {
    "uniprot": "Q4CVS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVS1"
  },
  {
    "uniprot": "Q4DS11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS11"
  },
  {
    "uniprot": "Q4CM60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM60"
  },
  {
    "uniprot": "Q4D7X3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7X3"
  },
  {
    "uniprot": "Q4CM51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM51"
  },
  {
    "uniprot": "Q4D4E7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4E7"
  },
  {
    "uniprot": "Q4CTV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTV6"
  },
  {
    "uniprot": "Q4E338",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E338"
  },
  {
    "uniprot": "Q4DWH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWH2"
  },
  {
    "uniprot": "Q4CUL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUL4"
  },
  {
    "uniprot": "Q4DCW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCW7"
  },
  {
    "uniprot": "Q4D7L2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7L2"
  },
  {
    "uniprot": "Q4DMV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMV5"
  },
  {
    "uniprot": "Q4DMS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMS2"
  },
  {
    "uniprot": "Q4DYB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYB0"
  },
  {
    "uniprot": "Q4DAW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAW9"
  },
  {
    "uniprot": "Q4D9E5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9E5"
  },
  {
    "uniprot": "Q4CZM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZM7"
  },
  {
    "uniprot": "Q4E0A0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0A0"
  },
  {
    "uniprot": "Q4DIE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIE0"
  },
  {
    "uniprot": "Q4CMM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMM6"
  },
  {
    "uniprot": "Q4DS88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS88"
  },
  {
    "uniprot": "Q4CY24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY24"
  },
  {
    "uniprot": "Q4DQF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQF3"
  },
  {
    "uniprot": "Q4E552",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E552"
  },
  {
    "uniprot": "Q4CTK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTK6"
  },
  {
    "uniprot": "Q4CM24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM24"
  },
  {
    "uniprot": "Q4D7F4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7F4"
  },
  {
    "uniprot": "Q4CUE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUE5"
  },
  {
    "uniprot": "Q4DBX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBX0"
  },
  {
    "uniprot": "Q4E0P8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0P8"
  },
  {
    "uniprot": "Q4D481",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D481"
  },
  {
    "uniprot": "Q4CNT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNT6"
  },
  {
    "uniprot": "Q4DNE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNE3"
  },
  {
    "uniprot": "Q4DEG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEG1"
  },
  {
    "uniprot": "Q4DYL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYL4"
  },
  {
    "uniprot": "Q4DLS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLS8"
  },
  {
    "uniprot": "Q4D961",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D961"
  },
  {
    "uniprot": "Q4E5R4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5R4"
  },
  {
    "uniprot": "Q4CVI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVI0"
  },
  {
    "uniprot": "Q4CNU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNU5"
  },
  {
    "uniprot": "Q4DW88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW88"
  },
  {
    "uniprot": "Q4CMU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMU6"
  },
  {
    "uniprot": "Q4D040",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D040"
  },
  {
    "uniprot": "Q4CZR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZR5"
  },
  {
    "uniprot": "Q4CU09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU09"
  },
  {
    "uniprot": "Q4CU16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU16"
  },
  {
    "uniprot": "Q4CK88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CK88"
  },
  {
    "uniprot": "Q4E4P8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4P8"
  },
  {
    "uniprot": "Q4DJ40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ40"
  },
  {
    "uniprot": "Q4DG89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG89"
  },
  {
    "uniprot": "Q4E0A6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0A6"
  },
  {
    "uniprot": "Q4E3Z1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Z1"
  },
  {
    "uniprot": "Q4CKT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKT9"
  },
  {
    "uniprot": "Q4CLG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLG4"
  },
  {
    "uniprot": "Q4CZ29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ29"
  },
  {
    "uniprot": "Q4CZJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZJ7"
  },
  {
    "uniprot": "Q4E3C3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3C3"
  },
  {
    "uniprot": "Q4DRL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRL0"
  },
  {
    "uniprot": "Q4DZG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZG2"
  },
  {
    "uniprot": "Q4CMU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMU0"
  },
  {
    "uniprot": "Q4CUW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUW7"
  },
  {
    "uniprot": "Q4DL26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL26"
  },
  {
    "uniprot": "Q4CP56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP56"
  },
  {
    "uniprot": "Q4E2L0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2L0"
  },
  {
    "uniprot": "Q4CZB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZB3"
  },
  {
    "uniprot": "Q4CKI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKI7"
  },
  {
    "uniprot": "Q4E521",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E521"
  },
  {
    "uniprot": "Q4D1V3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1V3"
  },
  {
    "uniprot": "Q4D7L8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7L8"
  },
  {
    "uniprot": "Q4D6X1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6X1"
  },
  {
    "uniprot": "Q4DPH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPH8"
  },
  {
    "uniprot": "Q4CR69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR69"
  },
  {
    "uniprot": "Q4E1F6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1F6"
  },
  {
    "uniprot": "Q4DH63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH63"
  },
  {
    "uniprot": "Q4CPJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPJ6"
  },
  {
    "uniprot": "Q4D5Q5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Q5"
  },
  {
    "uniprot": "Q4DP81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP81"
  },
  {
    "uniprot": "Q4DCL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCL5"
  },
  {
    "uniprot": "Q4DZQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZQ4"
  },
  {
    "uniprot": "Q4D514",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D514"
  },
  {
    "uniprot": "Q4DYQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYQ2"
  },
  {
    "uniprot": "Q4E0K1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0K1"
  },
  {
    "uniprot": "Q4CNN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNN7"
  },
  {
    "uniprot": "Q4DNG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNG7"
  },
  {
    "uniprot": "Q4D6Q7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Q7"
  },
  {
    "uniprot": "Q4E2E9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2E9"
  },
  {
    "uniprot": "Q4DJ32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ32"
  },
  {
    "uniprot": "Q4D989",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D989"
  },
  {
    "uniprot": "Q4DB42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB42"
  },
  {
    "uniprot": "Q4CWQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWQ6"
  },
  {
    "uniprot": "Q4CKT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKT1"
  },
  {
    "uniprot": "Q4DAK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAK7"
  },
  {
    "uniprot": "Q4DB45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB45"
  },
  {
    "uniprot": "Q4D4Q5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Q5"
  },
  {
    "uniprot": "Q4DH87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH87"
  },
  {
    "uniprot": "Q4DEE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEE3"
  },
  {
    "uniprot": "Q4CVK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVK5"
  },
  {
    "uniprot": "Q4E5Z8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Z8"
  },
  {
    "uniprot": "Q4E121",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E121"
  },
  {
    "uniprot": "Q4DGU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGU8"
  },
  {
    "uniprot": "Q4DB06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB06"
  },
  {
    "uniprot": "Q4DZ93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ93"
  },
  {
    "uniprot": "Q4CV65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV65"
  },
  {
    "uniprot": "Q4CMJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMJ9"
  },
  {
    "uniprot": "Q4DJZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJZ1"
  },
  {
    "uniprot": "Q4CM73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM73"
  },
  {
    "uniprot": "Q4D3E5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3E5"
  },
  {
    "uniprot": "Q4CT51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT51"
  },
  {
    "uniprot": "Q4DC06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC06"
  },
  {
    "uniprot": "Q4E3Q4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Q4"
  },
  {
    "uniprot": "Q4E2G3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2G3"
  },
  {
    "uniprot": "Q4CTA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTA7"
  },
  {
    "uniprot": "Q4DDA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDA8"
  },
  {
    "uniprot": "Q4DVK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVK5"
  },
  {
    "uniprot": "Q4E4P9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4P9"
  },
  {
    "uniprot": "Q4D9F6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9F6"
  },
  {
    "uniprot": "Q4CS28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS28"
  },
  {
    "uniprot": "Q4DHN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHN1"
  },
  {
    "uniprot": "Q4DV13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV13"
  },
  {
    "uniprot": "Q4DNY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNY6"
  },
  {
    "uniprot": "Q4CR20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR20"
  },
  {
    "uniprot": "Q4D5Q4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Q4"
  },
  {
    "uniprot": "Q4D9N5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9N5"
  },
  {
    "uniprot": "Q4DTE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTE8"
  },
  {
    "uniprot": "Q4DWF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWF8"
  },
  {
    "uniprot": "Q4CU71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU71"
  },
  {
    "uniprot": "Q4E082",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E082"
  },
  {
    "uniprot": "Q4D5A7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5A7"
  },
  {
    "uniprot": "Q4CZY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZY8"
  },
  {
    "uniprot": "Q4E1M6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1M6"
  },
  {
    "uniprot": "Q4D6A2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6A2"
  },
  {
    "uniprot": "Q4D9T3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9T3"
  },
  {
    "uniprot": "Q4D4D7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4D7"
  },
  {
    "uniprot": "Q4DWQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWQ8"
  },
  {
    "uniprot": "Q4DGS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGS8"
  },
  {
    "uniprot": "Q4DDE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDE6"
  },
  {
    "uniprot": "Q4DQV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQV3"
  },
  {
    "uniprot": "Q4DG76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG76"
  },
  {
    "uniprot": "Q4DAW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAW5"
  },
  {
    "uniprot": "Q4DUS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUS8"
  },
  {
    "uniprot": "Q4CPL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPL5"
  },
  {
    "uniprot": "Q4DF56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF56"
  },
  {
    "uniprot": "Q4DU01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU01"
  },
  {
    "uniprot": "Q4DN29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN29"
  },
  {
    "uniprot": "Q4D752",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D752"
  },
  {
    "uniprot": "Q4DZV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZV6"
  },
  {
    "uniprot": "Q4E3D2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3D2"
  },
  {
    "uniprot": "Q4DH47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH47"
  },
  {
    "uniprot": "Q4CW35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW35"
  },
  {
    "uniprot": "Q4E4M4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4M4"
  },
  {
    "uniprot": "Q4DKW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKW8"
  },
  {
    "uniprot": "Q4CSR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSR9"
  },
  {
    "uniprot": "Q4DAE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAE3"
  },
  {
    "uniprot": "Q4DQ00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ00"
  },
  {
    "uniprot": "Q4DMH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMH7"
  },
  {
    "uniprot": "Q4CXN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXN9"
  },
  {
    "uniprot": "Q4D131",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D131"
  },
  {
    "uniprot": "Q4D7A7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7A7"
  },
  {
    "uniprot": "Q4DHT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHT7"
  },
  {
    "uniprot": "Q4D006",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D006"
  },
  {
    "uniprot": "Q4CP52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP52"
  },
  {
    "uniprot": "Q4DDP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDP4"
  },
  {
    "uniprot": "Q4DL24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL24"
  },
  {
    "uniprot": "Q4CPM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPM7"
  },
  {
    "uniprot": "Q4E2M2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2M2"
  },
  {
    "uniprot": "Q4DWX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWX4"
  },
  {
    "uniprot": "Q4D5W3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5W3"
  },
  {
    "uniprot": "Q4DK26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK26"
  },
  {
    "uniprot": "Q4DZF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZF3"
  },
  {
    "uniprot": "Q4CLC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLC0"
  },
  {
    "uniprot": "Q4CVT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVT4"
  },
  {
    "uniprot": "Q4DR88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR88"
  },
  {
    "uniprot": "Q4DKV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKV5"
  },
  {
    "uniprot": "Q4CP55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP55"
  },
  {
    "uniprot": "Q4E234",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E234"
  },
  {
    "uniprot": "Q4DP41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP41"
  },
  {
    "uniprot": "Q4DM89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM89"
  },
  {
    "uniprot": "Q4DE35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE35"
  },
  {
    "uniprot": "Q4CRL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRL6"
  },
  {
    "uniprot": "Q4DK08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK08"
  },
  {
    "uniprot": "Q4D243",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D243"
  },
  {
    "uniprot": "Q4CLE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLE8"
  },
  {
    "uniprot": "Q4DSJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSJ2"
  },
  {
    "uniprot": "Q4DXH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXH1"
  },
  {
    "uniprot": "Q4DH22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH22"
  },
  {
    "uniprot": "Q4E1B0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1B0"
  },
  {
    "uniprot": "Q4DTY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTY5"
  },
  {
    "uniprot": "Q4DBA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBA0"
  },
  {
    "uniprot": "Q4CUV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUV6"
  },
  {
    "uniprot": "Q4CSI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSI7"
  },
  {
    "uniprot": "Q4DY24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY24"
  },
  {
    "uniprot": "Q4D136",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D136"
  },
  {
    "uniprot": "Q4DJT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJT9"
  },
  {
    "uniprot": "Q4DEN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEN7"
  },
  {
    "uniprot": "Q4D7M1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7M1"
  },
  {
    "uniprot": "Q4DHG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHG8"
  },
  {
    "uniprot": "Q4E5F9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5F9"
  },
  {
    "uniprot": "Q4CS84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS84"
  },
  {
    "uniprot": "Q4E4K2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4K2"
  },
  {
    "uniprot": "Q4CWV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWV5"
  },
  {
    "uniprot": "Q4CX92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX92"
  },
  {
    "uniprot": "Q4DL49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL49"
  },
  {
    "uniprot": "Q4D8T4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8T4"
  },
  {
    "uniprot": "Q4CP91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP91"
  },
  {
    "uniprot": "Q4CUJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUJ0"
  },
  {
    "uniprot": "Q4DPB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPB8"
  },
  {
    "uniprot": "Q4CQE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQE9"
  },
  {
    "uniprot": "Q4D8V4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8V4"
  },
  {
    "uniprot": "Q4CWJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWJ4"
  },
  {
    "uniprot": "Q4D030",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D030"
  },
  {
    "uniprot": "Q4DFP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFP5"
  },
  {
    "uniprot": "Q4CRA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRA7"
  },
  {
    "uniprot": "Q4D387",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D387"
  },
  {
    "uniprot": "Q4E287",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E287"
  },
  {
    "uniprot": "Q4DHF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHF0"
  },
  {
    "uniprot": "Q4DUZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUZ7"
  },
  {
    "uniprot": "Q4DAM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAM5"
  },
  {
    "uniprot": "Q4CKQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKQ9"
  },
  {
    "uniprot": "Q4DAX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAX4"
  },
  {
    "uniprot": "Q4DJ22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ22"
  },
  {
    "uniprot": "Q4DMT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMT0"
  },
  {
    "uniprot": "Q4E4F9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4F9"
  },
  {
    "uniprot": "Q4DYP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYP2"
  },
  {
    "uniprot": "Q4CZE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZE1"
  },
  {
    "uniprot": "Q4DEW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEW3"
  },
  {
    "uniprot": "Q4DCJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCJ9"
  },
  {
    "uniprot": "Q4E3K0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3K0"
  },
  {
    "uniprot": "Q4DSR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSR8"
  },
  {
    "uniprot": "Q4DD21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD21"
  },
  {
    "uniprot": "Q4CQX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQX4"
  },
  {
    "uniprot": "Q4D8D3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8D3"
  },
  {
    "uniprot": "Q4DBQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBQ1"
  },
  {
    "uniprot": "Q4CY28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY28"
  },
  {
    "uniprot": "Q4CTF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTF2"
  },
  {
    "uniprot": "Q4DW06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW06"
  },
  {
    "uniprot": "Q4DL58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL58"
  },
  {
    "uniprot": "Q4CVE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVE4"
  },
  {
    "uniprot": "Q4DVH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVH9"
  },
  {
    "uniprot": "Q4DRD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRD6"
  },
  {
    "uniprot": "Q4D5I2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5I2"
  },
  {
    "uniprot": "Q4CX09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX09"
  },
  {
    "uniprot": "Q4DS87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS87"
  },
  {
    "uniprot": "Q4E2X5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2X5"
  },
  {
    "uniprot": "Q4CLU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLU5"
  },
  {
    "uniprot": "Q4CXR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXR9"
  },
  {
    "uniprot": "Q4CNA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNA2"
  },
  {
    "uniprot": "Q4D2H6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2H6"
  },
  {
    "uniprot": "Q4DX67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX67"
  },
  {
    "uniprot": "Q4DTH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTH9"
  },
  {
    "uniprot": "Q4DSF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSF8"
  },
  {
    "uniprot": "Q4CV71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV71"
  },
  {
    "uniprot": "Q4D8D2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8D2"
  },
  {
    "uniprot": "Q4E1S1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1S1"
  },
  {
    "uniprot": "Q4DIB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIB5"
  },
  {
    "uniprot": "Q4DLL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLL5"
  },
  {
    "uniprot": "Q4DQQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQQ6"
  },
  {
    "uniprot": "Q4E473",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E473"
  },
  {
    "uniprot": "Q4E0L4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0L4"
  },
  {
    "uniprot": "Q4D265",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D265"
  },
  {
    "uniprot": "Q4CL47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL47"
  },
  {
    "uniprot": "Q4DXP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXP3"
  },
  {
    "uniprot": "Q4DR47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR47"
  },
  {
    "uniprot": "Q4DIH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIH3"
  },
  {
    "uniprot": "Q4D7M7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7M7"
  },
  {
    "uniprot": "Q4DN21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN21"
  },
  {
    "uniprot": "Q4DVZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVZ2"
  },
  {
    "uniprot": "Q4DFH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFH9"
  },
  {
    "uniprot": "Q4D3K8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3K8"
  },
  {
    "uniprot": "Q4E4Q0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Q0"
  },
  {
    "uniprot": "Q4DHK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHK3"
  },
  {
    "uniprot": "Q4E023",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E023"
  },
  {
    "uniprot": "Q4DX68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX68"
  },
  {
    "uniprot": "Q4DRI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRI2"
  },
  {
    "uniprot": "Q4DZR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZR4"
  },
  {
    "uniprot": "Q4CVN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVN5"
  },
  {
    "uniprot": "Q4CRZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRZ0"
  },
  {
    "uniprot": "Q4CSN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSN7"
  },
  {
    "uniprot": "Q4D1U6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1U6"
  },
  {
    "uniprot": "Q4DF63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF63"
  },
  {
    "uniprot": "Q4CS19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS19"
  },
  {
    "uniprot": "Q4CRP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRP7"
  },
  {
    "uniprot": "Q4DSB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSB3"
  },
  {
    "uniprot": "Q4DNF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNF9"
  },
  {
    "uniprot": "Q4DXN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXN0"
  },
  {
    "uniprot": "Q4E527",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E527"
  },
  {
    "uniprot": "Q4DPT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPT5"
  },
  {
    "uniprot": "Q4CVL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVL1"
  },
  {
    "uniprot": "Q4DWI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWI5"
  },
  {
    "uniprot": "Q4DIW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIW6"
  },
  {
    "uniprot": "Q4CS92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS92"
  },
  {
    "uniprot": "Q4E283",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E283"
  },
  {
    "uniprot": "Q4D5R4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5R4"
  },
  {
    "uniprot": "Q4D7Q3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Q3"
  },
  {
    "uniprot": "Q4D0N3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0N3"
  },
  {
    "uniprot": "Q4CS32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS32"
  },
  {
    "uniprot": "Q4DND1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DND1"
  },
  {
    "uniprot": "Q4DQX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQX2"
  },
  {
    "uniprot": "Q4DEG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEG5"
  },
  {
    "uniprot": "Q4CKU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKU2"
  },
  {
    "uniprot": "Q4E1M7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1M7"
  },
  {
    "uniprot": "Q4D7G9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7G9"
  },
  {
    "uniprot": "Q4CK97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CK97"
  },
  {
    "uniprot": "Q4DZZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZZ6"
  },
  {
    "uniprot": "Q4CT91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT91"
  },
  {
    "uniprot": "Q4E236",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E236"
  },
  {
    "uniprot": "Q4DY55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY55"
  },
  {
    "uniprot": "Q4CNH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNH3"
  },
  {
    "uniprot": "Q4DHE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHE2"
  },
  {
    "uniprot": "Q4E067",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E067"
  },
  {
    "uniprot": "Q4DJD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJD4"
  },
  {
    "uniprot": "Q4DG83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG83"
  },
  {
    "uniprot": "Q4DQ29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ29"
  },
  {
    "uniprot": "Q4DXA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXA5"
  },
  {
    "uniprot": "Q4CXQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXQ6"
  },
  {
    "uniprot": "Q4CQ19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ19"
  },
  {
    "uniprot": "Q4E279",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E279"
  },
  {
    "uniprot": "Q4CKV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKV1"
  },
  {
    "uniprot": "Q4E138",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E138"
  },
  {
    "uniprot": "Q4E2G7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2G7"
  },
  {
    "uniprot": "Q4DUG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUG5"
  },
  {
    "uniprot": "Q4E2L1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2L1"
  },
  {
    "uniprot": "Q4CKV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKV4"
  },
  {
    "uniprot": "Q4DUR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUR6"
  },
  {
    "uniprot": "Q4CUU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUU4"
  },
  {
    "uniprot": "Q4D8T0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8T0"
  },
  {
    "uniprot": "Q4CKG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKG7"
  },
  {
    "uniprot": "Q4CTC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTC9"
  },
  {
    "uniprot": "Q4DT71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT71"
  },
  {
    "uniprot": "Q4DPD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPD1"
  },
  {
    "uniprot": "Q4CVU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVU1"
  },
  {
    "uniprot": "Q4DIP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIP8"
  },
  {
    "uniprot": "Q4CWI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWI2"
  },
  {
    "uniprot": "Q4CNX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNX2"
  },
  {
    "uniprot": "Q4DQA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQA5"
  },
  {
    "uniprot": "Q4DJM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJM1"
  },
  {
    "uniprot": "Q4D3C7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3C7"
  },
  {
    "uniprot": "Q4DIZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIZ6"
  },
  {
    "uniprot": "Q4DCA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCA2"
  },
  {
    "uniprot": "Q4E4H2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4H2"
  },
  {
    "uniprot": "Q4E110",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E110"
  },
  {
    "uniprot": "Q4CQV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQV2"
  },
  {
    "uniprot": "Q4DTU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTU0"
  },
  {
    "uniprot": "Q4DKX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKX1"
  },
  {
    "uniprot": "Q4DEY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEY3"
  },
  {
    "uniprot": "Q4DRA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRA9"
  },
  {
    "uniprot": "Q4CX81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX81"
  },
  {
    "uniprot": "Q4E5Z9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Z9"
  },
  {
    "uniprot": "Q4DNL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNL2"
  },
  {
    "uniprot": "Q4CU61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU61"
  },
  {
    "uniprot": "Q4DJ26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ26"
  },
  {
    "uniprot": "Q4D8W1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8W1"
  },
  {
    "uniprot": "Q4DPF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPF6"
  },
  {
    "uniprot": "Q4DKP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKP8"
  },
  {
    "uniprot": "Q4E1K9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1K9"
  },
  {
    "uniprot": "Q4DA69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA69"
  },
  {
    "uniprot": "Q4DQ63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ63"
  },
  {
    "uniprot": "Q4CQY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQY6"
  },
  {
    "uniprot": "Q4DEG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEG6"
  },
  {
    "uniprot": "Q4DIK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIK1"
  },
  {
    "uniprot": "Q4DQC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQC9"
  },
  {
    "uniprot": "Q4DNZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNZ0"
  },
  {
    "uniprot": "Q4DQ13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ13"
  },
  {
    "uniprot": "Q4DDA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDA1"
  },
  {
    "uniprot": "Q4DVN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVN7"
  },
  {
    "uniprot": "Q4DFK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFK7"
  },
  {
    "uniprot": "Q4DVW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVW0"
  },
  {
    "uniprot": "Q4DJQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJQ6"
  },
  {
    "uniprot": "Q4E3N4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3N4"
  },
  {
    "uniprot": "Q4CNY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNY5"
  },
  {
    "uniprot": "Q4DM98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM98"
  },
  {
    "uniprot": "Q4CKV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKV0"
  },
  {
    "uniprot": "Q4D6M0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6M0"
  },
  {
    "uniprot": "Q4CY97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY97"
  },
  {
    "uniprot": "Q4DKM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKM7"
  },
  {
    "uniprot": "Q4CU76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU76"
  },
  {
    "uniprot": "Q4DF15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF15"
  },
  {
    "uniprot": "Q4DCY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCY1"
  },
  {
    "uniprot": "Q4D632",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D632"
  },
  {
    "uniprot": "Q4CLK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLK8"
  },
  {
    "uniprot": "Q4DNV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNV6"
  },
  {
    "uniprot": "Q4DMP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMP3"
  },
  {
    "uniprot": "Q4E384",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E384"
  },
  {
    "uniprot": "Q4DDW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDW0"
  },
  {
    "uniprot": "Q4DSK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSK4"
  },
  {
    "uniprot": "Q4D1S7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1S7"
  },
  {
    "uniprot": "Q4CV70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV70"
  },
  {
    "uniprot": "Q4DBK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBK2"
  },
  {
    "uniprot": "Q4DS80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS80"
  },
  {
    "uniprot": "Q4CWK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWK9"
  },
  {
    "uniprot": "Q4DMX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMX0"
  },
  {
    "uniprot": "Q4CTP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTP9"
  },
  {
    "uniprot": "Q4CRM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRM6"
  },
  {
    "uniprot": "Q4D9A0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9A0"
  },
  {
    "uniprot": "Q4D911",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D911"
  },
  {
    "uniprot": "Q4D606",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D606"
  },
  {
    "uniprot": "Q4CTB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTB4"
  },
  {
    "uniprot": "Q4E233",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E233"
  },
  {
    "uniprot": "Q4E127",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E127"
  },
  {
    "uniprot": "Q4DLV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLV1"
  },
  {
    "uniprot": "Q4DJB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJB7"
  },
  {
    "uniprot": "Q4D6I5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6I5"
  },
  {
    "uniprot": "Q4CY69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY69"
  },
  {
    "uniprot": "Q4CQV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQV9"
  },
  {
    "uniprot": "Q4D3J6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3J6"
  },
  {
    "uniprot": "Q4CSJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSJ1"
  },
  {
    "uniprot": "Q4DR73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR73"
  },
  {
    "uniprot": "Q4DT57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT57"
  },
  {
    "uniprot": "Q4DT00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT00"
  },
  {
    "uniprot": "Q4D923",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D923"
  },
  {
    "uniprot": "Q4CY50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY50"
  },
  {
    "uniprot": "Q4CZ00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ00"
  },
  {
    "uniprot": "Q4DKM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKM2"
  },
  {
    "uniprot": "Q4CST9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CST9"
  },
  {
    "uniprot": "Q4DNZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNZ6"
  },
  {
    "uniprot": "Q4DFH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFH0"
  },
  {
    "uniprot": "Q4DIK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIK5"
  },
  {
    "uniprot": "Q4DGX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGX4"
  },
  {
    "uniprot": "Q4CYQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYQ1"
  },
  {
    "uniprot": "Q4DSB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSB0"
  },
  {
    "uniprot": "Q4D0H9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0H9"
  },
  {
    "uniprot": "Q4DCT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCT1"
  },
  {
    "uniprot": "Q4CYR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYR1"
  },
  {
    "uniprot": "Q4CNB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNB6"
  },
  {
    "uniprot": "Q4D856",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D856"
  },
  {
    "uniprot": "Q4D685",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D685"
  },
  {
    "uniprot": "Q4DJ15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ15"
  },
  {
    "uniprot": "Q4CVF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVF8"
  },
  {
    "uniprot": "Q4DM80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM80"
  },
  {
    "uniprot": "Q4DHH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHH7"
  },
  {
    "uniprot": "Q4D6T0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6T0"
  },
  {
    "uniprot": "Q4CU53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU53"
  },
  {
    "uniprot": "Q4E606",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E606"
  },
  {
    "uniprot": "Q4D535",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D535"
  },
  {
    "uniprot": "Q4DLG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLG2"
  },
  {
    "uniprot": "Q4DB00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB00"
  },
  {
    "uniprot": "Q4DSY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSY8"
  },
  {
    "uniprot": "Q4DYY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYY0"
  },
  {
    "uniprot": "Q4D6D0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6D0"
  },
  {
    "uniprot": "Q4E5A5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5A5"
  },
  {
    "uniprot": "Q4CUY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUY5"
  },
  {
    "uniprot": "Q4DHI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHI7"
  },
  {
    "uniprot": "Q4DIU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIU7"
  },
  {
    "uniprot": "Q4DKC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKC2"
  },
  {
    "uniprot": "Q4DU29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU29"
  },
  {
    "uniprot": "Q4DMR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMR1"
  },
  {
    "uniprot": "Q4D1N2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1N2"
  },
  {
    "uniprot": "Q4DLY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLY9"
  },
  {
    "uniprot": "Q4E5D4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5D4"
  },
  {
    "uniprot": "Q4D8Q5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Q5"
  },
  {
    "uniprot": "Q4D9A2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9A2"
  },
  {
    "uniprot": "Q4CTC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTC7"
  },
  {
    "uniprot": "Q4D8G2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8G2"
  },
  {
    "uniprot": "Q4DXH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXH8"
  },
  {
    "uniprot": "Q4DI08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI08"
  },
  {
    "uniprot": "Q4DA81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA81"
  },
  {
    "uniprot": "Q4DY49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY49"
  },
  {
    "uniprot": "Q4CVI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVI9"
  },
  {
    "uniprot": "Q4DBM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBM6"
  },
  {
    "uniprot": "Q4DIH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIH2"
  },
  {
    "uniprot": "Q4D969",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D969"
  },
  {
    "uniprot": "Q4E680",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E680"
  },
  {
    "uniprot": "Q4CNU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNU4"
  },
  {
    "uniprot": "Q4D279",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D279"
  },
  {
    "uniprot": "Q4D526",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D526"
  },
  {
    "uniprot": "Q4E1E2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1E2"
  },
  {
    "uniprot": "Q4DR89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR89"
  },
  {
    "uniprot": "Q4DTS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTS2"
  },
  {
    "uniprot": "Q4CN60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN60"
  },
  {
    "uniprot": "Q4CPL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPL0"
  },
  {
    "uniprot": "Q4CPX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPX4"
  },
  {
    "uniprot": "Q4DEA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEA7"
  },
  {
    "uniprot": "Q4DNB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNB7"
  },
  {
    "uniprot": "Q4E4T1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4T1"
  },
  {
    "uniprot": "Q4DQX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQX8"
  },
  {
    "uniprot": "Q4E4U3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4U3"
  },
  {
    "uniprot": "Q4E416",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E416"
  },
  {
    "uniprot": "Q4CU45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU45"
  },
  {
    "uniprot": "Q4CZK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZK1"
  },
  {
    "uniprot": "Q4CMG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMG1"
  },
  {
    "uniprot": "Q4D4G0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4G0"
  },
  {
    "uniprot": "Q4DS54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS54"
  },
  {
    "uniprot": "Q4DHZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHZ5"
  },
  {
    "uniprot": "Q4CUR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUR0"
  },
  {
    "uniprot": "Q4DJE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJE9"
  },
  {
    "uniprot": "Q4CXM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXM2"
  },
  {
    "uniprot": "Q4DC87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC87"
  },
  {
    "uniprot": "Q4D512",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D512"
  },
  {
    "uniprot": "Q4DYR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYR5"
  },
  {
    "uniprot": "Q4DHS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHS6"
  },
  {
    "uniprot": "Q4E2J0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2J0"
  },
  {
    "uniprot": "Q4DTY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTY2"
  },
  {
    "uniprot": "Q4D224",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D224"
  },
  {
    "uniprot": "Q4D3W5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3W5"
  },
  {
    "uniprot": "Q4DH56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH56"
  },
  {
    "uniprot": "Q4DG51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG51"
  },
  {
    "uniprot": "Q4DRW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRW0"
  },
  {
    "uniprot": "Q4CWT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWT9"
  },
  {
    "uniprot": "Q4DWA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWA0"
  },
  {
    "uniprot": "Q4DN90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN90"
  },
  {
    "uniprot": "Q4E0H8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0H8"
  },
  {
    "uniprot": "Q4DIP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIP2"
  },
  {
    "uniprot": "Q4CNI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNI0"
  },
  {
    "uniprot": "Q4DL41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL41"
  },
  {
    "uniprot": "Q4DK32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK32"
  },
  {
    "uniprot": "Q4DUS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUS3"
  },
  {
    "uniprot": "Q4DYJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYJ6"
  },
  {
    "uniprot": "Q4DQS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQS1"
  },
  {
    "uniprot": "Q4CR74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR74"
  },
  {
    "uniprot": "Q4DME8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DME8"
  },
  {
    "uniprot": "Q4DD89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD89"
  },
  {
    "uniprot": "Q4DNK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNK9"
  },
  {
    "uniprot": "Q4CQ16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ16"
  },
  {
    "uniprot": "Q4DZ47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ47"
  },
  {
    "uniprot": "Q4DL56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL56"
  },
  {
    "uniprot": "Q4DKV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKV3"
  },
  {
    "uniprot": "Q4CLY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLY4"
  },
  {
    "uniprot": "Q4E5N2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5N2"
  },
  {
    "uniprot": "Q4DZL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZL8"
  },
  {
    "uniprot": "Q4DK27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK27"
  },
  {
    "uniprot": "Q4CMV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMV0"
  },
  {
    "uniprot": "Q4E2Z5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Z5"
  },
  {
    "uniprot": "Q4D748",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D748"
  },
  {
    "uniprot": "Q4CLU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLU4"
  },
  {
    "uniprot": "Q4D2S4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2S4"
  },
  {
    "uniprot": "Q4D703",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D703"
  },
  {
    "uniprot": "Q4E350",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E350"
  },
  {
    "uniprot": "Q4DB63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB63"
  },
  {
    "uniprot": "Q4CZV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZV0"
  },
  {
    "uniprot": "Q4CMM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMM2"
  },
  {
    "uniprot": "Q4DUE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUE4"
  },
  {
    "uniprot": "Q4DD37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD37"
  },
  {
    "uniprot": "Q4E0A3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0A3"
  },
  {
    "uniprot": "Q4CW94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW94"
  },
  {
    "uniprot": "Q4DBC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBC0"
  },
  {
    "uniprot": "Q4CYB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYB6"
  },
  {
    "uniprot": "Q4DKL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKL5"
  },
  {
    "uniprot": "Q4D8W4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8W4"
  },
  {
    "uniprot": "Q4DWR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWR6"
  },
  {
    "uniprot": "Q4DS13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS13"
  },
  {
    "uniprot": "Q4DN24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN24"
  },
  {
    "uniprot": "Q4DFD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFD8"
  },
  {
    "uniprot": "Q4DQV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQV1"
  },
  {
    "uniprot": "Q4DZ31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ31"
  },
  {
    "uniprot": "Q4CXR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXR3"
  },
  {
    "uniprot": "Q4D8G3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8G3"
  },
  {
    "uniprot": "Q4DLJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLJ9"
  },
  {
    "uniprot": "Q4DH30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH30"
  },
  {
    "uniprot": "Q4E1X4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1X4"
  },
  {
    "uniprot": "Q4CZH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZH5"
  },
  {
    "uniprot": "Q4DV71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV71"
  },
  {
    "uniprot": "Q4D1M8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1M8"
  },
  {
    "uniprot": "Q4CQ53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ53"
  },
  {
    "uniprot": "Q4CYL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYL9"
  },
  {
    "uniprot": "Q4DW80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW80"
  },
  {
    "uniprot": "Q4D2H9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2H9"
  },
  {
    "uniprot": "Q4DUY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUY2"
  },
  {
    "uniprot": "Q4CU93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU93"
  },
  {
    "uniprot": "Q4DWS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWS6"
  },
  {
    "uniprot": "Q4CN00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN00"
  },
  {
    "uniprot": "Q4D026",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D026"
  },
  {
    "uniprot": "Q4DU14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU14"
  },
  {
    "uniprot": "Q4DBW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBW7"
  },
  {
    "uniprot": "Q4DTH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTH5"
  },
  {
    "uniprot": "Q4E014",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E014"
  },
  {
    "uniprot": "Q4E179",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E179"
  },
  {
    "uniprot": "Q4DB37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB37"
  },
  {
    "uniprot": "Q4D6E9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6E9"
  },
  {
    "uniprot": "Q4CRZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRZ9"
  },
  {
    "uniprot": "Q4CXU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXU3"
  },
  {
    "uniprot": "Q4D0N1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0N1"
  },
  {
    "uniprot": "Q4DD99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD99"
  },
  {
    "uniprot": "Q4CWP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWP7"
  },
  {
    "uniprot": "Q4E0L1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0L1"
  },
  {
    "uniprot": "Q4D651",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D651"
  },
  {
    "uniprot": "Q4E0W9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0W9"
  },
  {
    "uniprot": "Q4E5R0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5R0"
  },
  {
    "uniprot": "Q4CV87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV87"
  },
  {
    "uniprot": "Q4DUL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUL4"
  },
  {
    "uniprot": "Q4E3S8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3S8"
  },
  {
    "uniprot": "Q4DHG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHG6"
  },
  {
    "uniprot": "Q4CMZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMZ0"
  },
  {
    "uniprot": "Q4E381",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E381"
  },
  {
    "uniprot": "Q4D7M5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7M5"
  },
  {
    "uniprot": "Q4CKU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKU5"
  },
  {
    "uniprot": "Q4DPV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPV2"
  },
  {
    "uniprot": "Q4CN29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN29"
  },
  {
    "uniprot": "Q4DEA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEA5"
  },
  {
    "uniprot": "Q4DVX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVX3"
  },
  {
    "uniprot": "Q4E0T4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0T4"
  },
  {
    "uniprot": "Q4CM80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM80"
  },
  {
    "uniprot": "Q4DSA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSA0"
  },
  {
    "uniprot": "Q4DRB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRB9"
  },
  {
    "uniprot": "Q4DDD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDD3"
  },
  {
    "uniprot": "Q4DF96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF96"
  },
  {
    "uniprot": "Q4DS01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS01"
  },
  {
    "uniprot": "Q4E2J5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2J5"
  },
  {
    "uniprot": "Q4DMS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMS3"
  },
  {
    "uniprot": "Q4CWP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWP9"
  },
  {
    "uniprot": "Q4CLE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLE2"
  },
  {
    "uniprot": "Q4E1T0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1T0"
  },
  {
    "uniprot": "Q4DJE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJE8"
  },
  {
    "uniprot": "Q4E2B7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2B7"
  },
  {
    "uniprot": "Q4DEJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEJ9"
  },
  {
    "uniprot": "Q4DAK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAK6"
  },
  {
    "uniprot": "Q4CWF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWF8"
  },
  {
    "uniprot": "Q4E4V0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4V0"
  },
  {
    "uniprot": "Q4E270",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E270"
  },
  {
    "uniprot": "Q4DPV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPV6"
  },
  {
    "uniprot": "Q4CYW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYW9"
  },
  {
    "uniprot": "Q4CNE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNE9"
  },
  {
    "uniprot": "Q4D728",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D728"
  },
  {
    "uniprot": "Q4CUE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUE2"
  },
  {
    "uniprot": "Q4DRY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRY8"
  },
  {
    "uniprot": "Q4DY36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY36"
  },
  {
    "uniprot": "Q4DST2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DST2"
  },
  {
    "uniprot": "Q4D976",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D976"
  },
  {
    "uniprot": "Q4D8F1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8F1"
  },
  {
    "uniprot": "Q4DHM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHM4"
  },
  {
    "uniprot": "Q4CLS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLS9"
  },
  {
    "uniprot": "Q4E0F2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0F2"
  },
  {
    "uniprot": "Q4CW60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW60"
  },
  {
    "uniprot": "Q4D3A4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3A4"
  },
  {
    "uniprot": "Q4CZB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZB9"
  },
  {
    "uniprot": "Q4DB67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB67"
  },
  {
    "uniprot": "Q4DEZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEZ7"
  },
  {
    "uniprot": "Q4D329",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D329"
  },
  {
    "uniprot": "Q4CS22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS22"
  },
  {
    "uniprot": "Q4DMK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMK9"
  },
  {
    "uniprot": "Q4DQG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQG0"
  },
  {
    "uniprot": "Q4CXJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXJ2"
  },
  {
    "uniprot": "Q4D9H6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9H6"
  },
  {
    "uniprot": "Q4DXA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXA9"
  },
  {
    "uniprot": "Q4CXL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXL9"
  },
  {
    "uniprot": "Q4D4V6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4V6"
  },
  {
    "uniprot": "Q4E294",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E294"
  },
  {
    "uniprot": "Q4DC65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC65"
  },
  {
    "uniprot": "Q4DYD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYD3"
  },
  {
    "uniprot": "Q4DQF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQF6"
  },
  {
    "uniprot": "Q4DJ21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ21"
  },
  {
    "uniprot": "Q4DSS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSS8"
  },
  {
    "uniprot": "Q4DNN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNN7"
  },
  {
    "uniprot": "Q4CQ67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ67"
  },
  {
    "uniprot": "Q4DK91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK91"
  },
  {
    "uniprot": "Q4E3T3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3T3"
  },
  {
    "uniprot": "Q4D549",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D549"
  },
  {
    "uniprot": "Q4DSG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSG6"
  },
  {
    "uniprot": "Q4CRC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRC0"
  },
  {
    "uniprot": "Q4CVU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVU2"
  },
  {
    "uniprot": "Q4CZY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZY0"
  },
  {
    "uniprot": "Q4DDX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDX4"
  },
  {
    "uniprot": "Q4E0M1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0M1"
  },
  {
    "uniprot": "Q4CUR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUR7"
  },
  {
    "uniprot": "Q4DAE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAE8"
  },
  {
    "uniprot": "Q4D1U2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1U2"
  },
  {
    "uniprot": "Q4E5E7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5E7"
  },
  {
    "uniprot": "Q4CWC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWC9"
  },
  {
    "uniprot": "Q4CYP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYP6"
  },
  {
    "uniprot": "Q4D8U3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8U3"
  },
  {
    "uniprot": "Q4E3D4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3D4"
  },
  {
    "uniprot": "Q4D146",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D146"
  },
  {
    "uniprot": "Q4DUV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUV6"
  },
  {
    "uniprot": "Q4D0L0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0L0"
  },
  {
    "uniprot": "Q4E295",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E295"
  },
  {
    "uniprot": "Q4CZL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZL8"
  },
  {
    "uniprot": "Q4CN03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN03"
  },
  {
    "uniprot": "Q4DDN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDN8"
  },
  {
    "uniprot": "Q4DA56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA56"
  },
  {
    "uniprot": "Q4DVN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVN5"
  },
  {
    "uniprot": "Q4D3G2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3G2"
  },
  {
    "uniprot": "Q4D5S2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5S2"
  },
  {
    "uniprot": "Q4CLQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLQ0"
  },
  {
    "uniprot": "Q4DXB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXB7"
  },
  {
    "uniprot": "Q4DTK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTK4"
  },
  {
    "uniprot": "Q4DH46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH46"
  },
  {
    "uniprot": "Q4D5H3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5H3"
  },
  {
    "uniprot": "Q4DBF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBF9"
  },
  {
    "uniprot": "Q4DG74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG74"
  },
  {
    "uniprot": "Q4DUR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUR3"
  },
  {
    "uniprot": "Q4DTN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTN8"
  },
  {
    "uniprot": "Q4DJS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJS5"
  },
  {
    "uniprot": "Q4DJG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJG0"
  },
  {
    "uniprot": "Q4CV95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV95"
  },
  {
    "uniprot": "Q4DBT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBT4"
  },
  {
    "uniprot": "Q4DX76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX76"
  },
  {
    "uniprot": "Q4E5X9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5X9"
  },
  {
    "uniprot": "Q4CP22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP22"
  },
  {
    "uniprot": "Q4E161",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E161"
  },
  {
    "uniprot": "Q4DLU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLU1"
  },
  {
    "uniprot": "Q4DKU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKU6"
  },
  {
    "uniprot": "Q4DG52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG52"
  },
  {
    "uniprot": "Q4CMF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMF5"
  },
  {
    "uniprot": "Q4D9L5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9L5"
  },
  {
    "uniprot": "Q4DR78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR78"
  },
  {
    "uniprot": "Q4D8X8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8X8"
  },
  {
    "uniprot": "Q4DBJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBJ3"
  },
  {
    "uniprot": "Q4DRE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRE9"
  },
  {
    "uniprot": "Q4CKM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKM5"
  },
  {
    "uniprot": "Q4E3V7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3V7"
  },
  {
    "uniprot": "Q4DS65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS65"
  },
  {
    "uniprot": "Q4DG24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG24"
  },
  {
    "uniprot": "Q4DUJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUJ2"
  },
  {
    "uniprot": "Q4CQV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQV3"
  },
  {
    "uniprot": "Q4D941",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D941"
  },
  {
    "uniprot": "Q4CVK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVK9"
  },
  {
    "uniprot": "Q4CKY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKY0"
  },
  {
    "uniprot": "Q4D2B6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2B6"
  },
  {
    "uniprot": "Q4DKW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKW3"
  },
  {
    "uniprot": "Q4DTI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTI8"
  },
  {
    "uniprot": "Q4DP36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP36"
  },
  {
    "uniprot": "Q4CLH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLH3"
  },
  {
    "uniprot": "Q4E3H5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3H5"
  },
  {
    "uniprot": "Q4CNX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNX6"
  },
  {
    "uniprot": "Q4E1H2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1H2"
  },
  {
    "uniprot": "Q4DR53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR53"
  },
  {
    "uniprot": "Q4DBE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBE5"
  },
  {
    "uniprot": "Q4D5Z3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Z3"
  },
  {
    "uniprot": "Q4CMD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMD2"
  },
  {
    "uniprot": "Q4D1E3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1E3"
  },
  {
    "uniprot": "Q4DRF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRF2"
  },
  {
    "uniprot": "Q4D262",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D262"
  },
  {
    "uniprot": "Q4CP96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP96"
  },
  {
    "uniprot": "Q4CR81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR81"
  },
  {
    "uniprot": "Q4CXN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXN5"
  },
  {
    "uniprot": "Q4D6E7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6E7"
  },
  {
    "uniprot": "Q4E0H7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0H7"
  },
  {
    "uniprot": "Q4DAJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAJ6"
  },
  {
    "uniprot": "Q4D294",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D294"
  },
  {
    "uniprot": "Q4DG29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG29"
  },
  {
    "uniprot": "Q4DUQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUQ0"
  },
  {
    "uniprot": "Q4DKK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKK6"
  },
  {
    "uniprot": "Q4DLK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLK2"
  },
  {
    "uniprot": "Q4E0S9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0S9"
  },
  {
    "uniprot": "Q4DL12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL12"
  },
  {
    "uniprot": "Q4D6F9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6F9"
  },
  {
    "uniprot": "Q4E4B3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4B3"
  },
  {
    "uniprot": "Q4DW81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW81"
  },
  {
    "uniprot": "Q4CQ30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ30"
  },
  {
    "uniprot": "Q4CRG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRG9"
  },
  {
    "uniprot": "Q4DSW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSW0"
  },
  {
    "uniprot": "Q4CYA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYA6"
  },
  {
    "uniprot": "Q4D0R6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0R6"
  },
  {
    "uniprot": "Q4D2A4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2A4"
  },
  {
    "uniprot": "Q4E0U1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0U1"
  },
  {
    "uniprot": "Q4DBT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBT7"
  },
  {
    "uniprot": "Q4DWM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWM3"
  },
  {
    "uniprot": "Q4DUJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUJ6"
  },
  {
    "uniprot": "Q4DQ23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ23"
  },
  {
    "uniprot": "Q4DJI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJI9"
  },
  {
    "uniprot": "Q4DQQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQQ5"
  },
  {
    "uniprot": "Q4CTY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTY5"
  },
  {
    "uniprot": "Q4E5N3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5N3"
  },
  {
    "uniprot": "Q4CR98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR98"
  },
  {
    "uniprot": "Q4DDB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDB5"
  },
  {
    "uniprot": "Q4D3X5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3X5"
  },
  {
    "uniprot": "Q4CLU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLU2"
  },
  {
    "uniprot": "Q4DLF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLF3"
  },
  {
    "uniprot": "Q4D0G4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0G4"
  },
  {
    "uniprot": "Q4D6E5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6E5"
  },
  {
    "uniprot": "Q4E1X9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1X9"
  },
  {
    "uniprot": "Q4CKC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKC0"
  },
  {
    "uniprot": "Q4E0A1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0A1"
  },
  {
    "uniprot": "Q4DFB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFB9"
  },
  {
    "uniprot": "Q4DUP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUP7"
  },
  {
    "uniprot": "Q4D9A9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9A9"
  },
  {
    "uniprot": "Q4CM36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM36"
  },
  {
    "uniprot": "Q4DWG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWG1"
  },
  {
    "uniprot": "Q4CZ73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ73"
  },
  {
    "uniprot": "Q4DXZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXZ2"
  },
  {
    "uniprot": "Q4D207",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D207"
  },
  {
    "uniprot": "Q4DGL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGL0"
  },
  {
    "uniprot": "Q4DNT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNT5"
  },
  {
    "uniprot": "Q4DCC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCC3"
  },
  {
    "uniprot": "Q4DMQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMQ9"
  },
  {
    "uniprot": "Q4CMY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMY1"
  },
  {
    "uniprot": "Q4DPH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPH3"
  },
  {
    "uniprot": "Q4DQX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQX3"
  },
  {
    "uniprot": "Q4DM22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM22"
  },
  {
    "uniprot": "Q4E5X4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5X4"
  },
  {
    "uniprot": "Q4DA03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA03"
  },
  {
    "uniprot": "Q4D7R0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7R0"
  },
  {
    "uniprot": "Q4CVA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVA4"
  },
  {
    "uniprot": "Q4DPS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPS0"
  },
  {
    "uniprot": "Q4DGE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGE1"
  },
  {
    "uniprot": "Q4DKJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKJ9"
  },
  {
    "uniprot": "Q4CQE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQE2"
  },
  {
    "uniprot": "Q4DLW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLW9"
  },
  {
    "uniprot": "Q4D640",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D640"
  },
  {
    "uniprot": "Q4CZI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZI0"
  },
  {
    "uniprot": "Q4DXE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXE5"
  },
  {
    "uniprot": "Q4DYP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYP3"
  },
  {
    "uniprot": "Q4D567",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D567"
  },
  {
    "uniprot": "Q4D1Y3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Y3"
  },
  {
    "uniprot": "Q4DX29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX29"
  },
  {
    "uniprot": "Q4D3Y6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Y6"
  },
  {
    "uniprot": "Q4DXL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXL6"
  },
  {
    "uniprot": "Q4D2N7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2N7"
  },
  {
    "uniprot": "Q4D3R4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3R4"
  },
  {
    "uniprot": "Q4CV21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV21"
  },
  {
    "uniprot": "Q4CVK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVK2"
  },
  {
    "uniprot": "Q4E354",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E354"
  },
  {
    "uniprot": "Q4DQ56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ56"
  },
  {
    "uniprot": "Q4CYU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYU3"
  },
  {
    "uniprot": "Q4CWH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWH4"
  },
  {
    "uniprot": "Q4CL37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL37"
  },
  {
    "uniprot": "Q4E2W2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2W2"
  },
  {
    "uniprot": "Q4DDV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDV4"
  },
  {
    "uniprot": "Q4CRU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRU7"
  },
  {
    "uniprot": "Q4DQT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQT4"
  },
  {
    "uniprot": "Q4E0F4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0F4"
  },
  {
    "uniprot": "Q4CZE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZE5"
  },
  {
    "uniprot": "Q4D545",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D545"
  },
  {
    "uniprot": "Q4D6V9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6V9"
  },
  {
    "uniprot": "Q4CS72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS72"
  },
  {
    "uniprot": "Q4E676",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E676"
  },
  {
    "uniprot": "Q4E4V1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4V1"
  },
  {
    "uniprot": "Q4DSQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSQ4"
  },
  {
    "uniprot": "Q4CSN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSN3"
  },
  {
    "uniprot": "Q4DUV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUV7"
  },
  {
    "uniprot": "Q4CYJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYJ9"
  },
  {
    "uniprot": "Q4D5L5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5L5"
  },
  {
    "uniprot": "Q4DL88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL88"
  },
  {
    "uniprot": "Q4CLG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLG8"
  },
  {
    "uniprot": "Q4CSN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSN1"
  },
  {
    "uniprot": "Q4CTI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTI7"
  },
  {
    "uniprot": "Q4DPH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPH2"
  },
  {
    "uniprot": "Q4DVY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVY8"
  },
  {
    "uniprot": "Q4E048",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E048"
  },
  {
    "uniprot": "Q4DGG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGG0"
  },
  {
    "uniprot": "Q4CYB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYB7"
  },
  {
    "uniprot": "Q4CVX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVX4"
  },
  {
    "uniprot": "Q4CPN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPN1"
  },
  {
    "uniprot": "Q4D9E8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9E8"
  },
  {
    "uniprot": "Q4D8C4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8C4"
  },
  {
    "uniprot": "Q4CX40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX40"
  },
  {
    "uniprot": "Q4D833",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D833"
  },
  {
    "uniprot": "Q4CZH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZH8"
  },
  {
    "uniprot": "Q4CWH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWH6"
  },
  {
    "uniprot": "Q4DRE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRE1"
  },
  {
    "uniprot": "Q4E5B8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5B8"
  },
  {
    "uniprot": "Q4D6Z3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Z3"
  },
  {
    "uniprot": "Q4DWJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWJ0"
  },
  {
    "uniprot": "Q4E4L6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4L6"
  },
  {
    "uniprot": "Q4DK55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK55"
  },
  {
    "uniprot": "Q4CNX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNX1"
  },
  {
    "uniprot": "Q4CLF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLF5"
  },
  {
    "uniprot": "Q4CWW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWW7"
  },
  {
    "uniprot": "Q4DN34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN34"
  },
  {
    "uniprot": "Q4CX66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX66"
  },
  {
    "uniprot": "Q4D8L3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8L3"
  },
  {
    "uniprot": "Q4D9W6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9W6"
  },
  {
    "uniprot": "Q4CXP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXP9"
  },
  {
    "uniprot": "Q4DC37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC37"
  },
  {
    "uniprot": "Q4CRZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRZ8"
  },
  {
    "uniprot": "Q4D5L7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5L7"
  },
  {
    "uniprot": "Q4CNM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNM9"
  },
  {
    "uniprot": "Q4DXT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXT8"
  },
  {
    "uniprot": "Q4E4Q5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Q5"
  },
  {
    "uniprot": "Q4DMQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMQ5"
  },
  {
    "uniprot": "Q4D9N1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9N1"
  },
  {
    "uniprot": "Q4DZJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZJ8"
  },
  {
    "uniprot": "Q4CVL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVL7"
  },
  {
    "uniprot": "Q4DV19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV19"
  },
  {
    "uniprot": "Q4DVI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVI3"
  },
  {
    "uniprot": "Q4DP01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP01"
  },
  {
    "uniprot": "Q4CSH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSH7"
  },
  {
    "uniprot": "Q4CMN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMN0"
  },
  {
    "uniprot": "Q4DKE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKE1"
  },
  {
    "uniprot": "Q4DA37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA37"
  },
  {
    "uniprot": "Q4DVY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVY1"
  },
  {
    "uniprot": "Q4DS73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS73"
  },
  {
    "uniprot": "Q4D4J6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4J6"
  },
  {
    "uniprot": "Q4DAA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAA2"
  },
  {
    "uniprot": "Q4E516",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E516"
  },
  {
    "uniprot": "Q4D0S5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0S5"
  },
  {
    "uniprot": "Q4CR88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR88"
  },
  {
    "uniprot": "Q4DG26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG26"
  },
  {
    "uniprot": "Q4DTF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTF8"
  },
  {
    "uniprot": "Q4CMZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMZ3"
  },
  {
    "uniprot": "Q4E2P8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2P8"
  },
  {
    "uniprot": "Q4DCP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCP7"
  },
  {
    "uniprot": "Q4D2S5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2S5"
  },
  {
    "uniprot": "Q4E0T0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0T0"
  },
  {
    "uniprot": "Q4DF79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF79"
  },
  {
    "uniprot": "Q4DS43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS43"
  },
  {
    "uniprot": "Q4DUB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUB7"
  },
  {
    "uniprot": "Q4D2A2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2A2"
  },
  {
    "uniprot": "Q4DBL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBL2"
  },
  {
    "uniprot": "Q4E126",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E126"
  },
  {
    "uniprot": "Q4CN52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN52"
  },
  {
    "uniprot": "Q4DQM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQM5"
  },
  {
    "uniprot": "Q4CQE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQE3"
  },
  {
    "uniprot": "Q4DNP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNP2"
  },
  {
    "uniprot": "Q4DX02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX02"
  },
  {
    "uniprot": "Q4DF74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF74"
  },
  {
    "uniprot": "Q4CY03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY03"
  },
  {
    "uniprot": "Q4CU77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU77"
  },
  {
    "uniprot": "Q4DK59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK59"
  },
  {
    "uniprot": "Q4CVB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVB6"
  },
  {
    "uniprot": "Q4DSC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSC3"
  },
  {
    "uniprot": "Q4CX58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX58"
  },
  {
    "uniprot": "Q4DVV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVV6"
  },
  {
    "uniprot": "Q4DY52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY52"
  },
  {
    "uniprot": "Q4CMA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMA3"
  },
  {
    "uniprot": "Q4CQP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQP1"
  },
  {
    "uniprot": "Q4DB04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB04"
  },
  {
    "uniprot": "Q4CXZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXZ4"
  },
  {
    "uniprot": "Q4CQ34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ34"
  },
  {
    "uniprot": "Q4CLR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLR1"
  },
  {
    "uniprot": "Q4CUW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUW5"
  },
  {
    "uniprot": "Q4D9X0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9X0"
  },
  {
    "uniprot": "Q4E4G2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4G2"
  },
  {
    "uniprot": "Q4D2C9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2C9"
  },
  {
    "uniprot": "Q4DD96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD96"
  },
  {
    "uniprot": "Q4DRI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRI1"
  },
  {
    "uniprot": "Q4DIB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIB7"
  },
  {
    "uniprot": "Q4DUB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUB4"
  },
  {
    "uniprot": "Q4DNM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNM8"
  },
  {
    "uniprot": "Q4CL12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL12"
  },
  {
    "uniprot": "Q4DXE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXE2"
  },
  {
    "uniprot": "Q4DYV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYV4"
  },
  {
    "uniprot": "Q4D9A6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9A6"
  },
  {
    "uniprot": "Q4DIV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIV8"
  },
  {
    "uniprot": "Q4D1Z0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Z0"
  },
  {
    "uniprot": "Q4E4T4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4T4"
  },
  {
    "uniprot": "Q4DG47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG47"
  },
  {
    "uniprot": "Q4DQN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQN2"
  },
  {
    "uniprot": "Q4DWR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWR7"
  },
  {
    "uniprot": "Q4CVA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVA9"
  },
  {
    "uniprot": "Q4E056",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E056"
  },
  {
    "uniprot": "Q4E0K6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0K6"
  },
  {
    "uniprot": "Q4D8I9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8I9"
  },
  {
    "uniprot": "Q4DQ32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ32"
  },
  {
    "uniprot": "Q4CSH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSH2"
  },
  {
    "uniprot": "Q4D119",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D119"
  },
  {
    "uniprot": "Q4DSX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSX1"
  },
  {
    "uniprot": "Q4DU30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU30"
  },
  {
    "uniprot": "Q4DQS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQS5"
  },
  {
    "uniprot": "Q4DM20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM20"
  },
  {
    "uniprot": "Q4D1X1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1X1"
  },
  {
    "uniprot": "Q4DAI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAI6"
  },
  {
    "uniprot": "Q4D7C3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7C3"
  },
  {
    "uniprot": "Q4DZV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZV4"
  },
  {
    "uniprot": "Q4D109",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D109"
  },
  {
    "uniprot": "Q4DP19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP19"
  },
  {
    "uniprot": "Q4DFK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFK0"
  },
  {
    "uniprot": "Q4DVC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVC3"
  },
  {
    "uniprot": "Q4D2J2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2J2"
  },
  {
    "uniprot": "Q4CZS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZS9"
  },
  {
    "uniprot": "Q4D7U2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7U2"
  },
  {
    "uniprot": "Q4D4Z6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Z6"
  },
  {
    "uniprot": "Q4CSM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSM9"
  },
  {
    "uniprot": "Q4D5Y5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Y5"
  },
  {
    "uniprot": "Q4CZ64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ64"
  },
  {
    "uniprot": "Q4DSM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSM1"
  },
  {
    "uniprot": "Q4DG41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG41"
  },
  {
    "uniprot": "Q4DT63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT63"
  },
  {
    "uniprot": "Q4CU83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU83"
  },
  {
    "uniprot": "Q4CT49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT49"
  },
  {
    "uniprot": "Q4D3L7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3L7"
  },
  {
    "uniprot": "Q4DMJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMJ3"
  },
  {
    "uniprot": "Q4CN81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN81"
  },
  {
    "uniprot": "Q4DBT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBT1"
  },
  {
    "uniprot": "Q4CSJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSJ6"
  },
  {
    "uniprot": "Q4CSD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSD4"
  },
  {
    "uniprot": "Q4DQ93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ93"
  },
  {
    "uniprot": "Q4D9V5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9V5"
  },
  {
    "uniprot": "Q4CNU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNU1"
  },
  {
    "uniprot": "Q4CR25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR25"
  },
  {
    "uniprot": "Q4DGS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGS6"
  },
  {
    "uniprot": "Q4CP83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP83"
  },
  {
    "uniprot": "Q4DFM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFM8"
  },
  {
    "uniprot": "Q4DVE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVE9"
  },
  {
    "uniprot": "Q4DT14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT14"
  },
  {
    "uniprot": "Q4CP95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP95"
  },
  {
    "uniprot": "Q4CUR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUR5"
  },
  {
    "uniprot": "Q4DRE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRE5"
  },
  {
    "uniprot": "Q4DGU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGU4"
  },
  {
    "uniprot": "Q4E055",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E055"
  },
  {
    "uniprot": "Q4DS37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS37"
  },
  {
    "uniprot": "Q4DBU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBU1"
  },
  {
    "uniprot": "Q4DR91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR91"
  },
  {
    "uniprot": "Q4DUD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUD9"
  },
  {
    "uniprot": "Q4CUF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUF1"
  },
  {
    "uniprot": "Q4CQ56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ56"
  },
  {
    "uniprot": "Q4CWJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWJ0"
  },
  {
    "uniprot": "Q4DLN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLN9"
  },
  {
    "uniprot": "Q4CZD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZD8"
  },
  {
    "uniprot": "Q4DJ70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ70"
  },
  {
    "uniprot": "Q4DYI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYI6"
  },
  {
    "uniprot": "Q4E499",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E499"
  },
  {
    "uniprot": "Q4DB56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB56"
  },
  {
    "uniprot": "Q4CVY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVY2"
  },
  {
    "uniprot": "Q4CV85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV85"
  },
  {
    "uniprot": "Q4DWY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWY7"
  },
  {
    "uniprot": "Q4D7W7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7W7"
  },
  {
    "uniprot": "Q4CKH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKH8"
  },
  {
    "uniprot": "Q4DN36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN36"
  },
  {
    "uniprot": "Q4D9P3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9P3"
  },
  {
    "uniprot": "Q4DKQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKQ0"
  },
  {
    "uniprot": "Q4CQC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQC2"
  },
  {
    "uniprot": "Q4CQX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQX2"
  },
  {
    "uniprot": "Q4DM11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM11"
  },
  {
    "uniprot": "Q4CSM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSM0"
  },
  {
    "uniprot": "Q4DY42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY42"
  },
  {
    "uniprot": "Q4DLK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLK3"
  },
  {
    "uniprot": "Q4D1Y4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Y4"
  },
  {
    "uniprot": "Q4DM84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM84"
  },
  {
    "uniprot": "Q4CUA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUA0"
  },
  {
    "uniprot": "Q4D689",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D689"
  },
  {
    "uniprot": "Q4DJZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJZ9"
  },
  {
    "uniprot": "Q4DB77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB77"
  },
  {
    "uniprot": "Q4E5I4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5I4"
  },
  {
    "uniprot": "Q4DT98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT98"
  },
  {
    "uniprot": "Q4DKJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKJ0"
  },
  {
    "uniprot": "Q4CN88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN88"
  },
  {
    "uniprot": "Q4CKC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKC2"
  },
  {
    "uniprot": "Q4D806",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D806"
  },
  {
    "uniprot": "Q4DJQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJQ3"
  },
  {
    "uniprot": "Q4DJQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJQ8"
  },
  {
    "uniprot": "Q4D8R2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8R2"
  },
  {
    "uniprot": "Q4DZ13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ13"
  },
  {
    "uniprot": "Q4E5L7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5L7"
  },
  {
    "uniprot": "Q4CYJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYJ8"
  },
  {
    "uniprot": "Q4DCZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCZ1"
  },
  {
    "uniprot": "Q4CM13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM13"
  },
  {
    "uniprot": "Q4DP04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP04"
  },
  {
    "uniprot": "Q4E5H2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5H2"
  },
  {
    "uniprot": "Q4DMH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMH6"
  },
  {
    "uniprot": "Q4D6W6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6W6"
  },
  {
    "uniprot": "Q4DV90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV90"
  },
  {
    "uniprot": "Q4D7D0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7D0"
  },
  {
    "uniprot": "Q4D592",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D592"
  },
  {
    "uniprot": "Q4DHA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHA3"
  },
  {
    "uniprot": "Q4DPG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPG9"
  },
  {
    "uniprot": "Q4DE28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE28"
  },
  {
    "uniprot": "Q4CMH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMH0"
  },
  {
    "uniprot": "Q4CVH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVH0"
  },
  {
    "uniprot": "Q4DWL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWL2"
  },
  {
    "uniprot": "Q4CNZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNZ2"
  },
  {
    "uniprot": "Q4DS84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS84"
  },
  {
    "uniprot": "Q4D3Y1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Y1"
  },
  {
    "uniprot": "Q4DGR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGR0"
  },
  {
    "uniprot": "Q4D7L5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7L5"
  },
  {
    "uniprot": "Q4DNB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNB1"
  },
  {
    "uniprot": "Q4DVS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVS3"
  },
  {
    "uniprot": "Q4DSH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSH8"
  },
  {
    "uniprot": "Q4CLT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLT9"
  },
  {
    "uniprot": "Q4DH25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH25"
  },
  {
    "uniprot": "Q4CVQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVQ5"
  },
  {
    "uniprot": "Q4DP29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP29"
  },
  {
    "uniprot": "Q4DX90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX90"
  },
  {
    "uniprot": "Q4D2Q8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Q8"
  },
  {
    "uniprot": "Q4D0J4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0J4"
  },
  {
    "uniprot": "Q4DVS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVS0"
  },
  {
    "uniprot": "Q4E608",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E608"
  },
  {
    "uniprot": "Q4DD66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD66"
  },
  {
    "uniprot": "Q4DMB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMB0"
  },
  {
    "uniprot": "Q4DK80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK80"
  },
  {
    "uniprot": "Q4DM49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM49"
  },
  {
    "uniprot": "Q4D254",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D254"
  },
  {
    "uniprot": "Q4CYS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYS8"
  },
  {
    "uniprot": "Q4DI77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI77"
  },
  {
    "uniprot": "Q4CZ74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ74"
  },
  {
    "uniprot": "Q4D506",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D506"
  },
  {
    "uniprot": "Q4DZL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZL2"
  },
  {
    "uniprot": "Q4DCP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCP9"
  },
  {
    "uniprot": "Q4E4G1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4G1"
  },
  {
    "uniprot": "Q4E200",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E200"
  },
  {
    "uniprot": "Q4D4C8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4C8"
  },
  {
    "uniprot": "Q4DN56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN56"
  },
  {
    "uniprot": "Q4DWS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWS1"
  },
  {
    "uniprot": "Q4DUH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUH3"
  },
  {
    "uniprot": "Q4E018",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E018"
  },
  {
    "uniprot": "Q4E172",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E172"
  },
  {
    "uniprot": "Q4DHX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHX9"
  },
  {
    "uniprot": "Q4DQT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQT0"
  },
  {
    "uniprot": "Q4DTK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTK1"
  },
  {
    "uniprot": "Q4DZG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZG9"
  },
  {
    "uniprot": "Q4DWM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWM4"
  },
  {
    "uniprot": "Q4DMD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMD2"
  },
  {
    "uniprot": "Q4CRS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRS6"
  },
  {
    "uniprot": "Q4D616",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D616"
  },
  {
    "uniprot": "Q4DZM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZM0"
  },
  {
    "uniprot": "Q4D0V5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0V5"
  },
  {
    "uniprot": "Q4E4P1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4P1"
  },
  {
    "uniprot": "Q4E5Y7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Y7"
  },
  {
    "uniprot": "Q4DS08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS08"
  },
  {
    "uniprot": "Q4D6L8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6L8"
  },
  {
    "uniprot": "Q4E3R9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3R9"
  },
  {
    "uniprot": "Q4D253",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D253"
  },
  {
    "uniprot": "Q4E159",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E159"
  },
  {
    "uniprot": "Q4D5Y9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Y9"
  },
  {
    "uniprot": "Q4DV32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV32"
  },
  {
    "uniprot": "Q4D3Q6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Q6"
  },
  {
    "uniprot": "Q4CQU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQU4"
  },
  {
    "uniprot": "Q4D4N9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4N9"
  },
  {
    "uniprot": "Q4DD19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD19"
  },
  {
    "uniprot": "Q4DDR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDR3"
  },
  {
    "uniprot": "Q4DNX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNX4"
  },
  {
    "uniprot": "Q4D4V2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4V2"
  },
  {
    "uniprot": "Q4DW89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW89"
  },
  {
    "uniprot": "Q4DRF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRF1"
  },
  {
    "uniprot": "Q4DEF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEF7"
  },
  {
    "uniprot": "Q4DR94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR94"
  },
  {
    "uniprot": "Q4E411",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E411"
  },
  {
    "uniprot": "Q4CP44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP44"
  },
  {
    "uniprot": "Q4DJB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJB6"
  },
  {
    "uniprot": "Q4CTT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTT1"
  },
  {
    "uniprot": "Q4CPG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPG9"
  },
  {
    "uniprot": "Q4CMR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMR3"
  },
  {
    "uniprot": "Q4D2G9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2G9"
  },
  {
    "uniprot": "Q4DA19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA19"
  },
  {
    "uniprot": "Q4CSE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSE1"
  },
  {
    "uniprot": "Q4D0B0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0B0"
  },
  {
    "uniprot": "Q4E624",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E624"
  },
  {
    "uniprot": "Q4DQ47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ47"
  },
  {
    "uniprot": "Q4CRE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRE3"
  },
  {
    "uniprot": "Q4DKK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKK0"
  },
  {
    "uniprot": "Q4E0V6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0V6"
  },
  {
    "uniprot": "Q4DUM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUM7"
  },
  {
    "uniprot": "Q4DR64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR64"
  },
  {
    "uniprot": "Q4E2I5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2I5"
  },
  {
    "uniprot": "Q4D809",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D809"
  },
  {
    "uniprot": "Q4CKX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKX3"
  },
  {
    "uniprot": "Q4CYP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYP0"
  },
  {
    "uniprot": "Q4DF75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF75"
  },
  {
    "uniprot": "Q4DRP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRP7"
  },
  {
    "uniprot": "Q4E618",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E618"
  },
  {
    "uniprot": "Q4CRC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRC6"
  },
  {
    "uniprot": "Q4DDN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDN5"
  },
  {
    "uniprot": "Q4DVK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVK6"
  },
  {
    "uniprot": "Q4DC16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC16"
  },
  {
    "uniprot": "Q4DM27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM27"
  },
  {
    "uniprot": "Q4D6T5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6T5"
  },
  {
    "uniprot": "Q4CUK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUK6"
  },
  {
    "uniprot": "Q4CTG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTG7"
  },
  {
    "uniprot": "Q4D203",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D203"
  },
  {
    "uniprot": "Q4CX77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX77"
  },
  {
    "uniprot": "Q4CLU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLU6"
  },
  {
    "uniprot": "Q4D134",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D134"
  },
  {
    "uniprot": "Q4D762",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D762"
  },
  {
    "uniprot": "Q4D8S1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8S1"
  },
  {
    "uniprot": "Q4D5A2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5A2"
  },
  {
    "uniprot": "Q4CMJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMJ4"
  },
  {
    "uniprot": "Q4D7C1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7C1"
  },
  {
    "uniprot": "Q4DU53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU53"
  },
  {
    "uniprot": "Q4DFY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFY9"
  },
  {
    "uniprot": "Q4CNJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNJ9"
  },
  {
    "uniprot": "Q4DY69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY69"
  },
  {
    "uniprot": "Q4E5C5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5C5"
  },
  {
    "uniprot": "Q4CLE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLE7"
  },
  {
    "uniprot": "Q4CLK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLK9"
  },
  {
    "uniprot": "Q4DIX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIX0"
  },
  {
    "uniprot": "Q4CZ18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ18"
  },
  {
    "uniprot": "Q4DUH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUH0"
  },
  {
    "uniprot": "Q4DNB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNB8"
  },
  {
    "uniprot": "Q4CZ90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ90"
  },
  {
    "uniprot": "Q4DAQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAQ4"
  },
  {
    "uniprot": "Q4CUX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUX3"
  },
  {
    "uniprot": "Q4DVL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVL0"
  },
  {
    "uniprot": "Q4E3R8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3R8"
  },
  {
    "uniprot": "Q4DUB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUB1"
  },
  {
    "uniprot": "Q4D6M3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6M3"
  },
  {
    "uniprot": "Q4DJW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJW1"
  },
  {
    "uniprot": "Q4DSQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSQ5"
  },
  {
    "uniprot": "Q4D9A3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9A3"
  },
  {
    "uniprot": "Q4CT02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT02"
  },
  {
    "uniprot": "Q4DJC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJC0"
  },
  {
    "uniprot": "Q4CNQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNQ6"
  },
  {
    "uniprot": "Q4DZ91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ91"
  },
  {
    "uniprot": "Q4DJ16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ16"
  },
  {
    "uniprot": "Q4DKJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKJ5"
  },
  {
    "uniprot": "Q4CML5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CML5"
  },
  {
    "uniprot": "Q4DZH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZH6"
  },
  {
    "uniprot": "Q4DH44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH44"
  },
  {
    "uniprot": "Q4CRA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRA3"
  },
  {
    "uniprot": "Q4D237",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D237"
  },
  {
    "uniprot": "Q4DAJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAJ3"
  },
  {
    "uniprot": "Q4CN98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN98"
  },
  {
    "uniprot": "Q4CZW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZW4"
  },
  {
    "uniprot": "Q4DS58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS58"
  },
  {
    "uniprot": "Q4CUN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUN1"
  },
  {
    "uniprot": "Q4DL54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL54"
  },
  {
    "uniprot": "Q4D346",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D346"
  },
  {
    "uniprot": "Q4CZF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZF2"
  },
  {
    "uniprot": "Q4CXH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXH8"
  },
  {
    "uniprot": "Q4D8C2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8C2"
  },
  {
    "uniprot": "Q4E4Q3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Q3"
  },
  {
    "uniprot": "Q4DPG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPG7"
  },
  {
    "uniprot": "Q4E536",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E536"
  },
  {
    "uniprot": "Q4CR42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR42"
  },
  {
    "uniprot": "Q4DTQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTQ4"
  },
  {
    "uniprot": "Q4DGH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGH6"
  },
  {
    "uniprot": "Q4CYZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYZ7"
  },
  {
    "uniprot": "Q4DG33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG33"
  },
  {
    "uniprot": "Q4DP95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP95"
  },
  {
    "uniprot": "Q4DFJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFJ0"
  },
  {
    "uniprot": "Q4CRX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRX1"
  },
  {
    "uniprot": "Q4DU85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU85"
  },
  {
    "uniprot": "Q4D9A7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9A7"
  },
  {
    "uniprot": "Q4DJM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJM4"
  },
  {
    "uniprot": "Q4D9C1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9C1"
  },
  {
    "uniprot": "Q4CTZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTZ4"
  },
  {
    "uniprot": "Q4D6N5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6N5"
  },
  {
    "uniprot": "Q4D6A8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6A8"
  },
  {
    "uniprot": "Q4DPY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPY6"
  },
  {
    "uniprot": "Q4D721",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D721"
  },
  {
    "uniprot": "Q4DI60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI60"
  },
  {
    "uniprot": "Q4CUX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUX8"
  },
  {
    "uniprot": "Q4CVH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVH1"
  },
  {
    "uniprot": "Q4CLS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLS2"
  },
  {
    "uniprot": "Q4E476",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E476"
  },
  {
    "uniprot": "Q4E296",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E296"
  },
  {
    "uniprot": "Q4D0N6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0N6"
  },
  {
    "uniprot": "Q4CRK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRK7"
  },
  {
    "uniprot": "Q4DT24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT24"
  },
  {
    "uniprot": "Q4CNT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNT3"
  },
  {
    "uniprot": "Q4E2A7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2A7"
  },
  {
    "uniprot": "Q4DXZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXZ3"
  },
  {
    "uniprot": "Q4DY64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY64"
  },
  {
    "uniprot": "Q4CTZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTZ2"
  },
  {
    "uniprot": "Q4DGQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGQ1"
  },
  {
    "uniprot": "Q4E2K4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2K4"
  },
  {
    "uniprot": "Q4DHP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHP2"
  },
  {
    "uniprot": "Q4D9P8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9P8"
  },
  {
    "uniprot": "Q4DBW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBW3"
  },
  {
    "uniprot": "Q4CRK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRK6"
  },
  {
    "uniprot": "Q4DZD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZD0"
  },
  {
    "uniprot": "Q4CQ82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ82"
  },
  {
    "uniprot": "Q4D494",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D494"
  },
  {
    "uniprot": "Q4CMF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMF1"
  },
  {
    "uniprot": "Q4D257",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D257"
  },
  {
    "uniprot": "Q4DEY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEY4"
  },
  {
    "uniprot": "Q4DVY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVY7"
  },
  {
    "uniprot": "Q4CTL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTL6"
  },
  {
    "uniprot": "Q4DUG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUG8"
  },
  {
    "uniprot": "Q4DB25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB25"
  },
  {
    "uniprot": "Q4DWL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWL3"
  },
  {
    "uniprot": "Q4E5Z0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Z0"
  },
  {
    "uniprot": "Q4CVT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVT3"
  },
  {
    "uniprot": "Q4D7K2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7K2"
  },
  {
    "uniprot": "Q4E1M9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1M9"
  },
  {
    "uniprot": "Q4CME3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CME3"
  },
  {
    "uniprot": "Q4DUN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUN8"
  },
  {
    "uniprot": "Q4DRT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRT8"
  },
  {
    "uniprot": "Q4D1Z5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Z5"
  },
  {
    "uniprot": "Q4CTN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTN2"
  },
  {
    "uniprot": "Q4DCP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCP2"
  },
  {
    "uniprot": "Q4DPA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPA6"
  },
  {
    "uniprot": "Q4D4B1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4B1"
  },
  {
    "uniprot": "Q4DL90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL90"
  },
  {
    "uniprot": "Q4DDE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDE5"
  },
  {
    "uniprot": "Q4CW17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW17"
  },
  {
    "uniprot": "Q4DPY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPY8"
  },
  {
    "uniprot": "Q4D1K2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1K2"
  },
  {
    "uniprot": "Q4CWF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWF9"
  },
  {
    "uniprot": "Q4DSL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSL5"
  },
  {
    "uniprot": "Q4E446",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E446"
  },
  {
    "uniprot": "Q4D8U5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8U5"
  },
  {
    "uniprot": "Q4DQS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQS6"
  },
  {
    "uniprot": "Q4CTR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTR2"
  },
  {
    "uniprot": "Q4CT16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT16"
  },
  {
    "uniprot": "Q4D7P4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7P4"
  },
  {
    "uniprot": "Q4DAD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAD6"
  },
  {
    "uniprot": "Q4DCL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCL4"
  },
  {
    "uniprot": "Q4DNM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNM1"
  },
  {
    "uniprot": "Q4DGM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGM3"
  },
  {
    "uniprot": "Q4D9Y8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Y8"
  },
  {
    "uniprot": "Q4DA30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA30"
  },
  {
    "uniprot": "Q4CKG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKG1"
  },
  {
    "uniprot": "Q4DQE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQE8"
  },
  {
    "uniprot": "Q4DJ29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ29"
  },
  {
    "uniprot": "Q4DG49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG49"
  },
  {
    "uniprot": "Q4D3L8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3L8"
  },
  {
    "uniprot": "Q4E2A3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2A3"
  },
  {
    "uniprot": "Q4DRL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRL3"
  },
  {
    "uniprot": "Q4D6K9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6K9"
  },
  {
    "uniprot": "Q4D507",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D507"
  },
  {
    "uniprot": "Q4DKT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKT2"
  },
  {
    "uniprot": "Q4DZ11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ11"
  },
  {
    "uniprot": "Q4DHE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHE7"
  },
  {
    "uniprot": "Q4DPH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPH9"
  },
  {
    "uniprot": "Q4DBA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBA3"
  },
  {
    "uniprot": "Q4DZR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZR0"
  },
  {
    "uniprot": "Q4DL47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL47"
  },
  {
    "uniprot": "Q4DKR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKR2"
  },
  {
    "uniprot": "Q4D209",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D209"
  },
  {
    "uniprot": "Q4CR66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR66"
  },
  {
    "uniprot": "Q4D5W4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5W4"
  },
  {
    "uniprot": "Q4DN92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN92"
  },
  {
    "uniprot": "Q4D555",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D555"
  },
  {
    "uniprot": "Q4CPF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPF5"
  },
  {
    "uniprot": "Q4CKM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKM9"
  },
  {
    "uniprot": "Q4CUJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUJ3"
  },
  {
    "uniprot": "Q4CR94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR94"
  },
  {
    "uniprot": "Q4D653",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D653"
  },
  {
    "uniprot": "Q4D9L9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9L9"
  },
  {
    "uniprot": "Q4DRY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRY6"
  },
  {
    "uniprot": "Q4DHY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHY7"
  },
  {
    "uniprot": "Q4D8U0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8U0"
  },
  {
    "uniprot": "Q4CLY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLY3"
  },
  {
    "uniprot": "Q4DIT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIT7"
  },
  {
    "uniprot": "Q4DPE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPE4"
  },
  {
    "uniprot": "Q4DSN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSN4"
  },
  {
    "uniprot": "Q4DGX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGX0"
  },
  {
    "uniprot": "Q4CYU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYU0"
  },
  {
    "uniprot": "Q4DYK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYK6"
  },
  {
    "uniprot": "Q4D9U9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9U9"
  },
  {
    "uniprot": "Q4DYQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYQ9"
  },
  {
    "uniprot": "Q4DKG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKG2"
  },
  {
    "uniprot": "Q4DZH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZH1"
  },
  {
    "uniprot": "Q4D7R9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7R9"
  },
  {
    "uniprot": "Q4D1N7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1N7"
  },
  {
    "uniprot": "Q4E395",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E395"
  },
  {
    "uniprot": "Q4DTF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTF7"
  },
  {
    "uniprot": "Q4D0A8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0A8"
  },
  {
    "uniprot": "Q4CV97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV97"
  },
  {
    "uniprot": "Q4DIM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIM2"
  },
  {
    "uniprot": "Q4E0C3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0C3"
  },
  {
    "uniprot": "Q4DR86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR86"
  },
  {
    "uniprot": "Q4DPE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPE1"
  },
  {
    "uniprot": "Q4DVG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVG4"
  },
  {
    "uniprot": "Q4DL89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL89"
  },
  {
    "uniprot": "Q4DC32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC32"
  },
  {
    "uniprot": "Q4E5T0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5T0"
  },
  {
    "uniprot": "Q4E310",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E310"
  },
  {
    "uniprot": "Q4CYU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYU8"
  },
  {
    "uniprot": "Q4E3V2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3V2"
  },
  {
    "uniprot": "Q4CQS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQS0"
  },
  {
    "uniprot": "Q4D1D1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1D1"
  },
  {
    "uniprot": "Q4DFI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFI5"
  },
  {
    "uniprot": "Q4CR33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR33"
  },
  {
    "uniprot": "Q4CSN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSN4"
  },
  {
    "uniprot": "Q4CS76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS76"
  },
  {
    "uniprot": "Q4DT56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT56"
  },
  {
    "uniprot": "Q4DKQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKQ3"
  },
  {
    "uniprot": "Q4DCU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCU8"
  },
  {
    "uniprot": "Q4DZ01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ01"
  },
  {
    "uniprot": "Q4DLM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLM9"
  },
  {
    "uniprot": "Q4E5M1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5M1"
  },
  {
    "uniprot": "Q4DNB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNB3"
  },
  {
    "uniprot": "Q4E3G3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3G3"
  },
  {
    "uniprot": "Q4DGA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGA6"
  },
  {
    "uniprot": "Q4D9Q9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Q9"
  },
  {
    "uniprot": "Q4CTQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTQ9"
  },
  {
    "uniprot": "Q4DG30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG30"
  },
  {
    "uniprot": "Q4CKA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKA7"
  },
  {
    "uniprot": "Q4CZ23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ23"
  },
  {
    "uniprot": "Q4CUM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUM8"
  },
  {
    "uniprot": "Q4CMJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMJ5"
  },
  {
    "uniprot": "Q4CX89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX89"
  },
  {
    "uniprot": "Q4E5N1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5N1"
  },
  {
    "uniprot": "Q4DGE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGE7"
  },
  {
    "uniprot": "Q4CVN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVN1"
  },
  {
    "uniprot": "Q4D7Z6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Z6"
  },
  {
    "uniprot": "Q4DJ77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ77"
  },
  {
    "uniprot": "Q4CY88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY88"
  },
  {
    "uniprot": "Q4DQ26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ26"
  },
  {
    "uniprot": "Q4D1G9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1G9"
  },
  {
    "uniprot": "Q4CLZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLZ6"
  },
  {
    "uniprot": "Q4DZI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZI9"
  },
  {
    "uniprot": "Q4E155",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E155"
  },
  {
    "uniprot": "Q4CNJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNJ0"
  },
  {
    "uniprot": "Q4DKY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKY1"
  },
  {
    "uniprot": "Q4E1W0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1W0"
  },
  {
    "uniprot": "Q4DXN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXN9"
  },
  {
    "uniprot": "Q4CLK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLK4"
  },
  {
    "uniprot": "Q4CP35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP35"
  },
  {
    "uniprot": "Q4CSQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSQ9"
  },
  {
    "uniprot": "Q4D8L8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8L8"
  },
  {
    "uniprot": "Q4DKQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKQ5"
  },
  {
    "uniprot": "Q4DX98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX98"
  },
  {
    "uniprot": "Q4E0V2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0V2"
  },
  {
    "uniprot": "Q4DE45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE45"
  },
  {
    "uniprot": "Q4DDY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDY0"
  },
  {
    "uniprot": "Q4DJN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJN7"
  },
  {
    "uniprot": "Q4DVH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVH7"
  },
  {
    "uniprot": "Q4CZK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZK2"
  },
  {
    "uniprot": "Q4DUQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUQ2"
  },
  {
    "uniprot": "Q4DBS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBS9"
  },
  {
    "uniprot": "Q4E3E4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3E4"
  },
  {
    "uniprot": "Q4CMW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMW8"
  },
  {
    "uniprot": "Q4CXD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXD7"
  },
  {
    "uniprot": "Q4DN55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN55"
  },
  {
    "uniprot": "Q4E0D8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0D8"
  },
  {
    "uniprot": "Q4CZ56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ56"
  },
  {
    "uniprot": "Q4D6V3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6V3"
  },
  {
    "uniprot": "Q4DUP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUP0"
  },
  {
    "uniprot": "Q4DCR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCR5"
  },
  {
    "uniprot": "Q4CTR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTR9"
  },
  {
    "uniprot": "Q4D9F9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9F9"
  },
  {
    "uniprot": "Q4D727",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D727"
  },
  {
    "uniprot": "Q4D160",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D160"
  },
  {
    "uniprot": "Q4D0L7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0L7"
  },
  {
    "uniprot": "Q4DBP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBP7"
  },
  {
    "uniprot": "Q4DZ22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ22"
  },
  {
    "uniprot": "Q4E510",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E510"
  },
  {
    "uniprot": "Q4CZT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZT7"
  },
  {
    "uniprot": "Q4DIV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIV1"
  },
  {
    "uniprot": "Q4DAS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAS3"
  },
  {
    "uniprot": "Q4DKA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKA1"
  },
  {
    "uniprot": "Q4CKG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKG5"
  },
  {
    "uniprot": "Q4DFE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFE4"
  },
  {
    "uniprot": "Q4D0P4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0P4"
  },
  {
    "uniprot": "Q4DE26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE26"
  },
  {
    "uniprot": "Q4DJM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJM3"
  },
  {
    "uniprot": "Q4DH51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH51"
  },
  {
    "uniprot": "Q4CPY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPY5"
  },
  {
    "uniprot": "Q4E3Y9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Y9"
  },
  {
    "uniprot": "Q4DYK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYK8"
  },
  {
    "uniprot": "Q4CWP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWP6"
  },
  {
    "uniprot": "Q4D3X8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3X8"
  },
  {
    "uniprot": "Q4E5L1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5L1"
  },
  {
    "uniprot": "Q4DAS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAS5"
  },
  {
    "uniprot": "Q4CRF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRF7"
  },
  {
    "uniprot": "Q4CLR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLR4"
  },
  {
    "uniprot": "Q4DLA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLA5"
  },
  {
    "uniprot": "Q4D666",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D666"
  },
  {
    "uniprot": "Q4CPQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPQ3"
  },
  {
    "uniprot": "Q4D3Q5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Q5"
  },
  {
    "uniprot": "Q4D6P7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6P7"
  },
  {
    "uniprot": "Q4DLX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLX4"
  },
  {
    "uniprot": "Q4CT63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT63"
  },
  {
    "uniprot": "Q4DD47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD47"
  },
  {
    "uniprot": "Q4CTV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTV7"
  },
  {
    "uniprot": "Q4DIS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIS3"
  },
  {
    "uniprot": "Q4DDK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDK7"
  },
  {
    "uniprot": "Q4DM64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM64"
  },
  {
    "uniprot": "Q4DVU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVU5"
  },
  {
    "uniprot": "Q4DP82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP82"
  },
  {
    "uniprot": "Q4E087",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E087"
  },
  {
    "uniprot": "Q4DU60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU60"
  },
  {
    "uniprot": "Q4DJZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJZ4"
  },
  {
    "uniprot": "Q4DKI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKI6"
  },
  {
    "uniprot": "Q4D7H9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7H9"
  },
  {
    "uniprot": "Q4D4H5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4H5"
  },
  {
    "uniprot": "Q4DHC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHC1"
  },
  {
    "uniprot": "Q4D0P6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0P6"
  },
  {
    "uniprot": "Q4CW90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW90"
  },
  {
    "uniprot": "Q4D696",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D696"
  },
  {
    "uniprot": "Q4CKL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKL5"
  },
  {
    "uniprot": "Q4DNV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNV4"
  },
  {
    "uniprot": "Q4CKR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKR8"
  },
  {
    "uniprot": "Q4D4N6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4N6"
  },
  {
    "uniprot": "Q4E005",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E005"
  },
  {
    "uniprot": "Q4DVN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVN3"
  },
  {
    "uniprot": "Q4D3I6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3I6"
  },
  {
    "uniprot": "Q4CS05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS05"
  },
  {
    "uniprot": "Q4D9P2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9P2"
  },
  {
    "uniprot": "Q4CYC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYC4"
  },
  {
    "uniprot": "Q4DEL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEL3"
  },
  {
    "uniprot": "Q4E547",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E547"
  },
  {
    "uniprot": "Q4D3M3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3M3"
  },
  {
    "uniprot": "Q4DB34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB34"
  },
  {
    "uniprot": "Q4CSY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSY5"
  },
  {
    "uniprot": "Q4DRM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRM4"
  },
  {
    "uniprot": "Q4DNL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNL0"
  },
  {
    "uniprot": "Q4CL63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL63"
  },
  {
    "uniprot": "Q4DLI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLI4"
  },
  {
    "uniprot": "Q4E5J8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5J8"
  },
  {
    "uniprot": "Q4D404",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D404"
  },
  {
    "uniprot": "Q4DJ00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ00"
  },
  {
    "uniprot": "Q4DZY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZY9"
  },
  {
    "uniprot": "Q4D898",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D898"
  },
  {
    "uniprot": "Q4D490",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D490"
  },
  {
    "uniprot": "Q4E3C7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3C7"
  },
  {
    "uniprot": "Q4D995",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D995"
  },
  {
    "uniprot": "Q4DY31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY31"
  },
  {
    "uniprot": "Q4CRB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRB0"
  },
  {
    "uniprot": "Q4DQU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQU7"
  },
  {
    "uniprot": "Q4CWK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWK3"
  },
  {
    "uniprot": "Q4DDN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDN9"
  },
  {
    "uniprot": "Q4E0U9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0U9"
  },
  {
    "uniprot": "Q4DM08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM08"
  },
  {
    "uniprot": "Q4DIZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIZ4"
  },
  {
    "uniprot": "Q4DTA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTA2"
  },
  {
    "uniprot": "Q4DXU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXU3"
  },
  {
    "uniprot": "Q4DMM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMM6"
  },
  {
    "uniprot": "Q4DC63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC63"
  },
  {
    "uniprot": "Q4DTR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTR8"
  },
  {
    "uniprot": "Q4D307",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D307"
  },
  {
    "uniprot": "Q4E1G3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1G3"
  },
  {
    "uniprot": "Q4DSM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSM5"
  },
  {
    "uniprot": "Q4DV39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV39"
  },
  {
    "uniprot": "Q4DZL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZL5"
  },
  {
    "uniprot": "Q4DVK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVK0"
  },
  {
    "uniprot": "Q4DJ60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ60"
  },
  {
    "uniprot": "Q4DYW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYW1"
  },
  {
    "uniprot": "Q4E2I7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2I7"
  },
  {
    "uniprot": "Q4DVU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVU8"
  },
  {
    "uniprot": "Q4D0U6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0U6"
  },
  {
    "uniprot": "Q4CWR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWR5"
  },
  {
    "uniprot": "Q4CRN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRN5"
  },
  {
    "uniprot": "Q4CKG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKG8"
  },
  {
    "uniprot": "Q4E102",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E102"
  },
  {
    "uniprot": "Q4DS69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS69"
  },
  {
    "uniprot": "Q4D478",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D478"
  },
  {
    "uniprot": "Q4CSV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSV5"
  },
  {
    "uniprot": "Q4DF85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF85"
  },
  {
    "uniprot": "Q4D5L4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5L4"
  },
  {
    "uniprot": "Q4DVP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVP7"
  },
  {
    "uniprot": "Q4DD11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD11"
  },
  {
    "uniprot": "Q4E4A1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4A1"
  },
  {
    "uniprot": "Q4CR14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR14"
  },
  {
    "uniprot": "Q4DBJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBJ2"
  },
  {
    "uniprot": "Q4DQ31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ31"
  },
  {
    "uniprot": "Q4D1G5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1G5"
  },
  {
    "uniprot": "Q4D9V1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9V1"
  },
  {
    "uniprot": "Q4CMD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMD5"
  },
  {
    "uniprot": "Q4D2X5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2X5"
  },
  {
    "uniprot": "Q4CVT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVT6"
  },
  {
    "uniprot": "Q4CNC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNC5"
  },
  {
    "uniprot": "Q4DAR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAR7"
  },
  {
    "uniprot": "Q4CPP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPP9"
  },
  {
    "uniprot": "Q4DLR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLR4"
  },
  {
    "uniprot": "Q4E3B9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3B9"
  },
  {
    "uniprot": "Q4CWM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWM3"
  },
  {
    "uniprot": "Q4E317",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E317"
  },
  {
    "uniprot": "Q4DLA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLA9"
  },
  {
    "uniprot": "Q4DH10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH10"
  },
  {
    "uniprot": "Q4DLB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLB1"
  },
  {
    "uniprot": "Q4DK15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK15"
  },
  {
    "uniprot": "Q4DFF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFF4"
  },
  {
    "uniprot": "Q4DCA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCA0"
  },
  {
    "uniprot": "Q4DGK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGK4"
  },
  {
    "uniprot": "Q4CLK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLK2"
  },
  {
    "uniprot": "Q4D9N7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9N7"
  },
  {
    "uniprot": "Q4E066",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E066"
  },
  {
    "uniprot": "Q4CLD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLD4"
  },
  {
    "uniprot": "Q4DWM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWM8"
  },
  {
    "uniprot": "Q4DNR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNR7"
  },
  {
    "uniprot": "Q4CL30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL30"
  },
  {
    "uniprot": "Q4E158",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E158"
  },
  {
    "uniprot": "Q4DAK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAK3"
  },
  {
    "uniprot": "Q4CQA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQA9"
  },
  {
    "uniprot": "Q4E5T8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5T8"
  },
  {
    "uniprot": "Q4CXY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXY1"
  },
  {
    "uniprot": "Q4CNZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNZ3"
  },
  {
    "uniprot": "Q4CT20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT20"
  },
  {
    "uniprot": "Q4E5C6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5C6"
  },
  {
    "uniprot": "Q4DRY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRY4"
  },
  {
    "uniprot": "Q4DI31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI31"
  },
  {
    "uniprot": "Q4CT45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT45"
  },
  {
    "uniprot": "Q4DWN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWN8"
  },
  {
    "uniprot": "Q4D5Q3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Q3"
  },
  {
    "uniprot": "Q4D487",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D487"
  },
  {
    "uniprot": "Q4E429",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E429"
  },
  {
    "uniprot": "Q4CV63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV63"
  },
  {
    "uniprot": "Q4D4A5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4A5"
  },
  {
    "uniprot": "Q4DNC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNC5"
  },
  {
    "uniprot": "Q4DJK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJK6"
  },
  {
    "uniprot": "Q4CWD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWD2"
  },
  {
    "uniprot": "Q4D3Q3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Q3"
  },
  {
    "uniprot": "Q4D546",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D546"
  },
  {
    "uniprot": "Q4DTJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTJ0"
  },
  {
    "uniprot": "Q4E069",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E069"
  },
  {
    "uniprot": "Q4CLI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLI9"
  },
  {
    "uniprot": "Q4D1W2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1W2"
  },
  {
    "uniprot": "Q4DDQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDQ4"
  },
  {
    "uniprot": "Q4CY91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY91"
  },
  {
    "uniprot": "Q4DUC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUC7"
  },
  {
    "uniprot": "Q4DBE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBE4"
  },
  {
    "uniprot": "Q4CRS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRS9"
  },
  {
    "uniprot": "Q4D009",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D009"
  },
  {
    "uniprot": "Q4D3A3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3A3"
  },
  {
    "uniprot": "Q4D605",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D605"
  },
  {
    "uniprot": "Q4DAY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAY4"
  },
  {
    "uniprot": "Q4DWC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWC9"
  },
  {
    "uniprot": "Q4DC09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC09"
  },
  {
    "uniprot": "Q4DL38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL38"
  },
  {
    "uniprot": "Q4E2Y5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Y5"
  },
  {
    "uniprot": "Q4D466",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D466"
  },
  {
    "uniprot": "Q4DCF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCF6"
  },
  {
    "uniprot": "Q4DPU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPU3"
  },
  {
    "uniprot": "Q4DC41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC41"
  },
  {
    "uniprot": "Q4DDV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDV7"
  },
  {
    "uniprot": "Q4CXQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXQ0"
  },
  {
    "uniprot": "Q4DZ81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ81"
  },
  {
    "uniprot": "Q4CMK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMK4"
  },
  {
    "uniprot": "Q4CX96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX96"
  },
  {
    "uniprot": "Q4CRE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRE7"
  },
  {
    "uniprot": "Q4DGI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGI2"
  },
  {
    "uniprot": "Q4DK66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK66"
  },
  {
    "uniprot": "Q4DU38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU38"
  },
  {
    "uniprot": "Q4DKZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKZ0"
  },
  {
    "uniprot": "Q4CVL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVL5"
  },
  {
    "uniprot": "Q4D7U3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7U3"
  },
  {
    "uniprot": "Q4DLG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLG7"
  },
  {
    "uniprot": "Q4E215",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E215"
  },
  {
    "uniprot": "Q4CNC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNC6"
  },
  {
    "uniprot": "Q4DPX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPX6"
  },
  {
    "uniprot": "Q4DGF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGF3"
  },
  {
    "uniprot": "Q4DSE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSE6"
  },
  {
    "uniprot": "Q4D9T0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9T0"
  },
  {
    "uniprot": "Q4DJ68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ68"
  },
  {
    "uniprot": "Q4D2C6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2C6"
  },
  {
    "uniprot": "Q4DS22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS22"
  },
  {
    "uniprot": "Q4DTM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTM2"
  },
  {
    "uniprot": "Q4DES6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DES6"
  },
  {
    "uniprot": "Q4DZS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZS1"
  },
  {
    "uniprot": "Q4DGM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGM9"
  },
  {
    "uniprot": "Q4DG97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG97"
  },
  {
    "uniprot": "Q4D1A0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1A0"
  },
  {
    "uniprot": "Q4CYW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYW7"
  },
  {
    "uniprot": "Q4DR38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR38"
  },
  {
    "uniprot": "Q4D7I9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7I9"
  },
  {
    "uniprot": "Q4DJW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJW4"
  },
  {
    "uniprot": "Q4DMC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMC3"
  },
  {
    "uniprot": "Q4DTX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTX5"
  },
  {
    "uniprot": "Q4DZF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZF4"
  },
  {
    "uniprot": "Q4CTN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTN1"
  },
  {
    "uniprot": "Q4CN57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN57"
  },
  {
    "uniprot": "Q4DQH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQH1"
  },
  {
    "uniprot": "Q4DEH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEH8"
  },
  {
    "uniprot": "Q4DWE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWE2"
  },
  {
    "uniprot": "Q4E054",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E054"
  },
  {
    "uniprot": "Q4D6Q3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Q3"
  },
  {
    "uniprot": "Q4D741",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D741"
  },
  {
    "uniprot": "Q4DKE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKE2"
  },
  {
    "uniprot": "Q4E1L8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1L8"
  },
  {
    "uniprot": "Q4D187",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D187"
  },
  {
    "uniprot": "Q4CNF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNF7"
  },
  {
    "uniprot": "Q4CTQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTQ7"
  },
  {
    "uniprot": "Q4E5F4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5F4"
  },
  {
    "uniprot": "Q4DIY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIY7"
  },
  {
    "uniprot": "Q4DJE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJE2"
  },
  {
    "uniprot": "Q4CV35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV35"
  },
  {
    "uniprot": "Q4CWE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWE1"
  },
  {
    "uniprot": "Q4CYG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYG4"
  },
  {
    "uniprot": "Q4E025",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E025"
  },
  {
    "uniprot": "Q4DGN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGN8"
  },
  {
    "uniprot": "Q4CV18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV18"
  },
  {
    "uniprot": "Q4CMJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMJ6"
  },
  {
    "uniprot": "Q4D322",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D322"
  },
  {
    "uniprot": "Q4DQM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQM1"
  },
  {
    "uniprot": "Q4D6D6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6D6"
  },
  {
    "uniprot": "Q4D5W6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5W6"
  },
  {
    "uniprot": "Q4DT61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT61"
  },
  {
    "uniprot": "Q4CQX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQX9"
  },
  {
    "uniprot": "Q4DT53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT53"
  },
  {
    "uniprot": "Q4CTN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTN5"
  },
  {
    "uniprot": "Q4DB55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB55"
  },
  {
    "uniprot": "Q4D064",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D064"
  },
  {
    "uniprot": "Q4D9G9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9G9"
  },
  {
    "uniprot": "Q4DJQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJQ4"
  },
  {
    "uniprot": "Q4CS54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS54"
  },
  {
    "uniprot": "Q4DP84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP84"
  },
  {
    "uniprot": "Q4CRB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRB4"
  },
  {
    "uniprot": "Q4CYE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYE6"
  },
  {
    "uniprot": "Q4D612",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D612"
  },
  {
    "uniprot": "Q4DQ01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ01"
  },
  {
    "uniprot": "Q4CQM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQM6"
  },
  {
    "uniprot": "Q4DGA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGA8"
  },
  {
    "uniprot": "Q4DTV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTV6"
  },
  {
    "uniprot": "Q4DPL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPL3"
  },
  {
    "uniprot": "Q4DQ65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ65"
  },
  {
    "uniprot": "Q4CKR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKR5"
  },
  {
    "uniprot": "Q4D5I3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5I3"
  },
  {
    "uniprot": "Q4D3T1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3T1"
  },
  {
    "uniprot": "Q4DCP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCP4"
  },
  {
    "uniprot": "Q4D330",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D330"
  },
  {
    "uniprot": "Q4DSG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSG7"
  },
  {
    "uniprot": "Q4DRE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRE6"
  },
  {
    "uniprot": "Q4CTM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTM4"
  },
  {
    "uniprot": "Q4D3K3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3K3"
  },
  {
    "uniprot": "Q4D438",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D438"
  },
  {
    "uniprot": "Q4CTJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTJ8"
  },
  {
    "uniprot": "Q4D7U8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7U8"
  },
  {
    "uniprot": "Q4E581",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E581"
  },
  {
    "uniprot": "Q4CS65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS65"
  },
  {
    "uniprot": "Q4CKM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKM4"
  },
  {
    "uniprot": "Q4D955",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D955"
  },
  {
    "uniprot": "Q4DF47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF47"
  },
  {
    "uniprot": "Q4DDM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDM3"
  },
  {
    "uniprot": "Q4D815",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D815"
  },
  {
    "uniprot": "Q4DXG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXG9"
  },
  {
    "uniprot": "Q4DRZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRZ3"
  },
  {
    "uniprot": "Q4D0V0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0V0"
  },
  {
    "uniprot": "Q4DY06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY06"
  },
  {
    "uniprot": "Q4CWY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWY3"
  },
  {
    "uniprot": "Q4DE27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE27"
  },
  {
    "uniprot": "Q4CP93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP93"
  },
  {
    "uniprot": "Q4D4P4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4P4"
  },
  {
    "uniprot": "Q4CW53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW53"
  },
  {
    "uniprot": "Q4D6D5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6D5"
  },
  {
    "uniprot": "Q4DNU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNU9"
  },
  {
    "uniprot": "Q4DFV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFV3"
  },
  {
    "uniprot": "Q4E5P9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5P9"
  },
  {
    "uniprot": "Q4D8V2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8V2"
  },
  {
    "uniprot": "Q4D7P6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7P6"
  },
  {
    "uniprot": "Q4DUP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUP2"
  },
  {
    "uniprot": "Q4DAX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAX7"
  },
  {
    "uniprot": "Q4D8V0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8V0"
  },
  {
    "uniprot": "Q4DDJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDJ6"
  },
  {
    "uniprot": "Q4DRK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRK7"
  },
  {
    "uniprot": "Q4CXM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXM9"
  },
  {
    "uniprot": "Q4DNQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNQ3"
  },
  {
    "uniprot": "Q4DSI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSI1"
  },
  {
    "uniprot": "Q4D0W0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0W0"
  },
  {
    "uniprot": "Q4DAP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAP1"
  },
  {
    "uniprot": "Q4DTS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTS4"
  },
  {
    "uniprot": "Q4D013",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D013"
  },
  {
    "uniprot": "Q4CX22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX22"
  },
  {
    "uniprot": "Q4D8K7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8K7"
  },
  {
    "uniprot": "Q4CS67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS67"
  },
  {
    "uniprot": "Q4D698",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D698"
  },
  {
    "uniprot": "Q4DSC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSC7"
  },
  {
    "uniprot": "Q4DXF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXF5"
  },
  {
    "uniprot": "Q4DG22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG22"
  },
  {
    "uniprot": "Q4E646",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E646"
  },
  {
    "uniprot": "Q4DFX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFX7"
  },
  {
    "uniprot": "Q4D1W8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1W8"
  },
  {
    "uniprot": "Q4E4U7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4U7"
  },
  {
    "uniprot": "Q4D648",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D648"
  },
  {
    "uniprot": "Q4DZ59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ59"
  },
  {
    "uniprot": "Q4DFE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFE8"
  },
  {
    "uniprot": "Q4DUA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUA0"
  },
  {
    "uniprot": "Q4E2X0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2X0"
  },
  {
    "uniprot": "Q4CW52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW52"
  },
  {
    "uniprot": "Q4DHW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHW1"
  },
  {
    "uniprot": "Q4DCD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCD8"
  },
  {
    "uniprot": "Q4D7I6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7I6"
  },
  {
    "uniprot": "Q4CTJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTJ7"
  },
  {
    "uniprot": "Q4DAX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAX3"
  },
  {
    "uniprot": "Q4D870",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D870"
  },
  {
    "uniprot": "Q4DY22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY22"
  },
  {
    "uniprot": "Q4DXT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXT1"
  },
  {
    "uniprot": "Q4CRR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRR1"
  },
  {
    "uniprot": "Q4DNS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNS1"
  },
  {
    "uniprot": "Q4DJT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJT8"
  },
  {
    "uniprot": "Q4DID3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DID3"
  },
  {
    "uniprot": "Q4DCA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCA6"
  },
  {
    "uniprot": "Q4D5H8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5H8"
  },
  {
    "uniprot": "Q4DQS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQS7"
  },
  {
    "uniprot": "Q4DM97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM97"
  },
  {
    "uniprot": "Q4DVF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVF4"
  },
  {
    "uniprot": "Q4DJ02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ02"
  },
  {
    "uniprot": "Q4DYF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYF7"
  },
  {
    "uniprot": "Q4DTN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTN1"
  },
  {
    "uniprot": "Q4DX26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX26"
  },
  {
    "uniprot": "Q4DMV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMV4"
  },
  {
    "uniprot": "Q4CYT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYT3"
  },
  {
    "uniprot": "Q4DRG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRG5"
  },
  {
    "uniprot": "Q4DY54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY54"
  },
  {
    "uniprot": "Q4E0B6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0B6"
  },
  {
    "uniprot": "Q4D8P4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8P4"
  },
  {
    "uniprot": "Q4CW82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW82"
  },
  {
    "uniprot": "Q4CT99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT99"
  },
  {
    "uniprot": "Q4CY59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY59"
  },
  {
    "uniprot": "Q4E603",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E603"
  },
  {
    "uniprot": "Q4DY98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY98"
  },
  {
    "uniprot": "Q4CL08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL08"
  },
  {
    "uniprot": "Q4DBM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBM9"
  },
  {
    "uniprot": "Q4CYN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYN5"
  },
  {
    "uniprot": "Q4DD70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD70"
  },
  {
    "uniprot": "Q4CMS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMS4"
  },
  {
    "uniprot": "Q4D0X6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0X6"
  },
  {
    "uniprot": "Q4DPC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPC2"
  },
  {
    "uniprot": "Q4D5Q8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Q8"
  },
  {
    "uniprot": "Q4D5P6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5P6"
  },
  {
    "uniprot": "Q4D6E3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6E3"
  },
  {
    "uniprot": "Q4D060",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D060"
  },
  {
    "uniprot": "Q4D6U0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6U0"
  },
  {
    "uniprot": "Q4CVM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVM0"
  },
  {
    "uniprot": "Q4CXQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXQ4"
  },
  {
    "uniprot": "Q4D8F7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8F7"
  },
  {
    "uniprot": "Q4D468",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D468"
  },
  {
    "uniprot": "Q4E1S2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1S2"
  },
  {
    "uniprot": "Q4D0P7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0P7"
  },
  {
    "uniprot": "Q4DXZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXZ5"
  },
  {
    "uniprot": "Q4E213",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E213"
  },
  {
    "uniprot": "Q4D2F0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2F0"
  },
  {
    "uniprot": "Q4CXG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXG7"
  },
  {
    "uniprot": "Q4CQZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQZ4"
  },
  {
    "uniprot": "Q4DXK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXK1"
  },
  {
    "uniprot": "Q4D2X7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2X7"
  },
  {
    "uniprot": "Q4D7Q4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Q4"
  },
  {
    "uniprot": "Q4DZR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZR8"
  },
  {
    "uniprot": "Q4DMP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMP5"
  },
  {
    "uniprot": "Q4DDI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDI3"
  },
  {
    "uniprot": "Q4D963",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D963"
  },
  {
    "uniprot": "Q4D1B6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1B6"
  },
  {
    "uniprot": "Q4CWV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWV8"
  },
  {
    "uniprot": "Q4DRQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRQ6"
  },
  {
    "uniprot": "Q4DQH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQH4"
  },
  {
    "uniprot": "Q4DHM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHM6"
  },
  {
    "uniprot": "Q4CUS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUS7"
  },
  {
    "uniprot": "Q4CSY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSY3"
  },
  {
    "uniprot": "Q4E1T7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1T7"
  },
  {
    "uniprot": "Q4CNF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNF4"
  },
  {
    "uniprot": "Q4D8Z1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Z1"
  },
  {
    "uniprot": "Q4CRK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRK8"
  },
  {
    "uniprot": "Q4D6F5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6F5"
  },
  {
    "uniprot": "Q4E0Y3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Y3"
  },
  {
    "uniprot": "Q4DM86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM86"
  },
  {
    "uniprot": "Q4DUW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUW9"
  },
  {
    "uniprot": "Q4E0T7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0T7"
  },
  {
    "uniprot": "Q4D1J4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1J4"
  },
  {
    "uniprot": "Q4D8N4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8N4"
  },
  {
    "uniprot": "Q4DMK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMK4"
  },
  {
    "uniprot": "Q4CUE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUE1"
  },
  {
    "uniprot": "Q4DTR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTR1"
  },
  {
    "uniprot": "Q4DYY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYY9"
  },
  {
    "uniprot": "Q4E4P3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4P3"
  },
  {
    "uniprot": "Q4DC74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC74"
  },
  {
    "uniprot": "Q4E545",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E545"
  },
  {
    "uniprot": "Q4CLP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLP5"
  },
  {
    "uniprot": "Q4CS46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS46"
  },
  {
    "uniprot": "Q4DFQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFQ4"
  },
  {
    "uniprot": "Q4CY33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY33"
  },
  {
    "uniprot": "Q4DZ03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ03"
  },
  {
    "uniprot": "Q4CP92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP92"
  },
  {
    "uniprot": "Q4CLR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLR6"
  },
  {
    "uniprot": "Q4DPI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPI4"
  },
  {
    "uniprot": "Q4CUA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUA1"
  },
  {
    "uniprot": "Q4DKQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKQ8"
  },
  {
    "uniprot": "Q4DUX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUX3"
  },
  {
    "uniprot": "Q4DEK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEK4"
  },
  {
    "uniprot": "Q4CT24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT24"
  },
  {
    "uniprot": "Q4DUK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUK1"
  },
  {
    "uniprot": "Q4E1G5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1G5"
  },
  {
    "uniprot": "Q4CWA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWA7"
  },
  {
    "uniprot": "Q4DXL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXL5"
  },
  {
    "uniprot": "Q4DAJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAJ2"
  },
  {
    "uniprot": "Q4D058",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D058"
  },
  {
    "uniprot": "Q4DC62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC62"
  },
  {
    "uniprot": "Q4CUE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUE0"
  },
  {
    "uniprot": "Q4DU40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU40"
  },
  {
    "uniprot": "Q4DZY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZY1"
  },
  {
    "uniprot": "Q4DCQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCQ8"
  },
  {
    "uniprot": "Q4E1B9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1B9"
  },
  {
    "uniprot": "Q4E245",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E245"
  },
  {
    "uniprot": "Q4CZQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZQ1"
  },
  {
    "uniprot": "Q4CQM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQM4"
  },
  {
    "uniprot": "Q4E3K7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3K7"
  },
  {
    "uniprot": "Q4CUJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUJ1"
  },
  {
    "uniprot": "Q4DSR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSR2"
  },
  {
    "uniprot": "Q4DBU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBU8"
  },
  {
    "uniprot": "Q4D8A1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8A1"
  },
  {
    "uniprot": "Q4CLS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLS5"
  },
  {
    "uniprot": "Q4D1D0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1D0"
  },
  {
    "uniprot": "Q4D8A0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8A0"
  },
  {
    "uniprot": "Q4DN58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN58"
  },
  {
    "uniprot": "Q4D8V1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8V1"
  },
  {
    "uniprot": "Q4CW67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW67"
  },
  {
    "uniprot": "Q4CVV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVV3"
  },
  {
    "uniprot": "Q4DB98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB98"
  },
  {
    "uniprot": "Q4D5I1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5I1"
  },
  {
    "uniprot": "Q4CSD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSD7"
  },
  {
    "uniprot": "Q4D736",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D736"
  },
  {
    "uniprot": "Q4DTB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTB1"
  },
  {
    "uniprot": "Q4CRM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRM3"
  },
  {
    "uniprot": "Q4CY21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY21"
  },
  {
    "uniprot": "Q4E1C4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1C4"
  },
  {
    "uniprot": "Q4DBA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBA1"
  },
  {
    "uniprot": "Q4E3N9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3N9"
  },
  {
    "uniprot": "Q4DEE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEE7"
  },
  {
    "uniprot": "Q4CLR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLR7"
  },
  {
    "uniprot": "Q4CVR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVR6"
  },
  {
    "uniprot": "Q4E344",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E344"
  },
  {
    "uniprot": "Q4CRJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRJ5"
  },
  {
    "uniprot": "Q4D397",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D397"
  },
  {
    "uniprot": "Q4DBE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBE8"
  },
  {
    "uniprot": "Q4D303",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D303"
  },
  {
    "uniprot": "Q4CWB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWB6"
  },
  {
    "uniprot": "Q4DVJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVJ6"
  },
  {
    "uniprot": "Q4DWU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWU9"
  },
  {
    "uniprot": "Q4D462",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D462"
  },
  {
    "uniprot": "Q4CMP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMP0"
  },
  {
    "uniprot": "Q4D899",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D899"
  },
  {
    "uniprot": "Q4CZU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZU5"
  },
  {
    "uniprot": "Q4DH80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH80"
  },
  {
    "uniprot": "Q4DL27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL27"
  },
  {
    "uniprot": "Q4DTB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTB7"
  },
  {
    "uniprot": "Q4DLR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLR2"
  },
  {
    "uniprot": "Q4D017",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D017"
  },
  {
    "uniprot": "Q4E3K1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3K1"
  },
  {
    "uniprot": "Q4DX61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX61"
  },
  {
    "uniprot": "Q4E0H4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0H4"
  },
  {
    "uniprot": "Q4DA79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA79"
  },
  {
    "uniprot": "Q4E4K6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4K6"
  },
  {
    "uniprot": "Q4CMK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMK5"
  },
  {
    "uniprot": "Q4DR25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR25"
  },
  {
    "uniprot": "Q4DVY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVY2"
  },
  {
    "uniprot": "Q4CRH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRH2"
  },
  {
    "uniprot": "Q4DMU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMU8"
  },
  {
    "uniprot": "Q4DTF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTF3"
  },
  {
    "uniprot": "Q4DSA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSA6"
  },
  {
    "uniprot": "Q4D011",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D011"
  },
  {
    "uniprot": "Q4CLS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLS0"
  },
  {
    "uniprot": "Q4CPU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPU0"
  },
  {
    "uniprot": "Q4DXF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXF7"
  },
  {
    "uniprot": "Q4DJF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJF7"
  },
  {
    "uniprot": "Q4E2H3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2H3"
  },
  {
    "uniprot": "Q4DBD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBD4"
  },
  {
    "uniprot": "Q4DMN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMN1"
  },
  {
    "uniprot": "Q4DD15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD15"
  },
  {
    "uniprot": "Q4DQS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQS8"
  },
  {
    "uniprot": "Q4CTF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTF6"
  },
  {
    "uniprot": "Q4CQQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQQ0"
  },
  {
    "uniprot": "Q4E4C5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4C5"
  },
  {
    "uniprot": "Q4D4I3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4I3"
  },
  {
    "uniprot": "Q4DE84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE84"
  },
  {
    "uniprot": "Q4DAH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAH5"
  },
  {
    "uniprot": "Q4CWL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWL1"
  },
  {
    "uniprot": "Q4DUR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUR5"
  },
  {
    "uniprot": "Q4DS10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS10"
  },
  {
    "uniprot": "Q4D4K8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4K8"
  },
  {
    "uniprot": "Q4E019",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E019"
  },
  {
    "uniprot": "Q4D921",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D921"
  },
  {
    "uniprot": "Q4DXW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXW3"
  },
  {
    "uniprot": "Q4DJZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJZ0"
  },
  {
    "uniprot": "Q4CYF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYF1"
  },
  {
    "uniprot": "Q4CY96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY96"
  },
  {
    "uniprot": "Q4DGH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGH7"
  },
  {
    "uniprot": "Q4DGQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGQ8"
  },
  {
    "uniprot": "Q4DW51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW51"
  },
  {
    "uniprot": "Q4CWV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWV7"
  },
  {
    "uniprot": "Q4CMC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMC7"
  },
  {
    "uniprot": "Q4CU38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU38"
  },
  {
    "uniprot": "Q4E5P3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5P3"
  },
  {
    "uniprot": "Q4E1S0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1S0"
  },
  {
    "uniprot": "Q4CKL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKL8"
  },
  {
    "uniprot": "Q4DJ76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ76"
  },
  {
    "uniprot": "Q4DEG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEG0"
  },
  {
    "uniprot": "Q4E1U5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1U5"
  },
  {
    "uniprot": "Q4DFU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFU0"
  },
  {
    "uniprot": "Q4CMQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMQ7"
  },
  {
    "uniprot": "Q4CMX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMX0"
  },
  {
    "uniprot": "Q4DQ76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ76"
  },
  {
    "uniprot": "Q4D6G4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6G4"
  },
  {
    "uniprot": "Q4DI43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI43"
  },
  {
    "uniprot": "Q4DC67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC67"
  },
  {
    "uniprot": "Q4D4T9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4T9"
  },
  {
    "uniprot": "Q4D0B2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0B2"
  },
  {
    "uniprot": "Q4D1A4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1A4"
  },
  {
    "uniprot": "Q4CZS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZS8"
  },
  {
    "uniprot": "Q4D091",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D091"
  },
  {
    "uniprot": "Q4DKZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKZ6"
  },
  {
    "uniprot": "Q4DL74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL74"
  },
  {
    "uniprot": "Q4DCN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCN6"
  },
  {
    "uniprot": "Q4CWI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWI0"
  },
  {
    "uniprot": "Q4CXQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXQ5"
  },
  {
    "uniprot": "Q4DNC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNC1"
  },
  {
    "uniprot": "Q4DTM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTM6"
  },
  {
    "uniprot": "Q4D0I8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0I8"
  },
  {
    "uniprot": "Q4D879",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D879"
  },
  {
    "uniprot": "Q4CKW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKW1"
  },
  {
    "uniprot": "Q4DXS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXS5"
  },
  {
    "uniprot": "Q4DDQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDQ8"
  },
  {
    "uniprot": "Q4D560",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D560"
  },
  {
    "uniprot": "Q4CS96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS96"
  },
  {
    "uniprot": "Q4E010",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E010"
  },
  {
    "uniprot": "Q4CLC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLC9"
  },
  {
    "uniprot": "Q4D967",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D967"
  },
  {
    "uniprot": "Q4CKH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKH6"
  },
  {
    "uniprot": "Q4DBN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBN5"
  },
  {
    "uniprot": "Q4DKV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKV8"
  },
  {
    "uniprot": "Q4CPK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPK7"
  },
  {
    "uniprot": "Q4DQA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQA0"
  },
  {
    "uniprot": "Q4DI99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI99"
  },
  {
    "uniprot": "Q4DSM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSM2"
  },
  {
    "uniprot": "Q4CX97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX97"
  },
  {
    "uniprot": "Q4D3B4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3B4"
  },
  {
    "uniprot": "Q4D9E1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9E1"
  },
  {
    "uniprot": "Q4D5Z9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Z9"
  },
  {
    "uniprot": "Q4DRW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRW3"
  },
  {
    "uniprot": "Q4CKT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKT3"
  },
  {
    "uniprot": "Q4CNS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNS4"
  },
  {
    "uniprot": "Q4DU23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU23"
  },
  {
    "uniprot": "Q4DR80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR80"
  },
  {
    "uniprot": "Q4E619",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E619"
  },
  {
    "uniprot": "Q4CX51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX51"
  },
  {
    "uniprot": "Q4DVS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVS7"
  },
  {
    "uniprot": "Q4CKV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKV9"
  },
  {
    "uniprot": "Q4DFQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFQ2"
  },
  {
    "uniprot": "Q4DEF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEF3"
  },
  {
    "uniprot": "Q4CNV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNV8"
  },
  {
    "uniprot": "Q4D2Z7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Z7"
  },
  {
    "uniprot": "Q4DV42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV42"
  },
  {
    "uniprot": "Q4DI51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI51"
  },
  {
    "uniprot": "Q4CV62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV62"
  },
  {
    "uniprot": "Q4DV07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV07"
  },
  {
    "uniprot": "Q4DD05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD05"
  },
  {
    "uniprot": "Q4DEM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEM8"
  },
  {
    "uniprot": "Q4D176",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D176"
  },
  {
    "uniprot": "Q4DMP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMP6"
  },
  {
    "uniprot": "Q4CYB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYB9"
  },
  {
    "uniprot": "Q4D3B7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3B7"
  },
  {
    "uniprot": "Q4CLP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLP0"
  },
  {
    "uniprot": "Q4D7X2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7X2"
  },
  {
    "uniprot": "Q4DKB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKB9"
  },
  {
    "uniprot": "Q4CP66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP66"
  },
  {
    "uniprot": "Q4DNP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNP3"
  },
  {
    "uniprot": "Q4DZJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZJ3"
  },
  {
    "uniprot": "Q4CUY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUY3"
  },
  {
    "uniprot": "Q4DG95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG95"
  },
  {
    "uniprot": "Q4DDD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDD1"
  },
  {
    "uniprot": "Q4DKV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKV2"
  },
  {
    "uniprot": "Q4D3B9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3B9"
  },
  {
    "uniprot": "Q4CRF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRF6"
  },
  {
    "uniprot": "Q4CLQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLQ9"
  },
  {
    "uniprot": "Q4D885",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D885"
  },
  {
    "uniprot": "Q4D9U6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9U6"
  },
  {
    "uniprot": "Q4D904",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D904"
  },
  {
    "uniprot": "Q4E5N9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5N9"
  },
  {
    "uniprot": "Q4D642",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D642"
  },
  {
    "uniprot": "Q4CWB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWB1"
  },
  {
    "uniprot": "Q4DIV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIV2"
  },
  {
    "uniprot": "Q4DAL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAL7"
  },
  {
    "uniprot": "Q4D3Y9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Y9"
  },
  {
    "uniprot": "Q4DEP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEP6"
  },
  {
    "uniprot": "Q4DN81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN81"
  },
  {
    "uniprot": "Q4CTX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTX7"
  },
  {
    "uniprot": "Q4E4F8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4F8"
  },
  {
    "uniprot": "Q4DTP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTP0"
  },
  {
    "uniprot": "Q4CMX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMX6"
  },
  {
    "uniprot": "Q4DKQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKQ7"
  },
  {
    "uniprot": "Q4CWU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWU0"
  },
  {
    "uniprot": "Q4DB12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB12"
  },
  {
    "uniprot": "Q4DP89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP89"
  },
  {
    "uniprot": "Q4DV11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV11"
  },
  {
    "uniprot": "Q4DES7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DES7"
  },
  {
    "uniprot": "Q4E5D7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5D7"
  },
  {
    "uniprot": "Q4E103",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E103"
  },
  {
    "uniprot": "Q4CZP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZP7"
  },
  {
    "uniprot": "Q4DE10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE10"
  },
  {
    "uniprot": "Q4DCP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCP1"
  },
  {
    "uniprot": "Q4E0L2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0L2"
  },
  {
    "uniprot": "Q4CW73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW73"
  },
  {
    "uniprot": "Q4D9M1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9M1"
  },
  {
    "uniprot": "Q4DEL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEL4"
  },
  {
    "uniprot": "Q4CT15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT15"
  },
  {
    "uniprot": "Q4DDA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDA2"
  },
  {
    "uniprot": "Q4DC14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC14"
  },
  {
    "uniprot": "Q4D384",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D384"
  },
  {
    "uniprot": "Q4D991",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D991"
  },
  {
    "uniprot": "Q4DZN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZN0"
  },
  {
    "uniprot": "Q4CXB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXB6"
  },
  {
    "uniprot": "Q4E640",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E640"
  },
  {
    "uniprot": "Q4DEE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEE6"
  },
  {
    "uniprot": "Q4D2F7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2F7"
  },
  {
    "uniprot": "Q4DW35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW35"
  },
  {
    "uniprot": "Q4D7V4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7V4"
  },
  {
    "uniprot": "Q4D5T9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5T9"
  },
  {
    "uniprot": "Q4D386",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D386"
  },
  {
    "uniprot": "Q4DQ73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ73"
  },
  {
    "uniprot": "Q4DZA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZA7"
  },
  {
    "uniprot": "Q4CKW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKW6"
  },
  {
    "uniprot": "Q4CNG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNG0"
  },
  {
    "uniprot": "Q4DBK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBK3"
  },
  {
    "uniprot": "Q4DCX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCX4"
  },
  {
    "uniprot": "Q4E0P4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0P4"
  },
  {
    "uniprot": "Q4CYF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYF9"
  },
  {
    "uniprot": "Q4CX57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX57"
  },
  {
    "uniprot": "Q4D9T6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9T6"
  },
  {
    "uniprot": "Q4DBX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBX2"
  },
  {
    "uniprot": "Q4DLZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLZ1"
  },
  {
    "uniprot": "Q4CMB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMB9"
  },
  {
    "uniprot": "Q4CLK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLK7"
  },
  {
    "uniprot": "Q4DMS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMS8"
  },
  {
    "uniprot": "Q4CMM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMM7"
  },
  {
    "uniprot": "Q4D621",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D621"
  },
  {
    "uniprot": "Q4E3F4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3F4"
  },
  {
    "uniprot": "Q4D2R3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2R3"
  },
  {
    "uniprot": "Q4DVK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVK7"
  },
  {
    "uniprot": "Q4D4P2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4P2"
  },
  {
    "uniprot": "Q4D6A4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6A4"
  },
  {
    "uniprot": "Q4DM14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM14"
  },
  {
    "uniprot": "Q4DCU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCU6"
  },
  {
    "uniprot": "Q4CNS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNS0"
  },
  {
    "uniprot": "Q4CT43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT43"
  },
  {
    "uniprot": "Q4D8B3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8B3"
  },
  {
    "uniprot": "Q4CKT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKT2"
  },
  {
    "uniprot": "Q4DGK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGK9"
  },
  {
    "uniprot": "Q4DVP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVP8"
  },
  {
    "uniprot": "Q4E397",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E397"
  },
  {
    "uniprot": "Q4D7U5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7U5"
  },
  {
    "uniprot": "Q4D3N3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3N3"
  },
  {
    "uniprot": "Q4D0I3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0I3"
  },
  {
    "uniprot": "Q4D3K1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3K1"
  },
  {
    "uniprot": "Q4DQ72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ72"
  },
  {
    "uniprot": "Q4E2E4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2E4"
  },
  {
    "uniprot": "Q4DRE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRE4"
  },
  {
    "uniprot": "Q4DKA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKA5"
  },
  {
    "uniprot": "Q4D851",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D851"
  },
  {
    "uniprot": "Q4DEY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEY7"
  },
  {
    "uniprot": "Q4E0Q6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Q6"
  },
  {
    "uniprot": "Q4D520",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D520"
  },
  {
    "uniprot": "Q4DCH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCH8"
  },
  {
    "uniprot": "Q4D061",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D061"
  },
  {
    "uniprot": "Q4DR92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR92"
  },
  {
    "uniprot": "Q4D4G1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4G1"
  },
  {
    "uniprot": "Q4DQE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQE6"
  },
  {
    "uniprot": "Q4D2N5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2N5"
  },
  {
    "uniprot": "Q4E2Z7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Z7"
  },
  {
    "uniprot": "Q4DTH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTH2"
  },
  {
    "uniprot": "Q4D9M5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9M5"
  },
  {
    "uniprot": "Q4DFU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFU5"
  },
  {
    "uniprot": "Q4CKG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKG4"
  },
  {
    "uniprot": "Q4DGC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGC1"
  },
  {
    "uniprot": "Q4DUZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUZ0"
  },
  {
    "uniprot": "Q4DLK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLK6"
  },
  {
    "uniprot": "Q4D5I4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5I4"
  },
  {
    "uniprot": "Q4DE02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE02"
  },
  {
    "uniprot": "Q4E5P6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5P6"
  },
  {
    "uniprot": "Q4DYR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYR8"
  },
  {
    "uniprot": "Q4DKN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKN7"
  },
  {
    "uniprot": "Q4E0E6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0E6"
  },
  {
    "uniprot": "Q4D1I1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1I1"
  },
  {
    "uniprot": "Q4DRE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRE2"
  },
  {
    "uniprot": "Q4DPS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPS4"
  },
  {
    "uniprot": "Q4D0X4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0X4"
  },
  {
    "uniprot": "Q4DU88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU88"
  },
  {
    "uniprot": "Q4DDA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDA9"
  },
  {
    "uniprot": "Q4CSP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSP9"
  },
  {
    "uniprot": "Q4CKZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKZ4"
  },
  {
    "uniprot": "Q4D920",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D920"
  },
  {
    "uniprot": "Q4CXT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXT8"
  },
  {
    "uniprot": "Q4DCM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCM8"
  },
  {
    "uniprot": "Q4D1E5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1E5"
  },
  {
    "uniprot": "Q4D4B6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4B6"
  },
  {
    "uniprot": "Q4DU74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU74"
  },
  {
    "uniprot": "Q4CL10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL10"
  },
  {
    "uniprot": "Q4D715",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D715"
  },
  {
    "uniprot": "Q4E3V5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3V5"
  },
  {
    "uniprot": "Q4CQ99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ99"
  },
  {
    "uniprot": "Q4E685",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E685"
  },
  {
    "uniprot": "Q4D7S6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7S6"
  },
  {
    "uniprot": "Q4D5T2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5T2"
  },
  {
    "uniprot": "Q4DR71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR71"
  },
  {
    "uniprot": "Q4DU65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU65"
  },
  {
    "uniprot": "Q4DVN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVN0"
  },
  {
    "uniprot": "Q4D1Q4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Q4"
  },
  {
    "uniprot": "Q4CMT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMT6"
  },
  {
    "uniprot": "Q4CVF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVF9"
  },
  {
    "uniprot": "Q4CVD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVD1"
  },
  {
    "uniprot": "Q4CUI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUI5"
  },
  {
    "uniprot": "Q4DGW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGW1"
  },
  {
    "uniprot": "Q4DNM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNM6"
  },
  {
    "uniprot": "Q4E4V8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4V8"
  },
  {
    "uniprot": "Q4CVI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVI5"
  },
  {
    "uniprot": "Q4CTF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTF7"
  },
  {
    "uniprot": "Q4E4D5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4D5"
  },
  {
    "uniprot": "Q4CP78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP78"
  },
  {
    "uniprot": "Q4E5G7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5G7"
  },
  {
    "uniprot": "Q4CRS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRS8"
  },
  {
    "uniprot": "Q4CYN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYN4"
  },
  {
    "uniprot": "Q4E3T5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3T5"
  },
  {
    "uniprot": "Q4D4M6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4M6"
  },
  {
    "uniprot": "Q4DD03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD03"
  },
  {
    "uniprot": "Q4DBR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBR6"
  },
  {
    "uniprot": "Q4E2C3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2C3"
  },
  {
    "uniprot": "Q4D7E6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7E6"
  },
  {
    "uniprot": "Q4D500",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D500"
  },
  {
    "uniprot": "Q4DKV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKV0"
  },
  {
    "uniprot": "Q4CSU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSU1"
  },
  {
    "uniprot": "Q4CLP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLP3"
  },
  {
    "uniprot": "Q4DNA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNA1"
  },
  {
    "uniprot": "Q4DS60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS60"
  },
  {
    "uniprot": "Q4CXP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXP6"
  },
  {
    "uniprot": "Q4E373",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E373"
  },
  {
    "uniprot": "Q4DJM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJM7"
  },
  {
    "uniprot": "Q4CU17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU17"
  },
  {
    "uniprot": "Q4DXS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXS4"
  },
  {
    "uniprot": "Q4CMZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMZ7"
  },
  {
    "uniprot": "Q4CYR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYR4"
  },
  {
    "uniprot": "Q4CZ45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ45"
  },
  {
    "uniprot": "Q4D3S1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3S1"
  },
  {
    "uniprot": "Q4DJM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJM0"
  },
  {
    "uniprot": "Q4DQW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQW1"
  },
  {
    "uniprot": "Q4CX87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX87"
  },
  {
    "uniprot": "Q4D4L6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4L6"
  },
  {
    "uniprot": "Q4CP59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP59"
  },
  {
    "uniprot": "Q4CZT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZT5"
  },
  {
    "uniprot": "Q4D8P2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8P2"
  },
  {
    "uniprot": "Q4CWY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWY4"
  },
  {
    "uniprot": "Q4D8H7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8H7"
  },
  {
    "uniprot": "Q4DUL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUL2"
  },
  {
    "uniprot": "Q4E2M9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2M9"
  },
  {
    "uniprot": "Q4CVM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVM9"
  },
  {
    "uniprot": "Q4D2U5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2U5"
  },
  {
    "uniprot": "Q4E3U6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3U6"
  },
  {
    "uniprot": "Q4D4K2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4K2"
  },
  {
    "uniprot": "Q4DDR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDR6"
  },
  {
    "uniprot": "Q4E1D7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1D7"
  },
  {
    "uniprot": "Q4E3E8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3E8"
  },
  {
    "uniprot": "Q4D5U3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5U3"
  },
  {
    "uniprot": "Q4DZZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZZ3"
  },
  {
    "uniprot": "Q4DSP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSP1"
  },
  {
    "uniprot": "Q4E5L3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5L3"
  },
  {
    "uniprot": "Q4DXS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXS7"
  },
  {
    "uniprot": "Q4DD86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD86"
  },
  {
    "uniprot": "Q4D3R3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3R3"
  },
  {
    "uniprot": "Q4CW16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW16"
  },
  {
    "uniprot": "Q4D573",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D573"
  },
  {
    "uniprot": "Q4DZU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZU2"
  },
  {
    "uniprot": "Q4E457",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E457"
  },
  {
    "uniprot": "Q4E504",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E504"
  },
  {
    "uniprot": "Q4DXA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXA7"
  },
  {
    "uniprot": "Q4DRF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRF7"
  },
  {
    "uniprot": "Q4DPK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPK7"
  },
  {
    "uniprot": "Q4D428",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D428"
  },
  {
    "uniprot": "Q4DSD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSD6"
  },
  {
    "uniprot": "Q4DN89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN89"
  },
  {
    "uniprot": "Q4DCK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCK4"
  },
  {
    "uniprot": "Q4CQL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQL1"
  },
  {
    "uniprot": "Q4CW41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW41"
  },
  {
    "uniprot": "Q4E243",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E243"
  },
  {
    "uniprot": "Q4DN82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN82"
  },
  {
    "uniprot": "Q4D0J1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0J1"
  },
  {
    "uniprot": "Q4D8Z3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Z3"
  },
  {
    "uniprot": "Q4DL02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL02"
  },
  {
    "uniprot": "Q4CZ76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ76"
  },
  {
    "uniprot": "Q4DHU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHU1"
  },
  {
    "uniprot": "Q4DQH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQH5"
  },
  {
    "uniprot": "Q4CL91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL91"
  },
  {
    "uniprot": "Q4DF52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF52"
  },
  {
    "uniprot": "Q4CNI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNI4"
  },
  {
    "uniprot": "Q4DI52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI52"
  },
  {
    "uniprot": "Q4DND0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DND0"
  },
  {
    "uniprot": "Q4E0N5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0N5"
  },
  {
    "uniprot": "Q4CU31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU31"
  },
  {
    "uniprot": "Q4DK81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK81"
  },
  {
    "uniprot": "Q4DES9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DES9"
  },
  {
    "uniprot": "Q4DGQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGQ2"
  },
  {
    "uniprot": "Q4DIM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIM0"
  },
  {
    "uniprot": "Q4D615",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D615"
  },
  {
    "uniprot": "Q4D0E0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0E0"
  },
  {
    "uniprot": "Q4CUL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUL2"
  },
  {
    "uniprot": "Q4DYC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYC8"
  },
  {
    "uniprot": "Q4DYQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYQ3"
  },
  {
    "uniprot": "Q4CL74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL74"
  },
  {
    "uniprot": "Q4CR61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR61"
  },
  {
    "uniprot": "Q4CY14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY14"
  },
  {
    "uniprot": "Q4E0S7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0S7"
  },
  {
    "uniprot": "Q4E455",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E455"
  },
  {
    "uniprot": "Q4CQ94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ94"
  },
  {
    "uniprot": "Q4DBJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBJ1"
  },
  {
    "uniprot": "Q4DC33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC33"
  },
  {
    "uniprot": "Q4DKG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKG9"
  },
  {
    "uniprot": "Q4DY58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY58"
  },
  {
    "uniprot": "Q4DPN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPN3"
  },
  {
    "uniprot": "Q4D070",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D070"
  },
  {
    "uniprot": "Q4CVE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVE5"
  },
  {
    "uniprot": "Q4CLQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLQ1"
  },
  {
    "uniprot": "Q4E0P5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0P5"
  },
  {
    "uniprot": "Q4DP49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP49"
  },
  {
    "uniprot": "Q4DPD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPD3"
  },
  {
    "uniprot": "Q4CMC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMC3"
  },
  {
    "uniprot": "Q4DUJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUJ5"
  },
  {
    "uniprot": "Q4E2L2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2L2"
  },
  {
    "uniprot": "Q4CYN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYN0"
  },
  {
    "uniprot": "Q4CVX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVX0"
  },
  {
    "uniprot": "Q4CUH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUH6"
  },
  {
    "uniprot": "Q4DWB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWB2"
  },
  {
    "uniprot": "Q4DK07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK07"
  },
  {
    "uniprot": "Q4DFS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFS6"
  },
  {
    "uniprot": "Q4DCS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCS1"
  },
  {
    "uniprot": "Q4E4U1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4U1"
  },
  {
    "uniprot": "Q4CQS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQS5"
  },
  {
    "uniprot": "Q4DJX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJX0"
  },
  {
    "uniprot": "Q4DV16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV16"
  },
  {
    "uniprot": "Q4DAP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAP7"
  },
  {
    "uniprot": "Q4DCI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCI7"
  },
  {
    "uniprot": "Q4CYV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYV3"
  },
  {
    "uniprot": "Q4E4E3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4E3"
  },
  {
    "uniprot": "Q4CSY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSY6"
  },
  {
    "uniprot": "Q4DFP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFP1"
  },
  {
    "uniprot": "Q4CT50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT50"
  },
  {
    "uniprot": "Q4D798",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D798"
  },
  {
    "uniprot": "Q4DUN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUN7"
  },
  {
    "uniprot": "Q4CQ41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ41"
  },
  {
    "uniprot": "Q4DVV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVV7"
  },
  {
    "uniprot": "Q4CYH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYH4"
  },
  {
    "uniprot": "Q4DPJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPJ2"
  },
  {
    "uniprot": "Q4DN86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN86"
  },
  {
    "uniprot": "Q4DVK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVK4"
  },
  {
    "uniprot": "Q4CUV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUV4"
  },
  {
    "uniprot": "Q4D3P2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3P2"
  },
  {
    "uniprot": "Q4D7D9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7D9"
  },
  {
    "uniprot": "Q4D3U8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3U8"
  },
  {
    "uniprot": "Q4DEH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEH5"
  },
  {
    "uniprot": "Q4CYP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYP4"
  },
  {
    "uniprot": "Q4CZK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZK0"
  },
  {
    "uniprot": "Q4D757",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D757"
  },
  {
    "uniprot": "Q4E5P7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5P7"
  },
  {
    "uniprot": "Q4DFR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFR8"
  },
  {
    "uniprot": "Q4CN49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN49"
  },
  {
    "uniprot": "Q4DMZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMZ5"
  },
  {
    "uniprot": "Q4DLT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLT0"
  },
  {
    "uniprot": "Q4D8P7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8P7"
  },
  {
    "uniprot": "Q4CYZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYZ5"
  },
  {
    "uniprot": "Q4D5B3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5B3"
  },
  {
    "uniprot": "Q4CZ30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ30"
  },
  {
    "uniprot": "Q4DEA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEA4"
  },
  {
    "uniprot": "Q4CPZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPZ9"
  },
  {
    "uniprot": "Q4DI38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI38"
  },
  {
    "uniprot": "Q4DEP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEP8"
  },
  {
    "uniprot": "Q4D4N3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4N3"
  },
  {
    "uniprot": "Q4DAL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAL6"
  },
  {
    "uniprot": "Q4DWW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWW3"
  },
  {
    "uniprot": "Q4DRN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRN9"
  },
  {
    "uniprot": "Q4DY74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY74"
  },
  {
    "uniprot": "Q4CTP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTP0"
  },
  {
    "uniprot": "Q4DK83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK83"
  },
  {
    "uniprot": "Q4CKZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKZ3"
  },
  {
    "uniprot": "Q4CSA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSA3"
  },
  {
    "uniprot": "Q4DBN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBN1"
  },
  {
    "uniprot": "Q4CRL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRL5"
  },
  {
    "uniprot": "Q4CSX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSX4"
  },
  {
    "uniprot": "Q4CY90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY90"
  },
  {
    "uniprot": "Q4DGX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGX6"
  },
  {
    "uniprot": "Q4DTK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTK8"
  },
  {
    "uniprot": "Q4CUQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUQ0"
  },
  {
    "uniprot": "Q4DKI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKI0"
  },
  {
    "uniprot": "Q4D5H7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5H7"
  },
  {
    "uniprot": "Q4E2Y0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Y0"
  },
  {
    "uniprot": "Q4DDD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDD6"
  },
  {
    "uniprot": "Q4CPL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPL9"
  },
  {
    "uniprot": "Q4DDR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDR0"
  },
  {
    "uniprot": "Q4D2E2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2E2"
  },
  {
    "uniprot": "Q4D376",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D376"
  },
  {
    "uniprot": "Q4CX71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX71"
  },
  {
    "uniprot": "Q4DCA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCA8"
  },
  {
    "uniprot": "Q4D1V6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1V6"
  },
  {
    "uniprot": "Q4D6R5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6R5"
  },
  {
    "uniprot": "Q4D787",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D787"
  },
  {
    "uniprot": "Q4E1G7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1G7"
  },
  {
    "uniprot": "Q4CTV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTV8"
  },
  {
    "uniprot": "Q4DGA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGA1"
  },
  {
    "uniprot": "Q4D4T1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4T1"
  },
  {
    "uniprot": "Q4D1M7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1M7"
  },
  {
    "uniprot": "Q4DDY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDY8"
  },
  {
    "uniprot": "Q4D2T3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2T3"
  },
  {
    "uniprot": "Q4DD42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD42"
  },
  {
    "uniprot": "Q4DT36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT36"
  },
  {
    "uniprot": "Q4E3H8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3H8"
  },
  {
    "uniprot": "Q4DZW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZW5"
  },
  {
    "uniprot": "Q4DUI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUI0"
  },
  {
    "uniprot": "Q4DKI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKI9"
  },
  {
    "uniprot": "Q4CT27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT27"
  },
  {
    "uniprot": "Q4DXX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXX0"
  },
  {
    "uniprot": "Q4D6P8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6P8"
  },
  {
    "uniprot": "Q4DIW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIW2"
  },
  {
    "uniprot": "Q4DTD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTD6"
  },
  {
    "uniprot": "Q4DXM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXM5"
  },
  {
    "uniprot": "Q4D6S0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6S0"
  },
  {
    "uniprot": "Q4DCX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCX9"
  },
  {
    "uniprot": "Q4CR08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR08"
  },
  {
    "uniprot": "Q4DCJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCJ4"
  },
  {
    "uniprot": "Q4DWK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWK2"
  },
  {
    "uniprot": "Q4D1X9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1X9"
  },
  {
    "uniprot": "Q4CKB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKB3"
  },
  {
    "uniprot": "Q4DPR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPR0"
  },
  {
    "uniprot": "Q4DRM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRM2"
  },
  {
    "uniprot": "Q4CL45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL45"
  },
  {
    "uniprot": "Q4DJ62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ62"
  },
  {
    "uniprot": "Q4CSU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSU9"
  },
  {
    "uniprot": "Q4D3J3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3J3"
  },
  {
    "uniprot": "Q4D2A5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2A5"
  },
  {
    "uniprot": "Q4D9H1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9H1"
  },
  {
    "uniprot": "Q4D036",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D036"
  },
  {
    "uniprot": "Q4DDX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDX0"
  },
  {
    "uniprot": "Q4E3F8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3F8"
  },
  {
    "uniprot": "Q4CLA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLA7"
  },
  {
    "uniprot": "Q4E681",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E681"
  },
  {
    "uniprot": "Q4DJ91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ91"
  },
  {
    "uniprot": "Q4DKD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKD6"
  },
  {
    "uniprot": "Q4CN61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN61"
  },
  {
    "uniprot": "Q4DL86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL86"
  },
  {
    "uniprot": "Q4D2Z4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Z4"
  },
  {
    "uniprot": "Q4CMH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMH5"
  },
  {
    "uniprot": "Q4E0L5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0L5"
  },
  {
    "uniprot": "Q4CYL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYL8"
  },
  {
    "uniprot": "Q4CLJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLJ0"
  },
  {
    "uniprot": "Q4DJL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJL7"
  },
  {
    "uniprot": "Q4DE22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE22"
  },
  {
    "uniprot": "Q4CSK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSK4"
  },
  {
    "uniprot": "Q4D6Q2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Q2"
  },
  {
    "uniprot": "Q4DHP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHP9"
  },
  {
    "uniprot": "Q4DFZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFZ3"
  },
  {
    "uniprot": "Q4CYE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYE2"
  },
  {
    "uniprot": "Q4DVJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVJ1"
  },
  {
    "uniprot": "Q4DIS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIS7"
  },
  {
    "uniprot": "Q4D0Z6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Z6"
  },
  {
    "uniprot": "Q4DVF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVF5"
  },
  {
    "uniprot": "Q4CLP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLP2"
  },
  {
    "uniprot": "Q4DBF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBF3"
  },
  {
    "uniprot": "Q4CTW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTW6"
  },
  {
    "uniprot": "Q4D2Y7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Y7"
  },
  {
    "uniprot": "Q4DRB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRB6"
  },
  {
    "uniprot": "Q4CR86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR86"
  },
  {
    "uniprot": "Q4CNB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNB7"
  },
  {
    "uniprot": "Q4DDP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDP3"
  },
  {
    "uniprot": "Q4DAP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAP2"
  },
  {
    "uniprot": "Q9GT49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q9GT49"
  },
  {
    "uniprot": "Q4E643",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E643"
  },
  {
    "uniprot": "Q4CTJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTJ0"
  },
  {
    "uniprot": "Q4CY42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY42"
  },
  {
    "uniprot": "Q4DX46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX46"
  },
  {
    "uniprot": "Q4D1B3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1B3"
  },
  {
    "uniprot": "Q4DVJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVJ3"
  },
  {
    "uniprot": "Q4DYK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYK5"
  },
  {
    "uniprot": "Q4E4U0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4U0"
  },
  {
    "uniprot": "Q4CT37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT37"
  },
  {
    "uniprot": "Q4DSY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSY1"
  },
  {
    "uniprot": "Q4CMC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMC2"
  },
  {
    "uniprot": "Q4DVD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVD2"
  },
  {
    "uniprot": "Q4CVE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVE6"
  },
  {
    "uniprot": "Q4DA02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA02"
  },
  {
    "uniprot": "Q4CU08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU08"
  },
  {
    "uniprot": "Q4CYV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYV6"
  },
  {
    "uniprot": "Q4CSG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSG7"
  },
  {
    "uniprot": "Q4DSC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSC2"
  },
  {
    "uniprot": "Q4E2S1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2S1"
  },
  {
    "uniprot": "Q4E0U7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0U7"
  },
  {
    "uniprot": "Q4D9H0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9H0"
  },
  {
    "uniprot": "Q4DWS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWS0"
  },
  {
    "uniprot": "Q4CKR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKR1"
  },
  {
    "uniprot": "Q4CVP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVP1"
  },
  {
    "uniprot": "Q4E4Y2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Y2"
  },
  {
    "uniprot": "Q4DCY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCY6"
  },
  {
    "uniprot": "Q4E5J3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5J3"
  },
  {
    "uniprot": "Q4E2B9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2B9"
  },
  {
    "uniprot": "Q4D2W8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2W8"
  },
  {
    "uniprot": "Q4CNL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNL7"
  },
  {
    "uniprot": "Q4DJ58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ58"
  },
  {
    "uniprot": "Q4D1V2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1V2"
  },
  {
    "uniprot": "Q4CQI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQI1"
  },
  {
    "uniprot": "Q4DFA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFA9"
  },
  {
    "uniprot": "Q4E328",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E328"
  },
  {
    "uniprot": "Q4DIA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIA1"
  },
  {
    "uniprot": "Q4DNA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNA7"
  },
  {
    "uniprot": "Q4E0K2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0K2"
  },
  {
    "uniprot": "Q4DL35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL35"
  },
  {
    "uniprot": "Q4D182",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D182"
  },
  {
    "uniprot": "Q4CKR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKR3"
  },
  {
    "uniprot": "Q4DVC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVC2"
  },
  {
    "uniprot": "Q4E3P3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3P3"
  },
  {
    "uniprot": "Q4DC24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC24"
  },
  {
    "uniprot": "Q4E467",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E467"
  },
  {
    "uniprot": "Q4DJ06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ06"
  },
  {
    "uniprot": "Q4E379",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E379"
  },
  {
    "uniprot": "Q4D0U0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0U0"
  },
  {
    "uniprot": "Q4CWH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWH8"
  },
  {
    "uniprot": "Q4D8E5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8E5"
  },
  {
    "uniprot": "Q4DZF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZF5"
  },
  {
    "uniprot": "Q4DF82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF82"
  },
  {
    "uniprot": "Q4DHD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHD1"
  },
  {
    "uniprot": "Q4DGH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGH3"
  },
  {
    "uniprot": "Q4DQ64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ64"
  },
  {
    "uniprot": "Q4DRZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRZ6"
  },
  {
    "uniprot": "Q4DUG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUG2"
  },
  {
    "uniprot": "Q4DTQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTQ1"
  },
  {
    "uniprot": "Q4CV57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV57"
  },
  {
    "uniprot": "Q4DU66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU66"
  },
  {
    "uniprot": "Q4DAY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAY3"
  },
  {
    "uniprot": "Q4DFX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFX6"
  },
  {
    "uniprot": "Q4DCJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCJ5"
  },
  {
    "uniprot": "Q4CWZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWZ0"
  },
  {
    "uniprot": "Q4E3E0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3E0"
  },
  {
    "uniprot": "Q4CPG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPG4"
  },
  {
    "uniprot": "Q4D1M5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1M5"
  },
  {
    "uniprot": "Q4CNZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNZ0"
  },
  {
    "uniprot": "Q4CSP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSP3"
  },
  {
    "uniprot": "Q4DPQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPQ5"
  },
  {
    "uniprot": "Q4DQR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQR4"
  },
  {
    "uniprot": "Q4DKC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKC7"
  },
  {
    "uniprot": "Q4CR77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR77"
  },
  {
    "uniprot": "Q4D7K8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7K8"
  },
  {
    "uniprot": "Q4E3U3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3U3"
  },
  {
    "uniprot": "Q4DK85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK85"
  },
  {
    "uniprot": "Q4DXU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXU2"
  },
  {
    "uniprot": "Q4DLE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLE8"
  },
  {
    "uniprot": "Q4CWK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWK0"
  },
  {
    "uniprot": "Q4D173",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D173"
  },
  {
    "uniprot": "Q4DA80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA80"
  },
  {
    "uniprot": "Q4E5Y1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Y1"
  },
  {
    "uniprot": "Q4D8H9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8H9"
  },
  {
    "uniprot": "Q4E2C9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2C9"
  },
  {
    "uniprot": "Q4D878",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D878"
  },
  {
    "uniprot": "Q4D288",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D288"
  },
  {
    "uniprot": "Q4DGF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGF5"
  },
  {
    "uniprot": "Q4CVB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVB7"
  },
  {
    "uniprot": "Q4DQY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQY8"
  },
  {
    "uniprot": "Q4DZG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZG0"
  },
  {
    "uniprot": "Q4CTQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTQ4"
  },
  {
    "uniprot": "Q4E4V6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4V6"
  },
  {
    "uniprot": "Q4DJR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJR7"
  },
  {
    "uniprot": "Q4DZR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZR6"
  },
  {
    "uniprot": "Q4DFT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFT6"
  },
  {
    "uniprot": "Q4DRC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRC9"
  },
  {
    "uniprot": "Q4D6G0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6G0"
  },
  {
    "uniprot": "Q4D2B0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2B0"
  },
  {
    "uniprot": "Q4DHM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHM2"
  },
  {
    "uniprot": "Q4DMH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMH8"
  },
  {
    "uniprot": "Q4CWM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWM5"
  },
  {
    "uniprot": "Q4D3Z3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Z3"
  },
  {
    "uniprot": "Q4CMI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMI8"
  },
  {
    "uniprot": "Q4E1X8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1X8"
  },
  {
    "uniprot": "Q4DHI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHI4"
  },
  {
    "uniprot": "Q4E2P3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2P3"
  },
  {
    "uniprot": "Q4D6K4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6K4"
  },
  {
    "uniprot": "Q4DBC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBC1"
  },
  {
    "uniprot": "Q4CY20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY20"
  },
  {
    "uniprot": "Q4DWN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWN1"
  },
  {
    "uniprot": "Q4CXT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXT9"
  },
  {
    "uniprot": "Q4E1U8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1U8"
  },
  {
    "uniprot": "Q4DMY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMY7"
  },
  {
    "uniprot": "Q4DR52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR52"
  },
  {
    "uniprot": "Q4DP30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP30"
  },
  {
    "uniprot": "Q4DTR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTR0"
  },
  {
    "uniprot": "Q4DYA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYA6"
  },
  {
    "uniprot": "Q4DHZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHZ2"
  },
  {
    "uniprot": "Q4DJ83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ83"
  },
  {
    "uniprot": "Q4DGT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGT7"
  },
  {
    "uniprot": "Q4D863",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D863"
  },
  {
    "uniprot": "Q4E5S4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5S4"
  },
  {
    "uniprot": "Q4DLK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLK5"
  },
  {
    "uniprot": "Q4DPJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPJ8"
  },
  {
    "uniprot": "Q4DQ88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ88"
  },
  {
    "uniprot": "Q4CL53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL53"
  },
  {
    "uniprot": "Q4CYY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYY3"
  },
  {
    "uniprot": "Q4CW57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW57"
  },
  {
    "uniprot": "Q4E4E7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4E7"
  },
  {
    "uniprot": "Q4CTL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTL9"
  },
  {
    "uniprot": "Q4DWX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWX5"
  },
  {
    "uniprot": "Q4DV89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV89"
  },
  {
    "uniprot": "Q4DIG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIG2"
  },
  {
    "uniprot": "Q4D4D5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4D5"
  },
  {
    "uniprot": "Q4CXT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXT2"
  },
  {
    "uniprot": "Q4DB11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB11"
  },
  {
    "uniprot": "Q4DNV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNV8"
  },
  {
    "uniprot": "Q4CL48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL48"
  },
  {
    "uniprot": "Q4E5G4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5G4"
  },
  {
    "uniprot": "Q4CUX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUX4"
  },
  {
    "uniprot": "Q4E2P5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2P5"
  },
  {
    "uniprot": "Q4CR99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR99"
  },
  {
    "uniprot": "Q4DS27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS27"
  },
  {
    "uniprot": "Q4DBK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBK1"
  },
  {
    "uniprot": "Q4D1V1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1V1"
  },
  {
    "uniprot": "Q4DST5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DST5"
  },
  {
    "uniprot": "Q4D8P9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8P9"
  },
  {
    "uniprot": "Q4D1M4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1M4"
  },
  {
    "uniprot": "Q4DUX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUX7"
  },
  {
    "uniprot": "Q4D880",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D880"
  },
  {
    "uniprot": "Q4D1C4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1C4"
  },
  {
    "uniprot": "Q4CRW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRW5"
  },
  {
    "uniprot": "Q4DT18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT18"
  },
  {
    "uniprot": "Q4E0R8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0R8"
  },
  {
    "uniprot": "Q4E682",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E682"
  },
  {
    "uniprot": "Q4D7M6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7M6"
  },
  {
    "uniprot": "Q4D9D1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9D1"
  },
  {
    "uniprot": "Q4D1S3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1S3"
  },
  {
    "uniprot": "Q4D8K0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8K0"
  },
  {
    "uniprot": "Q4D1C0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1C0"
  },
  {
    "uniprot": "Q4CUG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUG9"
  },
  {
    "uniprot": "Q4D1L8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1L8"
  },
  {
    "uniprot": "Q4CQI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQI7"
  },
  {
    "uniprot": "Q4DYD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYD7"
  },
  {
    "uniprot": "Q4DX35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX35"
  },
  {
    "uniprot": "Q4DS75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS75"
  },
  {
    "uniprot": "Q4DWH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWH8"
  },
  {
    "uniprot": "Q4DCY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCY7"
  },
  {
    "uniprot": "Q4CNR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNR6"
  },
  {
    "uniprot": "Q4D4Y9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Y9"
  },
  {
    "uniprot": "Q4CWN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWN4"
  },
  {
    "uniprot": "Q4D0A0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0A0"
  },
  {
    "uniprot": "Q4DXH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXH2"
  },
  {
    "uniprot": "Q4E3M5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3M5"
  },
  {
    "uniprot": "Q4DL91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL91"
  },
  {
    "uniprot": "Q4DTS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTS6"
  },
  {
    "uniprot": "Q4D3D1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3D1"
  },
  {
    "uniprot": "Q4E0E2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0E2"
  },
  {
    "uniprot": "Q4CWX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWX0"
  },
  {
    "uniprot": "Q4E359",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E359"
  },
  {
    "uniprot": "Q4DHP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHP1"
  },
  {
    "uniprot": "Q4CNG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNG7"
  },
  {
    "uniprot": "Q4CV90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV90"
  },
  {
    "uniprot": "Q4D4K0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4K0"
  },
  {
    "uniprot": "Q4CLW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLW9"
  },
  {
    "uniprot": "Q4D562",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D562"
  },
  {
    "uniprot": "Q4DX28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX28"
  },
  {
    "uniprot": "Q4DUA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUA7"
  },
  {
    "uniprot": "Q4CVD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVD6"
  },
  {
    "uniprot": "Q4E4X6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4X6"
  },
  {
    "uniprot": "Q4CTL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTL7"
  },
  {
    "uniprot": "Q4DX93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX93"
  },
  {
    "uniprot": "Q4D7S9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7S9"
  },
  {
    "uniprot": "Q4CM91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM91"
  },
  {
    "uniprot": "Q4DSP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSP5"
  },
  {
    "uniprot": "Q4D944",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D944"
  },
  {
    "uniprot": "Q4CZF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZF0"
  },
  {
    "uniprot": "Q4CSR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSR7"
  },
  {
    "uniprot": "Q4E0F8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0F8"
  },
  {
    "uniprot": "Q4CZ93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ93"
  },
  {
    "uniprot": "Q4DXV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXV7"
  },
  {
    "uniprot": "Q4DPR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPR7"
  },
  {
    "uniprot": "Q4DQ97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ97"
  },
  {
    "uniprot": "Q4E4L9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4L9"
  },
  {
    "uniprot": "Q4CW37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW37"
  },
  {
    "uniprot": "Q4CVQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVQ6"
  },
  {
    "uniprot": "Q4D065",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D065"
  },
  {
    "uniprot": "Q4DYZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYZ0"
  },
  {
    "uniprot": "Q4DUW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUW0"
  },
  {
    "uniprot": "Q4D367",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D367"
  },
  {
    "uniprot": "Q4CUD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUD6"
  },
  {
    "uniprot": "Q4E1R0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1R0"
  },
  {
    "uniprot": "Q4DK78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK78"
  },
  {
    "uniprot": "Q4DV06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV06"
  },
  {
    "uniprot": "Q4DA87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA87"
  },
  {
    "uniprot": "Q4CX43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX43"
  },
  {
    "uniprot": "Q4CWL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWL4"
  },
  {
    "uniprot": "Q4DN11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN11"
  },
  {
    "uniprot": "Q4E4S9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4S9"
  },
  {
    "uniprot": "Q4CTJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTJ9"
  },
  {
    "uniprot": "Q4D3K4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3K4"
  },
  {
    "uniprot": "Q4DIA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIA4"
  },
  {
    "uniprot": "Q4CWA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWA1"
  },
  {
    "uniprot": "Q4D1S1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1S1"
  },
  {
    "uniprot": "Q4CX95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX95"
  },
  {
    "uniprot": "Q4D4V0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4V0"
  },
  {
    "uniprot": "Q4D2X2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2X2"
  },
  {
    "uniprot": "Q4CSY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSY7"
  },
  {
    "uniprot": "Q4DQD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQD0"
  },
  {
    "uniprot": "Q4CUR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUR9"
  },
  {
    "uniprot": "Q4D9J0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9J0"
  },
  {
    "uniprot": "Q4D6L6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6L6"
  },
  {
    "uniprot": "Q4D3E9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3E9"
  },
  {
    "uniprot": "Q4DHI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHI5"
  },
  {
    "uniprot": "Q4DCF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCF4"
  },
  {
    "uniprot": "Q4D7W0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7W0"
  },
  {
    "uniprot": "Q4D8A6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8A6"
  },
  {
    "uniprot": "Q4DLS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLS0"
  },
  {
    "uniprot": "Q4E288",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E288"
  },
  {
    "uniprot": "Q4E459",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E459"
  },
  {
    "uniprot": "Q4CW68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW68"
  },
  {
    "uniprot": "Q4CL54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL54"
  },
  {
    "uniprot": "Q4E571",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E571"
  },
  {
    "uniprot": "Q4DUG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUG9"
  },
  {
    "uniprot": "Q4DQU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQU0"
  },
  {
    "uniprot": "Q4DAS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAS6"
  },
  {
    "uniprot": "Q4DMR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMR2"
  },
  {
    "uniprot": "Q4DHI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHI1"
  },
  {
    "uniprot": "Q4D3F7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3F7"
  },
  {
    "uniprot": "Q4CL92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL92"
  },
  {
    "uniprot": "Q4CV17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV17"
  },
  {
    "uniprot": "Q4D0W5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0W5"
  },
  {
    "uniprot": "Q4DSL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSL9"
  },
  {
    "uniprot": "Q4DNH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNH5"
  },
  {
    "uniprot": "Q4D6S9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6S9"
  },
  {
    "uniprot": "Q4DBN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBN0"
  },
  {
    "uniprot": "Q4DBP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBP6"
  },
  {
    "uniprot": "Q4DLP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLP4"
  },
  {
    "uniprot": "Q4CZ02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ02"
  },
  {
    "uniprot": "Q4DYJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYJ2"
  },
  {
    "uniprot": "Q4D690",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D690"
  },
  {
    "uniprot": "Q4DDQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDQ0"
  },
  {
    "uniprot": "Q4D2W9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2W9"
  },
  {
    "uniprot": "Q4DVP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVP0"
  },
  {
    "uniprot": "Q4E305",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E305"
  },
  {
    "uniprot": "Q4DZM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZM5"
  },
  {
    "uniprot": "Q4CU54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU54"
  },
  {
    "uniprot": "Q4CNW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNW5"
  },
  {
    "uniprot": "Q4D645",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D645"
  },
  {
    "uniprot": "Q4D4J4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4J4"
  },
  {
    "uniprot": "Q4D0D8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0D8"
  },
  {
    "uniprot": "Q4D738",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D738"
  },
  {
    "uniprot": "Q4DU97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU97"
  },
  {
    "uniprot": "Q4DHC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHC4"
  },
  {
    "uniprot": "Q4D9W8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9W8"
  },
  {
    "uniprot": "Q4DRS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRS3"
  },
  {
    "uniprot": "Q4DP83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP83"
  },
  {
    "uniprot": "Q4DHS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHS8"
  },
  {
    "uniprot": "Q4DYL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYL3"
  },
  {
    "uniprot": "Q4DNH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNH0"
  },
  {
    "uniprot": "Q4DA96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA96"
  },
  {
    "uniprot": "Q4CX76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX76"
  },
  {
    "uniprot": "Q4E0F0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0F0"
  },
  {
    "uniprot": "Q4DK90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK90"
  },
  {
    "uniprot": "Q4DPZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPZ2"
  },
  {
    "uniprot": "Q4CNJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNJ2"
  },
  {
    "uniprot": "Q4DPV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPV0"
  },
  {
    "uniprot": "Q4DYQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYQ6"
  },
  {
    "uniprot": "Q4DNN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNN9"
  },
  {
    "uniprot": "Q4CX61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX61"
  },
  {
    "uniprot": "Q4DSA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSA4"
  },
  {
    "uniprot": "Q4CNT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNT1"
  },
  {
    "uniprot": "Q4E2Z1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Z1"
  },
  {
    "uniprot": "Q4E115",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E115"
  },
  {
    "uniprot": "Q4DY84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY84"
  },
  {
    "uniprot": "Q4E2V4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2V4"
  },
  {
    "uniprot": "Q4DED2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DED2"
  },
  {
    "uniprot": "Q4E369",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E369"
  },
  {
    "uniprot": "Q4CKR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKR4"
  },
  {
    "uniprot": "Q4CTN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTN4"
  },
  {
    "uniprot": "Q4CPA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPA6"
  },
  {
    "uniprot": "Q4DDI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDI2"
  },
  {
    "uniprot": "Q4DMJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMJ4"
  },
  {
    "uniprot": "Q4DXI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXI7"
  },
  {
    "uniprot": "Q4DIT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIT9"
  },
  {
    "uniprot": "Q4DCL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCL8"
  },
  {
    "uniprot": "Q4DUS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUS2"
  },
  {
    "uniprot": "Q4DBQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBQ9"
  },
  {
    "uniprot": "Q4CX91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX91"
  },
  {
    "uniprot": "Q4CRT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRT0"
  },
  {
    "uniprot": "Q4E2R7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2R7"
  },
  {
    "uniprot": "Q4CKW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKW9"
  },
  {
    "uniprot": "Q4CXC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXC7"
  },
  {
    "uniprot": "Q4CXG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXG2"
  },
  {
    "uniprot": "Q4DN79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN79"
  },
  {
    "uniprot": "Q4D080",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D080"
  },
  {
    "uniprot": "Q4DQ61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ61"
  },
  {
    "uniprot": "Q4DAG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAG8"
  },
  {
    "uniprot": "Q4DR51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR51"
  },
  {
    "uniprot": "Q4DF66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF66"
  },
  {
    "uniprot": "Q4DDP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDP2"
  },
  {
    "uniprot": "Q4D2F2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2F2"
  },
  {
    "uniprot": "Q4DNI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNI6"
  },
  {
    "uniprot": "Q4CYI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYI8"
  },
  {
    "uniprot": "Q4D063",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D063"
  },
  {
    "uniprot": "Q4CM90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM90"
  },
  {
    "uniprot": "Q4CM07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM07"
  },
  {
    "uniprot": "Q4CPK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPK2"
  },
  {
    "uniprot": "Q4DVK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVK1"
  },
  {
    "uniprot": "Q4E2T3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2T3"
  },
  {
    "uniprot": "Q4DDE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDE3"
  },
  {
    "uniprot": "Q4CUM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUM9"
  },
  {
    "uniprot": "Q4DYX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYX3"
  },
  {
    "uniprot": "Q4DZG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZG1"
  },
  {
    "uniprot": "Q4DWJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWJ6"
  },
  {
    "uniprot": "Q4DPC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPC1"
  },
  {
    "uniprot": "Q4DY93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY93"
  },
  {
    "uniprot": "Q4CLR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLR3"
  },
  {
    "uniprot": "Q4CRN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRN1"
  },
  {
    "uniprot": "Q4DA88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA88"
  },
  {
    "uniprot": "Q4DDH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDH8"
  },
  {
    "uniprot": "Q4CTT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTT4"
  },
  {
    "uniprot": "Q4DFB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFB1"
  },
  {
    "uniprot": "Q4CQ90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ90"
  },
  {
    "uniprot": "Q4DYK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYK7"
  },
  {
    "uniprot": "Q4D517",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D517"
  },
  {
    "uniprot": "Q4D6T9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6T9"
  },
  {
    "uniprot": "Q4D7U0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7U0"
  },
  {
    "uniprot": "Q4D9X4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9X4"
  },
  {
    "uniprot": "Q4D4R2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4R2"
  },
  {
    "uniprot": "Q4CQX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQX0"
  },
  {
    "uniprot": "Q4E4D7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4D7"
  },
  {
    "uniprot": "Q4E1F8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1F8"
  },
  {
    "uniprot": "Q4E050",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E050"
  },
  {
    "uniprot": "Q4CSL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSL1"
  },
  {
    "uniprot": "Q4CLX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLX2"
  },
  {
    "uniprot": "Q4D2H5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2H5"
  },
  {
    "uniprot": "Q4DQL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQL2"
  },
  {
    "uniprot": "Q4DT26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT26"
  },
  {
    "uniprot": "Q4DLM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLM8"
  },
  {
    "uniprot": "Q4DSN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSN6"
  },
  {
    "uniprot": "Q4CNP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNP2"
  },
  {
    "uniprot": "Q4CW69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW69"
  },
  {
    "uniprot": "Q4CMD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMD4"
  },
  {
    "uniprot": "Q4DPD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPD4"
  },
  {
    "uniprot": "Q4DQ33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ33"
  },
  {
    "uniprot": "Q4CML4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CML4"
  },
  {
    "uniprot": "Q4E560",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E560"
  },
  {
    "uniprot": "Q4E3W0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3W0"
  },
  {
    "uniprot": "Q4CQI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQI0"
  },
  {
    "uniprot": "Q4DDX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDX6"
  },
  {
    "uniprot": "Q4CLA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLA8"
  },
  {
    "uniprot": "Q4DBG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBG0"
  },
  {
    "uniprot": "Q4D5K3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5K3"
  },
  {
    "uniprot": "Q4D5C3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5C3"
  },
  {
    "uniprot": "Q4CQ27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ27"
  },
  {
    "uniprot": "Q4CK92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CK92"
  },
  {
    "uniprot": "Q4DXX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXX9"
  },
  {
    "uniprot": "Q4DNN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNN8"
  },
  {
    "uniprot": "Q4DQ86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ86"
  },
  {
    "uniprot": "Q4DQ84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ84"
  },
  {
    "uniprot": "Q4E0Q9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Q9"
  },
  {
    "uniprot": "Q4CSM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSM6"
  },
  {
    "uniprot": "Q4DP53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP53"
  },
  {
    "uniprot": "Q4DUL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUL0"
  },
  {
    "uniprot": "Q4CVQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVQ7"
  },
  {
    "uniprot": "Q4DQ14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ14"
  },
  {
    "uniprot": "Q4E1G4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1G4"
  },
  {
    "uniprot": "Q4DU95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU95"
  },
  {
    "uniprot": "Q4DGP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGP0"
  },
  {
    "uniprot": "Q4CZI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZI3"
  },
  {
    "uniprot": "Q4DH04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH04"
  },
  {
    "uniprot": "Q4D448",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D448"
  },
  {
    "uniprot": "Q4D7Y6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Y6"
  },
  {
    "uniprot": "Q4D6B3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6B3"
  },
  {
    "uniprot": "Q4D1C5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1C5"
  },
  {
    "uniprot": "Q4E655",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E655"
  },
  {
    "uniprot": "Q4E5D8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5D8"
  },
  {
    "uniprot": "Q4DL73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL73"
  },
  {
    "uniprot": "Q4CU40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU40"
  },
  {
    "uniprot": "Q4DIP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIP5"
  },
  {
    "uniprot": "Q4CLZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLZ8"
  },
  {
    "uniprot": "Q4DS26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS26"
  },
  {
    "uniprot": "Q4CSR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSR2"
  },
  {
    "uniprot": "Q4DE30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE30"
  },
  {
    "uniprot": "Q4CT48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT48"
  },
  {
    "uniprot": "Q4D4D4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4D4"
  },
  {
    "uniprot": "Q4D2I2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2I2"
  },
  {
    "uniprot": "Q4D3Y8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Y8"
  },
  {
    "uniprot": "Q4E327",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E327"
  },
  {
    "uniprot": "Q4DPU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPU5"
  },
  {
    "uniprot": "Q4DUP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUP3"
  },
  {
    "uniprot": "Q4DXJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXJ8"
  },
  {
    "uniprot": "Q4D8Z4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Z4"
  },
  {
    "uniprot": "Q4CKR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKR6"
  },
  {
    "uniprot": "Q4DK01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK01"
  },
  {
    "uniprot": "Q4D601",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D601"
  },
  {
    "uniprot": "Q4D345",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D345"
  },
  {
    "uniprot": "Q4D9Q0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Q0"
  },
  {
    "uniprot": "Q4DF23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF23"
  },
  {
    "uniprot": "Q4DHY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHY5"
  },
  {
    "uniprot": "Q4D6H2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6H2"
  },
  {
    "uniprot": "Q4CZT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZT1"
  },
  {
    "uniprot": "Q4CRF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRF8"
  },
  {
    "uniprot": "Q4CTK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTK7"
  },
  {
    "uniprot": "Q4DDB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDB1"
  },
  {
    "uniprot": "Q4DLX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLX6"
  },
  {
    "uniprot": "Q4DQY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQY0"
  },
  {
    "uniprot": "Q4DRD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRD2"
  },
  {
    "uniprot": "Q4DLJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLJ4"
  },
  {
    "uniprot": "Q4DDC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDC6"
  },
  {
    "uniprot": "Q4D1R6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1R6"
  },
  {
    "uniprot": "Q4CTV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTV3"
  },
  {
    "uniprot": "Q4DN60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN60"
  },
  {
    "uniprot": "Q4DA44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA44"
  },
  {
    "uniprot": "Q4DNQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNQ5"
  },
  {
    "uniprot": "Q4DT60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT60"
  },
  {
    "uniprot": "Q4DEW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEW8"
  },
  {
    "uniprot": "Q4DE87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE87"
  },
  {
    "uniprot": "Q4CN45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN45"
  },
  {
    "uniprot": "Q4D1X6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1X6"
  },
  {
    "uniprot": "Q4DLT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLT5"
  },
  {
    "uniprot": "Q4E4H7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4H7"
  },
  {
    "uniprot": "Q4D2G2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2G2"
  },
  {
    "uniprot": "Q4D097",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D097"
  },
  {
    "uniprot": "Q4DW75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW75"
  },
  {
    "uniprot": "Q4DMW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMW2"
  },
  {
    "uniprot": "Q4D200",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D200"
  },
  {
    "uniprot": "Q4D5Z7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Z7"
  },
  {
    "uniprot": "Q4D293",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D293"
  },
  {
    "uniprot": "Q4DMF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMF1"
  },
  {
    "uniprot": "Q4DWK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWK9"
  },
  {
    "uniprot": "Q4DFS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFS2"
  },
  {
    "uniprot": "Q4D5D2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5D2"
  },
  {
    "uniprot": "Q4D9Z8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Z8"
  },
  {
    "uniprot": "Q4E5W5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5W5"
  },
  {
    "uniprot": "Q4DHJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHJ1"
  },
  {
    "uniprot": "Q4DMD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMD3"
  },
  {
    "uniprot": "Q4D9K5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9K5"
  },
  {
    "uniprot": "Q4D2K8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2K8"
  },
  {
    "uniprot": "Q4CVA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVA7"
  },
  {
    "uniprot": "Q4CWF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWF1"
  },
  {
    "uniprot": "Q4D756",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D756"
  },
  {
    "uniprot": "Q4E665",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E665"
  },
  {
    "uniprot": "Q4CTX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTX0"
  },
  {
    "uniprot": "Q4D6B6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6B6"
  },
  {
    "uniprot": "Q4DAT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAT7"
  },
  {
    "uniprot": "Q4E565",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E565"
  },
  {
    "uniprot": "Q4DHB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHB6"
  },
  {
    "uniprot": "Q4D8T1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8T1"
  },
  {
    "uniprot": "Q4D0A1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0A1"
  },
  {
    "uniprot": "Q4D2U1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2U1"
  },
  {
    "uniprot": "Q4CXS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXS7"
  },
  {
    "uniprot": "Q4CYJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYJ2"
  },
  {
    "uniprot": "Q4DWQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWQ9"
  },
  {
    "uniprot": "Q4CXU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXU7"
  },
  {
    "uniprot": "Q4DSA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSA3"
  },
  {
    "uniprot": "Q4CY08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY08"
  },
  {
    "uniprot": "Q4D5I6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5I6"
  },
  {
    "uniprot": "Q4DAB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAB7"
  },
  {
    "uniprot": "Q4E3Y5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Y5"
  },
  {
    "uniprot": "Q4DTH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTH1"
  },
  {
    "uniprot": "Q4D9Z4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Z4"
  },
  {
    "uniprot": "Q4DHC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHC8"
  },
  {
    "uniprot": "Q4CP90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP90"
  },
  {
    "uniprot": "Q4CSV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSV6"
  },
  {
    "uniprot": "Q4CYU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYU5"
  },
  {
    "uniprot": "Q4D7Z3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Z3"
  },
  {
    "uniprot": "Q4CMD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMD9"
  },
  {
    "uniprot": "Q4DM29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM29"
  },
  {
    "uniprot": "Q4DID1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DID1"
  },
  {
    "uniprot": "Q4DEY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEY2"
  },
  {
    "uniprot": "Q4CQF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQF3"
  },
  {
    "uniprot": "Q4CYL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYL4"
  },
  {
    "uniprot": "Q4CU72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU72"
  },
  {
    "uniprot": "Q4CW38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW38"
  },
  {
    "uniprot": "Q4CUG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUG1"
  },
  {
    "uniprot": "Q4CTD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTD1"
  },
  {
    "uniprot": "Q4DS83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS83"
  },
  {
    "uniprot": "Q4DG38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG38"
  },
  {
    "uniprot": "Q4D5X3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5X3"
  },
  {
    "uniprot": "Q4E635",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E635"
  },
  {
    "uniprot": "Q4DM18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM18"
  },
  {
    "uniprot": "Q4DF88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF88"
  },
  {
    "uniprot": "Q4E1Y7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Y7"
  },
  {
    "uniprot": "Q4DWB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWB1"
  },
  {
    "uniprot": "Q4D443",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D443"
  },
  {
    "uniprot": "Q4D6K1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6K1"
  },
  {
    "uniprot": "Q4E4S7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4S7"
  },
  {
    "uniprot": "Q4E2T9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2T9"
  },
  {
    "uniprot": "Q4DF31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF31"
  },
  {
    "uniprot": "Q4D5B9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5B9"
  },
  {
    "uniprot": "Q4DV00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV00"
  },
  {
    "uniprot": "Q4CQE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQE8"
  },
  {
    "uniprot": "Q4DZN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZN3"
  },
  {
    "uniprot": "Q4CQT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQT5"
  },
  {
    "uniprot": "Q4CQD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQD0"
  },
  {
    "uniprot": "Q4DVK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVK8"
  },
  {
    "uniprot": "Q4E1Z7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Z7"
  },
  {
    "uniprot": "Q4D790",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D790"
  },
  {
    "uniprot": "Q4CXX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXX0"
  },
  {
    "uniprot": "Q4DTP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTP7"
  },
  {
    "uniprot": "Q4D9E4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9E4"
  },
  {
    "uniprot": "Q4DU73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU73"
  },
  {
    "uniprot": "Q4E0V5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0V5"
  },
  {
    "uniprot": "Q4CMB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMB7"
  },
  {
    "uniprot": "Q4DBR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBR3"
  },
  {
    "uniprot": "Q4E0Y7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Y7"
  },
  {
    "uniprot": "Q4CMF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMF3"
  },
  {
    "uniprot": "Q4DSL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSL7"
  },
  {
    "uniprot": "Q4DP43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP43"
  },
  {
    "uniprot": "Q4DLS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLS1"
  },
  {
    "uniprot": "Q4DX31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX31"
  },
  {
    "uniprot": "Q4DWG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWG8"
  },
  {
    "uniprot": "Q4DC22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC22"
  },
  {
    "uniprot": "Q4DPV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPV5"
  },
  {
    "uniprot": "Q4DNA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNA6"
  },
  {
    "uniprot": "Q4E2Z4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Z4"
  },
  {
    "uniprot": "Q4E534",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E534"
  },
  {
    "uniprot": "Q4CUC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUC5"
  },
  {
    "uniprot": "Q4E3Y2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Y2"
  },
  {
    "uniprot": "Q4CRM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRM9"
  },
  {
    "uniprot": "Q4CS06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS06"
  },
  {
    "uniprot": "Q4DJ48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ48"
  },
  {
    "uniprot": "Q4D8B9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8B9"
  },
  {
    "uniprot": "Q4CPX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPX9"
  },
  {
    "uniprot": "Q4CXV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXV1"
  },
  {
    "uniprot": "Q4CQK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQK2"
  },
  {
    "uniprot": "Q4DIQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIQ3"
  },
  {
    "uniprot": "Q4DNT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNT3"
  },
  {
    "uniprot": "Q4DSS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSS4"
  },
  {
    "uniprot": "Q4E388",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E388"
  },
  {
    "uniprot": "Q4E408",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E408"
  },
  {
    "uniprot": "Q4CY44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY44"
  },
  {
    "uniprot": "Q4DEY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEY0"
  },
  {
    "uniprot": "Q4DNL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNL3"
  },
  {
    "uniprot": "Q4CNS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNS3"
  },
  {
    "uniprot": "Q4D197",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D197"
  },
  {
    "uniprot": "Q4E586",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E586"
  },
  {
    "uniprot": "Q4CKA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKA8"
  },
  {
    "uniprot": "Q4D702",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D702"
  },
  {
    "uniprot": "Q4DAQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAQ6"
  },
  {
    "uniprot": "Q4E607",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E607"
  },
  {
    "uniprot": "Q4DU99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU99"
  },
  {
    "uniprot": "Q4CXR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXR4"
  },
  {
    "uniprot": "Q4D6M5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6M5"
  },
  {
    "uniprot": "Q4CLN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLN0"
  },
  {
    "uniprot": "Q4DIR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIR2"
  },
  {
    "uniprot": "Q4CKZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKZ1"
  },
  {
    "uniprot": "Q4DBP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBP0"
  },
  {
    "uniprot": "Q4CNT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNT2"
  },
  {
    "uniprot": "Q4DS46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS46"
  },
  {
    "uniprot": "Q4CW23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW23"
  },
  {
    "uniprot": "Q4D2V5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2V5"
  },
  {
    "uniprot": "Q4CVP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVP9"
  },
  {
    "uniprot": "Q4D291",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D291"
  },
  {
    "uniprot": "Q4D3Z7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Z7"
  },
  {
    "uniprot": "Q4CK90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CK90"
  },
  {
    "uniprot": "Q4DJE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJE6"
  },
  {
    "uniprot": "Q4DDL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDL6"
  },
  {
    "uniprot": "Q4E629",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E629"
  },
  {
    "uniprot": "Q4D1N3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1N3"
  },
  {
    "uniprot": "Q4DP11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP11"
  },
  {
    "uniprot": "Q4DSP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSP0"
  },
  {
    "uniprot": "Q4CMB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMB5"
  },
  {
    "uniprot": "Q4CWW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWW0"
  },
  {
    "uniprot": "Q4D8G6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8G6"
  },
  {
    "uniprot": "Q4CRT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRT5"
  },
  {
    "uniprot": "Q4CSE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSE0"
  },
  {
    "uniprot": "Q4D0B5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0B5"
  },
  {
    "uniprot": "Q4DX99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX99"
  },
  {
    "uniprot": "Q4CY79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY79"
  },
  {
    "uniprot": "Q4E5G3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5G3"
  },
  {
    "uniprot": "Q4D6C1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6C1"
  },
  {
    "uniprot": "Q4D4A4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4A4"
  },
  {
    "uniprot": "Q4DLF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLF7"
  },
  {
    "uniprot": "Q4CRL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRL8"
  },
  {
    "uniprot": "Q4CX80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX80"
  },
  {
    "uniprot": "Q4CMS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMS2"
  },
  {
    "uniprot": "Q4CYY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYY0"
  },
  {
    "uniprot": "Q4DBB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBB1"
  },
  {
    "uniprot": "Q4CZ88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ88"
  },
  {
    "uniprot": "Q4D2G1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2G1"
  },
  {
    "uniprot": "Q4DJD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJD7"
  },
  {
    "uniprot": "Q4CLH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLH7"
  },
  {
    "uniprot": "Q4DLA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLA7"
  },
  {
    "uniprot": "Q4DUI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUI7"
  },
  {
    "uniprot": "Q4E5U6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5U6"
  },
  {
    "uniprot": "Q4DDM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDM4"
  },
  {
    "uniprot": "Q4DIH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIH7"
  },
  {
    "uniprot": "Q4E192",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E192"
  },
  {
    "uniprot": "Q4D440",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D440"
  },
  {
    "uniprot": "Q4DF01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF01"
  },
  {
    "uniprot": "Q4E214",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E214"
  },
  {
    "uniprot": "Q4D5V8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5V8"
  },
  {
    "uniprot": "Q4CNI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNI5"
  },
  {
    "uniprot": "Q4CZ89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ89"
  },
  {
    "uniprot": "Q4DIM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIM7"
  },
  {
    "uniprot": "Q4CMA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMA4"
  },
  {
    "uniprot": "Q4DLP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLP3"
  },
  {
    "uniprot": "Q4DWZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWZ8"
  },
  {
    "uniprot": "Q4E2P9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2P9"
  },
  {
    "uniprot": "Q4CZF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZF4"
  },
  {
    "uniprot": "Q4D656",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D656"
  },
  {
    "uniprot": "Q4D362",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D362"
  },
  {
    "uniprot": "Q4DA97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA97"
  },
  {
    "uniprot": "Q4DVU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVU9"
  },
  {
    "uniprot": "Q4CLN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLN8"
  },
  {
    "uniprot": "Q4D359",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D359"
  },
  {
    "uniprot": "Q4DMV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMV0"
  },
  {
    "uniprot": "Q4DYA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYA8"
  },
  {
    "uniprot": "Q4E013",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E013"
  },
  {
    "uniprot": "Q4DP03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP03"
  },
  {
    "uniprot": "Q4DJE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJE0"
  },
  {
    "uniprot": "Q4DUF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUF0"
  },
  {
    "uniprot": "Q4D694",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D694"
  },
  {
    "uniprot": "Q4CRP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRP2"
  },
  {
    "uniprot": "Q4DFA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFA6"
  },
  {
    "uniprot": "Q4DAU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAU0"
  },
  {
    "uniprot": "Q4CSP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSP1"
  },
  {
    "uniprot": "Q4DFJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFJ7"
  },
  {
    "uniprot": "Q4DKI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKI7"
  },
  {
    "uniprot": "Q4DT12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT12"
  },
  {
    "uniprot": "Q4D9S7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9S7"
  },
  {
    "uniprot": "Q4DGI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGI0"
  },
  {
    "uniprot": "Q4D838",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D838"
  },
  {
    "uniprot": "Q4DX05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX05"
  },
  {
    "uniprot": "Q4D1I3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1I3"
  },
  {
    "uniprot": "Q4DGA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGA5"
  },
  {
    "uniprot": "Q4DCI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCI2"
  },
  {
    "uniprot": "Q4CU97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU97"
  },
  {
    "uniprot": "Q4CU41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU41"
  },
  {
    "uniprot": "Q4DKK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKK9"
  },
  {
    "uniprot": "Q4DZS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZS0"
  },
  {
    "uniprot": "Q4D9U1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9U1"
  },
  {
    "uniprot": "Q4DRK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRK5"
  },
  {
    "uniprot": "Q4CTT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTT5"
  },
  {
    "uniprot": "Q4DFI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFI9"
  },
  {
    "uniprot": "Q4DRH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRH3"
  },
  {
    "uniprot": "Q4D7E4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7E4"
  },
  {
    "uniprot": "Q4CVH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVH5"
  },
  {
    "uniprot": "Q4D5M2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5M2"
  },
  {
    "uniprot": "Q4D3S4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3S4"
  },
  {
    "uniprot": "Q4DHU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHU8"
  },
  {
    "uniprot": "Q4CNV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNV6"
  },
  {
    "uniprot": "Q4CL04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL04"
  },
  {
    "uniprot": "Q4CTX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTX8"
  },
  {
    "uniprot": "Q4CRF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRF4"
  },
  {
    "uniprot": "Q4E150",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E150"
  },
  {
    "uniprot": "Q4DRA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRA0"
  },
  {
    "uniprot": "Q4DDW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDW1"
  },
  {
    "uniprot": "Q4E477",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E477"
  },
  {
    "uniprot": "Q4CZ08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ08"
  },
  {
    "uniprot": "Q4D2D0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2D0"
  },
  {
    "uniprot": "Q4E281",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E281"
  },
  {
    "uniprot": "Q4DYP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYP9"
  },
  {
    "uniprot": "Q4E3L8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3L8"
  },
  {
    "uniprot": "Q4D1F8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1F8"
  },
  {
    "uniprot": "Q4CKY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKY1"
  },
  {
    "uniprot": "Q4D0S6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0S6"
  },
  {
    "uniprot": "Q4CTL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTL0"
  },
  {
    "uniprot": "Q4E084",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E084"
  },
  {
    "uniprot": "Q4E497",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E497"
  },
  {
    "uniprot": "Q4CPL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPL6"
  },
  {
    "uniprot": "Q4DYY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYY7"
  },
  {
    "uniprot": "Q4CMQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMQ6"
  },
  {
    "uniprot": "Q4CWT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWT8"
  },
  {
    "uniprot": "Q4DAJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAJ8"
  },
  {
    "uniprot": "Q4DU27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU27"
  },
  {
    "uniprot": "Q4CMM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMM3"
  },
  {
    "uniprot": "Q4DVT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVT6"
  },
  {
    "uniprot": "Q4DN91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN91"
  },
  {
    "uniprot": "Q4DAM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAM4"
  },
  {
    "uniprot": "Q4CM16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM16"
  },
  {
    "uniprot": "Q4DQZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQZ9"
  },
  {
    "uniprot": "Q4DTI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTI2"
  },
  {
    "uniprot": "Q4D0J2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0J2"
  },
  {
    "uniprot": "Q4DP28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP28"
  },
  {
    "uniprot": "Q4CN33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN33"
  },
  {
    "uniprot": "Q4CZ03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ03"
  },
  {
    "uniprot": "Q4D6M6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6M6"
  },
  {
    "uniprot": "Q4CKS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKS6"
  },
  {
    "uniprot": "Q4D6W4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6W4"
  },
  {
    "uniprot": "Q4DDG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDG5"
  },
  {
    "uniprot": "Q4DRR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRR6"
  },
  {
    "uniprot": "Q4D148",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D148"
  },
  {
    "uniprot": "Q4DQ94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ94"
  },
  {
    "uniprot": "Q4E438",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E438"
  },
  {
    "uniprot": "Q4CZ95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ95"
  },
  {
    "uniprot": "Q4CTA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTA6"
  },
  {
    "uniprot": "Q4DW01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW01"
  },
  {
    "uniprot": "Q4E1A3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1A3"
  },
  {
    "uniprot": "Q4DF21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF21"
  },
  {
    "uniprot": "Q4DE65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE65"
  },
  {
    "uniprot": "Q4E418",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E418"
  },
  {
    "uniprot": "Q4DK18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK18"
  },
  {
    "uniprot": "Q4DH01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH01"
  },
  {
    "uniprot": "Q4DYM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYM6"
  },
  {
    "uniprot": "Q4DAD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAD5"
  },
  {
    "uniprot": "Q4DJS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJS3"
  },
  {
    "uniprot": "Q4E3X8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3X8"
  },
  {
    "uniprot": "Q4D965",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D965"
  },
  {
    "uniprot": "Q4CPQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPQ1"
  },
  {
    "uniprot": "Q4CUI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUI2"
  },
  {
    "uniprot": "Q4E077",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E077"
  },
  {
    "uniprot": "Q4DGD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGD5"
  },
  {
    "uniprot": "Q4CZY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZY9"
  },
  {
    "uniprot": "Q4DQY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQY7"
  },
  {
    "uniprot": "Q4DYX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYX2"
  },
  {
    "uniprot": "Q4CNV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNV3"
  },
  {
    "uniprot": "Q4D314",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D314"
  },
  {
    "uniprot": "Q4DTV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTV2"
  },
  {
    "uniprot": "Q4CWS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWS5"
  },
  {
    "uniprot": "Q4D0N8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0N8"
  },
  {
    "uniprot": "Q4E3W4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3W4"
  },
  {
    "uniprot": "Q4CQF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQF7"
  },
  {
    "uniprot": "Q4DUT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUT4"
  },
  {
    "uniprot": "Q4DLG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLG9"
  },
  {
    "uniprot": "Q4DRW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRW7"
  },
  {
    "uniprot": "Q4CNH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNH2"
  },
  {
    "uniprot": "Q4E4H8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4H8"
  },
  {
    "uniprot": "Q4DW94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW94"
  },
  {
    "uniprot": "Q4CU69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU69"
  },
  {
    "uniprot": "Q4DGX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGX8"
  },
  {
    "uniprot": "Q4DWB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWB5"
  },
  {
    "uniprot": "Q4D797",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D797"
  },
  {
    "uniprot": "Q4CTH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTH6"
  },
  {
    "uniprot": "Q4DKL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKL2"
  },
  {
    "uniprot": "Q4DZ15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ15"
  },
  {
    "uniprot": "Q4CTT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTT7"
  },
  {
    "uniprot": "Q4D3Y7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Y7"
  },
  {
    "uniprot": "Q4DYH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYH9"
  },
  {
    "uniprot": "Q4D3Y2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Y2"
  },
  {
    "uniprot": "Q4DVH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVH5"
  },
  {
    "uniprot": "Q4DIR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIR3"
  },
  {
    "uniprot": "Q4D6M9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6M9"
  },
  {
    "uniprot": "Q4CVJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVJ5"
  },
  {
    "uniprot": "Q4DZC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZC8"
  },
  {
    "uniprot": "Q4CKH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKH9"
  },
  {
    "uniprot": "Q4CSJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSJ3"
  },
  {
    "uniprot": "Q4CMD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMD1"
  },
  {
    "uniprot": "Q4DPH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPH0"
  },
  {
    "uniprot": "Q4CPP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPP4"
  },
  {
    "uniprot": "Q4DK87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK87"
  },
  {
    "uniprot": "Q4E2H1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2H1"
  },
  {
    "uniprot": "Q4DHU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHU4"
  },
  {
    "uniprot": "Q4CWA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWA6"
  },
  {
    "uniprot": "Q4D202",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D202"
  },
  {
    "uniprot": "Q4DGG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGG1"
  },
  {
    "uniprot": "Q4CTI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTI8"
  },
  {
    "uniprot": "Q4DSW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSW4"
  },
  {
    "uniprot": "Q4CSX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSX9"
  },
  {
    "uniprot": "Q4DEF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEF9"
  },
  {
    "uniprot": "Q4CP07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP07"
  },
  {
    "uniprot": "Q4D1T1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1T1"
  },
  {
    "uniprot": "Q4CKP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKP7"
  },
  {
    "uniprot": "Q4DV56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV56"
  },
  {
    "uniprot": "Q4CWD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWD3"
  },
  {
    "uniprot": "Q4DUY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUY1"
  },
  {
    "uniprot": "Q4DMT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMT9"
  },
  {
    "uniprot": "Q4DP99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP99"
  },
  {
    "uniprot": "Q4D869",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D869"
  },
  {
    "uniprot": "Q4DX33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX33"
  },
  {
    "uniprot": "Q4CXE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXE0"
  },
  {
    "uniprot": "Q4DL21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL21"
  },
  {
    "uniprot": "Q4DJQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJQ5"
  },
  {
    "uniprot": "Q4E5N5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5N5"
  },
  {
    "uniprot": "Q4D3R7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3R7"
  },
  {
    "uniprot": "Q4D581",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D581"
  },
  {
    "uniprot": "Q4DJQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJQ7"
  },
  {
    "uniprot": "Q4DP00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP00"
  },
  {
    "uniprot": "Q4DK10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK10"
  },
  {
    "uniprot": "Q4DZT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZT3"
  },
  {
    "uniprot": "Q4DXX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXX6"
  },
  {
    "uniprot": "Q4CZ85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ85"
  },
  {
    "uniprot": "Q4CT21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT21"
  },
  {
    "uniprot": "Q4CR29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR29"
  },
  {
    "uniprot": "Q4DDV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDV2"
  },
  {
    "uniprot": "Q4D9U2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9U2"
  },
  {
    "uniprot": "Q4D069",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D069"
  },
  {
    "uniprot": "Q4D628",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D628"
  },
  {
    "uniprot": "Q4D4Y0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Y0"
  },
  {
    "uniprot": "Q4D7N4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7N4"
  },
  {
    "uniprot": "Q4CP61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP61"
  },
  {
    "uniprot": "Q4D5A8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5A8"
  },
  {
    "uniprot": "Q4CZA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZA9"
  },
  {
    "uniprot": "Q4DNK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNK3"
  },
  {
    "uniprot": "Q4E4K3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4K3"
  },
  {
    "uniprot": "Q4CM19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM19"
  },
  {
    "uniprot": "Q4CPE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPE5"
  },
  {
    "uniprot": "Q4CS12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS12"
  },
  {
    "uniprot": "Q4D882",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D882"
  },
  {
    "uniprot": "Q4DF19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF19"
  },
  {
    "uniprot": "Q4D4Q2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Q2"
  },
  {
    "uniprot": "Q4DFW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFW3"
  },
  {
    "uniprot": "Q4CPK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPK5"
  },
  {
    "uniprot": "Q4CUG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUG7"
  },
  {
    "uniprot": "Q4CL15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL15"
  },
  {
    "uniprot": "Q4D4X6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4X6"
  },
  {
    "uniprot": "Q4DBQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBQ7"
  },
  {
    "uniprot": "Q4DZ35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ35"
  },
  {
    "uniprot": "Q4CUH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUH4"
  },
  {
    "uniprot": "Q4DKB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKB7"
  },
  {
    "uniprot": "Q4D6H8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6H8"
  },
  {
    "uniprot": "Q4DL63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL63"
  },
  {
    "uniprot": "Q4CKE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKE3"
  },
  {
    "uniprot": "Q4DJB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJB2"
  },
  {
    "uniprot": "Q4D714",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D714"
  },
  {
    "uniprot": "Q4E078",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E078"
  },
  {
    "uniprot": "Q4DBR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBR2"
  },
  {
    "uniprot": "Q4CQX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQX3"
  },
  {
    "uniprot": "Q4CSV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSV2"
  },
  {
    "uniprot": "Q4CPU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPU4"
  },
  {
    "uniprot": "Q4D9R6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9R6"
  },
  {
    "uniprot": "Q4DKR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKR3"
  },
  {
    "uniprot": "Q4CZ44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ44"
  },
  {
    "uniprot": "Q4D5R7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5R7"
  },
  {
    "uniprot": "Q4E028",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E028"
  },
  {
    "uniprot": "Q4E1I3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1I3"
  },
  {
    "uniprot": "Q4DL76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL76"
  },
  {
    "uniprot": "Q4CYK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYK2"
  },
  {
    "uniprot": "Q4D5G1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5G1"
  },
  {
    "uniprot": "Q4E1X5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1X5"
  },
  {
    "uniprot": "Q4DMB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMB8"
  },
  {
    "uniprot": "Q4DFC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFC4"
  },
  {
    "uniprot": "Q4DPB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPB0"
  },
  {
    "uniprot": "Q4DJU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJU6"
  },
  {
    "uniprot": "Q4DRC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRC0"
  },
  {
    "uniprot": "Q4DX70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX70"
  },
  {
    "uniprot": "Q4DMS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMS4"
  },
  {
    "uniprot": "Q4E480",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E480"
  },
  {
    "uniprot": "Q4D8K1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8K1"
  },
  {
    "uniprot": "Q4DWJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWJ5"
  },
  {
    "uniprot": "Q4CXY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXY4"
  },
  {
    "uniprot": "Q4DIK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIK7"
  },
  {
    "uniprot": "Q4CRH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRH1"
  },
  {
    "uniprot": "Q4CNP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNP5"
  },
  {
    "uniprot": "Q4D2C0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2C0"
  },
  {
    "uniprot": "Q4DAI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAI3"
  },
  {
    "uniprot": "Q4DP61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP61"
  },
  {
    "uniprot": "Q4DL32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL32"
  },
  {
    "uniprot": "Q4E4B8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4B8"
  },
  {
    "uniprot": "Q4E4V4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4V4"
  },
  {
    "uniprot": "Q4DMR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMR7"
  },
  {
    "uniprot": "Q4DK88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK88"
  },
  {
    "uniprot": "Q4D0Q4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Q4"
  },
  {
    "uniprot": "Q4DIN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIN3"
  },
  {
    "uniprot": "Q4E246",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E246"
  },
  {
    "uniprot": "Q4D8Y5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Y5"
  },
  {
    "uniprot": "Q4CUQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUQ2"
  },
  {
    "uniprot": "Q4DJP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJP2"
  },
  {
    "uniprot": "Q4DN46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN46"
  },
  {
    "uniprot": "Q4E402",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E402"
  },
  {
    "uniprot": "Q4DPI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPI1"
  },
  {
    "uniprot": "Q4CTE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTE5"
  },
  {
    "uniprot": "Q4DCV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCV8"
  },
  {
    "uniprot": "Q4DJE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJE1"
  },
  {
    "uniprot": "Q4E2X9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2X9"
  },
  {
    "uniprot": "Q4DIN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIN8"
  },
  {
    "uniprot": "Q4DB05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB05"
  },
  {
    "uniprot": "Q4DGV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGV6"
  },
  {
    "uniprot": "Q4CN76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN76"
  },
  {
    "uniprot": "Q4D3X9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3X9"
  },
  {
    "uniprot": "Q4DUZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUZ9"
  },
  {
    "uniprot": "Q4DXV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXV4"
  },
  {
    "uniprot": "Q4D808",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D808"
  },
  {
    "uniprot": "Q4DEC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEC3"
  },
  {
    "uniprot": "Q4D4J5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4J5"
  },
  {
    "uniprot": "Q4D7K6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7K6"
  },
  {
    "uniprot": "Q4CS75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS75"
  },
  {
    "uniprot": "Q4DIC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIC4"
  },
  {
    "uniprot": "Q4DLS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLS7"
  },
  {
    "uniprot": "Q4E0W4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0W4"
  },
  {
    "uniprot": "Q4D7G2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7G2"
  },
  {
    "uniprot": "Q4D7G1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7G1"
  },
  {
    "uniprot": "Q4DNK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNK8"
  },
  {
    "uniprot": "Q4DXM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXM6"
  },
  {
    "uniprot": "Q4E3K8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3K8"
  },
  {
    "uniprot": "Q4DUW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUW7"
  },
  {
    "uniprot": "Q4DV57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV57"
  },
  {
    "uniprot": "Q4D9Y7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Y7"
  },
  {
    "uniprot": "Q4DLL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLL7"
  },
  {
    "uniprot": "Q4E3P9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3P9"
  },
  {
    "uniprot": "Q4DR54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR54"
  },
  {
    "uniprot": "Q4CNC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNC4"
  },
  {
    "uniprot": "Q4DYZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYZ5"
  },
  {
    "uniprot": "Q4DJD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJD1"
  },
  {
    "uniprot": "Q4D5N2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5N2"
  },
  {
    "uniprot": "Q4DXQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXQ2"
  },
  {
    "uniprot": "Q4CQE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQE6"
  },
  {
    "uniprot": "Q4D5Z0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Z0"
  },
  {
    "uniprot": "Q4DHA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHA0"
  },
  {
    "uniprot": "Q4DMJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMJ8"
  },
  {
    "uniprot": "Q4CLG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLG5"
  },
  {
    "uniprot": "Q4E2L9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2L9"
  },
  {
    "uniprot": "Q4DU41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU41"
  },
  {
    "uniprot": "Q4DFL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFL0"
  },
  {
    "uniprot": "Q4DR57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR57"
  },
  {
    "uniprot": "Q4CU22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU22"
  },
  {
    "uniprot": "Q4DS28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS28"
  },
  {
    "uniprot": "Q4DWP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWP2"
  },
  {
    "uniprot": "Q4CRZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRZ3"
  },
  {
    "uniprot": "Q4CRY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRY7"
  },
  {
    "uniprot": "Q4DWZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWZ1"
  },
  {
    "uniprot": "Q4DUH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUH8"
  },
  {
    "uniprot": "Q4CW12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW12"
  },
  {
    "uniprot": "Q4CKD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKD4"
  },
  {
    "uniprot": "Q4DXX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXX7"
  },
  {
    "uniprot": "Q4CXA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXA3"
  },
  {
    "uniprot": "Q4CYU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYU9"
  },
  {
    "uniprot": "Q4D0U3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0U3"
  },
  {
    "uniprot": "Q4DAH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAH7"
  },
  {
    "uniprot": "Q4D4J3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4J3"
  },
  {
    "uniprot": "Q4DT13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT13"
  },
  {
    "uniprot": "Q4DB58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB58"
  },
  {
    "uniprot": "Q4CRJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRJ0"
  },
  {
    "uniprot": "Q4E264",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E264"
  },
  {
    "uniprot": "Q4DKK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKK4"
  },
  {
    "uniprot": "Q4CU70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU70"
  },
  {
    "uniprot": "Q4DRI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRI7"
  },
  {
    "uniprot": "Q4DU86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU86"
  },
  {
    "uniprot": "Q4D8S7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8S7"
  },
  {
    "uniprot": "Q4E062",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E062"
  },
  {
    "uniprot": "Q4CZ40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ40"
  },
  {
    "uniprot": "Q4DNZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNZ8"
  },
  {
    "uniprot": "Q4DBC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBC6"
  },
  {
    "uniprot": "Q4D8N9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8N9"
  },
  {
    "uniprot": "Q4CLT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLT7"
  },
  {
    "uniprot": "Q4DMI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMI9"
  },
  {
    "uniprot": "Q4DXQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXQ9"
  },
  {
    "uniprot": "Q4DKJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKJ3"
  },
  {
    "uniprot": "Q4CTQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTQ8"
  },
  {
    "uniprot": "Q4CV54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV54"
  },
  {
    "uniprot": "Q4CPH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPH0"
  },
  {
    "uniprot": "Q4DJ53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ53"
  },
  {
    "uniprot": "Q4DSA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSA5"
  },
  {
    "uniprot": "Q4DKY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKY9"
  },
  {
    "uniprot": "Q4DRN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRN1"
  },
  {
    "uniprot": "Q4CSP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSP8"
  },
  {
    "uniprot": "Q4D673",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D673"
  },
  {
    "uniprot": "Q4CZP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZP5"
  },
  {
    "uniprot": "Q4CR78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR78"
  },
  {
    "uniprot": "Q4CPU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPU1"
  },
  {
    "uniprot": "Q4DBS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBS0"
  },
  {
    "uniprot": "Q4D421",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D421"
  },
  {
    "uniprot": "Q4E513",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E513"
  },
  {
    "uniprot": "Q4DFD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFD7"
  },
  {
    "uniprot": "Q4DTT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTT5"
  },
  {
    "uniprot": "Q4D3F2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3F2"
  },
  {
    "uniprot": "Q4DHE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHE5"
  },
  {
    "uniprot": "Q4CVE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVE7"
  },
  {
    "uniprot": "Q4D4Z1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Z1"
  },
  {
    "uniprot": "Q4DEX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEX2"
  },
  {
    "uniprot": "Q4DHL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHL0"
  },
  {
    "uniprot": "Q4DBY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBY3"
  },
  {
    "uniprot": "Q4DTU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTU5"
  },
  {
    "uniprot": "Q4DZE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZE7"
  },
  {
    "uniprot": "Q4CTY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTY9"
  },
  {
    "uniprot": "Q4DF25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF25"
  },
  {
    "uniprot": "Q4DIF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIF5"
  },
  {
    "uniprot": "Q4CMN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMN7"
  },
  {
    "uniprot": "Q4CMM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMM4"
  },
  {
    "uniprot": "Q4DB79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB79"
  },
  {
    "uniprot": "Q4CL01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL01"
  },
  {
    "uniprot": "Q4E5Q0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Q0"
  },
  {
    "uniprot": "Q4DPA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPA2"
  },
  {
    "uniprot": "Q4DB03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB03"
  },
  {
    "uniprot": "Q4D6I2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6I2"
  },
  {
    "uniprot": "Q4DR90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR90"
  },
  {
    "uniprot": "Q4D7B9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7B9"
  },
  {
    "uniprot": "Q4DGP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGP2"
  },
  {
    "uniprot": "Q4CRE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRE0"
  },
  {
    "uniprot": "Q4D8C3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8C3"
  },
  {
    "uniprot": "Q4CTA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTA3"
  },
  {
    "uniprot": "Q4DAD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAD4"
  },
  {
    "uniprot": "Q4DCN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCN9"
  },
  {
    "uniprot": "Q4DL99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL99"
  },
  {
    "uniprot": "Q4DSD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSD3"
  },
  {
    "uniprot": "Q4DN94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN94"
  },
  {
    "uniprot": "Q4DH64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH64"
  },
  {
    "uniprot": "Q4E3J4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3J4"
  },
  {
    "uniprot": "Q4DZT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZT4"
  },
  {
    "uniprot": "Q4D3E0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3E0"
  },
  {
    "uniprot": "Q4DXJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXJ6"
  },
  {
    "uniprot": "Q4DVQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVQ3"
  },
  {
    "uniprot": "Q4DUC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUC4"
  },
  {
    "uniprot": "Q4DPE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPE0"
  },
  {
    "uniprot": "Q4DU51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU51"
  },
  {
    "uniprot": "Q4CKL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKL0"
  },
  {
    "uniprot": "Q4DU49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU49"
  },
  {
    "uniprot": "Q4CRV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRV0"
  },
  {
    "uniprot": "Q4DS93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS93"
  },
  {
    "uniprot": "Q4D6H5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6H5"
  },
  {
    "uniprot": "Q4DFQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFQ5"
  },
  {
    "uniprot": "Q4DG87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG87"
  },
  {
    "uniprot": "Q4DNC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNC7"
  },
  {
    "uniprot": "Q4DFE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFE0"
  },
  {
    "uniprot": "Q4CLD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLD9"
  },
  {
    "uniprot": "Q4D0F6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0F6"
  },
  {
    "uniprot": "Q4D9Q3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Q3"
  },
  {
    "uniprot": "Q4E556",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E556"
  },
  {
    "uniprot": "Q4DAZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAZ1"
  },
  {
    "uniprot": "Q4DGI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGI6"
  },
  {
    "uniprot": "Q4D483",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D483"
  },
  {
    "uniprot": "Q4DZT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZT5"
  },
  {
    "uniprot": "Q4DK29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK29"
  },
  {
    "uniprot": "Q4DXB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXB9"
  },
  {
    "uniprot": "Q4CMG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMG5"
  },
  {
    "uniprot": "Q4CU60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU60"
  },
  {
    "uniprot": "Q4D3Q1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Q1"
  },
  {
    "uniprot": "Q4DHB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHB2"
  },
  {
    "uniprot": "Q4CYP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYP2"
  },
  {
    "uniprot": "Q4CX85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX85"
  },
  {
    "uniprot": "Q4DXR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXR5"
  },
  {
    "uniprot": "Q4CL11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL11"
  },
  {
    "uniprot": "Q4DU91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU91"
  },
  {
    "uniprot": "Q4CQZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQZ6"
  },
  {
    "uniprot": "Q4CYY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYY1"
  },
  {
    "uniprot": "Q4DJ19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ19"
  },
  {
    "uniprot": "Q4DP15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP15"
  },
  {
    "uniprot": "Q4CWI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWI8"
  },
  {
    "uniprot": "Q4CL38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL38"
  },
  {
    "uniprot": "Q4DZ83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ83"
  },
  {
    "uniprot": "Q4DGB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGB4"
  },
  {
    "uniprot": "Q4D2D3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2D3"
  },
  {
    "uniprot": "Q4DYN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYN5"
  },
  {
    "uniprot": "Q4DVU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVU6"
  },
  {
    "uniprot": "Q4DWD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWD4"
  },
  {
    "uniprot": "Q4DH07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH07"
  },
  {
    "uniprot": "Q4D2E7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2E7"
  },
  {
    "uniprot": "Q4DNT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNT1"
  },
  {
    "uniprot": "Q4DGV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGV1"
  },
  {
    "uniprot": "Q4D775",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D775"
  },
  {
    "uniprot": "Q4D1E1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1E1"
  },
  {
    "uniprot": "Q4CSW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSW9"
  },
  {
    "uniprot": "Q4D3H3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3H3"
  },
  {
    "uniprot": "Q4DUK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUK0"
  },
  {
    "uniprot": "Q4CU50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU50"
  },
  {
    "uniprot": "Q4CX11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX11"
  },
  {
    "uniprot": "Q4DNE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNE7"
  },
  {
    "uniprot": "Q4D9Q6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Q6"
  },
  {
    "uniprot": "Q4D6W7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6W7"
  },
  {
    "uniprot": "Q4CL85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL85"
  },
  {
    "uniprot": "Q4CXN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXN0"
  },
  {
    "uniprot": "Q4CYR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYR5"
  },
  {
    "uniprot": "Q4DR48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR48"
  },
  {
    "uniprot": "Q4E0P7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0P7"
  },
  {
    "uniprot": "Q4DIV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIV0"
  },
  {
    "uniprot": "Q4E4I9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4I9"
  },
  {
    "uniprot": "Q4DIF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIF4"
  },
  {
    "uniprot": "Q4DY45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY45"
  },
  {
    "uniprot": "Q4DB70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB70"
  },
  {
    "uniprot": "Q4DHM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHM9"
  },
  {
    "uniprot": "Q4DX22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX22"
  },
  {
    "uniprot": "Q4CQ36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ36"
  },
  {
    "uniprot": "Q4DYU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYU5"
  },
  {
    "uniprot": "Q4DN32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN32"
  },
  {
    "uniprot": "Q4DLP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLP5"
  },
  {
    "uniprot": "Q4DNL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNL4"
  },
  {
    "uniprot": "Q4CP67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP67"
  },
  {
    "uniprot": "Q4DL85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL85"
  },
  {
    "uniprot": "Q4CSF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSF0"
  },
  {
    "uniprot": "Q4DI00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI00"
  },
  {
    "uniprot": "Q4E4Q1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Q1"
  },
  {
    "uniprot": "Q4DGA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGA0"
  },
  {
    "uniprot": "Q4DTI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTI0"
  },
  {
    "uniprot": "Q4CVC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVC5"
  },
  {
    "uniprot": "Q4CNW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNW3"
  },
  {
    "uniprot": "Q4D2Y4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Y4"
  },
  {
    "uniprot": "Q4DBU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBU3"
  },
  {
    "uniprot": "Q4CYJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYJ0"
  },
  {
    "uniprot": "Q4CUC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUC6"
  },
  {
    "uniprot": "Q4CXU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXU6"
  },
  {
    "uniprot": "Q4CR50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR50"
  },
  {
    "uniprot": "Q4DSG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSG4"
  },
  {
    "uniprot": "Q4E131",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E131"
  },
  {
    "uniprot": "Q4DPZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPZ8"
  },
  {
    "uniprot": "Q4DTV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTV7"
  },
  {
    "uniprot": "Q4CU36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU36"
  },
  {
    "uniprot": "Q4CQK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQK9"
  },
  {
    "uniprot": "Q4D0U1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0U1"
  },
  {
    "uniprot": "Q4DUX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUX8"
  },
  {
    "uniprot": "Q4D1M6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1M6"
  },
  {
    "uniprot": "Q4E4L2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4L2"
  },
  {
    "uniprot": "Q4DAU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAU9"
  },
  {
    "uniprot": "Q4D347",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D347"
  },
  {
    "uniprot": "Q4DMT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMT4"
  },
  {
    "uniprot": "Q4DA13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA13"
  },
  {
    "uniprot": "Q4DDG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDG2"
  },
  {
    "uniprot": "Q4DER6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DER6"
  },
  {
    "uniprot": "Q4DNQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNQ6"
  },
  {
    "uniprot": "Q4DIV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIV6"
  },
  {
    "uniprot": "Q4E0K8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0K8"
  },
  {
    "uniprot": "Q4CPA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPA4"
  },
  {
    "uniprot": "Q4E2H0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2H0"
  },
  {
    "uniprot": "Q4D7S3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7S3"
  },
  {
    "uniprot": "Q4D379",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D379"
  },
  {
    "uniprot": "Q4D2S1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2S1"
  },
  {
    "uniprot": "Q4DIK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIK0"
  },
  {
    "uniprot": "Q4DZK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZK6"
  },
  {
    "uniprot": "Q4D968",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D968"
  },
  {
    "uniprot": "Q4DG25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG25"
  },
  {
    "uniprot": "Q4E0P6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0P6"
  },
  {
    "uniprot": "Q4CUS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUS8"
  },
  {
    "uniprot": "Q4DJK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJK5"
  },
  {
    "uniprot": "Q4D7J9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7J9"
  },
  {
    "uniprot": "Q4DJN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJN8"
  },
  {
    "uniprot": "Q4DQP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQP5"
  },
  {
    "uniprot": "Q4CLR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLR9"
  },
  {
    "uniprot": "Q4CPL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPL2"
  },
  {
    "uniprot": "Q4CWF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWF4"
  },
  {
    "uniprot": "Q4E5M3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5M3"
  },
  {
    "uniprot": "Q4CSV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSV7"
  },
  {
    "uniprot": "Q4CKJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKJ0"
  },
  {
    "uniprot": "Q4DAK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAK8"
  },
  {
    "uniprot": "Q4DT44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT44"
  },
  {
    "uniprot": "Q4CVC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVC9"
  },
  {
    "uniprot": "Q4E1K0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1K0"
  },
  {
    "uniprot": "Q4DFW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFW8"
  },
  {
    "uniprot": "Q4DH00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH00"
  },
  {
    "uniprot": "Q4CTY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTY7"
  },
  {
    "uniprot": "Q4DB24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB24"
  },
  {
    "uniprot": "Q4CX23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX23"
  },
  {
    "uniprot": "Q4DYD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYD1"
  },
  {
    "uniprot": "Q4D5V4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5V4"
  },
  {
    "uniprot": "Q4DG23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG23"
  },
  {
    "uniprot": "Q4DZP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZP2"
  },
  {
    "uniprot": "Q4DIZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIZ7"
  },
  {
    "uniprot": "Q4CN19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN19"
  },
  {
    "uniprot": "Q4DQU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQU4"
  },
  {
    "uniprot": "Q4E5M6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5M6"
  },
  {
    "uniprot": "Q4D5Q2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Q2"
  },
  {
    "uniprot": "Q4CNS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNS1"
  },
  {
    "uniprot": "Q4DXU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXU0"
  },
  {
    "uniprot": "Q4D153",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D153"
  },
  {
    "uniprot": "Q4DWP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWP1"
  },
  {
    "uniprot": "Q4D5J4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5J4"
  },
  {
    "uniprot": "Q4DC79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC79"
  },
  {
    "uniprot": "Q4D8G8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8G8"
  },
  {
    "uniprot": "Q4DGD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGD2"
  },
  {
    "uniprot": "Q4DVR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVR9"
  },
  {
    "uniprot": "Q4D5G9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5G9"
  },
  {
    "uniprot": "Q4DWG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWG7"
  },
  {
    "uniprot": "Q4CNG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNG4"
  },
  {
    "uniprot": "Q4D334",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D334"
  },
  {
    "uniprot": "Q4DBF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBF7"
  },
  {
    "uniprot": "Q4CNE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNE2"
  },
  {
    "uniprot": "Q4CPG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPG0"
  },
  {
    "uniprot": "Q4CUX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUX0"
  },
  {
    "uniprot": "Q4CRN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRN0"
  },
  {
    "uniprot": "Q4CKX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKX9"
  },
  {
    "uniprot": "Q4DSK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSK0"
  },
  {
    "uniprot": "Q4E5C3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5C3"
  },
  {
    "uniprot": "Q4DRT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRT5"
  },
  {
    "uniprot": "Q4CLR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLR5"
  },
  {
    "uniprot": "Q4E4F4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4F4"
  },
  {
    "uniprot": "Q4DAP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAP9"
  },
  {
    "uniprot": "Q4DDP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDP8"
  },
  {
    "uniprot": "Q4DTK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTK7"
  },
  {
    "uniprot": "Q4DQW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQW6"
  },
  {
    "uniprot": "Q4E4B7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4B7"
  },
  {
    "uniprot": "Q4DZD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZD2"
  },
  {
    "uniprot": "Q4D1B8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1B8"
  },
  {
    "uniprot": "Q4CND6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CND6"
  },
  {
    "uniprot": "Q4E5U5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5U5"
  },
  {
    "uniprot": "Q4DLR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLR0"
  },
  {
    "uniprot": "Q4DW37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW37"
  },
  {
    "uniprot": "Q4D7H0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7H0"
  },
  {
    "uniprot": "Q4CTM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTM2"
  },
  {
    "uniprot": "Q4CVU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVU6"
  },
  {
    "uniprot": "Q4CZX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZX1"
  },
  {
    "uniprot": "Q4DR37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR37"
  },
  {
    "uniprot": "Q4D4D1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4D1"
  },
  {
    "uniprot": "Q4DSL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSL4"
  },
  {
    "uniprot": "Q4D8V9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8V9"
  },
  {
    "uniprot": "Q4E0G6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0G6"
  },
  {
    "uniprot": "Q4DLV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLV6"
  },
  {
    "uniprot": "Q4DFG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFG1"
  },
  {
    "uniprot": "Q4DVN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVN1"
  },
  {
    "uniprot": "Q4DD08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD08"
  },
  {
    "uniprot": "Q4E2B2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2B2"
  },
  {
    "uniprot": "Q4DVD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVD6"
  },
  {
    "uniprot": "Q4DX58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX58"
  },
  {
    "uniprot": "Q4CRY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRY2"
  },
  {
    "uniprot": "Q4DK92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK92"
  },
  {
    "uniprot": "Q4CQL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQL7"
  },
  {
    "uniprot": "Q4DVG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVG2"
  },
  {
    "uniprot": "Q4DWR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWR5"
  },
  {
    "uniprot": "Q4CRL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRL9"
  },
  {
    "uniprot": "Q4D0P9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0P9"
  },
  {
    "uniprot": "Q4DTT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTT7"
  },
  {
    "uniprot": "Q4DLI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLI1"
  },
  {
    "uniprot": "Q4DKD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKD0"
  },
  {
    "uniprot": "Q4E3M4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3M4"
  },
  {
    "uniprot": "Q4DBM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBM4"
  },
  {
    "uniprot": "Q4E1K7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1K7"
  },
  {
    "uniprot": "Q4E2U5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2U5"
  },
  {
    "uniprot": "Q4DWC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWC7"
  },
  {
    "uniprot": "Q4DLS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLS4"
  },
  {
    "uniprot": "Q4E501",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E501"
  },
  {
    "uniprot": "Q4E312",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E312"
  },
  {
    "uniprot": "Q4CZP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZP1"
  },
  {
    "uniprot": "Q4DA67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA67"
  },
  {
    "uniprot": "Q4DDM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDM1"
  },
  {
    "uniprot": "Q4D2T5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2T5"
  },
  {
    "uniprot": "Q4DJZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJZ8"
  },
  {
    "uniprot": "Q4CWJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWJ1"
  },
  {
    "uniprot": "Q4E3F1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3F1"
  },
  {
    "uniprot": "Q4DIG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIG5"
  },
  {
    "uniprot": "Q4D7K7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7K7"
  },
  {
    "uniprot": "Q4D3Q4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Q4"
  },
  {
    "uniprot": "Q4D6X0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6X0"
  },
  {
    "uniprot": "Q4CYN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYN9"
  },
  {
    "uniprot": "Q4DMF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMF2"
  },
  {
    "uniprot": "Q4CS34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS34"
  },
  {
    "uniprot": "Q4CZ87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ87"
  },
  {
    "uniprot": "Q4DQ68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ68"
  },
  {
    "uniprot": "Q4D6S7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6S7"
  },
  {
    "uniprot": "Q4DXP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXP9"
  },
  {
    "uniprot": "Q4D622",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D622"
  },
  {
    "uniprot": "Q4DUZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUZ1"
  },
  {
    "uniprot": "Q4E148",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E148"
  },
  {
    "uniprot": "Q4CSV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSV3"
  },
  {
    "uniprot": "Q4D2F6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2F6"
  },
  {
    "uniprot": "Q4E2T8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2T8"
  },
  {
    "uniprot": "Q4DNC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNC6"
  },
  {
    "uniprot": "Q4CW63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW63"
  },
  {
    "uniprot": "Q4DWL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWL1"
  },
  {
    "uniprot": "Q4DA15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA15"
  },
  {
    "uniprot": "Q4CT86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT86"
  },
  {
    "uniprot": "Q4DX60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX60"
  },
  {
    "uniprot": "Q4D4T7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4T7"
  },
  {
    "uniprot": "Q4DGY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGY1"
  },
  {
    "uniprot": "Q4DC07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC07"
  },
  {
    "uniprot": "Q4CSN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSN0"
  },
  {
    "uniprot": "Q4DR72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR72"
  },
  {
    "uniprot": "Q4CWH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWH3"
  },
  {
    "uniprot": "Q4DE49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE49"
  },
  {
    "uniprot": "Q4CMP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMP1"
  },
  {
    "uniprot": "Q4D1N8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1N8"
  },
  {
    "uniprot": "Q4E5S0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5S0"
  },
  {
    "uniprot": "Q4DF87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF87"
  },
  {
    "uniprot": "Q4DSF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSF1"
  },
  {
    "uniprot": "Q4CPN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPN9"
  },
  {
    "uniprot": "Q4DTZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTZ6"
  },
  {
    "uniprot": "Q4D9Q1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Q1"
  },
  {
    "uniprot": "Q4DEL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEL7"
  },
  {
    "uniprot": "Q4DB13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB13"
  },
  {
    "uniprot": "Q4CQD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQD9"
  },
  {
    "uniprot": "Q4CNR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNR0"
  },
  {
    "uniprot": "Q4E4K9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4K9"
  },
  {
    "uniprot": "Q4CYS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYS7"
  },
  {
    "uniprot": "Q4DBW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBW8"
  },
  {
    "uniprot": "Q4CRI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRI7"
  },
  {
    "uniprot": "Q4CSS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSS9"
  },
  {
    "uniprot": "Q4E157",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E157"
  },
  {
    "uniprot": "Q4DST7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DST7"
  },
  {
    "uniprot": "Q4CUG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUG6"
  },
  {
    "uniprot": "Q4DU05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU05"
  },
  {
    "uniprot": "Q4D003",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D003"
  },
  {
    "uniprot": "Q4CX49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX49"
  },
  {
    "uniprot": "Q4DIY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIY5"
  },
  {
    "uniprot": "Q4D118",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D118"
  },
  {
    "uniprot": "Q4DZX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZX1"
  },
  {
    "uniprot": "Q4CY11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY11"
  },
  {
    "uniprot": "Q4D2Y5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Y5"
  },
  {
    "uniprot": "Q4DXL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXL1"
  },
  {
    "uniprot": "Q4CRG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRG7"
  },
  {
    "uniprot": "Q4D409",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D409"
  },
  {
    "uniprot": "Q4DQB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQB9"
  },
  {
    "uniprot": "Q4CVY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVY1"
  },
  {
    "uniprot": "Q4DNS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNS5"
  },
  {
    "uniprot": "Q4DDF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDF1"
  },
  {
    "uniprot": "Q4E4B5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4B5"
  },
  {
    "uniprot": "Q4DY50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY50"
  },
  {
    "uniprot": "Q4CUF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUF6"
  },
  {
    "uniprot": "Q4E385",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E385"
  },
  {
    "uniprot": "Q4DDT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDT7"
  },
  {
    "uniprot": "Q4DZ18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ18"
  },
  {
    "uniprot": "Q4E570",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E570"
  },
  {
    "uniprot": "Q4D0W2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0W2"
  },
  {
    "uniprot": "Q4DMN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMN8"
  },
  {
    "uniprot": "Q4D2P4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2P4"
  },
  {
    "uniprot": "Q4CWH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWH5"
  },
  {
    "uniprot": "Q4E622",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E622"
  },
  {
    "uniprot": "Q4D1M3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1M3"
  },
  {
    "uniprot": "Q4DRV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRV7"
  },
  {
    "uniprot": "Q4D0A4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0A4"
  },
  {
    "uniprot": "Q4DET9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DET9"
  },
  {
    "uniprot": "Q4CVT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVT0"
  },
  {
    "uniprot": "Q4E5S5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5S5"
  },
  {
    "uniprot": "Q4CMS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMS5"
  },
  {
    "uniprot": "Q4DJP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJP3"
  },
  {
    "uniprot": "Q4D7K0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7K0"
  },
  {
    "uniprot": "Q4CTC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTC1"
  },
  {
    "uniprot": "Q4D7S5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7S5"
  },
  {
    "uniprot": "Q4D170",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D170"
  },
  {
    "uniprot": "Q4DRG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRG8"
  },
  {
    "uniprot": "Q4E336",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E336"
  },
  {
    "uniprot": "Q4E3K9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3K9"
  },
  {
    "uniprot": "Q4CYK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYK1"
  },
  {
    "uniprot": "Q4DVH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVH6"
  },
  {
    "uniprot": "Q4CQ05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ05"
  },
  {
    "uniprot": "Q4DPS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPS3"
  },
  {
    "uniprot": "Q4CWX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWX5"
  },
  {
    "uniprot": "Q4CZB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZB4"
  },
  {
    "uniprot": "Q4DS90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS90"
  },
  {
    "uniprot": "Q4DHU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHU3"
  },
  {
    "uniprot": "Q4CUK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUK8"
  },
  {
    "uniprot": "Q4DLN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLN1"
  },
  {
    "uniprot": "Q4DD01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD01"
  },
  {
    "uniprot": "Q4DNE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNE4"
  },
  {
    "uniprot": "Q4D412",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D412"
  },
  {
    "uniprot": "Q4DQN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQN9"
  },
  {
    "uniprot": "Q4E530",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E530"
  },
  {
    "uniprot": "Q4D2D9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2D9"
  },
  {
    "uniprot": "Q4D0Q1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Q1"
  },
  {
    "uniprot": "Q4DXW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXW6"
  },
  {
    "uniprot": "Q4DGU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGU2"
  },
  {
    "uniprot": "Q4CWJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWJ6"
  },
  {
    "uniprot": "Q4CQF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQF9"
  },
  {
    "uniprot": "Q4E0D4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0D4"
  },
  {
    "uniprot": "Q4DEF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEF5"
  },
  {
    "uniprot": "Q4CWZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWZ1"
  },
  {
    "uniprot": "Q4D9S8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9S8"
  },
  {
    "uniprot": "Q4DPX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPX1"
  },
  {
    "uniprot": "Q4DAY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAY7"
  },
  {
    "uniprot": "Q4E2D2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2D2"
  },
  {
    "uniprot": "Q4CWL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWL7"
  },
  {
    "uniprot": "Q4E4I2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4I2"
  },
  {
    "uniprot": "Q4DSB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSB1"
  },
  {
    "uniprot": "Q4D8Q4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Q4"
  },
  {
    "uniprot": "Q4CQJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQJ3"
  },
  {
    "uniprot": "Q4CVK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVK7"
  },
  {
    "uniprot": "Q4DV43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV43"
  },
  {
    "uniprot": "Q4DVM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVM3"
  },
  {
    "uniprot": "Q4DHV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHV0"
  },
  {
    "uniprot": "Q4DXA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXA0"
  },
  {
    "uniprot": "Q4E3S7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3S7"
  },
  {
    "uniprot": "Q4DY12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY12"
  },
  {
    "uniprot": "Q4DLR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLR5"
  },
  {
    "uniprot": "Q4DUV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUV4"
  },
  {
    "uniprot": "Q4CRI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRI0"
  },
  {
    "uniprot": "Q4D8S5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8S5"
  },
  {
    "uniprot": "Q4DZV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZV0"
  },
  {
    "uniprot": "Q4CUP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUP9"
  },
  {
    "uniprot": "Q4DL80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL80"
  },
  {
    "uniprot": "Q4DMR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMR3"
  },
  {
    "uniprot": "Q4DNE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNE2"
  },
  {
    "uniprot": "Q4DQ41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ41"
  },
  {
    "uniprot": "Q4D1B5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1B5"
  },
  {
    "uniprot": "Q4D2H4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2H4"
  },
  {
    "uniprot": "Q4CLI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLI5"
  },
  {
    "uniprot": "Q4DI16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI16"
  },
  {
    "uniprot": "Q4E5X2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5X2"
  },
  {
    "uniprot": "Q4CLB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLB5"
  },
  {
    "uniprot": "Q4DX57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX57"
  },
  {
    "uniprot": "Q4D1J3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1J3"
  },
  {
    "uniprot": "Q4CNT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNT7"
  },
  {
    "uniprot": "Q4DD67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD67"
  },
  {
    "uniprot": "Q4CUM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUM3"
  },
  {
    "uniprot": "Q4DCD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCD9"
  },
  {
    "uniprot": "Q4D8N2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8N2"
  },
  {
    "uniprot": "Q4CQ32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ32"
  },
  {
    "uniprot": "Q4D7E1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7E1"
  },
  {
    "uniprot": "Q4DDF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDF9"
  },
  {
    "uniprot": "Q4DX63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX63"
  },
  {
    "uniprot": "Q4D031",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D031"
  },
  {
    "uniprot": "Q4E3U0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3U0"
  },
  {
    "uniprot": "Q4E182",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E182"
  },
  {
    "uniprot": "Q4CUT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUT2"
  },
  {
    "uniprot": "Q4D8L4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8L4"
  },
  {
    "uniprot": "Q4E3B7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3B7"
  },
  {
    "uniprot": "Q4E2Y8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Y8"
  },
  {
    "uniprot": "Q4D3E2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3E2"
  },
  {
    "uniprot": "Q4DFW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFW0"
  },
  {
    "uniprot": "Q4DQ06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ06"
  },
  {
    "uniprot": "Q4D720",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D720"
  },
  {
    "uniprot": "Q4DWT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWT1"
  },
  {
    "uniprot": "Q4E0W2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0W2"
  },
  {
    "uniprot": "Q4CYX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYX9"
  },
  {
    "uniprot": "Q4DVJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVJ5"
  },
  {
    "uniprot": "Q4DIB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIB1"
  },
  {
    "uniprot": "Q4CT30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT30"
  },
  {
    "uniprot": "Q4DYS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYS5"
  },
  {
    "uniprot": "Q4DJY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJY3"
  },
  {
    "uniprot": "Q4DZ98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ98"
  },
  {
    "uniprot": "Q4E3U5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3U5"
  },
  {
    "uniprot": "Q4E2I3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2I3"
  },
  {
    "uniprot": "Q4DY39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY39"
  },
  {
    "uniprot": "Q4CXU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXU0"
  },
  {
    "uniprot": "Q4CYV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYV0"
  },
  {
    "uniprot": "Q4DEA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEA2"
  },
  {
    "uniprot": "Q4CMJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMJ0"
  },
  {
    "uniprot": "Q4DA54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA54"
  },
  {
    "uniprot": "Q4CUP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUP3"
  },
  {
    "uniprot": "Q4DB95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB95"
  },
  {
    "uniprot": "Q4CKQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKQ2"
  },
  {
    "uniprot": "Q4D502",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D502"
  },
  {
    "uniprot": "Q4DTE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTE7"
  },
  {
    "uniprot": "Q4DD75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD75"
  },
  {
    "uniprot": "Q4CT22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT22"
  },
  {
    "uniprot": "Q4DB08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB08"
  },
  {
    "uniprot": "Q4DVE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVE2"
  },
  {
    "uniprot": "Q4E546",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E546"
  },
  {
    "uniprot": "Q4DM68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM68"
  },
  {
    "uniprot": "Q4CSB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSB9"
  },
  {
    "uniprot": "Q4DP79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP79"
  },
  {
    "uniprot": "Q4CZE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZE9"
  },
  {
    "uniprot": "Q4DKY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKY3"
  },
  {
    "uniprot": "Q4DMP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMP1"
  },
  {
    "uniprot": "Q4DJL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJL8"
  },
  {
    "uniprot": "Q4D1K4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1K4"
  },
  {
    "uniprot": "Q4CVX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVX5"
  },
  {
    "uniprot": "Q4D2C1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2C1"
  },
  {
    "uniprot": "Q4CXP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXP1"
  },
  {
    "uniprot": "Q4D7Y7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Y7"
  },
  {
    "uniprot": "Q4E1P7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1P7"
  },
  {
    "uniprot": "Q4DAI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAI2"
  },
  {
    "uniprot": "Q4DX03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX03"
  },
  {
    "uniprot": "Q4DHZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHZ1"
  },
  {
    "uniprot": "Q4CYP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYP7"
  },
  {
    "uniprot": "Q4DU63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU63"
  },
  {
    "uniprot": "Q4D6C4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6C4"
  },
  {
    "uniprot": "Q4DIP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIP0"
  },
  {
    "uniprot": "Q4DBY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBY2"
  },
  {
    "uniprot": "Q4DET8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DET8"
  },
  {
    "uniprot": "Q4DH36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH36"
  },
  {
    "uniprot": "Q4D934",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D934"
  },
  {
    "uniprot": "Q4DIU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIU8"
  },
  {
    "uniprot": "Q4CMM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMM0"
  },
  {
    "uniprot": "Q4CSI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSI2"
  },
  {
    "uniprot": "Q4CWU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWU6"
  },
  {
    "uniprot": "Q4CM64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM64"
  },
  {
    "uniprot": "Q4CSV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSV8"
  },
  {
    "uniprot": "Q4CRP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRP8"
  },
  {
    "uniprot": "Q4DRI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRI9"
  },
  {
    "uniprot": "Q4DII9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DII9"
  },
  {
    "uniprot": "Q4DPW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPW9"
  },
  {
    "uniprot": "Q4D0W4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0W4"
  },
  {
    "uniprot": "Q4DB54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB54"
  },
  {
    "uniprot": "Q4D5B8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5B8"
  },
  {
    "uniprot": "Q4DN65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN65"
  },
  {
    "uniprot": "Q4E0G9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0G9"
  },
  {
    "uniprot": "Q4DHV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHV7"
  },
  {
    "uniprot": "Q4D7X5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7X5"
  },
  {
    "uniprot": "Q4CQG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQG8"
  },
  {
    "uniprot": "Q4DCT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCT7"
  },
  {
    "uniprot": "Q4DSK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSK3"
  },
  {
    "uniprot": "Q4D066",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D066"
  },
  {
    "uniprot": "Q4CZU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZU2"
  },
  {
    "uniprot": "Q4CMM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMM5"
  },
  {
    "uniprot": "Q4E5N7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5N7"
  },
  {
    "uniprot": "Q4DQ24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ24"
  },
  {
    "uniprot": "Q4E0N2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0N2"
  },
  {
    "uniprot": "Q4DK49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK49"
  },
  {
    "uniprot": "Q4DRP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRP8"
  },
  {
    "uniprot": "Q4E5N8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5N8"
  },
  {
    "uniprot": "Q4E5G9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5G9"
  },
  {
    "uniprot": "Q4DYI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYI5"
  },
  {
    "uniprot": "Q4DY27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY27"
  },
  {
    "uniprot": "Q4DN19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN19"
  },
  {
    "uniprot": "Q4D7U4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7U4"
  },
  {
    "uniprot": "Q4D133",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D133"
  },
  {
    "uniprot": "Q4E2W7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2W7"
  },
  {
    "uniprot": "Q4CWA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWA0"
  },
  {
    "uniprot": "Q4DXG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXG5"
  },
  {
    "uniprot": "Q4DCI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCI4"
  },
  {
    "uniprot": "Q4CTM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTM9"
  },
  {
    "uniprot": "Q4CPA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPA0"
  },
  {
    "uniprot": "Q4CUI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUI1"
  },
  {
    "uniprot": "Q4DD00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD00"
  },
  {
    "uniprot": "Q4DQ67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ67"
  },
  {
    "uniprot": "Q4DRX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRX1"
  },
  {
    "uniprot": "Q4D7Y4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Y4"
  },
  {
    "uniprot": "Q4D208",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D208"
  },
  {
    "uniprot": "Q4CR85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR85"
  },
  {
    "uniprot": "Q4DBI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBI0"
  },
  {
    "uniprot": "Q4DLZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLZ6"
  },
  {
    "uniprot": "Q4DEW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEW5"
  },
  {
    "uniprot": "Q4D927",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D927"
  },
  {
    "uniprot": "Q4CPB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPB9"
  },
  {
    "uniprot": "Q4D5V6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5V6"
  },
  {
    "uniprot": "Q4D474",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D474"
  },
  {
    "uniprot": "Q4CRZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRZ6"
  },
  {
    "uniprot": "Q4DXC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXC8"
  },
  {
    "uniprot": "Q4CSM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSM5"
  },
  {
    "uniprot": "Q4DJV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJV2"
  },
  {
    "uniprot": "Q4DZV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZV5"
  },
  {
    "uniprot": "Q4E1U6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1U6"
  },
  {
    "uniprot": "Q4DQA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQA1"
  },
  {
    "uniprot": "Q4D8Q9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Q9"
  },
  {
    "uniprot": "Q4DNY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNY3"
  },
  {
    "uniprot": "Q4D079",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D079"
  },
  {
    "uniprot": "Q4D2M7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2M7"
  },
  {
    "uniprot": "Q4CNR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNR1"
  },
  {
    "uniprot": "Q4DLM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLM4"
  },
  {
    "uniprot": "Q4E5U8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5U8"
  },
  {
    "uniprot": "Q4D223",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D223"
  },
  {
    "uniprot": "Q4DJH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJH0"
  },
  {
    "uniprot": "Q4D2Y9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Y9"
  },
  {
    "uniprot": "Q4DSN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSN3"
  },
  {
    "uniprot": "Q4CY47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY47"
  },
  {
    "uniprot": "Q4DQP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQP3"
  },
  {
    "uniprot": "Q4CVQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVQ3"
  },
  {
    "uniprot": "Q4DHE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHE0"
  },
  {
    "uniprot": "Q4DVX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVX2"
  },
  {
    "uniprot": "Q4DFM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFM5"
  },
  {
    "uniprot": "Q4E666",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E666"
  },
  {
    "uniprot": "Q4D9J1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9J1"
  },
  {
    "uniprot": "Q4DF83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF83"
  },
  {
    "uniprot": "Q4CNM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNM6"
  },
  {
    "uniprot": "Q4CYW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYW3"
  },
  {
    "uniprot": "Q4DH13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH13"
  },
  {
    "uniprot": "Q4CZD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZD9"
  },
  {
    "uniprot": "Q4DPK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPK9"
  },
  {
    "uniprot": "Q4DQJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQJ6"
  },
  {
    "uniprot": "Q4DMB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMB9"
  },
  {
    "uniprot": "Q4D7W9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7W9"
  },
  {
    "uniprot": "Q4DLP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLP2"
  },
  {
    "uniprot": "Q4E176",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E176"
  },
  {
    "uniprot": "Q4D6B7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6B7"
  },
  {
    "uniprot": "Q4DBR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBR4"
  },
  {
    "uniprot": "Q4CPR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPR2"
  },
  {
    "uniprot": "Q4DSA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSA8"
  },
  {
    "uniprot": "Q4D8F6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8F6"
  },
  {
    "uniprot": "Q4CPH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPH1"
  },
  {
    "uniprot": "Q4DAA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAA6"
  },
  {
    "uniprot": "Q4CY78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY78"
  },
  {
    "uniprot": "Q4CVB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVB5"
  },
  {
    "uniprot": "Q4D5M6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5M6"
  },
  {
    "uniprot": "Q4D554",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D554"
  },
  {
    "uniprot": "Q4DIM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIM5"
  },
  {
    "uniprot": "Q4CWQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWQ5"
  },
  {
    "uniprot": "Q4DG40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG40"
  },
  {
    "uniprot": "Q4DQ79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ79"
  },
  {
    "uniprot": "Q4E5F1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5F1"
  },
  {
    "uniprot": "Q4E2Q8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Q8"
  },
  {
    "uniprot": "Q4DIX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIX4"
  },
  {
    "uniprot": "Q4CYA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYA9"
  },
  {
    "uniprot": "Q4D1K1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1K1"
  },
  {
    "uniprot": "Q4DF98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF98"
  },
  {
    "uniprot": "Q4DIS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIS5"
  },
  {
    "uniprot": "Q4DJJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJJ9"
  },
  {
    "uniprot": "Q4DFZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFZ0"
  },
  {
    "uniprot": "Q4D5S6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5S6"
  },
  {
    "uniprot": "Q4E424",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E424"
  },
  {
    "uniprot": "Q4DZL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZL4"
  },
  {
    "uniprot": "Q4CU00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU00"
  },
  {
    "uniprot": "Q4DIC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIC9"
  },
  {
    "uniprot": "Q4CM69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM69"
  },
  {
    "uniprot": "Q4E1U3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1U3"
  },
  {
    "uniprot": "Q4D5W1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5W1"
  },
  {
    "uniprot": "Q4CS61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS61"
  },
  {
    "uniprot": "Q4CW79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW79"
  },
  {
    "uniprot": "Q4D635",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D635"
  },
  {
    "uniprot": "Q4CVM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVM4"
  },
  {
    "uniprot": "Q4CMT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMT3"
  },
  {
    "uniprot": "Q4DYZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYZ9"
  },
  {
    "uniprot": "Q4DHW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHW7"
  },
  {
    "uniprot": "Q4DKR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKR9"
  },
  {
    "uniprot": "Q4DEV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEV7"
  },
  {
    "uniprot": "Q4DYW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYW6"
  },
  {
    "uniprot": "Q4DNZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNZ9"
  },
  {
    "uniprot": "Q4D1M2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1M2"
  },
  {
    "uniprot": "Q4CQL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQL4"
  },
  {
    "uniprot": "Q4D0W9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0W9"
  },
  {
    "uniprot": "Q4CZY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZY2"
  },
  {
    "uniprot": "Q4DSF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSF3"
  },
  {
    "uniprot": "Q4CV84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV84"
  },
  {
    "uniprot": "Q4DT91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT91"
  },
  {
    "uniprot": "Q4D2M2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2M2"
  },
  {
    "uniprot": "Q4CUC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUC9"
  },
  {
    "uniprot": "Q4DS70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS70"
  },
  {
    "uniprot": "Q4CTM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTM6"
  },
  {
    "uniprot": "Q4CSL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSL7"
  },
  {
    "uniprot": "Q4CXK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXK4"
  },
  {
    "uniprot": "Q4DNX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNX6"
  },
  {
    "uniprot": "Q4DDJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDJ8"
  },
  {
    "uniprot": "Q4CSF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSF7"
  },
  {
    "uniprot": "Q4CQX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQX8"
  },
  {
    "uniprot": "Q4CTQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTQ3"
  },
  {
    "uniprot": "Q4DMX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMX4"
  },
  {
    "uniprot": "Q4DHT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHT9"
  },
  {
    "uniprot": "Q4CQX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQX5"
  },
  {
    "uniprot": "Q4E0P9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0P9"
  },
  {
    "uniprot": "Q4DPM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPM9"
  },
  {
    "uniprot": "Q4DD12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD12"
  },
  {
    "uniprot": "Q4E5E5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5E5"
  },
  {
    "uniprot": "Q4DGB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGB6"
  },
  {
    "uniprot": "Q4D8U6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8U6"
  },
  {
    "uniprot": "Q4CTD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTD9"
  },
  {
    "uniprot": "Q4DXG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXG3"
  },
  {
    "uniprot": "Q4CSY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSY1"
  },
  {
    "uniprot": "Q4DHY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHY2"
  },
  {
    "uniprot": "Q4DWC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWC0"
  },
  {
    "uniprot": "Q4CXM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXM1"
  },
  {
    "uniprot": "Q4DLT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLT9"
  },
  {
    "uniprot": "Q4CTR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTR5"
  },
  {
    "uniprot": "Q4DUC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUC3"
  },
  {
    "uniprot": "Q4CRQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRQ7"
  },
  {
    "uniprot": "Q4CRJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRJ2"
  },
  {
    "uniprot": "Q4CXE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXE1"
  },
  {
    "uniprot": "Q4DHK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHK8"
  },
  {
    "uniprot": "Q4DH74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH74"
  },
  {
    "uniprot": "Q4D5N3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5N3"
  },
  {
    "uniprot": "Q4DWP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWP3"
  },
  {
    "uniprot": "Q4DCH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCH1"
  },
  {
    "uniprot": "Q4CV77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV77"
  },
  {
    "uniprot": "Q4DB16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB16"
  },
  {
    "uniprot": "Q4CWE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWE7"
  },
  {
    "uniprot": "Q4DPA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPA1"
  },
  {
    "uniprot": "Q4D9N0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9N0"
  },
  {
    "uniprot": "Q4DQ09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ09"
  },
  {
    "uniprot": "Q4DSD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSD8"
  },
  {
    "uniprot": "Q4E470",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E470"
  },
  {
    "uniprot": "Q4D1H4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1H4"
  },
  {
    "uniprot": "Q4CR58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR58"
  },
  {
    "uniprot": "Q4D646",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D646"
  },
  {
    "uniprot": "Q4CN96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN96"
  },
  {
    "uniprot": "Q4CNS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNS6"
  },
  {
    "uniprot": "Q4D6W0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6W0"
  },
  {
    "uniprot": "Q4DVQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVQ9"
  },
  {
    "uniprot": "Q4E444",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E444"
  },
  {
    "uniprot": "Q4CYU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYU6"
  },
  {
    "uniprot": "Q4CR36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR36"
  },
  {
    "uniprot": "Q4CZQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZQ2"
  },
  {
    "uniprot": "Q4DA76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA76"
  },
  {
    "uniprot": "Q4DZ14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ14"
  },
  {
    "uniprot": "Q4DB48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB48"
  },
  {
    "uniprot": "Q4DE57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE57"
  },
  {
    "uniprot": "Q4D8P8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8P8"
  },
  {
    "uniprot": "Q4DFI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFI8"
  },
  {
    "uniprot": "Q4DP13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP13"
  },
  {
    "uniprot": "Q4CSE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSE5"
  },
  {
    "uniprot": "Q4CQS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQS6"
  },
  {
    "uniprot": "Q4D124",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D124"
  },
  {
    "uniprot": "Q4DYR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYR2"
  },
  {
    "uniprot": "Q4DC18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC18"
  },
  {
    "uniprot": "Q4CXD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXD9"
  },
  {
    "uniprot": "Q4D8J9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8J9"
  },
  {
    "uniprot": "Q4DHQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHQ2"
  },
  {
    "uniprot": "Q4E5A9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5A9"
  },
  {
    "uniprot": "Q4DYG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYG0"
  },
  {
    "uniprot": "Q4CTJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTJ5"
  },
  {
    "uniprot": "Q4DQV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQV0"
  },
  {
    "uniprot": "Q4DDG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDG3"
  },
  {
    "uniprot": "Q4DK46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK46"
  },
  {
    "uniprot": "Q4CZ24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ24"
  },
  {
    "uniprot": "Q4DQ21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ21"
  },
  {
    "uniprot": "Q4DXI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXI3"
  },
  {
    "uniprot": "Q4D459",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D459"
  },
  {
    "uniprot": "Q4DS94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS94"
  },
  {
    "uniprot": "Q4DGN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGN5"
  },
  {
    "uniprot": "Q4D947",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D947"
  },
  {
    "uniprot": "Q4DEN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEN6"
  },
  {
    "uniprot": "Q4E1V0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1V0"
  },
  {
    "uniprot": "Q4E068",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E068"
  },
  {
    "uniprot": "Q4E2H2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2H2"
  },
  {
    "uniprot": "Q4D2C3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2C3"
  },
  {
    "uniprot": "Q4DUV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUV5"
  },
  {
    "uniprot": "Q4DH43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH43"
  },
  {
    "uniprot": "Q4DG64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG64"
  },
  {
    "uniprot": "Q4DQF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQF1"
  },
  {
    "uniprot": "Q4DXL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXL9"
  },
  {
    "uniprot": "Q4DGE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGE4"
  },
  {
    "uniprot": "Q4CWM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWM7"
  },
  {
    "uniprot": "Q4DYG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYG2"
  },
  {
    "uniprot": "Q4CS25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS25"
  },
  {
    "uniprot": "Q4DMH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMH0"
  },
  {
    "uniprot": "Q4DT78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT78"
  },
  {
    "uniprot": "Q4DXI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXI8"
  },
  {
    "uniprot": "Q4E1W2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1W2"
  },
  {
    "uniprot": "Q4CNF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNF3"
  },
  {
    "uniprot": "Q4DYH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYH7"
  },
  {
    "uniprot": "Q4E4M0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4M0"
  },
  {
    "uniprot": "Q4DVM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVM8"
  },
  {
    "uniprot": "Q4DA24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA24"
  },
  {
    "uniprot": "Q4D2K9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2K9"
  },
  {
    "uniprot": "Q4CTZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTZ3"
  },
  {
    "uniprot": "Q4D245",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D245"
  },
  {
    "uniprot": "Q4DBK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBK5"
  },
  {
    "uniprot": "Q4CR04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR04"
  },
  {
    "uniprot": "Q4DPG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPG2"
  },
  {
    "uniprot": "Q4E0B5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0B5"
  },
  {
    "uniprot": "Q4E1J9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1J9"
  },
  {
    "uniprot": "Q4E1C7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1C7"
  },
  {
    "uniprot": "Q4DWL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWL8"
  },
  {
    "uniprot": "Q4CPV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPV8"
  },
  {
    "uniprot": "Q4DHV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHV1"
  },
  {
    "uniprot": "Q4CUN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUN9"
  },
  {
    "uniprot": "Q4DM72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM72"
  },
  {
    "uniprot": "Q4DQ02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ02"
  },
  {
    "uniprot": "Q4E0R4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0R4"
  },
  {
    "uniprot": "Q4E410",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E410"
  },
  {
    "uniprot": "Q4DTG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTG8"
  },
  {
    "uniprot": "Q4CZA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZA2"
  },
  {
    "uniprot": "Q4CZI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZI8"
  },
  {
    "uniprot": "Q4D050",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D050"
  },
  {
    "uniprot": "Q4E679",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E679"
  },
  {
    "uniprot": "Q4E1A5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1A5"
  },
  {
    "uniprot": "Q4CUW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUW8"
  },
  {
    "uniprot": "Q4CZ94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ94"
  },
  {
    "uniprot": "Q4CWR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWR8"
  },
  {
    "uniprot": "Q4DFW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFW7"
  },
  {
    "uniprot": "Q4E3W3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3W3"
  },
  {
    "uniprot": "Q4DKK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKK7"
  },
  {
    "uniprot": "Q4CTP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTP2"
  },
  {
    "uniprot": "Q4DQU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQU2"
  },
  {
    "uniprot": "Q4CN87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN87"
  },
  {
    "uniprot": "Q4DSS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSS7"
  },
  {
    "uniprot": "Q4DGZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGZ5"
  },
  {
    "uniprot": "Q4DFH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFH2"
  },
  {
    "uniprot": "Q4DND4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DND4"
  },
  {
    "uniprot": "Q4DCT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCT0"
  },
  {
    "uniprot": "Q4DBN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBN6"
  },
  {
    "uniprot": "Q4DNK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNK7"
  },
  {
    "uniprot": "Q4CR18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR18"
  },
  {
    "uniprot": "Q4E248",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E248"
  },
  {
    "uniprot": "Q4CLG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLG9"
  },
  {
    "uniprot": "Q4E5B3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5B3"
  },
  {
    "uniprot": "Q4CVJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVJ8"
  },
  {
    "uniprot": "Q4CQH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQH8"
  },
  {
    "uniprot": "Q4DYL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYL6"
  },
  {
    "uniprot": "Q4D614",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D614"
  },
  {
    "uniprot": "Q4DAA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAA4"
  },
  {
    "uniprot": "Q4D8C0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8C0"
  },
  {
    "uniprot": "Q4DFY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFY7"
  },
  {
    "uniprot": "Q4CWD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWD9"
  },
  {
    "uniprot": "Q4E5Q5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Q5"
  },
  {
    "uniprot": "Q4D111",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D111"
  },
  {
    "uniprot": "Q4CSG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSG1"
  },
  {
    "uniprot": "Q4DE04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE04"
  },
  {
    "uniprot": "Q4D216",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D216"
  },
  {
    "uniprot": "Q4CZN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZN6"
  },
  {
    "uniprot": "Q4D3Q7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Q7"
  },
  {
    "uniprot": "Q4DN71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN71"
  },
  {
    "uniprot": "Q4DUK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUK5"
  },
  {
    "uniprot": "Q4E5I5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5I5"
  },
  {
    "uniprot": "Q4CU75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU75"
  },
  {
    "uniprot": "Q4DAL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAL0"
  },
  {
    "uniprot": "Q4E525",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E525"
  },
  {
    "uniprot": "Q4E597",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E597"
  },
  {
    "uniprot": "Q4DFN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFN6"
  },
  {
    "uniprot": "Q4DX08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX08"
  },
  {
    "uniprot": "Q4DIG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIG7"
  },
  {
    "uniprot": "Q4E3I4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3I4"
  },
  {
    "uniprot": "Q4D4C7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4C7"
  },
  {
    "uniprot": "Q4DGP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGP4"
  },
  {
    "uniprot": "Q4DNM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNM5"
  },
  {
    "uniprot": "Q4E5Z3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Z3"
  },
  {
    "uniprot": "Q4D6H7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6H7"
  },
  {
    "uniprot": "Q4D282",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D282"
  },
  {
    "uniprot": "Q4DK73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK73"
  },
  {
    "uniprot": "Q4D5G0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5G0"
  },
  {
    "uniprot": "Q4DW02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW02"
  },
  {
    "uniprot": "Q4D4L3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4L3"
  },
  {
    "uniprot": "Q4CVL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVL8"
  },
  {
    "uniprot": "Q4E2X3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2X3"
  },
  {
    "uniprot": "Q4E1Q6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Q6"
  },
  {
    "uniprot": "Q4DXN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXN2"
  },
  {
    "uniprot": "Q4D4I1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4I1"
  },
  {
    "uniprot": "Q4D954",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D954"
  },
  {
    "uniprot": "Q4D8D7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8D7"
  },
  {
    "uniprot": "Q4E421",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E421"
  },
  {
    "uniprot": "Q4DUK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUK2"
  },
  {
    "uniprot": "Q4DM95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM95"
  },
  {
    "uniprot": "Q4D9M0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9M0"
  },
  {
    "uniprot": "Q4E1W9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1W9"
  },
  {
    "uniprot": "Q4DIM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIM1"
  },
  {
    "uniprot": "Q4D4T2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4T2"
  },
  {
    "uniprot": "Q4DDC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDC0"
  },
  {
    "uniprot": "Q4DHH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHH6"
  },
  {
    "uniprot": "Q4CS93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS93"
  },
  {
    "uniprot": "Q4D590",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D590"
  },
  {
    "uniprot": "Q4DS95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS95"
  },
  {
    "uniprot": "Q4DGP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGP6"
  },
  {
    "uniprot": "Q4DXP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXP1"
  },
  {
    "uniprot": "Q4DF30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF30"
  },
  {
    "uniprot": "Q4CRI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRI1"
  },
  {
    "uniprot": "Q4CQK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQK5"
  },
  {
    "uniprot": "Q4DQQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQQ8"
  },
  {
    "uniprot": "Q4DLF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLF8"
  },
  {
    "uniprot": "Q4D2Y0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Y0"
  },
  {
    "uniprot": "Q4CV00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV00"
  },
  {
    "uniprot": "Q4DSW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSW7"
  },
  {
    "uniprot": "Q4DM70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM70"
  },
  {
    "uniprot": "Q4D7A3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7A3"
  },
  {
    "uniprot": "Q4DB75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB75"
  },
  {
    "uniprot": "Q4CYB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYB3"
  },
  {
    "uniprot": "Q4DAJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAJ0"
  },
  {
    "uniprot": "Q4DR82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR82"
  },
  {
    "uniprot": "Q4D7L9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7L9"
  },
  {
    "uniprot": "Q4CWX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWX1"
  },
  {
    "uniprot": "Q4DJ38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ38"
  },
  {
    "uniprot": "Q4D0V7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0V7"
  },
  {
    "uniprot": "Q4DXS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXS2"
  },
  {
    "uniprot": "Q4CQC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQC6"
  },
  {
    "uniprot": "Q4CN35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN35"
  },
  {
    "uniprot": "Q4DI21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI21"
  },
  {
    "uniprot": "Q4DJZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJZ3"
  },
  {
    "uniprot": "Q4DAC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAC7"
  },
  {
    "uniprot": "Q4DM28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM28"
  },
  {
    "uniprot": "Q4CXF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXF9"
  },
  {
    "uniprot": "Q4D1F9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1F9"
  },
  {
    "uniprot": "Q4DEU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEU8"
  },
  {
    "uniprot": "Q4CPS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPS5"
  },
  {
    "uniprot": "Q4E0U8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0U8"
  },
  {
    "uniprot": "Q4DUW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUW1"
  },
  {
    "uniprot": "Q4D7T9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7T9"
  },
  {
    "uniprot": "Q4DN78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN78"
  },
  {
    "uniprot": "Q4DYN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYN6"
  },
  {
    "uniprot": "Q4DZ75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ75"
  },
  {
    "uniprot": "Q4D4Q8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Q8"
  },
  {
    "uniprot": "Q4CQS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQS3"
  },
  {
    "uniprot": "Q4CNE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNE4"
  },
  {
    "uniprot": "Q4DQF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQF5"
  },
  {
    "uniprot": "Q4DSQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSQ2"
  },
  {
    "uniprot": "Q4DD88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD88"
  },
  {
    "uniprot": "Q4CUD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUD2"
  },
  {
    "uniprot": "Q4DP42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP42"
  },
  {
    "uniprot": "Q4DVT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVT7"
  },
  {
    "uniprot": "Q4DG56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG56"
  },
  {
    "uniprot": "Q4CZA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZA3"
  },
  {
    "uniprot": "Q4D0T3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0T3"
  },
  {
    "uniprot": "Q4DZL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZL0"
  },
  {
    "uniprot": "G3XCP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/G3XCP0"
  },
  {
    "uniprot": "Q4D6H0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6H0"
  },
  {
    "uniprot": "Q4E387",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E387"
  },
  {
    "uniprot": "Q4D3P0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3P0"
  },
  {
    "uniprot": "Q4CKI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKI3"
  },
  {
    "uniprot": "Q4D2B9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2B9"
  },
  {
    "uniprot": "Q4CL14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL14"
  },
  {
    "uniprot": "Q4DJ28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ28"
  },
  {
    "uniprot": "Q4DQM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQM8"
  },
  {
    "uniprot": "Q4DR74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR74"
  },
  {
    "uniprot": "Q4E106",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E106"
  },
  {
    "uniprot": "Q4CM50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM50"
  },
  {
    "uniprot": "Q4D470",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D470"
  },
  {
    "uniprot": "Q4DVD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVD8"
  },
  {
    "uniprot": "Q4DMB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMB7"
  },
  {
    "uniprot": "Q4E2Z2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Z2"
  },
  {
    "uniprot": "Q4CL57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL57"
  },
  {
    "uniprot": "Q4DZ77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ77"
  },
  {
    "uniprot": "Q4D8B7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8B7"
  },
  {
    "uniprot": "Q4E149",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E149"
  },
  {
    "uniprot": "Q4CRU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRU6"
  },
  {
    "uniprot": "Q4DCZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCZ4"
  },
  {
    "uniprot": "Q4DVS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVS8"
  },
  {
    "uniprot": "Q4DM32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM32"
  },
  {
    "uniprot": "Q4DKR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKR5"
  },
  {
    "uniprot": "Q4D3L1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3L1"
  },
  {
    "uniprot": "Q4DUW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUW6"
  },
  {
    "uniprot": "Q4CVD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVD7"
  },
  {
    "uniprot": "Q4CQ44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ44"
  },
  {
    "uniprot": "Q4DFU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFU7"
  },
  {
    "uniprot": "Q4D067",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D067"
  },
  {
    "uniprot": "Q4CZH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZH3"
  },
  {
    "uniprot": "Q4DTG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTG7"
  },
  {
    "uniprot": "Q4DEA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEA9"
  },
  {
    "uniprot": "Q4DHA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHA4"
  },
  {
    "uniprot": "Q4D4X5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4X5"
  },
  {
    "uniprot": "Q4D9C9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9C9"
  },
  {
    "uniprot": "Q4D055",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D055"
  },
  {
    "uniprot": "Q4DG65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG65"
  },
  {
    "uniprot": "Q4DU13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU13"
  },
  {
    "uniprot": "Q4DA38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA38"
  },
  {
    "uniprot": "Q4E5Y8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Y8"
  },
  {
    "uniprot": "Q4DL15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL15"
  },
  {
    "uniprot": "Q4E271",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E271"
  },
  {
    "uniprot": "Q4DQL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQL4"
  },
  {
    "uniprot": "Q4DAY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAY0"
  },
  {
    "uniprot": "Q4D908",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D908"
  },
  {
    "uniprot": "Q4DY77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY77"
  },
  {
    "uniprot": "Q4E602",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E602"
  },
  {
    "uniprot": "Q4E684",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E684"
  },
  {
    "uniprot": "Q4DCU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCU9"
  },
  {
    "uniprot": "Q4E0P1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0P1"
  },
  {
    "uniprot": "Q4CSZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSZ2"
  },
  {
    "uniprot": "Q4DX95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX95"
  },
  {
    "uniprot": "Q4D3P6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3P6"
  },
  {
    "uniprot": "Q4DUA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUA1"
  },
  {
    "uniprot": "Q4CR56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR56"
  },
  {
    "uniprot": "Q4CXM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXM4"
  },
  {
    "uniprot": "Q4D1R8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1R8"
  },
  {
    "uniprot": "Q4E3F5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3F5"
  },
  {
    "uniprot": "Q4CM83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM83"
  },
  {
    "uniprot": "Q4CN99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN99"
  },
  {
    "uniprot": "Q4DUL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUL6"
  },
  {
    "uniprot": "Q4CQB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQB6"
  },
  {
    "uniprot": "Q4CN65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN65"
  },
  {
    "uniprot": "Q4DP66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP66"
  },
  {
    "uniprot": "Q4D7E3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7E3"
  },
  {
    "uniprot": "Q4E168",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E168"
  },
  {
    "uniprot": "Q4CWC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWC0"
  },
  {
    "uniprot": "Q4DIP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIP4"
  },
  {
    "uniprot": "Q4CNB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNB1"
  },
  {
    "uniprot": "Q4DBK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBK7"
  },
  {
    "uniprot": "Q4CUJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUJ5"
  },
  {
    "uniprot": "Q4DLN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLN5"
  },
  {
    "uniprot": "Q4CYU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYU1"
  },
  {
    "uniprot": "Q4DBV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBV1"
  },
  {
    "uniprot": "Q4D301",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D301"
  },
  {
    "uniprot": "Q4E458",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E458"
  },
  {
    "uniprot": "Q4E3E3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3E3"
  },
  {
    "uniprot": "Q4DFQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFQ8"
  },
  {
    "uniprot": "Q4DA89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA89"
  },
  {
    "uniprot": "Q4E093",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E093"
  },
  {
    "uniprot": "Q4D2K7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2K7"
  },
  {
    "uniprot": "Q4CRZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRZ2"
  },
  {
    "uniprot": "Q4DLV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLV7"
  },
  {
    "uniprot": "Q4DZG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZG6"
  },
  {
    "uniprot": "Q4DSV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSV8"
  },
  {
    "uniprot": "Q4CW34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW34"
  },
  {
    "uniprot": "Q4CSB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSB4"
  },
  {
    "uniprot": "Q4DUA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUA6"
  },
  {
    "uniprot": "Q4CMJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMJ1"
  },
  {
    "uniprot": "Q4D3T2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3T2"
  },
  {
    "uniprot": "Q4DBC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBC7"
  },
  {
    "uniprot": "Q4DDY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDY1"
  },
  {
    "uniprot": "Q4D525",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D525"
  },
  {
    "uniprot": "Q4DB01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB01"
  },
  {
    "uniprot": "Q4D6H4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6H4"
  },
  {
    "uniprot": "Q4DKH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKH4"
  },
  {
    "uniprot": "Q4DRR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRR1"
  },
  {
    "uniprot": "Q4DTM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTM5"
  },
  {
    "uniprot": "Q4CL55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL55"
  },
  {
    "uniprot": "Q4DKM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKM8"
  },
  {
    "uniprot": "Q4DEF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEF8"
  },
  {
    "uniprot": "Q4DT74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT74"
  },
  {
    "uniprot": "Q4CS03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS03"
  },
  {
    "uniprot": "Q4D068",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D068"
  },
  {
    "uniprot": "Q4CYH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYH3"
  },
  {
    "uniprot": "Q4CWE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWE9"
  },
  {
    "uniprot": "Q4DZ67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ67"
  },
  {
    "uniprot": "Q4DNX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNX8"
  },
  {
    "uniprot": "Q4DE85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE85"
  },
  {
    "uniprot": "Q4DLD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLD8"
  },
  {
    "uniprot": "Q4CVJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVJ2"
  },
  {
    "uniprot": "Q4DZ07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ07"
  },
  {
    "uniprot": "Q4DBD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBD3"
  },
  {
    "uniprot": "Q4CWY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWY2"
  },
  {
    "uniprot": "Q4E1S6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1S6"
  },
  {
    "uniprot": "Q4DLY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLY3"
  },
  {
    "uniprot": "Q4CV08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV08"
  },
  {
    "uniprot": "Q4DSZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSZ4"
  },
  {
    "uniprot": "Q4E5H9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5H9"
  },
  {
    "uniprot": "Q4D0A3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0A3"
  },
  {
    "uniprot": "Q4E0L6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0L6"
  },
  {
    "uniprot": "Q4DXK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXK3"
  },
  {
    "uniprot": "Q4D5M0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5M0"
  },
  {
    "uniprot": "Q4DZ24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ24"
  },
  {
    "uniprot": "Q4CTD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTD2"
  },
  {
    "uniprot": "Q4CU51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU51"
  },
  {
    "uniprot": "Q4D1A1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1A1"
  },
  {
    "uniprot": "Q4E5F0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5F0"
  },
  {
    "uniprot": "Q4D012",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D012"
  },
  {
    "uniprot": "Q4DDD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDD0"
  },
  {
    "uniprot": "Q4CX30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX30"
  },
  {
    "uniprot": "Q4DDH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDH4"
  },
  {
    "uniprot": "Q4E548",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E548"
  },
  {
    "uniprot": "Q4DHB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHB8"
  },
  {
    "uniprot": "Q4DJY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJY4"
  },
  {
    "uniprot": "Q4CVL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVL3"
  },
  {
    "uniprot": "Q4CN90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN90"
  },
  {
    "uniprot": "Q4DH12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH12"
  },
  {
    "uniprot": "Q4CQT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQT3"
  },
  {
    "uniprot": "Q4DDM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDM6"
  },
  {
    "uniprot": "Q4D1Z9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Z9"
  },
  {
    "uniprot": "Q4D0P3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0P3"
  },
  {
    "uniprot": "Q4DQM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQM3"
  },
  {
    "uniprot": "Q4D5D5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5D5"
  },
  {
    "uniprot": "Q4DLH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLH6"
  },
  {
    "uniprot": "Q4E1R9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1R9"
  },
  {
    "uniprot": "Q4DD10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD10"
  },
  {
    "uniprot": "Q4DDB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDB9"
  },
  {
    "uniprot": "Q4D953",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D953"
  },
  {
    "uniprot": "Q4DTV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTV0"
  },
  {
    "uniprot": "Q4CLM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLM5"
  },
  {
    "uniprot": "Q4DX86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX86"
  },
  {
    "uniprot": "Q4E2P0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2P0"
  },
  {
    "uniprot": "Q4DCF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCF5"
  },
  {
    "uniprot": "Q4CVC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVC7"
  },
  {
    "uniprot": "Q4D1R3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1R3"
  },
  {
    "uniprot": "Q4DAY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAY8"
  },
  {
    "uniprot": "Q4CW55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW55"
  },
  {
    "uniprot": "Q4CU10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU10"
  },
  {
    "uniprot": "Q4D0Q5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Q5"
  },
  {
    "uniprot": "Q4E0H5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0H5"
  },
  {
    "uniprot": "Q4DWZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWZ7"
  },
  {
    "uniprot": "Q4DFL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFL5"
  },
  {
    "uniprot": "Q4DM12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM12"
  },
  {
    "uniprot": "Q4E4V3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4V3"
  },
  {
    "uniprot": "Q4DCI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCI1"
  },
  {
    "uniprot": "Q4E0J0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0J0"
  },
  {
    "uniprot": "Q4CY49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY49"
  },
  {
    "uniprot": "Q4DI69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI69"
  },
  {
    "uniprot": "Q4DLP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLP7"
  },
  {
    "uniprot": "Q4CTS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTS8"
  },
  {
    "uniprot": "Q4DT88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT88"
  },
  {
    "uniprot": "Q4DXB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXB2"
  },
  {
    "uniprot": "Q4DRH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRH6"
  },
  {
    "uniprot": "Q4CM59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM59"
  },
  {
    "uniprot": "Q4D7S2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7S2"
  },
  {
    "uniprot": "Q4D1G0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1G0"
  },
  {
    "uniprot": "Q4D784",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D784"
  },
  {
    "uniprot": "Q4CRS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRS7"
  },
  {
    "uniprot": "Q4CMR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMR1"
  },
  {
    "uniprot": "Q4D1Q6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Q6"
  },
  {
    "uniprot": "Q4DK36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK36"
  },
  {
    "uniprot": "Q4E686",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E686"
  },
  {
    "uniprot": "Q4CTX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTX9"
  },
  {
    "uniprot": "Q4D8N3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8N3"
  },
  {
    "uniprot": "Q4E432",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E432"
  },
  {
    "uniprot": "Q4CXV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXV8"
  },
  {
    "uniprot": "Q4E205",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E205"
  },
  {
    "uniprot": "Q4DAE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAE1"
  },
  {
    "uniprot": "Q4D8G0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8G0"
  },
  {
    "uniprot": "Q4D4H7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4H7"
  },
  {
    "uniprot": "Q4DA62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA62"
  },
  {
    "uniprot": "Q4D2R1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2R1"
  },
  {
    "uniprot": "Q4CSN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSN8"
  },
  {
    "uniprot": "Q4D457",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D457"
  },
  {
    "uniprot": "Q4CN04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN04"
  },
  {
    "uniprot": "Q4D6V5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6V5"
  },
  {
    "uniprot": "Q4CND5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CND5"
  },
  {
    "uniprot": "Q4DRF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRF0"
  },
  {
    "uniprot": "Q4CNZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNZ7"
  },
  {
    "uniprot": "Q4DBM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBM0"
  },
  {
    "uniprot": "Q4DJJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJJ2"
  },
  {
    "uniprot": "Q4DXQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXQ7"
  },
  {
    "uniprot": "Q4DFC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFC0"
  },
  {
    "uniprot": "Q4E146",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E146"
  },
  {
    "uniprot": "Q4DER1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DER1"
  },
  {
    "uniprot": "Q4DRI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRI8"
  },
  {
    "uniprot": "Q4DHE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHE4"
  },
  {
    "uniprot": "Q4D3R6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3R6"
  },
  {
    "uniprot": "Q4DZT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZT7"
  },
  {
    "uniprot": "Q4DDD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDD9"
  },
  {
    "uniprot": "Q4CXU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXU8"
  },
  {
    "uniprot": "Q4CPR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPR5"
  },
  {
    "uniprot": "Q4D2I5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2I5"
  },
  {
    "uniprot": "Q4CVG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVG0"
  },
  {
    "uniprot": "Q4DEX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEX5"
  },
  {
    "uniprot": "Q4CW75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW75"
  },
  {
    "uniprot": "Q4CZZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZZ9"
  },
  {
    "uniprot": "Q4D0J5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0J5"
  },
  {
    "uniprot": "Q4CKJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKJ3"
  },
  {
    "uniprot": "Q4CQM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQM3"
  },
  {
    "uniprot": "Q4CPW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPW2"
  },
  {
    "uniprot": "Q4E4M3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4M3"
  },
  {
    "uniprot": "Q4D0P5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0P5"
  },
  {
    "uniprot": "Q4DH60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH60"
  },
  {
    "uniprot": "Q4E112",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E112"
  },
  {
    "uniprot": "Q4DMT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMT7"
  },
  {
    "uniprot": "Q4D4S8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4S8"
  },
  {
    "uniprot": "Q4DDX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDX5"
  },
  {
    "uniprot": "Q4CV38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV38"
  },
  {
    "uniprot": "Q4D354",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D354"
  },
  {
    "uniprot": "Q4CYY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYY4"
  },
  {
    "uniprot": "Q4DT37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT37"
  },
  {
    "uniprot": "Q4CQL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQL0"
  },
  {
    "uniprot": "Q4D610",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D610"
  },
  {
    "uniprot": "Q4D477",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D477"
  },
  {
    "uniprot": "Q4DBH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBH9"
  },
  {
    "uniprot": "Q4DD65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD65"
  },
  {
    "uniprot": "Q4CYW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYW2"
  },
  {
    "uniprot": "Q4CKJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKJ8"
  },
  {
    "uniprot": "Q4CQG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQG7"
  },
  {
    "uniprot": "Q4DEV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEV8"
  },
  {
    "uniprot": "Q4DE74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE74"
  },
  {
    "uniprot": "Q4DBI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBI6"
  },
  {
    "uniprot": "Q4DRJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRJ2"
  },
  {
    "uniprot": "Q4CU81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU81"
  },
  {
    "uniprot": "Q4CRP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRP9"
  },
  {
    "uniprot": "Q4DQ91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ91"
  },
  {
    "uniprot": "Q4D022",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D022"
  },
  {
    "uniprot": "Q4CNF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNF0"
  },
  {
    "uniprot": "Q4D570",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D570"
  },
  {
    "uniprot": "Q4D7T5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7T5"
  },
  {
    "uniprot": "Q4DYB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYB4"
  },
  {
    "uniprot": "Q4E1P1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1P1"
  },
  {
    "uniprot": "Q4DZE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZE9"
  },
  {
    "uniprot": "Q4CPR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPR7"
  },
  {
    "uniprot": "Q4D313",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D313"
  },
  {
    "uniprot": "Q4E454",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E454"
  },
  {
    "uniprot": "Q4CY74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY74"
  },
  {
    "uniprot": "Q4D864",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D864"
  },
  {
    "uniprot": "Q4E0S4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0S4"
  },
  {
    "uniprot": "Q4DUB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUB0"
  },
  {
    "uniprot": "Q4E4E4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4E4"
  },
  {
    "uniprot": "Q4D7T2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7T2"
  },
  {
    "uniprot": "Q4DSY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSY9"
  },
  {
    "uniprot": "Q4DXH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXH9"
  },
  {
    "uniprot": "Q4CM40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM40"
  },
  {
    "uniprot": "Q4D744",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D744"
  },
  {
    "uniprot": "Q4CNX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNX4"
  },
  {
    "uniprot": "Q4DNW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNW9"
  },
  {
    "uniprot": "Q4E0V1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0V1"
  },
  {
    "uniprot": "Q4DJ36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ36"
  },
  {
    "uniprot": "Q4DR14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR14"
  },
  {
    "uniprot": "Q4E651",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E651"
  },
  {
    "uniprot": "Q4E355",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E355"
  },
  {
    "uniprot": "Q4CSG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSG9"
  },
  {
    "uniprot": "Q4DEV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEV3"
  },
  {
    "uniprot": "Q4D9S3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9S3"
  },
  {
    "uniprot": "Q4DI68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI68"
  },
  {
    "uniprot": "Q4E4A5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4A5"
  },
  {
    "uniprot": "Q4CQT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQT1"
  },
  {
    "uniprot": "Q4D8B1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8B1"
  },
  {
    "uniprot": "Q4DBC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBC4"
  },
  {
    "uniprot": "Q4E2J6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2J6"
  },
  {
    "uniprot": "Q4DP90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP90"
  },
  {
    "uniprot": "Q4D5C8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5C8"
  },
  {
    "uniprot": "Q4DI59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI59"
  },
  {
    "uniprot": "Q4E2Y9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Y9"
  },
  {
    "uniprot": "Q4CWV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWV4"
  },
  {
    "uniprot": "Q4CKN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKN3"
  },
  {
    "uniprot": "Q4DR23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR23"
  },
  {
    "uniprot": "Q4DG78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG78"
  },
  {
    "uniprot": "Q4DWW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWW0"
  },
  {
    "uniprot": "Q4DA40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA40"
  },
  {
    "uniprot": "Q4DM04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM04"
  },
  {
    "uniprot": "Q4DXG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXG1"
  },
  {
    "uniprot": "Q4DS36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS36"
  },
  {
    "uniprot": "Q4CVM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVM8"
  },
  {
    "uniprot": "Q4CRH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRH3"
  },
  {
    "uniprot": "Q4DG00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG00"
  },
  {
    "uniprot": "Q4CYT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYT8"
  },
  {
    "uniprot": "Q4DM01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM01"
  },
  {
    "uniprot": "Q4DII5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DII5"
  },
  {
    "uniprot": "Q4D113",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D113"
  },
  {
    "uniprot": "Q4DML0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DML0"
  },
  {
    "uniprot": "Q4CTM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTM7"
  },
  {
    "uniprot": "Q4E4I5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4I5"
  },
  {
    "uniprot": "Q4DKS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKS6"
  },
  {
    "uniprot": "Q4DIN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIN0"
  },
  {
    "uniprot": "Q4CL21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL21"
  },
  {
    "uniprot": "Q4CSL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSL8"
  },
  {
    "uniprot": "Q4DMU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMU0"
  },
  {
    "uniprot": "Q4DQ53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ53"
  },
  {
    "uniprot": "Q4D1K9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1K9"
  },
  {
    "uniprot": "Q4CZ48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ48"
  },
  {
    "uniprot": "Q4DIL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIL3"
  },
  {
    "uniprot": "Q4D537",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D537"
  },
  {
    "uniprot": "Q4E4D4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4D4"
  },
  {
    "uniprot": "Q4CSH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSH6"
  },
  {
    "uniprot": "Q4E4R0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4R0"
  },
  {
    "uniprot": "Q4DDQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDQ6"
  },
  {
    "uniprot": "Q4DW95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW95"
  },
  {
    "uniprot": "Q4D035",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D035"
  },
  {
    "uniprot": "Q4D8L9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8L9"
  },
  {
    "uniprot": "Q4CR22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR22"
  },
  {
    "uniprot": "Q4CTR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTR4"
  },
  {
    "uniprot": "Q4CRB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRB9"
  },
  {
    "uniprot": "Q4D4Z7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Z7"
  },
  {
    "uniprot": "Q4DTP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTP3"
  },
  {
    "uniprot": "Q4DPJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPJ4"
  },
  {
    "uniprot": "Q4DHD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHD8"
  },
  {
    "uniprot": "Q4E4U4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4U4"
  },
  {
    "uniprot": "Q4CLB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLB3"
  },
  {
    "uniprot": "Q4DDT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDT8"
  },
  {
    "uniprot": "Q4E0I4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0I4"
  },
  {
    "uniprot": "Q4DRC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRC7"
  },
  {
    "uniprot": "Q4DDB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDB7"
  },
  {
    "uniprot": "Q4D8B6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8B6"
  },
  {
    "uniprot": "Q4CYI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYI0"
  },
  {
    "uniprot": "Q4DAA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAA0"
  },
  {
    "uniprot": "Q4D6V2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6V2"
  },
  {
    "uniprot": "Q4CL98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL98"
  },
  {
    "uniprot": "Q4CM85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM85"
  },
  {
    "uniprot": "Q4DIU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIU5"
  },
  {
    "uniprot": "Q4CM58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM58"
  },
  {
    "uniprot": "Q4DMD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMD8"
  },
  {
    "uniprot": "Q4DGG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGG5"
  },
  {
    "uniprot": "Q4CT96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT96"
  },
  {
    "uniprot": "Q4DSK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSK2"
  },
  {
    "uniprot": "Q4DNU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNU6"
  },
  {
    "uniprot": "Q4DSH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSH2"
  },
  {
    "uniprot": "Q4CR93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR93"
  },
  {
    "uniprot": "Q4CYZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYZ6"
  },
  {
    "uniprot": "Q4D1W5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1W5"
  },
  {
    "uniprot": "Q4CWR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWR6"
  },
  {
    "uniprot": "Q4CZB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZB8"
  },
  {
    "uniprot": "Q4DV30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV30"
  },
  {
    "uniprot": "Q4CKL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKL9"
  },
  {
    "uniprot": "Q4DD61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD61"
  },
  {
    "uniprot": "Q4DVX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVX7"
  },
  {
    "uniprot": "Q4CMT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMT0"
  },
  {
    "uniprot": "Q4DBV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBV2"
  },
  {
    "uniprot": "Q4CTQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTQ0"
  },
  {
    "uniprot": "Q4D840",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D840"
  },
  {
    "uniprot": "Q4CMA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMA1"
  },
  {
    "uniprot": "Q4DPC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPC5"
  },
  {
    "uniprot": "Q4DP71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP71"
  },
  {
    "uniprot": "Q4DTQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTQ9"
  },
  {
    "uniprot": "Q4E2K6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2K6"
  },
  {
    "uniprot": "Q4E0T9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0T9"
  },
  {
    "uniprot": "Q4CMU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMU8"
  },
  {
    "uniprot": "Q4D2P3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2P3"
  },
  {
    "uniprot": "Q4CTZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTZ9"
  },
  {
    "uniprot": "Q4CL02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL02"
  },
  {
    "uniprot": "Q4E4D8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4D8"
  },
  {
    "uniprot": "Q4CPH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPH6"
  },
  {
    "uniprot": "Q4DQ10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ10"
  },
  {
    "uniprot": "Q4DS04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS04"
  },
  {
    "uniprot": "Q4DUA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUA8"
  },
  {
    "uniprot": "Q4DPX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPX9"
  },
  {
    "uniprot": "Q4CMV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMV1"
  },
  {
    "uniprot": "Q4DJU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJU0"
  },
  {
    "uniprot": "Q4CU27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU27"
  },
  {
    "uniprot": "Q4DN40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN40"
  },
  {
    "uniprot": "Q4CTP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTP4"
  },
  {
    "uniprot": "Q4DUX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUX1"
  },
  {
    "uniprot": "Q4CSX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSX0"
  },
  {
    "uniprot": "Q4DS62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS62"
  },
  {
    "uniprot": "Q4DMM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMM0"
  },
  {
    "uniprot": "Q4CKA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKA2"
  },
  {
    "uniprot": "Q4CWC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWC2"
  },
  {
    "uniprot": "Q4CVE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVE3"
  },
  {
    "uniprot": "Q4CQA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQA4"
  },
  {
    "uniprot": "Q4DRR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRR5"
  },
  {
    "uniprot": "Q4CLY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLY2"
  },
  {
    "uniprot": "Q4D9A1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9A1"
  },
  {
    "uniprot": "Q4CQP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQP8"
  },
  {
    "uniprot": "Q4DZC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZC9"
  },
  {
    "uniprot": "Q4E5B5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5B5"
  },
  {
    "uniprot": "Q4DXM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXM0"
  },
  {
    "uniprot": "Q4DLQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLQ2"
  },
  {
    "uniprot": "Q4CQF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQF1"
  },
  {
    "uniprot": "Q4DUF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUF8"
  },
  {
    "uniprot": "Q4CVS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVS8"
  },
  {
    "uniprot": "Q4DRR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRR4"
  },
  {
    "uniprot": "Q4D142",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D142"
  },
  {
    "uniprot": "Q4E1L5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1L5"
  },
  {
    "uniprot": "Q4E3R2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3R2"
  },
  {
    "uniprot": "Q4CLS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLS1"
  },
  {
    "uniprot": "Q4DG02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG02"
  },
  {
    "uniprot": "Q4DUZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUZ6"
  },
  {
    "uniprot": "Q4DM45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM45"
  },
  {
    "uniprot": "Q4DME2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DME2"
  },
  {
    "uniprot": "Q4CQ46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ46"
  },
  {
    "uniprot": "Q4DBR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBR5"
  },
  {
    "uniprot": "Q4D9B6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9B6"
  },
  {
    "uniprot": "Q4D456",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D456"
  },
  {
    "uniprot": "Q4CWZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWZ4"
  },
  {
    "uniprot": "Q4D832",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D832"
  },
  {
    "uniprot": "Q4DY95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY95"
  },
  {
    "uniprot": "Q4DM78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM78"
  },
  {
    "uniprot": "Q4DYM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYM3"
  },
  {
    "uniprot": "Q4D9V4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9V4"
  },
  {
    "uniprot": "Q4DU25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU25"
  },
  {
    "uniprot": "Q4DL46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL46"
  },
  {
    "uniprot": "Q4CQN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQN7"
  },
  {
    "uniprot": "Q4D312",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D312"
  },
  {
    "uniprot": "Q4DKD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKD2"
  },
  {
    "uniprot": "Q4E2R8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2R8"
  },
  {
    "uniprot": "Q4CLZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLZ1"
  },
  {
    "uniprot": "Q4CUN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUN2"
  },
  {
    "uniprot": "Q4CNF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNF6"
  },
  {
    "uniprot": "Q4DNJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNJ6"
  },
  {
    "uniprot": "Q4DRI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRI3"
  },
  {
    "uniprot": "Q4DW77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW77"
  },
  {
    "uniprot": "Q4CYE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYE9"
  },
  {
    "uniprot": "Q4DVZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVZ3"
  },
  {
    "uniprot": "Q4CNJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNJ7"
  },
  {
    "uniprot": "Q4E170",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E170"
  },
  {
    "uniprot": "Q4D0C6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0C6"
  },
  {
    "uniprot": "Q4CZ38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ38"
  },
  {
    "uniprot": "Q4E0X6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0X6"
  },
  {
    "uniprot": "Q4D454",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D454"
  },
  {
    "uniprot": "Q4D2D8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2D8"
  },
  {
    "uniprot": "Q4DAH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAH1"
  },
  {
    "uniprot": "Q4DT80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT80"
  },
  {
    "uniprot": "Q4DRA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRA7"
  },
  {
    "uniprot": "Q4D5L3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5L3"
  },
  {
    "uniprot": "Q4DYQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYQ5"
  },
  {
    "uniprot": "Q4DHT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHT0"
  },
  {
    "uniprot": "Q4DDM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDM5"
  },
  {
    "uniprot": "Q4DL65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL65"
  },
  {
    "uniprot": "Q4DZG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZG7"
  },
  {
    "uniprot": "Q4DQT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQT8"
  },
  {
    "uniprot": "Q4CTT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTT8"
  },
  {
    "uniprot": "Q4CM47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM47"
  },
  {
    "uniprot": "Q4D370",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D370"
  },
  {
    "uniprot": "Q4D7S7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7S7"
  },
  {
    "uniprot": "Q4DVJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVJ0"
  },
  {
    "uniprot": "Q4DAS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAS1"
  },
  {
    "uniprot": "Q4CRM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRM0"
  },
  {
    "uniprot": "Q4DWX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWX7"
  },
  {
    "uniprot": "Q4CWA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWA9"
  },
  {
    "uniprot": "Q4DD45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD45"
  },
  {
    "uniprot": "Q4DZU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZU9"
  },
  {
    "uniprot": "Q4E142",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E142"
  },
  {
    "uniprot": "Q4DYR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYR4"
  },
  {
    "uniprot": "Q4E1F1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1F1"
  },
  {
    "uniprot": "Q4CKU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKU1"
  },
  {
    "uniprot": "Q4CL89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL89"
  },
  {
    "uniprot": "Q4DN66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN66"
  },
  {
    "uniprot": "Q4CLL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLL5"
  },
  {
    "uniprot": "Q4DNP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNP6"
  },
  {
    "uniprot": "Q4DQE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQE2"
  },
  {
    "uniprot": "Q4DXG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXG6"
  },
  {
    "uniprot": "Q4DKF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKF8"
  },
  {
    "uniprot": "Q4CTP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTP7"
  },
  {
    "uniprot": "Q4DTM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTM1"
  },
  {
    "uniprot": "Q4CVQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVQ0"
  },
  {
    "uniprot": "Q4D3K2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3K2"
  },
  {
    "uniprot": "Q4E2N1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2N1"
  },
  {
    "uniprot": "Q4E237",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E237"
  },
  {
    "uniprot": "Q4E3L6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3L6"
  },
  {
    "uniprot": "Q4DTD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTD7"
  },
  {
    "uniprot": "Q4DNY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNY0"
  },
  {
    "uniprot": "Q4CRC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRC8"
  },
  {
    "uniprot": "Q4DJV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJV9"
  },
  {
    "uniprot": "Q4CXI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXI5"
  },
  {
    "uniprot": "Q4D5M3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5M3"
  },
  {
    "uniprot": "Q4E398",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E398"
  },
  {
    "uniprot": "Q4DCM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCM4"
  },
  {
    "uniprot": "Q4E461",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E461"
  },
  {
    "uniprot": "Q4D3X4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3X4"
  },
  {
    "uniprot": "Q4D6U7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6U7"
  },
  {
    "uniprot": "Q4CZ35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ35"
  },
  {
    "uniprot": "Q4DGC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGC8"
  },
  {
    "uniprot": "Q4CT17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT17"
  },
  {
    "uniprot": "Q4CYZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYZ3"
  },
  {
    "uniprot": "Q4D4S9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4S9"
  },
  {
    "uniprot": "Q4CL76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL76"
  },
  {
    "uniprot": "Q4E0P0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0P0"
  },
  {
    "uniprot": "Q4CTD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTD0"
  },
  {
    "uniprot": "Q4CTE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTE8"
  },
  {
    "uniprot": "Q4DCN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCN5"
  },
  {
    "uniprot": "Q4DC40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC40"
  },
  {
    "uniprot": "Q4DBA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBA2"
  },
  {
    "uniprot": "Q4CNC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNC3"
  },
  {
    "uniprot": "Q4CMP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMP4"
  },
  {
    "uniprot": "Q4D2V3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2V3"
  },
  {
    "uniprot": "Q4CKT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKT6"
  },
  {
    "uniprot": "Q4CV82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV82"
  },
  {
    "uniprot": "Q4CPH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPH5"
  },
  {
    "uniprot": "Q4DML4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DML4"
  },
  {
    "uniprot": "Q4E0J5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0J5"
  },
  {
    "uniprot": "Q4DXY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXY8"
  },
  {
    "uniprot": "Q4DXD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXD1"
  },
  {
    "uniprot": "Q4D0V4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0V4"
  },
  {
    "uniprot": "Q4DNU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNU3"
  },
  {
    "uniprot": "Q4E1M5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1M5"
  },
  {
    "uniprot": "Q4DN20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN20"
  },
  {
    "uniprot": "Q4CNY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNY8"
  },
  {
    "uniprot": "Q4DTG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTG1"
  },
  {
    "uniprot": "Q4CQ37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ37"
  },
  {
    "uniprot": "Q4DJL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJL9"
  },
  {
    "uniprot": "Q4E4I0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4I0"
  },
  {
    "uniprot": "Q4CY62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY62"
  },
  {
    "uniprot": "Q4DUD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUD5"
  },
  {
    "uniprot": "Q4CKZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKZ2"
  },
  {
    "uniprot": "Q4DJB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJB5"
  },
  {
    "uniprot": "Q4E632",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E632"
  },
  {
    "uniprot": "Q4DNX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNX7"
  },
  {
    "uniprot": "Q4D7W8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7W8"
  },
  {
    "uniprot": "Q4CX01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX01"
  },
  {
    "uniprot": "Q4CWB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWB0"
  },
  {
    "uniprot": "Q4D6K5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6K5"
  },
  {
    "uniprot": "Q4DUF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUF1"
  },
  {
    "uniprot": "Q4CT44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT44"
  },
  {
    "uniprot": "Q4CPX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPX3"
  },
  {
    "uniprot": "Q4DAP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAP6"
  },
  {
    "uniprot": "Q4CK96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CK96"
  },
  {
    "uniprot": "Q4DQS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQS0"
  },
  {
    "uniprot": "Q4DM39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM39"
  },
  {
    "uniprot": "Q4DTG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTG6"
  },
  {
    "uniprot": "Q4CX83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX83"
  },
  {
    "uniprot": "Q4CPN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPN4"
  },
  {
    "uniprot": "Q4CYU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYU2"
  },
  {
    "uniprot": "Q4D2E6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2E6"
  },
  {
    "uniprot": "Q4DWT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWT7"
  },
  {
    "uniprot": "Q4CSC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSC3"
  },
  {
    "uniprot": "Q4D5Y6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Y6"
  },
  {
    "uniprot": "Q4DH05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH05"
  },
  {
    "uniprot": "Q4CN48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN48"
  },
  {
    "uniprot": "Q4E575",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E575"
  },
  {
    "uniprot": "Q4D814",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D814"
  },
  {
    "uniprot": "Q4D385",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D385"
  },
  {
    "uniprot": "Q4DZB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZB8"
  },
  {
    "uniprot": "Q4DCB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCB1"
  },
  {
    "uniprot": "Q4DI30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI30"
  },
  {
    "uniprot": "Q4D999",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D999"
  },
  {
    "uniprot": "Q4CZK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZK9"
  },
  {
    "uniprot": "Q4E0W6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0W6"
  },
  {
    "uniprot": "Q4E576",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E576"
  },
  {
    "uniprot": "Q4CRL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRL2"
  },
  {
    "uniprot": "Q4CWD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWD7"
  },
  {
    "uniprot": "Q4DWK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWK0"
  },
  {
    "uniprot": "Q4D1L9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1L9"
  },
  {
    "uniprot": "Q4D7M3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7M3"
  },
  {
    "uniprot": "Q4CQ47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ47"
  },
  {
    "uniprot": "Q4DXI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXI9"
  },
  {
    "uniprot": "Q4E1J0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1J0"
  },
  {
    "uniprot": "Q4CXT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXT0"
  },
  {
    "uniprot": "Q4CLP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLP1"
  },
  {
    "uniprot": "Q4CQU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQU0"
  },
  {
    "uniprot": "Q4D8A7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8A7"
  },
  {
    "uniprot": "Q4CTC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTC4"
  },
  {
    "uniprot": "Q4CPR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPR8"
  },
  {
    "uniprot": "Q4E260",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E260"
  },
  {
    "uniprot": "Q4DBF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBF1"
  },
  {
    "uniprot": "Q4E3Q9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Q9"
  },
  {
    "uniprot": "Q4DE75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE75"
  },
  {
    "uniprot": "Q4E3H6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3H6"
  },
  {
    "uniprot": "Q4D1Q9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Q9"
  },
  {
    "uniprot": "Q4CL34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL34"
  },
  {
    "uniprot": "Q4DSB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSB9"
  },
  {
    "uniprot": "Q4E450",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E450"
  },
  {
    "uniprot": "Q4CLI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLI3"
  },
  {
    "uniprot": "Q4DKW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKW5"
  },
  {
    "uniprot": "Q4DBS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBS6"
  },
  {
    "uniprot": "Q4CU46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU46"
  },
  {
    "uniprot": "Q4CXH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXH2"
  },
  {
    "uniprot": "Q4D372",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D372"
  },
  {
    "uniprot": "Q4DXB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXB3"
  },
  {
    "uniprot": "Q4CV49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV49"
  },
  {
    "uniprot": "Q4DWG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWG4"
  },
  {
    "uniprot": "Q4DSS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSS9"
  },
  {
    "uniprot": "Q4DU18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU18"
  },
  {
    "uniprot": "Q4DRP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRP6"
  },
  {
    "uniprot": "Q4DI17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI17"
  },
  {
    "uniprot": "Q4D8Z6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Z6"
  },
  {
    "uniprot": "Q4D0D5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0D5"
  },
  {
    "uniprot": "Q4DD57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD57"
  },
  {
    "uniprot": "Q4DD39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD39"
  },
  {
    "uniprot": "Q4E4D9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4D9"
  },
  {
    "uniprot": "Q4E3N6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3N6"
  },
  {
    "uniprot": "Q4CW49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW49"
  },
  {
    "uniprot": "Q4DXM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXM9"
  },
  {
    "uniprot": "Q4CSP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSP0"
  },
  {
    "uniprot": "Q4E5S8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5S8"
  },
  {
    "uniprot": "Q4D8R1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8R1"
  },
  {
    "uniprot": "Q4CR75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR75"
  },
  {
    "uniprot": "Q4DFX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFX1"
  },
  {
    "uniprot": "Q4E009",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E009"
  },
  {
    "uniprot": "Q4DH95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH95"
  },
  {
    "uniprot": "Q4DJF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJF9"
  },
  {
    "uniprot": "Q4DXP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXP5"
  },
  {
    "uniprot": "Q4DCG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCG6"
  },
  {
    "uniprot": "Q4D637",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D637"
  },
  {
    "uniprot": "Q4DMJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMJ9"
  },
  {
    "uniprot": "Q4DFQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFQ9"
  },
  {
    "uniprot": "Q4DPE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPE5"
  },
  {
    "uniprot": "Q4E3G2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3G2"
  },
  {
    "uniprot": "Q4CL43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL43"
  },
  {
    "uniprot": "Q4CKM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKM8"
  },
  {
    "uniprot": "Q4DZ84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ84"
  },
  {
    "uniprot": "Q4CY25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY25"
  },
  {
    "uniprot": "Q4E226",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E226"
  },
  {
    "uniprot": "Q4D8P5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8P5"
  },
  {
    "uniprot": "Q4DG21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG21"
  },
  {
    "uniprot": "Q4DNT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNT0"
  },
  {
    "uniprot": "Q4CRE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRE6"
  },
  {
    "uniprot": "Q4D411",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D411"
  },
  {
    "uniprot": "Q4DTR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTR9"
  },
  {
    "uniprot": "Q4D5T6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5T6"
  },
  {
    "uniprot": "Q4DJK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJK4"
  },
  {
    "uniprot": "Q4CWB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWB2"
  },
  {
    "uniprot": "Q4E3E6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3E6"
  },
  {
    "uniprot": "Q4CZ97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ97"
  },
  {
    "uniprot": "Q4E0S6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0S6"
  },
  {
    "uniprot": "Q4DMU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMU2"
  },
  {
    "uniprot": "Q4DFY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFY0"
  },
  {
    "uniprot": "Q4DY89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY89"
  },
  {
    "uniprot": "Q4E5Q8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Q8"
  },
  {
    "uniprot": "Q4D2D4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2D4"
  },
  {
    "uniprot": "Q4D0I9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0I9"
  },
  {
    "uniprot": "Q4DCW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCW9"
  },
  {
    "uniprot": "Q4DH16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH16"
  },
  {
    "uniprot": "Q4CSE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSE9"
  },
  {
    "uniprot": "Q4CX31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX31"
  },
  {
    "uniprot": "Q4DAE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAE2"
  },
  {
    "uniprot": "Q4DDW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDW3"
  },
  {
    "uniprot": "Q4E4N7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4N7"
  },
  {
    "uniprot": "Q4DZ60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ60"
  },
  {
    "uniprot": "Q4CYQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYQ5"
  },
  {
    "uniprot": "Q4DH72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH72"
  },
  {
    "uniprot": "Q4DKT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKT6"
  },
  {
    "uniprot": "Q4D2R6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2R6"
  },
  {
    "uniprot": "Q4CZ09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ09"
  },
  {
    "uniprot": "Q4E349",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E349"
  },
  {
    "uniprot": "Q4D7Y1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Y1"
  },
  {
    "uniprot": "Q4DUX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUX4"
  },
  {
    "uniprot": "Q4DJC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJC6"
  },
  {
    "uniprot": "Q4E1H6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1H6"
  },
  {
    "uniprot": "Q4E5G2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5G2"
  },
  {
    "uniprot": "Q4CSW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSW1"
  },
  {
    "uniprot": "Q4DZK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZK0"
  },
  {
    "uniprot": "Q4DNJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNJ5"
  },
  {
    "uniprot": "Q4DXZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXZ6"
  },
  {
    "uniprot": "Q4CMR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMR7"
  },
  {
    "uniprot": "Q4D6I9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6I9"
  },
  {
    "uniprot": "Q4DYP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYP4"
  },
  {
    "uniprot": "Q4CW70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW70"
  },
  {
    "uniprot": "Q4DH49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH49"
  },
  {
    "uniprot": "Q4DRV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRV1"
  },
  {
    "uniprot": "Q4CMT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMT7"
  },
  {
    "uniprot": "Q4CUD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUD5"
  },
  {
    "uniprot": "Q4D6Z0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Z0"
  },
  {
    "uniprot": "Q4DK09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK09"
  },
  {
    "uniprot": "Q4D179",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D179"
  },
  {
    "uniprot": "Q4DQ77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ77"
  },
  {
    "uniprot": "Q4CWR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWR2"
  },
  {
    "uniprot": "Q4DXS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXS1"
  },
  {
    "uniprot": "Q4CRC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRC7"
  },
  {
    "uniprot": "Q4E507",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E507"
  },
  {
    "uniprot": "Q4CVH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVH2"
  },
  {
    "uniprot": "Q4CQB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQB0"
  },
  {
    "uniprot": "Q4E0A8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0A8"
  },
  {
    "uniprot": "Q4CSR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSR4"
  },
  {
    "uniprot": "Q4DH09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH09"
  },
  {
    "uniprot": "Q4DMA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMA3"
  },
  {
    "uniprot": "Q4CZL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZL9"
  },
  {
    "uniprot": "Q4DBK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBK8"
  },
  {
    "uniprot": "Q4D210",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D210"
  },
  {
    "uniprot": "Q4CNZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNZ5"
  },
  {
    "uniprot": "Q4DQ07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ07"
  },
  {
    "uniprot": "Q4D503",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D503"
  },
  {
    "uniprot": "Q4CRD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRD7"
  },
  {
    "uniprot": "Q4DWT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWT8"
  },
  {
    "uniprot": "Q4DZA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZA1"
  },
  {
    "uniprot": "Q4CPE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPE0"
  },
  {
    "uniprot": "Q4DJA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJA9"
  },
  {
    "uniprot": "Q4CVC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVC8"
  },
  {
    "uniprot": "Q4E275",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E275"
  },
  {
    "uniprot": "Q4CW31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW31"
  },
  {
    "uniprot": "Q4CXJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXJ7"
  },
  {
    "uniprot": "Q4DK93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK93"
  },
  {
    "uniprot": "Q4DJU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJU9"
  },
  {
    "uniprot": "Q4D5Z1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Z1"
  },
  {
    "uniprot": "Q4DRX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRX9"
  },
  {
    "uniprot": "Q4DV87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV87"
  },
  {
    "uniprot": "Q4DBW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBW0"
  },
  {
    "uniprot": "Q4CM17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM17"
  },
  {
    "uniprot": "Q4DK02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK02"
  },
  {
    "uniprot": "Q4E124",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E124"
  },
  {
    "uniprot": "Q4CZE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZE2"
  },
  {
    "uniprot": "Q4DGL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGL3"
  },
  {
    "uniprot": "Q4CSW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSW3"
  },
  {
    "uniprot": "Q4DIH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIH8"
  },
  {
    "uniprot": "Q4D8D0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8D0"
  },
  {
    "uniprot": "Q4DXK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXK2"
  },
  {
    "uniprot": "Q4D791",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D791"
  },
  {
    "uniprot": "Q4DH93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH93"
  },
  {
    "uniprot": "Q4DQH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQH0"
  },
  {
    "uniprot": "Q4E508",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E508"
  },
  {
    "uniprot": "Q4DE39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE39"
  },
  {
    "uniprot": "Q4DEJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEJ5"
  },
  {
    "uniprot": "Q4CM42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM42"
  },
  {
    "uniprot": "Q4DAF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAF8"
  },
  {
    "uniprot": "Q4DW99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW99"
  },
  {
    "uniprot": "Q4DL95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL95"
  },
  {
    "uniprot": "Q4E070",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E070"
  },
  {
    "uniprot": "Q4CLM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLM1"
  },
  {
    "uniprot": "Q4D1R1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1R1"
  },
  {
    "uniprot": "Q4CVJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVJ9"
  },
  {
    "uniprot": "Q4D3E7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3E7"
  },
  {
    "uniprot": "Q4CWG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWG5"
  },
  {
    "uniprot": "Q4CL84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL84"
  },
  {
    "uniprot": "Q4DV94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV94"
  },
  {
    "uniprot": "Q4CNS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNS7"
  },
  {
    "uniprot": "Q4DRN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRN4"
  },
  {
    "uniprot": "Q4CLZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLZ4"
  },
  {
    "uniprot": "Q4D1T6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1T6"
  },
  {
    "uniprot": "Q4DL19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL19"
  },
  {
    "uniprot": "Q4D177",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D177"
  },
  {
    "uniprot": "Q4DY72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY72"
  },
  {
    "uniprot": "Q4CPD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPD5"
  },
  {
    "uniprot": "Q4DRG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRG4"
  },
  {
    "uniprot": "Q4D7T3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7T3"
  },
  {
    "uniprot": "Q4D2L9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2L9"
  },
  {
    "uniprot": "Q4CKI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKI4"
  },
  {
    "uniprot": "Q4DBQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBQ8"
  },
  {
    "uniprot": "Q4CRU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRU0"
  },
  {
    "uniprot": "Q4DQX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQX6"
  },
  {
    "uniprot": "Q4DDR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDR4"
  },
  {
    "uniprot": "Q4D267",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D267"
  },
  {
    "uniprot": "Q4DDS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDS9"
  },
  {
    "uniprot": "Q4CWB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWB9"
  },
  {
    "uniprot": "Q4DWA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWA9"
  },
  {
    "uniprot": "Q4E660",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E660"
  },
  {
    "uniprot": "Q4DD14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD14"
  },
  {
    "uniprot": "Q4E346",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E346"
  },
  {
    "uniprot": "Q4CVZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVZ1"
  },
  {
    "uniprot": "Q4CWC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWC1"
  },
  {
    "uniprot": "Q4DLK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLK4"
  },
  {
    "uniprot": "Q4DIQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIQ2"
  },
  {
    "uniprot": "Q4D8U2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8U2"
  },
  {
    "uniprot": "Q4CS09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS09"
  },
  {
    "uniprot": "Q4D085",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D085"
  },
  {
    "uniprot": "Q4E026",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E026"
  },
  {
    "uniprot": "Q4D1K8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1K8"
  },
  {
    "uniprot": "Q4CV46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV46"
  },
  {
    "uniprot": "Q4DBI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBI9"
  },
  {
    "uniprot": "Q4DCH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCH5"
  },
  {
    "uniprot": "Q4E340",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E340"
  },
  {
    "uniprot": "Q4DTP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTP1"
  },
  {
    "uniprot": "Q4CXT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXT3"
  },
  {
    "uniprot": "Q4CNQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNQ8"
  },
  {
    "uniprot": "Q4E0T2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0T2"
  },
  {
    "uniprot": "Q4E300",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E300"
  },
  {
    "uniprot": "Q4DZW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZW0"
  },
  {
    "uniprot": "Q4D596",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D596"
  },
  {
    "uniprot": "Q4CY01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY01"
  },
  {
    "uniprot": "Q4DHM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHM5"
  },
  {
    "uniprot": "Q4D3P9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3P9"
  },
  {
    "uniprot": "Q4CTF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTF4"
  },
  {
    "uniprot": "Q4CUL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUL9"
  },
  {
    "uniprot": "Q4DMV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMV1"
  },
  {
    "uniprot": "Q4E3T0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3T0"
  },
  {
    "uniprot": "Q4DYC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYC7"
  },
  {
    "uniprot": "Q4CZM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZM8"
  },
  {
    "uniprot": "Q4CW20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW20"
  },
  {
    "uniprot": "Q4DQP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQP7"
  },
  {
    "uniprot": "Q4DS06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS06"
  },
  {
    "uniprot": "Q4D236",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D236"
  },
  {
    "uniprot": "Q4DE91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE91"
  },
  {
    "uniprot": "Q4CYB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYB2"
  },
  {
    "uniprot": "Q4D7G5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7G5"
  },
  {
    "uniprot": "Q4DLC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLC8"
  },
  {
    "uniprot": "Q4DJE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJE4"
  },
  {
    "uniprot": "Q4CR64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR64"
  },
  {
    "uniprot": "Q4E627",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E627"
  },
  {
    "uniprot": "Q4D106",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D106"
  },
  {
    "uniprot": "Q4CQ08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ08"
  },
  {
    "uniprot": "Q4CMH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMH4"
  },
  {
    "uniprot": "Q4DYP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYP5"
  },
  {
    "uniprot": "Q4DRH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRH7"
  },
  {
    "uniprot": "Q4DDP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDP5"
  },
  {
    "uniprot": "Q4DS31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS31"
  },
  {
    "uniprot": "Q4DF49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF49"
  },
  {
    "uniprot": "Q4DDI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDI1"
  },
  {
    "uniprot": "Q4CPF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPF6"
  },
  {
    "uniprot": "Q4DCR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCR3"
  },
  {
    "uniprot": "Q4DJ05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ05"
  },
  {
    "uniprot": "Q4E228",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E228"
  },
  {
    "uniprot": "Q4DGU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGU1"
  },
  {
    "uniprot": "Q4DNY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNY4"
  },
  {
    "uniprot": "Q4DCH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCH7"
  },
  {
    "uniprot": "Q4DZD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZD8"
  },
  {
    "uniprot": "Q4CUQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUQ5"
  },
  {
    "uniprot": "Q4DWC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWC2"
  },
  {
    "uniprot": "Q4DY37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY37"
  },
  {
    "uniprot": "Q4DI11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI11"
  },
  {
    "uniprot": "Q4D746",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D746"
  },
  {
    "uniprot": "Q4CSR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSR8"
  },
  {
    "uniprot": "Q4E0Y5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Y5"
  },
  {
    "uniprot": "Q4CYD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYD0"
  },
  {
    "uniprot": "Q4E5G6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5G6"
  },
  {
    "uniprot": "Q4DG44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG44"
  },
  {
    "uniprot": "Q4DD35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD35"
  },
  {
    "uniprot": "Q4DUW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUW8"
  },
  {
    "uniprot": "Q4D1K3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1K3"
  },
  {
    "uniprot": "Q4E2G5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2G5"
  },
  {
    "uniprot": "Q4CPU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPU9"
  },
  {
    "uniprot": "Q4D4E2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4E2"
  },
  {
    "uniprot": "Q4CRK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRK1"
  },
  {
    "uniprot": "Q4DWG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWG5"
  },
  {
    "uniprot": "Q4DQ96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ96"
  },
  {
    "uniprot": "Q4CW93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW93"
  },
  {
    "uniprot": "Q4CXR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXR1"
  },
  {
    "uniprot": "Q4DGY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGY3"
  },
  {
    "uniprot": "Q4CQD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQD8"
  },
  {
    "uniprot": "Q4D028",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D028"
  },
  {
    "uniprot": "Q4DAY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAY6"
  },
  {
    "uniprot": "Q4DMZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMZ8"
  },
  {
    "uniprot": "Q4DP59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP59"
  },
  {
    "uniprot": "Q4DUJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUJ9"
  },
  {
    "uniprot": "Q4E5R8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5R8"
  },
  {
    "uniprot": "Q4DY56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY56"
  },
  {
    "uniprot": "Q4DE46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE46"
  },
  {
    "uniprot": "Q4CN63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN63"
  },
  {
    "uniprot": "Q4D0M8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0M8"
  },
  {
    "uniprot": "Q4CPR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPR1"
  },
  {
    "uniprot": "Q4DAC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAC4"
  },
  {
    "uniprot": "Q4CMA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMA5"
  },
  {
    "uniprot": "Q4DN26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN26"
  },
  {
    "uniprot": "Q4E3P0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3P0"
  },
  {
    "uniprot": "Q4DHX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHX7"
  },
  {
    "uniprot": "Q4E3B5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3B5"
  },
  {
    "uniprot": "Q4D595",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D595"
  },
  {
    "uniprot": "Q4DSV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSV0"
  },
  {
    "uniprot": "Q4DES4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DES4"
  },
  {
    "uniprot": "Q4E528",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E528"
  },
  {
    "uniprot": "Q4DKL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKL3"
  },
  {
    "uniprot": "Q4CVN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVN2"
  },
  {
    "uniprot": "Q4CZ86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ86"
  },
  {
    "uniprot": "Q4CP21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP21"
  },
  {
    "uniprot": "Q4E2F1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2F1"
  },
  {
    "uniprot": "Q4DWK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWK1"
  },
  {
    "uniprot": "Q4CTX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTX5"
  },
  {
    "uniprot": "Q4DAH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAH3"
  },
  {
    "uniprot": "Q4DYX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYX1"
  },
  {
    "uniprot": "Q4DP39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP39"
  },
  {
    "uniprot": "Q4CSS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSS7"
  },
  {
    "uniprot": "Q4E1U9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1U9"
  },
  {
    "uniprot": "Q4D522",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D522"
  },
  {
    "uniprot": "Q4D5B2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5B2"
  },
  {
    "uniprot": "Q4CMG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMG4"
  },
  {
    "uniprot": "Q4DZN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZN2"
  },
  {
    "uniprot": "Q4DFU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFU1"
  },
  {
    "uniprot": "Q4E1Q0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Q0"
  },
  {
    "uniprot": "Q4DQU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQU5"
  },
  {
    "uniprot": "Q4E505",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E505"
  },
  {
    "uniprot": "Q4DEB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEB4"
  },
  {
    "uniprot": "Q4CYG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYG3"
  },
  {
    "uniprot": "Q4CM68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM68"
  },
  {
    "uniprot": "Q4CUP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUP8"
  },
  {
    "uniprot": "Q4D8R9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8R9"
  },
  {
    "uniprot": "Q4DJ85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ85"
  },
  {
    "uniprot": "Q4DT19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT19"
  },
  {
    "uniprot": "Q4E445",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E445"
  },
  {
    "uniprot": "Q4D1U1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1U1"
  },
  {
    "uniprot": "Q4DRX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRX0"
  },
  {
    "uniprot": "Q4DSS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSS2"
  },
  {
    "uniprot": "Q4CWZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWZ8"
  },
  {
    "uniprot": "Q4CW26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW26"
  },
  {
    "uniprot": "Q4CMA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMA8"
  },
  {
    "uniprot": "Q4DJU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJU7"
  },
  {
    "uniprot": "Q4CVF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVF0"
  },
  {
    "uniprot": "Q4D1T7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1T7"
  },
  {
    "uniprot": "Q4DS96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS96"
  },
  {
    "uniprot": "Q4D147",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D147"
  },
  {
    "uniprot": "Q4DDK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDK2"
  },
  {
    "uniprot": "Q4DQ12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ12"
  },
  {
    "uniprot": "Q4CXV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXV7"
  },
  {
    "uniprot": "Q4E3W9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3W9"
  },
  {
    "uniprot": "Q4E2B6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2B6"
  },
  {
    "uniprot": "Q4CW42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW42"
  },
  {
    "uniprot": "Q4DZM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZM9"
  },
  {
    "uniprot": "Q4CTN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTN6"
  },
  {
    "uniprot": "Q4DWA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWA7"
  },
  {
    "uniprot": "Q4DP80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP80"
  },
  {
    "uniprot": "Q4DMY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMY0"
  },
  {
    "uniprot": "Q4CTZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTZ6"
  },
  {
    "uniprot": "Q4DCH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCH9"
  },
  {
    "uniprot": "Q4DE94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE94"
  },
  {
    "uniprot": "Q4CPJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPJ9"
  },
  {
    "uniprot": "Q4DIJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIJ0"
  },
  {
    "uniprot": "Q4CSB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSB2"
  },
  {
    "uniprot": "Q4CNQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNQ4"
  },
  {
    "uniprot": "Q4E5P1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5P1"
  },
  {
    "uniprot": "Q4DK62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK62"
  },
  {
    "uniprot": "Q4DMZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMZ1"
  },
  {
    "uniprot": "Q4CRM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRM2"
  },
  {
    "uniprot": "Q4DZ70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ70"
  },
  {
    "uniprot": "Q4DYN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYN0"
  },
  {
    "uniprot": "Q4DEB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEB8"
  },
  {
    "uniprot": "Q4DW74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW74"
  },
  {
    "uniprot": "Q4DJ79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ79"
  },
  {
    "uniprot": "Q4DDD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDD2"
  },
  {
    "uniprot": "Q4D3P5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3P5"
  },
  {
    "uniprot": "Q4CVF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVF2"
  },
  {
    "uniprot": "Q4DI05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI05"
  },
  {
    "uniprot": "Q4CPX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPX0"
  },
  {
    "uniprot": "Q4DXN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXN5"
  },
  {
    "uniprot": "Q4DW67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW67"
  },
  {
    "uniprot": "Q4E3G4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3G4"
  },
  {
    "uniprot": "Q4DRK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRK0"
  },
  {
    "uniprot": "Q4DDJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDJ2"
  },
  {
    "uniprot": "Q4CSC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSC6"
  },
  {
    "uniprot": "Q4E254",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E254"
  },
  {
    "uniprot": "Q4CNT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNT0"
  },
  {
    "uniprot": "Q4DQN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQN8"
  },
  {
    "uniprot": "Q4CRS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRS3"
  },
  {
    "uniprot": "Q4D1I5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1I5"
  },
  {
    "uniprot": "Q4DHP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHP6"
  },
  {
    "uniprot": "Q4D5B7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5B7"
  },
  {
    "uniprot": "Q4DYW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYW7"
  },
  {
    "uniprot": "Q4D290",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D290"
  },
  {
    "uniprot": "Q4D7F3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7F3"
  },
  {
    "uniprot": "Q4DY59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY59"
  },
  {
    "uniprot": "Q4CQ63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ63"
  },
  {
    "uniprot": "Q4D2S2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2S2"
  },
  {
    "uniprot": "Q4CNV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNV4"
  },
  {
    "uniprot": "Q4D0E9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0E9"
  },
  {
    "uniprot": "Q4E2X7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2X7"
  },
  {
    "uniprot": "Q4DIT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIT6"
  },
  {
    "uniprot": "Q4DVB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVB6"
  },
  {
    "uniprot": "Q4E1V3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1V3"
  },
  {
    "uniprot": "Q4E3P5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3P5"
  },
  {
    "uniprot": "Q4CU48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU48"
  },
  {
    "uniprot": "Q4DLZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLZ0"
  },
  {
    "uniprot": "Q4DRT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRT2"
  },
  {
    "uniprot": "Q4D0Y5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Y5"
  },
  {
    "uniprot": "Q4D7C2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7C2"
  },
  {
    "uniprot": "Q4D1U7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1U7"
  },
  {
    "uniprot": "Q4CSJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSJ8"
  },
  {
    "uniprot": "Q4CZM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZM2"
  },
  {
    "uniprot": "Q4D004",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D004"
  },
  {
    "uniprot": "Q4DDS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDS7"
  },
  {
    "uniprot": "Q4CSK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSK5"
  },
  {
    "uniprot": "Q4CL65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL65"
  },
  {
    "uniprot": "Q4DHL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHL2"
  },
  {
    "uniprot": "Q4CLV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLV9"
  },
  {
    "uniprot": "Q4CQF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQF0"
  },
  {
    "uniprot": "Q4E199",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E199"
  },
  {
    "uniprot": "Q4CLL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLL1"
  },
  {
    "uniprot": "Q4D6N7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6N7"
  },
  {
    "uniprot": "Q4E1A7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1A7"
  },
  {
    "uniprot": "Q4DV92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV92"
  },
  {
    "uniprot": "Q4DW97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW97"
  },
  {
    "uniprot": "Q4DRU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRU6"
  },
  {
    "uniprot": "Q4DRL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRL7"
  },
  {
    "uniprot": "Q4DJ12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ12"
  },
  {
    "uniprot": "Q4D5I8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5I8"
  },
  {
    "uniprot": "Q4D7J5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7J5"
  },
  {
    "uniprot": "Q4DM79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM79"
  },
  {
    "uniprot": "Q4E4R8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4R8"
  },
  {
    "uniprot": "Q4DKA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKA3"
  },
  {
    "uniprot": "Q4DSU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSU4"
  },
  {
    "uniprot": "Q4D8X9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8X9"
  },
  {
    "uniprot": "Q4CUP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUP4"
  },
  {
    "uniprot": "Q4DK16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK16"
  },
  {
    "uniprot": "Q4DU31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU31"
  },
  {
    "uniprot": "Q4E4Y8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Y8"
  },
  {
    "uniprot": "Q4CLP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLP7"
  },
  {
    "uniprot": "Q4D4L9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4L9"
  },
  {
    "uniprot": "Q4DCR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCR6"
  },
  {
    "uniprot": "Q4DZ28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ28"
  },
  {
    "uniprot": "Q4DJJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJJ4"
  },
  {
    "uniprot": "Q4DTL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTL4"
  },
  {
    "uniprot": "Q4CV43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV43"
  },
  {
    "uniprot": "Q4DF51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF51"
  },
  {
    "uniprot": "Q4DSJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSJ6"
  },
  {
    "uniprot": "Q4CY19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY19"
  },
  {
    "uniprot": "Q4D2L8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2L8"
  },
  {
    "uniprot": "Q4DA12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA12"
  },
  {
    "uniprot": "Q4CMA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMA6"
  },
  {
    "uniprot": "Q4E554",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E554"
  },
  {
    "uniprot": "Q4D4G3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4G3"
  },
  {
    "uniprot": "Q4D475",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D475"
  },
  {
    "uniprot": "Q4DFH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFH6"
  },
  {
    "uniprot": "Q4D1N0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1N0"
  },
  {
    "uniprot": "Q4DJZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJZ6"
  },
  {
    "uniprot": "Q4E456",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E456"
  },
  {
    "uniprot": "Q4D8U4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8U4"
  },
  {
    "uniprot": "Q4DLW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLW1"
  },
  {
    "uniprot": "Q4CN82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN82"
  },
  {
    "uniprot": "Q4D9J9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9J9"
  },
  {
    "uniprot": "Q4DTH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTH4"
  },
  {
    "uniprot": "Q4DFV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFV1"
  },
  {
    "uniprot": "Q4DPR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPR9"
  },
  {
    "uniprot": "Q4DKG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKG8"
  },
  {
    "uniprot": "Q4D672",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D672"
  },
  {
    "uniprot": "Q4D9I4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9I4"
  },
  {
    "uniprot": "Q4DED9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DED9"
  },
  {
    "uniprot": "Q4DSU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSU3"
  },
  {
    "uniprot": "Q4CRR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRR8"
  },
  {
    "uniprot": "Q4DMC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMC4"
  },
  {
    "uniprot": "Q4DQY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQY3"
  },
  {
    "uniprot": "Q4CNY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNY9"
  },
  {
    "uniprot": "Q4DMZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMZ7"
  },
  {
    "uniprot": "Q4D7Y8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Y8"
  },
  {
    "uniprot": "Q4DZQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZQ6"
  },
  {
    "uniprot": "Q4CZX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZX7"
  },
  {
    "uniprot": "Q4CZ50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ50"
  },
  {
    "uniprot": "Q4DKB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKB1"
  },
  {
    "uniprot": "Q4CZ84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ84"
  },
  {
    "uniprot": "Q4CX35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX35"
  },
  {
    "uniprot": "Q4CSI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSI4"
  },
  {
    "uniprot": "Q4DTE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTE1"
  },
  {
    "uniprot": "Q4CPS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPS2"
  },
  {
    "uniprot": "Q4DJG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJG8"
  },
  {
    "uniprot": "Q4DRQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRQ4"
  },
  {
    "uniprot": "Q4CQX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQX6"
  },
  {
    "uniprot": "Q4CU47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU47"
  },
  {
    "uniprot": "Q4CP87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP87"
  },
  {
    "uniprot": "Q4CTV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTV2"
  },
  {
    "uniprot": "Q4D5C7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5C7"
  },
  {
    "uniprot": "Q4E169",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E169"
  },
  {
    "uniprot": "Q4E342",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E342"
  },
  {
    "uniprot": "Q4CPW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPW8"
  },
  {
    "uniprot": "Q4DGJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGJ6"
  },
  {
    "uniprot": "Q4CSC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSC8"
  },
  {
    "uniprot": "Q4DMW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMW9"
  },
  {
    "uniprot": "Q4CRW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRW7"
  },
  {
    "uniprot": "Q4E257",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E257"
  },
  {
    "uniprot": "Q4D687",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D687"
  },
  {
    "uniprot": "Q4DT52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT52"
  },
  {
    "uniprot": "Q4DYV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYV6"
  },
  {
    "uniprot": "Q4DHA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHA2"
  },
  {
    "uniprot": "Q4CUY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUY8"
  },
  {
    "uniprot": "Q4DST3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DST3"
  },
  {
    "uniprot": "Q4D2F5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2F5"
  },
  {
    "uniprot": "Q4DC45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC45"
  },
  {
    "uniprot": "Q4CUU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUU9"
  },
  {
    "uniprot": "Q4DWU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWU5"
  },
  {
    "uniprot": "Q4DVN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVN8"
  },
  {
    "uniprot": "Q4DJX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJX3"
  },
  {
    "uniprot": "Q4D3Z8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Z8"
  },
  {
    "uniprot": "Q4CQ69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ69"
  },
  {
    "uniprot": "Q4DWR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWR1"
  },
  {
    "uniprot": "Q4DFE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFE2"
  },
  {
    "uniprot": "Q4DE19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE19"
  },
  {
    "uniprot": "Q4E5B0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5B0"
  },
  {
    "uniprot": "Q4DFD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFD9"
  },
  {
    "uniprot": "Q4DT68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT68"
  },
  {
    "uniprot": "Q4CV34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV34"
  },
  {
    "uniprot": "Q4D4U3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4U3"
  },
  {
    "uniprot": "Q4D432",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D432"
  },
  {
    "uniprot": "Q4CYN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYN2"
  },
  {
    "uniprot": "Q4CM33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM33"
  },
  {
    "uniprot": "Q4E559",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E559"
  },
  {
    "uniprot": "Q4DMD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMD4"
  },
  {
    "uniprot": "Q4DTC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTC7"
  },
  {
    "uniprot": "Q4E3G6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3G6"
  },
  {
    "uniprot": "Q4D5E3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5E3"
  },
  {
    "uniprot": "Q4CZA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZA7"
  },
  {
    "uniprot": "Q4DKN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKN9"
  },
  {
    "uniprot": "Q4DAD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAD3"
  },
  {
    "uniprot": "Q4CZU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZU9"
  },
  {
    "uniprot": "Q4D8Q7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Q7"
  },
  {
    "uniprot": "Q4CRY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRY6"
  },
  {
    "uniprot": "Q4D0Z0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Z0"
  },
  {
    "uniprot": "Q4CXW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXW0"
  },
  {
    "uniprot": "Q4CPG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPG3"
  },
  {
    "uniprot": "Q4CNL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNL3"
  },
  {
    "uniprot": "Q4CLE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLE5"
  },
  {
    "uniprot": "Q4DEY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEY6"
  },
  {
    "uniprot": "Q4CRL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRL1"
  },
  {
    "uniprot": "Q4CTA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTA0"
  },
  {
    "uniprot": "Q4CT85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT85"
  },
  {
    "uniprot": "Q4DA64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA64"
  },
  {
    "uniprot": "Q4DWP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWP9"
  },
  {
    "uniprot": "Q4D8W7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8W7"
  },
  {
    "uniprot": "Q4DRI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRI0"
  },
  {
    "uniprot": "Q4D5A4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5A4"
  },
  {
    "uniprot": "Q4DYI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYI9"
  },
  {
    "uniprot": "Q4DE50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE50"
  },
  {
    "uniprot": "Q4D760",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D760"
  },
  {
    "uniprot": "Q4CMW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMW3"
  },
  {
    "uniprot": "Q4E2J8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2J8"
  },
  {
    "uniprot": "Q4D780",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D780"
  },
  {
    "uniprot": "Q4DB52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB52"
  },
  {
    "uniprot": "Q4E564",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E564"
  },
  {
    "uniprot": "Q4DTZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTZ5"
  },
  {
    "uniprot": "Q4CKB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKB9"
  },
  {
    "uniprot": "Q4DIS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIS4"
  },
  {
    "uniprot": "Q4E4Y0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Y0"
  },
  {
    "uniprot": "Q4DYH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYH4"
  },
  {
    "uniprot": "Q4DUE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUE9"
  },
  {
    "uniprot": "Q4DK75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK75"
  },
  {
    "uniprot": "Q4E614",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E614"
  },
  {
    "uniprot": "Q4DGS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGS9"
  },
  {
    "uniprot": "Q4D0H7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0H7"
  },
  {
    "uniprot": "Q4CQ50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ50"
  },
  {
    "uniprot": "Q4E687",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E687"
  },
  {
    "uniprot": "Q4DS63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS63"
  },
  {
    "uniprot": "Q4DUT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUT1"
  },
  {
    "uniprot": "Q4DKF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKF0"
  },
  {
    "uniprot": "Q4DGA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGA4"
  },
  {
    "uniprot": "Q4DP31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP31"
  },
  {
    "uniprot": "Q4CMD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMD8"
  },
  {
    "uniprot": "Q4DIH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIH6"
  },
  {
    "uniprot": "Q4DA93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA93"
  },
  {
    "uniprot": "Q4CKG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKG6"
  },
  {
    "uniprot": "Q4DA77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA77"
  },
  {
    "uniprot": "Q4D2K5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2K5"
  },
  {
    "uniprot": "Q4DE34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE34"
  },
  {
    "uniprot": "Q4E1Z3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Z3"
  },
  {
    "uniprot": "Q4CY57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY57"
  },
  {
    "uniprot": "Q4DZ66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ66"
  },
  {
    "uniprot": "Q4CY39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY39"
  },
  {
    "uniprot": "Q4CTW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTW0"
  },
  {
    "uniprot": "Q4E2R0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2R0"
  },
  {
    "uniprot": "Q4E003",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E003"
  },
  {
    "uniprot": "Q4CWR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWR4"
  },
  {
    "uniprot": "Q4DE82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE82"
  },
  {
    "uniprot": "Q4CTU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTU0"
  },
  {
    "uniprot": "Q4DVG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVG3"
  },
  {
    "uniprot": "Q4DYU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYU2"
  },
  {
    "uniprot": "Q4CW33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW33"
  },
  {
    "uniprot": "Q4CPV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPV4"
  },
  {
    "uniprot": "Q4CM28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM28"
  },
  {
    "uniprot": "Q4DNK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNK1"
  },
  {
    "uniprot": "Q4DZI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZI5"
  },
  {
    "uniprot": "Q4DKN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKN2"
  },
  {
    "uniprot": "Q4DWU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWU2"
  },
  {
    "uniprot": "Q4CY27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY27"
  },
  {
    "uniprot": "Q4DZM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZM2"
  },
  {
    "uniprot": "Q4DTX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTX2"
  },
  {
    "uniprot": "Q4DBK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBK4"
  },
  {
    "uniprot": "Q4DKM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKM0"
  },
  {
    "uniprot": "Q4DDY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDY9"
  },
  {
    "uniprot": "Q4E620",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E620"
  },
  {
    "uniprot": "Q4D4G7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4G7"
  },
  {
    "uniprot": "Q4DRB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRB7"
  },
  {
    "uniprot": "Q4CZT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZT2"
  },
  {
    "uniprot": "Q4D4Y4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Y4"
  },
  {
    "uniprot": "Q4DQ42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ42"
  },
  {
    "uniprot": "Q4D8T7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8T7"
  },
  {
    "uniprot": "Q4E3I9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3I9"
  },
  {
    "uniprot": "Q4E2F9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2F9"
  },
  {
    "uniprot": "Q4DTX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTX1"
  },
  {
    "uniprot": "Q4D280",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D280"
  },
  {
    "uniprot": "Q4D8Q1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Q1"
  },
  {
    "uniprot": "Q4CKS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKS0"
  },
  {
    "uniprot": "Q4E173",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E173"
  },
  {
    "uniprot": "Q4DHX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHX2"
  },
  {
    "uniprot": "Q4E0F5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0F5"
  },
  {
    "uniprot": "Q4CRM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRM5"
  },
  {
    "uniprot": "Q4E2M5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2M5"
  },
  {
    "uniprot": "Q4D542",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D542"
  },
  {
    "uniprot": "Q4DS59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS59"
  },
  {
    "uniprot": "Q4DHI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHI8"
  },
  {
    "uniprot": "Q4E4C3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4C3"
  },
  {
    "uniprot": "Q4E034",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E034"
  },
  {
    "uniprot": "Q4DBV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBV3"
  },
  {
    "uniprot": "Q4DPE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPE8"
  },
  {
    "uniprot": "Q4DZS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZS7"
  },
  {
    "uniprot": "Q4CXN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXN3"
  },
  {
    "uniprot": "Q4D0F4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0F4"
  },
  {
    "uniprot": "Q4D3F3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3F3"
  },
  {
    "uniprot": "Q4CX08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX08"
  },
  {
    "uniprot": "Q4DM99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM99"
  },
  {
    "uniprot": "Q4DYR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYR3"
  },
  {
    "uniprot": "Q4CQU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQU2"
  },
  {
    "uniprot": "Q4E4E9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4E9"
  },
  {
    "uniprot": "Q4CPW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPW5"
  },
  {
    "uniprot": "Q4E4K8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4K8"
  },
  {
    "uniprot": "Q4DRQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRQ2"
  },
  {
    "uniprot": "Q4DCV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCV4"
  },
  {
    "uniprot": "Q4DK82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK82"
  },
  {
    "uniprot": "Q4DL50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL50"
  },
  {
    "uniprot": "Q4DLB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLB3"
  },
  {
    "uniprot": "Q4DCW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCW8"
  },
  {
    "uniprot": "Q4CLF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLF2"
  },
  {
    "uniprot": "Q4DNK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNK6"
  },
  {
    "uniprot": "Q4E1E1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1E1"
  },
  {
    "uniprot": "Q4E1B7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1B7"
  },
  {
    "uniprot": "Q4CUL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUL3"
  },
  {
    "uniprot": "Q4DEC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEC5"
  },
  {
    "uniprot": "Q4CSX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSX2"
  },
  {
    "uniprot": "Q4E4Z1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Z1"
  },
  {
    "uniprot": "Q4CZS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZS2"
  },
  {
    "uniprot": "Q4DFM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFM9"
  },
  {
    "uniprot": "Q4DA55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA55"
  },
  {
    "uniprot": "Q4DRE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRE3"
  },
  {
    "uniprot": "Q4DDC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDC9"
  },
  {
    "uniprot": "Q4CY41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY41"
  },
  {
    "uniprot": "Q4DNU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNU7"
  },
  {
    "uniprot": "Q4DE53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE53"
  },
  {
    "uniprot": "Q4DIW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIW8"
  },
  {
    "uniprot": "Q4DZ50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ50"
  },
  {
    "uniprot": "Q4DAB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAB1"
  },
  {
    "uniprot": "Q4DTW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTW3"
  },
  {
    "uniprot": "Q4CNQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNQ3"
  },
  {
    "uniprot": "Q4DFE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFE6"
  },
  {
    "uniprot": "Q4CQS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQS2"
  },
  {
    "uniprot": "Q4DXC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXC3"
  },
  {
    "uniprot": "Q4CMC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMC9"
  },
  {
    "uniprot": "Q4D625",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D625"
  },
  {
    "uniprot": "Q4CYT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYT4"
  },
  {
    "uniprot": "Q4D6B4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6B4"
  },
  {
    "uniprot": "Q4E1P2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1P2"
  },
  {
    "uniprot": "Q4DYV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYV1"
  },
  {
    "uniprot": "Q4DMX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMX5"
  },
  {
    "uniprot": "Q4DUQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUQ6"
  },
  {
    "uniprot": "Q4CQD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQD7"
  },
  {
    "uniprot": "Q4DMG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMG2"
  },
  {
    "uniprot": "Q4CRP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRP4"
  },
  {
    "uniprot": "Q4D088",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D088"
  },
  {
    "uniprot": "Q4CST8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CST8"
  },
  {
    "uniprot": "Q4DF32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF32"
  },
  {
    "uniprot": "Q4DTV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTV8"
  },
  {
    "uniprot": "Q4CTY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTY1"
  },
  {
    "uniprot": "Q4D0E1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0E1"
  },
  {
    "uniprot": "Q4E0G7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0G7"
  },
  {
    "uniprot": "Q4DXW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXW5"
  },
  {
    "uniprot": "Q4DDY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDY6"
  },
  {
    "uniprot": "Q4D0T2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0T2"
  },
  {
    "uniprot": "Q4DF37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF37"
  },
  {
    "uniprot": "Q4CWE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWE8"
  },
  {
    "uniprot": "Q4D3M5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3M5"
  },
  {
    "uniprot": "Q4CWM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWM6"
  },
  {
    "uniprot": "Q4DGI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGI5"
  },
  {
    "uniprot": "Q4DUK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUK9"
  },
  {
    "uniprot": "Q4CLY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLY5"
  },
  {
    "uniprot": "Q4E2Y2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Y2"
  },
  {
    "uniprot": "Q4E347",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E347"
  },
  {
    "uniprot": "Q4DPZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPZ4"
  },
  {
    "uniprot": "Q4CWT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWT5"
  },
  {
    "uniprot": "Q4E1J3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1J3"
  },
  {
    "uniprot": "Q4CS55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS55"
  },
  {
    "uniprot": "Q4DUR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUR0"
  },
  {
    "uniprot": "Q4DWY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWY3"
  },
  {
    "uniprot": "Q4DRH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRH5"
  },
  {
    "uniprot": "Q4CQZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQZ1"
  },
  {
    "uniprot": "Q4CM72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM72"
  },
  {
    "uniprot": "Q4DK12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK12"
  },
  {
    "uniprot": "Q4CQA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQA1"
  },
  {
    "uniprot": "Q4CLL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLL0"
  },
  {
    "uniprot": "Q4E201",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E201"
  },
  {
    "uniprot": "Q4DRP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRP0"
  },
  {
    "uniprot": "Q4DJ90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ90"
  },
  {
    "uniprot": "Q4E2I4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2I4"
  },
  {
    "uniprot": "Q4DBV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBV9"
  },
  {
    "uniprot": "Q4D8C7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8C7"
  },
  {
    "uniprot": "Q4E3C4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3C4"
  },
  {
    "uniprot": "Q4DK58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK58"
  },
  {
    "uniprot": "Q4DHC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHC0"
  },
  {
    "uniprot": "Q4DI87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI87"
  },
  {
    "uniprot": "Q4D5Y7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Y7"
  },
  {
    "uniprot": "Q4DIC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIC3"
  },
  {
    "uniprot": "Q4CL94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL94"
  },
  {
    "uniprot": "Q4D9Z5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Z5"
  },
  {
    "uniprot": "Q4D222",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D222"
  },
  {
    "uniprot": "Q4DII6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DII6"
  },
  {
    "uniprot": "Q4DYX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYX7"
  },
  {
    "uniprot": "Q4DTW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTW0"
  },
  {
    "uniprot": "Q4DRU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRU5"
  },
  {
    "uniprot": "Q4CVA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVA2"
  },
  {
    "uniprot": "Q4DCM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCM6"
  },
  {
    "uniprot": "Q4CTC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTC3"
  },
  {
    "uniprot": "Q4D495",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D495"
  },
  {
    "uniprot": "Q4CKM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKM7"
  },
  {
    "uniprot": "Q4CUF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUF5"
  },
  {
    "uniprot": "Q4DKF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKF3"
  },
  {
    "uniprot": "Q4E4D0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4D0"
  },
  {
    "uniprot": "Q4DFE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFE3"
  },
  {
    "uniprot": "Q4DR65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR65"
  },
  {
    "uniprot": "Q4CL68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL68"
  },
  {
    "uniprot": "Q4DRQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRQ7"
  },
  {
    "uniprot": "Q4DCJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCJ0"
  },
  {
    "uniprot": "Q4E0R3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0R3"
  },
  {
    "uniprot": "Q4DNZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNZ3"
  },
  {
    "uniprot": "Q4DXS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXS9"
  },
  {
    "uniprot": "Q4D429",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D429"
  },
  {
    "uniprot": "Q4CZL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZL0"
  },
  {
    "uniprot": "Q4E519",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E519"
  },
  {
    "uniprot": "Q4E596",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E596"
  },
  {
    "uniprot": "Q4E5X1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5X1"
  },
  {
    "uniprot": "Q4DR63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR63"
  },
  {
    "uniprot": "Q4DKI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKI3"
  },
  {
    "uniprot": "Q4E3I8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3I8"
  },
  {
    "uniprot": "Q4D9G4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9G4"
  },
  {
    "uniprot": "Q4CLA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLA9"
  },
  {
    "uniprot": "Q4D6V1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6V1"
  },
  {
    "uniprot": "Q4DC95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC95"
  },
  {
    "uniprot": "Q4DQ04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ04"
  },
  {
    "uniprot": "Q4DVG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVG0"
  },
  {
    "uniprot": "Q4DKD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKD9"
  },
  {
    "uniprot": "Q4D390",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D390"
  },
  {
    "uniprot": "Q4DIG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIG1"
  },
  {
    "uniprot": "Q4CYG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYG8"
  },
  {
    "uniprot": "Q4DRJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRJ1"
  },
  {
    "uniprot": "Q4DYF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYF2"
  },
  {
    "uniprot": "Q4DKM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKM5"
  },
  {
    "uniprot": "Q4D7Z8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Z8"
  },
  {
    "uniprot": "Q4CLQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLQ5"
  },
  {
    "uniprot": "Q4DRG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRG9"
  },
  {
    "uniprot": "Q4DP05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP05"
  },
  {
    "uniprot": "Q4E538",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E538"
  },
  {
    "uniprot": "Q4DFA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFA5"
  },
  {
    "uniprot": "Q4E307",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E307"
  },
  {
    "uniprot": "Q4D4U5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4U5"
  },
  {
    "uniprot": "Q4CQQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQQ9"
  },
  {
    "uniprot": "Q4CTB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTB8"
  },
  {
    "uniprot": "Q4D4E3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4E3"
  },
  {
    "uniprot": "Q4DAW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAW1"
  },
  {
    "uniprot": "Q4D681",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D681"
  },
  {
    "uniprot": "Q4DQL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQL7"
  },
  {
    "uniprot": "Q4DBU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBU6"
  },
  {
    "uniprot": "Q4D4M7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4M7"
  },
  {
    "uniprot": "Q4D8N1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8N1"
  },
  {
    "uniprot": "Q4D5F3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5F3"
  },
  {
    "uniprot": "Q4DG31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG31"
  },
  {
    "uniprot": "Q4CZJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZJ9"
  },
  {
    "uniprot": "Q4DEI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEI0"
  },
  {
    "uniprot": "Q4D6W3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6W3"
  },
  {
    "uniprot": "Q4D4B8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4B8"
  },
  {
    "uniprot": "Q4CYD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYD8"
  },
  {
    "uniprot": "Q4CTM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTM3"
  },
  {
    "uniprot": "Q4CUL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUL0"
  },
  {
    "uniprot": "Q4E5G0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5G0"
  },
  {
    "uniprot": "Q4DN51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN51"
  },
  {
    "uniprot": "Q4CYJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYJ3"
  },
  {
    "uniprot": "Q4D579",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D579"
  },
  {
    "uniprot": "Q4CYC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYC7"
  },
  {
    "uniprot": "Q4DAV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAV4"
  },
  {
    "uniprot": "Q4D6B5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6B5"
  },
  {
    "uniprot": "Q4DWP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWP4"
  },
  {
    "uniprot": "Q4DTM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTM0"
  },
  {
    "uniprot": "Q4DI58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI58"
  },
  {
    "uniprot": "Q4DZZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZZ8"
  },
  {
    "uniprot": "Q4DQJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQJ5"
  },
  {
    "uniprot": "Q4E1Q9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Q9"
  },
  {
    "uniprot": "Q4E5W1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5W1"
  },
  {
    "uniprot": "Q4DXY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXY0"
  },
  {
    "uniprot": "Q4CX44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX44"
  },
  {
    "uniprot": "Q4DRQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRQ3"
  },
  {
    "uniprot": "Q4D7H3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7H3"
  },
  {
    "uniprot": "Q4DUY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUY6"
  },
  {
    "uniprot": "Q4CTH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTH0"
  },
  {
    "uniprot": "Q4D6C6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6C6"
  },
  {
    "uniprot": "Q4E0F3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0F3"
  },
  {
    "uniprot": "Q4D4T8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4T8"
  },
  {
    "uniprot": "Q4DQE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQE1"
  },
  {
    "uniprot": "Q4E0K4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0K4"
  },
  {
    "uniprot": "Q4DAV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAV3"
  },
  {
    "uniprot": "Q4CVI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVI4"
  },
  {
    "uniprot": "Q4DNQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNQ4"
  },
  {
    "uniprot": "Q4CRU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRU8"
  },
  {
    "uniprot": "Q4DJ43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ43"
  },
  {
    "uniprot": "Q4E2W6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2W6"
  },
  {
    "uniprot": "Q4D9F7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9F7"
  },
  {
    "uniprot": "Q4DAT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAT8"
  },
  {
    "uniprot": "Q4D0E3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0E3"
  },
  {
    "uniprot": "Q4DF42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF42"
  },
  {
    "uniprot": "Q4CSU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSU5"
  },
  {
    "uniprot": "Q4D105",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D105"
  },
  {
    "uniprot": "Q4CYP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYP8"
  },
  {
    "uniprot": "Q4DSY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSY2"
  },
  {
    "uniprot": "Q4DF07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF07"
  },
  {
    "uniprot": "Q4D0N4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0N4"
  },
  {
    "uniprot": "Q4DLR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLR8"
  },
  {
    "uniprot": "Q4DU20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU20"
  },
  {
    "uniprot": "Q4CXH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXH5"
  },
  {
    "uniprot": "Q4CLW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLW7"
  },
  {
    "uniprot": "Q4D557",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D557"
  },
  {
    "uniprot": "Q4E0T1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0T1"
  },
  {
    "uniprot": "Q4CP86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP86"
  },
  {
    "uniprot": "Q4CS50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS50"
  },
  {
    "uniprot": "Q4DY70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY70"
  },
  {
    "uniprot": "Q4D7R7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7R7"
  },
  {
    "uniprot": "Q4CVY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVY4"
  },
  {
    "uniprot": "Q4DWY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWY1"
  },
  {
    "uniprot": "Q4CPG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPG8"
  },
  {
    "uniprot": "Q4D5I7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5I7"
  },
  {
    "uniprot": "Q4CR15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR15"
  },
  {
    "uniprot": "Q4DNC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNC3"
  },
  {
    "uniprot": "Q4D4T4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4T4"
  },
  {
    "uniprot": "Q4DFN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFN8"
  },
  {
    "uniprot": "Q4DH57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH57"
  },
  {
    "uniprot": "Q4DEB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEB6"
  },
  {
    "uniprot": "Q4CTG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTG2"
  },
  {
    "uniprot": "Q4DJ69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ69"
  },
  {
    "uniprot": "Q4DQN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQN3"
  },
  {
    "uniprot": "Q4CUN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUN5"
  },
  {
    "uniprot": "Q4DPB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPB1"
  },
  {
    "uniprot": "Q4CN27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN27"
  },
  {
    "uniprot": "Q4E0C9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0C9"
  },
  {
    "uniprot": "Q4DFW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFW2"
  },
  {
    "uniprot": "Q4DHA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHA5"
  },
  {
    "uniprot": "Q4D3E8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3E8"
  },
  {
    "uniprot": "Q4DBU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBU0"
  },
  {
    "uniprot": "Q4D9Z0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Z0"
  },
  {
    "uniprot": "Q4DUU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUU1"
  },
  {
    "uniprot": "Q4DGR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGR8"
  },
  {
    "uniprot": "Q4DCN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCN7"
  },
  {
    "uniprot": "Q4CZS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZS5"
  },
  {
    "uniprot": "Q4E5Q9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Q9"
  },
  {
    "uniprot": "Q4DBP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBP4"
  },
  {
    "uniprot": "Q4D803",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D803"
  },
  {
    "uniprot": "Q4DM48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM48"
  },
  {
    "uniprot": "Q4CYF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYF4"
  },
  {
    "uniprot": "Q4DEY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEY1"
  },
  {
    "uniprot": "Q4CKL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKL6"
  },
  {
    "uniprot": "Q4D6T4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6T4"
  },
  {
    "uniprot": "Q4E0V8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0V8"
  },
  {
    "uniprot": "Q4CU90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU90"
  },
  {
    "uniprot": "Q4CL16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL16"
  },
  {
    "uniprot": "Q4DHH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHH0"
  },
  {
    "uniprot": "Q4CQZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQZ5"
  },
  {
    "uniprot": "Q4CYV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYV1"
  },
  {
    "uniprot": "Q4E119",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E119"
  },
  {
    "uniprot": "Q4E515",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E515"
  },
  {
    "uniprot": "Q4DF77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF77"
  },
  {
    "uniprot": "Q4CKG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKG3"
  },
  {
    "uniprot": "Q4E241",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E241"
  },
  {
    "uniprot": "Q4CXK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXK7"
  },
  {
    "uniprot": "Q4D5W9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5W9"
  },
  {
    "uniprot": "Q4DHW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHW8"
  },
  {
    "uniprot": "Q4DK61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK61"
  },
  {
    "uniprot": "Q4D985",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D985"
  },
  {
    "uniprot": "Q4E362",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E362"
  },
  {
    "uniprot": "Q4D795",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D795"
  },
  {
    "uniprot": "Q4DZS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZS9"
  },
  {
    "uniprot": "Q4CUK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUK5"
  },
  {
    "uniprot": "Q4E0B7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0B7"
  },
  {
    "uniprot": "Q4CM88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM88"
  },
  {
    "uniprot": "Q4CVD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVD0"
  },
  {
    "uniprot": "Q4CQ38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ38"
  },
  {
    "uniprot": "Q4DYR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYR0"
  },
  {
    "uniprot": "Q4CL27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL27"
  },
  {
    "uniprot": "Q4DQR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQR2"
  },
  {
    "uniprot": "Q4D3D3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3D3"
  },
  {
    "uniprot": "Q4DJ67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ67"
  },
  {
    "uniprot": "Q4DXI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXI4"
  },
  {
    "uniprot": "Q4DFI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFI7"
  },
  {
    "uniprot": "Q4CMW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMW2"
  },
  {
    "uniprot": "Q4CLW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLW8"
  },
  {
    "uniprot": "Q4D7A2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7A2"
  },
  {
    "uniprot": "Q4CL33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL33"
  },
  {
    "uniprot": "Q4CTY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTY6"
  },
  {
    "uniprot": "Q4D5F2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5F2"
  },
  {
    "uniprot": "Q4DZ23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ23"
  },
  {
    "uniprot": "Q4DV29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV29"
  },
  {
    "uniprot": "Q4CXF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXF6"
  },
  {
    "uniprot": "Q4D5T5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5T5"
  },
  {
    "uniprot": "Q4CQ10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ10"
  },
  {
    "uniprot": "Q4E520",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E520"
  },
  {
    "uniprot": "Q4DAP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAP4"
  },
  {
    "uniprot": "Q4DBF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBF8"
  },
  {
    "uniprot": "Q4D3E6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3E6"
  },
  {
    "uniprot": "Q4DIR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIR0"
  },
  {
    "uniprot": "Q4DC43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC43"
  },
  {
    "uniprot": "Q4CR07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR07"
  },
  {
    "uniprot": "Q4E3W8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3W8"
  },
  {
    "uniprot": "Q4DUY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUY7"
  },
  {
    "uniprot": "Q4D4A6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4A6"
  },
  {
    "uniprot": "Q4DZH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZH0"
  },
  {
    "uniprot": "Q4E0Z5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Z5"
  },
  {
    "uniprot": "Q4DDK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDK3"
  },
  {
    "uniprot": "Q4E289",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E289"
  },
  {
    "uniprot": "Q4DT92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT92"
  },
  {
    "uniprot": "Q4DIT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIT4"
  },
  {
    "uniprot": "Q4CSG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSG3"
  },
  {
    "uniprot": "Q4E1Z2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Z2"
  },
  {
    "uniprot": "Q4CL32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL32"
  },
  {
    "uniprot": "Q4E3H7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3H7"
  },
  {
    "uniprot": "Q4CVT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVT2"
  },
  {
    "uniprot": "Q4D6F6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6F6"
  },
  {
    "uniprot": "Q4CWB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWB8"
  },
  {
    "uniprot": "Q4DBY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBY4"
  },
  {
    "uniprot": "Q4E1X1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1X1"
  },
  {
    "uniprot": "Q4CVQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVQ4"
  },
  {
    "uniprot": "Q4DIB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIB2"
  },
  {
    "uniprot": "Q4DY33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY33"
  },
  {
    "uniprot": "Q4D235",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D235"
  },
  {
    "uniprot": "Q4DFC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFC5"
  },
  {
    "uniprot": "Q4D524",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D524"
  },
  {
    "uniprot": "Q4D0A6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0A6"
  },
  {
    "uniprot": "Q4CWQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWQ8"
  },
  {
    "uniprot": "Q4DAV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAV9"
  },
  {
    "uniprot": "Q4CTU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTU4"
  },
  {
    "uniprot": "Q4DHA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHA9"
  },
  {
    "uniprot": "Q4DGQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGQ5"
  },
  {
    "uniprot": "Q4D9B4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9B4"
  },
  {
    "uniprot": "Q4CU24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU24"
  },
  {
    "uniprot": "Q4D373",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D373"
  },
  {
    "uniprot": "Q4E193",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E193"
  },
  {
    "uniprot": "Q4DH89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH89"
  },
  {
    "uniprot": "Q4CZR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZR7"
  },
  {
    "uniprot": "Q4DIR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIR8"
  },
  {
    "uniprot": "Q4DHL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHL5"
  },
  {
    "uniprot": "Q4D6R8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6R8"
  },
  {
    "uniprot": "Q4E2T2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2T2"
  },
  {
    "uniprot": "Q4DM54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM54"
  },
  {
    "uniprot": "Q4DQY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQY5"
  },
  {
    "uniprot": "Q4E3I3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3I3"
  },
  {
    "uniprot": "Q4D8W6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8W6"
  },
  {
    "uniprot": "Q4CNR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNR3"
  },
  {
    "uniprot": "Q4DI46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI46"
  },
  {
    "uniprot": "Q4CX73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX73"
  },
  {
    "uniprot": "Q4DER2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DER2"
  },
  {
    "uniprot": "Q4CV32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV32"
  },
  {
    "uniprot": "Q4CWK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWK8"
  },
  {
    "uniprot": "Q4CTP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTP3"
  },
  {
    "uniprot": "Q4DCX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCX2"
  },
  {
    "uniprot": "Q4CZT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZT6"
  },
  {
    "uniprot": "Q4DD90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD90"
  },
  {
    "uniprot": "Q4D7X6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7X6"
  },
  {
    "uniprot": "Q4CXA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXA9"
  },
  {
    "uniprot": "Q4E1I7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1I7"
  },
  {
    "uniprot": "Q4D7N6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7N6"
  },
  {
    "uniprot": "Q4D5R2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5R2"
  },
  {
    "uniprot": "Q4DF97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF97"
  },
  {
    "uniprot": "Q4DTK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTK5"
  },
  {
    "uniprot": "Q4DRT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRT1"
  },
  {
    "uniprot": "Q4CY58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY58"
  },
  {
    "uniprot": "Q4CNR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNR8"
  },
  {
    "uniprot": "Q4E630",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E630"
  },
  {
    "uniprot": "Q4DLG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLG6"
  },
  {
    "uniprot": "Q4D132",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D132"
  },
  {
    "uniprot": "Q4D8E4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8E4"
  },
  {
    "uniprot": "Q4DS85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS85"
  },
  {
    "uniprot": "Q4DW09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW09"
  },
  {
    "uniprot": "Q4D6R6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6R6"
  },
  {
    "uniprot": "Q4DVW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVW9"
  },
  {
    "uniprot": "Q4DDX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDX3"
  },
  {
    "uniprot": "Q4D6K6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6K6"
  },
  {
    "uniprot": "Q4E175",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E175"
  },
  {
    "uniprot": "Q4CUG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUG0"
  },
  {
    "uniprot": "Q4D008",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D008"
  },
  {
    "uniprot": "Q4CS11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS11"
  },
  {
    "uniprot": "Q4DNZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNZ4"
  },
  {
    "uniprot": "Q4DSF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSF0"
  },
  {
    "uniprot": "Q4DAQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAQ8"
  },
  {
    "uniprot": "Q4E5T4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5T4"
  },
  {
    "uniprot": "Q4DAS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAS4"
  },
  {
    "uniprot": "Q4DQ51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ51"
  },
  {
    "uniprot": "Q4DNG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNG5"
  },
  {
    "uniprot": "Q4DUW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUW4"
  },
  {
    "uniprot": "Q4CP33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP33"
  },
  {
    "uniprot": "Q4CPY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPY6"
  },
  {
    "uniprot": "Q4CMG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMG0"
  },
  {
    "uniprot": "Q4DW71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW71"
  },
  {
    "uniprot": "Q4DZM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZM4"
  },
  {
    "uniprot": "Q4DAI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAI4"
  },
  {
    "uniprot": "Q4DNC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNC4"
  },
  {
    "uniprot": "Q4DB26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB26"
  },
  {
    "uniprot": "Q4CTQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTQ5"
  },
  {
    "uniprot": "Q4DZW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZW6"
  },
  {
    "uniprot": "Q4E4F3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4F3"
  },
  {
    "uniprot": "Q4DEU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEU0"
  },
  {
    "uniprot": "Q4E2I0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2I0"
  },
  {
    "uniprot": "Q4DFK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFK8"
  },
  {
    "uniprot": "Q4CYS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYS1"
  },
  {
    "uniprot": "Q4DPT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPT4"
  },
  {
    "uniprot": "Q4D5P0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5P0"
  },
  {
    "uniprot": "Q4E0I5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0I5"
  },
  {
    "uniprot": "Q4CWS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWS9"
  },
  {
    "uniprot": "Q4DUT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUT3"
  },
  {
    "uniprot": "Q4DEQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEQ5"
  },
  {
    "uniprot": "Q4DDI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDI9"
  },
  {
    "uniprot": "Q4CNM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNM2"
  },
  {
    "uniprot": "Q4D585",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D585"
  },
  {
    "uniprot": "Q4DMH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMH4"
  },
  {
    "uniprot": "Q4DN27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN27"
  },
  {
    "uniprot": "Q4DJS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJS6"
  },
  {
    "uniprot": "Q4CW78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW78"
  },
  {
    "uniprot": "Q4DCH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCH0"
  },
  {
    "uniprot": "Q4E579",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E579"
  },
  {
    "uniprot": "Q4E2W0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2W0"
  },
  {
    "uniprot": "Q4D829",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D829"
  },
  {
    "uniprot": "Q4DUL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUL3"
  },
  {
    "uniprot": "Q4DRN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRN5"
  },
  {
    "uniprot": "Q4CSX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSX6"
  },
  {
    "uniprot": "Q4E0F9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0F9"
  },
  {
    "uniprot": "Q4D1U3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1U3"
  },
  {
    "uniprot": "Q4DN06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN06"
  },
  {
    "uniprot": "Q4DJY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJY0"
  },
  {
    "uniprot": "Q4E1M4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1M4"
  },
  {
    "uniprot": "Q4CZJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZJ4"
  },
  {
    "uniprot": "Q4DLH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLH2"
  },
  {
    "uniprot": "Q4CNM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNM7"
  },
  {
    "uniprot": "Q4DTU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTU2"
  },
  {
    "uniprot": "Q4DZU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZU3"
  },
  {
    "uniprot": "Q4D4R4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4R4"
  },
  {
    "uniprot": "Q4E4Z6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Z6"
  },
  {
    "uniprot": "Q4CN79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN79"
  },
  {
    "uniprot": "Q4DFR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFR7"
  },
  {
    "uniprot": "Q4CVV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVV4"
  },
  {
    "uniprot": "Q4D5X5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5X5"
  },
  {
    "uniprot": "Q4DMT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMT3"
  },
  {
    "uniprot": "Q4DF67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF67"
  },
  {
    "uniprot": "Q4E0X9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0X9"
  },
  {
    "uniprot": "Q4D6Z5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Z5"
  },
  {
    "uniprot": "Q4CMY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMY4"
  },
  {
    "uniprot": "Q4D9R8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9R8"
  },
  {
    "uniprot": "Q4DKE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKE3"
  },
  {
    "uniprot": "Q4D0L6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0L6"
  },
  {
    "uniprot": "Q4DG37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG37"
  },
  {
    "uniprot": "Q4D7G6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7G6"
  },
  {
    "uniprot": "Q4DFD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFD6"
  },
  {
    "uniprot": "Q4DHC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHC7"
  },
  {
    "uniprot": "Q4D572",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D572"
  },
  {
    "uniprot": "Q4CQ89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ89"
  },
  {
    "uniprot": "Q4D548",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D548"
  },
  {
    "uniprot": "Q4D4W3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4W3"
  },
  {
    "uniprot": "Q4CX02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX02"
  },
  {
    "uniprot": "Q4DZ32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ32"
  },
  {
    "uniprot": "Q4DEX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEX1"
  },
  {
    "uniprot": "Q4CSC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSC4"
  },
  {
    "uniprot": "Q4DTJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTJ5"
  },
  {
    "uniprot": "Q4CU58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU58"
  },
  {
    "uniprot": "Q4D093",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D093"
  },
  {
    "uniprot": "Q4E5I2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5I2"
  },
  {
    "uniprot": "Q4DTN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTN2"
  },
  {
    "uniprot": "Q4D6J4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6J4"
  },
  {
    "uniprot": "Q4E1H9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1H9"
  },
  {
    "uniprot": "Q4E361",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E361"
  },
  {
    "uniprot": "Q4DKW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKW0"
  },
  {
    "uniprot": "Q4DSD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSD5"
  },
  {
    "uniprot": "Q4CMC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMC0"
  },
  {
    "uniprot": "Q4D0E4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0E4"
  },
  {
    "uniprot": "Q4CRK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRK2"
  },
  {
    "uniprot": "Q4DCE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCE3"
  },
  {
    "uniprot": "Q4D3I4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3I4"
  },
  {
    "uniprot": "Q4DXD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXD2"
  },
  {
    "uniprot": "Q4D471",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D471"
  },
  {
    "uniprot": "Q4D3Y5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Y5"
  },
  {
    "uniprot": "Q4E688",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E688"
  },
  {
    "uniprot": "Q4E4T2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4T2"
  },
  {
    "uniprot": "Q4DY41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY41"
  },
  {
    "uniprot": "Q4DX32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX32"
  },
  {
    "uniprot": "Q4DT11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT11"
  },
  {
    "uniprot": "Q4D874",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D874"
  },
  {
    "uniprot": "Q4D4N1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4N1"
  },
  {
    "uniprot": "Q4D452",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D452"
  },
  {
    "uniprot": "Q4CRQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRQ2"
  },
  {
    "uniprot": "Q4DFY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFY5"
  },
  {
    "uniprot": "Q868H8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q868H8"
  },
  {
    "uniprot": "Q4CKA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKA5"
  },
  {
    "uniprot": "Q4E1C1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1C1"
  },
  {
    "uniprot": "Q4CYJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYJ4"
  },
  {
    "uniprot": "Q4DZ57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ57"
  },
  {
    "uniprot": "Q4D488",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D488"
  },
  {
    "uniprot": "Q4E463",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E463"
  },
  {
    "uniprot": "Q4E4P4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4P4"
  },
  {
    "uniprot": "Q4CRU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRU2"
  },
  {
    "uniprot": "Q4CP11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP11"
  },
  {
    "uniprot": "Q4DCQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCQ2"
  },
  {
    "uniprot": "Q4D6S5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6S5"
  },
  {
    "uniprot": "Q4DCP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCP5"
  },
  {
    "uniprot": "Q4DTJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTJ6"
  },
  {
    "uniprot": "Q4D7R6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7R6"
  },
  {
    "uniprot": "Q4D8F0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8F0"
  },
  {
    "uniprot": "Q4DMU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMU3"
  },
  {
    "uniprot": "Q4D6G6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6G6"
  },
  {
    "uniprot": "Q4CTI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTI4"
  },
  {
    "uniprot": "Q4DDW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDW6"
  },
  {
    "uniprot": "Q4DU58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU58"
  },
  {
    "uniprot": "Q4DL16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL16"
  },
  {
    "uniprot": "Q4DM74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM74"
  },
  {
    "uniprot": "Q4DMP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMP2"
  },
  {
    "uniprot": "Q4DZI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZI7"
  },
  {
    "uniprot": "Q4DF55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF55"
  },
  {
    "uniprot": "Q4DLX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLX3"
  },
  {
    "uniprot": "Q4E0N0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0N0"
  },
  {
    "uniprot": "Q4DXM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXM8"
  },
  {
    "uniprot": "Q4CQQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQQ6"
  },
  {
    "uniprot": "Q4CVK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVK0"
  },
  {
    "uniprot": "Q4E5Q6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Q6"
  },
  {
    "uniprot": "Q4D509",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D509"
  },
  {
    "uniprot": "Q4CVC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVC6"
  },
  {
    "uniprot": "Q4DF00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF00"
  },
  {
    "uniprot": "Q4E673",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E673"
  },
  {
    "uniprot": "Q4CSN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSN5"
  },
  {
    "uniprot": "Q4DBU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBU7"
  },
  {
    "uniprot": "Q4DZX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZX5"
  },
  {
    "uniprot": "Q4D426",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D426"
  },
  {
    "uniprot": "Q4CUT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUT5"
  },
  {
    "uniprot": "Q4D0E6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0E6"
  },
  {
    "uniprot": "Q4CTP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTP6"
  },
  {
    "uniprot": "Q4CL49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL49"
  },
  {
    "uniprot": "Q4DJA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJA3"
  },
  {
    "uniprot": "Q4DMJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMJ5"
  },
  {
    "uniprot": "Q4CMY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMY0"
  },
  {
    "uniprot": "Q4DZV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZV1"
  },
  {
    "uniprot": "Q4DX83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX83"
  },
  {
    "uniprot": "Q4DWM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWM7"
  },
  {
    "uniprot": "Q4E4E5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4E5"
  },
  {
    "uniprot": "Q4D7D3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7D3"
  },
  {
    "uniprot": "Q4D9F5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9F5"
  },
  {
    "uniprot": "Q4E0D9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0D9"
  },
  {
    "uniprot": "Q4DFS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFS5"
  },
  {
    "uniprot": "Q4DT93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT93"
  },
  {
    "uniprot": "Q4DZI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZI2"
  },
  {
    "uniprot": "Q4CY52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY52"
  },
  {
    "uniprot": "Q4CWH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWH2"
  },
  {
    "uniprot": "Q4CXK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXK2"
  },
  {
    "uniprot": "Q4DZE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZE6"
  },
  {
    "uniprot": "Q4DDB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDB3"
  },
  {
    "uniprot": "Q4D083",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D083"
  },
  {
    "uniprot": "Q4DYX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYX9"
  },
  {
    "uniprot": "Q4D394",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D394"
  },
  {
    "uniprot": "Q4D583",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D583"
  },
  {
    "uniprot": "Q4DTJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTJ1"
  },
  {
    "uniprot": "Q4CUZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUZ8"
  },
  {
    "uniprot": "Q4DYE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYE4"
  },
  {
    "uniprot": "Q4DJP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJP9"
  },
  {
    "uniprot": "Q4DQP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQP4"
  },
  {
    "uniprot": "Q4E0T6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0T6"
  },
  {
    "uniprot": "Q4DWT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWT5"
  },
  {
    "uniprot": "Q4CTI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTI5"
  },
  {
    "uniprot": "Q4CPW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPW3"
  },
  {
    "uniprot": "Q4DSQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSQ0"
  },
  {
    "uniprot": "Q4DQ43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ43"
  },
  {
    "uniprot": "Q4D8W2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8W2"
  },
  {
    "uniprot": "Q4DFS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFS3"
  },
  {
    "uniprot": "Q4D5C9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5C9"
  },
  {
    "uniprot": "Q4CSS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSS0"
  },
  {
    "uniprot": "Q4DJT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJT6"
  },
  {
    "uniprot": "Q4DQC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQC7"
  },
  {
    "uniprot": "Q4E351",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E351"
  },
  {
    "uniprot": "Q4CM18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM18"
  },
  {
    "uniprot": "Q4DVD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVD5"
  },
  {
    "uniprot": "Q4E0Y8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Y8"
  },
  {
    "uniprot": "Q4DV33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV33"
  },
  {
    "uniprot": "Q4DPY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPY3"
  },
  {
    "uniprot": "Q4D852",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D852"
  },
  {
    "uniprot": "Q4DD25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD25"
  },
  {
    "uniprot": "Q4CKC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKC7"
  },
  {
    "uniprot": "Q4DNV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNV2"
  },
  {
    "uniprot": "Q4DQJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQJ3"
  },
  {
    "uniprot": "Q4DJH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJH1"
  },
  {
    "uniprot": "Q4D6J8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6J8"
  },
  {
    "uniprot": "Q4DED6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DED6"
  },
  {
    "uniprot": "Q4DAD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAD0"
  },
  {
    "uniprot": "Q4CYU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYU7"
  },
  {
    "uniprot": "Q4CW54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW54"
  },
  {
    "uniprot": "Q4CUQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUQ9"
  },
  {
    "uniprot": "Q4DGM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGM6"
  },
  {
    "uniprot": "Q4DCV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCV9"
  },
  {
    "uniprot": "Q4CSB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSB6"
  },
  {
    "uniprot": "Q4D8U9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8U9"
  },
  {
    "uniprot": "Q4E1E3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1E3"
  },
  {
    "uniprot": "Q4DI54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI54"
  },
  {
    "uniprot": "Q4CMF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMF4"
  },
  {
    "uniprot": "Q4CP42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP42"
  },
  {
    "uniprot": "Q4DH39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH39"
  },
  {
    "uniprot": "Q4CUH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUH9"
  },
  {
    "uniprot": "Q4D075",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D075"
  },
  {
    "uniprot": "Q4CXJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXJ4"
  },
  {
    "uniprot": "Q4E0H9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0H9"
  },
  {
    "uniprot": "Q4E3X7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3X7"
  },
  {
    "uniprot": "Q4E153",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E153"
  },
  {
    "uniprot": "Q4DIS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIS0"
  },
  {
    "uniprot": "Q4DIY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIY4"
  },
  {
    "uniprot": "Q4DZK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZK8"
  },
  {
    "uniprot": "Q4DI63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI63"
  },
  {
    "uniprot": "Q4DVA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVA0"
  },
  {
    "uniprot": "Q4E5T3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5T3"
  },
  {
    "uniprot": "Q4E3A6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3A6"
  },
  {
    "uniprot": "Q4CUG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUG3"
  },
  {
    "uniprot": "Q4DA72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA72"
  },
  {
    "uniprot": "Q4DSA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSA1"
  },
  {
    "uniprot": "Q4D7E8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7E8"
  },
  {
    "uniprot": "Q4E116",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E116"
  },
  {
    "uniprot": "Q4D8K9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8K9"
  },
  {
    "uniprot": "Q4DP97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP97"
  },
  {
    "uniprot": "Q4D0E2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0E2"
  },
  {
    "uniprot": "Q4DZ49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ49"
  },
  {
    "uniprot": "Q4CX37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX37"
  },
  {
    "uniprot": "Q4DPL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPL6"
  },
  {
    "uniprot": "Q4CTS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTS0"
  },
  {
    "uniprot": "Q4CPL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPL8"
  },
  {
    "uniprot": "Q4E4C7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4C7"
  },
  {
    "uniprot": "Q4CLG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLG6"
  },
  {
    "uniprot": "Q4E5P5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5P5"
  },
  {
    "uniprot": "Q4DVY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVY5"
  },
  {
    "uniprot": "Q4E561",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E561"
  },
  {
    "uniprot": "Q4CZ61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ61"
  },
  {
    "uniprot": "Q4CTZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTZ7"
  },
  {
    "uniprot": "Q4D2P5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2P5"
  },
  {
    "uniprot": "Q4CV66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV66"
  },
  {
    "uniprot": "Q4CL69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL69"
  },
  {
    "uniprot": "Q4DFK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFK5"
  },
  {
    "uniprot": "Q4DC00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC00"
  },
  {
    "uniprot": "Q4D0V8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0V8"
  },
  {
    "uniprot": "Q4DE20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE20"
  },
  {
    "uniprot": "Q4DQK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQK3"
  },
  {
    "uniprot": "Q4CKQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKQ6"
  },
  {
    "uniprot": "Q4E449",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E449"
  },
  {
    "uniprot": "Q4CRA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRA8"
  },
  {
    "uniprot": "Q4DKU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKU5"
  },
  {
    "uniprot": "Q4E2V3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2V3"
  },
  {
    "uniprot": "Q4DUS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUS6"
  },
  {
    "uniprot": "Q4E531",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E531"
  },
  {
    "uniprot": "Q4D6A3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6A3"
  },
  {
    "uniprot": "Q4CTC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTC8"
  },
  {
    "uniprot": "Q4CNN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNN0"
  },
  {
    "uniprot": "Q4DUI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUI3"
  },
  {
    "uniprot": "Q4DW98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW98"
  },
  {
    "uniprot": "Q4D2Q5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Q5"
  },
  {
    "uniprot": "Q4DSB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSB4"
  },
  {
    "uniprot": "Q4D510",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D510"
  },
  {
    "uniprot": "Q4DHA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHA6"
  },
  {
    "uniprot": "Q4DX44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX44"
  },
  {
    "uniprot": "Q4D9C2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9C2"
  },
  {
    "uniprot": "Q4DJG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJG4"
  },
  {
    "uniprot": "Q4D5P2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5P2"
  },
  {
    "uniprot": "Q4CUM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUM5"
  },
  {
    "uniprot": "Q4DER7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DER7"
  },
  {
    "uniprot": "Q4D6U3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6U3"
  },
  {
    "uniprot": "Q4DZK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZK4"
  },
  {
    "uniprot": "Q4CNW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNW2"
  },
  {
    "uniprot": "Q4E604",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E604"
  },
  {
    "uniprot": "Q4D3J1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3J1"
  },
  {
    "uniprot": "Q4E5U9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5U9"
  },
  {
    "uniprot": "Q4E1A6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1A6"
  },
  {
    "uniprot": "Q4D6S3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6S3"
  },
  {
    "uniprot": "Q4CSA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSA9"
  },
  {
    "uniprot": "Q4E4Z7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Z7"
  },
  {
    "uniprot": "Q4E5L6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5L6"
  },
  {
    "uniprot": "Q4CX07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX07"
  },
  {
    "uniprot": "Q4DQI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQI0"
  },
  {
    "uniprot": "Q4CSU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSU6"
  },
  {
    "uniprot": "Q4E3R3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3R3"
  },
  {
    "uniprot": "Q4E4D3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4D3"
  },
  {
    "uniprot": "Q4DGM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGM5"
  },
  {
    "uniprot": "Q4E063",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E063"
  },
  {
    "uniprot": "Q4CKF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKF4"
  },
  {
    "uniprot": "Q4DCN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCN2"
  },
  {
    "uniprot": "Q4DTY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTY3"
  },
  {
    "uniprot": "Q4CYX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYX0"
  },
  {
    "uniprot": "Q4D296",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D296"
  },
  {
    "uniprot": "Q4CSI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSI6"
  },
  {
    "uniprot": "Q4DY79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY79"
  },
  {
    "uniprot": "Q4CKJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKJ6"
  },
  {
    "uniprot": "Q4DD92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD92"
  },
  {
    "uniprot": "Q4CRK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRK3"
  },
  {
    "uniprot": "Q4DTL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTL5"
  },
  {
    "uniprot": "Q4DTB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTB5"
  },
  {
    "uniprot": "Q4D0W8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0W8"
  },
  {
    "uniprot": "Q4DV75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV75"
  },
  {
    "uniprot": "Q4DHX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHX6"
  },
  {
    "uniprot": "Q4D0S8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0S8"
  },
  {
    "uniprot": "Q4E5I8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5I8"
  },
  {
    "uniprot": "Q4D5F7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5F7"
  },
  {
    "uniprot": "Q4DXW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXW4"
  },
  {
    "uniprot": "Q4CZ72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ72"
  },
  {
    "uniprot": "Q4DZA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZA0"
  },
  {
    "uniprot": "Q4DXD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXD7"
  },
  {
    "uniprot": "Q4DN59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN59"
  },
  {
    "uniprot": "Q4D6G3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6G3"
  },
  {
    "uniprot": "Q4CKM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKM2"
  },
  {
    "uniprot": "Q4CVV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVV8"
  },
  {
    "uniprot": "Q4DEQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEQ7"
  },
  {
    "uniprot": "Q4DYR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYR6"
  },
  {
    "uniprot": "Q4D6Q6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Q6"
  },
  {
    "uniprot": "Q4DVQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVQ0"
  },
  {
    "uniprot": "Q4D751",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D751"
  },
  {
    "uniprot": "Q4D6U1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6U1"
  },
  {
    "uniprot": "Q4DJU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJU4"
  },
  {
    "uniprot": "Q4D0L9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0L9"
  },
  {
    "uniprot": "Q4DXK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXK5"
  },
  {
    "uniprot": "Q4D5G2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5G2"
  },
  {
    "uniprot": "Q4DB40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB40"
  },
  {
    "uniprot": "Q4DTY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTY6"
  },
  {
    "uniprot": "Q4DD52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD52"
  },
  {
    "uniprot": "Q4E081",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E081"
  },
  {
    "uniprot": "Q4DXE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXE7"
  },
  {
    "uniprot": "Q4CV45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV45"
  },
  {
    "uniprot": "Q4CV94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV94"
  },
  {
    "uniprot": "Q4CU95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU95"
  },
  {
    "uniprot": "Q4CWA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWA2"
  },
  {
    "uniprot": "Q4D5C4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5C4"
  },
  {
    "uniprot": "Q4CQ93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ93"
  },
  {
    "uniprot": "Q4DT38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT38"
  },
  {
    "uniprot": "Q4CWP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWP0"
  },
  {
    "uniprot": "Q4D550",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D550"
  },
  {
    "uniprot": "Q4E147",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E147"
  },
  {
    "uniprot": "Q4CV64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV64"
  },
  {
    "uniprot": "Q4CNY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNY3"
  },
  {
    "uniprot": "Q4DAA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAA9"
  },
  {
    "uniprot": "Q4D7W4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7W4"
  },
  {
    "uniprot": "Q4CSR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSR3"
  },
  {
    "uniprot": "Q4D7Z2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Z2"
  },
  {
    "uniprot": "Q4DPB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPB9"
  },
  {
    "uniprot": "Q4DJA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJA1"
  },
  {
    "uniprot": "Q4DCG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCG7"
  },
  {
    "uniprot": "Q4DRD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRD0"
  },
  {
    "uniprot": "Q4DIH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIH5"
  },
  {
    "uniprot": "Q4DHK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHK5"
  },
  {
    "uniprot": "Q4CT46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT46"
  },
  {
    "uniprot": "Q4DZZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZZ0"
  },
  {
    "uniprot": "Q4DXD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXD4"
  },
  {
    "uniprot": "Q4DA11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA11"
  },
  {
    "uniprot": "Q4DAE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAE6"
  },
  {
    "uniprot": "Q4DK52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK52"
  },
  {
    "uniprot": "Q4DXE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXE0"
  },
  {
    "uniprot": "Q4D2S3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2S3"
  },
  {
    "uniprot": "Q4D381",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D381"
  },
  {
    "uniprot": "Q4DSM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSM6"
  },
  {
    "uniprot": "Q4CZ28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ28"
  },
  {
    "uniprot": "Q4DH75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH75"
  },
  {
    "uniprot": "Q4E194",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E194"
  },
  {
    "uniprot": "Q4CPU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPU3"
  },
  {
    "uniprot": "Q4E239",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E239"
  },
  {
    "uniprot": "Q4E550",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E550"
  },
  {
    "uniprot": "Q4CR37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR37"
  },
  {
    "uniprot": "Q4CKP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKP4"
  },
  {
    "uniprot": "Q4D922",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D922"
  },
  {
    "uniprot": "Q4DRD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRD7"
  },
  {
    "uniprot": "Q4DDA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDA3"
  },
  {
    "uniprot": "Q4DSY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSY6"
  },
  {
    "uniprot": "Q4DSW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSW6"
  },
  {
    "uniprot": "Q4DJR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJR1"
  },
  {
    "uniprot": "Q4CQV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQV4"
  },
  {
    "uniprot": "Q4DYG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYG4"
  },
  {
    "uniprot": "Q4CKY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKY3"
  },
  {
    "uniprot": "Q4DC01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC01"
  },
  {
    "uniprot": "Q4D7C0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7C0"
  },
  {
    "uniprot": "Q4DQE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQE3"
  },
  {
    "uniprot": "Q4E4N6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4N6"
  },
  {
    "uniprot": "Q4DYJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYJ7"
  },
  {
    "uniprot": "Q4DRR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRR3"
  },
  {
    "uniprot": "Q4CQG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQG1"
  },
  {
    "uniprot": "Q4DPU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPU2"
  },
  {
    "uniprot": "Q4DBY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBY8"
  },
  {
    "uniprot": "Q4DB35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB35"
  },
  {
    "uniprot": "Q4DHN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHN3"
  },
  {
    "uniprot": "Q4D566",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D566"
  },
  {
    "uniprot": "Q4CWW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWW8"
  },
  {
    "uniprot": "Q4D9R4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9R4"
  },
  {
    "uniprot": "Q4D7V8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7V8"
  },
  {
    "uniprot": "Q4CR55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR55"
  },
  {
    "uniprot": "Q4CQB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQB5"
  },
  {
    "uniprot": "Q4E0X5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0X5"
  },
  {
    "uniprot": "Q4CSC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSC7"
  },
  {
    "uniprot": "Q4CNT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNT5"
  },
  {
    "uniprot": "Q4DAU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAU7"
  },
  {
    "uniprot": "Q4D3I9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3I9"
  },
  {
    "uniprot": "Q4D919",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D919"
  },
  {
    "uniprot": "Q4D5Y0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Y0"
  },
  {
    "uniprot": "Q4CNZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNZ6"
  },
  {
    "uniprot": "Q4CNP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNP4"
  },
  {
    "uniprot": "Q4D8G7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8G7"
  },
  {
    "uniprot": "Q4D3X6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3X6"
  },
  {
    "uniprot": "Q4E5Q4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Q4"
  },
  {
    "uniprot": "Q4DQ49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ49"
  },
  {
    "uniprot": "Q4CRK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRK9"
  },
  {
    "uniprot": "Q4CYC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYC8"
  },
  {
    "uniprot": "Q4E315",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E315"
  },
  {
    "uniprot": "Q4D837",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D837"
  },
  {
    "uniprot": "Q4DX17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX17"
  },
  {
    "uniprot": "Q4DY40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY40"
  },
  {
    "uniprot": "Q4DB17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB17"
  },
  {
    "uniprot": "Q4DQM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQM6"
  },
  {
    "uniprot": "Q4E1A0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1A0"
  },
  {
    "uniprot": "Q4DIG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIG8"
  },
  {
    "uniprot": "Q4CZZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZZ4"
  },
  {
    "uniprot": "Q4DVA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVA8"
  },
  {
    "uniprot": "Q4CV59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV59"
  },
  {
    "uniprot": "Q4E049",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E049"
  },
  {
    "uniprot": "Q4DRG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRG0"
  },
  {
    "uniprot": "Q4DPN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPN4"
  },
  {
    "uniprot": "Q4CV15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV15"
  },
  {
    "uniprot": "Q4D617",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D617"
  },
  {
    "uniprot": "Q4DXQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXQ5"
  },
  {
    "uniprot": "Q4D7F2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7F2"
  },
  {
    "uniprot": "Q4D4T0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4T0"
  },
  {
    "uniprot": "Q4DJ59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ59"
  },
  {
    "uniprot": "Q4DAB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAB6"
  },
  {
    "uniprot": "Q4CPP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPP3"
  },
  {
    "uniprot": "Q4CMF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMF2"
  },
  {
    "uniprot": "Q4CV23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV23"
  },
  {
    "uniprot": "Q4E0Y1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Y1"
  },
  {
    "uniprot": "Q4D4H3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4H3"
  },
  {
    "uniprot": "Q4CNS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNS5"
  },
  {
    "uniprot": "Q4D0V3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0V3"
  },
  {
    "uniprot": "Q4E267",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E267"
  },
  {
    "uniprot": "Q4E3B4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3B4"
  },
  {
    "uniprot": "Q4D3J9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3J9"
  },
  {
    "uniprot": "Q4E494",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E494"
  },
  {
    "uniprot": "Q4DVI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVI7"
  },
  {
    "uniprot": "Q4DZT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZT1"
  },
  {
    "uniprot": "Q4CM46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM46"
  },
  {
    "uniprot": "Q4CVR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVR7"
  },
  {
    "uniprot": "Q4CNY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNY4"
  },
  {
    "uniprot": "Q4DWL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWL5"
  },
  {
    "uniprot": "Q4DBR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBR9"
  },
  {
    "uniprot": "Q4CLW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLW0"
  },
  {
    "uniprot": "Q4CWT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWT7"
  },
  {
    "uniprot": "Q4DK96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK96"
  },
  {
    "uniprot": "Q4CTK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTK8"
  },
  {
    "uniprot": "Q4DI07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI07"
  },
  {
    "uniprot": "Q4D844",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D844"
  },
  {
    "uniprot": "Q4DJ96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ96"
  },
  {
    "uniprot": "Q4D4U2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4U2"
  },
  {
    "uniprot": "Q4D5Y4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Y4"
  },
  {
    "uniprot": "Q4DFJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFJ5"
  },
  {
    "uniprot": "Q4CXV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXV5"
  },
  {
    "uniprot": "Q4CZW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZW7"
  },
  {
    "uniprot": "Q4CS31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS31"
  },
  {
    "uniprot": "Q4DD44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD44"
  },
  {
    "uniprot": "Q4DB71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB71"
  },
  {
    "uniprot": "Q4CM30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM30"
  },
  {
    "uniprot": "Q4DC27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC27"
  },
  {
    "uniprot": "Q4D4I8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4I8"
  },
  {
    "uniprot": "Q4E4L4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4L4"
  },
  {
    "uniprot": "Q4D328",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D328"
  },
  {
    "uniprot": "Q4E5V7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5V7"
  },
  {
    "uniprot": "Q4D3K6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3K6"
  },
  {
    "uniprot": "Q4E3A0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3A0"
  },
  {
    "uniprot": "Q4DJC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJC3"
  },
  {
    "uniprot": "Q4CPI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPI5"
  },
  {
    "uniprot": "Q4E0G5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0G5"
  },
  {
    "uniprot": "Q4CQP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQP6"
  },
  {
    "uniprot": "Q4DKV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKV7"
  },
  {
    "uniprot": "Q4DEY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEY8"
  },
  {
    "uniprot": "Q4DQA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQA2"
  },
  {
    "uniprot": "Q4D2M3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2M3"
  },
  {
    "uniprot": "Q4DQH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQH2"
  },
  {
    "uniprot": "Q4E3Q5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Q5"
  },
  {
    "uniprot": "Q4DKP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKP7"
  },
  {
    "uniprot": "Q4CMV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMV4"
  },
  {
    "uniprot": "Q4DH54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH54"
  },
  {
    "uniprot": "Q4D9J4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9J4"
  },
  {
    "uniprot": "Q4CNL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNL5"
  },
  {
    "uniprot": "Q4E1Z1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Z1"
  },
  {
    "uniprot": "Q4CQ78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ78"
  },
  {
    "uniprot": "Q4DCL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCL1"
  },
  {
    "uniprot": "Q4CUF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUF3"
  },
  {
    "uniprot": "Q4CVG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVG1"
  },
  {
    "uniprot": "Q4CX17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX17"
  },
  {
    "uniprot": "Q4D0V6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0V6"
  },
  {
    "uniprot": "Q4DYW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYW8"
  },
  {
    "uniprot": "Q4CV10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV10"
  },
  {
    "uniprot": "Q4DWD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWD0"
  },
  {
    "uniprot": "Q4DMQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMQ6"
  },
  {
    "uniprot": "Q4D1J5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1J5"
  },
  {
    "uniprot": "Q4DIX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIX8"
  },
  {
    "uniprot": "Q4DEA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEA6"
  },
  {
    "uniprot": "Q4DAC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAC2"
  },
  {
    "uniprot": "Q4CXL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXL8"
  },
  {
    "uniprot": "Q4DS14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS14"
  },
  {
    "uniprot": "Q4DML1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DML1"
  },
  {
    "uniprot": "Q4CLL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLL3"
  },
  {
    "uniprot": "Q4CY76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY76"
  },
  {
    "uniprot": "Q4DVJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVJ2"
  },
  {
    "uniprot": "Q4CVB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVB3"
  },
  {
    "uniprot": "Q4CQC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQC4"
  },
  {
    "uniprot": "Q4D1I9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1I9"
  },
  {
    "uniprot": "Q4CLI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLI7"
  },
  {
    "uniprot": "Q4CY87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY87"
  },
  {
    "uniprot": "Q4DU00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU00"
  },
  {
    "uniprot": "Q4D1Q2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Q2"
  },
  {
    "uniprot": "Q4D357",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D357"
  },
  {
    "uniprot": "Q4DJ51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ51"
  },
  {
    "uniprot": "Q4D6D7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6D7"
  },
  {
    "uniprot": "Q4CWQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWQ2"
  },
  {
    "uniprot": "Q4E433",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E433"
  },
  {
    "uniprot": "Q4CQF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQF5"
  },
  {
    "uniprot": "Q4DCU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCU0"
  },
  {
    "uniprot": "Q4DBW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBW5"
  },
  {
    "uniprot": "Q4D6Z9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Z9"
  },
  {
    "uniprot": "Q4DTE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTE2"
  },
  {
    "uniprot": "Q4CX12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX12"
  },
  {
    "uniprot": "Q4DPA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPA9"
  },
  {
    "uniprot": "Q4DF69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF69"
  },
  {
    "uniprot": "Q4DSX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSX2"
  },
  {
    "uniprot": "Q4D3A0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3A0"
  },
  {
    "uniprot": "Q4DJF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJF1"
  },
  {
    "uniprot": "Q4DPY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPY2"
  },
  {
    "uniprot": "Q4E2V8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2V8"
  },
  {
    "uniprot": "Q4DY04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY04"
  },
  {
    "uniprot": "Q4DQT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQT9"
  },
  {
    "uniprot": "Q4E4T6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4T6"
  },
  {
    "uniprot": "Q4CSG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSG8"
  },
  {
    "uniprot": "Q4DZE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZE3"
  },
  {
    "uniprot": "Q4D6M2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6M2"
  },
  {
    "uniprot": "Q4DED3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DED3"
  },
  {
    "uniprot": "Q4DVN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVN2"
  },
  {
    "uniprot": "Q4DLS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLS5"
  },
  {
    "uniprot": "Q4CXI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXI1"
  },
  {
    "uniprot": "Q4DTA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTA6"
  },
  {
    "uniprot": "Q4DTM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTM9"
  },
  {
    "uniprot": "Q4CXG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXG9"
  },
  {
    "uniprot": "Q4DC20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC20"
  },
  {
    "uniprot": "Q4D4K3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4K3"
  },
  {
    "uniprot": "Q4CPH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPH2"
  },
  {
    "uniprot": "Q4CNW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNW8"
  },
  {
    "uniprot": "Q4DID8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DID8"
  },
  {
    "uniprot": "Q4CWT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWT6"
  },
  {
    "uniprot": "Q4DSW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSW8"
  },
  {
    "uniprot": "Q4D348",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D348"
  },
  {
    "uniprot": "Q4CYG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYG6"
  },
  {
    "uniprot": "Q4CKD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKD9"
  },
  {
    "uniprot": "Q4DUC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUC9"
  },
  {
    "uniprot": "Q4CX59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX59"
  },
  {
    "uniprot": "Q4DSI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSI0"
  },
  {
    "uniprot": "Q4CR30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR30"
  },
  {
    "uniprot": "Q4DAQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAQ3"
  },
  {
    "uniprot": "Q4DJV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJV4"
  },
  {
    "uniprot": "Q4DWJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWJ1"
  },
  {
    "uniprot": "Q4DXJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXJ7"
  },
  {
    "uniprot": "Q4D724",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D724"
  },
  {
    "uniprot": "Q4CS70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS70"
  },
  {
    "uniprot": "Q4D7P5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7P5"
  },
  {
    "uniprot": "Q4CWQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWQ4"
  },
  {
    "uniprot": "Q4E5J2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5J2"
  },
  {
    "uniprot": "Q4DCL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCL9"
  },
  {
    "uniprot": "Q4DHA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHA8"
  },
  {
    "uniprot": "Q4CLG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLG0"
  },
  {
    "uniprot": "Q4D3S8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3S8"
  },
  {
    "uniprot": "Q4D875",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D875"
  },
  {
    "uniprot": "Q4D0Y6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Y6"
  },
  {
    "uniprot": "Q4D1L7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1L7"
  },
  {
    "uniprot": "Q4DZA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZA4"
  },
  {
    "uniprot": "Q4CSZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSZ7"
  },
  {
    "uniprot": "Q4E211",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E211"
  },
  {
    "uniprot": "Q4CPC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPC6"
  },
  {
    "uniprot": "Q4DDL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDL2"
  },
  {
    "uniprot": "Q4E3W5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3W5"
  },
  {
    "uniprot": "Q4E417",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E417"
  },
  {
    "uniprot": "Q4DID9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DID9"
  },
  {
    "uniprot": "Q4D076",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D076"
  },
  {
    "uniprot": "Q4CW97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW97"
  },
  {
    "uniprot": "Q4E4T3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4T3"
  },
  {
    "uniprot": "Q4CTL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTL8"
  },
  {
    "uniprot": "Q4DGW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGW2"
  },
  {
    "uniprot": "Q4CTR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTR7"
  },
  {
    "uniprot": "Q4DRL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRL1"
  },
  {
    "uniprot": "Q4DLZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLZ4"
  },
  {
    "uniprot": "Q4DPI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPI7"
  },
  {
    "uniprot": "Q4CQM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQM7"
  },
  {
    "uniprot": "Q4DMC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMC6"
  },
  {
    "uniprot": "Q4E5P8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5P8"
  },
  {
    "uniprot": "Q4D9G3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9G3"
  },
  {
    "uniprot": "Q4DBJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBJ5"
  },
  {
    "uniprot": "Q4D6P0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6P0"
  },
  {
    "uniprot": "Q4DC92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC92"
  },
  {
    "uniprot": "Q4CLB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLB9"
  },
  {
    "uniprot": "Q4DL37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL37"
  },
  {
    "uniprot": "Q4DCD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCD4"
  },
  {
    "uniprot": "Q4D893",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D893"
  },
  {
    "uniprot": "Q4CXS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXS2"
  },
  {
    "uniprot": "Q4CLY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLY0"
  },
  {
    "uniprot": "Q4E5S3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5S3"
  },
  {
    "uniprot": "Q4DD83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD83"
  },
  {
    "uniprot": "Q4DWB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWB9"
  },
  {
    "uniprot": "Q4DVH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVH3"
  },
  {
    "uniprot": "Q4DVW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVW4"
  },
  {
    "uniprot": "Q4DYM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYM7"
  },
  {
    "uniprot": "Q4CKB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKB0"
  },
  {
    "uniprot": "Q4DII3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DII3"
  },
  {
    "uniprot": "Q4D161",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D161"
  },
  {
    "uniprot": "Q4D5Z2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Z2"
  },
  {
    "uniprot": "Q4D848",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D848"
  },
  {
    "uniprot": "Q4DWA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWA4"
  },
  {
    "uniprot": "Q4DAG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAG3"
  },
  {
    "uniprot": "Q4DG67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG67"
  },
  {
    "uniprot": "Q4DPQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPQ0"
  },
  {
    "uniprot": "Q4E5V1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5V1"
  },
  {
    "uniprot": "Q4DBH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBH6"
  },
  {
    "uniprot": "Q4CYV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYV8"
  },
  {
    "uniprot": "Q4DY86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY86"
  },
  {
    "uniprot": "Q4DUK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUK8"
  },
  {
    "uniprot": "Q4DGS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGS2"
  },
  {
    "uniprot": "Q4DLE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLE9"
  },
  {
    "uniprot": "Q4D7G8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7G8"
  },
  {
    "uniprot": "Q4DK21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK21"
  },
  {
    "uniprot": "Q4DRW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRW8"
  },
  {
    "uniprot": "Q4DCV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCV0"
  },
  {
    "uniprot": "Q4D718",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D718"
  },
  {
    "uniprot": "Q4CU07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU07"
  },
  {
    "uniprot": "Q4E1N3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1N3"
  },
  {
    "uniprot": "Q4DFI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFI3"
  },
  {
    "uniprot": "Q4CX93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX93"
  },
  {
    "uniprot": "Q4DQP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQP0"
  },
  {
    "uniprot": "Q4E008",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E008"
  },
  {
    "uniprot": "Q4D2A3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2A3"
  },
  {
    "uniprot": "Q4CTJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTJ1"
  },
  {
    "uniprot": "Q4DZ25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ25"
  },
  {
    "uniprot": "Q4DBR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBR0"
  },
  {
    "uniprot": "Q4DDB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDB0"
  },
  {
    "uniprot": "Q4DU71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU71"
  },
  {
    "uniprot": "Q4E689",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E689"
  },
  {
    "uniprot": "Q4DXD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXD8"
  },
  {
    "uniprot": "Q4DF33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF33"
  },
  {
    "uniprot": "Q4D2E3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2E3"
  },
  {
    "uniprot": "Q4CY95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY95"
  },
  {
    "uniprot": "Q4DFY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFY1"
  },
  {
    "uniprot": "Q4D2Z3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Z3"
  },
  {
    "uniprot": "Q4D5F5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5F5"
  },
  {
    "uniprot": "Q4CUL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUL5"
  },
  {
    "uniprot": "Q4CPI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPI8"
  },
  {
    "uniprot": "Q4DGJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGJ1"
  },
  {
    "uniprot": "Q4CZH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZH7"
  },
  {
    "uniprot": "Q4CKA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKA6"
  },
  {
    "uniprot": "Q4E128",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E128"
  },
  {
    "uniprot": "Q4D8Y7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Y7"
  },
  {
    "uniprot": "Q4D9F3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9F3"
  },
  {
    "uniprot": "Q4DNN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNN2"
  },
  {
    "uniprot": "Q4DJI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJI8"
  },
  {
    "uniprot": "Q4DGS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGS3"
  },
  {
    "uniprot": "Q4CNU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNU6"
  },
  {
    "uniprot": "Q4D8D5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8D5"
  },
  {
    "uniprot": "Q4CXW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXW5"
  },
  {
    "uniprot": "Q4CMQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMQ8"
  },
  {
    "uniprot": "Q4DV63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV63"
  },
  {
    "uniprot": "Q4DLV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLV9"
  },
  {
    "uniprot": "Q4CNE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNE1"
  },
  {
    "uniprot": "Q4CY81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY81"
  },
  {
    "uniprot": "Q4DZD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZD6"
  },
  {
    "uniprot": "Q4CT11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT11"
  },
  {
    "uniprot": "Q4D978",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D978"
  },
  {
    "uniprot": "Q4DBY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBY1"
  },
  {
    "uniprot": "Q4CYK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYK8"
  },
  {
    "uniprot": "Q4CYL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYL5"
  },
  {
    "uniprot": "Q4DCW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCW4"
  },
  {
    "uniprot": "Q4E2Q6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Q6"
  },
  {
    "uniprot": "Q4CY73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY73"
  },
  {
    "uniprot": "Q4DGV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGV8"
  },
  {
    "uniprot": "Q4DJ73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ73"
  },
  {
    "uniprot": "Q4D7A0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7A0"
  },
  {
    "uniprot": "Q4D6K2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6K2"
  },
  {
    "uniprot": "Q4DDJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDJ1"
  },
  {
    "uniprot": "Q4CZI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZI4"
  },
  {
    "uniprot": "Q4DRI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRI4"
  },
  {
    "uniprot": "Q4CUI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUI0"
  },
  {
    "uniprot": "Q4CM34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM34"
  },
  {
    "uniprot": "Q4DPU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPU1"
  },
  {
    "uniprot": "Q4E035",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E035"
  },
  {
    "uniprot": "Q4DQ27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ27"
  },
  {
    "uniprot": "Q4D5A3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5A3"
  },
  {
    "uniprot": "Q4E2V2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2V2"
  },
  {
    "uniprot": "Q4D5D9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5D9"
  },
  {
    "uniprot": "Q4D0J6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0J6"
  },
  {
    "uniprot": "Q4DCD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCD1"
  },
  {
    "uniprot": "Q4CNB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNB5"
  },
  {
    "uniprot": "Q4DRA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRA6"
  },
  {
    "uniprot": "Q4CWC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWC4"
  },
  {
    "uniprot": "Q4E1R8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1R8"
  },
  {
    "uniprot": "Q4DXJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXJ9"
  },
  {
    "uniprot": "Q4DZ54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ54"
  },
  {
    "uniprot": "Q4DR96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR96"
  },
  {
    "uniprot": "Q4DLM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLM7"
  },
  {
    "uniprot": "Q4DGC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGC7"
  },
  {
    "uniprot": "Q4E633",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E633"
  },
  {
    "uniprot": "Q4D9U8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9U8"
  },
  {
    "uniprot": "Q4CP49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP49"
  },
  {
    "uniprot": "Q4E4U5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4U5"
  },
  {
    "uniprot": "Q4E674",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E674"
  },
  {
    "uniprot": "Q4DXS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXS0"
  },
  {
    "uniprot": "Q4DZJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZJ9"
  },
  {
    "uniprot": "Q4CRH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRH5"
  },
  {
    "uniprot": "Q4CVX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVX6"
  },
  {
    "uniprot": "Q4DTQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTQ8"
  },
  {
    "uniprot": "Q4CZ54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ54"
  },
  {
    "uniprot": "Q4DRD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRD1"
  },
  {
    "uniprot": "Q4CSP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSP5"
  },
  {
    "uniprot": "Q4DI83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI83"
  },
  {
    "uniprot": "Q4CZX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZX6"
  },
  {
    "uniprot": "Q4D295",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D295"
  },
  {
    "uniprot": "Q4D823",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D823"
  },
  {
    "uniprot": "Q4D1M9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1M9"
  },
  {
    "uniprot": "Q4CQJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQJ7"
  },
  {
    "uniprot": "Q4D9Z1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Z1"
  },
  {
    "uniprot": "Q4DZ94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ94"
  },
  {
    "uniprot": "Q4DFH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFH5"
  },
  {
    "uniprot": "Q4D318",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D318"
  },
  {
    "uniprot": "Q4DAL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAL9"
  },
  {
    "uniprot": "Q4DRX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRX4"
  },
  {
    "uniprot": "Q4E4S8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4S8"
  },
  {
    "uniprot": "Q4CT73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT73"
  },
  {
    "uniprot": "Q4DWV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWV6"
  },
  {
    "uniprot": "Q4DBR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBR8"
  },
  {
    "uniprot": "Q4CLM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLM8"
  },
  {
    "uniprot": "Q4DQD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQD3"
  },
  {
    "uniprot": "Q4CRR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRR2"
  },
  {
    "uniprot": "Q4CUW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUW0"
  },
  {
    "uniprot": "Q4DG42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG42"
  },
  {
    "uniprot": "Q4CUV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUV8"
  },
  {
    "uniprot": "Q4E3R1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3R1"
  },
  {
    "uniprot": "Q4DFX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFX3"
  },
  {
    "uniprot": "Q4DCB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCB9"
  },
  {
    "uniprot": "Q4D029",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D029"
  },
  {
    "uniprot": "Q4CSS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSS2"
  },
  {
    "uniprot": "Q4DJ94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ94"
  },
  {
    "uniprot": "Q4DVS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVS9"
  },
  {
    "uniprot": "Q4D799",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D799"
  },
  {
    "uniprot": "Q4CTJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTJ6"
  },
  {
    "uniprot": "Q4CZ36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ36"
  },
  {
    "uniprot": "Q4DU82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU82"
  },
  {
    "uniprot": "Q4E657",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E657"
  },
  {
    "uniprot": "Q4D0G0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0G0"
  },
  {
    "uniprot": "Q4DAW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAW8"
  },
  {
    "uniprot": "Q4DCN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCN3"
  },
  {
    "uniprot": "Q4DAT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAT1"
  },
  {
    "uniprot": "Q4CX84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX84"
  },
  {
    "uniprot": "Q4E558",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E558"
  },
  {
    "uniprot": "Q4CKB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKB2"
  },
  {
    "uniprot": "Q4DL64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL64"
  },
  {
    "uniprot": "Q4DRQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRQ5"
  },
  {
    "uniprot": "Q4E4A8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4A8"
  },
  {
    "uniprot": "Q4D4S1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4S1"
  },
  {
    "uniprot": "Q4D5Q0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Q0"
  },
  {
    "uniprot": "Q4DG61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG61"
  },
  {
    "uniprot": "Q4D5N6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5N6"
  },
  {
    "uniprot": "Q4DGB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGB1"
  },
  {
    "uniprot": "Q4E5S6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5S6"
  },
  {
    "uniprot": "Q4D6L2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6L2"
  },
  {
    "uniprot": "Q4DMB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMB3"
  },
  {
    "uniprot": "Q4DT83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT83"
  },
  {
    "uniprot": "Q4DWL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWL9"
  },
  {
    "uniprot": "Q4CP58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP58"
  },
  {
    "uniprot": "Q4DE36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE36"
  },
  {
    "uniprot": "Q4D717",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D717"
  },
  {
    "uniprot": "Q4CML8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CML8"
  },
  {
    "uniprot": "Q4D9P0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9P0"
  },
  {
    "uniprot": "Q4DL23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL23"
  },
  {
    "uniprot": "Q4DMY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMY1"
  },
  {
    "uniprot": "Q4DVQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVQ2"
  },
  {
    "uniprot": "Q4DYI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYI7"
  },
  {
    "uniprot": "Q4CLS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLS4"
  },
  {
    "uniprot": "Q4D816",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D816"
  },
  {
    "uniprot": "Q4D149",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D149"
  },
  {
    "uniprot": "Q4DT97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT97"
  },
  {
    "uniprot": "Q4DW21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW21"
  },
  {
    "uniprot": "Q4DGR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGR7"
  },
  {
    "uniprot": "Q4DCX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCX0"
  },
  {
    "uniprot": "Q4DCL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCL2"
  },
  {
    "uniprot": "Q4CS21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS21"
  },
  {
    "uniprot": "Q4DIE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIE9"
  },
  {
    "uniprot": "Q4D739",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D739"
  },
  {
    "uniprot": "Q4CT36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT36"
  },
  {
    "uniprot": "Q4CV78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV78"
  },
  {
    "uniprot": "Q4CQI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQI4"
  },
  {
    "uniprot": "Q4E5E0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5E0"
  },
  {
    "uniprot": "Q4DAW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAW3"
  },
  {
    "uniprot": "Q4DHN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHN4"
  },
  {
    "uniprot": "Q4DXF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXF2"
  },
  {
    "uniprot": "Q4DIU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIU4"
  },
  {
    "uniprot": "Q4CR35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR35"
  },
  {
    "uniprot": "Q4D998",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D998"
  },
  {
    "uniprot": "Q4CV33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV33"
  },
  {
    "uniprot": "Q4DAL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAL1"
  },
  {
    "uniprot": "Q4CLT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLT8"
  },
  {
    "uniprot": "Q4DEJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEJ4"
  },
  {
    "uniprot": "Q4E5N4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5N4"
  },
  {
    "uniprot": "Q4DHQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHQ6"
  },
  {
    "uniprot": "Q4DV53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV53"
  },
  {
    "uniprot": "Q4CN05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN05"
  },
  {
    "uniprot": "Q4D1T5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1T5"
  },
  {
    "uniprot": "Q4DYH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYH3"
  },
  {
    "uniprot": "Q4D7G0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7G0"
  },
  {
    "uniprot": "Q4E0A5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0A5"
  },
  {
    "uniprot": "Q4DPQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPQ4"
  },
  {
    "uniprot": "Q4D8H8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8H8"
  },
  {
    "uniprot": "Q4E0W3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0W3"
  },
  {
    "uniprot": "Q4D1Y7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Y7"
  },
  {
    "uniprot": "Q4CQZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQZ7"
  },
  {
    "uniprot": "Q4E1R1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1R1"
  },
  {
    "uniprot": "Q4DLN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLN2"
  },
  {
    "uniprot": "Q4DQH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQH3"
  },
  {
    "uniprot": "Q4DGV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGV9"
  },
  {
    "uniprot": "Q4CV80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV80"
  },
  {
    "uniprot": "Q4CZX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZX2"
  },
  {
    "uniprot": "Q4CQP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQP3"
  },
  {
    "uniprot": "Q4E5H1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5H1"
  },
  {
    "uniprot": "Q4DPW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPW4"
  },
  {
    "uniprot": "Q4DBB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBB4"
  },
  {
    "uniprot": "Q4DNJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNJ3"
  },
  {
    "uniprot": "Q4E039",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E039"
  },
  {
    "uniprot": "Q4E3W7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3W7"
  },
  {
    "uniprot": "Q4D6W8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6W8"
  },
  {
    "uniprot": "Q4D0C9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0C9"
  },
  {
    "uniprot": "Q4CQD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQD6"
  },
  {
    "uniprot": "Q4CNG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNG8"
  },
  {
    "uniprot": "Q4DIF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIF7"
  },
  {
    "uniprot": "Q4DVW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVW5"
  },
  {
    "uniprot": "Q4DLV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLV4"
  },
  {
    "uniprot": "Q4D7V5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7V5"
  },
  {
    "uniprot": "Q4CL64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL64"
  },
  {
    "uniprot": "Q4CRF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRF9"
  },
  {
    "uniprot": "Q4E0Z2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Z2"
  },
  {
    "uniprot": "Q4E393",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E393"
  },
  {
    "uniprot": "Q4CXC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXC1"
  },
  {
    "uniprot": "Q4E0I2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0I2"
  },
  {
    "uniprot": "Q4D403",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D403"
  },
  {
    "uniprot": "Q4DXI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXI5"
  },
  {
    "uniprot": "Q4DDS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDS8"
  },
  {
    "uniprot": "Q4CYW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYW0"
  },
  {
    "uniprot": "Q4DSX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSX0"
  },
  {
    "uniprot": "Q4CSQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSQ7"
  },
  {
    "uniprot": "Q4CKJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKJ2"
  },
  {
    "uniprot": "Q4DWU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWU0"
  },
  {
    "uniprot": "Q4DH50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH50"
  },
  {
    "uniprot": "Q4DG69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG69"
  },
  {
    "uniprot": "Q4DV88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV88"
  },
  {
    "uniprot": "Q4CPK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPK4"
  },
  {
    "uniprot": "Q4DPK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPK4"
  },
  {
    "uniprot": "Q4CV72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV72"
  },
  {
    "uniprot": "Q4CXJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXJ5"
  },
  {
    "uniprot": "Q4DYA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYA0"
  },
  {
    "uniprot": "Q4CSR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSR1"
  },
  {
    "uniprot": "Q4DIA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIA9"
  },
  {
    "uniprot": "Q4E5W4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5W4"
  },
  {
    "uniprot": "Q4DUN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUN6"
  },
  {
    "uniprot": "Q4CR17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR17"
  },
  {
    "uniprot": "Q4DKI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKI2"
  },
  {
    "uniprot": "Q4DVD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVD1"
  },
  {
    "uniprot": "Q4DX59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX59"
  },
  {
    "uniprot": "Q4DC29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC29"
  },
  {
    "uniprot": "Q4DCD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCD0"
  },
  {
    "uniprot": "Q4DTF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTF2"
  },
  {
    "uniprot": "Q4E4R7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4R7"
  },
  {
    "uniprot": "Q4D4M4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4M4"
  },
  {
    "uniprot": "Q4CU39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU39"
  },
  {
    "uniprot": "Q4DAZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAZ0"
  },
  {
    "uniprot": "Q4E4S5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4S5"
  },
  {
    "uniprot": "Q4D3D2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3D2"
  },
  {
    "uniprot": "Q4CKN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKN8"
  },
  {
    "uniprot": "Q4CRA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRA5"
  },
  {
    "uniprot": "Q4DDC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDC3"
  },
  {
    "uniprot": "Q4CPQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPQ4"
  },
  {
    "uniprot": "Q4CX48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX48"
  },
  {
    "uniprot": "Q4DVC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVC5"
  },
  {
    "uniprot": "Q4DLJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLJ1"
  },
  {
    "uniprot": "Q4D8U8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8U8"
  },
  {
    "uniprot": "Q4DBF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBF6"
  },
  {
    "uniprot": "Q4DCI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCI3"
  },
  {
    "uniprot": "Q4E2V6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2V6"
  },
  {
    "uniprot": "Q4DL28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL28"
  },
  {
    "uniprot": "Q4CZ25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ25"
  },
  {
    "uniprot": "Q4D8M1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8M1"
  },
  {
    "uniprot": "Q4D5N0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5N0"
  },
  {
    "uniprot": "Q4CKS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKS5"
  },
  {
    "uniprot": "Q4D4G2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4G2"
  },
  {
    "uniprot": "Q4D2G7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2G7"
  },
  {
    "uniprot": "Q4DU03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU03"
  },
  {
    "uniprot": "Q4DNY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNY2"
  },
  {
    "uniprot": "Q4E195",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E195"
  },
  {
    "uniprot": "Q4CSZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSZ0"
  },
  {
    "uniprot": "Q4DWJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWJ2"
  },
  {
    "uniprot": "Q4DGE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGE0"
  },
  {
    "uniprot": "Q4DWN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWN2"
  },
  {
    "uniprot": "Q4CVU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVU3"
  },
  {
    "uniprot": "Q4CNK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNK1"
  },
  {
    "uniprot": "Q4D3D6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3D6"
  },
  {
    "uniprot": "Q4D226",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D226"
  },
  {
    "uniprot": "Q4D1H7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1H7"
  },
  {
    "uniprot": "Q4DRH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRH9"
  },
  {
    "uniprot": "Q4DSZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSZ6"
  },
  {
    "uniprot": "Q4CT90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT90"
  },
  {
    "uniprot": "Q4D8X4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8X4"
  },
  {
    "uniprot": "Q4DST0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DST0"
  },
  {
    "uniprot": "Q4CLZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLZ2"
  },
  {
    "uniprot": "Q4CL67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL67"
  },
  {
    "uniprot": "Q4D5S5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5S5"
  },
  {
    "uniprot": "Q4DZJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZJ2"
  },
  {
    "uniprot": "Q4CTT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTT9"
  },
  {
    "uniprot": "Q4DK19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK19"
  },
  {
    "uniprot": "Q4DLZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLZ2"
  },
  {
    "uniprot": "Q4CPB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPB7"
  },
  {
    "uniprot": "Q4CY00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY00"
  },
  {
    "uniprot": "Q4CZ49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ49"
  },
  {
    "uniprot": "Q4D388",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D388"
  },
  {
    "uniprot": "Q4DQA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQA6"
  },
  {
    "uniprot": "Q4DJ81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ81"
  },
  {
    "uniprot": "Q4CLN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLN4"
  },
  {
    "uniprot": "Q4DRC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRC1"
  },
  {
    "uniprot": "Q4DII7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DII7"
  },
  {
    "uniprot": "Q4DK11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK11"
  },
  {
    "uniprot": "Q4CVL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVL0"
  },
  {
    "uniprot": "Q4CYX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYX7"
  },
  {
    "uniprot": "Q4CL79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL79"
  },
  {
    "uniprot": "Q4CQV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQV8"
  },
  {
    "uniprot": "Q4CVV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVV1"
  },
  {
    "uniprot": "Q4CWE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWE2"
  },
  {
    "uniprot": "Q4CWG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWG7"
  },
  {
    "uniprot": "Q4DVB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVB9"
  },
  {
    "uniprot": "Q4E3B8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3B8"
  },
  {
    "uniprot": "Q4DB21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB21"
  },
  {
    "uniprot": "Q4D1B0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1B0"
  },
  {
    "uniprot": "Q4E5W3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5W3"
  },
  {
    "uniprot": "Q4CZC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZC3"
  },
  {
    "uniprot": "Q4D7X0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7X0"
  },
  {
    "uniprot": "Q4D6T2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6T2"
  },
  {
    "uniprot": "Q4DW47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW47"
  },
  {
    "uniprot": "Q4DHZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHZ9"
  },
  {
    "uniprot": "Q4DK33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK33"
  },
  {
    "uniprot": "Q4CV36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV36"
  },
  {
    "uniprot": "Q4DQ18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ18"
  },
  {
    "uniprot": "Q4CWB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWB4"
  },
  {
    "uniprot": "Q4E4D6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4D6"
  },
  {
    "uniprot": "Q4D4A3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4A3"
  },
  {
    "uniprot": "Q4DQW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQW5"
  },
  {
    "uniprot": "Q4CV55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV55"
  },
  {
    "uniprot": "Q4D3U0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3U0"
  },
  {
    "uniprot": "Q4DMQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMQ1"
  },
  {
    "uniprot": "Q4CQK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQK4"
  },
  {
    "uniprot": "Q4CNB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNB9"
  },
  {
    "uniprot": "Q4DHS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHS9"
  },
  {
    "uniprot": "Q4E0Y0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Y0"
  },
  {
    "uniprot": "Q4DK84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK84"
  },
  {
    "uniprot": "Q4CS74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS74"
  },
  {
    "uniprot": "Q4DZL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZL1"
  },
  {
    "uniprot": "Q4DCE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCE8"
  },
  {
    "uniprot": "Q4CX79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX79"
  },
  {
    "uniprot": "Q4DIF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIF0"
  },
  {
    "uniprot": "Q4CP23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP23"
  },
  {
    "uniprot": "Q4DTY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTY8"
  },
  {
    "uniprot": "Q4DPU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPU0"
  },
  {
    "uniprot": "Q4CVI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVI1"
  },
  {
    "uniprot": "Q4CQ98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ98"
  },
  {
    "uniprot": "Q4D9D9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9D9"
  },
  {
    "uniprot": "Q4DG13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG13"
  },
  {
    "uniprot": "Q4CNW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNW6"
  },
  {
    "uniprot": "Q4CKD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKD2"
  },
  {
    "uniprot": "Q4CXV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXV6"
  },
  {
    "uniprot": "Q4DCK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCK8"
  },
  {
    "uniprot": "Q4E1J5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1J5"
  },
  {
    "uniprot": "Q4CMJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMJ3"
  },
  {
    "uniprot": "Q4DA41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA41"
  },
  {
    "uniprot": "Q4D6B8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6B8"
  },
  {
    "uniprot": "Q4E2C2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2C2"
  },
  {
    "uniprot": "Q4D679",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D679"
  },
  {
    "uniprot": "Q4CNE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNE5"
  },
  {
    "uniprot": "Q4D6Z1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Z1"
  },
  {
    "uniprot": "Q4DTU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTU4"
  },
  {
    "uniprot": "Q4CSW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSW8"
  },
  {
    "uniprot": "Q4E0U5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0U5"
  },
  {
    "uniprot": "Q4DW92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW92"
  },
  {
    "uniprot": "Q4DCQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCQ9"
  },
  {
    "uniprot": "Q4DHY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHY4"
  },
  {
    "uniprot": "Q4DY80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY80"
  },
  {
    "uniprot": "Q4DFT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFT9"
  },
  {
    "uniprot": "Q4E007",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E007"
  },
  {
    "uniprot": "Q4DWY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWY4"
  },
  {
    "uniprot": "Q4DE13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE13"
  },
  {
    "uniprot": "Q4D8M6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8M6"
  },
  {
    "uniprot": "Q4DW19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW19"
  },
  {
    "uniprot": "Q4CQ58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ58"
  },
  {
    "uniprot": "Q4E3X2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3X2"
  },
  {
    "uniprot": "Q4CNY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNY0"
  },
  {
    "uniprot": "Q4DHZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHZ3"
  },
  {
    "uniprot": "Q4DDF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDF5"
  },
  {
    "uniprot": "Q4DQA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQA9"
  },
  {
    "uniprot": "Q4DZG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZG4"
  },
  {
    "uniprot": "Q4DHU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHU2"
  },
  {
    "uniprot": "Q4CV96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV96"
  },
  {
    "uniprot": "Q4D988",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D988"
  },
  {
    "uniprot": "Q4E1J6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1J6"
  },
  {
    "uniprot": "Q4CML0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CML0"
  },
  {
    "uniprot": "Q4CZQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZQ8"
  },
  {
    "uniprot": "Q4CVV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVV6"
  },
  {
    "uniprot": "Q4CSH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSH9"
  },
  {
    "uniprot": "Q4CX03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX03"
  },
  {
    "uniprot": "Q4CYX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYX4"
  },
  {
    "uniprot": "Q4CZV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZV9"
  },
  {
    "uniprot": "Q4DCL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCL7"
  },
  {
    "uniprot": "Q4CUE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUE7"
  },
  {
    "uniprot": "Q4D6Z2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Z2"
  },
  {
    "uniprot": "Q4CLT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLT4"
  },
  {
    "uniprot": "Q4D238",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D238"
  },
  {
    "uniprot": "Q4DH59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH59"
  },
  {
    "uniprot": "Q4CYR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYR8"
  },
  {
    "uniprot": "Q4DP50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP50"
  },
  {
    "uniprot": "Q4CTJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTJ4"
  },
  {
    "uniprot": "Q4DW41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW41"
  },
  {
    "uniprot": "Q4D2F1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2F1"
  },
  {
    "uniprot": "Q4DMI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMI3"
  },
  {
    "uniprot": "Q4D785",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D785"
  },
  {
    "uniprot": "Q4DHW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHW2"
  },
  {
    "uniprot": "Q4DX51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX51"
  },
  {
    "uniprot": "Q4DGJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGJ7"
  },
  {
    "uniprot": "Q4DDZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDZ2"
  },
  {
    "uniprot": "Q4DL59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL59"
  },
  {
    "uniprot": "Q4DT69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT69"
  },
  {
    "uniprot": "Q4DN25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN25"
  },
  {
    "uniprot": "Q4E1J2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1J2"
  },
  {
    "uniprot": "Q4DNC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNC9"
  },
  {
    "uniprot": "Q4D9N9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9N9"
  },
  {
    "uniprot": "Q4DNR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNR5"
  },
  {
    "uniprot": "Q4D5C0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5C0"
  },
  {
    "uniprot": "Q4DAD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAD1"
  },
  {
    "uniprot": "Q4DVI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVI2"
  },
  {
    "uniprot": "Q4D866",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D866"
  },
  {
    "uniprot": "Q4D9L4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9L4"
  },
  {
    "uniprot": "Q4D6J1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6J1"
  },
  {
    "uniprot": "Q4DNU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNU5"
  },
  {
    "uniprot": "Q4DBG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBG5"
  },
  {
    "uniprot": "Q4D6J3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6J3"
  },
  {
    "uniprot": "Q4DT03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT03"
  },
  {
    "uniprot": "Q4DSH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSH4"
  },
  {
    "uniprot": "Q4DM05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM05"
  },
  {
    "uniprot": "Q4D6N1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6N1"
  },
  {
    "uniprot": "Q4DBQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBQ6"
  },
  {
    "uniprot": "Q4DQD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQD2"
  },
  {
    "uniprot": "Q4DZL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZL3"
  },
  {
    "uniprot": "Q4CUB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUB9"
  },
  {
    "uniprot": "Q4E5R5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5R5"
  },
  {
    "uniprot": "Q4DS71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS71"
  },
  {
    "uniprot": "Q4D2W3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2W3"
  },
  {
    "uniprot": "Q4CSZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSZ5"
  },
  {
    "uniprot": "Q4D1X4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1X4"
  },
  {
    "uniprot": "Q4CR52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR52"
  },
  {
    "uniprot": "Q4DN43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN43"
  },
  {
    "uniprot": "Q4CUT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUT9"
  },
  {
    "uniprot": "Q4DR77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR77"
  },
  {
    "uniprot": "Q4D399",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D399"
  },
  {
    "uniprot": "Q4E2Z3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Z3"
  },
  {
    "uniprot": "Q4DW86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW86"
  },
  {
    "uniprot": "Q4D8A3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8A3"
  },
  {
    "uniprot": "Q4DX06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX06"
  },
  {
    "uniprot": "Q4CLB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLB2"
  },
  {
    "uniprot": "Q4DVZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVZ0"
  },
  {
    "uniprot": "Q4E2S4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2S4"
  },
  {
    "uniprot": "Q4CZV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZV2"
  },
  {
    "uniprot": "Q4E2P2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2P2"
  },
  {
    "uniprot": "Q4D0K5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0K5"
  },
  {
    "uniprot": "Q4DL81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL81"
  },
  {
    "uniprot": "Q4D7M2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7M2"
  },
  {
    "uniprot": "Q4DUF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUF7"
  },
  {
    "uniprot": "Q4DGV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGV7"
  },
  {
    "uniprot": "Q4E1C3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1C3"
  },
  {
    "uniprot": "Q4CRL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRL7"
  },
  {
    "uniprot": "Q4DGZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGZ2"
  },
  {
    "uniprot": "Q4DUI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUI1"
  },
  {
    "uniprot": "Q4E3N1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3N1"
  },
  {
    "uniprot": "Q4E1K1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1K1"
  },
  {
    "uniprot": "Q4CM45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM45"
  },
  {
    "uniprot": "Q4CTT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTT6"
  },
  {
    "uniprot": "Q4DSI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSI5"
  },
  {
    "uniprot": "Q4DIS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIS1"
  },
  {
    "uniprot": "Q4E2G6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2G6"
  },
  {
    "uniprot": "Q4DCB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCB8"
  },
  {
    "uniprot": "Q4DQV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQV5"
  },
  {
    "uniprot": "Q4DPD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPD7"
  },
  {
    "uniprot": "Q4CTR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTR3"
  },
  {
    "uniprot": "Q4DW36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW36"
  },
  {
    "uniprot": "Q4E311",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E311"
  },
  {
    "uniprot": "Q4DAQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAQ1"
  },
  {
    "uniprot": "Q4CRU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRU1"
  },
  {
    "uniprot": "Q4DHG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHG2"
  },
  {
    "uniprot": "Q4E0J8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0J8"
  },
  {
    "uniprot": "Q4CMG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMG8"
  },
  {
    "uniprot": "Q4E5L8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5L8"
  },
  {
    "uniprot": "Q4DSZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSZ5"
  },
  {
    "uniprot": "Q4D9I7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9I7"
  },
  {
    "uniprot": "Q4DTE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTE6"
  },
  {
    "uniprot": "Q4CYQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYQ6"
  },
  {
    "uniprot": "Q4D234",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D234"
  },
  {
    "uniprot": "Q4DGK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGK0"
  },
  {
    "uniprot": "Q4CSY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSY8"
  },
  {
    "uniprot": "Q4DF84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF84"
  },
  {
    "uniprot": "Q4DBZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBZ1"
  },
  {
    "uniprot": "Q4DZX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZX3"
  },
  {
    "uniprot": "Q4DV44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV44"
  },
  {
    "uniprot": "Q4CPJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPJ8"
  },
  {
    "uniprot": "Q4DEP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEP9"
  },
  {
    "uniprot": "Q4E2F5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2F5"
  },
  {
    "uniprot": "Q4D107",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D107"
  },
  {
    "uniprot": "Q4DAU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAU3"
  },
  {
    "uniprot": "Q4DHW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHW5"
  },
  {
    "uniprot": "Q4CRP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRP6"
  },
  {
    "uniprot": "Q4CT83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT83"
  },
  {
    "uniprot": "Q4DM60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM60"
  },
  {
    "uniprot": "Q4D7W3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7W3"
  },
  {
    "uniprot": "Q4DVP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVP4"
  },
  {
    "uniprot": "Q4CL80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL80"
  },
  {
    "uniprot": "Q4D2B7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2B7"
  },
  {
    "uniprot": "Q4E479",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E479"
  },
  {
    "uniprot": "Q4D4P1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4P1"
  },
  {
    "uniprot": "Q4CXJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXJ0"
  },
  {
    "uniprot": "Q4DKN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKN3"
  },
  {
    "uniprot": "Q4DFM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFM4"
  },
  {
    "uniprot": "Q4CX21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX21"
  },
  {
    "uniprot": "Q4CNJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNJ8"
  },
  {
    "uniprot": "Q4CNQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNQ2"
  },
  {
    "uniprot": "Q4DY28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY28"
  },
  {
    "uniprot": "Q4DNF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNF6"
  },
  {
    "uniprot": "Q4E002",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E002"
  },
  {
    "uniprot": "Q4DYZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYZ8"
  },
  {
    "uniprot": "Q4DXD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXD3"
  },
  {
    "uniprot": "Q4DVR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVR5"
  },
  {
    "uniprot": "Q4DDB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDB4"
  },
  {
    "uniprot": "Q4DI48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI48"
  },
  {
    "uniprot": "Q4E207",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E207"
  },
  {
    "uniprot": "Q4D5P5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5P5"
  },
  {
    "uniprot": "Q4D7S4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7S4"
  },
  {
    "uniprot": "Q4D3C4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3C4"
  },
  {
    "uniprot": "Q4DLC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLC9"
  },
  {
    "uniprot": "Q4E4G0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4G0"
  },
  {
    "uniprot": "Q4DM31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM31"
  },
  {
    "uniprot": "Q4CUZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUZ7"
  },
  {
    "uniprot": "Q4DGH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGH9"
  },
  {
    "uniprot": "Q4D1A3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1A3"
  },
  {
    "uniprot": "Q4D0X1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0X1"
  },
  {
    "uniprot": "Q4DVC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVC1"
  },
  {
    "uniprot": "Q4CW29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW29"
  },
  {
    "uniprot": "Q4DAA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAA5"
  },
  {
    "uniprot": "Q4D8P3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8P3"
  },
  {
    "uniprot": "Q4CVV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVV9"
  },
  {
    "uniprot": "Q4DQR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQR7"
  },
  {
    "uniprot": "Q4D2B4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2B4"
  },
  {
    "uniprot": "Q4CPE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPE2"
  },
  {
    "uniprot": "Q4E1M2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1M2"
  },
  {
    "uniprot": "Q4DTD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTD4"
  },
  {
    "uniprot": "Q4E4C4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4C4"
  },
  {
    "uniprot": "Q4CRE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRE9"
  },
  {
    "uniprot": "Q4CZD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZD1"
  },
  {
    "uniprot": "Q4DU36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU36"
  },
  {
    "uniprot": "Q4D7D5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7D5"
  },
  {
    "uniprot": "Q4D8W3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8W3"
  },
  {
    "uniprot": "Q4D1U8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1U8"
  },
  {
    "uniprot": "Q4CQQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQQ3"
  },
  {
    "uniprot": "Q4DTH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTH7"
  },
  {
    "uniprot": "Q4CRV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRV4"
  },
  {
    "uniprot": "Q4DY91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY91"
  },
  {
    "uniprot": "Q4DQL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQL6"
  },
  {
    "uniprot": "Q4D4Q4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Q4"
  },
  {
    "uniprot": "Q4CPR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPR0"
  },
  {
    "uniprot": "Q4DSV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSV9"
  },
  {
    "uniprot": "Q4D0B3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0B3"
  },
  {
    "uniprot": "Q4DB73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB73"
  },
  {
    "uniprot": "Q4DVF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVF0"
  },
  {
    "uniprot": "Q4DGL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGL4"
  },
  {
    "uniprot": "Q4D906",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D906"
  },
  {
    "uniprot": "Q4DWW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWW2"
  },
  {
    "uniprot": "Q4D3U7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3U7"
  },
  {
    "uniprot": "Q4DVW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVW7"
  },
  {
    "uniprot": "Q4DMC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMC8"
  },
  {
    "uniprot": "Q4E1C6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1C6"
  },
  {
    "uniprot": "Q4CPP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPP7"
  },
  {
    "uniprot": "Q4E590",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E590"
  },
  {
    "uniprot": "Q4CT58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT58"
  },
  {
    "uniprot": "Q4DSU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSU6"
  },
  {
    "uniprot": "Q4CV01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV01"
  },
  {
    "uniprot": "Q4D8V5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8V5"
  },
  {
    "uniprot": "Q4DLL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLL8"
  },
  {
    "uniprot": "Q4DQS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQS2"
  },
  {
    "uniprot": "Q4DMG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMG9"
  },
  {
    "uniprot": "Q4DYF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYF1"
  },
  {
    "uniprot": "Q4DJF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJF6"
  },
  {
    "uniprot": "Q4E210",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E210"
  },
  {
    "uniprot": "Q4CMB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMB3"
  },
  {
    "uniprot": "Q4DQW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQW0"
  },
  {
    "uniprot": "Q4DJB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJB8"
  },
  {
    "uniprot": "Q4D3X2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3X2"
  },
  {
    "uniprot": "Q4DYP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYP6"
  },
  {
    "uniprot": "Q4DVU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVU4"
  },
  {
    "uniprot": "Q4DEU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEU5"
  },
  {
    "uniprot": "Q4CMF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMF7"
  },
  {
    "uniprot": "Q4CPM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPM8"
  },
  {
    "uniprot": "Q4DST4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DST4"
  },
  {
    "uniprot": "Q4DVF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVF7"
  },
  {
    "uniprot": "Q4D732",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D732"
  },
  {
    "uniprot": "Q4DHG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHG0"
  },
  {
    "uniprot": "Q4D1J6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1J6"
  },
  {
    "uniprot": "Q4DCZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCZ8"
  },
  {
    "uniprot": "Q4DB66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB66"
  },
  {
    "uniprot": "Q4DVM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVM9"
  },
  {
    "uniprot": "Q4CZY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZY5"
  },
  {
    "uniprot": "Q4DZP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZP7"
  },
  {
    "uniprot": "Q4CNK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNK7"
  },
  {
    "uniprot": "Q4DA74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA74"
  },
  {
    "uniprot": "Q4DSC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSC6"
  },
  {
    "uniprot": "Q4DAM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAM3"
  },
  {
    "uniprot": "Q4CW07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW07"
  },
  {
    "uniprot": "Q4DWN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWN6"
  },
  {
    "uniprot": "Q4DBS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBS5"
  },
  {
    "uniprot": "Q4DH65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH65"
  },
  {
    "uniprot": "Q4D281",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D281"
  },
  {
    "uniprot": "Q4DKK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKK5"
  },
  {
    "uniprot": "Q4DJF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJF4"
  },
  {
    "uniprot": "Q4D250",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D250"
  },
  {
    "uniprot": "Q4D6B1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6B1"
  },
  {
    "uniprot": "Q4DV37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV37"
  },
  {
    "uniprot": "Q4E409",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E409"
  },
  {
    "uniprot": "Q4E3T7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3T7"
  },
  {
    "uniprot": "Q4DMK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMK1"
  },
  {
    "uniprot": "Q4DUV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUV1"
  },
  {
    "uniprot": "Q4CVB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVB1"
  },
  {
    "uniprot": "Q4CWK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWK1"
  },
  {
    "uniprot": "Q4DDP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDP1"
  },
  {
    "uniprot": "Q4DMX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMX3"
  },
  {
    "uniprot": "Q4CVP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVP5"
  },
  {
    "uniprot": "Q4CYH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYH2"
  },
  {
    "uniprot": "Q4CVN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVN9"
  },
  {
    "uniprot": "Q4DZ62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ62"
  },
  {
    "uniprot": "Q4CN22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN22"
  },
  {
    "uniprot": "Q4E099",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E099"
  },
  {
    "uniprot": "Q4CL50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL50"
  },
  {
    "uniprot": "Q4D948",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D948"
  },
  {
    "uniprot": "Q4DPG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPG6"
  },
  {
    "uniprot": "Q4DGG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGG3"
  },
  {
    "uniprot": "Q4DM66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM66"
  },
  {
    "uniprot": "Q4E0S3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0S3"
  },
  {
    "uniprot": "Q4D6R3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6R3"
  },
  {
    "uniprot": "Q4DQK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQK8"
  },
  {
    "uniprot": "Q4D0Q6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Q6"
  },
  {
    "uniprot": "Q4D3V3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3V3"
  },
  {
    "uniprot": "Q4D544",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D544"
  },
  {
    "uniprot": "Q4CKV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKV6"
  },
  {
    "uniprot": "Q4E0P2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0P2"
  },
  {
    "uniprot": "Q4D516",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D516"
  },
  {
    "uniprot": "Q4CQS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQS4"
  },
  {
    "uniprot": "Q4CMW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMW6"
  },
  {
    "uniprot": "Q4D037",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D037"
  },
  {
    "uniprot": "Q4CMH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMH1"
  },
  {
    "uniprot": "Q4E337",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E337"
  },
  {
    "uniprot": "Q4CPQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPQ6"
  },
  {
    "uniprot": "Q4DS07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS07"
  },
  {
    "uniprot": "Q4DAT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAT9"
  },
  {
    "uniprot": "Q4D1Y9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Y9"
  },
  {
    "uniprot": "Q4DLU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLU6"
  },
  {
    "uniprot": "Q4E484",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E484"
  },
  {
    "uniprot": "Q4CR87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR87"
  },
  {
    "uniprot": "Q4CTW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTW8"
  },
  {
    "uniprot": "Q4CN21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN21"
  },
  {
    "uniprot": "Q4DAD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAD7"
  },
  {
    "uniprot": "Q4CPK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPK3"
  },
  {
    "uniprot": "Q4DCW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCW5"
  },
  {
    "uniprot": "Q4D072",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D072"
  },
  {
    "uniprot": "Q4DHW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHW9"
  },
  {
    "uniprot": "Q4CKP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKP3"
  },
  {
    "uniprot": "Q4DWS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWS4"
  },
  {
    "uniprot": "Q4DWD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWD8"
  },
  {
    "uniprot": "Q4D0V2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0V2"
  },
  {
    "uniprot": "Q4CM09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM09"
  },
  {
    "uniprot": "Q4CPZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPZ8"
  },
  {
    "uniprot": "Q4DA70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA70"
  },
  {
    "uniprot": "Q4CV40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV40"
  },
  {
    "uniprot": "Q4DBX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBX1"
  },
  {
    "uniprot": "Q4DGG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGG9"
  },
  {
    "uniprot": "Q4DD38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD38"
  },
  {
    "uniprot": "Q4DQD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQD6"
  },
  {
    "uniprot": "Q4DYK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYK9"
  },
  {
    "uniprot": "Q4DFM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFM0"
  },
  {
    "uniprot": "Q4DZJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZJ6"
  },
  {
    "uniprot": "Q4CQS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQS8"
  },
  {
    "uniprot": "Q4E2Q9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Q9"
  },
  {
    "uniprot": "Q4D7I1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7I1"
  },
  {
    "uniprot": "Q4CUI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUI4"
  },
  {
    "uniprot": "Q4CW77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW77"
  },
  {
    "uniprot": "Q4DQN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQN4"
  },
  {
    "uniprot": "Q4CV20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV20"
  },
  {
    "uniprot": "Q4D0M4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0M4"
  },
  {
    "uniprot": "Q4CSG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSG5"
  },
  {
    "uniprot": "Q4DE23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE23"
  },
  {
    "uniprot": "Q4D0R2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0R2"
  },
  {
    "uniprot": "Q4E117",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E117"
  },
  {
    "uniprot": "Q4DI14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI14"
  },
  {
    "uniprot": "Q4DDZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDZ9"
  },
  {
    "uniprot": "Q4CQA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQA2"
  },
  {
    "uniprot": "Q4DNU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNU1"
  },
  {
    "uniprot": "Q4CLT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLT5"
  },
  {
    "uniprot": "Q4CQZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQZ0"
  },
  {
    "uniprot": "Q4DSW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSW1"
  },
  {
    "uniprot": "Q4D6D3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6D3"
  },
  {
    "uniprot": "Q4E0W5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0W5"
  },
  {
    "uniprot": "Q4D5V9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5V9"
  },
  {
    "uniprot": "Q4DJ71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ71"
  },
  {
    "uniprot": "Q4DK63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK63"
  },
  {
    "uniprot": "Q4E308",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E308"
  },
  {
    "uniprot": "Q4E1V9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1V9"
  },
  {
    "uniprot": "Q4DQC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQC3"
  },
  {
    "uniprot": "Q4DQN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQN0"
  },
  {
    "uniprot": "Q4E487",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E487"
  },
  {
    "uniprot": "Q4CZM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZM5"
  },
  {
    "uniprot": "Q4DFW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFW5"
  },
  {
    "uniprot": "Q4CQR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQR1"
  },
  {
    "uniprot": "Q4DVT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVT0"
  },
  {
    "uniprot": "Q4CPJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPJ4"
  },
  {
    "uniprot": "Q4D4H6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4H6"
  },
  {
    "uniprot": "Q4D7X9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7X9"
  },
  {
    "uniprot": "Q4D4X0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4X0"
  },
  {
    "uniprot": "Q4CZI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZI7"
  },
  {
    "uniprot": "Q4DUX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUX6"
  },
  {
    "uniprot": "Q4E2A2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2A2"
  },
  {
    "uniprot": "Q4DP98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP98"
  },
  {
    "uniprot": "Q4CV58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV58"
  },
  {
    "uniprot": "Q4DUM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUM4"
  },
  {
    "uniprot": "Q4CTE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTE1"
  },
  {
    "uniprot": "Q4DG12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG12"
  },
  {
    "uniprot": "Q4D4R8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4R8"
  },
  {
    "uniprot": "Q4D4R5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4R5"
  },
  {
    "uniprot": "Q4E0V7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0V7"
  },
  {
    "uniprot": "Q4DZQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZQ3"
  },
  {
    "uniprot": "Q4DJU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJU3"
  },
  {
    "uniprot": "Q4CUW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUW9"
  },
  {
    "uniprot": "Q4DSD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSD2"
  },
  {
    "uniprot": "Q4D9V0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9V0"
  },
  {
    "uniprot": "Q4DJM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJM8"
  },
  {
    "uniprot": "Q4CLW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLW4"
  },
  {
    "uniprot": "Q4DGW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGW7"
  },
  {
    "uniprot": "Q4D1T2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1T2"
  },
  {
    "uniprot": "Q4D1G7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1G7"
  },
  {
    "uniprot": "Q4E2X2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2X2"
  },
  {
    "uniprot": "Q4E5B7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5B7"
  },
  {
    "uniprot": "Q4D5F6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5F6"
  },
  {
    "uniprot": "Q4DDN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDN7"
  },
  {
    "uniprot": "Q4CNN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNN2"
  },
  {
    "uniprot": "Q4DWI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWI8"
  },
  {
    "uniprot": "Q4DJP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJP7"
  },
  {
    "uniprot": "Q4DVZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVZ4"
  },
  {
    "uniprot": "Q4CQH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQH4"
  },
  {
    "uniprot": "Q4DI90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI90"
  },
  {
    "uniprot": "Q4E4K7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4K7"
  },
  {
    "uniprot": "Q4D355",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D355"
  },
  {
    "uniprot": "Q4DAC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAC1"
  },
  {
    "uniprot": "Q4CZ19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ19"
  },
  {
    "uniprot": "Q4DYF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYF9"
  },
  {
    "uniprot": "Q4DQZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQZ3"
  },
  {
    "uniprot": "Q4DL62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL62"
  },
  {
    "uniprot": "Q4DKX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKX6"
  },
  {
    "uniprot": "Q4DHV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHV6"
  },
  {
    "uniprot": "Q4DAN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAN1"
  },
  {
    "uniprot": "Q4DI93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI93"
  },
  {
    "uniprot": "Q4CUT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUT7"
  },
  {
    "uniprot": "Q4D3Q9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Q9"
  },
  {
    "uniprot": "Q4D9A8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9A8"
  },
  {
    "uniprot": "Q4DZB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZB6"
  },
  {
    "uniprot": "Q4CZD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZD5"
  },
  {
    "uniprot": "Q4DF99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF99"
  },
  {
    "uniprot": "Q4DXU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXU8"
  },
  {
    "uniprot": "Q4DD53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD53"
  },
  {
    "uniprot": "Q4E0D1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0D1"
  },
  {
    "uniprot": "Q4DE31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE31"
  },
  {
    "uniprot": "Q4CVP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVP2"
  },
  {
    "uniprot": "Q4CWF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWF6"
  },
  {
    "uniprot": "Q4E5K9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5K9"
  },
  {
    "uniprot": "Q4CRC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRC9"
  },
  {
    "uniprot": "Q4DKT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKT4"
  },
  {
    "uniprot": "Q4D749",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D749"
  },
  {
    "uniprot": "Q4CS88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS88"
  },
  {
    "uniprot": "Q4CSP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSP6"
  },
  {
    "uniprot": "Q4DH33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH33"
  },
  {
    "uniprot": "Q4DGC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGC5"
  },
  {
    "uniprot": "Q4D6Y1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Y1"
  },
  {
    "uniprot": "Q4DBA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBA6"
  },
  {
    "uniprot": "Q4DBG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBG2"
  },
  {
    "uniprot": "Q4CW59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW59"
  },
  {
    "uniprot": "Q4DA61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA61"
  },
  {
    "uniprot": "Q4DPL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPL0"
  },
  {
    "uniprot": "Q4D9E0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9E0"
  },
  {
    "uniprot": "Q4DFQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFQ3"
  },
  {
    "uniprot": "Q4D2V0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2V0"
  },
  {
    "uniprot": "Q4CXD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXD0"
  },
  {
    "uniprot": "Q4CLN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLN6"
  },
  {
    "uniprot": "Q4DHL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHL3"
  },
  {
    "uniprot": "Q4E3H9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3H9"
  },
  {
    "uniprot": "Q4CP20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP20"
  },
  {
    "uniprot": "Q4DR69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR69"
  },
  {
    "uniprot": "Q4CMV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMV6"
  },
  {
    "uniprot": "Q4D2N8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2N8"
  },
  {
    "uniprot": "Q4CU12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU12"
  },
  {
    "uniprot": "Q4DY18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY18"
  },
  {
    "uniprot": "Q4E1C5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1C5"
  },
  {
    "uniprot": "Q4DQ08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ08"
  },
  {
    "uniprot": "Q4DYZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYZ7"
  },
  {
    "uniprot": "Q4E612",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E612"
  },
  {
    "uniprot": "Q4DDY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDY5"
  },
  {
    "uniprot": "Q4D297",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D297"
  },
  {
    "uniprot": "Q4CTG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTG6"
  },
  {
    "uniprot": "Q4DXM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXM4"
  },
  {
    "uniprot": "Q4D3F5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3F5"
  },
  {
    "uniprot": "Q4DKZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKZ1"
  },
  {
    "uniprot": "Q4DAT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAT4"
  },
  {
    "uniprot": "Q4D6U9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6U9"
  },
  {
    "uniprot": "Q4CUM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUM1"
  },
  {
    "uniprot": "Q4DTG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTG4"
  },
  {
    "uniprot": "Q4DY32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY32"
  },
  {
    "uniprot": "Q4CQ97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ97"
  },
  {
    "uniprot": "Q4DBY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBY7"
  },
  {
    "uniprot": "Q4CMF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMF0"
  },
  {
    "uniprot": "Q4E5I3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5I3"
  },
  {
    "uniprot": "Q4DJ03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ03"
  },
  {
    "uniprot": "Q4DEH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEH9"
  },
  {
    "uniprot": "Q4D5U0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5U0"
  },
  {
    "uniprot": "Q4DFV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFV2"
  },
  {
    "uniprot": "Q4DC30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC30"
  },
  {
    "uniprot": "Q4E045",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E045"
  },
  {
    "uniprot": "Q4DQC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQC1"
  },
  {
    "uniprot": "Q4E0F6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0F6"
  },
  {
    "uniprot": "Q4CMP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMP5"
  },
  {
    "uniprot": "Q4DYK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYK2"
  },
  {
    "uniprot": "Q4DHQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHQ4"
  },
  {
    "uniprot": "Q4DDK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDK8"
  },
  {
    "uniprot": "Q4DGK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGK3"
  },
  {
    "uniprot": "Q4D9G1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9G1"
  },
  {
    "uniprot": "Q4CYT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYT5"
  },
  {
    "uniprot": "Q4CKB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKB5"
  },
  {
    "uniprot": "Q4CP81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP81"
  },
  {
    "uniprot": "Q4DD80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD80"
  },
  {
    "uniprot": "Q4DEL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEL1"
  },
  {
    "uniprot": "Q4CTJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTJ2"
  },
  {
    "uniprot": "Q4DPW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPW2"
  },
  {
    "uniprot": "Q4DGU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGU0"
  },
  {
    "uniprot": "Q4D6J9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6J9"
  },
  {
    "uniprot": "Q4E0R9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0R9"
  },
  {
    "uniprot": "Q4D627",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D627"
  },
  {
    "uniprot": "Q4D1Y0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Y0"
  },
  {
    "uniprot": "Q4CQE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQE7"
  },
  {
    "uniprot": "Q4CWG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWG2"
  },
  {
    "uniprot": "Q4D9W9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9W9"
  },
  {
    "uniprot": "Q4DZN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZN5"
  },
  {
    "uniprot": "Q4DGX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGX2"
  },
  {
    "uniprot": "Q4D4F2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4F2"
  },
  {
    "uniprot": "Q4DD64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD64"
  },
  {
    "uniprot": "Q4CRQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRQ3"
  },
  {
    "uniprot": "Q4DE40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE40"
  },
  {
    "uniprot": "Q4DWM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWM9"
  },
  {
    "uniprot": "Q4DKF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKF9"
  },
  {
    "uniprot": "Q4DC70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC70"
  },
  {
    "uniprot": "Q4DSJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSJ1"
  },
  {
    "uniprot": "Q4DBA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBA8"
  },
  {
    "uniprot": "Q4DZ61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ61"
  },
  {
    "uniprot": "Q4CW15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW15"
  },
  {
    "uniprot": "Q4DRS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRS1"
  },
  {
    "uniprot": "Q4CLX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLX5"
  },
  {
    "uniprot": "Q4CTB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTB5"
  },
  {
    "uniprot": "Q4DVJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVJ8"
  },
  {
    "uniprot": "Q4DHB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHB4"
  },
  {
    "uniprot": "Q4CY83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY83"
  },
  {
    "uniprot": "Q4CM02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM02"
  },
  {
    "uniprot": "Q4DR43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR43"
  },
  {
    "uniprot": "Q4DF40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF40"
  },
  {
    "uniprot": "Q4CY61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY61"
  },
  {
    "uniprot": "Q4DA78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA78"
  },
  {
    "uniprot": "Q4D654",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D654"
  },
  {
    "uniprot": "Q4DXL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXL3"
  },
  {
    "uniprot": "Q4DVF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVF6"
  },
  {
    "uniprot": "Q4CQU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQU9"
  },
  {
    "uniprot": "Q4CY02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY02"
  },
  {
    "uniprot": "Q4D2U2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2U2"
  },
  {
    "uniprot": "Q4D4T5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4T5"
  },
  {
    "uniprot": "Q4CMG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMG6"
  },
  {
    "uniprot": "Q4CVQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVQ2"
  },
  {
    "uniprot": "Q4CZN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZN0"
  },
  {
    "uniprot": "Q4D326",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D326"
  },
  {
    "uniprot": "Q4E2L3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2L3"
  },
  {
    "uniprot": "Q4D0N0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0N0"
  },
  {
    "uniprot": "Q4E0S0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0S0"
  },
  {
    "uniprot": "Q4D155",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D155"
  },
  {
    "uniprot": "Q4DNG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNG9"
  },
  {
    "uniprot": "Q4DII8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DII8"
  },
  {
    "uniprot": "Q4DLN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLN6"
  },
  {
    "uniprot": "Q4CXX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXX7"
  },
  {
    "uniprot": "Q4DDA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDA4"
  },
  {
    "uniprot": "Q4DJC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJC2"
  },
  {
    "uniprot": "Q4CPE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPE3"
  },
  {
    "uniprot": "Q4DKH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKH2"
  },
  {
    "uniprot": "Q4CS17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS17"
  },
  {
    "uniprot": "Q4CKF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKF1"
  },
  {
    "uniprot": "Q4E488",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E488"
  },
  {
    "uniprot": "Q4CKK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKK5"
  },
  {
    "uniprot": "Q4CWM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWM1"
  },
  {
    "uniprot": "Q4DHF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHF4"
  },
  {
    "uniprot": "Q4CZ98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ98"
  },
  {
    "uniprot": "Q4E2M7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2M7"
  },
  {
    "uniprot": "Q4DFS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFS1"
  },
  {
    "uniprot": "Q4D4I5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4I5"
  },
  {
    "uniprot": "Q4D351",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D351"
  },
  {
    "uniprot": "Q4DIV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIV9"
  },
  {
    "uniprot": "Q4D9C7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9C7"
  },
  {
    "uniprot": "Q4DMW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMW7"
  },
  {
    "uniprot": "Q4DP16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP16"
  },
  {
    "uniprot": "Q4DDT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDT0"
  },
  {
    "uniprot": "Q4CRG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRG6"
  },
  {
    "uniprot": "Q4E272",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E272"
  },
  {
    "uniprot": "Q4DAF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAF5"
  },
  {
    "uniprot": "Q4D364",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D364"
  },
  {
    "uniprot": "Q4CV06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV06"
  },
  {
    "uniprot": "Q4DG39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG39"
  },
  {
    "uniprot": "Q4CV26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV26"
  },
  {
    "uniprot": "Q4DUD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUD0"
  },
  {
    "uniprot": "Q4E4W9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4W9"
  },
  {
    "uniprot": "Q4D2A0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2A0"
  },
  {
    "uniprot": "Q4CXI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXI8"
  },
  {
    "uniprot": "Q4D360",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D360"
  },
  {
    "uniprot": "Q4DWP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWP8"
  },
  {
    "uniprot": "Q4DWY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWY2"
  },
  {
    "uniprot": "Q4D6H3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6H3"
  },
  {
    "uniprot": "Q4DCW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCW6"
  },
  {
    "uniprot": "Q4DLP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLP9"
  },
  {
    "uniprot": "Q4CS23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS23"
  },
  {
    "uniprot": "Q4DVG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVG1"
  },
  {
    "uniprot": "Q4CWV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWV2"
  },
  {
    "uniprot": "Q4CSZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSZ8"
  },
  {
    "uniprot": "Q4E592",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E592"
  },
  {
    "uniprot": "Q4CRT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRT6"
  },
  {
    "uniprot": "Q4DYY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYY8"
  },
  {
    "uniprot": "Q4CTU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTU3"
  },
  {
    "uniprot": "Q4DCQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCQ0"
  },
  {
    "uniprot": "Q4D2L2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2L2"
  },
  {
    "uniprot": "Q4DC04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC04"
  },
  {
    "uniprot": "Q4E4V7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4V7"
  },
  {
    "uniprot": "Q4D8H2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8H2"
  },
  {
    "uniprot": "Q4DK22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK22"
  },
  {
    "uniprot": "Q4DYK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYK4"
  },
  {
    "uniprot": "Q4CWY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWY5"
  },
  {
    "uniprot": "Q4CSI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSI0"
  },
  {
    "uniprot": "Q4CQW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQW8"
  },
  {
    "uniprot": "Q4DNI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNI1"
  },
  {
    "uniprot": "Q4DR17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR17"
  },
  {
    "uniprot": "Q4CZ12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ12"
  },
  {
    "uniprot": "Q4DZR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZR7"
  },
  {
    "uniprot": "Q4DL52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL52"
  },
  {
    "uniprot": "Q4DFA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFA8"
  },
  {
    "uniprot": "Q4DGE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGE8"
  },
  {
    "uniprot": "Q4D7Q6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Q6"
  },
  {
    "uniprot": "Q4DNT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNT9"
  },
  {
    "uniprot": "Q4D0U5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0U5"
  },
  {
    "uniprot": "Q4DUX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUX5"
  },
  {
    "uniprot": "Q4CN09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN09"
  },
  {
    "uniprot": "Q4D3I7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3I7"
  },
  {
    "uniprot": "Q4CTE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTE0"
  },
  {
    "uniprot": "Q4CP64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP64"
  },
  {
    "uniprot": "Q4DBT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBT3"
  },
  {
    "uniprot": "Q4D950",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D950"
  },
  {
    "uniprot": "Q4E2R5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2R5"
  },
  {
    "uniprot": "Q4E1A2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1A2"
  },
  {
    "uniprot": "Q4DC08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC08"
  },
  {
    "uniprot": "Q4CKT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKT0"
  },
  {
    "uniprot": "Q4DV49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV49"
  },
  {
    "uniprot": "Q4DHJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHJ4"
  },
  {
    "uniprot": "Q4E4C0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4C0"
  },
  {
    "uniprot": "Q4E4F5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4F5"
  },
  {
    "uniprot": "Q4DS78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS78"
  },
  {
    "uniprot": "Q4DE64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE64"
  },
  {
    "uniprot": "Q4DHV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHV3"
  },
  {
    "uniprot": "Q4DWK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWK8"
  },
  {
    "uniprot": "Q4CY56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY56"
  },
  {
    "uniprot": "Q4CRU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRU9"
  },
  {
    "uniprot": "Q4CTA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTA9"
  },
  {
    "uniprot": "Q4CXU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXU4"
  },
  {
    "uniprot": "Q4CN14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN14"
  },
  {
    "uniprot": "Q4DBC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBC8"
  },
  {
    "uniprot": "Q4D9K9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9K9"
  },
  {
    "uniprot": "Q4D1K7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1K7"
  },
  {
    "uniprot": "Q4DHJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHJ8"
  },
  {
    "uniprot": "Q4CRH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRH8"
  },
  {
    "uniprot": "Q4DAS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAS7"
  },
  {
    "uniprot": "Q4DRS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRS6"
  },
  {
    "uniprot": "Q4E1U1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1U1"
  },
  {
    "uniprot": "Q4DPH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPH7"
  },
  {
    "uniprot": "Q4DPC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPC0"
  },
  {
    "uniprot": "Q4D3A1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3A1"
  },
  {
    "uniprot": "Q4DD09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD09"
  },
  {
    "uniprot": "Q4CVC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVC2"
  },
  {
    "uniprot": "Q4E053",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E053"
  },
  {
    "uniprot": "Q4E2F2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2F2"
  },
  {
    "uniprot": "Q4DKA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKA4"
  },
  {
    "uniprot": "Q4D450",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D450"
  },
  {
    "uniprot": "Q4CLE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLE1"
  },
  {
    "uniprot": "Q4CTK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTK0"
  },
  {
    "uniprot": "Q4CXU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXU2"
  },
  {
    "uniprot": "Q4DNG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNG2"
  },
  {
    "uniprot": "Q4DXL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXL0"
  },
  {
    "uniprot": "Q4DE99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE99"
  },
  {
    "uniprot": "Q4D052",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D052"
  },
  {
    "uniprot": "Q4CUT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUT1"
  },
  {
    "uniprot": "Q4CVI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVI8"
  },
  {
    "uniprot": "Q4CNC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNC9"
  },
  {
    "uniprot": "Q4DXB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXB5"
  },
  {
    "uniprot": "Q4D158",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D158"
  },
  {
    "uniprot": "Q4DZN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZN6"
  },
  {
    "uniprot": "Q4D839",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D839"
  },
  {
    "uniprot": "Q4DQR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQR1"
  },
  {
    "uniprot": "Q4CSJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSJ0"
  },
  {
    "uniprot": "Q4D6B2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6B2"
  },
  {
    "uniprot": "Q4DW64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW64"
  },
  {
    "uniprot": "Q4DBZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBZ4"
  },
  {
    "uniprot": "Q4CUD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUD1"
  },
  {
    "uniprot": "Q4CP09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP09"
  },
  {
    "uniprot": "Q4CXY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXY9"
  },
  {
    "uniprot": "Q4D782",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D782"
  },
  {
    "uniprot": "Q4DQ03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ03"
  },
  {
    "uniprot": "Q4DQ38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ38"
  },
  {
    "uniprot": "Q4CTE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTE4"
  },
  {
    "uniprot": "Q4D244",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D244"
  },
  {
    "uniprot": "Q4CWL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWL9"
  },
  {
    "uniprot": "Q4DB30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB30"
  },
  {
    "uniprot": "Q4D4A0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4A0"
  },
  {
    "uniprot": "Q4DY53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY53"
  },
  {
    "uniprot": "Q4DTG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTG5"
  },
  {
    "uniprot": "Q4CW06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW06"
  },
  {
    "uniprot": "Q4DFC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFC7"
  },
  {
    "uniprot": "Q4DQX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQX5"
  },
  {
    "uniprot": "Q4CQI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQI8"
  },
  {
    "uniprot": "Q4DP44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP44"
  },
  {
    "uniprot": "Q4D1C9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1C9"
  },
  {
    "uniprot": "Q4CR39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR39"
  },
  {
    "uniprot": "Q4DJI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJI0"
  },
  {
    "uniprot": "Q4D0D0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0D0"
  },
  {
    "uniprot": "Q4CQ01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ01"
  },
  {
    "uniprot": "Q4E2A0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2A0"
  },
  {
    "uniprot": "Q4CN89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN89"
  },
  {
    "uniprot": "Q4DV91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV91"
  },
  {
    "uniprot": "Q4CQG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQG2"
  },
  {
    "uniprot": "Q4DDQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDQ5"
  },
  {
    "uniprot": "Q4CSM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSM7"
  },
  {
    "uniprot": "Q4CRQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRQ0"
  },
  {
    "uniprot": "Q4CX55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX55"
  },
  {
    "uniprot": "Q4CTN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTN9"
  },
  {
    "uniprot": "Q4D1D5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1D5"
  },
  {
    "uniprot": "Q4CQC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQC7"
  },
  {
    "uniprot": "Q4DV67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV67"
  },
  {
    "uniprot": "Q4DZ10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ10"
  },
  {
    "uniprot": "Q4D0Q9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Q9"
  },
  {
    "uniprot": "Q4DAR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAR9"
  },
  {
    "uniprot": "Q4CYX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYX3"
  },
  {
    "uniprot": "Q4D344",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D344"
  },
  {
    "uniprot": "Q4DC64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC64"
  },
  {
    "uniprot": "Q4E047",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E047"
  },
  {
    "uniprot": "Q4CVZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVZ5"
  },
  {
    "uniprot": "Q4DJS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJS9"
  },
  {
    "uniprot": "Q4CXL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXL5"
  },
  {
    "uniprot": "Q4D7Y2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Y2"
  },
  {
    "uniprot": "Q4DNR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNR8"
  },
  {
    "uniprot": "Q4DBF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBF2"
  },
  {
    "uniprot": "Q4CPD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPD7"
  },
  {
    "uniprot": "Q4DR62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR62"
  },
  {
    "uniprot": "Q4CUX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUX9"
  },
  {
    "uniprot": "Q4CXC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXC4"
  },
  {
    "uniprot": "Q4CWC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWC3"
  },
  {
    "uniprot": "Q4D4X9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4X9"
  },
  {
    "uniprot": "Q4CQ39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ39"
  },
  {
    "uniprot": "Q4CXZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXZ3"
  },
  {
    "uniprot": "Q4DVA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVA4"
  },
  {
    "uniprot": "Q4DKP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKP2"
  },
  {
    "uniprot": "Q4CWG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWG6"
  },
  {
    "uniprot": "Q4CN38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN38"
  },
  {
    "uniprot": "Q4DPL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPL4"
  },
  {
    "uniprot": "Q4D707",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D707"
  },
  {
    "uniprot": "Q4DXQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXQ0"
  },
  {
    "uniprot": "Q4E5Z1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Z1"
  },
  {
    "uniprot": "Q4DDY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDY7"
  },
  {
    "uniprot": "Q4CUP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUP6"
  },
  {
    "uniprot": "Q4DMW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMW3"
  },
  {
    "uniprot": "Q4DCT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCT5"
  },
  {
    "uniprot": "Q4DDZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDZ7"
  },
  {
    "uniprot": "Q4DIG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIG4"
  },
  {
    "uniprot": "Q4DR99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR99"
  },
  {
    "uniprot": "Q4E3G9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3G9"
  },
  {
    "uniprot": "Q4DFW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFW1"
  },
  {
    "uniprot": "Q4E428",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E428"
  },
  {
    "uniprot": "Q4CQB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQB8"
  },
  {
    "uniprot": "Q4DPR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPR3"
  },
  {
    "uniprot": "Q4CTK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTK5"
  },
  {
    "uniprot": "Q4DHK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHK4"
  },
  {
    "uniprot": "Q4DIA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIA7"
  },
  {
    "uniprot": "Q4DUS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUS0"
  },
  {
    "uniprot": "Q4DBS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBS3"
  },
  {
    "uniprot": "Q4CQL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQL9"
  },
  {
    "uniprot": "Q4DIB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIB8"
  },
  {
    "uniprot": "Q4DVM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVM5"
  },
  {
    "uniprot": "Q4DFR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFR9"
  },
  {
    "uniprot": "Q4E5K8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5K8"
  },
  {
    "uniprot": "Q4D726",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D726"
  },
  {
    "uniprot": "Q4DLD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLD6"
  },
  {
    "uniprot": "Q4D6C2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6C2"
  },
  {
    "uniprot": "Q4CSU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSU4"
  },
  {
    "uniprot": "Q4DTK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTK2"
  },
  {
    "uniprot": "Q4D1U9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1U9"
  },
  {
    "uniprot": "Q4DXT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXT4"
  },
  {
    "uniprot": "Q4DWV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWV1"
  },
  {
    "uniprot": "Q4E5S2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5S2"
  },
  {
    "uniprot": "Q4DVP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVP9"
  },
  {
    "uniprot": "Q4CP29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP29"
  },
  {
    "uniprot": "Q4DRF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRF6"
  },
  {
    "uniprot": "Q4E1G9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1G9"
  },
  {
    "uniprot": "Q4E650",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E650"
  },
  {
    "uniprot": "Q4DZZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZZ7"
  },
  {
    "uniprot": "Q4D2J8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2J8"
  },
  {
    "uniprot": "Q4DFL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFL3"
  },
  {
    "uniprot": "Q4DIJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIJ2"
  },
  {
    "uniprot": "Q4DK65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK65"
  },
  {
    "uniprot": "Q4DU06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU06"
  },
  {
    "uniprot": "Q4E2N0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2N0"
  },
  {
    "uniprot": "Q4DIJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIJ1"
  },
  {
    "uniprot": "Q4DKH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKH3"
  },
  {
    "uniprot": "Q4DA23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA23"
  },
  {
    "uniprot": "Q4CYG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYG7"
  },
  {
    "uniprot": "Q4DLE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLE4"
  },
  {
    "uniprot": "Q4CRE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRE1"
  },
  {
    "uniprot": "Q4D9H5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9H5"
  },
  {
    "uniprot": "Q4D0G8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0G8"
  },
  {
    "uniprot": "Q4DYA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYA3"
  },
  {
    "uniprot": "Q4D4T3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4T3"
  },
  {
    "uniprot": "Q4DIA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIA2"
  },
  {
    "uniprot": "Q4D0J8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0J8"
  },
  {
    "uniprot": "Q4DEZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEZ0"
  },
  {
    "uniprot": "Q4DW57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW57"
  },
  {
    "uniprot": "Q4CZB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZB0"
  },
  {
    "uniprot": "Q4DWR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWR8"
  },
  {
    "uniprot": "Q4E1Z6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Z6"
  },
  {
    "uniprot": "Q4D8F8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8F8"
  },
  {
    "uniprot": "Q4DJU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJU8"
  },
  {
    "uniprot": "Q4CN07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN07"
  },
  {
    "uniprot": "Q4CRJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRJ7"
  },
  {
    "uniprot": "Q4DTT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTT1"
  },
  {
    "uniprot": "Q4DPT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPT8"
  },
  {
    "uniprot": "Q4DZ72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ72"
  },
  {
    "uniprot": "Q4CRD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRD8"
  },
  {
    "uniprot": "Q4CQL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQL8"
  },
  {
    "uniprot": "Q4CR47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR47"
  },
  {
    "uniprot": "Q4D1K0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1K0"
  },
  {
    "uniprot": "Q4CSF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSF2"
  },
  {
    "uniprot": "Q4D5T3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5T3"
  },
  {
    "uniprot": "Q4DFK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFK9"
  },
  {
    "uniprot": "Q4E3G8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3G8"
  },
  {
    "uniprot": "Q4DE33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE33"
  },
  {
    "uniprot": "Q4CRJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRJ1"
  },
  {
    "uniprot": "Q4CS43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS43"
  },
  {
    "uniprot": "Q4D2Y2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Y2"
  },
  {
    "uniprot": "Q4E2S8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2S8"
  },
  {
    "uniprot": "Q4CLJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLJ1"
  },
  {
    "uniprot": "Q4DAD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAD2"
  },
  {
    "uniprot": "Q4DJC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJC5"
  },
  {
    "uniprot": "Q4CUR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUR1"
  },
  {
    "uniprot": "Q4DIJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIJ5"
  },
  {
    "uniprot": "Q4E593",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E593"
  },
  {
    "uniprot": "Q4DQB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQB5"
  },
  {
    "uniprot": "Q4CQ85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ85"
  },
  {
    "uniprot": "Q4DZC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZC2"
  },
  {
    "uniprot": "Q4CM12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM12"
  },
  {
    "uniprot": "Q4D657",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D657"
  },
  {
    "uniprot": "Q4D241",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D241"
  },
  {
    "uniprot": "Q4DJ37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ37"
  },
  {
    "uniprot": "Q4DC19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC19"
  },
  {
    "uniprot": "Q4DD73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD73"
  },
  {
    "uniprot": "Q4DDQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDQ2"
  },
  {
    "uniprot": "Q4CV76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV76"
  },
  {
    "uniprot": "Q4DET1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DET1"
  },
  {
    "uniprot": "Q4E591",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E591"
  },
  {
    "uniprot": "Q4D3K0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3K0"
  },
  {
    "uniprot": "Q4DJ57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ57"
  },
  {
    "uniprot": "Q4D8Y9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Y9"
  },
  {
    "uniprot": "Q4DIL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIL1"
  },
  {
    "uniprot": "Q4DS52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS52"
  },
  {
    "uniprot": "Q4DZ20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ20"
  },
  {
    "uniprot": "Q4D3N6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3N6"
  },
  {
    "uniprot": "Q4E0E1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0E1"
  },
  {
    "uniprot": "Q4DFY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFY3"
  },
  {
    "uniprot": "Q4E1N2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1N2"
  },
  {
    "uniprot": "Q4D609",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D609"
  },
  {
    "uniprot": "Q4DYY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYY1"
  },
  {
    "uniprot": "Q4CX06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX06"
  },
  {
    "uniprot": "Q4D1L1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1L1"
  },
  {
    "uniprot": "Q4D0S7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0S7"
  },
  {
    "uniprot": "Q4DZB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZB1"
  },
  {
    "uniprot": "Q4DHG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHG7"
  },
  {
    "uniprot": "Q4DYB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYB8"
  },
  {
    "uniprot": "Q4D2D5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2D5"
  },
  {
    "uniprot": "Q4DEX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEX6"
  },
  {
    "uniprot": "Q4CP74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP74"
  },
  {
    "uniprot": "Q4E3H2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3H2"
  },
  {
    "uniprot": "Q4DRL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRL8"
  },
  {
    "uniprot": "Q4D603",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D603"
  },
  {
    "uniprot": "Q4E2A8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2A8"
  },
  {
    "uniprot": "Q4D2Y1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Y1"
  },
  {
    "uniprot": "Q4CKL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKL2"
  },
  {
    "uniprot": "Q4DE81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE81"
  },
  {
    "uniprot": "Q4DC03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC03"
  },
  {
    "uniprot": "Q4DAQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAQ2"
  },
  {
    "uniprot": "Q4E583",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E583"
  },
  {
    "uniprot": "Q4D2E0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2E0"
  },
  {
    "uniprot": "Q4D5P9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5P9"
  },
  {
    "uniprot": "Q4D145",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D145"
  },
  {
    "uniprot": "Q4DMD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMD9"
  },
  {
    "uniprot": "Q4CZ43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ43"
  },
  {
    "uniprot": "Q4E537",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E537"
  },
  {
    "uniprot": "Q4CV89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV89"
  },
  {
    "uniprot": "Q4DUR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUR9"
  },
  {
    "uniprot": "Q4DAA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAA3"
  },
  {
    "uniprot": "Q4DVB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVB1"
  },
  {
    "uniprot": "Q4CZQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZQ4"
  },
  {
    "uniprot": "Q4CLY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLY1"
  },
  {
    "uniprot": "Q4DXK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXK9"
  },
  {
    "uniprot": "Q4CS48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS48"
  },
  {
    "uniprot": "Q4D6S6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6S6"
  },
  {
    "uniprot": "Q4E316",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E316"
  },
  {
    "uniprot": "Q4DMW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMW5"
  },
  {
    "uniprot": "Q4CPE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPE9"
  },
  {
    "uniprot": "Q4CYM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYM0"
  },
  {
    "uniprot": "Q4D5P1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5P1"
  },
  {
    "uniprot": "Q4DF78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF78"
  },
  {
    "uniprot": "Q4E030",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E030"
  },
  {
    "uniprot": "Q4CX10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX10"
  },
  {
    "uniprot": "Q4DMJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMJ2"
  },
  {
    "uniprot": "Q4DWJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWJ4"
  },
  {
    "uniprot": "Q4D675",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D675"
  },
  {
    "uniprot": "Q4CT26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT26"
  },
  {
    "uniprot": "Q4DQK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQK7"
  },
  {
    "uniprot": "Q4E472",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E472"
  },
  {
    "uniprot": "Q4DJH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJH5"
  },
  {
    "uniprot": "Q4E1W4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1W4"
  },
  {
    "uniprot": "Q4CTI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTI3"
  },
  {
    "uniprot": "Q4DDK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDK0"
  },
  {
    "uniprot": "Q4DFG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFG2"
  },
  {
    "uniprot": "Q4DGH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGH5"
  },
  {
    "uniprot": "Q4CSE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSE7"
  },
  {
    "uniprot": "Q4DNH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNH8"
  },
  {
    "uniprot": "Q4CWJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWJ7"
  },
  {
    "uniprot": "Q4D6R0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6R0"
  },
  {
    "uniprot": "Q4D3N9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3N9"
  },
  {
    "uniprot": "Q4DUQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUQ5"
  },
  {
    "uniprot": "Q4DVH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVH4"
  },
  {
    "uniprot": "Q4DD27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD27"
  },
  {
    "uniprot": "Q4DDM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDM0"
  },
  {
    "uniprot": "Q4D7D2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7D2"
  },
  {
    "uniprot": "Q4DEI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEI8"
  },
  {
    "uniprot": "Q4DE16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE16"
  },
  {
    "uniprot": "Q4CPD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPD2"
  },
  {
    "uniprot": "Q4DGJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGJ5"
  },
  {
    "uniprot": "Q4CTY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTY0"
  },
  {
    "uniprot": "Q4DCY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCY9"
  },
  {
    "uniprot": "Q4D4C1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4C1"
  },
  {
    "uniprot": "Q4E1L4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1L4"
  },
  {
    "uniprot": "Q4DNR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNR2"
  },
  {
    "uniprot": "Q4DYM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYM9"
  },
  {
    "uniprot": "Q4CS40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS40"
  },
  {
    "uniprot": "Q4CNN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNN4"
  },
  {
    "uniprot": "Q4DB46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB46"
  },
  {
    "uniprot": "Q4DL22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL22"
  },
  {
    "uniprot": "Q4DHX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHX3"
  },
  {
    "uniprot": "Q4DG14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG14"
  },
  {
    "uniprot": "Q4DW31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW31"
  },
  {
    "uniprot": "Q4DSI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSI9"
  },
  {
    "uniprot": "Q4CMR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMR4"
  },
  {
    "uniprot": "Q4D444",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D444"
  },
  {
    "uniprot": "Q4D5K6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5K6"
  },
  {
    "uniprot": "Q4E5B6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5B6"
  },
  {
    "uniprot": "Q4D1N5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1N5"
  },
  {
    "uniprot": "Q4DU70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU70"
  },
  {
    "uniprot": "Q4CW36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW36"
  },
  {
    "uniprot": "Q4DYS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYS4"
  },
  {
    "uniprot": "Q4DZI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZI0"
  },
  {
    "uniprot": "Q4E4E2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4E2"
  },
  {
    "uniprot": "Q4CUI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUI8"
  },
  {
    "uniprot": "Q4DL84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL84"
  },
  {
    "uniprot": "Q4D0Q0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Q0"
  },
  {
    "uniprot": "Q4D3A2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3A2"
  },
  {
    "uniprot": "Q4D2R8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2R8"
  },
  {
    "uniprot": "Q4E2R6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2R6"
  },
  {
    "uniprot": "Q4D484",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D484"
  },
  {
    "uniprot": "Q4DV21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV21"
  },
  {
    "uniprot": "Q4DJ61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ61"
  },
  {
    "uniprot": "Q4CM11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM11"
  },
  {
    "uniprot": "Q4DFQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFQ6"
  },
  {
    "uniprot": "Q4DSM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSM9"
  },
  {
    "uniprot": "Q4CXN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXN6"
  },
  {
    "uniprot": "Q4DUD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUD2"
  },
  {
    "uniprot": "Q4D5J9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5J9"
  },
  {
    "uniprot": "Q4D2I7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2I7"
  },
  {
    "uniprot": "Q4CPK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPK9"
  },
  {
    "uniprot": "Q4DK42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK42"
  },
  {
    "uniprot": "Q4DNT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNT2"
  },
  {
    "uniprot": "Q4DVX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVX1"
  },
  {
    "uniprot": "Q4E3P4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3P4"
  },
  {
    "uniprot": "Q4D9P5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9P5"
  },
  {
    "uniprot": "Q4DYM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYM8"
  },
  {
    "uniprot": "Q4CQ18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ18"
  },
  {
    "uniprot": "Q4D779",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D779"
  },
  {
    "uniprot": "Q4D021",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D021"
  },
  {
    "uniprot": "Q4DPA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPA4"
  },
  {
    "uniprot": "Q4DQB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQB0"
  },
  {
    "uniprot": "Q4DTS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTS7"
  },
  {
    "uniprot": "Q4CKE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKE8"
  },
  {
    "uniprot": "Q4E2Q7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Q7"
  },
  {
    "uniprot": "Q4CSD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSD1"
  },
  {
    "uniprot": "Q4CLI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLI8"
  },
  {
    "uniprot": "Q4DQ46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ46"
  },
  {
    "uniprot": "Q4CNL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNL6"
  },
  {
    "uniprot": "Q4CMP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMP2"
  },
  {
    "uniprot": "Q4DYE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYE8"
  },
  {
    "uniprot": "Q4DRG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRG2"
  },
  {
    "uniprot": "Q4D5R9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5R9"
  },
  {
    "uniprot": "Q4CWF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWF5"
  },
  {
    "uniprot": "Q4DK41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK41"
  },
  {
    "uniprot": "Q4DYD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYD2"
  },
  {
    "uniprot": "Q4DJF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJF2"
  },
  {
    "uniprot": "Q4DHE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHE6"
  },
  {
    "uniprot": "Q4DIH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIH0"
  },
  {
    "uniprot": "Q4DYC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYC1"
  },
  {
    "uniprot": "Q4CZ79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ79"
  },
  {
    "uniprot": "Q4CXU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXU5"
  },
  {
    "uniprot": "Q4D7E9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7E9"
  },
  {
    "uniprot": "Q4DWR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWR4"
  },
  {
    "uniprot": "Q4DG19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG19"
  },
  {
    "uniprot": "Q4DLK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLK7"
  },
  {
    "uniprot": "Q4E017",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E017"
  },
  {
    "uniprot": "Q4DSJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSJ9"
  },
  {
    "uniprot": "Q4DVE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVE0"
  },
  {
    "uniprot": "Q4DZD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZD9"
  },
  {
    "uniprot": "Q4CSW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSW2"
  },
  {
    "uniprot": "Q4DSV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSV5"
  },
  {
    "uniprot": "Q4DSV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSV6"
  },
  {
    "uniprot": "Q4DRP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRP9"
  },
  {
    "uniprot": "Q4DCE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCE7"
  },
  {
    "uniprot": "Q4D9F1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9F1"
  },
  {
    "uniprot": "Q4E611",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E611"
  },
  {
    "uniprot": "Q4DLL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLL1"
  },
  {
    "uniprot": "Q4CZ82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ82"
  },
  {
    "uniprot": "Q4DAR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAR0"
  },
  {
    "uniprot": "Q4E483",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E483"
  },
  {
    "uniprot": "Q4CT84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT84"
  },
  {
    "uniprot": "Q4CVH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVH4"
  },
  {
    "uniprot": "Q4DHS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHS3"
  },
  {
    "uniprot": "Q4DXX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXX5"
  },
  {
    "uniprot": "Q4CLP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLP9"
  },
  {
    "uniprot": "Q4DP62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP62"
  },
  {
    "uniprot": "Q4DBB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBB7"
  },
  {
    "uniprot": "Q4E4G6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4G6"
  },
  {
    "uniprot": "Q4CKH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKH4"
  },
  {
    "uniprot": "Q4DB60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB60"
  },
  {
    "uniprot": "Q4E690",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E690"
  },
  {
    "uniprot": "Q4DSQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSQ9"
  },
  {
    "uniprot": "Q4D8X3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8X3"
  },
  {
    "uniprot": "Q4CWZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWZ2"
  },
  {
    "uniprot": "Q4CUW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUW1"
  },
  {
    "uniprot": "Q4DPD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPD9"
  },
  {
    "uniprot": "Q4DJK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJK8"
  },
  {
    "uniprot": "Q4CY23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY23"
  },
  {
    "uniprot": "Q4CPC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPC5"
  },
  {
    "uniprot": "Q4D0S9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0S9"
  },
  {
    "uniprot": "Q4DLF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLF9"
  },
  {
    "uniprot": "Q4D7P1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7P1"
  },
  {
    "uniprot": "Q4D0R5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0R5"
  },
  {
    "uniprot": "Q4DGB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGB5"
  },
  {
    "uniprot": "Q4DHE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHE1"
  },
  {
    "uniprot": "Q4DFE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFE1"
  },
  {
    "uniprot": "Q4DVA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVA3"
  },
  {
    "uniprot": "Q4CU11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU11"
  },
  {
    "uniprot": "Q4DVD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVD9"
  },
  {
    "uniprot": "Q4E101",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E101"
  },
  {
    "uniprot": "Q4DX15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX15"
  },
  {
    "uniprot": "Q4DY13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY13"
  },
  {
    "uniprot": "Q4CKR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKR2"
  },
  {
    "uniprot": "Q4E071",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E071"
  },
  {
    "uniprot": "Q4DZF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZF1"
  },
  {
    "uniprot": "Q4CY46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY46"
  },
  {
    "uniprot": "Q4DF57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF57"
  },
  {
    "uniprot": "Q4E061",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E061"
  },
  {
    "uniprot": "Q4CRI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRI8"
  },
  {
    "uniprot": "Q4CVP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVP3"
  },
  {
    "uniprot": "Q4DUT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUT9"
  },
  {
    "uniprot": "Q4DBD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBD9"
  },
  {
    "uniprot": "Q4D7T0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7T0"
  },
  {
    "uniprot": "Q4DMF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMF8"
  },
  {
    "uniprot": "Q4DU44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU44"
  },
  {
    "uniprot": "Q4DFE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFE7"
  },
  {
    "uniprot": "Q4CT56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT56"
  },
  {
    "uniprot": "Q4E512",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E512"
  },
  {
    "uniprot": "Q4E114",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E114"
  },
  {
    "uniprot": "Q4DHN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHN9"
  },
  {
    "uniprot": "Q4E4P7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4P7"
  },
  {
    "uniprot": "Q4D4F5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4F5"
  },
  {
    "uniprot": "Q4DMP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMP7"
  },
  {
    "uniprot": "Q4DD22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD22"
  },
  {
    "uniprot": "Q4D1P9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1P9"
  },
  {
    "uniprot": "Q4CS53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS53"
  },
  {
    "uniprot": "Q4CMR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMR9"
  },
  {
    "uniprot": "Q4D5X8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5X8"
  },
  {
    "uniprot": "Q4CVK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVK1"
  },
  {
    "uniprot": "Q4DIK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIK8"
  },
  {
    "uniprot": "Q4DB80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB80"
  },
  {
    "uniprot": "Q4CWX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWX4"
  },
  {
    "uniprot": "Q4DQI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQI1"
  },
  {
    "uniprot": "Q4CWY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWY6"
  },
  {
    "uniprot": "Q4D8R3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8R3"
  },
  {
    "uniprot": "Q4DVJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVJ9"
  },
  {
    "uniprot": "Q4DDL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDL9"
  },
  {
    "uniprot": "Q4E3X0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3X0"
  },
  {
    "uniprot": "Q4D0H4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0H4"
  },
  {
    "uniprot": "Q4DZA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZA8"
  },
  {
    "uniprot": "Q4DMR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMR6"
  },
  {
    "uniprot": "Q4DC72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC72"
  },
  {
    "uniprot": "Q4DJD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJD5"
  },
  {
    "uniprot": "Q4DFP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFP3"
  },
  {
    "uniprot": "Q4DTV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTV1"
  },
  {
    "uniprot": "Q4D340",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D340"
  },
  {
    "uniprot": "Q4D3V4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3V4"
  },
  {
    "uniprot": "Q4D046",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D046"
  },
  {
    "uniprot": "Q4E059",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E059"
  },
  {
    "uniprot": "Q4DA06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA06"
  },
  {
    "uniprot": "Q4CKN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKN5"
  },
  {
    "uniprot": "Q4DBH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBH4"
  },
  {
    "uniprot": "Q4D868",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D868"
  },
  {
    "uniprot": "Q4DB62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB62"
  },
  {
    "uniprot": "Q4CMT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMT8"
  },
  {
    "uniprot": "Q4D1V8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1V8"
  },
  {
    "uniprot": "Q4CLD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLD1"
  },
  {
    "uniprot": "Q4E4R4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4R4"
  },
  {
    "uniprot": "Q4DMV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMV8"
  },
  {
    "uniprot": "Q4DY43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY43"
  },
  {
    "uniprot": "Q4DWP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWP0"
  },
  {
    "uniprot": "Q4CSB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSB1"
  },
  {
    "uniprot": "Q4E122",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E122"
  },
  {
    "uniprot": "Q4DDU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDU4"
  },
  {
    "uniprot": "Q4CXH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXH9"
  },
  {
    "uniprot": "Q4CTB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTB6"
  },
  {
    "uniprot": "Q4DMF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMF7"
  },
  {
    "uniprot": "Q4D215",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D215"
  },
  {
    "uniprot": "Q4DM57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM57"
  },
  {
    "uniprot": "Q4D9B3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9B3"
  },
  {
    "uniprot": "Q4CS87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS87"
  },
  {
    "uniprot": "Q4DPV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPV9"
  },
  {
    "uniprot": "Q4DHE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHE3"
  },
  {
    "uniprot": "Q4D9Z2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Z2"
  },
  {
    "uniprot": "Q4DLY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLY2"
  },
  {
    "uniprot": "Q4CL75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL75"
  },
  {
    "uniprot": "Q4D530",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D530"
  },
  {
    "uniprot": "Q4CTS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTS6"
  },
  {
    "uniprot": "Q4DEM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEM1"
  },
  {
    "uniprot": "Q4D4N2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4N2"
  },
  {
    "uniprot": "Q4E105",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E105"
  },
  {
    "uniprot": "Q4E4N0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4N0"
  },
  {
    "uniprot": "Q4D1K5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1K5"
  },
  {
    "uniprot": "Q4CLL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLL2"
  },
  {
    "uniprot": "Q4E0S5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0S5"
  },
  {
    "uniprot": "Q4DZK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZK3"
  },
  {
    "uniprot": "Q4CNP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNP6"
  },
  {
    "uniprot": "Q4D6X9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6X9"
  },
  {
    "uniprot": "Q4CTE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTE3"
  },
  {
    "uniprot": "Q4CT40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT40"
  },
  {
    "uniprot": "Q4E1J8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1J8"
  },
  {
    "uniprot": "Q4CKZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKZ9"
  },
  {
    "uniprot": "Q4D3M9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3M9"
  },
  {
    "uniprot": "Q4DXN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXN8"
  },
  {
    "uniprot": "Q4E221",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E221"
  },
  {
    "uniprot": "Q4E064",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E064"
  },
  {
    "uniprot": "Q4DRB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRB2"
  },
  {
    "uniprot": "Q4DGD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGD1"
  },
  {
    "uniprot": "Q4CPS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPS6"
  },
  {
    "uniprot": "Q4DHS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHS0"
  },
  {
    "uniprot": "Q4CUR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUR3"
  },
  {
    "uniprot": "Q4DWH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWH4"
  },
  {
    "uniprot": "Q4E2P7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2P7"
  },
  {
    "uniprot": "Q4CXA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXA0"
  },
  {
    "uniprot": "Q4DRV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRV4"
  },
  {
    "uniprot": "Q4CZ05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ05"
  },
  {
    "uniprot": "Q4DIX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIX7"
  },
  {
    "uniprot": "Q4D042",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D042"
  },
  {
    "uniprot": "Q4CV74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV74"
  },
  {
    "uniprot": "Q4DDK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDK4"
  },
  {
    "uniprot": "Q4D946",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D946"
  },
  {
    "uniprot": "Q4DCE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCE4"
  },
  {
    "uniprot": "Q4DLZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLZ8"
  },
  {
    "uniprot": "Q4DV27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV27"
  },
  {
    "uniprot": "Q4DH90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH90"
  },
  {
    "uniprot": "Q4CUK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUK3"
  },
  {
    "uniprot": "Q4DUX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUX9"
  },
  {
    "uniprot": "Q4DE92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE92"
  },
  {
    "uniprot": "Q4CUA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUA7"
  },
  {
    "uniprot": "Q4CVS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVS6"
  },
  {
    "uniprot": "Q4CYC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYC3"
  },
  {
    "uniprot": "Q4DC50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC50"
  },
  {
    "uniprot": "Q4DD62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD62"
  },
  {
    "uniprot": "Q4DLJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLJ6"
  },
  {
    "uniprot": "Q4CX62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX62"
  },
  {
    "uniprot": "Q4CTP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTP1"
  },
  {
    "uniprot": "Q4DNJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNJ9"
  },
  {
    "uniprot": "Q4DIG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIG9"
  },
  {
    "uniprot": "Q4DA42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA42"
  },
  {
    "uniprot": "Q4DPX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPX5"
  },
  {
    "uniprot": "Q4DW46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW46"
  },
  {
    "uniprot": "Q4DFP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFP8"
  },
  {
    "uniprot": "Q4D2I8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2I8"
  },
  {
    "uniprot": "Q4D4J8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4J8"
  },
  {
    "uniprot": "Q4CSR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSR5"
  },
  {
    "uniprot": "Q4DAE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAE0"
  },
  {
    "uniprot": "Q4CRB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRB5"
  },
  {
    "uniprot": "Q4D8H6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8H6"
  },
  {
    "uniprot": "Q4CLC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLC2"
  },
  {
    "uniprot": "Q4DZ41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ41"
  },
  {
    "uniprot": "Q4CTF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTF9"
  },
  {
    "uniprot": "Q4CZ39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ39"
  },
  {
    "uniprot": "Q4DME9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DME9"
  },
  {
    "uniprot": "Q4D2U3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2U3"
  },
  {
    "uniprot": "Q4D225",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D225"
  },
  {
    "uniprot": "Q4CRT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRT9"
  },
  {
    "uniprot": "Q4D9P6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9P6"
  },
  {
    "uniprot": "Q4E1T1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1T1"
  },
  {
    "uniprot": "Q4D0N2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0N2"
  },
  {
    "uniprot": "Q4D098",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D098"
  },
  {
    "uniprot": "Q4DHX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHX8"
  },
  {
    "uniprot": "Q4DY07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY07"
  },
  {
    "uniprot": "Q4DE90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE90"
  },
  {
    "uniprot": "Q4DEC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEC9"
  },
  {
    "uniprot": "Q4DQ95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ95"
  },
  {
    "uniprot": "Q4DM46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM46"
  },
  {
    "uniprot": "Q4CTH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTH8"
  },
  {
    "uniprot": "Q4CZW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZW2"
  },
  {
    "uniprot": "Q4CY75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY75"
  },
  {
    "uniprot": "Q4CSM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSM8"
  },
  {
    "uniprot": "Q4CU03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU03"
  },
  {
    "uniprot": "Q4D0F9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0F9"
  },
  {
    "uniprot": "Q4DI06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI06"
  },
  {
    "uniprot": "Q4DJL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJL6"
  },
  {
    "uniprot": "Q4CUS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUS9"
  },
  {
    "uniprot": "Q4DSF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSF9"
  },
  {
    "uniprot": "Q4DMC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMC5"
  },
  {
    "uniprot": "Q4DAL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAL2"
  },
  {
    "uniprot": "Q4D9K0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9K0"
  },
  {
    "uniprot": "Q4DWF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWF5"
  },
  {
    "uniprot": "Q4DAR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAR5"
  },
  {
    "uniprot": "Q4E1R6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1R6"
  },
  {
    "uniprot": "Q4DM09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM09"
  },
  {
    "uniprot": "Q4DWI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWI3"
  },
  {
    "uniprot": "Q4DC38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC38"
  },
  {
    "uniprot": "Q4CN24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN24"
  },
  {
    "uniprot": "Q4CWJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWJ8"
  },
  {
    "uniprot": "Q4D896",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D896"
  },
  {
    "uniprot": "Q4DA14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA14"
  },
  {
    "uniprot": "Q4D8Z0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Z0"
  },
  {
    "uniprot": "Q4CUF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUF0"
  },
  {
    "uniprot": "Q4DGD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGD6"
  },
  {
    "uniprot": "Q4CLT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLT2"
  },
  {
    "uniprot": "Q4CMK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMK6"
  },
  {
    "uniprot": "Q4CV68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV68"
  },
  {
    "uniprot": "Q4D9K6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9K6"
  },
  {
    "uniprot": "Q4D7S1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7S1"
  },
  {
    "uniprot": "Q4DIU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIU6"
  },
  {
    "uniprot": "Q4DAN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAN3"
  },
  {
    "uniprot": "Q4CZ69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ69"
  },
  {
    "uniprot": "Q4DTL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTL8"
  },
  {
    "uniprot": "Q4D1L3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1L3"
  },
  {
    "uniprot": "Q4CMW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMW9"
  },
  {
    "uniprot": "Q4D793",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D793"
  },
  {
    "uniprot": "Q4D174",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D174"
  },
  {
    "uniprot": "Q4DJV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJV7"
  },
  {
    "uniprot": "Q4DTA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTA5"
  },
  {
    "uniprot": "Q4D862",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D862"
  },
  {
    "uniprot": "Q4D3N1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3N1"
  },
  {
    "uniprot": "Q4CT10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT10"
  },
  {
    "uniprot": "Q4E4R3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4R3"
  },
  {
    "uniprot": "Q4CSQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSQ2"
  },
  {
    "uniprot": "Q4DWZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWZ2"
  },
  {
    "uniprot": "Q4DYY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYY2"
  },
  {
    "uniprot": "Q4DVX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVX0"
  },
  {
    "uniprot": "Q4DVQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVQ5"
  },
  {
    "uniprot": "Q4CSX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSX3"
  },
  {
    "uniprot": "Q4CKF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKF3"
  },
  {
    "uniprot": "Q4DYE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYE0"
  },
  {
    "uniprot": "Q4CPM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPM1"
  },
  {
    "uniprot": "Q4CP97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP97"
  },
  {
    "uniprot": "Q4DII0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DII0"
  },
  {
    "uniprot": "Q4CRR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRR6"
  },
  {
    "uniprot": "Q4DCU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCU3"
  },
  {
    "uniprot": "Q4DZW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZW3"
  },
  {
    "uniprot": "Q4DEC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEC0"
  },
  {
    "uniprot": "Q4DFM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFM3"
  },
  {
    "uniprot": "Q4E1Q4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Q4"
  },
  {
    "uniprot": "Q4E3S2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3S2"
  },
  {
    "uniprot": "Q4CRT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRT2"
  },
  {
    "uniprot": "Q4D504",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D504"
  },
  {
    "uniprot": "Q4CU44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU44"
  },
  {
    "uniprot": "Q4D7V9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7V9"
  },
  {
    "uniprot": "Q4D283",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D283"
  },
  {
    "uniprot": "Q4D2H0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2H0"
  },
  {
    "uniprot": "Q4D8F3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8F3"
  },
  {
    "uniprot": "Q4E3C5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3C5"
  },
  {
    "uniprot": "Q4DT40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT40"
  },
  {
    "uniprot": "Q4CLC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLC5"
  },
  {
    "uniprot": "Q4E4Z4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Z4"
  },
  {
    "uniprot": "Q4D6F3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6F3"
  },
  {
    "uniprot": "Q4DG68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG68"
  },
  {
    "uniprot": "Q4E4J7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4J7"
  },
  {
    "uniprot": "Q4DXB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXB8"
  },
  {
    "uniprot": "Q4DWQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWQ6"
  },
  {
    "uniprot": "Q4DTW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTW4"
  },
  {
    "uniprot": "Q4DNX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNX9"
  },
  {
    "uniprot": "Q4DME4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DME4"
  },
  {
    "uniprot": "Q4D0U9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0U9"
  },
  {
    "uniprot": "Q4CL18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL18"
  },
  {
    "uniprot": "Q4CNN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNN8"
  },
  {
    "uniprot": "Q4E553",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E553"
  },
  {
    "uniprot": "Q4CSW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSW7"
  },
  {
    "uniprot": "Q4DKS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKS5"
  },
  {
    "uniprot": "Q4CVL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVL4"
  },
  {
    "uniprot": "Q4D4J9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4J9"
  },
  {
    "uniprot": "Q4CMY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMY5"
  },
  {
    "uniprot": "Q4DZ90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ90"
  },
  {
    "uniprot": "Q4DAB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAB2"
  },
  {
    "uniprot": "Q4DGH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGH8"
  },
  {
    "uniprot": "Q4CR71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR71"
  },
  {
    "uniprot": "Q4DVS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVS2"
  },
  {
    "uniprot": "Q4E664",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E664"
  },
  {
    "uniprot": "Q4DNI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNI7"
  },
  {
    "uniprot": "Q4E111",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E111"
  },
  {
    "uniprot": "Q4D7K9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7K9"
  },
  {
    "uniprot": "Q4D0G1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0G1"
  },
  {
    "uniprot": "Q4D433",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D433"
  },
  {
    "uniprot": "Q4CNZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNZ8"
  },
  {
    "uniprot": "Q4CR49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR49"
  },
  {
    "uniprot": "Q4CWM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWM4"
  },
  {
    "uniprot": "Q4CRZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRZ7"
  },
  {
    "uniprot": "Q4CW99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW99"
  },
  {
    "uniprot": "Q4D0E8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0E8"
  },
  {
    "uniprot": "Q4DAX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAX1"
  },
  {
    "uniprot": "Q4D1I0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1I0"
  },
  {
    "uniprot": "Q4D368",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D368"
  },
  {
    "uniprot": "Q4DSH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSH6"
  },
  {
    "uniprot": "Q4CNL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNL0"
  },
  {
    "uniprot": "Q4CQ26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ26"
  },
  {
    "uniprot": "Q4DEM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEM2"
  },
  {
    "uniprot": "Q4DFV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFV8"
  },
  {
    "uniprot": "Q4DDH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDH0"
  },
  {
    "uniprot": "Q4D7C4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7C4"
  },
  {
    "uniprot": "Q4DJW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJW8"
  },
  {
    "uniprot": "Q4CVA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVA0"
  },
  {
    "uniprot": "Q4CSS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSS6"
  },
  {
    "uniprot": "Q4DWI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWI6"
  },
  {
    "uniprot": "Q4CLX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLX7"
  },
  {
    "uniprot": "Q4D835",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D835"
  },
  {
    "uniprot": "Q4CTK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTK9"
  },
  {
    "uniprot": "Q4D9J2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9J2"
  },
  {
    "uniprot": "Q4DDM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDM7"
  },
  {
    "uniprot": "Q4DZV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZV9"
  },
  {
    "uniprot": "Q4DHR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHR1"
  },
  {
    "uniprot": "Q4CVW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVW3"
  },
  {
    "uniprot": "Q4CSL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSL2"
  },
  {
    "uniprot": "Q4E5E4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5E4"
  },
  {
    "uniprot": "Q4DTU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTU3"
  },
  {
    "uniprot": "Q4DSL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSL1"
  },
  {
    "uniprot": "Q4D255",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D255"
  },
  {
    "uniprot": "Q4E118",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E118"
  },
  {
    "uniprot": "Q4CZN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZN4"
  },
  {
    "uniprot": "Q4DGZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGZ1"
  },
  {
    "uniprot": "Q4DMR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMR9"
  },
  {
    "uniprot": "Q4D513",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D513"
  },
  {
    "uniprot": "Q4E5H5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5H5"
  },
  {
    "uniprot": "Q4DEI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEI1"
  },
  {
    "uniprot": "Q4DZK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZK2"
  },
  {
    "uniprot": "Q4CSZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSZ3"
  },
  {
    "uniprot": "Q4D4Q9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Q9"
  },
  {
    "uniprot": "Q4D4W6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4W6"
  },
  {
    "uniprot": "Q4DX72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX72"
  },
  {
    "uniprot": "Q4CLL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLL4"
  },
  {
    "uniprot": "Q4D4R9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4R9"
  },
  {
    "uniprot": "Q4D9S6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9S6"
  },
  {
    "uniprot": "Q4DN67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN67"
  },
  {
    "uniprot": "Q4CPV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPV2"
  },
  {
    "uniprot": "Q4E3H4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3H4"
  },
  {
    "uniprot": "Q4DF10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF10"
  },
  {
    "uniprot": "Q4E5U3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5U3"
  },
  {
    "uniprot": "Q4DTN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTN5"
  },
  {
    "uniprot": "Q4CUK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUK7"
  },
  {
    "uniprot": "Q4DUZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUZ2"
  },
  {
    "uniprot": "Q4DN41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN41"
  },
  {
    "uniprot": "Q4CNR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNR5"
  },
  {
    "uniprot": "Q4D8M8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8M8"
  },
  {
    "uniprot": "Q4E5W2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5W2"
  },
  {
    "uniprot": "Q4E5F5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5F5"
  },
  {
    "uniprot": "Q4E4G3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4G3"
  },
  {
    "uniprot": "Q4D3L6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3L6"
  },
  {
    "uniprot": "Q4D1H1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1H1"
  },
  {
    "uniprot": "Q4DI67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI67"
  },
  {
    "uniprot": "Q4DDL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDL3"
  },
  {
    "uniprot": "Q4CXJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXJ9"
  },
  {
    "uniprot": "Q4D8H4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8H4"
  },
  {
    "uniprot": "Q4D1E4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1E4"
  },
  {
    "uniprot": "Q4CV31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV31"
  },
  {
    "uniprot": "Q4CZS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZS7"
  },
  {
    "uniprot": "Q4DIM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIM3"
  },
  {
    "uniprot": "Q4CN40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN40"
  },
  {
    "uniprot": "Q4E1Z0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Z0"
  },
  {
    "uniprot": "Q4DQT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQT5"
  },
  {
    "uniprot": "Q4D2F8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2F8"
  },
  {
    "uniprot": "Q4CZJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZJ8"
  },
  {
    "uniprot": "Q4CP25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP25"
  },
  {
    "uniprot": "Q4DGW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGW0"
  },
  {
    "uniprot": "Q4DYF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYF5"
  },
  {
    "uniprot": "Q4DI79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI79"
  },
  {
    "uniprot": "Q4CV14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV14"
  },
  {
    "uniprot": "Q4DZB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZB4"
  },
  {
    "uniprot": "Q4DDC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDC2"
  },
  {
    "uniprot": "Q4DHI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHI2"
  },
  {
    "uniprot": "Q4D9S0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9S0"
  },
  {
    "uniprot": "Q4DDT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDT2"
  },
  {
    "uniprot": "Q4CVJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVJ3"
  },
  {
    "uniprot": "Q4DAK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAK2"
  },
  {
    "uniprot": "Q4DWK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWK3"
  },
  {
    "uniprot": "Q4DI53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI53"
  },
  {
    "uniprot": "Q4E2I9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2I9"
  },
  {
    "uniprot": "Q4D9Y4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Y4"
  },
  {
    "uniprot": "Q4E4V5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4V5"
  },
  {
    "uniprot": "Q4D6X6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6X6"
  },
  {
    "uniprot": "Q4DZ44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ44"
  },
  {
    "uniprot": "Q4E535",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E535"
  },
  {
    "uniprot": "Q4DXV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXV1"
  },
  {
    "uniprot": "Q4D304",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D304"
  },
  {
    "uniprot": "Q4DH91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH91"
  },
  {
    "uniprot": "Q4DR93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR93"
  },
  {
    "uniprot": "Q4D8K5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8K5"
  },
  {
    "uniprot": "Q4E4H0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4H0"
  },
  {
    "uniprot": "Q4DE70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE70"
  },
  {
    "uniprot": "Q4E4P2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4P2"
  },
  {
    "uniprot": "Q4DUL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUL8"
  },
  {
    "uniprot": "Q4DEN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEN9"
  },
  {
    "uniprot": "Q4CXW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXW4"
  },
  {
    "uniprot": "Q4DPE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPE9"
  },
  {
    "uniprot": "Q4DQX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQX1"
  },
  {
    "uniprot": "Q4CKI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKI2"
  },
  {
    "uniprot": "Q4E5W8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5W8"
  },
  {
    "uniprot": "Q4CYD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYD4"
  },
  {
    "uniprot": "Q4CW50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW50"
  },
  {
    "uniprot": "Q4CNK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNK0"
  },
  {
    "uniprot": "Q4E412",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E412"
  },
  {
    "uniprot": "Q4DLY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLY5"
  },
  {
    "uniprot": "Q4DMK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMK5"
  },
  {
    "uniprot": "Q4D400",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D400"
  },
  {
    "uniprot": "Q4DRI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRI5"
  },
  {
    "uniprot": "Q4DM36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM36"
  },
  {
    "uniprot": "Q4DJQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJQ0"
  },
  {
    "uniprot": "Q4DM88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM88"
  },
  {
    "uniprot": "Q4CSZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSZ4"
  },
  {
    "uniprot": "Q4E1H3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1H3"
  },
  {
    "uniprot": "Q4DVC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVC9"
  },
  {
    "uniprot": "Q4D0L2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0L2"
  },
  {
    "uniprot": "Q4CZI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZI5"
  },
  {
    "uniprot": "Q4DPV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPV8"
  },
  {
    "uniprot": "Q4DYP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYP8"
  },
  {
    "uniprot": "Q4DRK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRK6"
  },
  {
    "uniprot": "Q4DR31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR31"
  },
  {
    "uniprot": "Q4D4H9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4H9"
  },
  {
    "uniprot": "Q4DZE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZE8"
  },
  {
    "uniprot": "Q4DYX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYX6"
  },
  {
    "uniprot": "Q4DLG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLG1"
  },
  {
    "uniprot": "Q4CUS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUS2"
  },
  {
    "uniprot": "Q4DI19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI19"
  },
  {
    "uniprot": "Q4DMG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMG4"
  },
  {
    "uniprot": "Q4DRX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRX5"
  },
  {
    "uniprot": "Q4CRW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRW4"
  },
  {
    "uniprot": "Q4DD28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD28"
  },
  {
    "uniprot": "Q4D914",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D914"
  },
  {
    "uniprot": "Q4DE44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE44"
  },
  {
    "uniprot": "Q4DSE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSE8"
  },
  {
    "uniprot": "Q4D5L9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5L9"
  },
  {
    "uniprot": "Q4D1P0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1P0"
  },
  {
    "uniprot": "Q4CN20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN20"
  },
  {
    "uniprot": "Q4CPL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPL7"
  },
  {
    "uniprot": "Q4DHT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHT6"
  },
  {
    "uniprot": "Q4D458",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D458"
  },
  {
    "uniprot": "Q4DE29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE29"
  },
  {
    "uniprot": "Q4DWA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWA6"
  },
  {
    "uniprot": "Q4D8N5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8N5"
  },
  {
    "uniprot": "Q4CR95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR95"
  },
  {
    "uniprot": "Q4DP22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP22"
  },
  {
    "uniprot": "Q4D9H4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9H4"
  },
  {
    "uniprot": "Q4CYT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYT6"
  },
  {
    "uniprot": "Q4DYS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYS0"
  },
  {
    "uniprot": "Q4DYV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYV2"
  },
  {
    "uniprot": "Q4DL08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL08"
  },
  {
    "uniprot": "Q4DAI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAI5"
  },
  {
    "uniprot": "Q4D1F4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1F4"
  },
  {
    "uniprot": "Q4DQZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQZ5"
  },
  {
    "uniprot": "Q4CNB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNB0"
  },
  {
    "uniprot": "Q4D7L1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7L1"
  },
  {
    "uniprot": "Q4E5S9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5S9"
  },
  {
    "uniprot": "Q4D905",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D905"
  },
  {
    "uniprot": "Q4CMG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMG9"
  },
  {
    "uniprot": "Q4DM50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM50"
  },
  {
    "uniprot": "Q4DCC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCC4"
  },
  {
    "uniprot": "Q4DYJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYJ4"
  },
  {
    "uniprot": "Q4DGI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGI7"
  },
  {
    "uniprot": "Q4E042",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E042"
  },
  {
    "uniprot": "Q4CW56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW56"
  },
  {
    "uniprot": "Q4CK95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CK95"
  },
  {
    "uniprot": "Q4E285",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E285"
  },
  {
    "uniprot": "Q4D8V7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8V7"
  },
  {
    "uniprot": "Q4CNC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNC2"
  },
  {
    "uniprot": "Q4DAM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAM9"
  },
  {
    "uniprot": "Q4DDU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDU9"
  },
  {
    "uniprot": "Q4E223",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E223"
  },
  {
    "uniprot": "Q4CQD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQD1"
  },
  {
    "uniprot": "Q4CN34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN34"
  },
  {
    "uniprot": "Q4CP89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP89"
  },
  {
    "uniprot": "Q4E0I8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0I8"
  },
  {
    "uniprot": "Q4D0F7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0F7"
  },
  {
    "uniprot": "Q4DF36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF36"
  },
  {
    "uniprot": "Q4D2N1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2N1"
  },
  {
    "uniprot": "Q4E2Z9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Z9"
  },
  {
    "uniprot": "Q4DI82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI82"
  },
  {
    "uniprot": "Q4DF61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF61"
  },
  {
    "uniprot": "Q4DSL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSL2"
  },
  {
    "uniprot": "Q4E366",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E366"
  },
  {
    "uniprot": "Q4DCI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCI9"
  },
  {
    "uniprot": "Q4DAI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAI9"
  },
  {
    "uniprot": "Q4CSY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSY4"
  },
  {
    "uniprot": "Q4CPJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPJ0"
  },
  {
    "uniprot": "Q4CWI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWI6"
  },
  {
    "uniprot": "Q4CKE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKE7"
  },
  {
    "uniprot": "Q4E309",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E309"
  },
  {
    "uniprot": "Q4DR39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR39"
  },
  {
    "uniprot": "Q4D9D8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9D8"
  },
  {
    "uniprot": "Q4E5M4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5M4"
  },
  {
    "uniprot": "Q4DEX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEX3"
  },
  {
    "uniprot": "Q4DTD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTD8"
  },
  {
    "uniprot": "Q4DZ06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ06"
  },
  {
    "uniprot": "Q4CY26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY26"
  },
  {
    "uniprot": "Q4DDE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDE8"
  },
  {
    "uniprot": "Q4CKH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKH0"
  },
  {
    "uniprot": "Q4D5D1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5D1"
  },
  {
    "uniprot": "Q4DC05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC05"
  },
  {
    "uniprot": "Q4DMW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMW0"
  },
  {
    "uniprot": "Q4CP98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP98"
  },
  {
    "uniprot": "Q4D582",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D582"
  },
  {
    "uniprot": "Q4DNI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNI2"
  },
  {
    "uniprot": "Q4CYE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYE1"
  },
  {
    "uniprot": "Q4D7H7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7H7"
  },
  {
    "uniprot": "Q4DN64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN64"
  },
  {
    "uniprot": "Q4E1C0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1C0"
  },
  {
    "uniprot": "Q4E3Q7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Q7"
  },
  {
    "uniprot": "Q4CYS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYS0"
  },
  {
    "uniprot": "Q4CRV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRV8"
  },
  {
    "uniprot": "Q4DZB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZB5"
  },
  {
    "uniprot": "Q4DMD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMD0"
  },
  {
    "uniprot": "Q4DI26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI26"
  },
  {
    "uniprot": "Q4DH52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH52"
  },
  {
    "uniprot": "Q4D2T7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2T7"
  },
  {
    "uniprot": "Q4CVC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVC1"
  },
  {
    "uniprot": "Q4CQA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQA0"
  },
  {
    "uniprot": "Q4D4P7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4P7"
  },
  {
    "uniprot": "Q4D611",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D611"
  },
  {
    "uniprot": "Q4CWC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWC5"
  },
  {
    "uniprot": "Q4DXZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXZ9"
  },
  {
    "uniprot": "Q4D4P9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4P9"
  },
  {
    "uniprot": "Q4E389",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E389"
  },
  {
    "uniprot": "Q4DGJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGJ4"
  },
  {
    "uniprot": "Q4DIW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIW5"
  },
  {
    "uniprot": "Q4DLB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLB9"
  },
  {
    "uniprot": "Q4DPP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPP5"
  },
  {
    "uniprot": "Q4DCY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCY4"
  },
  {
    "uniprot": "Q4DLK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLK1"
  },
  {
    "uniprot": "Q4CR28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR28"
  },
  {
    "uniprot": "Q4CQ60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ60"
  },
  {
    "uniprot": "Q4DRJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRJ7"
  },
  {
    "uniprot": "Q4DJL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJL1"
  },
  {
    "uniprot": "Q4D3L2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3L2"
  },
  {
    "uniprot": "Q4DLS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLS3"
  },
  {
    "uniprot": "Q4D1Y1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Y1"
  },
  {
    "uniprot": "Q4E5I1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5I1"
  },
  {
    "uniprot": "Q4D496",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D496"
  },
  {
    "uniprot": "Q4CYI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYI6"
  },
  {
    "uniprot": "Q4DHC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHC9"
  },
  {
    "uniprot": "Q4CL59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL59"
  },
  {
    "uniprot": "Q4E3G0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3G0"
  },
  {
    "uniprot": "Q4CTE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTE6"
  },
  {
    "uniprot": "Q4DCF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCF9"
  },
  {
    "uniprot": "Q4D5W0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5W0"
  },
  {
    "uniprot": "Q4DLS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLS6"
  },
  {
    "uniprot": "Q4CZS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZS3"
  },
  {
    "uniprot": "Q4DJW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJW6"
  },
  {
    "uniprot": "Q4D850",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D850"
  },
  {
    "uniprot": "Q4D8A2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8A2"
  },
  {
    "uniprot": "Q4D0S2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0S2"
  },
  {
    "uniprot": "Q4DHG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHG5"
  },
  {
    "uniprot": "Q4D0G9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0G9"
  },
  {
    "uniprot": "Q4D0I1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0I1"
  },
  {
    "uniprot": "Q4E2K5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2K5"
  },
  {
    "uniprot": "Q4D436",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D436"
  },
  {
    "uniprot": "Q4DZ38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ38"
  },
  {
    "uniprot": "Q4D9I2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9I2"
  },
  {
    "uniprot": "Q4D5X9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5X9"
  },
  {
    "uniprot": "Q4E5L2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5L2"
  },
  {
    "uniprot": "Q4DD93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD93"
  },
  {
    "uniprot": "Q4D5E8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5E8"
  },
  {
    "uniprot": "Q4DCU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCU1"
  },
  {
    "uniprot": "Q4CSC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSC1"
  },
  {
    "uniprot": "Q4CQ61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ61"
  },
  {
    "uniprot": "Q4DYI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYI0"
  },
  {
    "uniprot": "Q4E4Y6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Y6"
  },
  {
    "uniprot": "Q4DMP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMP0"
  },
  {
    "uniprot": "Q4CLD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLD3"
  },
  {
    "uniprot": "Q4D7Z7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Z7"
  },
  {
    "uniprot": "Q4D0Z3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Z3"
  },
  {
    "uniprot": "Q4DJP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJP0"
  },
  {
    "uniprot": "Q4D670",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D670"
  },
  {
    "uniprot": "Q4DQQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQQ2"
  },
  {
    "uniprot": "Q4E4F0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4F0"
  },
  {
    "uniprot": "Q4DEW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEW4"
  },
  {
    "uniprot": "Q4E1S4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1S4"
  },
  {
    "uniprot": "Q4CP75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP75"
  },
  {
    "uniprot": "Q4D2E4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2E4"
  },
  {
    "uniprot": "Q4DPB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPB2"
  },
  {
    "uniprot": "Q4CRS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRS2"
  },
  {
    "uniprot": "Q4CMJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMJ7"
  },
  {
    "uniprot": "Q4D9U3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9U3"
  },
  {
    "uniprot": "Q4DAR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAR3"
  },
  {
    "uniprot": "Q4CY06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY06"
  },
  {
    "uniprot": "Q4DI49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI49"
  },
  {
    "uniprot": "Q4E3M1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3M1"
  },
  {
    "uniprot": "Q4DFS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFS8"
  },
  {
    "uniprot": "Q4DUH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUH1"
  },
  {
    "uniprot": "Q4DT10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT10"
  },
  {
    "uniprot": "Q4E2S5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2S5"
  },
  {
    "uniprot": "Q4D6Z4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Z4"
  },
  {
    "uniprot": "Q4DPY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPY5"
  },
  {
    "uniprot": "Q4CUV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUV5"
  },
  {
    "uniprot": "Q4CRT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRT7"
  },
  {
    "uniprot": "Q4DBK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBK0"
  },
  {
    "uniprot": "Q4CNU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNU9"
  },
  {
    "uniprot": "Q4E2D5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2D5"
  },
  {
    "uniprot": "Q4DP08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP08"
  },
  {
    "uniprot": "Q4DUW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUW3"
  },
  {
    "uniprot": "Q4E185",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E185"
  },
  {
    "uniprot": "Q4E2T1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2T1"
  },
  {
    "uniprot": "Q4E3V3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3V3"
  },
  {
    "uniprot": "Q4DIB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIB9"
  },
  {
    "uniprot": "Q4DAZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAZ9"
  },
  {
    "uniprot": "Q4DHX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHX1"
  },
  {
    "uniprot": "Q4CNG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNG6"
  },
  {
    "uniprot": "Q4DYY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYY6"
  },
  {
    "uniprot": "Q4DIA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIA5"
  },
  {
    "uniprot": "Q4DVS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVS5"
  },
  {
    "uniprot": "Q4DJN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJN0"
  },
  {
    "uniprot": "Q4DRL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRL6"
  },
  {
    "uniprot": "Q4CRG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRG5"
  },
  {
    "uniprot": "Q4DTF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTF0"
  },
  {
    "uniprot": "Q4DD50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD50"
  },
  {
    "uniprot": "Q4DW38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW38"
  },
  {
    "uniprot": "Q4CTD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTD6"
  },
  {
    "uniprot": "Q4D4P6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4P6"
  },
  {
    "uniprot": "Q4CXL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXL0"
  },
  {
    "uniprot": "Q4CXZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXZ9"
  },
  {
    "uniprot": "Q4DY67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY67"
  },
  {
    "uniprot": "Q4CSA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSA8"
  },
  {
    "uniprot": "Q2VLK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q2VLK8"
  },
  {
    "uniprot": "Q4CNU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNU0"
  },
  {
    "uniprot": "Q4DTZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTZ7"
  },
  {
    "uniprot": "Q4DE00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE00"
  },
  {
    "uniprot": "Q4E189",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E189"
  },
  {
    "uniprot": "Q4CL72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL72"
  },
  {
    "uniprot": "Q4CZM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZM6"
  },
  {
    "uniprot": "Q4DLD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLD7"
  },
  {
    "uniprot": "Q4DCA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCA9"
  },
  {
    "uniprot": "Q4DLX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLX2"
  },
  {
    "uniprot": "Q4DPP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPP6"
  },
  {
    "uniprot": "Q4DVB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVB0"
  },
  {
    "uniprot": "Q4DB31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB31"
  },
  {
    "uniprot": "Q4CM23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM23"
  },
  {
    "uniprot": "Q4D0Q7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Q7"
  },
  {
    "uniprot": "Q4D6K8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6K8"
  },
  {
    "uniprot": "Q4E5R6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5R6"
  },
  {
    "uniprot": "Q4CU56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU56"
  },
  {
    "uniprot": "Q4DEK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEK1"
  },
  {
    "uniprot": "Q4D1Z7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Z7"
  },
  {
    "uniprot": "Q4CZQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZQ9"
  },
  {
    "uniprot": "Q4D5U8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5U8"
  },
  {
    "uniprot": "Q4D7B3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7B3"
  },
  {
    "uniprot": "Q4D1R0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1R0"
  },
  {
    "uniprot": "Q4E1E7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1E7"
  },
  {
    "uniprot": "Q4DI15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI15"
  },
  {
    "uniprot": "Q4D571",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D571"
  },
  {
    "uniprot": "Q4CUB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUB7"
  },
  {
    "uniprot": "Q4DYI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYI2"
  },
  {
    "uniprot": "Q4DVI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVI4"
  },
  {
    "uniprot": "Q4D7R8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7R8"
  },
  {
    "uniprot": "Q4DHA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHA1"
  },
  {
    "uniprot": "Q4DN10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN10"
  },
  {
    "uniprot": "Q4DRS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRS0"
  },
  {
    "uniprot": "Q4DWD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWD5"
  },
  {
    "uniprot": "Q4CQ43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ43"
  },
  {
    "uniprot": "Q4DM59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM59"
  },
  {
    "uniprot": "Q4D7H8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7H8"
  },
  {
    "uniprot": "Q4D094",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D094"
  },
  {
    "uniprot": "Q4E2G8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2G8"
  },
  {
    "uniprot": "Q4CVX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVX7"
  },
  {
    "uniprot": "Q4DRA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRA4"
  },
  {
    "uniprot": "Q4DIE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIE1"
  },
  {
    "uniprot": "Q4CRI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRI9"
  },
  {
    "uniprot": "Q4CVT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVT7"
  },
  {
    "uniprot": "Q4E3B2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3B2"
  },
  {
    "uniprot": "Q4CYH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYH0"
  },
  {
    "uniprot": "Q4DB43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB43"
  },
  {
    "uniprot": "Q4CPV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPV9"
  },
  {
    "uniprot": "Q4E348",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E348"
  },
  {
    "uniprot": "Q4DIC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIC0"
  },
  {
    "uniprot": "Q4DC42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC42"
  },
  {
    "uniprot": "Q4E2G4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2G4"
  },
  {
    "uniprot": "Q4DU48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU48"
  },
  {
    "uniprot": "Q4D1S0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1S0"
  },
  {
    "uniprot": "Q4DYR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYR7"
  },
  {
    "uniprot": "Q4DWW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWW1"
  },
  {
    "uniprot": "Q4DKK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKK3"
  },
  {
    "uniprot": "Q4DHU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHU6"
  },
  {
    "uniprot": "Q4E4R9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4R9"
  },
  {
    "uniprot": "Q4DPT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPT3"
  },
  {
    "uniprot": "Q4DKA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKA9"
  },
  {
    "uniprot": "Q4DKA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKA7"
  },
  {
    "uniprot": "Q4D4E6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4E6"
  },
  {
    "uniprot": "Q4D8G5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8G5"
  },
  {
    "uniprot": "Q4D2R9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2R9"
  },
  {
    "uniprot": "Q4D7M9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7M9"
  },
  {
    "uniprot": "Q4E2U0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2U0"
  },
  {
    "uniprot": "Q4DT32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT32"
  },
  {
    "uniprot": "Q4CTI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTI2"
  },
  {
    "uniprot": "Q4DCE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCE9"
  },
  {
    "uniprot": "Q4DF28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF28"
  },
  {
    "uniprot": "Q4CYS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYS6"
  },
  {
    "uniprot": "Q4DD07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD07"
  },
  {
    "uniprot": "Q4DQX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQX9"
  },
  {
    "uniprot": "Q4DKF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKF2"
  },
  {
    "uniprot": "Q4D374",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D374"
  },
  {
    "uniprot": "Q4D580",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D580"
  },
  {
    "uniprot": "Q4E4S1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4S1"
  },
  {
    "uniprot": "Q4CX41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX41"
  },
  {
    "uniprot": "Q4DN74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN74"
  },
  {
    "uniprot": "Q4DC61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC61"
  },
  {
    "uniprot": "Q4E015",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E015"
  },
  {
    "uniprot": "Q4E382",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E382"
  },
  {
    "uniprot": "Q4CQ59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ59"
  },
  {
    "uniprot": "Q4D0S1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0S1"
  },
  {
    "uniprot": "Q4DHB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHB5"
  },
  {
    "uniprot": "Q4DPP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPP3"
  },
  {
    "uniprot": "Q4E496",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E496"
  },
  {
    "uniprot": "Q4DPK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPK1"
  },
  {
    "uniprot": "Q4DFB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFB5"
  },
  {
    "uniprot": "Q4DT99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT99"
  },
  {
    "uniprot": "Q4E5Y5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Y5"
  },
  {
    "uniprot": "Q4CU57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU57"
  },
  {
    "uniprot": "Q4D6G7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6G7"
  },
  {
    "uniprot": "Q4D5Z5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Z5"
  },
  {
    "uniprot": "Q4DS09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS09"
  },
  {
    "uniprot": "Q4E1Q1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Q1"
  },
  {
    "uniprot": "Q4CYI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYI7"
  },
  {
    "uniprot": "Q4CRT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRT8"
  },
  {
    "uniprot": "Q4E3Y6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Y6"
  },
  {
    "uniprot": "Q4DMM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMM1"
  },
  {
    "uniprot": "Q4DGP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGP1"
  },
  {
    "uniprot": "Q4DTF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTF4"
  },
  {
    "uniprot": "Q4DI37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI37"
  },
  {
    "uniprot": "Q4E3C6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3C6"
  },
  {
    "uniprot": "Q4DA95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA95"
  },
  {
    "uniprot": "Q4CVI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVI2"
  },
  {
    "uniprot": "Q4CV22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV22"
  },
  {
    "uniprot": "Q4E1T5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1T5"
  },
  {
    "uniprot": "Q4CVJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVJ6"
  },
  {
    "uniprot": "Q4CPN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPN8"
  },
  {
    "uniprot": "Q4DVZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVZ8"
  },
  {
    "uniprot": "Q4DER0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DER0"
  },
  {
    "uniprot": "Q4CXV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXV9"
  },
  {
    "uniprot": "Q4D2P8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2P8"
  },
  {
    "uniprot": "Q4DT73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT73"
  },
  {
    "uniprot": "Q4D773",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D773"
  },
  {
    "uniprot": "Q4D123",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D123"
  },
  {
    "uniprot": "Q4DJM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJM6"
  },
  {
    "uniprot": "Q4CZF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZF7"
  },
  {
    "uniprot": "Q4CWN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWN9"
  },
  {
    "uniprot": "Q4DNS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNS6"
  },
  {
    "uniprot": "Q4DBE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBE3"
  },
  {
    "uniprot": "Q4DL67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL67"
  },
  {
    "uniprot": "Q4DCV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCV3"
  },
  {
    "uniprot": "Q4DMX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMX1"
  },
  {
    "uniprot": "Q4DNJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNJ0"
  },
  {
    "uniprot": "Q4DTV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTV4"
  },
  {
    "uniprot": "Q4CUA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUA8"
  },
  {
    "uniprot": "Q4CUC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUC1"
  },
  {
    "uniprot": "Q4DBY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBY5"
  },
  {
    "uniprot": "Q4DGF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGF8"
  },
  {
    "uniprot": "Q4DHK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHK9"
  },
  {
    "uniprot": "Q4DPI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPI5"
  },
  {
    "uniprot": "Q4CUW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUW6"
  },
  {
    "uniprot": "Q4CMM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMM1"
  },
  {
    "uniprot": "Q4DEK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEK3"
  },
  {
    "uniprot": "Q4CRD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRD3"
  },
  {
    "uniprot": "Q4D6R9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6R9"
  },
  {
    "uniprot": "Q4E1D6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1D6"
  },
  {
    "uniprot": "Q4DB68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB68"
  },
  {
    "uniprot": "Q4DI78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI78"
  },
  {
    "uniprot": "Q4E5P2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5P2"
  },
  {
    "uniprot": "Q4D1B4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1B4"
  },
  {
    "uniprot": "Q4E653",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E653"
  },
  {
    "uniprot": "Q4CQN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQN4"
  },
  {
    "uniprot": "Q4DKQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKQ4"
  },
  {
    "uniprot": "Q4CWS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWS3"
  },
  {
    "uniprot": "Q4CPL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPL4"
  },
  {
    "uniprot": "Q4D777",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D777"
  },
  {
    "uniprot": "Q4DPK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPK6"
  },
  {
    "uniprot": "Q4CYY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYY8"
  },
  {
    "uniprot": "Q4D4E9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4E9"
  },
  {
    "uniprot": "Q4CSE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSE4"
  },
  {
    "uniprot": "Q4D750",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D750"
  },
  {
    "uniprot": "Q4CTZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTZ1"
  },
  {
    "uniprot": "Q4CW74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW74"
  },
  {
    "uniprot": "Q4E2K3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2K3"
  },
  {
    "uniprot": "Q4DML2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DML2"
  },
  {
    "uniprot": "Q4E1D9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1D9"
  },
  {
    "uniprot": "Q4DS30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS30"
  },
  {
    "uniprot": "Q4DS40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS40"
  },
  {
    "uniprot": "Q4DR81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR81"
  },
  {
    "uniprot": "Q4CL35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL35"
  },
  {
    "uniprot": "Q4CST1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CST1"
  },
  {
    "uniprot": "Q4E5I6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5I6"
  },
  {
    "uniprot": "Q4D936",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D936"
  },
  {
    "uniprot": "Q4CP02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP02"
  },
  {
    "uniprot": "Q4DR10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR10"
  },
  {
    "uniprot": "Q4E4H3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4H3"
  },
  {
    "uniprot": "Q4DZ16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ16"
  },
  {
    "uniprot": "Q4DW49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW49"
  },
  {
    "uniprot": "Q4D205",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D205"
  },
  {
    "uniprot": "Q4CS44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS44"
  },
  {
    "uniprot": "Q4DCR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCR7"
  },
  {
    "uniprot": "Q4E529",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E529"
  },
  {
    "uniprot": "Q4E5K2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5K2"
  },
  {
    "uniprot": "Q4DVB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVB7"
  },
  {
    "uniprot": "Q4DMR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMR4"
  },
  {
    "uniprot": "Q4CVE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVE1"
  },
  {
    "uniprot": "Q4DL75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL75"
  },
  {
    "uniprot": "Q4CZX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZX8"
  },
  {
    "uniprot": "Q4DJD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJD2"
  },
  {
    "uniprot": "Q4CQ35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ35"
  },
  {
    "uniprot": "Q4CYD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYD2"
  },
  {
    "uniprot": "Q4D9F4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9F4"
  },
  {
    "uniprot": "Q4CLU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLU0"
  },
  {
    "uniprot": "Q4D710",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D710"
  },
  {
    "uniprot": "Q4D0U7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0U7"
  },
  {
    "uniprot": "Q4DRW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRW5"
  },
  {
    "uniprot": "Q4DGK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGK2"
  },
  {
    "uniprot": "Q4DE55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE55"
  },
  {
    "uniprot": "Q4CNQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNQ7"
  },
  {
    "uniprot": "Q4CP01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP01"
  },
  {
    "uniprot": "Q4DC60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC60"
  },
  {
    "uniprot": "Q4CTI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTI1"
  },
  {
    "uniprot": "Q4CLY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLY6"
  },
  {
    "uniprot": "Q4DMM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMM2"
  },
  {
    "uniprot": "Q4CQL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQL3"
  },
  {
    "uniprot": "Q4DA10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA10"
  },
  {
    "uniprot": "Q4DS02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS02"
  },
  {
    "uniprot": "Q4DVN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVN6"
  },
  {
    "uniprot": "Q4E1W3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1W3"
  },
  {
    "uniprot": "Q4D7V2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7V2"
  },
  {
    "uniprot": "Q4CWU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWU9"
  },
  {
    "uniprot": "Q4DXP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXP4"
  },
  {
    "uniprot": "Q4D1N9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1N9"
  },
  {
    "uniprot": "Q4E2N8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2N8"
  },
  {
    "uniprot": "Q4CT82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT82"
  },
  {
    "uniprot": "Q4DYF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYF6"
  },
  {
    "uniprot": "Q4DJ52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ52"
  },
  {
    "uniprot": "Q4DHF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHF5"
  },
  {
    "uniprot": "Q4DE80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE80"
  },
  {
    "uniprot": "Q4DIY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIY3"
  },
  {
    "uniprot": "Q4DCS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCS3"
  },
  {
    "uniprot": "Q4E043",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E043"
  },
  {
    "uniprot": "Q4CKL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKL4"
  },
  {
    "uniprot": "Q4D529",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D529"
  },
  {
    "uniprot": "Q4CXN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXN4"
  },
  {
    "uniprot": "Q4D8K3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8K3"
  },
  {
    "uniprot": "Q4DPY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPY4"
  },
  {
    "uniprot": "Q4DZW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZW2"
  },
  {
    "uniprot": "Q4CLC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLC1"
  },
  {
    "uniprot": "Q4CT41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT41"
  },
  {
    "uniprot": "Q4DIE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIE5"
  },
  {
    "uniprot": "Q4DUT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUT8"
  },
  {
    "uniprot": "Q4CMZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMZ1"
  },
  {
    "uniprot": "Q4D271",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D271"
  },
  {
    "uniprot": "Q4DV60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV60"
  },
  {
    "uniprot": "Q4D8D6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8D6"
  },
  {
    "uniprot": "Q4DYU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYU3"
  },
  {
    "uniprot": "Q4D240",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D240"
  },
  {
    "uniprot": "Q4D435",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D435"
  },
  {
    "uniprot": "Q4E442",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E442"
  },
  {
    "uniprot": "Q4DP09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP09"
  },
  {
    "uniprot": "Q4DR36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR36"
  },
  {
    "uniprot": "Q4CMN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMN9"
  },
  {
    "uniprot": "Q4CWZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWZ3"
  },
  {
    "uniprot": "Q4E601",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E601"
  },
  {
    "uniprot": "Q4E661",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E661"
  },
  {
    "uniprot": "Q4CMV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMV7"
  },
  {
    "uniprot": "Q4DFA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFA4"
  },
  {
    "uniprot": "Q4DZZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZZ1"
  },
  {
    "uniprot": "Q4CW88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW88"
  },
  {
    "uniprot": "Q4D393",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D393"
  },
  {
    "uniprot": "Q4CKP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKP8"
  },
  {
    "uniprot": "Q4DP20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP20"
  },
  {
    "uniprot": "Q4DM51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM51"
  },
  {
    "uniprot": "Q4DJF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJF8"
  },
  {
    "uniprot": "Q4D3U3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3U3"
  },
  {
    "uniprot": "Q4DQG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQG7"
  },
  {
    "uniprot": "Q4CT06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT06"
  },
  {
    "uniprot": "Q4D1S9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1S9"
  },
  {
    "uniprot": "Q4DGY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGY5"
  },
  {
    "uniprot": "Q4CRM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRM4"
  },
  {
    "uniprot": "Q4CL42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL42"
  },
  {
    "uniprot": "Q4E5C0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5C0"
  },
  {
    "uniprot": "Q4D120",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D120"
  },
  {
    "uniprot": "Q4D3M6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3M6"
  },
  {
    "uniprot": "Q4D8P6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8P6"
  },
  {
    "uniprot": "Q4DM76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM76"
  },
  {
    "uniprot": "Q4E0I7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0I7"
  },
  {
    "uniprot": "Q4DEZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEZ1"
  },
  {
    "uniprot": "Q4DVH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVH2"
  },
  {
    "uniprot": "Q4DJX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJX5"
  },
  {
    "uniprot": "Q4DUU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUU3"
  },
  {
    "uniprot": "Q4CZW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZW5"
  },
  {
    "uniprot": "Q4D342",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D342"
  },
  {
    "uniprot": "Q4DE52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE52"
  },
  {
    "uniprot": "Q4CS63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS63"
  },
  {
    "uniprot": "Q4DD36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD36"
  },
  {
    "uniprot": "Q4D232",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D232"
  },
  {
    "uniprot": "Q4DUY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUY0"
  },
  {
    "uniprot": "Q4D0H3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0H3"
  },
  {
    "uniprot": "Q4DGQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGQ9"
  },
  {
    "uniprot": "Q4CYH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYH7"
  },
  {
    "uniprot": "Q4CPM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPM3"
  },
  {
    "uniprot": "Q4CK93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CK93"
  },
  {
    "uniprot": "Q4CPT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPT9"
  },
  {
    "uniprot": "Q4DX94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX94"
  },
  {
    "uniprot": "Q4DDM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDM9"
  },
  {
    "uniprot": "Q4DBJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBJ7"
  },
  {
    "uniprot": "Q4D0X2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0X2"
  },
  {
    "uniprot": "Q4D5G6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5G6"
  },
  {
    "uniprot": "Q4D0I4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0I4"
  },
  {
    "uniprot": "Q4DPP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPP4"
  },
  {
    "uniprot": "Q4D5B5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5B5"
  },
  {
    "uniprot": "Q4DWM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWM2"
  },
  {
    "uniprot": "Q4DGL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGL9"
  },
  {
    "uniprot": "Q4D7Q9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Q9"
  },
  {
    "uniprot": "Q4D4J2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4J2"
  },
  {
    "uniprot": "Q4CTL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTL2"
  },
  {
    "uniprot": "Q4CPQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPQ2"
  },
  {
    "uniprot": "Q4D3B1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3B1"
  },
  {
    "uniprot": "Q4DNZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNZ1"
  },
  {
    "uniprot": "Q4DDF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDF6"
  },
  {
    "uniprot": "Q4CX19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX19"
  },
  {
    "uniprot": "Q4CTL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTL5"
  },
  {
    "uniprot": "Q4DD43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD43"
  },
  {
    "uniprot": "Q4CKQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKQ0"
  },
  {
    "uniprot": "Q4E3E2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3E2"
  },
  {
    "uniprot": "Q4D700",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D700"
  },
  {
    "uniprot": "Q4DYU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYU1"
  },
  {
    "uniprot": "Q4D778",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D778"
  },
  {
    "uniprot": "Q4E1R2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1R2"
  },
  {
    "uniprot": "Q4DGI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGI8"
  },
  {
    "uniprot": "Q4D7P3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7P3"
  },
  {
    "uniprot": "Q4E4Z5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Z5"
  },
  {
    "uniprot": "Q4D338",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D338"
  },
  {
    "uniprot": "Q4DLD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLD1"
  },
  {
    "uniprot": "Q4D4W5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4W5"
  },
  {
    "uniprot": "Q4DG17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG17"
  },
  {
    "uniprot": "Q4CTG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTG9"
  },
  {
    "uniprot": "Q4CNW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNW4"
  },
  {
    "uniprot": "Q4CW47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW47"
  },
  {
    "uniprot": "Q4D662",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D662"
  },
  {
    "uniprot": "Q4E415",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E415"
  },
  {
    "uniprot": "Q4CSK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSK3"
  },
  {
    "uniprot": "Q4DF45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF45"
  },
  {
    "uniprot": "Q4DI12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI12"
  },
  {
    "uniprot": "Q4CL05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL05"
  },
  {
    "uniprot": "Q4CMC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMC4"
  },
  {
    "uniprot": "Q4DQW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQW8"
  },
  {
    "uniprot": "Q4CPB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPB4"
  },
  {
    "uniprot": "Q4DIF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIF1"
  },
  {
    "uniprot": "Q4CU29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU29"
  },
  {
    "uniprot": "Q4CP41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP41"
  },
  {
    "uniprot": "Q4DY92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY92"
  },
  {
    "uniprot": "Q4DBL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBL5"
  },
  {
    "uniprot": "Q4D0R4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0R4"
  },
  {
    "uniprot": "Q4D5N1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5N1"
  },
  {
    "uniprot": "Q4DVD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVD4"
  },
  {
    "uniprot": "Q4DPZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPZ7"
  },
  {
    "uniprot": "Q4DTF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTF6"
  },
  {
    "uniprot": "Q4CRT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRT3"
  },
  {
    "uniprot": "Q4DKU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKU2"
  },
  {
    "uniprot": "Q4CMU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMU7"
  },
  {
    "uniprot": "Q4D156",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D156"
  },
  {
    "uniprot": "Q4DGB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGB8"
  },
  {
    "uniprot": "Q4CMU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMU4"
  },
  {
    "uniprot": "Q4DUF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUF4"
  },
  {
    "uniprot": "Q4CUC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUC2"
  },
  {
    "uniprot": "Q4DMF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMF4"
  },
  {
    "uniprot": "Q4D569",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D569"
  },
  {
    "uniprot": "Q4DUE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUE8"
  },
  {
    "uniprot": "Q4DI86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI86"
  },
  {
    "uniprot": "Q4CZA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZA1"
  },
  {
    "uniprot": "Q4DY44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY44"
  },
  {
    "uniprot": "Q4D986",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D986"
  },
  {
    "uniprot": "Q4DGR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGR1"
  },
  {
    "uniprot": "Q4CSA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSA0"
  },
  {
    "uniprot": "Q4D447",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D447"
  },
  {
    "uniprot": "Q4CY53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY53"
  },
  {
    "uniprot": "Q4E5L5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5L5"
  },
  {
    "uniprot": "Q4D980",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D980"
  },
  {
    "uniprot": "Q4CWH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWH7"
  },
  {
    "uniprot": "Q4DXZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXZ0"
  },
  {
    "uniprot": "Q4DHQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHQ0"
  },
  {
    "uniprot": "Q4E407",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E407"
  },
  {
    "uniprot": "Q4DIE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIE8"
  },
  {
    "uniprot": "Q4D5V5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5V5"
  },
  {
    "uniprot": "Q4CTW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTW3"
  },
  {
    "uniprot": "Q4D7B4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7B4"
  },
  {
    "uniprot": "Q4DXN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXN1"
  },
  {
    "uniprot": "Q4DUD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUD3"
  },
  {
    "uniprot": "Q4D455",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D455"
  },
  {
    "uniprot": "Q4D7Z5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Z5"
  },
  {
    "uniprot": "Q4E0E3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0E3"
  },
  {
    "uniprot": "Q4D6P2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6P2"
  },
  {
    "uniprot": "Q4D3U2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3U2"
  },
  {
    "uniprot": "Q4D4Z3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Z3"
  },
  {
    "uniprot": "Q4DQG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQG1"
  },
  {
    "uniprot": "Q4DTY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTY9"
  },
  {
    "uniprot": "Q4E2B8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2B8"
  },
  {
    "uniprot": "Q4CN41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN41"
  },
  {
    "uniprot": "Q4DSX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSX4"
  },
  {
    "uniprot": "Q4E298",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E298"
  },
  {
    "uniprot": "Q4CR40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR40"
  },
  {
    "uniprot": "Q4DDK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDK5"
  },
  {
    "uniprot": "Q4DL01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL01"
  },
  {
    "uniprot": "Q4DGM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGM0"
  },
  {
    "uniprot": "Q4D831",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D831"
  },
  {
    "uniprot": "Q4CQC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQC3"
  },
  {
    "uniprot": "Q4CYW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYW4"
  },
  {
    "uniprot": "Q4DEB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEB5"
  },
  {
    "uniprot": "Q4E567",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E567"
  },
  {
    "uniprot": "Q4DB22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB22"
  },
  {
    "uniprot": "Q4DNS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNS9"
  },
  {
    "uniprot": "Q4CK94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CK94"
  },
  {
    "uniprot": "Q4CM15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM15"
  },
  {
    "uniprot": "Q4CRX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRX2"
  },
  {
    "uniprot": "Q4DPV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPV3"
  },
  {
    "uniprot": "Q4DD95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD95"
  },
  {
    "uniprot": "Q4DT45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT45"
  },
  {
    "uniprot": "Q4D493",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D493"
  },
  {
    "uniprot": "Q4D618",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D618"
  },
  {
    "uniprot": "Q4D943",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D943"
  },
  {
    "uniprot": "Q4CMC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMC1"
  },
  {
    "uniprot": "Q4D3C3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3C3"
  },
  {
    "uniprot": "Q4CNF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNF2"
  },
  {
    "uniprot": "Q4CML1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CML1"
  },
  {
    "uniprot": "Q4D1A6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1A6"
  },
  {
    "uniprot": "Q4DCN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCN8"
  },
  {
    "uniprot": "Q4DI29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI29"
  },
  {
    "uniprot": "Q4E224",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E224"
  },
  {
    "uniprot": "Q4CUN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUN0"
  },
  {
    "uniprot": "Q4DI73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI73"
  },
  {
    "uniprot": "Q4D212",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D212"
  },
  {
    "uniprot": "Q4DUI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUI5"
  },
  {
    "uniprot": "Q4DZI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZI6"
  },
  {
    "uniprot": "Q4DLQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLQ4"
  },
  {
    "uniprot": "Q4CZ92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ92"
  },
  {
    "uniprot": "Q4DTX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTX7"
  },
  {
    "uniprot": "Q4DGX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGX3"
  },
  {
    "uniprot": "Q4DHT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHT2"
  },
  {
    "uniprot": "Q4D6Z6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Z6"
  },
  {
    "uniprot": "Q4D7R1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7R1"
  },
  {
    "uniprot": "Q4DB74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB74"
  },
  {
    "uniprot": "Q4D4E4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4E4"
  },
  {
    "uniprot": "Q4CKP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKP1"
  },
  {
    "uniprot": "Q4D6G1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6G1"
  },
  {
    "uniprot": "Q4D1Z8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Z8"
  },
  {
    "uniprot": "Q4CXF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXF7"
  },
  {
    "uniprot": "Q4DW78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW78"
  },
  {
    "uniprot": "Q4DJN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJN4"
  },
  {
    "uniprot": "Q4DSJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSJ3"
  },
  {
    "uniprot": "Q4CWE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWE6"
  },
  {
    "uniprot": "Q4DCV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCV7"
  },
  {
    "uniprot": "Q4CX32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX32"
  },
  {
    "uniprot": "Q4CZC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZC2"
  },
  {
    "uniprot": "Q4CVL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVL2"
  },
  {
    "uniprot": "Q4CLT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLT1"
  },
  {
    "uniprot": "Q4D7I8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7I8"
  },
  {
    "uniprot": "Q4D445",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D445"
  },
  {
    "uniprot": "Q4DSA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSA7"
  },
  {
    "uniprot": "Q4D9W3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9W3"
  },
  {
    "uniprot": "Q4DU42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU42"
  },
  {
    "uniprot": "Q4E498",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E498"
  },
  {
    "uniprot": "Q4CM62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM62"
  },
  {
    "uniprot": "Q4D2W6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2W6"
  },
  {
    "uniprot": "Q4CXK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXK0"
  },
  {
    "uniprot": "Q4D0H0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0H0"
  },
  {
    "uniprot": "Q4DEZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEZ8"
  },
  {
    "uniprot": "Q4E304",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E304"
  },
  {
    "uniprot": "Q4DYH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYH5"
  },
  {
    "uniprot": "Q4CS38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS38"
  },
  {
    "uniprot": "Q4CZ47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ47"
  },
  {
    "uniprot": "Q4DZG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZG8"
  },
  {
    "uniprot": "Q4DBK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBK9"
  },
  {
    "uniprot": "Q4DIU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIU3"
  },
  {
    "uniprot": "Q4DWE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWE4"
  },
  {
    "uniprot": "Q4E4I7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4I7"
  },
  {
    "uniprot": "Q4D3M1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3M1"
  },
  {
    "uniprot": "Q4D8Q8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Q8"
  },
  {
    "uniprot": "Q4DF91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF91"
  },
  {
    "uniprot": "Q4CN50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN50"
  },
  {
    "uniprot": "Q4CNH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNH5"
  },
  {
    "uniprot": "Q4D431",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D431"
  },
  {
    "uniprot": "Q4CYC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYC6"
  },
  {
    "uniprot": "Q4D6L9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6L9"
  },
  {
    "uniprot": "Q4DWF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWF0"
  },
  {
    "uniprot": "Q4DDU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDU2"
  },
  {
    "uniprot": "Q4CXH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXH1"
  },
  {
    "uniprot": "Q4CX67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX67"
  },
  {
    "uniprot": "Q4DMG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMG5"
  },
  {
    "uniprot": "Q4DMJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMJ0"
  },
  {
    "uniprot": "Q4E5A3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5A3"
  },
  {
    "uniprot": "Q4E4C1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4C1"
  },
  {
    "uniprot": "Q4D7Q2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Q2"
  },
  {
    "uniprot": "Q4DPK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPK3"
  },
  {
    "uniprot": "Q4CW89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW89"
  },
  {
    "uniprot": "Q4DZC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZC3"
  },
  {
    "uniprot": "Q4E3I0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3I0"
  },
  {
    "uniprot": "Q4DSW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSW2"
  },
  {
    "uniprot": "Q4D0G3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0G3"
  },
  {
    "uniprot": "Q4CVH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVH6"
  },
  {
    "uniprot": "Q4DET3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DET3"
  },
  {
    "uniprot": "Q4DK47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK47"
  },
  {
    "uniprot": "Q4CU67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU67"
  },
  {
    "uniprot": "Q4E677",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E677"
  },
  {
    "uniprot": "Q4CRR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRR0"
  },
  {
    "uniprot": "Q4CLF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLF0"
  },
  {
    "uniprot": "Q4D588",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D588"
  },
  {
    "uniprot": "Q4D9L1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9L1"
  },
  {
    "uniprot": "Q4DK99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK99"
  },
  {
    "uniprot": "Q4DP54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP54"
  },
  {
    "uniprot": "Q4DUU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUU9"
  },
  {
    "uniprot": "Q4DTT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTT2"
  },
  {
    "uniprot": "Q4DRC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRC4"
  },
  {
    "uniprot": "Q4D4Z0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Z0"
  },
  {
    "uniprot": "Q4D902",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D902"
  },
  {
    "uniprot": "Q4DHW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHW4"
  },
  {
    "uniprot": "Q4DJ23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ23"
  },
  {
    "uniprot": "Q4CZ83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ83"
  },
  {
    "uniprot": "Q4DY82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY82"
  },
  {
    "uniprot": "Q4CUI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUI9"
  },
  {
    "uniprot": "Q4D3N8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3N8"
  },
  {
    "uniprot": "Q4DP45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP45"
  },
  {
    "uniprot": "Q4CT00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT00"
  },
  {
    "uniprot": "Q4CQ04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ04"
  },
  {
    "uniprot": "Q4DB33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB33"
  },
  {
    "uniprot": "Q4DEN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEN0"
  },
  {
    "uniprot": "Q4E1B2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1B2"
  },
  {
    "uniprot": "Q4CN58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN58"
  },
  {
    "uniprot": "Q4DRV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRV5"
  },
  {
    "uniprot": "Q4DJJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJJ3"
  },
  {
    "uniprot": "Q4DWG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWG3"
  },
  {
    "uniprot": "Q4CNX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNX7"
  },
  {
    "uniprot": "Q4D9I5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9I5"
  },
  {
    "uniprot": "Q4CSL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSL0"
  },
  {
    "uniprot": "Q4DGY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGY6"
  },
  {
    "uniprot": "Q4E154",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E154"
  },
  {
    "uniprot": "Q4DV62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV62"
  },
  {
    "uniprot": "Q4CUP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUP7"
  },
  {
    "uniprot": "Q4DH86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH86"
  },
  {
    "uniprot": "Q4D0N7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0N7"
  },
  {
    "uniprot": "Q4DW76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW76"
  },
  {
    "uniprot": "Q4CUL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUL8"
  },
  {
    "uniprot": "Q4DGD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGD8"
  },
  {
    "uniprot": "Q4D9P7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9P7"
  },
  {
    "uniprot": "Q4DTC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTC6"
  },
  {
    "uniprot": "Q4DR33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR33"
  },
  {
    "uniprot": "Q4DDT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDT4"
  },
  {
    "uniprot": "Q4E2W4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2W4"
  },
  {
    "uniprot": "Q4CUY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUY1"
  },
  {
    "uniprot": "Q4E250",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E250"
  },
  {
    "uniprot": "Q4CRF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRF2"
  },
  {
    "uniprot": "Q4CQG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQG6"
  },
  {
    "uniprot": "Q4DNM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNM0"
  },
  {
    "uniprot": "Q4DSD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSD7"
  },
  {
    "uniprot": "Q4DQR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQR9"
  },
  {
    "uniprot": "Q4E440",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E440"
  },
  {
    "uniprot": "Q4DKR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKR6"
  },
  {
    "uniprot": "Q4DDZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDZ4"
  },
  {
    "uniprot": "Q4E3C1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3C1"
  },
  {
    "uniprot": "Q4D9Y6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Y6"
  },
  {
    "uniprot": "Q4E1B4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1B4"
  },
  {
    "uniprot": "Q4E1H7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1H7"
  },
  {
    "uniprot": "Q4DGT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGT4"
  },
  {
    "uniprot": "Q4DP18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP18"
  },
  {
    "uniprot": "Q4E539",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E539"
  },
  {
    "uniprot": "Q4D626",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D626"
  },
  {
    "uniprot": "Q4DS92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS92"
  },
  {
    "uniprot": "Q4CZG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZG1"
  },
  {
    "uniprot": "Q4DEN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEN8"
  },
  {
    "uniprot": "Q4CLB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLB6"
  },
  {
    "uniprot": "Q4DSI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSI4"
  },
  {
    "uniprot": "Q4D8Y6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Y6"
  },
  {
    "uniprot": "Q4DKL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKL1"
  },
  {
    "uniprot": "Q4D533",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D533"
  },
  {
    "uniprot": "Q4CS95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS95"
  },
  {
    "uniprot": "Q4DAH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAH6"
  },
  {
    "uniprot": "Q4D0W1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0W1"
  },
  {
    "uniprot": "Q4DV72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV72"
  },
  {
    "uniprot": "Q4DFB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFB8"
  },
  {
    "uniprot": "Q4D3G0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3G0"
  },
  {
    "uniprot": "Q4D828",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D828"
  },
  {
    "uniprot": "Q4E675",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E675"
  },
  {
    "uniprot": "Q4D1F6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1F6"
  },
  {
    "uniprot": "Q4DBX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBX6"
  },
  {
    "uniprot": "Q4CRL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRL4"
  },
  {
    "uniprot": "Q4CXS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXS5"
  },
  {
    "uniprot": "Q4CN42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN42"
  },
  {
    "uniprot": "Q4D095",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D095"
  },
  {
    "uniprot": "Q4D2D2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2D2"
  },
  {
    "uniprot": "Q4DAP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAP0"
  },
  {
    "uniprot": "Q4E299",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E299"
  },
  {
    "uniprot": "Q4DQR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQR0"
  },
  {
    "uniprot": "Q4DN99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN99"
  },
  {
    "uniprot": "Q4DXD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXD9"
  },
  {
    "uniprot": "Q4DE25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE25"
  },
  {
    "uniprot": "Q4DV70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV70"
  },
  {
    "uniprot": "Q4CYE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYE5"
  },
  {
    "uniprot": "Q4DHA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHA7"
  },
  {
    "uniprot": "Q4CSQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSQ0"
  },
  {
    "uniprot": "Q4E3T2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3T2"
  },
  {
    "uniprot": "Q4DDG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDG8"
  },
  {
    "uniprot": "Q4D9Q4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Q4"
  },
  {
    "uniprot": "Q4D2H3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2H3"
  },
  {
    "uniprot": "Q4E4H6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4H6"
  },
  {
    "uniprot": "Q4D8R8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8R8"
  },
  {
    "uniprot": "Q4CX75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX75"
  },
  {
    "uniprot": "Q4DQV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQV9"
  },
  {
    "uniprot": "Q4D983",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D983"
  },
  {
    "uniprot": "Q4E0T8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0T8"
  },
  {
    "uniprot": "Q4DAG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAG7"
  },
  {
    "uniprot": "Q4DGK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGK6"
  },
  {
    "uniprot": "Q4CV52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV52"
  },
  {
    "uniprot": "Q4DTF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTF5"
  },
  {
    "uniprot": "Q4CNI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNI9"
  },
  {
    "uniprot": "Q4CZL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZL7"
  },
  {
    "uniprot": "Q4CWG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWG9"
  },
  {
    "uniprot": "Q4DVU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVU3"
  },
  {
    "uniprot": "Q4DHQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHQ1"
  },
  {
    "uniprot": "Q4DZS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZS2"
  },
  {
    "uniprot": "Q4DU57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU57"
  },
  {
    "uniprot": "Q4E0J9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0J9"
  },
  {
    "uniprot": "Q4E469",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E469"
  },
  {
    "uniprot": "Q4E3F9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3F9"
  },
  {
    "uniprot": "Q4DLM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLM6"
  },
  {
    "uniprot": "Q4DVV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVV8"
  },
  {
    "uniprot": "Q4CYI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYI3"
  },
  {
    "uniprot": "Q4DBM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBM8"
  },
  {
    "uniprot": "Q4DZZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZZ4"
  },
  {
    "uniprot": "Q4DWQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWQ3"
  },
  {
    "uniprot": "Q4CPU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPU7"
  },
  {
    "uniprot": "Q4DLW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLW7"
  },
  {
    "uniprot": "Q4D9F0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9F0"
  },
  {
    "uniprot": "Q4E2M1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2M1"
  },
  {
    "uniprot": "Q4DL71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL71"
  },
  {
    "uniprot": "Q4CN47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN47"
  },
  {
    "uniprot": "Q4DZT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZT9"
  },
  {
    "uniprot": "Q4DLI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLI0"
  },
  {
    "uniprot": "Q4DUI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUI6"
  },
  {
    "uniprot": "Q4DRL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRL9"
  },
  {
    "uniprot": "Q4CPW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPW9"
  },
  {
    "uniprot": "Q4E113",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E113"
  },
  {
    "uniprot": "Q4DWV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWV7"
  },
  {
    "uniprot": "Q4DF80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF80"
  },
  {
    "uniprot": "Q4CUH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUH5"
  },
  {
    "uniprot": "Q4DMG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMG1"
  },
  {
    "uniprot": "Q4DGU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGU7"
  },
  {
    "uniprot": "Q4DNM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNM3"
  },
  {
    "uniprot": "Q4D2Z1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Z1"
  },
  {
    "uniprot": "Q4DJN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJN6"
  },
  {
    "uniprot": "Q4DC76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC76"
  },
  {
    "uniprot": "Q4CRF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRF0"
  },
  {
    "uniprot": "Q4D634",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D634"
  },
  {
    "uniprot": "Q4DEV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEV1"
  },
  {
    "uniprot": "Q4DHZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHZ6"
  },
  {
    "uniprot": "Q4DLL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLL3"
  },
  {
    "uniprot": "Q4DEU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEU2"
  },
  {
    "uniprot": "Q4DP75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP75"
  },
  {
    "uniprot": "Q4D636",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D636"
  },
  {
    "uniprot": "Q4CYV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYV4"
  },
  {
    "uniprot": "Q4DQ52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ52"
  },
  {
    "uniprot": "Q4DD81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD81"
  },
  {
    "uniprot": "Q4DI27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI27"
  },
  {
    "uniprot": "Q4E3Q0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Q0"
  },
  {
    "uniprot": "Q4DXF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXF6"
  },
  {
    "uniprot": "Q4E5A1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5A1"
  },
  {
    "uniprot": "Q4DZ65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ65"
  },
  {
    "uniprot": "Q4DPC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPC6"
  },
  {
    "uniprot": "Q4D1T8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1T8"
  },
  {
    "uniprot": "Q4E097",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E097"
  },
  {
    "uniprot": "Q4DBG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBG4"
  },
  {
    "uniprot": "Q4CVW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVW1"
  },
  {
    "uniprot": "Q4DNV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNV9"
  },
  {
    "uniprot": "Q4CZ59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ59"
  },
  {
    "uniprot": "Q4E3C2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3C2"
  },
  {
    "uniprot": "Q4D8C1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8C1"
  },
  {
    "uniprot": "Q4CQY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQY4"
  },
  {
    "uniprot": "Q4CKJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKJ4"
  },
  {
    "uniprot": "Q4DRJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRJ3"
  },
  {
    "uniprot": "Q4DPR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPR8"
  },
  {
    "uniprot": "Q4DSB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSB7"
  },
  {
    "uniprot": "Q4D629",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D629"
  },
  {
    "uniprot": "Q4D2X6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2X6"
  },
  {
    "uniprot": "Q4E273",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E273"
  },
  {
    "uniprot": "Q4DY48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY48"
  },
  {
    "uniprot": "Q4D7N2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7N2"
  },
  {
    "uniprot": "Q4DPL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPL5"
  },
  {
    "uniprot": "Q4DBI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBI8"
  },
  {
    "uniprot": "Q4E5G1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5G1"
  },
  {
    "uniprot": "Q4DKA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKA6"
  },
  {
    "uniprot": "Q4CQM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQM0"
  },
  {
    "uniprot": "Q4DGS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGS4"
  },
  {
    "uniprot": "Q4DND3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DND3"
  },
  {
    "uniprot": "Q4CME2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CME2"
  },
  {
    "uniprot": "Q4DQK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQK9"
  },
  {
    "uniprot": "Q4CRM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRM8"
  },
  {
    "uniprot": "Q4CW14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW14"
  },
  {
    "uniprot": "Q4E3I5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3I5"
  },
  {
    "uniprot": "Q4CR10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR10"
  },
  {
    "uniprot": "Q4DGY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGY7"
  },
  {
    "uniprot": "Q4D8Q3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Q3"
  },
  {
    "uniprot": "Q4DXY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXY3"
  },
  {
    "uniprot": "Q4CM71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM71"
  },
  {
    "uniprot": "Q4DB14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB14"
  },
  {
    "uniprot": "Q4CRW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRW9"
  },
  {
    "uniprot": "Q4CU89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU89"
  },
  {
    "uniprot": "Q4DY63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY63"
  },
  {
    "uniprot": "Q4DHU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHU7"
  },
  {
    "uniprot": "Q4DUN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUN1"
  },
  {
    "uniprot": "Q4E134",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E134"
  },
  {
    "uniprot": "Q4D4S6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4S6"
  },
  {
    "uniprot": "Q4CUC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUC7"
  },
  {
    "uniprot": "Q4E5Q3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Q3"
  },
  {
    "uniprot": "Q4E265",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E265"
  },
  {
    "uniprot": "Q4D5Q1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Q1"
  },
  {
    "uniprot": "Q4DV20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV20"
  },
  {
    "uniprot": "Q4DZY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZY2"
  },
  {
    "uniprot": "Q4CSV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSV9"
  },
  {
    "uniprot": "Q4DNN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNN1"
  },
  {
    "uniprot": "Q4CUA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUA4"
  },
  {
    "uniprot": "Q4D686",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D686"
  },
  {
    "uniprot": "Q4DMS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMS6"
  },
  {
    "uniprot": "Q4D2L5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2L5"
  },
  {
    "uniprot": "Q4DQV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQV7"
  },
  {
    "uniprot": "Q4DNA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNA8"
  },
  {
    "uniprot": "Q4E0X7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0X7"
  },
  {
    "uniprot": "Q4DJF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJF5"
  },
  {
    "uniprot": "Q4D2D7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2D7"
  },
  {
    "uniprot": "Q4CS83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS83"
  },
  {
    "uniprot": "Q4DMG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMG6"
  },
  {
    "uniprot": "Q4DJ98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ98"
  },
  {
    "uniprot": "Q4CTY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTY8"
  },
  {
    "uniprot": "Q4E1Y1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Y1"
  },
  {
    "uniprot": "Q4CKC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKC4"
  },
  {
    "uniprot": "Q4E1Q2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Q2"
  },
  {
    "uniprot": "Q4DNK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNK4"
  },
  {
    "uniprot": "Q4DSH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSH1"
  },
  {
    "uniprot": "Q4CR34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR34"
  },
  {
    "uniprot": "Q4CQQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQQ1"
  },
  {
    "uniprot": "Q4CY63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY63"
  },
  {
    "uniprot": "Q4DXV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXV0"
  },
  {
    "uniprot": "Q4DAT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAT2"
  },
  {
    "uniprot": "Q4CV07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV07"
  },
  {
    "uniprot": "Q4DFH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFH8"
  },
  {
    "uniprot": "Q4E1I1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1I1"
  },
  {
    "uniprot": "Q4DNJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNJ1"
  },
  {
    "uniprot": "Q4CLB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLB7"
  },
  {
    "uniprot": "Q4E3X4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3X4"
  },
  {
    "uniprot": "Q4DVV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVV2"
  },
  {
    "uniprot": "Q4CV03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV03"
  },
  {
    "uniprot": "Q4DY73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY73"
  },
  {
    "uniprot": "Q4CYW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYW1"
  },
  {
    "uniprot": "Q4CVA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVA6"
  },
  {
    "uniprot": "Q4DX19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX19"
  },
  {
    "uniprot": "Q4DWV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWV4"
  },
  {
    "uniprot": "Q4DCG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCG3"
  },
  {
    "uniprot": "Q4CKK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKK0"
  },
  {
    "uniprot": "Q4D213",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D213"
  },
  {
    "uniprot": "Q4CNA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNA6"
  },
  {
    "uniprot": "Q4CT80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT80"
  },
  {
    "uniprot": "Q4CZJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZJ3"
  },
  {
    "uniprot": "Q4D6B9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6B9"
  },
  {
    "uniprot": "Q4DBJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBJ6"
  },
  {
    "uniprot": "Q4DWQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWQ1"
  },
  {
    "uniprot": "Q4DF46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF46"
  },
  {
    "uniprot": "Q4CZX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZX0"
  },
  {
    "uniprot": "Q4DUF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUF9"
  },
  {
    "uniprot": "Q4D047",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D047"
  },
  {
    "uniprot": "Q4DWE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWE0"
  },
  {
    "uniprot": "Q4E2Q0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Q0"
  },
  {
    "uniprot": "Q4DY20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY20"
  },
  {
    "uniprot": "Q4DXE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXE8"
  },
  {
    "uniprot": "Q4DPG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPG3"
  },
  {
    "uniprot": "Q4DY88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY88"
  },
  {
    "uniprot": "Q4DEH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEH6"
  },
  {
    "uniprot": "Q4DJA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJA0"
  },
  {
    "uniprot": "Q4CSV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSV0"
  },
  {
    "uniprot": "Q4DYR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYR9"
  },
  {
    "uniprot": "Q4DHX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHX5"
  },
  {
    "uniprot": "Q4D9K7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9K7"
  },
  {
    "uniprot": "Q4CU52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU52"
  },
  {
    "uniprot": "Q4CMK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMK7"
  },
  {
    "uniprot": "Q4DTM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTM8"
  },
  {
    "uniprot": "Q4E436",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E436"
  },
  {
    "uniprot": "Q4DH76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH76"
  },
  {
    "uniprot": "Q4DWE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWE1"
  },
  {
    "uniprot": "Q4DWX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWX9"
  },
  {
    "uniprot": "Q4E198",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E198"
  },
  {
    "uniprot": "Q4D9G6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9G6"
  },
  {
    "uniprot": "Q4DCM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCM2"
  },
  {
    "uniprot": "Q4CWD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWD6"
  },
  {
    "uniprot": "Q4DSC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSC9"
  },
  {
    "uniprot": "Q4E297",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E297"
  },
  {
    "uniprot": "Q4D277",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D277"
  },
  {
    "uniprot": "Q4CSN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSN6"
  },
  {
    "uniprot": "Q4D767",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D767"
  },
  {
    "uniprot": "Q4CN70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN70"
  },
  {
    "uniprot": "Q4E188",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E188"
  },
  {
    "uniprot": "Q4CPS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPS9"
  },
  {
    "uniprot": "Q4DE61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE61"
  },
  {
    "uniprot": "Q4E3V4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3V4"
  },
  {
    "uniprot": "Q4D0R0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0R0"
  },
  {
    "uniprot": "Q4DVL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVL2"
  },
  {
    "uniprot": "Q4DA90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA90"
  },
  {
    "uniprot": "Q4DBE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBE1"
  },
  {
    "uniprot": "Q4D2P6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2P6"
  },
  {
    "uniprot": "Q4D1R9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1R9"
  },
  {
    "uniprot": "Q4CNR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNR9"
  },
  {
    "uniprot": "Q4DCX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCX5"
  },
  {
    "uniprot": "Q4DMM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMM9"
  },
  {
    "uniprot": "Q4DCH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCH4"
  },
  {
    "uniprot": "Q4D709",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D709"
  },
  {
    "uniprot": "Q4DFT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFT4"
  },
  {
    "uniprot": "Q4E1Q7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Q7"
  },
  {
    "uniprot": "Q4E5R3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5R3"
  },
  {
    "uniprot": "Q4DR41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR41"
  },
  {
    "uniprot": "Q4CP73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP73"
  },
  {
    "uniprot": "Q4CL73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL73"
  },
  {
    "uniprot": "Q4DL97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL97"
  },
  {
    "uniprot": "Q4CPT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPT4"
  },
  {
    "uniprot": "Q4DGF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGF6"
  },
  {
    "uniprot": "Q4CWP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWP8"
  },
  {
    "uniprot": "Q4CXK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXK9"
  },
  {
    "uniprot": "Q4DSN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSN2"
  },
  {
    "uniprot": "Q4DV46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV46"
  },
  {
    "uniprot": "Q4CX88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX88"
  },
  {
    "uniprot": "Q4CMH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMH2"
  },
  {
    "uniprot": "Q4CMI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMI0"
  },
  {
    "uniprot": "Q4CY89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY89"
  },
  {
    "uniprot": "Q4DTZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTZ9"
  },
  {
    "uniprot": "Q4E4J1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4J1"
  },
  {
    "uniprot": "Q4CPK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPK6"
  },
  {
    "uniprot": "Q4DW03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW03"
  },
  {
    "uniprot": "Q4DV69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV69"
  },
  {
    "uniprot": "Q4D7F7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7F7"
  },
  {
    "uniprot": "Q4E404",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E404"
  },
  {
    "uniprot": "Q4D333",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D333"
  },
  {
    "uniprot": "Q4DZC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZC7"
  },
  {
    "uniprot": "Q4CYQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYQ7"
  },
  {
    "uniprot": "Q4D578",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D578"
  },
  {
    "uniprot": "Q4CMX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMX8"
  },
  {
    "uniprot": "Q4E227",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E227"
  },
  {
    "uniprot": "Q4DBY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBY0"
  },
  {
    "uniprot": "Q4DHH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHH3"
  },
  {
    "uniprot": "Q4CLW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLW6"
  },
  {
    "uniprot": "Q4E038",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E038"
  },
  {
    "uniprot": "Q4CVP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVP6"
  },
  {
    "uniprot": "Q4D391",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D391"
  },
  {
    "uniprot": "Q4DXY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXY7"
  },
  {
    "uniprot": "Q4CR03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR03"
  },
  {
    "uniprot": "Q4DE41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE41"
  },
  {
    "uniprot": "Q4DJV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJV0"
  },
  {
    "uniprot": "Q4DRW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRW9"
  },
  {
    "uniprot": "Q4DZH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZH3"
  },
  {
    "uniprot": "Q4DJ20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ20"
  },
  {
    "uniprot": "Q4DTD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTD2"
  },
  {
    "uniprot": "Q4CY09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY09"
  },
  {
    "uniprot": "Q4DZY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZY0"
  },
  {
    "uniprot": "Q4D7T6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7T6"
  },
  {
    "uniprot": "Q4DTX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTX6"
  },
  {
    "uniprot": "Q4D1Y2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Y2"
  },
  {
    "uniprot": "Q4CZH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZH9"
  },
  {
    "uniprot": "Q4DPV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPV4"
  },
  {
    "uniprot": "Q4DF71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF71"
  },
  {
    "uniprot": "Q4CYF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYF6"
  },
  {
    "uniprot": "Q4D7P0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7P0"
  },
  {
    "uniprot": "Q4DN49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN49"
  },
  {
    "uniprot": "Q4E5L4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5L4"
  },
  {
    "uniprot": "Q4CYY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYY5"
  },
  {
    "uniprot": "Q4E598",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E598"
  },
  {
    "uniprot": "Q4DMK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMK3"
  },
  {
    "uniprot": "Q4E3P7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3P7"
  },
  {
    "uniprot": "Q4CL26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL26"
  },
  {
    "uniprot": "Q4DL61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL61"
  },
  {
    "uniprot": "Q4DR79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR79"
  },
  {
    "uniprot": "Q4D0U2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0U2"
  },
  {
    "uniprot": "Q4DKX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKX8"
  },
  {
    "uniprot": "Q4CSL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSL6"
  },
  {
    "uniprot": "Q4DYA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYA7"
  },
  {
    "uniprot": "Q4CLB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLB4"
  },
  {
    "uniprot": "Q4D3A5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3A5"
  },
  {
    "uniprot": "Q4CPH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPH8"
  },
  {
    "uniprot": "Q4DIX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIX1"
  },
  {
    "uniprot": "Q4D2I0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2I0"
  },
  {
    "uniprot": "Q4E4Y9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Y9"
  },
  {
    "uniprot": "Q4D649",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D649"
  },
  {
    "uniprot": "Q4DGK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGK5"
  },
  {
    "uniprot": "Q4DDZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDZ1"
  },
  {
    "uniprot": "Q4CML2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CML2"
  },
  {
    "uniprot": "Q4D8M3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8M3"
  },
  {
    "uniprot": "Q4DVB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVB2"
  },
  {
    "uniprot": "Q4E0B0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0B0"
  },
  {
    "uniprot": "Q4DSZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSZ3"
  },
  {
    "uniprot": "Q4DKK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKK1"
  },
  {
    "uniprot": "Q4DWF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWF4"
  },
  {
    "uniprot": "Q4D8X0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8X0"
  },
  {
    "uniprot": "Q4DVZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVZ1"
  },
  {
    "uniprot": "Q4DCZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCZ7"
  },
  {
    "uniprot": "Q4DW27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW27"
  },
  {
    "uniprot": "Q4CT95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT95"
  },
  {
    "uniprot": "Q4DX69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX69"
  },
  {
    "uniprot": "Q4CKF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKF0"
  },
  {
    "uniprot": "Q4DL17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL17"
  },
  {
    "uniprot": "Q4DKC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKC1"
  },
  {
    "uniprot": "Q4DLN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLN3"
  },
  {
    "uniprot": "Q4CMC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMC8"
  },
  {
    "uniprot": "Q4DYT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYT5"
  },
  {
    "uniprot": "Q4DQD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQD4"
  },
  {
    "uniprot": "Q4D0P1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0P1"
  },
  {
    "uniprot": "Q4CM01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM01"
  },
  {
    "uniprot": "Q4CND9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CND9"
  },
  {
    "uniprot": "Q4D2V1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2V1"
  },
  {
    "uniprot": "Q4D3V7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3V7"
  },
  {
    "uniprot": "Q4DWM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWM0"
  },
  {
    "uniprot": "Q4DWA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWA2"
  },
  {
    "uniprot": "Q4E2U2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2U2"
  },
  {
    "uniprot": "Q4DMA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMA6"
  },
  {
    "uniprot": "Q4E2H7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2H7"
  },
  {
    "uniprot": "Q4CWT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWT3"
  },
  {
    "uniprot": "Q4CPD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPD4"
  },
  {
    "uniprot": "Q4CPN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPN5"
  },
  {
    "uniprot": "Q4CU20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU20"
  },
  {
    "uniprot": "Q4CR51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR51"
  },
  {
    "uniprot": "Q4DWU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWU4"
  },
  {
    "uniprot": "Q4DDF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDF0"
  },
  {
    "uniprot": "Q4D2Z6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Z6"
  },
  {
    "uniprot": "Q4CQR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQR4"
  },
  {
    "uniprot": "Q4E4J3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4J3"
  },
  {
    "uniprot": "Q4D2H8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2H8"
  },
  {
    "uniprot": "Q4D0T9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0T9"
  },
  {
    "uniprot": "Q4CME4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CME4"
  },
  {
    "uniprot": "Q4E2Q5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Q5"
  },
  {
    "uniprot": "Q4D102",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D102"
  },
  {
    "uniprot": "Q4DRD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRD4"
  },
  {
    "uniprot": "Q4E652",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E652"
  },
  {
    "uniprot": "Q4DQY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQY1"
  },
  {
    "uniprot": "Q4DBS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBS2"
  },
  {
    "uniprot": "Q4DML3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DML3"
  },
  {
    "uniprot": "Q4E2A6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2A6"
  },
  {
    "uniprot": "Q4D089",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D089"
  },
  {
    "uniprot": "Q4CWS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWS6"
  },
  {
    "uniprot": "Q4D172",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D172"
  },
  {
    "uniprot": "Q4CXV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXV0"
  },
  {
    "uniprot": "Q4DGI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGI4"
  },
  {
    "uniprot": "Q4DSS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSS6"
  },
  {
    "uniprot": "Q4DU68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU68"
  },
  {
    "uniprot": "Q4CN54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN54"
  },
  {
    "uniprot": "Q4CX86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX86"
  },
  {
    "uniprot": "Q4E5M7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5M7"
  },
  {
    "uniprot": "Q4CVW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVW2"
  },
  {
    "uniprot": "Q4DNS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNS3"
  },
  {
    "uniprot": "Q4DMK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMK2"
  },
  {
    "uniprot": "Q4DV84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV84"
  },
  {
    "uniprot": "Q4D278",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D278"
  },
  {
    "uniprot": "Q4DQS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQS9"
  },
  {
    "uniprot": "Q4CLG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLG3"
  },
  {
    "uniprot": "Q4CT69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT69"
  },
  {
    "uniprot": "Q4DCL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCL6"
  },
  {
    "uniprot": "Q4D048",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D048"
  },
  {
    "uniprot": "Q4CW51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW51"
  },
  {
    "uniprot": "Q4DL69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL69"
  },
  {
    "uniprot": "Q4D2L4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2L4"
  },
  {
    "uniprot": "Q4DQ99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ99"
  },
  {
    "uniprot": "Q4E345",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E345"
  },
  {
    "uniprot": "Q4CS13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS13"
  },
  {
    "uniprot": "Q4DTA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTA8"
  },
  {
    "uniprot": "Q4DLY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLY8"
  },
  {
    "uniprot": "Q4CN18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN18"
  },
  {
    "uniprot": "Q4D220",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D220"
  },
  {
    "uniprot": "Q4DQQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQQ7"
  },
  {
    "uniprot": "Q4CX47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX47"
  },
  {
    "uniprot": "Q4DEG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEG7"
  },
  {
    "uniprot": "Q4DUX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUX0"
  },
  {
    "uniprot": "Q4DWQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWQ0"
  },
  {
    "uniprot": "Q4CZT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZT9"
  },
  {
    "uniprot": "Q4CRN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRN8"
  },
  {
    "uniprot": "Q4E1L3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1L3"
  },
  {
    "uniprot": "Q4CQ12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ12"
  },
  {
    "uniprot": "Q4DLK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLK0"
  },
  {
    "uniprot": "Q4CXZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXZ7"
  },
  {
    "uniprot": "Q4CMA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMA9"
  },
  {
    "uniprot": "Q4DNE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNE8"
  },
  {
    "uniprot": "Q4E0V3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0V3"
  },
  {
    "uniprot": "Q4CRA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRA6"
  },
  {
    "uniprot": "Q4CY45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY45"
  },
  {
    "uniprot": "Q4CPV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPV5"
  },
  {
    "uniprot": "Q4E0X0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0X0"
  },
  {
    "uniprot": "Q4DGW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGW3"
  },
  {
    "uniprot": "Q4CR19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR19"
  },
  {
    "uniprot": "Q4DW54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW54"
  },
  {
    "uniprot": "Q4DAK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAK4"
  },
  {
    "uniprot": "Q4D6A7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6A7"
  },
  {
    "uniprot": "Q4DG60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG60"
  },
  {
    "uniprot": "Q4D4Z2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Z2"
  },
  {
    "uniprot": "Q4CRN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRN2"
  },
  {
    "uniprot": "Q4DHN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHN8"
  },
  {
    "uniprot": "Q4DRU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRU9"
  },
  {
    "uniprot": "Q4E004",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E004"
  },
  {
    "uniprot": "Q4DKY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKY6"
  },
  {
    "uniprot": "Q4D3F8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3F8"
  },
  {
    "uniprot": "Q4CRE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRE8"
  },
  {
    "uniprot": "Q4CMT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMT5"
  },
  {
    "uniprot": "Q4DMG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMG8"
  },
  {
    "uniprot": "Q4DEQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEQ1"
  },
  {
    "uniprot": "Q4CYT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYT2"
  },
  {
    "uniprot": "Q4CXH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXH4"
  },
  {
    "uniprot": "Q4E1M1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1M1"
  },
  {
    "uniprot": "Q4DBN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBN9"
  },
  {
    "uniprot": "Q4CXG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXG5"
  },
  {
    "uniprot": "Q4DE93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE93"
  },
  {
    "uniprot": "Q4CLJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLJ8"
  },
  {
    "uniprot": "Q4DQH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQH7"
  },
  {
    "uniprot": "Q4E3D1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3D1"
  },
  {
    "uniprot": "Q4CQC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQC9"
  },
  {
    "uniprot": "Q4CVX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVX1"
  },
  {
    "uniprot": "Q4DFH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFH7"
  },
  {
    "uniprot": "Q4E678",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E678"
  },
  {
    "uniprot": "Q4CXZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXZ2"
  },
  {
    "uniprot": "Q4DQM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQM2"
  },
  {
    "uniprot": "Q4D1D2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1D2"
  },
  {
    "uniprot": "Q4CQ20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ20"
  },
  {
    "uniprot": "Q4CN30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN30"
  },
  {
    "uniprot": "Q4DZ37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ37"
  },
  {
    "uniprot": "Q4D0K9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0K9"
  },
  {
    "uniprot": "Q4DD17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD17"
  },
  {
    "uniprot": "Q4DSA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSA9"
  },
  {
    "uniprot": "Q4D9R2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9R2"
  },
  {
    "uniprot": "Q4D5S4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5S4"
  },
  {
    "uniprot": "Q4DVW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVW2"
  },
  {
    "uniprot": "Q4DVY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVY4"
  },
  {
    "uniprot": "Q4DKG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKG6"
  },
  {
    "uniprot": "Q4D9X7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9X7"
  },
  {
    "uniprot": "Q4D8P1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8P1"
  },
  {
    "uniprot": "Q4DI10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI10"
  },
  {
    "uniprot": "Q4D586",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D586"
  },
  {
    "uniprot": "Q4DX82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX82"
  },
  {
    "uniprot": "Q4CQM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQM8"
  },
  {
    "uniprot": "Q4D942",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D942"
  },
  {
    "uniprot": "Q4DP72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP72"
  },
  {
    "uniprot": "Q4DPL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPL1"
  },
  {
    "uniprot": "Q4DNB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNB9"
  },
  {
    "uniprot": "Q4DZS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZS5"
  },
  {
    "uniprot": "Q4DN80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN80"
  },
  {
    "uniprot": "Q4CPH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPH3"
  },
  {
    "uniprot": "Q4DB99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB99"
  },
  {
    "uniprot": "Q4DAU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAU6"
  },
  {
    "uniprot": "Q4DWF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWF1"
  },
  {
    "uniprot": "Q4DS42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS42"
  },
  {
    "uniprot": "Q4DJH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJH4"
  },
  {
    "uniprot": "Q4CVH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVH9"
  },
  {
    "uniprot": "Q4CWS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWS0"
  },
  {
    "uniprot": "Q4CY82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY82"
  },
  {
    "uniprot": "Q4CSS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSS8"
  },
  {
    "uniprot": "Q4DB96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB96"
  },
  {
    "uniprot": "Q4DY47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY47"
  },
  {
    "uniprot": "Q4DP85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP85"
  },
  {
    "uniprot": "Q4E2A1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2A1"
  },
  {
    "uniprot": "Q4D101",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D101"
  },
  {
    "uniprot": "Q4DVG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVG6"
  },
  {
    "uniprot": "Q4D9P4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9P4"
  },
  {
    "uniprot": "Q4DYB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYB6"
  },
  {
    "uniprot": "Q4CSD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSD9"
  },
  {
    "uniprot": "Q4DVK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVK9"
  },
  {
    "uniprot": "Q4CXP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXP7"
  },
  {
    "uniprot": "Q4DSG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSG0"
  },
  {
    "uniprot": "Q4DGS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGS7"
  },
  {
    "uniprot": "Q4CMA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMA0"
  },
  {
    "uniprot": "Q4E1B6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1B6"
  },
  {
    "uniprot": "Q4CWL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWL6"
  },
  {
    "uniprot": "Q4CRG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRG3"
  },
  {
    "uniprot": "Q4E2C4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2C4"
  },
  {
    "uniprot": "Q4DJV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJV8"
  },
  {
    "uniprot": "Q4DLI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLI7"
  },
  {
    "uniprot": "Q4DI01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI01"
  },
  {
    "uniprot": "Q4CLQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLQ7"
  },
  {
    "uniprot": "Q4CMD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMD0"
  },
  {
    "uniprot": "Q4DZF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZF9"
  },
  {
    "uniprot": "Q4CUW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUW3"
  },
  {
    "uniprot": "Q4D039",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D039"
  },
  {
    "uniprot": "Q4D5E9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5E9"
  },
  {
    "uniprot": "Q4CTX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTX2"
  },
  {
    "uniprot": "Q4D7F8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7F8"
  },
  {
    "uniprot": "Q4CQF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQF6"
  },
  {
    "uniprot": "Q4CLX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLX4"
  },
  {
    "uniprot": "Q4DVY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVY9"
  },
  {
    "uniprot": "Q4DNL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNL6"
  },
  {
    "uniprot": "Q4DE63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE63"
  },
  {
    "uniprot": "Q4D4V8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4V8"
  },
  {
    "uniprot": "Q4E0W7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0W7"
  },
  {
    "uniprot": "Q4DFZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFZ2"
  },
  {
    "uniprot": "Q4D7G3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7G3"
  },
  {
    "uniprot": "Q4E1V5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1V5"
  },
  {
    "uniprot": "Q4CQB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQB3"
  },
  {
    "uniprot": "Q4CXE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXE4"
  },
  {
    "uniprot": "Q4DV12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV12"
  },
  {
    "uniprot": "Q4DE03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE03"
  },
  {
    "uniprot": "Q4E2S7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2S7"
  },
  {
    "uniprot": "Q4CM82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM82"
  },
  {
    "uniprot": "Q4E3T4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3T4"
  },
  {
    "uniprot": "Q4DMK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMK8"
  },
  {
    "uniprot": "Q4D9V3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9V3"
  },
  {
    "uniprot": "Q4D890",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D890"
  },
  {
    "uniprot": "Q4DQJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQJ9"
  },
  {
    "uniprot": "Q4DCR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCR4"
  },
  {
    "uniprot": "Q4D0C8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0C8"
  },
  {
    "uniprot": "Q4DQ16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ16"
  },
  {
    "uniprot": "Q4DJC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJC7"
  },
  {
    "uniprot": "Q4E4Z9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Z9"
  },
  {
    "uniprot": "Q4DQ54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ54"
  },
  {
    "uniprot": "Q4DNT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNT4"
  },
  {
    "uniprot": "Q4CV24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV24"
  },
  {
    "uniprot": "Q4DMV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMV7"
  },
  {
    "uniprot": "Q4D3B8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3B8"
  },
  {
    "uniprot": "Q4DTB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTB6"
  },
  {
    "uniprot": "Q4DR70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR70"
  },
  {
    "uniprot": "Q4DJG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJG1"
  },
  {
    "uniprot": "Q4CVB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVB8"
  },
  {
    "uniprot": "Q4DPZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPZ5"
  },
  {
    "uniprot": "Q4DXT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXT3"
  },
  {
    "uniprot": "Q4CL96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL96"
  },
  {
    "uniprot": "Q4CMH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMH3"
  },
  {
    "uniprot": "Q4DKV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKV9"
  },
  {
    "uniprot": "Q4CS51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS51"
  },
  {
    "uniprot": "Q4D337",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D337"
  },
  {
    "uniprot": "Q4DVL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVL7"
  },
  {
    "uniprot": "Q4DD91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD91"
  },
  {
    "uniprot": "Q4CMV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMV2"
  },
  {
    "uniprot": "Q4DDJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDJ9"
  },
  {
    "uniprot": "Q4DR30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR30"
  },
  {
    "uniprot": "Q4DL77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL77"
  },
  {
    "uniprot": "Q4D1D8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1D8"
  },
  {
    "uniprot": "Q4E4J4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4J4"
  },
  {
    "uniprot": "Q4DDW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDW8"
  },
  {
    "uniprot": "Q4DNW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNW6"
  },
  {
    "uniprot": "Q4DSP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSP8"
  },
  {
    "uniprot": "Q4CN23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN23"
  },
  {
    "uniprot": "Q4CZR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZR0"
  },
  {
    "uniprot": "Q4CQK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQK7"
  },
  {
    "uniprot": "Q4CQE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQE1"
  },
  {
    "uniprot": "Q4CQU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQU1"
  },
  {
    "uniprot": "Q4DGV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGV5"
  },
  {
    "uniprot": "Q4CRI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRI3"
  },
  {
    "uniprot": "Q4D0F2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0F2"
  },
  {
    "uniprot": "Q4CM03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM03"
  },
  {
    "uniprot": "Q4CMS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMS7"
  },
  {
    "uniprot": "Q4CYE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYE8"
  },
  {
    "uniprot": "Q4D891",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D891"
  },
  {
    "uniprot": "Q4CM89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM89"
  },
  {
    "uniprot": "Q4DE38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE38"
  },
  {
    "uniprot": "Q4CM76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM76"
  },
  {
    "uniprot": "Q4DCU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCU7"
  },
  {
    "uniprot": "Q4CSB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSB3"
  },
  {
    "uniprot": "Q4CRY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRY1"
  },
  {
    "uniprot": "Q4DBQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBQ4"
  },
  {
    "uniprot": "Q4DKN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKN5"
  },
  {
    "uniprot": "Q4E0Z4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Z4"
  },
  {
    "uniprot": "Q4D8X1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8X1"
  },
  {
    "uniprot": "Q4CPM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPM5"
  },
  {
    "uniprot": "Q4E623",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E623"
  },
  {
    "uniprot": "Q4CTA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTA4"
  },
  {
    "uniprot": "Q4CN25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN25"
  },
  {
    "uniprot": "Q4DQ66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ66"
  },
  {
    "uniprot": "Q4CZJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZJ6"
  },
  {
    "uniprot": "Q4D6X3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6X3"
  },
  {
    "uniprot": "Q4DFM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFM7"
  },
  {
    "uniprot": "Q4DC34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC34"
  },
  {
    "uniprot": "Q4E2J4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2J4"
  },
  {
    "uniprot": "Q4DYZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYZ1"
  },
  {
    "uniprot": "Q4E2X1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2X1"
  },
  {
    "uniprot": "Q4DSE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSE3"
  },
  {
    "uniprot": "Q4E4Y1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Y1"
  },
  {
    "uniprot": "Q4DVE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVE1"
  },
  {
    "uniprot": "Q4CPA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPA7"
  },
  {
    "uniprot": "Q4DAY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAY2"
  },
  {
    "uniprot": "Q4DWV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWV9"
  },
  {
    "uniprot": "Q4DMK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMK6"
  },
  {
    "uniprot": "Q4DZV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZV2"
  },
  {
    "uniprot": "Q4CTN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTN8"
  },
  {
    "uniprot": "Q4E320",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E320"
  },
  {
    "uniprot": "Q4DME0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DME0"
  },
  {
    "uniprot": "Q4DFA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFA2"
  },
  {
    "uniprot": "Q4D7B2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7B2"
  },
  {
    "uniprot": "Q4CN26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN26"
  },
  {
    "uniprot": "Q4DA16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA16"
  },
  {
    "uniprot": "Q4D9G2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9G2"
  },
  {
    "uniprot": "Q4DZ95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ95"
  },
  {
    "uniprot": "Q4DXS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXS6"
  },
  {
    "uniprot": "Q4DTT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTT6"
  },
  {
    "uniprot": "Q4CL23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL23"
  },
  {
    "uniprot": "Q4CUN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUN8"
  },
  {
    "uniprot": "Q4E4J6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4J6"
  },
  {
    "uniprot": "Q4DKX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKX7"
  },
  {
    "uniprot": "Q4CKN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKN9"
  },
  {
    "uniprot": "Q4CQF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQF2"
  },
  {
    "uniprot": "Q4CWE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWE5"
  },
  {
    "uniprot": "Q4CP34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP34"
  },
  {
    "uniprot": "Q4CK91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CK91"
  },
  {
    "uniprot": "Q4CUX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUX2"
  },
  {
    "uniprot": "Q4D5U4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5U4"
  },
  {
    "uniprot": "Q4DLW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLW3"
  },
  {
    "uniprot": "Q4DJA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJA4"
  },
  {
    "uniprot": "Q4DPB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPB3"
  },
  {
    "uniprot": "Q4CVN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVN0"
  },
  {
    "uniprot": "Q4D2L0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2L0"
  },
  {
    "uniprot": "Q4DVH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVH1"
  },
  {
    "uniprot": "Q4E1P6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1P6"
  },
  {
    "uniprot": "Q4CZQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZQ5"
  },
  {
    "uniprot": "Q4E518",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E518"
  },
  {
    "uniprot": "Q4DWU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWU6"
  },
  {
    "uniprot": "Q4CYR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYR0"
  },
  {
    "uniprot": "Q4CQ68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ68"
  },
  {
    "uniprot": "Q4D3E3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3E3"
  },
  {
    "uniprot": "Q4DV78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV78"
  },
  {
    "uniprot": "Q4D6N6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6N6"
  },
  {
    "uniprot": "Q4DDH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDH7"
  },
  {
    "uniprot": "Q4CLT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLT0"
  },
  {
    "uniprot": "Q4CL77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL77"
  },
  {
    "uniprot": "Q4DZY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZY3"
  },
  {
    "uniprot": "Q4E2E0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2E0"
  },
  {
    "uniprot": "Q4D620",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D620"
  },
  {
    "uniprot": "Q4CUN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUN3"
  },
  {
    "uniprot": "Q4DFX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFX5"
  },
  {
    "uniprot": "Q4CTI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTI9"
  },
  {
    "uniprot": "Q4DZD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZD5"
  },
  {
    "uniprot": "Q4E5X8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5X8"
  },
  {
    "uniprot": "Q4DJY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJY9"
  },
  {
    "uniprot": "Q4DQ57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ57"
  },
  {
    "uniprot": "Q4DLA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLA2"
  },
  {
    "uniprot": "Q4DWB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWB8"
  },
  {
    "uniprot": "Q4D692",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D692"
  },
  {
    "uniprot": "Q4D7Z1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Z1"
  },
  {
    "uniprot": "Q4D568",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D568"
  },
  {
    "uniprot": "Q4E3A1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3A1"
  },
  {
    "uniprot": "Q4DGG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGG4"
  },
  {
    "uniprot": "Q4CW65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW65"
  },
  {
    "uniprot": "Q4DPJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPJ3"
  },
  {
    "uniprot": "Q4D5J5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5J5"
  },
  {
    "uniprot": "Q4DAB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAB5"
  },
  {
    "uniprot": "Q4D7I5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7I5"
  },
  {
    "uniprot": "Q4DLG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLG0"
  },
  {
    "uniprot": "Q4DES0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DES0"
  },
  {
    "uniprot": "Q4CKA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKA9"
  },
  {
    "uniprot": "Q4DXB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXB6"
  },
  {
    "uniprot": "Q4DUT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUT0"
  },
  {
    "uniprot": "Q4D8N8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8N8"
  },
  {
    "uniprot": "Q4DPB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPB6"
  },
  {
    "uniprot": "Q4E3T9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3T9"
  },
  {
    "uniprot": "Q4E4M6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4M6"
  },
  {
    "uniprot": "Q4DWZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWZ5"
  },
  {
    "uniprot": "Q4DZK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZK7"
  },
  {
    "uniprot": "Q4CPH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPH7"
  },
  {
    "uniprot": "Q4D2G4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2G4"
  },
  {
    "uniprot": "Q4DUM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUM0"
  },
  {
    "uniprot": "Q4DE24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE24"
  },
  {
    "uniprot": "Q4CPT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPT1"
  },
  {
    "uniprot": "Q4D6I0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6I0"
  },
  {
    "uniprot": "Q4DMC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMC2"
  },
  {
    "uniprot": "Q4DTZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTZ8"
  },
  {
    "uniprot": "Q4CQU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQU8"
  },
  {
    "uniprot": "Q4CNA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNA8"
  },
  {
    "uniprot": "Q4DD77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD77"
  },
  {
    "uniprot": "Q4D8L7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8L7"
  },
  {
    "uniprot": "Q4DEQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEQ0"
  },
  {
    "uniprot": "Q4CQK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQK3"
  },
  {
    "uniprot": "Q4E177",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E177"
  },
  {
    "uniprot": "Q4E0Q2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Q2"
  },
  {
    "uniprot": "Q4DH08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH08"
  },
  {
    "uniprot": "Q4DDF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDF3"
  },
  {
    "uniprot": "Q4D0K7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0K7"
  },
  {
    "uniprot": "Q4D7N9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7N9"
  },
  {
    "uniprot": "Q4DX11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX11"
  },
  {
    "uniprot": "Q4D4X4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4X4"
  },
  {
    "uniprot": "Q4DMU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMU9"
  },
  {
    "uniprot": "Q4DFQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFQ1"
  },
  {
    "uniprot": "Q4DJ87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ87"
  },
  {
    "uniprot": "Q4DYJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYJ5"
  },
  {
    "uniprot": "Q4DS29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS29"
  },
  {
    "uniprot": "Q4D306",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D306"
  },
  {
    "uniprot": "Q4CLB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLB8"
  },
  {
    "uniprot": "Q4D1S6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1S6"
  },
  {
    "uniprot": "Q4CWX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWX3"
  },
  {
    "uniprot": "Q4CUQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUQ4"
  },
  {
    "uniprot": "Q4DIM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIM8"
  },
  {
    "uniprot": "Q4DV79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV79"
  },
  {
    "uniprot": "Q4D410",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D410"
  },
  {
    "uniprot": "Q4DJ45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ45"
  },
  {
    "uniprot": "Q4CY10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY10"
  },
  {
    "uniprot": "Q4CWG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWG0"
  },
  {
    "uniprot": "Q4CT88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT88"
  },
  {
    "uniprot": "Q4CP53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP53"
  },
  {
    "uniprot": "Q4DYQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYQ8"
  },
  {
    "uniprot": "Q4DRR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRR9"
  },
  {
    "uniprot": "Q4DH27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH27"
  },
  {
    "uniprot": "Q4DZS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZS4"
  },
  {
    "uniprot": "Q4DWE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWE5"
  },
  {
    "uniprot": "Q4DK53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK53"
  },
  {
    "uniprot": "Q4DWB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWB6"
  },
  {
    "uniprot": "Q4DKD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKD3"
  },
  {
    "uniprot": "Q4DRT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRT6"
  },
  {
    "uniprot": "Q4E4W4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4W4"
  },
  {
    "uniprot": "Q4CT67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT67"
  },
  {
    "uniprot": "Q4DXL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXL2"
  },
  {
    "uniprot": "Q4CM41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM41"
  },
  {
    "uniprot": "Q4CY29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY29"
  },
  {
    "uniprot": "Q4DBV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBV4"
  },
  {
    "uniprot": "Q4D7I0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7I0"
  },
  {
    "uniprot": "Q4DF86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF86"
  },
  {
    "uniprot": "Q4DJ14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ14"
  },
  {
    "uniprot": "Q4CTU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTU5"
  },
  {
    "uniprot": "Q4DZJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZJ1"
  },
  {
    "uniprot": "Q4E587",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E587"
  },
  {
    "uniprot": "Q4E5L9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5L9"
  },
  {
    "uniprot": "Q4CVR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVR1"
  },
  {
    "uniprot": "Q4DU16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU16"
  },
  {
    "uniprot": "Q4D5G5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5G5"
  },
  {
    "uniprot": "Q4DIE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIE7"
  },
  {
    "uniprot": "Q4DI45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI45"
  },
  {
    "uniprot": "Q4DW22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW22"
  },
  {
    "uniprot": "Q4D6N3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6N3"
  },
  {
    "uniprot": "Q4DJR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJR4"
  },
  {
    "uniprot": "Q4E0S8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0S8"
  },
  {
    "uniprot": "Q4E5V6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5V6"
  },
  {
    "uniprot": "Q4DY35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY35"
  },
  {
    "uniprot": "Q4DEX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEX4"
  },
  {
    "uniprot": "Q4DP51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP51"
  },
  {
    "uniprot": "Q4DNX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNX2"
  },
  {
    "uniprot": "Q4CNG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNG3"
  },
  {
    "uniprot": "Q4DZT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZT6"
  },
  {
    "uniprot": "Q4CZ11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ11"
  },
  {
    "uniprot": "Q4DRQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRQ1"
  },
  {
    "uniprot": "Q4D4I2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4I2"
  },
  {
    "uniprot": "Q4CUQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUQ8"
  },
  {
    "uniprot": "Q4DP52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP52"
  },
  {
    "uniprot": "Q4CVN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVN4"
  },
  {
    "uniprot": "Q4DK38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK38"
  },
  {
    "uniprot": "Q4DTJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTJ4"
  },
  {
    "uniprot": "Q4E2T7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2T7"
  },
  {
    "uniprot": "Q4CWP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWP1"
  },
  {
    "uniprot": "Q4D5K9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5K9"
  },
  {
    "uniprot": "Q4E286",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E286"
  },
  {
    "uniprot": "Q4D1Z4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Z4"
  },
  {
    "uniprot": "Q4DFP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFP6"
  },
  {
    "uniprot": "Q4CQJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQJ8"
  },
  {
    "uniprot": "Q4DY25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY25"
  },
  {
    "uniprot": "Q4D263",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D263"
  },
  {
    "uniprot": "Q4E3D7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3D7"
  },
  {
    "uniprot": "Q4CP17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP17"
  },
  {
    "uniprot": "Q4DL48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL48"
  },
  {
    "uniprot": "Q4DXW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXW8"
  },
  {
    "uniprot": "Q4CLF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLF4"
  },
  {
    "uniprot": "Q4D8H3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8H3"
  },
  {
    "uniprot": "Q4DE76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE76"
  },
  {
    "uniprot": "Q4DRM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRM0"
  },
  {
    "uniprot": "Q4DA18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA18"
  },
  {
    "uniprot": "Q4DD16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD16"
  },
  {
    "uniprot": "Q4CX64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX64"
  },
  {
    "uniprot": "Q4CZC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZC1"
  },
  {
    "uniprot": "Q4CZ53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ53"
  },
  {
    "uniprot": "Q4D6P3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6P3"
  },
  {
    "uniprot": "Q4CQU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQU5"
  },
  {
    "uniprot": "Q4E2C0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2C0"
  },
  {
    "uniprot": "Q4DG59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG59"
  },
  {
    "uniprot": "Q4CWT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWT4"
  },
  {
    "uniprot": "Q4DW96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW96"
  },
  {
    "uniprot": "Q4DLA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLA8"
  },
  {
    "uniprot": "Q4DJ54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ54"
  },
  {
    "uniprot": "Q4DRJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRJ5"
  },
  {
    "uniprot": "Q4DK60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK60"
  },
  {
    "uniprot": "Q4E0V9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0V9"
  },
  {
    "uniprot": "Q4D4D9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4D9"
  },
  {
    "uniprot": "Q4E1F5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1F5"
  },
  {
    "uniprot": "Q4CYA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYA5"
  },
  {
    "uniprot": "Q4DZ29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ29"
  },
  {
    "uniprot": "Q4CLF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLF3"
  },
  {
    "uniprot": "Q4CLL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLL9"
  },
  {
    "uniprot": "Q4DTS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTS1"
  },
  {
    "uniprot": "Q4DDI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDI8"
  },
  {
    "uniprot": "Q4CX56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX56"
  },
  {
    "uniprot": "Q4D810",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D810"
  },
  {
    "uniprot": "Q4D9J3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9J3"
  },
  {
    "uniprot": "Q4E2F8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2F8"
  },
  {
    "uniprot": "Q4D6H6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6H6"
  },
  {
    "uniprot": "Q4D6X2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6X2"
  },
  {
    "uniprot": "Q4DKL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKL7"
  },
  {
    "uniprot": "Q4DM21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM21"
  },
  {
    "uniprot": "Q4DLR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLR6"
  },
  {
    "uniprot": "Q4DT81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT81"
  },
  {
    "uniprot": "Q4CVG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVG5"
  },
  {
    "uniprot": "Q4E1A9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1A9"
  },
  {
    "uniprot": "Q4E2D9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2D9"
  },
  {
    "uniprot": "Q4DLU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLU9"
  },
  {
    "uniprot": "Q4DJ33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ33"
  },
  {
    "uniprot": "Q4CSI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSI1"
  },
  {
    "uniprot": "Q4DW70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW70"
  },
  {
    "uniprot": "Q4D0C2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0C2"
  },
  {
    "uniprot": "Q4D5Y1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Y1"
  },
  {
    "uniprot": "Q4E052",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E052"
  },
  {
    "uniprot": "Q4D5P4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5P4"
  },
  {
    "uniprot": "Q4E3N0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3N0"
  },
  {
    "uniprot": "Q4DDN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDN0"
  },
  {
    "uniprot": "Q4D3H6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3H6"
  },
  {
    "uniprot": "Q4CY05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY05"
  },
  {
    "uniprot": "Q4CQN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQN6"
  },
  {
    "uniprot": "Q4CLJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLJ9"
  },
  {
    "uniprot": "Q4D366",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D366"
  },
  {
    "uniprot": "Q4DSU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSU1"
  },
  {
    "uniprot": "Q4D3C9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3C9"
  },
  {
    "uniprot": "Q4DDR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDR2"
  },
  {
    "uniprot": "Q4DVP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVP2"
  },
  {
    "uniprot": "Q4DYV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYV7"
  },
  {
    "uniprot": "Q4DTR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTR2"
  },
  {
    "uniprot": "Q4DSH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSH0"
  },
  {
    "uniprot": "Q4CWI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWI5"
  },
  {
    "uniprot": "Q4DPJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPJ6"
  },
  {
    "uniprot": "Q4DGI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGI1"
  },
  {
    "uniprot": "Q4CKY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKY4"
  },
  {
    "uniprot": "Q4DX25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX25"
  },
  {
    "uniprot": "Q4DQA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQA3"
  },
  {
    "uniprot": "Q4CQW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQW0"
  },
  {
    "uniprot": "Q4D3W1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3W1"
  },
  {
    "uniprot": "Q4DCE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCE5"
  },
  {
    "uniprot": "Q4CWL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWL8"
  },
  {
    "uniprot": "Q4DBY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBY6"
  },
  {
    "uniprot": "Q4E1Q3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Q3"
  },
  {
    "uniprot": "Q4DJT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJT7"
  },
  {
    "uniprot": "Q4CKV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKV2"
  },
  {
    "uniprot": "Q4E2F3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2F3"
  },
  {
    "uniprot": "Q4CSX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSX1"
  },
  {
    "uniprot": "Q4DP69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP69"
  },
  {
    "uniprot": "Q4E4A2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4A2"
  },
  {
    "uniprot": "Q4D0K2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0K2"
  },
  {
    "uniprot": "Q4DAH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAH2"
  },
  {
    "uniprot": "Q4CV02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV02"
  },
  {
    "uniprot": "Q4D867",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D867"
  },
  {
    "uniprot": "Q4DKY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKY5"
  },
  {
    "uniprot": "Q4DHL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHL8"
  },
  {
    "uniprot": "Q4DQ70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ70"
  },
  {
    "uniprot": "Q4DNJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNJ4"
  },
  {
    "uniprot": "Q4DSV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSV7"
  },
  {
    "uniprot": "Q4DEL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEL6"
  },
  {
    "uniprot": "Q4DRT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRT9"
  },
  {
    "uniprot": "Q4CU98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU98"
  },
  {
    "uniprot": "Q4DUY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUY5"
  },
  {
    "uniprot": "Q4D1H0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1H0"
  },
  {
    "uniprot": "Q4D7C9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7C9"
  },
  {
    "uniprot": "Q4DDH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDH2"
  },
  {
    "uniprot": "Q4DVW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVW3"
  },
  {
    "uniprot": "Q4E1X2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1X2"
  },
  {
    "uniprot": "Q4D8G9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8G9"
  },
  {
    "uniprot": "Q4DF24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF24"
  },
  {
    "uniprot": "Q4CQV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQV0"
  },
  {
    "uniprot": "Q4CQ95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ95"
  },
  {
    "uniprot": "Q4E2K0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2K0"
  },
  {
    "uniprot": "Q4E1T3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1T3"
  },
  {
    "uniprot": "Q4DPQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPQ8"
  },
  {
    "uniprot": "Q4E306",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E306"
  },
  {
    "uniprot": "Q4DZV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZV8"
  },
  {
    "uniprot": "Q4CS86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS86"
  },
  {
    "uniprot": "Q4D419",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D419"
  },
  {
    "uniprot": "Q4CSB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSB7"
  },
  {
    "uniprot": "Q4DLF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLF4"
  },
  {
    "uniprot": "Q4D886",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D886"
  },
  {
    "uniprot": "Q4CX70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX70"
  },
  {
    "uniprot": "Q4CS69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS69"
  },
  {
    "uniprot": "Q4DW04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW04"
  },
  {
    "uniprot": "Q4D761",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D761"
  },
  {
    "uniprot": "Q4DB47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB47"
  },
  {
    "uniprot": "Q4D0L8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0L8"
  },
  {
    "uniprot": "Q4DH42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH42"
  },
  {
    "uniprot": "Q4DIN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIN2"
  },
  {
    "uniprot": "Q4DRS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRS5"
  },
  {
    "uniprot": "Q4D8J8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8J8"
  },
  {
    "uniprot": "Q4DD71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD71"
  },
  {
    "uniprot": "Q4E3F2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3F2"
  },
  {
    "uniprot": "Q4DZW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZW8"
  },
  {
    "uniprot": "Q4DFM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFM6"
  },
  {
    "uniprot": "Q4DPS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPS7"
  },
  {
    "uniprot": "Q4E4Z8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Z8"
  },
  {
    "uniprot": "Q4DTV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTV3"
  },
  {
    "uniprot": "Q4CSD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSD8"
  },
  {
    "uniprot": "Q4DXY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXY9"
  },
  {
    "uniprot": "Q4DA50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA50"
  },
  {
    "uniprot": "Q4CX26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX26"
  },
  {
    "uniprot": "Q4DV10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV10"
  },
  {
    "uniprot": "Q4CZF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZF6"
  },
  {
    "uniprot": "Q4DGB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGB3"
  },
  {
    "uniprot": "Q4DLG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLG5"
  },
  {
    "uniprot": "Q4DF04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF04"
  },
  {
    "uniprot": "Q4CRZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRZ4"
  },
  {
    "uniprot": "Q4D945",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D945"
  },
  {
    "uniprot": "Q4CW43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW43"
  },
  {
    "uniprot": "Q4CUI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUI7"
  },
  {
    "uniprot": "Q4DD76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD76"
  },
  {
    "uniprot": "Q4E1N8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1N8"
  },
  {
    "uniprot": "Q4CW25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW25"
  },
  {
    "uniprot": "Q4DGT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGT1"
  },
  {
    "uniprot": "Q4CWM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWM0"
  },
  {
    "uniprot": "Q4DIE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIE3"
  },
  {
    "uniprot": "Q4D9E3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9E3"
  },
  {
    "uniprot": "Q4CRT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRT1"
  },
  {
    "uniprot": "Q4E5W6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5W6"
  },
  {
    "uniprot": "Q4CTX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTX3"
  },
  {
    "uniprot": "Q4DI75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI75"
  },
  {
    "uniprot": "Q4CKX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKX7"
  },
  {
    "uniprot": "Q4DIX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIX2"
  },
  {
    "uniprot": "Q4DA43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA43"
  },
  {
    "uniprot": "Q4CRS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRS4"
  },
  {
    "uniprot": "Q4DF17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF17"
  },
  {
    "uniprot": "Q4CUK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUK0"
  },
  {
    "uniprot": "Q4DFA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFA0"
  },
  {
    "uniprot": "Q4DSX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSX9"
  },
  {
    "uniprot": "Q4D266",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D266"
  },
  {
    "uniprot": "Q4DW66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW66"
  },
  {
    "uniprot": "Q4E3R5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3R5"
  },
  {
    "uniprot": "Q4E290",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E290"
  },
  {
    "uniprot": "Q4DQH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQH8"
  },
  {
    "uniprot": "Q4DF20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF20"
  },
  {
    "uniprot": "Q4DSN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSN7"
  },
  {
    "uniprot": "Q4CVY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVY6"
  },
  {
    "uniprot": "Q4E375",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E375"
  },
  {
    "uniprot": "Q4CR01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR01"
  },
  {
    "uniprot": "Q4DGZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGZ6"
  },
  {
    "uniprot": "Q4CVD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVD8"
  },
  {
    "uniprot": "Q4E2A5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2A5"
  },
  {
    "uniprot": "Q4DIP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIP1"
  },
  {
    "uniprot": "Q4DCQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCQ6"
  },
  {
    "uniprot": "Q4D8M4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8M4"
  },
  {
    "uniprot": "Q4CUB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUB3"
  },
  {
    "uniprot": "Q4DCN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCN1"
  },
  {
    "uniprot": "Q4DWV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWV2"
  },
  {
    "uniprot": "Q4E3N2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3N2"
  },
  {
    "uniprot": "Q4E5R2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5R2"
  },
  {
    "uniprot": "Q4CWR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWR7"
  },
  {
    "uniprot": "Q4DQU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQU9"
  },
  {
    "uniprot": "Q4DAT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAT6"
  },
  {
    "uniprot": "Q4D534",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D534"
  },
  {
    "uniprot": "Q4D0Z8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Z8"
  },
  {
    "uniprot": "Q4CUR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUR2"
  },
  {
    "uniprot": "Q4DH84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH84"
  },
  {
    "uniprot": "Q4CKU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKU3"
  },
  {
    "uniprot": "Q4D9Z6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Z6"
  },
  {
    "uniprot": "Q4DLC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLC4"
  },
  {
    "uniprot": "Q4CTD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTD8"
  },
  {
    "uniprot": "Q4D2P9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2P9"
  },
  {
    "uniprot": "Q4DUI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUI8"
  },
  {
    "uniprot": "Q4CT94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT94"
  },
  {
    "uniprot": "Q4E4R1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4R1"
  },
  {
    "uniprot": "Q4CR96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR96"
  },
  {
    "uniprot": "Q4DMT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMT2"
  },
  {
    "uniprot": "Q4D1Y5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Y5"
  },
  {
    "uniprot": "Q4DZU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZU6"
  },
  {
    "uniprot": "Q4DNS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNS0"
  },
  {
    "uniprot": "Q4CNJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNJ4"
  },
  {
    "uniprot": "Q4CXD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXD6"
  },
  {
    "uniprot": "Q4DCQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCQ3"
  },
  {
    "uniprot": "Q4D6Q4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Q4"
  },
  {
    "uniprot": "Q4DYL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYL1"
  },
  {
    "uniprot": "Q4CUY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUY4"
  },
  {
    "uniprot": "Q4D217",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D217"
  },
  {
    "uniprot": "Q4DV77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV77"
  },
  {
    "uniprot": "Q4DE47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE47"
  },
  {
    "uniprot": "Q4DRJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRJ4"
  },
  {
    "uniprot": "Q4D9Y2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Y2"
  },
  {
    "uniprot": "Q4CU19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU19"
  },
  {
    "uniprot": "Q4CMB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMB6"
  },
  {
    "uniprot": "Q4DJC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJC8"
  },
  {
    "uniprot": "Q4DP94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP94"
  },
  {
    "uniprot": "Q4DHK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHK0"
  },
  {
    "uniprot": "Q4CQT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQT4"
  },
  {
    "uniprot": "Q4D0H1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0H1"
  },
  {
    "uniprot": "Q4D9R3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9R3"
  },
  {
    "uniprot": "Q4CN32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN32"
  },
  {
    "uniprot": "Q4DRE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRE8"
  },
  {
    "uniprot": "Q4DZP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZP4"
  },
  {
    "uniprot": "Q4E5V9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5V9"
  },
  {
    "uniprot": "Q4CNB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNB3"
  },
  {
    "uniprot": "Q4DFU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFU9"
  },
  {
    "uniprot": "Q4DK43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK43"
  },
  {
    "uniprot": "Q4D9C3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9C3"
  },
  {
    "uniprot": "Q4D5F8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5F8"
  },
  {
    "uniprot": "Q4D5N8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5N8"
  },
  {
    "uniprot": "Q4DEM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEM9"
  },
  {
    "uniprot": "Q4CZW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZW9"
  },
  {
    "uniprot": "Q4DLR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLR3"
  },
  {
    "uniprot": "Q4DLH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLH5"
  },
  {
    "uniprot": "Q4CWN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWN8"
  },
  {
    "uniprot": "Q4DEI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEI4"
  },
  {
    "uniprot": "Q4D9S5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9S5"
  },
  {
    "uniprot": "Q4CPE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPE1"
  },
  {
    "uniprot": "Q4DYI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYI1"
  },
  {
    "uniprot": "Q4D9T4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9T4"
  },
  {
    "uniprot": "Q4DA84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA84"
  },
  {
    "uniprot": "Q4CZM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZM3"
  },
  {
    "uniprot": "Q4DZT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZT2"
  },
  {
    "uniprot": "Q4DII1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DII1"
  },
  {
    "uniprot": "Q4CZN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZN9"
  },
  {
    "uniprot": "Q4DCM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCM7"
  },
  {
    "uniprot": "Q4D2U0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2U0"
  },
  {
    "uniprot": "Q4E4X0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4X0"
  },
  {
    "uniprot": "Q4E321",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E321"
  },
  {
    "uniprot": "Q4D7V3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7V3"
  },
  {
    "uniprot": "Q4D3Y0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Y0"
  },
  {
    "uniprot": "Q4E509",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E509"
  },
  {
    "uniprot": "Q4D2X4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2X4"
  },
  {
    "uniprot": "Q4CZ65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ65"
  },
  {
    "uniprot": "Q4DBH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBH7"
  },
  {
    "uniprot": "Q4DMC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMC0"
  },
  {
    "uniprot": "Q4CWV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWV0"
  },
  {
    "uniprot": "Q4DL25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL25"
  },
  {
    "uniprot": "Q4E2C8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2C8"
  },
  {
    "uniprot": "Q4CVA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVA5"
  },
  {
    "uniprot": "Q4CXW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXW9"
  },
  {
    "uniprot": "Q4CZK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZK6"
  },
  {
    "uniprot": "Q4DPF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPF2"
  },
  {
    "uniprot": "Q4DLL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLL0"
  },
  {
    "uniprot": "Q4DW10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW10"
  },
  {
    "uniprot": "Q4DW73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW73"
  },
  {
    "uniprot": "Q4CMK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMK3"
  },
  {
    "uniprot": "Q4E3Q3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Q3"
  },
  {
    "uniprot": "Q4D453",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D453"
  },
  {
    "uniprot": "Q4DNN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNN0"
  },
  {
    "uniprot": "Q4D7W6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7W6"
  },
  {
    "uniprot": "Q4E5U7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5U7"
  },
  {
    "uniprot": "Q4DQ22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ22"
  },
  {
    "uniprot": "Q4E3S6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3S6"
  },
  {
    "uniprot": "Q4DZD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZD4"
  },
  {
    "uniprot": "Q4D913",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D913"
  },
  {
    "uniprot": "Q4CUE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUE8"
  },
  {
    "uniprot": "Q4DRV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRV0"
  },
  {
    "uniprot": "Q4CVY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVY7"
  },
  {
    "uniprot": "Q4E4N8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4N8"
  },
  {
    "uniprot": "Q4E041",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E041"
  },
  {
    "uniprot": "Q4DJT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJT2"
  },
  {
    "uniprot": "Q4CT52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT52"
  },
  {
    "uniprot": "Q4DVB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVB4"
  },
  {
    "uniprot": "Q4D6D9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6D9"
  },
  {
    "uniprot": "Q4E3E1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3E1"
  },
  {
    "uniprot": "Q4DGG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGG8"
  },
  {
    "uniprot": "Q4DTC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTC9"
  },
  {
    "uniprot": "Q4D9S1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9S1"
  },
  {
    "uniprot": "Q4DD20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD20"
  },
  {
    "uniprot": "Q4CNK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNK5"
  },
  {
    "uniprot": "Q4DGS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGS0"
  },
  {
    "uniprot": "Q4D7J3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7J3"
  },
  {
    "uniprot": "Q4D539",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D539"
  },
  {
    "uniprot": "Q4D9I3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9I3"
  },
  {
    "uniprot": "Q4E3A4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3A4"
  },
  {
    "uniprot": "Q4E5R7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5R7"
  },
  {
    "uniprot": "Q4E4Y4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Y4"
  },
  {
    "uniprot": "Q4DM92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM92"
  },
  {
    "uniprot": "Q4E2E7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2E7"
  },
  {
    "uniprot": "Q4DKG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKG4"
  },
  {
    "uniprot": "Q4DKT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKT8"
  },
  {
    "uniprot": "Q4DVU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVU0"
  },
  {
    "uniprot": "Q4DQ80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ80"
  },
  {
    "uniprot": "Q4DIK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIK9"
  },
  {
    "uniprot": "Q4CUS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUS6"
  },
  {
    "uniprot": "Q4D5V2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5V2"
  },
  {
    "uniprot": "Q4CQ65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ65"
  },
  {
    "uniprot": "Q4DSF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSF2"
  },
  {
    "uniprot": "Q4D6J2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6J2"
  },
  {
    "uniprot": "Q4D4I0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4I0"
  },
  {
    "uniprot": "Q4DTR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTR4"
  },
  {
    "uniprot": "Q4CU37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU37"
  },
  {
    "uniprot": "Q4D7W2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7W2"
  },
  {
    "uniprot": "Q4DZR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZR2"
  },
  {
    "uniprot": "Q4E4R5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4R5"
  },
  {
    "uniprot": "Q4DEZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEZ9"
  },
  {
    "uniprot": "Q4DBC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBC5"
  },
  {
    "uniprot": "Q4E1Z5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Z5"
  },
  {
    "uniprot": "Q4DX79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX79"
  },
  {
    "uniprot": "Q4DRJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRJ6"
  },
  {
    "uniprot": "Q4E5V4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5V4"
  },
  {
    "uniprot": "Q4DMR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMR0"
  },
  {
    "uniprot": "Q4DKD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKD1"
  },
  {
    "uniprot": "Q4DE42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE42"
  },
  {
    "uniprot": "Q4CRQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRQ5"
  },
  {
    "uniprot": "Q4CP16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP16"
  },
  {
    "uniprot": "Q4DRS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRS4"
  },
  {
    "uniprot": "Q4DJC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJC9"
  },
  {
    "uniprot": "Q4CQ91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ91"
  },
  {
    "uniprot": "Q4CZK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZK8"
  },
  {
    "uniprot": "Q4DSC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSC4"
  },
  {
    "uniprot": "Q4DCK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCK6"
  },
  {
    "uniprot": "Q4D6T7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6T7"
  },
  {
    "uniprot": "Q4DLQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLQ0"
  },
  {
    "uniprot": "Q4CNF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNF8"
  },
  {
    "uniprot": "Q4D1T3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1T3"
  },
  {
    "uniprot": "Q4DUE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUE1"
  },
  {
    "uniprot": "Q4CQN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQN1"
  },
  {
    "uniprot": "Q4CW85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW85"
  },
  {
    "uniprot": "Q4D6U2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6U2"
  },
  {
    "uniprot": "Q4DLI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLI3"
  },
  {
    "uniprot": "Q4DUY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUY8"
  },
  {
    "uniprot": "Q4D6P4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6P4"
  },
  {
    "uniprot": "Q4D150",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D150"
  },
  {
    "uniprot": "Q4CQB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQB9"
  },
  {
    "uniprot": "Q4DH98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH98"
  },
  {
    "uniprot": "Q4DCJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCJ3"
  },
  {
    "uniprot": "Q4E2F0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2F0"
  },
  {
    "uniprot": "Q4DSB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSB2"
  },
  {
    "uniprot": "Q4D191",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D191"
  },
  {
    "uniprot": "Q4DYF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYF4"
  },
  {
    "uniprot": "Q4DAB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAB9"
  },
  {
    "uniprot": "Q4DFH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFH1"
  },
  {
    "uniprot": "Q4CVK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVK3"
  },
  {
    "uniprot": "Q4DKH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKH1"
  },
  {
    "uniprot": "Q4DHD2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHD2"
  },
  {
    "uniprot": "Q4D4Q0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Q0"
  },
  {
    "uniprot": "Q4DWX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWX6"
  },
  {
    "uniprot": "Q4CWL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWL0"
  },
  {
    "uniprot": "Q4DPF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPF1"
  },
  {
    "uniprot": "Q4D5U5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5U5"
  },
  {
    "uniprot": "Q4CRW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRW3"
  },
  {
    "uniprot": "Q4DU78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU78"
  },
  {
    "uniprot": "Q4DCC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCC6"
  },
  {
    "uniprot": "Q4CV99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV99"
  },
  {
    "uniprot": "Q4DC56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC56"
  },
  {
    "uniprot": "Q4CRN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRN3"
  },
  {
    "uniprot": "Q4CSU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSU7"
  },
  {
    "uniprot": "Q4CNI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNI7"
  },
  {
    "uniprot": "Q4DSN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSN5"
  },
  {
    "uniprot": "Q4D8I4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8I4"
  },
  {
    "uniprot": "Q4DRZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRZ4"
  },
  {
    "uniprot": "Q4CP48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP48"
  },
  {
    "uniprot": "Q4DFG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFG0"
  },
  {
    "uniprot": "Q4D9A4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9A4"
  },
  {
    "uniprot": "Q4DDS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDS3"
  },
  {
    "uniprot": "Q4DIF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIF2"
  },
  {
    "uniprot": "Q4D3P8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3P8"
  },
  {
    "uniprot": "Q4CNI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNI1"
  },
  {
    "uniprot": "Q4DAZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAZ7"
  },
  {
    "uniprot": "Q4D5C6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5C6"
  },
  {
    "uniprot": "Q4CYB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYB0"
  },
  {
    "uniprot": "Q4E2K8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2K8"
  },
  {
    "uniprot": "Q4DQ28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ28"
  },
  {
    "uniprot": "Q4DSE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSE7"
  },
  {
    "uniprot": "Q4CSG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSG4"
  },
  {
    "uniprot": "Q4DRB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRB0"
  },
  {
    "uniprot": "Q4D9C4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9C4"
  },
  {
    "uniprot": "Q4DS19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS19"
  },
  {
    "uniprot": "Q4DS35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS35"
  },
  {
    "uniprot": "Q4DP68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP68"
  },
  {
    "uniprot": "Q4E2V9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2V9"
  },
  {
    "uniprot": "Q4DYK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYK0"
  },
  {
    "uniprot": "Q4DJD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJD0"
  },
  {
    "uniprot": "Q4DFU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFU6"
  },
  {
    "uniprot": "Q4D321",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D321"
  },
  {
    "uniprot": "Q4E0Q1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Q1"
  },
  {
    "uniprot": "Q4DIK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIK3"
  },
  {
    "uniprot": "Q4CXM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXM7"
  },
  {
    "uniprot": "Q4DU04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU04"
  },
  {
    "uniprot": "Q4DSP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSP7"
  },
  {
    "uniprot": "Q4DLP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLP8"
  },
  {
    "uniprot": "Q4CXI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXI6"
  },
  {
    "uniprot": "Q4DPR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPR6"
  },
  {
    "uniprot": "Q4CST7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CST7"
  },
  {
    "uniprot": "Q4CR63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR63"
  },
  {
    "uniprot": "Q4CLE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLE4"
  },
  {
    "uniprot": "Q4E4P6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4P6"
  },
  {
    "uniprot": "Q4CQM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQM9"
  },
  {
    "uniprot": "Q4CKK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKK9"
  },
  {
    "uniprot": "Q4CYT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYT0"
  },
  {
    "uniprot": "Q4E2J9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2J9"
  },
  {
    "uniprot": "Q4DPI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPI9"
  },
  {
    "uniprot": "Q4DSC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSC5"
  },
  {
    "uniprot": "Q4DJD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJD9"
  },
  {
    "uniprot": "Q4DHD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHD0"
  },
  {
    "uniprot": "Q4CTH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTH3"
  },
  {
    "uniprot": "Q4DZU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZU5"
  },
  {
    "uniprot": "Q4E2A9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2A9"
  },
  {
    "uniprot": "Q4D6Q5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Q5"
  },
  {
    "uniprot": "Q4CWK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWK5"
  },
  {
    "uniprot": "Q4DCC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCC0"
  },
  {
    "uniprot": "Q4DFW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFW4"
  },
  {
    "uniprot": "Q4DKZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKZ5"
  },
  {
    "uniprot": "Q4CRL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRL3"
  },
  {
    "uniprot": "Q4DG84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG84"
  },
  {
    "uniprot": "Q4D3S5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3S5"
  },
  {
    "uniprot": "Q4D2B1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2B1"
  },
  {
    "uniprot": "Q4DC28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC28"
  },
  {
    "uniprot": "Q4DX30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX30"
  },
  {
    "uniprot": "Q4CRS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRS5"
  },
  {
    "uniprot": "Q4D1W6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1W6"
  },
  {
    "uniprot": "Q4CZV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZV7"
  },
  {
    "uniprot": "Q4DMX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMX6"
  },
  {
    "uniprot": "Q4DDI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDI0"
  },
  {
    "uniprot": "Q4CNM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNM8"
  },
  {
    "uniprot": "Q4DN12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN12"
  },
  {
    "uniprot": "Q4CUW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUW4"
  },
  {
    "uniprot": "Q4DR11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR11"
  },
  {
    "uniprot": "Q4DT86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT86"
  },
  {
    "uniprot": "Q4D4B0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4B0"
  },
  {
    "uniprot": "Q4DM73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM73"
  },
  {
    "uniprot": "Q4CXY3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXY3"
  },
  {
    "uniprot": "Q4CQW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQW3"
  },
  {
    "uniprot": "Q4DIN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIN4"
  },
  {
    "uniprot": "Q4CXA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXA7"
  },
  {
    "uniprot": "Q4D924",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D924"
  },
  {
    "uniprot": "Q4DDZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDZ6"
  },
  {
    "uniprot": "Q4D4R3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4R3"
  },
  {
    "uniprot": "Q4E065",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E065"
  },
  {
    "uniprot": "Q4DQT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQT6"
  },
  {
    "uniprot": "Q4CZQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZQ6"
  },
  {
    "uniprot": "Q4DU80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU80"
  },
  {
    "uniprot": "Q4DQE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQE4"
  },
  {
    "uniprot": "Q4DTH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTH6"
  },
  {
    "uniprot": "Q4DE69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE69"
  },
  {
    "uniprot": "Q4DR66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR66"
  },
  {
    "uniprot": "Q4DXP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXP6"
  },
  {
    "uniprot": "Q4DF34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF34"
  },
  {
    "uniprot": "Q4CLX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLX6"
  },
  {
    "uniprot": "Q4DVR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVR2"
  },
  {
    "uniprot": "Q4CYX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYX2"
  },
  {
    "uniprot": "Q4DHR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHR7"
  },
  {
    "uniprot": "Q4E1A8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1A8"
  },
  {
    "uniprot": "Q4DRA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRA3"
  },
  {
    "uniprot": "Q4CQS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQS1"
  },
  {
    "uniprot": "Q4DTQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTQ7"
  },
  {
    "uniprot": "Q4D3P1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3P1"
  },
  {
    "uniprot": "Q4D857",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D857"
  },
  {
    "uniprot": "Q4E1V4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1V4"
  },
  {
    "uniprot": "Q4CZP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZP4"
  },
  {
    "uniprot": "Q4DI35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI35"
  },
  {
    "uniprot": "Q4DHJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHJ0"
  },
  {
    "uniprot": "Q4D705",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D705"
  },
  {
    "uniprot": "Q4DLR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLR9"
  },
  {
    "uniprot": "Q4DRV6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRV6"
  },
  {
    "uniprot": "Q4E0G3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0G3"
  },
  {
    "uniprot": "Q4DEZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEZ5"
  },
  {
    "uniprot": "Q4DPN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPN0"
  },
  {
    "uniprot": "Q4E040",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E040"
  },
  {
    "uniprot": "Q4D706",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D706"
  },
  {
    "uniprot": "Q4DV65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV65"
  },
  {
    "uniprot": "Q4DZB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZB7"
  },
  {
    "uniprot": "Q4E011",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E011"
  },
  {
    "uniprot": "Q4DYM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYM2"
  },
  {
    "uniprot": "Q4D5R3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5R3"
  },
  {
    "uniprot": "Q4DM41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM41"
  },
  {
    "uniprot": "Q4DSJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSJ8"
  },
  {
    "uniprot": "Q4E100",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E100"
  },
  {
    "uniprot": "Q4DIW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIW9"
  },
  {
    "uniprot": "Q4D485",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D485"
  },
  {
    "uniprot": "Q4CLZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLZ7"
  },
  {
    "uniprot": "Q4CN56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN56"
  },
  {
    "uniprot": "Q4CRK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRK5"
  },
  {
    "uniprot": "Q4CNH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNH6"
  },
  {
    "uniprot": "Q4DQB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQB8"
  },
  {
    "uniprot": "Q4DUM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUM3"
  },
  {
    "uniprot": "Q4DKG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKG0"
  },
  {
    "uniprot": "Q4DMQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMQ8"
  },
  {
    "uniprot": "Q4DQQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQQ3"
  },
  {
    "uniprot": "Q4D2U8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2U8"
  },
  {
    "uniprot": "Q4DDL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDL0"
  },
  {
    "uniprot": "Q4DDA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDA6"
  },
  {
    "uniprot": "Q4CVS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVS9"
  },
  {
    "uniprot": "Q4DQ19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ19"
  },
  {
    "uniprot": "Q4DTZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTZ3"
  },
  {
    "uniprot": "Q4CSY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSY0"
  },
  {
    "uniprot": "Q4D185",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D185"
  },
  {
    "uniprot": "Q4D669",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D669"
  },
  {
    "uniprot": "Q4DAY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAY1"
  },
  {
    "uniprot": "Q4DZI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZI8"
  },
  {
    "uniprot": "Q4E276",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E276"
  },
  {
    "uniprot": "Q4DGX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGX5"
  },
  {
    "uniprot": "Q4DL29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL29"
  },
  {
    "uniprot": "Q4DDW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDW9"
  },
  {
    "uniprot": "Q4DJ97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ97"
  },
  {
    "uniprot": "Q4DMB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMB1"
  },
  {
    "uniprot": "Q4D300",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D300"
  },
  {
    "uniprot": "Q4DWV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWV3"
  },
  {
    "uniprot": "Q4CT74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT74"
  },
  {
    "uniprot": "Q4DXY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXY2"
  },
  {
    "uniprot": "Q4D3Z6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Z6"
  },
  {
    "uniprot": "Q4DVT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVT4"
  },
  {
    "uniprot": "Q4CT09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT09"
  },
  {
    "uniprot": "Q4D7K4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7K4"
  },
  {
    "uniprot": "Q4D0X5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0X5"
  },
  {
    "uniprot": "Q4DNK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNK5"
  },
  {
    "uniprot": "Q4DTC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTC3"
  },
  {
    "uniprot": "Q4D0F8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0F8"
  },
  {
    "uniprot": "Q4DCS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCS8"
  },
  {
    "uniprot": "Q4DDM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDM8"
  },
  {
    "uniprot": "Q4D6R2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6R2"
  },
  {
    "uniprot": "Q4E427",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E427"
  },
  {
    "uniprot": "Q4DA08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA08"
  },
  {
    "uniprot": "Q4DYE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYE9"
  },
  {
    "uniprot": "Q4DLB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLB6"
  },
  {
    "uniprot": "Q4CSA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSA1"
  },
  {
    "uniprot": "Q4E4K4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4K4"
  },
  {
    "uniprot": "Q4CNP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNP9"
  },
  {
    "uniprot": "Q4DAB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAB0"
  },
  {
    "uniprot": "Q4DY14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY14"
  },
  {
    "uniprot": "Q4CQ70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ70"
  },
  {
    "uniprot": "Q4DIM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIM9"
  },
  {
    "uniprot": "Q4DIZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIZ2"
  },
  {
    "uniprot": "Q4D1Q7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Q7"
  },
  {
    "uniprot": "Q4DYD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYD5"
  },
  {
    "uniprot": "Q4E1K4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1K4"
  },
  {
    "uniprot": "Q4CW86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW86"
  },
  {
    "uniprot": "Q4DK23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK23"
  },
  {
    "uniprot": "Q4D352",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D352"
  },
  {
    "uniprot": "Q4CN31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN31"
  },
  {
    "uniprot": "Q4DPU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPU6"
  },
  {
    "uniprot": "Q4DWU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWU3"
  },
  {
    "uniprot": "Q4D613",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D613"
  },
  {
    "uniprot": "Q4DQK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQK4"
  },
  {
    "uniprot": "Q4CX39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX39"
  },
  {
    "uniprot": "Q4DN39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN39"
  },
  {
    "uniprot": "Q4DWE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWE9"
  },
  {
    "uniprot": "Q4DW08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW08"
  },
  {
    "uniprot": "Q4CLQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLQ2"
  },
  {
    "uniprot": "Q4DN18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN18"
  },
  {
    "uniprot": "Q4E2T5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2T5"
  },
  {
    "uniprot": "Q4DF14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF14"
  },
  {
    "uniprot": "Q4DNT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNT6"
  },
  {
    "uniprot": "Q4CM32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM32"
  },
  {
    "uniprot": "Q4CSD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSD3"
  },
  {
    "uniprot": "Q4DLT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLT8"
  },
  {
    "uniprot": "Q4E240",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E240"
  },
  {
    "uniprot": "Q4CW66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW66"
  },
  {
    "uniprot": "Q4D7X4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7X4"
  },
  {
    "uniprot": "Q4D3R0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3R0"
  },
  {
    "uniprot": "Q4DTJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTJ2"
  },
  {
    "uniprot": "Q4CUZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUZ9"
  },
  {
    "uniprot": "Q4D0I7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0I7"
  },
  {
    "uniprot": "Q4CNA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNA0"
  },
  {
    "uniprot": "Q4E1P0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1P0"
  },
  {
    "uniprot": "Q4DGD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGD3"
  },
  {
    "uniprot": "Q4CNQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNQ9"
  },
  {
    "uniprot": "Q4E208",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E208"
  },
  {
    "uniprot": "Q4E5T9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5T9"
  },
  {
    "uniprot": "Q4DZ19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ19"
  },
  {
    "uniprot": "Q4DJP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJP1"
  },
  {
    "uniprot": "Q4D8F5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8F5"
  },
  {
    "uniprot": "Q4DX66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX66"
  },
  {
    "uniprot": "Q4CXZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXZ0"
  },
  {
    "uniprot": "Q4D1I7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1I7"
  },
  {
    "uniprot": "Q4DF39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF39"
  },
  {
    "uniprot": "Q4DUV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUV9"
  },
  {
    "uniprot": "Q4D1Y6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Y6"
  },
  {
    "uniprot": "Q4D415",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D415"
  },
  {
    "uniprot": "Q4E0W1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0W1"
  },
  {
    "uniprot": "Q4DTH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTH8"
  },
  {
    "uniprot": "Q4CP12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP12"
  },
  {
    "uniprot": "Q4E5X3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5X3"
  },
  {
    "uniprot": "Q4DI39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI39"
  },
  {
    "uniprot": "Q4D5J6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5J6"
  },
  {
    "uniprot": "Q4E3B6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3B6"
  },
  {
    "uniprot": "Q4DU21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU21"
  },
  {
    "uniprot": "Q4D8E7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8E7"
  },
  {
    "uniprot": "Q4DAX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAX2"
  },
  {
    "uniprot": "Q4DAS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAS0"
  },
  {
    "uniprot": "Q4CUH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUH7"
  },
  {
    "uniprot": "Q4D3X7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3X7"
  },
  {
    "uniprot": "Q4DI61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI61"
  },
  {
    "uniprot": "Q4DJ74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ74"
  },
  {
    "uniprot": "Q4DN63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN63"
  },
  {
    "uniprot": "Q4DKS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKS3"
  },
  {
    "uniprot": "Q4CXP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXP0"
  },
  {
    "uniprot": "Q4D9A5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9A5"
  },
  {
    "uniprot": "Q4CSF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSF3"
  },
  {
    "uniprot": "Q4E2E2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2E2"
  },
  {
    "uniprot": "Q4CT70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT70"
  },
  {
    "uniprot": "Q4DK13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK13"
  },
  {
    "uniprot": "Q4DF05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF05"
  },
  {
    "uniprot": "Q4E4Y7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Y7"
  },
  {
    "uniprot": "Q4CXD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXD1"
  },
  {
    "uniprot": "Q4E2D3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2D3"
  },
  {
    "uniprot": "Q4CXE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXE9"
  },
  {
    "uniprot": "Q4CNF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNF9"
  },
  {
    "uniprot": "Q4D6Y2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6Y2"
  },
  {
    "uniprot": "Q4DSS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSS1"
  },
  {
    "uniprot": "Q4DQ37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ37"
  },
  {
    "uniprot": "Q4D776",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D776"
  },
  {
    "uniprot": "Q4DX34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX34"
  },
  {
    "uniprot": "Q4CMB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMB1"
  },
  {
    "uniprot": "Q4E1R7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1R7"
  },
  {
    "uniprot": "Q4D7E5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7E5"
  },
  {
    "uniprot": "Q4DQJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQJ8"
  },
  {
    "uniprot": "Q4CLD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLD8"
  },
  {
    "uniprot": "Q4D1H6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1H6"
  },
  {
    "uniprot": "Q4CW32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW32"
  },
  {
    "uniprot": "Q4CXI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXI7"
  },
  {
    "uniprot": "Q4CS94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS94"
  },
  {
    "uniprot": "Q4DYD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYD8"
  },
  {
    "uniprot": "Q4D4E5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4E5"
  },
  {
    "uniprot": "Q4DRM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRM9"
  },
  {
    "uniprot": "Q4CMZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMZ8"
  },
  {
    "uniprot": "Q4DL10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL10"
  },
  {
    "uniprot": "Q4E3B1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3B1"
  },
  {
    "uniprot": "Q4DXR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXR6"
  },
  {
    "uniprot": "Q4CPT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPT7"
  },
  {
    "uniprot": "Q4D9Y9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Y9"
  },
  {
    "uniprot": "Q4D2Z5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Z5"
  },
  {
    "uniprot": "Q4CKH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKH2"
  },
  {
    "uniprot": "Q4DCC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCC2"
  },
  {
    "uniprot": "Q4DFZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFZ6"
  },
  {
    "uniprot": "Q4DJL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJL4"
  },
  {
    "uniprot": "Q4CTR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTR8"
  },
  {
    "uniprot": "Q4DAM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAM1"
  },
  {
    "uniprot": "Q4DUA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUA2"
  },
  {
    "uniprot": "Q4CWK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWK2"
  },
  {
    "uniprot": "Q4CTC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTC5"
  },
  {
    "uniprot": "Q4CWB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWB7"
  },
  {
    "uniprot": "Q4CYJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYJ5"
  },
  {
    "uniprot": "Q4DEN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEN1"
  },
  {
    "uniprot": "Q4D2A7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2A7"
  },
  {
    "uniprot": "Q4DDU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDU1"
  },
  {
    "uniprot": "Q4D2T2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2T2"
  },
  {
    "uniprot": "Q4DDV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDV1"
  },
  {
    "uniprot": "Q4CWX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWX6"
  },
  {
    "uniprot": "Q4E495",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E495"
  },
  {
    "uniprot": "Q4DL82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL82"
  },
  {
    "uniprot": "Q4CYN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYN3"
  },
  {
    "uniprot": "Q4CP76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP76"
  },
  {
    "uniprot": "Q4D5K5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5K5"
  },
  {
    "uniprot": "Q4DP21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP21"
  },
  {
    "uniprot": "Q4CQI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQI9"
  },
  {
    "uniprot": "Q4CW02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW02"
  },
  {
    "uniprot": "Q4DPF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPF5"
  },
  {
    "uniprot": "Q4DE62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE62"
  },
  {
    "uniprot": "Q4CSK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSK0"
  },
  {
    "uniprot": "Q4D034",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D034"
  },
  {
    "uniprot": "Q4DE14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE14"
  },
  {
    "uniprot": "Q4DP63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP63"
  },
  {
    "uniprot": "Q4D5E5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5E5"
  },
  {
    "uniprot": "Q4DLQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLQ8"
  },
  {
    "uniprot": "Q4DJ01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ01"
  },
  {
    "uniprot": "Q4CQT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQT6"
  },
  {
    "uniprot": "Q4DLW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLW5"
  },
  {
    "uniprot": "Q4DGT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGT9"
  },
  {
    "uniprot": "Q4DI57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI57"
  },
  {
    "uniprot": "Q4D062",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D062"
  },
  {
    "uniprot": "Q4CKI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKI8"
  },
  {
    "uniprot": "Q4D1I6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1I6"
  },
  {
    "uniprot": "Q4E1R5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1R5"
  },
  {
    "uniprot": "Q4DAH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAH4"
  },
  {
    "uniprot": "Q4DP14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP14"
  },
  {
    "uniprot": "Q4DS44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS44"
  },
  {
    "uniprot": "Q4CTR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTR1"
  },
  {
    "uniprot": "Q4DV48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV48"
  },
  {
    "uniprot": "Q4CRU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRU4"
  },
  {
    "uniprot": "Q4CTX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTX1"
  },
  {
    "uniprot": "Q4D0M0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0M0"
  },
  {
    "uniprot": "Q4DMI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMI4"
  },
  {
    "uniprot": "Q4DFT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFT8"
  },
  {
    "uniprot": "Q4D175",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D175"
  },
  {
    "uniprot": "Q4E2P4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2P4"
  },
  {
    "uniprot": "Q4D974",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D974"
  },
  {
    "uniprot": "Q4DJD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJD8"
  },
  {
    "uniprot": "Q4DLY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLY6"
  },
  {
    "uniprot": "Q4CR02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR02"
  },
  {
    "uniprot": "Q4DZE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZE4"
  },
  {
    "uniprot": "Q4E0S1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0S1"
  },
  {
    "uniprot": "Q4CX53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX53"
  },
  {
    "uniprot": "Q4DTU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTU8"
  },
  {
    "uniprot": "Q4CK98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CK98"
  },
  {
    "uniprot": "Q4CKW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKW0"
  },
  {
    "uniprot": "Q4D693",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D693"
  },
  {
    "uniprot": "Q4CLV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLV8"
  },
  {
    "uniprot": "Q4D8W8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8W8"
  },
  {
    "uniprot": "Q4DTC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTC2"
  },
  {
    "uniprot": "Q4D9D0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9D0"
  },
  {
    "uniprot": "Q4CTG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTG1"
  },
  {
    "uniprot": "Q4D982",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D982"
  },
  {
    "uniprot": "Q4D353",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D353"
  },
  {
    "uniprot": "Q4CZ07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ07"
  },
  {
    "uniprot": "Q4CXV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXV2"
  },
  {
    "uniprot": "Q4DLT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLT4"
  },
  {
    "uniprot": "Q4CLG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLG7"
  },
  {
    "uniprot": "Q4CVG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVG9"
  },
  {
    "uniprot": "Q4E3V1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3V1"
  },
  {
    "uniprot": "Q4DL93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL93"
  },
  {
    "uniprot": "Q4CPW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPW7"
  },
  {
    "uniprot": "Q4CRV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRV5"
  },
  {
    "uniprot": "Q4CTB9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTB9"
  },
  {
    "uniprot": "Q4DHD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHD6"
  },
  {
    "uniprot": "Q4D2I9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2I9"
  },
  {
    "uniprot": "Q4DTD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTD0"
  },
  {
    "uniprot": "Q4DRH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRH0"
  },
  {
    "uniprot": "Q4DKZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKZ9"
  },
  {
    "uniprot": "Q4E057",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E057"
  },
  {
    "uniprot": "Q4D6U5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6U5"
  },
  {
    "uniprot": "Q4D7A4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7A4"
  },
  {
    "uniprot": "Q4DIJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIJ9"
  },
  {
    "uniprot": "Q4E634",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E634"
  },
  {
    "uniprot": "Q4DQI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQI4"
  },
  {
    "uniprot": "Q4E4S2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4S2"
  },
  {
    "uniprot": "Q4DDJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDJ5"
  },
  {
    "uniprot": "Q4DNB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNB4"
  },
  {
    "uniprot": "Q4CYW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYW6"
  },
  {
    "uniprot": "Q4CVA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVA3"
  },
  {
    "uniprot": "Q4CLJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLJ2"
  },
  {
    "uniprot": "Q4D9P9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9P9"
  },
  {
    "uniprot": "Q4DFL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFL9"
  },
  {
    "uniprot": "Q4D8H5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8H5"
  },
  {
    "uniprot": "Q4D5D3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5D3"
  },
  {
    "uniprot": "Q4CRQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRQ9"
  },
  {
    "uniprot": "Q4D1H8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1H8"
  },
  {
    "uniprot": "Q4DKB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKB6"
  },
  {
    "uniprot": "Q4CTS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTS5"
  },
  {
    "uniprot": "Q4CST5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CST5"
  },
  {
    "uniprot": "Q4D3W0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3W0"
  },
  {
    "uniprot": "Q4CVS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVS5"
  },
  {
    "uniprot": "Q4E1D4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1D4"
  },
  {
    "uniprot": "Q4DL53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL53"
  },
  {
    "uniprot": "Q4DW72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW72"
  },
  {
    "uniprot": "Q4D3X3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3X3"
  },
  {
    "uniprot": "Q4CKQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKQ5"
  },
  {
    "uniprot": "Q4DD46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD46"
  },
  {
    "uniprot": "Q4DLN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLN7"
  },
  {
    "uniprot": "Q4D1V0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1V0"
  },
  {
    "uniprot": "Q4DMC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMC9"
  },
  {
    "uniprot": "Q4D7Q1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Q1"
  },
  {
    "uniprot": "Q4DXL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXL8"
  },
  {
    "uniprot": "Q4D552",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D552"
  },
  {
    "uniprot": "Q4CKE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKE4"
  },
  {
    "uniprot": "Q4CWF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWF7"
  },
  {
    "uniprot": "Q4D2C2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2C2"
  },
  {
    "uniprot": "Q4CWU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWU4"
  },
  {
    "uniprot": "Q4E2U7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2U7"
  },
  {
    "uniprot": "Q4D165",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D165"
  },
  {
    "uniprot": "Q4CPJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPJ2"
  },
  {
    "uniprot": "Q4D181",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D181"
  },
  {
    "uniprot": "Q4CSU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSU3"
  },
  {
    "uniprot": "Q4DI23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI23"
  },
  {
    "uniprot": "Q4DJ13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ13"
  },
  {
    "uniprot": "Q4CXB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXB5"
  },
  {
    "uniprot": "Q4DS17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS17"
  },
  {
    "uniprot": "Q4CNN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNN1"
  },
  {
    "uniprot": "Q4D6L1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6L1"
  },
  {
    "uniprot": "Q4D731",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D731"
  },
  {
    "uniprot": "Q4DGX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGX9"
  },
  {
    "uniprot": "Q4DCF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCF3"
  },
  {
    "uniprot": "Q4DSW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSW9"
  },
  {
    "uniprot": "Q4D6W2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6W2"
  },
  {
    "uniprot": "Q4CLM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLM3"
  },
  {
    "uniprot": "Q4E163",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E163"
  },
  {
    "uniprot": "Q4DGF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGF1"
  },
  {
    "uniprot": "Q4DI81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI81"
  },
  {
    "uniprot": "Q4DMD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMD1"
  },
  {
    "uniprot": "Q4DBT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBT6"
  },
  {
    "uniprot": "Q4CU63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU63"
  },
  {
    "uniprot": "Q4DWJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWJ8"
  },
  {
    "uniprot": "Q4E0R6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0R6"
  },
  {
    "uniprot": "Q4DPT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPT9"
  },
  {
    "uniprot": "Q4DUK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUK4"
  },
  {
    "uniprot": "Q4D159",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D159"
  },
  {
    "uniprot": "Q4CKX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKX6"
  },
  {
    "uniprot": "Q4D3W4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3W4"
  },
  {
    "uniprot": "Q4DK04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK04"
  },
  {
    "uniprot": "Q4E0E0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0E0"
  },
  {
    "uniprot": "Q4D0T8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0T8"
  },
  {
    "uniprot": "Q4D3C0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3C0"
  },
  {
    "uniprot": "Q4DFK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFK4"
  },
  {
    "uniprot": "Q4DNQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNQ2"
  },
  {
    "uniprot": "Q4E353",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E353"
  },
  {
    "uniprot": "Q4D6E6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6E6"
  },
  {
    "uniprot": "Q4DX73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX73"
  },
  {
    "uniprot": "Q4E4Q6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Q6"
  },
  {
    "uniprot": "Q4DNW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNW7"
  },
  {
    "uniprot": "Q4CN97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN97"
  },
  {
    "uniprot": "Q4E3F6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3F6"
  },
  {
    "uniprot": "Q4DNQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNQ0"
  },
  {
    "uniprot": "Q4E3J2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3J2"
  },
  {
    "uniprot": "Q4DKG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKG5"
  },
  {
    "uniprot": "Q4D018",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D018"
  },
  {
    "uniprot": "Q4D144",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D144"
  },
  {
    "uniprot": "Q4D770",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D770"
  },
  {
    "uniprot": "Q4CM92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM92"
  },
  {
    "uniprot": "Q4DXN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXN3"
  },
  {
    "uniprot": "Q4DGL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGL5"
  },
  {
    "uniprot": "Q4CPG7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPG7"
  },
  {
    "uniprot": "Q4DZ26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ26"
  },
  {
    "uniprot": "Q4DPZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPZ3"
  },
  {
    "uniprot": "Q4CT29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT29"
  },
  {
    "uniprot": "Q4DEA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEA1"
  },
  {
    "uniprot": "Q4DR49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR49"
  },
  {
    "uniprot": "Q4D8E3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8E3"
  },
  {
    "uniprot": "Q4DJT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJT1"
  },
  {
    "uniprot": "Q4CP19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP19"
  },
  {
    "uniprot": "Q4E0Z3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Z3"
  },
  {
    "uniprot": "Q4D887",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D887"
  },
  {
    "uniprot": "Q4CN15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN15"
  },
  {
    "uniprot": "Q4D1T4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1T4"
  },
  {
    "uniprot": "Q4DPX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPX3"
  },
  {
    "uniprot": "Q4D2Z2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Z2"
  },
  {
    "uniprot": "Q4DH68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH68"
  },
  {
    "uniprot": "Q4DTC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTC0"
  },
  {
    "uniprot": "Q4DYJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYJ0"
  },
  {
    "uniprot": "Q4E506",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E506"
  },
  {
    "uniprot": "Q4DD58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD58"
  },
  {
    "uniprot": "Q4CY94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY94"
  },
  {
    "uniprot": "Q4CYZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYZ8"
  },
  {
    "uniprot": "Q4D2E8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2E8"
  },
  {
    "uniprot": "Q4DKU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKU4"
  },
  {
    "uniprot": "Q4D2V6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2V6"
  },
  {
    "uniprot": "Q4E1G0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1G0"
  },
  {
    "uniprot": "Q4CKM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKM3"
  },
  {
    "uniprot": "Q4DYT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYT1"
  },
  {
    "uniprot": "Q4D9V2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9V2"
  },
  {
    "uniprot": "Q4CTE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTE7"
  },
  {
    "uniprot": "Q4E4C2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4C2"
  },
  {
    "uniprot": "Q4D825",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D825"
  },
  {
    "uniprot": "Q4DZN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZN4"
  },
  {
    "uniprot": "Q4E1W7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1W7"
  },
  {
    "uniprot": "Q4D0Z4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Z4"
  },
  {
    "uniprot": "Q4DCB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCB5"
  },
  {
    "uniprot": "Q4CLW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLW1"
  },
  {
    "uniprot": "Q4DRM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRM7"
  },
  {
    "uniprot": "Q4DV86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV86"
  },
  {
    "uniprot": "Q4DRB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRB8"
  },
  {
    "uniprot": "Q4DBI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBI4"
  },
  {
    "uniprot": "Q4D3Q2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Q2"
  },
  {
    "uniprot": "Q4E3F3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3F3"
  },
  {
    "uniprot": "Q4DRY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRY0"
  },
  {
    "uniprot": "Q4DBD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBD8"
  },
  {
    "uniprot": "Q4DFQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFQ7"
  },
  {
    "uniprot": "Q4DST1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DST1"
  },
  {
    "uniprot": "Q4DC73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC73"
  },
  {
    "uniprot": "Q4DWN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWN0"
  },
  {
    "uniprot": "Q4DW42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW42"
  },
  {
    "uniprot": "Q4CRX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRX5"
  },
  {
    "uniprot": "Q4DN85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN85"
  },
  {
    "uniprot": "Q4CL88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL88"
  },
  {
    "uniprot": "Q4D655",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D655"
  },
  {
    "uniprot": "Q4D7B6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7B6"
  },
  {
    "uniprot": "Q4DBS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBS8"
  },
  {
    "uniprot": "Q4D8I3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8I3"
  },
  {
    "uniprot": "Q4E1P8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1P8"
  },
  {
    "uniprot": "Q4DVD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVD0"
  },
  {
    "uniprot": "Q4DWL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWL6"
  },
  {
    "uniprot": "Q4DL79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL79"
  },
  {
    "uniprot": "Q4DJI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJI7"
  },
  {
    "uniprot": "Q4D218",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D218"
  },
  {
    "uniprot": "Q4D110",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D110"
  },
  {
    "uniprot": "Q4DB10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB10"
  },
  {
    "uniprot": "Q4D7K3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7K3"
  },
  {
    "uniprot": "Q4CRU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRU5"
  },
  {
    "uniprot": "Q4D3V8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3V8"
  },
  {
    "uniprot": "Q4CX42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX42"
  },
  {
    "uniprot": "Q4DI28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI28"
  },
  {
    "uniprot": "Q4DXA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXA6"
  },
  {
    "uniprot": "Q4DUX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUX2"
  },
  {
    "uniprot": "Q4D697",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D697"
  },
  {
    "uniprot": "Q4CPL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPL1"
  },
  {
    "uniprot": "Q4DC81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC81"
  },
  {
    "uniprot": "Q4DEE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEE1"
  },
  {
    "uniprot": "Q4DEI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEI6"
  },
  {
    "uniprot": "Q4DQQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQQ9"
  },
  {
    "uniprot": "Q4DLD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLD0"
  },
  {
    "uniprot": "Q4CL24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL24"
  },
  {
    "uniprot": "Q4CMK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMK0"
  },
  {
    "uniprot": "Q4E1P4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1P4"
  },
  {
    "uniprot": "Q4DNF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNF4"
  },
  {
    "uniprot": "Q4DD06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD06"
  },
  {
    "uniprot": "Q4CZC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZC5"
  },
  {
    "uniprot": "Q4CL51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL51"
  },
  {
    "uniprot": "Q4D7C7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7C7"
  },
  {
    "uniprot": "Q4DHH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHH1"
  },
  {
    "uniprot": "Q4E3Y8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Y8"
  },
  {
    "uniprot": "Q4DT07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT07"
  },
  {
    "uniprot": "Q4CQG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQG9"
  },
  {
    "uniprot": "Q4DSQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSQ6"
  },
  {
    "uniprot": "Q4CTV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTV5"
  },
  {
    "uniprot": "Q4CLE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLE0"
  },
  {
    "uniprot": "Q4D242",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D242"
  },
  {
    "uniprot": "Q4D894",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D894"
  },
  {
    "uniprot": "Q4E164",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E164"
  },
  {
    "uniprot": "Q4DEC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEC7"
  },
  {
    "uniprot": "Q4CSX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSX5"
  },
  {
    "uniprot": "Q4E339",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E339"
  },
  {
    "uniprot": "Q4DHV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHV9"
  },
  {
    "uniprot": "Q4D951",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D951"
  },
  {
    "uniprot": "Q4CTL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTL1"
  },
  {
    "uniprot": "Q4DVV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVV9"
  },
  {
    "uniprot": "Q4E178",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E178"
  },
  {
    "uniprot": "Q4CZA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZA0"
  },
  {
    "uniprot": "Q4CZV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZV3"
  },
  {
    "uniprot": "Q4DLU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLU2"
  },
  {
    "uniprot": "Q4DDC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDC1"
  },
  {
    "uniprot": "Q4DYJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYJ9"
  },
  {
    "uniprot": "Q4D691",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D691"
  },
  {
    "uniprot": "Q4DT39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT39"
  },
  {
    "uniprot": "Q4DKJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKJ2"
  },
  {
    "uniprot": "Q4CY30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY30"
  },
  {
    "uniprot": "Q4CWY0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWY0"
  },
  {
    "uniprot": "Q4DHQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHQ7"
  },
  {
    "uniprot": "Q4DUR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUR4"
  },
  {
    "uniprot": "Q4DNK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNK2"
  },
  {
    "uniprot": "Q4CWW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWW2"
  },
  {
    "uniprot": "Q4E242",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E242"
  },
  {
    "uniprot": "Q4DNZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNZ5"
  },
  {
    "uniprot": "Q4CNE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNE3"
  },
  {
    "uniprot": "Q4DAA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAA8"
  },
  {
    "uniprot": "Q4E1K2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1K2"
  },
  {
    "uniprot": "Q4CUV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUV9"
  },
  {
    "uniprot": "Q4D0D4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0D4"
  },
  {
    "uniprot": "Q4DFA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFA1"
  },
  {
    "uniprot": "Q4DRU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRU7"
  },
  {
    "uniprot": "Q4DLS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLS2"
  },
  {
    "uniprot": "Q4D758",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D758"
  },
  {
    "uniprot": "Q4E356",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E356"
  },
  {
    "uniprot": "Q4DBJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBJ4"
  },
  {
    "uniprot": "Q4DN17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN17"
  },
  {
    "uniprot": "Q4D4Y1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Y1"
  },
  {
    "uniprot": "Q4D9S2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9S2"
  },
  {
    "uniprot": "Q4DVU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVU2"
  },
  {
    "uniprot": "Q4DCG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCG9"
  },
  {
    "uniprot": "Q4CKI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKI5"
  },
  {
    "uniprot": "Q4CT59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT59"
  },
  {
    "uniprot": "Q4D972",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D972"
  },
  {
    "uniprot": "Q4DQJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQJ1"
  },
  {
    "uniprot": "Q4D8E0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8E0"
  },
  {
    "uniprot": "Q4DJB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJB4"
  },
  {
    "uniprot": "Q4D1F7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1F7"
  },
  {
    "uniprot": "Q4CT38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT38"
  },
  {
    "uniprot": "Q4E352",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E352"
  },
  {
    "uniprot": "Q4D1P4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1P4"
  },
  {
    "uniprot": "Q4DS45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS45"
  },
  {
    "uniprot": "Q4D349",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D349"
  },
  {
    "uniprot": "Q4DCN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCN0"
  },
  {
    "uniprot": "Q4DH34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH34"
  },
  {
    "uniprot": "Q4DPA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPA3"
  },
  {
    "uniprot": "Q4DJ93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ93"
  },
  {
    "uniprot": "Q4CRH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRH6"
  },
  {
    "uniprot": "Q4CQP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQP9"
  },
  {
    "uniprot": "Q4CZZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZZ2"
  },
  {
    "uniprot": "Q4CTS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTS7"
  },
  {
    "uniprot": "Q4CRP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRP3"
  },
  {
    "uniprot": "Q4D699",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D699"
  },
  {
    "uniprot": "Q4DYG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYG3"
  },
  {
    "uniprot": "Q4CM04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM04"
  },
  {
    "uniprot": "Q4D1S5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1S5"
  },
  {
    "uniprot": "Q4DNH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNH9"
  },
  {
    "uniprot": "Q4CKW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKW2"
  },
  {
    "uniprot": "Q4CZE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZE7"
  },
  {
    "uniprot": "Q4D9D3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9D3"
  },
  {
    "uniprot": "Q4D4U0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4U0"
  },
  {
    "uniprot": "Q4D0F0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0F0"
  },
  {
    "uniprot": "Q4CMQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMQ1"
  },
  {
    "uniprot": "Q4CN55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN55"
  },
  {
    "uniprot": "Q4DXB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXB4"
  },
  {
    "uniprot": "Q4DRN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRN2"
  },
  {
    "uniprot": "Q4DZC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZC4"
  },
  {
    "uniprot": "Q4DM67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM67"
  },
  {
    "uniprot": "Q4CQA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQA3"
  },
  {
    "uniprot": "Q4DAD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAD8"
  },
  {
    "uniprot": "Q4DWI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWI7"
  },
  {
    "uniprot": "Q4DKU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKU1"
  },
  {
    "uniprot": "Q4CPX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPX8"
  },
  {
    "uniprot": "Q4DLA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLA3"
  },
  {
    "uniprot": "Q4DMY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMY9"
  },
  {
    "uniprot": "Q4DXR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXR4"
  },
  {
    "uniprot": "Q4DRP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRP2"
  },
  {
    "uniprot": "Q4D6S2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6S2"
  },
  {
    "uniprot": "Q4DD98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD98"
  },
  {
    "uniprot": "Q4D1P3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1P3"
  },
  {
    "uniprot": "Q4D5E7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5E7"
  },
  {
    "uniprot": "Q4DBQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBQ3"
  },
  {
    "uniprot": "Q4CR48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR48"
  },
  {
    "uniprot": "Q4CSA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSA2"
  },
  {
    "uniprot": "Q4DTX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTX8"
  },
  {
    "uniprot": "Q4E302",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E302"
  },
  {
    "uniprot": "Q4DUN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUN9"
  },
  {
    "uniprot": "Q4DI70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI70"
  },
  {
    "uniprot": "Q4CTW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTW5"
  },
  {
    "uniprot": "Q4CUD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUD7"
  },
  {
    "uniprot": "Q4DYW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYW4"
  },
  {
    "uniprot": "Q4D183",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D183"
  },
  {
    "uniprot": "Q4D2P2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2P2"
  },
  {
    "uniprot": "Q4DNG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNG1"
  },
  {
    "uniprot": "Q4DT20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT20"
  },
  {
    "uniprot": "Q4CQ22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ22"
  },
  {
    "uniprot": "Q4D192",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D192"
  },
  {
    "uniprot": "Q4CT54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT54"
  },
  {
    "uniprot": "Q4D9E2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9E2"
  },
  {
    "uniprot": "Q4DBS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBS1"
  },
  {
    "uniprot": "Q4CZG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZG4"
  },
  {
    "uniprot": "Q4D5Y8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Y8"
  },
  {
    "uniprot": "Q4DPG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPG0"
  },
  {
    "uniprot": "Q4D0U8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0U8"
  },
  {
    "uniprot": "Q4CLD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLD6"
  },
  {
    "uniprot": "Q4DTA0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTA0"
  },
  {
    "uniprot": "Q4DFG5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFG5"
  },
  {
    "uniprot": "Q4D4C0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4C0"
  },
  {
    "uniprot": "Q4DMI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMI7"
  },
  {
    "uniprot": "Q4DR58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR58"
  },
  {
    "uniprot": "Q4DKX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKX3"
  },
  {
    "uniprot": "Q4DGC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGC9"
  },
  {
    "uniprot": "Q4DPN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPN9"
  },
  {
    "uniprot": "Q4DYK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYK1"
  },
  {
    "uniprot": "Q4DIP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIP9"
  },
  {
    "uniprot": "Q4CNX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNX8"
  },
  {
    "uniprot": "Q4CV13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV13"
  },
  {
    "uniprot": "Q4D9N2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9N2"
  },
  {
    "uniprot": "Q4E364",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E364"
  },
  {
    "uniprot": "Q4CSZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSZ9"
  },
  {
    "uniprot": "Q4DPS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPS6"
  },
  {
    "uniprot": "Q4E3R6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3R6"
  },
  {
    "uniprot": "Q4CVN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVN3"
  },
  {
    "uniprot": "Q4DT49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT49"
  },
  {
    "uniprot": "Q4DGL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGL7"
  },
  {
    "uniprot": "Q4DID7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DID7"
  },
  {
    "uniprot": "Q4DHL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHL7"
  },
  {
    "uniprot": "Q4D623",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D623"
  },
  {
    "uniprot": "Q4CZY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZY1"
  },
  {
    "uniprot": "Q4DTJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTJ3"
  },
  {
    "uniprot": "Q4DMV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMV2"
  },
  {
    "uniprot": "Q4DHD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHD4"
  },
  {
    "uniprot": "Q4DA53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA53"
  },
  {
    "uniprot": "Q4D4M0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4M0"
  },
  {
    "uniprot": "Q4D239",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D239"
  },
  {
    "uniprot": "Q4D935",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D935"
  },
  {
    "uniprot": "Q4DRB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRB4"
  },
  {
    "uniprot": "Q4DQI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQI7"
  },
  {
    "uniprot": "Q4D6S8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6S8"
  },
  {
    "uniprot": "Q4CME0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CME0"
  },
  {
    "uniprot": "Q4E3U2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3U2"
  },
  {
    "uniprot": "Q4DXH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXH0"
  },
  {
    "uniprot": "Q4CNL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNL9"
  },
  {
    "uniprot": "Q4E5D0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5D0"
  },
  {
    "uniprot": "Q4DFH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFH3"
  },
  {
    "uniprot": "Q4D2H1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2H1"
  },
  {
    "uniprot": "Q4DNF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNF7"
  },
  {
    "uniprot": "Q4E0E9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0E9"
  },
  {
    "uniprot": "Q4DEZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEZ3"
  },
  {
    "uniprot": "Q4DTU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTU9"
  },
  {
    "uniprot": "Q4E5D2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5D2"
  },
  {
    "uniprot": "Q4DJ24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ24"
  },
  {
    "uniprot": "Q4CKC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKC6"
  },
  {
    "uniprot": "Q4E589",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E589"
  },
  {
    "uniprot": "Q4CY84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY84"
  },
  {
    "uniprot": "Q4CR54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR54"
  },
  {
    "uniprot": "Q4DE56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE56"
  },
  {
    "uniprot": "Q4DB09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB09"
  },
  {
    "uniprot": "Q4DK31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK31"
  },
  {
    "uniprot": "Q4DM81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM81"
  },
  {
    "uniprot": "Q4E0D6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0D6"
  },
  {
    "uniprot": "Q4D5K8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5K8"
  },
  {
    "uniprot": "Q4E2K9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2K9"
  },
  {
    "uniprot": "Q4D3J8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3J8"
  },
  {
    "uniprot": "Q4DL42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL42"
  },
  {
    "uniprot": "Q4E0I1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0I1"
  },
  {
    "uniprot": "Q4DVA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVA2"
  },
  {
    "uniprot": "Q4D8Y3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Y3"
  },
  {
    "uniprot": "Q4DL45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL45"
  },
  {
    "uniprot": "Q4DCT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCT8"
  },
  {
    "uniprot": "Q4D0A5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0A5"
  },
  {
    "uniprot": "Q4CZT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZT0"
  },
  {
    "uniprot": "Q4DT17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT17"
  },
  {
    "uniprot": "Q4CN71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN71"
  },
  {
    "uniprot": "Q4DRP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRP1"
  },
  {
    "uniprot": "Q4CP84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP84"
  },
  {
    "uniprot": "Q4DAU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAU2"
  },
  {
    "uniprot": "Q4DQ92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ92"
  },
  {
    "uniprot": "Q4DHW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHW3"
  },
  {
    "uniprot": "Q4CXP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXP2"
  },
  {
    "uniprot": "Q4DVB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVB5"
  },
  {
    "uniprot": "Q4DGR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGR3"
  },
  {
    "uniprot": "Q4DJY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJY8"
  },
  {
    "uniprot": "Q4D2V7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2V7"
  },
  {
    "uniprot": "Q4DGM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGM2"
  },
  {
    "uniprot": "Q4DY66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY66"
  },
  {
    "uniprot": "Q4E0E8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0E8"
  },
  {
    "uniprot": "Q4E3L9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3L9"
  },
  {
    "uniprot": "Q4D7C5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7C5"
  },
  {
    "uniprot": "Q4CSC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSC0"
  },
  {
    "uniprot": "Q4DKT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKT0"
  },
  {
    "uniprot": "Q4DC59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC59"
  },
  {
    "uniprot": "Q4CY32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY32"
  },
  {
    "uniprot": "Q4E4P0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4P0"
  },
  {
    "uniprot": "Q4DMJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMJ6"
  },
  {
    "uniprot": "Q4CSE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSE8"
  },
  {
    "uniprot": "Q4DD13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD13"
  },
  {
    "uniprot": "Q4E2F4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2F4"
  },
  {
    "uniprot": "Q4DLU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLU7"
  },
  {
    "uniprot": "Q4D162",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D162"
  },
  {
    "uniprot": "Q4E566",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E566"
  },
  {
    "uniprot": "Q4DKZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKZ2"
  },
  {
    "uniprot": "Q4DUU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUU8"
  },
  {
    "uniprot": "Q4CYX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYX8"
  },
  {
    "uniprot": "Q4D0W6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0W6"
  },
  {
    "uniprot": "Q4CLN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLN2"
  },
  {
    "uniprot": "Q4DJS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJS7"
  },
  {
    "uniprot": "Q4DAJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAJ7"
  },
  {
    "uniprot": "Q4D3I5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3I5"
  },
  {
    "uniprot": "Q4DN15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN15"
  },
  {
    "uniprot": "Q4D3G9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3G9"
  },
  {
    "uniprot": "Q4CTU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTU8"
  },
  {
    "uniprot": "Q4CY18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY18"
  },
  {
    "uniprot": "Q4D960",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D960"
  },
  {
    "uniprot": "Q4CXL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXL7"
  },
  {
    "uniprot": "Q4DYN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYN4"
  },
  {
    "uniprot": "Q4CRW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRW8"
  },
  {
    "uniprot": "Q4E203",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E203"
  },
  {
    "uniprot": "Q4D6L4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6L4"
  },
  {
    "uniprot": "Q4D2L1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2L1"
  },
  {
    "uniprot": "Q4D116",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D116"
  },
  {
    "uniprot": "Q4DPX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPX8"
  },
  {
    "uniprot": "Q4DCB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCB6"
  },
  {
    "uniprot": "Q4CNX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNX0"
  },
  {
    "uniprot": "Q4D2C8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2C8"
  },
  {
    "uniprot": "Q4DNW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNW2"
  },
  {
    "uniprot": "Q4CQM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQM2"
  },
  {
    "uniprot": "Q4CVN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVN7"
  },
  {
    "uniprot": "Q4D4P3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4P3"
  },
  {
    "uniprot": "Q4CR89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR89"
  },
  {
    "uniprot": "Q4DJM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJM5"
  },
  {
    "uniprot": "Q4E0N6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0N6"
  },
  {
    "uniprot": "Q4DY60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY60"
  },
  {
    "uniprot": "Q4E543",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E543"
  },
  {
    "uniprot": "Q4E460",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E460"
  },
  {
    "uniprot": "Q4E540",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E540"
  },
  {
    "uniprot": "Q4DVI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVI5"
  },
  {
    "uniprot": "Q4E1D1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1D1"
  },
  {
    "uniprot": "Q4CZC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZC7"
  },
  {
    "uniprot": "Q4E4X4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4X4"
  },
  {
    "uniprot": "Q4DQW3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQW3"
  },
  {
    "uniprot": "Q4CZU6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZU6"
  },
  {
    "uniprot": "Q4CSQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSQ8"
  },
  {
    "uniprot": "Q4DEB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEB2"
  },
  {
    "uniprot": "Q4DKC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKC8"
  },
  {
    "uniprot": "Q4CM54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM54"
  },
  {
    "uniprot": "Q4DW20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW20"
  },
  {
    "uniprot": "Q4DNN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNN4"
  },
  {
    "uniprot": "Q4CYI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYI9"
  },
  {
    "uniprot": "Q4CMI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMI2"
  },
  {
    "uniprot": "Q4D723",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D723"
  },
  {
    "uniprot": "Q4DXX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXX4"
  },
  {
    "uniprot": "Q4CU82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU82"
  },
  {
    "uniprot": "Q4CX78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX78"
  },
  {
    "uniprot": "Q4D5J1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5J1"
  },
  {
    "uniprot": "Q4CUB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUB2"
  },
  {
    "uniprot": "Q4D5L2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5L2"
  },
  {
    "uniprot": "Q4CXH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXH3"
  },
  {
    "uniprot": "Q4DRK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRK4"
  },
  {
    "uniprot": "Q4DQV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQV4"
  },
  {
    "uniprot": "Q4DA85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA85"
  },
  {
    "uniprot": "Q4DTB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTB0"
  },
  {
    "uniprot": "Q4DIL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIL5"
  },
  {
    "uniprot": "Q4D8S0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8S0"
  },
  {
    "uniprot": "Q4DAG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAG2"
  },
  {
    "uniprot": "Q4E5J9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5J9"
  },
  {
    "uniprot": "Q4DNN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNN5"
  },
  {
    "uniprot": "Q4E4A3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4A3"
  },
  {
    "uniprot": "Q4E1I4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1I4"
  },
  {
    "uniprot": "Q4CMA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMA7"
  },
  {
    "uniprot": "Q4CXI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXI0"
  },
  {
    "uniprot": "Q4DQL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQL1"
  },
  {
    "uniprot": "Q4DNX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNX0"
  },
  {
    "uniprot": "Q4DIY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIY8"
  },
  {
    "uniprot": "Q4DZJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZJ7"
  },
  {
    "uniprot": "Q4DZT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZT8"
  },
  {
    "uniprot": "Q4E4X5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4X5"
  },
  {
    "uniprot": "Q4E5W9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5W9"
  },
  {
    "uniprot": "Q4E0F7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0F7"
  },
  {
    "uniprot": "Q4DPI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPI6"
  },
  {
    "uniprot": "Q4DII2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DII2"
  },
  {
    "uniprot": "Q4DDC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDC5"
  },
  {
    "uniprot": "Q4CZM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZM1"
  },
  {
    "uniprot": "Q4D3Z9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Z9"
  },
  {
    "uniprot": "Q4D6E4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6E4"
  },
  {
    "uniprot": "Q4DQA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQA8"
  },
  {
    "uniprot": "Q4E1V2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1V2"
  },
  {
    "uniprot": "Q4E2Z0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Z0"
  },
  {
    "uniprot": "Q4DBQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBQ0"
  },
  {
    "uniprot": "Q4D3G8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3G8"
  },
  {
    "uniprot": "Q4D929",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D929"
  },
  {
    "uniprot": "Q4CWF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWF2"
  },
  {
    "uniprot": "Q4DQ59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ59"
  },
  {
    "uniprot": "Q4DVL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVL8"
  },
  {
    "uniprot": "Q4CS26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS26"
  },
  {
    "uniprot": "Q4DR55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR55"
  },
  {
    "uniprot": "Q4DF22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF22"
  },
  {
    "uniprot": "Q4E2S0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2S0"
  },
  {
    "uniprot": "Q4E557",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E557"
  },
  {
    "uniprot": "Q4CMN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMN6"
  },
  {
    "uniprot": "Q4DPX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPX2"
  },
  {
    "uniprot": "Q4CPR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPR6"
  },
  {
    "uniprot": "Q4CRS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRS0"
  },
  {
    "uniprot": "Q4D9M6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9M6"
  },
  {
    "uniprot": "Q4CKL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKL1"
  },
  {
    "uniprot": "Q4DUP9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUP9"
  },
  {
    "uniprot": "Q4D3B5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3B5"
  },
  {
    "uniprot": "Q4CXQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXQ3"
  },
  {
    "uniprot": "Q4D4X8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4X8"
  },
  {
    "uniprot": "Q4DWU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWU7"
  },
  {
    "uniprot": "Q4DE43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE43"
  },
  {
    "uniprot": "Q4DT62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT62"
  },
  {
    "uniprot": "Q4DNW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNW1"
  },
  {
    "uniprot": "Q4DM77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM77"
  },
  {
    "uniprot": "Q4CMK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMK8"
  },
  {
    "uniprot": "Q4CLU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLU3"
  },
  {
    "uniprot": "Q4CX28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX28"
  },
  {
    "uniprot": "Q4CLB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLB1"
  },
  {
    "uniprot": "Q4DH23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH23"
  },
  {
    "uniprot": "Q4CQH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQH5"
  },
  {
    "uniprot": "Q4DZY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZY5"
  },
  {
    "uniprot": "Q4E1T2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1T2"
  },
  {
    "uniprot": "Q4D678",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D678"
  },
  {
    "uniprot": "Q4DWQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWQ2"
  },
  {
    "uniprot": "Q4D3V6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3V6"
  },
  {
    "uniprot": "Q4DAG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAG1"
  },
  {
    "uniprot": "Q4D3B2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3B2"
  },
  {
    "uniprot": "Q4CNJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNJ6"
  },
  {
    "uniprot": "Q4CXL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXL1"
  },
  {
    "uniprot": "Q4E1U7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1U7"
  },
  {
    "uniprot": "Q4DR21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR21"
  },
  {
    "uniprot": "Q4CWN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWN5"
  },
  {
    "uniprot": "Q4CKV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKV8"
  },
  {
    "uniprot": "Q4DT08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT08"
  },
  {
    "uniprot": "Q4D962",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D962"
  },
  {
    "uniprot": "Q4DGE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGE2"
  },
  {
    "uniprot": "Q4DHZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHZ4"
  },
  {
    "uniprot": "Q4DX24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX24"
  },
  {
    "uniprot": "Q4CUG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUG2"
  },
  {
    "uniprot": "Q4CLI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLI1"
  },
  {
    "uniprot": "Q4DY17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY17"
  },
  {
    "uniprot": "Q4D7C6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7C6"
  },
  {
    "uniprot": "Q4DN97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN97"
  },
  {
    "uniprot": "Q4D540",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D540"
  },
  {
    "uniprot": "Q4DFM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFM2"
  },
  {
    "uniprot": "Q4CX63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX63"
  },
  {
    "uniprot": "Q4DC58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC58"
  },
  {
    "uniprot": "Q4DQS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQS4"
  },
  {
    "uniprot": "Q4DJ41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ41"
  },
  {
    "uniprot": "Q4D108",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D108"
  },
  {
    "uniprot": "Q4CT23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT23"
  },
  {
    "uniprot": "Q4E5Y6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Y6"
  },
  {
    "uniprot": "Q4CQ06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ06"
  },
  {
    "uniprot": "Q4DDZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDZ3"
  },
  {
    "uniprot": "Q4CTL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTL3"
  },
  {
    "uniprot": "Q4DXT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXT7"
  },
  {
    "uniprot": "Q4DP56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP56"
  },
  {
    "uniprot": "Q4DDU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDU7"
  },
  {
    "uniprot": "Q4CLX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLX1"
  },
  {
    "uniprot": "Q4D842",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D842"
  },
  {
    "uniprot": "Q4CXA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXA5"
  },
  {
    "uniprot": "Q4CPF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPF3"
  },
  {
    "uniprot": "Q4DW11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW11"
  },
  {
    "uniprot": "Q4E542",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E542"
  },
  {
    "uniprot": "Q4CL58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL58"
  },
  {
    "uniprot": "Q4DBG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBG3"
  },
  {
    "uniprot": "Q4DKT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKT7"
  },
  {
    "uniprot": "Q4CMY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMY6"
  },
  {
    "uniprot": "Q4CTB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTB2"
  },
  {
    "uniprot": "Q4DAE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAE9"
  },
  {
    "uniprot": "Q4DAR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAR8"
  },
  {
    "uniprot": "Q4DW23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW23"
  },
  {
    "uniprot": "Q4CMR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMR2"
  },
  {
    "uniprot": "Q4DCX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCX7"
  },
  {
    "uniprot": "Q4DFZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFZ7"
  },
  {
    "uniprot": "Q4D519",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D519"
  },
  {
    "uniprot": "Q4D0T0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0T0"
  },
  {
    "uniprot": "Q4DPC4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPC4"
  },
  {
    "uniprot": "Q4CMW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMW5"
  },
  {
    "uniprot": "Q4DB02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB02"
  },
  {
    "uniprot": "Q4DRZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRZ5"
  },
  {
    "uniprot": "Q4D8I0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8I0"
  },
  {
    "uniprot": "Q4DY83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY83"
  },
  {
    "uniprot": "Q4CQD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQD5"
  },
  {
    "uniprot": "Q4CZQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZQ0"
  },
  {
    "uniprot": "Q4D8V8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8V8"
  },
  {
    "uniprot": "Q4DAC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAC3"
  },
  {
    "uniprot": "Q4E268",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E268"
  },
  {
    "uniprot": "Q4DG79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG79"
  },
  {
    "uniprot": "Q4CQ40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ40"
  },
  {
    "uniprot": "Q4D8G4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8G4"
  },
  {
    "uniprot": "Q4DFD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFD5"
  },
  {
    "uniprot": "Q4E181",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E181"
  },
  {
    "uniprot": "Q4D275",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D275"
  },
  {
    "uniprot": "Q4DGN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGN9"
  },
  {
    "uniprot": "Q4DPE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPE6"
  },
  {
    "uniprot": "Q4D201",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D201"
  },
  {
    "uniprot": "Q4D9D5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9D5"
  },
  {
    "uniprot": "Q4D7G7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7G7"
  },
  {
    "uniprot": "Q4DAF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAF6"
  },
  {
    "uniprot": "Q4DPQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPQ1"
  },
  {
    "uniprot": "Q4CSJ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSJ9"
  },
  {
    "uniprot": "Q4E0J7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0J7"
  },
  {
    "uniprot": "Q4E403",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E403"
  },
  {
    "uniprot": "Q4D3M2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3M2"
  },
  {
    "uniprot": "Q4E4S6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4S6"
  },
  {
    "uniprot": "Q4D3L0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3L0"
  },
  {
    "uniprot": "Q4DKR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKR1"
  },
  {
    "uniprot": "Q4CPV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPV7"
  },
  {
    "uniprot": "Q4E1J4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1J4"
  },
  {
    "uniprot": "Q4DZL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZL9"
  },
  {
    "uniprot": "Q4CZF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZF5"
  },
  {
    "uniprot": "Q4CSE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSE2"
  },
  {
    "uniprot": "Q4CQC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQC0"
  },
  {
    "uniprot": "Q4DE08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE08"
  },
  {
    "uniprot": "Q4DS21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS21"
  },
  {
    "uniprot": "Q4DK68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK68"
  },
  {
    "uniprot": "Q4D204",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D204"
  },
  {
    "uniprot": "Q4E585",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E585"
  },
  {
    "uniprot": "Q4CQN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQN8"
  },
  {
    "uniprot": "Q4DCX1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCX1"
  },
  {
    "uniprot": "Q4CMX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMX4"
  },
  {
    "uniprot": "Q4E0C5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0C5"
  },
  {
    "uniprot": "Q4CKT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKT8"
  },
  {
    "uniprot": "Q4D7J7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7J7"
  },
  {
    "uniprot": "Q4DF09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF09"
  },
  {
    "uniprot": "Q4DVW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVW8"
  },
  {
    "uniprot": "Q4CN68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN68"
  },
  {
    "uniprot": "Q4DM02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM02"
  },
  {
    "uniprot": "Q4DU77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU77"
  },
  {
    "uniprot": "Q4CL86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL86"
  },
  {
    "uniprot": "Q4CU05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU05"
  },
  {
    "uniprot": "Q4CS37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS37"
  },
  {
    "uniprot": "Q4DY00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY00"
  },
  {
    "uniprot": "Q4CSX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSX8"
  },
  {
    "uniprot": "Q4DGN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGN1"
  },
  {
    "uniprot": "Q4DNL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNL8"
  },
  {
    "uniprot": "Q4CPQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPQ9"
  },
  {
    "uniprot": "Q4CP39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP39"
  },
  {
    "uniprot": "Q4D4L4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4L4"
  },
  {
    "uniprot": "Q4DV24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV24"
  },
  {
    "uniprot": "Q4CQ23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ23"
  },
  {
    "uniprot": "Q4DAB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAB4"
  },
  {
    "uniprot": "Q4DQZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQZ0"
  },
  {
    "uniprot": "Q4DXQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXQ3"
  },
  {
    "uniprot": "Q4E180",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E180"
  },
  {
    "uniprot": "Q4CLJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLJ3"
  },
  {
    "uniprot": "Q4DKW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKW9"
  },
  {
    "uniprot": "Q4E4F6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4F6"
  },
  {
    "uniprot": "Q4D6G2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6G2"
  },
  {
    "uniprot": "Q4DMF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMF5"
  },
  {
    "uniprot": "Q4D1R4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1R4"
  },
  {
    "uniprot": "Q4DFS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFS0"
  },
  {
    "uniprot": "Q4DH73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH73"
  },
  {
    "uniprot": "Q4DD63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD63"
  },
  {
    "uniprot": "Q4DJR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJR3"
  },
  {
    "uniprot": "Q4DET0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DET0"
  },
  {
    "uniprot": "Q4DC68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC68"
  },
  {
    "uniprot": "Q4DQC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQC6"
  },
  {
    "uniprot": "Q4D128",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D128"
  },
  {
    "uniprot": "Q4DPM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPM1"
  },
  {
    "uniprot": "Q4D4X3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4X3"
  },
  {
    "uniprot": "Q4D8X6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8X6"
  },
  {
    "uniprot": "Q4E2R3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2R3"
  },
  {
    "uniprot": "Q4E0Z9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Z9"
  },
  {
    "uniprot": "Q4DSJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSJ5"
  },
  {
    "uniprot": "Q4DCB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCB4"
  },
  {
    "uniprot": "Q4CWY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWY7"
  },
  {
    "uniprot": "Q4E3Z8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Z8"
  },
  {
    "uniprot": "Q4DAR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAR1"
  },
  {
    "uniprot": "Q4CZP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZP2"
  },
  {
    "uniprot": "Q4DZ53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ53"
  },
  {
    "uniprot": "Q4D7F1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7F1"
  },
  {
    "uniprot": "Q4CV69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV69"
  },
  {
    "uniprot": "Q4DTE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTE3"
  },
  {
    "uniprot": "Q4DXV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXV8"
  },
  {
    "uniprot": "Q4DLU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLU8"
  },
  {
    "uniprot": "Q4E3A7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3A7"
  },
  {
    "uniprot": "Q4E5U2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5U2"
  },
  {
    "uniprot": "Q4DYE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYE2"
  },
  {
    "uniprot": "Q4E1X3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1X3"
  },
  {
    "uniprot": "Q4DK97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK97"
  },
  {
    "uniprot": "Q4DB69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB69"
  },
  {
    "uniprot": "Q4DV73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV73"
  },
  {
    "uniprot": "Q4CR79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR79"
  },
  {
    "uniprot": "Q4D194",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D194"
  },
  {
    "uniprot": "Q4D3A8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3A8"
  },
  {
    "uniprot": "Q4DZK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZK1"
  },
  {
    "uniprot": "Q4CLQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLQ6"
  },
  {
    "uniprot": "Q4DBL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBL3"
  },
  {
    "uniprot": "Q4E3S1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3S1"
  },
  {
    "uniprot": "Q4CWM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWM8"
  },
  {
    "uniprot": "Q4DEB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEB1"
  },
  {
    "uniprot": "Q4DQ98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ98"
  },
  {
    "uniprot": "Q4D0K1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0K1"
  },
  {
    "uniprot": "Q4DWY8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWY8"
  },
  {
    "uniprot": "Q4DWA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWA1"
  },
  {
    "uniprot": "Q4DEG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEG8"
  },
  {
    "uniprot": "Q4CTQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTQ2"
  },
  {
    "uniprot": "Q4DXB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXB0"
  },
  {
    "uniprot": "Q4CPJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPJ5"
  },
  {
    "uniprot": "Q4D861",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D861"
  },
  {
    "uniprot": "Q4CXC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXC6"
  },
  {
    "uniprot": "Q4E1Y4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Y4"
  },
  {
    "uniprot": "Q4E2M6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2M6"
  },
  {
    "uniprot": "Q4DTV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTV9"
  },
  {
    "uniprot": "Q4DMU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMU4"
  },
  {
    "uniprot": "Q4CQP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQP2"
  },
  {
    "uniprot": "Q4CVP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVP8"
  },
  {
    "uniprot": "Q4CNE0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNE0"
  },
  {
    "uniprot": "Q4CYJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYJ1"
  },
  {
    "uniprot": "Q4DLY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLY4"
  },
  {
    "uniprot": "Q4CY86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY86"
  },
  {
    "uniprot": "Q4DM75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM75"
  },
  {
    "uniprot": "Q4CL44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL44"
  },
  {
    "uniprot": "Q4DN62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN62"
  },
  {
    "uniprot": "Q4D597",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D597"
  },
  {
    "uniprot": "Q4DHZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHZ0"
  },
  {
    "uniprot": "Q4CQR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQR8"
  },
  {
    "uniprot": "Q4D3H5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3H5"
  },
  {
    "uniprot": "Q4CPK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPK0"
  },
  {
    "uniprot": "Q4E220",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E220"
  },
  {
    "uniprot": "Q4DN83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN83"
  },
  {
    "uniprot": "Q4DJ27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ27"
  },
  {
    "uniprot": "Q4CPX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPX7"
  },
  {
    "uniprot": "Q4CUG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUG8"
  },
  {
    "uniprot": "Q4DRH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRH4"
  },
  {
    "uniprot": "Q4DZR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZR3"
  },
  {
    "uniprot": "Q4DX45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX45"
  },
  {
    "uniprot": "Q4DRM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRM5"
  },
  {
    "uniprot": "Q4CPF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPF0"
  },
  {
    "uniprot": "Q4D4Z4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Z4"
  },
  {
    "uniprot": "Q4DPW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPW7"
  },
  {
    "uniprot": "Q4D382",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D382"
  },
  {
    "uniprot": "Q4DCU4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCU4"
  },
  {
    "uniprot": "Q4D071",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D071"
  },
  {
    "uniprot": "Q4DYG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYG1"
  },
  {
    "uniprot": "Q4CUC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUC3"
  },
  {
    "uniprot": "Q4DNE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNE6"
  },
  {
    "uniprot": "Q4CSD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSD6"
  },
  {
    "uniprot": "Q4DFZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFZ9"
  },
  {
    "uniprot": "Q4CYK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYK7"
  },
  {
    "uniprot": "Q4CXC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXC0"
  },
  {
    "uniprot": "Q4CVF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVF7"
  },
  {
    "uniprot": "Q4CLH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLH4"
  },
  {
    "uniprot": "Q4D2I4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2I4"
  },
  {
    "uniprot": "Q4D4B5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4B5"
  },
  {
    "uniprot": "Q4CXB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXB1"
  },
  {
    "uniprot": "Q4E1G2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1G2"
  },
  {
    "uniprot": "Q4D647",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D647"
  },
  {
    "uniprot": "Q4E4S3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4S3"
  },
  {
    "uniprot": "Q4D3F6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3F6"
  },
  {
    "uniprot": "Q4DYY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYY4"
  },
  {
    "uniprot": "Q4E5X6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5X6"
  },
  {
    "uniprot": "Q4D051",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D051"
  },
  {
    "uniprot": "Q4D5S8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5S8"
  },
  {
    "uniprot": "Q4DND7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DND7"
  },
  {
    "uniprot": "Q4D1W0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1W0"
  },
  {
    "uniprot": "Q4DDM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDM2"
  },
  {
    "uniprot": "Q4DH97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH97"
  },
  {
    "uniprot": "Q4DRW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRW1"
  },
  {
    "uniprot": "Q4E638",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E638"
  },
  {
    "uniprot": "Q4CPB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPB0"
  },
  {
    "uniprot": "Q4E668",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E668"
  },
  {
    "uniprot": "Q4DYD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYD4"
  },
  {
    "uniprot": "Q4D9G0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9G0"
  },
  {
    "uniprot": "Q4DTR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTR3"
  },
  {
    "uniprot": "Q4DBG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBG9"
  },
  {
    "uniprot": "Q4CY22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY22"
  },
  {
    "uniprot": "Q4E247",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E247"
  },
  {
    "uniprot": "Q4CKR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKR7"
  },
  {
    "uniprot": "Q4E5B2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5B2"
  },
  {
    "uniprot": "Q4D2R0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2R0"
  },
  {
    "uniprot": "Q4CNR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNR2"
  },
  {
    "uniprot": "Q4D3D4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3D4"
  },
  {
    "uniprot": "Q4DYQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYQ7"
  },
  {
    "uniprot": "Q4DCR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCR8"
  },
  {
    "uniprot": "Q4E4W3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4W3"
  },
  {
    "uniprot": "Q4E3L2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3L2"
  },
  {
    "uniprot": "Q4DVR8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVR8"
  },
  {
    "uniprot": "Q4DYS7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYS7"
  },
  {
    "uniprot": "Q4D532",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D532"
  },
  {
    "uniprot": "Q4D7J1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7J1"
  },
  {
    "uniprot": "Q4DZR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZR9"
  },
  {
    "uniprot": "Q4DSZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSZ0"
  },
  {
    "uniprot": "Q4E325",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E325"
  },
  {
    "uniprot": "Q4DWE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWE3"
  },
  {
    "uniprot": "Q4DZR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZR1"
  },
  {
    "uniprot": "Q4CM99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM99"
  },
  {
    "uniprot": "Q4CXB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXB0"
  },
  {
    "uniprot": "Q4DCZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCZ0"
  },
  {
    "uniprot": "Q4DKQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKQ2"
  },
  {
    "uniprot": "Q4CP38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP38"
  },
  {
    "uniprot": "Q4DW59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW59"
  },
  {
    "uniprot": "Q4E1W8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1W8"
  },
  {
    "uniprot": "Q4CN77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN77"
  },
  {
    "uniprot": "Q4CP46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP46"
  },
  {
    "uniprot": "Q4CZG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZG3"
  },
  {
    "uniprot": "Q4CNM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNM0"
  },
  {
    "uniprot": "Q4CP63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP63"
  },
  {
    "uniprot": "Q4CWX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWX8"
  },
  {
    "uniprot": "Q4DYT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYT3"
  },
  {
    "uniprot": "Q4D007",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D007"
  },
  {
    "uniprot": "Q4D4V1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4V1"
  },
  {
    "uniprot": "Q4DEZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEZ6"
  },
  {
    "uniprot": "Q4DPL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPL9"
  },
  {
    "uniprot": "Q4DZM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZM1"
  },
  {
    "uniprot": "Q4D2U7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2U7"
  },
  {
    "uniprot": "Q4E0C8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0C8"
  },
  {
    "uniprot": "Q4DLY7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLY7"
  },
  {
    "uniprot": "Q4E2D1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2D1"
  },
  {
    "uniprot": "Q4D4U7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4U7"
  },
  {
    "uniprot": "Q4DGD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGD0"
  },
  {
    "uniprot": "Q4DXH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXH3"
  },
  {
    "uniprot": "Q4D5F9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5F9"
  },
  {
    "uniprot": "Q4D0N9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0N9"
  },
  {
    "uniprot": "Q4DK34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK34"
  },
  {
    "uniprot": "Q4E037",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E037"
  },
  {
    "uniprot": "Q4DK30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK30"
  },
  {
    "uniprot": "Q4DQI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQI2"
  },
  {
    "uniprot": "Q4D5K4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5K4"
  },
  {
    "uniprot": "Q4CRC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRC5"
  },
  {
    "uniprot": "Q4D676",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D676"
  },
  {
    "uniprot": "Q4CY07",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY07"
  },
  {
    "uniprot": "Q4CPA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPA8"
  },
  {
    "uniprot": "Q4DYL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYL8"
  },
  {
    "uniprot": "Q4DTM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTM3"
  },
  {
    "uniprot": "Q4DIR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIR7"
  },
  {
    "uniprot": "Q4D5Z8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Z8"
  },
  {
    "uniprot": "Q4DX36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX36"
  },
  {
    "uniprot": "Q4DX91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX91"
  },
  {
    "uniprot": "Q4D783",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D783"
  },
  {
    "uniprot": "Q4E2U6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2U6"
  },
  {
    "uniprot": "Q4CQQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQQ5"
  },
  {
    "uniprot": "Q4CSK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSK6"
  },
  {
    "uniprot": "Q4DB19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB19"
  },
  {
    "uniprot": "Q4CR06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR06"
  },
  {
    "uniprot": "Q4DCS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCS9"
  },
  {
    "uniprot": "Q4DHT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHT1"
  },
  {
    "uniprot": "Q4E5H7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5H7"
  },
  {
    "uniprot": "Q4DP76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP76"
  },
  {
    "uniprot": "Q4CPZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPZ6"
  },
  {
    "uniprot": "Q4DPS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPS1"
  },
  {
    "uniprot": "Q4DPP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPP2"
  },
  {
    "uniprot": "Q4DU67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU67"
  },
  {
    "uniprot": "Q4DRN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRN7"
  },
  {
    "uniprot": "Q4DH99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH99"
  },
  {
    "uniprot": "Q4DTL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTL3"
  },
  {
    "uniprot": "Q4DN87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN87"
  },
  {
    "uniprot": "Q4DCC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCC1"
  },
  {
    "uniprot": "Q4DSX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSX7"
  },
  {
    "uniprot": "Q4CPZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPZ0"
  },
  {
    "uniprot": "Q4D0H8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0H8"
  },
  {
    "uniprot": "Q4DXP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXP7"
  },
  {
    "uniprot": "Q4DET6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DET6"
  },
  {
    "uniprot": "Q4DAC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAC8"
  },
  {
    "uniprot": "Q4D5X6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5X6"
  },
  {
    "uniprot": "Q4CQT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQT8"
  },
  {
    "uniprot": "Q4D2Z9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Z9"
  },
  {
    "uniprot": "Q4CMZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMZ4"
  },
  {
    "uniprot": "Q4E490",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E490"
  },
  {
    "uniprot": "Q4DQY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQY9"
  },
  {
    "uniprot": "Q4DIK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIK6"
  },
  {
    "uniprot": "Q4DF06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF06"
  },
  {
    "uniprot": "Q4D423",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D423"
  },
  {
    "uniprot": "Q4D5V1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5V1"
  },
  {
    "uniprot": "Q4DG91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG91"
  },
  {
    "uniprot": "Q4DA51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA51"
  },
  {
    "uniprot": "Q4DSR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSR0"
  },
  {
    "uniprot": "Q4CUF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUF2"
  },
  {
    "uniprot": "Q4E129",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E129"
  },
  {
    "uniprot": "Q4CM29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM29"
  },
  {
    "uniprot": "Q4CRM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRM7"
  },
  {
    "uniprot": "Q4CRR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRR5"
  },
  {
    "uniprot": "Q4CNA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNA5"
  },
  {
    "uniprot": "Q4DU93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU93"
  },
  {
    "uniprot": "Q4D553",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D553"
  },
  {
    "uniprot": "Q4CP85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP85"
  },
  {
    "uniprot": "Q4E5B1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5B1"
  },
  {
    "uniprot": "Q4D1H5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1H5"
  },
  {
    "uniprot": "Q4DK35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK35"
  },
  {
    "uniprot": "Q4DMA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMA7"
  },
  {
    "uniprot": "Q4CTJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTJ3"
  },
  {
    "uniprot": "Q4DUA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUA4"
  },
  {
    "uniprot": "Q4DHT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHT5"
  },
  {
    "uniprot": "Q4CP31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP31"
  },
  {
    "uniprot": "Q4DP65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP65"
  },
  {
    "uniprot": "Q4DEJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEJ7"
  },
  {
    "uniprot": "Q4DL60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL60"
  },
  {
    "uniprot": "Q4DPZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPZ0"
  },
  {
    "uniprot": "Q4DTT8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTT8"
  },
  {
    "uniprot": "Q4DXJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXJ1"
  },
  {
    "uniprot": "Q4D3C8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3C8"
  },
  {
    "uniprot": "Q4DDV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDV0"
  },
  {
    "uniprot": "Q4CXE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXE6"
  },
  {
    "uniprot": "Q4E667",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E667"
  },
  {
    "uniprot": "Q4DYN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYN7"
  },
  {
    "uniprot": "Q4E3D0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3D0"
  },
  {
    "uniprot": "Q4DXZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXZ4"
  },
  {
    "uniprot": "Q4DR04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR04"
  },
  {
    "uniprot": "Q4DLF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLF5"
  },
  {
    "uniprot": "Q4CP24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP24"
  },
  {
    "uniprot": "Q4DXA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXA4"
  },
  {
    "uniprot": "Q4D563",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D563"
  },
  {
    "uniprot": "Q4E4R6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4R6"
  },
  {
    "uniprot": "Q4DQY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQY6"
  },
  {
    "uniprot": "Q4DBD7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBD7"
  },
  {
    "uniprot": "Q4D2Z8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Z8"
  },
  {
    "uniprot": "Q4D9E7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9E7"
  },
  {
    "uniprot": "Q4D081",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D081"
  },
  {
    "uniprot": "Q4DWV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWV5"
  },
  {
    "uniprot": "Q4E615",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E615"
  },
  {
    "uniprot": "Q4CQT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQT9"
  },
  {
    "uniprot": "Q4DFB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFB7"
  },
  {
    "uniprot": "Q4D2A6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2A6"
  },
  {
    "uniprot": "Q4DUZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUZ5"
  },
  {
    "uniprot": "Q4DJR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJR2"
  },
  {
    "uniprot": "Q4E3K2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3K2"
  },
  {
    "uniprot": "Q4DE21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE21"
  },
  {
    "uniprot": "Q4CSW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSW6"
  },
  {
    "uniprot": "Q7Z1V1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q7Z1V1"
  },
  {
    "uniprot": "Q4DZF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZF7"
  },
  {
    "uniprot": "Q4CT25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT25"
  },
  {
    "uniprot": "Q4DI98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI98"
  },
  {
    "uniprot": "Q4DSZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSZ9"
  },
  {
    "uniprot": "Q4CSW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSW0"
  },
  {
    "uniprot": "Q4DGH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGH0"
  },
  {
    "uniprot": "Q4DU92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU92"
  },
  {
    "uniprot": "Q4CY71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY71"
  },
  {
    "uniprot": "Q4E5H0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5H0"
  },
  {
    "uniprot": "Q4DKD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKD8"
  },
  {
    "uniprot": "Q4DUN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUN3"
  },
  {
    "uniprot": "Q4DDH5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDH5"
  },
  {
    "uniprot": "Q4D8M7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8M7"
  },
  {
    "uniprot": "Q4CUD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUD0"
  },
  {
    "uniprot": "Q4D2K3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2K3"
  },
  {
    "uniprot": "Q4CWG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWG4"
  },
  {
    "uniprot": "Q4CVR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVR3"
  },
  {
    "uniprot": "Q4DWM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWM1"
  },
  {
    "uniprot": "Q4D505",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D505"
  },
  {
    "uniprot": "Q4D5Q6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5Q6"
  },
  {
    "uniprot": "Q4CKI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKI0"
  },
  {
    "uniprot": "Q4DLL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLL4"
  },
  {
    "uniprot": "Q4CZ26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ26"
  },
  {
    "uniprot": "Q4CT72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT72"
  },
  {
    "uniprot": "Q4D5S0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5S0"
  },
  {
    "uniprot": "Q4DA65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA65"
  },
  {
    "uniprot": "Q4CWV1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWV1"
  },
  {
    "uniprot": "Q4CRV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRV2"
  },
  {
    "uniprot": "Q4DKS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKS1"
  },
  {
    "uniprot": "Q4CS56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS56"
  },
  {
    "uniprot": "Q4E1T9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1T9"
  },
  {
    "uniprot": "Q4CM21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM21"
  },
  {
    "uniprot": "Q4E423",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E423"
  },
  {
    "uniprot": "Q4E5K1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5K1"
  },
  {
    "uniprot": "Q4CSA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSA5"
  },
  {
    "uniprot": "Q4CV61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV61"
  },
  {
    "uniprot": "Q4DVI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVI0"
  },
  {
    "uniprot": "Q4CKF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKF8"
  },
  {
    "uniprot": "Q4DM10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM10"
  },
  {
    "uniprot": "Q4CWT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWT2"
  },
  {
    "uniprot": "Q4CPL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPL3"
  },
  {
    "uniprot": "Q4DSD1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSD1"
  },
  {
    "uniprot": "Q4DR67",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR67"
  },
  {
    "uniprot": "Q4CY99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY99"
  },
  {
    "uniprot": "Q4DKR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKR0"
  },
  {
    "uniprot": "Q4DQ35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ35"
  },
  {
    "uniprot": "Q4D933",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D933"
  },
  {
    "uniprot": "Q4DNE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNE9"
  },
  {
    "uniprot": "Q4CPN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPN2"
  },
  {
    "uniprot": "Q4CW39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW39"
  },
  {
    "uniprot": "Q4DGW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGW8"
  },
  {
    "uniprot": "Q4CTW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTW2"
  },
  {
    "uniprot": "Q4CWR3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWR3"
  },
  {
    "uniprot": "Q4D4N8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4N8"
  },
  {
    "uniprot": "Q4DY99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY99"
  },
  {
    "uniprot": "Q4DEA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEA8"
  },
  {
    "uniprot": "Q4CS52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS52"
  },
  {
    "uniprot": "Q4CUV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUV0"
  },
  {
    "uniprot": "Q4CPE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPE8"
  },
  {
    "uniprot": "Q4DU46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU46"
  },
  {
    "uniprot": "Q4CQK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQK0"
  },
  {
    "uniprot": "Q4DYL5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYL5"
  },
  {
    "uniprot": "Q4DZB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZB3"
  },
  {
    "uniprot": "Q4DFR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFR0"
  },
  {
    "uniprot": "Q4D9X9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9X9"
  },
  {
    "uniprot": "Q4CSS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSS3"
  },
  {
    "uniprot": "Q4DE89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE89"
  },
  {
    "uniprot": "Q4DUF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUF3"
  },
  {
    "uniprot": "Q4CM27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM27"
  },
  {
    "uniprot": "Q4CQI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQI5"
  },
  {
    "uniprot": "Q4DMI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMI1"
  },
  {
    "uniprot": "Q4CRZ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRZ1"
  },
  {
    "uniprot": "Q4DTL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTL1"
  },
  {
    "uniprot": "Q4CNM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNM3"
  },
  {
    "uniprot": "Q4DT50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT50"
  },
  {
    "uniprot": "Q4E3C0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3C0"
  },
  {
    "uniprot": "Q4DB85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB85"
  },
  {
    "uniprot": "Q4CM65",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM65"
  },
  {
    "uniprot": "Q4DRN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRN0"
  },
  {
    "uniprot": "Q4E016",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E016"
  },
  {
    "uniprot": "Q4CY55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY55"
  },
  {
    "uniprot": "Q4CKC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKC3"
  },
  {
    "uniprot": "Q4DUJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUJ1"
  },
  {
    "uniprot": "Q4E0Q3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Q3"
  },
  {
    "uniprot": "Q4E1X6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1X6"
  },
  {
    "uniprot": "Q4D7T7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7T7"
  },
  {
    "uniprot": "Q4D4Y6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Y6"
  },
  {
    "uniprot": "Q4CXQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXQ1"
  },
  {
    "uniprot": "Q4CWN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWN7"
  },
  {
    "uniprot": "Q4D1C2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1C2"
  },
  {
    "uniprot": "Q4DT64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT64"
  },
  {
    "uniprot": "Q4DVS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVS6"
  },
  {
    "uniprot": "Q4E5M2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5M2"
  },
  {
    "uniprot": "Q4D2Q4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Q4"
  },
  {
    "uniprot": "Q4D3U9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3U9"
  },
  {
    "uniprot": "Q4D251",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D251"
  },
  {
    "uniprot": "Q4D5H0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5H0"
  },
  {
    "uniprot": "Q4DW12",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW12"
  },
  {
    "uniprot": "Q4DWF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWF6"
  },
  {
    "uniprot": "Q4D2M6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2M6"
  },
  {
    "uniprot": "Q4DMU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMU5"
  },
  {
    "uniprot": "Q4DW00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW00"
  },
  {
    "uniprot": "Q4DIZ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIZ3"
  },
  {
    "uniprot": "Q4DLN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLN4"
  },
  {
    "uniprot": "Q4DG35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG35"
  },
  {
    "uniprot": "Q4D4Q1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Q1"
  },
  {
    "uniprot": "Q4CLI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLI2"
  },
  {
    "uniprot": "Q4D4A9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4A9"
  },
  {
    "uniprot": "Q4E4T8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4T8"
  },
  {
    "uniprot": "Q4DNT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNT7"
  },
  {
    "uniprot": "Q4D619",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D619"
  },
  {
    "uniprot": "Q4DB78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB78"
  },
  {
    "uniprot": "Q4CV16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV16"
  },
  {
    "uniprot": "Q4E391",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E391"
  },
  {
    "uniprot": "Q4DHW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHW6"
  },
  {
    "uniprot": "Q4CXY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXY6"
  },
  {
    "uniprot": "Q4DUM5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUM5"
  },
  {
    "uniprot": "Q4DB23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB23"
  },
  {
    "uniprot": "Q4DVZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVZ7"
  },
  {
    "uniprot": "Q4DDS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDS4"
  },
  {
    "uniprot": "Q4DLE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLE3"
  },
  {
    "uniprot": "Q4DVT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVT9"
  },
  {
    "uniprot": "Q4D9K4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9K4"
  },
  {
    "uniprot": "Q4DVE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVE4"
  },
  {
    "uniprot": "Q4DLF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLF1"
  },
  {
    "uniprot": "Q4DF11",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF11"
  },
  {
    "uniprot": "Q4D5L1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5L1"
  },
  {
    "uniprot": "Q4D7X1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7X1"
  },
  {
    "uniprot": "Q4E0M8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0M8"
  },
  {
    "uniprot": "Q4CWU8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWU8"
  },
  {
    "uniprot": "Q4DM06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM06"
  },
  {
    "uniprot": "Q4DZA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZA6"
  },
  {
    "uniprot": "Q4D677",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D677"
  },
  {
    "uniprot": "Q4CT03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT03"
  },
  {
    "uniprot": "Q4CQ28",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ28"
  },
  {
    "uniprot": "Q4DD54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD54"
  },
  {
    "uniprot": "Q4D5W7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5W7"
  },
  {
    "uniprot": "Q4CZG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZG0"
  },
  {
    "uniprot": "Q4CXA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXA1"
  },
  {
    "uniprot": "Q4CT53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT53"
  },
  {
    "uniprot": "Q4CPS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPS1"
  },
  {
    "uniprot": "Q4D9L0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9L0"
  },
  {
    "uniprot": "Q4DAA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAA7"
  },
  {
    "uniprot": "Q4DSD0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSD0"
  },
  {
    "uniprot": "Q4E2S2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2S2"
  },
  {
    "uniprot": "Q4D0Y4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Y4"
  },
  {
    "uniprot": "Q4DCC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCC8"
  },
  {
    "uniprot": "Q4CN66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN66"
  },
  {
    "uniprot": "Q4DYL0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYL0"
  },
  {
    "uniprot": "Q4D3W3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3W3"
  },
  {
    "uniprot": "Q4CUW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUW2"
  },
  {
    "uniprot": "Q4CP08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP08"
  },
  {
    "uniprot": "Q4DD79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD79"
  },
  {
    "uniprot": "Q4CSG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSG6"
  },
  {
    "uniprot": "Q4CPG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPG1"
  },
  {
    "uniprot": "Q4CZ68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ68"
  },
  {
    "uniprot": "Q4D7Z9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7Z9"
  },
  {
    "uniprot": "Q4DQI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQI3"
  },
  {
    "uniprot": "Q4DKX2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKX2"
  },
  {
    "uniprot": "Q4CLX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLX9"
  },
  {
    "uniprot": "Q4D298",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D298"
  },
  {
    "uniprot": "Q4DIC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIC5"
  },
  {
    "uniprot": "Q4D2R7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2R7"
  },
  {
    "uniprot": "Q4DFT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFT2"
  },
  {
    "uniprot": "Q4D316",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D316"
  },
  {
    "uniprot": "Q4CT60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT60"
  },
  {
    "uniprot": "Q4DQF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQF2"
  },
  {
    "uniprot": "Q4CX05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX05"
  },
  {
    "uniprot": "Q4E216",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E216"
  },
  {
    "uniprot": "Q4DE73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE73"
  },
  {
    "uniprot": "Q4E1N0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1N0"
  },
  {
    "uniprot": "Q4CTT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTT2"
  },
  {
    "uniprot": "Q4DIH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIH4"
  },
  {
    "uniprot": "Q4DLQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLQ6"
  },
  {
    "uniprot": "Q4D7I3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7I3"
  },
  {
    "uniprot": "Q4DMF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMF0"
  },
  {
    "uniprot": "Q4CNX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNX3"
  },
  {
    "uniprot": "Q4CNN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNN6"
  },
  {
    "uniprot": "Q4CNI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNI8"
  },
  {
    "uniprot": "Q4DZB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZB0"
  },
  {
    "uniprot": "Q4DNA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNA2"
  },
  {
    "uniprot": "Q4D7V0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7V0"
  },
  {
    "uniprot": "Q4CQ57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ57"
  },
  {
    "uniprot": "Q4DS79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS79"
  },
  {
    "uniprot": "Q4DEP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEP7"
  },
  {
    "uniprot": "Q4D5A5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5A5"
  },
  {
    "uniprot": "Q4DMA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMA9"
  },
  {
    "uniprot": "Q4CTH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTH7"
  },
  {
    "uniprot": "Q4D9W5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9W5"
  },
  {
    "uniprot": "Q4D3Z2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Z2"
  },
  {
    "uniprot": "Q4E441",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E441"
  },
  {
    "uniprot": "Q4CUR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUR6"
  },
  {
    "uniprot": "Q4CWR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWR9"
  },
  {
    "uniprot": "Q4E3Y3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3Y3"
  },
  {
    "uniprot": "Q4D7R2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7R2"
  },
  {
    "uniprot": "Q4CLH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLH1"
  },
  {
    "uniprot": "Q4D2P0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2P0"
  },
  {
    "uniprot": "Q4D256",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D256"
  },
  {
    "uniprot": "Q4D3D7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3D7"
  },
  {
    "uniprot": "Q4DET5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DET5"
  },
  {
    "uniprot": "Q4DJI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJI4"
  },
  {
    "uniprot": "Q4D6W5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6W5"
  },
  {
    "uniprot": "Q4DFX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFX0"
  },
  {
    "uniprot": "Q4CSI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSI5"
  },
  {
    "uniprot": "Q4D166",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D166"
  },
  {
    "uniprot": "Q4CNH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNH0"
  },
  {
    "uniprot": "Q4E1F2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1F2"
  },
  {
    "uniprot": "Q4CYM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYM1"
  },
  {
    "uniprot": "Q4E277",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E277"
  },
  {
    "uniprot": "Q4D4W9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4W9"
  },
  {
    "uniprot": "Q4E3U9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3U9"
  },
  {
    "uniprot": "Q4D005",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D005"
  },
  {
    "uniprot": "Q4DQ87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ87"
  },
  {
    "uniprot": "Q4CVJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVJ0"
  },
  {
    "uniprot": "Q4D044",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D044"
  },
  {
    "uniprot": "Q4CKS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKS2"
  },
  {
    "uniprot": "Q4DDQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDQ3"
  },
  {
    "uniprot": "Q4DXI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXI1"
  },
  {
    "uniprot": "Q4CXK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXK5"
  },
  {
    "uniprot": "Q4CPY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPY2"
  },
  {
    "uniprot": "Q4DLV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLV3"
  },
  {
    "uniprot": "Q4CZD4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZD4"
  },
  {
    "uniprot": "Q4CZ37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ37"
  },
  {
    "uniprot": "Q4DK37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK37"
  },
  {
    "uniprot": "Q4DV50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV50"
  },
  {
    "uniprot": "Q4DGU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGU9"
  },
  {
    "uniprot": "Q4DC47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC47"
  },
  {
    "uniprot": "Q4CXF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXF4"
  },
  {
    "uniprot": "Q4DSF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSF5"
  },
  {
    "uniprot": "Q4D010",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D010"
  },
  {
    "uniprot": "Q4CXP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXP3"
  },
  {
    "uniprot": "Q4DEC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEC2"
  },
  {
    "uniprot": "Q4CP14",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP14"
  },
  {
    "uniprot": "Q4D305",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D305"
  },
  {
    "uniprot": "Q4E1M0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1M0"
  },
  {
    "uniprot": "Q4CLV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLV3"
  },
  {
    "uniprot": "Q4D8L2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8L2"
  },
  {
    "uniprot": "Q4E1C8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1C8"
  },
  {
    "uniprot": "Q4E474",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E474"
  },
  {
    "uniprot": "Q4CU33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU33"
  },
  {
    "uniprot": "Q4DIF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIF9"
  },
  {
    "uniprot": "Q4E5C8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5C8"
  },
  {
    "uniprot": "Q4DSG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSG2"
  },
  {
    "uniprot": "Q4DTA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTA9"
  },
  {
    "uniprot": "Q4DIC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIC1"
  },
  {
    "uniprot": "Q4CT79",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT79"
  },
  {
    "uniprot": "Q4D8K4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8K4"
  },
  {
    "uniprot": "Q4DQG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQG8"
  },
  {
    "uniprot": "Q4CLH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLH6"
  },
  {
    "uniprot": "Q4DTB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTB3"
  },
  {
    "uniprot": "Q4E0R1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0R1"
  },
  {
    "uniprot": "Q4DN44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN44"
  },
  {
    "uniprot": "Q4CR70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR70"
  },
  {
    "uniprot": "Q4DR85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR85"
  },
  {
    "uniprot": "Q4CKH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKH3"
  },
  {
    "uniprot": "Q4D6C7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6C7"
  },
  {
    "uniprot": "Q4D3H1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3H1"
  },
  {
    "uniprot": "Q4E3U4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3U4"
  },
  {
    "uniprot": "Q4DU62",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU62"
  },
  {
    "uniprot": "Q4D8K8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8K8"
  },
  {
    "uniprot": "Q4CT66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CT66"
  },
  {
    "uniprot": "Q4CYL3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYL3"
  },
  {
    "uniprot": "Q4DUE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUE2"
  },
  {
    "uniprot": "Q4DN50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN50"
  },
  {
    "uniprot": "Q4DJG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJG3"
  },
  {
    "uniprot": "Q4DFN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFN4"
  },
  {
    "uniprot": "Q4D449",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D449"
  },
  {
    "uniprot": "Q4DG01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG01"
  },
  {
    "uniprot": "Q4DTR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTR5"
  },
  {
    "uniprot": "Q4DMA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMA2"
  },
  {
    "uniprot": "Q4CUV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUV7"
  },
  {
    "uniprot": "Q4DGJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGJ3"
  },
  {
    "uniprot": "Q4D8I1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8I1"
  },
  {
    "uniprot": "Q4D8I7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8I7"
  },
  {
    "uniprot": "Q4DC96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC96"
  },
  {
    "uniprot": "Q4DEN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEN3"
  },
  {
    "uniprot": "Q4DV96",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV96"
  },
  {
    "uniprot": "Q4DSV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSV4"
  },
  {
    "uniprot": "Q4DBH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBH1"
  },
  {
    "uniprot": "Q4DAH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAH8"
  },
  {
    "uniprot": "Q4DJW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJW0"
  },
  {
    "uniprot": "Q4E3N7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3N7"
  },
  {
    "uniprot": "Q4DUH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUH7"
  },
  {
    "uniprot": "Q4DL94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL94"
  },
  {
    "uniprot": "Q4DHN5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHN5"
  },
  {
    "uniprot": "Q4DYT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYT6"
  },
  {
    "uniprot": "Q4DDF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDF4"
  },
  {
    "uniprot": "Q4DFF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFF9"
  },
  {
    "uniprot": "Q4D729",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D729"
  },
  {
    "uniprot": "Q4CZB5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZB5"
  },
  {
    "uniprot": "Q4CQP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQP5"
  },
  {
    "uniprot": "Q4DVI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVI1"
  },
  {
    "uniprot": "Q4CTB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTB1"
  },
  {
    "uniprot": "Q4DXJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXJ0"
  },
  {
    "uniprot": "Q4DGH4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGH4"
  },
  {
    "uniprot": "Q4E3C9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3C9"
  },
  {
    "uniprot": "Q4CX68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX68"
  },
  {
    "uniprot": "Q4DTS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTS8"
  },
  {
    "uniprot": "Q4DDG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDG1"
  },
  {
    "uniprot": "Q4DS03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS03"
  },
  {
    "uniprot": "Q4D9E9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9E9"
  },
  {
    "uniprot": "Q4CX82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX82"
  },
  {
    "uniprot": "Q4DY76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY76"
  },
  {
    "uniprot": "Q4E1N9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1N9"
  },
  {
    "uniprot": "Q4D9C6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9C6"
  },
  {
    "uniprot": "Q4CS39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS39"
  },
  {
    "uniprot": "Q4CQ00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ00"
  },
  {
    "uniprot": "Q4DS41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS41"
  },
  {
    "uniprot": "Q4DVX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVX8"
  },
  {
    "uniprot": "Q4DJS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJS8"
  },
  {
    "uniprot": "Q4CLR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLR2"
  },
  {
    "uniprot": "Q4CLM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLM4"
  },
  {
    "uniprot": "Q4DWL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWL4"
  },
  {
    "uniprot": "Q4CYC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYC0"
  },
  {
    "uniprot": "Q4CZS1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZS1"
  },
  {
    "uniprot": "Q4CPA5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPA5"
  },
  {
    "uniprot": "Q4DU47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU47"
  },
  {
    "uniprot": "Q4E5X5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5X5"
  },
  {
    "uniprot": "Q4DBP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBP8"
  },
  {
    "uniprot": "Q4DD49",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD49"
  },
  {
    "uniprot": "Q4DZS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZS6"
  },
  {
    "uniprot": "Q4D323",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D323"
  },
  {
    "uniprot": "Q4DYW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYW5"
  },
  {
    "uniprot": "Q4D8J7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8J7"
  },
  {
    "uniprot": "Q4D073",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D073"
  },
  {
    "uniprot": "Q4DB97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB97"
  },
  {
    "uniprot": "Q4CU68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU68"
  },
  {
    "uniprot": "Q4DWW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWW6"
  },
  {
    "uniprot": "Q4DJA8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJA8"
  },
  {
    "uniprot": "Q4DUP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUP5"
  },
  {
    "uniprot": "Q4D473",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D473"
  },
  {
    "uniprot": "Q4CR38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR38"
  },
  {
    "uniprot": "Q4E358",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E358"
  },
  {
    "uniprot": "Q4DNN6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNN6"
  },
  {
    "uniprot": "Q4DIV4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIV4"
  },
  {
    "uniprot": "Q4CX45",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX45"
  },
  {
    "uniprot": "Q4D7P2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7P2"
  },
  {
    "uniprot": "Q4DM61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM61"
  },
  {
    "uniprot": "Q4D3I1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3I1"
  },
  {
    "uniprot": "Q4D6U4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6U4"
  },
  {
    "uniprot": "Q4DV09",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV09"
  },
  {
    "uniprot": "Q4DZ88",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ88"
  },
  {
    "uniprot": "Q4DV68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV68"
  },
  {
    "uniprot": "Q4D846",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D846"
  },
  {
    "uniprot": "Q4CSW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSW5"
  },
  {
    "uniprot": "Q4DMI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMI5"
  },
  {
    "uniprot": "Q4CPM2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPM2"
  },
  {
    "uniprot": "Q4CQA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQA6"
  },
  {
    "uniprot": "Q4DW83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW83"
  },
  {
    "uniprot": "Q4DBW9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBW9"
  },
  {
    "uniprot": "Q4CW08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CW08"
  },
  {
    "uniprot": "Q4E1F9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1F9"
  },
  {
    "uniprot": "Q4CWS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWS8"
  },
  {
    "uniprot": "Q4DID5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DID5"
  },
  {
    "uniprot": "Q4DXS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXS3"
  },
  {
    "uniprot": "Q4DYU7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYU7"
  },
  {
    "uniprot": "Q4DIW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIW4"
  },
  {
    "uniprot": "Q4D9W4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9W4"
  },
  {
    "uniprot": "Q4DCS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCS0"
  },
  {
    "uniprot": "Q4CPG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPG2"
  },
  {
    "uniprot": "Q4DKK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKK2"
  },
  {
    "uniprot": "Q4D8S2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8S2"
  },
  {
    "uniprot": "Q4CUD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUD3"
  },
  {
    "uniprot": "Q4CPI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPI1"
  },
  {
    "uniprot": "Q4D8Z5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Z5"
  },
  {
    "uniprot": "Q4DJT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJT3"
  },
  {
    "uniprot": "Q4DN72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN72"
  },
  {
    "uniprot": "Q4D740",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D740"
  },
  {
    "uniprot": "Q4DDT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDT5"
  },
  {
    "uniprot": "Q4DLP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLP1"
  },
  {
    "uniprot": "Q4DAN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAN0"
  },
  {
    "uniprot": "Q4CNV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNV0"
  },
  {
    "uniprot": "Q4DJU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJU1"
  },
  {
    "uniprot": "Q4DNB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNB6"
  },
  {
    "uniprot": "Q4DN61",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN61"
  },
  {
    "uniprot": "Q4CSJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSJ2"
  },
  {
    "uniprot": "Q4CQG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQG3"
  },
  {
    "uniprot": "Q4DTY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTY4"
  },
  {
    "uniprot": "Q4D822",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D822"
  },
  {
    "uniprot": "Q4DM71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM71"
  },
  {
    "uniprot": "Q4D0S0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0S0"
  },
  {
    "uniprot": "Q4CWI1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWI1"
  },
  {
    "uniprot": "Q4D9Q8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9Q8"
  },
  {
    "uniprot": "Q4E165",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E165"
  },
  {
    "uniprot": "Q4CUM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUM0"
  },
  {
    "uniprot": "Q4DI66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI66"
  },
  {
    "uniprot": "Q4D4H0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4H0"
  },
  {
    "uniprot": "Q4D901",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D901"
  },
  {
    "uniprot": "Q4DFB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFB3"
  },
  {
    "uniprot": "Q4DYU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYU0"
  },
  {
    "uniprot": "Q4DQK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQK5"
  },
  {
    "uniprot": "Q4DY30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY30"
  },
  {
    "uniprot": "Q4D531",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D531"
  },
  {
    "uniprot": "Q4D9L7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9L7"
  },
  {
    "uniprot": "Q4DAB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAB3"
  },
  {
    "uniprot": "Q4DBL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBL8"
  },
  {
    "uniprot": "Q4DW05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW05"
  },
  {
    "uniprot": "Q4DAF2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAF2"
  },
  {
    "uniprot": "Q4DYQ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYQ0"
  },
  {
    "uniprot": "Q4CVP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVP4"
  },
  {
    "uniprot": "Q4DJB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJB0"
  },
  {
    "uniprot": "Q4E0D7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0D7"
  },
  {
    "uniprot": "Q4D104",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D104"
  },
  {
    "uniprot": "Q4E3R7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3R7"
  },
  {
    "uniprot": "Q4DZ80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ80"
  },
  {
    "uniprot": "Q4DQ25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ25"
  },
  {
    "uniprot": "Q4DUQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUQ9"
  },
  {
    "uniprot": "Q4CPF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPF4"
  },
  {
    "uniprot": "Q4DEE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEE5"
  },
  {
    "uniprot": "Q4DL43",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL43"
  },
  {
    "uniprot": "Q4DM23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM23"
  },
  {
    "uniprot": "Q4DQM7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQM7"
  },
  {
    "uniprot": "Q4CN75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN75"
  },
  {
    "uniprot": "Q4D2T8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2T8"
  },
  {
    "uniprot": "Q4DAV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAV5"
  },
  {
    "uniprot": "Q4E5R9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5R9"
  },
  {
    "uniprot": "Q4DAC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAC9"
  },
  {
    "uniprot": "Q4E072",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E072"
  },
  {
    "uniprot": "Q4CP71",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP71"
  },
  {
    "uniprot": "Q4DQF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQF7"
  },
  {
    "uniprot": "Q4DQZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQZ4"
  },
  {
    "uniprot": "Q4CQW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQW4"
  },
  {
    "uniprot": "Q4DV54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV54"
  },
  {
    "uniprot": "Q4DRF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRF4"
  },
  {
    "uniprot": "Q4CM97",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM97"
  },
  {
    "uniprot": "Q4DTD3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTD3"
  },
  {
    "uniprot": "Q4D599",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D599"
  },
  {
    "uniprot": "Q4DK48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK48"
  },
  {
    "uniprot": "Q4DBP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBP5"
  },
  {
    "uniprot": "Q4DLI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLI9"
  },
  {
    "uniprot": "Q4CP27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP27"
  },
  {
    "uniprot": "Q4CYM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYM6"
  },
  {
    "uniprot": "Q4DP55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP55"
  },
  {
    "uniprot": "Q4CWG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWG3"
  },
  {
    "uniprot": "Q4DAK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAK5"
  },
  {
    "uniprot": "Q4CVU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVU9"
  },
  {
    "uniprot": "Q4DI50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI50"
  },
  {
    "uniprot": "Q4DSC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSC1"
  },
  {
    "uniprot": "Q4CTF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTF8"
  },
  {
    "uniprot": "Q4CY40",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY40"
  },
  {
    "uniprot": "Q4DML7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DML7"
  },
  {
    "uniprot": "Q4D4C6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4C6"
  },
  {
    "uniprot": "Q4CXF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXF5"
  },
  {
    "uniprot": "Q4CU18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU18"
  },
  {
    "uniprot": "Q4DG63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG63"
  },
  {
    "uniprot": "Q4D285",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D285"
  },
  {
    "uniprot": "Q4DUM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUM8"
  },
  {
    "uniprot": "Q4DI42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI42"
  },
  {
    "uniprot": "Q4D1K6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1K6"
  },
  {
    "uniprot": "Q4E2Q1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Q1"
  },
  {
    "uniprot": "Q4E326",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E326"
  },
  {
    "uniprot": "Q4DTL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTL9"
  },
  {
    "uniprot": "Q4DW15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW15"
  },
  {
    "uniprot": "Q4DCI8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCI8"
  },
  {
    "uniprot": "Q4DEQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEQ4"
  },
  {
    "uniprot": "Q4DMQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMQ2"
  },
  {
    "uniprot": "Q4E0U6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0U6"
  },
  {
    "uniprot": "Q4CKE9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKE9"
  },
  {
    "uniprot": "Q4DPF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPF0"
  },
  {
    "uniprot": "Q4DA47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA47"
  },
  {
    "uniprot": "Q4DP32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP32"
  },
  {
    "uniprot": "Q4CRA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRA1"
  },
  {
    "uniprot": "Q4E145",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E145"
  },
  {
    "uniprot": "Q4DNP5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNP5"
  },
  {
    "uniprot": "Q4CXY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXY2"
  },
  {
    "uniprot": "Q4DQI9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQI9"
  },
  {
    "uniprot": "Q4D0M3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0M3"
  },
  {
    "uniprot": "Q4CQ87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ87"
  },
  {
    "uniprot": "Q4DEW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEW7"
  },
  {
    "uniprot": "Q4D2N6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2N6"
  },
  {
    "uniprot": "Q4CZ21",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ21"
  },
  {
    "uniprot": "Q4DVP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVP1"
  },
  {
    "uniprot": "Q4DPV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPV7"
  },
  {
    "uniprot": "Q4D427",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D427"
  },
  {
    "uniprot": "Q4CSK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSK2"
  },
  {
    "uniprot": "Q4DKM1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKM1"
  },
  {
    "uniprot": "Q4CTX4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTX4"
  },
  {
    "uniprot": "Q4CK99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CK99"
  },
  {
    "uniprot": "Q4DEI7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEI7"
  },
  {
    "uniprot": "Q4DCI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCI6"
  },
  {
    "uniprot": "Q4CP06",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP06"
  },
  {
    "uniprot": "Q4D024",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D024"
  },
  {
    "uniprot": "Q4DXQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXQ8"
  },
  {
    "uniprot": "Q4DVE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVE7"
  },
  {
    "uniprot": "Q4E0B2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0B2"
  },
  {
    "uniprot": "Q4CUJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUJ4"
  },
  {
    "uniprot": "Q4D033",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D033"
  },
  {
    "uniprot": "Q4CUL7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUL7"
  },
  {
    "uniprot": "Q4D1U0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1U0"
  },
  {
    "uniprot": "Q4DX56",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX56"
  },
  {
    "uniprot": "Q4DZP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZP1"
  },
  {
    "uniprot": "Q4DT23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT23"
  },
  {
    "uniprot": "Q4DJY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJY5"
  },
  {
    "uniprot": "Q4D8E6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8E6"
  },
  {
    "uniprot": "Q4DWZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWZ9"
  },
  {
    "uniprot": "Q4D9I8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9I8"
  },
  {
    "uniprot": "Q4CVE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVE8"
  },
  {
    "uniprot": "Q4D7H6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7H6"
  },
  {
    "uniprot": "Q4E5A2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5A2"
  },
  {
    "uniprot": "Q4CP80",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP80"
  },
  {
    "uniprot": "Q4CSA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSA4"
  },
  {
    "uniprot": "Q4CYH1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYH1"
  },
  {
    "uniprot": "Q4E1D8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1D8"
  },
  {
    "uniprot": "Q4E448",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E448"
  },
  {
    "uniprot": "Q4E2P1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2P1"
  },
  {
    "uniprot": "Q4DT05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT05"
  },
  {
    "uniprot": "Q4DF81",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF81"
  },
  {
    "uniprot": "Q4E1F0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1F0"
  },
  {
    "uniprot": "Q4DM00",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM00"
  },
  {
    "uniprot": "Q4E3N8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3N8"
  },
  {
    "uniprot": "Q4DM58",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM58"
  },
  {
    "uniprot": "Q4DCX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCX8"
  },
  {
    "uniprot": "Q4DA86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA86"
  },
  {
    "uniprot": "Q4D6A5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6A5"
  },
  {
    "uniprot": "Q4D708",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D708"
  },
  {
    "uniprot": "Q4DM52",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM52"
  },
  {
    "uniprot": "Q4DDJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDJ0"
  },
  {
    "uniprot": "Q4DYY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYY5"
  },
  {
    "uniprot": "Q4DEX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEX7"
  },
  {
    "uniprot": "Q4CQJ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQJ2"
  },
  {
    "uniprot": "Q4CZK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZK3"
  },
  {
    "uniprot": "Q4CS36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS36"
  },
  {
    "uniprot": "Q4DCN4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCN4"
  },
  {
    "uniprot": "Q4DB91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB91"
  },
  {
    "uniprot": "Q4DKC0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKC0"
  },
  {
    "uniprot": "Q4E5U1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5U1"
  },
  {
    "uniprot": "Q4D600",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D600"
  },
  {
    "uniprot": "Q4CYY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYY9"
  },
  {
    "uniprot": "Q4D8W5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8W5"
  },
  {
    "uniprot": "Q4DPJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPJ1"
  },
  {
    "uniprot": "Q4DLH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLH0"
  },
  {
    "uniprot": "Q4D4F8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4F8"
  },
  {
    "uniprot": "Q4DL05",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL05"
  },
  {
    "uniprot": "Q4DAL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAL4"
  },
  {
    "uniprot": "Q4DU59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU59"
  },
  {
    "uniprot": "Q4CTZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTZ8"
  },
  {
    "uniprot": "Q4DWI0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWI0"
  },
  {
    "uniprot": "Q4DMF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMF6"
  },
  {
    "uniprot": "Q4DLQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLQ1"
  },
  {
    "uniprot": "Q4D9K1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9K1"
  },
  {
    "uniprot": "Q4D479",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D479"
  },
  {
    "uniprot": "Q4D8B4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8B4"
  },
  {
    "uniprot": "Q4CZC9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZC9"
  },
  {
    "uniprot": "Q4D5X2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5X2"
  },
  {
    "uniprot": "Q4DQP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQP1"
  },
  {
    "uniprot": "Q4DQN1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQN1"
  },
  {
    "uniprot": "Q4CQP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQP4"
  },
  {
    "uniprot": "Q4DHL4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHL4"
  },
  {
    "uniprot": "Q4CXF0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXF0"
  },
  {
    "uniprot": "Q4DCR9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCR9"
  },
  {
    "uniprot": "Q4E4U2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4U2"
  },
  {
    "uniprot": "Q4DJK3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJK3"
  },
  {
    "uniprot": "Q4D7M8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7M8"
  },
  {
    "uniprot": "Q4E4I8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4I8"
  },
  {
    "uniprot": "Q4D383",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D383"
  },
  {
    "uniprot": "Q4DHU0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHU0"
  },
  {
    "uniprot": "Q4DFY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFY2"
  },
  {
    "uniprot": "Q4D195",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D195"
  },
  {
    "uniprot": "Q4DQT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQT1"
  },
  {
    "uniprot": "Q4DPQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPQ6"
  },
  {
    "uniprot": "Q4E4E0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4E0"
  },
  {
    "uniprot": "Q4CU59",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU59"
  },
  {
    "uniprot": "Q4DGQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGQ3"
  },
  {
    "uniprot": "Q4DCA7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCA7"
  },
  {
    "uniprot": "Q4D9J7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9J7"
  },
  {
    "uniprot": "Q4D8J3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8J3"
  },
  {
    "uniprot": "Q4D1R5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1R5"
  },
  {
    "uniprot": "Q4D1V7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1V7"
  },
  {
    "uniprot": "Q4D3N4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3N4"
  },
  {
    "uniprot": "Q4CS15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS15"
  },
  {
    "uniprot": "Q4DS38",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DS38"
  },
  {
    "uniprot": "Q4D1R7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1R7"
  },
  {
    "uniprot": "Q4CVZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVZ4"
  },
  {
    "uniprot": "Q4DVM0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVM0"
  },
  {
    "uniprot": "Q4CSR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSR6"
  },
  {
    "uniprot": "Q4CYK4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYK4"
  },
  {
    "uniprot": "Q4DTS3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTS3"
  },
  {
    "uniprot": "Q4DKB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKB3"
  },
  {
    "uniprot": "Q4CMM8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMM8"
  },
  {
    "uniprot": "Q4D139",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D139"
  },
  {
    "uniprot": "Q4DLF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLF6"
  },
  {
    "uniprot": "Q4DEU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEU1"
  },
  {
    "uniprot": "Q4D3T9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3T9"
  },
  {
    "uniprot": "Q4CYG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYG0"
  },
  {
    "uniprot": "Q4CPT3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPT3"
  },
  {
    "uniprot": "Q4E104",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E104"
  },
  {
    "uniprot": "Q4D5I0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5I0"
  },
  {
    "uniprot": "Q4D6N2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6N2"
  },
  {
    "uniprot": "Q4E230",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E230"
  },
  {
    "uniprot": "Q4E396",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E396"
  },
  {
    "uniprot": "Q4DPR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPR5"
  },
  {
    "uniprot": "Q4CY93",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY93"
  },
  {
    "uniprot": "Q4D5H6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5H6"
  },
  {
    "uniprot": "Q4CRW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRW2"
  },
  {
    "uniprot": "Q4D0H5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0H5"
  },
  {
    "uniprot": "Q4CVI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVI6"
  },
  {
    "uniprot": "Q4D5M5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5M5"
  },
  {
    "uniprot": "Q4CM26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM26"
  },
  {
    "uniprot": "Q4D8U7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8U7"
  },
  {
    "uniprot": "Q4CXJ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXJ6"
  },
  {
    "uniprot": "Q4CSQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSQ1"
  },
  {
    "uniprot": "Q4DUR7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUR7"
  },
  {
    "uniprot": "Q4DT33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT33"
  },
  {
    "uniprot": "Q4D3U6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3U6"
  },
  {
    "uniprot": "Q4E4W7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4W7"
  },
  {
    "uniprot": "Q4D6B0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6B0"
  },
  {
    "uniprot": "Q4DA83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA83"
  },
  {
    "uniprot": "Q4DNG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNG0"
  },
  {
    "uniprot": "Q4DJP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJP8"
  },
  {
    "uniprot": "Q4DUB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUB6"
  },
  {
    "uniprot": "Q4CSJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSJ5"
  },
  {
    "uniprot": "Q4DJM9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJM9"
  },
  {
    "uniprot": "Q4CS78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS78"
  },
  {
    "uniprot": "Q4DJG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJG6"
  },
  {
    "uniprot": "Q4CNK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNK9"
  },
  {
    "uniprot": "Q4DEC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEC8"
  },
  {
    "uniprot": "Q4DPW0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPW0"
  },
  {
    "uniprot": "Q4DU89",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU89"
  },
  {
    "uniprot": "Q4DYV9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYV9"
  },
  {
    "uniprot": "Q4E0N8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0N8"
  },
  {
    "uniprot": "Q4E5D9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5D9"
  },
  {
    "uniprot": "Q4D8A4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8A4"
  },
  {
    "uniprot": "Q4D9F8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9F8"
  },
  {
    "uniprot": "Q4CSK9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSK9"
  },
  {
    "uniprot": "Q4DB64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB64"
  },
  {
    "uniprot": "Q4DE95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE95"
  },
  {
    "uniprot": "Q4CZB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZB2"
  },
  {
    "uniprot": "Q4DGK8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGK8"
  },
  {
    "uniprot": "Q4DVR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVR4"
  },
  {
    "uniprot": "Q4D1Q8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1Q8"
  },
  {
    "uniprot": "Q4CQ33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ33"
  },
  {
    "uniprot": "Q4DYD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYD9"
  },
  {
    "uniprot": "Q4E1Q5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1Q5"
  },
  {
    "uniprot": "Q4CQG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQG4"
  },
  {
    "uniprot": "Q4D996",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D996"
  },
  {
    "uniprot": "Q4DEY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEY9"
  },
  {
    "uniprot": "Q4DWC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWC3"
  },
  {
    "uniprot": "Q4DN54",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN54"
  },
  {
    "uniprot": "Q4DR50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR50"
  },
  {
    "uniprot": "Q4DA25",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA25"
  },
  {
    "uniprot": "Q4CKB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKB1"
  },
  {
    "uniprot": "Q4DCX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCX3"
  },
  {
    "uniprot": "Q4DQP8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQP8"
  },
  {
    "uniprot": "Q4D5U6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5U6"
  },
  {
    "uniprot": "Q4DXP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXP2"
  },
  {
    "uniprot": "Q4DAF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAF9"
  },
  {
    "uniprot": "Q4D9T8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9T8"
  },
  {
    "uniprot": "Q4CY13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY13"
  },
  {
    "uniprot": "Q4D3Q0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Q0"
  },
  {
    "uniprot": "Q4CWD8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWD8"
  },
  {
    "uniprot": "Q4DPY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPY1"
  },
  {
    "uniprot": "Q4D680",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D680"
  },
  {
    "uniprot": "Q4DQW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQW4"
  },
  {
    "uniprot": "Q4D903",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D903"
  },
  {
    "uniprot": "Q4DTW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTW6"
  },
  {
    "uniprot": "Q4D826",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D826"
  },
  {
    "uniprot": "Q4CZN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZN7"
  },
  {
    "uniprot": "Q4E0B3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0B3"
  },
  {
    "uniprot": "Q4DJW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJW5"
  },
  {
    "uniprot": "Q4CRC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRC2"
  },
  {
    "uniprot": "Q4DZ85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ85"
  },
  {
    "uniprot": "Q4DT70",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT70"
  },
  {
    "uniprot": "Q4E3H1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3H1"
  },
  {
    "uniprot": "Q4E2Y1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Y1"
  },
  {
    "uniprot": "Q4DW82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DW82"
  },
  {
    "uniprot": "Q4CYA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYA4"
  },
  {
    "uniprot": "Q4DPD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPD6"
  },
  {
    "uniprot": "Q4D9K3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9K3"
  },
  {
    "uniprot": "Q4DRC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRC8"
  },
  {
    "uniprot": "Q4CXM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXM3"
  },
  {
    "uniprot": "Q4DFT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFT7"
  },
  {
    "uniprot": "Q4CPS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPS4"
  },
  {
    "uniprot": "Q4CQT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQT7"
  },
  {
    "uniprot": "Q4DZX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZX7"
  },
  {
    "uniprot": "Q4CP04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP04"
  },
  {
    "uniprot": "Q4CPW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPW4"
  },
  {
    "uniprot": "Q4DWX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWX3"
  },
  {
    "uniprot": "Q4CX90",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX90"
  },
  {
    "uniprot": "Q4DGJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGJ0"
  },
  {
    "uniprot": "Q4DVX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVX5"
  },
  {
    "uniprot": "Q4D7F9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7F9"
  },
  {
    "uniprot": "Q4E3B0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3B0"
  },
  {
    "uniprot": "Q4E4N3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4N3"
  },
  {
    "uniprot": "Q4DXA2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXA2"
  },
  {
    "uniprot": "Q4DP91",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP91"
  },
  {
    "uniprot": "Q4CQT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQT0"
  },
  {
    "uniprot": "Q4DA33",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA33"
  },
  {
    "uniprot": "Q4E0Q5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0Q5"
  },
  {
    "uniprot": "Q4DN53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN53"
  },
  {
    "uniprot": "Q4D6S4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6S4"
  },
  {
    "uniprot": "Q4DHK1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHK1"
  },
  {
    "uniprot": "Q4CR41",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR41"
  },
  {
    "uniprot": "Q4CYP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYP3"
  },
  {
    "uniprot": "Q4E329",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E329"
  },
  {
    "uniprot": "Q4DNL1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNL1"
  },
  {
    "uniprot": "Q4DLC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLC3"
  },
  {
    "uniprot": "Q4DHT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHT4"
  },
  {
    "uniprot": "Q4DUV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUV2"
  },
  {
    "uniprot": "Q4CWU1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWU1"
  },
  {
    "uniprot": "Q4DI02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI02"
  },
  {
    "uniprot": "Q4E371",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E371"
  },
  {
    "uniprot": "Q4D0Q3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Q3"
  },
  {
    "uniprot": "Q4D1C8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1C8"
  },
  {
    "uniprot": "Q4E0K9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0K9"
  },
  {
    "uniprot": "Q4DP47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP47"
  },
  {
    "uniprot": "Q4DPW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPW1"
  },
  {
    "uniprot": "Q4DK20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK20"
  },
  {
    "uniprot": "Q4CVJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVJ7"
  },
  {
    "uniprot": "Q4DSJ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSJ0"
  },
  {
    "uniprot": "Q4DQW7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQW7"
  },
  {
    "uniprot": "Q4DCP3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCP3"
  },
  {
    "uniprot": "Q4DUG6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUG6"
  },
  {
    "uniprot": "Q4DKM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKM6"
  },
  {
    "uniprot": "Q4E588",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E588"
  },
  {
    "uniprot": "Q4CZE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZE4"
  },
  {
    "uniprot": "Q4D143",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D143"
  },
  {
    "uniprot": "Q4CZS6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZS6"
  },
  {
    "uniprot": "Q4D4L7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4L7"
  },
  {
    "uniprot": "Q4DXY5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXY5"
  },
  {
    "uniprot": "Q4CVX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVX8"
  },
  {
    "uniprot": "Q4DZT0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZT0"
  },
  {
    "uniprot": "Q4CS77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS77"
  },
  {
    "uniprot": "Q4E4B6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4B6"
  },
  {
    "uniprot": "Q4D4D3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4D3"
  },
  {
    "uniprot": "Q4CZA4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZA4"
  },
  {
    "uniprot": "Q4CXJ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXJ3"
  },
  {
    "uniprot": "Q4E642",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E642"
  },
  {
    "uniprot": "Q4CM53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM53"
  },
  {
    "uniprot": "Q4D3I3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3I3"
  },
  {
    "uniprot": "Q4DCS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCS5"
  },
  {
    "uniprot": "Q4DP74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP74"
  },
  {
    "uniprot": "Q4CZJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZJ5"
  },
  {
    "uniprot": "Q4DV95",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV95"
  },
  {
    "uniprot": "Q4DXW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXW1"
  },
  {
    "uniprot": "Q4DLV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLV2"
  },
  {
    "uniprot": "Q4D5F0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5F0"
  },
  {
    "uniprot": "Q4D130",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D130"
  },
  {
    "uniprot": "Q4DU50",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU50"
  },
  {
    "uniprot": "Q4DEW1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEW1"
  },
  {
    "uniprot": "Q4DSU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSU9"
  },
  {
    "uniprot": "Q4DE18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE18"
  },
  {
    "uniprot": "Q4CKJ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKJ1"
  },
  {
    "uniprot": "Q4D1V4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1V4"
  },
  {
    "uniprot": "Q4CUF4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUF4"
  },
  {
    "uniprot": "Q4E2Y3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Y3"
  },
  {
    "uniprot": "Q4D981",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D981"
  },
  {
    "uniprot": "Q4D0X8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0X8"
  },
  {
    "uniprot": "Q4DWK7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWK7"
  },
  {
    "uniprot": "Q4DEG9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEG9"
  },
  {
    "uniprot": "Q4CN10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN10"
  },
  {
    "uniprot": "Q4DT76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT76"
  },
  {
    "uniprot": "Q4CXT1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXT1"
  },
  {
    "uniprot": "Q4DMN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMN9"
  },
  {
    "uniprot": "Q4CP10",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP10"
  },
  {
    "uniprot": "Q4D4Z8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Z8"
  },
  {
    "uniprot": "Q4DYM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYM4"
  },
  {
    "uniprot": "Q4DKU9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKU9"
  },
  {
    "uniprot": "Q4DCI5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCI5"
  },
  {
    "uniprot": "Q4CUU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUU2"
  },
  {
    "uniprot": "Q4CTG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTG8"
  },
  {
    "uniprot": "Q4E2Z6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2Z6"
  },
  {
    "uniprot": "Q4CQF8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQF8"
  },
  {
    "uniprot": "Q4DCC5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCC5"
  },
  {
    "uniprot": "Q4DR29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR29"
  },
  {
    "uniprot": "Q4D8A8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8A8"
  },
  {
    "uniprot": "Q4D8F4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8F4"
  },
  {
    "uniprot": "Q4CZV8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZV8"
  },
  {
    "uniprot": "Q4DUW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUW5"
  },
  {
    "uniprot": "Q4DLL9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLL9"
  },
  {
    "uniprot": "Q4CM48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM48"
  },
  {
    "uniprot": "Q4D3V0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3V0"
  },
  {
    "uniprot": "Q4CN85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN85"
  },
  {
    "uniprot": "Q4CSM4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSM4"
  },
  {
    "uniprot": "Q4CKE2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKE2"
  },
  {
    "uniprot": "Q4D781",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D781"
  },
  {
    "uniprot": "Q4CXG8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXG8"
  },
  {
    "uniprot": "Q4DM42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM42"
  },
  {
    "uniprot": "Q4DJ75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ75"
  },
  {
    "uniprot": "Q4DBV0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBV0"
  },
  {
    "uniprot": "Q4CY68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY68"
  },
  {
    "uniprot": "Q4E2N9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2N9"
  },
  {
    "uniprot": "Q4D3W9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3W9"
  },
  {
    "uniprot": "Q4E2U8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2U8"
  },
  {
    "uniprot": "Q4DKP4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKP4"
  },
  {
    "uniprot": "Q4CNS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNS9"
  },
  {
    "uniprot": "Q4D949",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D949"
  },
  {
    "uniprot": "Q4E605",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E605"
  },
  {
    "uniprot": "Q4DE77",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DE77"
  },
  {
    "uniprot": "Q4DRW4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRW4"
  },
  {
    "uniprot": "Q4DWG2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWG2"
  },
  {
    "uniprot": "Q4CYQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYQ9"
  },
  {
    "uniprot": "Q4DVE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVE6"
  },
  {
    "uniprot": "Q4E5F2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5F2"
  },
  {
    "uniprot": "Q4E333",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E333"
  },
  {
    "uniprot": "Q4CMQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMQ4"
  },
  {
    "uniprot": "Q4DZS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZS8"
  },
  {
    "uniprot": "Q4DU72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU72"
  },
  {
    "uniprot": "Q4DV34",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV34"
  },
  {
    "uniprot": "Q4D701",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D701"
  },
  {
    "uniprot": "Q4DRS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRS9"
  },
  {
    "uniprot": "Q4DAV2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAV2"
  },
  {
    "uniprot": "Q4DPG1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPG1"
  },
  {
    "uniprot": "Q4CNU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNU3"
  },
  {
    "uniprot": "Q4DVJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVJ4"
  },
  {
    "uniprot": "Q4CMF6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMF6"
  },
  {
    "uniprot": "Q4DSL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSL8"
  },
  {
    "uniprot": "Q4DHF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHF7"
  },
  {
    "uniprot": "Q4DAN2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAN2"
  },
  {
    "uniprot": "Q4CUA9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUA9"
  },
  {
    "uniprot": "Q4DRT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRT4"
  },
  {
    "uniprot": "Q4CLR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLR0"
  },
  {
    "uniprot": "Q4DRW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRW2"
  },
  {
    "uniprot": "Q4E4V2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4V2"
  },
  {
    "uniprot": "Q4DN04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN04"
  },
  {
    "uniprot": "Q4CS18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CS18"
  },
  {
    "uniprot": "Q4CMB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMB8"
  },
  {
    "uniprot": "Q4DBX3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBX3"
  },
  {
    "uniprot": "Q4CVR5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVR5"
  },
  {
    "uniprot": "Q4D764",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D764"
  },
  {
    "uniprot": "Q4D8T2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8T2"
  },
  {
    "uniprot": "Q4DPJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPJ7"
  },
  {
    "uniprot": "Q4D4G5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4G5"
  },
  {
    "uniprot": "Q4D3M7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3M7"
  },
  {
    "uniprot": "Q4CSF1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSF1"
  },
  {
    "uniprot": "Q4D7H1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7H1"
  },
  {
    "uniprot": "Q4CR27",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR27"
  },
  {
    "uniprot": "Q4E1P9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1P9"
  },
  {
    "uniprot": "Q4DX47",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DX47"
  },
  {
    "uniprot": "Q4DU84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU84"
  },
  {
    "uniprot": "Q4DL36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL36"
  },
  {
    "uniprot": "Q4D683",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D683"
  },
  {
    "uniprot": "Q4DFI2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFI2"
  },
  {
    "uniprot": "Q4CNX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNX9"
  },
  {
    "uniprot": "Q4DGQ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGQ4"
  },
  {
    "uniprot": "Q4D2N2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2N2"
  },
  {
    "uniprot": "Q4DIV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIV5"
  },
  {
    "uniprot": "Q4E022",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E022"
  },
  {
    "uniprot": "Q4D1S8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1S8"
  },
  {
    "uniprot": "Q4DF92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF92"
  },
  {
    "uniprot": "Q4CY66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CY66"
  },
  {
    "uniprot": "Q4DXS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXS8"
  },
  {
    "uniprot": "Q4DZ17",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ17"
  },
  {
    "uniprot": "Q4E0J6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0J6"
  },
  {
    "uniprot": "Q4DSX5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSX5"
  },
  {
    "uniprot": "Q4CRG4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRG4"
  },
  {
    "uniprot": "Q4DD51",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD51"
  },
  {
    "uniprot": "Q4D1V9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1V9"
  },
  {
    "uniprot": "Q4CRY4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRY4"
  },
  {
    "uniprot": "Q4DCG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCG0"
  },
  {
    "uniprot": "Q4DC46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC46"
  },
  {
    "uniprot": "Q4CUT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUT4"
  },
  {
    "uniprot": "Q4E031",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E031"
  },
  {
    "uniprot": "Q4CR44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR44"
  },
  {
    "uniprot": "Q4E0C4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0C4"
  },
  {
    "uniprot": "Q4D7A1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7A1"
  },
  {
    "uniprot": "Q4CN94",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN94"
  },
  {
    "uniprot": "Q4D0I0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0I0"
  },
  {
    "uniprot": "Q4DY26",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY26"
  },
  {
    "uniprot": "Q4E3I2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3I2"
  },
  {
    "uniprot": "Q4DHY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHY1"
  },
  {
    "uniprot": "Q4E258",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E258"
  },
  {
    "uniprot": "Q4CZ75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ75"
  },
  {
    "uniprot": "Q4DR08",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR08"
  },
  {
    "uniprot": "Q4D577",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D577"
  },
  {
    "uniprot": "Q4CMX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMX9"
  },
  {
    "uniprot": "Q4CQ83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ83"
  },
  {
    "uniprot": "Q4DUT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUT7"
  },
  {
    "uniprot": "Q4DDX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDX9"
  },
  {
    "uniprot": "Q4CYW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYW8"
  },
  {
    "uniprot": "Q4DWI4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWI4"
  },
  {
    "uniprot": "Q4DP48",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DP48"
  },
  {
    "uniprot": "Q4D3G6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3G6"
  },
  {
    "uniprot": "Q4DTC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTC8"
  },
  {
    "uniprot": "Q4CXM6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXM6"
  },
  {
    "uniprot": "Q4DBB6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBB6"
  },
  {
    "uniprot": "Q4DWS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWS9"
  },
  {
    "uniprot": "Q4DSE4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSE4"
  },
  {
    "uniprot": "Q4DJ82",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJ82"
  },
  {
    "uniprot": "Q4DJS4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJS4"
  },
  {
    "uniprot": "Q4DA68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA68"
  },
  {
    "uniprot": "Q4DWZ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWZ4"
  },
  {
    "uniprot": "Q4D722",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D722"
  },
  {
    "uniprot": "Q4DNF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNF5"
  },
  {
    "uniprot": "Q4DY85",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY85"
  },
  {
    "uniprot": "Q4DDP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDP0"
  },
  {
    "uniprot": "Q4DNH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNH7"
  },
  {
    "uniprot": "Q4DFI6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFI6"
  },
  {
    "uniprot": "Q4DV83",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV83"
  },
  {
    "uniprot": "Q4DQ60",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQ60"
  },
  {
    "uniprot": "Q4DD87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD87"
  },
  {
    "uniprot": "Q4CQ55",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ55"
  },
  {
    "uniprot": "Q4E0U2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0U2"
  },
  {
    "uniprot": "Q4DGN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGN3"
  },
  {
    "uniprot": "Q4D3R1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3R1"
  },
  {
    "uniprot": "Q4CR72",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR72"
  },
  {
    "uniprot": "Q4DIG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIG0"
  },
  {
    "uniprot": "Q4DGY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGY9"
  },
  {
    "uniprot": "Q4D4V5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4V5"
  },
  {
    "uniprot": "Q4D9W1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9W1"
  },
  {
    "uniprot": "Q4DQN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQN7"
  },
  {
    "uniprot": "Q4CQ31",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ31"
  },
  {
    "uniprot": "Q4DGP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGP7"
  },
  {
    "uniprot": "Q4DCJ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCJ7"
  },
  {
    "uniprot": "Q4D4X7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4X7"
  },
  {
    "uniprot": "Q4DRQ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DRQ8"
  },
  {
    "uniprot": "Q4D743",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D743"
  },
  {
    "uniprot": "Q4D422",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D422"
  },
  {
    "uniprot": "Q4CV73",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CV73"
  },
  {
    "uniprot": "Q4D6A6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6A6"
  },
  {
    "uniprot": "Q4DVV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVV3"
  },
  {
    "uniprot": "Q4DV99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV99"
  },
  {
    "uniprot": "Q4DHR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHR4"
  },
  {
    "uniprot": "Q4DDB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDB2"
  },
  {
    "uniprot": "Q4CNT9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNT9"
  },
  {
    "uniprot": "Q4CPQ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPQ5"
  },
  {
    "uniprot": "Q4D4T6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4T6"
  },
  {
    "uniprot": "Q4CL36",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL36"
  },
  {
    "uniprot": "Q4D5X1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5X1"
  },
  {
    "uniprot": "Q4CK86",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CK86"
  },
  {
    "uniprot": "Q4DR98",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DR98"
  },
  {
    "uniprot": "Q4E3J1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3J1"
  },
  {
    "uniprot": "Q4DY87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY87"
  },
  {
    "uniprot": "Q4D4Z9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4Z9"
  },
  {
    "uniprot": "Q4CLW5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLW5"
  },
  {
    "uniprot": "Q4DGL8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGL8"
  },
  {
    "uniprot": "Q4DXE3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXE3"
  },
  {
    "uniprot": "Q4DZP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZP6"
  },
  {
    "uniprot": "Q4D9W2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9W2"
  },
  {
    "uniprot": "Q4CN44",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN44"
  },
  {
    "uniprot": "Q4DVE5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVE5"
  },
  {
    "uniprot": "Q4D272",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D272"
  },
  {
    "uniprot": "Q4E5Y3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5Y3"
  },
  {
    "uniprot": "Q4DAX6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAX6"
  },
  {
    "uniprot": "Q4CPV3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPV3"
  },
  {
    "uniprot": "Q4DAR2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAR2"
  },
  {
    "uniprot": "Q4CQH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQH7"
  },
  {
    "uniprot": "Q4DET2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DET2"
  },
  {
    "uniprot": "Q4D273",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D273"
  },
  {
    "uniprot": "Q4DIS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIS8"
  },
  {
    "uniprot": "Q4DWH0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWH0"
  },
  {
    "uniprot": "Q4DSR4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSR4"
  },
  {
    "uniprot": "Q4E0J2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0J2"
  },
  {
    "uniprot": "Q4E222",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E222"
  },
  {
    "uniprot": "Q4D2C5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2C5"
  },
  {
    "uniprot": "Q4E5V0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E5V0"
  },
  {
    "uniprot": "Q4D0Z5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0Z5"
  },
  {
    "uniprot": "Q4CM78",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM78"
  },
  {
    "uniprot": "Q4CMJ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMJ8"
  },
  {
    "uniprot": "Q4DN13",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN13"
  },
  {
    "uniprot": "Q4DME3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DME3"
  },
  {
    "uniprot": "Q4DPP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPP1"
  },
  {
    "uniprot": "Q4DM30",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM30"
  },
  {
    "uniprot": "Q4DMC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMC7"
  },
  {
    "uniprot": "Q4DI24",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI24"
  },
  {
    "uniprot": "Q4D151",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D151"
  },
  {
    "uniprot": "Q4CU01",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU01"
  },
  {
    "uniprot": "Q4D9N3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9N3"
  },
  {
    "uniprot": "Q4CMH7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMH7"
  },
  {
    "uniprot": "Q4E2J3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2J3"
  },
  {
    "uniprot": "Q4CN37",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN37"
  },
  {
    "uniprot": "Q4D5R1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5R1"
  },
  {
    "uniprot": "Q4D4H8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4H8"
  },
  {
    "uniprot": "Q4DFC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFC2"
  },
  {
    "uniprot": "Q4D0A9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0A9"
  },
  {
    "uniprot": "Q4D5V0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5V0"
  },
  {
    "uniprot": "Q4DGI3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGI3"
  },
  {
    "uniprot": "Q4DN16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DN16"
  },
  {
    "uniprot": "Q4D1M0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1M0"
  },
  {
    "uniprot": "Q4DV74",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV74"
  },
  {
    "uniprot": "Q4CYS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYS9"
  },
  {
    "uniprot": "Q4DEP2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEP2"
  },
  {
    "uniprot": "Q4DQC2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQC2"
  },
  {
    "uniprot": "Q4CPN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPN0"
  },
  {
    "uniprot": "Q4CUC8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUC8"
  },
  {
    "uniprot": "Q4DC39",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DC39"
  },
  {
    "uniprot": "Q4CM22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CM22"
  },
  {
    "uniprot": "Q4CLC6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLC6"
  },
  {
    "uniprot": "Q4CKF9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKF9"
  },
  {
    "uniprot": "Q4E2M3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E2M3"
  },
  {
    "uniprot": "Q4DLC7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLC7"
  },
  {
    "uniprot": "Q4CUQ3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUQ3"
  },
  {
    "uniprot": "Q4D9H8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9H8"
  },
  {
    "uniprot": "Q4E206",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E206"
  },
  {
    "uniprot": "Q4CQ42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQ42"
  },
  {
    "uniprot": "Q4DBB8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBB8"
  },
  {
    "uniprot": "Q4DMY6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMY6"
  },
  {
    "uniprot": "Q4DCR1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCR1"
  },
  {
    "uniprot": "Q4D939",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D939"
  },
  {
    "uniprot": "Q4DHQ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHQ9"
  },
  {
    "uniprot": "Q4DZ87",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZ87"
  },
  {
    "uniprot": "Q4CR53",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CR53"
  },
  {
    "uniprot": "Q4CPN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPN3"
  },
  {
    "uniprot": "Q4DF16",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DF16"
  },
  {
    "uniprot": "Q4DGL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGL2"
  },
  {
    "uniprot": "Q4E1L2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1L2"
  },
  {
    "uniprot": "Q4D4G6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4G6"
  },
  {
    "uniprot": "Q4DWE7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWE7"
  },
  {
    "uniprot": "Q4DMN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMN7"
  },
  {
    "uniprot": "Q4D4N5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4N5"
  },
  {
    "uniprot": "Q4D712",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D712"
  },
  {
    "uniprot": "Q4CXG3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXG3"
  },
  {
    "uniprot": "Q4D4S5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D4S5"
  },
  {
    "uniprot": "Q4D5K1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5K1"
  },
  {
    "uniprot": "Q4DHL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHL6"
  },
  {
    "uniprot": "Q4DV64",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DV64"
  },
  {
    "uniprot": "Q4CYP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYP1"
  },
  {
    "uniprot": "Q4CSY2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSY2"
  },
  {
    "uniprot": "Q4D820",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D820"
  },
  {
    "uniprot": "Q4DUT5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUT5"
  },
  {
    "uniprot": "Q4E631",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E631"
  },
  {
    "uniprot": "Q4E1D0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1D0"
  },
  {
    "uniprot": "Q4DAN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAN9"
  },
  {
    "uniprot": "Q4DBQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBQ2"
  },
  {
    "uniprot": "Q4CKE6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKE6"
  },
  {
    "uniprot": "Q4CTU2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTU2"
  },
  {
    "uniprot": "Q4E4Z2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4Z2"
  },
  {
    "uniprot": "Q4DMG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMG0"
  },
  {
    "uniprot": "Q4DXW2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXW2"
  },
  {
    "uniprot": "Q4DBZ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBZ7"
  },
  {
    "uniprot": "Q4D8D8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8D8"
  },
  {
    "uniprot": "Q4CNL2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNL2"
  },
  {
    "uniprot": "Q4DQK6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQK6"
  },
  {
    "uniprot": "Q4DEX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEX0"
  },
  {
    "uniprot": "Q4DIQ1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIQ1"
  },
  {
    "uniprot": "Q4CLZ0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLZ0"
  },
  {
    "uniprot": "Q4CNK2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNK2"
  },
  {
    "uniprot": "Q4CWQ7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWQ7"
  },
  {
    "uniprot": "Q4CYH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYH9"
  },
  {
    "uniprot": "Q4DAZ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAZ2"
  },
  {
    "uniprot": "Q4DWT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DWT2"
  },
  {
    "uniprot": "Q4CX15",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX15"
  },
  {
    "uniprot": "Q4DFV5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFV5"
  },
  {
    "uniprot": "Q4D593",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D593"
  },
  {
    "uniprot": "Q4E1N6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1N6"
  },
  {
    "uniprot": "Q4D0C4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D0C4"
  },
  {
    "uniprot": "Q4DD02",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD02"
  },
  {
    "uniprot": "Q4D3S7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3S7"
  },
  {
    "uniprot": "Q4DU22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DU22"
  },
  {
    "uniprot": "Q4DPN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPN8"
  },
  {
    "uniprot": "Q4DTK0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTK0"
  },
  {
    "uniprot": "Q4DZX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZX8"
  },
  {
    "uniprot": "Q4CUD9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CUD9"
  },
  {
    "uniprot": "Q4D964",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D964"
  },
  {
    "uniprot": "Q4CKE1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKE1"
  },
  {
    "uniprot": "Q4DBN3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBN3"
  },
  {
    "uniprot": "Q4D114",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D114"
  },
  {
    "uniprot": "Q4DK69",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DK69"
  },
  {
    "uniprot": "Q4D3J2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3J2"
  },
  {
    "uniprot": "Q4CSQ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CSQ6"
  },
  {
    "uniprot": "Q4CRT4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRT4"
  },
  {
    "uniprot": "Q4DPS9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DPS9"
  },
  {
    "uniprot": "Q4D1W4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1W4"
  },
  {
    "uniprot": "Q4DZN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZN9"
  },
  {
    "uniprot": "Q4DGR6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGR6"
  },
  {
    "uniprot": "Q4DY68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY68"
  },
  {
    "uniprot": "Q4CXF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXF3"
  },
  {
    "uniprot": "Q4D966",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D966"
  },
  {
    "uniprot": "Q4DCF7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCF7"
  },
  {
    "uniprot": "Q4CXA6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXA6"
  },
  {
    "uniprot": "Q4DAN7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAN7"
  },
  {
    "uniprot": "Q4E027",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E027"
  },
  {
    "uniprot": "Q4CZ22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ22"
  },
  {
    "uniprot": "Q4CL22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL22"
  },
  {
    "uniprot": "Q4DHC3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHC3"
  },
  {
    "uniprot": "Q4DT42",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT42"
  },
  {
    "uniprot": "Q4DEB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEB7"
  },
  {
    "uniprot": "Q4D112",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D112"
  },
  {
    "uniprot": "Q4E1J1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1J1"
  },
  {
    "uniprot": "Q4DB76",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DB76"
  },
  {
    "uniprot": "Q4DDS0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDS0"
  },
  {
    "uniprot": "Q4CNS8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNS8"
  },
  {
    "uniprot": "Q4CP18",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP18"
  },
  {
    "uniprot": "Q4D401",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D401"
  },
  {
    "uniprot": "Q4DUF5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUF5"
  },
  {
    "uniprot": "Q4CZ63",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZ63"
  },
  {
    "uniprot": "Q4DMB4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DMB4"
  },
  {
    "uniprot": "Q4D6J7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6J7"
  },
  {
    "uniprot": "Q4DKW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DKW6"
  },
  {
    "uniprot": "Q4DGT2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DGT2"
  },
  {
    "uniprot": "Q4D302",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D302"
  },
  {
    "uniprot": "Q4E1P5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1P5"
  },
  {
    "uniprot": "Q4DYB1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DYB1"
  },
  {
    "uniprot": "Q4DFV7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFV7"
  },
  {
    "uniprot": "Q4D1F1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D1F1"
  },
  {
    "uniprot": "Q4CLY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLY9"
  },
  {
    "uniprot": "Q4DY57",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY57"
  },
  {
    "uniprot": "Q4D7B7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D7B7"
  },
  {
    "uniprot": "Q4DJC1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJC1"
  },
  {
    "uniprot": "Q4DG46",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DG46"
  },
  {
    "uniprot": "Q4E0U4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E0U4"
  },
  {
    "uniprot": "Q4CX20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CX20"
  },
  {
    "uniprot": "Q4CL29",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CL29"
  },
  {
    "uniprot": "Q4E3G1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3G1"
  },
  {
    "uniprot": "Q4DHX0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHX0"
  },
  {
    "uniprot": "Q4CTZ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CTZ5"
  },
  {
    "uniprot": "Q4CP99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CP99"
  },
  {
    "uniprot": "Q4DH19",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH19"
  },
  {
    "uniprot": "Q4CLX8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLX8"
  },
  {
    "uniprot": "Q4D8S8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8S8"
  },
  {
    "uniprot": "Q4D8Q2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8Q2"
  },
  {
    "uniprot": "Q4CNH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNH9"
  },
  {
    "uniprot": "Q4CVR0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CVR0"
  },
  {
    "uniprot": "Q4CMX7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CMX7"
  },
  {
    "uniprot": "Q4E481",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E481"
  },
  {
    "uniprot": "Q4DHE8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DHE8"
  },
  {
    "uniprot": "Q4DVH8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DVH8"
  },
  {
    "uniprot": "Q4DDD5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDD5"
  },
  {
    "uniprot": "Q4DLW6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLW6"
  },
  {
    "uniprot": "Q4D9K8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D9K8"
  },
  {
    "uniprot": "Q4CZW8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZW8"
  },
  {
    "uniprot": "Q4DA32",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA32"
  },
  {
    "uniprot": "Q4CLH2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLH2"
  },
  {
    "uniprot": "Q4DEQ2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DEQ2"
  },
  {
    "uniprot": "Q4DBN8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DBN8"
  },
  {
    "uniprot": "Q4D3T6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3T6"
  },
  {
    "uniprot": "Q4DD04",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD04"
  },
  {
    "uniprot": "Q4CRP1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRP1"
  },
  {
    "uniprot": "Q4DDT6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DDT6"
  },
  {
    "uniprot": "Q4E3A8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3A8"
  },
  {
    "uniprot": "Q4DI92",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DI92"
  },
  {
    "uniprot": "Q4CPP6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CPP6"
  },
  {
    "uniprot": "Q4DNF3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNF3"
  },
  {
    "uniprot": "Q4D3Z5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3Z5"
  },
  {
    "uniprot": "Q4D3W2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D3W2"
  },
  {
    "uniprot": "Q4DH35",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DH35"
  },
  {
    "uniprot": "Q4D343",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D343"
  },
  {
    "uniprot": "Q4CYA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CYA1"
  },
  {
    "uniprot": "Q4D6F1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D6F1"
  },
  {
    "uniprot": "Q4CWU3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CWU3"
  },
  {
    "uniprot": "Q4DLZ9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLZ9"
  },
  {
    "uniprot": "Q4DM03",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DM03"
  },
  {
    "uniprot": "Q4D5B6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5B6"
  },
  {
    "uniprot": "Q4CZZ6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZZ6"
  },
  {
    "uniprot": "Q4DA99",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DA99"
  },
  {
    "uniprot": "Q4CQL6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQL6"
  },
  {
    "uniprot": "Q4CQP0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQP0"
  },
  {
    "uniprot": "Q4D5T1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D5T1"
  },
  {
    "uniprot": "Q4DD68",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DD68"
  },
  {
    "uniprot": "Q4CKB7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKB7"
  },
  {
    "uniprot": "Q4E562",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E562"
  },
  {
    "uniprot": "Q4D765",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D765"
  },
  {
    "uniprot": "Q4D8E2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D8E2"
  },
  {
    "uniprot": "Q4D759",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D759"
  },
  {
    "uniprot": "Q4DFZ8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFZ8"
  },
  {
    "uniprot": "Q4CN84",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CN84"
  },
  {
    "uniprot": "Q4D937",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D937"
  },
  {
    "uniprot": "Q4D2K4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2K4"
  },
  {
    "uniprot": "Q4D2V8",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2V8"
  },
  {
    "uniprot": "Q4CU23",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU23"
  },
  {
    "uniprot": "Q4DZK5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DZK5"
  },
  {
    "uniprot": "Q4CRH9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CRH9"
  },
  {
    "uniprot": "Q4CKN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CKN0"
  },
  {
    "uniprot": "Q4CNY1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CNY1"
  },
  {
    "uniprot": "Q4DUY9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DUY9"
  },
  {
    "uniprot": "Q4DXM3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DXM3"
  },
  {
    "uniprot": "Q4E437",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E437"
  },
  {
    "uniprot": "Q4DCS2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DCS2"
  },
  {
    "uniprot": "Q4DLU5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLU5"
  },
  {
    "uniprot": "Q4DSH3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSH3"
  },
  {
    "uniprot": "Q4E256",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E256"
  },
  {
    "uniprot": "Q4DY75",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DY75"
  },
  {
    "uniprot": "Q4DQT7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DQT7"
  },
  {
    "uniprot": "Q4DL20",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DL20"
  },
  {
    "uniprot": "Q4DJB3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DJB3"
  },
  {
    "uniprot": "Q4CZD6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CZD6"
  },
  {
    "uniprot": "Q4CQH6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQH6"
  },
  {
    "uniprot": "Q4E3A2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E3A2"
  },
  {
    "uniprot": "Q4CQN0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CQN0"
  },
  {
    "uniprot": "Q4DT22",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DT22"
  },
  {
    "uniprot": "Q4CLA1",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CLA1"
  },
  {
    "uniprot": "Q4D556",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D556"
  },
  {
    "uniprot": "Q4CU66",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CU66"
  },
  {
    "uniprot": "Q4DNB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNB0"
  },
  {
    "uniprot": "Q4DIP7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DIP7"
  },
  {
    "uniprot": "Q4CXX9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXX9"
  },
  {
    "uniprot": "Q4DLB0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLB0"
  },
  {
    "uniprot": "Q4D521",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D521"
  },
  {
    "uniprot": "Q4CXG0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4CXG0"
  },
  {
    "uniprot": "Q4E1W6",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E1W6"
  },
  {
    "uniprot": "Q4DNA3",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DNA3"
  },
  {
    "uniprot": "Q4DSS5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DSS5"
  },
  {
    "uniprot": "Q4DTN9",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DTN9"
  },
  {
    "uniprot": "Q4DFJ4",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DFJ4"
  },
  {
    "uniprot": "Q4D2Q0",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4D2Q0"
  },
  {
    "uniprot": "Q4E313",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E313"
  },
  {
    "uniprot": "Q4E4G7",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E4G7"
  },
  {
    "uniprot": "Q4E617",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4E617"
  },
  {
    "uniprot": "Q4DLB2",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DLB2"
  },
  {
    "uniprot": "Q4DAJ5",
    "species": "Trypanosoma cruzi",
    "disease": "Chagas disease",
    "results": "/results/Q4DAJ5"
  },
  {
    "uniprot": "Q8I2C9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2C9"
  },
  {
    "uniprot": "A0A5K1K9E3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9E3"
  },
  {
    "uniprot": "Q8IJF4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJF4"
  },
  {
    "uniprot": "A0A140KXX4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A140KXX4"
  },
  {
    "uniprot": "Q8IAV5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAV5"
  },
  {
    "uniprot": "Q8ILL8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILL8"
  },
  {
    "uniprot": "Q8IKC1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKC1"
  },
  {
    "uniprot": "C0H4V3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4V3"
  },
  {
    "uniprot": "Q8IBR8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBR8"
  },
  {
    "uniprot": "Q8IBL8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBL8"
  },
  {
    "uniprot": "Q8IC49",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC49"
  },
  {
    "uniprot": "Q8ILM7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILM7"
  },
  {
    "uniprot": "Q8IBM4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBM4"
  },
  {
    "uniprot": "Q8I082",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I082"
  },
  {
    "uniprot": "C0H4N3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4N3"
  },
  {
    "uniprot": "Q8I439",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I439"
  },
  {
    "uniprot": "Q8IM45",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM45"
  },
  {
    "uniprot": "Q8ILB2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILB2"
  },
  {
    "uniprot": "Q8IKB1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKB1"
  },
  {
    "uniprot": "A0A144A0Z5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0Z5"
  },
  {
    "uniprot": "Q8IKW3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKW3"
  },
  {
    "uniprot": "C0H4K5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4K5"
  },
  {
    "uniprot": "O97291",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97291"
  },
  {
    "uniprot": "Q8I1Y6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Y6"
  },
  {
    "uniprot": "Q8IE83",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE83"
  },
  {
    "uniprot": "C6KSY9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSY9"
  },
  {
    "uniprot": "Q8I2B3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2B3"
  },
  {
    "uniprot": "Q8II58",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II58"
  },
  {
    "uniprot": "Q8I572",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I572"
  },
  {
    "uniprot": "Q8ILQ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILQ3"
  },
  {
    "uniprot": "Q8I635",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I635"
  },
  {
    "uniprot": "Q8I315",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I315"
  },
  {
    "uniprot": "Q8IJ43",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ43"
  },
  {
    "uniprot": "C6KTF9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTF9"
  },
  {
    "uniprot": "C6S3D3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3D3"
  },
  {
    "uniprot": "C6KSZ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSZ1"
  },
  {
    "uniprot": "O97280",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97280"
  },
  {
    "uniprot": "Q8IKX7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKX7"
  },
  {
    "uniprot": "Q8IBN3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBN3"
  },
  {
    "uniprot": "A0A143ZWW4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWW4"
  },
  {
    "uniprot": "Q8IKZ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKZ5"
  },
  {
    "uniprot": "C0H499",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H499"
  },
  {
    "uniprot": "C6KTC8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTC8"
  },
  {
    "uniprot": "Q8I563",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I563"
  },
  {
    "uniprot": "Q8II15",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II15"
  },
  {
    "uniprot": "Q8IAN5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAN5"
  },
  {
    "uniprot": "Q8IEL8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEL8"
  },
  {
    "uniprot": "Q8IC20",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC20"
  },
  {
    "uniprot": "Q8I1X6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1X6"
  },
  {
    "uniprot": "A0A5K1K8S0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8S0"
  },
  {
    "uniprot": "Q8I3E1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3E1"
  },
  {
    "uniprot": "Q8IBD0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBD0"
  },
  {
    "uniprot": "Q8I616",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I616"
  },
  {
    "uniprot": "Q8IKC7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKC7"
  },
  {
    "uniprot": "B9ZSH9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSH9"
  },
  {
    "uniprot": "Q9U0K9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0K9"
  },
  {
    "uniprot": "Q8IK23",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK23"
  },
  {
    "uniprot": "A0A5K1K967",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K967"
  },
  {
    "uniprot": "Q8IKA6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKA6"
  },
  {
    "uniprot": "C0H586",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H586"
  },
  {
    "uniprot": "C0H4B5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4B5"
  },
  {
    "uniprot": "Q8IL97",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL97"
  },
  {
    "uniprot": "Q8I2W7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2W7"
  },
  {
    "uniprot": "A0A5K1K9F6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9F6"
  },
  {
    "uniprot": "A0A5K1K8Q1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8Q1"
  },
  {
    "uniprot": "Q8I3T2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3T2"
  },
  {
    "uniprot": "A0A5K1K954",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K954"
  },
  {
    "uniprot": "Q8I2S6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2S6"
  },
  {
    "uniprot": "O77382",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77382"
  },
  {
    "uniprot": "Q8IB72",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB72"
  },
  {
    "uniprot": "Q8IB69",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB69"
  },
  {
    "uniprot": "Q8IHT5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHT5"
  },
  {
    "uniprot": "C6S3B6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3B6"
  },
  {
    "uniprot": "C6KSQ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSQ5"
  },
  {
    "uniprot": "Q8IBV9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBV9"
  },
  {
    "uniprot": "Q8I5S6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5S6"
  },
  {
    "uniprot": "Q8IM61",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM61"
  },
  {
    "uniprot": "Q8I4X4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4X4"
  },
  {
    "uniprot": "Q8IBX7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBX7"
  },
  {
    "uniprot": "C6KT18",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT18"
  },
  {
    "uniprot": "Q8IDB5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDB5"
  },
  {
    "uniprot": "C6KT05",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT05"
  },
  {
    "uniprot": "Q8IJZ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJZ9"
  },
  {
    "uniprot": "Q8I3F6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3F6"
  },
  {
    "uniprot": "C6KT28",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT28"
  },
  {
    "uniprot": "C0H530",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H530"
  },
  {
    "uniprot": "Q8IJQ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJQ4"
  },
  {
    "uniprot": "C0H4E1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4E1"
  },
  {
    "uniprot": "Q8I231",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I231"
  },
  {
    "uniprot": "Q8IAP0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAP0"
  },
  {
    "uniprot": "Q8I1U6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1U6"
  },
  {
    "uniprot": "Q8II56",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II56"
  },
  {
    "uniprot": "Q8I3T9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3T9"
  },
  {
    "uniprot": "Q8IKK3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKK3"
  },
  {
    "uniprot": "Q8I3W8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3W8"
  },
  {
    "uniprot": "A0A144A160",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A160"
  },
  {
    "uniprot": "A0A144A1W9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1W9"
  },
  {
    "uniprot": "Q8I4Z8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Z8"
  },
  {
    "uniprot": "C0H4T0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4T0"
  },
  {
    "uniprot": "Q9NFB3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NFB3"
  },
  {
    "uniprot": "Q8IEV1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEV1"
  },
  {
    "uniprot": "Q8IDH3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDH3"
  },
  {
    "uniprot": "Q8IJ88",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ88"
  },
  {
    "uniprot": "Q8I716",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I716"
  },
  {
    "uniprot": "C0H4T1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4T1"
  },
  {
    "uniprot": "Q8IEI7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEI7"
  },
  {
    "uniprot": "Q8IET2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IET2"
  },
  {
    "uniprot": "A0A140KXX5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A140KXX5"
  },
  {
    "uniprot": "Q8ILX2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILX2"
  },
  {
    "uniprot": "Q8I489",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I489"
  },
  {
    "uniprot": "Q8IC22",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC22"
  },
  {
    "uniprot": "B9ZSI4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSI4"
  },
  {
    "uniprot": "Q25811",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25811"
  },
  {
    "uniprot": "Q8IB64",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB64"
  },
  {
    "uniprot": "Q8I5S9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5S9"
  },
  {
    "uniprot": "Q8ILQ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILQ4"
  },
  {
    "uniprot": "C6KTF1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTF1"
  },
  {
    "uniprot": "C0H4M0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4M0"
  },
  {
    "uniprot": "C0H520",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H520"
  },
  {
    "uniprot": "Q8IKX1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKX1"
  },
  {
    "uniprot": "A0A144A3F9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3F9"
  },
  {
    "uniprot": "A0A5K1K8W5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8W5"
  },
  {
    "uniprot": "Q8IJ54",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ54"
  },
  {
    "uniprot": "Q8IKK4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKK4"
  },
  {
    "uniprot": "Q8IKM6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKM6"
  },
  {
    "uniprot": "Q8I612",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I612"
  },
  {
    "uniprot": "A0A143ZVU1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVU1"
  },
  {
    "uniprot": "Q8IBB7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBB7"
  },
  {
    "uniprot": "A0A5K1K9F9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9F9"
  },
  {
    "uniprot": "Q8I393",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I393"
  },
  {
    "uniprot": "Q8I5F0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5F0"
  },
  {
    "uniprot": "A0A143ZZR0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZR0"
  },
  {
    "uniprot": "Q8IL43",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL43"
  },
  {
    "uniprot": "Q8II71",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II71"
  },
  {
    "uniprot": "O77375",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77375"
  },
  {
    "uniprot": "Q8IJU1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJU1"
  },
  {
    "uniprot": "C6KSX2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSX2"
  },
  {
    "uniprot": "Q8ILA1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILA1"
  },
  {
    "uniprot": "Q8I1U8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1U8"
  },
  {
    "uniprot": "A0A143ZWB4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWB4"
  },
  {
    "uniprot": "C0H4Q7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Q7"
  },
  {
    "uniprot": "Q8IK76",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK76"
  },
  {
    "uniprot": "Q9NDU7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NDU7"
  },
  {
    "uniprot": "Q8I5Z8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Z8"
  },
  {
    "uniprot": "A0A5K1K8J1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8J1"
  },
  {
    "uniprot": "Q8IL31",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL31"
  },
  {
    "uniprot": "Q8IKU4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKU4"
  },
  {
    "uniprot": "Q8ILV6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILV6"
  },
  {
    "uniprot": "Q8I5R3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5R3"
  },
  {
    "uniprot": "Q8IK16",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK16"
  },
  {
    "uniprot": "A0A5K1K924",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K924"
  },
  {
    "uniprot": "Q8IDL8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDL8"
  },
  {
    "uniprot": "Q8IEU6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEU6"
  },
  {
    "uniprot": "A0A144A308",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A308"
  },
  {
    "uniprot": "C6S3C2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3C2"
  },
  {
    "uniprot": "Q8ILS6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILS6"
  },
  {
    "uniprot": "Q8ID22",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID22"
  },
  {
    "uniprot": "Q8I3V9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3V9"
  },
  {
    "uniprot": "C0H580",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H580"
  },
  {
    "uniprot": "Q8IJH1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJH1"
  },
  {
    "uniprot": "Q8I3V8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3V8"
  },
  {
    "uniprot": "Q8III7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8III7"
  },
  {
    "uniprot": "C0H4F9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4F9"
  },
  {
    "uniprot": "Q8IIV4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIV4"
  },
  {
    "uniprot": "Q8I0U8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0U8"
  },
  {
    "uniprot": "Q8IK42",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK42"
  },
  {
    "uniprot": "B9ZSI8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSI8"
  },
  {
    "uniprot": "A0A5K1K8V1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8V1"
  },
  {
    "uniprot": "Q8IAX1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAX1"
  },
  {
    "uniprot": "Q8I1W8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1W8"
  },
  {
    "uniprot": "C0H4G0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4G0"
  },
  {
    "uniprot": "Q8I2F6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2F6"
  },
  {
    "uniprot": "Q8I238",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I238"
  },
  {
    "uniprot": "C6KSZ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSZ2"
  },
  {
    "uniprot": "Q8I307",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I307"
  },
  {
    "uniprot": "Q8I3L4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3L4"
  },
  {
    "uniprot": "Q8ID33",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID33"
  },
  {
    "uniprot": "A0A144A465",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A465"
  },
  {
    "uniprot": "Q8IEN8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEN8"
  },
  {
    "uniprot": "Q8IDZ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDZ9"
  },
  {
    "uniprot": "Q8IKZ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKZ4"
  },
  {
    "uniprot": "Q8IL98",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL98"
  },
  {
    "uniprot": "Q8IKP4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKP4"
  },
  {
    "uniprot": "Q8I2V8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2V8"
  },
  {
    "uniprot": "Q8I5F2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5F2"
  },
  {
    "uniprot": "A0A5K1K936",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K936"
  },
  {
    "uniprot": "Q8IJL3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJL3"
  },
  {
    "uniprot": "Q8IJL1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJL1"
  },
  {
    "uniprot": "C6KTF8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTF8"
  },
  {
    "uniprot": "Q8I480",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I480"
  },
  {
    "uniprot": "Q8IB26",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB26"
  },
  {
    "uniprot": "Q8IBI8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBI8"
  },
  {
    "uniprot": "A0A144A207",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A207"
  },
  {
    "uniprot": "Q8IBG0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBG0"
  },
  {
    "uniprot": "Q8IAQ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAQ5"
  },
  {
    "uniprot": "Q8I479",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I479"
  },
  {
    "uniprot": "Q8IBM6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBM6"
  },
  {
    "uniprot": "Q8I5U5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5U5"
  },
  {
    "uniprot": "Q8IBX2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBX2"
  },
  {
    "uniprot": "Q8IKT9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKT9"
  },
  {
    "uniprot": "Q8IDQ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDQ8"
  },
  {
    "uniprot": "Q8IM76",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM76"
  },
  {
    "uniprot": "A0A143ZXB4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXB4"
  },
  {
    "uniprot": "Q8IL92",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL92"
  },
  {
    "uniprot": "Q8IJN0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJN0"
  },
  {
    "uniprot": "Q8IK78",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK78"
  },
  {
    "uniprot": "Q8IDS7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDS7"
  },
  {
    "uniprot": "Q8IIT8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIT8"
  },
  {
    "uniprot": "C6KSL2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSL2"
  },
  {
    "uniprot": "Q8IIH2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIH2"
  },
  {
    "uniprot": "A0A5K1K836",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K836"
  },
  {
    "uniprot": "Q9U0J4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0J4"
  },
  {
    "uniprot": "C0H4M5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4M5"
  },
  {
    "uniprot": "Q8II61",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II61"
  },
  {
    "uniprot": "C0H4U9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4U9"
  },
  {
    "uniprot": "Q8ILX6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILX6"
  },
  {
    "uniprot": "Q8I5B7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5B7"
  },
  {
    "uniprot": "A0A5K1K9A4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9A4"
  },
  {
    "uniprot": "Q8IKQ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKQ4"
  },
  {
    "uniprot": "Q8IJ99",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ99"
  },
  {
    "uniprot": "A0A143ZXE4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXE4"
  },
  {
    "uniprot": "Q8ILH5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILH5"
  },
  {
    "uniprot": "C6KSL5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSL5"
  },
  {
    "uniprot": "Q8I4X5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4X5"
  },
  {
    "uniprot": "Q8IAS5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAS5"
  },
  {
    "uniprot": "O77348",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77348"
  },
  {
    "uniprot": "Q8I5A0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5A0"
  },
  {
    "uniprot": "Q8IDD0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDD0"
  },
  {
    "uniprot": "Q8I2Q1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Q1"
  },
  {
    "uniprot": "C6KT01",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT01"
  },
  {
    "uniprot": "Q9U0M0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0M0"
  },
  {
    "uniprot": "Q8IKG6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKG6"
  },
  {
    "uniprot": "O96288",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96288"
  },
  {
    "uniprot": "C0H5I6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5I6"
  },
  {
    "uniprot": "O97247",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97247"
  },
  {
    "uniprot": "Q8I3U5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3U5"
  },
  {
    "uniprot": "C6KT56",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT56"
  },
  {
    "uniprot": "Q8ILQ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILQ6"
  },
  {
    "uniprot": "Q8I4P0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4P0"
  },
  {
    "uniprot": "Q8IKC3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKC3"
  },
  {
    "uniprot": "Q8I3R7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3R7"
  },
  {
    "uniprot": "Q8I6T8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6T8"
  },
  {
    "uniprot": "Q8I2I9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2I9"
  },
  {
    "uniprot": "Q8I3D9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3D9"
  },
  {
    "uniprot": "O96264",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96264"
  },
  {
    "uniprot": "Q8I4X2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4X2"
  },
  {
    "uniprot": "A0A144A0W6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0W6"
  },
  {
    "uniprot": "Q8IE96",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE96"
  },
  {
    "uniprot": "Q8I3G9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3G9"
  },
  {
    "uniprot": "C6KT93",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT93"
  },
  {
    "uniprot": "C0H584",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H584"
  },
  {
    "uniprot": "Q8II11",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II11"
  },
  {
    "uniprot": "C0H5C2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5C2"
  },
  {
    "uniprot": "Q8IIE3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIE3"
  },
  {
    "uniprot": "Q8I3D2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3D2"
  },
  {
    "uniprot": "Q8IKT2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKT2"
  },
  {
    "uniprot": "Q8IJ86",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ86"
  },
  {
    "uniprot": "P46468",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/P46468"
  },
  {
    "uniprot": "Q8I2E5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2E5"
  },
  {
    "uniprot": "Q8I1Q4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Q4"
  },
  {
    "uniprot": "C0H4R8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4R8"
  },
  {
    "uniprot": "C0H4P8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4P8"
  },
  {
    "uniprot": "Q25823",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25823"
  },
  {
    "uniprot": "A0A143ZZU2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZU2"
  },
  {
    "uniprot": "Q8ILT9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILT9"
  },
  {
    "uniprot": "A0A5K1K8Y9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8Y9"
  },
  {
    "uniprot": "Q8IKQ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKQ9"
  },
  {
    "uniprot": "Q8IJL7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJL7"
  },
  {
    "uniprot": "Q8IIS2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIS2"
  },
  {
    "uniprot": "C0H4F4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4F4"
  },
  {
    "uniprot": "Q8IKV6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKV6"
  },
  {
    "uniprot": "Q7K6A5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7K6A5"
  },
  {
    "uniprot": "Q8IHW5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHW5"
  },
  {
    "uniprot": "Q8IJ00",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ00"
  },
  {
    "uniprot": "B9ZSI3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSI3"
  },
  {
    "uniprot": "Q8IKV7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKV7"
  },
  {
    "uniprot": "Q8I442",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I442"
  },
  {
    "uniprot": "Q9U0I2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0I2"
  },
  {
    "uniprot": "Q8IIS0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIS0"
  },
  {
    "uniprot": "Q8IAP6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAP6"
  },
  {
    "uniprot": "A0A143ZWL4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWL4"
  },
  {
    "uniprot": "C6KSZ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSZ0"
  },
  {
    "uniprot": "Q8IK88",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK88"
  },
  {
    "uniprot": "C0H559",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H559"
  },
  {
    "uniprot": "C6KSP5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSP5"
  },
  {
    "uniprot": "Q8IJQ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJQ6"
  },
  {
    "uniprot": "Q8IBT4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBT4"
  },
  {
    "uniprot": "O77359",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77359"
  },
  {
    "uniprot": "Q8I3H0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3H0"
  },
  {
    "uniprot": "O77321",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77321"
  },
  {
    "uniprot": "C6KSL7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSL7"
  },
  {
    "uniprot": "Q8IJN4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJN4"
  },
  {
    "uniprot": "Q8IL50",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL50"
  },
  {
    "uniprot": "Q8II53",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II53"
  },
  {
    "uniprot": "C6KSQ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSQ7"
  },
  {
    "uniprot": "Q8IEQ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEQ9"
  },
  {
    "uniprot": "Q8IKU1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKU1"
  },
  {
    "uniprot": "A0A144A1J3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1J3"
  },
  {
    "uniprot": "Q8IEB7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEB7"
  },
  {
    "uniprot": "Q8IKM8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKM8"
  },
  {
    "uniprot": "Q8IL22",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL22"
  },
  {
    "uniprot": "O97261",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97261"
  },
  {
    "uniprot": "A0A5K1K8D5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8D5"
  },
  {
    "uniprot": "O97336",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97336"
  },
  {
    "uniprot": "Q8IL53",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL53"
  },
  {
    "uniprot": "O77376",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77376"
  },
  {
    "uniprot": "O96177",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96177"
  },
  {
    "uniprot": "Q8I6Z7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6Z7"
  },
  {
    "uniprot": "C0H5E6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5E6"
  },
  {
    "uniprot": "Q8IIB4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIB4"
  },
  {
    "uniprot": "Q8I3U1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3U1"
  },
  {
    "uniprot": "C0H5M5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5M5"
  },
  {
    "uniprot": "Q8I4U4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4U4"
  },
  {
    "uniprot": "Q8IDS1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDS1"
  },
  {
    "uniprot": "Q8I2W0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2W0"
  },
  {
    "uniprot": "Q8IDE5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDE5"
  },
  {
    "uniprot": "O97234",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97234"
  },
  {
    "uniprot": "Q8IKC2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKC2"
  },
  {
    "uniprot": "Q8ILZ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILZ1"
  },
  {
    "uniprot": "Q8IKM9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKM9"
  },
  {
    "uniprot": "O96124",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96124"
  },
  {
    "uniprot": "Q8IEB9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEB9"
  },
  {
    "uniprot": "C6KT67",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT67"
  },
  {
    "uniprot": "C6KTC1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTC1"
  },
  {
    "uniprot": "A0A144A2Q6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2Q6"
  },
  {
    "uniprot": "A0A143ZX41",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZX41"
  },
  {
    "uniprot": "Q8I4R6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4R6"
  },
  {
    "uniprot": "Q8IIJ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIJ1"
  },
  {
    "uniprot": "A0A5K1K8S2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8S2"
  },
  {
    "uniprot": "A0A5K1K9B8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9B8"
  },
  {
    "uniprot": "Q8IAZ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAZ6"
  },
  {
    "uniprot": "A0A143ZVN3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVN3"
  },
  {
    "uniprot": "Q8ILM0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILM0"
  },
  {
    "uniprot": "Q8I440",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I440"
  },
  {
    "uniprot": "A0A5K1K8E3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8E3"
  },
  {
    "uniprot": "Q8IIH7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIH7"
  },
  {
    "uniprot": "A0A143ZX62",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZX62"
  },
  {
    "uniprot": "Q8IK25",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK25"
  },
  {
    "uniprot": "Q8IKL8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKL8"
  },
  {
    "uniprot": "O96243",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96243"
  },
  {
    "uniprot": "C0H4P3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4P3"
  },
  {
    "uniprot": "Q7KWJ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KWJ2"
  },
  {
    "uniprot": "Q8IB18",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB18"
  },
  {
    "uniprot": "Q9NFA9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NFA9"
  },
  {
    "uniprot": "Q8I4S1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4S1"
  },
  {
    "uniprot": "Q8ILS7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILS7"
  },
  {
    "uniprot": "C6KTC3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTC3"
  },
  {
    "uniprot": "A0A144A2R0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2R0"
  },
  {
    "uniprot": "Q8IKR9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKR9"
  },
  {
    "uniprot": "Q8ID41",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID41"
  },
  {
    "uniprot": "Q8IB09",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB09"
  },
  {
    "uniprot": "C0H549",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H549"
  },
  {
    "uniprot": "C0H4V2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4V2"
  },
  {
    "uniprot": "O96200",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96200"
  },
  {
    "uniprot": "A0A143ZZW7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZW7"
  },
  {
    "uniprot": "Q8IJ28",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ28"
  },
  {
    "uniprot": "O97333",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97333"
  },
  {
    "uniprot": "O96186",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96186"
  },
  {
    "uniprot": "O97313",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97313"
  },
  {
    "uniprot": "Q8I3D8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3D8"
  },
  {
    "uniprot": "Q8II99",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II99"
  },
  {
    "uniprot": "A0A143ZVZ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVZ5"
  },
  {
    "uniprot": "Q8I2Q4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Q4"
  },
  {
    "uniprot": "A0A5K1K8H7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8H7"
  },
  {
    "uniprot": "A0A143ZY29",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZY29"
  },
  {
    "uniprot": "A0A143ZXD1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXD1"
  },
  {
    "uniprot": "A0A144A2K5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2K5"
  },
  {
    "uniprot": "Q8IIC6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIC6"
  },
  {
    "uniprot": "Q8IBQ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBQ2"
  },
  {
    "uniprot": "Q8IDE1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDE1"
  },
  {
    "uniprot": "Q8IBW9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBW9"
  },
  {
    "uniprot": "Q9U0L4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0L4"
  },
  {
    "uniprot": "C0H542",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H542"
  },
  {
    "uniprot": "A0A143ZWM3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWM3"
  },
  {
    "uniprot": "O96130",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96130"
  },
  {
    "uniprot": "Q8IEG7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEG7"
  },
  {
    "uniprot": "A0A5K1K8X1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8X1"
  },
  {
    "uniprot": "Q8I533",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I533"
  },
  {
    "uniprot": "A0A144A4I8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A4I8"
  },
  {
    "uniprot": "Q8IB74",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB74"
  },
  {
    "uniprot": "Q8IIL5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIL5"
  },
  {
    "uniprot": "Q8IEU8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEU8"
  },
  {
    "uniprot": "Q8I604",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I604"
  },
  {
    "uniprot": "Q8IKF2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKF2"
  },
  {
    "uniprot": "Q8I2Y1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Y1"
  },
  {
    "uniprot": "Q8IIF9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIF9"
  },
  {
    "uniprot": "Q8I331",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I331"
  },
  {
    "uniprot": "Q8IIR6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIR6"
  },
  {
    "uniprot": "A0A143ZVD1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVD1"
  },
  {
    "uniprot": "Q8ID24",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID24"
  },
  {
    "uniprot": "Q8I4Q0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Q0"
  },
  {
    "uniprot": "Q8I1Q2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Q2"
  },
  {
    "uniprot": "Q8I244",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I244"
  },
  {
    "uniprot": "Q8IE05",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE05"
  },
  {
    "uniprot": "O97244",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97244"
  },
  {
    "uniprot": "C0H4W5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4W5"
  },
  {
    "uniprot": "O77367",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77367"
  },
  {
    "uniprot": "Q8II86",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II86"
  },
  {
    "uniprot": "Q8IBM9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBM9"
  },
  {
    "uniprot": "Q8I500",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I500"
  },
  {
    "uniprot": "Q8IIL1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIL1"
  },
  {
    "uniprot": "Q8I2H5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2H5"
  },
  {
    "uniprot": "C0H4H6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4H6"
  },
  {
    "uniprot": "Q8ILU3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILU3"
  },
  {
    "uniprot": "Q8I4N5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4N5"
  },
  {
    "uniprot": "C0H518",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H518"
  },
  {
    "uniprot": "O97251",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97251"
  },
  {
    "uniprot": "C0H5C1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5C1"
  },
  {
    "uniprot": "A0A5K1K8W3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8W3"
  },
  {
    "uniprot": "Q8I2Z9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Z9"
  },
  {
    "uniprot": "Q8I2M3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2M3"
  },
  {
    "uniprot": "O97275",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97275"
  },
  {
    "uniprot": "Q8I565",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I565"
  },
  {
    "uniprot": "Q8IKE0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKE0"
  },
  {
    "uniprot": "Q8I5F9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5F9"
  },
  {
    "uniprot": "C6KSU8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSU8"
  },
  {
    "uniprot": "A0A144A3L0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3L0"
  },
  {
    "uniprot": "Q8I617",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I617"
  },
  {
    "uniprot": "O96228",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96228"
  },
  {
    "uniprot": "Q8IJL5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJL5"
  },
  {
    "uniprot": "Q8IHY3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHY3"
  },
  {
    "uniprot": "C0H4U6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4U6"
  },
  {
    "uniprot": "A0A5K1K8T8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8T8"
  },
  {
    "uniprot": "Q8IHQ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHQ2"
  },
  {
    "uniprot": "C6S3B4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3B4"
  },
  {
    "uniprot": "Q8IIK1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIK1"
  },
  {
    "uniprot": "Q8I340",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I340"
  },
  {
    "uniprot": "Q8I623",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I623"
  },
  {
    "uniprot": "Q7K6B1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7K6B1"
  },
  {
    "uniprot": "A0A143ZY51",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZY51"
  },
  {
    "uniprot": "Q8I5Z0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Z0"
  },
  {
    "uniprot": "Q7HP03",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7HP03"
  },
  {
    "uniprot": "Q8IAM3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAM3"
  },
  {
    "uniprot": "Q8IEK4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEK4"
  },
  {
    "uniprot": "Q8IHT3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHT3"
  },
  {
    "uniprot": "A0A143ZXG5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXG5"
  },
  {
    "uniprot": "Q8I5Q6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Q6"
  },
  {
    "uniprot": "Q8I406",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I406"
  },
  {
    "uniprot": "Q8I2Q0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Q0"
  },
  {
    "uniprot": "Q8IAN9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAN9"
  },
  {
    "uniprot": "Q8I1V5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1V5"
  },
  {
    "uniprot": "O96260",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96260"
  },
  {
    "uniprot": "Q8I355",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I355"
  },
  {
    "uniprot": "C6KT07",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT07"
  },
  {
    "uniprot": "C0H5L3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5L3"
  },
  {
    "uniprot": "Q8I384",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I384"
  },
  {
    "uniprot": "Q8ILH7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILH7"
  },
  {
    "uniprot": "Q8IKW5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKW5"
  },
  {
    "uniprot": "A0A5K1K9B9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9B9"
  },
  {
    "uniprot": "O96290",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96290"
  },
  {
    "uniprot": "Q8IJS7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJS7"
  },
  {
    "uniprot": "Q8I574",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I574"
  },
  {
    "uniprot": "C6KSY4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSY4"
  },
  {
    "uniprot": "Q8I3Y0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Y0"
  },
  {
    "uniprot": "C0H5N8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5N8"
  },
  {
    "uniprot": "C6KSV9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSV9"
  },
  {
    "uniprot": "C0H4F6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4F6"
  },
  {
    "uniprot": "Q8I661",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I661"
  },
  {
    "uniprot": "Q8I624",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I624"
  },
  {
    "uniprot": "C0H548",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H548"
  },
  {
    "uniprot": "C0H593",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H593"
  },
  {
    "uniprot": "Q9U0K5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0K5"
  },
  {
    "uniprot": "Q8IBZ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBZ8"
  },
  {
    "uniprot": "Q8I2S2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2S2"
  },
  {
    "uniprot": "A0A5K1K8F9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8F9"
  },
  {
    "uniprot": "Q8IJX6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJX6"
  },
  {
    "uniprot": "Q8I388",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I388"
  },
  {
    "uniprot": "Q8IHX3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHX3"
  },
  {
    "uniprot": "O77351",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77351"
  },
  {
    "uniprot": "Q8IEK8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEK8"
  },
  {
    "uniprot": "Q8IK47",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK47"
  },
  {
    "uniprot": "Q8ILC7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILC7"
  },
  {
    "uniprot": "C6KTE9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTE9"
  },
  {
    "uniprot": "Q8IIV0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIV0"
  },
  {
    "uniprot": "Q8IJ07",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ07"
  },
  {
    "uniprot": "A0A144A322",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A322"
  },
  {
    "uniprot": "Q8I4W6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4W6"
  },
  {
    "uniprot": "Q8IB24",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB24"
  },
  {
    "uniprot": "Q8IEM2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEM2"
  },
  {
    "uniprot": "Q8ILB6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILB6"
  },
  {
    "uniprot": "Q8IKX0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKX0"
  },
  {
    "uniprot": "Q8I5J7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5J7"
  },
  {
    "uniprot": "O96246",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96246"
  },
  {
    "uniprot": "A0A144A1J6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1J6"
  },
  {
    "uniprot": "C0H531",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H531"
  },
  {
    "uniprot": "P21422",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/P21422"
  },
  {
    "uniprot": "Q8I1N7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1N7"
  },
  {
    "uniprot": "Q8I5V9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5V9"
  },
  {
    "uniprot": "Q8I4Q2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Q2"
  },
  {
    "uniprot": "A0A140KXW6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A140KXW6"
  },
  {
    "uniprot": "Q8IJT0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJT0"
  },
  {
    "uniprot": "Q8ILD6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILD6"
  },
  {
    "uniprot": "A0A143ZYR6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYR6"
  },
  {
    "uniprot": "Q8IJJ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJJ1"
  },
  {
    "uniprot": "Q8IJ64",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ64"
  },
  {
    "uniprot": "Q8I5C3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5C3"
  },
  {
    "uniprot": "Q8IIZ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIZ2"
  },
  {
    "uniprot": "Q8IEM1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEM1"
  },
  {
    "uniprot": "C6KTF6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTF6"
  },
  {
    "uniprot": "Q6LFN2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q6LFN2"
  },
  {
    "uniprot": "Q8IEN9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEN9"
  },
  {
    "uniprot": "Q8I4P4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4P4"
  },
  {
    "uniprot": "O96117",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96117"
  },
  {
    "uniprot": "Q8IBP3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBP3"
  },
  {
    "uniprot": "A0A5K1K885",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K885"
  },
  {
    "uniprot": "Q8I4V8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4V8"
  },
  {
    "uniprot": "Q8IIC4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIC4"
  },
  {
    "uniprot": "C0H5K2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5K2"
  },
  {
    "uniprot": "Q8IC26",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC26"
  },
  {
    "uniprot": "Q8ILL3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILL3"
  },
  {
    "uniprot": "O96274",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96274"
  },
  {
    "uniprot": "Q8ILF7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILF7"
  },
  {
    "uniprot": "C6KSU2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSU2"
  },
  {
    "uniprot": "C6KT88",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT88"
  },
  {
    "uniprot": "C0H5C3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5C3"
  },
  {
    "uniprot": "Q8ILV2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILV2"
  },
  {
    "uniprot": "Q8IM01",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM01"
  },
  {
    "uniprot": "C0H599",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H599"
  },
  {
    "uniprot": "O96174",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96174"
  },
  {
    "uniprot": "Q8IL99",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL99"
  },
  {
    "uniprot": "A0A144A544",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A544"
  },
  {
    "uniprot": "C0H4M4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4M4"
  },
  {
    "uniprot": "Q8I280",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I280"
  },
  {
    "uniprot": "Q8IJK4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJK4"
  },
  {
    "uniprot": "O97310",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97310"
  },
  {
    "uniprot": "Q8IC23",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC23"
  },
  {
    "uniprot": "C0H5B8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5B8"
  },
  {
    "uniprot": "Q8I1Q0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Q0"
  },
  {
    "uniprot": "C0H5N4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5N4"
  },
  {
    "uniprot": "Q8IJG0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJG0"
  },
  {
    "uniprot": "A0A5K1K929",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K929"
  },
  {
    "uniprot": "O96154",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96154"
  },
  {
    "uniprot": "Q8I6S4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6S4"
  },
  {
    "uniprot": "Q8IAU4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAU4"
  },
  {
    "uniprot": "Q8IDW7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDW7"
  },
  {
    "uniprot": "Q8I4Q6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Q6"
  },
  {
    "uniprot": "Q8ID31",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID31"
  },
  {
    "uniprot": "Q8I2F4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2F4"
  },
  {
    "uniprot": "Q8IEN2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEN2"
  },
  {
    "uniprot": "Q8ILA4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILA4"
  },
  {
    "uniprot": "C6KSP7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSP7"
  },
  {
    "uniprot": "Q8IBQ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBQ0"
  },
  {
    "uniprot": "Q8I3V6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3V6"
  },
  {
    "uniprot": "Q8IEG8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEG8"
  },
  {
    "uniprot": "Q8IDF0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDF0"
  },
  {
    "uniprot": "C6KT55",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT55"
  },
  {
    "uniprot": "Q8I5K9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5K9"
  },
  {
    "uniprot": "Q8ID12",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID12"
  },
  {
    "uniprot": "Q8IJ29",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ29"
  },
  {
    "uniprot": "Q8I285",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I285"
  },
  {
    "uniprot": "Q8IEC7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEC7"
  },
  {
    "uniprot": "Q8IHZ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHZ5"
  },
  {
    "uniprot": "Q8IKT6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKT6"
  },
  {
    "uniprot": "Q8IKK9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKK9"
  },
  {
    "uniprot": "Q8IKQ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKQ2"
  },
  {
    "uniprot": "C6KT47",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT47"
  },
  {
    "uniprot": "C0H4Y0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Y0"
  },
  {
    "uniprot": "C0H4W2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4W2"
  },
  {
    "uniprot": "A0A143ZXB9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXB9"
  },
  {
    "uniprot": "A0A5K1K8Z8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8Z8"
  },
  {
    "uniprot": "Q8I3N1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3N1"
  },
  {
    "uniprot": "A0A143ZWD5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWD5"
  },
  {
    "uniprot": "Q8ILG3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILG3"
  },
  {
    "uniprot": "Q8IKI8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKI8"
  },
  {
    "uniprot": "O96273",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96273"
  },
  {
    "uniprot": "Q7YTF7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7YTF7"
  },
  {
    "uniprot": "Q8I3Z9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Z9"
  },
  {
    "uniprot": "Q8IFQ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFQ4"
  },
  {
    "uniprot": "Q8IJI1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJI1"
  },
  {
    "uniprot": "Q8IKE8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKE8"
  },
  {
    "uniprot": "C0H4E9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4E9"
  },
  {
    "uniprot": "Q8IAU7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAU7"
  },
  {
    "uniprot": "C6KSS4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSS4"
  },
  {
    "uniprot": "A0A143ZYV4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYV4"
  },
  {
    "uniprot": "C0H5L8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5L8"
  },
  {
    "uniprot": "Q8IKX4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKX4"
  },
  {
    "uniprot": "Q8I4S2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4S2"
  },
  {
    "uniprot": "Q8IL26",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL26"
  },
  {
    "uniprot": "C6KSY3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSY3"
  },
  {
    "uniprot": "Q8IAW7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAW7"
  },
  {
    "uniprot": "O96140",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96140"
  },
  {
    "uniprot": "Q8II55",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II55"
  },
  {
    "uniprot": "Q8IDI9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDI9"
  },
  {
    "uniprot": "Q8IJN2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJN2"
  },
  {
    "uniprot": "Q8I303",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I303"
  },
  {
    "uniprot": "Q8IBN4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBN4"
  },
  {
    "uniprot": "Q8IET8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IET8"
  },
  {
    "uniprot": "Q8IJ59",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ59"
  },
  {
    "uniprot": "Q8I524",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I524"
  },
  {
    "uniprot": "Q8I493",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I493"
  },
  {
    "uniprot": "O97320",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97320"
  },
  {
    "uniprot": "Q8I1Y8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Y8"
  },
  {
    "uniprot": "Q8IK24",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK24"
  },
  {
    "uniprot": "Q8I491",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I491"
  },
  {
    "uniprot": "Q8IL28",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL28"
  },
  {
    "uniprot": "Q8I3T1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3T1"
  },
  {
    "uniprot": "Q8IJD6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJD6"
  },
  {
    "uniprot": "Q8IIY4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIY4"
  },
  {
    "uniprot": "Q8I4T4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4T4"
  },
  {
    "uniprot": "C0H5E9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5E9"
  },
  {
    "uniprot": "Q8I456",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I456"
  },
  {
    "uniprot": "Q8IBA3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBA3"
  },
  {
    "uniprot": "Q8ILV1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILV1"
  },
  {
    "uniprot": "Q8I5Y8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Y8"
  },
  {
    "uniprot": "Q8I4R4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4R4"
  },
  {
    "uniprot": "Q8I229",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I229"
  },
  {
    "uniprot": "C0H490",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H490"
  },
  {
    "uniprot": "C6KSV7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSV7"
  },
  {
    "uniprot": "Q8IHU2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHU2"
  },
  {
    "uniprot": "Q25799",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25799"
  },
  {
    "uniprot": "Q8IDS6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDS6"
  },
  {
    "uniprot": "C0H569",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H569"
  },
  {
    "uniprot": "Q8IFN6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFN6"
  },
  {
    "uniprot": "Q8IBC9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBC9"
  },
  {
    "uniprot": "Q8IKK6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKK6"
  },
  {
    "uniprot": "Q8IIF0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIF0"
  },
  {
    "uniprot": "A0A144A1U0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1U0"
  },
  {
    "uniprot": "A0A143ZZ93",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZ93"
  },
  {
    "uniprot": "A0A5K1K9F0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9F0"
  },
  {
    "uniprot": "Q8IL87",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL87"
  },
  {
    "uniprot": "C0H556",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H556"
  },
  {
    "uniprot": "Q8IK22",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK22"
  },
  {
    "uniprot": "Q8ILP0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILP0"
  },
  {
    "uniprot": "Q8I224",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I224"
  },
  {
    "uniprot": "C0H4X8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4X8"
  },
  {
    "uniprot": "Q8ILU2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILU2"
  },
  {
    "uniprot": "A0A144A1F3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1F3"
  },
  {
    "uniprot": "Q8I273",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I273"
  },
  {
    "uniprot": "Q8I6T7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6T7"
  },
  {
    "uniprot": "Q8I3L8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3L8"
  },
  {
    "uniprot": "Q8IFN7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFN7"
  },
  {
    "uniprot": "Q8IDS8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDS8"
  },
  {
    "uniprot": "A0A144A0A4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0A4"
  },
  {
    "uniprot": "Q8IL95",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL95"
  },
  {
    "uniprot": "Q8IEG2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEG2"
  },
  {
    "uniprot": "A0A144A3U7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3U7"
  },
  {
    "uniprot": "C0H466",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H466"
  },
  {
    "uniprot": "C6KTB1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTB1"
  },
  {
    "uniprot": "Q8IHW1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHW1"
  },
  {
    "uniprot": "O97257",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97257"
  },
  {
    "uniprot": "Q8IM72",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM72"
  },
  {
    "uniprot": "A0A144A0J8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0J8"
  },
  {
    "uniprot": "Q8IBF0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBF0"
  },
  {
    "uniprot": "Q8IL15",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL15"
  },
  {
    "uniprot": "A0A5K1K8S6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8S6"
  },
  {
    "uniprot": "Q8IB54",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB54"
  },
  {
    "uniprot": "Q8IJR7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJR7"
  },
  {
    "uniprot": "A0A5K1K8N8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8N8"
  },
  {
    "uniprot": "C0H4F3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4F3"
  },
  {
    "uniprot": "Q8I213",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I213"
  },
  {
    "uniprot": "Q8I5Z4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Z4"
  },
  {
    "uniprot": "Q8IJY0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJY0"
  },
  {
    "uniprot": "Q9U0H2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0H2"
  },
  {
    "uniprot": "Q8IJV1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJV1"
  },
  {
    "uniprot": "C0H478",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H478"
  },
  {
    "uniprot": "O97225",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97225"
  },
  {
    "uniprot": "Q7KQK0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KQK0"
  },
  {
    "uniprot": "C0H529",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H529"
  },
  {
    "uniprot": "O77347",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77347"
  },
  {
    "uniprot": "O77317",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77317"
  },
  {
    "uniprot": "Q8II31",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II31"
  },
  {
    "uniprot": "Q8I2W3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2W3"
  },
  {
    "uniprot": "O77329",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77329"
  },
  {
    "uniprot": "Q8IK45",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK45"
  },
  {
    "uniprot": "C6S3G6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3G6"
  },
  {
    "uniprot": "Q8IK70",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK70"
  },
  {
    "uniprot": "C6S3H6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3H6"
  },
  {
    "uniprot": "Q8I2F9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2F9"
  },
  {
    "uniprot": "Q9NLA7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NLA7"
  },
  {
    "uniprot": "Q8I632",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I632"
  },
  {
    "uniprot": "Q8I518",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I518"
  },
  {
    "uniprot": "C0H511",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H511"
  },
  {
    "uniprot": "Q8I2R0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2R0"
  },
  {
    "uniprot": "Q8ID30",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID30"
  },
  {
    "uniprot": "C0H4W1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4W1"
  },
  {
    "uniprot": "Q8I3D6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3D6"
  },
  {
    "uniprot": "Q8ILF4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILF4"
  },
  {
    "uniprot": "C6KSM6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSM6"
  },
  {
    "uniprot": "C0H4Y4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Y4"
  },
  {
    "uniprot": "Q8IDL7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDL7"
  },
  {
    "uniprot": "Q8I669",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I669"
  },
  {
    "uniprot": "Q8I1Z0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Z0"
  },
  {
    "uniprot": "C6KSZ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSZ4"
  },
  {
    "uniprot": "Q8I4Q8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Q8"
  },
  {
    "uniprot": "O97319",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97319"
  },
  {
    "uniprot": "Q8IDX9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDX9"
  },
  {
    "uniprot": "C6KT14",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT14"
  },
  {
    "uniprot": "Q8IIK8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIK8"
  },
  {
    "uniprot": "Q8IJA7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJA7"
  },
  {
    "uniprot": "Q8IKN9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKN9"
  },
  {
    "uniprot": "Q8IJS6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJS6"
  },
  {
    "uniprot": "Q8IJV3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJV3"
  },
  {
    "uniprot": "Q8IL36",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL36"
  },
  {
    "uniprot": "A0A5K1K8K0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8K0"
  },
  {
    "uniprot": "C6KTD0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTD0"
  },
  {
    "uniprot": "Q25817",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25817"
  },
  {
    "uniprot": "Q76NM7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q76NM7"
  },
  {
    "uniprot": "O97228",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97228"
  },
  {
    "uniprot": "Q8I211",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I211"
  },
  {
    "uniprot": "C6KSY8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSY8"
  },
  {
    "uniprot": "Q8IJS1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJS1"
  },
  {
    "uniprot": "C6KT92",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT92"
  },
  {
    "uniprot": "A0A143ZX72",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZX72"
  },
  {
    "uniprot": "A0A144A102",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A102"
  },
  {
    "uniprot": "O77346",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77346"
  },
  {
    "uniprot": "Q8IET3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IET3"
  },
  {
    "uniprot": "Q8IE21",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE21"
  },
  {
    "uniprot": "C0H4T2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4T2"
  },
  {
    "uniprot": "Q8I1W9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1W9"
  },
  {
    "uniprot": "Q8IJK6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJK6"
  },
  {
    "uniprot": "O96267",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96267"
  },
  {
    "uniprot": "A0A5K1K8G1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8G1"
  },
  {
    "uniprot": "Q8I2T3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2T3"
  },
  {
    "uniprot": "Q8IHZ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHZ4"
  },
  {
    "uniprot": "Q8IL32",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL32"
  },
  {
    "uniprot": "C0H4Y8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Y8"
  },
  {
    "uniprot": "Q8I358",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I358"
  },
  {
    "uniprot": "O96226",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96226"
  },
  {
    "uniprot": "Q8IE09",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE09"
  },
  {
    "uniprot": "Q8I2U9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2U9"
  },
  {
    "uniprot": "Q7KQK1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KQK1"
  },
  {
    "uniprot": "Q8IKN0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKN0"
  },
  {
    "uniprot": "A0A5K1K989",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K989"
  },
  {
    "uniprot": "Q8IJX8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJX8"
  },
  {
    "uniprot": "A0A5K1K942",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K942"
  },
  {
    "uniprot": "Q8I485",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I485"
  },
  {
    "uniprot": "A0A144A2S8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2S8"
  },
  {
    "uniprot": "Q8IJE5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJE5"
  },
  {
    "uniprot": "Q8IIA0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIA0"
  },
  {
    "uniprot": "Q8I4W9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4W9"
  },
  {
    "uniprot": "Q8IBB9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBB9"
  },
  {
    "uniprot": "C6S3I8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3I8"
  },
  {
    "uniprot": "Q8IJ76",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ76"
  },
  {
    "uniprot": "Q8IBJ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBJ9"
  },
  {
    "uniprot": "C0H484",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H484"
  },
  {
    "uniprot": "A0A143ZY16",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZY16"
  },
  {
    "uniprot": "Q8IKC4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKC4"
  },
  {
    "uniprot": "O96252",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96252"
  },
  {
    "uniprot": "Q8ID53",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID53"
  },
  {
    "uniprot": "Q8IKJ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKJ1"
  },
  {
    "uniprot": "Q8ILX4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILX4"
  },
  {
    "uniprot": "Q8I461",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I461"
  },
  {
    "uniprot": "Q8IAM1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAM1"
  },
  {
    "uniprot": "Q8IBR1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBR1"
  },
  {
    "uniprot": "Q8IJU6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJU6"
  },
  {
    "uniprot": "Q8I2S5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2S5"
  },
  {
    "uniprot": "Q8I230",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I230"
  },
  {
    "uniprot": "Q8IAZ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAZ7"
  },
  {
    "uniprot": "C0H5G9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5G9"
  },
  {
    "uniprot": "A0A5K1K943",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K943"
  },
  {
    "uniprot": "Q8IJL9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJL9"
  },
  {
    "uniprot": "C6KSX5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSX5"
  },
  {
    "uniprot": "Q8IJC3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJC3"
  },
  {
    "uniprot": "Q8IBF2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBF2"
  },
  {
    "uniprot": "Q8IEP8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEP8"
  },
  {
    "uniprot": "O77316",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77316"
  },
  {
    "uniprot": "Q8II88",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II88"
  },
  {
    "uniprot": "A0A144A1W6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1W6"
  },
  {
    "uniprot": "C0H4G9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4G9"
  },
  {
    "uniprot": "Q8IIE4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIE4"
  },
  {
    "uniprot": "Q8I556",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I556"
  },
  {
    "uniprot": "C0H4E3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4E3"
  },
  {
    "uniprot": "Q8I5C5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5C5"
  },
  {
    "uniprot": "C6KT68",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT68"
  },
  {
    "uniprot": "C6S3G5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3G5"
  },
  {
    "uniprot": "Q8IAV6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAV6"
  },
  {
    "uniprot": "Q8IB92",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB92"
  },
  {
    "uniprot": "Q8IKT3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKT3"
  },
  {
    "uniprot": "Q8IB51",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB51"
  },
  {
    "uniprot": "Q8IKJ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKJ8"
  },
  {
    "uniprot": "Q8IC38",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC38"
  },
  {
    "uniprot": "Q8ILA5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILA5"
  },
  {
    "uniprot": "Q8IIE1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIE1"
  },
  {
    "uniprot": "Q8I4Z1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Z1"
  },
  {
    "uniprot": "Q8IIH6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIH6"
  },
  {
    "uniprot": "A0A5K1K8Q7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8Q7"
  },
  {
    "uniprot": "Q8IBW8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBW8"
  },
  {
    "uniprot": "A0A5K1K8U7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8U7"
  },
  {
    "uniprot": "Q8I3V3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3V3"
  },
  {
    "uniprot": "Q8I3B7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3B7"
  },
  {
    "uniprot": "C6KTD7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTD7"
  },
  {
    "uniprot": "Q8I322",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I322"
  },
  {
    "uniprot": "Q8IBT2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBT2"
  },
  {
    "uniprot": "Q8I2I0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2I0"
  },
  {
    "uniprot": "Q8IL71",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL71"
  },
  {
    "uniprot": "O77327",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77327"
  },
  {
    "uniprot": "Q8I426",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I426"
  },
  {
    "uniprot": "Q8I4Y5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Y5"
  },
  {
    "uniprot": "Q8IDG5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDG5"
  },
  {
    "uniprot": "Q8I5W7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5W7"
  },
  {
    "uniprot": "Q8I5E5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5E5"
  },
  {
    "uniprot": "Q8IKL1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKL1"
  },
  {
    "uniprot": "C6KT57",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT57"
  },
  {
    "uniprot": "Q8IDD9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDD9"
  },
  {
    "uniprot": "Q8I3G7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3G7"
  },
  {
    "uniprot": "C6S3K5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3K5"
  },
  {
    "uniprot": "Q25820",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25820"
  },
  {
    "uniprot": "Q8I578",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I578"
  },
  {
    "uniprot": "Q8I5T1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5T1"
  },
  {
    "uniprot": "C0H471",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H471"
  },
  {
    "uniprot": "A0A5K1K8N0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8N0"
  },
  {
    "uniprot": "C0H587",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H587"
  },
  {
    "uniprot": "Q8ILD2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILD2"
  },
  {
    "uniprot": "Q8I5N7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5N7"
  },
  {
    "uniprot": "Q8ID23",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID23"
  },
  {
    "uniprot": "Q8IB63",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB63"
  },
  {
    "uniprot": "C6KSW5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSW5"
  },
  {
    "uniprot": "Q8IEB1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEB1"
  },
  {
    "uniprot": "Q8I200",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I200"
  },
  {
    "uniprot": "C6S3D6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3D6"
  },
  {
    "uniprot": "Q8IL10",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL10"
  },
  {
    "uniprot": "Q9U0N1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0N1"
  },
  {
    "uniprot": "Q8IIY3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIY3"
  },
  {
    "uniprot": "Q8IB89",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB89"
  },
  {
    "uniprot": "B9ZSH8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSH8"
  },
  {
    "uniprot": "A0A143ZVC5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVC5"
  },
  {
    "uniprot": "Q8IKT1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKT1"
  },
  {
    "uniprot": "Q8I3R0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3R0"
  },
  {
    "uniprot": "Q8IFP8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFP8"
  },
  {
    "uniprot": "Q8I0V5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0V5"
  },
  {
    "uniprot": "Q8ID94",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID94"
  },
  {
    "uniprot": "Q8I4S6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4S6"
  },
  {
    "uniprot": "Q8I3P7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3P7"
  },
  {
    "uniprot": "C0H532",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H532"
  },
  {
    "uniprot": "Q8I3W5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3W5"
  },
  {
    "uniprot": "B9ZSH7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSH7"
  },
  {
    "uniprot": "Q8IIF6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIF6"
  },
  {
    "uniprot": "Q8IIT6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIT6"
  },
  {
    "uniprot": "Q8IBK8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBK8"
  },
  {
    "uniprot": "Q8IBZ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBZ9"
  },
  {
    "uniprot": "Q9U0G7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0G7"
  },
  {
    "uniprot": "A0A5K1K8K6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8K6"
  },
  {
    "uniprot": "Q8IIM5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIM5"
  },
  {
    "uniprot": "Q8IEF2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEF2"
  },
  {
    "uniprot": "Q8I599",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I599"
  },
  {
    "uniprot": "Q8IDQ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDQ3"
  },
  {
    "uniprot": "Q9NLB3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NLB3"
  },
  {
    "uniprot": "Q8IAM4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAM4"
  },
  {
    "uniprot": "Q8I348",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I348"
  },
  {
    "uniprot": "O97250",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97250"
  },
  {
    "uniprot": "Q8IFQ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFQ5"
  },
  {
    "uniprot": "Q9U0N3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0N3"
  },
  {
    "uniprot": "A0A5K1K8Y8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8Y8"
  },
  {
    "uniprot": "A0A5K1K9F2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9F2"
  },
  {
    "uniprot": "Q8I3E9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3E9"
  },
  {
    "uniprot": "Q8IIF4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIF4"
  },
  {
    "uniprot": "Q8IIG8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIG8"
  },
  {
    "uniprot": "C6KT71",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT71"
  },
  {
    "uniprot": "Q8IIM2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIM2"
  },
  {
    "uniprot": "Q8II13",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II13"
  },
  {
    "uniprot": "C0H4G1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4G1"
  },
  {
    "uniprot": "Q8IBH9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBH9"
  },
  {
    "uniprot": "Q8IJW7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJW7"
  },
  {
    "uniprot": "Q8IEB2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEB2"
  },
  {
    "uniprot": "Q8I576",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I576"
  },
  {
    "uniprot": "C6KSN6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSN6"
  },
  {
    "uniprot": "Q8IHT0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHT0"
  },
  {
    "uniprot": "A0A5K1K9E5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9E5"
  },
  {
    "uniprot": "Q8IKP5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKP5"
  },
  {
    "uniprot": "Q8I571",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I571"
  },
  {
    "uniprot": "C0H537",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H537"
  },
  {
    "uniprot": "Q8IM74",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM74"
  },
  {
    "uniprot": "Q8IKV8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKV8"
  },
  {
    "uniprot": "Q8IJC7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJC7"
  },
  {
    "uniprot": "C6KSZ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSZ7"
  },
  {
    "uniprot": "O96147",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96147"
  },
  {
    "uniprot": "O96213",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96213"
  },
  {
    "uniprot": "Q8IER4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IER4"
  },
  {
    "uniprot": "Q8I2K9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2K9"
  },
  {
    "uniprot": "Q25812",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25812"
  },
  {
    "uniprot": "C6KTD3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTD3"
  },
  {
    "uniprot": "C0H4L6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4L6"
  },
  {
    "uniprot": "Q8I3E7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3E7"
  },
  {
    "uniprot": "Q8IIN5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIN5"
  },
  {
    "uniprot": "Q8IFM0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFM0"
  },
  {
    "uniprot": "Q8IL73",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL73"
  },
  {
    "uniprot": "Q8I2A4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2A4"
  },
  {
    "uniprot": "Q8IFP0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFP0"
  },
  {
    "uniprot": "Q8I3A5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3A5"
  },
  {
    "uniprot": "Q8ILD3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILD3"
  },
  {
    "uniprot": "Q8I3T4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3T4"
  },
  {
    "uniprot": "A0A5K1K8Z6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8Z6"
  },
  {
    "uniprot": "Q7K6A1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7K6A1"
  },
  {
    "uniprot": "Q8I419",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I419"
  },
  {
    "uniprot": "C6KTA0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTA0"
  },
  {
    "uniprot": "Q8IDW9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDW9"
  },
  {
    "uniprot": "Q8I3T6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3T6"
  },
  {
    "uniprot": "C6KT11",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT11"
  },
  {
    "uniprot": "Q8IJY7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJY7"
  },
  {
    "uniprot": "C6KT16",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT16"
  },
  {
    "uniprot": "Q8IFL8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFL8"
  },
  {
    "uniprot": "Q9U0I8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0I8"
  },
  {
    "uniprot": "Q8I5P5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5P5"
  },
  {
    "uniprot": "Q8I2U8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2U8"
  },
  {
    "uniprot": "A0A5K1K9G8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9G8"
  },
  {
    "uniprot": "Q8IL07",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL07"
  },
  {
    "uniprot": "C6KTB0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTB0"
  },
  {
    "uniprot": "Q8IDW8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDW8"
  },
  {
    "uniprot": "Q9U0K7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0K7"
  },
  {
    "uniprot": "A0A143ZWJ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWJ4"
  },
  {
    "uniprot": "Q9U0G5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0G5"
  },
  {
    "uniprot": "A0A143ZYW1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYW1"
  },
  {
    "uniprot": "Q8ILY1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILY1"
  },
  {
    "uniprot": "Q8IBL1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBL1"
  },
  {
    "uniprot": "Q8I2U6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2U6"
  },
  {
    "uniprot": "Q8IFQ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFQ6"
  },
  {
    "uniprot": "Q8I5S1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5S1"
  },
  {
    "uniprot": "A0A143ZVB5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVB5"
  },
  {
    "uniprot": "A0A140KXU9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A140KXU9"
  },
  {
    "uniprot": "Q8I2V7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2V7"
  },
  {
    "uniprot": "C0H469",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H469"
  },
  {
    "uniprot": "C0H4Q1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Q1"
  },
  {
    "uniprot": "Q8IKE5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKE5"
  },
  {
    "uniprot": "A0A143ZZ50",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZ50"
  },
  {
    "uniprot": "C0H541",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H541"
  },
  {
    "uniprot": "Q8I324",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I324"
  },
  {
    "uniprot": "Q76NM3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q76NM3"
  },
  {
    "uniprot": "O96198",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96198"
  },
  {
    "uniprot": "C6S3B5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3B5"
  },
  {
    "uniprot": "Q8I1Z1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Z1"
  },
  {
    "uniprot": "A0A143ZWC2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWC2"
  },
  {
    "uniprot": "Q8IIJ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIJ0"
  },
  {
    "uniprot": "Q8IEL0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEL0"
  },
  {
    "uniprot": "Q8IC14",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC14"
  },
  {
    "uniprot": "Q8I642",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I642"
  },
  {
    "uniprot": "Q8I5J5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5J5"
  },
  {
    "uniprot": "C0H4W9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4W9"
  },
  {
    "uniprot": "Q8IBG3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBG3"
  },
  {
    "uniprot": "A0A144A0M2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0M2"
  },
  {
    "uniprot": "C6KT76",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT76"
  },
  {
    "uniprot": "C6KTA4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTA4"
  },
  {
    "uniprot": "O77391",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77391"
  },
  {
    "uniprot": "Q8I1X7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1X7"
  },
  {
    "uniprot": "Q8I5P9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5P9"
  },
  {
    "uniprot": "Q8I517",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I517"
  },
  {
    "uniprot": "Q8IK54",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK54"
  },
  {
    "uniprot": "C0H4R6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4R6"
  },
  {
    "uniprot": "Q8I513",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I513"
  },
  {
    "uniprot": "O96151",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96151"
  },
  {
    "uniprot": "Q8IJM4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJM4"
  },
  {
    "uniprot": "Q8IK90",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK90"
  },
  {
    "uniprot": "Q8I4R5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4R5"
  },
  {
    "uniprot": "Q8IEP5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEP5"
  },
  {
    "uniprot": "Q8ILK6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILK6"
  },
  {
    "uniprot": "Q8I4X7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4X7"
  },
  {
    "uniprot": "Q8I466",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I466"
  },
  {
    "uniprot": "A0A144A2J0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2J0"
  },
  {
    "uniprot": "Q8IJT3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJT3"
  },
  {
    "uniprot": "Q8IJH7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJH7"
  },
  {
    "uniprot": "C6S3J4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3J4"
  },
  {
    "uniprot": "Q8IC43",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC43"
  },
  {
    "uniprot": "Q8ILP4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILP4"
  },
  {
    "uniprot": "Q8IDW4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDW4"
  },
  {
    "uniprot": "Q8IKK1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKK1"
  },
  {
    "uniprot": "Q8IDB0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDB0"
  },
  {
    "uniprot": "Q8ILW3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILW3"
  },
  {
    "uniprot": "Q8IL12",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL12"
  },
  {
    "uniprot": "Q8I0W2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0W2"
  },
  {
    "uniprot": "Q8ID59",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID59"
  },
  {
    "uniprot": "Q8IE73",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE73"
  },
  {
    "uniprot": "Q8IJB2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJB2"
  },
  {
    "uniprot": "Q8IHX8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHX8"
  },
  {
    "uniprot": "Q8I4Y6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Y6"
  },
  {
    "uniprot": "Q8IBV4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBV4"
  },
  {
    "uniprot": "Q8IDN7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDN7"
  },
  {
    "uniprot": "Q8ILD7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILD7"
  },
  {
    "uniprot": "Q8I422",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I422"
  },
  {
    "uniprot": "Q8I462",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I462"
  },
  {
    "uniprot": "Q8IKA0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKA0"
  },
  {
    "uniprot": "Q8IJ85",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ85"
  },
  {
    "uniprot": "Q8IEC8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEC8"
  },
  {
    "uniprot": "Q8IAZ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAZ0"
  },
  {
    "uniprot": "A0A5K1K8P4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8P4"
  },
  {
    "uniprot": "O96254",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96254"
  },
  {
    "uniprot": "Q8IDG2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDG2"
  },
  {
    "uniprot": "Q8IKW0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKW0"
  },
  {
    "uniprot": "Q8I247",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I247"
  },
  {
    "uniprot": "A0A5K1K8I2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8I2"
  },
  {
    "uniprot": "Q8I314",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I314"
  },
  {
    "uniprot": "Q8IFM9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFM9"
  },
  {
    "uniprot": "C0H498",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H498"
  },
  {
    "uniprot": "O96253",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96253"
  },
  {
    "uniprot": "Q8I5D5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5D5"
  },
  {
    "uniprot": "Q8IAQ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAQ4"
  },
  {
    "uniprot": "Q8I0V8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0V8"
  },
  {
    "uniprot": "Q8I318",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I318"
  },
  {
    "uniprot": "A0A5K1K8X7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8X7"
  },
  {
    "uniprot": "Q8IFL3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFL3"
  },
  {
    "uniprot": "Q8II79",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II79"
  },
  {
    "uniprot": "Q8I3V5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3V5"
  },
  {
    "uniprot": "Q8I5E1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5E1"
  },
  {
    "uniprot": "C6KSZ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSZ9"
  },
  {
    "uniprot": "O96176",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96176"
  },
  {
    "uniprot": "Q8IJN1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJN1"
  },
  {
    "uniprot": "Q8I3J1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3J1"
  },
  {
    "uniprot": "Q8I1W3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1W3"
  },
  {
    "uniprot": "Q8I341",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I341"
  },
  {
    "uniprot": "C6S3F2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3F2"
  },
  {
    "uniprot": "Q8IB31",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB31"
  },
  {
    "uniprot": "Q27730",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q27730"
  },
  {
    "uniprot": "C0H4X0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4X0"
  },
  {
    "uniprot": "C0H5H9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5H9"
  },
  {
    "uniprot": "C6KTE2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTE2"
  },
  {
    "uniprot": "Q8I5Z9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Z9"
  },
  {
    "uniprot": "Q8IDY0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDY0"
  },
  {
    "uniprot": "Q8IBV0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBV0"
  },
  {
    "uniprot": "Q8IBE7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBE7"
  },
  {
    "uniprot": "Q8IHN7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHN7"
  },
  {
    "uniprot": "C0H553",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H553"
  },
  {
    "uniprot": "A0A5K1K934",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K934"
  },
  {
    "uniprot": "O96251",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96251"
  },
  {
    "uniprot": "Q8I2K5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2K5"
  },
  {
    "uniprot": "Q9U0L6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0L6"
  },
  {
    "uniprot": "Q8I5B2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5B2"
  },
  {
    "uniprot": "Q8IKA2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKA2"
  },
  {
    "uniprot": "Q8IKR7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKR7"
  },
  {
    "uniprot": "Q8I2N8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2N8"
  },
  {
    "uniprot": "Q8ILW1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILW1"
  },
  {
    "uniprot": "C6KT51",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT51"
  },
  {
    "uniprot": "Q76NM6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q76NM6"
  },
  {
    "uniprot": "C6KT09",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT09"
  },
  {
    "uniprot": "Q8I210",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I210"
  },
  {
    "uniprot": "Q8IDT7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDT7"
  },
  {
    "uniprot": "Q8I515",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I515"
  },
  {
    "uniprot": "Q8I3L1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3L1"
  },
  {
    "uniprot": "O77313",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77313"
  },
  {
    "uniprot": "Q8I338",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I338"
  },
  {
    "uniprot": "Q8I3F4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3F4"
  },
  {
    "uniprot": "Q8IJ49",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ49"
  },
  {
    "uniprot": "Q8IHZ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHZ6"
  },
  {
    "uniprot": "Q8IJ26",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ26"
  },
  {
    "uniprot": "Q8IBN6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBN6"
  },
  {
    "uniprot": "O77343",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77343"
  },
  {
    "uniprot": "Q8I5L4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5L4"
  },
  {
    "uniprot": "C6KTA9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTA9"
  },
  {
    "uniprot": "Q8IJ06",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ06"
  },
  {
    "uniprot": "Q8I4X9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4X9"
  },
  {
    "uniprot": "A0A143ZXA3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXA3"
  },
  {
    "uniprot": "Q8IBZ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBZ4"
  },
  {
    "uniprot": "Q8ID38",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID38"
  },
  {
    "uniprot": "A0A5K1K826",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K826"
  },
  {
    "uniprot": "Q8IK09",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK09"
  },
  {
    "uniprot": "Q8II01",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II01"
  },
  {
    "uniprot": "C0H583",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H583"
  },
  {
    "uniprot": "O96119",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96119"
  },
  {
    "uniprot": "O77396",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77396"
  },
  {
    "uniprot": "A0A144A595",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A595"
  },
  {
    "uniprot": "Q8IJI7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJI7"
  },
  {
    "uniprot": "Q8IIW0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIW0"
  },
  {
    "uniprot": "C0H5C7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5C7"
  },
  {
    "uniprot": "C6KT15",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT15"
  },
  {
    "uniprot": "O97323",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97323"
  },
  {
    "uniprot": "Q8I4Y3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Y3"
  },
  {
    "uniprot": "C0H4B2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4B2"
  },
  {
    "uniprot": "Q8IJF7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJF7"
  },
  {
    "uniprot": "Q8ILY7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILY7"
  },
  {
    "uniprot": "Q8I312",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I312"
  },
  {
    "uniprot": "Q8IJA1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJA1"
  },
  {
    "uniprot": "Q8I566",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I566"
  },
  {
    "uniprot": "Q8I2A1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2A1"
  },
  {
    "uniprot": "Q8IEE9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEE9"
  },
  {
    "uniprot": "K7NTP5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/K7NTP5"
  },
  {
    "uniprot": "Q8IIN6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIN6"
  },
  {
    "uniprot": "Q7KQK2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KQK2"
  },
  {
    "uniprot": "A0A143ZZR4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZR4"
  },
  {
    "uniprot": "C0H5I4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5I4"
  },
  {
    "uniprot": "C6KSS0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSS0"
  },
  {
    "uniprot": "Q8IIS8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIS8"
  },
  {
    "uniprot": "Q8I3F7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3F7"
  },
  {
    "uniprot": "Q8I346",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I346"
  },
  {
    "uniprot": "Q8IDF9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDF9"
  },
  {
    "uniprot": "Q8IDW5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDW5"
  },
  {
    "uniprot": "Q8I568",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I568"
  },
  {
    "uniprot": "Q8IDY3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDY3"
  },
  {
    "uniprot": "A0A143ZZH9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZH9"
  },
  {
    "uniprot": "Q7K6A0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7K6A0"
  },
  {
    "uniprot": "Q8I3U9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3U9"
  },
  {
    "uniprot": "Q8IBB0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBB0"
  },
  {
    "uniprot": "Q8I2Y9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Y9"
  },
  {
    "uniprot": "Q8IE08",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE08"
  },
  {
    "uniprot": "Q8III1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8III1"
  },
  {
    "uniprot": "Q8IAY0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAY0"
  },
  {
    "uniprot": "A0A144A3T1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3T1"
  },
  {
    "uniprot": "Q8I278",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I278"
  },
  {
    "uniprot": "Q8IJR2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJR2"
  },
  {
    "uniprot": "Q8I5D8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5D8"
  },
  {
    "uniprot": "Q9U0G6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0G6"
  },
  {
    "uniprot": "Q8I5M1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5M1"
  },
  {
    "uniprot": "C0H4M3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4M3"
  },
  {
    "uniprot": "O96296",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96296"
  },
  {
    "uniprot": "A0A144A325",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A325"
  },
  {
    "uniprot": "Q8I2H1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2H1"
  },
  {
    "uniprot": "C0H5B1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5B1"
  },
  {
    "uniprot": "A0A5K1K911",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K911"
  },
  {
    "uniprot": "Q8I4T0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4T0"
  },
  {
    "uniprot": "Q8IB39",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB39"
  },
  {
    "uniprot": "Q8IJ87",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ87"
  },
  {
    "uniprot": "Q8IK95",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK95"
  },
  {
    "uniprot": "Q8IJX2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJX2"
  },
  {
    "uniprot": "Q8IJP1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJP1"
  },
  {
    "uniprot": "O97231",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97231"
  },
  {
    "uniprot": "Q8IIH3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIH3"
  },
  {
    "uniprot": "Q8I2L9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2L9"
  },
  {
    "uniprot": "Q8IJI8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJI8"
  },
  {
    "uniprot": "Q8I1T1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1T1"
  },
  {
    "uniprot": "Q8ILB0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILB0"
  },
  {
    "uniprot": "Q8ILU4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILU4"
  },
  {
    "uniprot": "Q8IBA4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBA4"
  },
  {
    "uniprot": "O96265",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96265"
  },
  {
    "uniprot": "A0A5K1K8W4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8W4"
  },
  {
    "uniprot": "Q8I220",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I220"
  },
  {
    "uniprot": "C6KSQ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSQ9"
  },
  {
    "uniprot": "Q8IDE2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDE2"
  },
  {
    "uniprot": "Q8IC17",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC17"
  },
  {
    "uniprot": "A0A5K1K8X0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8X0"
  },
  {
    "uniprot": "Q8IEJ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEJ8"
  },
  {
    "uniprot": "Q8I463",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I463"
  },
  {
    "uniprot": "Q8IFM7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFM7"
  },
  {
    "uniprot": "A0A5K1K918",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K918"
  },
  {
    "uniprot": "Q8IHR3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHR3"
  },
  {
    "uniprot": "Q8IK97",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK97"
  },
  {
    "uniprot": "Q8IHM6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHM6"
  },
  {
    "uniprot": "Q8I5Q4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Q4"
  },
  {
    "uniprot": "Q8I3V0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3V0"
  },
  {
    "uniprot": "Q8IBA2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBA2"
  },
  {
    "uniprot": "O97334",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97334"
  },
  {
    "uniprot": "Q8I5U1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5U1"
  },
  {
    "uniprot": "C0H596",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H596"
  },
  {
    "uniprot": "C6S3B7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3B7"
  },
  {
    "uniprot": "Q8I3X9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3X9"
  },
  {
    "uniprot": "C0H522",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H522"
  },
  {
    "uniprot": "Q8IKH5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKH5"
  },
  {
    "uniprot": "Q8IEP4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEP4"
  },
  {
    "uniprot": "O77379",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77379"
  },
  {
    "uniprot": "Q8I5N9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5N9"
  },
  {
    "uniprot": "A0A5K1K8L6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8L6"
  },
  {
    "uniprot": "C6KTE5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTE5"
  },
  {
    "uniprot": "C0H4G2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4G2"
  },
  {
    "uniprot": "P27362",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/P27362"
  },
  {
    "uniprot": "Q8I5U6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5U6"
  },
  {
    "uniprot": "Q8ILD9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILD9"
  },
  {
    "uniprot": "A0A144A1E8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1E8"
  },
  {
    "uniprot": "O96199",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96199"
  },
  {
    "uniprot": "Q8IFN9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFN9"
  },
  {
    "uniprot": "Q6LFH8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q6LFH8"
  },
  {
    "uniprot": "Q8I3N5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3N5"
  },
  {
    "uniprot": "Q8IIP5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIP5"
  },
  {
    "uniprot": "A0A143ZZT8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZT8"
  },
  {
    "uniprot": "O97304",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97304"
  },
  {
    "uniprot": "Q8IJD3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJD3"
  },
  {
    "uniprot": "Q8IM24",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM24"
  },
  {
    "uniprot": "A0A144A4X8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A4X8"
  },
  {
    "uniprot": "Q8IIJ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIJ6"
  },
  {
    "uniprot": "Q8IBM3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBM3"
  },
  {
    "uniprot": "Q8IBS6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBS6"
  },
  {
    "uniprot": "Q8I5U2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5U2"
  },
  {
    "uniprot": "A0A144A3C7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3C7"
  },
  {
    "uniprot": "Q8IKT7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKT7"
  },
  {
    "uniprot": "C6KT25",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT25"
  },
  {
    "uniprot": "Q8I2N0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2N0"
  },
  {
    "uniprot": "Q8IM15",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM15"
  },
  {
    "uniprot": "C0H4U3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4U3"
  },
  {
    "uniprot": "C6KSN3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSN3"
  },
  {
    "uniprot": "Q8I6T6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6T6"
  },
  {
    "uniprot": "Q8IBF3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBF3"
  },
  {
    "uniprot": "C0H4S1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4S1"
  },
  {
    "uniprot": "Q8I304",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I304"
  },
  {
    "uniprot": "A0A5K1K8W0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8W0"
  },
  {
    "uniprot": "Q8I1R8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1R8"
  },
  {
    "uniprot": "Q8IJR4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJR4"
  },
  {
    "uniprot": "Q8I3G2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3G2"
  },
  {
    "uniprot": "Q8ILS5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILS5"
  },
  {
    "uniprot": "Q8I5N1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5N1"
  },
  {
    "uniprot": "Q8I1Y0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Y0"
  },
  {
    "uniprot": "A0A143ZZE4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZE4"
  },
  {
    "uniprot": "Q8IM53",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM53"
  },
  {
    "uniprot": "O97240",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97240"
  },
  {
    "uniprot": "Q8ILK7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILK7"
  },
  {
    "uniprot": "Q8ILG0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILG0"
  },
  {
    "uniprot": "C6KT40",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT40"
  },
  {
    "uniprot": "A0A143ZW11",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZW11"
  },
  {
    "uniprot": "C0H5H8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5H8"
  },
  {
    "uniprot": "Q8IL44",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL44"
  },
  {
    "uniprot": "Q25824",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25824"
  },
  {
    "uniprot": "Q8I3C5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3C5"
  },
  {
    "uniprot": "Q8IEL6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEL6"
  },
  {
    "uniprot": "Q8IE99",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE99"
  },
  {
    "uniprot": "Q8IAU6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAU6"
  },
  {
    "uniprot": "C6S3E9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3E9"
  },
  {
    "uniprot": "Q8IL77",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL77"
  },
  {
    "uniprot": "Q8I548",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I548"
  },
  {
    "uniprot": "A0A5K1K8S9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8S9"
  },
  {
    "uniprot": "Q8IM44",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM44"
  },
  {
    "uniprot": "A0A143ZYU5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYU5"
  },
  {
    "uniprot": "Q8IKQ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKQ5"
  },
  {
    "uniprot": "C0H477",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H477"
  },
  {
    "uniprot": "Q8I374",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I374"
  },
  {
    "uniprot": "Q8I5H9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5H9"
  },
  {
    "uniprot": "A0A5K1K996",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K996"
  },
  {
    "uniprot": "C6S3E1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3E1"
  },
  {
    "uniprot": "A0A143ZXM2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXM2"
  },
  {
    "uniprot": "Q8II91",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II91"
  },
  {
    "uniprot": "Q8ID39",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID39"
  },
  {
    "uniprot": "Q8IIA4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIA4"
  },
  {
    "uniprot": "Q8IKG9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKG9"
  },
  {
    "uniprot": "Q8IM07",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM07"
  },
  {
    "uniprot": "A0A5K1K8Y5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8Y5"
  },
  {
    "uniprot": "A0A143ZWI7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWI7"
  },
  {
    "uniprot": "O77345",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77345"
  },
  {
    "uniprot": "Q8I5Y6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Y6"
  },
  {
    "uniprot": "Q8IK61",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK61"
  },
  {
    "uniprot": "A0A5K1K976",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K976"
  },
  {
    "uniprot": "Q8IAW4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAW4"
  },
  {
    "uniprot": "C6KT48",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT48"
  },
  {
    "uniprot": "Q8IAW3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAW3"
  },
  {
    "uniprot": "Q8IJF3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJF3"
  },
  {
    "uniprot": "Q8I2T6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2T6"
  },
  {
    "uniprot": "C0H567",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H567"
  },
  {
    "uniprot": "C0H5B9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5B9"
  },
  {
    "uniprot": "Q8IL63",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL63"
  },
  {
    "uniprot": "A0A144A2T4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2T4"
  },
  {
    "uniprot": "Q8I5I7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5I7"
  },
  {
    "uniprot": "Q8ILF5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILF5"
  },
  {
    "uniprot": "O77328",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77328"
  },
  {
    "uniprot": "Q8I609",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I609"
  },
  {
    "uniprot": "C0H5E0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5E0"
  },
  {
    "uniprot": "C0H533",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H533"
  },
  {
    "uniprot": "Q8I5T8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5T8"
  },
  {
    "uniprot": "Q8IJR8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJR8"
  },
  {
    "uniprot": "A0A144A0I2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0I2"
  },
  {
    "uniprot": "Q7K6A7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7K6A7"
  },
  {
    "uniprot": "Q8II90",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II90"
  },
  {
    "uniprot": "Q8I3F8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3F8"
  },
  {
    "uniprot": "C6KSM3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSM3"
  },
  {
    "uniprot": "Q8IJ40",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ40"
  },
  {
    "uniprot": "Q8IKV3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKV3"
  },
  {
    "uniprot": "O96163",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96163"
  },
  {
    "uniprot": "C0H561",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H561"
  },
  {
    "uniprot": "Q8IJI3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJI3"
  },
  {
    "uniprot": "Q8I3H7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3H7"
  },
  {
    "uniprot": "Q8I2H7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2H7"
  },
  {
    "uniprot": "A0A143ZWG8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWG8"
  },
  {
    "uniprot": "C6KT26",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT26"
  },
  {
    "uniprot": "A0A5K1K8I4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8I4"
  },
  {
    "uniprot": "C6S3G0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3G0"
  },
  {
    "uniprot": "Q8ILU5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILU5"
  },
  {
    "uniprot": "Q8IM19",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM19"
  },
  {
    "uniprot": "C6S3C4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3C4"
  },
  {
    "uniprot": "O97307",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97307"
  },
  {
    "uniprot": "O96208",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96208"
  },
  {
    "uniprot": "Q8IK93",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK93"
  },
  {
    "uniprot": "A0A143ZWF7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWF7"
  },
  {
    "uniprot": "Q8I4Z2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Z2"
  },
  {
    "uniprot": "Q8IJT5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJT5"
  },
  {
    "uniprot": "C6KSP4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSP4"
  },
  {
    "uniprot": "Q8I2T8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2T8"
  },
  {
    "uniprot": "Q8I5B9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5B9"
  },
  {
    "uniprot": "Q8IIX4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIX4"
  },
  {
    "uniprot": "A0A144A2R4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2R4"
  },
  {
    "uniprot": "O96218",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96218"
  },
  {
    "uniprot": "Q8IEU5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEU5"
  },
  {
    "uniprot": "Q8IJ71",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ71"
  },
  {
    "uniprot": "Q8II34",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II34"
  },
  {
    "uniprot": "Q8I5G7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5G7"
  },
  {
    "uniprot": "Q8IEH1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEH1"
  },
  {
    "uniprot": "Q9U0J8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0J8"
  },
  {
    "uniprot": "C6S3F1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3F1"
  },
  {
    "uniprot": "Q8I362",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I362"
  },
  {
    "uniprot": "A0A143ZWH6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWH6"
  },
  {
    "uniprot": "Q8I5P0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5P0"
  },
  {
    "uniprot": "Q8IKN8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKN8"
  },
  {
    "uniprot": "Q8IHM3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHM3"
  },
  {
    "uniprot": "C0H551",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H551"
  },
  {
    "uniprot": "O96149",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96149"
  },
  {
    "uniprot": "Q8IKP1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKP1"
  },
  {
    "uniprot": "C0H4K7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4K7"
  },
  {
    "uniprot": "Q8I4W4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4W4"
  },
  {
    "uniprot": "Q8IL67",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL67"
  },
  {
    "uniprot": "C6KSN2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSN2"
  },
  {
    "uniprot": "Q8I3M0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3M0"
  },
  {
    "uniprot": "Q8ILY3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILY3"
  },
  {
    "uniprot": "Q8IJ68",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ68"
  },
  {
    "uniprot": "O96207",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96207"
  },
  {
    "uniprot": "C6KSR7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSR7"
  },
  {
    "uniprot": "Q8I2E3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2E3"
  },
  {
    "uniprot": "O96212",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96212"
  },
  {
    "uniprot": "Q8I4Z9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Z9"
  },
  {
    "uniprot": "O77365",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77365"
  },
  {
    "uniprot": "C6KSQ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSQ6"
  },
  {
    "uniprot": "Q8IE97",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE97"
  },
  {
    "uniprot": "Q8I5M2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5M2"
  },
  {
    "uniprot": "C6KSW9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSW9"
  },
  {
    "uniprot": "O97289",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97289"
  },
  {
    "uniprot": "Q8IB49",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB49"
  },
  {
    "uniprot": "O97255",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97255"
  },
  {
    "uniprot": "Q8IBD1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBD1"
  },
  {
    "uniprot": "C0H564",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H564"
  },
  {
    "uniprot": "Q8I2S3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2S3"
  },
  {
    "uniprot": "Q8IJG3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJG3"
  },
  {
    "uniprot": "C0H595",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H595"
  },
  {
    "uniprot": "Q9U0I3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0I3"
  },
  {
    "uniprot": "A0A143ZZG4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZG4"
  },
  {
    "uniprot": "Q8IJH2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJH2"
  },
  {
    "uniprot": "Q8IIB0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIB0"
  },
  {
    "uniprot": "A0A5K1K8X9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8X9"
  },
  {
    "uniprot": "Q8I2E1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2E1"
  },
  {
    "uniprot": "Q8I398",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I398"
  },
  {
    "uniprot": "Q8I5S8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5S8"
  },
  {
    "uniprot": "C6KSP0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSP0"
  },
  {
    "uniprot": "Q8IK08",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK08"
  },
  {
    "uniprot": "Q8IAT7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAT7"
  },
  {
    "uniprot": "C0H5F5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5F5"
  },
  {
    "uniprot": "Q8II82",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II82"
  },
  {
    "uniprot": "Q8I3L6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3L6"
  },
  {
    "uniprot": "Q8ILR8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILR8"
  },
  {
    "uniprot": "C6KSR4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSR4"
  },
  {
    "uniprot": "Q8IJG5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJG5"
  },
  {
    "uniprot": "Q8I5M9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5M9"
  },
  {
    "uniprot": "Q8IIH4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIH4"
  },
  {
    "uniprot": "C0H4T9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4T9"
  },
  {
    "uniprot": "Q8IDH2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDH2"
  },
  {
    "uniprot": "C6S3B0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3B0"
  },
  {
    "uniprot": "Q8I631",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I631"
  },
  {
    "uniprot": "Q8IKG8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKG8"
  },
  {
    "uniprot": "Q8IAM2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAM2"
  },
  {
    "uniprot": "Q8IBX8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBX8"
  },
  {
    "uniprot": "Q8IIP0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIP0"
  },
  {
    "uniprot": "Q8I488",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I488"
  },
  {
    "uniprot": "Q8ILA8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILA8"
  },
  {
    "uniprot": "Q8I5H1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5H1"
  },
  {
    "uniprot": "C6KT21",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT21"
  },
  {
    "uniprot": "Q8I529",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I529"
  },
  {
    "uniprot": "A0A143ZXM1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXM1"
  },
  {
    "uniprot": "Q8I1V7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1V7"
  },
  {
    "uniprot": "P50250",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/P50250"
  },
  {
    "uniprot": "Q8IDW3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDW3"
  },
  {
    "uniprot": "Q8IIU1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIU1"
  },
  {
    "uniprot": "Q8I5I4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5I4"
  },
  {
    "uniprot": "O96170",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96170"
  },
  {
    "uniprot": "Q8II40",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II40"
  },
  {
    "uniprot": "Q8I3T8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3T8"
  },
  {
    "uniprot": "Q8IBM7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBM7"
  },
  {
    "uniprot": "O77337",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77337"
  },
  {
    "uniprot": "Q8IJQ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJQ9"
  },
  {
    "uniprot": "Q8I5B5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5B5"
  },
  {
    "uniprot": "Q8I2N1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2N1"
  },
  {
    "uniprot": "Q8IC40",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC40"
  },
  {
    "uniprot": "Q8IEJ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEJ5"
  },
  {
    "uniprot": "Q8ID37",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID37"
  },
  {
    "uniprot": "A0A144A1E9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1E9"
  },
  {
    "uniprot": "Q8IEP1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEP1"
  },
  {
    "uniprot": "A0A5K1K7X4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K7X4"
  },
  {
    "uniprot": "Q8I2M4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2M4"
  },
  {
    "uniprot": "Q8IHV7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHV7"
  },
  {
    "uniprot": "A0A144A2G5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2G5"
  },
  {
    "uniprot": "O77330",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77330"
  },
  {
    "uniprot": "C0H4C5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4C5"
  },
  {
    "uniprot": "Q8IFL9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFL9"
  },
  {
    "uniprot": "Q8IJ02",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ02"
  },
  {
    "uniprot": "O97335",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97335"
  },
  {
    "uniprot": "Q8IJA0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJA0"
  },
  {
    "uniprot": "O97286",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97286"
  },
  {
    "uniprot": "O96292",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96292"
  },
  {
    "uniprot": "Q8I715",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I715"
  },
  {
    "uniprot": "Q8IAP8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAP8"
  },
  {
    "uniprot": "Q8IJ27",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ27"
  },
  {
    "uniprot": "Q8IJZ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJZ2"
  },
  {
    "uniprot": "Q8IB13",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB13"
  },
  {
    "uniprot": "Q8I538",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I538"
  },
  {
    "uniprot": "C6KSX1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSX1"
  },
  {
    "uniprot": "Q8IEP2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEP2"
  },
  {
    "uniprot": "Q8IIC0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIC0"
  },
  {
    "uniprot": "Q8IHS3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHS3"
  },
  {
    "uniprot": "C0H4B8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4B8"
  },
  {
    "uniprot": "Q8IDY8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDY8"
  },
  {
    "uniprot": "C6KT95",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT95"
  },
  {
    "uniprot": "C6KT78",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT78"
  },
  {
    "uniprot": "A0A143ZYL5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYL5"
  },
  {
    "uniprot": "Q8IIE7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIE7"
  },
  {
    "uniprot": "Q8IIS4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIS4"
  },
  {
    "uniprot": "C0H5L0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5L0"
  },
  {
    "uniprot": "O96263",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96263"
  },
  {
    "uniprot": "Q8IBK1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBK1"
  },
  {
    "uniprot": "Q8I6V0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6V0"
  },
  {
    "uniprot": "Q8IED2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IED2"
  },
  {
    "uniprot": "Q8IEA7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEA7"
  },
  {
    "uniprot": "Q8I330",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I330"
  },
  {
    "uniprot": "A0A144A0S1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0S1"
  },
  {
    "uniprot": "Q8IB47",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB47"
  },
  {
    "uniprot": "Q8IBF6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBF6"
  },
  {
    "uniprot": "Q8I5K0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5K0"
  },
  {
    "uniprot": "Q8III8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8III8"
  },
  {
    "uniprot": "Q8I3K2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3K2"
  },
  {
    "uniprot": "Q8IIQ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIQ2"
  },
  {
    "uniprot": "Q8ILV8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILV8"
  },
  {
    "uniprot": "Q8IDD5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDD5"
  },
  {
    "uniprot": "Q8I333",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I333"
  },
  {
    "uniprot": "Q8I4S7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4S7"
  },
  {
    "uniprot": "Q9U0M7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0M7"
  },
  {
    "uniprot": "Q8IIB8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIB8"
  },
  {
    "uniprot": "Q8IB37",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB37"
  },
  {
    "uniprot": "Q8I478",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I478"
  },
  {
    "uniprot": "Q8I3A4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3A4"
  },
  {
    "uniprot": "Q8IKJ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKJ3"
  },
  {
    "uniprot": "C0H4K2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4K2"
  },
  {
    "uniprot": "A0A5K1K8C4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8C4"
  },
  {
    "uniprot": "Q8I1S1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1S1"
  },
  {
    "uniprot": "Q8IKF6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKF6"
  },
  {
    "uniprot": "Q8I434",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I434"
  },
  {
    "uniprot": "A0A5K1K8M7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8M7"
  },
  {
    "uniprot": "C6KSU4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSU4"
  },
  {
    "uniprot": "Q8IDC0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDC0"
  },
  {
    "uniprot": "C6KTC5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTC5"
  },
  {
    "uniprot": "Q8IEQ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEQ5"
  },
  {
    "uniprot": "Q8IHV2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHV2"
  },
  {
    "uniprot": "Q8I535",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I535"
  },
  {
    "uniprot": "A0A5K1K8U2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8U2"
  },
  {
    "uniprot": "Q8I3Z0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Z0"
  },
  {
    "uniprot": "Q8I5U8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5U8"
  },
  {
    "uniprot": "Q8IDB6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDB6"
  },
  {
    "uniprot": "C0H5H2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5H2"
  },
  {
    "uniprot": "Q9U0J2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0J2"
  },
  {
    "uniprot": "O97260",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97260"
  },
  {
    "uniprot": "Q8IHU0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHU0"
  },
  {
    "uniprot": "Q8IC28",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC28"
  },
  {
    "uniprot": "Q8IM57",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM57"
  },
  {
    "uniprot": "Q8IKJ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKJ7"
  },
  {
    "uniprot": "C6KT44",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT44"
  },
  {
    "uniprot": "C0H5G1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5G1"
  },
  {
    "uniprot": "C6S3H7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3H7"
  },
  {
    "uniprot": "A0A144A6N0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A6N0"
  },
  {
    "uniprot": "Q8I227",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I227"
  },
  {
    "uniprot": "Q8I3Q4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Q4"
  },
  {
    "uniprot": "Q8IK15",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK15"
  },
  {
    "uniprot": "Q8IE67",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE67"
  },
  {
    "uniprot": "Q8IHP3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHP3"
  },
  {
    "uniprot": "Q8ILD4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILD4"
  },
  {
    "uniprot": "C6KST1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KST1"
  },
  {
    "uniprot": "Q8I5T4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5T4"
  },
  {
    "uniprot": "Q8IL84",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL84"
  },
  {
    "uniprot": "O77369",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77369"
  },
  {
    "uniprot": "Q8IE53",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE53"
  },
  {
    "uniprot": "Q8IIL6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIL6"
  },
  {
    "uniprot": "Q8IJJ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJJ3"
  },
  {
    "uniprot": "Q8I5K7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5K7"
  },
  {
    "uniprot": "C6KST4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KST4"
  },
  {
    "uniprot": "A0A5K1K8W7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8W7"
  },
  {
    "uniprot": "Q8I5F8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5F8"
  },
  {
    "uniprot": "Q8ILK2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILK2"
  },
  {
    "uniprot": "Q7KQK7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KQK7"
  },
  {
    "uniprot": "Q8IKW7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKW7"
  },
  {
    "uniprot": "A0A5K1K930",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K930"
  },
  {
    "uniprot": "Q8IE26",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE26"
  },
  {
    "uniprot": "Q8I5H6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5H6"
  },
  {
    "uniprot": "Q8IBH8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBH8"
  },
  {
    "uniprot": "Q8IAX6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAX6"
  },
  {
    "uniprot": "Q8I549",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I549"
  },
  {
    "uniprot": "Q8IAS7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAS7"
  },
  {
    "uniprot": "C0H589",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H589"
  },
  {
    "uniprot": "Q8I3W9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3W9"
  },
  {
    "uniprot": "Q8IJN8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJN8"
  },
  {
    "uniprot": "C6KSX4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSX4"
  },
  {
    "uniprot": "Q8ILU9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILU9"
  },
  {
    "uniprot": "Q8IJD8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJD8"
  },
  {
    "uniprot": "Q9NFB6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NFB6"
  },
  {
    "uniprot": "Q8IEA0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEA0"
  },
  {
    "uniprot": "Q8IBW7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBW7"
  },
  {
    "uniprot": "C6KSV8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSV8"
  },
  {
    "uniprot": "Q8I1Q1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Q1"
  },
  {
    "uniprot": "C6S3F9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3F9"
  },
  {
    "uniprot": "Q8IDV6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDV6"
  },
  {
    "uniprot": "Q8I444",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I444"
  },
  {
    "uniprot": "Q9U0L0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0L0"
  },
  {
    "uniprot": "Q8IIB7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIB7"
  },
  {
    "uniprot": "Q8ILQ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILQ9"
  },
  {
    "uniprot": "Q8ILA6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILA6"
  },
  {
    "uniprot": "Q8IK86",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK86"
  },
  {
    "uniprot": "Q8I3R5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3R5"
  },
  {
    "uniprot": "Q8IDM2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDM2"
  },
  {
    "uniprot": "O97308",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97308"
  },
  {
    "uniprot": "Q8II57",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II57"
  },
  {
    "uniprot": "Q8IE81",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE81"
  },
  {
    "uniprot": "Q8I5K3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5K3"
  },
  {
    "uniprot": "Q8IDP4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDP4"
  },
  {
    "uniprot": "O96259",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96259"
  },
  {
    "uniprot": "Q8I0V2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0V2"
  },
  {
    "uniprot": "Q8ILW8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILW8"
  },
  {
    "uniprot": "C6KT91",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT91"
  },
  {
    "uniprot": "Q8IKJ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKJ9"
  },
  {
    "uniprot": "Q8IM55",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM55"
  },
  {
    "uniprot": "Q9U0H8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0H8"
  },
  {
    "uniprot": "Q8IBR4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBR4"
  },
  {
    "uniprot": "C0H4P5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4P5"
  },
  {
    "uniprot": "Q8IIB2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIB2"
  },
  {
    "uniprot": "Q9TY96",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9TY96"
  },
  {
    "uniprot": "Q8IIE5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIE5"
  },
  {
    "uniprot": "Q8IHM5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHM5"
  },
  {
    "uniprot": "C0H4D3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4D3"
  },
  {
    "uniprot": "Q8IE48",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE48"
  },
  {
    "uniprot": "Q8IIV7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIV7"
  },
  {
    "uniprot": "Q8I1U3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1U3"
  },
  {
    "uniprot": "B9ZSI0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSI0"
  },
  {
    "uniprot": "O96136",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96136"
  },
  {
    "uniprot": "Q8ILP2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILP2"
  },
  {
    "uniprot": "C6S3D0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3D0"
  },
  {
    "uniprot": "Q8I0P6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0P6"
  },
  {
    "uniprot": "O77324",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77324"
  },
  {
    "uniprot": "Q8IJV7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJV7"
  },
  {
    "uniprot": "C0H4A4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4A4"
  },
  {
    "uniprot": "Q8I5B3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5B3"
  },
  {
    "uniprot": "Q8ILB5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILB5"
  },
  {
    "uniprot": "Q8IB90",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB90"
  },
  {
    "uniprot": "Q8I4P2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4P2"
  },
  {
    "uniprot": "C6KTB4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTB4"
  },
  {
    "uniprot": "O96122",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96122"
  },
  {
    "uniprot": "Q8IIW5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIW5"
  },
  {
    "uniprot": "Q8IDX1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDX1"
  },
  {
    "uniprot": "C6KTD9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTD9"
  },
  {
    "uniprot": "Q8I0E0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0E0"
  },
  {
    "uniprot": "Q8IBX3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBX3"
  },
  {
    "uniprot": "Q8ILE1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILE1"
  },
  {
    "uniprot": "A0A5K1K9F7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9F7"
  },
  {
    "uniprot": "Q8IDD8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDD8"
  },
  {
    "uniprot": "Q8IED3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IED3"
  },
  {
    "uniprot": "Q8I6T1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6T1"
  },
  {
    "uniprot": "A0A143ZZP8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZP8"
  },
  {
    "uniprot": "Q8IBI4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBI4"
  },
  {
    "uniprot": "Q8I5U7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5U7"
  },
  {
    "uniprot": "Q8IKY5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKY5"
  },
  {
    "uniprot": "Q8I3I3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3I3"
  },
  {
    "uniprot": "Q8I5W9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5W9"
  },
  {
    "uniprot": "C0H493",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H493"
  },
  {
    "uniprot": "Q8II65",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II65"
  },
  {
    "uniprot": "C0H5I3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5I3"
  },
  {
    "uniprot": "Q8IIJ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIJ4"
  },
  {
    "uniprot": "C6KSV0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSV0"
  },
  {
    "uniprot": "A0A143ZWP5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWP5"
  },
  {
    "uniprot": "Q8IKR0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKR0"
  },
  {
    "uniprot": "Q8IBY2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBY2"
  },
  {
    "uniprot": "Q8I5E8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5E8"
  },
  {
    "uniprot": "Q8IJK1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJK1"
  },
  {
    "uniprot": "O77389",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77389"
  },
  {
    "uniprot": "O96283",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96283"
  },
  {
    "uniprot": "Q8IFL7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFL7"
  },
  {
    "uniprot": "Q8IKY9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKY9"
  },
  {
    "uniprot": "A0A5K1K9A9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9A9"
  },
  {
    "uniprot": "A0A5K1K916",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K916"
  },
  {
    "uniprot": "O96111",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96111"
  },
  {
    "uniprot": "A0A5K1K9E7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9E7"
  },
  {
    "uniprot": "Q8IM30",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM30"
  },
  {
    "uniprot": "A0A143ZVM5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVM5"
  },
  {
    "uniprot": "Q9U0L3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0L3"
  },
  {
    "uniprot": "Q8IAK1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAK1"
  },
  {
    "uniprot": "Q8I6V3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6V3"
  },
  {
    "uniprot": "Q8IB46",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB46"
  },
  {
    "uniprot": "Q8I551",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I551"
  },
  {
    "uniprot": "Q8I5V8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5V8"
  },
  {
    "uniprot": "Q8IDA7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDA7"
  },
  {
    "uniprot": "C6S3J1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3J1"
  },
  {
    "uniprot": "Q8IJU0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJU0"
  },
  {
    "uniprot": "Q8I217",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I217"
  },
  {
    "uniprot": "Q8I521",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I521"
  },
  {
    "uniprot": "A0A144A323",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A323"
  },
  {
    "uniprot": "Q8IL17",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL17"
  },
  {
    "uniprot": "Q9U0H1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0H1"
  },
  {
    "uniprot": "Q8IJ33",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ33"
  },
  {
    "uniprot": "Q8IHT6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHT6"
  },
  {
    "uniprot": "Q8IL37",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL37"
  },
  {
    "uniprot": "C6KT39",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT39"
  },
  {
    "uniprot": "Q8IDN6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDN6"
  },
  {
    "uniprot": "Q8ILH8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILH8"
  },
  {
    "uniprot": "Q8ILM9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILM9"
  },
  {
    "uniprot": "C6KSY7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSY7"
  },
  {
    "uniprot": "Q8IDF6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDF6"
  },
  {
    "uniprot": "Q8II50",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II50"
  },
  {
    "uniprot": "Q76NN7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q76NN7"
  },
  {
    "uniprot": "Q8I5U0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5U0"
  },
  {
    "uniprot": "Q8IJR9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJR9"
  },
  {
    "uniprot": "O96114",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96114"
  },
  {
    "uniprot": "Q8I2I7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2I7"
  },
  {
    "uniprot": "C0H4X4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4X4"
  },
  {
    "uniprot": "Q8IAU1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAU1"
  },
  {
    "uniprot": "Q8IKQ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKQ8"
  },
  {
    "uniprot": "Q8II38",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II38"
  },
  {
    "uniprot": "C6KT46",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT46"
  },
  {
    "uniprot": "Q9U0K0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0K0"
  },
  {
    "uniprot": "Q9U0N4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0N4"
  },
  {
    "uniprot": "O77355",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77355"
  },
  {
    "uniprot": "Q8I554",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I554"
  },
  {
    "uniprot": "A0A144A140",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A140"
  },
  {
    "uniprot": "C6S3I9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3I9"
  },
  {
    "uniprot": "Q8IJ10",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ10"
  },
  {
    "uniprot": "C6KSU0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSU0"
  },
  {
    "uniprot": "Q8IKY0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKY0"
  },
  {
    "uniprot": "Q8IJA4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJA4"
  },
  {
    "uniprot": "O77314",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77314"
  },
  {
    "uniprot": "Q25807",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25807"
  },
  {
    "uniprot": "O97321",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97321"
  },
  {
    "uniprot": "Q8IKF4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKF4"
  },
  {
    "uniprot": "C0H4A1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4A1"
  },
  {
    "uniprot": "Q8IJA3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJA3"
  },
  {
    "uniprot": "Q8I629",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I629"
  },
  {
    "uniprot": "Q8IIX0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIX0"
  },
  {
    "uniprot": "C6KT10",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT10"
  },
  {
    "uniprot": "Q8I3L3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3L3"
  },
  {
    "uniprot": "Q8IKD6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKD6"
  },
  {
    "uniprot": "Q8IJT4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJT4"
  },
  {
    "uniprot": "Q8IJZ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJZ4"
  },
  {
    "uniprot": "Q8I628",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I628"
  },
  {
    "uniprot": "O97284",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97284"
  },
  {
    "uniprot": "A0A5K1K969",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K969"
  },
  {
    "uniprot": "Q8I4U3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4U3"
  },
  {
    "uniprot": "Q8I652",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I652"
  },
  {
    "uniprot": "Q8IER0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IER0"
  },
  {
    "uniprot": "Q8IAZ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAZ5"
  },
  {
    "uniprot": "Q8IL20",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL20"
  },
  {
    "uniprot": "Q9NFA0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NFA0"
  },
  {
    "uniprot": "Q8I4N7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4N7"
  },
  {
    "uniprot": "C0H4Q0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Q0"
  },
  {
    "uniprot": "Q9TY98",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9TY98"
  },
  {
    "uniprot": "Q8II76",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II76"
  },
  {
    "uniprot": "A0A143ZYB5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYB5"
  },
  {
    "uniprot": "Q8IJY1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJY1"
  },
  {
    "uniprot": "A0A143ZZC7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZC7"
  },
  {
    "uniprot": "Q8I1Q5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Q5"
  },
  {
    "uniprot": "Q9U0H0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0H0"
  },
  {
    "uniprot": "Q8IKH6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKH6"
  },
  {
    "uniprot": "Q8IJD0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJD0"
  },
  {
    "uniprot": "Q25814",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25814"
  },
  {
    "uniprot": "Q8IJW8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJW8"
  },
  {
    "uniprot": "Q8I2K7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2K7"
  },
  {
    "uniprot": "O97264",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97264"
  },
  {
    "uniprot": "C0H4W7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4W7"
  },
  {
    "uniprot": "Q8IIQ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIQ7"
  },
  {
    "uniprot": "O97300",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97300"
  },
  {
    "uniprot": "Q8I2N2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2N2"
  },
  {
    "uniprot": "O96126",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96126"
  },
  {
    "uniprot": "A0A5K1K9C5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9C5"
  },
  {
    "uniprot": "Q8I6Z3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6Z3"
  },
  {
    "uniprot": "Q8IDG0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDG0"
  },
  {
    "uniprot": "Q9NLB9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NLB9"
  },
  {
    "uniprot": "C6KSQ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSQ2"
  },
  {
    "uniprot": "Q8I5F1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5F1"
  },
  {
    "uniprot": "Q8II52",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II52"
  },
  {
    "uniprot": "B9ZSJ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSJ3"
  },
  {
    "uniprot": "Q8I516",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I516"
  },
  {
    "uniprot": "Q8IKP9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKP9"
  },
  {
    "uniprot": "Q8IJV5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJV5"
  },
  {
    "uniprot": "C0H5F2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5F2"
  },
  {
    "uniprot": "A0A5K1K9G3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9G3"
  },
  {
    "uniprot": "A0A5K1K926",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K926"
  },
  {
    "uniprot": "Q8ILI8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILI8"
  },
  {
    "uniprot": "A0A143ZVB6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVB6"
  },
  {
    "uniprot": "Q8IBU9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBU9"
  },
  {
    "uniprot": "Q8IAP4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAP4"
  },
  {
    "uniprot": "Q76NM2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q76NM2"
  },
  {
    "uniprot": "Q8IEC3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEC3"
  },
  {
    "uniprot": "Q8IM41",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM41"
  },
  {
    "uniprot": "Q8I256",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I256"
  },
  {
    "uniprot": "O96139",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96139"
  },
  {
    "uniprot": "Q8IJ93",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ93"
  },
  {
    "uniprot": "Q8IIC3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIC3"
  },
  {
    "uniprot": "Q8IKM5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKM5"
  },
  {
    "uniprot": "Q8I3Y2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Y2"
  },
  {
    "uniprot": "Q8ILL2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILL2"
  },
  {
    "uniprot": "Q8IBV5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBV5"
  },
  {
    "uniprot": "Q8IDX2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDX2"
  },
  {
    "uniprot": "Q8IK10",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK10"
  },
  {
    "uniprot": "A0A143ZYK0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYK0"
  },
  {
    "uniprot": "Q8I2B4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2B4"
  },
  {
    "uniprot": "Q8II29",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II29"
  },
  {
    "uniprot": "Q8IL86",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL86"
  },
  {
    "uniprot": "C6KSV2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSV2"
  },
  {
    "uniprot": "Q8ILY6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILY6"
  },
  {
    "uniprot": "Q8IHU9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHU9"
  },
  {
    "uniprot": "Q8I555",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I555"
  },
  {
    "uniprot": "Q8I418",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I418"
  },
  {
    "uniprot": "Q8I2W1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2W1"
  },
  {
    "uniprot": "Q8IAW2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAW2"
  },
  {
    "uniprot": "Q8I380",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I380"
  },
  {
    "uniprot": "Q8I2U0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2U0"
  },
  {
    "uniprot": "C6KTA3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTA3"
  },
  {
    "uniprot": "C6KT02",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT02"
  },
  {
    "uniprot": "Q8IJI0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJI0"
  },
  {
    "uniprot": "Q8I5W0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5W0"
  },
  {
    "uniprot": "O97229",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97229"
  },
  {
    "uniprot": "Q8I4U7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4U7"
  },
  {
    "uniprot": "Q8IKI0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKI0"
  },
  {
    "uniprot": "Q8IIM7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIM7"
  },
  {
    "uniprot": "O77331",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77331"
  },
  {
    "uniprot": "O96187",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96187"
  },
  {
    "uniprot": "Q8IKU3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKU3"
  },
  {
    "uniprot": "Q8IC05",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC05"
  },
  {
    "uniprot": "Q8IEQ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEQ7"
  },
  {
    "uniprot": "Q8I4P9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4P9"
  },
  {
    "uniprot": "Q8IE17",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE17"
  },
  {
    "uniprot": "Q8IAK4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAK4"
  },
  {
    "uniprot": "Q8ID32",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID32"
  },
  {
    "uniprot": "Q8I5X8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5X8"
  },
  {
    "uniprot": "Q8IKQ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKQ3"
  },
  {
    "uniprot": "C0H526",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H526"
  },
  {
    "uniprot": "Q8IB60",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB60"
  },
  {
    "uniprot": "C0H5A1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5A1"
  },
  {
    "uniprot": "Q8I2W5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2W5"
  },
  {
    "uniprot": "Q8I2B2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2B2"
  },
  {
    "uniprot": "Q8IKC5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKC5"
  },
  {
    "uniprot": "Q8I627",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I627"
  },
  {
    "uniprot": "C6KT63",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT63"
  },
  {
    "uniprot": "Q8IDF7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDF7"
  },
  {
    "uniprot": "Q8IJN6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJN6"
  },
  {
    "uniprot": "Q8I5A4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5A4"
  },
  {
    "uniprot": "Q8I4X1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4X1"
  },
  {
    "uniprot": "C6KSV4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSV4"
  },
  {
    "uniprot": "C6KT70",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT70"
  },
  {
    "uniprot": "Q8IJU2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJU2"
  },
  {
    "uniprot": "Q8IDR5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDR5"
  },
  {
    "uniprot": "O96233",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96233"
  },
  {
    "uniprot": "Q8IJD2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJD2"
  },
  {
    "uniprot": "C0H481",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H481"
  },
  {
    "uniprot": "Q8IEQ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEQ1"
  },
  {
    "uniprot": "Q8I5J9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5J9"
  },
  {
    "uniprot": "Q8I3I2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3I2"
  },
  {
    "uniprot": "Q8ILE8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILE8"
  },
  {
    "uniprot": "Q8IM40",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM40"
  },
  {
    "uniprot": "Q8IK81",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK81"
  },
  {
    "uniprot": "Q8II70",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II70"
  },
  {
    "uniprot": "Q8IDZ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDZ6"
  },
  {
    "uniprot": "Q8I319",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I319"
  },
  {
    "uniprot": "Q8I458",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I458"
  },
  {
    "uniprot": "Q8IEJ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEJ1"
  },
  {
    "uniprot": "Q8IJY2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJY2"
  },
  {
    "uniprot": "A0A143ZVW0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVW0"
  },
  {
    "uniprot": "Q8IKD8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKD8"
  },
  {
    "uniprot": "C0H4U8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4U8"
  },
  {
    "uniprot": "A0A5K1K8T3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8T3"
  },
  {
    "uniprot": "C0H5F8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5F8"
  },
  {
    "uniprot": "C0H5C4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5C4"
  },
  {
    "uniprot": "Q8IIT5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIT5"
  },
  {
    "uniprot": "Q8IIQ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIQ0"
  },
  {
    "uniprot": "Q8IJW5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJW5"
  },
  {
    "uniprot": "Q8ILL1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILL1"
  },
  {
    "uniprot": "Q8IC07",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC07"
  },
  {
    "uniprot": "Q8IAN1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAN1"
  },
  {
    "uniprot": "A0A144A1V5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1V5"
  },
  {
    "uniprot": "Q8IJA5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJA5"
  },
  {
    "uniprot": "A0A5K1K8K9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8K9"
  },
  {
    "uniprot": "A0A5K1K8P5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8P5"
  },
  {
    "uniprot": "A0A143ZWJ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWJ2"
  },
  {
    "uniprot": "Q8IKD9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKD9"
  },
  {
    "uniprot": "Q8I3S3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3S3"
  },
  {
    "uniprot": "Q8IJK2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJK2"
  },
  {
    "uniprot": "A0A143ZYC4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYC4"
  },
  {
    "uniprot": "C6KSS1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSS1"
  },
  {
    "uniprot": "C6S3I4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3I4"
  },
  {
    "uniprot": "A0A143ZYJ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYJ4"
  },
  {
    "uniprot": "Q8IHR1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHR1"
  },
  {
    "uniprot": "C0H4L0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4L0"
  },
  {
    "uniprot": "Q8IFM2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFM2"
  },
  {
    "uniprot": "C0H4K1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4K1"
  },
  {
    "uniprot": "Q8I359",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I359"
  },
  {
    "uniprot": "C0H4R5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4R5"
  },
  {
    "uniprot": "O97274",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97274"
  },
  {
    "uniprot": "Q8I0V1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0V1"
  },
  {
    "uniprot": "Q8IB79",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB79"
  },
  {
    "uniprot": "Q8IKA9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKA9"
  },
  {
    "uniprot": "O97329",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97329"
  },
  {
    "uniprot": "Q8IHR5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHR5"
  },
  {
    "uniprot": "Q8I614",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I614"
  },
  {
    "uniprot": "C0H4R4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4R4"
  },
  {
    "uniprot": "Q8ILG4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILG4"
  },
  {
    "uniprot": "Q8IJW3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJW3"
  },
  {
    "uniprot": "C6S3I6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3I6"
  },
  {
    "uniprot": "Q8I2K4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2K4"
  },
  {
    "uniprot": "Q8ILC5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILC5"
  },
  {
    "uniprot": "Q8IFN1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFN1"
  },
  {
    "uniprot": "Q8IB20",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB20"
  },
  {
    "uniprot": "A0A143ZWH7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWH7"
  },
  {
    "uniprot": "Q8IK83",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK83"
  },
  {
    "uniprot": "C6KT27",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT27"
  },
  {
    "uniprot": "Q8I372",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I372"
  },
  {
    "uniprot": "C0H472",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H472"
  },
  {
    "uniprot": "C0H5D4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5D4"
  },
  {
    "uniprot": "Q8IBN1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBN1"
  },
  {
    "uniprot": "Q8IM18",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM18"
  },
  {
    "uniprot": "Q8II18",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II18"
  },
  {
    "uniprot": "Q8IIM9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIM9"
  },
  {
    "uniprot": "A0A143ZZZ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZZ9"
  },
  {
    "uniprot": "C0H4S8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4S8"
  },
  {
    "uniprot": "Q8I3I5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3I5"
  },
  {
    "uniprot": "Q8I465",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I465"
  },
  {
    "uniprot": "A0A146M158",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A146M158"
  },
  {
    "uniprot": "Q8IAU0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAU0"
  },
  {
    "uniprot": "Q8IHP4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHP4"
  },
  {
    "uniprot": "Q8I3Q5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Q5"
  },
  {
    "uniprot": "C6KSP8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSP8"
  },
  {
    "uniprot": "C6KT59",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT59"
  },
  {
    "uniprot": "Q8IJ45",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ45"
  },
  {
    "uniprot": "Q8I335",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I335"
  },
  {
    "uniprot": "C6KT38",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT38"
  },
  {
    "uniprot": "A0A144A659",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A659"
  },
  {
    "uniprot": "Q8IJA9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJA9"
  },
  {
    "uniprot": "Q8II03",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II03"
  },
  {
    "uniprot": "C6KSN8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSN8"
  },
  {
    "uniprot": "Q8III4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8III4"
  },
  {
    "uniprot": "Q8I5H2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5H2"
  },
  {
    "uniprot": "Q8ILX7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILX7"
  },
  {
    "uniprot": "O96258",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96258"
  },
  {
    "uniprot": "Q8I3E4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3E4"
  },
  {
    "uniprot": "Q5MYR6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q5MYR6"
  },
  {
    "uniprot": "Q8IKM2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKM2"
  },
  {
    "uniprot": "B3FEM7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B3FEM7"
  },
  {
    "uniprot": "Q8IKB0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKB0"
  },
  {
    "uniprot": "O77385",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77385"
  },
  {
    "uniprot": "C0H4A9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4A9"
  },
  {
    "uniprot": "A0A5K1K8R6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8R6"
  },
  {
    "uniprot": "C6KT37",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT37"
  },
  {
    "uniprot": "C6S3J2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3J2"
  },
  {
    "uniprot": "Q8I3Z2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Z2"
  },
  {
    "uniprot": "Q8I347",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I347"
  },
  {
    "uniprot": "Q8IJN3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJN3"
  },
  {
    "uniprot": "Q8I334",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I334"
  },
  {
    "uniprot": "Q8IIE9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIE9"
  },
  {
    "uniprot": "A0A143ZW95",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZW95"
  },
  {
    "uniprot": "O97243",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97243"
  },
  {
    "uniprot": "Q8I665",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I665"
  },
  {
    "uniprot": "Q8IKK2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKK2"
  },
  {
    "uniprot": "A0A5K1K8W6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8W6"
  },
  {
    "uniprot": "C0H4U1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4U1"
  },
  {
    "uniprot": "Q8IFL1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFL1"
  },
  {
    "uniprot": "Q8I248",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I248"
  },
  {
    "uniprot": "Q8I5Q1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Q1"
  },
  {
    "uniprot": "Q8IKB3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKB3"
  },
  {
    "uniprot": "A0A143ZV68",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZV68"
  },
  {
    "uniprot": "C0H5A9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5A9"
  },
  {
    "uniprot": "Q8IBC1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBC1"
  },
  {
    "uniprot": "Q8IBP6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBP6"
  },
  {
    "uniprot": "A0A5K1K8T1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8T1"
  },
  {
    "uniprot": "Q8I0U6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0U6"
  },
  {
    "uniprot": "Q8IHS2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHS2"
  },
  {
    "uniprot": "Q8I2T4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2T4"
  },
  {
    "uniprot": "Q8IIH1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIH1"
  },
  {
    "uniprot": "Q8IBH5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBH5"
  },
  {
    "uniprot": "Q8IAK7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAK7"
  },
  {
    "uniprot": "Q8IJL0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJL0"
  },
  {
    "uniprot": "Q8IKJ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKJ6"
  },
  {
    "uniprot": "A0A5K1K904",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K904"
  },
  {
    "uniprot": "Q8IAR6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAR6"
  },
  {
    "uniprot": "Q8I407",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I407"
  },
  {
    "uniprot": "Q8IJ37",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ37"
  },
  {
    "uniprot": "O77364",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77364"
  },
  {
    "uniprot": "Q8I4P7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4P7"
  },
  {
    "uniprot": "Q8IEL9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEL9"
  },
  {
    "uniprot": "A0A5K1K8U0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8U0"
  },
  {
    "uniprot": "O96112",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96112"
  },
  {
    "uniprot": "Q8I4P3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4P3"
  },
  {
    "uniprot": "C6KSU1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSU1"
  },
  {
    "uniprot": "Q8I2Q7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Q7"
  },
  {
    "uniprot": "C6S3H4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3H4"
  },
  {
    "uniprot": "C6KSR3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSR3"
  },
  {
    "uniprot": "Q8I4U8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4U8"
  },
  {
    "uniprot": "Q8IJT7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJT7"
  },
  {
    "uniprot": "Q8IL75",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL75"
  },
  {
    "uniprot": "Q7KQL4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KQL4"
  },
  {
    "uniprot": "Q25809",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25809"
  },
  {
    "uniprot": "Q8IEM5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEM5"
  },
  {
    "uniprot": "O96121",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96121"
  },
  {
    "uniprot": "Q8I605",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I605"
  },
  {
    "uniprot": "Q25815",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25815"
  },
  {
    "uniprot": "Q8IJH3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJH3"
  },
  {
    "uniprot": "O77312",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77312"
  },
  {
    "uniprot": "Q8I0W6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0W6"
  },
  {
    "uniprot": "A0A143ZWI3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWI3"
  },
  {
    "uniprot": "C6KSM8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSM8"
  },
  {
    "uniprot": "C0H515",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H515"
  },
  {
    "uniprot": "O96230",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96230"
  },
  {
    "uniprot": "Q8IKL9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKL9"
  },
  {
    "uniprot": "Q8IDM7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDM7"
  },
  {
    "uniprot": "Q8IJP8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJP8"
  },
  {
    "uniprot": "Q8I5M5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5M5"
  },
  {
    "uniprot": "Q8I1U2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1U2"
  },
  {
    "uniprot": "Q8IDP1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDP1"
  },
  {
    "uniprot": "Q8IDU9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDU9"
  },
  {
    "uniprot": "Q9U0I5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0I5"
  },
  {
    "uniprot": "Q8IBF1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBF1"
  },
  {
    "uniprot": "Q8IBZ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBZ7"
  },
  {
    "uniprot": "Q8I5Q3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Q3"
  },
  {
    "uniprot": "Q8I2R7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2R7"
  },
  {
    "uniprot": "Q8I1Z8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Z8"
  },
  {
    "uniprot": "Q8IBB8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBB8"
  },
  {
    "uniprot": "Q8IJR5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJR5"
  },
  {
    "uniprot": "A0A144A022",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A022"
  },
  {
    "uniprot": "O77350",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77350"
  },
  {
    "uniprot": "Q8IDH9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDH9"
  },
  {
    "uniprot": "Q8IE10",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE10"
  },
  {
    "uniprot": "Q8IEH3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEH3"
  },
  {
    "uniprot": "A0A143ZY53",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZY53"
  },
  {
    "uniprot": "C6S3K6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3K6"
  },
  {
    "uniprot": "Q8IKJ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKJ4"
  },
  {
    "uniprot": "Q8ILJ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILJ6"
  },
  {
    "uniprot": "Q8I2H0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2H0"
  },
  {
    "uniprot": "A0A146M1R1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A146M1R1"
  },
  {
    "uniprot": "C6KTC4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTC4"
  },
  {
    "uniprot": "A0A5K1K846",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K846"
  },
  {
    "uniprot": "O97266",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97266"
  },
  {
    "uniprot": "Q8IIS1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIS1"
  },
  {
    "uniprot": "Q8I405",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I405"
  },
  {
    "uniprot": "Q8IJF8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJF8"
  },
  {
    "uniprot": "Q8IKU6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKU6"
  },
  {
    "uniprot": "Q8I427",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I427"
  },
  {
    "uniprot": "Q8IER7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IER7"
  },
  {
    "uniprot": "Q8IEU7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEU7"
  },
  {
    "uniprot": "Q8IIJ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIJ2"
  },
  {
    "uniprot": "Q8ID49",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID49"
  },
  {
    "uniprot": "A0A144A0N0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0N0"
  },
  {
    "uniprot": "Q8IHU6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHU6"
  },
  {
    "uniprot": "C0H5L2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5L2"
  },
  {
    "uniprot": "Q8I5X9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5X9"
  },
  {
    "uniprot": "C6S3D4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3D4"
  },
  {
    "uniprot": "Q8I4W5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4W5"
  },
  {
    "uniprot": "Q8IBT3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBT3"
  },
  {
    "uniprot": "Q8I5S5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5S5"
  },
  {
    "uniprot": "Q8IB83",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB83"
  },
  {
    "uniprot": "C0H4G3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4G3"
  },
  {
    "uniprot": "Q8I412",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I412"
  },
  {
    "uniprot": "Q8IIQ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIQ9"
  },
  {
    "uniprot": "C0H4C0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4C0"
  },
  {
    "uniprot": "C0H5E2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5E2"
  },
  {
    "uniprot": "Q8IET0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IET0"
  },
  {
    "uniprot": "Q8ILG9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILG9"
  },
  {
    "uniprot": "Q8I4T9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4T9"
  },
  {
    "uniprot": "Q6ZMA7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q6ZMA7"
  },
  {
    "uniprot": "Q8I387",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I387"
  },
  {
    "uniprot": "Q8IKW4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKW4"
  },
  {
    "uniprot": "A0A5K1K8V9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8V9"
  },
  {
    "uniprot": "Q8IJQ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJQ1"
  },
  {
    "uniprot": "Q8ILB7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILB7"
  },
  {
    "uniprot": "Q8IIV6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIV6"
  },
  {
    "uniprot": "O97227",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97227"
  },
  {
    "uniprot": "A0A143ZYR7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYR7"
  },
  {
    "uniprot": "Q8II98",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II98"
  },
  {
    "uniprot": "C6S3E7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3E7"
  },
  {
    "uniprot": "A0A5K1K8Q9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8Q9"
  },
  {
    "uniprot": "Q8I4Q9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Q9"
  },
  {
    "uniprot": "Q8IIA9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIA9"
  },
  {
    "uniprot": "C6KSW6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSW6"
  },
  {
    "uniprot": "Q8IJP2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJP2"
  },
  {
    "uniprot": "Q8IJ11",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ11"
  },
  {
    "uniprot": "Q8IIU7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIU7"
  },
  {
    "uniprot": "Q8IHS7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHS7"
  },
  {
    "uniprot": "Q8I2N3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2N3"
  },
  {
    "uniprot": "A0A143ZZN7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZN7"
  },
  {
    "uniprot": "O97303",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97303"
  },
  {
    "uniprot": "Q8I3I6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3I6"
  },
  {
    "uniprot": "Q8I394",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I394"
  },
  {
    "uniprot": "Q8II19",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II19"
  },
  {
    "uniprot": "Q8IK57",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK57"
  },
  {
    "uniprot": "A0A144A118",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A118"
  },
  {
    "uniprot": "Q8I5R9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5R9"
  },
  {
    "uniprot": "Q8ILY0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILY0"
  },
  {
    "uniprot": "Q8I2Q5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Q5"
  },
  {
    "uniprot": "Q8IJW6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJW6"
  },
  {
    "uniprot": "A0A143ZZK9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZK9"
  },
  {
    "uniprot": "Q8I3U0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3U0"
  },
  {
    "uniprot": "Q8ILD1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILD1"
  },
  {
    "uniprot": "Q8IKP0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKP0"
  },
  {
    "uniprot": "Q8IIA3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIA3"
  },
  {
    "uniprot": "Q7KQL7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KQL7"
  },
  {
    "uniprot": "Q76NM1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q76NM1"
  },
  {
    "uniprot": "Q8IE72",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE72"
  },
  {
    "uniprot": "Q8I5Z3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Z3"
  },
  {
    "uniprot": "A0A144A3E6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3E6"
  },
  {
    "uniprot": "C6S3K9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3K9"
  },
  {
    "uniprot": "A0A144A0C5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0C5"
  },
  {
    "uniprot": "Q8I656",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I656"
  },
  {
    "uniprot": "Q8IB66",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB66"
  },
  {
    "uniprot": "Q8I4Y4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Y4"
  },
  {
    "uniprot": "Q8ILW4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILW4"
  },
  {
    "uniprot": "O96293",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96293"
  },
  {
    "uniprot": "A0A143ZXP1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXP1"
  },
  {
    "uniprot": "Q8IIW6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIW6"
  },
  {
    "uniprot": "A0A144A3J2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3J2"
  },
  {
    "uniprot": "Q8I2A3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2A3"
  },
  {
    "uniprot": "Q7KQM5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KQM5"
  },
  {
    "uniprot": "Q8IIA7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIA7"
  },
  {
    "uniprot": "Q8IDE7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDE7"
  },
  {
    "uniprot": "C0H4P9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4P9"
  },
  {
    "uniprot": "Q8II21",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II21"
  },
  {
    "uniprot": "Q8I3Z8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Z8"
  },
  {
    "uniprot": "Q8ID92",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID92"
  },
  {
    "uniprot": "Q8IEK3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEK3"
  },
  {
    "uniprot": "C0H5H0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5H0"
  },
  {
    "uniprot": "O97462",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97462"
  },
  {
    "uniprot": "Q8IKD7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKD7"
  },
  {
    "uniprot": "Q8I5E0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5E0"
  },
  {
    "uniprot": "Q8I1T3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1T3"
  },
  {
    "uniprot": "Q8IEU3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEU3"
  },
  {
    "uniprot": "A0A143ZZN8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZN8"
  },
  {
    "uniprot": "O77325",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77325"
  },
  {
    "uniprot": "Q8I286",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I286"
  },
  {
    "uniprot": "Q8IIF8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIF8"
  },
  {
    "uniprot": "A0A146M1Y0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A146M1Y0"
  },
  {
    "uniprot": "Q8I1T7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1T7"
  },
  {
    "uniprot": "Q8I441",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I441"
  },
  {
    "uniprot": "Q8IEF3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEF3"
  },
  {
    "uniprot": "O96159",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96159"
  },
  {
    "uniprot": "Q8ILQ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILQ0"
  },
  {
    "uniprot": "A0A5K1K937",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K937"
  },
  {
    "uniprot": "Q8IBL4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBL4"
  },
  {
    "uniprot": "Q9U5M0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U5M0"
  },
  {
    "uniprot": "Q8IHS8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHS8"
  },
  {
    "uniprot": "Q8I542",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I542"
  },
  {
    "uniprot": "Q8IBW3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBW3"
  },
  {
    "uniprot": "Q8IKR4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKR4"
  },
  {
    "uniprot": "Q8I1Z2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Z2"
  },
  {
    "uniprot": "Q8II17",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II17"
  },
  {
    "uniprot": "Q8IED6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IED6"
  },
  {
    "uniprot": "Q8I2S9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2S9"
  },
  {
    "uniprot": "Q8IE43",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE43"
  },
  {
    "uniprot": "Q8IK85",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK85"
  },
  {
    "uniprot": "A0A143ZVL3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVL3"
  },
  {
    "uniprot": "Q8I2R3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2R3"
  },
  {
    "uniprot": "O77361",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77361"
  },
  {
    "uniprot": "Q8ILJ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILJ0"
  },
  {
    "uniprot": "Q8I1V9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1V9"
  },
  {
    "uniprot": "C6KT66",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT66"
  },
  {
    "uniprot": "Q8IM36",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM36"
  },
  {
    "uniprot": "Q8I5W4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5W4"
  },
  {
    "uniprot": "Q8I501",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I501"
  },
  {
    "uniprot": "Q8IEA6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEA6"
  },
  {
    "uniprot": "A0A143ZZQ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZQ0"
  },
  {
    "uniprot": "Q8I328",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I328"
  },
  {
    "uniprot": "Q8I266",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I266"
  },
  {
    "uniprot": "Q8IFL4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFL4"
  },
  {
    "uniprot": "Q8I621",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I621"
  },
  {
    "uniprot": "C0H585",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H585"
  },
  {
    "uniprot": "Q8I534",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I534"
  },
  {
    "uniprot": "Q8IK04",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK04"
  },
  {
    "uniprot": "Q8I0Q0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0Q0"
  },
  {
    "uniprot": "Q8IKN2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKN2"
  },
  {
    "uniprot": "Q8IB59",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB59"
  },
  {
    "uniprot": "O96192",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96192"
  },
  {
    "uniprot": "Q8I349",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I349"
  },
  {
    "uniprot": "Q8IES9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IES9"
  },
  {
    "uniprot": "C0H4S6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4S6"
  },
  {
    "uniprot": "Q8ILF3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILF3"
  },
  {
    "uniprot": "Q8I433",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I433"
  },
  {
    "uniprot": "C6KST3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KST3"
  },
  {
    "uniprot": "Q8IJ09",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ09"
  },
  {
    "uniprot": "O96109",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96109"
  },
  {
    "uniprot": "C0H4S0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4S0"
  },
  {
    "uniprot": "C0H4L7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4L7"
  },
  {
    "uniprot": "C6KSL8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSL8"
  },
  {
    "uniprot": "Q8IM16",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM16"
  },
  {
    "uniprot": "Q8IJI4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJI4"
  },
  {
    "uniprot": "C6KT06",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT06"
  },
  {
    "uniprot": "C0H519",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H519"
  },
  {
    "uniprot": "O96237",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96237"
  },
  {
    "uniprot": "A0A144A3H8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3H8"
  },
  {
    "uniprot": "Q8IBL6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBL6"
  },
  {
    "uniprot": "Q8IK41",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK41"
  },
  {
    "uniprot": "Q8I6Z8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6Z8"
  },
  {
    "uniprot": "C0H4N6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4N6"
  },
  {
    "uniprot": "Q8I2C5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2C5"
  },
  {
    "uniprot": "A0A5K1K8J3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8J3"
  },
  {
    "uniprot": "Q8I4Y8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Y8"
  },
  {
    "uniprot": "Q8IKS5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKS5"
  },
  {
    "uniprot": "Q8I0X4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0X4"
  },
  {
    "uniprot": "C6KT94",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT94"
  },
  {
    "uniprot": "A0A5K1K8U8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8U8"
  },
  {
    "uniprot": "Q8I2M8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2M8"
  },
  {
    "uniprot": "Q8I3Z4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Z4"
  },
  {
    "uniprot": "Q8ILE7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILE7"
  },
  {
    "uniprot": "Q6ZLZ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q6ZLZ9"
  },
  {
    "uniprot": "Q8I1S5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1S5"
  },
  {
    "uniprot": "C0H4Z6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Z6"
  },
  {
    "uniprot": "Q8I3B0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3B0"
  },
  {
    "uniprot": "Q8IJG7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJG7"
  },
  {
    "uniprot": "A0A5K1K982",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K982"
  },
  {
    "uniprot": "C0H467",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H467"
  },
  {
    "uniprot": "Q8IAL0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAL0"
  },
  {
    "uniprot": "O77357",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77357"
  },
  {
    "uniprot": "Q8I3Q1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Q1"
  },
  {
    "uniprot": "Q8IKZ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKZ9"
  },
  {
    "uniprot": "Q8I275",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I275"
  },
  {
    "uniprot": "Q8I2D5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2D5"
  },
  {
    "uniprot": "A0A5K1K977",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K977"
  },
  {
    "uniprot": "C0H5M6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5M6"
  },
  {
    "uniprot": "Q8ILE0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILE0"
  },
  {
    "uniprot": "Q76NN8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q76NN8"
  },
  {
    "uniprot": "A0A5K1K8U3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8U3"
  },
  {
    "uniprot": "Q8I3S7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3S7"
  },
  {
    "uniprot": "C0H5F6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5F6"
  },
  {
    "uniprot": "O96128",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96128"
  },
  {
    "uniprot": "Q8I630",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I630"
  },
  {
    "uniprot": "Q8IBM5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBM5"
  },
  {
    "uniprot": "Q8IE80",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE80"
  },
  {
    "uniprot": "Q8IET5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IET5"
  },
  {
    "uniprot": "Q8IKR6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKR6"
  },
  {
    "uniprot": "Q8I2T0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2T0"
  },
  {
    "uniprot": "C0H4N8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4N8"
  },
  {
    "uniprot": "Q25801",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25801"
  },
  {
    "uniprot": "O97285",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97285"
  },
  {
    "uniprot": "O77308",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77308"
  },
  {
    "uniprot": "Q8IBR0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBR0"
  },
  {
    "uniprot": "Q8I3X2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3X2"
  },
  {
    "uniprot": "Q8IHU5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHU5"
  },
  {
    "uniprot": "Q8IIZ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIZ8"
  },
  {
    "uniprot": "Q8IBJ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBJ8"
  },
  {
    "uniprot": "Q8I3X4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3X4"
  },
  {
    "uniprot": "Q8I353",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I353"
  },
  {
    "uniprot": "Q8I5C9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5C9"
  },
  {
    "uniprot": "Q8IC45",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC45"
  },
  {
    "uniprot": "Q8I2C0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2C0"
  },
  {
    "uniprot": "Q7KWJ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KWJ5"
  },
  {
    "uniprot": "Q8I4X3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4X3"
  },
  {
    "uniprot": "Q8IM75",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM75"
  },
  {
    "uniprot": "Q8IBH2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBH2"
  },
  {
    "uniprot": "O77370",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77370"
  },
  {
    "uniprot": "Q8I584",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I584"
  },
  {
    "uniprot": "Q8IBI9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBI9"
  },
  {
    "uniprot": "Q8IL89",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL89"
  },
  {
    "uniprot": "C0H4X1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4X1"
  },
  {
    "uniprot": "Q8IIL8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIL8"
  },
  {
    "uniprot": "Q8IJJ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJJ8"
  },
  {
    "uniprot": "Q8II73",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II73"
  },
  {
    "uniprot": "C0H524",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H524"
  },
  {
    "uniprot": "Q8IES0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IES0"
  },
  {
    "uniprot": "A0A143ZXG1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXG1"
  },
  {
    "uniprot": "Q8IIF1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIF1"
  },
  {
    "uniprot": "C6KT52",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT52"
  },
  {
    "uniprot": "A0A143ZXI1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXI1"
  },
  {
    "uniprot": "C0H4W6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4W6"
  },
  {
    "uniprot": "O96275",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96275"
  },
  {
    "uniprot": "Q8IE90",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE90"
  },
  {
    "uniprot": "Q8I3U8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3U8"
  },
  {
    "uniprot": "O96183",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96183"
  },
  {
    "uniprot": "O96240",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96240"
  },
  {
    "uniprot": "Q8IAX9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAX9"
  },
  {
    "uniprot": "Q8I4W1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4W1"
  },
  {
    "uniprot": "Q8I378",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I378"
  },
  {
    "uniprot": "A0A144A1B2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1B2"
  },
  {
    "uniprot": "Q8IBZ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBZ5"
  },
  {
    "uniprot": "C0H5M9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5M9"
  },
  {
    "uniprot": "Q8I3A2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3A2"
  },
  {
    "uniprot": "Q8I5Q8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Q8"
  },
  {
    "uniprot": "C0H4M9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4M9"
  },
  {
    "uniprot": "Q8IBY3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBY3"
  },
  {
    "uniprot": "Q8I486",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I486"
  },
  {
    "uniprot": "Q8IKX6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKX6"
  },
  {
    "uniprot": "Q8I701",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I701"
  },
  {
    "uniprot": "Q8IKR3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKR3"
  },
  {
    "uniprot": "Q8I403",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I403"
  },
  {
    "uniprot": "O96182",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96182"
  },
  {
    "uniprot": "Q8I657",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I657"
  },
  {
    "uniprot": "A0A5K1K912",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K912"
  },
  {
    "uniprot": "Q8IKX8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKX8"
  },
  {
    "uniprot": "Q8IHR4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHR4"
  },
  {
    "uniprot": "O97242",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97242"
  },
  {
    "uniprot": "Q8IM21",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM21"
  },
  {
    "uniprot": "Q8IKU2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKU2"
  },
  {
    "uniprot": "C0H4A6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4A6"
  },
  {
    "uniprot": "Q8IE68",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE68"
  },
  {
    "uniprot": "Q8IM42",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM42"
  },
  {
    "uniprot": "Q8I320",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I320"
  },
  {
    "uniprot": "Q9U0H6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0H6"
  },
  {
    "uniprot": "Q9U0I6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0I6"
  },
  {
    "uniprot": "Q8IB71",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB71"
  },
  {
    "uniprot": "Q8ILJ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILJ3"
  },
  {
    "uniprot": "Q8I448",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I448"
  },
  {
    "uniprot": "Q8IJX7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJX7"
  },
  {
    "uniprot": "Q8ILE3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILE3"
  },
  {
    "uniprot": "A0A143ZZG2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZG2"
  },
  {
    "uniprot": "Q8IKC6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKC6"
  },
  {
    "uniprot": "Q8IDF8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDF8"
  },
  {
    "uniprot": "A0A143ZZL4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZL4"
  },
  {
    "uniprot": "C6KT23",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT23"
  },
  {
    "uniprot": "Q8ILZ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILZ0"
  },
  {
    "uniprot": "Q8IK92",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK92"
  },
  {
    "uniprot": "C6KSY2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSY2"
  },
  {
    "uniprot": "Q8IC44",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC44"
  },
  {
    "uniprot": "C0H494",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H494"
  },
  {
    "uniprot": "C6KSR0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSR0"
  },
  {
    "uniprot": "Q8ID28",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID28"
  },
  {
    "uniprot": "O96171",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96171"
  },
  {
    "uniprot": "Q8IB78",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB78"
  },
  {
    "uniprot": "A0A144A2N8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2N8"
  },
  {
    "uniprot": "Q8ILW7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILW7"
  },
  {
    "uniprot": "A0A5K1K994",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K994"
  },
  {
    "uniprot": "Q8I332",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I332"
  },
  {
    "uniprot": "Q8IK21",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK21"
  },
  {
    "uniprot": "Q8IBK2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBK2"
  },
  {
    "uniprot": "C6S3K7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3K7"
  },
  {
    "uniprot": "A0A144A153",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A153"
  },
  {
    "uniprot": "Q8IJG1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJG1"
  },
  {
    "uniprot": "Q8IJY3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJY3"
  },
  {
    "uniprot": "Q8I2J4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2J4"
  },
  {
    "uniprot": "O97235",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97235"
  },
  {
    "uniprot": "Q25806",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25806"
  },
  {
    "uniprot": "Q8ILV5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILV5"
  },
  {
    "uniprot": "Q8IIU5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIU5"
  },
  {
    "uniprot": "C6KT03",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT03"
  },
  {
    "uniprot": "Q8IIF7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIF7"
  },
  {
    "uniprot": "Q8I5I8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5I8"
  },
  {
    "uniprot": "Q8I459",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I459"
  },
  {
    "uniprot": "C6KT60",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT60"
  },
  {
    "uniprot": "C6KSM5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSM5"
  },
  {
    "uniprot": "O96196",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96196"
  },
  {
    "uniprot": "C6KSS6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSS6"
  },
  {
    "uniprot": "Q8IB02",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB02"
  },
  {
    "uniprot": "O96236",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96236"
  },
  {
    "uniprot": "Q8I226",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I226"
  },
  {
    "uniprot": "Q8I1S6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1S6"
  },
  {
    "uniprot": "Q8IKU5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKU5"
  },
  {
    "uniprot": "Q8IC04",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC04"
  },
  {
    "uniprot": "Q8I700",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I700"
  },
  {
    "uniprot": "Q8I2X8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2X8"
  },
  {
    "uniprot": "Q8IAX8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAX8"
  },
  {
    "uniprot": "Q8IJ50",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ50"
  },
  {
    "uniprot": "O77397",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77397"
  },
  {
    "uniprot": "A0A144A0T9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0T9"
  },
  {
    "uniprot": "Q8I5F5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5F5"
  },
  {
    "uniprot": "Q8IIR7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIR7"
  },
  {
    "uniprot": "C6S3G9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3G9"
  },
  {
    "uniprot": "Q8I3K8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3K8"
  },
  {
    "uniprot": "Q8IKW1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKW1"
  },
  {
    "uniprot": "A0A5K1K8A0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8A0"
  },
  {
    "uniprot": "O96279",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96279"
  },
  {
    "uniprot": "A0A143ZWN4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWN4"
  },
  {
    "uniprot": "Q8IIV9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIV9"
  },
  {
    "uniprot": "Q8I4Z4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Z4"
  },
  {
    "uniprot": "A0A143ZXZ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXZ0"
  },
  {
    "uniprot": "Q8I473",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I473"
  },
  {
    "uniprot": "Q8IIC9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIC9"
  },
  {
    "uniprot": "A0A5K1K985",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K985"
  },
  {
    "uniprot": "Q8I5J8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5J8"
  },
  {
    "uniprot": "C6KT08",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT08"
  },
  {
    "uniprot": "A0A143ZYA4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYA4"
  },
  {
    "uniprot": "Q8IJ96",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ96"
  },
  {
    "uniprot": "Q8IIS6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIS6"
  },
  {
    "uniprot": "Q8IEM7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEM7"
  },
  {
    "uniprot": "Q8ILW0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILW0"
  },
  {
    "uniprot": "C0H5K7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5K7"
  },
  {
    "uniprot": "A0A143ZVB3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVB3"
  },
  {
    "uniprot": "Q8IBK3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBK3"
  },
  {
    "uniprot": "C0H4A0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4A0"
  },
  {
    "uniprot": "Q8ILH9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILH9"
  },
  {
    "uniprot": "Q8I2Y4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Y4"
  },
  {
    "uniprot": "Q8I596",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I596"
  },
  {
    "uniprot": "Q8IKP2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKP2"
  },
  {
    "uniprot": "O96256",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96256"
  },
  {
    "uniprot": "A0A144A3H3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3H3"
  },
  {
    "uniprot": "C0H516",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H516"
  },
  {
    "uniprot": "C0H588",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H588"
  },
  {
    "uniprot": "Q8III6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8III6"
  },
  {
    "uniprot": "Q8IHV5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHV5"
  },
  {
    "uniprot": "Q8IKD5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKD5"
  },
  {
    "uniprot": "Q8I3C3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3C3"
  },
  {
    "uniprot": "Q8I2P2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2P2"
  },
  {
    "uniprot": "Q8ILZ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILZ3"
  },
  {
    "uniprot": "Q8I234",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I234"
  },
  {
    "uniprot": "Q8IDC6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDC6"
  },
  {
    "uniprot": "Q8I4W0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4W0"
  },
  {
    "uniprot": "Q8I713",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I713"
  },
  {
    "uniprot": "Q8IKN3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKN3"
  },
  {
    "uniprot": "Q8II16",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II16"
  },
  {
    "uniprot": "Q8I4V3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4V3"
  },
  {
    "uniprot": "Q8IJ80",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ80"
  },
  {
    "uniprot": "Q8III2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8III2"
  },
  {
    "uniprot": "Q8ILP3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILP3"
  },
  {
    "uniprot": "A0A5K1K957",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K957"
  },
  {
    "uniprot": "Q8ILE6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILE6"
  },
  {
    "uniprot": "Q8IM52",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM52"
  },
  {
    "uniprot": "Q8IK03",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK03"
  },
  {
    "uniprot": "Q8IIE8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIE8"
  },
  {
    "uniprot": "Q8IM64",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM64"
  },
  {
    "uniprot": "A0A143ZV51",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZV51"
  },
  {
    "uniprot": "Q8I2V9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2V9"
  },
  {
    "uniprot": "Q8IIQ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIQ1"
  },
  {
    "uniprot": "C6KT13",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT13"
  },
  {
    "uniprot": "Q8IAX3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAX3"
  },
  {
    "uniprot": "Q8IKS1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKS1"
  },
  {
    "uniprot": "C0H4D0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4D0"
  },
  {
    "uniprot": "Q8IHP6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHP6"
  },
  {
    "uniprot": "C6KSV5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSV5"
  },
  {
    "uniprot": "Q7KQK3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KQK3"
  },
  {
    "uniprot": "A0A143ZY93",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZY93"
  },
  {
    "uniprot": "Q8I530",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I530"
  },
  {
    "uniprot": "Q8IE58",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE58"
  },
  {
    "uniprot": "Q8IE85",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE85"
  },
  {
    "uniprot": "A0A146M1U1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A146M1U1"
  },
  {
    "uniprot": "C0H4V1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4V1"
  },
  {
    "uniprot": "Q8IL59",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL59"
  },
  {
    "uniprot": "Q8I1T4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1T4"
  },
  {
    "uniprot": "A0A5K1K8Z4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8Z4"
  },
  {
    "uniprot": "Q8IB42",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB42"
  },
  {
    "uniprot": "Q8IE38",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE38"
  },
  {
    "uniprot": "Q8ILS0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILS0"
  },
  {
    "uniprot": "Q8I532",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I532"
  },
  {
    "uniprot": "Q8I3F9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3F9"
  },
  {
    "uniprot": "Q8I720",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I720"
  },
  {
    "uniprot": "Q8IBJ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBJ7"
  },
  {
    "uniprot": "Q8IJG9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJG9"
  },
  {
    "uniprot": "Q8I641",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I641"
  },
  {
    "uniprot": "Q8I5X0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5X0"
  },
  {
    "uniprot": "Q8IL40",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL40"
  },
  {
    "uniprot": "A0A143ZWJ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWJ6"
  },
  {
    "uniprot": "Q8IIK2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIK2"
  },
  {
    "uniprot": "Q8I5V4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5V4"
  },
  {
    "uniprot": "Q8I3G4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3G4"
  },
  {
    "uniprot": "C6KSR6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSR6"
  },
  {
    "uniprot": "O77363",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77363"
  },
  {
    "uniprot": "Q8IL35",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL35"
  },
  {
    "uniprot": "Q8IB41",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB41"
  },
  {
    "uniprot": "A0A144A134",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A134"
  },
  {
    "uniprot": "Q8IEP7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEP7"
  },
  {
    "uniprot": "Q8I5Q7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Q7"
  },
  {
    "uniprot": "B9ZSH6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSH6"
  },
  {
    "uniprot": "Q8IKY4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKY4"
  },
  {
    "uniprot": "Q8IIT3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIT3"
  },
  {
    "uniprot": "Q8I308",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I308"
  },
  {
    "uniprot": "A0A144A171",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A171"
  },
  {
    "uniprot": "Q8I4Y9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Y9"
  },
  {
    "uniprot": "Q8I262",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I262"
  },
  {
    "uniprot": "C6S3F6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3F6"
  },
  {
    "uniprot": "Q8I6Z5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6Z5"
  },
  {
    "uniprot": "O97248",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97248"
  },
  {
    "uniprot": "Q8I5C6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5C6"
  },
  {
    "uniprot": "Q8IKB5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKB5"
  },
  {
    "uniprot": "Q7K734",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7K734"
  },
  {
    "uniprot": "C6KT79",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT79"
  },
  {
    "uniprot": "Q8IAS1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAS1"
  },
  {
    "uniprot": "Q8IEP0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEP0"
  },
  {
    "uniprot": "Q8IDH5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDH5"
  },
  {
    "uniprot": "C6KT17",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT17"
  },
  {
    "uniprot": "Q8I267",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I267"
  },
  {
    "uniprot": "O97309",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97309"
  },
  {
    "uniprot": "O96272",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96272"
  },
  {
    "uniprot": "C6KSY1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSY1"
  },
  {
    "uniprot": "A0A5K1K906",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K906"
  },
  {
    "uniprot": "Q8IDZ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDZ3"
  },
  {
    "uniprot": "Q8IJP5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJP5"
  },
  {
    "uniprot": "Q8I242",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I242"
  },
  {
    "uniprot": "C0H4U7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4U7"
  },
  {
    "uniprot": "O96287",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96287"
  },
  {
    "uniprot": "C6KSM9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSM9"
  },
  {
    "uniprot": "Q8IDR1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDR1"
  },
  {
    "uniprot": "Q8IKH0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKH0"
  },
  {
    "uniprot": "Q8IK11",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK11"
  },
  {
    "uniprot": "C6KST5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KST5"
  },
  {
    "uniprot": "A0A144A3A5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3A5"
  },
  {
    "uniprot": "Q6LFD5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q6LFD5"
  },
  {
    "uniprot": "A0A144A4T5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A4T5"
  },
  {
    "uniprot": "C0H5G2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5G2"
  },
  {
    "uniprot": "Q8IBM1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBM1"
  },
  {
    "uniprot": "Q8I1Q6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Q6"
  },
  {
    "uniprot": "O97311",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97311"
  },
  {
    "uniprot": "Q8IJW0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJW0"
  },
  {
    "uniprot": "O96148",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96148"
  },
  {
    "uniprot": "C6S3J0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3J0"
  },
  {
    "uniprot": "Q8IM26",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM26"
  },
  {
    "uniprot": "Q7KWJ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KWJ1"
  },
  {
    "uniprot": "Q8IK77",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK77"
  },
  {
    "uniprot": "Q8I5X7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5X7"
  },
  {
    "uniprot": "Q8IE20",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE20"
  },
  {
    "uniprot": "Q8I5K4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5K4"
  },
  {
    "uniprot": "Q8IJB8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJB8"
  },
  {
    "uniprot": "C0H4R2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4R2"
  },
  {
    "uniprot": "Q7KQK5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KQK5"
  },
  {
    "uniprot": "O97272",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97272"
  },
  {
    "uniprot": "Q8IJB9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJB9"
  },
  {
    "uniprot": "Q8II49",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II49"
  },
  {
    "uniprot": "Q8IAN8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAN8"
  },
  {
    "uniprot": "Q8IHT8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHT8"
  },
  {
    "uniprot": "Q8IK17",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK17"
  },
  {
    "uniprot": "O00806",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O00806"
  },
  {
    "uniprot": "Q8IIW1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIW1"
  },
  {
    "uniprot": "Q8IBQ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBQ5"
  },
  {
    "uniprot": "Q8I404",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I404"
  },
  {
    "uniprot": "A0A5K1K940",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K940"
  },
  {
    "uniprot": "A0A140KXW9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A140KXW9"
  },
  {
    "uniprot": "Q8IKV4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKV4"
  },
  {
    "uniprot": "Q8IIL2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIL2"
  },
  {
    "uniprot": "Q8IJ42",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ42"
  },
  {
    "uniprot": "Q8IAL6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAL6"
  },
  {
    "uniprot": "C6KSM4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSM4"
  },
  {
    "uniprot": "Q8IB44",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB44"
  },
  {
    "uniprot": "Q8IBC3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBC3"
  },
  {
    "uniprot": "C0H4P1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4P1"
  },
  {
    "uniprot": "Q8IAY2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAY2"
  },
  {
    "uniprot": "Q8I505",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I505"
  },
  {
    "uniprot": "Q8IJ58",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ58"
  },
  {
    "uniprot": "Q8ILN7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILN7"
  },
  {
    "uniprot": "Q8IET4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IET4"
  },
  {
    "uniprot": "Q8IKZ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKZ3"
  },
  {
    "uniprot": "Q8IM83",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM83"
  },
  {
    "uniprot": "Q25825",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25825"
  },
  {
    "uniprot": "Q8IKL4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKL4"
  },
  {
    "uniprot": "Q8I3S4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3S4"
  },
  {
    "uniprot": "Q8ILT3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILT3"
  },
  {
    "uniprot": "Q8IIW3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIW3"
  },
  {
    "uniprot": "Q8IEN3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEN3"
  },
  {
    "uniprot": "Q8IIG0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIG0"
  },
  {
    "uniprot": "Q8IEL5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEL5"
  },
  {
    "uniprot": "Q6ZMA8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q6ZMA8"
  },
  {
    "uniprot": "A0A143ZZX7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZX7"
  },
  {
    "uniprot": "Q8I583",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I583"
  },
  {
    "uniprot": "Q8IEJ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEJ9"
  },
  {
    "uniprot": "Q8I2Z7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Z7"
  },
  {
    "uniprot": "Q8IEL2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEL2"
  },
  {
    "uniprot": "C0H562",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H562"
  },
  {
    "uniprot": "Q8IK35",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK35"
  },
  {
    "uniprot": "Q8IEM0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEM0"
  },
  {
    "uniprot": "A0A5K1K998",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K998"
  },
  {
    "uniprot": "O96217",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96217"
  },
  {
    "uniprot": "Q8I2W6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2W6"
  },
  {
    "uniprot": "C0H4X3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4X3"
  },
  {
    "uniprot": "C6KSQ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSQ1"
  },
  {
    "uniprot": "Q8IKD0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKD0"
  },
  {
    "uniprot": "Q8IEE5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEE5"
  },
  {
    "uniprot": "A0A5K1K8F0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8F0"
  },
  {
    "uniprot": "Q8IL58",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL58"
  },
  {
    "uniprot": "O96227",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96227"
  },
  {
    "uniprot": "Q8IBC0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBC0"
  },
  {
    "uniprot": "Q8I6T4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6T4"
  },
  {
    "uniprot": "Q8I5F3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5F3"
  },
  {
    "uniprot": "Q8I3Y5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Y5"
  },
  {
    "uniprot": "Q8I3Q7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Q7"
  },
  {
    "uniprot": "Q8I4W7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4W7"
  },
  {
    "uniprot": "Q8IAV9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAV9"
  },
  {
    "uniprot": "A0A144A1Q0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1Q0"
  },
  {
    "uniprot": "Q8I4N6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4N6"
  },
  {
    "uniprot": "A0A144A3R0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3R0"
  },
  {
    "uniprot": "Q8IBE9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBE9"
  },
  {
    "uniprot": "Q8I512",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I512"
  },
  {
    "uniprot": "Q8I2H8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2H8"
  },
  {
    "uniprot": "A0A146M145",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A146M145"
  },
  {
    "uniprot": "Q9U0M8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0M8"
  },
  {
    "uniprot": "C6KSS2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSS2"
  },
  {
    "uniprot": "Q8IDK8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDK8"
  },
  {
    "uniprot": "Q8ILF9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILF9"
  },
  {
    "uniprot": "Q8IDI8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDI8"
  },
  {
    "uniprot": "Q8ILC0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILC0"
  },
  {
    "uniprot": "Q25803",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25803"
  },
  {
    "uniprot": "Q8IKG2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKG2"
  },
  {
    "uniprot": "Q8ILY5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILY5"
  },
  {
    "uniprot": "Q8ILG8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILG8"
  },
  {
    "uniprot": "C0H4Q4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Q4"
  },
  {
    "uniprot": "Q8IJD9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJD9"
  },
  {
    "uniprot": "Q8IBV7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBV7"
  },
  {
    "uniprot": "Q8IKX3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKX3"
  },
  {
    "uniprot": "Q7K740",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7K740"
  },
  {
    "uniprot": "Q8IBZ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBZ2"
  },
  {
    "uniprot": "Q8IEJ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEJ6"
  },
  {
    "uniprot": "Q8IBL9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBL9"
  },
  {
    "uniprot": "Q8IB40",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB40"
  },
  {
    "uniprot": "A0A5K1K8P9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8P9"
  },
  {
    "uniprot": "Q8IHQ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHQ7"
  },
  {
    "uniprot": "Q8I1Y2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Y2"
  },
  {
    "uniprot": "Q8I5B6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5B6"
  },
  {
    "uniprot": "Q8I4Y1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Y1"
  },
  {
    "uniprot": "A0A143ZZG7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZG7"
  },
  {
    "uniprot": "Q8ILM5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILM5"
  },
  {
    "uniprot": "C0H4C7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4C7"
  },
  {
    "uniprot": "Q8IJ98",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ98"
  },
  {
    "uniprot": "Q8I2X3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2X3"
  },
  {
    "uniprot": "Q8IAZ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAZ8"
  },
  {
    "uniprot": "Q8IDK2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDK2"
  },
  {
    "uniprot": "A0A5K1K8M9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8M9"
  },
  {
    "uniprot": "Q8IHX9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHX9"
  },
  {
    "uniprot": "Q8IC08",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC08"
  },
  {
    "uniprot": "O96181",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96181"
  },
  {
    "uniprot": "Q8IIY8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIY8"
  },
  {
    "uniprot": "Q76NN6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q76NN6"
  },
  {
    "uniprot": "Q8IK99",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK99"
  },
  {
    "uniprot": "Q8IDY5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDY5"
  },
  {
    "uniprot": "Q8IBW5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBW5"
  },
  {
    "uniprot": "Q8IJL6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJL6"
  },
  {
    "uniprot": "Q8II93",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II93"
  },
  {
    "uniprot": "Q8IC21",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC21"
  },
  {
    "uniprot": "Q8I5Q2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Q2"
  },
  {
    "uniprot": "Q8IBW6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBW6"
  },
  {
    "uniprot": "Q8I0V0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0V0"
  },
  {
    "uniprot": "C6KT41",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT41"
  },
  {
    "uniprot": "Q8IHW0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHW0"
  },
  {
    "uniprot": "Q8IIP6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIP6"
  },
  {
    "uniprot": "C6KSS3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSS3"
  },
  {
    "uniprot": "Q8IDA4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDA4"
  },
  {
    "uniprot": "Q8I3Z3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Z3"
  },
  {
    "uniprot": "A0A143ZZS2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZS2"
  },
  {
    "uniprot": "A0A5K1K8S1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8S1"
  },
  {
    "uniprot": "Q8I3D3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3D3"
  },
  {
    "uniprot": "Q8I579",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I579"
  },
  {
    "uniprot": "Q8IDG1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDG1"
  },
  {
    "uniprot": "Q8I3F1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3F1"
  },
  {
    "uniprot": "Q8IIQ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIQ5"
  },
  {
    "uniprot": "C6KT34",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT34"
  },
  {
    "uniprot": "C0H4V8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4V8"
  },
  {
    "uniprot": "O96143",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96143"
  },
  {
    "uniprot": "C0H4E4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4E4"
  },
  {
    "uniprot": "Q8ILV4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILV4"
  },
  {
    "uniprot": "Q8IIY2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIY2"
  },
  {
    "uniprot": "A0A143ZYZ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYZ7"
  },
  {
    "uniprot": "Q8IK00",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK00"
  },
  {
    "uniprot": "Q8I3M2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3M2"
  },
  {
    "uniprot": "Q8IKY3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKY3"
  },
  {
    "uniprot": "Q8IJL2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJL2"
  },
  {
    "uniprot": "A0A144A1V6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1V6"
  },
  {
    "uniprot": "Q8IID5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IID5"
  },
  {
    "uniprot": "Q8IE00",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE00"
  },
  {
    "uniprot": "Q8I610",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I610"
  },
  {
    "uniprot": "C6KSW8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSW8"
  },
  {
    "uniprot": "Q8I3F0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3F0"
  },
  {
    "uniprot": "Q8IJ25",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ25"
  },
  {
    "uniprot": "Q8I5G5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5G5"
  },
  {
    "uniprot": "A0A5K1K8Q4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8Q4"
  },
  {
    "uniprot": "Q8I2N9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2N9"
  },
  {
    "uniprot": "Q7HP05",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7HP05"
  },
  {
    "uniprot": "Q8I5M6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5M6"
  },
  {
    "uniprot": "C0H5B4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5B4"
  },
  {
    "uniprot": "Q8IKB7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKB7"
  },
  {
    "uniprot": "Q8I2S1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2S1"
  },
  {
    "uniprot": "Q8IJH6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJH6"
  },
  {
    "uniprot": "Q9U0G8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0G8"
  },
  {
    "uniprot": "C6KTB6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTB6"
  },
  {
    "uniprot": "Q8I460",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I460"
  },
  {
    "uniprot": "Q8I2A8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2A8"
  },
  {
    "uniprot": "O96245",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96245"
  },
  {
    "uniprot": "A0A144A1K5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1K5"
  },
  {
    "uniprot": "Q8IEG5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEG5"
  },
  {
    "uniprot": "Q8IEK7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEK7"
  },
  {
    "uniprot": "Q8IK55",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK55"
  },
  {
    "uniprot": "Q8IBX9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBX9"
  },
  {
    "uniprot": "A0A5K1K9B3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9B3"
  },
  {
    "uniprot": "Q8I4Y7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Y7"
  },
  {
    "uniprot": "C0H4E5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4E5"
  },
  {
    "uniprot": "Q8IBY4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBY4"
  },
  {
    "uniprot": "Q8IER1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IER1"
  },
  {
    "uniprot": "Q8II83",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II83"
  },
  {
    "uniprot": "Q76NM4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q76NM4"
  },
  {
    "uniprot": "Q8IBV3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBV3"
  },
  {
    "uniprot": "Q8ILE4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILE4"
  },
  {
    "uniprot": "Q8I317",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I317"
  },
  {
    "uniprot": "Q8I2K2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2K2"
  },
  {
    "uniprot": "Q8I2G4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2G4"
  },
  {
    "uniprot": "Q8IIZ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIZ3"
  },
  {
    "uniprot": "Q8IJ75",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ75"
  },
  {
    "uniprot": "A0A144A0F5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0F5"
  },
  {
    "uniprot": "Q8IIC2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIC2"
  },
  {
    "uniprot": "Q8IIZ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIZ5"
  },
  {
    "uniprot": "C6KT20",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT20"
  },
  {
    "uniprot": "C0H4A8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4A8"
  },
  {
    "uniprot": "Q8I717",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I717"
  },
  {
    "uniprot": "Q8I363",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I363"
  },
  {
    "uniprot": "Q8IDF4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDF4"
  },
  {
    "uniprot": "A0A5K1K898",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K898"
  },
  {
    "uniprot": "Q8I5U4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5U4"
  },
  {
    "uniprot": "Q8IIT1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIT1"
  },
  {
    "uniprot": "Q8I5N2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5N2"
  },
  {
    "uniprot": "Q8IHS6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHS6"
  },
  {
    "uniprot": "Q8IE01",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE01"
  },
  {
    "uniprot": "Q8IDD7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDD7"
  },
  {
    "uniprot": "O96113",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96113"
  },
  {
    "uniprot": "C0H4D6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4D6"
  },
  {
    "uniprot": "C6S3D1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3D1"
  },
  {
    "uniprot": "Q8II20",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II20"
  },
  {
    "uniprot": "C6S3A7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3A7"
  },
  {
    "uniprot": "C0H4R1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4R1"
  },
  {
    "uniprot": "Q8IK87",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK87"
  },
  {
    "uniprot": "C6KTC7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTC7"
  },
  {
    "uniprot": "O97252",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97252"
  },
  {
    "uniprot": "C0H547",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H547"
  },
  {
    "uniprot": "Q8IM31",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM31"
  },
  {
    "uniprot": "Q8I3A0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3A0"
  },
  {
    "uniprot": "Q8I5V7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5V7"
  },
  {
    "uniprot": "Q8IJE1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJE1"
  },
  {
    "uniprot": "Q8IJP4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJP4"
  },
  {
    "uniprot": "Q8IE76",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE76"
  },
  {
    "uniprot": "Q8ILX8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILX8"
  },
  {
    "uniprot": "Q8I467",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I467"
  },
  {
    "uniprot": "C6KT73",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT73"
  },
  {
    "uniprot": "Q9U0N0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0N0"
  },
  {
    "uniprot": "Q8IJ82",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ82"
  },
  {
    "uniprot": "A0A143ZX57",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZX57"
  },
  {
    "uniprot": "Q8IE95",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE95"
  },
  {
    "uniprot": "Q8I5M8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5M8"
  },
  {
    "uniprot": "A0A144A197",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A197"
  },
  {
    "uniprot": "Q8IB85",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB85"
  },
  {
    "uniprot": "O77395",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77395"
  },
  {
    "uniprot": "Q8I1P6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1P6"
  },
  {
    "uniprot": "Q8ILI2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILI2"
  },
  {
    "uniprot": "Q8IB88",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB88"
  },
  {
    "uniprot": "Q8I611",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I611"
  },
  {
    "uniprot": "Q8IJQ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJQ2"
  },
  {
    "uniprot": "Q8I0V4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0V4"
  },
  {
    "uniprot": "C6KT04",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT04"
  },
  {
    "uniprot": "Q8I541",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I541"
  },
  {
    "uniprot": "Q8IIU2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIU2"
  },
  {
    "uniprot": "Q8I1V4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1V4"
  },
  {
    "uniprot": "Q8I1T8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1T8"
  },
  {
    "uniprot": "O96195",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96195"
  },
  {
    "uniprot": "O97254",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97254"
  },
  {
    "uniprot": "A0A144A108",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A108"
  },
  {
    "uniprot": "Q8IB82",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB82"
  },
  {
    "uniprot": "Q8IL39",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL39"
  },
  {
    "uniprot": "Q8IJY5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJY5"
  },
  {
    "uniprot": "O96150",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96150"
  },
  {
    "uniprot": "Q8I3E5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3E5"
  },
  {
    "uniprot": "Q8I592",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I592"
  },
  {
    "uniprot": "O97270",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97270"
  },
  {
    "uniprot": "Q8IB06",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB06"
  },
  {
    "uniprot": "Q8IJ23",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ23"
  },
  {
    "uniprot": "C6KTE4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTE4"
  },
  {
    "uniprot": "Q8IL90",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL90"
  },
  {
    "uniprot": "Q8IIB6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIB6"
  },
  {
    "uniprot": "Q8IIL0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIL0"
  },
  {
    "uniprot": "C0H557",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H557"
  },
  {
    "uniprot": "Q8IHU1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHU1"
  },
  {
    "uniprot": "C0H5D3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5D3"
  },
  {
    "uniprot": "Q8I585",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I585"
  },
  {
    "uniprot": "Q8IBM0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBM0"
  },
  {
    "uniprot": "Q8IFM1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFM1"
  },
  {
    "uniprot": "Q8I643",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I643"
  },
  {
    "uniprot": "O97322",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97322"
  },
  {
    "uniprot": "C6KTF5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTF5"
  },
  {
    "uniprot": "C0H480",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H480"
  },
  {
    "uniprot": "Q8IKK8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKK8"
  },
  {
    "uniprot": "Q8I1Y7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Y7"
  },
  {
    "uniprot": "Q8IBD3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBD3"
  },
  {
    "uniprot": "C0H5I5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5I5"
  },
  {
    "uniprot": "A0A143ZVW7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVW7"
  },
  {
    "uniprot": "Q8IKT0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKT0"
  },
  {
    "uniprot": "C6KT65",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT65"
  },
  {
    "uniprot": "Q8IIS3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIS3"
  },
  {
    "uniprot": "C6KT62",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT62"
  },
  {
    "uniprot": "C6KSL1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSL1"
  },
  {
    "uniprot": "Q8I449",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I449"
  },
  {
    "uniprot": "A0A143ZY73",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZY73"
  },
  {
    "uniprot": "A0A146M1S3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A146M1S3"
  },
  {
    "uniprot": "Q9NLB0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NLB0"
  },
  {
    "uniprot": "C0H4E6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4E6"
  },
  {
    "uniprot": "C0H5M8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5M8"
  },
  {
    "uniprot": "O77390",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77390"
  },
  {
    "uniprot": "Q8IJM0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJM0"
  },
  {
    "uniprot": "Q8IIE6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIE6"
  },
  {
    "uniprot": "A0A144A4T0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A4T0"
  },
  {
    "uniprot": "Q8IEI6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEI6"
  },
  {
    "uniprot": "Q8I299",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I299"
  },
  {
    "uniprot": "Q8I3V2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3V2"
  },
  {
    "uniprot": "Q8IHP1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHP1"
  },
  {
    "uniprot": "C0H5A8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5A8"
  },
  {
    "uniprot": "A0A144A137",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A137"
  },
  {
    "uniprot": "Q8ILY4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILY4"
  },
  {
    "uniprot": "A0A143ZZR8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZR8"
  },
  {
    "uniprot": "Q8I447",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I447"
  },
  {
    "uniprot": "Q8IKD1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKD1"
  },
  {
    "uniprot": "Q8IC48",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC48"
  },
  {
    "uniprot": "C6S3E6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3E6"
  },
  {
    "uniprot": "Q8IKL5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKL5"
  },
  {
    "uniprot": "C6KSN1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSN1"
  },
  {
    "uniprot": "A0A144A1D9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1D9"
  },
  {
    "uniprot": "A0A143ZX23",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZX23"
  },
  {
    "uniprot": "Q9NLB2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NLB2"
  },
  {
    "uniprot": "Q8I490",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I490"
  },
  {
    "uniprot": "Q8IKW9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKW9"
  },
  {
    "uniprot": "Q8I1T6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1T6"
  },
  {
    "uniprot": "Q8II43",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II43"
  },
  {
    "uniprot": "Q8IL02",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL02"
  },
  {
    "uniprot": "Q8IHV6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHV6"
  },
  {
    "uniprot": "O97224",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97224"
  },
  {
    "uniprot": "A0A5K1K8X3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8X3"
  },
  {
    "uniprot": "C0H4H0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4H0"
  },
  {
    "uniprot": "C6KSW1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSW1"
  },
  {
    "uniprot": "C0H573",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H573"
  },
  {
    "uniprot": "Q8IE14",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE14"
  },
  {
    "uniprot": "A0A5K1K950",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K950"
  },
  {
    "uniprot": "A0A5K1K8P1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8P1"
  },
  {
    "uniprot": "Q8I5I6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5I6"
  },
  {
    "uniprot": "C6S3I1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3I1"
  },
  {
    "uniprot": "Q8IBT8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBT8"
  },
  {
    "uniprot": "Q8I241",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I241"
  },
  {
    "uniprot": "Q8IKV2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKV2"
  },
  {
    "uniprot": "C6KSW3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSW3"
  },
  {
    "uniprot": "Q8I5G6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5G6"
  },
  {
    "uniprot": "C6KSQ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSQ8"
  },
  {
    "uniprot": "Q8IB03",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB03"
  },
  {
    "uniprot": "A0A144A139",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A139"
  },
  {
    "uniprot": "Q8I3D7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3D7"
  },
  {
    "uniprot": "C6S3J8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3J8"
  },
  {
    "uniprot": "Q8IFN4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFN4"
  },
  {
    "uniprot": "A0A143ZXJ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXJ2"
  },
  {
    "uniprot": "Q8I431",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I431"
  },
  {
    "uniprot": "Q8IE52",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE52"
  },
  {
    "uniprot": "C0H521",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H521"
  },
  {
    "uniprot": "Q8IJX4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJX4"
  },
  {
    "uniprot": "C0H5J8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5J8"
  },
  {
    "uniprot": "Q8I718",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I718"
  },
  {
    "uniprot": "Q8IBB6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBB6"
  },
  {
    "uniprot": "Q8I502",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I502"
  },
  {
    "uniprot": "Q8I527",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I527"
  },
  {
    "uniprot": "C0H4H4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4H4"
  },
  {
    "uniprot": "Q8I5T9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5T9"
  },
  {
    "uniprot": "Q8IFK8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFK8"
  },
  {
    "uniprot": "Q8I2D4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2D4"
  },
  {
    "uniprot": "Q8I3C0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3C0"
  },
  {
    "uniprot": "Q8IM25",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM25"
  },
  {
    "uniprot": "A0A144A3E5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3E5"
  },
  {
    "uniprot": "C0H550",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H550"
  },
  {
    "uniprot": "Q8IIR3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIR3"
  },
  {
    "uniprot": "C6S3E2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3E2"
  },
  {
    "uniprot": "Q8I5H5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5H5"
  },
  {
    "uniprot": "Q8ILN5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILN5"
  },
  {
    "uniprot": "Q8I3Q6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Q6"
  },
  {
    "uniprot": "Q8ILH2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILH2"
  },
  {
    "uniprot": "Q8IC32",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC32"
  },
  {
    "uniprot": "Q8II27",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II27"
  },
  {
    "uniprot": "Q8IBN9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBN9"
  },
  {
    "uniprot": "Q8IIU4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIU4"
  },
  {
    "uniprot": "Q8IKC9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKC9"
  },
  {
    "uniprot": "Q8IK06",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK06"
  },
  {
    "uniprot": "Q8I249",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I249"
  },
  {
    "uniprot": "Q8II36",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II36"
  },
  {
    "uniprot": "A0A143ZY32",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZY32"
  },
  {
    "uniprot": "Q8I5L9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5L9"
  },
  {
    "uniprot": "O77341",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77341"
  },
  {
    "uniprot": "Q8IM49",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM49"
  },
  {
    "uniprot": "Q8I243",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I243"
  },
  {
    "uniprot": "Q8IM14",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM14"
  },
  {
    "uniprot": "Q8IIJ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIJ3"
  },
  {
    "uniprot": "Q8IJT2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJT2"
  },
  {
    "uniprot": "Q8I3L5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3L5"
  },
  {
    "uniprot": "C6S3D2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3D2"
  },
  {
    "uniprot": "Q8I608",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I608"
  },
  {
    "uniprot": "P61075",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/P61075"
  },
  {
    "uniprot": "Q8ILG2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILG2"
  },
  {
    "uniprot": "Q8IEC9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEC9"
  },
  {
    "uniprot": "O77358",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77358"
  },
  {
    "uniprot": "C0H4A7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4A7"
  },
  {
    "uniprot": "Q7KWJ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KWJ3"
  },
  {
    "uniprot": "A0A144A1X7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1X7"
  },
  {
    "uniprot": "Q8I2E4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2E4"
  },
  {
    "uniprot": "Q8I2G9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2G9"
  },
  {
    "uniprot": "Q8I5C8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5C8"
  },
  {
    "uniprot": "Q8I2V1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2V1"
  },
  {
    "uniprot": "A0A143ZVX7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVX7"
  },
  {
    "uniprot": "C0H5L1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5L1"
  },
  {
    "uniprot": "C6S3I3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3I3"
  },
  {
    "uniprot": "A0A5K1K7Z9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K7Z9"
  },
  {
    "uniprot": "O77378",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77378"
  },
  {
    "uniprot": "Q9Y011",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9Y011"
  },
  {
    "uniprot": "Q8IBV1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBV1"
  },
  {
    "uniprot": "Q8I2W4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2W4"
  },
  {
    "uniprot": "Q8I5S7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5S7"
  },
  {
    "uniprot": "Q8I3R2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3R2"
  },
  {
    "uniprot": "Q8IEL4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEL4"
  },
  {
    "uniprot": "Q9Y013",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9Y013"
  },
  {
    "uniprot": "Q8II54",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II54"
  },
  {
    "uniprot": "Q8I537",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I537"
  },
  {
    "uniprot": "Q8IB96",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB96"
  },
  {
    "uniprot": "Q8ILI5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILI5"
  },
  {
    "uniprot": "Q8IC11",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC11"
  },
  {
    "uniprot": "A0A5K1K8J9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8J9"
  },
  {
    "uniprot": "O96123",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96123"
  },
  {
    "uniprot": "A0A143ZVJ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVJ1"
  },
  {
    "uniprot": "Q8IBU2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBU2"
  },
  {
    "uniprot": "Q8IL19",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL19"
  },
  {
    "uniprot": "Q8I4P6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4P6"
  },
  {
    "uniprot": "Q8IDI5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDI5"
  },
  {
    "uniprot": "C0H5M7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5M7"
  },
  {
    "uniprot": "C0H4T7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4T7"
  },
  {
    "uniprot": "A0A143ZXS2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXS2"
  },
  {
    "uniprot": "Q8I3P8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3P8"
  },
  {
    "uniprot": "Q8IB75",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB75"
  },
  {
    "uniprot": "A0A5K1K9E9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9E9"
  },
  {
    "uniprot": "C6KT58",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT58"
  },
  {
    "uniprot": "Q8IIX3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIX3"
  },
  {
    "uniprot": "Q8IDT3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDT3"
  },
  {
    "uniprot": "Q8IKN1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKN1"
  },
  {
    "uniprot": "C0H5G8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5G8"
  },
  {
    "uniprot": "Q8IIU3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIU3"
  },
  {
    "uniprot": "Q8I2M1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2M1"
  },
  {
    "uniprot": "Q8IHU3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHU3"
  },
  {
    "uniprot": "Q8IL65",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL65"
  },
  {
    "uniprot": "Q8IES8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IES8"
  },
  {
    "uniprot": "A0A143ZXE1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXE1"
  },
  {
    "uniprot": "Q8I2G7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2G7"
  },
  {
    "uniprot": "Q8IKJ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKJ0"
  },
  {
    "uniprot": "Q8I0V6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0V6"
  },
  {
    "uniprot": "Q8IJ63",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ63"
  },
  {
    "uniprot": "C0H4S3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4S3"
  },
  {
    "uniprot": "Q8IIB5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIB5"
  },
  {
    "uniprot": "O96137",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96137"
  },
  {
    "uniprot": "A0A143ZXT2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXT2"
  },
  {
    "uniprot": "A0A5K1K9F3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9F3"
  },
  {
    "uniprot": "Q8I240",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I240"
  },
  {
    "uniprot": "Q8IJC2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJC2"
  },
  {
    "uniprot": "A0A5K1K8U1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8U1"
  },
  {
    "uniprot": "A0A143ZXD4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXD4"
  },
  {
    "uniprot": "C0H536",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H536"
  },
  {
    "uniprot": "O97474",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97474"
  },
  {
    "uniprot": "Q8IK05",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK05"
  },
  {
    "uniprot": "A0A144A041",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A041"
  },
  {
    "uniprot": "A0A144A431",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A431"
  },
  {
    "uniprot": "C6KSX9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSX9"
  },
  {
    "uniprot": "Q8I381",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I381"
  },
  {
    "uniprot": "Q8I5R4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5R4"
  },
  {
    "uniprot": "Q8I6T3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6T3"
  },
  {
    "uniprot": "Q8III3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8III3"
  },
  {
    "uniprot": "Q8IHN1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHN1"
  },
  {
    "uniprot": "O96201",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96201"
  },
  {
    "uniprot": "Q8I255",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I255"
  },
  {
    "uniprot": "A0A144A0B3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0B3"
  },
  {
    "uniprot": "Q8I3K5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3K5"
  },
  {
    "uniprot": "Q8IJV8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJV8"
  },
  {
    "uniprot": "Q8ILY2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILY2"
  },
  {
    "uniprot": "O96141",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96141"
  },
  {
    "uniprot": "Q8I5H8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5H8"
  },
  {
    "uniprot": "Q8IKU9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKU9"
  },
  {
    "uniprot": "A0A5K1K8X2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8X2"
  },
  {
    "uniprot": "A0A5K1K8F4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8F4"
  },
  {
    "uniprot": "Q8I1S2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1S2"
  },
  {
    "uniprot": "Q8IHU7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHU7"
  },
  {
    "uniprot": "Q8I5D4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5D4"
  },
  {
    "uniprot": "Q8I5D1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5D1"
  },
  {
    "uniprot": "C6KT72",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT72"
  },
  {
    "uniprot": "Q8I3R9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3R9"
  },
  {
    "uniprot": "C6KTF2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTF2"
  },
  {
    "uniprot": "Q8I0U9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0U9"
  },
  {
    "uniprot": "C6KTA7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTA7"
  },
  {
    "uniprot": "A0A143ZXY8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXY8"
  },
  {
    "uniprot": "Q8II05",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II05"
  },
  {
    "uniprot": "O97295",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97295"
  },
  {
    "uniprot": "A0A143ZVC0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVC0"
  },
  {
    "uniprot": "Q8I5S4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5S4"
  },
  {
    "uniprot": "Q8IJP6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJP6"
  },
  {
    "uniprot": "Q8I292",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I292"
  },
  {
    "uniprot": "Q8IBE8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBE8"
  },
  {
    "uniprot": "Q8I2X0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2X0"
  },
  {
    "uniprot": "Q8ILA2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILA2"
  },
  {
    "uniprot": "Q8IB14",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB14"
  },
  {
    "uniprot": "Q8IE03",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE03"
  },
  {
    "uniprot": "Q8IID3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IID3"
  },
  {
    "uniprot": "Q8ILK8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILK8"
  },
  {
    "uniprot": "Q9U0H9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0H9"
  },
  {
    "uniprot": "Q8IEK1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEK1"
  },
  {
    "uniprot": "Q8ILZ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILZ9"
  },
  {
    "uniprot": "C0H4H3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4H3"
  },
  {
    "uniprot": "Q8ILN8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILN8"
  },
  {
    "uniprot": "Q8II26",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II26"
  },
  {
    "uniprot": "C0H539",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H539"
  },
  {
    "uniprot": "A0A143ZWE8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWE8"
  },
  {
    "uniprot": "Q8IBS2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBS2"
  },
  {
    "uniprot": "Q8IJ01",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ01"
  },
  {
    "uniprot": "O96155",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96155"
  },
  {
    "uniprot": "C6S3G4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3G4"
  },
  {
    "uniprot": "C6KT42",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT42"
  },
  {
    "uniprot": "A0A5K1K8Y7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8Y7"
  },
  {
    "uniprot": "Q8I385",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I385"
  },
  {
    "uniprot": "A0A140KXW8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A140KXW8"
  },
  {
    "uniprot": "Q8ILK3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILK3"
  },
  {
    "uniprot": "Q8IJA8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJA8"
  },
  {
    "uniprot": "C6KTB3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTB3"
  },
  {
    "uniprot": "A0A143ZWJ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWJ1"
  },
  {
    "uniprot": "A0A143ZVM1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVM1"
  },
  {
    "uniprot": "Q8IE82",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE82"
  },
  {
    "uniprot": "Q8IB29",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB29"
  },
  {
    "uniprot": "A0A5K1K903",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K903"
  },
  {
    "uniprot": "Q8I3N3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3N3"
  },
  {
    "uniprot": "Q8IJP3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJP3"
  },
  {
    "uniprot": "P61074",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/P61074"
  },
  {
    "uniprot": "C6KTF3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTF3"
  },
  {
    "uniprot": "Q8IIK3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIK3"
  },
  {
    "uniprot": "Q8IDQ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDQ2"
  },
  {
    "uniprot": "Q8ID62",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID62"
  },
  {
    "uniprot": "C0H4U4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4U4"
  },
  {
    "uniprot": "A0A143ZXT6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXT6"
  },
  {
    "uniprot": "Q8IHV9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHV9"
  },
  {
    "uniprot": "Q8II94",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II94"
  },
  {
    "uniprot": "Q8I558",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I558"
  },
  {
    "uniprot": "Q8I3Y8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Y8"
  },
  {
    "uniprot": "Q8IC00",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC00"
  },
  {
    "uniprot": "A0A146M009",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A146M009"
  },
  {
    "uniprot": "C6KSX8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSX8"
  },
  {
    "uniprot": "O96156",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96156"
  },
  {
    "uniprot": "Q8IE07",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE07"
  },
  {
    "uniprot": "Q8IB70",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB70"
  },
  {
    "uniprot": "A0A144A4G4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A4G4"
  },
  {
    "uniprot": "Q8IIU8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIU8"
  },
  {
    "uniprot": "A0A143ZYA5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYA5"
  },
  {
    "uniprot": "A0A144A3N9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3N9"
  },
  {
    "uniprot": "Q8I3J4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3J4"
  },
  {
    "uniprot": "Q9U0N5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0N5"
  },
  {
    "uniprot": "O77387",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77387"
  },
  {
    "uniprot": "Q8I429",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I429"
  },
  {
    "uniprot": "Q8IKV1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKV1"
  },
  {
    "uniprot": "Q7KWJ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KWJ4"
  },
  {
    "uniprot": "Q8I476",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I476"
  },
  {
    "uniprot": "Q8IBK0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBK0"
  },
  {
    "uniprot": "Q8IKK7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKK7"
  },
  {
    "uniprot": "Q8I577",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I577"
  },
  {
    "uniprot": "Q8I6T5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6T5"
  },
  {
    "uniprot": "A0A5K1K7U1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K7U1"
  },
  {
    "uniprot": "A0A143ZWL7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWL7"
  },
  {
    "uniprot": "Q8I1U4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1U4"
  },
  {
    "uniprot": "C0H4V7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4V7"
  },
  {
    "uniprot": "Q8I2X5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2X5"
  },
  {
    "uniprot": "A0A5K1K9F1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9F1"
  },
  {
    "uniprot": "C6KSM2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSM2"
  },
  {
    "uniprot": "Q8IKL3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKL3"
  },
  {
    "uniprot": "A0A5K1K928",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K928"
  },
  {
    "uniprot": "C6S3C5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3C5"
  },
  {
    "uniprot": "C6KSL9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSL9"
  },
  {
    "uniprot": "Q9U0K8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0K8"
  },
  {
    "uniprot": "Q8IFN5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFN5"
  },
  {
    "uniprot": "C6S3E4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3E4"
  },
  {
    "uniprot": "Q8I561",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I561"
  },
  {
    "uniprot": "Q8IHP7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHP7"
  },
  {
    "uniprot": "Q8IJQ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJQ0"
  },
  {
    "uniprot": "A0A5K1K8R3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8R3"
  },
  {
    "uniprot": "Q8I274",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I274"
  },
  {
    "uniprot": "Q8IED7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IED7"
  },
  {
    "uniprot": "Q8IL49",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL49"
  },
  {
    "uniprot": "Q8I666",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I666"
  },
  {
    "uniprot": "Q8I3H9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3H9"
  },
  {
    "uniprot": "Q7KQK6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KQK6"
  },
  {
    "uniprot": "Q8IKD3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKD3"
  },
  {
    "uniprot": "Q8IHS1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHS1"
  },
  {
    "uniprot": "Q8I1P1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1P1"
  },
  {
    "uniprot": "A0A143ZY44",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZY44"
  },
  {
    "uniprot": "Q9U0H3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0H3"
  },
  {
    "uniprot": "C6KSR9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSR9"
  },
  {
    "uniprot": "Q8ILX3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILX3"
  },
  {
    "uniprot": "Q8IBJ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBJ5"
  },
  {
    "uniprot": "Q8IAU3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAU3"
  },
  {
    "uniprot": "Q8I5L2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5L2"
  },
  {
    "uniprot": "O77349",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77349"
  },
  {
    "uniprot": "A0A5K1K9G1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9G1"
  },
  {
    "uniprot": "Q8I0X1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0X1"
  },
  {
    "uniprot": "Q8I1X4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1X4"
  },
  {
    "uniprot": "Q8I290",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I290"
  },
  {
    "uniprot": "Q8I212",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I212"
  },
  {
    "uniprot": "Q8II81",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II81"
  },
  {
    "uniprot": "O97312",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97312"
  },
  {
    "uniprot": "Q8IAN4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAN4"
  },
  {
    "uniprot": "Q8I413",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I413"
  },
  {
    "uniprot": "C6KSQ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSQ0"
  },
  {
    "uniprot": "Q8I5C7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5C7"
  },
  {
    "uniprot": "C6S3J6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3J6"
  },
  {
    "uniprot": "Q8I1N8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1N8"
  },
  {
    "uniprot": "Q8ILT4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILT4"
  },
  {
    "uniprot": "Q8IHV0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHV0"
  },
  {
    "uniprot": "Q8I626",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I626"
  },
  {
    "uniprot": "Q8IBF8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBF8"
  },
  {
    "uniprot": "Q8ILD0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILD0"
  },
  {
    "uniprot": "Q8I544",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I544"
  },
  {
    "uniprot": "Q8I425",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I425"
  },
  {
    "uniprot": "A0A143ZVC8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVC8"
  },
  {
    "uniprot": "Q8IM34",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM34"
  },
  {
    "uniprot": "Q8IC12",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC12"
  },
  {
    "uniprot": "A0A5K1K859",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K859"
  },
  {
    "uniprot": "Q8I302",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I302"
  },
  {
    "uniprot": "C0H570",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H570"
  },
  {
    "uniprot": "A0A144A114",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A114"
  },
  {
    "uniprot": "Q8IFP2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFP2"
  },
  {
    "uniprot": "Q8II85",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II85"
  },
  {
    "uniprot": "Q8IM10",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM10"
  },
  {
    "uniprot": "O77322",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77322"
  },
  {
    "uniprot": "Q8IEH8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEH8"
  },
  {
    "uniprot": "A0A143ZVA6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVA6"
  },
  {
    "uniprot": "Q8I2X4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2X4"
  },
  {
    "uniprot": "A0A143ZWT2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWT2"
  },
  {
    "uniprot": "Q8IIG3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIG3"
  },
  {
    "uniprot": "Q8I420",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I420"
  },
  {
    "uniprot": "Q8IJW4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJW4"
  },
  {
    "uniprot": "C6KTE1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTE1"
  },
  {
    "uniprot": "O96220",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96220"
  },
  {
    "uniprot": "A0A144A524",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A524"
  },
  {
    "uniprot": "Q8IM23",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM23"
  },
  {
    "uniprot": "C6KSX7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSX7"
  },
  {
    "uniprot": "Q9NLB4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NLB4"
  },
  {
    "uniprot": "C6S3C8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3C8"
  },
  {
    "uniprot": "Q8I637",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I637"
  },
  {
    "uniprot": "Q8IDB3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDB3"
  },
  {
    "uniprot": "Q8I0G7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0G7"
  },
  {
    "uniprot": "Q8I3H1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3H1"
  },
  {
    "uniprot": "O97314",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97314"
  },
  {
    "uniprot": "Q9NDU6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NDU6"
  },
  {
    "uniprot": "C6S3E0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3E0"
  },
  {
    "uniprot": "Q8II63",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II63"
  },
  {
    "uniprot": "Q8IC19",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC19"
  },
  {
    "uniprot": "O96189",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96189"
  },
  {
    "uniprot": "C6S3K0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3K0"
  },
  {
    "uniprot": "Q8IJD1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJD1"
  },
  {
    "uniprot": "O97318",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97318"
  },
  {
    "uniprot": "Q8I201",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I201"
  },
  {
    "uniprot": "Q8IFP5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFP5"
  },
  {
    "uniprot": "Q8IDN0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDN0"
  },
  {
    "uniprot": "Q8IBD2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBD2"
  },
  {
    "uniprot": "Q9NFA1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NFA1"
  },
  {
    "uniprot": "Q8I209",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I209"
  },
  {
    "uniprot": "O97273",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97273"
  },
  {
    "uniprot": "Q8IBI2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBI2"
  },
  {
    "uniprot": "Q8I3I4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3I4"
  },
  {
    "uniprot": "C0H4Z1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Z1"
  },
  {
    "uniprot": "C0H4L8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4L8"
  },
  {
    "uniprot": "Q8IB77",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB77"
  },
  {
    "uniprot": "Q8ILI7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILI7"
  },
  {
    "uniprot": "Q8I6Z9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6Z9"
  },
  {
    "uniprot": "Q8I5D9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5D9"
  },
  {
    "uniprot": "A0A144A6G1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A6G1"
  },
  {
    "uniprot": "C6KSZ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSZ3"
  },
  {
    "uniprot": "Q8I509",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I509"
  },
  {
    "uniprot": "A0A144A1B3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1B3"
  },
  {
    "uniprot": "Q8IIG2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIG2"
  },
  {
    "uniprot": "Q8IKG0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKG0"
  },
  {
    "uniprot": "Q8I4V7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4V7"
  },
  {
    "uniprot": "Q8II51",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II51"
  },
  {
    "uniprot": "C6KTB2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTB2"
  },
  {
    "uniprot": "A0A144A488",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A488"
  },
  {
    "uniprot": "A0A144A1B9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1B9"
  },
  {
    "uniprot": "O97305",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97305"
  },
  {
    "uniprot": "Q8I5H4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5H4"
  },
  {
    "uniprot": "Q8I546",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I546"
  },
  {
    "uniprot": "Q8ILN2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILN2"
  },
  {
    "uniprot": "Q8I1S9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1S9"
  },
  {
    "uniprot": "C6KSK9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSK9"
  },
  {
    "uniprot": "Q8IBQ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBQ6"
  },
  {
    "uniprot": "C0H4X7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4X7"
  },
  {
    "uniprot": "Q8IIX1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIX1"
  },
  {
    "uniprot": "C0H4M2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4M2"
  },
  {
    "uniprot": "Q8IJY9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJY9"
  },
  {
    "uniprot": "C6KSR5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSR5"
  },
  {
    "uniprot": "Q8I2F2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2F2"
  },
  {
    "uniprot": "A0A5K1K8H4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8H4"
  },
  {
    "uniprot": "Q8ILM8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILM8"
  },
  {
    "uniprot": "Q8I2R6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2R6"
  },
  {
    "uniprot": "Q8I326",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I326"
  },
  {
    "uniprot": "C6S3F7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3F7"
  },
  {
    "uniprot": "Q8I3L9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3L9"
  },
  {
    "uniprot": "Q8IIR9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIR9"
  },
  {
    "uniprot": "Q8IL41",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL41"
  },
  {
    "uniprot": "Q8IIL3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIL3"
  },
  {
    "uniprot": "Q8IIT4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIT4"
  },
  {
    "uniprot": "A0A5K1K8K8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8K8"
  },
  {
    "uniprot": "Q8IAY6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAY6"
  },
  {
    "uniprot": "Q8IAM0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAM0"
  },
  {
    "uniprot": "O97253",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97253"
  },
  {
    "uniprot": "Q8I5X4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5X4"
  },
  {
    "uniprot": "Q8IJ48",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ48"
  },
  {
    "uniprot": "C6S3H1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3H1"
  },
  {
    "uniprot": "Q9NFB5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NFB5"
  },
  {
    "uniprot": "A0A5K1K8R1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8R1"
  },
  {
    "uniprot": "C0H5H3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5H3"
  },
  {
    "uniprot": "Q8IJ30",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ30"
  },
  {
    "uniprot": "O96152",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96152"
  },
  {
    "uniprot": "C6KTF0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTF0"
  },
  {
    "uniprot": "Q8IM12",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM12"
  },
  {
    "uniprot": "Q8I4W8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4W8"
  },
  {
    "uniprot": "Q8ILV7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILV7"
  },
  {
    "uniprot": "C0H4V9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4V9"
  },
  {
    "uniprot": "Q8IIF5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIF5"
  },
  {
    "uniprot": "Q8IJ84",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ84"
  },
  {
    "uniprot": "Q8I1R6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1R6"
  },
  {
    "uniprot": "Q8IEK6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEK6"
  },
  {
    "uniprot": "C6S3K4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3K4"
  },
  {
    "uniprot": "Q8ID52",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID52"
  },
  {
    "uniprot": "C6KSX6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSX6"
  },
  {
    "uniprot": "Q8IBV6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBV6"
  },
  {
    "uniprot": "C0H4C1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4C1"
  },
  {
    "uniprot": "Q8ILB4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILB4"
  },
  {
    "uniprot": "C0H5A4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5A4"
  },
  {
    "uniprot": "Q8I582",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I582"
  },
  {
    "uniprot": "Q8ILL4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILL4"
  },
  {
    "uniprot": "Q8I2D1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2D1"
  },
  {
    "uniprot": "Q8I4S8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4S8"
  },
  {
    "uniprot": "Q8ILJ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILJ1"
  },
  {
    "uniprot": "Q8I1Z7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Z7"
  },
  {
    "uniprot": "Q8IJP9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJP9"
  },
  {
    "uniprot": "A0A5K1K8M5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8M5"
  },
  {
    "uniprot": "A0A5K1K868",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K868"
  },
  {
    "uniprot": "O97267",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97267"
  },
  {
    "uniprot": "Q9TY94",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9TY94"
  },
  {
    "uniprot": "Q8I269",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I269"
  },
  {
    "uniprot": "A0A143ZXR3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXR3"
  },
  {
    "uniprot": "Q8ILM3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILM3"
  },
  {
    "uniprot": "Q9U0J0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0J0"
  },
  {
    "uniprot": "O96235",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96235"
  },
  {
    "uniprot": "Q8IJC0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJC0"
  },
  {
    "uniprot": "C6KTD6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTD6"
  },
  {
    "uniprot": "Q7KQL1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KQL1"
  },
  {
    "uniprot": "C6KT49",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT49"
  },
  {
    "uniprot": "Q8IHM2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHM2"
  },
  {
    "uniprot": "Q8IJX0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJX0"
  },
  {
    "uniprot": "Q8IJV4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJV4"
  },
  {
    "uniprot": "Q8I668",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I668"
  },
  {
    "uniprot": "Q8IKR1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKR1"
  },
  {
    "uniprot": "Q8I3L7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3L7"
  },
  {
    "uniprot": "A0A5K1K973",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K973"
  },
  {
    "uniprot": "C0H473",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H473"
  },
  {
    "uniprot": "Q8IM29",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM29"
  },
  {
    "uniprot": "C6S3K2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3K2"
  },
  {
    "uniprot": "Q8I545",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I545"
  },
  {
    "uniprot": "Q8IHM9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHM9"
  },
  {
    "uniprot": "Q9U0G9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0G9"
  },
  {
    "uniprot": "O97327",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97327"
  },
  {
    "uniprot": "C6KSX0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSX0"
  },
  {
    "uniprot": "A0A144A0W9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0W9"
  },
  {
    "uniprot": "Q8IJM7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJM7"
  },
  {
    "uniprot": "Q8IBR6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBR6"
  },
  {
    "uniprot": "Q8II02",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II02"
  },
  {
    "uniprot": "C0H5N5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5N5"
  },
  {
    "uniprot": "C0H591",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H591"
  },
  {
    "uniprot": "Q8I1Q9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Q9"
  },
  {
    "uniprot": "Q8IBR9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBR9"
  },
  {
    "uniprot": "Q8II46",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II46"
  },
  {
    "uniprot": "A0A144A236",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A236"
  },
  {
    "uniprot": "O97297",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97297"
  },
  {
    "uniprot": "C0H4T5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4T5"
  },
  {
    "uniprot": "O77318",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77318"
  },
  {
    "uniprot": "A0A144A1R5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1R5"
  },
  {
    "uniprot": "Q8IJM6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJM6"
  },
  {
    "uniprot": "Q8I507",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I507"
  },
  {
    "uniprot": "Q8ILU0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILU0"
  },
  {
    "uniprot": "Q8I5Y1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Y1"
  },
  {
    "uniprot": "Q7K6A4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7K6A4"
  },
  {
    "uniprot": "C0H5D1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5D1"
  },
  {
    "uniprot": "A0A144A0B2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0B2"
  },
  {
    "uniprot": "Q8I2F5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2F5"
  },
  {
    "uniprot": "Q8IL13",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL13"
  },
  {
    "uniprot": "C6KSM0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSM0"
  },
  {
    "uniprot": "Q8II92",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II92"
  },
  {
    "uniprot": "Q8I373",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I373"
  },
  {
    "uniprot": "Q8IEN0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEN0"
  },
  {
    "uniprot": "Q8I5B4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5B4"
  },
  {
    "uniprot": "Q8IEC6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEC6"
  },
  {
    "uniprot": "C6KSW4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSW4"
  },
  {
    "uniprot": "C0H560",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H560"
  },
  {
    "uniprot": "A0A144A024",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A024"
  },
  {
    "uniprot": "A0A5K1K8Z5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8Z5"
  },
  {
    "uniprot": "A0A5K1K8Y2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8Y2"
  },
  {
    "uniprot": "Q8IHP2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHP2"
  },
  {
    "uniprot": "C0H475",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H475"
  },
  {
    "uniprot": "Q8IKX9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKX9"
  },
  {
    "uniprot": "Q8IKP3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKP3"
  },
  {
    "uniprot": "Q8I3E2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3E2"
  },
  {
    "uniprot": "Q8I2Y6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Y6"
  },
  {
    "uniprot": "Q9NFB0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NFB0"
  },
  {
    "uniprot": "Q8I3M3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3M3"
  },
  {
    "uniprot": "Q8I3J0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3J0"
  },
  {
    "uniprot": "Q8IKN5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKN5"
  },
  {
    "uniprot": "Q8IIR4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIR4"
  },
  {
    "uniprot": "C6KSV6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSV6"
  },
  {
    "uniprot": "Q8ILR0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILR0"
  },
  {
    "uniprot": "Q8ILF1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILF1"
  },
  {
    "uniprot": "Q8I5J4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5J4"
  },
  {
    "uniprot": "O77338",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77338"
  },
  {
    "uniprot": "Q8IJ78",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ78"
  },
  {
    "uniprot": "Q8IK34",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK34"
  },
  {
    "uniprot": "C0H4T4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4T4"
  },
  {
    "uniprot": "Q8IAV3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAV3"
  },
  {
    "uniprot": "Q8IEJ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEJ2"
  },
  {
    "uniprot": "A0A143ZXK1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXK1"
  },
  {
    "uniprot": "Q8IEK2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEK2"
  },
  {
    "uniprot": "Q8IE77",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE77"
  },
  {
    "uniprot": "Q8I5G0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5G0"
  },
  {
    "uniprot": "A0A5K1K8L5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8L5"
  },
  {
    "uniprot": "Q8I2R5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2R5"
  },
  {
    "uniprot": "Q8I3F5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3F5"
  },
  {
    "uniprot": "Q8IEU1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEU1"
  },
  {
    "uniprot": "Q8ILC4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILC4"
  },
  {
    "uniprot": "C6KT85",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT85"
  },
  {
    "uniprot": "A0A143ZZD7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZD7"
  },
  {
    "uniprot": "Q8IEN7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEN7"
  },
  {
    "uniprot": "Q8IEH4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEH4"
  },
  {
    "uniprot": "Q8IIC7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIC7"
  },
  {
    "uniprot": "A0A144A1U3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1U3"
  },
  {
    "uniprot": "A0A143ZVI9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVI9"
  },
  {
    "uniprot": "Q8ILW2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILW2"
  },
  {
    "uniprot": "Q8IHW2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHW2"
  },
  {
    "uniprot": "A0A143ZWA3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWA3"
  },
  {
    "uniprot": "Q8IM28",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM28"
  },
  {
    "uniprot": "Q8IHR6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHR6"
  },
  {
    "uniprot": "Q8IJ72",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ72"
  },
  {
    "uniprot": "Q8I2J9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2J9"
  },
  {
    "uniprot": "Q8IDW0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDW0"
  },
  {
    "uniprot": "Q9U0K3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0K3"
  },
  {
    "uniprot": "Q8IBN0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBN0"
  },
  {
    "uniprot": "C6S3G7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3G7"
  },
  {
    "uniprot": "Q8I2U5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2U5"
  },
  {
    "uniprot": "Q8ID58",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID58"
  },
  {
    "uniprot": "Q8IJU3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJU3"
  },
  {
    "uniprot": "A0A144A213",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A213"
  },
  {
    "uniprot": "Q8I4X8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4X8"
  },
  {
    "uniprot": "Q8I386",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I386"
  },
  {
    "uniprot": "Q8IBY1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBY1"
  },
  {
    "uniprot": "Q8IES1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IES1"
  },
  {
    "uniprot": "Q8IEJ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEJ7"
  },
  {
    "uniprot": "Q8I4Z7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Z7"
  },
  {
    "uniprot": "A0A5K1K8V6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8V6"
  },
  {
    "uniprot": "Q8I2Y3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Y3"
  },
  {
    "uniprot": "Q8IAT2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAT2"
  },
  {
    "uniprot": "P21421",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/P21421"
  },
  {
    "uniprot": "Q8IAW6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAW6"
  },
  {
    "uniprot": "Q8I2G6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2G6"
  },
  {
    "uniprot": "Q8IIP4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIP4"
  },
  {
    "uniprot": "Q8IBA6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBA6"
  },
  {
    "uniprot": "Q8IK74",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK74"
  },
  {
    "uniprot": "Q8IAS6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAS6"
  },
  {
    "uniprot": "A0A144A060",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A060"
  },
  {
    "uniprot": "Q8IC16",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC16"
  },
  {
    "uniprot": "Q8II80",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II80"
  },
  {
    "uniprot": "C6KT35",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT35"
  },
  {
    "uniprot": "Q8IED4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IED4"
  },
  {
    "uniprot": "C6KT96",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT96"
  },
  {
    "uniprot": "A0A5K1K8V8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8V8"
  },
  {
    "uniprot": "Q8IKA1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKA1"
  },
  {
    "uniprot": "C0H540",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H540"
  },
  {
    "uniprot": "C0H470",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H470"
  },
  {
    "uniprot": "Q8I457",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I457"
  },
  {
    "uniprot": "A0A143ZXE3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXE3"
  },
  {
    "uniprot": "O77335",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77335"
  },
  {
    "uniprot": "C6S3A8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3A8"
  },
  {
    "uniprot": "Q8I3A3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3A3"
  },
  {
    "uniprot": "C6S3H3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3H3"
  },
  {
    "uniprot": "Q8I5J3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5J3"
  },
  {
    "uniprot": "Q8I3N4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3N4"
  },
  {
    "uniprot": "C0H4M6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4M6"
  },
  {
    "uniprot": "Q8IJ53",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ53"
  },
  {
    "uniprot": "C0H4N1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4N1"
  },
  {
    "uniprot": "Q8IL62",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL62"
  },
  {
    "uniprot": "Q8I562",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I562"
  },
  {
    "uniprot": "Q25822",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25822"
  },
  {
    "uniprot": "O96215",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96215"
  },
  {
    "uniprot": "Q8IKE7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKE7"
  },
  {
    "uniprot": "Q8IDQ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDQ5"
  },
  {
    "uniprot": "Q8IM20",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM20"
  },
  {
    "uniprot": "A0A5K1K8M3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8M3"
  },
  {
    "uniprot": "Q8IDK1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDK1"
  },
  {
    "uniprot": "A0A143ZWD4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWD4"
  },
  {
    "uniprot": "O96142",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96142"
  },
  {
    "uniprot": "Q8IKS2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKS2"
  },
  {
    "uniprot": "O96291",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96291"
  },
  {
    "uniprot": "A0A5K1K895",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K895"
  },
  {
    "uniprot": "Q8I5P8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5P8"
  },
  {
    "uniprot": "Q8I2V0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2V0"
  },
  {
    "uniprot": "Q8IIS7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIS7"
  },
  {
    "uniprot": "Q8I0U7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0U7"
  },
  {
    "uniprot": "Q8ILC3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILC3"
  },
  {
    "uniprot": "Q8I2R8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2R8"
  },
  {
    "uniprot": "Q8IER5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IER5"
  },
  {
    "uniprot": "Q8IJZ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJZ1"
  },
  {
    "uniprot": "Q8IKI5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKI5"
  },
  {
    "uniprot": "A0A5K1K9B5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9B5"
  },
  {
    "uniprot": "Q8IJ70",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ70"
  },
  {
    "uniprot": "Q8I1S0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1S0"
  },
  {
    "uniprot": "Q8IL76",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL76"
  },
  {
    "uniprot": "Q8IAU5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAU5"
  },
  {
    "uniprot": "Q8IKB8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKB8"
  },
  {
    "uniprot": "O96229",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96229"
  },
  {
    "uniprot": "Q8I5S2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5S2"
  },
  {
    "uniprot": "Q8IKU8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKU8"
  },
  {
    "uniprot": "Q8IM38",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM38"
  },
  {
    "uniprot": "Q8I5L1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5L1"
  },
  {
    "uniprot": "Q8IE47",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE47"
  },
  {
    "uniprot": "Q8IB86",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB86"
  },
  {
    "uniprot": "A0A143ZXL1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXL1"
  },
  {
    "uniprot": "Q25805",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25805"
  },
  {
    "uniprot": "Q8I236",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I236"
  },
  {
    "uniprot": "Q8I5Y5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Y5"
  },
  {
    "uniprot": "Q8I573",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I573"
  },
  {
    "uniprot": "Q8ID17",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID17"
  },
  {
    "uniprot": "Q8IC50",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC50"
  },
  {
    "uniprot": "C6KSW7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSW7"
  },
  {
    "uniprot": "Q8I5V1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5V1"
  },
  {
    "uniprot": "Q8IHU8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHU8"
  },
  {
    "uniprot": "O77340",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77340"
  },
  {
    "uniprot": "Q8ILJ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILJ8"
  },
  {
    "uniprot": "Q8ILF8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILF8"
  },
  {
    "uniprot": "C6KTA6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTA6"
  },
  {
    "uniprot": "Q8IE22",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE22"
  },
  {
    "uniprot": "Q8ILI3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILI3"
  },
  {
    "uniprot": "C6KSV3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSV3"
  },
  {
    "uniprot": "O77392",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77392"
  },
  {
    "uniprot": "O97259",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97259"
  },
  {
    "uniprot": "C0H4W8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4W8"
  },
  {
    "uniprot": "Q25808",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25808"
  },
  {
    "uniprot": "Q8ILN4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILN4"
  },
  {
    "uniprot": "Q8I2G3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2G3"
  },
  {
    "uniprot": "Q8IM37",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM37"
  },
  {
    "uniprot": "O77373",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77373"
  },
  {
    "uniprot": "Q8ILH4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILH4"
  },
  {
    "uniprot": "Q8ILM2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILM2"
  },
  {
    "uniprot": "Q8I3U7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3U7"
  },
  {
    "uniprot": "A0A5K1K970",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K970"
  },
  {
    "uniprot": "C0H4S9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4S9"
  },
  {
    "uniprot": "Q8I1P3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1P3"
  },
  {
    "uniprot": "Q8IJI2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJI2"
  },
  {
    "uniprot": "Q8IK19",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK19"
  },
  {
    "uniprot": "Q9U5L9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U5L9"
  },
  {
    "uniprot": "Q8I3J5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3J5"
  },
  {
    "uniprot": "Q8IEP9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEP9"
  },
  {
    "uniprot": "Q8I1Y9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Y9"
  },
  {
    "uniprot": "Q8I5J6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5J6"
  },
  {
    "uniprot": "Q8IDF2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDF2"
  },
  {
    "uniprot": "Q8I559",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I559"
  },
  {
    "uniprot": "Q8IIM4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIM4"
  },
  {
    "uniprot": "Q8I3P9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3P9"
  },
  {
    "uniprot": "Q8IM43",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM43"
  },
  {
    "uniprot": "Q8I0W1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0W1"
  },
  {
    "uniprot": "Q8IDS3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDS3"
  },
  {
    "uniprot": "Q8IIK6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIK6"
  },
  {
    "uniprot": "A0A143ZXH0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXH0"
  },
  {
    "uniprot": "Q8I3V7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3V7"
  },
  {
    "uniprot": "C0H4V4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4V4"
  },
  {
    "uniprot": "Q8IHM7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHM7"
  },
  {
    "uniprot": "A0A143ZVL6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVL6"
  },
  {
    "uniprot": "C0H4Z5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Z5"
  },
  {
    "uniprot": "Q8IBJ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBJ0"
  },
  {
    "uniprot": "A0A144A2J9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2J9"
  },
  {
    "uniprot": "A0A5K1K8B7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8B7"
  },
  {
    "uniprot": "Q8IJ83",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ83"
  },
  {
    "uniprot": "Q8IJM1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJM1"
  },
  {
    "uniprot": "Q8IE75",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE75"
  },
  {
    "uniprot": "C0H5E7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5E7"
  },
  {
    "uniprot": "Q8I5A5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5A5"
  },
  {
    "uniprot": "C0H4X9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4X9"
  },
  {
    "uniprot": "Q8IFL0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFL0"
  },
  {
    "uniprot": "Q8IIU9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIU9"
  },
  {
    "uniprot": "Q8I4T2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4T2"
  },
  {
    "uniprot": "Q8IJF0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJF0"
  },
  {
    "uniprot": "Q8I257",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I257"
  },
  {
    "uniprot": "Q8I553",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I553"
  },
  {
    "uniprot": "A0A144A1Y0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1Y0"
  },
  {
    "uniprot": "C6KSN4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSN4"
  },
  {
    "uniprot": "Q8II72",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II72"
  },
  {
    "uniprot": "Q8I5E9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5E9"
  },
  {
    "uniprot": "Q8I415",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I415"
  },
  {
    "uniprot": "O97249",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97249"
  },
  {
    "uniprot": "Q8I5D6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5D6"
  },
  {
    "uniprot": "Q8IK71",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK71"
  },
  {
    "uniprot": "Q8I4S0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4S0"
  },
  {
    "uniprot": "Q8I287",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I287"
  },
  {
    "uniprot": "Q8ILT0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILT0"
  },
  {
    "uniprot": "O97276",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97276"
  },
  {
    "uniprot": "Q8ILA7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILA7"
  },
  {
    "uniprot": "C0H4S7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4S7"
  },
  {
    "uniprot": "Q9NLA9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NLA9"
  },
  {
    "uniprot": "Q8I5J0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5J0"
  },
  {
    "uniprot": "Q8IKB2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKB2"
  },
  {
    "uniprot": "Q8I2Y2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Y2"
  },
  {
    "uniprot": "C6KSV1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSV1"
  },
  {
    "uniprot": "Q8IDD3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDD3"
  },
  {
    "uniprot": "Q8IL54",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL54"
  },
  {
    "uniprot": "Q8IDZ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDZ2"
  },
  {
    "uniprot": "A0A144A1K0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1K0"
  },
  {
    "uniprot": "Q8I3W0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3W0"
  },
  {
    "uniprot": "Q8ILT2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILT2"
  },
  {
    "uniprot": "O96202",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96202"
  },
  {
    "uniprot": "Q8IIA6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIA6"
  },
  {
    "uniprot": "Q8IM11",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM11"
  },
  {
    "uniprot": "Q8IBK7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBK7"
  },
  {
    "uniprot": "Q8I321",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I321"
  },
  {
    "uniprot": "Q25802",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25802"
  },
  {
    "uniprot": "Q7KWI9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KWI9"
  },
  {
    "uniprot": "Q8IBS5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBS5"
  },
  {
    "uniprot": "Q8IHQ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHQ3"
  },
  {
    "uniprot": "A0A144A1F5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1F5"
  },
  {
    "uniprot": "Q8IBX1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBX1"
  },
  {
    "uniprot": "Q8IIP3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIP3"
  },
  {
    "uniprot": "A0A5K1K810",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K810"
  },
  {
    "uniprot": "Q8I6U7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6U7"
  },
  {
    "uniprot": "Q8I6T2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6T2"
  },
  {
    "uniprot": "Q8IL78",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL78"
  },
  {
    "uniprot": "Q8I435",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I435"
  },
  {
    "uniprot": "Q8IBI7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBI7"
  },
  {
    "uniprot": "Q8II64",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II64"
  },
  {
    "uniprot": "Q8IKY6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKY6"
  },
  {
    "uniprot": "Q8I376",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I376"
  },
  {
    "uniprot": "A0A143ZXY0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXY0"
  },
  {
    "uniprot": "Q8I4R3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4R3"
  },
  {
    "uniprot": "Q8I402",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I402"
  },
  {
    "uniprot": "Q8IL47",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL47"
  },
  {
    "uniprot": "A0A144A0Z1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0Z1"
  },
  {
    "uniprot": "Q7K6B0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7K6B0"
  },
  {
    "uniprot": "C6S3H5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3H5"
  },
  {
    "uniprot": "Q8I206",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I206"
  },
  {
    "uniprot": "C0H4E0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4E0"
  },
  {
    "uniprot": "Q8IKF1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKF1"
  },
  {
    "uniprot": "Q8IDZ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDZ8"
  },
  {
    "uniprot": "Q8I2V6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2V6"
  },
  {
    "uniprot": "Q8IHZ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHZ1"
  },
  {
    "uniprot": "Q8IL74",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL74"
  },
  {
    "uniprot": "Q8IJJ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJJ2"
  },
  {
    "uniprot": "Q8I508",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I508"
  },
  {
    "uniprot": "Q8IHV4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHV4"
  },
  {
    "uniprot": "Q8IL60",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL60"
  },
  {
    "uniprot": "Q8I2I3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2I3"
  },
  {
    "uniprot": "Q8IIZ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIZ7"
  },
  {
    "uniprot": "Q9NLB1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NLB1"
  },
  {
    "uniprot": "Q8IJF6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJF6"
  },
  {
    "uniprot": "Q8IEQ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEQ6"
  },
  {
    "uniprot": "A0A5K1K8E9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8E9"
  },
  {
    "uniprot": "A0A5K1K987",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K987"
  },
  {
    "uniprot": "Q8I356",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I356"
  },
  {
    "uniprot": "C6KSR1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSR1"
  },
  {
    "uniprot": "Q8IJB6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJB6"
  },
  {
    "uniprot": "Q8IL68",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL68"
  },
  {
    "uniprot": "C0H4A3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4A3"
  },
  {
    "uniprot": "O96223",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96223"
  },
  {
    "uniprot": "C0H4Z7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Z7"
  },
  {
    "uniprot": "Q8IBG6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBG6"
  },
  {
    "uniprot": "Q8IIT7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIT7"
  },
  {
    "uniprot": "Q8I383",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I383"
  },
  {
    "uniprot": "Q8I316",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I316"
  },
  {
    "uniprot": "C0H4P6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4P6"
  },
  {
    "uniprot": "Q8IHZ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHZ2"
  },
  {
    "uniprot": "Q8IKI1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKI1"
  },
  {
    "uniprot": "Q8IL23",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL23"
  },
  {
    "uniprot": "Q8IK82",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK82"
  },
  {
    "uniprot": "Q8IJD7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJD7"
  },
  {
    "uniprot": "Q8I352",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I352"
  },
  {
    "uniprot": "Q8I1P5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1P5"
  },
  {
    "uniprot": "Q8I235",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I235"
  },
  {
    "uniprot": "Q8IHZ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHZ8"
  },
  {
    "uniprot": "Q8IKR8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKR8"
  },
  {
    "uniprot": "Q8IDE0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDE0"
  },
  {
    "uniprot": "Q8IL09",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL09"
  },
  {
    "uniprot": "Q8IB50",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB50"
  },
  {
    "uniprot": "Q8IL18",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL18"
  },
  {
    "uniprot": "Q8IHT7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHT7"
  },
  {
    "uniprot": "A0A143ZX45",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZX45"
  },
  {
    "uniprot": "C6KSQ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSQ4"
  },
  {
    "uniprot": "C6KSZ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSZ8"
  },
  {
    "uniprot": "A0A144A0B4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0B4"
  },
  {
    "uniprot": "C0H4X5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4X5"
  },
  {
    "uniprot": "Q8IC03",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC03"
  },
  {
    "uniprot": "O96295",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96295"
  },
  {
    "uniprot": "Q8I633",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I633"
  },
  {
    "uniprot": "A0A5K1K8X8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8X8"
  },
  {
    "uniprot": "Q8I1X1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1X1"
  },
  {
    "uniprot": "A0A144A0C0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0C0"
  },
  {
    "uniprot": "Q8IKE6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKE6"
  },
  {
    "uniprot": "O77356",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77356"
  },
  {
    "uniprot": "Q8I2B9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2B9"
  },
  {
    "uniprot": "Q8IKM7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKM7"
  },
  {
    "uniprot": "Q8I291",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I291"
  },
  {
    "uniprot": "C0H479",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H479"
  },
  {
    "uniprot": "Q8IKM1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKM1"
  },
  {
    "uniprot": "Q8IL55",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL55"
  },
  {
    "uniprot": "Q8I5F6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5F6"
  },
  {
    "uniprot": "C0H4C2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4C2"
  },
  {
    "uniprot": "C0H4Y1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Y1"
  },
  {
    "uniprot": "Q8IJB1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJB1"
  },
  {
    "uniprot": "Q8I3R6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3R6"
  },
  {
    "uniprot": "O77309",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77309"
  },
  {
    "uniprot": "Q8I5N0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5N0"
  },
  {
    "uniprot": "Q8ILH1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILH1"
  },
  {
    "uniprot": "Q8ILA3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILA3"
  },
  {
    "uniprot": "C0H4N2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4N2"
  },
  {
    "uniprot": "Q8ILI4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILI4"
  },
  {
    "uniprot": "Q8I5A6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5A6"
  },
  {
    "uniprot": "Q8ILR4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILR4"
  },
  {
    "uniprot": "Q8I3Q2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Q2"
  },
  {
    "uniprot": "Q8IIA5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIA5"
  },
  {
    "uniprot": "Q8IL24",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL24"
  },
  {
    "uniprot": "Q8IL70",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL70"
  },
  {
    "uniprot": "Q8I445",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I445"
  },
  {
    "uniprot": "C6KTD5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTD5"
  },
  {
    "uniprot": "A0A143ZXZ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXZ7"
  },
  {
    "uniprot": "O97306",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97306"
  },
  {
    "uniprot": "Q8I305",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I305"
  },
  {
    "uniprot": "Q8I494",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I494"
  },
  {
    "uniprot": "Q8I250",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I250"
  },
  {
    "uniprot": "Q8IAW0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAW0"
  },
  {
    "uniprot": "A0A5K1K9A0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9A0"
  },
  {
    "uniprot": "Q8I3B8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3B8"
  },
  {
    "uniprot": "A0A144A0N6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0N6"
  },
  {
    "uniprot": "Q8IKF9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKF9"
  },
  {
    "uniprot": "Q8I4N9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4N9"
  },
  {
    "uniprot": "Q8IJU7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJU7"
  },
  {
    "uniprot": "Q8I390",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I390"
  },
  {
    "uniprot": "Q8IE84",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE84"
  },
  {
    "uniprot": "Q8I1V2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1V2"
  },
  {
    "uniprot": "Q8IDG8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDG8"
  },
  {
    "uniprot": "Q8IAQ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAQ8"
  },
  {
    "uniprot": "C6KTE8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTE8"
  },
  {
    "uniprot": "C0H4E2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4E2"
  },
  {
    "uniprot": "A0A143ZY34",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZY34"
  },
  {
    "uniprot": "O77371",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77371"
  },
  {
    "uniprot": "Q8I3Y9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Y9"
  },
  {
    "uniprot": "Q8IK68",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK68"
  },
  {
    "uniprot": "A0A5K1K8C6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8C6"
  },
  {
    "uniprot": "Q8I607",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I607"
  },
  {
    "uniprot": "Q8ILL5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILL5"
  },
  {
    "uniprot": "Q8IDQ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDQ9"
  },
  {
    "uniprot": "O96248",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96248"
  },
  {
    "uniprot": "C6KT98",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT98"
  },
  {
    "uniprot": "Q8IHR0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHR0"
  },
  {
    "uniprot": "Q8IJJ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJJ7"
  },
  {
    "uniprot": "Q8IB16",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB16"
  },
  {
    "uniprot": "C6KT43",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT43"
  },
  {
    "uniprot": "A0A144A1R4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1R4"
  },
  {
    "uniprot": "Q8I1W0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1W0"
  },
  {
    "uniprot": "A0A144A181",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A181"
  },
  {
    "uniprot": "Q8ILV3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILV3"
  },
  {
    "uniprot": "A0A5K1K9E4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9E4"
  },
  {
    "uniprot": "Q7KQK8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KQK8"
  },
  {
    "uniprot": "C6KSS7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSS7"
  },
  {
    "uniprot": "Q8ICR0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ICR0"
  },
  {
    "uniprot": "Q8I5S0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5S0"
  },
  {
    "uniprot": "Q8I5W3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5W3"
  },
  {
    "uniprot": "Q9NFE5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NFE5"
  },
  {
    "uniprot": "Q8IM80",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM80"
  },
  {
    "uniprot": "Q8I526",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I526"
  },
  {
    "uniprot": "O97269",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97269"
  },
  {
    "uniprot": "C6KSP1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSP1"
  },
  {
    "uniprot": "Q8IK18",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK18"
  },
  {
    "uniprot": "Q8II24",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II24"
  },
  {
    "uniprot": "Q8II00",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II00"
  },
  {
    "uniprot": "Q8IJM8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJM8"
  },
  {
    "uniprot": "C0H485",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H485"
  },
  {
    "uniprot": "Q8IC01",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC01"
  },
  {
    "uniprot": "Q8I2Y5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Y5"
  },
  {
    "uniprot": "Q8IHN6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHN6"
  },
  {
    "uniprot": "O97245",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97245"
  },
  {
    "uniprot": "A0A143ZWU2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWU2"
  },
  {
    "uniprot": "A0A143ZVJ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVJ8"
  },
  {
    "uniprot": "A0A144A382",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A382"
  },
  {
    "uniprot": "Q8IJC9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJC9"
  },
  {
    "uniprot": "Q8IIY1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIY1"
  },
  {
    "uniprot": "Q8IK89",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK89"
  },
  {
    "uniprot": "Q8ID96",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID96"
  },
  {
    "uniprot": "Q8I5H0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5H0"
  },
  {
    "uniprot": "Q8ID61",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID61"
  },
  {
    "uniprot": "Q8IER9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IER9"
  },
  {
    "uniprot": "Q8IJH8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJH8"
  },
  {
    "uniprot": "O77315",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77315"
  },
  {
    "uniprot": "O96231",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96231"
  },
  {
    "uniprot": "Q8IKA5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKA5"
  },
  {
    "uniprot": "Q8ILC8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILC8"
  },
  {
    "uniprot": "A0A144A3J8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3J8"
  },
  {
    "uniprot": "Q8IHW7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHW7"
  },
  {
    "uniprot": "C0H5A5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5A5"
  },
  {
    "uniprot": "Q9U0I0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0I0"
  },
  {
    "uniprot": "C6KT54",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT54"
  },
  {
    "uniprot": "Q8IM03",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM03"
  },
  {
    "uniprot": "Q8I3P3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3P3"
  },
  {
    "uniprot": "Q8IBH3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBH3"
  },
  {
    "uniprot": "Q8I4Y0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Y0"
  },
  {
    "uniprot": "C6KTE3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTE3"
  },
  {
    "uniprot": "Q8IJ22",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ22"
  },
  {
    "uniprot": "Q8I570",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I570"
  },
  {
    "uniprot": "Q8ILG6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILG6"
  },
  {
    "uniprot": "Q8I5N8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5N8"
  },
  {
    "uniprot": "C0H5N0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5N0"
  },
  {
    "uniprot": "Q8I455",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I455"
  },
  {
    "uniprot": "Q8IDH1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDH1"
  },
  {
    "uniprot": "C0H571",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H571"
  },
  {
    "uniprot": "A0A144A039",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A039"
  },
  {
    "uniprot": "C0H4H7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4H7"
  },
  {
    "uniprot": "Q8IKE2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKE2"
  },
  {
    "uniprot": "Q8IAZ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAZ3"
  },
  {
    "uniprot": "Q8IDI4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDI4"
  },
  {
    "uniprot": "Q8IBP8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBP8"
  },
  {
    "uniprot": "Q8I3D4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3D4"
  },
  {
    "uniprot": "Q8IEI1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEI1"
  },
  {
    "uniprot": "Q8IKF7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKF7"
  },
  {
    "uniprot": "Q8IDS0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDS0"
  },
  {
    "uniprot": "Q8I3A1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3A1"
  },
  {
    "uniprot": "Q8IBA9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBA9"
  },
  {
    "uniprot": "Q8I344",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I344"
  },
  {
    "uniprot": "Q8ILX1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILX1"
  },
  {
    "uniprot": "Q8IAS9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAS9"
  },
  {
    "uniprot": "Q8I3B1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3B1"
  },
  {
    "uniprot": "Q8IBA5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBA5"
  },
  {
    "uniprot": "A0A5K1K922",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K922"
  },
  {
    "uniprot": "C6S3G2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3G2"
  },
  {
    "uniprot": "A0A5K1K984",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K984"
  },
  {
    "uniprot": "Q8I3G1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3G1"
  },
  {
    "uniprot": "Q8I5Q9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Q9"
  },
  {
    "uniprot": "Q8IJB0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJB0"
  },
  {
    "uniprot": "Q8IDQ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDQ0"
  },
  {
    "uniprot": "O96169",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96169"
  },
  {
    "uniprot": "Q8IIA8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIA8"
  },
  {
    "uniprot": "Q8I3W4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3W4"
  },
  {
    "uniprot": "Q8IDW6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDW6"
  },
  {
    "uniprot": "C0H581",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H581"
  },
  {
    "uniprot": "Q8II35",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II35"
  },
  {
    "uniprot": "Q8I3P2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3P2"
  },
  {
    "uniprot": "C0H4D2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4D2"
  },
  {
    "uniprot": "A0A144A3W0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3W0"
  },
  {
    "uniprot": "Q8IKP7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKP7"
  },
  {
    "uniprot": "Q8I3K1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3K1"
  },
  {
    "uniprot": "C0H5N2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5N2"
  },
  {
    "uniprot": "C0H4S5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4S5"
  },
  {
    "uniprot": "Q8IIW2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIW2"
  },
  {
    "uniprot": "A0A5K1K9F5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9F5"
  },
  {
    "uniprot": "Q8IDD6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDD6"
  },
  {
    "uniprot": "Q8I2R1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2R1"
  },
  {
    "uniprot": "Q8IK36",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK36"
  },
  {
    "uniprot": "Q8I6Z2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6Z2"
  },
  {
    "uniprot": "Q7KWI7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KWI7"
  },
  {
    "uniprot": "Q8IID1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IID1"
  },
  {
    "uniprot": "Q8I6U9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6U9"
  },
  {
    "uniprot": "A0A144A2H0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2H0"
  },
  {
    "uniprot": "O96244",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96244"
  },
  {
    "uniprot": "Q8I3M4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3M4"
  },
  {
    "uniprot": "C6KST0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KST0"
  },
  {
    "uniprot": "Q8I3H2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3H2"
  },
  {
    "uniprot": "Q8I261",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I261"
  },
  {
    "uniprot": "Q8IB38",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB38"
  },
  {
    "uniprot": "O96190",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96190"
  },
  {
    "uniprot": "Q8I5P1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5P1"
  },
  {
    "uniprot": "O97290",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97290"
  },
  {
    "uniprot": "Q8I613",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I613"
  },
  {
    "uniprot": "Q8I294",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I294"
  },
  {
    "uniprot": "Q8IHN5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHN5"
  },
  {
    "uniprot": "A0A144A4E0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A4E0"
  },
  {
    "uniprot": "O96268",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96268"
  },
  {
    "uniprot": "Q8IIK0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIK0"
  },
  {
    "uniprot": "Q8IIN1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIN1"
  },
  {
    "uniprot": "Q8IKV9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKV9"
  },
  {
    "uniprot": "Q8IJK0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJK0"
  },
  {
    "uniprot": "O96188",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96188"
  },
  {
    "uniprot": "Q8I5I0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5I0"
  },
  {
    "uniprot": "Q8IJ32",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ32"
  },
  {
    "uniprot": "Q8IEQ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEQ3"
  },
  {
    "uniprot": "Q8I2H3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2H3"
  },
  {
    "uniprot": "Q8IJ04",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ04"
  },
  {
    "uniprot": "C0H554",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H554"
  },
  {
    "uniprot": "Q8I2C4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2C4"
  },
  {
    "uniprot": "Q8IAN2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAN2"
  },
  {
    "uniprot": "Q8IBU8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBU8"
  },
  {
    "uniprot": "Q8I482",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I482"
  },
  {
    "uniprot": "Q8IE18",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE18"
  },
  {
    "uniprot": "Q8I3P4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3P4"
  },
  {
    "uniprot": "Q8IDR9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDR9"
  },
  {
    "uniprot": "A0A146M1M7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A146M1M7"
  },
  {
    "uniprot": "Q8II78",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II78"
  },
  {
    "uniprot": "O77393",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77393"
  },
  {
    "uniprot": "Q8IDZ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDZ5"
  },
  {
    "uniprot": "Q8IBU0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBU0"
  },
  {
    "uniprot": "Q8I5T7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5T7"
  },
  {
    "uniprot": "Q8I1Y5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Y5"
  },
  {
    "uniprot": "C6KT74",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT74"
  },
  {
    "uniprot": "A0A5K1K8J0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8J0"
  },
  {
    "uniprot": "Q8IDY4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDY4"
  },
  {
    "uniprot": "O97324",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97324"
  },
  {
    "uniprot": "C6S3I7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3I7"
  },
  {
    "uniprot": "Q8IDF3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDF3"
  },
  {
    "uniprot": "Q8I399",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I399"
  },
  {
    "uniprot": "A0A5K1K8T4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8T4"
  },
  {
    "uniprot": "Q25804",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25804"
  },
  {
    "uniprot": "Q8IIX5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIX5"
  },
  {
    "uniprot": "Q8IIG9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIG9"
  },
  {
    "uniprot": "Q8IL34",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL34"
  },
  {
    "uniprot": "Q8IKS0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKS0"
  },
  {
    "uniprot": "C6KTD4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTD4"
  },
  {
    "uniprot": "A0A5K1K9G0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9G0"
  },
  {
    "uniprot": "O96197",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96197"
  },
  {
    "uniprot": "Q8I6V1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6V1"
  },
  {
    "uniprot": "Q8IKP8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKP8"
  },
  {
    "uniprot": "Q8IJZ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJZ3"
  },
  {
    "uniprot": "Q8IJE6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJE6"
  },
  {
    "uniprot": "Q8I2A2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2A2"
  },
  {
    "uniprot": "Q8IDR2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDR2"
  },
  {
    "uniprot": "Q8I3N0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3N0"
  },
  {
    "uniprot": "Q8IJJ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJJ0"
  },
  {
    "uniprot": "Q8IJR6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJR6"
  },
  {
    "uniprot": "Q8I3X8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3X8"
  },
  {
    "uniprot": "C0H4L9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4L9"
  },
  {
    "uniprot": "Q8I714",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I714"
  },
  {
    "uniprot": "Q8I2Z0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Z0"
  },
  {
    "uniprot": "Q8I5C4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5C4"
  },
  {
    "uniprot": "A0A5K1K8Z2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8Z2"
  },
  {
    "uniprot": "C6S3I2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3I2"
  },
  {
    "uniprot": "Q8IKS3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKS3"
  },
  {
    "uniprot": "Q8IM78",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM78"
  },
  {
    "uniprot": "Q8I5X1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5X1"
  },
  {
    "uniprot": "Q8IFN3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFN3"
  },
  {
    "uniprot": "Q8IJC1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJC1"
  },
  {
    "uniprot": "Q9U0I1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0I1"
  },
  {
    "uniprot": "C0H4M7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4M7"
  },
  {
    "uniprot": "Q9U0N2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0N2"
  },
  {
    "uniprot": "Q8IKX2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKX2"
  },
  {
    "uniprot": "Q8IDG3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDG3"
  },
  {
    "uniprot": "Q8IKR2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKR2"
  },
  {
    "uniprot": "Q8I1T2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1T2"
  },
  {
    "uniprot": "A0A144A4N4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A4N4"
  },
  {
    "uniprot": "Q8IL21",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL21"
  },
  {
    "uniprot": "Q8IJQ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJQ8"
  },
  {
    "uniprot": "Q8IDM8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDM8"
  },
  {
    "uniprot": "Q8IES2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IES2"
  },
  {
    "uniprot": "Q8I1Q3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Q3"
  },
  {
    "uniprot": "Q8IK14",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK14"
  },
  {
    "uniprot": "Q8II23",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II23"
  },
  {
    "uniprot": "Q8IAS3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAS3"
  },
  {
    "uniprot": "A0A146M1X1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A146M1X1"
  },
  {
    "uniprot": "Q8I4R8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4R8"
  },
  {
    "uniprot": "Q8IJZ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJZ7"
  },
  {
    "uniprot": "Q8ILJ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILJ7"
  },
  {
    "uniprot": "Q8I2T9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2T9"
  },
  {
    "uniprot": "Q8I4U6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4U6"
  },
  {
    "uniprot": "C6KSL4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSL4"
  },
  {
    "uniprot": "Q8IAV1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAV1"
  },
  {
    "uniprot": "O96271",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96271"
  },
  {
    "uniprot": "Q8IC37",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC37"
  },
  {
    "uniprot": "Q8IKW8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKW8"
  },
  {
    "uniprot": "A0A5K1K8S4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8S4"
  },
  {
    "uniprot": "Q8II32",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II32"
  },
  {
    "uniprot": "Q8IFQ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFQ7"
  },
  {
    "uniprot": "Q8IJW1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJW1"
  },
  {
    "uniprot": "A0A146M1Z3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A146M1Z3"
  },
  {
    "uniprot": "Q8IJM2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJM2"
  },
  {
    "uniprot": "Q8IK38",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK38"
  },
  {
    "uniprot": "Q8I2L2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2L2"
  },
  {
    "uniprot": "Q8IJP0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJP0"
  },
  {
    "uniprot": "C6KSX3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSX3"
  },
  {
    "uniprot": "Q8I3N2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3N2"
  },
  {
    "uniprot": "O96193",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96193"
  },
  {
    "uniprot": "Q8IE50",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE50"
  },
  {
    "uniprot": "C0H525",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H525"
  },
  {
    "uniprot": "C6KT45",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT45"
  },
  {
    "uniprot": "Q8ILU7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILU7"
  },
  {
    "uniprot": "C0H4T6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4T6"
  },
  {
    "uniprot": "C6S3C1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3C1"
  },
  {
    "uniprot": "Q8I5Y9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Y9"
  },
  {
    "uniprot": "Q8I409",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I409"
  },
  {
    "uniprot": "Q8IKF8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKF8"
  },
  {
    "uniprot": "Q8IHY0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHY0"
  },
  {
    "uniprot": "Q8IFQ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFQ3"
  },
  {
    "uniprot": "C0H4W0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4W0"
  },
  {
    "uniprot": "Q8IDB8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDB8"
  },
  {
    "uniprot": "Q8ID35",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID35"
  },
  {
    "uniprot": "Q8IHM4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHM4"
  },
  {
    "uniprot": "Q8IBK9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBK9"
  },
  {
    "uniprot": "C0H4G6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4G6"
  },
  {
    "uniprot": "Q8IEJ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEJ0"
  },
  {
    "uniprot": "C6S3C6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3C6"
  },
  {
    "uniprot": "Q8I1P7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1P7"
  },
  {
    "uniprot": "C0H5H1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5H1"
  },
  {
    "uniprot": "Q8I4S5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4S5"
  },
  {
    "uniprot": "A0A144A5C9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A5C9"
  },
  {
    "uniprot": "Q8IC33",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC33"
  },
  {
    "uniprot": "Q8I2P0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2P0"
  },
  {
    "uniprot": "Q8I3Y1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Y1"
  },
  {
    "uniprot": "Q8IEA2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEA2"
  },
  {
    "uniprot": "C6KT53",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT53"
  },
  {
    "uniprot": "O96232",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96232"
  },
  {
    "uniprot": "Q8I2L7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2L7"
  },
  {
    "uniprot": "Q8IBD5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBD5"
  },
  {
    "uniprot": "Q8IBR7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBR7"
  },
  {
    "uniprot": "Q8ILN0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILN0"
  },
  {
    "uniprot": "Q25800",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25800"
  },
  {
    "uniprot": "C6S3A9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3A9"
  },
  {
    "uniprot": "Q8IM17",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM17"
  },
  {
    "uniprot": "C0H482",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H482"
  },
  {
    "uniprot": "Q8I492",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I492"
  },
  {
    "uniprot": "A0A144A2U7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2U7"
  },
  {
    "uniprot": "Q8IEK0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEK0"
  },
  {
    "uniprot": "Q8ILU6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILU6"
  },
  {
    "uniprot": "C0H5A7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5A7"
  },
  {
    "uniprot": "A0A5K1K958",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K958"
  },
  {
    "uniprot": "C6KSN7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSN7"
  },
  {
    "uniprot": "A0A5K1K8H0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8H0"
  },
  {
    "uniprot": "Q8IKS8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKS8"
  },
  {
    "uniprot": "A0A144A1E5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1E5"
  },
  {
    "uniprot": "O96222",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96222"
  },
  {
    "uniprot": "C0H4Z4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Z4"
  },
  {
    "uniprot": "Q8IAX2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAX2"
  },
  {
    "uniprot": "C0H4D9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4D9"
  },
  {
    "uniprot": "Q8IAK5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAK5"
  },
  {
    "uniprot": "Q8IJG4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJG4"
  },
  {
    "uniprot": "Q8IHT9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHT9"
  },
  {
    "uniprot": "C6KT36",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT36"
  },
  {
    "uniprot": "O77332",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77332"
  },
  {
    "uniprot": "Q8IJA6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJA6"
  },
  {
    "uniprot": "O97238",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97238"
  },
  {
    "uniprot": "Q8IIX8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIX8"
  },
  {
    "uniprot": "Q8IM70",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM70"
  },
  {
    "uniprot": "A0A143ZYI5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYI5"
  },
  {
    "uniprot": "C0H4D8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4D8"
  },
  {
    "uniprot": "Q8IDR8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDR8"
  },
  {
    "uniprot": "Q8ILQ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILQ5"
  },
  {
    "uniprot": "Q8ILG1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILG1"
  },
  {
    "uniprot": "O97299",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97299"
  },
  {
    "uniprot": "O96221",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96221"
  },
  {
    "uniprot": "Q8ILT7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILT7"
  },
  {
    "uniprot": "C0H5K9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5K9"
  },
  {
    "uniprot": "Q7KQL2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KQL2"
  },
  {
    "uniprot": "Q8IHS5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHS5"
  },
  {
    "uniprot": "Q8I253",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I253"
  },
  {
    "uniprot": "Q8IJF2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJF2"
  },
  {
    "uniprot": "Q8ILB8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILB8"
  },
  {
    "uniprot": "Q8IK13",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK13"
  },
  {
    "uniprot": "A0A5K1K900",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K900"
  },
  {
    "uniprot": "Q8I3C9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3C9"
  },
  {
    "uniprot": "Q8I1Z5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Z5"
  },
  {
    "uniprot": "Q8IBN2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBN2"
  },
  {
    "uniprot": "Q8I2P1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2P1"
  },
  {
    "uniprot": "Q8IL69",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL69"
  },
  {
    "uniprot": "C0H5B0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5B0"
  },
  {
    "uniprot": "Q8ILP1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILP1"
  },
  {
    "uniprot": "Q8IKY1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKY1"
  },
  {
    "uniprot": "Q8IDH7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDH7"
  },
  {
    "uniprot": "Q8IIZ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIZ1"
  },
  {
    "uniprot": "Q8IFK7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFK7"
  },
  {
    "uniprot": "C0H4Z0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Z0"
  },
  {
    "uniprot": "C0H5K3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5K3"
  },
  {
    "uniprot": "O96225",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96225"
  },
  {
    "uniprot": "Q8IIX7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIX7"
  },
  {
    "uniprot": "Q8I2W2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2W2"
  },
  {
    "uniprot": "Q8IHX2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHX2"
  },
  {
    "uniprot": "Q8I2U3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2U3"
  },
  {
    "uniprot": "Q8I2G1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2G1"
  },
  {
    "uniprot": "C6KT84",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT84"
  },
  {
    "uniprot": "A0A5K1K8M1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8M1"
  },
  {
    "uniprot": "Q8IBS3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBS3"
  },
  {
    "uniprot": "O97246",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97246"
  },
  {
    "uniprot": "Q8IHX1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHX1"
  },
  {
    "uniprot": "C0H4S2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4S2"
  },
  {
    "uniprot": "A0A5K1K8N5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8N5"
  },
  {
    "uniprot": "Q8I5T0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5T0"
  },
  {
    "uniprot": "Q8IIN7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIN7"
  },
  {
    "uniprot": "Q8I0V3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0V3"
  },
  {
    "uniprot": "Q8IIG5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIG5"
  },
  {
    "uniprot": "C0H4F1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4F1"
  },
  {
    "uniprot": "A0A144A2E6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2E6"
  },
  {
    "uniprot": "A0A143ZX28",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZX28"
  },
  {
    "uniprot": "C0H4L5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4L5"
  },
  {
    "uniprot": "A0A143ZXB3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXB3"
  },
  {
    "uniprot": "C6KSM7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSM7"
  },
  {
    "uniprot": "A0A5K1K9A2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9A2"
  },
  {
    "uniprot": "Q8I670",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I670"
  },
  {
    "uniprot": "Q8IAZ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAZ1"
  },
  {
    "uniprot": "Q8I477",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I477"
  },
  {
    "uniprot": "A0A5K1K9A6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9A6"
  },
  {
    "uniprot": "Q8I214",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I214"
  },
  {
    "uniprot": "O96278",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96278"
  },
  {
    "uniprot": "Q8IHW3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHW3"
  },
  {
    "uniprot": "Q8IBH4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBH4"
  },
  {
    "uniprot": "Q8IE02",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE02"
  },
  {
    "uniprot": "Q8IEB8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEB8"
  },
  {
    "uniprot": "Q8IB11",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB11"
  },
  {
    "uniprot": "C6S3I0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3I0"
  },
  {
    "uniprot": "Q8IDG6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDG6"
  },
  {
    "uniprot": "Q8IM05",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM05"
  },
  {
    "uniprot": "Q8IBT7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBT7"
  },
  {
    "uniprot": "Q8IAY9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAY9"
  },
  {
    "uniprot": "Q8IBU1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBU1"
  },
  {
    "uniprot": "Q8I1P2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1P2"
  },
  {
    "uniprot": "Q8IHX4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHX4"
  },
  {
    "uniprot": "Q8IHT1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHT1"
  },
  {
    "uniprot": "Q8I2Q6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Q6"
  },
  {
    "uniprot": "Q8I5L6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5L6"
  },
  {
    "uniprot": "C6KTA8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTA8"
  },
  {
    "uniprot": "A0A144A0T7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0T7"
  },
  {
    "uniprot": "C6KT90",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT90"
  },
  {
    "uniprot": "Q8IDQ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDQ7"
  },
  {
    "uniprot": "Q8IEJ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEJ4"
  },
  {
    "uniprot": "B9ZSI2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSI2"
  },
  {
    "uniprot": "Q8IBQ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBQ4"
  },
  {
    "uniprot": "Q8IIM3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIM3"
  },
  {
    "uniprot": "Q8IJC6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJC6"
  },
  {
    "uniprot": "Q9NF98",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NF98"
  },
  {
    "uniprot": "Q8I5Y3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Y3"
  },
  {
    "uniprot": "Q8IHW9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHW9"
  },
  {
    "uniprot": "O96261",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96261"
  },
  {
    "uniprot": "A0A143ZY21",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZY21"
  },
  {
    "uniprot": "Q25821",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25821"
  },
  {
    "uniprot": "C0H4U0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4U0"
  },
  {
    "uniprot": "O96234",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96234"
  },
  {
    "uniprot": "A0A146M427",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A146M427"
  },
  {
    "uniprot": "Q8IL48",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL48"
  },
  {
    "uniprot": "Q8IDX8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDX8"
  },
  {
    "uniprot": "Q8I283",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I283"
  },
  {
    "uniprot": "Q8IBK6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBK6"
  },
  {
    "uniprot": "Q8IKH2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKH2"
  },
  {
    "uniprot": "Q8IIN0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIN0"
  },
  {
    "uniprot": "Q8IJJ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJJ4"
  },
  {
    "uniprot": "Q8IIW8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIW8"
  },
  {
    "uniprot": "Q8II41",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II41"
  },
  {
    "uniprot": "Q8IBI0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBI0"
  },
  {
    "uniprot": "C0H4F2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4F2"
  },
  {
    "uniprot": "Q8IB25",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB25"
  },
  {
    "uniprot": "Q8IAY8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAY8"
  },
  {
    "uniprot": "Q76NM5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q76NM5"
  },
  {
    "uniprot": "O96161",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96161"
  },
  {
    "uniprot": "Q8IDG9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDG9"
  },
  {
    "uniprot": "A0A144A3X6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3X6"
  },
  {
    "uniprot": "Q9NFE6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NFE6"
  },
  {
    "uniprot": "Q8I1P4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1P4"
  },
  {
    "uniprot": "Q4VWQ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q4VWQ3"
  },
  {
    "uniprot": "Q8IBT1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBT1"
  },
  {
    "uniprot": "Q8I1P0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1P0"
  },
  {
    "uniprot": "Q8IID2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IID2"
  },
  {
    "uniprot": "Q8I216",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I216"
  },
  {
    "uniprot": "Q8IBP0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBP0"
  },
  {
    "uniprot": "Q8I2H6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2H6"
  },
  {
    "uniprot": "Q8IJX5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJX5"
  },
  {
    "uniprot": "Q8ILY8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILY8"
  },
  {
    "uniprot": "O96239",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96239"
  },
  {
    "uniprot": "Q8I5E3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5E3"
  },
  {
    "uniprot": "Q8II06",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II06"
  },
  {
    "uniprot": "Q9NLB8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NLB8"
  },
  {
    "uniprot": "Q8IID0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IID0"
  },
  {
    "uniprot": "A0A143ZY63",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZY63"
  },
  {
    "uniprot": "A0A5K1K907",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K907"
  },
  {
    "uniprot": "C0H4E7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4E7"
  },
  {
    "uniprot": "C0H4A5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4A5"
  },
  {
    "uniprot": "Q8I3W2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3W2"
  },
  {
    "uniprot": "Q8I5U3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5U3"
  },
  {
    "uniprot": "Q8IAT5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAT5"
  },
  {
    "uniprot": "Q8I2S0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2S0"
  },
  {
    "uniprot": "Q8ILS3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILS3"
  },
  {
    "uniprot": "O96146",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96146"
  },
  {
    "uniprot": "C6KSY5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSY5"
  },
  {
    "uniprot": "C6KT75",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT75"
  },
  {
    "uniprot": "Q8IKA7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKA7"
  },
  {
    "uniprot": "C6S3C9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3C9"
  },
  {
    "uniprot": "Q8IBF5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBF5"
  },
  {
    "uniprot": "Q8ILK1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILK1"
  },
  {
    "uniprot": "Q8IKC8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKC8"
  },
  {
    "uniprot": "Q8IEU4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEU4"
  },
  {
    "uniprot": "Q8IBD8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBD8"
  },
  {
    "uniprot": "A0A144A0J9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0J9"
  },
  {
    "uniprot": "Q8I295",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I295"
  },
  {
    "uniprot": "Q9U0H7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0H7"
  },
  {
    "uniprot": "C6S3D9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3D9"
  },
  {
    "uniprot": "Q8IAR1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAR1"
  },
  {
    "uniprot": "Q8I3I0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3I0"
  },
  {
    "uniprot": "Q8I4Z0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Z0"
  },
  {
    "uniprot": "A0A144A3Q5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3Q5"
  },
  {
    "uniprot": "Q8IJC8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJC8"
  },
  {
    "uniprot": "Q8I5N4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5N4"
  },
  {
    "uniprot": "Q8IAR5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAR5"
  },
  {
    "uniprot": "Q8IDK6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDK6"
  },
  {
    "uniprot": "Q8I2Y0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Y0"
  },
  {
    "uniprot": "Q9NFE4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NFE4"
  },
  {
    "uniprot": "Q8I2F8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2F8"
  },
  {
    "uniprot": "C0H590",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H590"
  },
  {
    "uniprot": "Q8IIQ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIQ6"
  },
  {
    "uniprot": "Q7KQK4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KQK4"
  },
  {
    "uniprot": "B9ZSI6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSI6"
  },
  {
    "uniprot": "Q8IJR3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJR3"
  },
  {
    "uniprot": "Q8IBL5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBL5"
  },
  {
    "uniprot": "C0H4C3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4C3"
  },
  {
    "uniprot": "Q8IDX3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDX3"
  },
  {
    "uniprot": "Q8IM85",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM85"
  },
  {
    "uniprot": "C6KTE6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTE6"
  },
  {
    "uniprot": "Q8II22",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II22"
  },
  {
    "uniprot": "Q8IED9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IED9"
  },
  {
    "uniprot": "C6KTC9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTC9"
  },
  {
    "uniprot": "Q8IBI3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBI3"
  },
  {
    "uniprot": "A0A144A2N6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2N6"
  },
  {
    "uniprot": "Q8I1U1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1U1"
  },
  {
    "uniprot": "A0A5K1K8M0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8M0"
  },
  {
    "uniprot": "Q8ILT8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILT8"
  },
  {
    "uniprot": "Q8I2U4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2U4"
  },
  {
    "uniprot": "Q8IC39",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC39"
  },
  {
    "uniprot": "Q8IHX6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHX6"
  },
  {
    "uniprot": "Q8IBE5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBE5"
  },
  {
    "uniprot": "Q8I2P9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2P9"
  },
  {
    "uniprot": "Q8IL96",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL96"
  },
  {
    "uniprot": "Q8I3Z5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Z5"
  },
  {
    "uniprot": "Q8I2E7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2E7"
  },
  {
    "uniprot": "Q8I5L3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5L3"
  },
  {
    "uniprot": "Q8I5S3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5S3"
  },
  {
    "uniprot": "C6KSR2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSR2"
  },
  {
    "uniprot": "Q8IL94",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL94"
  },
  {
    "uniprot": "Q8II28",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II28"
  },
  {
    "uniprot": "Q8IBI5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBI5"
  },
  {
    "uniprot": "Q8IE34",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE34"
  },
  {
    "uniprot": "C0H4H1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4H1"
  },
  {
    "uniprot": "Q8IIC5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIC5"
  },
  {
    "uniprot": "Q8IKT8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKT8"
  },
  {
    "uniprot": "C6KSU6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSU6"
  },
  {
    "uniprot": "Q8IBY9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBY9"
  },
  {
    "uniprot": "Q8I506",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I506"
  },
  {
    "uniprot": "Q8IDX4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDX4"
  },
  {
    "uniprot": "Q8IHQ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHQ6"
  },
  {
    "uniprot": "Q8ILM6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILM6"
  },
  {
    "uniprot": "Q8IJG8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJG8"
  },
  {
    "uniprot": "Q9NLB6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NLB6"
  },
  {
    "uniprot": "Q8IIL9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIL9"
  },
  {
    "uniprot": "Q8I3M5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3M5"
  },
  {
    "uniprot": "Q8I2H9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2H9"
  },
  {
    "uniprot": "Q8IEE4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEE4"
  },
  {
    "uniprot": "C0H4Z2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Z2"
  },
  {
    "uniprot": "Q8I5A1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5A1"
  },
  {
    "uniprot": "Q8IL01",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL01"
  },
  {
    "uniprot": "Q8IB23",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB23"
  },
  {
    "uniprot": "Q8IM67",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM67"
  },
  {
    "uniprot": "Q8I2I2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2I2"
  },
  {
    "uniprot": "C0H4L4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4L4"
  },
  {
    "uniprot": "Q8IAN3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAN3"
  },
  {
    "uniprot": "C0H4Y9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Y9"
  },
  {
    "uniprot": "A0A143ZVN0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVN0"
  },
  {
    "uniprot": "Q8IES4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IES4"
  },
  {
    "uniprot": "Q8IJQ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJQ7"
  },
  {
    "uniprot": "O96144",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96144"
  },
  {
    "uniprot": "O77360",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77360"
  },
  {
    "uniprot": "Q8IJF5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJF5"
  },
  {
    "uniprot": "C6KSR8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSR8"
  },
  {
    "uniprot": "Q8IKD4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKD4"
  },
  {
    "uniprot": "Q8IHV8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHV8"
  },
  {
    "uniprot": "Q8IIF2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIF2"
  },
  {
    "uniprot": "Q8ILR7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILR7"
  },
  {
    "uniprot": "Q8I3Y7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Y7"
  },
  {
    "uniprot": "C6KTC0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTC0"
  },
  {
    "uniprot": "C0H488",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H488"
  },
  {
    "uniprot": "Q8I6V2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6V2"
  },
  {
    "uniprot": "Q8ID43",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID43"
  },
  {
    "uniprot": "C6KT87",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT87"
  },
  {
    "uniprot": "A0A5K1K8Q0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8Q0"
  },
  {
    "uniprot": "Q8IKB4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKB4"
  },
  {
    "uniprot": "C6KSU9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSU9"
  },
  {
    "uniprot": "Q8IHV1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHV1"
  },
  {
    "uniprot": "Q8I3S0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3S0"
  },
  {
    "uniprot": "Q8IJJ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJJ5"
  },
  {
    "uniprot": "Q8IHQ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHQ8"
  },
  {
    "uniprot": "Q8I4Z3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Z3"
  },
  {
    "uniprot": "Q8IIM8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIM8"
  },
  {
    "uniprot": "Q8IB55",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB55"
  },
  {
    "uniprot": "O77334",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77334"
  },
  {
    "uniprot": "Q8ILL6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILL6"
  },
  {
    "uniprot": "Q8IJ77",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ77"
  },
  {
    "uniprot": "Q8IKH8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKH8"
  },
  {
    "uniprot": "C6KSU5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSU5"
  },
  {
    "uniprot": "A0A144A0U7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0U7"
  },
  {
    "uniprot": "Q8I360",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I360"
  },
  {
    "uniprot": "Q8IJ69",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ69"
  },
  {
    "uniprot": "Q8IHZ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHZ3"
  },
  {
    "uniprot": "B9ZSI5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSI5"
  },
  {
    "uniprot": "Q8I5R8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5R8"
  },
  {
    "uniprot": "Q8IJG2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJG2"
  },
  {
    "uniprot": "Q8IBN7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBN7"
  },
  {
    "uniprot": "Q8IKU7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKU7"
  },
  {
    "uniprot": "Q8IM81",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM81"
  },
  {
    "uniprot": "Q8I4Y2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Y2"
  },
  {
    "uniprot": "Q8I5Q0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Q0"
  },
  {
    "uniprot": "Q8I5M4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5M4"
  },
  {
    "uniprot": "Q8IDK5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDK5"
  },
  {
    "uniprot": "Q8I5R0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5R0"
  },
  {
    "uniprot": "Q8IJ73",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ73"
  },
  {
    "uniprot": "Q9TY99",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9TY99"
  },
  {
    "uniprot": "Q8IET1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IET1"
  },
  {
    "uniprot": "Q8I3E3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3E3"
  },
  {
    "uniprot": "Q8I557",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I557"
  },
  {
    "uniprot": "Q8IB08",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB08"
  },
  {
    "uniprot": "Q8I2M2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2M2"
  },
  {
    "uniprot": "A0A5K1K9F4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9F4"
  },
  {
    "uniprot": "A0A143ZZU6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZU6"
  },
  {
    "uniprot": "O96167",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96167"
  },
  {
    "uniprot": "Q8I391",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I391"
  },
  {
    "uniprot": "Q8I5I9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5I9"
  },
  {
    "uniprot": "Q8IIZ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIZ9"
  },
  {
    "uniprot": "Q76NM8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q76NM8"
  },
  {
    "uniprot": "Q8I569",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I569"
  },
  {
    "uniprot": "Q8I2D9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2D9"
  },
  {
    "uniprot": "Q8IDE6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDE6"
  },
  {
    "uniprot": "Q8I1U7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1U7"
  },
  {
    "uniprot": "Q8I237",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I237"
  },
  {
    "uniprot": "Q8I0X0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0X0"
  },
  {
    "uniprot": "Q8I239",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I239"
  },
  {
    "uniprot": "Q8IBH0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBH0"
  },
  {
    "uniprot": "Q8IBH1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBH1"
  },
  {
    "uniprot": "O97288",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97288"
  },
  {
    "uniprot": "Q8IBX6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBX6"
  },
  {
    "uniprot": "Q8IER6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IER6"
  },
  {
    "uniprot": "Q8IE49",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE49"
  },
  {
    "uniprot": "Q9NLA8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NLA8"
  },
  {
    "uniprot": "Q8IAN0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAN0"
  },
  {
    "uniprot": "Q8I3V1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3V1"
  },
  {
    "uniprot": "Q8I3J8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3J8"
  },
  {
    "uniprot": "A0A143ZXF2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXF2"
  },
  {
    "uniprot": "Q8IBB3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBB3"
  },
  {
    "uniprot": "Q8I5V2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5V2"
  },
  {
    "uniprot": "O96219",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96219"
  },
  {
    "uniprot": "C6KT31",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT31"
  },
  {
    "uniprot": "C6KT89",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT89"
  },
  {
    "uniprot": "Q8IJX3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJX3"
  },
  {
    "uniprot": "Q8IJZ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJZ6"
  },
  {
    "uniprot": "Q8IHM1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHM1"
  },
  {
    "uniprot": "C6KT19",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT19"
  },
  {
    "uniprot": "C0H5C6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5C6"
  },
  {
    "uniprot": "A0A5K1K981",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K981"
  },
  {
    "uniprot": "Q8I215",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I215"
  },
  {
    "uniprot": "C0H4F0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4F0"
  },
  {
    "uniprot": "Q8IHZ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHZ7"
  },
  {
    "uniprot": "Q8IIV3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIV3"
  },
  {
    "uniprot": "Q8IFQ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFQ2"
  },
  {
    "uniprot": "A0A143ZZF4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZF4"
  },
  {
    "uniprot": "Q8I276",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I276"
  },
  {
    "uniprot": "O96125",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96125"
  },
  {
    "uniprot": "Q8IJM5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJM5"
  },
  {
    "uniprot": "C0H575",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H575"
  },
  {
    "uniprot": "Q8I3J7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3J7"
  },
  {
    "uniprot": "Q8I560",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I560"
  },
  {
    "uniprot": "Q8I5P6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5P6"
  },
  {
    "uniprot": "A0A5K1K9G4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9G4"
  },
  {
    "uniprot": "Q8IEG6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEG6"
  },
  {
    "uniprot": "Q8I469",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I469"
  },
  {
    "uniprot": "Q8IJ65",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ65"
  },
  {
    "uniprot": "Q8ILR3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILR3"
  },
  {
    "uniprot": "Q8IIK5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIK5"
  },
  {
    "uniprot": "O77374",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77374"
  },
  {
    "uniprot": "Q8I523",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I523"
  },
  {
    "uniprot": "Q8IIX6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIX6"
  },
  {
    "uniprot": "Q8IIP2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIP2"
  },
  {
    "uniprot": "C6KTB9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTB9"
  },
  {
    "uniprot": "Q8IEK5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEK5"
  },
  {
    "uniprot": "Q8I2V5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2V5"
  },
  {
    "uniprot": "Q8IBB5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBB5"
  },
  {
    "uniprot": "Q8I446",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I446"
  },
  {
    "uniprot": "C0H5A2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5A2"
  },
  {
    "uniprot": "Q8IL33",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL33"
  },
  {
    "uniprot": "Q8I254",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I254"
  },
  {
    "uniprot": "C6KT32",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT32"
  },
  {
    "uniprot": "Q8I5D7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5D7"
  },
  {
    "uniprot": "Q8IFM8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFM8"
  },
  {
    "uniprot": "Q8I3L2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3L2"
  },
  {
    "uniprot": "O97332",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97332"
  },
  {
    "uniprot": "Q8IJV2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJV2"
  },
  {
    "uniprot": "Q8I4Q5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Q5"
  },
  {
    "uniprot": "O97256",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97256"
  },
  {
    "uniprot": "Q8IJ67",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ67"
  },
  {
    "uniprot": "A0A5K1K8S8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8S8"
  },
  {
    "uniprot": "Q8IM51",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM51"
  },
  {
    "uniprot": "Q8IIZ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIZ6"
  },
  {
    "uniprot": "C0H4B1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4B1"
  },
  {
    "uniprot": "Q8IJ90",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ90"
  },
  {
    "uniprot": "Q8ID54",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID54"
  },
  {
    "uniprot": "Q8IK62",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK62"
  },
  {
    "uniprot": "Q8I1Y1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Y1"
  },
  {
    "uniprot": "Q8I531",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I531"
  },
  {
    "uniprot": "O77388",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77388"
  },
  {
    "uniprot": "C6KST9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KST9"
  },
  {
    "uniprot": "Q8I207",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I207"
  },
  {
    "uniprot": "Q8I5A7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5A7"
  },
  {
    "uniprot": "Q8ILI9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILI9"
  },
  {
    "uniprot": "Q8IL27",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL27"
  },
  {
    "uniprot": "A0A143ZZX1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZX1"
  },
  {
    "uniprot": "Q8IAZ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAZ2"
  },
  {
    "uniprot": "C0H495",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H495"
  },
  {
    "uniprot": "Q8I0X3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0X3"
  },
  {
    "uniprot": "Q8IKA3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKA3"
  },
  {
    "uniprot": "Q8IB33",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB33"
  },
  {
    "uniprot": "Q8ILP9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILP9"
  },
  {
    "uniprot": "Q8I2H2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2H2"
  },
  {
    "uniprot": "Q8IJR0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJR0"
  },
  {
    "uniprot": "C6S3B1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3B1"
  },
  {
    "uniprot": "Q8I522",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I522"
  },
  {
    "uniprot": "C6S3I5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3I5"
  },
  {
    "uniprot": "Q8IHQ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHQ1"
  },
  {
    "uniprot": "O77366",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77366"
  },
  {
    "uniprot": "Q8I5E7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5E7"
  },
  {
    "uniprot": "Q8IIA2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIA2"
  },
  {
    "uniprot": "Q8IEU2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEU2"
  },
  {
    "uniprot": "Q8IBE3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBE3"
  },
  {
    "uniprot": "C0H4B4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4B4"
  },
  {
    "uniprot": "C0H5C0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5C0"
  },
  {
    "uniprot": "Q8IIN8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIN8"
  },
  {
    "uniprot": "Q8IID6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IID6"
  },
  {
    "uniprot": "Q8I395",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I395"
  },
  {
    "uniprot": "Q8IDZ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDZ1"
  },
  {
    "uniprot": "C6KSW0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSW0"
  },
  {
    "uniprot": "A0A144A2V3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2V3"
  },
  {
    "uniprot": "Q8I219",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I219"
  },
  {
    "uniprot": "C6KT80",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT80"
  },
  {
    "uniprot": "Q8IIW9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIW9"
  },
  {
    "uniprot": "Q8I1V6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1V6"
  },
  {
    "uniprot": "A0A143ZZ84",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZ84"
  },
  {
    "uniprot": "B9ZSI7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSI7"
  },
  {
    "uniprot": "Q8I5E2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5E2"
  },
  {
    "uniprot": "C6KT69",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT69"
  },
  {
    "uniprot": "C6KTF4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTF4"
  },
  {
    "uniprot": "C0H543",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H543"
  },
  {
    "uniprot": "Q8IBX0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBX0"
  },
  {
    "uniprot": "B9ZSJ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSJ0"
  },
  {
    "uniprot": "A0A144A0U1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0U1"
  },
  {
    "uniprot": "Q8III9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8III9"
  },
  {
    "uniprot": "Q8IJB7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJB7"
  },
  {
    "uniprot": "O96266",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96266"
  },
  {
    "uniprot": "Q8IET6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IET6"
  },
  {
    "uniprot": "C0H523",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H523"
  },
  {
    "uniprot": "Q8I2L4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2L4"
  },
  {
    "uniprot": "Q8ID26",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID26"
  },
  {
    "uniprot": "Q8IJU8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJU8"
  },
  {
    "uniprot": "A0A144A366",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A366"
  },
  {
    "uniprot": "Q8ILC6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILC6"
  },
  {
    "uniprot": "Q8IJB4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJB4"
  },
  {
    "uniprot": "C0H5H7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5H7"
  },
  {
    "uniprot": "Q8ID74",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID74"
  },
  {
    "uniprot": "O96164",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96164"
  },
  {
    "uniprot": "O97226",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97226"
  },
  {
    "uniprot": "Q8II59",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II59"
  },
  {
    "uniprot": "Q8IED5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IED5"
  },
  {
    "uniprot": "Q8IJX1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJX1"
  },
  {
    "uniprot": "Q8I5Z1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Z1"
  },
  {
    "uniprot": "Q8I481",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I481"
  },
  {
    "uniprot": "C6S3J3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3J3"
  },
  {
    "uniprot": "C0H4F5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4F5"
  },
  {
    "uniprot": "Q8I511",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I511"
  },
  {
    "uniprot": "Q8I3J3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3J3"
  },
  {
    "uniprot": "Q8IHY7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHY7"
  },
  {
    "uniprot": "C0H4R3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4R3"
  },
  {
    "uniprot": "Q8IKV5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKV5"
  },
  {
    "uniprot": "O96166",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96166"
  },
  {
    "uniprot": "Q8IJ66",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ66"
  },
  {
    "uniprot": "Q9U0J3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0J3"
  },
  {
    "uniprot": "C0H5L9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5L9"
  },
  {
    "uniprot": "Q8IJN9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJN9"
  },
  {
    "uniprot": "Q8IM62",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM62"
  },
  {
    "uniprot": "Q8IJB5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJB5"
  },
  {
    "uniprot": "Q8IAR3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAR3"
  },
  {
    "uniprot": "A0A143ZXW4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXW4"
  },
  {
    "uniprot": "Q8IJ08",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ08"
  },
  {
    "uniprot": "C6KT86",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT86"
  },
  {
    "uniprot": "Q8IIR0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIR0"
  },
  {
    "uniprot": "Q8I5G3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5G3"
  },
  {
    "uniprot": "Q8I3Y6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Y6"
  },
  {
    "uniprot": "Q8IL80",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL80"
  },
  {
    "uniprot": "Q8I288",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I288"
  },
  {
    "uniprot": "Q8ILF2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILF2"
  },
  {
    "uniprot": "Q8IBA0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBA0"
  },
  {
    "uniprot": "Q8I310",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I310"
  },
  {
    "uniprot": "Q8IM56",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM56"
  },
  {
    "uniprot": "Q8IC42",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC42"
  },
  {
    "uniprot": "C6S3L2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3L2"
  },
  {
    "uniprot": "Q8IK40",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK40"
  },
  {
    "uniprot": "Q8IBQ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBQ1"
  },
  {
    "uniprot": "O77342",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77342"
  },
  {
    "uniprot": "Q8IEM4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEM4"
  },
  {
    "uniprot": "Q8I365",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I365"
  },
  {
    "uniprot": "O97325",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97325"
  },
  {
    "uniprot": "Q8I540",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I540"
  },
  {
    "uniprot": "O97287",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97287"
  },
  {
    "uniprot": "Q8IIB9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIB9"
  },
  {
    "uniprot": "C6KT30",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT30"
  },
  {
    "uniprot": "Q8IE63",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE63"
  },
  {
    "uniprot": "B9ZSJ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSJ1"
  },
  {
    "uniprot": "Q8I259",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I259"
  },
  {
    "uniprot": "Q8I2P4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2P4"
  },
  {
    "uniprot": "O96294",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96294"
  },
  {
    "uniprot": "C0H5G0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5G0"
  },
  {
    "uniprot": "Q8I4Q4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Q4"
  },
  {
    "uniprot": "O96168",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96168"
  },
  {
    "uniprot": "C6KT97",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT97"
  },
  {
    "uniprot": "O77381",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77381"
  },
  {
    "uniprot": "Q8ILP7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILP7"
  },
  {
    "uniprot": "Q8IJ21",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ21"
  },
  {
    "uniprot": "A0A5K1K935",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K935"
  },
  {
    "uniprot": "C0H597",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H597"
  },
  {
    "uniprot": "Q8I636",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I636"
  },
  {
    "uniprot": "Q8IIT9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIT9"
  },
  {
    "uniprot": "Q8IAX5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAX5"
  },
  {
    "uniprot": "Q8I519",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I519"
  },
  {
    "uniprot": "A0A144A0U2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0U2"
  },
  {
    "uniprot": "Q8IKH3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKH3"
  },
  {
    "uniprot": "A0A144A2M6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2M6"
  },
  {
    "uniprot": "C0H5I9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5I9"
  },
  {
    "uniprot": "Q8IIE2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIE2"
  },
  {
    "uniprot": "Q8I4Q7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Q7"
  },
  {
    "uniprot": "C6KTA1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTA1"
  },
  {
    "uniprot": "Q8I4P5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4P5"
  },
  {
    "uniprot": "Q8IAR7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAR7"
  },
  {
    "uniprot": "Q8IM39",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM39"
  },
  {
    "uniprot": "C0H4G8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4G8"
  },
  {
    "uniprot": "C0H5F1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5F1"
  },
  {
    "uniprot": "Q8I3D1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3D1"
  },
  {
    "uniprot": "Q8IJT9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJT9"
  },
  {
    "uniprot": "Q8IIQ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIQ3"
  },
  {
    "uniprot": "Q8IDE8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDE8"
  },
  {
    "uniprot": "Q8IBU5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBU5"
  },
  {
    "uniprot": "C0H5I7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5I7"
  },
  {
    "uniprot": "Q8IBM2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBM2"
  },
  {
    "uniprot": "Q8I653",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I653"
  },
  {
    "uniprot": "Q8II37",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II37"
  },
  {
    "uniprot": "O96129",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96129"
  },
  {
    "uniprot": "Q8IC02",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC02"
  },
  {
    "uniprot": "Q8IIR2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIR2"
  },
  {
    "uniprot": "C6KT64",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT64"
  },
  {
    "uniprot": "A0A143ZVI1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVI1"
  },
  {
    "uniprot": "Q8IKH1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKH1"
  },
  {
    "uniprot": "Q8I3U4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3U4"
  },
  {
    "uniprot": "C6KSU3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSU3"
  },
  {
    "uniprot": "Q8I205",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I205"
  },
  {
    "uniprot": "O97271",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97271"
  },
  {
    "uniprot": "Q8I5W2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5W2"
  },
  {
    "uniprot": "Q8IKT4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKT4"
  },
  {
    "uniprot": "Q8I5R5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5R5"
  },
  {
    "uniprot": "Q8IJB3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJB3"
  },
  {
    "uniprot": "A0A143ZYC7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYC7"
  },
  {
    "uniprot": "Q8I2D6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2D6"
  },
  {
    "uniprot": "Q8I375",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I375"
  },
  {
    "uniprot": "A0A143ZXC7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXC7"
  },
  {
    "uniprot": "Q8I5P2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5P2"
  },
  {
    "uniprot": "C0H5I8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5I8"
  },
  {
    "uniprot": "Q8IJT1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJT1"
  },
  {
    "uniprot": "Q8ILJ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILJ5"
  },
  {
    "uniprot": "Q8IKW6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKW6"
  },
  {
    "uniprot": "Q8IIP8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIP8"
  },
  {
    "uniprot": "Q8I5U9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5U9"
  },
  {
    "uniprot": "Q8I495",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I495"
  },
  {
    "uniprot": "C0H4V6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4V6"
  },
  {
    "uniprot": "A0A140KXW0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A140KXW0"
  },
  {
    "uniprot": "C0H4Y6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Y6"
  },
  {
    "uniprot": "Q8ILG5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILG5"
  },
  {
    "uniprot": "A0A144A026",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A026"
  },
  {
    "uniprot": "C0H576",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H576"
  },
  {
    "uniprot": "Q8IIL7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIL7"
  },
  {
    "uniprot": "Q8IM08",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM08"
  },
  {
    "uniprot": "Q8IB05",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB05"
  },
  {
    "uniprot": "O96210",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96210"
  },
  {
    "uniprot": "Q8ILN9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILN9"
  },
  {
    "uniprot": "Q8I397",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I397"
  },
  {
    "uniprot": "C6KSZ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSZ6"
  },
  {
    "uniprot": "A0A144A0G8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0G8"
  },
  {
    "uniprot": "Q8I306",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I306"
  },
  {
    "uniprot": "A0A5K1K988",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K988"
  },
  {
    "uniprot": "Q8I4U0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4U0"
  },
  {
    "uniprot": "Q8IHT4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHT4"
  },
  {
    "uniprot": "Q8IBN8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBN8"
  },
  {
    "uniprot": "C0H5D2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5D2"
  },
  {
    "uniprot": "Q8IE66",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE66"
  },
  {
    "uniprot": "Q8I2M9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2M9"
  },
  {
    "uniprot": "A0A5K1K963",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K963"
  },
  {
    "uniprot": "C0H534",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H534"
  },
  {
    "uniprot": "A0A144A260",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A260"
  },
  {
    "uniprot": "Q8IIJ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIJ8"
  },
  {
    "uniprot": "Q8IFP7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFP7"
  },
  {
    "uniprot": "Q8IM69",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM69"
  },
  {
    "uniprot": "A0A144A1F1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1F1"
  },
  {
    "uniprot": "A0A144A1F0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1F0"
  },
  {
    "uniprot": "A0A5K1K8R2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8R2"
  },
  {
    "uniprot": "Q8I6U5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6U5"
  },
  {
    "uniprot": "C6S3C0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3C0"
  },
  {
    "uniprot": "Q8II47",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II47"
  },
  {
    "uniprot": "A0A5K1K873",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K873"
  },
  {
    "uniprot": "Q8I5J1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5J1"
  },
  {
    "uniprot": "O96116",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96116"
  },
  {
    "uniprot": "A0A5K1K925",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K925"
  },
  {
    "uniprot": "Q8I528",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I528"
  },
  {
    "uniprot": "Q8IJS4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJS4"
  },
  {
    "uniprot": "A0A144A2E0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2E0"
  },
  {
    "uniprot": "Q8I5T6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5T6"
  },
  {
    "uniprot": "Q8IBQ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBQ9"
  },
  {
    "uniprot": "Q8IK43",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK43"
  },
  {
    "uniprot": "Q8IIH0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIH0"
  },
  {
    "uniprot": "Q8I2Z6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Z6"
  },
  {
    "uniprot": "Q8IDN4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDN4"
  },
  {
    "uniprot": "A0A144A0V8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0V8"
  },
  {
    "uniprot": "Q8I5X5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5X5"
  },
  {
    "uniprot": "Q8I2M7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2M7"
  },
  {
    "uniprot": "O77326",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77326"
  },
  {
    "uniprot": "Q8IL88",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL88"
  },
  {
    "uniprot": "Q9NFB4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NFB4"
  },
  {
    "uniprot": "Q8ILS8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILS8"
  },
  {
    "uniprot": "O96127",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96127"
  },
  {
    "uniprot": "Q8ILP6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILP6"
  },
  {
    "uniprot": "C6KTB5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTB5"
  },
  {
    "uniprot": "Q8II12",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II12"
  },
  {
    "uniprot": "Q8IKF5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKF5"
  },
  {
    "uniprot": "O96211",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96211"
  },
  {
    "uniprot": "Q8IAL4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAL4"
  },
  {
    "uniprot": "O96179",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96179"
  },
  {
    "uniprot": "Q8IAL3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAL3"
  },
  {
    "uniprot": "Q8I5G2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5G2"
  },
  {
    "uniprot": "O77383",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77383"
  },
  {
    "uniprot": "Q8I4U5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4U5"
  },
  {
    "uniprot": "A0A144A0T0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0T0"
  },
  {
    "uniprot": "A0A5K1K965",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K965"
  },
  {
    "uniprot": "Q8I377",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I377"
  },
  {
    "uniprot": "Q8I271",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I271"
  },
  {
    "uniprot": "Q8IDG7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDG7"
  },
  {
    "uniprot": "Q8IKF0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKF0"
  },
  {
    "uniprot": "Q8IHP5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHP5"
  },
  {
    "uniprot": "Q8IEL1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEL1"
  },
  {
    "uniprot": "Q8IE98",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE98"
  },
  {
    "uniprot": "Q8I3E0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3E0"
  },
  {
    "uniprot": "C6S3H2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3H2"
  },
  {
    "uniprot": "Q8II89",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II89"
  },
  {
    "uniprot": "Q8IB73",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB73"
  },
  {
    "uniprot": "Q8IFK9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFK9"
  },
  {
    "uniprot": "Q8ILJ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILJ4"
  },
  {
    "uniprot": "Q8IIV8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIV8"
  },
  {
    "uniprot": "Q9TY95",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9TY95"
  },
  {
    "uniprot": "A0A143ZWT9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWT9"
  },
  {
    "uniprot": "C0H4Y5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Y5"
  },
  {
    "uniprot": "C6KTC2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTC2"
  },
  {
    "uniprot": "Q8IJZ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJZ8"
  },
  {
    "uniprot": "Q8I5J2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5J2"
  },
  {
    "uniprot": "C6KSN5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSN5"
  },
  {
    "uniprot": "Q8I3B4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3B4"
  },
  {
    "uniprot": "Q76NL8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q76NL8"
  },
  {
    "uniprot": "Q8IDL4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDL4"
  },
  {
    "uniprot": "Q8II95",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II95"
  },
  {
    "uniprot": "Q8IK69",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK69"
  },
  {
    "uniprot": "Q8I5Z6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Z6"
  },
  {
    "uniprot": "C0H563",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H563"
  },
  {
    "uniprot": "C0H527",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H527"
  },
  {
    "uniprot": "Q8I416",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I416"
  },
  {
    "uniprot": "Q8IHW8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHW8"
  },
  {
    "uniprot": "O77306",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77306"
  },
  {
    "uniprot": "A0A144A297",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A297"
  },
  {
    "uniprot": "Q8I289",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I289"
  },
  {
    "uniprot": "Q8I634",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I634"
  },
  {
    "uniprot": "Q8IJS2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJS2"
  },
  {
    "uniprot": "Q8I3P6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3P6"
  },
  {
    "uniprot": "Q8IJJ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJJ9"
  },
  {
    "uniprot": "Q8I3W6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3W6"
  },
  {
    "uniprot": "Q8IC35",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC35"
  },
  {
    "uniprot": "C6KSN9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSN9"
  },
  {
    "uniprot": "Q9U0H5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0H5"
  },
  {
    "uniprot": "C0H4H5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4H5"
  },
  {
    "uniprot": "Q8ILZ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILZ4"
  },
  {
    "uniprot": "O97328",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97328"
  },
  {
    "uniprot": "Q8I638",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I638"
  },
  {
    "uniprot": "Q8I2G0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2G0"
  },
  {
    "uniprot": "Q8IM73",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM73"
  },
  {
    "uniprot": "Q8I5E4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5E4"
  },
  {
    "uniprot": "Q8IB99",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB99"
  },
  {
    "uniprot": "Q8I246",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I246"
  },
  {
    "uniprot": "C6KSY6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSY6"
  },
  {
    "uniprot": "Q8IIH8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIH8"
  },
  {
    "uniprot": "Q8I2C6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2C6"
  },
  {
    "uniprot": "Q8IJ31",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ31"
  },
  {
    "uniprot": "A0A143ZWS9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWS9"
  },
  {
    "uniprot": "C0H5G7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5G7"
  },
  {
    "uniprot": "Q8I3N6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3N6"
  },
  {
    "uniprot": "Q8ILF6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILF6"
  },
  {
    "uniprot": "C0H5B2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5B2"
  },
  {
    "uniprot": "A0A5K1K956",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K956"
  },
  {
    "uniprot": "Q8I3N7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3N7"
  },
  {
    "uniprot": "Q8IM50",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM50"
  },
  {
    "uniprot": "A0A144A3K9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A3K9"
  },
  {
    "uniprot": "Q8IIE0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIE0"
  },
  {
    "uniprot": "A0A5K1K962",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K962"
  },
  {
    "uniprot": "Q8I6Z4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6Z4"
  },
  {
    "uniprot": "Q8II45",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II45"
  },
  {
    "uniprot": "Q8I3W1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3W1"
  },
  {
    "uniprot": "C6S3K1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3K1"
  },
  {
    "uniprot": "A0A143ZY58",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZY58"
  },
  {
    "uniprot": "Q8IEI4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEI4"
  },
  {
    "uniprot": "Q8I401",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I401"
  },
  {
    "uniprot": "Q8II62",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II62"
  },
  {
    "uniprot": "Q25813",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25813"
  },
  {
    "uniprot": "Q8I567",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I567"
  },
  {
    "uniprot": "Q8I2Z2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Z2"
  },
  {
    "uniprot": "A0A5K1K8X4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8X4"
  },
  {
    "uniprot": "A0A5K1K941",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K941"
  },
  {
    "uniprot": "Q8I2F7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2F7"
  },
  {
    "uniprot": "Q8I484",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I484"
  },
  {
    "uniprot": "Q8ID50",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID50"
  },
  {
    "uniprot": "Q8I1V0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1V0"
  },
  {
    "uniprot": "Q8I1X3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1X3"
  },
  {
    "uniprot": "Q8I3B9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3B9"
  },
  {
    "uniprot": "A0A143ZYV5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYV5"
  },
  {
    "uniprot": "O96209",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96209"
  },
  {
    "uniprot": "C0H4P0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4P0"
  },
  {
    "uniprot": "Q8I364",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I364"
  },
  {
    "uniprot": "Q8IJW9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJW9"
  },
  {
    "uniprot": "Q9NFB2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NFB2"
  },
  {
    "uniprot": "Q8IKG3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKG3"
  },
  {
    "uniprot": "Q8I430",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I430"
  },
  {
    "uniprot": "Q8I3Y3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Y3"
  },
  {
    "uniprot": "Q8I2D3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2D3"
  },
  {
    "uniprot": "Q8II07",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II07"
  },
  {
    "uniprot": "C0H4G5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4G5"
  },
  {
    "uniprot": "Q8IIX2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIX2"
  },
  {
    "uniprot": "Q8I2A9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2A9"
  },
  {
    "uniprot": "O77354",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77354"
  },
  {
    "uniprot": "Q8IIT2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIT2"
  },
  {
    "uniprot": "Q8I325",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I325"
  },
  {
    "uniprot": "Q8I202",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I202"
  },
  {
    "uniprot": "A0A143ZVA4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVA4"
  },
  {
    "uniprot": "A0A143ZYI9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYI9"
  },
  {
    "uniprot": "Q8I3H5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3H5"
  },
  {
    "uniprot": "C0H535",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H535"
  },
  {
    "uniprot": "Q8IIH9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIH9"
  },
  {
    "uniprot": "A0A143ZZY9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZY9"
  },
  {
    "uniprot": "C6KTA5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTA5"
  },
  {
    "uniprot": "C0H512",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H512"
  },
  {
    "uniprot": "C0H5J1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5J1"
  },
  {
    "uniprot": "Q8I5R2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5R2"
  },
  {
    "uniprot": "Q8IKQ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKQ0"
  },
  {
    "uniprot": "Q8I2C8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2C8"
  },
  {
    "uniprot": "Q8IJS9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJS9"
  },
  {
    "uniprot": "Q8I487",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I487"
  },
  {
    "uniprot": "Q8I4N8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4N8"
  },
  {
    "uniprot": "Q8I3K7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3K7"
  },
  {
    "uniprot": "O77394",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77394"
  },
  {
    "uniprot": "C0H4I0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4I0"
  },
  {
    "uniprot": "Q8I2E9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2E9"
  },
  {
    "uniprot": "Q8I2L0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2L0"
  },
  {
    "uniprot": "Q8IKI6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKI6"
  },
  {
    "uniprot": "C6KT22",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT22"
  },
  {
    "uniprot": "Q8I5V5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5V5"
  },
  {
    "uniprot": "O77310",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77310"
  },
  {
    "uniprot": "C6S3H8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3H8"
  },
  {
    "uniprot": "Q8I5Q5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Q5"
  },
  {
    "uniprot": "Q8I3P1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3P1"
  },
  {
    "uniprot": "C0H513",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H513"
  },
  {
    "uniprot": "Q8IEL7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEL7"
  },
  {
    "uniprot": "Q8IJH5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJH5"
  },
  {
    "uniprot": "O96276",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96276"
  },
  {
    "uniprot": "Q8IK46",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK46"
  },
  {
    "uniprot": "Q8IEC1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEC1"
  },
  {
    "uniprot": "C0H5G3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5G3"
  },
  {
    "uniprot": "Q8IKA8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKA8"
  },
  {
    "uniprot": "Q8II44",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II44"
  },
  {
    "uniprot": "Q8ID56",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID56"
  },
  {
    "uniprot": "Q8I272",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I272"
  },
  {
    "uniprot": "Q8I1S7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1S7"
  },
  {
    "uniprot": "Q8I3K0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3K0"
  },
  {
    "uniprot": "C6KSP6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSP6"
  },
  {
    "uniprot": "Q8I2G2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2G2"
  },
  {
    "uniprot": "Q8IKZ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKZ6"
  },
  {
    "uniprot": "Q8IKK0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKK0"
  },
  {
    "uniprot": "A0A5K1K948",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K948"
  },
  {
    "uniprot": "Q8IHR7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHR7"
  },
  {
    "uniprot": "Q8IJ95",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ95"
  },
  {
    "uniprot": "C0H568",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H568"
  },
  {
    "uniprot": "Q8IEG9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEG9"
  },
  {
    "uniprot": "C0H5B5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5B5"
  },
  {
    "uniprot": "Q8IBE4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBE4"
  },
  {
    "uniprot": "Q8ILK5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILK5"
  },
  {
    "uniprot": "C0H5N9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5N9"
  },
  {
    "uniprot": "Q8I4S3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4S3"
  },
  {
    "uniprot": "Q8IM27",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM27"
  },
  {
    "uniprot": "A0A143ZX69",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZX69"
  },
  {
    "uniprot": "Q8II14",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II14"
  },
  {
    "uniprot": "C0H5F0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5F0"
  },
  {
    "uniprot": "Q8IEH6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEH6"
  },
  {
    "uniprot": "Q8I4V9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4V9"
  },
  {
    "uniprot": "Q8IBY5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBY5"
  },
  {
    "uniprot": "Q8II84",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II84"
  },
  {
    "uniprot": "Q8IBW2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBW2"
  },
  {
    "uniprot": "Q8IJH0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJH0"
  },
  {
    "uniprot": "Q8I472",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I472"
  },
  {
    "uniprot": "C0H4M1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4M1"
  },
  {
    "uniprot": "B9ZSI1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSI1"
  },
  {
    "uniprot": "Q8IBU4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBU4"
  },
  {
    "uniprot": "Q8IK20",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK20"
  },
  {
    "uniprot": "A0A5K1K8Y1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8Y1"
  },
  {
    "uniprot": "Q8IJK8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJK8"
  },
  {
    "uniprot": "Q8IBE2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBE2"
  },
  {
    "uniprot": "Q8IDE3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDE3"
  },
  {
    "uniprot": "Q8IIV2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIV2"
  },
  {
    "uniprot": "Q8I1R1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1R1"
  },
  {
    "uniprot": "Q8I3A7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3A7"
  },
  {
    "uniprot": "Q8IJ97",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ97"
  },
  {
    "uniprot": "Q8I5G8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5G8"
  },
  {
    "uniprot": "O97326",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97326"
  },
  {
    "uniprot": "Q8I3B3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3B3"
  },
  {
    "uniprot": "Q8IL61",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL61"
  },
  {
    "uniprot": "Q8I5K8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5K8"
  },
  {
    "uniprot": "C6KTD1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTD1"
  },
  {
    "uniprot": "Q8II87",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II87"
  },
  {
    "uniprot": "Q8IAX4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAX4"
  },
  {
    "uniprot": "Q8I1X9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1X9"
  },
  {
    "uniprot": "A0A5K1K8C9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8C9"
  },
  {
    "uniprot": "Q8IIR8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIR8"
  },
  {
    "uniprot": "Q8I5L8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5L8"
  },
  {
    "uniprot": "Q8IK12",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK12"
  },
  {
    "uniprot": "Q8IB57",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB57"
  },
  {
    "uniprot": "Q8I300",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I300"
  },
  {
    "uniprot": "Q8IJ52",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ52"
  },
  {
    "uniprot": "C6S3K8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3K8"
  },
  {
    "uniprot": "A0A5K1K961",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K961"
  },
  {
    "uniprot": "A0A143ZYC1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYC1"
  },
  {
    "uniprot": "A0A143ZWL6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWL6"
  },
  {
    "uniprot": "Q8IM54",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM54"
  },
  {
    "uniprot": "Q7KWI6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KWI6"
  },
  {
    "uniprot": "Q8IAS0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAS0"
  },
  {
    "uniprot": "Q8IBQ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBQ8"
  },
  {
    "uniprot": "Q8IDL6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDL6"
  },
  {
    "uniprot": "Q8II77",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II77"
  },
  {
    "uniprot": "C6KSP2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSP2"
  },
  {
    "uniprot": "A0A5K1K8V5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8V5"
  },
  {
    "uniprot": "Q8IJT6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJT6"
  },
  {
    "uniprot": "Q8I5V3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5V3"
  },
  {
    "uniprot": "Q8IKI3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKI3"
  },
  {
    "uniprot": "Q8ID66",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID66"
  },
  {
    "uniprot": "Q8IKS9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKS9"
  },
  {
    "uniprot": "C6KT24",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT24"
  },
  {
    "uniprot": "C6S3F0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3F0"
  },
  {
    "uniprot": "C0H4D5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4D5"
  },
  {
    "uniprot": "C0H4F7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4F7"
  },
  {
    "uniprot": "A0A5K1K847",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K847"
  },
  {
    "uniprot": "Q8I600",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I600"
  },
  {
    "uniprot": "Q8I575",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I575"
  },
  {
    "uniprot": "Q8I520",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I520"
  },
  {
    "uniprot": "O77353",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77353"
  },
  {
    "uniprot": "Q8I475",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I475"
  },
  {
    "uniprot": "Q8IC10",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC10"
  },
  {
    "uniprot": "C0H514",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H514"
  },
  {
    "uniprot": "Q8IJ81",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ81"
  },
  {
    "uniprot": "Q8I5I5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5I5"
  },
  {
    "uniprot": "A0A143ZYK6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYK6"
  },
  {
    "uniprot": "Q8IBD9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBD9"
  },
  {
    "uniprot": "C6KT77",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT77"
  },
  {
    "uniprot": "A0A5K1K923",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K923"
  },
  {
    "uniprot": "A0A5K1K8H8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8H8"
  },
  {
    "uniprot": "Q8IEH2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEH2"
  },
  {
    "uniprot": "C0H5E1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5E1"
  },
  {
    "uniprot": "C6S3D8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3D8"
  },
  {
    "uniprot": "O97277",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97277"
  },
  {
    "uniprot": "A0A143ZXG9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXG9"
  },
  {
    "uniprot": "O96191",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96191"
  },
  {
    "uniprot": "Q8IAY3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAY3"
  },
  {
    "uniprot": "Q8I2J2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2J2"
  },
  {
    "uniprot": "C0H4K0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4K0"
  },
  {
    "uniprot": "Q9U0K4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0K4"
  },
  {
    "uniprot": "A0A5K1K993",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K993"
  },
  {
    "uniprot": "Q8I3G6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3G6"
  },
  {
    "uniprot": "Q9U0I4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0I4"
  },
  {
    "uniprot": "Q8IK27",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK27"
  },
  {
    "uniprot": "Q8IJL4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJL4"
  },
  {
    "uniprot": "Q8ILH6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILH6"
  },
  {
    "uniprot": "Q8IFM5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFM5"
  },
  {
    "uniprot": "Q8IBP9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBP9"
  },
  {
    "uniprot": "Q8I598",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I598"
  },
  {
    "uniprot": "Q8IBD4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBD4"
  },
  {
    "uniprot": "Q8I6S7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6S7"
  },
  {
    "uniprot": "A0A5K1K9E6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9E6"
  },
  {
    "uniprot": "A0A140KXV7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A140KXV7"
  },
  {
    "uniprot": "Q8I504",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I504"
  },
  {
    "uniprot": "A0A144A6T9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A6T9"
  },
  {
    "uniprot": "Q8IHR8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHR8"
  },
  {
    "uniprot": "Q8II97",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II97"
  },
  {
    "uniprot": "O77339",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77339"
  },
  {
    "uniprot": "A0A144A372",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A372"
  },
  {
    "uniprot": "Q8I662",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I662"
  },
  {
    "uniprot": "Q8I0W8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0W8"
  },
  {
    "uniprot": "Q8ID34",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID34"
  },
  {
    "uniprot": "Q8I651",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I651"
  },
  {
    "uniprot": "C6S3E8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3E8"
  },
  {
    "uniprot": "A0A143ZXE7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXE7"
  },
  {
    "uniprot": "A0A144A1R2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1R2"
  },
  {
    "uniprot": "C6S3B8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3B8"
  },
  {
    "uniprot": "Q8IEF7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEF7"
  },
  {
    "uniprot": "Q8IAY4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAY4"
  },
  {
    "uniprot": "C6KST6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KST6"
  },
  {
    "uniprot": "Q8IL66",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL66"
  },
  {
    "uniprot": "Q8IJY8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJY8"
  },
  {
    "uniprot": "A0A5K1K8G8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8G8"
  },
  {
    "uniprot": "Q8IET7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IET7"
  },
  {
    "uniprot": "Q8IDM6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDM6"
  },
  {
    "uniprot": "Q8IJS0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJS0"
  },
  {
    "uniprot": "Q8IEH0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEH0"
  },
  {
    "uniprot": "Q8IK98",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK98"
  },
  {
    "uniprot": "A0A143ZVK7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVK7"
  },
  {
    "uniprot": "Q8IKS4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKS4"
  },
  {
    "uniprot": "Q9U0J6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0J6"
  },
  {
    "uniprot": "Q8IM68",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM68"
  },
  {
    "uniprot": "A0A144A0Z6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0Z6"
  },
  {
    "uniprot": "Q8I2T2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2T2"
  },
  {
    "uniprot": "Q8IB95",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB95"
  },
  {
    "uniprot": "Q8ILA0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILA0"
  },
  {
    "uniprot": "Q8I3A9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3A9"
  },
  {
    "uniprot": "A0A143ZVJ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVJ7"
  },
  {
    "uniprot": "C0H4L1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4L1"
  },
  {
    "uniprot": "Q8I603",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I603"
  },
  {
    "uniprot": "Q8I3F2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3F2"
  },
  {
    "uniprot": "C0H528",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H528"
  },
  {
    "uniprot": "O97265",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97265"
  },
  {
    "uniprot": "Q8IKL7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKL7"
  },
  {
    "uniprot": "Q25810",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25810"
  },
  {
    "uniprot": "Q8IBG2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBG2"
  },
  {
    "uniprot": "Q8I2E6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2E6"
  },
  {
    "uniprot": "C0H496",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H496"
  },
  {
    "uniprot": "Q8I5P3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5P3"
  },
  {
    "uniprot": "A0A143ZXC4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZXC4"
  },
  {
    "uniprot": "Q8IK44",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK44"
  },
  {
    "uniprot": "C0H4W3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4W3"
  },
  {
    "uniprot": "Q8I6S5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6S5"
  },
  {
    "uniprot": "Q8IJS3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJS3"
  },
  {
    "uniprot": "Q8I660",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I660"
  },
  {
    "uniprot": "Q8IJT8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJT8"
  },
  {
    "uniprot": "C6S3G8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3G8"
  },
  {
    "uniprot": "C6KST2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KST2"
  },
  {
    "uniprot": "Q8II67",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II67"
  },
  {
    "uniprot": "Q9U0J9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0J9"
  },
  {
    "uniprot": "Q8IFP3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFP3"
  },
  {
    "uniprot": "Q8I0W9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0W9"
  },
  {
    "uniprot": "O96224",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96224"
  },
  {
    "uniprot": "Q8IL46",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL46"
  },
  {
    "uniprot": "C0H579",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H579"
  },
  {
    "uniprot": "A0A5K1K8Q3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8Q3"
  },
  {
    "uniprot": "O96153",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96153"
  },
  {
    "uniprot": "Q08210",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q08210"
  },
  {
    "uniprot": "A0A5K1K9A3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9A3"
  },
  {
    "uniprot": "Q8I503",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I503"
  },
  {
    "uniprot": "Q8I298",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I298"
  },
  {
    "uniprot": "O97232",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97232"
  },
  {
    "uniprot": "Q8IBG5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBG5"
  },
  {
    "uniprot": "Q8ILZ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILZ7"
  },
  {
    "uniprot": "Q8I5M3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5M3"
  },
  {
    "uniprot": "Q8I3J2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3J2"
  },
  {
    "uniprot": "C0H546",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H546"
  },
  {
    "uniprot": "Q8I3A8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3A8"
  },
  {
    "uniprot": "Q8IIP9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIP9"
  },
  {
    "uniprot": "C6S3E3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3E3"
  },
  {
    "uniprot": "O97233",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97233"
  },
  {
    "uniprot": "Q8IBJ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBJ3"
  },
  {
    "uniprot": "A0A143ZUM0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZUM0"
  },
  {
    "uniprot": "Q8IK58",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK58"
  },
  {
    "uniprot": "Q8I5D3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5D3"
  },
  {
    "uniprot": "Q25818",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25818"
  },
  {
    "uniprot": "Q8I3X7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3X7"
  },
  {
    "uniprot": "C0H572",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H572"
  },
  {
    "uniprot": "Q8I2J0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2J0"
  },
  {
    "uniprot": "Q8I423",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I423"
  },
  {
    "uniprot": "C6S3H9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3H9"
  },
  {
    "uniprot": "A0A144A2U1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2U1"
  },
  {
    "uniprot": "O97331",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97331"
  },
  {
    "uniprot": "Q8IAM8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAM8"
  },
  {
    "uniprot": "Q8IBL0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBL0"
  },
  {
    "uniprot": "C0H574",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H574"
  },
  {
    "uniprot": "Q8IJV9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJV9"
  },
  {
    "uniprot": "O96270",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96270"
  },
  {
    "uniprot": "A0A143ZVA5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVA5"
  },
  {
    "uniprot": "Q9NLB5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NLB5"
  },
  {
    "uniprot": "Q8IB97",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB97"
  },
  {
    "uniprot": "Q8IKJ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKJ5"
  },
  {
    "uniprot": "Q8IEK9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEK9"
  },
  {
    "uniprot": "Q8IKM4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKM4"
  },
  {
    "uniprot": "Q8I2Q2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Q2"
  },
  {
    "uniprot": "Q8IEQ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEQ8"
  },
  {
    "uniprot": "Q8IFN2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFN2"
  },
  {
    "uniprot": "Q8I5X2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5X2"
  },
  {
    "uniprot": "Q8ILY9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILY9"
  },
  {
    "uniprot": "A0A5K1K8A9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8A9"
  },
  {
    "uniprot": "Q8I510",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I510"
  },
  {
    "uniprot": "Q8I550",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I550"
  },
  {
    "uniprot": "A0A143ZYQ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYQ2"
  },
  {
    "uniprot": "Q8IE69",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE69"
  },
  {
    "uniprot": "Q8IHT2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHT2"
  },
  {
    "uniprot": "C0H5N3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5N3"
  },
  {
    "uniprot": "Q8IIV5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIV5"
  },
  {
    "uniprot": "Q8IK37",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK37"
  },
  {
    "uniprot": "O77323",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77323"
  },
  {
    "uniprot": "Q8IDG4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDG4"
  },
  {
    "uniprot": "O96203",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96203"
  },
  {
    "uniprot": "Q8IJN5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJN5"
  },
  {
    "uniprot": "Q8I5K1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5K1"
  },
  {
    "uniprot": "Q8IEV0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEV0"
  },
  {
    "uniprot": "C6KSL0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSL0"
  },
  {
    "uniprot": "Q8IHZ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHZ0"
  },
  {
    "uniprot": "A0A143ZY25",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZY25"
  },
  {
    "uniprot": "Q8IK60",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK60"
  },
  {
    "uniprot": "Q8I2J3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2J3"
  },
  {
    "uniprot": "A0A140KXV8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A140KXV8"
  },
  {
    "uniprot": "Q8I591",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I591"
  },
  {
    "uniprot": "Q8I2U7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2U7"
  },
  {
    "uniprot": "Q8ILU8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILU8"
  },
  {
    "uniprot": "Q8IHN2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHN2"
  },
  {
    "uniprot": "O96110",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96110"
  },
  {
    "uniprot": "A0A143ZY06",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZY06"
  },
  {
    "uniprot": "Q8IJ56",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ56"
  },
  {
    "uniprot": "A0A5K1K919",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K919"
  },
  {
    "uniprot": "Q8I4U1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4U1"
  },
  {
    "uniprot": "Q8I408",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I408"
  },
  {
    "uniprot": "Q8I5T2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5T2"
  },
  {
    "uniprot": "Q8I514",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I514"
  },
  {
    "uniprot": "Q8I4U9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4U9"
  },
  {
    "uniprot": "Q8IKE3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKE3"
  },
  {
    "uniprot": "Q8I5Y0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Y0"
  },
  {
    "uniprot": "O77368",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77368"
  },
  {
    "uniprot": "A0A144A4C1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A4C1"
  },
  {
    "uniprot": "A0A143ZWH4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWH4"
  },
  {
    "uniprot": "A0A143ZYA8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYA8"
  },
  {
    "uniprot": "A0A143ZYM6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYM6"
  },
  {
    "uniprot": "Q8IL57",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL57"
  },
  {
    "uniprot": "Q8ID84",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID84"
  },
  {
    "uniprot": "Q8IM71",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM71"
  },
  {
    "uniprot": "Q8I654",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I654"
  },
  {
    "uniprot": "Q8ILN3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILN3"
  },
  {
    "uniprot": "Q8IIM6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIM6"
  },
  {
    "uniprot": "Q8IKZ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKZ0"
  },
  {
    "uniprot": "C6KSN0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSN0"
  },
  {
    "uniprot": "Q8I5W1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5W1"
  },
  {
    "uniprot": "Q8IE40",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE40"
  },
  {
    "uniprot": "C0H4X2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4X2"
  },
  {
    "uniprot": "C6KSS9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSS9"
  },
  {
    "uniprot": "Q8I5E6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5E6"
  },
  {
    "uniprot": "O96120",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96120"
  },
  {
    "uniprot": "C0H4V5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4V5"
  },
  {
    "uniprot": "Q8IK91",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK91"
  },
  {
    "uniprot": "A0A143ZWE7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWE7"
  },
  {
    "uniprot": "Q8IM60",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM60"
  },
  {
    "uniprot": "Q8II30",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II30"
  },
  {
    "uniprot": "C6KT29",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT29"
  },
  {
    "uniprot": "A0A143ZVI0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZVI0"
  },
  {
    "uniprot": "Q8IK07",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK07"
  },
  {
    "uniprot": "Q8IL91",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL91"
  },
  {
    "uniprot": "Q8IM47",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM47"
  },
  {
    "uniprot": "A0A144A2C9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A2C9"
  },
  {
    "uniprot": "Q8I468",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I468"
  },
  {
    "uniprot": "Q8IHW4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHW4"
  },
  {
    "uniprot": "Q8IM33",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM33"
  },
  {
    "uniprot": "Q8ID85",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID85"
  },
  {
    "uniprot": "Q8IAK9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAK9"
  },
  {
    "uniprot": "Q8I3U2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3U2"
  },
  {
    "uniprot": "Q8I277",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I277"
  },
  {
    "uniprot": "O96108",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96108"
  },
  {
    "uniprot": "Q8IKK5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKK5"
  },
  {
    "uniprot": "Q8I400",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I400"
  },
  {
    "uniprot": "O96173",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96173"
  },
  {
    "uniprot": "C0H545",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H545"
  },
  {
    "uniprot": "Q8I4R0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4R0"
  },
  {
    "uniprot": "Q8II42",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II42"
  },
  {
    "uniprot": "Q8I3H4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3H4"
  },
  {
    "uniprot": "Q8IM82",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM82"
  },
  {
    "uniprot": "O96289",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96289"
  },
  {
    "uniprot": "Q8I4Z6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4Z6"
  },
  {
    "uniprot": "Q8III5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8III5"
  },
  {
    "uniprot": "Q8I2M0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2M0"
  },
  {
    "uniprot": "Q8IKZ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKZ8"
  },
  {
    "uniprot": "Q8ILP8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILP8"
  },
  {
    "uniprot": "Q8IDK7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDK7"
  },
  {
    "uniprot": "C0H4B7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4B7"
  },
  {
    "uniprot": "Q8IM65",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM65"
  },
  {
    "uniprot": "Q8IIC8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIC8"
  },
  {
    "uniprot": "C0H565",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H565"
  },
  {
    "uniprot": "Q8I265",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I265"
  },
  {
    "uniprot": "A0A5K1K8N6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8N6"
  },
  {
    "uniprot": "C0H4D1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4D1"
  },
  {
    "uniprot": "Q8IKP6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKP6"
  },
  {
    "uniprot": "C0H4B9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4B9"
  },
  {
    "uniprot": "Q8I5A8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5A8"
  },
  {
    "uniprot": "Q8IEC4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEC4"
  },
  {
    "uniprot": "Q8I3M8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3M8"
  },
  {
    "uniprot": "Q8ILB3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILB3"
  },
  {
    "uniprot": "Q8IJQ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJQ3"
  },
  {
    "uniprot": "Q8I2K8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2K8"
  },
  {
    "uniprot": "O97241",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97241"
  },
  {
    "uniprot": "Q8IAT4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAT4"
  },
  {
    "uniprot": "Q8IM66",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM66"
  },
  {
    "uniprot": "Q8IBV2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBV2"
  },
  {
    "uniprot": "Q8IL25",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL25"
  },
  {
    "uniprot": "Q8I2Z4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Z4"
  },
  {
    "uniprot": "O97294",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97294"
  },
  {
    "uniprot": "Q8IIV1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIV1"
  },
  {
    "uniprot": "Q8ILR6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILR6"
  },
  {
    "uniprot": "Q8IEM3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEM3"
  },
  {
    "uniprot": "Q8IJ55",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ55"
  },
  {
    "uniprot": "Q8II66",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II66"
  },
  {
    "uniprot": "Q8IE51",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE51"
  },
  {
    "uniprot": "Q8IDA3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDA3"
  },
  {
    "uniprot": "Q8I2B6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2B6"
  },
  {
    "uniprot": "Q8I1Z9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Z9"
  },
  {
    "uniprot": "C0H5K6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5K6"
  },
  {
    "uniprot": "C0H582",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H582"
  },
  {
    "uniprot": "Q8IAM7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAM7"
  },
  {
    "uniprot": "Q8I5V0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5V0"
  },
  {
    "uniprot": "Q8I450",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I450"
  },
  {
    "uniprot": "O97302",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97302"
  },
  {
    "uniprot": "C0H4Q9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Q9"
  },
  {
    "uniprot": "Q8IDJ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDJ8"
  },
  {
    "uniprot": "Q8ILF0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILF0"
  },
  {
    "uniprot": "Q8IJ79",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ79"
  },
  {
    "uniprot": "A0A5K1K999",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K999"
  },
  {
    "uniprot": "Q8IBT9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBT9"
  },
  {
    "uniprot": "Q8IK32",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK32"
  },
  {
    "uniprot": "C0H492",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H492"
  },
  {
    "uniprot": "Q8I369",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I369"
  },
  {
    "uniprot": "A0A5K1K9G2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K9G2"
  },
  {
    "uniprot": "Q8IJU4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJU4"
  },
  {
    "uniprot": "Q8IB67",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB67"
  },
  {
    "uniprot": "Q8I1R7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1R7"
  },
  {
    "uniprot": "Q8IDB7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDB7"
  },
  {
    "uniprot": "A0A144A5J7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A5J7"
  },
  {
    "uniprot": "C6KSQ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSQ3"
  },
  {
    "uniprot": "Q8IJM3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJM3"
  },
  {
    "uniprot": "Q8IJS5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJS5"
  },
  {
    "uniprot": "Q8I3M7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3M7"
  },
  {
    "uniprot": "C0H5J5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5J5"
  },
  {
    "uniprot": "Q8IBZ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBZ1"
  },
  {
    "uniprot": "Q8ILN1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILN1"
  },
  {
    "uniprot": "Q8IAV2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAV2"
  },
  {
    "uniprot": "C0H468",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H468"
  },
  {
    "uniprot": "A0A143ZZ61",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZ61"
  },
  {
    "uniprot": "Q8ILA9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILA9"
  },
  {
    "uniprot": "Q8IAN7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAN7"
  },
  {
    "uniprot": "Q8I2G8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2G8"
  },
  {
    "uniprot": "A0A144A0A3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0A3"
  },
  {
    "uniprot": "Q8IBB4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBB4"
  },
  {
    "uniprot": "Q8IIN9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIN9"
  },
  {
    "uniprot": "A0A5K1K910",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K910"
  },
  {
    "uniprot": "A0A5K1K870",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K870"
  },
  {
    "uniprot": "Q8ILQ8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILQ8"
  },
  {
    "uniprot": "A0A143ZZY8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZY8"
  },
  {
    "uniprot": "Q8IHR9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHR9"
  },
  {
    "uniprot": "Q8ILJ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILJ9"
  },
  {
    "uniprot": "C0H4V0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4V0"
  },
  {
    "uniprot": "Q8I5G9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5G9"
  },
  {
    "uniprot": "C6S3D7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3D7"
  },
  {
    "uniprot": "O97279",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97279"
  },
  {
    "uniprot": "Q8I251",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I251"
  },
  {
    "uniprot": "Q8I2A0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2A0"
  },
  {
    "uniprot": "Q8ILE9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILE9"
  },
  {
    "uniprot": "Q8IEN6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEN6"
  },
  {
    "uniprot": "Q8IKM3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKM3"
  },
  {
    "uniprot": "Q8IEH9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEH9"
  },
  {
    "uniprot": "Q8IL79",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL79"
  },
  {
    "uniprot": "Q8I2I8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2I8"
  },
  {
    "uniprot": "Q8IIK9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIK9"
  },
  {
    "uniprot": "A0A5K1K8V2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8V2"
  },
  {
    "uniprot": "Q8IK49",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK49"
  },
  {
    "uniprot": "Q8IE71",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IE71"
  },
  {
    "uniprot": "A0A144A0M5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0M5"
  },
  {
    "uniprot": "C0H5J6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5J6"
  },
  {
    "uniprot": "Q8I3C7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3C7"
  },
  {
    "uniprot": "O77344",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O77344"
  },
  {
    "uniprot": "A0A5K1K8E0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8E0"
  },
  {
    "uniprot": "A0A5K1K8J8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8J8"
  },
  {
    "uniprot": "Q8I5F4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5F4"
  },
  {
    "uniprot": "Q8I6Z1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6Z1"
  },
  {
    "uniprot": "Q8IHY2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHY2"
  },
  {
    "uniprot": "Q8IDJ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDJ7"
  },
  {
    "uniprot": "Q8I5A9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5A9"
  },
  {
    "uniprot": "Q8I345",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I345"
  },
  {
    "uniprot": "Q8I0X2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0X2"
  },
  {
    "uniprot": "A0A146LZZ4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A146LZZ4"
  },
  {
    "uniprot": "Q8IKI9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKI9"
  },
  {
    "uniprot": "Q9U0L2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0L2"
  },
  {
    "uniprot": "Q8I6T0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6T0"
  },
  {
    "uniprot": "Q8IEN4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEN4"
  },
  {
    "uniprot": "Q8I2E8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2E8"
  },
  {
    "uniprot": "Q8II69",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II69"
  },
  {
    "uniprot": "Q25816",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25816"
  },
  {
    "uniprot": "Q8I3R1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3R1"
  },
  {
    "uniprot": "Q8IL83",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL83"
  },
  {
    "uniprot": "Q8IC18",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC18"
  },
  {
    "uniprot": "Q8IAS4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAS4"
  },
  {
    "uniprot": "Q8IL38",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL38"
  },
  {
    "uniprot": "Q8IFN0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IFN0"
  },
  {
    "uniprot": "Q8IKX5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKX5"
  },
  {
    "uniprot": "Q8IBN5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBN5"
  },
  {
    "uniprot": "Q8IM22",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM22"
  },
  {
    "uniprot": "Q8IIG6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIG6"
  },
  {
    "uniprot": "Q8I1Q8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Q8"
  },
  {
    "uniprot": "A0A143ZWV2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWV2"
  },
  {
    "uniprot": "Q8I2A6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2A6"
  },
  {
    "uniprot": "Q8I3R4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3R4"
  },
  {
    "uniprot": "Q9U0H4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0H4"
  },
  {
    "uniprot": "Q8IBF9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBF9"
  },
  {
    "uniprot": "Q8IEH7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEH7"
  },
  {
    "uniprot": "A0A5K1K8T6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8T6"
  },
  {
    "uniprot": "Q8I282",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I282"
  },
  {
    "uniprot": "A0A143ZW02",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZW02"
  },
  {
    "uniprot": "Q8IL29",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL29"
  },
  {
    "uniprot": "Q8ILZ5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILZ5"
  },
  {
    "uniprot": "Q8IEF9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEF9"
  },
  {
    "uniprot": "A0A5K1K991",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K991"
  },
  {
    "uniprot": "Q8I0V9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0V9"
  },
  {
    "uniprot": "Q8I3H6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3H6"
  },
  {
    "uniprot": "O97330",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97330"
  },
  {
    "uniprot": "Q8IKG5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKG5"
  },
  {
    "uniprot": "C6S3C7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3C7"
  },
  {
    "uniprot": "Q8ILM1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILM1"
  },
  {
    "uniprot": "Q9NDU5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NDU5"
  },
  {
    "uniprot": "Q8IHS0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHS0"
  },
  {
    "uniprot": "O96165",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96165"
  },
  {
    "uniprot": "Q9U0J7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0J7"
  },
  {
    "uniprot": "O96238",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96238"
  },
  {
    "uniprot": "Q8I5G4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5G4"
  },
  {
    "uniprot": "Q8I2C7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2C7"
  },
  {
    "uniprot": "Q8IJ60",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ60"
  },
  {
    "uniprot": "Q8ILL9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILL9"
  },
  {
    "uniprot": "Q8I5F7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5F7"
  },
  {
    "uniprot": "Q8I5V6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5V6"
  },
  {
    "uniprot": "C6S3J5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3J5"
  },
  {
    "uniprot": "A0A143ZZC6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZC6"
  },
  {
    "uniprot": "Q8I252",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I252"
  },
  {
    "uniprot": "Q9U0K6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9U0K6"
  },
  {
    "uniprot": "Q8IBA8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBA8"
  },
  {
    "uniprot": "Q8IIB3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIB3"
  },
  {
    "uniprot": "A0A5K1K8R9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8R9"
  },
  {
    "uniprot": "Q8I3Y4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Y4"
  },
  {
    "uniprot": "Q8I5I3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5I3"
  },
  {
    "uniprot": "Q8IJ34",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ34"
  },
  {
    "uniprot": "Q8I2Z3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Z3"
  },
  {
    "uniprot": "Q7K6A9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7K6A9"
  },
  {
    "uniprot": "C6S3J9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3J9"
  },
  {
    "uniprot": "Q8I2H4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2H4"
  },
  {
    "uniprot": "Q8IK02",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK02"
  },
  {
    "uniprot": "Q8ILW5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILW5"
  },
  {
    "uniprot": "A0A143ZZZ3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZZZ3"
  },
  {
    "uniprot": "Q8IIK7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIK7"
  },
  {
    "uniprot": "C0H5N6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5N6"
  },
  {
    "uniprot": "Q8IBH7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBH7"
  },
  {
    "uniprot": "Q8ILK9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILK9"
  },
  {
    "uniprot": "A0A144A0U0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A0U0"
  },
  {
    "uniprot": "Q8IIR5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIR5"
  },
  {
    "uniprot": "Q8IB43",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB43"
  },
  {
    "uniprot": "Q8IJY6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJY6"
  },
  {
    "uniprot": "Q8IJV6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJV6"
  },
  {
    "uniprot": "Q8IKI7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKI7"
  },
  {
    "uniprot": "Q8IIL4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIL4"
  },
  {
    "uniprot": "Q8IJP7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJP7"
  },
  {
    "uniprot": "Q8IJV0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJV0"
  },
  {
    "uniprot": "Q8I1X0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1X0"
  },
  {
    "uniprot": "C0H598",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H598"
  },
  {
    "uniprot": "A0A144A7A0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A7A0"
  },
  {
    "uniprot": "Q8I5H3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5H3"
  },
  {
    "uniprot": "Q8I2N6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2N6"
  },
  {
    "uniprot": "Q8IKQ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKQ7"
  },
  {
    "uniprot": "Q8I1V1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1V1"
  },
  {
    "uniprot": "C0H4K8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4K8"
  },
  {
    "uniprot": "Q8I260",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I260"
  },
  {
    "uniprot": "Q8IC13",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC13"
  },
  {
    "uniprot": "Q8I5A3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5A3"
  },
  {
    "uniprot": "Q8IBS1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBS1"
  },
  {
    "uniprot": "Q8IKE4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKE4"
  },
  {
    "uniprot": "Q8II60",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II60"
  },
  {
    "uniprot": "Q8I5L5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5L5"
  },
  {
    "uniprot": "Q8I1R5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1R5"
  },
  {
    "uniprot": "Q8IJ89",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ89"
  },
  {
    "uniprot": "Q8I2Z8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Z8"
  },
  {
    "uniprot": "Q8IBU3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBU3"
  },
  {
    "uniprot": "C6KT83",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT83"
  },
  {
    "uniprot": "Q8IKY7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKY7"
  },
  {
    "uniprot": "C6KSM1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSM1"
  },
  {
    "uniprot": "P61076",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/P61076"
  },
  {
    "uniprot": "C0H4A2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4A2"
  },
  {
    "uniprot": "A0A144A1B7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1B7"
  },
  {
    "uniprot": "Q8IJ05",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ05"
  },
  {
    "uniprot": "O97282",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97282"
  },
  {
    "uniprot": "Q8IC06",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IC06"
  },
  {
    "uniprot": "Q25819",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q25819"
  },
  {
    "uniprot": "Q8I5P4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5P4"
  },
  {
    "uniprot": "Q8IJ74",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ74"
  },
  {
    "uniprot": "Q8I1T0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1T0"
  },
  {
    "uniprot": "Q8I357",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I357"
  },
  {
    "uniprot": "Q8IKI4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKI4"
  },
  {
    "uniprot": "Q8I2M5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2M5"
  },
  {
    "uniprot": "Q8I5N3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5N3"
  },
  {
    "uniprot": "C0H4G4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4G4"
  },
  {
    "uniprot": "Q8IJM9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJM9"
  },
  {
    "uniprot": "C6KSP9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSP9"
  },
  {
    "uniprot": "Q8ID42",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ID42"
  },
  {
    "uniprot": "Q8IKN7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKN7"
  },
  {
    "uniprot": "Q8I719",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I719"
  },
  {
    "uniprot": "Q8I0W4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I0W4"
  },
  {
    "uniprot": "Q8I5A2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5A2"
  },
  {
    "uniprot": "Q8I2X9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2X9"
  },
  {
    "uniprot": "Q8IJ92",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJ92"
  },
  {
    "uniprot": "C6KT61",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT61"
  },
  {
    "uniprot": "Q8I655",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I655"
  },
  {
    "uniprot": "Q8I367",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I367"
  },
  {
    "uniprot": "Q8I3K4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3K4"
  },
  {
    "uniprot": "Q8IL00",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL00"
  },
  {
    "uniprot": "A0A5K1K972",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K972"
  },
  {
    "uniprot": "A0A143ZWS3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZWS3"
  },
  {
    "uniprot": "O97230",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97230"
  },
  {
    "uniprot": "Q8IDD2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDD2"
  },
  {
    "uniprot": "Q8I2S7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2S7"
  },
  {
    "uniprot": "O97237",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97237"
  },
  {
    "uniprot": "Q8I432",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I432"
  },
  {
    "uniprot": "Q8ILG7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILG7"
  },
  {
    "uniprot": "Q8IJS8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJS8"
  },
  {
    "uniprot": "C6KST8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KST8"
  },
  {
    "uniprot": "Q8I1S4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1S4"
  },
  {
    "uniprot": "Q8IKE9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKE9"
  },
  {
    "uniprot": "Q8IBP4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBP4"
  },
  {
    "uniprot": "Q8I5H7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5H7"
  },
  {
    "uniprot": "C6KSS8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSS8"
  },
  {
    "uniprot": "Q8IKR5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKR5"
  },
  {
    "uniprot": "O97301",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O97301"
  },
  {
    "uniprot": "A0A144A4V4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A4V4"
  },
  {
    "uniprot": "Q8IL72",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL72"
  },
  {
    "uniprot": "A0A144A1C7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A144A1C7"
  },
  {
    "uniprot": "Q8IM84",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM84"
  },
  {
    "uniprot": "Q8IB48",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB48"
  },
  {
    "uniprot": "C6S3J7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3J7"
  },
  {
    "uniprot": "Q8IEP3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEP3"
  },
  {
    "uniprot": "Q8IAW1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAW1"
  },
  {
    "uniprot": "Q8I3Q8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3Q8"
  },
  {
    "uniprot": "Q8ILQ1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILQ1"
  },
  {
    "uniprot": "Q8IBM8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBM8"
  },
  {
    "uniprot": "C0H483",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H483"
  },
  {
    "uniprot": "Q8I5Y7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I5Y7"
  },
  {
    "uniprot": "Q8IL16",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL16"
  },
  {
    "uniprot": "Q8I233",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I233"
  },
  {
    "uniprot": "Q8IKN6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKN6"
  },
  {
    "uniprot": "C6KTA2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTA2"
  },
  {
    "uniprot": "Q8IJZ0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJZ0"
  },
  {
    "uniprot": "Q8IL11",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL11"
  },
  {
    "uniprot": "C0H5L4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5L4"
  },
  {
    "uniprot": "Q8IJU5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJU5"
  },
  {
    "uniprot": "O96158",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96158"
  },
  {
    "uniprot": "C0H578",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H578"
  },
  {
    "uniprot": "C6KTC6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTC6"
  },
  {
    "uniprot": "Q8IHY6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHY6"
  },
  {
    "uniprot": "Q9NJU9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q9NJU9"
  },
  {
    "uniprot": "Q8IJC4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJC4"
  },
  {
    "uniprot": "Q8I2F3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2F3"
  },
  {
    "uniprot": "B9ZSJ2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSJ2"
  },
  {
    "uniprot": "Q8IJK5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJK5"
  },
  {
    "uniprot": "O96145",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96145"
  },
  {
    "uniprot": "C6KT99",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT99"
  },
  {
    "uniprot": "C0H4N9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4N9"
  },
  {
    "uniprot": "Q8I4W3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4W3"
  },
  {
    "uniprot": "Q8IK01",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IK01"
  },
  {
    "uniprot": "C0H4U5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4U5"
  },
  {
    "uniprot": "Q8IBD6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBD6"
  },
  {
    "uniprot": "Q8I6U4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I6U4"
  },
  {
    "uniprot": "Q7HP04",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7HP04"
  },
  {
    "uniprot": "Q8I1Y4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1Y4"
  },
  {
    "uniprot": "Q8IAP7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAP7"
  },
  {
    "uniprot": "C0H5B7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5B7"
  },
  {
    "uniprot": "C0H566",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H566"
  },
  {
    "uniprot": "Q8IDR4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDR4"
  },
  {
    "uniprot": "Q8IL82",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL82"
  },
  {
    "uniprot": "Q8I618",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I618"
  },
  {
    "uniprot": "A0A143ZYX3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZYX3"
  },
  {
    "uniprot": "Q8IM06",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IM06"
  },
  {
    "uniprot": "Q7KQJ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q7KQJ9"
  },
  {
    "uniprot": "Q8II33",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8II33"
  },
  {
    "uniprot": "C6S3B2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3B2"
  },
  {
    "uniprot": "Q8ILL7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILL7"
  },
  {
    "uniprot": "Q8I470",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I470"
  },
  {
    "uniprot": "Q8IDW1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDW1"
  },
  {
    "uniprot": "A0A5K1K8A8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8A8"
  },
  {
    "uniprot": "Q8IHY8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHY8"
  },
  {
    "uniprot": "O96134",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96134"
  },
  {
    "uniprot": "Q8I2T7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2T7"
  },
  {
    "uniprot": "C6KT81",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT81"
  },
  {
    "uniprot": "Q8I2B1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2B1"
  },
  {
    "uniprot": "C6KT12",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT12"
  },
  {
    "uniprot": "Q8IKZ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKZ7"
  },
  {
    "uniprot": "Q8IBL7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IBL7"
  },
  {
    "uniprot": "Q8IHX0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IHX0"
  },
  {
    "uniprot": "Q8I483",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I483"
  },
  {
    "uniprot": "C0H489",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H489"
  },
  {
    "uniprot": "Q8I2C1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2C1"
  },
  {
    "uniprot": "Q8IL56",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL56"
  },
  {
    "uniprot": "C6KTE0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KTE0"
  },
  {
    "uniprot": "Q8IAS8",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAS8"
  },
  {
    "uniprot": "A0A143ZW87",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A143ZW87"
  },
  {
    "uniprot": "C6S3D5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6S3D5"
  },
  {
    "uniprot": "Q8IJH9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJH9"
  },
  {
    "uniprot": "Q8I301",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I301"
  },
  {
    "uniprot": "Q8I2X6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2X6"
  },
  {
    "uniprot": "Q8ILZ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILZ6"
  },
  {
    "uniprot": "Q8IAZ9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IAZ9"
  },
  {
    "uniprot": "C0H544",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H544"
  },
  {
    "uniprot": "Q8I2X7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2X7"
  },
  {
    "uniprot": "C0H5F3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5F3"
  },
  {
    "uniprot": "B9ZSI9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/B9ZSI9"
  },
  {
    "uniprot": "Q8IJA2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJA2"
  },
  {
    "uniprot": "Q8IKL2",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IKL2"
  },
  {
    "uniprot": "Q8IDR3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IDR3"
  },
  {
    "uniprot": "A0A5K1K8K5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/A0A5K1K8K5"
  },
  {
    "uniprot": "Q8IL93",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL93"
  },
  {
    "uniprot": "Q8I313",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I313"
  },
  {
    "uniprot": "Q8IIJ7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIJ7"
  },
  {
    "uniprot": "Q8IJJ6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJJ6"
  },
  {
    "uniprot": "Q8ILK4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILK4"
  },
  {
    "uniprot": "Q8I2J1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2J1"
  },
  {
    "uniprot": "Q8IL51",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IL51"
  },
  {
    "uniprot": "C0H4W4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4W4"
  },
  {
    "uniprot": "O96118",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/O96118"
  },
  {
    "uniprot": "C0H5D5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H5D5"
  },
  {
    "uniprot": "Q8ILH3",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8ILH3"
  },
  {
    "uniprot": "Q8IB93",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB93"
  },
  {
    "uniprot": "Q8IEF6",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEF6"
  },
  {
    "uniprot": "C0H4N7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4N7"
  },
  {
    "uniprot": "C0H4Q5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H4Q5"
  },
  {
    "uniprot": "Q8IB87",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IB87"
  },
  {
    "uniprot": "Q8I379",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I379"
  },
  {
    "uniprot": "Q8I2Z5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I2Z5"
  },
  {
    "uniprot": "C6KSU7",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KSU7"
  },
  {
    "uniprot": "Q8I601",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I601"
  },
  {
    "uniprot": "Q8IJE0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IJE0"
  },
  {
    "uniprot": "C0H558",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C0H558"
  },
  {
    "uniprot": "Q8I1U9",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I1U9"
  },
  {
    "uniprot": "C6KT50",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/C6KT50"
  },
  {
    "uniprot": "Q8I4V0",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I4V0"
  },
  {
    "uniprot": "Q8I323",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I323"
  },
  {
    "uniprot": "Q8IEE1",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEE1"
  },
  {
    "uniprot": "Q8IEG4",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IEG4"
  },
  {
    "uniprot": "Q8I3T5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8I3T5"
  },
  {
    "uniprot": "Q8IIY5",
    "species": "Plasmodium falciparum",
    "disease": "Malaria",
    "results": "/results/Q8IIY5"
  },
  {
    "uniprot": "A0A0N4UHY3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHY3"
  },
  {
    "uniprot": "A0A0N4UEI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEI0"
  },
  {
    "uniprot": "A0A0N4U5Y5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Y5"
  },
  {
    "uniprot": "A0A0N4U721",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U721"
  },
  {
    "uniprot": "A0A0N4UIV5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIV5"
  },
  {
    "uniprot": "A0A3P7QD58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QD58"
  },
  {
    "uniprot": "A0A0N4U7C5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7C5"
  },
  {
    "uniprot": "A0A0N4UHW5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHW5"
  },
  {
    "uniprot": "A0A0N4U8J2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8J2"
  },
  {
    "uniprot": "A0A3P7T3P5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T3P5"
  },
  {
    "uniprot": "A0A0N4UNT9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNT9"
  },
  {
    "uniprot": "A0A3P7PQ57",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQ57"
  },
  {
    "uniprot": "A0A0N4UAC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAC1"
  },
  {
    "uniprot": "A0A0N4UEE7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEE7"
  },
  {
    "uniprot": "A0A0N4U191",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U191"
  },
  {
    "uniprot": "A0A158Q516",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q516"
  },
  {
    "uniprot": "A0A0N4U8M4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8M4"
  },
  {
    "uniprot": "A0A0N4U4N7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4N7"
  },
  {
    "uniprot": "A0A0N4UMU5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMU5"
  },
  {
    "uniprot": "A0A0N4UKS0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKS0"
  },
  {
    "uniprot": "A0A0N4U1S2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1S2"
  },
  {
    "uniprot": "A0A158Q3R5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3R5"
  },
  {
    "uniprot": "A0A0N4UIB8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIB8"
  },
  {
    "uniprot": "A0A0N4U6S5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6S5"
  },
  {
    "uniprot": "A0A0N4UI86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI86"
  },
  {
    "uniprot": "A0A0N4UJ81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ81"
  },
  {
    "uniprot": "A0A0N4UD55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD55"
  },
  {
    "uniprot": "A0A0N4UN00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN00"
  },
  {
    "uniprot": "A0A0N4US57",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US57"
  },
  {
    "uniprot": "A0A0N4UC51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC51"
  },
  {
    "uniprot": "A0A0N4UFT4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFT4"
  },
  {
    "uniprot": "A0A0N4UIR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIR1"
  },
  {
    "uniprot": "A0A3P7PFW2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PFW2"
  },
  {
    "uniprot": "A0A0N4ULE0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULE0"
  },
  {
    "uniprot": "A0A0N4U8P2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8P2"
  },
  {
    "uniprot": "A0A0N4U899",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U899"
  },
  {
    "uniprot": "A0A0N4UFX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFX5"
  },
  {
    "uniprot": "A0A0N4UL10",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL10"
  },
  {
    "uniprot": "A0A0N4UCU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCU6"
  },
  {
    "uniprot": "A0A0N4U3Y9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Y9"
  },
  {
    "uniprot": "A0A0N4U8W7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8W7"
  },
  {
    "uniprot": "A0A0N4U4M1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4M1"
  },
  {
    "uniprot": "A0A3P7PL03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PL03"
  },
  {
    "uniprot": "A0A0N4U4R4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4R4"
  },
  {
    "uniprot": "A0A0N4UFR5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFR5"
  },
  {
    "uniprot": "A0A0N4UHG7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHG7"
  },
  {
    "uniprot": "A0A0N4UL98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL98"
  },
  {
    "uniprot": "A0A158Q423",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q423"
  },
  {
    "uniprot": "A0A0N4U763",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U763"
  },
  {
    "uniprot": "A0A0N4U1E9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1E9"
  },
  {
    "uniprot": "A0A0N4UQL7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQL7"
  },
  {
    "uniprot": "A0A0N4UED3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UED3"
  },
  {
    "uniprot": "A0A0N4UNY5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNY5"
  },
  {
    "uniprot": "A0A0N4UGE9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGE9"
  },
  {
    "uniprot": "A0A158Q4I8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4I8"
  },
  {
    "uniprot": "A0A0N4US66",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US66"
  },
  {
    "uniprot": "A0A158Q4F9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4F9"
  },
  {
    "uniprot": "A0A0N4U4R6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4R6"
  },
  {
    "uniprot": "A0A0N4U0T6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0T6"
  },
  {
    "uniprot": "A0A158Q6D2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6D2"
  },
  {
    "uniprot": "A0A0N4UJR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJR1"
  },
  {
    "uniprot": "A0A0N4U326",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U326"
  },
  {
    "uniprot": "A0A0N4UCJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCJ2"
  },
  {
    "uniprot": "A0A0N4UCN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCN9"
  },
  {
    "uniprot": "A0A0N4U9X3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9X3"
  },
  {
    "uniprot": "A0A0N4U1K2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1K2"
  },
  {
    "uniprot": "A0A0N4UBP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBP3"
  },
  {
    "uniprot": "A0A0N4UE77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE77"
  },
  {
    "uniprot": "A0A0N4U4I3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4I3"
  },
  {
    "uniprot": "A0A3P7QDN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QDN6"
  },
  {
    "uniprot": "A0A0N4U758",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U758"
  },
  {
    "uniprot": "A0A0N4U822",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U822"
  },
  {
    "uniprot": "A0A0N4UCZ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCZ3"
  },
  {
    "uniprot": "A0A0N4U5V6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5V6"
  },
  {
    "uniprot": "A0A0N4ULI6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULI6"
  },
  {
    "uniprot": "A0A0N4UL01",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL01"
  },
  {
    "uniprot": "A0A0N4U6E8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6E8"
  },
  {
    "uniprot": "A0A0N4U3J0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3J0"
  },
  {
    "uniprot": "A0A158Q331",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q331"
  },
  {
    "uniprot": "A0A3P7Q7H7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q7H7"
  },
  {
    "uniprot": "A0A0N4UG56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG56"
  },
  {
    "uniprot": "A0A3P7Q1V9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q1V9"
  },
  {
    "uniprot": "A0A0N4UES4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UES4"
  },
  {
    "uniprot": "A0A0N4U2J6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2J6"
  },
  {
    "uniprot": "A0A0N4UQC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQC7"
  },
  {
    "uniprot": "A0A0N4U1V2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1V2"
  },
  {
    "uniprot": "A0A0N4UFW2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFW2"
  },
  {
    "uniprot": "A0A0N4U5P5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5P5"
  },
  {
    "uniprot": "A0A0N4U123",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U123"
  },
  {
    "uniprot": "A0A0N4U6Z1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Z1"
  },
  {
    "uniprot": "A0A0N4UGI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGI3"
  },
  {
    "uniprot": "A0A0N4UCI8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCI8"
  },
  {
    "uniprot": "A0A0N4URG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URG4"
  },
  {
    "uniprot": "A0A0N4UKA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKA1"
  },
  {
    "uniprot": "A0A0N4U8L2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8L2"
  },
  {
    "uniprot": "A0A0N4U617",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U617"
  },
  {
    "uniprot": "A0A0N4UA51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA51"
  },
  {
    "uniprot": "A0A158Q5X7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5X7"
  },
  {
    "uniprot": "A0A0N4UEF7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEF7"
  },
  {
    "uniprot": "A0A0N4UCZ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCZ8"
  },
  {
    "uniprot": "A0A0N4UJG5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJG5"
  },
  {
    "uniprot": "A0A158Q305",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q305"
  },
  {
    "uniprot": "A0A0N4UPP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPP3"
  },
  {
    "uniprot": "A0A158Q2X8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2X8"
  },
  {
    "uniprot": "A0A0N4ULX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULX7"
  },
  {
    "uniprot": "A0A0N4UHP8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHP8"
  },
  {
    "uniprot": "A0A0N4UJ44",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ44"
  },
  {
    "uniprot": "A0A0N4URS2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URS2"
  },
  {
    "uniprot": "A0A0N4U7F6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7F6"
  },
  {
    "uniprot": "A0A0N4U5M5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5M5"
  },
  {
    "uniprot": "A0A0N4UIE5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIE5"
  },
  {
    "uniprot": "A0A158Q6M9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6M9"
  },
  {
    "uniprot": "A0A0N4UH90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH90"
  },
  {
    "uniprot": "A0A158Q6M8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6M8"
  },
  {
    "uniprot": "A0A0N4UCR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCR9"
  },
  {
    "uniprot": "A0A0N4UFS2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFS2"
  },
  {
    "uniprot": "A0A0N4U4V0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4V0"
  },
  {
    "uniprot": "A0A0N4U551",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U551"
  },
  {
    "uniprot": "A0A158Q3M4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3M4"
  },
  {
    "uniprot": "A0A0N4U0F6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0F6"
  },
  {
    "uniprot": "A0A158Q2V5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2V5"
  },
  {
    "uniprot": "A0A0N4UCB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCB1"
  },
  {
    "uniprot": "A0A0N4UL46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL46"
  },
  {
    "uniprot": "A0A158Q4E3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4E3"
  },
  {
    "uniprot": "A0A0N4U9T0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9T0"
  },
  {
    "uniprot": "A0A0N4ULB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULB3"
  },
  {
    "uniprot": "A0A3P7Q1J9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q1J9"
  },
  {
    "uniprot": "A0A0N4UNY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNY4"
  },
  {
    "uniprot": "A0A0N4U571",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U571"
  },
  {
    "uniprot": "A0A158Q4S7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4S7"
  },
  {
    "uniprot": "A0A3P7SVP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SVP1"
  },
  {
    "uniprot": "A0A0N4UMH0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMH0"
  },
  {
    "uniprot": "A0A0N4UCW7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCW7"
  },
  {
    "uniprot": "A0A3P7SYT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SYT6"
  },
  {
    "uniprot": "A0A0N4UFJ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFJ6"
  },
  {
    "uniprot": "A0A0N4UEQ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEQ2"
  },
  {
    "uniprot": "A0A0N4UQT2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQT2"
  },
  {
    "uniprot": "A0A0N4U645",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U645"
  },
  {
    "uniprot": "A0A0N4UD74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD74"
  },
  {
    "uniprot": "A0A0N4U3L2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3L2"
  },
  {
    "uniprot": "A0A0N4U0W0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0W0"
  },
  {
    "uniprot": "A0A3P7PMM5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PMM5"
  },
  {
    "uniprot": "A0A0N4UGB6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGB6"
  },
  {
    "uniprot": "A0A0N4U3J3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3J3"
  },
  {
    "uniprot": "A0A0N4UGV2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGV2"
  },
  {
    "uniprot": "A0A0N4UQL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQL3"
  },
  {
    "uniprot": "A0A0N4U0X0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0X0"
  },
  {
    "uniprot": "A0A0N4UP94",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP94"
  },
  {
    "uniprot": "A0A0N4UJU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJU6"
  },
  {
    "uniprot": "A0A0N4U6R4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6R4"
  },
  {
    "uniprot": "A0A0N4UMA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMA2"
  },
  {
    "uniprot": "A0A0N4U5C5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5C5"
  },
  {
    "uniprot": "A0A0N4UG23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG23"
  },
  {
    "uniprot": "A0A0N4UEP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEP3"
  },
  {
    "uniprot": "A0A158Q326",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q326"
  },
  {
    "uniprot": "A0A158Q4M5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4M5"
  },
  {
    "uniprot": "A0A0N4U5E0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5E0"
  },
  {
    "uniprot": "A0A0N4UA66",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA66"
  },
  {
    "uniprot": "A0A3P7Q5J5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q5J5"
  },
  {
    "uniprot": "A0A0N4U3X3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3X3"
  },
  {
    "uniprot": "A0A3P7PZ16",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PZ16"
  },
  {
    "uniprot": "A0A3P7T058",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T058"
  },
  {
    "uniprot": "A0A0N4UJ06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ06"
  },
  {
    "uniprot": "A0A0N4UHH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHH1"
  },
  {
    "uniprot": "A0A0N4UHH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHH3"
  },
  {
    "uniprot": "A0A0N4UFV4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFV4"
  },
  {
    "uniprot": "A0A3P7QG53",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QG53"
  },
  {
    "uniprot": "A0A0N4UBI8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBI8"
  },
  {
    "uniprot": "A0A0N4UQS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQS3"
  },
  {
    "uniprot": "A0A0N4U546",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U546"
  },
  {
    "uniprot": "A0A3P7PS66",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PS66"
  },
  {
    "uniprot": "A0A0N4U5P7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5P7"
  },
  {
    "uniprot": "A0A3P7PTU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PTU3"
  },
  {
    "uniprot": "A0A0N4UFH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFH4"
  },
  {
    "uniprot": "A0A0N4U5E7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5E7"
  },
  {
    "uniprot": "A0A0N4U6X4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6X4"
  },
  {
    "uniprot": "A0A158Q375",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q375"
  },
  {
    "uniprot": "A0A0N4UMJ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMJ6"
  },
  {
    "uniprot": "A0A0N4UDG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDG4"
  },
  {
    "uniprot": "A0A0N4UJ67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ67"
  },
  {
    "uniprot": "A0A158Q3W9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3W9"
  },
  {
    "uniprot": "A0A0N4UIZ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIZ6"
  },
  {
    "uniprot": "A0A0N4U6T8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6T8"
  },
  {
    "uniprot": "A0A158Q2Y5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Y5"
  },
  {
    "uniprot": "A0A0N4UBY5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBY5"
  },
  {
    "uniprot": "A0A0N4U0R0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0R0"
  },
  {
    "uniprot": "A0A158Q527",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q527"
  },
  {
    "uniprot": "A0A158Q3U0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3U0"
  },
  {
    "uniprot": "A0A3P7TA36",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TA36"
  },
  {
    "uniprot": "A0A3P7PZ26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PZ26"
  },
  {
    "uniprot": "A0A0N4UNC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNC9"
  },
  {
    "uniprot": "A0A0N4UEY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEY4"
  },
  {
    "uniprot": "A0A0N4UMX2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMX2"
  },
  {
    "uniprot": "A0A0N4UPG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPG3"
  },
  {
    "uniprot": "A0A0N4UR19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR19"
  },
  {
    "uniprot": "A0A0N4UGT8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGT8"
  },
  {
    "uniprot": "A0A3P7Q0X5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q0X5"
  },
  {
    "uniprot": "A0A0N4U4X3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4X3"
  },
  {
    "uniprot": "A0A0N4UB51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB51"
  },
  {
    "uniprot": "A0A0N4U974",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U974"
  },
  {
    "uniprot": "A0A0N4UL99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL99"
  },
  {
    "uniprot": "A0A0N4UPP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPP6"
  },
  {
    "uniprot": "A0A0N4U6J7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6J7"
  },
  {
    "uniprot": "A0A0N4U1L8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1L8"
  },
  {
    "uniprot": "A0A0N4U8R0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8R0"
  },
  {
    "uniprot": "A0A0N4U7U6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7U6"
  },
  {
    "uniprot": "A0A3P7PZR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PZR2"
  },
  {
    "uniprot": "A0A0N4UAW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAW0"
  },
  {
    "uniprot": "A0A0N4U2B8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2B8"
  },
  {
    "uniprot": "A0A0N4U362",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U362"
  },
  {
    "uniprot": "A0A0N4ULP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULP6"
  },
  {
    "uniprot": "A0A0N4ULM9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULM9"
  },
  {
    "uniprot": "A0A0N4U6C1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6C1"
  },
  {
    "uniprot": "A0A3P7T0B9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T0B9"
  },
  {
    "uniprot": "A0A0N4UP28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP28"
  },
  {
    "uniprot": "A0A0N4UNU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNU2"
  },
  {
    "uniprot": "A0A0N4U7T8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7T8"
  },
  {
    "uniprot": "A0A0N4UH49",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH49"
  },
  {
    "uniprot": "A0A0N4URL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URL8"
  },
  {
    "uniprot": "A0A158Q3W8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3W8"
  },
  {
    "uniprot": "A0A0N4UHW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHW8"
  },
  {
    "uniprot": "A0A0N4UDD9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDD9"
  },
  {
    "uniprot": "A0A0N4UCC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCC0"
  },
  {
    "uniprot": "A0A0N4U1J5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1J5"
  },
  {
    "uniprot": "A0A0N4U9L2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9L2"
  },
  {
    "uniprot": "A0A0N4UDI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDI9"
  },
  {
    "uniprot": "A0A0N4UI07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI07"
  },
  {
    "uniprot": "A0A0N4UDA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDA4"
  },
  {
    "uniprot": "A0A0N4UE33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE33"
  },
  {
    "uniprot": "A0A0N4ULB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULB1"
  },
  {
    "uniprot": "A0A0N4UMN8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMN8"
  },
  {
    "uniprot": "A0A158Q5G4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5G4"
  },
  {
    "uniprot": "A0A0N4ULB4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULB4"
  },
  {
    "uniprot": "A0A0N4URV5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URV5"
  },
  {
    "uniprot": "A0A0N4ULK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULK1"
  },
  {
    "uniprot": "A0A0N4UDG1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDG1"
  },
  {
    "uniprot": "A0A0N4U930",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U930"
  },
  {
    "uniprot": "A0A0N4UBK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBK5"
  },
  {
    "uniprot": "A0A0N4U4D0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4D0"
  },
  {
    "uniprot": "A0A158Q3F2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3F2"
  },
  {
    "uniprot": "A0A3P7P5R6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P5R6"
  },
  {
    "uniprot": "A0A0N4U2M9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2M9"
  },
  {
    "uniprot": "A0A158Q439",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q439"
  },
  {
    "uniprot": "A0A158Q540",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q540"
  },
  {
    "uniprot": "A0A0N4UGB4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGB4"
  },
  {
    "uniprot": "A0A3P7PJC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PJC6"
  },
  {
    "uniprot": "A0A0N4UMR3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMR3"
  },
  {
    "uniprot": "A0A0N4UL38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL38"
  },
  {
    "uniprot": "A0A0N4UKN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKN2"
  },
  {
    "uniprot": "A0A0N4U767",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U767"
  },
  {
    "uniprot": "A0A0N4UMX0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMX0"
  },
  {
    "uniprot": "A0A0N4U9R8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9R8"
  },
  {
    "uniprot": "A0A0N4UNQ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNQ9"
  },
  {
    "uniprot": "A0A3P7STB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7STB2"
  },
  {
    "uniprot": "A0A0N4UPL4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPL4"
  },
  {
    "uniprot": "A0A0N4U8J0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8J0"
  },
  {
    "uniprot": "A0A0N4UFF1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFF1"
  },
  {
    "uniprot": "A0A0N4UIW4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIW4"
  },
  {
    "uniprot": "A0A0N4UPD3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPD3"
  },
  {
    "uniprot": "A0A0N4U3E1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3E1"
  },
  {
    "uniprot": "A0A3P7PU95",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PU95"
  },
  {
    "uniprot": "A0A0N4UP31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP31"
  },
  {
    "uniprot": "A0A0N4U556",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U556"
  },
  {
    "uniprot": "A0A3P7Q354",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q354"
  },
  {
    "uniprot": "A0A0N4U7T3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7T3"
  },
  {
    "uniprot": "A0A0N4UEX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEX7"
  },
  {
    "uniprot": "A0A0N4UDP2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDP2"
  },
  {
    "uniprot": "A0A158Q552",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q552"
  },
  {
    "uniprot": "A0A0N4UFA7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFA7"
  },
  {
    "uniprot": "A0A0N4U733",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U733"
  },
  {
    "uniprot": "A0A0N4UG58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG58"
  },
  {
    "uniprot": "A0A158Q2R5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2R5"
  },
  {
    "uniprot": "A0A0N4UID0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UID0"
  },
  {
    "uniprot": "A0A0N4U7F5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7F5"
  },
  {
    "uniprot": "A0A0N4UL72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL72"
  },
  {
    "uniprot": "A0A0N4U6X1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6X1"
  },
  {
    "uniprot": "A0A0N4URW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URW3"
  },
  {
    "uniprot": "A0A0N4UN13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN13"
  },
  {
    "uniprot": "A0A3P7PD20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PD20"
  },
  {
    "uniprot": "A0A0N4UPC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPC5"
  },
  {
    "uniprot": "A0A0N4UIR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIR4"
  },
  {
    "uniprot": "A0A0N4UDX6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDX6"
  },
  {
    "uniprot": "A0A3P7P728",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P728"
  },
  {
    "uniprot": "A0A158Q616",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q616"
  },
  {
    "uniprot": "A0A158Q325",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q325"
  },
  {
    "uniprot": "A0A0N4UQK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQK0"
  },
  {
    "uniprot": "A0A0N4UJN7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJN7"
  },
  {
    "uniprot": "A0A158Q341",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q341"
  },
  {
    "uniprot": "A0A0N4U272",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U272"
  },
  {
    "uniprot": "A0A0N4U8C4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8C4"
  },
  {
    "uniprot": "A0A0N4U817",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U817"
  },
  {
    "uniprot": "A0A0N4UH37",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH37"
  },
  {
    "uniprot": "A0A0N4USC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USC3"
  },
  {
    "uniprot": "A0A0N4U135",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U135"
  },
  {
    "uniprot": "A0A0N4U3V8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3V8"
  },
  {
    "uniprot": "A0A0N4UHF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHF3"
  },
  {
    "uniprot": "A0A0N4UD09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD09"
  },
  {
    "uniprot": "A0A0N4U879",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U879"
  },
  {
    "uniprot": "A0A0N4U0R2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0R2"
  },
  {
    "uniprot": "A0A0N4UQP2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQP2"
  },
  {
    "uniprot": "A0A0N4U8T2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8T2"
  },
  {
    "uniprot": "A0A0N4UD97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD97"
  },
  {
    "uniprot": "A0A3P7PQN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQN0"
  },
  {
    "uniprot": "A0A3P7PU24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PU24"
  },
  {
    "uniprot": "A0A0N4UN63",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN63"
  },
  {
    "uniprot": "A0A0N4U6I1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6I1"
  },
  {
    "uniprot": "A0A0N4UK35",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK35"
  },
  {
    "uniprot": "A0A0N4U7J0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7J0"
  },
  {
    "uniprot": "A0A0N4U4L6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4L6"
  },
  {
    "uniprot": "A0A0N4UDR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDR9"
  },
  {
    "uniprot": "A0A0N4U168",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U168"
  },
  {
    "uniprot": "A0A3P7Q8N6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q8N6"
  },
  {
    "uniprot": "A0A158Q3D4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3D4"
  },
  {
    "uniprot": "A0A0N4UCJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCJ7"
  },
  {
    "uniprot": "A0A0N4U204",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U204"
  },
  {
    "uniprot": "A0A0N4UHM6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHM6"
  },
  {
    "uniprot": "A0A158Q2P6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2P6"
  },
  {
    "uniprot": "A0A3P7QDH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QDH2"
  },
  {
    "uniprot": "A0A0N4U609",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U609"
  },
  {
    "uniprot": "A0A0N4UCR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCR6"
  },
  {
    "uniprot": "A0A0N4UE19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE19"
  },
  {
    "uniprot": "A0A3P7QCB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QCB1"
  },
  {
    "uniprot": "A0A0N4U8T5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8T5"
  },
  {
    "uniprot": "A0A0N4U2L0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2L0"
  },
  {
    "uniprot": "A0A0N4U1L1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1L1"
  },
  {
    "uniprot": "A0A3P7P6Q8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P6Q8"
  },
  {
    "uniprot": "A0A158Q2U1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2U1"
  },
  {
    "uniprot": "A0A0N4UQD5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQD5"
  },
  {
    "uniprot": "A0A0N4UKQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKQ3"
  },
  {
    "uniprot": "A0A0N4UM39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM39"
  },
  {
    "uniprot": "A0A0N4UIR0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIR0"
  },
  {
    "uniprot": "A0A0N4UJ35",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ35"
  },
  {
    "uniprot": "A0A0N4U431",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U431"
  },
  {
    "uniprot": "A0A158Q561",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q561"
  },
  {
    "uniprot": "A0A0N4U3I5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3I5"
  },
  {
    "uniprot": "A0A0N4UH03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH03"
  },
  {
    "uniprot": "A0A0N4UJ79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ79"
  },
  {
    "uniprot": "A0A0N4UHM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHM0"
  },
  {
    "uniprot": "A0A0N4UA20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA20"
  },
  {
    "uniprot": "A0A158Q4X3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4X3"
  },
  {
    "uniprot": "A0A0N4U3Z2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Z2"
  },
  {
    "uniprot": "A0A3P7QW24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QW24"
  },
  {
    "uniprot": "A0A0N4UKC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKC7"
  },
  {
    "uniprot": "A0A0N4UDE4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDE4"
  },
  {
    "uniprot": "A0A0N4U3R8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3R8"
  },
  {
    "uniprot": "A0A0N4US02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US02"
  },
  {
    "uniprot": "A0A0N4U100",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U100"
  },
  {
    "uniprot": "A0A0N4U3I8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3I8"
  },
  {
    "uniprot": "A0A158Q533",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q533"
  },
  {
    "uniprot": "A0A158Q5E0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5E0"
  },
  {
    "uniprot": "A0A0N4UE75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE75"
  },
  {
    "uniprot": "A0A3P7PN17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PN17"
  },
  {
    "uniprot": "A0A158Q4Y3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Y3"
  },
  {
    "uniprot": "A0A0N4UJ08",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ08"
  },
  {
    "uniprot": "A0A158Q388",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q388"
  },
  {
    "uniprot": "A0A0N4UPZ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPZ8"
  },
  {
    "uniprot": "A0A0N4U2Z3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Z3"
  },
  {
    "uniprot": "A0A0N4U7F4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7F4"
  },
  {
    "uniprot": "A0A3P7Q0G3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q0G3"
  },
  {
    "uniprot": "A0A0N4U2V5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2V5"
  },
  {
    "uniprot": "A0A0N4U4B0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4B0"
  },
  {
    "uniprot": "A0A0N4UDS5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDS5"
  },
  {
    "uniprot": "A0A0N4UDT7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDT7"
  },
  {
    "uniprot": "A0A0N4UQH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQH3"
  },
  {
    "uniprot": "A0A0N4U2W5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2W5"
  },
  {
    "uniprot": "A0A0N4U757",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U757"
  },
  {
    "uniprot": "A0A0N4UEX6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEX6"
  },
  {
    "uniprot": "A0A3P7Q7D5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q7D5"
  },
  {
    "uniprot": "A0A0N4UQR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQR1"
  },
  {
    "uniprot": "A0A0N4U475",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U475"
  },
  {
    "uniprot": "A0A0N4U250",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U250"
  },
  {
    "uniprot": "A0A158Q590",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q590"
  },
  {
    "uniprot": "A0A0N4US19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US19"
  },
  {
    "uniprot": "A0A0N4U0Q2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Q2"
  },
  {
    "uniprot": "A0A0N4UJL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJL8"
  },
  {
    "uniprot": "A0A0N4UQP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQP0"
  },
  {
    "uniprot": "A0A0N4UPG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPG2"
  },
  {
    "uniprot": "A0A0N4U405",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U405"
  },
  {
    "uniprot": "A0A0N4U4Y7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Y7"
  },
  {
    "uniprot": "A0A0N4UKS4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKS4"
  },
  {
    "uniprot": "A0A0N4UK76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK76"
  },
  {
    "uniprot": "A0A0N4U9Q5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Q5"
  },
  {
    "uniprot": "A0A0N4U2I4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2I4"
  },
  {
    "uniprot": "A0A158Q574",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q574"
  },
  {
    "uniprot": "A0A0N4UIB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIB7"
  },
  {
    "uniprot": "A0A0N4UH30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH30"
  },
  {
    "uniprot": "A0A0N4UMS4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMS4"
  },
  {
    "uniprot": "A0A0N4U830",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U830"
  },
  {
    "uniprot": "A0A0N4UEA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEA5"
  },
  {
    "uniprot": "A0A0N4U6K5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6K5"
  },
  {
    "uniprot": "A0A0N4UJL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJL0"
  },
  {
    "uniprot": "A0A0N4UC53",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC53"
  },
  {
    "uniprot": "A0A158Q448",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q448"
  },
  {
    "uniprot": "A0A3P7SDR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SDR7"
  },
  {
    "uniprot": "A0A0N4UFI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFI9"
  },
  {
    "uniprot": "A0A0N4UEB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEB5"
  },
  {
    "uniprot": "A0A0N4UR17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR17"
  },
  {
    "uniprot": "A0A0N4UQA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQA1"
  },
  {
    "uniprot": "A0A0N4UJ93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ93"
  },
  {
    "uniprot": "A0A0N4U7A8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7A8"
  },
  {
    "uniprot": "A0A0N4UPI7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPI7"
  },
  {
    "uniprot": "A0A0N4U2C5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2C5"
  },
  {
    "uniprot": "A0A0N4U5G5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5G5"
  },
  {
    "uniprot": "A0A158Q625",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q625"
  },
  {
    "uniprot": "A0A0N4U3N5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3N5"
  },
  {
    "uniprot": "A0A0N4UE63",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE63"
  },
  {
    "uniprot": "A0A3P7TCA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TCA6"
  },
  {
    "uniprot": "A0A0N4U658",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U658"
  },
  {
    "uniprot": "A0A0N4U781",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U781"
  },
  {
    "uniprot": "A0A3P7SJH7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SJH7"
  },
  {
    "uniprot": "A0A158Q501",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q501"
  },
  {
    "uniprot": "A0A0N4UR93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR93"
  },
  {
    "uniprot": "A0A0N4UID6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UID6"
  },
  {
    "uniprot": "A0A0N4U687",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U687"
  },
  {
    "uniprot": "A0A0N4UJE5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJE5"
  },
  {
    "uniprot": "A0A0N4U675",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U675"
  },
  {
    "uniprot": "A0A0N4UJ39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ39"
  },
  {
    "uniprot": "A0A3P7SZX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SZX8"
  },
  {
    "uniprot": "A0A158Q303",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q303"
  },
  {
    "uniprot": "A0A0N4UJS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJS9"
  },
  {
    "uniprot": "A0A0N4UEL6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEL6"
  },
  {
    "uniprot": "A0A0N4UHE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHE3"
  },
  {
    "uniprot": "A0A0N4U5C4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5C4"
  },
  {
    "uniprot": "A0A0N4URP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URP4"
  },
  {
    "uniprot": "A0A0N4UAU8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAU8"
  },
  {
    "uniprot": "A0A0N4UK25",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK25"
  },
  {
    "uniprot": "A0A0N4UIL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIL3"
  },
  {
    "uniprot": "A0A0N4UJS1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJS1"
  },
  {
    "uniprot": "A0A3P7Q4I6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q4I6"
  },
  {
    "uniprot": "A0A0N4UHP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHP4"
  },
  {
    "uniprot": "A0A0N4U364",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U364"
  },
  {
    "uniprot": "A0A0N4U6Q6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Q6"
  },
  {
    "uniprot": "A0A0N4UC46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC46"
  },
  {
    "uniprot": "A0A0N4U455",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U455"
  },
  {
    "uniprot": "A0A0N4U618",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U618"
  },
  {
    "uniprot": "A0A0N4UPS4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPS4"
  },
  {
    "uniprot": "A0A0N4U9Y9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Y9"
  },
  {
    "uniprot": "A0A0N4U105",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U105"
  },
  {
    "uniprot": "A0A0N4U304",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U304"
  },
  {
    "uniprot": "A0A158Q4V2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4V2"
  },
  {
    "uniprot": "A0A0N4U6N7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6N7"
  },
  {
    "uniprot": "A0A158Q4Y7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Y7"
  },
  {
    "uniprot": "A0A0N4UCM3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCM3"
  },
  {
    "uniprot": "A0A158Q5I0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5I0"
  },
  {
    "uniprot": "A0A0N4UER1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UER1"
  },
  {
    "uniprot": "A0A0N4U646",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U646"
  },
  {
    "uniprot": "A0A0N4U9B1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9B1"
  },
  {
    "uniprot": "A0A3P7PTU0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PTU0"
  },
  {
    "uniprot": "A0A0N4U6Z8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Z8"
  },
  {
    "uniprot": "A0A0N4ULK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULK0"
  },
  {
    "uniprot": "A0A0N4U8V4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8V4"
  },
  {
    "uniprot": "A0A0N4U8T3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8T3"
  },
  {
    "uniprot": "A0A0N4UK74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK74"
  },
  {
    "uniprot": "A0A0N4UG02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG02"
  },
  {
    "uniprot": "A0A0N4UCQ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCQ2"
  },
  {
    "uniprot": "A0A0N4UIV0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIV0"
  },
  {
    "uniprot": "A0A0N4UDI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDI3"
  },
  {
    "uniprot": "A0A0N4UMC8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMC8"
  },
  {
    "uniprot": "A0A0N4ULC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULC4"
  },
  {
    "uniprot": "A0A0N4U7P6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7P6"
  },
  {
    "uniprot": "A0A0N4UFJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFJ1"
  },
  {
    "uniprot": "A0A0N4U119",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U119"
  },
  {
    "uniprot": "A0A0N4UGE0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGE0"
  },
  {
    "uniprot": "A0A158Q5L2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5L2"
  },
  {
    "uniprot": "A0A0N4UHK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHK3"
  },
  {
    "uniprot": "A0A0N4UCD5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCD5"
  },
  {
    "uniprot": "A0A0N4UCF0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCF0"
  },
  {
    "uniprot": "A0A0N4UQM7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQM7"
  },
  {
    "uniprot": "A0A0N4U959",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U959"
  },
  {
    "uniprot": "A0A0N4UCR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCR1"
  },
  {
    "uniprot": "A0A0N4U2Y8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Y8"
  },
  {
    "uniprot": "A0A0N4UQK4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQK4"
  },
  {
    "uniprot": "A0A0N4ULG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULG4"
  },
  {
    "uniprot": "A0A0N4UIK8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIK8"
  },
  {
    "uniprot": "A0A0N4UJB4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJB4"
  },
  {
    "uniprot": "A0A0N4UPF0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPF0"
  },
  {
    "uniprot": "A0A158Q515",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q515"
  },
  {
    "uniprot": "A0A158Q3S7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3S7"
  },
  {
    "uniprot": "A0A3P7Q3W3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q3W3"
  },
  {
    "uniprot": "A0A0N4U259",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U259"
  },
  {
    "uniprot": "A0A3P7Q2R5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q2R5"
  },
  {
    "uniprot": "A0A0N4U0N1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0N1"
  },
  {
    "uniprot": "A0A0N4UHN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHN2"
  },
  {
    "uniprot": "A0A0N4U6B3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6B3"
  },
  {
    "uniprot": "A0A158Q472",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q472"
  },
  {
    "uniprot": "A0A0N4U941",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U941"
  },
  {
    "uniprot": "A0A3P7PDU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PDU6"
  },
  {
    "uniprot": "A0A0N4U5I0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5I0"
  },
  {
    "uniprot": "A0A0N4UKL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKL3"
  },
  {
    "uniprot": "A0A0N4U6R1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6R1"
  },
  {
    "uniprot": "A0A0N4U6U8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6U8"
  },
  {
    "uniprot": "A0A0N4U8N8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8N8"
  },
  {
    "uniprot": "A0A0N4UN99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN99"
  },
  {
    "uniprot": "A0A0N4UQR0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQR0"
  },
  {
    "uniprot": "A0A0N4UFU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFU6"
  },
  {
    "uniprot": "A0A3P7PJH8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PJH8"
  },
  {
    "uniprot": "A0A0N4UM67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM67"
  },
  {
    "uniprot": "A0A0N4U6K1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6K1"
  },
  {
    "uniprot": "A0A158Q4E9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4E9"
  },
  {
    "uniprot": "A0A0N4UJU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJU3"
  },
  {
    "uniprot": "A0A3P7SY93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SY93"
  },
  {
    "uniprot": "A0A158Q508",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q508"
  },
  {
    "uniprot": "A0A3P7Q6K2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q6K2"
  },
  {
    "uniprot": "A0A0N4U5B6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5B6"
  },
  {
    "uniprot": "A0A158Q5V2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5V2"
  },
  {
    "uniprot": "A0A3P7PLN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PLN9"
  },
  {
    "uniprot": "A0A0N4UHM1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHM1"
  },
  {
    "uniprot": "A0A158Q529",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q529"
  },
  {
    "uniprot": "A0A3P7SHW5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SHW5"
  },
  {
    "uniprot": "A0A0N4UH23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH23"
  },
  {
    "uniprot": "A0A0N4UBU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBU4"
  },
  {
    "uniprot": "A0A0N4UME8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UME8"
  },
  {
    "uniprot": "A0A0N4UIM3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIM3"
  },
  {
    "uniprot": "A0A0N4UA79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA79"
  },
  {
    "uniprot": "A0A0N4U9G9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9G9"
  },
  {
    "uniprot": "A0A0N4U8A5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8A5"
  },
  {
    "uniprot": "A0A0N4U3Z4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Z4"
  },
  {
    "uniprot": "A0A0N4U0Y0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Y0"
  },
  {
    "uniprot": "A0A0N4U269",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U269"
  },
  {
    "uniprot": "A0A0N4UQV2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQV2"
  },
  {
    "uniprot": "A0A0N4UHN4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHN4"
  },
  {
    "uniprot": "A0A0N4U5I4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5I4"
  },
  {
    "uniprot": "A0A3P7QMP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QMP6"
  },
  {
    "uniprot": "A0A0N4U569",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U569"
  },
  {
    "uniprot": "A0A0N4UEY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEY2"
  },
  {
    "uniprot": "A0A0N4UEQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEQ4"
  },
  {
    "uniprot": "A0A3P7PBQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PBQ1"
  },
  {
    "uniprot": "A0A0N4U7U5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7U5"
  },
  {
    "uniprot": "A0A3P7SGR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SGR2"
  },
  {
    "uniprot": "A0A0N4UR49",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR49"
  },
  {
    "uniprot": "A0A3P7PP49",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PP49"
  },
  {
    "uniprot": "A0A0N4UCY6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCY6"
  },
  {
    "uniprot": "A0A0N4UGA3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGA3"
  },
  {
    "uniprot": "A0A0N4UAI6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAI6"
  },
  {
    "uniprot": "A0A158Q4S8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4S8"
  },
  {
    "uniprot": "A0A0N4U7C3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7C3"
  },
  {
    "uniprot": "A0A0N4U3X2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3X2"
  },
  {
    "uniprot": "A0A0N4UQ98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ98"
  },
  {
    "uniprot": "A0A0N4U0T9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0T9"
  },
  {
    "uniprot": "A0A0N4UJC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJC3"
  },
  {
    "uniprot": "A0A0N4U5B3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5B3"
  },
  {
    "uniprot": "A0A0N4UKY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKY4"
  },
  {
    "uniprot": "A0A0N4UNB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNB2"
  },
  {
    "uniprot": "A0A0N4UM43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM43"
  },
  {
    "uniprot": "A0A3P7PXU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PXU2"
  },
  {
    "uniprot": "A0A0N4U2W1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2W1"
  },
  {
    "uniprot": "A0A0N4UL73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL73"
  },
  {
    "uniprot": "A0A0N4U0M2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0M2"
  },
  {
    "uniprot": "A0A0N4UCS1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCS1"
  },
  {
    "uniprot": "A0A0N4U726",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U726"
  },
  {
    "uniprot": "A0A0N4U1X8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1X8"
  },
  {
    "uniprot": "A0A0N4UP12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP12"
  },
  {
    "uniprot": "A0A0N4URQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URQ5"
  },
  {
    "uniprot": "A0A0N4U0Q0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Q0"
  },
  {
    "uniprot": "A0A0N4UJ04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ04"
  },
  {
    "uniprot": "A0A0N4UK64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK64"
  },
  {
    "uniprot": "A0A0N4U121",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U121"
  },
  {
    "uniprot": "A0A0N4U832",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U832"
  },
  {
    "uniprot": "A0A3P7SLM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SLM0"
  },
  {
    "uniprot": "A0A0N4U3R7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3R7"
  },
  {
    "uniprot": "A0A0N4U9X4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9X4"
  },
  {
    "uniprot": "A0A0N4UFY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFY0"
  },
  {
    "uniprot": "A0A158Q3F7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3F7"
  },
  {
    "uniprot": "A0A0N4U7A0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7A0"
  },
  {
    "uniprot": "A0A0N4UPY6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPY6"
  },
  {
    "uniprot": "A0A3P7TG13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TG13"
  },
  {
    "uniprot": "A0A0N4U523",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U523"
  },
  {
    "uniprot": "A0A0N4UBS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBS3"
  },
  {
    "uniprot": "A0A0N4U118",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U118"
  },
  {
    "uniprot": "A0A0N4UH29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH29"
  },
  {
    "uniprot": "A0A0N4U2E8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2E8"
  },
  {
    "uniprot": "A0A0N4U2S2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2S2"
  },
  {
    "uniprot": "A0A0N4UKB4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKB4"
  },
  {
    "uniprot": "A0A0N4UMM6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMM6"
  },
  {
    "uniprot": "A0A158Q457",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q457"
  },
  {
    "uniprot": "A0A3P7QRR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QRR7"
  },
  {
    "uniprot": "A0A0N4U5V5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5V5"
  },
  {
    "uniprot": "A0A0N4UAZ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAZ2"
  },
  {
    "uniprot": "A0A3P7PAE4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PAE4"
  },
  {
    "uniprot": "A0A3P7SHV5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SHV5"
  },
  {
    "uniprot": "A0A0N4U842",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U842"
  },
  {
    "uniprot": "A0A3P7QF41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QF41"
  },
  {
    "uniprot": "A0A0N4UBF7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBF7"
  },
  {
    "uniprot": "A0A158Q2X0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2X0"
  },
  {
    "uniprot": "A0A0N4UA35",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA35"
  },
  {
    "uniprot": "A0A0N4UC72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC72"
  },
  {
    "uniprot": "A0A0N4U4W2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4W2"
  },
  {
    "uniprot": "A0A0N4UME2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UME2"
  },
  {
    "uniprot": "A0A0N4URK7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URK7"
  },
  {
    "uniprot": "A0A158Q5P5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5P5"
  },
  {
    "uniprot": "A0A0N4UBJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBJ1"
  },
  {
    "uniprot": "A0A0N4UAN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAN2"
  },
  {
    "uniprot": "A0A0N4U5D3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5D3"
  },
  {
    "uniprot": "A0A0N4UEZ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEZ0"
  },
  {
    "uniprot": "A0A158Q4D7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4D7"
  },
  {
    "uniprot": "A0A3P7Q2P6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q2P6"
  },
  {
    "uniprot": "A0A0N4UAL7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAL7"
  },
  {
    "uniprot": "A0A0N4U8G7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8G7"
  },
  {
    "uniprot": "A0A0N4UFU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFU2"
  },
  {
    "uniprot": "A0A0N4ULR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULR9"
  },
  {
    "uniprot": "A0A0N4UJR5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJR5"
  },
  {
    "uniprot": "A0A0N4UQK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQK2"
  },
  {
    "uniprot": "A0A0N4UJ03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ03"
  },
  {
    "uniprot": "A0A3P7SAN1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SAN1"
  },
  {
    "uniprot": "A0A158Q542",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q542"
  },
  {
    "uniprot": "A0A0N4UI00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI00"
  },
  {
    "uniprot": "A0A3P7PX85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PX85"
  },
  {
    "uniprot": "A0A0N4UCR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCR2"
  },
  {
    "uniprot": "A0A0N4U793",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U793"
  },
  {
    "uniprot": "A0A158Q4B2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4B2"
  },
  {
    "uniprot": "A0A0N4U4Z4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Z4"
  },
  {
    "uniprot": "A0A0N4UFY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFY7"
  },
  {
    "uniprot": "A0A0N4UIX2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIX2"
  },
  {
    "uniprot": "A0A0N4U8H9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8H9"
  },
  {
    "uniprot": "A0A0N4UPK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPK6"
  },
  {
    "uniprot": "A0A0N4UGV9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGV9"
  },
  {
    "uniprot": "A0A0N4UQS4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQS4"
  },
  {
    "uniprot": "A0A0N4UFL1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFL1"
  },
  {
    "uniprot": "A0A0N4U4B2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4B2"
  },
  {
    "uniprot": "A0A0N4U8K8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8K8"
  },
  {
    "uniprot": "A0A0N4U247",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U247"
  },
  {
    "uniprot": "A0A3P7PCG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PCG9"
  },
  {
    "uniprot": "A0A0N4U0E0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0E0"
  },
  {
    "uniprot": "A0A0N4UKZ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKZ0"
  },
  {
    "uniprot": "A0A0N4U5Q6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Q6"
  },
  {
    "uniprot": "A0A0N4UEP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEP1"
  },
  {
    "uniprot": "A0A0N4UK82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK82"
  },
  {
    "uniprot": "A0A0N4URF7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URF7"
  },
  {
    "uniprot": "A0A0N4UQS8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQS8"
  },
  {
    "uniprot": "A0A158Q2V6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2V6"
  },
  {
    "uniprot": "A0A0N4ULA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULA8"
  },
  {
    "uniprot": "A0A0N4UCP8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCP8"
  },
  {
    "uniprot": "A0A3P7PPQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPQ4"
  },
  {
    "uniprot": "A0A0N4U7U7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7U7"
  },
  {
    "uniprot": "A0A0N4UDL7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDL7"
  },
  {
    "uniprot": "A0A0N4UQJ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQJ5"
  },
  {
    "uniprot": "A0A0N4UHR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHR2"
  },
  {
    "uniprot": "A0A3P7PX60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PX60"
  },
  {
    "uniprot": "A0A0N4U279",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U279"
  },
  {
    "uniprot": "A0A158Q636",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q636"
  },
  {
    "uniprot": "A0A0N4US24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US24"
  },
  {
    "uniprot": "A0A0N4U7M0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7M0"
  },
  {
    "uniprot": "A0A0N4UB72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB72"
  },
  {
    "uniprot": "A0A0N4UR34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR34"
  },
  {
    "uniprot": "A0A0N4UNE7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNE7"
  },
  {
    "uniprot": "A0A0N4UBR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBR4"
  },
  {
    "uniprot": "A0A0N4U628",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U628"
  },
  {
    "uniprot": "A0A0N4U825",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U825"
  },
  {
    "uniprot": "A0A0N4UP21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP21"
  },
  {
    "uniprot": "A0A0N4UBG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBG6"
  },
  {
    "uniprot": "A0A0N4U7A9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7A9"
  },
  {
    "uniprot": "A0A158Q692",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q692"
  },
  {
    "uniprot": "A0A0N4UP20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP20"
  },
  {
    "uniprot": "A0A0N4UPW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPW0"
  },
  {
    "uniprot": "A0A0N4UMI2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMI2"
  },
  {
    "uniprot": "A0A3P7Q6E7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q6E7"
  },
  {
    "uniprot": "A0A0N4UGK4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGK4"
  },
  {
    "uniprot": "A0A0N4UQS7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQS7"
  },
  {
    "uniprot": "A0A0N4UGG1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGG1"
  },
  {
    "uniprot": "A0A158Q2L4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2L4"
  },
  {
    "uniprot": "A0A0N4UF48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF48"
  },
  {
    "uniprot": "A0A0N4U0F2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0F2"
  },
  {
    "uniprot": "A0A0N4UQ19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ19"
  },
  {
    "uniprot": "A0A0N4UJS5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJS5"
  },
  {
    "uniprot": "A0A0N4UPG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPG9"
  },
  {
    "uniprot": "A0A158Q2Y9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Y9"
  },
  {
    "uniprot": "A0A158Q5S8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5S8"
  },
  {
    "uniprot": "A0A158Q401",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q401"
  },
  {
    "uniprot": "A0A0N4U900",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U900"
  },
  {
    "uniprot": "A0A0N4UAU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAU6"
  },
  {
    "uniprot": "A0A3P7Q7H1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q7H1"
  },
  {
    "uniprot": "A0A0N4U7T7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7T7"
  },
  {
    "uniprot": "A0A158Q2Z2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Z2"
  },
  {
    "uniprot": "A0A0N4U5F2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5F2"
  },
  {
    "uniprot": "A0A0N4UQC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQC4"
  },
  {
    "uniprot": "A0A158Q657",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q657"
  },
  {
    "uniprot": "A0A0N4UMU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMU4"
  },
  {
    "uniprot": "A0A0N4UGS6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGS6"
  },
  {
    "uniprot": "A0A0N4U8D8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8D8"
  },
  {
    "uniprot": "A0A0N4U1G5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1G5"
  },
  {
    "uniprot": "A0A0N4U2T2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2T2"
  },
  {
    "uniprot": "A0A158Q5R7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5R7"
  },
  {
    "uniprot": "A0A0N4UIM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIM8"
  },
  {
    "uniprot": "A0A0N4ULY3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULY3"
  },
  {
    "uniprot": "A0A3P7QQY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QQY4"
  },
  {
    "uniprot": "A0A0N4U7G7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7G7"
  },
  {
    "uniprot": "A0A0N4U3B1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3B1"
  },
  {
    "uniprot": "A0A0N4U353",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U353"
  },
  {
    "uniprot": "A0A0N4US68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US68"
  },
  {
    "uniprot": "A0A3P7PYI5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PYI5"
  },
  {
    "uniprot": "A0A0N4U7H0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7H0"
  },
  {
    "uniprot": "A0A158Q580",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q580"
  },
  {
    "uniprot": "A0A0N4UBJ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBJ8"
  },
  {
    "uniprot": "A0A0N4UE96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE96"
  },
  {
    "uniprot": "A0A0N4UEX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEX8"
  },
  {
    "uniprot": "A0A0N4U9U2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9U2"
  },
  {
    "uniprot": "A0A0N4U3B0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3B0"
  },
  {
    "uniprot": "A0A0N4ULX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULX5"
  },
  {
    "uniprot": "A0A3P7SKJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SKJ1"
  },
  {
    "uniprot": "A0A3P7QR60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QR60"
  },
  {
    "uniprot": "A0A0N4UN72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN72"
  },
  {
    "uniprot": "A0A3P7SF92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SF92"
  },
  {
    "uniprot": "A0A3P7QKP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QKP9"
  },
  {
    "uniprot": "A0A0N4U4B4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4B4"
  },
  {
    "uniprot": "A0A0N4UII6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UII6"
  },
  {
    "uniprot": "A0A0N4UFB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFB9"
  },
  {
    "uniprot": "A0A0N4UDU8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDU8"
  },
  {
    "uniprot": "A0A0N4UFV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFV7"
  },
  {
    "uniprot": "A0A0N4UR32",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR32"
  },
  {
    "uniprot": "A0A0N4U216",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U216"
  },
  {
    "uniprot": "A0A0N4UA95",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA95"
  },
  {
    "uniprot": "A0A3P7T0X9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T0X9"
  },
  {
    "uniprot": "A0A158Q2W8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2W8"
  },
  {
    "uniprot": "A0A3P7SH21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SH21"
  },
  {
    "uniprot": "A0A0N4U208",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U208"
  },
  {
    "uniprot": "A0A0N4U0V5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0V5"
  },
  {
    "uniprot": "A0A0N4UKD3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKD3"
  },
  {
    "uniprot": "A0A158Q6H0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6H0"
  },
  {
    "uniprot": "A0A3P7PNA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PNA2"
  },
  {
    "uniprot": "A0A0N4UKT2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKT2"
  },
  {
    "uniprot": "A0A0N4U684",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U684"
  },
  {
    "uniprot": "A0A158Q584",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q584"
  },
  {
    "uniprot": "A0A0N4UQW7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQW7"
  },
  {
    "uniprot": "A0A0N4UAD1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAD1"
  },
  {
    "uniprot": "A0A0N4U3B9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3B9"
  },
  {
    "uniprot": "A0A3P7PJ72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PJ72"
  },
  {
    "uniprot": "A0A0N4UMD3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMD3"
  },
  {
    "uniprot": "A0A0N4UJG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJG3"
  },
  {
    "uniprot": "A0A0N4U0Y8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Y8"
  },
  {
    "uniprot": "A0A0N4UR35",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR35"
  },
  {
    "uniprot": "A0A158Q4F3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4F3"
  },
  {
    "uniprot": "A0A0N4ULY8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULY8"
  },
  {
    "uniprot": "A0A0N4U4M5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4M5"
  },
  {
    "uniprot": "A0A0N4UFP5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFP5"
  },
  {
    "uniprot": "A0A0N4UB36",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB36"
  },
  {
    "uniprot": "A0A0N4UF90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF90"
  },
  {
    "uniprot": "A0A0N4U156",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U156"
  },
  {
    "uniprot": "A0A0N4U5V2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5V2"
  },
  {
    "uniprot": "A0A0N4UD24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD24"
  },
  {
    "uniprot": "A0A0N4UAS4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAS4"
  },
  {
    "uniprot": "A0A3P7PF77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PF77"
  },
  {
    "uniprot": "A0A158Q3L8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3L8"
  },
  {
    "uniprot": "A0A0N4UD03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD03"
  },
  {
    "uniprot": "A0A0N4U1F0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1F0"
  },
  {
    "uniprot": "A0A0N4UEW1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEW1"
  },
  {
    "uniprot": "A0A0N4US43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US43"
  },
  {
    "uniprot": "A0A0N4ULR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULR6"
  },
  {
    "uniprot": "A0A0N4UD47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD47"
  },
  {
    "uniprot": "A0A3P7Q9G8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q9G8"
  },
  {
    "uniprot": "A0A0N4UQ83",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ83"
  },
  {
    "uniprot": "A0A0N4UC00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC00"
  },
  {
    "uniprot": "A0A0N4UK46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK46"
  },
  {
    "uniprot": "A0A0N4UJ62",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ62"
  },
  {
    "uniprot": "A0A0N4U7N5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7N5"
  },
  {
    "uniprot": "A0A0N4UBS6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBS6"
  },
  {
    "uniprot": "A0A0N4U1V7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1V7"
  },
  {
    "uniprot": "A0A0N4U887",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U887"
  },
  {
    "uniprot": "A0A0N4UD67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD67"
  },
  {
    "uniprot": "A0A158Q6E6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6E6"
  },
  {
    "uniprot": "A0A3P7STZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7STZ5"
  },
  {
    "uniprot": "A0A0N4U3M0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3M0"
  },
  {
    "uniprot": "A0A0N4U8S4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8S4"
  },
  {
    "uniprot": "A0A0N4UL03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL03"
  },
  {
    "uniprot": "A0A0N4UBX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBX8"
  },
  {
    "uniprot": "A0A0N4UQK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQK5"
  },
  {
    "uniprot": "A0A0N4UK08",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK08"
  },
  {
    "uniprot": "A0A158Q4C8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4C8"
  },
  {
    "uniprot": "A0A0N4UFJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFJ7"
  },
  {
    "uniprot": "A0A0N4UBM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBM2"
  },
  {
    "uniprot": "A0A0N4UIC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIC2"
  },
  {
    "uniprot": "A0A0N4U914",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U914"
  },
  {
    "uniprot": "A0A3P7PRA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PRA6"
  },
  {
    "uniprot": "A0A0N4UF12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF12"
  },
  {
    "uniprot": "A0A0N4UMV0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMV0"
  },
  {
    "uniprot": "A0A0N4UEA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEA4"
  },
  {
    "uniprot": "A0A0N4U3H0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3H0"
  },
  {
    "uniprot": "A0A3P7STQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7STQ7"
  },
  {
    "uniprot": "A0A158Q5N6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5N6"
  },
  {
    "uniprot": "A0A0N4UMP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMP1"
  },
  {
    "uniprot": "A0A0N4UH69",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH69"
  },
  {
    "uniprot": "A0A158Q2M0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2M0"
  },
  {
    "uniprot": "A0A0N4U391",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U391"
  },
  {
    "uniprot": "A0A0N4UJ69",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ69"
  },
  {
    "uniprot": "A0A0N4UCF6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCF6"
  },
  {
    "uniprot": "A0A0N4U4Z8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Z8"
  },
  {
    "uniprot": "A0A0N4U2D5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2D5"
  },
  {
    "uniprot": "A0A0N4UFM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFM2"
  },
  {
    "uniprot": "A0A0N4UHE0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHE0"
  },
  {
    "uniprot": "A0A0N4UAV2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAV2"
  },
  {
    "uniprot": "A0A158Q496",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q496"
  },
  {
    "uniprot": "A0A0N4U655",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U655"
  },
  {
    "uniprot": "A0A0N4UJZ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJZ7"
  },
  {
    "uniprot": "A0A0N4UK19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK19"
  },
  {
    "uniprot": "A0A0N4UN19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN19"
  },
  {
    "uniprot": "A0A0N4UPB4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPB4"
  },
  {
    "uniprot": "A0A0N4UGC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGC3"
  },
  {
    "uniprot": "A0A0N4UMP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMP0"
  },
  {
    "uniprot": "A0A0N4UKF7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKF7"
  },
  {
    "uniprot": "A0A0N4UJQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJQ6"
  },
  {
    "uniprot": "A0A0N4UFK4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFK4"
  },
  {
    "uniprot": "A0A158Q404",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q404"
  },
  {
    "uniprot": "A0A0N4U3W5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3W5"
  },
  {
    "uniprot": "A0A0N4UN46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN46"
  },
  {
    "uniprot": "A0A158Q3Z7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Z7"
  },
  {
    "uniprot": "A0A0N4UFA3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFA3"
  },
  {
    "uniprot": "A0A0N4U1W7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1W7"
  },
  {
    "uniprot": "A0A3P7TAG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TAG0"
  },
  {
    "uniprot": "A0A0N4UGM9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGM9"
  },
  {
    "uniprot": "A0A0N4UI87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI87"
  },
  {
    "uniprot": "A0A0N4U0H2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0H2"
  },
  {
    "uniprot": "A0A158Q5G2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5G2"
  },
  {
    "uniprot": "A0A0N4UJL6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJL6"
  },
  {
    "uniprot": "A0A0N4U8Q6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Q6"
  },
  {
    "uniprot": "A0A0N4UE54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE54"
  },
  {
    "uniprot": "A0A3P7PS26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PS26"
  },
  {
    "uniprot": "A0A0N4U5B7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5B7"
  },
  {
    "uniprot": "A0A0N4UI85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI85"
  },
  {
    "uniprot": "A0A0N4U2Y0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Y0"
  },
  {
    "uniprot": "A0A0N4U1X6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1X6"
  },
  {
    "uniprot": "A0A0N4U0C9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0C9"
  },
  {
    "uniprot": "A0A0N4UHU8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHU8"
  },
  {
    "uniprot": "A0A0N4UA64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA64"
  },
  {
    "uniprot": "A0A0N4U9N6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9N6"
  },
  {
    "uniprot": "A0A0N4U2X1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2X1"
  },
  {
    "uniprot": "A0A0N4UPY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPY7"
  },
  {
    "uniprot": "A0A0N4UJV4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJV4"
  },
  {
    "uniprot": "A0A0N4UPK7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPK7"
  },
  {
    "uniprot": "A0A0N4UCT3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCT3"
  },
  {
    "uniprot": "A0A0N4U2Y6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Y6"
  },
  {
    "uniprot": "A0A3P7QVJ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QVJ3"
  },
  {
    "uniprot": "A0A158Q6J0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6J0"
  },
  {
    "uniprot": "A0A0N4U170",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U170"
  },
  {
    "uniprot": "A0A0N4UC08",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC08"
  },
  {
    "uniprot": "A0A0N4UQD0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQD0"
  },
  {
    "uniprot": "A0A3P7SUS8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SUS8"
  },
  {
    "uniprot": "A0A0N4UKH7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKH7"
  },
  {
    "uniprot": "A0A0N4U8X1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8X1"
  },
  {
    "uniprot": "A0A0N4UAU0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAU0"
  },
  {
    "uniprot": "A0A3P7PL06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PL06"
  },
  {
    "uniprot": "A0A0N4UCQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCQ4"
  },
  {
    "uniprot": "A0A158Q4D4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4D4"
  },
  {
    "uniprot": "A0A0N4U8I3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8I3"
  },
  {
    "uniprot": "A0A0N4U779",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U779"
  },
  {
    "uniprot": "A0A0N4U158",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U158"
  },
  {
    "uniprot": "A0A158Q617",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q617"
  },
  {
    "uniprot": "A0A0N4UJH0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJH0"
  },
  {
    "uniprot": "A0A3P7PPX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPX1"
  },
  {
    "uniprot": "A0A0N4UGD1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGD1"
  },
  {
    "uniprot": "A0A0N4UFL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFL3"
  },
  {
    "uniprot": "A0A158Q4G1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4G1"
  },
  {
    "uniprot": "A0A0N4UCM4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCM4"
  },
  {
    "uniprot": "A0A0N4UJ75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ75"
  },
  {
    "uniprot": "A0A158Q3N2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3N2"
  },
  {
    "uniprot": "A0A0N4UEK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEK0"
  },
  {
    "uniprot": "A0A0N4U7J3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7J3"
  },
  {
    "uniprot": "A0A0N4ULL4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULL4"
  },
  {
    "uniprot": "A0A0N4UI92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI92"
  },
  {
    "uniprot": "A0A0N4UIA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIA8"
  },
  {
    "uniprot": "A0A0N4U8U4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8U4"
  },
  {
    "uniprot": "A0A0N4UG65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG65"
  },
  {
    "uniprot": "A0A0N4U5A9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5A9"
  },
  {
    "uniprot": "A0A0N4UJG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJG4"
  },
  {
    "uniprot": "A0A3P7QU01",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QU01"
  },
  {
    "uniprot": "A0A0N4UHZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHZ4"
  },
  {
    "uniprot": "A0A3P7T513",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T513"
  },
  {
    "uniprot": "A0A0N4U9I4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9I4"
  },
  {
    "uniprot": "A0A0N4UCG7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCG7"
  },
  {
    "uniprot": "A0A0N4U8C2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8C2"
  },
  {
    "uniprot": "A0A0N4ULV5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULV5"
  },
  {
    "uniprot": "A0A158Q5M3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5M3"
  },
  {
    "uniprot": "A0A0N4UKR8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKR8"
  },
  {
    "uniprot": "A0A0N4UNM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNM2"
  },
  {
    "uniprot": "A0A158Q2W6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2W6"
  },
  {
    "uniprot": "A0A0N4UQX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQX8"
  },
  {
    "uniprot": "A0A0N4UHA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHA8"
  },
  {
    "uniprot": "A0A158Q2X2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2X2"
  },
  {
    "uniprot": "A0A158Q2V2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2V2"
  },
  {
    "uniprot": "A0A0N4U4N2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4N2"
  },
  {
    "uniprot": "A0A0N4U6T5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6T5"
  },
  {
    "uniprot": "A0A0N4UGV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGV8"
  },
  {
    "uniprot": "A0A0N4UQ36",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ36"
  },
  {
    "uniprot": "A0A3P7SDN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SDN2"
  },
  {
    "uniprot": "A0A0N4UPD9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPD9"
  },
  {
    "uniprot": "A0A0N4UM38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM38"
  },
  {
    "uniprot": "A0A0N4U5V1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5V1"
  },
  {
    "uniprot": "A0A3P7SLL2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SLL2"
  },
  {
    "uniprot": "A0A3P7PVA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PVA6"
  },
  {
    "uniprot": "A0A158Q6C5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6C5"
  },
  {
    "uniprot": "A0A0N4UGW9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGW9"
  },
  {
    "uniprot": "A0A158Q2N5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2N5"
  },
  {
    "uniprot": "A0A0N4U718",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U718"
  },
  {
    "uniprot": "A0A0N4UBC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBC6"
  },
  {
    "uniprot": "A0A0N4U9L9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9L9"
  },
  {
    "uniprot": "A0A0N4UBW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBW0"
  },
  {
    "uniprot": "A0A158Q2R3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2R3"
  },
  {
    "uniprot": "A0A0N4U6V2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6V2"
  },
  {
    "uniprot": "A0A0N4UNE2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNE2"
  },
  {
    "uniprot": "A0A0N4UHN1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHN1"
  },
  {
    "uniprot": "A0A3P7PIX6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PIX6"
  },
  {
    "uniprot": "A0A0N4UA41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA41"
  },
  {
    "uniprot": "A0A0N4UEN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEN2"
  },
  {
    "uniprot": "A0A158Q5X3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5X3"
  },
  {
    "uniprot": "A0A0N4UQ78",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ78"
  },
  {
    "uniprot": "A0A0N4UNK7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNK7"
  },
  {
    "uniprot": "A0A0N4UJK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJK1"
  },
  {
    "uniprot": "A0A0N4U2K9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2K9"
  },
  {
    "uniprot": "A0A0N4UR76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR76"
  },
  {
    "uniprot": "A0A3P7PUC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PUC5"
  },
  {
    "uniprot": "A0A0N4U0M9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0M9"
  },
  {
    "uniprot": "A0A0N4UB49",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB49"
  },
  {
    "uniprot": "A0A158Q2P3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2P3"
  },
  {
    "uniprot": "A0A0N4UD30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD30"
  },
  {
    "uniprot": "A0A0N4UJL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJL3"
  },
  {
    "uniprot": "A0A3P7PVS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PVS3"
  },
  {
    "uniprot": "A0A0N4UPH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPH6"
  },
  {
    "uniprot": "A0A0N4UHK8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHK8"
  },
  {
    "uniprot": "A0A0N4U281",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U281"
  },
  {
    "uniprot": "A0A0N4UGH5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGH5"
  },
  {
    "uniprot": "A0A0N4U210",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U210"
  },
  {
    "uniprot": "A0A0N4UEM6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEM6"
  },
  {
    "uniprot": "A0A0N4U878",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U878"
  },
  {
    "uniprot": "A0A0N4UD23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD23"
  },
  {
    "uniprot": "A0A3P7QGM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QGM2"
  },
  {
    "uniprot": "A0A0N4UKS8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKS8"
  },
  {
    "uniprot": "A0A0N4UPI8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPI8"
  },
  {
    "uniprot": "A0A3P7PNA7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PNA7"
  },
  {
    "uniprot": "A0A0N4UIM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIM0"
  },
  {
    "uniprot": "A0A0N4U732",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U732"
  },
  {
    "uniprot": "A0A0N4U5B1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5B1"
  },
  {
    "uniprot": "A0A0N4U5X1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5X1"
  },
  {
    "uniprot": "A0A0N4U0D1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0D1"
  },
  {
    "uniprot": "A0A0N4U782",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U782"
  },
  {
    "uniprot": "A0A3P7P443",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P443"
  },
  {
    "uniprot": "A0A3P7P9K4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P9K4"
  },
  {
    "uniprot": "A0A3P7PJP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PJP0"
  },
  {
    "uniprot": "A0A158Q4H2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4H2"
  },
  {
    "uniprot": "A0A0N4URW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URW0"
  },
  {
    "uniprot": "A0A0N4UNL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNL0"
  },
  {
    "uniprot": "A0A3P7T3J9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T3J9"
  },
  {
    "uniprot": "A0A0N4U5G1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5G1"
  },
  {
    "uniprot": "A0A0N4UE47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE47"
  },
  {
    "uniprot": "A0A0N4UNI5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNI5"
  },
  {
    "uniprot": "A0A0N4UDU0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDU0"
  },
  {
    "uniprot": "A0A158Q4F1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4F1"
  },
  {
    "uniprot": "A0A0N4UCG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCG2"
  },
  {
    "uniprot": "A0A0N4U0E5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0E5"
  },
  {
    "uniprot": "A0A0N4UGS0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGS0"
  },
  {
    "uniprot": "A0A0N4U929",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U929"
  },
  {
    "uniprot": "A0A0N4UHV2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHV2"
  },
  {
    "uniprot": "A0A0N4UHH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHH9"
  },
  {
    "uniprot": "A0A0N4U7N3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7N3"
  },
  {
    "uniprot": "A0A3P7PUN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PUN3"
  },
  {
    "uniprot": "A0A0N4UES5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UES5"
  },
  {
    "uniprot": "A0A0N4U7Z9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Z9"
  },
  {
    "uniprot": "A0A0N4U3S5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3S5"
  },
  {
    "uniprot": "A0A0N4UM74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM74"
  },
  {
    "uniprot": "A0A3P7SY00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SY00"
  },
  {
    "uniprot": "A0A0N4UG42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG42"
  },
  {
    "uniprot": "A0A3P7SMK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SMK5"
  },
  {
    "uniprot": "A0A0N4ULG8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULG8"
  },
  {
    "uniprot": "A0A0N4UNZ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNZ2"
  },
  {
    "uniprot": "A0A0N4U562",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U562"
  },
  {
    "uniprot": "A0A0N4UNN5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNN5"
  },
  {
    "uniprot": "A0A158Q6E0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6E0"
  },
  {
    "uniprot": "A0A0N4UIF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIF3"
  },
  {
    "uniprot": "A0A0N4UE12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE12"
  },
  {
    "uniprot": "A0A0N4UCV1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCV1"
  },
  {
    "uniprot": "A0A0N4U1T5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1T5"
  },
  {
    "uniprot": "A0A0N4UIY8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIY8"
  },
  {
    "uniprot": "A0A0N4U1U3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1U3"
  },
  {
    "uniprot": "A0A3P7Q3C8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q3C8"
  },
  {
    "uniprot": "A0A0N4UEK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEK2"
  },
  {
    "uniprot": "A0A0N4U998",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U998"
  },
  {
    "uniprot": "A0A0N4U283",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U283"
  },
  {
    "uniprot": "A0A0N4UK87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK87"
  },
  {
    "uniprot": "A0A0N4UHC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHC7"
  },
  {
    "uniprot": "A0A3P7QED4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QED4"
  },
  {
    "uniprot": "A0A0N4UDW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDW3"
  },
  {
    "uniprot": "A0A158Q338",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q338"
  },
  {
    "uniprot": "A0A0N4U5Z4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Z4"
  },
  {
    "uniprot": "A0A0N4U728",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U728"
  },
  {
    "uniprot": "A0A0N4UB54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB54"
  },
  {
    "uniprot": "A0A158Q3L3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3L3"
  },
  {
    "uniprot": "A0A158Q3U5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3U5"
  },
  {
    "uniprot": "A0A0N4UQX0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQX0"
  },
  {
    "uniprot": "A0A3P7Q2U9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q2U9"
  },
  {
    "uniprot": "A0A0N4U2G6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2G6"
  },
  {
    "uniprot": "A0A0N4UIQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIQ6"
  },
  {
    "uniprot": "A0A0N4UHL5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHL5"
  },
  {
    "uniprot": "A0A0N4U6E6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6E6"
  },
  {
    "uniprot": "A0A158Q4Z1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Z1"
  },
  {
    "uniprot": "A0A0N4UQH8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQH8"
  },
  {
    "uniprot": "A0A0N4UMF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMF3"
  },
  {
    "uniprot": "A0A0N4U4A0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4A0"
  },
  {
    "uniprot": "A0A0N4UJT0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJT0"
  },
  {
    "uniprot": "A0A0N4U318",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U318"
  },
  {
    "uniprot": "A0A0N4UJH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJH3"
  },
  {
    "uniprot": "A0A0N4UME9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UME9"
  },
  {
    "uniprot": "A0A0N4UAG8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAG8"
  },
  {
    "uniprot": "A0A3P7Q0A9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q0A9"
  },
  {
    "uniprot": "A0A0N4UQM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQM8"
  },
  {
    "uniprot": "A0A158Q4H1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4H1"
  },
  {
    "uniprot": "A0A3P7SSA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SSA9"
  },
  {
    "uniprot": "A0A0N4U433",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U433"
  },
  {
    "uniprot": "A0A0N4UQJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQJ1"
  },
  {
    "uniprot": "A0A0N4US56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US56"
  },
  {
    "uniprot": "A0A0N4U555",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U555"
  },
  {
    "uniprot": "A0A0N4U4L4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4L4"
  },
  {
    "uniprot": "A0A0N4UFB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFB2"
  },
  {
    "uniprot": "A0A0N4U1W9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1W9"
  },
  {
    "uniprot": "A0A158Q406",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q406"
  },
  {
    "uniprot": "A0A0N4UK66",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK66"
  },
  {
    "uniprot": "A0A0N4UKU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKU6"
  },
  {
    "uniprot": "A0A158Q5W6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5W6"
  },
  {
    "uniprot": "A0A158Q666",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q666"
  },
  {
    "uniprot": "A0A0N4UPC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPC9"
  },
  {
    "uniprot": "A0A0N4UH58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH58"
  },
  {
    "uniprot": "A0A0N4U777",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U777"
  },
  {
    "uniprot": "A0A158Q3S4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3S4"
  },
  {
    "uniprot": "A0A0N4UI99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI99"
  },
  {
    "uniprot": "A0A158Q346",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q346"
  },
  {
    "uniprot": "A0A3P7R008",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7R008"
  },
  {
    "uniprot": "A0A158Q4K9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4K9"
  },
  {
    "uniprot": "A0A0N4U5K0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5K0"
  },
  {
    "uniprot": "A0A0N4U2X7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2X7"
  },
  {
    "uniprot": "A0A3P7Q0L1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q0L1"
  },
  {
    "uniprot": "A0A0N4UCE2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCE2"
  },
  {
    "uniprot": "A0A0N4U7Q1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Q1"
  },
  {
    "uniprot": "A0A0N4UM86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM86"
  },
  {
    "uniprot": "A0A0N4ULE2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULE2"
  },
  {
    "uniprot": "A0A0N4UAF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAF9"
  },
  {
    "uniprot": "A0A0N4UPN8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPN8"
  },
  {
    "uniprot": "A0A158Q6G7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6G7"
  },
  {
    "uniprot": "A0A0N4UQV0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQV0"
  },
  {
    "uniprot": "A0A0N4UIH7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIH7"
  },
  {
    "uniprot": "A0A0N4UPH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPH1"
  },
  {
    "uniprot": "A0A0N4UET1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UET1"
  },
  {
    "uniprot": "A0A0N4UL21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL21"
  },
  {
    "uniprot": "A0A0N4UD83",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD83"
  },
  {
    "uniprot": "A0A158Q4S2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4S2"
  },
  {
    "uniprot": "A0A0N4U9B7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9B7"
  },
  {
    "uniprot": "A0A0N4U5I1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5I1"
  },
  {
    "uniprot": "A0A0N4U4B7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4B7"
  },
  {
    "uniprot": "A0A0N4U165",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U165"
  },
  {
    "uniprot": "A0A0N4U8S3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8S3"
  },
  {
    "uniprot": "A0A0N4UAN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAN0"
  },
  {
    "uniprot": "A0A0N4URI2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URI2"
  },
  {
    "uniprot": "A0A0N4U8C1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8C1"
  },
  {
    "uniprot": "A0A0N4UQP5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQP5"
  },
  {
    "uniprot": "A0A0N4UML7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UML7"
  },
  {
    "uniprot": "A0A0N4UKK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKK3"
  },
  {
    "uniprot": "A0A0N4UF46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF46"
  },
  {
    "uniprot": "A0A0N4UP33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP33"
  },
  {
    "uniprot": "A0A0N4U5M3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5M3"
  },
  {
    "uniprot": "A0A0N4UKF6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKF6"
  },
  {
    "uniprot": "A0A0N4U9X2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9X2"
  },
  {
    "uniprot": "A0A158Q2L1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2L1"
  },
  {
    "uniprot": "A0A0N4U7F0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7F0"
  },
  {
    "uniprot": "A0A0N4UQ72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ72"
  },
  {
    "uniprot": "A0A0N4UAQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAQ7"
  },
  {
    "uniprot": "A0A0N4UQ50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ50"
  },
  {
    "uniprot": "A0A0N4UEI2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEI2"
  },
  {
    "uniprot": "A0A0N4UCZ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCZ0"
  },
  {
    "uniprot": "A0A158Q5R6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5R6"
  },
  {
    "uniprot": "A0A0N4UH06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH06"
  },
  {
    "uniprot": "A0A0N4U1S6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1S6"
  },
  {
    "uniprot": "A0A0N4UEN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEN0"
  },
  {
    "uniprot": "A0A0N4UCY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCY1"
  },
  {
    "uniprot": "A0A3P7Q5U2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q5U2"
  },
  {
    "uniprot": "A0A158Q3U3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3U3"
  },
  {
    "uniprot": "A0A0N4U0M8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0M8"
  },
  {
    "uniprot": "A0A0N4UD92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD92"
  },
  {
    "uniprot": "A0A0N4UC11",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC11"
  },
  {
    "uniprot": "A0A158Q3S1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3S1"
  },
  {
    "uniprot": "A0A0N4ULD3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULD3"
  },
  {
    "uniprot": "A0A0N4UBP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBP1"
  },
  {
    "uniprot": "A0A0N4UD63",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD63"
  },
  {
    "uniprot": "A0A0N4U0G4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0G4"
  },
  {
    "uniprot": "A0A0N4UCV4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCV4"
  },
  {
    "uniprot": "A0A0N4UG12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG12"
  },
  {
    "uniprot": "A0A158Q4A1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4A1"
  },
  {
    "uniprot": "A0A0N4U4W5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4W5"
  },
  {
    "uniprot": "A0A0N4UJC8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJC8"
  },
  {
    "uniprot": "A0A0N4UG86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG86"
  },
  {
    "uniprot": "A0A0N4UM31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM31"
  },
  {
    "uniprot": "A0A0N4UEC8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEC8"
  },
  {
    "uniprot": "A0A158Q5C2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5C2"
  },
  {
    "uniprot": "A0A0N4UD69",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD69"
  },
  {
    "uniprot": "A0A0N4UKX0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKX0"
  },
  {
    "uniprot": "A0A0N4U4A6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4A6"
  },
  {
    "uniprot": "A0A0N4U0V1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0V1"
  },
  {
    "uniprot": "A0A3P7PVF7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PVF7"
  },
  {
    "uniprot": "A0A158Q3H1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3H1"
  },
  {
    "uniprot": "A0A158Q5N0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5N0"
  },
  {
    "uniprot": "A0A0N4U3S0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3S0"
  },
  {
    "uniprot": "A0A0N4U251",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U251"
  },
  {
    "uniprot": "A0A0N4U7E9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7E9"
  },
  {
    "uniprot": "A0A0N4U528",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U528"
  },
  {
    "uniprot": "A0A0N4UC49",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC49"
  },
  {
    "uniprot": "A0A0N4U9F1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9F1"
  },
  {
    "uniprot": "A0A3P7PKG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PKG9"
  },
  {
    "uniprot": "A0A0N4UGE2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGE2"
  },
  {
    "uniprot": "A0A0N4UGN5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGN5"
  },
  {
    "uniprot": "A0A158Q4P8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4P8"
  },
  {
    "uniprot": "A0A0N4U945",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U945"
  },
  {
    "uniprot": "A0A0N4UEW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEW8"
  },
  {
    "uniprot": "A0A0N4UMC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMC3"
  },
  {
    "uniprot": "A0A0N4U3I6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3I6"
  },
  {
    "uniprot": "A0A0N4U3R6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3R6"
  },
  {
    "uniprot": "A0A0N4UB74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB74"
  },
  {
    "uniprot": "A0A0N4U3N7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3N7"
  },
  {
    "uniprot": "A0A0N4U1K3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1K3"
  },
  {
    "uniprot": "A0A0N4U2A3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2A3"
  },
  {
    "uniprot": "A0A0N4UE32",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE32"
  },
  {
    "uniprot": "A0A0N4URZ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URZ7"
  },
  {
    "uniprot": "A0A0N4UM20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM20"
  },
  {
    "uniprot": "A0A0N4U5A7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5A7"
  },
  {
    "uniprot": "A0A0N4U8L7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8L7"
  },
  {
    "uniprot": "A0A0N4U511",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U511"
  },
  {
    "uniprot": "A0A0N4UQY3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQY3"
  },
  {
    "uniprot": "A0A0N4UEK4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEK4"
  },
  {
    "uniprot": "A0A3P7Q8C7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q8C7"
  },
  {
    "uniprot": "A0A0N4UKI8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKI8"
  },
  {
    "uniprot": "A0A0N4U146",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U146"
  },
  {
    "uniprot": "A0A158Q347",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q347"
  },
  {
    "uniprot": "A0A0N4U876",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U876"
  },
  {
    "uniprot": "A0A0N4UGW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGW8"
  },
  {
    "uniprot": "A0A0N4U986",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U986"
  },
  {
    "uniprot": "A0A0N4UDY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDY2"
  },
  {
    "uniprot": "A0A158Q4V5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4V5"
  },
  {
    "uniprot": "A0A3P7Q1H5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q1H5"
  },
  {
    "uniprot": "A0A0N4U5G9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5G9"
  },
  {
    "uniprot": "A0A0N4U9G3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9G3"
  },
  {
    "uniprot": "A0A0N4U5T9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5T9"
  },
  {
    "uniprot": "A0A158Q5H5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5H5"
  },
  {
    "uniprot": "A0A0N4UC87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC87"
  },
  {
    "uniprot": "A0A0N4URX3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URX3"
  },
  {
    "uniprot": "A0A3P7SLM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SLM8"
  },
  {
    "uniprot": "A0A3P7PXC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PXC9"
  },
  {
    "uniprot": "A0A0N4U1L6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1L6"
  },
  {
    "uniprot": "A0A158Q3R9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3R9"
  },
  {
    "uniprot": "A0A0N4UCF1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCF1"
  },
  {
    "uniprot": "A0A0N4UKB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKB7"
  },
  {
    "uniprot": "A0A0N4UCU9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCU9"
  },
  {
    "uniprot": "A0A0N4U484",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U484"
  },
  {
    "uniprot": "A0A0N4UE23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE23"
  },
  {
    "uniprot": "A0A0N4UC90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC90"
  },
  {
    "uniprot": "A0A0N4UAX6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAX6"
  },
  {
    "uniprot": "A0A3P7PBD1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PBD1"
  },
  {
    "uniprot": "A0A0N4UQH0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQH0"
  },
  {
    "uniprot": "A0A0N4UAI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAI1"
  },
  {
    "uniprot": "A0A0N4U316",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U316"
  },
  {
    "uniprot": "A0A0N4UIK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIK0"
  },
  {
    "uniprot": "A0A0N4URA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URA6"
  },
  {
    "uniprot": "A0A0N4UMU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMU3"
  },
  {
    "uniprot": "A0A0N4U6S3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6S3"
  },
  {
    "uniprot": "A0A0N4U815",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U815"
  },
  {
    "uniprot": "A0A0N4UKT9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKT9"
  },
  {
    "uniprot": "A0A0N4UGD3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGD3"
  },
  {
    "uniprot": "A0A0N4UEV0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEV0"
  },
  {
    "uniprot": "A0A158Q440",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q440"
  },
  {
    "uniprot": "A0A0N4UFK7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFK7"
  },
  {
    "uniprot": "A0A0N4UGM7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGM7"
  },
  {
    "uniprot": "A0A0N4U0Y7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Y7"
  },
  {
    "uniprot": "A0A3P7QQH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QQH1"
  },
  {
    "uniprot": "A0A0N4UDQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDQ4"
  },
  {
    "uniprot": "A0A0N4UHT5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHT5"
  },
  {
    "uniprot": "A0A0N4U115",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U115"
  },
  {
    "uniprot": "A0A158Q2T0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2T0"
  },
  {
    "uniprot": "A0A0N4U1K4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1K4"
  },
  {
    "uniprot": "A0A0N4UFD5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFD5"
  },
  {
    "uniprot": "A0A3P7PK65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PK65"
  },
  {
    "uniprot": "A0A158Q3B9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3B9"
  },
  {
    "uniprot": "A0A0N4U9D6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9D6"
  },
  {
    "uniprot": "A0A0N4UA45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA45"
  },
  {
    "uniprot": "A0A0N4UE17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE17"
  },
  {
    "uniprot": "A0A158Q4T1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4T1"
  },
  {
    "uniprot": "A0A0N4ULD8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULD8"
  },
  {
    "uniprot": "A0A0N4UPC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPC2"
  },
  {
    "uniprot": "A0A0N4UDJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDJ9"
  },
  {
    "uniprot": "A0A0N4UMQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMQ5"
  },
  {
    "uniprot": "A0A0N4UB84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB84"
  },
  {
    "uniprot": "A0A3P7S7I9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S7I9"
  },
  {
    "uniprot": "A0A3P7Q689",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q689"
  },
  {
    "uniprot": "A0A158Q5T2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5T2"
  },
  {
    "uniprot": "A0A0N4U0K1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0K1"
  },
  {
    "uniprot": "A0A0N4U2F1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2F1"
  },
  {
    "uniprot": "A0A0N4UJ47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ47"
  },
  {
    "uniprot": "A0A0N4UPN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPN3"
  },
  {
    "uniprot": "A0A0N4UJY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJY2"
  },
  {
    "uniprot": "A0A0N4U3P7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3P7"
  },
  {
    "uniprot": "A0A0N4ULI2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULI2"
  },
  {
    "uniprot": "A0A0N4U5R3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5R3"
  },
  {
    "uniprot": "A0A158Q394",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q394"
  },
  {
    "uniprot": "A0A0N4UP47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP47"
  },
  {
    "uniprot": "A0A0N4UNJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNJ4"
  },
  {
    "uniprot": "A0A0N4UD18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD18"
  },
  {
    "uniprot": "A0A0N4UFV9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFV9"
  },
  {
    "uniprot": "A0A0N4UF15",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF15"
  },
  {
    "uniprot": "A0A0N4UCG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCG4"
  },
  {
    "uniprot": "A0A0N4UD71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD71"
  },
  {
    "uniprot": "A0A0N4UJZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJZ4"
  },
  {
    "uniprot": "A0A0N4UBT0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBT0"
  },
  {
    "uniprot": "A0A0N4U0H6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0H6"
  },
  {
    "uniprot": "A0A0N4UI16",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI16"
  },
  {
    "uniprot": "A0A0N4UAJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAJ2"
  },
  {
    "uniprot": "A0A0N4U755",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U755"
  },
  {
    "uniprot": "A0A3P7R108",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7R108"
  },
  {
    "uniprot": "A0A0N4U3P9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3P9"
  },
  {
    "uniprot": "A0A0N4UH65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH65"
  },
  {
    "uniprot": "A0A3P7Q4R7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q4R7"
  },
  {
    "uniprot": "A0A0N4U4J6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4J6"
  },
  {
    "uniprot": "A0A0N4UC09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC09"
  },
  {
    "uniprot": "A0A0N4URY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URY4"
  },
  {
    "uniprot": "A0A0N4UI77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI77"
  },
  {
    "uniprot": "A0A0N4UMZ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMZ0"
  },
  {
    "uniprot": "A0A0N4UH12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH12"
  },
  {
    "uniprot": "A0A3P7QNG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QNG4"
  },
  {
    "uniprot": "A0A0N4UF70",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF70"
  },
  {
    "uniprot": "A0A0N4UNV0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNV0"
  },
  {
    "uniprot": "A0A3P7QB48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QB48"
  },
  {
    "uniprot": "A0A0N4UEB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEB9"
  },
  {
    "uniprot": "A0A0N4UHN8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHN8"
  },
  {
    "uniprot": "A0A0N4U0P0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0P0"
  },
  {
    "uniprot": "A0A0N4UMA3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMA3"
  },
  {
    "uniprot": "A0A0N4U5S5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5S5"
  },
  {
    "uniprot": "A0A0N4U8Z6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Z6"
  },
  {
    "uniprot": "A0A3P7PVM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PVM2"
  },
  {
    "uniprot": "A0A0N4UBA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBA9"
  },
  {
    "uniprot": "A0A158Q310",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q310"
  },
  {
    "uniprot": "A0A0N4U2X9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2X9"
  },
  {
    "uniprot": "A0A0N4U1F6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1F6"
  },
  {
    "uniprot": "A0A3P7Q263",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q263"
  },
  {
    "uniprot": "A0A0N4U4E2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4E2"
  },
  {
    "uniprot": "A0A0N4URX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URX8"
  },
  {
    "uniprot": "A0A0N4UNN8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNN8"
  },
  {
    "uniprot": "A0A0N4UHR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHR9"
  },
  {
    "uniprot": "A0A0N4U302",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U302"
  },
  {
    "uniprot": "A0A0N4UB76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB76"
  },
  {
    "uniprot": "A0A158Q3D1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3D1"
  },
  {
    "uniprot": "A0A0N4U792",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U792"
  },
  {
    "uniprot": "A0A3P7Q6Q2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q6Q2"
  },
  {
    "uniprot": "A0A0N4U3X1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3X1"
  },
  {
    "uniprot": "A0A158Q6L9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6L9"
  },
  {
    "uniprot": "A0A0N4U9C0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9C0"
  },
  {
    "uniprot": "A0A0N4UMM5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMM5"
  },
  {
    "uniprot": "A0A0N4UQ94",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ94"
  },
  {
    "uniprot": "A0A0N4U372",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U372"
  },
  {
    "uniprot": "A0A0N4UK77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK77"
  },
  {
    "uniprot": "A0A0N4UHC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHC4"
  },
  {
    "uniprot": "A0A0N4UI08",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI08"
  },
  {
    "uniprot": "A0A0N4UBA3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBA3"
  },
  {
    "uniprot": "A0A3P7SL24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SL24"
  },
  {
    "uniprot": "A0A0N4U6C2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6C2"
  },
  {
    "uniprot": "A0A0N4UN73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN73"
  },
  {
    "uniprot": "A0A0N4UJE1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJE1"
  },
  {
    "uniprot": "A0A158Q3Z2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Z2"
  },
  {
    "uniprot": "A0A0N4UBD2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBD2"
  },
  {
    "uniprot": "A0A0N4UFH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFH9"
  },
  {
    "uniprot": "A0A0N4UMC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMC6"
  },
  {
    "uniprot": "A0A0N4UPL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPL0"
  },
  {
    "uniprot": "A0A0N4UNL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNL9"
  },
  {
    "uniprot": "A0A0N4U3S3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3S3"
  },
  {
    "uniprot": "A0A0N4UG11",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG11"
  },
  {
    "uniprot": "A0A0N4UB73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB73"
  },
  {
    "uniprot": "A0A158Q5P4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5P4"
  },
  {
    "uniprot": "A0A0N4U459",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U459"
  },
  {
    "uniprot": "A0A0N4U6M6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6M6"
  },
  {
    "uniprot": "A0A0N4UGD9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGD9"
  },
  {
    "uniprot": "A0A158Q402",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q402"
  },
  {
    "uniprot": "A0A0N4UQH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQH1"
  },
  {
    "uniprot": "A0A0N4U7Q8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Q8"
  },
  {
    "uniprot": "A0A0N4U1Q0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Q0"
  },
  {
    "uniprot": "A0A0N4UC40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC40"
  },
  {
    "uniprot": "A0A158Q6J2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6J2"
  },
  {
    "uniprot": "A0A0N4U1Z8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Z8"
  },
  {
    "uniprot": "A0A0N4U587",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U587"
  },
  {
    "uniprot": "A0A0N4UR48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR48"
  },
  {
    "uniprot": "A0A0N4UBV4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBV4"
  },
  {
    "uniprot": "A0A3P7P7U4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P7U4"
  },
  {
    "uniprot": "A0A0N4U6P6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6P6"
  },
  {
    "uniprot": "A0A0N4U989",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U989"
  },
  {
    "uniprot": "A0A158Q3V1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3V1"
  },
  {
    "uniprot": "A0A0N4UCG8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCG8"
  },
  {
    "uniprot": "A0A3P7SU06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SU06"
  },
  {
    "uniprot": "A0A0N4UFG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFG9"
  },
  {
    "uniprot": "A0A3P7PPD4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPD4"
  },
  {
    "uniprot": "A0A0N4UQZ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQZ2"
  },
  {
    "uniprot": "A0A0N4UEG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEG3"
  },
  {
    "uniprot": "A0A158Q418",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q418"
  },
  {
    "uniprot": "A0A0N4U6W8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6W8"
  },
  {
    "uniprot": "A0A158Q6I3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6I3"
  },
  {
    "uniprot": "A0A0N4U474",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U474"
  },
  {
    "uniprot": "A0A3P7S918",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S918"
  },
  {
    "uniprot": "A0A0N4UCJ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCJ3"
  },
  {
    "uniprot": "A0A0N4UPR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPR1"
  },
  {
    "uniprot": "A0A0N4UPN1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPN1"
  },
  {
    "uniprot": "A0A0N4UL04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL04"
  },
  {
    "uniprot": "A0A0N4UFE7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFE7"
  },
  {
    "uniprot": "A0A0N4UIU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIU4"
  },
  {
    "uniprot": "A0A0N4ULZ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULZ0"
  },
  {
    "uniprot": "A0A0N4U375",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U375"
  },
  {
    "uniprot": "A0A0N4UBQ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBQ9"
  },
  {
    "uniprot": "A0A0N4UBT9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBT9"
  },
  {
    "uniprot": "A0A0N4UDX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDX1"
  },
  {
    "uniprot": "A0A158Q3A9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3A9"
  },
  {
    "uniprot": "A0A0N4UAT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAT6"
  },
  {
    "uniprot": "A0A158Q409",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q409"
  },
  {
    "uniprot": "A0A0N4UCW2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCW2"
  },
  {
    "uniprot": "A0A0N4U4A8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4A8"
  },
  {
    "uniprot": "A0A158Q491",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q491"
  },
  {
    "uniprot": "A0A0N4UPD8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPD8"
  },
  {
    "uniprot": "A0A0N4URM7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URM7"
  },
  {
    "uniprot": "A0A0N4UJJ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJJ5"
  },
  {
    "uniprot": "A0A158Q5B5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5B5"
  },
  {
    "uniprot": "A0A0N4UIP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIP1"
  },
  {
    "uniprot": "A0A0N4U6W0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6W0"
  },
  {
    "uniprot": "A0A0N4UGP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGP6"
  },
  {
    "uniprot": "A0A0N4UD38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD38"
  },
  {
    "uniprot": "A0A0N4UJH5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJH5"
  },
  {
    "uniprot": "A0A0N4URM9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URM9"
  },
  {
    "uniprot": "A0A0N4UR31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR31"
  },
  {
    "uniprot": "A0A3P7PYN7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PYN7"
  },
  {
    "uniprot": "A0A0N4UIG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIG3"
  },
  {
    "uniprot": "A0A0N4U9S7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9S7"
  },
  {
    "uniprot": "A0A0N4UG21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG21"
  },
  {
    "uniprot": "A0A0N4ULR8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULR8"
  },
  {
    "uniprot": "A0A3P7QPG8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QPG8"
  },
  {
    "uniprot": "A0A0N4U8G1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8G1"
  },
  {
    "uniprot": "A0A158Q5F6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5F6"
  },
  {
    "uniprot": "A0A0N4U7H2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7H2"
  },
  {
    "uniprot": "A0A0N4UKY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKY2"
  },
  {
    "uniprot": "A0A3P7QMX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QMX7"
  },
  {
    "uniprot": "A0A0N4U9D0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9D0"
  },
  {
    "uniprot": "A0A0N4UPC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPC1"
  },
  {
    "uniprot": "A0A0N4U3P8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3P8"
  },
  {
    "uniprot": "A0A158Q567",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q567"
  },
  {
    "uniprot": "A0A0N4U3A8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3A8"
  },
  {
    "uniprot": "A0A0N4U4U3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4U3"
  },
  {
    "uniprot": "A0A0N4U8Z5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Z5"
  },
  {
    "uniprot": "A0A0N4UL07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL07"
  },
  {
    "uniprot": "A0A0N4UKE8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKE8"
  },
  {
    "uniprot": "A0A0N4UQG7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQG7"
  },
  {
    "uniprot": "A0A0N4UCF8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCF8"
  },
  {
    "uniprot": "A0A0N4UJL2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJL2"
  },
  {
    "uniprot": "A0A0N4UNL1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNL1"
  },
  {
    "uniprot": "A0A0N4UQY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQY1"
  },
  {
    "uniprot": "A0A0N4U513",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U513"
  },
  {
    "uniprot": "A0A0N4UFG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFG6"
  },
  {
    "uniprot": "A0A0N4U3U0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3U0"
  },
  {
    "uniprot": "A0A3P7Q3W4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q3W4"
  },
  {
    "uniprot": "A0A0N4UIH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIH6"
  },
  {
    "uniprot": "A0A0N4UKV9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKV9"
  },
  {
    "uniprot": "A0A0N4ULS7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULS7"
  },
  {
    "uniprot": "A0A0N4U5I2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5I2"
  },
  {
    "uniprot": "A0A3P7SPS7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SPS7"
  },
  {
    "uniprot": "A0A0N4U9F2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9F2"
  },
  {
    "uniprot": "A0A0N4URC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URC0"
  },
  {
    "uniprot": "A0A0N4UB28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB28"
  },
  {
    "uniprot": "A0A0N4U319",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U319"
  },
  {
    "uniprot": "A0A158Q3Q6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Q6"
  },
  {
    "uniprot": "A0A0N4U273",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U273"
  },
  {
    "uniprot": "A0A0N4U106",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U106"
  },
  {
    "uniprot": "A0A0N4U9B4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9B4"
  },
  {
    "uniprot": "A0A0N4ULS6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULS6"
  },
  {
    "uniprot": "A0A0N4UIB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIB9"
  },
  {
    "uniprot": "A0A158Q3I2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3I2"
  },
  {
    "uniprot": "A0A0N4UQJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQJ4"
  },
  {
    "uniprot": "A0A0N4U8J4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8J4"
  },
  {
    "uniprot": "A0A0N4UEQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEQ7"
  },
  {
    "uniprot": "A0A0N4UI06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI06"
  },
  {
    "uniprot": "A0A0N4UK02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK02"
  },
  {
    "uniprot": "A0A0N4UQJ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQJ8"
  },
  {
    "uniprot": "A0A0N4U3Y0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Y0"
  },
  {
    "uniprot": "A0A0N4UQQ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQQ8"
  },
  {
    "uniprot": "A0A0N4UHS4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHS4"
  },
  {
    "uniprot": "A0A0N4U136",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U136"
  },
  {
    "uniprot": "A0A0N4UGA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGA1"
  },
  {
    "uniprot": "A0A158Q3E4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3E4"
  },
  {
    "uniprot": "A0A3P7PGC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PGC3"
  },
  {
    "uniprot": "A0A158Q4N9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4N9"
  },
  {
    "uniprot": "A0A3P7QFV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QFV8"
  },
  {
    "uniprot": "A0A0N4U5F3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5F3"
  },
  {
    "uniprot": "A0A0N4UNG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNG2"
  },
  {
    "uniprot": "A0A158Q337",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q337"
  },
  {
    "uniprot": "A0A3P7PB28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PB28"
  },
  {
    "uniprot": "A0A158Q358",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q358"
  },
  {
    "uniprot": "A0A158Q4W0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4W0"
  },
  {
    "uniprot": "A0A0N4U4D2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4D2"
  },
  {
    "uniprot": "A0A0N4UET2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UET2"
  },
  {
    "uniprot": "A0A0N4UP24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP24"
  },
  {
    "uniprot": "A0A0N4U772",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U772"
  },
  {
    "uniprot": "A0A0N4U8J7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8J7"
  },
  {
    "uniprot": "A0A0N4UHZ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHZ2"
  },
  {
    "uniprot": "A0A0N4UK57",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK57"
  },
  {
    "uniprot": "A0A0N4UNG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNG4"
  },
  {
    "uniprot": "A0A0N4UNJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNJ9"
  },
  {
    "uniprot": "A0A3P7QJL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QJL3"
  },
  {
    "uniprot": "A0A0N4UC67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC67"
  },
  {
    "uniprot": "A0A0N4UF54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF54"
  },
  {
    "uniprot": "A0A0N4U4S2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4S2"
  },
  {
    "uniprot": "A0A0N4U9C1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9C1"
  },
  {
    "uniprot": "A0A0N4U466",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U466"
  },
  {
    "uniprot": "A0A0N4UDM6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDM6"
  },
  {
    "uniprot": "A0A0N4U797",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U797"
  },
  {
    "uniprot": "A0A3P7SCL2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SCL2"
  },
  {
    "uniprot": "A0A158Q3S5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3S5"
  },
  {
    "uniprot": "A0A0N4UAY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAY1"
  },
  {
    "uniprot": "A0A0N4U9H8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9H8"
  },
  {
    "uniprot": "A0A0N4UQK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQK1"
  },
  {
    "uniprot": "A0A0N4UBG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBG9"
  },
  {
    "uniprot": "A0A0N4UFK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFK3"
  },
  {
    "uniprot": "A0A0N4U9E7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9E7"
  },
  {
    "uniprot": "A0A3P7SPE9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SPE9"
  },
  {
    "uniprot": "A0A0N4UN98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN98"
  },
  {
    "uniprot": "A0A0N4U715",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U715"
  },
  {
    "uniprot": "A0A3P7SQ79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SQ79"
  },
  {
    "uniprot": "A0A0N4U467",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U467"
  },
  {
    "uniprot": "A0A0N4U8S6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8S6"
  },
  {
    "uniprot": "A0A0N4UDP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDP6"
  },
  {
    "uniprot": "A0A0N4UJ29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ29"
  },
  {
    "uniprot": "A0A0N4UKS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKS3"
  },
  {
    "uniprot": "A0A0N4U5M9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5M9"
  },
  {
    "uniprot": "A0A0N4U4T3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4T3"
  },
  {
    "uniprot": "A0A0N4UCK4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCK4"
  },
  {
    "uniprot": "A0A0N4UG94",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG94"
  },
  {
    "uniprot": "A0A0N4UMJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMJ1"
  },
  {
    "uniprot": "A0A0N4UB16",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB16"
  },
  {
    "uniprot": "A0A0N4UAW6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAW6"
  },
  {
    "uniprot": "A0A0N4UNG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNG6"
  },
  {
    "uniprot": "A0A0N4U3N0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3N0"
  },
  {
    "uniprot": "A0A0N4U9P5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9P5"
  },
  {
    "uniprot": "A0A3P7PTL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PTL0"
  },
  {
    "uniprot": "A0A158Q514",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q514"
  },
  {
    "uniprot": "A0A0N4U4E1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4E1"
  },
  {
    "uniprot": "A0A0N4UL55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL55"
  },
  {
    "uniprot": "A0A0N4UDH8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDH8"
  },
  {
    "uniprot": "A0A0N4US17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US17"
  },
  {
    "uniprot": "A0A3P7SX65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SX65"
  },
  {
    "uniprot": "A0A3P7SW77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SW77"
  },
  {
    "uniprot": "A0A0N4U365",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U365"
  },
  {
    "uniprot": "A0A0N4UQ17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ17"
  },
  {
    "uniprot": "A0A0N4U8T8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8T8"
  },
  {
    "uniprot": "A0A158Q4Q4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Q4"
  },
  {
    "uniprot": "A0A3P7P5I2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P5I2"
  },
  {
    "uniprot": "A0A0N4UE55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE55"
  },
  {
    "uniprot": "A0A3P7SJR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SJR7"
  },
  {
    "uniprot": "A0A0N4UP78",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP78"
  },
  {
    "uniprot": "A0A0N4UDZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDZ4"
  },
  {
    "uniprot": "A0A0N4U4L5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4L5"
  },
  {
    "uniprot": "A0A0N4UQ25",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ25"
  },
  {
    "uniprot": "A0A3P7SMU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SMU2"
  },
  {
    "uniprot": "A0A0N4UIR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIR2"
  },
  {
    "uniprot": "A0A0N4U1S7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1S7"
  },
  {
    "uniprot": "A0A0N4U2V4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2V4"
  },
  {
    "uniprot": "A0A0N4UJR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJR9"
  },
  {
    "uniprot": "A0A3P7Q0N5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q0N5"
  },
  {
    "uniprot": "A0A0N4URD2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URD2"
  },
  {
    "uniprot": "A0A0N4ULF8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULF8"
  },
  {
    "uniprot": "A0A0N4U6L4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6L4"
  },
  {
    "uniprot": "A0A3P7R2F1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7R2F1"
  },
  {
    "uniprot": "A0A0N4UD98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD98"
  },
  {
    "uniprot": "A0A0N4UQK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQK6"
  },
  {
    "uniprot": "A0A158Q2Z1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Z1"
  },
  {
    "uniprot": "A0A0N4U3R1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3R1"
  },
  {
    "uniprot": "A0A0N4UFL4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFL4"
  },
  {
    "uniprot": "A0A3P7QCG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QCG6"
  },
  {
    "uniprot": "A0A0N4UGP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGP4"
  },
  {
    "uniprot": "A0A0N4U3H2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3H2"
  },
  {
    "uniprot": "A0A3P7Q8I2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q8I2"
  },
  {
    "uniprot": "A0A0N4UCC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCC9"
  },
  {
    "uniprot": "A0A0N4U3Q4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Q4"
  },
  {
    "uniprot": "A0A0N4U963",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U963"
  },
  {
    "uniprot": "A0A0N4UHB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHB1"
  },
  {
    "uniprot": "A0A0N4UM98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM98"
  },
  {
    "uniprot": "A0A0N4U5L2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5L2"
  },
  {
    "uniprot": "A0A158Q4I2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4I2"
  },
  {
    "uniprot": "A0A158Q3G2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3G2"
  },
  {
    "uniprot": "A0A0N4UFD3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFD3"
  },
  {
    "uniprot": "A0A3P7PRY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PRY1"
  },
  {
    "uniprot": "A0A0N4U8X3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8X3"
  },
  {
    "uniprot": "A0A0N4U2V9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2V9"
  },
  {
    "uniprot": "A0A158Q4J7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4J7"
  },
  {
    "uniprot": "A0A0N4U5J6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5J6"
  },
  {
    "uniprot": "A0A3P7Q0I8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q0I8"
  },
  {
    "uniprot": "A0A0N4U5V7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5V7"
  },
  {
    "uniprot": "A0A0N4U4I7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4I7"
  },
  {
    "uniprot": "A0A0N4UKZ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKZ2"
  },
  {
    "uniprot": "A0A0N4UFN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFN9"
  },
  {
    "uniprot": "A0A0N4UAC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAC6"
  },
  {
    "uniprot": "A0A0N4UH77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH77"
  },
  {
    "uniprot": "A0A0N4URL7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URL7"
  },
  {
    "uniprot": "A0A0N4U6K8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6K8"
  },
  {
    "uniprot": "A0A0N4U7V1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7V1"
  },
  {
    "uniprot": "A0A0N4UEQ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEQ9"
  },
  {
    "uniprot": "A0A0N4UPU9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPU9"
  },
  {
    "uniprot": "A0A158Q301",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q301"
  },
  {
    "uniprot": "A0A3P7Q4V5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q4V5"
  },
  {
    "uniprot": "A0A0N4UMG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMG9"
  },
  {
    "uniprot": "A0A3P7P978",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P978"
  },
  {
    "uniprot": "A0A0N4U4V9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4V9"
  },
  {
    "uniprot": "A0A0N4U8E0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8E0"
  },
  {
    "uniprot": "A0A0N4UPI2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPI2"
  },
  {
    "uniprot": "A0A0N4UBH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBH4"
  },
  {
    "uniprot": "A0A3P7SR73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SR73"
  },
  {
    "uniprot": "A0A158Q541",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q541"
  },
  {
    "uniprot": "A0A158Q544",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q544"
  },
  {
    "uniprot": "A0A3P7PRJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PRJ2"
  },
  {
    "uniprot": "A0A0N4UDN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDN9"
  },
  {
    "uniprot": "A0A0N4U7M2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7M2"
  },
  {
    "uniprot": "A0A158Q577",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q577"
  },
  {
    "uniprot": "A0A0N4UI73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI73"
  },
  {
    "uniprot": "A0A0N4ULZ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULZ3"
  },
  {
    "uniprot": "A0A0N4U1Q5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Q5"
  },
  {
    "uniprot": "A0A0N4U4G8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4G8"
  },
  {
    "uniprot": "A0A0N4U4U6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4U6"
  },
  {
    "uniprot": "A0A0N4U5U7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5U7"
  },
  {
    "uniprot": "A0A158Q4M1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4M1"
  },
  {
    "uniprot": "A0A0N4UJR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJR4"
  },
  {
    "uniprot": "A0A0N4UHM4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHM4"
  },
  {
    "uniprot": "A0A0N4U180",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U180"
  },
  {
    "uniprot": "A0A0N4U160",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U160"
  },
  {
    "uniprot": "A0A0N4U592",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U592"
  },
  {
    "uniprot": "A0A0N4UCC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCC1"
  },
  {
    "uniprot": "A0A3P7P8J8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P8J8"
  },
  {
    "uniprot": "A0A0N4UBL2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBL2"
  },
  {
    "uniprot": "A0A0N4U4D5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4D5"
  },
  {
    "uniprot": "A0A0N4U5N9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5N9"
  },
  {
    "uniprot": "A0A0N4U7J6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7J6"
  },
  {
    "uniprot": "A0A158Q3Q4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Q4"
  },
  {
    "uniprot": "A0A0N4UGK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGK0"
  },
  {
    "uniprot": "A0A0N4UMY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMY1"
  },
  {
    "uniprot": "A0A0N4U6V1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6V1"
  },
  {
    "uniprot": "A0A0N4UE02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE02"
  },
  {
    "uniprot": "A0A3P7Q5Y7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q5Y7"
  },
  {
    "uniprot": "A0A158Q2V7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2V7"
  },
  {
    "uniprot": "A0A0N4UFN7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFN7"
  },
  {
    "uniprot": "A0A0N4U0P6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0P6"
  },
  {
    "uniprot": "A0A3P7P9T9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P9T9"
  },
  {
    "uniprot": "A0A0N4U314",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U314"
  },
  {
    "uniprot": "A0A3P7PXR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PXR4"
  },
  {
    "uniprot": "A0A0N4U943",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U943"
  },
  {
    "uniprot": "A0A0N4US46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US46"
  },
  {
    "uniprot": "A0A158Q5F5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5F5"
  },
  {
    "uniprot": "A0A0N4UAD4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAD4"
  },
  {
    "uniprot": "A0A0N4UMW6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMW6"
  },
  {
    "uniprot": "A0A0N4U6X3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6X3"
  },
  {
    "uniprot": "A0A0N4U3Z9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Z9"
  },
  {
    "uniprot": "A0A158Q6C4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6C4"
  },
  {
    "uniprot": "A0A0N4ULC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULC0"
  },
  {
    "uniprot": "A0A0N4UIH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIH9"
  },
  {
    "uniprot": "A0A0N4UDW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDW8"
  },
  {
    "uniprot": "A0A158Q4E2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4E2"
  },
  {
    "uniprot": "A0A0N4U3Y2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Y2"
  },
  {
    "uniprot": "A0A0N4USA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USA4"
  },
  {
    "uniprot": "A0A0N4U9P4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9P4"
  },
  {
    "uniprot": "A0A0N4UQN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQN2"
  },
  {
    "uniprot": "A0A0N4U4N0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4N0"
  },
  {
    "uniprot": "A0A0N4U4V8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4V8"
  },
  {
    "uniprot": "A0A158Q6G5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6G5"
  },
  {
    "uniprot": "A0A0N4U8U8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8U8"
  },
  {
    "uniprot": "A0A0N4UPR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPR9"
  },
  {
    "uniprot": "A0A0N4U8Z8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Z8"
  },
  {
    "uniprot": "A0A0N4UJA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJA0"
  },
  {
    "uniprot": "A0A0N4U5W9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5W9"
  },
  {
    "uniprot": "A0A0N4UFD1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFD1"
  },
  {
    "uniprot": "A0A0N4U8T0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8T0"
  },
  {
    "uniprot": "A0A0N4U570",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U570"
  },
  {
    "uniprot": "A0A0N4UFT2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFT2"
  },
  {
    "uniprot": "A0A158Q480",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q480"
  },
  {
    "uniprot": "A0A158Q3N4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3N4"
  },
  {
    "uniprot": "A0A0N4U6A9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6A9"
  },
  {
    "uniprot": "A0A0N4UJF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJF3"
  },
  {
    "uniprot": "A0A3P7PAR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PAR4"
  },
  {
    "uniprot": "A0A0N4UF96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF96"
  },
  {
    "uniprot": "A0A0N4U1D3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1D3"
  },
  {
    "uniprot": "A0A0N4U7D9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7D9"
  },
  {
    "uniprot": "A0A0N4UKA7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKA7"
  },
  {
    "uniprot": "A0A0N4UCB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCB5"
  },
  {
    "uniprot": "A0A0N4U7Q7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Q7"
  },
  {
    "uniprot": "A0A0N4UBB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBB1"
  },
  {
    "uniprot": "A0A0N4UMH8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMH8"
  },
  {
    "uniprot": "A0A0N4UNA3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNA3"
  },
  {
    "uniprot": "A0A0N4UGM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGM0"
  },
  {
    "uniprot": "A0A3P7SSX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SSX1"
  },
  {
    "uniprot": "A0A0N4ULI7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULI7"
  },
  {
    "uniprot": "A0A0N4UI97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI97"
  },
  {
    "uniprot": "A0A158Q5M2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5M2"
  },
  {
    "uniprot": "A0A0N4U139",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U139"
  },
  {
    "uniprot": "A0A158Q5M8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5M8"
  },
  {
    "uniprot": "A0A0N4U3B3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3B3"
  },
  {
    "uniprot": "A0A158Q3L9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3L9"
  },
  {
    "uniprot": "A0A0N4UEA3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEA3"
  },
  {
    "uniprot": "A0A158Q4H7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4H7"
  },
  {
    "uniprot": "A0A158Q4G7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4G7"
  },
  {
    "uniprot": "A0A0N4UFW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFW3"
  },
  {
    "uniprot": "A0A0N4U7D4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7D4"
  },
  {
    "uniprot": "A0A0N4U447",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U447"
  },
  {
    "uniprot": "A0A0N4URB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URB0"
  },
  {
    "uniprot": "A0A0N4U376",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U376"
  },
  {
    "uniprot": "A0A0N4UE22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE22"
  },
  {
    "uniprot": "A0A3P7QQP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QQP1"
  },
  {
    "uniprot": "A0A0N4U4Y8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Y8"
  },
  {
    "uniprot": "A0A0N4UA17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA17"
  },
  {
    "uniprot": "A0A0N4U8Q3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Q3"
  },
  {
    "uniprot": "A0A0N4U2Y1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Y1"
  },
  {
    "uniprot": "A0A0N4UHW2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHW2"
  },
  {
    "uniprot": "A0A0N4UM23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM23"
  },
  {
    "uniprot": "A0A158Q3E8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3E8"
  },
  {
    "uniprot": "A0A0N4ULT9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULT9"
  },
  {
    "uniprot": "A0A158Q6B4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6B4"
  },
  {
    "uniprot": "A0A0N4UDM9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDM9"
  },
  {
    "uniprot": "A0A0N4UF03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF03"
  },
  {
    "uniprot": "A0A0N4UPT3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPT3"
  },
  {
    "uniprot": "A0A0N4UHG8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHG8"
  },
  {
    "uniprot": "A0A0N4ULQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULQ5"
  },
  {
    "uniprot": "A0A0N4ULZ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULZ9"
  },
  {
    "uniprot": "A0A0N4UHH7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHH7"
  },
  {
    "uniprot": "A0A0N4U7P4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7P4"
  },
  {
    "uniprot": "A0A0N4UE59",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE59"
  },
  {
    "uniprot": "A0A0N4URE4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URE4"
  },
  {
    "uniprot": "A0A0N4UHV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHV6"
  },
  {
    "uniprot": "A0A0N4UGU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGU2"
  },
  {
    "uniprot": "A0A0N4UP74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP74"
  },
  {
    "uniprot": "A0A0N4U4G4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4G4"
  },
  {
    "uniprot": "A0A0N4UK17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK17"
  },
  {
    "uniprot": "A0A0N4U2Q5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Q5"
  },
  {
    "uniprot": "A0A0N4UEF8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEF8"
  },
  {
    "uniprot": "A0A0N4UH42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH42"
  },
  {
    "uniprot": "A0A3P7SYU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SYU2"
  },
  {
    "uniprot": "A0A0N4USC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USC6"
  },
  {
    "uniprot": "A0A0N4U7I7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7I7"
  },
  {
    "uniprot": "A0A158Q2W9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2W9"
  },
  {
    "uniprot": "A0A0N4UG35",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG35"
  },
  {
    "uniprot": "A0A3P7PY18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PY18"
  },
  {
    "uniprot": "A0A0N4UP99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP99"
  },
  {
    "uniprot": "A0A3P7P7R7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P7R7"
  },
  {
    "uniprot": "A0A0N4UQN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQN9"
  },
  {
    "uniprot": "A0A0N4UM89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM89"
  },
  {
    "uniprot": "A0A0N4U586",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U586"
  },
  {
    "uniprot": "A0A158Q3Y6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Y6"
  },
  {
    "uniprot": "A0A0N4U0S4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0S4"
  },
  {
    "uniprot": "A0A0N4U8J6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8J6"
  },
  {
    "uniprot": "A0A0N4UR20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR20"
  },
  {
    "uniprot": "A0A0N4U742",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U742"
  },
  {
    "uniprot": "A0A0N4U3Y8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Y8"
  },
  {
    "uniprot": "A0A0N4UA88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA88"
  },
  {
    "uniprot": "A0A0N4UH94",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH94"
  },
  {
    "uniprot": "A0A0N4U1M3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1M3"
  },
  {
    "uniprot": "A0A0N4UBG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBG3"
  },
  {
    "uniprot": "A0A0N4UCY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCY0"
  },
  {
    "uniprot": "A0A0N4UNC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNC0"
  },
  {
    "uniprot": "A0A0N4U1A2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1A2"
  },
  {
    "uniprot": "A0A0N4U233",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U233"
  },
  {
    "uniprot": "A0A0N4UKE5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKE5"
  },
  {
    "uniprot": "A0A0N4UM81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM81"
  },
  {
    "uniprot": "A0A0N4U6G5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6G5"
  },
  {
    "uniprot": "A0A0N4U622",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U622"
  },
  {
    "uniprot": "A0A0N4UQX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQX9"
  },
  {
    "uniprot": "A0A0N4UC85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC85"
  },
  {
    "uniprot": "A0A0N4UJ10",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ10"
  },
  {
    "uniprot": "A0A158Q3X0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3X0"
  },
  {
    "uniprot": "A0A0N4UAI5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAI5"
  },
  {
    "uniprot": "A0A0N4UD56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD56"
  },
  {
    "uniprot": "A0A0N4UCZ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCZ7"
  },
  {
    "uniprot": "A0A0N4UE40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE40"
  },
  {
    "uniprot": "A0A0N4UDF2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDF2"
  },
  {
    "uniprot": "A0A0N4UNZ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNZ6"
  },
  {
    "uniprot": "A0A0N4U4A5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4A5"
  },
  {
    "uniprot": "A0A0N4UD49",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD49"
  },
  {
    "uniprot": "A0A0N4UE94",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE94"
  },
  {
    "uniprot": "A0A0N4UP38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP38"
  },
  {
    "uniprot": "A0A0N4U4D7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4D7"
  },
  {
    "uniprot": "A0A0N4U865",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U865"
  },
  {
    "uniprot": "A0A0N4U5F9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5F9"
  },
  {
    "uniprot": "A0A0N4UJ45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ45"
  },
  {
    "uniprot": "A0A0N4UQF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQF3"
  },
  {
    "uniprot": "A0A0N4U343",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U343"
  },
  {
    "uniprot": "A0A0N4ULY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULY1"
  },
  {
    "uniprot": "A0A3P7Q2G4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q2G4"
  },
  {
    "uniprot": "A0A0N4UDX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDX9"
  },
  {
    "uniprot": "A0A0N4UPS0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPS0"
  },
  {
    "uniprot": "A0A3P7TDR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TDR6"
  },
  {
    "uniprot": "A0A0N4UIZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIZ5"
  },
  {
    "uniprot": "A0A0N4U1Y9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Y9"
  },
  {
    "uniprot": "A0A0N4U891",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U891"
  },
  {
    "uniprot": "A0A3P7SJ55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SJ55"
  },
  {
    "uniprot": "A0A0N4UBB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBB5"
  },
  {
    "uniprot": "A0A0N4UF83",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF83"
  },
  {
    "uniprot": "A0A0N4UDD8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDD8"
  },
  {
    "uniprot": "A0A0N4U0Q4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Q4"
  },
  {
    "uniprot": "A0A0N4U545",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U545"
  },
  {
    "uniprot": "A0A0N4U2R6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2R6"
  },
  {
    "uniprot": "A0A0N4UGM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGM8"
  },
  {
    "uniprot": "A0A0N4UKC8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKC8"
  },
  {
    "uniprot": "A0A0N4UA84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA84"
  },
  {
    "uniprot": "A0A0N4UQ93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ93"
  },
  {
    "uniprot": "A0A0N4U9K0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9K0"
  },
  {
    "uniprot": "A0A0N4UQN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQN3"
  },
  {
    "uniprot": "A0A0N4US44",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US44"
  },
  {
    "uniprot": "A0A0N4UAB8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAB8"
  },
  {
    "uniprot": "A0A0N4UAT8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAT8"
  },
  {
    "uniprot": "A0A0N4UMR8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMR8"
  },
  {
    "uniprot": "A0A3P7Q616",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q616"
  },
  {
    "uniprot": "A0A0N4U6R3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6R3"
  },
  {
    "uniprot": "A0A3P7PBI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PBI1"
  },
  {
    "uniprot": "A0A0N4UBC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBC5"
  },
  {
    "uniprot": "A0A0N4URV9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URV9"
  },
  {
    "uniprot": "A0A0N4UMW5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMW5"
  },
  {
    "uniprot": "A0A158Q628",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q628"
  },
  {
    "uniprot": "A0A0N4UJ78",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ78"
  },
  {
    "uniprot": "A0A0N4U0Q7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Q7"
  },
  {
    "uniprot": "A0A158Q6M1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6M1"
  },
  {
    "uniprot": "A0A0N4U2M3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2M3"
  },
  {
    "uniprot": "A0A0N4U4P2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4P2"
  },
  {
    "uniprot": "A0A3P7PSF6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PSF6"
  },
  {
    "uniprot": "A0A0N4UFA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFA8"
  },
  {
    "uniprot": "A0A158Q413",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q413"
  },
  {
    "uniprot": "A0A0N4U228",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U228"
  },
  {
    "uniprot": "A0A0N4URT3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URT3"
  },
  {
    "uniprot": "A0A3P7PGA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PGA9"
  },
  {
    "uniprot": "A0A158Q3V4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3V4"
  },
  {
    "uniprot": "A0A0N4U401",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U401"
  },
  {
    "uniprot": "A0A0N4UL39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL39"
  },
  {
    "uniprot": "A0A0N4UIZ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIZ3"
  },
  {
    "uniprot": "A0A0N4U8Y6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Y6"
  },
  {
    "uniprot": "A0A0N4U982",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U982"
  },
  {
    "uniprot": "A0A0N4U9Z4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Z4"
  },
  {
    "uniprot": "A0A0N4UNU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNU3"
  },
  {
    "uniprot": "A0A0N4UBE4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBE4"
  },
  {
    "uniprot": "A0A0N4UKB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKB9"
  },
  {
    "uniprot": "A0A0N4UEK9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEK9"
  },
  {
    "uniprot": "A0A0N4U5H6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5H6"
  },
  {
    "uniprot": "A0A0N4U2W3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2W3"
  },
  {
    "uniprot": "A0A0N4UMY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMY0"
  },
  {
    "uniprot": "A0A0N4U6X0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6X0"
  },
  {
    "uniprot": "A0A158Q2I3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2I3"
  },
  {
    "uniprot": "A0A3P7QI51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QI51"
  },
  {
    "uniprot": "A0A0N4U5M7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5M7"
  },
  {
    "uniprot": "A0A3P7PYM1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PYM1"
  },
  {
    "uniprot": "A0A3P7SIJ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SIJ3"
  },
  {
    "uniprot": "A0A0N4UKF2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKF2"
  },
  {
    "uniprot": "A0A0N4UFE2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFE2"
  },
  {
    "uniprot": "A0A0N4UHZ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHZ0"
  },
  {
    "uniprot": "A0A0N4U7Q9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Q9"
  },
  {
    "uniprot": "A0A0N4UJV0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJV0"
  },
  {
    "uniprot": "A0A0N4UK13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK13"
  },
  {
    "uniprot": "A0A0N4UGE8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGE8"
  },
  {
    "uniprot": "A0A0N4U4I4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4I4"
  },
  {
    "uniprot": "A0A0N4UJ40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ40"
  },
  {
    "uniprot": "A0A158Q585",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q585"
  },
  {
    "uniprot": "A0A158Q5C0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5C0"
  },
  {
    "uniprot": "A0A0N4UMN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMN0"
  },
  {
    "uniprot": "A0A0N4UGS1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGS1"
  },
  {
    "uniprot": "A0A0N4UBD5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBD5"
  },
  {
    "uniprot": "A0A0N4UCP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCP6"
  },
  {
    "uniprot": "A0A0N4UDM1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDM1"
  },
  {
    "uniprot": "A0A0N4USB4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USB4"
  },
  {
    "uniprot": "A0A0N4UPX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPX7"
  },
  {
    "uniprot": "A0A0N4UEP5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEP5"
  },
  {
    "uniprot": "A0A158Q3Y8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Y8"
  },
  {
    "uniprot": "A0A0N4ULZ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULZ7"
  },
  {
    "uniprot": "A0A3P7QCN4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QCN4"
  },
  {
    "uniprot": "A0A0N4U4D4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4D4"
  },
  {
    "uniprot": "A0A0N4U666",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U666"
  },
  {
    "uniprot": "A0A0N4UQH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQH6"
  },
  {
    "uniprot": "A0A0N4US09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US09"
  },
  {
    "uniprot": "A0A0N4UK67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK67"
  },
  {
    "uniprot": "A0A158Q3U2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3U2"
  },
  {
    "uniprot": "A0A0N4URR5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URR5"
  },
  {
    "uniprot": "A0A0N4UQA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQA0"
  },
  {
    "uniprot": "A0A0N4UFW7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFW7"
  },
  {
    "uniprot": "A0A0N4UBK4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBK4"
  },
  {
    "uniprot": "A0A0N4UHT3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHT3"
  },
  {
    "uniprot": "A0A0N4U9T6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9T6"
  },
  {
    "uniprot": "A0A158Q3D7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3D7"
  },
  {
    "uniprot": "A0A0N4UKP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKP1"
  },
  {
    "uniprot": "A0A0N4U679",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U679"
  },
  {
    "uniprot": "A0A0N4UR84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR84"
  },
  {
    "uniprot": "A0A0N4UN25",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN25"
  },
  {
    "uniprot": "A0A0N4URH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URH6"
  },
  {
    "uniprot": "A0A0N4UI66",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI66"
  },
  {
    "uniprot": "A0A158Q4U3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4U3"
  },
  {
    "uniprot": "A0A158Q5S5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5S5"
  },
  {
    "uniprot": "A0A0N4US14",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US14"
  },
  {
    "uniprot": "A0A0N4UA29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA29"
  },
  {
    "uniprot": "A0A0N4URY8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URY8"
  },
  {
    "uniprot": "A0A158Q3T2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3T2"
  },
  {
    "uniprot": "A0A0N4UIU8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIU8"
  },
  {
    "uniprot": "A0A0N4UF62",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF62"
  },
  {
    "uniprot": "A0A0N4U9E8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9E8"
  },
  {
    "uniprot": "A0A3P7SUI5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SUI5"
  },
  {
    "uniprot": "A0A0N4U9I3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9I3"
  },
  {
    "uniprot": "A0A0N4U1D7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1D7"
  },
  {
    "uniprot": "A0A0N4UD22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD22"
  },
  {
    "uniprot": "A0A3P7P9S4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P9S4"
  },
  {
    "uniprot": "A0A0N4UF35",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF35"
  },
  {
    "uniprot": "A0A0N4ULH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULH2"
  },
  {
    "uniprot": "A0A0N4UQQ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQQ9"
  },
  {
    "uniprot": "A0A0N4U638",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U638"
  },
  {
    "uniprot": "A0A158Q2W4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2W4"
  },
  {
    "uniprot": "A0A3P7PL61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PL61"
  },
  {
    "uniprot": "A0A0N4UJC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJC6"
  },
  {
    "uniprot": "A0A0N4UDL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDL9"
  },
  {
    "uniprot": "A0A158Q634",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q634"
  },
  {
    "uniprot": "A0A0N4UIM1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIM1"
  },
  {
    "uniprot": "A0A0N4UAF7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAF7"
  },
  {
    "uniprot": "A0A158Q456",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q456"
  },
  {
    "uniprot": "A0A0N4UBK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBK3"
  },
  {
    "uniprot": "A0A0N4UBN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBN3"
  },
  {
    "uniprot": "A0A3P7Q5L6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q5L6"
  },
  {
    "uniprot": "A0A0N4UCH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCH4"
  },
  {
    "uniprot": "A0A158Q5D3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5D3"
  },
  {
    "uniprot": "A0A3P7PSP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PSP0"
  },
  {
    "uniprot": "A0A0N4U5C9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5C9"
  },
  {
    "uniprot": "A0A0N4UD68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD68"
  },
  {
    "uniprot": "A0A0N4U744",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U744"
  },
  {
    "uniprot": "A0A0N4UE72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE72"
  },
  {
    "uniprot": "A0A0N4UBH5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBH5"
  },
  {
    "uniprot": "A0A0N4U6X9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6X9"
  },
  {
    "uniprot": "A0A158Q2Q7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Q7"
  },
  {
    "uniprot": "A0A0N4U3G2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3G2"
  },
  {
    "uniprot": "A0A0N4U0S9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0S9"
  },
  {
    "uniprot": "A0A0N4UAQ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAQ9"
  },
  {
    "uniprot": "A0A0N4UIG1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIG1"
  },
  {
    "uniprot": "A0A3P7SM22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SM22"
  },
  {
    "uniprot": "A0A0N4ULS2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULS2"
  },
  {
    "uniprot": "A0A0N4UIM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIM2"
  },
  {
    "uniprot": "A0A0N4UHT2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHT2"
  },
  {
    "uniprot": "A0A0N4UHM7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHM7"
  },
  {
    "uniprot": "A0A0N4UJK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJK3"
  },
  {
    "uniprot": "A0A0N4ULF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULF3"
  },
  {
    "uniprot": "A0A0N4UHD2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHD2"
  },
  {
    "uniprot": "A0A0N4ULS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULS3"
  },
  {
    "uniprot": "A0A0N4ULQ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULQ9"
  },
  {
    "uniprot": "A0A0N4URG8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URG8"
  },
  {
    "uniprot": "A0A0N4UPI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPI3"
  },
  {
    "uniprot": "A0A0N4UHC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHC6"
  },
  {
    "uniprot": "A0A0N4U883",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U883"
  },
  {
    "uniprot": "A0A0N4UEM9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEM9"
  },
  {
    "uniprot": "A0A0N4U6H9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6H9"
  },
  {
    "uniprot": "A0A0N4UL84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL84"
  },
  {
    "uniprot": "A0A0N4UBB8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBB8"
  },
  {
    "uniprot": "A0A0N4UKR3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKR3"
  },
  {
    "uniprot": "A0A158Q635",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q635"
  },
  {
    "uniprot": "A0A0N4UHF0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHF0"
  },
  {
    "uniprot": "A0A0N4UNC8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNC8"
  },
  {
    "uniprot": "A0A0N4UG01",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG01"
  },
  {
    "uniprot": "A0A0N4U988",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U988"
  },
  {
    "uniprot": "A0A0N4US53",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US53"
  },
  {
    "uniprot": "A0A0N4UDM5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDM5"
  },
  {
    "uniprot": "A0A0N4UMB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMB7"
  },
  {
    "uniprot": "A0A0N4URT4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URT4"
  },
  {
    "uniprot": "A0A0N4U619",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U619"
  },
  {
    "uniprot": "A0A158Q5S0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5S0"
  },
  {
    "uniprot": "A0A0N4UKV0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKV0"
  },
  {
    "uniprot": "A0A0N4U6U2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6U2"
  },
  {
    "uniprot": "A0A0N4U2H8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2H8"
  },
  {
    "uniprot": "A0A158Q3I5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3I5"
  },
  {
    "uniprot": "A0A3P7PNR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PNR9"
  },
  {
    "uniprot": "A0A0N4U8Q8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Q8"
  },
  {
    "uniprot": "A0A0N4UR96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR96"
  },
  {
    "uniprot": "A0A0N4U176",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U176"
  },
  {
    "uniprot": "A0A0N4UM58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM58"
  },
  {
    "uniprot": "A0A0N4UE18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE18"
  },
  {
    "uniprot": "A0A0N4USC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USC7"
  },
  {
    "uniprot": "A0A158Q624",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q624"
  },
  {
    "uniprot": "A0A0N4U5A4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5A4"
  },
  {
    "uniprot": "A0A3P7PU43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PU43"
  },
  {
    "uniprot": "A0A0N4U5Z8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Z8"
  },
  {
    "uniprot": "A0A0N4U1H0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1H0"
  },
  {
    "uniprot": "A0A3P7Q5T9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q5T9"
  },
  {
    "uniprot": "A0A0N4U7B8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7B8"
  },
  {
    "uniprot": "A0A0N4U7M5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7M5"
  },
  {
    "uniprot": "A0A0N4U0T2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0T2"
  },
  {
    "uniprot": "A0A0N4UKE0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKE0"
  },
  {
    "uniprot": "A0A0N4U690",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U690"
  },
  {
    "uniprot": "A0A158Q5E7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5E7"
  },
  {
    "uniprot": "A0A0N4U7D6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7D6"
  },
  {
    "uniprot": "A0A158Q4G0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4G0"
  },
  {
    "uniprot": "A0A0N4UGK8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGK8"
  },
  {
    "uniprot": "A0A0N4U636",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U636"
  },
  {
    "uniprot": "A0A0N4UDW2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDW2"
  },
  {
    "uniprot": "A0A0N4UNX3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNX3"
  },
  {
    "uniprot": "A0A0N4UNL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNL8"
  },
  {
    "uniprot": "A0A0N4U6R2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6R2"
  },
  {
    "uniprot": "A0A0N4UHX3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHX3"
  },
  {
    "uniprot": "A0A3P7PSY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PSY4"
  },
  {
    "uniprot": "A0A3P7PMS7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PMS7"
  },
  {
    "uniprot": "A0A0N4UNG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNG3"
  },
  {
    "uniprot": "A0A0N4UF08",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF08"
  },
  {
    "uniprot": "A0A158Q5U1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5U1"
  },
  {
    "uniprot": "A0A3P7T1J3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T1J3"
  },
  {
    "uniprot": "A0A0N4UE64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE64"
  },
  {
    "uniprot": "A0A0N4U451",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U451"
  },
  {
    "uniprot": "A0A0N4UMP8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMP8"
  },
  {
    "uniprot": "A0A0N4UNU0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNU0"
  },
  {
    "uniprot": "A0A0N4UE52",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE52"
  },
  {
    "uniprot": "A0A0N4U3F1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3F1"
  },
  {
    "uniprot": "A0A0N4ULB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULB5"
  },
  {
    "uniprot": "A0A0N4UIJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIJ9"
  },
  {
    "uniprot": "A0A0N4UB99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB99"
  },
  {
    "uniprot": "A0A0N4UHL2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHL2"
  },
  {
    "uniprot": "A0A0N4UQW6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQW6"
  },
  {
    "uniprot": "A0A0N4U5X9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5X9"
  },
  {
    "uniprot": "A0A0N4UL86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL86"
  },
  {
    "uniprot": "A0A158Q3D9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3D9"
  },
  {
    "uniprot": "A0A0N4U532",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U532"
  },
  {
    "uniprot": "A0A0N4UHQ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHQ8"
  },
  {
    "uniprot": "A0A3P7Q7X4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q7X4"
  },
  {
    "uniprot": "A0A3P7SKR5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SKR5"
  },
  {
    "uniprot": "A0A158Q655",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q655"
  },
  {
    "uniprot": "A0A0N4U3V3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3V3"
  },
  {
    "uniprot": "A0A0N4UHT7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHT7"
  },
  {
    "uniprot": "A0A0N4UP98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP98"
  },
  {
    "uniprot": "A0A0N4U623",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U623"
  },
  {
    "uniprot": "A0A0N4U1K9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1K9"
  },
  {
    "uniprot": "A0A0N4UMR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMR2"
  },
  {
    "uniprot": "A0A0N4UMA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMA8"
  },
  {
    "uniprot": "A0A0N4ULF6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULF6"
  },
  {
    "uniprot": "A0A3P7Q1U5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q1U5"
  },
  {
    "uniprot": "A0A0N4U754",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U754"
  },
  {
    "uniprot": "A0A0N4U3L8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3L8"
  },
  {
    "uniprot": "A0A0N4UNH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNH3"
  },
  {
    "uniprot": "A0A0N4U560",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U560"
  },
  {
    "uniprot": "A0A0N4U3I2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3I2"
  },
  {
    "uniprot": "A0A158Q6N5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6N5"
  },
  {
    "uniprot": "A0A158Q5V9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5V9"
  },
  {
    "uniprot": "A0A0N4UDK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDK1"
  },
  {
    "uniprot": "A0A0N4U9M0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9M0"
  },
  {
    "uniprot": "A0A0N4UGZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGZ1"
  },
  {
    "uniprot": "A0A0N4UPB6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPB6"
  },
  {
    "uniprot": "A0A0N4UIU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIU3"
  },
  {
    "uniprot": "A0A0N4UNQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNQ7"
  },
  {
    "uniprot": "A0A3P7SC82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SC82"
  },
  {
    "uniprot": "A0A158Q4R2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4R2"
  },
  {
    "uniprot": "A0A0N4U0M3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0M3"
  },
  {
    "uniprot": "A0A0N4UJB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJB7"
  },
  {
    "uniprot": "A0A0N4U5W4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5W4"
  },
  {
    "uniprot": "A0A0N4U398",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U398"
  },
  {
    "uniprot": "A0A0N4UQ74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ74"
  },
  {
    "uniprot": "A0A0N4UMF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMF9"
  },
  {
    "uniprot": "A0A0N4URD3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URD3"
  },
  {
    "uniprot": "A0A0N4UM75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM75"
  },
  {
    "uniprot": "A0A3P7T2E2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T2E2"
  },
  {
    "uniprot": "A0A0N4UI68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI68"
  },
  {
    "uniprot": "A0A158Q452",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q452"
  },
  {
    "uniprot": "A0A0N4U1T3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1T3"
  },
  {
    "uniprot": "A0A0N4UQ67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ67"
  },
  {
    "uniprot": "A0A0N4URQ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URQ0"
  },
  {
    "uniprot": "A0A0N4UEW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEW0"
  },
  {
    "uniprot": "A0A0N4UL29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL29"
  },
  {
    "uniprot": "A0A0N4U5N6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5N6"
  },
  {
    "uniprot": "A0A0N4UFL7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFL7"
  },
  {
    "uniprot": "A0A0N4U2S0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2S0"
  },
  {
    "uniprot": "A0A0N4UMG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMG3"
  },
  {
    "uniprot": "A0A0N4UKV2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKV2"
  },
  {
    "uniprot": "A0A3P7PKH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PKH1"
  },
  {
    "uniprot": "A0A0N4UD01",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD01"
  },
  {
    "uniprot": "A0A0N4UM99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM99"
  },
  {
    "uniprot": "A0A0N4UB02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB02"
  },
  {
    "uniprot": "A0A0N4UPB8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPB8"
  },
  {
    "uniprot": "A0A0N4URS7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URS7"
  },
  {
    "uniprot": "A0A0N4ULD9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULD9"
  },
  {
    "uniprot": "A0A158Q4J9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4J9"
  },
  {
    "uniprot": "A0A0N4U1T9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1T9"
  },
  {
    "uniprot": "A0A158Q5D6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5D6"
  },
  {
    "uniprot": "A0A0N4UAT2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAT2"
  },
  {
    "uniprot": "A0A0N4U1R2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1R2"
  },
  {
    "uniprot": "A0A0N4UA96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA96"
  },
  {
    "uniprot": "A0A0N4U4A4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4A4"
  },
  {
    "uniprot": "A0A3P7Q1A9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q1A9"
  },
  {
    "uniprot": "A0A158Q2W7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2W7"
  },
  {
    "uniprot": "A0A0N4UA02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA02"
  },
  {
    "uniprot": "A0A0N4U1H3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1H3"
  },
  {
    "uniprot": "A0A0N4UC26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC26"
  },
  {
    "uniprot": "A0A0N4UKK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKK6"
  },
  {
    "uniprot": "A0A0N4UFR8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFR8"
  },
  {
    "uniprot": "A0A0N4UDH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDH6"
  },
  {
    "uniprot": "A0A3P7SML5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SML5"
  },
  {
    "uniprot": "A0A158Q5T0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5T0"
  },
  {
    "uniprot": "A0A0N4UJ70",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ70"
  },
  {
    "uniprot": "A0A0N4UMF7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMF7"
  },
  {
    "uniprot": "A0A3P7Q1J0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q1J0"
  },
  {
    "uniprot": "A0A0N4UDJ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDJ5"
  },
  {
    "uniprot": "A0A3P7Q256",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q256"
  },
  {
    "uniprot": "A0A0N4UF26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF26"
  },
  {
    "uniprot": "A0A0N4UMS1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMS1"
  },
  {
    "uniprot": "A0A0N4U7K0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7K0"
  },
  {
    "uniprot": "A0A0N4U6A4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6A4"
  },
  {
    "uniprot": "A0A0N4UHJ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHJ8"
  },
  {
    "uniprot": "A0A0N4UF57",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF57"
  },
  {
    "uniprot": "A0A3P7PCB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PCB1"
  },
  {
    "uniprot": "A0A0N4U373",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U373"
  },
  {
    "uniprot": "A0A0N4U307",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U307"
  },
  {
    "uniprot": "A0A0N4U916",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U916"
  },
  {
    "uniprot": "A0A158Q575",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q575"
  },
  {
    "uniprot": "A0A0N4U0R5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0R5"
  },
  {
    "uniprot": "A0A0N4UJZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJZ5"
  },
  {
    "uniprot": "A0A0N4UHF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHF5"
  },
  {
    "uniprot": "A0A0N4UE80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE80"
  },
  {
    "uniprot": "A0A0N4URE2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URE2"
  },
  {
    "uniprot": "A0A0N4UPG1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPG1"
  },
  {
    "uniprot": "A0A0N4UMH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMH2"
  },
  {
    "uniprot": "A0A0N4UE20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE20"
  },
  {
    "uniprot": "A0A0N4UGP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGP9"
  },
  {
    "uniprot": "A0A0N4URE6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URE6"
  },
  {
    "uniprot": "A0A0N4U2T5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2T5"
  },
  {
    "uniprot": "A0A0N4UGU0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGU0"
  },
  {
    "uniprot": "A0A0N4UBW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBW8"
  },
  {
    "uniprot": "A0A3P7PW37",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PW37"
  },
  {
    "uniprot": "A0A0N4UI84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI84"
  },
  {
    "uniprot": "A0A3P7SQU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SQU3"
  },
  {
    "uniprot": "A0A0N4UN24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN24"
  },
  {
    "uniprot": "A0A0N4U517",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U517"
  },
  {
    "uniprot": "A0A158Q4X9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4X9"
  },
  {
    "uniprot": "A0A0N4UJV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJV6"
  },
  {
    "uniprot": "A0A3P7PZ06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PZ06"
  },
  {
    "uniprot": "A0A0N4U809",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U809"
  },
  {
    "uniprot": "A0A0N4U1F4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1F4"
  },
  {
    "uniprot": "A0A0N4ULK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULK2"
  },
  {
    "uniprot": "A0A0N4UNG5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNG5"
  },
  {
    "uniprot": "A0A0N4U2A2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2A2"
  },
  {
    "uniprot": "A0A0N4UKY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKY0"
  },
  {
    "uniprot": "A0A0N4UPI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPI1"
  },
  {
    "uniprot": "A0A0N4UAM9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAM9"
  },
  {
    "uniprot": "A0A0N4U6T4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6T4"
  },
  {
    "uniprot": "A0A0N4UKM5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKM5"
  },
  {
    "uniprot": "A0A0N4U0D9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0D9"
  },
  {
    "uniprot": "A0A0N4UPC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPC4"
  },
  {
    "uniprot": "A0A158Q311",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q311"
  },
  {
    "uniprot": "A0A158Q4C5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4C5"
  },
  {
    "uniprot": "A0A0N4UEP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEP0"
  },
  {
    "uniprot": "A0A0N4UJ99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ99"
  },
  {
    "uniprot": "A0A0N4UM34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM34"
  },
  {
    "uniprot": "A0A158Q5C6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5C6"
  },
  {
    "uniprot": "A0A0N4UQS2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQS2"
  },
  {
    "uniprot": "A0A0N4UA33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA33"
  },
  {
    "uniprot": "A0A0N4UMY5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMY5"
  },
  {
    "uniprot": "A0A0N4UN75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN75"
  },
  {
    "uniprot": "A0A0N4U1Z3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Z3"
  },
  {
    "uniprot": "A0A0N4U8X7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8X7"
  },
  {
    "uniprot": "A0A0N4UP88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP88"
  },
  {
    "uniprot": "A0A0N4UMB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMB2"
  },
  {
    "uniprot": "A0A0N4UHE7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHE7"
  },
  {
    "uniprot": "A0A0N4UQ65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ65"
  },
  {
    "uniprot": "A0A0N4UKJ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKJ0"
  },
  {
    "uniprot": "A0A0N4UKQ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKQ8"
  },
  {
    "uniprot": "A0A158Q689",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q689"
  },
  {
    "uniprot": "A0A0N4UL32",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL32"
  },
  {
    "uniprot": "A0A0N4U393",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U393"
  },
  {
    "uniprot": "A0A0N4UN16",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN16"
  },
  {
    "uniprot": "A0A0N4UI46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI46"
  },
  {
    "uniprot": "A0A0N4UF77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF77"
  },
  {
    "uniprot": "A0A158Q2H5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2H5"
  },
  {
    "uniprot": "A0A0N4UJM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJM8"
  },
  {
    "uniprot": "A0A0N4UK51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK51"
  },
  {
    "uniprot": "A0A3P7T718",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T718"
  },
  {
    "uniprot": "A0A0N4U6G8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6G8"
  },
  {
    "uniprot": "A0A0N4UID9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UID9"
  },
  {
    "uniprot": "A0A3P7Q420",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q420"
  },
  {
    "uniprot": "A0A0N4UMP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMP9"
  },
  {
    "uniprot": "A0A0N4UET4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UET4"
  },
  {
    "uniprot": "A0A0N4U1K5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1K5"
  },
  {
    "uniprot": "A0A0N4ULV9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULV9"
  },
  {
    "uniprot": "A0A0N4UE28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE28"
  },
  {
    "uniprot": "A0A0N4U122",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U122"
  },
  {
    "uniprot": "A0A3P7PYS4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PYS4"
  },
  {
    "uniprot": "A0A0N4UG04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG04"
  },
  {
    "uniprot": "A0A158Q4J4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4J4"
  },
  {
    "uniprot": "A0A0N4U6Q2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Q2"
  },
  {
    "uniprot": "A0A158Q3T1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3T1"
  },
  {
    "uniprot": "A0A0N4U7G3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7G3"
  },
  {
    "uniprot": "A0A0N4UPA3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPA3"
  },
  {
    "uniprot": "A0A0N4UJM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJM0"
  },
  {
    "uniprot": "A0A0N4UQ31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ31"
  },
  {
    "uniprot": "A0A158Q547",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q547"
  },
  {
    "uniprot": "A0A0N4UBZ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBZ2"
  },
  {
    "uniprot": "A0A0N4U6G1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6G1"
  },
  {
    "uniprot": "A0A0N4U8Q9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Q9"
  },
  {
    "uniprot": "A0A0N4UFT3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFT3"
  },
  {
    "uniprot": "A0A0N4UNH0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNH0"
  },
  {
    "uniprot": "A0A0N4UQL6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQL6"
  },
  {
    "uniprot": "A0A158Q622",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q622"
  },
  {
    "uniprot": "A0A3P7PXS5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PXS5"
  },
  {
    "uniprot": "A0A0N4UFN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFN3"
  },
  {
    "uniprot": "A0A0N4UIM4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIM4"
  },
  {
    "uniprot": "A0A0N4UIE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIE3"
  },
  {
    "uniprot": "A0A0N4U7D3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7D3"
  },
  {
    "uniprot": "A0A158Q2R1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2R1"
  },
  {
    "uniprot": "A0A0N4UIX0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIX0"
  },
  {
    "uniprot": "A0A3P7S7P3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S7P3"
  },
  {
    "uniprot": "A0A3P7TBZ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TBZ0"
  },
  {
    "uniprot": "A0A0N4UQP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQP4"
  },
  {
    "uniprot": "A0A158Q4R7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4R7"
  },
  {
    "uniprot": "A0A0N4U752",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U752"
  },
  {
    "uniprot": "A0A0N4UAI8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAI8"
  },
  {
    "uniprot": "A0A3P7Q092",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q092"
  },
  {
    "uniprot": "A0A0N4U7G4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7G4"
  },
  {
    "uniprot": "A0A0N4U2T6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2T6"
  },
  {
    "uniprot": "A0A0N4UQF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQF5"
  },
  {
    "uniprot": "A0A0N4U6Z2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Z2"
  },
  {
    "uniprot": "A0A0N4UJZ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJZ3"
  },
  {
    "uniprot": "A0A158Q2K4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2K4"
  },
  {
    "uniprot": "A0A0N4U293",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U293"
  },
  {
    "uniprot": "A0A0N4UH25",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH25"
  },
  {
    "uniprot": "A0A0N4U3Y5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Y5"
  },
  {
    "uniprot": "A0A158Q5C3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5C3"
  },
  {
    "uniprot": "A0A0N4UKZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKZ5"
  },
  {
    "uniprot": "A0A0N4UKJ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKJ8"
  },
  {
    "uniprot": "A0A0N4UC99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC99"
  },
  {
    "uniprot": "A0A0N4U389",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U389"
  },
  {
    "uniprot": "A0A0N4UPL7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPL7"
  },
  {
    "uniprot": "A0A0N4U3H5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3H5"
  },
  {
    "uniprot": "A0A0N4U8L1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8L1"
  },
  {
    "uniprot": "A0A0N4UAN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAN9"
  },
  {
    "uniprot": "A0A3P7T1I1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T1I1"
  },
  {
    "uniprot": "A0A0N4U1H4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1H4"
  },
  {
    "uniprot": "A0A0N4UHL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHL9"
  },
  {
    "uniprot": "A0A0N4UFN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFN0"
  },
  {
    "uniprot": "A0A0N4UB87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB87"
  },
  {
    "uniprot": "A0A0N4UCN5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCN5"
  },
  {
    "uniprot": "A0A0N4UQ24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ24"
  },
  {
    "uniprot": "A0A0N4UCR5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCR5"
  },
  {
    "uniprot": "A0A0N4U6M7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6M7"
  },
  {
    "uniprot": "A0A0N4UQ29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ29"
  },
  {
    "uniprot": "A0A0N4U0L6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0L6"
  },
  {
    "uniprot": "A0A0N4UL15",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL15"
  },
  {
    "uniprot": "A0A0N4UEK8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEK8"
  },
  {
    "uniprot": "A0A0N4U559",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U559"
  },
  {
    "uniprot": "A0A3P7PK51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PK51"
  },
  {
    "uniprot": "A0A158Q6G9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6G9"
  },
  {
    "uniprot": "A0A0N4U491",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U491"
  },
  {
    "uniprot": "A0A0N4U0R3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0R3"
  },
  {
    "uniprot": "A0A158Q391",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q391"
  },
  {
    "uniprot": "A0A0N4UPS8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPS8"
  },
  {
    "uniprot": "A0A0N4UNV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNV7"
  },
  {
    "uniprot": "A0A0N4UPH8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPH8"
  },
  {
    "uniprot": "A0A158Q5H7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5H7"
  },
  {
    "uniprot": "A0A0N4U692",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U692"
  },
  {
    "uniprot": "A0A0N4U9Z3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Z3"
  },
  {
    "uniprot": "A0A0N4U9N9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9N9"
  },
  {
    "uniprot": "A0A0N4UIW6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIW6"
  },
  {
    "uniprot": "A0A0N4U4R2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4R2"
  },
  {
    "uniprot": "A0A0N4UPE1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPE1"
  },
  {
    "uniprot": "A0A0N4U4C5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4C5"
  },
  {
    "uniprot": "A0A3P7SYR0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SYR0"
  },
  {
    "uniprot": "A0A0N4U0P4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0P4"
  },
  {
    "uniprot": "A0A0N4U3C1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3C1"
  },
  {
    "uniprot": "A0A158Q3H2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3H2"
  },
  {
    "uniprot": "A0A0N4UKU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKU7"
  },
  {
    "uniprot": "A0A0N4UCD3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCD3"
  },
  {
    "uniprot": "A0A158Q589",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q589"
  },
  {
    "uniprot": "A0A0N4UDC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDC7"
  },
  {
    "uniprot": "A0A3P7QBC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QBC4"
  },
  {
    "uniprot": "A0A0N4UEG5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEG5"
  },
  {
    "uniprot": "A0A0N4UHF8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHF8"
  },
  {
    "uniprot": "A0A0N4ULD1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULD1"
  },
  {
    "uniprot": "A0A0N4ULD6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULD6"
  },
  {
    "uniprot": "A0A0N4UQB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQB9"
  },
  {
    "uniprot": "A0A0N4U7W9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7W9"
  },
  {
    "uniprot": "A0A0N4UN96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN96"
  },
  {
    "uniprot": "A0A3P7Q5H9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q5H9"
  },
  {
    "uniprot": "A0A158Q4N3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4N3"
  },
  {
    "uniprot": "A0A0N4UDE9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDE9"
  },
  {
    "uniprot": "A0A0N4UD57",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD57"
  },
  {
    "uniprot": "A0A0N4UNY6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNY6"
  },
  {
    "uniprot": "A0A0N4U8K9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8K9"
  },
  {
    "uniprot": "A0A158Q5B1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5B1"
  },
  {
    "uniprot": "A0A0N4UJB6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJB6"
  },
  {
    "uniprot": "A0A0N4UEI5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEI5"
  },
  {
    "uniprot": "A0A0N4UGX3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGX3"
  },
  {
    "uniprot": "A0A0N4U9W4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9W4"
  },
  {
    "uniprot": "A0A0N4UAR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAR1"
  },
  {
    "uniprot": "A0A0N4UR65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR65"
  },
  {
    "uniprot": "A0A0N4ULQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULQ6"
  },
  {
    "uniprot": "A0A158Q592",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q592"
  },
  {
    "uniprot": "A0A0N4UJD1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJD1"
  },
  {
    "uniprot": "A0A0N4UEQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEQ5"
  },
  {
    "uniprot": "A0A0N4UJ41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ41"
  },
  {
    "uniprot": "A0A158Q6F5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6F5"
  },
  {
    "uniprot": "A0A0N4U126",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U126"
  },
  {
    "uniprot": "A0A0N4U3S6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3S6"
  },
  {
    "uniprot": "A0A0N4ULH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULH9"
  },
  {
    "uniprot": "A0A3P7QRG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QRG2"
  },
  {
    "uniprot": "A0A0N4U8G6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8G6"
  },
  {
    "uniprot": "A0A0N4UH76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH76"
  },
  {
    "uniprot": "A0A0N4UGJ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGJ8"
  },
  {
    "uniprot": "A0A0N4U1N5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1N5"
  },
  {
    "uniprot": "A0A158Q5V8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5V8"
  },
  {
    "uniprot": "A0A0N4UNH5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNH5"
  },
  {
    "uniprot": "A0A0N4UQI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQI3"
  },
  {
    "uniprot": "A0A0N4US47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US47"
  },
  {
    "uniprot": "A0A158Q6D0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6D0"
  },
  {
    "uniprot": "A0A0N4U3U1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3U1"
  },
  {
    "uniprot": "A0A0N4U6I8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6I8"
  },
  {
    "uniprot": "A0A0N4UP65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP65"
  },
  {
    "uniprot": "A0A3P7PLL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PLL0"
  },
  {
    "uniprot": "A0A0N4US75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US75"
  },
  {
    "uniprot": "A0A158Q313",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q313"
  },
  {
    "uniprot": "A0A0N4U8B8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8B8"
  },
  {
    "uniprot": "A0A0N4U4M3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4M3"
  },
  {
    "uniprot": "A0A0N4UQ43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ43"
  },
  {
    "uniprot": "A0A3P7PRX4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PRX4"
  },
  {
    "uniprot": "A0A0N4U651",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U651"
  },
  {
    "uniprot": "A0A0N4URG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URG3"
  },
  {
    "uniprot": "A0A0N4UJ27",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ27"
  },
  {
    "uniprot": "A0A0N4U9C5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9C5"
  },
  {
    "uniprot": "A0A3P7PSI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PSI0"
  },
  {
    "uniprot": "A0A0N4UDB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDB7"
  },
  {
    "uniprot": "A0A0N4U6V5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6V5"
  },
  {
    "uniprot": "A0A0N4UCA3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCA3"
  },
  {
    "uniprot": "A0A158Q4Q8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Q8"
  },
  {
    "uniprot": "A0A0N4U380",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U380"
  },
  {
    "uniprot": "A0A0N4UMA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMA9"
  },
  {
    "uniprot": "A0A158Q6I4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6I4"
  },
  {
    "uniprot": "A0A0N4U705",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U705"
  },
  {
    "uniprot": "A0A0N4UC84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC84"
  },
  {
    "uniprot": "A0A0N4UGA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGA6"
  },
  {
    "uniprot": "A0A0N4UD86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD86"
  },
  {
    "uniprot": "A0A0N4UDI4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDI4"
  },
  {
    "uniprot": "A0A0N4ULW6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULW6"
  },
  {
    "uniprot": "A0A0N4UJ77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ77"
  },
  {
    "uniprot": "A0A0N4U0K8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0K8"
  },
  {
    "uniprot": "A0A158Q3X5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3X5"
  },
  {
    "uniprot": "A0A0N4U1P3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1P3"
  },
  {
    "uniprot": "A0A0N4URK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URK5"
  },
  {
    "uniprot": "A0A3P7SAJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SAJ4"
  },
  {
    "uniprot": "A0A158Q5E6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5E6"
  },
  {
    "uniprot": "A0A0N4UC27",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC27"
  },
  {
    "uniprot": "A0A0N4UHY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHY1"
  },
  {
    "uniprot": "A0A0N4UIY3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIY3"
  },
  {
    "uniprot": "A0A0N4UC39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC39"
  },
  {
    "uniprot": "A0A0N4U5A1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5A1"
  },
  {
    "uniprot": "A0A0N4UMB8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMB8"
  },
  {
    "uniprot": "A0A0N4UGR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGR6"
  },
  {
    "uniprot": "A0A0N4UI27",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI27"
  },
  {
    "uniprot": "A0A0N4UN57",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN57"
  },
  {
    "uniprot": "A0A0N4UR75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR75"
  },
  {
    "uniprot": "A0A0N4U4U0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4U0"
  },
  {
    "uniprot": "A0A0N4UEM4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEM4"
  },
  {
    "uniprot": "A0A0N4U2L6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2L6"
  },
  {
    "uniprot": "A0A158Q2H4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2H4"
  },
  {
    "uniprot": "A0A0N4U0N6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0N6"
  },
  {
    "uniprot": "A0A0N4USD2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USD2"
  },
  {
    "uniprot": "A0A0N4UER8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UER8"
  },
  {
    "uniprot": "A0A0N4U0U0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0U0"
  },
  {
    "uniprot": "A0A0N4UIF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIF5"
  },
  {
    "uniprot": "A0A0N4U626",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U626"
  },
  {
    "uniprot": "A0A0N4U1A7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1A7"
  },
  {
    "uniprot": "A0A0N4UKP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKP0"
  },
  {
    "uniprot": "A0A3P7Q1L1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q1L1"
  },
  {
    "uniprot": "A0A0N4UIX6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIX6"
  },
  {
    "uniprot": "A0A0N4U9B5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9B5"
  },
  {
    "uniprot": "A0A3P7Q9V6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q9V6"
  },
  {
    "uniprot": "A0A158Q2U7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2U7"
  },
  {
    "uniprot": "A0A3P7PM02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PM02"
  },
  {
    "uniprot": "A0A0N4U6F0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6F0"
  },
  {
    "uniprot": "A0A0N4U869",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U869"
  },
  {
    "uniprot": "A0A0N4U9Y7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Y7"
  },
  {
    "uniprot": "A0A0N4UJH7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJH7"
  },
  {
    "uniprot": "A0A158Q4S5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4S5"
  },
  {
    "uniprot": "A0A0N4U1I4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1I4"
  },
  {
    "uniprot": "A0A0N4U9R6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9R6"
  },
  {
    "uniprot": "A0A0N4UM42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM42"
  },
  {
    "uniprot": "A0A0N4ULZ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULZ2"
  },
  {
    "uniprot": "A0A3P7PYC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PYC3"
  },
  {
    "uniprot": "A0A0N4UB22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB22"
  },
  {
    "uniprot": "A0A0N4UA89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA89"
  },
  {
    "uniprot": "A0A0N4UFH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFH6"
  },
  {
    "uniprot": "A0A0N4UAJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAJ9"
  },
  {
    "uniprot": "A0A0N4UQX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQX7"
  },
  {
    "uniprot": "A0A0N4UQS0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQS0"
  },
  {
    "uniprot": "A0A0N4U3I0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3I0"
  },
  {
    "uniprot": "A0A0N4UG46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG46"
  },
  {
    "uniprot": "A0A0N4U4I5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4I5"
  },
  {
    "uniprot": "A0A0N4UFE6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFE6"
  },
  {
    "uniprot": "A0A0N4U938",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U938"
  },
  {
    "uniprot": "A0A158Q5A7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5A7"
  },
  {
    "uniprot": "A0A158Q356",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q356"
  },
  {
    "uniprot": "A0A158Q367",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q367"
  },
  {
    "uniprot": "A0A0N4UQ89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ89"
  },
  {
    "uniprot": "A0A0N4U196",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U196"
  },
  {
    "uniprot": "A0A3P7SXB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SXB2"
  },
  {
    "uniprot": "A0A0N4UQP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQP3"
  },
  {
    "uniprot": "A0A0N4U140",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U140"
  },
  {
    "uniprot": "A0A0N4UNP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNP9"
  },
  {
    "uniprot": "A0A0N4U9D3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9D3"
  },
  {
    "uniprot": "A0A0N4UQF8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQF8"
  },
  {
    "uniprot": "A0A0N4U9E4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9E4"
  },
  {
    "uniprot": "A0A0N4UC56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC56"
  },
  {
    "uniprot": "A0A3P7PLL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PLL8"
  },
  {
    "uniprot": "A0A0N4U8D7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8D7"
  },
  {
    "uniprot": "A0A3P7SW90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SW90"
  },
  {
    "uniprot": "A0A0N4UGN7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGN7"
  },
  {
    "uniprot": "A0A0N4U0K2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0K2"
  },
  {
    "uniprot": "A0A0N4UK20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK20"
  },
  {
    "uniprot": "A0A3P7Q2E7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q2E7"
  },
  {
    "uniprot": "A0A0N4U653",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U653"
  },
  {
    "uniprot": "A0A0N4U2U2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2U2"
  },
  {
    "uniprot": "A0A0N4U1E7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1E7"
  },
  {
    "uniprot": "A0A0N4UE05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE05"
  },
  {
    "uniprot": "A0A0N4UP04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP04"
  },
  {
    "uniprot": "A0A0N4U9A8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9A8"
  },
  {
    "uniprot": "A0A0N4UH35",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH35"
  },
  {
    "uniprot": "A0A0N4U5B8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5B8"
  },
  {
    "uniprot": "A0A3P7SPQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SPQ6"
  },
  {
    "uniprot": "A0A158Q444",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q444"
  },
  {
    "uniprot": "A0A0N4UPM5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPM5"
  },
  {
    "uniprot": "A0A0N4UC36",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC36"
  },
  {
    "uniprot": "A0A158Q653",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q653"
  },
  {
    "uniprot": "A0A158Q2I9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2I9"
  },
  {
    "uniprot": "A0A0N4UF58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF58"
  },
  {
    "uniprot": "A0A0N4ULJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULJ1"
  },
  {
    "uniprot": "A0A0N4UFV0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFV0"
  },
  {
    "uniprot": "A0A0N4U803",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U803"
  },
  {
    "uniprot": "A0A158Q2K3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2K3"
  },
  {
    "uniprot": "A0A158Q526",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q526"
  },
  {
    "uniprot": "A0A0N4UEN7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEN7"
  },
  {
    "uniprot": "A0A158Q4W4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4W4"
  },
  {
    "uniprot": "A0A0N4UKD2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKD2"
  },
  {
    "uniprot": "A0A0N4UJ31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ31"
  },
  {
    "uniprot": "A0A158Q5C8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5C8"
  },
  {
    "uniprot": "A0A0N4UHI8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHI8"
  },
  {
    "uniprot": "A0A0N4UDY3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDY3"
  },
  {
    "uniprot": "A0A158Q2V1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2V1"
  },
  {
    "uniprot": "A0A0N4UES3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UES3"
  },
  {
    "uniprot": "A0A0N4UG70",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG70"
  },
  {
    "uniprot": "A0A0N4U8M1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8M1"
  },
  {
    "uniprot": "A0A0N4U9T8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9T8"
  },
  {
    "uniprot": "A0A0N4URX2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URX2"
  },
  {
    "uniprot": "A0A0N4UJ84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ84"
  },
  {
    "uniprot": "A0A0N4UQY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQY0"
  },
  {
    "uniprot": "A0A0N4U2V3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2V3"
  },
  {
    "uniprot": "A0A158Q6I1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6I1"
  },
  {
    "uniprot": "A0A3P7Q8R4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q8R4"
  },
  {
    "uniprot": "A0A3P7SNZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SNZ4"
  },
  {
    "uniprot": "A0A3P7SJZ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SJZ6"
  },
  {
    "uniprot": "A0A0N4UGY6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGY6"
  },
  {
    "uniprot": "A0A0N4UHV4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHV4"
  },
  {
    "uniprot": "A0A0N4UPL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPL3"
  },
  {
    "uniprot": "A0A158Q2Q6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Q6"
  },
  {
    "uniprot": "A0A0N4U4U8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4U8"
  },
  {
    "uniprot": "A0A158Q5U7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5U7"
  },
  {
    "uniprot": "A0A0N4UL28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL28"
  },
  {
    "uniprot": "A0A0N4UG06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG06"
  },
  {
    "uniprot": "A0A3P7PP63",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PP63"
  },
  {
    "uniprot": "A0A158Q357",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q357"
  },
  {
    "uniprot": "A0A0N4UEC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEC5"
  },
  {
    "uniprot": "A0A0N4UD59",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD59"
  },
  {
    "uniprot": "A0A0N4UCP5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCP5"
  },
  {
    "uniprot": "A0A0N4UNF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNF5"
  },
  {
    "uniprot": "A0A158Q5E3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5E3"
  },
  {
    "uniprot": "A0A0N4U6F2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6F2"
  },
  {
    "uniprot": "A0A158Q5Y4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Y4"
  },
  {
    "uniprot": "A0A0N4UAU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAU7"
  },
  {
    "uniprot": "A0A0N4U5W5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5W5"
  },
  {
    "uniprot": "A0A0N4UFY8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFY8"
  },
  {
    "uniprot": "A0A0N4UGK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGK6"
  },
  {
    "uniprot": "A0A0N4U724",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U724"
  },
  {
    "uniprot": "A0A0N4U8V8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8V8"
  },
  {
    "uniprot": "A0A3P7T6K0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T6K0"
  },
  {
    "uniprot": "A0A0N4UNB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNB5"
  },
  {
    "uniprot": "A0A0N4U3M7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3M7"
  },
  {
    "uniprot": "A0A0N4UBJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBJ4"
  },
  {
    "uniprot": "A0A0N4UD73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD73"
  },
  {
    "uniprot": "A0A0N4U6G3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6G3"
  },
  {
    "uniprot": "A0A0N4UFH7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFH7"
  },
  {
    "uniprot": "A0A0N4UE69",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE69"
  },
  {
    "uniprot": "A0A0N4UQR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQR2"
  },
  {
    "uniprot": "A0A158Q4W3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4W3"
  },
  {
    "uniprot": "A0A158Q2S6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2S6"
  },
  {
    "uniprot": "A0A0N4U1C0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1C0"
  },
  {
    "uniprot": "A0A0N4UDW1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDW1"
  },
  {
    "uniprot": "A0A0N4UCS6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCS6"
  },
  {
    "uniprot": "A0A0N4U300",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U300"
  },
  {
    "uniprot": "A0A0N4U985",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U985"
  },
  {
    "uniprot": "A0A0N4U274",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U274"
  },
  {
    "uniprot": "A0A0N4U0F4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0F4"
  },
  {
    "uniprot": "A0A0N4ULV4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULV4"
  },
  {
    "uniprot": "A0A0N4UDD2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDD2"
  },
  {
    "uniprot": "A0A0N4UJ22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ22"
  },
  {
    "uniprot": "A0A0N4UPT2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPT2"
  },
  {
    "uniprot": "A0A0N4UGL1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGL1"
  },
  {
    "uniprot": "A0A0N4UGD0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGD0"
  },
  {
    "uniprot": "A0A3P7Q4B6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q4B6"
  },
  {
    "uniprot": "A0A0N4ULL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULL9"
  },
  {
    "uniprot": "A0A0N4U9N2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9N2"
  },
  {
    "uniprot": "A0A0N4UCW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCW0"
  },
  {
    "uniprot": "A0A3P7PSI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PSI3"
  },
  {
    "uniprot": "A0A158Q535",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q535"
  },
  {
    "uniprot": "A0A0N4U1G6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1G6"
  },
  {
    "uniprot": "A0A0N4UQ73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ73"
  },
  {
    "uniprot": "A0A0N4UQT8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQT8"
  },
  {
    "uniprot": "A0A0N4U738",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U738"
  },
  {
    "uniprot": "A0A0N4UMF0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMF0"
  },
  {
    "uniprot": "A0A0N4UDN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDN6"
  },
  {
    "uniprot": "A0A0N4U503",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U503"
  },
  {
    "uniprot": "A0A0N4UBK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBK6"
  },
  {
    "uniprot": "A0A158Q600",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q600"
  },
  {
    "uniprot": "A0A158Q438",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q438"
  },
  {
    "uniprot": "A0A158Q5F3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5F3"
  },
  {
    "uniprot": "A0A0N4U7F1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7F1"
  },
  {
    "uniprot": "A0A0N4UBV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBV8"
  },
  {
    "uniprot": "A0A0N4UNE9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNE9"
  },
  {
    "uniprot": "A0A0N4UNQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNQ1"
  },
  {
    "uniprot": "A0A0N4U7Z5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Z5"
  },
  {
    "uniprot": "A0A158Q591",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q591"
  },
  {
    "uniprot": "A0A0N4UKI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKI9"
  },
  {
    "uniprot": "A0A0N4UFS0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFS0"
  },
  {
    "uniprot": "A0A0N4U5C1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5C1"
  },
  {
    "uniprot": "A0A0N4UD41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD41"
  },
  {
    "uniprot": "A0A0N4UNM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNM0"
  },
  {
    "uniprot": "A0A0N4UMS8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMS8"
  },
  {
    "uniprot": "A0A0N4UJ17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ17"
  },
  {
    "uniprot": "A0A158Q5Y7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Y7"
  },
  {
    "uniprot": "A0A0N4U476",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U476"
  },
  {
    "uniprot": "A0A0N4U2C6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2C6"
  },
  {
    "uniprot": "A0A0N4UHB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHB7"
  },
  {
    "uniprot": "A0A0N4UC23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC23"
  },
  {
    "uniprot": "A0A0N4UCW4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCW4"
  },
  {
    "uniprot": "A0A0N4UPL5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPL5"
  },
  {
    "uniprot": "A0A158Q3B1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3B1"
  },
  {
    "uniprot": "A0A3P7PU42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PU42"
  },
  {
    "uniprot": "A0A0N4U2P0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2P0"
  },
  {
    "uniprot": "A0A0N4U7E0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7E0"
  },
  {
    "uniprot": "A0A0N4UI69",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI69"
  },
  {
    "uniprot": "A0A0N4U6P3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6P3"
  },
  {
    "uniprot": "A0A0N4UQ48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ48"
  },
  {
    "uniprot": "A0A0N4U5C8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5C8"
  },
  {
    "uniprot": "A0A0N4UA71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA71"
  },
  {
    "uniprot": "A0A0N4ULJ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULJ6"
  },
  {
    "uniprot": "A0A158Q4J2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4J2"
  },
  {
    "uniprot": "A0A0N4U2U4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2U4"
  },
  {
    "uniprot": "A0A3P7SGU9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SGU9"
  },
  {
    "uniprot": "A0A158Q3X1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3X1"
  },
  {
    "uniprot": "A0A0N4UI22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI22"
  },
  {
    "uniprot": "A0A0N4U8C6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8C6"
  },
  {
    "uniprot": "A0A0N4UK03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK03"
  },
  {
    "uniprot": "A0A0N4UP63",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP63"
  },
  {
    "uniprot": "A0A0N4UFU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFU4"
  },
  {
    "uniprot": "A0A0N4UDN5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDN5"
  },
  {
    "uniprot": "A0A0N4UQH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQH4"
  },
  {
    "uniprot": "A0A0N4UA86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA86"
  },
  {
    "uniprot": "A0A3P7QR14",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QR14"
  },
  {
    "uniprot": "A0A0N4UJ80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ80"
  },
  {
    "uniprot": "A0A3P7PX50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PX50"
  },
  {
    "uniprot": "A0A0N4U8Y1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Y1"
  },
  {
    "uniprot": "A0A0N4UAN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAN6"
  },
  {
    "uniprot": "A0A0N4USB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USB1"
  },
  {
    "uniprot": "A0A0N4U8R4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8R4"
  },
  {
    "uniprot": "A0A158Q2Q3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Q3"
  },
  {
    "uniprot": "A0A0N4UL23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL23"
  },
  {
    "uniprot": "A0A0N4UQU0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQU0"
  },
  {
    "uniprot": "A0A3P7PPV0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPV0"
  },
  {
    "uniprot": "A0A0N4UNB6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNB6"
  },
  {
    "uniprot": "A0A3P7Q5T7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q5T7"
  },
  {
    "uniprot": "A0A3P7R214",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7R214"
  },
  {
    "uniprot": "A0A0N4UHU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHU4"
  },
  {
    "uniprot": "A0A0N4U4Z5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Z5"
  },
  {
    "uniprot": "A0A158Q525",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q525"
  },
  {
    "uniprot": "A0A3P7PWS6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PWS6"
  },
  {
    "uniprot": "A0A0N4U5I7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5I7"
  },
  {
    "uniprot": "A0A0N4UGT7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGT7"
  },
  {
    "uniprot": "A0A0N4U6L5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6L5"
  },
  {
    "uniprot": "A0A0N4UFR3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFR3"
  },
  {
    "uniprot": "A0A0N4UED6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UED6"
  },
  {
    "uniprot": "A0A0N4UNN1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNN1"
  },
  {
    "uniprot": "A0A0N4UP29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP29"
  },
  {
    "uniprot": "A0A3P7Q360",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q360"
  },
  {
    "uniprot": "A0A0N4UIK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIK3"
  },
  {
    "uniprot": "A0A0N4URQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URQ1"
  },
  {
    "uniprot": "A0A0N4UD45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD45"
  },
  {
    "uniprot": "A0A3P7QV67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QV67"
  },
  {
    "uniprot": "A0A0N4UQK8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQK8"
  },
  {
    "uniprot": "A0A0N4UP87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP87"
  },
  {
    "uniprot": "A0A0N4UD05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD05"
  },
  {
    "uniprot": "A0A0N4UC28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC28"
  },
  {
    "uniprot": "A0A158Q3H8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3H8"
  },
  {
    "uniprot": "A0A0N4UFD7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFD7"
  },
  {
    "uniprot": "A0A0N4UA49",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA49"
  },
  {
    "uniprot": "A0A158Q4H4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4H4"
  },
  {
    "uniprot": "A0A0N4UJI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJI1"
  },
  {
    "uniprot": "A0A158Q449",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q449"
  },
  {
    "uniprot": "A0A0N4UNP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNP7"
  },
  {
    "uniprot": "A0A0N4U981",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U981"
  },
  {
    "uniprot": "A0A3P7SYK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SYK3"
  },
  {
    "uniprot": "A0A0N4UGQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGQ7"
  },
  {
    "uniprot": "A0A0N4UDZ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDZ3"
  },
  {
    "uniprot": "A0A0N4US25",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US25"
  },
  {
    "uniprot": "A0A0N4UDE2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDE2"
  },
  {
    "uniprot": "A0A158Q4E1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4E1"
  },
  {
    "uniprot": "A0A0N4U392",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U392"
  },
  {
    "uniprot": "A0A0N4UNT7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNT7"
  },
  {
    "uniprot": "A0A158Q2Q1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Q1"
  },
  {
    "uniprot": "A0A0N4UH38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH38"
  },
  {
    "uniprot": "A0A0N4UMK4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMK4"
  },
  {
    "uniprot": "A0A3P7SXE1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SXE1"
  },
  {
    "uniprot": "A0A0N4U537",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U537"
  },
  {
    "uniprot": "A0A0N4UFC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFC3"
  },
  {
    "uniprot": "A0A158Q5L6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5L6"
  },
  {
    "uniprot": "A0A158Q3U9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3U9"
  },
  {
    "uniprot": "A0A0N4UC65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC65"
  },
  {
    "uniprot": "A0A0N4UH17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH17"
  },
  {
    "uniprot": "A0A0N4UJN5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJN5"
  },
  {
    "uniprot": "A0A0N4UAJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAJ1"
  },
  {
    "uniprot": "A0A0N4U7Q0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Q0"
  },
  {
    "uniprot": "A0A3P7PV31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PV31"
  },
  {
    "uniprot": "A0A0N4U0E6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0E6"
  },
  {
    "uniprot": "A0A0N4UHI7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHI7"
  },
  {
    "uniprot": "A0A0N4U0R6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0R6"
  },
  {
    "uniprot": "A0A0N4UCA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCA6"
  },
  {
    "uniprot": "A0A158Q3A5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3A5"
  },
  {
    "uniprot": "A0A0N4UI01",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI01"
  },
  {
    "uniprot": "A0A0N4UCH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCH2"
  },
  {
    "uniprot": "A0A0N4U9W3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9W3"
  },
  {
    "uniprot": "A0A0N4U0H9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0H9"
  },
  {
    "uniprot": "A0A3P7Q427",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q427"
  },
  {
    "uniprot": "A0A0N4UGL2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGL2"
  },
  {
    "uniprot": "A0A0N4UMZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMZ5"
  },
  {
    "uniprot": "A0A0N4UM40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM40"
  },
  {
    "uniprot": "A0A0N4U130",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U130"
  },
  {
    "uniprot": "A0A0N4U3C6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3C6"
  },
  {
    "uniprot": "A0A0N4UHZ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHZ3"
  },
  {
    "uniprot": "A0A0N4UBK8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBK8"
  },
  {
    "uniprot": "A0A0N4UIZ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIZ7"
  },
  {
    "uniprot": "A0A158Q483",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q483"
  },
  {
    "uniprot": "A0A158Q2K7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2K7"
  },
  {
    "uniprot": "A0A158Q3J3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3J3"
  },
  {
    "uniprot": "A0A158Q5R0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5R0"
  },
  {
    "uniprot": "A0A0N4UFP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFP6"
  },
  {
    "uniprot": "A0A0N4UHD6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHD6"
  },
  {
    "uniprot": "A0A3P7STC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7STC3"
  },
  {
    "uniprot": "A0A158Q5M9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5M9"
  },
  {
    "uniprot": "A0A0N4UKP8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKP8"
  },
  {
    "uniprot": "A0A0N4UR06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR06"
  },
  {
    "uniprot": "A0A0N4UJV1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJV1"
  },
  {
    "uniprot": "A0A0N4UQS5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQS5"
  },
  {
    "uniprot": "A0A3P7PW19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PW19"
  },
  {
    "uniprot": "A0A0N4UR15",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR15"
  },
  {
    "uniprot": "A0A158Q3I9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3I9"
  },
  {
    "uniprot": "A0A0N4U0W7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0W7"
  },
  {
    "uniprot": "A0A3P7PS10",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PS10"
  },
  {
    "uniprot": "A0A0N4UMN5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMN5"
  },
  {
    "uniprot": "A0A0N4UET3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UET3"
  },
  {
    "uniprot": "A0A0N4ULN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULN9"
  },
  {
    "uniprot": "A0A0N4U0Y9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Y9"
  },
  {
    "uniprot": "A0A0N4UN79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN79"
  },
  {
    "uniprot": "A0A158Q3V8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3V8"
  },
  {
    "uniprot": "A0A0N4U7I2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7I2"
  },
  {
    "uniprot": "A0A158Q3U7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3U7"
  },
  {
    "uniprot": "A0A158Q421",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q421"
  },
  {
    "uniprot": "A0A0N4UBJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBJ7"
  },
  {
    "uniprot": "A0A3P7QGP8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QGP8"
  },
  {
    "uniprot": "A0A3P7S8S6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S8S6"
  },
  {
    "uniprot": "A0A0N4UJH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJH9"
  },
  {
    "uniprot": "A0A0N4UBU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBU6"
  },
  {
    "uniprot": "A0A0N4UEG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEG9"
  },
  {
    "uniprot": "A0A158Q563",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q563"
  },
  {
    "uniprot": "A0A158Q6F6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6F6"
  },
  {
    "uniprot": "A0A0N4U9B0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9B0"
  },
  {
    "uniprot": "A0A0N4UL89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL89"
  },
  {
    "uniprot": "A0A0N4UN90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN90"
  },
  {
    "uniprot": "A0A158Q4T2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4T2"
  },
  {
    "uniprot": "A0A0N4U5J7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5J7"
  },
  {
    "uniprot": "A0A0N4UAD7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAD7"
  },
  {
    "uniprot": "A0A0N4UR92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR92"
  },
  {
    "uniprot": "A0A0N4UDU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDU7"
  },
  {
    "uniprot": "A0A0N4U8N2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8N2"
  },
  {
    "uniprot": "A0A0N4UGV5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGV5"
  },
  {
    "uniprot": "A0A0N4UI54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI54"
  },
  {
    "uniprot": "A0A158Q5W0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5W0"
  },
  {
    "uniprot": "A0A0N4UHP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHP0"
  },
  {
    "uniprot": "A0A0N4U5E6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5E6"
  },
  {
    "uniprot": "A0A0N4U3A3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3A3"
  },
  {
    "uniprot": "A0A0N4UA03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA03"
  },
  {
    "uniprot": "A0A0N4UBD3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBD3"
  },
  {
    "uniprot": "A0A0N4U3E3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3E3"
  },
  {
    "uniprot": "A0A0N4U8F2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8F2"
  },
  {
    "uniprot": "A0A0N4UNY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNY7"
  },
  {
    "uniprot": "A0A0N4UND8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UND8"
  },
  {
    "uniprot": "A0A0N4U1P7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1P7"
  },
  {
    "uniprot": "A0A0N4UK38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK38"
  },
  {
    "uniprot": "A0A158Q676",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q676"
  },
  {
    "uniprot": "A0A0N4UP43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP43"
  },
  {
    "uniprot": "A0A0N4UHA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHA9"
  },
  {
    "uniprot": "A0A0N4UAQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAQ1"
  },
  {
    "uniprot": "A0A158Q4H6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4H6"
  },
  {
    "uniprot": "A0A0N4UAR5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAR5"
  },
  {
    "uniprot": "A0A0N4UJ05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ05"
  },
  {
    "uniprot": "A0A158Q5G7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5G7"
  },
  {
    "uniprot": "A0A0N4U5I3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5I3"
  },
  {
    "uniprot": "A0A0N4UDP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDP3"
  },
  {
    "uniprot": "A0A0N4UH05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH05"
  },
  {
    "uniprot": "A0A0N4UCS5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCS5"
  },
  {
    "uniprot": "A0A0N4UAB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAB2"
  },
  {
    "uniprot": "A0A0N4UB17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB17"
  },
  {
    "uniprot": "A0A0N4UIP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIP6"
  },
  {
    "uniprot": "A0A0N4U1W5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1W5"
  },
  {
    "uniprot": "A0A0N4U6L9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6L9"
  },
  {
    "uniprot": "A0A0N4UKK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKK5"
  },
  {
    "uniprot": "A0A0N4UJN1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJN1"
  },
  {
    "uniprot": "A0A0N4UJP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJP1"
  },
  {
    "uniprot": "A0A0N4U7L3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7L3"
  },
  {
    "uniprot": "A0A0N4U253",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U253"
  },
  {
    "uniprot": "A0A0N4UK11",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK11"
  },
  {
    "uniprot": "A0A0N4U9D2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9D2"
  },
  {
    "uniprot": "A0A158Q4V3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4V3"
  },
  {
    "uniprot": "A0A0N4UJ24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ24"
  },
  {
    "uniprot": "A0A0N4UJU1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJU1"
  },
  {
    "uniprot": "A0A0N4UD81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD81"
  },
  {
    "uniprot": "A0A0N4UEH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEH4"
  },
  {
    "uniprot": "A0A0N4UNZ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNZ8"
  },
  {
    "uniprot": "A0A0N4UA06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA06"
  },
  {
    "uniprot": "A0A158Q343",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q343"
  },
  {
    "uniprot": "A0A0N4UBG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBG0"
  },
  {
    "uniprot": "A0A0N4UIP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIP7"
  },
  {
    "uniprot": "A0A0N4U5U0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5U0"
  },
  {
    "uniprot": "A0A0N4U438",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U438"
  },
  {
    "uniprot": "A0A158Q4L5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4L5"
  },
  {
    "uniprot": "A0A0N4U5D7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5D7"
  },
  {
    "uniprot": "A0A0N4U510",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U510"
  },
  {
    "uniprot": "A0A0N4UAQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAQ3"
  },
  {
    "uniprot": "A0A0N4UR41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR41"
  },
  {
    "uniprot": "A0A0N4UE76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE76"
  },
  {
    "uniprot": "A0A158Q6K4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6K4"
  },
  {
    "uniprot": "A0A0N4UEH8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEH8"
  },
  {
    "uniprot": "A0A0N4UAB6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAB6"
  },
  {
    "uniprot": "A0A0N4UAS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAS3"
  },
  {
    "uniprot": "A0A0N4UA70",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA70"
  },
  {
    "uniprot": "A0A158Q5L3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5L3"
  },
  {
    "uniprot": "A0A0N4URU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URU3"
  },
  {
    "uniprot": "A0A0N4U237",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U237"
  },
  {
    "uniprot": "A0A0N4UJ60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ60"
  },
  {
    "uniprot": "A0A3P7PZR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PZR4"
  },
  {
    "uniprot": "A0A0N4UQA7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQA7"
  },
  {
    "uniprot": "A0A0N4UG28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG28"
  },
  {
    "uniprot": "A0A0N4UJH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJH2"
  },
  {
    "uniprot": "A0A0N4UAR3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAR3"
  },
  {
    "uniprot": "A0A0N4U248",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U248"
  },
  {
    "uniprot": "A0A0N4U769",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U769"
  },
  {
    "uniprot": "A0A0N4UBQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBQ1"
  },
  {
    "uniprot": "A0A0N4UFV2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFV2"
  },
  {
    "uniprot": "A0A0N4UMN4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMN4"
  },
  {
    "uniprot": "A0A0N4UCC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCC5"
  },
  {
    "uniprot": "A0A0N4U1K0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1K0"
  },
  {
    "uniprot": "A0A0N4UIW7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIW7"
  },
  {
    "uniprot": "A0A0N4UED2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UED2"
  },
  {
    "uniprot": "A0A0N4UDY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDY7"
  },
  {
    "uniprot": "A0A0N4U351",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U351"
  },
  {
    "uniprot": "A0A0N4UEC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEC9"
  },
  {
    "uniprot": "A0A0N4UDD7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDD7"
  },
  {
    "uniprot": "A0A0N4UDS4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDS4"
  },
  {
    "uniprot": "A0A158Q3G3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3G3"
  },
  {
    "uniprot": "A0A0N4UCJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCJ1"
  },
  {
    "uniprot": "A0A0N4U3L3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3L3"
  },
  {
    "uniprot": "A0A158Q3P6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3P6"
  },
  {
    "uniprot": "A0A0N4UFA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFA1"
  },
  {
    "uniprot": "A0A0N4US73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US73"
  },
  {
    "uniprot": "A0A0N4UP86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP86"
  },
  {
    "uniprot": "A0A0N4U4I1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4I1"
  },
  {
    "uniprot": "A0A0N4UCD4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCD4"
  },
  {
    "uniprot": "A0A0N4UD02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD02"
  },
  {
    "uniprot": "A0A0N4UJU9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJU9"
  },
  {
    "uniprot": "A0A0N4UKC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKC5"
  },
  {
    "uniprot": "A0A0N4UNT8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNT8"
  },
  {
    "uniprot": "A0A0N4UAC8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAC8"
  },
  {
    "uniprot": "A0A0N4UBM6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBM6"
  },
  {
    "uniprot": "A0A0N4UAY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAY4"
  },
  {
    "uniprot": "A0A0N4UPJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPJ2"
  },
  {
    "uniprot": "A0A0N4UQ79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ79"
  },
  {
    "uniprot": "A0A0N4ULP2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULP2"
  },
  {
    "uniprot": "A0A0N4UJ82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ82"
  },
  {
    "uniprot": "A0A158Q4L6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4L6"
  },
  {
    "uniprot": "A0A0N4UF05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF05"
  },
  {
    "uniprot": "A0A3P7SWA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SWA0"
  },
  {
    "uniprot": "A0A0N4U456",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U456"
  },
  {
    "uniprot": "A0A0N4UNI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNI3"
  },
  {
    "uniprot": "A0A0N4URB6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URB6"
  },
  {
    "uniprot": "A0A158Q6M6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6M6"
  },
  {
    "uniprot": "A0A0N4U0J2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0J2"
  },
  {
    "uniprot": "A0A0N4UR81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR81"
  },
  {
    "uniprot": "A0A0N4ULY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULY0"
  },
  {
    "uniprot": "A0A0N4U6U5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6U5"
  },
  {
    "uniprot": "A0A0N4UQ45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ45"
  },
  {
    "uniprot": "A0A0N4UBQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBQ5"
  },
  {
    "uniprot": "A0A0N4U2C3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2C3"
  },
  {
    "uniprot": "A0A0N4UKY3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKY3"
  },
  {
    "uniprot": "A0A0N4U892",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U892"
  },
  {
    "uniprot": "A0A0N4U5I9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5I9"
  },
  {
    "uniprot": "A0A0N4UJ54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ54"
  },
  {
    "uniprot": "A0A0N4URP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URP6"
  },
  {
    "uniprot": "A0A0N4ULL1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULL1"
  },
  {
    "uniprot": "A0A158Q2K1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2K1"
  },
  {
    "uniprot": "A0A0N4URB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URB3"
  },
  {
    "uniprot": "A0A158Q2J2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2J2"
  },
  {
    "uniprot": "A0A0N4UF47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF47"
  },
  {
    "uniprot": "A0A158Q4V8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4V8"
  },
  {
    "uniprot": "A0A0N4UKH5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKH5"
  },
  {
    "uniprot": "A0A0N4U2R1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2R1"
  },
  {
    "uniprot": "A0A0N4U4H9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4H9"
  },
  {
    "uniprot": "A0A158Q559",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q559"
  },
  {
    "uniprot": "A0A158Q4W5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4W5"
  },
  {
    "uniprot": "A0A0N4ULN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULN2"
  },
  {
    "uniprot": "A0A3P7SEN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SEN6"
  },
  {
    "uniprot": "A0A0N4UHQ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHQ0"
  },
  {
    "uniprot": "A0A0N4U6P0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6P0"
  },
  {
    "uniprot": "A0A0N4U200",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U200"
  },
  {
    "uniprot": "A0A0N4UDB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDB9"
  },
  {
    "uniprot": "A0A0N4U4I8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4I8"
  },
  {
    "uniprot": "A0A158Q379",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q379"
  },
  {
    "uniprot": "A0A158Q5Z0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Z0"
  },
  {
    "uniprot": "A0A158Q3T9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3T9"
  },
  {
    "uniprot": "A0A0N4UKR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKR9"
  },
  {
    "uniprot": "A0A158Q2H7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2H7"
  },
  {
    "uniprot": "A0A0N4UGQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGQ5"
  },
  {
    "uniprot": "A0A0N4UGZ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGZ6"
  },
  {
    "uniprot": "A0A0N4U1F5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1F5"
  },
  {
    "uniprot": "A0A0N4U975",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U975"
  },
  {
    "uniprot": "A0A0N4U812",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U812"
  },
  {
    "uniprot": "A0A0N4U610",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U610"
  },
  {
    "uniprot": "A0A0N4UNE5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNE5"
  },
  {
    "uniprot": "A0A0N4UEK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEK3"
  },
  {
    "uniprot": "A0A0N4UFA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFA5"
  },
  {
    "uniprot": "A0A0N4UPL6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPL6"
  },
  {
    "uniprot": "A0A0N4U5N8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5N8"
  },
  {
    "uniprot": "A0A158Q2Q9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Q9"
  },
  {
    "uniprot": "A0A0N4U1U1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1U1"
  },
  {
    "uniprot": "A0A0N4UD48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD48"
  },
  {
    "uniprot": "A0A0N4UR18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR18"
  },
  {
    "uniprot": "A0A0N4UP23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP23"
  },
  {
    "uniprot": "A0A0N4UNA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNA6"
  },
  {
    "uniprot": "A0A0N4U117",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U117"
  },
  {
    "uniprot": "A0A0N4U9G8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9G8"
  },
  {
    "uniprot": "A0A0N4US93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US93"
  },
  {
    "uniprot": "A0A0N4U8F0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8F0"
  },
  {
    "uniprot": "A0A0N4U2N7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2N7"
  },
  {
    "uniprot": "A0A0N4UGD6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGD6"
  },
  {
    "uniprot": "A0A0N4U5E2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5E2"
  },
  {
    "uniprot": "A0A0N4UM18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM18"
  },
  {
    "uniprot": "A0A0N4UCT9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCT9"
  },
  {
    "uniprot": "A0A0N4UCT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCT6"
  },
  {
    "uniprot": "A0A0N4UN09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN09"
  },
  {
    "uniprot": "A0A0N4U7C1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7C1"
  },
  {
    "uniprot": "A0A0N4UPW4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPW4"
  },
  {
    "uniprot": "A0A0N4UK43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK43"
  },
  {
    "uniprot": "A0A0N4UP35",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP35"
  },
  {
    "uniprot": "A0A0N4UKV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKV8"
  },
  {
    "uniprot": "A0A0N4UN22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN22"
  },
  {
    "uniprot": "A0A0N4U8W9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8W9"
  },
  {
    "uniprot": "A0A0N4U3Q0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Q0"
  },
  {
    "uniprot": "A0A158Q353",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q353"
  },
  {
    "uniprot": "A0A0N4UNM6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNM6"
  },
  {
    "uniprot": "A0A0N4UC24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC24"
  },
  {
    "uniprot": "A0A0N4UNC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNC7"
  },
  {
    "uniprot": "A0A0N4UPY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPY4"
  },
  {
    "uniprot": "A0A3P7SV12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SV12"
  },
  {
    "uniprot": "A0A0N4UPI4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPI4"
  },
  {
    "uniprot": "A0A0N4UF39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF39"
  },
  {
    "uniprot": "A0A158Q4G3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4G3"
  },
  {
    "uniprot": "A0A0N4UGN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGN9"
  },
  {
    "uniprot": "A0A0N4UFA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFA4"
  },
  {
    "uniprot": "A0A0N4UQQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQQ6"
  },
  {
    "uniprot": "A0A0N4U1Q2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Q2"
  },
  {
    "uniprot": "A0A0N4UKZ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKZ9"
  },
  {
    "uniprot": "A0A0N4UI90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI90"
  },
  {
    "uniprot": "A0A0N4UNT3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNT3"
  },
  {
    "uniprot": "A0A0N4U3C0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3C0"
  },
  {
    "uniprot": "A0A3P7PYP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PYP4"
  },
  {
    "uniprot": "A0A158Q433",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q433"
  },
  {
    "uniprot": "A0A0N4UG13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG13"
  },
  {
    "uniprot": "A0A0N4U1P2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1P2"
  },
  {
    "uniprot": "A0A0N4U4H1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4H1"
  },
  {
    "uniprot": "A0A0N4UPT0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPT0"
  },
  {
    "uniprot": "A0A0N4UGR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGR7"
  },
  {
    "uniprot": "A0A0N4UEV2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEV2"
  },
  {
    "uniprot": "A0A0N4U7I3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7I3"
  },
  {
    "uniprot": "A0A0N4UA09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA09"
  },
  {
    "uniprot": "A0A0N4U7X5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7X5"
  },
  {
    "uniprot": "A0A0N4U5K9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5K9"
  },
  {
    "uniprot": "A0A0N4U1Q1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Q1"
  },
  {
    "uniprot": "A0A0N4UN49",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN49"
  },
  {
    "uniprot": "A0A0N4UPW5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPW5"
  },
  {
    "uniprot": "A0A3P7PQM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQM2"
  },
  {
    "uniprot": "A0A0N4U7W0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7W0"
  },
  {
    "uniprot": "A0A0N4U1L7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1L7"
  },
  {
    "uniprot": "A0A0N4UJA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJA4"
  },
  {
    "uniprot": "A0A0N4UNW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNW0"
  },
  {
    "uniprot": "A0A0N4UH26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH26"
  },
  {
    "uniprot": "A0A158Q6B2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6B2"
  },
  {
    "uniprot": "A0A158Q6D3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6D3"
  },
  {
    "uniprot": "A0A158Q5H8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5H8"
  },
  {
    "uniprot": "A0A0N4U399",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U399"
  },
  {
    "uniprot": "A0A3P7PEG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PEG0"
  },
  {
    "uniprot": "A0A158Q3V7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3V7"
  },
  {
    "uniprot": "A0A0N4U328",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U328"
  },
  {
    "uniprot": "A0A158Q3R1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3R1"
  },
  {
    "uniprot": "A0A0N4UJM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJM2"
  },
  {
    "uniprot": "A0A3P7QJ41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QJ41"
  },
  {
    "uniprot": "A0A0N4UPU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPU7"
  },
  {
    "uniprot": "A0A0N4U3C7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3C7"
  },
  {
    "uniprot": "A0A0N4UDU1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDU1"
  },
  {
    "uniprot": "A0A0N4UHZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHZ1"
  },
  {
    "uniprot": "A0A0N4U799",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U799"
  },
  {
    "uniprot": "A0A0N4UA93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA93"
  },
  {
    "uniprot": "A0A0N4U448",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U448"
  },
  {
    "uniprot": "A0A0N4UGW6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGW6"
  },
  {
    "uniprot": "A0A0N4UR05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR05"
  },
  {
    "uniprot": "A0A0N4UGH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGH2"
  },
  {
    "uniprot": "A0A0N4UJ26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ26"
  },
  {
    "uniprot": "A0A158Q2I5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2I5"
  },
  {
    "uniprot": "A0A3P7Q6T1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q6T1"
  },
  {
    "uniprot": "A0A0N4UC14",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC14"
  },
  {
    "uniprot": "A0A0N4ULA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULA4"
  },
  {
    "uniprot": "A0A3P7QDM4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QDM4"
  },
  {
    "uniprot": "A0A0N4UHW7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHW7"
  },
  {
    "uniprot": "A0A158Q2P2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2P2"
  },
  {
    "uniprot": "A0A158Q4R4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4R4"
  },
  {
    "uniprot": "A0A0N4UQT0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQT0"
  },
  {
    "uniprot": "A0A0N4U597",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U597"
  },
  {
    "uniprot": "A0A0N4U9Y5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Y5"
  },
  {
    "uniprot": "A0A0N4UF44",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF44"
  },
  {
    "uniprot": "A0A0N4UP13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP13"
  },
  {
    "uniprot": "A0A0N4U4F1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4F1"
  },
  {
    "uniprot": "A0A0N4UDB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDB0"
  },
  {
    "uniprot": "A0A3P7PD56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PD56"
  },
  {
    "uniprot": "A0A158Q321",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q321"
  },
  {
    "uniprot": "A0A0N4UMK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMK5"
  },
  {
    "uniprot": "A0A0N4U7X0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7X0"
  },
  {
    "uniprot": "A0A0N4U0C8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0C8"
  },
  {
    "uniprot": "A0A0N4U8B3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8B3"
  },
  {
    "uniprot": "A0A3P7T1B9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T1B9"
  },
  {
    "uniprot": "A0A158Q5X5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5X5"
  },
  {
    "uniprot": "A0A0N4UPM3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPM3"
  },
  {
    "uniprot": "A0A0N4U9A9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9A9"
  },
  {
    "uniprot": "A0A3P7PPC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPC7"
  },
  {
    "uniprot": "A0A0N4UA78",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA78"
  },
  {
    "uniprot": "A0A158Q3R4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3R4"
  },
  {
    "uniprot": "A0A0N4UR70",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR70"
  },
  {
    "uniprot": "A0A0N4UJI6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJI6"
  },
  {
    "uniprot": "A0A0N4UJC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJC1"
  },
  {
    "uniprot": "A0A0N4U0Y1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Y1"
  },
  {
    "uniprot": "A0A0N4UMH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMH3"
  },
  {
    "uniprot": "A0A0N4UBR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBR9"
  },
  {
    "uniprot": "A0A3P7QEG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QEG6"
  },
  {
    "uniprot": "A0A158Q5L8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5L8"
  },
  {
    "uniprot": "A0A0N4U6G0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6G0"
  },
  {
    "uniprot": "A0A158Q4D3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4D3"
  },
  {
    "uniprot": "A0A0N4UNI7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNI7"
  },
  {
    "uniprot": "A0A0N4ULR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULR1"
  },
  {
    "uniprot": "A0A0N4UJE2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJE2"
  },
  {
    "uniprot": "A0A0N4UB29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB29"
  },
  {
    "uniprot": "A0A0N4UIH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIH2"
  },
  {
    "uniprot": "A0A0N4U163",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U163"
  },
  {
    "uniprot": "A0A0N4U8X6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8X6"
  },
  {
    "uniprot": "A0A3P7PUX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PUX9"
  },
  {
    "uniprot": "A0A0N4U3V4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3V4"
  },
  {
    "uniprot": "A0A0N4UB19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB19"
  },
  {
    "uniprot": "A0A0N4UQ86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ86"
  },
  {
    "uniprot": "A0A0N4UI13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI13"
  },
  {
    "uniprot": "A0A0N4UC17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC17"
  },
  {
    "uniprot": "A0A0N4UF53",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF53"
  },
  {
    "uniprot": "A0A0N4UK06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK06"
  },
  {
    "uniprot": "A0A0N4UNI8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNI8"
  },
  {
    "uniprot": "A0A0N4UDF4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDF4"
  },
  {
    "uniprot": "A0A0N4UHU0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHU0"
  },
  {
    "uniprot": "A0A0N4U128",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U128"
  },
  {
    "uniprot": "A0A0N4U3X4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3X4"
  },
  {
    "uniprot": "A0A0N4U596",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U596"
  },
  {
    "uniprot": "A0A158Q3E0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3E0"
  },
  {
    "uniprot": "A0A0N4UIU1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIU1"
  },
  {
    "uniprot": "A0A0N4U0S3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0S3"
  },
  {
    "uniprot": "A0A0N4ULN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULN0"
  },
  {
    "uniprot": "A0A0N4UGM3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGM3"
  },
  {
    "uniprot": "A0A3P7Q9I0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q9I0"
  },
  {
    "uniprot": "A0A0N4U824",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U824"
  },
  {
    "uniprot": "A0A3P7S637",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S637"
  },
  {
    "uniprot": "A0A0N4UEA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEA8"
  },
  {
    "uniprot": "A0A3P7PE15",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PE15"
  },
  {
    "uniprot": "A0A0N4UPN5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPN5"
  },
  {
    "uniprot": "A0A0N4UDG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDG6"
  },
  {
    "uniprot": "A0A158Q661",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q661"
  },
  {
    "uniprot": "A0A158Q3G8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3G8"
  },
  {
    "uniprot": "A0A0N4U5P9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5P9"
  },
  {
    "uniprot": "A0A0N4U8A7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8A7"
  },
  {
    "uniprot": "A0A0N4UE65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE65"
  },
  {
    "uniprot": "A0A3P7SWU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SWU6"
  },
  {
    "uniprot": "A0A0N4UPT7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPT7"
  },
  {
    "uniprot": "A0A0N4UJ74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ74"
  },
  {
    "uniprot": "A0A0N4UQI4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQI4"
  },
  {
    "uniprot": "A0A3P7SJQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SJQ5"
  },
  {
    "uniprot": "A0A3P7QAE7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QAE7"
  },
  {
    "uniprot": "A0A0N4U6F6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6F6"
  },
  {
    "uniprot": "A0A158Q5Z9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Z9"
  },
  {
    "uniprot": "A0A3P7SP90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SP90"
  },
  {
    "uniprot": "A0A0N4UB08",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB08"
  },
  {
    "uniprot": "A0A3P7QJE2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QJE2"
  },
  {
    "uniprot": "A0A0N4UHQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHQ4"
  },
  {
    "uniprot": "A0A0N4U2A5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2A5"
  },
  {
    "uniprot": "A0A0N4UG16",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG16"
  },
  {
    "uniprot": "A0A0N4UN39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN39"
  },
  {
    "uniprot": "A0A0N4U2U6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2U6"
  },
  {
    "uniprot": "A0A0N4UC31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC31"
  },
  {
    "uniprot": "A0A3P7Q265",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q265"
  },
  {
    "uniprot": "A0A0N4ULC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULC2"
  },
  {
    "uniprot": "A0A0N4UFB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFB0"
  },
  {
    "uniprot": "A0A0N4UR01",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR01"
  },
  {
    "uniprot": "A0A0N4UE81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE81"
  },
  {
    "uniprot": "A0A158Q637",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q637"
  },
  {
    "uniprot": "A0A0N4UH24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH24"
  },
  {
    "uniprot": "A0A0N4UPX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPX1"
  },
  {
    "uniprot": "A0A0N4ULU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULU3"
  },
  {
    "uniprot": "A0A0N4UQG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQG0"
  },
  {
    "uniprot": "A0A0N4UL60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL60"
  },
  {
    "uniprot": "A0A3P7QME7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QME7"
  },
  {
    "uniprot": "A0A158Q6F9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6F9"
  },
  {
    "uniprot": "A0A0N4UDQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDQ7"
  },
  {
    "uniprot": "A0A0N4U3V5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3V5"
  },
  {
    "uniprot": "A0A0N4U9M7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9M7"
  },
  {
    "uniprot": "A0A0N4U6Z4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Z4"
  },
  {
    "uniprot": "A0A0N4UBL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBL3"
  },
  {
    "uniprot": "A0A0N4UQ44",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ44"
  },
  {
    "uniprot": "A0A0N4UCD1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCD1"
  },
  {
    "uniprot": "A0A0N4UKQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKQ6"
  },
  {
    "uniprot": "A0A0N4UR86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR86"
  },
  {
    "uniprot": "A0A0N4UDJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDJ1"
  },
  {
    "uniprot": "A0A0N4UHS1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHS1"
  },
  {
    "uniprot": "A0A0N4U9Z7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Z7"
  },
  {
    "uniprot": "A0A3P7PSS5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PSS5"
  },
  {
    "uniprot": "A0A0N4URP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URP1"
  },
  {
    "uniprot": "A0A0N4UG30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG30"
  },
  {
    "uniprot": "A0A0N4U4M0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4M0"
  },
  {
    "uniprot": "A0A0N4UAK9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAK9"
  },
  {
    "uniprot": "A0A0N4UK31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK31"
  },
  {
    "uniprot": "A0A0N4UHL4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHL4"
  },
  {
    "uniprot": "A0A0N4U0V0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0V0"
  },
  {
    "uniprot": "A0A0N4UEI4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEI4"
  },
  {
    "uniprot": "A0A0N4UCQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCQ5"
  },
  {
    "uniprot": "A0A158Q5X2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5X2"
  },
  {
    "uniprot": "A0A158Q460",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q460"
  },
  {
    "uniprot": "A0A0N4UP51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP51"
  },
  {
    "uniprot": "A0A0N4U4J3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4J3"
  },
  {
    "uniprot": "A0A158Q670",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q670"
  },
  {
    "uniprot": "A0A0N4UMV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMV3"
  },
  {
    "uniprot": "A0A0N4U881",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U881"
  },
  {
    "uniprot": "A0A0N4ULH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULH6"
  },
  {
    "uniprot": "A0A0N4UKJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKJ4"
  },
  {
    "uniprot": "A0A0N4U7N6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7N6"
  },
  {
    "uniprot": "A0A0N4UII8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UII8"
  },
  {
    "uniprot": "A0A0N4UE43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE43"
  },
  {
    "uniprot": "A0A0N4UI76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI76"
  },
  {
    "uniprot": "A0A0N4UPF8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPF8"
  },
  {
    "uniprot": "A0A158Q4X6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4X6"
  },
  {
    "uniprot": "A0A0N4UC05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC05"
  },
  {
    "uniprot": "A0A0N4U6P7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6P7"
  },
  {
    "uniprot": "A0A3P7QXU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QXU7"
  },
  {
    "uniprot": "A0A0N4UN34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN34"
  },
  {
    "uniprot": "A0A3P7PJT3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PJT3"
  },
  {
    "uniprot": "A0A0N4UFN1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFN1"
  },
  {
    "uniprot": "A0A0N4UGS2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGS2"
  },
  {
    "uniprot": "A0A0N4UQ09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ09"
  },
  {
    "uniprot": "A0A0N4UMS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMS9"
  },
  {
    "uniprot": "A0A0N4UAR8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAR8"
  },
  {
    "uniprot": "A0A0N4UBS8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBS8"
  },
  {
    "uniprot": "A0A0N4UEJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEJ9"
  },
  {
    "uniprot": "A0A0N4UKB8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKB8"
  },
  {
    "uniprot": "A0A0N4U774",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U774"
  },
  {
    "uniprot": "A0A158Q4D2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4D2"
  },
  {
    "uniprot": "A0A0N4U7L0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7L0"
  },
  {
    "uniprot": "A0A0N4U2S9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2S9"
  },
  {
    "uniprot": "A0A3P7TBK9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TBK9"
  },
  {
    "uniprot": "A0A0N4UFZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFZ4"
  },
  {
    "uniprot": "A0A0N4U2K4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2K4"
  },
  {
    "uniprot": "A0A0N4U483",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U483"
  },
  {
    "uniprot": "A0A0N4U2B6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2B6"
  },
  {
    "uniprot": "A0A158Q503",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q503"
  },
  {
    "uniprot": "A0A158Q4S0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4S0"
  },
  {
    "uniprot": "A0A0N4U0S2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0S2"
  },
  {
    "uniprot": "A0A0N4UBE6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBE6"
  },
  {
    "uniprot": "A0A3P7PUY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PUY4"
  },
  {
    "uniprot": "A0A0N4UAG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAG3"
  },
  {
    "uniprot": "A0A0N4UQY9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQY9"
  },
  {
    "uniprot": "A0A0N4U536",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U536"
  },
  {
    "uniprot": "A0A0N4U6H1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6H1"
  },
  {
    "uniprot": "A0A0N4UP71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP71"
  },
  {
    "uniprot": "A0A0N4UAH8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAH8"
  },
  {
    "uniprot": "A0A0N4U6R5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6R5"
  },
  {
    "uniprot": "A0A0N4UQL5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQL5"
  },
  {
    "uniprot": "A0A0N4UHX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHX1"
  },
  {
    "uniprot": "A0A0N4URU0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URU0"
  },
  {
    "uniprot": "A0A0N4U572",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U572"
  },
  {
    "uniprot": "A0A3P7QK81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QK81"
  },
  {
    "uniprot": "A0A0N4UA97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA97"
  },
  {
    "uniprot": "A0A0N4URQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URQ6"
  },
  {
    "uniprot": "A0A0N4UBS2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBS2"
  },
  {
    "uniprot": "A0A0N4U6H4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6H4"
  },
  {
    "uniprot": "A0A0N4UID3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UID3"
  },
  {
    "uniprot": "A0A0N4U711",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U711"
  },
  {
    "uniprot": "A0A0N4UAH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAH4"
  },
  {
    "uniprot": "A0A0N4U1N0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1N0"
  },
  {
    "uniprot": "A0A0N4U2T4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2T4"
  },
  {
    "uniprot": "A0A0N4UQW4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQW4"
  },
  {
    "uniprot": "A0A0N4U1Q9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Q9"
  },
  {
    "uniprot": "A0A3P7P7K1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P7K1"
  },
  {
    "uniprot": "A0A158Q6F7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6F7"
  },
  {
    "uniprot": "A0A0N4UL74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL74"
  },
  {
    "uniprot": "A0A0N4UCK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCK2"
  },
  {
    "uniprot": "A0A0N4UC33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC33"
  },
  {
    "uniprot": "A0A0N4UDM3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDM3"
  },
  {
    "uniprot": "A0A0N4UFI8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFI8"
  },
  {
    "uniprot": "A0A3P7PCW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PCW0"
  },
  {
    "uniprot": "A0A3P7PQ07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQ07"
  },
  {
    "uniprot": "A0A0N4UEF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEF9"
  },
  {
    "uniprot": "A0A0N4USC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USC5"
  },
  {
    "uniprot": "A0A0N4U8J5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8J5"
  },
  {
    "uniprot": "A0A0N4UAV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAV6"
  },
  {
    "uniprot": "A0A3P7QIG5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QIG5"
  },
  {
    "uniprot": "A0A0N4U6H3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6H3"
  },
  {
    "uniprot": "A0A0N4UML3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UML3"
  },
  {
    "uniprot": "A0A0N4UR02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR02"
  },
  {
    "uniprot": "A0A0N4UPS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPS3"
  },
  {
    "uniprot": "A0A0N4UMI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMI1"
  },
  {
    "uniprot": "A0A0N4U418",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U418"
  },
  {
    "uniprot": "A0A0N4U973",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U973"
  },
  {
    "uniprot": "A0A0N4UNS5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNS5"
  },
  {
    "uniprot": "A0A0N4URP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URP9"
  },
  {
    "uniprot": "A0A158Q6K8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6K8"
  },
  {
    "uniprot": "A0A0N4UMM9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMM9"
  },
  {
    "uniprot": "A0A158Q5A6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5A6"
  },
  {
    "uniprot": "A0A0N4UG54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG54"
  },
  {
    "uniprot": "A0A0N4U9N1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9N1"
  },
  {
    "uniprot": "A0A0N4U8H5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8H5"
  },
  {
    "uniprot": "A0A0N4UPN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPN6"
  },
  {
    "uniprot": "A0A0N4USD3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USD3"
  },
  {
    "uniprot": "A0A0N4UFW4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFW4"
  },
  {
    "uniprot": "A0A0N4UNZ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNZ0"
  },
  {
    "uniprot": "A0A0N4UCB4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCB4"
  },
  {
    "uniprot": "A0A0N4URF2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URF2"
  },
  {
    "uniprot": "A0A0N4UPT4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPT4"
  },
  {
    "uniprot": "A0A0N4U2C0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2C0"
  },
  {
    "uniprot": "A0A158Q554",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q554"
  },
  {
    "uniprot": "A0A158Q3D6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3D6"
  },
  {
    "uniprot": "A0A0N4U9E6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9E6"
  },
  {
    "uniprot": "A0A0N4UAE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAE3"
  },
  {
    "uniprot": "A0A0N4U3Q5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Q5"
  },
  {
    "uniprot": "A0A158Q583",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q583"
  },
  {
    "uniprot": "A0A0N4U161",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U161"
  },
  {
    "uniprot": "A0A0N4UKW2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKW2"
  },
  {
    "uniprot": "A0A3P7PT13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PT13"
  },
  {
    "uniprot": "A0A0N4UBV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBV7"
  },
  {
    "uniprot": "A0A158Q2G8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2G8"
  },
  {
    "uniprot": "A0A0N4UAV5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAV5"
  },
  {
    "uniprot": "A0A0N4UB90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB90"
  },
  {
    "uniprot": "A0A0N4UBG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBG2"
  },
  {
    "uniprot": "A0A0N4UIL4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIL4"
  },
  {
    "uniprot": "A0A0N4U254",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U254"
  },
  {
    "uniprot": "A0A0N4UIC8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIC8"
  },
  {
    "uniprot": "A0A0N4U0G7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0G7"
  },
  {
    "uniprot": "A0A0N4UBK7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBK7"
  },
  {
    "uniprot": "A0A0N4UM60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM60"
  },
  {
    "uniprot": "A0A0N4U189",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U189"
  },
  {
    "uniprot": "A0A0N4U7R4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7R4"
  },
  {
    "uniprot": "A0A0N4UH43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH43"
  },
  {
    "uniprot": "A0A3P7QHC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QHC9"
  },
  {
    "uniprot": "A0A0N4U7R8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7R8"
  },
  {
    "uniprot": "A0A0N4UB34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB34"
  },
  {
    "uniprot": "A0A0N4UEP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEP4"
  },
  {
    "uniprot": "A0A3P7PHN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PHN3"
  },
  {
    "uniprot": "A0A0N4UD51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD51"
  },
  {
    "uniprot": "A0A0N4UKN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKN3"
  },
  {
    "uniprot": "A0A0N4UND6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UND6"
  },
  {
    "uniprot": "A0A0N4U432",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U432"
  },
  {
    "uniprot": "A0A0N4U7V2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7V2"
  },
  {
    "uniprot": "A0A0N4UI30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI30"
  },
  {
    "uniprot": "A0A0N4UCG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCG6"
  },
  {
    "uniprot": "A0A158Q5Q2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Q2"
  },
  {
    "uniprot": "A0A0N4UDT3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDT3"
  },
  {
    "uniprot": "A0A0N4UGD2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGD2"
  },
  {
    "uniprot": "A0A0N4U3I9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3I9"
  },
  {
    "uniprot": "A0A0N4U2K6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2K6"
  },
  {
    "uniprot": "A0A0N4UNS4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNS4"
  },
  {
    "uniprot": "A0A0N4U682",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U682"
  },
  {
    "uniprot": "A0A0N4U1P5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1P5"
  },
  {
    "uniprot": "A0A158Q639",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q639"
  },
  {
    "uniprot": "A0A3P7QWN7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QWN7"
  },
  {
    "uniprot": "A0A0N4U408",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U408"
  },
  {
    "uniprot": "A0A0N4UNE6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNE6"
  },
  {
    "uniprot": "A0A3P7PK92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PK92"
  },
  {
    "uniprot": "A0A0N4U0W8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0W8"
  },
  {
    "uniprot": "A0A0N4UD46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD46"
  },
  {
    "uniprot": "A0A0N4UC19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC19"
  },
  {
    "uniprot": "A0A0N4UP02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP02"
  },
  {
    "uniprot": "A0A0N4UKW6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKW6"
  },
  {
    "uniprot": "A0A0N4U188",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U188"
  },
  {
    "uniprot": "A0A0N4UK00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK00"
  },
  {
    "uniprot": "A0A0N4UCV2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCV2"
  },
  {
    "uniprot": "A0A0N4UR37",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR37"
  },
  {
    "uniprot": "A0A158Q5V3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5V3"
  },
  {
    "uniprot": "A0A0N4U203",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U203"
  },
  {
    "uniprot": "A0A0N4UG45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG45"
  },
  {
    "uniprot": "A0A0N4UJU8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJU8"
  },
  {
    "uniprot": "A0A0N4U7Z2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Z2"
  },
  {
    "uniprot": "A0A0N4UDX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDX5"
  },
  {
    "uniprot": "A0A0N4UH36",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH36"
  },
  {
    "uniprot": "A0A0N4US91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US91"
  },
  {
    "uniprot": "A0A0N4UMM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMM0"
  },
  {
    "uniprot": "A0A0N4U1B5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1B5"
  },
  {
    "uniprot": "A0A0N4UEL7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEL7"
  },
  {
    "uniprot": "A0A158Q2P5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2P5"
  },
  {
    "uniprot": "A0A0N4UME3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UME3"
  },
  {
    "uniprot": "A0A158Q4T3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4T3"
  },
  {
    "uniprot": "A0A3P7PLB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PLB9"
  },
  {
    "uniprot": "A0A0N4U5T7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5T7"
  },
  {
    "uniprot": "A0A0N4U153",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U153"
  },
  {
    "uniprot": "A0A0N4U3C5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3C5"
  },
  {
    "uniprot": "A0A0N4UF59",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF59"
  },
  {
    "uniprot": "A0A0N4U700",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U700"
  },
  {
    "uniprot": "A0A3P7SQU5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SQU5"
  },
  {
    "uniprot": "A0A158Q6E5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6E5"
  },
  {
    "uniprot": "A0A0N4URV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URV6"
  },
  {
    "uniprot": "A0A158Q2N0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2N0"
  },
  {
    "uniprot": "A0A0N4ULM7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULM7"
  },
  {
    "uniprot": "A0A0N4UNF0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNF0"
  },
  {
    "uniprot": "A0A0N4UCW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCW3"
  },
  {
    "uniprot": "A0A0N4U7Y1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Y1"
  },
  {
    "uniprot": "A0A0N4UEV1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEV1"
  },
  {
    "uniprot": "A0A0N4U911",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U911"
  },
  {
    "uniprot": "A0A0N4U378",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U378"
  },
  {
    "uniprot": "A0A0N4U268",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U268"
  },
  {
    "uniprot": "A0A0N4U9S1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9S1"
  },
  {
    "uniprot": "A0A0N4U437",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U437"
  },
  {
    "uniprot": "A0A0N4UG67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG67"
  },
  {
    "uniprot": "A0A0N4U8E2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8E2"
  },
  {
    "uniprot": "A0A0N4UII7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UII7"
  },
  {
    "uniprot": "A0A0N4U3G9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3G9"
  },
  {
    "uniprot": "A0A0N4UBC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBC1"
  },
  {
    "uniprot": "A0A3P7SKV2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SKV2"
  },
  {
    "uniprot": "A0A0N4U4K2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4K2"
  },
  {
    "uniprot": "A0A158Q5S3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5S3"
  },
  {
    "uniprot": "A0A0N4U5J5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5J5"
  },
  {
    "uniprot": "A0A3P7PTY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PTY0"
  },
  {
    "uniprot": "A0A0N4U567",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U567"
  },
  {
    "uniprot": "A0A0N4UQC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQC2"
  },
  {
    "uniprot": "A0A0N4UDF6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDF6"
  },
  {
    "uniprot": "A0A0N4UL12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL12"
  },
  {
    "uniprot": "A0A0N4UEM5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEM5"
  },
  {
    "uniprot": "A0A0N4UC37",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC37"
  },
  {
    "uniprot": "A0A0N4UB07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB07"
  },
  {
    "uniprot": "A0A0N4UE71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE71"
  },
  {
    "uniprot": "A0A3P7PY39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PY39"
  },
  {
    "uniprot": "A0A0N4U976",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U976"
  },
  {
    "uniprot": "A0A0N4URB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URB7"
  },
  {
    "uniprot": "A0A158Q5L7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5L7"
  },
  {
    "uniprot": "A0A0N4UJW1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJW1"
  },
  {
    "uniprot": "A0A0N4UQG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQG4"
  },
  {
    "uniprot": "A0A158Q4R9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4R9"
  },
  {
    "uniprot": "A0A0N4UKT5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKT5"
  },
  {
    "uniprot": "A0A0N4UL02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL02"
  },
  {
    "uniprot": "A0A158Q3K4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3K4"
  },
  {
    "uniprot": "A0A0N4UAV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAV8"
  },
  {
    "uniprot": "A0A0N4U9L1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9L1"
  },
  {
    "uniprot": "A0A0N4UI37",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI37"
  },
  {
    "uniprot": "A0A0N4UB06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB06"
  },
  {
    "uniprot": "A0A0N4U2C7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2C7"
  },
  {
    "uniprot": "A0A158Q3W1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3W1"
  },
  {
    "uniprot": "A0A0N4UAY3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAY3"
  },
  {
    "uniprot": "A0A0N4UM22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM22"
  },
  {
    "uniprot": "A0A158Q6K0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6K0"
  },
  {
    "uniprot": "A0A0N4U6N3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6N3"
  },
  {
    "uniprot": "A0A0N4U1R3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1R3"
  },
  {
    "uniprot": "A0A0N4URB4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URB4"
  },
  {
    "uniprot": "A0A0N4U7K1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7K1"
  },
  {
    "uniprot": "A0A3P7Q6G3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q6G3"
  },
  {
    "uniprot": "A0A158Q488",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q488"
  },
  {
    "uniprot": "A0A0N4U197",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U197"
  },
  {
    "uniprot": "A0A158Q5J0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5J0"
  },
  {
    "uniprot": "A0A0N4U4S0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4S0"
  },
  {
    "uniprot": "A0A158Q5N2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5N2"
  },
  {
    "uniprot": "A0A3P7SCT3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SCT3"
  },
  {
    "uniprot": "A0A158Q4K3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4K3"
  },
  {
    "uniprot": "A0A0N4U1M4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1M4"
  },
  {
    "uniprot": "A0A0N4UQ46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ46"
  },
  {
    "uniprot": "A0A158Q6H4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6H4"
  },
  {
    "uniprot": "A0A0N4UED7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UED7"
  },
  {
    "uniprot": "A0A0N4UMD6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMD6"
  },
  {
    "uniprot": "A0A158Q3Q0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Q0"
  },
  {
    "uniprot": "A0A0N4UL85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL85"
  },
  {
    "uniprot": "A0A0N4U5A8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5A8"
  },
  {
    "uniprot": "A0A0N4UD79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD79"
  },
  {
    "uniprot": "A0A158Q5G6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5G6"
  },
  {
    "uniprot": "A0A0N4UBX2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBX2"
  },
  {
    "uniprot": "A0A0N4U519",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U519"
  },
  {
    "uniprot": "A0A0N4ULQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULQ1"
  },
  {
    "uniprot": "A0A0N4UMH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMH9"
  },
  {
    "uniprot": "A0A0N4UDA3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDA3"
  },
  {
    "uniprot": "A0A0N4U898",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U898"
  },
  {
    "uniprot": "A0A0N4U0E2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0E2"
  },
  {
    "uniprot": "A0A0N4US96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US96"
  },
  {
    "uniprot": "A0A0N4UFR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFR2"
  },
  {
    "uniprot": "A0A0N4UKZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKZ1"
  },
  {
    "uniprot": "A0A0N4U979",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U979"
  },
  {
    "uniprot": "A0A3P7Q9B2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q9B2"
  },
  {
    "uniprot": "A0A0N4UKP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKP9"
  },
  {
    "uniprot": "A0A0N4UC48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC48"
  },
  {
    "uniprot": "A0A0N4UF38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF38"
  },
  {
    "uniprot": "A0A3P7SVI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SVI0"
  },
  {
    "uniprot": "A0A158Q5H6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5H6"
  },
  {
    "uniprot": "A0A0N4UK63",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK63"
  },
  {
    "uniprot": "A0A0N4UIH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIH3"
  },
  {
    "uniprot": "A0A0N4U964",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U964"
  },
  {
    "uniprot": "A0A0N4U5U3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5U3"
  },
  {
    "uniprot": "A0A0N4U1K7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1K7"
  },
  {
    "uniprot": "A0A0N4U0S8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0S8"
  },
  {
    "uniprot": "A0A0N4UN10",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN10"
  },
  {
    "uniprot": "A0A0N4U3W7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3W7"
  },
  {
    "uniprot": "A0A0N4UHD5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHD5"
  },
  {
    "uniprot": "A0A0N4UNV1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNV1"
  },
  {
    "uniprot": "A0A158Q5E5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5E5"
  },
  {
    "uniprot": "A0A0N4UE48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE48"
  },
  {
    "uniprot": "A0A0N4UGD5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGD5"
  },
  {
    "uniprot": "A0A0N4UNR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNR9"
  },
  {
    "uniprot": "A0A0N4UEA7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEA7"
  },
  {
    "uniprot": "A0A0N4U0H7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0H7"
  },
  {
    "uniprot": "A0A158Q4Q2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Q2"
  },
  {
    "uniprot": "A0A0N4UB82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB82"
  },
  {
    "uniprot": "A0A0N4U1W2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1W2"
  },
  {
    "uniprot": "A0A3P7SCF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SCF9"
  },
  {
    "uniprot": "A0A0N4UIB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIB1"
  },
  {
    "uniprot": "A0A0N4U7G1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7G1"
  },
  {
    "uniprot": "A0A0N4UGC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGC5"
  },
  {
    "uniprot": "A0A0N4URB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URB5"
  },
  {
    "uniprot": "A0A158Q4L3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4L3"
  },
  {
    "uniprot": "A0A158Q6B8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6B8"
  },
  {
    "uniprot": "A0A0N4U9I5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9I5"
  },
  {
    "uniprot": "A0A0N4U576",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U576"
  },
  {
    "uniprot": "A0A0N4UBE5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBE5"
  },
  {
    "uniprot": "A0A0N4UFH0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFH0"
  },
  {
    "uniprot": "A0A158Q2X1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2X1"
  },
  {
    "uniprot": "A0A0N4ULC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULC3"
  },
  {
    "uniprot": "A0A0N4U0G5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0G5"
  },
  {
    "uniprot": "A0A158Q4T9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4T9"
  },
  {
    "uniprot": "A0A0N4U3J7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3J7"
  },
  {
    "uniprot": "A0A0N4UNW5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNW5"
  },
  {
    "uniprot": "A0A0N4U7E5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7E5"
  },
  {
    "uniprot": "A0A0N4UJX4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJX4"
  },
  {
    "uniprot": "A0A158Q6D5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6D5"
  },
  {
    "uniprot": "A0A3P7SMQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SMQ7"
  },
  {
    "uniprot": "A0A3P7S8Z0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S8Z0"
  },
  {
    "uniprot": "A0A0N4UHP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHP9"
  },
  {
    "uniprot": "A0A3P7QMG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QMG9"
  },
  {
    "uniprot": "A0A3P7PLI2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PLI2"
  },
  {
    "uniprot": "A0A0N4UCZ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCZ6"
  },
  {
    "uniprot": "A0A3P7PQ00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQ00"
  },
  {
    "uniprot": "A0A0N4U2A9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2A9"
  },
  {
    "uniprot": "A0A158Q3C2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3C2"
  },
  {
    "uniprot": "A0A0N4U138",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U138"
  },
  {
    "uniprot": "A0A0N4U264",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U264"
  },
  {
    "uniprot": "A0A3P7PP30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PP30"
  },
  {
    "uniprot": "A0A3P7SUW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SUW3"
  },
  {
    "uniprot": "A0A158Q553",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q553"
  },
  {
    "uniprot": "A0A0N4UJM9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJM9"
  },
  {
    "uniprot": "A0A0N4UKB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKB1"
  },
  {
    "uniprot": "A0A0N4UNN4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNN4"
  },
  {
    "uniprot": "A0A0N4UCC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCC6"
  },
  {
    "uniprot": "A0A0N4UC82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC82"
  },
  {
    "uniprot": "A0A0N4UC68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC68"
  },
  {
    "uniprot": "A0A158Q5A4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5A4"
  },
  {
    "uniprot": "A0A3P7PN41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PN41"
  },
  {
    "uniprot": "A0A0N4US72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US72"
  },
  {
    "uniprot": "A0A158Q4B3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4B3"
  },
  {
    "uniprot": "A0A0N4UQU5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQU5"
  },
  {
    "uniprot": "A0A158Q5E2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5E2"
  },
  {
    "uniprot": "A0A0N4UI23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI23"
  },
  {
    "uniprot": "A0A0N4UKP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKP7"
  },
  {
    "uniprot": "A0A158Q505",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q505"
  },
  {
    "uniprot": "A0A0N4UPP5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPP5"
  },
  {
    "uniprot": "A0A0N4UHG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHG0"
  },
  {
    "uniprot": "A0A0N4UFK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFK5"
  },
  {
    "uniprot": "A0A0N4UNW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNW3"
  },
  {
    "uniprot": "A0A0N4UGA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGA0"
  },
  {
    "uniprot": "A0A0N4UNA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNA4"
  },
  {
    "uniprot": "A0A0N4UHK7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHK7"
  },
  {
    "uniprot": "A0A158Q5W7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5W7"
  },
  {
    "uniprot": "A0A0N4UPD5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPD5"
  },
  {
    "uniprot": "A0A0N4U6K2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6K2"
  },
  {
    "uniprot": "A0A0N4UNT1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNT1"
  },
  {
    "uniprot": "A0A0N4UCV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCV6"
  },
  {
    "uniprot": "A0A3P7PPM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPM0"
  },
  {
    "uniprot": "A0A3P7QEG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QEG0"
  },
  {
    "uniprot": "A0A0N4UF94",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF94"
  },
  {
    "uniprot": "A0A0N4U719",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U719"
  },
  {
    "uniprot": "A0A0N4U8E9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8E9"
  },
  {
    "uniprot": "A0A0N4UEU8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEU8"
  },
  {
    "uniprot": "A0A0N4U436",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U436"
  },
  {
    "uniprot": "A0A0N4UKL6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKL6"
  },
  {
    "uniprot": "A0A0N4UCT0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCT0"
  },
  {
    "uniprot": "A0A0N4ULT8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULT8"
  },
  {
    "uniprot": "A0A0N4U851",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U851"
  },
  {
    "uniprot": "A0A0N4UN35",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN35"
  },
  {
    "uniprot": "A0A3P7T3T6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T3T6"
  },
  {
    "uniprot": "A0A0N4UMB4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMB4"
  },
  {
    "uniprot": "A0A0N4U7C6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7C6"
  },
  {
    "uniprot": "A0A3P7S6Q2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S6Q2"
  },
  {
    "uniprot": "A0A3P7SRZ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SRZ8"
  },
  {
    "uniprot": "A0A0N4U222",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U222"
  },
  {
    "uniprot": "A0A0N4UN76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN76"
  },
  {
    "uniprot": "A0A3P7PUH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PUH9"
  },
  {
    "uniprot": "A0A0N4U286",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U286"
  },
  {
    "uniprot": "A0A0N4UPQ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPQ8"
  },
  {
    "uniprot": "A0A3P7Q9P8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q9P8"
  },
  {
    "uniprot": "A0A0N4UBM9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBM9"
  },
  {
    "uniprot": "A0A0N4UES6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UES6"
  },
  {
    "uniprot": "A0A3P7PXF0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PXF0"
  },
  {
    "uniprot": "A0A0N4UCL4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCL4"
  },
  {
    "uniprot": "A0A0N4ULK8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULK8"
  },
  {
    "uniprot": "A0A0N4UIG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIG4"
  },
  {
    "uniprot": "A0A0N4UAE8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAE8"
  },
  {
    "uniprot": "A0A0N4U331",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U331"
  },
  {
    "uniprot": "A0A0N4UEE8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEE8"
  },
  {
    "uniprot": "A0A0N4ULR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULR4"
  },
  {
    "uniprot": "A0A0N4U514",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U514"
  },
  {
    "uniprot": "A0A0N4U3L5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3L5"
  },
  {
    "uniprot": "A0A0N4UQD8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQD8"
  },
  {
    "uniprot": "A0A0N4UH70",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH70"
  },
  {
    "uniprot": "A0A0N4UK09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK09"
  },
  {
    "uniprot": "A0A0N4U4R7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4R7"
  },
  {
    "uniprot": "A0A0N4UMU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMU7"
  },
  {
    "uniprot": "A0A0N4U717",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U717"
  },
  {
    "uniprot": "A0A0N4UFX4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFX4"
  },
  {
    "uniprot": "A0A0N4UME0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UME0"
  },
  {
    "uniprot": "A0A0N4UE26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE26"
  },
  {
    "uniprot": "A0A0N4UKG5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKG5"
  },
  {
    "uniprot": "A0A0N4U6I7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6I7"
  },
  {
    "uniprot": "A0A158Q678",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q678"
  },
  {
    "uniprot": "A0A0N4U427",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U427"
  },
  {
    "uniprot": "A0A0N4UGQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGQ6"
  },
  {
    "uniprot": "A0A0N4UK14",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK14"
  },
  {
    "uniprot": "A0A0N4UHZ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHZ9"
  },
  {
    "uniprot": "A0A0N4UNU1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNU1"
  },
  {
    "uniprot": "A0A0N4U1B3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1B3"
  },
  {
    "uniprot": "A0A0N4UGC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGC6"
  },
  {
    "uniprot": "A0A3P7QD24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QD24"
  },
  {
    "uniprot": "A0A0N4UCN7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCN7"
  },
  {
    "uniprot": "A0A0N4U8R9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8R9"
  },
  {
    "uniprot": "A0A0N4UCY8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCY8"
  },
  {
    "uniprot": "A0A0N4UIC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIC5"
  },
  {
    "uniprot": "A0A0N4U0L9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0L9"
  },
  {
    "uniprot": "A0A0N4UQD2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQD2"
  },
  {
    "uniprot": "A0A158Q3P5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3P5"
  },
  {
    "uniprot": "A0A158Q4S3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4S3"
  },
  {
    "uniprot": "A0A0N4U8S7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8S7"
  },
  {
    "uniprot": "A0A0N4UR09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR09"
  },
  {
    "uniprot": "A0A0N4U677",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U677"
  },
  {
    "uniprot": "A0A0N4UF67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF67"
  },
  {
    "uniprot": "A0A0N4U7U0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7U0"
  },
  {
    "uniprot": "A0A0N4UDB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDB5"
  },
  {
    "uniprot": "A0A0N4UHS5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHS5"
  },
  {
    "uniprot": "A0A0N4UFX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFX7"
  },
  {
    "uniprot": "A0A158Q3E6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3E6"
  },
  {
    "uniprot": "A0A0N4UK28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK28"
  },
  {
    "uniprot": "A0A0N4UM03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM03"
  },
  {
    "uniprot": "A0A0N4UI12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI12"
  },
  {
    "uniprot": "A0A0N4U984",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U984"
  },
  {
    "uniprot": "A0A0N4UDT8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDT8"
  },
  {
    "uniprot": "A0A0N4UJZ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJZ6"
  },
  {
    "uniprot": "A0A0N4UNF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNF9"
  },
  {
    "uniprot": "A0A0N4UQ58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ58"
  },
  {
    "uniprot": "A0A0N4UMQ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMQ9"
  },
  {
    "uniprot": "A0A0N4UIX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIX7"
  },
  {
    "uniprot": "A0A3P7T5H7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T5H7"
  },
  {
    "uniprot": "A0A0N4UKV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKV6"
  },
  {
    "uniprot": "A0A0N4UPY5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPY5"
  },
  {
    "uniprot": "A0A0N4U231",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U231"
  },
  {
    "uniprot": "A0A0N4U7H7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7H7"
  },
  {
    "uniprot": "A0A0N4U7M8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7M8"
  },
  {
    "uniprot": "A0A0N4U712",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U712"
  },
  {
    "uniprot": "A0A0N4U3R2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3R2"
  },
  {
    "uniprot": "A0A0N4U6A6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6A6"
  },
  {
    "uniprot": "A0A0N4UD62",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD62"
  },
  {
    "uniprot": "A0A0N4UKR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKR2"
  },
  {
    "uniprot": "A0A0N4U333",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U333"
  },
  {
    "uniprot": "A0A158Q545",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q545"
  },
  {
    "uniprot": "A0A0N4UAE9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAE9"
  },
  {
    "uniprot": "A0A0N4UKG8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKG8"
  },
  {
    "uniprot": "A0A0N4U7I6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7I6"
  },
  {
    "uniprot": "A0A0N4U886",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U886"
  },
  {
    "uniprot": "A0A0N4UFA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFA9"
  },
  {
    "uniprot": "A0A0N4UDR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDR1"
  },
  {
    "uniprot": "A0A158Q3G9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3G9"
  },
  {
    "uniprot": "A0A0N4UIR3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIR3"
  },
  {
    "uniprot": "A0A0N4U995",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U995"
  },
  {
    "uniprot": "A0A0N4UKY9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKY9"
  },
  {
    "uniprot": "A0A0N4UFJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFJ2"
  },
  {
    "uniprot": "A0A158Q4D0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4D0"
  },
  {
    "uniprot": "A0A0N4UCA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCA5"
  },
  {
    "uniprot": "A0A0N4U813",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U813"
  },
  {
    "uniprot": "A0A0N4ULL2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULL2"
  },
  {
    "uniprot": "A0A0N4UMI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMI9"
  },
  {
    "uniprot": "A0A0N4U7I1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7I1"
  },
  {
    "uniprot": "A0A0N4UBM3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBM3"
  },
  {
    "uniprot": "A0A0N4UJM7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJM7"
  },
  {
    "uniprot": "A0A0N4UCN1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCN1"
  },
  {
    "uniprot": "A0A0N4U2N2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2N2"
  },
  {
    "uniprot": "A0A0N4U419",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U419"
  },
  {
    "uniprot": "A0A3P7PYJ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PYJ6"
  },
  {
    "uniprot": "A0A0N4UMB6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMB6"
  },
  {
    "uniprot": "A0A3P7PR62",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PR62"
  },
  {
    "uniprot": "A0A158Q370",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q370"
  },
  {
    "uniprot": "A0A158Q4T5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4T5"
  },
  {
    "uniprot": "A0A0N4U922",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U922"
  },
  {
    "uniprot": "A0A158Q4I4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4I4"
  },
  {
    "uniprot": "A0A0N4U9X7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9X7"
  },
  {
    "uniprot": "A0A0N4UGL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGL9"
  },
  {
    "uniprot": "A0A0N4U710",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U710"
  },
  {
    "uniprot": "A0A0N4UA44",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA44"
  },
  {
    "uniprot": "A0A158Q3P9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3P9"
  },
  {
    "uniprot": "A0A0N4U518",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U518"
  },
  {
    "uniprot": "A0A0N4U2S6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2S6"
  },
  {
    "uniprot": "A0A0N4UCR0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCR0"
  },
  {
    "uniprot": "A0A158Q3L1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3L1"
  },
  {
    "uniprot": "A0A0N4U621",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U621"
  },
  {
    "uniprot": "A0A0N4U5T2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5T2"
  },
  {
    "uniprot": "A0A158Q4A4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4A4"
  },
  {
    "uniprot": "A0A0N4U3D5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3D5"
  },
  {
    "uniprot": "A0A0N4U7T5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7T5"
  },
  {
    "uniprot": "A0A0N4U7W8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7W8"
  },
  {
    "uniprot": "A0A0N4UGB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGB5"
  },
  {
    "uniprot": "A0A158Q3H5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3H5"
  },
  {
    "uniprot": "A0A0N4U566",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U566"
  },
  {
    "uniprot": "A0A3P7PVK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PVK5"
  },
  {
    "uniprot": "A0A0N4U701",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U701"
  },
  {
    "uniprot": "A0A0N4UDQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDQ5"
  },
  {
    "uniprot": "A0A0N4UHS7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHS7"
  },
  {
    "uniprot": "A0A0N4U2U3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2U3"
  },
  {
    "uniprot": "A0A0N4UJW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJW8"
  },
  {
    "uniprot": "A0A3P7PRH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PRH3"
  },
  {
    "uniprot": "A0A0N4U124",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U124"
  },
  {
    "uniprot": "A0A0N4UG73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG73"
  },
  {
    "uniprot": "A0A3P7PT76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PT76"
  },
  {
    "uniprot": "A0A0N4UNQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNQ4"
  },
  {
    "uniprot": "A0A3P7QHG1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QHG1"
  },
  {
    "uniprot": "A0A0N4ULT5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULT5"
  },
  {
    "uniprot": "A0A0N4UCB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCB9"
  },
  {
    "uniprot": "A0A0N4U4Q6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Q6"
  },
  {
    "uniprot": "A0A0N4UHG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHG9"
  },
  {
    "uniprot": "A0A0N4UE61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE61"
  },
  {
    "uniprot": "A0A0N4UFI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFI3"
  },
  {
    "uniprot": "A0A0N4UJT9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJT9"
  },
  {
    "uniprot": "A0A0N4U1I3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1I3"
  },
  {
    "uniprot": "A0A0N4U4H7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4H7"
  },
  {
    "uniprot": "A0A158Q537",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q537"
  },
  {
    "uniprot": "A0A158Q6I8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6I8"
  },
  {
    "uniprot": "A0A0N4UMX4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMX4"
  },
  {
    "uniprot": "A0A3P7SRT0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SRT0"
  },
  {
    "uniprot": "A0A0N4UPH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPH3"
  },
  {
    "uniprot": "A0A0N4UJL5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJL5"
  },
  {
    "uniprot": "A0A158Q3Q3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Q3"
  },
  {
    "uniprot": "A0A0N4UGJ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGJ0"
  },
  {
    "uniprot": "A0A0N4U6D9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6D9"
  },
  {
    "uniprot": "A0A158Q3W7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3W7"
  },
  {
    "uniprot": "A0A0N4UAF2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAF2"
  },
  {
    "uniprot": "A0A158Q5M4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5M4"
  },
  {
    "uniprot": "A0A158Q4N4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4N4"
  },
  {
    "uniprot": "A0A0N4U6X7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6X7"
  },
  {
    "uniprot": "A0A0N4UCM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCM8"
  },
  {
    "uniprot": "A0A0N4UQT5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQT5"
  },
  {
    "uniprot": "A0A0N4UIC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIC7"
  },
  {
    "uniprot": "A0A3P7QPP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QPP6"
  },
  {
    "uniprot": "A0A0N4U7A7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7A7"
  },
  {
    "uniprot": "A0A0N4UR64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR64"
  },
  {
    "uniprot": "A0A0N4UFS8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFS8"
  },
  {
    "uniprot": "A0A158Q5I8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5I8"
  },
  {
    "uniprot": "A0A158Q5M6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5M6"
  },
  {
    "uniprot": "A0A0N4URG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URG6"
  },
  {
    "uniprot": "A0A158Q2V4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2V4"
  },
  {
    "uniprot": "A0A0N4UNN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNN0"
  },
  {
    "uniprot": "A0A0N4U101",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U101"
  },
  {
    "uniprot": "A0A0N4UPB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPB0"
  },
  {
    "uniprot": "A0A0N4UP95",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP95"
  },
  {
    "uniprot": "A0A0N4U1C7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1C7"
  },
  {
    "uniprot": "A0A0N4UII5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UII5"
  },
  {
    "uniprot": "A0A0N4UA36",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA36"
  },
  {
    "uniprot": "A0A0N4UET9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UET9"
  },
  {
    "uniprot": "A0A0N4UR08",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR08"
  },
  {
    "uniprot": "A0A0N4UH40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH40"
  },
  {
    "uniprot": "A0A0N4U8N0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8N0"
  },
  {
    "uniprot": "A0A158Q548",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q548"
  },
  {
    "uniprot": "A0A0N4U4I9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4I9"
  },
  {
    "uniprot": "A0A0N4U5M4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5M4"
  },
  {
    "uniprot": "A0A0N4U349",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U349"
  },
  {
    "uniprot": "A0A0N4U7E2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7E2"
  },
  {
    "uniprot": "A0A158Q5W4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5W4"
  },
  {
    "uniprot": "A0A3P7QH06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QH06"
  },
  {
    "uniprot": "A0A158Q5T1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5T1"
  },
  {
    "uniprot": "A0A0N4UC22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC22"
  },
  {
    "uniprot": "A0A158Q5L0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5L0"
  },
  {
    "uniprot": "A0A0N4UGF8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGF8"
  },
  {
    "uniprot": "A0A158Q5X9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5X9"
  },
  {
    "uniprot": "A0A3P7Q3B9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q3B9"
  },
  {
    "uniprot": "A0A0N4UKC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKC2"
  },
  {
    "uniprot": "A0A0N4UP59",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP59"
  },
  {
    "uniprot": "A0A0N4UMJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMJ4"
  },
  {
    "uniprot": "A0A0N4URT1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URT1"
  },
  {
    "uniprot": "A0A0N4UBH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBH6"
  },
  {
    "uniprot": "A0A0N4UA10",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA10"
  },
  {
    "uniprot": "A0A0N4UJF0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJF0"
  },
  {
    "uniprot": "A0A0N4U440",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U440"
  },
  {
    "uniprot": "A0A158Q4X5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4X5"
  },
  {
    "uniprot": "A0A0N4U5B4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5B4"
  },
  {
    "uniprot": "A0A3P7PTW2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PTW2"
  },
  {
    "uniprot": "A0A3P7QDK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QDK0"
  },
  {
    "uniprot": "A0A0N4UMJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMJ9"
  },
  {
    "uniprot": "A0A0N4U4Y5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Y5"
  },
  {
    "uniprot": "A0A0N4UF79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF79"
  },
  {
    "uniprot": "A0A0N4UP53",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP53"
  },
  {
    "uniprot": "A0A158Q407",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q407"
  },
  {
    "uniprot": "A0A0N4UGL6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGL6"
  },
  {
    "uniprot": "A0A3P7PYF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PYF5"
  },
  {
    "uniprot": "A0A0N4U4K5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4K5"
  },
  {
    "uniprot": "A0A3P7PS30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PS30"
  },
  {
    "uniprot": "A0A3P7S5P1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S5P1"
  },
  {
    "uniprot": "A0A158Q482",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q482"
  },
  {
    "uniprot": "A0A0N4UDZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDZ1"
  },
  {
    "uniprot": "A0A0N4UFY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFY1"
  },
  {
    "uniprot": "A0A0N4UEH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEH2"
  },
  {
    "uniprot": "A0A0N4U1L0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1L0"
  },
  {
    "uniprot": "A0A0N4UN07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN07"
  },
  {
    "uniprot": "A0A0N4UFE1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFE1"
  },
  {
    "uniprot": "A0A0N4UNG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNG0"
  },
  {
    "uniprot": "A0A0N4UCC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCC3"
  },
  {
    "uniprot": "A0A0N4UCF4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCF4"
  },
  {
    "uniprot": "A0A3P7PF56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PF56"
  },
  {
    "uniprot": "A0A0N4UBD1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBD1"
  },
  {
    "uniprot": "A0A158Q6J5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6J5"
  },
  {
    "uniprot": "A0A3P7SAM1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SAM1"
  },
  {
    "uniprot": "A0A0N4U9S5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9S5"
  },
  {
    "uniprot": "A0A0N4U382",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U382"
  },
  {
    "uniprot": "A0A158Q5S2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5S2"
  },
  {
    "uniprot": "A0A0N4U3F6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3F6"
  },
  {
    "uniprot": "A0A0N4UA28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA28"
  },
  {
    "uniprot": "A0A3P7PPH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPH3"
  },
  {
    "uniprot": "A0A0N4UIL7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIL7"
  },
  {
    "uniprot": "A0A0N4UBZ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBZ9"
  },
  {
    "uniprot": "A0A3P7PR29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PR29"
  },
  {
    "uniprot": "A0A0N4U2E6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2E6"
  },
  {
    "uniprot": "A0A0N4U3U9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3U9"
  },
  {
    "uniprot": "A0A3P7SNJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SNJ9"
  },
  {
    "uniprot": "A0A0N4U9U6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9U6"
  },
  {
    "uniprot": "A0A0N4UM92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM92"
  },
  {
    "uniprot": "A0A0N4U766",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U766"
  },
  {
    "uniprot": "A0A0N4UQ81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ81"
  },
  {
    "uniprot": "A0A158Q3H7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3H7"
  },
  {
    "uniprot": "A0A0N4URD6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URD6"
  },
  {
    "uniprot": "A0A0N4UKD4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKD4"
  },
  {
    "uniprot": "A0A0N4UMA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMA4"
  },
  {
    "uniprot": "A0A0N4U5V4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5V4"
  },
  {
    "uniprot": "A0A0N4U9W1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9W1"
  },
  {
    "uniprot": "A0A0N4UJ57",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ57"
  },
  {
    "uniprot": "A0A158Q3C7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3C7"
  },
  {
    "uniprot": "A0A3P7PLN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PLN6"
  },
  {
    "uniprot": "A0A0N4USC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USC1"
  },
  {
    "uniprot": "A0A0N4UH11",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH11"
  },
  {
    "uniprot": "A0A0N4ULV0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULV0"
  },
  {
    "uniprot": "A0A0N4UF43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF43"
  },
  {
    "uniprot": "A0A0N4U8N6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8N6"
  },
  {
    "uniprot": "A0A0N4U5I8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5I8"
  },
  {
    "uniprot": "A0A0N4U7A3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7A3"
  },
  {
    "uniprot": "A0A3P7PX79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PX79"
  },
  {
    "uniprot": "A0A0N4UF24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF24"
  },
  {
    "uniprot": "A0A0N4U152",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U152"
  },
  {
    "uniprot": "A0A0N4URR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URR2"
  },
  {
    "uniprot": "A0A0N4UM53",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM53"
  },
  {
    "uniprot": "A0A0N4UFD6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFD6"
  },
  {
    "uniprot": "A0A0N4U0H3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0H3"
  },
  {
    "uniprot": "A0A0N4UNA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNA5"
  },
  {
    "uniprot": "A0A0N4U0I6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0I6"
  },
  {
    "uniprot": "A0A158Q4E6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4E6"
  },
  {
    "uniprot": "A0A0N4U2U5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2U5"
  },
  {
    "uniprot": "A0A158Q5J1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5J1"
  },
  {
    "uniprot": "A0A0N4UE08",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE08"
  },
  {
    "uniprot": "A0A0N4UDP5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDP5"
  },
  {
    "uniprot": "A0A0N4U2B3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2B3"
  },
  {
    "uniprot": "A0A158Q306",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q306"
  },
  {
    "uniprot": "A0A0N4U347",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U347"
  },
  {
    "uniprot": "A0A0N4UKA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKA8"
  },
  {
    "uniprot": "A0A0N4U9E1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9E1"
  },
  {
    "uniprot": "A0A0N4UEZ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEZ7"
  },
  {
    "uniprot": "A0A0N4U5V8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5V8"
  },
  {
    "uniprot": "A0A0N4U1R6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1R6"
  },
  {
    "uniprot": "A0A0N4UR71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR71"
  },
  {
    "uniprot": "A0A0N4UQX4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQX4"
  },
  {
    "uniprot": "A0A158Q632",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q632"
  },
  {
    "uniprot": "A0A0N4UNK4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNK4"
  },
  {
    "uniprot": "A0A3P7QNS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QNS3"
  },
  {
    "uniprot": "A0A3P7PRP8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PRP8"
  },
  {
    "uniprot": "A0A0N4U1F7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1F7"
  },
  {
    "uniprot": "A0A0N4UFY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFY4"
  },
  {
    "uniprot": "A0A0N4U8V9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8V9"
  },
  {
    "uniprot": "A0A0N4UIU0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIU0"
  },
  {
    "uniprot": "A0A0N4U171",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U171"
  },
  {
    "uniprot": "A0A0N4UKJ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKJ6"
  },
  {
    "uniprot": "A0A158Q652",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q652"
  },
  {
    "uniprot": "A0A0N4U787",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U787"
  },
  {
    "uniprot": "A0A0N4U709",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U709"
  },
  {
    "uniprot": "A0A0N4UM49",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM49"
  },
  {
    "uniprot": "A0A0N4UAH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAH2"
  },
  {
    "uniprot": "A0A0N4UCH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCH9"
  },
  {
    "uniprot": "A0A0N4UDK4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDK4"
  },
  {
    "uniprot": "A0A0N4U3M6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3M6"
  },
  {
    "uniprot": "A0A0N4UCG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCG0"
  },
  {
    "uniprot": "A0A0N4UR45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR45"
  },
  {
    "uniprot": "A0A0N4U5Y7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Y7"
  },
  {
    "uniprot": "A0A0N4ULD5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULD5"
  },
  {
    "uniprot": "A0A158Q422",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q422"
  },
  {
    "uniprot": "A0A3P7PUN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PUN0"
  },
  {
    "uniprot": "A0A0N4U635",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U635"
  },
  {
    "uniprot": "A0A158Q4T7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4T7"
  },
  {
    "uniprot": "A0A3P7PDE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PDE3"
  },
  {
    "uniprot": "A0A0N4UFU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFU7"
  },
  {
    "uniprot": "A0A0N4UHZ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHZ7"
  },
  {
    "uniprot": "A0A0N4UDH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDH3"
  },
  {
    "uniprot": "A0A0N4U771",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U771"
  },
  {
    "uniprot": "A0A0N4U3D8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3D8"
  },
  {
    "uniprot": "A0A0N4U6S4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6S4"
  },
  {
    "uniprot": "A0A0N4UQ88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ88"
  },
  {
    "uniprot": "A0A0N4UBG7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBG7"
  },
  {
    "uniprot": "A0A3P7SD37",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SD37"
  },
  {
    "uniprot": "A0A0N4UMI7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMI7"
  },
  {
    "uniprot": "A0A0N4UQM3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQM3"
  },
  {
    "uniprot": "A0A0N4UJJ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJJ0"
  },
  {
    "uniprot": "A0A0N4U957",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U957"
  },
  {
    "uniprot": "A0A158Q465",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q465"
  },
  {
    "uniprot": "A0A0N4U632",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U632"
  },
  {
    "uniprot": "A0A158Q5J4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5J4"
  },
  {
    "uniprot": "A0A158Q4G5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4G5"
  },
  {
    "uniprot": "A0A0N4UHE6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHE6"
  },
  {
    "uniprot": "A0A3P7PZ39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PZ39"
  },
  {
    "uniprot": "A0A0N4U935",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U935"
  },
  {
    "uniprot": "A0A0N4UK91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK91"
  },
  {
    "uniprot": "A0A0N4UPA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPA8"
  },
  {
    "uniprot": "A0A0N4ULL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULL3"
  },
  {
    "uniprot": "A0A0N4UK94",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK94"
  },
  {
    "uniprot": "A0A0N4UF72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF72"
  },
  {
    "uniprot": "A0A0N4UKC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKC4"
  },
  {
    "uniprot": "A0A0N4U164",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U164"
  },
  {
    "uniprot": "A0A158Q4T0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4T0"
  },
  {
    "uniprot": "A0A0N4UKZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKZ4"
  },
  {
    "uniprot": "A0A158Q605",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q605"
  },
  {
    "uniprot": "A0A0N4U7Y9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Y9"
  },
  {
    "uniprot": "A0A158Q650",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q650"
  },
  {
    "uniprot": "A0A0N4UDF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDF5"
  },
  {
    "uniprot": "A0A158Q3P3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3P3"
  },
  {
    "uniprot": "A0A3P7P8I1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P8I1"
  },
  {
    "uniprot": "A0A0N4U1A4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1A4"
  },
  {
    "uniprot": "A0A0N4UJK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJK6"
  },
  {
    "uniprot": "A0A0N4UII4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UII4"
  },
  {
    "uniprot": "A0A3P7T168",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T168"
  },
  {
    "uniprot": "A0A3P7Q6L4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q6L4"
  },
  {
    "uniprot": "A0A0N4UHX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHX7"
  },
  {
    "uniprot": "A0A0N4UCZ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCZ9"
  },
  {
    "uniprot": "A0A0N4UI44",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI44"
  },
  {
    "uniprot": "A0A158Q2L0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2L0"
  },
  {
    "uniprot": "A0A0N4UC42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC42"
  },
  {
    "uniprot": "A0A0N4UPC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPC7"
  },
  {
    "uniprot": "A0A0N4U1T0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1T0"
  },
  {
    "uniprot": "A0A0N4U236",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U236"
  },
  {
    "uniprot": "A0A3P7PTH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PTH6"
  },
  {
    "uniprot": "A0A158Q3N8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3N8"
  },
  {
    "uniprot": "A0A3P7SR03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SR03"
  },
  {
    "uniprot": "A0A0N4U2R0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2R0"
  },
  {
    "uniprot": "A0A0N4U997",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U997"
  },
  {
    "uniprot": "A0A0N4UFM6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFM6"
  },
  {
    "uniprot": "A0A0N4U1Y1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Y1"
  },
  {
    "uniprot": "A0A0N4UNF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNF3"
  },
  {
    "uniprot": "A0A0N4U6I5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6I5"
  },
  {
    "uniprot": "A0A0N4UC76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC76"
  },
  {
    "uniprot": "A0A0N4U4G3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4G3"
  },
  {
    "uniprot": "A0A0N4UPJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPJ9"
  },
  {
    "uniprot": "A0A0N4UF75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF75"
  },
  {
    "uniprot": "A0A0N4UR89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR89"
  },
  {
    "uniprot": "A0A0N4UQI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQI9"
  },
  {
    "uniprot": "A0A0N4UDK9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDK9"
  },
  {
    "uniprot": "A0A0N4UR21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR21"
  },
  {
    "uniprot": "A0A0N4UC92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC92"
  },
  {
    "uniprot": "A0A0N4U7J8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7J8"
  },
  {
    "uniprot": "A0A0N4USA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USA1"
  },
  {
    "uniprot": "A0A0N4U1F2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1F2"
  },
  {
    "uniprot": "A0A0N4U1Z0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Z0"
  },
  {
    "uniprot": "A0A0N4U841",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U841"
  },
  {
    "uniprot": "A0A3P7SI87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SI87"
  },
  {
    "uniprot": "A0A0N4U862",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U862"
  },
  {
    "uniprot": "A0A0N4U1F1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1F1"
  },
  {
    "uniprot": "A0A0N4U358",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U358"
  },
  {
    "uniprot": "A0A0N4URH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URH4"
  },
  {
    "uniprot": "A0A0N4UQW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQW3"
  },
  {
    "uniprot": "A0A0N4U2Q4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Q4"
  },
  {
    "uniprot": "A0A0N4U934",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U934"
  },
  {
    "uniprot": "A0A158Q654",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q654"
  },
  {
    "uniprot": "A0A0N4UBD9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBD9"
  },
  {
    "uniprot": "A0A0N4UE39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE39"
  },
  {
    "uniprot": "A0A3P7QNU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QNU7"
  },
  {
    "uniprot": "A0A3P7PAY3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PAY3"
  },
  {
    "uniprot": "A0A0N4UN42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN42"
  },
  {
    "uniprot": "A0A158Q2I1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2I1"
  },
  {
    "uniprot": "A0A0N4UG20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG20"
  },
  {
    "uniprot": "A0A158Q390",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q390"
  },
  {
    "uniprot": "A0A0N4UEP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEP6"
  },
  {
    "uniprot": "A0A0N4UEX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEX1"
  },
  {
    "uniprot": "A0A3P7PVZ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PVZ9"
  },
  {
    "uniprot": "A0A0N4UH15",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH15"
  },
  {
    "uniprot": "A0A0N4U5X6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5X6"
  },
  {
    "uniprot": "A0A0N4UE99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE99"
  },
  {
    "uniprot": "A0A0N4UI04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI04"
  },
  {
    "uniprot": "A0A0N4U8Q4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Q4"
  },
  {
    "uniprot": "A0A0N4UDV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDV7"
  },
  {
    "uniprot": "A0A0N4UEU1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEU1"
  },
  {
    "uniprot": "A0A0N4US04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US04"
  },
  {
    "uniprot": "A0A0N4UA74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA74"
  },
  {
    "uniprot": "A0A0N4U8V5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8V5"
  },
  {
    "uniprot": "A0A0N4UMY6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMY6"
  },
  {
    "uniprot": "A0A0N4UDY8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDY8"
  },
  {
    "uniprot": "A0A0N4U7P0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7P0"
  },
  {
    "uniprot": "A0A0N4UID4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UID4"
  },
  {
    "uniprot": "A0A0N4UEN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEN3"
  },
  {
    "uniprot": "A0A0N4U836",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U836"
  },
  {
    "uniprot": "A0A0N4UGI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGI0"
  },
  {
    "uniprot": "A0A0N4URI6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URI6"
  },
  {
    "uniprot": "A0A0N4UBZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBZ5"
  },
  {
    "uniprot": "A0A3P7QHA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QHA8"
  },
  {
    "uniprot": "A0A0N4U0F1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0F1"
  },
  {
    "uniprot": "A0A3P7P773",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P773"
  },
  {
    "uniprot": "A0A0N4UF14",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF14"
  },
  {
    "uniprot": "A0A0N4UQG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQG6"
  },
  {
    "uniprot": "A0A0N4UC71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC71"
  },
  {
    "uniprot": "A0A3P7PFV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PFV6"
  },
  {
    "uniprot": "A0A158Q5Y3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Y3"
  },
  {
    "uniprot": "A0A0N4U730",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U730"
  },
  {
    "uniprot": "A0A0N4U7D1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7D1"
  },
  {
    "uniprot": "A0A0N4UMV1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMV1"
  },
  {
    "uniprot": "A0A3P7SE55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SE55"
  },
  {
    "uniprot": "A0A0N4UL24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL24"
  },
  {
    "uniprot": "A0A158Q6C6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6C6"
  },
  {
    "uniprot": "A0A0N4UL08",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL08"
  },
  {
    "uniprot": "A0A0N4UNQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNQ6"
  },
  {
    "uniprot": "A0A0N4UF86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF86"
  },
  {
    "uniprot": "A0A3P7QN34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QN34"
  },
  {
    "uniprot": "A0A3P7Q191",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q191"
  },
  {
    "uniprot": "A0A0N4UM10",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM10"
  },
  {
    "uniprot": "A0A0N4UAM4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAM4"
  },
  {
    "uniprot": "A0A0N4UNS7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNS7"
  },
  {
    "uniprot": "A0A3P7Q6L2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q6L2"
  },
  {
    "uniprot": "A0A158Q419",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q419"
  },
  {
    "uniprot": "A0A0N4UQV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQV8"
  },
  {
    "uniprot": "A0A0N4UHV1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHV1"
  },
  {
    "uniprot": "A0A158Q6C8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6C8"
  },
  {
    "uniprot": "A0A158Q520",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q520"
  },
  {
    "uniprot": "A0A3P7QBK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QBK3"
  },
  {
    "uniprot": "A0A0N4UKL7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKL7"
  },
  {
    "uniprot": "A0A158Q2M5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2M5"
  },
  {
    "uniprot": "A0A0N4UNV4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNV4"
  },
  {
    "uniprot": "A0A3P7SEH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SEH3"
  },
  {
    "uniprot": "A0A0N4UNM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNM8"
  },
  {
    "uniprot": "A0A0N4UKF1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKF1"
  },
  {
    "uniprot": "A0A0N4UAP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAP6"
  },
  {
    "uniprot": "A0A0N4U949",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U949"
  },
  {
    "uniprot": "A0A0N4U2H5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2H5"
  },
  {
    "uniprot": "A0A0N4UMV5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMV5"
  },
  {
    "uniprot": "A0A158Q586",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q586"
  },
  {
    "uniprot": "A0A0N4UFK9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFK9"
  },
  {
    "uniprot": "A0A0N4U8A8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8A8"
  },
  {
    "uniprot": "A0A158Q3J1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3J1"
  },
  {
    "uniprot": "A0A0N4U3T4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3T4"
  },
  {
    "uniprot": "A0A0N4URR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URR1"
  },
  {
    "uniprot": "A0A0N4UH86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH86"
  },
  {
    "uniprot": "A0A0N4U107",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U107"
  },
  {
    "uniprot": "A0A0N4UE90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE90"
  },
  {
    "uniprot": "A0A0N4U3T9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3T9"
  },
  {
    "uniprot": "A0A0N4UHX2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHX2"
  },
  {
    "uniprot": "A0A0N4UI43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI43"
  },
  {
    "uniprot": "A0A0N4U9U7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9U7"
  },
  {
    "uniprot": "A0A158Q523",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q523"
  },
  {
    "uniprot": "A0A0N4U6A2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6A2"
  },
  {
    "uniprot": "A0A0N4U4T6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4T6"
  },
  {
    "uniprot": "A0A0N4UCQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCQ7"
  },
  {
    "uniprot": "A0A0N4UBQ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBQ2"
  },
  {
    "uniprot": "A0A0N4UG38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG38"
  },
  {
    "uniprot": "A0A0N4US64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US64"
  },
  {
    "uniprot": "A0A0N4UMG8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMG8"
  },
  {
    "uniprot": "A0A158Q351",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q351"
  },
  {
    "uniprot": "A0A158Q307",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q307"
  },
  {
    "uniprot": "A0A0N4UN41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN41"
  },
  {
    "uniprot": "A0A0N4U5F7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5F7"
  },
  {
    "uniprot": "A0A0N4UGB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGB0"
  },
  {
    "uniprot": "A0A0N4UCP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCP0"
  },
  {
    "uniprot": "A0A158Q5V5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5V5"
  },
  {
    "uniprot": "A0A0N4U3F2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3F2"
  },
  {
    "uniprot": "A0A0N4UDT9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDT9"
  },
  {
    "uniprot": "A0A0N4UP44",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP44"
  },
  {
    "uniprot": "A0A0N4U999",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U999"
  },
  {
    "uniprot": "A0A3P7PFA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PFA4"
  },
  {
    "uniprot": "A0A0N4UDA7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDA7"
  },
  {
    "uniprot": "A0A0N4U6D5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6D5"
  },
  {
    "uniprot": "A0A0N4U9T1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9T1"
  },
  {
    "uniprot": "A0A0N4UI94",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI94"
  },
  {
    "uniprot": "A0A158Q5D8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5D8"
  },
  {
    "uniprot": "A0A0N4U4P0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4P0"
  },
  {
    "uniprot": "A0A0N4UPV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPV3"
  },
  {
    "uniprot": "A0A0N4U9J8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9J8"
  },
  {
    "uniprot": "A0A0N4UFU1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFU1"
  },
  {
    "uniprot": "A0A0N4UH97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH97"
  },
  {
    "uniprot": "A0A3P7Q3X1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q3X1"
  },
  {
    "uniprot": "A0A0N4U962",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U962"
  },
  {
    "uniprot": "A0A0N4UK89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK89"
  },
  {
    "uniprot": "A0A0N4ULH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULH4"
  },
  {
    "uniprot": "A0A0N4UER4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UER4"
  },
  {
    "uniprot": "A0A3P7Q4F5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q4F5"
  },
  {
    "uniprot": "A0A0N4U1B4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1B4"
  },
  {
    "uniprot": "A0A0N4UBI5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBI5"
  },
  {
    "uniprot": "A0A0N4UL75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL75"
  },
  {
    "uniprot": "A0A0N4UHT8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHT8"
  },
  {
    "uniprot": "A0A0N4UF29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF29"
  },
  {
    "uniprot": "A0A158Q466",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q466"
  },
  {
    "uniprot": "A0A0N4U1J8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1J8"
  },
  {
    "uniprot": "A0A0N4UK85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK85"
  },
  {
    "uniprot": "A0A0N4UB18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB18"
  },
  {
    "uniprot": "A0A0N4U4N4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4N4"
  },
  {
    "uniprot": "A0A158Q4I1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4I1"
  },
  {
    "uniprot": "A0A0N4UR88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR88"
  },
  {
    "uniprot": "A0A0N4UPY8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPY8"
  },
  {
    "uniprot": "A0A0N4UC97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC97"
  },
  {
    "uniprot": "A0A0N4UPB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPB2"
  },
  {
    "uniprot": "A0A158Q5W5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5W5"
  },
  {
    "uniprot": "A0A0N4UC61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC61"
  },
  {
    "uniprot": "A0A0N4UJH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJH4"
  },
  {
    "uniprot": "A0A0N4URN1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URN1"
  },
  {
    "uniprot": "A0A0N4URY6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URY6"
  },
  {
    "uniprot": "A0A0N4UA68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA68"
  },
  {
    "uniprot": "A0A0N4UGE4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGE4"
  },
  {
    "uniprot": "A0A0N4UHI2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHI2"
  },
  {
    "uniprot": "A0A0N4U5R2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5R2"
  },
  {
    "uniprot": "A0A0N4UR79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR79"
  },
  {
    "uniprot": "A0A0N4U3W2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3W2"
  },
  {
    "uniprot": "A0A0N4UAG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAG6"
  },
  {
    "uniprot": "A0A0N4UHJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHJ2"
  },
  {
    "uniprot": "A0A0N4UR27",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR27"
  },
  {
    "uniprot": "A0A0N4U417",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U417"
  },
  {
    "uniprot": "A0A0N4U3A2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3A2"
  },
  {
    "uniprot": "A0A0N4UI91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI91"
  },
  {
    "uniprot": "A0A0N4U0M5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0M5"
  },
  {
    "uniprot": "A0A0N4UPQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPQ4"
  },
  {
    "uniprot": "A0A0N4UEC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEC2"
  },
  {
    "uniprot": "A0A0N4U6S8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6S8"
  },
  {
    "uniprot": "A0A0N4U5G7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5G7"
  },
  {
    "uniprot": "A0A0N4U2K3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2K3"
  },
  {
    "uniprot": "A0A0N4U488",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U488"
  },
  {
    "uniprot": "A0A0N4UMR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMR1"
  },
  {
    "uniprot": "A0A0N4U2E7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2E7"
  },
  {
    "uniprot": "A0A0N4UR82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR82"
  },
  {
    "uniprot": "A0A0N4UGX0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGX0"
  },
  {
    "uniprot": "A0A0N4US76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US76"
  },
  {
    "uniprot": "A0A0N4U672",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U672"
  },
  {
    "uniprot": "A0A3P7PPA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPA6"
  },
  {
    "uniprot": "A0A0N4UCE5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCE5"
  },
  {
    "uniprot": "A0A3P7PX06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PX06"
  },
  {
    "uniprot": "A0A0N4UPN7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPN7"
  },
  {
    "uniprot": "A0A3P7PUK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PUK5"
  },
  {
    "uniprot": "A0A0N4UDP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDP7"
  },
  {
    "uniprot": "A0A3P7SU96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SU96"
  },
  {
    "uniprot": "A0A0N4U6J5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6J5"
  },
  {
    "uniprot": "A0A0N4UJJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJJ7"
  },
  {
    "uniprot": "A0A0N4UPU0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPU0"
  },
  {
    "uniprot": "A0A0N4ULR0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULR0"
  },
  {
    "uniprot": "A0A158Q4G9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4G9"
  },
  {
    "uniprot": "A0A3P7SUR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SUR6"
  },
  {
    "uniprot": "A0A0N4UG03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG03"
  },
  {
    "uniprot": "A0A0N4UQ03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ03"
  },
  {
    "uniprot": "A0A0N4UFI6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFI6"
  },
  {
    "uniprot": "A0A0N4U2Z4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Z4"
  },
  {
    "uniprot": "A0A3P7P5J6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P5J6"
  },
  {
    "uniprot": "A0A0N4URS5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URS5"
  },
  {
    "uniprot": "A0A158Q6G0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6G0"
  },
  {
    "uniprot": "A0A0N4UG10",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG10"
  },
  {
    "uniprot": "A0A3P7PSV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PSV3"
  },
  {
    "uniprot": "A0A0N4U1M1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1M1"
  },
  {
    "uniprot": "A0A158Q4S6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4S6"
  },
  {
    "uniprot": "A0A158Q447",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q447"
  },
  {
    "uniprot": "A0A0N4UAX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAX7"
  },
  {
    "uniprot": "A0A0N4ULQ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULQ2"
  },
  {
    "uniprot": "A0A0N4U548",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U548"
  },
  {
    "uniprot": "A0A0N4UQH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQH9"
  },
  {
    "uniprot": "A0A0N4UM24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM24"
  },
  {
    "uniprot": "A0A0N4UH96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH96"
  },
  {
    "uniprot": "A0A0N4U2M6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2M6"
  },
  {
    "uniprot": "A0A0N4U441",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U441"
  },
  {
    "uniprot": "A0A0N4UCP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCP9"
  },
  {
    "uniprot": "A0A0N4U246",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U246"
  },
  {
    "uniprot": "A0A0N4U6G6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6G6"
  },
  {
    "uniprot": "A0A0N4ULY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULY2"
  },
  {
    "uniprot": "A0A0N4UHI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHI9"
  },
  {
    "uniprot": "A0A0N4U907",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U907"
  },
  {
    "uniprot": "A0A3P7TGR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TGR4"
  },
  {
    "uniprot": "A0A0N4U8Z1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Z1"
  },
  {
    "uniprot": "A0A0N4U811",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U811"
  },
  {
    "uniprot": "A0A0N4UEJ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEJ0"
  },
  {
    "uniprot": "A0A3P7PPV5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPV5"
  },
  {
    "uniprot": "A0A0N4UIQ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIQ8"
  },
  {
    "uniprot": "A0A0N4UH81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH81"
  },
  {
    "uniprot": "A0A0N4UGH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGH3"
  },
  {
    "uniprot": "A0A0N4UIG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIG2"
  },
  {
    "uniprot": "A0A158Q3E2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3E2"
  },
  {
    "uniprot": "A0A0N4U637",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U637"
  },
  {
    "uniprot": "A0A0N4U2W8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2W8"
  },
  {
    "uniprot": "A0A0N4UMB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMB1"
  },
  {
    "uniprot": "A0A0N4UPQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPQ7"
  },
  {
    "uniprot": "A0A0N4U5T5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5T5"
  },
  {
    "uniprot": "A0A0N4U1C9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1C9"
  },
  {
    "uniprot": "A0A0N4U473",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U473"
  },
  {
    "uniprot": "A0A0N4UKV4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKV4"
  },
  {
    "uniprot": "A0A0N4U7W2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7W2"
  },
  {
    "uniprot": "A0A0N4U5G4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5G4"
  },
  {
    "uniprot": "A0A0N4UJ07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ07"
  },
  {
    "uniprot": "A0A3P7QQF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QQF5"
  },
  {
    "uniprot": "A0A0N4ULN7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULN7"
  },
  {
    "uniprot": "A0A0N4UBY9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBY9"
  },
  {
    "uniprot": "A0A0N4UDS6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDS6"
  },
  {
    "uniprot": "A0A0N4U6H7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6H7"
  },
  {
    "uniprot": "A0A3P7PJL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PJL8"
  },
  {
    "uniprot": "A0A0N4UC57",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC57"
  },
  {
    "uniprot": "A0A158Q4C4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4C4"
  },
  {
    "uniprot": "A0A158Q5I5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5I5"
  },
  {
    "uniprot": "A0A0N4UBB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBB2"
  },
  {
    "uniprot": "A0A0N4UCF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCF3"
  },
  {
    "uniprot": "A0A158Q2R8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2R8"
  },
  {
    "uniprot": "A0A0N4UGX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGX1"
  },
  {
    "uniprot": "A0A0N4UPW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPW8"
  },
  {
    "uniprot": "A0A0N4U2I8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2I8"
  },
  {
    "uniprot": "A0A0N4UC43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC43"
  },
  {
    "uniprot": "A0A0N4UG64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG64"
  },
  {
    "uniprot": "A0A0N4UHI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHI1"
  },
  {
    "uniprot": "A0A158Q551",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q551"
  },
  {
    "uniprot": "A0A0N4UPF6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPF6"
  },
  {
    "uniprot": "A0A158Q588",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q588"
  },
  {
    "uniprot": "A0A0N4U5H8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5H8"
  },
  {
    "uniprot": "A0A0N4U6S1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6S1"
  },
  {
    "uniprot": "A0A0N4UE13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE13"
  },
  {
    "uniprot": "A0A0N4U5A6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5A6"
  },
  {
    "uniprot": "A0A0N4UIY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIY0"
  },
  {
    "uniprot": "A0A0N4UAX2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAX2"
  },
  {
    "uniprot": "A0A0N4URQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URQ4"
  },
  {
    "uniprot": "A0A158Q5R1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5R1"
  },
  {
    "uniprot": "A0A0N4UJI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJI0"
  },
  {
    "uniprot": "A0A0N4UE70",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE70"
  },
  {
    "uniprot": "A0A3P7Q0T3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q0T3"
  },
  {
    "uniprot": "A0A0N4UCQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCQ3"
  },
  {
    "uniprot": "A0A0N4U845",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U845"
  },
  {
    "uniprot": "A0A0N4U2P7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2P7"
  },
  {
    "uniprot": "A0A0N4UGN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGN0"
  },
  {
    "uniprot": "A0A0N4UB20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB20"
  },
  {
    "uniprot": "A0A0N4UJP5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJP5"
  },
  {
    "uniprot": "A0A0N4UMQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMQ7"
  },
  {
    "uniprot": "A0A3P7QNZ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QNZ2"
  },
  {
    "uniprot": "A0A0N4UFG7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFG7"
  },
  {
    "uniprot": "A0A0N4UF45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF45"
  },
  {
    "uniprot": "A0A0N4UJX6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJX6"
  },
  {
    "uniprot": "A0A3P7Q8X1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q8X1"
  },
  {
    "uniprot": "A0A0N4UJB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJB5"
  },
  {
    "uniprot": "A0A0N4UJF7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJF7"
  },
  {
    "uniprot": "A0A3P7SWJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SWJ7"
  },
  {
    "uniprot": "A0A0N4U6U0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6U0"
  },
  {
    "uniprot": "A0A0N4UDV1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDV1"
  },
  {
    "uniprot": "A0A0N4UCD9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCD9"
  },
  {
    "uniprot": "A0A0N4UPP2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPP2"
  },
  {
    "uniprot": "A0A0N4UMG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMG6"
  },
  {
    "uniprot": "A0A0N4U723",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U723"
  },
  {
    "uniprot": "A0A0N4UGF4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGF4"
  },
  {
    "uniprot": "A0A0N4UC69",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC69"
  },
  {
    "uniprot": "A0A0N4UCM5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCM5"
  },
  {
    "uniprot": "A0A0N4UA53",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA53"
  },
  {
    "uniprot": "A0A0N4U4T8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4T8"
  },
  {
    "uniprot": "A0A0N4URZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URZ5"
  },
  {
    "uniprot": "A0A158Q2W5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2W5"
  },
  {
    "uniprot": "A0A0N4UBL7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBL7"
  },
  {
    "uniprot": "A0A0N4UAX0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAX0"
  },
  {
    "uniprot": "A0A0N4U8Q1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Q1"
  },
  {
    "uniprot": "A0A158Q6H1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6H1"
  },
  {
    "uniprot": "A0A158Q4F5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4F5"
  },
  {
    "uniprot": "A0A0N4UCI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCI3"
  },
  {
    "uniprot": "A0A0N4U3J6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3J6"
  },
  {
    "uniprot": "A0A0N4ULT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULT6"
  },
  {
    "uniprot": "A0A3P7QXR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QXR1"
  },
  {
    "uniprot": "A0A0N4UCW5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCW5"
  },
  {
    "uniprot": "A0A0N4U846",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U846"
  },
  {
    "uniprot": "A0A3P7PMI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PMI0"
  },
  {
    "uniprot": "A0A0N4U5G3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5G3"
  },
  {
    "uniprot": "A0A0N4U7S4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7S4"
  },
  {
    "uniprot": "A0A0N4UKK4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKK4"
  },
  {
    "uniprot": "A0A158Q2K9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2K9"
  },
  {
    "uniprot": "A0A0N4U0J4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0J4"
  },
  {
    "uniprot": "A0A0N4UHD4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHD4"
  },
  {
    "uniprot": "A0A158Q3W3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3W3"
  },
  {
    "uniprot": "A0A0N4U0S0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0S0"
  },
  {
    "uniprot": "A0A0N4U9L4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9L4"
  },
  {
    "uniprot": "A0A3P7PSY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PSY7"
  },
  {
    "uniprot": "A0A0N4U4X4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4X4"
  },
  {
    "uniprot": "A0A158Q4W8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4W8"
  },
  {
    "uniprot": "A0A0N4U3D1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3D1"
  },
  {
    "uniprot": "A0A0N4U6T2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6T2"
  },
  {
    "uniprot": "A0A0N4U9D4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9D4"
  },
  {
    "uniprot": "A0A3P7SYQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SYQ3"
  },
  {
    "uniprot": "A0A0N4U5S6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5S6"
  },
  {
    "uniprot": "A0A0N4UQA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQA8"
  },
  {
    "uniprot": "A0A0N4UAE1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAE1"
  },
  {
    "uniprot": "A0A0N4UQD7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQD7"
  },
  {
    "uniprot": "A0A0N4U194",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U194"
  },
  {
    "uniprot": "A0A0N4U9G0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9G0"
  },
  {
    "uniprot": "A0A0N4U910",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U910"
  },
  {
    "uniprot": "A0A0N4UHQ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHQ2"
  },
  {
    "uniprot": "A0A0N4UKX3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKX3"
  },
  {
    "uniprot": "A0A158Q6E2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6E2"
  },
  {
    "uniprot": "A0A0N4UAV9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAV9"
  },
  {
    "uniprot": "A0A0N4U7F3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7F3"
  },
  {
    "uniprot": "A0A0N4U9J3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9J3"
  },
  {
    "uniprot": "A0A0N4UI62",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI62"
  },
  {
    "uniprot": "A0A0N4UCS7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCS7"
  },
  {
    "uniprot": "A0A0N4UQX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQX1"
  },
  {
    "uniprot": "A0A0N4U423",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U423"
  },
  {
    "uniprot": "A0A0N4UKR0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKR0"
  },
  {
    "uniprot": "A0A0N4U9D8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9D8"
  },
  {
    "uniprot": "A0A0N4U5H7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5H7"
  },
  {
    "uniprot": "A0A0N4UIT3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIT3"
  },
  {
    "uniprot": "A0A3P7SPY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SPY0"
  },
  {
    "uniprot": "A0A0N4UKR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKR1"
  },
  {
    "uniprot": "A0A0N4UH54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH54"
  },
  {
    "uniprot": "A0A0N4UFL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFL9"
  },
  {
    "uniprot": "A0A0N4UCS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCS3"
  },
  {
    "uniprot": "A0A0N4UHK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHK6"
  },
  {
    "uniprot": "A0A0N4URC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URC3"
  },
  {
    "uniprot": "A0A0N4U386",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U386"
  },
  {
    "uniprot": "A0A0N4U229",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U229"
  },
  {
    "uniprot": "A0A0N4U7Y5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Y5"
  },
  {
    "uniprot": "A0A0N4U8F5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8F5"
  },
  {
    "uniprot": "A0A0N4UNR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNR1"
  },
  {
    "uniprot": "A0A0N4ULK7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULK7"
  },
  {
    "uniprot": "A0A0N4UCL5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCL5"
  },
  {
    "uniprot": "A0A3P7Q387",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q387"
  },
  {
    "uniprot": "A0A0N4U5N1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5N1"
  },
  {
    "uniprot": "A0A0N4UNB4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNB4"
  },
  {
    "uniprot": "A0A0N4UMM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMM8"
  },
  {
    "uniprot": "A0A0N4URM5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URM5"
  },
  {
    "uniprot": "A0A0N4UIE7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIE7"
  },
  {
    "uniprot": "A0A0N4UP37",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP37"
  },
  {
    "uniprot": "A0A0N4UR30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR30"
  },
  {
    "uniprot": "A0A158Q3X3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3X3"
  },
  {
    "uniprot": "A0A0N4U104",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U104"
  },
  {
    "uniprot": "A0A3P7QL55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QL55"
  },
  {
    "uniprot": "A0A0N4U1I6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1I6"
  },
  {
    "uniprot": "A0A0N4UCS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCS9"
  },
  {
    "uniprot": "A0A0N4U5G0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5G0"
  },
  {
    "uniprot": "A0A0N4U6K3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6K3"
  },
  {
    "uniprot": "A0A0N4U3S4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3S4"
  },
  {
    "uniprot": "A0A0N4U802",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U802"
  },
  {
    "uniprot": "A0A0N4URU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URU4"
  },
  {
    "uniprot": "A0A0N4UNR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNR4"
  },
  {
    "uniprot": "A0A0N4UBR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBR6"
  },
  {
    "uniprot": "A0A0N4UE51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE51"
  },
  {
    "uniprot": "A0A158Q534",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q534"
  },
  {
    "uniprot": "A0A0N4UFQ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFQ2"
  },
  {
    "uniprot": "A0A0N4U9J0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9J0"
  },
  {
    "uniprot": "A0A0N4U350",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U350"
  },
  {
    "uniprot": "A0A158Q5P8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5P8"
  },
  {
    "uniprot": "A0A0N4U8D2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8D2"
  },
  {
    "uniprot": "A0A0N4UNA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNA1"
  },
  {
    "uniprot": "A0A0N4UHU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHU3"
  },
  {
    "uniprot": "A0A0N4UED1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UED1"
  },
  {
    "uniprot": "A0A0N4U662",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U662"
  },
  {
    "uniprot": "A0A0N4UN44",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN44"
  },
  {
    "uniprot": "A0A0N4U4U2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4U2"
  },
  {
    "uniprot": "A0A0N4U6K9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6K9"
  },
  {
    "uniprot": "A0A0N4U3W1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3W1"
  },
  {
    "uniprot": "A0A3P7PW59",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PW59"
  },
  {
    "uniprot": "A0A0N4U4Q2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Q2"
  },
  {
    "uniprot": "A0A0N4UMI4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMI4"
  },
  {
    "uniprot": "A0A0N4UKX2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKX2"
  },
  {
    "uniprot": "A0A3P7QB16",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QB16"
  },
  {
    "uniprot": "A0A0N4US87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US87"
  },
  {
    "uniprot": "A0A0N4U9A1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9A1"
  },
  {
    "uniprot": "A0A0N4UB86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB86"
  },
  {
    "uniprot": "A0A0N4U823",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U823"
  },
  {
    "uniprot": "A0A158Q5Y2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Y2"
  },
  {
    "uniprot": "A0A0N4UBI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBI9"
  },
  {
    "uniprot": "A0A158Q6L4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6L4"
  },
  {
    "uniprot": "A0A3P7PXJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PXJ7"
  },
  {
    "uniprot": "A0A0N4UCI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCI0"
  },
  {
    "uniprot": "A0A0N4UHT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHT6"
  },
  {
    "uniprot": "A0A158Q443",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q443"
  },
  {
    "uniprot": "A0A0N4U0M6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0M6"
  },
  {
    "uniprot": "A0A0N4UPP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPP1"
  },
  {
    "uniprot": "A0A0N4URB8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URB8"
  },
  {
    "uniprot": "A0A3P7PNQ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PNQ2"
  },
  {
    "uniprot": "A0A3P7SI11",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SI11"
  },
  {
    "uniprot": "A0A0N4UI81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI81"
  },
  {
    "uniprot": "A0A0N4UGH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGH1"
  },
  {
    "uniprot": "A0A0N4U937",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U937"
  },
  {
    "uniprot": "A0A0N4UEH0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEH0"
  },
  {
    "uniprot": "A0A0N4U0R9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0R9"
  },
  {
    "uniprot": "A0A158Q5M7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5M7"
  },
  {
    "uniprot": "A0A0N4UDZ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDZ8"
  },
  {
    "uniprot": "A0A158Q3D3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3D3"
  },
  {
    "uniprot": "A0A0N4UI75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI75"
  },
  {
    "uniprot": "A0A0N4UHN5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHN5"
  },
  {
    "uniprot": "A0A3P7PAZ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PAZ6"
  },
  {
    "uniprot": "A0A0N4UIQ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIQ2"
  },
  {
    "uniprot": "A0A3P7PVQ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PVQ8"
  },
  {
    "uniprot": "A0A0N4U8L4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8L4"
  },
  {
    "uniprot": "A0A0N4UHF4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHF4"
  },
  {
    "uniprot": "A0A0N4U5Y8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Y8"
  },
  {
    "uniprot": "A0A0N4U390",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U390"
  },
  {
    "uniprot": "A0A0N4UKK9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKK9"
  },
  {
    "uniprot": "A0A0N4UEC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEC3"
  },
  {
    "uniprot": "A0A0N4U108",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U108"
  },
  {
    "uniprot": "A0A0N4UBE2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBE2"
  },
  {
    "uniprot": "A0A0N4UBD6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBD6"
  },
  {
    "uniprot": "A0A0N4UF19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF19"
  },
  {
    "uniprot": "A0A0N4U4Q7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Q7"
  },
  {
    "uniprot": "A0A0N4U5P1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5P1"
  },
  {
    "uniprot": "A0A0N4UG61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG61"
  },
  {
    "uniprot": "A0A158Q3K1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3K1"
  },
  {
    "uniprot": "A0A0N4UBH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBH2"
  },
  {
    "uniprot": "A0A0N4U5R7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5R7"
  },
  {
    "uniprot": "A0A3P7PXJ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PXJ0"
  },
  {
    "uniprot": "A0A3P7Q846",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q846"
  },
  {
    "uniprot": "A0A0N4UQC8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQC8"
  },
  {
    "uniprot": "A0A0N4UK75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK75"
  },
  {
    "uniprot": "A0A0N4UGS8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGS8"
  },
  {
    "uniprot": "A0A0N4U649",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U649"
  },
  {
    "uniprot": "A0A158Q4P1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4P1"
  },
  {
    "uniprot": "A0A0N4UF37",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF37"
  },
  {
    "uniprot": "A0A158Q428",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q428"
  },
  {
    "uniprot": "A0A0N4U6G9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6G9"
  },
  {
    "uniprot": "A0A0N4UAJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAJ7"
  },
  {
    "uniprot": "A0A158Q6B0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6B0"
  },
  {
    "uniprot": "A0A0N4UFP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFP4"
  },
  {
    "uniprot": "A0A0N4UGR3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGR3"
  },
  {
    "uniprot": "A0A0N4UC20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC20"
  },
  {
    "uniprot": "A0A0N4UAE5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAE5"
  },
  {
    "uniprot": "A0A0N4UEA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEA1"
  },
  {
    "uniprot": "A0A158Q450",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q450"
  },
  {
    "uniprot": "A0A0N4ULE1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULE1"
  },
  {
    "uniprot": "A0A0N4U827",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U827"
  },
  {
    "uniprot": "A0A0N4UGG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGG4"
  },
  {
    "uniprot": "A0A0N4UL09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL09"
  },
  {
    "uniprot": "A0A0N4U624",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U624"
  },
  {
    "uniprot": "A0A0N4U7I0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7I0"
  },
  {
    "uniprot": "A0A3P7Q016",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q016"
  },
  {
    "uniprot": "A0A0N4UDI7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDI7"
  },
  {
    "uniprot": "A0A0N4U3A4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3A4"
  },
  {
    "uniprot": "A0A0N4UQE7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQE7"
  },
  {
    "uniprot": "A0A0N4UJK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJK0"
  },
  {
    "uniprot": "A0A0N4UB53",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB53"
  },
  {
    "uniprot": "A0A158Q3P2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3P2"
  },
  {
    "uniprot": "A0A0N4UNR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNR2"
  },
  {
    "uniprot": "A0A0N4UB57",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB57"
  },
  {
    "uniprot": "A0A0N4URV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URV8"
  },
  {
    "uniprot": "A0A0N4ULT3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULT3"
  },
  {
    "uniprot": "A0A158Q5A8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5A8"
  },
  {
    "uniprot": "A0A0N4UJ87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ87"
  },
  {
    "uniprot": "A0A3P7PN75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PN75"
  },
  {
    "uniprot": "A0A0N4UBI6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBI6"
  },
  {
    "uniprot": "A0A0N4U6S2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6S2"
  },
  {
    "uniprot": "A0A3P7PJV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PJV6"
  },
  {
    "uniprot": "A0A3P7PMY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PMY0"
  },
  {
    "uniprot": "A0A0N4U435",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U435"
  },
  {
    "uniprot": "A0A3P7P8V3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P8V3"
  },
  {
    "uniprot": "A0A0N4UAA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAA0"
  },
  {
    "uniprot": "A0A0N4UAT4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAT4"
  },
  {
    "uniprot": "A0A0N4UNT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNT6"
  },
  {
    "uniprot": "A0A158Q383",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q383"
  },
  {
    "uniprot": "A0A158Q5T4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5T4"
  },
  {
    "uniprot": "A0A0N4UG85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG85"
  },
  {
    "uniprot": "A0A0N4UKQ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKQ0"
  },
  {
    "uniprot": "A0A0N4UFJ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFJ0"
  },
  {
    "uniprot": "A0A0N4UPV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPV6"
  },
  {
    "uniprot": "A0A0N4URX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URX5"
  },
  {
    "uniprot": "A0A0N4UNH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNH4"
  },
  {
    "uniprot": "A0A0N4UE30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE30"
  },
  {
    "uniprot": "A0A0N4U1E8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1E8"
  },
  {
    "uniprot": "A0A0N4U785",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U785"
  },
  {
    "uniprot": "A0A158Q668",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q668"
  },
  {
    "uniprot": "A0A158Q3C6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3C6"
  },
  {
    "uniprot": "A0A0N4UG18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG18"
  },
  {
    "uniprot": "A0A0N4UGT0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGT0"
  },
  {
    "uniprot": "A0A0N4UJH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJH6"
  },
  {
    "uniprot": "A0A158Q5K6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5K6"
  },
  {
    "uniprot": "A0A0N4URT8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URT8"
  },
  {
    "uniprot": "A0A0N4UDS1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDS1"
  },
  {
    "uniprot": "A0A0N4U6F3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6F3"
  },
  {
    "uniprot": "A0A0N4UKA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKA0"
  },
  {
    "uniprot": "A0A0N4UIZ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIZ8"
  },
  {
    "uniprot": "A0A158Q5Z7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Z7"
  },
  {
    "uniprot": "A0A0N4U660",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U660"
  },
  {
    "uniprot": "A0A0N4U2F9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2F9"
  },
  {
    "uniprot": "A0A0N4UDZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDZ5"
  },
  {
    "uniprot": "A0A0N4U565",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U565"
  },
  {
    "uniprot": "A0A0N4UKD7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKD7"
  },
  {
    "uniprot": "A0A0N4U5J9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5J9"
  },
  {
    "uniprot": "A0A0N4UAF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAF3"
  },
  {
    "uniprot": "A0A0N4UCI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCI1"
  },
  {
    "uniprot": "A0A0N4UQD3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQD3"
  },
  {
    "uniprot": "A0A0N4ULP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULP0"
  },
  {
    "uniprot": "A0A3P7QAS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QAS3"
  },
  {
    "uniprot": "A0A0N4U346",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U346"
  },
  {
    "uniprot": "A0A0N4U5P2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5P2"
  },
  {
    "uniprot": "A0A3P7Q3R9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q3R9"
  },
  {
    "uniprot": "A0A0N4UEW2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEW2"
  },
  {
    "uniprot": "A0A0N4UQY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQY2"
  },
  {
    "uniprot": "A0A0N4UJW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJW3"
  },
  {
    "uniprot": "A0A0N4UEC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEC0"
  },
  {
    "uniprot": "A0A0N4UD64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD64"
  },
  {
    "uniprot": "A0A0N4U0M4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0M4"
  },
  {
    "uniprot": "A0A0N4UM66",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM66"
  },
  {
    "uniprot": "A0A0N4U5K2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5K2"
  },
  {
    "uniprot": "A0A158Q5H1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5H1"
  },
  {
    "uniprot": "A0A158Q5G5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5G5"
  },
  {
    "uniprot": "A0A0N4UIW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIW8"
  },
  {
    "uniprot": "A0A158Q4V7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4V7"
  },
  {
    "uniprot": "A0A3P7TG48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TG48"
  },
  {
    "uniprot": "A0A158Q3A4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3A4"
  },
  {
    "uniprot": "A0A3P7PAT3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PAT3"
  },
  {
    "uniprot": "A0A0N4UEN8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEN8"
  },
  {
    "uniprot": "A0A0N4U5D8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5D8"
  },
  {
    "uniprot": "A0A0N4UN80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN80"
  },
  {
    "uniprot": "A0A0N4U608",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U608"
  },
  {
    "uniprot": "A0A158Q4T4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4T4"
  },
  {
    "uniprot": "A0A0N4UJD3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJD3"
  },
  {
    "uniprot": "A0A158Q2K2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2K2"
  },
  {
    "uniprot": "A0A0N4UKZ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKZ8"
  },
  {
    "uniprot": "A0A0N4UEJ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEJ6"
  },
  {
    "uniprot": "A0A0N4U5G6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5G6"
  },
  {
    "uniprot": "A0A158Q3B5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3B5"
  },
  {
    "uniprot": "A0A0N4U490",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U490"
  },
  {
    "uniprot": "A0A0N4UFW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFW8"
  },
  {
    "uniprot": "A0A0N4U598",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U598"
  },
  {
    "uniprot": "A0A0N4UPD0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPD0"
  },
  {
    "uniprot": "A0A0N4U6V4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6V4"
  },
  {
    "uniprot": "A0A0N4URN8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URN8"
  },
  {
    "uniprot": "A0A0N4UHU9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHU9"
  },
  {
    "uniprot": "A0A3P7PUB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PUB2"
  },
  {
    "uniprot": "A0A0N4U0V7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0V7"
  },
  {
    "uniprot": "A0A0N4ULU0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULU0"
  },
  {
    "uniprot": "A0A3P7PD04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PD04"
  },
  {
    "uniprot": "A0A0N4U6N0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6N0"
  },
  {
    "uniprot": "A0A0N4UK99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK99"
  },
  {
    "uniprot": "A0A0N4UEG8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEG8"
  },
  {
    "uniprot": "A0A158Q615",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q615"
  },
  {
    "uniprot": "A0A0N4UQ41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ41"
  },
  {
    "uniprot": "A0A0N4U8M2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8M2"
  },
  {
    "uniprot": "A0A0N4UC47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC47"
  },
  {
    "uniprot": "A0A0N4UH07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH07"
  },
  {
    "uniprot": "A0A0N4U0W3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0W3"
  },
  {
    "uniprot": "A0A0N4UAP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAP0"
  },
  {
    "uniprot": "A0A0N4U743",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U743"
  },
  {
    "uniprot": "A0A0N4UFB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFB7"
  },
  {
    "uniprot": "A0A0N4U699",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U699"
  },
  {
    "uniprot": "A0A3P7QSK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QSK3"
  },
  {
    "uniprot": "A0A0N4U756",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U756"
  },
  {
    "uniprot": "A0A0N4U7Z3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Z3"
  },
  {
    "uniprot": "A0A0N4URG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URG9"
  },
  {
    "uniprot": "A0A0N4US79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US79"
  },
  {
    "uniprot": "A0A0N4U157",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U157"
  },
  {
    "uniprot": "A0A0N4UCX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCX9"
  },
  {
    "uniprot": "A0A0N4UM09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM09"
  },
  {
    "uniprot": "A0A0N4UJW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJW0"
  },
  {
    "uniprot": "A0A0N4U8H0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8H0"
  },
  {
    "uniprot": "A0A0N4UJX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJX1"
  },
  {
    "uniprot": "A0A0N4UHZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHZ5"
  },
  {
    "uniprot": "A0A0N4UMV4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMV4"
  },
  {
    "uniprot": "A0A0N4ULT2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULT2"
  },
  {
    "uniprot": "A0A0N4U8Q2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Q2"
  },
  {
    "uniprot": "A0A0N4U1H9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1H9"
  },
  {
    "uniprot": "A0A158Q679",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q679"
  },
  {
    "uniprot": "A0A0N4U385",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U385"
  },
  {
    "uniprot": "A0A0N4U0N8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0N8"
  },
  {
    "uniprot": "A0A0N4U670",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U670"
  },
  {
    "uniprot": "A0A0N4UQ40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ40"
  },
  {
    "uniprot": "A0A158Q468",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q468"
  },
  {
    "uniprot": "A0A3P7Q3Z4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q3Z4"
  },
  {
    "uniprot": "A0A0N4U698",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U698"
  },
  {
    "uniprot": "A0A3P7QUL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QUL3"
  },
  {
    "uniprot": "A0A0N4UHY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHY2"
  },
  {
    "uniprot": "A0A0N4U2M0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2M0"
  },
  {
    "uniprot": "A0A0N4UNF1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNF1"
  },
  {
    "uniprot": "A0A0N4UE03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE03"
  },
  {
    "uniprot": "A0A3P7T814",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T814"
  },
  {
    "uniprot": "A0A0N4UFY6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFY6"
  },
  {
    "uniprot": "A0A3P7PQR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQR1"
  },
  {
    "uniprot": "A0A0N4U2Z1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Z1"
  },
  {
    "uniprot": "A0A158Q5W1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5W1"
  },
  {
    "uniprot": "A0A158Q5M5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5M5"
  },
  {
    "uniprot": "A0A0N4UPL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPL8"
  },
  {
    "uniprot": "A0A0N4UGB8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGB8"
  },
  {
    "uniprot": "A0A0N4U7N4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7N4"
  },
  {
    "uniprot": "A0A0N4UI80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI80"
  },
  {
    "uniprot": "A0A0N4UAS6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAS6"
  },
  {
    "uniprot": "A0A0N4UMU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMU6"
  },
  {
    "uniprot": "A0A158Q610",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q610"
  },
  {
    "uniprot": "A0A0N4U299",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U299"
  },
  {
    "uniprot": "A0A0N4UR52",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR52"
  },
  {
    "uniprot": "A0A0N4ULA3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULA3"
  },
  {
    "uniprot": "A0A0N4UE21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE21"
  },
  {
    "uniprot": "A0A0N4U7X2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7X2"
  },
  {
    "uniprot": "A0A3P7PFW7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PFW7"
  },
  {
    "uniprot": "A0A0N4UL26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL26"
  },
  {
    "uniprot": "A0A0N4U1H8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1H8"
  },
  {
    "uniprot": "A0A0N4U181",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U181"
  },
  {
    "uniprot": "A0A0N4U7D8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7D8"
  },
  {
    "uniprot": "A0A0N4UA91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA91"
  },
  {
    "uniprot": "A0A0N4UE46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE46"
  },
  {
    "uniprot": "A0A0N4U8Y8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Y8"
  },
  {
    "uniprot": "A0A0N4U1X7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1X7"
  },
  {
    "uniprot": "A0A0N4UKT4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKT4"
  },
  {
    "uniprot": "A0A3P7PRB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PRB7"
  },
  {
    "uniprot": "A0A0N4URV4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URV4"
  },
  {
    "uniprot": "A0A3P7SSR0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SSR0"
  },
  {
    "uniprot": "A0A0N4UI65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI65"
  },
  {
    "uniprot": "A0A0N4U407",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U407"
  },
  {
    "uniprot": "A0A0N4U7C2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7C2"
  },
  {
    "uniprot": "A0A0N4UIV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIV8"
  },
  {
    "uniprot": "A0A0N4UEZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEZ4"
  },
  {
    "uniprot": "A0A0N4ULF1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULF1"
  },
  {
    "uniprot": "A0A0N4UGT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGT6"
  },
  {
    "uniprot": "A0A158Q3T8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3T8"
  },
  {
    "uniprot": "A0A3P7SX23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SX23"
  },
  {
    "uniprot": "A0A0N4UNU5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNU5"
  },
  {
    "uniprot": "A0A0N4UM47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM47"
  },
  {
    "uniprot": "A0A0N4U8T9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8T9"
  },
  {
    "uniprot": "A0A3P7SW00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SW00"
  },
  {
    "uniprot": "A0A3P7QBR3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QBR3"
  },
  {
    "uniprot": "A0A0N4UML5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UML5"
  },
  {
    "uniprot": "A0A0N4U284",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U284"
  },
  {
    "uniprot": "A0A0N4U8Y4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Y4"
  },
  {
    "uniprot": "A0A0N4UIP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIP0"
  },
  {
    "uniprot": "A0A0N4U5Q1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Q1"
  },
  {
    "uniprot": "A0A158Q314",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q314"
  },
  {
    "uniprot": "A0A0N4U8C7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8C7"
  },
  {
    "uniprot": "A0A0N4U4A2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4A2"
  },
  {
    "uniprot": "A0A0N4UMZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMZ1"
  },
  {
    "uniprot": "A0A3P7PQ20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQ20"
  },
  {
    "uniprot": "A0A0N4U311",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U311"
  },
  {
    "uniprot": "A0A3P7S7V3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S7V3"
  },
  {
    "uniprot": "A0A0N4U6L2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6L2"
  },
  {
    "uniprot": "A0A0N4UI49",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI49"
  },
  {
    "uniprot": "A0A0N4U4X2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4X2"
  },
  {
    "uniprot": "A0A158Q3N9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3N9"
  },
  {
    "uniprot": "A0A0N4UGP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGP7"
  },
  {
    "uniprot": "A0A0N4U965",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U965"
  },
  {
    "uniprot": "A0A0N4UDA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDA0"
  },
  {
    "uniprot": "A0A0N4UCP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCP7"
  },
  {
    "uniprot": "A0A0N4U3F9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3F9"
  },
  {
    "uniprot": "A0A0N4UFE0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFE0"
  },
  {
    "uniprot": "A0A0N4U454",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U454"
  },
  {
    "uniprot": "A0A0N4UF97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF97"
  },
  {
    "uniprot": "A0A0N4UGD8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGD8"
  },
  {
    "uniprot": "A0A0N4U5Q2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Q2"
  },
  {
    "uniprot": "A0A0N4ULI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULI1"
  },
  {
    "uniprot": "A0A3P7PV46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PV46"
  },
  {
    "uniprot": "A0A0N4U0L0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0L0"
  },
  {
    "uniprot": "A0A0N4U6N4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6N4"
  },
  {
    "uniprot": "A0A0N4UFK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFK2"
  },
  {
    "uniprot": "A0A0N4U601",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U601"
  },
  {
    "uniprot": "A0A3P7SNE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SNE3"
  },
  {
    "uniprot": "A0A0N4UBX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBX5"
  },
  {
    "uniprot": "A0A0N4UK55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK55"
  },
  {
    "uniprot": "A0A0N4UCL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCL0"
  },
  {
    "uniprot": "A0A0N4UB39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB39"
  },
  {
    "uniprot": "A0A158Q4H5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4H5"
  },
  {
    "uniprot": "A0A3P7QL48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QL48"
  },
  {
    "uniprot": "A0A0N4U2G7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2G7"
  },
  {
    "uniprot": "A0A0N4UF56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF56"
  },
  {
    "uniprot": "A0A0N4UG92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG92"
  },
  {
    "uniprot": "A0A3P7QNX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QNX9"
  },
  {
    "uniprot": "A0A0N4U143",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U143"
  },
  {
    "uniprot": "A0A0N4U736",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U736"
  },
  {
    "uniprot": "A0A0N4UG87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG87"
  },
  {
    "uniprot": "A0A0N4UAA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAA6"
  },
  {
    "uniprot": "A0A0N4UKH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKH9"
  },
  {
    "uniprot": "A0A158Q467",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q467"
  },
  {
    "uniprot": "A0A0N4U3X8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3X8"
  },
  {
    "uniprot": "A0A0N4UC66",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC66"
  },
  {
    "uniprot": "A0A0N4U7A6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7A6"
  },
  {
    "uniprot": "A0A0N4UKW7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKW7"
  },
  {
    "uniprot": "A0A0N4URF1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URF1"
  },
  {
    "uniprot": "A0A3P7SQF2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SQF2"
  },
  {
    "uniprot": "A0A0N4USA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USA2"
  },
  {
    "uniprot": "A0A0N4UBQ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBQ8"
  },
  {
    "uniprot": "A0A158Q4R3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4R3"
  },
  {
    "uniprot": "A0A158Q2H0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2H0"
  },
  {
    "uniprot": "A0A3P7SAL2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SAL2"
  },
  {
    "uniprot": "A0A158Q5U8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5U8"
  },
  {
    "uniprot": "A0A0N4U8D6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8D6"
  },
  {
    "uniprot": "A0A0N4U6D7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6D7"
  },
  {
    "uniprot": "A0A0N4UDQ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDQ0"
  },
  {
    "uniprot": "A0A3P7PHC8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PHC8"
  },
  {
    "uniprot": "A0A0N4UG98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG98"
  },
  {
    "uniprot": "A0A3P7PDM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PDM8"
  },
  {
    "uniprot": "A0A0N4UBE1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBE1"
  },
  {
    "uniprot": "A0A0N4U855",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U855"
  },
  {
    "uniprot": "A0A0N4UN15",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN15"
  },
  {
    "uniprot": "A0A0N4UR22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR22"
  },
  {
    "uniprot": "A0A0N4U2D3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2D3"
  },
  {
    "uniprot": "A0A0N4UME5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UME5"
  },
  {
    "uniprot": "A0A0N4UA18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA18"
  },
  {
    "uniprot": "A0A0N4UK18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK18"
  },
  {
    "uniprot": "A0A158Q694",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q694"
  },
  {
    "uniprot": "A0A0N4U509",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U509"
  },
  {
    "uniprot": "A0A0N4UG32",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG32"
  },
  {
    "uniprot": "A0A0N4UA14",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA14"
  },
  {
    "uniprot": "A0A0N4UKU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKU3"
  },
  {
    "uniprot": "A0A0N4U6D4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6D4"
  },
  {
    "uniprot": "A0A0N4UP15",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP15"
  },
  {
    "uniprot": "A0A0N4UK68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK68"
  },
  {
    "uniprot": "A0A0N4UM76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM76"
  },
  {
    "uniprot": "A0A0N4URS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URS9"
  },
  {
    "uniprot": "A0A0N4UD75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD75"
  },
  {
    "uniprot": "A0A0N4UG97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG97"
  },
  {
    "uniprot": "A0A158Q398",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q398"
  },
  {
    "uniprot": "A0A3P7SML3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SML3"
  },
  {
    "uniprot": "A0A0N4UHY8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHY8"
  },
  {
    "uniprot": "A0A0N4UQZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQZ5"
  },
  {
    "uniprot": "A0A0N4U8E1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8E1"
  },
  {
    "uniprot": "A0A0N4UPM1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPM1"
  },
  {
    "uniprot": "A0A0N4U2G2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2G2"
  },
  {
    "uniprot": "A0A0N4U918",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U918"
  },
  {
    "uniprot": "A0A0N4UP17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP17"
  },
  {
    "uniprot": "A0A3P7QF75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QF75"
  },
  {
    "uniprot": "A0A0N4U4F7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4F7"
  },
  {
    "uniprot": "A0A0N4UHN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHN9"
  },
  {
    "uniprot": "A0A0N4U5Y9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Y9"
  },
  {
    "uniprot": "A0A0N4U8M9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8M9"
  },
  {
    "uniprot": "A0A0N4UG76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG76"
  },
  {
    "uniprot": "A0A0N4U0H4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0H4"
  },
  {
    "uniprot": "A0A0N4U4K8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4K8"
  },
  {
    "uniprot": "A0A0N4UQM9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQM9"
  },
  {
    "uniprot": "A0A0N4UG14",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG14"
  },
  {
    "uniprot": "A0A0N4UJZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJZ1"
  },
  {
    "uniprot": "A0A0N4U301",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U301"
  },
  {
    "uniprot": "A0A0N4UFF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFF9"
  },
  {
    "uniprot": "A0A0N4UFR0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFR0"
  },
  {
    "uniprot": "A0A158Q6N8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6N8"
  },
  {
    "uniprot": "A0A0N4U2V8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2V8"
  },
  {
    "uniprot": "A0A3P7Q082",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q082"
  },
  {
    "uniprot": "A0A0N4U9D9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9D9"
  },
  {
    "uniprot": "A0A0N4UD17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD17"
  },
  {
    "uniprot": "A0A3P7PXA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PXA0"
  },
  {
    "uniprot": "A0A0N4UCY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCY2"
  },
  {
    "uniprot": "A0A3P7SUT8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SUT8"
  },
  {
    "uniprot": "A0A0N4UK83",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK83"
  },
  {
    "uniprot": "A0A0N4UE93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE93"
  },
  {
    "uniprot": "A0A0N4UPR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPR2"
  },
  {
    "uniprot": "A0A0N4UQ33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ33"
  },
  {
    "uniprot": "A0A0N4U1J9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1J9"
  },
  {
    "uniprot": "A0A0N4U3J5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3J5"
  },
  {
    "uniprot": "A0A0N4USB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USB2"
  },
  {
    "uniprot": "A0A0N4UF69",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF69"
  },
  {
    "uniprot": "A0A0N4UA72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA72"
  },
  {
    "uniprot": "A0A3P7S9Y0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S9Y0"
  },
  {
    "uniprot": "A0A0N4U0I2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0I2"
  },
  {
    "uniprot": "A0A0N4U0J9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0J9"
  },
  {
    "uniprot": "A0A0N4U1Y0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Y0"
  },
  {
    "uniprot": "A0A0N4UKW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKW8"
  },
  {
    "uniprot": "A0A0N4U0D6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0D6"
  },
  {
    "uniprot": "A0A0N4UFG1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFG1"
  },
  {
    "uniprot": "A0A0N4UAD0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAD0"
  },
  {
    "uniprot": "A0A0N4U9S2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9S2"
  },
  {
    "uniprot": "A0A0N4U3T1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3T1"
  },
  {
    "uniprot": "A0A0N4UB38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB38"
  },
  {
    "uniprot": "A0A0N4U4J1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4J1"
  },
  {
    "uniprot": "A0A158Q462",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q462"
  },
  {
    "uniprot": "A0A158Q5G8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5G8"
  },
  {
    "uniprot": "A0A0N4U7M4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7M4"
  },
  {
    "uniprot": "A0A0N4UMN1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMN1"
  },
  {
    "uniprot": "A0A0N4UH22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH22"
  },
  {
    "uniprot": "A0A0N4UNL7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNL7"
  },
  {
    "uniprot": "A0A0N4UQL2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQL2"
  },
  {
    "uniprot": "A0A0N4UIS2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIS2"
  },
  {
    "uniprot": "A0A0N4UNV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNV8"
  },
  {
    "uniprot": "A0A0N4UNC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNC1"
  },
  {
    "uniprot": "A0A0N4UEM7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEM7"
  },
  {
    "uniprot": "A0A0N4U906",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U906"
  },
  {
    "uniprot": "A0A0N4U6N6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6N6"
  },
  {
    "uniprot": "A0A158Q360",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q360"
  },
  {
    "uniprot": "A0A0N4U8V2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8V2"
  },
  {
    "uniprot": "A0A0N4U2Q7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Q7"
  },
  {
    "uniprot": "A0A0N4U7B7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7B7"
  },
  {
    "uniprot": "A0A0N4U125",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U125"
  },
  {
    "uniprot": "A0A3P7SR33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SR33"
  },
  {
    "uniprot": "A0A0N4U902",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U902"
  },
  {
    "uniprot": "A0A3P7Q0N4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q0N4"
  },
  {
    "uniprot": "A0A0N4UIN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIN0"
  },
  {
    "uniprot": "A0A0N4U1W1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1W1"
  },
  {
    "uniprot": "A0A3P7PQY8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQY8"
  },
  {
    "uniprot": "A0A3P7Q4F1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q4F1"
  },
  {
    "uniprot": "A0A0N4UJI2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJI2"
  },
  {
    "uniprot": "A0A0N4UBL1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBL1"
  },
  {
    "uniprot": "A0A0N4UA55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA55"
  },
  {
    "uniprot": "A0A0N4UFQ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFQ8"
  },
  {
    "uniprot": "A0A0N4U6I0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6I0"
  },
  {
    "uniprot": "A0A158Q5K5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5K5"
  },
  {
    "uniprot": "A0A0N4UHY5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHY5"
  },
  {
    "uniprot": "A0A0N4UFC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFC9"
  },
  {
    "uniprot": "A0A0N4URS4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URS4"
  },
  {
    "uniprot": "A0A0N4UQ34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ34"
  },
  {
    "uniprot": "A0A0N4UFU9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFU9"
  },
  {
    "uniprot": "A0A0N4U0P3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0P3"
  },
  {
    "uniprot": "A0A0N4UB91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB91"
  },
  {
    "uniprot": "A0A0N4U657",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U657"
  },
  {
    "uniprot": "A0A0N4UCE1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCE1"
  },
  {
    "uniprot": "A0A158Q6H7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6H7"
  },
  {
    "uniprot": "A0A3P7T3U5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T3U5"
  },
  {
    "uniprot": "A0A3P7T2I8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T2I8"
  },
  {
    "uniprot": "A0A0N4UIR5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIR5"
  },
  {
    "uniprot": "A0A0N4U4P5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4P5"
  },
  {
    "uniprot": "A0A0N4UM91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM91"
  },
  {
    "uniprot": "A0A0N4UFN4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFN4"
  },
  {
    "uniprot": "A0A0N4U4J0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4J0"
  },
  {
    "uniprot": "A0A0N4UE25",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE25"
  },
  {
    "uniprot": "A0A0N4U1T2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1T2"
  },
  {
    "uniprot": "A0A0N4UIA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIA0"
  },
  {
    "uniprot": "A0A158Q3Y3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Y3"
  },
  {
    "uniprot": "A0A0N4UFE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFE3"
  },
  {
    "uniprot": "A0A0N4U6P8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6P8"
  },
  {
    "uniprot": "A0A0N4UPK4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPK4"
  },
  {
    "uniprot": "A0A3P7QF30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QF30"
  },
  {
    "uniprot": "A0A3P7SQJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SQJ9"
  },
  {
    "uniprot": "A0A0N4UKM3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKM3"
  },
  {
    "uniprot": "A0A3P7P3X7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P3X7"
  },
  {
    "uniprot": "A0A0N4U5Q5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Q5"
  },
  {
    "uniprot": "A0A0N4UAG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAG4"
  },
  {
    "uniprot": "A0A0N4UEQ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEQ0"
  },
  {
    "uniprot": "A0A0N4U492",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U492"
  },
  {
    "uniprot": "A0A158Q2Y3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Y3"
  },
  {
    "uniprot": "A0A0N4UL67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL67"
  },
  {
    "uniprot": "A0A0N4UFE8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFE8"
  },
  {
    "uniprot": "A0A158Q3R7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3R7"
  },
  {
    "uniprot": "A0A0N4U3V9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3V9"
  },
  {
    "uniprot": "A0A0N4U1J3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1J3"
  },
  {
    "uniprot": "A0A0N4UGS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGS9"
  },
  {
    "uniprot": "A0A158Q479",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q479"
  },
  {
    "uniprot": "A0A0N4U406",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U406"
  },
  {
    "uniprot": "A0A158Q4R6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4R6"
  },
  {
    "uniprot": "A0A0N4UPU5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPU5"
  },
  {
    "uniprot": "A0A0N4U354",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U354"
  },
  {
    "uniprot": "A0A0N4UPP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPP9"
  },
  {
    "uniprot": "A0A0N4U429",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U429"
  },
  {
    "uniprot": "A0A0N4UIT8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIT8"
  },
  {
    "uniprot": "A0A0N4UCH5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCH5"
  },
  {
    "uniprot": "A0A0N4U1X2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1X2"
  },
  {
    "uniprot": "A0A0N4UM61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM61"
  },
  {
    "uniprot": "A0A0N4U4T9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4T9"
  },
  {
    "uniprot": "A0A0N4U867",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U867"
  },
  {
    "uniprot": "A0A0N4U4U5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4U5"
  },
  {
    "uniprot": "A0A0N4U644",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U644"
  },
  {
    "uniprot": "A0A0N4UIF6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIF6"
  },
  {
    "uniprot": "A0A0N4ULU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULU6"
  },
  {
    "uniprot": "A0A0N4UEC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEC7"
  },
  {
    "uniprot": "A0A158Q5N3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5N3"
  },
  {
    "uniprot": "A0A0N4UFX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFX9"
  },
  {
    "uniprot": "A0A0N4UEJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEJ1"
  },
  {
    "uniprot": "A0A0N4UAL5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAL5"
  },
  {
    "uniprot": "A0A158Q606",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q606"
  },
  {
    "uniprot": "A0A0N4UPK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPK3"
  },
  {
    "uniprot": "A0A3P7PR68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PR68"
  },
  {
    "uniprot": "A0A0N4UDV4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDV4"
  },
  {
    "uniprot": "A0A0N4UH33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH33"
  },
  {
    "uniprot": "A0A0N4UBW4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBW4"
  },
  {
    "uniprot": "A0A0N4U8D9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8D9"
  },
  {
    "uniprot": "A0A0N4UGJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGJ7"
  },
  {
    "uniprot": "A0A0N4UBU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBU7"
  },
  {
    "uniprot": "A0A0N4U6F4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6F4"
  },
  {
    "uniprot": "A0A0N4UHT9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHT9"
  },
  {
    "uniprot": "A0A0N4UQJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQJ7"
  },
  {
    "uniprot": "A0A0N4U4C9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4C9"
  },
  {
    "uniprot": "A0A3P7PBG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PBG3"
  },
  {
    "uniprot": "A0A0N4UEM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEM2"
  },
  {
    "uniprot": "A0A158Q3P7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3P7"
  },
  {
    "uniprot": "A0A0N4UDD4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDD4"
  },
  {
    "uniprot": "A0A0N4UA81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA81"
  },
  {
    "uniprot": "A0A0N4U4Z9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Z9"
  },
  {
    "uniprot": "A0A0N4UL48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL48"
  },
  {
    "uniprot": "A0A3P7QZI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QZI3"
  },
  {
    "uniprot": "A0A0N4UIZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIZ4"
  },
  {
    "uniprot": "A0A0N4UCK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCK3"
  },
  {
    "uniprot": "A0A0N4UGC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGC9"
  },
  {
    "uniprot": "A0A0N4UAR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAR4"
  },
  {
    "uniprot": "A0A3P7SD09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SD09"
  },
  {
    "uniprot": "A0A3P7Q7V5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q7V5"
  },
  {
    "uniprot": "A0A0N4U1G4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1G4"
  },
  {
    "uniprot": "A0A0N4UKR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKR7"
  },
  {
    "uniprot": "A0A3P7T3A4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T3A4"
  },
  {
    "uniprot": "A0A158Q5Q9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Q9"
  },
  {
    "uniprot": "A0A0N4U9Z5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Z5"
  },
  {
    "uniprot": "A0A0N4UFZ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFZ2"
  },
  {
    "uniprot": "A0A0N4UH84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH84"
  },
  {
    "uniprot": "A0A3P7QWG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QWG6"
  },
  {
    "uniprot": "A0A3P7Q819",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q819"
  },
  {
    "uniprot": "A0A0N4UCN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCN2"
  },
  {
    "uniprot": "A0A0N4UJ72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ72"
  },
  {
    "uniprot": "A0A0N4UJQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJQ5"
  },
  {
    "uniprot": "A0A0N4UIT2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIT2"
  },
  {
    "uniprot": "A0A0N4UBW7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBW7"
  },
  {
    "uniprot": "A0A0N4U1D6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1D6"
  },
  {
    "uniprot": "A0A0N4UR50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR50"
  },
  {
    "uniprot": "A0A0N4U2V6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2V6"
  },
  {
    "uniprot": "A0A0N4U227",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U227"
  },
  {
    "uniprot": "A0A0N4U6C0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6C0"
  },
  {
    "uniprot": "A0A0N4UEU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEU3"
  },
  {
    "uniprot": "A0A0N4U2I6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2I6"
  },
  {
    "uniprot": "A0A0N4UGQ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGQ9"
  },
  {
    "uniprot": "A0A0N4U6A5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6A5"
  },
  {
    "uniprot": "A0A0N4UL50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL50"
  },
  {
    "uniprot": "A0A0N4UHB4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHB4"
  },
  {
    "uniprot": "A0A0N4UM68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM68"
  },
  {
    "uniprot": "A0A0N4U7D5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7D5"
  },
  {
    "uniprot": "A0A158Q2J3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2J3"
  },
  {
    "uniprot": "A0A0N4U7R7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7R7"
  },
  {
    "uniprot": "A0A0N4U305",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U305"
  },
  {
    "uniprot": "A0A158Q430",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q430"
  },
  {
    "uniprot": "A0A0N4U2T7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2T7"
  },
  {
    "uniprot": "A0A0N4U1I8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1I8"
  },
  {
    "uniprot": "A0A3P7Q652",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q652"
  },
  {
    "uniprot": "A0A0N4U308",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U308"
  },
  {
    "uniprot": "A0A158Q3S2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3S2"
  },
  {
    "uniprot": "A0A158Q4Y2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Y2"
  },
  {
    "uniprot": "A0A0N4UB68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB68"
  },
  {
    "uniprot": "A0A0N4UNP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNP0"
  },
  {
    "uniprot": "A0A0N4UND9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UND9"
  },
  {
    "uniprot": "A0A0N4U296",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U296"
  },
  {
    "uniprot": "A0A3P7Q9T8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q9T8"
  },
  {
    "uniprot": "A0A0N4UPR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPR4"
  },
  {
    "uniprot": "A0A158Q322",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q322"
  },
  {
    "uniprot": "A0A158Q445",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q445"
  },
  {
    "uniprot": "A0A158Q6L8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6L8"
  },
  {
    "uniprot": "A0A158Q6I2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6I2"
  },
  {
    "uniprot": "A0A0N4U3Z8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Z8"
  },
  {
    "uniprot": "A0A0N4U8P3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8P3"
  },
  {
    "uniprot": "A0A0N4UGR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGR4"
  },
  {
    "uniprot": "A0A0N4U5S8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5S8"
  },
  {
    "uniprot": "A0A0N4U6A1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6A1"
  },
  {
    "uniprot": "A0A158Q579",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q579"
  },
  {
    "uniprot": "A0A0N4UR10",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR10"
  },
  {
    "uniprot": "A0A0N4U581",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U581"
  },
  {
    "uniprot": "A0A0N4U7J1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7J1"
  },
  {
    "uniprot": "A0A0N4UHA3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHA3"
  },
  {
    "uniprot": "A0A3P7QAD0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QAD0"
  },
  {
    "uniprot": "A0A0N4UNV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNV3"
  },
  {
    "uniprot": "A0A0N4U8X2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8X2"
  },
  {
    "uniprot": "A0A0N4UDI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDI0"
  },
  {
    "uniprot": "A0A0N4UKJ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKJ5"
  },
  {
    "uniprot": "A0A0N4UM55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM55"
  },
  {
    "uniprot": "A0A0N4UJ96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ96"
  },
  {
    "uniprot": "A0A0N4U3A0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3A0"
  },
  {
    "uniprot": "A0A158Q4U7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4U7"
  },
  {
    "uniprot": "A0A3P7Q257",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q257"
  },
  {
    "uniprot": "A0A0N4U5E5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5E5"
  },
  {
    "uniprot": "A0A0N4UK37",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK37"
  },
  {
    "uniprot": "A0A0N4U1V5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1V5"
  },
  {
    "uniprot": "A0A0N4UJY6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJY6"
  },
  {
    "uniprot": "A0A0N4UK58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK58"
  },
  {
    "uniprot": "A0A158Q6C3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6C3"
  },
  {
    "uniprot": "A0A3P7PPG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPG4"
  },
  {
    "uniprot": "A0A3P7QW54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QW54"
  },
  {
    "uniprot": "A0A3P7QG06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QG06"
  },
  {
    "uniprot": "A0A0N4U8A4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8A4"
  },
  {
    "uniprot": "A0A0N4U0Z6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Z6"
  },
  {
    "uniprot": "A0A0N4U729",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U729"
  },
  {
    "uniprot": "A0A0N4U482",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U482"
  },
  {
    "uniprot": "A0A0N4UDJ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDJ0"
  },
  {
    "uniprot": "A0A0N4UK07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK07"
  },
  {
    "uniprot": "A0A0N4UHT4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHT4"
  },
  {
    "uniprot": "A0A3P7T940",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T940"
  },
  {
    "uniprot": "A0A158Q2H8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2H8"
  },
  {
    "uniprot": "A0A0N4U5Q0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Q0"
  },
  {
    "uniprot": "A0A0N4UKE2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKE2"
  },
  {
    "uniprot": "A0A0N4UQY8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQY8"
  },
  {
    "uniprot": "A0A0N4UIJ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIJ8"
  },
  {
    "uniprot": "A0A0N4UGZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGZ4"
  },
  {
    "uniprot": "A0A0N4U7V9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7V9"
  },
  {
    "uniprot": "A0A158Q4M2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4M2"
  },
  {
    "uniprot": "A0A0N4U1E6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1E6"
  },
  {
    "uniprot": "A0A0N4UM00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM00"
  },
  {
    "uniprot": "A0A0N4USA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USA9"
  },
  {
    "uniprot": "A0A0N4UHP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHP6"
  },
  {
    "uniprot": "A0A0N4UB41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB41"
  },
  {
    "uniprot": "A0A0N4UCF2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCF2"
  },
  {
    "uniprot": "A0A0N4U9H7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9H7"
  },
  {
    "uniprot": "A0A0N4UEP2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEP2"
  },
  {
    "uniprot": "A0A0N4UH55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH55"
  },
  {
    "uniprot": "A0A0N4UDM4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDM4"
  },
  {
    "uniprot": "A0A158Q671",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q671"
  },
  {
    "uniprot": "A0A0N4UL95",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL95"
  },
  {
    "uniprot": "A0A158Q5S1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5S1"
  },
  {
    "uniprot": "A0A0N4U6P9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6P9"
  },
  {
    "uniprot": "A0A0N4U9Z2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Z2"
  },
  {
    "uniprot": "A0A0N4U0J8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0J8"
  },
  {
    "uniprot": "A0A3P7PKX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PKX5"
  },
  {
    "uniprot": "A0A0N4U8I9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8I9"
  },
  {
    "uniprot": "A0A0N4U7S8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7S8"
  },
  {
    "uniprot": "A0A0N4UBH8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBH8"
  },
  {
    "uniprot": "A0A0N4UG47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG47"
  },
  {
    "uniprot": "A0A0N4U7T0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7T0"
  },
  {
    "uniprot": "A0A0N4UMX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMX7"
  },
  {
    "uniprot": "A0A0N4U4J9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4J9"
  },
  {
    "uniprot": "A0A0N4UEF2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEF2"
  },
  {
    "uniprot": "A0A3P7Q8L7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q8L7"
  },
  {
    "uniprot": "A0A158Q618",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q618"
  },
  {
    "uniprot": "A0A0N4U5H2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5H2"
  },
  {
    "uniprot": "A0A0N4U209",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U209"
  },
  {
    "uniprot": "A0A0N4U837",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U837"
  },
  {
    "uniprot": "A0A0N4UKT0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKT0"
  },
  {
    "uniprot": "A0A0N4UP72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP72"
  },
  {
    "uniprot": "A0A0N4UQQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQQ5"
  },
  {
    "uniprot": "A0A0N4UQ96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ96"
  },
  {
    "uniprot": "A0A0N4U256",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U256"
  },
  {
    "uniprot": "A0A158Q649",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q649"
  },
  {
    "uniprot": "A0A0N4UE38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE38"
  },
  {
    "uniprot": "A0A0N4U4J4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4J4"
  },
  {
    "uniprot": "A0A0N4U2B0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2B0"
  },
  {
    "uniprot": "A0A0N4U5J0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5J0"
  },
  {
    "uniprot": "A0A0N4U4P1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4P1"
  },
  {
    "uniprot": "A0A0N4U833",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U833"
  },
  {
    "uniprot": "A0A158Q5V1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5V1"
  },
  {
    "uniprot": "A0A0N4UF61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF61"
  },
  {
    "uniprot": "A0A0N4UB32",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB32"
  },
  {
    "uniprot": "A0A0N4U0N9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0N9"
  },
  {
    "uniprot": "A0A0N4U4D1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4D1"
  },
  {
    "uniprot": "A0A0N4UIV1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIV1"
  },
  {
    "uniprot": "A0A0N4UIK9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIK9"
  },
  {
    "uniprot": "A0A0N4U1X1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1X1"
  },
  {
    "uniprot": "A0A0N4U150",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U150"
  },
  {
    "uniprot": "A0A3P7P9R5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P9R5"
  },
  {
    "uniprot": "A0A0N4UG19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG19"
  },
  {
    "uniprot": "A0A0N4U905",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U905"
  },
  {
    "uniprot": "A0A0N4UD33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD33"
  },
  {
    "uniprot": "A0A0N4UG80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG80"
  },
  {
    "uniprot": "A0A0N4ULJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULJ2"
  },
  {
    "uniprot": "A0A0N4UIW1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIW1"
  },
  {
    "uniprot": "A0A0N4URW9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URW9"
  },
  {
    "uniprot": "A0A158Q4J3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4J3"
  },
  {
    "uniprot": "A0A0N4UI32",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI32"
  },
  {
    "uniprot": "A0A0N4UI70",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI70"
  },
  {
    "uniprot": "A0A0N4U554",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U554"
  },
  {
    "uniprot": "A0A0N4U7H3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7H3"
  },
  {
    "uniprot": "A0A0N4UM07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM07"
  },
  {
    "uniprot": "A0A0N4UIE2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIE2"
  },
  {
    "uniprot": "A0A0N4UDQ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDQ2"
  },
  {
    "uniprot": "A0A158Q5F8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5F8"
  },
  {
    "uniprot": "A0A158Q5L9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5L9"
  },
  {
    "uniprot": "A0A0N4UCD0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCD0"
  },
  {
    "uniprot": "A0A0N4U2S3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2S3"
  },
  {
    "uniprot": "A0A0N4U630",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U630"
  },
  {
    "uniprot": "A0A0N4UDI5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDI5"
  },
  {
    "uniprot": "A0A0N4UF16",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF16"
  },
  {
    "uniprot": "A0A158Q664",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q664"
  },
  {
    "uniprot": "A0A0N4UK29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK29"
  },
  {
    "uniprot": "A0A0N4UB58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB58"
  },
  {
    "uniprot": "A0A0N4UGN1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGN1"
  },
  {
    "uniprot": "A0A0N4UN70",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN70"
  },
  {
    "uniprot": "A0A0N4U3K4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3K4"
  },
  {
    "uniprot": "A0A0N4U629",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U629"
  },
  {
    "uniprot": "A0A0N4U0Q1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Q1"
  },
  {
    "uniprot": "A0A0N4U212",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U212"
  },
  {
    "uniprot": "A0A0N4UQF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQF9"
  },
  {
    "uniprot": "A0A0N4U0I1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0I1"
  },
  {
    "uniprot": "A0A0N4U6B2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6B2"
  },
  {
    "uniprot": "A0A0N4U0X1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0X1"
  },
  {
    "uniprot": "A0A0N4U5U6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5U6"
  },
  {
    "uniprot": "A0A0N4UD88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD88"
  },
  {
    "uniprot": "A0A3P7SQ52",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SQ52"
  },
  {
    "uniprot": "A0A0N4U9N7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9N7"
  },
  {
    "uniprot": "A0A0N4UDZ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDZ7"
  },
  {
    "uniprot": "A0A3P7SU86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SU86"
  },
  {
    "uniprot": "A0A3P7S6T9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S6T9"
  },
  {
    "uniprot": "A0A0N4UHJ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHJ3"
  },
  {
    "uniprot": "A0A0N4UFE5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFE5"
  },
  {
    "uniprot": "A0A0N4UAI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAI9"
  },
  {
    "uniprot": "A0A0N4UNB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNB3"
  },
  {
    "uniprot": "A0A0N4ULY9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULY9"
  },
  {
    "uniprot": "A0A3P7PEK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PEK2"
  },
  {
    "uniprot": "A0A0N4UHJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHJ4"
  },
  {
    "uniprot": "A0A0N4UDL4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDL4"
  },
  {
    "uniprot": "A0A0N4UAN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAN3"
  },
  {
    "uniprot": "A0A0N4U7K3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7K3"
  },
  {
    "uniprot": "A0A158Q3E9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3E9"
  },
  {
    "uniprot": "A0A0N4U370",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U370"
  },
  {
    "uniprot": "A0A0N4U1W3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1W3"
  },
  {
    "uniprot": "A0A0N4UR28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR28"
  },
  {
    "uniprot": "A0A158Q5N5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5N5"
  },
  {
    "uniprot": "A0A0N4UFP8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFP8"
  },
  {
    "uniprot": "A0A3P7PZZ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PZZ6"
  },
  {
    "uniprot": "A0A0N4URT9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URT9"
  },
  {
    "uniprot": "A0A0N4UFD8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFD8"
  },
  {
    "uniprot": "A0A0N4UH61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH61"
  },
  {
    "uniprot": "A0A0N4UH13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH13"
  },
  {
    "uniprot": "A0A0N4UPS2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPS2"
  },
  {
    "uniprot": "A0A0N4U2Q6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Q6"
  },
  {
    "uniprot": "A0A3P7SUZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SUZ1"
  },
  {
    "uniprot": "A0A3P7Q4C9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q4C9"
  },
  {
    "uniprot": "A0A0N4UFY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFY2"
  },
  {
    "uniprot": "A0A0N4U6J2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6J2"
  },
  {
    "uniprot": "A0A3P7QS82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QS82"
  },
  {
    "uniprot": "A0A0N4U9S9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9S9"
  },
  {
    "uniprot": "A0A0N4UFC8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFC8"
  },
  {
    "uniprot": "A0A0N4U786",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U786"
  },
  {
    "uniprot": "A0A0N4UE45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE45"
  },
  {
    "uniprot": "A0A0N4UPG8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPG8"
  },
  {
    "uniprot": "A0A158Q4A2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4A2"
  },
  {
    "uniprot": "A0A0N4U9K2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9K2"
  },
  {
    "uniprot": "A0A0N4U5M0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5M0"
  },
  {
    "uniprot": "A0A3P7SX33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SX33"
  },
  {
    "uniprot": "A0A158Q4C6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4C6"
  },
  {
    "uniprot": "A0A0N4UDN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDN0"
  },
  {
    "uniprot": "A0A0N4UMZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMZ4"
  },
  {
    "uniprot": "A0A0N4UDP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDP0"
  },
  {
    "uniprot": "A0A0N4U7Y0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Y0"
  },
  {
    "uniprot": "A0A0N4UD16",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD16"
  },
  {
    "uniprot": "A0A0N4UDS7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDS7"
  },
  {
    "uniprot": "A0A158Q3V2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3V2"
  },
  {
    "uniprot": "A0A0N4U416",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U416"
  },
  {
    "uniprot": "A0A0N4UKH0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKH0"
  },
  {
    "uniprot": "A0A158Q4X4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4X4"
  },
  {
    "uniprot": "A0A158Q4Y1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Y1"
  },
  {
    "uniprot": "A0A158Q361",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q361"
  },
  {
    "uniprot": "A0A0N4U9P6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9P6"
  },
  {
    "uniprot": "A0A0N4UPH7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPH7"
  },
  {
    "uniprot": "A0A0N4UCL6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCL6"
  },
  {
    "uniprot": "A0A158Q3G6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3G6"
  },
  {
    "uniprot": "A0A0N4UGY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGY2"
  },
  {
    "uniprot": "A0A3P7TEC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TEC0"
  },
  {
    "uniprot": "A0A0N4UBL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBL9"
  },
  {
    "uniprot": "A0A0N4UK32",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK32"
  },
  {
    "uniprot": "A0A0N4U703",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U703"
  },
  {
    "uniprot": "A0A0N4UCE0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCE0"
  },
  {
    "uniprot": "A0A0N4U5T4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5T4"
  },
  {
    "uniprot": "A0A0N4UQR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQR7"
  },
  {
    "uniprot": "A0A158Q2U3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2U3"
  },
  {
    "uniprot": "A0A0N4UF78",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF78"
  },
  {
    "uniprot": "A0A0N4UL65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL65"
  },
  {
    "uniprot": "A0A3P7P4U8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P4U8"
  },
  {
    "uniprot": "A0A3P7P9M8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P9M8"
  },
  {
    "uniprot": "A0A0N4UBA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBA0"
  },
  {
    "uniprot": "A0A0N4UBR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBR7"
  },
  {
    "uniprot": "A0A0N4U7E3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7E3"
  },
  {
    "uniprot": "A0A158Q3J7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3J7"
  },
  {
    "uniprot": "A0A0N4U2I1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2I1"
  },
  {
    "uniprot": "A0A0N4U613",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U613"
  },
  {
    "uniprot": "A0A3P7PVR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PVR9"
  },
  {
    "uniprot": "A0A0N4URT0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URT0"
  },
  {
    "uniprot": "A0A0N4U529",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U529"
  },
  {
    "uniprot": "A0A0N4U2X8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2X8"
  },
  {
    "uniprot": "A0A0N4U5N3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5N3"
  },
  {
    "uniprot": "A0A0N4UP30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP30"
  },
  {
    "uniprot": "A0A158Q2R0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2R0"
  },
  {
    "uniprot": "A0A0N4UF33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF33"
  },
  {
    "uniprot": "A0A0N4UD42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD42"
  },
  {
    "uniprot": "A0A0N4UHH0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHH0"
  },
  {
    "uniprot": "A0A158Q5Z2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Z2"
  },
  {
    "uniprot": "A0A0N4URK9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URK9"
  },
  {
    "uniprot": "A0A0N4UMW4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMW4"
  },
  {
    "uniprot": "A0A0N4U4B9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4B9"
  },
  {
    "uniprot": "A0A0N4UCD8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCD8"
  },
  {
    "uniprot": "A0A0N4URJ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URJ0"
  },
  {
    "uniprot": "A0A0N4UDE0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDE0"
  },
  {
    "uniprot": "A0A158Q5R5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5R5"
  },
  {
    "uniprot": "A0A0N4UBB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBB9"
  },
  {
    "uniprot": "A0A0N4U7B9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7B9"
  },
  {
    "uniprot": "A0A3P7QA55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QA55"
  },
  {
    "uniprot": "A0A0N4UDJ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDJ8"
  },
  {
    "uniprot": "A0A0N4U395",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U395"
  },
  {
    "uniprot": "A0A3P7PID9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PID9"
  },
  {
    "uniprot": "A0A0N4U7S3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7S3"
  },
  {
    "uniprot": "A0A158Q5R8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5R8"
  },
  {
    "uniprot": "A0A0N4UNY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNY0"
  },
  {
    "uniprot": "A0A0N4UPH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPH9"
  },
  {
    "uniprot": "A0A158Q2I4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2I4"
  },
  {
    "uniprot": "A0A0N4U2Q2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Q2"
  },
  {
    "uniprot": "A0A0N4UGN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGN6"
  },
  {
    "uniprot": "A0A158Q630",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q630"
  },
  {
    "uniprot": "A0A3P7QF83",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QF83"
  },
  {
    "uniprot": "A0A0N4U600",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U600"
  },
  {
    "uniprot": "A0A0N4UI20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI20"
  },
  {
    "uniprot": "A0A3P7PV58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PV58"
  },
  {
    "uniprot": "A0A0N4UEG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEG6"
  },
  {
    "uniprot": "A0A0N4UK12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK12"
  },
  {
    "uniprot": "A0A0N4U7Z8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Z8"
  },
  {
    "uniprot": "A0A0N4UAH5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAH5"
  },
  {
    "uniprot": "A0A0N4UKU8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKU8"
  },
  {
    "uniprot": "A0A0N4UJJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJJ2"
  },
  {
    "uniprot": "A0A0N4U8C0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8C0"
  },
  {
    "uniprot": "A0A0N4U199",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U199"
  },
  {
    "uniprot": "A0A0N4U5B5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5B5"
  },
  {
    "uniprot": "A0A0N4UPJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPJ4"
  },
  {
    "uniprot": "A0A0N4UBM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBM8"
  },
  {
    "uniprot": "A0A3P7Q0Q8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q0Q8"
  },
  {
    "uniprot": "A0A158Q3C4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3C4"
  },
  {
    "uniprot": "A0A0N4U8G3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8G3"
  },
  {
    "uniprot": "A0A158Q4L9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4L9"
  },
  {
    "uniprot": "A0A158Q6G1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6G1"
  },
  {
    "uniprot": "A0A0N4U0F9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0F9"
  },
  {
    "uniprot": "A0A158Q3S6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3S6"
  },
  {
    "uniprot": "A0A0N4UCA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCA4"
  },
  {
    "uniprot": "A0A0N4URC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URC9"
  },
  {
    "uniprot": "A0A0N4U0U3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0U3"
  },
  {
    "uniprot": "A0A158Q6M4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6M4"
  },
  {
    "uniprot": "A0A0N4U0N3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0N3"
  },
  {
    "uniprot": "A0A0N4UJP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJP9"
  },
  {
    "uniprot": "A0A0N4UJD8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJD8"
  },
  {
    "uniprot": "A0A158Q5F0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5F0"
  },
  {
    "uniprot": "A0A0N4UE97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE97"
  },
  {
    "uniprot": "A0A3P7TGC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TGC4"
  },
  {
    "uniprot": "A0A158Q4Z4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Z4"
  },
  {
    "uniprot": "A0A0N4UGJ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGJ6"
  },
  {
    "uniprot": "A0A0N4UH67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH67"
  },
  {
    "uniprot": "A0A0N4ULL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULL0"
  },
  {
    "uniprot": "A0A0N4UBR5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBR5"
  },
  {
    "uniprot": "A0A0N4UJ71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ71"
  },
  {
    "uniprot": "A0A158Q4D5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4D5"
  },
  {
    "uniprot": "A0A0N4UP01",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP01"
  },
  {
    "uniprot": "A0A0N4UJ66",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ66"
  },
  {
    "uniprot": "A0A0N4UKG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKG3"
  },
  {
    "uniprot": "A0A0N4UB98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB98"
  },
  {
    "uniprot": "A0A0N4U9M1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9M1"
  },
  {
    "uniprot": "A0A3P7QH72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QH72"
  },
  {
    "uniprot": "A0A0N4U4Y1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Y1"
  },
  {
    "uniprot": "A0A0N4U141",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U141"
  },
  {
    "uniprot": "A0A158Q386",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q386"
  },
  {
    "uniprot": "A0A0N4U955",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U955"
  },
  {
    "uniprot": "A0A0N4UKY5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKY5"
  },
  {
    "uniprot": "A0A0N4U6P2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6P2"
  },
  {
    "uniprot": "A0A0N4UPG7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPG7"
  },
  {
    "uniprot": "A0A0N4ULF0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULF0"
  },
  {
    "uniprot": "A0A0N4UEB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEB1"
  },
  {
    "uniprot": "A0A0N4U1S3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1S3"
  },
  {
    "uniprot": "A0A0N4UGX4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGX4"
  },
  {
    "uniprot": "A0A158Q5P3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5P3"
  },
  {
    "uniprot": "A0A0N4U2G8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2G8"
  },
  {
    "uniprot": "A0A158Q2N8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2N8"
  },
  {
    "uniprot": "A0A158Q6G6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6G6"
  },
  {
    "uniprot": "A0A0N4ULN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULN6"
  },
  {
    "uniprot": "A0A0N4U242",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U242"
  },
  {
    "uniprot": "A0A0N4URZ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URZ2"
  },
  {
    "uniprot": "A0A0N4UPJ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPJ3"
  },
  {
    "uniprot": "A0A0N4U4J8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4J8"
  },
  {
    "uniprot": "A0A0N4U1F9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1F9"
  },
  {
    "uniprot": "A0A0N4UEH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEH1"
  },
  {
    "uniprot": "A0A0N4UE36",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE36"
  },
  {
    "uniprot": "A0A158Q3V9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3V9"
  },
  {
    "uniprot": "A0A0N4UD37",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD37"
  },
  {
    "uniprot": "A0A0N4U4H4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4H4"
  },
  {
    "uniprot": "A0A3P7PAU5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PAU5"
  },
  {
    "uniprot": "A0A0N4UDZ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDZ6"
  },
  {
    "uniprot": "A0A0N4URQ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URQ2"
  },
  {
    "uniprot": "A0A0N4UHJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHJ7"
  },
  {
    "uniprot": "A0A0N4UMM4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMM4"
  },
  {
    "uniprot": "A0A158Q2Z6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Z6"
  },
  {
    "uniprot": "A0A0N4UDK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDK0"
  },
  {
    "uniprot": "A0A3P7SD05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SD05"
  },
  {
    "uniprot": "A0A0N4UA80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA80"
  },
  {
    "uniprot": "A0A158Q2L7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2L7"
  },
  {
    "uniprot": "A0A3P7Q930",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q930"
  },
  {
    "uniprot": "A0A3P7QDU1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QDU1"
  },
  {
    "uniprot": "A0A0N4UBK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBK0"
  },
  {
    "uniprot": "A0A0N4UG81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG81"
  },
  {
    "uniprot": "A0A0N4UI89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI89"
  },
  {
    "uniprot": "A0A0N4U2J3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2J3"
  },
  {
    "uniprot": "A0A0N4UF22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF22"
  },
  {
    "uniprot": "A0A158Q459",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q459"
  },
  {
    "uniprot": "A0A0N4UIT5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIT5"
  },
  {
    "uniprot": "A0A0N4U7L5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7L5"
  },
  {
    "uniprot": "A0A0N4UD08",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD08"
  },
  {
    "uniprot": "A0A0N4U3E4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3E4"
  },
  {
    "uniprot": "A0A3P7PMU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PMU2"
  },
  {
    "uniprot": "A0A0N4UR39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR39"
  },
  {
    "uniprot": "A0A3P7Q9Z1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q9Z1"
  },
  {
    "uniprot": "A0A0N4UDR3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDR3"
  },
  {
    "uniprot": "A0A0N4UM79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM79"
  },
  {
    "uniprot": "A0A0N4UQT7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQT7"
  },
  {
    "uniprot": "A0A158Q522",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q522"
  },
  {
    "uniprot": "A0A158Q323",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q323"
  },
  {
    "uniprot": "A0A0N4U3S7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3S7"
  },
  {
    "uniprot": "A0A0N4UKY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKY1"
  },
  {
    "uniprot": "A0A0N4UHJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHJ9"
  },
  {
    "uniprot": "A0A0N4UHJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHJ1"
  },
  {
    "uniprot": "A0A0N4UCB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCB7"
  },
  {
    "uniprot": "A0A0N4U8X9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8X9"
  },
  {
    "uniprot": "A0A0N4UR46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR46"
  },
  {
    "uniprot": "A0A0N4UIP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIP3"
  },
  {
    "uniprot": "A0A0N4UFZ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFZ0"
  },
  {
    "uniprot": "A0A0N4UH47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH47"
  },
  {
    "uniprot": "A0A158Q3W5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3W5"
  },
  {
    "uniprot": "A0A0N4UQB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQB2"
  },
  {
    "uniprot": "A0A0N4UCQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCQ1"
  },
  {
    "uniprot": "A0A0N4U4S1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4S1"
  },
  {
    "uniprot": "A0A0N4URF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URF5"
  },
  {
    "uniprot": "A0A0N4UDC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDC9"
  },
  {
    "uniprot": "A0A0N4US97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US97"
  },
  {
    "uniprot": "A0A0N4UQW5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQW5"
  },
  {
    "uniprot": "A0A0N4U5X8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5X8"
  },
  {
    "uniprot": "A0A0N4U7S1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7S1"
  },
  {
    "uniprot": "A0A0N4UFR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFR7"
  },
  {
    "uniprot": "A0A0N4U6K0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6K0"
  },
  {
    "uniprot": "A0A3P7SCS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SCS9"
  },
  {
    "uniprot": "A0A0N4UJ94",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ94"
  },
  {
    "uniprot": "A0A0N4UKD9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKD9"
  },
  {
    "uniprot": "A0A0N4UCS4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCS4"
  },
  {
    "uniprot": "A0A0N4UDR5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDR5"
  },
  {
    "uniprot": "A0A158Q2P1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2P1"
  },
  {
    "uniprot": "A0A0N4UMZ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMZ6"
  },
  {
    "uniprot": "A0A0N4U198",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U198"
  },
  {
    "uniprot": "A0A0N4UBX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBX9"
  },
  {
    "uniprot": "A0A0N4UEK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEK1"
  },
  {
    "uniprot": "A0A0N4UPH0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPH0"
  },
  {
    "uniprot": "A0A158Q611",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q611"
  },
  {
    "uniprot": "A0A3P7PBT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PBT6"
  },
  {
    "uniprot": "A0A0N4U5Q4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Q4"
  },
  {
    "uniprot": "A0A0N4U342",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U342"
  },
  {
    "uniprot": "A0A0N4U6Z9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Z9"
  },
  {
    "uniprot": "A0A0N4UMI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMI3"
  },
  {
    "uniprot": "A0A3P7Q8T7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q8T7"
  },
  {
    "uniprot": "A0A3P7SH59",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SH59"
  },
  {
    "uniprot": "A0A3P7PJT4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PJT4"
  },
  {
    "uniprot": "A0A0N4UQE6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQE6"
  },
  {
    "uniprot": "A0A0N4U3P2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3P2"
  },
  {
    "uniprot": "A0A158Q5S7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5S7"
  },
  {
    "uniprot": "A0A158Q633",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q633"
  },
  {
    "uniprot": "A0A0N4UJJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJJ4"
  },
  {
    "uniprot": "A0A0N4UAD8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAD8"
  },
  {
    "uniprot": "A0A0N4U9J1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9J1"
  },
  {
    "uniprot": "A0A0N4UM44",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM44"
  },
  {
    "uniprot": "A0A0N4UD87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD87"
  },
  {
    "uniprot": "A0A0N4U2X5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2X5"
  },
  {
    "uniprot": "A0A0N4U3Q8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Q8"
  },
  {
    "uniprot": "A0A0N4UGX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGX7"
  },
  {
    "uniprot": "A0A0N4UP82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP82"
  },
  {
    "uniprot": "A0A0N4URE0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URE0"
  },
  {
    "uniprot": "A0A158Q377",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q377"
  },
  {
    "uniprot": "A0A0N4U5L4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5L4"
  },
  {
    "uniprot": "A0A0N4UKS5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKS5"
  },
  {
    "uniprot": "A0A0N4U214",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U214"
  },
  {
    "uniprot": "A0A0N4UA27",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA27"
  },
  {
    "uniprot": "A0A0N4UHC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHC5"
  },
  {
    "uniprot": "A0A0N4U356",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U356"
  },
  {
    "uniprot": "A0A158Q546",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q546"
  },
  {
    "uniprot": "A0A0N4ULX4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULX4"
  },
  {
    "uniprot": "A0A0N4U0S7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0S7"
  },
  {
    "uniprot": "A0A0N4UDT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDT6"
  },
  {
    "uniprot": "A0A0N4U683",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U683"
  },
  {
    "uniprot": "A0A0N4U4F5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4F5"
  },
  {
    "uniprot": "A0A0N4UHN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHN0"
  },
  {
    "uniprot": "A0A0N4UMH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMH4"
  },
  {
    "uniprot": "A0A0N4UI56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI56"
  },
  {
    "uniprot": "A0A0N4UL64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL64"
  },
  {
    "uniprot": "A0A0N4UNJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNJ2"
  },
  {
    "uniprot": "A0A0N4UFG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFG2"
  },
  {
    "uniprot": "A0A0N4URK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URK0"
  },
  {
    "uniprot": "A0A0N4URK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URK1"
  },
  {
    "uniprot": "A0A0N4UF01",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF01"
  },
  {
    "uniprot": "A0A0N4UIN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIN3"
  },
  {
    "uniprot": "A0A0N4UBQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBQ4"
  },
  {
    "uniprot": "A0A158Q5U6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5U6"
  },
  {
    "uniprot": "A0A0N4U5P4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5P4"
  },
  {
    "uniprot": "A0A0N4UPK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPK0"
  },
  {
    "uniprot": "A0A0N4U9V9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9V9"
  },
  {
    "uniprot": "A0A0N4URM6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URM6"
  },
  {
    "uniprot": "A0A3P7PV60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PV60"
  },
  {
    "uniprot": "A0A0N4UCV9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCV9"
  },
  {
    "uniprot": "A0A0N4UBC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBC0"
  },
  {
    "uniprot": "A0A3P7SXQ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SXQ9"
  },
  {
    "uniprot": "A0A0N4UMD7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMD7"
  },
  {
    "uniprot": "A0A158Q6I6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6I6"
  },
  {
    "uniprot": "A0A0N4UAR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAR2"
  },
  {
    "uniprot": "A0A0N4UBU0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBU0"
  },
  {
    "uniprot": "A0A158Q2J5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2J5"
  },
  {
    "uniprot": "A0A0N4UHA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHA2"
  },
  {
    "uniprot": "A0A0N4UAZ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAZ6"
  },
  {
    "uniprot": "A0A158Q2R9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2R9"
  },
  {
    "uniprot": "A0A0N4UFM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFM0"
  },
  {
    "uniprot": "A0A0N4UH74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH74"
  },
  {
    "uniprot": "A0A0N4U741",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U741"
  },
  {
    "uniprot": "A0A0N4U6R7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6R7"
  },
  {
    "uniprot": "A0A0N4U6Y5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Y5"
  },
  {
    "uniprot": "A0A0N4UFH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFH1"
  },
  {
    "uniprot": "A0A0N4UC12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC12"
  },
  {
    "uniprot": "A0A0N4U953",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U953"
  },
  {
    "uniprot": "A0A0N4UJY8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJY8"
  },
  {
    "uniprot": "A0A0N4UQ53",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ53"
  },
  {
    "uniprot": "A0A0N4UB95",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB95"
  },
  {
    "uniprot": "A0A0N4UGH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGH6"
  },
  {
    "uniprot": "A0A0N4U9V3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9V3"
  },
  {
    "uniprot": "A0A0N4U6Z3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Z3"
  },
  {
    "uniprot": "A0A158Q405",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q405"
  },
  {
    "uniprot": "A0A0N4UQ20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ20"
  },
  {
    "uniprot": "A0A0N4UCB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCB3"
  },
  {
    "uniprot": "A0A3P7SH92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SH92"
  },
  {
    "uniprot": "A0A0N4UR25",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR25"
  },
  {
    "uniprot": "A0A0N4U3R4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3R4"
  },
  {
    "uniprot": "A0A0N4U760",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U760"
  },
  {
    "uniprot": "A0A0N4URS0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URS0"
  },
  {
    "uniprot": "A0A0N4UPT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPT6"
  },
  {
    "uniprot": "A0A0N4UJ11",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ11"
  },
  {
    "uniprot": "A0A0N4UI26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI26"
  },
  {
    "uniprot": "A0A0N4UGZ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGZ2"
  },
  {
    "uniprot": "A0A3P7QL31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QL31"
  },
  {
    "uniprot": "A0A158Q477",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q477"
  },
  {
    "uniprot": "A0A158Q4C3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4C3"
  },
  {
    "uniprot": "A0A0N4U1Q7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Q7"
  },
  {
    "uniprot": "A0A158Q603",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q603"
  },
  {
    "uniprot": "A0A0N4U6H5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6H5"
  },
  {
    "uniprot": "A0A0N4US77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US77"
  },
  {
    "uniprot": "A0A0N4URE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URE3"
  },
  {
    "uniprot": "A0A0N4UGI2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGI2"
  },
  {
    "uniprot": "A0A0N4UA37",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA37"
  },
  {
    "uniprot": "A0A0N4UM04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM04"
  },
  {
    "uniprot": "A0A0N4UNW9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNW9"
  },
  {
    "uniprot": "A0A0N4U1A9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1A9"
  },
  {
    "uniprot": "A0A0N4U478",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U478"
  },
  {
    "uniprot": "A0A3P7SMP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SMP0"
  },
  {
    "uniprot": "A0A0N4UGC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGC0"
  },
  {
    "uniprot": "A0A0N4UDQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDQ6"
  },
  {
    "uniprot": "A0A0N4UHD9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHD9"
  },
  {
    "uniprot": "A0A158Q3P8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3P8"
  },
  {
    "uniprot": "A0A3P7PT74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PT74"
  },
  {
    "uniprot": "A0A0N4U338",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U338"
  },
  {
    "uniprot": "A0A158Q524",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q524"
  },
  {
    "uniprot": "A0A0N4U9R2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9R2"
  },
  {
    "uniprot": "A0A158Q5Z5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Z5"
  },
  {
    "uniprot": "A0A0N4U4X5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4X5"
  },
  {
    "uniprot": "A0A0N4UQR5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQR5"
  },
  {
    "uniprot": "A0A3P7Q4J4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q4J4"
  },
  {
    "uniprot": "A0A0N4UKT1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKT1"
  },
  {
    "uniprot": "A0A0N4UGT2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGT2"
  },
  {
    "uniprot": "A0A0N4UIS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIS3"
  },
  {
    "uniprot": "A0A0N4UI21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI21"
  },
  {
    "uniprot": "A0A158Q2H2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2H2"
  },
  {
    "uniprot": "A0A0N4UCL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCL9"
  },
  {
    "uniprot": "A0A0N4U1B7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1B7"
  },
  {
    "uniprot": "A0A0N4U0L5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0L5"
  },
  {
    "uniprot": "A0A0N4UG95",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG95"
  },
  {
    "uniprot": "A0A0N4UQZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQZ1"
  },
  {
    "uniprot": "A0A0N4UJ19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ19"
  },
  {
    "uniprot": "A0A0N4UDJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDJ2"
  },
  {
    "uniprot": "A0A3P7TDP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TDP4"
  },
  {
    "uniprot": "A0A0N4UM62",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM62"
  },
  {
    "uniprot": "A0A0N4UD34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD34"
  },
  {
    "uniprot": "A0A0N4UMQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMQ3"
  },
  {
    "uniprot": "A0A0N4ULB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULB0"
  },
  {
    "uniprot": "A0A0N4UMQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMQ1"
  },
  {
    "uniprot": "A0A158Q2T7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2T7"
  },
  {
    "uniprot": "A0A0N4UFM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFM8"
  },
  {
    "uniprot": "A0A0N4UC77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC77"
  },
  {
    "uniprot": "A0A0N4U7K9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7K9"
  },
  {
    "uniprot": "A0A0N4U8X8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8X8"
  },
  {
    "uniprot": "A0A0N4UH56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH56"
  },
  {
    "uniprot": "A0A0N4URX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URX9"
  },
  {
    "uniprot": "A0A0N4U0Q5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Q5"
  },
  {
    "uniprot": "A0A0N4UIN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIN9"
  },
  {
    "uniprot": "A0A0N4U9S3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9S3"
  },
  {
    "uniprot": "A0A0N4UJW5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJW5"
  },
  {
    "uniprot": "A0A0N4UC81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC81"
  },
  {
    "uniprot": "A0A0N4U4G0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4G0"
  },
  {
    "uniprot": "A0A3P7SAW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SAW3"
  },
  {
    "uniprot": "A0A0N4U6T0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6T0"
  },
  {
    "uniprot": "A0A0N4U0P7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0P7"
  },
  {
    "uniprot": "A0A0N4UNF6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNF6"
  },
  {
    "uniprot": "A0A0N4U7V6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7V6"
  },
  {
    "uniprot": "A0A158Q451",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q451"
  },
  {
    "uniprot": "A0A0N4UJ92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ92"
  },
  {
    "uniprot": "A0A0N4UMZ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMZ2"
  },
  {
    "uniprot": "A0A0N4UQU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQU6"
  },
  {
    "uniprot": "A0A0N4U852",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U852"
  },
  {
    "uniprot": "A0A0N4URP2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URP2"
  },
  {
    "uniprot": "A0A3P7PLP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PLP0"
  },
  {
    "uniprot": "A0A0N4UFL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFL8"
  },
  {
    "uniprot": "A0A0N4U5C2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5C2"
  },
  {
    "uniprot": "A0A0N4UJ16",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ16"
  },
  {
    "uniprot": "A0A3P7P9C8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P9C8"
  },
  {
    "uniprot": "A0A0N4UJN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJN2"
  },
  {
    "uniprot": "A0A0N4U0Y5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Y5"
  },
  {
    "uniprot": "A0A0N4U0P9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0P9"
  },
  {
    "uniprot": "A0A158Q646",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q646"
  },
  {
    "uniprot": "A0A0N4UN68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN68"
  },
  {
    "uniprot": "A0A3P7P4V1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P4V1"
  },
  {
    "uniprot": "A0A0N4UFR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFR1"
  },
  {
    "uniprot": "A0A0N4US21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US21"
  },
  {
    "uniprot": "A0A0N4UB70",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB70"
  },
  {
    "uniprot": "A0A3P7PPM9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPM9"
  },
  {
    "uniprot": "A0A3P7PMS2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PMS2"
  },
  {
    "uniprot": "A0A158Q4R8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4R8"
  },
  {
    "uniprot": "A0A0N4U9F4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9F4"
  },
  {
    "uniprot": "A0A3P7PLF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PLF5"
  },
  {
    "uniprot": "A0A3P7PDY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PDY7"
  },
  {
    "uniprot": "A0A0N4U7W1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7W1"
  },
  {
    "uniprot": "A0A3P7PUT0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PUT0"
  },
  {
    "uniprot": "A0A0N4UMX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMX8"
  },
  {
    "uniprot": "A0A0N4UNG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNG9"
  },
  {
    "uniprot": "A0A0N4UE58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE58"
  },
  {
    "uniprot": "A0A0N4UGU8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGU8"
  },
  {
    "uniprot": "A0A0N4UBW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBW3"
  },
  {
    "uniprot": "A0A0N4U3F7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3F7"
  },
  {
    "uniprot": "A0A0N4ULR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULR2"
  },
  {
    "uniprot": "A0A3P7PNJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PNJ4"
  },
  {
    "uniprot": "A0A158Q6B1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6B1"
  },
  {
    "uniprot": "A0A0N4U2E9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2E9"
  },
  {
    "uniprot": "A0A0N4U5B2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5B2"
  },
  {
    "uniprot": "A0A0N4UQ64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ64"
  },
  {
    "uniprot": "A0A0N4UC34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC34"
  },
  {
    "uniprot": "A0A0N4UE60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE60"
  },
  {
    "uniprot": "A0A0N4UI45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI45"
  },
  {
    "uniprot": "A0A0N4UJX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJX7"
  },
  {
    "uniprot": "A0A0N4UIM9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIM9"
  },
  {
    "uniprot": "A0A0N4U612",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U612"
  },
  {
    "uniprot": "A0A0N4U2Y2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Y2"
  },
  {
    "uniprot": "A0A0N4U7X9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7X9"
  },
  {
    "uniprot": "A0A0N4UR12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR12"
  },
  {
    "uniprot": "A0A0N4ULZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULZ4"
  },
  {
    "uniprot": "A0A0N4UNS8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNS8"
  },
  {
    "uniprot": "A0A0N4UKU9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKU9"
  },
  {
    "uniprot": "A0A0N4U2Z2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Z2"
  },
  {
    "uniprot": "A0A0N4U0I3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0I3"
  },
  {
    "uniprot": "A0A0N4UAZ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAZ3"
  },
  {
    "uniprot": "A0A0N4U4E3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4E3"
  },
  {
    "uniprot": "A0A158Q3E5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3E5"
  },
  {
    "uniprot": "A0A0N4UJ37",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ37"
  },
  {
    "uniprot": "A0A0N4UJA7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJA7"
  },
  {
    "uniprot": "A0A0N4UCI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCI9"
  },
  {
    "uniprot": "A0A0N4UG51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG51"
  },
  {
    "uniprot": "A0A0N4U4R9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4R9"
  },
  {
    "uniprot": "A0A0N4U936",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U936"
  },
  {
    "uniprot": "A0A0N4U6L3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6L3"
  },
  {
    "uniprot": "A0A158Q2N3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2N3"
  },
  {
    "uniprot": "A0A3P7SKR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SKR1"
  },
  {
    "uniprot": "A0A0N4UL63",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL63"
  },
  {
    "uniprot": "A0A0N4U594",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U594"
  },
  {
    "uniprot": "A0A158Q4Z9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Z9"
  },
  {
    "uniprot": "A0A0N4U3M4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3M4"
  },
  {
    "uniprot": "A0A0N4ULB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULB7"
  },
  {
    "uniprot": "A0A0N4UKX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKX9"
  },
  {
    "uniprot": "A0A0N4U602",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U602"
  },
  {
    "uniprot": "A0A158Q665",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q665"
  },
  {
    "uniprot": "A0A0N4U0P1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0P1"
  },
  {
    "uniprot": "A0A0N4U8E7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8E7"
  },
  {
    "uniprot": "A0A0N4UHS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHS3"
  },
  {
    "uniprot": "A0A158Q4G6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4G6"
  },
  {
    "uniprot": "A0A0N4UJD5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJD5"
  },
  {
    "uniprot": "A0A0N4UGA7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGA7"
  },
  {
    "uniprot": "A0A0N4UHG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHG6"
  },
  {
    "uniprot": "A0A158Q2N1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2N1"
  },
  {
    "uniprot": "A0A0N4UQ30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ30"
  },
  {
    "uniprot": "A0A3P7PWP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PWP1"
  },
  {
    "uniprot": "A0A0N4UCB8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCB8"
  },
  {
    "uniprot": "A0A0N4UN89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN89"
  },
  {
    "uniprot": "A0A0N4U7K8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7K8"
  },
  {
    "uniprot": "A0A0N4UNP8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNP8"
  },
  {
    "uniprot": "A0A0N4UH02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH02"
  },
  {
    "uniprot": "A0A0N4UAY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAY7"
  },
  {
    "uniprot": "A0A0N4UGQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGQ3"
  },
  {
    "uniprot": "A0A0N4UF49",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF49"
  },
  {
    "uniprot": "A0A0N4UP32",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP32"
  },
  {
    "uniprot": "A0A0N4UIS8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIS8"
  },
  {
    "uniprot": "A0A0N4UBZ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBZ0"
  },
  {
    "uniprot": "A0A0N4UJG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJG6"
  },
  {
    "uniprot": "A0A0N4UBT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBT6"
  },
  {
    "uniprot": "A0A0N4U422",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U422"
  },
  {
    "uniprot": "A0A0N4UKN4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKN4"
  },
  {
    "uniprot": "A0A158Q2L3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2L3"
  },
  {
    "uniprot": "A0A0N4U0T7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0T7"
  },
  {
    "uniprot": "A0A0N4UKL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKL9"
  },
  {
    "uniprot": "A0A0N4UED8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UED8"
  },
  {
    "uniprot": "A0A3P7QA18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QA18"
  },
  {
    "uniprot": "A0A0N4U0S6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0S6"
  },
  {
    "uniprot": "A0A0N4U9K7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9K7"
  },
  {
    "uniprot": "A0A0N4U1S1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1S1"
  },
  {
    "uniprot": "A0A0N4UPM7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPM7"
  },
  {
    "uniprot": "A0A0N4UHC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHC1"
  },
  {
    "uniprot": "A0A0N4U5F1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5F1"
  },
  {
    "uniprot": "A0A0N4U5S2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5S2"
  },
  {
    "uniprot": "A0A0N4UGX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGX8"
  },
  {
    "uniprot": "A0A0N4UQ47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ47"
  },
  {
    "uniprot": "A0A0N4UB83",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB83"
  },
  {
    "uniprot": "A0A0N4UP42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP42"
  },
  {
    "uniprot": "A0A0N4UM69",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM69"
  },
  {
    "uniprot": "A0A0N4UR14",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR14"
  },
  {
    "uniprot": "A0A0N4U7V7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7V7"
  },
  {
    "uniprot": "A0A0N4UJH8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJH8"
  },
  {
    "uniprot": "A0A0N4UII9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UII9"
  },
  {
    "uniprot": "A0A0N4UGW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGW0"
  },
  {
    "uniprot": "A0A0N4UQN8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQN8"
  },
  {
    "uniprot": "A0A0N4U5R6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5R6"
  },
  {
    "uniprot": "A0A0N4UD93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD93"
  },
  {
    "uniprot": "A0A3P7ST11",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7ST11"
  },
  {
    "uniprot": "A0A158Q2Q4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Q4"
  },
  {
    "uniprot": "A0A0N4UFJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFJ9"
  },
  {
    "uniprot": "A0A0N4U4B5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4B5"
  },
  {
    "uniprot": "A0A0N4UL90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL90"
  },
  {
    "uniprot": "A0A0N4U5I6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5I6"
  },
  {
    "uniprot": "A0A0N4URW7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URW7"
  },
  {
    "uniprot": "A0A0N4UB42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB42"
  },
  {
    "uniprot": "A0A0N4UQL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQL0"
  },
  {
    "uniprot": "A0A0N4UNY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNY2"
  },
  {
    "uniprot": "A0A158Q6C2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6C2"
  },
  {
    "uniprot": "A0A0N4URM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URM0"
  },
  {
    "uniprot": "A0A0N4U155",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U155"
  },
  {
    "uniprot": "A0A0N4UMX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMX5"
  },
  {
    "uniprot": "A0A0N4UBT5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBT5"
  },
  {
    "uniprot": "A0A0N4UPY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPY2"
  },
  {
    "uniprot": "A0A158Q2X3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2X3"
  },
  {
    "uniprot": "A0A0N4UJ36",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ36"
  },
  {
    "uniprot": "A0A0N4U3M8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3M8"
  },
  {
    "uniprot": "A0A0N4UAK8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAK8"
  },
  {
    "uniprot": "A0A3P7QFB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QFB1"
  },
  {
    "uniprot": "A0A0N4UH73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH73"
  },
  {
    "uniprot": "A0A158Q6L1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6L1"
  },
  {
    "uniprot": "A0A3P7QT52",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QT52"
  },
  {
    "uniprot": "A0A0N4U713",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U713"
  },
  {
    "uniprot": "A0A0N4U0S5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0S5"
  },
  {
    "uniprot": "A0A0N4UP06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP06"
  },
  {
    "uniprot": "A0A0N4UHA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHA5"
  },
  {
    "uniprot": "A0A0N4U0K3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0K3"
  },
  {
    "uniprot": "A0A0N4U2Y4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Y4"
  },
  {
    "uniprot": "A0A0N4UFQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFQ6"
  },
  {
    "uniprot": "A0A0N4ULD4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULD4"
  },
  {
    "uniprot": "A0A0N4UCL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCL8"
  },
  {
    "uniprot": "A0A158Q6G3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6G3"
  },
  {
    "uniprot": "A0A0N4U0Y4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Y4"
  },
  {
    "uniprot": "A0A0N4UNP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNP4"
  },
  {
    "uniprot": "A0A0N4UKH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKH1"
  },
  {
    "uniprot": "A0A0N4UH75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH75"
  },
  {
    "uniprot": "A0A0N4UH93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH93"
  },
  {
    "uniprot": "A0A0N4UN87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN87"
  },
  {
    "uniprot": "A0A0N4U6C5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6C5"
  },
  {
    "uniprot": "A0A0N4UKX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKX1"
  },
  {
    "uniprot": "A0A0N4UN59",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN59"
  },
  {
    "uniprot": "A0A158Q4K2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4K2"
  },
  {
    "uniprot": "A0A0N4UH87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH87"
  },
  {
    "uniprot": "A0A0N4UE09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE09"
  },
  {
    "uniprot": "A0A0N4UQ69",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ69"
  },
  {
    "uniprot": "A0A0N4U7S9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7S9"
  },
  {
    "uniprot": "A0A0N4UJI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJI3"
  },
  {
    "uniprot": "A0A0N4U3M9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3M9"
  },
  {
    "uniprot": "A0A0N4UE82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE82"
  },
  {
    "uniprot": "A0A0N4UJD6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJD6"
  },
  {
    "uniprot": "A0A0N4UQP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQP7"
  },
  {
    "uniprot": "A0A0N4UBJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBJ9"
  },
  {
    "uniprot": "A0A0N4U6Q4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Q4"
  },
  {
    "uniprot": "A0A0N4UES2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UES2"
  },
  {
    "uniprot": "A0A0N4UJ13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ13"
  },
  {
    "uniprot": "A0A0N4U8U5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8U5"
  },
  {
    "uniprot": "A0A0N4U1U8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1U8"
  },
  {
    "uniprot": "A0A3P7T930",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T930"
  },
  {
    "uniprot": "A0A0N4U1S0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1S0"
  },
  {
    "uniprot": "A0A158Q2M9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2M9"
  },
  {
    "uniprot": "A0A0N4U524",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U524"
  },
  {
    "uniprot": "A0A0N4UAW5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAW5"
  },
  {
    "uniprot": "A0A3P7QMH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QMH9"
  },
  {
    "uniprot": "A0A0N4U3V1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3V1"
  },
  {
    "uniprot": "A0A0N4UEN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEN6"
  },
  {
    "uniprot": "A0A0N4UQ18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ18"
  },
  {
    "uniprot": "A0A0N4UNJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNJ1"
  },
  {
    "uniprot": "A0A158Q5X4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5X4"
  },
  {
    "uniprot": "A0A158Q5J9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5J9"
  },
  {
    "uniprot": "A0A0N4UGJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGJ2"
  },
  {
    "uniprot": "A0A0N4UG25",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG25"
  },
  {
    "uniprot": "A0A0N4UPQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPQ5"
  },
  {
    "uniprot": "A0A0N4UCX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCX8"
  },
  {
    "uniprot": "A0A0N4U7G2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7G2"
  },
  {
    "uniprot": "A0A0N4UMJ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMJ5"
  },
  {
    "uniprot": "A0A0N4U908",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U908"
  },
  {
    "uniprot": "A0A158Q489",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q489"
  },
  {
    "uniprot": "A0A0N4UP97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP97"
  },
  {
    "uniprot": "A0A3P7PG48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PG48"
  },
  {
    "uniprot": "A0A0N4U7K5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7K5"
  },
  {
    "uniprot": "A0A0N4UPK9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPK9"
  },
  {
    "uniprot": "A0A0N4U9C4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9C4"
  },
  {
    "uniprot": "A0A0N4UEM3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEM3"
  },
  {
    "uniprot": "A0A0N4UHX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHX5"
  },
  {
    "uniprot": "A0A0N4U8Y5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Y5"
  },
  {
    "uniprot": "A0A3P7SK93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SK93"
  },
  {
    "uniprot": "A0A0N4UKJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKJ1"
  },
  {
    "uniprot": "A0A0N4U2J1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2J1"
  },
  {
    "uniprot": "A0A0N4U172",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U172"
  },
  {
    "uniprot": "A0A3P7PTM6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PTM6"
  },
  {
    "uniprot": "A0A0N4U6Q0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Q0"
  },
  {
    "uniprot": "A0A0N4UFQ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFQ0"
  },
  {
    "uniprot": "A0A0N4UE34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE34"
  },
  {
    "uniprot": "A0A158Q5P9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5P9"
  },
  {
    "uniprot": "A0A0N4UDJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDJ4"
  },
  {
    "uniprot": "A0A0N4UEY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEY0"
  },
  {
    "uniprot": "A0A0N4U270",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U270"
  },
  {
    "uniprot": "A0A0N4UPU1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPU1"
  },
  {
    "uniprot": "A0A0N4UAB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAB9"
  },
  {
    "uniprot": "A0A0N4U1D2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1D2"
  },
  {
    "uniprot": "A0A0N4UR38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR38"
  },
  {
    "uniprot": "A0A0N4U9W7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9W7"
  },
  {
    "uniprot": "A0A0N4UL44",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL44"
  },
  {
    "uniprot": "A0A0N4UME4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UME4"
  },
  {
    "uniprot": "A0A0N4UMN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMN3"
  },
  {
    "uniprot": "A0A0N4UJR3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJR3"
  },
  {
    "uniprot": "A0A3P7PQS1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQS1"
  },
  {
    "uniprot": "A0A0N4UKK8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKK8"
  },
  {
    "uniprot": "A0A0N4UF28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF28"
  },
  {
    "uniprot": "A0A3P7SK55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SK55"
  },
  {
    "uniprot": "A0A0N4UG34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG34"
  },
  {
    "uniprot": "A0A0N4U9C2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9C2"
  },
  {
    "uniprot": "A0A0N4UBF1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBF1"
  },
  {
    "uniprot": "A0A158Q6L7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6L7"
  },
  {
    "uniprot": "A0A158Q3X9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3X9"
  },
  {
    "uniprot": "A0A0N4U1W0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1W0"
  },
  {
    "uniprot": "A0A158Q6K1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6K1"
  },
  {
    "uniprot": "A0A158Q3W2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3W2"
  },
  {
    "uniprot": "A0A3P7QZQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QZQ6"
  },
  {
    "uniprot": "A0A3P7SAK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SAK6"
  },
  {
    "uniprot": "A0A0N4UN37",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN37"
  },
  {
    "uniprot": "A0A158Q673",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q673"
  },
  {
    "uniprot": "A0A0N4U7Q4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Q4"
  },
  {
    "uniprot": "A0A0N4ULQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULQ3"
  },
  {
    "uniprot": "A0A0N4UHP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHP3"
  },
  {
    "uniprot": "A0A3P7QAF8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QAF8"
  },
  {
    "uniprot": "A0A0N4U4P3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4P3"
  },
  {
    "uniprot": "A0A0N4ULW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULW0"
  },
  {
    "uniprot": "A0A0N4UN31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN31"
  },
  {
    "uniprot": "A0A158Q4M8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4M8"
  },
  {
    "uniprot": "A0A0N4U2U8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2U8"
  },
  {
    "uniprot": "A0A0N4UAR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAR6"
  },
  {
    "uniprot": "A0A0N4UPP8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPP8"
  },
  {
    "uniprot": "A0A158Q2Y1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Y1"
  },
  {
    "uniprot": "A0A0N4U0J1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0J1"
  },
  {
    "uniprot": "A0A0N4UMF1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMF1"
  },
  {
    "uniprot": "A0A0N4U5Q8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Q8"
  },
  {
    "uniprot": "A0A0N4UGH8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGH8"
  },
  {
    "uniprot": "A0A0N4UIB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIB0"
  },
  {
    "uniprot": "A0A158Q3N1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3N1"
  },
  {
    "uniprot": "A0A0N4U8F6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8F6"
  },
  {
    "uniprot": "A0A0N4U144",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U144"
  },
  {
    "uniprot": "A0A3P7PT69",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PT69"
  },
  {
    "uniprot": "A0A0N4UCH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCH1"
  },
  {
    "uniprot": "A0A0N4U7N0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7N0"
  },
  {
    "uniprot": "A0A0N4U850",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U850"
  },
  {
    "uniprot": "A0A0N4UI29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI29"
  },
  {
    "uniprot": "A0A0N4U1N6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1N6"
  },
  {
    "uniprot": "A0A3P7TCE2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TCE2"
  },
  {
    "uniprot": "A0A0N4UGF2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGF2"
  },
  {
    "uniprot": "A0A0N4U9H2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9H2"
  },
  {
    "uniprot": "A0A0N4UFI7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFI7"
  },
  {
    "uniprot": "A0A3P7Q1K2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q1K2"
  },
  {
    "uniprot": "A0A0N4URI4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URI4"
  },
  {
    "uniprot": "A0A0N4U656",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U656"
  },
  {
    "uniprot": "A0A0N4UPI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPI9"
  },
  {
    "uniprot": "A0A0N4UHY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHY4"
  },
  {
    "uniprot": "A0A158Q2K5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2K5"
  },
  {
    "uniprot": "A0A0N4U241",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U241"
  },
  {
    "uniprot": "A0A0N4UIE1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIE1"
  },
  {
    "uniprot": "A0A0N4UQ12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ12"
  },
  {
    "uniprot": "A0A0N4U872",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U872"
  },
  {
    "uniprot": "A0A158Q3C3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3C3"
  },
  {
    "uniprot": "A0A0N4U6Y7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Y7"
  },
  {
    "uniprot": "A0A158Q3Q7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Q7"
  },
  {
    "uniprot": "A0A0N4UPC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPC6"
  },
  {
    "uniprot": "A0A0N4UAZ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAZ9"
  },
  {
    "uniprot": "A0A0N4UQA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQA6"
  },
  {
    "uniprot": "A0A0N4UN27",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN27"
  },
  {
    "uniprot": "A0A3P7TCK8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TCK8"
  },
  {
    "uniprot": "A0A0N4UAT5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAT5"
  },
  {
    "uniprot": "A0A0N4U8T1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8T1"
  },
  {
    "uniprot": "A0A0N4UBI2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBI2"
  },
  {
    "uniprot": "A0A0N4UDG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDG9"
  },
  {
    "uniprot": "A0A0N4UCL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCL3"
  },
  {
    "uniprot": "A0A0N4UBY8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBY8"
  },
  {
    "uniprot": "A0A0N4UKQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKQ7"
  },
  {
    "uniprot": "A0A0N4UIJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIJ7"
  },
  {
    "uniprot": "A0A0N4U7P7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7P7"
  },
  {
    "uniprot": "A0A0N4UNT5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNT5"
  },
  {
    "uniprot": "A0A158Q6B6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6B6"
  },
  {
    "uniprot": "A0A158Q5Z6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Z6"
  },
  {
    "uniprot": "A0A0N4UE88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE88"
  },
  {
    "uniprot": "A0A3P7PX97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PX97"
  },
  {
    "uniprot": "A0A0N4UPF1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPF1"
  },
  {
    "uniprot": "A0A0N4UGV0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGV0"
  },
  {
    "uniprot": "A0A0N4U0I4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0I4"
  },
  {
    "uniprot": "A0A0N4UIM7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIM7"
  },
  {
    "uniprot": "A0A0N4UH32",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH32"
  },
  {
    "uniprot": "A0A0N4UI24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI24"
  },
  {
    "uniprot": "A0A0N4UQZ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQZ7"
  },
  {
    "uniprot": "A0A0N4UJS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJS3"
  },
  {
    "uniprot": "A0A0N4UHW9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHW9"
  },
  {
    "uniprot": "A0A0N4UAF0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAF0"
  },
  {
    "uniprot": "A0A0N4UE27",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE27"
  },
  {
    "uniprot": "A0A3P7SM39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SM39"
  },
  {
    "uniprot": "A0A158Q5H4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5H4"
  },
  {
    "uniprot": "A0A0N4UEJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEJ7"
  },
  {
    "uniprot": "A0A0N4UAI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAI3"
  },
  {
    "uniprot": "A0A0N4UBR8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBR8"
  },
  {
    "uniprot": "A0A0N4UBA7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBA7"
  },
  {
    "uniprot": "A0A0N4UGF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGF9"
  },
  {
    "uniprot": "A0A0N4U6E5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6E5"
  },
  {
    "uniprot": "A0A0N4UJ51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ51"
  },
  {
    "uniprot": "A0A0N4U0K7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0K7"
  },
  {
    "uniprot": "A0A0N4US94",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US94"
  },
  {
    "uniprot": "A0A0N4U7E7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7E7"
  },
  {
    "uniprot": "A0A0N4U8P1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8P1"
  },
  {
    "uniprot": "A0A3P7Q6N0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q6N0"
  },
  {
    "uniprot": "A0A0N4UDV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDV8"
  },
  {
    "uniprot": "A0A3P7PCE9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PCE9"
  },
  {
    "uniprot": "A0A3P7PZ71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PZ71"
  },
  {
    "uniprot": "A0A0N4U2I0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2I0"
  },
  {
    "uniprot": "A0A158Q557",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q557"
  },
  {
    "uniprot": "A0A0N4UM97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM97"
  },
  {
    "uniprot": "A0A0N4U5X3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5X3"
  },
  {
    "uniprot": "A0A0N4U458",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U458"
  },
  {
    "uniprot": "A0A158Q2Z5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Z5"
  },
  {
    "uniprot": "A0A158Q319",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q319"
  },
  {
    "uniprot": "A0A0N4U1V9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1V9"
  },
  {
    "uniprot": "A0A0N4UEZ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEZ9"
  },
  {
    "uniprot": "A0A158Q415",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q415"
  },
  {
    "uniprot": "A0A3P7QA92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QA92"
  },
  {
    "uniprot": "A0A3P7S8L2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S8L2"
  },
  {
    "uniprot": "A0A0N4U381",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U381"
  },
  {
    "uniprot": "A0A0N4UBS7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBS7"
  },
  {
    "uniprot": "A0A0N4UHY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHY7"
  },
  {
    "uniprot": "A0A0N4UB93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB93"
  },
  {
    "uniprot": "A0A0N4UQM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQM0"
  },
  {
    "uniprot": "A0A0N4URA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URA0"
  },
  {
    "uniprot": "A0A0N4U0U8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0U8"
  },
  {
    "uniprot": "A0A3P7PUU1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PUU1"
  },
  {
    "uniprot": "A0A0N4U4F6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4F6"
  },
  {
    "uniprot": "A0A0N4UN77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN77"
  },
  {
    "uniprot": "A0A0N4UHD8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHD8"
  },
  {
    "uniprot": "A0A0N4U9P7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9P7"
  },
  {
    "uniprot": "A0A0N4UJV2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJV2"
  },
  {
    "uniprot": "A0A0N4UJ64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ64"
  },
  {
    "uniprot": "A0A0N4U5N5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5N5"
  },
  {
    "uniprot": "A0A158Q4F4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4F4"
  },
  {
    "uniprot": "A0A0N4U2A0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2A0"
  },
  {
    "uniprot": "A0A0N4UMZ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMZ3"
  },
  {
    "uniprot": "A0A0N4U6Q3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Q3"
  },
  {
    "uniprot": "A0A0N4UDR0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDR0"
  },
  {
    "uniprot": "A0A0N4UIA3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIA3"
  },
  {
    "uniprot": "A0A0N4UCU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCU4"
  },
  {
    "uniprot": "A0A0N4UFF4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFF4"
  },
  {
    "uniprot": "A0A0N4UGG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGG9"
  },
  {
    "uniprot": "A0A0N4UGM5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGM5"
  },
  {
    "uniprot": "A0A0N4UF41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF41"
  },
  {
    "uniprot": "A0A0N4U588",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U588"
  },
  {
    "uniprot": "A0A0N4UQV1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQV1"
  },
  {
    "uniprot": "A0A0N4UFQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFQ1"
  },
  {
    "uniprot": "A0A0N4URA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URA1"
  },
  {
    "uniprot": "A0A158Q3K8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3K8"
  },
  {
    "uniprot": "A0A0N4U0X3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0X3"
  },
  {
    "uniprot": "A0A0N4UD43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD43"
  },
  {
    "uniprot": "A0A0N4UJP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJP3"
  },
  {
    "uniprot": "A0A0N4UNL5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNL5"
  },
  {
    "uniprot": "A0A0N4U784",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U784"
  },
  {
    "uniprot": "A0A0N4U2J9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2J9"
  },
  {
    "uniprot": "A0A0N4UM95",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM95"
  },
  {
    "uniprot": "A0A0N4UL81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL81"
  },
  {
    "uniprot": "A0A0N4UL34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL34"
  },
  {
    "uniprot": "A0A0N4UH19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH19"
  },
  {
    "uniprot": "A0A0N4UNX4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNX4"
  },
  {
    "uniprot": "A0A0N4U1C8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1C8"
  },
  {
    "uniprot": "A0A0N4U5Q9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Q9"
  },
  {
    "uniprot": "A0A0N4UM82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM82"
  },
  {
    "uniprot": "A0A0N4U9B6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9B6"
  },
  {
    "uniprot": "A0A0N4UNA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNA2"
  },
  {
    "uniprot": "A0A0N4UP62",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP62"
  },
  {
    "uniprot": "A0A0N4U9G4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9G4"
  },
  {
    "uniprot": "A0A0N4U8F9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8F9"
  },
  {
    "uniprot": "A0A0N4UDK8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDK8"
  },
  {
    "uniprot": "A0A0N4UGI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGI9"
  },
  {
    "uniprot": "A0A0N4UPF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPF3"
  },
  {
    "uniprot": "A0A0N4UK54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK54"
  },
  {
    "uniprot": "A0A0N4UAU1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAU1"
  },
  {
    "uniprot": "A0A0N4UN50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN50"
  },
  {
    "uniprot": "A0A158Q4E7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4E7"
  },
  {
    "uniprot": "A0A0N4U0W2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0W2"
  },
  {
    "uniprot": "A0A0N4UNF4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNF4"
  },
  {
    "uniprot": "A0A0N4U816",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U816"
  },
  {
    "uniprot": "A0A0N4URX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URX1"
  },
  {
    "uniprot": "A0A0N4UKW5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKW5"
  },
  {
    "uniprot": "A0A158Q5P0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5P0"
  },
  {
    "uniprot": "A0A158Q5U3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5U3"
  },
  {
    "uniprot": "A0A0N4U3U4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3U4"
  },
  {
    "uniprot": "A0A0N4UDF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDF9"
  },
  {
    "uniprot": "A0A0N4U8L8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8L8"
  },
  {
    "uniprot": "A0A0N4UIT9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIT9"
  },
  {
    "uniprot": "A0A0N4UCJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCJ4"
  },
  {
    "uniprot": "A0A0N4UNB8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNB8"
  },
  {
    "uniprot": "A0A0N4UE56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE56"
  },
  {
    "uniprot": "A0A0N4UMF2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMF2"
  },
  {
    "uniprot": "A0A0N4UG26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG26"
  },
  {
    "uniprot": "A0A0N4U9P9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9P9"
  },
  {
    "uniprot": "A0A3P7PTT7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PTT7"
  },
  {
    "uniprot": "A0A0N4U2A6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2A6"
  },
  {
    "uniprot": "A0A0N4UMP2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMP2"
  },
  {
    "uniprot": "A0A0N4U1I5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1I5"
  },
  {
    "uniprot": "A0A0N4UEE0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEE0"
  },
  {
    "uniprot": "A0A158Q5D9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5D9"
  },
  {
    "uniprot": "A0A0N4URM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URM2"
  },
  {
    "uniprot": "A0A158Q473",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q473"
  },
  {
    "uniprot": "A0A0N4UEZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEZ1"
  },
  {
    "uniprot": "A0A0N4UCE8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCE8"
  },
  {
    "uniprot": "A0A0N4UID2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UID2"
  },
  {
    "uniprot": "A0A0N4UAR0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAR0"
  },
  {
    "uniprot": "A0A0N4UM19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM19"
  },
  {
    "uniprot": "A0A0N4U1W4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1W4"
  },
  {
    "uniprot": "A0A0N4U4U7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4U7"
  },
  {
    "uniprot": "A0A0N4UJ98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ98"
  },
  {
    "uniprot": "A0A0N4UNI4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNI4"
  },
  {
    "uniprot": "A0A0N4U0C7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0C7"
  },
  {
    "uniprot": "A0A0N4UPL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPL9"
  },
  {
    "uniprot": "A0A158Q2L8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2L8"
  },
  {
    "uniprot": "A0A0N4UKQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKQ5"
  },
  {
    "uniprot": "A0A158Q4V0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4V0"
  },
  {
    "uniprot": "A0A0N4U6Y3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Y3"
  },
  {
    "uniprot": "A0A0N4UK36",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK36"
  },
  {
    "uniprot": "A0A0N4UJS6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJS6"
  },
  {
    "uniprot": "A0A3P7Q8V9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q8V9"
  },
  {
    "uniprot": "A0A0N4U3K1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3K1"
  },
  {
    "uniprot": "A0A0N4U6W2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6W2"
  },
  {
    "uniprot": "A0A0N4UP11",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP11"
  },
  {
    "uniprot": "A0A3P7SQR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SQR6"
  },
  {
    "uniprot": "A0A0N4U5N4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5N4"
  },
  {
    "uniprot": "A0A0N4U7K2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7K2"
  },
  {
    "uniprot": "A0A158Q3X7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3X7"
  },
  {
    "uniprot": "A0A0N4U593",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U593"
  },
  {
    "uniprot": "A0A0N4UG43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG43"
  },
  {
    "uniprot": "A0A0N4U972",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U972"
  },
  {
    "uniprot": "A0A0N4UJI5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJI5"
  },
  {
    "uniprot": "A0A158Q354",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q354"
  },
  {
    "uniprot": "A0A158Q6F4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6F4"
  },
  {
    "uniprot": "A0A0N4UGS5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGS5"
  },
  {
    "uniprot": "A0A0N4URK4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URK4"
  },
  {
    "uniprot": "A0A0N4UBK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBK1"
  },
  {
    "uniprot": "A0A0N4U2B7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2B7"
  },
  {
    "uniprot": "A0A158Q4F2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4F2"
  },
  {
    "uniprot": "A0A0N4U261",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U261"
  },
  {
    "uniprot": "A0A0N4U3B7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3B7"
  },
  {
    "uniprot": "A0A0N4U0V4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0V4"
  },
  {
    "uniprot": "A0A0N4UCY3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCY3"
  },
  {
    "uniprot": "A0A0N4UAK4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAK4"
  },
  {
    "uniprot": "A0A0N4UN26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN26"
  },
  {
    "uniprot": "A0A0N4UN28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN28"
  },
  {
    "uniprot": "A0A3P7QC68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QC68"
  },
  {
    "uniprot": "A0A158Q5T5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5T5"
  },
  {
    "uniprot": "A0A0N4UEF1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEF1"
  },
  {
    "uniprot": "A0A0N4UDR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDR4"
  },
  {
    "uniprot": "A0A0N4UE14",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE14"
  },
  {
    "uniprot": "A0A0N4ULE5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULE5"
  },
  {
    "uniprot": "A0A0N4U360",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U360"
  },
  {
    "uniprot": "A0A0N4UHS2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHS2"
  },
  {
    "uniprot": "A0A158Q4M9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4M9"
  },
  {
    "uniprot": "A0A0N4UMW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMW3"
  },
  {
    "uniprot": "A0A0N4UEZ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEZ2"
  },
  {
    "uniprot": "A0A0N4UDL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDL0"
  },
  {
    "uniprot": "A0A0N4UCW9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCW9"
  },
  {
    "uniprot": "A0A0N4U0V3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0V3"
  },
  {
    "uniprot": "A0A0N4URA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URA2"
  },
  {
    "uniprot": "A0A0N4U465",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U465"
  },
  {
    "uniprot": "A0A3P7Q9D6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q9D6"
  },
  {
    "uniprot": "A0A0N4UP14",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP14"
  },
  {
    "uniprot": "A0A0N4U7H1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7H1"
  },
  {
    "uniprot": "A0A0N4UE31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE31"
  },
  {
    "uniprot": "A0A0N4UIW5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIW5"
  },
  {
    "uniprot": "A0A158Q3M3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3M3"
  },
  {
    "uniprot": "A0A0N4URG1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URG1"
  },
  {
    "uniprot": "A0A0N4U255",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U255"
  },
  {
    "uniprot": "A0A0N4U6T1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6T1"
  },
  {
    "uniprot": "A0A0N4UN58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN58"
  },
  {
    "uniprot": "A0A0N4UMC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMC7"
  },
  {
    "uniprot": "A0A0N4U2H6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2H6"
  },
  {
    "uniprot": "A0A158Q334",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q334"
  },
  {
    "uniprot": "A0A0N4U2W2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2W2"
  },
  {
    "uniprot": "A0A158Q4Q7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Q7"
  },
  {
    "uniprot": "A0A0N4U6U4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6U4"
  },
  {
    "uniprot": "A0A0N4UFT1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFT1"
  },
  {
    "uniprot": "A0A0N4UGF0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGF0"
  },
  {
    "uniprot": "A0A0N4U1L2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1L2"
  },
  {
    "uniprot": "A0A0N4UB79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB79"
  },
  {
    "uniprot": "A0A0N4UQB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQB7"
  },
  {
    "uniprot": "A0A0N4UMW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMW8"
  },
  {
    "uniprot": "A0A0N4UM85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM85"
  },
  {
    "uniprot": "A0A0N4UB66",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB66"
  },
  {
    "uniprot": "A0A158Q328",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q328"
  },
  {
    "uniprot": "A0A3P7QAP8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QAP8"
  },
  {
    "uniprot": "A0A3P7PKE8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PKE8"
  },
  {
    "uniprot": "A0A0N4UKM7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKM7"
  },
  {
    "uniprot": "A0A0N4UP34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP34"
  },
  {
    "uniprot": "A0A0N4U2Q1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Q1"
  },
  {
    "uniprot": "A0A0N4US98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US98"
  },
  {
    "uniprot": "A0A3P7TFQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TFQ1"
  },
  {
    "uniprot": "A0A0N4UE04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE04"
  },
  {
    "uniprot": "A0A0N4UB56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB56"
  },
  {
    "uniprot": "A0A0N4UQ49",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ49"
  },
  {
    "uniprot": "A0A0N4UGU5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGU5"
  },
  {
    "uniprot": "A0A0N4UHQ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHQ9"
  },
  {
    "uniprot": "A0A0N4UR11",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR11"
  },
  {
    "uniprot": "A0A0N4U5Z3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Z3"
  },
  {
    "uniprot": "A0A0N4U3Q3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Q3"
  },
  {
    "uniprot": "A0A0N4URD0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URD0"
  },
  {
    "uniprot": "A0A0N4UKQ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKQ9"
  },
  {
    "uniprot": "A0A0N4UQU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQU7"
  },
  {
    "uniprot": "A0A0N4U691",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U691"
  },
  {
    "uniprot": "A0A158Q6D6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6D6"
  },
  {
    "uniprot": "A0A0N4UI28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI28"
  },
  {
    "uniprot": "A0A0N4UQ63",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ63"
  },
  {
    "uniprot": "A0A0N4U8I8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8I8"
  },
  {
    "uniprot": "A0A0N4URV1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URV1"
  },
  {
    "uniprot": "A0A0N4UQF0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQF0"
  },
  {
    "uniprot": "A0A0N4URZ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URZ8"
  },
  {
    "uniprot": "A0A0N4U2P3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2P3"
  },
  {
    "uniprot": "A0A158Q570",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q570"
  },
  {
    "uniprot": "A0A0N4U522",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U522"
  },
  {
    "uniprot": "A0A0N4UFT0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFT0"
  },
  {
    "uniprot": "A0A0N4UCY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCY7"
  },
  {
    "uniprot": "A0A0N4UM78",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM78"
  },
  {
    "uniprot": "A0A0N4U7Q3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Q3"
  },
  {
    "uniprot": "A0A0N4UPH5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPH5"
  },
  {
    "uniprot": "A0A0N4U7X1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7X1"
  },
  {
    "uniprot": "A0A0N4U5K1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5K1"
  },
  {
    "uniprot": "A0A0N4U977",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U977"
  },
  {
    "uniprot": "A0A0N4U1G0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1G0"
  },
  {
    "uniprot": "A0A158Q5W2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5W2"
  },
  {
    "uniprot": "A0A0N4U315",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U315"
  },
  {
    "uniprot": "A0A0N4U6Z5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Z5"
  },
  {
    "uniprot": "A0A3P7SRV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SRV3"
  },
  {
    "uniprot": "A0A0N4UDH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDH4"
  },
  {
    "uniprot": "A0A0N4UJF8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJF8"
  },
  {
    "uniprot": "A0A0N4UN61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN61"
  },
  {
    "uniprot": "A0A0N4UR36",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR36"
  },
  {
    "uniprot": "A0A158Q6A8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6A8"
  },
  {
    "uniprot": "A0A0N4UI05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI05"
  },
  {
    "uniprot": "A0A158Q5A3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5A3"
  },
  {
    "uniprot": "A0A0N4U746",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U746"
  },
  {
    "uniprot": "A0A0N4UEL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEL9"
  },
  {
    "uniprot": "A0A0N4U0U1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0U1"
  },
  {
    "uniprot": "A0A158Q464",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q464"
  },
  {
    "uniprot": "A0A0N4ULP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULP3"
  },
  {
    "uniprot": "A0A158Q5R9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5R9"
  },
  {
    "uniprot": "A0A0N4U5E4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5E4"
  },
  {
    "uniprot": "A0A3P7QLQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QLQ3"
  },
  {
    "uniprot": "A0A3P7Q7W0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q7W0"
  },
  {
    "uniprot": "A0A0N4U2W0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2W0"
  },
  {
    "uniprot": "A0A0N4U5P8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5P8"
  },
  {
    "uniprot": "A0A3P7SZY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SZY7"
  },
  {
    "uniprot": "A0A3P7PZ88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PZ88"
  },
  {
    "uniprot": "A0A3P7SY45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SY45"
  },
  {
    "uniprot": "A0A0N4U591",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U591"
  },
  {
    "uniprot": "A0A0N4UKN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKN9"
  },
  {
    "uniprot": "A0A0N4URU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URU2"
  },
  {
    "uniprot": "A0A0N4U0Y6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Y6"
  },
  {
    "uniprot": "A0A0N4U9J6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9J6"
  },
  {
    "uniprot": "A0A0N4U460",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U460"
  },
  {
    "uniprot": "A0A3P7Q0C9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q0C9"
  },
  {
    "uniprot": "A0A0N4UCI4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCI4"
  },
  {
    "uniprot": "A0A0N4U230",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U230"
  },
  {
    "uniprot": "A0A0N4U9M6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9M6"
  },
  {
    "uniprot": "A0A0N4ULP5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULP5"
  },
  {
    "uniprot": "A0A0N4ULP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULP9"
  },
  {
    "uniprot": "A0A158Q5K2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5K2"
  },
  {
    "uniprot": "A0A0N4UDG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDG0"
  },
  {
    "uniprot": "A0A158Q697",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q697"
  },
  {
    "uniprot": "A0A0N4UF71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF71"
  },
  {
    "uniprot": "A0A0N4UQP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQP1"
  },
  {
    "uniprot": "A0A0N4U8N3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8N3"
  },
  {
    "uniprot": "A0A158Q5X8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5X8"
  },
  {
    "uniprot": "A0A0N4U5T8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5T8"
  },
  {
    "uniprot": "A0A0N4UJX0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJX0"
  },
  {
    "uniprot": "A0A3P7SNE6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SNE6"
  },
  {
    "uniprot": "A0A0N4UFH5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFH5"
  },
  {
    "uniprot": "A0A0N4UP03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP03"
  },
  {
    "uniprot": "A0A0N4U114",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U114"
  },
  {
    "uniprot": "A0A158Q5R3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5R3"
  },
  {
    "uniprot": "A0A3P7PEI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PEI1"
  },
  {
    "uniprot": "A0A0N4U1N3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1N3"
  },
  {
    "uniprot": "A0A0N4US89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US89"
  },
  {
    "uniprot": "A0A0N4UIK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIK1"
  },
  {
    "uniprot": "A0A0N4UIC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIC6"
  },
  {
    "uniprot": "A0A0N4U295",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U295"
  },
  {
    "uniprot": "A0A0N4UJT4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJT4"
  },
  {
    "uniprot": "A0A0N4UGZ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGZ8"
  },
  {
    "uniprot": "A0A0N4UR77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR77"
  },
  {
    "uniprot": "A0A0N4UIR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIR7"
  },
  {
    "uniprot": "A0A0N4U527",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U527"
  },
  {
    "uniprot": "A0A0N4UH98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH98"
  },
  {
    "uniprot": "A0A0N4U0W4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0W4"
  },
  {
    "uniprot": "A0A0N4U599",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U599"
  },
  {
    "uniprot": "A0A0N4UPX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPX9"
  },
  {
    "uniprot": "A0A0N4UEU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEU2"
  },
  {
    "uniprot": "A0A0N4UQZ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQZ0"
  },
  {
    "uniprot": "A0A0N4U7N1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7N1"
  },
  {
    "uniprot": "A0A0N4UQF2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQF2"
  },
  {
    "uniprot": "A0A0N4UKW4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKW4"
  },
  {
    "uniprot": "A0A0N4UAS5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAS5"
  },
  {
    "uniprot": "A0A0N4UJF1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJF1"
  },
  {
    "uniprot": "A0A0N4URA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URA8"
  },
  {
    "uniprot": "A0A0N4U860",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U860"
  },
  {
    "uniprot": "A0A0N4U2H4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2H4"
  },
  {
    "uniprot": "A0A0N4U561",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U561"
  },
  {
    "uniprot": "A0A158Q6E8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6E8"
  },
  {
    "uniprot": "A0A0N4U2L8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2L8"
  },
  {
    "uniprot": "A0A0N4UJ68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ68"
  },
  {
    "uniprot": "A0A3P7Q407",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q407"
  },
  {
    "uniprot": "A0A0N4UI15",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI15"
  },
  {
    "uniprot": "A0A0N4U9J9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9J9"
  },
  {
    "uniprot": "A0A158Q385",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q385"
  },
  {
    "uniprot": "A0A3P7QFC8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QFC8"
  },
  {
    "uniprot": "A0A0N4U4Y6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Y6"
  },
  {
    "uniprot": "A0A0N4UP84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP84"
  },
  {
    "uniprot": "A0A0N4U1C1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1C1"
  },
  {
    "uniprot": "A0A0N4UHU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHU2"
  },
  {
    "uniprot": "A0A0N4U6H0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6H0"
  },
  {
    "uniprot": "A0A0N4UPE9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPE9"
  },
  {
    "uniprot": "A0A3P7PJ70",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PJ70"
  },
  {
    "uniprot": "A0A0N4U8E3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8E3"
  },
  {
    "uniprot": "A0A0N4URV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URV3"
  },
  {
    "uniprot": "A0A158Q4B4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4B4"
  },
  {
    "uniprot": "A0A158Q304",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q304"
  },
  {
    "uniprot": "A0A0N4U4E8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4E8"
  },
  {
    "uniprot": "A0A0N4UF99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF99"
  },
  {
    "uniprot": "A0A0N4UBZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBZ4"
  },
  {
    "uniprot": "A0A3P7QEG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QEG4"
  },
  {
    "uniprot": "A0A0N4UFQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFQ3"
  },
  {
    "uniprot": "A0A0N4UNA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNA0"
  },
  {
    "uniprot": "A0A0N4UJ88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ88"
  },
  {
    "uniprot": "A0A0N4UF34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF34"
  },
  {
    "uniprot": "A0A3P7Q8U9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q8U9"
  },
  {
    "uniprot": "A0A0N4ULB8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULB8"
  },
  {
    "uniprot": "A0A0N4U3J2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3J2"
  },
  {
    "uniprot": "A0A158Q3R8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3R8"
  },
  {
    "uniprot": "A0A0N4UND7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UND7"
  },
  {
    "uniprot": "A0A0N4UNH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNH1"
  },
  {
    "uniprot": "A0A158Q4S4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4S4"
  },
  {
    "uniprot": "A0A0N4UQZ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQZ9"
  },
  {
    "uniprot": "A0A0N4U7X6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7X6"
  },
  {
    "uniprot": "A0A0N4UB88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB88"
  },
  {
    "uniprot": "A0A0N4U4Y3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Y3"
  },
  {
    "uniprot": "A0A0N4UIV2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIV2"
  },
  {
    "uniprot": "A0A158Q2N6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2N6"
  },
  {
    "uniprot": "A0A0N4U3S8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3S8"
  },
  {
    "uniprot": "A0A0N4U332",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U332"
  },
  {
    "uniprot": "A0A0N4URE8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URE8"
  },
  {
    "uniprot": "A0A0N4UD76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD76"
  },
  {
    "uniprot": "A0A0N4U8Z2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Z2"
  },
  {
    "uniprot": "A0A0N4UQ80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ80"
  },
  {
    "uniprot": "A0A0N4UM02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM02"
  },
  {
    "uniprot": "A0A0N4U0G3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0G3"
  },
  {
    "uniprot": "A0A0N4U8U3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8U3"
  },
  {
    "uniprot": "A0A0N4UP45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP45"
  },
  {
    "uniprot": "A0A0N4UGT9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGT9"
  },
  {
    "uniprot": "A0A0N4UK72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK72"
  },
  {
    "uniprot": "A0A0N4UJQ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJQ0"
  },
  {
    "uniprot": "A0A0N4UAA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAA8"
  },
  {
    "uniprot": "A0A0N4U3H3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3H3"
  },
  {
    "uniprot": "A0A0N4U9N8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9N8"
  },
  {
    "uniprot": "A0A0N4U6G2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6G2"
  },
  {
    "uniprot": "A0A0N4UQQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQQ4"
  },
  {
    "uniprot": "A0A0N4U224",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U224"
  },
  {
    "uniprot": "A0A0N4UR62",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR62"
  },
  {
    "uniprot": "A0A0N4U9I1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9I1"
  },
  {
    "uniprot": "A0A0N4UHM9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHM9"
  },
  {
    "uniprot": "A0A0N4UF32",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF32"
  },
  {
    "uniprot": "A0A3P7PVB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PVB1"
  },
  {
    "uniprot": "A0A0N4UMW9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMW9"
  },
  {
    "uniprot": "A0A0N4U882",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U882"
  },
  {
    "uniprot": "A0A158Q2Q5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Q5"
  },
  {
    "uniprot": "A0A0N4US30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US30"
  },
  {
    "uniprot": "A0A0N4UAD6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAD6"
  },
  {
    "uniprot": "A0A3P7QBC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QBC5"
  },
  {
    "uniprot": "A0A0N4UIE4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIE4"
  },
  {
    "uniprot": "A0A0N4UN56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN56"
  },
  {
    "uniprot": "A0A0N4UCT8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCT8"
  },
  {
    "uniprot": "A0A0N4UB01",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB01"
  },
  {
    "uniprot": "A0A158Q6M2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6M2"
  },
  {
    "uniprot": "A0A158Q3V6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3V6"
  },
  {
    "uniprot": "A0A3P7SVY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SVY4"
  },
  {
    "uniprot": "A0A0N4UEL1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEL1"
  },
  {
    "uniprot": "A0A0N4U1H6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1H6"
  },
  {
    "uniprot": "A0A0N4UDD0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDD0"
  },
  {
    "uniprot": "A0A0N4U2D6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2D6"
  },
  {
    "uniprot": "A0A158Q3M8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3M8"
  },
  {
    "uniprot": "A0A0N4U4E0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4E0"
  },
  {
    "uniprot": "A0A0N4UAK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAK0"
  },
  {
    "uniprot": "A0A0N4UI10",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI10"
  },
  {
    "uniprot": "A0A0N4UMP5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMP5"
  },
  {
    "uniprot": "A0A3P7Q6C4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q6C4"
  },
  {
    "uniprot": "A0A158Q2V8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2V8"
  },
  {
    "uniprot": "A0A0N4UDA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDA6"
  },
  {
    "uniprot": "A0A0N4UF89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF89"
  },
  {
    "uniprot": "A0A0N4UFS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFS3"
  },
  {
    "uniprot": "A0A0N4UGM4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGM4"
  },
  {
    "uniprot": "A0A3P7PAK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PAK1"
  },
  {
    "uniprot": "A0A0N4UII0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UII0"
  },
  {
    "uniprot": "A0A0N4UAM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAM0"
  },
  {
    "uniprot": "A0A0N4U966",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U966"
  },
  {
    "uniprot": "A0A0N4UDC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDC1"
  },
  {
    "uniprot": "A0A0N4UKD6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKD6"
  },
  {
    "uniprot": "A0A0N4UI59",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI59"
  },
  {
    "uniprot": "A0A0N4URL4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URL4"
  },
  {
    "uniprot": "A0A0N4U6A8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6A8"
  },
  {
    "uniprot": "A0A158Q4C1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4C1"
  },
  {
    "uniprot": "A0A0N4UH28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH28"
  },
  {
    "uniprot": "A0A0N4U1N8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1N8"
  },
  {
    "uniprot": "A0A0N4U0D4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0D4"
  },
  {
    "uniprot": "A0A0N4UGW4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGW4"
  },
  {
    "uniprot": "A0A0N4UJ53",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ53"
  },
  {
    "uniprot": "A0A0N4U745",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U745"
  },
  {
    "uniprot": "A0A158Q5V0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5V0"
  },
  {
    "uniprot": "A0A3P7PWK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PWK3"
  },
  {
    "uniprot": "A0A0N4ULQ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULQ8"
  },
  {
    "uniprot": "A0A0N4U6I2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6I2"
  },
  {
    "uniprot": "A0A0N4U3E9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3E9"
  },
  {
    "uniprot": "A0A0N4ULX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULX8"
  },
  {
    "uniprot": "A0A0N4U9G2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9G2"
  },
  {
    "uniprot": "A0A158Q5Q6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Q6"
  },
  {
    "uniprot": "A0A0N4ULX3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULX3"
  },
  {
    "uniprot": "A0A0N4U708",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U708"
  },
  {
    "uniprot": "A0A0N4U6H2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6H2"
  },
  {
    "uniprot": "A0A0N4U3Z5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Z5"
  },
  {
    "uniprot": "A0A0N4U1R5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1R5"
  },
  {
    "uniprot": "A0A0N4UD53",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD53"
  },
  {
    "uniprot": "A0A0N4UM70",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM70"
  },
  {
    "uniprot": "A0A0N4UB69",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB69"
  },
  {
    "uniprot": "A0A0N4UIY5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIY5"
  },
  {
    "uniprot": "A0A0N4UNK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNK6"
  },
  {
    "uniprot": "A0A0N4U5S4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5S4"
  },
  {
    "uniprot": "A0A0N4UJZ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJZ0"
  },
  {
    "uniprot": "A0A158Q696",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q696"
  },
  {
    "uniprot": "A0A158Q683",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q683"
  },
  {
    "uniprot": "A0A0N4U3E6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3E6"
  },
  {
    "uniprot": "A0A0N4UAI2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAI2"
  },
  {
    "uniprot": "A0A0N4U3K0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3K0"
  },
  {
    "uniprot": "A0A0N4U810",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U810"
  },
  {
    "uniprot": "A0A0N4UR67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR67"
  },
  {
    "uniprot": "A0A0N4UHY9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHY9"
  },
  {
    "uniprot": "A0A0N4UGF1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGF1"
  },
  {
    "uniprot": "A0A158Q474",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q474"
  },
  {
    "uniprot": "A0A158Q3E3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3E3"
  },
  {
    "uniprot": "A0A0N4U521",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U521"
  },
  {
    "uniprot": "A0A0N4URH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URH9"
  },
  {
    "uniprot": "A0A0N4U0D8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0D8"
  },
  {
    "uniprot": "A0A3P7SKF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SKF3"
  },
  {
    "uniprot": "A0A158Q333",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q333"
  },
  {
    "uniprot": "A0A0N4UQ85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ85"
  },
  {
    "uniprot": "A0A0N4U8H3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8H3"
  },
  {
    "uniprot": "A0A3P7Q686",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q686"
  },
  {
    "uniprot": "A0A0N4UQZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQZ4"
  },
  {
    "uniprot": "A0A3P7QAV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QAV3"
  },
  {
    "uniprot": "A0A0N4U508",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U508"
  },
  {
    "uniprot": "A0A158Q6E9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6E9"
  },
  {
    "uniprot": "A0A0N4UIG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIG0"
  },
  {
    "uniprot": "A0A158Q2R7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2R7"
  },
  {
    "uniprot": "A0A158Q5H2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5H2"
  },
  {
    "uniprot": "A0A0N4UMC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMC9"
  },
  {
    "uniprot": "A0A0N4UFR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFR4"
  },
  {
    "uniprot": "A0A158Q371",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q371"
  },
  {
    "uniprot": "A0A0N4UP73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP73"
  },
  {
    "uniprot": "A0A0N4UHA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHA4"
  },
  {
    "uniprot": "A0A0N4U4V7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4V7"
  },
  {
    "uniprot": "A0A0N4U9W2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9W2"
  },
  {
    "uniprot": "A0A0N4UGU1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGU1"
  },
  {
    "uniprot": "A0A0N4UCA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCA2"
  },
  {
    "uniprot": "A0A0N4U3Q6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Q6"
  },
  {
    "uniprot": "A0A0N4UAC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAC5"
  },
  {
    "uniprot": "A0A0N4UB24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB24"
  },
  {
    "uniprot": "A0A0N4UAG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAG2"
  },
  {
    "uniprot": "A0A0N4UJQ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJQ8"
  },
  {
    "uniprot": "A0A158Q420",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q420"
  },
  {
    "uniprot": "A0A0N4UA52",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA52"
  },
  {
    "uniprot": "A0A0N4U5H0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5H0"
  },
  {
    "uniprot": "A0A0N4UD78",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD78"
  },
  {
    "uniprot": "A0A0N4U794",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U794"
  },
  {
    "uniprot": "A0A3P7SU04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SU04"
  },
  {
    "uniprot": "A0A0N4URA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URA5"
  },
  {
    "uniprot": "A0A3P7PP46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PP46"
  },
  {
    "uniprot": "A0A0N4UP60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP60"
  },
  {
    "uniprot": "A0A0N4U8Z4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Z4"
  },
  {
    "uniprot": "A0A3P7Q3A2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q3A2"
  },
  {
    "uniprot": "A0A0N4UC54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC54"
  },
  {
    "uniprot": "A0A3P7Q6K4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q6K4"
  },
  {
    "uniprot": "A0A0N4U1U0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1U0"
  },
  {
    "uniprot": "A0A0N4URW2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URW2"
  },
  {
    "uniprot": "A0A0N4U2K5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2K5"
  },
  {
    "uniprot": "A0A0N4UJB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJB0"
  },
  {
    "uniprot": "A0A0N4UFC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFC5"
  },
  {
    "uniprot": "A0A0N4U1P1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1P1"
  },
  {
    "uniprot": "A0A0N4UEV4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEV4"
  },
  {
    "uniprot": "A0A0N4UEU0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEU0"
  },
  {
    "uniprot": "A0A0N4UCT2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCT2"
  },
  {
    "uniprot": "A0A0N4UAA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAA1"
  },
  {
    "uniprot": "A0A3P7QCG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QCG3"
  },
  {
    "uniprot": "A0A158Q5B7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5B7"
  },
  {
    "uniprot": "A0A0N4U9V1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9V1"
  },
  {
    "uniprot": "A0A0N4U6V0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6V0"
  },
  {
    "uniprot": "A0A0N4ULB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULB9"
  },
  {
    "uniprot": "A0A0N4UAQ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAQ0"
  },
  {
    "uniprot": "A0A0N4U1X9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1X9"
  },
  {
    "uniprot": "A0A3P7QDR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QDR2"
  },
  {
    "uniprot": "A0A0N4U604",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U604"
  },
  {
    "uniprot": "A0A0N4UBN5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBN5"
  },
  {
    "uniprot": "A0A3P7Q0M3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q0M3"
  },
  {
    "uniprot": "A0A0N4UQW9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQW9"
  },
  {
    "uniprot": "A0A0N4UCR3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCR3"
  },
  {
    "uniprot": "A0A158Q484",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q484"
  },
  {
    "uniprot": "A0A0N4U2D7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2D7"
  },
  {
    "uniprot": "A0A0N4UF09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF09"
  },
  {
    "uniprot": "A0A0N4UGH7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGH7"
  },
  {
    "uniprot": "A0A0N4ULW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULW8"
  },
  {
    "uniprot": "A0A0N4UDB6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDB6"
  },
  {
    "uniprot": "A0A0N4UNC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNC2"
  },
  {
    "uniprot": "A0A0N4UK04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK04"
  },
  {
    "uniprot": "A0A0N4U4Z1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Z1"
  },
  {
    "uniprot": "A0A3P7T0B1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T0B1"
  },
  {
    "uniprot": "A0A0N4UN18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN18"
  },
  {
    "uniprot": "A0A0N4UFG8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFG8"
  },
  {
    "uniprot": "A0A0N4UEG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEG4"
  },
  {
    "uniprot": "A0A158Q4U8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4U8"
  },
  {
    "uniprot": "A0A0N4UMS7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMS7"
  },
  {
    "uniprot": "A0A3P7PXX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PXX7"
  },
  {
    "uniprot": "A0A0N4U9W9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9W9"
  },
  {
    "uniprot": "A0A0N4UEZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEZ5"
  },
  {
    "uniprot": "A0A0N4UBW6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBW6"
  },
  {
    "uniprot": "A0A0N4U6L1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6L1"
  },
  {
    "uniprot": "A0A0N4UG79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG79"
  },
  {
    "uniprot": "A0A0N4U394",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U394"
  },
  {
    "uniprot": "A0A0N4US23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US23"
  },
  {
    "uniprot": "A0A0N4UMZ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMZ8"
  },
  {
    "uniprot": "A0A0N4UCJ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCJ0"
  },
  {
    "uniprot": "A0A0N4U1R1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1R1"
  },
  {
    "uniprot": "A0A158Q5I6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5I6"
  },
  {
    "uniprot": "A0A0N4UQP8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQP8"
  },
  {
    "uniprot": "A0A0N4UBX0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBX0"
  },
  {
    "uniprot": "A0A0N4UIK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIK2"
  },
  {
    "uniprot": "A0A0N4UIA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIA5"
  },
  {
    "uniprot": "A0A0N4UGY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGY1"
  },
  {
    "uniprot": "A0A0N4UNJ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNJ6"
  },
  {
    "uniprot": "A0A0N4UPP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPP4"
  },
  {
    "uniprot": "A0A0N4UJR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJR6"
  },
  {
    "uniprot": "A0A0N4UDN1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDN1"
  },
  {
    "uniprot": "A0A0N4UNU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNU7"
  },
  {
    "uniprot": "A0A0N4UK69",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK69"
  },
  {
    "uniprot": "A0A0N4UGD4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGD4"
  },
  {
    "uniprot": "A0A0N4U909",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U909"
  },
  {
    "uniprot": "A0A0N4U2U1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2U1"
  },
  {
    "uniprot": "A0A0N4U9V0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9V0"
  },
  {
    "uniprot": "A0A158Q4P3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4P3"
  },
  {
    "uniprot": "A0A0N4UDG7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDG7"
  },
  {
    "uniprot": "A0A0N4UPF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPF5"
  },
  {
    "uniprot": "A0A0N4UEX3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEX3"
  },
  {
    "uniprot": "A0A0N4UHE4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHE4"
  },
  {
    "uniprot": "A0A0N4U3I7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3I7"
  },
  {
    "uniprot": "A0A0N4UNQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNQ3"
  },
  {
    "uniprot": "A0A0N4UL92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL92"
  },
  {
    "uniprot": "A0A0N4UJQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJQ7"
  },
  {
    "uniprot": "A0A158Q332",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q332"
  },
  {
    "uniprot": "A0A3P7SKD4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SKD4"
  },
  {
    "uniprot": "A0A0N4UB75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB75"
  },
  {
    "uniprot": "A0A0N4UCG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCG3"
  },
  {
    "uniprot": "A0A3P7TDQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TDQ5"
  },
  {
    "uniprot": "A0A0N4UFB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFB5"
  },
  {
    "uniprot": "A0A3P7QUH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QUH4"
  },
  {
    "uniprot": "A0A0N4UK56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK56"
  },
  {
    "uniprot": "A0A0N4UGP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGP0"
  },
  {
    "uniprot": "A0A0N4UHP5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHP5"
  },
  {
    "uniprot": "A0A0N4UQ10",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ10"
  },
  {
    "uniprot": "A0A0N4UE41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE41"
  },
  {
    "uniprot": "A0A0N4UHQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHQ3"
  },
  {
    "uniprot": "A0A0N4UIL5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIL5"
  },
  {
    "uniprot": "A0A0N4U4S3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4S3"
  },
  {
    "uniprot": "A0A0N4UPS6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPS6"
  },
  {
    "uniprot": "A0A0N4U8L6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8L6"
  },
  {
    "uniprot": "A0A0N4UDF0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDF0"
  },
  {
    "uniprot": "A0A0N4UD04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD04"
  },
  {
    "uniprot": "A0A0N4U1D5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1D5"
  },
  {
    "uniprot": "A0A158Q470",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q470"
  },
  {
    "uniprot": "A0A0N4URC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URC1"
  },
  {
    "uniprot": "A0A3P7Q6X2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q6X2"
  },
  {
    "uniprot": "A0A158Q442",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q442"
  },
  {
    "uniprot": "A0A0N4U5X4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5X4"
  },
  {
    "uniprot": "A0A3P7PVJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PVJ9"
  },
  {
    "uniprot": "A0A0N4U7J2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7J2"
  },
  {
    "uniprot": "A0A0N4U2S1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2S1"
  },
  {
    "uniprot": "A0A0N4UP19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP19"
  },
  {
    "uniprot": "A0A3P7S844",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S844"
  },
  {
    "uniprot": "A0A3P7PY40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PY40"
  },
  {
    "uniprot": "A0A0N4UA98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA98"
  },
  {
    "uniprot": "A0A0N4UDG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDG2"
  },
  {
    "uniprot": "A0A0N4UM15",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM15"
  },
  {
    "uniprot": "A0A0N4UD66",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD66"
  },
  {
    "uniprot": "A0A0N4UA43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA43"
  },
  {
    "uniprot": "A0A0N4UF04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF04"
  },
  {
    "uniprot": "A0A0N4UDB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDB2"
  },
  {
    "uniprot": "A0A0N4U383",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U383"
  },
  {
    "uniprot": "A0A0N4UH00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH00"
  },
  {
    "uniprot": "A0A158Q3C9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3C9"
  },
  {
    "uniprot": "A0A158Q4Q0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Q0"
  },
  {
    "uniprot": "A0A0N4U6Z7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Z7"
  },
  {
    "uniprot": "A0A0N4UL96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL96"
  },
  {
    "uniprot": "A0A0N4UP70",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP70"
  },
  {
    "uniprot": "A0A0N4U1B1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1B1"
  },
  {
    "uniprot": "A0A0N4UEF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEF5"
  },
  {
    "uniprot": "A0A0N4UNU8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNU8"
  },
  {
    "uniprot": "A0A0N4UFX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFX8"
  },
  {
    "uniprot": "A0A158Q453",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q453"
  },
  {
    "uniprot": "A0A0N4U533",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U533"
  },
  {
    "uniprot": "A0A0N4UM84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM84"
  },
  {
    "uniprot": "A0A3P7PWY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PWY1"
  },
  {
    "uniprot": "A0A0N4UFT9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFT9"
  },
  {
    "uniprot": "A0A0N4U631",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U631"
  },
  {
    "uniprot": "A0A0N4UAF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAF5"
  },
  {
    "uniprot": "A0A0N4U481",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U481"
  },
  {
    "uniprot": "A0A158Q6J4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6J4"
  },
  {
    "uniprot": "A0A0N4U1C3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1C3"
  },
  {
    "uniprot": "A0A0N4UPQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPQ6"
  },
  {
    "uniprot": "A0A0N4U6D0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6D0"
  },
  {
    "uniprot": "A0A0N4UNL4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNL4"
  },
  {
    "uniprot": "A0A0N4U9Z6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Z6"
  },
  {
    "uniprot": "A0A158Q4Y5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Y5"
  },
  {
    "uniprot": "A0A0N4U2T0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2T0"
  },
  {
    "uniprot": "A0A158Q381",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q381"
  },
  {
    "uniprot": "A0A0N4URN4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URN4"
  },
  {
    "uniprot": "A0A0N4UEE1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEE1"
  },
  {
    "uniprot": "A0A0N4UPG5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPG5"
  },
  {
    "uniprot": "A0A0N4UAZ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAZ7"
  },
  {
    "uniprot": "A0A3P7Q7S1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q7S1"
  },
  {
    "uniprot": "A0A0N4UK80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK80"
  },
  {
    "uniprot": "A0A3P7QJY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QJY1"
  },
  {
    "uniprot": "A0A158Q4H0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4H0"
  },
  {
    "uniprot": "A0A158Q509",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q509"
  },
  {
    "uniprot": "A0A0N4UB47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB47"
  },
  {
    "uniprot": "A0A0N4UAC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAC4"
  },
  {
    "uniprot": "A0A3P7P7Y9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P7Y9"
  },
  {
    "uniprot": "A0A0N4U9S6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9S6"
  },
  {
    "uniprot": "A0A0N4U4K3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4K3"
  },
  {
    "uniprot": "A0A0N4UAS1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAS1"
  },
  {
    "uniprot": "A0A0N4U3V7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3V7"
  },
  {
    "uniprot": "A0A0N4U3Z3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Z3"
  },
  {
    "uniprot": "A0A158Q599",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q599"
  },
  {
    "uniprot": "A0A0N4UCA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCA9"
  },
  {
    "uniprot": "A0A0N4UF51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF51"
  },
  {
    "uniprot": "A0A0N4U134",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U134"
  },
  {
    "uniprot": "A0A0N4U2K8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2K8"
  },
  {
    "uniprot": "A0A0N4US15",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US15"
  },
  {
    "uniprot": "A0A0N4UQ28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ28"
  },
  {
    "uniprot": "A0A0N4UMS5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMS5"
  },
  {
    "uniprot": "A0A0N4U639",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U639"
  },
  {
    "uniprot": "A0A0N4UNI2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNI2"
  },
  {
    "uniprot": "A0A0N4U3G3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3G3"
  },
  {
    "uniprot": "A0A0N4U770",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U770"
  },
  {
    "uniprot": "A0A0N4UDT2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDT2"
  },
  {
    "uniprot": "A0A0N4U0T5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0T5"
  },
  {
    "uniprot": "A0A0N4UG31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG31"
  },
  {
    "uniprot": "A0A0N4UJV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJV3"
  },
  {
    "uniprot": "A0A0N4U6X2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6X2"
  },
  {
    "uniprot": "A0A0N4U9K3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9K3"
  },
  {
    "uniprot": "A0A0N4U6U3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6U3"
  },
  {
    "uniprot": "A0A158Q576",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q576"
  },
  {
    "uniprot": "A0A0N4U2F6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2F6"
  },
  {
    "uniprot": "A0A0N4UGW1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGW1"
  },
  {
    "uniprot": "A0A0N4U801",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U801"
  },
  {
    "uniprot": "A0A0N4UBN7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBN7"
  },
  {
    "uniprot": "A0A158Q699",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q699"
  },
  {
    "uniprot": "A0A0N4U232",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U232"
  },
  {
    "uniprot": "A0A0N4UDF7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDF7"
  },
  {
    "uniprot": "A0A0N4URY5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URY5"
  },
  {
    "uniprot": "A0A158Q550",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q550"
  },
  {
    "uniprot": "A0A3P7P8W2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P8W2"
  },
  {
    "uniprot": "A0A0N4UEY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEY7"
  },
  {
    "uniprot": "A0A0N4UAW1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAW1"
  },
  {
    "uniprot": "A0A0N4U5Z0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Z0"
  },
  {
    "uniprot": "A0A0N4UDY5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDY5"
  },
  {
    "uniprot": "A0A158Q642",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q642"
  },
  {
    "uniprot": "A0A0N4UQ52",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ52"
  },
  {
    "uniprot": "A0A0N4U8N1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8N1"
  },
  {
    "uniprot": "A0A0N4U0X2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0X2"
  },
  {
    "uniprot": "A0A0N4U6F9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6F9"
  },
  {
    "uniprot": "A0A3P7SQM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SQM0"
  },
  {
    "uniprot": "A0A0N4ULJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULJ4"
  },
  {
    "uniprot": "A0A0N4UMY9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMY9"
  },
  {
    "uniprot": "A0A3P7PX78",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PX78"
  },
  {
    "uniprot": "A0A0N4UMQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMQ6"
  },
  {
    "uniprot": "A0A0N4URG7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URG7"
  },
  {
    "uniprot": "A0A0N4UNI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNI9"
  },
  {
    "uniprot": "A0A0N4U2D9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2D9"
  },
  {
    "uniprot": "A0A0N4ULJ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULJ0"
  },
  {
    "uniprot": "A0A0N4UL18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL18"
  },
  {
    "uniprot": "A0A0N4UG90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG90"
  },
  {
    "uniprot": "A0A0N4UFE9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFE9"
  },
  {
    "uniprot": "A0A0N4UNK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNK5"
  },
  {
    "uniprot": "A0A0N4U773",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U773"
  },
  {
    "uniprot": "A0A0N4U3H1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3H1"
  },
  {
    "uniprot": "A0A0N4UK95",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK95"
  },
  {
    "uniprot": "A0A0N4UL45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL45"
  },
  {
    "uniprot": "A0A0N4U2U7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2U7"
  },
  {
    "uniprot": "A0A0N4UML0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UML0"
  },
  {
    "uniprot": "A0A0N4UAE4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAE4"
  },
  {
    "uniprot": "A0A0N4U6F7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6F7"
  },
  {
    "uniprot": "A0A0N4UHQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHQ1"
  },
  {
    "uniprot": "A0A3P7Q383",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q383"
  },
  {
    "uniprot": "A0A0N4U7B1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7B1"
  },
  {
    "uniprot": "A0A0N4U1N1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1N1"
  },
  {
    "uniprot": "A0A0N4UJI4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJI4"
  },
  {
    "uniprot": "A0A158Q5G9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5G9"
  },
  {
    "uniprot": "A0A0N4URD4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URD4"
  },
  {
    "uniprot": "A0A0N4U6Y4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Y4"
  },
  {
    "uniprot": "A0A0N4UCY9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCY9"
  },
  {
    "uniprot": "A0A0N4UA04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA04"
  },
  {
    "uniprot": "A0A0N4U7Z0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Z0"
  },
  {
    "uniprot": "A0A0N4UNS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNS9"
  },
  {
    "uniprot": "A0A0N4UEY3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEY3"
  },
  {
    "uniprot": "A0A0N4U8X0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8X0"
  },
  {
    "uniprot": "A0A0N4UHE9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHE9"
  },
  {
    "uniprot": "A0A158Q369",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q369"
  },
  {
    "uniprot": "A0A0N4U0L4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0L4"
  },
  {
    "uniprot": "A0A0N4UKX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKX7"
  },
  {
    "uniprot": "A0A0N4U0W6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0W6"
  },
  {
    "uniprot": "A0A0N4U8S5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8S5"
  },
  {
    "uniprot": "A0A0N4UAT9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAT9"
  },
  {
    "uniprot": "A0A0N4URN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URN3"
  },
  {
    "uniprot": "A0A3P7SQX6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SQX6"
  },
  {
    "uniprot": "A0A3P7PUX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PUX5"
  },
  {
    "uniprot": "A0A0N4U6H6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6H6"
  },
  {
    "uniprot": "A0A0N4UJ61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ61"
  },
  {
    "uniprot": "A0A0N4UFZ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFZ7"
  },
  {
    "uniprot": "A0A0N4U0Z5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Z5"
  },
  {
    "uniprot": "A0A0N4U3T0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3T0"
  },
  {
    "uniprot": "A0A0N4U856",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U856"
  },
  {
    "uniprot": "A0A0N4UJD2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJD2"
  },
  {
    "uniprot": "A0A0N4UL06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL06"
  },
  {
    "uniprot": "A0A0N4UE68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE68"
  },
  {
    "uniprot": "A0A158Q4P6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4P6"
  },
  {
    "uniprot": "A0A0N4UIP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIP9"
  },
  {
    "uniprot": "A0A0N4UDX0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDX0"
  },
  {
    "uniprot": "A0A0N4UBB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBB3"
  },
  {
    "uniprot": "A0A0N4UNK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNK2"
  },
  {
    "uniprot": "A0A0N4URT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URT6"
  },
  {
    "uniprot": "A0A3P7S7M7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S7M7"
  },
  {
    "uniprot": "A0A0N4U388",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U388"
  },
  {
    "uniprot": "A0A0N4UIY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIY7"
  },
  {
    "uniprot": "A0A0N4UF40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF40"
  },
  {
    "uniprot": "A0A3P7T202",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T202"
  },
  {
    "uniprot": "A0A0N4UAG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAG0"
  },
  {
    "uniprot": "A0A0N4U751",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U751"
  },
  {
    "uniprot": "A0A0N4UF74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF74"
  },
  {
    "uniprot": "A0A158Q4H9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4H9"
  },
  {
    "uniprot": "A0A0N4UFX0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFX0"
  },
  {
    "uniprot": "A0A0N4UEU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEU4"
  },
  {
    "uniprot": "A0A3P7SZ10",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SZ10"
  },
  {
    "uniprot": "A0A0N4U4D8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4D8"
  },
  {
    "uniprot": "A0A0N4U2S7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2S7"
  },
  {
    "uniprot": "A0A0N4UD35",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD35"
  },
  {
    "uniprot": "A0A0N4UK47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK47"
  },
  {
    "uniprot": "A0A0N4UR60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR60"
  },
  {
    "uniprot": "A0A0N4U8K6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8K6"
  },
  {
    "uniprot": "A0A0N4U6R6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6R6"
  },
  {
    "uniprot": "A0A0N4U7V8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7V8"
  },
  {
    "uniprot": "A0A0N4US03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US03"
  },
  {
    "uniprot": "A0A0N4UN54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN54"
  },
  {
    "uniprot": "A0A0N4UG66",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG66"
  },
  {
    "uniprot": "A0A0N4UNX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNX9"
  },
  {
    "uniprot": "A0A0N4UGS7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGS7"
  },
  {
    "uniprot": "A0A0N4UE84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE84"
  },
  {
    "uniprot": "A0A0N4UIG7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIG7"
  },
  {
    "uniprot": "A0A0N4UBG1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBG1"
  },
  {
    "uniprot": "A0A158Q2W0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2W0"
  },
  {
    "uniprot": "A0A0N4U8R5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8R5"
  },
  {
    "uniprot": "A0A0N4U6W1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6W1"
  },
  {
    "uniprot": "A0A0N4U9N0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9N0"
  },
  {
    "uniprot": "A0A0N4U9T9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9T9"
  },
  {
    "uniprot": "A0A0N4U4K4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4K4"
  },
  {
    "uniprot": "A0A3P7SBY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SBY0"
  },
  {
    "uniprot": "A0A0N4UER7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UER7"
  },
  {
    "uniprot": "A0A3P7PWX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PWX9"
  },
  {
    "uniprot": "A0A3P7Q873",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q873"
  },
  {
    "uniprot": "A0A0N4UM71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM71"
  },
  {
    "uniprot": "A0A0N4UDV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDV3"
  },
  {
    "uniprot": "A0A0N4UIF4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIF4"
  },
  {
    "uniprot": "A0A0N4UAT1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAT1"
  },
  {
    "uniprot": "A0A0N4UIU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIU6"
  },
  {
    "uniprot": "A0A0N4URR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URR4"
  },
  {
    "uniprot": "A0A0N4UCJ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCJ8"
  },
  {
    "uniprot": "A0A0N4UIG8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIG8"
  },
  {
    "uniprot": "A0A0N4UHE1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHE1"
  },
  {
    "uniprot": "A0A0N4UJR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJR7"
  },
  {
    "uniprot": "A0A0N4UI14",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI14"
  },
  {
    "uniprot": "A0A3P7PZN4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PZN4"
  },
  {
    "uniprot": "A0A0N4UC25",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC25"
  },
  {
    "uniprot": "A0A0N4U5I5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5I5"
  },
  {
    "uniprot": "A0A3P7QAL6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QAL6"
  },
  {
    "uniprot": "A0A0N4UMP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMP7"
  },
  {
    "uniprot": "A0A0N4U4Q0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Q0"
  },
  {
    "uniprot": "A0A0N4U2W6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2W6"
  },
  {
    "uniprot": "A0A0N4UBY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBY2"
  },
  {
    "uniprot": "A0A0N4U9C6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9C6"
  },
  {
    "uniprot": "A0A0N4UHX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHX8"
  },
  {
    "uniprot": "A0A3P7Q226",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q226"
  },
  {
    "uniprot": "A0A158Q608",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q608"
  },
  {
    "uniprot": "A0A158Q461",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q461"
  },
  {
    "uniprot": "A0A0N4UI78",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI78"
  },
  {
    "uniprot": "A0A0N4UF64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF64"
  },
  {
    "uniprot": "A0A0N4U9L7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9L7"
  },
  {
    "uniprot": "A0A0N4UAQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAQ4"
  },
  {
    "uniprot": "A0A0N4UAU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAU4"
  },
  {
    "uniprot": "A0A0N4UB11",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB11"
  },
  {
    "uniprot": "A0A0N4UPR3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPR3"
  },
  {
    "uniprot": "A0A158Q2L5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2L5"
  },
  {
    "uniprot": "A0A158Q5W3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5W3"
  },
  {
    "uniprot": "A0A3P7SJJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SJJ4"
  },
  {
    "uniprot": "A0A3P7QTC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QTC2"
  },
  {
    "uniprot": "A0A3P7SI97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SI97"
  },
  {
    "uniprot": "A0A158Q504",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q504"
  },
  {
    "uniprot": "A0A0N4UMB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMB5"
  },
  {
    "uniprot": "A0A0N4U539",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U539"
  },
  {
    "uniprot": "A0A0N4ULC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULC9"
  },
  {
    "uniprot": "A0A0N4UKL5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKL5"
  },
  {
    "uniprot": "A0A0N4UKN8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKN8"
  },
  {
    "uniprot": "A0A3P7QIX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QIX5"
  },
  {
    "uniprot": "A0A0N4URX6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URX6"
  },
  {
    "uniprot": "A0A0N4UIN4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIN4"
  },
  {
    "uniprot": "A0A0N4UEY5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEY5"
  },
  {
    "uniprot": "A0A158Q3I0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3I0"
  },
  {
    "uniprot": "A0A0N4U8D4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8D4"
  },
  {
    "uniprot": "A0A3P7T3I4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T3I4"
  },
  {
    "uniprot": "A0A0N4UCS2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCS2"
  },
  {
    "uniprot": "A0A158Q5G1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5G1"
  },
  {
    "uniprot": "A0A0N4UEZ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEZ3"
  },
  {
    "uniprot": "A0A0N4UAW2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAW2"
  },
  {
    "uniprot": "A0A0N4UIH0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIH0"
  },
  {
    "uniprot": "A0A158Q3Z6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Z6"
  },
  {
    "uniprot": "A0A0N4U9I2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9I2"
  },
  {
    "uniprot": "A0A0N4UB96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB96"
  },
  {
    "uniprot": "A0A0N4U7V3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7V3"
  },
  {
    "uniprot": "A0A0N4URC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URC7"
  },
  {
    "uniprot": "A0A0N4UE11",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE11"
  },
  {
    "uniprot": "A0A0N4U0V8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0V8"
  },
  {
    "uniprot": "A0A0N4U9C3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9C3"
  },
  {
    "uniprot": "A0A0N4U8I7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8I7"
  },
  {
    "uniprot": "A0A0N4U8I5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8I5"
  },
  {
    "uniprot": "A0A0N4UK52",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK52"
  },
  {
    "uniprot": "A0A3P7PWC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PWC9"
  },
  {
    "uniprot": "A0A0N4ULS5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULS5"
  },
  {
    "uniprot": "A0A0N4UKX6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKX6"
  },
  {
    "uniprot": "A0A0N4UFV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFV8"
  },
  {
    "uniprot": "A0A158Q6E3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6E3"
  },
  {
    "uniprot": "A0A0N4UPN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPN9"
  },
  {
    "uniprot": "A0A0N4UIR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIR9"
  },
  {
    "uniprot": "A0A0N4U403",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U403"
  },
  {
    "uniprot": "A0A0N4UJE0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJE0"
  },
  {
    "uniprot": "A0A0N4US26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US26"
  },
  {
    "uniprot": "A0A0N4UGS4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGS4"
  },
  {
    "uniprot": "A0A0N4UIX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIX5"
  },
  {
    "uniprot": "A0A158Q5K4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5K4"
  },
  {
    "uniprot": "A0A0N4UK34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK34"
  },
  {
    "uniprot": "A0A0N4U469",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U469"
  },
  {
    "uniprot": "A0A0N4U889",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U889"
  },
  {
    "uniprot": "A0A0N4U1V3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1V3"
  },
  {
    "uniprot": "A0A0N4UHK4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHK4"
  },
  {
    "uniprot": "A0A0N4UQT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQT6"
  },
  {
    "uniprot": "A0A0N4UJ46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ46"
  },
  {
    "uniprot": "A0A0N4UH72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH72"
  },
  {
    "uniprot": "A0A0N4U2I3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2I3"
  },
  {
    "uniprot": "A0A3P7QIN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QIN0"
  },
  {
    "uniprot": "A0A0N4U5J2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5J2"
  },
  {
    "uniprot": "A0A0N4UNT2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNT2"
  },
  {
    "uniprot": "A0A0N4UHP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHP1"
  },
  {
    "uniprot": "A0A3P7PQ65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQ65"
  },
  {
    "uniprot": "A0A0N4U4V3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4V3"
  },
  {
    "uniprot": "A0A3P7PYN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PYN9"
  },
  {
    "uniprot": "A0A0N4U2H2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2H2"
  },
  {
    "uniprot": "A0A0N4U3S1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3S1"
  },
  {
    "uniprot": "A0A0N4U3W4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3W4"
  },
  {
    "uniprot": "A0A0N4UM56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM56"
  },
  {
    "uniprot": "A0A0N4UIX4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIX4"
  },
  {
    "uniprot": "A0A0N4U8E5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8E5"
  },
  {
    "uniprot": "A0A0N4UN03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN03"
  },
  {
    "uniprot": "A0A0N4UHM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHM2"
  },
  {
    "uniprot": "A0A0N4U6B5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6B5"
  },
  {
    "uniprot": "A0A0N4UPE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPE3"
  },
  {
    "uniprot": "A0A0N4U4G7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4G7"
  },
  {
    "uniprot": "A0A158Q3Y0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Y0"
  },
  {
    "uniprot": "A0A0N4UKK7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKK7"
  },
  {
    "uniprot": "A0A0N4UEQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEQ6"
  },
  {
    "uniprot": "A0A0N4URM3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URM3"
  },
  {
    "uniprot": "A0A0N4U0X8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0X8"
  },
  {
    "uniprot": "A0A158Q3J6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3J6"
  },
  {
    "uniprot": "A0A0N4UBW9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBW9"
  },
  {
    "uniprot": "A0A0N4URP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URP7"
  },
  {
    "uniprot": "A0A0N4UGQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGQ4"
  },
  {
    "uniprot": "A0A0N4U0Z3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Z3"
  },
  {
    "uniprot": "A0A3P7SGQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SGQ7"
  },
  {
    "uniprot": "A0A0N4U4I0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4I0"
  },
  {
    "uniprot": "A0A0N4U5S7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5S7"
  },
  {
    "uniprot": "A0A158Q469",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q469"
  },
  {
    "uniprot": "A0A0N4UCV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCV8"
  },
  {
    "uniprot": "A0A0N4UQ05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ05"
  },
  {
    "uniprot": "A0A0N4U5M1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5M1"
  },
  {
    "uniprot": "A0A0N4U1W6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1W6"
  },
  {
    "uniprot": "A0A0N4UQB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQB0"
  },
  {
    "uniprot": "A0A0N4UL91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL91"
  },
  {
    "uniprot": "A0A0N4UN66",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN66"
  },
  {
    "uniprot": "A0A0N4UGC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGC4"
  },
  {
    "uniprot": "A0A0N4UQW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQW8"
  },
  {
    "uniprot": "A0A158Q5Y8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Y8"
  },
  {
    "uniprot": "A0A158Q4X2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4X2"
  },
  {
    "uniprot": "A0A3P7SQ86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SQ86"
  },
  {
    "uniprot": "A0A3P7SKQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SKQ1"
  },
  {
    "uniprot": "A0A0N4UNY3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNY3"
  },
  {
    "uniprot": "A0A0N4UBB4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBB4"
  },
  {
    "uniprot": "A0A0N4US65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US65"
  },
  {
    "uniprot": "A0A158Q6H3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6H3"
  },
  {
    "uniprot": "A0A0N4U4A3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4A3"
  },
  {
    "uniprot": "A0A0N4U4L9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4L9"
  },
  {
    "uniprot": "A0A0N4UNW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNW8"
  },
  {
    "uniprot": "A0A3P7R0T7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7R0T7"
  },
  {
    "uniprot": "A0A0N4UBP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBP4"
  },
  {
    "uniprot": "A0A158Q3Q2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Q2"
  },
  {
    "uniprot": "A0A158Q669",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q669"
  },
  {
    "uniprot": "A0A0N4U5P3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5P3"
  },
  {
    "uniprot": "A0A0N4UIL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIL8"
  },
  {
    "uniprot": "A0A158Q3Z4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Z4"
  },
  {
    "uniprot": "A0A0N4UQS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQS9"
  },
  {
    "uniprot": "A0A0N4UF63",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF63"
  },
  {
    "uniprot": "A0A0N4UA26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA26"
  },
  {
    "uniprot": "A0A0N4UQS1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQS1"
  },
  {
    "uniprot": "A0A158Q3M6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3M6"
  },
  {
    "uniprot": "A0A0N4UAM5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAM5"
  },
  {
    "uniprot": "A0A0N4UER2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UER2"
  },
  {
    "uniprot": "A0A3P7PX41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PX41"
  },
  {
    "uniprot": "A0A0N4UMS6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMS6"
  },
  {
    "uniprot": "A0A0N4ULK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULK5"
  },
  {
    "uniprot": "A0A0N4UA85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA85"
  },
  {
    "uniprot": "A0A0N4U413",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U413"
  },
  {
    "uniprot": "A0A158Q3N0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3N0"
  },
  {
    "uniprot": "A0A0N4U3L9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3L9"
  },
  {
    "uniprot": "A0A0N4UQB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQB3"
  },
  {
    "uniprot": "A0A0N4U2I2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2I2"
  },
  {
    "uniprot": "A0A0N4U5E3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5E3"
  },
  {
    "uniprot": "A0A0N4UB71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB71"
  },
  {
    "uniprot": "A0A158Q3Q8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Q8"
  },
  {
    "uniprot": "A0A0N4U2E3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2E3"
  },
  {
    "uniprot": "A0A0N4UIU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIU2"
  },
  {
    "uniprot": "A0A158Q539",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q539"
  },
  {
    "uniprot": "A0A3P7SHF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SHF5"
  },
  {
    "uniprot": "A0A3P7SK10",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SK10"
  },
  {
    "uniprot": "A0A3P7PMU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PMU6"
  },
  {
    "uniprot": "A0A0N4UMC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMC5"
  },
  {
    "uniprot": "A0A0N4U3U6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3U6"
  },
  {
    "uniprot": "A0A0N4UJA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJA1"
  },
  {
    "uniprot": "A0A0N4UJ58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ58"
  },
  {
    "uniprot": "A0A158Q3M5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3M5"
  },
  {
    "uniprot": "A0A0N4UKR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKR4"
  },
  {
    "uniprot": "A0A0N4UMK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMK2"
  },
  {
    "uniprot": "A0A0N4U5V0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5V0"
  },
  {
    "uniprot": "A0A0N4UQD9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQD9"
  },
  {
    "uniprot": "A0A0N4U2H0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2H0"
  },
  {
    "uniprot": "A0A0N4UB97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB97"
  },
  {
    "uniprot": "A0A158Q2T4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2T4"
  },
  {
    "uniprot": "A0A158Q2N9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2N9"
  },
  {
    "uniprot": "A0A158Q5A1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5A1"
  },
  {
    "uniprot": "A0A158Q6G4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6G4"
  },
  {
    "uniprot": "A0A158Q6A2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6A2"
  },
  {
    "uniprot": "A0A0N4UMY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMY2"
  },
  {
    "uniprot": "A0A0N4UJF6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJF6"
  },
  {
    "uniprot": "A0A0N4UET0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UET0"
  },
  {
    "uniprot": "A0A0N4UAP8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAP8"
  },
  {
    "uniprot": "A0A3P7SUL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SUL0"
  },
  {
    "uniprot": "A0A0N4U374",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U374"
  },
  {
    "uniprot": "A0A0N4UJ21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ21"
  },
  {
    "uniprot": "A0A0N4URF0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URF0"
  },
  {
    "uniprot": "A0A0N4UJ59",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ59"
  },
  {
    "uniprot": "A0A0N4U4P4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4P4"
  },
  {
    "uniprot": "A0A158Q582",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q582"
  },
  {
    "uniprot": "A0A0N4U1I7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1I7"
  },
  {
    "uniprot": "A0A0N4U9N5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9N5"
  },
  {
    "uniprot": "A0A0N4U7C9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7C9"
  },
  {
    "uniprot": "A0A0N4U888",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U888"
  },
  {
    "uniprot": "A0A3P7Q8T5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q8T5"
  },
  {
    "uniprot": "A0A0N4U8K7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8K7"
  },
  {
    "uniprot": "A0A0N4URA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URA4"
  },
  {
    "uniprot": "A0A3P7QT80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QT80"
  },
  {
    "uniprot": "A0A3P7TGX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TGX8"
  },
  {
    "uniprot": "A0A0N4UIH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIH1"
  },
  {
    "uniprot": "A0A0N4U807",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U807"
  },
  {
    "uniprot": "A0A0N4UB85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB85"
  },
  {
    "uniprot": "A0A0N4U2J2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2J2"
  },
  {
    "uniprot": "A0A0N4UB48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB48"
  },
  {
    "uniprot": "A0A0N4UIX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIX8"
  },
  {
    "uniprot": "A0A0N4URH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URH1"
  },
  {
    "uniprot": "A0A158Q3U1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3U1"
  },
  {
    "uniprot": "A0A0N4UJJ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJJ8"
  },
  {
    "uniprot": "A0A0N4U312",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U312"
  },
  {
    "uniprot": "A0A0N4UPJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPJ7"
  },
  {
    "uniprot": "A0A158Q2Z3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Z3"
  },
  {
    "uniprot": "A0A0N4UMH7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMH7"
  },
  {
    "uniprot": "A0A0N4U4J2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4J2"
  },
  {
    "uniprot": "A0A0N4UQV9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQV9"
  },
  {
    "uniprot": "A0A0N4UNR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNR6"
  },
  {
    "uniprot": "A0A0N4USA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USA5"
  },
  {
    "uniprot": "A0A0N4UG40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG40"
  },
  {
    "uniprot": "A0A0N4ULP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULP7"
  },
  {
    "uniprot": "A0A3P7SAR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SAR6"
  },
  {
    "uniprot": "A0A0N4UPQ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPQ9"
  },
  {
    "uniprot": "A0A158Q4L0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4L0"
  },
  {
    "uniprot": "A0A0N4UCU5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCU5"
  },
  {
    "uniprot": "A0A0N4UJC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJC9"
  },
  {
    "uniprot": "A0A0N4UR55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR55"
  },
  {
    "uniprot": "A0A0N4U1T7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1T7"
  },
  {
    "uniprot": "A0A0N4U5F6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5F6"
  },
  {
    "uniprot": "A0A0N4UKC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKC1"
  },
  {
    "uniprot": "A0A0N4UGH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGH4"
  },
  {
    "uniprot": "A0A0N4UKX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKX8"
  },
  {
    "uniprot": "A0A3P7QL32",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QL32"
  },
  {
    "uniprot": "A0A0N4UFW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFW0"
  },
  {
    "uniprot": "A0A0N4UI98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI98"
  },
  {
    "uniprot": "A0A0N4UAN8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAN8"
  },
  {
    "uniprot": "A0A3P7P8Z0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P8Z0"
  },
  {
    "uniprot": "A0A0N4UCW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCW8"
  },
  {
    "uniprot": "A0A158Q2N2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2N2"
  },
  {
    "uniprot": "A0A0N4U547",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U547"
  },
  {
    "uniprot": "A0A0N4U4L1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4L1"
  },
  {
    "uniprot": "A0A0N4U4U1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4U1"
  },
  {
    "uniprot": "A0A0N4UPC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPC0"
  },
  {
    "uniprot": "A0A3P7ST88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7ST88"
  },
  {
    "uniprot": "A0A0N4UC03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC03"
  },
  {
    "uniprot": "A0A0N4U6D1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6D1"
  },
  {
    "uniprot": "A0A158Q2S0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2S0"
  },
  {
    "uniprot": "A0A0N4URC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URC5"
  },
  {
    "uniprot": "A0A0N4UAB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAB7"
  },
  {
    "uniprot": "A0A0N4U0T4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0T4"
  },
  {
    "uniprot": "A0A158Q2Y8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Y8"
  },
  {
    "uniprot": "A0A0N4U7Y7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Y7"
  },
  {
    "uniprot": "A0A0N4UQ91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ91"
  },
  {
    "uniprot": "A0A0N4UFM5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFM5"
  },
  {
    "uniprot": "A0A0N4U323",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U323"
  },
  {
    "uniprot": "A0A0N4UIL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIL0"
  },
  {
    "uniprot": "A0A0N4UAY9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAY9"
  },
  {
    "uniprot": "A0A0N4U9A7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9A7"
  },
  {
    "uniprot": "A0A0N4U3D4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3D4"
  },
  {
    "uniprot": "A0A0N4UFS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFS9"
  },
  {
    "uniprot": "A0A3P7PV90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PV90"
  },
  {
    "uniprot": "A0A158Q3Y5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Y5"
  },
  {
    "uniprot": "A0A0N4U397",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U397"
  },
  {
    "uniprot": "A0A0N4UBY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBY0"
  },
  {
    "uniprot": "A0A0N4URI5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URI5"
  },
  {
    "uniprot": "A0A0N4ULK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULK6"
  },
  {
    "uniprot": "A0A0N4UM63",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM63"
  },
  {
    "uniprot": "A0A3P7QHZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QHZ4"
  },
  {
    "uniprot": "A0A0N4UFD9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFD9"
  },
  {
    "uniprot": "A0A0N4U1F8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1F8"
  },
  {
    "uniprot": "A0A0N4UA83",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA83"
  },
  {
    "uniprot": "A0A158Q4N8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4N8"
  },
  {
    "uniprot": "A0A0N4UM41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM41"
  },
  {
    "uniprot": "A0A0N4UQN1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQN1"
  },
  {
    "uniprot": "A0A0N4U4D9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4D9"
  },
  {
    "uniprot": "A0A0N4U3Q1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Q1"
  },
  {
    "uniprot": "A0A3P7SRJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SRJ2"
  },
  {
    "uniprot": "A0A3P7QFG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QFG2"
  },
  {
    "uniprot": "A0A0N4U8T7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8T7"
  },
  {
    "uniprot": "A0A3P7Q0V9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q0V9"
  },
  {
    "uniprot": "A0A0N4UC89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC89"
  },
  {
    "uniprot": "A0A3P7QFV9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QFV9"
  },
  {
    "uniprot": "A0A3P7Q921",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q921"
  },
  {
    "uniprot": "A0A0N4U875",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U875"
  },
  {
    "uniprot": "A0A0N4U8S2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8S2"
  },
  {
    "uniprot": "A0A0N4U5Z5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Z5"
  },
  {
    "uniprot": "A0A158Q4I3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4I3"
  },
  {
    "uniprot": "A0A3P7SRK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SRK0"
  },
  {
    "uniprot": "A0A3P7R2K5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7R2K5"
  },
  {
    "uniprot": "A0A0N4U7Q2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Q2"
  },
  {
    "uniprot": "A0A0N4UA92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA92"
  },
  {
    "uniprot": "A0A158Q3J0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3J0"
  },
  {
    "uniprot": "A0A158Q549",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q549"
  },
  {
    "uniprot": "A0A0N4U5K7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5K7"
  },
  {
    "uniprot": "A0A158Q4E4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4E4"
  },
  {
    "uniprot": "A0A0N4U3W3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3W3"
  },
  {
    "uniprot": "A0A0N4UEN1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEN1"
  },
  {
    "uniprot": "A0A0N4U983",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U983"
  },
  {
    "uniprot": "A0A0N4US07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US07"
  },
  {
    "uniprot": "A0A158Q6F3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6F3"
  },
  {
    "uniprot": "A0A0N4UGX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGX5"
  },
  {
    "uniprot": "A0A0N4UD19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD19"
  },
  {
    "uniprot": "A0A0N4U464",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U464"
  },
  {
    "uniprot": "A0A0N4U7I8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7I8"
  },
  {
    "uniprot": "A0A158Q3T5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3T5"
  },
  {
    "uniprot": "A0A0N4ULJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULJ7"
  },
  {
    "uniprot": "A0A0N4UIV9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIV9"
  },
  {
    "uniprot": "A0A0N4U4H8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4H8"
  },
  {
    "uniprot": "A0A0N4UAM1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAM1"
  },
  {
    "uniprot": "A0A0N4U325",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U325"
  },
  {
    "uniprot": "A0A0N4UR40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR40"
  },
  {
    "uniprot": "A0A3P7T0D4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T0D4"
  },
  {
    "uniprot": "A0A3P7Q687",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q687"
  },
  {
    "uniprot": "A0A0N4UN40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN40"
  },
  {
    "uniprot": "A0A0N4U278",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U278"
  },
  {
    "uniprot": "A0A158Q3A7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3A7"
  },
  {
    "uniprot": "A0A0N4UMG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMG2"
  },
  {
    "uniprot": "A0A158Q372",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q372"
  },
  {
    "uniprot": "A0A0N4UAF8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAF8"
  },
  {
    "uniprot": "A0A158Q424",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q424"
  },
  {
    "uniprot": "A0A0N4U9D7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9D7"
  },
  {
    "uniprot": "A0A0N4U8P4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8P4"
  },
  {
    "uniprot": "A0A0N4U0I8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0I8"
  },
  {
    "uniprot": "A0A158Q4G4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4G4"
  },
  {
    "uniprot": "A0A3P7SGU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SGU4"
  },
  {
    "uniprot": "A0A0N4ULV1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULV1"
  },
  {
    "uniprot": "A0A0N4UJA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJA2"
  },
  {
    "uniprot": "A0A0N4URI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URI1"
  },
  {
    "uniprot": "A0A0N4U2B5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2B5"
  },
  {
    "uniprot": "A0A0N4UJR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJR2"
  },
  {
    "uniprot": "A0A0N4URG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URG0"
  },
  {
    "uniprot": "A0A3P7Q1S4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q1S4"
  },
  {
    "uniprot": "A0A0N4U213",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U213"
  },
  {
    "uniprot": "A0A0N4US38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US38"
  },
  {
    "uniprot": "A0A0N4UNR3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNR3"
  },
  {
    "uniprot": "A0A0N4UC30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC30"
  },
  {
    "uniprot": "A0A3P7QWA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QWA6"
  },
  {
    "uniprot": "A0A0N4UCH0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCH0"
  },
  {
    "uniprot": "A0A0N4U1M7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1M7"
  },
  {
    "uniprot": "A0A0N4UAY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAY2"
  },
  {
    "uniprot": "A0A158Q571",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q571"
  },
  {
    "uniprot": "A0A0N4UGB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGB9"
  },
  {
    "uniprot": "A0A0N4ULG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULG9"
  },
  {
    "uniprot": "A0A0N4UML1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UML1"
  },
  {
    "uniprot": "A0A0N4US92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US92"
  },
  {
    "uniprot": "A0A0N4UPZ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPZ0"
  },
  {
    "uniprot": "A0A0N4UGQ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGQ0"
  },
  {
    "uniprot": "A0A0N4UET7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UET7"
  },
  {
    "uniprot": "A0A0N4U2M8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2M8"
  },
  {
    "uniprot": "A0A0N4U6E4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6E4"
  },
  {
    "uniprot": "A0A0N4UIF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIF9"
  },
  {
    "uniprot": "A0A0N4U6D8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6D8"
  },
  {
    "uniprot": "A0A158Q5B4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5B4"
  },
  {
    "uniprot": "A0A0N4UGL4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGL4"
  },
  {
    "uniprot": "A0A0N4U969",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U969"
  },
  {
    "uniprot": "A0A0N4UEP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEP9"
  },
  {
    "uniprot": "A0A3P7S9P4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S9P4"
  },
  {
    "uniprot": "A0A0N4U0E7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0E7"
  },
  {
    "uniprot": "A0A0N4U5Y4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Y4"
  },
  {
    "uniprot": "A0A0N4UQR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQR4"
  },
  {
    "uniprot": "A0A158Q6B5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6B5"
  },
  {
    "uniprot": "A0A0N4UJN4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJN4"
  },
  {
    "uniprot": "A0A0N4UJV5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJV5"
  },
  {
    "uniprot": "A0A3P7PW64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PW64"
  },
  {
    "uniprot": "A0A0N4U590",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U590"
  },
  {
    "uniprot": "A0A0N4UML6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UML6"
  },
  {
    "uniprot": "A0A0N4UG17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG17"
  },
  {
    "uniprot": "A0A0N4UM54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM54"
  },
  {
    "uniprot": "A0A0N4UQJ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQJ0"
  },
  {
    "uniprot": "A0A0N4U2X2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2X2"
  },
  {
    "uniprot": "A0A0N4UDU5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDU5"
  },
  {
    "uniprot": "A0A158Q6B9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6B9"
  },
  {
    "uniprot": "A0A0N4U5J1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5J1"
  },
  {
    "uniprot": "A0A0N4UD65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD65"
  },
  {
    "uniprot": "A0A158Q6N4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6N4"
  },
  {
    "uniprot": "A0A0N4U5W7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5W7"
  },
  {
    "uniprot": "A0A0N4UQA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQA2"
  },
  {
    "uniprot": "A0A0N4UNM7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNM7"
  },
  {
    "uniprot": "A0A158Q2V3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2V3"
  },
  {
    "uniprot": "A0A0N4UN01",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN01"
  },
  {
    "uniprot": "A0A3P7SDX6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SDX6"
  },
  {
    "uniprot": "A0A158Q2T5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2T5"
  },
  {
    "uniprot": "A0A0N4U1D8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1D8"
  },
  {
    "uniprot": "A0A3P7QH42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QH42"
  },
  {
    "uniprot": "A0A0N4U8T4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8T4"
  },
  {
    "uniprot": "A0A0N4UFB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFB1"
  },
  {
    "uniprot": "A0A0N4URC8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URC8"
  },
  {
    "uniprot": "A0A0N4UA39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA39"
  },
  {
    "uniprot": "A0A0N4UN38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN38"
  },
  {
    "uniprot": "A0A0N4UHK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHK2"
  },
  {
    "uniprot": "A0A0N4U0Z1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Z1"
  },
  {
    "uniprot": "A0A0N4U1P8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1P8"
  },
  {
    "uniprot": "A0A0N4UDE6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDE6"
  },
  {
    "uniprot": "A0A0N4UHS6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHS6"
  },
  {
    "uniprot": "A0A0N4U1Q6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Q6"
  },
  {
    "uniprot": "A0A0N4U0N7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0N7"
  },
  {
    "uniprot": "A0A0N4UAV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAV3"
  },
  {
    "uniprot": "A0A3P7SXC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SXC5"
  },
  {
    "uniprot": "A0A3P7QBI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QBI0"
  },
  {
    "uniprot": "A0A3P7QJ51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QJ51"
  },
  {
    "uniprot": "A0A3P7Q914",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q914"
  },
  {
    "uniprot": "A0A0N4U2P4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2P4"
  },
  {
    "uniprot": "A0A0N4UQJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQJ9"
  },
  {
    "uniprot": "A0A0N4U3Q7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Q7"
  },
  {
    "uniprot": "A0A0N4UI61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI61"
  },
  {
    "uniprot": "A0A0N4U7G6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7G6"
  },
  {
    "uniprot": "A0A0N4UAK7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAK7"
  },
  {
    "uniprot": "A0A0N4U6A7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6A7"
  },
  {
    "uniprot": "A0A158Q5L5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5L5"
  },
  {
    "uniprot": "A0A0N4U0D2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0D2"
  },
  {
    "uniprot": "A0A0N4U652",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U652"
  },
  {
    "uniprot": "A0A0N4UPA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPA9"
  },
  {
    "uniprot": "A0A158Q3R2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3R2"
  },
  {
    "uniprot": "A0A0N4UFH8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFH8"
  },
  {
    "uniprot": "A0A0N4UPX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPX5"
  },
  {
    "uniprot": "A0A158Q4P0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4P0"
  },
  {
    "uniprot": "A0A3P7P3M4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P3M4"
  },
  {
    "uniprot": "A0A0N4U1Q8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Q8"
  },
  {
    "uniprot": "A0A0N4UM50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM50"
  },
  {
    "uniprot": "A0A0N4UNE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNE3"
  },
  {
    "uniprot": "A0A0N4U6E0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6E0"
  },
  {
    "uniprot": "A0A0N4U923",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U923"
  },
  {
    "uniprot": "A0A0N4UCX2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCX2"
  },
  {
    "uniprot": "A0A0N4UEX4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEX4"
  },
  {
    "uniprot": "A0A0N4U0U5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0U5"
  },
  {
    "uniprot": "A0A0N4UGD7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGD7"
  },
  {
    "uniprot": "A0A0N4UAX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAX5"
  },
  {
    "uniprot": "A0A158Q6H8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6H8"
  },
  {
    "uniprot": "A0A0N4ULS0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULS0"
  },
  {
    "uniprot": "A0A0N4UFA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFA2"
  },
  {
    "uniprot": "A0A0N4U5P6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5P6"
  },
  {
    "uniprot": "A0A0N4U731",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U731"
  },
  {
    "uniprot": "A0A0N4UQ42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ42"
  },
  {
    "uniprot": "A0A0N4UC96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC96"
  },
  {
    "uniprot": "A0A0N4U8S0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8S0"
  },
  {
    "uniprot": "A0A158Q497",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q497"
  },
  {
    "uniprot": "A0A158Q3B6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3B6"
  },
  {
    "uniprot": "A0A158Q506",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q506"
  },
  {
    "uniprot": "A0A0N4UNB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNB7"
  },
  {
    "uniprot": "A0A0N4ULY5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULY5"
  },
  {
    "uniprot": "A0A158Q3N3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3N3"
  },
  {
    "uniprot": "A0A0N4UBN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBN6"
  },
  {
    "uniprot": "A0A0N4UBU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBU3"
  },
  {
    "uniprot": "A0A0N4UN82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN82"
  },
  {
    "uniprot": "A0A0N4UQA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQA5"
  },
  {
    "uniprot": "A0A0N4U3E0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3E0"
  },
  {
    "uniprot": "A0A3P7Q159",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q159"
  },
  {
    "uniprot": "A0A0N4U424",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U424"
  },
  {
    "uniprot": "A0A158Q498",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q498"
  },
  {
    "uniprot": "A0A158Q4I9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4I9"
  },
  {
    "uniprot": "A0A0N4UCB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCB0"
  },
  {
    "uniprot": "A0A3P7SZN7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SZN7"
  },
  {
    "uniprot": "A0A3P7PLV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PLV8"
  },
  {
    "uniprot": "A0A158Q2M3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2M3"
  },
  {
    "uniprot": "A0A0N4URI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URI3"
  },
  {
    "uniprot": "A0A0N4U5H9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5H9"
  },
  {
    "uniprot": "A0A0N4U897",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U897"
  },
  {
    "uniprot": "A0A0N4UCX4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCX4"
  },
  {
    "uniprot": "A0A0N4US90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US90"
  },
  {
    "uniprot": "A0A0N4U505",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U505"
  },
  {
    "uniprot": "A0A3P7PTB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PTB3"
  },
  {
    "uniprot": "A0A0N4UBZ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBZ6"
  },
  {
    "uniprot": "A0A3P7Q3Y5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q3Y5"
  },
  {
    "uniprot": "A0A0N4U7T4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7T4"
  },
  {
    "uniprot": "A0A0N4URJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URJ9"
  },
  {
    "uniprot": "A0A0N4U7E1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7E1"
  },
  {
    "uniprot": "A0A158Q3V3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3V3"
  },
  {
    "uniprot": "A0A158Q4R0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4R0"
  },
  {
    "uniprot": "A0A0N4U6M5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6M5"
  },
  {
    "uniprot": "A0A3P7Q064",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q064"
  },
  {
    "uniprot": "A0A0N4U2F8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2F8"
  },
  {
    "uniprot": "A0A158Q4I6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4I6"
  },
  {
    "uniprot": "A0A158Q3Z8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Z8"
  },
  {
    "uniprot": "A0A158Q685",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q685"
  },
  {
    "uniprot": "A0A0N4UAQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAQ6"
  },
  {
    "uniprot": "A0A158Q6J3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6J3"
  },
  {
    "uniprot": "A0A3P7R0I2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7R0I2"
  },
  {
    "uniprot": "A0A0N4UQ95",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ95"
  },
  {
    "uniprot": "A0A0N4UAH0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAH0"
  },
  {
    "uniprot": "A0A0N4UC86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC86"
  },
  {
    "uniprot": "A0A3P7S7X9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S7X9"
  },
  {
    "uniprot": "A0A0N4UNR0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNR0"
  },
  {
    "uniprot": "A0A0N4U5L1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5L1"
  },
  {
    "uniprot": "A0A158Q4D8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4D8"
  },
  {
    "uniprot": "A0A0N4UM13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM13"
  },
  {
    "uniprot": "A0A0N4U3G7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3G7"
  },
  {
    "uniprot": "A0A0N4UJY9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJY9"
  },
  {
    "uniprot": "A0A0N4UGW5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGW5"
  },
  {
    "uniprot": "A0A0N4U6L8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6L8"
  },
  {
    "uniprot": "A0A0N4UDJ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDJ3"
  },
  {
    "uniprot": "A0A158Q4D6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4D6"
  },
  {
    "uniprot": "A0A0N4U1K1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1K1"
  },
  {
    "uniprot": "A0A3P7PPE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPE3"
  },
  {
    "uniprot": "A0A158Q3Y2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Y2"
  },
  {
    "uniprot": "A0A158Q3X4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3X4"
  },
  {
    "uniprot": "A0A0N4UHD3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHD3"
  },
  {
    "uniprot": "A0A0N4UER9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UER9"
  },
  {
    "uniprot": "A0A0N4UQB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQB1"
  },
  {
    "uniprot": "A0A3P7PPB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPB3"
  },
  {
    "uniprot": "A0A0N4UPR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPR7"
  },
  {
    "uniprot": "A0A3P7SN97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SN97"
  },
  {
    "uniprot": "A0A0N4U2S5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2S5"
  },
  {
    "uniprot": "A0A0N4U103",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U103"
  },
  {
    "uniprot": "A0A0N4UML8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UML8"
  },
  {
    "uniprot": "A0A0N4UC02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC02"
  },
  {
    "uniprot": "A0A158Q411",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q411"
  },
  {
    "uniprot": "A0A0N4UDQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDQ3"
  },
  {
    "uniprot": "A0A0N4U439",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U439"
  },
  {
    "uniprot": "A0A158Q4H3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4H3"
  },
  {
    "uniprot": "A0A3P7PEH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PEH4"
  },
  {
    "uniprot": "A0A0N4U798",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U798"
  },
  {
    "uniprot": "A0A0N4UQ04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ04"
  },
  {
    "uniprot": "A0A158Q6K2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6K2"
  },
  {
    "uniprot": "A0A0N4U226",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U226"
  },
  {
    "uniprot": "A0A0N4UPA7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPA7"
  },
  {
    "uniprot": "A0A0N4U329",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U329"
  },
  {
    "uniprot": "A0A0N4UNK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNK3"
  },
  {
    "uniprot": "A0A0N4UD39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD39"
  },
  {
    "uniprot": "A0A0N4UQG1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQG1"
  },
  {
    "uniprot": "A0A0N4UPM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPM0"
  },
  {
    "uniprot": "A0A0N4UH78",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH78"
  },
  {
    "uniprot": "A0A3P7QB62",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QB62"
  },
  {
    "uniprot": "A0A0N4UJS8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJS8"
  },
  {
    "uniprot": "A0A158Q569",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q569"
  },
  {
    "uniprot": "A0A0N4UC38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC38"
  },
  {
    "uniprot": "A0A0N4UND1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UND1"
  },
  {
    "uniprot": "A0A0N4UAD3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAD3"
  },
  {
    "uniprot": "A0A0N4UNN7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNN7"
  },
  {
    "uniprot": "A0A0N4U605",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U605"
  },
  {
    "uniprot": "A0A0N4UEV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEV3"
  },
  {
    "uniprot": "A0A0N4UNN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNN2"
  },
  {
    "uniprot": "A0A0N4UQB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQB5"
  },
  {
    "uniprot": "A0A0N4U980",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U980"
  },
  {
    "uniprot": "A0A0N4UJG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJG9"
  },
  {
    "uniprot": "A0A0N4UBZ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBZ3"
  },
  {
    "uniprot": "A0A0N4U8U2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8U2"
  },
  {
    "uniprot": "A0A158Q5T3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5T3"
  },
  {
    "uniprot": "A0A0N4UAP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAP1"
  },
  {
    "uniprot": "A0A0N4UH60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH60"
  },
  {
    "uniprot": "A0A158Q493",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q493"
  },
  {
    "uniprot": "A0A3P7Q8A4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q8A4"
  },
  {
    "uniprot": "A0A3P7S9X0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S9X0"
  },
  {
    "uniprot": "A0A0N4UDK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDK6"
  },
  {
    "uniprot": "A0A0N4U8B2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8B2"
  },
  {
    "uniprot": "A0A0N4URE1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URE1"
  },
  {
    "uniprot": "A0A158Q619",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q619"
  },
  {
    "uniprot": "A0A0N4UP05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP05"
  },
  {
    "uniprot": "A0A0N4UFJ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFJ5"
  },
  {
    "uniprot": "A0A0N4U6T6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6T6"
  },
  {
    "uniprot": "A0A158Q3Z1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Z1"
  },
  {
    "uniprot": "A0A0N4UI31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI31"
  },
  {
    "uniprot": "A0A0N4UEN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEN9"
  },
  {
    "uniprot": "A0A0N4U8Y3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Y3"
  },
  {
    "uniprot": "A0A0N4UPT8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPT8"
  },
  {
    "uniprot": "A0A158Q3F9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3F9"
  },
  {
    "uniprot": "A0A0N4UK24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK24"
  },
  {
    "uniprot": "A0A3P7SEH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SEH4"
  },
  {
    "uniprot": "A0A0N4UMY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMY4"
  },
  {
    "uniprot": "A0A0N4UBJ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBJ5"
  },
  {
    "uniprot": "A0A0N4US18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US18"
  },
  {
    "uniprot": "A0A0N4UP79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP79"
  },
  {
    "uniprot": "A0A0N4U4C0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4C0"
  },
  {
    "uniprot": "A0A0N4UK21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK21"
  },
  {
    "uniprot": "A0A158Q6D1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6D1"
  },
  {
    "uniprot": "A0A158Q5I3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5I3"
  },
  {
    "uniprot": "A0A0N4UQG8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQG8"
  },
  {
    "uniprot": "A0A3P7QU59",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QU59"
  },
  {
    "uniprot": "A0A0N4URP5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URP5"
  },
  {
    "uniprot": "A0A0N4UE62",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE62"
  },
  {
    "uniprot": "A0A0N4U7L4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7L4"
  },
  {
    "uniprot": "A0A158Q2S9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2S9"
  },
  {
    "uniprot": "A0A0N4U468",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U468"
  },
  {
    "uniprot": "A0A0N4UMJ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMJ0"
  },
  {
    "uniprot": "A0A3P7SF62",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SF62"
  },
  {
    "uniprot": "A0A0N4U8X5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8X5"
  },
  {
    "uniprot": "A0A0N4UGJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGJ4"
  },
  {
    "uniprot": "A0A0N4UBA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBA8"
  },
  {
    "uniprot": "A0A0N4UNQ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNQ8"
  },
  {
    "uniprot": "A0A0N4U589",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U589"
  },
  {
    "uniprot": "A0A0N4UGQ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGQ8"
  },
  {
    "uniprot": "A0A0N4UAK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAK1"
  },
  {
    "uniprot": "A0A0N4UHW1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHW1"
  },
  {
    "uniprot": "A0A0N4U306",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U306"
  },
  {
    "uniprot": "A0A0N4U243",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U243"
  },
  {
    "uniprot": "A0A0N4U0I5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0I5"
  },
  {
    "uniprot": "A0A0N4USB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USB5"
  },
  {
    "uniprot": "A0A0N4U805",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U805"
  },
  {
    "uniprot": "A0A0N4U9R7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9R7"
  },
  {
    "uniprot": "A0A0N4U5D2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5D2"
  },
  {
    "uniprot": "A0A158Q5F1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5F1"
  },
  {
    "uniprot": "A0A0N4ULH7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULH7"
  },
  {
    "uniprot": "A0A0N4UCE7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCE7"
  },
  {
    "uniprot": "A0A0N4U3N8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3N8"
  },
  {
    "uniprot": "A0A0N4UL94",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL94"
  },
  {
    "uniprot": "A0A3P7P8V2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P8V2"
  },
  {
    "uniprot": "A0A0N4U321",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U321"
  },
  {
    "uniprot": "A0A0N4UL62",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL62"
  },
  {
    "uniprot": "A0A0N4U9X1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9X1"
  },
  {
    "uniprot": "A0A0N4UCT5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCT5"
  },
  {
    "uniprot": "A0A0N4U174",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U174"
  },
  {
    "uniprot": "A0A0N4U0T3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0T3"
  },
  {
    "uniprot": "A0A0N4U6M8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6M8"
  },
  {
    "uniprot": "A0A0N4UPY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPY0"
  },
  {
    "uniprot": "A0A158Q5L1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5L1"
  },
  {
    "uniprot": "A0A0N4ULH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULH3"
  },
  {
    "uniprot": "A0A158Q3K9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3K9"
  },
  {
    "uniprot": "A0A158Q5U2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5U2"
  },
  {
    "uniprot": "A0A158Q4J6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4J6"
  },
  {
    "uniprot": "A0A0N4UHV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHV7"
  },
  {
    "uniprot": "A0A0N4UA75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA75"
  },
  {
    "uniprot": "A0A0N4U3D6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3D6"
  },
  {
    "uniprot": "A0A0N4UHE8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHE8"
  },
  {
    "uniprot": "A0A158Q3D2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3D2"
  },
  {
    "uniprot": "A0A0N4UQB6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQB6"
  },
  {
    "uniprot": "A0A0N4U8D5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8D5"
  },
  {
    "uniprot": "A0A0N4UIS6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIS6"
  },
  {
    "uniprot": "A0A3P7Q889",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q889"
  },
  {
    "uniprot": "A0A0N4UN88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN88"
  },
  {
    "uniprot": "A0A3P7P8K9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P8K9"
  },
  {
    "uniprot": "A0A0N4U7K6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7K6"
  },
  {
    "uniprot": "A0A0N4UM96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM96"
  },
  {
    "uniprot": "A0A0N4UE66",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE66"
  },
  {
    "uniprot": "A0A0N4UM11",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM11"
  },
  {
    "uniprot": "A0A158Q643",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q643"
  },
  {
    "uniprot": "A0A0N4U4X7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4X7"
  },
  {
    "uniprot": "A0A3P7SWR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SWR2"
  },
  {
    "uniprot": "A0A0N4UEJ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEJ8"
  },
  {
    "uniprot": "A0A158Q6A3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6A3"
  },
  {
    "uniprot": "A0A0N4U8B5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8B5"
  },
  {
    "uniprot": "A0A3P7SAP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SAP1"
  },
  {
    "uniprot": "A0A0N4U0J0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0J0"
  },
  {
    "uniprot": "A0A0N4UJF2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJF2"
  },
  {
    "uniprot": "A0A0N4U716",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U716"
  },
  {
    "uniprot": "A0A0N4ULA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULA1"
  },
  {
    "uniprot": "A0A0N4U2G5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2G5"
  },
  {
    "uniprot": "A0A0N4U472",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U472"
  },
  {
    "uniprot": "A0A0N4UJ14",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ14"
  },
  {
    "uniprot": "A0A0N4UPM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPM2"
  },
  {
    "uniprot": "A0A0N4U525",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U525"
  },
  {
    "uniprot": "A0A0N4U6K7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6K7"
  },
  {
    "uniprot": "A0A0N4ULH5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULH5"
  },
  {
    "uniprot": "A0A0N4UGT1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGT1"
  },
  {
    "uniprot": "A0A0N4UIT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIT6"
  },
  {
    "uniprot": "A0A0N4UCK9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCK9"
  },
  {
    "uniprot": "A0A158Q4U6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4U6"
  },
  {
    "uniprot": "A0A0N4UBN1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBN1"
  },
  {
    "uniprot": "A0A0N4URL5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URL5"
  },
  {
    "uniprot": "A0A3P7SRY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SRY7"
  },
  {
    "uniprot": "A0A158Q3E7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3E7"
  },
  {
    "uniprot": "A0A0N4U8M6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8M6"
  },
  {
    "uniprot": "A0A158Q4T6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4T6"
  },
  {
    "uniprot": "A0A0N4U425",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U425"
  },
  {
    "uniprot": "A0A0N4U453",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U453"
  },
  {
    "uniprot": "A0A0N4UIR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIR6"
  },
  {
    "uniprot": "A0A0N4UNK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNK0"
  },
  {
    "uniprot": "A0A0N4UEA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEA0"
  },
  {
    "uniprot": "A0A0N4U7P3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7P3"
  },
  {
    "uniprot": "A0A0N4U2B1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2B1"
  },
  {
    "uniprot": "A0A0N4U0U7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0U7"
  },
  {
    "uniprot": "A0A0N4U4X0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4X0"
  },
  {
    "uniprot": "A0A0N4UGE6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGE6"
  },
  {
    "uniprot": "A0A0N4UQV5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQV5"
  },
  {
    "uniprot": "A0A0N4ULM3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULM3"
  },
  {
    "uniprot": "A0A0N4U7E6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7E6"
  },
  {
    "uniprot": "A0A0N4UJP2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJP2"
  },
  {
    "uniprot": "A0A0N4U2B4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2B4"
  },
  {
    "uniprot": "A0A158Q4L7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4L7"
  },
  {
    "uniprot": "A0A0N4U1Z2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Z2"
  },
  {
    "uniprot": "A0A0N4UKJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKJ7"
  },
  {
    "uniprot": "A0A0N4UGI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGI1"
  },
  {
    "uniprot": "A0A0N4UEA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEA2"
  },
  {
    "uniprot": "A0A0N4UMK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMK3"
  },
  {
    "uniprot": "A0A0N4UMM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMM2"
  },
  {
    "uniprot": "A0A0N4U960",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U960"
  },
  {
    "uniprot": "A0A0N4U2H1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2H1"
  },
  {
    "uniprot": "A0A0N4UC21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC21"
  },
  {
    "uniprot": "A0A158Q3Z0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Z0"
  },
  {
    "uniprot": "A0A0N4UBL4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBL4"
  },
  {
    "uniprot": "A0A3P7Q7K0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q7K0"
  },
  {
    "uniprot": "A0A0N4UD26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD26"
  },
  {
    "uniprot": "A0A0N4UMJ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMJ8"
  },
  {
    "uniprot": "A0A0N4UIY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIY1"
  },
  {
    "uniprot": "A0A0N4U9P2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9P2"
  },
  {
    "uniprot": "A0A158Q3E1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3E1"
  },
  {
    "uniprot": "A0A158Q5Y9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Y9"
  },
  {
    "uniprot": "A0A0N4U4N8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4N8"
  },
  {
    "uniprot": "A0A0N4UKU0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKU0"
  },
  {
    "uniprot": "A0A0N4UB60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB60"
  },
  {
    "uniprot": "A0A0N4UAJ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAJ8"
  },
  {
    "uniprot": "A0A0N4U1G2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1G2"
  },
  {
    "uniprot": "A0A0N4UBH0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBH0"
  },
  {
    "uniprot": "A0A0N4UHN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHN3"
  },
  {
    "uniprot": "A0A158Q690",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q690"
  },
  {
    "uniprot": "A0A3P7PI34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PI34"
  },
  {
    "uniprot": "A0A0N4U8F7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8F7"
  },
  {
    "uniprot": "A0A0N4UJT2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJT2"
  },
  {
    "uniprot": "A0A3P7T1C4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T1C4"
  },
  {
    "uniprot": "A0A0N4U2L1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2L1"
  },
  {
    "uniprot": "A0A0N4UJ90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ90"
  },
  {
    "uniprot": "A0A0N4UQ92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ92"
  },
  {
    "uniprot": "A0A0N4UMQ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMQ2"
  },
  {
    "uniprot": "A0A0N4URH8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URH8"
  },
  {
    "uniprot": "A0A0N4UJA3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJA3"
  },
  {
    "uniprot": "A0A0N4UIQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIQ4"
  },
  {
    "uniprot": "A0A0N4UE91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE91"
  },
  {
    "uniprot": "A0A0N4UDK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDK2"
  },
  {
    "uniprot": "A0A0N4UPN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPN0"
  },
  {
    "uniprot": "A0A0N4U885",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U885"
  },
  {
    "uniprot": "A0A0N4UDW7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDW7"
  },
  {
    "uniprot": "A0A158Q6H9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6H9"
  },
  {
    "uniprot": "A0A0N4UK42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK42"
  },
  {
    "uniprot": "A0A0N4UIW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIW3"
  },
  {
    "uniprot": "A0A0N4UQ39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ39"
  },
  {
    "uniprot": "A0A0N4UGC8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGC8"
  },
  {
    "uniprot": "A0A3P7PQY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQY2"
  },
  {
    "uniprot": "A0A0N4UCR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCR4"
  },
  {
    "uniprot": "A0A0N4USB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USB3"
  },
  {
    "uniprot": "A0A0N4UPZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPZ5"
  },
  {
    "uniprot": "A0A0N4UDV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDV6"
  },
  {
    "uniprot": "A0A0N4U3K7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3K7"
  },
  {
    "uniprot": "A0A158Q4V9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4V9"
  },
  {
    "uniprot": "A0A0N4UGH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGH9"
  },
  {
    "uniprot": "A0A0N4U0W5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0W5"
  },
  {
    "uniprot": "A0A158Q5A0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5A0"
  },
  {
    "uniprot": "A0A0N4UC98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC98"
  },
  {
    "uniprot": "A0A0N4UB14",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB14"
  },
  {
    "uniprot": "A0A0N4UQN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQN0"
  },
  {
    "uniprot": "A0A158Q4U5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4U5"
  },
  {
    "uniprot": "A0A3P7Q197",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q197"
  },
  {
    "uniprot": "A0A0N4UEI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEI1"
  },
  {
    "uniprot": "A0A0N4URZ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URZ9"
  },
  {
    "uniprot": "A0A0N4U7D0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7D0"
  },
  {
    "uniprot": "A0A0N4UFU8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFU8"
  },
  {
    "uniprot": "A0A0N4UID8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UID8"
  },
  {
    "uniprot": "A0A0N4U368",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U368"
  },
  {
    "uniprot": "A0A158Q4W2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4W2"
  },
  {
    "uniprot": "A0A0N4UPV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPV7"
  },
  {
    "uniprot": "A0A0N4UGN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGN2"
  },
  {
    "uniprot": "A0A158Q345",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q345"
  },
  {
    "uniprot": "A0A0N4UH51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH51"
  },
  {
    "uniprot": "A0A0N4U6G4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6G4"
  },
  {
    "uniprot": "A0A0N4U5L6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5L6"
  },
  {
    "uniprot": "A0A0N4UFA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFA6"
  },
  {
    "uniprot": "A0A0N4U1J4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1J4"
  },
  {
    "uniprot": "A0A0N4U4C8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4C8"
  },
  {
    "uniprot": "A0A0N4UAS2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAS2"
  },
  {
    "uniprot": "A0A0N4U0U6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0U6"
  },
  {
    "uniprot": "A0A0N4UMD5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMD5"
  },
  {
    "uniprot": "A0A0N4UR24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR24"
  },
  {
    "uniprot": "A0A0N4UCE6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCE6"
  },
  {
    "uniprot": "A0A0N4U5E8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5E8"
  },
  {
    "uniprot": "A0A0N4UBV0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBV0"
  },
  {
    "uniprot": "A0A0N4U239",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U239"
  },
  {
    "uniprot": "A0A0N4UM16",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM16"
  },
  {
    "uniprot": "A0A0N4UC04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC04"
  },
  {
    "uniprot": "A0A0N4UG24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG24"
  },
  {
    "uniprot": "A0A158Q3K6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3K6"
  },
  {
    "uniprot": "A0A0N4UJN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJN0"
  },
  {
    "uniprot": "A0A0N4UKK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKK0"
  },
  {
    "uniprot": "A0A0N4UJM5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJM5"
  },
  {
    "uniprot": "A0A0N4UKF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKF9"
  },
  {
    "uniprot": "A0A0N4ULW1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULW1"
  },
  {
    "uniprot": "A0A3P7QBQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QBQ4"
  },
  {
    "uniprot": "A0A0N4UHH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHH6"
  },
  {
    "uniprot": "A0A0N4U258",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U258"
  },
  {
    "uniprot": "A0A0N4UHQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHQ6"
  },
  {
    "uniprot": "A0A0N4U2D1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2D1"
  },
  {
    "uniprot": "A0A0N4URQ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URQ9"
  },
  {
    "uniprot": "A0A0N4UPY9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPY9"
  },
  {
    "uniprot": "A0A158Q562",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q562"
  },
  {
    "uniprot": "A0A0N4UF84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF84"
  },
  {
    "uniprot": "A0A0N4UNV5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNV5"
  },
  {
    "uniprot": "A0A0N4ULQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULQ7"
  },
  {
    "uniprot": "A0A0N4U5U4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5U4"
  },
  {
    "uniprot": "A0A0N4UCC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCC4"
  },
  {
    "uniprot": "A0A0N4URJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URJ7"
  },
  {
    "uniprot": "A0A0N4UFY9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFY9"
  },
  {
    "uniprot": "A0A0N4UR13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR13"
  },
  {
    "uniprot": "A0A0N4UGW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGW3"
  },
  {
    "uniprot": "A0A0N4U6R8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6R8"
  },
  {
    "uniprot": "A0A0N4U497",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U497"
  },
  {
    "uniprot": "A0A0N4UEQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEQ1"
  },
  {
    "uniprot": "A0A3P7QUL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QUL0"
  },
  {
    "uniprot": "A0A0N4UMX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMX9"
  },
  {
    "uniprot": "A0A0N4U6Y9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Y9"
  },
  {
    "uniprot": "A0A0N4UIE0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIE0"
  },
  {
    "uniprot": "A0A158Q6F0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6F0"
  },
  {
    "uniprot": "A0A0N4U0E9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0E9"
  },
  {
    "uniprot": "A0A3P7T014",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T014"
  },
  {
    "uniprot": "A0A0N4UAN7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAN7"
  },
  {
    "uniprot": "A0A0N4U443",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U443"
  },
  {
    "uniprot": "A0A0N4UN20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN20"
  },
  {
    "uniprot": "A0A0N4UKB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKB5"
  },
  {
    "uniprot": "A0A0N4UHH8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHH8"
  },
  {
    "uniprot": "A0A0N4UAL1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAL1"
  },
  {
    "uniprot": "A0A0N4U132",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U132"
  },
  {
    "uniprot": "A0A0N4UQE1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQE1"
  },
  {
    "uniprot": "A0A0N4UQX3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQX3"
  },
  {
    "uniprot": "A0A158Q6A5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6A5"
  },
  {
    "uniprot": "A0A0N4U3X6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3X6"
  },
  {
    "uniprot": "A0A0N4UCV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCV3"
  },
  {
    "uniprot": "A0A0N4UF80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF80"
  },
  {
    "uniprot": "A0A158Q4Z6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Z6"
  },
  {
    "uniprot": "A0A3P7Q348",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q348"
  },
  {
    "uniprot": "A0A3P7Q3A3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q3A3"
  },
  {
    "uniprot": "A0A0N4U6I3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6I3"
  },
  {
    "uniprot": "A0A0N4UCU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCU3"
  },
  {
    "uniprot": "A0A3P7PP80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PP80"
  },
  {
    "uniprot": "A0A0N4UL05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL05"
  },
  {
    "uniprot": "A0A0N4U1A5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1A5"
  },
  {
    "uniprot": "A0A158Q6H6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6H6"
  },
  {
    "uniprot": "A0A0N4U1P6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1P6"
  },
  {
    "uniprot": "A0A0N4U4Y2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Y2"
  },
  {
    "uniprot": "A0A158Q2I2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2I2"
  },
  {
    "uniprot": "A0A0N4UDH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDH2"
  },
  {
    "uniprot": "A0A158Q364",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q364"
  },
  {
    "uniprot": "A0A0N4UBB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBB7"
  },
  {
    "uniprot": "A0A3P7QL21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QL21"
  },
  {
    "uniprot": "A0A0N4UIJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIJ4"
  },
  {
    "uniprot": "A0A0N4U3J4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3J4"
  },
  {
    "uniprot": "A0A0N4UG41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG41"
  },
  {
    "uniprot": "A0A158Q3L0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3L0"
  },
  {
    "uniprot": "A0A3P7T0J8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T0J8"
  },
  {
    "uniprot": "A0A158Q4F0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4F0"
  },
  {
    "uniprot": "A0A0N4UNG1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNG1"
  },
  {
    "uniprot": "A0A0N4UDU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDU3"
  },
  {
    "uniprot": "A0A0N4U616",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U616"
  },
  {
    "uniprot": "A0A0N4UE10",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE10"
  },
  {
    "uniprot": "A0A158Q5K3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5K3"
  },
  {
    "uniprot": "A0A158Q366",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q366"
  },
  {
    "uniprot": "A0A0N4UGV4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGV4"
  },
  {
    "uniprot": "A0A0N4U477",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U477"
  },
  {
    "uniprot": "A0A158Q5A9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5A9"
  },
  {
    "uniprot": "A0A0N4UAL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAL3"
  },
  {
    "uniprot": "A0A0N4U9Q4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Q4"
  },
  {
    "uniprot": "A0A0N4UBS4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBS4"
  },
  {
    "uniprot": "A0A0N4UHK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHK0"
  },
  {
    "uniprot": "A0A0N4UQ56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ56"
  },
  {
    "uniprot": "A0A0N4USC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USC4"
  },
  {
    "uniprot": "A0A158Q5F9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5F9"
  },
  {
    "uniprot": "A0A3P7PSC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PSC4"
  },
  {
    "uniprot": "A0A0N4UIZ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIZ2"
  },
  {
    "uniprot": "A0A158Q4V6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4V6"
  },
  {
    "uniprot": "A0A0N4UG72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG72"
  },
  {
    "uniprot": "A0A0N4U7D7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7D7"
  },
  {
    "uniprot": "A0A0N4UMS0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMS0"
  },
  {
    "uniprot": "A0A0N4U5M2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5M2"
  },
  {
    "uniprot": "A0A3P7PI35",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PI35"
  },
  {
    "uniprot": "A0A158Q4K8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4K8"
  },
  {
    "uniprot": "A0A158Q2S7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2S7"
  },
  {
    "uniprot": "A0A0N4UH95",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH95"
  },
  {
    "uniprot": "A0A0N4U7F2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7F2"
  },
  {
    "uniprot": "A0A0N4UNL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNL3"
  },
  {
    "uniprot": "A0A0N4ULH0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULH0"
  },
  {
    "uniprot": "A0A0N4UNM4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNM4"
  },
  {
    "uniprot": "A0A0N4UEH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEH9"
  },
  {
    "uniprot": "A0A158Q6I7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6I7"
  },
  {
    "uniprot": "A0A0N4UH48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH48"
  },
  {
    "uniprot": "A0A0N4UJB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJB1"
  },
  {
    "uniprot": "A0A0N4UHW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHW0"
  },
  {
    "uniprot": "A0A158Q4Q9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Q9"
  },
  {
    "uniprot": "A0A0N4U9I0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9I0"
  },
  {
    "uniprot": "A0A0N4UFF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFF5"
  },
  {
    "uniprot": "A0A0N4U9W0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9W0"
  },
  {
    "uniprot": "A0A0N4UAQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAQ5"
  },
  {
    "uniprot": "A0A0N4UDA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDA8"
  },
  {
    "uniprot": "A0A0N4UR83",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR83"
  },
  {
    "uniprot": "A0A3P7QTL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QTL9"
  },
  {
    "uniprot": "A0A0N4U1X0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1X0"
  },
  {
    "uniprot": "A0A3P7SFE1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SFE1"
  },
  {
    "uniprot": "A0A0N4UHW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHW3"
  },
  {
    "uniprot": "A0A3P7PN55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PN55"
  },
  {
    "uniprot": "A0A158Q674",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q674"
  },
  {
    "uniprot": "A0A0N4U8B7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8B7"
  },
  {
    "uniprot": "A0A0N4UDT5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDT5"
  },
  {
    "uniprot": "A0A0N4U1G7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1G7"
  },
  {
    "uniprot": "A0A0N4UFQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFQ7"
  },
  {
    "uniprot": "A0A0N4UIC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIC3"
  },
  {
    "uniprot": "A0A3P7PQI6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQI6"
  },
  {
    "uniprot": "A0A0N4UI36",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI36"
  },
  {
    "uniprot": "A0A0N4UBA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBA2"
  },
  {
    "uniprot": "A0A0N4UQ16",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ16"
  },
  {
    "uniprot": "A0A0N4ULV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULV3"
  },
  {
    "uniprot": "A0A0N4UJ43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ43"
  },
  {
    "uniprot": "A0A0N4UJJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJJ1"
  },
  {
    "uniprot": "A0A3P7SRA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SRA6"
  },
  {
    "uniprot": "A0A158Q499",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q499"
  },
  {
    "uniprot": "A0A0N4U3A9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3A9"
  },
  {
    "uniprot": "A0A0N4U1N7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1N7"
  },
  {
    "uniprot": "A0A3P7SIG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SIG0"
  },
  {
    "uniprot": "A0A0N4UP36",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP36"
  },
  {
    "uniprot": "A0A0N4UCH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCH3"
  },
  {
    "uniprot": "A0A3P7PPK7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPK7"
  },
  {
    "uniprot": "A0A0N4UG96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG96"
  },
  {
    "uniprot": "A0A0N4ULC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULC7"
  },
  {
    "uniprot": "A0A0N4U3K8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3K8"
  },
  {
    "uniprot": "A0A0N4UJU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJU4"
  },
  {
    "uniprot": "A0A0N4U9K8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9K8"
  },
  {
    "uniprot": "A0A0N4UF20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF20"
  },
  {
    "uniprot": "A0A0N4URW1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URW1"
  },
  {
    "uniprot": "A0A0N4U8B9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8B9"
  },
  {
    "uniprot": "A0A158Q3I6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3I6"
  },
  {
    "uniprot": "A0A0N4U2A4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2A4"
  },
  {
    "uniprot": "A0A0N4URU8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URU8"
  },
  {
    "uniprot": "A0A0N4UN91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN91"
  },
  {
    "uniprot": "A0A0N4UL54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL54"
  },
  {
    "uniprot": "A0A0N4U2J4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2J4"
  },
  {
    "uniprot": "A0A3P7S8C4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S8C4"
  },
  {
    "uniprot": "A0A158Q604",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q604"
  },
  {
    "uniprot": "A0A0N4U4D3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4D3"
  },
  {
    "uniprot": "A0A0N4UDI8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDI8"
  },
  {
    "uniprot": "A0A0N4UMG1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMG1"
  },
  {
    "uniprot": "A0A0N4UB80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB80"
  },
  {
    "uniprot": "A0A0N4UH09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH09"
  },
  {
    "uniprot": "A0A0N4U430",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U430"
  },
  {
    "uniprot": "A0A0N4UD82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD82"
  },
  {
    "uniprot": "A0A158Q492",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q492"
  },
  {
    "uniprot": "A0A0N4UDG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDG3"
  },
  {
    "uniprot": "A0A0N4U8W0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8W0"
  },
  {
    "uniprot": "A0A0N4UJ28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ28"
  },
  {
    "uniprot": "A0A0N4U8T6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8T6"
  },
  {
    "uniprot": "A0A0N4UHN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHN6"
  },
  {
    "uniprot": "A0A0N4U6U1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6U1"
  },
  {
    "uniprot": "A0A0N4U2F2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2F2"
  },
  {
    "uniprot": "A0A3P7PU78",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PU78"
  },
  {
    "uniprot": "A0A0N4UHF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHF9"
  },
  {
    "uniprot": "A0A0N4U8I0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8I0"
  },
  {
    "uniprot": "A0A0N4ULG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULG0"
  },
  {
    "uniprot": "A0A0N4U4N1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4N1"
  },
  {
    "uniprot": "A0A0N4U2T8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2T8"
  },
  {
    "uniprot": "A0A0N4ULG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULG6"
  },
  {
    "uniprot": "A0A0N4UHB6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHB6"
  },
  {
    "uniprot": "A0A0N4UEN5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEN5"
  },
  {
    "uniprot": "A0A0N4U5D9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5D9"
  },
  {
    "uniprot": "A0A0N4U2R4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2R4"
  },
  {
    "uniprot": "A0A158Q3Q1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Q1"
  },
  {
    "uniprot": "A0A0N4UF21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF21"
  },
  {
    "uniprot": "A0A0N4URW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URW8"
  },
  {
    "uniprot": "A0A0N4UR69",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR69"
  },
  {
    "uniprot": "A0A0N4U402",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U402"
  },
  {
    "uniprot": "A0A3P7Q4R5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q4R5"
  },
  {
    "uniprot": "A0A0N4UEK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEK6"
  },
  {
    "uniprot": "A0A0N4ULH8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULH8"
  },
  {
    "uniprot": "A0A0N4U4C7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4C7"
  },
  {
    "uniprot": "A0A158Q4M0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4M0"
  },
  {
    "uniprot": "A0A0N4U634",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U634"
  },
  {
    "uniprot": "A0A0N4UMF8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMF8"
  },
  {
    "uniprot": "A0A0N4UKU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKU4"
  },
  {
    "uniprot": "A0A0N4U5Z7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Z7"
  },
  {
    "uniprot": "A0A0N4UKP5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKP5"
  },
  {
    "uniprot": "A0A0N4U5W3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5W3"
  },
  {
    "uniprot": "A0A0N4UCT4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCT4"
  },
  {
    "uniprot": "A0A0N4U1U4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1U4"
  },
  {
    "uniprot": "A0A0N4UIZ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIZ0"
  },
  {
    "uniprot": "A0A0N4UPZ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPZ7"
  },
  {
    "uniprot": "A0A0N4U585",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U585"
  },
  {
    "uniprot": "A0A0N4UM25",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM25"
  },
  {
    "uniprot": "A0A3P7SHN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SHN2"
  },
  {
    "uniprot": "A0A0N4UNL6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNL6"
  },
  {
    "uniprot": "A0A0N4UP55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP55"
  },
  {
    "uniprot": "A0A3P7SPT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SPT6"
  },
  {
    "uniprot": "A0A0N4UK65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK65"
  },
  {
    "uniprot": "A0A158Q3T7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3T7"
  },
  {
    "uniprot": "A0A0N4UPV9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPV9"
  },
  {
    "uniprot": "A0A3P7SAJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SAJ2"
  },
  {
    "uniprot": "A0A0N4UMN7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMN7"
  },
  {
    "uniprot": "A0A0N4U0X6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0X6"
  },
  {
    "uniprot": "A0A0N4UA54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA54"
  },
  {
    "uniprot": "A0A0N4U3Y1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Y1"
  },
  {
    "uniprot": "A0A0N4UMT0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMT0"
  },
  {
    "uniprot": "A0A0N4U1K8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1K8"
  },
  {
    "uniprot": "A0A0N4UQT3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQT3"
  },
  {
    "uniprot": "A0A0N4U4M7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4M7"
  },
  {
    "uniprot": "A0A0N4UN64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN64"
  },
  {
    "uniprot": "A0A0N4UBD8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBD8"
  },
  {
    "uniprot": "A0A0N4U0L3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0L3"
  },
  {
    "uniprot": "A0A0N4USC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USC0"
  },
  {
    "uniprot": "A0A0N4UQK7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQK7"
  },
  {
    "uniprot": "A0A0N4UQE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQE3"
  },
  {
    "uniprot": "A0A0N4UQ55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ55"
  },
  {
    "uniprot": "A0A3P7SV54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SV54"
  },
  {
    "uniprot": "A0A0N4U3D0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3D0"
  },
  {
    "uniprot": "A0A158Q5C1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5C1"
  },
  {
    "uniprot": "A0A0N4UAQ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAQ8"
  },
  {
    "uniprot": "A0A0N4UP48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP48"
  },
  {
    "uniprot": "A0A0N4UF81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF81"
  },
  {
    "uniprot": "A0A3P7SKZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SKZ1"
  },
  {
    "uniprot": "A0A0N4UH68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH68"
  },
  {
    "uniprot": "A0A0N4UM64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM64"
  },
  {
    "uniprot": "A0A0N4UN65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN65"
  },
  {
    "uniprot": "A0A0N4UBP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBP6"
  },
  {
    "uniprot": "A0A0N4UJW9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJW9"
  },
  {
    "uniprot": "A0A0N4UGK7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGK7"
  },
  {
    "uniprot": "A0A0N4U515",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U515"
  },
  {
    "uniprot": "A0A0N4UL68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL68"
  },
  {
    "uniprot": "A0A0N4U7R1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7R1"
  },
  {
    "uniprot": "A0A0N4UP64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP64"
  },
  {
    "uniprot": "A0A158Q4Q6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Q6"
  },
  {
    "uniprot": "A0A0N4UCX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCX5"
  },
  {
    "uniprot": "A0A158Q4K0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4K0"
  },
  {
    "uniprot": "A0A0N4UNH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNH9"
  },
  {
    "uniprot": "A0A0N4U6W5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6W5"
  },
  {
    "uniprot": "A0A0N4U0J3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0J3"
  },
  {
    "uniprot": "A0A158Q587",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q587"
  },
  {
    "uniprot": "A0A3P7SNK4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SNK4"
  },
  {
    "uniprot": "A0A158Q4A0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4A0"
  },
  {
    "uniprot": "A0A0N4URD5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URD5"
  },
  {
    "uniprot": "A0A0N4UGA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGA4"
  },
  {
    "uniprot": "A0A3P7QH33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QH33"
  },
  {
    "uniprot": "A0A158Q3F3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3F3"
  },
  {
    "uniprot": "A0A0N4UHL6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHL6"
  },
  {
    "uniprot": "A0A0N4UIJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIJ1"
  },
  {
    "uniprot": "A0A0N4U9A6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9A6"
  },
  {
    "uniprot": "A0A0N4UMV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMV7"
  },
  {
    "uniprot": "A0A0N4UA87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA87"
  },
  {
    "uniprot": "A0A0N4UKG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKG0"
  },
  {
    "uniprot": "A0A158Q3X8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3X8"
  },
  {
    "uniprot": "A0A0N4UL80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL80"
  },
  {
    "uniprot": "A0A0N4UGJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGJ1"
  },
  {
    "uniprot": "A0A0N4UPH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPH4"
  },
  {
    "uniprot": "A0A0N4UDZ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDZ2"
  },
  {
    "uniprot": "A0A0N4URK8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URK8"
  },
  {
    "uniprot": "A0A0N4U6W9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6W9"
  },
  {
    "uniprot": "A0A0N4UC50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC50"
  },
  {
    "uniprot": "A0A3P7PI01",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PI01"
  },
  {
    "uniprot": "A0A0N4UBT2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBT2"
  },
  {
    "uniprot": "A0A0N4UQW1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQW1"
  },
  {
    "uniprot": "A0A0N4UJ83",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ83"
  },
  {
    "uniprot": "A0A0N4UMK9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMK9"
  },
  {
    "uniprot": "A0A0N4UP54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP54"
  },
  {
    "uniprot": "A0A0N4UR78",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR78"
  },
  {
    "uniprot": "A0A0N4UPF7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPF7"
  },
  {
    "uniprot": "A0A0N4U0J6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0J6"
  },
  {
    "uniprot": "A0A0N4UCM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCM0"
  },
  {
    "uniprot": "A0A158Q6A4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6A4"
  },
  {
    "uniprot": "A0A158Q2W2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2W2"
  },
  {
    "uniprot": "A0A0N4UEF4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEF4"
  },
  {
    "uniprot": "A0A0N4UMI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMI0"
  },
  {
    "uniprot": "A0A0N4U643",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U643"
  },
  {
    "uniprot": "A0A3P7PT04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PT04"
  },
  {
    "uniprot": "A0A0N4U4V1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4V1"
  },
  {
    "uniprot": "A0A0N4U4E4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4E4"
  },
  {
    "uniprot": "A0A0N4UFK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFK6"
  },
  {
    "uniprot": "A0A0N4UDD6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDD6"
  },
  {
    "uniprot": "A0A3P7QSB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QSB7"
  },
  {
    "uniprot": "A0A0N4UMF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMF5"
  },
  {
    "uniprot": "A0A0N4U2V1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2V1"
  },
  {
    "uniprot": "A0A0N4U828",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U828"
  },
  {
    "uniprot": "A0A0N4U0T1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0T1"
  },
  {
    "uniprot": "A0A0N4UH39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH39"
  },
  {
    "uniprot": "A0A0N4UCB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCB2"
  },
  {
    "uniprot": "A0A0N4U3I4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3I4"
  },
  {
    "uniprot": "A0A0N4U457",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U457"
  },
  {
    "uniprot": "A0A158Q3A1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3A1"
  },
  {
    "uniprot": "A0A3P7PZ90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PZ90"
  },
  {
    "uniprot": "A0A158Q463",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q463"
  },
  {
    "uniprot": "A0A3P7S786",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S786"
  },
  {
    "uniprot": "A0A0N4U0L7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0L7"
  },
  {
    "uniprot": "A0A0N4U720",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U720"
  },
  {
    "uniprot": "A0A3P7SS68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SS68"
  },
  {
    "uniprot": "A0A0N4U277",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U277"
  },
  {
    "uniprot": "A0A3P7PQ29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQ29"
  },
  {
    "uniprot": "A0A0N4UC78",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC78"
  },
  {
    "uniprot": "A0A0N4UIV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIV3"
  },
  {
    "uniprot": "A0A0N4UF23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF23"
  },
  {
    "uniprot": "A0A0N4U4D6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4D6"
  },
  {
    "uniprot": "A0A0N4UCM7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCM7"
  },
  {
    "uniprot": "A0A0N4UDM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDM0"
  },
  {
    "uniprot": "A0A0N4UA23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA23"
  },
  {
    "uniprot": "A0A158Q3J9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3J9"
  },
  {
    "uniprot": "A0A158Q3M9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3M9"
  },
  {
    "uniprot": "A0A0N4U8V1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8V1"
  },
  {
    "uniprot": "A0A0N4UBI4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBI4"
  },
  {
    "uniprot": "A0A0N4UMN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMN9"
  },
  {
    "uniprot": "A0A0N4U167",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U167"
  },
  {
    "uniprot": "A0A0N4UDP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDP1"
  },
  {
    "uniprot": "A0A0N4U2P5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2P5"
  },
  {
    "uniprot": "A0A0N4US70",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US70"
  },
  {
    "uniprot": "A0A0N4UJL4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJL4"
  },
  {
    "uniprot": "A0A0N4UGR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGR9"
  },
  {
    "uniprot": "A0A3P7SFD3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SFD3"
  },
  {
    "uniprot": "A0A0N4UHD7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHD7"
  },
  {
    "uniprot": "A0A0N4U4S9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4S9"
  },
  {
    "uniprot": "A0A3P7PH24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PH24"
  },
  {
    "uniprot": "A0A0N4UBC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBC3"
  },
  {
    "uniprot": "A0A0N4U7B6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7B6"
  },
  {
    "uniprot": "A0A3P7PNP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PNP3"
  },
  {
    "uniprot": "A0A0N4U921",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U921"
  },
  {
    "uniprot": "A0A0N4U6B0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6B0"
  },
  {
    "uniprot": "A0A0N4UBC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBC2"
  },
  {
    "uniprot": "A0A0N4UR51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR51"
  },
  {
    "uniprot": "A0A158Q6G2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6G2"
  },
  {
    "uniprot": "A0A0N4U4N5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4N5"
  },
  {
    "uniprot": "A0A0N4UET5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UET5"
  },
  {
    "uniprot": "A0A158Q5T7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5T7"
  },
  {
    "uniprot": "A0A0N4UDP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDP9"
  },
  {
    "uniprot": "A0A0N4U8J8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8J8"
  },
  {
    "uniprot": "A0A0N4U7I4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7I4"
  },
  {
    "uniprot": "A0A3P7SVZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SVZ5"
  },
  {
    "uniprot": "A0A0N4U479",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U479"
  },
  {
    "uniprot": "A0A0N4UKU1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKU1"
  },
  {
    "uniprot": "A0A0N4UDW9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDW9"
  },
  {
    "uniprot": "A0A0N4U185",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U185"
  },
  {
    "uniprot": "A0A0N4UN32",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN32"
  },
  {
    "uniprot": "A0A0N4U218",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U218"
  },
  {
    "uniprot": "A0A0N4U5C7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5C7"
  },
  {
    "uniprot": "A0A0N4U3A5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3A5"
  },
  {
    "uniprot": "A0A0N4U6B4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6B4"
  },
  {
    "uniprot": "A0A3P7S9B4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S9B4"
  },
  {
    "uniprot": "A0A0N4U737",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U737"
  },
  {
    "uniprot": "A0A0N4UQ68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ68"
  },
  {
    "uniprot": "A0A0N4UJ23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ23"
  },
  {
    "uniprot": "A0A158Q2Q8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Q8"
  },
  {
    "uniprot": "A0A3P7STH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7STH4"
  },
  {
    "uniprot": "A0A0N4U6Y2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Y2"
  },
  {
    "uniprot": "A0A0N4UCC8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCC8"
  },
  {
    "uniprot": "A0A3P7QFS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QFS9"
  },
  {
    "uniprot": "A0A0N4UAC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAC9"
  },
  {
    "uniprot": "A0A0N4UFV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFV3"
  },
  {
    "uniprot": "A0A0N4U6W4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6W4"
  },
  {
    "uniprot": "A0A0N4UB21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB21"
  },
  {
    "uniprot": "A0A0N4UEH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEH6"
  },
  {
    "uniprot": "A0A0N4U7B2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7B2"
  },
  {
    "uniprot": "A0A0N4UIH8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIH8"
  },
  {
    "uniprot": "A0A0N4UH57",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH57"
  },
  {
    "uniprot": "A0A3P7PWR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PWR1"
  },
  {
    "uniprot": "A0A0N4UCW1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCW1"
  },
  {
    "uniprot": "A0A3P7SC45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SC45"
  },
  {
    "uniprot": "A0A0N4UIR8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIR8"
  },
  {
    "uniprot": "A0A0N4UA31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA31"
  },
  {
    "uniprot": "A0A0N4U324",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U324"
  },
  {
    "uniprot": "A0A0N4UFH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFH3"
  },
  {
    "uniprot": "A0A0N4U289",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U289"
  },
  {
    "uniprot": "A0A0N4U2R7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2R7"
  },
  {
    "uniprot": "A0A0N4U8B4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8B4"
  },
  {
    "uniprot": "A0A0N4UEQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEQ3"
  },
  {
    "uniprot": "A0A0N4U3Z1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Z1"
  },
  {
    "uniprot": "A0A0N4UAF6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAF6"
  },
  {
    "uniprot": "A0A158Q2V9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2V9"
  },
  {
    "uniprot": "A0A0N4UBN8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBN8"
  },
  {
    "uniprot": "A0A158Q5N9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5N9"
  },
  {
    "uniprot": "A0A158Q5F4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5F4"
  },
  {
    "uniprot": "A0A0N4UBT8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBT8"
  },
  {
    "uniprot": "A0A0N4U1M2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1M2"
  },
  {
    "uniprot": "A0A0N4US84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US84"
  },
  {
    "uniprot": "A0A0N4UM57",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM57"
  },
  {
    "uniprot": "A0A0N4UBG8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBG8"
  },
  {
    "uniprot": "A0A0N4UAM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAM2"
  },
  {
    "uniprot": "A0A0N4UDG8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDG8"
  },
  {
    "uniprot": "A0A0N4UJI7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJI7"
  },
  {
    "uniprot": "A0A3P7SF34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SF34"
  },
  {
    "uniprot": "A0A0N4U444",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U444"
  },
  {
    "uniprot": "A0A0N4U9G1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9G1"
  },
  {
    "uniprot": "A0A0N4ULE8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULE8"
  },
  {
    "uniprot": "A0A158Q4W6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4W6"
  },
  {
    "uniprot": "A0A0N4U2K0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2K0"
  },
  {
    "uniprot": "A0A0N4UH79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH79"
  },
  {
    "uniprot": "A0A0N4UQE8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQE8"
  },
  {
    "uniprot": "A0A0N4UMR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMR4"
  },
  {
    "uniprot": "A0A0N4U4T7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4T7"
  },
  {
    "uniprot": "A0A0N4UCX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCX1"
  },
  {
    "uniprot": "A0A0N4UF02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF02"
  },
  {
    "uniprot": "A0A0N4U696",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U696"
  },
  {
    "uniprot": "A0A0N4UKS7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKS7"
  },
  {
    "uniprot": "A0A158Q518",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q518"
  },
  {
    "uniprot": "A0A3P7Q2H2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q2H2"
  },
  {
    "uniprot": "A0A0N4U789",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U789"
  },
  {
    "uniprot": "A0A0N4UQJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQJ2"
  },
  {
    "uniprot": "A0A158Q5F7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5F7"
  },
  {
    "uniprot": "A0A0N4U4M6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4M6"
  },
  {
    "uniprot": "A0A0N4URF6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URF6"
  },
  {
    "uniprot": "A0A158Q6M5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6M5"
  },
  {
    "uniprot": "A0A0N4UP09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP09"
  },
  {
    "uniprot": "A0A0N4U5Y1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Y1"
  },
  {
    "uniprot": "A0A3P7SWK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SWK3"
  },
  {
    "uniprot": "A0A158Q4X0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4X0"
  },
  {
    "uniprot": "A0A0N4UQ22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ22"
  },
  {
    "uniprot": "A0A158Q2Y0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Y0"
  },
  {
    "uniprot": "A0A0N4UJD4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJD4"
  },
  {
    "uniprot": "A0A0N4UK62",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK62"
  },
  {
    "uniprot": "A0A3P7PY95",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PY95"
  },
  {
    "uniprot": "A0A158Q680",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q680"
  },
  {
    "uniprot": "A0A0N4UGU9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGU9"
  },
  {
    "uniprot": "A0A0N4UNC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNC6"
  },
  {
    "uniprot": "A0A158Q688",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q688"
  },
  {
    "uniprot": "A0A0N4U0M1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0M1"
  },
  {
    "uniprot": "A0A0N4UJY3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJY3"
  },
  {
    "uniprot": "A0A0N4U7P8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7P8"
  },
  {
    "uniprot": "A0A0N4UPQ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPQ0"
  },
  {
    "uniprot": "A0A0N4U8S8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8S8"
  },
  {
    "uniprot": "A0A0N4ULS8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULS8"
  },
  {
    "uniprot": "A0A3P7SWZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SWZ4"
  },
  {
    "uniprot": "A0A0N4U9F6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9F6"
  },
  {
    "uniprot": "A0A0N4UM05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM05"
  },
  {
    "uniprot": "A0A0N4UAX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAX1"
  },
  {
    "uniprot": "A0A0N4U3P5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3P5"
  },
  {
    "uniprot": "A0A3P7P4A5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P4A5"
  },
  {
    "uniprot": "A0A0N4UEH5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEH5"
  },
  {
    "uniprot": "A0A0N4UJY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJY1"
  },
  {
    "uniprot": "A0A3P7PXD4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PXD4"
  },
  {
    "uniprot": "A0A0N4U9S4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9S4"
  },
  {
    "uniprot": "A0A3P7SD84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SD84"
  },
  {
    "uniprot": "A0A0N4UIP8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIP8"
  },
  {
    "uniprot": "A0A0N4U9U0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9U0"
  },
  {
    "uniprot": "A0A0N4US85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US85"
  },
  {
    "uniprot": "A0A0N4UCQ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCQ0"
  },
  {
    "uniprot": "A0A0N4UD15",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD15"
  },
  {
    "uniprot": "A0A0N4U2J5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2J5"
  },
  {
    "uniprot": "A0A158Q2P4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2P4"
  },
  {
    "uniprot": "A0A0N4U4T4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4T4"
  },
  {
    "uniprot": "A0A0N4UAX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAX8"
  },
  {
    "uniprot": "A0A0N4U9K4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9K4"
  },
  {
    "uniprot": "A0A0N4UNZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNZ1"
  },
  {
    "uniprot": "A0A0N4U2X0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2X0"
  },
  {
    "uniprot": "A0A0N4UBH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBH9"
  },
  {
    "uniprot": "A0A0N4ULW5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULW5"
  },
  {
    "uniprot": "A0A0N4UMG5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMG5"
  },
  {
    "uniprot": "A0A0N4UMD2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMD2"
  },
  {
    "uniprot": "A0A0N4U0Z4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Z4"
  },
  {
    "uniprot": "A0A3P7QD50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QD50"
  },
  {
    "uniprot": "A0A0N4ULU8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULU8"
  },
  {
    "uniprot": "A0A0N4U0N2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0N2"
  },
  {
    "uniprot": "A0A0N4UCG1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCG1"
  },
  {
    "uniprot": "A0A3P7Q3X8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q3X8"
  },
  {
    "uniprot": "A0A0N4UAJ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAJ5"
  },
  {
    "uniprot": "A0A3P7PQV0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQV0"
  },
  {
    "uniprot": "A0A0N4US32",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US32"
  },
  {
    "uniprot": "A0A0N4UK84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK84"
  },
  {
    "uniprot": "A0A3P7P8P1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P8P1"
  },
  {
    "uniprot": "A0A0N4UEW9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEW9"
  },
  {
    "uniprot": "A0A0N4UFT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFT6"
  },
  {
    "uniprot": "A0A0N4U1M0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1M0"
  },
  {
    "uniprot": "A0A3P7TAD1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TAD1"
  },
  {
    "uniprot": "A0A0N4U496",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U496"
  },
  {
    "uniprot": "A0A3P7SUN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SUN2"
  },
  {
    "uniprot": "A0A3P7Q6W1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q6W1"
  },
  {
    "uniprot": "A0A0N4UA11",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA11"
  },
  {
    "uniprot": "A0A3P7PR13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PR13"
  },
  {
    "uniprot": "A0A0N4U8U7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8U7"
  },
  {
    "uniprot": "A0A0N4U285",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U285"
  },
  {
    "uniprot": "A0A0N4UPV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPV8"
  },
  {
    "uniprot": "A0A3P7R095",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7R095"
  },
  {
    "uniprot": "A0A158Q2J4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2J4"
  },
  {
    "uniprot": "A0A0N4UEB8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEB8"
  },
  {
    "uniprot": "A0A0N4UCQ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCQ9"
  },
  {
    "uniprot": "A0A0N4UEL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEL8"
  },
  {
    "uniprot": "A0A158Q2Z7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Z7"
  },
  {
    "uniprot": "A0A3P7QAF1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QAF1"
  },
  {
    "uniprot": "A0A0N4UNW6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNW6"
  },
  {
    "uniprot": "A0A0N4UH44",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH44"
  },
  {
    "uniprot": "A0A0N4UA08",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA08"
  },
  {
    "uniprot": "A0A0N4U8Y2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Y2"
  },
  {
    "uniprot": "A0A0N4U2C4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2C4"
  },
  {
    "uniprot": "A0A0N4UNX0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNX0"
  },
  {
    "uniprot": "A0A0N4U5V9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5V9"
  },
  {
    "uniprot": "A0A0N4ULY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULY4"
  },
  {
    "uniprot": "A0A0N4U260",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U260"
  },
  {
    "uniprot": "A0A158Q5J6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5J6"
  },
  {
    "uniprot": "A0A0N4UI09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI09"
  },
  {
    "uniprot": "A0A0N4U1H5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1H5"
  },
  {
    "uniprot": "A0A0N4UC75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC75"
  },
  {
    "uniprot": "A0A0N4URT7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URT7"
  },
  {
    "uniprot": "A0A3P7Q9A6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q9A6"
  },
  {
    "uniprot": "A0A158Q2T8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2T8"
  },
  {
    "uniprot": "A0A0N4U764",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U764"
  },
  {
    "uniprot": "A0A0N4UKP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKP4"
  },
  {
    "uniprot": "A0A0N4U5T1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5T1"
  },
  {
    "uniprot": "A0A0N4UQ02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ02"
  },
  {
    "uniprot": "A0A0N4UJ20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ20"
  },
  {
    "uniprot": "A0A0N4UNZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNZ4"
  },
  {
    "uniprot": "A0A0N4U7R5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7R5"
  },
  {
    "uniprot": "A0A0N4URJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URJ1"
  },
  {
    "uniprot": "A0A0N4U344",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U344"
  },
  {
    "uniprot": "A0A158Q6E1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6E1"
  },
  {
    "uniprot": "A0A3P7PQB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQB0"
  },
  {
    "uniprot": "A0A158Q3B3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3B3"
  },
  {
    "uniprot": "A0A0N4U7L9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7L9"
  },
  {
    "uniprot": "A0A0N4U8Y0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Y0"
  },
  {
    "uniprot": "A0A158Q436",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q436"
  },
  {
    "uniprot": "A0A0N4UJE8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJE8"
  },
  {
    "uniprot": "A0A158Q389",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q389"
  },
  {
    "uniprot": "A0A3P7PAI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PAI1"
  },
  {
    "uniprot": "A0A0N4UCZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCZ5"
  },
  {
    "uniprot": "A0A0N4UAH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAH9"
  },
  {
    "uniprot": "A0A0N4UGI4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGI4"
  },
  {
    "uniprot": "A0A3P7QE87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QE87"
  },
  {
    "uniprot": "A0A3P7SS07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SS07"
  },
  {
    "uniprot": "A0A0N4U8J9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8J9"
  },
  {
    "uniprot": "A0A0N4UPX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPX8"
  },
  {
    "uniprot": "A0A0N4U133",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U133"
  },
  {
    "uniprot": "A0A0N4UCA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCA1"
  },
  {
    "uniprot": "A0A0N4UND0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UND0"
  },
  {
    "uniprot": "A0A0N4UI33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI33"
  },
  {
    "uniprot": "A0A0N4U778",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U778"
  },
  {
    "uniprot": "A0A0N4UB52",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB52"
  },
  {
    "uniprot": "A0A0N4U583",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U583"
  },
  {
    "uniprot": "A0A0N4U1S8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1S8"
  },
  {
    "uniprot": "A0A0N4UQX2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQX2"
  },
  {
    "uniprot": "A0A0N4U471",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U471"
  },
  {
    "uniprot": "A0A0N4U192",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U192"
  },
  {
    "uniprot": "A0A0N4ULG5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULG5"
  },
  {
    "uniprot": "A0A0N4U2Q9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Q9"
  },
  {
    "uniprot": "A0A0N4UQF1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQF1"
  },
  {
    "uniprot": "A0A0N4UIQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIQ7"
  },
  {
    "uniprot": "A0A0N4UG60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG60"
  },
  {
    "uniprot": "A0A0N4UA76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA76"
  },
  {
    "uniprot": "A0A0N4U7G5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7G5"
  },
  {
    "uniprot": "A0A0N4UN29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN29"
  },
  {
    "uniprot": "A0A3P7SF78",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SF78"
  },
  {
    "uniprot": "A0A0N4UMX3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMX3"
  },
  {
    "uniprot": "A0A158Q349",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q349"
  },
  {
    "uniprot": "A0A0N4ULE9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULE9"
  },
  {
    "uniprot": "A0A0N4U5F0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5F0"
  },
  {
    "uniprot": "A0A3P7PS70",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PS70"
  },
  {
    "uniprot": "A0A0N4U3N1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3N1"
  },
  {
    "uniprot": "A0A158Q5Z8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Z8"
  },
  {
    "uniprot": "A0A0N4URM4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URM4"
  },
  {
    "uniprot": "A0A0N4U9K5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9K5"
  },
  {
    "uniprot": "A0A0N4ULE4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULE4"
  },
  {
    "uniprot": "A0A158Q2Z0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Z0"
  },
  {
    "uniprot": "A0A0N4UCW6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCW6"
  },
  {
    "uniprot": "A0A0N4UI95",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI95"
  },
  {
    "uniprot": "A0A0N4U6B9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6B9"
  },
  {
    "uniprot": "A0A0N4U541",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U541"
  },
  {
    "uniprot": "A0A3P7Q1C0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q1C0"
  },
  {
    "uniprot": "A0A0N4UG77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG77"
  },
  {
    "uniprot": "A0A0N4U3V6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3V6"
  },
  {
    "uniprot": "A0A158Q2X6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2X6"
  },
  {
    "uniprot": "A0A158Q6J1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6J1"
  },
  {
    "uniprot": "A0A0N4UFZ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFZ3"
  },
  {
    "uniprot": "A0A0N4U8W5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8W5"
  },
  {
    "uniprot": "A0A0N4UNT0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNT0"
  },
  {
    "uniprot": "A0A0N4UNP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNP6"
  },
  {
    "uniprot": "A0A0N4UIG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIG6"
  },
  {
    "uniprot": "A0A0N4U1H7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1H7"
  },
  {
    "uniprot": "A0A0N4ULI5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULI5"
  },
  {
    "uniprot": "A0A0N4U7G8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7G8"
  },
  {
    "uniprot": "A0A0N4UNC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNC4"
  },
  {
    "uniprot": "A0A0N4UID1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UID1"
  },
  {
    "uniprot": "A0A0N4U7P9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7P9"
  },
  {
    "uniprot": "A0A158Q5C9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5C9"
  },
  {
    "uniprot": "A0A0N4UDD5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDD5"
  },
  {
    "uniprot": "A0A0N4UKM1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKM1"
  },
  {
    "uniprot": "A0A0N4U7Z1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Z1"
  },
  {
    "uniprot": "A0A0N4UDE8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDE8"
  },
  {
    "uniprot": "A0A0N4U3G6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3G6"
  },
  {
    "uniprot": "A0A3P7PJL5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PJL5"
  },
  {
    "uniprot": "A0A0N4UFJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFJ4"
  },
  {
    "uniprot": "A0A3P7QR08",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QR08"
  },
  {
    "uniprot": "A0A0N4U7U3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7U3"
  },
  {
    "uniprot": "A0A158Q4C2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4C2"
  },
  {
    "uniprot": "A0A0N4U4B6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4B6"
  },
  {
    "uniprot": "A0A0N4U0K9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0K9"
  },
  {
    "uniprot": "A0A0N4ULR5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULR5"
  },
  {
    "uniprot": "A0A0N4UGY3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGY3"
  },
  {
    "uniprot": "A0A0N4UK93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK93"
  },
  {
    "uniprot": "A0A0N4U5R1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5R1"
  },
  {
    "uniprot": "A0A0N4URN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URN6"
  },
  {
    "uniprot": "A0A0N4U6J8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6J8"
  },
  {
    "uniprot": "A0A0N4U7H8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7H8"
  },
  {
    "uniprot": "A0A158Q5B6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5B6"
  },
  {
    "uniprot": "A0A0N4UKD1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKD1"
  },
  {
    "uniprot": "A0A158Q2G9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2G9"
  },
  {
    "uniprot": "A0A0N4UC62",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC62"
  },
  {
    "uniprot": "A0A0N4UC63",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC63"
  },
  {
    "uniprot": "A0A3P7Q543",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q543"
  },
  {
    "uniprot": "A0A0N4UAG5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAG5"
  },
  {
    "uniprot": "A0A0N4U1C5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1C5"
  },
  {
    "uniprot": "A0A0N4UG52",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG52"
  },
  {
    "uniprot": "A0A0N4UGY9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGY9"
  },
  {
    "uniprot": "A0A0N4UHL1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHL1"
  },
  {
    "uniprot": "A0A0N4U526",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U526"
  },
  {
    "uniprot": "A0A158Q2M4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2M4"
  },
  {
    "uniprot": "A0A3P7PYP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PYP7"
  },
  {
    "uniprot": "A0A0N4UKS1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKS1"
  },
  {
    "uniprot": "A0A3P7Q0Y2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q0Y2"
  },
  {
    "uniprot": "A0A0N4UP57",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP57"
  },
  {
    "uniprot": "A0A0N4UAG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAG9"
  },
  {
    "uniprot": "A0A0N4U5C6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5C6"
  },
  {
    "uniprot": "A0A0N4U8U6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8U6"
  },
  {
    "uniprot": "A0A0N4UMN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMN2"
  },
  {
    "uniprot": "A0A0N4UAA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAA2"
  },
  {
    "uniprot": "A0A158Q5D5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5D5"
  },
  {
    "uniprot": "A0A0N4U2F4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2F4"
  },
  {
    "uniprot": "A0A0N4U3C4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3C4"
  },
  {
    "uniprot": "A0A0N4U369",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U369"
  },
  {
    "uniprot": "A0A0N4UJX2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJX2"
  },
  {
    "uniprot": "A0A0N4URM1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URM1"
  },
  {
    "uniprot": "A0A0N4U5B9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5B9"
  },
  {
    "uniprot": "A0A0N4UKI4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKI4"
  },
  {
    "uniprot": "A0A0N4UNB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNB9"
  },
  {
    "uniprot": "A0A0N4U238",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U238"
  },
  {
    "uniprot": "A0A0N4U4N9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4N9"
  },
  {
    "uniprot": "A0A3P7PP33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PP33"
  },
  {
    "uniprot": "A0A0N4UA50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA50"
  },
  {
    "uniprot": "A0A0N4U445",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U445"
  },
  {
    "uniprot": "A0A0N4UGE1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGE1"
  },
  {
    "uniprot": "A0A0N4UNM5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNM5"
  },
  {
    "uniprot": "A0A0N4UM45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM45"
  },
  {
    "uniprot": "A0A0N4UI50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI50"
  },
  {
    "uniprot": "A0A0N4U3Y4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Y4"
  },
  {
    "uniprot": "A0A0N4UFJ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFJ3"
  },
  {
    "uniprot": "A0A0N4UD00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD00"
  },
  {
    "uniprot": "A0A0N4UE73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE73"
  },
  {
    "uniprot": "A0A0N4U4F2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4F2"
  },
  {
    "uniprot": "A0A3P7Q338",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q338"
  },
  {
    "uniprot": "A0A0N4U9L5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9L5"
  },
  {
    "uniprot": "A0A158Q2I0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2I0"
  },
  {
    "uniprot": "A0A0N4UPE5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPE5"
  },
  {
    "uniprot": "A0A0N4UDY9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDY9"
  },
  {
    "uniprot": "A0A0N4UGA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGA5"
  },
  {
    "uniprot": "A0A0N4UF27",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF27"
  },
  {
    "uniprot": "A0A0N4UD50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD50"
  },
  {
    "uniprot": "A0A0N4UJT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJT6"
  },
  {
    "uniprot": "A0A0N4UMA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMA0"
  },
  {
    "uniprot": "A0A0N4U5P0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5P0"
  },
  {
    "uniprot": "A0A0N4U8F8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8F8"
  },
  {
    "uniprot": "A0A0N4UI41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI41"
  },
  {
    "uniprot": "A0A0N4ULF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULF9"
  },
  {
    "uniprot": "A0A0N4U310",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U310"
  },
  {
    "uniprot": "A0A0N4U223",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U223"
  },
  {
    "uniprot": "A0A3P7SX30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SX30"
  },
  {
    "uniprot": "A0A0N4U7L7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7L7"
  },
  {
    "uniprot": "A0A0N4UI39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI39"
  },
  {
    "uniprot": "A0A0N4U1T4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1T4"
  },
  {
    "uniprot": "A0A0N4U336",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U336"
  },
  {
    "uniprot": "A0A0N4UKM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKM0"
  },
  {
    "uniprot": "A0A0N4U8R8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8R8"
  },
  {
    "uniprot": "A0A0N4UF68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF68"
  },
  {
    "uniprot": "A0A158Q4Y4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Y4"
  },
  {
    "uniprot": "A0A3P7Q561",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q561"
  },
  {
    "uniprot": "A0A0N4UGF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGF5"
  },
  {
    "uniprot": "A0A0N4URL6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URL6"
  },
  {
    "uniprot": "A0A0N4U750",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U750"
  },
  {
    "uniprot": "A0A0N4U1P4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1P4"
  },
  {
    "uniprot": "A0A0N4U874",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U874"
  },
  {
    "uniprot": "A0A0N4U366",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U366"
  },
  {
    "uniprot": "A0A0N4URG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URG2"
  },
  {
    "uniprot": "A0A0N4U7M7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7M7"
  },
  {
    "uniprot": "A0A0N4UHJ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHJ0"
  },
  {
    "uniprot": "A0A158Q3F6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3F6"
  },
  {
    "uniprot": "A0A158Q536",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q536"
  },
  {
    "uniprot": "A0A0N4U926",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U926"
  },
  {
    "uniprot": "A0A0N4UAA7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAA7"
  },
  {
    "uniprot": "A0A0N4U1S9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1S9"
  },
  {
    "uniprot": "A0A0N4UP46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP46"
  },
  {
    "uniprot": "A0A0N4U4M4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4M4"
  },
  {
    "uniprot": "A0A0N4UHJ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHJ5"
  },
  {
    "uniprot": "A0A0N4UEF6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEF6"
  },
  {
    "uniprot": "A0A0N4U297",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U297"
  },
  {
    "uniprot": "A0A158Q4P7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4P7"
  },
  {
    "uniprot": "A0A0N4ULU5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULU5"
  },
  {
    "uniprot": "A0A0N4UFP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFP1"
  },
  {
    "uniprot": "A0A3P7SQL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SQL0"
  },
  {
    "uniprot": "A0A0N4ULJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULJ9"
  },
  {
    "uniprot": "A0A0N4UB67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB67"
  },
  {
    "uniprot": "A0A0N4U9K9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9K9"
  },
  {
    "uniprot": "A0A0N4UIS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIS9"
  },
  {
    "uniprot": "A0A0N4U1T6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1T6"
  },
  {
    "uniprot": "A0A158Q2Y6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Y6"
  },
  {
    "uniprot": "A0A0N4URL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URL0"
  },
  {
    "uniprot": "A0A0N4U0Z8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Z8"
  },
  {
    "uniprot": "A0A0N4U3L7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3L7"
  },
  {
    "uniprot": "A0A0N4UI79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI79"
  },
  {
    "uniprot": "A0A158Q4K5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4K5"
  },
  {
    "uniprot": "A0A0N4U788",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U788"
  },
  {
    "uniprot": "A0A158Q355",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q355"
  },
  {
    "uniprot": "A0A0N4UAH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAH3"
  },
  {
    "uniprot": "A0A158Q318",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q318"
  },
  {
    "uniprot": "A0A0N4US86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US86"
  },
  {
    "uniprot": "A0A0N4UCK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCK1"
  },
  {
    "uniprot": "A0A158Q4N5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4N5"
  },
  {
    "uniprot": "A0A158Q4C9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4C9"
  },
  {
    "uniprot": "A0A0N4U8Y7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Y7"
  },
  {
    "uniprot": "A0A158Q412",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q412"
  },
  {
    "uniprot": "A0A3P7PSJ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PSJ3"
  },
  {
    "uniprot": "A0A0N4U991",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U991"
  },
  {
    "uniprot": "A0A0N4UCQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCQ6"
  },
  {
    "uniprot": "A0A0N4U2N0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2N0"
  },
  {
    "uniprot": "A0A0N4U790",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U790"
  },
  {
    "uniprot": "A0A158Q6N7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6N7"
  },
  {
    "uniprot": "A0A0N4UFQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFQ4"
  },
  {
    "uniprot": "A0A0N4U275",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U275"
  },
  {
    "uniprot": "A0A0N4ULU9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULU9"
  },
  {
    "uniprot": "A0A0N4UJE7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJE7"
  },
  {
    "uniprot": "A0A0N4UD95",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD95"
  },
  {
    "uniprot": "A0A0N4UFT7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFT7"
  },
  {
    "uniprot": "A0A0N4UPR8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPR8"
  },
  {
    "uniprot": "A0A158Q5Q1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Q1"
  },
  {
    "uniprot": "A0A0N4UA12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA12"
  },
  {
    "uniprot": "A0A158Q672",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q672"
  },
  {
    "uniprot": "A0A0N4U4W4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4W4"
  },
  {
    "uniprot": "A0A0N4US12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US12"
  },
  {
    "uniprot": "A0A0N4U0Z9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Z9"
  },
  {
    "uniprot": "A0A0N4U542",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U542"
  },
  {
    "uniprot": "A0A158Q3B7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3B7"
  },
  {
    "uniprot": "A0A0N4UHR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHR1"
  },
  {
    "uniprot": "A0A0N4UDK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDK3"
  },
  {
    "uniprot": "A0A0N4U320",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U320"
  },
  {
    "uniprot": "A0A0N4U4S8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4S8"
  },
  {
    "uniprot": "A0A0N4UCC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCC7"
  },
  {
    "uniprot": "A0A0N4UDY6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDY6"
  },
  {
    "uniprot": "A0A0N4U0L2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0L2"
  },
  {
    "uniprot": "A0A0N4U3D3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3D3"
  },
  {
    "uniprot": "A0A0N4U9V6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9V6"
  },
  {
    "uniprot": "A0A0N4U9H5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9H5"
  },
  {
    "uniprot": "A0A0N4U6P5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6P5"
  },
  {
    "uniprot": "A0A158Q4K6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4K6"
  },
  {
    "uniprot": "A0A0N4UIW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIW0"
  },
  {
    "uniprot": "A0A0N4UG82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG82"
  },
  {
    "uniprot": "A0A0N4U5W1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5W1"
  },
  {
    "uniprot": "A0A0N4U0P2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0P2"
  },
  {
    "uniprot": "A0A0N4U1E2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1E2"
  },
  {
    "uniprot": "A0A158Q3G7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3G7"
  },
  {
    "uniprot": "A0A0N4UP58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP58"
  },
  {
    "uniprot": "A0A0N4UHJ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHJ6"
  },
  {
    "uniprot": "A0A0N4U3M3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3M3"
  },
  {
    "uniprot": "A0A0N4U6G7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6G7"
  },
  {
    "uniprot": "A0A3P7T3Z5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T3Z5"
  },
  {
    "uniprot": "A0A0N4U2L5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2L5"
  },
  {
    "uniprot": "A0A0N4UBP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBP7"
  },
  {
    "uniprot": "A0A0N4ULC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULC5"
  },
  {
    "uniprot": "A0A158Q6A0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6A0"
  },
  {
    "uniprot": "A0A158Q2I6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2I6"
  },
  {
    "uniprot": "A0A158Q2S3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2S3"
  },
  {
    "uniprot": "A0A0N4U1I0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1I0"
  },
  {
    "uniprot": "A0A0N4UL31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL31"
  },
  {
    "uniprot": "A0A0N4UGW7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGW7"
  },
  {
    "uniprot": "A0A158Q4A9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4A9"
  },
  {
    "uniprot": "A0A0N4UGJ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGJ3"
  },
  {
    "uniprot": "A0A0N4US35",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US35"
  },
  {
    "uniprot": "A0A158Q3K2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3K2"
  },
  {
    "uniprot": "A0A0N4UHY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHY0"
  },
  {
    "uniprot": "A0A0N4UF87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF87"
  },
  {
    "uniprot": "A0A0N4UP76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP76"
  },
  {
    "uniprot": "A0A0N4ULM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULM0"
  },
  {
    "uniprot": "A0A0N4UC15",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC15"
  },
  {
    "uniprot": "A0A0N4U6Y0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Y0"
  },
  {
    "uniprot": "A0A0N4UNC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNC3"
  },
  {
    "uniprot": "A0A0N4U3V2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3V2"
  },
  {
    "uniprot": "A0A0N4UEV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEV8"
  },
  {
    "uniprot": "A0A0N4U102",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U102"
  },
  {
    "uniprot": "A0A0N4U819",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U819"
  },
  {
    "uniprot": "A0A0N4UIJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIJ2"
  },
  {
    "uniprot": "A0A3P7Q4K4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q4K4"
  },
  {
    "uniprot": "A0A0N4UF18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF18"
  },
  {
    "uniprot": "A0A158Q4U4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4U4"
  },
  {
    "uniprot": "A0A0N4U446",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U446"
  },
  {
    "uniprot": "A0A3P7QMM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QMM8"
  },
  {
    "uniprot": "A0A0N4UIB6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIB6"
  },
  {
    "uniprot": "A0A0N4U2N8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2N8"
  },
  {
    "uniprot": "A0A0N4UCK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCK5"
  },
  {
    "uniprot": "A0A0N4UNX6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNX6"
  },
  {
    "uniprot": "A0A3P7QIV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QIV7"
  },
  {
    "uniprot": "A0A0N4UBU5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBU5"
  },
  {
    "uniprot": "A0A0N4UGX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGX9"
  },
  {
    "uniprot": "A0A0N4U5X7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5X7"
  },
  {
    "uniprot": "A0A0N4UNJ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNJ8"
  },
  {
    "uniprot": "A0A0N4U6W3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6W3"
  },
  {
    "uniprot": "A0A3P7SF24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SF24"
  },
  {
    "uniprot": "A0A0N4U131",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U131"
  },
  {
    "uniprot": "A0A0N4U9E2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9E2"
  },
  {
    "uniprot": "A0A0N4UHG5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHG5"
  },
  {
    "uniprot": "A0A0N4U4V2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4V2"
  },
  {
    "uniprot": "A0A0N4UM87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM87"
  },
  {
    "uniprot": "A0A0N4U8H7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8H7"
  },
  {
    "uniprot": "A0A0N4UN17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN17"
  },
  {
    "uniprot": "A0A0N4UFN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFN6"
  },
  {
    "uniprot": "A0A0N4UNN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNN3"
  },
  {
    "uniprot": "A0A3P7SI76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SI76"
  },
  {
    "uniprot": "A0A158Q641",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q641"
  },
  {
    "uniprot": "A0A0N4UK33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK33"
  },
  {
    "uniprot": "A0A0N4ULZ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULZ6"
  },
  {
    "uniprot": "A0A158Q682",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q682"
  },
  {
    "uniprot": "A0A0N4URA3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URA3"
  },
  {
    "uniprot": "A0A0N4U800",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U800"
  },
  {
    "uniprot": "A0A0N4U6X5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6X5"
  },
  {
    "uniprot": "A0A0N4U9Y2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Y2"
  },
  {
    "uniprot": "A0A0N4URV2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URV2"
  },
  {
    "uniprot": "A0A0N4UQ75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ75"
  },
  {
    "uniprot": "A0A0N4U489",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U489"
  },
  {
    "uniprot": "A0A0N4U9R4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9R4"
  },
  {
    "uniprot": "A0A158Q335",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q335"
  },
  {
    "uniprot": "A0A0N4U0F8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0F8"
  },
  {
    "uniprot": "A0A0N4US00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US00"
  },
  {
    "uniprot": "A0A0N4URB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URB9"
  },
  {
    "uniprot": "A0A0N4UN33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN33"
  },
  {
    "uniprot": "A0A0N4UMR5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMR5"
  },
  {
    "uniprot": "A0A3P7PF12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PF12"
  },
  {
    "uniprot": "A0A0N4U426",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U426"
  },
  {
    "uniprot": "A0A0N4UPD2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPD2"
  },
  {
    "uniprot": "A0A0N4UQV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQV3"
  },
  {
    "uniprot": "A0A158Q3G5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3G5"
  },
  {
    "uniprot": "A0A0N4UIM5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIM5"
  },
  {
    "uniprot": "A0A0N4UDS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDS3"
  },
  {
    "uniprot": "A0A0N4UMD4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMD4"
  },
  {
    "uniprot": "A0A0N4UKS2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKS2"
  },
  {
    "uniprot": "A0A0N4UB09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB09"
  },
  {
    "uniprot": "A0A0N4UD14",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD14"
  },
  {
    "uniprot": "A0A0N4UQ37",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ37"
  },
  {
    "uniprot": "A0A0N4U993",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U993"
  },
  {
    "uniprot": "A0A0N4U9X5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9X5"
  },
  {
    "uniprot": "A0A0N4UD36",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD36"
  },
  {
    "uniprot": "A0A0N4UFZ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFZ9"
  },
  {
    "uniprot": "A0A158Q4M7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4M7"
  },
  {
    "uniprot": "A0A0N4UA90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA90"
  },
  {
    "uniprot": "A0A0N4UF10",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF10"
  },
  {
    "uniprot": "A0A0N4UL13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL13"
  },
  {
    "uniprot": "A0A0N4U3B8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3B8"
  },
  {
    "uniprot": "A0A0N4U173",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U173"
  },
  {
    "uniprot": "A0A0N4U9F5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9F5"
  },
  {
    "uniprot": "A0A0N4U5L3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5L3"
  },
  {
    "uniprot": "A0A3P7TEV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TEV8"
  },
  {
    "uniprot": "A0A0N4UQG5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQG5"
  },
  {
    "uniprot": "A0A0N4UGP8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGP8"
  },
  {
    "uniprot": "A0A0N4UFW1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFW1"
  },
  {
    "uniprot": "A0A0N4ULZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULZ1"
  },
  {
    "uniprot": "A0A0N4U3F5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3F5"
  },
  {
    "uniprot": "A0A0N4UBI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBI3"
  },
  {
    "uniprot": "A0A0N4UG05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG05"
  },
  {
    "uniprot": "A0A158Q511",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q511"
  },
  {
    "uniprot": "A0A0N4U7X4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7X4"
  },
  {
    "uniprot": "A0A0N4U0Q6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Q6"
  },
  {
    "uniprot": "A0A0N4U9V8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9V8"
  },
  {
    "uniprot": "A0A3P7SL30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SL30"
  },
  {
    "uniprot": "A0A158Q4C0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4C0"
  },
  {
    "uniprot": "A0A0N4U485",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U485"
  },
  {
    "uniprot": "A0A0N4UJB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJB3"
  },
  {
    "uniprot": "A0A0N4UHY6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHY6"
  },
  {
    "uniprot": "A0A0N4U7Q5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Q5"
  },
  {
    "uniprot": "A0A0N4UMG7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMG7"
  },
  {
    "uniprot": "A0A0N4U970",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U970"
  },
  {
    "uniprot": "A0A0N4U8P7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8P7"
  },
  {
    "uniprot": "A0A0N4U5U9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5U9"
  },
  {
    "uniprot": "A0A158Q5P6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5P6"
  },
  {
    "uniprot": "A0A0N4UJM6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJM6"
  },
  {
    "uniprot": "A0A0N4UJI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJI9"
  },
  {
    "uniprot": "A0A0N4UNE1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNE1"
  },
  {
    "uniprot": "A0A3P7SS29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SS29"
  },
  {
    "uniprot": "A0A158Q530",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q530"
  },
  {
    "uniprot": "A0A0N4U1V0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1V0"
  },
  {
    "uniprot": "A0A0N4ULZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULZ5"
  },
  {
    "uniprot": "A0A0N4UL66",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL66"
  },
  {
    "uniprot": "A0A0N4UHF6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHF6"
  },
  {
    "uniprot": "A0A0N4UMU8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMU8"
  },
  {
    "uniprot": "A0A158Q3F0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3F0"
  },
  {
    "uniprot": "A0A0N4UCX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCX7"
  },
  {
    "uniprot": "A0A0N4UPH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPH2"
  },
  {
    "uniprot": "A0A0N4UN21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN21"
  },
  {
    "uniprot": "A0A0N4U8P8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8P8"
  },
  {
    "uniprot": "A0A0N4UNI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNI1"
  },
  {
    "uniprot": "A0A0N4UNY8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNY8"
  },
  {
    "uniprot": "A0A0N4UAU9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAU9"
  },
  {
    "uniprot": "A0A0N4UBG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBG4"
  },
  {
    "uniprot": "A0A0N4UKM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKM8"
  },
  {
    "uniprot": "A0A0N4UJA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJA6"
  },
  {
    "uniprot": "A0A0N4UD89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD89"
  },
  {
    "uniprot": "A0A0N4U1C2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1C2"
  },
  {
    "uniprot": "A0A0N4UE06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE06"
  },
  {
    "uniprot": "A0A0N4U4W1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4W1"
  },
  {
    "uniprot": "A0A0N4U987",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U987"
  },
  {
    "uniprot": "A0A0N4U2G9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2G9"
  },
  {
    "uniprot": "A0A0N4UEE5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEE5"
  },
  {
    "uniprot": "A0A0N4U337",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U337"
  },
  {
    "uniprot": "A0A0N4UIQ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIQ0"
  },
  {
    "uniprot": "A0A0N4UM80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM80"
  },
  {
    "uniprot": "A0A0N4UBX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBX7"
  },
  {
    "uniprot": "A0A0N4U627",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U627"
  },
  {
    "uniprot": "A0A0N4U3H8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3H8"
  },
  {
    "uniprot": "A0A0N4UA38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA38"
  },
  {
    "uniprot": "A0A3P7PWL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PWL3"
  },
  {
    "uniprot": "A0A0N4UDF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDF3"
  },
  {
    "uniprot": "A0A0N4U3I3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3I3"
  },
  {
    "uniprot": "A0A3P7QRM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QRM0"
  },
  {
    "uniprot": "A0A0N4U3J9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3J9"
  },
  {
    "uniprot": "A0A158Q3D5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3D5"
  },
  {
    "uniprot": "A0A0N4UBM7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBM7"
  },
  {
    "uniprot": "A0A0N4U9V4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9V4"
  },
  {
    "uniprot": "A0A0N4UCF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCF5"
  },
  {
    "uniprot": "A0A3P7Q4Q8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q4Q8"
  },
  {
    "uniprot": "A0A0N4UE49",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE49"
  },
  {
    "uniprot": "A0A0N4U7E4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7E4"
  },
  {
    "uniprot": "A0A158Q2M8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2M8"
  },
  {
    "uniprot": "A0A0N4UMR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMR7"
  },
  {
    "uniprot": "A0A0N4UQV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQV7"
  },
  {
    "uniprot": "A0A0N4U409",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U409"
  },
  {
    "uniprot": "A0A0N4U0R8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0R8"
  },
  {
    "uniprot": "A0A0N4UKF0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKF0"
  },
  {
    "uniprot": "A0A0N4U4W8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4W8"
  },
  {
    "uniprot": "A0A0N4U1R8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1R8"
  },
  {
    "uniprot": "A0A0N4U0D5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0D5"
  },
  {
    "uniprot": "A0A3P7PXL5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PXL5"
  },
  {
    "uniprot": "A0A0N4UH71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH71"
  },
  {
    "uniprot": "A0A0N4UDW6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDW6"
  },
  {
    "uniprot": "A0A0N4U5L7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5L7"
  },
  {
    "uniprot": "A0A0N4U3Y7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Y7"
  },
  {
    "uniprot": "A0A0N4U303",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U303"
  },
  {
    "uniprot": "A0A3P7SN48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SN48"
  },
  {
    "uniprot": "A0A0N4UPJ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPJ5"
  },
  {
    "uniprot": "A0A0N4US74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US74"
  },
  {
    "uniprot": "A0A0N4U7K4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7K4"
  },
  {
    "uniprot": "A0A0N4UM17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM17"
  },
  {
    "uniprot": "A0A0N4UBF4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBF4"
  },
  {
    "uniprot": "A0A0N4U933",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U933"
  },
  {
    "uniprot": "A0A0N4UKP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKP3"
  },
  {
    "uniprot": "A0A0N4UAE6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAE6"
  },
  {
    "uniprot": "A0A0N4UPD1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPD1"
  },
  {
    "uniprot": "A0A0N4UHP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHP7"
  },
  {
    "uniprot": "A0A0N4UF06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF06"
  },
  {
    "uniprot": "A0A0N4U1I2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1I2"
  },
  {
    "uniprot": "A0A0N4U4K1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4K1"
  },
  {
    "uniprot": "A0A158Q568",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q568"
  },
  {
    "uniprot": "A0A3P7PEQ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PEQ0"
  },
  {
    "uniprot": "A0A0N4U847",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U847"
  },
  {
    "uniprot": "A0A0N4UCX6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCX6"
  },
  {
    "uniprot": "A0A0N4UKX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKX5"
  },
  {
    "uniprot": "A0A0N4U5E1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5E1"
  },
  {
    "uniprot": "A0A158Q395",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q395"
  },
  {
    "uniprot": "A0A0N4UK48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK48"
  },
  {
    "uniprot": "A0A0N4UBP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBP0"
  },
  {
    "uniprot": "A0A158Q573",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q573"
  },
  {
    "uniprot": "A0A0N4U6P4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6P4"
  },
  {
    "uniprot": "A0A3P7QI52",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QI52"
  },
  {
    "uniprot": "A0A0N4UBV1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBV1"
  },
  {
    "uniprot": "A0A0N4UL78",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL78"
  },
  {
    "uniprot": "A0A0N4UCD2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCD2"
  },
  {
    "uniprot": "A0A158Q2X9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2X9"
  },
  {
    "uniprot": "A0A0N4U5S9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5S9"
  },
  {
    "uniprot": "A0A0N4U7R9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7R9"
  },
  {
    "uniprot": "A0A0N4U3J1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3J1"
  },
  {
    "uniprot": "A0A0N4UGE5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGE5"
  },
  {
    "uniprot": "A0A0N4U574",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U574"
  },
  {
    "uniprot": "A0A3P7Q9W5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q9W5"
  },
  {
    "uniprot": "A0A0N4UFG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFG4"
  },
  {
    "uniprot": "A0A0N4UFG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFG0"
  },
  {
    "uniprot": "A0A0N4UP26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP26"
  },
  {
    "uniprot": "A0A0N4UE79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE79"
  },
  {
    "uniprot": "A0A0N4UKT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKT6"
  },
  {
    "uniprot": "A0A158Q5Q3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Q3"
  },
  {
    "uniprot": "A0A0N4UD85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD85"
  },
  {
    "uniprot": "A0A0N4UG09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG09"
  },
  {
    "uniprot": "A0A0N4U7M6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7M6"
  },
  {
    "uniprot": "A0A0N4URY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URY2"
  },
  {
    "uniprot": "A0A0N4UP56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP56"
  },
  {
    "uniprot": "A0A3P7PTC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PTC6"
  },
  {
    "uniprot": "A0A0N4UPN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPN2"
  },
  {
    "uniprot": "A0A3P7PMG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PMG3"
  },
  {
    "uniprot": "A0A0N4UA48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA48"
  },
  {
    "uniprot": "A0A0N4UN30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN30"
  },
  {
    "uniprot": "A0A0N4UQM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQM2"
  },
  {
    "uniprot": "A0A0N4UIA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIA4"
  },
  {
    "uniprot": "A0A0N4UGF7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGF7"
  },
  {
    "uniprot": "A0A0N4URU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URU6"
  },
  {
    "uniprot": "A0A0N4UQY6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQY6"
  },
  {
    "uniprot": "A0A0N4UIQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIQ3"
  },
  {
    "uniprot": "A0A0N4UFB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFB3"
  },
  {
    "uniprot": "A0A0N4U9Q1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Q1"
  },
  {
    "uniprot": "A0A0N4U2N6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2N6"
  },
  {
    "uniprot": "A0A0N4U245",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U245"
  },
  {
    "uniprot": "A0A0N4URN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URN0"
  },
  {
    "uniprot": "A0A0N4UM37",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM37"
  },
  {
    "uniprot": "A0A0N4US31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US31"
  },
  {
    "uniprot": "A0A0N4UEN4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEN4"
  },
  {
    "uniprot": "A0A0N4U9R9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9R9"
  },
  {
    "uniprot": "A0A0N4UAE7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAE7"
  },
  {
    "uniprot": "A0A0N4ULX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULX9"
  },
  {
    "uniprot": "A0A3P7PED0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PED0"
  },
  {
    "uniprot": "A0A0N4UNW4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNW4"
  },
  {
    "uniprot": "A0A0N4U449",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U449"
  },
  {
    "uniprot": "A0A0N4U8P5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8P5"
  },
  {
    "uniprot": "A0A158Q494",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q494"
  },
  {
    "uniprot": "A0A158Q5F2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5F2"
  },
  {
    "uniprot": "A0A0N4UC79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC79"
  },
  {
    "uniprot": "A0A0N4U4E7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4E7"
  },
  {
    "uniprot": "A0A158Q5T6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5T6"
  },
  {
    "uniprot": "A0A0N4UPZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPZ4"
  },
  {
    "uniprot": "A0A3P7T537",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T537"
  },
  {
    "uniprot": "A0A0N4UE57",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE57"
  },
  {
    "uniprot": "A0A0N4UA30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA30"
  },
  {
    "uniprot": "A0A158Q3R6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3R6"
  },
  {
    "uniprot": "A0A0N4UCN8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCN8"
  },
  {
    "uniprot": "A0A0N4UAC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAC7"
  },
  {
    "uniprot": "A0A0N4UNZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNZ5"
  },
  {
    "uniprot": "A0A0N4U4J5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4J5"
  },
  {
    "uniprot": "A0A0N4UJF4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJF4"
  },
  {
    "uniprot": "A0A158Q5P1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5P1"
  },
  {
    "uniprot": "A0A0N4UBS1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBS1"
  },
  {
    "uniprot": "A0A0N4U577",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U577"
  },
  {
    "uniprot": "A0A0N4U0K5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0K5"
  },
  {
    "uniprot": "A0A0N4UNZ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNZ3"
  },
  {
    "uniprot": "A0A0N4UDH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDH9"
  },
  {
    "uniprot": "A0A0N4U0T8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0T8"
  },
  {
    "uniprot": "A0A0N4UPB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPB7"
  },
  {
    "uniprot": "A0A0N4UD72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD72"
  },
  {
    "uniprot": "A0A0N4U780",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U780"
  },
  {
    "uniprot": "A0A0N4UNE8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNE8"
  },
  {
    "uniprot": "A0A0N4U9Q7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Q7"
  },
  {
    "uniprot": "A0A158Q490",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q490"
  },
  {
    "uniprot": "A0A158Q2M7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2M7"
  },
  {
    "uniprot": "A0A0N4URI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URI0"
  },
  {
    "uniprot": "A0A0N4U0V6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0V6"
  },
  {
    "uniprot": "A0A0N4ULW4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULW4"
  },
  {
    "uniprot": "A0A0N4UEM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEM8"
  },
  {
    "uniprot": "A0A158Q2H1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2H1"
  },
  {
    "uniprot": "A0A0N4UF30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF30"
  },
  {
    "uniprot": "A0A0N4URD8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URD8"
  },
  {
    "uniprot": "A0A0N4ULN8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULN8"
  },
  {
    "uniprot": "A0A158Q572",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q572"
  },
  {
    "uniprot": "A0A0N4UGM1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGM1"
  },
  {
    "uniprot": "A0A0N4UNR8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNR8"
  },
  {
    "uniprot": "A0A0N4UI03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI03"
  },
  {
    "uniprot": "A0A0N4UGV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGV6"
  },
  {
    "uniprot": "A0A0N4U266",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U266"
  },
  {
    "uniprot": "A0A0N4UQC5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQC5"
  },
  {
    "uniprot": "A0A0N4U1X3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1X3"
  },
  {
    "uniprot": "A0A0N4UKI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKI1"
  },
  {
    "uniprot": "A0A0N4UHI5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHI5"
  },
  {
    "uniprot": "A0A0N4U4L7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4L7"
  },
  {
    "uniprot": "A0A0N4UP52",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP52"
  },
  {
    "uniprot": "A0A3P7PL67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PL67"
  },
  {
    "uniprot": "A0A0N4UDE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDE3"
  },
  {
    "uniprot": "A0A0N4U290",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U290"
  },
  {
    "uniprot": "A0A0N4U4W3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4W3"
  },
  {
    "uniprot": "A0A0N4UIN1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIN1"
  },
  {
    "uniprot": "A0A0N4U4X6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4X6"
  },
  {
    "uniprot": "A0A0N4U5U8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5U8"
  },
  {
    "uniprot": "A0A0N4UR00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR00"
  },
  {
    "uniprot": "A0A3P7SI90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SI90"
  },
  {
    "uniprot": "A0A3P7PXS8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PXS8"
  },
  {
    "uniprot": "A0A3P7SHR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SHR9"
  },
  {
    "uniprot": "A0A0N4U2F7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2F7"
  },
  {
    "uniprot": "A0A0N4UBT3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBT3"
  },
  {
    "uniprot": "A0A158Q397",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q397"
  },
  {
    "uniprot": "A0A0N4U1E0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1E0"
  },
  {
    "uniprot": "A0A0N4ULD0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULD0"
  },
  {
    "uniprot": "A0A0N4U0X9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0X9"
  },
  {
    "uniprot": "A0A0N4U870",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U870"
  },
  {
    "uniprot": "A0A0N4UIX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIX9"
  },
  {
    "uniprot": "A0A0N4URN7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URN7"
  },
  {
    "uniprot": "A0A0N4U688",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U688"
  },
  {
    "uniprot": "A0A0N4UP75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP75"
  },
  {
    "uniprot": "A0A0N4UFL6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFL6"
  },
  {
    "uniprot": "A0A0N4URI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URI9"
  },
  {
    "uniprot": "A0A0N4U9H3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9H3"
  },
  {
    "uniprot": "A0A0N4UFF0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFF0"
  },
  {
    "uniprot": "A0A0N4UJE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJE3"
  },
  {
    "uniprot": "A0A0N4U3R3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3R3"
  },
  {
    "uniprot": "A0A0N4UBI7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBI7"
  },
  {
    "uniprot": "A0A0N4U1E4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1E4"
  },
  {
    "uniprot": "A0A0N4UB33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB33"
  },
  {
    "uniprot": "A0A0N4UGY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGY7"
  },
  {
    "uniprot": "A0A0N4ULE6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULE6"
  },
  {
    "uniprot": "A0A158Q2T2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2T2"
  },
  {
    "uniprot": "A0A0N4UA13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA13"
  },
  {
    "uniprot": "A0A0N4U7W4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7W4"
  },
  {
    "uniprot": "A0A3P7PQB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQB2"
  },
  {
    "uniprot": "A0A0N4UH16",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH16"
  },
  {
    "uniprot": "A0A0N4U7S7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7S7"
  },
  {
    "uniprot": "A0A3P7QZ83",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QZ83"
  },
  {
    "uniprot": "A0A158Q3L6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3L6"
  },
  {
    "uniprot": "A0A0N4UER0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UER0"
  },
  {
    "uniprot": "A0A158Q4S1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4S1"
  },
  {
    "uniprot": "A0A0N4UKH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKH2"
  },
  {
    "uniprot": "A0A0N4UQH5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQH5"
  },
  {
    "uniprot": "A0A0N4URU9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URU9"
  },
  {
    "uniprot": "A0A0N4U3S2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3S2"
  },
  {
    "uniprot": "A0A3P7SB56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SB56"
  },
  {
    "uniprot": "A0A0N4UAK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAK2"
  },
  {
    "uniprot": "A0A0N4UFM3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFM3"
  },
  {
    "uniprot": "A0A0N4U0L8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0L8"
  },
  {
    "uniprot": "A0A0N4U400",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U400"
  },
  {
    "uniprot": "A0A0N4UQU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQU4"
  },
  {
    "uniprot": "A0A0N4UHW6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHW6"
  },
  {
    "uniprot": "A0A0N4UC80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC80"
  },
  {
    "uniprot": "A0A0N4UDJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDJ7"
  },
  {
    "uniprot": "A0A0N4U0H8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0H8"
  },
  {
    "uniprot": "A0A0N4UHL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHL3"
  },
  {
    "uniprot": "A0A0N4UJC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJC7"
  },
  {
    "uniprot": "A0A0N4UIS1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIS1"
  },
  {
    "uniprot": "A0A0N4U1U7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1U7"
  },
  {
    "uniprot": "A0A0N4UQD1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQD1"
  },
  {
    "uniprot": "A0A0N4UGG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGG2"
  },
  {
    "uniprot": "A0A158Q5H0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5H0"
  },
  {
    "uniprot": "A0A0N4UPG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPG0"
  },
  {
    "uniprot": "A0A158Q5P7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5P7"
  },
  {
    "uniprot": "A0A0N4U7C4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7C4"
  },
  {
    "uniprot": "A0A158Q410",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q410"
  },
  {
    "uniprot": "A0A0N4UQ57",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ57"
  },
  {
    "uniprot": "A0A3P7SZT9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SZT9"
  },
  {
    "uniprot": "A0A0N4UA82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA82"
  },
  {
    "uniprot": "A0A0N4URQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URQ3"
  },
  {
    "uniprot": "A0A0N4U412",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U412"
  },
  {
    "uniprot": "A0A0N4UAF4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAF4"
  },
  {
    "uniprot": "A0A0N4U3P3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3P3"
  },
  {
    "uniprot": "A0A0N4U4E6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4E6"
  },
  {
    "uniprot": "A0A3P7PRM4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PRM4"
  },
  {
    "uniprot": "A0A0N4UM29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM29"
  },
  {
    "uniprot": "A0A0N4U4Z2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Z2"
  },
  {
    "uniprot": "A0A158Q2U6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2U6"
  },
  {
    "uniprot": "A0A0N4U4M8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4M8"
  },
  {
    "uniprot": "A0A0N4U3N4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3N4"
  },
  {
    "uniprot": "A0A0N4UCA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCA0"
  },
  {
    "uniprot": "A0A0N4UQ87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ87"
  },
  {
    "uniprot": "A0A0N4UBJ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBJ3"
  },
  {
    "uniprot": "A0A0N4UHR4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHR4"
  },
  {
    "uniprot": "A0A0N4US55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US55"
  },
  {
    "uniprot": "A0A0N4UI88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI88"
  },
  {
    "uniprot": "A0A0N4URG5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URG5"
  },
  {
    "uniprot": "A0A158Q3D8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3D8"
  },
  {
    "uniprot": "A0A0N4UN67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN67"
  },
  {
    "uniprot": "A0A0N4USA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USA8"
  },
  {
    "uniprot": "A0A0N4U0Q8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Q8"
  },
  {
    "uniprot": "A0A0N4UN48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN48"
  },
  {
    "uniprot": "A0A0N4US71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US71"
  },
  {
    "uniprot": "A0A0N4UA07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA07"
  },
  {
    "uniprot": "A0A3P7SWA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SWA5"
  },
  {
    "uniprot": "A0A0N4U8D3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8D3"
  },
  {
    "uniprot": "A0A0N4UKI5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKI5"
  },
  {
    "uniprot": "A0A3P7QNC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QNC9"
  },
  {
    "uniprot": "A0A158Q3T0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3T0"
  },
  {
    "uniprot": "A0A0N4UMV2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMV2"
  },
  {
    "uniprot": "A0A3P7QA10",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QA10"
  },
  {
    "uniprot": "A0A0N4ULE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULE3"
  },
  {
    "uniprot": "A0A0N4URH5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URH5"
  },
  {
    "uniprot": "A0A3P7QK47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QK47"
  },
  {
    "uniprot": "A0A0N4UG99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG99"
  },
  {
    "uniprot": "A0A0N4U762",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U762"
  },
  {
    "uniprot": "A0A0N4UJP8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJP8"
  },
  {
    "uniprot": "A0A0N4UPU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPU2"
  },
  {
    "uniprot": "A0A0N4UHU5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHU5"
  },
  {
    "uniprot": "A0A158Q5U9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5U9"
  },
  {
    "uniprot": "A0A0N4U3X0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3X0"
  },
  {
    "uniprot": "A0A0N4UPM9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPM9"
  },
  {
    "uniprot": "A0A3P7Q2M2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q2M2"
  },
  {
    "uniprot": "A0A0N4UQF7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQF7"
  },
  {
    "uniprot": "A0A0N4U0F0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0F0"
  },
  {
    "uniprot": "A0A3P7QCY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QCY7"
  },
  {
    "uniprot": "A0A158Q620",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q620"
  },
  {
    "uniprot": "A0A0N4U0Z2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Z2"
  },
  {
    "uniprot": "A0A0N4U9G6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9G6"
  },
  {
    "uniprot": "A0A0N4U9K1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9K1"
  },
  {
    "uniprot": "A0A0N4UJR0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJR0"
  },
  {
    "uniprot": "A0A0N4U4P9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4P9"
  },
  {
    "uniprot": "A0A158Q2K6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2K6"
  },
  {
    "uniprot": "A0A0N4UND4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UND4"
  },
  {
    "uniprot": "A0A0N4U1A8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1A8"
  },
  {
    "uniprot": "A0A0N4U9M4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9M4"
  },
  {
    "uniprot": "A0A0N4UDF8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDF8"
  },
  {
    "uniprot": "A0A0N4UD99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD99"
  },
  {
    "uniprot": "A0A0N4UMV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMV8"
  },
  {
    "uniprot": "A0A0N4UIB4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIB4"
  },
  {
    "uniprot": "A0A0N4UJS2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJS2"
  },
  {
    "uniprot": "A0A0N4UE87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE87"
  },
  {
    "uniprot": "A0A0N4UJQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJQ4"
  },
  {
    "uniprot": "A0A0N4UP67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP67"
  },
  {
    "uniprot": "A0A158Q6C1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6C1"
  },
  {
    "uniprot": "A0A3P7PM47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PM47"
  },
  {
    "uniprot": "A0A158Q6L5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6L5"
  },
  {
    "uniprot": "A0A0N4U0G2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0G2"
  },
  {
    "uniprot": "A0A0N4URJ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URJ3"
  },
  {
    "uniprot": "A0A0N4UIE9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIE9"
  },
  {
    "uniprot": "A0A0N4U3P6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3P6"
  },
  {
    "uniprot": "A0A158Q3H6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3H6"
  },
  {
    "uniprot": "A0A158Q4Z8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Z8"
  },
  {
    "uniprot": "A0A0N4UNK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNK1"
  },
  {
    "uniprot": "A0A0N4UE95",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE95"
  },
  {
    "uniprot": "A0A158Q348",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q348"
  },
  {
    "uniprot": "A0A3P7PKM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PKM2"
  },
  {
    "uniprot": "A0A0N4UP81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP81"
  },
  {
    "uniprot": "A0A0N4UNQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNQ5"
  },
  {
    "uniprot": "A0A0N4UBB6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBB6"
  },
  {
    "uniprot": "A0A0N4UDW4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDW4"
  },
  {
    "uniprot": "A0A158Q3L2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3L2"
  },
  {
    "uniprot": "A0A0N4UE85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE85"
  },
  {
    "uniprot": "A0A0N4U0I9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0I9"
  },
  {
    "uniprot": "A0A0N4UQA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQA4"
  },
  {
    "uniprot": "A0A0N4UGU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGU7"
  },
  {
    "uniprot": "A0A0N4U4R8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4R8"
  },
  {
    "uniprot": "A0A0N4U4H6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4H6"
  },
  {
    "uniprot": "A0A0N4UGG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGG0"
  },
  {
    "uniprot": "A0A0N4UF76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF76"
  },
  {
    "uniprot": "A0A0N4UH04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH04"
  },
  {
    "uniprot": "A0A0N4UL71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL71"
  },
  {
    "uniprot": "A0A0N4UNF8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNF8"
  },
  {
    "uniprot": "A0A0N4U2T9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2T9"
  },
  {
    "uniprot": "A0A0N4UPI6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPI6"
  },
  {
    "uniprot": "A0A0N4UEB4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEB4"
  },
  {
    "uniprot": "A0A0N4UIJ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIJ0"
  },
  {
    "uniprot": "A0A158Q612",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q612"
  },
  {
    "uniprot": "A0A158Q441",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q441"
  },
  {
    "uniprot": "A0A0N4U0M7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0M7"
  },
  {
    "uniprot": "A0A0N4U9Z1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Z1"
  },
  {
    "uniprot": "A0A158Q6I0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6I0"
  },
  {
    "uniprot": "A0A3P7S9J5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S9J5"
  },
  {
    "uniprot": "A0A0N4U7E8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7E8"
  },
  {
    "uniprot": "A0A3P7TBA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TBA6"
  },
  {
    "uniprot": "A0A0N4UB55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB55"
  },
  {
    "uniprot": "A0A158Q2Q0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Q0"
  },
  {
    "uniprot": "A0A0N4U563",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U563"
  },
  {
    "uniprot": "A0A3P7PSA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PSA1"
  },
  {
    "uniprot": "A0A158Q2N4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2N4"
  },
  {
    "uniprot": "A0A0N4UIS0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIS0"
  },
  {
    "uniprot": "A0A0N4UAW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAW3"
  },
  {
    "uniprot": "A0A0N4UCZ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCZ2"
  },
  {
    "uniprot": "A0A0N4U4C3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4C3"
  },
  {
    "uniprot": "A0A0N4UPE6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPE6"
  },
  {
    "uniprot": "A0A0N4UR72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR72"
  },
  {
    "uniprot": "A0A0N4UFC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFC6"
  },
  {
    "uniprot": "A0A0N4UQ97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ97"
  },
  {
    "uniprot": "A0A158Q5I7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5I7"
  },
  {
    "uniprot": "A0A0N4U3M1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3M1"
  },
  {
    "uniprot": "A0A0N4ULL5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULL5"
  },
  {
    "uniprot": "A0A0N4UIN8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIN8"
  },
  {
    "uniprot": "A0A0N4UMK7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMK7"
  },
  {
    "uniprot": "A0A0N4U313",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U313"
  },
  {
    "uniprot": "A0A0N4UPA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPA4"
  },
  {
    "uniprot": "A0A3P7PUV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PUV7"
  },
  {
    "uniprot": "A0A3P7Q508",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q508"
  },
  {
    "uniprot": "A0A0N4UBS5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBS5"
  },
  {
    "uniprot": "A0A0N4U0G6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0G6"
  },
  {
    "uniprot": "A0A0N4UL76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL76"
  },
  {
    "uniprot": "A0A0N4U145",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U145"
  },
  {
    "uniprot": "A0A0N4U1G8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1G8"
  },
  {
    "uniprot": "A0A0N4U6C6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6C6"
  },
  {
    "uniprot": "A0A0N4U7X8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7X8"
  },
  {
    "uniprot": "A0A0N4UEB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEB0"
  },
  {
    "uniprot": "A0A0N4UPR0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPR0"
  },
  {
    "uniprot": "A0A0N4U9E0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9E0"
  },
  {
    "uniprot": "A0A0N4UMD0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMD0"
  },
  {
    "uniprot": "A0A0N4ULA7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULA7"
  },
  {
    "uniprot": "A0A3P7Q5X9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q5X9"
  },
  {
    "uniprot": "A0A0N4UAB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAB5"
  },
  {
    "uniprot": "A0A0N4UC74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC74"
  },
  {
    "uniprot": "A0A0N4UA57",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA57"
  },
  {
    "uniprot": "A0A158Q3V5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3V5"
  },
  {
    "uniprot": "A0A0N4UK15",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK15"
  },
  {
    "uniprot": "A0A0N4UJY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJY0"
  },
  {
    "uniprot": "A0A158Q5C7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5C7"
  },
  {
    "uniprot": "A0A0N4UG91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG91"
  },
  {
    "uniprot": "A0A0N4UCX3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCX3"
  },
  {
    "uniprot": "A0A0N4UG08",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG08"
  },
  {
    "uniprot": "A0A0N4U9P3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9P3"
  },
  {
    "uniprot": "A0A0N4ULL6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULL6"
  },
  {
    "uniprot": "A0A0N4UGR0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGR0"
  },
  {
    "uniprot": "A0A0N4U7N8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7N8"
  },
  {
    "uniprot": "A0A3P7Q806",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q806"
  },
  {
    "uniprot": "A0A0N4US33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US33"
  },
  {
    "uniprot": "A0A0N4UMC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMC2"
  },
  {
    "uniprot": "A0A0N4UD91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD91"
  },
  {
    "uniprot": "A0A3P7QSY9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QSY9"
  },
  {
    "uniprot": "A0A0N4U1J0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1J0"
  },
  {
    "uniprot": "A0A0N4U8K1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8K1"
  },
  {
    "uniprot": "A0A0N4UN43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN43"
  },
  {
    "uniprot": "A0A0N4UHI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHI3"
  },
  {
    "uniprot": "A0A0N4UPI5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPI5"
  },
  {
    "uniprot": "A0A0N4UBQ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBQ0"
  },
  {
    "uniprot": "A0A0N4U3G5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3G5"
  },
  {
    "uniprot": "A0A158Q607",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q607"
  },
  {
    "uniprot": "A0A0N4U6Y8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Y8"
  },
  {
    "uniprot": "A0A0N4U990",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U990"
  },
  {
    "uniprot": "A0A0N4UKH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKH4"
  },
  {
    "uniprot": "A0A0N4UHB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHB2"
  },
  {
    "uniprot": "A0A0N4UPU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPU4"
  },
  {
    "uniprot": "A0A0N4UJT1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJT1"
  },
  {
    "uniprot": "A0A0N4U697",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U697"
  },
  {
    "uniprot": "A0A0N4UNA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNA8"
  },
  {
    "uniprot": "A0A3P7PNB6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PNB6"
  },
  {
    "uniprot": "A0A158Q2M6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2M6"
  },
  {
    "uniprot": "A0A0N4UE83",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE83"
  },
  {
    "uniprot": "A0A0N4UPQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPQ1"
  },
  {
    "uniprot": "A0A0N4U530",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U530"
  },
  {
    "uniprot": "A0A3P7S891",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S891"
  },
  {
    "uniprot": "A0A0N4UBZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBZ1"
  },
  {
    "uniprot": "A0A0N4URS8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URS8"
  },
  {
    "uniprot": "A0A0N4U3L4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3L4"
  },
  {
    "uniprot": "A0A0N4URH7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URH7"
  },
  {
    "uniprot": "A0A0N4UQE9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQE9"
  },
  {
    "uniprot": "A0A0N4UJX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJX8"
  },
  {
    "uniprot": "A0A0N4U4F4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4F4"
  },
  {
    "uniprot": "A0A0N4UGE7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGE7"
  },
  {
    "uniprot": "A0A0N4U3Z6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Z6"
  },
  {
    "uniprot": "A0A0N4UBU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBU2"
  },
  {
    "uniprot": "A0A0N4UFD4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFD4"
  },
  {
    "uniprot": "A0A0N4UD32",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD32"
  },
  {
    "uniprot": "A0A158Q695",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q695"
  },
  {
    "uniprot": "A0A0N4U2N4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2N4"
  },
  {
    "uniprot": "A0A0N4U8G4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8G4"
  },
  {
    "uniprot": "A0A0N4U9H0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9H0"
  },
  {
    "uniprot": "A0A0N4UEG1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEG1"
  },
  {
    "uniprot": "A0A158Q6N1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6N1"
  },
  {
    "uniprot": "A0A3P7T0Y9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T0Y9"
  },
  {
    "uniprot": "A0A3P7PD47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PD47"
  },
  {
    "uniprot": "A0A0N4UN62",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN62"
  },
  {
    "uniprot": "A0A0N4UA42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA42"
  },
  {
    "uniprot": "A0A3P7QSK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QSK2"
  },
  {
    "uniprot": "A0A0N4UKG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKG6"
  },
  {
    "uniprot": "A0A0N4US82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US82"
  },
  {
    "uniprot": "A0A0N4UGP5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGP5"
  },
  {
    "uniprot": "A0A0N4UB77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB77"
  },
  {
    "uniprot": "A0A3P7T0T6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T0T6"
  },
  {
    "uniprot": "A0A0N4UKB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKB0"
  },
  {
    "uniprot": "A0A0N4UBF0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBF0"
  },
  {
    "uniprot": "A0A0N4UB89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB89"
  },
  {
    "uniprot": "A0A0N4UB65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB65"
  },
  {
    "uniprot": "A0A0N4U1N2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1N2"
  },
  {
    "uniprot": "A0A0N4UQC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQC9"
  },
  {
    "uniprot": "A0A0N4U2F0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2F0"
  },
  {
    "uniprot": "A0A158Q380",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q380"
  },
  {
    "uniprot": "A0A0N4U7R0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7R0"
  },
  {
    "uniprot": "A0A0N4UIG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIG9"
  },
  {
    "uniprot": "A0A0N4UHB8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHB8"
  },
  {
    "uniprot": "A0A0N4URR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URR6"
  },
  {
    "uniprot": "A0A0N4UED9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UED9"
  },
  {
    "uniprot": "A0A0N4UMS2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMS2"
  },
  {
    "uniprot": "A0A158Q4Y0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Y0"
  },
  {
    "uniprot": "A0A0N4UPT5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPT5"
  },
  {
    "uniprot": "A0A0N4UJK8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJK8"
  },
  {
    "uniprot": "A0A0N4U265",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U265"
  },
  {
    "uniprot": "A0A158Q5E9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5E9"
  },
  {
    "uniprot": "A0A3P7PQI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQI1"
  },
  {
    "uniprot": "A0A3P7PV98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PV98"
  },
  {
    "uniprot": "A0A0N4U8H2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8H2"
  },
  {
    "uniprot": "A0A0N4U9V5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9V5"
  },
  {
    "uniprot": "A0A158Q486",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q486"
  },
  {
    "uniprot": "A0A0N4UQQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQQ1"
  },
  {
    "uniprot": "A0A0N4UAI7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAI7"
  },
  {
    "uniprot": "A0A0N4UL93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL93"
  },
  {
    "uniprot": "A0A0N4U7R2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7R2"
  },
  {
    "uniprot": "A0A158Q3L7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3L7"
  },
  {
    "uniprot": "A0A0N4U9H6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9H6"
  },
  {
    "uniprot": "A0A3P7PTR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PTR1"
  },
  {
    "uniprot": "A0A0N4URI8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URI8"
  },
  {
    "uniprot": "A0A158Q4Y9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Y9"
  },
  {
    "uniprot": "A0A0N4UQW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQW0"
  },
  {
    "uniprot": "A0A0N4UDP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDP4"
  },
  {
    "uniprot": "A0A0N4UPM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPM8"
  },
  {
    "uniprot": "A0A0N4UJ48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ48"
  },
  {
    "uniprot": "A0A0N4UG53",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG53"
  },
  {
    "uniprot": "A0A3P7T132",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T132"
  },
  {
    "uniprot": "A0A0N4U8G9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8G9"
  },
  {
    "uniprot": "A0A158Q2N7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2N7"
  },
  {
    "uniprot": "A0A0N4UDF1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDF1"
  },
  {
    "uniprot": "A0A0N4UCU0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCU0"
  },
  {
    "uniprot": "A0A0N4U1D4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1D4"
  },
  {
    "uniprot": "A0A0N4UPA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPA5"
  },
  {
    "uniprot": "A0A0N4UME1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UME1"
  },
  {
    "uniprot": "A0A0N4UBP2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBP2"
  },
  {
    "uniprot": "A0A0N4URD1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URD1"
  },
  {
    "uniprot": "A0A0N4U0N0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0N0"
  },
  {
    "uniprot": "A0A0N4U3Q9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Q9"
  },
  {
    "uniprot": "A0A0N4U5Y0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Y0"
  },
  {
    "uniprot": "A0A158Q6D8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6D8"
  },
  {
    "uniprot": "A0A0N4UQ54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ54"
  },
  {
    "uniprot": "A0A0N4U912",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U912"
  },
  {
    "uniprot": "A0A0N4UJP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJP7"
  },
  {
    "uniprot": "A0A3P7QVC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QVC7"
  },
  {
    "uniprot": "A0A0N4U5N2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5N2"
  },
  {
    "uniprot": "A0A0N4U820",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U820"
  },
  {
    "uniprot": "A0A3P7Q009",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q009"
  },
  {
    "uniprot": "A0A0N4U1N4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1N4"
  },
  {
    "uniprot": "A0A0N4UND5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UND5"
  },
  {
    "uniprot": "A0A0N4ULJ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULJ5"
  },
  {
    "uniprot": "A0A0N4UPR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPR6"
  },
  {
    "uniprot": "A0A0N4UHU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHU6"
  },
  {
    "uniprot": "A0A0N4U8W8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8W8"
  },
  {
    "uniprot": "A0A0N4U7L8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7L8"
  },
  {
    "uniprot": "A0A0N4U749",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U749"
  },
  {
    "uniprot": "A0A0N4U868",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U868"
  },
  {
    "uniprot": "A0A0N4U8F3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8F3"
  },
  {
    "uniprot": "A0A0N4UD13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD13"
  },
  {
    "uniprot": "A0A0N4U7L6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7L6"
  },
  {
    "uniprot": "A0A3P7PAL1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PAL1"
  },
  {
    "uniprot": "A0A3P7QUN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QUN6"
  },
  {
    "uniprot": "A0A0N4U7U4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7U4"
  },
  {
    "uniprot": "A0A0N4U844",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U844"
  },
  {
    "uniprot": "A0A158Q4D9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4D9"
  },
  {
    "uniprot": "A0A158Q2I8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2I8"
  },
  {
    "uniprot": "A0A0N4UQB8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQB8"
  },
  {
    "uniprot": "A0A0N4U9Y3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Y3"
  },
  {
    "uniprot": "A0A0N4UG71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG71"
  },
  {
    "uniprot": "A0A0N4UAD5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAD5"
  },
  {
    "uniprot": "A0A0N4U704",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U704"
  },
  {
    "uniprot": "A0A0N4UNG7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNG7"
  },
  {
    "uniprot": "A0A0N4URE7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URE7"
  },
  {
    "uniprot": "A0A3P7PIG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PIG3"
  },
  {
    "uniprot": "A0A0N4U7Z7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Z7"
  },
  {
    "uniprot": "A0A0N4UGZ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGZ9"
  },
  {
    "uniprot": "A0A0N4UIA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIA1"
  },
  {
    "uniprot": "A0A0N4URH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URH2"
  },
  {
    "uniprot": "A0A158Q2H9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2H9"
  },
  {
    "uniprot": "A0A0N4UL56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL56"
  },
  {
    "uniprot": "A0A0N4U1Z4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Z4"
  },
  {
    "uniprot": "A0A0N4UJY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJY4"
  },
  {
    "uniprot": "A0A158Q4J1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4J1"
  },
  {
    "uniprot": "A0A0N4UQR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQR6"
  },
  {
    "uniprot": "A0A158Q601",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q601"
  },
  {
    "uniprot": "A0A158Q365",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q365"
  },
  {
    "uniprot": "A0A0N4U0D7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0D7"
  },
  {
    "uniprot": "A0A0N4UFQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFQ5"
  },
  {
    "uniprot": "A0A0N4ULN1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULN1"
  },
  {
    "uniprot": "A0A0N4UIL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIL9"
  },
  {
    "uniprot": "A0A0N4UL70",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL70"
  },
  {
    "uniprot": "A0A0N4ULT7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULT7"
  },
  {
    "uniprot": "A0A0N4U7J9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7J9"
  },
  {
    "uniprot": "A0A0N4U371",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U371"
  },
  {
    "uniprot": "A0A0N4UIN5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIN5"
  },
  {
    "uniprot": "A0A0N4UIB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIB3"
  },
  {
    "uniprot": "A0A0N4UNI6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNI6"
  },
  {
    "uniprot": "A0A3P7SMY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SMY1"
  },
  {
    "uniprot": "A0A0N4U0V2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0V2"
  },
  {
    "uniprot": "A0A0N4UHB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHB3"
  },
  {
    "uniprot": "A0A0N4UK61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK61"
  },
  {
    "uniprot": "A0A0N4UJU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJU2"
  },
  {
    "uniprot": "A0A0N4UAB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAB1"
  },
  {
    "uniprot": "A0A0N4UQR8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQR8"
  },
  {
    "uniprot": "A0A0N4U4C6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4C6"
  },
  {
    "uniprot": "A0A0N4UDS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDS9"
  },
  {
    "uniprot": "A0A0N4U8V6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8V6"
  },
  {
    "uniprot": "A0A0N4UDR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDR6"
  },
  {
    "uniprot": "A0A0N4ULA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULA6"
  },
  {
    "uniprot": "A0A0N4UKD0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKD0"
  },
  {
    "uniprot": "A0A0N4UGB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGB1"
  },
  {
    "uniprot": "A0A0N4U7A1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7A1"
  },
  {
    "uniprot": "A0A0N4U7A5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7A5"
  },
  {
    "uniprot": "A0A158Q5C5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5C5"
  },
  {
    "uniprot": "A0A0N4UDL2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDL2"
  },
  {
    "uniprot": "A0A0N4UCZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCZ4"
  },
  {
    "uniprot": "A0A3P7PSN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PSN3"
  },
  {
    "uniprot": "A0A0N4U9Q9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Q9"
  },
  {
    "uniprot": "A0A0N4U1V1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1V1"
  },
  {
    "uniprot": "A0A0N4U3G0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3G0"
  },
  {
    "uniprot": "A0A158Q3F1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3F1"
  },
  {
    "uniprot": "A0A3P7PS65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PS65"
  },
  {
    "uniprot": "A0A3P7QN87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QN87"
  },
  {
    "uniprot": "A0A0N4U2P9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2P9"
  },
  {
    "uniprot": "A0A0N4UJT8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJT8"
  },
  {
    "uniprot": "A0A0N4U4Q4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Q4"
  },
  {
    "uniprot": "A0A0N4UF65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF65"
  },
  {
    "uniprot": "A0A0N4U9U3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9U3"
  },
  {
    "uniprot": "A0A0N4U550",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U550"
  },
  {
    "uniprot": "A0A158Q5N8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5N8"
  },
  {
    "uniprot": "A0A158Q4T8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4T8"
  },
  {
    "uniprot": "A0A0N4UJC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJC0"
  },
  {
    "uniprot": "A0A0N4UGK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGK2"
  },
  {
    "uniprot": "A0A0N4U606",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U606"
  },
  {
    "uniprot": "A0A158Q4D1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4D1"
  },
  {
    "uniprot": "A0A0N4URX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URX7"
  },
  {
    "uniprot": "A0A0N4U968",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U968"
  },
  {
    "uniprot": "A0A158Q5I1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5I1"
  },
  {
    "uniprot": "A0A0N4UAM7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAM7"
  },
  {
    "uniprot": "A0A0N4U8S9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8S9"
  },
  {
    "uniprot": "A0A0N4UK22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK22"
  },
  {
    "uniprot": "A0A0N4UHX4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHX4"
  },
  {
    "uniprot": "A0A0N4U6I6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6I6"
  },
  {
    "uniprot": "A0A0N4UCP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCP4"
  },
  {
    "uniprot": "A0A0N4U6A0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6A0"
  },
  {
    "uniprot": "A0A3P7SH02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SH02"
  },
  {
    "uniprot": "A0A158Q3A0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3A0"
  },
  {
    "uniprot": "A0A0N4UBQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBQ7"
  },
  {
    "uniprot": "A0A0N4U9H9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9H9"
  },
  {
    "uniprot": "A0A3P7PSW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PSW8"
  },
  {
    "uniprot": "A0A158Q564",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q564"
  },
  {
    "uniprot": "A0A0N4UM72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM72"
  },
  {
    "uniprot": "A0A0N4ULT1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULT1"
  },
  {
    "uniprot": "A0A0N4UBB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBB0"
  },
  {
    "uniprot": "A0A158Q6K5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6K5"
  },
  {
    "uniprot": "A0A0N4U8I2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8I2"
  },
  {
    "uniprot": "A0A158Q4B9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4B9"
  },
  {
    "uniprot": "A0A0N4U8J1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8J1"
  },
  {
    "uniprot": "A0A0N4UR54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR54"
  },
  {
    "uniprot": "A0A0N4U748",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U748"
  },
  {
    "uniprot": "A0A158Q4X7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4X7"
  },
  {
    "uniprot": "A0A0N4UPP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPP0"
  },
  {
    "uniprot": "A0A0N4U6X6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6X6"
  },
  {
    "uniprot": "A0A0N4ULA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULA5"
  },
  {
    "uniprot": "A0A0N4UAZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAZ4"
  },
  {
    "uniprot": "A0A3P7Q4N6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q4N6"
  },
  {
    "uniprot": "A0A0N4UJL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJL9"
  },
  {
    "uniprot": "A0A0N4UGN8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGN8"
  },
  {
    "uniprot": "A0A0N4UAR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAR7"
  },
  {
    "uniprot": "A0A0N4UKK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKK1"
  },
  {
    "uniprot": "A0A0N4UHH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHH2"
  },
  {
    "uniprot": "A0A0N4UNJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNJ7"
  },
  {
    "uniprot": "A0A3P7QFK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QFK2"
  },
  {
    "uniprot": "A0A0N4UDA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDA2"
  },
  {
    "uniprot": "A0A0N4UQR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQR9"
  },
  {
    "uniprot": "A0A0N4U5Z1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Z1"
  },
  {
    "uniprot": "A0A0N4UF52",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF52"
  },
  {
    "uniprot": "A0A3P7QVF8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QVF8"
  },
  {
    "uniprot": "A0A0N4UG07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG07"
  },
  {
    "uniprot": "A0A0N4U186",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U186"
  },
  {
    "uniprot": "A0A0N4UM46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM46"
  },
  {
    "uniprot": "A0A0N4U952",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U952"
  },
  {
    "uniprot": "A0A0N4UHV5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHV5"
  },
  {
    "uniprot": "A0A3P7P6I3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P6I3"
  },
  {
    "uniprot": "A0A0N4U3F8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3F8"
  },
  {
    "uniprot": "A0A0N4U3T6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3T6"
  },
  {
    "uniprot": "A0A0N4U4H3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4H3"
  },
  {
    "uniprot": "A0A0N4U804",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U804"
  },
  {
    "uniprot": "A0A0N4UK26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK26"
  },
  {
    "uniprot": "A0A0N4UQI6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQI6"
  },
  {
    "uniprot": "A0A0N4URF8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URF8"
  },
  {
    "uniprot": "A0A0N4UMW1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMW1"
  },
  {
    "uniprot": "A0A0N4UBS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBS9"
  },
  {
    "uniprot": "A0A0N4UE67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE67"
  },
  {
    "uniprot": "A0A0N4UR98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR98"
  },
  {
    "uniprot": "A0A0N4UEW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEW3"
  },
  {
    "uniprot": "A0A0N4UPV4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPV4"
  },
  {
    "uniprot": "A0A0N4UR87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR87"
  },
  {
    "uniprot": "A0A0N4USC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USC9"
  },
  {
    "uniprot": "A0A0N4UAL2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAL2"
  },
  {
    "uniprot": "A0A0N4UG44",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG44"
  },
  {
    "uniprot": "A0A0N4URT5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URT5"
  },
  {
    "uniprot": "A0A158Q5A2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5A2"
  },
  {
    "uniprot": "A0A3P7SQE8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SQE8"
  },
  {
    "uniprot": "A0A158Q458",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q458"
  },
  {
    "uniprot": "A0A0N4U956",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U956"
  },
  {
    "uniprot": "A0A0N4U893",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U893"
  },
  {
    "uniprot": "A0A3P7TCQ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TCQ9"
  },
  {
    "uniprot": "A0A0N4ULJ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULJ8"
  },
  {
    "uniprot": "A0A0N4UII3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UII3"
  },
  {
    "uniprot": "A0A158Q5B9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5B9"
  },
  {
    "uniprot": "A0A0N4ULR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULR7"
  },
  {
    "uniprot": "A0A158Q5H3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5H3"
  },
  {
    "uniprot": "A0A0N4UGS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGS3"
  },
  {
    "uniprot": "A0A0N4UQ61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ61"
  },
  {
    "uniprot": "A0A3P7QT91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QT91"
  },
  {
    "uniprot": "A0A0N4UCM6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCM6"
  },
  {
    "uniprot": "A0A3P7SB08",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SB08"
  },
  {
    "uniprot": "A0A0N4U5H5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5H5"
  },
  {
    "uniprot": "A0A0N4UG55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG55"
  },
  {
    "uniprot": "A0A0N4U6U9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6U9"
  },
  {
    "uniprot": "A0A0N4U680",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U680"
  },
  {
    "uniprot": "A0A0N4U4E5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4E5"
  },
  {
    "uniprot": "A0A0N4U7B5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7B5"
  },
  {
    "uniprot": "A0A0N4U5A0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5A0"
  },
  {
    "uniprot": "A0A0N4UED4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UED4"
  },
  {
    "uniprot": "A0A3P7PDH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PDH2"
  },
  {
    "uniprot": "A0A0N4U3Z0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Z0"
  },
  {
    "uniprot": "A0A0N4UPY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPY1"
  },
  {
    "uniprot": "A0A0N4UGL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGL0"
  },
  {
    "uniprot": "A0A0N4U3T3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3T3"
  },
  {
    "uniprot": "A0A0N4UCS0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCS0"
  },
  {
    "uniprot": "A0A0N4UKW1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKW1"
  },
  {
    "uniprot": "A0A0N4U6F5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6F5"
  },
  {
    "uniprot": "A0A158Q374",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q374"
  },
  {
    "uniprot": "A0A158Q5J5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5J5"
  },
  {
    "uniprot": "A0A158Q4Z3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Z3"
  },
  {
    "uniprot": "A0A0N4U9D5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9D5"
  },
  {
    "uniprot": "A0A0N4UD77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD77"
  },
  {
    "uniprot": "A0A0N4U0E4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0E4"
  },
  {
    "uniprot": "A0A0N4U0P8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0P8"
  },
  {
    "uniprot": "A0A0N4UDL5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDL5"
  },
  {
    "uniprot": "A0A158Q556",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q556"
  },
  {
    "uniprot": "A0A0N4U9B2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9B2"
  },
  {
    "uniprot": "A0A0N4U345",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U345"
  },
  {
    "uniprot": "A0A0N4URW4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URW4"
  },
  {
    "uniprot": "A0A0N4UQM5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQM5"
  },
  {
    "uniprot": "A0A0N4UEE6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEE6"
  },
  {
    "uniprot": "A0A0N4U2F5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2F5"
  },
  {
    "uniprot": "A0A0N4U8Q5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Q5"
  },
  {
    "uniprot": "A0A3P7PMQ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PMQ8"
  },
  {
    "uniprot": "A0A0N4UGF6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGF6"
  },
  {
    "uniprot": "A0A0N4UI34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI34"
  },
  {
    "uniprot": "A0A3P7QJI6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QJI6"
  },
  {
    "uniprot": "A0A0N4UHL7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHL7"
  },
  {
    "uniprot": "A0A0N4UKG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKG2"
  },
  {
    "uniprot": "A0A0N4U221",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U221"
  },
  {
    "uniprot": "A0A0N4U2Z5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Z5"
  },
  {
    "uniprot": "A0A0N4UCP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCP3"
  },
  {
    "uniprot": "A0A158Q435",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q435"
  },
  {
    "uniprot": "A0A0N4UES8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UES8"
  },
  {
    "uniprot": "A0A0N4U5R8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5R8"
  },
  {
    "uniprot": "A0A0N4UNN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNN9"
  },
  {
    "uniprot": "A0A0N4UCN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCN6"
  },
  {
    "uniprot": "A0A158Q3M0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3M0"
  },
  {
    "uniprot": "A0A0N4U829",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U829"
  },
  {
    "uniprot": "A0A158Q5G0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5G0"
  },
  {
    "uniprot": "A0A158Q6F8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6F8"
  },
  {
    "uniprot": "A0A0N4UJ25",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ25"
  },
  {
    "uniprot": "A0A0N4UEL2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEL2"
  },
  {
    "uniprot": "A0A0N4UL19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL19"
  },
  {
    "uniprot": "A0A158Q6I5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6I5"
  },
  {
    "uniprot": "A0A0N4UDN4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDN4"
  },
  {
    "uniprot": "A0A3P7Q0C8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q0C8"
  },
  {
    "uniprot": "A0A0N4UL17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL17"
  },
  {
    "uniprot": "A0A158Q2Z9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Z9"
  },
  {
    "uniprot": "A0A158Q6K3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6K3"
  },
  {
    "uniprot": "A0A0N4U996",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U996"
  },
  {
    "uniprot": "A0A0N4U8L3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8L3"
  },
  {
    "uniprot": "A0A0N4UM26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM26"
  },
  {
    "uniprot": "A0A0N4U722",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U722"
  },
  {
    "uniprot": "A0A0N4U6Q5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Q5"
  },
  {
    "uniprot": "A0A0N4UC94",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC94"
  },
  {
    "uniprot": "A0A0N4UDU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDU2"
  },
  {
    "uniprot": "A0A158Q5S6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5S6"
  },
  {
    "uniprot": "A0A0N4U5H1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5H1"
  },
  {
    "uniprot": "A0A0N4U6N2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6N2"
  },
  {
    "uniprot": "A0A0N4UHR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHR6"
  },
  {
    "uniprot": "A0A0N4U3L1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3L1"
  },
  {
    "uniprot": "A0A0N4UCR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCR7"
  },
  {
    "uniprot": "A0A0N4UHL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHL0"
  },
  {
    "uniprot": "A0A0N4UB00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB00"
  },
  {
    "uniprot": "A0A0N4U9Y4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Y4"
  },
  {
    "uniprot": "A0A0N4U9T2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9T2"
  },
  {
    "uniprot": "A0A0N4U2J0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2J0"
  },
  {
    "uniprot": "A0A0N4U9T3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9T3"
  },
  {
    "uniprot": "A0A0N4UES0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UES0"
  },
  {
    "uniprot": "A0A0N4U9A4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9A4"
  },
  {
    "uniprot": "A0A0N4U461",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U461"
  },
  {
    "uniprot": "A0A0N4U707",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U707"
  },
  {
    "uniprot": "A0A0N4UMR0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMR0"
  },
  {
    "uniprot": "A0A0N4UMJ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMJ3"
  },
  {
    "uniprot": "A0A0N4U667",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U667"
  },
  {
    "uniprot": "A0A0N4U4T2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4T2"
  },
  {
    "uniprot": "A0A158Q495",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q495"
  },
  {
    "uniprot": "A0A0N4U463",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U463"
  },
  {
    "uniprot": "A0A0N4UEI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEI3"
  },
  {
    "uniprot": "A0A0N4U693",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U693"
  },
  {
    "uniprot": "A0A158Q4E5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4E5"
  },
  {
    "uniprot": "A0A0N4UF95",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF95"
  },
  {
    "uniprot": "A0A0N4UAU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAU2"
  },
  {
    "uniprot": "A0A0N4UPX2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPX2"
  },
  {
    "uniprot": "A0A158Q4L2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4L2"
  },
  {
    "uniprot": "A0A0N4UM14",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM14"
  },
  {
    "uniprot": "A0A0N4U7A4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7A4"
  },
  {
    "uniprot": "A0A0N4UI17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI17"
  },
  {
    "uniprot": "A0A3P7Q9R0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q9R0"
  },
  {
    "uniprot": "A0A0N4UMA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMA6"
  },
  {
    "uniprot": "A0A0N4U7Z6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Z6"
  },
  {
    "uniprot": "A0A0N4U932",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U932"
  },
  {
    "uniprot": "A0A0N4UPZ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPZ6"
  },
  {
    "uniprot": "A0A0N4U487",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U487"
  },
  {
    "uniprot": "A0A0N4U6S9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6S9"
  },
  {
    "uniprot": "A0A0N4U0E1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0E1"
  },
  {
    "uniprot": "A0A0N4U367",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U367"
  },
  {
    "uniprot": "A0A0N4UPS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPS9"
  },
  {
    "uniprot": "A0A0N4UD58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD58"
  },
  {
    "uniprot": "A0A0N4U421",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U421"
  },
  {
    "uniprot": "A0A0N4UJW6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJW6"
  },
  {
    "uniprot": "A0A158Q3G1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3G1"
  },
  {
    "uniprot": "A0A0N4UM52",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM52"
  },
  {
    "uniprot": "A0A0N4UBY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBY7"
  },
  {
    "uniprot": "A0A0N4ULV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULV8"
  },
  {
    "uniprot": "A0A3P7PYK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PYK1"
  },
  {
    "uniprot": "A0A0N4U5M8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5M8"
  },
  {
    "uniprot": "A0A158Q621",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q621"
  },
  {
    "uniprot": "A0A0N4U9Y0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Y0"
  },
  {
    "uniprot": "A0A0N4UER6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UER6"
  },
  {
    "uniprot": "A0A0N4U1W8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1W8"
  },
  {
    "uniprot": "A0A3P7PMJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PMJ2"
  },
  {
    "uniprot": "A0A0N4UEW7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEW7"
  },
  {
    "uniprot": "A0A0N4UK78",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK78"
  },
  {
    "uniprot": "A0A0N4UAD2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAD2"
  },
  {
    "uniprot": "A0A0N4UQ51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ51"
  },
  {
    "uniprot": "A0A3P7SIG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SIG9"
  },
  {
    "uniprot": "A0A0N4UC07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC07"
  },
  {
    "uniprot": "A0A0N4ULT0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULT0"
  },
  {
    "uniprot": "A0A0N4UF31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF31"
  },
  {
    "uniprot": "A0A3P7PNM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PNM8"
  },
  {
    "uniprot": "A0A0N4UIW2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIW2"
  },
  {
    "uniprot": "A0A0N4U1L5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1L5"
  },
  {
    "uniprot": "A0A0N4U1J1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1J1"
  },
  {
    "uniprot": "A0A0N4UDL6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDL6"
  },
  {
    "uniprot": "A0A3P7QLS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QLS3"
  },
  {
    "uniprot": "A0A158Q3B4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3B4"
  },
  {
    "uniprot": "A0A0N4U498",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U498"
  },
  {
    "uniprot": "A0A0N4UMA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMA1"
  },
  {
    "uniprot": "A0A0N4UFF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFF3"
  },
  {
    "uniprot": "A0A0N4U6U7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6U7"
  },
  {
    "uniprot": "A0A0N4U7X7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7X7"
  },
  {
    "uniprot": "A0A0N4U0W9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0W9"
  },
  {
    "uniprot": "A0A158Q623",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q623"
  },
  {
    "uniprot": "A0A158Q5T8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5T8"
  },
  {
    "uniprot": "A0A3P7SK60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SK60"
  },
  {
    "uniprot": "A0A0N4U607",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U607"
  },
  {
    "uniprot": "A0A158Q3F8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3F8"
  },
  {
    "uniprot": "A0A0N4U3K6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3K6"
  },
  {
    "uniprot": "A0A158Q5Z3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Z3"
  },
  {
    "uniprot": "A0A158Q3M2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3M2"
  },
  {
    "uniprot": "A0A0N4UIQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIQ5"
  },
  {
    "uniprot": "A0A0N4UEF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEF3"
  },
  {
    "uniprot": "A0A0N4U9V7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9V7"
  },
  {
    "uniprot": "A0A0N4U3U2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3U2"
  },
  {
    "uniprot": "A0A158Q3S0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3S0"
  },
  {
    "uniprot": "A0A0N4U843",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U843"
  },
  {
    "uniprot": "A0A0N4UKL4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKL4"
  },
  {
    "uniprot": "A0A0N4UA63",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA63"
  },
  {
    "uniprot": "A0A0N4UHK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHK5"
  },
  {
    "uniprot": "A0A3P7SXC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SXC2"
  },
  {
    "uniprot": "A0A158Q4B8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4B8"
  },
  {
    "uniprot": "A0A3P7PE91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PE91"
  },
  {
    "uniprot": "A0A0N4U2R9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2R9"
  },
  {
    "uniprot": "A0A0N4UF98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF98"
  },
  {
    "uniprot": "A0A158Q3J2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3J2"
  },
  {
    "uniprot": "A0A0N4ULL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULL8"
  },
  {
    "uniprot": "A0A0N4UBP5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBP5"
  },
  {
    "uniprot": "A0A0N4UMY3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMY3"
  },
  {
    "uniprot": "A0A0N4U9E9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9E9"
  },
  {
    "uniprot": "A0A0N4U1I9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1I9"
  },
  {
    "uniprot": "A0A0N4UPE4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPE4"
  },
  {
    "uniprot": "A0A158Q5M0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5M0"
  },
  {
    "uniprot": "A0A0N4UD27",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD27"
  },
  {
    "uniprot": "A0A3P7SZ97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SZ97"
  },
  {
    "uniprot": "A0A0N4UMP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMP4"
  },
  {
    "uniprot": "A0A0N4UGF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGF3"
  },
  {
    "uniprot": "A0A158Q4E0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4E0"
  },
  {
    "uniprot": "A0A0N4U5Y3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Y3"
  },
  {
    "uniprot": "A0A0N4UGU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGU6"
  },
  {
    "uniprot": "A0A158Q4M6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4M6"
  },
  {
    "uniprot": "A0A0N4U9P0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9P0"
  },
  {
    "uniprot": "A0A0N4U7M9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7M9"
  },
  {
    "uniprot": "A0A0N4ULF7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULF7"
  },
  {
    "uniprot": "A0A0N4UK88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK88"
  },
  {
    "uniprot": "A0A0N4UJI8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJI8"
  },
  {
    "uniprot": "A0A3P7PJC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PJC7"
  },
  {
    "uniprot": "A0A3P7PD66",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PD66"
  },
  {
    "uniprot": "A0A3P7PUF8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PUF8"
  },
  {
    "uniprot": "A0A0N4UFP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFP7"
  },
  {
    "uniprot": "A0A0N4UG68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG68"
  },
  {
    "uniprot": "A0A3P7PRM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PRM2"
  },
  {
    "uniprot": "A0A0N4U7I5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7I5"
  },
  {
    "uniprot": "A0A0N4U1L3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1L3"
  },
  {
    "uniprot": "A0A0N4UDK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDK5"
  },
  {
    "uniprot": "A0A0N4U6I9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6I9"
  },
  {
    "uniprot": "A0A158Q6L6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6L6"
  },
  {
    "uniprot": "A0A0N4U211",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U211"
  },
  {
    "uniprot": "A0A0N4U2J8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2J8"
  },
  {
    "uniprot": "A0A0N4U8F1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8F1"
  },
  {
    "uniprot": "A0A0N4U267",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U267"
  },
  {
    "uniprot": "A0A0N4UK70",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK70"
  },
  {
    "uniprot": "A0A0N4UJP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJP6"
  },
  {
    "uniprot": "A0A0N4UQG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQG3"
  },
  {
    "uniprot": "A0A0N4U3L6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3L6"
  },
  {
    "uniprot": "A0A0N4U298",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U298"
  },
  {
    "uniprot": "A0A0N4U9H1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9H1"
  },
  {
    "uniprot": "A0A0N4U1F3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1F3"
  },
  {
    "uniprot": "A0A0N4U3R0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3R0"
  },
  {
    "uniprot": "A0A0N4UL16",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL16"
  },
  {
    "uniprot": "A0A0N4UEK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEK5"
  },
  {
    "uniprot": "A0A0N4U442",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U442"
  },
  {
    "uniprot": "A0A0N4URD7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URD7"
  },
  {
    "uniprot": "A0A0N4U4Q3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Q3"
  },
  {
    "uniprot": "A0A0N4UPZ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPZ3"
  },
  {
    "uniprot": "A0A158Q382",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q382"
  },
  {
    "uniprot": "A0A158Q6J7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6J7"
  },
  {
    "uniprot": "A0A3P7PNM6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PNM6"
  },
  {
    "uniprot": "A0A3P7PS19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PS19"
  },
  {
    "uniprot": "A0A0N4UQN4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQN4"
  },
  {
    "uniprot": "A0A0N4UF36",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF36"
  },
  {
    "uniprot": "A0A0N4UCD6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCD6"
  },
  {
    "uniprot": "A0A0N4U9L6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9L6"
  },
  {
    "uniprot": "A0A0N4U8W6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8W6"
  },
  {
    "uniprot": "A0A0N4UA60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA60"
  },
  {
    "uniprot": "A0A0N4U1R0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1R0"
  },
  {
    "uniprot": "A0A158Q5B2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5B2"
  },
  {
    "uniprot": "A0A158Q4J0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4J0"
  },
  {
    "uniprot": "A0A158Q5C4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5C4"
  },
  {
    "uniprot": "A0A0N4UHK9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHK9"
  },
  {
    "uniprot": "A0A0N4UPN4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPN4"
  },
  {
    "uniprot": "A0A0N4UL97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL97"
  },
  {
    "uniprot": "A0A0N4UKY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKY7"
  },
  {
    "uniprot": "A0A0N4UJQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJQ1"
  },
  {
    "uniprot": "A0A3P7PDF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PDF9"
  },
  {
    "uniprot": "A0A0N4UD31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD31"
  },
  {
    "uniprot": "A0A158Q342",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q342"
  },
  {
    "uniprot": "A0A0N4UAK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAK3"
  },
  {
    "uniprot": "A0A0N4UR91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR91"
  },
  {
    "uniprot": "A0A0N4U739",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U739"
  },
  {
    "uniprot": "A0A0N4UHV9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHV9"
  },
  {
    "uniprot": "A0A0N4UF13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF13"
  },
  {
    "uniprot": "A0A158Q2Z4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Z4"
  },
  {
    "uniprot": "A0A0N4UNZ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNZ7"
  },
  {
    "uniprot": "A0A3P7SMG1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SMG1"
  },
  {
    "uniprot": "A0A3P7Q8P9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q8P9"
  },
  {
    "uniprot": "A0A0N4U6N9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6N9"
  },
  {
    "uniprot": "A0A0N4UCD7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCD7"
  },
  {
    "uniprot": "A0A0N4UMD9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMD9"
  },
  {
    "uniprot": "A0A0N4UIT4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIT4"
  },
  {
    "uniprot": "A0A0N4U9U5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9U5"
  },
  {
    "uniprot": "A0A0N4U7S6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7S6"
  },
  {
    "uniprot": "A0A0N4U2V7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2V7"
  },
  {
    "uniprot": "A0A0N4UMI6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMI6"
  },
  {
    "uniprot": "A0A0N4UKF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKF5"
  },
  {
    "uniprot": "A0A0N4U3K9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3K9"
  },
  {
    "uniprot": "A0A0N4UEG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEG2"
  },
  {
    "uniprot": "A0A158Q2J1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2J1"
  },
  {
    "uniprot": "A0A0N4U854",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U854"
  },
  {
    "uniprot": "A0A0N4U1U9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1U9"
  },
  {
    "uniprot": "A0A0N4UKX4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKX4"
  },
  {
    "uniprot": "A0A0N4UQY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQY4"
  },
  {
    "uniprot": "A0A0N4ULW9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULW9"
  },
  {
    "uniprot": "A0A0N4U5H3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5H3"
  },
  {
    "uniprot": "A0A0N4U1U2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1U2"
  },
  {
    "uniprot": "A0A0N4UCU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCU7"
  },
  {
    "uniprot": "A0A0N4UJD7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJD7"
  },
  {
    "uniprot": "A0A0N4U9P1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9P1"
  },
  {
    "uniprot": "A0A0N4UA21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA21"
  },
  {
    "uniprot": "A0A0N4UMQ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMQ0"
  },
  {
    "uniprot": "A0A0N4URZ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URZ3"
  },
  {
    "uniprot": "A0A0N4U7V0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7V0"
  },
  {
    "uniprot": "A0A3P7SBP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SBP9"
  },
  {
    "uniprot": "A0A0N4UFU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFU3"
  },
  {
    "uniprot": "A0A0N4UDY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDY1"
  },
  {
    "uniprot": "A0A0N4UL69",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL69"
  },
  {
    "uniprot": "A0A0N4UHG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHG2"
  },
  {
    "uniprot": "A0A158Q4B5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4B5"
  },
  {
    "uniprot": "A0A0N4US63",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US63"
  },
  {
    "uniprot": "A0A0N4UA65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA65"
  },
  {
    "uniprot": "A0A0N4U1B6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1B6"
  },
  {
    "uniprot": "A0A0N4UB35",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB35"
  },
  {
    "uniprot": "A0A158Q6D4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6D4"
  },
  {
    "uniprot": "A0A3P7PNQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PNQ6"
  },
  {
    "uniprot": "A0A0N4U2A1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2A1"
  },
  {
    "uniprot": "A0A0N4U549",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U549"
  },
  {
    "uniprot": "A0A0N4UCL7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCL7"
  },
  {
    "uniprot": "A0A0N4UCU8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCU8"
  },
  {
    "uniprot": "A0A0N4UCT7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCT7"
  },
  {
    "uniprot": "A0A0N4UAB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAB3"
  },
  {
    "uniprot": "A0A0N4U944",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U944"
  },
  {
    "uniprot": "A0A0N4U207",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U207"
  },
  {
    "uniprot": "A0A0N4UFV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFV6"
  },
  {
    "uniprot": "A0A0N4UPI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPI0"
  },
  {
    "uniprot": "A0A0N4U6Q7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Q7"
  },
  {
    "uniprot": "A0A0N4UBR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBR2"
  },
  {
    "uniprot": "A0A0N4UNE4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNE4"
  },
  {
    "uniprot": "A0A0N4UNI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNI0"
  },
  {
    "uniprot": "A0A0N4ULU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULU7"
  },
  {
    "uniprot": "A0A0N4U219",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U219"
  },
  {
    "uniprot": "A0A0N4U818",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U818"
  },
  {
    "uniprot": "A0A158Q6K7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6K7"
  },
  {
    "uniprot": "A0A0N4U1M5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1M5"
  },
  {
    "uniprot": "A0A0N4U614",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U614"
  },
  {
    "uniprot": "A0A0N4ULS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULS9"
  },
  {
    "uniprot": "A0A3P7SE40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SE40"
  },
  {
    "uniprot": "A0A0N4UGT4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGT4"
  },
  {
    "uniprot": "A0A0N4UAH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAH6"
  },
  {
    "uniprot": "A0A0N4UB44",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB44"
  },
  {
    "uniprot": "A0A0N4UDH0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDH0"
  },
  {
    "uniprot": "A0A0N4UII2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UII2"
  },
  {
    "uniprot": "A0A0N4UI83",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI83"
  },
  {
    "uniprot": "A0A3P7PXK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PXK2"
  },
  {
    "uniprot": "A0A0N4UFZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFZ1"
  },
  {
    "uniprot": "A0A0N4U4I2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4I2"
  },
  {
    "uniprot": "A0A0N4UCT1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCT1"
  },
  {
    "uniprot": "A0A0N4U5K4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5K4"
  },
  {
    "uniprot": "A0A0N4UAY6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAY6"
  },
  {
    "uniprot": "A0A0N4UQF4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQF4"
  },
  {
    "uniprot": "A0A0N4UCU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCU2"
  },
  {
    "uniprot": "A0A0N4UGG7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGG7"
  },
  {
    "uniprot": "A0A158Q5N4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5N4"
  },
  {
    "uniprot": "A0A158Q3Y4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Y4"
  },
  {
    "uniprot": "A0A0N4UDA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDA9"
  },
  {
    "uniprot": "A0A0N4UAX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAX9"
  },
  {
    "uniprot": "A0A0N4UKW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKW0"
  },
  {
    "uniprot": "A0A0N4U9Z8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Z8"
  },
  {
    "uniprot": "A0A0N4UJ09",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ09"
  },
  {
    "uniprot": "A0A158Q2X7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2X7"
  },
  {
    "uniprot": "A0A0N4U8L0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8L0"
  },
  {
    "uniprot": "A0A0N4US06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US06"
  },
  {
    "uniprot": "A0A158Q3X2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3X2"
  },
  {
    "uniprot": "A0A0N4UFP2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFP2"
  },
  {
    "uniprot": "A0A0N4UAB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAB0"
  },
  {
    "uniprot": "A0A0N4U2U0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2U0"
  },
  {
    "uniprot": "A0A0N4UE44",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE44"
  },
  {
    "uniprot": "A0A0N4UKA3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKA3"
  },
  {
    "uniprot": "A0A0N4UQ13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ13"
  },
  {
    "uniprot": "A0A0N4U540",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U540"
  },
  {
    "uniprot": "A0A158Q3P0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3P0"
  },
  {
    "uniprot": "A0A0N4UB50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB50"
  },
  {
    "uniprot": "A0A0N4U1E3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1E3"
  },
  {
    "uniprot": "A0A0N4UGU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGU4"
  },
  {
    "uniprot": "A0A0N4U9R1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9R1"
  },
  {
    "uniprot": "A0A0N4UJ91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ91"
  },
  {
    "uniprot": "A0A0N4UH83",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH83"
  },
  {
    "uniprot": "A0A0N4UNG8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNG8"
  },
  {
    "uniprot": "A0A0N4UMB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMB0"
  },
  {
    "uniprot": "A0A0N4UC29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC29"
  },
  {
    "uniprot": "A0A0N4U6V8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6V8"
  },
  {
    "uniprot": "A0A3P7SBS0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SBS0"
  },
  {
    "uniprot": "A0A3P7PWL2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PWL2"
  },
  {
    "uniprot": "A0A0N4UFP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFP9"
  },
  {
    "uniprot": "A0A0N4UM06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM06"
  },
  {
    "uniprot": "A0A0N4UFV1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFV1"
  },
  {
    "uniprot": "A0A158Q5K8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5K8"
  },
  {
    "uniprot": "A0A0N4U650",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U650"
  },
  {
    "uniprot": "A0A0N4ULM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULM2"
  },
  {
    "uniprot": "A0A0N4UNU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNU4"
  },
  {
    "uniprot": "A0A0N4UN06",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN06"
  },
  {
    "uniprot": "A0A3P7PJ30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PJ30"
  },
  {
    "uniprot": "A0A0N4UF73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF73"
  },
  {
    "uniprot": "A0A0N4UNX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNX8"
  },
  {
    "uniprot": "A0A0N4UF91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF91"
  },
  {
    "uniprot": "A0A0N4UCF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCF9"
  },
  {
    "uniprot": "A0A0N4UCI7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCI7"
  },
  {
    "uniprot": "A0A0N4UL22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL22"
  },
  {
    "uniprot": "A0A0N4UFK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFK1"
  },
  {
    "uniprot": "A0A0N4UKL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKL8"
  },
  {
    "uniprot": "A0A0N4UNW7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNW7"
  },
  {
    "uniprot": "A0A0N4UL49",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL49"
  },
  {
    "uniprot": "A0A0N4U4Z3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Z3"
  },
  {
    "uniprot": "A0A0N4UAS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAS9"
  },
  {
    "uniprot": "A0A0N4UCA7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCA7"
  },
  {
    "uniprot": "A0A0N4URE9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URE9"
  },
  {
    "uniprot": "A0A158Q5D0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5D0"
  },
  {
    "uniprot": "A0A0N4UN60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN60"
  },
  {
    "uniprot": "A0A3P7T8Y6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T8Y6"
  },
  {
    "uniprot": "A0A0N4UGK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGK1"
  },
  {
    "uniprot": "A0A0N4U3H6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3H6"
  },
  {
    "uniprot": "A0A0N4UKK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKK2"
  },
  {
    "uniprot": "A0A3P7PPN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPN0"
  },
  {
    "uniprot": "A0A3P7TD81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TD81"
  },
  {
    "uniprot": "A0A0N4UKH8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKH8"
  },
  {
    "uniprot": "A0A0N4U3C2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3C2"
  },
  {
    "uniprot": "A0A3P7S5W4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S5W4"
  },
  {
    "uniprot": "A0A0N4U940",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U940"
  },
  {
    "uniprot": "A0A0N4UG74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG74"
  },
  {
    "uniprot": "A0A158Q437",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q437"
  },
  {
    "uniprot": "A0A0N4U971",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U971"
  },
  {
    "uniprot": "A0A0N4UEV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEV6"
  },
  {
    "uniprot": "A0A0N4U1T8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1T8"
  },
  {
    "uniprot": "A0A0N4UQL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQL9"
  },
  {
    "uniprot": "A0A0N4UNH7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNH7"
  },
  {
    "uniprot": "A0A0N4UM51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM51"
  },
  {
    "uniprot": "A0A0N4URF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URF3"
  },
  {
    "uniprot": "A0A158Q6L0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6L0"
  },
  {
    "uniprot": "A0A0N4UJF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJF9"
  },
  {
    "uniprot": "A0A0N4UGL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGL8"
  },
  {
    "uniprot": "A0A0N4U5R5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5R5"
  },
  {
    "uniprot": "A0A0N4ULA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULA0"
  },
  {
    "uniprot": "A0A0N4UQA3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQA3"
  },
  {
    "uniprot": "A0A0N4UEY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEY1"
  },
  {
    "uniprot": "A0A0N4UM08",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM08"
  },
  {
    "uniprot": "A0A0N4URK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URK3"
  },
  {
    "uniprot": "A0A0N4UR63",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR63"
  },
  {
    "uniprot": "A0A0N4UBV5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBV5"
  },
  {
    "uniprot": "A0A0N4UIK7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIK7"
  },
  {
    "uniprot": "A0A0N4U7C8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7C8"
  },
  {
    "uniprot": "A0A3P7PNW5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PNW5"
  },
  {
    "uniprot": "A0A0N4UJ42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ42"
  },
  {
    "uniprot": "A0A0N4UPF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPF9"
  },
  {
    "uniprot": "A0A0N4UAL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAL0"
  },
  {
    "uniprot": "A0A0N4UJU0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJU0"
  },
  {
    "uniprot": "A0A0N4UC73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC73"
  },
  {
    "uniprot": "A0A3P7Q9P0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q9P0"
  },
  {
    "uniprot": "A0A158Q3J8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3J8"
  },
  {
    "uniprot": "A0A0N4UKE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKE3"
  },
  {
    "uniprot": "A0A0N4UL61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL61"
  },
  {
    "uniprot": "A0A0N4UAU5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAU5"
  },
  {
    "uniprot": "A0A0N4UHP2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHP2"
  },
  {
    "uniprot": "A0A3P7PMU9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PMU9"
  },
  {
    "uniprot": "A0A0N4UHS8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHS8"
  },
  {
    "uniprot": "A0A0N4U109",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U109"
  },
  {
    "uniprot": "A0A0N4U9N3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9N3"
  },
  {
    "uniprot": "A0A0N4UPZ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPZ9"
  },
  {
    "uniprot": "A0A0N4UIA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIA2"
  },
  {
    "uniprot": "A0A0N4UHX0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHX0"
  },
  {
    "uniprot": "A0A0N4UGR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGR2"
  },
  {
    "uniprot": "A0A0N4U954",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U954"
  },
  {
    "uniprot": "A0A3P7QDC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QDC0"
  },
  {
    "uniprot": "A0A0N4UP89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP89"
  },
  {
    "uniprot": "A0A0N4UGP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGP3"
  },
  {
    "uniprot": "A0A0N4U2G3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2G3"
  },
  {
    "uniprot": "A0A0N4U2D4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2D4"
  },
  {
    "uniprot": "A0A0N4UF92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF92"
  },
  {
    "uniprot": "A0A0N4UKN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKN6"
  },
  {
    "uniprot": "A0A0N4USB8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USB8"
  },
  {
    "uniprot": "A0A0N4UJF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJF5"
  },
  {
    "uniprot": "A0A0N4U6Q1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Q1"
  },
  {
    "uniprot": "A0A0N4US60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US60"
  },
  {
    "uniprot": "A0A158Q6N0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6N0"
  },
  {
    "uniprot": "A0A158Q2U5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2U5"
  },
  {
    "uniprot": "A0A0N4UJV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJV7"
  },
  {
    "uniprot": "A0A0N4UB31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB31"
  },
  {
    "uniprot": "A0A0N4UPW9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPW9"
  },
  {
    "uniprot": "A0A0N4U9B8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9B8"
  },
  {
    "uniprot": "A0A0N4UGE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGE3"
  },
  {
    "uniprot": "A0A158Q3Z5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Z5"
  },
  {
    "uniprot": "A0A0N4UI58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI58"
  },
  {
    "uniprot": "A0A0N4UH31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH31"
  },
  {
    "uniprot": "A0A0N4U6D2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6D2"
  },
  {
    "uniprot": "A0A0N4UGZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGZ5"
  },
  {
    "uniprot": "A0A0N4U411",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U411"
  },
  {
    "uniprot": "A0A0N4UQ35",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ35"
  },
  {
    "uniprot": "A0A3P7Q625",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q625"
  },
  {
    "uniprot": "A0A0N4U504",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U504"
  },
  {
    "uniprot": "A0A0N4URL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URL3"
  },
  {
    "uniprot": "A0A0N4UL27",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL27"
  },
  {
    "uniprot": "A0A0N4U178",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U178"
  },
  {
    "uniprot": "A0A0N4UG22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG22"
  },
  {
    "uniprot": "A0A0N4U8A0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8A0"
  },
  {
    "uniprot": "A0A0N4UL40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL40"
  },
  {
    "uniprot": "A0A0N4U9Q6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Q6"
  },
  {
    "uniprot": "A0A0N4U694",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U694"
  },
  {
    "uniprot": "A0A0N4U4X8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4X8"
  },
  {
    "uniprot": "A0A0N4UPF4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPF4"
  },
  {
    "uniprot": "A0A0N4UEH7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEH7"
  },
  {
    "uniprot": "A0A0N4U0N4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0N4"
  },
  {
    "uniprot": "A0A0N4U7U1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7U1"
  },
  {
    "uniprot": "A0A0N4US51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US51"
  },
  {
    "uniprot": "A0A0N4U2P8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2P8"
  },
  {
    "uniprot": "A0A0N4UDX7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDX7"
  },
  {
    "uniprot": "A0A0N4U1Y8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Y8"
  },
  {
    "uniprot": "A0A0N4UJ55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ55"
  },
  {
    "uniprot": "A0A0N4U9W6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9W6"
  },
  {
    "uniprot": "A0A0N4UL33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL33"
  },
  {
    "uniprot": "A0A0N4UDU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDU4"
  },
  {
    "uniprot": "A0A158Q6L3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6L3"
  },
  {
    "uniprot": "A0A0N4UQF6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQF6"
  },
  {
    "uniprot": "A0A0N4UQQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQQ7"
  },
  {
    "uniprot": "A0A3P7PV30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PV30"
  },
  {
    "uniprot": "A0A0N4UJN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJN9"
  },
  {
    "uniprot": "A0A0N4U7J5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7J5"
  },
  {
    "uniprot": "A0A0N4UEJ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEJ5"
  },
  {
    "uniprot": "A0A3P7TD22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TD22"
  },
  {
    "uniprot": "A0A0N4UJB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJB9"
  },
  {
    "uniprot": "A0A0N4U7H6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7H6"
  },
  {
    "uniprot": "A0A3P7SYY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SYY2"
  },
  {
    "uniprot": "A0A0N4U6A3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6A3"
  },
  {
    "uniprot": "A0A0N4UQ26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ26"
  },
  {
    "uniprot": "A0A0N4U428",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U428"
  },
  {
    "uniprot": "A0A0N4U838",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U838"
  },
  {
    "uniprot": "A0A0N4UDC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDC3"
  },
  {
    "uniprot": "A0A3P7PIK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PIK2"
  },
  {
    "uniprot": "A0A0N4U1A6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1A6"
  },
  {
    "uniprot": "A0A0N4U271",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U271"
  },
  {
    "uniprot": "A0A3P7PC73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PC73"
  },
  {
    "uniprot": "A0A0N4UR61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR61"
  },
  {
    "uniprot": "A0A3P7SJ14",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SJ14"
  },
  {
    "uniprot": "A0A0N4UKN7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKN7"
  },
  {
    "uniprot": "A0A0N4US81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US81"
  },
  {
    "uniprot": "A0A0N4U6K6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6K6"
  },
  {
    "uniprot": "A0A0N4UN11",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN11"
  },
  {
    "uniprot": "A0A0N4U452",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U452"
  },
  {
    "uniprot": "A0A0N4UKS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKS9"
  },
  {
    "uniprot": "A0A3P7SYP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SYP1"
  },
  {
    "uniprot": "A0A0N4UCG5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCG5"
  },
  {
    "uniprot": "A0A0N4U9V2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9V2"
  },
  {
    "uniprot": "A0A158Q3K7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3K7"
  },
  {
    "uniprot": "A0A158Q602",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q602"
  },
  {
    "uniprot": "A0A0N4U1P0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1P0"
  },
  {
    "uniprot": "A0A0N4UKC9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKC9"
  },
  {
    "uniprot": "A0A0N4U7K7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7K7"
  },
  {
    "uniprot": "A0A0N4U8K3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8K3"
  },
  {
    "uniprot": "A0A158Q455",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q455"
  },
  {
    "uniprot": "A0A0N4UJA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJA8"
  },
  {
    "uniprot": "A0A158Q3Q5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Q5"
  },
  {
    "uniprot": "A0A0N4UEK7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEK7"
  },
  {
    "uniprot": "A0A158Q4U9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4U9"
  },
  {
    "uniprot": "A0A0N4UJJ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJJ3"
  },
  {
    "uniprot": "A0A0N4U5A3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5A3"
  },
  {
    "uniprot": "A0A0N4URF4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URF4"
  },
  {
    "uniprot": "A0A0N4UIP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIP4"
  },
  {
    "uniprot": "A0A0N4U3N9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3N9"
  },
  {
    "uniprot": "A0A0N4U0K0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0K0"
  },
  {
    "uniprot": "A0A0N4UGK9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGK9"
  },
  {
    "uniprot": "A0A0N4UKD8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKD8"
  },
  {
    "uniprot": "A0A0N4U294",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U294"
  },
  {
    "uniprot": "A0A0N4UB64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB64"
  },
  {
    "uniprot": "A0A0N4ULG7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULG7"
  },
  {
    "uniprot": "A0A158Q4K7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4K7"
  },
  {
    "uniprot": "A0A3P7PLR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PLR1"
  },
  {
    "uniprot": "A0A0N4UGA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGA9"
  },
  {
    "uniprot": "A0A0N4UD94",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD94"
  },
  {
    "uniprot": "A0A158Q417",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q417"
  },
  {
    "uniprot": "A0A158Q3L5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3L5"
  },
  {
    "uniprot": "A0A158Q3I4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3I4"
  },
  {
    "uniprot": "A0A0N4U8R3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8R3"
  },
  {
    "uniprot": "A0A3P7PM95",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PM95"
  },
  {
    "uniprot": "A0A0N4ULK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULK3"
  },
  {
    "uniprot": "A0A158Q2J8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2J8"
  },
  {
    "uniprot": "A0A0N4U116",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U116"
  },
  {
    "uniprot": "A0A3P7QBL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QBL3"
  },
  {
    "uniprot": "A0A0N4UBQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBQ6"
  },
  {
    "uniprot": "A0A0N4UEM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEM0"
  },
  {
    "uniprot": "A0A0N4UND2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UND2"
  },
  {
    "uniprot": "A0A0N4UQP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQP9"
  },
  {
    "uniprot": "A0A0N4U4A7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4A7"
  },
  {
    "uniprot": "A0A158Q4L4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4L4"
  },
  {
    "uniprot": "A0A3P7Q319",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q319"
  },
  {
    "uniprot": "A0A0N4U1C4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1C4"
  },
  {
    "uniprot": "A0A0N4UBE7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBE7"
  },
  {
    "uniprot": "A0A0N4UH99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH99"
  },
  {
    "uniprot": "A0A0N4UJM3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJM3"
  },
  {
    "uniprot": "A0A0N4UKL2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKL2"
  },
  {
    "uniprot": "A0A0N4U287",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U287"
  },
  {
    "uniprot": "A0A0N4UBT1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBT1"
  },
  {
    "uniprot": "A0A0N4UGR1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGR1"
  },
  {
    "uniprot": "A0A0N4UD84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD84"
  },
  {
    "uniprot": "A0A3P7SHA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SHA9"
  },
  {
    "uniprot": "A0A0N4U2I9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2I9"
  },
  {
    "uniprot": "A0A0N4U0S1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0S1"
  },
  {
    "uniprot": "A0A0N4UQG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQG2"
  },
  {
    "uniprot": "A0A0N4UIA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIA9"
  },
  {
    "uniprot": "A0A0N4U0D0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0D0"
  },
  {
    "uniprot": "A0A0N4UJ56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ56"
  },
  {
    "uniprot": "A0A0N4U5J3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5J3"
  },
  {
    "uniprot": "A0A0N4UR56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR56"
  },
  {
    "uniprot": "A0A158Q5N1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5N1"
  },
  {
    "uniprot": "A0A0N4UNA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNA9"
  },
  {
    "uniprot": "A0A0N4UP83",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP83"
  },
  {
    "uniprot": "A0A3P7SV47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SV47"
  },
  {
    "uniprot": "A0A0N4UK73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK73"
  },
  {
    "uniprot": "A0A0N4U202",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U202"
  },
  {
    "uniprot": "A0A3P7P9G3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P9G3"
  },
  {
    "uniprot": "A0A0N4UKA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKA9"
  },
  {
    "uniprot": "A0A0N4UMA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMA5"
  },
  {
    "uniprot": "A0A158Q4L8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4L8"
  },
  {
    "uniprot": "A0A0N4UHW4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHW4"
  },
  {
    "uniprot": "A0A3P7PXP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PXP3"
  },
  {
    "uniprot": "A0A0N4UNM3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNM3"
  },
  {
    "uniprot": "A0A0N4UMF6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMF6"
  },
  {
    "uniprot": "A0A0N4ULY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULY7"
  },
  {
    "uniprot": "A0A0N4US13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US13"
  },
  {
    "uniprot": "A0A0N4UF50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF50"
  },
  {
    "uniprot": "A0A0N4U2N1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2N1"
  },
  {
    "uniprot": "A0A0N4UIK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIK6"
  },
  {
    "uniprot": "A0A3P7SJK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SJK3"
  },
  {
    "uniprot": "A0A158Q512",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q512"
  },
  {
    "uniprot": "A0A0N4URJ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URJ4"
  },
  {
    "uniprot": "A0A0N4UDT4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDT4"
  },
  {
    "uniprot": "A0A0N4UDC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDC6"
  },
  {
    "uniprot": "A0A0N4U8A1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8A1"
  },
  {
    "uniprot": "A0A0N4ULV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULV7"
  },
  {
    "uniprot": "A0A0N4UG63",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG63"
  },
  {
    "uniprot": "A0A0N4UB92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB92"
  },
  {
    "uniprot": "A0A0N4UET8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UET8"
  },
  {
    "uniprot": "A0A0N4UHE2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHE2"
  },
  {
    "uniprot": "A0A0N4U8K0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8K0"
  },
  {
    "uniprot": "A0A0N4UGY8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGY8"
  },
  {
    "uniprot": "A0A0N4U4J7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4J7"
  },
  {
    "uniprot": "A0A0N4UR23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR23"
  },
  {
    "uniprot": "A0A0N4U280",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U280"
  },
  {
    "uniprot": "A0A3P7Q0N2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q0N2"
  },
  {
    "uniprot": "A0A0N4UP96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP96"
  },
  {
    "uniprot": "A0A3P7QJW1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QJW1"
  },
  {
    "uniprot": "A0A0N4U3P0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3P0"
  },
  {
    "uniprot": "A0A3P7T7H0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T7H0"
  },
  {
    "uniprot": "A0A0N4U2S4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2S4"
  },
  {
    "uniprot": "A0A0N4U5Y6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Y6"
  },
  {
    "uniprot": "A0A0N4UB63",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB63"
  },
  {
    "uniprot": "A0A0N4UHD1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHD1"
  },
  {
    "uniprot": "A0A0N4U946",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U946"
  },
  {
    "uniprot": "A0A158Q3T3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3T3"
  },
  {
    "uniprot": "A0A0N4UBE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBE3"
  },
  {
    "uniprot": "A0A0N4ULV2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULV2"
  },
  {
    "uniprot": "A0A0N4U7P1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7P1"
  },
  {
    "uniprot": "A0A0N4U288",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U288"
  },
  {
    "uniprot": "A0A0N4UIT7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIT7"
  },
  {
    "uniprot": "A0A0N4UA15",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA15"
  },
  {
    "uniprot": "A0A0N4UAV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAV7"
  },
  {
    "uniprot": "A0A0N4UMR6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMR6"
  },
  {
    "uniprot": "A0A0N4U9X8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9X8"
  },
  {
    "uniprot": "A0A0N4U2V2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2V2"
  },
  {
    "uniprot": "A0A0N4UNH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNH2"
  },
  {
    "uniprot": "A0A3P7SCP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SCP1"
  },
  {
    "uniprot": "A0A0N4UEV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEV7"
  },
  {
    "uniprot": "A0A0N4UJH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJH1"
  },
  {
    "uniprot": "A0A0N4UQU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQU3"
  },
  {
    "uniprot": "A0A3P7PL15",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PL15"
  },
  {
    "uniprot": "A0A0N4ULS1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULS1"
  },
  {
    "uniprot": "A0A0N4U8H8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8H8"
  },
  {
    "uniprot": "A0A0N4UC44",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC44"
  },
  {
    "uniprot": "A0A0N4U9Y6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Y6"
  },
  {
    "uniprot": "A0A0N4UHT1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHT1"
  },
  {
    "uniprot": "A0A0N4UFM1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFM1"
  },
  {
    "uniprot": "A0A0N4UDL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDL3"
  },
  {
    "uniprot": "A0A0N4UEX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEX5"
  },
  {
    "uniprot": "A0A158Q4J8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4J8"
  },
  {
    "uniprot": "A0A0N4U671",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U671"
  },
  {
    "uniprot": "A0A3P7SRU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SRU4"
  },
  {
    "uniprot": "A0A0N4UBA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBA4"
  },
  {
    "uniprot": "A0A0N4URR3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URR3"
  },
  {
    "uniprot": "A0A0N4U663",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U663"
  },
  {
    "uniprot": "A0A158Q4Z5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Z5"
  },
  {
    "uniprot": "A0A158Q5D2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5D2"
  },
  {
    "uniprot": "A0A0N4U4Q5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Q5"
  },
  {
    "uniprot": "A0A158Q4Z2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Z2"
  },
  {
    "uniprot": "A0A158Q2T1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2T1"
  },
  {
    "uniprot": "A0A0N4UBK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBK2"
  },
  {
    "uniprot": "A0A0N4UL88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL88"
  },
  {
    "uniprot": "A0A0N4UCA8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCA8"
  },
  {
    "uniprot": "A0A0N4U335",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U335"
  },
  {
    "uniprot": "A0A0N4UKM6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKM6"
  },
  {
    "uniprot": "A0A0N4UM93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM93"
  },
  {
    "uniprot": "A0A0N4UKI2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKI2"
  },
  {
    "uniprot": "A0A0N4UD29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD29"
  },
  {
    "uniprot": "A0A0N4UD54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD54"
  },
  {
    "uniprot": "A0A0N4U927",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U927"
  },
  {
    "uniprot": "A0A0N4UH82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH82"
  },
  {
    "uniprot": "A0A0N4UAP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAP4"
  },
  {
    "uniprot": "A0A0N4UH53",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH53"
  },
  {
    "uniprot": "A0A0N4UIL6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIL6"
  },
  {
    "uniprot": "A0A0N4U0R1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0R1"
  },
  {
    "uniprot": "A0A158Q566",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q566"
  },
  {
    "uniprot": "A0A158Q4W7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4W7"
  },
  {
    "uniprot": "A0A0N4U8R2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8R2"
  },
  {
    "uniprot": "A0A0N4USA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USA0"
  },
  {
    "uniprot": "A0A158Q5E1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5E1"
  },
  {
    "uniprot": "A0A0N4UE00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE00"
  },
  {
    "uniprot": "A0A0N4UA40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA40"
  },
  {
    "uniprot": "A0A0N4UC64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC64"
  },
  {
    "uniprot": "A0A0N4UR57",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR57"
  },
  {
    "uniprot": "A0A0N4UDV9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDV9"
  },
  {
    "uniprot": "A0A0N4UK45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK45"
  },
  {
    "uniprot": "A0A158Q393",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q393"
  },
  {
    "uniprot": "A0A0N4U5U2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5U2"
  },
  {
    "uniprot": "A0A3P7PGF7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PGF7"
  },
  {
    "uniprot": "A0A3P7PZF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PZF3"
  },
  {
    "uniprot": "A0A158Q3H0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3H0"
  },
  {
    "uniprot": "A0A0N4UDQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDQ1"
  },
  {
    "uniprot": "A0A0N4UBJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBJ2"
  },
  {
    "uniprot": "A0A0N4U674",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U674"
  },
  {
    "uniprot": "A0A0N4UH46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH46"
  },
  {
    "uniprot": "A0A0N4UN85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN85"
  },
  {
    "uniprot": "A0A0N4U6L6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6L6"
  },
  {
    "uniprot": "A0A3P7Q9T2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q9T2"
  },
  {
    "uniprot": "A0A0N4UN74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN74"
  },
  {
    "uniprot": "A0A3P7PT91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PT91"
  },
  {
    "uniprot": "A0A0N4UQZ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQZ8"
  },
  {
    "uniprot": "A0A158Q4V4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4V4"
  },
  {
    "uniprot": "A0A158Q3R3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3R3"
  },
  {
    "uniprot": "A0A0N4U3F4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3F4"
  },
  {
    "uniprot": "A0A0N4UMS3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMS3"
  },
  {
    "uniprot": "A0A0N4UA34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA34"
  },
  {
    "uniprot": "A0A158Q309",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q309"
  },
  {
    "uniprot": "A0A0N4UH18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH18"
  },
  {
    "uniprot": "A0A0N4UR90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR90"
  },
  {
    "uniprot": "A0A0N4UIB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIB2"
  },
  {
    "uniprot": "A0A0N4UCM1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCM1"
  },
  {
    "uniprot": "A0A0N4UFG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFG3"
  },
  {
    "uniprot": "A0A0N4UQU9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQU9"
  },
  {
    "uniprot": "A0A0N4UIS5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIS5"
  },
  {
    "uniprot": "A0A0N4UDS0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDS0"
  },
  {
    "uniprot": "A0A158Q3D0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3D0"
  },
  {
    "uniprot": "A0A0N4UBM0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBM0"
  },
  {
    "uniprot": "A0A0N4UAX4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAX4"
  },
  {
    "uniprot": "A0A0N4U3U3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3U3"
  },
  {
    "uniprot": "A0A0N4UQY5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQY5"
  },
  {
    "uniprot": "A0A0N4URZ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URZ4"
  },
  {
    "uniprot": "A0A0N4UJZ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJZ9"
  },
  {
    "uniprot": "A0A0N4U2E2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2E2"
  },
  {
    "uniprot": "A0A0N4U3H7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3H7"
  },
  {
    "uniprot": "A0A0N4UCG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCG9"
  },
  {
    "uniprot": "A0A0N4U7P2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7P2"
  },
  {
    "uniprot": "A0A0N4US59",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US59"
  },
  {
    "uniprot": "A0A0N4U506",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U506"
  },
  {
    "uniprot": "A0A0N4U0L1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0L1"
  },
  {
    "uniprot": "A0A0N4U4L0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4L0"
  },
  {
    "uniprot": "A0A158Q4N0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4N0"
  },
  {
    "uniprot": "A0A0N4URK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URK6"
  },
  {
    "uniprot": "A0A0N4U1R7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1R7"
  },
  {
    "uniprot": "A0A3P7PM69",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PM69"
  },
  {
    "uniprot": "A0A0N4UFT5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFT5"
  },
  {
    "uniprot": "A0A0N4U8M8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8M8"
  },
  {
    "uniprot": "A0A0N4UQV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQV6"
  },
  {
    "uniprot": "A0A0N4URA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URA9"
  },
  {
    "uniprot": "A0A158Q502",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q502"
  },
  {
    "uniprot": "A0A158Q4I5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4I5"
  },
  {
    "uniprot": "A0A0N4U702",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U702"
  },
  {
    "uniprot": "A0A0N4UI96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI96"
  },
  {
    "uniprot": "A0A0N4UER3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UER3"
  },
  {
    "uniprot": "A0A0N4UR68",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR68"
  },
  {
    "uniprot": "A0A0N4U3Z7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Z7"
  },
  {
    "uniprot": "A0A0N4UBV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBV3"
  },
  {
    "uniprot": "A0A0N4U1V4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1V4"
  },
  {
    "uniprot": "A0A158Q2R6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2R6"
  },
  {
    "uniprot": "A0A0N4UA22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA22"
  },
  {
    "uniprot": "A0A0N4UQU8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQU8"
  },
  {
    "uniprot": "A0A0N4UHR8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHR8"
  },
  {
    "uniprot": "A0A0N4UJQ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJQ9"
  },
  {
    "uniprot": "A0A0N4U1Q4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Q4"
  },
  {
    "uniprot": "A0A0N4U4H0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4H0"
  },
  {
    "uniprot": "A0A0N4U8B0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8B0"
  },
  {
    "uniprot": "A0A0N4UEX0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEX0"
  },
  {
    "uniprot": "A0A0N4U8A3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8A3"
  },
  {
    "uniprot": "A0A0N4UQM1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQM1"
  },
  {
    "uniprot": "A0A0N4URJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URJ2"
  },
  {
    "uniprot": "A0A0N4UJ02",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ02"
  },
  {
    "uniprot": "A0A0N4UJ34",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ34"
  },
  {
    "uniprot": "A0A158Q638",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q638"
  },
  {
    "uniprot": "A0A0N4UDX2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDX2"
  },
  {
    "uniprot": "A0A0N4UMC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMC1"
  },
  {
    "uniprot": "A0A3P7SNY2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SNY2"
  },
  {
    "uniprot": "A0A0N4U0R7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0R7"
  },
  {
    "uniprot": "A0A0N4UI42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI42"
  },
  {
    "uniprot": "A0A0N4U915",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U915"
  },
  {
    "uniprot": "A0A0N4URM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URM8"
  },
  {
    "uniprot": "A0A0N4UPV5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPV5"
  },
  {
    "uniprot": "A0A3P7SW30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SW30"
  },
  {
    "uniprot": "A0A0N4UAC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAC2"
  },
  {
    "uniprot": "A0A0N4ULM4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULM4"
  },
  {
    "uniprot": "A0A0N4UE74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE74"
  },
  {
    "uniprot": "A0A0N4UAI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAI0"
  },
  {
    "uniprot": "A0A0N4UC16",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC16"
  },
  {
    "uniprot": "A0A0N4UQC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQC3"
  },
  {
    "uniprot": "A0A0N4U9X9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9X9"
  },
  {
    "uniprot": "A0A158Q329",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q329"
  },
  {
    "uniprot": "A0A0N4U4B1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4B1"
  },
  {
    "uniprot": "A0A0N4U568",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U568"
  },
  {
    "uniprot": "A0A0N4U341",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U341"
  },
  {
    "uniprot": "A0A0N4ULK9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULK9"
  },
  {
    "uniprot": "A0A0N4U8M3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8M3"
  },
  {
    "uniprot": "A0A3P7PTM9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PTM9"
  },
  {
    "uniprot": "A0A0N4UA99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA99"
  },
  {
    "uniprot": "A0A3P7Q7B4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q7B4"
  },
  {
    "uniprot": "A0A0N4U520",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U520"
  },
  {
    "uniprot": "A0A0N4U0F3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0F3"
  },
  {
    "uniprot": "A0A0N4U493",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U493"
  },
  {
    "uniprot": "A0A0N4U9A0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9A0"
  },
  {
    "uniprot": "A0A0N4UKZ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKZ3"
  },
  {
    "uniprot": "A0A0N4UR29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR29"
  },
  {
    "uniprot": "A0A0N4U6J1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6J1"
  },
  {
    "uniprot": "A0A0N4UG84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG84"
  },
  {
    "uniprot": "A0A0N4UJQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJQ3"
  },
  {
    "uniprot": "A0A158Q2U0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2U0"
  },
  {
    "uniprot": "A0A0N4U4X1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4X1"
  },
  {
    "uniprot": "A0A0N4UEC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEC4"
  },
  {
    "uniprot": "A0A0N4UFX3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFX3"
  },
  {
    "uniprot": "A0A0N4U967",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U967"
  },
  {
    "uniprot": "A0A158Q2J9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2J9"
  },
  {
    "uniprot": "A0A0N4UGC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGC1"
  },
  {
    "uniprot": "A0A0N4UH14",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH14"
  },
  {
    "uniprot": "A0A0N4UAL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAL9"
  },
  {
    "uniprot": "A0A3P7SGF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SGF3"
  },
  {
    "uniprot": "A0A0N4UCI2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCI2"
  },
  {
    "uniprot": "A0A0N4U357",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U357"
  },
  {
    "uniprot": "A0A0N4UN94",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN94"
  },
  {
    "uniprot": "A0A158Q416",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q416"
  },
  {
    "uniprot": "A0A0N4UIK4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIK4"
  },
  {
    "uniprot": "A0A0N4U641",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U641"
  },
  {
    "uniprot": "A0A158Q6N6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6N6"
  },
  {
    "uniprot": "A0A3P7PKG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PKG4"
  },
  {
    "uniprot": "A0A0N4UAL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAL8"
  },
  {
    "uniprot": "A0A0N4U2N9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2N9"
  },
  {
    "uniprot": "A0A0N4UD60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD60"
  },
  {
    "uniprot": "A0A0N4UAG7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAG7"
  },
  {
    "uniprot": "A0A0N4U582",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U582"
  },
  {
    "uniprot": "A0A158Q4I0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4I0"
  },
  {
    "uniprot": "A0A0N4UHS0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHS0"
  },
  {
    "uniprot": "A0A0N4U0K6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0K6"
  },
  {
    "uniprot": "A0A0N4UBR3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBR3"
  },
  {
    "uniprot": "A0A0N4UIF0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIF0"
  },
  {
    "uniprot": "A0A0N4U9I7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9I7"
  },
  {
    "uniprot": "A0A0N4UK96",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK96"
  },
  {
    "uniprot": "A0A3P7PB56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PB56"
  },
  {
    "uniprot": "A0A0N4UH89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH89"
  },
  {
    "uniprot": "A0A0N4UM83",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM83"
  },
  {
    "uniprot": "A0A3P7QZR5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QZR5"
  },
  {
    "uniprot": "A0A0N4UAW4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAW4"
  },
  {
    "uniprot": "A0A3P7SV29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SV29"
  },
  {
    "uniprot": "A0A0N4UDW5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDW5"
  },
  {
    "uniprot": "A0A0N4UF25",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF25"
  },
  {
    "uniprot": "A0A0N4UBD0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBD0"
  },
  {
    "uniprot": "A0A0N4UN84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN84"
  },
  {
    "uniprot": "A0A3P7T083",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T083"
  },
  {
    "uniprot": "A0A0N4U0G1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0G1"
  },
  {
    "uniprot": "A0A0N4UNU9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNU9"
  },
  {
    "uniprot": "A0A0N4UBD7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBD7"
  },
  {
    "uniprot": "A0A0N4ULZ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULZ8"
  },
  {
    "uniprot": "A0A0N4U3J8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3J8"
  },
  {
    "uniprot": "A0A0N4U2H9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2H9"
  },
  {
    "uniprot": "A0A0N4URL1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URL1"
  },
  {
    "uniprot": "A0A0N4URB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URB1"
  },
  {
    "uniprot": "A0A3P7Q330",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q330"
  },
  {
    "uniprot": "A0A158Q6C9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6C9"
  },
  {
    "uniprot": "A0A158Q4H8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4H8"
  },
  {
    "uniprot": "A0A0N4UJ65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ65"
  },
  {
    "uniprot": "A0A0N4U4A1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4A1"
  },
  {
    "uniprot": "A0A0N4UGV1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGV1"
  },
  {
    "uniprot": "A0A0N4UJL7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJL7"
  },
  {
    "uniprot": "A0A0N4U8G8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8G8"
  },
  {
    "uniprot": "A0A0N4U7X3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7X3"
  },
  {
    "uniprot": "A0A0N4UAX3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAX3"
  },
  {
    "uniprot": "A0A158Q2H6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2H6"
  },
  {
    "uniprot": "A0A3P7PS73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PS73"
  },
  {
    "uniprot": "A0A0N4UJ49",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ49"
  },
  {
    "uniprot": "A0A158Q528",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q528"
  },
  {
    "uniprot": "A0A0N4U695",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U695"
  },
  {
    "uniprot": "A0A0N4UHR0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHR0"
  },
  {
    "uniprot": "A0A158Q4U1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4U1"
  },
  {
    "uniprot": "A0A3P7T3B7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T3B7"
  },
  {
    "uniprot": "A0A0N4ULN5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULN5"
  },
  {
    "uniprot": "A0A0N4UKC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKC0"
  },
  {
    "uniprot": "A0A0N4UP61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP61"
  },
  {
    "uniprot": "A0A0N4UMH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMH1"
  },
  {
    "uniprot": "A0A3P7Q4T6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q4T6"
  },
  {
    "uniprot": "A0A0N4U961",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U961"
  },
  {
    "uniprot": "A0A158Q315",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q315"
  },
  {
    "uniprot": "A0A0N4UPU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPU3"
  },
  {
    "uniprot": "A0A0N4U535",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U535"
  },
  {
    "uniprot": "A0A0N4U9F0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9F0"
  },
  {
    "uniprot": "A0A0N4UFL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFL0"
  },
  {
    "uniprot": "A0A0N4UL58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL58"
  },
  {
    "uniprot": "A0A0N4ULU4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULU4"
  },
  {
    "uniprot": "A0A0N4US41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US41"
  },
  {
    "uniprot": "A0A0N4UIT0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIT0"
  },
  {
    "uniprot": "A0A158Q663",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q663"
  },
  {
    "uniprot": "A0A158Q3H3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3H3"
  },
  {
    "uniprot": "A0A0N4UR04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR04"
  },
  {
    "uniprot": "A0A0N4U9U4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9U4"
  },
  {
    "uniprot": "A0A0N4U1Z9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Z9"
  },
  {
    "uniprot": "A0A0N4U352",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U352"
  },
  {
    "uniprot": "A0A3P7PQN4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQN4"
  },
  {
    "uniprot": "A0A3P7PJK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PJK6"
  },
  {
    "uniprot": "A0A0N4UJP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJP0"
  },
  {
    "uniprot": "A0A0N4UL42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL42"
  },
  {
    "uniprot": "A0A0N4UEL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEL0"
  },
  {
    "uniprot": "A0A0N4U5X2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5X2"
  },
  {
    "uniprot": "A0A0N4U939",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U939"
  },
  {
    "uniprot": "A0A0N4UHC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHC2"
  },
  {
    "uniprot": "A0A158Q660",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q660"
  },
  {
    "uniprot": "A0A0N4URP8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URP8"
  },
  {
    "uniprot": "A0A158Q3J4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3J4"
  },
  {
    "uniprot": "A0A0N4UKF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKF3"
  },
  {
    "uniprot": "A0A0N4UDL1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDL1"
  },
  {
    "uniprot": "A0A3P7PPP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPP3"
  },
  {
    "uniprot": "A0A3P7Q847",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q847"
  },
  {
    "uniprot": "A0A3P7PET8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PET8"
  },
  {
    "uniprot": "A0A0N4UP16",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP16"
  },
  {
    "uniprot": "A0A0N4U3H9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3H9"
  },
  {
    "uniprot": "A0A158Q5E8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5E8"
  },
  {
    "uniprot": "A0A158Q6A6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6A6"
  },
  {
    "uniprot": "A0A3P7PIP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PIP9"
  },
  {
    "uniprot": "A0A0N4URR0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URR0"
  },
  {
    "uniprot": "A0A0N4UQI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQI0"
  },
  {
    "uniprot": "A0A0N4U814",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U814"
  },
  {
    "uniprot": "A0A3P7SCG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SCG9"
  },
  {
    "uniprot": "A0A0N4UKT8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKT8"
  },
  {
    "uniprot": "A0A0N4U2J7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2J7"
  },
  {
    "uniprot": "A0A0N4UMX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMX1"
  },
  {
    "uniprot": "A0A158Q596",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q596"
  },
  {
    "uniprot": "A0A0N4U3E5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3E5"
  },
  {
    "uniprot": "A0A0N4U831",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U831"
  },
  {
    "uniprot": "A0A0N4UAA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAA9"
  },
  {
    "uniprot": "A0A0N4U0W1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0W1"
  },
  {
    "uniprot": "A0A0N4US29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US29"
  },
  {
    "uniprot": "A0A0N4UP40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP40"
  },
  {
    "uniprot": "A0A158Q3I8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3I8"
  },
  {
    "uniprot": "A0A158Q432",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q432"
  },
  {
    "uniprot": "A0A0N4U7T2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7T2"
  },
  {
    "uniprot": "A0A0N4UHG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHG3"
  },
  {
    "uniprot": "A0A0N4UK05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK05"
  },
  {
    "uniprot": "A0A158Q4P2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4P2"
  },
  {
    "uniprot": "A0A0N4UD12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD12"
  },
  {
    "uniprot": "A0A0N4UNM9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNM9"
  },
  {
    "uniprot": "A0A0N4UC58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC58"
  },
  {
    "uniprot": "A0A0N4URN5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URN5"
  },
  {
    "uniprot": "A0A0N4U6V6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6V6"
  },
  {
    "uniprot": "A0A0N4UBX3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBX3"
  },
  {
    "uniprot": "A0A0N4U6Y1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Y1"
  },
  {
    "uniprot": "A0A0N4ULI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULI0"
  },
  {
    "uniprot": "A0A3P7PRY6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PRY6"
  },
  {
    "uniprot": "A0A0N4UQ23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ23"
  },
  {
    "uniprot": "A0A0N4U220",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U220"
  },
  {
    "uniprot": "A0A0N4UKQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKQ4"
  },
  {
    "uniprot": "A0A0N4UL43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL43"
  },
  {
    "uniprot": "A0A0N4UR42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR42"
  },
  {
    "uniprot": "A0A0N4UPK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPK2"
  },
  {
    "uniprot": "A0A0N4UFI4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFI4"
  },
  {
    "uniprot": "A0A0N4UDI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDI1"
  },
  {
    "uniprot": "A0A0N4UIJ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIJ5"
  },
  {
    "uniprot": "A0A0N4UJW2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJW2"
  },
  {
    "uniprot": "A0A0N4UB43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB43"
  },
  {
    "uniprot": "A0A0N4U6B6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6B6"
  },
  {
    "uniprot": "A0A0N4UHM5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHM5"
  },
  {
    "uniprot": "A0A0N4U2K2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2K2"
  },
  {
    "uniprot": "A0A0N4U6S6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6S6"
  },
  {
    "uniprot": "A0A0N4UP92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP92"
  },
  {
    "uniprot": "A0A158Q5U0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5U0"
  },
  {
    "uniprot": "A0A3P7SZZ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SZZ5"
  },
  {
    "uniprot": "A0A0N4UAU3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAU3"
  },
  {
    "uniprot": "A0A0N4UH20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH20"
  },
  {
    "uniprot": "A0A0N4UBF2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBF2"
  },
  {
    "uniprot": "A0A0N4UQE2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQE2"
  },
  {
    "uniprot": "A0A0N4UGB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGB3"
  },
  {
    "uniprot": "A0A0N4UD25",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD25"
  },
  {
    "uniprot": "A0A0N4UPB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPB1"
  },
  {
    "uniprot": "A0A0N4UKV5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKV5"
  },
  {
    "uniprot": "A0A3P7PT38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PT38"
  },
  {
    "uniprot": "A0A0N4UQ99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ99"
  },
  {
    "uniprot": "A0A0N4UBV2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBV2"
  },
  {
    "uniprot": "A0A0N4U2Z7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Z7"
  },
  {
    "uniprot": "A0A0N4ULW2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULW2"
  },
  {
    "uniprot": "A0A0N4U0X4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0X4"
  },
  {
    "uniprot": "A0A0N4U5Z2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Z2"
  },
  {
    "uniprot": "A0A158Q4U0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4U0"
  },
  {
    "uniprot": "A0A0N4U0Q3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Q3"
  },
  {
    "uniprot": "A0A0N4U4C1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4C1"
  },
  {
    "uniprot": "A0A0N4UCN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCN0"
  },
  {
    "uniprot": "A0A0N4UQC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQC0"
  },
  {
    "uniprot": "A0A158Q339",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q339"
  },
  {
    "uniprot": "A0A3P7SL82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SL82"
  },
  {
    "uniprot": "A0A0N4U187",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U187"
  },
  {
    "uniprot": "A0A0N4UAJ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAJ6"
  },
  {
    "uniprot": "A0A0N4U783",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U783"
  },
  {
    "uniprot": "A0A3P7PUF1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PUF1"
  },
  {
    "uniprot": "A0A0N4U9U8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9U8"
  },
  {
    "uniprot": "A0A0N4UCX0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCX0"
  },
  {
    "uniprot": "A0A0N4UQJ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQJ6"
  },
  {
    "uniprot": "A0A0N4U1B8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1B8"
  },
  {
    "uniprot": "A0A0N4U664",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U664"
  },
  {
    "uniprot": "A0A0N4U6B1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6B1"
  },
  {
    "uniprot": "A0A0N4U821",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U821"
  },
  {
    "uniprot": "A0A0N4U3X5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3X5"
  },
  {
    "uniprot": "A0A0N4UEB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEB3"
  },
  {
    "uniprot": "A0A0N4U2Q8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Q8"
  },
  {
    "uniprot": "A0A0N4UJ86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ86"
  },
  {
    "uniprot": "A0A0N4UMF4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMF4"
  },
  {
    "uniprot": "A0A0N4U9J7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9J7"
  },
  {
    "uniprot": "A0A0N4UHF2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHF2"
  },
  {
    "uniprot": "A0A0N4U4U4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4U4"
  },
  {
    "uniprot": "A0A0N4U276",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U276"
  },
  {
    "uniprot": "A0A3P7Q2Z2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q2Z2"
  },
  {
    "uniprot": "A0A0N4UKA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKA6"
  },
  {
    "uniprot": "A0A0N4UP10",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP10"
  },
  {
    "uniprot": "A0A3P7S9R1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S9R1"
  },
  {
    "uniprot": "A0A0N4U573",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U573"
  },
  {
    "uniprot": "A0A0N4UA73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA73"
  },
  {
    "uniprot": "A0A0N4U7U2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7U2"
  },
  {
    "uniprot": "A0A158Q684",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q684"
  },
  {
    "uniprot": "A0A3P7SN43",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SN43"
  },
  {
    "uniprot": "A0A0N4UHB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHB0"
  },
  {
    "uniprot": "A0A0N4UFT8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFT8"
  },
  {
    "uniprot": "A0A3P7Q8I7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q8I7"
  },
  {
    "uniprot": "A0A0N4UHZ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHZ8"
  },
  {
    "uniprot": "A0A0N4U0H0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0H0"
  },
  {
    "uniprot": "A0A0N4U1R9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1R9"
  },
  {
    "uniprot": "A0A158Q368",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q368"
  },
  {
    "uniprot": "A0A0N4U1A0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1A0"
  },
  {
    "uniprot": "A0A0N4U7Y8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Y8"
  },
  {
    "uniprot": "A0A0N4ULU1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULU1"
  },
  {
    "uniprot": "A0A0N4U3A6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3A6"
  },
  {
    "uniprot": "A0A0N4U4N3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4N3"
  },
  {
    "uniprot": "A0A0N4UNV2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNV2"
  },
  {
    "uniprot": "A0A158Q3N5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3N5"
  },
  {
    "uniprot": "A0A0N4U9U9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9U9"
  },
  {
    "uniprot": "A0A0N4UGZ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGZ0"
  },
  {
    "uniprot": "A0A0N4UDH7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDH7"
  },
  {
    "uniprot": "A0A3P7PU12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PU12"
  },
  {
    "uniprot": "A0A158Q5S9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5S9"
  },
  {
    "uniprot": "A0A0N4URY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URY1"
  },
  {
    "uniprot": "A0A0N4U5L5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5L5"
  },
  {
    "uniprot": "A0A0N4UA94",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA94"
  },
  {
    "uniprot": "A0A0N4UBN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBN0"
  },
  {
    "uniprot": "A0A0N4UJR8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJR8"
  },
  {
    "uniprot": "A0A0N4UMC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMC0"
  },
  {
    "uniprot": "A0A0N4UQI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQI1"
  },
  {
    "uniprot": "A0A0N4UKT3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKT3"
  },
  {
    "uniprot": "A0A0N4U5F8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5F8"
  },
  {
    "uniprot": "A0A0N4UG00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG00"
  },
  {
    "uniprot": "A0A0N4ULM6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULM6"
  },
  {
    "uniprot": "A0A158Q2K8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2K8"
  },
  {
    "uniprot": "A0A0N4U8N9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8N9"
  },
  {
    "uniprot": "A0A0N4UH59",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH59"
  },
  {
    "uniprot": "A0A0N4U2D8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2D8"
  },
  {
    "uniprot": "A0A0N4UK49",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK49"
  },
  {
    "uniprot": "A0A0N4UC18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC18"
  },
  {
    "uniprot": "A0A0N4ULC8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULC8"
  },
  {
    "uniprot": "A0A158Q677",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q677"
  },
  {
    "uniprot": "A0A3P7PPI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPI9"
  },
  {
    "uniprot": "A0A0N4UIC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIC1"
  },
  {
    "uniprot": "A0A0N4UDI2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDI2"
  },
  {
    "uniprot": "A0A3P7PN98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PN98"
  },
  {
    "uniprot": "A0A0N4UB12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB12"
  },
  {
    "uniprot": "A0A0N4UB05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB05"
  },
  {
    "uniprot": "A0A0N4UPM4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPM4"
  },
  {
    "uniprot": "A0A158Q658",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q658"
  },
  {
    "uniprot": "A0A0N4UM90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM90"
  },
  {
    "uniprot": "A0A0N4U0U2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0U2"
  },
  {
    "uniprot": "A0A0N4U4F9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4F9"
  },
  {
    "uniprot": "A0A0N4U611",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U611"
  },
  {
    "uniprot": "A0A158Q471",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q471"
  },
  {
    "uniprot": "A0A0N4U689",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U689"
  },
  {
    "uniprot": "A0A0N4UGC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGC2"
  },
  {
    "uniprot": "A0A0N4U5Z9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5Z9"
  },
  {
    "uniprot": "A0A0N4UQY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQY7"
  },
  {
    "uniprot": "A0A3P7QW05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QW05"
  },
  {
    "uniprot": "A0A0N4UHH4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHH4"
  },
  {
    "uniprot": "A0A158Q3Y1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Y1"
  },
  {
    "uniprot": "A0A0N4UN12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN12"
  },
  {
    "uniprot": "A0A0N4U2R5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2R5"
  },
  {
    "uniprot": "A0A0N4UMK8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMK8"
  },
  {
    "uniprot": "A0A0N4UD52",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD52"
  },
  {
    "uniprot": "A0A158Q3A2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3A2"
  },
  {
    "uniprot": "A0A0N4UAJ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAJ3"
  },
  {
    "uniprot": "A0A158Q593",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q593"
  },
  {
    "uniprot": "A0A158Q2S8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2S8"
  },
  {
    "uniprot": "A0A0N4UBY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBY4"
  },
  {
    "uniprot": "A0A0N4U3K5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3K5"
  },
  {
    "uniprot": "A0A0N4UPV1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPV1"
  },
  {
    "uniprot": "A0A0N4U676",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U676"
  },
  {
    "uniprot": "A0A0N4UPD7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPD7"
  },
  {
    "uniprot": "A0A0N4U3S9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3S9"
  },
  {
    "uniprot": "A0A0N4UKG7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKG7"
  },
  {
    "uniprot": "A0A0N4UAL6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAL6"
  },
  {
    "uniprot": "A0A0N4UF11",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF11"
  },
  {
    "uniprot": "A0A0N4UEB6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEB6"
  },
  {
    "uniprot": "A0A0N4UGN4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGN4"
  },
  {
    "uniprot": "A0A0N4U665",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U665"
  },
  {
    "uniprot": "A0A0N4U5S1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5S1"
  },
  {
    "uniprot": "A0A158Q647",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q647"
  },
  {
    "uniprot": "A0A158Q2U8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2U8"
  },
  {
    "uniprot": "A0A0N4U2Y5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Y5"
  },
  {
    "uniprot": "A0A0N4UG39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG39"
  },
  {
    "uniprot": "A0A0N4U1B0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1B0"
  },
  {
    "uniprot": "A0A0N4UD28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD28"
  },
  {
    "uniprot": "A0A0N4U835",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U835"
  },
  {
    "uniprot": "A0A0N4U3M5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3M5"
  },
  {
    "uniprot": "A0A0N4UBF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBF9"
  },
  {
    "uniprot": "A0A0N4UBL5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBL5"
  },
  {
    "uniprot": "A0A0N4U8P0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8P0"
  },
  {
    "uniprot": "A0A0N4UFC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFC0"
  },
  {
    "uniprot": "A0A0N4U4L3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4L3"
  },
  {
    "uniprot": "A0A0N4UKV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKV7"
  },
  {
    "uniprot": "A0A0N4UL35",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL35"
  },
  {
    "uniprot": "A0A0N4UK79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK79"
  },
  {
    "uniprot": "A0A0N4U0X5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0X5"
  },
  {
    "uniprot": "A0A0N4UBC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBC7"
  },
  {
    "uniprot": "A0A0N4UME7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UME7"
  },
  {
    "uniprot": "A0A3P7QBX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QBX1"
  },
  {
    "uniprot": "A0A0N4UHE5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHE5"
  },
  {
    "uniprot": "A0A0N4U244",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U244"
  },
  {
    "uniprot": "A0A3P7QDE0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QDE0"
  },
  {
    "uniprot": "A0A0N4UID5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UID5"
  },
  {
    "uniprot": "A0A3P7PB41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PB41"
  },
  {
    "uniprot": "A0A158Q565",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q565"
  },
  {
    "uniprot": "A0A0N4URR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URR9"
  },
  {
    "uniprot": "A0A0N4U8P9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8P9"
  },
  {
    "uniprot": "A0A0N4URP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URP0"
  },
  {
    "uniprot": "A0A3P7PBQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PBQ5"
  },
  {
    "uniprot": "A0A0N4UG48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG48"
  },
  {
    "uniprot": "A0A0N4UFS6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFS6"
  },
  {
    "uniprot": "A0A0N4U183",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U183"
  },
  {
    "uniprot": "A0A0N4U826",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U826"
  },
  {
    "uniprot": "A0A0N4UNR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNR7"
  },
  {
    "uniprot": "A0A0N4UFH2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFH2"
  },
  {
    "uniprot": "A0A0N4UNS2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNS2"
  },
  {
    "uniprot": "A0A0N4U9T7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9T7"
  },
  {
    "uniprot": "A0A158Q5Y5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Y5"
  },
  {
    "uniprot": "A0A158Q3Q9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Q9"
  },
  {
    "uniprot": "A0A0N4ULQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULQ4"
  },
  {
    "uniprot": "A0A0N4UIA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIA6"
  },
  {
    "uniprot": "A0A0N4UCK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCK0"
  },
  {
    "uniprot": "A0A0N4U791",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U791"
  },
  {
    "uniprot": "A0A0N4UN71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN71"
  },
  {
    "uniprot": "A0A0N4U8U0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8U0"
  },
  {
    "uniprot": "A0A0N4UQT4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQT4"
  },
  {
    "uniprot": "A0A0N4UIZ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIZ9"
  },
  {
    "uniprot": "A0A0N4ULX6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULX6"
  },
  {
    "uniprot": "A0A0N4UIG5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIG5"
  },
  {
    "uniprot": "A0A0N4U7C7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7C7"
  },
  {
    "uniprot": "A0A0N4UIY6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIY6"
  },
  {
    "uniprot": "A0A0N4U1Y2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Y2"
  },
  {
    "uniprot": "A0A0N4U2M7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2M7"
  },
  {
    "uniprot": "A0A0N4UBW1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBW1"
  },
  {
    "uniprot": "A0A0N4URS1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URS1"
  },
  {
    "uniprot": "A0A0N4U263",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U263"
  },
  {
    "uniprot": "A0A0N4UC88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC88"
  },
  {
    "uniprot": "A0A0N4U9H4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9H4"
  },
  {
    "uniprot": "A0A0N4UGJ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGJ5"
  },
  {
    "uniprot": "A0A0N4U544",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U544"
  },
  {
    "uniprot": "A0A158Q4U2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4U2"
  },
  {
    "uniprot": "A0A0N4UQL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQL8"
  },
  {
    "uniprot": "A0A0N4U6U6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6U6"
  },
  {
    "uniprot": "A0A3P7PHA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PHA0"
  },
  {
    "uniprot": "A0A3P7Q2M5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q2M5"
  },
  {
    "uniprot": "A0A0N4UFZ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFZ6"
  },
  {
    "uniprot": "A0A0N4U2Y9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Y9"
  },
  {
    "uniprot": "A0A158Q4Q1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Q1"
  },
  {
    "uniprot": "A0A0N4U154",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U154"
  },
  {
    "uniprot": "A0A0N4U9E5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9E5"
  },
  {
    "uniprot": "A0A0N4UJK9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJK9"
  },
  {
    "uniprot": "A0A0N4UKA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKA4"
  },
  {
    "uniprot": "A0A3P7P7A0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P7A0"
  },
  {
    "uniprot": "A0A0N4U4Y9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Y9"
  },
  {
    "uniprot": "A0A0N4UA47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA47"
  },
  {
    "uniprot": "A0A0N4U7I9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7I9"
  },
  {
    "uniprot": "A0A158Q5Z1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Z1"
  },
  {
    "uniprot": "A0A0N4U3G4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3G4"
  },
  {
    "uniprot": "A0A0N4UMW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMW0"
  },
  {
    "uniprot": "A0A0N4UET6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UET6"
  },
  {
    "uniprot": "A0A0N4UL79",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL79"
  },
  {
    "uniprot": "A0A0N4UEA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEA6"
  },
  {
    "uniprot": "A0A158Q3U4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3U4"
  },
  {
    "uniprot": "A0A0N4UE24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE24"
  },
  {
    "uniprot": "A0A0N4UPE2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPE2"
  },
  {
    "uniprot": "A0A0N4UFY3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFY3"
  },
  {
    "uniprot": "A0A0N4UDH5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDH5"
  },
  {
    "uniprot": "A0A0N4UEP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEP7"
  },
  {
    "uniprot": "A0A0N4UJ89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ89"
  },
  {
    "uniprot": "A0A0N4U0I7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0I7"
  },
  {
    "uniprot": "A0A158Q429",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q429"
  },
  {
    "uniprot": "A0A0N4URH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URH3"
  },
  {
    "uniprot": "A0A0N4U249",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U249"
  },
  {
    "uniprot": "A0A0N4UJC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJC2"
  },
  {
    "uniprot": "A0A158Q384",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q384"
  },
  {
    "uniprot": "A0A0N4UFI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFI1"
  },
  {
    "uniprot": "A0A0N4U7H5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7H5"
  },
  {
    "uniprot": "A0A0N4UIL1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIL1"
  },
  {
    "uniprot": "A0A0N4U1U6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1U6"
  },
  {
    "uniprot": "A0A0N4UD44",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD44"
  },
  {
    "uniprot": "A0A0N4UAA3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAA3"
  },
  {
    "uniprot": "A0A0N4UCF7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCF7"
  },
  {
    "uniprot": "A0A0N4UDB4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDB4"
  },
  {
    "uniprot": "A0A0N4U538",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U538"
  },
  {
    "uniprot": "A0A0N4UDR8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDR8"
  },
  {
    "uniprot": "A0A0N4UBE8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBE8"
  },
  {
    "uniprot": "A0A0N4U4K6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4K6"
  },
  {
    "uniprot": "A0A3P7Q4L0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q4L0"
  },
  {
    "uniprot": "A0A0N4UF17",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF17"
  },
  {
    "uniprot": "A0A0N4UL87",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL87"
  },
  {
    "uniprot": "A0A158Q3T6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3T6"
  },
  {
    "uniprot": "A0A3P7QC84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QC84"
  },
  {
    "uniprot": "A0A0N4UJG7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJG7"
  },
  {
    "uniprot": "A0A0N4U2N3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2N3"
  },
  {
    "uniprot": "A0A0N4UK97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK97"
  },
  {
    "uniprot": "A0A0N4UEW6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEW6"
  },
  {
    "uniprot": "A0A0N4UAA4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAA4"
  },
  {
    "uniprot": "A0A0N4UEE4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEE4"
  },
  {
    "uniprot": "A0A0N4U740",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U740"
  },
  {
    "uniprot": "A0A158Q4S9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4S9"
  },
  {
    "uniprot": "A0A158Q373",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q373"
  },
  {
    "uniprot": "A0A0N4UQI2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQI2"
  },
  {
    "uniprot": "A0A3P7PK69",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PK69"
  },
  {
    "uniprot": "A0A0N4U499",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U499"
  },
  {
    "uniprot": "A0A158Q4G2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4G2"
  },
  {
    "uniprot": "A0A3P7SSB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SSB9"
  },
  {
    "uniprot": "A0A0N4UGM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGM2"
  },
  {
    "uniprot": "A0A0N4UAM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAM8"
  },
  {
    "uniprot": "A0A0N4U895",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U895"
  },
  {
    "uniprot": "A0A158Q3S3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3S3"
  },
  {
    "uniprot": "A0A0N4UBL0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBL0"
  },
  {
    "uniprot": "A0A0N4U958",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U958"
  },
  {
    "uniprot": "A0A0N4U931",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U931"
  },
  {
    "uniprot": "A0A0N4UPT9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPT9"
  },
  {
    "uniprot": "A0A0N4UGN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGN3"
  },
  {
    "uniprot": "A0A0N4UKQ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKQ1"
  },
  {
    "uniprot": "A0A0N4U516",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U516"
  },
  {
    "uniprot": "A0A0N4UI38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI38"
  },
  {
    "uniprot": "A0A0N4UM36",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM36"
  },
  {
    "uniprot": "A0A158Q4B6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4B6"
  },
  {
    "uniprot": "A0A0N4U894",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U894"
  },
  {
    "uniprot": "A0A0N4UQU2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQU2"
  },
  {
    "uniprot": "A0A0N4U543",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U543"
  },
  {
    "uniprot": "A0A0N4UFD0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFD0"
  },
  {
    "uniprot": "A0A158Q6G8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6G8"
  },
  {
    "uniprot": "A0A0N4UM21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM21"
  },
  {
    "uniprot": "A0A0N4U7B3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7B3"
  },
  {
    "uniprot": "A0A0N4U6B8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6B8"
  },
  {
    "uniprot": "A0A0N4UAH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAH1"
  },
  {
    "uniprot": "A0A158Q2S4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2S4"
  },
  {
    "uniprot": "A0A0N4UHS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHS9"
  },
  {
    "uniprot": "A0A3P7PXV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PXV6"
  },
  {
    "uniprot": "A0A0N4UPS1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPS1"
  },
  {
    "uniprot": "A0A0N4UA25",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA25"
  },
  {
    "uniprot": "A0A158Q6L2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6L2"
  },
  {
    "uniprot": "A0A0N4UGL7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGL7"
  },
  {
    "uniprot": "A0A0N4U3G8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3G8"
  },
  {
    "uniprot": "A0A0N4UJC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJC4"
  },
  {
    "uniprot": "A0A158Q302",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q302"
  },
  {
    "uniprot": "A0A0N4UJB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJB2"
  },
  {
    "uniprot": "A0A158Q4N1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4N1"
  },
  {
    "uniprot": "A0A0N4UHB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHB9"
  },
  {
    "uniprot": "A0A0N4U871",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U871"
  },
  {
    "uniprot": "A0A0N4UB45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB45"
  },
  {
    "uniprot": "A0A0N4U9Z0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Z0"
  },
  {
    "uniprot": "A0A158Q5B8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5B8"
  },
  {
    "uniprot": "A0A0N4UQK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQK3"
  },
  {
    "uniprot": "A0A0N4U5A2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5A2"
  },
  {
    "uniprot": "A0A158Q2T3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2T3"
  },
  {
    "uniprot": "A0A0N4UII1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UII1"
  },
  {
    "uniprot": "A0A3P7Q6S8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q6S8"
  },
  {
    "uniprot": "A0A0N4UNS1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNS1"
  },
  {
    "uniprot": "A0A3P7S6P0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7S6P0"
  },
  {
    "uniprot": "A0A0N4UMK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMK6"
  },
  {
    "uniprot": "A0A0N4UPK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPK1"
  },
  {
    "uniprot": "A0A0N4UIT1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIT1"
  },
  {
    "uniprot": "A0A0N4UEU5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEU5"
  },
  {
    "uniprot": "A0A0N4UER5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UER5"
  },
  {
    "uniprot": "A0A0N4UDU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDU6"
  },
  {
    "uniprot": "A0A0N4U765",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U765"
  },
  {
    "uniprot": "A0A0N4UAJ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAJ0"
  },
  {
    "uniprot": "A0A3P7PA89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PA89"
  },
  {
    "uniprot": "A0A0N4UDG5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDG5"
  },
  {
    "uniprot": "A0A0N4UEM1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEM1"
  },
  {
    "uniprot": "A0A0N4U857",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U857"
  },
  {
    "uniprot": "A0A0N4ULC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULC1"
  },
  {
    "uniprot": "A0A0N4UBI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBI1"
  },
  {
    "uniprot": "A0A0N4UDP8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDP8"
  },
  {
    "uniprot": "A0A0N4UBP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBP9"
  },
  {
    "uniprot": "A0A0N4U5K8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5K8"
  },
  {
    "uniprot": "A0A0N4ULR3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULR3"
  },
  {
    "uniprot": "A0A0N4URH0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URH0"
  },
  {
    "uniprot": "A0A0N4UQP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQP6"
  },
  {
    "uniprot": "A0A158Q5B3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5B3"
  },
  {
    "uniprot": "A0A0N4US48",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US48"
  },
  {
    "uniprot": "A0A0N4UL00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL00"
  },
  {
    "uniprot": "A0A0N4UMP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMP3"
  },
  {
    "uniprot": "A0A3P7T2M9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T2M9"
  },
  {
    "uniprot": "A0A0N4UKE9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKE9"
  },
  {
    "uniprot": "A0A158Q6N2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6N2"
  },
  {
    "uniprot": "A0A0N4US28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US28"
  },
  {
    "uniprot": "A0A158Q5Q5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Q5"
  },
  {
    "uniprot": "A0A0N4UEG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEG0"
  },
  {
    "uniprot": "A0A3P7SLP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SLP0"
  },
  {
    "uniprot": "A0A0N4UJA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJA5"
  },
  {
    "uniprot": "A0A0N4UBF5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBF5"
  },
  {
    "uniprot": "A0A0N4UJN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJN3"
  },
  {
    "uniprot": "A0A0N4UIF1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIF1"
  },
  {
    "uniprot": "A0A0N4UDC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDC2"
  },
  {
    "uniprot": "A0A0N4UMP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMP6"
  },
  {
    "uniprot": "A0A0N4U924",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U924"
  },
  {
    "uniprot": "A0A0N4UL36",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL36"
  },
  {
    "uniprot": "A0A0N4UHU1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHU1"
  },
  {
    "uniprot": "A0A158Q691",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q691"
  },
  {
    "uniprot": "A0A0N4UAM6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAM6"
  },
  {
    "uniprot": "A0A0N4U4R5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4R5"
  },
  {
    "uniprot": "A0A0N4ULY6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULY6"
  },
  {
    "uniprot": "A0A0N4U8Z3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Z3"
  },
  {
    "uniprot": "A0A0N4UFB4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFB4"
  },
  {
    "uniprot": "A0A0N4UG50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG50"
  },
  {
    "uniprot": "A0A3P7PQT9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQT9"
  },
  {
    "uniprot": "A0A0N4UIN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIN6"
  },
  {
    "uniprot": "A0A0N4UDA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDA5"
  },
  {
    "uniprot": "A0A0N4U4S4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4S4"
  },
  {
    "uniprot": "A0A0N4U5G2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5G2"
  },
  {
    "uniprot": "A0A158Q3Y7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Y7"
  },
  {
    "uniprot": "A0A0N4UIU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIU7"
  },
  {
    "uniprot": "A0A0N4UB03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB03"
  },
  {
    "uniprot": "A0A0N4UFR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFR9"
  },
  {
    "uniprot": "A0A158Q2P0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2P0"
  },
  {
    "uniprot": "A0A0N4UIV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIV6"
  },
  {
    "uniprot": "A0A0N4U775",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U775"
  },
  {
    "uniprot": "A0A0N4U9R0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9R0"
  },
  {
    "uniprot": "A0A0N4U6J6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6J6"
  },
  {
    "uniprot": "A0A0N4U3L0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3L0"
  },
  {
    "uniprot": "A0A0N4UGG5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGG5"
  },
  {
    "uniprot": "A0A0N4U9F8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9F8"
  },
  {
    "uniprot": "A0A0N4U661",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U661"
  },
  {
    "uniprot": "A0A3P7P589",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7P589"
  },
  {
    "uniprot": "A0A0N4U640",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U640"
  },
  {
    "uniprot": "A0A0N4U673",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U673"
  },
  {
    "uniprot": "A0A0N4U450",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U450"
  },
  {
    "uniprot": "A0A0N4UP00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP00"
  },
  {
    "uniprot": "A0A0N4UBA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBA1"
  },
  {
    "uniprot": "A0A0N4UA46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA46"
  },
  {
    "uniprot": "A0A158Q627",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q627"
  },
  {
    "uniprot": "A0A0N4UE35",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE35"
  },
  {
    "uniprot": "A0A0N4U8F4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8F4"
  },
  {
    "uniprot": "A0A158Q531",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q531"
  },
  {
    "uniprot": "A0A0N4UKS6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKS6"
  },
  {
    "uniprot": "A0A158Q2Z8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Z8"
  },
  {
    "uniprot": "A0A0N4UIZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIZ1"
  },
  {
    "uniprot": "A0A0N4UP77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP77"
  },
  {
    "uniprot": "A0A158Q392",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q392"
  },
  {
    "uniprot": "A0A3P7Q9X3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q9X3"
  },
  {
    "uniprot": "A0A0N4UBZ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBZ8"
  },
  {
    "uniprot": "A0A158Q4W1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4W1"
  },
  {
    "uniprot": "A0A0N4UB30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB30"
  },
  {
    "uniprot": "A0A0N4UGP2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGP2"
  },
  {
    "uniprot": "A0A0N4UP80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP80"
  },
  {
    "uniprot": "A0A0N4UJZ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJZ8"
  },
  {
    "uniprot": "A0A0N4U685",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U685"
  },
  {
    "uniprot": "A0A0N4U225",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U225"
  },
  {
    "uniprot": "A0A0N4UMX6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMX6"
  },
  {
    "uniprot": "A0A0N4U9Y8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Y8"
  },
  {
    "uniprot": "A0A158Q6C0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6C0"
  },
  {
    "uniprot": "A0A158Q645",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q645"
  },
  {
    "uniprot": "A0A0N4UN04",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN04"
  },
  {
    "uniprot": "A0A0N4U9I9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9I9"
  },
  {
    "uniprot": "A0A0N4U110",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U110"
  },
  {
    "uniprot": "A0A0N4U309",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U309"
  },
  {
    "uniprot": "A0A0N4U890",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U890"
  },
  {
    "uniprot": "A0A0N4U9U1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9U1"
  },
  {
    "uniprot": "A0A0N4UR03",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR03"
  },
  {
    "uniprot": "A0A0N4U0U9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0U9"
  },
  {
    "uniprot": "A0A0N4UPW1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPW1"
  },
  {
    "uniprot": "A0A158Q2T9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2T9"
  },
  {
    "uniprot": "A0A0N4URU5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URU5"
  },
  {
    "uniprot": "A0A0N4U8W1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8W1"
  },
  {
    "uniprot": "A0A158Q5Z4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Z4"
  },
  {
    "uniprot": "A0A0N4U6L7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6L7"
  },
  {
    "uniprot": "A0A0N4UJE9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJE9"
  },
  {
    "uniprot": "A0A0N4UEI8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEI8"
  },
  {
    "uniprot": "A0A0N4U2E5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2E5"
  },
  {
    "uniprot": "A0A3P7TEK9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TEK9"
  },
  {
    "uniprot": "A0A3P7QBB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QBB5"
  },
  {
    "uniprot": "A0A0N4UP66",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP66"
  },
  {
    "uniprot": "A0A0N4UP22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP22"
  },
  {
    "uniprot": "A0A0N4UBX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBX1"
  },
  {
    "uniprot": "A0A0N4U9Q0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Q0"
  },
  {
    "uniprot": "A0A0N4U2R2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2R2"
  },
  {
    "uniprot": "A0A3P7QQI1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QQI1"
  },
  {
    "uniprot": "A0A158Q408",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q408"
  },
  {
    "uniprot": "A0A0N4U686",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U686"
  },
  {
    "uniprot": "A0A0N4UQ71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ71"
  },
  {
    "uniprot": "A0A0N4UA58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA58"
  },
  {
    "uniprot": "A0A0N4U2K7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2K7"
  },
  {
    "uniprot": "A0A158Q4R5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4R5"
  },
  {
    "uniprot": "A0A0N4UKY6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKY6"
  },
  {
    "uniprot": "A0A0N4U978",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U978"
  },
  {
    "uniprot": "D7RTV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/D7RTV6"
  },
  {
    "uniprot": "A0A0N4U9N4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9N4"
  },
  {
    "uniprot": "A0A0N4U8R7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8R7"
  },
  {
    "uniprot": "A0A0N4UEH3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEH3"
  },
  {
    "uniprot": "A0A0N4U1M9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1M9"
  },
  {
    "uniprot": "A0A0N4U3D2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3D2"
  },
  {
    "uniprot": "A0A0N4UPE0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPE0"
  },
  {
    "uniprot": "A0A3P7STL6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7STL6"
  },
  {
    "uniprot": "A0A3P7PXT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PXT6"
  },
  {
    "uniprot": "A0A0N4UAM3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAM3"
  },
  {
    "uniprot": "A0A0N4UPA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPA0"
  },
  {
    "uniprot": "A0A0N4U7P5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7P5"
  },
  {
    "uniprot": "A0A0N4UE53",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE53"
  },
  {
    "uniprot": "A0A0N4UJG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJG0"
  },
  {
    "uniprot": "A0A0N4UKY8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKY8"
  },
  {
    "uniprot": "A0A0N4U149",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U149"
  },
  {
    "uniprot": "A0A0N4U5V3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5V3"
  },
  {
    "uniprot": "A0A0N4UK71",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK71"
  },
  {
    "uniprot": "A0A0N4UDE5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDE5"
  },
  {
    "uniprot": "A0A0N4UQT1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQT1"
  },
  {
    "uniprot": "A0A0N4U182",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U182"
  },
  {
    "uniprot": "A0A0N4U0E8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0E8"
  },
  {
    "uniprot": "A0A0N4UDE7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDE7"
  },
  {
    "uniprot": "A0A0N4U5N7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5N7"
  },
  {
    "uniprot": "A0A0N4U9G5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9G5"
  },
  {
    "uniprot": "A0A0N4U904",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U904"
  },
  {
    "uniprot": "A0A3P7QRJ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QRJ1"
  },
  {
    "uniprot": "A0A0N4U5D5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5D5"
  },
  {
    "uniprot": "A0A0N4U620",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U620"
  },
  {
    "uniprot": "A0A0N4U6D6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6D6"
  },
  {
    "uniprot": "A0A0N4U8M0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8M0"
  },
  {
    "uniprot": "A0A3P7PHZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PHZ1"
  },
  {
    "uniprot": "A0A3P7PX58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PX58"
  },
  {
    "uniprot": "A0A0N4URX4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URX4"
  },
  {
    "uniprot": "A0A158Q6H2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6H2"
  },
  {
    "uniprot": "A0A0N4UQQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQQ3"
  },
  {
    "uniprot": "A0A3P7PS86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PS86"
  },
  {
    "uniprot": "A0A158Q2Y7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Y7"
  },
  {
    "uniprot": "A0A158Q4P5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4P5"
  },
  {
    "uniprot": "A0A0N4UEI7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEI7"
  },
  {
    "uniprot": "A0A0N4U234",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U234"
  },
  {
    "uniprot": "A0A0N4U1T1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1T1"
  },
  {
    "uniprot": "A0A0N4U2I5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2I5"
  },
  {
    "uniprot": "A0A0N4U2N5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2N5"
  },
  {
    "uniprot": "A0A0N4U2P6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2P6"
  },
  {
    "uniprot": "A0A0N4UH45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH45"
  },
  {
    "uniprot": "A0A0N4U654",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U654"
  },
  {
    "uniprot": "A0A0N4UMH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMH6"
  },
  {
    "uniprot": "A0A0N4UQ32",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ32"
  },
  {
    "uniprot": "A0A158Q4B0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4B0"
  },
  {
    "uniprot": "A0A158Q3C0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3C0"
  },
  {
    "uniprot": "A0A0N4UNJ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNJ5"
  },
  {
    "uniprot": "A0A0N4U334",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U334"
  },
  {
    "uniprot": "A0A0N4U9J5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9J5"
  },
  {
    "uniprot": "A0A0N4UBF3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBF3"
  },
  {
    "uniprot": "A0A0N4ULA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULA2"
  },
  {
    "uniprot": "A0A0N4UPA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPA2"
  },
  {
    "uniprot": "A0A0N4U5N0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5N0"
  },
  {
    "uniprot": "A0A0N4U584",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U584"
  },
  {
    "uniprot": "A0A0N4U5C0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5C0"
  },
  {
    "uniprot": "A0A158Q485",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q485"
  },
  {
    "uniprot": "A0A158Q6K9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6K9"
  },
  {
    "uniprot": "A0A158Q2Y2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Y2"
  },
  {
    "uniprot": "A0A0N4UIV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIV7"
  },
  {
    "uniprot": "A0A0N4U864",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U864"
  },
  {
    "uniprot": "A0A0N4UHR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHR7"
  },
  {
    "uniprot": "A0A3P7PQ61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQ61"
  },
  {
    "uniprot": "A0A0N4U7S5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7S5"
  },
  {
    "uniprot": "A0A0N4UHB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHB5"
  },
  {
    "uniprot": "A0A0N4U8P6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8P6"
  },
  {
    "uniprot": "A0A0N4UGC7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGC7"
  },
  {
    "uniprot": "A0A0N4U5L0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5L0"
  },
  {
    "uniprot": "A0A0N4URT2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URT2"
  },
  {
    "uniprot": "A0A0N4UDZ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDZ0"
  },
  {
    "uniprot": "A0A0N4U9D1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9D1"
  },
  {
    "uniprot": "A0A0N4UC52",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC52"
  },
  {
    "uniprot": "A0A3P7SEI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SEI3"
  },
  {
    "uniprot": "A0A3P7Q5S6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q5S6"
  },
  {
    "uniprot": "A0A0N4U6J0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6J0"
  },
  {
    "uniprot": "A0A0N4UME6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UME6"
  },
  {
    "uniprot": "A0A0N4UM32",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM32"
  },
  {
    "uniprot": "A0A0N4UJ50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ50"
  },
  {
    "uniprot": "A0A0N4UIC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIC0"
  },
  {
    "uniprot": "A0A0N4U3U5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3U5"
  },
  {
    "uniprot": "A0A0N4U129",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U129"
  },
  {
    "uniprot": "A0A0N4URF9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URF9"
  },
  {
    "uniprot": "A0A0N4U9M9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9M9"
  },
  {
    "uniprot": "A0A0N4U240",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U240"
  },
  {
    "uniprot": "A0A0N4U0M0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0M0"
  },
  {
    "uniprot": "A0A0N4U3B5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3B5"
  },
  {
    "uniprot": "A0A3P7PZT4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PZT4"
  },
  {
    "uniprot": "A0A0N4UI93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI93"
  },
  {
    "uniprot": "A0A0N4URL2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URL2"
  },
  {
    "uniprot": "A0A0N4UPS7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPS7"
  },
  {
    "uniprot": "A0A0N4UDI6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDI6"
  },
  {
    "uniprot": "A0A0N4UDQ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDQ9"
  },
  {
    "uniprot": "A0A0N4U6X8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6X8"
  },
  {
    "uniprot": "A0A0N4US67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US67"
  },
  {
    "uniprot": "A0A3P7SSQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SSQ4"
  },
  {
    "uniprot": "A0A0N4UBM5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBM5"
  },
  {
    "uniprot": "A0A3P7SK18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SK18"
  },
  {
    "uniprot": "A0A0N4U942",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U942"
  },
  {
    "uniprot": "A0A0N4UNV6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNV6"
  },
  {
    "uniprot": "A0A0N4UAB4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAB4"
  },
  {
    "uniprot": "A0A0N4UGZ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGZ7"
  },
  {
    "uniprot": "A0A0N4U4K9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4K9"
  },
  {
    "uniprot": "A0A0N4UAV4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAV4"
  },
  {
    "uniprot": "A0A0N4U6T3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6T3"
  },
  {
    "uniprot": "A0A3P7PAX4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PAX4"
  },
  {
    "uniprot": "A0A0N4URE5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URE5"
  },
  {
    "uniprot": "A0A0N4U7Y4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Y4"
  },
  {
    "uniprot": "A0A0N4UBN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBN2"
  },
  {
    "uniprot": "A0A0N4UFX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFX1"
  },
  {
    "uniprot": "A0A0N4U2A7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2A7"
  },
  {
    "uniprot": "A0A0N4UDM8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDM8"
  },
  {
    "uniprot": "A0A0N4U928",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U928"
  },
  {
    "uniprot": "A0A0N4U322",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U322"
  },
  {
    "uniprot": "A0A0N4U4P8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4P8"
  },
  {
    "uniprot": "A0A0N4U348",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U348"
  },
  {
    "uniprot": "A0A158Q6E4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6E4"
  },
  {
    "uniprot": "A0A158Q5U5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5U5"
  },
  {
    "uniprot": "A0A0N4UCI6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCI6"
  },
  {
    "uniprot": "A0A0N4U0F5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0F5"
  },
  {
    "uniprot": "A0A0N4UCR8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCR8"
  },
  {
    "uniprot": "A0A0N4UDY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDY0"
  },
  {
    "uniprot": "A0A3P7PQQ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PQQ6"
  },
  {
    "uniprot": "A0A0N4U2Q3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Q3"
  },
  {
    "uniprot": "A0A0N4U7T6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7T6"
  },
  {
    "uniprot": "A0A158Q5Q7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Q7"
  },
  {
    "uniprot": "A0A0N4UJK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJK5"
  },
  {
    "uniprot": "A0A0N4U6R9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6R9"
  },
  {
    "uniprot": "A0A3P7QEL4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QEL4"
  },
  {
    "uniprot": "A0A0N4U9L0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9L0"
  },
  {
    "uniprot": "A0A0N4UGK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGK5"
  },
  {
    "uniprot": "A0A0N4UAV1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAV1"
  },
  {
    "uniprot": "A0A3P7PEC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PEC3"
  },
  {
    "uniprot": "A0A0N4U5R4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5R4"
  },
  {
    "uniprot": "A0A0N4UAW9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAW9"
  },
  {
    "uniprot": "A0A0N4ULI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULI9"
  },
  {
    "uniprot": "A0A0N4U2I7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2I7"
  },
  {
    "uniprot": "A0A0N4UP50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP50"
  },
  {
    "uniprot": "A0A3P7PNI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PNI9"
  },
  {
    "uniprot": "A0A0N4U0F7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0F7"
  },
  {
    "uniprot": "A0A0N4U8C5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8C5"
  },
  {
    "uniprot": "A0A0N4UG33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG33"
  },
  {
    "uniprot": "A0A0N4UGI6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGI6"
  },
  {
    "uniprot": "A0A0N4UIL2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIL2"
  },
  {
    "uniprot": "A0A158Q2H3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2H3"
  },
  {
    "uniprot": "A0A0N4UMC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMC4"
  },
  {
    "uniprot": "A0A0N4U917",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U917"
  },
  {
    "uniprot": "A0A0N4UBX4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBX4"
  },
  {
    "uniprot": "A0A0N4U8K5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8K5"
  },
  {
    "uniprot": "A0A0N4U669",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U669"
  },
  {
    "uniprot": "A0A0N4UDM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDM2"
  },
  {
    "uniprot": "A0A0N4UL30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL30"
  },
  {
    "uniprot": "A0A0N4U4B3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4B3"
  },
  {
    "uniprot": "A0A0N4U9S8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9S8"
  },
  {
    "uniprot": "A0A0N4UM33",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM33"
  },
  {
    "uniprot": "A0A0N4U8W4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8W4"
  },
  {
    "uniprot": "A0A0N4UDS2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDS2"
  },
  {
    "uniprot": "A0A3P7QKE4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QKE4"
  },
  {
    "uniprot": "A0A0N4UNY9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNY9"
  },
  {
    "uniprot": "A0A0N4U8B6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8B6"
  },
  {
    "uniprot": "A0A0N4UIU9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIU9"
  },
  {
    "uniprot": "A0A0N4U7W6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7W6"
  },
  {
    "uniprot": "A0A0N4UI74",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI74"
  },
  {
    "uniprot": "A0A0N4U1J2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1J2"
  },
  {
    "uniprot": "A0A0N4U2Z9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Z9"
  },
  {
    "uniprot": "A0A158Q5T9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5T9"
  },
  {
    "uniprot": "A0A0N4UC01",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC01"
  },
  {
    "uniprot": "A0A0N4UA59",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA59"
  },
  {
    "uniprot": "A0A0N4UNJ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNJ3"
  },
  {
    "uniprot": "A0A0N4UA05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA05"
  },
  {
    "uniprot": "A0A0N4URX0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URX0"
  },
  {
    "uniprot": "A0A0N4UJB8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJB8"
  },
  {
    "uniprot": "A0A0N4UGY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGY4"
  },
  {
    "uniprot": "A0A0N4U7C0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7C0"
  },
  {
    "uniprot": "A0A0N4UE89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE89"
  },
  {
    "uniprot": "A0A0N4U768",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U768"
  },
  {
    "uniprot": "A0A0N4U4Z0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Z0"
  },
  {
    "uniprot": "A0A158Q597",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q597"
  },
  {
    "uniprot": "A0A0N4UP93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP93"
  },
  {
    "uniprot": "A0A0N4UJV9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJV9"
  },
  {
    "uniprot": "A0A0N4UL11",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL11"
  },
  {
    "uniprot": "A0A0N4UFK0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFK0"
  },
  {
    "uniprot": "A0A0N4U4L2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4L2"
  },
  {
    "uniprot": "A0A0N4U3Q2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Q2"
  },
  {
    "uniprot": "A0A0N4URY9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URY9"
  },
  {
    "uniprot": "A0A0N4UFN5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFN5"
  },
  {
    "uniprot": "A0A3P7PH40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PH40"
  },
  {
    "uniprot": "A0A0N4ULF4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULF4"
  },
  {
    "uniprot": "A0A0N4ULN4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULN4"
  },
  {
    "uniprot": "A0A0N4URZ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URZ6"
  },
  {
    "uniprot": "A0A0N4UAY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAY0"
  },
  {
    "uniprot": "A0A0N4UKW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKW3"
  },
  {
    "uniprot": "A0A0N4UDL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDL8"
  },
  {
    "uniprot": "A0A3P7SY76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SY76"
  },
  {
    "uniprot": "A0A0N4UED5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UED5"
  },
  {
    "uniprot": "A0A0N4UPW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPW3"
  },
  {
    "uniprot": "A0A0N4UR26",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR26"
  },
  {
    "uniprot": "A0A158Q4A6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4A6"
  },
  {
    "uniprot": "A0A0N4ULW3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULW3"
  },
  {
    "uniprot": "A0A0N4UAY5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAY5"
  },
  {
    "uniprot": "A0A0N4U1Z7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Z7"
  },
  {
    "uniprot": "A0A0N4U992",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U992"
  },
  {
    "uniprot": "A0A3P7PJS7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PJS7"
  },
  {
    "uniprot": "A0A0N4UH50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH50"
  },
  {
    "uniprot": "A0A0N4UFP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFP3"
  },
  {
    "uniprot": "A0A0N4US61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US61"
  },
  {
    "uniprot": "A0A0N4U4R3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4R3"
  },
  {
    "uniprot": "A0A158Q427",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q427"
  },
  {
    "uniprot": "A0A0N4UDZ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDZ9"
  },
  {
    "uniprot": "A0A0N4U127",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U127"
  },
  {
    "uniprot": "A0A158Q4Y8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Y8"
  },
  {
    "uniprot": "A0A0N4UAP2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAP2"
  },
  {
    "uniprot": "A0A3P7QKH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QKH9"
  },
  {
    "uniprot": "A0A0N4U5W8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5W8"
  },
  {
    "uniprot": "A0A0N4U9R5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9R5"
  },
  {
    "uniprot": "A0A0N4UI19",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI19"
  },
  {
    "uniprot": "A0A0N4UG88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG88"
  },
  {
    "uniprot": "A0A0N4UFX2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFX2"
  },
  {
    "uniprot": "A0A0N4U7J7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7J7"
  },
  {
    "uniprot": "A0A0N4UKJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKJ2"
  },
  {
    "uniprot": "A0A0N4UH85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH85"
  },
  {
    "uniprot": "A0A0N4UAG1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAG1"
  },
  {
    "uniprot": "A0A0N4UIY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIY4"
  },
  {
    "uniprot": "A0A0N4UF07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF07"
  },
  {
    "uniprot": "A0A0N4UG36",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG36"
  },
  {
    "uniprot": "A0A0N4UCJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCJ9"
  },
  {
    "uniprot": "A0A0N4ULC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULC6"
  },
  {
    "uniprot": "A0A158Q5V4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5V4"
  },
  {
    "uniprot": "A0A0N4U925",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U925"
  },
  {
    "uniprot": "A0A158Q359",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q359"
  },
  {
    "uniprot": "A0A0N4US42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US42"
  },
  {
    "uniprot": "A0A0N4U7U8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7U8"
  },
  {
    "uniprot": "A0A158Q4B1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4B1"
  },
  {
    "uniprot": "A0A0N4U2Z8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Z8"
  },
  {
    "uniprot": "A0A0N4ULX2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULX2"
  },
  {
    "uniprot": "A0A0N4UHA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHA0"
  },
  {
    "uniprot": "A0A0N4UFD2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFD2"
  },
  {
    "uniprot": "A0A0N4U486",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U486"
  },
  {
    "uniprot": "A0A0N4UJM1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJM1"
  },
  {
    "uniprot": "A0A0N4UIX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIX1"
  },
  {
    "uniprot": "A0A0N4UKI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKI0"
  },
  {
    "uniprot": "A0A0N4UNY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNY1"
  },
  {
    "uniprot": "A0A0N4U5G8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5G8"
  },
  {
    "uniprot": "A0A0N4UJ97",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ97"
  },
  {
    "uniprot": "A0A0N4UJW4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJW4"
  },
  {
    "uniprot": "A0A0N4UL57",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL57"
  },
  {
    "uniprot": "A0A0N4UQ84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ84"
  },
  {
    "uniprot": "A0A0N4UHH5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHH5"
  },
  {
    "uniprot": "A0A0N4UAE0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAE0"
  },
  {
    "uniprot": "A0A158Q6B3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6B3"
  },
  {
    "uniprot": "A0A0N4U2D2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2D2"
  },
  {
    "uniprot": "A0A0N4U8H1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8H1"
  },
  {
    "uniprot": "A0A0N4UEY9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEY9"
  },
  {
    "uniprot": "A0A0N4UH88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH88"
  },
  {
    "uniprot": "A0A0N4U2Y3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Y3"
  },
  {
    "uniprot": "A0A0N4UJ73",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ73"
  },
  {
    "uniprot": "A0A0N4UFS7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFS7"
  },
  {
    "uniprot": "A0A0N4UEL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEL3"
  },
  {
    "uniprot": "A0A3P7PZN5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PZN5"
  },
  {
    "uniprot": "A0A0N4U3N6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3N6"
  },
  {
    "uniprot": "A0A0N4UMG0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMG0"
  },
  {
    "uniprot": "A0A0N4UQ27",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ27"
  },
  {
    "uniprot": "A0A158Q5A5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5A5"
  },
  {
    "uniprot": "A0A0N4US20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US20"
  },
  {
    "uniprot": "A0A0N4UR58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR58"
  },
  {
    "uniprot": "A0A0N4UPG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPG4"
  },
  {
    "uniprot": "A0A3P7QF51",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QF51"
  },
  {
    "uniprot": "A0A3P7SDN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SDN9"
  },
  {
    "uniprot": "A0A0N4U0K4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0K4"
  },
  {
    "uniprot": "A0A158Q2Q2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2Q2"
  },
  {
    "uniprot": "A0A0N4UCP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCP1"
  },
  {
    "uniprot": "A0A3P7SFD5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SFD5"
  },
  {
    "uniprot": "A0A0N4UIX3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIX3"
  },
  {
    "uniprot": "A0A158Q5J8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5J8"
  },
  {
    "uniprot": "A0A158Q2U9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2U9"
  },
  {
    "uniprot": "A0A0N4UK81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK81"
  },
  {
    "uniprot": "A0A0N4UDC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDC0"
  },
  {
    "uniprot": "A0A158Q3I1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3I1"
  },
  {
    "uniprot": "A0A0N4U7R6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7R6"
  },
  {
    "uniprot": "A0A0N4U6N1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6N1"
  },
  {
    "uniprot": "A0A0N4U0X7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0X7"
  },
  {
    "uniprot": "A0A0N4US54",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US54"
  },
  {
    "uniprot": "A0A0N4UEB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEB7"
  },
  {
    "uniprot": "A0A3P7TBX2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TBX2"
  },
  {
    "uniprot": "A0A0N4UH92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH92"
  },
  {
    "uniprot": "A0A0N4UP41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP41"
  },
  {
    "uniprot": "A0A0N4U9Y1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Y1"
  },
  {
    "uniprot": "A0A3P7Q4E7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q4E7"
  },
  {
    "uniprot": "A0A0N4UFI2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFI2"
  },
  {
    "uniprot": "A0A3P7PTB1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PTB1"
  },
  {
    "uniprot": "A0A0N4UK60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK60"
  },
  {
    "uniprot": "A0A0N4U9C7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9C7"
  },
  {
    "uniprot": "A0A3P7QJF2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QJF2"
  },
  {
    "uniprot": "A0A158Q425",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q425"
  },
  {
    "uniprot": "A0A0N4U6E1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6E1"
  },
  {
    "uniprot": "A0A0N4UQ21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ21"
  },
  {
    "uniprot": "A0A3P7QFY1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QFY1"
  },
  {
    "uniprot": "A0A0N4ULG3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULG3"
  },
  {
    "uniprot": "A0A0N4UML9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UML9"
  },
  {
    "uniprot": "A0A0N4UDD1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDD1"
  },
  {
    "uniprot": "A0A0N4UN52",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN52"
  },
  {
    "uniprot": "A0A0N4UJM4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJM4"
  },
  {
    "uniprot": "A0A158Q686",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q686"
  },
  {
    "uniprot": "A0A3P7PUB0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PUB0"
  },
  {
    "uniprot": "A0A0N4U4C2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4C2"
  },
  {
    "uniprot": "A0A0N4UFK8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFK8"
  },
  {
    "uniprot": "A0A0N4UQ77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ77"
  },
  {
    "uniprot": "A0A0N4U7S0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7S0"
  },
  {
    "uniprot": "A0A0N4UPF2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPF2"
  },
  {
    "uniprot": "A0A0N4U2M1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2M1"
  },
  {
    "uniprot": "A0A3P7PP13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PP13"
  },
  {
    "uniprot": "A0A3P7PGV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PGV8"
  },
  {
    "uniprot": "A0A0N4UPX0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPX0"
  },
  {
    "uniprot": "A0A0N4UC13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC13"
  },
  {
    "uniprot": "A0A0N4URU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URU7"
  },
  {
    "uniprot": "A0A158Q3W6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3W6"
  },
  {
    "uniprot": "A0A0N4UDA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDA1"
  },
  {
    "uniprot": "A0A3P7SWE0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SWE0"
  },
  {
    "uniprot": "A0A0N4UJK2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJK2"
  },
  {
    "uniprot": "A0A158Q6J9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6J9"
  },
  {
    "uniprot": "A0A0N4UK50",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK50"
  },
  {
    "uniprot": "A0A3P7PH85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PH85"
  },
  {
    "uniprot": "A0A0N4UDR2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDR2"
  },
  {
    "uniprot": "A0A0N4UM12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM12"
  },
  {
    "uniprot": "A0A0N4ULD7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULD7"
  },
  {
    "uniprot": "A0A0N4U5X0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5X0"
  },
  {
    "uniprot": "A0A0N4UFJ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFJ8"
  },
  {
    "uniprot": "A0A0N4UMB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMB3"
  },
  {
    "uniprot": "A0A158Q2T6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2T6"
  },
  {
    "uniprot": "A0A3P7QBX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QBX8"
  },
  {
    "uniprot": "A0A158Q675",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q675"
  },
  {
    "uniprot": "A0A0N4UQ76",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ76"
  },
  {
    "uniprot": "A0A0N4UEU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEU6"
  },
  {
    "uniprot": "A0A0N4UNH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNH6"
  },
  {
    "uniprot": "A0A0N4UEJ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEJ2"
  },
  {
    "uniprot": "A0A0N4UKP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKP6"
  },
  {
    "uniprot": "A0A158Q350",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q350"
  },
  {
    "uniprot": "A0A158Q558",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q558"
  },
  {
    "uniprot": "A0A0N4U877",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U877"
  },
  {
    "uniprot": "A0A0N4UB59",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB59"
  },
  {
    "uniprot": "A0A0N4UN05",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN05"
  },
  {
    "uniprot": "A0A0N4UHA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHA6"
  },
  {
    "uniprot": "A0A158Q363",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q363"
  },
  {
    "uniprot": "A0A0N4UB15",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB15"
  },
  {
    "uniprot": "A0A158Q6N3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6N3"
  },
  {
    "uniprot": "A0A0N4UF88",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF88"
  },
  {
    "uniprot": "A0A158Q3R0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3R0"
  },
  {
    "uniprot": "A0A3P7QHH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QHH1"
  },
  {
    "uniprot": "A0A0N4UQQ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQQ2"
  },
  {
    "uniprot": "A0A0N4UAV0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAV0"
  },
  {
    "uniprot": "A0A0N4UK10",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK10"
  },
  {
    "uniprot": "A0A0N4U2G4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2G4"
  },
  {
    "uniprot": "A0A0N4UAZ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAZ8"
  },
  {
    "uniprot": "A0A0N4UML4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UML4"
  },
  {
    "uniprot": "A0A0N4UCZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCZ1"
  },
  {
    "uniprot": "A0A0N4UR07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR07"
  },
  {
    "uniprot": "A0A0N4UKF4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKF4"
  },
  {
    "uniprot": "A0A0N4UCN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCN3"
  },
  {
    "uniprot": "A0A0N4UNS6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNS6"
  },
  {
    "uniprot": "A0A0N4UPW7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPW7"
  },
  {
    "uniprot": "A0A158Q336",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q336"
  },
  {
    "uniprot": "A0A0N4UFS1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFS1"
  },
  {
    "uniprot": "A0A0N4UGK3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGK3"
  },
  {
    "uniprot": "A0A0N4U558",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U558"
  },
  {
    "uniprot": "A0A3P7PLP6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PLP6"
  },
  {
    "uniprot": "A0A158Q6I9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6I9"
  },
  {
    "uniprot": "A0A0N4U2X4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2X4"
  },
  {
    "uniprot": "A0A158Q4F8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4F8"
  },
  {
    "uniprot": "A0A0N4U6C8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6C8"
  },
  {
    "uniprot": "A0A0N4UBE0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBE0"
  },
  {
    "uniprot": "A0A3P7SXI7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SXI7"
  },
  {
    "uniprot": "A0A0N4UH21",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH21"
  },
  {
    "uniprot": "A0A0N4U7Z4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7Z4"
  },
  {
    "uniprot": "A0A0N4UQE5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQE5"
  },
  {
    "uniprot": "A0A3P7PNC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PNC0"
  },
  {
    "uniprot": "A0A0N4UQ38",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ38"
  },
  {
    "uniprot": "A0A0N4U858",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U858"
  },
  {
    "uniprot": "A0A3P7SBH7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SBH7"
  },
  {
    "uniprot": "A0A0N4U3Y3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3Y3"
  },
  {
    "uniprot": "A0A0N4U4R1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4R1"
  },
  {
    "uniprot": "A0A0N4UIU5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIU5"
  },
  {
    "uniprot": "A0A0N4UQC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQC1"
  },
  {
    "uniprot": "A0A0N4UQ66",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ66"
  },
  {
    "uniprot": "A0A3P7PBV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PBV7"
  },
  {
    "uniprot": "A0A158Q3N6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3N6"
  },
  {
    "uniprot": "A0A0N4U177",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U177"
  },
  {
    "uniprot": "A0A0N4U3K3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3K3"
  },
  {
    "uniprot": "A0A158Q4I7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4I7"
  },
  {
    "uniprot": "A0A158Q631",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q631"
  },
  {
    "uniprot": "A0A0N4UFF7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFF7"
  },
  {
    "uniprot": "A0A3P7Q9H8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q9H8"
  },
  {
    "uniprot": "A0A3P7SNH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SNH6"
  },
  {
    "uniprot": "A0A0N4UQA9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQA9"
  },
  {
    "uniprot": "A0A0N4UQG9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQG9"
  },
  {
    "uniprot": "A0A158Q4Z7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Z7"
  },
  {
    "uniprot": "A0A0N4U3M2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3M2"
  },
  {
    "uniprot": "A0A0N4UMW2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMW2"
  },
  {
    "uniprot": "A0A3P7PZS4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PZS4"
  },
  {
    "uniprot": "A0A0N4UIH5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIH5"
  },
  {
    "uniprot": "A0A0N4UAT7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAT7"
  },
  {
    "uniprot": "A0A0N4UHQ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHQ7"
  },
  {
    "uniprot": "A0A0N4UGL5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGL5"
  },
  {
    "uniprot": "A0A0N4UMY8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMY8"
  },
  {
    "uniprot": "A0A0N4UB37",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB37"
  },
  {
    "uniprot": "A0A158Q320",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q320"
  },
  {
    "uniprot": "A0A0N4URV7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URV7"
  },
  {
    "uniprot": "A0A0N4UR16",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR16"
  },
  {
    "uniprot": "A0A3P7QRI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QRI3"
  },
  {
    "uniprot": "A0A3P7PUT6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PUT6"
  },
  {
    "uniprot": "A0A0N4U3D7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3D7"
  },
  {
    "uniprot": "A0A0N4UMN6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMN6"
  },
  {
    "uniprot": "A0A0N4U8I4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8I4"
  },
  {
    "uniprot": "A0A0N4UNX2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNX2"
  },
  {
    "uniprot": "A0A0N4UM28",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM28"
  },
  {
    "uniprot": "A0A0N4U262",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U262"
  },
  {
    "uniprot": "A0A0N4UHF7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHF7"
  },
  {
    "uniprot": "A0A0N4UEV9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEV9"
  },
  {
    "uniprot": "A0A0N4U8R1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8R1"
  },
  {
    "uniprot": "A0A0N4UB46",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB46"
  },
  {
    "uniprot": "A0A3P7QDP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QDP9"
  },
  {
    "uniprot": "A0A0N4UGG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGG6"
  },
  {
    "uniprot": "A0A0N4U5T6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5T6"
  },
  {
    "uniprot": "A0A0N4U3E2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3E2"
  },
  {
    "uniprot": "A0A0N4U0Z0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0Z0"
  },
  {
    "uniprot": "A0A158Q2P9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2P9"
  },
  {
    "uniprot": "A0A0N4UH64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH64"
  },
  {
    "uniprot": "A0A0N4UQI7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQI7"
  },
  {
    "uniprot": "A0A0N4UMK1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMK1"
  },
  {
    "uniprot": "A0A0N4UNU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNU6"
  },
  {
    "uniprot": "A0A0N4UPG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPG6"
  },
  {
    "uniprot": "A0A158Q595",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q595"
  },
  {
    "uniprot": "A0A0N4U0T0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0T0"
  },
  {
    "uniprot": "A0A0N4UEC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEC1"
  },
  {
    "uniprot": "A0A3P7Q628",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q628"
  },
  {
    "uniprot": "A0A0N4U6V9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6V9"
  },
  {
    "uniprot": "A0A0N4UEC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEC6"
  },
  {
    "uniprot": "A0A0N4UDN8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDN8"
  },
  {
    "uniprot": "A0A0N4UHV3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHV3"
  },
  {
    "uniprot": "A0A0N4UGJ9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGJ9"
  },
  {
    "uniprot": "A0A0N4UGM6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGM6"
  },
  {
    "uniprot": "A0A0N4UAP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAP7"
  },
  {
    "uniprot": "A0A0N4US16",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US16"
  },
  {
    "uniprot": "A0A0N4UKI6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKI6"
  },
  {
    "uniprot": "A0A0N4US22",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US22"
  },
  {
    "uniprot": "A0A0N4UGL3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGL3"
  },
  {
    "uniprot": "A0A158Q3P1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3P1"
  },
  {
    "uniprot": "A0A0N4U747",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U747"
  },
  {
    "uniprot": "A0A0N4UPZ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPZ2"
  },
  {
    "uniprot": "A0A0N4UFF2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFF2"
  },
  {
    "uniprot": "A0A0N4UKA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKA5"
  },
  {
    "uniprot": "A0A0N4UHL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHL8"
  },
  {
    "uniprot": "A0A0N4UBA5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBA5"
  },
  {
    "uniprot": "A0A0N4U1X5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1X5"
  },
  {
    "uniprot": "A0A158Q698",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q698"
  },
  {
    "uniprot": "A0A0N4URC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URC6"
  },
  {
    "uniprot": "A0A0N4UQ82",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ82"
  },
  {
    "uniprot": "A0A0N4UI72",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI72"
  },
  {
    "uniprot": "A0A0N4UBA6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBA6"
  },
  {
    "uniprot": "A0A0N4UCC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCC2"
  },
  {
    "uniprot": "A0A158Q4W9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4W9"
  },
  {
    "uniprot": "A0A0N4U159",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U159"
  },
  {
    "uniprot": "A0A0N4UB78",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB78"
  },
  {
    "uniprot": "A0A0N4U727",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U727"
  },
  {
    "uniprot": "A0A0N4UES7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UES7"
  },
  {
    "uniprot": "A0A3P7SCD4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SCD4"
  },
  {
    "uniprot": "A0A0N4U7M3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7M3"
  },
  {
    "uniprot": "A0A0N4UBM4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBM4"
  },
  {
    "uniprot": "A0A0N4UAH7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAH7"
  },
  {
    "uniprot": "A0A0N4U5T0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5T0"
  },
  {
    "uniprot": "A0A0N4UEX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEX9"
  },
  {
    "uniprot": "A0A0N4UKG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKG4"
  },
  {
    "uniprot": "A0A0N4UQE0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQE0"
  },
  {
    "uniprot": "A0A0N4U647",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U647"
  },
  {
    "uniprot": "A0A0N4UR95",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR95"
  },
  {
    "uniprot": "A0A0N4U8Q7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Q7"
  },
  {
    "uniprot": "A0A0N4U415",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U415"
  },
  {
    "uniprot": "A0A0N4U142",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U142"
  },
  {
    "uniprot": "A0A0N4UNW2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNW2"
  },
  {
    "uniprot": "A0A0N4UES1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UES1"
  },
  {
    "uniprot": "A0A0N4UN83",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN83"
  },
  {
    "uniprot": "A0A158Q476",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q476"
  },
  {
    "uniprot": "A0A0N4UIP5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIP5"
  },
  {
    "uniprot": "A0A0N4UMG4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMG4"
  },
  {
    "uniprot": "A0A3P7PLU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PLU7"
  },
  {
    "uniprot": "A0A3P7T9Q8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T9Q8"
  },
  {
    "uniprot": "A0A3P7SSR8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SSR8"
  },
  {
    "uniprot": "A0A0N4U9E3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9E3"
  },
  {
    "uniprot": "A0A0N4U2C9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2C9"
  },
  {
    "uniprot": "A0A0N4UK40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK40"
  },
  {
    "uniprot": "A0A0N4UP91",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP91"
  },
  {
    "uniprot": "A0A0N4U6C9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6C9"
  },
  {
    "uniprot": "A0A0N4UAP9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAP9"
  },
  {
    "uniprot": "A0A0N4U9Q3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Q3"
  },
  {
    "uniprot": "A0A0N4U2E0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2E0"
  },
  {
    "uniprot": "A0A0N4UKM2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKM2"
  },
  {
    "uniprot": "A0A3P7QGN0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QGN0"
  },
  {
    "uniprot": "A0A0N4UIS4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIS4"
  },
  {
    "uniprot": "A0A3P7SR64",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SR64"
  },
  {
    "uniprot": "A0A0N4U470",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U470"
  },
  {
    "uniprot": "A0A0N4U603",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U603"
  },
  {
    "uniprot": "A0A0N4UJS7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJS7"
  },
  {
    "uniprot": "A0A0N4U6P1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6P1"
  },
  {
    "uniprot": "A0A0N4UI60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI60"
  },
  {
    "uniprot": "A0A0N4UE86",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE86"
  },
  {
    "uniprot": "A0A0N4UE42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE42"
  },
  {
    "uniprot": "A0A0N4U5W2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5W2"
  },
  {
    "uniprot": "A0A0N4UFL5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFL5"
  },
  {
    "uniprot": "A0A0N4UAC0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAC0"
  },
  {
    "uniprot": "A0A158Q510",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q510"
  },
  {
    "uniprot": "A0A0N4UJV8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJV8"
  },
  {
    "uniprot": "A0A3P7PKI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PKI3"
  },
  {
    "uniprot": "A0A0N4U6M3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6M3"
  },
  {
    "uniprot": "A0A0N4UKC6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKC6"
  },
  {
    "uniprot": "A0A0N4UPJ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPJ6"
  },
  {
    "uniprot": "A0A0N4U681",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U681"
  },
  {
    "uniprot": "A0A0N4UKE1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKE1"
  },
  {
    "uniprot": "A0A0N4UEE3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEE3"
  },
  {
    "uniprot": "A0A0N4UED0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UED0"
  },
  {
    "uniprot": "A0A0N4URY0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URY0"
  },
  {
    "uniprot": "A0A0N4U3C9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3C9"
  },
  {
    "uniprot": "A0A0N4UIF8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIF8"
  },
  {
    "uniprot": "A0A0N4UH01",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH01"
  },
  {
    "uniprot": "A0A0N4UKB6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKB6"
  },
  {
    "uniprot": "A0A158Q3I7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3I7"
  },
  {
    "uniprot": "A0A0N4UA24",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA24"
  },
  {
    "uniprot": "A0A0N4UP18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP18"
  },
  {
    "uniprot": "A0A0N4UF42",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF42"
  },
  {
    "uniprot": "A0A158Q3K3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3K3"
  },
  {
    "uniprot": "A0A158Q3C1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3C1"
  },
  {
    "uniprot": "A0A0N4U4V4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4V4"
  },
  {
    "uniprot": "A0A0N4UND3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UND3"
  },
  {
    "uniprot": "A0A0N4UH80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UH80"
  },
  {
    "uniprot": "A0A0N4UQR3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQR3"
  },
  {
    "uniprot": "A0A158Q3H4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3H4"
  },
  {
    "uniprot": "A0A3P7PHG2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PHG2"
  },
  {
    "uniprot": "A0A0N4UHA1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHA1"
  },
  {
    "uniprot": "A0A3P7SUL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SUL9"
  },
  {
    "uniprot": "A0A0N4U1Z5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Z5"
  },
  {
    "uniprot": "A0A158Q3Z9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Z9"
  },
  {
    "uniprot": "A0A0N4UES9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UES9"
  },
  {
    "uniprot": "A0A158Q5B0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5B0"
  },
  {
    "uniprot": "A0A0N4UA67",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA67"
  },
  {
    "uniprot": "A0A0N4U2K1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2K1"
  },
  {
    "uniprot": "A0A0N4U4R0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4R0"
  },
  {
    "uniprot": "A0A0N4U2Z6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2Z6"
  },
  {
    "uniprot": "A0A0N4UAQ2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAQ2"
  },
  {
    "uniprot": "A0A0N4U379",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U379"
  },
  {
    "uniprot": "A0A158Q300",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q300"
  },
  {
    "uniprot": "A0A0N4U5T3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5T3"
  },
  {
    "uniprot": "A0A0N4UL47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL47"
  },
  {
    "uniprot": "A0A158Q500",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q500"
  },
  {
    "uniprot": "A0A0N4UDN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDN3"
  },
  {
    "uniprot": "A0A0N4UQL1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQL1"
  },
  {
    "uniprot": "A0A0N4U7B4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7B4"
  },
  {
    "uniprot": "A0A0N4U1V8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1V8"
  },
  {
    "uniprot": "A0A0N4UPX3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPX3"
  },
  {
    "uniprot": "A0A0N4UBC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBC4"
  },
  {
    "uniprot": "A0A0N4U8C9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8C9"
  },
  {
    "uniprot": "A0A0N4U9Q2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9Q2"
  },
  {
    "uniprot": "A0A0N4U6E3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6E3"
  },
  {
    "uniprot": "A0A0N4UD20",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD20"
  },
  {
    "uniprot": "A0A3P7PYX0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PYX0"
  },
  {
    "uniprot": "A0A0N4UB25",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB25"
  },
  {
    "uniprot": "A0A0N4UFC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFC2"
  },
  {
    "uniprot": "A0A0N4URU1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URU1"
  },
  {
    "uniprot": "A0A0N4U6B7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6B7"
  },
  {
    "uniprot": "A0A3P7SH84",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SH84"
  },
  {
    "uniprot": "A0A0N4UHQ5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHQ5"
  },
  {
    "uniprot": "A0A0N4U4S7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4S7"
  },
  {
    "uniprot": "A0A0N4U3T7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3T7"
  },
  {
    "uniprot": "A0A0N4UEY6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEY6"
  },
  {
    "uniprot": "A0A0N4UNX1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNX1"
  },
  {
    "uniprot": "A0A0N4UAW8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAW8"
  },
  {
    "uniprot": "A0A0N4U761",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U761"
  },
  {
    "uniprot": "A0A0N4UFS4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFS4"
  },
  {
    "uniprot": "A0A0N4UJG1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJG1"
  },
  {
    "uniprot": "A0A0N4UKH6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKH6"
  },
  {
    "uniprot": "A0A0N4UP27",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP27"
  },
  {
    "uniprot": "A0A0N4UAI4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAI4"
  },
  {
    "uniprot": "A0A0N4U0V9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U0V9"
  },
  {
    "uniprot": "A0A0N4UFA0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFA0"
  },
  {
    "uniprot": "A0A0N4UM94",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM94"
  },
  {
    "uniprot": "A0A0N4UAZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAZ1"
  },
  {
    "uniprot": "A0A3P7Q0G7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q0G7"
  },
  {
    "uniprot": "A0A0N4U330",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U330"
  },
  {
    "uniprot": "A0A0N4UA00",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA00"
  },
  {
    "uniprot": "A0A0N4U3W8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3W8"
  },
  {
    "uniprot": "A0A158Q481",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q481"
  },
  {
    "uniprot": "A0A0N4UGY5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGY5"
  },
  {
    "uniprot": "A0A0N4UN45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN45"
  },
  {
    "uniprot": "A0A0N4UR47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR47"
  },
  {
    "uniprot": "A0A0N4UP90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UP90"
  },
  {
    "uniprot": "A0A0N4UQU1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQU1"
  },
  {
    "uniprot": "A0A0N4UBH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBH1"
  },
  {
    "uniprot": "A0A158Q340",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q340"
  },
  {
    "uniprot": "A0A0N4UJ12",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ12"
  },
  {
    "uniprot": "A0A0N4UDU9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDU9"
  },
  {
    "uniprot": "A0A158Q316",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q316"
  },
  {
    "uniprot": "A0A0N4U3U7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3U7"
  },
  {
    "uniprot": "A0A0N4UKU5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKU5"
  },
  {
    "uniprot": "A0A0N4UG83",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG83"
  },
  {
    "uniprot": "A0A0N4UJY7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJY7"
  },
  {
    "uniprot": "A0A0N4U7B0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7B0"
  },
  {
    "uniprot": "A0A0N4U5K3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5K3"
  },
  {
    "uniprot": "A0A0N4UIJ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIJ6"
  },
  {
    "uniprot": "A0A158Q4N7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4N7"
  },
  {
    "uniprot": "A0A0N4U2A8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2A8"
  },
  {
    "uniprot": "A0A0N4UGB7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGB7"
  },
  {
    "uniprot": "A0A0N4UI55",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UI55"
  },
  {
    "uniprot": "A0A3P7PIS9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PIS9"
  },
  {
    "uniprot": "A0A0N4UAN5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAN5"
  },
  {
    "uniprot": "A0A0N4U853",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U853"
  },
  {
    "uniprot": "A0A0N4U9C9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9C9"
  },
  {
    "uniprot": "A0A0N4UDC8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDC8"
  },
  {
    "uniprot": "A0A0N4U3D9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3D9"
  },
  {
    "uniprot": "A0A3P7PRH1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PRH1"
  },
  {
    "uniprot": "A0A0N4U6M4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6M4"
  },
  {
    "uniprot": "A0A0N4UR53",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR53"
  },
  {
    "uniprot": "A0A158Q6A7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6A7"
  },
  {
    "uniprot": "A0A0N4UJK7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJK7"
  },
  {
    "uniprot": "A0A0N4UK27",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK27"
  },
  {
    "uniprot": "A0A3P7T0L8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7T0L8"
  },
  {
    "uniprot": "A0A0N4UA77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA77"
  },
  {
    "uniprot": "A0A0N4U6E9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6E9"
  },
  {
    "uniprot": "A0A0N4U480",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U480"
  },
  {
    "uniprot": "A0A158Q3U6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3U6"
  },
  {
    "uniprot": "A0A0N4UBL8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBL8"
  },
  {
    "uniprot": "A0A0N4UA32",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA32"
  },
  {
    "uniprot": "A0A3P7TGM4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7TGM4"
  },
  {
    "uniprot": "A0A0N4UE15",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE15"
  },
  {
    "uniprot": "A0A158Q3Y9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3Y9"
  },
  {
    "uniprot": "A0A0N4U5M6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5M6"
  },
  {
    "uniprot": "A0A158Q3M7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3M7"
  },
  {
    "uniprot": "A0A0N4UDV0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDV0"
  },
  {
    "uniprot": "A0A3P7SN31",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SN31"
  },
  {
    "uniprot": "A0A158Q555",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q555"
  },
  {
    "uniprot": "A0A0N4UK98",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK98"
  },
  {
    "uniprot": "A0A0N4U4F8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4F8"
  },
  {
    "uniprot": "A0A0N4U4V6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4V6"
  },
  {
    "uniprot": "A0A0N4U1Y7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Y7"
  },
  {
    "uniprot": "A0A0N4UDT1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDT1"
  },
  {
    "uniprot": "A0A0N4UG75",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG75"
  },
  {
    "uniprot": "A0A0N4ULP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULP4"
  },
  {
    "uniprot": "A0A3P7QA08",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7QA08"
  },
  {
    "uniprot": "A0A158Q3W4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3W4"
  },
  {
    "uniprot": "A0A0N4U205",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U205"
  },
  {
    "uniprot": "A0A0N4UPW6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPW6"
  },
  {
    "uniprot": "A0A0N4UQT9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQT9"
  },
  {
    "uniprot": "A0A0N4UBU8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBU8"
  },
  {
    "uniprot": "A0A0N4UR99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR99"
  },
  {
    "uniprot": "A0A0N4U808",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U808"
  },
  {
    "uniprot": "A0A0N4UKN1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKN1"
  },
  {
    "uniprot": "A0A0N4URP3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URP3"
  },
  {
    "uniprot": "A0A0N4U201",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U201"
  },
  {
    "uniprot": "A0A0N4UNX5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNX5"
  },
  {
    "uniprot": "A0A3P7PWK5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PWK5"
  },
  {
    "uniprot": "A0A0N4UCK6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCK6"
  },
  {
    "uniprot": "A0A158Q2R2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2R2"
  },
  {
    "uniprot": "A0A0N4UMI5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMI5"
  },
  {
    "uniprot": "A0A0N4UHA7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHA7"
  },
  {
    "uniprot": "A0A0N4UPU6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPU6"
  },
  {
    "uniprot": "A0A158Q519",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q519"
  },
  {
    "uniprot": "A0A0N4UDR7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDR7"
  },
  {
    "uniprot": "A0A0N4US99",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US99"
  },
  {
    "uniprot": "A0A3P7PJG6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PJG6"
  },
  {
    "uniprot": "A0A158Q431",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q431"
  },
  {
    "uniprot": "A0A158Q5D4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5D4"
  },
  {
    "uniprot": "A0A0N4UA56",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA56"
  },
  {
    "uniprot": "A0A0N4UGR8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGR8"
  },
  {
    "uniprot": "A0A0N4UDW0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDW0"
  },
  {
    "uniprot": "A0A158Q344",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q344"
  },
  {
    "uniprot": "A0A0N4U5E9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5E9"
  },
  {
    "uniprot": "A0A0N4UIN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIN2"
  },
  {
    "uniprot": "A0A0N4U1H1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1H1"
  },
  {
    "uniprot": "A0A0N4U8Y9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8Y9"
  },
  {
    "uniprot": "A0A0N4UDB8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDB8"
  },
  {
    "uniprot": "A0A0N4URL9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URL9"
  },
  {
    "uniprot": "A0A0N4UQ07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ07"
  },
  {
    "uniprot": "A0A0N4UEZ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEZ6"
  },
  {
    "uniprot": "A0A158Q6F2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6F2"
  },
  {
    "uniprot": "A0A0N4U4F3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4F3"
  },
  {
    "uniprot": "A0A0N4UR80",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR80"
  },
  {
    "uniprot": "A0A0N4UQW2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQW2"
  },
  {
    "uniprot": "A0A0N4U2C2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2C2"
  },
  {
    "uniprot": "A0A0N4U4U9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4U9"
  },
  {
    "uniprot": "A0A0N4UDN2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDN2"
  },
  {
    "uniprot": "A0A0N4U615",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U615"
  },
  {
    "uniprot": "A0A0N4U6Z0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Z0"
  },
  {
    "uniprot": "A0A0N4UHX9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHX9"
  },
  {
    "uniprot": "A0A0N4U292",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U292"
  },
  {
    "uniprot": "A0A0N4U2M5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2M5"
  },
  {
    "uniprot": "A0A0N4ULI4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULI4"
  },
  {
    "uniprot": "A0A0N4UJD0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJD0"
  },
  {
    "uniprot": "A0A0N4UIB5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIB5"
  },
  {
    "uniprot": "A0A3P7SPH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SPH9"
  },
  {
    "uniprot": "A0A158Q4F6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4F6"
  },
  {
    "uniprot": "A0A0N4U2W7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2W7"
  },
  {
    "uniprot": "A0A0N4ULN3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULN3"
  },
  {
    "uniprot": "A0A3P7PV47",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PV47"
  },
  {
    "uniprot": "A0A0N4UPQ3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPQ3"
  },
  {
    "uniprot": "A0A3P7Q2V0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q2V0"
  },
  {
    "uniprot": "A0A0N4UGH0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGH0"
  },
  {
    "uniprot": "A0A0N4UG93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UG93"
  },
  {
    "uniprot": "A0A0N4U2S8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2S8"
  },
  {
    "uniprot": "A0A0N4US58",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US58"
  },
  {
    "uniprot": "A0A0N4U648",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U648"
  },
  {
    "uniprot": "A0A0N4U4K0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4K0"
  },
  {
    "uniprot": "A0A3P7ST65",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7ST65"
  },
  {
    "uniprot": "A0A3P7SPI9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SPI9"
  },
  {
    "uniprot": "A0A0N4U2U9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2U9"
  },
  {
    "uniprot": "A0A0N4U6Q8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Q8"
  },
  {
    "uniprot": "A0A0N4UBU1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBU1"
  },
  {
    "uniprot": "A0A0N4UAR9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAR9"
  },
  {
    "uniprot": "A0A0N4U215",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U215"
  },
  {
    "uniprot": "A0A0N4UGA2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGA2"
  },
  {
    "uniprot": "A0A0N4U6F8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6F8"
  },
  {
    "uniprot": "A0A0N4U4P7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4P7"
  },
  {
    "uniprot": "A0A0N4URJ6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URJ6"
  },
  {
    "uniprot": "A0A3P7PPH9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PPH9"
  },
  {
    "uniprot": "A0A0N4UB81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB81"
  },
  {
    "uniprot": "A0A0N4U7H4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7H4"
  },
  {
    "uniprot": "A0A0N4U1J7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1J7"
  },
  {
    "uniprot": "A0A0N4UPB3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPB3"
  },
  {
    "uniprot": "A0A158Q3B0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q3B0"
  },
  {
    "uniprot": "A0A0N4UBJ0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBJ0"
  },
  {
    "uniprot": "A0A3P7SR89",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7SR89"
  },
  {
    "uniprot": "A0A0N4U3E7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3E7"
  },
  {
    "uniprot": "A0A0N4UM35",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM35"
  },
  {
    "uniprot": "A0A0N4UD40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD40"
  },
  {
    "uniprot": "A0A0N4UBH7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBH7"
  },
  {
    "uniprot": "A0A158Q5Y1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5Y1"
  },
  {
    "uniprot": "A0A0N4U1D1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1D1"
  },
  {
    "uniprot": "A0A0N4UGI7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UGI7"
  },
  {
    "uniprot": "A0A0N4UA01",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UA01"
  },
  {
    "uniprot": "A0A0N4UPP7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPP7"
  },
  {
    "uniprot": "A0A158Q4A8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4A8"
  },
  {
    "uniprot": "A0A0N4UE07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE07"
  },
  {
    "uniprot": "A0A158Q324",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q324"
  },
  {
    "uniprot": "A0A0N4ULI8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULI8"
  },
  {
    "uniprot": "A0A0N4U2C8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2C8"
  },
  {
    "uniprot": "A0A0N4U901",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U901"
  },
  {
    "uniprot": "A0A0N4UB61",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB61"
  },
  {
    "uniprot": "A0A0N4U4V5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4V5"
  },
  {
    "uniprot": "A0A0N4UN81",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN81"
  },
  {
    "uniprot": "A0A0N4U3T8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3T8"
  },
  {
    "uniprot": "A0A0N4U848",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U848"
  },
  {
    "uniprot": "A0A0N4U9A5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9A5"
  },
  {
    "uniprot": "A0A0N4UAL4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAL4"
  },
  {
    "uniprot": "A0A0N4UPZ1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UPZ1"
  },
  {
    "uniprot": "A0A0N4U4N6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4N6"
  },
  {
    "uniprot": "A0A0N4ULW7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULW7"
  },
  {
    "uniprot": "A0A0N4UHX6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHX6"
  },
  {
    "uniprot": "A0A0N4U8H4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8H4"
  },
  {
    "uniprot": "A0A3P7PJT7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PJT7"
  },
  {
    "uniprot": "A0A0N4ULB6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULB6"
  },
  {
    "uniprot": "A0A0N4UIE8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UIE8"
  },
  {
    "uniprot": "A0A0N4U3F3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3F3"
  },
  {
    "uniprot": "A0A158Q312",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q312"
  },
  {
    "uniprot": "A0A0N4U9W5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9W5"
  },
  {
    "uniprot": "A0A0N4UCK7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UCK7"
  },
  {
    "uniprot": "A0A0N4UF93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UF93"
  },
  {
    "uniprot": "A0A0N4UC60",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC60"
  },
  {
    "uniprot": "A0A0N4URS6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URS6"
  },
  {
    "uniprot": "A0A0N4UC93",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC93"
  },
  {
    "uniprot": "A0A0N4UJD9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJD9"
  },
  {
    "uniprot": "A0A0N4UMB9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UMB9"
  },
  {
    "uniprot": "A0A0N4UJ18",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ18"
  },
  {
    "uniprot": "A0A0N4UB23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB23"
  },
  {
    "uniprot": "A0A0N4UB13",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB13"
  },
  {
    "uniprot": "A0A0N4UJP4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJP4"
  },
  {
    "uniprot": "A0A0N4U1Y6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1Y6"
  },
  {
    "uniprot": "A0A158Q399",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q399"
  },
  {
    "uniprot": "A0A0N4U534",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U534"
  },
  {
    "uniprot": "A0A0N4UL37",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL37"
  },
  {
    "uniprot": "A0A0N4UQ90",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UQ90"
  },
  {
    "uniprot": "A0A0N4UBF6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBF6"
  },
  {
    "uniprot": "A0A158Q2S2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2S2"
  },
  {
    "uniprot": "A0A0N4UC45",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UC45"
  },
  {
    "uniprot": "A0A158Q629",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q629"
  },
  {
    "uniprot": "A0A0N4UFP0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFP0"
  },
  {
    "uniprot": "A0A0N4UK39",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UK39"
  },
  {
    "uniprot": "A0A0N4UN92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN92"
  },
  {
    "uniprot": "A0A158Q2L2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2L2"
  },
  {
    "uniprot": "A0A0N4URC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URC4"
  },
  {
    "uniprot": "A0A0N4UD07",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UD07"
  },
  {
    "uniprot": "A0A0N4UDX3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDX3"
  },
  {
    "uniprot": "A0A0N4UR85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UR85"
  },
  {
    "uniprot": "A0A158Q378",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q378"
  },
  {
    "uniprot": "A0A0N4U3F0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3F0"
  },
  {
    "uniprot": "A0A158Q5V6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5V6"
  },
  {
    "uniprot": "A0A0N4U6Z6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U6Z6"
  },
  {
    "uniprot": "A0A158Q6E7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q6E7"
  },
  {
    "uniprot": "A0A0N4U1H2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1H2"
  },
  {
    "uniprot": "A0A158Q2I7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q2I7"
  },
  {
    "uniprot": "A0A3P7PJ29",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PJ29"
  },
  {
    "uniprot": "A0A3P7PGL2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PGL2"
  },
  {
    "uniprot": "A0A0N4UAC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAC3"
  },
  {
    "uniprot": "A0A0N4US40",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4US40"
  },
  {
    "uniprot": "A0A0N4UE92",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UE92"
  },
  {
    "uniprot": "A0A0N4UNP1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UNP1"
  },
  {
    "uniprot": "A0A0N4URB2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URB2"
  },
  {
    "uniprot": "A0A158Q560",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q560"
  },
  {
    "uniprot": "A0A3P7R2U5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7R2U5"
  },
  {
    "uniprot": "A0A0N4UJ85",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UJ85"
  },
  {
    "uniprot": "A0A0N4U5F4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U5F4"
  },
  {
    "uniprot": "A0A0N4U3R9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U3R9"
  },
  {
    "uniprot": "A0A0N4UBT4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBT4"
  },
  {
    "uniprot": "A0A0N4U4Q9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U4Q9"
  },
  {
    "uniprot": "A0A0N4U8W2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U8W2"
  },
  {
    "uniprot": "A0A0N4UDX8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDX8"
  },
  {
    "uniprot": "A0A3P7Q9S5",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7Q9S5"
  },
  {
    "uniprot": "A0A0N4U7N9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7N9"
  },
  {
    "uniprot": "A0A0N4UFC1",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFC1"
  },
  {
    "uniprot": "A0A0N4UB62",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UB62"
  },
  {
    "uniprot": "A0A3P7PZQ4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PZQ4"
  },
  {
    "uniprot": "A0A0N4U7N7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U7N7"
  },
  {
    "uniprot": "A0A0N4UL41",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL41"
  },
  {
    "uniprot": "A0A0N4UKI3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UKI3"
  },
  {
    "uniprot": "A0A0N4U839",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U839"
  },
  {
    "uniprot": "A0A158Q5K7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5K7"
  },
  {
    "uniprot": "A0A0N4U1K6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U1K6"
  },
  {
    "uniprot": "A0A0N4UBV9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBV9"
  },
  {
    "uniprot": "A0A0N4U9L8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9L8"
  },
  {
    "uniprot": "A0A0N4UAE2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UAE2"
  },
  {
    "uniprot": "A0A0N4UDC4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDC4"
  },
  {
    "uniprot": "A0A0N4UBN9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UBN9"
  },
  {
    "uniprot": "A0A3P7PEJ7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A3P7PEJ7"
  },
  {
    "uniprot": "A0A0N4ULD2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4ULD2"
  },
  {
    "uniprot": "A0A0N4UEU7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEU7"
  },
  {
    "uniprot": "A0A0N4UM30",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UM30"
  },
  {
    "uniprot": "A0A0N4U9S0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U9S0"
  },
  {
    "uniprot": "A0A158Q434",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q434"
  },
  {
    "uniprot": "A0A0N4U2P2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4U2P2"
  },
  {
    "uniprot": "A0A0N4UEG7",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UEG7"
  },
  {
    "uniprot": "A0A0N4UL77",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UL77"
  },
  {
    "uniprot": "A0A0N4UFI0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UFI0"
  },
  {
    "uniprot": "A0A0N4USC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4USC2"
  },
  {
    "uniprot": "A0A0N4UDY4",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDY4"
  },
  {
    "uniprot": "A0A0N4UHC3",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UHC3"
  },
  {
    "uniprot": "A0A0N4UN23",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UN23"
  },
  {
    "uniprot": "A0A0N4UDQ8",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4UDQ8"
  },
  {
    "uniprot": "A0A158Q5I9",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5I9"
  },
  {
    "uniprot": "A0A158Q5X6",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q5X6"
  },
  {
    "uniprot": "A0A158Q4Z0",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A158Q4Z0"
  },
  {
    "uniprot": "A0A0N4URC2",
    "species": "Dracunculus medinensis",
    "disease": "Dracunculiasis",
    "results": "/results/A0A0N4URC2"
  },
  {
    "uniprot": "Q9CCD0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCD0"
  },
  {
    "uniprot": "Q9ZBD8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9ZBD8"
  },
  {
    "uniprot": "Q49857",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49857"
  },
  {
    "uniprot": "Q7AQC8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQC8"
  },
  {
    "uniprot": "Q7AQ42",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ42"
  },
  {
    "uniprot": "Q9CBG0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBG0"
  },
  {
    "uniprot": "Q9CC66",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC66"
  },
  {
    "uniprot": "Q9CBP7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBP7"
  },
  {
    "uniprot": "Q9CC86",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC86"
  },
  {
    "uniprot": "Q9CB32",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB32"
  },
  {
    "uniprot": "Q9CBC3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBC3"
  },
  {
    "uniprot": "Q9CCI5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCI5"
  },
  {
    "uniprot": "Q7APX7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APX7"
  },
  {
    "uniprot": "Q49649",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49649"
  },
  {
    "uniprot": "P54138",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54138"
  },
  {
    "uniprot": "Q7AQP2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQP2"
  },
  {
    "uniprot": "Q7AQ57",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ57"
  },
  {
    "uniprot": "Q9S375",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9S375"
  },
  {
    "uniprot": "O33084",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33084"
  },
  {
    "uniprot": "Q7APR8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APR8"
  },
  {
    "uniprot": "Q9CBN2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBN2"
  },
  {
    "uniprot": "Q9CC02",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC02"
  },
  {
    "uniprot": "Q7APY9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APY9"
  },
  {
    "uniprot": "Q9CB34",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB34"
  },
  {
    "uniprot": "Q9CCB6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCB6"
  },
  {
    "uniprot": "Q7AQF8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQF8"
  },
  {
    "uniprot": "P53524",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53524"
  },
  {
    "uniprot": "Q9CBG1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBG1"
  },
  {
    "uniprot": "Q9CBZ5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBZ5"
  },
  {
    "uniprot": "P46388",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46388"
  },
  {
    "uniprot": "Q9CD04",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD04"
  },
  {
    "uniprot": "Q9X7E3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7E3"
  },
  {
    "uniprot": "Q50043",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50043"
  },
  {
    "uniprot": "Q9CCI1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCI1"
  },
  {
    "uniprot": "Q7AQM0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQM0"
  },
  {
    "uniprot": "O05756",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O05756"
  },
  {
    "uniprot": "Q9CD87",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD87"
  },
  {
    "uniprot": "Q7AQ31",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ31"
  },
  {
    "uniprot": "Q9CC51",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC51"
  },
  {
    "uniprot": "Q50121",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50121"
  },
  {
    "uniprot": "Q7AQL8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQL8"
  },
  {
    "uniprot": "Q9CD58",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD58"
  },
  {
    "uniprot": "P57990",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P57990"
  },
  {
    "uniprot": "O69524",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69524"
  },
  {
    "uniprot": "Q9CD20",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD20"
  },
  {
    "uniprot": "Q9CCH6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCH6"
  },
  {
    "uniprot": "Q9CC11",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC11"
  },
  {
    "uniprot": "Q49752",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49752"
  },
  {
    "uniprot": "P45831",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45831"
  },
  {
    "uniprot": "Q9CBK0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBK0"
  },
  {
    "uniprot": "P31951",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P31951"
  },
  {
    "uniprot": "Q9CC59",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC59"
  },
  {
    "uniprot": "O69475",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69475"
  },
  {
    "uniprot": "Q9CCB8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCB8"
  },
  {
    "uniprot": "Q9CBJ5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBJ5"
  },
  {
    "uniprot": "Q7AQE6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQE6"
  },
  {
    "uniprot": "Q9X7C7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7C7"
  },
  {
    "uniprot": "Q7AQ12",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ12"
  },
  {
    "uniprot": "Q9CBW1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBW1"
  },
  {
    "uniprot": "Q7AQ96",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ96"
  },
  {
    "uniprot": "Q7APW2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APW2"
  },
  {
    "uniprot": "Q9CD01",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD01"
  },
  {
    "uniprot": "P45822",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45822"
  },
  {
    "uniprot": "Q9CD36",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD36"
  },
  {
    "uniprot": "Q9Z5H8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9Z5H8"
  },
  {
    "uniprot": "Q9CBU4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBU4"
  },
  {
    "uniprot": "P38386",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P38386"
  },
  {
    "uniprot": "Q50173",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50173"
  },
  {
    "uniprot": "Q7APT2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APT2"
  },
  {
    "uniprot": "Q7APV1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APV1"
  },
  {
    "uniprot": "P45827",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45827"
  },
  {
    "uniprot": "Q7AQI6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQI6"
  },
  {
    "uniprot": "Q9CD71",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD71"
  },
  {
    "uniprot": "Q9CC12",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC12"
  },
  {
    "uniprot": "Q7AQA3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQA3"
  },
  {
    "uniprot": "P38388",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P38388"
  },
  {
    "uniprot": "Q9CB85",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB85"
  },
  {
    "uniprot": "Q9CCZ5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCZ5"
  },
  {
    "uniprot": "Q9CC04",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC04"
  },
  {
    "uniprot": "P19993",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P19993"
  },
  {
    "uniprot": "O06084",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O06084"
  },
  {
    "uniprot": "Q7AQK2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQK2"
  },
  {
    "uniprot": "Q9CD66",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD66"
  },
  {
    "uniprot": "Q9CB97",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB97"
  },
  {
    "uniprot": "Q49751",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49751"
  },
  {
    "uniprot": "Q9CC29",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC29"
  },
  {
    "uniprot": "O05564",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O05564"
  },
  {
    "uniprot": "Q7APT0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APT0"
  },
  {
    "uniprot": "Q9CDA0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDA0"
  },
  {
    "uniprot": "Q9CBA2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBA2"
  },
  {
    "uniprot": "Q9CBV7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBV7"
  },
  {
    "uniprot": "Q9CDC4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDC4"
  },
  {
    "uniprot": "Q9CCT0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCT0"
  },
  {
    "uniprot": "Q7AQ83",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ83"
  },
  {
    "uniprot": "Q9CB42",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB42"
  },
  {
    "uniprot": "Q9CCA3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCA3"
  },
  {
    "uniprot": "Q7AQ16",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ16"
  },
  {
    "uniprot": "P45834",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45834"
  },
  {
    "uniprot": "Q9CBB5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBB5"
  },
  {
    "uniprot": "Q49618",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49618"
  },
  {
    "uniprot": "Q9CBT4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBT4"
  },
  {
    "uniprot": "Q49848",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49848"
  },
  {
    "uniprot": "Q9CCD2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCD2"
  },
  {
    "uniprot": "O69473",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69473"
  },
  {
    "uniprot": "Q7AQ44",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ44"
  },
  {
    "uniprot": "Q9CBD2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBD2"
  },
  {
    "uniprot": "Q9CCE5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCE5"
  },
  {
    "uniprot": "P46808",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46808"
  },
  {
    "uniprot": "P40831",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P40831"
  },
  {
    "uniprot": "Q9CB31",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB31"
  },
  {
    "uniprot": "P54133",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54133"
  },
  {
    "uniprot": "Q7AQ59",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ59"
  },
  {
    "uniprot": "Q9CBR7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBR7"
  },
  {
    "uniprot": "Q7AQD3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQD3"
  },
  {
    "uniprot": "O33038",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33038"
  },
  {
    "uniprot": "Q7AQB1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQB1"
  },
  {
    "uniprot": "Q9CCC5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCC5"
  },
  {
    "uniprot": "Q9CD43",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD43"
  },
  {
    "uniprot": "Q9CCK1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCK1"
  },
  {
    "uniprot": "Q7AQ26",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ26"
  },
  {
    "uniprot": "P54882",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54882"
  },
  {
    "uniprot": "Q7APS5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APS5"
  },
  {
    "uniprot": "Q9CCA1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCA1"
  },
  {
    "uniprot": "Q49894",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49894"
  },
  {
    "uniprot": "Q7APV5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APV5"
  },
  {
    "uniprot": "Q9CC17",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC17"
  },
  {
    "uniprot": "Q9CDD7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDD7"
  },
  {
    "uniprot": "Q9CCV1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCV1"
  },
  {
    "uniprot": "Q9CDE0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDE0"
  },
  {
    "uniprot": "Q7APU2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APU2"
  },
  {
    "uniprot": "Q9CD16",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD16"
  },
  {
    "uniprot": "O69548",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69548"
  },
  {
    "uniprot": "Q9CBW9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBW9"
  },
  {
    "uniprot": "Q9CB88",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB88"
  },
  {
    "uniprot": "Q9CDB4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDB4"
  },
  {
    "uniprot": "Q7AQH2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQH2"
  },
  {
    "uniprot": "Q9CBG8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBG8"
  },
  {
    "uniprot": "Q9CCL7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCL7"
  },
  {
    "uniprot": "Q9CC47",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC47"
  },
  {
    "uniprot": "P52982",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P52982"
  },
  {
    "uniprot": "O33039",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33039"
  },
  {
    "uniprot": "Q7AQL6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQL6"
  },
  {
    "uniprot": "Q9CC07",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC07"
  },
  {
    "uniprot": "Q9CD19",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD19"
  },
  {
    "uniprot": "O33002",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33002"
  },
  {
    "uniprot": "Q7AQC9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQC9"
  },
  {
    "uniprot": "Q7APT4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APT4"
  },
  {
    "uniprot": "Q9CB19",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB19"
  },
  {
    "uniprot": "Q9CD39",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD39"
  },
  {
    "uniprot": "Q9CCF4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCF4"
  },
  {
    "uniprot": "O32871",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32871"
  },
  {
    "uniprot": "Q9CDB2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDB2"
  },
  {
    "uniprot": "Q7APZ1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APZ1"
  },
  {
    "uniprot": "Q9CBH9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBH9"
  },
  {
    "uniprot": "Q9CCE8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCE8"
  },
  {
    "uniprot": "Q9CCZ6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCZ6"
  },
  {
    "uniprot": "Q9CCE4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCE4"
  },
  {
    "uniprot": "O32954",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32954"
  },
  {
    "uniprot": "Q9CC71",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC71"
  },
  {
    "uniprot": "Q9CBL0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBL0"
  },
  {
    "uniprot": "Q9CBU5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBU5"
  },
  {
    "uniprot": "Q49647",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49647"
  },
  {
    "uniprot": "Q9CBY8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBY8"
  },
  {
    "uniprot": "Q9CD63",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD63"
  },
  {
    "uniprot": "Q7APT7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APT7"
  },
  {
    "uniprot": "Q7APX2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APX2"
  },
  {
    "uniprot": "Q7AQN6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQN6"
  },
  {
    "uniprot": "Q9CBD5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBD5"
  },
  {
    "uniprot": "O05668",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O05668"
  },
  {
    "uniprot": "Q7AQN7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQN7"
  },
  {
    "uniprot": "P54077",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54077"
  },
  {
    "uniprot": "Q9CCB3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCB3"
  },
  {
    "uniprot": "Q9CCD7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCD7"
  },
  {
    "uniprot": "P46861",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46861"
  },
  {
    "uniprot": "P53431",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53431"
  },
  {
    "uniprot": "P19361",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P19361"
  },
  {
    "uniprot": "P46839",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46839"
  },
  {
    "uniprot": "Q9CCG9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCG9"
  },
  {
    "uniprot": "Q9CB61",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB61"
  },
  {
    "uniprot": "Q9CBT1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBT1"
  },
  {
    "uniprot": "Q9CBX5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBX5"
  },
  {
    "uniprot": "Q9CC84",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC84"
  },
  {
    "uniprot": "Q49776",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49776"
  },
  {
    "uniprot": "Q7AQJ7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQJ7"
  },
  {
    "uniprot": "Q49803",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49803"
  },
  {
    "uniprot": "Q49724",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49724"
  },
  {
    "uniprot": "Q9CBP9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBP9"
  },
  {
    "uniprot": "Q9CC38",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC38"
  },
  {
    "uniprot": "Q49730",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49730"
  },
  {
    "uniprot": "Q9CCP6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCP6"
  },
  {
    "uniprot": "Q49738",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49738"
  },
  {
    "uniprot": "Q7AQF5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQF5"
  },
  {
    "uniprot": "Q9CD74",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD74"
  },
  {
    "uniprot": "Q9CD33",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD33"
  },
  {
    "uniprot": "Q9CBV5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBV5"
  },
  {
    "uniprot": "Q50136",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50136"
  },
  {
    "uniprot": "Q9CBK1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBK1"
  },
  {
    "uniprot": "Q9CB73",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB73"
  },
  {
    "uniprot": "Q7AQF7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQF7"
  },
  {
    "uniprot": "Q9CBJ6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBJ6"
  },
  {
    "uniprot": "Q7AQF4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQF4"
  },
  {
    "uniprot": "Q7APX4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APX4"
  },
  {
    "uniprot": "Q7AQ38",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ38"
  },
  {
    "uniprot": "Q7AQ99",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ99"
  },
  {
    "uniprot": "Q7AQK9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQK9"
  },
  {
    "uniprot": "Q9CC62",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC62"
  },
  {
    "uniprot": "Q9CC34",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC34"
  },
  {
    "uniprot": "Q9CBD3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBD3"
  },
  {
    "uniprot": "Q9CD31",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD31"
  },
  {
    "uniprot": "O33105",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33105"
  },
  {
    "uniprot": "O32920",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32920"
  },
  {
    "uniprot": "Q7AQI3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQI3"
  },
  {
    "uniprot": "Q9X7C0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7C0"
  },
  {
    "uniprot": "O69595",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69595"
  },
  {
    "uniprot": "Q9CBF4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBF4"
  },
  {
    "uniprot": "Q9CD91",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD91"
  },
  {
    "uniprot": "Q9CCH5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCH5"
  },
  {
    "uniprot": "P46390",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46390"
  },
  {
    "uniprot": "Q9CCT7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCT7"
  },
  {
    "uniprot": "Q9CD76",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD76"
  },
  {
    "uniprot": "Q9CBL8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBL8"
  },
  {
    "uniprot": "P0C0X1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P0C0X1"
  },
  {
    "uniprot": "Q7AQ50",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ50"
  },
  {
    "uniprot": "Q9CDA3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDA3"
  },
  {
    "uniprot": "Q9CBM8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBM8"
  },
  {
    "uniprot": "Q9CC82",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC82"
  },
  {
    "uniprot": "Q9CC19",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC19"
  },
  {
    "uniprot": "Q49721",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49721"
  },
  {
    "uniprot": "Q50186",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50186"
  },
  {
    "uniprot": "Q9CCP5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCP5"
  },
  {
    "uniprot": "Q9CCM7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCM7"
  },
  {
    "uniprot": "Q7AQF1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQF1"
  },
  {
    "uniprot": "Q9CC16",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC16"
  },
  {
    "uniprot": "Q7AQL1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQL1"
  },
  {
    "uniprot": "Q9CCD1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCD1"
  },
  {
    "uniprot": "Q9CCC8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCC8"
  },
  {
    "uniprot": "Q9CDF1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDF1"
  },
  {
    "uniprot": "Q9CD26",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD26"
  },
  {
    "uniprot": "O69468",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69468"
  },
  {
    "uniprot": "O33040",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33040"
  },
  {
    "uniprot": "Q7AQ94",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ94"
  },
  {
    "uniprot": "Q50022",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50022"
  },
  {
    "uniprot": "Q9CBV6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBV6"
  },
  {
    "uniprot": "Q9CCX5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCX5"
  },
  {
    "uniprot": "P53526",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53526"
  },
  {
    "uniprot": "Q7APY3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APY3"
  },
  {
    "uniprot": "P46700",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46700"
  },
  {
    "uniprot": "Q9CCY5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCY5"
  },
  {
    "uniprot": "O33075",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33075"
  },
  {
    "uniprot": "Q9CBQ8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBQ8"
  },
  {
    "uniprot": "P46712",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46712"
  },
  {
    "uniprot": "Q9CBX2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBX2"
  },
  {
    "uniprot": "P46840",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46840"
  },
  {
    "uniprot": "Q7APY6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APY6"
  },
  {
    "uniprot": "O32912",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32912"
  },
  {
    "uniprot": "Q9CCT2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCT2"
  },
  {
    "uniprot": "O32975",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32975"
  },
  {
    "uniprot": "Q9CD77",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD77"
  },
  {
    "uniprot": "Q9CB62",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB62"
  },
  {
    "uniprot": "P30767",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P30767"
  },
  {
    "uniprot": "Q9CCL5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCL5"
  },
  {
    "uniprot": "Q7AQH0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQH0"
  },
  {
    "uniprot": "Q9CBE3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBE3"
  },
  {
    "uniprot": "Q50046",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50046"
  },
  {
    "uniprot": "P40829",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P40829"
  },
  {
    "uniprot": "Q9CD86",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD86"
  },
  {
    "uniprot": "Q9CBE2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBE2"
  },
  {
    "uniprot": "Q9CCW1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCW1"
  },
  {
    "uniprot": "Q7AQL0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQL0"
  },
  {
    "uniprot": "Q9CBZ7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBZ7"
  },
  {
    "uniprot": "Q7APV4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APV4"
  },
  {
    "uniprot": "Q9CBW0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBW0"
  },
  {
    "uniprot": "Q9CB64",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB64"
  },
  {
    "uniprot": "Q9CBD7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBD7"
  },
  {
    "uniprot": "Q9CCN4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCN4"
  },
  {
    "uniprot": "O33132",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33132"
  },
  {
    "uniprot": "Q9CD46",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD46"
  },
  {
    "uniprot": "Q9CBI8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBI8"
  },
  {
    "uniprot": "Q9CCQ9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCQ9"
  },
  {
    "uniprot": "Q49885",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49885"
  },
  {
    "uniprot": "Q9CB27",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB27"
  },
  {
    "uniprot": "O69600",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69600"
  },
  {
    "uniprot": "Q7AQF0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQF0"
  },
  {
    "uniprot": "Q7AQ14",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ14"
  },
  {
    "uniprot": "Q9CD29",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD29"
  },
  {
    "uniprot": "O32995",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32995"
  },
  {
    "uniprot": "O07708",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O07708"
  },
  {
    "uniprot": "Q9CCS8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCS8"
  },
  {
    "uniprot": "Q49823",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49823"
  },
  {
    "uniprot": "Q7APZ4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APZ4"
  },
  {
    "uniprot": "P30766",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P30766"
  },
  {
    "uniprot": "Q9CDD4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDD4"
  },
  {
    "uniprot": "Q7APW9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APW9"
  },
  {
    "uniprot": "Q9CBY1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBY1"
  },
  {
    "uniprot": "O05755",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O05755"
  },
  {
    "uniprot": "Q7AQJ2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQJ2"
  },
  {
    "uniprot": "Q9CCS3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCS3"
  },
  {
    "uniprot": "Q7AQ90",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ90"
  },
  {
    "uniprot": "Q7AQ92",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ92"
  },
  {
    "uniprot": "Q9CC42",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC42"
  },
  {
    "uniprot": "Q9CCF5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCF5"
  },
  {
    "uniprot": "P13733",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P13733"
  },
  {
    "uniprot": "Q9CB60",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB60"
  },
  {
    "uniprot": "Q50140",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50140"
  },
  {
    "uniprot": "Q9CCL2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCL2"
  },
  {
    "uniprot": "Q9CCG4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCG4"
  },
  {
    "uniprot": "Q9CBL2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBL2"
  },
  {
    "uniprot": "Q9CCA0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCA0"
  },
  {
    "uniprot": "Q9CBJ1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBJ1"
  },
  {
    "uniprot": "Q7AQ87",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ87"
  },
  {
    "uniprot": "Q9CCG7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCG7"
  },
  {
    "uniprot": "P46835",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46835"
  },
  {
    "uniprot": "Q9CD93",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD93"
  },
  {
    "uniprot": "Q9Z5I7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9Z5I7"
  },
  {
    "uniprot": "O33123",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33123"
  },
  {
    "uniprot": "O69581",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69581"
  },
  {
    "uniprot": "P45825",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45825"
  },
  {
    "uniprot": "Q9CB77",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB77"
  },
  {
    "uniprot": "Q9CC70",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC70"
  },
  {
    "uniprot": "Q9CBC0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBC0"
  },
  {
    "uniprot": "Q9X7B0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7B0"
  },
  {
    "uniprot": "O33045",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33045"
  },
  {
    "uniprot": "Q7AQC7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQC7"
  },
  {
    "uniprot": "Q9CCB4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCB4"
  },
  {
    "uniprot": "O33120",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33120"
  },
  {
    "uniprot": "Q9CDD3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDD3"
  },
  {
    "uniprot": "Q9CC94",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC94"
  },
  {
    "uniprot": "Q9CD84",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD84"
  },
  {
    "uniprot": "Q9CD02",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD02"
  },
  {
    "uniprot": "Q7AQ15",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ15"
  },
  {
    "uniprot": "Q9CC24",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC24"
  },
  {
    "uniprot": "Q9CB33",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB33"
  },
  {
    "uniprot": "Q9CBB0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBB0"
  },
  {
    "uniprot": "Q9X796",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X796"
  },
  {
    "uniprot": "O69552",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69552"
  },
  {
    "uniprot": "Q9CC88",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC88"
  },
  {
    "uniprot": "Q9CBU0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBU0"
  },
  {
    "uniprot": "Q9CCJ3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCJ3"
  },
  {
    "uniprot": "Q49988",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49988"
  },
  {
    "uniprot": "O32870",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32870"
  },
  {
    "uniprot": "Q9CDE8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDE8"
  },
  {
    "uniprot": "Q7APS6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APS6"
  },
  {
    "uniprot": "O32961",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32961"
  },
  {
    "uniprot": "Q9X7E7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7E7"
  },
  {
    "uniprot": "Q9CB69",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB69"
  },
  {
    "uniprot": "Q9ZBL1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9ZBL1"
  },
  {
    "uniprot": "Q50144",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50144"
  },
  {
    "uniprot": "P46710",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46710"
  },
  {
    "uniprot": "O32978",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32978"
  },
  {
    "uniprot": "P46716",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46716"
  },
  {
    "uniprot": "Q50205",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50205"
  },
  {
    "uniprot": "Q9CBM5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBM5"
  },
  {
    "uniprot": "Q7APX3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APX3"
  },
  {
    "uniprot": "P45828",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45828"
  },
  {
    "uniprot": "Q9CBE8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBE8"
  },
  {
    "uniprot": "Q7AQG8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQG8"
  },
  {
    "uniprot": "Q9CD51",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD51"
  },
  {
    "uniprot": "Q9CB86",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB86"
  },
  {
    "uniprot": "Q7APU5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APU5"
  },
  {
    "uniprot": "Q9CB17",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB17"
  },
  {
    "uniprot": "Q7AQ48",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ48"
  },
  {
    "uniprot": "Q9CDA8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDA8"
  },
  {
    "uniprot": "Q7AQ17",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ17"
  },
  {
    "uniprot": "Q9CCT4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCT4"
  },
  {
    "uniprot": "Q9CBK7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBK7"
  },
  {
    "uniprot": "O33046",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33046"
  },
  {
    "uniprot": "Q9CD27",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD27"
  },
  {
    "uniprot": "Q9ZBM7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9ZBM7"
  },
  {
    "uniprot": "O32988",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32988"
  },
  {
    "uniprot": "Q9CDD8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDD8"
  },
  {
    "uniprot": "P46705",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46705"
  },
  {
    "uniprot": "Q9CDB6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDB6"
  },
  {
    "uniprot": "Q9CD80",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD80"
  },
  {
    "uniprot": "P37859",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P37859"
  },
  {
    "uniprot": "Q9CCH1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCH1"
  },
  {
    "uniprot": "Q9CD30",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD30"
  },
  {
    "uniprot": "Q7AQ05",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ05"
  },
  {
    "uniprot": "P46394",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46394"
  },
  {
    "uniprot": "Q9CCH2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCH2"
  },
  {
    "uniprot": "Q7APU4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APU4"
  },
  {
    "uniprot": "Q9X799",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X799"
  },
  {
    "uniprot": "O32993",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32993"
  },
  {
    "uniprot": "O69519",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69519"
  },
  {
    "uniprot": "Q9CBK6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBK6"
  },
  {
    "uniprot": "Q49616",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49616"
  },
  {
    "uniprot": "P46836",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46836"
  },
  {
    "uniprot": "Q9CCJ1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCJ1"
  },
  {
    "uniprot": "Q9CBN5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBN5"
  },
  {
    "uniprot": "P53432",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53432"
  },
  {
    "uniprot": "Q7AQB7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQB7"
  },
  {
    "uniprot": "Q7APU3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APU3"
  },
  {
    "uniprot": "Q9CC58",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC58"
  },
  {
    "uniprot": "Q59531",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q59531"
  },
  {
    "uniprot": "P30768",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P30768"
  },
  {
    "uniprot": "O33062",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33062"
  },
  {
    "uniprot": "Q9CDB5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDB5"
  },
  {
    "uniprot": "Q9CCL3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCL3"
  },
  {
    "uniprot": "Q9CBY3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBY3"
  },
  {
    "uniprot": "Q7AQI4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQI4"
  },
  {
    "uniprot": "Q7AQ49",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ49"
  },
  {
    "uniprot": "Q9CBX4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBX4"
  },
  {
    "uniprot": "Q9CC06",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC06"
  },
  {
    "uniprot": "P24301",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P24301"
  },
  {
    "uniprot": "Q9CD62",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD62"
  },
  {
    "uniprot": "Q9CBK2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBK2"
  },
  {
    "uniprot": "Q9CCI2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCI2"
  },
  {
    "uniprot": "Q9CBL9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBL9"
  },
  {
    "uniprot": "Q7AQC2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQC2"
  },
  {
    "uniprot": "Q7APW5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APW5"
  },
  {
    "uniprot": "Q9CCU0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCU0"
  },
  {
    "uniprot": "Q9CCK6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCK6"
  },
  {
    "uniprot": "Q9CB95",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB95"
  },
  {
    "uniprot": "Q59533",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q59533"
  },
  {
    "uniprot": "Q7AQ65",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ65"
  },
  {
    "uniprot": "O33011",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33011"
  },
  {
    "uniprot": "Q9CBR1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBR1"
  },
  {
    "uniprot": "O69497",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69497"
  },
  {
    "uniprot": "Q9CBD8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBD8"
  },
  {
    "uniprot": "Q7AQC0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQC0"
  },
  {
    "uniprot": "Q7AQF2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQF2"
  },
  {
    "uniprot": "Q9CD35",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD35"
  },
  {
    "uniprot": "Q9CB35",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB35"
  },
  {
    "uniprot": "O69582",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69582"
  },
  {
    "uniprot": "Q9X7B8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7B8"
  },
  {
    "uniprot": "Q9CD79",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD79"
  },
  {
    "uniprot": "Q9CB39",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB39"
  },
  {
    "uniprot": "Q7AQ36",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ36"
  },
  {
    "uniprot": "P38014",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P38014"
  },
  {
    "uniprot": "Q49722",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49722"
  },
  {
    "uniprot": "Q9CCC2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCC2"
  },
  {
    "uniprot": "Q7APY7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APY7"
  },
  {
    "uniprot": "Q9CC08",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC08"
  },
  {
    "uniprot": "Q9CBC4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBC4"
  },
  {
    "uniprot": "Q7APZ5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APZ5"
  },
  {
    "uniprot": "O33015",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33015"
  },
  {
    "uniprot": "P53531",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53531"
  },
  {
    "uniprot": "Q9CCE3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCE3"
  },
  {
    "uniprot": "P46392",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46392"
  },
  {
    "uniprot": "Q9CBM7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBM7"
  },
  {
    "uniprot": "P46727",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46727"
  },
  {
    "uniprot": "O32999",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32999"
  },
  {
    "uniprot": "Q49736",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49736"
  },
  {
    "uniprot": "Q9CCD8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCD8"
  },
  {
    "uniprot": "Q9CCW3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCW3"
  },
  {
    "uniprot": "Q7AQ45",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ45"
  },
  {
    "uniprot": "Q9CBN1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBN1"
  },
  {
    "uniprot": "Q7APZ3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APZ3"
  },
  {
    "uniprot": "Q9CBW7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBW7"
  },
  {
    "uniprot": "Q9CDA5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDA5"
  },
  {
    "uniprot": "Q9CD59",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD59"
  },
  {
    "uniprot": "Q9CDA6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDA6"
  },
  {
    "uniprot": "O32915",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32915"
  },
  {
    "uniprot": "Q9CCX0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCX0"
  },
  {
    "uniprot": "Q9CBY5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBY5"
  },
  {
    "uniprot": "O69460",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69460"
  },
  {
    "uniprot": "Q9CB41",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB41"
  },
  {
    "uniprot": "Q9CD12",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD12"
  },
  {
    "uniprot": "Q9CBA0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBA0"
  },
  {
    "uniprot": "Q9CCR9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCR9"
  },
  {
    "uniprot": "Q7AQD8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQD8"
  },
  {
    "uniprot": "Q9CCC1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCC1"
  },
  {
    "uniprot": "Q9CBE1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBE1"
  },
  {
    "uniprot": "Q9CCD3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCD3"
  },
  {
    "uniprot": "Q9CB57",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB57"
  },
  {
    "uniprot": "Q7AQJ0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQJ0"
  },
  {
    "uniprot": "O32955",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32955"
  },
  {
    "uniprot": "Q7APY4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APY4"
  },
  {
    "uniprot": "Q9CCY1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCY1"
  },
  {
    "uniprot": "Q9CCX8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCX8"
  },
  {
    "uniprot": "P46703",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46703"
  },
  {
    "uniprot": "O33127",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33127"
  },
  {
    "uniprot": "O33099",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33099"
  },
  {
    "uniprot": "Q9CC83",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC83"
  },
  {
    "uniprot": "Q7AQJ9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQJ9"
  },
  {
    "uniprot": "Q9CBU3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBU3"
  },
  {
    "uniprot": "Q9CB90",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB90"
  },
  {
    "uniprot": "Q9CCY4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCY4"
  },
  {
    "uniprot": "Q9CCM1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCM1"
  },
  {
    "uniprot": "Q9CC00",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC00"
  },
  {
    "uniprot": "Q9CC95",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC95"
  },
  {
    "uniprot": "P54880",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54880"
  },
  {
    "uniprot": "P52678",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P52678"
  },
  {
    "uniprot": "Q9CDC3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDC3"
  },
  {
    "uniprot": "Q7AQE2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQE2"
  },
  {
    "uniprot": "O32882",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32882"
  },
  {
    "uniprot": "Q7APS0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APS0"
  },
  {
    "uniprot": "Q9CCZ1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCZ1"
  },
  {
    "uniprot": "P45486",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45486"
  },
  {
    "uniprot": "Q07297",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q07297"
  },
  {
    "uniprot": "P54134",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54134"
  },
  {
    "uniprot": "Q9CBA1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBA1"
  },
  {
    "uniprot": "Q49935",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49935"
  },
  {
    "uniprot": "Q50025",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50025"
  },
  {
    "uniprot": "O33114",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33114"
  },
  {
    "uniprot": "Q9ZBM2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9ZBM2"
  },
  {
    "uniprot": "Q9CCQ6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCQ6"
  },
  {
    "uniprot": "Q9Z5H5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9Z5H5"
  },
  {
    "uniprot": "Q9CC80",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC80"
  },
  {
    "uniprot": "Q9CBP8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBP8"
  },
  {
    "uniprot": "Q9CBW3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBW3"
  },
  {
    "uniprot": "P54744",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54744"
  },
  {
    "uniprot": "Q9CD97",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD97"
  },
  {
    "uniprot": "Q7AQ54",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ54"
  },
  {
    "uniprot": "Q7APZ6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APZ6"
  },
  {
    "uniprot": "Q9CC15",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC15"
  },
  {
    "uniprot": "Q9CCK2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCK2"
  },
  {
    "uniprot": "Q7APZ9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APZ9"
  },
  {
    "uniprot": "Q7AQ09",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ09"
  },
  {
    "uniprot": "Q9CBS1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBS1"
  },
  {
    "uniprot": "Q7AQ75",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ75"
  },
  {
    "uniprot": "Q7AQD6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQD6"
  },
  {
    "uniprot": "Q7AQN4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQN4"
  },
  {
    "uniprot": "Q9CBT6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBT6"
  },
  {
    "uniprot": "P54884",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54884"
  },
  {
    "uniprot": "Q9CB47",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB47"
  },
  {
    "uniprot": "P46862",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46862"
  },
  {
    "uniprot": "P53006",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53006"
  },
  {
    "uniprot": "O32983",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32983"
  },
  {
    "uniprot": "Q9CBR6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBR6"
  },
  {
    "uniprot": "Q9CBZ1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBZ1"
  },
  {
    "uniprot": "Q9CBU7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBU7"
  },
  {
    "uniprot": "O69556",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69556"
  },
  {
    "uniprot": "Q9CB82",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB82"
  },
  {
    "uniprot": "Q7AQ08",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ08"
  },
  {
    "uniprot": "Q9Z5I8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9Z5I8"
  },
  {
    "uniprot": "O32985",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32985"
  },
  {
    "uniprot": "P0C0X2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P0C0X2"
  },
  {
    "uniprot": "P46724",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46724"
  },
  {
    "uniprot": "Q9CBV2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBV2"
  },
  {
    "uniprot": "Q9CDB1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDB1"
  },
  {
    "uniprot": "Q9CB18",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB18"
  },
  {
    "uniprot": "Q9CCF2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCF2"
  },
  {
    "uniprot": "Q9CBN0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBN0"
  },
  {
    "uniprot": "Q49682",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49682"
  },
  {
    "uniprot": "Q7AQ91",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ91"
  },
  {
    "uniprot": "Q9CBB8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBB8"
  },
  {
    "uniprot": "O33104",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33104"
  },
  {
    "uniprot": "Q9CBC5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBC5"
  },
  {
    "uniprot": "Q9CBU1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBU1"
  },
  {
    "uniprot": "Q9CCL1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCL1"
  },
  {
    "uniprot": "Q9CCK8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCK8"
  },
  {
    "uniprot": "Q9CBC6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBC6"
  },
  {
    "uniprot": "Q9CBD9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBD9"
  },
  {
    "uniprot": "Q9CBI2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBI2"
  },
  {
    "uniprot": "Q9CD25",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD25"
  },
  {
    "uniprot": "Q49670",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49670"
  },
  {
    "uniprot": "Q9CCU7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCU7"
  },
  {
    "uniprot": "Q49842",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49842"
  },
  {
    "uniprot": "Q9CCK4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCK4"
  },
  {
    "uniprot": "Q7AQC5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQC5"
  },
  {
    "uniprot": "O07137",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O07137"
  },
  {
    "uniprot": "P46386",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46386"
  },
  {
    "uniprot": "Q7AQ18",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ18"
  },
  {
    "uniprot": "Q7AQC1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQC1"
  },
  {
    "uniprot": "Q50191",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50191"
  },
  {
    "uniprot": "Q7AQ52",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ52"
  },
  {
    "uniprot": "Q9CC13",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC13"
  },
  {
    "uniprot": "P46711",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46711"
  },
  {
    "uniprot": "Q50103",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50103"
  },
  {
    "uniprot": "Q9CCL0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCL0"
  },
  {
    "uniprot": "O33057",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33057"
  },
  {
    "uniprot": "P45840",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45840"
  },
  {
    "uniprot": "Q7AQ73",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ73"
  },
  {
    "uniprot": "P50474",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P50474"
  },
  {
    "uniprot": "Q9CCR6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCR6"
  },
  {
    "uniprot": "Q9CCI3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCI3"
  },
  {
    "uniprot": "Q7AQI1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQI1"
  },
  {
    "uniprot": "O32879",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32879"
  },
  {
    "uniprot": "P37969",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P37969"
  },
  {
    "uniprot": "Q9CBB7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBB7"
  },
  {
    "uniprot": "O32953",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32953"
  },
  {
    "uniprot": "Q9CCW7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCW7"
  },
  {
    "uniprot": "Q9CCN2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCN2"
  },
  {
    "uniprot": "Q9CBS5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBS5"
  },
  {
    "uniprot": "Q7APZ2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APZ2"
  },
  {
    "uniprot": "Q49729",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49729"
  },
  {
    "uniprot": "Q9CD92",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD92"
  },
  {
    "uniprot": "P0A5D0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P0A5D0"
  },
  {
    "uniprot": "Q7AQ04",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ04"
  },
  {
    "uniprot": "Q9CBH7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBH7"
  },
  {
    "uniprot": "Q9CD52",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD52"
  },
  {
    "uniprot": "Q9CB78",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB78"
  },
  {
    "uniprot": "Q9CBS3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBS3"
  },
  {
    "uniprot": "Q9CC23",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC23"
  },
  {
    "uniprot": "Q7AQB2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQB2"
  },
  {
    "uniprot": "O32987",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32987"
  },
  {
    "uniprot": "Q9CC99",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC99"
  },
  {
    "uniprot": "P46814",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46814"
  },
  {
    "uniprot": "Q9CCI4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCI4"
  },
  {
    "uniprot": "Q7AQN3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQN3"
  },
  {
    "uniprot": "Q7AQ78",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ78"
  },
  {
    "uniprot": "O32965",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32965"
  },
  {
    "uniprot": "Q9CBP5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBP5"
  },
  {
    "uniprot": "Q7AQ11",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ11"
  },
  {
    "uniprot": "Q7AQ71",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ71"
  },
  {
    "uniprot": "P50917",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P50917"
  },
  {
    "uniprot": "Q49877",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49877"
  },
  {
    "uniprot": "Q49767",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49767"
  },
  {
    "uniprot": "P54581",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54581"
  },
  {
    "uniprot": "Q9CBM1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBM1"
  },
  {
    "uniprot": "Q7APV3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APV3"
  },
  {
    "uniprot": "O33010",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33010"
  },
  {
    "uniprot": "P53382",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53382"
  },
  {
    "uniprot": "Q9CD15",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD15"
  },
  {
    "uniprot": "Q9CCB9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCB9"
  },
  {
    "uniprot": "Q50192",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50192"
  },
  {
    "uniprot": "Q7AQ62",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ62"
  },
  {
    "uniprot": "O32886",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32886"
  },
  {
    "uniprot": "Q9CBG4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBG4"
  },
  {
    "uniprot": "Q9CBB9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBB9"
  },
  {
    "uniprot": "Q50047",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50047"
  },
  {
    "uniprot": "O06069",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O06069"
  },
  {
    "uniprot": "Q9CDE4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDE4"
  },
  {
    "uniprot": "Q7APW0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APW0"
  },
  {
    "uniprot": "Q9CB46",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB46"
  },
  {
    "uniprot": "Q9CBD1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBD1"
  },
  {
    "uniprot": "P30769",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P30769"
  },
  {
    "uniprot": "Q9CC20",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC20"
  },
  {
    "uniprot": "Q9CCP9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCP9"
  },
  {
    "uniprot": "Q7APT8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APT8"
  },
  {
    "uniprot": "O33013",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33013"
  },
  {
    "uniprot": "Q9CCK0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCK0"
  },
  {
    "uniprot": "O33107",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33107"
  },
  {
    "uniprot": "Q9CCA8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCA8"
  },
  {
    "uniprot": "Q9CC26",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC26"
  },
  {
    "uniprot": "Q9CCT6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCT6"
  },
  {
    "uniprot": "Q9CBZ6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBZ6"
  },
  {
    "uniprot": "Q9CC49",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC49"
  },
  {
    "uniprot": "Q9CBH5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBH5"
  },
  {
    "uniprot": "Q9CBA4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBA4"
  },
  {
    "uniprot": "Q9CBH4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBH4"
  },
  {
    "uniprot": "Q9X7E5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7E5"
  },
  {
    "uniprot": "Q7AQH7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQH7"
  },
  {
    "uniprot": "Q9CD95",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD95"
  },
  {
    "uniprot": "O69470",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69470"
  },
  {
    "uniprot": "P40834",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P40834"
  },
  {
    "uniprot": "Q9CCZ4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCZ4"
  },
  {
    "uniprot": "Q9CCR7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCR7"
  },
  {
    "uniprot": "Q7AQ32",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ32"
  },
  {
    "uniprot": "Q9CCY0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCY0"
  },
  {
    "uniprot": "Q9CCC0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCC0"
  },
  {
    "uniprot": "Q9Z5G4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9Z5G4"
  },
  {
    "uniprot": "Q9CCX7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCX7"
  },
  {
    "uniprot": "Q9CB22",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB22"
  },
  {
    "uniprot": "P13367",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P13367"
  },
  {
    "uniprot": "P54079",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54079"
  },
  {
    "uniprot": "Q9CCQ8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCQ8"
  },
  {
    "uniprot": "Q9CBW5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBW5"
  },
  {
    "uniprot": "Q9ZBL5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9ZBL5"
  },
  {
    "uniprot": "Q7AQM1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQM1"
  },
  {
    "uniprot": "P53523",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53523"
  },
  {
    "uniprot": "Q9CCW4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCW4"
  },
  {
    "uniprot": "P46725",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46725"
  },
  {
    "uniprot": "Q7AQ63",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ63"
  },
  {
    "uniprot": "Q9CBH8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBH8"
  },
  {
    "uniprot": "Q9CC60",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC60"
  },
  {
    "uniprot": "P53530",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53530"
  },
  {
    "uniprot": "P37391",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P37391"
  },
  {
    "uniprot": "Q7APU8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APU8"
  },
  {
    "uniprot": "Q9CBY4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBY4"
  },
  {
    "uniprot": "Q9CBN3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBN3"
  },
  {
    "uniprot": "O69555",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69555"
  },
  {
    "uniprot": "Q9CC03",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC03"
  },
  {
    "uniprot": "Q7AQK3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQK3"
  },
  {
    "uniprot": "Q9CB70",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB70"
  },
  {
    "uniprot": "Q9CBA6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBA6"
  },
  {
    "uniprot": "Q9CC33",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC33"
  },
  {
    "uniprot": "Q9CCJ9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCJ9"
  },
  {
    "uniprot": "P45821",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45821"
  },
  {
    "uniprot": "Q9CB92",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB92"
  },
  {
    "uniprot": "Q9CC81",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC81"
  },
  {
    "uniprot": "Q9CBD6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBD6"
  },
  {
    "uniprot": "Q7AQH8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQH8"
  },
  {
    "uniprot": "P57996",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P57996"
  },
  {
    "uniprot": "Q9CDD1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDD1"
  },
  {
    "uniprot": "Q9CC30",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC30"
  },
  {
    "uniprot": "P68998",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P68998"
  },
  {
    "uniprot": "Q49622",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49622"
  },
  {
    "uniprot": "O32922",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32922"
  },
  {
    "uniprot": "P43315",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P43315"
  },
  {
    "uniprot": "Q7AQG1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQG1"
  },
  {
    "uniprot": "Q7AQA4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQA4"
  },
  {
    "uniprot": "Q9CD98",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD98"
  },
  {
    "uniprot": "Q49933",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49933"
  },
  {
    "uniprot": "O33022",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33022"
  },
  {
    "uniprot": "Q9CCK5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCK5"
  },
  {
    "uniprot": "Q9CBG2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBG2"
  },
  {
    "uniprot": "O69529",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69529"
  },
  {
    "uniprot": "Q7AQ20",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ20"
  },
  {
    "uniprot": "Q9CCV7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCV7"
  },
  {
    "uniprot": "Q9CBJ9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBJ9"
  },
  {
    "uniprot": "Q9CBA9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBA9"
  },
  {
    "uniprot": "Q9CD65",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD65"
  },
  {
    "uniprot": "Q9CBC7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBC7"
  },
  {
    "uniprot": "Q9CB21",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB21"
  },
  {
    "uniprot": "Q7AQ21",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ21"
  },
  {
    "uniprot": "Q9CC10",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC10"
  },
  {
    "uniprot": "Q9CD53",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD53"
  },
  {
    "uniprot": "Q7AQG2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQG2"
  },
  {
    "uniprot": "Q50203",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50203"
  },
  {
    "uniprot": "Q9CB89",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB89"
  },
  {
    "uniprot": "O32982",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32982"
  },
  {
    "uniprot": "Q9CBU2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBU2"
  },
  {
    "uniprot": "Q7APV7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APV7"
  },
  {
    "uniprot": "Q9CB30",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB30"
  },
  {
    "uniprot": "Q7AQ24",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ24"
  },
  {
    "uniprot": "Q7AQL3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQL3"
  },
  {
    "uniprot": "Q9CBL1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBL1"
  },
  {
    "uniprot": "Q9CC18",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC18"
  },
  {
    "uniprot": "Q9X7C2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7C2"
  },
  {
    "uniprot": "Q7APZ8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APZ8"
  },
  {
    "uniprot": "Q9CC43",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC43"
  },
  {
    "uniprot": "Q49864",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49864"
  },
  {
    "uniprot": "Q7AQI0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQI0"
  },
  {
    "uniprot": "P38387",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P38387"
  },
  {
    "uniprot": "Q49946",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49946"
  },
  {
    "uniprot": "Q9CCM3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCM3"
  },
  {
    "uniprot": "Q9CCA5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCA5"
  },
  {
    "uniprot": "O32874",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32874"
  },
  {
    "uniprot": "Q9CBZ0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBZ0"
  },
  {
    "uniprot": "Q9CBU6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBU6"
  },
  {
    "uniprot": "P30764",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P30764"
  },
  {
    "uniprot": "Q9CCY2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCY2"
  },
  {
    "uniprot": "P54580",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54580"
  },
  {
    "uniprot": "Q7AQI8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQI8"
  },
  {
    "uniprot": "Q7APR6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APR6"
  },
  {
    "uniprot": "O53125",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O53125"
  },
  {
    "uniprot": "Q7APY2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APY2"
  },
  {
    "uniprot": "Q9CB40",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB40"
  },
  {
    "uniprot": "Q9CB79",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB79"
  },
  {
    "uniprot": "Q7AQ74",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ74"
  },
  {
    "uniprot": "P45837",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45837"
  },
  {
    "uniprot": "P38119",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P38119"
  },
  {
    "uniprot": "Q9CD41",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD41"
  },
  {
    "uniprot": "Q9CCR1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCR1"
  },
  {
    "uniprot": "Q7AQM6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQM6"
  },
  {
    "uniprot": "Q7AQK4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQK4"
  },
  {
    "uniprot": "Q9CCP7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCP7"
  },
  {
    "uniprot": "Q9CCW0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCW0"
  },
  {
    "uniprot": "Q9CCQ5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCQ5"
  },
  {
    "uniprot": "Q9Z5I9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9Z5I9"
  },
  {
    "uniprot": "Q7AQ35",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ35"
  },
  {
    "uniprot": "Q9X7C3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7C3"
  },
  {
    "uniprot": "Q49690",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49690"
  },
  {
    "uniprot": "P46810",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46810"
  },
  {
    "uniprot": "Q9CCD9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCD9"
  },
  {
    "uniprot": "Q9X7B9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7B9"
  },
  {
    "uniprot": "Q7APX1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APX1"
  },
  {
    "uniprot": "Q7AQI5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQI5"
  },
  {
    "uniprot": "Q9CC97",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC97"
  },
  {
    "uniprot": "Q9CB91",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB91"
  },
  {
    "uniprot": "Q7AQK8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQK8"
  },
  {
    "uniprot": "Q7AQE8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQE8"
  },
  {
    "uniprot": "Q9CDB3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDB3"
  },
  {
    "uniprot": "O32998",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32998"
  },
  {
    "uniprot": "Q7APW1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APW1"
  },
  {
    "uniprot": "Q49742",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49742"
  },
  {
    "uniprot": "Q9CC31",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC31"
  },
  {
    "uniprot": "Q49942",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49942"
  },
  {
    "uniprot": "P36429",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P36429"
  },
  {
    "uniprot": "Q9CCJ8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCJ8"
  },
  {
    "uniprot": "Q50178",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50178"
  },
  {
    "uniprot": "Q9CCW2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCW2"
  },
  {
    "uniprot": "Q9CDE9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDE9"
  },
  {
    "uniprot": "Q9CD45",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD45"
  },
  {
    "uniprot": "Q9CBQ1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBQ1"
  },
  {
    "uniprot": "Q9CD78",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD78"
  },
  {
    "uniprot": "Q9CCN1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCN1"
  },
  {
    "uniprot": "Q9CCY9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCY9"
  },
  {
    "uniprot": "P45488",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45488"
  },
  {
    "uniprot": "Q9CB24",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB24"
  },
  {
    "uniprot": "Q7AQJ5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQJ5"
  },
  {
    "uniprot": "Q7APY5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APY5"
  },
  {
    "uniprot": "Q9CCF1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCF1"
  },
  {
    "uniprot": "Q9CBW6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBW6"
  },
  {
    "uniprot": "Q9CDE3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDE3"
  },
  {
    "uniprot": "P46391",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46391"
  },
  {
    "uniprot": "Q9CD60",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD60"
  },
  {
    "uniprot": "Q9CCS2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCS2"
  },
  {
    "uniprot": "Q9CBX8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBX8"
  },
  {
    "uniprot": "Q9Z5G7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9Z5G7"
  },
  {
    "uniprot": "Q9CBX0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBX0"
  },
  {
    "uniprot": "Q7AQN2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQN2"
  },
  {
    "uniprot": "O05560",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O05560"
  },
  {
    "uniprot": "Q49626",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49626"
  },
  {
    "uniprot": "Q7AQF9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQF9"
  },
  {
    "uniprot": "Q9CDC6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDC6"
  },
  {
    "uniprot": "Q9CBR5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBR5"
  },
  {
    "uniprot": "O05678",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O05678"
  },
  {
    "uniprot": "Q49869",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49869"
  },
  {
    "uniprot": "Q9X783",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X783"
  },
  {
    "uniprot": "Q49897",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49897"
  },
  {
    "uniprot": "Q9CDB7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDB7"
  },
  {
    "uniprot": "Q7AQP4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQP4"
  },
  {
    "uniprot": "Q50167",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50167"
  },
  {
    "uniprot": "Q9CB94",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB94"
  },
  {
    "uniprot": "Q9CCZ3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCZ3"
  },
  {
    "uniprot": "Q7APX8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APX8"
  },
  {
    "uniprot": "Q7AQA0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQA0"
  },
  {
    "uniprot": "Q9CCD4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCD4"
  },
  {
    "uniprot": "Q7AQ56",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ56"
  },
  {
    "uniprot": "Q9X795",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X795"
  },
  {
    "uniprot": "Q9CB71",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB71"
  },
  {
    "uniprot": "Q9CCG3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCG3"
  },
  {
    "uniprot": "Q7AQE9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQE9"
  },
  {
    "uniprot": "P53425",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53425"
  },
  {
    "uniprot": "Q9CBZ4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBZ4"
  },
  {
    "uniprot": "Q9CDA4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDA4"
  },
  {
    "uniprot": "Q9CCY8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCY8"
  },
  {
    "uniprot": "Q9CB49",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB49"
  },
  {
    "uniprot": "Q7AQE1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQE1"
  },
  {
    "uniprot": "Q9CBZ9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBZ9"
  },
  {
    "uniprot": "Q9CC40",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC40"
  },
  {
    "uniprot": "Q9CBK5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBK5"
  },
  {
    "uniprot": "Q9CCC3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCC3"
  },
  {
    "uniprot": "Q7AQ86",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ86"
  },
  {
    "uniprot": "Q9CB38",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB38"
  },
  {
    "uniprot": "Q9CC98",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC98"
  },
  {
    "uniprot": "Q7AQC6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQC6"
  },
  {
    "uniprot": "Q9CB81",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB81"
  },
  {
    "uniprot": "Q9CBN9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBN9"
  },
  {
    "uniprot": "P40833",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P40833"
  },
  {
    "uniprot": "Q49808",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49808"
  },
  {
    "uniprot": "Q7AQI2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQI2"
  },
  {
    "uniprot": "Q7AQ02",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ02"
  },
  {
    "uniprot": "P53002",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53002"
  },
  {
    "uniprot": "Q7AQE5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQE5"
  },
  {
    "uniprot": "Q9CC14",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC14"
  },
  {
    "uniprot": "Q9CDD2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDD2"
  },
  {
    "uniprot": "Q9CDC8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDC8"
  },
  {
    "uniprot": "Q7AQA8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQA8"
  },
  {
    "uniprot": "Q9CBB1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBB1"
  },
  {
    "uniprot": "Q7AQP3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQP3"
  },
  {
    "uniprot": "P46811",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46811"
  },
  {
    "uniprot": "Q9CB26",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB26"
  },
  {
    "uniprot": "Q9CCR5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCR5"
  },
  {
    "uniprot": "Q9CCX2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCX2"
  },
  {
    "uniprot": "Q50008",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50008"
  },
  {
    "uniprot": "Q9X794",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X794"
  },
  {
    "uniprot": "Q9CC79",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC79"
  },
  {
    "uniprot": "Q9CCE0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCE0"
  },
  {
    "uniprot": "Q7APV9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APV9"
  },
  {
    "uniprot": "Q9CBW4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBW4"
  },
  {
    "uniprot": "Q9CBM3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBM3"
  },
  {
    "uniprot": "Q9CCH4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCH4"
  },
  {
    "uniprot": "Q9CBK9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBK9"
  },
  {
    "uniprot": "O69560",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69560"
  },
  {
    "uniprot": "P45823",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45823"
  },
  {
    "uniprot": "Q9CCH0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCH0"
  },
  {
    "uniprot": "Q9CBN4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBN4"
  },
  {
    "uniprot": "Q7AQF6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQF6"
  },
  {
    "uniprot": "Q9CD88",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD88"
  },
  {
    "uniprot": "Q9CBN7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBN7"
  },
  {
    "uniprot": "Q7AQP0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQP0"
  },
  {
    "uniprot": "Q9CCI0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCI0"
  },
  {
    "uniprot": "Q9CD81",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD81"
  },
  {
    "uniprot": "P57994",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P57994"
  },
  {
    "uniprot": "Q7AQM4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQM4"
  },
  {
    "uniprot": "Q9CBM4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBM4"
  },
  {
    "uniprot": "Q7APR7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APR7"
  },
  {
    "uniprot": "Q7AQ47",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ47"
  },
  {
    "uniprot": "Q9X7A2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7A2"
  },
  {
    "uniprot": "P46713",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46713"
  },
  {
    "uniprot": "O33024",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33024"
  },
  {
    "uniprot": "Q9CCE9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCE9"
  },
  {
    "uniprot": "Q7AQE3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQE3"
  },
  {
    "uniprot": "Q7APS2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APS2"
  },
  {
    "uniprot": "Q9CCP3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCP3"
  },
  {
    "uniprot": "O33017",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33017"
  },
  {
    "uniprot": "Q9CC50",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC50"
  },
  {
    "uniprot": "Q9CD22",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD22"
  },
  {
    "uniprot": "Q9CBQ2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBQ2"
  },
  {
    "uniprot": "P37578",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P37578"
  },
  {
    "uniprot": "Q9CBC8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBC8"
  },
  {
    "uniprot": "Q9CC96",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC96"
  },
  {
    "uniprot": "Q9CD73",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD73"
  },
  {
    "uniprot": "Q9CCT9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCT9"
  },
  {
    "uniprot": "Q9CCV4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCV4"
  },
  {
    "uniprot": "Q9CCU4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCU4"
  },
  {
    "uniprot": "Q9CD21",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD21"
  },
  {
    "uniprot": "Q9CC27",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC27"
  },
  {
    "uniprot": "Q9CBL5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBL5"
  },
  {
    "uniprot": "Q9CD72",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD72"
  },
  {
    "uniprot": "P38056",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P38056"
  },
  {
    "uniprot": "Q9CD11",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD11"
  },
  {
    "uniprot": "Q49642",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49642"
  },
  {
    "uniprot": "Q9CB93",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB93"
  },
  {
    "uniprot": "Q9CBF8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBF8"
  },
  {
    "uniprot": "O33096",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33096"
  },
  {
    "uniprot": "Q7AQ58",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ58"
  },
  {
    "uniprot": "Q9CCM4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCM4"
  },
  {
    "uniprot": "Q9CD85",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD85"
  },
  {
    "uniprot": "Q49619",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49619"
  },
  {
    "uniprot": "Q9CBX9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBX9"
  },
  {
    "uniprot": "P46708",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46708"
  },
  {
    "uniprot": "Q9CCN7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCN7"
  },
  {
    "uniprot": "Q9CBB3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBB3"
  },
  {
    "uniprot": "P53426",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53426"
  },
  {
    "uniprot": "Q9CCS9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCS9"
  },
  {
    "uniprot": "Q9CB74",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB74"
  },
  {
    "uniprot": "Q9CCV9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCV9"
  },
  {
    "uniprot": "Q9CBI6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBI6"
  },
  {
    "uniprot": "Q9CC68",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC68"
  },
  {
    "uniprot": "Q7AQH1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQH1"
  },
  {
    "uniprot": "Q9CD40",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD40"
  },
  {
    "uniprot": "Q9CBQ7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBQ7"
  },
  {
    "uniprot": "Q7AQA6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQA6"
  },
  {
    "uniprot": "Q7AQP1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQP1"
  },
  {
    "uniprot": "O69462",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69462"
  },
  {
    "uniprot": "Q7AQB6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQB6"
  },
  {
    "uniprot": "Q9CBI3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBI3"
  },
  {
    "uniprot": "Q9CCR2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCR2"
  },
  {
    "uniprot": "P46697",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46697"
  },
  {
    "uniprot": "Q49768",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49768"
  },
  {
    "uniprot": "Q9CD23",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD23"
  },
  {
    "uniprot": "Q7AQ76",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ76"
  },
  {
    "uniprot": "Q9CCA9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCA9"
  },
  {
    "uniprot": "Q49757",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49757"
  },
  {
    "uniprot": "Q7AQP5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQP5"
  },
  {
    "uniprot": "Q7APX0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APX0"
  },
  {
    "uniprot": "Q7APY1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APY1"
  },
  {
    "uniprot": "O69466",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69466"
  },
  {
    "uniprot": "Q49884",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49884"
  },
  {
    "uniprot": "Q9CBI5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBI5"
  },
  {
    "uniprot": "Q9CCQ1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCQ1"
  },
  {
    "uniprot": "Q49723",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49723"
  },
  {
    "uniprot": "P46509",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46509"
  },
  {
    "uniprot": "Q9CCY3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCY3"
  },
  {
    "uniprot": "Q9CCQ3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCQ3"
  },
  {
    "uniprot": "Q9CBH6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBH6"
  },
  {
    "uniprot": "Q7AQM8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQM8"
  },
  {
    "uniprot": "Q49938",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49938"
  },
  {
    "uniprot": "Q9CDC1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDC1"
  },
  {
    "uniprot": "Q9CB84",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB84"
  },
  {
    "uniprot": "Q49771",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49771"
  },
  {
    "uniprot": "Q9CD08",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD08"
  },
  {
    "uniprot": "P46843",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46843"
  },
  {
    "uniprot": "Q7AQN8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQN8"
  },
  {
    "uniprot": "Q9CCM8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCM8"
  },
  {
    "uniprot": "Q9CCQ4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCQ4"
  },
  {
    "uniprot": "Q9CCY7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCY7"
  },
  {
    "uniprot": "Q9CCE7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCE7"
  },
  {
    "uniprot": "O69601",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69601"
  },
  {
    "uniprot": "Q50123",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50123"
  },
  {
    "uniprot": "Q7APS7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APS7"
  },
  {
    "uniprot": "Q9CD67",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD67"
  },
  {
    "uniprot": "Q9CBU8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBU8"
  },
  {
    "uniprot": "Q9CDC0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDC0"
  },
  {
    "uniprot": "Q9CD07",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD07"
  },
  {
    "uniprot": "Q7AQ77",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ77"
  },
  {
    "uniprot": "O33106",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33106"
  },
  {
    "uniprot": "Q49762",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49762"
  },
  {
    "uniprot": "Q9CBM6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBM6"
  },
  {
    "uniprot": "Q9CCC4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCC4"
  },
  {
    "uniprot": "O33007",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33007"
  },
  {
    "uniprot": "Q7APX5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APX5"
  },
  {
    "uniprot": "Q7APS4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APS4"
  },
  {
    "uniprot": "O69540",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69540"
  },
  {
    "uniprot": "Q9CBP1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBP1"
  },
  {
    "uniprot": "Q49689",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49689"
  },
  {
    "uniprot": "P53435",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53435"
  },
  {
    "uniprot": "Q9CC76",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC76"
  },
  {
    "uniprot": "Q50201",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50201"
  },
  {
    "uniprot": "P54076",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54076"
  },
  {
    "uniprot": "Q9CCE6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCE6"
  },
  {
    "uniprot": "Q9CCD5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCD5"
  },
  {
    "uniprot": "Q9CBJ3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBJ3"
  },
  {
    "uniprot": "Q9CCJ6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCJ6"
  },
  {
    "uniprot": "O33016",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33016"
  },
  {
    "uniprot": "O32960",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32960"
  },
  {
    "uniprot": "Q7AQ33",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ33"
  },
  {
    "uniprot": "Q9CCI8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCI8"
  },
  {
    "uniprot": "Q9X781",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X781"
  },
  {
    "uniprot": "Q9CCI9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCI9"
  },
  {
    "uniprot": "Q9CBF6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBF6"
  },
  {
    "uniprot": "Q7APS9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APS9"
  },
  {
    "uniprot": "Q9Z5J2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9Z5J2"
  },
  {
    "uniprot": "Q7APT1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APT1"
  },
  {
    "uniprot": "Q7AQ03",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ03"
  },
  {
    "uniprot": "O32885",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32885"
  },
  {
    "uniprot": "Q7APS1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APS1"
  },
  {
    "uniprot": "Q7AQG0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQG0"
  },
  {
    "uniprot": "Q9ZBL0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9ZBL0"
  },
  {
    "uniprot": "Q9CBQ5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBQ5"
  },
  {
    "uniprot": "P53424",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53424"
  },
  {
    "uniprot": "Q9CCR3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCR3"
  },
  {
    "uniprot": "Q9CCX1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCX1"
  },
  {
    "uniprot": "Q9CC45",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC45"
  },
  {
    "uniprot": "Q49630",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49630"
  },
  {
    "uniprot": "Q9CDB9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDB9"
  },
  {
    "uniprot": "Q9CD28",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD28"
  },
  {
    "uniprot": "P24428",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P24428"
  },
  {
    "uniprot": "P30761",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P30761"
  },
  {
    "uniprot": "Q49617",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49617"
  },
  {
    "uniprot": "Q50130",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50130"
  },
  {
    "uniprot": "Q9CB75",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB75"
  },
  {
    "uniprot": "Q9CBS9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBS9"
  },
  {
    "uniprot": "Q9CBT7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBT7"
  },
  {
    "uniprot": "Q49741",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49741"
  },
  {
    "uniprot": "O33117",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33117"
  },
  {
    "uniprot": "Q9CBQ9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBQ9"
  },
  {
    "uniprot": "Q49945",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49945"
  },
  {
    "uniprot": "P54075",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54075"
  },
  {
    "uniprot": "O32996",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32996"
  },
  {
    "uniprot": "Q9CCY6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCY6"
  },
  {
    "uniprot": "Q7AQL4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQL4"
  },
  {
    "uniprot": "Q9CBE9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBE9"
  },
  {
    "uniprot": "P45832",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45832"
  },
  {
    "uniprot": "O69531",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69531"
  },
  {
    "uniprot": "O32997",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32997"
  },
  {
    "uniprot": "Q9CCA7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCA7"
  },
  {
    "uniprot": "P46696",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46696"
  },
  {
    "uniprot": "Q7AQD2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQD2"
  },
  {
    "uniprot": "Q9CBN8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBN8"
  },
  {
    "uniprot": "Q49770",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49770"
  },
  {
    "uniprot": "Q7AQE7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQE7"
  },
  {
    "uniprot": "Q7AQN9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQN9"
  },
  {
    "uniprot": "Q05861",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q05861"
  },
  {
    "uniprot": "P54881",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54881"
  },
  {
    "uniprot": "P46806",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46806"
  },
  {
    "uniprot": "O69528",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69528"
  },
  {
    "uniprot": "Q7AQH3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQH3"
  },
  {
    "uniprot": "Q50206",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50206"
  },
  {
    "uniprot": "Q49657",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49657"
  },
  {
    "uniprot": "Q9CBE6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBE6"
  },
  {
    "uniprot": "Q9CBI1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBI1"
  },
  {
    "uniprot": "Q7AQ95",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ95"
  },
  {
    "uniprot": "Q9X7F0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7F0"
  },
  {
    "uniprot": "Q7AQM7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQM7"
  },
  {
    "uniprot": "O33124",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33124"
  },
  {
    "uniprot": "Q9CCG8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCG8"
  },
  {
    "uniprot": "Q7AQL9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQL9"
  },
  {
    "uniprot": "Q9CBI7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBI7"
  },
  {
    "uniprot": "Q9CBV9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBV9"
  },
  {
    "uniprot": "Q9CBF3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBF3"
  },
  {
    "uniprot": "Q9CC54",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC54"
  },
  {
    "uniprot": "Q9CD37",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD37"
  },
  {
    "uniprot": "Q7AQB0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQB0"
  },
  {
    "uniprot": "Q9CB45",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB45"
  },
  {
    "uniprot": "Q7AQ51",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ51"
  },
  {
    "uniprot": "O33000",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33000"
  },
  {
    "uniprot": "Q49900",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49900"
  },
  {
    "uniprot": "Q9CB36",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB36"
  },
  {
    "uniprot": "Q9CBH1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBH1"
  },
  {
    "uniprot": "Q9CBS7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBS7"
  },
  {
    "uniprot": "P46387",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46387"
  },
  {
    "uniprot": "Q7AQD9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQD9"
  },
  {
    "uniprot": "Q9CD49",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD49"
  },
  {
    "uniprot": "Q9CCJ0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCJ0"
  },
  {
    "uniprot": "Q9CCZ8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCZ8"
  },
  {
    "uniprot": "P57992",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P57992"
  },
  {
    "uniprot": "O33014",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33014"
  },
  {
    "uniprot": "Q49807",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49807"
  },
  {
    "uniprot": "Q9CCZ2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCZ2"
  },
  {
    "uniprot": "Q9CBS0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBS0"
  },
  {
    "uniprot": "Q9CBB2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBB2"
  },
  {
    "uniprot": "Q49624",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49624"
  },
  {
    "uniprot": "P45830",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45830"
  },
  {
    "uniprot": "Q7AQJ4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQJ4"
  },
  {
    "uniprot": "Q49775",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49775"
  },
  {
    "uniprot": "Q9CDA9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDA9"
  },
  {
    "uniprot": "Q9CDC2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDC2"
  },
  {
    "uniprot": "Q9CC55",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC55"
  },
  {
    "uniprot": "Q9CD90",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD90"
  },
  {
    "uniprot": "Q9CBR4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBR4"
  },
  {
    "uniprot": "Q9CBC2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBC2"
  },
  {
    "uniprot": "Q9CB66",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB66"
  },
  {
    "uniprot": "Q9CD57",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD57"
  },
  {
    "uniprot": "Q9CCB0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCB0"
  },
  {
    "uniprot": "P15878",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P15878"
  },
  {
    "uniprot": "Q50036",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50036"
  },
  {
    "uniprot": "Q50000",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50000"
  },
  {
    "uniprot": "Q9CCL4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCL4"
  },
  {
    "uniprot": "Q7AQJ8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQJ8"
  },
  {
    "uniprot": "Q9CB99",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB99"
  },
  {
    "uniprot": "P45835",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45835"
  },
  {
    "uniprot": "Q9CBF5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBF5"
  },
  {
    "uniprot": "O33001",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33001"
  },
  {
    "uniprot": "Q7AQ28",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ28"
  },
  {
    "uniprot": "Q9CCJ7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCJ7"
  },
  {
    "uniprot": "Q9CCF8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCF8"
  },
  {
    "uniprot": "Q7APY0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APY0"
  },
  {
    "uniprot": "Q9CCS6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCS6"
  },
  {
    "uniprot": "P53525",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53525"
  },
  {
    "uniprot": "Q9CBG5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBG5"
  },
  {
    "uniprot": "O33112",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33112"
  },
  {
    "uniprot": "Q7APR9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APR9"
  },
  {
    "uniprot": "Q49786",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49786"
  },
  {
    "uniprot": "Q9CCM0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCM0"
  },
  {
    "uniprot": "Q7AQH5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQH5"
  },
  {
    "uniprot": "Q7AQD4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQD4"
  },
  {
    "uniprot": "P54878",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54878"
  },
  {
    "uniprot": "Q9CD94",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD94"
  },
  {
    "uniprot": "Q7AQI9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQI9"
  },
  {
    "uniprot": "Q9CBE0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBE0"
  },
  {
    "uniprot": "P41484",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P41484"
  },
  {
    "uniprot": "Q9CC89",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC89"
  },
  {
    "uniprot": "Q50068",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50068"
  },
  {
    "uniprot": "Q9CBR3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBR3"
  },
  {
    "uniprot": "Q7AQB8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQB8"
  },
  {
    "uniprot": "Q9CB29",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB29"
  },
  {
    "uniprot": "Q9CC53",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC53"
  },
  {
    "uniprot": "O69469",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69469"
  },
  {
    "uniprot": "Q7AQD5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQD5"
  },
  {
    "uniprot": "Q7APU1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APU1"
  },
  {
    "uniprot": "O69537",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69537"
  },
  {
    "uniprot": "Q9CD14",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD14"
  },
  {
    "uniprot": "Q9CBK4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBK4"
  },
  {
    "uniprot": "Q9CCN9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCN9"
  },
  {
    "uniprot": "Q9CCC6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCC6"
  },
  {
    "uniprot": "Q7AQJ6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQJ6"
  },
  {
    "uniprot": "Q9CDF0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDF0"
  },
  {
    "uniprot": "Q50028",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50028"
  },
  {
    "uniprot": "Q9CBY7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBY7"
  },
  {
    "uniprot": "Q9CBP3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBP3"
  },
  {
    "uniprot": "Q9CBL7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBL7"
  },
  {
    "uniprot": "P54200",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54200"
  },
  {
    "uniprot": "Q50023",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50023"
  },
  {
    "uniprot": "Q9CCT8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCT8"
  },
  {
    "uniprot": "Q9CB72",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB72"
  },
  {
    "uniprot": "P53529",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53529"
  },
  {
    "uniprot": "Q49992",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49992"
  },
  {
    "uniprot": "Q9CDE5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDE5"
  },
  {
    "uniprot": "P46715",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46715"
  },
  {
    "uniprot": "Q9CCR8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCR8"
  },
  {
    "uniprot": "Q7AQD7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQD7"
  },
  {
    "uniprot": "O33065",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33065"
  },
  {
    "uniprot": "Q57532",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q57532"
  },
  {
    "uniprot": "Q49646",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49646"
  },
  {
    "uniprot": "Q9CD09",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD09"
  },
  {
    "uniprot": "P46842",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46842"
  },
  {
    "uniprot": "O32956",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32956"
  },
  {
    "uniprot": "O69557",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69557"
  },
  {
    "uniprot": "Q9CBY2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBY2"
  },
  {
    "uniprot": "Q9CCK7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCK7"
  },
  {
    "uniprot": "Q9Z5I4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9Z5I4"
  },
  {
    "uniprot": "O32962",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32962"
  },
  {
    "uniprot": "Q7AQ55",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ55"
  },
  {
    "uniprot": "Q9CBT0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBT0"
  },
  {
    "uniprot": "Q7AQ43",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ43"
  },
  {
    "uniprot": "P49774",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P49774"
  },
  {
    "uniprot": "Q49734",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49734"
  },
  {
    "uniprot": "Q9CB98",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB98"
  },
  {
    "uniprot": "Q7APU6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APU6"
  },
  {
    "uniprot": "Q7AQG3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQG3"
  },
  {
    "uniprot": "Q7AQI7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQI7"
  },
  {
    "uniprot": "Q50097",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50097"
  },
  {
    "uniprot": "Q9CCB7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCB7"
  },
  {
    "uniprot": "O33102",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33102"
  },
  {
    "uniprot": "Q7AQ00",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ00"
  },
  {
    "uniprot": "Q9CCK3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCK3"
  },
  {
    "uniprot": "Q9CC48",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC48"
  },
  {
    "uniprot": "Q9CC93",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC93"
  },
  {
    "uniprot": "Q7AQK5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQK5"
  },
  {
    "uniprot": "Q9CD42",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD42"
  },
  {
    "uniprot": "Q05862",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q05862"
  },
  {
    "uniprot": "Q9CBF9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBF9"
  },
  {
    "uniprot": "Q9CD32",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD32"
  },
  {
    "uniprot": "P24094",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P24094"
  },
  {
    "uniprot": "Q9CCG2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCG2"
  },
  {
    "uniprot": "P45824",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45824"
  },
  {
    "uniprot": "Q7AQ53",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ53"
  },
  {
    "uniprot": "Q7AQ93",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ93"
  },
  {
    "uniprot": "Q9CBJ0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBJ0"
  },
  {
    "uniprot": "Q9CD75",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD75"
  },
  {
    "uniprot": "Q7AQD1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQD1"
  },
  {
    "uniprot": "Q9CB87",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB87"
  },
  {
    "uniprot": "P46807",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46807"
  },
  {
    "uniprot": "P40832",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P40832"
  },
  {
    "uniprot": "Q49700",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49700"
  },
  {
    "uniprot": "Q9CCW9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCW9"
  },
  {
    "uniprot": "Q9CC41",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC41"
  },
  {
    "uniprot": "Q9CCH9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCH9"
  },
  {
    "uniprot": "Q7AQN5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQN5"
  },
  {
    "uniprot": "Q9CBT2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBT2"
  },
  {
    "uniprot": "Q7AQ89",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ89"
  },
  {
    "uniprot": "Q7AQ13",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ13"
  },
  {
    "uniprot": "Q9CCV8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCV8"
  },
  {
    "uniprot": "Q9CDE7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDE7"
  },
  {
    "uniprot": "Q7AQ40",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ40"
  },
  {
    "uniprot": "O33006",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33006"
  },
  {
    "uniprot": "Q9X798",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X798"
  },
  {
    "uniprot": "Q9CDF4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDF4"
  },
  {
    "uniprot": "Q7AQH9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQH9"
  },
  {
    "uniprot": "Q9CBQ4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBQ4"
  },
  {
    "uniprot": "O69520",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69520"
  },
  {
    "uniprot": "Q7AQM2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQM2"
  },
  {
    "uniprot": "Q9CCV6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCV6"
  },
  {
    "uniprot": "P30762",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P30762"
  },
  {
    "uniprot": "Q7AQB9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQB9"
  },
  {
    "uniprot": "Q7APX9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APX9"
  },
  {
    "uniprot": "Q9CBV8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBV8"
  },
  {
    "uniprot": "Q9CBF1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBF1"
  },
  {
    "uniprot": "O07147",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O07147"
  },
  {
    "uniprot": "Q9CC90",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC90"
  },
  {
    "uniprot": "Q9CD54",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD54"
  },
  {
    "uniprot": "Q9CBP2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBP2"
  },
  {
    "uniprot": "P46385",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46385"
  },
  {
    "uniprot": "Q9X7C5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7C5"
  },
  {
    "uniprot": "Q7APZ0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APZ0"
  },
  {
    "uniprot": "P09239",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P09239"
  },
  {
    "uniprot": "Q9CCW5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCW5"
  },
  {
    "uniprot": "Q9CCG0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCG0"
  },
  {
    "uniprot": "Q9CCP4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCP4"
  },
  {
    "uniprot": "Q7AQ22",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ22"
  },
  {
    "uniprot": "Q9CD18",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD18"
  },
  {
    "uniprot": "Q9X7F1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7F1"
  },
  {
    "uniprot": "Q9CBH2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBH2"
  },
  {
    "uniprot": "P12809",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P12809"
  },
  {
    "uniprot": "Q9CB23",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB23"
  },
  {
    "uniprot": "P30770",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P30770"
  },
  {
    "uniprot": "Q9CCI7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCI7"
  },
  {
    "uniprot": "Q7AQ41",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ41"
  },
  {
    "uniprot": "Q9CBF7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBF7"
  },
  {
    "uniprot": "Q9CCI6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCI6"
  },
  {
    "uniprot": "Q9CDE1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDE1"
  },
  {
    "uniprot": "Q9CB37",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB37"
  },
  {
    "uniprot": "Q9CBL6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBL6"
  },
  {
    "uniprot": "Q9CDE6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDE6"
  },
  {
    "uniprot": "P30760",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P30760"
  },
  {
    "uniprot": "Q9CBI0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBI0"
  },
  {
    "uniprot": "Q9CCT1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCT1"
  },
  {
    "uniprot": "O32989",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32989"
  },
  {
    "uniprot": "Q9CDE2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDE2"
  },
  {
    "uniprot": "Q9CD44",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD44"
  },
  {
    "uniprot": "Q9CCS5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCS5"
  },
  {
    "uniprot": "Q9CBW2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBW2"
  },
  {
    "uniprot": "Q9CBV4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBV4"
  },
  {
    "uniprot": "Q9CBZ3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBZ3"
  },
  {
    "uniprot": "Q9Z5J1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9Z5J1"
  },
  {
    "uniprot": "Q7AQ06",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ06"
  },
  {
    "uniprot": "Q9X7D6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7D6"
  },
  {
    "uniprot": "Q9CBL3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBL3"
  },
  {
    "uniprot": "Q9CBJ4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBJ4"
  },
  {
    "uniprot": "Q7AQM9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQM9"
  },
  {
    "uniprot": "Q9CD38",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD38"
  },
  {
    "uniprot": "Q9CBQ6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBQ6"
  },
  {
    "uniprot": "Q7APR5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APR5"
  },
  {
    "uniprot": "Q9CD70",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD70"
  },
  {
    "uniprot": "O33128",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33128"
  },
  {
    "uniprot": "Q9CB67",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB67"
  },
  {
    "uniprot": "P54078",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54078"
  },
  {
    "uniprot": "Q9ZBE0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9ZBE0"
  },
  {
    "uniprot": "Q7AQG5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQG5"
  },
  {
    "uniprot": "P57991",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P57991"
  },
  {
    "uniprot": "Q9CD34",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD34"
  },
  {
    "uniprot": "Q9CCM6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCM6"
  },
  {
    "uniprot": "Q9CBE5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBE5"
  },
  {
    "uniprot": "Q9CB20",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB20"
  },
  {
    "uniprot": "Q7AQE0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQE0"
  },
  {
    "uniprot": "Q9CCA4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCA4"
  },
  {
    "uniprot": "Q9CBB6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBB6"
  },
  {
    "uniprot": "Q7AQA7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQA7"
  },
  {
    "uniprot": "P37939",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P37939"
  },
  {
    "uniprot": "Q7AQ46",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ46"
  },
  {
    "uniprot": "Q9X784",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X784"
  },
  {
    "uniprot": "Q9CC44",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC44"
  },
  {
    "uniprot": "Q9CD69",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD69"
  },
  {
    "uniprot": "Q9CBA3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBA3"
  },
  {
    "uniprot": "Q9CCV3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCV3"
  },
  {
    "uniprot": "Q7AQ70",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ70"
  },
  {
    "uniprot": "P45833",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45833"
  },
  {
    "uniprot": "Q7APW6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APW6"
  },
  {
    "uniprot": "P30765",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P30765"
  },
  {
    "uniprot": "Q7AQH4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQH4"
  },
  {
    "uniprot": "P46841",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46841"
  },
  {
    "uniprot": "Q9CCM5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCM5"
  },
  {
    "uniprot": "Q49784",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49784"
  },
  {
    "uniprot": "Q49929",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49929"
  },
  {
    "uniprot": "Q9CCH7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCH7"
  },
  {
    "uniprot": "Q9CBT5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBT5"
  },
  {
    "uniprot": "Q9CCF0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCF0"
  },
  {
    "uniprot": "Q7APZ7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APZ7"
  },
  {
    "uniprot": "Q9CDC7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDC7"
  },
  {
    "uniprot": "Q9CDA2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDA2"
  },
  {
    "uniprot": "Q9CDD6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDD6"
  },
  {
    "uniprot": "Q9CCN3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCN3"
  },
  {
    "uniprot": "Q9CC87",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC87"
  },
  {
    "uniprot": "Q7AQK1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQK1"
  },
  {
    "uniprot": "Q9CBR8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBR8"
  },
  {
    "uniprot": "Q9CCL8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCL8"
  },
  {
    "uniprot": "O07134",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O07134"
  },
  {
    "uniprot": "Q9CC22",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC22"
  },
  {
    "uniprot": "Q9CCP1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCP1"
  },
  {
    "uniprot": "Q9CBN6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBN6"
  },
  {
    "uniprot": "Q7AQ88",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ88"
  },
  {
    "uniprot": "Q9CBI4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBI4"
  },
  {
    "uniprot": "O32986",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32986"
  },
  {
    "uniprot": "Q9X7A1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7A1"
  },
  {
    "uniprot": "Q9CCH3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCH3"
  },
  {
    "uniprot": "Q9CBY6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBY6"
  },
  {
    "uniprot": "Q9CCG1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCG1"
  },
  {
    "uniprot": "Q9CD17",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD17"
  },
  {
    "uniprot": "Q7AQB4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQB4"
  },
  {
    "uniprot": "O33113",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33113"
  },
  {
    "uniprot": "P46831",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46831"
  },
  {
    "uniprot": "O06079",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O06079"
  },
  {
    "uniprot": "Q9CBJ2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBJ2"
  },
  {
    "uniprot": "Q9Z5K4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9Z5K4"
  },
  {
    "uniprot": "Q9CBA8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBA8"
  },
  {
    "uniprot": "Q9CC09",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC09"
  },
  {
    "uniprot": "Q9CBI9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBI9"
  },
  {
    "uniprot": "Q49865",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49865"
  },
  {
    "uniprot": "Q9CCN0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCN0"
  },
  {
    "uniprot": "Q7APS3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APS3"
  },
  {
    "uniprot": "Q9CD68",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD68"
  },
  {
    "uniprot": "Q9CB83",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB83"
  },
  {
    "uniprot": "Q49755",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49755"
  },
  {
    "uniprot": "O32994",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32994"
  },
  {
    "uniprot": "Q9CDA1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDA1"
  },
  {
    "uniprot": "Q9CCQ7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCQ7"
  },
  {
    "uniprot": "O32881",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32881"
  },
  {
    "uniprot": "Q7AQ98",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ98"
  },
  {
    "uniprot": "Q9CBS8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBS8"
  },
  {
    "uniprot": "Q9CCE2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCE2"
  },
  {
    "uniprot": "Q9CCE1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCE1"
  },
  {
    "uniprot": "Q9CBM0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBM0"
  },
  {
    "uniprot": "P57995",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P57995"
  },
  {
    "uniprot": "Q9CCJ2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCJ2"
  },
  {
    "uniprot": "Q9CBX6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBX6"
  },
  {
    "uniprot": "Q9CCB1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCB1"
  },
  {
    "uniprot": "O32913",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32913"
  },
  {
    "uniprot": "P46714",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46714"
  },
  {
    "uniprot": "Q9CCD6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCD6"
  },
  {
    "uniprot": "Q9CDB0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDB0"
  },
  {
    "uniprot": "Q9CD48",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD48"
  },
  {
    "uniprot": "Q7AQ84",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ84"
  },
  {
    "uniprot": "Q7APV8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APV8"
  },
  {
    "uniprot": "P46815",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46815"
  },
  {
    "uniprot": "Q7APV2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APV2"
  },
  {
    "uniprot": "Q9CCC9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCC9"
  },
  {
    "uniprot": "Q7AQ23",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ23"
  },
  {
    "uniprot": "P30763",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P30763"
  },
  {
    "uniprot": "Q9CBX3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBX3"
  },
  {
    "uniprot": "Q7AQ69",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ69"
  },
  {
    "uniprot": "Q9CBV0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBV0"
  },
  {
    "uniprot": "Q02605",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q02605"
  },
  {
    "uniprot": "O07151",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O07151"
  },
  {
    "uniprot": "Q9CCX3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCX3"
  },
  {
    "uniprot": "Q7AQM5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQM5"
  },
  {
    "uniprot": "P55193",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P55193"
  },
  {
    "uniprot": "P0A5H7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P0A5H7"
  },
  {
    "uniprot": "Q9CBA5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBA5"
  },
  {
    "uniprot": "P46726",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46726"
  },
  {
    "uniprot": "P45829",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45829"
  },
  {
    "uniprot": "Q9CCT5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCT5"
  },
  {
    "uniprot": "Q9CBG6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBG6"
  },
  {
    "uniprot": "Q49645",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49645"
  },
  {
    "uniprot": "O08361",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O08361"
  },
  {
    "uniprot": "Q7AQ39",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ39"
  },
  {
    "uniprot": "Q9CBE7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBE7"
  },
  {
    "uniprot": "Q49890",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49890"
  },
  {
    "uniprot": "Q9CBQ0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBQ0"
  },
  {
    "uniprot": "Q9CBD0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBD0"
  },
  {
    "uniprot": "Q7AQG7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQG7"
  },
  {
    "uniprot": "Q49765",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49765"
  },
  {
    "uniprot": "P46701",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46701"
  },
  {
    "uniprot": "O69481",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69481"
  },
  {
    "uniprot": "Q9CB80",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB80"
  },
  {
    "uniprot": "Q49896",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49896"
  },
  {
    "uniprot": "O33116",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33116"
  },
  {
    "uniprot": "O69492",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69492"
  },
  {
    "uniprot": "O07145",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O07145"
  },
  {
    "uniprot": "Q9X797",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X797"
  },
  {
    "uniprot": "Q9CCF6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCF6"
  },
  {
    "uniprot": "Q9CCU2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCU2"
  },
  {
    "uniprot": "P45836",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45836"
  },
  {
    "uniprot": "Q7AQ97",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ97"
  },
  {
    "uniprot": "Q7AQ07",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ07"
  },
  {
    "uniprot": "Q49844",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49844"
  },
  {
    "uniprot": "P46389",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46389"
  },
  {
    "uniprot": "O69583",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69583"
  },
  {
    "uniprot": "Q9CBC1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBC1"
  },
  {
    "uniprot": "P46707",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46707"
  },
  {
    "uniprot": "Q9CC01",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC01"
  },
  {
    "uniprot": "Q7APW7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APW7"
  },
  {
    "uniprot": "Q9CC85",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC85"
  },
  {
    "uniprot": "Q9CCP8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCP8"
  },
  {
    "uniprot": "P45487",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P45487"
  },
  {
    "uniprot": "Q9CCX4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCX4"
  },
  {
    "uniprot": "Q9CBV3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBV3"
  },
  {
    "uniprot": "Q9CD13",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD13"
  },
  {
    "uniprot": "Q7APT3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APT3"
  },
  {
    "uniprot": "P46813",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46813"
  },
  {
    "uniprot": "Q9CCJ4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCJ4"
  },
  {
    "uniprot": "Q9CD06",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD06"
  },
  {
    "uniprot": "Q7AQB3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQB3"
  },
  {
    "uniprot": "P57993",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P57993"
  },
  {
    "uniprot": "O32990",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32990"
  },
  {
    "uniprot": "P54879",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54879"
  },
  {
    "uniprot": "Q9CCU3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCU3"
  },
  {
    "uniprot": "Q9CD82",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD82"
  },
  {
    "uniprot": "Q9CBY9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBY9"
  },
  {
    "uniprot": "Q9CC56",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC56"
  },
  {
    "uniprot": "Q49640",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49640"
  },
  {
    "uniprot": "Q9CBP4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBP4"
  },
  {
    "uniprot": "Q9CCQ0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCQ0"
  },
  {
    "uniprot": "O33060",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33060"
  },
  {
    "uniprot": "Q7AQC4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQC4"
  },
  {
    "uniprot": "Q9CD96",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD96"
  },
  {
    "uniprot": "Q7AQD0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQD0"
  },
  {
    "uniprot": "Q7AQA9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQA9"
  },
  {
    "uniprot": "Q9CBQ3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBQ3"
  },
  {
    "uniprot": "Q9CC25",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC25"
  },
  {
    "uniprot": "Q7AQG6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQG6"
  },
  {
    "uniprot": "P54139",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54139"
  },
  {
    "uniprot": "Q9CC92",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC92"
  },
  {
    "uniprot": "Q9CBF2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBF2"
  },
  {
    "uniprot": "Q9CBP0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBP0"
  },
  {
    "uniprot": "O32967",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32967"
  },
  {
    "uniprot": "O32984",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O32984"
  },
  {
    "uniprot": "Q9CB65",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB65"
  },
  {
    "uniprot": "Q9CCP0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCP0"
  },
  {
    "uniprot": "Q9CD61",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD61"
  },
  {
    "uniprot": "Q9CD47",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD47"
  },
  {
    "uniprot": "Q9CBS6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBS6"
  },
  {
    "uniprot": "Q7APU0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APU0"
  },
  {
    "uniprot": "Q7APT5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APT5"
  },
  {
    "uniprot": "Q9CD03",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD03"
  },
  {
    "uniprot": "P54579",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54579"
  },
  {
    "uniprot": "Q9CD55",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD55"
  },
  {
    "uniprot": "Q9CDA7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDA7"
  },
  {
    "uniprot": "Q9CCU6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCU6"
  },
  {
    "uniprot": "Q9CDB8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDB8"
  },
  {
    "uniprot": "Q7AQN0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQN0"
  },
  {
    "uniprot": "Q9CBX1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBX1"
  },
  {
    "uniprot": "Q7AQP6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQP6"
  },
  {
    "uniprot": "Q7AQ61",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ61"
  },
  {
    "uniprot": "Q7AQ81",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ81"
  },
  {
    "uniprot": "O33071",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33071"
  },
  {
    "uniprot": "Q7AQL2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQL2"
  },
  {
    "uniprot": "Q7AQJ3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQJ3"
  },
  {
    "uniprot": "O69538",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69538"
  },
  {
    "uniprot": "Q9CC91",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC91"
  },
  {
    "uniprot": "Q9CCH8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCH8"
  },
  {
    "uniprot": "Q9CC05",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC05"
  },
  {
    "uniprot": "Q9CB68",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB68"
  },
  {
    "uniprot": "Q49780",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49780"
  },
  {
    "uniprot": "P40828",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P40828"
  },
  {
    "uniprot": "O33020",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33020"
  },
  {
    "uniprot": "Q49939",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49939"
  },
  {
    "uniprot": "Q9CBP6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBP6"
  },
  {
    "uniprot": "Q9X7E6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7E6"
  },
  {
    "uniprot": "Q9CBF0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBF0"
  },
  {
    "uniprot": "Q50049",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50049"
  },
  {
    "uniprot": "Q7AQ80",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ80"
  },
  {
    "uniprot": "Q9CCU8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCU8"
  },
  {
    "uniprot": "O33064",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33064"
  },
  {
    "uniprot": "Q9CCK9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCK9"
  },
  {
    "uniprot": "Q9CBB4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBB4"
  },
  {
    "uniprot": "Q9CCS1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCS1"
  },
  {
    "uniprot": "Q9CC52",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC52"
  },
  {
    "uniprot": "Q49901",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49901"
  },
  {
    "uniprot": "Q7AQ82",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ82"
  },
  {
    "uniprot": "Q9CBR0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBR0"
  },
  {
    "uniprot": "Q9CD00",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD00"
  },
  {
    "uniprot": "Q9X7A0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9X7A0"
  },
  {
    "uniprot": "Q9CCG5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCG5"
  },
  {
    "uniprot": "P54883",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54883"
  },
  {
    "uniprot": "Q49744",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49744"
  },
  {
    "uniprot": "O33012",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33012"
  },
  {
    "uniprot": "P54743",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P54743"
  },
  {
    "uniprot": "Q9CD10",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD10"
  },
  {
    "uniprot": "Q9CDC9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDC9"
  },
  {
    "uniprot": "Q9CBH3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBH3"
  },
  {
    "uniprot": "Q9CD05",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD05"
  },
  {
    "uniprot": "Q9CBR9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBR9"
  },
  {
    "uniprot": "Q7APW3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APW3"
  },
  {
    "uniprot": "Q9CD89",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD89"
  },
  {
    "uniprot": "Q9CB63",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB63"
  },
  {
    "uniprot": "Q9CC46",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC46"
  },
  {
    "uniprot": "Q9CBZ2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBZ2"
  },
  {
    "uniprot": "Q7AQJ1",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQJ1"
  },
  {
    "uniprot": "Q49934",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49934"
  },
  {
    "uniprot": "Q9CCL9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCL9"
  },
  {
    "uniprot": "Q9CCL6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCL6"
  },
  {
    "uniprot": "Q9CD24",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD24"
  },
  {
    "uniprot": "Q9CB96",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB96"
  },
  {
    "uniprot": "Q7AQ72",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ72"
  },
  {
    "uniprot": "Q9CBG7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBG7"
  },
  {
    "uniprot": "P46702",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46702"
  },
  {
    "uniprot": "Q9CCZ0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCZ0"
  },
  {
    "uniprot": "Q9CBU9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBU9"
  },
  {
    "uniprot": "O33125",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33125"
  },
  {
    "uniprot": "Q9CBY0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBY0"
  },
  {
    "uniprot": "Q9CC57",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC57"
  },
  {
    "uniprot": "Q9CD64",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD64"
  },
  {
    "uniprot": "Q9CDD9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDD9"
  },
  {
    "uniprot": "Q9CBS4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBS4"
  },
  {
    "uniprot": "Q9CCB2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCB2"
  },
  {
    "uniprot": "Q9CBW8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBW8"
  },
  {
    "uniprot": "Q9CD50",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD50"
  },
  {
    "uniprot": "Q9ZBL2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9ZBL2"
  },
  {
    "uniprot": "Q49746",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49746"
  },
  {
    "uniprot": "Q9CBD4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBD4"
  },
  {
    "uniprot": "P46610",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46610"
  },
  {
    "uniprot": "Q9CCS0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCS0"
  },
  {
    "uniprot": "Q9CCB5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCB5"
  },
  {
    "uniprot": "Q9CCN6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCN6"
  },
  {
    "uniprot": "Q9CBX7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBX7"
  },
  {
    "uniprot": "Q9CBS2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBS2"
  },
  {
    "uniprot": "Q7AQM3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQM3"
  },
  {
    "uniprot": "Q7APY8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APY8"
  },
  {
    "uniprot": "Q9CBR2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBR2"
  },
  {
    "uniprot": "Q9CB44",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB44"
  },
  {
    "uniprot": "Q7AQK6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQK6"
  },
  {
    "uniprot": "Q7AQ60",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ60"
  },
  {
    "uniprot": "Q7AQK0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQK0"
  },
  {
    "uniprot": "Q9CBT8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBT8"
  },
  {
    "uniprot": "P35901",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P35901"
  },
  {
    "uniprot": "Q7AQ10",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ10"
  },
  {
    "uniprot": "Q9CCQ2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCQ2"
  },
  {
    "uniprot": "P46838",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46838"
  },
  {
    "uniprot": "Q7APS8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APS8"
  },
  {
    "uniprot": "Q9CBT9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBT9"
  },
  {
    "uniprot": "Q9CCW8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCW8"
  },
  {
    "uniprot": "Q9CCF9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCF9"
  },
  {
    "uniprot": "Q7AQ37",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ37"
  },
  {
    "uniprot": "O06091",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O06091"
  },
  {
    "uniprot": "Q9CD83",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD83"
  },
  {
    "uniprot": "P46723",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46723"
  },
  {
    "uniprot": "Q49871",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49871"
  },
  {
    "uniprot": "Q9CCP2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCP2"
  },
  {
    "uniprot": "O33021",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33021"
  },
  {
    "uniprot": "Q9CCA6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCA6"
  },
  {
    "uniprot": "Q9CCC7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCC7"
  },
  {
    "uniprot": "Q9CC67",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC67"
  },
  {
    "uniprot": "Q9CCA2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCA2"
  },
  {
    "uniprot": "Q7AQ01",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ01"
  },
  {
    "uniprot": "Q9CCG6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCG6"
  },
  {
    "uniprot": "P52063",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P52063"
  },
  {
    "uniprot": "Q9CC35",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC35"
  },
  {
    "uniprot": "Q9CDD0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDD0"
  },
  {
    "uniprot": "Q9CDC5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDC5"
  },
  {
    "uniprot": "Q9CD99",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD99"
  },
  {
    "uniprot": "Q7AQB5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQB5"
  },
  {
    "uniprot": "Q9CBZ8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBZ8"
  },
  {
    "uniprot": "Q9CBJ8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBJ8"
  },
  {
    "uniprot": "Q9CBJ7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBJ7"
  },
  {
    "uniprot": "Q9CCM2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCM2"
  },
  {
    "uniprot": "Q9CBA7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBA7"
  },
  {
    "uniprot": "Q7AQG9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQG9"
  },
  {
    "uniprot": "P46809",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P46809"
  },
  {
    "uniprot": "Q9CBC9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBC9"
  },
  {
    "uniprot": "O69561",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O69561"
  },
  {
    "uniprot": "P53528",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53528"
  },
  {
    "uniprot": "O06081",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O06081"
  },
  {
    "uniprot": "Q9CCR4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCR4"
  },
  {
    "uniprot": "O33089",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33089"
  },
  {
    "uniprot": "Q7APV0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APV0"
  },
  {
    "uniprot": "Q9CCR0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCR0"
  },
  {
    "uniprot": "Q7AQA2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQA2"
  },
  {
    "uniprot": "P53423",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/P53423"
  },
  {
    "uniprot": "Q9CCU5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCU5"
  },
  {
    "uniprot": "Q9CCZ9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCZ9"
  },
  {
    "uniprot": "Q7AQK7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQK7"
  },
  {
    "uniprot": "Q9CCF3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCF3"
  },
  {
    "uniprot": "Q9CC28",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC28"
  },
  {
    "uniprot": "Q50098",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q50098"
  },
  {
    "uniprot": "Q9CC69",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC69"
  },
  {
    "uniprot": "Q49769",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49769"
  },
  {
    "uniprot": "Q7AQL7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQL7"
  },
  {
    "uniprot": "Q49760",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q49760"
  },
  {
    "uniprot": "Q7AQA5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQA5"
  },
  {
    "uniprot": "Q7AQL5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQL5"
  },
  {
    "uniprot": "Q9CCW6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCW6"
  },
  {
    "uniprot": "Q7AQH6",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQH6"
  },
  {
    "uniprot": "Q9CBM9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBM9"
  },
  {
    "uniprot": "Q9CCN8",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCN8"
  },
  {
    "uniprot": "Q57240",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q57240"
  },
  {
    "uniprot": "Q9CBT3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBT3"
  },
  {
    "uniprot": "Q7AQ19",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ19"
  },
  {
    "uniprot": "Q9CCV0",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCV0"
  },
  {
    "uniprot": "O53114",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O53114"
  },
  {
    "uniprot": "Q9CB48",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB48"
  },
  {
    "uniprot": "Q9CCJ5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCJ5"
  },
  {
    "uniprot": "Q9CBL4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBL4"
  },
  {
    "uniprot": "Q7AQ25",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ25"
  },
  {
    "uniprot": "Q7AQ64",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ64"
  },
  {
    "uniprot": "Q7APW4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APW4"
  },
  {
    "uniprot": "Q9CB43",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB43"
  },
  {
    "uniprot": "Q9CC21",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CC21"
  },
  {
    "uniprot": "O33095",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/O33095"
  },
  {
    "uniprot": "Q9CBK3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBK3"
  },
  {
    "uniprot": "Q9CDD5",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CDD5"
  },
  {
    "uniprot": "Q9CCX9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCX9"
  },
  {
    "uniprot": "Q9CCS4",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCS4"
  },
  {
    "uniprot": "Q7AQ79",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ79"
  },
  {
    "uniprot": "Q9CB25",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB25"
  },
  {
    "uniprot": "Q9CB76",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB76"
  },
  {
    "uniprot": "Q9CB28",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CB28"
  },
  {
    "uniprot": "Q05868",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q05868"
  },
  {
    "uniprot": "Q9CBG3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBG3"
  },
  {
    "uniprot": "Q7AQC3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQC3"
  },
  {
    "uniprot": "Q7AQF3",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQF3"
  },
  {
    "uniprot": "Q9CBM2",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CBM2"
  },
  {
    "uniprot": "Q7APU9",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7APU9"
  },
  {
    "uniprot": "Q7AQ34",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ34"
  },
  {
    "uniprot": "Q9CCS7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCS7"
  },
  {
    "uniprot": "Q7AQ27",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q7AQ27"
  },
  {
    "uniprot": "Q9CD56",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CD56"
  },
  {
    "uniprot": "Q9CCZ7",
    "species": "Mycobacterium leprae",
    "disease": "Leprosy",
    "results": "/results/Q9CCZ7"
  },
  {
    "uniprot": "A4I3W4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3W4"
  },
  {
    "uniprot": "A4I2U1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2U1"
  },
  {
    "uniprot": "A4ID61",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID61"
  },
  {
    "uniprot": "A4HS89",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS89"
  },
  {
    "uniprot": "A4I5Z6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Z6"
  },
  {
    "uniprot": "A4HSY4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSY4"
  },
  {
    "uniprot": "A4HY84",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY84"
  },
  {
    "uniprot": "A4IBT9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBT9"
  },
  {
    "uniprot": "A4I1J1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1J1"
  },
  {
    "uniprot": "A4I1Q1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Q1"
  },
  {
    "uniprot": "A4HUX0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUX0"
  },
  {
    "uniprot": "A4HTK5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTK5"
  },
  {
    "uniprot": "A4HY65",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY65"
  },
  {
    "uniprot": "A4HZW4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZW4"
  },
  {
    "uniprot": "E9AGU6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGU6"
  },
  {
    "uniprot": "A4I3D6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3D6"
  },
  {
    "uniprot": "A4HTV3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTV3"
  },
  {
    "uniprot": "A4HVS1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVS1"
  },
  {
    "uniprot": "A4I0N4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0N4"
  },
  {
    "uniprot": "A4I2Z9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Z9"
  },
  {
    "uniprot": "A4HRN3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRN3"
  },
  {
    "uniprot": "A4I3Y0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Y0"
  },
  {
    "uniprot": "A4IDT1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDT1"
  },
  {
    "uniprot": "A4HYU9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYU9"
  },
  {
    "uniprot": "A4I280",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I280"
  },
  {
    "uniprot": "A4IAN4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAN4"
  },
  {
    "uniprot": "E9AGZ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGZ1"
  },
  {
    "uniprot": "A4I1I7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1I7"
  },
  {
    "uniprot": "A4IA91",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA91"
  },
  {
    "uniprot": "A4ID94",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID94"
  },
  {
    "uniprot": "A4I9D7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9D7"
  },
  {
    "uniprot": "A4HSG6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSG6"
  },
  {
    "uniprot": "A4I5D1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5D1"
  },
  {
    "uniprot": "A4HYA7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYA7"
  },
  {
    "uniprot": "A4IBK6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBK6"
  },
  {
    "uniprot": "A4HV32",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV32"
  },
  {
    "uniprot": "A4HYZ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYZ6"
  },
  {
    "uniprot": "A4IDN6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDN6"
  },
  {
    "uniprot": "A4HZY0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZY0"
  },
  {
    "uniprot": "A4ICM6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICM6"
  },
  {
    "uniprot": "A4IA72",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA72"
  },
  {
    "uniprot": "E9AHZ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHZ1"
  },
  {
    "uniprot": "A4IDR5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDR5"
  },
  {
    "uniprot": "A4HV28",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV28"
  },
  {
    "uniprot": "A4I099",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I099"
  },
  {
    "uniprot": "A4HVL4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVL4"
  },
  {
    "uniprot": "A4HUW6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUW6"
  },
  {
    "uniprot": "A4I0C1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0C1"
  },
  {
    "uniprot": "A4I6L8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6L8"
  },
  {
    "uniprot": "A4HVY5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVY5"
  },
  {
    "uniprot": "A4I1A0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1A0"
  },
  {
    "uniprot": "A4IDZ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDZ4"
  },
  {
    "uniprot": "A4ICW3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICW3"
  },
  {
    "uniprot": "A4HW72",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW72"
  },
  {
    "uniprot": "A4ICG5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICG5"
  },
  {
    "uniprot": "A4I741",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I741"
  },
  {
    "uniprot": "A4HYI5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYI5"
  },
  {
    "uniprot": "A4IAB4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAB4"
  },
  {
    "uniprot": "A4HTE1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTE1"
  },
  {
    "uniprot": "A4HV43",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV43"
  },
  {
    "uniprot": "A4I895",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I895"
  },
  {
    "uniprot": "A4I9P2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9P2"
  },
  {
    "uniprot": "A4HUV4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUV4"
  },
  {
    "uniprot": "A4I7P0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7P0"
  },
  {
    "uniprot": "A4HVV0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVV0"
  },
  {
    "uniprot": "A4IBD7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBD7"
  },
  {
    "uniprot": "A4I8X1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8X1"
  },
  {
    "uniprot": "A4I707",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I707"
  },
  {
    "uniprot": "A4HWY1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWY1"
  },
  {
    "uniprot": "A4I9W1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9W1"
  },
  {
    "uniprot": "A4ICH3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICH3"
  },
  {
    "uniprot": "A4I1L9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1L9"
  },
  {
    "uniprot": "A4I357",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I357"
  },
  {
    "uniprot": "A4HXT2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXT2"
  },
  {
    "uniprot": "A4I8F7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8F7"
  },
  {
    "uniprot": "A4I3R8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3R8"
  },
  {
    "uniprot": "A4I2L1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2L1"
  },
  {
    "uniprot": "A4HRI3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRI3"
  },
  {
    "uniprot": "A4HRD2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRD2"
  },
  {
    "uniprot": "A4I447",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I447"
  },
  {
    "uniprot": "A4IBB9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBB9"
  },
  {
    "uniprot": "A4ICS0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICS0"
  },
  {
    "uniprot": "E9AH27",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH27"
  },
  {
    "uniprot": "A4I9K1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9K1"
  },
  {
    "uniprot": "A4I6M3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6M3"
  },
  {
    "uniprot": "A4HVM7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVM7"
  },
  {
    "uniprot": "A4I5V4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5V4"
  },
  {
    "uniprot": "A4IAJ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAJ9"
  },
  {
    "uniprot": "A4HTJ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTJ4"
  },
  {
    "uniprot": "A4I0X4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0X4"
  },
  {
    "uniprot": "A4I9S4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9S4"
  },
  {
    "uniprot": "A4HXN8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXN8"
  },
  {
    "uniprot": "A4HVY8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVY8"
  },
  {
    "uniprot": "A4IE21",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE21"
  },
  {
    "uniprot": "A4I3A9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3A9"
  },
  {
    "uniprot": "A4HZM3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZM3"
  },
  {
    "uniprot": "A4I2D7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2D7"
  },
  {
    "uniprot": "A4HTI3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTI3"
  },
  {
    "uniprot": "A4HXD2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXD2"
  },
  {
    "uniprot": "A4HU43",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU43"
  },
  {
    "uniprot": "A4IDC0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDC0"
  },
  {
    "uniprot": "A4IDM4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDM4"
  },
  {
    "uniprot": "A4IE41",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE41"
  },
  {
    "uniprot": "A4I8K0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8K0"
  },
  {
    "uniprot": "A4I488",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I488"
  },
  {
    "uniprot": "A4HSD6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSD6"
  },
  {
    "uniprot": "E9AHE3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHE3"
  },
  {
    "uniprot": "A4ID89",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID89"
  },
  {
    "uniprot": "A4HZC4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZC4"
  },
  {
    "uniprot": "A4I3A1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3A1"
  },
  {
    "uniprot": "A4I3N6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3N6"
  },
  {
    "uniprot": "A4IC14",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC14"
  },
  {
    "uniprot": "A4I2E7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2E7"
  },
  {
    "uniprot": "A4IA01",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA01"
  },
  {
    "uniprot": "A4I8K5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8K5"
  },
  {
    "uniprot": "A4I3D3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3D3"
  },
  {
    "uniprot": "A4I258",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I258"
  },
  {
    "uniprot": "A4HY37",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY37"
  },
  {
    "uniprot": "A4I322",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I322"
  },
  {
    "uniprot": "A4I102",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I102"
  },
  {
    "uniprot": "A4I4K2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4K2"
  },
  {
    "uniprot": "A4HS31",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS31"
  },
  {
    "uniprot": "A4I5X9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5X9"
  },
  {
    "uniprot": "A4HW85",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW85"
  },
  {
    "uniprot": "A4IAR0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAR0"
  },
  {
    "uniprot": "A4IAS9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAS9"
  },
  {
    "uniprot": "A4I1T3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1T3"
  },
  {
    "uniprot": "A4HTG8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTG8"
  },
  {
    "uniprot": "E9AGU0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGU0"
  },
  {
    "uniprot": "A4I862",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I862"
  },
  {
    "uniprot": "A4HZC2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZC2"
  },
  {
    "uniprot": "A0A381MV60",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MV60"
  },
  {
    "uniprot": "A4I495",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I495"
  },
  {
    "uniprot": "A4IE01",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE01"
  },
  {
    "uniprot": "A4I2S9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2S9"
  },
  {
    "uniprot": "A4I202",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I202"
  },
  {
    "uniprot": "A4HXY9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXY9"
  },
  {
    "uniprot": "A4I3L8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3L8"
  },
  {
    "uniprot": "A4ICL4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICL4"
  },
  {
    "uniprot": "A4HXR0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXR0"
  },
  {
    "uniprot": "E9AGT0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGT0"
  },
  {
    "uniprot": "A4I7R7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7R7"
  },
  {
    "uniprot": "A4I098",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I098"
  },
  {
    "uniprot": "A4I2P9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2P9"
  },
  {
    "uniprot": "E9AGZ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGZ6"
  },
  {
    "uniprot": "A4I242",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I242"
  },
  {
    "uniprot": "E9AHS0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHS0"
  },
  {
    "uniprot": "E9AG48",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG48"
  },
  {
    "uniprot": "A4HT78",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT78"
  },
  {
    "uniprot": "A4I6H2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6H2"
  },
  {
    "uniprot": "A4I253",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I253"
  },
  {
    "uniprot": "A4HYS6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYS6"
  },
  {
    "uniprot": "A4ICY9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICY9"
  },
  {
    "uniprot": "E9AGV9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGV9"
  },
  {
    "uniprot": "A4I0R3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0R3"
  },
  {
    "uniprot": "A4HZA2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZA2"
  },
  {
    "uniprot": "A4HW65",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW65"
  },
  {
    "uniprot": "A4I4V2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4V2"
  },
  {
    "uniprot": "E9AHR3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHR3"
  },
  {
    "uniprot": "A4HYX3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYX3"
  },
  {
    "uniprot": "A4HT47",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT47"
  },
  {
    "uniprot": "E9AG27",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG27"
  },
  {
    "uniprot": "A4I1D8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1D8"
  },
  {
    "uniprot": "A4I2C4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2C4"
  },
  {
    "uniprot": "A4I3U2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3U2"
  },
  {
    "uniprot": "E9AGI5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGI5"
  },
  {
    "uniprot": "A4ICX7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICX7"
  },
  {
    "uniprot": "E9AG93",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG93"
  },
  {
    "uniprot": "E9AGG9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGG9"
  },
  {
    "uniprot": "A4HYD3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYD3"
  },
  {
    "uniprot": "A4HW67",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW67"
  },
  {
    "uniprot": "A4I4A7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4A7"
  },
  {
    "uniprot": "A4I2T6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2T6"
  },
  {
    "uniprot": "A4IAV3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAV3"
  },
  {
    "uniprot": "A4HUX3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUX3"
  },
  {
    "uniprot": "A4HU53",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU53"
  },
  {
    "uniprot": "A4I3E0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3E0"
  },
  {
    "uniprot": "A4I4X6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4X6"
  },
  {
    "uniprot": "A4I1J8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1J8"
  },
  {
    "uniprot": "A4HSA5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSA5"
  },
  {
    "uniprot": "A4IBE2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBE2"
  },
  {
    "uniprot": "A4HWV1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWV1"
  },
  {
    "uniprot": "E9AI00",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AI00"
  },
  {
    "uniprot": "A4I6U3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6U3"
  },
  {
    "uniprot": "A4I092",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I092"
  },
  {
    "uniprot": "A4ID38",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID38"
  },
  {
    "uniprot": "A4IBI3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBI3"
  },
  {
    "uniprot": "A4I8A2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8A2"
  },
  {
    "uniprot": "A4ICX4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICX4"
  },
  {
    "uniprot": "A4IBV7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBV7"
  },
  {
    "uniprot": "A4HWC5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWC5"
  },
  {
    "uniprot": "A4I566",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I566"
  },
  {
    "uniprot": "A4I3U3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3U3"
  },
  {
    "uniprot": "A4I6P2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6P2"
  },
  {
    "uniprot": "A4HX03",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX03"
  },
  {
    "uniprot": "A4ICR0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICR0"
  },
  {
    "uniprot": "A4HXP2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXP2"
  },
  {
    "uniprot": "A4I9M0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9M0"
  },
  {
    "uniprot": "A4I359",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I359"
  },
  {
    "uniprot": "E9AGK8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGK8"
  },
  {
    "uniprot": "A4ICY2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICY2"
  },
  {
    "uniprot": "A4I0J5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0J5"
  },
  {
    "uniprot": "A4IBQ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBQ4"
  },
  {
    "uniprot": "A4I9J0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9J0"
  },
  {
    "uniprot": "A4HW49",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW49"
  },
  {
    "uniprot": "A4I3B7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3B7"
  },
  {
    "uniprot": "A4HRM1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRM1"
  },
  {
    "uniprot": "A4IAD4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAD4"
  },
  {
    "uniprot": "A4HVR1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVR1"
  },
  {
    "uniprot": "A4HZ14",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ14"
  },
  {
    "uniprot": "A4IDL1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDL1"
  },
  {
    "uniprot": "A4HUH7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUH7"
  },
  {
    "uniprot": "A4I1Q7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Q7"
  },
  {
    "uniprot": "A4I1Q0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Q0"
  },
  {
    "uniprot": "A4IC10",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC10"
  },
  {
    "uniprot": "E9AH36",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH36"
  },
  {
    "uniprot": "A4ICG3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICG3"
  },
  {
    "uniprot": "A4HUE9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUE9"
  },
  {
    "uniprot": "A4I3H0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3H0"
  },
  {
    "uniprot": "A4HS42",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS42"
  },
  {
    "uniprot": "A4I9A9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9A9"
  },
  {
    "uniprot": "A4HSZ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSZ7"
  },
  {
    "uniprot": "A4I6H5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6H5"
  },
  {
    "uniprot": "A4IBS6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBS6"
  },
  {
    "uniprot": "A4I3E1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3E1"
  },
  {
    "uniprot": "A4HRX4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRX4"
  },
  {
    "uniprot": "A4I331",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I331"
  },
  {
    "uniprot": "A4IB34",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB34"
  },
  {
    "uniprot": "A4HTI0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTI0"
  },
  {
    "uniprot": "A4HYQ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYQ7"
  },
  {
    "uniprot": "A4HV12",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV12"
  },
  {
    "uniprot": "A4I3V9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3V9"
  },
  {
    "uniprot": "E9AHD2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHD2"
  },
  {
    "uniprot": "A4HVK6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVK6"
  },
  {
    "uniprot": "A4IBM8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBM8"
  },
  {
    "uniprot": "A4IBV3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBV3"
  },
  {
    "uniprot": "A4IBZ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBZ0"
  },
  {
    "uniprot": "A4HWK2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWK2"
  },
  {
    "uniprot": "A4HTS2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTS2"
  },
  {
    "uniprot": "A4I4M2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4M2"
  },
  {
    "uniprot": "A4HSE0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSE0"
  },
  {
    "uniprot": "A4I8X9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8X9"
  },
  {
    "uniprot": "A4HX01",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX01"
  },
  {
    "uniprot": "A4IBV6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBV6"
  },
  {
    "uniprot": "A4IBN3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBN3"
  },
  {
    "uniprot": "A4HVZ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVZ4"
  },
  {
    "uniprot": "A4HWV8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWV8"
  },
  {
    "uniprot": "A4HRL3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRL3"
  },
  {
    "uniprot": "A4HWX0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWX0"
  },
  {
    "uniprot": "A4HTV0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTV0"
  },
  {
    "uniprot": "A4I2R2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2R2"
  },
  {
    "uniprot": "A4HVM0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVM0"
  },
  {
    "uniprot": "A4HZ98",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ98"
  },
  {
    "uniprot": "A4I7R2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7R2"
  },
  {
    "uniprot": "A4I960",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I960"
  },
  {
    "uniprot": "A4HSM4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSM4"
  },
  {
    "uniprot": "A4HXA7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXA7"
  },
  {
    "uniprot": "A4IDG2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDG2"
  },
  {
    "uniprot": "A4IBF5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBF5"
  },
  {
    "uniprot": "A4I3C6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3C6"
  },
  {
    "uniprot": "A4I0M2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0M2"
  },
  {
    "uniprot": "A4I4C2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4C2"
  },
  {
    "uniprot": "A4HTN6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTN6"
  },
  {
    "uniprot": "A4I9X9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9X9"
  },
  {
    "uniprot": "A4I9T8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9T8"
  },
  {
    "uniprot": "A4I4Z7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Z7"
  },
  {
    "uniprot": "A4I1I5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1I5"
  },
  {
    "uniprot": "A4IC56",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC56"
  },
  {
    "uniprot": "A4HZX3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZX3"
  },
  {
    "uniprot": "A4I5G8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5G8"
  },
  {
    "uniprot": "A0A381MFJ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MFJ0"
  },
  {
    "uniprot": "A4HVM2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVM2"
  },
  {
    "uniprot": "A4HSP4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSP4"
  },
  {
    "uniprot": "A4ICP3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICP3"
  },
  {
    "uniprot": "A4ICF6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICF6"
  },
  {
    "uniprot": "A4IAJ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAJ6"
  },
  {
    "uniprot": "A4I2D1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2D1"
  },
  {
    "uniprot": "E9AG59",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG59"
  },
  {
    "uniprot": "A4I5K4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5K4"
  },
  {
    "uniprot": "A4I8M9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8M9"
  },
  {
    "uniprot": "A4HZA5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZA5"
  },
  {
    "uniprot": "A0A381MKM4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MKM4"
  },
  {
    "uniprot": "A4I5R1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5R1"
  },
  {
    "uniprot": "A4HVE4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVE4"
  },
  {
    "uniprot": "A4I583",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I583"
  },
  {
    "uniprot": "A4IDD1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDD1"
  },
  {
    "uniprot": "A4HY23",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY23"
  },
  {
    "uniprot": "A4HUB2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUB2"
  },
  {
    "uniprot": "A4I9U6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9U6"
  },
  {
    "uniprot": "A4IDZ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDZ3"
  },
  {
    "uniprot": "A4IAB7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAB7"
  },
  {
    "uniprot": "A4HXQ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXQ3"
  },
  {
    "uniprot": "A4I712",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I712"
  },
  {
    "uniprot": "A4IAM9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAM9"
  },
  {
    "uniprot": "A4HXJ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXJ0"
  },
  {
    "uniprot": "A4HVH8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVH8"
  },
  {
    "uniprot": "A4I3B2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3B2"
  },
  {
    "uniprot": "A4HVH9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVH9"
  },
  {
    "uniprot": "A4I765",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I765"
  },
  {
    "uniprot": "E9AGZ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGZ9"
  },
  {
    "uniprot": "A4IBC5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBC5"
  },
  {
    "uniprot": "A4I519",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I519"
  },
  {
    "uniprot": "E9AHL0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHL0"
  },
  {
    "uniprot": "E9AGB5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGB5"
  },
  {
    "uniprot": "A4I2G7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2G7"
  },
  {
    "uniprot": "A4I222",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I222"
  },
  {
    "uniprot": "A4ICJ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICJ2"
  },
  {
    "uniprot": "A4HRI2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRI2"
  },
  {
    "uniprot": "A4IDJ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDJ4"
  },
  {
    "uniprot": "A4IBY1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBY1"
  },
  {
    "uniprot": "A4I5K3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5K3"
  },
  {
    "uniprot": "A4I1M2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1M2"
  },
  {
    "uniprot": "A4HRE9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRE9"
  },
  {
    "uniprot": "A4ICL1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICL1"
  },
  {
    "uniprot": "A4I842",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I842"
  },
  {
    "uniprot": "A4HVS6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVS6"
  },
  {
    "uniprot": "A4HS49",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS49"
  },
  {
    "uniprot": "A4IB72",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB72"
  },
  {
    "uniprot": "A4I3X2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3X2"
  },
  {
    "uniprot": "A0A381MS01",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MS01"
  },
  {
    "uniprot": "A4I9S7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9S7"
  },
  {
    "uniprot": "A4IAF8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAF8"
  },
  {
    "uniprot": "E9AG24",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG24"
  },
  {
    "uniprot": "A4HWS1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWS1"
  },
  {
    "uniprot": "A4IBC4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBC4"
  },
  {
    "uniprot": "E9AGR2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGR2"
  },
  {
    "uniprot": "A4HTI7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTI7"
  },
  {
    "uniprot": "A4I944",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I944"
  },
  {
    "uniprot": "A4I585",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I585"
  },
  {
    "uniprot": "E9AGN4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGN4"
  },
  {
    "uniprot": "A4I9K4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9K4"
  },
  {
    "uniprot": "A4HXS3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXS3"
  },
  {
    "uniprot": "A4HUC5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUC5"
  },
  {
    "uniprot": "A4HZV7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZV7"
  },
  {
    "uniprot": "A4HTY5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTY5"
  },
  {
    "uniprot": "A4IBI5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBI5"
  },
  {
    "uniprot": "A4HUC1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUC1"
  },
  {
    "uniprot": "A4I6C2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6C2"
  },
  {
    "uniprot": "A4I8L2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8L2"
  },
  {
    "uniprot": "A4I0E4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0E4"
  },
  {
    "uniprot": "A4I5N9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5N9"
  },
  {
    "uniprot": "A4I5Z3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Z3"
  },
  {
    "uniprot": "A4I5W7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5W7"
  },
  {
    "uniprot": "E9AGU7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGU7"
  },
  {
    "uniprot": "A4HWL5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWL5"
  },
  {
    "uniprot": "A4IDV1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDV1"
  },
  {
    "uniprot": "A4HTW8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTW8"
  },
  {
    "uniprot": "E9AG58",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG58"
  },
  {
    "uniprot": "A0A381MWU3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MWU3"
  },
  {
    "uniprot": "A4HZS1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZS1"
  },
  {
    "uniprot": "A4I150",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I150"
  },
  {
    "uniprot": "A4I512",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I512"
  },
  {
    "uniprot": "A4HX86",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX86"
  },
  {
    "uniprot": "A4IB10",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB10"
  },
  {
    "uniprot": "A4ID15",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID15"
  },
  {
    "uniprot": "A4IDM9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDM9"
  },
  {
    "uniprot": "Q2PD92",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q2PD92"
  },
  {
    "uniprot": "A4IE47",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE47"
  },
  {
    "uniprot": "A4I0S3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0S3"
  },
  {
    "uniprot": "A4HRZ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRZ9"
  },
  {
    "uniprot": "A4I8X4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8X4"
  },
  {
    "uniprot": "A4HV50",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV50"
  },
  {
    "uniprot": "A4I0X5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0X5"
  },
  {
    "uniprot": "A4I8A3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8A3"
  },
  {
    "uniprot": "A4IA21",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA21"
  },
  {
    "uniprot": "A4HY10",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY10"
  },
  {
    "uniprot": "A4HV35",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV35"
  },
  {
    "uniprot": "A4HXH8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXH8"
  },
  {
    "uniprot": "A4HRI1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRI1"
  },
  {
    "uniprot": "A4HYR3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYR3"
  },
  {
    "uniprot": "A4I0F4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0F4"
  },
  {
    "uniprot": "A4I6Q9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Q9"
  },
  {
    "uniprot": "A4I975",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I975"
  },
  {
    "uniprot": "A4I3K8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3K8"
  },
  {
    "uniprot": "A4I3T6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3T6"
  },
  {
    "uniprot": "E9AGX0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGX0"
  },
  {
    "uniprot": "A4I5Q9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Q9"
  },
  {
    "uniprot": "A4ICL9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICL9"
  },
  {
    "uniprot": "A4HZX2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZX2"
  },
  {
    "uniprot": "A4I1A2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1A2"
  },
  {
    "uniprot": "A4I5A1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5A1"
  },
  {
    "uniprot": "A4I3B0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3B0"
  },
  {
    "uniprot": "A4IBF9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBF9"
  },
  {
    "uniprot": "A4HZA8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZA8"
  },
  {
    "uniprot": "A4IAK0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAK0"
  },
  {
    "uniprot": "A4HTT2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTT2"
  },
  {
    "uniprot": "E9AGN3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGN3"
  },
  {
    "uniprot": "A4I3R7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3R7"
  },
  {
    "uniprot": "A4HSX1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSX1"
  },
  {
    "uniprot": "A4I0R6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0R6"
  },
  {
    "uniprot": "A4I4V0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4V0"
  },
  {
    "uniprot": "A4HVT2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVT2"
  },
  {
    "uniprot": "A4HS76",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS76"
  },
  {
    "uniprot": "A4HS25",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS25"
  },
  {
    "uniprot": "A4IBT2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBT2"
  },
  {
    "uniprot": "A4I7Z3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Z3"
  },
  {
    "uniprot": "A4HWJ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWJ4"
  },
  {
    "uniprot": "A4I042",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I042"
  },
  {
    "uniprot": "Q8MQV3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q8MQV3"
  },
  {
    "uniprot": "A4I1H7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1H7"
  },
  {
    "uniprot": "A4HT60",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT60"
  },
  {
    "uniprot": "A4IDF2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDF2"
  },
  {
    "uniprot": "A4HWG5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWG5"
  },
  {
    "uniprot": "A4I4W7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4W7"
  },
  {
    "uniprot": "A4I4Z6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Z6"
  },
  {
    "uniprot": "A4HYP8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYP8"
  },
  {
    "uniprot": "A4HS33",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS33"
  },
  {
    "uniprot": "A4I1A1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1A1"
  },
  {
    "uniprot": "A4HU04",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU04"
  },
  {
    "uniprot": "A4HTB7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTB7"
  },
  {
    "uniprot": "A4I8V8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8V8"
  },
  {
    "uniprot": "A4IC43",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC43"
  },
  {
    "uniprot": "A4IDW7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDW7"
  },
  {
    "uniprot": "A4I517",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I517"
  },
  {
    "uniprot": "A4HW95",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW95"
  },
  {
    "uniprot": "A4I9V3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9V3"
  },
  {
    "uniprot": "A4HUX8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUX8"
  },
  {
    "uniprot": "A4HRR3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRR3"
  },
  {
    "uniprot": "A4HYV2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYV2"
  },
  {
    "uniprot": "E9AG50",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG50"
  },
  {
    "uniprot": "A4I4B1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4B1"
  },
  {
    "uniprot": "A4IA79",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA79"
  },
  {
    "uniprot": "A4ICS4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICS4"
  },
  {
    "uniprot": "A4I4U7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4U7"
  },
  {
    "uniprot": "A4HZQ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZQ3"
  },
  {
    "uniprot": "A4I2C9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2C9"
  },
  {
    "uniprot": "A4IAI9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAI9"
  },
  {
    "uniprot": "A4ICV1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICV1"
  },
  {
    "uniprot": "A4HYU2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYU2"
  },
  {
    "uniprot": "A4IBN7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBN7"
  },
  {
    "uniprot": "A4I1H4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1H4"
  },
  {
    "uniprot": "A4HYN3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYN3"
  },
  {
    "uniprot": "A4I9M3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9M3"
  },
  {
    "uniprot": "A4I3M0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3M0"
  },
  {
    "uniprot": "A4HTP9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTP9"
  },
  {
    "uniprot": "A4HWH7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWH7"
  },
  {
    "uniprot": "A4I963",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I963"
  },
  {
    "uniprot": "A4I2H7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2H7"
  },
  {
    "uniprot": "A4IDH6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDH6"
  },
  {
    "uniprot": "A4HVI0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVI0"
  },
  {
    "uniprot": "A4HRN8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRN8"
  },
  {
    "uniprot": "A4HUH8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUH8"
  },
  {
    "uniprot": "A4I885",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I885"
  },
  {
    "uniprot": "A4I4G1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4G1"
  },
  {
    "uniprot": "E9AGD3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGD3"
  },
  {
    "uniprot": "E9AGV8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGV8"
  },
  {
    "uniprot": "A4I227",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I227"
  },
  {
    "uniprot": "A4IB35",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB35"
  },
  {
    "uniprot": "A4HUI4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUI4"
  },
  {
    "uniprot": "A4ID96",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID96"
  },
  {
    "uniprot": "A4IAY3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAY3"
  },
  {
    "uniprot": "A4I7W5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7W5"
  },
  {
    "uniprot": "A4I1E1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1E1"
  },
  {
    "uniprot": "A4HWF0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWF0"
  },
  {
    "uniprot": "A4IB60",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB60"
  },
  {
    "uniprot": "A4HYA2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYA2"
  },
  {
    "uniprot": "A4I1P1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1P1"
  },
  {
    "uniprot": "A4ID66",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID66"
  },
  {
    "uniprot": "A4I7K0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7K0"
  },
  {
    "uniprot": "A4IBT8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBT8"
  },
  {
    "uniprot": "A4IDH0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDH0"
  },
  {
    "uniprot": "E9AH77",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH77"
  },
  {
    "uniprot": "A4I510",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I510"
  },
  {
    "uniprot": "A4HSZ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSZ5"
  },
  {
    "uniprot": "A4HSG8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSG8"
  },
  {
    "uniprot": "A4I4A1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4A1"
  },
  {
    "uniprot": "A4HZ58",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ58"
  },
  {
    "uniprot": "A4HXD6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXD6"
  },
  {
    "uniprot": "A4I4U0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4U0"
  },
  {
    "uniprot": "A4I4B7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4B7"
  },
  {
    "uniprot": "A4I619",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I619"
  },
  {
    "uniprot": "E9AHL7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHL7"
  },
  {
    "uniprot": "E9AHJ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHJ0"
  },
  {
    "uniprot": "A4HTW6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTW6"
  },
  {
    "uniprot": "A4I3T1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3T1"
  },
  {
    "uniprot": "A4I7P1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7P1"
  },
  {
    "uniprot": "A4I511",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I511"
  },
  {
    "uniprot": "A4HUL0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUL0"
  },
  {
    "uniprot": "A4I2R0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2R0"
  },
  {
    "uniprot": "A4I1V4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1V4"
  },
  {
    "uniprot": "A4HX33",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX33"
  },
  {
    "uniprot": "A4HZ80",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ80"
  },
  {
    "uniprot": "A4ICB6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICB6"
  },
  {
    "uniprot": "A4HRS3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRS3"
  },
  {
    "uniprot": "A4I8E3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8E3"
  },
  {
    "uniprot": "A4I025",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I025"
  },
  {
    "uniprot": "A4IDJ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDJ0"
  },
  {
    "uniprot": "A4IBL6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBL6"
  },
  {
    "uniprot": "A4I5Q7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Q7"
  },
  {
    "uniprot": "A4I9D2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9D2"
  },
  {
    "uniprot": "A4I8A0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8A0"
  },
  {
    "uniprot": "A4IDC5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDC5"
  },
  {
    "uniprot": "A4I0T7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0T7"
  },
  {
    "uniprot": "A4ICQ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICQ3"
  },
  {
    "uniprot": "A4I652",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I652"
  },
  {
    "uniprot": "A4HY38",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY38"
  },
  {
    "uniprot": "A4I1W1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1W1"
  },
  {
    "uniprot": "A4HT90",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT90"
  },
  {
    "uniprot": "A4HUS3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUS3"
  },
  {
    "uniprot": "A4IDI4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDI4"
  },
  {
    "uniprot": "A4HVL2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVL2"
  },
  {
    "uniprot": "A4HVQ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVQ3"
  },
  {
    "uniprot": "A4HXU6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXU6"
  },
  {
    "uniprot": "A4ICH5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICH5"
  },
  {
    "uniprot": "A4HVM8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVM8"
  },
  {
    "uniprot": "A4I8F2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8F2"
  },
  {
    "uniprot": "A4I180",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I180"
  },
  {
    "uniprot": "A4IC26",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC26"
  },
  {
    "uniprot": "A4HXE4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXE4"
  },
  {
    "uniprot": "E9AHR7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHR7"
  },
  {
    "uniprot": "A4HXN3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXN3"
  },
  {
    "uniprot": "E9AGU4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGU4"
  },
  {
    "uniprot": "A4I3R5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3R5"
  },
  {
    "uniprot": "A4I1Z3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Z3"
  },
  {
    "uniprot": "A4I9Z6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Z6"
  },
  {
    "uniprot": "A4I8M1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8M1"
  },
  {
    "uniprot": "A4ICB5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICB5"
  },
  {
    "uniprot": "A4HRI5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRI5"
  },
  {
    "uniprot": "A4I7T1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7T1"
  },
  {
    "uniprot": "A4HTP3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTP3"
  },
  {
    "uniprot": "A4HUX6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUX6"
  },
  {
    "uniprot": "A4HVY7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVY7"
  },
  {
    "uniprot": "A4I395",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I395"
  },
  {
    "uniprot": "A4I115",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I115"
  },
  {
    "uniprot": "A4I097",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I097"
  },
  {
    "uniprot": "A4I3A2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3A2"
  },
  {
    "uniprot": "A4IAV4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAV4"
  },
  {
    "uniprot": "A4HWD8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWD8"
  },
  {
    "uniprot": "A4HW10",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW10"
  },
  {
    "uniprot": "E9AH25",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH25"
  },
  {
    "uniprot": "A4HV30",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV30"
  },
  {
    "uniprot": "A4IDC3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDC3"
  },
  {
    "uniprot": "A4HXA8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXA8"
  },
  {
    "uniprot": "A4I8N1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8N1"
  },
  {
    "uniprot": "A4I2C5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2C5"
  },
  {
    "uniprot": "A4I8Z4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Z4"
  },
  {
    "uniprot": "E9AH49",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH49"
  },
  {
    "uniprot": "A4I2T0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2T0"
  },
  {
    "uniprot": "A4IBX3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBX3"
  },
  {
    "uniprot": "A4HXS2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXS2"
  },
  {
    "uniprot": "E9AGC9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGC9"
  },
  {
    "uniprot": "A4I978",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I978"
  },
  {
    "uniprot": "A4HZF6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZF6"
  },
  {
    "uniprot": "A4I969",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I969"
  },
  {
    "uniprot": "A4HV58",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV58"
  },
  {
    "uniprot": "A4I3M9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3M9"
  },
  {
    "uniprot": "E9AHZ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHZ5"
  },
  {
    "uniprot": "A4HRI9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRI9"
  },
  {
    "uniprot": "A4IAD7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAD7"
  },
  {
    "uniprot": "A4IBZ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBZ6"
  },
  {
    "uniprot": "A4HVU5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVU5"
  },
  {
    "uniprot": "A4HZW5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZW5"
  },
  {
    "uniprot": "E9AHK8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHK8"
  },
  {
    "uniprot": "A4HYS7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYS7"
  },
  {
    "uniprot": "A4HW04",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW04"
  },
  {
    "uniprot": "A4HV15",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV15"
  },
  {
    "uniprot": "A4HUG0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUG0"
  },
  {
    "uniprot": "E9AG85",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG85"
  },
  {
    "uniprot": "A4I114",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I114"
  },
  {
    "uniprot": "A4HUB8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUB8"
  },
  {
    "uniprot": "A4I9B4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9B4"
  },
  {
    "uniprot": "A4HWF8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWF8"
  },
  {
    "uniprot": "A4I4Q0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Q0"
  },
  {
    "uniprot": "A4I4S2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4S2"
  },
  {
    "uniprot": "A4HTJ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTJ7"
  },
  {
    "uniprot": "A4HZM6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZM6"
  },
  {
    "uniprot": "Q9GNZ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q9GNZ8"
  },
  {
    "uniprot": "A4I8J4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8J4"
  },
  {
    "uniprot": "A4I6P8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6P8"
  },
  {
    "uniprot": "E9AG29",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG29"
  },
  {
    "uniprot": "A4IDY7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDY7"
  },
  {
    "uniprot": "A4I644",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I644"
  },
  {
    "uniprot": "A4I894",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I894"
  },
  {
    "uniprot": "A4HU49",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU49"
  },
  {
    "uniprot": "A4I223",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I223"
  },
  {
    "uniprot": "A4HY02",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY02"
  },
  {
    "uniprot": "A4I7G5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7G5"
  },
  {
    "uniprot": "A4HXR6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXR6"
  },
  {
    "uniprot": "A4I2B7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2B7"
  },
  {
    "uniprot": "E9AH55",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH55"
  },
  {
    "uniprot": "A4HY60",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY60"
  },
  {
    "uniprot": "A4I7J1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7J1"
  },
  {
    "uniprot": "A4HYR2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYR2"
  },
  {
    "uniprot": "A4IAE3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAE3"
  },
  {
    "uniprot": "A4HTZ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTZ5"
  },
  {
    "uniprot": "A4I0Q4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Q4"
  },
  {
    "uniprot": "E9AGB4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGB4"
  },
  {
    "uniprot": "A4HSK0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSK0"
  },
  {
    "uniprot": "A4ICN3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICN3"
  },
  {
    "uniprot": "A4HXU9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXU9"
  },
  {
    "uniprot": "A4HSM6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSM6"
  },
  {
    "uniprot": "A4I0D5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0D5"
  },
  {
    "uniprot": "A4HTR8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTR8"
  },
  {
    "uniprot": "A4I8W3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8W3"
  },
  {
    "uniprot": "A4I7V6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7V6"
  },
  {
    "uniprot": "A4ICR5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICR5"
  },
  {
    "uniprot": "A4I8H8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8H8"
  },
  {
    "uniprot": "E9AHT6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHT6"
  },
  {
    "uniprot": "A4HXQ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXQ1"
  },
  {
    "uniprot": "A4I6E4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6E4"
  },
  {
    "uniprot": "A4HTF6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTF6"
  },
  {
    "uniprot": "A4HXX5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXX5"
  },
  {
    "uniprot": "A2TEF2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A2TEF2"
  },
  {
    "uniprot": "A4I2I7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2I7"
  },
  {
    "uniprot": "A4I843",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I843"
  },
  {
    "uniprot": "A4I6P0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6P0"
  },
  {
    "uniprot": "A4IA49",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA49"
  },
  {
    "uniprot": "E9AHE2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHE2"
  },
  {
    "uniprot": "A4I059",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I059"
  },
  {
    "uniprot": "E9AHR1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHR1"
  },
  {
    "uniprot": "A4I648",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I648"
  },
  {
    "uniprot": "A4HZ87",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ87"
  },
  {
    "uniprot": "A4I2F3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2F3"
  },
  {
    "uniprot": "A4HVV2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVV2"
  },
  {
    "uniprot": "A4I521",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I521"
  },
  {
    "uniprot": "A4HSJ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSJ3"
  },
  {
    "uniprot": "A4I2P8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2P8"
  },
  {
    "uniprot": "A4I738",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I738"
  },
  {
    "uniprot": "E9AG35",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG35"
  },
  {
    "uniprot": "A4I2U3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2U3"
  },
  {
    "uniprot": "A4HU68",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU68"
  },
  {
    "uniprot": "A4I4J2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4J2"
  },
  {
    "uniprot": "A4HSS2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSS2"
  },
  {
    "uniprot": "A4ICU2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICU2"
  },
  {
    "uniprot": "A4HS78",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS78"
  },
  {
    "uniprot": "E9AHW6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHW6"
  },
  {
    "uniprot": "A4HYE3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYE3"
  },
  {
    "uniprot": "A4IDQ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDQ4"
  },
  {
    "uniprot": "A4I5I8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5I8"
  },
  {
    "uniprot": "A4HSY9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSY9"
  },
  {
    "uniprot": "A4IDR8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDR8"
  },
  {
    "uniprot": "A4IC33",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC33"
  },
  {
    "uniprot": "A4HW24",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW24"
  },
  {
    "uniprot": "A4IAG3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAG3"
  },
  {
    "uniprot": "A4I1C0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1C0"
  },
  {
    "uniprot": "A4I6T7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6T7"
  },
  {
    "uniprot": "A4I840",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I840"
  },
  {
    "uniprot": "A4HWQ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWQ0"
  },
  {
    "uniprot": "A4I301",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I301"
  },
  {
    "uniprot": "E9AHC7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHC7"
  },
  {
    "uniprot": "A4I3T3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3T3"
  },
  {
    "uniprot": "A4IA96",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA96"
  },
  {
    "uniprot": "E9AGG6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGG6"
  },
  {
    "uniprot": "A4I8U9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8U9"
  },
  {
    "uniprot": "A4IBS4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBS4"
  },
  {
    "uniprot": "A4I2X5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2X5"
  },
  {
    "uniprot": "A4HSK5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSK5"
  },
  {
    "uniprot": "A4HV26",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV26"
  },
  {
    "uniprot": "A4IAZ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAZ6"
  },
  {
    "uniprot": "A4HUI2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUI2"
  },
  {
    "uniprot": "A0A381MJI1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MJI1"
  },
  {
    "uniprot": "A4IAE0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAE0"
  },
  {
    "uniprot": "A4I5I1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5I1"
  },
  {
    "uniprot": "A4HTX7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTX7"
  },
  {
    "uniprot": "A4HYK3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYK3"
  },
  {
    "uniprot": "A4I595",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I595"
  },
  {
    "uniprot": "A4HWF6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWF6"
  },
  {
    "uniprot": "A4I1H8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1H8"
  },
  {
    "uniprot": "A4I5M1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5M1"
  },
  {
    "uniprot": "A4IDK5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDK5"
  },
  {
    "uniprot": "A4HXT7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXT7"
  },
  {
    "uniprot": "A4HRM6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRM6"
  },
  {
    "uniprot": "A4HT51",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT51"
  },
  {
    "uniprot": "E9AGI0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGI0"
  },
  {
    "uniprot": "A4ICM0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICM0"
  },
  {
    "uniprot": "A4I2F4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2F4"
  },
  {
    "uniprot": "A4HZQ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZQ1"
  },
  {
    "uniprot": "A4I5D4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5D4"
  },
  {
    "uniprot": "A4I0L9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0L9"
  },
  {
    "uniprot": "A4IDT7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDT7"
  },
  {
    "uniprot": "A4I8V1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8V1"
  },
  {
    "uniprot": "A4I9F7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9F7"
  },
  {
    "uniprot": "A4I6A8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6A8"
  },
  {
    "uniprot": "A4ICR8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICR8"
  },
  {
    "uniprot": "A4I500",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I500"
  },
  {
    "uniprot": "A4I264",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I264"
  },
  {
    "uniprot": "E9AGF9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGF9"
  },
  {
    "uniprot": "A4ID07",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID07"
  },
  {
    "uniprot": "A4HSN4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSN4"
  },
  {
    "uniprot": "A4I312",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I312"
  },
  {
    "uniprot": "A4HTF5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTF5"
  },
  {
    "uniprot": "A4IE65",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE65"
  },
  {
    "uniprot": "A4HWU1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWU1"
  },
  {
    "uniprot": "A4IA39",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA39"
  },
  {
    "uniprot": "A4HRQ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRQ8"
  },
  {
    "uniprot": "A4I4N7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4N7"
  },
  {
    "uniprot": "A4I751",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I751"
  },
  {
    "uniprot": "A4I839",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I839"
  },
  {
    "uniprot": "A4HTH6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTH6"
  },
  {
    "uniprot": "A4I2Y5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Y5"
  },
  {
    "uniprot": "A4I5J0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5J0"
  },
  {
    "uniprot": "A4HVI4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVI4"
  },
  {
    "uniprot": "A4I281",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I281"
  },
  {
    "uniprot": "A4I6Z3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Z3"
  },
  {
    "uniprot": "A4HTZ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTZ4"
  },
  {
    "uniprot": "A4I2A0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2A0"
  },
  {
    "uniprot": "A4IDN3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDN3"
  },
  {
    "uniprot": "A4I561",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I561"
  },
  {
    "uniprot": "A4HYX8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYX8"
  },
  {
    "uniprot": "E9AGL6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGL6"
  },
  {
    "uniprot": "A4HRG8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRG8"
  },
  {
    "uniprot": "A4I524",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I524"
  },
  {
    "uniprot": "A4I371",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I371"
  },
  {
    "uniprot": "A4HUS5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUS5"
  },
  {
    "uniprot": "A4HSQ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSQ4"
  },
  {
    "uniprot": "A4HTX6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTX6"
  },
  {
    "uniprot": "A4I2P6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2P6"
  },
  {
    "uniprot": "A4HT92",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT92"
  },
  {
    "uniprot": "A4HWP7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWP7"
  },
  {
    "uniprot": "A4HT23",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT23"
  },
  {
    "uniprot": "A4IA40",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA40"
  },
  {
    "uniprot": "A4HUY4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUY4"
  },
  {
    "uniprot": "E9AHL5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHL5"
  },
  {
    "uniprot": "A4HZJ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZJ4"
  },
  {
    "uniprot": "A4I5E9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5E9"
  },
  {
    "uniprot": "A4HSJ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSJ6"
  },
  {
    "uniprot": "A4IDN9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDN9"
  },
  {
    "uniprot": "A4IDI5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDI5"
  },
  {
    "uniprot": "A4IAF1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAF1"
  },
  {
    "uniprot": "A4HXD1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXD1"
  },
  {
    "uniprot": "A4HZT3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZT3"
  },
  {
    "uniprot": "A4ID76",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID76"
  },
  {
    "uniprot": "A4I6B4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6B4"
  },
  {
    "uniprot": "A4HWD7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWD7"
  },
  {
    "uniprot": "A4I3S9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3S9"
  },
  {
    "uniprot": "A4I1T5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1T5"
  },
  {
    "uniprot": "A4HSP6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSP6"
  },
  {
    "uniprot": "A4HZG6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZG6"
  },
  {
    "uniprot": "A4HSX9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSX9"
  },
  {
    "uniprot": "A4HU00",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU00"
  },
  {
    "uniprot": "A4I6R9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6R9"
  },
  {
    "uniprot": "E9AGR9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGR9"
  },
  {
    "uniprot": "A4I1F0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1F0"
  },
  {
    "uniprot": "A4HSU7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSU7"
  },
  {
    "uniprot": "A4I6P9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6P9"
  },
  {
    "uniprot": "A4I9P9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9P9"
  },
  {
    "uniprot": "A4HWJ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWJ8"
  },
  {
    "uniprot": "A4IE12",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE12"
  },
  {
    "uniprot": "E9AG55",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG55"
  },
  {
    "uniprot": "A4I1N3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1N3"
  },
  {
    "uniprot": "A4HUJ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUJ8"
  },
  {
    "uniprot": "A4IC75",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC75"
  },
  {
    "uniprot": "A4I972",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I972"
  },
  {
    "uniprot": "A4I0R5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0R5"
  },
  {
    "uniprot": "A4HS75",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS75"
  },
  {
    "uniprot": "A4HTU5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTU5"
  },
  {
    "uniprot": "A4HTH5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTH5"
  },
  {
    "uniprot": "E9AHF6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHF6"
  },
  {
    "uniprot": "A4I754",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I754"
  },
  {
    "uniprot": "E9AH28",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH28"
  },
  {
    "uniprot": "A4HSN6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSN6"
  },
  {
    "uniprot": "A4I314",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I314"
  },
  {
    "uniprot": "A4I1A6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1A6"
  },
  {
    "uniprot": "A4I7U8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7U8"
  },
  {
    "uniprot": "A4I9T1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9T1"
  },
  {
    "uniprot": "A4HYV5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYV5"
  },
  {
    "uniprot": "A4I838",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I838"
  },
  {
    "uniprot": "A4HYD7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYD7"
  },
  {
    "uniprot": "A4I4S1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4S1"
  },
  {
    "uniprot": "A4HST3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HST3"
  },
  {
    "uniprot": "A4ICR2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICR2"
  },
  {
    "uniprot": "A4HST6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HST6"
  },
  {
    "uniprot": "A4HVI7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVI7"
  },
  {
    "uniprot": "E9AHX5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHX5"
  },
  {
    "uniprot": "E9AHP1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHP1"
  },
  {
    "uniprot": "A4I507",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I507"
  },
  {
    "uniprot": "A4HTN5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTN5"
  },
  {
    "uniprot": "A4I2M6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2M6"
  },
  {
    "uniprot": "A4IB63",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB63"
  },
  {
    "uniprot": "A4HSL5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSL5"
  },
  {
    "uniprot": "A4HUU4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUU4"
  },
  {
    "uniprot": "A4HU76",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU76"
  },
  {
    "uniprot": "A4I6M9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6M9"
  },
  {
    "uniprot": "A4HZC7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZC7"
  },
  {
    "uniprot": "A4HTH4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTH4"
  },
  {
    "uniprot": "A4ICS8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICS8"
  },
  {
    "uniprot": "A4ICR7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICR7"
  },
  {
    "uniprot": "A4HYA9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYA9"
  },
  {
    "uniprot": "A4I244",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I244"
  },
  {
    "uniprot": "A4IDJ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDJ3"
  },
  {
    "uniprot": "E9AGA9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGA9"
  },
  {
    "uniprot": "A4I5S5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5S5"
  },
  {
    "uniprot": "A4IAR7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAR7"
  },
  {
    "uniprot": "A4HUV5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUV5"
  },
  {
    "uniprot": "A4I9J9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9J9"
  },
  {
    "uniprot": "A4HUI8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUI8"
  },
  {
    "uniprot": "A4I2F5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2F5"
  },
  {
    "uniprot": "A4HUZ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUZ9"
  },
  {
    "uniprot": "A4I317",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I317"
  },
  {
    "uniprot": "A4HRX2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRX2"
  },
  {
    "uniprot": "A4I2R5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2R5"
  },
  {
    "uniprot": "A4HZK9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZK9"
  },
  {
    "uniprot": "A4I7Q1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Q1"
  },
  {
    "uniprot": "A4I7P8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7P8"
  },
  {
    "uniprot": "A4HRT6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRT6"
  },
  {
    "uniprot": "A4HS22",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS22"
  },
  {
    "uniprot": "A4HS02",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS02"
  },
  {
    "uniprot": "A4HTY0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTY0"
  },
  {
    "uniprot": "A4IDR4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDR4"
  },
  {
    "uniprot": "A4IDL7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDL7"
  },
  {
    "uniprot": "A4I2N2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2N2"
  },
  {
    "uniprot": "A4I7B0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7B0"
  },
  {
    "uniprot": "A4I4I9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4I9"
  },
  {
    "uniprot": "A4I1C1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1C1"
  },
  {
    "uniprot": "A4HTX9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTX9"
  },
  {
    "uniprot": "E9AGR5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGR5"
  },
  {
    "uniprot": "A4I4Y5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Y5"
  },
  {
    "uniprot": "A4I020",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I020"
  },
  {
    "uniprot": "A4HU72",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU72"
  },
  {
    "uniprot": "A4IAC2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAC2"
  },
  {
    "uniprot": "A4I6L6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6L6"
  },
  {
    "uniprot": "A4HYJ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYJ3"
  },
  {
    "uniprot": "A4I4H7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4H7"
  },
  {
    "uniprot": "A4HZQ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZQ9"
  },
  {
    "uniprot": "A4I8G8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8G8"
  },
  {
    "uniprot": "A4HV59",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV59"
  },
  {
    "uniprot": "A4ICF0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICF0"
  },
  {
    "uniprot": "A4HX11",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX11"
  },
  {
    "uniprot": "A4HZY5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZY5"
  },
  {
    "uniprot": "A4I162",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I162"
  },
  {
    "uniprot": "A4I2K3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2K3"
  },
  {
    "uniprot": "A4I3G7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3G7"
  },
  {
    "uniprot": "A4I442",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I442"
  },
  {
    "uniprot": "E9AHF2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHF2"
  },
  {
    "uniprot": "A4I639",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I639"
  },
  {
    "uniprot": "A4HZE3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZE3"
  },
  {
    "uniprot": "A4I040",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I040"
  },
  {
    "uniprot": "A4ID84",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID84"
  },
  {
    "uniprot": "A4I1D1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1D1"
  },
  {
    "uniprot": "A4IBI6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBI6"
  },
  {
    "uniprot": "A4IA90",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA90"
  },
  {
    "uniprot": "E9AGA2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGA2"
  },
  {
    "uniprot": "A4HU37",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU37"
  },
  {
    "uniprot": "A4IAB2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAB2"
  },
  {
    "uniprot": "A4I635",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I635"
  },
  {
    "uniprot": "A4I913",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I913"
  },
  {
    "uniprot": "A4IAR4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAR4"
  },
  {
    "uniprot": "A4I7C4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7C4"
  },
  {
    "uniprot": "A4I368",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I368"
  },
  {
    "uniprot": "A4HT14",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT14"
  },
  {
    "uniprot": "A4I4S4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4S4"
  },
  {
    "uniprot": "A4HZX7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZX7"
  },
  {
    "uniprot": "A4IB17",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB17"
  },
  {
    "uniprot": "A4I2R9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2R9"
  },
  {
    "uniprot": "A4I2X8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2X8"
  },
  {
    "uniprot": "A4IAQ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAQ6"
  },
  {
    "uniprot": "A4HV51",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV51"
  },
  {
    "uniprot": "A4HX47",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX47"
  },
  {
    "uniprot": "A4I874",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I874"
  },
  {
    "uniprot": "A4I4W5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4W5"
  },
  {
    "uniprot": "A4I1Z0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Z0"
  },
  {
    "uniprot": "A4I1X9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1X9"
  },
  {
    "uniprot": "A4IDK0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDK0"
  },
  {
    "uniprot": "A4HUX5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUX5"
  },
  {
    "uniprot": "A4I3Q4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Q4"
  },
  {
    "uniprot": "A4I9K9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9K9"
  },
  {
    "uniprot": "A4I5Z7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Z7"
  },
  {
    "uniprot": "A4HXN5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXN5"
  },
  {
    "uniprot": "A4I109",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I109"
  },
  {
    "uniprot": "A4HUU2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUU2"
  },
  {
    "uniprot": "A4HZR2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZR2"
  },
  {
    "uniprot": "E9AHS9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHS9"
  },
  {
    "uniprot": "A4I2W3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2W3"
  },
  {
    "uniprot": "A4IC36",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC36"
  },
  {
    "uniprot": "A4I6S8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6S8"
  },
  {
    "uniprot": "A4I324",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I324"
  },
  {
    "uniprot": "A4HYL9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYL9"
  },
  {
    "uniprot": "A4I8G2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8G2"
  },
  {
    "uniprot": "A4I904",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I904"
  },
  {
    "uniprot": "A4I6Q2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Q2"
  },
  {
    "uniprot": "A4I2V2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2V2"
  },
  {
    "uniprot": "A4HZE7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZE7"
  },
  {
    "uniprot": "A4I3K5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3K5"
  },
  {
    "uniprot": "A4I1B0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1B0"
  },
  {
    "uniprot": "A4I268",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I268"
  },
  {
    "uniprot": "A4I611",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I611"
  },
  {
    "uniprot": "A4IDS1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDS1"
  },
  {
    "uniprot": "A4I436",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I436"
  },
  {
    "uniprot": "A4IBZ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBZ8"
  },
  {
    "uniprot": "A4I4T9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4T9"
  },
  {
    "uniprot": "A4IBB2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBB2"
  },
  {
    "uniprot": "A4I0A2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0A2"
  },
  {
    "uniprot": "A4IAD5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAD5"
  },
  {
    "uniprot": "A4HW01",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW01"
  },
  {
    "uniprot": "A4HXA3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXA3"
  },
  {
    "uniprot": "A4I7A4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7A4"
  },
  {
    "uniprot": "A4IDM6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDM6"
  },
  {
    "uniprot": "A4I8I0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8I0"
  },
  {
    "uniprot": "A4IBP7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBP7"
  },
  {
    "uniprot": "A4HYV8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYV8"
  },
  {
    "uniprot": "A0A381MX11",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MX11"
  },
  {
    "uniprot": "A4I5F0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5F0"
  },
  {
    "uniprot": "E9AG87",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG87"
  },
  {
    "uniprot": "Q18L05",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q18L05"
  },
  {
    "uniprot": "A4HT71",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT71"
  },
  {
    "uniprot": "A4IA22",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA22"
  },
  {
    "uniprot": "A4HY58",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY58"
  },
  {
    "uniprot": "A4IAK9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAK9"
  },
  {
    "uniprot": "A4I8K8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8K8"
  },
  {
    "uniprot": "A4I9S1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9S1"
  },
  {
    "uniprot": "A4I0J8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0J8"
  },
  {
    "uniprot": "A4HSA0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSA0"
  },
  {
    "uniprot": "A4I475",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I475"
  },
  {
    "uniprot": "A4HS73",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS73"
  },
  {
    "uniprot": "A4I261",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I261"
  },
  {
    "uniprot": "A4I083",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I083"
  },
  {
    "uniprot": "A4HTW1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTW1"
  },
  {
    "uniprot": "A4I9I6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9I6"
  },
  {
    "uniprot": "E9AHX4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHX4"
  },
  {
    "uniprot": "A4I9F5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9F5"
  },
  {
    "uniprot": "A4I3R2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3R2"
  },
  {
    "uniprot": "A4ICC7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICC7"
  },
  {
    "uniprot": "A4IA14",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA14"
  },
  {
    "uniprot": "A4HZZ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZZ2"
  },
  {
    "uniprot": "A4HWT8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWT8"
  },
  {
    "uniprot": "E9AHV6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHV6"
  },
  {
    "uniprot": "A4I8X7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8X7"
  },
  {
    "uniprot": "A4ICA0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICA0"
  },
  {
    "uniprot": "A4IE20",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE20"
  },
  {
    "uniprot": "A4IAG1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAG1"
  },
  {
    "uniprot": "A4I6I1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6I1"
  },
  {
    "uniprot": "A4HRH7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRH7"
  },
  {
    "uniprot": "A4I3U5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3U5"
  },
  {
    "uniprot": "A4I5E7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5E7"
  },
  {
    "uniprot": "A4IBA1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBA1"
  },
  {
    "uniprot": "A4I2L4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2L4"
  },
  {
    "uniprot": "A4ICB8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICB8"
  },
  {
    "uniprot": "A4IC80",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC80"
  },
  {
    "uniprot": "A4I9Y2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Y2"
  },
  {
    "uniprot": "A4IDN8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDN8"
  },
  {
    "uniprot": "A4HXV8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXV8"
  },
  {
    "uniprot": "A4I1N6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1N6"
  },
  {
    "uniprot": "A4IAP0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAP0"
  },
  {
    "uniprot": "A4I5V2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5V2"
  },
  {
    "uniprot": "E9AHD6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHD6"
  },
  {
    "uniprot": "A4ICW5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICW5"
  },
  {
    "uniprot": "A4I8G1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8G1"
  },
  {
    "uniprot": "A4I7J8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7J8"
  },
  {
    "uniprot": "A4I1U9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1U9"
  },
  {
    "uniprot": "E9AH75",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH75"
  },
  {
    "uniprot": "A4HZP5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZP5"
  },
  {
    "uniprot": "A4I8Q1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Q1"
  },
  {
    "uniprot": "A4I9X7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9X7"
  },
  {
    "uniprot": "A4HXF3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXF3"
  },
  {
    "uniprot": "A4HXP5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXP5"
  },
  {
    "uniprot": "E9AG71",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG71"
  },
  {
    "uniprot": "A4IBI8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBI8"
  },
  {
    "uniprot": "A4I4T5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4T5"
  },
  {
    "uniprot": "A4IA75",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA75"
  },
  {
    "uniprot": "A4I068",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I068"
  },
  {
    "uniprot": "E9AH45",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH45"
  },
  {
    "uniprot": "A4I9S5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9S5"
  },
  {
    "uniprot": "A4I8J1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8J1"
  },
  {
    "uniprot": "A4I0I4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0I4"
  },
  {
    "uniprot": "A4I0L4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0L4"
  },
  {
    "uniprot": "A4ICT6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICT6"
  },
  {
    "uniprot": "A4I1K2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1K2"
  },
  {
    "uniprot": "A4ICB1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICB1"
  },
  {
    "uniprot": "A4HRI6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRI6"
  },
  {
    "uniprot": "A4HTC4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTC4"
  },
  {
    "uniprot": "A4I332",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I332"
  },
  {
    "uniprot": "A4HSK7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSK7"
  },
  {
    "uniprot": "A4I7T4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7T4"
  },
  {
    "uniprot": "A4HVD8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVD8"
  },
  {
    "uniprot": "A4IE09",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE09"
  },
  {
    "uniprot": "A4HWE3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWE3"
  },
  {
    "uniprot": "A4I6B7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6B7"
  },
  {
    "uniprot": "A4HYH4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYH4"
  },
  {
    "uniprot": "A4I418",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I418"
  },
  {
    "uniprot": "A4HS62",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS62"
  },
  {
    "uniprot": "A4ICE9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICE9"
  },
  {
    "uniprot": "A4ID39",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID39"
  },
  {
    "uniprot": "E9AG52",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG52"
  },
  {
    "uniprot": "A4HVT8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVT8"
  },
  {
    "uniprot": "A4ID40",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID40"
  },
  {
    "uniprot": "E9AGP7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGP7"
  },
  {
    "uniprot": "A4HW38",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW38"
  },
  {
    "uniprot": "A4IBC1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBC1"
  },
  {
    "uniprot": "A4HZT4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZT4"
  },
  {
    "uniprot": "A4IBI4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBI4"
  },
  {
    "uniprot": "A4I7R5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7R5"
  },
  {
    "uniprot": "A4HUB6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUB6"
  },
  {
    "uniprot": "A4I938",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I938"
  },
  {
    "uniprot": "A4IA27",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA27"
  },
  {
    "uniprot": "A4HXB6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXB6"
  },
  {
    "uniprot": "A4HZU1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZU1"
  },
  {
    "uniprot": "A4HY20",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY20"
  },
  {
    "uniprot": "A4IA34",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA34"
  },
  {
    "uniprot": "A4I7W4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7W4"
  },
  {
    "uniprot": "A4HSD8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSD8"
  },
  {
    "uniprot": "A4HSR2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSR2"
  },
  {
    "uniprot": "A4HW41",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW41"
  },
  {
    "uniprot": "A4HT40",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT40"
  },
  {
    "uniprot": "A4HSV8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSV8"
  },
  {
    "uniprot": "A4I8Y6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Y6"
  },
  {
    "uniprot": "A4I9P7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9P7"
  },
  {
    "uniprot": "A4I249",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I249"
  },
  {
    "uniprot": "A4I166",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I166"
  },
  {
    "uniprot": "A4I1I6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1I6"
  },
  {
    "uniprot": "A4HSA1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSA1"
  },
  {
    "uniprot": "A4I5S9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5S9"
  },
  {
    "uniprot": "A4I483",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I483"
  },
  {
    "uniprot": "A4HZA9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZA9"
  },
  {
    "uniprot": "A4I749",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I749"
  },
  {
    "uniprot": "A4HYE6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYE6"
  },
  {
    "uniprot": "A4HRR0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRR0"
  },
  {
    "uniprot": "A4ICQ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICQ2"
  },
  {
    "uniprot": "A4I7Z5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Z5"
  },
  {
    "uniprot": "Q7Z1D4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q7Z1D4"
  },
  {
    "uniprot": "A4IBK2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBK2"
  },
  {
    "uniprot": "A4IE34",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE34"
  },
  {
    "uniprot": "A4HVP8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVP8"
  },
  {
    "uniprot": "E9AHK6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHK6"
  },
  {
    "uniprot": "A4HYT3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYT3"
  },
  {
    "uniprot": "A4I563",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I563"
  },
  {
    "uniprot": "A4I190",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I190"
  },
  {
    "uniprot": "A4I912",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I912"
  },
  {
    "uniprot": "A4IBH3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBH3"
  },
  {
    "uniprot": "A4I4S5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4S5"
  },
  {
    "uniprot": "A4I5A8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5A8"
  },
  {
    "uniprot": "A4HXI9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXI9"
  },
  {
    "uniprot": "A4I0U4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0U4"
  },
  {
    "uniprot": "A4I0H5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0H5"
  },
  {
    "uniprot": "A4I9P4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9P4"
  },
  {
    "uniprot": "A4HYI1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYI1"
  },
  {
    "uniprot": "A4I4P1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4P1"
  },
  {
    "uniprot": "A4I4A6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4A6"
  },
  {
    "uniprot": "A4HSJ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSJ4"
  },
  {
    "uniprot": "A4HUE5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUE5"
  },
  {
    "uniprot": "A4HXF1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXF1"
  },
  {
    "uniprot": "A4IDL2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDL2"
  },
  {
    "uniprot": "A0A381MLP4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MLP4"
  },
  {
    "uniprot": "A4I1C6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1C6"
  },
  {
    "uniprot": "E9AHB5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHB5"
  },
  {
    "uniprot": "A4IA62",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA62"
  },
  {
    "uniprot": "A4I0A5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0A5"
  },
  {
    "uniprot": "A4HWA3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWA3"
  },
  {
    "uniprot": "A4I093",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I093"
  },
  {
    "uniprot": "A4HX26",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX26"
  },
  {
    "uniprot": "A4I2M1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2M1"
  },
  {
    "uniprot": "A4IB80",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB80"
  },
  {
    "uniprot": "A4IDX7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDX7"
  },
  {
    "uniprot": "E9AHE4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHE4"
  },
  {
    "uniprot": "A4ICP8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICP8"
  },
  {
    "uniprot": "A4HYH2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYH2"
  },
  {
    "uniprot": "A4HY04",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY04"
  },
  {
    "uniprot": "A4I7M4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7M4"
  },
  {
    "uniprot": "A4HSN9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSN9"
  },
  {
    "uniprot": "A4HY18",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY18"
  },
  {
    "uniprot": "A4I056",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I056"
  },
  {
    "uniprot": "A4HTN0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTN0"
  },
  {
    "uniprot": "A4I660",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I660"
  },
  {
    "uniprot": "E9AHX9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHX9"
  },
  {
    "uniprot": "A4HXS6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXS6"
  },
  {
    "uniprot": "A4I0F2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0F2"
  },
  {
    "uniprot": "A4HZI3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZI3"
  },
  {
    "uniprot": "A4HTB6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTB6"
  },
  {
    "uniprot": "A4HUL5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUL5"
  },
  {
    "uniprot": "A4HW15",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW15"
  },
  {
    "uniprot": "A4IBW4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBW4"
  },
  {
    "uniprot": "A4I9K5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9K5"
  },
  {
    "uniprot": "A4I0L8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0L8"
  },
  {
    "uniprot": "A4I1N0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1N0"
  },
  {
    "uniprot": "A4HVW1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVW1"
  },
  {
    "uniprot": "A4I9L6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9L6"
  },
  {
    "uniprot": "A4I1F6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1F6"
  },
  {
    "uniprot": "A4HZ86",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ86"
  },
  {
    "uniprot": "A4I254",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I254"
  },
  {
    "uniprot": "A4HSD0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSD0"
  },
  {
    "uniprot": "A4HTY1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTY1"
  },
  {
    "uniprot": "A4IBV0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBV0"
  },
  {
    "uniprot": "A4I3A8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3A8"
  },
  {
    "uniprot": "A4HUG4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUG4"
  },
  {
    "uniprot": "A4HZE9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZE9"
  },
  {
    "uniprot": "A4IE13",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE13"
  },
  {
    "uniprot": "A4HVK1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVK1"
  },
  {
    "uniprot": "A4I949",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I949"
  },
  {
    "uniprot": "A4IC81",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC81"
  },
  {
    "uniprot": "A4IAH2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAH2"
  },
  {
    "uniprot": "A4I4N1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4N1"
  },
  {
    "uniprot": "A4HTS4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTS4"
  },
  {
    "uniprot": "A4ICD0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICD0"
  },
  {
    "uniprot": "A4I5K0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5K0"
  },
  {
    "uniprot": "A4HZP3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZP3"
  },
  {
    "uniprot": "A4IAD8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAD8"
  },
  {
    "uniprot": "A4HU60",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU60"
  },
  {
    "uniprot": "A4HSD5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSD5"
  },
  {
    "uniprot": "A4I3L9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3L9"
  },
  {
    "uniprot": "E9AGE9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGE9"
  },
  {
    "uniprot": "A4IDF6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDF6"
  },
  {
    "uniprot": "A4ICA2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICA2"
  },
  {
    "uniprot": "A4HZA4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZA4"
  },
  {
    "uniprot": "A4I5Z2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Z2"
  },
  {
    "uniprot": "A4HUB7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUB7"
  },
  {
    "uniprot": "A4I8C7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8C7"
  },
  {
    "uniprot": "A4I1H9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1H9"
  },
  {
    "uniprot": "A4HV34",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV34"
  },
  {
    "uniprot": "A4HYM0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYM0"
  },
  {
    "uniprot": "E9AH15",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH15"
  },
  {
    "uniprot": "A4I858",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I858"
  },
  {
    "uniprot": "A4HX18",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX18"
  },
  {
    "uniprot": "A4HSW3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSW3"
  },
  {
    "uniprot": "A4HY74",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY74"
  },
  {
    "uniprot": "A4I5R8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5R8"
  },
  {
    "uniprot": "A4IDZ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDZ8"
  },
  {
    "uniprot": "A4I5G0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5G0"
  },
  {
    "uniprot": "E9AHU8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHU8"
  },
  {
    "uniprot": "A4HXW8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXW8"
  },
  {
    "uniprot": "A4I7K6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7K6"
  },
  {
    "uniprot": "A4I657",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I657"
  },
  {
    "uniprot": "A4IC57",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC57"
  },
  {
    "uniprot": "A4I9J1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9J1"
  },
  {
    "uniprot": "E9AG37",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG37"
  },
  {
    "uniprot": "A4I9W7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9W7"
  },
  {
    "uniprot": "A4I6V1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6V1"
  },
  {
    "uniprot": "A4ICF4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICF4"
  },
  {
    "uniprot": "A4HZ33",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ33"
  },
  {
    "uniprot": "A4I7Q7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Q7"
  },
  {
    "uniprot": "A4I629",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I629"
  },
  {
    "uniprot": "A4IBE6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBE6"
  },
  {
    "uniprot": "A4HUB4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUB4"
  },
  {
    "uniprot": "A4HXT0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXT0"
  },
  {
    "uniprot": "A4HZ06",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ06"
  },
  {
    "uniprot": "A4HS72",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS72"
  },
  {
    "uniprot": "A4I831",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I831"
  },
  {
    "uniprot": "A4I6T2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6T2"
  },
  {
    "uniprot": "A4HU71",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU71"
  },
  {
    "uniprot": "A4HVM5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVM5"
  },
  {
    "uniprot": "A4HUR9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUR9"
  },
  {
    "uniprot": "A4HRQ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRQ9"
  },
  {
    "uniprot": "A4HUG1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUG1"
  },
  {
    "uniprot": "A4HT54",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT54"
  },
  {
    "uniprot": "A4IC31",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC31"
  },
  {
    "uniprot": "A4ICW4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICW4"
  },
  {
    "uniprot": "A4IE22",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE22"
  },
  {
    "uniprot": "A4HUS4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUS4"
  },
  {
    "uniprot": "A4I6K7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6K7"
  },
  {
    "uniprot": "A4IE37",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE37"
  },
  {
    "uniprot": "A4IBU8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBU8"
  },
  {
    "uniprot": "A4HUF6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUF6"
  },
  {
    "uniprot": "A4IDH4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDH4"
  },
  {
    "uniprot": "A4HZA0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZA0"
  },
  {
    "uniprot": "A4IDD7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDD7"
  },
  {
    "uniprot": "A4I0B6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0B6"
  },
  {
    "uniprot": "A4HWM6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWM6"
  },
  {
    "uniprot": "A4I5I4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5I4"
  },
  {
    "uniprot": "A4HYS1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYS1"
  },
  {
    "uniprot": "E9AGZ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGZ8"
  },
  {
    "uniprot": "E9AHL3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHL3"
  },
  {
    "uniprot": "A4HXW2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXW2"
  },
  {
    "uniprot": "A4HWB8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWB8"
  },
  {
    "uniprot": "A4IDZ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDZ9"
  },
  {
    "uniprot": "A4HTA0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTA0"
  },
  {
    "uniprot": "A4I7T7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7T7"
  },
  {
    "uniprot": "A4ICX2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICX2"
  },
  {
    "uniprot": "A4I5A4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5A4"
  },
  {
    "uniprot": "A4I063",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I063"
  },
  {
    "uniprot": "A4HU24",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU24"
  },
  {
    "uniprot": "E9AHL2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHL2"
  },
  {
    "uniprot": "A4IBT3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBT3"
  },
  {
    "uniprot": "A4IDD9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDD9"
  },
  {
    "uniprot": "A4I2V0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2V0"
  },
  {
    "uniprot": "A4HYT1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYT1"
  },
  {
    "uniprot": "A4HZ42",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ42"
  },
  {
    "uniprot": "A4IA98",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA98"
  },
  {
    "uniprot": "A4IB88",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB88"
  },
  {
    "uniprot": "A4I8H0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8H0"
  },
  {
    "uniprot": "A4HYL4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYL4"
  },
  {
    "uniprot": "A4HYN0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYN0"
  },
  {
    "uniprot": "E9AH92",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH92"
  },
  {
    "uniprot": "A4I3I9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3I9"
  },
  {
    "uniprot": "E9AG94",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG94"
  },
  {
    "uniprot": "A4I996",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I996"
  },
  {
    "uniprot": "A4I006",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I006"
  },
  {
    "uniprot": "A4I4J0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4J0"
  },
  {
    "uniprot": "A4I0N8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0N8"
  },
  {
    "uniprot": "A4I228",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I228"
  },
  {
    "uniprot": "A4IB30",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB30"
  },
  {
    "uniprot": "A4HUT6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUT6"
  },
  {
    "uniprot": "A4I2Y6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Y6"
  },
  {
    "uniprot": "A4I8G3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8G3"
  },
  {
    "uniprot": "A4ICK1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICK1"
  },
  {
    "uniprot": "A4HRQ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRQ6"
  },
  {
    "uniprot": "A4I9B0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9B0"
  },
  {
    "uniprot": "E9AGX8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGX8"
  },
  {
    "uniprot": "A4I655",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I655"
  },
  {
    "uniprot": "A4IBK7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBK7"
  },
  {
    "uniprot": "A4IC67",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC67"
  },
  {
    "uniprot": "A4HS65",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS65"
  },
  {
    "uniprot": "A4I4Y1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Y1"
  },
  {
    "uniprot": "E9AGU9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGU9"
  },
  {
    "uniprot": "A4I743",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I743"
  },
  {
    "uniprot": "A4I964",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I964"
  },
  {
    "uniprot": "A4IBH8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBH8"
  },
  {
    "uniprot": "A4I3F8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3F8"
  },
  {
    "uniprot": "E9AGK5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGK5"
  },
  {
    "uniprot": "A4HSV3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSV3"
  },
  {
    "uniprot": "A4I1R1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1R1"
  },
  {
    "uniprot": "A4I983",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I983"
  },
  {
    "uniprot": "A4I366",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I366"
  },
  {
    "uniprot": "A4HXZ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXZ0"
  },
  {
    "uniprot": "A4I523",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I523"
  },
  {
    "uniprot": "A4HX67",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX67"
  },
  {
    "uniprot": "A4HVR8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVR8"
  },
  {
    "uniprot": "A4I6N2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6N2"
  },
  {
    "uniprot": "A4IDJ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDJ7"
  },
  {
    "uniprot": "A4IBK5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBK5"
  },
  {
    "uniprot": "A4IBW6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBW6"
  },
  {
    "uniprot": "A4HRD1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRD1"
  },
  {
    "uniprot": "A4HST0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HST0"
  },
  {
    "uniprot": "A4I3L4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3L4"
  },
  {
    "uniprot": "A4HZH7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZH7"
  },
  {
    "uniprot": "A4I4E5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4E5"
  },
  {
    "uniprot": "A4I7S8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7S8"
  },
  {
    "uniprot": "A4I3L6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3L6"
  },
  {
    "uniprot": "A4HWC9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWC9"
  },
  {
    "uniprot": "A4I2H0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2H0"
  },
  {
    "uniprot": "A4IC82",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC82"
  },
  {
    "uniprot": "A4HXB5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXB5"
  },
  {
    "uniprot": "A4HX96",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX96"
  },
  {
    "uniprot": "A4I886",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I886"
  },
  {
    "uniprot": "A4HXQ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXQ5"
  },
  {
    "uniprot": "A4HSY5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSY5"
  },
  {
    "uniprot": "A4I4E2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4E2"
  },
  {
    "uniprot": "A4I1L2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1L2"
  },
  {
    "uniprot": "A4HYJ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYJ5"
  },
  {
    "uniprot": "A4HT91",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT91"
  },
  {
    "uniprot": "A4I1U3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1U3"
  },
  {
    "uniprot": "A4IC58",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC58"
  },
  {
    "uniprot": "A4I770",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I770"
  },
  {
    "uniprot": "A4HUT8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUT8"
  },
  {
    "uniprot": "A4I7M2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7M2"
  },
  {
    "uniprot": "A4ICC3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICC3"
  },
  {
    "uniprot": "A4IBH9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBH9"
  },
  {
    "uniprot": "A4I5P3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5P3"
  },
  {
    "uniprot": "A4I8Q5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Q5"
  },
  {
    "uniprot": "A4I8H7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8H7"
  },
  {
    "uniprot": "A4ID31",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID31"
  },
  {
    "uniprot": "A4I0H1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0H1"
  },
  {
    "uniprot": "A4HSR8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSR8"
  },
  {
    "uniprot": "A4I8E5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8E5"
  },
  {
    "uniprot": "A4HY82",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY82"
  },
  {
    "uniprot": "E9AG81",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG81"
  },
  {
    "uniprot": "A4I5E4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5E4"
  },
  {
    "uniprot": "A4I4S6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4S6"
  },
  {
    "uniprot": "A4HYJ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYJ4"
  },
  {
    "uniprot": "A4IC99",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC99"
  },
  {
    "uniprot": "A4I434",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I434"
  },
  {
    "uniprot": "A4I876",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I876"
  },
  {
    "uniprot": "A4IC94",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC94"
  },
  {
    "uniprot": "A4IDI6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDI6"
  },
  {
    "uniprot": "A4I1U5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1U5"
  },
  {
    "uniprot": "A4I830",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I830"
  },
  {
    "uniprot": "A4HXN4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXN4"
  },
  {
    "uniprot": "A4I3W3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3W3"
  },
  {
    "uniprot": "A4HYZ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYZ7"
  },
  {
    "uniprot": "E9AGD0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGD0"
  },
  {
    "uniprot": "A4I676",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I676"
  },
  {
    "uniprot": "A4I2S3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2S3"
  },
  {
    "uniprot": "A4I277",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I277"
  },
  {
    "uniprot": "A4HSY7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSY7"
  },
  {
    "uniprot": "A4I3S3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3S3"
  },
  {
    "uniprot": "A4HWM9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWM9"
  },
  {
    "uniprot": "A4HTE9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTE9"
  },
  {
    "uniprot": "A4I850",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I850"
  },
  {
    "uniprot": "A4HU13",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU13"
  },
  {
    "uniprot": "A4I6Y8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Y8"
  },
  {
    "uniprot": "A4HXI1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXI1"
  },
  {
    "uniprot": "A4I4E4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4E4"
  },
  {
    "uniprot": "A4IAU3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAU3"
  },
  {
    "uniprot": "E9AGH7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGH7"
  },
  {
    "uniprot": "E9AGN6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGN6"
  },
  {
    "uniprot": "A4HV11",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV11"
  },
  {
    "uniprot": "A4I219",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I219"
  },
  {
    "uniprot": "A4I4C7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4C7"
  },
  {
    "uniprot": "A4HZP6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZP6"
  },
  {
    "uniprot": "A4I350",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I350"
  },
  {
    "uniprot": "A4IBN8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBN8"
  },
  {
    "uniprot": "A4HSH6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSH6"
  },
  {
    "uniprot": "A4HWW2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWW2"
  },
  {
    "uniprot": "E9AHK3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHK3"
  },
  {
    "uniprot": "A4IAC0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAC0"
  },
  {
    "uniprot": "A4ICE5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICE5"
  },
  {
    "uniprot": "A4I5P6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5P6"
  },
  {
    "uniprot": "A4ICY1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICY1"
  },
  {
    "uniprot": "A4HSB9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSB9"
  },
  {
    "uniprot": "A4IC23",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC23"
  },
  {
    "uniprot": "A4IBW7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBW7"
  },
  {
    "uniprot": "A4I326",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I326"
  },
  {
    "uniprot": "A4HXY0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXY0"
  },
  {
    "uniprot": "A4HYB1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYB1"
  },
  {
    "uniprot": "A4IB62",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB62"
  },
  {
    "uniprot": "A4IB98",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB98"
  },
  {
    "uniprot": "A4HVF4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVF4"
  },
  {
    "uniprot": "A4I3R6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3R6"
  },
  {
    "uniprot": "A4HXW3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXW3"
  },
  {
    "uniprot": "A4HZ96",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ96"
  },
  {
    "uniprot": "A4HWN3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWN3"
  },
  {
    "uniprot": "A4I4M8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4M8"
  },
  {
    "uniprot": "A4HSB4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSB4"
  },
  {
    "uniprot": "A4HYG9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYG9"
  },
  {
    "uniprot": "A4HWU2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWU2"
  },
  {
    "uniprot": "A4HS63",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS63"
  },
  {
    "uniprot": "A4HSC2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSC2"
  },
  {
    "uniprot": "A4IDB4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDB4"
  },
  {
    "uniprot": "A4IDK3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDK3"
  },
  {
    "uniprot": "A4HSK1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSK1"
  },
  {
    "uniprot": "A4HSS9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSS9"
  },
  {
    "uniprot": "A4HZ81",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ81"
  },
  {
    "uniprot": "A4HYZ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYZ4"
  },
  {
    "uniprot": "A4IBI7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBI7"
  },
  {
    "uniprot": "A4I5T0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5T0"
  },
  {
    "uniprot": "A4HVK3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVK3"
  },
  {
    "uniprot": "A4I1E9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1E9"
  },
  {
    "uniprot": "A4I5H1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5H1"
  },
  {
    "uniprot": "A4IA99",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA99"
  },
  {
    "uniprot": "A4HUB3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUB3"
  },
  {
    "uniprot": "A4I9H9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9H9"
  },
  {
    "uniprot": "A4HTZ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTZ0"
  },
  {
    "uniprot": "A4IAC6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAC6"
  },
  {
    "uniprot": "A4HUF1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUF1"
  },
  {
    "uniprot": "A4HXI3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXI3"
  },
  {
    "uniprot": "A4I9L8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9L8"
  },
  {
    "uniprot": "A4I101",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I101"
  },
  {
    "uniprot": "A4I9K3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9K3"
  },
  {
    "uniprot": "A4I046",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I046"
  },
  {
    "uniprot": "E9AG76",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG76"
  },
  {
    "uniprot": "A4HU63",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU63"
  },
  {
    "uniprot": "A4IE51",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE51"
  },
  {
    "uniprot": "A4HZ55",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ55"
  },
  {
    "uniprot": "A0A381MJV1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MJV1"
  },
  {
    "uniprot": "A4I9X8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9X8"
  },
  {
    "uniprot": "A4IC71",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC71"
  },
  {
    "uniprot": "A4ID33",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID33"
  },
  {
    "uniprot": "A4IE15",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE15"
  },
  {
    "uniprot": "A4IBT1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBT1"
  },
  {
    "uniprot": "A4IAS8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAS8"
  },
  {
    "uniprot": "A4I374",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I374"
  },
  {
    "uniprot": "A4HXE5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXE5"
  },
  {
    "uniprot": "A4IDH7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDH7"
  },
  {
    "uniprot": "A4HTH9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTH9"
  },
  {
    "uniprot": "A4HSM8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSM8"
  },
  {
    "uniprot": "A4IB18",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB18"
  },
  {
    "uniprot": "A4IE19",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE19"
  },
  {
    "uniprot": "A4I4K5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4K5"
  },
  {
    "uniprot": "A4I7B6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7B6"
  },
  {
    "uniprot": "A4I601",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I601"
  },
  {
    "uniprot": "A4I5G7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5G7"
  },
  {
    "uniprot": "A4HV04",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV04"
  },
  {
    "uniprot": "A0A381MCU2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MCU2"
  },
  {
    "uniprot": "A4I745",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I745"
  },
  {
    "uniprot": "A4ID58",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID58"
  },
  {
    "uniprot": "A4IDU8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDU8"
  },
  {
    "uniprot": "E9AG88",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG88"
  },
  {
    "uniprot": "A4IBR9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBR9"
  },
  {
    "uniprot": "A4I777",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I777"
  },
  {
    "uniprot": "A4HT17",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT17"
  },
  {
    "uniprot": "A4HS64",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS64"
  },
  {
    "uniprot": "A4I6M5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6M5"
  },
  {
    "uniprot": "A4I0S5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0S5"
  },
  {
    "uniprot": "A4I819",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I819"
  },
  {
    "uniprot": "A4HVE0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVE0"
  },
  {
    "uniprot": "A4HW69",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW69"
  },
  {
    "uniprot": "A4IBS3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBS3"
  },
  {
    "uniprot": "A4I505",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I505"
  },
  {
    "uniprot": "A4HU35",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU35"
  },
  {
    "uniprot": "A4I4D8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4D8"
  },
  {
    "uniprot": "A4HRZ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRZ7"
  },
  {
    "uniprot": "A4HYZ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYZ2"
  },
  {
    "uniprot": "A4IE50",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE50"
  },
  {
    "uniprot": "A4HXX1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXX1"
  },
  {
    "uniprot": "A4IDH5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDH5"
  },
  {
    "uniprot": "A4I911",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I911"
  },
  {
    "uniprot": "A4HZY4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZY4"
  },
  {
    "uniprot": "Q6LA77",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q6LA77"
  },
  {
    "uniprot": "A4I2C0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2C0"
  },
  {
    "uniprot": "A4I3D8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3D8"
  },
  {
    "uniprot": "A4HWA8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWA8"
  },
  {
    "uniprot": "A4I054",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I054"
  },
  {
    "uniprot": "A4I8J8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8J8"
  },
  {
    "uniprot": "A4HYM1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYM1"
  },
  {
    "uniprot": "A4ICB9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICB9"
  },
  {
    "uniprot": "A4HWH6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWH6"
  },
  {
    "uniprot": "A4IBS2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBS2"
  },
  {
    "uniprot": "A4I9E0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9E0"
  },
  {
    "uniprot": "A4I6A5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6A5"
  },
  {
    "uniprot": "E9AGB0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGB0"
  },
  {
    "uniprot": "A4HXC3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXC3"
  },
  {
    "uniprot": "A4I7C1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7C1"
  },
  {
    "uniprot": "A4I4Q9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Q9"
  },
  {
    "uniprot": "A4I7A9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7A9"
  },
  {
    "uniprot": "A4HYB8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYB8"
  },
  {
    "uniprot": "A4HYS8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYS8"
  },
  {
    "uniprot": "A4HSN0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSN0"
  },
  {
    "uniprot": "A4HX06",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX06"
  },
  {
    "uniprot": "E9AGC8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGC8"
  },
  {
    "uniprot": "A4I8P8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8P8"
  },
  {
    "uniprot": "A4I2D2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2D2"
  },
  {
    "uniprot": "A4I0B3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0B3"
  },
  {
    "uniprot": "A4HYS9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYS9"
  },
  {
    "uniprot": "A4HY29",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY29"
  },
  {
    "uniprot": "A4HYT9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYT9"
  },
  {
    "uniprot": "A2CIN0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A2CIN0"
  },
  {
    "uniprot": "A4I0A0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0A0"
  },
  {
    "uniprot": "A4I2E4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2E4"
  },
  {
    "uniprot": "A4I2Q0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Q0"
  },
  {
    "uniprot": "A4HUU7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUU7"
  },
  {
    "uniprot": "A4I191",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I191"
  },
  {
    "uniprot": "A4HZ48",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ48"
  },
  {
    "uniprot": "A4I914",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I914"
  },
  {
    "uniprot": "A0A381MBI0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MBI0"
  },
  {
    "uniprot": "A4HS82",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS82"
  },
  {
    "uniprot": "A4IE52",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE52"
  },
  {
    "uniprot": "A4IAZ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAZ4"
  },
  {
    "uniprot": "E9AG44",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG44"
  },
  {
    "uniprot": "A4I2I4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2I4"
  },
  {
    "uniprot": "A4I614",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I614"
  },
  {
    "uniprot": "A4HSZ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSZ4"
  },
  {
    "uniprot": "A4I0K9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0K9"
  },
  {
    "uniprot": "A4HW60",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW60"
  },
  {
    "uniprot": "A4ICS2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICS2"
  },
  {
    "uniprot": "A4I4R8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4R8"
  },
  {
    "uniprot": "E9AG62",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG62"
  },
  {
    "uniprot": "A4I7U9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7U9"
  },
  {
    "uniprot": "A4IBF8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBF8"
  },
  {
    "uniprot": "A4I402",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I402"
  },
  {
    "uniprot": "A4I0N7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0N7"
  },
  {
    "uniprot": "A4IB65",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB65"
  },
  {
    "uniprot": "A4HUW4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUW4"
  },
  {
    "uniprot": "A4I804",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I804"
  },
  {
    "uniprot": "A4I0S7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0S7"
  },
  {
    "uniprot": "A4HUI7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUI7"
  },
  {
    "uniprot": "E9AHE6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHE6"
  },
  {
    "uniprot": "A4HRE6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRE6"
  },
  {
    "uniprot": "E9AH80",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH80"
  },
  {
    "uniprot": "A4ID65",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID65"
  },
  {
    "uniprot": "E9AGT4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGT4"
  },
  {
    "uniprot": "A4IAQ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAQ9"
  },
  {
    "uniprot": "E9AGW2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGW2"
  },
  {
    "uniprot": "A4I509",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I509"
  },
  {
    "uniprot": "A4IAB6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAB6"
  },
  {
    "uniprot": "A4HRP3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRP3"
  },
  {
    "uniprot": "E9AGY8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGY8"
  },
  {
    "uniprot": "A4HS14",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS14"
  },
  {
    "uniprot": "A4HTA8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTA8"
  },
  {
    "uniprot": "A4IAH6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAH6"
  },
  {
    "uniprot": "A4I6C1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6C1"
  },
  {
    "uniprot": "A4HV49",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV49"
  },
  {
    "uniprot": "A4I1V6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1V6"
  },
  {
    "uniprot": "A4HV24",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV24"
  },
  {
    "uniprot": "A4HXC4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXC4"
  },
  {
    "uniprot": "E9AHL4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHL4"
  },
  {
    "uniprot": "A4I823",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I823"
  },
  {
    "uniprot": "A4IAF0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAF0"
  },
  {
    "uniprot": "A4HXJ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXJ1"
  },
  {
    "uniprot": "D1GJ51",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/D1GJ51"
  },
  {
    "uniprot": "A4HXK4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXK4"
  },
  {
    "uniprot": "A4I426",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I426"
  },
  {
    "uniprot": "A4HZD6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZD6"
  },
  {
    "uniprot": "A4HT45",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT45"
  },
  {
    "uniprot": "A4ICZ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICZ6"
  },
  {
    "uniprot": "A4HRL2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRL2"
  },
  {
    "uniprot": "A4HXR2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXR2"
  },
  {
    "uniprot": "A4IBY9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBY9"
  },
  {
    "uniprot": "A4HXG2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXG2"
  },
  {
    "uniprot": "A4HYJ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYJ2"
  },
  {
    "uniprot": "A4HSM2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSM2"
  },
  {
    "uniprot": "A4I0U7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0U7"
  },
  {
    "uniprot": "A4I502",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I502"
  },
  {
    "uniprot": "E9AGG2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGG2"
  },
  {
    "uniprot": "E9AHB6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHB6"
  },
  {
    "uniprot": "A4I807",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I807"
  },
  {
    "uniprot": "A4HWN4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWN4"
  },
  {
    "uniprot": "A4I6W7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6W7"
  },
  {
    "uniprot": "A4HYC9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYC9"
  },
  {
    "uniprot": "E9AGI6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGI6"
  },
  {
    "uniprot": "A4HW84",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW84"
  },
  {
    "uniprot": "A4HVW0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVW0"
  },
  {
    "uniprot": "A4HUD0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUD0"
  },
  {
    "uniprot": "A4HRH2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRH2"
  },
  {
    "uniprot": "A4HT82",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT82"
  },
  {
    "uniprot": "A4I4T0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4T0"
  },
  {
    "uniprot": "A4I732",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I732"
  },
  {
    "uniprot": "A4HXT3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXT3"
  },
  {
    "uniprot": "E9AHK2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHK2"
  },
  {
    "uniprot": "A4HZX1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZX1"
  },
  {
    "uniprot": "A4HTK8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTK8"
  },
  {
    "uniprot": "A4IDZ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDZ5"
  },
  {
    "uniprot": "A4I319",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I319"
  },
  {
    "uniprot": "A4HVS7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVS7"
  },
  {
    "uniprot": "A4HWH4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWH4"
  },
  {
    "uniprot": "A4HYA8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYA8"
  },
  {
    "uniprot": "A4IE42",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE42"
  },
  {
    "uniprot": "A4HT53",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT53"
  },
  {
    "uniprot": "A4HWQ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWQ4"
  },
  {
    "uniprot": "E9AHD0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHD0"
  },
  {
    "uniprot": "A4I100",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I100"
  },
  {
    "uniprot": "A4I2T3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2T3"
  },
  {
    "uniprot": "A4IAC8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAC8"
  },
  {
    "uniprot": "A4IDK1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDK1"
  },
  {
    "uniprot": "A4IC37",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC37"
  },
  {
    "uniprot": "A4I1L7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1L7"
  },
  {
    "uniprot": "A4IBL0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBL0"
  },
  {
    "uniprot": "A4ICB4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICB4"
  },
  {
    "uniprot": "A4I233",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I233"
  },
  {
    "uniprot": "A4HYL2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYL2"
  },
  {
    "uniprot": "A4HTD8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTD8"
  },
  {
    "uniprot": "A4HS40",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS40"
  },
  {
    "uniprot": "A4HVS8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVS8"
  },
  {
    "uniprot": "A4I772",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I772"
  },
  {
    "uniprot": "A4I4D4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4D4"
  },
  {
    "uniprot": "A4HXK9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXK9"
  },
  {
    "uniprot": "E9AHI9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHI9"
  },
  {
    "uniprot": "P62884",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/P62884"
  },
  {
    "uniprot": "A4I2V5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2V5"
  },
  {
    "uniprot": "A4I5N1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5N1"
  },
  {
    "uniprot": "A4I3H6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3H6"
  },
  {
    "uniprot": "A4I739",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I739"
  },
  {
    "uniprot": "A4I6F9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6F9"
  },
  {
    "uniprot": "A4I6T4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6T4"
  },
  {
    "uniprot": "A4HYG6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYG6"
  },
  {
    "uniprot": "A4I391",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I391"
  },
  {
    "uniprot": "A4IDT6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDT6"
  },
  {
    "uniprot": "A4HSF9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSF9"
  },
  {
    "uniprot": "A4HS51",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS51"
  },
  {
    "uniprot": "A4HZD4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZD4"
  },
  {
    "uniprot": "A4ICS1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICS1"
  },
  {
    "uniprot": "A4I811",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I811"
  },
  {
    "uniprot": "A4I4L9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4L9"
  },
  {
    "uniprot": "A4I504",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I504"
  },
  {
    "uniprot": "A4I620",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I620"
  },
  {
    "uniprot": "A4I1L3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1L3"
  },
  {
    "uniprot": "A4HZE1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZE1"
  },
  {
    "uniprot": "E9AHB8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHB8"
  },
  {
    "uniprot": "A4I7C2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7C2"
  },
  {
    "uniprot": "A4I6P3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6P3"
  },
  {
    "uniprot": "A4IBH5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBH5"
  },
  {
    "uniprot": "A4HVM9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVM9"
  },
  {
    "uniprot": "A4HZB0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZB0"
  },
  {
    "uniprot": "A4IE66",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE66"
  },
  {
    "uniprot": "A4IAY9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAY9"
  },
  {
    "uniprot": "E9AGN8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGN8"
  },
  {
    "uniprot": "A4HZB1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZB1"
  },
  {
    "uniprot": "A4I118",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I118"
  },
  {
    "uniprot": "A4IDQ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDQ9"
  },
  {
    "uniprot": "A4HV01",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV01"
  },
  {
    "uniprot": "A4I6S5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6S5"
  },
  {
    "uniprot": "A4ID63",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID63"
  },
  {
    "uniprot": "A4I7Y1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Y1"
  },
  {
    "uniprot": "A4I6X2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6X2"
  },
  {
    "uniprot": "A4I577",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I577"
  },
  {
    "uniprot": "A4I364",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I364"
  },
  {
    "uniprot": "A4HUN4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUN4"
  },
  {
    "uniprot": "A4HYE2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYE2"
  },
  {
    "uniprot": "A4IB28",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB28"
  },
  {
    "uniprot": "A4I4X7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4X7"
  },
  {
    "uniprot": "A4HSG1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSG1"
  },
  {
    "uniprot": "A4I5G3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5G3"
  },
  {
    "uniprot": "A4HSG2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSG2"
  },
  {
    "uniprot": "A4HW18",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW18"
  },
  {
    "uniprot": "E9AHM3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHM3"
  },
  {
    "uniprot": "A4I1K9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1K9"
  },
  {
    "uniprot": "A4HTL4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTL4"
  },
  {
    "uniprot": "A4HTQ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTQ0"
  },
  {
    "uniprot": "A4HYP5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYP5"
  },
  {
    "uniprot": "A4HUL7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUL7"
  },
  {
    "uniprot": "A4HZL4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZL4"
  },
  {
    "uniprot": "E9AGZ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGZ7"
  },
  {
    "uniprot": "A4HUC3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUC3"
  },
  {
    "uniprot": "A4I022",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I022"
  },
  {
    "uniprot": "A4HYT2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYT2"
  },
  {
    "uniprot": "A4I8B3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8B3"
  },
  {
    "uniprot": "A4IBA6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBA6"
  },
  {
    "uniprot": "A4I871",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I871"
  },
  {
    "uniprot": "A4I1J7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1J7"
  },
  {
    "uniprot": "E9AG33",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG33"
  },
  {
    "uniprot": "A4HSI2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSI2"
  },
  {
    "uniprot": "A4I6M8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6M8"
  },
  {
    "uniprot": "A4HTG4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTG4"
  },
  {
    "uniprot": "A4HXR8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXR8"
  },
  {
    "uniprot": "E9AH79",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH79"
  },
  {
    "uniprot": "A4IAE9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAE9"
  },
  {
    "uniprot": "A4I176",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I176"
  },
  {
    "uniprot": "A4I8I9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8I9"
  },
  {
    "uniprot": "A4I3W1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3W1"
  },
  {
    "uniprot": "A4IBD1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBD1"
  },
  {
    "uniprot": "A4I8G4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8G4"
  },
  {
    "uniprot": "A4HSB1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSB1"
  },
  {
    "uniprot": "A4I356",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I356"
  },
  {
    "uniprot": "A4I7R6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7R6"
  },
  {
    "uniprot": "A4I0K8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0K8"
  },
  {
    "uniprot": "A4IDW3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDW3"
  },
  {
    "uniprot": "A4I106",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I106"
  },
  {
    "uniprot": "Q9N9V4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q9N9V4"
  },
  {
    "uniprot": "A4HYU4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYU4"
  },
  {
    "uniprot": "E9AG75",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG75"
  },
  {
    "uniprot": "A4I5G6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5G6"
  },
  {
    "uniprot": "A4I8V6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8V6"
  },
  {
    "uniprot": "A4I8Z6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Z6"
  },
  {
    "uniprot": "A4IDM3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDM3"
  },
  {
    "uniprot": "A4I7M3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7M3"
  },
  {
    "uniprot": "A4IAG4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAG4"
  },
  {
    "uniprot": "A4HUH0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUH0"
  },
  {
    "uniprot": "A4IA63",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA63"
  },
  {
    "uniprot": "A4HU44",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU44"
  },
  {
    "uniprot": "A4HXL8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXL8"
  },
  {
    "uniprot": "A4I8S3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8S3"
  },
  {
    "uniprot": "A4I650",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I650"
  },
  {
    "uniprot": "A4IA73",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA73"
  },
  {
    "uniprot": "E9AHW0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHW0"
  },
  {
    "uniprot": "E9AHN3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHN3"
  },
  {
    "uniprot": "A4IDC9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDC9"
  },
  {
    "uniprot": "A4I881",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I881"
  },
  {
    "uniprot": "A4HW56",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW56"
  },
  {
    "uniprot": "A4I699",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I699"
  },
  {
    "uniprot": "A4IB91",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB91"
  },
  {
    "uniprot": "A4HUP4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUP4"
  },
  {
    "uniprot": "A4ICT1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICT1"
  },
  {
    "uniprot": "A4HV39",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV39"
  },
  {
    "uniprot": "A4I1R5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1R5"
  },
  {
    "uniprot": "A4I1X4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1X4"
  },
  {
    "uniprot": "E9AHU2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHU2"
  },
  {
    "uniprot": "A4HU51",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU51"
  },
  {
    "uniprot": "A4I4V9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4V9"
  },
  {
    "uniprot": "A4HXW7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXW7"
  },
  {
    "uniprot": "A4I909",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I909"
  },
  {
    "uniprot": "A4I075",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I075"
  },
  {
    "uniprot": "A4HWJ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWJ7"
  },
  {
    "uniprot": "A4I2L8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2L8"
  },
  {
    "uniprot": "E9AHQ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHQ3"
  },
  {
    "uniprot": "A4I5L1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5L1"
  },
  {
    "uniprot": "A4I1T6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1T6"
  },
  {
    "uniprot": "A4I3T5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3T5"
  },
  {
    "uniprot": "A4HT36",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT36"
  },
  {
    "uniprot": "A4I7W0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7W0"
  },
  {
    "uniprot": "A4HVI6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVI6"
  },
  {
    "uniprot": "A4I2A6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2A6"
  },
  {
    "uniprot": "A4HSV0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSV0"
  },
  {
    "uniprot": "E9AH99",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH99"
  },
  {
    "uniprot": "A4IAT4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAT4"
  },
  {
    "uniprot": "A4HUS7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUS7"
  },
  {
    "uniprot": "A4HRY4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRY4"
  },
  {
    "uniprot": "A4ICL3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICL3"
  },
  {
    "uniprot": "A4HTG2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTG2"
  },
  {
    "uniprot": "A4HXG6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXG6"
  },
  {
    "uniprot": "A4HTR1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTR1"
  },
  {
    "uniprot": "A4I148",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I148"
  },
  {
    "uniprot": "A4I4B8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4B8"
  },
  {
    "uniprot": "A4HZV2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZV2"
  },
  {
    "uniprot": "A4HZ01",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ01"
  },
  {
    "uniprot": "A4HVI3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVI3"
  },
  {
    "uniprot": "A4HWS9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWS9"
  },
  {
    "uniprot": "A4IBJ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBJ2"
  },
  {
    "uniprot": "A4I8U4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8U4"
  },
  {
    "uniprot": "A4I0H8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0H8"
  },
  {
    "uniprot": "A4I7Y6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Y6"
  },
  {
    "uniprot": "A4IDT2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDT2"
  },
  {
    "uniprot": "A4HX84",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX84"
  },
  {
    "uniprot": "A4HSL4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSL4"
  },
  {
    "uniprot": "A4HRW1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRW1"
  },
  {
    "uniprot": "A4I1C5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1C5"
  },
  {
    "uniprot": "A4I8T7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8T7"
  },
  {
    "uniprot": "A4I2Z1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Z1"
  },
  {
    "uniprot": "A4HUR3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUR3"
  },
  {
    "uniprot": "A4I320",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I320"
  },
  {
    "uniprot": "A4I129",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I129"
  },
  {
    "uniprot": "A4I4U3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4U3"
  },
  {
    "uniprot": "A4I4A4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4A4"
  },
  {
    "uniprot": "A4IB02",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB02"
  },
  {
    "uniprot": "A4I7H3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7H3"
  },
  {
    "uniprot": "A4ID49",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID49"
  },
  {
    "uniprot": "A4HZ76",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ76"
  },
  {
    "uniprot": "A4IAG2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAG2"
  },
  {
    "uniprot": "A4HZH4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZH4"
  },
  {
    "uniprot": "A4IA38",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA38"
  },
  {
    "uniprot": "A4HU52",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU52"
  },
  {
    "uniprot": "A4I3Z7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Z7"
  },
  {
    "uniprot": "A4I587",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I587"
  },
  {
    "uniprot": "A4IBH0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBH0"
  },
  {
    "uniprot": "A4HSA6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSA6"
  },
  {
    "uniprot": "A4HTA1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTA1"
  },
  {
    "uniprot": "A4HRS9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRS9"
  },
  {
    "uniprot": "A4I128",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I128"
  },
  {
    "uniprot": "A4I7J5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7J5"
  },
  {
    "uniprot": "A4IAM4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAM4"
  },
  {
    "uniprot": "E9AG66",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG66"
  },
  {
    "uniprot": "A4IBB8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBB8"
  },
  {
    "uniprot": "A4I2H5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2H5"
  },
  {
    "uniprot": "A4HVZ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVZ7"
  },
  {
    "uniprot": "A4I5V1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5V1"
  },
  {
    "uniprot": "A4I994",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I994"
  },
  {
    "uniprot": "A4I302",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I302"
  },
  {
    "uniprot": "A4HUJ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUJ0"
  },
  {
    "uniprot": "A4I8S0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8S0"
  },
  {
    "uniprot": "A4HW27",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW27"
  },
  {
    "uniprot": "A4I3Y4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Y4"
  },
  {
    "uniprot": "E9AH94",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH94"
  },
  {
    "uniprot": "A4HUJ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUJ5"
  },
  {
    "uniprot": "A4HSP1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSP1"
  },
  {
    "uniprot": "A4IBX1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBX1"
  },
  {
    "uniprot": "A4I245",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I245"
  },
  {
    "uniprot": "A4HRR2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRR2"
  },
  {
    "uniprot": "A4HXG8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXG8"
  },
  {
    "uniprot": "A4I8L4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8L4"
  },
  {
    "uniprot": "A4I6G5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6G5"
  },
  {
    "uniprot": "A4ICU9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICU9"
  },
  {
    "uniprot": "A4IAR5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAR5"
  },
  {
    "uniprot": "E9AHZ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHZ0"
  },
  {
    "uniprot": "A4I9M8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9M8"
  },
  {
    "uniprot": "A4I1K7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1K7"
  },
  {
    "uniprot": "A4HUZ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUZ5"
  },
  {
    "uniprot": "A4HWT2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWT2"
  },
  {
    "uniprot": "A4I686",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I686"
  },
  {
    "uniprot": "A4I0R1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0R1"
  },
  {
    "uniprot": "A4I3N5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3N5"
  },
  {
    "uniprot": "A4HS48",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS48"
  },
  {
    "uniprot": "A4I925",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I925"
  },
  {
    "uniprot": "A4IB04",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB04"
  },
  {
    "uniprot": "A4HS07",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS07"
  },
  {
    "uniprot": "A4I7L2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7L2"
  },
  {
    "uniprot": "A4HVE3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVE3"
  },
  {
    "uniprot": "A4I0U6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0U6"
  },
  {
    "uniprot": "A4I490",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I490"
  },
  {
    "uniprot": "A4I1Y1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Y1"
  },
  {
    "uniprot": "A4ICL6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICL6"
  },
  {
    "uniprot": "A4HTQ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTQ4"
  },
  {
    "uniprot": "A4I4F0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4F0"
  },
  {
    "uniprot": "A4I0D8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0D8"
  },
  {
    "uniprot": "A4I0N0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0N0"
  },
  {
    "uniprot": "A4I0R8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0R8"
  },
  {
    "uniprot": "A4I4B2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4B2"
  },
  {
    "uniprot": "A4I5B4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5B4"
  },
  {
    "uniprot": "A4HTF3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTF3"
  },
  {
    "uniprot": "A4HRV5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRV5"
  },
  {
    "uniprot": "A4HTM6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTM6"
  },
  {
    "uniprot": "E9AHT7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHT7"
  },
  {
    "uniprot": "A4HWT5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWT5"
  },
  {
    "uniprot": "E9AHJ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHJ6"
  },
  {
    "uniprot": "A4I0E3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0E3"
  },
  {
    "uniprot": "A4HYB2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYB2"
  },
  {
    "uniprot": "A4HW31",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW31"
  },
  {
    "uniprot": "A4I7N6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7N6"
  },
  {
    "uniprot": "E9AGV5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGV5"
  },
  {
    "uniprot": "A4HVJ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVJ5"
  },
  {
    "uniprot": "Q9N9V8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q9N9V8"
  },
  {
    "uniprot": "A4HYZ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYZ5"
  },
  {
    "uniprot": "A4I328",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I328"
  },
  {
    "uniprot": "A4IB71",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB71"
  },
  {
    "uniprot": "A4IAV2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAV2"
  },
  {
    "uniprot": "A4HV48",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV48"
  },
  {
    "uniprot": "A4I4T4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4T4"
  },
  {
    "uniprot": "A4I630",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I630"
  },
  {
    "uniprot": "A4I9F3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9F3"
  },
  {
    "uniprot": "E9AG19",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG19"
  },
  {
    "uniprot": "A4IB13",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB13"
  },
  {
    "uniprot": "A4IAD3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAD3"
  },
  {
    "uniprot": "A4HYA5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYA5"
  },
  {
    "uniprot": "A4IE30",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE30"
  },
  {
    "uniprot": "A4I980",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I980"
  },
  {
    "uniprot": "A4HZM5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZM5"
  },
  {
    "uniprot": "A4I7K9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7K9"
  },
  {
    "uniprot": "A4HRM3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRM3"
  },
  {
    "uniprot": "A4HT69",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT69"
  },
  {
    "uniprot": "A4I1I9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1I9"
  },
  {
    "uniprot": "A4HZZ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZZ9"
  },
  {
    "uniprot": "A4HYM8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYM8"
  },
  {
    "uniprot": "A4I7L8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7L8"
  },
  {
    "uniprot": "A4ICH8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICH8"
  },
  {
    "uniprot": "A4I4H5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4H5"
  },
  {
    "uniprot": "A4I6G9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6G9"
  },
  {
    "uniprot": "A4I9T3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9T3"
  },
  {
    "uniprot": "A4I387",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I387"
  },
  {
    "uniprot": "A4HYH7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYH7"
  },
  {
    "uniprot": "A4IA02",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA02"
  },
  {
    "uniprot": "A4I9U9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9U9"
  },
  {
    "uniprot": "A4I9N4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9N4"
  },
  {
    "uniprot": "E9AGD2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGD2"
  },
  {
    "uniprot": "A4HUE7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUE7"
  },
  {
    "uniprot": "A4IBZ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBZ2"
  },
  {
    "uniprot": "A4HZC9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZC9"
  },
  {
    "uniprot": "E9AGE1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGE1"
  },
  {
    "uniprot": "A4I410",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I410"
  },
  {
    "uniprot": "A4I0J6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0J6"
  },
  {
    "uniprot": "A4HT72",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT72"
  },
  {
    "uniprot": "A4HXS5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXS5"
  },
  {
    "uniprot": "A4HS29",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS29"
  },
  {
    "uniprot": "A4HYX5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYX5"
  },
  {
    "uniprot": "A4HVQ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVQ6"
  },
  {
    "uniprot": "A4HYL3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYL3"
  },
  {
    "uniprot": "A4HWL6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWL6"
  },
  {
    "uniprot": "A4HSG4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSG4"
  },
  {
    "uniprot": "A4IC04",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC04"
  },
  {
    "uniprot": "A4HS97",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS97"
  },
  {
    "uniprot": "A4HWF4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWF4"
  },
  {
    "uniprot": "A4I406",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I406"
  },
  {
    "uniprot": "A4I3X0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3X0"
  },
  {
    "uniprot": "A4I8Z5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Z5"
  },
  {
    "uniprot": "A4HZH2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZH2"
  },
  {
    "uniprot": "A4I2W1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2W1"
  },
  {
    "uniprot": "A4I7J0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7J0"
  },
  {
    "uniprot": "A4I0E9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0E9"
  },
  {
    "uniprot": "A4I8S7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8S7"
  },
  {
    "uniprot": "A4I4T1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4T1"
  },
  {
    "uniprot": "A4HW45",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW45"
  },
  {
    "uniprot": "A4ICU4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICU4"
  },
  {
    "uniprot": "A4HUG5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUG5"
  },
  {
    "uniprot": "A4HX69",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX69"
  },
  {
    "uniprot": "E9AGT3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGT3"
  },
  {
    "uniprot": "E9AH01",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH01"
  },
  {
    "uniprot": "A4ICQ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICQ4"
  },
  {
    "uniprot": "A4HW08",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW08"
  },
  {
    "uniprot": "A4HVM4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVM4"
  },
  {
    "uniprot": "A4HXM8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXM8"
  },
  {
    "uniprot": "A4I065",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I065"
  },
  {
    "uniprot": "A4HXN0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXN0"
  },
  {
    "uniprot": "A4HU38",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU38"
  },
  {
    "uniprot": "A4IDF1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDF1"
  },
  {
    "uniprot": "A4HXD4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXD4"
  },
  {
    "uniprot": "A4I0X6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0X6"
  },
  {
    "uniprot": "A4I6A2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6A2"
  },
  {
    "uniprot": "A4I590",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I590"
  },
  {
    "uniprot": "A4HVQ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVQ8"
  },
  {
    "uniprot": "A4HVX1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVX1"
  },
  {
    "uniprot": "A4IA50",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA50"
  },
  {
    "uniprot": "A4HXF6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXF6"
  },
  {
    "uniprot": "A4I7T5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7T5"
  },
  {
    "uniprot": "A4I6L2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6L2"
  },
  {
    "uniprot": "A4HYK7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYK7"
  },
  {
    "uniprot": "A4HSN1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSN1"
  },
  {
    "uniprot": "A4IAI6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAI6"
  },
  {
    "uniprot": "A4HXY6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXY6"
  },
  {
    "uniprot": "A4HU20",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU20"
  },
  {
    "uniprot": "A4I420",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I420"
  },
  {
    "uniprot": "A4I5H5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5H5"
  },
  {
    "uniprot": "A4HY81",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY81"
  },
  {
    "uniprot": "A4I189",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I189"
  },
  {
    "uniprot": "A4HVV9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVV9"
  },
  {
    "uniprot": "A4IBS7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBS7"
  },
  {
    "uniprot": "A4I161",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I161"
  },
  {
    "uniprot": "A4HZP2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZP2"
  },
  {
    "uniprot": "A4HXU7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXU7"
  },
  {
    "uniprot": "E9AHA4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHA4"
  },
  {
    "uniprot": "A4I6H6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6H6"
  },
  {
    "uniprot": "A4HZR9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZR9"
  },
  {
    "uniprot": "A4IA83",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA83"
  },
  {
    "uniprot": "A4I4Z4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Z4"
  },
  {
    "uniprot": "E9AGY4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGY4"
  },
  {
    "uniprot": "A4IBY8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBY8"
  },
  {
    "uniprot": "A4I7K4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7K4"
  },
  {
    "uniprot": "A4IDB8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDB8"
  },
  {
    "uniprot": "A4I0Y3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Y3"
  },
  {
    "uniprot": "A4I3B4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3B4"
  },
  {
    "uniprot": "A4I321",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I321"
  },
  {
    "uniprot": "A4HVW5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVW5"
  },
  {
    "uniprot": "A4HXR9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXR9"
  },
  {
    "uniprot": "A4HWD4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWD4"
  },
  {
    "uniprot": "A4I5Y3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Y3"
  },
  {
    "uniprot": "E9AH38",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH38"
  },
  {
    "uniprot": "A4HYY2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYY2"
  },
  {
    "uniprot": "A4I6V7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6V7"
  },
  {
    "uniprot": "A4HUI6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUI6"
  },
  {
    "uniprot": "A4HZ37",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ37"
  },
  {
    "uniprot": "A4IAT5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAT5"
  },
  {
    "uniprot": "A4I2U2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2U2"
  },
  {
    "uniprot": "A4I196",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I196"
  },
  {
    "uniprot": "A4I845",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I845"
  },
  {
    "uniprot": "A4IC45",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC45"
  },
  {
    "uniprot": "A4I9C6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9C6"
  },
  {
    "uniprot": "A4HSE3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSE3"
  },
  {
    "uniprot": "A4HTQ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTQ3"
  },
  {
    "uniprot": "A4HSD3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSD3"
  },
  {
    "uniprot": "A4HZW2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZW2"
  },
  {
    "uniprot": "A4I7I1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7I1"
  },
  {
    "uniprot": "A4I954",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I954"
  },
  {
    "uniprot": "A4I8Z8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Z8"
  },
  {
    "uniprot": "A4I181",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I181"
  },
  {
    "uniprot": "A4IDE6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDE6"
  },
  {
    "uniprot": "A4HYD2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYD2"
  },
  {
    "uniprot": "A4IAI1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAI1"
  },
  {
    "uniprot": "A4HZ99",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ99"
  },
  {
    "uniprot": "A4IAX5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAX5"
  },
  {
    "uniprot": "A4HUC4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUC4"
  },
  {
    "uniprot": "A4I0X0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0X0"
  },
  {
    "uniprot": "E9AHS5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHS5"
  },
  {
    "uniprot": "A4IBN5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBN5"
  },
  {
    "uniprot": "A4I279",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I279"
  },
  {
    "uniprot": "A4ID27",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID27"
  },
  {
    "uniprot": "A4IA17",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA17"
  },
  {
    "uniprot": "A4IAK1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAK1"
  },
  {
    "uniprot": "A4I945",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I945"
  },
  {
    "uniprot": "A4HRE2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRE2"
  },
  {
    "uniprot": "A4HSW5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSW5"
  },
  {
    "uniprot": "A4HRQ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRQ5"
  },
  {
    "uniprot": "A4I1E3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1E3"
  },
  {
    "uniprot": "E9AHC4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHC4"
  },
  {
    "uniprot": "A4IC86",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC86"
  },
  {
    "uniprot": "A4IAM2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAM2"
  },
  {
    "uniprot": "A4IA43",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA43"
  },
  {
    "uniprot": "A4I9M7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9M7"
  },
  {
    "uniprot": "A4HUX4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUX4"
  },
  {
    "uniprot": "A4ICG6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICG6"
  },
  {
    "uniprot": "A4HYW3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYW3"
  },
  {
    "uniprot": "A4HYU5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYU5"
  },
  {
    "uniprot": "A4IDX0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDX0"
  },
  {
    "uniprot": "A4I1E5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1E5"
  },
  {
    "uniprot": "A4HWL7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWL7"
  },
  {
    "uniprot": "A4HWC2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWC2"
  },
  {
    "uniprot": "A4I0L1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0L1"
  },
  {
    "uniprot": "A4HUZ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUZ8"
  },
  {
    "uniprot": "A4HTZ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTZ9"
  },
  {
    "uniprot": "A4IDL4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDL4"
  },
  {
    "uniprot": "A4I5Q2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Q2"
  },
  {
    "uniprot": "A4IDQ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDQ8"
  },
  {
    "uniprot": "A4IDT0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDT0"
  },
  {
    "uniprot": "A4I8A7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8A7"
  },
  {
    "uniprot": "A4I6V5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6V5"
  },
  {
    "uniprot": "A4I7W1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7W1"
  },
  {
    "uniprot": "A4HY07",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY07"
  },
  {
    "uniprot": "A4ICJ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICJ8"
  },
  {
    "uniprot": "A4IDV6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDV6"
  },
  {
    "uniprot": "A4ICY0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICY0"
  },
  {
    "uniprot": "A4I9M5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9M5"
  },
  {
    "uniprot": "A4I4Q5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Q5"
  },
  {
    "uniprot": "E9AGA3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGA3"
  },
  {
    "uniprot": "A4HSQ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSQ5"
  },
  {
    "uniprot": "A4I8A1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8A1"
  },
  {
    "uniprot": "E9AHP8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHP8"
  },
  {
    "uniprot": "A4I5R2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5R2"
  },
  {
    "uniprot": "A4I1Z2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Z2"
  },
  {
    "uniprot": "A4HZT0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZT0"
  },
  {
    "uniprot": "A4HZB7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZB7"
  },
  {
    "uniprot": "A4IC11",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC11"
  },
  {
    "uniprot": "A4I5J9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5J9"
  },
  {
    "uniprot": "A4IAT9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAT9"
  },
  {
    "uniprot": "A4I9A5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9A5"
  },
  {
    "uniprot": "A4HXA4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXA4"
  },
  {
    "uniprot": "A4IE25",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE25"
  },
  {
    "uniprot": "E9AH30",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH30"
  },
  {
    "uniprot": "A4I2J2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2J2"
  },
  {
    "uniprot": "A4I295",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I295"
  },
  {
    "uniprot": "A4HWU0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWU0"
  },
  {
    "uniprot": "A4I9G6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9G6"
  },
  {
    "uniprot": "A4HT70",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT70"
  },
  {
    "uniprot": "A4I5S1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5S1"
  },
  {
    "uniprot": "A4I3A0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3A0"
  },
  {
    "uniprot": "A4HSE9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSE9"
  },
  {
    "uniprot": "E9AGN7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGN7"
  },
  {
    "uniprot": "A4ICY3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICY3"
  },
  {
    "uniprot": "E9AGK7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGK7"
  },
  {
    "uniprot": "A4I7N7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7N7"
  },
  {
    "uniprot": "A4HYT6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYT6"
  },
  {
    "uniprot": "A4IA54",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA54"
  },
  {
    "uniprot": "A4IA06",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA06"
  },
  {
    "uniprot": "A4I0P4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0P4"
  },
  {
    "uniprot": "A4I9E3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9E3"
  },
  {
    "uniprot": "A4IC53",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC53"
  },
  {
    "uniprot": "Q6RYT3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q6RYT3"
  },
  {
    "uniprot": "A4I3S5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3S5"
  },
  {
    "uniprot": "A4I1K6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1K6"
  },
  {
    "uniprot": "A4I6W2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6W2"
  },
  {
    "uniprot": "A4IC34",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC34"
  },
  {
    "uniprot": "A4I7R3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7R3"
  },
  {
    "uniprot": "A4HZD5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZD5"
  },
  {
    "uniprot": "A4HX99",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX99"
  },
  {
    "uniprot": "A4HT16",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT16"
  },
  {
    "uniprot": "E9AHM4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHM4"
  },
  {
    "uniprot": "A4HW83",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW83"
  },
  {
    "uniprot": "A4I2M3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2M3"
  },
  {
    "uniprot": "A4HUD6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUD6"
  },
  {
    "uniprot": "A4HSQ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSQ9"
  },
  {
    "uniprot": "A4I1W4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1W4"
  },
  {
    "uniprot": "A4I131",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I131"
  },
  {
    "uniprot": "A4HRW6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRW6"
  },
  {
    "uniprot": "A4I801",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I801"
  },
  {
    "uniprot": "A4ID74",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID74"
  },
  {
    "uniprot": "A4HT61",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT61"
  },
  {
    "uniprot": "A4IB03",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB03"
  },
  {
    "uniprot": "A4HVU0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVU0"
  },
  {
    "uniprot": "A4IDY2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDY2"
  },
  {
    "uniprot": "A4IDE0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDE0"
  },
  {
    "uniprot": "A4I3P7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3P7"
  },
  {
    "uniprot": "E9AHH0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHH0"
  },
  {
    "uniprot": "A4I4L8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4L8"
  },
  {
    "uniprot": "A4I5N0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5N0"
  },
  {
    "uniprot": "A4I201",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I201"
  },
  {
    "uniprot": "A4I259",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I259"
  },
  {
    "uniprot": "A4HWD9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWD9"
  },
  {
    "uniprot": "A4IBC9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBC9"
  },
  {
    "uniprot": "A4I2B0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2B0"
  },
  {
    "uniprot": "A4I3Y2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Y2"
  },
  {
    "uniprot": "A4HZM7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZM7"
  },
  {
    "uniprot": "A4I8T0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8T0"
  },
  {
    "uniprot": "A4IAC7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAC7"
  },
  {
    "uniprot": "A4ID50",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID50"
  },
  {
    "uniprot": "A4HRJ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRJ3"
  },
  {
    "uniprot": "A4I5S4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5S4"
  },
  {
    "uniprot": "A4IA00",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA00"
  },
  {
    "uniprot": "A4HWL4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWL4"
  },
  {
    "uniprot": "A4HUC2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUC2"
  },
  {
    "uniprot": "A4HWP4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWP4"
  },
  {
    "uniprot": "A4HU39",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU39"
  },
  {
    "uniprot": "A4HW13",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW13"
  },
  {
    "uniprot": "A4I782",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I782"
  },
  {
    "uniprot": "Q7JMX2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q7JMX2"
  },
  {
    "uniprot": "A4IDF4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDF4"
  },
  {
    "uniprot": "A4HTT7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTT7"
  },
  {
    "uniprot": "A4I001",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I001"
  },
  {
    "uniprot": "A4I0I7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0I7"
  },
  {
    "uniprot": "A4I2Y9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Y9"
  },
  {
    "uniprot": "A4HVN8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVN8"
  },
  {
    "uniprot": "A4HSW0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSW0"
  },
  {
    "uniprot": "A4I5A6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5A6"
  },
  {
    "uniprot": "A4I3U0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3U0"
  },
  {
    "uniprot": "A4IAH7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAH7"
  },
  {
    "uniprot": "A4I3A4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3A4"
  },
  {
    "uniprot": "A4HU67",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU67"
  },
  {
    "uniprot": "A4HXA5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXA5"
  },
  {
    "uniprot": "A4IDW4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDW4"
  },
  {
    "uniprot": "A4HZQ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZQ0"
  },
  {
    "uniprot": "A4I580",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I580"
  },
  {
    "uniprot": "A4I6Y4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Y4"
  },
  {
    "uniprot": "A4HWR5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWR5"
  },
  {
    "uniprot": "A4I5B2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5B2"
  },
  {
    "uniprot": "A4HU74",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU74"
  },
  {
    "uniprot": "A4I6T8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6T8"
  },
  {
    "uniprot": "A4HWY0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWY0"
  },
  {
    "uniprot": "E9AGS0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGS0"
  },
  {
    "uniprot": "A4IAA4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAA4"
  },
  {
    "uniprot": "E9AH52",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH52"
  },
  {
    "uniprot": "A4ICE8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICE8"
  },
  {
    "uniprot": "A4HRE7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRE7"
  },
  {
    "uniprot": "E9AH09",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH09"
  },
  {
    "uniprot": "A4ICX1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICX1"
  },
  {
    "uniprot": "A4HXQ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXQ8"
  },
  {
    "uniprot": "A4HWI0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWI0"
  },
  {
    "uniprot": "E9AH10",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH10"
  },
  {
    "uniprot": "A4I8T6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8T6"
  },
  {
    "uniprot": "A4I0H0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0H0"
  },
  {
    "uniprot": "A4HVH6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVH6"
  },
  {
    "uniprot": "A4I5K6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5K6"
  },
  {
    "uniprot": "A4HTB2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTB2"
  },
  {
    "uniprot": "A4HVN0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVN0"
  },
  {
    "uniprot": "A4I9Z2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Z2"
  },
  {
    "uniprot": "E9AGX1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGX1"
  },
  {
    "uniprot": "A4HXC5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXC5"
  },
  {
    "uniprot": "A4IBG3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBG3"
  },
  {
    "uniprot": "A4ID44",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID44"
  },
  {
    "uniprot": "A4I3V1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3V1"
  },
  {
    "uniprot": "A4I0K3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0K3"
  },
  {
    "uniprot": "A4ICX9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICX9"
  },
  {
    "uniprot": "A4I793",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I793"
  },
  {
    "uniprot": "A4IE35",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE35"
  },
  {
    "uniprot": "A4HTY6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTY6"
  },
  {
    "uniprot": "A4I9Y7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Y7"
  },
  {
    "uniprot": "E9AHJ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHJ4"
  },
  {
    "uniprot": "A4HW29",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW29"
  },
  {
    "uniprot": "A4I0V4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0V4"
  },
  {
    "uniprot": "A4I7H8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7H8"
  },
  {
    "uniprot": "A4IC01",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC01"
  },
  {
    "uniprot": "A4I430",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I430"
  },
  {
    "uniprot": "A4IBJ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBJ5"
  },
  {
    "uniprot": "A4I156",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I156"
  },
  {
    "uniprot": "A4I7I3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7I3"
  },
  {
    "uniprot": "A4I009",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I009"
  },
  {
    "uniprot": "A4I824",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I824"
  },
  {
    "uniprot": "A4I6R5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6R5"
  },
  {
    "uniprot": "A4I3N7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3N7"
  },
  {
    "uniprot": "A4I6Z5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Z5"
  },
  {
    "uniprot": "A4I2N3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2N3"
  },
  {
    "uniprot": "A4I908",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I908"
  },
  {
    "uniprot": "E9AGJ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGJ1"
  },
  {
    "uniprot": "A4I3I8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3I8"
  },
  {
    "uniprot": "E9AHA0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHA0"
  },
  {
    "uniprot": "A4I3C4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3C4"
  },
  {
    "uniprot": "A4I3G6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3G6"
  },
  {
    "uniprot": "A4HX13",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX13"
  },
  {
    "uniprot": "E9AGC5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGC5"
  },
  {
    "uniprot": "A4I134",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I134"
  },
  {
    "uniprot": "A4IE32",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE32"
  },
  {
    "uniprot": "A4HXW4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXW4"
  },
  {
    "uniprot": "A4I861",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I861"
  },
  {
    "uniprot": "A4HZ95",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ95"
  },
  {
    "uniprot": "A4HSW1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSW1"
  },
  {
    "uniprot": "A4I323",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I323"
  },
  {
    "uniprot": "A4IDP2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDP2"
  },
  {
    "uniprot": "A4HX90",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX90"
  },
  {
    "uniprot": "A4IBC3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBC3"
  },
  {
    "uniprot": "A4ID03",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID03"
  },
  {
    "uniprot": "A4I6B1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6B1"
  },
  {
    "uniprot": "A4IDY3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDY3"
  },
  {
    "uniprot": "A0A381MTA7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MTA7"
  },
  {
    "uniprot": "A4IC83",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC83"
  },
  {
    "uniprot": "A4HTU7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTU7"
  },
  {
    "uniprot": "A4I8R5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8R5"
  },
  {
    "uniprot": "A4I178",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I178"
  },
  {
    "uniprot": "A4I5F6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5F6"
  },
  {
    "uniprot": "A4I4C3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4C3"
  },
  {
    "uniprot": "A4I2P0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2P0"
  },
  {
    "uniprot": "A4HYW9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYW9"
  },
  {
    "uniprot": "A4HVK8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVK8"
  },
  {
    "uniprot": "A4HW26",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW26"
  },
  {
    "uniprot": "E9AGF4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGF4"
  },
  {
    "uniprot": "A4I5L3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5L3"
  },
  {
    "uniprot": "A4HZY1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZY1"
  },
  {
    "uniprot": "A4HY21",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY21"
  },
  {
    "uniprot": "A4I4L2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4L2"
  },
  {
    "uniprot": "E9AH63",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH63"
  },
  {
    "uniprot": "A4HZJ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZJ5"
  },
  {
    "uniprot": "A4I5F1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5F1"
  },
  {
    "uniprot": "A4I8I8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8I8"
  },
  {
    "uniprot": "A4IDU3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDU3"
  },
  {
    "uniprot": "A4I2Y8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Y8"
  },
  {
    "uniprot": "A4I3C1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3C1"
  },
  {
    "uniprot": "A4I844",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I844"
  },
  {
    "uniprot": "A4HS83",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS83"
  },
  {
    "uniprot": "A4I8M5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8M5"
  },
  {
    "uniprot": "A4I6N1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6N1"
  },
  {
    "uniprot": "A4HSN8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSN8"
  },
  {
    "uniprot": "A4I2B1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2B1"
  },
  {
    "uniprot": "A4HXZ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXZ6"
  },
  {
    "uniprot": "A4I3I3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3I3"
  },
  {
    "uniprot": "A4I8Y1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Y1"
  },
  {
    "uniprot": "A4I8R7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8R7"
  },
  {
    "uniprot": "A4I2H1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2H1"
  },
  {
    "uniprot": "A4I4X5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4X5"
  },
  {
    "uniprot": "A4I2S6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2S6"
  },
  {
    "uniprot": "A4IDG1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDG1"
  },
  {
    "uniprot": "A4I1G5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1G5"
  },
  {
    "uniprot": "A4HUV0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUV0"
  },
  {
    "uniprot": "A4HYK5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYK5"
  },
  {
    "uniprot": "A4ICV6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICV6"
  },
  {
    "uniprot": "A0A381N054",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381N054"
  },
  {
    "uniprot": "A4HSW6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSW6"
  },
  {
    "uniprot": "E9AGN2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGN2"
  },
  {
    "uniprot": "A4HX32",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX32"
  },
  {
    "uniprot": "A4I2G8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2G8"
  },
  {
    "uniprot": "E9AGC2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGC2"
  },
  {
    "uniprot": "A4HUE0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUE0"
  },
  {
    "uniprot": "E9AGE5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGE5"
  },
  {
    "uniprot": "A4IDB7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDB7"
  },
  {
    "uniprot": "A4HXY1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXY1"
  },
  {
    "uniprot": "A4HWU7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWU7"
  },
  {
    "uniprot": "A4I6A9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6A9"
  },
  {
    "uniprot": "A4I2A8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2A8"
  },
  {
    "uniprot": "A4HYS4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYS4"
  },
  {
    "uniprot": "A4HU18",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU18"
  },
  {
    "uniprot": "A4I1K0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1K0"
  },
  {
    "uniprot": "A4HVU1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVU1"
  },
  {
    "uniprot": "A4I8F8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8F8"
  },
  {
    "uniprot": "A4I1Z9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Z9"
  },
  {
    "uniprot": "A4HYN2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYN2"
  },
  {
    "uniprot": "A4HWI5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWI5"
  },
  {
    "uniprot": "A4HX12",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX12"
  },
  {
    "uniprot": "A4I6L3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6L3"
  },
  {
    "uniprot": "A4I142",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I142"
  },
  {
    "uniprot": "A4HXG1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXG1"
  },
  {
    "uniprot": "A4HXX6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXX6"
  },
  {
    "uniprot": "A4I043",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I043"
  },
  {
    "uniprot": "A4HVL5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVL5"
  },
  {
    "uniprot": "A4IAJ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAJ2"
  },
  {
    "uniprot": "A4I5B1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5B1"
  },
  {
    "uniprot": "A4HVX7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVX7"
  },
  {
    "uniprot": "A4HX59",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX59"
  },
  {
    "uniprot": "A4HW33",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW33"
  },
  {
    "uniprot": "A4I7Z0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Z0"
  },
  {
    "uniprot": "E9AHA8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHA8"
  },
  {
    "uniprot": "A4IBT7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBT7"
  },
  {
    "uniprot": "A4I048",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I048"
  },
  {
    "uniprot": "A4I3N1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3N1"
  },
  {
    "uniprot": "A4IE06",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE06"
  },
  {
    "uniprot": "A4I7I8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7I8"
  },
  {
    "uniprot": "A4HTF8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTF8"
  },
  {
    "uniprot": "A4I7N2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7N2"
  },
  {
    "uniprot": "E9AH32",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH32"
  },
  {
    "uniprot": "A4HT77",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT77"
  },
  {
    "uniprot": "A4HZ75",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ75"
  },
  {
    "uniprot": "A4I2S1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2S1"
  },
  {
    "uniprot": "A4I3M6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3M6"
  },
  {
    "uniprot": "A4I7H4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7H4"
  },
  {
    "uniprot": "A4IAX1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAX1"
  },
  {
    "uniprot": "A4I930",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I930"
  },
  {
    "uniprot": "E9AGN9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGN9"
  },
  {
    "uniprot": "A4I0K7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0K7"
  },
  {
    "uniprot": "A4HW40",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW40"
  },
  {
    "uniprot": "A4IDE7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDE7"
  },
  {
    "uniprot": "A4ID72",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID72"
  },
  {
    "uniprot": "E9AGY7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGY7"
  },
  {
    "uniprot": "A4HXA9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXA9"
  },
  {
    "uniprot": "E9AH86",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH86"
  },
  {
    "uniprot": "E8NHC6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E8NHC6"
  },
  {
    "uniprot": "A4I4X4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4X4"
  },
  {
    "uniprot": "A4I304",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I304"
  },
  {
    "uniprot": "A4I8K4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8K4"
  },
  {
    "uniprot": "A4I2P4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2P4"
  },
  {
    "uniprot": "A4HTH2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTH2"
  },
  {
    "uniprot": "A4HX00",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX00"
  },
  {
    "uniprot": "A4I3E4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3E4"
  },
  {
    "uniprot": "A4I081",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I081"
  },
  {
    "uniprot": "A4IDH1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDH1"
  },
  {
    "uniprot": "A4HU77",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU77"
  },
  {
    "uniprot": "A4I935",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I935"
  },
  {
    "uniprot": "A4HRF0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRF0"
  },
  {
    "uniprot": "A4I7Z9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Z9"
  },
  {
    "uniprot": "A4I8E1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8E1"
  },
  {
    "uniprot": "E8NHC9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E8NHC9"
  },
  {
    "uniprot": "A4IDW9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDW9"
  },
  {
    "uniprot": "A4ID37",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID37"
  },
  {
    "uniprot": "A4HUW1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUW1"
  },
  {
    "uniprot": "A4I2U0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2U0"
  },
  {
    "uniprot": "A4I5L8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5L8"
  },
  {
    "uniprot": "A4HV23",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV23"
  },
  {
    "uniprot": "A4HT85",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT85"
  },
  {
    "uniprot": "A4IDW1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDW1"
  },
  {
    "uniprot": "A4I8P4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8P4"
  },
  {
    "uniprot": "A4I9P6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9P6"
  },
  {
    "uniprot": "A4IC69",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC69"
  },
  {
    "uniprot": "A4I308",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I308"
  },
  {
    "uniprot": "A4IAS0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAS0"
  },
  {
    "uniprot": "A4IBR5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBR5"
  },
  {
    "uniprot": "A4HRJ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRJ8"
  },
  {
    "uniprot": "A4IDR6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDR6"
  },
  {
    "uniprot": "A4HV36",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV36"
  },
  {
    "uniprot": "A4IB70",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB70"
  },
  {
    "uniprot": "A4HS91",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS91"
  },
  {
    "uniprot": "E9AH12",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH12"
  },
  {
    "uniprot": "E9AGD6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGD6"
  },
  {
    "uniprot": "E9AG31",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG31"
  },
  {
    "uniprot": "A4I7R9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7R9"
  },
  {
    "uniprot": "A4HWP3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWP3"
  },
  {
    "uniprot": "A4I4F5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4F5"
  },
  {
    "uniprot": "A4HVZ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVZ6"
  },
  {
    "uniprot": "A4HST4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HST4"
  },
  {
    "uniprot": "A4I7Z2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Z2"
  },
  {
    "uniprot": "A4IBB6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBB6"
  },
  {
    "uniprot": "A4I4A8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4A8"
  },
  {
    "uniprot": "A4I1W7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1W7"
  },
  {
    "uniprot": "A4I3V5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3V5"
  },
  {
    "uniprot": "A4HX89",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX89"
  },
  {
    "uniprot": "A4I1L1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1L1"
  },
  {
    "uniprot": "A4HYQ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYQ0"
  },
  {
    "uniprot": "A4HT76",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT76"
  },
  {
    "uniprot": "A4I565",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I565"
  },
  {
    "uniprot": "A4IBD2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBD2"
  },
  {
    "uniprot": "A4ICA5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICA5"
  },
  {
    "uniprot": "A4HXI0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXI0"
  },
  {
    "uniprot": "A4I338",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I338"
  },
  {
    "uniprot": "E9AH08",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH08"
  },
  {
    "uniprot": "A4HWY4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWY4"
  },
  {
    "uniprot": "A4I1F5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1F5"
  },
  {
    "uniprot": "A4I3H4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3H4"
  },
  {
    "uniprot": "E9AHV2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHV2"
  },
  {
    "uniprot": "A4I7Z7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Z7"
  },
  {
    "uniprot": "E9AG22",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG22"
  },
  {
    "uniprot": "A4HZS6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZS6"
  },
  {
    "uniprot": "A4HYP4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYP4"
  },
  {
    "uniprot": "E9AGB2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGB2"
  },
  {
    "uniprot": "A4I353",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I353"
  },
  {
    "uniprot": "A4I918",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I918"
  },
  {
    "uniprot": "A4I3Z8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Z8"
  },
  {
    "uniprot": "A4HVY3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVY3"
  },
  {
    "uniprot": "A4HWC0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWC0"
  },
  {
    "uniprot": "A4HSF7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSF7"
  },
  {
    "uniprot": "A4IBM3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBM3"
  },
  {
    "uniprot": "A4IA97",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA97"
  },
  {
    "uniprot": "A4HRD7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRD7"
  },
  {
    "uniprot": "A4I4F2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4F2"
  },
  {
    "uniprot": "A4I5D8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5D8"
  },
  {
    "uniprot": "A4IC62",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC62"
  },
  {
    "uniprot": "A4HTD7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTD7"
  },
  {
    "uniprot": "A4I1V2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1V2"
  },
  {
    "uniprot": "A4I7X2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7X2"
  },
  {
    "uniprot": "A4IBQ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBQ9"
  },
  {
    "uniprot": "A4I4Q2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Q2"
  },
  {
    "uniprot": "A4I2F2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2F2"
  },
  {
    "uniprot": "A4ID12",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID12"
  },
  {
    "uniprot": "A4IDD0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDD0"
  },
  {
    "uniprot": "A4I2V3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2V3"
  },
  {
    "uniprot": "A4I9M1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9M1"
  },
  {
    "uniprot": "A4I3T0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3T0"
  },
  {
    "uniprot": "A4HV20",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV20"
  },
  {
    "uniprot": "A4IE29",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE29"
  },
  {
    "uniprot": "A4I7B8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7B8"
  },
  {
    "uniprot": "A4I1Y6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Y6"
  },
  {
    "uniprot": "A4ICL0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICL0"
  },
  {
    "uniprot": "A4HVV4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVV4"
  },
  {
    "uniprot": "E9AH19",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH19"
  },
  {
    "uniprot": "A4I1I8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1I8"
  },
  {
    "uniprot": "E9AHI8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHI8"
  },
  {
    "uniprot": "E9AHK0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHK0"
  },
  {
    "uniprot": "A4I869",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I869"
  },
  {
    "uniprot": "A4HSC6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSC6"
  },
  {
    "uniprot": "A4I2Q6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Q6"
  },
  {
    "uniprot": "A4I3P6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3P6"
  },
  {
    "uniprot": "A4HWL2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWL2"
  },
  {
    "uniprot": "A4I9P5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9P5"
  },
  {
    "uniprot": "A4HUR4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUR4"
  },
  {
    "uniprot": "A4I3Y7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Y7"
  },
  {
    "uniprot": "A4HTY4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTY4"
  },
  {
    "uniprot": "A4HW39",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW39"
  },
  {
    "uniprot": "A4I0H3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0H3"
  },
  {
    "uniprot": "A4IC20",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC20"
  },
  {
    "uniprot": "A4I0M4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0M4"
  },
  {
    "uniprot": "A4I9I4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9I4"
  },
  {
    "uniprot": "A4I5X2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5X2"
  },
  {
    "uniprot": "A4I981",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I981"
  },
  {
    "uniprot": "A4I1Q6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Q6"
  },
  {
    "uniprot": "A4I4H6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4H6"
  },
  {
    "uniprot": "A4IC28",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC28"
  },
  {
    "uniprot": "A4HU64",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU64"
  },
  {
    "uniprot": "A4I740",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I740"
  },
  {
    "uniprot": "A4HRP1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRP1"
  },
  {
    "uniprot": "A4I7V9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7V9"
  },
  {
    "uniprot": "A4IAK7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAK7"
  },
  {
    "uniprot": "A4I9H7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9H7"
  },
  {
    "uniprot": "A4HUV6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUV6"
  },
  {
    "uniprot": "A4I8P1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8P1"
  },
  {
    "uniprot": "E9AH56",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH56"
  },
  {
    "uniprot": "A4HW80",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW80"
  },
  {
    "uniprot": "A4HTK0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTK0"
  },
  {
    "uniprot": "A4IE03",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE03"
  },
  {
    "uniprot": "A4I941",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I941"
  },
  {
    "uniprot": "A4IAZ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAZ2"
  },
  {
    "uniprot": "A4I9U7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9U7"
  },
  {
    "uniprot": "A4IBG2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBG2"
  },
  {
    "uniprot": "A4HWX1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWX1"
  },
  {
    "uniprot": "A4HTI8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTI8"
  },
  {
    "uniprot": "A4I5R3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5R3"
  },
  {
    "uniprot": "A4HTZ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTZ3"
  },
  {
    "uniprot": "A4I2R4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2R4"
  },
  {
    "uniprot": "A4I236",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I236"
  },
  {
    "uniprot": "A4HSE1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSE1"
  },
  {
    "uniprot": "A4I5B0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5B0"
  },
  {
    "uniprot": "A4I6E5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6E5"
  },
  {
    "uniprot": "A4HXM0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXM0"
  },
  {
    "uniprot": "A4HZC8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZC8"
  },
  {
    "uniprot": "A4HW59",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW59"
  },
  {
    "uniprot": "A4HT27",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT27"
  },
  {
    "uniprot": "A4HRV0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRV0"
  },
  {
    "uniprot": "A4I399",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I399"
  },
  {
    "uniprot": "A4HW12",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW12"
  },
  {
    "uniprot": "A4HXV4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXV4"
  },
  {
    "uniprot": "A4I2E8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2E8"
  },
  {
    "uniprot": "A4I853",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I853"
  },
  {
    "uniprot": "A4I9E1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9E1"
  },
  {
    "uniprot": "A4HYQ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYQ4"
  },
  {
    "uniprot": "A4HY33",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY33"
  },
  {
    "uniprot": "A4I4N6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4N6"
  },
  {
    "uniprot": "A4HVS0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVS0"
  },
  {
    "uniprot": "A4HRH1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRH1"
  },
  {
    "uniprot": "A4HX49",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX49"
  },
  {
    "uniprot": "A4I5K9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5K9"
  },
  {
    "uniprot": "A4I372",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I372"
  },
  {
    "uniprot": "A4IAF3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAF3"
  },
  {
    "uniprot": "A4HSJ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSJ1"
  },
  {
    "uniprot": "A4I105",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I105"
  },
  {
    "uniprot": "A4HZZ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZZ8"
  },
  {
    "uniprot": "A4I1M0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1M0"
  },
  {
    "uniprot": "A4I8B0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8B0"
  },
  {
    "uniprot": "A4IAC3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAC3"
  },
  {
    "uniprot": "A4HUN6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUN6"
  },
  {
    "uniprot": "A4I0S0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0S0"
  },
  {
    "uniprot": "A4IAH3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAH3"
  },
  {
    "uniprot": "A4IAI8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAI8"
  },
  {
    "uniprot": "A4I6U2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6U2"
  },
  {
    "uniprot": "A4HVN2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVN2"
  },
  {
    "uniprot": "E9AGM4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGM4"
  },
  {
    "uniprot": "A4HYS2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYS2"
  },
  {
    "uniprot": "A4HZV1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZV1"
  },
  {
    "uniprot": "A4HZ59",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ59"
  },
  {
    "uniprot": "A4I3E3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3E3"
  },
  {
    "uniprot": "A4HW14",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW14"
  },
  {
    "uniprot": "A4I5U3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5U3"
  },
  {
    "uniprot": "A4IC97",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC97"
  },
  {
    "uniprot": "A4IC52",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC52"
  },
  {
    "uniprot": "E9AHS8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHS8"
  },
  {
    "uniprot": "A4I276",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I276"
  },
  {
    "uniprot": "A4HRT5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRT5"
  },
  {
    "uniprot": "A4HYQ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYQ3"
  },
  {
    "uniprot": "A4I6T1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6T1"
  },
  {
    "uniprot": "A4HTH3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTH3"
  },
  {
    "uniprot": "A4I9Q1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Q1"
  },
  {
    "uniprot": "A4HXP4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXP4"
  },
  {
    "uniprot": "A4ICW2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICW2"
  },
  {
    "uniprot": "A4I8T4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8T4"
  },
  {
    "uniprot": "A4HYU1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYU1"
  },
  {
    "uniprot": "A4HZ84",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ84"
  },
  {
    "uniprot": "A4ICB3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICB3"
  },
  {
    "uniprot": "A4I3K6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3K6"
  },
  {
    "uniprot": "A4I588",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I588"
  },
  {
    "uniprot": "A4I3S7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3S7"
  },
  {
    "uniprot": "A4I2N8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2N8"
  },
  {
    "uniprot": "A4I2I5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2I5"
  },
  {
    "uniprot": "A4HSJ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSJ7"
  },
  {
    "uniprot": "A4I7P6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7P6"
  },
  {
    "uniprot": "A4I0I8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0I8"
  },
  {
    "uniprot": "A4I5I3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5I3"
  },
  {
    "uniprot": "E9AG63",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG63"
  },
  {
    "uniprot": "A4HXP6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXP6"
  },
  {
    "uniprot": "A4IBK1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBK1"
  },
  {
    "uniprot": "A4IBZ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBZ9"
  },
  {
    "uniprot": "A4I8C8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8C8"
  },
  {
    "uniprot": "A4HWH0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWH0"
  },
  {
    "uniprot": "A4IBU5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBU5"
  },
  {
    "uniprot": "A4IBE7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBE7"
  },
  {
    "uniprot": "A4I5U8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5U8"
  },
  {
    "uniprot": "A4HUS2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUS2"
  },
  {
    "uniprot": "A4HXQ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXQ9"
  },
  {
    "uniprot": "A4I6G2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6G2"
  },
  {
    "uniprot": "E8NHD2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E8NHD2"
  },
  {
    "uniprot": "A4HSL0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSL0"
  },
  {
    "uniprot": "A4HSJ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSJ0"
  },
  {
    "uniprot": "A4HWP6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWP6"
  },
  {
    "uniprot": "A4IBE9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBE9"
  },
  {
    "uniprot": "A4IA64",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA64"
  },
  {
    "uniprot": "A4HZM1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZM1"
  },
  {
    "uniprot": "A4IB96",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB96"
  },
  {
    "uniprot": "A4HZ64",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ64"
  },
  {
    "uniprot": "A4I4X3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4X3"
  },
  {
    "uniprot": "A4I9A2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9A2"
  },
  {
    "uniprot": "A4I9I9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9I9"
  },
  {
    "uniprot": "E9AHI2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHI2"
  },
  {
    "uniprot": "A4HWG9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWG9"
  },
  {
    "uniprot": "A4HRH8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRH8"
  },
  {
    "uniprot": "A4I4C6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4C6"
  },
  {
    "uniprot": "A4I3L1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3L1"
  },
  {
    "uniprot": "A4IDC6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDC6"
  },
  {
    "uniprot": "A4IBN0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBN0"
  },
  {
    "uniprot": "Q9NJS2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q9NJS2"
  },
  {
    "uniprot": "A4HSI7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSI7"
  },
  {
    "uniprot": "A4IB08",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB08"
  },
  {
    "uniprot": "A4HT33",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT33"
  },
  {
    "uniprot": "A4I854",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I854"
  },
  {
    "uniprot": "A4HUM8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUM8"
  },
  {
    "uniprot": "A4I3J5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3J5"
  },
  {
    "uniprot": "A4IAG5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAG5"
  },
  {
    "uniprot": "A4HZ63",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ63"
  },
  {
    "uniprot": "A4HY92",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY92"
  },
  {
    "uniprot": "A4HRY1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRY1"
  },
  {
    "uniprot": "E9AH23",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH23"
  },
  {
    "uniprot": "A4HRN9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRN9"
  },
  {
    "uniprot": "A4I6A3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6A3"
  },
  {
    "uniprot": "A4IBP2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBP2"
  },
  {
    "uniprot": "A4IDB5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDB5"
  },
  {
    "uniprot": "A4I9H8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9H8"
  },
  {
    "uniprot": "E9AGV1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGV1"
  },
  {
    "uniprot": "A4HY78",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY78"
  },
  {
    "uniprot": "A4HWT6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWT6"
  },
  {
    "uniprot": "A4I478",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I478"
  },
  {
    "uniprot": "A4HSV7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSV7"
  },
  {
    "uniprot": "A4IC89",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC89"
  },
  {
    "uniprot": "A4I9C4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9C4"
  },
  {
    "uniprot": "A4I089",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I089"
  },
  {
    "uniprot": "A4HZ85",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ85"
  },
  {
    "uniprot": "A4HS46",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS46"
  },
  {
    "uniprot": "A4HSP7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSP7"
  },
  {
    "uniprot": "A4IBU6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBU6"
  },
  {
    "uniprot": "A4HTJ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTJ6"
  },
  {
    "uniprot": "A4I9X6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9X6"
  },
  {
    "uniprot": "A4I7Y8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Y8"
  },
  {
    "uniprot": "A4IDT8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDT8"
  },
  {
    "uniprot": "A4ICZ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICZ2"
  },
  {
    "uniprot": "A4IAK8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAK8"
  },
  {
    "uniprot": "A4I7K3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7K3"
  },
  {
    "uniprot": "A4I044",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I044"
  },
  {
    "uniprot": "A4I4B0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4B0"
  },
  {
    "uniprot": "E9AHC5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHC5"
  },
  {
    "uniprot": "A4I212",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I212"
  },
  {
    "uniprot": "A4HVJ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVJ9"
  },
  {
    "uniprot": "A4HWK9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWK9"
  },
  {
    "uniprot": "A4HY27",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY27"
  },
  {
    "uniprot": "A4HWK6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWK6"
  },
  {
    "uniprot": "A4IBR2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBR2"
  },
  {
    "uniprot": "A4HV38",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV38"
  },
  {
    "uniprot": "A4HX05",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX05"
  },
  {
    "uniprot": "A4ID04",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID04"
  },
  {
    "uniprot": "A4HVE8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVE8"
  },
  {
    "uniprot": "A4I2Y7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Y7"
  },
  {
    "uniprot": "A4I394",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I394"
  },
  {
    "uniprot": "A4I856",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I856"
  },
  {
    "uniprot": "A4I2I3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2I3"
  },
  {
    "uniprot": "A4I2U8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2U8"
  },
  {
    "uniprot": "A4ICW0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICW0"
  },
  {
    "uniprot": "A4HRQ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRQ7"
  },
  {
    "uniprot": "A4I4T3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4T3"
  },
  {
    "uniprot": "A4HZL1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZL1"
  },
  {
    "uniprot": "A4I923",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I923"
  },
  {
    "uniprot": "A4I1J0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1J0"
  },
  {
    "uniprot": "A4I4M5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4M5"
  },
  {
    "uniprot": "A4I748",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I748"
  },
  {
    "uniprot": "A4I7A5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7A5"
  },
  {
    "uniprot": "A4I427",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I427"
  },
  {
    "uniprot": "A4ICV5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICV5"
  },
  {
    "uniprot": "A4I1X7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1X7"
  },
  {
    "uniprot": "A4IC05",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC05"
  },
  {
    "uniprot": "A4HZ08",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ08"
  },
  {
    "uniprot": "A4HVF6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVF6"
  },
  {
    "uniprot": "A4I2J3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2J3"
  },
  {
    "uniprot": "A4HRE0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRE0"
  },
  {
    "uniprot": "A4HV54",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV54"
  },
  {
    "uniprot": "A4HZB2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZB2"
  },
  {
    "uniprot": "E9AHG5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHG5"
  },
  {
    "uniprot": "A4HXK1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXK1"
  },
  {
    "uniprot": "A4I218",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I218"
  },
  {
    "uniprot": "A4HWG0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWG0"
  },
  {
    "uniprot": "A4HRE1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRE1"
  },
  {
    "uniprot": "A4I449",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I449"
  },
  {
    "uniprot": "A4HTJ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTJ3"
  },
  {
    "uniprot": "A4I1K1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1K1"
  },
  {
    "uniprot": "A4I2Z8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Z8"
  },
  {
    "uniprot": "A4HYL1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYL1"
  },
  {
    "uniprot": "A4HT96",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT96"
  },
  {
    "uniprot": "A4I8F1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8F1"
  },
  {
    "uniprot": "A4HTH8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTH8"
  },
  {
    "uniprot": "A4HYW4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYW4"
  },
  {
    "uniprot": "A4HT29",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT29"
  },
  {
    "uniprot": "A4HUW8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUW8"
  },
  {
    "uniprot": "A4HWP9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWP9"
  },
  {
    "uniprot": "A4HUW9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUW9"
  },
  {
    "uniprot": "A4IA68",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA68"
  },
  {
    "uniprot": "A4HWF1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWF1"
  },
  {
    "uniprot": "A4HSS4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSS4"
  },
  {
    "uniprot": "A4I0K2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0K2"
  },
  {
    "uniprot": "A4HRV7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRV7"
  },
  {
    "uniprot": "A4IBI0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBI0"
  },
  {
    "uniprot": "A4I4I0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4I0"
  },
  {
    "uniprot": "A4HRW0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRW0"
  },
  {
    "uniprot": "A4I2X6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2X6"
  },
  {
    "uniprot": "A4I9Y6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Y6"
  },
  {
    "uniprot": "A4ICQ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICQ8"
  },
  {
    "uniprot": "A4I123",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I123"
  },
  {
    "uniprot": "A4HXX3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXX3"
  },
  {
    "uniprot": "Q8I0E1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q8I0E1"
  },
  {
    "uniprot": "A4HTN1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTN1"
  },
  {
    "uniprot": "A4I5Z9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Z9"
  },
  {
    "uniprot": "A4I2K6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2K6"
  },
  {
    "uniprot": "A4I584",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I584"
  },
  {
    "uniprot": "A4I5X1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5X1"
  },
  {
    "uniprot": "A4HXF9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXF9"
  },
  {
    "uniprot": "E9AGV3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGV3"
  },
  {
    "uniprot": "A4HUE3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUE3"
  },
  {
    "uniprot": "A4I709",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I709"
  },
  {
    "uniprot": "A4I3N0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3N0"
  },
  {
    "uniprot": "A4HYL5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYL5"
  },
  {
    "uniprot": "E9AGU2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGU2"
  },
  {
    "uniprot": "A4I7L4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7L4"
  },
  {
    "uniprot": "A4I1T9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1T9"
  },
  {
    "uniprot": "A4HY54",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY54"
  },
  {
    "uniprot": "A4ID00",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID00"
  },
  {
    "uniprot": "A4HW71",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW71"
  },
  {
    "uniprot": "A4HSX6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSX6"
  },
  {
    "uniprot": "E9AHT5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHT5"
  },
  {
    "uniprot": "E9AHU0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHU0"
  },
  {
    "uniprot": "A4HZ39",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ39"
  },
  {
    "uniprot": "A4I1Z5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Z5"
  },
  {
    "uniprot": "A4IDU1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDU1"
  },
  {
    "uniprot": "A4I3U1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3U1"
  },
  {
    "uniprot": "A4I7T3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7T3"
  },
  {
    "uniprot": "A4ID28",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID28"
  },
  {
    "uniprot": "A4ID46",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID46"
  },
  {
    "uniprot": "A4HVJ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVJ6"
  },
  {
    "uniprot": "A4I3R1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3R1"
  },
  {
    "uniprot": "A4I8Q9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Q9"
  },
  {
    "uniprot": "A4I5A0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5A0"
  },
  {
    "uniprot": "A4I3U9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3U9"
  },
  {
    "uniprot": "A4IBQ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBQ6"
  },
  {
    "uniprot": "A4HSH1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSH1"
  },
  {
    "uniprot": "A4I594",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I594"
  },
  {
    "uniprot": "A4I2P2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2P2"
  },
  {
    "uniprot": "E9AH48",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH48"
  },
  {
    "uniprot": "A0A381MCG8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MCG8"
  },
  {
    "uniprot": "A4IBG7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBG7"
  },
  {
    "uniprot": "A4I1B1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1B1"
  },
  {
    "uniprot": "A4HUV9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUV9"
  },
  {
    "uniprot": "A4I607",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I607"
  },
  {
    "uniprot": "A4I2Q4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Q4"
  },
  {
    "uniprot": "A4ID17",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID17"
  },
  {
    "uniprot": "A4HTF2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTF2"
  },
  {
    "uniprot": "A4IE36",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE36"
  },
  {
    "uniprot": "A4I206",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I206"
  },
  {
    "uniprot": "E9AHN4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHN4"
  },
  {
    "uniprot": "A4HVM6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVM6"
  },
  {
    "uniprot": "A4HT63",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT63"
  },
  {
    "uniprot": "A4I6K0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6K0"
  },
  {
    "uniprot": "A4I7P9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7P9"
  },
  {
    "uniprot": "A4I0C8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0C8"
  },
  {
    "uniprot": "A4HXM9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXM9"
  },
  {
    "uniprot": "A0A381MTN1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MTN1"
  },
  {
    "uniprot": "A4ICE2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICE2"
  },
  {
    "uniprot": "E9AHN1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHN1"
  },
  {
    "uniprot": "A4HXD3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXD3"
  },
  {
    "uniprot": "A4I4K3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4K3"
  },
  {
    "uniprot": "A4I1H5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1H5"
  },
  {
    "uniprot": "A4HWJ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWJ1"
  },
  {
    "uniprot": "A4I526",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I526"
  },
  {
    "uniprot": "E9AH29",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH29"
  },
  {
    "uniprot": "A4HTH1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTH1"
  },
  {
    "uniprot": "E9AHM5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHM5"
  },
  {
    "uniprot": "A4HYN4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYN4"
  },
  {
    "uniprot": "A4I6N7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6N7"
  },
  {
    "uniprot": "A4IA71",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA71"
  },
  {
    "uniprot": "A4I4F6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4F6"
  },
  {
    "uniprot": "A4I067",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I067"
  },
  {
    "uniprot": "A4HXZ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXZ9"
  },
  {
    "uniprot": "A4HRF7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRF7"
  },
  {
    "uniprot": "A4HWX2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWX2"
  },
  {
    "uniprot": "A4HZQ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZQ5"
  },
  {
    "uniprot": "A4HUT9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUT9"
  },
  {
    "uniprot": "E9AHV4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHV4"
  },
  {
    "uniprot": "A4I879",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I879"
  },
  {
    "uniprot": "A4I756",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I756"
  },
  {
    "uniprot": "A4IAH8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAH8"
  },
  {
    "uniprot": "A4I4S7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4S7"
  },
  {
    "uniprot": "A4HSS0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSS0"
  },
  {
    "uniprot": "E9AHF9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHF9"
  },
  {
    "uniprot": "A4I7L5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7L5"
  },
  {
    "uniprot": "A4I8S2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8S2"
  },
  {
    "uniprot": "A4HZS0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZS0"
  },
  {
    "uniprot": "A4I0Q7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Q7"
  },
  {
    "uniprot": "A4HXG7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXG7"
  },
  {
    "uniprot": "A4HU23",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU23"
  },
  {
    "uniprot": "A4I995",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I995"
  },
  {
    "uniprot": "A4I570",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I570"
  },
  {
    "uniprot": "A4HUC9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUC9"
  },
  {
    "uniprot": "A4HX19",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX19"
  },
  {
    "uniprot": "A4I852",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I852"
  },
  {
    "uniprot": "A4I7B9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7B9"
  },
  {
    "uniprot": "A4I6L0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6L0"
  },
  {
    "uniprot": "A4I2T9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2T9"
  },
  {
    "uniprot": "A4I337",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I337"
  },
  {
    "uniprot": "E9AHW3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHW3"
  },
  {
    "uniprot": "A4HWF5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWF5"
  },
  {
    "uniprot": "A4IAX8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAX8"
  },
  {
    "uniprot": "A4I435",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I435"
  },
  {
    "uniprot": "A4HYC3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYC3"
  },
  {
    "uniprot": "A4HWG3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWG3"
  },
  {
    "uniprot": "A4HS99",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS99"
  },
  {
    "uniprot": "A4I1E4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1E4"
  },
  {
    "uniprot": "A4HW44",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW44"
  },
  {
    "uniprot": "A4I4C8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4C8"
  },
  {
    "uniprot": "A4IBU1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBU1"
  },
  {
    "uniprot": "A4HWF9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWF9"
  },
  {
    "uniprot": "A4HUF0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUF0"
  },
  {
    "uniprot": "A4HZB5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZB5"
  },
  {
    "uniprot": "A4HX20",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX20"
  },
  {
    "uniprot": "A4ICT0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICT0"
  },
  {
    "uniprot": "A4HTB5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTB5"
  },
  {
    "uniprot": "A4IDC2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDC2"
  },
  {
    "uniprot": "A4I0B4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0B4"
  },
  {
    "uniprot": "A4HTN8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTN8"
  },
  {
    "uniprot": "A4I9J6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9J6"
  },
  {
    "uniprot": "A4I1M6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1M6"
  },
  {
    "uniprot": "A4HY69",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY69"
  },
  {
    "uniprot": "A4IAP2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAP2"
  },
  {
    "uniprot": "A4I8J6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8J6"
  },
  {
    "uniprot": "A4I377",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I377"
  },
  {
    "uniprot": "A4HXM3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXM3"
  },
  {
    "uniprot": "A4I0S6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0S6"
  },
  {
    "uniprot": "A4HWQ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWQ6"
  },
  {
    "uniprot": "A4HRS1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRS1"
  },
  {
    "uniprot": "A4I0C7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0C7"
  },
  {
    "uniprot": "A4I0H7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0H7"
  },
  {
    "uniprot": "A4IE39",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE39"
  },
  {
    "uniprot": "A4I2Q9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Q9"
  },
  {
    "uniprot": "A4ICD1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICD1"
  },
  {
    "uniprot": "A4HWE8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWE8"
  },
  {
    "uniprot": "A4I5F3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5F3"
  },
  {
    "uniprot": "A4I8A5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8A5"
  },
  {
    "uniprot": "A4HXS8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXS8"
  },
  {
    "uniprot": "Q9N9V5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q9N9V5"
  },
  {
    "uniprot": "A4I2C1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2C1"
  },
  {
    "uniprot": "A4IAR2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAR2"
  },
  {
    "uniprot": "A4HRJ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRJ5"
  },
  {
    "uniprot": "A4HT41",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT41"
  },
  {
    "uniprot": "A4I0K1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0K1"
  },
  {
    "uniprot": "A4I5C5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5C5"
  },
  {
    "uniprot": "A4I5Y6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Y6"
  },
  {
    "uniprot": "A4HSU4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSU4"
  },
  {
    "uniprot": "A4IAC9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAC9"
  },
  {
    "uniprot": "A4IB83",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB83"
  },
  {
    "uniprot": "A4HU46",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU46"
  },
  {
    "uniprot": "A4I9C5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9C5"
  },
  {
    "uniprot": "A4I6V4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6V4"
  },
  {
    "uniprot": "A4HZW1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZW1"
  },
  {
    "uniprot": "A4HTU0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTU0"
  },
  {
    "uniprot": "A4HX60",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX60"
  },
  {
    "uniprot": "A4IBF3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBF3"
  },
  {
    "uniprot": "A4HV31",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV31"
  },
  {
    "uniprot": "A4I5D7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5D7"
  },
  {
    "uniprot": "A4I2M4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2M4"
  },
  {
    "uniprot": "A4I1X2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1X2"
  },
  {
    "uniprot": "A4ID97",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID97"
  },
  {
    "uniprot": "A4HWT9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWT9"
  },
  {
    "uniprot": "A4I482",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I482"
  },
  {
    "uniprot": "A4HWA7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWA7"
  },
  {
    "uniprot": "A4HTD0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTD0"
  },
  {
    "uniprot": "A4I6S4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6S4"
  },
  {
    "uniprot": "A4HV45",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV45"
  },
  {
    "uniprot": "A4HXY4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXY4"
  },
  {
    "uniprot": "A4I3P2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3P2"
  },
  {
    "uniprot": "A4I2A1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2A1"
  },
  {
    "uniprot": "E9AGH6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGH6"
  },
  {
    "uniprot": "A4HXF7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXF7"
  },
  {
    "uniprot": "A4HSL1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSL1"
  },
  {
    "uniprot": "A4I4X9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4X9"
  },
  {
    "uniprot": "E9AG91",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG91"
  },
  {
    "uniprot": "A4I0M9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0M9"
  },
  {
    "uniprot": "A4I7Z4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Z4"
  },
  {
    "uniprot": "A4HS04",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS04"
  },
  {
    "uniprot": "A4HYC7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYC7"
  },
  {
    "uniprot": "A4HRD4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRD4"
  },
  {
    "uniprot": "A4I6K6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6K6"
  },
  {
    "uniprot": "A4IA56",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA56"
  },
  {
    "uniprot": "A4IBF1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBF1"
  },
  {
    "uniprot": "A4HUL4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUL4"
  },
  {
    "uniprot": "A4ICI8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICI8"
  },
  {
    "uniprot": "A4IBL1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBL1"
  },
  {
    "uniprot": "A4HSM7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSM7"
  },
  {
    "uniprot": "A4IC44",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC44"
  },
  {
    "uniprot": "A4HVT4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVT4"
  },
  {
    "uniprot": "A4IDW0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDW0"
  },
  {
    "uniprot": "E9AGL3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGL3"
  },
  {
    "uniprot": "A4HSE6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSE6"
  },
  {
    "uniprot": "A4HSI3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSI3"
  },
  {
    "uniprot": "E9AGP1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGP1"
  },
  {
    "uniprot": "A4IDF5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDF5"
  },
  {
    "uniprot": "A4I865",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I865"
  },
  {
    "uniprot": "A4HTB8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTB8"
  },
  {
    "uniprot": "A4IDA0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDA0"
  },
  {
    "uniprot": "A4HZP4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZP4"
  },
  {
    "uniprot": "A4IBX9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBX9"
  },
  {
    "uniprot": "A4HSQ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSQ1"
  },
  {
    "uniprot": "A4HSC4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSC4"
  },
  {
    "uniprot": "A4HX29",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX29"
  },
  {
    "uniprot": "A4I934",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I934"
  },
  {
    "uniprot": "A4I4B4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4B4"
  },
  {
    "uniprot": "A4I9D4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9D4"
  },
  {
    "uniprot": "E9AGN1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGN1"
  },
  {
    "uniprot": "A4I9K7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9K7"
  },
  {
    "uniprot": "A4HS52",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS52"
  },
  {
    "uniprot": "A4I336",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I336"
  },
  {
    "uniprot": "A4HSR9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSR9"
  },
  {
    "uniprot": "A4HUM7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUM7"
  },
  {
    "uniprot": "A4HW21",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW21"
  },
  {
    "uniprot": "A4HX21",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX21"
  },
  {
    "uniprot": "A4I3T4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3T4"
  },
  {
    "uniprot": "A4IAN1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAN1"
  },
  {
    "uniprot": "A4IAN7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAN7"
  },
  {
    "uniprot": "A4I7Y3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Y3"
  },
  {
    "uniprot": "A4I4M1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4M1"
  },
  {
    "uniprot": "A4IDU9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDU9"
  },
  {
    "uniprot": "A4HUY1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUY1"
  },
  {
    "uniprot": "A4I0T8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0T8"
  },
  {
    "uniprot": "A4I9B6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9B6"
  },
  {
    "uniprot": "A4ID11",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID11"
  },
  {
    "uniprot": "A4HYD5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYD5"
  },
  {
    "uniprot": "A4I7T2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7T2"
  },
  {
    "uniprot": "A4ICV4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICV4"
  },
  {
    "uniprot": "A4I1W6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1W6"
  },
  {
    "uniprot": "A4I078",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I078"
  },
  {
    "uniprot": "A4IBL2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBL2"
  },
  {
    "uniprot": "A4HVZ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVZ1"
  },
  {
    "uniprot": "A4HY41",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY41"
  },
  {
    "uniprot": "A4I008",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I008"
  },
  {
    "uniprot": "A4ID86",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID86"
  },
  {
    "uniprot": "E9AH18",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH18"
  },
  {
    "uniprot": "A4I604",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I604"
  },
  {
    "uniprot": "A4IAL6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAL6"
  },
  {
    "uniprot": "A4I955",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I955"
  },
  {
    "uniprot": "A4I8N5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8N5"
  },
  {
    "uniprot": "A4I6P4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6P4"
  },
  {
    "uniprot": "A4ID24",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID24"
  },
  {
    "uniprot": "A4HXH1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXH1"
  },
  {
    "uniprot": "A4HWU9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWU9"
  },
  {
    "uniprot": "A4IAA3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAA3"
  },
  {
    "uniprot": "A4HZZ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZZ0"
  },
  {
    "uniprot": "A4HTS9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTS9"
  },
  {
    "uniprot": "A4I7M9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7M9"
  },
  {
    "uniprot": "A4I3P9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3P9"
  },
  {
    "uniprot": "A4HYK1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYK1"
  },
  {
    "uniprot": "A4I8K1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8K1"
  },
  {
    "uniprot": "A4IBD9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBD9"
  },
  {
    "uniprot": "A4HY36",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY36"
  },
  {
    "uniprot": "A4I309",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I309"
  },
  {
    "uniprot": "A4IA60",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA60"
  },
  {
    "uniprot": "A4I8P0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8P0"
  },
  {
    "uniprot": "E9AGD9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGD9"
  },
  {
    "uniprot": "A4I8B2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8B2"
  },
  {
    "uniprot": "A4HWT3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWT3"
  },
  {
    "uniprot": "E9AGG1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGG1"
  },
  {
    "uniprot": "A4I3E9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3E9"
  },
  {
    "uniprot": "A4I278",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I278"
  },
  {
    "uniprot": "A4HS36",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS36"
  },
  {
    "uniprot": "A4I579",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I579"
  },
  {
    "uniprot": "A4I5J1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5J1"
  },
  {
    "uniprot": "A4HW99",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW99"
  },
  {
    "uniprot": "A4I1W9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1W9"
  },
  {
    "uniprot": "A4HRH9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRH9"
  },
  {
    "uniprot": "A4I390",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I390"
  },
  {
    "uniprot": "A4HUS8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUS8"
  },
  {
    "uniprot": "A4I3Y8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Y8"
  },
  {
    "uniprot": "A4I8L9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8L9"
  },
  {
    "uniprot": "A4I0Z6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Z6"
  },
  {
    "uniprot": "A4I1P6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1P6"
  },
  {
    "uniprot": "E9AHW7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHW7"
  },
  {
    "uniprot": "E9AG90",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG90"
  },
  {
    "uniprot": "A4I0D9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0D9"
  },
  {
    "uniprot": "A4HWF2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWF2"
  },
  {
    "uniprot": "A4I0A6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0A6"
  },
  {
    "uniprot": "A4ICS7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICS7"
  },
  {
    "uniprot": "A4HV27",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV27"
  },
  {
    "uniprot": "A4I8D1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8D1"
  },
  {
    "uniprot": "A4ICH4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICH4"
  },
  {
    "uniprot": "A4I897",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I897"
  },
  {
    "uniprot": "A4HV14",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV14"
  },
  {
    "uniprot": "A4IDS6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDS6"
  },
  {
    "uniprot": "A4ICW7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICW7"
  },
  {
    "uniprot": "A4I3Z5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Z5"
  },
  {
    "uniprot": "A4I7N8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7N8"
  },
  {
    "uniprot": "A4I6F5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6F5"
  },
  {
    "uniprot": "A4HXC1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXC1"
  },
  {
    "uniprot": "A4HRP7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRP7"
  },
  {
    "uniprot": "A4HT19",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT19"
  },
  {
    "uniprot": "A4HX52",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX52"
  },
  {
    "uniprot": "A4HUJ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUJ1"
  },
  {
    "uniprot": "A4IC66",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC66"
  },
  {
    "uniprot": "A4HXI4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXI4"
  },
  {
    "uniprot": "A4HZ79",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ79"
  },
  {
    "uniprot": "A4IAA9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAA9"
  },
  {
    "uniprot": "A4I0E6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0E6"
  },
  {
    "uniprot": "A4I5S7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5S7"
  },
  {
    "uniprot": "A4HY99",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY99"
  },
  {
    "uniprot": "A4HSH0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSH0"
  },
  {
    "uniprot": "A4HYC1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYC1"
  },
  {
    "uniprot": "A4IBP4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBP4"
  },
  {
    "uniprot": "A4IDC1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDC1"
  },
  {
    "uniprot": "A4HVT6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVT6"
  },
  {
    "uniprot": "A4I7W6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7W6"
  },
  {
    "uniprot": "A4I5W2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5W2"
  },
  {
    "uniprot": "A4HTV8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTV8"
  },
  {
    "uniprot": "A4I567",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I567"
  },
  {
    "uniprot": "A4HXZ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXZ1"
  },
  {
    "uniprot": "A4HZD0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZD0"
  },
  {
    "uniprot": "A4IBI9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBI9"
  },
  {
    "uniprot": "A4ICD4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICD4"
  },
  {
    "uniprot": "A4HW90",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW90"
  },
  {
    "uniprot": "A4HXZ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXZ5"
  },
  {
    "uniprot": "A4I0P7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0P7"
  },
  {
    "uniprot": "A4I1B3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1B3"
  },
  {
    "uniprot": "A4HSD2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSD2"
  },
  {
    "uniprot": "A4IAL1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAL1"
  },
  {
    "uniprot": "A4HYP0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYP0"
  },
  {
    "uniprot": "A4HU33",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU33"
  },
  {
    "uniprot": "A4HW96",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW96"
  },
  {
    "uniprot": "E9AGS4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGS4"
  },
  {
    "uniprot": "A4HW63",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW63"
  },
  {
    "uniprot": "A4HZL7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZL7"
  },
  {
    "uniprot": "A4I0D3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0D3"
  },
  {
    "uniprot": "A4IDG5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDG5"
  },
  {
    "uniprot": "A4I2Y3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Y3"
  },
  {
    "uniprot": "A4I957",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I957"
  },
  {
    "uniprot": "A4I140",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I140"
  },
  {
    "uniprot": "A4HY98",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY98"
  },
  {
    "uniprot": "A4IC19",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC19"
  },
  {
    "uniprot": "A4I646",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I646"
  },
  {
    "uniprot": "E9AHH2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHH2"
  },
  {
    "uniprot": "A4HY51",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY51"
  },
  {
    "uniprot": "A4IAY2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAY2"
  },
  {
    "uniprot": "A4HTA5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTA5"
  },
  {
    "uniprot": "A4I5Y1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Y1"
  },
  {
    "uniprot": "A4HX24",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX24"
  },
  {
    "uniprot": "A4HWB9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWB9"
  },
  {
    "uniprot": "A4HRI8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRI8"
  },
  {
    "uniprot": "A4I6G8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6G8"
  },
  {
    "uniprot": "A4HU15",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU15"
  },
  {
    "uniprot": "A4HUG7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUG7"
  },
  {
    "uniprot": "A4HU05",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU05"
  },
  {
    "uniprot": "A4I4E9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4E9"
  },
  {
    "uniprot": "A4HY57",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY57"
  },
  {
    "uniprot": "A4HTE2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTE2"
  },
  {
    "uniprot": "E9AH33",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH33"
  },
  {
    "uniprot": "A4ICJ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICJ4"
  },
  {
    "uniprot": "A4HTP8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTP8"
  },
  {
    "uniprot": "A4I816",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I816"
  },
  {
    "uniprot": "A4HTE7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTE7"
  },
  {
    "uniprot": "E9AGE8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGE8"
  },
  {
    "uniprot": "A4HSR6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSR6"
  },
  {
    "uniprot": "A4HTG6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTG6"
  },
  {
    "uniprot": "A4IA78",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA78"
  },
  {
    "uniprot": "A4I237",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I237"
  },
  {
    "uniprot": "A4IDI9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDI9"
  },
  {
    "uniprot": "A4I8G7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8G7"
  },
  {
    "uniprot": "A4HVN3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVN3"
  },
  {
    "uniprot": "E9AGW1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGW1"
  },
  {
    "uniprot": "A4HZR7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZR7"
  },
  {
    "uniprot": "A4I379",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I379"
  },
  {
    "uniprot": "A4IB00",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB00"
  },
  {
    "uniprot": "A4HWA9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWA9"
  },
  {
    "uniprot": "A4HWI2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWI2"
  },
  {
    "uniprot": "A4I560",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I560"
  },
  {
    "uniprot": "A4I9V4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9V4"
  },
  {
    "uniprot": "A4HUU8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUU8"
  },
  {
    "uniprot": "A4HY68",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY68"
  },
  {
    "uniprot": "A4HTR0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTR0"
  },
  {
    "uniprot": "A4HZB6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZB6"
  },
  {
    "uniprot": "E9AHB4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHB4"
  },
  {
    "uniprot": "A4IDM7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDM7"
  },
  {
    "uniprot": "A4I361",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I361"
  },
  {
    "uniprot": "A4HV08",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV08"
  },
  {
    "uniprot": "A4HRT9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRT9"
  },
  {
    "uniprot": "A4I0Q0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Q0"
  },
  {
    "uniprot": "E9AHF0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHF0"
  },
  {
    "uniprot": "A4ICC5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICC5"
  },
  {
    "uniprot": "A4I1B7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1B7"
  },
  {
    "uniprot": "A4I1P3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1P3"
  },
  {
    "uniprot": "A4I255",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I255"
  },
  {
    "uniprot": "A4I311",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I311"
  },
  {
    "uniprot": "A4HZL3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZL3"
  },
  {
    "uniprot": "A4I5I7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5I7"
  },
  {
    "uniprot": "A4I5L4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5L4"
  },
  {
    "uniprot": "A4I951",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I951"
  },
  {
    "uniprot": "A4HTB0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTB0"
  },
  {
    "uniprot": "E9AHJ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHJ2"
  },
  {
    "uniprot": "A4IB59",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB59"
  },
  {
    "uniprot": "A4HYM6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYM6"
  },
  {
    "uniprot": "A4HSG0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSG0"
  },
  {
    "uniprot": "A4I036",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I036"
  },
  {
    "uniprot": "A4HRP8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRP8"
  },
  {
    "uniprot": "A4IAX7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAX7"
  },
  {
    "uniprot": "A4HY34",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY34"
  },
  {
    "uniprot": "A4IE02",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE02"
  },
  {
    "uniprot": "A4HTJ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTJ2"
  },
  {
    "uniprot": "A4I5M5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5M5"
  },
  {
    "uniprot": "A4HWY9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWY9"
  },
  {
    "uniprot": "A4HXV7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXV7"
  },
  {
    "uniprot": "A4I047",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I047"
  },
  {
    "uniprot": "A4HT98",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT98"
  },
  {
    "uniprot": "A4I8C2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8C2"
  },
  {
    "uniprot": "A4ICT8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICT8"
  },
  {
    "uniprot": "A4HU47",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU47"
  },
  {
    "uniprot": "A4HU55",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU55"
  },
  {
    "uniprot": "A4I1E0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1E0"
  },
  {
    "uniprot": "A4HU48",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU48"
  },
  {
    "uniprot": "A4I6F2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6F2"
  },
  {
    "uniprot": "A4ICC8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICC8"
  },
  {
    "uniprot": "A4I7J7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7J7"
  },
  {
    "uniprot": "A4I9B2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9B2"
  },
  {
    "uniprot": "A4HY39",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY39"
  },
  {
    "uniprot": "A4I7K8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7K8"
  },
  {
    "uniprot": "A4HXK2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXK2"
  },
  {
    "uniprot": "A4HXJ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXJ7"
  },
  {
    "uniprot": "A4I5Y7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Y7"
  },
  {
    "uniprot": "A4HUN3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUN3"
  },
  {
    "uniprot": "A4I1C3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1C3"
  },
  {
    "uniprot": "A4IAR8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAR8"
  },
  {
    "uniprot": "A4I2C6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2C6"
  },
  {
    "uniprot": "A4I6F4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6F4"
  },
  {
    "uniprot": "E9AGI4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGI4"
  },
  {
    "uniprot": "A4I6K1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6K1"
  },
  {
    "uniprot": "A4HTN9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTN9"
  },
  {
    "uniprot": "A4I3E5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3E5"
  },
  {
    "uniprot": "A4I792",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I792"
  },
  {
    "uniprot": "E9AH71",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH71"
  },
  {
    "uniprot": "E9AG96",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG96"
  },
  {
    "uniprot": "A4I0N5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0N5"
  },
  {
    "uniprot": "A4I6B9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6B9"
  },
  {
    "uniprot": "A4HST1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HST1"
  },
  {
    "uniprot": "A4HZ07",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ07"
  },
  {
    "uniprot": "A4I055",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I055"
  },
  {
    "uniprot": "A4HU19",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU19"
  },
  {
    "uniprot": "A4ID73",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID73"
  },
  {
    "uniprot": "A4HZJ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZJ6"
  },
  {
    "uniprot": "A4I890",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I890"
  },
  {
    "uniprot": "A4I6T0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6T0"
  },
  {
    "uniprot": "E9AHR5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHR5"
  },
  {
    "uniprot": "A4HSR5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSR5"
  },
  {
    "uniprot": "A4I9Q4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Q4"
  },
  {
    "uniprot": "A4IB22",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB22"
  },
  {
    "uniprot": "A4I8Y9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Y9"
  },
  {
    "uniprot": "A4I9F1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9F1"
  },
  {
    "uniprot": "A4I7T0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7T0"
  },
  {
    "uniprot": "A4HUZ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUZ7"
  },
  {
    "uniprot": "A4I396",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I396"
  },
  {
    "uniprot": "A4HUZ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUZ6"
  },
  {
    "uniprot": "A4I653",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I653"
  },
  {
    "uniprot": "A4IDA8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDA8"
  },
  {
    "uniprot": "E9AGY5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGY5"
  },
  {
    "uniprot": "A4ICI1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICI1"
  },
  {
    "uniprot": "A4IBC7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBC7"
  },
  {
    "uniprot": "A4I5C7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5C7"
  },
  {
    "uniprot": "A4I2I8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2I8"
  },
  {
    "uniprot": "A4HXM2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXM2"
  },
  {
    "uniprot": "A4I4I6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4I6"
  },
  {
    "uniprot": "A4HYE7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYE7"
  },
  {
    "uniprot": "A4I4Y8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Y8"
  },
  {
    "uniprot": "A4I6E9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6E9"
  },
  {
    "uniprot": "A4I0W2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0W2"
  },
  {
    "uniprot": "A4HVQ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVQ5"
  },
  {
    "uniprot": "A4HZK7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZK7"
  },
  {
    "uniprot": "A4ICA4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICA4"
  },
  {
    "uniprot": "A4I2S8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2S8"
  },
  {
    "uniprot": "A4HXL6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXL6"
  },
  {
    "uniprot": "A4I982",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I982"
  },
  {
    "uniprot": "A4HVL6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVL6"
  },
  {
    "uniprot": "A4IAG6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAG6"
  },
  {
    "uniprot": "A4HT81",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT81"
  },
  {
    "uniprot": "A4HTX3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTX3"
  },
  {
    "uniprot": "A4HST2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HST2"
  },
  {
    "uniprot": "A4IBE8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBE8"
  },
  {
    "uniprot": "A4I953",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I953"
  },
  {
    "uniprot": "A4HVV6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVV6"
  },
  {
    "uniprot": "A4HRS5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRS5"
  },
  {
    "uniprot": "A4HXJ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXJ5"
  },
  {
    "uniprot": "A4HWH9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWH9"
  },
  {
    "uniprot": "A4I169",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I169"
  },
  {
    "uniprot": "A4ICC0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICC0"
  },
  {
    "uniprot": "A4I286",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I286"
  },
  {
    "uniprot": "A4HZ62",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ62"
  },
  {
    "uniprot": "A4IAY1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAY1"
  },
  {
    "uniprot": "A4HTS6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTS6"
  },
  {
    "uniprot": "A4HWT1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWT1"
  },
  {
    "uniprot": "A4I4X2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4X2"
  },
  {
    "uniprot": "A4I0Z5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Z5"
  },
  {
    "uniprot": "A4HWA2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWA2"
  },
  {
    "uniprot": "A4ICT5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICT5"
  },
  {
    "uniprot": "A4HRU9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRU9"
  },
  {
    "uniprot": "A4HUP5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUP5"
  },
  {
    "uniprot": "A4IAX4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAX4"
  },
  {
    "uniprot": "A4IDS3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDS3"
  },
  {
    "uniprot": "A4HYU6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYU6"
  },
  {
    "uniprot": "A4I4J8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4J8"
  },
  {
    "uniprot": "A4I7Q0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Q0"
  },
  {
    "uniprot": "A4I7I0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7I0"
  },
  {
    "uniprot": "A4I3H5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3H5"
  },
  {
    "uniprot": "A4I474",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I474"
  },
  {
    "uniprot": "A4HZ43",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ43"
  },
  {
    "uniprot": "A4HVP1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVP1"
  },
  {
    "uniprot": "A4HSV9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSV9"
  },
  {
    "uniprot": "A4HVF2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVF2"
  },
  {
    "uniprot": "A4IAM8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAM8"
  },
  {
    "uniprot": "A4HVI8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVI8"
  },
  {
    "uniprot": "A4HXZ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXZ7"
  },
  {
    "uniprot": "A4IC40",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC40"
  },
  {
    "uniprot": "A4I4Z5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Z5"
  },
  {
    "uniprot": "A4I899",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I899"
  },
  {
    "uniprot": "A4I3J2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3J2"
  },
  {
    "uniprot": "E9AGK4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGK4"
  },
  {
    "uniprot": "A4I5A3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5A3"
  },
  {
    "uniprot": "A4I8R8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8R8"
  },
  {
    "uniprot": "A4I2W9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2W9"
  },
  {
    "uniprot": "A4I4K8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4K8"
  },
  {
    "uniprot": "A4I5M6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5M6"
  },
  {
    "uniprot": "A4HTI9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTI9"
  },
  {
    "uniprot": "A4I8D2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8D2"
  },
  {
    "uniprot": "A4I2V7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2V7"
  },
  {
    "uniprot": "E9AGQ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGQ9"
  },
  {
    "uniprot": "A4I5M4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5M4"
  },
  {
    "uniprot": "A4HXD9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXD9"
  },
  {
    "uniprot": "A4I4J9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4J9"
  },
  {
    "uniprot": "A4I3G9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3G9"
  },
  {
    "uniprot": "A4I5U2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5U2"
  },
  {
    "uniprot": "E9AGI1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGI1"
  },
  {
    "uniprot": "A4I2W8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2W8"
  },
  {
    "uniprot": "E9AG74",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG74"
  },
  {
    "uniprot": "A4HSH8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSH8"
  },
  {
    "uniprot": "A4HYZ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYZ1"
  },
  {
    "uniprot": "A4I5F2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5F2"
  },
  {
    "uniprot": "A4I2P5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2P5"
  },
  {
    "uniprot": "A4HUI9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUI9"
  },
  {
    "uniprot": "A4IA53",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA53"
  },
  {
    "uniprot": "A4I598",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I598"
  },
  {
    "uniprot": "A4HS59",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS59"
  },
  {
    "uniprot": "A4HYQ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYQ6"
  },
  {
    "uniprot": "A4I5W9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5W9"
  },
  {
    "uniprot": "A4I8E8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8E8"
  },
  {
    "uniprot": "A4I4W3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4W3"
  },
  {
    "uniprot": "A4HY25",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY25"
  },
  {
    "uniprot": "A4HYN5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYN5"
  },
  {
    "uniprot": "A4ICF7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICF7"
  },
  {
    "uniprot": "A4HX34",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX34"
  },
  {
    "uniprot": "A4I9P0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9P0"
  },
  {
    "uniprot": "E9AGG3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGG3"
  },
  {
    "uniprot": "A4I7V1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7V1"
  },
  {
    "uniprot": "A4HXE8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXE8"
  },
  {
    "uniprot": "A4I0D2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0D2"
  },
  {
    "uniprot": "A4HRH3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRH3"
  },
  {
    "uniprot": "A4I4W2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4W2"
  },
  {
    "uniprot": "A4HRX1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRX1"
  },
  {
    "uniprot": "A4HZ77",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ77"
  },
  {
    "uniprot": "A4HRC6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRC6"
  },
  {
    "uniprot": "A4I3S4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3S4"
  },
  {
    "uniprot": "A4IB16",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB16"
  },
  {
    "uniprot": "A4I9W6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9W6"
  },
  {
    "uniprot": "A4I3A3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3A3"
  },
  {
    "uniprot": "A4HXF8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXF8"
  },
  {
    "uniprot": "A4HZ12",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ12"
  },
  {
    "uniprot": "A4I3B3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3B3"
  },
  {
    "uniprot": "A4I220",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I220"
  },
  {
    "uniprot": "A4I5W6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5W6"
  },
  {
    "uniprot": "A4HZC1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZC1"
  },
  {
    "uniprot": "A4I2G4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2G4"
  },
  {
    "uniprot": "A4HVF3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVF3"
  },
  {
    "uniprot": "A4HXP7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXP7"
  },
  {
    "uniprot": "A4I8N9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8N9"
  },
  {
    "uniprot": "A4HTP5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTP5"
  },
  {
    "uniprot": "A4I4C1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4C1"
  },
  {
    "uniprot": "A4IDD5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDD5"
  },
  {
    "uniprot": "A4HRF9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRF9"
  },
  {
    "uniprot": "E9AG47",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG47"
  },
  {
    "uniprot": "A4I0I3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0I3"
  },
  {
    "uniprot": "A4HTL3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTL3"
  },
  {
    "uniprot": "A4HXJ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXJ6"
  },
  {
    "uniprot": "A4HTM1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTM1"
  },
  {
    "uniprot": "A4I9F9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9F9"
  },
  {
    "uniprot": "E9AHX7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHX7"
  },
  {
    "uniprot": "A4HUP0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUP0"
  },
  {
    "uniprot": "E9AGI7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGI7"
  },
  {
    "uniprot": "A4IC02",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC02"
  },
  {
    "uniprot": "A4I6K4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6K4"
  },
  {
    "uniprot": "E9AGY2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGY2"
  },
  {
    "uniprot": "A4HZR1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZR1"
  },
  {
    "uniprot": "A4HSB6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSB6"
  },
  {
    "uniprot": "A4I6X1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6X1"
  },
  {
    "uniprot": "A4HY43",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY43"
  },
  {
    "uniprot": "A4I3J0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3J0"
  },
  {
    "uniprot": "E9AHY6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHY6"
  },
  {
    "uniprot": "A4HZ31",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ31"
  },
  {
    "uniprot": "A4IAW6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAW6"
  },
  {
    "uniprot": "A4HTB1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTB1"
  },
  {
    "uniprot": "A4HRQ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRQ0"
  },
  {
    "uniprot": "A4HT79",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT79"
  },
  {
    "uniprot": "A4I6Q4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Q4"
  },
  {
    "uniprot": "A4I8T2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8T2"
  },
  {
    "uniprot": "A4HVY9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVY9"
  },
  {
    "uniprot": "A4I1D2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1D2"
  },
  {
    "uniprot": "A4I9B7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9B7"
  },
  {
    "uniprot": "A4I3Y1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Y1"
  },
  {
    "uniprot": "A4HV19",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV19"
  },
  {
    "uniprot": "A4I4A2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4A2"
  },
  {
    "uniprot": "A4I135",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I135"
  },
  {
    "uniprot": "A4HXK5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXK5"
  },
  {
    "uniprot": "A4I791",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I791"
  },
  {
    "uniprot": "A4I6R0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6R0"
  },
  {
    "uniprot": "A4IDP5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDP5"
  },
  {
    "uniprot": "A4I058",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I058"
  },
  {
    "uniprot": "A4I4K1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4K1"
  },
  {
    "uniprot": "A4I2E2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2E2"
  },
  {
    "uniprot": "A4HTY3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTY3"
  },
  {
    "uniprot": "A4I3D2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3D2"
  },
  {
    "uniprot": "A4ICF1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICF1"
  },
  {
    "uniprot": "A4HYN9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYN9"
  },
  {
    "uniprot": "A4IBR6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBR6"
  },
  {
    "uniprot": "A4IBJ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBJ1"
  },
  {
    "uniprot": "A4I702",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I702"
  },
  {
    "uniprot": "A4I0Z0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Z0"
  },
  {
    "uniprot": "A4HZ15",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ15"
  },
  {
    "uniprot": "A4ICT3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICT3"
  },
  {
    "uniprot": "A4I3Z3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Z3"
  },
  {
    "uniprot": "A4I767",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I767"
  },
  {
    "uniprot": "A4HUX7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUX7"
  },
  {
    "uniprot": "A4I9U4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9U4"
  },
  {
    "uniprot": "A4I3N2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3N2"
  },
  {
    "uniprot": "E9AI03",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AI03"
  },
  {
    "uniprot": "A4I774",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I774"
  },
  {
    "uniprot": "A4HUU3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUU3"
  },
  {
    "uniprot": "A4I8C9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8C9"
  },
  {
    "uniprot": "A4HRG4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRG4"
  },
  {
    "uniprot": "A4HSA9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSA9"
  },
  {
    "uniprot": "A4I5Q0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Q0"
  },
  {
    "uniprot": "A4HZJ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZJ1"
  },
  {
    "uniprot": "A4HVR2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVR2"
  },
  {
    "uniprot": "A4I2K7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2K7"
  },
  {
    "uniprot": "A4HW07",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW07"
  },
  {
    "uniprot": "A4IDC4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDC4"
  },
  {
    "uniprot": "A4HRF4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRF4"
  },
  {
    "uniprot": "A4I0E1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0E1"
  },
  {
    "uniprot": "A4HU70",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU70"
  },
  {
    "uniprot": "A4HSA4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSA4"
  },
  {
    "uniprot": "A4I3Z9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Z9"
  },
  {
    "uniprot": "A4IDP1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDP1"
  },
  {
    "uniprot": "A4HXJ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXJ4"
  },
  {
    "uniprot": "A4I2J4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2J4"
  },
  {
    "uniprot": "A4I7C0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7C0"
  },
  {
    "uniprot": "A4I1F2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1F2"
  },
  {
    "uniprot": "A4HVE7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVE7"
  },
  {
    "uniprot": "A4HSG9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSG9"
  },
  {
    "uniprot": "A4I9B5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9B5"
  },
  {
    "uniprot": "A4I9G7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9G7"
  },
  {
    "uniprot": "A4HWQ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWQ8"
  },
  {
    "uniprot": "A4I785",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I785"
  },
  {
    "uniprot": "A4I5X3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5X3"
  },
  {
    "uniprot": "E9AHZ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHZ4"
  },
  {
    "uniprot": "A4HVZ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVZ5"
  },
  {
    "uniprot": "A4I3E2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3E2"
  },
  {
    "uniprot": "A4HYU3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYU3"
  },
  {
    "uniprot": "A4IAY8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAY8"
  },
  {
    "uniprot": "A4I1N7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1N7"
  },
  {
    "uniprot": "A4HSF6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSF6"
  },
  {
    "uniprot": "A4IAS2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAS2"
  },
  {
    "uniprot": "A4HZF5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZF5"
  },
  {
    "uniprot": "E9AHY8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHY8"
  },
  {
    "uniprot": "A4I6R3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6R3"
  },
  {
    "uniprot": "A4I8E9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8E9"
  },
  {
    "uniprot": "A4IC41",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC41"
  },
  {
    "uniprot": "A4I389",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I389"
  },
  {
    "uniprot": "A4IB57",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB57"
  },
  {
    "uniprot": "E9AHB1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHB1"
  },
  {
    "uniprot": "A4I103",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I103"
  },
  {
    "uniprot": "A4ID35",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID35"
  },
  {
    "uniprot": "A4HX94",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX94"
  },
  {
    "uniprot": "A4IDM8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDM8"
  },
  {
    "uniprot": "A0A381MRJ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MRJ9"
  },
  {
    "uniprot": "A4ICV7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICV7"
  },
  {
    "uniprot": "A4HRC5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRC5"
  },
  {
    "uniprot": "A4HRF2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRF2"
  },
  {
    "uniprot": "A4IBN6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBN6"
  },
  {
    "uniprot": "E9AGX9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGX9"
  },
  {
    "uniprot": "E9AHD3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHD3"
  },
  {
    "uniprot": "A4HXT1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXT1"
  },
  {
    "uniprot": "A4HWH8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWH8"
  },
  {
    "uniprot": "A4IAN3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAN3"
  },
  {
    "uniprot": "A4I4P8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4P8"
  },
  {
    "uniprot": "A4IDK6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDK6"
  },
  {
    "uniprot": "A4HWV9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWV9"
  },
  {
    "uniprot": "A4HYY7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYY7"
  },
  {
    "uniprot": "A4HUP2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUP2"
  },
  {
    "uniprot": "A4I376",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I376"
  },
  {
    "uniprot": "E9AGQ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGQ6"
  },
  {
    "uniprot": "A4I752",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I752"
  },
  {
    "uniprot": "A4I8X8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8X8"
  },
  {
    "uniprot": "A4IC35",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC35"
  },
  {
    "uniprot": "A4IBF2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBF2"
  },
  {
    "uniprot": "A4I8Q6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Q6"
  },
  {
    "uniprot": "A4I6Y3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Y3"
  },
  {
    "uniprot": "A4HTK2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTK2"
  },
  {
    "uniprot": "A4ICT4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICT4"
  },
  {
    "uniprot": "A4HYQ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYQ5"
  },
  {
    "uniprot": "A4HTW5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTW5"
  },
  {
    "uniprot": "A4HYB7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYB7"
  },
  {
    "uniprot": "A4HUK9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUK9"
  },
  {
    "uniprot": "A4HYZ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYZ9"
  },
  {
    "uniprot": "A4I1K4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1K4"
  },
  {
    "uniprot": "A4I012",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I012"
  },
  {
    "uniprot": "A4I966",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I966"
  },
  {
    "uniprot": "A4I809",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I809"
  },
  {
    "uniprot": "A4I6H7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6H7"
  },
  {
    "uniprot": "A4ICB0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICB0"
  },
  {
    "uniprot": "A4ICF2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICF2"
  },
  {
    "uniprot": "A4I3T2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3T2"
  },
  {
    "uniprot": "A4I3Q5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Q5"
  },
  {
    "uniprot": "A4I2G0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2G0"
  },
  {
    "uniprot": "A4HVE2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVE2"
  },
  {
    "uniprot": "A4IAN8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAN8"
  },
  {
    "uniprot": "E9AHK1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHK1"
  },
  {
    "uniprot": "A4IAB1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAB1"
  },
  {
    "uniprot": "A4I179",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I179"
  },
  {
    "uniprot": "A4I094",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I094"
  },
  {
    "uniprot": "A4IC12",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC12"
  },
  {
    "uniprot": "A4I1Q2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Q2"
  },
  {
    "uniprot": "A4HV02",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV02"
  },
  {
    "uniprot": "A4HWV3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWV3"
  },
  {
    "uniprot": "A4HVV1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVV1"
  },
  {
    "uniprot": "A4I0B5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0B5"
  },
  {
    "uniprot": "A4IDS5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDS5"
  },
  {
    "uniprot": "A4IDL6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDL6"
  },
  {
    "uniprot": "E9AGV0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGV0"
  },
  {
    "uniprot": "A4HYR4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYR4"
  },
  {
    "uniprot": "A4I514",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I514"
  },
  {
    "uniprot": "A4HVU8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVU8"
  },
  {
    "uniprot": "A4HRM8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRM8"
  },
  {
    "uniprot": "A4IA77",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA77"
  },
  {
    "uniprot": "A4I8N3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8N3"
  },
  {
    "uniprot": "A4HZR0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZR0"
  },
  {
    "uniprot": "A4I1N4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1N4"
  },
  {
    "uniprot": "A4IAC5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAC5"
  },
  {
    "uniprot": "A4ICP1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICP1"
  },
  {
    "uniprot": "A4HSJ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSJ5"
  },
  {
    "uniprot": "A4I6L5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6L5"
  },
  {
    "uniprot": "A4I248",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I248"
  },
  {
    "uniprot": "A4HYG3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYG3"
  },
  {
    "uniprot": "A4I9U0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9U0"
  },
  {
    "uniprot": "A4I9N9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9N9"
  },
  {
    "uniprot": "E9AGP5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGP5"
  },
  {
    "uniprot": "E9AHQ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHQ9"
  },
  {
    "uniprot": "A4ID02",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID02"
  },
  {
    "uniprot": "A4HXB7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXB7"
  },
  {
    "uniprot": "A4I672",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I672"
  },
  {
    "uniprot": "A4I4Y3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Y3"
  },
  {
    "uniprot": "A4HWA0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWA0"
  },
  {
    "uniprot": "A0A381MM20",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MM20"
  },
  {
    "uniprot": "A4ID51",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID51"
  },
  {
    "uniprot": "E9AH47",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH47"
  },
  {
    "uniprot": "E9AH61",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH61"
  },
  {
    "uniprot": "A4HU58",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU58"
  },
  {
    "uniprot": "E9AG39",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG39"
  },
  {
    "uniprot": "A4IBQ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBQ1"
  },
  {
    "uniprot": "A4I5B3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5B3"
  },
  {
    "uniprot": "A4I4I5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4I5"
  },
  {
    "uniprot": "A4I9Z9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Z9"
  },
  {
    "uniprot": "A4IC18",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC18"
  },
  {
    "uniprot": "A4I562",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I562"
  },
  {
    "uniprot": "A4HWR4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWR4"
  },
  {
    "uniprot": "A4HXE3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXE3"
  },
  {
    "uniprot": "A4I810",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I810"
  },
  {
    "uniprot": "A4I8D5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8D5"
  },
  {
    "uniprot": "A4HV47",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV47"
  },
  {
    "uniprot": "A4I8K7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8K7"
  },
  {
    "uniprot": "A4IB67",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB67"
  },
  {
    "uniprot": "A4HW36",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW36"
  },
  {
    "uniprot": "A4HRJ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRJ2"
  },
  {
    "uniprot": "A4IDA4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDA4"
  },
  {
    "uniprot": "A4HXC2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXC2"
  },
  {
    "uniprot": "A4I157",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I157"
  },
  {
    "uniprot": "A4HUT1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUT1"
  },
  {
    "uniprot": "A4HWE1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWE1"
  },
  {
    "uniprot": "A4I564",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I564"
  },
  {
    "uniprot": "A4HUK6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUK6"
  },
  {
    "uniprot": "A4HWI7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWI7"
  },
  {
    "uniprot": "E9AGA6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGA6"
  },
  {
    "uniprot": "A4I416",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I416"
  },
  {
    "uniprot": "A4HZ54",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ54"
  },
  {
    "uniprot": "A4HZF0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZF0"
  },
  {
    "uniprot": "E9AGY0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGY0"
  },
  {
    "uniprot": "A4I9T7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9T7"
  },
  {
    "uniprot": "A4I6N8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6N8"
  },
  {
    "uniprot": "A4I970",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I970"
  },
  {
    "uniprot": "A4I9W5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9W5"
  },
  {
    "uniprot": "A4ICZ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICZ5"
  },
  {
    "uniprot": "A4I5N7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5N7"
  },
  {
    "uniprot": "A4HRV4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRV4"
  },
  {
    "uniprot": "A4I846",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I846"
  },
  {
    "uniprot": "A4HZ92",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ92"
  },
  {
    "uniprot": "A4HSU8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSU8"
  },
  {
    "uniprot": "A4HXX9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXX9"
  },
  {
    "uniprot": "A4HXW6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXW6"
  },
  {
    "uniprot": "E9AH53",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH53"
  },
  {
    "uniprot": "A4I1X0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1X0"
  },
  {
    "uniprot": "E9AG34",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG34"
  },
  {
    "uniprot": "A4IBY5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBY5"
  },
  {
    "uniprot": "A4ID01",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID01"
  },
  {
    "uniprot": "A4IDX4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDX4"
  },
  {
    "uniprot": "A4HVX2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVX2"
  },
  {
    "uniprot": "A4HSI4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSI4"
  },
  {
    "uniprot": "A4HWQ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWQ9"
  },
  {
    "uniprot": "A4I127",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I127"
  },
  {
    "uniprot": "A4I8Z3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Z3"
  },
  {
    "uniprot": "A4I9W3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9W3"
  },
  {
    "uniprot": "A4IAU0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAU0"
  },
  {
    "uniprot": "A4HV25",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV25"
  },
  {
    "uniprot": "A4I432",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I432"
  },
  {
    "uniprot": "A4ICE4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICE4"
  },
  {
    "uniprot": "E9AGL4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGL4"
  },
  {
    "uniprot": "A4HU50",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU50"
  },
  {
    "uniprot": "E9AGP0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGP0"
  },
  {
    "uniprot": "A4HZS3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZS3"
  },
  {
    "uniprot": "A4ICA9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICA9"
  },
  {
    "uniprot": "A4I2J1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2J1"
  },
  {
    "uniprot": "A4I8K9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8K9"
  },
  {
    "uniprot": "A4HRT1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRT1"
  },
  {
    "uniprot": "A4HX15",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX15"
  },
  {
    "uniprot": "A4I592",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I592"
  },
  {
    "uniprot": "A4IAJ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAJ3"
  },
  {
    "uniprot": "A4I3W7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3W7"
  },
  {
    "uniprot": "A4I3Y6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Y6"
  },
  {
    "uniprot": "A4I9T6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9T6"
  },
  {
    "uniprot": "A4HXP1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXP1"
  },
  {
    "uniprot": "A4HTW3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTW3"
  },
  {
    "uniprot": "A4HUR8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUR8"
  },
  {
    "uniprot": "A4HW03",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW03"
  },
  {
    "uniprot": "A4HWW8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWW8"
  },
  {
    "uniprot": "A4I986",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I986"
  },
  {
    "uniprot": "A4I6X3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6X3"
  },
  {
    "uniprot": "A4HXC0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXC0"
  },
  {
    "uniprot": "A4IBM5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBM5"
  },
  {
    "uniprot": "A4I486",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I486"
  },
  {
    "uniprot": "A4HTM2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTM2"
  },
  {
    "uniprot": "A4I4E6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4E6"
  },
  {
    "uniprot": "A4HSI9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSI9"
  },
  {
    "uniprot": "A4I3G8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3G8"
  },
  {
    "uniprot": "A4HRQ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRQ4"
  },
  {
    "uniprot": "A4HW50",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW50"
  },
  {
    "uniprot": "E9AG70",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG70"
  },
  {
    "uniprot": "A4I737",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I737"
  },
  {
    "uniprot": "A4I3G5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3G5"
  },
  {
    "uniprot": "A4I198",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I198"
  },
  {
    "uniprot": "A4HYE4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYE4"
  },
  {
    "uniprot": "A4I4Q8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Q8"
  },
  {
    "uniprot": "E9AGM5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGM5"
  },
  {
    "uniprot": "A4HS60",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS60"
  },
  {
    "uniprot": "A4I433",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I433"
  },
  {
    "uniprot": "A4IBY0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBY0"
  },
  {
    "uniprot": "A4HSR3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSR3"
  },
  {
    "uniprot": "A4I7X9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7X9"
  },
  {
    "uniprot": "A4HVZ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVZ9"
  },
  {
    "uniprot": "A4IBS8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBS8"
  },
  {
    "uniprot": "E9AHC3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHC3"
  },
  {
    "uniprot": "E9AH40",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH40"
  },
  {
    "uniprot": "A4I7X0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7X0"
  },
  {
    "uniprot": "A4I8H6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8H6"
  },
  {
    "uniprot": "A4I8C6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8C6"
  },
  {
    "uniprot": "A4ICM8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICM8"
  },
  {
    "uniprot": "E9AGD7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGD7"
  },
  {
    "uniprot": "A4HRG6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRG6"
  },
  {
    "uniprot": "A4IBU7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBU7"
  },
  {
    "uniprot": "A4I5C2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5C2"
  },
  {
    "uniprot": "E9AHZ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHZ8"
  },
  {
    "uniprot": "A4I1S2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1S2"
  },
  {
    "uniprot": "A4IDQ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDQ7"
  },
  {
    "uniprot": "A4IE59",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE59"
  },
  {
    "uniprot": "A4I2A4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2A4"
  },
  {
    "uniprot": "A4I5I6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5I6"
  },
  {
    "uniprot": "E9AGX3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGX3"
  },
  {
    "uniprot": "E9AHF3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHF3"
  },
  {
    "uniprot": "E9AHX0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHX0"
  },
  {
    "uniprot": "A4I2W0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2W0"
  },
  {
    "uniprot": "A4HSJ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSJ9"
  },
  {
    "uniprot": "A4ICF3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICF3"
  },
  {
    "uniprot": "A4I7A1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7A1"
  },
  {
    "uniprot": "A4IA70",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA70"
  },
  {
    "uniprot": "A4I0S4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0S4"
  },
  {
    "uniprot": "A4HSV2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSV2"
  },
  {
    "uniprot": "A4I5J7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5J7"
  },
  {
    "uniprot": "A4IDG9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDG9"
  },
  {
    "uniprot": "A4HS70",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS70"
  },
  {
    "uniprot": "A4HXM5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXM5"
  },
  {
    "uniprot": "A4HSS1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSS1"
  },
  {
    "uniprot": "A4HW94",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW94"
  },
  {
    "uniprot": "A4I3L2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3L2"
  },
  {
    "uniprot": "A4I2E1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2E1"
  },
  {
    "uniprot": "A4HXF2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXF2"
  },
  {
    "uniprot": "A4I1C8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1C8"
  },
  {
    "uniprot": "A4HY71",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY71"
  },
  {
    "uniprot": "A4IC07",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC07"
  },
  {
    "uniprot": "E9AHP3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHP3"
  },
  {
    "uniprot": "A4I126",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I126"
  },
  {
    "uniprot": "A4HYX7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYX7"
  },
  {
    "uniprot": "A4HUF2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUF2"
  },
  {
    "uniprot": "A4I339",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I339"
  },
  {
    "uniprot": "A4I2Y0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Y0"
  },
  {
    "uniprot": "A4HTJ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTJ8"
  },
  {
    "uniprot": "A4HUV7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUV7"
  },
  {
    "uniprot": "E9AHH5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHH5"
  },
  {
    "uniprot": "A4HRI7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRI7"
  },
  {
    "uniprot": "A4HRE3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRE3"
  },
  {
    "uniprot": "A4ICS5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICS5"
  },
  {
    "uniprot": "E9AHN2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHN2"
  },
  {
    "uniprot": "A4HYP7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYP7"
  },
  {
    "uniprot": "A4I5D6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5D6"
  },
  {
    "uniprot": "A4HWK0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWK0"
  },
  {
    "uniprot": "A4HWW6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWW6"
  },
  {
    "uniprot": "A4I860",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I860"
  },
  {
    "uniprot": "A4ICR3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICR3"
  },
  {
    "uniprot": "A4I8H9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8H9"
  },
  {
    "uniprot": "A4I367",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I367"
  },
  {
    "uniprot": "A4HV07",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV07"
  },
  {
    "uniprot": "A4ICW1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICW1"
  },
  {
    "uniprot": "A4HU45",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU45"
  },
  {
    "uniprot": "A4HTJ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTJ0"
  },
  {
    "uniprot": "A4ID77",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID77"
  },
  {
    "uniprot": "E9AHW2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHW2"
  },
  {
    "uniprot": "A4IA46",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA46"
  },
  {
    "uniprot": "A4HZP9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZP9"
  },
  {
    "uniprot": "E9AHU3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHU3"
  },
  {
    "uniprot": "A4I4E0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4E0"
  },
  {
    "uniprot": "A4HYI3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYI3"
  },
  {
    "uniprot": "A4HYN7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYN7"
  },
  {
    "uniprot": "A4I946",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I946"
  },
  {
    "uniprot": "A4HYG8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYG8"
  },
  {
    "uniprot": "A4IDA3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDA3"
  },
  {
    "uniprot": "E9AGB8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGB8"
  },
  {
    "uniprot": "A4HT56",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT56"
  },
  {
    "uniprot": "A4I8W8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8W8"
  },
  {
    "uniprot": "A4HXH9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXH9"
  },
  {
    "uniprot": "E9AGH8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGH8"
  },
  {
    "uniprot": "A4HVP5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVP5"
  },
  {
    "uniprot": "A4I8D7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8D7"
  },
  {
    "uniprot": "A4I4I3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4I3"
  },
  {
    "uniprot": "A4HUT4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUT4"
  },
  {
    "uniprot": "A4IAJ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAJ8"
  },
  {
    "uniprot": "A4HUM4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUM4"
  },
  {
    "uniprot": "A4IAL3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAL3"
  },
  {
    "uniprot": "A4IBW0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBW0"
  },
  {
    "uniprot": "A4HXW1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXW1"
  },
  {
    "uniprot": "A4HVH5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVH5"
  },
  {
    "uniprot": "A4I513",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I513"
  },
  {
    "uniprot": "A4I0E0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0E0"
  },
  {
    "uniprot": "A4HSI0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSI0"
  },
  {
    "uniprot": "A4I4U1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4U1"
  },
  {
    "uniprot": "A4IAE2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAE2"
  },
  {
    "uniprot": "A4I3C3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3C3"
  },
  {
    "uniprot": "A4HYW0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYW0"
  },
  {
    "uniprot": "A4IC48",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC48"
  },
  {
    "uniprot": "A4HY88",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY88"
  },
  {
    "uniprot": "A4HZZ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZZ5"
  },
  {
    "uniprot": "A4IC93",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC93"
  },
  {
    "uniprot": "A4HXU0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXU0"
  },
  {
    "uniprot": "A4HTV4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTV4"
  },
  {
    "uniprot": "A4HT32",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT32"
  },
  {
    "uniprot": "A4I6E6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6E6"
  },
  {
    "uniprot": "A4IDM0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDM0"
  },
  {
    "uniprot": "A4I9Z7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Z7"
  },
  {
    "uniprot": "A4I5T2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5T2"
  },
  {
    "uniprot": "A4IB25",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB25"
  },
  {
    "uniprot": "A4HYY5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYY5"
  },
  {
    "uniprot": "A4I1C9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1C9"
  },
  {
    "uniprot": "A4HZW0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZW0"
  },
  {
    "uniprot": "A4I8Y7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Y7"
  },
  {
    "uniprot": "A4I977",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I977"
  },
  {
    "uniprot": "A4HSK9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSK9"
  },
  {
    "uniprot": "A4HTM9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTM9"
  },
  {
    "uniprot": "A4HYU8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYU8"
  },
  {
    "uniprot": "A4I6Z6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Z6"
  },
  {
    "uniprot": "A4I6U7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6U7"
  },
  {
    "uniprot": "A4HRC3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRC3"
  },
  {
    "uniprot": "E9AHT4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHT4"
  },
  {
    "uniprot": "A4I8W4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8W4"
  },
  {
    "uniprot": "A4HRC4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRC4"
  },
  {
    "uniprot": "A4HVV8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVV8"
  },
  {
    "uniprot": "E9AHY2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHY2"
  },
  {
    "uniprot": "A4HRS4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRS4"
  },
  {
    "uniprot": "A4I6R7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6R7"
  },
  {
    "uniprot": "A4HXL3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXL3"
  },
  {
    "uniprot": "A4I7H1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7H1"
  },
  {
    "uniprot": "A4HXI5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXI5"
  },
  {
    "uniprot": "A4HZ78",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ78"
  },
  {
    "uniprot": "A4HRN4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRN4"
  },
  {
    "uniprot": "A4IDX9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDX9"
  },
  {
    "uniprot": "A4HVJ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVJ0"
  },
  {
    "uniprot": "A4HU78",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU78"
  },
  {
    "uniprot": "A4HYJ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYJ7"
  },
  {
    "uniprot": "A4I962",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I962"
  },
  {
    "uniprot": "A4I5N8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5N8"
  },
  {
    "uniprot": "A4I024",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I024"
  },
  {
    "uniprot": "A4IA07",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA07"
  },
  {
    "uniprot": "A4I775",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I775"
  },
  {
    "uniprot": "A4IDL0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDL0"
  },
  {
    "uniprot": "A4I3P4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3P4"
  },
  {
    "uniprot": "A4I0Y6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Y6"
  },
  {
    "uniprot": "A4HZE6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZE6"
  },
  {
    "uniprot": "A4I7A3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7A3"
  },
  {
    "uniprot": "A4HSM3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSM3"
  },
  {
    "uniprot": "A4I7S0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7S0"
  },
  {
    "uniprot": "A4I589",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I589"
  },
  {
    "uniprot": "A4ICA1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICA1"
  },
  {
    "uniprot": "E9AHT2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHT2"
  },
  {
    "uniprot": "A4IAB8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAB8"
  },
  {
    "uniprot": "A4I2D9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2D9"
  },
  {
    "uniprot": "A4I7J4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7J4"
  },
  {
    "uniprot": "A4I9G0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9G0"
  },
  {
    "uniprot": "A4I0F6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0F6"
  },
  {
    "uniprot": "E9AGW4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGW4"
  },
  {
    "uniprot": "A4HZU4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZU4"
  },
  {
    "uniprot": "A4I993",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I993"
  },
  {
    "uniprot": "A4HZ10",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ10"
  },
  {
    "uniprot": "A4IDQ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDQ2"
  },
  {
    "uniprot": "A4HSI5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSI5"
  },
  {
    "uniprot": "A4HWI6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWI6"
  },
  {
    "uniprot": "A4I110",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I110"
  },
  {
    "uniprot": "A4HZA3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZA3"
  },
  {
    "uniprot": "A4HVE6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVE6"
  },
  {
    "uniprot": "A4HSR7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSR7"
  },
  {
    "uniprot": "A4I7Z1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Z1"
  },
  {
    "uniprot": "A4HZR3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZR3"
  },
  {
    "uniprot": "A4IE10",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE10"
  },
  {
    "uniprot": "A4I4H9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4H9"
  },
  {
    "uniprot": "E9AHC8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHC8"
  },
  {
    "uniprot": "A4HTE0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTE0"
  },
  {
    "uniprot": "A4ICA8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICA8"
  },
  {
    "uniprot": "A4HZH5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZH5"
  },
  {
    "uniprot": "A4IDX5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDX5"
  },
  {
    "uniprot": "A4I4F3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4F3"
  },
  {
    "uniprot": "A4HRW9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRW9"
  },
  {
    "uniprot": "A4ID41",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID41"
  },
  {
    "uniprot": "A4HZG5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZG5"
  },
  {
    "uniprot": "A4IA94",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA94"
  },
  {
    "uniprot": "A4HUS6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUS6"
  },
  {
    "uniprot": "E9AH05",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH05"
  },
  {
    "uniprot": "A4HSM5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSM5"
  },
  {
    "uniprot": "E9AH04",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH04"
  },
  {
    "uniprot": "A4I942",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I942"
  },
  {
    "uniprot": "A4HTK3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTK3"
  },
  {
    "uniprot": "A4HTG0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTG0"
  },
  {
    "uniprot": "A4IC06",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC06"
  },
  {
    "uniprot": "A4I2K5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2K5"
  },
  {
    "uniprot": "A4IB12",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB12"
  },
  {
    "uniprot": "A4HY80",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY80"
  },
  {
    "uniprot": "A4I0U1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0U1"
  },
  {
    "uniprot": "A4I7N4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7N4"
  },
  {
    "uniprot": "A4I204",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I204"
  },
  {
    "uniprot": "A4HZG9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZG9"
  },
  {
    "uniprot": "A4I773",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I773"
  },
  {
    "uniprot": "A4I8N6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8N6"
  },
  {
    "uniprot": "A4IB73",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB73"
  },
  {
    "uniprot": "A4HWS4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWS4"
  },
  {
    "uniprot": "A4I7U7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7U7"
  },
  {
    "uniprot": "A4IB94",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB94"
  },
  {
    "uniprot": "A4I404",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I404"
  },
  {
    "uniprot": "A4I9K0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9K0"
  },
  {
    "uniprot": "A4HVX4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVX4"
  },
  {
    "uniprot": "A4I1Q3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Q3"
  },
  {
    "uniprot": "A4I335",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I335"
  },
  {
    "uniprot": "A4HRN1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRN1"
  },
  {
    "uniprot": "E9AHC9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHC9"
  },
  {
    "uniprot": "A4I071",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I071"
  },
  {
    "uniprot": "A4I4V7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4V7"
  },
  {
    "uniprot": "A4HX65",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX65"
  },
  {
    "uniprot": "A4HWW9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWW9"
  },
  {
    "uniprot": "A4I7Y0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Y0"
  },
  {
    "uniprot": "A4I9R3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9R3"
  },
  {
    "uniprot": "A4I4P3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4P3"
  },
  {
    "uniprot": "A4ICJ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICJ0"
  },
  {
    "uniprot": "E9AH07",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH07"
  },
  {
    "uniprot": "A4I8Y0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Y0"
  },
  {
    "uniprot": "A4HSD1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSD1"
  },
  {
    "uniprot": "A4HRP0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRP0"
  },
  {
    "uniprot": "A4I9Z8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Z8"
  },
  {
    "uniprot": "A4I0H2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0H2"
  },
  {
    "uniprot": "A4I757",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I757"
  },
  {
    "uniprot": "A4I0P0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0P0"
  },
  {
    "uniprot": "A4I576",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I576"
  },
  {
    "uniprot": "A4I7V4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7V4"
  },
  {
    "uniprot": "A4I6Q8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Q8"
  },
  {
    "uniprot": "E9AHJ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHJ3"
  },
  {
    "uniprot": "A4HZZ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZZ7"
  },
  {
    "uniprot": "E9AHC2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHC2"
  },
  {
    "uniprot": "A4I6I2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6I2"
  },
  {
    "uniprot": "A4IAG8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAG8"
  },
  {
    "uniprot": "A4IAQ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAQ1"
  },
  {
    "uniprot": "A4HXP3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXP3"
  },
  {
    "uniprot": "A4HX37",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX37"
  },
  {
    "uniprot": "E9AH67",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH67"
  },
  {
    "uniprot": "A4IBA8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBA8"
  },
  {
    "uniprot": "A4HXV2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXV2"
  },
  {
    "uniprot": "A4HZ34",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ34"
  },
  {
    "uniprot": "A4IB27",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB27"
  },
  {
    "uniprot": "E9AHQ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHQ6"
  },
  {
    "uniprot": "A4HXA0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXA0"
  },
  {
    "uniprot": "A4HSE4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSE4"
  },
  {
    "uniprot": "A4I1J5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1J5"
  },
  {
    "uniprot": "A4I9G1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9G1"
  },
  {
    "uniprot": "A4HXY7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXY7"
  },
  {
    "uniprot": "A4I365",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I365"
  },
  {
    "uniprot": "A4HW93",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW93"
  },
  {
    "uniprot": "E9AHA6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHA6"
  },
  {
    "uniprot": "A4I2W2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2W2"
  },
  {
    "uniprot": "A4IB56",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB56"
  },
  {
    "uniprot": "A4HS28",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS28"
  },
  {
    "uniprot": "A4HUH1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUH1"
  },
  {
    "uniprot": "A4HVS9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVS9"
  },
  {
    "uniprot": "A4HWI3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWI3"
  },
  {
    "uniprot": "A4I0L7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0L7"
  },
  {
    "uniprot": "E9AG41",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG41"
  },
  {
    "uniprot": "A4I932",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I932"
  },
  {
    "uniprot": "E9AH06",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH06"
  },
  {
    "uniprot": "A4HZF4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZF4"
  },
  {
    "uniprot": "A4I494",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I494"
  },
  {
    "uniprot": "A4I4L7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4L7"
  },
  {
    "uniprot": "A4IDI3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDI3"
  },
  {
    "uniprot": "A4ICU6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICU6"
  },
  {
    "uniprot": "A4ID26",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID26"
  },
  {
    "uniprot": "A4I2Q7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Q7"
  },
  {
    "uniprot": "E9AHD9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHD9"
  },
  {
    "uniprot": "Q9N9V6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q9N9V6"
  },
  {
    "uniprot": "A4IAX6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAX6"
  },
  {
    "uniprot": "A4I5R5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5R5"
  },
  {
    "uniprot": "E9AGJ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGJ7"
  },
  {
    "uniprot": "A4HYV0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYV0"
  },
  {
    "uniprot": "A4HXW0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXW0"
  },
  {
    "uniprot": "A4I4G7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4G7"
  },
  {
    "uniprot": "A4I153",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I153"
  },
  {
    "uniprot": "A4HWS0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWS0"
  },
  {
    "uniprot": "A4I0V3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0V3"
  },
  {
    "uniprot": "A4I7J9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7J9"
  },
  {
    "uniprot": "A4IE26",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE26"
  },
  {
    "uniprot": "A4IDT9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDT9"
  },
  {
    "uniprot": "A4I397",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I397"
  },
  {
    "uniprot": "A4HTK7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTK7"
  },
  {
    "uniprot": "A4I568",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I568"
  },
  {
    "uniprot": "A4IAT8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAT8"
  },
  {
    "uniprot": "A4I8T3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8T3"
  },
  {
    "uniprot": "A4I023",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I023"
  },
  {
    "uniprot": "E9AHZ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHZ2"
  },
  {
    "uniprot": "A4I812",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I812"
  },
  {
    "uniprot": "A4IDY4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDY4"
  },
  {
    "uniprot": "A4I9I1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9I1"
  },
  {
    "uniprot": "A4I1A3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1A3"
  },
  {
    "uniprot": "A4HZE0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZE0"
  },
  {
    "uniprot": "A4IB93",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB93"
  },
  {
    "uniprot": "A4I6U6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6U6"
  },
  {
    "uniprot": "A4I0Q8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Q8"
  },
  {
    "uniprot": "A4HV53",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV53"
  },
  {
    "uniprot": "A4I0D0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0D0"
  },
  {
    "uniprot": "A4I6V3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6V3"
  },
  {
    "uniprot": "A4HX23",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX23"
  },
  {
    "uniprot": "A4HWX7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWX7"
  },
  {
    "uniprot": "A4I8I7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8I7"
  },
  {
    "uniprot": "A4HSG7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSG7"
  },
  {
    "uniprot": "A4I192",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I192"
  },
  {
    "uniprot": "A4I7N9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7N9"
  },
  {
    "uniprot": "E9AHE0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHE0"
  },
  {
    "uniprot": "A4I5B7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5B7"
  },
  {
    "uniprot": "A4HZ66",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ66"
  },
  {
    "uniprot": "E9AGI9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGI9"
  },
  {
    "uniprot": "A4I3X7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3X7"
  },
  {
    "uniprot": "A4I2L3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2L3"
  },
  {
    "uniprot": "A4I1W5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1W5"
  },
  {
    "uniprot": "A4I340",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I340"
  },
  {
    "uniprot": "A4HZC3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZC3"
  },
  {
    "uniprot": "A4HSA8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSA8"
  },
  {
    "uniprot": "A4IBN1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBN1"
  },
  {
    "uniprot": "A4ICG4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICG4"
  },
  {
    "uniprot": "A4ICD6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICD6"
  },
  {
    "uniprot": "A4HX85",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX85"
  },
  {
    "uniprot": "A4I3Q7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Q7"
  },
  {
    "uniprot": "A4HXB1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXB1"
  },
  {
    "uniprot": "A4HVP4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVP4"
  },
  {
    "uniprot": "A4I2G1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2G1"
  },
  {
    "uniprot": "A4ID16",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID16"
  },
  {
    "uniprot": "A4HXL5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXL5"
  },
  {
    "uniprot": "A4IBU3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBU3"
  },
  {
    "uniprot": "A4HSJ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSJ8"
  },
  {
    "uniprot": "A4HSS7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSS7"
  },
  {
    "uniprot": "E9AG28",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG28"
  },
  {
    "uniprot": "A4HYM3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYM3"
  },
  {
    "uniprot": "A4IAY7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAY7"
  },
  {
    "uniprot": "A4HSD7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSD7"
  },
  {
    "uniprot": "A4HRU1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRU1"
  },
  {
    "uniprot": "A4HWG1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWG1"
  },
  {
    "uniprot": "A4I124",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I124"
  },
  {
    "uniprot": "A4HRF1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRF1"
  },
  {
    "uniprot": "A4I8P2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8P2"
  },
  {
    "uniprot": "A4I5K7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5K7"
  },
  {
    "uniprot": "A4I7G9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7G9"
  },
  {
    "uniprot": "A4HVK9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVK9"
  },
  {
    "uniprot": "A4HTV5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTV5"
  },
  {
    "uniprot": "A4HRX9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRX9"
  },
  {
    "uniprot": "E9AH64",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH64"
  },
  {
    "uniprot": "A4I501",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I501"
  },
  {
    "uniprot": "A4HXR7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXR7"
  },
  {
    "uniprot": "A4IB97",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB97"
  },
  {
    "uniprot": "A4I6N0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6N0"
  },
  {
    "uniprot": "A4IB07",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB07"
  },
  {
    "uniprot": "A4I3Q9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Q9"
  },
  {
    "uniprot": "A4HSN5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSN5"
  },
  {
    "uniprot": "A4IAE6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAE6"
  },
  {
    "uniprot": "A4I479",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I479"
  },
  {
    "uniprot": "A4I225",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I225"
  },
  {
    "uniprot": "A4HU69",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU69"
  },
  {
    "uniprot": "A4HUI1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUI1"
  },
  {
    "uniprot": "A4HSR4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSR4"
  },
  {
    "uniprot": "A4HY40",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY40"
  },
  {
    "uniprot": "A4IBQ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBQ7"
  },
  {
    "uniprot": "A4HRG1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRG1"
  },
  {
    "uniprot": "A4HYC6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYC6"
  },
  {
    "uniprot": "A4I8F9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8F9"
  },
  {
    "uniprot": "A4HWD3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWD3"
  },
  {
    "uniprot": "A4I0Z1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Z1"
  },
  {
    "uniprot": "A4HVW2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVW2"
  },
  {
    "uniprot": "A4I0S2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0S2"
  },
  {
    "uniprot": "A4HW86",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW86"
  },
  {
    "uniprot": "E9AH68",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH68"
  },
  {
    "uniprot": "A4HUN0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUN0"
  },
  {
    "uniprot": "A4I378",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I378"
  },
  {
    "uniprot": "A4I3K1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3K1"
  },
  {
    "uniprot": "A4I3Z1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Z1"
  },
  {
    "uniprot": "A4I880",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I880"
  },
  {
    "uniprot": "A4HZ03",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ03"
  },
  {
    "uniprot": "A4I5T6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5T6"
  },
  {
    "uniprot": "A4HYC4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYC4"
  },
  {
    "uniprot": "A4IC59",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC59"
  },
  {
    "uniprot": "A4I658",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I658"
  },
  {
    "uniprot": "A4IAU1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAU1"
  },
  {
    "uniprot": "A0A381MHF5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MHF5"
  },
  {
    "uniprot": "A4HWQ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWQ7"
  },
  {
    "uniprot": "A4IE62",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE62"
  },
  {
    "uniprot": "A4I882",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I882"
  },
  {
    "uniprot": "A4I8Y5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Y5"
  },
  {
    "uniprot": "A4HZQ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZQ2"
  },
  {
    "uniprot": "A4HXF5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXF5"
  },
  {
    "uniprot": "A4HZY9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZY9"
  },
  {
    "uniprot": "A4IDF8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDF8"
  },
  {
    "uniprot": "A4I586",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I586"
  },
  {
    "uniprot": "A4I508",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I508"
  },
  {
    "uniprot": "A4HZL2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZL2"
  },
  {
    "uniprot": "A4I019",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I019"
  },
  {
    "uniprot": "A4I6G6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6G6"
  },
  {
    "uniprot": "A4IAQ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAQ5"
  },
  {
    "uniprot": "A4HSF4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSF4"
  },
  {
    "uniprot": "A4HXS4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXS4"
  },
  {
    "uniprot": "A4I8D6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8D6"
  },
  {
    "uniprot": "A4HRJ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRJ1"
  },
  {
    "uniprot": "A4I0K5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0K5"
  },
  {
    "uniprot": "A4HSX4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSX4"
  },
  {
    "uniprot": "A4I1Y7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Y7"
  },
  {
    "uniprot": "A4I422",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I422"
  },
  {
    "uniprot": "A4I6A6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6A6"
  },
  {
    "uniprot": "A4HYH8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYH8"
  },
  {
    "uniprot": "A4HYH3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYH3"
  },
  {
    "uniprot": "A4ICG0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICG0"
  },
  {
    "uniprot": "A4HTG7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTG7"
  },
  {
    "uniprot": "A4ICJ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICJ5"
  },
  {
    "uniprot": "A4IB33",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB33"
  },
  {
    "uniprot": "A4I2V4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2V4"
  },
  {
    "uniprot": "A4I9R5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9R5"
  },
  {
    "uniprot": "A4I035",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I035"
  },
  {
    "uniprot": "A4HVX8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVX8"
  },
  {
    "uniprot": "A4I358",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I358"
  },
  {
    "uniprot": "A4IBA5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBA5"
  },
  {
    "uniprot": "A4HSU3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSU3"
  },
  {
    "uniprot": "A4ICN4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICN4"
  },
  {
    "uniprot": "A4HZ83",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ83"
  },
  {
    "uniprot": "E9AH84",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH84"
  },
  {
    "uniprot": "A4HRV2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRV2"
  },
  {
    "uniprot": "A4HSF2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSF2"
  },
  {
    "uniprot": "A4IAY0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAY0"
  },
  {
    "uniprot": "A4I251",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I251"
  },
  {
    "uniprot": "A4HZW7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZW7"
  },
  {
    "uniprot": "A4HYQ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYQ2"
  },
  {
    "uniprot": "A4HVR3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVR3"
  },
  {
    "uniprot": "A4I1Y0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Y0"
  },
  {
    "uniprot": "A4I0W4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0W4"
  },
  {
    "uniprot": "A4I8R9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8R9"
  },
  {
    "uniprot": "A4I3S1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3S1"
  },
  {
    "uniprot": "A4IAD0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAD0"
  },
  {
    "uniprot": "A4HV03",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV03"
  },
  {
    "uniprot": "A4IE17",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE17"
  },
  {
    "uniprot": "E9AG23",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG23"
  },
  {
    "uniprot": "A4HZE2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZE2"
  },
  {
    "uniprot": "A4ICI7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICI7"
  },
  {
    "uniprot": "A4I9Z0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Z0"
  },
  {
    "uniprot": "A4IBN9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBN9"
  },
  {
    "uniprot": "A4HYH6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYH6"
  },
  {
    "uniprot": "A4HT24",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT24"
  },
  {
    "uniprot": "A4IAT2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAT2"
  },
  {
    "uniprot": "A4I8J5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8J5"
  },
  {
    "uniprot": "A4IBN2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBN2"
  },
  {
    "uniprot": "E9AHX2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHX2"
  },
  {
    "uniprot": "A4I5D3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5D3"
  },
  {
    "uniprot": "A4HV55",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV55"
  },
  {
    "uniprot": "A4IAI2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAI2"
  },
  {
    "uniprot": "A4HTT1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTT1"
  },
  {
    "uniprot": "A4HWZ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWZ6"
  },
  {
    "uniprot": "A4I4V4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4V4"
  },
  {
    "uniprot": "A4HX98",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX98"
  },
  {
    "uniprot": "A4HYR1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYR1"
  },
  {
    "uniprot": "A4I7P5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7P5"
  },
  {
    "uniprot": "A4ICI3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICI3"
  },
  {
    "uniprot": "E9AG56",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG56"
  },
  {
    "uniprot": "A4I3S2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3S2"
  },
  {
    "uniprot": "A4IBV2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBV2"
  },
  {
    "uniprot": "A4HYZ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYZ0"
  },
  {
    "uniprot": "A4I2Q3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Q3"
  },
  {
    "uniprot": "A4I347",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I347"
  },
  {
    "uniprot": "A4I9A3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9A3"
  },
  {
    "uniprot": "A4I3G0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3G0"
  },
  {
    "uniprot": "A4I2F0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2F0"
  },
  {
    "uniprot": "A4I8S1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8S1"
  },
  {
    "uniprot": "A4I2Y1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Y1"
  },
  {
    "uniprot": "A4IDH2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDH2"
  },
  {
    "uniprot": "A4I6N4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6N4"
  },
  {
    "uniprot": "E9AG49",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG49"
  },
  {
    "uniprot": "A4I6B2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6B2"
  },
  {
    "uniprot": "A4HS67",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS67"
  },
  {
    "uniprot": "A4IDP3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDP3"
  },
  {
    "uniprot": "A4I2T5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2T5"
  },
  {
    "uniprot": "A4I9V5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9V5"
  },
  {
    "uniprot": "A4I9J3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9J3"
  },
  {
    "uniprot": "A4I1S7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1S7"
  },
  {
    "uniprot": "A4I9M4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9M4"
  },
  {
    "uniprot": "A4HSA7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSA7"
  },
  {
    "uniprot": "A4I708",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I708"
  },
  {
    "uniprot": "A4ICZ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICZ4"
  },
  {
    "uniprot": "A4HZ91",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ91"
  },
  {
    "uniprot": "A4HY55",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY55"
  },
  {
    "uniprot": "A4HW00",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW00"
  },
  {
    "uniprot": "A4I2K1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2K1"
  },
  {
    "uniprot": "A4HSC1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSC1"
  },
  {
    "uniprot": "A4I659",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I659"
  },
  {
    "uniprot": "A4HRV9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRV9"
  },
  {
    "uniprot": "A4HXK3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXK3"
  },
  {
    "uniprot": "A4HXR3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXR3"
  },
  {
    "uniprot": "A4I5P4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5P4"
  },
  {
    "uniprot": "A4I3M4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3M4"
  },
  {
    "uniprot": "A4I8J3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8J3"
  },
  {
    "uniprot": "A4I1M4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1M4"
  },
  {
    "uniprot": "A4I1S6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1S6"
  },
  {
    "uniprot": "A4I8E0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8E0"
  },
  {
    "uniprot": "A4I5Y9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Y9"
  },
  {
    "uniprot": "A4HUK8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUK8"
  },
  {
    "uniprot": "A4HTX1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTX1"
  },
  {
    "uniprot": "A4HUF9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUF9"
  },
  {
    "uniprot": "A4I742",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I742"
  },
  {
    "uniprot": "A4ICM5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICM5"
  },
  {
    "uniprot": "A4I834",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I834"
  },
  {
    "uniprot": "A4HXY5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXY5"
  },
  {
    "uniprot": "A4I3D4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3D4"
  },
  {
    "uniprot": "A4I183",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I183"
  },
  {
    "uniprot": "A4HVU4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVU4"
  },
  {
    "uniprot": "E9AG64",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG64"
  },
  {
    "uniprot": "A4I448",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I448"
  },
  {
    "uniprot": "A4IDI1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDI1"
  },
  {
    "uniprot": "A4I9E6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9E6"
  },
  {
    "uniprot": "A4I4M7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4M7"
  },
  {
    "uniprot": "A4I7V0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7V0"
  },
  {
    "uniprot": "A4I247",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I247"
  },
  {
    "uniprot": "A4HWW5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWW5"
  },
  {
    "uniprot": "A4I2F8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2F8"
  },
  {
    "uniprot": "A4I274",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I274"
  },
  {
    "uniprot": "A4I5B9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5B9"
  },
  {
    "uniprot": "A4HRZ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRZ6"
  },
  {
    "uniprot": "A4HSC9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSC9"
  },
  {
    "uniprot": "A4HYS5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYS5"
  },
  {
    "uniprot": "A4I373",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I373"
  },
  {
    "uniprot": "A4HZ13",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ13"
  },
  {
    "uniprot": "A4I213",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I213"
  },
  {
    "uniprot": "E9AG69",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG69"
  },
  {
    "uniprot": "A4I8X3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8X3"
  },
  {
    "uniprot": "A4I1I1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1I1"
  },
  {
    "uniprot": "A4I111",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I111"
  },
  {
    "uniprot": "A4HWK4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWK4"
  },
  {
    "uniprot": "A4HWJ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWJ9"
  },
  {
    "uniprot": "A4I2B4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2B4"
  },
  {
    "uniprot": "A4HRY0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRY0"
  },
  {
    "uniprot": "A4I5Q3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Q3"
  },
  {
    "uniprot": "A4IDJ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDJ8"
  },
  {
    "uniprot": "A4I5V8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5V8"
  },
  {
    "uniprot": "A4ID62",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID62"
  },
  {
    "uniprot": "A4I263",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I263"
  },
  {
    "uniprot": "A4I1J2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1J2"
  },
  {
    "uniprot": "A4HSE8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSE8"
  },
  {
    "uniprot": "A4I5X4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5X4"
  },
  {
    "uniprot": "A4HXI2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXI2"
  },
  {
    "uniprot": "A4I151",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I151"
  },
  {
    "uniprot": "A4IBX8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBX8"
  },
  {
    "uniprot": "A4I2L2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2L2"
  },
  {
    "uniprot": "A4I8B5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8B5"
  },
  {
    "uniprot": "A4I813",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I813"
  },
  {
    "uniprot": "E9AGA1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGA1"
  },
  {
    "uniprot": "A4I334",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I334"
  },
  {
    "uniprot": "A4I7C3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7C3"
  },
  {
    "uniprot": "A4I7I6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7I6"
  },
  {
    "uniprot": "A4HUC6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUC6"
  },
  {
    "uniprot": "E9AGF7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGF7"
  },
  {
    "uniprot": "A4HZ45",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ45"
  },
  {
    "uniprot": "E9AH20",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH20"
  },
  {
    "uniprot": "A4IBC8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBC8"
  },
  {
    "uniprot": "A4ICY8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICY8"
  },
  {
    "uniprot": "A4HV56",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV56"
  },
  {
    "uniprot": "A4I2M5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2M5"
  },
  {
    "uniprot": "A4IAL7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAL7"
  },
  {
    "uniprot": "A4I6I0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6I0"
  },
  {
    "uniprot": "A4HXK0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXK0"
  },
  {
    "uniprot": "A4HYT5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYT5"
  },
  {
    "uniprot": "A4HXA2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXA2"
  },
  {
    "uniprot": "A4I4B9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4B9"
  },
  {
    "uniprot": "A4I9Q2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Q2"
  },
  {
    "uniprot": "A4IA61",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA61"
  },
  {
    "uniprot": "A4I363",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I363"
  },
  {
    "uniprot": "A4HUJ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUJ2"
  },
  {
    "uniprot": "A4HTM4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTM4"
  },
  {
    "uniprot": "A4HX16",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX16"
  },
  {
    "uniprot": "A4IBP1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBP1"
  },
  {
    "uniprot": "A4I7W8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7W8"
  },
  {
    "uniprot": "A4ICL7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICL7"
  },
  {
    "uniprot": "A4HSY3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSY3"
  },
  {
    "uniprot": "A4I0J3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0J3"
  },
  {
    "uniprot": "A4I265",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I265"
  },
  {
    "uniprot": "A4I6W6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6W6"
  },
  {
    "uniprot": "A4HRX6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRX6"
  },
  {
    "uniprot": "A4HXL2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXL2"
  },
  {
    "uniprot": "E9AH60",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH60"
  },
  {
    "uniprot": "A4HUY5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUY5"
  },
  {
    "uniprot": "A4I6U1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6U1"
  },
  {
    "uniprot": "A4I924",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I924"
  },
  {
    "uniprot": "A4HZC6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZC6"
  },
  {
    "uniprot": "A4I8W6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8W6"
  },
  {
    "uniprot": "A4I2M9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2M9"
  },
  {
    "uniprot": "A4ICC1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICC1"
  },
  {
    "uniprot": "A4HWR1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWR1"
  },
  {
    "uniprot": "A4I7S6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7S6"
  },
  {
    "uniprot": "A4IBF0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBF0"
  },
  {
    "uniprot": "E9AHJ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHJ9"
  },
  {
    "uniprot": "A4I000",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I000"
  },
  {
    "uniprot": "A4HWB0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWB0"
  },
  {
    "uniprot": "A4HW75",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW75"
  },
  {
    "uniprot": "A4HWN6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWN6"
  },
  {
    "uniprot": "A4IDV8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDV8"
  },
  {
    "uniprot": "A4IDJ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDJ9"
  },
  {
    "uniprot": "A4HS87",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS87"
  },
  {
    "uniprot": "A4HTM0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTM0"
  },
  {
    "uniprot": "A4I2B3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2B3"
  },
  {
    "uniprot": "A4ICE7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICE7"
  },
  {
    "uniprot": "E9AGC4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGC4"
  },
  {
    "uniprot": "E9AGE2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGE2"
  },
  {
    "uniprot": "A4I8D4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8D4"
  },
  {
    "uniprot": "A4HVT9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVT9"
  },
  {
    "uniprot": "A4HYW8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYW8"
  },
  {
    "uniprot": "A4I769",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I769"
  },
  {
    "uniprot": "A4HWH2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWH2"
  },
  {
    "uniprot": "A4HS53",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS53"
  },
  {
    "uniprot": "A4HZ97",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ97"
  },
  {
    "uniprot": "A4I8E2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8E2"
  },
  {
    "uniprot": "A4I3G3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3G3"
  },
  {
    "uniprot": "E9AG43",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG43"
  },
  {
    "uniprot": "A4HYP9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYP9"
  },
  {
    "uniprot": "A4HWQ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWQ1"
  },
  {
    "uniprot": "A4I849",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I849"
  },
  {
    "uniprot": "A4HZ61",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ61"
  },
  {
    "uniprot": "A4I230",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I230"
  },
  {
    "uniprot": "A4HZ41",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ41"
  },
  {
    "uniprot": "A4HSM0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSM0"
  },
  {
    "uniprot": "A4HZ73",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ73"
  },
  {
    "uniprot": "A4HX10",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX10"
  },
  {
    "uniprot": "A4HTD1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTD1"
  },
  {
    "uniprot": "A4I3P0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3P0"
  },
  {
    "uniprot": "A4HW28",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW28"
  },
  {
    "uniprot": "A4IDV5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDV5"
  },
  {
    "uniprot": "A4HSK4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSK4"
  },
  {
    "uniprot": "A4I6P1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6P1"
  },
  {
    "uniprot": "A4I826",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I826"
  },
  {
    "uniprot": "A4I4Q1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Q1"
  },
  {
    "uniprot": "A4I9H4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9H4"
  },
  {
    "uniprot": "A4HY89",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY89"
  },
  {
    "uniprot": "A4I0S8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0S8"
  },
  {
    "uniprot": "A4I8V3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8V3"
  },
  {
    "uniprot": "E9AGQ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGQ1"
  },
  {
    "uniprot": "A4IBE1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBE1"
  },
  {
    "uniprot": "E9AGL0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGL0"
  },
  {
    "uniprot": "A4HX53",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX53"
  },
  {
    "uniprot": "E9AG65",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG65"
  },
  {
    "uniprot": "A4ICV3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICV3"
  },
  {
    "uniprot": "A4I0E7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0E7"
  },
  {
    "uniprot": "A4I8B9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8B9"
  },
  {
    "uniprot": "A4IBM0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBM0"
  },
  {
    "uniprot": "A4I7X6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7X6"
  },
  {
    "uniprot": "A4HZ74",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ74"
  },
  {
    "uniprot": "A4IA57",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA57"
  },
  {
    "uniprot": "A4HRE4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRE4"
  },
  {
    "uniprot": "A4ID79",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID79"
  },
  {
    "uniprot": "A4HYE1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYE1"
  },
  {
    "uniprot": "A4I9E5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9E5"
  },
  {
    "uniprot": "A4HVG8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVG8"
  },
  {
    "uniprot": "A4HZL8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZL8"
  },
  {
    "uniprot": "A4HYV9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYV9"
  },
  {
    "uniprot": "A4I8Q3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Q3"
  },
  {
    "uniprot": "A4HX66",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX66"
  },
  {
    "uniprot": "A4I9H2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9H2"
  },
  {
    "uniprot": "A4HTQ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTQ1"
  },
  {
    "uniprot": "A4I441",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I441"
  },
  {
    "uniprot": "A4IDC7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDC7"
  },
  {
    "uniprot": "A4I471",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I471"
  },
  {
    "uniprot": "A4HWP1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWP1"
  },
  {
    "uniprot": "A4HYU0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYU0"
  },
  {
    "uniprot": "E9AH16",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH16"
  },
  {
    "uniprot": "A4IDW2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDW2"
  },
  {
    "uniprot": "A4I6R2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6R2"
  },
  {
    "uniprot": "A4I2N0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2N0"
  },
  {
    "uniprot": "E9AH44",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH44"
  },
  {
    "uniprot": "E9AHK7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHK7"
  },
  {
    "uniprot": "A4I5E6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5E6"
  },
  {
    "uniprot": "A4HVH0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVH0"
  },
  {
    "uniprot": "A4I7G4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7G4"
  },
  {
    "uniprot": "A4I8I3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8I3"
  },
  {
    "uniprot": "A4I9C9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9C9"
  },
  {
    "uniprot": "A4IC64",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC64"
  },
  {
    "uniprot": "A4I8M7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8M7"
  },
  {
    "uniprot": "A4HY76",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY76"
  },
  {
    "uniprot": "A4ICK0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICK0"
  },
  {
    "uniprot": "E9AHM2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHM2"
  },
  {
    "uniprot": "A4HXH7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXH7"
  },
  {
    "uniprot": "A4HRU5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRU5"
  },
  {
    "uniprot": "A4I489",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I489"
  },
  {
    "uniprot": "A4HZ56",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ56"
  },
  {
    "uniprot": "A4I4Z8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Z8"
  },
  {
    "uniprot": "A4I6W0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6W0"
  },
  {
    "uniprot": "A4I076",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I076"
  },
  {
    "uniprot": "A4I0V5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0V5"
  },
  {
    "uniprot": "A4I703",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I703"
  },
  {
    "uniprot": "A4HT18",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT18"
  },
  {
    "uniprot": "A4I417",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I417"
  },
  {
    "uniprot": "A4HRK2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRK2"
  },
  {
    "uniprot": "A4I4Y6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Y6"
  },
  {
    "uniprot": "E9AGB3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGB3"
  },
  {
    "uniprot": "A4I6P5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6P5"
  },
  {
    "uniprot": "A4HUS0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUS0"
  },
  {
    "uniprot": "A4IA87",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA87"
  },
  {
    "uniprot": "A4I7P7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7P7"
  },
  {
    "uniprot": "A4I0X2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0X2"
  },
  {
    "uniprot": "A4I5G4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5G4"
  },
  {
    "uniprot": "A4IBW9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBW9"
  },
  {
    "uniprot": "A4I3Q0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Q0"
  },
  {
    "uniprot": "A4HRK6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRK6"
  },
  {
    "uniprot": "A4I8L5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8L5"
  },
  {
    "uniprot": "A4I2S2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2S2"
  },
  {
    "uniprot": "A4HTD2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTD2"
  },
  {
    "uniprot": "A4I8Z0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Z0"
  },
  {
    "uniprot": "A4HZ90",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ90"
  },
  {
    "uniprot": "A4IBI1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBI1"
  },
  {
    "uniprot": "A4ICG2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICG2"
  },
  {
    "uniprot": "A4I443",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I443"
  },
  {
    "uniprot": "A4HY24",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY24"
  },
  {
    "uniprot": "A4I1U2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1U2"
  },
  {
    "uniprot": "A4IAG0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAG0"
  },
  {
    "uniprot": "A4HTL2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTL2"
  },
  {
    "uniprot": "A4I7R4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7R4"
  },
  {
    "uniprot": "A4HZH0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZH0"
  },
  {
    "uniprot": "A4I0V1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0V1"
  },
  {
    "uniprot": "A4I0C6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0C6"
  },
  {
    "uniprot": "A4I1W8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1W8"
  },
  {
    "uniprot": "A4I3I1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3I1"
  },
  {
    "uniprot": "A4I8D9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8D9"
  },
  {
    "uniprot": "A4IAR9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAR9"
  },
  {
    "uniprot": "A4I3A5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3A5"
  },
  {
    "uniprot": "A4HVE5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVE5"
  },
  {
    "uniprot": "A4HVR9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVR9"
  },
  {
    "uniprot": "A4IAH0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAH0"
  },
  {
    "uniprot": "A4HUJ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUJ4"
  },
  {
    "uniprot": "A4ICI6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICI6"
  },
  {
    "uniprot": "A4ID18",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID18"
  },
  {
    "uniprot": "A4IC47",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC47"
  },
  {
    "uniprot": "A4ICZ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICZ0"
  },
  {
    "uniprot": "A4HU10",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU10"
  },
  {
    "uniprot": "A4HX41",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX41"
  },
  {
    "uniprot": "A4HY15",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY15"
  },
  {
    "uniprot": "A4I5J5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5J5"
  },
  {
    "uniprot": "A4HTX8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTX8"
  },
  {
    "uniprot": "A4I7C5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7C5"
  },
  {
    "uniprot": "A4I6Y2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Y2"
  },
  {
    "uniprot": "A4ID23",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID23"
  },
  {
    "uniprot": "A4HU75",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU75"
  },
  {
    "uniprot": "A4HXD0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXD0"
  },
  {
    "uniprot": "A4HVS4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVS4"
  },
  {
    "uniprot": "A4IBD0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBD0"
  },
  {
    "uniprot": "A4IBD4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBD4"
  },
  {
    "uniprot": "A4HZE8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZE8"
  },
  {
    "uniprot": "A4HS20",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS20"
  },
  {
    "uniprot": "A4I4C4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4C4"
  },
  {
    "uniprot": "A4I794",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I794"
  },
  {
    "uniprot": "A4I352",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I352"
  },
  {
    "uniprot": "A4I6F6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6F6"
  },
  {
    "uniprot": "A4I5P2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5P2"
  },
  {
    "uniprot": "A4HY50",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY50"
  },
  {
    "uniprot": "A4I235",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I235"
  },
  {
    "uniprot": "A4I3F6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3F6"
  },
  {
    "uniprot": "A4HTG3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTG3"
  },
  {
    "uniprot": "A4HY94",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY94"
  },
  {
    "uniprot": "A4I6F7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6F7"
  },
  {
    "uniprot": "E9AHB9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHB9"
  },
  {
    "uniprot": "A4I7Q9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Q9"
  },
  {
    "uniprot": "A4HRU8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRU8"
  },
  {
    "uniprot": "A4HZG3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZG3"
  },
  {
    "uniprot": "A4I2Q2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Q2"
  },
  {
    "uniprot": "A4ID25",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID25"
  },
  {
    "uniprot": "A4I8Z9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Z9"
  },
  {
    "uniprot": "A4ICM3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICM3"
  },
  {
    "uniprot": "A4IBA9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBA9"
  },
  {
    "uniprot": "A4HRQ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRQ2"
  },
  {
    "uniprot": "A4IDK8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDK8"
  },
  {
    "uniprot": "A4HTP4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTP4"
  },
  {
    "uniprot": "A4I145",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I145"
  },
  {
    "uniprot": "A4HRX7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRX7"
  },
  {
    "uniprot": "A0A381ML35",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381ML35"
  },
  {
    "uniprot": "A4I2J6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2J6"
  },
  {
    "uniprot": "A4I9I0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9I0"
  },
  {
    "uniprot": "A4HU40",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU40"
  },
  {
    "uniprot": "A4I1D5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1D5"
  },
  {
    "uniprot": "A4I4F7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4F7"
  },
  {
    "uniprot": "A4HU16",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU16"
  },
  {
    "uniprot": "A4I6T3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6T3"
  },
  {
    "uniprot": "A4HY85",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY85"
  },
  {
    "uniprot": "A4HXE9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXE9"
  },
  {
    "uniprot": "A4ICM2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICM2"
  },
  {
    "uniprot": "A4I2K2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2K2"
  },
  {
    "uniprot": "A4I8Q8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Q8"
  },
  {
    "uniprot": "E9AHW1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHW1"
  },
  {
    "uniprot": "A4I1F8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1F8"
  },
  {
    "uniprot": "E9AHP6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHP6"
  },
  {
    "uniprot": "A4IE18",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE18"
  },
  {
    "uniprot": "A4I0R7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0R7"
  },
  {
    "uniprot": "A4IAA5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAA5"
  },
  {
    "uniprot": "A4I6X0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6X0"
  },
  {
    "uniprot": "A4HUD7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUD7"
  },
  {
    "uniprot": "A4IAZ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAZ7"
  },
  {
    "uniprot": "A4I203",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I203"
  },
  {
    "uniprot": "A4I9V0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9V0"
  },
  {
    "uniprot": "A4I8M4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8M4"
  },
  {
    "uniprot": "A4I3H3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3H3"
  },
  {
    "uniprot": "A4I5P0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5P0"
  },
  {
    "uniprot": "E9AGM1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGM1"
  },
  {
    "uniprot": "A4IBW1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBW1"
  },
  {
    "uniprot": "A4I4Y9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Y9"
  },
  {
    "uniprot": "A4HWX4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWX4"
  },
  {
    "uniprot": "A4HSF0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSF0"
  },
  {
    "uniprot": "A4I4E8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4E8"
  },
  {
    "uniprot": "A4I7V7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7V7"
  },
  {
    "uniprot": "A4IBR3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBR3"
  },
  {
    "uniprot": "A4I1T1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1T1"
  },
  {
    "uniprot": "A4IAW9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAW9"
  },
  {
    "uniprot": "A4HV29",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV29"
  },
  {
    "uniprot": "A4I349",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I349"
  },
  {
    "uniprot": "A4I310",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I310"
  },
  {
    "uniprot": "A4I0J7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0J7"
  },
  {
    "uniprot": "A4HSN2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSN2"
  },
  {
    "uniprot": "A4HT58",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT58"
  },
  {
    "uniprot": "A4HS13",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS13"
  },
  {
    "uniprot": "E9AHN9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHN9"
  },
  {
    "uniprot": "A4HY63",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY63"
  },
  {
    "uniprot": "A4HYE9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYE9"
  },
  {
    "uniprot": "A4HY31",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY31"
  },
  {
    "uniprot": "A4I3F1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3F1"
  },
  {
    "uniprot": "A4HZI9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZI9"
  },
  {
    "uniprot": "A4HRM2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRM2"
  },
  {
    "uniprot": "A4IAS1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAS1"
  },
  {
    "uniprot": "A4HWL9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWL9"
  },
  {
    "uniprot": "A4HRN7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRN7"
  },
  {
    "uniprot": "A4IDL8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDL8"
  },
  {
    "uniprot": "A4ICG7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICG7"
  },
  {
    "uniprot": "A4IDU7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDU7"
  },
  {
    "uniprot": "A4I7P2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7P2"
  },
  {
    "uniprot": "A4HXQ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXQ7"
  },
  {
    "uniprot": "A4I1E7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1E7"
  },
  {
    "uniprot": "A4HYZ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYZ3"
  },
  {
    "uniprot": "A4HYT4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYT4"
  },
  {
    "uniprot": "A4HYN1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYN1"
  },
  {
    "uniprot": "A4I4V8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4V8"
  },
  {
    "uniprot": "A4HY83",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY83"
  },
  {
    "uniprot": "A4HS58",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS58"
  },
  {
    "uniprot": "A4I5K2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5K2"
  },
  {
    "uniprot": "A4HVW8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVW8"
  },
  {
    "uniprot": "A4HS98",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS98"
  },
  {
    "uniprot": "A4HS27",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS27"
  },
  {
    "uniprot": "A4ID14",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID14"
  },
  {
    "uniprot": "A4I9L2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9L2"
  },
  {
    "uniprot": "A4I9S6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9S6"
  },
  {
    "uniprot": "A4I1R4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1R4"
  },
  {
    "uniprot": "A4HSU2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSU2"
  },
  {
    "uniprot": "A4I1K3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1K3"
  },
  {
    "uniprot": "A4IAQ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAQ2"
  },
  {
    "uniprot": "A4I7M7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7M7"
  },
  {
    "uniprot": "A4I9B1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9B1"
  },
  {
    "uniprot": "A4IBG8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBG8"
  },
  {
    "uniprot": "A4HZV6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZV6"
  },
  {
    "uniprot": "A4I0M8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0M8"
  },
  {
    "uniprot": "A4HS61",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS61"
  },
  {
    "uniprot": "E9AG26",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG26"
  },
  {
    "uniprot": "A4I307",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I307"
  },
  {
    "uniprot": "A4I855",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I855"
  },
  {
    "uniprot": "A4I9Y0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Y0"
  },
  {
    "uniprot": "A4I4L6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4L6"
  },
  {
    "uniprot": "E9AGL5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGL5"
  },
  {
    "uniprot": "A4I9E4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9E4"
  },
  {
    "uniprot": "A4IAT6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAT6"
  },
  {
    "uniprot": "A4I424",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I424"
  },
  {
    "uniprot": "A4HX30",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX30"
  },
  {
    "uniprot": "A4I771",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I771"
  },
  {
    "uniprot": "A4I8T9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8T9"
  },
  {
    "uniprot": "A4IAC4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAC4"
  },
  {
    "uniprot": "A4IC27",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC27"
  },
  {
    "uniprot": "A4I9K8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9K8"
  },
  {
    "uniprot": "A4HXE7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXE7"
  },
  {
    "uniprot": "A4ICX6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICX6"
  },
  {
    "uniprot": "A4HTD6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTD6"
  },
  {
    "uniprot": "A4HX92",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX92"
  },
  {
    "uniprot": "A4I759",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I759"
  },
  {
    "uniprot": "A4IB58",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB58"
  },
  {
    "uniprot": "A4I8M2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8M2"
  },
  {
    "uniprot": "A4IC46",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC46"
  },
  {
    "uniprot": "A4IBX5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBX5"
  },
  {
    "uniprot": "E9AG36",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG36"
  },
  {
    "uniprot": "A4I3K0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3K0"
  },
  {
    "uniprot": "A4I571",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I571"
  },
  {
    "uniprot": "A4I615",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I615"
  },
  {
    "uniprot": "A4IB11",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB11"
  },
  {
    "uniprot": "A4I234",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I234"
  },
  {
    "uniprot": "A4ICB7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICB7"
  },
  {
    "uniprot": "A4I7M0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7M0"
  },
  {
    "uniprot": "A4IA13",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA13"
  },
  {
    "uniprot": "A4I950",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I950"
  },
  {
    "uniprot": "A4IBV5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBV5"
  },
  {
    "uniprot": "A4I3I5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3I5"
  },
  {
    "uniprot": "A4I5B8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5B8"
  },
  {
    "uniprot": "A4I0U0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0U0"
  },
  {
    "uniprot": "A4I6Z1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Z1"
  },
  {
    "uniprot": "A4IA48",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA48"
  },
  {
    "uniprot": "A4I5A7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5A7"
  },
  {
    "uniprot": "E9AG68",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG68"
  },
  {
    "uniprot": "A4HSA2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSA2"
  },
  {
    "uniprot": "A4IDX2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDX2"
  },
  {
    "uniprot": "A4I4N3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4N3"
  },
  {
    "uniprot": "A4I273",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I273"
  },
  {
    "uniprot": "A4HSF3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSF3"
  },
  {
    "uniprot": "E9AH24",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH24"
  },
  {
    "uniprot": "A4I0L0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0L0"
  },
  {
    "uniprot": "A4HVK2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVK2"
  },
  {
    "uniprot": "A4I2B6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2B6"
  },
  {
    "uniprot": "A4I0F8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0F8"
  },
  {
    "uniprot": "A4HRW4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRW4"
  },
  {
    "uniprot": "A4I052",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I052"
  },
  {
    "uniprot": "A4I5M8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5M8"
  },
  {
    "uniprot": "E9AG46",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG46"
  },
  {
    "uniprot": "A4HUJ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUJ6"
  },
  {
    "uniprot": "A4I5V0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5V0"
  },
  {
    "uniprot": "A4IDI7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDI7"
  },
  {
    "uniprot": "A4I6R1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6R1"
  },
  {
    "uniprot": "A4IE53",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE53"
  },
  {
    "uniprot": "A4I5J2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5J2"
  },
  {
    "uniprot": "A4HXL7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXL7"
  },
  {
    "uniprot": "A4I1A9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1A9"
  },
  {
    "uniprot": "A4I8M8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8M8"
  },
  {
    "uniprot": "A4HZU3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZU3"
  },
  {
    "uniprot": "A4HU62",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU62"
  },
  {
    "uniprot": "A4I283",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I283"
  },
  {
    "uniprot": "A4I8X2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8X2"
  },
  {
    "uniprot": "A4IDD6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDD6"
  },
  {
    "uniprot": "A4I4G4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4G4"
  },
  {
    "uniprot": "A4I868",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I868"
  },
  {
    "uniprot": "A4HTV9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTV9"
  },
  {
    "uniprot": "A4I7B7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7B7"
  },
  {
    "uniprot": "A4HSW8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSW8"
  },
  {
    "uniprot": "A4HUW7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUW7"
  },
  {
    "uniprot": "A4IBT6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBT6"
  },
  {
    "uniprot": "A4HTC6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTC6"
  },
  {
    "uniprot": "A4HU66",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU66"
  },
  {
    "uniprot": "A4I6Y7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Y7"
  },
  {
    "uniprot": "A4HRC8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRC8"
  },
  {
    "uniprot": "A4HZY6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZY6"
  },
  {
    "uniprot": "E9AHE1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHE1"
  },
  {
    "uniprot": "A4I8R3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8R3"
  },
  {
    "uniprot": "E9AHE5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHE5"
  },
  {
    "uniprot": "A4I8C3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8C3"
  },
  {
    "uniprot": "A4HZZ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZZ3"
  },
  {
    "uniprot": "A4HWG6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWG6"
  },
  {
    "uniprot": "A4IDR2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDR2"
  },
  {
    "uniprot": "A4I4L1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4L1"
  },
  {
    "uniprot": "A0A381ML17",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381ML17"
  },
  {
    "uniprot": "A4I2Z3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Z3"
  },
  {
    "uniprot": "A4I2T2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2T2"
  },
  {
    "uniprot": "A4I3Z6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Z6"
  },
  {
    "uniprot": "A4HY08",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY08"
  },
  {
    "uniprot": "A4HTB4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTB4"
  },
  {
    "uniprot": "A4I3H9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3H9"
  },
  {
    "uniprot": "A4I9V8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9V8"
  },
  {
    "uniprot": "A4HZT8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZT8"
  },
  {
    "uniprot": "A4I6S6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6S6"
  },
  {
    "uniprot": "A4I480",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I480"
  },
  {
    "uniprot": "A4I991",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I991"
  },
  {
    "uniprot": "A4HZ32",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ32"
  },
  {
    "uniprot": "A4HV37",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV37"
  },
  {
    "uniprot": "A4HUY9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUY9"
  },
  {
    "uniprot": "A4IAL2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAL2"
  },
  {
    "uniprot": "A4IA08",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA08"
  },
  {
    "uniprot": "A4HTS3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTS3"
  },
  {
    "uniprot": "A4I6W8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6W8"
  },
  {
    "uniprot": "A4I3V8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3V8"
  },
  {
    "uniprot": "A4HU06",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU06"
  },
  {
    "uniprot": "E9AGQ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGQ8"
  },
  {
    "uniprot": "A4I9D6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9D6"
  },
  {
    "uniprot": "E9AHH8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHH8"
  },
  {
    "uniprot": "A4I2H4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2H4"
  },
  {
    "uniprot": "A4HRR8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRR8"
  },
  {
    "uniprot": "A4I9Q7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Q7"
  },
  {
    "uniprot": "Q18L04",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q18L04"
  },
  {
    "uniprot": "A4I4P9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4P9"
  },
  {
    "uniprot": "A4HU59",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU59"
  },
  {
    "uniprot": "A4ID08",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID08"
  },
  {
    "uniprot": "A4IBX6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBX6"
  },
  {
    "uniprot": "A4HSP9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSP9"
  },
  {
    "uniprot": "A4I9Y8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Y8"
  },
  {
    "uniprot": "A4HUZ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUZ1"
  },
  {
    "uniprot": "E9AHX3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHX3"
  },
  {
    "uniprot": "A4IA15",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA15"
  },
  {
    "uniprot": "A4I3F5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3F5"
  },
  {
    "uniprot": "A4I7G8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7G8"
  },
  {
    "uniprot": "A4I481",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I481"
  },
  {
    "uniprot": "A4I3Q2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Q2"
  },
  {
    "uniprot": "A4I5D5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5D5"
  },
  {
    "uniprot": "E9AGA8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGA8"
  },
  {
    "uniprot": "A4I0V9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0V9"
  },
  {
    "uniprot": "E9AGF3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGF3"
  },
  {
    "uniprot": "A4HYY1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYY1"
  },
  {
    "uniprot": "A4I9L1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9L1"
  },
  {
    "uniprot": "A4HZV4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZV4"
  },
  {
    "uniprot": "A4I484",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I484"
  },
  {
    "uniprot": "A4HRQ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRQ1"
  },
  {
    "uniprot": "A4I3F4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3F4"
  },
  {
    "uniprot": "A4HXZ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXZ4"
  },
  {
    "uniprot": "A4HYL0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYL0"
  },
  {
    "uniprot": "A4I2J7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2J7"
  },
  {
    "uniprot": "A4I8P5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8P5"
  },
  {
    "uniprot": "A4ID92",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID92"
  },
  {
    "uniprot": "A4ICP7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICP7"
  },
  {
    "uniprot": "A4I883",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I883"
  },
  {
    "uniprot": "A4HTT0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTT0"
  },
  {
    "uniprot": "A4I185",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I185"
  },
  {
    "uniprot": "A4HYJ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYJ9"
  },
  {
    "uniprot": "A4HYK8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYK8"
  },
  {
    "uniprot": "A4I3A6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3A6"
  },
  {
    "uniprot": "A4IDE1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDE1"
  },
  {
    "uniprot": "A4I6P6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6P6"
  },
  {
    "uniprot": "A4IDP0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDP0"
  },
  {
    "uniprot": "A4HX56",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX56"
  },
  {
    "uniprot": "A4I990",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I990"
  },
  {
    "uniprot": "A4I2T4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2T4"
  },
  {
    "uniprot": "A4I1T8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1T8"
  },
  {
    "uniprot": "A4HZB3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZB3"
  },
  {
    "uniprot": "A4IDD4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDD4"
  },
  {
    "uniprot": "A4HW82",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW82"
  },
  {
    "uniprot": "A4HX07",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX07"
  },
  {
    "uniprot": "A4I5D9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5D9"
  },
  {
    "uniprot": "E9AG77",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG77"
  },
  {
    "uniprot": "A4IE31",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE31"
  },
  {
    "uniprot": "A4HVP7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVP7"
  },
  {
    "uniprot": "A4HY93",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY93"
  },
  {
    "uniprot": "E8NHC8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E8NHC8"
  },
  {
    "uniprot": "E9AHI6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHI6"
  },
  {
    "uniprot": "A4I0Y8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Y8"
  },
  {
    "uniprot": "A4I8L8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8L8"
  },
  {
    "uniprot": "A4HVP2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVP2"
  },
  {
    "uniprot": "E9AGR0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGR0"
  },
  {
    "uniprot": "E9AHZ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHZ7"
  },
  {
    "uniprot": "A4HXU2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXU2"
  },
  {
    "uniprot": "A4I6Q7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Q7"
  },
  {
    "uniprot": "E9AHH3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHH3"
  },
  {
    "uniprot": "A4I6E8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6E8"
  },
  {
    "uniprot": "A4I5F8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5F8"
  },
  {
    "uniprot": "A4ICQ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICQ7"
  },
  {
    "uniprot": "A4I0X9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0X9"
  },
  {
    "uniprot": "A4I8W5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8W5"
  },
  {
    "uniprot": "A4HZF8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZF8"
  },
  {
    "uniprot": "A4HWR9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWR9"
  },
  {
    "uniprot": "A4I5Z4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Z4"
  },
  {
    "uniprot": "A4I4E1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4E1"
  },
  {
    "uniprot": "Q7K8Z6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q7K8Z6"
  },
  {
    "uniprot": "A4I6U0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6U0"
  },
  {
    "uniprot": "A4HUC8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUC8"
  },
  {
    "uniprot": "A4IBS5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBS5"
  },
  {
    "uniprot": "A4I5Z8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Z8"
  },
  {
    "uniprot": "A4HRJ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRJ4"
  },
  {
    "uniprot": "A4IAL5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAL5"
  },
  {
    "uniprot": "A4IB86",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB86"
  },
  {
    "uniprot": "A4ICH1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICH1"
  },
  {
    "uniprot": "A4I0G3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0G3"
  },
  {
    "uniprot": "A4HYJ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYJ8"
  },
  {
    "uniprot": "A4HRD3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRD3"
  },
  {
    "uniprot": "A4HSY6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSY6"
  },
  {
    "uniprot": "A4I2T8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2T8"
  },
  {
    "uniprot": "A4HRW2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRW2"
  },
  {
    "uniprot": "A4I0M6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0M6"
  },
  {
    "uniprot": "A4HSJ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSJ2"
  },
  {
    "uniprot": "A4ICJ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICJ3"
  },
  {
    "uniprot": "A4I282",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I282"
  },
  {
    "uniprot": "A4I003",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I003"
  },
  {
    "uniprot": "E8NHC2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E8NHC2"
  },
  {
    "uniprot": "A4I062",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I062"
  },
  {
    "uniprot": "A4I4S8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4S8"
  },
  {
    "uniprot": "A4ICI2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICI2"
  },
  {
    "uniprot": "A4I2A5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2A5"
  },
  {
    "uniprot": "A4I450",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I450"
  },
  {
    "uniprot": "A4HT87",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT87"
  },
  {
    "uniprot": "A4HSW2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSW2"
  },
  {
    "uniprot": "A4IE61",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE61"
  },
  {
    "uniprot": "A4ICN7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICN7"
  },
  {
    "uniprot": "A4I2J5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2J5"
  },
  {
    "uniprot": "A4I609",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I609"
  },
  {
    "uniprot": "A4HZC5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZC5"
  },
  {
    "uniprot": "A4I7W7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7W7"
  },
  {
    "uniprot": "A4HVG5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVG5"
  },
  {
    "uniprot": "A4I0P2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0P2"
  },
  {
    "uniprot": "A4I122",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I122"
  },
  {
    "uniprot": "A4HZD9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZD9"
  },
  {
    "uniprot": "A4IDP8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDP8"
  },
  {
    "uniprot": "A4I3T9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3T9"
  },
  {
    "uniprot": "A4IBG1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBG1"
  },
  {
    "uniprot": "A4IB82",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB82"
  },
  {
    "uniprot": "A4HUC0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUC0"
  },
  {
    "uniprot": "A4IAQ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAQ8"
  },
  {
    "uniprot": "A4HRI4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRI4"
  },
  {
    "uniprot": "A4HWG7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWG7"
  },
  {
    "uniprot": "A4I3C9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3C9"
  },
  {
    "uniprot": "A4HVF1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVF1"
  },
  {
    "uniprot": "A4I346",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I346"
  },
  {
    "uniprot": "A4I760",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I760"
  },
  {
    "uniprot": "A4I1N8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1N8"
  },
  {
    "uniprot": "A4IB81",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB81"
  },
  {
    "uniprot": "A4I9A6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9A6"
  },
  {
    "uniprot": "A4HYV3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYV3"
  },
  {
    "uniprot": "A4I9S8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9S8"
  },
  {
    "uniprot": "A4HX51",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX51"
  },
  {
    "uniprot": "A4ID70",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID70"
  },
  {
    "uniprot": "A4HW73",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW73"
  },
  {
    "uniprot": "A4I5P5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5P5"
  },
  {
    "uniprot": "A4I5W1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5W1"
  },
  {
    "uniprot": "A4HSX2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSX2"
  },
  {
    "uniprot": "E9AGL7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGL7"
  },
  {
    "uniprot": "A4I210",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I210"
  },
  {
    "uniprot": "A4I9U3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9U3"
  },
  {
    "uniprot": "A4I6Q5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Q5"
  },
  {
    "uniprot": "A4IDI8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDI8"
  },
  {
    "uniprot": "A4HXJ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXJ3"
  },
  {
    "uniprot": "E9AHN7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHN7"
  },
  {
    "uniprot": "A4I5Y5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Y5"
  },
  {
    "uniprot": "A4HUH5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUH5"
  },
  {
    "uniprot": "A4IDG6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDG6"
  },
  {
    "uniprot": "A4HRY3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRY3"
  },
  {
    "uniprot": "A4HXJ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXJ8"
  },
  {
    "uniprot": "A4I260",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I260"
  },
  {
    "uniprot": "A4IA09",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA09"
  },
  {
    "uniprot": "A4ICR1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICR1"
  },
  {
    "uniprot": "A4IAG7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAG7"
  },
  {
    "uniprot": "A4I8X5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8X5"
  },
  {
    "uniprot": "A4IBX0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBX0"
  },
  {
    "uniprot": "E9AGG8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGG8"
  },
  {
    "uniprot": "A4IC42",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC42"
  },
  {
    "uniprot": "A4HTS8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTS8"
  },
  {
    "uniprot": "A4HSB0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSB0"
  },
  {
    "uniprot": "A4I5C9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5C9"
  },
  {
    "uniprot": "A4IE04",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE04"
  },
  {
    "uniprot": "A4I1T4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1T4"
  },
  {
    "uniprot": "A4I4D9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4D9"
  },
  {
    "uniprot": "A4I4D1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4D1"
  },
  {
    "uniprot": "A4ICR9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICR9"
  },
  {
    "uniprot": "E9AGH2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGH2"
  },
  {
    "uniprot": "A4HY19",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY19"
  },
  {
    "uniprot": "E9AG92",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG92"
  },
  {
    "uniprot": "A4ICT7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICT7"
  },
  {
    "uniprot": "A4I7R0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7R0"
  },
  {
    "uniprot": "A4I305",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I305"
  },
  {
    "uniprot": "A4ID56",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID56"
  },
  {
    "uniprot": "A4IDI0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDI0"
  },
  {
    "uniprot": "A4IDY8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDY8"
  },
  {
    "uniprot": "A4I4M6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4M6"
  },
  {
    "uniprot": "A4I1M8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1M8"
  },
  {
    "uniprot": "A4IDZ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDZ1"
  },
  {
    "uniprot": "A4I2V6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2V6"
  },
  {
    "uniprot": "A4I0N2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0N2"
  },
  {
    "uniprot": "A4I7X1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7X1"
  },
  {
    "uniprot": "A4I663",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I663"
  },
  {
    "uniprot": "A4HRH4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRH4"
  },
  {
    "uniprot": "E9AH81",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH81"
  },
  {
    "uniprot": "A4I5Q1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Q1"
  },
  {
    "uniprot": "A4ID67",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID67"
  },
  {
    "uniprot": "A4I4N4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4N4"
  },
  {
    "uniprot": "A4IE05",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE05"
  },
  {
    "uniprot": "A4IDA5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDA5"
  },
  {
    "uniprot": "E9AHU4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHU4"
  },
  {
    "uniprot": "A4I4W9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4W9"
  },
  {
    "uniprot": "A4I768",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I768"
  },
  {
    "uniprot": "A4HRM0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRM0"
  },
  {
    "uniprot": "A4I926",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I926"
  },
  {
    "uniprot": "A4I2E6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2E6"
  },
  {
    "uniprot": "E9AGR4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGR4"
  },
  {
    "uniprot": "A4I3H1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3H1"
  },
  {
    "uniprot": "A4HSD4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSD4"
  },
  {
    "uniprot": "A4HWU4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWU4"
  },
  {
    "uniprot": "A4I9T4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9T4"
  },
  {
    "uniprot": "A4I1L0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1L0"
  },
  {
    "uniprot": "A4I306",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I306"
  },
  {
    "uniprot": "A4ICU5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICU5"
  },
  {
    "uniprot": "A4I057",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I057"
  },
  {
    "uniprot": "A4I0B7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0B7"
  },
  {
    "uniprot": "A4I061",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I061"
  },
  {
    "uniprot": "A4HXP8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXP8"
  },
  {
    "uniprot": "A4I3C2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3C2"
  },
  {
    "uniprot": "A4HVN9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVN9"
  },
  {
    "uniprot": "A4I0V6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0V6"
  },
  {
    "uniprot": "A4IBP9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBP9"
  },
  {
    "uniprot": "A4IBT4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBT4"
  },
  {
    "uniprot": "A4I1D3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1D3"
  },
  {
    "uniprot": "A4HTE5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTE5"
  },
  {
    "uniprot": "A4HSB3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSB3"
  },
  {
    "uniprot": "A4I1B2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1B2"
  },
  {
    "uniprot": "A4I6J9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6J9"
  },
  {
    "uniprot": "A4I841",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I841"
  },
  {
    "uniprot": "A4IAJ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAJ7"
  },
  {
    "uniprot": "A4IAW3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAW3"
  },
  {
    "uniprot": "A4IBE0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBE0"
  },
  {
    "uniprot": "A4HVW6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVW6"
  },
  {
    "uniprot": "A4HUM2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUM2"
  },
  {
    "uniprot": "A4I1Y3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Y3"
  },
  {
    "uniprot": "A0A381MQ29",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MQ29"
  },
  {
    "uniprot": "E9AH82",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH82"
  },
  {
    "uniprot": "A4I6S2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6S2"
  },
  {
    "uniprot": "A4I0U9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0U9"
  },
  {
    "uniprot": "A4HT25",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT25"
  },
  {
    "uniprot": "A4HX68",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX68"
  },
  {
    "uniprot": "A4I1P9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1P9"
  },
  {
    "uniprot": "A4IDG8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDG8"
  },
  {
    "uniprot": "A4HZS9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZS9"
  },
  {
    "uniprot": "A4HYA4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYA4"
  },
  {
    "uniprot": "A4I4E7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4E7"
  },
  {
    "uniprot": "A4HRX5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRX5"
  },
  {
    "uniprot": "A4IAT7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAT7"
  },
  {
    "uniprot": "A4HWG2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWG2"
  },
  {
    "uniprot": "A4I815",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I815"
  },
  {
    "uniprot": "A4IBG9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBG9"
  },
  {
    "uniprot": "A4I147",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I147"
  },
  {
    "uniprot": "A4I293",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I293"
  },
  {
    "uniprot": "A4I917",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I917"
  },
  {
    "uniprot": "E9AHJ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHJ1"
  },
  {
    "uniprot": "A4ID83",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID83"
  },
  {
    "uniprot": "A4HS57",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS57"
  },
  {
    "uniprot": "A4HZ00",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ00"
  },
  {
    "uniprot": "A4I9F8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9F8"
  },
  {
    "uniprot": "A4HRX8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRX8"
  },
  {
    "uniprot": "A4HVJ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVJ7"
  },
  {
    "uniprot": "A4I045",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I045"
  },
  {
    "uniprot": "A4I085",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I085"
  },
  {
    "uniprot": "A4HY48",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY48"
  },
  {
    "uniprot": "A4I2U4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2U4"
  },
  {
    "uniprot": "A4I617",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I617"
  },
  {
    "uniprot": "A4IDX1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDX1"
  },
  {
    "uniprot": "A4I1Y9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Y9"
  },
  {
    "uniprot": "A4HZT1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZT1"
  },
  {
    "uniprot": "A4I8Y8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Y8"
  },
  {
    "uniprot": "A4I7G6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7G6"
  },
  {
    "uniprot": "A4HVB0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVB0"
  },
  {
    "uniprot": "A4I016",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I016"
  },
  {
    "uniprot": "A4I2Q8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Q8"
  },
  {
    "uniprot": "A4I7S1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7S1"
  },
  {
    "uniprot": "A4HSL6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSL6"
  },
  {
    "uniprot": "A4I6H3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6H3"
  },
  {
    "uniprot": "A4I4P7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4P7"
  },
  {
    "uniprot": "A4I239",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I239"
  },
  {
    "uniprot": "A4I893",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I893"
  },
  {
    "uniprot": "A4I1L8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1L8"
  },
  {
    "uniprot": "A4HTU1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTU1"
  },
  {
    "uniprot": "A4HXL9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXL9"
  },
  {
    "uniprot": "A4I705",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I705"
  },
  {
    "uniprot": "A4I1N5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1N5"
  },
  {
    "uniprot": "A4I9G3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9G3"
  },
  {
    "uniprot": "A4I9N2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9N2"
  },
  {
    "uniprot": "A4I170",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I170"
  },
  {
    "uniprot": "A4IBT0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBT0"
  },
  {
    "uniprot": "A4IBL8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBL8"
  },
  {
    "uniprot": "A4IBC6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBC6"
  },
  {
    "uniprot": "A4I569",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I569"
  },
  {
    "uniprot": "A4I5B6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5B6"
  },
  {
    "uniprot": "A4ICD9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICD9"
  },
  {
    "uniprot": "E9AHN8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHN8"
  },
  {
    "uniprot": "A4I5U7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5U7"
  },
  {
    "uniprot": "A4IDY1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDY1"
  },
  {
    "uniprot": "A4I257",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I257"
  },
  {
    "uniprot": "A4HXH2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXH2"
  },
  {
    "uniprot": "A4IDL9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDL9"
  },
  {
    "uniprot": "A4HS15",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS15"
  },
  {
    "uniprot": "A4IDQ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDQ1"
  },
  {
    "uniprot": "A4IDG0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDG0"
  },
  {
    "uniprot": "A4I9L9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9L9"
  },
  {
    "uniprot": "A4HZG4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZG4"
  },
  {
    "uniprot": "A4I799",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I799"
  },
  {
    "uniprot": "A4HTA7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTA7"
  },
  {
    "uniprot": "A4HRH5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRH5"
  },
  {
    "uniprot": "E9AGM9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGM9"
  },
  {
    "uniprot": "A4HY95",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY95"
  },
  {
    "uniprot": "A4IAJ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAJ0"
  },
  {
    "uniprot": "A4I3N3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3N3"
  },
  {
    "uniprot": "A4HY26",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY26"
  },
  {
    "uniprot": "A4I522",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I522"
  },
  {
    "uniprot": "E9AHG3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHG3"
  },
  {
    "uniprot": "A4HS94",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS94"
  },
  {
    "uniprot": "A4I5P7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5P7"
  },
  {
    "uniprot": "A4HXY3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXY3"
  },
  {
    "uniprot": "A4HXW5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXW5"
  },
  {
    "uniprot": "A4I3L0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3L0"
  },
  {
    "uniprot": "A4I7S9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7S9"
  },
  {
    "uniprot": "A4I0W5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0W5"
  },
  {
    "uniprot": "A4I4P5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4P5"
  },
  {
    "uniprot": "A4HYN6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYN6"
  },
  {
    "uniprot": "A4I2H8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2H8"
  },
  {
    "uniprot": "A4IDH9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDH9"
  },
  {
    "uniprot": "A4HUD9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUD9"
  },
  {
    "uniprot": "A4IAL9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAL9"
  },
  {
    "uniprot": "A4I2M7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2M7"
  },
  {
    "uniprot": "A4I6Z2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Z2"
  },
  {
    "uniprot": "A4I0C0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0C0"
  },
  {
    "uniprot": "A4I5S2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5S2"
  },
  {
    "uniprot": "A4IB53",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB53"
  },
  {
    "uniprot": "A4HWU3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWU3"
  },
  {
    "uniprot": "A4I4L0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4L0"
  },
  {
    "uniprot": "A4I2R3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2R3"
  },
  {
    "uniprot": "A4I7Q6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Q6"
  },
  {
    "uniprot": "A4I6V8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6V8"
  },
  {
    "uniprot": "A4I1W3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1W3"
  },
  {
    "uniprot": "A4I0H9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0H9"
  },
  {
    "uniprot": "A4IBD6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBD6"
  },
  {
    "uniprot": "A4HU32",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU32"
  },
  {
    "uniprot": "A4I1H0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1H0"
  },
  {
    "uniprot": "A4I6N6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6N6"
  },
  {
    "uniprot": "A4I229",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I229"
  },
  {
    "uniprot": "A4IB77",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB77"
  },
  {
    "uniprot": "E9AGI2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGI2"
  },
  {
    "uniprot": "A4IDN2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDN2"
  },
  {
    "uniprot": "A4HYJ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYJ0"
  },
  {
    "uniprot": "A4HVW7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVW7"
  },
  {
    "uniprot": "A4I5G9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5G9"
  },
  {
    "uniprot": "A4HZ72",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ72"
  },
  {
    "uniprot": "A4HZT2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZT2"
  },
  {
    "uniprot": "A4I303",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I303"
  },
  {
    "uniprot": "A4IAH9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAH9"
  },
  {
    "uniprot": "E9AH11",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH11"
  },
  {
    "uniprot": "A4ID47",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID47"
  },
  {
    "uniprot": "A4I4Y7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Y7"
  },
  {
    "uniprot": "A4ID53",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID53"
  },
  {
    "uniprot": "A4I070",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I070"
  },
  {
    "uniprot": "A4HV06",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV06"
  },
  {
    "uniprot": "A4I7L1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7L1"
  },
  {
    "uniprot": "A4I2T1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2T1"
  },
  {
    "uniprot": "A4I051",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I051"
  },
  {
    "uniprot": "A4I2Z6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Z6"
  },
  {
    "uniprot": "E9AGV2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGV2"
  },
  {
    "uniprot": "A4I0Y4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Y4"
  },
  {
    "uniprot": "A4I152",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I152"
  },
  {
    "uniprot": "A4HUM6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUM6"
  },
  {
    "uniprot": "A4I753",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I753"
  },
  {
    "uniprot": "A4IBS1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBS1"
  },
  {
    "uniprot": "A4HT12",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT12"
  },
  {
    "uniprot": "A4HVG1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVG1"
  },
  {
    "uniprot": "A4ICU7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICU7"
  },
  {
    "uniprot": "A4HS47",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS47"
  },
  {
    "uniprot": "A4I1Z7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Z7"
  },
  {
    "uniprot": "Q2PYN0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q2PYN0"
  },
  {
    "uniprot": "A4HSE7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSE7"
  },
  {
    "uniprot": "A4HT62",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT62"
  },
  {
    "uniprot": "A4IBL7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBL7"
  },
  {
    "uniprot": "A4IBQ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBQ3"
  },
  {
    "uniprot": "A4I9I2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9I2"
  },
  {
    "uniprot": "E9AH72",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH72"
  },
  {
    "uniprot": "E9AGQ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGQ5"
  },
  {
    "uniprot": "A4I6N3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6N3"
  },
  {
    "uniprot": "A4I5R0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5R0"
  },
  {
    "uniprot": "A4I6V9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6V9"
  },
  {
    "uniprot": "A4HSY8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSY8"
  },
  {
    "uniprot": "A4I2U9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2U9"
  },
  {
    "uniprot": "A4HTD3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTD3"
  },
  {
    "uniprot": "A4HZK8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZK8"
  },
  {
    "uniprot": "A4HUW2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUW2"
  },
  {
    "uniprot": "A4IDE5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDE5"
  },
  {
    "uniprot": "A4I2D4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2D4"
  },
  {
    "uniprot": "A4I2J8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2J8"
  },
  {
    "uniprot": "A4I146",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I146"
  },
  {
    "uniprot": "A4I2L9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2L9"
  },
  {
    "uniprot": "A4I3I6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3I6"
  },
  {
    "uniprot": "A4HYX1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYX1"
  },
  {
    "uniprot": "A4I7Y5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Y5"
  },
  {
    "uniprot": "A4HZC0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZC0"
  },
  {
    "uniprot": "A4IC92",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC92"
  },
  {
    "uniprot": "A0A381MFS8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MFS8"
  },
  {
    "uniprot": "E9AGW8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGW8"
  },
  {
    "uniprot": "A4ICH2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICH2"
  },
  {
    "uniprot": "A4HUM0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUM0"
  },
  {
    "uniprot": "A4I9X0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9X0"
  },
  {
    "uniprot": "A4ICU8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICU8"
  },
  {
    "uniprot": "A4HZS7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZS7"
  },
  {
    "uniprot": "A4HWK1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWK1"
  },
  {
    "uniprot": "A4ICL2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICL2"
  },
  {
    "uniprot": "A4HUT2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUT2"
  },
  {
    "uniprot": "A4I0U3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0U3"
  },
  {
    "uniprot": "A4HS05",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS05"
  },
  {
    "uniprot": "A4I492",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I492"
  },
  {
    "uniprot": "A4HRJ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRJ9"
  },
  {
    "uniprot": "A4I936",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I936"
  },
  {
    "uniprot": "A4IBE5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBE5"
  },
  {
    "uniprot": "A4I5B5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5B5"
  },
  {
    "uniprot": "A4HZ36",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ36"
  },
  {
    "uniprot": "A4HRM4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRM4"
  },
  {
    "uniprot": "A4I0Y0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Y0"
  },
  {
    "uniprot": "A4HYK9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYK9"
  },
  {
    "uniprot": "A4HVY4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVY4"
  },
  {
    "uniprot": "A4I360",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I360"
  },
  {
    "uniprot": "A4HY66",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY66"
  },
  {
    "uniprot": "A4I5U9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5U9"
  },
  {
    "uniprot": "A4HTT6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTT6"
  },
  {
    "uniprot": "A4I817",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I817"
  },
  {
    "uniprot": "A4IAS6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAS6"
  },
  {
    "uniprot": "A4IB48",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB48"
  },
  {
    "uniprot": "A4I6L7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6L7"
  },
  {
    "uniprot": "A4HSE5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSE5"
  },
  {
    "uniprot": "A4I269",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I269"
  },
  {
    "uniprot": "A4HZ52",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ52"
  },
  {
    "uniprot": "E9AGT6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGT6"
  },
  {
    "uniprot": "A4I5G5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5G5"
  },
  {
    "uniprot": "A4I0Q5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Q5"
  },
  {
    "uniprot": "A4I419",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I419"
  },
  {
    "uniprot": "A4HUM3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUM3"
  },
  {
    "uniprot": "A4IA28",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA28"
  },
  {
    "uniprot": "A4HUB1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUB1"
  },
  {
    "uniprot": "A4IDY0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDY0"
  },
  {
    "uniprot": "A4HTY7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTY7"
  },
  {
    "uniprot": "E9AG72",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG72"
  },
  {
    "uniprot": "A4HSC5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSC5"
  },
  {
    "uniprot": "A4I6N9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6N9"
  },
  {
    "uniprot": "A4I9H1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9H1"
  },
  {
    "uniprot": "A4HU26",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU26"
  },
  {
    "uniprot": "A4IDJ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDJ1"
  },
  {
    "uniprot": "A4I6F8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6F8"
  },
  {
    "uniprot": "A4I8U3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8U3"
  },
  {
    "uniprot": "A4I1Z1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Z1"
  },
  {
    "uniprot": "A4I5C6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5C6"
  },
  {
    "uniprot": "A4I6Q3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Q3"
  },
  {
    "uniprot": "A4HRP4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRP4"
  },
  {
    "uniprot": "A4IDW6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDW6"
  },
  {
    "uniprot": "A4I6T6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6T6"
  },
  {
    "uniprot": "A4HZM2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZM2"
  },
  {
    "uniprot": "A4I1I2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1I2"
  },
  {
    "uniprot": "A4HWD0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWD0"
  },
  {
    "uniprot": "A4I8D3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8D3"
  },
  {
    "uniprot": "A4I9H3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9H3"
  },
  {
    "uniprot": "E9AGH5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGH5"
  },
  {
    "uniprot": "A4HVI1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVI1"
  },
  {
    "uniprot": "A4ICS6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICS6"
  },
  {
    "uniprot": "A4HX54",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX54"
  },
  {
    "uniprot": "E9AHQ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHQ0"
  },
  {
    "uniprot": "A4I5S6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5S6"
  },
  {
    "uniprot": "A4I746",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I746"
  },
  {
    "uniprot": "A4I2E3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2E3"
  },
  {
    "uniprot": "A4I159",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I159"
  },
  {
    "uniprot": "E9AGR6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGR6"
  },
  {
    "uniprot": "A4I4X1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4X1"
  },
  {
    "uniprot": "A4IA95",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA95"
  },
  {
    "uniprot": "A4I0B0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0B0"
  },
  {
    "uniprot": "E9AGX2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGX2"
  },
  {
    "uniprot": "A4IAW5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAW5"
  },
  {
    "uniprot": "A4I415",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I415"
  },
  {
    "uniprot": "A4I3L5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3L5"
  },
  {
    "uniprot": "A4HWR7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWR7"
  },
  {
    "uniprot": "A4ICU3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICU3"
  },
  {
    "uniprot": "A4IC25",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC25"
  },
  {
    "uniprot": "A4I4H8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4H8"
  },
  {
    "uniprot": "A4I789",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I789"
  },
  {
    "uniprot": "E9AG40",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG40"
  },
  {
    "uniprot": "A4IAB9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAB9"
  },
  {
    "uniprot": "A4HXJ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXJ2"
  },
  {
    "uniprot": "A4I0Y5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Y5"
  },
  {
    "uniprot": "A4I7K2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7K2"
  },
  {
    "uniprot": "A4I119",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I119"
  },
  {
    "uniprot": "A4HS50",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS50"
  },
  {
    "uniprot": "A4I3N9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3N9"
  },
  {
    "uniprot": "A4IDV9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDV9"
  },
  {
    "uniprot": "A4IA31",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA31"
  },
  {
    "uniprot": "A4I050",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I050"
  },
  {
    "uniprot": "A4I0W6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0W6"
  },
  {
    "uniprot": "A4HVG3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVG3"
  },
  {
    "uniprot": "A4IAI3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAI3"
  },
  {
    "uniprot": "A4IA04",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA04"
  },
  {
    "uniprot": "E8NHC3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E8NHC3"
  },
  {
    "uniprot": "A4HRU3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRU3"
  },
  {
    "uniprot": "A4IB09",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB09"
  },
  {
    "uniprot": "A4HWK3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWK3"
  },
  {
    "uniprot": "E8NHD3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E8NHD3"
  },
  {
    "uniprot": "A4I9A7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9A7"
  },
  {
    "uniprot": "A4ICG1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICG1"
  },
  {
    "uniprot": "A4I8H1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8H1"
  },
  {
    "uniprot": "A4I9W8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9W8"
  },
  {
    "uniprot": "Q25302",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q25302"
  },
  {
    "uniprot": "A4IBT5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBT5"
  },
  {
    "uniprot": "A4HVJ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVJ2"
  },
  {
    "uniprot": "A4I7G7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7G7"
  },
  {
    "uniprot": "E9AHY0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHY0"
  },
  {
    "uniprot": "A4I4B3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4B3"
  },
  {
    "uniprot": "A4I0B1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0B1"
  },
  {
    "uniprot": "E9AGX4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGX4"
  },
  {
    "uniprot": "A4I5V3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5V3"
  },
  {
    "uniprot": "A4I4I8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4I8"
  },
  {
    "uniprot": "A4I7U6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7U6"
  },
  {
    "uniprot": "A4IC77",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC77"
  },
  {
    "uniprot": "A4HU01",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU01"
  },
  {
    "uniprot": "E9AHI7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHI7"
  },
  {
    "uniprot": "A4I2X9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2X9"
  },
  {
    "uniprot": "A4IB05",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB05"
  },
  {
    "uniprot": "A4HZU0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZU0"
  },
  {
    "uniprot": "A4HZW3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZW3"
  },
  {
    "uniprot": "A4I6K5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6K5"
  },
  {
    "uniprot": "A4HTB9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTB9"
  },
  {
    "uniprot": "E9AGZ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGZ5"
  },
  {
    "uniprot": "A4IC98",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC98"
  },
  {
    "uniprot": "A4I2A7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2A7"
  },
  {
    "uniprot": "A4I0C3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0C3"
  },
  {
    "uniprot": "A4HS84",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS84"
  },
  {
    "uniprot": "A4HTX2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTX2"
  },
  {
    "uniprot": "A4I2M0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2M0"
  },
  {
    "uniprot": "A4HS19",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS19"
  },
  {
    "uniprot": "A4I5K5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5K5"
  },
  {
    "uniprot": "A4HXP0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXP0"
  },
  {
    "uniprot": "A4HRU2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRU2"
  },
  {
    "uniprot": "A4I1U1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1U1"
  },
  {
    "uniprot": "A4I0M1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0M1"
  },
  {
    "uniprot": "E9AHR6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHR6"
  },
  {
    "uniprot": "A4I8S4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8S4"
  },
  {
    "uniprot": "A4IBV8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBV8"
  },
  {
    "uniprot": "E9AGU5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGU5"
  },
  {
    "uniprot": "A4IDE4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDE4"
  },
  {
    "uniprot": "A4IAE5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAE5"
  },
  {
    "uniprot": "A4I864",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I864"
  },
  {
    "uniprot": "A4I4W8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4W8"
  },
  {
    "uniprot": "A4I7X7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7X7"
  },
  {
    "uniprot": "A4I445",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I445"
  },
  {
    "uniprot": "A4HYI0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYI0"
  },
  {
    "uniprot": "E9AH21",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH21"
  },
  {
    "uniprot": "E9AHW9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHW9"
  },
  {
    "uniprot": "A4I9E2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9E2"
  },
  {
    "uniprot": "A4ICD2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICD2"
  },
  {
    "uniprot": "E9AH74",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH74"
  },
  {
    "uniprot": "A4IB52",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB52"
  },
  {
    "uniprot": "A4HZ11",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ11"
  },
  {
    "uniprot": "E9AHH9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHH9"
  },
  {
    "uniprot": "A4I0T5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0T5"
  },
  {
    "uniprot": "A4I1K5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1K5"
  },
  {
    "uniprot": "A4I4Z2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Z2"
  },
  {
    "uniprot": "A4IAZ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAZ3"
  },
  {
    "uniprot": "A4IAN6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAN6"
  },
  {
    "uniprot": "A4HYI4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYI4"
  },
  {
    "uniprot": "A4HX04",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX04"
  },
  {
    "uniprot": "A4HRS6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRS6"
  },
  {
    "uniprot": "A4ID59",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID59"
  },
  {
    "uniprot": "A4I9A4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9A4"
  },
  {
    "uniprot": "A4HX14",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX14"
  },
  {
    "uniprot": "A4HU09",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU09"
  },
  {
    "uniprot": "E9AH43",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH43"
  },
  {
    "uniprot": "A4I4G8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4G8"
  },
  {
    "uniprot": "A4I9U2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9U2"
  },
  {
    "uniprot": "A4IAR1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAR1"
  },
  {
    "uniprot": "A4HYX9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYX9"
  },
  {
    "uniprot": "A4HRR7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRR7"
  },
  {
    "uniprot": "A4HVF7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVF7"
  },
  {
    "uniprot": "A4I1R6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1R6"
  },
  {
    "uniprot": "A4I3I7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3I7"
  },
  {
    "uniprot": "A4HWM2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWM2"
  },
  {
    "uniprot": "A4HRZ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRZ8"
  },
  {
    "uniprot": "A4I735",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I735"
  },
  {
    "uniprot": "A4I011",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I011"
  },
  {
    "uniprot": "E9AHN6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHN6"
  },
  {
    "uniprot": "A4IAQ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAQ0"
  },
  {
    "uniprot": "A4HWM4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWM4"
  },
  {
    "uniprot": "A4HZU7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZU7"
  },
  {
    "uniprot": "A4I3D0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3D0"
  },
  {
    "uniprot": "A4I2E0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2E0"
  },
  {
    "uniprot": "A4HXH4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXH4"
  },
  {
    "uniprot": "A4I7U2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7U2"
  },
  {
    "uniprot": "A4HUP7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUP7"
  },
  {
    "uniprot": "A4HWV0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWV0"
  },
  {
    "uniprot": "A4HXU4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXU4"
  },
  {
    "uniprot": "A4HY06",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY06"
  },
  {
    "uniprot": "A4HUD1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUD1"
  },
  {
    "uniprot": "A4I989",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I989"
  },
  {
    "uniprot": "A4I9L0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9L0"
  },
  {
    "uniprot": "A4I3V7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3V7"
  },
  {
    "uniprot": "A4I9F2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9F2"
  },
  {
    "uniprot": "A4I1I3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1I3"
  },
  {
    "uniprot": "E9AHR4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHR4"
  },
  {
    "uniprot": "A4HZF7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZF7"
  },
  {
    "uniprot": "A4ICQ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICQ0"
  },
  {
    "uniprot": "A4HVU9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVU9"
  },
  {
    "uniprot": "A4HWY2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWY2"
  },
  {
    "uniprot": "A4IDA2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDA2"
  },
  {
    "uniprot": "A4IBV9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBV9"
  },
  {
    "uniprot": "A4I9Y3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Y3"
  },
  {
    "uniprot": "A4I9P1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9P1"
  },
  {
    "uniprot": "A4HVQ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVQ4"
  },
  {
    "uniprot": "E9AHP0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHP0"
  },
  {
    "uniprot": "A4I5A9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5A9"
  },
  {
    "uniprot": "A4I004",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I004"
  },
  {
    "uniprot": "A4I9T0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9T0"
  },
  {
    "uniprot": "A4I711",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I711"
  },
  {
    "uniprot": "A4I998",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I998"
  },
  {
    "uniprot": "A4I2D0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2D0"
  },
  {
    "uniprot": "A4I1A5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1A5"
  },
  {
    "uniprot": "E9AGC6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGC6"
  },
  {
    "uniprot": "A4I2R7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2R7"
  },
  {
    "uniprot": "A4I9Q8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Q8"
  },
  {
    "uniprot": "A4HW64",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW64"
  },
  {
    "uniprot": "A4HZX5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZX5"
  },
  {
    "uniprot": "A4HZR5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZR5"
  },
  {
    "uniprot": "A4HRJ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRJ6"
  },
  {
    "uniprot": "A4IBP6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBP6"
  },
  {
    "uniprot": "E9AHA2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHA2"
  },
  {
    "uniprot": "A4IA76",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA76"
  },
  {
    "uniprot": "A4HZS5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZS5"
  },
  {
    "uniprot": "A4IA82",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA82"
  },
  {
    "uniprot": "A4HX73",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX73"
  },
  {
    "uniprot": "A4I270",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I270"
  },
  {
    "uniprot": "A4HSC0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSC0"
  },
  {
    "uniprot": "A4I7M8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7M8"
  },
  {
    "uniprot": "A4I6R8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6R8"
  },
  {
    "uniprot": "A4HUF3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUF3"
  },
  {
    "uniprot": "A4I476",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I476"
  },
  {
    "uniprot": "A4I0H6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0H6"
  },
  {
    "uniprot": "A4HVZ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVZ0"
  },
  {
    "uniprot": "A4I6M7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6M7"
  },
  {
    "uniprot": "A4I8V4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8V4"
  },
  {
    "uniprot": "A4IDR9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDR9"
  },
  {
    "uniprot": "A4HS09",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS09"
  },
  {
    "uniprot": "A4HYM5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYM5"
  },
  {
    "uniprot": "A4HSS6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSS6"
  },
  {
    "uniprot": "A4I499",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I499"
  },
  {
    "uniprot": "A4I3L7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3L7"
  },
  {
    "uniprot": "A4HVX3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVX3"
  },
  {
    "uniprot": "A4HY32",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY32"
  },
  {
    "uniprot": "A4I575",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I575"
  },
  {
    "uniprot": "A4ID57",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID57"
  },
  {
    "uniprot": "A4I4Y4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Y4"
  },
  {
    "uniprot": "A4ICK2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICK2"
  },
  {
    "uniprot": "A4IAI0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAI0"
  },
  {
    "uniprot": "A4I205",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I205"
  },
  {
    "uniprot": "A4HY90",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY90"
  },
  {
    "uniprot": "A4HVZ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVZ3"
  },
  {
    "uniprot": "A4HTH7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTH7"
  },
  {
    "uniprot": "A4I3S8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3S8"
  },
  {
    "uniprot": "A4I0T6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0T6"
  },
  {
    "uniprot": "A4HY77",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY77"
  },
  {
    "uniprot": "A4I4S3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4S3"
  },
  {
    "uniprot": "A4IDH3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDH3"
  },
  {
    "uniprot": "A4IAJ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAJ1"
  },
  {
    "uniprot": "A4HU25",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU25"
  },
  {
    "uniprot": "A4I8N4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8N4"
  },
  {
    "uniprot": "A4IC95",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC95"
  },
  {
    "uniprot": "A4IA33",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA33"
  },
  {
    "uniprot": "A4I1G0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1G0"
  },
  {
    "uniprot": "A4HZM8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZM8"
  },
  {
    "uniprot": "A4I3F0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3F0"
  },
  {
    "uniprot": "A4I0N3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0N3"
  },
  {
    "uniprot": "E9AG67",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG67"
  },
  {
    "uniprot": "A4I947",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I947"
  },
  {
    "uniprot": "E9AH50",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH50"
  },
  {
    "uniprot": "A4I1C2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1C2"
  },
  {
    "uniprot": "E9AHF1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHF1"
  },
  {
    "uniprot": "A4I5Y0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Y0"
  },
  {
    "uniprot": "A4IDN1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDN1"
  },
  {
    "uniprot": "A4HTC2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTC2"
  },
  {
    "uniprot": "A4HSE2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSE2"
  },
  {
    "uniprot": "A4I1S3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1S3"
  },
  {
    "uniprot": "A4HZI1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZI1"
  },
  {
    "uniprot": "A4I825",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I825"
  },
  {
    "uniprot": "A4HTA6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTA6"
  },
  {
    "uniprot": "A4HYI6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYI6"
  },
  {
    "uniprot": "A4ICT9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICT9"
  },
  {
    "uniprot": "A4HVN5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVN5"
  },
  {
    "uniprot": "A4HVI2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVI2"
  },
  {
    "uniprot": "A4IBU4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBU4"
  },
  {
    "uniprot": "A4HX72",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX72"
  },
  {
    "uniprot": "A4IA45",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA45"
  },
  {
    "uniprot": "A4ID71",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID71"
  },
  {
    "uniprot": "A4HS39",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS39"
  },
  {
    "uniprot": "A4ICK6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICK6"
  },
  {
    "uniprot": "A4IDA6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDA6"
  },
  {
    "uniprot": "A4HRL6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRL6"
  },
  {
    "uniprot": "A4I2J9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2J9"
  },
  {
    "uniprot": "A4HYA1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYA1"
  },
  {
    "uniprot": "A4I7Q2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Q2"
  },
  {
    "uniprot": "A4HSZ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSZ1"
  },
  {
    "uniprot": "A4HX95",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX95"
  },
  {
    "uniprot": "A4I827",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I827"
  },
  {
    "uniprot": "A4HVR0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVR0"
  },
  {
    "uniprot": "A4HT22",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT22"
  },
  {
    "uniprot": "A4HX46",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX46"
  },
  {
    "uniprot": "A4HTP2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTP2"
  },
  {
    "uniprot": "A4HS17",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS17"
  },
  {
    "uniprot": "A4HUK2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUK2"
  },
  {
    "uniprot": "A4IE11",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE11"
  },
  {
    "uniprot": "A4I5H0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5H0"
  },
  {
    "uniprot": "A4IB84",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB84"
  },
  {
    "uniprot": "A4I937",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I937"
  },
  {
    "uniprot": "Q2PDC5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q2PDC5"
  },
  {
    "uniprot": "A4I3U7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3U7"
  },
  {
    "uniprot": "A4IDB1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDB1"
  },
  {
    "uniprot": "A4IAD1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAD1"
  },
  {
    "uniprot": "A4I9L7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9L7"
  },
  {
    "uniprot": "A4ID54",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID54"
  },
  {
    "uniprot": "A4HXG3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXG3"
  },
  {
    "uniprot": "A4HYY6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYY6"
  },
  {
    "uniprot": "A4I403",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I403"
  },
  {
    "uniprot": "Q9GP00",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q9GP00"
  },
  {
    "uniprot": "A4I0N6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0N6"
  },
  {
    "uniprot": "A4I1G4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1G4"
  },
  {
    "uniprot": "A4HWV7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWV7"
  },
  {
    "uniprot": "A4IA32",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA32"
  },
  {
    "uniprot": "A4I7U3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7U3"
  },
  {
    "uniprot": "A4I3Y5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Y5"
  },
  {
    "uniprot": "A4I3B8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3B8"
  },
  {
    "uniprot": "A4I7L6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7L6"
  },
  {
    "uniprot": "A4IDS7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDS7"
  },
  {
    "uniprot": "A4IDB9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDB9"
  },
  {
    "uniprot": "A4I9G2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9G2"
  },
  {
    "uniprot": "A4HXC7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXC7"
  },
  {
    "uniprot": "A4IDS4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDS4"
  },
  {
    "uniprot": "A4HZV0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZV0"
  },
  {
    "uniprot": "A4HWA1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWA1"
  },
  {
    "uniprot": "A4I7J2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7J2"
  },
  {
    "uniprot": "A4HVU3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVU3"
  },
  {
    "uniprot": "A4HVQ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVQ1"
  },
  {
    "uniprot": "A4HUL9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUL9"
  },
  {
    "uniprot": "A4HUW0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUW0"
  },
  {
    "uniprot": "E9AHS7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHS7"
  },
  {
    "uniprot": "A4I4V3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4V3"
  },
  {
    "uniprot": "A4IDB0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDB0"
  },
  {
    "uniprot": "A4I2A2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2A2"
  },
  {
    "uniprot": "A4IBK0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBK0"
  },
  {
    "uniprot": "A4HT13",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT13"
  },
  {
    "uniprot": "A4I9V9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9V9"
  },
  {
    "uniprot": "A4I872",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I872"
  },
  {
    "uniprot": "A4I401",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I401"
  },
  {
    "uniprot": "A4I2D8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2D8"
  },
  {
    "uniprot": "A4IAR6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAR6"
  },
  {
    "uniprot": "A4I3F7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3F7"
  },
  {
    "uniprot": "A4IC39",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC39"
  },
  {
    "uniprot": "A4HY05",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY05"
  },
  {
    "uniprot": "A4HW79",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW79"
  },
  {
    "uniprot": "A4I288",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I288"
  },
  {
    "uniprot": "A4I290",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I290"
  },
  {
    "uniprot": "A4I747",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I747"
  },
  {
    "uniprot": "E9AGS8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGS8"
  },
  {
    "uniprot": "E9AHP2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHP2"
  },
  {
    "uniprot": "A4HT57",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT57"
  },
  {
    "uniprot": "A4I8U6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8U6"
  },
  {
    "uniprot": "A4HS79",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS79"
  },
  {
    "uniprot": "A4I381",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I381"
  },
  {
    "uniprot": "A4HZD1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZD1"
  },
  {
    "uniprot": "A4HSH7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSH7"
  },
  {
    "uniprot": "A4IBY3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBY3"
  },
  {
    "uniprot": "A4I1V0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1V0"
  },
  {
    "uniprot": "A4HUI5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUI5"
  },
  {
    "uniprot": "A4HXN9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXN9"
  },
  {
    "uniprot": "A4I9W2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9W2"
  },
  {
    "uniprot": "A4HXH3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXH3"
  },
  {
    "uniprot": "A4I1L4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1L4"
  },
  {
    "uniprot": "E9AHP7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHP7"
  },
  {
    "uniprot": "A4I325",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I325"
  },
  {
    "uniprot": "E8NHC7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E8NHC7"
  },
  {
    "uniprot": "A4HZI4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZI4"
  },
  {
    "uniprot": "A4HTU9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTU9"
  },
  {
    "uniprot": "E9AGF0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGF0"
  },
  {
    "uniprot": "A4ID05",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID05"
  },
  {
    "uniprot": "A4I5X0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5X0"
  },
  {
    "uniprot": "A4I832",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I832"
  },
  {
    "uniprot": "A4HRK3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRK3"
  },
  {
    "uniprot": "A4IB92",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB92"
  },
  {
    "uniprot": "E9AGG7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGG7"
  },
  {
    "uniprot": "A4HZ47",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ47"
  },
  {
    "uniprot": "A4ICD5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICD5"
  },
  {
    "uniprot": "E9AGI3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGI3"
  },
  {
    "uniprot": "E9AHL8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHL8"
  },
  {
    "uniprot": "A4I4U2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4U2"
  },
  {
    "uniprot": "A4HWX8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWX8"
  },
  {
    "uniprot": "A4HV09",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV09"
  },
  {
    "uniprot": "A4HYL8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYL8"
  },
  {
    "uniprot": "A4ICZ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICZ9"
  },
  {
    "uniprot": "E9AGU1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGU1"
  },
  {
    "uniprot": "A4HXA1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXA1"
  },
  {
    "uniprot": "A4I256",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I256"
  },
  {
    "uniprot": "A4I3J7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3J7"
  },
  {
    "uniprot": "A4HX48",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX48"
  },
  {
    "uniprot": "A4I781",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I781"
  },
  {
    "uniprot": "A0A381MTN2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MTN2"
  },
  {
    "uniprot": "A4HSI1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSI1"
  },
  {
    "uniprot": "A4I6Q6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Q6"
  },
  {
    "uniprot": "A4I0Q2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Q2"
  },
  {
    "uniprot": "A4I5U4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5U4"
  },
  {
    "uniprot": "A4I828",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I828"
  },
  {
    "uniprot": "A4HYH0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYH0"
  },
  {
    "uniprot": "A4HUS1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUS1"
  },
  {
    "uniprot": "A4IA80",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA80"
  },
  {
    "uniprot": "A4I0V7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0V7"
  },
  {
    "uniprot": "A4HZQ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZQ7"
  },
  {
    "uniprot": "E9AH41",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH41"
  },
  {
    "uniprot": "A0A381MAT2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MAT2"
  },
  {
    "uniprot": "A4I6R6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6R6"
  },
  {
    "uniprot": "A4HW61",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW61"
  },
  {
    "uniprot": "E9AHQ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHQ5"
  },
  {
    "uniprot": "A4ICV8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICV8"
  },
  {
    "uniprot": "A4HXS0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXS0"
  },
  {
    "uniprot": "A4I6B8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6B8"
  },
  {
    "uniprot": "E9AGR8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGR8"
  },
  {
    "uniprot": "A4HRM9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRM9"
  },
  {
    "uniprot": "E9AH73",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH73"
  },
  {
    "uniprot": "A4I2I2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2I2"
  },
  {
    "uniprot": "A4HXJ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXJ9"
  },
  {
    "uniprot": "A4I8X0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8X0"
  },
  {
    "uniprot": "E9AHS4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHS4"
  },
  {
    "uniprot": "A4HT11",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT11"
  },
  {
    "uniprot": "A4I8S9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8S9"
  },
  {
    "uniprot": "A4I3N4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3N4"
  },
  {
    "uniprot": "E9AGD4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGD4"
  },
  {
    "uniprot": "A4I4R9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4R9"
  },
  {
    "uniprot": "A4I0B9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0B9"
  },
  {
    "uniprot": "A4I848",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I848"
  },
  {
    "uniprot": "A4I3M8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3M8"
  },
  {
    "uniprot": "A4I473",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I473"
  },
  {
    "uniprot": "A4HY13",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY13"
  },
  {
    "uniprot": "A4I5Q6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Q6"
  },
  {
    "uniprot": "A4I985",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I985"
  },
  {
    "uniprot": "A4I5E0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5E0"
  },
  {
    "uniprot": "A4I7C7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7C7"
  },
  {
    "uniprot": "A4I4W4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4W4"
  },
  {
    "uniprot": "A4I7K5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7K5"
  },
  {
    "uniprot": "A4I0F9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0F9"
  },
  {
    "uniprot": "A4IE46",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE46"
  },
  {
    "uniprot": "A4IDV0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDV0"
  },
  {
    "uniprot": "A4HX71",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX71"
  },
  {
    "uniprot": "E9AI04",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AI04"
  },
  {
    "uniprot": "A4HZ49",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ49"
  },
  {
    "uniprot": "A4HZA7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZA7"
  },
  {
    "uniprot": "A4IDR7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDR7"
  },
  {
    "uniprot": "E9AH02",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH02"
  },
  {
    "uniprot": "A4IDZ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDZ0"
  },
  {
    "uniprot": "A4HWJ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWJ0"
  },
  {
    "uniprot": "A4I0P8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0P8"
  },
  {
    "uniprot": "A4HVI5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVI5"
  },
  {
    "uniprot": "A0A381MFR4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MFR4"
  },
  {
    "uniprot": "A4HRU7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRU7"
  },
  {
    "uniprot": "A4I091",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I091"
  },
  {
    "uniprot": "A4HYY4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYY4"
  },
  {
    "uniprot": "A4I8H2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8H2"
  },
  {
    "uniprot": "E9AH91",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH91"
  },
  {
    "uniprot": "A4I5C3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5C3"
  },
  {
    "uniprot": "A4IB55",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB55"
  },
  {
    "uniprot": "A4HTZ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTZ1"
  },
  {
    "uniprot": "A4I5M3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5M3"
  },
  {
    "uniprot": "A4I002",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I002"
  },
  {
    "uniprot": "A4I5L9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5L9"
  },
  {
    "uniprot": "A4I5U6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5U6"
  },
  {
    "uniprot": "A4HXV6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXV6"
  },
  {
    "uniprot": "A4HUB5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUB5"
  },
  {
    "uniprot": "A4I6J8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6J8"
  },
  {
    "uniprot": "A4I2H6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2H6"
  },
  {
    "uniprot": "A4HSQ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSQ3"
  },
  {
    "uniprot": "A4I182",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I182"
  },
  {
    "uniprot": "A4HUR7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUR7"
  },
  {
    "uniprot": "E9AGY9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGY9"
  },
  {
    "uniprot": "A4IBZ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBZ7"
  },
  {
    "uniprot": "A4I776",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I776"
  },
  {
    "uniprot": "A4I8K3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8K3"
  },
  {
    "uniprot": "A4I5T5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5T5"
  },
  {
    "uniprot": "A4HY42",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY42"
  },
  {
    "uniprot": "A0A381MKM9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MKM9"
  },
  {
    "uniprot": "A4HT94",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT94"
  },
  {
    "uniprot": "E9AH70",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH70"
  },
  {
    "uniprot": "A4I961",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I961"
  },
  {
    "uniprot": "A4I9S3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9S3"
  },
  {
    "uniprot": "A4HTR3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTR3"
  },
  {
    "uniprot": "A4IE49",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE49"
  },
  {
    "uniprot": "E9AHY4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHY4"
  },
  {
    "uniprot": "A4IB64",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB64"
  },
  {
    "uniprot": "A4HWN0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWN0"
  },
  {
    "uniprot": "A4HT35",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT35"
  },
  {
    "uniprot": "E9AI01",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AI01"
  },
  {
    "uniprot": "A4HXF0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXF0"
  },
  {
    "uniprot": "A4HUY2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUY2"
  },
  {
    "uniprot": "A4HW81",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW81"
  },
  {
    "uniprot": "A4I4R5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4R5"
  },
  {
    "uniprot": "A4ID36",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID36"
  },
  {
    "uniprot": "A4HX31",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX31"
  },
  {
    "uniprot": "A4I948",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I948"
  },
  {
    "uniprot": "A4I010",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I010"
  },
  {
    "uniprot": "A4I5Z5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Z5"
  },
  {
    "uniprot": "A4HTV1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTV1"
  },
  {
    "uniprot": "A4IAD6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAD6"
  },
  {
    "uniprot": "A4HT48",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT48"
  },
  {
    "uniprot": "A4I6C3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6C3"
  },
  {
    "uniprot": "A4ICH0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICH0"
  },
  {
    "uniprot": "A4HVQ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVQ2"
  },
  {
    "uniprot": "A4I7L3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7L3"
  },
  {
    "uniprot": "A4IDU2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDU2"
  },
  {
    "uniprot": "A4HY87",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY87"
  },
  {
    "uniprot": "A4IAW1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAW1"
  },
  {
    "uniprot": "A4I979",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I979"
  },
  {
    "uniprot": "A4I3X9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3X9"
  },
  {
    "uniprot": "A4I7L9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7L9"
  },
  {
    "uniprot": "A4HYM7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYM7"
  },
  {
    "uniprot": "A4I3D9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3D9"
  },
  {
    "uniprot": "E9AHH7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHH7"
  },
  {
    "uniprot": "A4I922",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I922"
  },
  {
    "uniprot": "A4I5W4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5W4"
  },
  {
    "uniprot": "A4IE07",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE07"
  },
  {
    "uniprot": "A4I6H0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6H0"
  },
  {
    "uniprot": "A4IBM2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBM2"
  },
  {
    "uniprot": "A4IC24",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC24"
  },
  {
    "uniprot": "A4HUK1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUK1"
  },
  {
    "uniprot": "A4HY52",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY52"
  },
  {
    "uniprot": "A4I1T7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1T7"
  },
  {
    "uniprot": "A4I582",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I582"
  },
  {
    "uniprot": "A4I851",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I851"
  },
  {
    "uniprot": "A4I784",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I784"
  },
  {
    "uniprot": "A4HTM7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTM7"
  },
  {
    "uniprot": "A4I696",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I696"
  },
  {
    "uniprot": "A4HVN4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVN4"
  },
  {
    "uniprot": "E9AHD8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHD8"
  },
  {
    "uniprot": "E9AG38",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG38"
  },
  {
    "uniprot": "A4IE40",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE40"
  },
  {
    "uniprot": "A4HUZ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUZ2"
  },
  {
    "uniprot": "A4I4G5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4G5"
  },
  {
    "uniprot": "A4I2A9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2A9"
  },
  {
    "uniprot": "A4I3Y3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Y3"
  },
  {
    "uniprot": "A0A381MVV1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MVV1"
  },
  {
    "uniprot": "A4HUU6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUU6"
  },
  {
    "uniprot": "A4HSZ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSZ0"
  },
  {
    "uniprot": "A4HZD8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZD8"
  },
  {
    "uniprot": "A4I5C1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5C1"
  },
  {
    "uniprot": "E9AGZ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGZ2"
  },
  {
    "uniprot": "A4HST9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HST9"
  },
  {
    "uniprot": "A4I0G8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0G8"
  },
  {
    "uniprot": "A4I2G3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2G3"
  },
  {
    "uniprot": "E9AGU8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGU8"
  },
  {
    "uniprot": "A4HRY2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRY2"
  },
  {
    "uniprot": "A4HXV3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXV3"
  },
  {
    "uniprot": "A4HUH4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUH4"
  },
  {
    "uniprot": "A4I6K9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6K9"
  },
  {
    "uniprot": "A4HRL8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRL8"
  },
  {
    "uniprot": "A4IAE1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAE1"
  },
  {
    "uniprot": "A4I6Y6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Y6"
  },
  {
    "uniprot": "A4HZ88",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ88"
  },
  {
    "uniprot": "A4I345",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I345"
  },
  {
    "uniprot": "A4I3P3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3P3"
  },
  {
    "uniprot": "A4I3V6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3V6"
  },
  {
    "uniprot": "A4I3M3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3M3"
  },
  {
    "uniprot": "A4I919",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I919"
  },
  {
    "uniprot": "A4HWH3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWH3"
  },
  {
    "uniprot": "A4I014",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I014"
  },
  {
    "uniprot": "A4I814",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I814"
  },
  {
    "uniprot": "A4HW09",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW09"
  },
  {
    "uniprot": "A4IBJ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBJ4"
  },
  {
    "uniprot": "A4HWQ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWQ3"
  },
  {
    "uniprot": "A4HXN7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXN7"
  },
  {
    "uniprot": "E9AGH1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGH1"
  },
  {
    "uniprot": "A4HTG9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTG9"
  },
  {
    "uniprot": "A4HWY8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWY8"
  },
  {
    "uniprot": "E9AGZ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGZ3"
  },
  {
    "uniprot": "A4I647",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I647"
  },
  {
    "uniprot": "A4I3K2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3K2"
  },
  {
    "uniprot": "A4HWJ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWJ2"
  },
  {
    "uniprot": "A4HRK5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRK5"
  },
  {
    "uniprot": "A4I214",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I214"
  },
  {
    "uniprot": "A4I2Y2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Y2"
  },
  {
    "uniprot": "A4HSP0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSP0"
  },
  {
    "uniprot": "A4HW06",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW06"
  },
  {
    "uniprot": "A4I9U1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9U1"
  },
  {
    "uniprot": "A4I4J3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4J3"
  },
  {
    "uniprot": "A4HVR4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVR4"
  },
  {
    "uniprot": "A4IE24",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE24"
  },
  {
    "uniprot": "A4IA25",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA25"
  },
  {
    "uniprot": "A4I6M2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6M2"
  },
  {
    "uniprot": "A4HVF9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVF9"
  },
  {
    "uniprot": "A4HSM9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSM9"
  },
  {
    "uniprot": "A4I3Z0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Z0"
  },
  {
    "uniprot": "A4I1H6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1H6"
  },
  {
    "uniprot": "A4HS35",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS35"
  },
  {
    "uniprot": "A4HUY8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUY8"
  },
  {
    "uniprot": "A4IAK2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAK2"
  },
  {
    "uniprot": "A4I4R7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4R7"
  },
  {
    "uniprot": "A4HTD9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTD9"
  },
  {
    "uniprot": "A4I7L7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7L7"
  },
  {
    "uniprot": "A4ICL8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICL8"
  },
  {
    "uniprot": "A4I167",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I167"
  },
  {
    "uniprot": "A4HWD5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWD5"
  },
  {
    "uniprot": "A4I1V9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1V9"
  },
  {
    "uniprot": "A4HTT8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTT8"
  },
  {
    "uniprot": "E9AGJ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGJ4"
  },
  {
    "uniprot": "A4I2V9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2V9"
  },
  {
    "uniprot": "A4I4D5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4D5"
  },
  {
    "uniprot": "A4I2U6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2U6"
  },
  {
    "uniprot": "A4I2Z2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Z2"
  },
  {
    "uniprot": "A4HTP0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTP0"
  },
  {
    "uniprot": "A4IAH1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAH1"
  },
  {
    "uniprot": "A4HWR2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWR2"
  },
  {
    "uniprot": "A4HYW5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYW5"
  },
  {
    "uniprot": "A4HY12",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY12"
  },
  {
    "uniprot": "A4HY64",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY64"
  },
  {
    "uniprot": "A4ID45",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID45"
  },
  {
    "uniprot": "A4I3X3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3X3"
  },
  {
    "uniprot": "A4HVL9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVL9"
  },
  {
    "uniprot": "A4HWA6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWA6"
  },
  {
    "uniprot": "E9AI02",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AI02"
  },
  {
    "uniprot": "A4IBK9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBK9"
  },
  {
    "uniprot": "A4I121",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I121"
  },
  {
    "uniprot": "A4I5A5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5A5"
  },
  {
    "uniprot": "A4HWN8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWN8"
  },
  {
    "uniprot": "A4HVS3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVS3"
  },
  {
    "uniprot": "A4IC13",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC13"
  },
  {
    "uniprot": "A4HYZ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYZ8"
  },
  {
    "uniprot": "A4HVR5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVR5"
  },
  {
    "uniprot": "A4HRT0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRT0"
  },
  {
    "uniprot": "A4I0T1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0T1"
  },
  {
    "uniprot": "A4HRF6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRF6"
  },
  {
    "uniprot": "A4IAZ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAZ9"
  },
  {
    "uniprot": "A4ICK4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICK4"
  },
  {
    "uniprot": "A4HYC8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYC8"
  },
  {
    "uniprot": "A4HZT5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZT5"
  },
  {
    "uniprot": "A4HZI7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZI7"
  },
  {
    "uniprot": "A4I984",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I984"
  },
  {
    "uniprot": "A4I125",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I125"
  },
  {
    "uniprot": "A4IDW8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDW8"
  },
  {
    "uniprot": "A4I383",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I383"
  },
  {
    "uniprot": "A4IA81",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA81"
  },
  {
    "uniprot": "A4I8S5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8S5"
  },
  {
    "uniprot": "A4HUL3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUL3"
  },
  {
    "uniprot": "A4I755",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I755"
  },
  {
    "uniprot": "A4I9D9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9D9"
  },
  {
    "uniprot": "A4I1B5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1B5"
  },
  {
    "uniprot": "A4HW98",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW98"
  },
  {
    "uniprot": "Q8I6E4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q8I6E4"
  },
  {
    "uniprot": "A4I8G9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8G9"
  },
  {
    "uniprot": "A4HZ02",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ02"
  },
  {
    "uniprot": "A4I355",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I355"
  },
  {
    "uniprot": "A4IA10",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA10"
  },
  {
    "uniprot": "A4I649",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I649"
  },
  {
    "uniprot": "A4I8W9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8W9"
  },
  {
    "uniprot": "A4I299",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I299"
  },
  {
    "uniprot": "A4IC29",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC29"
  },
  {
    "uniprot": "A4I4U4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4U4"
  },
  {
    "uniprot": "A4I4X0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4X0"
  },
  {
    "uniprot": "A4HZR8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZR8"
  },
  {
    "uniprot": "A4IDX3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDX3"
  },
  {
    "uniprot": "A4I9J5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9J5"
  },
  {
    "uniprot": "A4I0E5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0E5"
  },
  {
    "uniprot": "A4HYB5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYB5"
  },
  {
    "uniprot": "A4I9N1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9N1"
  },
  {
    "uniprot": "A4HVN6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVN6"
  },
  {
    "uniprot": "A4I2W4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2W4"
  },
  {
    "uniprot": "E9AH42",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH42"
  },
  {
    "uniprot": "A4I005",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I005"
  },
  {
    "uniprot": "A4HUF4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUF4"
  },
  {
    "uniprot": "A4HYV7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYV7"
  },
  {
    "uniprot": "A4IC68",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC68"
  },
  {
    "uniprot": "E9AG57",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG57"
  },
  {
    "uniprot": "A4IAQ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAQ4"
  },
  {
    "uniprot": "A4I362",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I362"
  },
  {
    "uniprot": "A4HX91",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX91"
  },
  {
    "uniprot": "A4HYH1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYH1"
  },
  {
    "uniprot": "A4I7H6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7H6"
  },
  {
    "uniprot": "A4I867",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I867"
  },
  {
    "uniprot": "A4IE14",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE14"
  },
  {
    "uniprot": "A4HTA9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTA9"
  },
  {
    "uniprot": "A4I3A7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3A7"
  },
  {
    "uniprot": "A4I2D6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2D6"
  },
  {
    "uniprot": "A4HS68",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS68"
  },
  {
    "uniprot": "A4HXR1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXR1"
  },
  {
    "uniprot": "A4I9D5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9D5"
  },
  {
    "uniprot": "A4I330",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I330"
  },
  {
    "uniprot": "A4IDR3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDR3"
  },
  {
    "uniprot": "A4IBS0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBS0"
  },
  {
    "uniprot": "E9AGV7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGV7"
  },
  {
    "uniprot": "A4I4I7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4I7"
  },
  {
    "uniprot": "A4IBZ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBZ1"
  },
  {
    "uniprot": "A4I5E8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5E8"
  },
  {
    "uniprot": "A4I7A7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7A7"
  },
  {
    "uniprot": "A4I1R9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1R9"
  },
  {
    "uniprot": "A4I4P2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4P2"
  },
  {
    "uniprot": "A4I8Q4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Q4"
  },
  {
    "uniprot": "A4I6S0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6S0"
  },
  {
    "uniprot": "A4HUT5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUT5"
  },
  {
    "uniprot": "A4HZL0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZL0"
  },
  {
    "uniprot": "A4ICP6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICP6"
  },
  {
    "uniprot": "A4I5Q4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Q4"
  },
  {
    "uniprot": "A4HY53",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY53"
  },
  {
    "uniprot": "A4I992",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I992"
  },
  {
    "uniprot": "A4HWW7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWW7"
  },
  {
    "uniprot": "A4HUY6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUY6"
  },
  {
    "uniprot": "A4IBK8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBK8"
  },
  {
    "uniprot": "A4ICC9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICC9"
  },
  {
    "uniprot": "A4I0P3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0P3"
  },
  {
    "uniprot": "A4HTN2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTN2"
  },
  {
    "uniprot": "A4I0P1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0P1"
  },
  {
    "uniprot": "A4I4Y0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Y0"
  },
  {
    "uniprot": "A4HX40",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX40"
  },
  {
    "uniprot": "A4I4W6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4W6"
  },
  {
    "uniprot": "A4I0W3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0W3"
  },
  {
    "uniprot": "A4ID60",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID60"
  },
  {
    "uniprot": "A4I2N1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2N1"
  },
  {
    "uniprot": "A4I988",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I988"
  },
  {
    "uniprot": "A4I1A8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1A8"
  },
  {
    "uniprot": "A4I0N9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0N9"
  },
  {
    "uniprot": "A4I873",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I873"
  },
  {
    "uniprot": "A4I8F5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8F5"
  },
  {
    "uniprot": "A4HXQ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXQ0"
  },
  {
    "uniprot": "A4I5V9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5V9"
  },
  {
    "uniprot": "A4HUU5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUU5"
  },
  {
    "uniprot": "A4ID30",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID30"
  },
  {
    "uniprot": "A4IB75",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB75"
  },
  {
    "uniprot": "A4I9K6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9K6"
  },
  {
    "uniprot": "A4I4D6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4D6"
  },
  {
    "uniprot": "E9AH83",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH83"
  },
  {
    "uniprot": "A4I287",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I287"
  },
  {
    "uniprot": "A4IAV5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAV5"
  },
  {
    "uniprot": "A4I1Q9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Q9"
  },
  {
    "uniprot": "E9AHY1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHY1"
  },
  {
    "uniprot": "A4I1Z8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Z8"
  },
  {
    "uniprot": "A4I698",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I698"
  },
  {
    "uniprot": "A4I5M7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5M7"
  },
  {
    "uniprot": "E9AH78",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH78"
  },
  {
    "uniprot": "A4I3Q6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Q6"
  },
  {
    "uniprot": "A4I8R6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8R6"
  },
  {
    "uniprot": "A4I1X8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1X8"
  },
  {
    "uniprot": "A4HZJ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZJ3"
  },
  {
    "uniprot": "A4I267",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I267"
  },
  {
    "uniprot": "A4HUU1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUU1"
  },
  {
    "uniprot": "A4HYB3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYB3"
  },
  {
    "uniprot": "A4I0B8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0B8"
  },
  {
    "uniprot": "E9AHG1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHG1"
  },
  {
    "uniprot": "E9AG42",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG42"
  },
  {
    "uniprot": "A4I7B1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7B1"
  },
  {
    "uniprot": "A4I3P5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3P5"
  },
  {
    "uniprot": "A4I637",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I637"
  },
  {
    "uniprot": "A4HXK8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXK8"
  },
  {
    "uniprot": "A4I796",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I796"
  },
  {
    "uniprot": "A4IDU0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDU0"
  },
  {
    "uniprot": "E9AG61",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG61"
  },
  {
    "uniprot": "A4I405",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I405"
  },
  {
    "uniprot": "A4I958",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I958"
  },
  {
    "uniprot": "A4I9V7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9V7"
  },
  {
    "uniprot": "A4IDF0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDF0"
  },
  {
    "uniprot": "A4I7Q3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Q3"
  },
  {
    "uniprot": "A4I581",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I581"
  },
  {
    "uniprot": "A4ICS9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICS9"
  },
  {
    "uniprot": "A4I0J0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0J0"
  },
  {
    "uniprot": "A4HWI4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWI4"
  },
  {
    "uniprot": "E9AHW5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHW5"
  },
  {
    "uniprot": "E9AG53",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG53"
  },
  {
    "uniprot": "A4IE45",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE45"
  },
  {
    "uniprot": "A4HVJ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVJ1"
  },
  {
    "uniprot": "A4I5C0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5C0"
  },
  {
    "uniprot": "A4IDD2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDD2"
  },
  {
    "uniprot": "A4HTI2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTI2"
  },
  {
    "uniprot": "A4IAA8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAA8"
  },
  {
    "uniprot": "A4ICH9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICH9"
  },
  {
    "uniprot": "A4HVG9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVG9"
  },
  {
    "uniprot": "A4I0G1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0G1"
  },
  {
    "uniprot": "E9AI08",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AI08"
  },
  {
    "uniprot": "A4ICK5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICK5"
  },
  {
    "uniprot": "A4HW43",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW43"
  },
  {
    "uniprot": "A4I491",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I491"
  },
  {
    "uniprot": "A4IAM7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAM7"
  },
  {
    "uniprot": "E9AHH4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHH4"
  },
  {
    "uniprot": "A4IA35",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA35"
  },
  {
    "uniprot": "A4HVP6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVP6"
  },
  {
    "uniprot": "A4I6Q0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Q0"
  },
  {
    "uniprot": "A4HZB4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZB4"
  },
  {
    "uniprot": "A4HRL4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRL4"
  },
  {
    "uniprot": "A4HYQ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYQ8"
  },
  {
    "uniprot": "E9AGC3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGC3"
  },
  {
    "uniprot": "A4I870",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I870"
  },
  {
    "uniprot": "A4I8J7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8J7"
  },
  {
    "uniprot": "A4I3X5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3X5"
  },
  {
    "uniprot": "A4I9G5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9G5"
  },
  {
    "uniprot": "A4I477",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I477"
  },
  {
    "uniprot": "A4HWW4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWW4"
  },
  {
    "uniprot": "A4IBA3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBA3"
  },
  {
    "uniprot": "A4I8R4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8R4"
  },
  {
    "uniprot": "E9AGF1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGF1"
  },
  {
    "uniprot": "A4HVP0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVP0"
  },
  {
    "uniprot": "A4HRJ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRJ0"
  },
  {
    "uniprot": "A0A381MN58",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MN58"
  },
  {
    "uniprot": "A4I8B1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8B1"
  },
  {
    "uniprot": "A4HYJ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYJ1"
  },
  {
    "uniprot": "A4HXD8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXD8"
  },
  {
    "uniprot": "A4HWP2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWP2"
  },
  {
    "uniprot": "A4ICX3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICX3"
  },
  {
    "uniprot": "A4I096",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I096"
  },
  {
    "uniprot": "A4HRK4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRK4"
  },
  {
    "uniprot": "A4HWN1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWN1"
  },
  {
    "uniprot": "A4I4R6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4R6"
  },
  {
    "uniprot": "A4I487",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I487"
  },
  {
    "uniprot": "A4IA55",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA55"
  },
  {
    "uniprot": "A4I496",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I496"
  },
  {
    "uniprot": "A4I6S7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6S7"
  },
  {
    "uniprot": "E9AHQ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHQ4"
  },
  {
    "uniprot": "A4I1E6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1E6"
  },
  {
    "uniprot": "A4IBD5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBD5"
  },
  {
    "uniprot": "A4ICN2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICN2"
  },
  {
    "uniprot": "A4I3R3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3R3"
  },
  {
    "uniprot": "A4I662",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I662"
  },
  {
    "uniprot": "A4I820",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I820"
  },
  {
    "uniprot": "A4HXX7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXX7"
  },
  {
    "uniprot": "E9AH69",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH69"
  },
  {
    "uniprot": "A4I3E7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3E7"
  },
  {
    "uniprot": "A4HVX0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVX0"
  },
  {
    "uniprot": "A4I6V0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6V0"
  },
  {
    "uniprot": "A4HYA0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYA0"
  },
  {
    "uniprot": "A4HVT5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVT5"
  },
  {
    "uniprot": "A4HYL6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYL6"
  },
  {
    "uniprot": "A4I2B9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2B9"
  },
  {
    "uniprot": "A4I5E3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5E3"
  },
  {
    "uniprot": "A4HRN6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRN6"
  },
  {
    "uniprot": "A4I385",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I385"
  },
  {
    "uniprot": "A4HRT7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRT7"
  },
  {
    "uniprot": "A4HUK0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUK0"
  },
  {
    "uniprot": "A4I7M6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7M6"
  },
  {
    "uniprot": "A4I2C7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2C7"
  },
  {
    "uniprot": "A4IC49",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC49"
  },
  {
    "uniprot": "A4IBL5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBL5"
  },
  {
    "uniprot": "A4I3T7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3T7"
  },
  {
    "uniprot": "A4IBQ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBQ2"
  },
  {
    "uniprot": "A4I9M6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9M6"
  },
  {
    "uniprot": "Q9GPZ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q9GPZ9"
  },
  {
    "uniprot": "A4HZG2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZG2"
  },
  {
    "uniprot": "A4HY11",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY11"
  },
  {
    "uniprot": "A4I2L0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2L0"
  },
  {
    "uniprot": "A4ICJ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICJ6"
  },
  {
    "uniprot": "E9AHL1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHL1"
  },
  {
    "uniprot": "A4HZ71",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ71"
  },
  {
    "uniprot": "A4I9N0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9N0"
  },
  {
    "uniprot": "A4HTI4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTI4"
  },
  {
    "uniprot": "A4I207",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I207"
  },
  {
    "uniprot": "A4HRG2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRG2"
  },
  {
    "uniprot": "A4I3R4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3R4"
  },
  {
    "uniprot": "A4I2Z7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Z7"
  },
  {
    "uniprot": "A4HU73",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU73"
  },
  {
    "uniprot": "A4HXR5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXR5"
  },
  {
    "uniprot": "A4IBS9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBS9"
  },
  {
    "uniprot": "E9AGW3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGW3"
  },
  {
    "uniprot": "E9AH35",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH35"
  },
  {
    "uniprot": "A4IAK5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAK5"
  },
  {
    "uniprot": "A4HTX4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTX4"
  },
  {
    "uniprot": "A4HY59",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY59"
  },
  {
    "uniprot": "A4HTF7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTF7"
  },
  {
    "uniprot": "A4I2H2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2H2"
  },
  {
    "uniprot": "A4I158",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I158"
  },
  {
    "uniprot": "A4I6F3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6F3"
  },
  {
    "uniprot": "A4HWX3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWX3"
  },
  {
    "uniprot": "A4HSL3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSL3"
  },
  {
    "uniprot": "A4HYR6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYR6"
  },
  {
    "uniprot": "A4I578",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I578"
  },
  {
    "uniprot": "E9AGQ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGQ0"
  },
  {
    "uniprot": "A4I4U5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4U5"
  },
  {
    "uniprot": "A4HYR7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYR7"
  },
  {
    "uniprot": "A4I8C1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8C1"
  },
  {
    "uniprot": "A4ICE6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICE6"
  },
  {
    "uniprot": "A4I5D0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5D0"
  },
  {
    "uniprot": "A4I313",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I313"
  },
  {
    "uniprot": "A4I2S7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2S7"
  },
  {
    "uniprot": "A4I8R2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8R2"
  },
  {
    "uniprot": "A4HWI9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWI9"
  },
  {
    "uniprot": "A4IAL0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAL0"
  },
  {
    "uniprot": "A4HTU8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTU8"
  },
  {
    "uniprot": "A4I2H3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2H3"
  },
  {
    "uniprot": "A4I164",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I164"
  },
  {
    "uniprot": "A4I4I1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4I1"
  },
  {
    "uniprot": "A4IAS4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAS4"
  },
  {
    "uniprot": "E9AGA5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGA5"
  },
  {
    "uniprot": "A4HWQ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWQ5"
  },
  {
    "uniprot": "E9AHM9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHM9"
  },
  {
    "uniprot": "A4I250",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I250"
  },
  {
    "uniprot": "A4HVP3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVP3"
  },
  {
    "uniprot": "A4HTU6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTU6"
  },
  {
    "uniprot": "A4I3C0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3C0"
  },
  {
    "uniprot": "E9AI06",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AI06"
  },
  {
    "uniprot": "A4I4N0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4N0"
  },
  {
    "uniprot": "A4HWB2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWB2"
  },
  {
    "uniprot": "E9AGE0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGE0"
  },
  {
    "uniprot": "A4HVK5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVK5"
  },
  {
    "uniprot": "A4I1P2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1P2"
  },
  {
    "uniprot": "A4I392",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I392"
  },
  {
    "uniprot": "A4HS80",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS80"
  },
  {
    "uniprot": "A4IE16",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE16"
  },
  {
    "uniprot": "E9AGJ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGJ6"
  },
  {
    "uniprot": "A4HUT0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUT0"
  },
  {
    "uniprot": "A4I453",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I453"
  },
  {
    "uniprot": "A4HYG5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYG5"
  },
  {
    "uniprot": "A4HY96",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY96"
  },
  {
    "uniprot": "E9AHE9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHE9"
  },
  {
    "uniprot": "A4I910",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I910"
  },
  {
    "uniprot": "A4IBC0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBC0"
  },
  {
    "uniprot": "A4HY72",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY72"
  },
  {
    "uniprot": "A4HXM1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXM1"
  },
  {
    "uniprot": "E9AG30",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG30"
  },
  {
    "uniprot": "A4IB23",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB23"
  },
  {
    "uniprot": "A4HT02",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT02"
  },
  {
    "uniprot": "A4I9I8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9I8"
  },
  {
    "uniprot": "A4I8I5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8I5"
  },
  {
    "uniprot": "A4IDT3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDT3"
  },
  {
    "uniprot": "A4I3J3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3J3"
  },
  {
    "uniprot": "A4I9C2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9C2"
  },
  {
    "uniprot": "A4HYD9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYD9"
  },
  {
    "uniprot": "A4I915",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I915"
  },
  {
    "uniprot": "A4I803",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I803"
  },
  {
    "uniprot": "A4HXC6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXC6"
  },
  {
    "uniprot": "A4I8N0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8N0"
  },
  {
    "uniprot": "A0A381MVV3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MVV3"
  },
  {
    "uniprot": "A4I2F7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2F7"
  },
  {
    "uniprot": "A4HXB2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXB2"
  },
  {
    "uniprot": "A4I1R7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1R7"
  },
  {
    "uniprot": "A4HZQ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZQ4"
  },
  {
    "uniprot": "A4IAZ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAZ1"
  },
  {
    "uniprot": "A4IAU6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAU6"
  },
  {
    "uniprot": "A4IA36",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA36"
  },
  {
    "uniprot": "A4I7K7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7K7"
  },
  {
    "uniprot": "A4IB85",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB85"
  },
  {
    "uniprot": "A4HT08",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT08"
  },
  {
    "uniprot": "E9AHM1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHM1"
  },
  {
    "uniprot": "A4I3U8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3U8"
  },
  {
    "uniprot": "E9AGV6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGV6"
  },
  {
    "uniprot": "A4HRV3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRV3"
  },
  {
    "uniprot": "A4HSM1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSM1"
  },
  {
    "uniprot": "A4I8L7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8L7"
  },
  {
    "uniprot": "A4HY16",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY16"
  },
  {
    "uniprot": "A4HWD2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWD2"
  },
  {
    "uniprot": "A4HVU2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVU2"
  },
  {
    "uniprot": "A4IDJ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDJ2"
  },
  {
    "uniprot": "A4IAW0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAW0"
  },
  {
    "uniprot": "A4HZU2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZU2"
  },
  {
    "uniprot": "A4HWA5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWA5"
  },
  {
    "uniprot": "A4I5J8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5J8"
  },
  {
    "uniprot": "A4IDA9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDA9"
  },
  {
    "uniprot": "A4I884",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I884"
  },
  {
    "uniprot": "E9AGD5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGD5"
  },
  {
    "uniprot": "A4I1H3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1H3"
  },
  {
    "uniprot": "A4IC79",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC79"
  },
  {
    "uniprot": "A4HSK2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSK2"
  },
  {
    "uniprot": "A4HSY1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSY1"
  },
  {
    "uniprot": "A4I5I5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5I5"
  },
  {
    "uniprot": "A4I289",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I289"
  },
  {
    "uniprot": "A4IDF7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDF7"
  },
  {
    "uniprot": "A4HZV5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZV5"
  },
  {
    "uniprot": "A4HYT0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYT0"
  },
  {
    "uniprot": "A4HUJ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUJ7"
  },
  {
    "uniprot": "A4HU65",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU65"
  },
  {
    "uniprot": "A4I9G4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9G4"
  },
  {
    "uniprot": "A4I6X4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6X4"
  },
  {
    "uniprot": "A4HVD9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVD9"
  },
  {
    "uniprot": "A4HUY3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUY3"
  },
  {
    "uniprot": "A4I9S9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9S9"
  },
  {
    "uniprot": "A4I026",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I026"
  },
  {
    "uniprot": "E9AGA4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGA4"
  },
  {
    "uniprot": "A4I6M1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6M1"
  },
  {
    "uniprot": "A4HUN2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUN2"
  },
  {
    "uniprot": "A4HRL1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRL1"
  },
  {
    "uniprot": "A4I0K4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0K4"
  },
  {
    "uniprot": "A4ICQ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICQ9"
  },
  {
    "uniprot": "A4HT21",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT21"
  },
  {
    "uniprot": "A4I9T2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9T2"
  },
  {
    "uniprot": "A4IDM5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDM5"
  },
  {
    "uniprot": "A4HY00",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY00"
  },
  {
    "uniprot": "A4I0C5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0C5"
  },
  {
    "uniprot": "A4I6C4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6C4"
  },
  {
    "uniprot": "A4HTE3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTE3"
  },
  {
    "uniprot": "A4I5D2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5D2"
  },
  {
    "uniprot": "E9AHG0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHG0"
  },
  {
    "uniprot": "A4IE23",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE23"
  },
  {
    "uniprot": "A4HXN1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXN1"
  },
  {
    "uniprot": "A4I636",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I636"
  },
  {
    "uniprot": "A4HS21",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS21"
  },
  {
    "uniprot": "A4I9N8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9N8"
  },
  {
    "uniprot": "E9AHX6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHX6"
  },
  {
    "uniprot": "E9AHR0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHR0"
  },
  {
    "uniprot": "A4HRK0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRK0"
  },
  {
    "uniprot": "A4IB40",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB40"
  },
  {
    "uniprot": "A4HZ50",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ50"
  },
  {
    "uniprot": "E9AH76",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH76"
  },
  {
    "uniprot": "A4ICP9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICP9"
  },
  {
    "uniprot": "A4I9M2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9M2"
  },
  {
    "uniprot": "A4HWZ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWZ8"
  },
  {
    "uniprot": "A4HYC2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYC2"
  },
  {
    "uniprot": "A4IAJ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAJ4"
  },
  {
    "uniprot": "A4HTK6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTK6"
  },
  {
    "uniprot": "A4I5M0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5M0"
  },
  {
    "uniprot": "E9AG89",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG89"
  },
  {
    "uniprot": "A4I9A1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9A1"
  },
  {
    "uniprot": "A4HXQ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXQ6"
  },
  {
    "uniprot": "A4HY09",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY09"
  },
  {
    "uniprot": "A4HSP2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSP2"
  },
  {
    "uniprot": "A4I246",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I246"
  },
  {
    "uniprot": "E9AGR1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGR1"
  },
  {
    "uniprot": "A4HUE1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUE1"
  },
  {
    "uniprot": "A4IAS5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAS5"
  },
  {
    "uniprot": "A4I7A2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7A2"
  },
  {
    "uniprot": "A4HZL5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZL5"
  },
  {
    "uniprot": "A4HVT3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVT3"
  },
  {
    "uniprot": "A4IAW2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAW2"
  },
  {
    "uniprot": "A4HS32",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS32"
  },
  {
    "uniprot": "A4IDR1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDR1"
  },
  {
    "uniprot": "A4I704",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I704"
  },
  {
    "uniprot": "E9AHI5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHI5"
  },
  {
    "uniprot": "A4HRT3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRT3"
  },
  {
    "uniprot": "A4HYY8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYY8"
  },
  {
    "uniprot": "A4HVK0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVK0"
  },
  {
    "uniprot": "A4HTY2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTY2"
  },
  {
    "uniprot": "A4I5N5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5N5"
  },
  {
    "uniprot": "A4HUG9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUG9"
  },
  {
    "uniprot": "A4HX36",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX36"
  },
  {
    "uniprot": "A4HZ70",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ70"
  },
  {
    "uniprot": "A4I5E2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5E2"
  },
  {
    "uniprot": "A4HVL7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVL7"
  },
  {
    "uniprot": "E9AGK2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGK2"
  },
  {
    "uniprot": "A4HW77",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW77"
  },
  {
    "uniprot": "A4HSV6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSV6"
  },
  {
    "uniprot": "A4HZI2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZI2"
  },
  {
    "uniprot": "A4ICE1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICE1"
  },
  {
    "uniprot": "A4HV13",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV13"
  },
  {
    "uniprot": "A4I224",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I224"
  },
  {
    "uniprot": "A4I3W5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3W5"
  },
  {
    "uniprot": "A4IAM0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAM0"
  },
  {
    "uniprot": "A4ICM4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICM4"
  },
  {
    "uniprot": "A4HSZ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSZ9"
  },
  {
    "uniprot": "A4I2M8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2M8"
  },
  {
    "uniprot": "A4I706",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I706"
  },
  {
    "uniprot": "A4ICT2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICT2"
  },
  {
    "uniprot": "E9AHG4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHG4"
  },
  {
    "uniprot": "E9AG78",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG78"
  },
  {
    "uniprot": "A4I8F6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8F6"
  },
  {
    "uniprot": "A4I8I6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8I6"
  },
  {
    "uniprot": "E9AHR9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHR9"
  },
  {
    "uniprot": "A4HTC3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTC3"
  },
  {
    "uniprot": "A4IAV6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAV6"
  },
  {
    "uniprot": "A4HU03",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU03"
  },
  {
    "uniprot": "E9AHY5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHY5"
  },
  {
    "uniprot": "A4HRF5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRF5"
  },
  {
    "uniprot": "E9AGF8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGF8"
  },
  {
    "uniprot": "A4IBJ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBJ9"
  },
  {
    "uniprot": "E9AGP3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGP3"
  },
  {
    "uniprot": "A4I822",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I822"
  },
  {
    "uniprot": "A4I973",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I973"
  },
  {
    "uniprot": "A4HVH7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVH7"
  },
  {
    "uniprot": "A4I4I2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4I2"
  },
  {
    "uniprot": "E9AHY7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHY7"
  },
  {
    "uniprot": "A4HUV1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUV1"
  },
  {
    "uniprot": "A4IDN5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDN5"
  },
  {
    "uniprot": "A4IBZ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBZ5"
  },
  {
    "uniprot": "A4I7T6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7T6"
  },
  {
    "uniprot": "A4I3B5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3B5"
  },
  {
    "uniprot": "A4IAH5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAH5"
  },
  {
    "uniprot": "A4HSW4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSW4"
  },
  {
    "uniprot": "A4I9A0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9A0"
  },
  {
    "uniprot": "A4HWN5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWN5"
  },
  {
    "uniprot": "A4HTF9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTF9"
  },
  {
    "uniprot": "A4I7S5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7S5"
  },
  {
    "uniprot": "A4I2E5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2E5"
  },
  {
    "uniprot": "A4IDK7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDK7"
  },
  {
    "uniprot": "E9AGJ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGJ9"
  },
  {
    "uniprot": "A4IBG0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBG0"
  },
  {
    "uniprot": "A4I8Y3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Y3"
  },
  {
    "uniprot": "E9AGC1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGC1"
  },
  {
    "uniprot": "A4I8G6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8G6"
  },
  {
    "uniprot": "A4HU30",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU30"
  },
  {
    "uniprot": "E9AG82",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG82"
  },
  {
    "uniprot": "A4HTS7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTS7"
  },
  {
    "uniprot": "A4HSS3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSS3"
  },
  {
    "uniprot": "A4I9D3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9D3"
  },
  {
    "uniprot": "A4I2N4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2N4"
  },
  {
    "uniprot": "A4HRD8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRD8"
  },
  {
    "uniprot": "A4HT06",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT06"
  },
  {
    "uniprot": "A4I8N8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8N8"
  },
  {
    "uniprot": "A4I8I1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8I1"
  },
  {
    "uniprot": "A4HY61",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY61"
  },
  {
    "uniprot": "A4I818",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I818"
  },
  {
    "uniprot": "A4IAV9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAV9"
  },
  {
    "uniprot": "A4I3W2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3W2"
  },
  {
    "uniprot": "A4IBE3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBE3"
  },
  {
    "uniprot": "A4HUB9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUB9"
  },
  {
    "uniprot": "A4HY47",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY47"
  },
  {
    "uniprot": "A4HW05",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW05"
  },
  {
    "uniprot": "A4IAH4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAH4"
  },
  {
    "uniprot": "A4I292",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I292"
  },
  {
    "uniprot": "A4I168",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I168"
  },
  {
    "uniprot": "A4I6A4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6A4"
  },
  {
    "uniprot": "A4I1D7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1D7"
  },
  {
    "uniprot": "A4I4J7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4J7"
  },
  {
    "uniprot": "A4IAE4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAE4"
  },
  {
    "uniprot": "A4I1N9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1N9"
  },
  {
    "uniprot": "A4I1P0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1P0"
  },
  {
    "uniprot": "A4I8V0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8V0"
  },
  {
    "uniprot": "A4HWM5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWM5"
  },
  {
    "uniprot": "A4HYK2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYK2"
  },
  {
    "uniprot": "A4HVT1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVT1"
  },
  {
    "uniprot": "A4IBK4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBK4"
  },
  {
    "uniprot": "A4I940",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I940"
  },
  {
    "uniprot": "A4I0G5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0G5"
  },
  {
    "uniprot": "A4HUD8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUD8"
  },
  {
    "uniprot": "A4I3W0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3W0"
  },
  {
    "uniprot": "A4I1C7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1C7"
  },
  {
    "uniprot": "A4I5L0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5L0"
  },
  {
    "uniprot": "A4I238",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I238"
  },
  {
    "uniprot": "A4IBH6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBH6"
  },
  {
    "uniprot": "A4HTE6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTE6"
  },
  {
    "uniprot": "A4I965",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I965"
  },
  {
    "uniprot": "A4HVY6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVY6"
  },
  {
    "uniprot": "A4HVL3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVL3"
  },
  {
    "uniprot": "A4I0T4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0T4"
  },
  {
    "uniprot": "A4HUT3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUT3"
  },
  {
    "uniprot": "A4IDK9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDK9"
  },
  {
    "uniprot": "Q95NF5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q95NF5"
  },
  {
    "uniprot": "A4I7W2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7W2"
  },
  {
    "uniprot": "A4ID80",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID80"
  },
  {
    "uniprot": "A4I1J4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1J4"
  },
  {
    "uniprot": "A4HVD7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVD7"
  },
  {
    "uniprot": "E9AG51",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG51"
  },
  {
    "uniprot": "A4IBQ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBQ0"
  },
  {
    "uniprot": "A4HYT7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYT7"
  },
  {
    "uniprot": "A4I4T7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4T7"
  },
  {
    "uniprot": "E9AHB0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHB0"
  },
  {
    "uniprot": "A4HT34",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT34"
  },
  {
    "uniprot": "A4I136",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I136"
  },
  {
    "uniprot": "A4HU41",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU41"
  },
  {
    "uniprot": "A4I0W7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0W7"
  },
  {
    "uniprot": "E9AH90",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH90"
  },
  {
    "uniprot": "A4HTY9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTY9"
  },
  {
    "uniprot": "A4I421",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I421"
  },
  {
    "uniprot": "E9AHG9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHG9"
  },
  {
    "uniprot": "A4IAM6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAM6"
  },
  {
    "uniprot": "A4HYH5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYH5"
  },
  {
    "uniprot": "E9AHR2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHR2"
  },
  {
    "uniprot": "A4I9R7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9R7"
  },
  {
    "uniprot": "A0A381MG06",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MG06"
  },
  {
    "uniprot": "E9AHZ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHZ9"
  },
  {
    "uniprot": "A4I6K2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6K2"
  },
  {
    "uniprot": "A4HUS9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUS9"
  },
  {
    "uniprot": "A4IDA1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDA1"
  },
  {
    "uniprot": "A4HT37",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT37"
  },
  {
    "uniprot": "A4HTJ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTJ9"
  },
  {
    "uniprot": "A4I0M5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0M5"
  },
  {
    "uniprot": "A4HUY0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUY0"
  },
  {
    "uniprot": "A4I3G1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3G1"
  },
  {
    "uniprot": "A4HYM4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYM4"
  },
  {
    "uniprot": "A4I116",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I116"
  },
  {
    "uniprot": "A4I6T9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6T9"
  },
  {
    "uniprot": "A4HWV2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWV2"
  },
  {
    "uniprot": "A4I082",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I082"
  },
  {
    "uniprot": "A4I8N2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8N2"
  },
  {
    "uniprot": "A4I8B8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8B8"
  },
  {
    "uniprot": "A4I959",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I959"
  },
  {
    "uniprot": "A4HWW1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWW1"
  },
  {
    "uniprot": "A4I8H4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8H4"
  },
  {
    "uniprot": "A4I0Z9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Z9"
  },
  {
    "uniprot": "A4I8C5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8C5"
  },
  {
    "uniprot": "A4HXS9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXS9"
  },
  {
    "uniprot": "A4HRL9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRL9"
  },
  {
    "uniprot": "A4I905",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I905"
  },
  {
    "uniprot": "A4HRS7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRS7"
  },
  {
    "uniprot": "A4I783",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I783"
  },
  {
    "uniprot": "A4I3Q3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Q3"
  },
  {
    "uniprot": "A4HTV7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTV7"
  },
  {
    "uniprot": "A4HUE6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUE6"
  },
  {
    "uniprot": "A4I7I7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7I7"
  },
  {
    "uniprot": "A4I0J9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0J9"
  },
  {
    "uniprot": "A4I4Z3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Z3"
  },
  {
    "uniprot": "A4HT00",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT00"
  },
  {
    "uniprot": "A4HY97",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY97"
  },
  {
    "uniprot": "A4I4I4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4I4"
  },
  {
    "uniprot": "A4HW16",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW16"
  },
  {
    "uniprot": "A4I6V2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6V2"
  },
  {
    "uniprot": "A4I1R2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1R2"
  },
  {
    "uniprot": "A4I7X8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7X8"
  },
  {
    "uniprot": "A4HTS1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTS1"
  },
  {
    "uniprot": "A4HXZ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXZ8"
  },
  {
    "uniprot": "A4HTC7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTC7"
  },
  {
    "uniprot": "A4HVN7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVN7"
  },
  {
    "uniprot": "E9AGE4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGE4"
  },
  {
    "uniprot": "A4I074",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I074"
  },
  {
    "uniprot": "A4IC50",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC50"
  },
  {
    "uniprot": "A4I5Y2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Y2"
  },
  {
    "uniprot": "A4ICF5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICF5"
  },
  {
    "uniprot": "A4I5J3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5J3"
  },
  {
    "uniprot": "A4I3S6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3S6"
  },
  {
    "uniprot": "A4HXM7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXM7"
  },
  {
    "uniprot": "A4ID32",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID32"
  },
  {
    "uniprot": "E9AH85",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH85"
  },
  {
    "uniprot": "A4HWY3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWY3"
  },
  {
    "uniprot": "A4IDM1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDM1"
  },
  {
    "uniprot": "A4I0X8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0X8"
  },
  {
    "uniprot": "E9AHP4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHP4"
  },
  {
    "uniprot": "A4HUV3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUV3"
  },
  {
    "uniprot": "A4I9Z3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Z3"
  },
  {
    "uniprot": "A4HWS6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWS6"
  },
  {
    "uniprot": "E9AG25",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG25"
  },
  {
    "uniprot": "A4I1M9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1M9"
  },
  {
    "uniprot": "A4I200",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I200"
  },
  {
    "uniprot": "A4HSP8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSP8"
  },
  {
    "uniprot": "A4I0Z3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Z3"
  },
  {
    "uniprot": "A4IBH2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBH2"
  },
  {
    "uniprot": "A4IDQ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDQ6"
  },
  {
    "uniprot": "A4HZA6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZA6"
  },
  {
    "uniprot": "A4I232",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I232"
  },
  {
    "uniprot": "A4IA92",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA92"
  },
  {
    "uniprot": "A4I8E6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8E6"
  },
  {
    "uniprot": "A4HT10",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT10"
  },
  {
    "uniprot": "A4ID99",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID99"
  },
  {
    "uniprot": "A4I300",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I300"
  },
  {
    "uniprot": "A4I1R0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1R0"
  },
  {
    "uniprot": "E9AHD7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHD7"
  },
  {
    "uniprot": "A4IDI2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDI2"
  },
  {
    "uniprot": "A4I2C8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2C8"
  },
  {
    "uniprot": "A4I4A9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4A9"
  },
  {
    "uniprot": "A4I3D7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3D7"
  },
  {
    "uniprot": "E9AGT1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGT1"
  },
  {
    "uniprot": "E9AGA0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGA0"
  },
  {
    "uniprot": "A4HZM0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZM0"
  },
  {
    "uniprot": "E9AGY1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGY1"
  },
  {
    "uniprot": "A4HWE7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWE7"
  },
  {
    "uniprot": "A4HUK5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUK5"
  },
  {
    "uniprot": "A4HY03",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY03"
  },
  {
    "uniprot": "A4HRN0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRN0"
  },
  {
    "uniprot": "A4HVF8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVF8"
  },
  {
    "uniprot": "A4I139",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I139"
  },
  {
    "uniprot": "E9AH97",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH97"
  },
  {
    "uniprot": "A4IB76",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB76"
  },
  {
    "uniprot": "A4I574",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I574"
  },
  {
    "uniprot": "E9AH59",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH59"
  },
  {
    "uniprot": "A4HS38",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS38"
  },
  {
    "uniprot": "A4HUR5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUR5"
  },
  {
    "uniprot": "A4HUU0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUU0"
  },
  {
    "uniprot": "A4I1Q8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Q8"
  },
  {
    "uniprot": "A4I616",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I616"
  },
  {
    "uniprot": "A4HS08",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS08"
  },
  {
    "uniprot": "A4I710",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I710"
  },
  {
    "uniprot": "A4I4G9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4G9"
  },
  {
    "uniprot": "A4I9I3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9I3"
  },
  {
    "uniprot": "A4I2A3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2A3"
  },
  {
    "uniprot": "A4I2Z5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Z5"
  },
  {
    "uniprot": "E9AH87",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH87"
  },
  {
    "uniprot": "A4I0R9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0R9"
  },
  {
    "uniprot": "A4HT03",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT03"
  },
  {
    "uniprot": "A4ICZ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICZ8"
  },
  {
    "uniprot": "A4IA11",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA11"
  },
  {
    "uniprot": "E9AGH4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGH4"
  },
  {
    "uniprot": "A4HW19",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW19"
  },
  {
    "uniprot": "A4I8F4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8F4"
  },
  {
    "uniprot": "A4HU29",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU29"
  },
  {
    "uniprot": "A4HV42",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV42"
  },
  {
    "uniprot": "A4HT74",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT74"
  },
  {
    "uniprot": "A4I572",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I572"
  },
  {
    "uniprot": "A4I6S3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6S3"
  },
  {
    "uniprot": "A4ICC6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICC6"
  },
  {
    "uniprot": "A4I606",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I606"
  },
  {
    "uniprot": "A4HTU4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTU4"
  },
  {
    "uniprot": "A4I654",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I654"
  },
  {
    "uniprot": "A4I889",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I889"
  },
  {
    "uniprot": "A4HY73",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY73"
  },
  {
    "uniprot": "A4HTI1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTI1"
  },
  {
    "uniprot": "A4I6F0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6F0"
  },
  {
    "uniprot": "A4HUH2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUH2"
  },
  {
    "uniprot": "E9AHA1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHA1"
  },
  {
    "uniprot": "A4HRS2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRS2"
  },
  {
    "uniprot": "A4I6K3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6K3"
  },
  {
    "uniprot": "E9AHC6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHC6"
  },
  {
    "uniprot": "E9AGX6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGX6"
  },
  {
    "uniprot": "E9AGG5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGG5"
  },
  {
    "uniprot": "E9AGS6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGS6"
  },
  {
    "uniprot": "E9AGK6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGK6"
  },
  {
    "uniprot": "A4I7I9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7I9"
  },
  {
    "uniprot": "A4HVW9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVW9"
  },
  {
    "uniprot": "A4HXB3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXB3"
  },
  {
    "uniprot": "A4IBL9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBL9"
  },
  {
    "uniprot": "A4I038",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I038"
  },
  {
    "uniprot": "A4I398",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I398"
  },
  {
    "uniprot": "A4HXU3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXU3"
  },
  {
    "uniprot": "A4HWZ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWZ3"
  },
  {
    "uniprot": "A4I4Q3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Q3"
  },
  {
    "uniprot": "A4HSL9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSL9"
  },
  {
    "uniprot": "A4HYY0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYY0"
  },
  {
    "uniprot": "A4I8N7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8N7"
  },
  {
    "uniprot": "A4I891",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I891"
  },
  {
    "uniprot": "A4HWZ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWZ1"
  },
  {
    "uniprot": "A4HRL0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRL0"
  },
  {
    "uniprot": "A4I428",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I428"
  },
  {
    "uniprot": "A4IAN9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAN9"
  },
  {
    "uniprot": "A4I763",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I763"
  },
  {
    "uniprot": "A4I439",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I439"
  },
  {
    "uniprot": "A4I329",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I329"
  },
  {
    "uniprot": "A4I3I2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3I2"
  },
  {
    "uniprot": "A4IDZ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDZ6"
  },
  {
    "uniprot": "A4I6A1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6A1"
  },
  {
    "uniprot": "A4IBQ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBQ5"
  },
  {
    "uniprot": "A4HYI2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYI2"
  },
  {
    "uniprot": "A4IDM2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDM2"
  },
  {
    "uniprot": "A4HYP6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYP6"
  },
  {
    "uniprot": "A4IC90",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC90"
  },
  {
    "uniprot": "A4ICC2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICC2"
  },
  {
    "uniprot": "A4I0I0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0I0"
  },
  {
    "uniprot": "A4I4C0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4C0"
  },
  {
    "uniprot": "A4I974",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I974"
  },
  {
    "uniprot": "A4I4T6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4T6"
  },
  {
    "uniprot": "A4HRW3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRW3"
  },
  {
    "uniprot": "A4ICZ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICZ1"
  },
  {
    "uniprot": "A4I1X3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1X3"
  },
  {
    "uniprot": "A4I0W0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0W0"
  },
  {
    "uniprot": "A4HU42",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU42"
  },
  {
    "uniprot": "A4IA47",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA47"
  },
  {
    "uniprot": "A4I8E7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8E7"
  },
  {
    "uniprot": "A4I907",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I907"
  },
  {
    "uniprot": "A4I952",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I952"
  },
  {
    "uniprot": "A4I5U5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5U5"
  },
  {
    "uniprot": "A4I800",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I800"
  },
  {
    "uniprot": "A4HRR9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRR9"
  },
  {
    "uniprot": "A4HYK0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYK0"
  },
  {
    "uniprot": "A4HT97",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT97"
  },
  {
    "uniprot": "A4HZ57",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ57"
  },
  {
    "uniprot": "A4I498",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I498"
  },
  {
    "uniprot": "A4HXL1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXL1"
  },
  {
    "uniprot": "E9AHZ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHZ6"
  },
  {
    "uniprot": "A4IA44",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA44"
  },
  {
    "uniprot": "A4I120",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I120"
  },
  {
    "uniprot": "A4HSF1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSF1"
  },
  {
    "uniprot": "A4IB79",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB79"
  },
  {
    "uniprot": "A4I2R1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2R1"
  },
  {
    "uniprot": "A4HUM1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUM1"
  },
  {
    "uniprot": "A4HZE5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZE5"
  },
  {
    "uniprot": "A4HTC0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTC0"
  },
  {
    "uniprot": "A4I5F9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5F9"
  },
  {
    "uniprot": "A4HX87",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX87"
  },
  {
    "uniprot": "A0A381ML15",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381ML15"
  },
  {
    "uniprot": "E9AG83",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG83"
  },
  {
    "uniprot": "A4I6Y5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Y5"
  },
  {
    "uniprot": "A4I1B9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1B9"
  },
  {
    "uniprot": "A4I8I2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8I2"
  },
  {
    "uniprot": "A4HZ65",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ65"
  },
  {
    "uniprot": "A4ID85",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID85"
  },
  {
    "uniprot": "A4HZX8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZX8"
  },
  {
    "uniprot": "A4HV16",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV16"
  },
  {
    "uniprot": "A4IAF9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAF9"
  },
  {
    "uniprot": "A4I797",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I797"
  },
  {
    "uniprot": "A4I877",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I877"
  },
  {
    "uniprot": "A4HUE4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUE4"
  },
  {
    "uniprot": "A4HRD9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRD9"
  },
  {
    "uniprot": "A4IA59",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA59"
  },
  {
    "uniprot": "E9AH65",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH65"
  },
  {
    "uniprot": "A4I0R4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0R4"
  },
  {
    "uniprot": "A4ICU0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICU0"
  },
  {
    "uniprot": "A4I7N3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7N3"
  },
  {
    "uniprot": "A4I493",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I493"
  },
  {
    "uniprot": "A4HXE6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXE6"
  },
  {
    "uniprot": "A4I761",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I761"
  },
  {
    "uniprot": "A4HTT9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTT9"
  },
  {
    "uniprot": "A4HSR1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSR1"
  },
  {
    "uniprot": "A4I8L3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8L3"
  },
  {
    "uniprot": "A4I6U8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6U8"
  },
  {
    "uniprot": "A4HT83",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT83"
  },
  {
    "uniprot": "A4I327",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I327"
  },
  {
    "uniprot": "A4I1N2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1N2"
  },
  {
    "uniprot": "A4HSQ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSQ0"
  },
  {
    "uniprot": "A4I1Q5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Q5"
  },
  {
    "uniprot": "A4I6W9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6W9"
  },
  {
    "uniprot": "A4HSP5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSP5"
  },
  {
    "uniprot": "A4HXB9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXB9"
  },
  {
    "uniprot": "A4HVJ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVJ3"
  },
  {
    "uniprot": "A4I1X5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1X5"
  },
  {
    "uniprot": "A4IBH1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBH1"
  },
  {
    "uniprot": "A4I4K9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4K9"
  },
  {
    "uniprot": "A4I1I4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1I4"
  },
  {
    "uniprot": "A4I7Y2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Y2"
  },
  {
    "uniprot": "E9AH89",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH89"
  },
  {
    "uniprot": "A4HUZ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUZ4"
  },
  {
    "uniprot": "A4I821",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I821"
  },
  {
    "uniprot": "A4I5T4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5T4"
  },
  {
    "uniprot": "A4IBN4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBN4"
  },
  {
    "uniprot": "A4HSH5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSH5"
  },
  {
    "uniprot": "A4I3H2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3H2"
  },
  {
    "uniprot": "A4HRL7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRL7"
  },
  {
    "uniprot": "A4HZ05",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ05"
  },
  {
    "uniprot": "E9AHI1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHI1"
  },
  {
    "uniprot": "A4HYG4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYG4"
  },
  {
    "uniprot": "E9AGI8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGI8"
  },
  {
    "uniprot": "A4I2H9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2H9"
  },
  {
    "uniprot": "A4HZH8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZH8"
  },
  {
    "uniprot": "A4IA65",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA65"
  },
  {
    "uniprot": "E9AHD5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHD5"
  },
  {
    "uniprot": "A4I0A7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0A7"
  },
  {
    "uniprot": "A4I9F0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9F0"
  },
  {
    "uniprot": "A4I6Z4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Z4"
  },
  {
    "uniprot": "A4I5W8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5W8"
  },
  {
    "uniprot": "E9AH34",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH34"
  },
  {
    "uniprot": "A4IAM1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAM1"
  },
  {
    "uniprot": "A4I0L5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0L5"
  },
  {
    "uniprot": "A0A381MU64",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MU64"
  },
  {
    "uniprot": "A4I3Q8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Q8"
  },
  {
    "uniprot": "E9AG80",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG80"
  },
  {
    "uniprot": "A4IB20",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB20"
  },
  {
    "uniprot": "A4HSQ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSQ8"
  },
  {
    "uniprot": "A4I4K7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4K7"
  },
  {
    "uniprot": "A4I209",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I209"
  },
  {
    "uniprot": "A4ICP5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICP5"
  },
  {
    "uniprot": "A4I0I5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0I5"
  },
  {
    "uniprot": "A4IBG4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBG4"
  },
  {
    "uniprot": "A4HVX6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVX6"
  },
  {
    "uniprot": "A0A381ME06",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381ME06"
  },
  {
    "uniprot": "A4HV17",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV17"
  },
  {
    "uniprot": "A4I0Q6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Q6"
  },
  {
    "uniprot": "A4I786",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I786"
  },
  {
    "uniprot": "A4IDV3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDV3"
  },
  {
    "uniprot": "A4HUH9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUH9"
  },
  {
    "uniprot": "A4I8K6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8K6"
  },
  {
    "uniprot": "A4HW32",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW32"
  },
  {
    "uniprot": "A4I0M3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0M3"
  },
  {
    "uniprot": "A4I440",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I440"
  },
  {
    "uniprot": "A4I1E8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1E8"
  },
  {
    "uniprot": "A4I863",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I863"
  },
  {
    "uniprot": "A4ID95",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID95"
  },
  {
    "uniprot": "A4IBY6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBY6"
  },
  {
    "uniprot": "A4I226",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I226"
  },
  {
    "uniprot": "A4I6G7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6G7"
  },
  {
    "uniprot": "A4I1R3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1R3"
  },
  {
    "uniprot": "A4IDS2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDS2"
  },
  {
    "uniprot": "A4HZU8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZU8"
  },
  {
    "uniprot": "A4IDS8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDS8"
  },
  {
    "uniprot": "A4HTK4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTK4"
  },
  {
    "uniprot": "A4I1Y2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Y2"
  },
  {
    "uniprot": "A4I2X7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2X7"
  },
  {
    "uniprot": "A4I896",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I896"
  },
  {
    "uniprot": "A4I0P5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0P5"
  },
  {
    "uniprot": "E9AGL9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGL9"
  },
  {
    "uniprot": "A4HW76",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW76"
  },
  {
    "uniprot": "A4IDJ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDJ5"
  },
  {
    "uniprot": "A4HTX5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTX5"
  },
  {
    "uniprot": "A4I9E7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9E7"
  },
  {
    "uniprot": "A4HYJ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYJ6"
  },
  {
    "uniprot": "A4IDN7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDN7"
  },
  {
    "uniprot": "A4I5G2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5G2"
  },
  {
    "uniprot": "A4I0Y7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Y7"
  },
  {
    "uniprot": "A4HUF8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUF8"
  },
  {
    "uniprot": "A4I6B5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6B5"
  },
  {
    "uniprot": "A4I9Y1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Y1"
  },
  {
    "uniprot": "A4I018",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I018"
  },
  {
    "uniprot": "A4I2L5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2L5"
  },
  {
    "uniprot": "A4HRT8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRT8"
  },
  {
    "uniprot": "A4I0R0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0R0"
  },
  {
    "uniprot": "A4IE55",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE55"
  },
  {
    "uniprot": "A4HS01",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS01"
  },
  {
    "uniprot": "A4IAF6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAF6"
  },
  {
    "uniprot": "A4HTJ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTJ1"
  },
  {
    "uniprot": "A4HSC8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSC8"
  },
  {
    "uniprot": "E9AGL2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGL2"
  },
  {
    "uniprot": "E9AHV8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHV8"
  },
  {
    "uniprot": "A4IAA2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAA2"
  },
  {
    "uniprot": "A4HZR6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZR6"
  },
  {
    "uniprot": "A4IAN0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAN0"
  },
  {
    "uniprot": "A4I7U1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7U1"
  },
  {
    "uniprot": "A4I1Y4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Y4"
  },
  {
    "uniprot": "A4IDP9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDP9"
  },
  {
    "uniprot": "A4HRW5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRW5"
  },
  {
    "uniprot": "A4I610",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I610"
  },
  {
    "uniprot": "A4ICF9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICF9"
  },
  {
    "uniprot": "A4I7J3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7J3"
  },
  {
    "uniprot": "A4HYR0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYR0"
  },
  {
    "uniprot": "A4HYC5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYC5"
  },
  {
    "uniprot": "A4I3G4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3G4"
  },
  {
    "uniprot": "A4HUL2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUL2"
  },
  {
    "uniprot": "A4HWN2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWN2"
  },
  {
    "uniprot": "A4ICA7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICA7"
  },
  {
    "uniprot": "A4I8A4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8A4"
  },
  {
    "uniprot": "E9AH96",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH96"
  },
  {
    "uniprot": "A4I053",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I053"
  },
  {
    "uniprot": "A4HX70",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX70"
  },
  {
    "uniprot": "A4HSN3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSN3"
  },
  {
    "uniprot": "E9AGE7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGE7"
  },
  {
    "uniprot": "A4HTW4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTW4"
  },
  {
    "uniprot": "A4IA93",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA93"
  },
  {
    "uniprot": "E9AHW4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHW4"
  },
  {
    "uniprot": "A4HV44",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV44"
  },
  {
    "uniprot": "A4ICC4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICC4"
  },
  {
    "uniprot": "A4IB78",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB78"
  },
  {
    "uniprot": "A4I0P9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0P9"
  },
  {
    "uniprot": "A4HRN2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRN2"
  },
  {
    "uniprot": "A4I7Y4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Y4"
  },
  {
    "uniprot": "A4I4M9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4M9"
  },
  {
    "uniprot": "A4HTU2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTU2"
  },
  {
    "uniprot": "A4I600",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I600"
  },
  {
    "uniprot": "E9AG98",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG98"
  },
  {
    "uniprot": "A4HU02",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU02"
  },
  {
    "uniprot": "A4ID52",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID52"
  },
  {
    "uniprot": "A4I6Y9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Y9"
  },
  {
    "uniprot": "A4I3B9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3B9"
  },
  {
    "uniprot": "A4I2W6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2W6"
  },
  {
    "uniprot": "A4HVK7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVK7"
  },
  {
    "uniprot": "E9AH54",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH54"
  },
  {
    "uniprot": "A4I171",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I171"
  },
  {
    "uniprot": "Q8I496",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q8I496"
  },
  {
    "uniprot": "A4I7P4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7P4"
  },
  {
    "uniprot": "A4HS34",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS34"
  },
  {
    "uniprot": "A4HWZ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWZ4"
  },
  {
    "uniprot": "A4I7R8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7R8"
  },
  {
    "uniprot": "A4I7Z8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Z8"
  },
  {
    "uniprot": "E9AHK9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHK9"
  },
  {
    "uniprot": "A4ICX8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICX8"
  },
  {
    "uniprot": "A4I8H5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8H5"
  },
  {
    "uniprot": "A4HX62",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX62"
  },
  {
    "uniprot": "A4HYV1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYV1"
  },
  {
    "uniprot": "A4IBP5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBP5"
  },
  {
    "uniprot": "A4HXG0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXG0"
  },
  {
    "uniprot": "E9AGQ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGQ4"
  },
  {
    "uniprot": "A4I6B3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6B3"
  },
  {
    "uniprot": "A4IB90",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB90"
  },
  {
    "uniprot": "A4I425",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I425"
  },
  {
    "uniprot": "A4I9V6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9V6"
  },
  {
    "uniprot": "A4IDF3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDF3"
  },
  {
    "uniprot": "A4I2Z4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Z4"
  },
  {
    "uniprot": "A4HXE0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXE0"
  },
  {
    "uniprot": "A4I8G0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8G0"
  },
  {
    "uniprot": "A4I3M5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3M5"
  },
  {
    "uniprot": "A4ICQ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICQ1"
  },
  {
    "uniprot": "A4I3Q1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Q1"
  },
  {
    "uniprot": "A4I5I2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5I2"
  },
  {
    "uniprot": "A4I9D8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9D8"
  },
  {
    "uniprot": "A4IBG5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBG5"
  },
  {
    "uniprot": "A4HRS0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRS0"
  },
  {
    "uniprot": "A4I836",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I836"
  },
  {
    "uniprot": "E9AGQ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGQ3"
  },
  {
    "uniprot": "A4I0A4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0A4"
  },
  {
    "uniprot": "A4HTA3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTA3"
  },
  {
    "uniprot": "A4I1M3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1M3"
  },
  {
    "uniprot": "A4I1I0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1I0"
  },
  {
    "uniprot": "A4HVM1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVM1"
  },
  {
    "uniprot": "A4I073",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I073"
  },
  {
    "uniprot": "A4I0L3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0L3"
  },
  {
    "uniprot": "A4HZG8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZG8"
  },
  {
    "uniprot": "A4IBP0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBP0"
  },
  {
    "uniprot": "A4HX02",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX02"
  },
  {
    "uniprot": "A4I779",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I779"
  },
  {
    "uniprot": "A4I3F2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3F2"
  },
  {
    "uniprot": "A4I2I0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2I0"
  },
  {
    "uniprot": "A4HUC7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUC7"
  },
  {
    "uniprot": "A4I7M5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7M5"
  },
  {
    "uniprot": "A4HYQ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYQ9"
  },
  {
    "uniprot": "A4I0I6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0I6"
  },
  {
    "uniprot": "A4I7L0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7L0"
  },
  {
    "uniprot": "A4HT05",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT05"
  },
  {
    "uniprot": "A4IBR1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBR1"
  },
  {
    "uniprot": "A4I5Z0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Z0"
  },
  {
    "uniprot": "A4HWT4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWT4"
  },
  {
    "uniprot": "A4I1V3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1V3"
  },
  {
    "uniprot": "E9AHD4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHD4"
  },
  {
    "uniprot": "A4I3K4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3K4"
  },
  {
    "uniprot": "A4I1D4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1D4"
  },
  {
    "uniprot": "A4HRN5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRN5"
  },
  {
    "uniprot": "A4I4K6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4K6"
  },
  {
    "uniprot": "A4I217",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I217"
  },
  {
    "uniprot": "A4I3M1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3M1"
  },
  {
    "uniprot": "A4HUL8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUL8"
  },
  {
    "uniprot": "E9AHN5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHN5"
  },
  {
    "uniprot": "A4HTG5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTG5"
  },
  {
    "uniprot": "A4HXU1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXU1"
  },
  {
    "uniprot": "A4I7R1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7R1"
  },
  {
    "uniprot": "A4HT07",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT07"
  },
  {
    "uniprot": "A4HRW7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRW7"
  },
  {
    "uniprot": "A4IDX8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDX8"
  },
  {
    "uniprot": "A4I7N5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7N5"
  },
  {
    "uniprot": "A4I6Q1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Q1"
  },
  {
    "uniprot": "E9AHE8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHE8"
  },
  {
    "uniprot": "A4HRP6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRP6"
  },
  {
    "uniprot": "A4I0V2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0V2"
  },
  {
    "uniprot": "A4IDX6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDX6"
  },
  {
    "uniprot": "A4HTD5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTD5"
  },
  {
    "uniprot": "A4HRS8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRS8"
  },
  {
    "uniprot": "A4IAZ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAZ0"
  },
  {
    "uniprot": "A4HX50",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX50"
  },
  {
    "uniprot": "A4I4U8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4U8"
  },
  {
    "uniprot": "A4I2G9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2G9"
  },
  {
    "uniprot": "A4IE54",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE54"
  },
  {
    "uniprot": "A4IBL4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBL4"
  },
  {
    "uniprot": "A4HUN5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUN5"
  },
  {
    "uniprot": "A4HSF5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSF5"
  },
  {
    "uniprot": "A4I6L4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6L4"
  },
  {
    "uniprot": "A4I5L7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5L7"
  },
  {
    "uniprot": "A4HW87",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW87"
  },
  {
    "uniprot": "A4IC76",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC76"
  },
  {
    "uniprot": "A4I7S2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7S2"
  },
  {
    "uniprot": "A4I8W0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8W0"
  },
  {
    "uniprot": "A4I0X3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0X3"
  },
  {
    "uniprot": "A4I266",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I266"
  },
  {
    "uniprot": "A4I5E1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5E1"
  },
  {
    "uniprot": "A4IAJ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAJ5"
  },
  {
    "uniprot": "A4HWM7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWM7"
  },
  {
    "uniprot": "A4HUX2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUX2"
  },
  {
    "uniprot": "A4I968",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I968"
  },
  {
    "uniprot": "A4HYG7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYG7"
  },
  {
    "uniprot": "A4HWH1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWH1"
  },
  {
    "uniprot": "A4I506",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I506"
  },
  {
    "uniprot": "A4I163",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I163"
  },
  {
    "uniprot": "E9AGY6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGY6"
  },
  {
    "uniprot": "A4I887",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I887"
  },
  {
    "uniprot": "A4HTJ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTJ5"
  },
  {
    "uniprot": "A4I9A8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9A8"
  },
  {
    "uniprot": "A4I3J4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3J4"
  },
  {
    "uniprot": "A4IAT0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAT0"
  },
  {
    "uniprot": "A4I9C1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9C1"
  },
  {
    "uniprot": "A4IDY9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDY9"
  },
  {
    "uniprot": "A4I9L3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9L3"
  },
  {
    "uniprot": "A4IAX3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAX3"
  },
  {
    "uniprot": "A4I7N0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7N0"
  },
  {
    "uniprot": "A4HZB8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZB8"
  },
  {
    "uniprot": "A4I7Q8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Q8"
  },
  {
    "uniprot": "A4IAB5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAB5"
  },
  {
    "uniprot": "A4I8V7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8V7"
  },
  {
    "uniprot": "A4HUL6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUL6"
  },
  {
    "uniprot": "A4ID10",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID10"
  },
  {
    "uniprot": "A4ICA3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICA3"
  },
  {
    "uniprot": "A4I8D0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8D0"
  },
  {
    "uniprot": "A4HZX6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZX6"
  },
  {
    "uniprot": "A4HW46",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW46"
  },
  {
    "uniprot": "A0A381MCS3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MCS3"
  },
  {
    "uniprot": "A4I8U2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8U2"
  },
  {
    "uniprot": "A4I6M4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6M4"
  },
  {
    "uniprot": "A4I593",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I593"
  },
  {
    "uniprot": "A4I0A9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0A9"
  },
  {
    "uniprot": "A4I4L4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4L4"
  },
  {
    "uniprot": "A4HT42",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT42"
  },
  {
    "uniprot": "A4I790",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I790"
  },
  {
    "uniprot": "A4I3G2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3G2"
  },
  {
    "uniprot": "A4HVQ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVQ9"
  },
  {
    "uniprot": "A4I3P1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3P1"
  },
  {
    "uniprot": "E9AGT5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGT5"
  },
  {
    "uniprot": "A4I645",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I645"
  },
  {
    "uniprot": "A4HVE9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVE9"
  },
  {
    "uniprot": "A4I4C9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4C9"
  },
  {
    "uniprot": "A4HUR6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUR6"
  },
  {
    "uniprot": "A4I2F1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2F1"
  },
  {
    "uniprot": "A4I833",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I833"
  },
  {
    "uniprot": "A4I017",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I017"
  },
  {
    "uniprot": "A4HVF0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVF0"
  },
  {
    "uniprot": "E9AH39",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH39"
  },
  {
    "uniprot": "A4I0M7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0M7"
  },
  {
    "uniprot": "A4HUV2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUV2"
  },
  {
    "uniprot": "A4I8P6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8P6"
  },
  {
    "uniprot": "A4ICJ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICJ1"
  },
  {
    "uniprot": "A4HUI0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUI0"
  },
  {
    "uniprot": "A4HWL1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWL1"
  },
  {
    "uniprot": "E9AGD8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGD8"
  },
  {
    "uniprot": "A4I2S4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2S4"
  },
  {
    "uniprot": "A4IDQ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDQ3"
  },
  {
    "uniprot": "A4I2I1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2I1"
  },
  {
    "uniprot": "A4I429",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I429"
  },
  {
    "uniprot": "E9AHG7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHG7"
  },
  {
    "uniprot": "A4I080",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I080"
  },
  {
    "uniprot": "A4HU56",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU56"
  },
  {
    "uniprot": "A4ICN1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICN1"
  },
  {
    "uniprot": "A0A381MBS1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MBS1"
  },
  {
    "uniprot": "A4HTC1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTC1"
  },
  {
    "uniprot": "E9AH57",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH57"
  },
  {
    "uniprot": "A4HTZ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTZ2"
  },
  {
    "uniprot": "A4I0Z4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Z4"
  },
  {
    "uniprot": "A4HWB4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWB4"
  },
  {
    "uniprot": "A4HT28",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT28"
  },
  {
    "uniprot": "A4HRV1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRV1"
  },
  {
    "uniprot": "E8NHD1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E8NHD1"
  },
  {
    "uniprot": "E9AHX8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHX8"
  },
  {
    "uniprot": "A4I503",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I503"
  },
  {
    "uniprot": "A4I8M3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8M3"
  },
  {
    "uniprot": "A4I0X7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0X7"
  },
  {
    "uniprot": "A4IC09",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC09"
  },
  {
    "uniprot": "A4I3M7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3M7"
  },
  {
    "uniprot": "E9AHR8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHR8"
  },
  {
    "uniprot": "A4HTH0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTH0"
  },
  {
    "uniprot": "A4IBF6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBF6"
  },
  {
    "uniprot": "A4I4L5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4L5"
  },
  {
    "uniprot": "A4HZH1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZH1"
  },
  {
    "uniprot": "A4I9R8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9R8"
  },
  {
    "uniprot": "A4HUD4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUD4"
  },
  {
    "uniprot": "A4I9H5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9H5"
  },
  {
    "uniprot": "A4HXK6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXK6"
  },
  {
    "uniprot": "A4ICK8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICK8"
  },
  {
    "uniprot": "A4I2B8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2B8"
  },
  {
    "uniprot": "A4HXG5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXG5"
  },
  {
    "uniprot": "A4I0J4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0J4"
  },
  {
    "uniprot": "A4IBV4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBV4"
  },
  {
    "uniprot": "A4I7Q5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Q5"
  },
  {
    "uniprot": "A4IDZ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDZ7"
  },
  {
    "uniprot": "A4HY91",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY91"
  },
  {
    "uniprot": "A4I9W4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9W4"
  },
  {
    "uniprot": "E9AGJ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGJ2"
  },
  {
    "uniprot": "A4I9P8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9P8"
  },
  {
    "uniprot": "A4I7S3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7S3"
  },
  {
    "uniprot": "A4I9Q6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Q6"
  },
  {
    "uniprot": "A4IDW5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDW5"
  },
  {
    "uniprot": "A0A381M9M8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381M9M8"
  },
  {
    "uniprot": "A4HWE4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWE4"
  },
  {
    "uniprot": "A4IC38",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC38"
  },
  {
    "uniprot": "A4I9R9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9R9"
  },
  {
    "uniprot": "A4HUN8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUN8"
  },
  {
    "uniprot": "A4I165",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I165"
  },
  {
    "uniprot": "A4HVM3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVM3"
  },
  {
    "uniprot": "A4HXG4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXG4"
  },
  {
    "uniprot": "A4HZ93",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ93"
  },
  {
    "uniprot": "A4I9S0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9S0"
  },
  {
    "uniprot": "A4I5I0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5I0"
  },
  {
    "uniprot": "A4IBJ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBJ0"
  },
  {
    "uniprot": "A4I8U8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8U8"
  },
  {
    "uniprot": "A4HT67",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT67"
  },
  {
    "uniprot": "A4IC63",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC63"
  },
  {
    "uniprot": "A4HZI5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZI5"
  },
  {
    "uniprot": "A4HX55",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX55"
  },
  {
    "uniprot": "A4IA30",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA30"
  },
  {
    "uniprot": "A4IBC2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBC2"
  },
  {
    "uniprot": "A4HX97",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX97"
  },
  {
    "uniprot": "A4I8V2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8V2"
  },
  {
    "uniprot": "A4ICI0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICI0"
  },
  {
    "uniprot": "A4HVW4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVW4"
  },
  {
    "uniprot": "A4HZW6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZW6"
  },
  {
    "uniprot": "A4I2P1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2P1"
  },
  {
    "uniprot": "A4HTA4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTA4"
  },
  {
    "uniprot": "A4HWZ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWZ0"
  },
  {
    "uniprot": "E9AGB7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGB7"
  },
  {
    "uniprot": "A4HYX4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYX4"
  },
  {
    "uniprot": "A4I3L3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3L3"
  },
  {
    "uniprot": "A4HT01",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT01"
  },
  {
    "uniprot": "A4HVU6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVU6"
  },
  {
    "uniprot": "A4I1M1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1M1"
  },
  {
    "uniprot": "A4HYD4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYD4"
  },
  {
    "uniprot": "E9AHV3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHV3"
  },
  {
    "uniprot": "A4I0T9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0T9"
  },
  {
    "uniprot": "A4I444",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I444"
  },
  {
    "uniprot": "A4ICB2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICB2"
  },
  {
    "uniprot": "A4I1S9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1S9"
  },
  {
    "uniprot": "A4I5S3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5S3"
  },
  {
    "uniprot": "A4HWT7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWT7"
  },
  {
    "uniprot": "A4HZQ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZQ6"
  },
  {
    "uniprot": "A4HSL7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSL7"
  },
  {
    "uniprot": "A4I6W4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6W4"
  },
  {
    "uniprot": "A4IAF5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAF5"
  },
  {
    "uniprot": "A4I1H2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1H2"
  },
  {
    "uniprot": "E9AH31",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH31"
  },
  {
    "uniprot": "A4HW52",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW52"
  },
  {
    "uniprot": "A4I431",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I431"
  },
  {
    "uniprot": "A4HW17",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW17"
  },
  {
    "uniprot": "A4HX17",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX17"
  },
  {
    "uniprot": "A4I2K0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2K0"
  },
  {
    "uniprot": "A4I866",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I866"
  },
  {
    "uniprot": "A4I0C4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0C4"
  },
  {
    "uniprot": "A4HZP7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZP7"
  },
  {
    "uniprot": "A4HXY8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXY8"
  },
  {
    "uniprot": "A4I1M7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1M7"
  },
  {
    "uniprot": "A4HX42",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX42"
  },
  {
    "uniprot": "A4I1W0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1W0"
  },
  {
    "uniprot": "A4HWC1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWC1"
  },
  {
    "uniprot": "A4I294",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I294"
  },
  {
    "uniprot": "A4HTA2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTA2"
  },
  {
    "uniprot": "A4ICS3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICS3"
  },
  {
    "uniprot": "A4I7Z6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Z6"
  },
  {
    "uniprot": "A4HRP2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRP2"
  },
  {
    "uniprot": "E9AH00",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH00"
  },
  {
    "uniprot": "A4IDP4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDP4"
  },
  {
    "uniprot": "A4HXX4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXX4"
  },
  {
    "uniprot": "A4IDA7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDA7"
  },
  {
    "uniprot": "A4I0D4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0D4"
  },
  {
    "uniprot": "A4I7Q4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Q4"
  },
  {
    "uniprot": "A4I7Y9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Y9"
  },
  {
    "uniprot": "A4I758",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I758"
  },
  {
    "uniprot": "A4IA89",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA89"
  },
  {
    "uniprot": "A4I6H8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6H8"
  },
  {
    "uniprot": "A4I3T8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3T8"
  },
  {
    "uniprot": "A4HTR7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTR7"
  },
  {
    "uniprot": "A4HT15",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT15"
  },
  {
    "uniprot": "A4ICQ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICQ6"
  },
  {
    "uniprot": "A4ID93",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID93"
  },
  {
    "uniprot": "A4I3F3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3F3"
  },
  {
    "uniprot": "A4I3E8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3E8"
  },
  {
    "uniprot": "A4I4N9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4N9"
  },
  {
    "uniprot": "A4HRK8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRK8"
  },
  {
    "uniprot": "A4HSX5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSX5"
  },
  {
    "uniprot": "A4IDV4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDV4"
  },
  {
    "uniprot": "A4I9Y5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Y5"
  },
  {
    "uniprot": "E9AHE7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHE7"
  },
  {
    "uniprot": "A4HT49",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT49"
  },
  {
    "uniprot": "A4I3W9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3W9"
  },
  {
    "uniprot": "A4I628",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I628"
  },
  {
    "uniprot": "A4HSK6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSK6"
  },
  {
    "uniprot": "A4I943",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I943"
  },
  {
    "uniprot": "Q6RYT2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q6RYT2"
  },
  {
    "uniprot": "A4I0E8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0E8"
  },
  {
    "uniprot": "A4I0G9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0G9"
  },
  {
    "uniprot": "A4HV52",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV52"
  },
  {
    "uniprot": "A4HWR0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWR0"
  },
  {
    "uniprot": "A4I1X1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1X1"
  },
  {
    "uniprot": "A4I1F7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1F7"
  },
  {
    "uniprot": "A4I1B6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1B6"
  },
  {
    "uniprot": "A4ID09",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID09"
  },
  {
    "uniprot": "A4IE60",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE60"
  },
  {
    "uniprot": "A4IB54",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB54"
  },
  {
    "uniprot": "A4HXM4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXM4"
  },
  {
    "uniprot": "A4HZQ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZQ8"
  },
  {
    "uniprot": "A4I6N5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6N5"
  },
  {
    "uniprot": "A4IAC1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAC1"
  },
  {
    "uniprot": "A4I384",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I384"
  },
  {
    "uniprot": "A4ICW6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICW6"
  },
  {
    "uniprot": "E9AGK0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGK0"
  },
  {
    "uniprot": "A4ICM9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICM9"
  },
  {
    "uniprot": "A4HUE2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUE2"
  },
  {
    "uniprot": "A4HU12",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU12"
  },
  {
    "uniprot": "A4HVN1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVN1"
  },
  {
    "uniprot": "A4I5J4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5J4"
  },
  {
    "uniprot": "A4ICW8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICW8"
  },
  {
    "uniprot": "A4I7K1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7K1"
  },
  {
    "uniprot": "A4ID42",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID42"
  },
  {
    "uniprot": "A4HSA3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSA3"
  },
  {
    "uniprot": "A4HSY2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSY2"
  },
  {
    "uniprot": "A4I6G3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6G3"
  },
  {
    "uniprot": "A4HTW2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTW2"
  },
  {
    "uniprot": "A4IBQ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBQ8"
  },
  {
    "uniprot": "A4HZP8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZP8"
  },
  {
    "uniprot": "A4HUD5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUD5"
  },
  {
    "uniprot": "A4I0B2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0B2"
  },
  {
    "uniprot": "A4IE44",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE44"
  },
  {
    "uniprot": "A4IAG9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAG9"
  },
  {
    "uniprot": "A4HXT8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXT8"
  },
  {
    "uniprot": "A4I928",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I928"
  },
  {
    "uniprot": "A4HX08",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX08"
  },
  {
    "uniprot": "A4I3K3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3K3"
  },
  {
    "uniprot": "A4IAR3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAR3"
  },
  {
    "uniprot": "A4IBB3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBB3"
  },
  {
    "uniprot": "A4HTS5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTS5"
  },
  {
    "uniprot": "A4IE58",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE58"
  },
  {
    "uniprot": "A4I997",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I997"
  },
  {
    "uniprot": "E8NHC5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E8NHC5"
  },
  {
    "uniprot": "A4IBU0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBU0"
  },
  {
    "uniprot": "A4I2F6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2F6"
  },
  {
    "uniprot": "A4ID29",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID29"
  },
  {
    "uniprot": "A4IC08",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC08"
  },
  {
    "uniprot": "A4IAA0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAA0"
  },
  {
    "uniprot": "A4HYI9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYI9"
  },
  {
    "uniprot": "A4I0R2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0R2"
  },
  {
    "uniprot": "A4HXT4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXT4"
  },
  {
    "uniprot": "A4I3R0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3R0"
  },
  {
    "uniprot": "A4I155",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I155"
  },
  {
    "uniprot": "A4HUY7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUY7"
  },
  {
    "uniprot": "E9AGF6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGF6"
  },
  {
    "uniprot": "A4I086",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I086"
  },
  {
    "uniprot": "A4IAW7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAW7"
  },
  {
    "uniprot": "A4I4A0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4A0"
  },
  {
    "uniprot": "A4I6X5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6X5"
  },
  {
    "uniprot": "A4I6A0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6A0"
  },
  {
    "uniprot": "A4HSL2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSL2"
  },
  {
    "uniprot": "A4HVG0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVG0"
  },
  {
    "uniprot": "A4HS56",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS56"
  },
  {
    "uniprot": "A4HUG6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUG6"
  },
  {
    "uniprot": "A4HWZ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWZ2"
  },
  {
    "uniprot": "A4HWY5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWY5"
  },
  {
    "uniprot": "A4HWC3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWC3"
  },
  {
    "uniprot": "A4HSZ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSZ6"
  },
  {
    "uniprot": "A4I342",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I342"
  },
  {
    "uniprot": "A4I8U0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8U0"
  },
  {
    "uniprot": "A4I8W1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8W1"
  },
  {
    "uniprot": "A4I5R6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5R6"
  },
  {
    "uniprot": "A4HTK1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTK1"
  },
  {
    "uniprot": "A4I1E2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1E2"
  },
  {
    "uniprot": "A4I3U4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3U4"
  },
  {
    "uniprot": "A4I1D6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1D6"
  },
  {
    "uniprot": "A4I3C5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3C5"
  },
  {
    "uniprot": "E9AGD1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGD1"
  },
  {
    "uniprot": "A4IBU9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBU9"
  },
  {
    "uniprot": "A4I272",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I272"
  },
  {
    "uniprot": "A4I7I4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7I4"
  },
  {
    "uniprot": "A4I2V8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2V8"
  },
  {
    "uniprot": "A4HYP2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYP2"
  },
  {
    "uniprot": "A4IE56",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE56"
  },
  {
    "uniprot": "E9AHT1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHT1"
  },
  {
    "uniprot": "A4I1P5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1P5"
  },
  {
    "uniprot": "A4IDQ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDQ5"
  },
  {
    "uniprot": "A4HYS0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYS0"
  },
  {
    "uniprot": "A4I2W7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2W7"
  },
  {
    "uniprot": "A4I906",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I906"
  },
  {
    "uniprot": "A4I285",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I285"
  },
  {
    "uniprot": "A4I9T5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9T5"
  },
  {
    "uniprot": "A4HS88",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS88"
  },
  {
    "uniprot": "A4IDH8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDH8"
  },
  {
    "uniprot": "A4HW34",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW34"
  },
  {
    "uniprot": "A4HWZ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWZ5"
  },
  {
    "uniprot": "A4I956",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I956"
  },
  {
    "uniprot": "A4IAA7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAA7"
  },
  {
    "uniprot": "A4ID13",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID13"
  },
  {
    "uniprot": "A4HZ82",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ82"
  },
  {
    "uniprot": "A4HSN7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSN7"
  },
  {
    "uniprot": "A4I1J6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1J6"
  },
  {
    "uniprot": "A4I6H4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6H4"
  },
  {
    "uniprot": "A4HZG7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZG7"
  },
  {
    "uniprot": "A4HYI8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYI8"
  },
  {
    "uniprot": "A4IDK2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDK2"
  },
  {
    "uniprot": "A4I9J2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9J2"
  },
  {
    "uniprot": "A4HUH6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUH6"
  },
  {
    "uniprot": "A4HRE8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRE8"
  },
  {
    "uniprot": "A4I8E4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8E4"
  },
  {
    "uniprot": "A4HW88",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW88"
  },
  {
    "uniprot": "A4I5F4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5F4"
  },
  {
    "uniprot": "A4HSV1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSV1"
  },
  {
    "uniprot": "A4HSU0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSU0"
  },
  {
    "uniprot": "A4HXA6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXA6"
  },
  {
    "uniprot": "A4I4P4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4P4"
  },
  {
    "uniprot": "A4IAK6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAK6"
  },
  {
    "uniprot": "A4I6R4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6R4"
  },
  {
    "uniprot": "E9AHS6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHS6"
  },
  {
    "uniprot": "A4HUV8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUV8"
  },
  {
    "uniprot": "A4HXU5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXU5"
  },
  {
    "uniprot": "A4HWE5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWE5"
  },
  {
    "uniprot": "A4I9I5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9I5"
  },
  {
    "uniprot": "A4HXL0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXL0"
  },
  {
    "uniprot": "A4I112",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I112"
  },
  {
    "uniprot": "A4HUT7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUT7"
  },
  {
    "uniprot": "A4IBW8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBW8"
  },
  {
    "uniprot": "A4I8Z1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Z1"
  },
  {
    "uniprot": "A4I6S9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6S9"
  },
  {
    "uniprot": "A4I8C0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8C0"
  },
  {
    "uniprot": "A4I0Z7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Z7"
  },
  {
    "uniprot": "A4HSI8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSI8"
  },
  {
    "uniprot": "E9AHV5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHV5"
  },
  {
    "uniprot": "A4IB50",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB50"
  },
  {
    "uniprot": "A4I7H2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7H2"
  },
  {
    "uniprot": "A4IC00",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC00"
  },
  {
    "uniprot": "A4I999",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I999"
  },
  {
    "uniprot": "A4HRW8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRW8"
  },
  {
    "uniprot": "A4I8P7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8P7"
  },
  {
    "uniprot": "A4I2K4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2K4"
  },
  {
    "uniprot": "A4IBG6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBG6"
  },
  {
    "uniprot": "A4IDU5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDU5"
  },
  {
    "uniprot": "A4HRU6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRU6"
  },
  {
    "uniprot": "A4ID91",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID91"
  },
  {
    "uniprot": "A4HZL6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZL6"
  },
  {
    "uniprot": "A4I931",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I931"
  },
  {
    "uniprot": "A4HSU1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSU1"
  },
  {
    "uniprot": "A4HV00",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV00"
  },
  {
    "uniprot": "E9AGW6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGW6"
  },
  {
    "uniprot": "A4I7H7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7H7"
  },
  {
    "uniprot": "A4HX45",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX45"
  },
  {
    "uniprot": "A4I108",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I108"
  },
  {
    "uniprot": "A4I1Y5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Y5"
  },
  {
    "uniprot": "A4I3Z2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Z2"
  },
  {
    "uniprot": "A4I780",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I780"
  },
  {
    "uniprot": "A0A381MCF0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MCF0"
  },
  {
    "uniprot": "A4I193",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I193"
  },
  {
    "uniprot": "A4I393",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I393"
  },
  {
    "uniprot": "A4I6H9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6H9"
  },
  {
    "uniprot": "A4I1D0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1D0"
  },
  {
    "uniprot": "A4I6B0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6B0"
  },
  {
    "uniprot": "A4HY14",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY14"
  },
  {
    "uniprot": "A4IDG3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDG3"
  },
  {
    "uniprot": "A4IB87",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB87"
  },
  {
    "uniprot": "A4I2Y4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Y4"
  },
  {
    "uniprot": "A4I5Q8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Q8"
  },
  {
    "uniprot": "A4HZ16",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ16"
  },
  {
    "uniprot": "A4HZH3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZH3"
  },
  {
    "uniprot": "A4HY70",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY70"
  },
  {
    "uniprot": "A4I9V1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9V1"
  },
  {
    "uniprot": "A4HYX6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYX6"
  },
  {
    "uniprot": "A4IA86",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA86"
  },
  {
    "uniprot": "E9AGA7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGA7"
  },
  {
    "uniprot": "A4I5U0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5U0"
  },
  {
    "uniprot": "A4I7H9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7H9"
  },
  {
    "uniprot": "A4HTI6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTI6"
  },
  {
    "uniprot": "A4ICH6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICH6"
  },
  {
    "uniprot": "E9AGZ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGZ0"
  },
  {
    "uniprot": "A4HZS2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZS2"
  },
  {
    "uniprot": "E8NHD0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E8NHD0"
  },
  {
    "uniprot": "A4I1Y8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Y8"
  },
  {
    "uniprot": "A4HY62",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY62"
  },
  {
    "uniprot": "A4HXD5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXD5"
  },
  {
    "uniprot": "A4I8C4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8C4"
  },
  {
    "uniprot": "A4I7H5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7H5"
  },
  {
    "uniprot": "A4IBP3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBP3"
  },
  {
    "uniprot": "A4IAF4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAF4"
  },
  {
    "uniprot": "A4I315",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I315"
  },
  {
    "uniprot": "E9AH88",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH88"
  },
  {
    "uniprot": "A4I5N4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5N4"
  },
  {
    "uniprot": "A4I3B6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3B6"
  },
  {
    "uniprot": "A4I060",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I060"
  },
  {
    "uniprot": "A4IC32",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC32"
  },
  {
    "uniprot": "A4HWM8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWM8"
  },
  {
    "uniprot": "A4I3X1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3X1"
  },
  {
    "uniprot": "A4HST8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HST8"
  },
  {
    "uniprot": "A4ICI5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICI5"
  },
  {
    "uniprot": "A4HVX5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVX5"
  },
  {
    "uniprot": "A4I7H0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7H0"
  },
  {
    "uniprot": "A4HWB7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWB7"
  },
  {
    "uniprot": "A4I184",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I184"
  },
  {
    "uniprot": "A4HTF4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTF4"
  },
  {
    "uniprot": "A4HXI8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXI8"
  },
  {
    "uniprot": "A4HVT0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVT0"
  },
  {
    "uniprot": "A4I7T8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7T8"
  },
  {
    "uniprot": "A4ICX0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICX0"
  },
  {
    "uniprot": "A4I2S5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2S5"
  },
  {
    "uniprot": "A4HXL4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXL4"
  },
  {
    "uniprot": "A4HZY7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZY7"
  },
  {
    "uniprot": "A4HTC9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTC9"
  },
  {
    "uniprot": "A4I6U5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6U5"
  },
  {
    "uniprot": "A4IBX2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBX2"
  },
  {
    "uniprot": "A4I154",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I154"
  },
  {
    "uniprot": "A4I2Q5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Q5"
  },
  {
    "uniprot": "A4HXR4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXR4"
  },
  {
    "uniprot": "A4HWM1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWM1"
  },
  {
    "uniprot": "A4HT38",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT38"
  },
  {
    "uniprot": "A4IBU2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBU2"
  },
  {
    "uniprot": "A4HX63",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX63"
  },
  {
    "uniprot": "A4I1Z4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Z4"
  },
  {
    "uniprot": "A4I5Q5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Q5"
  },
  {
    "uniprot": "A4I1N1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1N1"
  },
  {
    "uniprot": "A4HXZ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXZ2"
  },
  {
    "uniprot": "A2CIG5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A2CIG5"
  },
  {
    "uniprot": "A4HUR0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUR0"
  },
  {
    "uniprot": "A4I351",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I351"
  },
  {
    "uniprot": "E9AGT9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGT9"
  },
  {
    "uniprot": "A4I3J6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3J6"
  },
  {
    "uniprot": "A4I520",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I520"
  },
  {
    "uniprot": "A4IA26",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA26"
  },
  {
    "uniprot": "A4HU61",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU61"
  },
  {
    "uniprot": "A4IDQ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDQ0"
  },
  {
    "uniprot": "A4HXH5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXH5"
  },
  {
    "uniprot": "A4IAT3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAT3"
  },
  {
    "uniprot": "A4HZT7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZT7"
  },
  {
    "uniprot": "A4HWM3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWM3"
  },
  {
    "uniprot": "A4I7N1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7N1"
  },
  {
    "uniprot": "A4I857",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I857"
  },
  {
    "uniprot": "A0A381MTB8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MTB8"
  },
  {
    "uniprot": "A4I9Z4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Z4"
  },
  {
    "uniprot": "A4HU57",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU57"
  },
  {
    "uniprot": "A4I4Q6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Q6"
  },
  {
    "uniprot": "A4I291",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I291"
  },
  {
    "uniprot": "A4I8A9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8A9"
  },
  {
    "uniprot": "A4IBP8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBP8"
  },
  {
    "uniprot": "A4HSZ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSZ2"
  },
  {
    "uniprot": "A4I4P6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4P6"
  },
  {
    "uniprot": "A4I802",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I802"
  },
  {
    "uniprot": "A4HXH0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXH0"
  },
  {
    "uniprot": "A4I9Q5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Q5"
  },
  {
    "uniprot": "A4I066",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I066"
  },
  {
    "uniprot": "A4I2M2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2M2"
  },
  {
    "uniprot": "A4HXW9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXW9"
  },
  {
    "uniprot": "A4I1L6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1L6"
  },
  {
    "uniprot": "A4I5R9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5R9"
  },
  {
    "uniprot": "A4HS81",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS81"
  },
  {
    "uniprot": "A4IA67",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA67"
  },
  {
    "uniprot": "A4HUD3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUD3"
  },
  {
    "uniprot": "A4I5L5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5L5"
  },
  {
    "uniprot": "E9AGT7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGT7"
  },
  {
    "uniprot": "A4HXE1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXE1"
  },
  {
    "uniprot": "A4I4H4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4H4"
  },
  {
    "uniprot": "E9AHJ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHJ8"
  },
  {
    "uniprot": "A4I9J4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9J4"
  },
  {
    "uniprot": "A4HWR8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWR8"
  },
  {
    "uniprot": "A4HU22",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU22"
  },
  {
    "uniprot": "A4IC22",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC22"
  },
  {
    "uniprot": "A4IDR0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDR0"
  },
  {
    "uniprot": "A4HYY3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYY3"
  },
  {
    "uniprot": "A4I967",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I967"
  },
  {
    "uniprot": "A4HYW2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYW2"
  },
  {
    "uniprot": "A4HWC8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWC8"
  },
  {
    "uniprot": "A4HVR6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVR6"
  },
  {
    "uniprot": "A4IDT5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDT5"
  },
  {
    "uniprot": "A4I6C0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6C0"
  },
  {
    "uniprot": "A4HV40",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV40"
  },
  {
    "uniprot": "A4HVT7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVT7"
  },
  {
    "uniprot": "A4HZ53",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ53"
  },
  {
    "uniprot": "A4I6U4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6U4"
  },
  {
    "uniprot": "A4I9R2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9R2"
  },
  {
    "uniprot": "E9AG17",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG17"
  },
  {
    "uniprot": "A4HS54",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS54"
  },
  {
    "uniprot": "A4HZP1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZP1"
  },
  {
    "uniprot": "A4I216",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I216"
  },
  {
    "uniprot": "A4HZZ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZZ1"
  },
  {
    "uniprot": "E9AHG2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHG2"
  },
  {
    "uniprot": "A4IDS0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDS0"
  },
  {
    "uniprot": "A4HY67",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY67"
  },
  {
    "uniprot": "A4I9U5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9U5"
  },
  {
    "uniprot": "A4HYW6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYW6"
  },
  {
    "uniprot": "A4I343",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I343"
  },
  {
    "uniprot": "A4HUN1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUN1"
  },
  {
    "uniprot": "E9AGF2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGF2"
  },
  {
    "uniprot": "A4I8A8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8A8"
  },
  {
    "uniprot": "A4I2Z0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Z0"
  },
  {
    "uniprot": "A4I7V3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7V3"
  },
  {
    "uniprot": "A4HXE2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXE2"
  },
  {
    "uniprot": "E9AHS2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHS2"
  },
  {
    "uniprot": "A4IDC8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDC8"
  },
  {
    "uniprot": "A4I7J6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7J6"
  },
  {
    "uniprot": "A0A381MTM9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MTM9"
  },
  {
    "uniprot": "A4HT86",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT86"
  },
  {
    "uniprot": "A4IAY6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAY6"
  },
  {
    "uniprot": "A4I631",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I631"
  },
  {
    "uniprot": "A4IB24",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB24"
  },
  {
    "uniprot": "A4I6W5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6W5"
  },
  {
    "uniprot": "E9AGE3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGE3"
  },
  {
    "uniprot": "A4I8Q7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Q7"
  },
  {
    "uniprot": "A4HWE2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWE2"
  },
  {
    "uniprot": "A4HRX3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRX3"
  },
  {
    "uniprot": "A4IA74",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA74"
  },
  {
    "uniprot": "A4IBI2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBI2"
  },
  {
    "uniprot": "A4HVU7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVU7"
  },
  {
    "uniprot": "A4I6A7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6A7"
  },
  {
    "uniprot": "A4I634",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I634"
  },
  {
    "uniprot": "A4HTR9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTR9"
  },
  {
    "uniprot": "A4I8M6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8M6"
  },
  {
    "uniprot": "A4HS93",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS93"
  },
  {
    "uniprot": "A4ICV0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICV0"
  },
  {
    "uniprot": "A4I5R4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5R4"
  },
  {
    "uniprot": "A4I446",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I446"
  },
  {
    "uniprot": "A4I8M0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8M0"
  },
  {
    "uniprot": "A4I5T9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5T9"
  },
  {
    "uniprot": "A4I8D8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8D8"
  },
  {
    "uniprot": "A4I027",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I027"
  },
  {
    "uniprot": "A4I5W5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5W5"
  },
  {
    "uniprot": "A4HTB3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTB3"
  },
  {
    "uniprot": "A4HW22",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW22"
  },
  {
    "uniprot": "A4HRC9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRC9"
  },
  {
    "uniprot": "A4I0C9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0C9"
  },
  {
    "uniprot": "A4HWF7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWF7"
  },
  {
    "uniprot": "A4IE38",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE38"
  },
  {
    "uniprot": "A4I007",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I007"
  },
  {
    "uniprot": "E9AG73",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG73"
  },
  {
    "uniprot": "A4I0V8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0V8"
  },
  {
    "uniprot": "E9AHS3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHS3"
  },
  {
    "uniprot": "A4HT31",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT31"
  },
  {
    "uniprot": "A4ICL5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICL5"
  },
  {
    "uniprot": "A4ICP2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICP2"
  },
  {
    "uniprot": "E9AGM7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGM7"
  },
  {
    "uniprot": "A4HY17",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY17"
  },
  {
    "uniprot": "E9AH58",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH58"
  },
  {
    "uniprot": "A4HYB0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYB0"
  },
  {
    "uniprot": "A4I987",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I987"
  },
  {
    "uniprot": "A4I0Z8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Z8"
  },
  {
    "uniprot": "E9AHM8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHM8"
  },
  {
    "uniprot": "A4HTN7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTN7"
  },
  {
    "uniprot": "A4I3V2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3V2"
  },
  {
    "uniprot": "A4HW66",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW66"
  },
  {
    "uniprot": "A4IA88",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA88"
  },
  {
    "uniprot": "E9AG21",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG21"
  },
  {
    "uniprot": "A4I5A2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5A2"
  },
  {
    "uniprot": "A4HZ68",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ68"
  },
  {
    "uniprot": "A4HWN7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWN7"
  },
  {
    "uniprot": "A4I933",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I933"
  },
  {
    "uniprot": "A4I9S2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9S2"
  },
  {
    "uniprot": "A4HVH4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVH4"
  },
  {
    "uniprot": "A4I0A3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0A3"
  },
  {
    "uniprot": "A4HUM9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUM9"
  },
  {
    "uniprot": "A4HZX9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZX9"
  },
  {
    "uniprot": "A4HT39",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT39"
  },
  {
    "uniprot": "A0A381MFR9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MFR9"
  },
  {
    "uniprot": "A4I829",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I829"
  },
  {
    "uniprot": "A4I0G0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0G0"
  },
  {
    "uniprot": "A4HS23",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS23"
  },
  {
    "uniprot": "A4I414",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I414"
  },
  {
    "uniprot": "A4HYE8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYE8"
  },
  {
    "uniprot": "A4I0L6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0L6"
  },
  {
    "uniprot": "A4HRP5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRP5"
  },
  {
    "uniprot": "A4IDP6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDP6"
  },
  {
    "uniprot": "A4I7A8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7A8"
  },
  {
    "uniprot": "A4HT04",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT04"
  },
  {
    "uniprot": "E9AHT0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHT0"
  },
  {
    "uniprot": "A4I788",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I788"
  },
  {
    "uniprot": "E9AGC7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGC7"
  },
  {
    "uniprot": "A4HW51",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW51"
  },
  {
    "uniprot": "A4HXC8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXC8"
  },
  {
    "uniprot": "A4I187",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I187"
  },
  {
    "uniprot": "A4IAU4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAU4"
  },
  {
    "uniprot": "A4HT75",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT75"
  },
  {
    "uniprot": "A4HS44",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS44"
  },
  {
    "uniprot": "A4I3I0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3I0"
  },
  {
    "uniprot": "A4I388",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I388"
  },
  {
    "uniprot": "A4IA51",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA51"
  },
  {
    "uniprot": "E9AHZ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHZ3"
  },
  {
    "uniprot": "A4HU34",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU34"
  },
  {
    "uniprot": "E9AHC0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHC0"
  },
  {
    "uniprot": "A4HZ46",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ46"
  },
  {
    "uniprot": "A4HX27",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX27"
  },
  {
    "uniprot": "E9AHQ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHQ7"
  },
  {
    "uniprot": "A4I1S8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1S8"
  },
  {
    "uniprot": "A4I172",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I172"
  },
  {
    "uniprot": "A4I939",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I939"
  },
  {
    "uniprot": "A4I5K1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5K1"
  },
  {
    "uniprot": "A4HXS1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXS1"
  },
  {
    "uniprot": "A4HZ35",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ35"
  },
  {
    "uniprot": "A4HVG7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVG7"
  },
  {
    "uniprot": "A4HS10",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS10"
  },
  {
    "uniprot": "A4I6G1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6G1"
  },
  {
    "uniprot": "A4HTM8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTM8"
  },
  {
    "uniprot": "A4HTL1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTL1"
  },
  {
    "uniprot": "A4HWF3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWF3"
  },
  {
    "uniprot": "A4I132",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I132"
  },
  {
    "uniprot": "A0A381MVY0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MVY0"
  },
  {
    "uniprot": "A4HTS0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTS0"
  },
  {
    "uniprot": "A4IAV8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAV8"
  },
  {
    "uniprot": "A4I472",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I472"
  },
  {
    "uniprot": "A4I0K6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0K6"
  },
  {
    "uniprot": "A4HXX0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXX0"
  },
  {
    "uniprot": "A4I4B6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4B6"
  },
  {
    "uniprot": "E9AH51",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH51"
  },
  {
    "uniprot": "A4IA29",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA29"
  },
  {
    "uniprot": "A4I9C3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9C3"
  },
  {
    "uniprot": "A4I5Z1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Z1"
  },
  {
    "uniprot": "A4HRD5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRD5"
  },
  {
    "uniprot": "A4HYR8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYR8"
  },
  {
    "uniprot": "E9AGH9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGH9"
  },
  {
    "uniprot": "A4HTP6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTP6"
  },
  {
    "uniprot": "A4IAU5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAU5"
  },
  {
    "uniprot": "A4HZI8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZI8"
  },
  {
    "uniprot": "A4ICQ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICQ5"
  },
  {
    "uniprot": "A4IDE9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDE9"
  },
  {
    "uniprot": "A4I0L2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0L2"
  },
  {
    "uniprot": "A4I4D7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4D7"
  },
  {
    "uniprot": "A4I2C3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2C3"
  },
  {
    "uniprot": "A4IAD9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAD9"
  },
  {
    "uniprot": "A4HXU8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXU8"
  },
  {
    "uniprot": "A4IC15",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC15"
  },
  {
    "uniprot": "A4I0I1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0I1"
  },
  {
    "uniprot": "A4HSG5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSG5"
  },
  {
    "uniprot": "A4I1B8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1B8"
  },
  {
    "uniprot": "A4ICY4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICY4"
  },
  {
    "uniprot": "A4I4M3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4M3"
  },
  {
    "uniprot": "A4HZF9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZF9"
  },
  {
    "uniprot": "A4IDD8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDD8"
  },
  {
    "uniprot": "A4IE63",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE63"
  },
  {
    "uniprot": "A4I9T9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9T9"
  },
  {
    "uniprot": "A0A381MTC7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MTC7"
  },
  {
    "uniprot": "A4I591",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I591"
  },
  {
    "uniprot": "A4I835",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I835"
  },
  {
    "uniprot": "A4ICX5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICX5"
  },
  {
    "uniprot": "A4I9C0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9C0"
  },
  {
    "uniprot": "A4HXD7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXD7"
  },
  {
    "uniprot": "A4I077",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I077"
  },
  {
    "uniprot": "A4HW92",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW92"
  },
  {
    "uniprot": "A4HWS8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWS8"
  },
  {
    "uniprot": "A4IBH7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBH7"
  },
  {
    "uniprot": "A4I621",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I621"
  },
  {
    "uniprot": "A4IBZ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBZ3"
  },
  {
    "uniprot": "A4HT73",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT73"
  },
  {
    "uniprot": "A4I064",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I064"
  },
  {
    "uniprot": "A4IE57",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE57"
  },
  {
    "uniprot": "A4ICN8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICN8"
  },
  {
    "uniprot": "A4I1P8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1P8"
  },
  {
    "uniprot": "A4IB06",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB06"
  },
  {
    "uniprot": "A4HVK4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVK4"
  },
  {
    "uniprot": "A4ID78",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID78"
  },
  {
    "uniprot": "A4HX58",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX58"
  },
  {
    "uniprot": "A4HT65",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT65"
  },
  {
    "uniprot": "A4HSB2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSB2"
  },
  {
    "uniprot": "A4IAE7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAE7"
  },
  {
    "uniprot": "A4ICV9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICV9"
  },
  {
    "uniprot": "A4HZI0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZI0"
  },
  {
    "uniprot": "A4HV22",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV22"
  },
  {
    "uniprot": "E9AGX7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGX7"
  },
  {
    "uniprot": "A4HXI7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXI7"
  },
  {
    "uniprot": "A4I133",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I133"
  },
  {
    "uniprot": "A4IB29",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB29"
  },
  {
    "uniprot": "A4HZ67",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ67"
  },
  {
    "uniprot": "A4IC54",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC54"
  },
  {
    "uniprot": "A4HRV6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRV6"
  },
  {
    "uniprot": "A4ICZ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICZ3"
  },
  {
    "uniprot": "A4I5P8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5P8"
  },
  {
    "uniprot": "A4HWR3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWR3"
  },
  {
    "uniprot": "A4I5S0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5S0"
  },
  {
    "uniprot": "E9AHB7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHB7"
  },
  {
    "uniprot": "A4I470",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I470"
  },
  {
    "uniprot": "A4HVI9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVI9"
  },
  {
    "uniprot": "A4I160",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I160"
  },
  {
    "uniprot": "A4I0F1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0F1"
  },
  {
    "uniprot": "E9AHQ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHQ8"
  },
  {
    "uniprot": "A4HWB3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWB3"
  },
  {
    "uniprot": "A4HYI7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYI7"
  },
  {
    "uniprot": "A4I9C8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9C8"
  },
  {
    "uniprot": "A4IAU2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAU2"
  },
  {
    "uniprot": "A4I4F8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4F8"
  },
  {
    "uniprot": "A4HXZ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXZ3"
  },
  {
    "uniprot": "A4I4D0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4D0"
  },
  {
    "uniprot": "A4I382",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I382"
  },
  {
    "uniprot": "A4I4F4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4F4"
  },
  {
    "uniprot": "A4HUF5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUF5"
  },
  {
    "uniprot": "A4HRG3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRG3"
  },
  {
    "uniprot": "A4I069",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I069"
  },
  {
    "uniprot": "A4HRR1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRR1"
  },
  {
    "uniprot": "A4I9G9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9G9"
  },
  {
    "uniprot": "A4I5X8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5X8"
  },
  {
    "uniprot": "E9AG60",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG60"
  },
  {
    "uniprot": "A4HRH6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRH6"
  },
  {
    "uniprot": "A4I2I9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2I9"
  },
  {
    "uniprot": "A4I916",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I916"
  },
  {
    "uniprot": "A4I1K8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1K8"
  },
  {
    "uniprot": "A4HWE9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWE9"
  },
  {
    "uniprot": "A4ID19",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID19"
  },
  {
    "uniprot": "A4IC78",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC78"
  },
  {
    "uniprot": "A4ID43",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID43"
  },
  {
    "uniprot": "A4I215",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I215"
  },
  {
    "uniprot": "A4HUX1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUX1"
  },
  {
    "uniprot": "A4ICN5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICN5"
  },
  {
    "uniprot": "E9AH46",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH46"
  },
  {
    "uniprot": "A4HUZ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUZ0"
  },
  {
    "uniprot": "A4I1A4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1A4"
  },
  {
    "uniprot": "A4I1G8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1G8"
  },
  {
    "uniprot": "A4IA42",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA42"
  },
  {
    "uniprot": "A4HXB8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXB8"
  },
  {
    "uniprot": "A4HY44",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY44"
  },
  {
    "uniprot": "A4HZ94",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ94"
  },
  {
    "uniprot": "A4HZ40",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ40"
  },
  {
    "uniprot": "A4IBB5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBB5"
  },
  {
    "uniprot": "A4I0T0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0T0"
  },
  {
    "uniprot": "A4HT68",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT68"
  },
  {
    "uniprot": "A4I188",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I188"
  },
  {
    "uniprot": "A4IAV1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAV1"
  },
  {
    "uniprot": "A4HSC7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSC7"
  },
  {
    "uniprot": "A4I0I2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0I2"
  },
  {
    "uniprot": "A4HTP7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTP7"
  },
  {
    "uniprot": "A4HUX9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUX9"
  },
  {
    "uniprot": "A4I5J6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5J6"
  },
  {
    "uniprot": "A4HRD0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRD0"
  },
  {
    "uniprot": "A4HX88",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX88"
  },
  {
    "uniprot": "A4I898",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I898"
  },
  {
    "uniprot": "A4I087",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I087"
  },
  {
    "uniprot": "A4IA23",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA23"
  },
  {
    "uniprot": "A4HSU5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSU5"
  },
  {
    "uniprot": "E9AGR3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGR3"
  },
  {
    "uniprot": "A4IBW3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBW3"
  },
  {
    "uniprot": "A4HRI0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRI0"
  },
  {
    "uniprot": "A4ICN0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICN0"
  },
  {
    "uniprot": "A4IC16",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC16"
  },
  {
    "uniprot": "A4I194",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I194"
  },
  {
    "uniprot": "A4I8Z2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Z2"
  },
  {
    "uniprot": "A4ID06",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID06"
  },
  {
    "uniprot": "A4IAZ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAZ8"
  },
  {
    "uniprot": "A4HST5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HST5"
  },
  {
    "uniprot": "A4HZF3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZF3"
  },
  {
    "uniprot": "E9AGQ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGQ2"
  },
  {
    "uniprot": "A4HVJ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVJ4"
  },
  {
    "uniprot": "A4I7A6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7A6"
  },
  {
    "uniprot": "A4HRU4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRU4"
  },
  {
    "uniprot": "A4I3Z4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3Z4"
  },
  {
    "uniprot": "A4HZH9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZH9"
  },
  {
    "uniprot": "A4I412",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I412"
  },
  {
    "uniprot": "A4HS86",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS86"
  },
  {
    "uniprot": "A4HYR9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYR9"
  },
  {
    "uniprot": "A4I8S8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8S8"
  },
  {
    "uniprot": "A4I750",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I750"
  },
  {
    "uniprot": "A4I4X8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4X8"
  },
  {
    "uniprot": "A4I762",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I762"
  },
  {
    "uniprot": "A4HZE4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZE4"
  },
  {
    "uniprot": "A4I4A3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4A3"
  },
  {
    "uniprot": "A4IAK3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAK3"
  },
  {
    "uniprot": "A4IBX4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBX4"
  },
  {
    "uniprot": "A4I695",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I695"
  },
  {
    "uniprot": "E9AGW9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGW9"
  },
  {
    "uniprot": "A4ID90",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID90"
  },
  {
    "uniprot": "A4IC96",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC96"
  },
  {
    "uniprot": "A4HRG9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRG9"
  },
  {
    "uniprot": "A4I2Q1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2Q1"
  },
  {
    "uniprot": "A4I2D5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2D5"
  },
  {
    "uniprot": "A4I6K8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6K8"
  },
  {
    "uniprot": "A4IAB3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAB3"
  },
  {
    "uniprot": "A4ICK3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICK3"
  },
  {
    "uniprot": "A4IBY2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBY2"
  },
  {
    "uniprot": "A4IAS3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAS3"
  },
  {
    "uniprot": "A4I5M2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5M2"
  },
  {
    "uniprot": "A4I4M0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4M0"
  },
  {
    "uniprot": "A4ICI9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICI9"
  },
  {
    "uniprot": "A4I7V2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7V2"
  },
  {
    "uniprot": "A4HS96",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS96"
  },
  {
    "uniprot": "A4I0X1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0X1"
  },
  {
    "uniprot": "A4IDF9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDF9"
  },
  {
    "uniprot": "A4I0Q3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Q3"
  },
  {
    "uniprot": "A4HZV3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZV3"
  },
  {
    "uniprot": "A4HT93",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT93"
  },
  {
    "uniprot": "A4HSZ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSZ8"
  },
  {
    "uniprot": "A4IDK4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDK4"
  },
  {
    "uniprot": "A4HYD8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYD8"
  },
  {
    "uniprot": "E9AGM6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGM6"
  },
  {
    "uniprot": "A4HRK9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRK9"
  },
  {
    "uniprot": "A4I808",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I808"
  },
  {
    "uniprot": "A4I1G3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1G3"
  },
  {
    "uniprot": "A4I1V5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1V5"
  },
  {
    "uniprot": "A4IB01",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB01"
  },
  {
    "uniprot": "A4IDS9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDS9"
  },
  {
    "uniprot": "A4I452",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I452"
  },
  {
    "uniprot": "A4HW74",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW74"
  },
  {
    "uniprot": "A4IAW8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAW8"
  },
  {
    "uniprot": "A4HUH3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUH3"
  },
  {
    "uniprot": "A4HSB7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSB7"
  },
  {
    "uniprot": "A4HXX8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXX8"
  },
  {
    "uniprot": "A4I8S6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8S6"
  },
  {
    "uniprot": "A4HZ51",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ51"
  },
  {
    "uniprot": "A4ID34",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID34"
  },
  {
    "uniprot": "A4HUW5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUW5"
  },
  {
    "uniprot": "A4I744",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I744"
  },
  {
    "uniprot": "A4IB15",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB15"
  },
  {
    "uniprot": "A4I1S5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1S5"
  },
  {
    "uniprot": "E9AGT8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGT8"
  },
  {
    "uniprot": "A4ID88",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID88"
  },
  {
    "uniprot": "A4IB69",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB69"
  },
  {
    "uniprot": "A4I9Y9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Y9"
  },
  {
    "uniprot": "A4I6M6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6M6"
  },
  {
    "uniprot": "A4HZF2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZF2"
  },
  {
    "uniprot": "A4I0H4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0H4"
  },
  {
    "uniprot": "A4HZX4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZX4"
  },
  {
    "uniprot": "A4HWS3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWS3"
  },
  {
    "uniprot": "A4HS03",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS03"
  },
  {
    "uniprot": "E9AGJ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGJ3"
  },
  {
    "uniprot": "A4I6Z8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Z8"
  },
  {
    "uniprot": "A4I4H0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4H0"
  },
  {
    "uniprot": "A4IAU7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAU7"
  },
  {
    "uniprot": "A4I141",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I141"
  },
  {
    "uniprot": "A4I608",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I608"
  },
  {
    "uniprot": "A4I173",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I173"
  },
  {
    "uniprot": "A4I837",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I837"
  },
  {
    "uniprot": "A4I9G8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9G8"
  },
  {
    "uniprot": "A4IBE4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBE4"
  },
  {
    "uniprot": "A4I4P0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4P0"
  },
  {
    "uniprot": "A4I3R9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3R9"
  },
  {
    "uniprot": "A4HWP0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWP0"
  },
  {
    "uniprot": "A4I1R8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1R8"
  },
  {
    "uniprot": "A4HVZ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVZ8"
  },
  {
    "uniprot": "A4I2B5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2B5"
  },
  {
    "uniprot": "A4I697",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I697"
  },
  {
    "uniprot": "A4HZW9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZW9"
  },
  {
    "uniprot": "A4I1U0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1U0"
  },
  {
    "uniprot": "A4HWX6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWX6"
  },
  {
    "uniprot": "A4ICJ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICJ7"
  },
  {
    "uniprot": "A4HVV7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVV7"
  },
  {
    "uniprot": "E9AHI3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHI3"
  },
  {
    "uniprot": "A4IC30",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC30"
  },
  {
    "uniprot": "A4I037",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I037"
  },
  {
    "uniprot": "A4I3P8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3P8"
  },
  {
    "uniprot": "E9AHM6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHM6"
  },
  {
    "uniprot": "Q25299",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q25299"
  },
  {
    "uniprot": "A4I8A6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8A6"
  },
  {
    "uniprot": "A4HV46",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV46"
  },
  {
    "uniprot": "A4HV33",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV33"
  },
  {
    "uniprot": "A4ICD8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICD8"
  },
  {
    "uniprot": "A4HRT4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRT4"
  },
  {
    "uniprot": "A4I9M9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9M9"
  },
  {
    "uniprot": "A4I2J0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2J0"
  },
  {
    "uniprot": "A4ICA6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICA6"
  },
  {
    "uniprot": "A4I5L6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5L6"
  },
  {
    "uniprot": "A4HY79",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY79"
  },
  {
    "uniprot": "A4I4G2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4G2"
  },
  {
    "uniprot": "E9AH03",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH03"
  },
  {
    "uniprot": "A4HU07",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU07"
  },
  {
    "uniprot": "A4IAT1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAT1"
  },
  {
    "uniprot": "A4ID64",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID64"
  },
  {
    "uniprot": "A4HT66",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT66"
  },
  {
    "uniprot": "A4HXN2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXN2"
  },
  {
    "uniprot": "E9AHP9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHP9"
  },
  {
    "uniprot": "A4I5P1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5P1"
  },
  {
    "uniprot": "E9AHU5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHU5"
  },
  {
    "uniprot": "A4I5T8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5T8"
  },
  {
    "uniprot": "A4IA19",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA19"
  },
  {
    "uniprot": "A4I8U7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8U7"
  },
  {
    "uniprot": "A4I0W1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0W1"
  },
  {
    "uniprot": "A4I3X6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3X6"
  },
  {
    "uniprot": "A4ICG8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICG8"
  },
  {
    "uniprot": "A4HSG3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSG3"
  },
  {
    "uniprot": "A4HUD2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUD2"
  },
  {
    "uniprot": "A4IA41",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA41"
  },
  {
    "uniprot": "A4IAN5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAN5"
  },
  {
    "uniprot": "A4I4N2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4N2"
  },
  {
    "uniprot": "A4IAA1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAA1"
  },
  {
    "uniprot": "A4I9J8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9J8"
  },
  {
    "uniprot": "A4IE28",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE28"
  },
  {
    "uniprot": "A4HWY6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWY6"
  },
  {
    "uniprot": "A4IA24",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA24"
  },
  {
    "uniprot": "A4HUG8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUG8"
  },
  {
    "uniprot": "A4IB51",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB51"
  },
  {
    "uniprot": "E9AHS1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHS1"
  },
  {
    "uniprot": "A4I5G1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5G1"
  },
  {
    "uniprot": "A4I9N5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9N5"
  },
  {
    "uniprot": "A4I0N1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0N1"
  },
  {
    "uniprot": "A4I9C7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9C7"
  },
  {
    "uniprot": "A4IBA4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBA4"
  },
  {
    "uniprot": "A4I072",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I072"
  },
  {
    "uniprot": "A4I7W9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7W9"
  },
  {
    "uniprot": "E9AGS1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGS1"
  },
  {
    "uniprot": "A4HVW3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVW3"
  },
  {
    "uniprot": "A4HVG6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVG6"
  },
  {
    "uniprot": "A4HVY0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVY0"
  },
  {
    "uniprot": "A4ICD7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICD7"
  },
  {
    "uniprot": "A4ICY7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICY7"
  },
  {
    "uniprot": "A4I137",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I137"
  },
  {
    "uniprot": "A4I284",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I284"
  },
  {
    "uniprot": "A4I764",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I764"
  },
  {
    "uniprot": "A4I0D6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0D6"
  },
  {
    "uniprot": "A4I7V5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7V5"
  },
  {
    "uniprot": "A4I8V9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8V9"
  },
  {
    "uniprot": "A2CIJ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A2CIJ8"
  },
  {
    "uniprot": "A4I795",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I795"
  },
  {
    "uniprot": "A4I7V8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7V8"
  },
  {
    "uniprot": "A4HYX0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYX0"
  },
  {
    "uniprot": "A4HWJ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWJ3"
  },
  {
    "uniprot": "A4I380",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I380"
  },
  {
    "uniprot": "A4ID81",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID81"
  },
  {
    "uniprot": "A4I013",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I013"
  },
  {
    "uniprot": "A4HW35",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW35"
  },
  {
    "uniprot": "A4I2U5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2U5"
  },
  {
    "uniprot": "A4HZY8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZY8"
  },
  {
    "uniprot": "A4HSW7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSW7"
  },
  {
    "uniprot": "A4HX38",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX38"
  },
  {
    "uniprot": "A4I1A7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1A7"
  },
  {
    "uniprot": "A4HW20",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW20"
  },
  {
    "uniprot": "A4IBL3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBL3"
  },
  {
    "uniprot": "E9AGV4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGV4"
  },
  {
    "uniprot": "A4HSQ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSQ6"
  },
  {
    "uniprot": "A4I451",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I451"
  },
  {
    "uniprot": "A4HV41",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV41"
  },
  {
    "uniprot": "A4I875",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I875"
  },
  {
    "uniprot": "A4HXG9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXG9"
  },
  {
    "uniprot": "A4HXP9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXP9"
  },
  {
    "uniprot": "A4HZ38",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ38"
  },
  {
    "uniprot": "A4I174",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I174"
  },
  {
    "uniprot": "A4HUG3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUG3"
  },
  {
    "uniprot": "A4HVE1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVE1"
  },
  {
    "uniprot": "A4I3J9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3J9"
  },
  {
    "uniprot": "A4HVL8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVL8"
  },
  {
    "uniprot": "A4HTF0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTF0"
  },
  {
    "uniprot": "A4IDP7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDP7"
  },
  {
    "uniprot": "E9AHM0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHM0"
  },
  {
    "uniprot": "A4I1V1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1V1"
  },
  {
    "uniprot": "A4I2R8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2R8"
  },
  {
    "uniprot": "A4I0G7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0G7"
  },
  {
    "uniprot": "A4HRG0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRG0"
  },
  {
    "uniprot": "A4HU08",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU08"
  },
  {
    "uniprot": "A4HZD7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZD7"
  },
  {
    "uniprot": "A4HXQ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXQ4"
  },
  {
    "uniprot": "A4HVF5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVF5"
  },
  {
    "uniprot": "A4HYE0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYE0"
  },
  {
    "uniprot": "E9AGJ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGJ0"
  },
  {
    "uniprot": "A4IA16",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA16"
  },
  {
    "uniprot": "A4HRP9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRP9"
  },
  {
    "uniprot": "A4HTN4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTN4"
  },
  {
    "uniprot": "A4HST7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HST7"
  },
  {
    "uniprot": "A4I638",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I638"
  },
  {
    "uniprot": "A4IAP1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAP1"
  },
  {
    "uniprot": "A4I888",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I888"
  },
  {
    "uniprot": "A4IAE8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAE8"
  },
  {
    "uniprot": "E9AGW7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGW7"
  },
  {
    "uniprot": "A4IE27",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE27"
  },
  {
    "uniprot": "A4I6U9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6U9"
  },
  {
    "uniprot": "A4IB61",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB61"
  },
  {
    "uniprot": "A4IDY5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDY5"
  },
  {
    "uniprot": "A4I661",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I661"
  },
  {
    "uniprot": "A4HZ60",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ60"
  },
  {
    "uniprot": "A4HUE8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUE8"
  },
  {
    "uniprot": "A4I971",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I971"
  },
  {
    "uniprot": "A4IAQ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAQ7"
  },
  {
    "uniprot": "A4HUW3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUW3"
  },
  {
    "uniprot": "A4HVR7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVR7"
  },
  {
    "uniprot": "E9AGN0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGN0"
  },
  {
    "uniprot": "A4HV71",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV71"
  },
  {
    "uniprot": "A4I1S4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1S4"
  },
  {
    "uniprot": "A4IDU4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDU4"
  },
  {
    "uniprot": "A4HXX2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXX2"
  },
  {
    "uniprot": "A4IDD3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDD3"
  },
  {
    "uniprot": "E9AGW5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGW5"
  },
  {
    "uniprot": "A4I798",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I798"
  },
  {
    "uniprot": "A4I1F4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1F4"
  },
  {
    "uniprot": "A4HY46",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY46"
  },
  {
    "uniprot": "A4HZM4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZM4"
  },
  {
    "uniprot": "A4IA69",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA69"
  },
  {
    "uniprot": "A4IA20",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA20"
  },
  {
    "uniprot": "A4HVP9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVP9"
  },
  {
    "uniprot": "A4IC72",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC72"
  },
  {
    "uniprot": "A4I8U1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8U1"
  },
  {
    "uniprot": "A4HYK6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYK6"
  },
  {
    "uniprot": "A4HWY7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWY7"
  },
  {
    "uniprot": "A4I0V0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0V0"
  },
  {
    "uniprot": "E9AHF7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHF7"
  },
  {
    "uniprot": "A4HYC0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYC0"
  },
  {
    "uniprot": "A4I6G0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6G0"
  },
  {
    "uniprot": "A4I0C2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0C2"
  },
  {
    "uniprot": "A4HWD1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWD1"
  },
  {
    "uniprot": "A4I8W2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8W2"
  },
  {
    "uniprot": "A4HYY9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYY9"
  },
  {
    "uniprot": "A4HSD9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSD9"
  },
  {
    "uniprot": "E9AHA3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHA3"
  },
  {
    "uniprot": "E9AG45",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG45"
  },
  {
    "uniprot": "A4I208",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I208"
  },
  {
    "uniprot": "A4HRF8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRF8"
  },
  {
    "uniprot": "A4HVS2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVS2"
  },
  {
    "uniprot": "A4I0U5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0U5"
  },
  {
    "uniprot": "A4I5V5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5V5"
  },
  {
    "uniprot": "A4ICU1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICU1"
  },
  {
    "uniprot": "A4I3E6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3E6"
  },
  {
    "uniprot": "A4I4E3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4E3"
  },
  {
    "uniprot": "A4I5Y8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Y8"
  },
  {
    "uniprot": "A4I4D2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4D2"
  },
  {
    "uniprot": "A4ICV2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICV2"
  },
  {
    "uniprot": "A4I9R4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9R4"
  },
  {
    "uniprot": "A4I2I6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2I6"
  },
  {
    "uniprot": "A4I3V0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3V0"
  },
  {
    "uniprot": "A4I497",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I497"
  },
  {
    "uniprot": "A4I6G4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6G4"
  },
  {
    "uniprot": "A4I2C2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2C2"
  },
  {
    "uniprot": "A4I1V7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1V7"
  },
  {
    "uniprot": "A4HUU9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUU9"
  },
  {
    "uniprot": "A4HTV6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTV6"
  },
  {
    "uniprot": "A4IB32",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB32"
  },
  {
    "uniprot": "A4HXI6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXI6"
  },
  {
    "uniprot": "A4HT84",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT84"
  },
  {
    "uniprot": "A4HWJ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWJ5"
  },
  {
    "uniprot": "A4I6F1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6F1"
  },
  {
    "uniprot": "A4I1S0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1S0"
  },
  {
    "uniprot": "A4I1P4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1P4"
  },
  {
    "uniprot": "A4I9H0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9H0"
  },
  {
    "uniprot": "A4HT20",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT20"
  },
  {
    "uniprot": "A4HVV3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVV3"
  },
  {
    "uniprot": "A4I2L6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2L6"
  },
  {
    "uniprot": "A4HWL0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWL0"
  },
  {
    "uniprot": "A4IE43",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE43"
  },
  {
    "uniprot": "A4HWC6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWC6"
  },
  {
    "uniprot": "A4I5X6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5X6"
  },
  {
    "uniprot": "A4I0S9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0S9"
  },
  {
    "uniprot": "E9AHH1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHH1"
  },
  {
    "uniprot": "A4I7I5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7I5"
  },
  {
    "uniprot": "A4I0U2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0U2"
  },
  {
    "uniprot": "A4I8F3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8F3"
  },
  {
    "uniprot": "A4HUZ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUZ3"
  },
  {
    "uniprot": "A4I0P6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0P6"
  },
  {
    "uniprot": "A4I3W8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3W8"
  },
  {
    "uniprot": "E9AH66",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH66"
  },
  {
    "uniprot": "A4I651",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I651"
  },
  {
    "uniprot": "A4HXT9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXT9"
  },
  {
    "uniprot": "A4HRC7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRC7"
  },
  {
    "uniprot": "A4I7B5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7B5"
  },
  {
    "uniprot": "A4HUP1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUP1"
  },
  {
    "uniprot": "A4IB26",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB26"
  },
  {
    "uniprot": "A4I1T0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1T0"
  },
  {
    "uniprot": "A4IB49",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB49"
  },
  {
    "uniprot": "A4HZT9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZT9"
  },
  {
    "uniprot": "E9AGZ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGZ4"
  },
  {
    "uniprot": "A4HWI1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWI1"
  },
  {
    "uniprot": "A4HXV0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXV0"
  },
  {
    "uniprot": "A4IDB6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDB6"
  },
  {
    "uniprot": "A4HWP5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWP5"
  },
  {
    "uniprot": "A4I4S0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4S0"
  },
  {
    "uniprot": "A4IDN4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDN4"
  },
  {
    "uniprot": "A4I4K0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4K0"
  },
  {
    "uniprot": "A4I9Q0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Q0"
  },
  {
    "uniprot": "A4I5L2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5L2"
  },
  {
    "uniprot": "A4HV57",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV57"
  },
  {
    "uniprot": "A4HTD4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTD4"
  },
  {
    "uniprot": "E9AHB3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHB3"
  },
  {
    "uniprot": "A4I3K7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3K7"
  },
  {
    "uniprot": "A4I5V7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5V7"
  },
  {
    "uniprot": "A4I130",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I130"
  },
  {
    "uniprot": "A4HU17",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU17"
  },
  {
    "uniprot": "A4I3X4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3X4"
  },
  {
    "uniprot": "A4I5E5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5E5"
  },
  {
    "uniprot": "A4I186",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I186"
  },
  {
    "uniprot": "A4I090",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I090"
  },
  {
    "uniprot": "A4HS16",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS16"
  },
  {
    "uniprot": "A4I4Z1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Z1"
  },
  {
    "uniprot": "A4HT52",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT52"
  },
  {
    "uniprot": "A4HT95",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT95"
  },
  {
    "uniprot": "A4I7B4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7B4"
  },
  {
    "uniprot": "A4IAY5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAY5"
  },
  {
    "uniprot": "A4I1J3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1J3"
  },
  {
    "uniprot": "Q95U89",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q95U89"
  },
  {
    "uniprot": "A4I8Y2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Y2"
  },
  {
    "uniprot": "A4HWB1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWB1"
  },
  {
    "uniprot": "E9AG54",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG54"
  },
  {
    "uniprot": "A4I1G6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1G6"
  },
  {
    "uniprot": "A4I1F1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1F1"
  },
  {
    "uniprot": "A4HZJ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZJ2"
  },
  {
    "uniprot": "A4I1Q4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1Q4"
  },
  {
    "uniprot": "A4I2N9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2N9"
  },
  {
    "uniprot": "A4I138",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I138"
  },
  {
    "uniprot": "A4I3H7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3H7"
  },
  {
    "uniprot": "A4HWA4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWA4"
  },
  {
    "uniprot": "A4I2P7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2P7"
  },
  {
    "uniprot": "A4I8Z7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Z7"
  },
  {
    "uniprot": "A4HSK3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSK3"
  },
  {
    "uniprot": "E9AHQ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHQ1"
  },
  {
    "uniprot": "A4I613",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I613"
  },
  {
    "uniprot": "A4IA12",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA12"
  },
  {
    "uniprot": "A4HSU9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSU9"
  },
  {
    "uniprot": "A4HZG0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZG0"
  },
  {
    "uniprot": "A4IAD2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAD2"
  },
  {
    "uniprot": "A4HS95",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS95"
  },
  {
    "uniprot": "A4HW02",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW02"
  },
  {
    "uniprot": "A4HTY8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTY8"
  },
  {
    "uniprot": "A4I3N8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3N8"
  },
  {
    "uniprot": "A4HWN9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWN9"
  },
  {
    "uniprot": "E9AHF8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHF8"
  },
  {
    "uniprot": "A4HYN8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYN8"
  },
  {
    "uniprot": "A4HSQ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSQ2"
  },
  {
    "uniprot": "A4ID87",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID87"
  },
  {
    "uniprot": "A4I5W0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5W0"
  },
  {
    "uniprot": "A4IBD8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBD8"
  },
  {
    "uniprot": "A4I859",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I859"
  },
  {
    "uniprot": "A4I084",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I084"
  },
  {
    "uniprot": "A4I4Y2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Y2"
  },
  {
    "uniprot": "A4HVZ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVZ2"
  },
  {
    "uniprot": "A4I1U4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1U4"
  },
  {
    "uniprot": "A4I485",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I485"
  },
  {
    "uniprot": "A4ICP4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICP4"
  },
  {
    "uniprot": "A4I9Z5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Z5"
  },
  {
    "uniprot": "A4IBV1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBV1"
  },
  {
    "uniprot": "A4I6Y1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Y1"
  },
  {
    "uniprot": "A4I1H1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1H1"
  },
  {
    "uniprot": "A4HT26",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT26"
  },
  {
    "uniprot": "E9AG32",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG32"
  },
  {
    "uniprot": "E9AGM3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGM3"
  },
  {
    "uniprot": "A4HW70",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW70"
  },
  {
    "uniprot": "A4HZ69",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ69"
  },
  {
    "uniprot": "A4I2T7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2T7"
  },
  {
    "uniprot": "A4HSR0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSR0"
  },
  {
    "uniprot": "A4I199",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I199"
  },
  {
    "uniprot": "A4IBJ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBJ3"
  },
  {
    "uniprot": "A4I8B4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8B4"
  },
  {
    "uniprot": "A4HSL8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSL8"
  },
  {
    "uniprot": "A4IAI4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAI4"
  },
  {
    "uniprot": "A4HSV4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSV4"
  },
  {
    "uniprot": "A4I9Z1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Z1"
  },
  {
    "uniprot": "A4IBW5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBW5"
  },
  {
    "uniprot": "A4HW25",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW25"
  },
  {
    "uniprot": "A4IBR0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBR0"
  },
  {
    "uniprot": "A4I4F1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4F1"
  },
  {
    "uniprot": "A4I5N6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5N6"
  },
  {
    "uniprot": "A4HSH9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSH9"
  },
  {
    "uniprot": "A4I6B6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6B6"
  },
  {
    "uniprot": "A4IBM1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBM1"
  },
  {
    "uniprot": "A4HTZ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTZ6"
  },
  {
    "uniprot": "A4HSS5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSS5"
  },
  {
    "uniprot": "A4HS77",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS77"
  },
  {
    "uniprot": "A4I2F9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2F9"
  },
  {
    "uniprot": "A4IBK3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBK3"
  },
  {
    "uniprot": "A4I4J5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4J5"
  },
  {
    "uniprot": "A4HS00",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS00"
  },
  {
    "uniprot": "E9AHM7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHM7"
  },
  {
    "uniprot": "A4I929",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I929"
  },
  {
    "uniprot": "A4HZX0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZX0"
  },
  {
    "uniprot": "E9AGT2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGT2"
  },
  {
    "uniprot": "A4HYS3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYS3"
  },
  {
    "uniprot": "A0A381MIF8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MIF8"
  },
  {
    "uniprot": "A4HTM3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTM3"
  },
  {
    "uniprot": "A4I2R6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2R6"
  },
  {
    "uniprot": "A4IBA7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBA7"
  },
  {
    "uniprot": "A4IB95",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB95"
  },
  {
    "uniprot": "A4IA52",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA52"
  },
  {
    "uniprot": "A4I5N2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5N2"
  },
  {
    "uniprot": "A4HVG4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVG4"
  },
  {
    "uniprot": "A4ICE0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICE0"
  },
  {
    "uniprot": "A4HY45",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY45"
  },
  {
    "uniprot": "A4HWB5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWB5"
  },
  {
    "uniprot": "A4I079",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I079"
  },
  {
    "uniprot": "A4HTU3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTU3"
  },
  {
    "uniprot": "A4I4G0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4G0"
  },
  {
    "uniprot": "A4HS69",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS69"
  },
  {
    "uniprot": "A4I8T1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8T1"
  },
  {
    "uniprot": "A4HV10",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HV10"
  },
  {
    "uniprot": "E9AHW8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHW8"
  },
  {
    "uniprot": "A4IC17",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC17"
  },
  {
    "uniprot": "A4I766",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I766"
  },
  {
    "uniprot": "A4HS85",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS85"
  },
  {
    "uniprot": "A4I1L5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1L5"
  },
  {
    "uniprot": "A4I8J2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8J2"
  },
  {
    "uniprot": "A4HW62",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW62"
  },
  {
    "uniprot": "A4HWI8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWI8"
  },
  {
    "uniprot": "A4HZU5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZU5"
  },
  {
    "uniprot": "A4IC87",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC87"
  },
  {
    "uniprot": "A4IBA0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBA0"
  },
  {
    "uniprot": "E9AHT9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHT9"
  },
  {
    "uniprot": "A4I516",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I516"
  },
  {
    "uniprot": "A4I5U1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5U1"
  },
  {
    "uniprot": "A4HS24",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS24"
  },
  {
    "uniprot": "E9AGC0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGC0"
  },
  {
    "uniprot": "A4HW42",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW42"
  },
  {
    "uniprot": "E9AGS7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGS7"
  },
  {
    "uniprot": "A4I3I4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3I4"
  },
  {
    "uniprot": "A4IE08",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE08"
  },
  {
    "uniprot": "A4I9U8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9U8"
  },
  {
    "uniprot": "A4HW78",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW78"
  },
  {
    "uniprot": "E9AGJ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGJ5"
  },
  {
    "uniprot": "A4I4Z0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Z0"
  },
  {
    "uniprot": "A4IBY4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBY4"
  },
  {
    "uniprot": "A4I8J0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8J0"
  },
  {
    "uniprot": "E9AH17",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH17"
  },
  {
    "uniprot": "A4HRG7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRG7"
  },
  {
    "uniprot": "A4IB31",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB31"
  },
  {
    "uniprot": "A4I9N7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9N7"
  },
  {
    "uniprot": "A4HTM5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTM5"
  },
  {
    "uniprot": "A4IC70",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC70"
  },
  {
    "uniprot": "A4HY01",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY01"
  },
  {
    "uniprot": "E9AHQ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHQ2"
  },
  {
    "uniprot": "A4HT50",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT50"
  },
  {
    "uniprot": "A4I605",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I605"
  },
  {
    "uniprot": "A4HXS7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXS7"
  },
  {
    "uniprot": "A4I9B3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9B3"
  },
  {
    "uniprot": "A4I1J9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1J9"
  },
  {
    "uniprot": "E9AH62",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH62"
  },
  {
    "uniprot": "A4HUP3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUP3"
  },
  {
    "uniprot": "A4HS26",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS26"
  },
  {
    "uniprot": "A4IBH4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBH4"
  },
  {
    "uniprot": "A4I316",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I316"
  },
  {
    "uniprot": "A4I6X8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6X8"
  },
  {
    "uniprot": "A4I7U5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7U5"
  },
  {
    "uniprot": "A4I633",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I633"
  },
  {
    "uniprot": "A4IA37",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA37"
  },
  {
    "uniprot": "A4HVL1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVL1"
  },
  {
    "uniprot": "A4HY49",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY49"
  },
  {
    "uniprot": "A4I8L6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8L6"
  },
  {
    "uniprot": "A4HRD6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRD6"
  },
  {
    "uniprot": "A4ICI4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICI4"
  },
  {
    "uniprot": "A4I976",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I976"
  },
  {
    "uniprot": "A4I612",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I612"
  },
  {
    "uniprot": "A4HXF4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXF4"
  },
  {
    "uniprot": "A4HSS8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSS8"
  },
  {
    "uniprot": "A4HZ44",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ44"
  },
  {
    "uniprot": "E9AGR7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGR7"
  },
  {
    "uniprot": "A4I8R1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8R1"
  },
  {
    "uniprot": "A4HT64",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT64"
  },
  {
    "uniprot": "A4I7T9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7T9"
  },
  {
    "uniprot": "A4HX57",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX57"
  },
  {
    "uniprot": "A4HW68",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW68"
  },
  {
    "uniprot": "A4IDL3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDL3"
  },
  {
    "uniprot": "A4I0D7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0D7"
  },
  {
    "uniprot": "A4ICH7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICH7"
  },
  {
    "uniprot": "A4I9Q9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Q9"
  },
  {
    "uniprot": "A4IE00",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE00"
  },
  {
    "uniprot": "A4HY30",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY30"
  },
  {
    "uniprot": "E9AI05",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AI05"
  },
  {
    "uniprot": "A4HTQ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTQ2"
  },
  {
    "uniprot": "A4HX28",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX28"
  },
  {
    "uniprot": "A4HTC5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTC5"
  },
  {
    "uniprot": "A4I4Z9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Z9"
  },
  {
    "uniprot": "A4I0G2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0G2"
  },
  {
    "uniprot": "A4HUK3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUK3"
  },
  {
    "uniprot": "A4HZY3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZY3"
  },
  {
    "uniprot": "A4ID55",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID55"
  },
  {
    "uniprot": "A0A381MP32",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MP32"
  },
  {
    "uniprot": "A4I6Z0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Z0"
  },
  {
    "uniprot": "A4I3C8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3C8"
  },
  {
    "uniprot": "A4IC85",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC85"
  },
  {
    "uniprot": "A4I8R0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8R0"
  },
  {
    "uniprot": "A4I6L1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6L1"
  },
  {
    "uniprot": "A4I262",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I262"
  },
  {
    "uniprot": "A4I104",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I104"
  },
  {
    "uniprot": "A4HTN3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTN3"
  },
  {
    "uniprot": "E9AHV9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHV9"
  },
  {
    "uniprot": "A4HZD2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZD2"
  },
  {
    "uniprot": "A4HYB4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYB4"
  },
  {
    "uniprot": "A4HU28",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU28"
  },
  {
    "uniprot": "A4IBD3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBD3"
  },
  {
    "uniprot": "A4IBF7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBF7"
  },
  {
    "uniprot": "A4HZS8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZS8"
  },
  {
    "uniprot": "A4I175",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I175"
  },
  {
    "uniprot": "A4HVJ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVJ8"
  },
  {
    "uniprot": "A4HWW3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWW3"
  },
  {
    "uniprot": "A4HS43",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS43"
  },
  {
    "uniprot": "A4I5W3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5W3"
  },
  {
    "uniprot": "A4IC55",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC55"
  },
  {
    "uniprot": "A4HU27",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU27"
  },
  {
    "uniprot": "A4IE64",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE64"
  },
  {
    "uniprot": "A4HZB9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZB9"
  },
  {
    "uniprot": "A4I0T2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0T2"
  },
  {
    "uniprot": "A4I9K2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9K2"
  },
  {
    "uniprot": "A4HUL1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUL1"
  },
  {
    "uniprot": "A4HWC4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWC4"
  },
  {
    "uniprot": "A4I241",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I241"
  },
  {
    "uniprot": "A4I400",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I400"
  },
  {
    "uniprot": "E9AGE6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGE6"
  },
  {
    "uniprot": "A4IBW2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBW2"
  },
  {
    "uniprot": "A4HSY0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSY0"
  },
  {
    "uniprot": "A4I787",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I787"
  },
  {
    "uniprot": "A4I4T2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4T2"
  },
  {
    "uniprot": "E9AHJ5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHJ5"
  },
  {
    "uniprot": "A4HS90",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS90"
  },
  {
    "uniprot": "A4I6Z7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6Z7"
  },
  {
    "uniprot": "E9AGH3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGH3"
  },
  {
    "uniprot": "A4HX09",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX09"
  },
  {
    "uniprot": "A4I5C4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5C4"
  },
  {
    "uniprot": "A4HUK4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUK4"
  },
  {
    "uniprot": "A4IB21",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB21"
  },
  {
    "uniprot": "A4HYP3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYP3"
  },
  {
    "uniprot": "A4I8W7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8W7"
  },
  {
    "uniprot": "A4IAS7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAS7"
  },
  {
    "uniprot": "E9AH93",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH93"
  },
  {
    "uniprot": "A4IB19",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB19"
  },
  {
    "uniprot": "A4HSZ3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSZ3"
  },
  {
    "uniprot": "A4I3K9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3K9"
  },
  {
    "uniprot": "E9AH13",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH13"
  },
  {
    "uniprot": "A4I9R0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9R0"
  },
  {
    "uniprot": "A4I7S7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7S7"
  },
  {
    "uniprot": "A4I088",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I088"
  },
  {
    "uniprot": "A4HWQ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWQ2"
  },
  {
    "uniprot": "A4I5S8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5S8"
  },
  {
    "uniprot": "A4I9J7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9J7"
  },
  {
    "uniprot": "E9AHY3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHY3"
  },
  {
    "uniprot": "A4I2D3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2D3"
  },
  {
    "uniprot": "A4IAY4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAY4"
  },
  {
    "uniprot": "A4HS18",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS18"
  },
  {
    "uniprot": "A4HWD6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWD6"
  },
  {
    "uniprot": "A4IAN2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAN2"
  },
  {
    "uniprot": "A4IC91",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC91"
  },
  {
    "uniprot": "A4HWJ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWJ6"
  },
  {
    "uniprot": "A4I7P3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7P3"
  },
  {
    "uniprot": "A4HYV6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYV6"
  },
  {
    "uniprot": "A4I7X5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7X5"
  },
  {
    "uniprot": "A4HZ89",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZ89"
  },
  {
    "uniprot": "A4I518",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I518"
  },
  {
    "uniprot": "A4I344",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I344"
  },
  {
    "uniprot": "A4IA03",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IA03"
  },
  {
    "uniprot": "A4I3U6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3U6"
  },
  {
    "uniprot": "A4I6T5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6T5"
  },
  {
    "uniprot": "A4I8J9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8J9"
  },
  {
    "uniprot": "A4I3B1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3B1"
  },
  {
    "uniprot": "A4IDG7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDG7"
  },
  {
    "uniprot": "A4I211",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I211"
  },
  {
    "uniprot": "A4HWB6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWB6"
  },
  {
    "uniprot": "A4HXB4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXB4"
  },
  {
    "uniprot": "A4I341",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I341"
  },
  {
    "uniprot": "A4I5F7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5F7"
  },
  {
    "uniprot": "A4HWU5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWU5"
  },
  {
    "uniprot": "A4HYR5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYR5"
  },
  {
    "uniprot": "A4HSB8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSB8"
  },
  {
    "uniprot": "A4HSW9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSW9"
  },
  {
    "uniprot": "A4IC51",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC51"
  },
  {
    "uniprot": "A4HU36",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HU36"
  },
  {
    "uniprot": "A4I386",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I386"
  },
  {
    "uniprot": "A4I4S9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4S9"
  },
  {
    "uniprot": "A4IBF4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBF4"
  },
  {
    "uniprot": "E9AHL9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHL9"
  },
  {
    "uniprot": "A4HWG4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWG4"
  },
  {
    "uniprot": "A4I5V6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5V6"
  },
  {
    "uniprot": "A4I0F0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0F0"
  },
  {
    "uniprot": "A4HVQ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVQ7"
  },
  {
    "uniprot": "A4I6E7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6E7"
  },
  {
    "uniprot": "A4HXY2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXY2"
  },
  {
    "uniprot": "A4I1W2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1W2"
  },
  {
    "uniprot": "E9AHV7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHV7"
  },
  {
    "uniprot": "A4I927",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I927"
  },
  {
    "uniprot": "A4I5P9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5P9"
  },
  {
    "uniprot": "A4HZD3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZD3"
  },
  {
    "uniprot": "A4I778",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I778"
  },
  {
    "uniprot": "A4I599",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I599"
  },
  {
    "uniprot": "A4I149",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I149"
  },
  {
    "uniprot": "A4I515",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I515"
  },
  {
    "uniprot": "A4I9R1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9R1"
  },
  {
    "uniprot": "A4HTR2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTR2"
  },
  {
    "uniprot": "A4HT44",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT44"
  },
  {
    "uniprot": "A4I438",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I438"
  },
  {
    "uniprot": "E9AGH0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGH0"
  },
  {
    "uniprot": "A4I878",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I878"
  },
  {
    "uniprot": "A4HX22",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX22"
  },
  {
    "uniprot": "A4I243",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I243"
  },
  {
    "uniprot": "A4IDE8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDE8"
  },
  {
    "uniprot": "A4HSI6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSI6"
  },
  {
    "uniprot": "A4HYX2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYX2"
  },
  {
    "uniprot": "A4IB14",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB14"
  },
  {
    "uniprot": "A4HTK9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTK9"
  },
  {
    "uniprot": "A4IAF2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAF2"
  },
  {
    "uniprot": "A4HRM5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRM5"
  },
  {
    "uniprot": "E9AHA7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHA7"
  },
  {
    "uniprot": "A4I1P7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1P7"
  },
  {
    "uniprot": "A4I107",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I107"
  },
  {
    "uniprot": "A4I5M9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5M9"
  },
  {
    "uniprot": "A4I5I9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5I9"
  },
  {
    "uniprot": "A4I0W9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0W9"
  },
  {
    "uniprot": "A4I3M2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3M2"
  },
  {
    "uniprot": "A4I0Y1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Y1"
  },
  {
    "uniprot": "A4I3C7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3C7"
  },
  {
    "uniprot": "A4I4W0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4W0"
  },
  {
    "uniprot": "A4I4T8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4T8"
  },
  {
    "uniprot": "E9AGU3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGU3"
  },
  {
    "uniprot": "A4I4C5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4C5"
  },
  {
    "uniprot": "A4IBX7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBX7"
  },
  {
    "uniprot": "E9AGM0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGM0"
  },
  {
    "uniprot": "A4HXK7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXK7"
  },
  {
    "uniprot": "A4HUN7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUN7"
  },
  {
    "uniprot": "A4HTW7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTW7"
  },
  {
    "uniprot": "A4I0F7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0F7"
  },
  {
    "uniprot": "A4HVL0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HVL0"
  },
  {
    "uniprot": "A4HY86",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY86"
  },
  {
    "uniprot": "A4HSF8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSF8"
  },
  {
    "uniprot": "A4HWS5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWS5"
  },
  {
    "uniprot": "A4HX61",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX61"
  },
  {
    "uniprot": "A4I375",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I375"
  },
  {
    "uniprot": "A4I039",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I039"
  },
  {
    "uniprot": "A4HW91",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW91"
  },
  {
    "uniprot": "A4IAM5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAM5"
  },
  {
    "uniprot": "A4ICD3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICD3"
  },
  {
    "uniprot": "A4ID68",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID68"
  },
  {
    "uniprot": "A4ID98",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID98"
  },
  {
    "uniprot": "A4IE48",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IE48"
  },
  {
    "uniprot": "A4HW37",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW37"
  },
  {
    "uniprot": "A4IDV2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDV2"
  },
  {
    "uniprot": "A4HRH0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRH0"
  },
  {
    "uniprot": "E9AI07",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AI07"
  },
  {
    "uniprot": "A0A381MMW5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MMW5"
  },
  {
    "uniprot": "E9AHF5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHF5"
  },
  {
    "uniprot": "A4HXT5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXT5"
  },
  {
    "uniprot": "A4I0Q9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0Q9"
  },
  {
    "uniprot": "A4I1T2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1T2"
  },
  {
    "uniprot": "A0A381MBG2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MBG2"
  },
  {
    "uniprot": "A4I144",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I144"
  },
  {
    "uniprot": "A0A381MI35",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MI35"
  },
  {
    "uniprot": "A4I5H9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5H9"
  },
  {
    "uniprot": "A4HZR4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZR4"
  },
  {
    "uniprot": "A4I1C4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1C4"
  },
  {
    "uniprot": "A4I9W0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9W0"
  },
  {
    "uniprot": "A4I221",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I221"
  },
  {
    "uniprot": "A4I6M0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I6M0"
  },
  {
    "uniprot": "A4HZJ0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZJ0"
  },
  {
    "uniprot": "A4I2L7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2L7"
  },
  {
    "uniprot": "A4HTF1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTF1"
  },
  {
    "uniprot": "A4HXV1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXV1"
  },
  {
    "uniprot": "A4IAA6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAA6"
  },
  {
    "uniprot": "A4HY35",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY35"
  },
  {
    "uniprot": "A4I318",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I318"
  },
  {
    "uniprot": "A4I1S1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1S1"
  },
  {
    "uniprot": "A4I2U7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I2U7"
  },
  {
    "uniprot": "A4ICR4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICR4"
  },
  {
    "uniprot": "E9AG79",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG79"
  },
  {
    "uniprot": "A4ICK7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICK7"
  },
  {
    "uniprot": "A4HX93",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX93"
  },
  {
    "uniprot": "A4HW47",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW47"
  },
  {
    "uniprot": "A4I1M5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1M5"
  },
  {
    "uniprot": "A4HS06",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS06"
  },
  {
    "uniprot": "A4I7Y7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7Y7"
  },
  {
    "uniprot": "A4ICR6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICR6"
  },
  {
    "uniprot": "A4I4G3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4G3"
  },
  {
    "uniprot": "A4IDJ6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDJ6"
  },
  {
    "uniprot": "A4IB89",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB89"
  },
  {
    "uniprot": "A4IDB2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDB2"
  },
  {
    "uniprot": "A4I847",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I847"
  },
  {
    "uniprot": "A4HSH2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSH2"
  },
  {
    "uniprot": "A4I7X4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7X4"
  },
  {
    "uniprot": "A4I8Y4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Y4"
  },
  {
    "uniprot": "A4I8X6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8X6"
  },
  {
    "uniprot": "A4IDL5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDL5"
  },
  {
    "uniprot": "A4I197",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I197"
  },
  {
    "uniprot": "A4HSK8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSK8"
  },
  {
    "uniprot": "E9AHY9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHY9"
  },
  {
    "uniprot": "A4I3F9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3F9"
  },
  {
    "uniprot": "A4I9N6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9N6"
  },
  {
    "uniprot": "A4HYB9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYB9"
  },
  {
    "uniprot": "A4IBM6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBM6"
  },
  {
    "uniprot": "A4HUN9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUN9"
  },
  {
    "uniprot": "A4ICE3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICE3"
  },
  {
    "uniprot": "A4HXQ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXQ2"
  },
  {
    "uniprot": "A4I0W8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0W8"
  },
  {
    "uniprot": "A4HWS2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWS2"
  },
  {
    "uniprot": "A4ICF8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICF8"
  },
  {
    "uniprot": "A4HZW8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZW8"
  },
  {
    "uniprot": "A4I4Q7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4Q7"
  },
  {
    "uniprot": "A4I5Y4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5Y4"
  },
  {
    "uniprot": "A4HYH9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYH9"
  },
  {
    "uniprot": "A4I3J8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3J8"
  },
  {
    "uniprot": "A4ICJ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICJ9"
  },
  {
    "uniprot": "A4HYM2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYM2"
  },
  {
    "uniprot": "A4IB66",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB66"
  },
  {
    "uniprot": "A4I1G7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1G7"
  },
  {
    "uniprot": "A4IBZ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBZ4"
  },
  {
    "uniprot": "A4HYQ1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYQ1"
  },
  {
    "uniprot": "E9AHP5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHP5"
  },
  {
    "uniprot": "A4I5X5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5X5"
  },
  {
    "uniprot": "A4HXB0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXB0"
  },
  {
    "uniprot": "A4HUM5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUM5"
  },
  {
    "uniprot": "A4I9Y4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9Y4"
  },
  {
    "uniprot": "A4HY75",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY75"
  },
  {
    "uniprot": "A4I7M1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7M1"
  },
  {
    "uniprot": "A4I5C8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I5C8"
  },
  {
    "uniprot": "A4I7A0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7A0"
  },
  {
    "uniprot": "E9AHJ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHJ7"
  },
  {
    "uniprot": "A4I195",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I195"
  },
  {
    "uniprot": "A4HX25",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX25"
  },
  {
    "uniprot": "A4I7S4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7S4"
  },
  {
    "uniprot": "A4ID48",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ID48"
  },
  {
    "uniprot": "A4HTG1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTG1"
  },
  {
    "uniprot": "A4I1X6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I1X6"
  },
  {
    "uniprot": "A4I525",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I525"
  },
  {
    "uniprot": "A4HWC7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWC7"
  },
  {
    "uniprot": "A4HWL8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWL8"
  },
  {
    "uniprot": "A4IC84",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC84"
  },
  {
    "uniprot": "A4I9I7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9I7"
  },
  {
    "uniprot": "A4I7U0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7U0"
  },
  {
    "uniprot": "E9AGB6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGB6"
  },
  {
    "uniprot": "A4I4U9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4U9"
  },
  {
    "uniprot": "A4HRJ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRJ7"
  },
  {
    "uniprot": "A4IB74",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IB74"
  },
  {
    "uniprot": "A4HWU8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWU8"
  },
  {
    "uniprot": "A4HYW1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYW1"
  },
  {
    "uniprot": "A4I8L0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8L0"
  },
  {
    "uniprot": "A4HWX9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWX9"
  },
  {
    "uniprot": "A4I0G4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0G4"
  },
  {
    "uniprot": "A4I0D1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0D1"
  },
  {
    "uniprot": "A4HRK7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRK7"
  },
  {
    "uniprot": "A4I9F4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9F4"
  },
  {
    "uniprot": "A4I4H1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4H1"
  },
  {
    "uniprot": "A4HXC9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXC9"
  },
  {
    "uniprot": "A4I7I2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7I2"
  },
  {
    "uniprot": "E9AGN5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGN5"
  },
  {
    "uniprot": "A4HXN6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXN6"
  },
  {
    "uniprot": "A4HUK7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUK7"
  },
  {
    "uniprot": "A4I143",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I143"
  },
  {
    "uniprot": "A4HS71",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HS71"
  },
  {
    "uniprot": "A4IC03",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IC03"
  },
  {
    "uniprot": "A4I9N3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9N3"
  },
  {
    "uniprot": "A4HYE5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYE5"
  },
  {
    "uniprot": "A4I0K0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0K0"
  },
  {
    "uniprot": "A4HWT0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWT0"
  },
  {
    "uniprot": "A4I4D3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4D3"
  },
  {
    "uniprot": "A4I4U6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4U6"
  },
  {
    "uniprot": "A4IDE2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDE2"
  },
  {
    "uniprot": "A4I4J6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4J6"
  },
  {
    "uniprot": "A4IBM7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBM7"
  },
  {
    "uniprot": "A4I602",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I602"
  },
  {
    "uniprot": "A4ICY5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICY5"
  },
  {
    "uniprot": "A4I632",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I632"
  },
  {
    "uniprot": "A4I9L5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I9L5"
  },
  {
    "uniprot": "A4I0T3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0T3"
  },
  {
    "uniprot": "A4I0U8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0U8"
  },
  {
    "uniprot": "A4HZZ4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HZZ4"
  },
  {
    "uniprot": "A4HUJ9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HUJ9"
  },
  {
    "uniprot": "E9AH26",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH26"
  },
  {
    "uniprot": "A4I8T5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8T5"
  },
  {
    "uniprot": "A4I4V5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I4V5"
  },
  {
    "uniprot": "A4IBM9",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBM9"
  },
  {
    "uniprot": "A4I8K2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8K2"
  },
  {
    "uniprot": "A4HXV5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXV5"
  },
  {
    "uniprot": "A4IAI5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IAI5"
  },
  {
    "uniprot": "A4IDV7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDV7"
  },
  {
    "uniprot": "Q8T9R3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/Q8T9R3"
  },
  {
    "uniprot": "A4HTZ8",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HTZ8"
  },
  {
    "uniprot": "A4IBR4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBR4"
  },
  {
    "uniprot": "A4IDZ2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IDZ2"
  },
  {
    "uniprot": "A4I8F0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8F0"
  },
  {
    "uniprot": "A4I8Q2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8Q2"
  },
  {
    "uniprot": "E9AGF5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AGF5"
  },
  {
    "uniprot": "A4ICM1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4ICM1"
  },
  {
    "uniprot": "A4HYD6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HYD6"
  },
  {
    "uniprot": "A0A381MH18",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A0A381MH18"
  },
  {
    "uniprot": "A4I0M0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I0M0"
  },
  {
    "uniprot": "A4HXT6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HXT6"
  },
  {
    "uniprot": "A4I573",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I573"
  },
  {
    "uniprot": "A4HWH5",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWH5"
  },
  {
    "uniprot": "A4I8P3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8P3"
  },
  {
    "uniprot": "A4HW89",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HW89"
  },
  {
    "uniprot": "A4HRM7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRM7"
  },
  {
    "uniprot": "E9AH22",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AH22"
  },
  {
    "uniprot": "A4I736",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I736"
  },
  {
    "uniprot": "E9AHD1",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHD1"
  },
  {
    "uniprot": "A4HT59",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HT59"
  },
  {
    "uniprot": "A4I3W6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I3W6"
  },
  {
    "uniprot": "A4HWM0",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HWM0"
  },
  {
    "uniprot": "A4HSQ7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HSQ7"
  },
  {
    "uniprot": "E9AHG6",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AHG6"
  },
  {
    "uniprot": "A4I8H3",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I8H3"
  },
  {
    "uniprot": "A4HY56",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HY56"
  },
  {
    "uniprot": "E9AG20",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/E9AG20"
  },
  {
    "uniprot": "A4HX35",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HX35"
  },
  {
    "uniprot": "A4IBY7",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4IBY7"
  },
  {
    "uniprot": "A4I7U4",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4I7U4"
  },
  {
    "uniprot": "A4HRT2",
    "species": "Leishmania infantum",
    "disease": "Leishmaniasis",
    "results": "/results/A4HRT2"
  },
  {
    "uniprot": "X8F0P6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0P6"
  },
  {
    "uniprot": "X8EZE8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZE8"
  },
  {
    "uniprot": "X8F703",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F703"
  },
  {
    "uniprot": "X8FNT9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNT9"
  },
  {
    "uniprot": "X8FCJ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCJ8"
  },
  {
    "uniprot": "X8F5V4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5V4"
  },
  {
    "uniprot": "X8F8T4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8T4"
  },
  {
    "uniprot": "X8EXK2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXK2"
  },
  {
    "uniprot": "X8F1Z6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Z6"
  },
  {
    "uniprot": "X8F4R6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4R6"
  },
  {
    "uniprot": "X8FB48",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB48"
  },
  {
    "uniprot": "X8FIM4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIM4"
  },
  {
    "uniprot": "X8F913",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F913"
  },
  {
    "uniprot": "X8FEC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEC0"
  },
  {
    "uniprot": "X8FBL8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBL8"
  },
  {
    "uniprot": "X8FPR9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPR9"
  },
  {
    "uniprot": "X8F246",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F246"
  },
  {
    "uniprot": "X8FEH9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEH9"
  },
  {
    "uniprot": "X8FM33",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM33"
  },
  {
    "uniprot": "X8F5F0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5F0"
  },
  {
    "uniprot": "X8F5A4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5A4"
  },
  {
    "uniprot": "X8FLP4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLP4"
  },
  {
    "uniprot": "X8FLG0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLG0"
  },
  {
    "uniprot": "X8F5N5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5N5"
  },
  {
    "uniprot": "X8F7N7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7N7"
  },
  {
    "uniprot": "X8FE06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE06"
  },
  {
    "uniprot": "X8FKR3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKR3"
  },
  {
    "uniprot": "X8FAF2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAF2"
  },
  {
    "uniprot": "X8FN51",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN51"
  },
  {
    "uniprot": "X8F478",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F478"
  },
  {
    "uniprot": "X8FED4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FED4"
  },
  {
    "uniprot": "X8F682",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F682"
  },
  {
    "uniprot": "X8FJX6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJX6"
  },
  {
    "uniprot": "X8FJE9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJE9"
  },
  {
    "uniprot": "X8FBG6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBG6"
  },
  {
    "uniprot": "X8FG12",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG12"
  },
  {
    "uniprot": "X8EZZ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZZ6"
  },
  {
    "uniprot": "X8FDF8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDF8"
  },
  {
    "uniprot": "X8F5U8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5U8"
  },
  {
    "uniprot": "X8F4Y7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Y7"
  },
  {
    "uniprot": "X8FLY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLY9"
  },
  {
    "uniprot": "X8FE41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE41"
  },
  {
    "uniprot": "X8F8A0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8A0"
  },
  {
    "uniprot": "X8F6W5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6W5"
  },
  {
    "uniprot": "X8FC69",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC69"
  },
  {
    "uniprot": "X8F989",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F989"
  },
  {
    "uniprot": "X8F856",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F856"
  },
  {
    "uniprot": "X8EZN1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZN1"
  },
  {
    "uniprot": "X8F3N2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3N2"
  },
  {
    "uniprot": "X8FJJ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJJ8"
  },
  {
    "uniprot": "X8FEU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEU0"
  },
  {
    "uniprot": "X8FFI7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFI7"
  },
  {
    "uniprot": "X8FNS8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNS8"
  },
  {
    "uniprot": "X8F8B3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8B3"
  },
  {
    "uniprot": "X8FKI0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKI0"
  },
  {
    "uniprot": "X8F0M4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0M4"
  },
  {
    "uniprot": "X8F8Q7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Q7"
  },
  {
    "uniprot": "X8FBN5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBN5"
  },
  {
    "uniprot": "X8FQH1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQH1"
  },
  {
    "uniprot": "X8F1I5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1I5"
  },
  {
    "uniprot": "X8EY72",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY72"
  },
  {
    "uniprot": "X8FCL5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCL5"
  },
  {
    "uniprot": "X8FPW5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPW5"
  },
  {
    "uniprot": "X8FCM1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCM1"
  },
  {
    "uniprot": "X8FL58",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL58"
  },
  {
    "uniprot": "X8FHW7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHW7"
  },
  {
    "uniprot": "X8F7T4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7T4"
  },
  {
    "uniprot": "X8FKD1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKD1"
  },
  {
    "uniprot": "X8FEL8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEL8"
  },
  {
    "uniprot": "X8F4L8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4L8"
  },
  {
    "uniprot": "X8FHX2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHX2"
  },
  {
    "uniprot": "X8EY35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY35"
  },
  {
    "uniprot": "X8F7B4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7B4"
  },
  {
    "uniprot": "X8FD09",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD09"
  },
  {
    "uniprot": "X8F5T2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5T2"
  },
  {
    "uniprot": "X8FND2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FND2"
  },
  {
    "uniprot": "X8FBJ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBJ4"
  },
  {
    "uniprot": "X8FID5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FID5"
  },
  {
    "uniprot": "X8F947",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F947"
  },
  {
    "uniprot": "X8FP33",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP33"
  },
  {
    "uniprot": "X8FIG7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIG7"
  },
  {
    "uniprot": "X8FDT1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDT1"
  },
  {
    "uniprot": "X8F1W3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1W3"
  },
  {
    "uniprot": "X8F3S9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3S9"
  },
  {
    "uniprot": "X8FM49",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM49"
  },
  {
    "uniprot": "X8F1T2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1T2"
  },
  {
    "uniprot": "X8FN41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN41"
  },
  {
    "uniprot": "X8FMD9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMD9"
  },
  {
    "uniprot": "X8FBN6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBN6"
  },
  {
    "uniprot": "X8FJD6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJD6"
  },
  {
    "uniprot": "X8F360",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F360"
  },
  {
    "uniprot": "X8EYL0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYL0"
  },
  {
    "uniprot": "X8FLC4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLC4"
  },
  {
    "uniprot": "X8FLD9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLD9"
  },
  {
    "uniprot": "X8F866",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F866"
  },
  {
    "uniprot": "X8F039",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F039"
  },
  {
    "uniprot": "X8FJ30",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ30"
  },
  {
    "uniprot": "X8FBE1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBE1"
  },
  {
    "uniprot": "X8FC32",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC32"
  },
  {
    "uniprot": "X8FNP6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNP6"
  },
  {
    "uniprot": "X8F9H9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9H9"
  },
  {
    "uniprot": "X8F4Q4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Q4"
  },
  {
    "uniprot": "X8F6P8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6P8"
  },
  {
    "uniprot": "X8FFX8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFX8"
  },
  {
    "uniprot": "X8FKF6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKF6"
  },
  {
    "uniprot": "X8FJE3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJE3"
  },
  {
    "uniprot": "X8F4E4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4E4"
  },
  {
    "uniprot": "X8F162",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F162"
  },
  {
    "uniprot": "X8F541",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F541"
  },
  {
    "uniprot": "X8F9R6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9R6"
  },
  {
    "uniprot": "X8F225",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F225"
  },
  {
    "uniprot": "X8EXD4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXD4"
  },
  {
    "uniprot": "X8FDR5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDR5"
  },
  {
    "uniprot": "X8F5C0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5C0"
  },
  {
    "uniprot": "X8F903",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F903"
  },
  {
    "uniprot": "X8F1P2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1P2"
  },
  {
    "uniprot": "X8FAZ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAZ3"
  },
  {
    "uniprot": "X8F203",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F203"
  },
  {
    "uniprot": "X8FKZ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKZ1"
  },
  {
    "uniprot": "X8FL14",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL14"
  },
  {
    "uniprot": "X8FPG2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPG2"
  },
  {
    "uniprot": "X8FHY7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHY7"
  },
  {
    "uniprot": "X8F605",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F605"
  },
  {
    "uniprot": "X8F0R9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0R9"
  },
  {
    "uniprot": "X8EZJ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZJ9"
  },
  {
    "uniprot": "X8FJY6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJY6"
  },
  {
    "uniprot": "X8FIU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIU0"
  },
  {
    "uniprot": "X8F2V1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2V1"
  },
  {
    "uniprot": "X8F6D9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6D9"
  },
  {
    "uniprot": "X8FKP6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKP6"
  },
  {
    "uniprot": "X8FEW3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEW3"
  },
  {
    "uniprot": "X8FK41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK41"
  },
  {
    "uniprot": "X8FA02",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA02"
  },
  {
    "uniprot": "X8F2L5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2L5"
  },
  {
    "uniprot": "X8FFS3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFS3"
  },
  {
    "uniprot": "X8F764",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F764"
  },
  {
    "uniprot": "X8FFE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFE2"
  },
  {
    "uniprot": "X8F538",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F538"
  },
  {
    "uniprot": "X8EX67",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX67"
  },
  {
    "uniprot": "X8F617",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F617"
  },
  {
    "uniprot": "X8F875",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F875"
  },
  {
    "uniprot": "X8F9V3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9V3"
  },
  {
    "uniprot": "X8FHZ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHZ1"
  },
  {
    "uniprot": "X8F851",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F851"
  },
  {
    "uniprot": "X8FKC5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKC5"
  },
  {
    "uniprot": "X8F3A0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3A0"
  },
  {
    "uniprot": "X8F7L8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7L8"
  },
  {
    "uniprot": "X8F549",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F549"
  },
  {
    "uniprot": "X8F7U2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7U2"
  },
  {
    "uniprot": "X8FLE8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLE8"
  },
  {
    "uniprot": "X8FCK6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCK6"
  },
  {
    "uniprot": "X8EZV2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZV2"
  },
  {
    "uniprot": "X8F9W8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9W8"
  },
  {
    "uniprot": "X8FBM3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBM3"
  },
  {
    "uniprot": "X8FD08",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD08"
  },
  {
    "uniprot": "X8FHZ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHZ0"
  },
  {
    "uniprot": "X8F3M6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3M6"
  },
  {
    "uniprot": "X8FHK5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHK5"
  },
  {
    "uniprot": "X8F434",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F434"
  },
  {
    "uniprot": "X8F0Z5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Z5"
  },
  {
    "uniprot": "X8FC78",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC78"
  },
  {
    "uniprot": "X8F5I3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5I3"
  },
  {
    "uniprot": "X8F0G0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0G0"
  },
  {
    "uniprot": "X8FL72",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL72"
  },
  {
    "uniprot": "X8FHQ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHQ5"
  },
  {
    "uniprot": "X8FLI1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLI1"
  },
  {
    "uniprot": "X8F464",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F464"
  },
  {
    "uniprot": "X8FDG4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDG4"
  },
  {
    "uniprot": "X8F0N6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0N6"
  },
  {
    "uniprot": "X8FDY7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDY7"
  },
  {
    "uniprot": "X8FI53",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI53"
  },
  {
    "uniprot": "X8F2K5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2K5"
  },
  {
    "uniprot": "X8FG26",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG26"
  },
  {
    "uniprot": "X8F2A5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2A5"
  },
  {
    "uniprot": "X8FGW2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGW2"
  },
  {
    "uniprot": "X8F077",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F077"
  },
  {
    "uniprot": "X8FDN1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDN1"
  },
  {
    "uniprot": "X8FIK4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIK4"
  },
  {
    "uniprot": "X8F1M9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1M9"
  },
  {
    "uniprot": "X8FDD9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDD9"
  },
  {
    "uniprot": "X8F4G5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4G5"
  },
  {
    "uniprot": "X8FL27",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL27"
  },
  {
    "uniprot": "X8FCY7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCY7"
  },
  {
    "uniprot": "X8FKH9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKH9"
  },
  {
    "uniprot": "X8FIW1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIW1"
  },
  {
    "uniprot": "X8F4M5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4M5"
  },
  {
    "uniprot": "X8F8Q4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Q4"
  },
  {
    "uniprot": "X8EZ50",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ50"
  },
  {
    "uniprot": "X8EZA2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZA2"
  },
  {
    "uniprot": "X8FKF5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKF5"
  },
  {
    "uniprot": "X8FBT6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBT6"
  },
  {
    "uniprot": "X8F6Y6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Y6"
  },
  {
    "uniprot": "X8FAP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAP9"
  },
  {
    "uniprot": "X8FKH6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKH6"
  },
  {
    "uniprot": "X8FMQ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMQ5"
  },
  {
    "uniprot": "X8F5G2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5G2"
  },
  {
    "uniprot": "X8FA11",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA11"
  },
  {
    "uniprot": "X8F1L9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1L9"
  },
  {
    "uniprot": "X8FAM2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAM2"
  },
  {
    "uniprot": "X8FL96",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL96"
  },
  {
    "uniprot": "X8FL05",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL05"
  },
  {
    "uniprot": "X8F758",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F758"
  },
  {
    "uniprot": "X8FBP8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBP8"
  },
  {
    "uniprot": "X8FNF9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNF9"
  },
  {
    "uniprot": "X8FIQ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIQ1"
  },
  {
    "uniprot": "X8FFB1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFB1"
  },
  {
    "uniprot": "X8F9P5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9P5"
  },
  {
    "uniprot": "X8FJ01",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ01"
  },
  {
    "uniprot": "X8FAG3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAG3"
  },
  {
    "uniprot": "X8EXP8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXP8"
  },
  {
    "uniprot": "X8F435",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F435"
  },
  {
    "uniprot": "X8F3T8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3T8"
  },
  {
    "uniprot": "X8F7Z7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Z7"
  },
  {
    "uniprot": "X8F376",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F376"
  },
  {
    "uniprot": "X8F6R9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6R9"
  },
  {
    "uniprot": "X8FCY0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCY0"
  },
  {
    "uniprot": "X8FHK7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHK7"
  },
  {
    "uniprot": "X8F2H9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2H9"
  },
  {
    "uniprot": "X8FJJ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJJ3"
  },
  {
    "uniprot": "X8FLX6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLX6"
  },
  {
    "uniprot": "X8FMJ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMJ1"
  },
  {
    "uniprot": "X8FM89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM89"
  },
  {
    "uniprot": "X8FAW4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAW4"
  },
  {
    "uniprot": "X8FIY8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIY8"
  },
  {
    "uniprot": "X8FKR8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKR8"
  },
  {
    "uniprot": "X8FMC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMC0"
  },
  {
    "uniprot": "X8EXC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXC8"
  },
  {
    "uniprot": "X8FBN4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBN4"
  },
  {
    "uniprot": "X8FG56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG56"
  },
  {
    "uniprot": "X8F5N9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5N9"
  },
  {
    "uniprot": "X8F3K6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3K6"
  },
  {
    "uniprot": "X8F0W8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0W8"
  },
  {
    "uniprot": "X8F016",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F016"
  },
  {
    "uniprot": "X8FLP1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLP1"
  },
  {
    "uniprot": "X8FCW6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCW6"
  },
  {
    "uniprot": "X8FK51",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK51"
  },
  {
    "uniprot": "X8EYS3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYS3"
  },
  {
    "uniprot": "X8FND8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FND8"
  },
  {
    "uniprot": "X8FKP3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKP3"
  },
  {
    "uniprot": "X8FCF1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCF1"
  },
  {
    "uniprot": "X8FEV9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEV9"
  },
  {
    "uniprot": "X8F748",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F748"
  },
  {
    "uniprot": "X8FBU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBU0"
  },
  {
    "uniprot": "X8FHF6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHF6"
  },
  {
    "uniprot": "X8FIE0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIE0"
  },
  {
    "uniprot": "X8F269",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F269"
  },
  {
    "uniprot": "X8F1E9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1E9"
  },
  {
    "uniprot": "X8F7Y3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Y3"
  },
  {
    "uniprot": "X8F367",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F367"
  },
  {
    "uniprot": "X8F228",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F228"
  },
  {
    "uniprot": "X8FAB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAB3"
  },
  {
    "uniprot": "X8F9A7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9A7"
  },
  {
    "uniprot": "X8FBJ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBJ3"
  },
  {
    "uniprot": "X8F3U1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3U1"
  },
  {
    "uniprot": "X8FDH1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDH1"
  },
  {
    "uniprot": "X8F4V6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4V6"
  },
  {
    "uniprot": "X8FL40",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL40"
  },
  {
    "uniprot": "X8FLC9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLC9"
  },
  {
    "uniprot": "X8FJU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJU0"
  },
  {
    "uniprot": "X8FPQ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPQ7"
  },
  {
    "uniprot": "X8FNJ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNJ9"
  },
  {
    "uniprot": "X8FE67",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE67"
  },
  {
    "uniprot": "X8F0S7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0S7"
  },
  {
    "uniprot": "X8F5G1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5G1"
  },
  {
    "uniprot": "X8FC28",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC28"
  },
  {
    "uniprot": "X8F9I8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9I8"
  },
  {
    "uniprot": "X8F9Q3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Q3"
  },
  {
    "uniprot": "X8FI41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI41"
  },
  {
    "uniprot": "X8EWQ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWQ3"
  },
  {
    "uniprot": "X8FHI9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHI9"
  },
  {
    "uniprot": "X8FJW1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJW1"
  },
  {
    "uniprot": "X8FJ21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ21"
  },
  {
    "uniprot": "X8FI15",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI15"
  },
  {
    "uniprot": "X8FQ03",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQ03"
  },
  {
    "uniprot": "X8F2U0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2U0"
  },
  {
    "uniprot": "X8F1K6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1K6"
  },
  {
    "uniprot": "X8FAE5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAE5"
  },
  {
    "uniprot": "X8FE77",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE77"
  },
  {
    "uniprot": "X8F7I2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7I2"
  },
  {
    "uniprot": "X8FIE8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIE8"
  },
  {
    "uniprot": "X8FB51",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB51"
  },
  {
    "uniprot": "X8FNE7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNE7"
  },
  {
    "uniprot": "X8F6G6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6G6"
  },
  {
    "uniprot": "X8F2H5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2H5"
  },
  {
    "uniprot": "X8FC67",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC67"
  },
  {
    "uniprot": "X8F3M5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3M5"
  },
  {
    "uniprot": "X8FEU8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEU8"
  },
  {
    "uniprot": "X8FLN4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLN4"
  },
  {
    "uniprot": "X8FFE0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFE0"
  },
  {
    "uniprot": "X8F8N0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8N0"
  },
  {
    "uniprot": "X8EXA0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXA0"
  },
  {
    "uniprot": "X8FE26",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE26"
  },
  {
    "uniprot": "X8FC75",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC75"
  },
  {
    "uniprot": "X8FIB1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIB1"
  },
  {
    "uniprot": "X8FFR9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFR9"
  },
  {
    "uniprot": "X8FJN2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJN2"
  },
  {
    "uniprot": "X8F324",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F324"
  },
  {
    "uniprot": "X8FIC9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIC9"
  },
  {
    "uniprot": "X8FB34",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB34"
  },
  {
    "uniprot": "X8FAA5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAA5"
  },
  {
    "uniprot": "X8FJF7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJF7"
  },
  {
    "uniprot": "X8FFE7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFE7"
  },
  {
    "uniprot": "X8FCH3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCH3"
  },
  {
    "uniprot": "X8F8A1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8A1"
  },
  {
    "uniprot": "X8F2E1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2E1"
  },
  {
    "uniprot": "X8F8W0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8W0"
  },
  {
    "uniprot": "X8F6B2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6B2"
  },
  {
    "uniprot": "X8F002",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F002"
  },
  {
    "uniprot": "X8FK19",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK19"
  },
  {
    "uniprot": "X8FFB2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFB2"
  },
  {
    "uniprot": "X8FAF5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAF5"
  },
  {
    "uniprot": "X8F9C6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9C6"
  },
  {
    "uniprot": "X8F5V9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5V9"
  },
  {
    "uniprot": "X8FGZ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGZ2"
  },
  {
    "uniprot": "X8FF53",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF53"
  },
  {
    "uniprot": "X8F1E2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1E2"
  },
  {
    "uniprot": "X8FF70",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF70"
  },
  {
    "uniprot": "X8F5U0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5U0"
  },
  {
    "uniprot": "X8F5G4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5G4"
  },
  {
    "uniprot": "X8FN61",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN61"
  },
  {
    "uniprot": "X8FNU7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNU7"
  },
  {
    "uniprot": "X8FL69",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL69"
  },
  {
    "uniprot": "X8F443",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F443"
  },
  {
    "uniprot": "X8F3G5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3G5"
  },
  {
    "uniprot": "X8F4X4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4X4"
  },
  {
    "uniprot": "X8EZX3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZX3"
  },
  {
    "uniprot": "X8F6U5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6U5"
  },
  {
    "uniprot": "X8F390",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F390"
  },
  {
    "uniprot": "X8F3Q6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Q6"
  },
  {
    "uniprot": "X8FJ74",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ74"
  },
  {
    "uniprot": "X8FKH2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKH2"
  },
  {
    "uniprot": "X8F9C5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9C5"
  },
  {
    "uniprot": "X8EZ31",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ31"
  },
  {
    "uniprot": "X8FB56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB56"
  },
  {
    "uniprot": "X8F628",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F628"
  },
  {
    "uniprot": "X8F9D6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9D6"
  },
  {
    "uniprot": "X8F618",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F618"
  },
  {
    "uniprot": "X8FEM7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEM7"
  },
  {
    "uniprot": "X8EXL0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXL0"
  },
  {
    "uniprot": "X8FCQ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCQ2"
  },
  {
    "uniprot": "X8FIR7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIR7"
  },
  {
    "uniprot": "X8F878",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F878"
  },
  {
    "uniprot": "X8FEF4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEF4"
  },
  {
    "uniprot": "X8EZ27",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ27"
  },
  {
    "uniprot": "X8F9G7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9G7"
  },
  {
    "uniprot": "X8FMZ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMZ9"
  },
  {
    "uniprot": "X8F403",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F403"
  },
  {
    "uniprot": "X8F0L4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0L4"
  },
  {
    "uniprot": "X8EVY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EVY9"
  },
  {
    "uniprot": "X8F932",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F932"
  },
  {
    "uniprot": "X8FDR4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDR4"
  },
  {
    "uniprot": "X8F3L6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3L6"
  },
  {
    "uniprot": "X8FC64",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC64"
  },
  {
    "uniprot": "X8FET5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FET5"
  },
  {
    "uniprot": "X8FDZ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDZ7"
  },
  {
    "uniprot": "X8FB12",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB12"
  },
  {
    "uniprot": "X8FIG9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIG9"
  },
  {
    "uniprot": "X8FCT0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCT0"
  },
  {
    "uniprot": "X8FMB4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMB4"
  },
  {
    "uniprot": "X8FBE8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBE8"
  },
  {
    "uniprot": "X8FEC9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEC9"
  },
  {
    "uniprot": "X8FET1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FET1"
  },
  {
    "uniprot": "X8FBC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBC8"
  },
  {
    "uniprot": "X8FHN7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHN7"
  },
  {
    "uniprot": "X8F9Y2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Y2"
  },
  {
    "uniprot": "X8FEF7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEF7"
  },
  {
    "uniprot": "X8F8V1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8V1"
  },
  {
    "uniprot": "X8F5C8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5C8"
  },
  {
    "uniprot": "X8F5M3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5M3"
  },
  {
    "uniprot": "X8F2Q7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Q7"
  },
  {
    "uniprot": "X8F4S4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4S4"
  },
  {
    "uniprot": "X8FJQ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJQ0"
  },
  {
    "uniprot": "X8F2P6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2P6"
  },
  {
    "uniprot": "X8F4T2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4T2"
  },
  {
    "uniprot": "X8FJF8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJF8"
  },
  {
    "uniprot": "X8FGN4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGN4"
  },
  {
    "uniprot": "X8F9L8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9L8"
  },
  {
    "uniprot": "X8F5R9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5R9"
  },
  {
    "uniprot": "X8F676",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F676"
  },
  {
    "uniprot": "X8F629",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F629"
  },
  {
    "uniprot": "X8EZS5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZS5"
  },
  {
    "uniprot": "X8FIP2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIP2"
  },
  {
    "uniprot": "X8FL61",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL61"
  },
  {
    "uniprot": "X8F6T9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6T9"
  },
  {
    "uniprot": "X8FH72",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH72"
  },
  {
    "uniprot": "X8FLA5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLA5"
  },
  {
    "uniprot": "X8FBY0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBY0"
  },
  {
    "uniprot": "X8F3K3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3K3"
  },
  {
    "uniprot": "X8FMY8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMY8"
  },
  {
    "uniprot": "X8FEK4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEK4"
  },
  {
    "uniprot": "X8FIE3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIE3"
  },
  {
    "uniprot": "X8FE76",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE76"
  },
  {
    "uniprot": "X8F3E5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3E5"
  },
  {
    "uniprot": "X8F5Y4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Y4"
  },
  {
    "uniprot": "X8FP48",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP48"
  },
  {
    "uniprot": "X8FPG9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPG9"
  },
  {
    "uniprot": "X8FFU6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFU6"
  },
  {
    "uniprot": "X8F6P0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6P0"
  },
  {
    "uniprot": "X8FKG9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKG9"
  },
  {
    "uniprot": "X8FMR0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMR0"
  },
  {
    "uniprot": "X8F6Z8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Z8"
  },
  {
    "uniprot": "X8F0F4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0F4"
  },
  {
    "uniprot": "X8F0L0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0L0"
  },
  {
    "uniprot": "X8FJG0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJG0"
  },
  {
    "uniprot": "X8F7R6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7R6"
  },
  {
    "uniprot": "X8FEG8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEG8"
  },
  {
    "uniprot": "X8FC20",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC20"
  },
  {
    "uniprot": "X8FFJ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFJ0"
  },
  {
    "uniprot": "X8F2F1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2F1"
  },
  {
    "uniprot": "X8F7Z5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Z5"
  },
  {
    "uniprot": "X8EZZ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZZ9"
  },
  {
    "uniprot": "X8FHI7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHI7"
  },
  {
    "uniprot": "X8EW45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EW45"
  },
  {
    "uniprot": "X8FNC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNC7"
  },
  {
    "uniprot": "X8F4X8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4X8"
  },
  {
    "uniprot": "X8EZF5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZF5"
  },
  {
    "uniprot": "X8F9B2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9B2"
  },
  {
    "uniprot": "X8F7R3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7R3"
  },
  {
    "uniprot": "X8FLE6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLE6"
  },
  {
    "uniprot": "X8FHA1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHA1"
  },
  {
    "uniprot": "X8F6V2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6V2"
  },
  {
    "uniprot": "X8FK82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK82"
  },
  {
    "uniprot": "X8F648",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F648"
  },
  {
    "uniprot": "X8F1Q4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Q4"
  },
  {
    "uniprot": "X8F0E5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0E5"
  },
  {
    "uniprot": "X8F0L9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0L9"
  },
  {
    "uniprot": "X8F679",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F679"
  },
  {
    "uniprot": "X8FMQ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMQ3"
  },
  {
    "uniprot": "X8FIL0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIL0"
  },
  {
    "uniprot": "X8FPB4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPB4"
  },
  {
    "uniprot": "X8EY99",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY99"
  },
  {
    "uniprot": "X8FKF2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKF2"
  },
  {
    "uniprot": "X8FMV7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMV7"
  },
  {
    "uniprot": "X8FPH0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPH0"
  },
  {
    "uniprot": "X8FC01",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC01"
  },
  {
    "uniprot": "X8FHV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHV8"
  },
  {
    "uniprot": "X8F7U1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7U1"
  },
  {
    "uniprot": "X8F515",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F515"
  },
  {
    "uniprot": "X8FHS2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHS2"
  },
  {
    "uniprot": "X8FD93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD93"
  },
  {
    "uniprot": "X8FNC2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNC2"
  },
  {
    "uniprot": "X8F4W2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4W2"
  },
  {
    "uniprot": "X8EYK3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYK3"
  },
  {
    "uniprot": "X8F624",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F624"
  },
  {
    "uniprot": "X8F2M4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2M4"
  },
  {
    "uniprot": "X8FCG8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCG8"
  },
  {
    "uniprot": "X8F674",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F674"
  },
  {
    "uniprot": "X8F552",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F552"
  },
  {
    "uniprot": "X8F5W6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5W6"
  },
  {
    "uniprot": "X8F4I9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4I9"
  },
  {
    "uniprot": "X8FG83",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG83"
  },
  {
    "uniprot": "X8F3I6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3I6"
  },
  {
    "uniprot": "X8F2F5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2F5"
  },
  {
    "uniprot": "X8EYR1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYR1"
  },
  {
    "uniprot": "X8FJP4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJP4"
  },
  {
    "uniprot": "X8FCK2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCK2"
  },
  {
    "uniprot": "X8FBK3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBK3"
  },
  {
    "uniprot": "X8FIT6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIT6"
  },
  {
    "uniprot": "X8FIY4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIY4"
  },
  {
    "uniprot": "X8F2C7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2C7"
  },
  {
    "uniprot": "X8FHV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHV0"
  },
  {
    "uniprot": "X8FFW9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFW9"
  },
  {
    "uniprot": "X8F1L8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1L8"
  },
  {
    "uniprot": "X8FKY0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKY0"
  },
  {
    "uniprot": "X8EZG1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZG1"
  },
  {
    "uniprot": "X8FCA9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCA9"
  },
  {
    "uniprot": "X8FN79",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN79"
  },
  {
    "uniprot": "X8FM28",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM28"
  },
  {
    "uniprot": "X8FB80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB80"
  },
  {
    "uniprot": "X8F3R2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3R2"
  },
  {
    "uniprot": "X8FD79",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD79"
  },
  {
    "uniprot": "X8F3A8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3A8"
  },
  {
    "uniprot": "X8FGQ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGQ4"
  },
  {
    "uniprot": "X8F1T8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1T8"
  },
  {
    "uniprot": "X8FAI0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAI0"
  },
  {
    "uniprot": "X8F415",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F415"
  },
  {
    "uniprot": "X8F4J0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4J0"
  },
  {
    "uniprot": "X8FJB5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJB5"
  },
  {
    "uniprot": "X8F0S2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0S2"
  },
  {
    "uniprot": "X8F1M8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1M8"
  },
  {
    "uniprot": "X8FMP5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMP5"
  },
  {
    "uniprot": "X8F573",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F573"
  },
  {
    "uniprot": "X8FKY2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKY2"
  },
  {
    "uniprot": "X8FHK2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHK2"
  },
  {
    "uniprot": "X8F9Z4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Z4"
  },
  {
    "uniprot": "X8FMJ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMJ2"
  },
  {
    "uniprot": "X8FD40",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD40"
  },
  {
    "uniprot": "X8F5I6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5I6"
  },
  {
    "uniprot": "X8F770",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F770"
  },
  {
    "uniprot": "X8EYA4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYA4"
  },
  {
    "uniprot": "X8FG74",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG74"
  },
  {
    "uniprot": "X8F1B0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1B0"
  },
  {
    "uniprot": "X8FMC4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMC4"
  },
  {
    "uniprot": "X8FBQ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBQ8"
  },
  {
    "uniprot": "X8F627",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F627"
  },
  {
    "uniprot": "X8F577",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F577"
  },
  {
    "uniprot": "X8F254",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F254"
  },
  {
    "uniprot": "X8FHM2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHM2"
  },
  {
    "uniprot": "X8FBC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBC7"
  },
  {
    "uniprot": "X8FGH3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGH3"
  },
  {
    "uniprot": "X8F858",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F858"
  },
  {
    "uniprot": "X8FP77",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP77"
  },
  {
    "uniprot": "X8FAX5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAX5"
  },
  {
    "uniprot": "X8F057",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F057"
  },
  {
    "uniprot": "X8F6B6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6B6"
  },
  {
    "uniprot": "X8FNQ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNQ0"
  },
  {
    "uniprot": "X8F1V3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1V3"
  },
  {
    "uniprot": "X8FK50",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK50"
  },
  {
    "uniprot": "X8F3K8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3K8"
  },
  {
    "uniprot": "X8F1N1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1N1"
  },
  {
    "uniprot": "X8EZ72",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ72"
  },
  {
    "uniprot": "X8F4C5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4C5"
  },
  {
    "uniprot": "X8FIJ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIJ8"
  },
  {
    "uniprot": "X8F5J1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5J1"
  },
  {
    "uniprot": "X8F4T7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4T7"
  },
  {
    "uniprot": "X8F4V1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4V1"
  },
  {
    "uniprot": "X8F407",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F407"
  },
  {
    "uniprot": "X8F5C3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5C3"
  },
  {
    "uniprot": "X8F2P5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2P5"
  },
  {
    "uniprot": "X8F0H9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0H9"
  },
  {
    "uniprot": "X8F4S5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4S5"
  },
  {
    "uniprot": "X8FCU6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCU6"
  },
  {
    "uniprot": "X8F090",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F090"
  },
  {
    "uniprot": "X8FI57",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI57"
  },
  {
    "uniprot": "X8F699",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F699"
  },
  {
    "uniprot": "X8F9T5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9T5"
  },
  {
    "uniprot": "X8FJ57",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ57"
  },
  {
    "uniprot": "X8FLJ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLJ4"
  },
  {
    "uniprot": "X8F5E3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5E3"
  },
  {
    "uniprot": "X8FHA2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHA2"
  },
  {
    "uniprot": "X8FF78",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF78"
  },
  {
    "uniprot": "X8FMD5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMD5"
  },
  {
    "uniprot": "X8F807",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F807"
  },
  {
    "uniprot": "X8FAA9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAA9"
  },
  {
    "uniprot": "X8FDM9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDM9"
  },
  {
    "uniprot": "X8FF26",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF26"
  },
  {
    "uniprot": "X8FEQ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEQ5"
  },
  {
    "uniprot": "X8EY56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY56"
  },
  {
    "uniprot": "X8FLH1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLH1"
  },
  {
    "uniprot": "X8FKP4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKP4"
  },
  {
    "uniprot": "X8F2Y0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Y0"
  },
  {
    "uniprot": "X8FJ84",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ84"
  },
  {
    "uniprot": "X8F9B0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9B0"
  },
  {
    "uniprot": "X8FLX3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLX3"
  },
  {
    "uniprot": "X8FKM9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKM9"
  },
  {
    "uniprot": "X8FIG0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIG0"
  },
  {
    "uniprot": "X8FHN9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHN9"
  },
  {
    "uniprot": "X8FC12",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC12"
  },
  {
    "uniprot": "X8EXF7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXF7"
  },
  {
    "uniprot": "X8FMV3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMV3"
  },
  {
    "uniprot": "X8FFP8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFP8"
  },
  {
    "uniprot": "X8FAW0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAW0"
  },
  {
    "uniprot": "X8FHL2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHL2"
  },
  {
    "uniprot": "X8FLB6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLB6"
  },
  {
    "uniprot": "X8F4D7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4D7"
  },
  {
    "uniprot": "X8EZ55",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ55"
  },
  {
    "uniprot": "X8F4I8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4I8"
  },
  {
    "uniprot": "X8FE95",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE95"
  },
  {
    "uniprot": "X8FL51",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL51"
  },
  {
    "uniprot": "X8FJ27",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ27"
  },
  {
    "uniprot": "X8F136",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F136"
  },
  {
    "uniprot": "X8FFN6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFN6"
  },
  {
    "uniprot": "X8FN33",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN33"
  },
  {
    "uniprot": "X8F5V3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5V3"
  },
  {
    "uniprot": "X8FB08",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB08"
  },
  {
    "uniprot": "X8EY01",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY01"
  },
  {
    "uniprot": "X8F010",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F010"
  },
  {
    "uniprot": "X8FFT3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFT3"
  },
  {
    "uniprot": "X8FA95",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA95"
  },
  {
    "uniprot": "X8FG40",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG40"
  },
  {
    "uniprot": "X8F196",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F196"
  },
  {
    "uniprot": "X8FL08",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL08"
  },
  {
    "uniprot": "X8FAM5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAM5"
  },
  {
    "uniprot": "X8FIZ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIZ1"
  },
  {
    "uniprot": "X8FEB6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEB6"
  },
  {
    "uniprot": "X8F9R7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9R7"
  },
  {
    "uniprot": "X8FEH6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEH6"
  },
  {
    "uniprot": "X8FGQ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGQ7"
  },
  {
    "uniprot": "X8FJL5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJL5"
  },
  {
    "uniprot": "X8FAD0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAD0"
  },
  {
    "uniprot": "X8FDL4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDL4"
  },
  {
    "uniprot": "X8F2Q5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Q5"
  },
  {
    "uniprot": "X8F486",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F486"
  },
  {
    "uniprot": "X8FCG1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCG1"
  },
  {
    "uniprot": "X8F227",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F227"
  },
  {
    "uniprot": "X8FK86",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK86"
  },
  {
    "uniprot": "X8F262",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F262"
  },
  {
    "uniprot": "X8F651",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F651"
  },
  {
    "uniprot": "X8F179",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F179"
  },
  {
    "uniprot": "X8FKV6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKV6"
  },
  {
    "uniprot": "X8FC63",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC63"
  },
  {
    "uniprot": "X8FAS9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAS9"
  },
  {
    "uniprot": "X8EXJ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXJ0"
  },
  {
    "uniprot": "X8FHA5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHA5"
  },
  {
    "uniprot": "X8FNA5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNA5"
  },
  {
    "uniprot": "X8F7M9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7M9"
  },
  {
    "uniprot": "X8EYR8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYR8"
  },
  {
    "uniprot": "X8EX80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX80"
  },
  {
    "uniprot": "X8F1W2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1W2"
  },
  {
    "uniprot": "X8EYJ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYJ1"
  },
  {
    "uniprot": "X8FG93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG93"
  },
  {
    "uniprot": "X8FJN4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJN4"
  },
  {
    "uniprot": "X8FDY3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDY3"
  },
  {
    "uniprot": "X8FAC2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAC2"
  },
  {
    "uniprot": "X8F3B7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3B7"
  },
  {
    "uniprot": "X8F6I1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6I1"
  },
  {
    "uniprot": "X8FMA2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMA2"
  },
  {
    "uniprot": "X8FF52",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF52"
  },
  {
    "uniprot": "X8FB29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB29"
  },
  {
    "uniprot": "X8FCD4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCD4"
  },
  {
    "uniprot": "X8FLT4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLT4"
  },
  {
    "uniprot": "X8FC03",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC03"
  },
  {
    "uniprot": "X8F5Z5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Z5"
  },
  {
    "uniprot": "X8EZ97",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ97"
  },
  {
    "uniprot": "X8FJR0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJR0"
  },
  {
    "uniprot": "X8FKD2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKD2"
  },
  {
    "uniprot": "X8F2K9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2K9"
  },
  {
    "uniprot": "X8FAB5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAB5"
  },
  {
    "uniprot": "X8FCQ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCQ9"
  },
  {
    "uniprot": "X8FA52",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA52"
  },
  {
    "uniprot": "X8FJA5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJA5"
  },
  {
    "uniprot": "X8EXS4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXS4"
  },
  {
    "uniprot": "X8FAK4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAK4"
  },
  {
    "uniprot": "X8EZW6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZW6"
  },
  {
    "uniprot": "X8F8K3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8K3"
  },
  {
    "uniprot": "X8FKY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKY9"
  },
  {
    "uniprot": "X8FCR3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCR3"
  },
  {
    "uniprot": "X8FEQ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEQ3"
  },
  {
    "uniprot": "X8F7M3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7M3"
  },
  {
    "uniprot": "X8FLB1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLB1"
  },
  {
    "uniprot": "X8F9F5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9F5"
  },
  {
    "uniprot": "X8FFW5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFW5"
  },
  {
    "uniprot": "X8FGU2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGU2"
  },
  {
    "uniprot": "X8FBI3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBI3"
  },
  {
    "uniprot": "X8FGR1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGR1"
  },
  {
    "uniprot": "X8FDK7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDK7"
  },
  {
    "uniprot": "X8FLR8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLR8"
  },
  {
    "uniprot": "X8FFD1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFD1"
  },
  {
    "uniprot": "X8FA19",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA19"
  },
  {
    "uniprot": "X8FII4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FII4"
  },
  {
    "uniprot": "X8FGP3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGP3"
  },
  {
    "uniprot": "X8FIN7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIN7"
  },
  {
    "uniprot": "X8F5A8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5A8"
  },
  {
    "uniprot": "X8FJ02",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ02"
  },
  {
    "uniprot": "X8FEK6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEK6"
  },
  {
    "uniprot": "X8F7B3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7B3"
  },
  {
    "uniprot": "X8FHL8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHL8"
  },
  {
    "uniprot": "X8EYN1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYN1"
  },
  {
    "uniprot": "X8FEM9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEM9"
  },
  {
    "uniprot": "X8F1U1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1U1"
  },
  {
    "uniprot": "X8F258",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F258"
  },
  {
    "uniprot": "X8F3V0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3V0"
  },
  {
    "uniprot": "X8FH41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH41"
  },
  {
    "uniprot": "X8F3R4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3R4"
  },
  {
    "uniprot": "X8FFH6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFH6"
  },
  {
    "uniprot": "X8FFC9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFC9"
  },
  {
    "uniprot": "X8EXP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXP9"
  },
  {
    "uniprot": "X8F0D1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0D1"
  },
  {
    "uniprot": "X8F7K6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7K6"
  },
  {
    "uniprot": "X8FAQ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAQ5"
  },
  {
    "uniprot": "X8FFM8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFM8"
  },
  {
    "uniprot": "X8EZI7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZI7"
  },
  {
    "uniprot": "X8F850",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F850"
  },
  {
    "uniprot": "X8FEJ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEJ0"
  },
  {
    "uniprot": "X8FJH8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJH8"
  },
  {
    "uniprot": "X8F361",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F361"
  },
  {
    "uniprot": "X8FF35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF35"
  },
  {
    "uniprot": "X8F6J2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6J2"
  },
  {
    "uniprot": "X8FBS9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBS9"
  },
  {
    "uniprot": "X8F1V6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1V6"
  },
  {
    "uniprot": "X8F368",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F368"
  },
  {
    "uniprot": "X8F2K7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2K7"
  },
  {
    "uniprot": "X8F2A1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2A1"
  },
  {
    "uniprot": "X8FB41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB41"
  },
  {
    "uniprot": "X8FN88",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN88"
  },
  {
    "uniprot": "X8FF63",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF63"
  },
  {
    "uniprot": "X8FKH0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKH0"
  },
  {
    "uniprot": "X8FDP5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDP5"
  },
  {
    "uniprot": "X8FGI7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGI7"
  },
  {
    "uniprot": "X8EZD6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZD6"
  },
  {
    "uniprot": "X8FFA0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFA0"
  },
  {
    "uniprot": "X8F9L2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9L2"
  },
  {
    "uniprot": "X8F0I7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0I7"
  },
  {
    "uniprot": "X8FQ98",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQ98"
  },
  {
    "uniprot": "X8FGQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGQ6"
  },
  {
    "uniprot": "X8FAX1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAX1"
  },
  {
    "uniprot": "X8FJY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJY9"
  },
  {
    "uniprot": "X8FAF7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAF7"
  },
  {
    "uniprot": "X8FCR4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCR4"
  },
  {
    "uniprot": "X8FET2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FET2"
  },
  {
    "uniprot": "X8FKH8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKH8"
  },
  {
    "uniprot": "X8F1A9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1A9"
  },
  {
    "uniprot": "X8F9D9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9D9"
  },
  {
    "uniprot": "X8FJY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJY1"
  },
  {
    "uniprot": "X8FMA5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMA5"
  },
  {
    "uniprot": "X8FAK6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAK6"
  },
  {
    "uniprot": "X8FHH0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHH0"
  },
  {
    "uniprot": "X8FDC6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDC6"
  },
  {
    "uniprot": "X8EZA4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZA4"
  },
  {
    "uniprot": "X8FBP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBP9"
  },
  {
    "uniprot": "X8F1B4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1B4"
  },
  {
    "uniprot": "X8FF48",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF48"
  },
  {
    "uniprot": "X8F8D4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8D4"
  },
  {
    "uniprot": "X8FG34",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG34"
  },
  {
    "uniprot": "X8FGE7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGE7"
  },
  {
    "uniprot": "X8F6X3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6X3"
  },
  {
    "uniprot": "X8FKY7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKY7"
  },
  {
    "uniprot": "X8FLZ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLZ7"
  },
  {
    "uniprot": "X8EYK8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYK8"
  },
  {
    "uniprot": "X8F5U2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5U2"
  },
  {
    "uniprot": "X8FEW2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEW2"
  },
  {
    "uniprot": "X8FQ02",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQ02"
  },
  {
    "uniprot": "X8FFU4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFU4"
  },
  {
    "uniprot": "X8FG15",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG15"
  },
  {
    "uniprot": "X8F221",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F221"
  },
  {
    "uniprot": "X8F6W9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6W9"
  },
  {
    "uniprot": "X8EZH5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZH5"
  },
  {
    "uniprot": "X8FG28",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG28"
  },
  {
    "uniprot": "X8FHR2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHR2"
  },
  {
    "uniprot": "X8F2S9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2S9"
  },
  {
    "uniprot": "X8FP86",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP86"
  },
  {
    "uniprot": "X8FA29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA29"
  },
  {
    "uniprot": "X8FG77",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG77"
  },
  {
    "uniprot": "X8F2N8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2N8"
  },
  {
    "uniprot": "X8FIQ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIQ9"
  },
  {
    "uniprot": "X8F6Q9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Q9"
  },
  {
    "uniprot": "X8F8E3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8E3"
  },
  {
    "uniprot": "X8F1Y8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Y8"
  },
  {
    "uniprot": "X8FCS0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCS0"
  },
  {
    "uniprot": "X8FNT5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNT5"
  },
  {
    "uniprot": "X8FCU1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCU1"
  },
  {
    "uniprot": "X8EW67",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EW67"
  },
  {
    "uniprot": "X8FLI5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLI5"
  },
  {
    "uniprot": "X8EZ56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ56"
  },
  {
    "uniprot": "X8F7D2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7D2"
  },
  {
    "uniprot": "X8F9V5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9V5"
  },
  {
    "uniprot": "X8FHH7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHH7"
  },
  {
    "uniprot": "X8F698",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F698"
  },
  {
    "uniprot": "X8FMA6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMA6"
  },
  {
    "uniprot": "X8FJ44",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ44"
  },
  {
    "uniprot": "X8F0Q5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Q5"
  },
  {
    "uniprot": "X8FC58",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC58"
  },
  {
    "uniprot": "X8FF28",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF28"
  },
  {
    "uniprot": "X8FBM6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBM6"
  },
  {
    "uniprot": "X8FD65",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD65"
  },
  {
    "uniprot": "X8FCU5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCU5"
  },
  {
    "uniprot": "X8FM84",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM84"
  },
  {
    "uniprot": "X8FC09",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC09"
  },
  {
    "uniprot": "X8FCC6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCC6"
  },
  {
    "uniprot": "X8EZW8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZW8"
  },
  {
    "uniprot": "X8FNP8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNP8"
  },
  {
    "uniprot": "X8FMK3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMK3"
  },
  {
    "uniprot": "X8FL80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL80"
  },
  {
    "uniprot": "X8F0J7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0J7"
  },
  {
    "uniprot": "X8FE17",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE17"
  },
  {
    "uniprot": "X8F930",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F930"
  },
  {
    "uniprot": "X8F3D7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3D7"
  },
  {
    "uniprot": "X8F2I7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2I7"
  },
  {
    "uniprot": "X8F5X1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5X1"
  },
  {
    "uniprot": "X8FBF1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBF1"
  },
  {
    "uniprot": "X8F0V3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0V3"
  },
  {
    "uniprot": "X8F1D5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1D5"
  },
  {
    "uniprot": "X8FM01",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM01"
  },
  {
    "uniprot": "X8F7J1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7J1"
  },
  {
    "uniprot": "X8FBQ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBQ7"
  },
  {
    "uniprot": "X8F0V2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0V2"
  },
  {
    "uniprot": "X8FK61",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK61"
  },
  {
    "uniprot": "X8F568",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F568"
  },
  {
    "uniprot": "X8FJN1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJN1"
  },
  {
    "uniprot": "X8FA30",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA30"
  },
  {
    "uniprot": "X8FBF4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBF4"
  },
  {
    "uniprot": "X8FJT5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJT5"
  },
  {
    "uniprot": "X8FCR1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCR1"
  },
  {
    "uniprot": "X8F9F7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9F7"
  },
  {
    "uniprot": "X8F1H2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1H2"
  },
  {
    "uniprot": "X8F2R7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2R7"
  },
  {
    "uniprot": "X8FNS4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNS4"
  },
  {
    "uniprot": "X8FPB7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPB7"
  },
  {
    "uniprot": "X8FCS4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCS4"
  },
  {
    "uniprot": "X8FJM3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJM3"
  },
  {
    "uniprot": "X8F702",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F702"
  },
  {
    "uniprot": "X8F9G6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9G6"
  },
  {
    "uniprot": "X8F795",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F795"
  },
  {
    "uniprot": "X8FMD8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMD8"
  },
  {
    "uniprot": "X8FBF0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBF0"
  },
  {
    "uniprot": "X8FBW9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBW9"
  },
  {
    "uniprot": "X8F9L7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9L7"
  },
  {
    "uniprot": "X8F1R0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1R0"
  },
  {
    "uniprot": "X8F298",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F298"
  },
  {
    "uniprot": "X8F8N5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8N5"
  },
  {
    "uniprot": "X8FGJ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGJ4"
  },
  {
    "uniprot": "X8FEY4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEY4"
  },
  {
    "uniprot": "X8F842",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F842"
  },
  {
    "uniprot": "X8F1H8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1H8"
  },
  {
    "uniprot": "X8EYE0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYE0"
  },
  {
    "uniprot": "X8FI30",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI30"
  },
  {
    "uniprot": "X8FI84",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI84"
  },
  {
    "uniprot": "X8F3H7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3H7"
  },
  {
    "uniprot": "X8F3Y3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Y3"
  },
  {
    "uniprot": "X8F2Q2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Q2"
  },
  {
    "uniprot": "X8F4S8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4S8"
  },
  {
    "uniprot": "X8FG94",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG94"
  },
  {
    "uniprot": "X8F0W5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0W5"
  },
  {
    "uniprot": "X8FBM9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBM9"
  },
  {
    "uniprot": "X8F659",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F659"
  },
  {
    "uniprot": "X8FPC5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPC5"
  },
  {
    "uniprot": "X8F9K0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9K0"
  },
  {
    "uniprot": "X8FM25",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM25"
  },
  {
    "uniprot": "X8F1T7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1T7"
  },
  {
    "uniprot": "X8FAA0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAA0"
  },
  {
    "uniprot": "X8FNE3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNE3"
  },
  {
    "uniprot": "X8FHT8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHT8"
  },
  {
    "uniprot": "X8F1H9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1H9"
  },
  {
    "uniprot": "X8F125",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F125"
  },
  {
    "uniprot": "X8FC57",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC57"
  },
  {
    "uniprot": "X8EXT5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXT5"
  },
  {
    "uniprot": "X8FF30",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF30"
  },
  {
    "uniprot": "X8FGW9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGW9"
  },
  {
    "uniprot": "X8FJ69",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ69"
  },
  {
    "uniprot": "X8F1B2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1B2"
  },
  {
    "uniprot": "X8EZR2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZR2"
  },
  {
    "uniprot": "X8FJH2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJH2"
  },
  {
    "uniprot": "X8F0P3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0P3"
  },
  {
    "uniprot": "X8FH92",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH92"
  },
  {
    "uniprot": "X8F8G7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8G7"
  },
  {
    "uniprot": "X8EZQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZQ6"
  },
  {
    "uniprot": "X8EZJ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZJ3"
  },
  {
    "uniprot": "X8FHT6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHT6"
  },
  {
    "uniprot": "X8FGH6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGH6"
  },
  {
    "uniprot": "X8F9L9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9L9"
  },
  {
    "uniprot": "X8FCJ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCJ9"
  },
  {
    "uniprot": "X8FJH4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJH4"
  },
  {
    "uniprot": "X8FE15",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE15"
  },
  {
    "uniprot": "X8FGZ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGZ5"
  },
  {
    "uniprot": "X8F2V7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2V7"
  },
  {
    "uniprot": "X8F4N4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4N4"
  },
  {
    "uniprot": "X8FLE4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLE4"
  },
  {
    "uniprot": "X8FI79",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI79"
  },
  {
    "uniprot": "X8EXK7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXK7"
  },
  {
    "uniprot": "X8F924",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F924"
  },
  {
    "uniprot": "X8EXB6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXB6"
  },
  {
    "uniprot": "X8F755",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F755"
  },
  {
    "uniprot": "X8FP74",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP74"
  },
  {
    "uniprot": "X8FCA4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCA4"
  },
  {
    "uniprot": "X8FAH2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAH2"
  },
  {
    "uniprot": "X8F043",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F043"
  },
  {
    "uniprot": "X8FHC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHC8"
  },
  {
    "uniprot": "X8FAD6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAD6"
  },
  {
    "uniprot": "X8EYM4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYM4"
  },
  {
    "uniprot": "X8F2N9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2N9"
  },
  {
    "uniprot": "X8F660",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F660"
  },
  {
    "uniprot": "X8FLS0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLS0"
  },
  {
    "uniprot": "X8FF59",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF59"
  },
  {
    "uniprot": "X8FIK7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIK7"
  },
  {
    "uniprot": "X8FIU3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIU3"
  },
  {
    "uniprot": "X8F0M1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0M1"
  },
  {
    "uniprot": "X8F926",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F926"
  },
  {
    "uniprot": "X8FB05",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB05"
  },
  {
    "uniprot": "X8F6Q6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Q6"
  },
  {
    "uniprot": "X8FHX1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHX1"
  },
  {
    "uniprot": "X8FKW0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKW0"
  },
  {
    "uniprot": "X8FJE6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJE6"
  },
  {
    "uniprot": "X8FGB0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGB0"
  },
  {
    "uniprot": "X8F8U7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8U7"
  },
  {
    "uniprot": "X8FCQ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCQ0"
  },
  {
    "uniprot": "X8FJE4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJE4"
  },
  {
    "uniprot": "X8FI62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI62"
  },
  {
    "uniprot": "X8FJV5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJV5"
  },
  {
    "uniprot": "X8FHX0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHX0"
  },
  {
    "uniprot": "X8EZF9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZF9"
  },
  {
    "uniprot": "X8FHX3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHX3"
  },
  {
    "uniprot": "X8FGB8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGB8"
  },
  {
    "uniprot": "X8FEY7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEY7"
  },
  {
    "uniprot": "X8F8M1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8M1"
  },
  {
    "uniprot": "X8FIA0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIA0"
  },
  {
    "uniprot": "X8FLQ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLQ1"
  },
  {
    "uniprot": "X8F4Q7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Q7"
  },
  {
    "uniprot": "X8EYY4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYY4"
  },
  {
    "uniprot": "X8FFI0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFI0"
  },
  {
    "uniprot": "X8F870",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F870"
  },
  {
    "uniprot": "X8F996",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F996"
  },
  {
    "uniprot": "X8FCK7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCK7"
  },
  {
    "uniprot": "X8FMC6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMC6"
  },
  {
    "uniprot": "X8FF79",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF79"
  },
  {
    "uniprot": "X8F7N0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7N0"
  },
  {
    "uniprot": "X8F040",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F040"
  },
  {
    "uniprot": "X8FI68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI68"
  },
  {
    "uniprot": "X8F8S0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8S0"
  },
  {
    "uniprot": "X8FJJ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJJ4"
  },
  {
    "uniprot": "X8F4B4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4B4"
  },
  {
    "uniprot": "X8FLR5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLR5"
  },
  {
    "uniprot": "X8FC95",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC95"
  },
  {
    "uniprot": "X8FGN6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGN6"
  },
  {
    "uniprot": "X8FGD8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGD8"
  },
  {
    "uniprot": "X8FAL1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAL1"
  },
  {
    "uniprot": "X8FAT8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAT8"
  },
  {
    "uniprot": "X8EZY8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZY8"
  },
  {
    "uniprot": "X8F632",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F632"
  },
  {
    "uniprot": "X8F6C6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6C6"
  },
  {
    "uniprot": "X8FN54",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN54"
  },
  {
    "uniprot": "X8F8Y7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Y7"
  },
  {
    "uniprot": "X8FKN9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKN9"
  },
  {
    "uniprot": "X8F1Q3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Q3"
  },
  {
    "uniprot": "X8F1B8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1B8"
  },
  {
    "uniprot": "X8F8K8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8K8"
  },
  {
    "uniprot": "X8FI16",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI16"
  },
  {
    "uniprot": "X8EWU4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWU4"
  },
  {
    "uniprot": "X8F2S1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2S1"
  },
  {
    "uniprot": "X8F2X1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2X1"
  },
  {
    "uniprot": "X8EZ66",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ66"
  },
  {
    "uniprot": "X8F014",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F014"
  },
  {
    "uniprot": "X8F690",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F690"
  },
  {
    "uniprot": "X8F529",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F529"
  },
  {
    "uniprot": "X8FQJ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQJ9"
  },
  {
    "uniprot": "X8F6J6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6J6"
  },
  {
    "uniprot": "X8F2Q0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Q0"
  },
  {
    "uniprot": "X8FKQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKQ6"
  },
  {
    "uniprot": "X8F9K9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9K9"
  },
  {
    "uniprot": "X8F4Y5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Y5"
  },
  {
    "uniprot": "X8FNC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNC0"
  },
  {
    "uniprot": "X8FPZ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPZ0"
  },
  {
    "uniprot": "X8FIZ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIZ7"
  },
  {
    "uniprot": "X8F7U8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7U8"
  },
  {
    "uniprot": "X8F551",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F551"
  },
  {
    "uniprot": "X8F2C3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2C3"
  },
  {
    "uniprot": "X8FP90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP90"
  },
  {
    "uniprot": "X8F2S8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2S8"
  },
  {
    "uniprot": "X8FMM0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMM0"
  },
  {
    "uniprot": "X8FCB9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCB9"
  },
  {
    "uniprot": "X8FMS0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMS0"
  },
  {
    "uniprot": "X8FBY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBY1"
  },
  {
    "uniprot": "X8F9K2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9K2"
  },
  {
    "uniprot": "X8F5N2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5N2"
  },
  {
    "uniprot": "X8FC19",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC19"
  },
  {
    "uniprot": "X8F5P2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5P2"
  },
  {
    "uniprot": "X8F1J4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1J4"
  },
  {
    "uniprot": "X8F8B2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8B2"
  },
  {
    "uniprot": "X8FK18",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK18"
  },
  {
    "uniprot": "X8EZB6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZB6"
  },
  {
    "uniprot": "X8FAA4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAA4"
  },
  {
    "uniprot": "X8F0B7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0B7"
  },
  {
    "uniprot": "X8FPA1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPA1"
  },
  {
    "uniprot": "X8FGG6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGG6"
  },
  {
    "uniprot": "X8F145",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F145"
  },
  {
    "uniprot": "X8F2E0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2E0"
  },
  {
    "uniprot": "X8F786",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F786"
  },
  {
    "uniprot": "X8FDS5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDS5"
  },
  {
    "uniprot": "X8F0Q8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Q8"
  },
  {
    "uniprot": "X8FBT9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBT9"
  },
  {
    "uniprot": "X8F0L3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0L3"
  },
  {
    "uniprot": "X8F6I7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6I7"
  },
  {
    "uniprot": "X8F985",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F985"
  },
  {
    "uniprot": "X8FGC2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGC2"
  },
  {
    "uniprot": "X8F6Q2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Q2"
  },
  {
    "uniprot": "X8F524",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F524"
  },
  {
    "uniprot": "X8FHQ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHQ4"
  },
  {
    "uniprot": "X8FJ76",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ76"
  },
  {
    "uniprot": "X8FFE4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFE4"
  },
  {
    "uniprot": "X8FL18",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL18"
  },
  {
    "uniprot": "X8F172",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F172"
  },
  {
    "uniprot": "X8FJ65",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ65"
  },
  {
    "uniprot": "X8FBF9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBF9"
  },
  {
    "uniprot": "X8FEL1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEL1"
  },
  {
    "uniprot": "X8FDG1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDG1"
  },
  {
    "uniprot": "X8F8D0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8D0"
  },
  {
    "uniprot": "X8F727",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F727"
  },
  {
    "uniprot": "X8FN49",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN49"
  },
  {
    "uniprot": "X8F3F0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3F0"
  },
  {
    "uniprot": "X8FC59",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC59"
  },
  {
    "uniprot": "X8FEH0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEH0"
  },
  {
    "uniprot": "X8F0S9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0S9"
  },
  {
    "uniprot": "X8EYE1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYE1"
  },
  {
    "uniprot": "X8FLP2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLP2"
  },
  {
    "uniprot": "X8F5N3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5N3"
  },
  {
    "uniprot": "X8F3D5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3D5"
  },
  {
    "uniprot": "X8FA47",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA47"
  },
  {
    "uniprot": "X8FIL1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIL1"
  },
  {
    "uniprot": "X8FPX0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPX0"
  },
  {
    "uniprot": "X8FM42",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM42"
  },
  {
    "uniprot": "X8F5M0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5M0"
  },
  {
    "uniprot": "X8F777",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F777"
  },
  {
    "uniprot": "X8EZM6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZM6"
  },
  {
    "uniprot": "X8EYA9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYA9"
  },
  {
    "uniprot": "X8FDK0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDK0"
  },
  {
    "uniprot": "X8FCB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCB3"
  },
  {
    "uniprot": "X8FI23",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI23"
  },
  {
    "uniprot": "X8EZU3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZU3"
  },
  {
    "uniprot": "X8EY08",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY08"
  },
  {
    "uniprot": "X8FBZ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBZ2"
  },
  {
    "uniprot": "X8FKG7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKG7"
  },
  {
    "uniprot": "X8F6H5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6H5"
  },
  {
    "uniprot": "X8F5S8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5S8"
  },
  {
    "uniprot": "X8F372",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F372"
  },
  {
    "uniprot": "X8F6C7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6C7"
  },
  {
    "uniprot": "X8FCX7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCX7"
  },
  {
    "uniprot": "X8EY86",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY86"
  },
  {
    "uniprot": "X8FLA2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLA2"
  },
  {
    "uniprot": "X8F0U3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0U3"
  },
  {
    "uniprot": "X8F381",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F381"
  },
  {
    "uniprot": "X8F564",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F564"
  },
  {
    "uniprot": "X8FMR2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMR2"
  },
  {
    "uniprot": "X8EYY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYY5"
  },
  {
    "uniprot": "X8FHM1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHM1"
  },
  {
    "uniprot": "X8FLD6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLD6"
  },
  {
    "uniprot": "X8F063",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F063"
  },
  {
    "uniprot": "X8EX85",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX85"
  },
  {
    "uniprot": "X8EYR5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYR5"
  },
  {
    "uniprot": "X8F306",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F306"
  },
  {
    "uniprot": "X8F238",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F238"
  },
  {
    "uniprot": "X8F1Z8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Z8"
  },
  {
    "uniprot": "X8FCJ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCJ0"
  },
  {
    "uniprot": "X8F8S9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8S9"
  },
  {
    "uniprot": "X8FL17",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL17"
  },
  {
    "uniprot": "X8EW02",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EW02"
  },
  {
    "uniprot": "X8FE43",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE43"
  },
  {
    "uniprot": "X8FGQ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGQ2"
  },
  {
    "uniprot": "X8FA63",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA63"
  },
  {
    "uniprot": "X8F0Y7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Y7"
  },
  {
    "uniprot": "X8FF54",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF54"
  },
  {
    "uniprot": "X8F1Y5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Y5"
  },
  {
    "uniprot": "X8FJ58",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ58"
  },
  {
    "uniprot": "X8EYQ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYQ5"
  },
  {
    "uniprot": "X8F1U0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1U0"
  },
  {
    "uniprot": "X8F884",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F884"
  },
  {
    "uniprot": "X8F0G8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0G8"
  },
  {
    "uniprot": "X8FI33",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI33"
  },
  {
    "uniprot": "X8FJ39",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ39"
  },
  {
    "uniprot": "X8FGC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGC8"
  },
  {
    "uniprot": "X8F2Z0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Z0"
  },
  {
    "uniprot": "X8F5U5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5U5"
  },
  {
    "uniprot": "X8F4P4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4P4"
  },
  {
    "uniprot": "X8FMN3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMN3"
  },
  {
    "uniprot": "X8F4G6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4G6"
  },
  {
    "uniprot": "X8FH24",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH24"
  },
  {
    "uniprot": "X8FMD6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMD6"
  },
  {
    "uniprot": "X8EZN5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZN5"
  },
  {
    "uniprot": "X8FJ53",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ53"
  },
  {
    "uniprot": "X8F9Y5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Y5"
  },
  {
    "uniprot": "X8FI54",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI54"
  },
  {
    "uniprot": "X8F2K2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2K2"
  },
  {
    "uniprot": "X8FD58",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD58"
  },
  {
    "uniprot": "X8F1F4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1F4"
  },
  {
    "uniprot": "X8F7A9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7A9"
  },
  {
    "uniprot": "X8FDY8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDY8"
  },
  {
    "uniprot": "X8F787",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F787"
  },
  {
    "uniprot": "X8F2D4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2D4"
  },
  {
    "uniprot": "X8F585",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F585"
  },
  {
    "uniprot": "X8FKH4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKH4"
  },
  {
    "uniprot": "X8FIG5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIG5"
  },
  {
    "uniprot": "X8EXE7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXE7"
  },
  {
    "uniprot": "X8FEX7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEX7"
  },
  {
    "uniprot": "X8FBH0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBH0"
  },
  {
    "uniprot": "X8EYD0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYD0"
  },
  {
    "uniprot": "X8F9A3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9A3"
  },
  {
    "uniprot": "X8F1E3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1E3"
  },
  {
    "uniprot": "X8EXP4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXP4"
  },
  {
    "uniprot": "X8F9A5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9A5"
  },
  {
    "uniprot": "X8FD95",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD95"
  },
  {
    "uniprot": "X8FKJ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKJ0"
  },
  {
    "uniprot": "X8FCA7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCA7"
  },
  {
    "uniprot": "X8EX40",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX40"
  },
  {
    "uniprot": "X8FCC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCC7"
  },
  {
    "uniprot": "X8FGF6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGF6"
  },
  {
    "uniprot": "X8F7P3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7P3"
  },
  {
    "uniprot": "X8EYC5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYC5"
  },
  {
    "uniprot": "X8FPZ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPZ3"
  },
  {
    "uniprot": "X8FA78",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA78"
  },
  {
    "uniprot": "X8F5D8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5D8"
  },
  {
    "uniprot": "X8FGG5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGG5"
  },
  {
    "uniprot": "X8EZB9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZB9"
  },
  {
    "uniprot": "X8F640",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F640"
  },
  {
    "uniprot": "X8FAX4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAX4"
  },
  {
    "uniprot": "X8F6H1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6H1"
  },
  {
    "uniprot": "X8FAC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAC7"
  },
  {
    "uniprot": "X8F1L2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1L2"
  },
  {
    "uniprot": "X8FL15",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL15"
  },
  {
    "uniprot": "X8F475",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F475"
  },
  {
    "uniprot": "X8FBY4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBY4"
  },
  {
    "uniprot": "X8FIU5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIU5"
  },
  {
    "uniprot": "X8FLB0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLB0"
  },
  {
    "uniprot": "X8FGE4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGE4"
  },
  {
    "uniprot": "X8FE51",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE51"
  },
  {
    "uniprot": "X8FFL5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFL5"
  },
  {
    "uniprot": "X8F658",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F658"
  },
  {
    "uniprot": "X8F414",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F414"
  },
  {
    "uniprot": "X8FER3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FER3"
  },
  {
    "uniprot": "X8F821",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F821"
  },
  {
    "uniprot": "X8F7U9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7U9"
  },
  {
    "uniprot": "X8FAL3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAL3"
  },
  {
    "uniprot": "X8EZV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZV8"
  },
  {
    "uniprot": "X8FNS2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNS2"
  },
  {
    "uniprot": "X8FF71",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF71"
  },
  {
    "uniprot": "X8FBB7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBB7"
  },
  {
    "uniprot": "X8FA58",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA58"
  },
  {
    "uniprot": "X8FA90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA90"
  },
  {
    "uniprot": "X8EY45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY45"
  },
  {
    "uniprot": "X8EZ12",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ12"
  },
  {
    "uniprot": "X8F2T3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2T3"
  },
  {
    "uniprot": "X8FHN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHN8"
  },
  {
    "uniprot": "X8FLG6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLG6"
  },
  {
    "uniprot": "X8F9E7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9E7"
  },
  {
    "uniprot": "X8FF07",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF07"
  },
  {
    "uniprot": "X8FKY3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKY3"
  },
  {
    "uniprot": "X8FP05",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP05"
  },
  {
    "uniprot": "X8FFY4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFY4"
  },
  {
    "uniprot": "X8EZU7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZU7"
  },
  {
    "uniprot": "X8F0Q1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Q1"
  },
  {
    "uniprot": "X8F364",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F364"
  },
  {
    "uniprot": "X8F9J6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9J6"
  },
  {
    "uniprot": "X8FBL2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBL2"
  },
  {
    "uniprot": "X8F209",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F209"
  },
  {
    "uniprot": "X8F7U6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7U6"
  },
  {
    "uniprot": "X8FAV7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAV7"
  },
  {
    "uniprot": "X8FFW6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFW6"
  },
  {
    "uniprot": "X8F6J3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6J3"
  },
  {
    "uniprot": "X8F2X4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2X4"
  },
  {
    "uniprot": "X8FL25",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL25"
  },
  {
    "uniprot": "X8F4S7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4S7"
  },
  {
    "uniprot": "X8F2F4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2F4"
  },
  {
    "uniprot": "X8FBA4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBA4"
  },
  {
    "uniprot": "X8FCL3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCL3"
  },
  {
    "uniprot": "X8FMH1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMH1"
  },
  {
    "uniprot": "X8FK36",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK36"
  },
  {
    "uniprot": "X8FPG4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPG4"
  },
  {
    "uniprot": "X8F3T7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3T7"
  },
  {
    "uniprot": "X8EX89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX89"
  },
  {
    "uniprot": "X8EXW3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXW3"
  },
  {
    "uniprot": "X8F1N2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1N2"
  },
  {
    "uniprot": "X8F7S9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7S9"
  },
  {
    "uniprot": "X8FER0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FER0"
  },
  {
    "uniprot": "X8F0Z1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Z1"
  },
  {
    "uniprot": "X8F113",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F113"
  },
  {
    "uniprot": "X8F173",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F173"
  },
  {
    "uniprot": "X8FBN9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBN9"
  },
  {
    "uniprot": "X8F5E7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5E7"
  },
  {
    "uniprot": "X8F6H2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6H2"
  },
  {
    "uniprot": "X8F715",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F715"
  },
  {
    "uniprot": "X8F1K8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1K8"
  },
  {
    "uniprot": "X8FHV5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHV5"
  },
  {
    "uniprot": "X8F9Y0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Y0"
  },
  {
    "uniprot": "X8FJG3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJG3"
  },
  {
    "uniprot": "X8FGV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGV8"
  },
  {
    "uniprot": "X8FBR3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBR3"
  },
  {
    "uniprot": "X8F5W1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5W1"
  },
  {
    "uniprot": "X8FB93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB93"
  },
  {
    "uniprot": "X8F9N8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9N8"
  },
  {
    "uniprot": "X8F1S5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1S5"
  },
  {
    "uniprot": "X8F7D6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7D6"
  },
  {
    "uniprot": "X8FG63",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG63"
  },
  {
    "uniprot": "X8FGI2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGI2"
  },
  {
    "uniprot": "X8EZZ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZZ2"
  },
  {
    "uniprot": "X8F614",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F614"
  },
  {
    "uniprot": "X8FFS1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFS1"
  },
  {
    "uniprot": "X8FAZ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAZ4"
  },
  {
    "uniprot": "X8FHY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHY1"
  },
  {
    "uniprot": "X8FIM5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIM5"
  },
  {
    "uniprot": "X8FPC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPC7"
  },
  {
    "uniprot": "X8FNY8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNY8"
  },
  {
    "uniprot": "X8FEI9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEI9"
  },
  {
    "uniprot": "X8F979",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F979"
  },
  {
    "uniprot": "X8FLH7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLH7"
  },
  {
    "uniprot": "X8FDU7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDU7"
  },
  {
    "uniprot": "X8F3P5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3P5"
  },
  {
    "uniprot": "X8FIR3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIR3"
  },
  {
    "uniprot": "X8FLD3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLD3"
  },
  {
    "uniprot": "X8F1G9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1G9"
  },
  {
    "uniprot": "X8FHT2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHT2"
  },
  {
    "uniprot": "X8F2Y8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Y8"
  },
  {
    "uniprot": "X8FGM5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGM5"
  },
  {
    "uniprot": "X8FI32",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI32"
  },
  {
    "uniprot": "X8F9I0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9I0"
  },
  {
    "uniprot": "X8FIM7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIM7"
  },
  {
    "uniprot": "X8F555",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F555"
  },
  {
    "uniprot": "X8FC79",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC79"
  },
  {
    "uniprot": "X8FL85",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL85"
  },
  {
    "uniprot": "X8FGA3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGA3"
  },
  {
    "uniprot": "X8F323",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F323"
  },
  {
    "uniprot": "X8F7T9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7T9"
  },
  {
    "uniprot": "X8FHU3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHU3"
  },
  {
    "uniprot": "X8F790",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F790"
  },
  {
    "uniprot": "X8FHE9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHE9"
  },
  {
    "uniprot": "X8FK91",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK91"
  },
  {
    "uniprot": "X8FE72",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE72"
  },
  {
    "uniprot": "X8F174",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F174"
  },
  {
    "uniprot": "X8F4V8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4V8"
  },
  {
    "uniprot": "X8FM55",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM55"
  },
  {
    "uniprot": "X8FDW2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDW2"
  },
  {
    "uniprot": "X8F7J9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7J9"
  },
  {
    "uniprot": "X8F340",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F340"
  },
  {
    "uniprot": "X8FK71",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK71"
  },
  {
    "uniprot": "X8F5A7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5A7"
  },
  {
    "uniprot": "X8FMK4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMK4"
  },
  {
    "uniprot": "X8EXE1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXE1"
  },
  {
    "uniprot": "X8F9G8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9G8"
  },
  {
    "uniprot": "X8FDE0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDE0"
  },
  {
    "uniprot": "X8FBE5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBE5"
  },
  {
    "uniprot": "X8F9W5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9W5"
  },
  {
    "uniprot": "X8FK45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK45"
  },
  {
    "uniprot": "X8FDJ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDJ2"
  },
  {
    "uniprot": "X8FL95",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL95"
  },
  {
    "uniprot": "X8F3X8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3X8"
  },
  {
    "uniprot": "X8F5E8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5E8"
  },
  {
    "uniprot": "X8FNY4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNY4"
  },
  {
    "uniprot": "X8FFI9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFI9"
  },
  {
    "uniprot": "X8F9X6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9X6"
  },
  {
    "uniprot": "X8F4A5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4A5"
  },
  {
    "uniprot": "X8FIW2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIW2"
  },
  {
    "uniprot": "X8FFC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFC0"
  },
  {
    "uniprot": "X8FBE4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBE4"
  },
  {
    "uniprot": "X8F4A4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4A4"
  },
  {
    "uniprot": "X8FBS2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBS2"
  },
  {
    "uniprot": "X8F3A3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3A3"
  },
  {
    "uniprot": "X8F3R1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3R1"
  },
  {
    "uniprot": "X8FH21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH21"
  },
  {
    "uniprot": "X8FD75",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD75"
  },
  {
    "uniprot": "X8FLW5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLW5"
  },
  {
    "uniprot": "X8F328",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F328"
  },
  {
    "uniprot": "X8F9R2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9R2"
  },
  {
    "uniprot": "X8FJI5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJI5"
  },
  {
    "uniprot": "X8F906",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F906"
  },
  {
    "uniprot": "X8FEK5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEK5"
  },
  {
    "uniprot": "X8F5K2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5K2"
  },
  {
    "uniprot": "X8F505",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F505"
  },
  {
    "uniprot": "X8FCJ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCJ5"
  },
  {
    "uniprot": "X8F1X7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1X7"
  },
  {
    "uniprot": "X8FJ54",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ54"
  },
  {
    "uniprot": "X8EXY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXY5"
  },
  {
    "uniprot": "X8FDB7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDB7"
  },
  {
    "uniprot": "X8FJA9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJA9"
  },
  {
    "uniprot": "X8FEI1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEI1"
  },
  {
    "uniprot": "X8F3V7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3V7"
  },
  {
    "uniprot": "X8F827",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F827"
  },
  {
    "uniprot": "X8F192",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F192"
  },
  {
    "uniprot": "X8EYW4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYW4"
  },
  {
    "uniprot": "X8F0G5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0G5"
  },
  {
    "uniprot": "X8FAI8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAI8"
  },
  {
    "uniprot": "X8EX57",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX57"
  },
  {
    "uniprot": "X8F7V7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7V7"
  },
  {
    "uniprot": "X8F9I9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9I9"
  },
  {
    "uniprot": "X8FGN5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGN5"
  },
  {
    "uniprot": "X8FG24",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG24"
  },
  {
    "uniprot": "X8FL92",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL92"
  },
  {
    "uniprot": "X8F4P9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4P9"
  },
  {
    "uniprot": "X8FH12",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH12"
  },
  {
    "uniprot": "X8FCT8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCT8"
  },
  {
    "uniprot": "X8F7G6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7G6"
  },
  {
    "uniprot": "X8F775",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F775"
  },
  {
    "uniprot": "X8F0J4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0J4"
  },
  {
    "uniprot": "X8FMX1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMX1"
  },
  {
    "uniprot": "X8FID6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FID6"
  },
  {
    "uniprot": "X8FKE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKE2"
  },
  {
    "uniprot": "X8F6B0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6B0"
  },
  {
    "uniprot": "X8F7W5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7W5"
  },
  {
    "uniprot": "X8FCN6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCN6"
  },
  {
    "uniprot": "X8F4P3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4P3"
  },
  {
    "uniprot": "X8FM07",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM07"
  },
  {
    "uniprot": "X8FDZ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDZ6"
  },
  {
    "uniprot": "X8F673",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F673"
  },
  {
    "uniprot": "X8FGU1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGU1"
  },
  {
    "uniprot": "X8FC89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC89"
  },
  {
    "uniprot": "X8F950",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F950"
  },
  {
    "uniprot": "X8F6T5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6T5"
  },
  {
    "uniprot": "X8F2B2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2B2"
  },
  {
    "uniprot": "X8FHD0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHD0"
  },
  {
    "uniprot": "X8FGJ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGJ6"
  },
  {
    "uniprot": "X8F0R0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0R0"
  },
  {
    "uniprot": "X8FLT0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLT0"
  },
  {
    "uniprot": "X8FB43",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB43"
  },
  {
    "uniprot": "X8FIU9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIU9"
  },
  {
    "uniprot": "X8FK89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK89"
  },
  {
    "uniprot": "X8F914",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F914"
  },
  {
    "uniprot": "X8FGB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGB3"
  },
  {
    "uniprot": "X8F1Q9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Q9"
  },
  {
    "uniprot": "X8EZA6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZA6"
  },
  {
    "uniprot": "X8F3B9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3B9"
  },
  {
    "uniprot": "X8FMU1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMU1"
  },
  {
    "uniprot": "X8F6Q7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Q7"
  },
  {
    "uniprot": "X8FHN1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHN1"
  },
  {
    "uniprot": "X8FDV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDV0"
  },
  {
    "uniprot": "X8F885",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F885"
  },
  {
    "uniprot": "X8FIU6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIU6"
  },
  {
    "uniprot": "X8EZB5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZB5"
  },
  {
    "uniprot": "X8F6L7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6L7"
  },
  {
    "uniprot": "X8F0A0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0A0"
  },
  {
    "uniprot": "X8EXN2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXN2"
  },
  {
    "uniprot": "X8F7F7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7F7"
  },
  {
    "uniprot": "X8FB11",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB11"
  },
  {
    "uniprot": "X8EZW9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZW9"
  },
  {
    "uniprot": "X8FPR3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPR3"
  },
  {
    "uniprot": "X8FGY7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGY7"
  },
  {
    "uniprot": "X8F3D4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3D4"
  },
  {
    "uniprot": "X8EYY8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYY8"
  },
  {
    "uniprot": "X8FL32",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL32"
  },
  {
    "uniprot": "X8FKX9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKX9"
  },
  {
    "uniprot": "X8FBG4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBG4"
  },
  {
    "uniprot": "X8FKS8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKS8"
  },
  {
    "uniprot": "X8FAI2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAI2"
  },
  {
    "uniprot": "X8FHX5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHX5"
  },
  {
    "uniprot": "X8F810",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F810"
  },
  {
    "uniprot": "X8F902",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F902"
  },
  {
    "uniprot": "X8FGC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGC7"
  },
  {
    "uniprot": "X8FKV5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKV5"
  },
  {
    "uniprot": "X8FG65",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG65"
  },
  {
    "uniprot": "X8FIR4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIR4"
  },
  {
    "uniprot": "X8FII3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FII3"
  },
  {
    "uniprot": "X8FC53",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC53"
  },
  {
    "uniprot": "X8FMA1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMA1"
  },
  {
    "uniprot": "X8EZ99",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ99"
  },
  {
    "uniprot": "X8F4Z1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Z1"
  },
  {
    "uniprot": "X8FEY6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEY6"
  },
  {
    "uniprot": "X8F7P8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7P8"
  },
  {
    "uniprot": "X8FB02",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB02"
  },
  {
    "uniprot": "X8F983",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F983"
  },
  {
    "uniprot": "X8FEZ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEZ1"
  },
  {
    "uniprot": "X8F539",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F539"
  },
  {
    "uniprot": "X8F399",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F399"
  },
  {
    "uniprot": "X8F778",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F778"
  },
  {
    "uniprot": "X8F1N9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1N9"
  },
  {
    "uniprot": "X8FBU1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBU1"
  },
  {
    "uniprot": "X8F1K1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1K1"
  },
  {
    "uniprot": "X8FAE6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAE6"
  },
  {
    "uniprot": "X8FAT5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAT5"
  },
  {
    "uniprot": "X8F3B2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3B2"
  },
  {
    "uniprot": "X8FNZ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNZ2"
  },
  {
    "uniprot": "X8FD45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD45"
  },
  {
    "uniprot": "X8FIB6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIB6"
  },
  {
    "uniprot": "X8EZF1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZF1"
  },
  {
    "uniprot": "X8FI85",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI85"
  },
  {
    "uniprot": "X8EX31",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX31"
  },
  {
    "uniprot": "X8FKS0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKS0"
  },
  {
    "uniprot": "X8F8F9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8F9"
  },
  {
    "uniprot": "X8FL93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL93"
  },
  {
    "uniprot": "X8FJW3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJW3"
  },
  {
    "uniprot": "X8F379",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F379"
  },
  {
    "uniprot": "X8F3E9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3E9"
  },
  {
    "uniprot": "X8FKL1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKL1"
  },
  {
    "uniprot": "X8FMF1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMF1"
  },
  {
    "uniprot": "X8F0H7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0H7"
  },
  {
    "uniprot": "X8F282",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F282"
  },
  {
    "uniprot": "X8F3A4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3A4"
  },
  {
    "uniprot": "X8FHG6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHG6"
  },
  {
    "uniprot": "X8FAN5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAN5"
  },
  {
    "uniprot": "X8FAV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAV0"
  },
  {
    "uniprot": "X8EYS7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYS7"
  },
  {
    "uniprot": "X8F1J6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1J6"
  },
  {
    "uniprot": "X8F7W9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7W9"
  },
  {
    "uniprot": "X8FBX6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBX6"
  },
  {
    "uniprot": "X8FHV1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHV1"
  },
  {
    "uniprot": "X8F3K4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3K4"
  },
  {
    "uniprot": "X8F055",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F055"
  },
  {
    "uniprot": "X8FKF8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKF8"
  },
  {
    "uniprot": "X8F0F0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0F0"
  },
  {
    "uniprot": "X8F429",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F429"
  },
  {
    "uniprot": "X8F7V9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7V9"
  },
  {
    "uniprot": "X8F0S8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0S8"
  },
  {
    "uniprot": "X8F7F3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7F3"
  },
  {
    "uniprot": "X8F589",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F589"
  },
  {
    "uniprot": "X8EYV6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYV6"
  },
  {
    "uniprot": "X8FEL4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEL4"
  },
  {
    "uniprot": "X8F5H4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5H4"
  },
  {
    "uniprot": "X8FBM1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBM1"
  },
  {
    "uniprot": "X8F3E3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3E3"
  },
  {
    "uniprot": "X8FFR5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFR5"
  },
  {
    "uniprot": "X8F9E3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9E3"
  },
  {
    "uniprot": "X8F7Y0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Y0"
  },
  {
    "uniprot": "X8FJL9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJL9"
  },
  {
    "uniprot": "X8FA53",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA53"
  },
  {
    "uniprot": "X8FHV2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHV2"
  },
  {
    "uniprot": "X8EYQ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYQ1"
  },
  {
    "uniprot": "X8FAA3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAA3"
  },
  {
    "uniprot": "X8FAX0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAX0"
  },
  {
    "uniprot": "X8FGB5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGB5"
  },
  {
    "uniprot": "X8F6N5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6N5"
  },
  {
    "uniprot": "X8FI31",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI31"
  },
  {
    "uniprot": "X8F5T3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5T3"
  },
  {
    "uniprot": "X8FK96",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK96"
  },
  {
    "uniprot": "X8F559",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F559"
  },
  {
    "uniprot": "X8FLC2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLC2"
  },
  {
    "uniprot": "X8FI65",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI65"
  },
  {
    "uniprot": "X8F6E4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6E4"
  },
  {
    "uniprot": "X8FP29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP29"
  },
  {
    "uniprot": "X8FLN1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLN1"
  },
  {
    "uniprot": "X8F3Y4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Y4"
  },
  {
    "uniprot": "X8FIF6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIF6"
  },
  {
    "uniprot": "X8FGR8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGR8"
  },
  {
    "uniprot": "X8F394",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F394"
  },
  {
    "uniprot": "X8F393",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F393"
  },
  {
    "uniprot": "X8FHR1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHR1"
  },
  {
    "uniprot": "X8F5E5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5E5"
  },
  {
    "uniprot": "X8FBK9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBK9"
  },
  {
    "uniprot": "X8FN78",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN78"
  },
  {
    "uniprot": "X8FK13",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK13"
  },
  {
    "uniprot": "X8FHF7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHF7"
  },
  {
    "uniprot": "X8FC88",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC88"
  },
  {
    "uniprot": "X8F3H4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3H4"
  },
  {
    "uniprot": "X8FAJ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAJ6"
  },
  {
    "uniprot": "X8EZR4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZR4"
  },
  {
    "uniprot": "X8FCU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCU0"
  },
  {
    "uniprot": "X8F9G0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9G0"
  },
  {
    "uniprot": "X8FHQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHQ6"
  },
  {
    "uniprot": "X8F9E6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9E6"
  },
  {
    "uniprot": "X8FJJ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJJ1"
  },
  {
    "uniprot": "X8F935",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F935"
  },
  {
    "uniprot": "X8FJQ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJQ3"
  },
  {
    "uniprot": "X8EYS6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYS6"
  },
  {
    "uniprot": "X8F4B1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4B1"
  },
  {
    "uniprot": "X8FFH2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFH2"
  },
  {
    "uniprot": "X8FA36",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA36"
  },
  {
    "uniprot": "X8FHF5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHF5"
  },
  {
    "uniprot": "X8EZW3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZW3"
  },
  {
    "uniprot": "X8F7M0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7M0"
  },
  {
    "uniprot": "X8FN20",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN20"
  },
  {
    "uniprot": "X8F9D5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9D5"
  },
  {
    "uniprot": "X8FKT6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKT6"
  },
  {
    "uniprot": "X8EWP6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWP6"
  },
  {
    "uniprot": "X8FC85",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC85"
  },
  {
    "uniprot": "X8FJK4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJK4"
  },
  {
    "uniprot": "X8EZC9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZC9"
  },
  {
    "uniprot": "X8FLK7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLK7"
  },
  {
    "uniprot": "X8FFG7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFG7"
  },
  {
    "uniprot": "X8F1C8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1C8"
  },
  {
    "uniprot": "X8FH37",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH37"
  },
  {
    "uniprot": "X8FH67",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH67"
  },
  {
    "uniprot": "X8F3D6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3D6"
  },
  {
    "uniprot": "X8F9B1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9B1"
  },
  {
    "uniprot": "X8F1X4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1X4"
  },
  {
    "uniprot": "X8F7N6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7N6"
  },
  {
    "uniprot": "X8FF27",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF27"
  },
  {
    "uniprot": "X8F2N5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2N5"
  },
  {
    "uniprot": "X8F1C9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1C9"
  },
  {
    "uniprot": "X8FF45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF45"
  },
  {
    "uniprot": "X8FMM5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMM5"
  },
  {
    "uniprot": "X8FA71",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA71"
  },
  {
    "uniprot": "X8F9P9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9P9"
  },
  {
    "uniprot": "X8F530",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F530"
  },
  {
    "uniprot": "X8F0I3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0I3"
  },
  {
    "uniprot": "X8FJV7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJV7"
  },
  {
    "uniprot": "X8FD23",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD23"
  },
  {
    "uniprot": "X8F4S6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4S6"
  },
  {
    "uniprot": "X8F1C3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1C3"
  },
  {
    "uniprot": "X8FCX8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCX8"
  },
  {
    "uniprot": "X8F9C3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9C3"
  },
  {
    "uniprot": "X8FHW3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHW3"
  },
  {
    "uniprot": "X8FP01",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP01"
  },
  {
    "uniprot": "X8FND9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FND9"
  },
  {
    "uniprot": "X8FLM5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLM5"
  },
  {
    "uniprot": "X8FCA1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCA1"
  },
  {
    "uniprot": "X8FJ75",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ75"
  },
  {
    "uniprot": "X8F5Q3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Q3"
  },
  {
    "uniprot": "X8F484",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F484"
  },
  {
    "uniprot": "X8F4E1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4E1"
  },
  {
    "uniprot": "X8F974",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F974"
  },
  {
    "uniprot": "X8FJG5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJG5"
  },
  {
    "uniprot": "X8F536",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F536"
  },
  {
    "uniprot": "X8F7R9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7R9"
  },
  {
    "uniprot": "X8FB14",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB14"
  },
  {
    "uniprot": "X8FGC1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGC1"
  },
  {
    "uniprot": "X8F2L7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2L7"
  },
  {
    "uniprot": "X8FP85",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP85"
  },
  {
    "uniprot": "X8FLA6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLA6"
  },
  {
    "uniprot": "X8EZE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZE2"
  },
  {
    "uniprot": "X8FGS4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGS4"
  },
  {
    "uniprot": "X8FMX5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMX5"
  },
  {
    "uniprot": "X8F336",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F336"
  },
  {
    "uniprot": "X8FBV5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBV5"
  },
  {
    "uniprot": "X8F3S7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3S7"
  },
  {
    "uniprot": "X8FHL9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHL9"
  },
  {
    "uniprot": "X8F3Q4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Q4"
  },
  {
    "uniprot": "X8EZR0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZR0"
  },
  {
    "uniprot": "X8FF24",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF24"
  },
  {
    "uniprot": "X8FEY0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEY0"
  },
  {
    "uniprot": "X8FCX0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCX0"
  },
  {
    "uniprot": "X8F8J8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8J8"
  },
  {
    "uniprot": "X8F5W7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5W7"
  },
  {
    "uniprot": "X8FIE9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIE9"
  },
  {
    "uniprot": "X8FJZ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJZ7"
  },
  {
    "uniprot": "X8FLL6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLL6"
  },
  {
    "uniprot": "X8FNJ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNJ7"
  },
  {
    "uniprot": "X8FA03",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA03"
  },
  {
    "uniprot": "X8F799",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F799"
  },
  {
    "uniprot": "X8F8D8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8D8"
  },
  {
    "uniprot": "X8F6I5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6I5"
  },
  {
    "uniprot": "X8F3J1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3J1"
  },
  {
    "uniprot": "X8FK70",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK70"
  },
  {
    "uniprot": "X8FCB1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCB1"
  },
  {
    "uniprot": "X8F8N2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8N2"
  },
  {
    "uniprot": "X8F377",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F377"
  },
  {
    "uniprot": "X8F1X1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1X1"
  },
  {
    "uniprot": "X8F481",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F481"
  },
  {
    "uniprot": "X8F9Y8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Y8"
  },
  {
    "uniprot": "X8FCT7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCT7"
  },
  {
    "uniprot": "X8FKU1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKU1"
  },
  {
    "uniprot": "X8FLP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLP9"
  },
  {
    "uniprot": "X8EZJ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZJ2"
  },
  {
    "uniprot": "X8F4F1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4F1"
  },
  {
    "uniprot": "X8FD98",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD98"
  },
  {
    "uniprot": "X8FLU9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLU9"
  },
  {
    "uniprot": "X8FAD7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAD7"
  },
  {
    "uniprot": "X8FL29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL29"
  },
  {
    "uniprot": "X8FBU8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBU8"
  },
  {
    "uniprot": "X8FC41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC41"
  },
  {
    "uniprot": "X8F0D8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0D8"
  },
  {
    "uniprot": "X8FEP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEP9"
  },
  {
    "uniprot": "X8FLM2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLM2"
  },
  {
    "uniprot": "X8EX56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX56"
  },
  {
    "uniprot": "X8F9L3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9L3"
  },
  {
    "uniprot": "X8FEK7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEK7"
  },
  {
    "uniprot": "X8F2G9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2G9"
  },
  {
    "uniprot": "X8EZ78",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ78"
  },
  {
    "uniprot": "X8F267",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F267"
  },
  {
    "uniprot": "X8F712",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F712"
  },
  {
    "uniprot": "X8FKP0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKP0"
  },
  {
    "uniprot": "X8FDK1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDK1"
  },
  {
    "uniprot": "X8FNY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNY1"
  },
  {
    "uniprot": "X8FB33",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB33"
  },
  {
    "uniprot": "X8FGH9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGH9"
  },
  {
    "uniprot": "X8EZN4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZN4"
  },
  {
    "uniprot": "X8FAV2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAV2"
  },
  {
    "uniprot": "X8FF69",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF69"
  },
  {
    "uniprot": "X8FA45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA45"
  },
  {
    "uniprot": "X8FKQ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKQ2"
  },
  {
    "uniprot": "X8FC11",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC11"
  },
  {
    "uniprot": "X8FJG8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJG8"
  },
  {
    "uniprot": "X8FG03",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG03"
  },
  {
    "uniprot": "X8F2Y7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Y7"
  },
  {
    "uniprot": "X8FAQ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAQ2"
  },
  {
    "uniprot": "X8FMZ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMZ5"
  },
  {
    "uniprot": "X8F7I7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7I7"
  },
  {
    "uniprot": "X8FCW0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCW0"
  },
  {
    "uniprot": "X8FIT9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIT9"
  },
  {
    "uniprot": "X8FGJ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGJ2"
  },
  {
    "uniprot": "X8F1B5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1B5"
  },
  {
    "uniprot": "X8EX27",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX27"
  },
  {
    "uniprot": "X8F477",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F477"
  },
  {
    "uniprot": "X8F1L7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1L7"
  },
  {
    "uniprot": "X8FC71",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC71"
  },
  {
    "uniprot": "X8EXB1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXB1"
  },
  {
    "uniprot": "X8F6B4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6B4"
  },
  {
    "uniprot": "X8FFR2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFR2"
  },
  {
    "uniprot": "X8F7G7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7G7"
  },
  {
    "uniprot": "X8FEV1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEV1"
  },
  {
    "uniprot": "X8EYR6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYR6"
  },
  {
    "uniprot": "X8F3D8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3D8"
  },
  {
    "uniprot": "X8FF95",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF95"
  },
  {
    "uniprot": "X8FB01",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB01"
  },
  {
    "uniprot": "X8F272",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F272"
  },
  {
    "uniprot": "X8FHP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHP9"
  },
  {
    "uniprot": "X8EYN0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYN0"
  },
  {
    "uniprot": "X8FD94",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD94"
  },
  {
    "uniprot": "X8F2T5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2T5"
  },
  {
    "uniprot": "X8FGA0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGA0"
  },
  {
    "uniprot": "X8FMR6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMR6"
  },
  {
    "uniprot": "X8FHG7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHG7"
  },
  {
    "uniprot": "X8F838",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F838"
  },
  {
    "uniprot": "X8F4G1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4G1"
  },
  {
    "uniprot": "X8F0V0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0V0"
  },
  {
    "uniprot": "X8F6Z2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Z2"
  },
  {
    "uniprot": "X8EYE7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYE7"
  },
  {
    "uniprot": "X8F3B4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3B4"
  },
  {
    "uniprot": "X8FKA2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKA2"
  },
  {
    "uniprot": "X8F4H4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4H4"
  },
  {
    "uniprot": "X8FLS2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLS2"
  },
  {
    "uniprot": "X8FNZ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNZ6"
  },
  {
    "uniprot": "X8F887",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F887"
  },
  {
    "uniprot": "X8FLF7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLF7"
  },
  {
    "uniprot": "X8FD30",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD30"
  },
  {
    "uniprot": "X8FNJ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNJ6"
  },
  {
    "uniprot": "X8F8N1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8N1"
  },
  {
    "uniprot": "X8F0X5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0X5"
  },
  {
    "uniprot": "X8FF40",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF40"
  },
  {
    "uniprot": "X8FAD2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAD2"
  },
  {
    "uniprot": "X8FIR0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIR0"
  },
  {
    "uniprot": "X8F1W8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1W8"
  },
  {
    "uniprot": "X8FNN7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNN7"
  },
  {
    "uniprot": "X8EYY0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYY0"
  },
  {
    "uniprot": "X8FAD3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAD3"
  },
  {
    "uniprot": "X8EY80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY80"
  },
  {
    "uniprot": "X8FKJ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKJ7"
  },
  {
    "uniprot": "X8FI96",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI96"
  },
  {
    "uniprot": "X8EXC2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXC2"
  },
  {
    "uniprot": "X8FHI3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHI3"
  },
  {
    "uniprot": "X8F7Q2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Q2"
  },
  {
    "uniprot": "X8FFM9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFM9"
  },
  {
    "uniprot": "X8F1A5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1A5"
  },
  {
    "uniprot": "X8FDF6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDF6"
  },
  {
    "uniprot": "X8FEG5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEG5"
  },
  {
    "uniprot": "X8F9K3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9K3"
  },
  {
    "uniprot": "X8FE89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE89"
  },
  {
    "uniprot": "X8F9H5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9H5"
  },
  {
    "uniprot": "X8F5P3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5P3"
  },
  {
    "uniprot": "X8EZ64",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ64"
  },
  {
    "uniprot": "X8F623",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F623"
  },
  {
    "uniprot": "X8FMV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMV0"
  },
  {
    "uniprot": "X8FK31",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK31"
  },
  {
    "uniprot": "X8FMI5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMI5"
  },
  {
    "uniprot": "X8F4L4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4L4"
  },
  {
    "uniprot": "X8F4L0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4L0"
  },
  {
    "uniprot": "X8FHZ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHZ7"
  },
  {
    "uniprot": "X8F3L9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3L9"
  },
  {
    "uniprot": "X8FF43",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF43"
  },
  {
    "uniprot": "X8FMI9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMI9"
  },
  {
    "uniprot": "X8EWL9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWL9"
  },
  {
    "uniprot": "X8EYA8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYA8"
  },
  {
    "uniprot": "X8F571",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F571"
  },
  {
    "uniprot": "X8F8S5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8S5"
  },
  {
    "uniprot": "X8FFC5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFC5"
  },
  {
    "uniprot": "X8FP79",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP79"
  },
  {
    "uniprot": "X8F8E8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8E8"
  },
  {
    "uniprot": "X8F9H0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9H0"
  },
  {
    "uniprot": "X8FH90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH90"
  },
  {
    "uniprot": "X8FMF2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMF2"
  },
  {
    "uniprot": "X8FJV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJV8"
  },
  {
    "uniprot": "X8FLC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLC8"
  },
  {
    "uniprot": "X8F7H0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7H0"
  },
  {
    "uniprot": "X8FN11",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN11"
  },
  {
    "uniprot": "X8F8B0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8B0"
  },
  {
    "uniprot": "X8FD99",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD99"
  },
  {
    "uniprot": "X8FFG2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFG2"
  },
  {
    "uniprot": "X8F6K5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6K5"
  },
  {
    "uniprot": "X8F9A0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9A0"
  },
  {
    "uniprot": "X8FDZ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDZ2"
  },
  {
    "uniprot": "X8FAS7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAS7"
  },
  {
    "uniprot": "X8F8J7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8J7"
  },
  {
    "uniprot": "X8FIU1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIU1"
  },
  {
    "uniprot": "X8FH91",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH91"
  },
  {
    "uniprot": "X8F3P3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3P3"
  },
  {
    "uniprot": "X8FFF4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFF4"
  },
  {
    "uniprot": "X8F022",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F022"
  },
  {
    "uniprot": "X8F051",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F051"
  },
  {
    "uniprot": "X8F446",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F446"
  },
  {
    "uniprot": "X8EZP1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZP1"
  },
  {
    "uniprot": "X8F0F5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0F5"
  },
  {
    "uniprot": "X8F1Q8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Q8"
  },
  {
    "uniprot": "X8FM21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM21"
  },
  {
    "uniprot": "X8FBT5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBT5"
  },
  {
    "uniprot": "X8F4M1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4M1"
  },
  {
    "uniprot": "X8F806",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F806"
  },
  {
    "uniprot": "X8EYE8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYE8"
  },
  {
    "uniprot": "X8FAK9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAK9"
  },
  {
    "uniprot": "X8F7Z0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Z0"
  },
  {
    "uniprot": "X8F3R6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3R6"
  },
  {
    "uniprot": "X8F8V0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8V0"
  },
  {
    "uniprot": "X8F1V9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1V9"
  },
  {
    "uniprot": "X8F9R8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9R8"
  },
  {
    "uniprot": "X8FGY6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGY6"
  },
  {
    "uniprot": "X8FP34",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP34"
  },
  {
    "uniprot": "X8FEM6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEM6"
  },
  {
    "uniprot": "X8FMU8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMU8"
  },
  {
    "uniprot": "X8F1Y7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Y7"
  },
  {
    "uniprot": "X8FG20",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG20"
  },
  {
    "uniprot": "X8F2X6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2X6"
  },
  {
    "uniprot": "X8FI80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI80"
  },
  {
    "uniprot": "X8FPD3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPD3"
  },
  {
    "uniprot": "X8FM83",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM83"
  },
  {
    "uniprot": "X8FIV6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIV6"
  },
  {
    "uniprot": "X8EWC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWC8"
  },
  {
    "uniprot": "X8FMR7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMR7"
  },
  {
    "uniprot": "X8F5U4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5U4"
  },
  {
    "uniprot": "X8FM56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM56"
  },
  {
    "uniprot": "X8FL02",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL02"
  },
  {
    "uniprot": "X8FDN7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDN7"
  },
  {
    "uniprot": "X8F0R3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0R3"
  },
  {
    "uniprot": "X8F1Z4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Z4"
  },
  {
    "uniprot": "X8F0B0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0B0"
  },
  {
    "uniprot": "X8EZ94",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ94"
  },
  {
    "uniprot": "X8FE35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE35"
  },
  {
    "uniprot": "X8F7H4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7H4"
  },
  {
    "uniprot": "X8F6N6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6N6"
  },
  {
    "uniprot": "X8FP82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP82"
  },
  {
    "uniprot": "X8FHE3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHE3"
  },
  {
    "uniprot": "X8F2H6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2H6"
  },
  {
    "uniprot": "X8FH79",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH79"
  },
  {
    "uniprot": "X8FCS7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCS7"
  },
  {
    "uniprot": "X8FAJ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAJ9"
  },
  {
    "uniprot": "X8FBT1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBT1"
  },
  {
    "uniprot": "X8FIV1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIV1"
  },
  {
    "uniprot": "X8FE71",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE71"
  },
  {
    "uniprot": "X8FES7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FES7"
  },
  {
    "uniprot": "X8FHA4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHA4"
  },
  {
    "uniprot": "X8F1H0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1H0"
  },
  {
    "uniprot": "X8FPD6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPD6"
  },
  {
    "uniprot": "X8F5V8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5V8"
  },
  {
    "uniprot": "X8F825",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F825"
  },
  {
    "uniprot": "X8FID1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FID1"
  },
  {
    "uniprot": "X8F915",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F915"
  },
  {
    "uniprot": "X8F028",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F028"
  },
  {
    "uniprot": "X8F7C8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7C8"
  },
  {
    "uniprot": "X8F118",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F118"
  },
  {
    "uniprot": "X8FF46",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF46"
  },
  {
    "uniprot": "X8FMD7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMD7"
  },
  {
    "uniprot": "X8FM79",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM79"
  },
  {
    "uniprot": "X8F5L6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5L6"
  },
  {
    "uniprot": "X8F3C1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3C1"
  },
  {
    "uniprot": "X8FP28",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP28"
  },
  {
    "uniprot": "X8FD37",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD37"
  },
  {
    "uniprot": "X8FDX1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDX1"
  },
  {
    "uniprot": "X8F9J3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9J3"
  },
  {
    "uniprot": "X8FGK5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGK5"
  },
  {
    "uniprot": "X8EZT2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZT2"
  },
  {
    "uniprot": "X8F954",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F954"
  },
  {
    "uniprot": "X8FFH3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFH3"
  },
  {
    "uniprot": "X8F2R6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2R6"
  },
  {
    "uniprot": "X8EYJ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYJ7"
  },
  {
    "uniprot": "X8F9D4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9D4"
  },
  {
    "uniprot": "X8FGL8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGL8"
  },
  {
    "uniprot": "X8FH20",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH20"
  },
  {
    "uniprot": "X8FN25",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN25"
  },
  {
    "uniprot": "X8F5S3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5S3"
  },
  {
    "uniprot": "X8EXD6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXD6"
  },
  {
    "uniprot": "X8F3N6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3N6"
  },
  {
    "uniprot": "X8F611",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F611"
  },
  {
    "uniprot": "X8FJV6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJV6"
  },
  {
    "uniprot": "X8F1G4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1G4"
  },
  {
    "uniprot": "X8FLK8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLK8"
  },
  {
    "uniprot": "X8FCG9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCG9"
  },
  {
    "uniprot": "X8EZV6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZV6"
  },
  {
    "uniprot": "X8EYY7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYY7"
  },
  {
    "uniprot": "X8FBI0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBI0"
  },
  {
    "uniprot": "X8FG09",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG09"
  },
  {
    "uniprot": "X8FIA3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIA3"
  },
  {
    "uniprot": "X8F357",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F357"
  },
  {
    "uniprot": "X8FDD4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDD4"
  },
  {
    "uniprot": "X8FDZ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDZ4"
  },
  {
    "uniprot": "X8F3A5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3A5"
  },
  {
    "uniprot": "X8F7L5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7L5"
  },
  {
    "uniprot": "X8EZN0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZN0"
  },
  {
    "uniprot": "X8F6M5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6M5"
  },
  {
    "uniprot": "X8FIE4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIE4"
  },
  {
    "uniprot": "X8FED7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FED7"
  },
  {
    "uniprot": "X8F0V7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0V7"
  },
  {
    "uniprot": "X8F609",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F609"
  },
  {
    "uniprot": "X8EZ47",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ47"
  },
  {
    "uniprot": "X8FD53",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD53"
  },
  {
    "uniprot": "X8FNB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNB3"
  },
  {
    "uniprot": "X8F1R5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1R5"
  },
  {
    "uniprot": "X8F9Y1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Y1"
  },
  {
    "uniprot": "X8FMX2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMX2"
  },
  {
    "uniprot": "X8F784",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F784"
  },
  {
    "uniprot": "X8FBR9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBR9"
  },
  {
    "uniprot": "X8FLX7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLX7"
  },
  {
    "uniprot": "X8F749",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F749"
  },
  {
    "uniprot": "X8FAL4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAL4"
  },
  {
    "uniprot": "X8F3F2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3F2"
  },
  {
    "uniprot": "X8FHS7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHS7"
  },
  {
    "uniprot": "X8EZV1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZV1"
  },
  {
    "uniprot": "X8FA40",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA40"
  },
  {
    "uniprot": "X8F968",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F968"
  },
  {
    "uniprot": "X8F4Z2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Z2"
  },
  {
    "uniprot": "X8FL54",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL54"
  },
  {
    "uniprot": "X8FDV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDV8"
  },
  {
    "uniprot": "X8FGX0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGX0"
  },
  {
    "uniprot": "X8FCB4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCB4"
  },
  {
    "uniprot": "X8FHM3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHM3"
  },
  {
    "uniprot": "X8FJK2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJK2"
  },
  {
    "uniprot": "X8FHV3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHV3"
  },
  {
    "uniprot": "X8F288",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F288"
  },
  {
    "uniprot": "X8FJ43",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ43"
  },
  {
    "uniprot": "X8FG96",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG96"
  },
  {
    "uniprot": "X8FIC5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIC5"
  },
  {
    "uniprot": "X8EX61",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX61"
  },
  {
    "uniprot": "X8F1G3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1G3"
  },
  {
    "uniprot": "X8FLP5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLP5"
  },
  {
    "uniprot": "X8FCK3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCK3"
  },
  {
    "uniprot": "X8FN21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN21"
  },
  {
    "uniprot": "X8FKH1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKH1"
  },
  {
    "uniprot": "X8FH45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH45"
  },
  {
    "uniprot": "X8F094",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F094"
  },
  {
    "uniprot": "X8FIQ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIQ5"
  },
  {
    "uniprot": "X8FLB7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLB7"
  },
  {
    "uniprot": "X8F294",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F294"
  },
  {
    "uniprot": "X8F2Z5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Z5"
  },
  {
    "uniprot": "X8FAC3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAC3"
  },
  {
    "uniprot": "X8EWD4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWD4"
  },
  {
    "uniprot": "X8FIS7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIS7"
  },
  {
    "uniprot": "X8FEV3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEV3"
  },
  {
    "uniprot": "X8F4C3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4C3"
  },
  {
    "uniprot": "X8FEF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEF3"
  },
  {
    "uniprot": "X8F4T6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4T6"
  },
  {
    "uniprot": "X8FMZ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMZ2"
  },
  {
    "uniprot": "X8FK02",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK02"
  },
  {
    "uniprot": "X8F3Q8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Q8"
  },
  {
    "uniprot": "X8FEK0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEK0"
  },
  {
    "uniprot": "X8FDT6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDT6"
  },
  {
    "uniprot": "X8FKV7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKV7"
  },
  {
    "uniprot": "X8FH97",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH97"
  },
  {
    "uniprot": "X8F5V2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5V2"
  },
  {
    "uniprot": "X8FCM2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCM2"
  },
  {
    "uniprot": "X8F4B6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4B6"
  },
  {
    "uniprot": "X8F5L4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5L4"
  },
  {
    "uniprot": "X8F597",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F597"
  },
  {
    "uniprot": "X8FFL4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFL4"
  },
  {
    "uniprot": "X8FQM6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQM6"
  },
  {
    "uniprot": "X8F7M4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7M4"
  },
  {
    "uniprot": "X8F7Q7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Q7"
  },
  {
    "uniprot": "X8FI43",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI43"
  },
  {
    "uniprot": "X8EYN3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYN3"
  },
  {
    "uniprot": "X8FFQ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFQ1"
  },
  {
    "uniprot": "X8F239",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F239"
  },
  {
    "uniprot": "X8FE49",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE49"
  },
  {
    "uniprot": "X8FMM6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMM6"
  },
  {
    "uniprot": "X8FIN9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIN9"
  },
  {
    "uniprot": "X8FKP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKP9"
  },
  {
    "uniprot": "X8FHI8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHI8"
  },
  {
    "uniprot": "X8F3E8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3E8"
  },
  {
    "uniprot": "X8F2X5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2X5"
  },
  {
    "uniprot": "X8FDV9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDV9"
  },
  {
    "uniprot": "X8FBF6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBF6"
  },
  {
    "uniprot": "X8F1E6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1E6"
  },
  {
    "uniprot": "X8F211",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F211"
  },
  {
    "uniprot": "X8FE92",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE92"
  },
  {
    "uniprot": "X8F8M5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8M5"
  },
  {
    "uniprot": "X8F7Y4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Y4"
  },
  {
    "uniprot": "X8F096",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F096"
  },
  {
    "uniprot": "X8F5N4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5N4"
  },
  {
    "uniprot": "X8F8P8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8P8"
  },
  {
    "uniprot": "X8FPU7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPU7"
  },
  {
    "uniprot": "X8FF74",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF74"
  },
  {
    "uniprot": "X8F9I3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9I3"
  },
  {
    "uniprot": "X8F8E5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8E5"
  },
  {
    "uniprot": "X8FJ91",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ91"
  },
  {
    "uniprot": "X8FAA1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAA1"
  },
  {
    "uniprot": "X8F358",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F358"
  },
  {
    "uniprot": "X8F6H0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6H0"
  },
  {
    "uniprot": "X8F2V9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2V9"
  },
  {
    "uniprot": "X8F917",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F917"
  },
  {
    "uniprot": "X8FG59",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG59"
  },
  {
    "uniprot": "X8FQ15",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQ15"
  },
  {
    "uniprot": "X8F6E7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6E7"
  },
  {
    "uniprot": "X8FLI8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLI8"
  },
  {
    "uniprot": "X8FLE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLE2"
  },
  {
    "uniprot": "X8FIF0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIF0"
  },
  {
    "uniprot": "X8FAG0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAG0"
  },
  {
    "uniprot": "X8FBF5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBF5"
  },
  {
    "uniprot": "X8FF98",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF98"
  },
  {
    "uniprot": "X8F8L9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8L9"
  },
  {
    "uniprot": "X8FC47",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC47"
  },
  {
    "uniprot": "X8F808",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F808"
  },
  {
    "uniprot": "X8F1I1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1I1"
  },
  {
    "uniprot": "X8F616",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F616"
  },
  {
    "uniprot": "X8FGV6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGV6"
  },
  {
    "uniprot": "X8FAM1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAM1"
  },
  {
    "uniprot": "X8FE70",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE70"
  },
  {
    "uniprot": "X8F706",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F706"
  },
  {
    "uniprot": "X8FLT6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLT6"
  },
  {
    "uniprot": "X8F9J2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9J2"
  },
  {
    "uniprot": "X8F8L2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8L2"
  },
  {
    "uniprot": "X8FKD3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKD3"
  },
  {
    "uniprot": "X8F612",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F612"
  },
  {
    "uniprot": "X8FE50",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE50"
  },
  {
    "uniprot": "X8F905",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F905"
  },
  {
    "uniprot": "X8EYV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYV8"
  },
  {
    "uniprot": "X8F2E6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2E6"
  },
  {
    "uniprot": "X8F2W5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2W5"
  },
  {
    "uniprot": "X8FA20",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA20"
  },
  {
    "uniprot": "X8F6W2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6W2"
  },
  {
    "uniprot": "X8F1F9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1F9"
  },
  {
    "uniprot": "X8F5N0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5N0"
  },
  {
    "uniprot": "X8F7F1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7F1"
  },
  {
    "uniprot": "X8FBS7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBS7"
  },
  {
    "uniprot": "X8F4P7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4P7"
  },
  {
    "uniprot": "X8FG01",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG01"
  },
  {
    "uniprot": "X8F4B5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4B5"
  },
  {
    "uniprot": "X8FAH5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAH5"
  },
  {
    "uniprot": "X8FLZ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLZ8"
  },
  {
    "uniprot": "X8F980",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F980"
  },
  {
    "uniprot": "X8F2U3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2U3"
  },
  {
    "uniprot": "X8FHR5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHR5"
  },
  {
    "uniprot": "X8F8Y8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Y8"
  },
  {
    "uniprot": "X8FDL0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDL0"
  },
  {
    "uniprot": "X8FIR5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIR5"
  },
  {
    "uniprot": "X8F1H4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1H4"
  },
  {
    "uniprot": "X8F8S6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8S6"
  },
  {
    "uniprot": "X8EZI2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZI2"
  },
  {
    "uniprot": "X8FP37",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP37"
  },
  {
    "uniprot": "X8F053",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F053"
  },
  {
    "uniprot": "X8EZN6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZN6"
  },
  {
    "uniprot": "X8F1I7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1I7"
  },
  {
    "uniprot": "X8FAS3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAS3"
  },
  {
    "uniprot": "X8FJE0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJE0"
  },
  {
    "uniprot": "X8F984",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F984"
  },
  {
    "uniprot": "X8FLR7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLR7"
  },
  {
    "uniprot": "X8F0F1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0F1"
  },
  {
    "uniprot": "X8F633",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F633"
  },
  {
    "uniprot": "X8F037",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F037"
  },
  {
    "uniprot": "X8EZL8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZL8"
  },
  {
    "uniprot": "X8FI46",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI46"
  },
  {
    "uniprot": "X8FGU5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGU5"
  },
  {
    "uniprot": "X8F6D2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6D2"
  },
  {
    "uniprot": "X8FIK3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIK3"
  },
  {
    "uniprot": "X8F4W0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4W0"
  },
  {
    "uniprot": "X8FBK4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBK4"
  },
  {
    "uniprot": "X8FNW7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNW7"
  },
  {
    "uniprot": "X8EZN2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZN2"
  },
  {
    "uniprot": "X8F3G0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3G0"
  },
  {
    "uniprot": "X8FIT5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIT5"
  },
  {
    "uniprot": "X8FFB0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFB0"
  },
  {
    "uniprot": "X8FIR9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIR9"
  },
  {
    "uniprot": "X8F688",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F688"
  },
  {
    "uniprot": "X8FFD5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFD5"
  },
  {
    "uniprot": "X8FJU5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJU5"
  },
  {
    "uniprot": "X8F9V8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9V8"
  },
  {
    "uniprot": "X8F3Q5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Q5"
  },
  {
    "uniprot": "X8FJD4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJD4"
  },
  {
    "uniprot": "X8FAH6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAH6"
  },
  {
    "uniprot": "X8F0M2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0M2"
  },
  {
    "uniprot": "X8FH36",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH36"
  },
  {
    "uniprot": "X8F831",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F831"
  },
  {
    "uniprot": "X8F7V1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7V1"
  },
  {
    "uniprot": "X8FH25",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH25"
  },
  {
    "uniprot": "X8F7V2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7V2"
  },
  {
    "uniprot": "X8F369",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F369"
  },
  {
    "uniprot": "X8FGY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGY9"
  },
  {
    "uniprot": "X8FHS6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHS6"
  },
  {
    "uniprot": "X8F4N9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4N9"
  },
  {
    "uniprot": "X8F4P5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4P5"
  },
  {
    "uniprot": "X8FLG9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLG9"
  },
  {
    "uniprot": "X8F3H9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3H9"
  },
  {
    "uniprot": "X8FDP4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDP4"
  },
  {
    "uniprot": "X8F7F0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7F0"
  },
  {
    "uniprot": "X8F2R3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2R3"
  },
  {
    "uniprot": "X8FIZ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIZ0"
  },
  {
    "uniprot": "X8FAD8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAD8"
  },
  {
    "uniprot": "X8F8K5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8K5"
  },
  {
    "uniprot": "X8F1Z3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Z3"
  },
  {
    "uniprot": "X8FKZ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKZ9"
  },
  {
    "uniprot": "X8F3X4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3X4"
  },
  {
    "uniprot": "X8FM68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM68"
  },
  {
    "uniprot": "X8FBP4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBP4"
  },
  {
    "uniprot": "X8F9W3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9W3"
  },
  {
    "uniprot": "X8FJN9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJN9"
  },
  {
    "uniprot": "X8F1P3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1P3"
  },
  {
    "uniprot": "X8FF10",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF10"
  },
  {
    "uniprot": "X8FP02",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP02"
  },
  {
    "uniprot": "X8F0X3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0X3"
  },
  {
    "uniprot": "X8FEI3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEI3"
  },
  {
    "uniprot": "X8EZA8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZA8"
  },
  {
    "uniprot": "X8FBI1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBI1"
  },
  {
    "uniprot": "X8FLS9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLS9"
  },
  {
    "uniprot": "X8FL41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL41"
  },
  {
    "uniprot": "X8FKC4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKC4"
  },
  {
    "uniprot": "X8F1S7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1S7"
  },
  {
    "uniprot": "X8FCT1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCT1"
  },
  {
    "uniprot": "X8F193",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F193"
  },
  {
    "uniprot": "X8FDT5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDT5"
  },
  {
    "uniprot": "X8FFD7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFD7"
  },
  {
    "uniprot": "X8F313",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F313"
  },
  {
    "uniprot": "X8FCR5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCR5"
  },
  {
    "uniprot": "X8FDZ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDZ8"
  },
  {
    "uniprot": "X8F3A2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3A2"
  },
  {
    "uniprot": "X8F620",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F620"
  },
  {
    "uniprot": "X8FMA4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMA4"
  },
  {
    "uniprot": "X8FES5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FES5"
  },
  {
    "uniprot": "X8F5S1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5S1"
  },
  {
    "uniprot": "X8EWJ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWJ4"
  },
  {
    "uniprot": "X8F8V2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8V2"
  },
  {
    "uniprot": "X8F034",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F034"
  },
  {
    "uniprot": "X8F9L5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9L5"
  },
  {
    "uniprot": "X8FCG3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCG3"
  },
  {
    "uniprot": "X8FM46",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM46"
  },
  {
    "uniprot": "X8FLV6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLV6"
  },
  {
    "uniprot": "X8EXJ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXJ4"
  },
  {
    "uniprot": "X8FGH4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGH4"
  },
  {
    "uniprot": "X8FCB7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCB7"
  },
  {
    "uniprot": "X8F237",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F237"
  },
  {
    "uniprot": "X8F839",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F839"
  },
  {
    "uniprot": "X8F780",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F780"
  },
  {
    "uniprot": "X8F1H3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1H3"
  },
  {
    "uniprot": "X8FIW7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIW7"
  },
  {
    "uniprot": "X8FI02",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI02"
  },
  {
    "uniprot": "X8F643",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F643"
  },
  {
    "uniprot": "X8EXS0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXS0"
  },
  {
    "uniprot": "X8F2R9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2R9"
  },
  {
    "uniprot": "X8F714",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F714"
  },
  {
    "uniprot": "X8FLG7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLG7"
  },
  {
    "uniprot": "X8F1J7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1J7"
  },
  {
    "uniprot": "X8EYC1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYC1"
  },
  {
    "uniprot": "X8EY29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY29"
  },
  {
    "uniprot": "X8F2S7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2S7"
  },
  {
    "uniprot": "X8F514",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F514"
  },
  {
    "uniprot": "X8F311",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F311"
  },
  {
    "uniprot": "X8F6K6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6K6"
  },
  {
    "uniprot": "X8FDQ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDQ8"
  },
  {
    "uniprot": "X8FN85",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN85"
  },
  {
    "uniprot": "X8F0S0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0S0"
  },
  {
    "uniprot": "X8F7R0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7R0"
  },
  {
    "uniprot": "X8EYI7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYI7"
  },
  {
    "uniprot": "X8FE38",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE38"
  },
  {
    "uniprot": "X8F4U3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4U3"
  },
  {
    "uniprot": "X8FLN7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLN7"
  },
  {
    "uniprot": "X8F713",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F713"
  },
  {
    "uniprot": "X8EYL4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYL4"
  },
  {
    "uniprot": "X8FJM1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJM1"
  },
  {
    "uniprot": "X8F213",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F213"
  },
  {
    "uniprot": "X8EZL6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZL6"
  },
  {
    "uniprot": "X8EXQ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXQ4"
  },
  {
    "uniprot": "X8FHJ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHJ9"
  },
  {
    "uniprot": "X8EXC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXC0"
  },
  {
    "uniprot": "X8FK54",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK54"
  },
  {
    "uniprot": "X8FB92",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB92"
  },
  {
    "uniprot": "X8FHA9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHA9"
  },
  {
    "uniprot": "X8FAG7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAG7"
  },
  {
    "uniprot": "X8F1E1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1E1"
  },
  {
    "uniprot": "X8EWT1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWT1"
  },
  {
    "uniprot": "X8F5J6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5J6"
  },
  {
    "uniprot": "X8FJY7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJY7"
  },
  {
    "uniprot": "X8FE39",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE39"
  },
  {
    "uniprot": "X8FCU8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCU8"
  },
  {
    "uniprot": "X8FD06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD06"
  },
  {
    "uniprot": "X8FD16",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD16"
  },
  {
    "uniprot": "X8EXL8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXL8"
  },
  {
    "uniprot": "X8FJI7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJI7"
  },
  {
    "uniprot": "X8FA89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA89"
  },
  {
    "uniprot": "X8FIX9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIX9"
  },
  {
    "uniprot": "X8FGM2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGM2"
  },
  {
    "uniprot": "X8F3Z0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Z0"
  },
  {
    "uniprot": "X8FBW0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBW0"
  },
  {
    "uniprot": "X8FD74",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD74"
  },
  {
    "uniprot": "X8FMV4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMV4"
  },
  {
    "uniprot": "X8F934",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F934"
  },
  {
    "uniprot": "X8F240",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F240"
  },
  {
    "uniprot": "X8FM77",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM77"
  },
  {
    "uniprot": "X8F709",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F709"
  },
  {
    "uniprot": "X8FH93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH93"
  },
  {
    "uniprot": "X8F3S3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3S3"
  },
  {
    "uniprot": "X8FH73",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH73"
  },
  {
    "uniprot": "X8FDC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDC0"
  },
  {
    "uniprot": "X8FF42",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF42"
  },
  {
    "uniprot": "X8F249",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F249"
  },
  {
    "uniprot": "X8FHS4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHS4"
  },
  {
    "uniprot": "X8FDU2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDU2"
  },
  {
    "uniprot": "X8EYD7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYD7"
  },
  {
    "uniprot": "X8FK73",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK73"
  },
  {
    "uniprot": "X8FK83",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK83"
  },
  {
    "uniprot": "X8FEA8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEA8"
  },
  {
    "uniprot": "X8F6Z5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Z5"
  },
  {
    "uniprot": "X8F4D6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4D6"
  },
  {
    "uniprot": "X8F1H7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1H7"
  },
  {
    "uniprot": "X8FJ48",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ48"
  },
  {
    "uniprot": "X8FFT0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFT0"
  },
  {
    "uniprot": "X8FDR0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDR0"
  },
  {
    "uniprot": "X8FIH6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIH6"
  },
  {
    "uniprot": "X8F8Y2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Y2"
  },
  {
    "uniprot": "X8FNP7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNP7"
  },
  {
    "uniprot": "X8FB87",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB87"
  },
  {
    "uniprot": "X8FK22",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK22"
  },
  {
    "uniprot": "X8F7Y8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Y8"
  },
  {
    "uniprot": "X8F4Y1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Y1"
  },
  {
    "uniprot": "X8FFG3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFG3"
  },
  {
    "uniprot": "X8FJH7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJH7"
  },
  {
    "uniprot": "X8FQ61",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQ61"
  },
  {
    "uniprot": "X8FFT2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFT2"
  },
  {
    "uniprot": "X8FBJ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBJ1"
  },
  {
    "uniprot": "X8FM90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM90"
  },
  {
    "uniprot": "X8FN13",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN13"
  },
  {
    "uniprot": "X8FIV2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIV2"
  },
  {
    "uniprot": "X8FA85",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA85"
  },
  {
    "uniprot": "X8FD46",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD46"
  },
  {
    "uniprot": "X8F299",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F299"
  },
  {
    "uniprot": "X8EZJ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZJ8"
  },
  {
    "uniprot": "X8FJC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJC7"
  },
  {
    "uniprot": "X8F3M4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3M4"
  },
  {
    "uniprot": "X8FBC9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBC9"
  },
  {
    "uniprot": "X8FBS0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBS0"
  },
  {
    "uniprot": "X8FH38",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH38"
  },
  {
    "uniprot": "X8F2L6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2L6"
  },
  {
    "uniprot": "X8FHK4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHK4"
  },
  {
    "uniprot": "X8FDI3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDI3"
  },
  {
    "uniprot": "X8F0D9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0D9"
  },
  {
    "uniprot": "X8F2J5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2J5"
  },
  {
    "uniprot": "X8FM57",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM57"
  },
  {
    "uniprot": "X8FLC5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLC5"
  },
  {
    "uniprot": "X8FCI0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCI0"
  },
  {
    "uniprot": "X8F097",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F097"
  },
  {
    "uniprot": "X8EZV4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZV4"
  },
  {
    "uniprot": "X8FG25",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG25"
  },
  {
    "uniprot": "X8FLT3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLT3"
  },
  {
    "uniprot": "X8FGZ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGZ1"
  },
  {
    "uniprot": "X8F800",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F800"
  },
  {
    "uniprot": "X8FA66",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA66"
  },
  {
    "uniprot": "X8F6M8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6M8"
  },
  {
    "uniprot": "X8FI99",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI99"
  },
  {
    "uniprot": "X8FLP0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLP0"
  },
  {
    "uniprot": "X8F5C5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5C5"
  },
  {
    "uniprot": "X8F5A5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5A5"
  },
  {
    "uniprot": "X8F5W3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5W3"
  },
  {
    "uniprot": "X8FB32",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB32"
  },
  {
    "uniprot": "X8F6E3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6E3"
  },
  {
    "uniprot": "X8FA10",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA10"
  },
  {
    "uniprot": "X8FHU2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHU2"
  },
  {
    "uniprot": "X8F319",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F319"
  },
  {
    "uniprot": "X8F5P1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5P1"
  },
  {
    "uniprot": "X8FJP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJP9"
  },
  {
    "uniprot": "X8FL11",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL11"
  },
  {
    "uniprot": "X8FPK6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPK6"
  },
  {
    "uniprot": "X8FI45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI45"
  },
  {
    "uniprot": "X8FMS8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMS8"
  },
  {
    "uniprot": "X8EYP8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYP8"
  },
  {
    "uniprot": "X8FF61",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF61"
  },
  {
    "uniprot": "X8FHU9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHU9"
  },
  {
    "uniprot": "X8EYS0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYS0"
  },
  {
    "uniprot": "X8FD13",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD13"
  },
  {
    "uniprot": "X8FCQ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCQ3"
  },
  {
    "uniprot": "X8FKK8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKK8"
  },
  {
    "uniprot": "X8FPR2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPR2"
  },
  {
    "uniprot": "X8FPI6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPI6"
  },
  {
    "uniprot": "X8FLJ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLJ8"
  },
  {
    "uniprot": "X8F7P4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7P4"
  },
  {
    "uniprot": "X8F7A8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7A8"
  },
  {
    "uniprot": "X8F562",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F562"
  },
  {
    "uniprot": "X8F705",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F705"
  },
  {
    "uniprot": "X8FDN2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDN2"
  },
  {
    "uniprot": "X8FCF4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCF4"
  },
  {
    "uniprot": "X8F567",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F567"
  },
  {
    "uniprot": "X8F119",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F119"
  },
  {
    "uniprot": "X8FC26",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC26"
  },
  {
    "uniprot": "X8FJV1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJV1"
  },
  {
    "uniprot": "X8FJN6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJN6"
  },
  {
    "uniprot": "X8FGM1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGM1"
  },
  {
    "uniprot": "X8FAK1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAK1"
  },
  {
    "uniprot": "X8EZV7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZV7"
  },
  {
    "uniprot": "X8F170",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F170"
  },
  {
    "uniprot": "X8F2H4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2H4"
  },
  {
    "uniprot": "X8F801",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F801"
  },
  {
    "uniprot": "X8F7L1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7L1"
  },
  {
    "uniprot": "X8FB79",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB79"
  },
  {
    "uniprot": "X8F6F1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6F1"
  },
  {
    "uniprot": "X8FEB5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEB5"
  },
  {
    "uniprot": "X8FPG6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPG6"
  },
  {
    "uniprot": "X8F2W9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2W9"
  },
  {
    "uniprot": "X8FKK6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKK6"
  },
  {
    "uniprot": "X8FDJ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDJ3"
  },
  {
    "uniprot": "X8F792",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F792"
  },
  {
    "uniprot": "X8EW40",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EW40"
  },
  {
    "uniprot": "X8F6D8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6D8"
  },
  {
    "uniprot": "X8FDP2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDP2"
  },
  {
    "uniprot": "X8FBH1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBH1"
  },
  {
    "uniprot": "X8EY24",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY24"
  },
  {
    "uniprot": "X8FPD8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPD8"
  },
  {
    "uniprot": "X8F9X4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9X4"
  },
  {
    "uniprot": "X8FJ52",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ52"
  },
  {
    "uniprot": "X8FCV5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCV5"
  },
  {
    "uniprot": "X8F1Z2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Z2"
  },
  {
    "uniprot": "X8FQF7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQF7"
  },
  {
    "uniprot": "X8FGU9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGU9"
  },
  {
    "uniprot": "X8FAJ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAJ0"
  },
  {
    "uniprot": "X8F412",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F412"
  },
  {
    "uniprot": "X8FEJ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEJ3"
  },
  {
    "uniprot": "X8FD57",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD57"
  },
  {
    "uniprot": "X8F8A2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8A2"
  },
  {
    "uniprot": "X8EXC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXC7"
  },
  {
    "uniprot": "X8FKC3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKC3"
  },
  {
    "uniprot": "X8FMI3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMI3"
  },
  {
    "uniprot": "X8F281",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F281"
  },
  {
    "uniprot": "X8F5I9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5I9"
  },
  {
    "uniprot": "X8F8Z6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Z6"
  },
  {
    "uniprot": "X8F994",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F994"
  },
  {
    "uniprot": "X8FJ06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ06"
  },
  {
    "uniprot": "X8F3F5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3F5"
  },
  {
    "uniprot": "X8EYZ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYZ6"
  },
  {
    "uniprot": "X8F811",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F811"
  },
  {
    "uniprot": "X8FHJ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHJ2"
  },
  {
    "uniprot": "X8F723",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F723"
  },
  {
    "uniprot": "X8FIF2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIF2"
  },
  {
    "uniprot": "X8FKJ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKJ4"
  },
  {
    "uniprot": "X8F487",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F487"
  },
  {
    "uniprot": "X8EZ98",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ98"
  },
  {
    "uniprot": "X8FCW3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCW3"
  },
  {
    "uniprot": "X8FC39",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC39"
  },
  {
    "uniprot": "X8FBA7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBA7"
  },
  {
    "uniprot": "X8F2W2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2W2"
  },
  {
    "uniprot": "X8F5H3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5H3"
  },
  {
    "uniprot": "X8F8J2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8J2"
  },
  {
    "uniprot": "X8F9U8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9U8"
  },
  {
    "uniprot": "X8FP91",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP91"
  },
  {
    "uniprot": "X8F7U3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7U3"
  },
  {
    "uniprot": "X8F4Q6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Q6"
  },
  {
    "uniprot": "X8F6B3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6B3"
  },
  {
    "uniprot": "X8F083",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F083"
  },
  {
    "uniprot": "X8FMN1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMN1"
  },
  {
    "uniprot": "X8FPJ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPJ4"
  },
  {
    "uniprot": "X8EZD3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZD3"
  },
  {
    "uniprot": "X8FK47",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK47"
  },
  {
    "uniprot": "X8FI58",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI58"
  },
  {
    "uniprot": "X8FBT4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBT4"
  },
  {
    "uniprot": "X8F718",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F718"
  },
  {
    "uniprot": "X8F3S4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3S4"
  },
  {
    "uniprot": "X8F0F6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0F6"
  },
  {
    "uniprot": "X8FFU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFU0"
  },
  {
    "uniprot": "X8FH50",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH50"
  },
  {
    "uniprot": "X8FD22",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD22"
  },
  {
    "uniprot": "X8FDF9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDF9"
  },
  {
    "uniprot": "X8F5Z9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Z9"
  },
  {
    "uniprot": "X8F921",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F921"
  },
  {
    "uniprot": "X8FGW3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGW3"
  },
  {
    "uniprot": "X8FHZ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHZ2"
  },
  {
    "uniprot": "X8F4G3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4G3"
  },
  {
    "uniprot": "X8FI88",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI88"
  },
  {
    "uniprot": "X8F0Q0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Q0"
  },
  {
    "uniprot": "X8F0I2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0I2"
  },
  {
    "uniprot": "X8FM47",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM47"
  },
  {
    "uniprot": "X8F7H2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7H2"
  },
  {
    "uniprot": "X8FA23",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA23"
  },
  {
    "uniprot": "X8FA44",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA44"
  },
  {
    "uniprot": "X8F1B9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1B9"
  },
  {
    "uniprot": "X8FH09",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH09"
  },
  {
    "uniprot": "X8FKQ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKQ7"
  },
  {
    "uniprot": "X8F365",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F365"
  },
  {
    "uniprot": "X8F8K9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8K9"
  },
  {
    "uniprot": "X8F0U4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0U4"
  },
  {
    "uniprot": "X8FFP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFP9"
  },
  {
    "uniprot": "X8F6H6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6H6"
  },
  {
    "uniprot": "X8F693",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F693"
  },
  {
    "uniprot": "X8F6L3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6L3"
  },
  {
    "uniprot": "X8FE23",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE23"
  },
  {
    "uniprot": "X8FIX3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIX3"
  },
  {
    "uniprot": "X8F9Z0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Z0"
  },
  {
    "uniprot": "X8FD35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD35"
  },
  {
    "uniprot": "X8F469",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F469"
  },
  {
    "uniprot": "X8FEF8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEF8"
  },
  {
    "uniprot": "X8F8L0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8L0"
  },
  {
    "uniprot": "X8FKZ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKZ6"
  },
  {
    "uniprot": "X8FCV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCV0"
  },
  {
    "uniprot": "X8FHJ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHJ1"
  },
  {
    "uniprot": "X8F533",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F533"
  },
  {
    "uniprot": "X8FLR1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLR1"
  },
  {
    "uniprot": "X8FG22",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG22"
  },
  {
    "uniprot": "X8FKB7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKB7"
  },
  {
    "uniprot": "X8FIM1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIM1"
  },
  {
    "uniprot": "X8F0G3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0G3"
  },
  {
    "uniprot": "X8FJM7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJM7"
  },
  {
    "uniprot": "X8F5N1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5N1"
  },
  {
    "uniprot": "X8FHQ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHQ3"
  },
  {
    "uniprot": "X8F5H7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5H7"
  },
  {
    "uniprot": "X8EZ87",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ87"
  },
  {
    "uniprot": "X8F3I4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3I4"
  },
  {
    "uniprot": "X8FCJ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCJ4"
  },
  {
    "uniprot": "X8F942",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F942"
  },
  {
    "uniprot": "X8FBP0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBP0"
  },
  {
    "uniprot": "X8F7M6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7M6"
  },
  {
    "uniprot": "X8F9B8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9B8"
  },
  {
    "uniprot": "X8EX21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX21"
  },
  {
    "uniprot": "X8FEF6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEF6"
  },
  {
    "uniprot": "X8F2F0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2F0"
  },
  {
    "uniprot": "X8FD80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD80"
  },
  {
    "uniprot": "X8F3Y9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Y9"
  },
  {
    "uniprot": "X8FI01",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI01"
  },
  {
    "uniprot": "X8F8R0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8R0"
  },
  {
    "uniprot": "X8F0K1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0K1"
  },
  {
    "uniprot": "X8EYZ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYZ2"
  },
  {
    "uniprot": "X8FC13",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC13"
  },
  {
    "uniprot": "X8F2M7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2M7"
  },
  {
    "uniprot": "X8EWU9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWU9"
  },
  {
    "uniprot": "X8FAZ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAZ5"
  },
  {
    "uniprot": "X8F6S0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6S0"
  },
  {
    "uniprot": "X8F4C8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4C8"
  },
  {
    "uniprot": "X8F124",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F124"
  },
  {
    "uniprot": "X8EW06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EW06"
  },
  {
    "uniprot": "X8FLU4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLU4"
  },
  {
    "uniprot": "X8EYX5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYX5"
  },
  {
    "uniprot": "X8FH86",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH86"
  },
  {
    "uniprot": "X8F813",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F813"
  },
  {
    "uniprot": "X8F1Y6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Y6"
  },
  {
    "uniprot": "X8F3I5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3I5"
  },
  {
    "uniprot": "X8FAW8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAW8"
  },
  {
    "uniprot": "X8FFF2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFF2"
  },
  {
    "uniprot": "X8FH58",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH58"
  },
  {
    "uniprot": "X8FJ61",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ61"
  },
  {
    "uniprot": "X8F0V6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0V6"
  },
  {
    "uniprot": "X8FF76",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF76"
  },
  {
    "uniprot": "X8FGZ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGZ3"
  },
  {
    "uniprot": "X8FHK8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHK8"
  },
  {
    "uniprot": "X8F0G2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0G2"
  },
  {
    "uniprot": "X8FLS6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLS6"
  },
  {
    "uniprot": "X8F1I3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1I3"
  },
  {
    "uniprot": "X8F6Y7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Y7"
  },
  {
    "uniprot": "X8FPF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPF3"
  },
  {
    "uniprot": "X8FLA7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLA7"
  },
  {
    "uniprot": "X8FCZ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCZ1"
  },
  {
    "uniprot": "X8FHV4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHV4"
  },
  {
    "uniprot": "X8FM69",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM69"
  },
  {
    "uniprot": "X8FH47",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH47"
  },
  {
    "uniprot": "X8FGU8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGU8"
  },
  {
    "uniprot": "X8FI60",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI60"
  },
  {
    "uniprot": "X8F936",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F936"
  },
  {
    "uniprot": "X8FJT2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJT2"
  },
  {
    "uniprot": "X8FIT3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIT3"
  },
  {
    "uniprot": "X8FFB9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFB9"
  },
  {
    "uniprot": "X8FK08",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK08"
  },
  {
    "uniprot": "X8F9F9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9F9"
  },
  {
    "uniprot": "X8FG60",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG60"
  },
  {
    "uniprot": "X8F736",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F736"
  },
  {
    "uniprot": "X8FLZ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLZ9"
  },
  {
    "uniprot": "X8F4H2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4H2"
  },
  {
    "uniprot": "X8F845",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F845"
  },
  {
    "uniprot": "X8FDD5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDD5"
  },
  {
    "uniprot": "X8FKB9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKB9"
  },
  {
    "uniprot": "X8F132",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F132"
  },
  {
    "uniprot": "X8FJ63",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ63"
  },
  {
    "uniprot": "X8FAB1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAB1"
  },
  {
    "uniprot": "X8FKZ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKZ2"
  },
  {
    "uniprot": "X8F301",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F301"
  },
  {
    "uniprot": "X8FNT3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNT3"
  },
  {
    "uniprot": "X8FIN5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIN5"
  },
  {
    "uniprot": "X8F9A6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9A6"
  },
  {
    "uniprot": "X8F6N1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6N1"
  },
  {
    "uniprot": "X8F3S1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3S1"
  },
  {
    "uniprot": "X8FK40",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK40"
  },
  {
    "uniprot": "X8F2M9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2M9"
  },
  {
    "uniprot": "X8F5A0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5A0"
  },
  {
    "uniprot": "X8FGM6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGM6"
  },
  {
    "uniprot": "X8F1U7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1U7"
  },
  {
    "uniprot": "X8FHI1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHI1"
  },
  {
    "uniprot": "X8FID9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FID9"
  },
  {
    "uniprot": "X8F460",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F460"
  },
  {
    "uniprot": "X8FI94",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI94"
  },
  {
    "uniprot": "X8F2K4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2K4"
  },
  {
    "uniprot": "X8FEN2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEN2"
  },
  {
    "uniprot": "X8FEM3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEM3"
  },
  {
    "uniprot": "X8FA09",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA09"
  },
  {
    "uniprot": "X8F7U5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7U5"
  },
  {
    "uniprot": "X8FI19",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI19"
  },
  {
    "uniprot": "X8F0P1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0P1"
  },
  {
    "uniprot": "X8FLS5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLS5"
  },
  {
    "uniprot": "X8FH74",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH74"
  },
  {
    "uniprot": "X8F8R6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8R6"
  },
  {
    "uniprot": "X8FAF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAF3"
  },
  {
    "uniprot": "X8F0U7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0U7"
  },
  {
    "uniprot": "X8F8G6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8G6"
  },
  {
    "uniprot": "X8FIE1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIE1"
  },
  {
    "uniprot": "X8F5L1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5L1"
  },
  {
    "uniprot": "X8FMB8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMB8"
  },
  {
    "uniprot": "X8F1W9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1W9"
  },
  {
    "uniprot": "X8FDW1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDW1"
  },
  {
    "uniprot": "X8FAH9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAH9"
  },
  {
    "uniprot": "X8FJP8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJP8"
  },
  {
    "uniprot": "X8FLC1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLC1"
  },
  {
    "uniprot": "X8FNX2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNX2"
  },
  {
    "uniprot": "X8FLB8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLB8"
  },
  {
    "uniprot": "X8FDV4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDV4"
  },
  {
    "uniprot": "X8FNI6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNI6"
  },
  {
    "uniprot": "X8F8V8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8V8"
  },
  {
    "uniprot": "X8F4M8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4M8"
  },
  {
    "uniprot": "X8FKE0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKE0"
  },
  {
    "uniprot": "X8F2N1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2N1"
  },
  {
    "uniprot": "X8FM44",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM44"
  },
  {
    "uniprot": "X8FKB6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKB6"
  },
  {
    "uniprot": "X8FM41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM41"
  },
  {
    "uniprot": "X8FC51",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC51"
  },
  {
    "uniprot": "X8F3M3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3M3"
  },
  {
    "uniprot": "X8F2X9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2X9"
  },
  {
    "uniprot": "X8F3L7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3L7"
  },
  {
    "uniprot": "X8FA26",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA26"
  },
  {
    "uniprot": "X8F5Y3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Y3"
  },
  {
    "uniprot": "X8FIL9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIL9"
  },
  {
    "uniprot": "X8FP25",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP25"
  },
  {
    "uniprot": "X8F5K5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5K5"
  },
  {
    "uniprot": "X8F223",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F223"
  },
  {
    "uniprot": "X8FDH5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDH5"
  },
  {
    "uniprot": "X8F5H6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5H6"
  },
  {
    "uniprot": "X8F1U8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1U8"
  },
  {
    "uniprot": "X8F7J4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7J4"
  },
  {
    "uniprot": "X8F2S0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2S0"
  },
  {
    "uniprot": "X8F1B3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1B3"
  },
  {
    "uniprot": "X8F928",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F928"
  },
  {
    "uniprot": "X8FDI7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDI7"
  },
  {
    "uniprot": "X8FJL1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJL1"
  },
  {
    "uniprot": "X8FI07",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI07"
  },
  {
    "uniprot": "X8FJG9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJG9"
  },
  {
    "uniprot": "X8FJQ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJQ2"
  },
  {
    "uniprot": "X8EZS0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZS0"
  },
  {
    "uniprot": "X8FG38",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG38"
  },
  {
    "uniprot": "X8EZM1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZM1"
  },
  {
    "uniprot": "X8FKY8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKY8"
  },
  {
    "uniprot": "X8FMN0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMN0"
  },
  {
    "uniprot": "X8F424",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F424"
  },
  {
    "uniprot": "X8FHI4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHI4"
  },
  {
    "uniprot": "X8FMI0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMI0"
  },
  {
    "uniprot": "X8F6U1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6U1"
  },
  {
    "uniprot": "X8F5Y2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Y2"
  },
  {
    "uniprot": "X8FF32",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF32"
  },
  {
    "uniprot": "X8F110",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F110"
  },
  {
    "uniprot": "X8F7Q9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Q9"
  },
  {
    "uniprot": "X8FE90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE90"
  },
  {
    "uniprot": "X8F1S6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1S6"
  },
  {
    "uniprot": "X8F326",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F326"
  },
  {
    "uniprot": "X8FH03",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH03"
  },
  {
    "uniprot": "X8EZL9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZL9"
  },
  {
    "uniprot": "X8F2A4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2A4"
  },
  {
    "uniprot": "X8FGS5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGS5"
  },
  {
    "uniprot": "X8FPE7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPE7"
  },
  {
    "uniprot": "X8F576",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F576"
  },
  {
    "uniprot": "X8FGV9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGV9"
  },
  {
    "uniprot": "X8FB07",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB07"
  },
  {
    "uniprot": "X8FGN3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGN3"
  },
  {
    "uniprot": "X8F731",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F731"
  },
  {
    "uniprot": "X8F5L9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5L9"
  },
  {
    "uniprot": "X8FFG0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFG0"
  },
  {
    "uniprot": "X8F2J3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2J3"
  },
  {
    "uniprot": "X8FDK9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDK9"
  },
  {
    "uniprot": "X8FIS8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIS8"
  },
  {
    "uniprot": "X8F5H0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5H0"
  },
  {
    "uniprot": "X8FLX0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLX0"
  },
  {
    "uniprot": "X8FMY6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMY6"
  },
  {
    "uniprot": "X8FJ94",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ94"
  },
  {
    "uniprot": "X8FJ10",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ10"
  },
  {
    "uniprot": "X8F6C1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6C1"
  },
  {
    "uniprot": "X8F675",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F675"
  },
  {
    "uniprot": "X8F8H1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8H1"
  },
  {
    "uniprot": "X8F4W5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4W5"
  },
  {
    "uniprot": "X8F610",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F610"
  },
  {
    "uniprot": "X8F2S3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2S3"
  },
  {
    "uniprot": "X8F6G3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6G3"
  },
  {
    "uniprot": "X8FE82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE82"
  },
  {
    "uniprot": "X8FKB2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKB2"
  },
  {
    "uniprot": "X8EWY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWY9"
  },
  {
    "uniprot": "X8F2P8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2P8"
  },
  {
    "uniprot": "X8EYQ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYQ2"
  },
  {
    "uniprot": "X8F8U5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8U5"
  },
  {
    "uniprot": "X8FEY3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEY3"
  },
  {
    "uniprot": "X8F2J0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2J0"
  },
  {
    "uniprot": "X8FJE7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJE7"
  },
  {
    "uniprot": "X8FF83",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF83"
  },
  {
    "uniprot": "X8FME6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FME6"
  },
  {
    "uniprot": "X8EY17",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY17"
  },
  {
    "uniprot": "X8F8Z4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Z4"
  },
  {
    "uniprot": "X8F3S2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3S2"
  },
  {
    "uniprot": "X8F859",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F859"
  },
  {
    "uniprot": "X8F7S5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7S5"
  },
  {
    "uniprot": "X8FDM5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDM5"
  },
  {
    "uniprot": "X8FEI5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEI5"
  },
  {
    "uniprot": "X8FKN5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKN5"
  },
  {
    "uniprot": "X8F1Z5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Z5"
  },
  {
    "uniprot": "X8FIP5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIP5"
  },
  {
    "uniprot": "X8FKH3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKH3"
  },
  {
    "uniprot": "X8FKJ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKJ3"
  },
  {
    "uniprot": "X8F029",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F029"
  },
  {
    "uniprot": "X8FA21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA21"
  },
  {
    "uniprot": "X8FEV7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEV7"
  },
  {
    "uniprot": "X8F4N6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4N6"
  },
  {
    "uniprot": "X8FI34",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI34"
  },
  {
    "uniprot": "X8FEC1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEC1"
  },
  {
    "uniprot": "X8F9S2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9S2"
  },
  {
    "uniprot": "X8F5W4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5W4"
  },
  {
    "uniprot": "X8F1J0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1J0"
  },
  {
    "uniprot": "X8FF81",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF81"
  },
  {
    "uniprot": "X8F0A4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0A4"
  },
  {
    "uniprot": "X8FE12",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE12"
  },
  {
    "uniprot": "X8F9N2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9N2"
  },
  {
    "uniprot": "X8FJE1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJE1"
  },
  {
    "uniprot": "X8F569",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F569"
  },
  {
    "uniprot": "X8F093",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F093"
  },
  {
    "uniprot": "X8FEZ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEZ7"
  },
  {
    "uniprot": "X8FFD4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFD4"
  },
  {
    "uniprot": "X8F5P0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5P0"
  },
  {
    "uniprot": "X8FC10",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC10"
  },
  {
    "uniprot": "X8F0Z0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Z0"
  },
  {
    "uniprot": "X8FH83",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH83"
  },
  {
    "uniprot": "X8FKA5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKA5"
  },
  {
    "uniprot": "X8FLJ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLJ0"
  },
  {
    "uniprot": "X8F044",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F044"
  },
  {
    "uniprot": "X8F5F6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5F6"
  },
  {
    "uniprot": "X8EZP6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZP6"
  },
  {
    "uniprot": "X8F840",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F840"
  },
  {
    "uniprot": "X8FGJ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGJ5"
  },
  {
    "uniprot": "X8F7A3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7A3"
  },
  {
    "uniprot": "X8EXR7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXR7"
  },
  {
    "uniprot": "X8FIG6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIG6"
  },
  {
    "uniprot": "X8FJS8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJS8"
  },
  {
    "uniprot": "X8F3I2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3I2"
  },
  {
    "uniprot": "X8F641",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F641"
  },
  {
    "uniprot": "X8EZ80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ80"
  },
  {
    "uniprot": "X8FBK5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBK5"
  },
  {
    "uniprot": "X8FFK1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFK1"
  },
  {
    "uniprot": "X8EZH6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZH6"
  },
  {
    "uniprot": "X8FBG7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBG7"
  },
  {
    "uniprot": "X8F2S4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2S4"
  },
  {
    "uniprot": "X8F9B3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9B3"
  },
  {
    "uniprot": "X8FJU7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJU7"
  },
  {
    "uniprot": "X8FK00",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK00"
  },
  {
    "uniprot": "X8F199",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F199"
  },
  {
    "uniprot": "X8EZS6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZS6"
  },
  {
    "uniprot": "X8F7X4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7X4"
  },
  {
    "uniprot": "X8EZ85",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ85"
  },
  {
    "uniprot": "X8F1H5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1H5"
  },
  {
    "uniprot": "X8FJY8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJY8"
  },
  {
    "uniprot": "X8FLS4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLS4"
  },
  {
    "uniprot": "X8F8D6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8D6"
  },
  {
    "uniprot": "X8F2B9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2B9"
  },
  {
    "uniprot": "X8F3X1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3X1"
  },
  {
    "uniprot": "X8FF64",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF64"
  },
  {
    "uniprot": "X8F8Q3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Q3"
  },
  {
    "uniprot": "X8F0U5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0U5"
  },
  {
    "uniprot": "X8FJF0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJF0"
  },
  {
    "uniprot": "X8FAE3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAE3"
  },
  {
    "uniprot": "X8FJ64",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ64"
  },
  {
    "uniprot": "X8FHN3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHN3"
  },
  {
    "uniprot": "X8F2V6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2V6"
  },
  {
    "uniprot": "X8FDL7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDL7"
  },
  {
    "uniprot": "X8FIR8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIR8"
  },
  {
    "uniprot": "X8F4C6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4C6"
  },
  {
    "uniprot": "X8F4H6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4H6"
  },
  {
    "uniprot": "X8F9A9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9A9"
  },
  {
    "uniprot": "X8F5T7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5T7"
  },
  {
    "uniprot": "X8F971",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F971"
  },
  {
    "uniprot": "X8FFQ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFQ0"
  },
  {
    "uniprot": "X8FCE1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCE1"
  },
  {
    "uniprot": "X8FFI2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFI2"
  },
  {
    "uniprot": "X8FGX8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGX8"
  },
  {
    "uniprot": "X8F6W4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6W4"
  },
  {
    "uniprot": "X8F7T2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7T2"
  },
  {
    "uniprot": "X8FAK0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAK0"
  },
  {
    "uniprot": "X8FIF4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIF4"
  },
  {
    "uniprot": "X8FLF2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLF2"
  },
  {
    "uniprot": "X8F7L4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7L4"
  },
  {
    "uniprot": "X8F1M6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1M6"
  },
  {
    "uniprot": "X8FCE4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCE4"
  },
  {
    "uniprot": "X8F668",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F668"
  },
  {
    "uniprot": "X8FEZ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEZ2"
  },
  {
    "uniprot": "X8FCU4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCU4"
  },
  {
    "uniprot": "X8FH66",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH66"
  },
  {
    "uniprot": "X8F5R5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5R5"
  },
  {
    "uniprot": "X8F3C0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3C0"
  },
  {
    "uniprot": "X8F969",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F969"
  },
  {
    "uniprot": "X8FEX5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEX5"
  },
  {
    "uniprot": "X8FC22",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC22"
  },
  {
    "uniprot": "X8FMW9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMW9"
  },
  {
    "uniprot": "X8FEY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEY5"
  },
  {
    "uniprot": "X8EZQ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZQ1"
  },
  {
    "uniprot": "X8F5D1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5D1"
  },
  {
    "uniprot": "X8F8Q6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Q6"
  },
  {
    "uniprot": "X8FL53",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL53"
  },
  {
    "uniprot": "X8FFF8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFF8"
  },
  {
    "uniprot": "X8FFJ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFJ5"
  },
  {
    "uniprot": "X8F4A1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4A1"
  },
  {
    "uniprot": "X8FCX2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCX2"
  },
  {
    "uniprot": "X8FFB8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFB8"
  },
  {
    "uniprot": "X8FF39",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF39"
  },
  {
    "uniprot": "X8FF67",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF67"
  },
  {
    "uniprot": "X8FBT7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBT7"
  },
  {
    "uniprot": "X8FCL1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCL1"
  },
  {
    "uniprot": "X8FFP2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFP2"
  },
  {
    "uniprot": "X8FDE6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDE6"
  },
  {
    "uniprot": "X8F3E0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3E0"
  },
  {
    "uniprot": "X8FMY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMY1"
  },
  {
    "uniprot": "X8F9M9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9M9"
  },
  {
    "uniprot": "X8F1X2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1X2"
  },
  {
    "uniprot": "X8FLL0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLL0"
  },
  {
    "uniprot": "X8F657",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F657"
  },
  {
    "uniprot": "X8F7Y1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Y1"
  },
  {
    "uniprot": "X8FIH7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIH7"
  },
  {
    "uniprot": "X8FJD3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJD3"
  },
  {
    "uniprot": "X8F7C3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7C3"
  },
  {
    "uniprot": "X8FHT3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHT3"
  },
  {
    "uniprot": "X8EZL7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZL7"
  },
  {
    "uniprot": "X8FI47",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI47"
  },
  {
    "uniprot": "X8F977",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F977"
  },
  {
    "uniprot": "X8F2C1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2C1"
  },
  {
    "uniprot": "X8F248",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F248"
  },
  {
    "uniprot": "X8FH85",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH85"
  },
  {
    "uniprot": "X8FIY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIY9"
  },
  {
    "uniprot": "X8F2Z1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Z1"
  },
  {
    "uniprot": "X8FJF1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJF1"
  },
  {
    "uniprot": "X8EZN7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZN7"
  },
  {
    "uniprot": "X8FFG4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFG4"
  },
  {
    "uniprot": "X8FDU1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDU1"
  },
  {
    "uniprot": "X8F9P4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9P4"
  },
  {
    "uniprot": "X8FHM8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHM8"
  },
  {
    "uniprot": "X8F3Z9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Z9"
  },
  {
    "uniprot": "X8EXB8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXB8"
  },
  {
    "uniprot": "X8FCI2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCI2"
  },
  {
    "uniprot": "X8FMQ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMQ9"
  },
  {
    "uniprot": "X8FML3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FML3"
  },
  {
    "uniprot": "X8F178",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F178"
  },
  {
    "uniprot": "X8F0I4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0I4"
  },
  {
    "uniprot": "X8FB17",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB17"
  },
  {
    "uniprot": "X8EZC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZC7"
  },
  {
    "uniprot": "X8FHP1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHP1"
  },
  {
    "uniprot": "X8F7W7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7W7"
  },
  {
    "uniprot": "X8FM15",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM15"
  },
  {
    "uniprot": "X8FHJ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHJ5"
  },
  {
    "uniprot": "X8FLR2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLR2"
  },
  {
    "uniprot": "X8EYT2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYT2"
  },
  {
    "uniprot": "X8F776",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F776"
  },
  {
    "uniprot": "X8EZK8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZK8"
  },
  {
    "uniprot": "X8FNN6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNN6"
  },
  {
    "uniprot": "X8EXM1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXM1"
  },
  {
    "uniprot": "X8FBQ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBQ9"
  },
  {
    "uniprot": "X8FAD9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAD9"
  },
  {
    "uniprot": "X8F300",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F300"
  },
  {
    "uniprot": "X8FMT7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMT7"
  },
  {
    "uniprot": "X8FGU3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGU3"
  },
  {
    "uniprot": "X8F5F8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5F8"
  },
  {
    "uniprot": "X8F285",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F285"
  },
  {
    "uniprot": "X8FDQ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDQ2"
  },
  {
    "uniprot": "X8FAG8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAG8"
  },
  {
    "uniprot": "X8FFZ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFZ9"
  },
  {
    "uniprot": "X8FH14",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH14"
  },
  {
    "uniprot": "X8FA57",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA57"
  },
  {
    "uniprot": "X8F088",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F088"
  },
  {
    "uniprot": "X8FFM2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFM2"
  },
  {
    "uniprot": "X8FJD9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJD9"
  },
  {
    "uniprot": "X8FBU4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBU4"
  },
  {
    "uniprot": "X8FGV1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGV1"
  },
  {
    "uniprot": "X8FD55",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD55"
  },
  {
    "uniprot": "X8FNE4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNE4"
  },
  {
    "uniprot": "X8FLN5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLN5"
  },
  {
    "uniprot": "X8F359",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F359"
  },
  {
    "uniprot": "X8FMS6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMS6"
  },
  {
    "uniprot": "X8EYH5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYH5"
  },
  {
    "uniprot": "X8FLJ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLJ6"
  },
  {
    "uniprot": "X8FCB2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCB2"
  },
  {
    "uniprot": "X8F3R5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3R5"
  },
  {
    "uniprot": "X8FDC2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDC2"
  },
  {
    "uniprot": "X8F532",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F532"
  },
  {
    "uniprot": "X8FHH2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHH2"
  },
  {
    "uniprot": "X8F6N4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6N4"
  },
  {
    "uniprot": "X8FBH6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBH6"
  },
  {
    "uniprot": "X8F1K3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1K3"
  },
  {
    "uniprot": "X8FH56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH56"
  },
  {
    "uniprot": "X8F941",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F941"
  },
  {
    "uniprot": "X8FIP1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIP1"
  },
  {
    "uniprot": "X8EYB2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYB2"
  },
  {
    "uniprot": "X8F1G8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1G8"
  },
  {
    "uniprot": "X8FB49",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB49"
  },
  {
    "uniprot": "X8FGV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGV0"
  },
  {
    "uniprot": "X8F6H7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6H7"
  },
  {
    "uniprot": "X8FH77",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH77"
  },
  {
    "uniprot": "X8FGL6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGL6"
  },
  {
    "uniprot": "X8FG05",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG05"
  },
  {
    "uniprot": "X8EWS4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWS4"
  },
  {
    "uniprot": "X8FJB9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJB9"
  },
  {
    "uniprot": "X8F8H4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8H4"
  },
  {
    "uniprot": "X8FN80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN80"
  },
  {
    "uniprot": "X8F0U2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0U2"
  },
  {
    "uniprot": "X8EZH8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZH8"
  },
  {
    "uniprot": "X8FG86",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG86"
  },
  {
    "uniprot": "X8EYP3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYP3"
  },
  {
    "uniprot": "X8FIB0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIB0"
  },
  {
    "uniprot": "X8FK66",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK66"
  },
  {
    "uniprot": "X8FGH2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGH2"
  },
  {
    "uniprot": "X8FMW6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMW6"
  },
  {
    "uniprot": "X8FGG4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGG4"
  },
  {
    "uniprot": "X8F2V4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2V4"
  },
  {
    "uniprot": "X8F264",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F264"
  },
  {
    "uniprot": "X8F2D8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2D8"
  },
  {
    "uniprot": "X8FNR2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNR2"
  },
  {
    "uniprot": "X8F206",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F206"
  },
  {
    "uniprot": "X8FA31",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA31"
  },
  {
    "uniprot": "X8FAP1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAP1"
  },
  {
    "uniprot": "X8FMY4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMY4"
  },
  {
    "uniprot": "X8FDE8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDE8"
  },
  {
    "uniprot": "X8EXZ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXZ3"
  },
  {
    "uniprot": "X8F788",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F788"
  },
  {
    "uniprot": "X8FDW3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDW3"
  },
  {
    "uniprot": "X8FKX5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKX5"
  },
  {
    "uniprot": "X8F7Y6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Y6"
  },
  {
    "uniprot": "X8FD59",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD59"
  },
  {
    "uniprot": "X8F4Y3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Y3"
  },
  {
    "uniprot": "X8F6B7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6B7"
  },
  {
    "uniprot": "X8F9K7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9K7"
  },
  {
    "uniprot": "X8EZ15",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ15"
  },
  {
    "uniprot": "X8FP99",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP99"
  },
  {
    "uniprot": "X8F4Z0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Z0"
  },
  {
    "uniprot": "X8EWB7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWB7"
  },
  {
    "uniprot": "X8FJI3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJI3"
  },
  {
    "uniprot": "X8F2C9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2C9"
  },
  {
    "uniprot": "X8FAC4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAC4"
  },
  {
    "uniprot": "X8FH35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH35"
  },
  {
    "uniprot": "X8F3S5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3S5"
  },
  {
    "uniprot": "X8FGK3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGK3"
  },
  {
    "uniprot": "X8F7F6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7F6"
  },
  {
    "uniprot": "X8F6I4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6I4"
  },
  {
    "uniprot": "X8F1M7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1M7"
  },
  {
    "uniprot": "X8FE29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE29"
  },
  {
    "uniprot": "X8FCA3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCA3"
  },
  {
    "uniprot": "X8F669",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F669"
  },
  {
    "uniprot": "X8FAV1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAV1"
  },
  {
    "uniprot": "X8EX73",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX73"
  },
  {
    "uniprot": "X8FNG3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNG3"
  },
  {
    "uniprot": "X8F325",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F325"
  },
  {
    "uniprot": "X8F5E4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5E4"
  },
  {
    "uniprot": "X8F287",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F287"
  },
  {
    "uniprot": "X8F1H1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1H1"
  },
  {
    "uniprot": "X8EZG6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZG6"
  },
  {
    "uniprot": "X8FP58",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP58"
  },
  {
    "uniprot": "X8FET4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FET4"
  },
  {
    "uniprot": "X8FIS4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIS4"
  },
  {
    "uniprot": "X8F1T3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1T3"
  },
  {
    "uniprot": "X8FB55",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB55"
  },
  {
    "uniprot": "X8FHY4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHY4"
  },
  {
    "uniprot": "X8F5Q1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Q1"
  },
  {
    "uniprot": "X8FH87",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH87"
  },
  {
    "uniprot": "X8FLC6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLC6"
  },
  {
    "uniprot": "X8FIE7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIE7"
  },
  {
    "uniprot": "X8F3C2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3C2"
  },
  {
    "uniprot": "X8FIL4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIL4"
  },
  {
    "uniprot": "X8FDS8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDS8"
  },
  {
    "uniprot": "X8FAY3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAY3"
  },
  {
    "uniprot": "X8F4J9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4J9"
  },
  {
    "uniprot": "X8F5X0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5X0"
  },
  {
    "uniprot": "X8FCV4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCV4"
  },
  {
    "uniprot": "X8F8L7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8L7"
  },
  {
    "uniprot": "X8F7V4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7V4"
  },
  {
    "uniprot": "X8FF87",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF87"
  },
  {
    "uniprot": "X8F2Y2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Y2"
  },
  {
    "uniprot": "X8FEF5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEF5"
  },
  {
    "uniprot": "X8FGP2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGP2"
  },
  {
    "uniprot": "X8EYZ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYZ3"
  },
  {
    "uniprot": "X8FNK3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNK3"
  },
  {
    "uniprot": "X8F3M2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3M2"
  },
  {
    "uniprot": "X8FDC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDC7"
  },
  {
    "uniprot": "X8F0Y4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Y4"
  },
  {
    "uniprot": "X8F0E3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0E3"
  },
  {
    "uniprot": "X8F2E3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2E3"
  },
  {
    "uniprot": "X8FJ90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ90"
  },
  {
    "uniprot": "X8FNQ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNQ5"
  },
  {
    "uniprot": "X8FJ89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ89"
  },
  {
    "uniprot": "X8F065",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F065"
  },
  {
    "uniprot": "X8FIY2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIY2"
  },
  {
    "uniprot": "X8FKE8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKE8"
  },
  {
    "uniprot": "X8F4R9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4R9"
  },
  {
    "uniprot": "X8FLL9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLL9"
  },
  {
    "uniprot": "X8F929",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F929"
  },
  {
    "uniprot": "X8FID0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FID0"
  },
  {
    "uniprot": "X8F744",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F744"
  },
  {
    "uniprot": "X8FBD5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBD5"
  },
  {
    "uniprot": "X8FKT5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKT5"
  },
  {
    "uniprot": "X8FH43",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH43"
  },
  {
    "uniprot": "X8F6Q1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Q1"
  },
  {
    "uniprot": "X8F1F3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1F3"
  },
  {
    "uniprot": "X8FF17",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF17"
  },
  {
    "uniprot": "X8FKE4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKE4"
  },
  {
    "uniprot": "X8FEL2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEL2"
  },
  {
    "uniprot": "X8F8U2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8U2"
  },
  {
    "uniprot": "X8FQM8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQM8"
  },
  {
    "uniprot": "X8F3B1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3B1"
  },
  {
    "uniprot": "X8FGT0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGT0"
  },
  {
    "uniprot": "X8F234",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F234"
  },
  {
    "uniprot": "X8F752",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F752"
  },
  {
    "uniprot": "X8F7N3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7N3"
  },
  {
    "uniprot": "X8FGB1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGB1"
  },
  {
    "uniprot": "X8F6F7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6F7"
  },
  {
    "uniprot": "X8FC80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC80"
  },
  {
    "uniprot": "X8FJD7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJD7"
  },
  {
    "uniprot": "X8F216",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F216"
  },
  {
    "uniprot": "X8F0F8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0F8"
  },
  {
    "uniprot": "X8F250",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F250"
  },
  {
    "uniprot": "X8FIK8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIK8"
  },
  {
    "uniprot": "X8F4U4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4U4"
  },
  {
    "uniprot": "X8F531",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F531"
  },
  {
    "uniprot": "X8FCP3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCP3"
  },
  {
    "uniprot": "X8FCE3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCE3"
  },
  {
    "uniprot": "X8F959",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F959"
  },
  {
    "uniprot": "X8F018",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F018"
  },
  {
    "uniprot": "X8F9I5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9I5"
  },
  {
    "uniprot": "X8FIA5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIA5"
  },
  {
    "uniprot": "X8FB89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB89"
  },
  {
    "uniprot": "X8F5R2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5R2"
  },
  {
    "uniprot": "X8FFR8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFR8"
  },
  {
    "uniprot": "X8F7D8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7D8"
  },
  {
    "uniprot": "X8FGA2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGA2"
  },
  {
    "uniprot": "X8F222",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F222"
  },
  {
    "uniprot": "X8F543",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F543"
  },
  {
    "uniprot": "X8FA91",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA91"
  },
  {
    "uniprot": "X8FCC2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCC2"
  },
  {
    "uniprot": "X8FH27",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH27"
  },
  {
    "uniprot": "X8F1F5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1F5"
  },
  {
    "uniprot": "X8FNS9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNS9"
  },
  {
    "uniprot": "X8FJ92",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ92"
  },
  {
    "uniprot": "X8F2F3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2F3"
  },
  {
    "uniprot": "X8FIG8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIG8"
  },
  {
    "uniprot": "X8FBF7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBF7"
  },
  {
    "uniprot": "X8FMS3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMS3"
  },
  {
    "uniprot": "X8F247",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F247"
  },
  {
    "uniprot": "X8F3P6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3P6"
  },
  {
    "uniprot": "X8FN91",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN91"
  },
  {
    "uniprot": "X8FG80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG80"
  },
  {
    "uniprot": "X8FEF0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEF0"
  },
  {
    "uniprot": "X8FD68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD68"
  },
  {
    "uniprot": "X8F5D6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5D6"
  },
  {
    "uniprot": "X8EYK2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYK2"
  },
  {
    "uniprot": "X8FB21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB21"
  },
  {
    "uniprot": "X8EZR8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZR8"
  },
  {
    "uniprot": "X8FKZ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKZ8"
  },
  {
    "uniprot": "X8F101",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F101"
  },
  {
    "uniprot": "X8FI82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI82"
  },
  {
    "uniprot": "X8F3L5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3L5"
  },
  {
    "uniprot": "X8FMS1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMS1"
  },
  {
    "uniprot": "X8F189",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F189"
  },
  {
    "uniprot": "X8FA92",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA92"
  },
  {
    "uniprot": "X8FM63",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM63"
  },
  {
    "uniprot": "X8FKL2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKL2"
  },
  {
    "uniprot": "X8EZT6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZT6"
  },
  {
    "uniprot": "X8F9E0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9E0"
  },
  {
    "uniprot": "X8F0B8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0B8"
  },
  {
    "uniprot": "X8F062",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F062"
  },
  {
    "uniprot": "X8FE80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE80"
  },
  {
    "uniprot": "X8FJ86",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ86"
  },
  {
    "uniprot": "X8FJ28",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ28"
  },
  {
    "uniprot": "X8FBC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBC0"
  },
  {
    "uniprot": "X8FFN5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFN5"
  },
  {
    "uniprot": "X8FKC6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKC6"
  },
  {
    "uniprot": "X8F1S9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1S9"
  },
  {
    "uniprot": "X8FBX9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBX9"
  },
  {
    "uniprot": "X8FH40",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH40"
  },
  {
    "uniprot": "X8F7F4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7F4"
  },
  {
    "uniprot": "X8EY98",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY98"
  },
  {
    "uniprot": "X8FAB2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAB2"
  },
  {
    "uniprot": "X8FBY7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBY7"
  },
  {
    "uniprot": "X8FAT6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAT6"
  },
  {
    "uniprot": "X8FLM6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLM6"
  },
  {
    "uniprot": "X8F9V6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9V6"
  },
  {
    "uniprot": "X8F6K4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6K4"
  },
  {
    "uniprot": "X8FNM4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNM4"
  },
  {
    "uniprot": "X8FMF9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMF9"
  },
  {
    "uniprot": "X8FN87",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN87"
  },
  {
    "uniprot": "X8FJ04",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ04"
  },
  {
    "uniprot": "X8EZ90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ90"
  },
  {
    "uniprot": "X8F260",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F260"
  },
  {
    "uniprot": "X8F9I1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9I1"
  },
  {
    "uniprot": "X8F1Z7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Z7"
  },
  {
    "uniprot": "X8F420",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F420"
  },
  {
    "uniprot": "X8FEZ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEZ4"
  },
  {
    "uniprot": "X8FDN4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDN4"
  },
  {
    "uniprot": "X8FLP8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLP8"
  },
  {
    "uniprot": "X8FD92",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD92"
  },
  {
    "uniprot": "X8FHE6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHE6"
  },
  {
    "uniprot": "X8FB24",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB24"
  },
  {
    "uniprot": "X8FEB0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEB0"
  },
  {
    "uniprot": "X8EY46",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY46"
  },
  {
    "uniprot": "X8F8I8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8I8"
  },
  {
    "uniprot": "X8F6I3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6I3"
  },
  {
    "uniprot": "X8FDK5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDK5"
  },
  {
    "uniprot": "X8F0B5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0B5"
  },
  {
    "uniprot": "X8EZ89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ89"
  },
  {
    "uniprot": "X8F927",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F927"
  },
  {
    "uniprot": "X8FAI9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAI9"
  },
  {
    "uniprot": "X8FMP1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMP1"
  },
  {
    "uniprot": "X8FJX2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJX2"
  },
  {
    "uniprot": "X8EZU1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZU1"
  },
  {
    "uniprot": "X8FBB2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBB2"
  },
  {
    "uniprot": "X8FGT6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGT6"
  },
  {
    "uniprot": "X8F8A5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8A5"
  },
  {
    "uniprot": "X8FL99",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL99"
  },
  {
    "uniprot": "X8FH71",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH71"
  },
  {
    "uniprot": "X8FDX8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDX8"
  },
  {
    "uniprot": "X8FEU2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEU2"
  },
  {
    "uniprot": "X8F0Y9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Y9"
  },
  {
    "uniprot": "X8FKK7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKK7"
  },
  {
    "uniprot": "X8F4B0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4B0"
  },
  {
    "uniprot": "X8F6S6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6S6"
  },
  {
    "uniprot": "X8F1F0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1F0"
  },
  {
    "uniprot": "X8FCJ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCJ6"
  },
  {
    "uniprot": "X8EZ93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ93"
  },
  {
    "uniprot": "X8FET8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FET8"
  },
  {
    "uniprot": "X8F341",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F341"
  },
  {
    "uniprot": "X8FDF1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDF1"
  },
  {
    "uniprot": "X8FID7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FID7"
  },
  {
    "uniprot": "X8F5Q8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Q8"
  },
  {
    "uniprot": "X8FDC4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDC4"
  },
  {
    "uniprot": "X8F7G9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7G9"
  },
  {
    "uniprot": "X8FJH9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJH9"
  },
  {
    "uniprot": "X8FI05",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI05"
  },
  {
    "uniprot": "X8FJZ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJZ5"
  },
  {
    "uniprot": "X8FB38",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB38"
  },
  {
    "uniprot": "X8FK65",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK65"
  },
  {
    "uniprot": "X8F6U0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6U0"
  },
  {
    "uniprot": "X8EWL0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWL0"
  },
  {
    "uniprot": "X8F8U0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8U0"
  },
  {
    "uniprot": "X8F036",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F036"
  },
  {
    "uniprot": "X8F5W8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5W8"
  },
  {
    "uniprot": "X8F9Q4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Q4"
  },
  {
    "uniprot": "X8F266",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F266"
  },
  {
    "uniprot": "X8EZE4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZE4"
  },
  {
    "uniprot": "X8FHN2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHN2"
  },
  {
    "uniprot": "X8EZ14",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ14"
  },
  {
    "uniprot": "X8FMM1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMM1"
  },
  {
    "uniprot": "X8FNC1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNC1"
  },
  {
    "uniprot": "X8FHP6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHP6"
  },
  {
    "uniprot": "X8FB13",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB13"
  },
  {
    "uniprot": "X8EYC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYC0"
  },
  {
    "uniprot": "X8FDA6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDA6"
  },
  {
    "uniprot": "X8F6A4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6A4"
  },
  {
    "uniprot": "X8F257",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F257"
  },
  {
    "uniprot": "X8FK32",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK32"
  },
  {
    "uniprot": "X8F9M1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9M1"
  },
  {
    "uniprot": "X8F990",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F990"
  },
  {
    "uniprot": "X8FKA3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKA3"
  },
  {
    "uniprot": "X8FAZ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAZ1"
  },
  {
    "uniprot": "X8F0J3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0J3"
  },
  {
    "uniprot": "X8F0K2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0K2"
  },
  {
    "uniprot": "X8F9W4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9W4"
  },
  {
    "uniprot": "X8FEC3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEC3"
  },
  {
    "uniprot": "X8F4A9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4A9"
  },
  {
    "uniprot": "X8FJL4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJL4"
  },
  {
    "uniprot": "X8F8X3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8X3"
  },
  {
    "uniprot": "X8EX76",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX76"
  },
  {
    "uniprot": "X8FB15",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB15"
  },
  {
    "uniprot": "X8F217",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F217"
  },
  {
    "uniprot": "X8FG04",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG04"
  },
  {
    "uniprot": "X8F012",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F012"
  },
  {
    "uniprot": "X8FI13",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI13"
  },
  {
    "uniprot": "X8FIF9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIF9"
  },
  {
    "uniprot": "X8FB16",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB16"
  },
  {
    "uniprot": "X8F861",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F861"
  },
  {
    "uniprot": "X8FFC2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFC2"
  },
  {
    "uniprot": "X8F6X0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6X0"
  },
  {
    "uniprot": "X8F9W1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9W1"
  },
  {
    "uniprot": "X8FIH8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIH8"
  },
  {
    "uniprot": "X8F554",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F554"
  },
  {
    "uniprot": "X8EYU3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYU3"
  },
  {
    "uniprot": "X8F4T9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4T9"
  },
  {
    "uniprot": "X8FKT9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKT9"
  },
  {
    "uniprot": "X8EWC3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWC3"
  },
  {
    "uniprot": "X8FG21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG21"
  },
  {
    "uniprot": "X8FIS6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIS6"
  },
  {
    "uniprot": "X8FKT0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKT0"
  },
  {
    "uniprot": "X8F346",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F346"
  },
  {
    "uniprot": "X8F3W1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3W1"
  },
  {
    "uniprot": "X8FHE4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHE4"
  },
  {
    "uniprot": "X8F195",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F195"
  },
  {
    "uniprot": "X8FB67",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB67"
  },
  {
    "uniprot": "X8F6Y0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Y0"
  },
  {
    "uniprot": "X8F1R9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1R9"
  },
  {
    "uniprot": "X8FBZ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBZ3"
  },
  {
    "uniprot": "X8F9R1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9R1"
  },
  {
    "uniprot": "X8FP96",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP96"
  },
  {
    "uniprot": "X8F7C7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7C7"
  },
  {
    "uniprot": "X8F625",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F625"
  },
  {
    "uniprot": "X8FJV3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJV3"
  },
  {
    "uniprot": "X8EZK6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZK6"
  },
  {
    "uniprot": "X8F6X1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6X1"
  },
  {
    "uniprot": "X8F2M0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2M0"
  },
  {
    "uniprot": "X8FG52",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG52"
  },
  {
    "uniprot": "X8FPC2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPC2"
  },
  {
    "uniprot": "X8F4F7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4F7"
  },
  {
    "uniprot": "X8FLQ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLQ8"
  },
  {
    "uniprot": "X8F6T2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6T2"
  },
  {
    "uniprot": "X8F432",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F432"
  },
  {
    "uniprot": "X8FIG4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIG4"
  },
  {
    "uniprot": "X8FGF5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGF5"
  },
  {
    "uniprot": "X8F4X1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4X1"
  },
  {
    "uniprot": "X8FFT9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFT9"
  },
  {
    "uniprot": "X8F9Q5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Q5"
  },
  {
    "uniprot": "X8EYV2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYV2"
  },
  {
    "uniprot": "X8FM78",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM78"
  },
  {
    "uniprot": "X8FBE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBE2"
  },
  {
    "uniprot": "X8FKT2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKT2"
  },
  {
    "uniprot": "X8FNG8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNG8"
  },
  {
    "uniprot": "X8F181",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F181"
  },
  {
    "uniprot": "X8FBN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBN8"
  },
  {
    "uniprot": "X8FLQ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLQ2"
  },
  {
    "uniprot": "X8FCP5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCP5"
  },
  {
    "uniprot": "X8FMC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMC7"
  },
  {
    "uniprot": "X8F020",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F020"
  },
  {
    "uniprot": "X8FCB6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCB6"
  },
  {
    "uniprot": "X8FE08",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE08"
  },
  {
    "uniprot": "X8FAX2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAX2"
  },
  {
    "uniprot": "X8FKU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKU0"
  },
  {
    "uniprot": "X8F5B1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5B1"
  },
  {
    "uniprot": "X8FDA3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDA3"
  },
  {
    "uniprot": "X8FJF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJF3"
  },
  {
    "uniprot": "X8EYW7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYW7"
  },
  {
    "uniprot": "X8FCT6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCT6"
  },
  {
    "uniprot": "X8F4Y4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Y4"
  },
  {
    "uniprot": "X8FFR4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFR4"
  },
  {
    "uniprot": "X8F5B5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5B5"
  },
  {
    "uniprot": "X8F8I1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8I1"
  },
  {
    "uniprot": "X8FGR4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGR4"
  },
  {
    "uniprot": "X8FJI2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJI2"
  },
  {
    "uniprot": "X8FMI7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMI7"
  },
  {
    "uniprot": "X8F086",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F086"
  },
  {
    "uniprot": "X8F7I3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7I3"
  },
  {
    "uniprot": "X8F4Y9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Y9"
  },
  {
    "uniprot": "X8FHK1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHK1"
  },
  {
    "uniprot": "X8F590",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F590"
  },
  {
    "uniprot": "X8FFB7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFB7"
  },
  {
    "uniprot": "X8FFB4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFB4"
  },
  {
    "uniprot": "X8F161",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F161"
  },
  {
    "uniprot": "X8FAT2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAT2"
  },
  {
    "uniprot": "X8FHD1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHD1"
  },
  {
    "uniprot": "X8F1Y1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Y1"
  },
  {
    "uniprot": "X8EZ08",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ08"
  },
  {
    "uniprot": "X8EYX6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYX6"
  },
  {
    "uniprot": "X8FMY0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMY0"
  },
  {
    "uniprot": "X8FLH4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLH4"
  },
  {
    "uniprot": "X8FCY8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCY8"
  },
  {
    "uniprot": "X8FLQ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLQ9"
  },
  {
    "uniprot": "X8FE61",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE61"
  },
  {
    "uniprot": "X8EYI4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYI4"
  },
  {
    "uniprot": "X8F186",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F186"
  },
  {
    "uniprot": "X8FDX5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDX5"
  },
  {
    "uniprot": "X8FAK8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAK8"
  },
  {
    "uniprot": "X8FBP5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBP5"
  },
  {
    "uniprot": "X8F6P5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6P5"
  },
  {
    "uniprot": "X8EY38",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY38"
  },
  {
    "uniprot": "X8FC06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC06"
  },
  {
    "uniprot": "X8FNL6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNL6"
  },
  {
    "uniprot": "X8F888",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F888"
  },
  {
    "uniprot": "X8F6M7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6M7"
  },
  {
    "uniprot": "X8F7W4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7W4"
  },
  {
    "uniprot": "X8FIH9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIH9"
  },
  {
    "uniprot": "X8F5X9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5X9"
  },
  {
    "uniprot": "X8FLN2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLN2"
  },
  {
    "uniprot": "X8F1N0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1N0"
  },
  {
    "uniprot": "X8FIC1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIC1"
  },
  {
    "uniprot": "X8F819",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F819"
  },
  {
    "uniprot": "X8F1T6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1T6"
  },
  {
    "uniprot": "X8FJ23",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ23"
  },
  {
    "uniprot": "X8F4W9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4W9"
  },
  {
    "uniprot": "X8F6I8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6I8"
  },
  {
    "uniprot": "X8F9I7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9I7"
  },
  {
    "uniprot": "X8FPA8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPA8"
  },
  {
    "uniprot": "X8EXI1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXI1"
  },
  {
    "uniprot": "X8FFX0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFX0"
  },
  {
    "uniprot": "X8F5I4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5I4"
  },
  {
    "uniprot": "X8F2T0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2T0"
  },
  {
    "uniprot": "X8F2M5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2M5"
  },
  {
    "uniprot": "X8FKV1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKV1"
  },
  {
    "uniprot": "X8FDT7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDT7"
  },
  {
    "uniprot": "X8FP30",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP30"
  },
  {
    "uniprot": "X8FEX1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEX1"
  },
  {
    "uniprot": "X8F7K1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7K1"
  },
  {
    "uniprot": "X8F8F8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8F8"
  },
  {
    "uniprot": "X8FKW4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKW4"
  },
  {
    "uniprot": "X8FHW5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHW5"
  },
  {
    "uniprot": "X8F317",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F317"
  },
  {
    "uniprot": "X8FEX9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEX9"
  },
  {
    "uniprot": "X8F8Z8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Z8"
  },
  {
    "uniprot": "X8EWX0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWX0"
  },
  {
    "uniprot": "X8F7X7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7X7"
  },
  {
    "uniprot": "X8F646",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F646"
  },
  {
    "uniprot": "X8FKF9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKF9"
  },
  {
    "uniprot": "X8F0K7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0K7"
  },
  {
    "uniprot": "X8EYC4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYC4"
  },
  {
    "uniprot": "X8FHP2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHP2"
  },
  {
    "uniprot": "X8F9G2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9G2"
  },
  {
    "uniprot": "X8EYY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYY1"
  },
  {
    "uniprot": "X8FM91",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM91"
  },
  {
    "uniprot": "X8F197",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F197"
  },
  {
    "uniprot": "X8F6Z0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Z0"
  },
  {
    "uniprot": "X8FIX1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIX1"
  },
  {
    "uniprot": "X8F498",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F498"
  },
  {
    "uniprot": "X8FBU6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBU6"
  },
  {
    "uniprot": "X8F450",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F450"
  },
  {
    "uniprot": "X8FMP4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMP4"
  },
  {
    "uniprot": "X8FIR6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIR6"
  },
  {
    "uniprot": "X8EWQ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWQ9"
  },
  {
    "uniprot": "X8F3X3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3X3"
  },
  {
    "uniprot": "X8FCV9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCV9"
  },
  {
    "uniprot": "X8F296",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F296"
  },
  {
    "uniprot": "X8EYI0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYI0"
  },
  {
    "uniprot": "X8FKI6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKI6"
  },
  {
    "uniprot": "X8F7P6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7P6"
  },
  {
    "uniprot": "X8FIM8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIM8"
  },
  {
    "uniprot": "X8FJU2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJU2"
  },
  {
    "uniprot": "X8F537",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F537"
  },
  {
    "uniprot": "X8F3C4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3C4"
  },
  {
    "uniprot": "X8F330",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F330"
  },
  {
    "uniprot": "X8FBR5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBR5"
  },
  {
    "uniprot": "X8FK85",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK85"
  },
  {
    "uniprot": "X8FB88",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB88"
  },
  {
    "uniprot": "X8FJ97",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ97"
  },
  {
    "uniprot": "X8F5T9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5T9"
  },
  {
    "uniprot": "X8F547",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F547"
  },
  {
    "uniprot": "X8FJ11",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ11"
  },
  {
    "uniprot": "X8FFV9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFV9"
  },
  {
    "uniprot": "X8FLK4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLK4"
  },
  {
    "uniprot": "X8FM32",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM32"
  },
  {
    "uniprot": "X8F8A3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8A3"
  },
  {
    "uniprot": "X8FIL7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIL7"
  },
  {
    "uniprot": "X8EYI2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYI2"
  },
  {
    "uniprot": "X8F0C9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0C9"
  },
  {
    "uniprot": "X8F3M8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3M8"
  },
  {
    "uniprot": "X8F241",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F241"
  },
  {
    "uniprot": "X8FCB8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCB8"
  },
  {
    "uniprot": "X8F509",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F509"
  },
  {
    "uniprot": "X8EZX9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZX9"
  },
  {
    "uniprot": "X8FIT1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIT1"
  },
  {
    "uniprot": "X8FA87",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA87"
  },
  {
    "uniprot": "X8EZS9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZS9"
  },
  {
    "uniprot": "X8FJU3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJU3"
  },
  {
    "uniprot": "X8F7K5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7K5"
  },
  {
    "uniprot": "X8FIL6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIL6"
  },
  {
    "uniprot": "X8FDG0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDG0"
  },
  {
    "uniprot": "X8F030",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F030"
  },
  {
    "uniprot": "X8FNW6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNW6"
  },
  {
    "uniprot": "X8FD72",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD72"
  },
  {
    "uniprot": "X8FP44",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP44"
  },
  {
    "uniprot": "X8FJE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJE2"
  },
  {
    "uniprot": "X8F404",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F404"
  },
  {
    "uniprot": "X8F5U9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5U9"
  },
  {
    "uniprot": "X8F3J0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3J0"
  },
  {
    "uniprot": "X8FJW8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJW8"
  },
  {
    "uniprot": "X8F0Q7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Q7"
  },
  {
    "uniprot": "X8FE81",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE81"
  },
  {
    "uniprot": "X8FF22",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF22"
  },
  {
    "uniprot": "X8FAK3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAK3"
  },
  {
    "uniprot": "X8EW35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EW35"
  },
  {
    "uniprot": "X8FEP2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEP2"
  },
  {
    "uniprot": "X8FD81",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD81"
  },
  {
    "uniprot": "X8F6D7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6D7"
  },
  {
    "uniprot": "X8F2P2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2P2"
  },
  {
    "uniprot": "X8FGF4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGF4"
  },
  {
    "uniprot": "X8FDJ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDJ7"
  },
  {
    "uniprot": "X8F8X0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8X0"
  },
  {
    "uniprot": "X8FC76",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC76"
  },
  {
    "uniprot": "X8FNI1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNI1"
  },
  {
    "uniprot": "X8FLR4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLR4"
  },
  {
    "uniprot": "X8F666",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F666"
  },
  {
    "uniprot": "X8FCD7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCD7"
  },
  {
    "uniprot": "X8F1K4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1K4"
  },
  {
    "uniprot": "X8FED1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FED1"
  },
  {
    "uniprot": "X8FLI6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLI6"
  },
  {
    "uniprot": "X8F315",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F315"
  },
  {
    "uniprot": "X8FIW0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIW0"
  },
  {
    "uniprot": "X8FKV3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKV3"
  },
  {
    "uniprot": "X8FA82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA82"
  },
  {
    "uniprot": "X8FF05",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF05"
  },
  {
    "uniprot": "X8F1J9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1J9"
  },
  {
    "uniprot": "X8F8Q0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Q0"
  },
  {
    "uniprot": "X8FAZ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAZ9"
  },
  {
    "uniprot": "X8EYN9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYN9"
  },
  {
    "uniprot": "X8FKC2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKC2"
  },
  {
    "uniprot": "X8F5T1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5T1"
  },
  {
    "uniprot": "X8F6I6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6I6"
  },
  {
    "uniprot": "X8FLK3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLK3"
  },
  {
    "uniprot": "X8F8U1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8U1"
  },
  {
    "uniprot": "X8F510",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F510"
  },
  {
    "uniprot": "X8FDD3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDD3"
  },
  {
    "uniprot": "X8EYV3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYV3"
  },
  {
    "uniprot": "X8FHD4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHD4"
  },
  {
    "uniprot": "X8F1W4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1W4"
  },
  {
    "uniprot": "X8F0B1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0B1"
  },
  {
    "uniprot": "X8FKJ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKJ9"
  },
  {
    "uniprot": "X8FLN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLN8"
  },
  {
    "uniprot": "X8FA93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA93"
  },
  {
    "uniprot": "X8F8H6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8H6"
  },
  {
    "uniprot": "X8FJ78",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ78"
  },
  {
    "uniprot": "X8FFC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFC7"
  },
  {
    "uniprot": "X8EYF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYF3"
  },
  {
    "uniprot": "X8F899",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F899"
  },
  {
    "uniprot": "X8FAY8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAY8"
  },
  {
    "uniprot": "X8FDY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDY1"
  },
  {
    "uniprot": "X8FAN1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAN1"
  },
  {
    "uniprot": "X8FLD2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLD2"
  },
  {
    "uniprot": "X8FKU3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKU3"
  },
  {
    "uniprot": "X8FKR6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKR6"
  },
  {
    "uniprot": "X8F8B5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8B5"
  },
  {
    "uniprot": "X8FP57",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP57"
  },
  {
    "uniprot": "X8FKD9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKD9"
  },
  {
    "uniprot": "X8F9I4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9I4"
  },
  {
    "uniprot": "X8F6M9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6M9"
  },
  {
    "uniprot": "X8F2A3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2A3"
  },
  {
    "uniprot": "X8F8Z0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Z0"
  },
  {
    "uniprot": "X8F2Z7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Z7"
  },
  {
    "uniprot": "X8FJX7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJX7"
  },
  {
    "uniprot": "X8FJH3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJH3"
  },
  {
    "uniprot": "X8FD71",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD71"
  },
  {
    "uniprot": "X8FD86",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD86"
  },
  {
    "uniprot": "X8FFE8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFE8"
  },
  {
    "uniprot": "X8FC04",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC04"
  },
  {
    "uniprot": "X8FAI1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAI1"
  },
  {
    "uniprot": "X8F626",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F626"
  },
  {
    "uniprot": "X8F6M2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6M2"
  },
  {
    "uniprot": "X8F998",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F998"
  },
  {
    "uniprot": "X8F293",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F293"
  },
  {
    "uniprot": "X8FHZ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHZ9"
  },
  {
    "uniprot": "X8FGD0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGD0"
  },
  {
    "uniprot": "X8F4X0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4X0"
  },
  {
    "uniprot": "X8F5T8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5T8"
  },
  {
    "uniprot": "X8F384",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F384"
  },
  {
    "uniprot": "X8FLF9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLF9"
  },
  {
    "uniprot": "X8FDM0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDM0"
  },
  {
    "uniprot": "X8FM88",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM88"
  },
  {
    "uniprot": "X8FGC4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGC4"
  },
  {
    "uniprot": "X8FCF7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCF7"
  },
  {
    "uniprot": "X8FMJ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMJ5"
  },
  {
    "uniprot": "X8FP70",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP70"
  },
  {
    "uniprot": "X8F278",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F278"
  },
  {
    "uniprot": "X8EY37",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY37"
  },
  {
    "uniprot": "X8FK79",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK79"
  },
  {
    "uniprot": "X8F5J4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5J4"
  },
  {
    "uniprot": "X8FDB2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDB2"
  },
  {
    "uniprot": "X8FFJ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFJ1"
  },
  {
    "uniprot": "X8F4M9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4M9"
  },
  {
    "uniprot": "X8F975",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F975"
  },
  {
    "uniprot": "X8F8Z5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Z5"
  },
  {
    "uniprot": "X8F834",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F834"
  },
  {
    "uniprot": "X8FMY3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMY3"
  },
  {
    "uniprot": "X8FIE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIE2"
  },
  {
    "uniprot": "X8FK10",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK10"
  },
  {
    "uniprot": "X8F059",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F059"
  },
  {
    "uniprot": "X8F3D3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3D3"
  },
  {
    "uniprot": "X8F9T1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9T1"
  },
  {
    "uniprot": "X8FCU7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCU7"
  },
  {
    "uniprot": "X8FFY3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFY3"
  },
  {
    "uniprot": "X8F8C2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8C2"
  },
  {
    "uniprot": "X8F0E6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0E6"
  },
  {
    "uniprot": "X8F5R3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5R3"
  },
  {
    "uniprot": "X8F741",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F741"
  },
  {
    "uniprot": "X8FDU8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDU8"
  },
  {
    "uniprot": "X8FLZ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLZ0"
  },
  {
    "uniprot": "X8FL59",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL59"
  },
  {
    "uniprot": "X8F329",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F329"
  },
  {
    "uniprot": "X8FD34",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD34"
  },
  {
    "uniprot": "X8FI21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI21"
  },
  {
    "uniprot": "X8F9V2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9V2"
  },
  {
    "uniprot": "X8F4M7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4M7"
  },
  {
    "uniprot": "X8F2N6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2N6"
  },
  {
    "uniprot": "X8FKK1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKK1"
  },
  {
    "uniprot": "X8FJA1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJA1"
  },
  {
    "uniprot": "X8FN72",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN72"
  },
  {
    "uniprot": "X8FIX8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIX8"
  },
  {
    "uniprot": "X8F1T1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1T1"
  },
  {
    "uniprot": "X8FC02",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC02"
  },
  {
    "uniprot": "X8FK67",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK67"
  },
  {
    "uniprot": "X8FF02",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF02"
  },
  {
    "uniprot": "X8F0T0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0T0"
  },
  {
    "uniprot": "X8EZG9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZG9"
  },
  {
    "uniprot": "X8F6N2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6N2"
  },
  {
    "uniprot": "X8FEA3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEA3"
  },
  {
    "uniprot": "X8F1G1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1G1"
  },
  {
    "uniprot": "X8FM14",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM14"
  },
  {
    "uniprot": "X8F8H2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8H2"
  },
  {
    "uniprot": "X8F684",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F684"
  },
  {
    "uniprot": "X8F1D1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1D1"
  },
  {
    "uniprot": "X8FA05",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA05"
  },
  {
    "uniprot": "X8FE78",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE78"
  },
  {
    "uniprot": "X8FP66",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP66"
  },
  {
    "uniprot": "X8FHG5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHG5"
  },
  {
    "uniprot": "X8F366",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F366"
  },
  {
    "uniprot": "X8FJT1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJT1"
  },
  {
    "uniprot": "X8F320",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F320"
  },
  {
    "uniprot": "X8F471",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F471"
  },
  {
    "uniprot": "X8F558",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F558"
  },
  {
    "uniprot": "X8F1U2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1U2"
  },
  {
    "uniprot": "X8FCA0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCA0"
  },
  {
    "uniprot": "X8FM80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM80"
  },
  {
    "uniprot": "X8FL94",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL94"
  },
  {
    "uniprot": "X8FNW2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNW2"
  },
  {
    "uniprot": "X8FP23",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP23"
  },
  {
    "uniprot": "X8F356",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F356"
  },
  {
    "uniprot": "X8FLV2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLV2"
  },
  {
    "uniprot": "X8FD21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD21"
  },
  {
    "uniprot": "X8F5F9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5F9"
  },
  {
    "uniprot": "X8FPL7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPL7"
  },
  {
    "uniprot": "X8FPY7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPY7"
  },
  {
    "uniprot": "X8FLG8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLG8"
  },
  {
    "uniprot": "X8F2I9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2I9"
  },
  {
    "uniprot": "X8F0C1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0C1"
  },
  {
    "uniprot": "X8F9P8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9P8"
  },
  {
    "uniprot": "X8F4Z8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Z8"
  },
  {
    "uniprot": "X8FEX0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEX0"
  },
  {
    "uniprot": "X8F405",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F405"
  },
  {
    "uniprot": "X8F832",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F832"
  },
  {
    "uniprot": "X8FIC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIC7"
  },
  {
    "uniprot": "X8FMR3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMR3"
  },
  {
    "uniprot": "X8FD31",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD31"
  },
  {
    "uniprot": "X8FFX4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFX4"
  },
  {
    "uniprot": "X8FCP2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCP2"
  },
  {
    "uniprot": "X8F869",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F869"
  },
  {
    "uniprot": "X8EW62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EW62"
  },
  {
    "uniprot": "X8EZ95",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ95"
  },
  {
    "uniprot": "X8FNM0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNM0"
  },
  {
    "uniprot": "X8FBI4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBI4"
  },
  {
    "uniprot": "X8FHF8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHF8"
  },
  {
    "uniprot": "X8F7W8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7W8"
  },
  {
    "uniprot": "X8EXM9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXM9"
  },
  {
    "uniprot": "X8FHL3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHL3"
  },
  {
    "uniprot": "X8FAU3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAU3"
  },
  {
    "uniprot": "X8FME4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FME4"
  },
  {
    "uniprot": "X8F255",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F255"
  },
  {
    "uniprot": "X8F456",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F456"
  },
  {
    "uniprot": "X8F445",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F445"
  },
  {
    "uniprot": "X8FL13",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL13"
  },
  {
    "uniprot": "X8F3B3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3B3"
  },
  {
    "uniprot": "X8FNH2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNH2"
  },
  {
    "uniprot": "X8F342",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F342"
  },
  {
    "uniprot": "X8EYD5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYD5"
  },
  {
    "uniprot": "X8F9E9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9E9"
  },
  {
    "uniprot": "X8FEQ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEQ1"
  },
  {
    "uniprot": "X8F734",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F734"
  },
  {
    "uniprot": "X8FAH0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAH0"
  },
  {
    "uniprot": "X8FPK7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPK7"
  },
  {
    "uniprot": "X8F7T5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7T5"
  },
  {
    "uniprot": "X8FIY3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIY3"
  },
  {
    "uniprot": "X8FFW2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFW2"
  },
  {
    "uniprot": "X8F4A2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4A2"
  },
  {
    "uniprot": "X8FAE4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAE4"
  },
  {
    "uniprot": "X8FM48",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM48"
  },
  {
    "uniprot": "X8FHX6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHX6"
  },
  {
    "uniprot": "X8F6J1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6J1"
  },
  {
    "uniprot": "X8FMX7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMX7"
  },
  {
    "uniprot": "X8FAB8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAB8"
  },
  {
    "uniprot": "X8F9L0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9L0"
  },
  {
    "uniprot": "X8F8J6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8J6"
  },
  {
    "uniprot": "X8F6I9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6I9"
  },
  {
    "uniprot": "X8F2U7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2U7"
  },
  {
    "uniprot": "X8F4V2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4V2"
  },
  {
    "uniprot": "X8F2U4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2U4"
  },
  {
    "uniprot": "X8F7X8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7X8"
  },
  {
    "uniprot": "X8F518",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F518"
  },
  {
    "uniprot": "X8FPB8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPB8"
  },
  {
    "uniprot": "X8F1H6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1H6"
  },
  {
    "uniprot": "X8FBM7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBM7"
  },
  {
    "uniprot": "X8FCP7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCP7"
  },
  {
    "uniprot": "X8FFH1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFH1"
  },
  {
    "uniprot": "X8FGM4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGM4"
  },
  {
    "uniprot": "X8FEJ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEJ8"
  },
  {
    "uniprot": "X8F8E7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8E7"
  },
  {
    "uniprot": "X8EZ25",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ25"
  },
  {
    "uniprot": "X8FCT2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCT2"
  },
  {
    "uniprot": "X8F769",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F769"
  },
  {
    "uniprot": "X8FGU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGU0"
  },
  {
    "uniprot": "X8F6M6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6M6"
  },
  {
    "uniprot": "X8F7U0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7U0"
  },
  {
    "uniprot": "X8F966",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F966"
  },
  {
    "uniprot": "X8EXP3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXP3"
  },
  {
    "uniprot": "X8EZJ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZJ4"
  },
  {
    "uniprot": "X8FPH6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPH6"
  },
  {
    "uniprot": "X8FAU4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAU4"
  },
  {
    "uniprot": "X8FHG4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHG4"
  },
  {
    "uniprot": "X8F5B9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5B9"
  },
  {
    "uniprot": "X8FFK5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFK5"
  },
  {
    "uniprot": "X8F6B5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6B5"
  },
  {
    "uniprot": "X8F970",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F970"
  },
  {
    "uniprot": "X8FDH6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDH6"
  },
  {
    "uniprot": "X8FCA8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCA8"
  },
  {
    "uniprot": "X8FNW0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNW0"
  },
  {
    "uniprot": "X8F1M4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1M4"
  },
  {
    "uniprot": "X8EZR5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZR5"
  },
  {
    "uniprot": "X8FHG9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHG9"
  },
  {
    "uniprot": "X8F570",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F570"
  },
  {
    "uniprot": "X8FJ66",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ66"
  },
  {
    "uniprot": "X8F3W8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3W8"
  },
  {
    "uniprot": "X8F4N2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4N2"
  },
  {
    "uniprot": "X8F0J2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0J2"
  },
  {
    "uniprot": "X8FJP1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJP1"
  },
  {
    "uniprot": "X8FHM7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHM7"
  },
  {
    "uniprot": "X8FD14",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD14"
  },
  {
    "uniprot": "X8F0N5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0N5"
  },
  {
    "uniprot": "X8FNM3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNM3"
  },
  {
    "uniprot": "X8F1Y0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Y0"
  },
  {
    "uniprot": "X8F922",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F922"
  },
  {
    "uniprot": "X8FE59",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE59"
  },
  {
    "uniprot": "X8F3X0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3X0"
  },
  {
    "uniprot": "X8F1X8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1X8"
  },
  {
    "uniprot": "X8FK64",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK64"
  },
  {
    "uniprot": "X8FAV4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAV4"
  },
  {
    "uniprot": "X8F650",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F650"
  },
  {
    "uniprot": "X8FIG1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIG1"
  },
  {
    "uniprot": "X8FIJ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIJ3"
  },
  {
    "uniprot": "X8FP51",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP51"
  },
  {
    "uniprot": "X8FA38",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA38"
  },
  {
    "uniprot": "X8EYZ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYZ1"
  },
  {
    "uniprot": "X8EWW2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWW2"
  },
  {
    "uniprot": "X8FFU1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFU1"
  },
  {
    "uniprot": "X8F4K0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4K0"
  },
  {
    "uniprot": "X8FIJ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIJ5"
  },
  {
    "uniprot": "X8FFX2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFX2"
  },
  {
    "uniprot": "X8FFP0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFP0"
  },
  {
    "uniprot": "X8FD63",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD63"
  },
  {
    "uniprot": "X8FJS6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJS6"
  },
  {
    "uniprot": "X8FLC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLC7"
  },
  {
    "uniprot": "X8FEU6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEU6"
  },
  {
    "uniprot": "X8F739",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F739"
  },
  {
    "uniprot": "X8FHC2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHC2"
  },
  {
    "uniprot": "X8EWZ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWZ1"
  },
  {
    "uniprot": "X8F081",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F081"
  },
  {
    "uniprot": "X8F873",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F873"
  },
  {
    "uniprot": "X8EZI9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZI9"
  },
  {
    "uniprot": "X8F494",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F494"
  },
  {
    "uniprot": "X8F5U3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5U3"
  },
  {
    "uniprot": "X8F7N4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7N4"
  },
  {
    "uniprot": "X8FGP6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGP6"
  },
  {
    "uniprot": "X8FK04",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK04"
  },
  {
    "uniprot": "X8F3P8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3P8"
  },
  {
    "uniprot": "X8F334",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F334"
  },
  {
    "uniprot": "X8FAV5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAV5"
  },
  {
    "uniprot": "X8F5I0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5I0"
  },
  {
    "uniprot": "X8F7L6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7L6"
  },
  {
    "uniprot": "X8FDI0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDI0"
  },
  {
    "uniprot": "X8F5C6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5C6"
  },
  {
    "uniprot": "X8F7E3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7E3"
  },
  {
    "uniprot": "X8FC99",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC99"
  },
  {
    "uniprot": "X8FL74",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL74"
  },
  {
    "uniprot": "X8FCN2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCN2"
  },
  {
    "uniprot": "X8FGR9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGR9"
  },
  {
    "uniprot": "X8FCZ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCZ4"
  },
  {
    "uniprot": "X8FK49",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK49"
  },
  {
    "uniprot": "X8F9J4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9J4"
  },
  {
    "uniprot": "X8EZ18",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ18"
  },
  {
    "uniprot": "X8FJJ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJJ7"
  },
  {
    "uniprot": "X8FF99",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF99"
  },
  {
    "uniprot": "X8FLY4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLY4"
  },
  {
    "uniprot": "X8F0Y1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Y1"
  },
  {
    "uniprot": "X8FD42",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD42"
  },
  {
    "uniprot": "X8F2F8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2F8"
  },
  {
    "uniprot": "X8FB52",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB52"
  },
  {
    "uniprot": "X8F126",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F126"
  },
  {
    "uniprot": "X8F1J2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1J2"
  },
  {
    "uniprot": "X8EX11",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX11"
  },
  {
    "uniprot": "X8F253",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F253"
  },
  {
    "uniprot": "X8EZQ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZQ4"
  },
  {
    "uniprot": "X8F218",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F218"
  },
  {
    "uniprot": "X8F579",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F579"
  },
  {
    "uniprot": "X8FG81",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG81"
  },
  {
    "uniprot": "X8F277",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F277"
  },
  {
    "uniprot": "X8EZY3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZY3"
  },
  {
    "uniprot": "X8F3U5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3U5"
  },
  {
    "uniprot": "X8F0L6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0L6"
  },
  {
    "uniprot": "X8F4Z3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Z3"
  },
  {
    "uniprot": "X8FIU8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIU8"
  },
  {
    "uniprot": "X8F6C4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6C4"
  },
  {
    "uniprot": "X8F148",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F148"
  },
  {
    "uniprot": "X8F0M0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0M0"
  },
  {
    "uniprot": "X8FLF0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLF0"
  },
  {
    "uniprot": "X8EZ73",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ73"
  },
  {
    "uniprot": "X8FK72",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK72"
  },
  {
    "uniprot": "X8F9C9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9C9"
  },
  {
    "uniprot": "X8F9E2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9E2"
  },
  {
    "uniprot": "X8F6A7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6A7"
  },
  {
    "uniprot": "X8F572",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F572"
  },
  {
    "uniprot": "X8FAC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAC8"
  },
  {
    "uniprot": "X8FDF5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDF5"
  },
  {
    "uniprot": "X8F5F3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5F3"
  },
  {
    "uniprot": "X8FDP8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDP8"
  },
  {
    "uniprot": "X8FNE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNE2"
  },
  {
    "uniprot": "X8FGH7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGH7"
  },
  {
    "uniprot": "X8FB91",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB91"
  },
  {
    "uniprot": "X8FIP7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIP7"
  },
  {
    "uniprot": "X8F958",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F958"
  },
  {
    "uniprot": "X8F4F3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4F3"
  },
  {
    "uniprot": "X8F8W8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8W8"
  },
  {
    "uniprot": "X8FDU9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDU9"
  },
  {
    "uniprot": "X8EX35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX35"
  },
  {
    "uniprot": "X8FEL5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEL5"
  },
  {
    "uniprot": "X8F6L5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6L5"
  },
  {
    "uniprot": "X8F7E5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7E5"
  },
  {
    "uniprot": "X8FKB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKB3"
  },
  {
    "uniprot": "X8FHA8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHA8"
  },
  {
    "uniprot": "X8F4S9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4S9"
  },
  {
    "uniprot": "X8EY32",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY32"
  },
  {
    "uniprot": "X8FMC9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMC9"
  },
  {
    "uniprot": "X8FFW8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFW8"
  },
  {
    "uniprot": "X8FGP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGP9"
  },
  {
    "uniprot": "X8F406",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F406"
  },
  {
    "uniprot": "X8FG95",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG95"
  },
  {
    "uniprot": "X8FDR7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDR7"
  },
  {
    "uniprot": "X8F0G1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0G1"
  },
  {
    "uniprot": "X8F451",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F451"
  },
  {
    "uniprot": "X8FIV7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIV7"
  },
  {
    "uniprot": "X8FBF8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBF8"
  },
  {
    "uniprot": "X8F9A4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9A4"
  },
  {
    "uniprot": "X8F506",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F506"
  },
  {
    "uniprot": "X8F2P9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2P9"
  },
  {
    "uniprot": "X8F771",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F771"
  },
  {
    "uniprot": "X8FCS2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCS2"
  },
  {
    "uniprot": "X8F4G9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4G9"
  },
  {
    "uniprot": "X8FIS2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIS2"
  },
  {
    "uniprot": "X8FFW4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFW4"
  },
  {
    "uniprot": "X8FJ67",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ67"
  },
  {
    "uniprot": "X8FFL7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFL7"
  },
  {
    "uniprot": "X8FCD2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCD2"
  },
  {
    "uniprot": "X8EY11",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY11"
  },
  {
    "uniprot": "X8FHG2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHG2"
  },
  {
    "uniprot": "X8FJF2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJF2"
  },
  {
    "uniprot": "X8FA69",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA69"
  },
  {
    "uniprot": "X8FMF4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMF4"
  },
  {
    "uniprot": "X8F1F8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1F8"
  },
  {
    "uniprot": "X8F7Y7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Y7"
  },
  {
    "uniprot": "X8FBW3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBW3"
  },
  {
    "uniprot": "X8F3V3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3V3"
  },
  {
    "uniprot": "X8FE36",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE36"
  },
  {
    "uniprot": "X8F7Q1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Q1"
  },
  {
    "uniprot": "X8F854",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F854"
  },
  {
    "uniprot": "X8FMU6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMU6"
  },
  {
    "uniprot": "X8FJI9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJI9"
  },
  {
    "uniprot": "X8F4D3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4D3"
  },
  {
    "uniprot": "X8FLY3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLY3"
  },
  {
    "uniprot": "X8F6V5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6V5"
  },
  {
    "uniprot": "X8F5W5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5W5"
  },
  {
    "uniprot": "X8FKV4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKV4"
  },
  {
    "uniprot": "X8FIV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIV0"
  },
  {
    "uniprot": "X8FKZ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKZ5"
  },
  {
    "uniprot": "X8EYU9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYU9"
  },
  {
    "uniprot": "X8F6H9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6H9"
  },
  {
    "uniprot": "X8F5X5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5X5"
  },
  {
    "uniprot": "X8F8J1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8J1"
  },
  {
    "uniprot": "X8EZI5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZI5"
  },
  {
    "uniprot": "X8FHD7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHD7"
  },
  {
    "uniprot": "X8FKR4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKR4"
  },
  {
    "uniprot": "X8F4E7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4E7"
  },
  {
    "uniprot": "X8FKX7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKX7"
  },
  {
    "uniprot": "X8FFE5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFE5"
  },
  {
    "uniprot": "X8FDS9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDS9"
  },
  {
    "uniprot": "X8FA61",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA61"
  },
  {
    "uniprot": "X8FAD4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAD4"
  },
  {
    "uniprot": "X8FPL6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPL6"
  },
  {
    "uniprot": "X8FCN7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCN7"
  },
  {
    "uniprot": "X8FHF1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHF1"
  },
  {
    "uniprot": "X8FKN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKN8"
  },
  {
    "uniprot": "X8F6W0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6W0"
  },
  {
    "uniprot": "X8EZC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZC8"
  },
  {
    "uniprot": "X8F863",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F863"
  },
  {
    "uniprot": "X8F4J1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4J1"
  },
  {
    "uniprot": "X8F907",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F907"
  },
  {
    "uniprot": "X8FFH8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFH8"
  },
  {
    "uniprot": "X8FJY3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJY3"
  },
  {
    "uniprot": "X8EZ26",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ26"
  },
  {
    "uniprot": "X8FNJ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNJ0"
  },
  {
    "uniprot": "X8FKS9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKS9"
  },
  {
    "uniprot": "X8F2B3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2B3"
  },
  {
    "uniprot": "X8F8V4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8V4"
  },
  {
    "uniprot": "X8F303",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F303"
  },
  {
    "uniprot": "X8F574",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F574"
  },
  {
    "uniprot": "X8FAN2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAN2"
  },
  {
    "uniprot": "X8FG50",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG50"
  },
  {
    "uniprot": "X8FDX0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDX0"
  },
  {
    "uniprot": "X8FAW2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAW2"
  },
  {
    "uniprot": "X8F200",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F200"
  },
  {
    "uniprot": "X8EX26",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX26"
  },
  {
    "uniprot": "X8EZ62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ62"
  },
  {
    "uniprot": "X8F0A3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0A3"
  },
  {
    "uniprot": "X8F8Y6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Y6"
  },
  {
    "uniprot": "X8F0B3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0B3"
  },
  {
    "uniprot": "X8FM04",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM04"
  },
  {
    "uniprot": "X8F3P4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3P4"
  },
  {
    "uniprot": "X8FH64",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH64"
  },
  {
    "uniprot": "X8F466",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F466"
  },
  {
    "uniprot": "X8FKE1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKE1"
  },
  {
    "uniprot": "X8FP89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP89"
  },
  {
    "uniprot": "X8F108",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F108"
  },
  {
    "uniprot": "X8FEZ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEZ9"
  },
  {
    "uniprot": "X8F0P9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0P9"
  },
  {
    "uniprot": "X8F4J3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4J3"
  },
  {
    "uniprot": "X8F0J6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0J6"
  },
  {
    "uniprot": "X8FMG4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMG4"
  },
  {
    "uniprot": "X8FLJ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLJ3"
  },
  {
    "uniprot": "X8FK77",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK77"
  },
  {
    "uniprot": "X8FI06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI06"
  },
  {
    "uniprot": "X8FKL8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKL8"
  },
  {
    "uniprot": "X8FBZ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBZ8"
  },
  {
    "uniprot": "X8EXU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXU0"
  },
  {
    "uniprot": "X8EZT5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZT5"
  },
  {
    "uniprot": "X8FFZ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFZ3"
  },
  {
    "uniprot": "X8FIH0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIH0"
  },
  {
    "uniprot": "X8FLS1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLS1"
  },
  {
    "uniprot": "X8FBJ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBJ6"
  },
  {
    "uniprot": "X8EX13",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX13"
  },
  {
    "uniprot": "X8FDK6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDK6"
  },
  {
    "uniprot": "X8FQL2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQL2"
  },
  {
    "uniprot": "X8F6Y3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Y3"
  },
  {
    "uniprot": "X8FEU1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEU1"
  },
  {
    "uniprot": "X8FEX2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEX2"
  },
  {
    "uniprot": "X8F6G9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6G9"
  },
  {
    "uniprot": "X8F5I1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5I1"
  },
  {
    "uniprot": "X8F1R8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1R8"
  },
  {
    "uniprot": "X8FMF6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMF6"
  },
  {
    "uniprot": "X8F000",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F000"
  },
  {
    "uniprot": "X8FDQ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDQ3"
  },
  {
    "uniprot": "X8F7B9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7B9"
  },
  {
    "uniprot": "X8F8Y0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Y0"
  },
  {
    "uniprot": "X8F7B5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7B5"
  },
  {
    "uniprot": "X8F2Q8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Q8"
  },
  {
    "uniprot": "X8F2E4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2E4"
  },
  {
    "uniprot": "X8F765",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F765"
  },
  {
    "uniprot": "X8F2U6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2U6"
  },
  {
    "uniprot": "X8EYW5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYW5"
  },
  {
    "uniprot": "X8F8G9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8G9"
  },
  {
    "uniprot": "X8FAF6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAF6"
  },
  {
    "uniprot": "X8FBA8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBA8"
  },
  {
    "uniprot": "X8FBT3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBT3"
  },
  {
    "uniprot": "X8FG51",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG51"
  },
  {
    "uniprot": "X8FBP3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBP3"
  },
  {
    "uniprot": "X8FIH1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIH1"
  },
  {
    "uniprot": "X8FGA7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGA7"
  },
  {
    "uniprot": "X8F7T1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7T1"
  },
  {
    "uniprot": "X8EZ46",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ46"
  },
  {
    "uniprot": "X8F695",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F695"
  },
  {
    "uniprot": "X8F3T9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3T9"
  },
  {
    "uniprot": "X8FIQ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIQ0"
  },
  {
    "uniprot": "X8F8V9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8V9"
  },
  {
    "uniprot": "X8FL00",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL00"
  },
  {
    "uniprot": "X8FFE3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFE3"
  },
  {
    "uniprot": "X8FPL3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPL3"
  },
  {
    "uniprot": "X8FH16",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH16"
  },
  {
    "uniprot": "X8FPV9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPV9"
  },
  {
    "uniprot": "X8FMP7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMP7"
  },
  {
    "uniprot": "X8F9U4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9U4"
  },
  {
    "uniprot": "X8EZ21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ21"
  },
  {
    "uniprot": "X8FCL7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCL7"
  },
  {
    "uniprot": "X8F274",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F274"
  },
  {
    "uniprot": "X8F8L8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8L8"
  },
  {
    "uniprot": "X8FLE3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLE3"
  },
  {
    "uniprot": "X8F353",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F353"
  },
  {
    "uniprot": "X8FC30",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC30"
  },
  {
    "uniprot": "X8FGA4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGA4"
  },
  {
    "uniprot": "X8FLL8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLL8"
  },
  {
    "uniprot": "X8F6X8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6X8"
  },
  {
    "uniprot": "X8FIA9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIA9"
  },
  {
    "uniprot": "X8EZ07",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ07"
  },
  {
    "uniprot": "X8FIA6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIA6"
  },
  {
    "uniprot": "X8FGS3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGS3"
  },
  {
    "uniprot": "X8F6A2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6A2"
  },
  {
    "uniprot": "X8F004",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F004"
  },
  {
    "uniprot": "X8FJ08",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ08"
  },
  {
    "uniprot": "X8FCJ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCJ1"
  },
  {
    "uniprot": "X8F542",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F542"
  },
  {
    "uniprot": "X8FLV7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLV7"
  },
  {
    "uniprot": "X8FLL3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLL3"
  },
  {
    "uniprot": "X8FL81",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL81"
  },
  {
    "uniprot": "X8FES2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FES2"
  },
  {
    "uniprot": "X8FI48",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI48"
  },
  {
    "uniprot": "X8F2L0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2L0"
  },
  {
    "uniprot": "X8F4B8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4B8"
  },
  {
    "uniprot": "X8F6Z6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Z6"
  },
  {
    "uniprot": "X8FHW8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHW8"
  },
  {
    "uniprot": "X8FED3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FED3"
  },
  {
    "uniprot": "X8FA15",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA15"
  },
  {
    "uniprot": "X8F6Z7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Z7"
  },
  {
    "uniprot": "X8FCM9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCM9"
  },
  {
    "uniprot": "X8F8D3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8D3"
  },
  {
    "uniprot": "X8FG39",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG39"
  },
  {
    "uniprot": "X8FEW1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEW1"
  },
  {
    "uniprot": "X8FDJ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDJ8"
  },
  {
    "uniprot": "X8FD25",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD25"
  },
  {
    "uniprot": "X8F0X2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0X2"
  },
  {
    "uniprot": "X8FEA1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEA1"
  },
  {
    "uniprot": "X8FDD2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDD2"
  },
  {
    "uniprot": "X8FEL0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEL0"
  },
  {
    "uniprot": "X8F528",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F528"
  },
  {
    "uniprot": "X8EZK3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZK3"
  },
  {
    "uniprot": "X8FNQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNQ6"
  },
  {
    "uniprot": "X8F6Y4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Y4"
  },
  {
    "uniprot": "X8F387",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F387"
  },
  {
    "uniprot": "X8FLV3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLV3"
  },
  {
    "uniprot": "X8F9Q0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Q0"
  },
  {
    "uniprot": "X8F0K5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0K5"
  },
  {
    "uniprot": "X8FJQ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJQ8"
  },
  {
    "uniprot": "X8FKW5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKW5"
  },
  {
    "uniprot": "X8F256",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F256"
  },
  {
    "uniprot": "X8F6R7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6R7"
  },
  {
    "uniprot": "X8FGJ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGJ3"
  },
  {
    "uniprot": "X8FKW6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKW6"
  },
  {
    "uniprot": "X8FFR3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFR3"
  },
  {
    "uniprot": "X8FJ46",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ46"
  },
  {
    "uniprot": "X8FM50",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM50"
  },
  {
    "uniprot": "X8FCW7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCW7"
  },
  {
    "uniprot": "X8FBQ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBQ4"
  },
  {
    "uniprot": "X8F7I8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7I8"
  },
  {
    "uniprot": "X8FDI5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDI5"
  },
  {
    "uniprot": "X8F0Y2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Y2"
  },
  {
    "uniprot": "X8F6J8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6J8"
  },
  {
    "uniprot": "X8FEZ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEZ8"
  },
  {
    "uniprot": "X8FCC1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCC1"
  },
  {
    "uniprot": "X8FCH4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCH4"
  },
  {
    "uniprot": "X8FF85",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF85"
  },
  {
    "uniprot": "X8F6V8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6V8"
  },
  {
    "uniprot": "X8FMS5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMS5"
  },
  {
    "uniprot": "X8FM45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM45"
  },
  {
    "uniprot": "X8F116",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F116"
  },
  {
    "uniprot": "X8EXD2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXD2"
  },
  {
    "uniprot": "X8F4M2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4M2"
  },
  {
    "uniprot": "X8EZK0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZK0"
  },
  {
    "uniprot": "X8F129",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F129"
  },
  {
    "uniprot": "X8FP45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP45"
  },
  {
    "uniprot": "X8F1U3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1U3"
  },
  {
    "uniprot": "X8FPE4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPE4"
  },
  {
    "uniprot": "X8F046",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F046"
  },
  {
    "uniprot": "X8FNT1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNT1"
  },
  {
    "uniprot": "X8FMC2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMC2"
  },
  {
    "uniprot": "X8FBU9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBU9"
  },
  {
    "uniprot": "X8FLZ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLZ5"
  },
  {
    "uniprot": "X8FPD5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPD5"
  },
  {
    "uniprot": "X8FKY6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKY6"
  },
  {
    "uniprot": "X8FMN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMN8"
  },
  {
    "uniprot": "X8FCC9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCC9"
  },
  {
    "uniprot": "X8EZX2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZX2"
  },
  {
    "uniprot": "X8F501",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F501"
  },
  {
    "uniprot": "X8FFK8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFK8"
  },
  {
    "uniprot": "X8F183",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F183"
  },
  {
    "uniprot": "X8FHC4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHC4"
  },
  {
    "uniprot": "X8FK28",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK28"
  },
  {
    "uniprot": "X8FLA8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLA8"
  },
  {
    "uniprot": "X8F0Q4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Q4"
  },
  {
    "uniprot": "X8EZ04",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ04"
  },
  {
    "uniprot": "X8F2X3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2X3"
  },
  {
    "uniprot": "X8FMY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMY9"
  },
  {
    "uniprot": "X8F6F9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6F9"
  },
  {
    "uniprot": "X8FBR8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBR8"
  },
  {
    "uniprot": "X8FAU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAU0"
  },
  {
    "uniprot": "X8FE99",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE99"
  },
  {
    "uniprot": "X8F8T7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8T7"
  },
  {
    "uniprot": "X8FIW5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIW5"
  },
  {
    "uniprot": "X8FCE9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCE9"
  },
  {
    "uniprot": "X8FF19",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF19"
  },
  {
    "uniprot": "X8F4M6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4M6"
  },
  {
    "uniprot": "X8F3M9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3M9"
  },
  {
    "uniprot": "X8FCY3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCY3"
  },
  {
    "uniprot": "X8F024",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F024"
  },
  {
    "uniprot": "X8EW31",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EW31"
  },
  {
    "uniprot": "X8F3X2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3X2"
  },
  {
    "uniprot": "X8FGR6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGR6"
  },
  {
    "uniprot": "X8FFN7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFN7"
  },
  {
    "uniprot": "X8FK75",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK75"
  },
  {
    "uniprot": "X8EZK5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZK5"
  },
  {
    "uniprot": "X8FMU4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMU4"
  },
  {
    "uniprot": "X8F112",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F112"
  },
  {
    "uniprot": "X8FKL0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKL0"
  },
  {
    "uniprot": "X8FDY0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDY0"
  },
  {
    "uniprot": "X8F9M4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9M4"
  },
  {
    "uniprot": "X8FB35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB35"
  },
  {
    "uniprot": "X8F1Z9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Z9"
  },
  {
    "uniprot": "X8F976",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F976"
  },
  {
    "uniprot": "X8FKW3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKW3"
  },
  {
    "uniprot": "X8F3R9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3R9"
  },
  {
    "uniprot": "X8F940",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F940"
  },
  {
    "uniprot": "X8FC45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC45"
  },
  {
    "uniprot": "X8FDX7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDX7"
  },
  {
    "uniprot": "X8F2Y3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Y3"
  },
  {
    "uniprot": "X8F0I1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0I1"
  },
  {
    "uniprot": "X8F9R0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9R0"
  },
  {
    "uniprot": "X8FGR5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGR5"
  },
  {
    "uniprot": "X8EXS3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXS3"
  },
  {
    "uniprot": "X8FJG6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJG6"
  },
  {
    "uniprot": "X8FPU2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPU2"
  },
  {
    "uniprot": "X8F8D2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8D2"
  },
  {
    "uniprot": "X8FM62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM62"
  },
  {
    "uniprot": "X8FB66",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB66"
  },
  {
    "uniprot": "X8FHP0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHP0"
  },
  {
    "uniprot": "X8FP19",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP19"
  },
  {
    "uniprot": "X8FEE4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEE4"
  },
  {
    "uniprot": "X8F8B1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8B1"
  },
  {
    "uniprot": "X8F482",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F482"
  },
  {
    "uniprot": "X8EZH9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZH9"
  },
  {
    "uniprot": "X8FL98",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL98"
  },
  {
    "uniprot": "X8FIK5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIK5"
  },
  {
    "uniprot": "X8FK25",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK25"
  },
  {
    "uniprot": "X8FBZ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBZ4"
  },
  {
    "uniprot": "X8FJA8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJA8"
  },
  {
    "uniprot": "X8F8H5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8H5"
  },
  {
    "uniprot": "X8FBQ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBQ3"
  },
  {
    "uniprot": "X8FCG7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCG7"
  },
  {
    "uniprot": "X8EZ05",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ05"
  },
  {
    "uniprot": "X8FA76",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA76"
  },
  {
    "uniprot": "X8F0S3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0S3"
  },
  {
    "uniprot": "X8F047",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F047"
  },
  {
    "uniprot": "X8FB99",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB99"
  },
  {
    "uniprot": "X8FIX6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIX6"
  },
  {
    "uniprot": "X8FGN1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGN1"
  },
  {
    "uniprot": "X8FAP0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAP0"
  },
  {
    "uniprot": "X8F5K8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5K8"
  },
  {
    "uniprot": "X8FMP2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMP2"
  },
  {
    "uniprot": "X8F0W4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0W4"
  },
  {
    "uniprot": "X8FD44",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD44"
  },
  {
    "uniprot": "X8F607",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F607"
  },
  {
    "uniprot": "X8FJI6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJI6"
  },
  {
    "uniprot": "X8F8R4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8R4"
  },
  {
    "uniprot": "X8FGE6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGE6"
  },
  {
    "uniprot": "X8FKW9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKW9"
  },
  {
    "uniprot": "X8FD10",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD10"
  },
  {
    "uniprot": "X8FC18",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC18"
  },
  {
    "uniprot": "X8FCV6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCV6"
  },
  {
    "uniprot": "X8FEG1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEG1"
  },
  {
    "uniprot": "X8F4C9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4C9"
  },
  {
    "uniprot": "X8F8F4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8F4"
  },
  {
    "uniprot": "X8FEG2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEG2"
  },
  {
    "uniprot": "X8FCR0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCR0"
  },
  {
    "uniprot": "X8FIJ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIJ9"
  },
  {
    "uniprot": "X8FI00",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI00"
  },
  {
    "uniprot": "X8FAC6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAC6"
  },
  {
    "uniprot": "X8FEK9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEK9"
  },
  {
    "uniprot": "X8F9J7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9J7"
  },
  {
    "uniprot": "X8F5V6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5V6"
  },
  {
    "uniprot": "X8FB83",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB83"
  },
  {
    "uniprot": "X8FI90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI90"
  },
  {
    "uniprot": "X8F747",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F747"
  },
  {
    "uniprot": "X8FKB0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKB0"
  },
  {
    "uniprot": "X8FHC1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHC1"
  },
  {
    "uniprot": "X8FAV6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAV6"
  },
  {
    "uniprot": "X8FB19",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB19"
  },
  {
    "uniprot": "X8FN57",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN57"
  },
  {
    "uniprot": "X8EXL9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXL9"
  },
  {
    "uniprot": "X8FEN4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEN4"
  },
  {
    "uniprot": "X8FA65",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA65"
  },
  {
    "uniprot": "X8FG30",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG30"
  },
  {
    "uniprot": "X8FJT3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJT3"
  },
  {
    "uniprot": "X8F0V8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0V8"
  },
  {
    "uniprot": "X8F5L2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5L2"
  },
  {
    "uniprot": "X8FBC2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBC2"
  },
  {
    "uniprot": "X8EZ03",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ03"
  },
  {
    "uniprot": "X8EZM2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZM2"
  },
  {
    "uniprot": "X8FFM4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFM4"
  },
  {
    "uniprot": "X8FG18",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG18"
  },
  {
    "uniprot": "X8FKZ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKZ3"
  },
  {
    "uniprot": "X8F4X9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4X9"
  },
  {
    "uniprot": "X8FA07",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA07"
  },
  {
    "uniprot": "X8FM38",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM38"
  },
  {
    "uniprot": "X8FQF0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQF0"
  },
  {
    "uniprot": "X8FJW4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJW4"
  },
  {
    "uniprot": "X8FGJ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGJ1"
  },
  {
    "uniprot": "X8FK21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK21"
  },
  {
    "uniprot": "X8FN98",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN98"
  },
  {
    "uniprot": "X8FI11",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI11"
  },
  {
    "uniprot": "X8EWR7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWR7"
  },
  {
    "uniprot": "X8EZW5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZW5"
  },
  {
    "uniprot": "X8EXU8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXU8"
  },
  {
    "uniprot": "X8F1C4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1C4"
  },
  {
    "uniprot": "X8EZX7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZX7"
  },
  {
    "uniprot": "X8FJC4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJC4"
  },
  {
    "uniprot": "X8F1F6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1F6"
  },
  {
    "uniprot": "X8FB31",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB31"
  },
  {
    "uniprot": "X8F8F3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8F3"
  },
  {
    "uniprot": "X8F4M4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4M4"
  },
  {
    "uniprot": "X8FD47",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD47"
  },
  {
    "uniprot": "X8F836",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F836"
  },
  {
    "uniprot": "X8F1I9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1I9"
  },
  {
    "uniprot": "X8EZT3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZT3"
  },
  {
    "uniprot": "X8FBN1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBN1"
  },
  {
    "uniprot": "X8F120",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F120"
  },
  {
    "uniprot": "X8FEX8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEX8"
  },
  {
    "uniprot": "X8FM60",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM60"
  },
  {
    "uniprot": "X8FQ68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQ68"
  },
  {
    "uniprot": "X8FEH1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEH1"
  },
  {
    "uniprot": "X8FPF2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPF2"
  },
  {
    "uniprot": "X8FBT8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBT8"
  },
  {
    "uniprot": "X8F292",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F292"
  },
  {
    "uniprot": "X8EZP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZP9"
  },
  {
    "uniprot": "X8F5J2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5J2"
  },
  {
    "uniprot": "X8F697",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F697"
  },
  {
    "uniprot": "X8FLI9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLI9"
  },
  {
    "uniprot": "X8FBC3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBC3"
  },
  {
    "uniprot": "X8FI93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI93"
  },
  {
    "uniprot": "X8FN17",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN17"
  },
  {
    "uniprot": "X8F3Q2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Q2"
  },
  {
    "uniprot": "X8EYN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYN8"
  },
  {
    "uniprot": "X8FKM8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKM8"
  },
  {
    "uniprot": "X8FBV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBV0"
  },
  {
    "uniprot": "X8FLA4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLA4"
  },
  {
    "uniprot": "X8F271",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F271"
  },
  {
    "uniprot": "X8FLP6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLP6"
  },
  {
    "uniprot": "X8F9B9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9B9"
  },
  {
    "uniprot": "X8FN45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN45"
  },
  {
    "uniprot": "X8F6J4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6J4"
  },
  {
    "uniprot": "X8FM59",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM59"
  },
  {
    "uniprot": "X8FHD9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHD9"
  },
  {
    "uniprot": "X8F351",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F351"
  },
  {
    "uniprot": "X8F4G7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4G7"
  },
  {
    "uniprot": "X8F4X5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4X5"
  },
  {
    "uniprot": "X8F7R4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7R4"
  },
  {
    "uniprot": "X8F133",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F133"
  },
  {
    "uniprot": "X8FEU5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEU5"
  },
  {
    "uniprot": "X8FGG9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGG9"
  },
  {
    "uniprot": "X8FDB8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDB8"
  },
  {
    "uniprot": "X8F701",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F701"
  },
  {
    "uniprot": "X8F857",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F857"
  },
  {
    "uniprot": "X8FAH8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAH8"
  },
  {
    "uniprot": "X8F8X4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8X4"
  },
  {
    "uniprot": "X8F3K5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3K5"
  },
  {
    "uniprot": "X8F816",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F816"
  },
  {
    "uniprot": "X8F7P2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7P2"
  },
  {
    "uniprot": "X8F5E1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5E1"
  },
  {
    "uniprot": "X8F1N6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1N6"
  },
  {
    "uniprot": "X8F0K4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0K4"
  },
  {
    "uniprot": "X8F2D1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2D1"
  },
  {
    "uniprot": "X8FM12",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM12"
  },
  {
    "uniprot": "X8F5P9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5P9"
  },
  {
    "uniprot": "X8FL82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL82"
  },
  {
    "uniprot": "X8FNC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNC8"
  },
  {
    "uniprot": "X8F9U7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9U7"
  },
  {
    "uniprot": "X8FK27",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK27"
  },
  {
    "uniprot": "X8FDA4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDA4"
  },
  {
    "uniprot": "X8EYX4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYX4"
  },
  {
    "uniprot": "X8FHV6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHV6"
  },
  {
    "uniprot": "X8F3K9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3K9"
  },
  {
    "uniprot": "X8FPL2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPL2"
  },
  {
    "uniprot": "X8F4X7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4X7"
  },
  {
    "uniprot": "X8FAT1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAT1"
  },
  {
    "uniprot": "X8F820",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F820"
  },
  {
    "uniprot": "X8EYV4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYV4"
  },
  {
    "uniprot": "X8EX90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX90"
  },
  {
    "uniprot": "X8FKY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKY1"
  },
  {
    "uniprot": "X8F639",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F639"
  },
  {
    "uniprot": "X8F774",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F774"
  },
  {
    "uniprot": "X8FER1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FER1"
  },
  {
    "uniprot": "X8F1C6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1C6"
  },
  {
    "uniprot": "X8FLW7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLW7"
  },
  {
    "uniprot": "X8FC08",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC08"
  },
  {
    "uniprot": "X8FLY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLY5"
  },
  {
    "uniprot": "X8FNW3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNW3"
  },
  {
    "uniprot": "X8F0H6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0H6"
  },
  {
    "uniprot": "X8FLX1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLX1"
  },
  {
    "uniprot": "X8FCC4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCC4"
  },
  {
    "uniprot": "X8F202",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F202"
  },
  {
    "uniprot": "X8FLV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLV8"
  },
  {
    "uniprot": "X8EZV3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZV3"
  },
  {
    "uniprot": "X8FCG4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCG4"
  },
  {
    "uniprot": "X8F4V0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4V0"
  },
  {
    "uniprot": "X8FIK6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIK6"
  },
  {
    "uniprot": "X8F436",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F436"
  },
  {
    "uniprot": "X8F672",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F672"
  },
  {
    "uniprot": "X8F1Q0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Q0"
  },
  {
    "uniprot": "X8F194",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F194"
  },
  {
    "uniprot": "X8F8M0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8M0"
  },
  {
    "uniprot": "X8F0W3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0W3"
  },
  {
    "uniprot": "X8F343",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F343"
  },
  {
    "uniprot": "X8F5G5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5G5"
  },
  {
    "uniprot": "X8F7Z8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Z8"
  },
  {
    "uniprot": "X8F0C6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0C6"
  },
  {
    "uniprot": "X8F2W8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2W8"
  },
  {
    "uniprot": "X8FKS7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKS7"
  },
  {
    "uniprot": "X8EZE1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZE1"
  },
  {
    "uniprot": "X8FJ95",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ95"
  },
  {
    "uniprot": "X8FHW4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHW4"
  },
  {
    "uniprot": "X8FKE6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKE6"
  },
  {
    "uniprot": "X8FFQ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFQ3"
  },
  {
    "uniprot": "X8FNH6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNH6"
  },
  {
    "uniprot": "X8F8S8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8S8"
  },
  {
    "uniprot": "X8FLB9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLB9"
  },
  {
    "uniprot": "X8FA48",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA48"
  },
  {
    "uniprot": "X8F8J9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8J9"
  },
  {
    "uniprot": "X8F526",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F526"
  },
  {
    "uniprot": "X8EWH3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWH3"
  },
  {
    "uniprot": "X8FMQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMQ6"
  },
  {
    "uniprot": "X8FEM4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEM4"
  },
  {
    "uniprot": "X8F9S1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9S1"
  },
  {
    "uniprot": "X8FFV5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFV5"
  },
  {
    "uniprot": "X8FBJ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBJ0"
  },
  {
    "uniprot": "X8FF77",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF77"
  },
  {
    "uniprot": "X8EZK9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZK9"
  },
  {
    "uniprot": "X8F041",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F041"
  },
  {
    "uniprot": "X8F0D5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0D5"
  },
  {
    "uniprot": "X8FHU1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHU1"
  },
  {
    "uniprot": "X8F512",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F512"
  },
  {
    "uniprot": "X8FI63",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI63"
  },
  {
    "uniprot": "X8F843",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F843"
  },
  {
    "uniprot": "X8F0A7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0A7"
  },
  {
    "uniprot": "X8FB00",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB00"
  },
  {
    "uniprot": "X8FBH3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBH3"
  },
  {
    "uniprot": "X8EWM7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWM7"
  },
  {
    "uniprot": "X8FMB0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMB0"
  },
  {
    "uniprot": "X8F087",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F087"
  },
  {
    "uniprot": "X8FD43",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD43"
  },
  {
    "uniprot": "X8FCJ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCJ2"
  },
  {
    "uniprot": "X8FAG6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAG6"
  },
  {
    "uniprot": "X8FPA3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPA3"
  },
  {
    "uniprot": "X8F9J0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9J0"
  },
  {
    "uniprot": "X8FAX7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAX7"
  },
  {
    "uniprot": "X8FD38",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD38"
  },
  {
    "uniprot": "X8FMF7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMF7"
  },
  {
    "uniprot": "X8F5Q0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Q0"
  },
  {
    "uniprot": "X8F6X2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6X2"
  },
  {
    "uniprot": "X8F054",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F054"
  },
  {
    "uniprot": "X8F516",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F516"
  },
  {
    "uniprot": "X8FGX2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGX2"
  },
  {
    "uniprot": "X8FAW3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAW3"
  },
  {
    "uniprot": "X8FGS1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGS1"
  },
  {
    "uniprot": "X8FB76",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB76"
  },
  {
    "uniprot": "X8FL84",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL84"
  },
  {
    "uniprot": "X8FA42",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA42"
  },
  {
    "uniprot": "X8FCW5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCW5"
  },
  {
    "uniprot": "X8FN47",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN47"
  },
  {
    "uniprot": "X8FIT4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIT4"
  },
  {
    "uniprot": "X8F728",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F728"
  },
  {
    "uniprot": "X8FDP7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDP7"
  },
  {
    "uniprot": "X8FJU4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJU4"
  },
  {
    "uniprot": "X8F5W0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5W0"
  },
  {
    "uniprot": "X8FHB5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHB5"
  },
  {
    "uniprot": "X8F6A8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6A8"
  },
  {
    "uniprot": "X8FDH2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDH2"
  },
  {
    "uniprot": "X8EZY2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZY2"
  },
  {
    "uniprot": "X8F154",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F154"
  },
  {
    "uniprot": "X8F2P1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2P1"
  },
  {
    "uniprot": "X8FL20",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL20"
  },
  {
    "uniprot": "X8FFD0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFD0"
  },
  {
    "uniprot": "X8FE57",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE57"
  },
  {
    "uniprot": "X8FDS2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDS2"
  },
  {
    "uniprot": "X8FAH4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAH4"
  },
  {
    "uniprot": "X8FGG7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGG7"
  },
  {
    "uniprot": "X8F2B5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2B5"
  },
  {
    "uniprot": "X8FGV3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGV3"
  },
  {
    "uniprot": "X8F1C2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1C2"
  },
  {
    "uniprot": "X8EZT7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZT7"
  },
  {
    "uniprot": "X8F6A1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6A1"
  },
  {
    "uniprot": "X8F588",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F588"
  },
  {
    "uniprot": "X8FJX1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJX1"
  },
  {
    "uniprot": "X8FDJ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDJ5"
  },
  {
    "uniprot": "X8FCA5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCA5"
  },
  {
    "uniprot": "X8EZW4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZW4"
  },
  {
    "uniprot": "X8FH54",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH54"
  },
  {
    "uniprot": "X8EXD9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXD9"
  },
  {
    "uniprot": "X8FK69",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK69"
  },
  {
    "uniprot": "X8F9K1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9K1"
  },
  {
    "uniprot": "X8FIN2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIN2"
  },
  {
    "uniprot": "X8FCX4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCX4"
  },
  {
    "uniprot": "X8F3R7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3R7"
  },
  {
    "uniprot": "X8FA84",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA84"
  },
  {
    "uniprot": "X8F7S2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7S2"
  },
  {
    "uniprot": "X8FAR7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAR7"
  },
  {
    "uniprot": "X8FH02",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH02"
  },
  {
    "uniprot": "X8EZN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZN8"
  },
  {
    "uniprot": "X8FM13",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM13"
  },
  {
    "uniprot": "X8EYP5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYP5"
  },
  {
    "uniprot": "X8FLF4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLF4"
  },
  {
    "uniprot": "X8F5M1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5M1"
  },
  {
    "uniprot": "X8F750",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F750"
  },
  {
    "uniprot": "X8F599",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F599"
  },
  {
    "uniprot": "X8F9H7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9H7"
  },
  {
    "uniprot": "X8EYT1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYT1"
  },
  {
    "uniprot": "X8FPS4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPS4"
  },
  {
    "uniprot": "X8F2P4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2P4"
  },
  {
    "uniprot": "X8F4M0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4M0"
  },
  {
    "uniprot": "X8F6N3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6N3"
  },
  {
    "uniprot": "X8FJ09",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ09"
  },
  {
    "uniprot": "X8F104",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F104"
  },
  {
    "uniprot": "X8F1V0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1V0"
  },
  {
    "uniprot": "X8F3Z5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Z5"
  },
  {
    "uniprot": "X8F6P9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6P9"
  },
  {
    "uniprot": "X8EZK4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZK4"
  },
  {
    "uniprot": "X8FGD5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGD5"
  },
  {
    "uniprot": "X8FCU9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCU9"
  },
  {
    "uniprot": "X8EZZ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZZ4"
  },
  {
    "uniprot": "X8FN26",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN26"
  },
  {
    "uniprot": "X8FBR6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBR6"
  },
  {
    "uniprot": "X8FDN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDN8"
  },
  {
    "uniprot": "X8F4E5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4E5"
  },
  {
    "uniprot": "X8FK81",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK81"
  },
  {
    "uniprot": "X8FA25",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA25"
  },
  {
    "uniprot": "X8FLV1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLV1"
  },
  {
    "uniprot": "X8F212",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F212"
  },
  {
    "uniprot": "X8FE96",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE96"
  },
  {
    "uniprot": "X8FLP7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLP7"
  },
  {
    "uniprot": "X8F4Q1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Q1"
  },
  {
    "uniprot": "X8F4U7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4U7"
  },
  {
    "uniprot": "X8EZM0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZM0"
  },
  {
    "uniprot": "X8F4L2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4L2"
  },
  {
    "uniprot": "X8FES3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FES3"
  },
  {
    "uniprot": "X8F7D5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7D5"
  },
  {
    "uniprot": "X8FFF5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFF5"
  },
  {
    "uniprot": "X8FHC3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHC3"
  },
  {
    "uniprot": "X8FPA4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPA4"
  },
  {
    "uniprot": "X8F0R4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0R4"
  },
  {
    "uniprot": "X8FIV5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIV5"
  },
  {
    "uniprot": "X8FC05",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC05"
  },
  {
    "uniprot": "X8FFM5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFM5"
  },
  {
    "uniprot": "X8FMS7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMS7"
  },
  {
    "uniprot": "X8EZ58",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ58"
  },
  {
    "uniprot": "X8FD96",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD96"
  },
  {
    "uniprot": "X8FCV2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCV2"
  },
  {
    "uniprot": "X8FNC5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNC5"
  },
  {
    "uniprot": "X8FE65",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE65"
  },
  {
    "uniprot": "X8F4L7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4L7"
  },
  {
    "uniprot": "X8FMD1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMD1"
  },
  {
    "uniprot": "X8F4I1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4I1"
  },
  {
    "uniprot": "X8F2W7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2W7"
  },
  {
    "uniprot": "X8FGS7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGS7"
  },
  {
    "uniprot": "X8FNH5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNH5"
  },
  {
    "uniprot": "X8FBN7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBN7"
  },
  {
    "uniprot": "X8FBV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBV8"
  },
  {
    "uniprot": "X8FBQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBQ6"
  },
  {
    "uniprot": "X8FNQ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNQ9"
  },
  {
    "uniprot": "X8F4X6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4X6"
  },
  {
    "uniprot": "X8FEK2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEK2"
  },
  {
    "uniprot": "X8FPA9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPA9"
  },
  {
    "uniprot": "X8EZK2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZK2"
  },
  {
    "uniprot": "X8FJ31",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ31"
  },
  {
    "uniprot": "X8FC81",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC81"
  },
  {
    "uniprot": "X8FB75",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB75"
  },
  {
    "uniprot": "X8FA62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA62"
  },
  {
    "uniprot": "X8FJV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJV0"
  },
  {
    "uniprot": "X8F7R1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7R1"
  },
  {
    "uniprot": "X8FN82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN82"
  },
  {
    "uniprot": "X8FIY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIY1"
  },
  {
    "uniprot": "X8FN43",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN43"
  },
  {
    "uniprot": "X8FF72",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF72"
  },
  {
    "uniprot": "X8FHA3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHA3"
  },
  {
    "uniprot": "X8FEH3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEH3"
  },
  {
    "uniprot": "X8FH68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH68"
  },
  {
    "uniprot": "X8FL65",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL65"
  },
  {
    "uniprot": "X8FLE5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLE5"
  },
  {
    "uniprot": "X8F0M3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0M3"
  },
  {
    "uniprot": "X8F4F8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4F8"
  },
  {
    "uniprot": "X8FEP0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEP0"
  },
  {
    "uniprot": "X8FHB0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHB0"
  },
  {
    "uniprot": "X8FHJ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHJ7"
  },
  {
    "uniprot": "X8FDM7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDM7"
  },
  {
    "uniprot": "X8FHS0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHS0"
  },
  {
    "uniprot": "X8FLZ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLZ3"
  },
  {
    "uniprot": "X8FID8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FID8"
  },
  {
    "uniprot": "X8F6H3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6H3"
  },
  {
    "uniprot": "X8FHJ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHJ6"
  },
  {
    "uniprot": "X8EZS1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZS1"
  },
  {
    "uniprot": "X8FH78",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH78"
  },
  {
    "uniprot": "X8F862",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F862"
  },
  {
    "uniprot": "X8FG85",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG85"
  },
  {
    "uniprot": "X8F2C0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2C0"
  },
  {
    "uniprot": "X8F4J2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4J2"
  },
  {
    "uniprot": "X8FE84",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE84"
  },
  {
    "uniprot": "X8F916",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F916"
  },
  {
    "uniprot": "X8FBX8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBX8"
  },
  {
    "uniprot": "X8F0H5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0H5"
  },
  {
    "uniprot": "X8F5K1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5K1"
  },
  {
    "uniprot": "X8FE85",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE85"
  },
  {
    "uniprot": "X8FAG5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAG5"
  },
  {
    "uniprot": "X8EZW7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZW7"
  },
  {
    "uniprot": "X8FAL7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAL7"
  },
  {
    "uniprot": "X8FGX5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGX5"
  },
  {
    "uniprot": "X8F7W2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7W2"
  },
  {
    "uniprot": "X8F671",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F671"
  },
  {
    "uniprot": "X8FED2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FED2"
  },
  {
    "uniprot": "X8F8T3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8T3"
  },
  {
    "uniprot": "X8FLG1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLG1"
  },
  {
    "uniprot": "X8FE97",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE97"
  },
  {
    "uniprot": "X8F923",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F923"
  },
  {
    "uniprot": "X8FB26",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB26"
  },
  {
    "uniprot": "X8F0S6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0S6"
  },
  {
    "uniprot": "X8F8W3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8W3"
  },
  {
    "uniprot": "X8FD48",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD48"
  },
  {
    "uniprot": "X8F6W6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6W6"
  },
  {
    "uniprot": "X8EWW5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWW5"
  },
  {
    "uniprot": "X8F0N7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0N7"
  },
  {
    "uniprot": "X8EY41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY41"
  },
  {
    "uniprot": "X8F9N4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9N4"
  },
  {
    "uniprot": "X8F0L2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0L2"
  },
  {
    "uniprot": "X8FNX5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNX5"
  },
  {
    "uniprot": "X8FDN6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDN6"
  },
  {
    "uniprot": "X8F7B8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7B8"
  },
  {
    "uniprot": "X8FAZ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAZ7"
  },
  {
    "uniprot": "X8FK33",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK33"
  },
  {
    "uniprot": "X8FK94",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK94"
  },
  {
    "uniprot": "X8FGI5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGI5"
  },
  {
    "uniprot": "X8F3P7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3P7"
  },
  {
    "uniprot": "X8EZJ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZJ5"
  },
  {
    "uniprot": "X8FD04",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD04"
  },
  {
    "uniprot": "X8FEZ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEZ0"
  },
  {
    "uniprot": "X8FFI4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFI4"
  },
  {
    "uniprot": "X8FF58",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF58"
  },
  {
    "uniprot": "X8FEZ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEZ6"
  },
  {
    "uniprot": "X8F392",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F392"
  },
  {
    "uniprot": "X8FPQ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPQ0"
  },
  {
    "uniprot": "X8EZP7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZP7"
  },
  {
    "uniprot": "X8FNU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNU0"
  },
  {
    "uniprot": "X8FGC5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGC5"
  },
  {
    "uniprot": "X8FJN5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJN5"
  },
  {
    "uniprot": "X8FCZ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCZ8"
  },
  {
    "uniprot": "X8FQM1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQM1"
  },
  {
    "uniprot": "X8FP41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP41"
  },
  {
    "uniprot": "X8F0X1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0X1"
  },
  {
    "uniprot": "X8FCP8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCP8"
  },
  {
    "uniprot": "X8FMK9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMK9"
  },
  {
    "uniprot": "X8F5X4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5X4"
  },
  {
    "uniprot": "X8F8J3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8J3"
  },
  {
    "uniprot": "X8FIQ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIQ2"
  },
  {
    "uniprot": "X8EY70",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY70"
  },
  {
    "uniprot": "X8F7L2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7L2"
  },
  {
    "uniprot": "X8FMG8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMG8"
  },
  {
    "uniprot": "X8F2I8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2I8"
  },
  {
    "uniprot": "X8FB40",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB40"
  },
  {
    "uniprot": "X8F089",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F089"
  },
  {
    "uniprot": "X8F724",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F724"
  },
  {
    "uniprot": "X8FKP2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKP2"
  },
  {
    "uniprot": "X8F8P1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8P1"
  },
  {
    "uniprot": "X8FE98",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE98"
  },
  {
    "uniprot": "X8FDJ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDJ1"
  },
  {
    "uniprot": "X8FAN3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAN3"
  },
  {
    "uniprot": "X8F056",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F056"
  },
  {
    "uniprot": "X8F106",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F106"
  },
  {
    "uniprot": "X8F397",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F397"
  },
  {
    "uniprot": "X8EZ24",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ24"
  },
  {
    "uniprot": "X8FDP6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDP6"
  },
  {
    "uniprot": "X8F544",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F544"
  },
  {
    "uniprot": "X8FMT0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMT0"
  },
  {
    "uniprot": "X8FGL5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGL5"
  },
  {
    "uniprot": "X8EZM4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZM4"
  },
  {
    "uniprot": "X8EWY7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWY7"
  },
  {
    "uniprot": "X8FEY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEY1"
  },
  {
    "uniprot": "X8EZF4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZF4"
  },
  {
    "uniprot": "X8FK43",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK43"
  },
  {
    "uniprot": "X8F4H3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4H3"
  },
  {
    "uniprot": "X8F130",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F130"
  },
  {
    "uniprot": "X8EXN7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXN7"
  },
  {
    "uniprot": "X8F5Q6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Q6"
  },
  {
    "uniprot": "X8FKB1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKB1"
  },
  {
    "uniprot": "X8F2Q9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Q9"
  },
  {
    "uniprot": "X8F6M4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6M4"
  },
  {
    "uniprot": "X8FA32",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA32"
  },
  {
    "uniprot": "X8F5B3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5B3"
  },
  {
    "uniprot": "X8F5R0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5R0"
  },
  {
    "uniprot": "X8FJV9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJV9"
  },
  {
    "uniprot": "X8FAA7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAA7"
  },
  {
    "uniprot": "X8FAG4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAG4"
  },
  {
    "uniprot": "X8F5Z2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Z2"
  },
  {
    "uniprot": "X8F3V4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3V4"
  },
  {
    "uniprot": "X8FBC6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBC6"
  },
  {
    "uniprot": "X8F4G2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4G2"
  },
  {
    "uniprot": "X8F0A8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0A8"
  },
  {
    "uniprot": "X8F502",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F502"
  },
  {
    "uniprot": "X8FHF2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHF2"
  },
  {
    "uniprot": "X8FAQ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAQ9"
  },
  {
    "uniprot": "X8FG47",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG47"
  },
  {
    "uniprot": "X8FE32",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE32"
  },
  {
    "uniprot": "X8F8L1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8L1"
  },
  {
    "uniprot": "X8FCK8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCK8"
  },
  {
    "uniprot": "X8FPX5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPX5"
  },
  {
    "uniprot": "X8F2J9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2J9"
  },
  {
    "uniprot": "X8FG13",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG13"
  },
  {
    "uniprot": "X8FAN9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAN9"
  },
  {
    "uniprot": "X8FK92",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK92"
  },
  {
    "uniprot": "X8FFT4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFT4"
  },
  {
    "uniprot": "X8FLJ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLJ7"
  },
  {
    "uniprot": "X8FFV4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFV4"
  },
  {
    "uniprot": "X8FDQ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDQ1"
  },
  {
    "uniprot": "X8FDU3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDU3"
  },
  {
    "uniprot": "X8F2T4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2T4"
  },
  {
    "uniprot": "X8FI04",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI04"
  },
  {
    "uniprot": "X8F335",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F335"
  },
  {
    "uniprot": "X8FJ96",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ96"
  },
  {
    "uniprot": "X8FBH7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBH7"
  },
  {
    "uniprot": "X8F2E9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2E9"
  },
  {
    "uniprot": "X8FMZ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMZ7"
  },
  {
    "uniprot": "X8F198",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F198"
  },
  {
    "uniprot": "X8FPI0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPI0"
  },
  {
    "uniprot": "X8F2U9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2U9"
  },
  {
    "uniprot": "X8FFN2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFN2"
  },
  {
    "uniprot": "X8FI59",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI59"
  },
  {
    "uniprot": "X8FBD9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBD9"
  },
  {
    "uniprot": "X8F4B7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4B7"
  },
  {
    "uniprot": "X8F8V5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8V5"
  },
  {
    "uniprot": "X8F461",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F461"
  },
  {
    "uniprot": "X8FE16",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE16"
  },
  {
    "uniprot": "X8FAE7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAE7"
  },
  {
    "uniprot": "X8FHB7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHB7"
  },
  {
    "uniprot": "X8F782",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F782"
  },
  {
    "uniprot": "X8FM58",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM58"
  },
  {
    "uniprot": "X8FHH3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHH3"
  },
  {
    "uniprot": "X8EXP1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXP1"
  },
  {
    "uniprot": "X8FF88",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF88"
  },
  {
    "uniprot": "X8FDE9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDE9"
  },
  {
    "uniprot": "X8F314",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F314"
  },
  {
    "uniprot": "X8EWZ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWZ4"
  },
  {
    "uniprot": "X8F0C3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0C3"
  },
  {
    "uniprot": "X8FD84",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD84"
  },
  {
    "uniprot": "X8FLF6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLF6"
  },
  {
    "uniprot": "X8FKG8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKG8"
  },
  {
    "uniprot": "X8F171",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F171"
  },
  {
    "uniprot": "X8F180",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F180"
  },
  {
    "uniprot": "X8F967",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F967"
  },
  {
    "uniprot": "X8F5U1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5U1"
  },
  {
    "uniprot": "X8F370",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F370"
  },
  {
    "uniprot": "X8F352",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F352"
  },
  {
    "uniprot": "X8F978",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F978"
  },
  {
    "uniprot": "X8F824",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F824"
  },
  {
    "uniprot": "X8FCM4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCM4"
  },
  {
    "uniprot": "X8F7Z4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Z4"
  },
  {
    "uniprot": "X8FEW6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEW6"
  },
  {
    "uniprot": "X8F2L9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2L9"
  },
  {
    "uniprot": "X8F7G1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7G1"
  },
  {
    "uniprot": "X8FEW4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEW4"
  },
  {
    "uniprot": "X8FJK0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJK0"
  },
  {
    "uniprot": "X8F261",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F261"
  },
  {
    "uniprot": "X8F220",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F220"
  },
  {
    "uniprot": "X8FLN0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLN0"
  },
  {
    "uniprot": "X8FFP5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFP5"
  },
  {
    "uniprot": "X8F4A3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4A3"
  },
  {
    "uniprot": "X8F2T6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2T6"
  },
  {
    "uniprot": "X8F2A9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2A9"
  },
  {
    "uniprot": "X8FFV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFV8"
  },
  {
    "uniprot": "X8FG62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG62"
  },
  {
    "uniprot": "X8FFF0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFF0"
  },
  {
    "uniprot": "X8FLI4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLI4"
  },
  {
    "uniprot": "X8F1U6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1U6"
  },
  {
    "uniprot": "X8F2X8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2X8"
  },
  {
    "uniprot": "X8F215",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F215"
  },
  {
    "uniprot": "X8F1C7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1C7"
  },
  {
    "uniprot": "X8EYF7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYF7"
  },
  {
    "uniprot": "X8FD52",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD52"
  },
  {
    "uniprot": "X8F9C1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9C1"
  },
  {
    "uniprot": "X8FM36",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM36"
  },
  {
    "uniprot": "X8FKZ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKZ4"
  },
  {
    "uniprot": "X8F6U7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6U7"
  },
  {
    "uniprot": "X8F9Y7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Y7"
  },
  {
    "uniprot": "X8FC29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC29"
  },
  {
    "uniprot": "X8FB74",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB74"
  },
  {
    "uniprot": "X8F4D8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4D8"
  },
  {
    "uniprot": "X8FDT4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDT4"
  },
  {
    "uniprot": "X8FGB7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGB7"
  },
  {
    "uniprot": "X8F5H2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5H2"
  },
  {
    "uniprot": "X8FN19",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN19"
  },
  {
    "uniprot": "X8FHE0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHE0"
  },
  {
    "uniprot": "X8F4R8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4R8"
  },
  {
    "uniprot": "X8F483",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F483"
  },
  {
    "uniprot": "X8FFV1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFV1"
  },
  {
    "uniprot": "X8FMU7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMU7"
  },
  {
    "uniprot": "X8FKR2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKR2"
  },
  {
    "uniprot": "X8F3Y1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Y1"
  },
  {
    "uniprot": "X8FMF0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMF0"
  },
  {
    "uniprot": "X8FBX1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBX1"
  },
  {
    "uniprot": "X8FHI2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHI2"
  },
  {
    "uniprot": "X8F893",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F893"
  },
  {
    "uniprot": "X8F3X5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3X5"
  },
  {
    "uniprot": "X8F009",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F009"
  },
  {
    "uniprot": "X8FG06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG06"
  },
  {
    "uniprot": "X8F1K9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1K9"
  },
  {
    "uniprot": "X8FCE0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCE0"
  },
  {
    "uniprot": "X8F0Z6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Z6"
  },
  {
    "uniprot": "X8FBZ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBZ9"
  },
  {
    "uniprot": "X8FHJ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHJ3"
  },
  {
    "uniprot": "X8F5F4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5F4"
  },
  {
    "uniprot": "X8F385",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F385"
  },
  {
    "uniprot": "X8EZQ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZQ5"
  },
  {
    "uniprot": "X8FD54",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD54"
  },
  {
    "uniprot": "X8F5B8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5B8"
  },
  {
    "uniprot": "X8FJM6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJM6"
  },
  {
    "uniprot": "X8FHF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHF3"
  },
  {
    "uniprot": "X8FB70",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB70"
  },
  {
    "uniprot": "X8EZK1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZK1"
  },
  {
    "uniprot": "X8FCB5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCB5"
  },
  {
    "uniprot": "X8F8B8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8B8"
  },
  {
    "uniprot": "X8F952",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F952"
  },
  {
    "uniprot": "X8FB57",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB57"
  },
  {
    "uniprot": "X8FGV4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGV4"
  },
  {
    "uniprot": "X8F187",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F187"
  },
  {
    "uniprot": "X8FA37",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA37"
  },
  {
    "uniprot": "X8FE05",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE05"
  },
  {
    "uniprot": "X8FLM4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLM4"
  },
  {
    "uniprot": "X8F988",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F988"
  },
  {
    "uniprot": "X8EZE7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZE7"
  },
  {
    "uniprot": "X8FL03",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL03"
  },
  {
    "uniprot": "X8FC23",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC23"
  },
  {
    "uniprot": "X8EY55",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY55"
  },
  {
    "uniprot": "X8F8F1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8F1"
  },
  {
    "uniprot": "X8FHM5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHM5"
  },
  {
    "uniprot": "X8F3Y2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Y2"
  },
  {
    "uniprot": "X8F511",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F511"
  },
  {
    "uniprot": "X8FQL6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQL6"
  },
  {
    "uniprot": "X8FFR0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFR0"
  },
  {
    "uniprot": "X8FI92",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI92"
  },
  {
    "uniprot": "X8F0N8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0N8"
  },
  {
    "uniprot": "X8FJH0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJH0"
  },
  {
    "uniprot": "X8F382",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F382"
  },
  {
    "uniprot": "X8FKF0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKF0"
  },
  {
    "uniprot": "X8F3V8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3V8"
  },
  {
    "uniprot": "X8FDV2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDV2"
  },
  {
    "uniprot": "X8F8W2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8W2"
  },
  {
    "uniprot": "X8FCY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCY9"
  },
  {
    "uniprot": "X8EWX4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWX4"
  },
  {
    "uniprot": "X8EZX4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZX4"
  },
  {
    "uniprot": "X8F4D0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4D0"
  },
  {
    "uniprot": "X8F8Q8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Q8"
  },
  {
    "uniprot": "X8FIB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIB3"
  },
  {
    "uniprot": "X8F6V0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6V0"
  },
  {
    "uniprot": "X8FL87",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL87"
  },
  {
    "uniprot": "X8EZQ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZQ8"
  },
  {
    "uniprot": "X8F1A3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1A3"
  },
  {
    "uniprot": "X8F8Z2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Z2"
  },
  {
    "uniprot": "X8EYW9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYW9"
  },
  {
    "uniprot": "X8FDD7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDD7"
  },
  {
    "uniprot": "X8FAF9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAF9"
  },
  {
    "uniprot": "X8FH17",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH17"
  },
  {
    "uniprot": "X8FJN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJN8"
  },
  {
    "uniprot": "X8F6D5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6D5"
  },
  {
    "uniprot": "X8FLW3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLW3"
  },
  {
    "uniprot": "X8FLJ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLJ1"
  },
  {
    "uniprot": "X8EX53",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX53"
  },
  {
    "uniprot": "X8FB37",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB37"
  },
  {
    "uniprot": "X8FM00",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM00"
  },
  {
    "uniprot": "X8F8Z3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Z3"
  },
  {
    "uniprot": "X8EZB8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZB8"
  },
  {
    "uniprot": "X8FET7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FET7"
  },
  {
    "uniprot": "X8F7H5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7H5"
  },
  {
    "uniprot": "X8FAJ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAJ2"
  },
  {
    "uniprot": "X8F1X3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1X3"
  },
  {
    "uniprot": "X8FEB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEB3"
  },
  {
    "uniprot": "X8F3W9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3W9"
  },
  {
    "uniprot": "X8FFZ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFZ1"
  },
  {
    "uniprot": "X8FL83",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL83"
  },
  {
    "uniprot": "X8F309",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F309"
  },
  {
    "uniprot": "X8F8P9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8P9"
  },
  {
    "uniprot": "X8F1V8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1V8"
  },
  {
    "uniprot": "X8FM27",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM27"
  },
  {
    "uniprot": "X8FB18",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB18"
  },
  {
    "uniprot": "X8FFX3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFX3"
  },
  {
    "uniprot": "X8FK39",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK39"
  },
  {
    "uniprot": "X8F210",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F210"
  },
  {
    "uniprot": "X8FAY2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAY2"
  },
  {
    "uniprot": "X8FJC5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJC5"
  },
  {
    "uniprot": "X8EZ33",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ33"
  },
  {
    "uniprot": "X8FEL9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEL9"
  },
  {
    "uniprot": "X8FM43",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM43"
  },
  {
    "uniprot": "X8FK98",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK98"
  },
  {
    "uniprot": "X8EZR6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZR6"
  },
  {
    "uniprot": "X8F716",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F716"
  },
  {
    "uniprot": "X8F3Y8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Y8"
  },
  {
    "uniprot": "X8FD32",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD32"
  },
  {
    "uniprot": "X8FJ14",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ14"
  },
  {
    "uniprot": "X8F7N2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7N2"
  },
  {
    "uniprot": "X8F2A0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2A0"
  },
  {
    "uniprot": "X8FEG4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEG4"
  },
  {
    "uniprot": "X8FCM7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCM7"
  },
  {
    "uniprot": "X8FDN9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDN9"
  },
  {
    "uniprot": "X8FKP7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKP7"
  },
  {
    "uniprot": "X8FB62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB62"
  },
  {
    "uniprot": "X8F9T9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9T9"
  },
  {
    "uniprot": "X8FLW2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLW2"
  },
  {
    "uniprot": "X8F881",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F881"
  },
  {
    "uniprot": "X8FED8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FED8"
  },
  {
    "uniprot": "X8FGU7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGU7"
  },
  {
    "uniprot": "X8F689",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F689"
  },
  {
    "uniprot": "X8F472",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F472"
  },
  {
    "uniprot": "X8F7Z9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Z9"
  },
  {
    "uniprot": "X8FFI6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFI6"
  },
  {
    "uniprot": "X8F3G6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3G6"
  },
  {
    "uniprot": "X8FMG9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMG9"
  },
  {
    "uniprot": "X8F2M6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2M6"
  },
  {
    "uniprot": "X8F5S0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5S0"
  },
  {
    "uniprot": "X8F075",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F075"
  },
  {
    "uniprot": "X8F7S3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7S3"
  },
  {
    "uniprot": "X8F9V0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9V0"
  },
  {
    "uniprot": "X8F5D0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5D0"
  },
  {
    "uniprot": "X8F508",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F508"
  },
  {
    "uniprot": "X8FAI7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAI7"
  },
  {
    "uniprot": "X8FGS2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGS2"
  },
  {
    "uniprot": "X8FCA6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCA6"
  },
  {
    "uniprot": "X8FJR9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJR9"
  },
  {
    "uniprot": "X8FFG9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFG9"
  },
  {
    "uniprot": "X8FLL2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLL2"
  },
  {
    "uniprot": "X8EZ45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ45"
  },
  {
    "uniprot": "X8FGG8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGG8"
  },
  {
    "uniprot": "X8FD64",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD64"
  },
  {
    "uniprot": "X8F9P3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9P3"
  },
  {
    "uniprot": "X8FLG5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLG5"
  },
  {
    "uniprot": "X8FD24",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD24"
  },
  {
    "uniprot": "X8F804",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F804"
  },
  {
    "uniprot": "X8FI22",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI22"
  },
  {
    "uniprot": "X8F1G2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1G2"
  },
  {
    "uniprot": "X8FI49",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI49"
  },
  {
    "uniprot": "X8F9S8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9S8"
  },
  {
    "uniprot": "X8FEG6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEG6"
  },
  {
    "uniprot": "X8FFK4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFK4"
  },
  {
    "uniprot": "X8FHY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHY9"
  },
  {
    "uniprot": "X8FGC6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGC6"
  },
  {
    "uniprot": "X8FA01",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA01"
  },
  {
    "uniprot": "X8F0B2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0B2"
  },
  {
    "uniprot": "X8F4N3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4N3"
  },
  {
    "uniprot": "X8FDF4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDF4"
  },
  {
    "uniprot": "X8FIM6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIM6"
  },
  {
    "uniprot": "X8FAB9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAB9"
  },
  {
    "uniprot": "X8FI26",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI26"
  },
  {
    "uniprot": "X8EWU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWU0"
  },
  {
    "uniprot": "X8FIP6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIP6"
  },
  {
    "uniprot": "X8FGT4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGT4"
  },
  {
    "uniprot": "X8FMJ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMJ4"
  },
  {
    "uniprot": "X8F2H2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2H2"
  },
  {
    "uniprot": "X8FNH0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNH0"
  },
  {
    "uniprot": "X8FL28",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL28"
  },
  {
    "uniprot": "X8F060",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F060"
  },
  {
    "uniprot": "X8F143",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F143"
  },
  {
    "uniprot": "X8FAF1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAF1"
  },
  {
    "uniprot": "X8F8P7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8P7"
  },
  {
    "uniprot": "X8F1P1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1P1"
  },
  {
    "uniprot": "X8F6V6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6V6"
  },
  {
    "uniprot": "X8F2L8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2L8"
  },
  {
    "uniprot": "X8F6E0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6E0"
  },
  {
    "uniprot": "X8FJ33",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ33"
  },
  {
    "uniprot": "X8EZY4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZY4"
  },
  {
    "uniprot": "X8FGD6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGD6"
  },
  {
    "uniprot": "X8F7G8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7G8"
  },
  {
    "uniprot": "X8F413",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F413"
  },
  {
    "uniprot": "X8FJD2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJD2"
  },
  {
    "uniprot": "X8FB85",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB85"
  },
  {
    "uniprot": "X8FHX4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHX4"
  },
  {
    "uniprot": "X8FC16",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC16"
  },
  {
    "uniprot": "X8FIA8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIA8"
  },
  {
    "uniprot": "X8F6K1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6K1"
  },
  {
    "uniprot": "X8FDX2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDX2"
  },
  {
    "uniprot": "X8F4J5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4J5"
  },
  {
    "uniprot": "X8EY21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY21"
  },
  {
    "uniprot": "X8FLD8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLD8"
  },
  {
    "uniprot": "X8F883",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F883"
  },
  {
    "uniprot": "X8FCD8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCD8"
  },
  {
    "uniprot": "X8FGY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGY5"
  },
  {
    "uniprot": "X8FMA7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMA7"
  },
  {
    "uniprot": "X8F1D0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1D0"
  },
  {
    "uniprot": "X8F1N7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1N7"
  },
  {
    "uniprot": "X8F8K2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8K2"
  },
  {
    "uniprot": "X8F5E2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5E2"
  },
  {
    "uniprot": "X8F280",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F280"
  },
  {
    "uniprot": "X8FAS4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAS4"
  },
  {
    "uniprot": "X8F3F7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3F7"
  },
  {
    "uniprot": "X8FEQ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEQ8"
  },
  {
    "uniprot": "X8F3N9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3N9"
  },
  {
    "uniprot": "X8F3J4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3J4"
  },
  {
    "uniprot": "X8FLK0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLK0"
  },
  {
    "uniprot": "X8FCI6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCI6"
  },
  {
    "uniprot": "X8FLH3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLH3"
  },
  {
    "uniprot": "X8F7J2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7J2"
  },
  {
    "uniprot": "X8FEW0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEW0"
  },
  {
    "uniprot": "X8F9P6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9P6"
  },
  {
    "uniprot": "X8F4N5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4N5"
  },
  {
    "uniprot": "X8F8L3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8L3"
  },
  {
    "uniprot": "X8FPM3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPM3"
  },
  {
    "uniprot": "X8FNF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNF3"
  },
  {
    "uniprot": "X8F757",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F757"
  },
  {
    "uniprot": "X8FDV7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDV7"
  },
  {
    "uniprot": "X8F4F9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4F9"
  },
  {
    "uniprot": "X8FA96",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA96"
  },
  {
    "uniprot": "X8FIY7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIY7"
  },
  {
    "uniprot": "X8FDG9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDG9"
  },
  {
    "uniprot": "X8F9S5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9S5"
  },
  {
    "uniprot": "X8FEH4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEH4"
  },
  {
    "uniprot": "X8FDE5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDE5"
  },
  {
    "uniprot": "X8FG11",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG11"
  },
  {
    "uniprot": "X8FDV3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDV3"
  },
  {
    "uniprot": "X8FI52",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI52"
  },
  {
    "uniprot": "X8EZC6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZC6"
  },
  {
    "uniprot": "X8FMF5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMF5"
  },
  {
    "uniprot": "X8F833",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F833"
  },
  {
    "uniprot": "X8FGX7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGX7"
  },
  {
    "uniprot": "X8F9Q8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Q8"
  },
  {
    "uniprot": "X8F1M5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1M5"
  },
  {
    "uniprot": "X8EXE5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXE5"
  },
  {
    "uniprot": "X8FAF8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAF8"
  },
  {
    "uniprot": "X8F480",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F480"
  },
  {
    "uniprot": "X8FKN6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKN6"
  },
  {
    "uniprot": "X8F3H3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3H3"
  },
  {
    "uniprot": "X8F5M4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5M4"
  },
  {
    "uniprot": "X8FAC1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAC1"
  },
  {
    "uniprot": "X8FP63",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP63"
  },
  {
    "uniprot": "X8F159",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F159"
  },
  {
    "uniprot": "X8FC84",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC84"
  },
  {
    "uniprot": "X8FLM1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLM1"
  },
  {
    "uniprot": "X8FHB4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHB4"
  },
  {
    "uniprot": "X8F0N1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0N1"
  },
  {
    "uniprot": "X8FEQ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEQ9"
  },
  {
    "uniprot": "X8F4E8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4E8"
  },
  {
    "uniprot": "X8F131",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F131"
  },
  {
    "uniprot": "X8F1C1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1C1"
  },
  {
    "uniprot": "X8EYN6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYN6"
  },
  {
    "uniprot": "X8FCZ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCZ3"
  },
  {
    "uniprot": "X8F1S0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1S0"
  },
  {
    "uniprot": "X8F622",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F622"
  },
  {
    "uniprot": "X8F7E8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7E8"
  },
  {
    "uniprot": "X8FC31",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC31"
  },
  {
    "uniprot": "X8FCH9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCH9"
  },
  {
    "uniprot": "X8FGS9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGS9"
  },
  {
    "uniprot": "X8F0P2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0P2"
  },
  {
    "uniprot": "X8FJZ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJZ9"
  },
  {
    "uniprot": "X8FE74",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE74"
  },
  {
    "uniprot": "X8FFA3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFA3"
  },
  {
    "uniprot": "X8FHT5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHT5"
  },
  {
    "uniprot": "X8F4Y6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Y6"
  },
  {
    "uniprot": "X8FDJ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDJ9"
  },
  {
    "uniprot": "X8F1Q6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Q6"
  },
  {
    "uniprot": "X8FF97",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF97"
  },
  {
    "uniprot": "X8FNF4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNF4"
  },
  {
    "uniprot": "X8FBF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBF3"
  },
  {
    "uniprot": "X8F877",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F877"
  },
  {
    "uniprot": "X8FNY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNY9"
  },
  {
    "uniprot": "X8FHA6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHA6"
  },
  {
    "uniprot": "X8FG08",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG08"
  },
  {
    "uniprot": "X8F2M1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2M1"
  },
  {
    "uniprot": "X8EZ49",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ49"
  },
  {
    "uniprot": "X8FKZ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKZ7"
  },
  {
    "uniprot": "X8FBU2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBU2"
  },
  {
    "uniprot": "X8FLL1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLL1"
  },
  {
    "uniprot": "X8FIV9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIV9"
  },
  {
    "uniprot": "X8EYN5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYN5"
  },
  {
    "uniprot": "X8F8E1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8E1"
  },
  {
    "uniprot": "X8F500",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F500"
  },
  {
    "uniprot": "X8FPB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPB3"
  },
  {
    "uniprot": "X8EYM6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYM6"
  },
  {
    "uniprot": "X8FQN3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQN3"
  },
  {
    "uniprot": "X8FLD7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLD7"
  },
  {
    "uniprot": "X8FK84",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK84"
  },
  {
    "uniprot": "X8F847",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F847"
  },
  {
    "uniprot": "X8FJL6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJL6"
  },
  {
    "uniprot": "X8FAG1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAG1"
  },
  {
    "uniprot": "X8F7M2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7M2"
  },
  {
    "uniprot": "X8FMK0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMK0"
  },
  {
    "uniprot": "X8FIX4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIX4"
  },
  {
    "uniprot": "X8F5T4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5T4"
  },
  {
    "uniprot": "X8FC07",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC07"
  },
  {
    "uniprot": "X8EYG9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYG9"
  },
  {
    "uniprot": "X8F8U8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8U8"
  },
  {
    "uniprot": "X8F6Z9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Z9"
  },
  {
    "uniprot": "X8EY92",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY92"
  },
  {
    "uniprot": "X8F3Z7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Z7"
  },
  {
    "uniprot": "X8F1P4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1P4"
  },
  {
    "uniprot": "X8FBK7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBK7"
  },
  {
    "uniprot": "X8FGI3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGI3"
  },
  {
    "uniprot": "X8F0Y5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Y5"
  },
  {
    "uniprot": "X8FG57",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG57"
  },
  {
    "uniprot": "X8EZ53",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ53"
  },
  {
    "uniprot": "X8FHI6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHI6"
  },
  {
    "uniprot": "X8FIC2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIC2"
  },
  {
    "uniprot": "X8FFL8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFL8"
  },
  {
    "uniprot": "X8FEE0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEE0"
  },
  {
    "uniprot": "X8FKT8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKT8"
  },
  {
    "uniprot": "X8FLI2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLI2"
  },
  {
    "uniprot": "X8F495",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F495"
  },
  {
    "uniprot": "X8EZ54",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ54"
  },
  {
    "uniprot": "X8EZJ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZJ7"
  },
  {
    "uniprot": "X8FN36",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN36"
  },
  {
    "uniprot": "X8EXN6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXN6"
  },
  {
    "uniprot": "X8FHU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHU0"
  },
  {
    "uniprot": "X8EZS3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZS3"
  },
  {
    "uniprot": "X8F015",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F015"
  },
  {
    "uniprot": "X8FDI4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDI4"
  },
  {
    "uniprot": "X8FDC1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDC1"
  },
  {
    "uniprot": "X8F949",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F949"
  },
  {
    "uniprot": "X8FMB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMB3"
  },
  {
    "uniprot": "X8F232",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F232"
  },
  {
    "uniprot": "X8F396",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F396"
  },
  {
    "uniprot": "X8FP35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP35"
  },
  {
    "uniprot": "X8FI20",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI20"
  },
  {
    "uniprot": "X8F630",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F630"
  },
  {
    "uniprot": "X8F7S1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7S1"
  },
  {
    "uniprot": "X8FCH6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCH6"
  },
  {
    "uniprot": "X8FML0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FML0"
  },
  {
    "uniprot": "X8EZQ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZQ9"
  },
  {
    "uniprot": "X8FDB0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDB0"
  },
  {
    "uniprot": "X8F8L6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8L6"
  },
  {
    "uniprot": "X8FFS7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFS7"
  },
  {
    "uniprot": "X8FBA2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBA2"
  },
  {
    "uniprot": "X8FJ12",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ12"
  },
  {
    "uniprot": "X8EZX1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZX1"
  },
  {
    "uniprot": "X8F2U5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2U5"
  },
  {
    "uniprot": "X8FE79",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE79"
  },
  {
    "uniprot": "X8F150",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F150"
  },
  {
    "uniprot": "X8F373",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F373"
  },
  {
    "uniprot": "X8FF29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF29"
  },
  {
    "uniprot": "X8FHZ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHZ5"
  },
  {
    "uniprot": "X8FB86",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB86"
  },
  {
    "uniprot": "X8FAM0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAM0"
  },
  {
    "uniprot": "X8FNW8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNW8"
  },
  {
    "uniprot": "X8FMG1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMG1"
  },
  {
    "uniprot": "X8FGP0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGP0"
  },
  {
    "uniprot": "X8F5G0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5G0"
  },
  {
    "uniprot": "X8FL78",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL78"
  },
  {
    "uniprot": "X8FJ05",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ05"
  },
  {
    "uniprot": "X8F9P7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9P7"
  },
  {
    "uniprot": "X8FKG5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKG5"
  },
  {
    "uniprot": "X8FA04",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA04"
  },
  {
    "uniprot": "X8F5L3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5L3"
  },
  {
    "uniprot": "X8EYF4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYF4"
  },
  {
    "uniprot": "X8FG17",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG17"
  },
  {
    "uniprot": "X8F7B1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7B1"
  },
  {
    "uniprot": "X8F8R9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8R9"
  },
  {
    "uniprot": "X8FC73",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC73"
  },
  {
    "uniprot": "X8FMK7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMK7"
  },
  {
    "uniprot": "X8FLC3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLC3"
  },
  {
    "uniprot": "X8F9V1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9V1"
  },
  {
    "uniprot": "X8F3E4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3E4"
  },
  {
    "uniprot": "X8FJT7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJT7"
  },
  {
    "uniprot": "X8F6U3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6U3"
  },
  {
    "uniprot": "X8FLD0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLD0"
  },
  {
    "uniprot": "X8FK53",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK53"
  },
  {
    "uniprot": "X8FCY6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCY6"
  },
  {
    "uniprot": "X8F9X8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9X8"
  },
  {
    "uniprot": "X8FKH7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKH7"
  },
  {
    "uniprot": "X8F114",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F114"
  },
  {
    "uniprot": "X8FMZ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMZ3"
  },
  {
    "uniprot": "X8F453",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F453"
  },
  {
    "uniprot": "X8FA50",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA50"
  },
  {
    "uniprot": "X8F0G9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0G9"
  },
  {
    "uniprot": "X8FK48",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK48"
  },
  {
    "uniprot": "X8FDE3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDE3"
  },
  {
    "uniprot": "X8EXD1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXD1"
  },
  {
    "uniprot": "X8FLI0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLI0"
  },
  {
    "uniprot": "X8FCL8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCL8"
  },
  {
    "uniprot": "X8FJ60",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ60"
  },
  {
    "uniprot": "X8FHJ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHJ4"
  },
  {
    "uniprot": "X8F168",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F168"
  },
  {
    "uniprot": "X8F0T1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0T1"
  },
  {
    "uniprot": "X8FB60",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB60"
  },
  {
    "uniprot": "X8FFA8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFA8"
  },
  {
    "uniprot": "X8EYU7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYU7"
  },
  {
    "uniprot": "X8F1Y2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Y2"
  },
  {
    "uniprot": "X8FEU4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEU4"
  },
  {
    "uniprot": "X8FEE7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEE7"
  },
  {
    "uniprot": "X8F3T0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3T0"
  },
  {
    "uniprot": "X8F4A6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4A6"
  },
  {
    "uniprot": "X8F606",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F606"
  },
  {
    "uniprot": "X8F071",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F071"
  },
  {
    "uniprot": "X8F3U8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3U8"
  },
  {
    "uniprot": "X8FBA3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBA3"
  },
  {
    "uniprot": "X8FAM4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAM4"
  },
  {
    "uniprot": "X8FC21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC21"
  },
  {
    "uniprot": "X8FGF2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGF2"
  },
  {
    "uniprot": "X8FH06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH06"
  },
  {
    "uniprot": "X8FE88",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE88"
  },
  {
    "uniprot": "X8F1T0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1T0"
  },
  {
    "uniprot": "X8FBB9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBB9"
  },
  {
    "uniprot": "X8FF33",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF33"
  },
  {
    "uniprot": "X8FBS5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBS5"
  },
  {
    "uniprot": "X8F4C1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4C1"
  },
  {
    "uniprot": "X8FPS5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPS5"
  },
  {
    "uniprot": "X8F1A8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1A8"
  },
  {
    "uniprot": "X8F1D2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1D2"
  },
  {
    "uniprot": "X8FAH3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAH3"
  },
  {
    "uniprot": "X8FJK9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJK9"
  },
  {
    "uniprot": "X8FEV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEV8"
  },
  {
    "uniprot": "X8EZB2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZB2"
  },
  {
    "uniprot": "X8FI76",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI76"
  },
  {
    "uniprot": "X8F0Z4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Z4"
  },
  {
    "uniprot": "X8FLR6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLR6"
  },
  {
    "uniprot": "X8EY82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY82"
  },
  {
    "uniprot": "X8FNB0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNB0"
  },
  {
    "uniprot": "X8EZI6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZI6"
  },
  {
    "uniprot": "X8EYP2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYP2"
  },
  {
    "uniprot": "X8FPJ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPJ2"
  },
  {
    "uniprot": "X8F467",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F467"
  },
  {
    "uniprot": "X8FPV5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPV5"
  },
  {
    "uniprot": "X8F740",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F740"
  },
  {
    "uniprot": "X8FPE8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPE8"
  },
  {
    "uniprot": "X8EZP2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZP2"
  },
  {
    "uniprot": "X8FFJ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFJ8"
  },
  {
    "uniprot": "X8FEH8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEH8"
  },
  {
    "uniprot": "X8FD18",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD18"
  },
  {
    "uniprot": "X8FCE6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCE6"
  },
  {
    "uniprot": "X8FMB1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMB1"
  },
  {
    "uniprot": "X8F3F4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3F4"
  },
  {
    "uniprot": "X8F664",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F664"
  },
  {
    "uniprot": "X8FIU4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIU4"
  },
  {
    "uniprot": "X8FDJ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDJ0"
  },
  {
    "uniprot": "X8FGF7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGF7"
  },
  {
    "uniprot": "X8F3T5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3T5"
  },
  {
    "uniprot": "X8FDH7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDH7"
  },
  {
    "uniprot": "X8FH99",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH99"
  },
  {
    "uniprot": "X8FMC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMC8"
  },
  {
    "uniprot": "X8EXV9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXV9"
  },
  {
    "uniprot": "X8F9E8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9E8"
  },
  {
    "uniprot": "X8FER9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FER9"
  },
  {
    "uniprot": "X8FIX7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIX7"
  },
  {
    "uniprot": "X8FJC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJC8"
  },
  {
    "uniprot": "X8FJ15",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ15"
  },
  {
    "uniprot": "X8FH28",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH28"
  },
  {
    "uniprot": "X8F1D4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1D4"
  },
  {
    "uniprot": "X8F763",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F763"
  },
  {
    "uniprot": "X8F027",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F027"
  },
  {
    "uniprot": "X8FEC6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEC6"
  },
  {
    "uniprot": "X8FAR9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAR9"
  },
  {
    "uniprot": "X8FGP1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGP1"
  },
  {
    "uniprot": "X8F7C2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7C2"
  },
  {
    "uniprot": "X8F592",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F592"
  },
  {
    "uniprot": "X8FDK8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDK8"
  },
  {
    "uniprot": "X8F0R1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0R1"
  },
  {
    "uniprot": "X8EZT9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZT9"
  },
  {
    "uniprot": "X8FHN0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHN0"
  },
  {
    "uniprot": "X8F946",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F946"
  },
  {
    "uniprot": "X8FDS6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDS6"
  },
  {
    "uniprot": "X8F6T4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6T4"
  },
  {
    "uniprot": "X8FGG2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGG2"
  },
  {
    "uniprot": "X8FLH2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLH2"
  },
  {
    "uniprot": "X8FKI9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKI9"
  },
  {
    "uniprot": "X8EXA7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXA7"
  },
  {
    "uniprot": "X8F8N4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8N4"
  },
  {
    "uniprot": "X8F7R8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7R8"
  },
  {
    "uniprot": "X8F7Z3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Z3"
  },
  {
    "uniprot": "X8EYZ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYZ8"
  },
  {
    "uniprot": "X8FAU9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAU9"
  },
  {
    "uniprot": "X8F4P6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4P6"
  },
  {
    "uniprot": "X8FJV2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJV2"
  },
  {
    "uniprot": "X8F3S0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3S0"
  },
  {
    "uniprot": "X8F3Z3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Z3"
  },
  {
    "uniprot": "X8FDK3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDK3"
  },
  {
    "uniprot": "X8FHR8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHR8"
  },
  {
    "uniprot": "X8FNA4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNA4"
  },
  {
    "uniprot": "X8FQ09",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQ09"
  },
  {
    "uniprot": "X8EZL0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZL0"
  },
  {
    "uniprot": "X8EZA9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZA9"
  },
  {
    "uniprot": "X8F1C0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1C0"
  },
  {
    "uniprot": "X8F872",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F872"
  },
  {
    "uniprot": "X8FJ37",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ37"
  },
  {
    "uniprot": "X8FEP4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEP4"
  },
  {
    "uniprot": "X8FMN6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMN6"
  },
  {
    "uniprot": "X8FLQ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLQ5"
  },
  {
    "uniprot": "X8FBW1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBW1"
  },
  {
    "uniprot": "X8EY90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY90"
  },
  {
    "uniprot": "X8FKQ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKQ5"
  },
  {
    "uniprot": "X8FJK3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJK3"
  },
  {
    "uniprot": "X8FGZ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGZ0"
  },
  {
    "uniprot": "X8F5C1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5C1"
  },
  {
    "uniprot": "X8FL04",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL04"
  },
  {
    "uniprot": "X8F7M8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7M8"
  },
  {
    "uniprot": "X8FEE5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEE5"
  },
  {
    "uniprot": "X8F7N9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7N9"
  },
  {
    "uniprot": "X8EYJ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYJ2"
  },
  {
    "uniprot": "X8F8G2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8G2"
  },
  {
    "uniprot": "X8FJJ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJJ9"
  },
  {
    "uniprot": "X8F6Y5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Y5"
  },
  {
    "uniprot": "X8F7A4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7A4"
  },
  {
    "uniprot": "X8F072",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F072"
  },
  {
    "uniprot": "X8FFH5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFH5"
  },
  {
    "uniprot": "X8F0Q6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Q6"
  },
  {
    "uniprot": "X8FGP5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGP5"
  },
  {
    "uniprot": "X8FFQ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFQ8"
  },
  {
    "uniprot": "X8FDJ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDJ4"
  },
  {
    "uniprot": "X8FND4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FND4"
  },
  {
    "uniprot": "X8F442",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F442"
  },
  {
    "uniprot": "X8F955",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F955"
  },
  {
    "uniprot": "X8EXW8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXW8"
  },
  {
    "uniprot": "X8FJM2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJM2"
  },
  {
    "uniprot": "X8FMU3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMU3"
  },
  {
    "uniprot": "X8FM08",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM08"
  },
  {
    "uniprot": "X8FBS6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBS6"
  },
  {
    "uniprot": "X8FD02",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD02"
  },
  {
    "uniprot": "X8FFE1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFE1"
  },
  {
    "uniprot": "X8FAT3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAT3"
  },
  {
    "uniprot": "X8F0W9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0W9"
  },
  {
    "uniprot": "X8FBE7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBE7"
  },
  {
    "uniprot": "X8FC36",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC36"
  },
  {
    "uniprot": "X8F835",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F835"
  },
  {
    "uniprot": "X8FDE7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDE7"
  },
  {
    "uniprot": "X8F4K3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4K3"
  },
  {
    "uniprot": "X8FM02",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM02"
  },
  {
    "uniprot": "X8FKI3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKI3"
  },
  {
    "uniprot": "X8EXZ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXZ6"
  },
  {
    "uniprot": "X8FHB2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHB2"
  },
  {
    "uniprot": "X8F5U7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5U7"
  },
  {
    "uniprot": "X8FD28",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD28"
  },
  {
    "uniprot": "X8F566",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F566"
  },
  {
    "uniprot": "X8FGM8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGM8"
  },
  {
    "uniprot": "X8F546",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F546"
  },
  {
    "uniprot": "X8FER7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FER7"
  },
  {
    "uniprot": "X8FE60",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE60"
  },
  {
    "uniprot": "X8FK38",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK38"
  },
  {
    "uniprot": "X8FHH4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHH4"
  },
  {
    "uniprot": "X8F3E7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3E7"
  },
  {
    "uniprot": "X8F3F8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3F8"
  },
  {
    "uniprot": "X8FBQ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBQ1"
  },
  {
    "uniprot": "X8FKY4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKY4"
  },
  {
    "uniprot": "X8FJQ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJQ4"
  },
  {
    "uniprot": "X8F6G2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6G2"
  },
  {
    "uniprot": "X8FKI4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKI4"
  },
  {
    "uniprot": "X8EZY6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZY6"
  },
  {
    "uniprot": "X8FI72",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI72"
  },
  {
    "uniprot": "X8FMW2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMW2"
  },
  {
    "uniprot": "X8EW56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EW56"
  },
  {
    "uniprot": "X8FBD3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBD3"
  },
  {
    "uniprot": "X8FNX8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNX8"
  },
  {
    "uniprot": "X8FIT7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIT7"
  },
  {
    "uniprot": "X8FIT0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIT0"
  },
  {
    "uniprot": "X8FG49",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG49"
  },
  {
    "uniprot": "X8EZF6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZF6"
  },
  {
    "uniprot": "X8FL57",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL57"
  },
  {
    "uniprot": "X8FLV4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLV4"
  },
  {
    "uniprot": "X8FAS0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAS0"
  },
  {
    "uniprot": "X8F5N8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5N8"
  },
  {
    "uniprot": "X8F5K0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5K0"
  },
  {
    "uniprot": "X8FEG7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEG7"
  },
  {
    "uniprot": "X8FHK0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHK0"
  },
  {
    "uniprot": "X8F0N0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0N0"
  },
  {
    "uniprot": "X8F837",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F837"
  },
  {
    "uniprot": "X8FJN0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJN0"
  },
  {
    "uniprot": "X8F8K7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8K7"
  },
  {
    "uniprot": "X8F8T9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8T9"
  },
  {
    "uniprot": "X8FL38",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL38"
  },
  {
    "uniprot": "X8FKU7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKU7"
  },
  {
    "uniprot": "X8EZT4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZT4"
  },
  {
    "uniprot": "X8FED0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FED0"
  },
  {
    "uniprot": "X8FI61",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI61"
  },
  {
    "uniprot": "X8FP67",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP67"
  },
  {
    "uniprot": "X8F5J5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5J5"
  },
  {
    "uniprot": "X8F2W1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2W1"
  },
  {
    "uniprot": "X8FAJ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAJ1"
  },
  {
    "uniprot": "X8FLE7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLE7"
  },
  {
    "uniprot": "X8EZC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZC0"
  },
  {
    "uniprot": "X8FKB5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKB5"
  },
  {
    "uniprot": "X8FLC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLC0"
  },
  {
    "uniprot": "X8FMT4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMT4"
  },
  {
    "uniprot": "X8F449",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F449"
  },
  {
    "uniprot": "X8FIC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIC8"
  },
  {
    "uniprot": "X8F214",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F214"
  },
  {
    "uniprot": "X8FII0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FII0"
  },
  {
    "uniprot": "X8F8A6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8A6"
  },
  {
    "uniprot": "X8F1F2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1F2"
  },
  {
    "uniprot": "X8F3U3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3U3"
  },
  {
    "uniprot": "X8FBG1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBG1"
  },
  {
    "uniprot": "X8FJZ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJZ2"
  },
  {
    "uniprot": "X8F5Q5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Q5"
  },
  {
    "uniprot": "X8FDE1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDE1"
  },
  {
    "uniprot": "X8EZF7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZF7"
  },
  {
    "uniprot": "X8EZ91",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ91"
  },
  {
    "uniprot": "X8F6M0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6M0"
  },
  {
    "uniprot": "X8EXM6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXM6"
  },
  {
    "uniprot": "X8FH30",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH30"
  },
  {
    "uniprot": "X8FP06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP06"
  },
  {
    "uniprot": "X8FPY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPY1"
  },
  {
    "uniprot": "X8F8L5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8L5"
  },
  {
    "uniprot": "X8F925",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F925"
  },
  {
    "uniprot": "X8FAR4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAR4"
  },
  {
    "uniprot": "X8F391",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F391"
  },
  {
    "uniprot": "X8F6F4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6F4"
  },
  {
    "uniprot": "X8FJF6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJF6"
  },
  {
    "uniprot": "X8F0L1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0L1"
  },
  {
    "uniprot": "X8FLY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLY1"
  },
  {
    "uniprot": "X8F0J9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0J9"
  },
  {
    "uniprot": "X8FGF9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGF9"
  },
  {
    "uniprot": "X8FGQ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGQ0"
  },
  {
    "uniprot": "X8FE04",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE04"
  },
  {
    "uniprot": "X8F2A2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2A2"
  },
  {
    "uniprot": "X8FGJ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGJ0"
  },
  {
    "uniprot": "X8EX45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX45"
  },
  {
    "uniprot": "X8FIC3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIC3"
  },
  {
    "uniprot": "X8FKG6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKG6"
  },
  {
    "uniprot": "X8FEB2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEB2"
  },
  {
    "uniprot": "X8FDQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDQ6"
  },
  {
    "uniprot": "X8EZJ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZJ0"
  },
  {
    "uniprot": "X8FNS5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNS5"
  },
  {
    "uniprot": "X8F9I2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9I2"
  },
  {
    "uniprot": "X8FLM9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLM9"
  },
  {
    "uniprot": "X8F2S2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2S2"
  },
  {
    "uniprot": "X8FGW1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGW1"
  },
  {
    "uniprot": "X8F9L1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9L1"
  },
  {
    "uniprot": "X8F8G5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8G5"
  },
  {
    "uniprot": "X8FKD8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKD8"
  },
  {
    "uniprot": "X8FNA9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNA9"
  },
  {
    "uniprot": "X8F308",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F308"
  },
  {
    "uniprot": "X8FEV5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEV5"
  },
  {
    "uniprot": "X8EZH4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZH4"
  },
  {
    "uniprot": "X8FDY6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDY6"
  },
  {
    "uniprot": "X8F9Z5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Z5"
  },
  {
    "uniprot": "X8F3Y6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Y6"
  },
  {
    "uniprot": "X8FEM0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEM0"
  },
  {
    "uniprot": "X8EXN1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXN1"
  },
  {
    "uniprot": "X8FNH9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNH9"
  },
  {
    "uniprot": "X8FJP6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJP6"
  },
  {
    "uniprot": "X8FA64",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA64"
  },
  {
    "uniprot": "X8F6M1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6M1"
  },
  {
    "uniprot": "X8EZV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZV0"
  },
  {
    "uniprot": "X8F9Q9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Q9"
  },
  {
    "uniprot": "X8F4Z7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Z7"
  },
  {
    "uniprot": "X8FEN6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEN6"
  },
  {
    "uniprot": "X8FDZ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDZ3"
  },
  {
    "uniprot": "X8EZR7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZR7"
  },
  {
    "uniprot": "X8FKB8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKB8"
  },
  {
    "uniprot": "X8FME1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FME1"
  },
  {
    "uniprot": "X8F0N2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0N2"
  },
  {
    "uniprot": "X8FER6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FER6"
  },
  {
    "uniprot": "X8F8I9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8I9"
  },
  {
    "uniprot": "X8FDT3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDT3"
  },
  {
    "uniprot": "X8EZ23",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ23"
  },
  {
    "uniprot": "X8FDL5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDL5"
  },
  {
    "uniprot": "X8FGW7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGW7"
  },
  {
    "uniprot": "X8FFS2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFS2"
  },
  {
    "uniprot": "X8EWN5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWN5"
  },
  {
    "uniprot": "X8FN00",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN00"
  },
  {
    "uniprot": "X8FHE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHE2"
  },
  {
    "uniprot": "X8FMQ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMQ2"
  },
  {
    "uniprot": "X8FEB1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEB1"
  },
  {
    "uniprot": "X8F7I5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7I5"
  },
  {
    "uniprot": "X8EWE8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWE8"
  },
  {
    "uniprot": "X8F6Q4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Q4"
  },
  {
    "uniprot": "X8F4V5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4V5"
  },
  {
    "uniprot": "X8FLA3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLA3"
  },
  {
    "uniprot": "X8FJI0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJI0"
  },
  {
    "uniprot": "X8FKG2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKG2"
  },
  {
    "uniprot": "X8F0X7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0X7"
  },
  {
    "uniprot": "X8FCS6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCS6"
  },
  {
    "uniprot": "X8FJ71",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ71"
  },
  {
    "uniprot": "X8F2W6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2W6"
  },
  {
    "uniprot": "X8F9W7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9W7"
  },
  {
    "uniprot": "X8EZR1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZR1"
  },
  {
    "uniprot": "X8FLY0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLY0"
  },
  {
    "uniprot": "X8F7G2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7G2"
  },
  {
    "uniprot": "X8FBJ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBJ2"
  },
  {
    "uniprot": "X8F3Q3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Q3"
  },
  {
    "uniprot": "X8F8H3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8H3"
  },
  {
    "uniprot": "X8F0J5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0J5"
  },
  {
    "uniprot": "X8FDL1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDL1"
  },
  {
    "uniprot": "X8F919",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F919"
  },
  {
    "uniprot": "X8FGT7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGT7"
  },
  {
    "uniprot": "X8FK23",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK23"
  },
  {
    "uniprot": "X8FDT2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDT2"
  },
  {
    "uniprot": "X8F6Z4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Z4"
  },
  {
    "uniprot": "X8FDT8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDT8"
  },
  {
    "uniprot": "X8FHT9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHT9"
  },
  {
    "uniprot": "X8F6N7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6N7"
  },
  {
    "uniprot": "X8F2D6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2D6"
  },
  {
    "uniprot": "X8F7P1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7P1"
  },
  {
    "uniprot": "X8FAQ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAQ1"
  },
  {
    "uniprot": "X8EXA2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXA2"
  },
  {
    "uniprot": "X8EZP0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZP0"
  },
  {
    "uniprot": "X8FL90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL90"
  },
  {
    "uniprot": "X8FDA2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDA2"
  },
  {
    "uniprot": "X8F9H6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9H6"
  },
  {
    "uniprot": "X8F9W2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9W2"
  },
  {
    "uniprot": "X8F720",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F720"
  },
  {
    "uniprot": "X8FER5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FER5"
  },
  {
    "uniprot": "X8EYT9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYT9"
  },
  {
    "uniprot": "X8FKW7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKW7"
  },
  {
    "uniprot": "X8FMD4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMD4"
  },
  {
    "uniprot": "X8FAI3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAI3"
  },
  {
    "uniprot": "X8FKN1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKN1"
  },
  {
    "uniprot": "X8FKC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKC8"
  },
  {
    "uniprot": "X8FHK9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHK9"
  },
  {
    "uniprot": "X8FHR3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHR3"
  },
  {
    "uniprot": "X8F2X7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2X7"
  },
  {
    "uniprot": "X8EZX6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZX6"
  },
  {
    "uniprot": "X8EYI6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYI6"
  },
  {
    "uniprot": "X8FM72",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM72"
  },
  {
    "uniprot": "X8FKK9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKK9"
  },
  {
    "uniprot": "X8F791",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F791"
  },
  {
    "uniprot": "X8FKC9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKC9"
  },
  {
    "uniprot": "X8FAS2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAS2"
  },
  {
    "uniprot": "X8F9B7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9B7"
  },
  {
    "uniprot": "X8EYG4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYG4"
  },
  {
    "uniprot": "X8FQ48",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQ48"
  },
  {
    "uniprot": "X8F5Y6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Y6"
  },
  {
    "uniprot": "X8FDL2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDL2"
  },
  {
    "uniprot": "X8F9X2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9X2"
  },
  {
    "uniprot": "X8F5Z0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Z0"
  },
  {
    "uniprot": "X8FMV1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMV1"
  },
  {
    "uniprot": "X8EYZ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYZ9"
  },
  {
    "uniprot": "X8F4K9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4K9"
  },
  {
    "uniprot": "X8FFY6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFY6"
  },
  {
    "uniprot": "X8FKF1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKF1"
  },
  {
    "uniprot": "X8FA06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA06"
  },
  {
    "uniprot": "X8FMI1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMI1"
  },
  {
    "uniprot": "X8FDU5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDU5"
  },
  {
    "uniprot": "X8F9P0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9P0"
  },
  {
    "uniprot": "X8F035",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F035"
  },
  {
    "uniprot": "X8F107",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F107"
  },
  {
    "uniprot": "X8F6U9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6U9"
  },
  {
    "uniprot": "X8F638",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F638"
  },
  {
    "uniprot": "X8EZR9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZR9"
  },
  {
    "uniprot": "X8FDP1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDP1"
  },
  {
    "uniprot": "X8FK06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK06"
  },
  {
    "uniprot": "X8FI56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI56"
  },
  {
    "uniprot": "X8FK60",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK60"
  },
  {
    "uniprot": "X8FK35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK35"
  },
  {
    "uniprot": "X8FLM0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLM0"
  },
  {
    "uniprot": "X8FL86",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL86"
  },
  {
    "uniprot": "X8FLL7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLL7"
  },
  {
    "uniprot": "X8FE48",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE48"
  },
  {
    "uniprot": "X8EW50",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EW50"
  },
  {
    "uniprot": "X8FP24",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP24"
  },
  {
    "uniprot": "X8FGS6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGS6"
  },
  {
    "uniprot": "X8FK37",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK37"
  },
  {
    "uniprot": "X8F2T9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2T9"
  },
  {
    "uniprot": "X8F3G3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3G3"
  },
  {
    "uniprot": "X8FGY0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGY0"
  },
  {
    "uniprot": "X8FAQ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAQ4"
  },
  {
    "uniprot": "X8FJX8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJX8"
  },
  {
    "uniprot": "X8FKD4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKD4"
  },
  {
    "uniprot": "X8F6G1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6G1"
  },
  {
    "uniprot": "X8F1R7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1R7"
  },
  {
    "uniprot": "X8FAU8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAU8"
  },
  {
    "uniprot": "X8EZL1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZL1"
  },
  {
    "uniprot": "X8F417",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F417"
  },
  {
    "uniprot": "X8F5Y0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Y0"
  },
  {
    "uniprot": "X8FAK2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAK2"
  },
  {
    "uniprot": "X8FB46",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB46"
  },
  {
    "uniprot": "X8F9G5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9G5"
  },
  {
    "uniprot": "X8F1P0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1P0"
  },
  {
    "uniprot": "X8F7C9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7C9"
  },
  {
    "uniprot": "X8FJ79",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ79"
  },
  {
    "uniprot": "X8F6T1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6T1"
  },
  {
    "uniprot": "X8FJU1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJU1"
  },
  {
    "uniprot": "X8F7H6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7H6"
  },
  {
    "uniprot": "X8F956",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F956"
  },
  {
    "uniprot": "X8FM34",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM34"
  },
  {
    "uniprot": "X8F520",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F520"
  },
  {
    "uniprot": "X8F560",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F560"
  },
  {
    "uniprot": "X8FC24",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC24"
  },
  {
    "uniprot": "X8FNJ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNJ1"
  },
  {
    "uniprot": "X8FIZ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIZ4"
  },
  {
    "uniprot": "X8FAG9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAG9"
  },
  {
    "uniprot": "X8FEN5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEN5"
  },
  {
    "uniprot": "X8FKI8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKI8"
  },
  {
    "uniprot": "X8FBW2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBW2"
  },
  {
    "uniprot": "X8F3V5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3V5"
  },
  {
    "uniprot": "X8F091",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F091"
  },
  {
    "uniprot": "X8FD67",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD67"
  },
  {
    "uniprot": "X8FFP6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFP6"
  },
  {
    "uniprot": "X8F1Z0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Z0"
  },
  {
    "uniprot": "X8FLH5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLH5"
  },
  {
    "uniprot": "X8FEM5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEM5"
  },
  {
    "uniprot": "X8F7N5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7N5"
  },
  {
    "uniprot": "X8F9S6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9S6"
  },
  {
    "uniprot": "X8F6I2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6I2"
  },
  {
    "uniprot": "X8FFI3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFI3"
  },
  {
    "uniprot": "X8F1E8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1E8"
  },
  {
    "uniprot": "X8F8N9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8N9"
  },
  {
    "uniprot": "X8FPN9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPN9"
  },
  {
    "uniprot": "X8F738",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F738"
  },
  {
    "uniprot": "X8F8H7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8H7"
  },
  {
    "uniprot": "X8FKX0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKX0"
  },
  {
    "uniprot": "X8FEY8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEY8"
  },
  {
    "uniprot": "X8FBR7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBR7"
  },
  {
    "uniprot": "X8F7V5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7V5"
  },
  {
    "uniprot": "X8FKT1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKT1"
  },
  {
    "uniprot": "X8EYG1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYG1"
  },
  {
    "uniprot": "X8F175",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F175"
  },
  {
    "uniprot": "X8FJ35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ35"
  },
  {
    "uniprot": "X8FFA7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFA7"
  },
  {
    "uniprot": "X8FMT1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMT1"
  },
  {
    "uniprot": "X8F2J8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2J8"
  },
  {
    "uniprot": "X8FFP1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFP1"
  },
  {
    "uniprot": "X8FG00",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG00"
  },
  {
    "uniprot": "X8FC68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC68"
  },
  {
    "uniprot": "X8F3Y0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Y0"
  },
  {
    "uniprot": "X8FM54",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM54"
  },
  {
    "uniprot": "X8FGX3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGX3"
  },
  {
    "uniprot": "X8F144",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F144"
  },
  {
    "uniprot": "X8F1L0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1L0"
  },
  {
    "uniprot": "X8FAB6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAB6"
  },
  {
    "uniprot": "X8F767",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F767"
  },
  {
    "uniprot": "X8FGH1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGH1"
  },
  {
    "uniprot": "X8FD85",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD85"
  },
  {
    "uniprot": "X8FCP6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCP6"
  },
  {
    "uniprot": "X8FIH4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIH4"
  },
  {
    "uniprot": "X8FL79",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL79"
  },
  {
    "uniprot": "X8F9N9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9N9"
  },
  {
    "uniprot": "X8FEQ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEQ4"
  },
  {
    "uniprot": "X8FED5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FED5"
  },
  {
    "uniprot": "X8FD20",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD20"
  },
  {
    "uniprot": "X8FEY2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEY2"
  },
  {
    "uniprot": "X8F3A6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3A6"
  },
  {
    "uniprot": "X8F3J5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3J5"
  },
  {
    "uniprot": "X8FLF1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLF1"
  },
  {
    "uniprot": "X8FLB4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLB4"
  },
  {
    "uniprot": "X8F7T6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7T6"
  },
  {
    "uniprot": "X8FBK0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBK0"
  },
  {
    "uniprot": "X8F645",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F645"
  },
  {
    "uniprot": "X8F3P1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3P1"
  },
  {
    "uniprot": "X8F5Z3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Z3"
  },
  {
    "uniprot": "X8FB53",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB53"
  },
  {
    "uniprot": "X8F499",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F499"
  },
  {
    "uniprot": "X8EZJ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZJ6"
  },
  {
    "uniprot": "X8F345",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F345"
  },
  {
    "uniprot": "X8F0Z3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Z3"
  },
  {
    "uniprot": "X8FD01",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD01"
  },
  {
    "uniprot": "X8F1W7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1W7"
  },
  {
    "uniprot": "X8FL91",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL91"
  },
  {
    "uniprot": "X8FK62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK62"
  },
  {
    "uniprot": "X8EYR3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYR3"
  },
  {
    "uniprot": "X8EZD4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZD4"
  },
  {
    "uniprot": "X8FNF0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNF0"
  },
  {
    "uniprot": "X8FML5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FML5"
  },
  {
    "uniprot": "X8EWR9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWR9"
  },
  {
    "uniprot": "X8EXQ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXQ5"
  },
  {
    "uniprot": "X8F7L0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7L0"
  },
  {
    "uniprot": "X8EZG7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZG7"
  },
  {
    "uniprot": "X8FEL6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEL6"
  },
  {
    "uniprot": "X8F680",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F680"
  },
  {
    "uniprot": "X8FN73",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN73"
  },
  {
    "uniprot": "X8FPF4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPF4"
  },
  {
    "uniprot": "X8F5Q4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Q4"
  },
  {
    "uniprot": "X8FIV4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIV4"
  },
  {
    "uniprot": "X8F894",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F894"
  },
  {
    "uniprot": "X8FJ00",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ00"
  },
  {
    "uniprot": "X8FMR4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMR4"
  },
  {
    "uniprot": "X8F2A7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2A7"
  },
  {
    "uniprot": "X8FI40",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI40"
  },
  {
    "uniprot": "X8EW85",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EW85"
  },
  {
    "uniprot": "X8EZA0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZA0"
  },
  {
    "uniprot": "X8FKE3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKE3"
  },
  {
    "uniprot": "X8EZA3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZA3"
  },
  {
    "uniprot": "X8FMJ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMJ6"
  },
  {
    "uniprot": "X8F9Z6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Z6"
  },
  {
    "uniprot": "X8FL26",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL26"
  },
  {
    "uniprot": "X8FET9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FET9"
  },
  {
    "uniprot": "X8FQA5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQA5"
  },
  {
    "uniprot": "X8FP94",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP94"
  },
  {
    "uniprot": "X8F3J8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3J8"
  },
  {
    "uniprot": "X8EXX3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXX3"
  },
  {
    "uniprot": "X8FF65",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF65"
  },
  {
    "uniprot": "X8F5X3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5X3"
  },
  {
    "uniprot": "X8FAX8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAX8"
  },
  {
    "uniprot": "X8F421",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F421"
  },
  {
    "uniprot": "X8FHW2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHW2"
  },
  {
    "uniprot": "X8FIC6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIC6"
  },
  {
    "uniprot": "X8FLZ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLZ4"
  },
  {
    "uniprot": "X8FJ68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ68"
  },
  {
    "uniprot": "X8F3U6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3U6"
  },
  {
    "uniprot": "X8F2V0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2V0"
  },
  {
    "uniprot": "X8F3W6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3W6"
  },
  {
    "uniprot": "X8FFT7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFT7"
  },
  {
    "uniprot": "X8FKM6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKM6"
  },
  {
    "uniprot": "X8FLX9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLX9"
  },
  {
    "uniprot": "X8FIB8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIB8"
  },
  {
    "uniprot": "X8F5J8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5J8"
  },
  {
    "uniprot": "X8FD33",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD33"
  },
  {
    "uniprot": "X8F5K7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5K7"
  },
  {
    "uniprot": "X8FDX6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDX6"
  },
  {
    "uniprot": "X8F4B3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4B3"
  },
  {
    "uniprot": "X8F951",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F951"
  },
  {
    "uniprot": "X8F0V1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0V1"
  },
  {
    "uniprot": "X8F491",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F491"
  },
  {
    "uniprot": "X8F802",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F802"
  },
  {
    "uniprot": "X8F344",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F344"
  },
  {
    "uniprot": "X8F8A8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8A8"
  },
  {
    "uniprot": "X8F772",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F772"
  },
  {
    "uniprot": "X8FIZ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIZ8"
  },
  {
    "uniprot": "X8F4Q5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Q5"
  },
  {
    "uniprot": "X8EYB8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYB8"
  },
  {
    "uniprot": "X8FKA0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKA0"
  },
  {
    "uniprot": "X8F6P3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6P3"
  },
  {
    "uniprot": "X8FJC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJC0"
  },
  {
    "uniprot": "X8F050",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F050"
  },
  {
    "uniprot": "X8FPR8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPR8"
  },
  {
    "uniprot": "X8FJK8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJK8"
  },
  {
    "uniprot": "X8FML9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FML9"
  },
  {
    "uniprot": "X8F9X7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9X7"
  },
  {
    "uniprot": "X8FFS9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFS9"
  },
  {
    "uniprot": "X8F165",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F165"
  },
  {
    "uniprot": "X8FP10",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP10"
  },
  {
    "uniprot": "X8FA59",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA59"
  },
  {
    "uniprot": "X8FE63",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE63"
  },
  {
    "uniprot": "X8FHZ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHZ8"
  },
  {
    "uniprot": "X8F8H8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8H8"
  },
  {
    "uniprot": "X8FA81",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA81"
  },
  {
    "uniprot": "X8FMG0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMG0"
  },
  {
    "uniprot": "X8FM94",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM94"
  },
  {
    "uniprot": "X8F4C0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4C0"
  },
  {
    "uniprot": "X8FE87",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE87"
  },
  {
    "uniprot": "X8F205",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F205"
  },
  {
    "uniprot": "X8FFW7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFW7"
  },
  {
    "uniprot": "X8FGQ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGQ3"
  },
  {
    "uniprot": "X8FJU8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJU8"
  },
  {
    "uniprot": "X8FEV2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEV2"
  },
  {
    "uniprot": "X8FB03",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB03"
  },
  {
    "uniprot": "X8EZ02",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ02"
  },
  {
    "uniprot": "X8F1A4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1A4"
  },
  {
    "uniprot": "X8FD82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD82"
  },
  {
    "uniprot": "X8F1E5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1E5"
  },
  {
    "uniprot": "X8F0Z8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Z8"
  },
  {
    "uniprot": "X8FJY4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJY4"
  },
  {
    "uniprot": "X8FC72",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC72"
  },
  {
    "uniprot": "X8FGT3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGT3"
  },
  {
    "uniprot": "X8EWZ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWZ9"
  },
  {
    "uniprot": "X8F5A1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5A1"
  },
  {
    "uniprot": "X8F242",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F242"
  },
  {
    "uniprot": "X8F9J5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9J5"
  },
  {
    "uniprot": "X8F972",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F972"
  },
  {
    "uniprot": "X8F5B7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5B7"
  },
  {
    "uniprot": "X8FFS5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFS5"
  },
  {
    "uniprot": "X8FCM3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCM3"
  },
  {
    "uniprot": "X8FE68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE68"
  },
  {
    "uniprot": "X8F447",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F447"
  },
  {
    "uniprot": "X8FP95",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP95"
  },
  {
    "uniprot": "X8FGR0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGR0"
  },
  {
    "uniprot": "X8F0J1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0J1"
  },
  {
    "uniprot": "X8FN97",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN97"
  },
  {
    "uniprot": "X8F5P6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5P6"
  },
  {
    "uniprot": "X8F6L0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6L0"
  },
  {
    "uniprot": "X8F433",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F433"
  },
  {
    "uniprot": "X8F1D3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1D3"
  },
  {
    "uniprot": "X8EZE3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZE3"
  },
  {
    "uniprot": "X8FEM8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEM8"
  },
  {
    "uniprot": "X8FJ72",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ72"
  },
  {
    "uniprot": "X8FFG6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFG6"
  },
  {
    "uniprot": "X8FFA2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFA2"
  },
  {
    "uniprot": "X8F0E4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0E4"
  },
  {
    "uniprot": "X8EZI1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZI1"
  },
  {
    "uniprot": "X8FC34",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC34"
  },
  {
    "uniprot": "X8FG14",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG14"
  },
  {
    "uniprot": "X8FFU9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFU9"
  },
  {
    "uniprot": "X8F527",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F527"
  },
  {
    "uniprot": "X8F2B8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2B8"
  },
  {
    "uniprot": "X8FI39",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI39"
  },
  {
    "uniprot": "X8F7T0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7T0"
  },
  {
    "uniprot": "X8FG29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG29"
  },
  {
    "uniprot": "X8FBL9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBL9"
  },
  {
    "uniprot": "X8FFL6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFL6"
  },
  {
    "uniprot": "X8EZ69",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ69"
  },
  {
    "uniprot": "X8EYM9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYM9"
  },
  {
    "uniprot": "X8FA79",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA79"
  },
  {
    "uniprot": "X8F401",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F401"
  },
  {
    "uniprot": "X8F2Y4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Y4"
  },
  {
    "uniprot": "X8F5Z6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Z6"
  },
  {
    "uniprot": "X8F5L7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5L7"
  },
  {
    "uniprot": "X8F3G4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3G4"
  },
  {
    "uniprot": "X8F6J7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6J7"
  },
  {
    "uniprot": "X8F7K7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7K7"
  },
  {
    "uniprot": "X8F656",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F656"
  },
  {
    "uniprot": "X8F068",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F068"
  },
  {
    "uniprot": "X8FK44",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK44"
  },
  {
    "uniprot": "X8FQH9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQH9"
  },
  {
    "uniprot": "X8FN94",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN94"
  },
  {
    "uniprot": "X8EZQ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZQ7"
  },
  {
    "uniprot": "X8EZU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZU0"
  },
  {
    "uniprot": "X8F095",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F095"
  },
  {
    "uniprot": "X8F6E2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6E2"
  },
  {
    "uniprot": "X8FIR2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIR2"
  },
  {
    "uniprot": "X8FNI4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNI4"
  },
  {
    "uniprot": "X8FB64",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB64"
  },
  {
    "uniprot": "X8FN77",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN77"
  },
  {
    "uniprot": "X8F7A1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7A1"
  },
  {
    "uniprot": "X8FKD6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKD6"
  },
  {
    "uniprot": "X8F3N4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3N4"
  },
  {
    "uniprot": "X8F874",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F874"
  },
  {
    "uniprot": "X8FG72",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG72"
  },
  {
    "uniprot": "X8FAW5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAW5"
  },
  {
    "uniprot": "X8FJS0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJS0"
  },
  {
    "uniprot": "X8F7K4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7K4"
  },
  {
    "uniprot": "X8F647",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F647"
  },
  {
    "uniprot": "X8FJU9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJU9"
  },
  {
    "uniprot": "X8FN50",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN50"
  },
  {
    "uniprot": "X8F5G6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5G6"
  },
  {
    "uniprot": "X8F4Z5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Z5"
  },
  {
    "uniprot": "X8FJB4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJB4"
  },
  {
    "uniprot": "X8F586",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F586"
  },
  {
    "uniprot": "X8F485",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F485"
  },
  {
    "uniprot": "X8F817",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F817"
  },
  {
    "uniprot": "X8FIG3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIG3"
  },
  {
    "uniprot": "X8FEN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEN8"
  },
  {
    "uniprot": "X8F8U4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8U4"
  },
  {
    "uniprot": "X8F4U9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4U9"
  },
  {
    "uniprot": "X8FF94",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF94"
  },
  {
    "uniprot": "X8EYS8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYS8"
  },
  {
    "uniprot": "X8F846",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F846"
  },
  {
    "uniprot": "X8FE03",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE03"
  },
  {
    "uniprot": "X8FCL6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCL6"
  },
  {
    "uniprot": "X8EY16",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY16"
  },
  {
    "uniprot": "X8EZM8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZM8"
  },
  {
    "uniprot": "X8F8H0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8H0"
  },
  {
    "uniprot": "X8FI24",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI24"
  },
  {
    "uniprot": "X8F8G8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8G8"
  },
  {
    "uniprot": "X8FH08",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH08"
  },
  {
    "uniprot": "X8F2M2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2M2"
  },
  {
    "uniprot": "X8F7J8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7J8"
  },
  {
    "uniprot": "X8FGX6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGX6"
  },
  {
    "uniprot": "X8FPM8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPM8"
  },
  {
    "uniprot": "X8FGF0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGF0"
  },
  {
    "uniprot": "X8F999",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F999"
  },
  {
    "uniprot": "X8F5W9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5W9"
  },
  {
    "uniprot": "X8EZ60",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ60"
  },
  {
    "uniprot": "X8FHC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHC0"
  },
  {
    "uniprot": "X8EYW3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYW3"
  },
  {
    "uniprot": "X8FDM2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDM2"
  },
  {
    "uniprot": "X8FIZ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIZ2"
  },
  {
    "uniprot": "X8FHL0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHL0"
  },
  {
    "uniprot": "X8FE01",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE01"
  },
  {
    "uniprot": "X8F867",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F867"
  },
  {
    "uniprot": "X8F5K6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5K6"
  },
  {
    "uniprot": "X8F6A3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6A3"
  },
  {
    "uniprot": "X8F2G7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2G7"
  },
  {
    "uniprot": "X8F1A7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1A7"
  },
  {
    "uniprot": "X8FC98",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC98"
  },
  {
    "uniprot": "X8FDS4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDS4"
  },
  {
    "uniprot": "X8FAB4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAB4"
  },
  {
    "uniprot": "X8F6K8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6K8"
  },
  {
    "uniprot": "X8FJH5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJH5"
  },
  {
    "uniprot": "X8F602",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F602"
  },
  {
    "uniprot": "X8FGG1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGG1"
  },
  {
    "uniprot": "X8FL47",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL47"
  },
  {
    "uniprot": "X8FGY2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGY2"
  },
  {
    "uniprot": "X8F3V6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3V6"
  },
  {
    "uniprot": "X8F1P7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1P7"
  },
  {
    "uniprot": "X8FN06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN06"
  },
  {
    "uniprot": "X8FCH0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCH0"
  },
  {
    "uniprot": "X8F058",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F058"
  },
  {
    "uniprot": "X8FI08",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI08"
  },
  {
    "uniprot": "X8FC97",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC97"
  },
  {
    "uniprot": "X8FD05",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD05"
  },
  {
    "uniprot": "X8FC77",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC77"
  },
  {
    "uniprot": "X8FMA9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMA9"
  },
  {
    "uniprot": "X8FFR6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFR6"
  },
  {
    "uniprot": "X8FFC4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFC4"
  },
  {
    "uniprot": "X8F8R5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8R5"
  },
  {
    "uniprot": "X8EYQ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYQ9"
  },
  {
    "uniprot": "X8F8I6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8I6"
  },
  {
    "uniprot": "X8FJC2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJC2"
  },
  {
    "uniprot": "X8EX06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX06"
  },
  {
    "uniprot": "X8F5T6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5T6"
  },
  {
    "uniprot": "X8F6T7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6T7"
  },
  {
    "uniprot": "X8EY28",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY28"
  },
  {
    "uniprot": "X8FCP0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCP0"
  },
  {
    "uniprot": "X8FEP6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEP6"
  },
  {
    "uniprot": "X8F2F6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2F6"
  },
  {
    "uniprot": "X8EZD7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZD7"
  },
  {
    "uniprot": "X8F1M1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1M1"
  },
  {
    "uniprot": "X8F9H3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9H3"
  },
  {
    "uniprot": "X8FGW4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGW4"
  },
  {
    "uniprot": "X8FGH5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGH5"
  },
  {
    "uniprot": "X8FLW8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLW8"
  },
  {
    "uniprot": "X8F8F0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8F0"
  },
  {
    "uniprot": "X8FNK4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNK4"
  },
  {
    "uniprot": "X8F270",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F270"
  },
  {
    "uniprot": "X8FGQ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGQ5"
  },
  {
    "uniprot": "X8F7R7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7R7"
  },
  {
    "uniprot": "X8F4D4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4D4"
  },
  {
    "uniprot": "X8F6L6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6L6"
  },
  {
    "uniprot": "X8FDK2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDK2"
  },
  {
    "uniprot": "X8FH39",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH39"
  },
  {
    "uniprot": "X8FAI4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAI4"
  },
  {
    "uniprot": "X8F380",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F380"
  },
  {
    "uniprot": "X8FBH5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBH5"
  },
  {
    "uniprot": "X8FF16",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF16"
  },
  {
    "uniprot": "X8EWR4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWR4"
  },
  {
    "uniprot": "X8F7L7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7L7"
  },
  {
    "uniprot": "X8F6I0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6I0"
  },
  {
    "uniprot": "X8FPE3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPE3"
  },
  {
    "uniprot": "X8FA74",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA74"
  },
  {
    "uniprot": "X8FDH8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDH8"
  },
  {
    "uniprot": "X8F8X5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8X5"
  },
  {
    "uniprot": "X8F6A0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6A0"
  },
  {
    "uniprot": "X8FDF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDF3"
  },
  {
    "uniprot": "X8FCS8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCS8"
  },
  {
    "uniprot": "X8F973",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F973"
  },
  {
    "uniprot": "X8FFZ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFZ6"
  },
  {
    "uniprot": "X8F9F0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9F0"
  },
  {
    "uniprot": "X8FA27",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA27"
  },
  {
    "uniprot": "X8FJE8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJE8"
  },
  {
    "uniprot": "X8FAE0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAE0"
  },
  {
    "uniprot": "X8F9H4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9H4"
  },
  {
    "uniprot": "X8F8P2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8P2"
  },
  {
    "uniprot": "X8FB69",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB69"
  },
  {
    "uniprot": "X8FQD5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQD5"
  },
  {
    "uniprot": "X8FP21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP21"
  },
  {
    "uniprot": "X8FGC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGC0"
  },
  {
    "uniprot": "X8F0T7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0T7"
  },
  {
    "uniprot": "X8FC52",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC52"
  },
  {
    "uniprot": "X8FCC3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCC3"
  },
  {
    "uniprot": "X8FM75",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM75"
  },
  {
    "uniprot": "X8F4P2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4P2"
  },
  {
    "uniprot": "X8F4H5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4H5"
  },
  {
    "uniprot": "X8FN10",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN10"
  },
  {
    "uniprot": "X8FL62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL62"
  },
  {
    "uniprot": "X8FKE5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKE5"
  },
  {
    "uniprot": "X8F945",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F945"
  },
  {
    "uniprot": "X8F6P7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6P7"
  },
  {
    "uniprot": "X8F9R9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9R9"
  },
  {
    "uniprot": "X8FAY0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAY0"
  },
  {
    "uniprot": "X8FI66",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI66"
  },
  {
    "uniprot": "X8FJK7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJK7"
  },
  {
    "uniprot": "X8F4Y0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Y0"
  },
  {
    "uniprot": "X8FBY8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBY8"
  },
  {
    "uniprot": "X8FH29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH29"
  },
  {
    "uniprot": "X8FDP3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDP3"
  },
  {
    "uniprot": "X8FI97",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI97"
  },
  {
    "uniprot": "X8F085",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F085"
  },
  {
    "uniprot": "X8F7Q6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Q6"
  },
  {
    "uniprot": "X8F9F6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9F6"
  },
  {
    "uniprot": "X8F0L7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0L7"
  },
  {
    "uniprot": "X8FAD5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAD5"
  },
  {
    "uniprot": "X8F5A6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5A6"
  },
  {
    "uniprot": "X8F8J0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8J0"
  },
  {
    "uniprot": "X8FHN6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHN6"
  },
  {
    "uniprot": "X8EZW2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZW2"
  },
  {
    "uniprot": "X8F0N3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0N3"
  },
  {
    "uniprot": "X8F0Q9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Q9"
  },
  {
    "uniprot": "X8FD27",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD27"
  },
  {
    "uniprot": "X8EZF2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZF2"
  },
  {
    "uniprot": "X8FFF9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFF9"
  },
  {
    "uniprot": "X8FKR1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKR1"
  },
  {
    "uniprot": "X8F437",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F437"
  },
  {
    "uniprot": "X8FNL5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNL5"
  },
  {
    "uniprot": "X8F805",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F805"
  },
  {
    "uniprot": "X8FKX2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKX2"
  },
  {
    "uniprot": "X8F439",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F439"
  },
  {
    "uniprot": "X8EW79",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EW79"
  },
  {
    "uniprot": "X8FEW5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEW5"
  },
  {
    "uniprot": "X8FLG3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLG3"
  },
  {
    "uniprot": "X8F276",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F276"
  },
  {
    "uniprot": "X8F8N8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8N8"
  },
  {
    "uniprot": "X8FM20",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM20"
  },
  {
    "uniprot": "X8FMQ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMQ8"
  },
  {
    "uniprot": "X8FD77",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD77"
  },
  {
    "uniprot": "X8FH69",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH69"
  },
  {
    "uniprot": "X8F667",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F667"
  },
  {
    "uniprot": "X8F0F9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0F9"
  },
  {
    "uniprot": "X8F190",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F190"
  },
  {
    "uniprot": "X8FE53",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE53"
  },
  {
    "uniprot": "X8FGP4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGP4"
  },
  {
    "uniprot": "X8F8E9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8E9"
  },
  {
    "uniprot": "X8F8A9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8A9"
  },
  {
    "uniprot": "X8FII7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FII7"
  },
  {
    "uniprot": "X8F4S2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4S2"
  },
  {
    "uniprot": "X8FM51",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM51"
  },
  {
    "uniprot": "X8FLA9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLA9"
  },
  {
    "uniprot": "X8FHZ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHZ4"
  },
  {
    "uniprot": "X8F9S9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9S9"
  },
  {
    "uniprot": "X8FAM9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAM9"
  },
  {
    "uniprot": "X8FD60",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD60"
  },
  {
    "uniprot": "X8F3E2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3E2"
  },
  {
    "uniprot": "X8FGA6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGA6"
  },
  {
    "uniprot": "X8FML2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FML2"
  },
  {
    "uniprot": "X8F2J1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2J1"
  },
  {
    "uniprot": "X8F6W3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6W3"
  },
  {
    "uniprot": "X8F654",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F654"
  },
  {
    "uniprot": "X8FH42",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH42"
  },
  {
    "uniprot": "X8EZS2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZS2"
  },
  {
    "uniprot": "X8FNP4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNP4"
  },
  {
    "uniprot": "X8FF75",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF75"
  },
  {
    "uniprot": "X8FBJ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBJ5"
  },
  {
    "uniprot": "X8EXG8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXG8"
  },
  {
    "uniprot": "X8FHV9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHV9"
  },
  {
    "uniprot": "X8FLX4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLX4"
  },
  {
    "uniprot": "X8FF34",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF34"
  },
  {
    "uniprot": "X8FF04",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF04"
  },
  {
    "uniprot": "X8FBH9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBH9"
  },
  {
    "uniprot": "X8FJA6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJA6"
  },
  {
    "uniprot": "X8F5I7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5I7"
  },
  {
    "uniprot": "X8FGA9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGA9"
  },
  {
    "uniprot": "X8FNR6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNR6"
  },
  {
    "uniprot": "X8F582",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F582"
  },
  {
    "uniprot": "X8FGN7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGN7"
  },
  {
    "uniprot": "X8F5X2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5X2"
  },
  {
    "uniprot": "X8F8Y4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Y4"
  },
  {
    "uniprot": "X8FH26",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH26"
  },
  {
    "uniprot": "X8FAX9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAX9"
  },
  {
    "uniprot": "X8FH04",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH04"
  },
  {
    "uniprot": "X8FFR7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFR7"
  },
  {
    "uniprot": "X8F201",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F201"
  },
  {
    "uniprot": "X8F6D4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6D4"
  },
  {
    "uniprot": "X8F3V2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3V2"
  },
  {
    "uniprot": "X8FDB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDB3"
  },
  {
    "uniprot": "X8FC43",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC43"
  },
  {
    "uniprot": "X8F7R2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7R2"
  },
  {
    "uniprot": "X8FIU2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIU2"
  },
  {
    "uniprot": "X8F5D7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5D7"
  },
  {
    "uniprot": "X8F991",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F991"
  },
  {
    "uniprot": "X8FB25",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB25"
  },
  {
    "uniprot": "X8F1V7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1V7"
  },
  {
    "uniprot": "X8F9L6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9L6"
  },
  {
    "uniprot": "X8FJD1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJD1"
  },
  {
    "uniprot": "X8FHH9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHH9"
  },
  {
    "uniprot": "X8F3N3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3N3"
  },
  {
    "uniprot": "X8FC55",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC55"
  },
  {
    "uniprot": "X8FN01",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN01"
  },
  {
    "uniprot": "X8FN90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN90"
  },
  {
    "uniprot": "X8FFL1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFL1"
  },
  {
    "uniprot": "X8EXR0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXR0"
  },
  {
    "uniprot": "X8F061",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F061"
  },
  {
    "uniprot": "X8FN32",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN32"
  },
  {
    "uniprot": "X8FAR8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAR8"
  },
  {
    "uniprot": "X8FE54",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE54"
  },
  {
    "uniprot": "X8EZQ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZQ2"
  },
  {
    "uniprot": "X8F226",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F226"
  },
  {
    "uniprot": "X8FBF2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBF2"
  },
  {
    "uniprot": "X8FGD4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGD4"
  },
  {
    "uniprot": "X8F6H8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6H8"
  },
  {
    "uniprot": "X8FF68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF68"
  },
  {
    "uniprot": "X8F7E1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7E1"
  },
  {
    "uniprot": "X8F711",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F711"
  },
  {
    "uniprot": "X8EZZ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZZ0"
  },
  {
    "uniprot": "X8F9U6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9U6"
  },
  {
    "uniprot": "X8F5H9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5H9"
  },
  {
    "uniprot": "X8FAR5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAR5"
  },
  {
    "uniprot": "X8FCE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCE2"
  },
  {
    "uniprot": "X8F105",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F105"
  },
  {
    "uniprot": "X8FPV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPV0"
  },
  {
    "uniprot": "X8FEQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEQ6"
  },
  {
    "uniprot": "X8FCN1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCN1"
  },
  {
    "uniprot": "X8FCC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCC0"
  },
  {
    "uniprot": "X8F2A6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2A6"
  },
  {
    "uniprot": "X8FEP3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEP3"
  },
  {
    "uniprot": "X8F4T4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4T4"
  },
  {
    "uniprot": "X8F9B6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9B6"
  },
  {
    "uniprot": "X8FAL2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAL2"
  },
  {
    "uniprot": "X8FIK1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIK1"
  },
  {
    "uniprot": "X8FK68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK68"
  },
  {
    "uniprot": "X8F3U4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3U4"
  },
  {
    "uniprot": "X8F3T6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3T6"
  },
  {
    "uniprot": "X8FJW5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJW5"
  },
  {
    "uniprot": "X8F1N3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1N3"
  },
  {
    "uniprot": "X8EZR3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZR3"
  },
  {
    "uniprot": "X8F6F0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6F0"
  },
  {
    "uniprot": "X8FLQ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLQ0"
  },
  {
    "uniprot": "X8F3C7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3C7"
  },
  {
    "uniprot": "X8FK80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK80"
  },
  {
    "uniprot": "X8F8K0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8K0"
  },
  {
    "uniprot": "X8FAU6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAU6"
  },
  {
    "uniprot": "X8FBX7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBX7"
  },
  {
    "uniprot": "X8FFP3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFP3"
  },
  {
    "uniprot": "X8F102",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F102"
  },
  {
    "uniprot": "X8FCQ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCQ4"
  },
  {
    "uniprot": "X8F207",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F207"
  },
  {
    "uniprot": "X8FFN4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFN4"
  },
  {
    "uniprot": "X8FHF4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHF4"
  },
  {
    "uniprot": "X8F540",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F540"
  },
  {
    "uniprot": "X8FIL5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIL5"
  },
  {
    "uniprot": "X8FFC6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFC6"
  },
  {
    "uniprot": "X8F111",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F111"
  },
  {
    "uniprot": "X8EZH0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZH0"
  },
  {
    "uniprot": "X8FKW2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKW2"
  },
  {
    "uniprot": "X8FFZ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFZ8"
  },
  {
    "uniprot": "X8F865",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F865"
  },
  {
    "uniprot": "X8EZ41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ41"
  },
  {
    "uniprot": "X8EYH7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYH7"
  },
  {
    "uniprot": "X8F2G5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2G5"
  },
  {
    "uniprot": "X8FBT0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBT0"
  },
  {
    "uniprot": "X8FKF7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKF7"
  },
  {
    "uniprot": "X8FL19",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL19"
  },
  {
    "uniprot": "X8F5Y7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Y7"
  },
  {
    "uniprot": "X8FF93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF93"
  },
  {
    "uniprot": "X8F1L1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1L1"
  },
  {
    "uniprot": "X8FAN0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAN0"
  },
  {
    "uniprot": "X8FN64",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN64"
  },
  {
    "uniprot": "X8F0P5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0P5"
  },
  {
    "uniprot": "X8F0M7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0M7"
  },
  {
    "uniprot": "X8F746",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F746"
  },
  {
    "uniprot": "X8FAW6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAW6"
  },
  {
    "uniprot": "X8EZM9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZM9"
  },
  {
    "uniprot": "X8FEB9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEB9"
  },
  {
    "uniprot": "X8F4F4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4F4"
  },
  {
    "uniprot": "X8FDB9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDB9"
  },
  {
    "uniprot": "X8FE24",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE24"
  },
  {
    "uniprot": "X8FJ45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ45"
  },
  {
    "uniprot": "X8F2D2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2D2"
  },
  {
    "uniprot": "X8FIH3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIH3"
  },
  {
    "uniprot": "X8FFY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFY9"
  },
  {
    "uniprot": "X8F6X4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6X4"
  },
  {
    "uniprot": "X8F6Q8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Q8"
  },
  {
    "uniprot": "X8F4B2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4B2"
  },
  {
    "uniprot": "X8F3F3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3F3"
  },
  {
    "uniprot": "X8FHF9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHF9"
  },
  {
    "uniprot": "X8FH95",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH95"
  },
  {
    "uniprot": "X8F6K0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6K0"
  },
  {
    "uniprot": "X8FG42",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG42"
  },
  {
    "uniprot": "X8FF89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF89"
  },
  {
    "uniprot": "X8F2G3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2G3"
  },
  {
    "uniprot": "X8FDS3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDS3"
  },
  {
    "uniprot": "X8FFV2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFV2"
  },
  {
    "uniprot": "X8F830",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F830"
  },
  {
    "uniprot": "X8F1N4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1N4"
  },
  {
    "uniprot": "X8F1M3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1M3"
  },
  {
    "uniprot": "X8F918",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F918"
  },
  {
    "uniprot": "X8FCW4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCW4"
  },
  {
    "uniprot": "X8FIG2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIG2"
  },
  {
    "uniprot": "X8EZ17",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ17"
  },
  {
    "uniprot": "X8FA94",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA94"
  },
  {
    "uniprot": "X8EZ20",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ20"
  },
  {
    "uniprot": "X8F655",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F655"
  },
  {
    "uniprot": "X8FCB0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCB0"
  },
  {
    "uniprot": "X8FKT4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKT4"
  },
  {
    "uniprot": "X8F6D1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6D1"
  },
  {
    "uniprot": "X8FGE3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGE3"
  },
  {
    "uniprot": "X8F9F2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9F2"
  },
  {
    "uniprot": "X8FJA4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJA4"
  },
  {
    "uniprot": "X8FIS0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIS0"
  },
  {
    "uniprot": "X8F5C4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5C4"
  },
  {
    "uniprot": "X8F5Q7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Q7"
  },
  {
    "uniprot": "X8FG78",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG78"
  },
  {
    "uniprot": "X8FPM2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPM2"
  },
  {
    "uniprot": "X8FL52",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL52"
  },
  {
    "uniprot": "X8FBA5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBA5"
  },
  {
    "uniprot": "X8F135",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F135"
  },
  {
    "uniprot": "X8FMM3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMM3"
  },
  {
    "uniprot": "X8F3D0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3D0"
  },
  {
    "uniprot": "X8F2J7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2J7"
  },
  {
    "uniprot": "X8FNV5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNV5"
  },
  {
    "uniprot": "X8F9X5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9X5"
  },
  {
    "uniprot": "X8FI67",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI67"
  },
  {
    "uniprot": "X8F9K5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9K5"
  },
  {
    "uniprot": "X8F6X5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6X5"
  },
  {
    "uniprot": "X8FH34",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH34"
  },
  {
    "uniprot": "X8F025",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F025"
  },
  {
    "uniprot": "X8FFB5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFB5"
  },
  {
    "uniprot": "X8FEE9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEE9"
  },
  {
    "uniprot": "X8FBL0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBL0"
  },
  {
    "uniprot": "X8FNJ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNJ2"
  },
  {
    "uniprot": "X8FNN2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNN2"
  },
  {
    "uniprot": "X8FD69",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD69"
  },
  {
    "uniprot": "X8F6Y9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Y9"
  },
  {
    "uniprot": "X8F9H1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9H1"
  },
  {
    "uniprot": "X8F1S3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1S3"
  },
  {
    "uniprot": "X8EYV9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYV9"
  },
  {
    "uniprot": "X8FLU2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLU2"
  },
  {
    "uniprot": "X8FGK9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGK9"
  },
  {
    "uniprot": "X8FNA8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNA8"
  },
  {
    "uniprot": "X8FDH3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDH3"
  },
  {
    "uniprot": "X8FL01",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL01"
  },
  {
    "uniprot": "X8EWL5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWL5"
  },
  {
    "uniprot": "X8FKJ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKJ5"
  },
  {
    "uniprot": "X8EZE6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZE6"
  },
  {
    "uniprot": "X8FBG0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBG0"
  },
  {
    "uniprot": "X8F3D9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3D9"
  },
  {
    "uniprot": "X8FF03",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF03"
  },
  {
    "uniprot": "X8F4S3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4S3"
  },
  {
    "uniprot": "X8EYF8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYF8"
  },
  {
    "uniprot": "X8FN60",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN60"
  },
  {
    "uniprot": "X8F5V1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5V1"
  },
  {
    "uniprot": "X8F631",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F631"
  },
  {
    "uniprot": "X8F889",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F889"
  },
  {
    "uniprot": "X8FIE5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIE5"
  },
  {
    "uniprot": "X8FP39",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP39"
  },
  {
    "uniprot": "X8EY60",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY60"
  },
  {
    "uniprot": "X8FHV7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHV7"
  },
  {
    "uniprot": "X8F5Q9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Q9"
  },
  {
    "uniprot": "X8F8G3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8G3"
  },
  {
    "uniprot": "X8FG37",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG37"
  },
  {
    "uniprot": "X8EXR8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXR8"
  },
  {
    "uniprot": "X8FAS1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAS1"
  },
  {
    "uniprot": "X8F1Z1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Z1"
  },
  {
    "uniprot": "X8FNL7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNL7"
  },
  {
    "uniprot": "X8FPJ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPJ6"
  },
  {
    "uniprot": "X8FFS8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFS8"
  },
  {
    "uniprot": "X8F6B1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6B1"
  },
  {
    "uniprot": "X8FFL0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFL0"
  },
  {
    "uniprot": "X8EXQ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXQ2"
  },
  {
    "uniprot": "X8F3P9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3P9"
  },
  {
    "uniprot": "X8FJB8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJB8"
  },
  {
    "uniprot": "X8FN92",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN92"
  },
  {
    "uniprot": "X8EZL2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZL2"
  },
  {
    "uniprot": "X8FJQ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJQ7"
  },
  {
    "uniprot": "X8FEK8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEK8"
  },
  {
    "uniprot": "X8F730",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F730"
  },
  {
    "uniprot": "X8F904",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F904"
  },
  {
    "uniprot": "X8FBE9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBE9"
  },
  {
    "uniprot": "X8FJ88",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ88"
  },
  {
    "uniprot": "X8F880",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F880"
  },
  {
    "uniprot": "X8EZ84",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ84"
  },
  {
    "uniprot": "X8F0E9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0E9"
  },
  {
    "uniprot": "X8FA88",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA88"
  },
  {
    "uniprot": "X8FJ19",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ19"
  },
  {
    "uniprot": "X8FM03",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM03"
  },
  {
    "uniprot": "X8FEU3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEU3"
  },
  {
    "uniprot": "X8FAW1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAW1"
  },
  {
    "uniprot": "X8FDQ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDQ7"
  },
  {
    "uniprot": "X8FMT6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMT6"
  },
  {
    "uniprot": "X8F275",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F275"
  },
  {
    "uniprot": "X8F1R2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1R2"
  },
  {
    "uniprot": "X8FLT7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLT7"
  },
  {
    "uniprot": "X8FCQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCQ6"
  },
  {
    "uniprot": "X8FC62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC62"
  },
  {
    "uniprot": "X8F2G6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2G6"
  },
  {
    "uniprot": "X8FBE0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBE0"
  },
  {
    "uniprot": "X8FI71",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI71"
  },
  {
    "uniprot": "X8FCR9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCR9"
  },
  {
    "uniprot": "X8F621",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F621"
  },
  {
    "uniprot": "X8FKN0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKN0"
  },
  {
    "uniprot": "X8FHH5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHH5"
  },
  {
    "uniprot": "X8FAZ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAZ2"
  },
  {
    "uniprot": "X8EYE5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYE5"
  },
  {
    "uniprot": "X8EYQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYQ6"
  },
  {
    "uniprot": "X8FBB0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBB0"
  },
  {
    "uniprot": "X8FH00",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH00"
  },
  {
    "uniprot": "X8FCQ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCQ8"
  },
  {
    "uniprot": "X8F438",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F438"
  },
  {
    "uniprot": "X8FA12",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA12"
  },
  {
    "uniprot": "X8F7H7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7H7"
  },
  {
    "uniprot": "X8EYR2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYR2"
  },
  {
    "uniprot": "X8FF36",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF36"
  },
  {
    "uniprot": "X8EZ39",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ39"
  },
  {
    "uniprot": "X8FLD5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLD5"
  },
  {
    "uniprot": "X8FA56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA56"
  },
  {
    "uniprot": "X8FKA1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKA1"
  },
  {
    "uniprot": "X8FG54",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG54"
  },
  {
    "uniprot": "X8FBX4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBX4"
  },
  {
    "uniprot": "X8FEJ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEJ7"
  },
  {
    "uniprot": "X8FJZ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJZ8"
  },
  {
    "uniprot": "X8FAV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAV8"
  },
  {
    "uniprot": "X8EY43",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY43"
  },
  {
    "uniprot": "X8F5R4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5R4"
  },
  {
    "uniprot": "X8FMB9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMB9"
  },
  {
    "uniprot": "X8FJ07",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ07"
  },
  {
    "uniprot": "X8FK24",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK24"
  },
  {
    "uniprot": "X8F8X9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8X9"
  },
  {
    "uniprot": "X8EZ01",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ01"
  },
  {
    "uniprot": "X8F1X0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1X0"
  },
  {
    "uniprot": "X8F109",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F109"
  },
  {
    "uniprot": "X8FL64",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL64"
  },
  {
    "uniprot": "X8FA86",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA86"
  },
  {
    "uniprot": "X8F7Z2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Z2"
  },
  {
    "uniprot": "X8F2I2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2I2"
  },
  {
    "uniprot": "X8FG73",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG73"
  },
  {
    "uniprot": "X8FB95",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB95"
  },
  {
    "uniprot": "X8FAQ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAQ3"
  },
  {
    "uniprot": "X8F3Z6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Z6"
  },
  {
    "uniprot": "X8F5K4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5K4"
  },
  {
    "uniprot": "X8F3K0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3K0"
  },
  {
    "uniprot": "X8EZL4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZL4"
  },
  {
    "uniprot": "X8F431",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F431"
  },
  {
    "uniprot": "X8F4Q2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Q2"
  },
  {
    "uniprot": "X8FNR7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNR7"
  },
  {
    "uniprot": "X8F452",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F452"
  },
  {
    "uniprot": "X8F188",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F188"
  },
  {
    "uniprot": "X8F581",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F581"
  },
  {
    "uniprot": "X8FMI4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMI4"
  },
  {
    "uniprot": "X8FCI3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCI3"
  },
  {
    "uniprot": "X8FNA7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNA7"
  },
  {
    "uniprot": "X8EZB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZB3"
  },
  {
    "uniprot": "X8F8U9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8U9"
  },
  {
    "uniprot": "X8FFW1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFW1"
  },
  {
    "uniprot": "X8FAN7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAN7"
  },
  {
    "uniprot": "X8F363",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F363"
  },
  {
    "uniprot": "X8F1G7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1G7"
  },
  {
    "uniprot": "X8F092",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F092"
  },
  {
    "uniprot": "X8FDP0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDP0"
  },
  {
    "uniprot": "X8F3C5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3C5"
  },
  {
    "uniprot": "X8F1K7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1K7"
  },
  {
    "uniprot": "X8F229",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F229"
  },
  {
    "uniprot": "X8FIC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIC0"
  },
  {
    "uniprot": "X8FDA1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDA1"
  },
  {
    "uniprot": "X8F137",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F137"
  },
  {
    "uniprot": "X8FQ89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQ89"
  },
  {
    "uniprot": "X8FFK0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFK0"
  },
  {
    "uniprot": "X8F735",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F735"
  },
  {
    "uniprot": "X8F2L4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2L4"
  },
  {
    "uniprot": "X8FM92",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM92"
  },
  {
    "uniprot": "X8FG19",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG19"
  },
  {
    "uniprot": "X8F479",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F479"
  },
  {
    "uniprot": "X8FAL5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAL5"
  },
  {
    "uniprot": "X8F337",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F337"
  },
  {
    "uniprot": "X8F710",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F710"
  },
  {
    "uniprot": "X8F9T7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9T7"
  },
  {
    "uniprot": "X8F0G7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0G7"
  },
  {
    "uniprot": "X8FKA4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKA4"
  },
  {
    "uniprot": "X8FCY4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCY4"
  },
  {
    "uniprot": "X8FJL8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJL8"
  },
  {
    "uniprot": "X8FJZ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJZ3"
  },
  {
    "uniprot": "X8F0T8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0T8"
  },
  {
    "uniprot": "X8F7T7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7T7"
  },
  {
    "uniprot": "X8F9D2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9D2"
  },
  {
    "uniprot": "X8FI77",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI77"
  },
  {
    "uniprot": "X8FBA6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBA6"
  },
  {
    "uniprot": "X8FNG5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNG5"
  },
  {
    "uniprot": "X8F1L5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1L5"
  },
  {
    "uniprot": "X8FIW9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIW9"
  },
  {
    "uniprot": "X8EZK7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZK7"
  },
  {
    "uniprot": "X8F149",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F149"
  },
  {
    "uniprot": "X8FMW3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMW3"
  },
  {
    "uniprot": "X8F7N1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7N1"
  },
  {
    "uniprot": "X8F3E1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3E1"
  },
  {
    "uniprot": "X8F307",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F307"
  },
  {
    "uniprot": "X8FMZ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMZ6"
  },
  {
    "uniprot": "X8FNL0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNL0"
  },
  {
    "uniprot": "X8FF41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF41"
  },
  {
    "uniprot": "X8EW89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EW89"
  },
  {
    "uniprot": "X8F4F2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4F2"
  },
  {
    "uniprot": "X8F283",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F283"
  },
  {
    "uniprot": "X8FKR7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKR7"
  },
  {
    "uniprot": "X8F322",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F322"
  },
  {
    "uniprot": "X8FKA9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKA9"
  },
  {
    "uniprot": "X8F9D7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9D7"
  },
  {
    "uniprot": "X8F7W0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7W0"
  },
  {
    "uniprot": "X8FK58",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK58"
  },
  {
    "uniprot": "X8F890",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F890"
  },
  {
    "uniprot": "X8FE34",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE34"
  },
  {
    "uniprot": "X8FP53",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP53"
  },
  {
    "uniprot": "X8FM30",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM30"
  },
  {
    "uniprot": "X8FHN4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHN4"
  },
  {
    "uniprot": "X8FCT3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCT3"
  },
  {
    "uniprot": "X8FQG3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQG3"
  },
  {
    "uniprot": "X8FJK5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJK5"
  },
  {
    "uniprot": "X8FHQ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHQ9"
  },
  {
    "uniprot": "X8FM26",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM26"
  },
  {
    "uniprot": "X8F8R2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8R2"
  },
  {
    "uniprot": "X8FH19",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH19"
  },
  {
    "uniprot": "X8F766",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F766"
  },
  {
    "uniprot": "X8F1P6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1P6"
  },
  {
    "uniprot": "X8FQ05",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQ05"
  },
  {
    "uniprot": "X8FHQ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHQ2"
  },
  {
    "uniprot": "X8FK93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK93"
  },
  {
    "uniprot": "X8F2Q1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Q1"
  },
  {
    "uniprot": "X8FFG8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFG8"
  },
  {
    "uniprot": "X8FJ13",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ13"
  },
  {
    "uniprot": "X8F6S8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6S8"
  },
  {
    "uniprot": "X8FE28",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE28"
  },
  {
    "uniprot": "X8FLU6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLU6"
  },
  {
    "uniprot": "X8FCI7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCI7"
  },
  {
    "uniprot": "X8FBD2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBD2"
  },
  {
    "uniprot": "X8F067",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F067"
  },
  {
    "uniprot": "X8F797",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F797"
  },
  {
    "uniprot": "X8F1W6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1W6"
  },
  {
    "uniprot": "X8EXV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXV8"
  },
  {
    "uniprot": "X8FAU5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAU5"
  },
  {
    "uniprot": "X8F4W3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4W3"
  },
  {
    "uniprot": "X8FF86",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF86"
  },
  {
    "uniprot": "X8F3A7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3A7"
  },
  {
    "uniprot": "X8F321",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F321"
  },
  {
    "uniprot": "X8F661",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F661"
  },
  {
    "uniprot": "X8FK15",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK15"
  },
  {
    "uniprot": "X8F2D3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2D3"
  },
  {
    "uniprot": "X8F1X5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1X5"
  },
  {
    "uniprot": "X8FEE1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEE1"
  },
  {
    "uniprot": "X8FNP1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNP1"
  },
  {
    "uniprot": "X8FHB9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHB9"
  },
  {
    "uniprot": "X8FFX9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFX9"
  },
  {
    "uniprot": "X8FNX1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNX1"
  },
  {
    "uniprot": "X8F078",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F078"
  },
  {
    "uniprot": "X8FL48",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL48"
  },
  {
    "uniprot": "X8FF80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF80"
  },
  {
    "uniprot": "X8EYX7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYX7"
  },
  {
    "uniprot": "X8F6K9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6K9"
  },
  {
    "uniprot": "X8F8Y3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Y3"
  },
  {
    "uniprot": "X8F4K5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4K5"
  },
  {
    "uniprot": "X8FHQ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHQ0"
  },
  {
    "uniprot": "X8FL30",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL30"
  },
  {
    "uniprot": "X8F8A7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8A7"
  },
  {
    "uniprot": "X8FEL3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEL3"
  },
  {
    "uniprot": "X8F9M8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9M8"
  },
  {
    "uniprot": "X8F8N7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8N7"
  },
  {
    "uniprot": "X8F726",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F726"
  },
  {
    "uniprot": "X8F2H3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2H3"
  },
  {
    "uniprot": "X8FBN0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBN0"
  },
  {
    "uniprot": "X8F9V4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9V4"
  },
  {
    "uniprot": "X8F7J6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7J6"
  },
  {
    "uniprot": "X8F4E2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4E2"
  },
  {
    "uniprot": "X8FKN2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKN2"
  },
  {
    "uniprot": "X8F9F4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9F4"
  },
  {
    "uniprot": "X8EZZ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZZ3"
  },
  {
    "uniprot": "X8F038",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F038"
  },
  {
    "uniprot": "X8F0Y6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Y6"
  },
  {
    "uniprot": "X8F6M3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6M3"
  },
  {
    "uniprot": "X8F5F2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5F2"
  },
  {
    "uniprot": "X8FN99",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN99"
  },
  {
    "uniprot": "X8FJY2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJY2"
  },
  {
    "uniprot": "X8FEC2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEC2"
  },
  {
    "uniprot": "X8FHD8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHD8"
  },
  {
    "uniprot": "X8F6N8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6N8"
  },
  {
    "uniprot": "X8FID3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FID3"
  },
  {
    "uniprot": "X8F169",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F169"
  },
  {
    "uniprot": "X8FG90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG90"
  },
  {
    "uniprot": "X8FGA5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGA5"
  },
  {
    "uniprot": "X8FF01",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF01"
  },
  {
    "uniprot": "X8F4L1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4L1"
  },
  {
    "uniprot": "X8FL97",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL97"
  },
  {
    "uniprot": "X8F7A7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7A7"
  },
  {
    "uniprot": "X8F7S0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7S0"
  },
  {
    "uniprot": "X8FE46",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE46"
  },
  {
    "uniprot": "X8FLH0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLH0"
  },
  {
    "uniprot": "X8FFF6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFF6"
  },
  {
    "uniprot": "X8FJ51",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ51"
  },
  {
    "uniprot": "X8FC91",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC91"
  },
  {
    "uniprot": "X8FCR7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCR7"
  },
  {
    "uniprot": "X8F151",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F151"
  },
  {
    "uniprot": "X8EY79",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY79"
  },
  {
    "uniprot": "X8F233",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F233"
  },
  {
    "uniprot": "X8FLV5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLV5"
  },
  {
    "uniprot": "X8F5Y1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Y1"
  },
  {
    "uniprot": "X8FG33",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG33"
  },
  {
    "uniprot": "X8FDP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDP9"
  },
  {
    "uniprot": "X8FEB4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEB4"
  },
  {
    "uniprot": "X8F6F3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6F3"
  },
  {
    "uniprot": "X8FFX6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFX6"
  },
  {
    "uniprot": "X8FAP3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAP3"
  },
  {
    "uniprot": "X8FBV9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBV9"
  },
  {
    "uniprot": "X8F9H8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9H8"
  },
  {
    "uniprot": "X8F2J6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2J6"
  },
  {
    "uniprot": "X8FDC5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDC5"
  },
  {
    "uniprot": "X8FMA3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMA3"
  },
  {
    "uniprot": "X8FI12",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI12"
  },
  {
    "uniprot": "X8FAL0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAL0"
  },
  {
    "uniprot": "X8F5H8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5H8"
  },
  {
    "uniprot": "X8EZF8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZF8"
  },
  {
    "uniprot": "X8FL10",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL10"
  },
  {
    "uniprot": "X8FDQ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDQ0"
  },
  {
    "uniprot": "X8FEJ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEJ1"
  },
  {
    "uniprot": "X8F297",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F297"
  },
  {
    "uniprot": "X8F4L6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4L6"
  },
  {
    "uniprot": "X8F0C4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0C4"
  },
  {
    "uniprot": "X8FLS3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLS3"
  },
  {
    "uniprot": "X8FF84",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF84"
  },
  {
    "uniprot": "X8FBV4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBV4"
  },
  {
    "uniprot": "X8FEC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEC7"
  },
  {
    "uniprot": "X8FE27",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE27"
  },
  {
    "uniprot": "X8F115",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F115"
  },
  {
    "uniprot": "X8F5R7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5R7"
  },
  {
    "uniprot": "X8F6F5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6F5"
  },
  {
    "uniprot": "X8FN76",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN76"
  },
  {
    "uniprot": "X8F6S5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6S5"
  },
  {
    "uniprot": "X8FD11",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD11"
  },
  {
    "uniprot": "X8F9M2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9M2"
  },
  {
    "uniprot": "X8FCV7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCV7"
  },
  {
    "uniprot": "X8EYB6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYB6"
  },
  {
    "uniprot": "X8FCS5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCS5"
  },
  {
    "uniprot": "X8FFN9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFN9"
  },
  {
    "uniprot": "X8FK16",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK16"
  },
  {
    "uniprot": "X8FBG9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBG9"
  },
  {
    "uniprot": "X8FN29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN29"
  },
  {
    "uniprot": "X8FH89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH89"
  },
  {
    "uniprot": "X8F9F8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9F8"
  },
  {
    "uniprot": "X8FDV5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDV5"
  },
  {
    "uniprot": "X8FL34",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL34"
  },
  {
    "uniprot": "X8FBG8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBG8"
  },
  {
    "uniprot": "X8FBI2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBI2"
  },
  {
    "uniprot": "X8FK46",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK46"
  },
  {
    "uniprot": "X8FFM0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFM0"
  },
  {
    "uniprot": "X8FET6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FET6"
  },
  {
    "uniprot": "X8F0J8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0J8"
  },
  {
    "uniprot": "X8FN27",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN27"
  },
  {
    "uniprot": "X8FFI8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFI8"
  },
  {
    "uniprot": "X8F5E6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5E6"
  },
  {
    "uniprot": "X8F575",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F575"
  },
  {
    "uniprot": "X8F9X3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9X3"
  },
  {
    "uniprot": "X8FE00",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE00"
  },
  {
    "uniprot": "X8FEI2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEI2"
  },
  {
    "uniprot": "X8EZS8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZS8"
  },
  {
    "uniprot": "X8FKF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKF3"
  },
  {
    "uniprot": "X8F122",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F122"
  },
  {
    "uniprot": "X8F8F5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8F5"
  },
  {
    "uniprot": "X8FD49",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD49"
  },
  {
    "uniprot": "X8FBS1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBS1"
  },
  {
    "uniprot": "X8FJD8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJD8"
  },
  {
    "uniprot": "X8F8W4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8W4"
  },
  {
    "uniprot": "X8FGY4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGY4"
  },
  {
    "uniprot": "X8EZY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZY5"
  },
  {
    "uniprot": "X8FJX9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJX9"
  },
  {
    "uniprot": "X8FFQ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFQ4"
  },
  {
    "uniprot": "X8FJB1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJB1"
  },
  {
    "uniprot": "X8FJ62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ62"
  },
  {
    "uniprot": "X8EXK4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXK4"
  },
  {
    "uniprot": "X8F021",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F021"
  },
  {
    "uniprot": "X8F8C4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8C4"
  },
  {
    "uniprot": "X8FDL8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDL8"
  },
  {
    "uniprot": "X8F007",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F007"
  },
  {
    "uniprot": "X8EWT5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWT5"
  },
  {
    "uniprot": "X8F3Z8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Z8"
  },
  {
    "uniprot": "X8FA08",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA08"
  },
  {
    "uniprot": "X8FDJ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDJ6"
  },
  {
    "uniprot": "X8F7N8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7N8"
  },
  {
    "uniprot": "X8FJ29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ29"
  },
  {
    "uniprot": "X8FCL4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCL4"
  },
  {
    "uniprot": "X8FLR3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLR3"
  },
  {
    "uniprot": "X8F4J7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4J7"
  },
  {
    "uniprot": "X8FDT0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDT0"
  },
  {
    "uniprot": "X8FK90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK90"
  },
  {
    "uniprot": "X8FGF8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGF8"
  },
  {
    "uniprot": "X8FJS1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJS1"
  },
  {
    "uniprot": "X8F7M7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7M7"
  },
  {
    "uniprot": "X8FPX6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPX6"
  },
  {
    "uniprot": "X8F6G7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6G7"
  },
  {
    "uniprot": "X8FGW8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGW8"
  },
  {
    "uniprot": "X8F121",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F121"
  },
  {
    "uniprot": "X8F3M1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3M1"
  },
  {
    "uniprot": "X8FK57",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK57"
  },
  {
    "uniprot": "X8FA72",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA72"
  },
  {
    "uniprot": "X8F166",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F166"
  },
  {
    "uniprot": "X8FGD1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGD1"
  },
  {
    "uniprot": "X8FNT6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNT6"
  },
  {
    "uniprot": "X8F185",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F185"
  },
  {
    "uniprot": "X8F5H5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5H5"
  },
  {
    "uniprot": "X8FLM8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLM8"
  },
  {
    "uniprot": "X8EY59",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY59"
  },
  {
    "uniprot": "X8FA70",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA70"
  },
  {
    "uniprot": "X8FBW6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBW6"
  },
  {
    "uniprot": "X8F251",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F251"
  },
  {
    "uniprot": "X8EZ52",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ52"
  },
  {
    "uniprot": "X8FAR0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAR0"
  },
  {
    "uniprot": "X8F4N8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4N8"
  },
  {
    "uniprot": "X8F0K0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0K0"
  },
  {
    "uniprot": "X8F389",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F389"
  },
  {
    "uniprot": "X8F0T5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0T5"
  },
  {
    "uniprot": "X8F001",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F001"
  },
  {
    "uniprot": "X8F4V3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4V3"
  },
  {
    "uniprot": "X8FNA3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNA3"
  },
  {
    "uniprot": "X8FEI4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEI4"
  },
  {
    "uniprot": "X8FGM9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGM9"
  },
  {
    "uniprot": "X8FDI9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDI9"
  },
  {
    "uniprot": "X8F1Y4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Y4"
  },
  {
    "uniprot": "X8FL46",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL46"
  },
  {
    "uniprot": "X8FKA8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKA8"
  },
  {
    "uniprot": "X8FEP5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEP5"
  },
  {
    "uniprot": "X8F4J6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4J6"
  },
  {
    "uniprot": "X8F584",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F584"
  },
  {
    "uniprot": "X8F8C3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8C3"
  },
  {
    "uniprot": "X8F7S8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7S8"
  },
  {
    "uniprot": "X8FHW0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHW0"
  },
  {
    "uniprot": "X8F3U7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3U7"
  },
  {
    "uniprot": "X8FHR0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHR0"
  },
  {
    "uniprot": "X8FK97",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK97"
  },
  {
    "uniprot": "X8FB54",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB54"
  },
  {
    "uniprot": "X8F879",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F879"
  },
  {
    "uniprot": "X8F2E2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2E2"
  },
  {
    "uniprot": "X8FHI5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHI5"
  },
  {
    "uniprot": "X8FAD1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAD1"
  },
  {
    "uniprot": "X8FDM4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDM4"
  },
  {
    "uniprot": "X8FHK3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHK3"
  },
  {
    "uniprot": "X8FGI6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGI6"
  },
  {
    "uniprot": "X8EYF2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYF2"
  },
  {
    "uniprot": "X8FK09",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK09"
  },
  {
    "uniprot": "X8F0W2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0W2"
  },
  {
    "uniprot": "X8FDZ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDZ1"
  },
  {
    "uniprot": "X8F2K6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2K6"
  },
  {
    "uniprot": "X8F0X8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0X8"
  },
  {
    "uniprot": "X8F4A8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4A8"
  },
  {
    "uniprot": "X8F794",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F794"
  },
  {
    "uniprot": "X8F8Q9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Q9"
  },
  {
    "uniprot": "X8F3C9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3C9"
  },
  {
    "uniprot": "X8F3N5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3N5"
  },
  {
    "uniprot": "X8F700",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F700"
  },
  {
    "uniprot": "X8FLU7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLU7"
  },
  {
    "uniprot": "X8EYD6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYD6"
  },
  {
    "uniprot": "X8F7X5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7X5"
  },
  {
    "uniprot": "X8FFB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFB3"
  },
  {
    "uniprot": "X8F9A2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9A2"
  },
  {
    "uniprot": "X8FF15",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF15"
  },
  {
    "uniprot": "X8FK34",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK34"
  },
  {
    "uniprot": "X8FI10",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI10"
  },
  {
    "uniprot": "X8F9M7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9M7"
  },
  {
    "uniprot": "X8FBP2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBP2"
  },
  {
    "uniprot": "X8FBU7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBU7"
  },
  {
    "uniprot": "X8FJB2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJB2"
  },
  {
    "uniprot": "X8F6P2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6P2"
  },
  {
    "uniprot": "X8F4R3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4R3"
  },
  {
    "uniprot": "X8FAJ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAJ3"
  },
  {
    "uniprot": "X8F8R8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8R8"
  },
  {
    "uniprot": "X8F8Y9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Y9"
  },
  {
    "uniprot": "X8FMY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMY5"
  },
  {
    "uniprot": "X8F2R5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2R5"
  },
  {
    "uniprot": "X8FHD6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHD6"
  },
  {
    "uniprot": "X8F1I6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1I6"
  },
  {
    "uniprot": "X8FA73",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA73"
  },
  {
    "uniprot": "X8FGI4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGI4"
  },
  {
    "uniprot": "X8FFI1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFI1"
  },
  {
    "uniprot": "X8FH31",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH31"
  },
  {
    "uniprot": "X8EZE5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZE5"
  },
  {
    "uniprot": "X8FA33",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA33"
  },
  {
    "uniprot": "X8FBR4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBR4"
  },
  {
    "uniprot": "X8FF14",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF14"
  },
  {
    "uniprot": "X8FHJ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHJ0"
  },
  {
    "uniprot": "X8FBV3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBV3"
  },
  {
    "uniprot": "X8F2R0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2R0"
  },
  {
    "uniprot": "X8FI95",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI95"
  },
  {
    "uniprot": "X8FIN4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIN4"
  },
  {
    "uniprot": "X8EZ48",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ48"
  },
  {
    "uniprot": "X8FFP7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFP7"
  },
  {
    "uniprot": "X8EYM1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYM1"
  },
  {
    "uniprot": "X8FM64",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM64"
  },
  {
    "uniprot": "X8FLF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLF3"
  },
  {
    "uniprot": "X8FNE8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNE8"
  },
  {
    "uniprot": "X8F2C4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2C4"
  },
  {
    "uniprot": "X8FAL6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAL6"
  },
  {
    "uniprot": "X8F463",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F463"
  },
  {
    "uniprot": "X8F3H6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3H6"
  },
  {
    "uniprot": "X8F4S0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4S0"
  },
  {
    "uniprot": "X8FNX4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNX4"
  },
  {
    "uniprot": "X8FBY3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBY3"
  },
  {
    "uniprot": "X8FHE1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHE1"
  },
  {
    "uniprot": "X8FB65",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB65"
  },
  {
    "uniprot": "X8F587",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F587"
  },
  {
    "uniprot": "X8FI91",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI91"
  },
  {
    "uniprot": "X8F9Y4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Y4"
  },
  {
    "uniprot": "X8FIB2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIB2"
  },
  {
    "uniprot": "X8F7Q4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Q4"
  },
  {
    "uniprot": "X8F5A2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5A2"
  },
  {
    "uniprot": "X8FK30",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK30"
  },
  {
    "uniprot": "X8FMN9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMN9"
  },
  {
    "uniprot": "X8FAU7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAU7"
  },
  {
    "uniprot": "X8FGE0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGE0"
  },
  {
    "uniprot": "X8FDX9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDX9"
  },
  {
    "uniprot": "X8FMB5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMB5"
  },
  {
    "uniprot": "X8FCM5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCM5"
  },
  {
    "uniprot": "X8F304",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F304"
  },
  {
    "uniprot": "X8F519",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F519"
  },
  {
    "uniprot": "X8F719",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F719"
  },
  {
    "uniprot": "X8FN40",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN40"
  },
  {
    "uniprot": "X8FEQ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEQ0"
  },
  {
    "uniprot": "X8F008",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F008"
  },
  {
    "uniprot": "X8FF21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF21"
  },
  {
    "uniprot": "X8FH80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH80"
  },
  {
    "uniprot": "X8F9R5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9R5"
  },
  {
    "uniprot": "X8F4Z4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Z4"
  },
  {
    "uniprot": "X8EXG7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXG7"
  },
  {
    "uniprot": "X8F153",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F153"
  },
  {
    "uniprot": "X8FLL4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLL4"
  },
  {
    "uniprot": "X8FII6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FII6"
  },
  {
    "uniprot": "X8FLK9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLK9"
  },
  {
    "uniprot": "X8FIW4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIW4"
  },
  {
    "uniprot": "X8FIZ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIZ5"
  },
  {
    "uniprot": "X8EYL7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYL7"
  },
  {
    "uniprot": "X8FFX1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFX1"
  },
  {
    "uniprot": "X8FF56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF56"
  },
  {
    "uniprot": "X8FI17",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI17"
  },
  {
    "uniprot": "X8F3B8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3B8"
  },
  {
    "uniprot": "X8F2H0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2H0"
  },
  {
    "uniprot": "X8FFU3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFU3"
  },
  {
    "uniprot": "X8F841",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F841"
  },
  {
    "uniprot": "X8F2Y6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Y6"
  },
  {
    "uniprot": "X8FGJ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGJ8"
  },
  {
    "uniprot": "X8FES4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FES4"
  },
  {
    "uniprot": "X8FL44",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL44"
  },
  {
    "uniprot": "X8FB20",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB20"
  },
  {
    "uniprot": "X8EYX0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYX0"
  },
  {
    "uniprot": "X8FAS6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAS6"
  },
  {
    "uniprot": "X8F5M5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5M5"
  },
  {
    "uniprot": "X8F0U9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0U9"
  },
  {
    "uniprot": "X8FKD5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKD5"
  },
  {
    "uniprot": "X8EY66",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY66"
  },
  {
    "uniprot": "X8FC35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC35"
  },
  {
    "uniprot": "X8F0H4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0H4"
  },
  {
    "uniprot": "X8FA00",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA00"
  },
  {
    "uniprot": "X8FCL9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCL9"
  },
  {
    "uniprot": "X8F7D9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7D9"
  },
  {
    "uniprot": "X8FNA2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNA2"
  },
  {
    "uniprot": "X8FI64",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI64"
  },
  {
    "uniprot": "X8F745",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F745"
  },
  {
    "uniprot": "X8F8R3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8R3"
  },
  {
    "uniprot": "X8FJZ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJZ4"
  },
  {
    "uniprot": "X8F9P2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9P2"
  },
  {
    "uniprot": "X8FM10",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM10"
  },
  {
    "uniprot": "X8FCQ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCQ7"
  },
  {
    "uniprot": "X8FD15",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD15"
  },
  {
    "uniprot": "X8FNU4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNU4"
  },
  {
    "uniprot": "X8FFY2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFY2"
  },
  {
    "uniprot": "X8F0D3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0D3"
  },
  {
    "uniprot": "X8F732",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F732"
  },
  {
    "uniprot": "X8FJR1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJR1"
  },
  {
    "uniprot": "X8F6A5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6A5"
  },
  {
    "uniprot": "X8EX46",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX46"
  },
  {
    "uniprot": "X8F4N0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4N0"
  },
  {
    "uniprot": "X8FBP6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBP6"
  },
  {
    "uniprot": "X8FAM8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAM8"
  },
  {
    "uniprot": "X8F8Z1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Z1"
  },
  {
    "uniprot": "X8F1J5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1J5"
  },
  {
    "uniprot": "X8FGB4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGB4"
  },
  {
    "uniprot": "X8F7D0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7D0"
  },
  {
    "uniprot": "X8F8B9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8B9"
  },
  {
    "uniprot": "X8FAW7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAW7"
  },
  {
    "uniprot": "X8FE42",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE42"
  },
  {
    "uniprot": "X8FBZ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBZ0"
  },
  {
    "uniprot": "X8FGK8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGK8"
  },
  {
    "uniprot": "X8FG44",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG44"
  },
  {
    "uniprot": "X8FJQ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJQ1"
  },
  {
    "uniprot": "X8F7V3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7V3"
  },
  {
    "uniprot": "X8FH13",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH13"
  },
  {
    "uniprot": "X8FF37",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF37"
  },
  {
    "uniprot": "X8FGZ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGZ9"
  },
  {
    "uniprot": "X8F6U8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6U8"
  },
  {
    "uniprot": "X8FAQ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAQ0"
  },
  {
    "uniprot": "X8FGD7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGD7"
  },
  {
    "uniprot": "X8F0G4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0G4"
  },
  {
    "uniprot": "X8FPT3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPT3"
  },
  {
    "uniprot": "X8FKK5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKK5"
  },
  {
    "uniprot": "X8F4L3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4L3"
  },
  {
    "uniprot": "X8FF82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF82"
  },
  {
    "uniprot": "X8F931",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F931"
  },
  {
    "uniprot": "X8FCX6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCX6"
  },
  {
    "uniprot": "X8FKM2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKM2"
  },
  {
    "uniprot": "X8FKC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKC0"
  },
  {
    "uniprot": "X8FGQ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGQ9"
  },
  {
    "uniprot": "X8FK95",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK95"
  },
  {
    "uniprot": "X8FN46",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN46"
  },
  {
    "uniprot": "X8FD91",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD91"
  },
  {
    "uniprot": "X8FA60",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA60"
  },
  {
    "uniprot": "X8FD87",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD87"
  },
  {
    "uniprot": "X8FEA5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEA5"
  },
  {
    "uniprot": "X8FKA7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKA7"
  },
  {
    "uniprot": "X8FKM5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKM5"
  },
  {
    "uniprot": "X8FLG4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLG4"
  },
  {
    "uniprot": "X8F5R8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5R8"
  },
  {
    "uniprot": "X8FAJ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAJ5"
  },
  {
    "uniprot": "X8F8B7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8B7"
  },
  {
    "uniprot": "X8FH82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH82"
  },
  {
    "uniprot": "X8EZP5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZP5"
  },
  {
    "uniprot": "X8FBL4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBL4"
  },
  {
    "uniprot": "X8FF90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF90"
  },
  {
    "uniprot": "X8FGP8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGP8"
  },
  {
    "uniprot": "X8FJC9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJC9"
  },
  {
    "uniprot": "X8FBK6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBK6"
  },
  {
    "uniprot": "X8FCW1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCW1"
  },
  {
    "uniprot": "X8FKJ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKJ2"
  },
  {
    "uniprot": "X8FIQ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIQ3"
  },
  {
    "uniprot": "X8FHM9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHM9"
  },
  {
    "uniprot": "X8F1J3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1J3"
  },
  {
    "uniprot": "X8FNF8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNF8"
  },
  {
    "uniprot": "X8FL37",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL37"
  },
  {
    "uniprot": "X8F8M2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8M2"
  },
  {
    "uniprot": "X8F4T3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4T3"
  },
  {
    "uniprot": "X8FFJ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFJ7"
  },
  {
    "uniprot": "X8F600",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F600"
  },
  {
    "uniprot": "X8FDN0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDN0"
  },
  {
    "uniprot": "X8FGY8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGY8"
  },
  {
    "uniprot": "X8FIM3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIM3"
  },
  {
    "uniprot": "X8EZ00",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ00"
  },
  {
    "uniprot": "X8FIF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIF3"
  },
  {
    "uniprot": "X8FD50",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD50"
  },
  {
    "uniprot": "X8FHM6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHM6"
  },
  {
    "uniprot": "X8F4I7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4I7"
  },
  {
    "uniprot": "X8FJP5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJP5"
  },
  {
    "uniprot": "X8FG87",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG87"
  },
  {
    "uniprot": "X8FNU9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNU9"
  },
  {
    "uniprot": "X8FCI1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCI1"
  },
  {
    "uniprot": "X8FPE6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPE6"
  },
  {
    "uniprot": "X8FHP5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHP5"
  },
  {
    "uniprot": "X8EYW0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYW0"
  },
  {
    "uniprot": "X8FP69",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP69"
  },
  {
    "uniprot": "X8FLJ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLJ2"
  },
  {
    "uniprot": "X8FPC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPC8"
  },
  {
    "uniprot": "X8FJF9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJF9"
  },
  {
    "uniprot": "X8FDE4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDE4"
  },
  {
    "uniprot": "X8FGV7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGV7"
  },
  {
    "uniprot": "X8F158",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F158"
  },
  {
    "uniprot": "X8FKL7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKL7"
  },
  {
    "uniprot": "X8FGX1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGX1"
  },
  {
    "uniprot": "X8FHS8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHS8"
  },
  {
    "uniprot": "X8FJL0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJL0"
  },
  {
    "uniprot": "X8EYU4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYU4"
  },
  {
    "uniprot": "X8F4T8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4T8"
  },
  {
    "uniprot": "X8F0W0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0W0"
  },
  {
    "uniprot": "X8FIK9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIK9"
  },
  {
    "uniprot": "X8FHZ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHZ3"
  },
  {
    "uniprot": "X8FEW7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEW7"
  },
  {
    "uniprot": "X8FHT4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHT4"
  },
  {
    "uniprot": "X8FE20",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE20"
  },
  {
    "uniprot": "X8FF55",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF55"
  },
  {
    "uniprot": "X8F7K2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7K2"
  },
  {
    "uniprot": "X8F273",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F273"
  },
  {
    "uniprot": "X8F5E9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5E9"
  },
  {
    "uniprot": "X8F962",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F962"
  },
  {
    "uniprot": "X8FA18",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA18"
  },
  {
    "uniprot": "X8F0F3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0F3"
  },
  {
    "uniprot": "X8FG66",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG66"
  },
  {
    "uniprot": "X8F7H9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7H9"
  },
  {
    "uniprot": "X8F8V6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8V6"
  },
  {
    "uniprot": "X8FAQ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAQ8"
  },
  {
    "uniprot": "X8F2G4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2G4"
  },
  {
    "uniprot": "X8FJX4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJX4"
  },
  {
    "uniprot": "X8FB39",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB39"
  },
  {
    "uniprot": "X8FGD2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGD2"
  },
  {
    "uniprot": "X8FNV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNV0"
  },
  {
    "uniprot": "X8FQE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQE2"
  },
  {
    "uniprot": "X8FKW1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKW1"
  },
  {
    "uniprot": "X8F1B7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1B7"
  },
  {
    "uniprot": "X8FLE1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLE1"
  },
  {
    "uniprot": "X8F8N3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8N3"
  },
  {
    "uniprot": "X8FBL7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBL7"
  },
  {
    "uniprot": "X8FHA7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHA7"
  },
  {
    "uniprot": "X8FDQ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDQ4"
  },
  {
    "uniprot": "X8FG84",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG84"
  },
  {
    "uniprot": "X8FFC1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFC1"
  },
  {
    "uniprot": "X8FCU2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCU2"
  },
  {
    "uniprot": "X8F7F9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7F9"
  },
  {
    "uniprot": "X8FM31",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM31"
  },
  {
    "uniprot": "X8FBV6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBV6"
  },
  {
    "uniprot": "X8F2V3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2V3"
  },
  {
    "uniprot": "X8F245",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F245"
  },
  {
    "uniprot": "X8F416",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F416"
  },
  {
    "uniprot": "X8F4T1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4T1"
  },
  {
    "uniprot": "X8F011",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F011"
  },
  {
    "uniprot": "X8EYJ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYJ8"
  },
  {
    "uniprot": "X8F4Z9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Z9"
  },
  {
    "uniprot": "X8F5L0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5L0"
  },
  {
    "uniprot": "X8FG76",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG76"
  },
  {
    "uniprot": "X8FET0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FET0"
  },
  {
    "uniprot": "X8F3A1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3A1"
  },
  {
    "uniprot": "X8F1G5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1G5"
  },
  {
    "uniprot": "X8F302",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F302"
  },
  {
    "uniprot": "X8FN69",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN69"
  },
  {
    "uniprot": "X8F2K0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2K0"
  },
  {
    "uniprot": "X8FAA6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAA6"
  },
  {
    "uniprot": "X8F349",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F349"
  },
  {
    "uniprot": "X8FN84",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN84"
  },
  {
    "uniprot": "X8FMF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMF3"
  },
  {
    "uniprot": "X8FIY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIY5"
  },
  {
    "uniprot": "X8F338",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F338"
  },
  {
    "uniprot": "X8FNN9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNN9"
  },
  {
    "uniprot": "X8FJ87",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ87"
  },
  {
    "uniprot": "X8F7A2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7A2"
  },
  {
    "uniprot": "X8F4I3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4I3"
  },
  {
    "uniprot": "X8FC25",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC25"
  },
  {
    "uniprot": "X8FJG7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJG7"
  },
  {
    "uniprot": "X8FFS4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFS4"
  },
  {
    "uniprot": "X8F3I1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3I1"
  },
  {
    "uniprot": "X8F3H8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3H8"
  },
  {
    "uniprot": "X8F8S7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8S7"
  },
  {
    "uniprot": "X8F5M2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5M2"
  },
  {
    "uniprot": "X8F4V9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4V9"
  },
  {
    "uniprot": "X8FEA0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEA0"
  },
  {
    "uniprot": "X8FDB1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDB1"
  },
  {
    "uniprot": "X8FAP2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAP2"
  },
  {
    "uniprot": "X8FEN1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEN1"
  },
  {
    "uniprot": "X8F3S8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3S8"
  },
  {
    "uniprot": "X8FM87",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM87"
  },
  {
    "uniprot": "X8F3N8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3N8"
  },
  {
    "uniprot": "X8FM05",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM05"
  },
  {
    "uniprot": "X8F8C8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8C8"
  },
  {
    "uniprot": "X8F3U9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3U9"
  },
  {
    "uniprot": "X8FD39",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD39"
  },
  {
    "uniprot": "X8FBD7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBD7"
  },
  {
    "uniprot": "X8F4K8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4K8"
  },
  {
    "uniprot": "X8FBI5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBI5"
  },
  {
    "uniprot": "X8FIZ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIZ9"
  },
  {
    "uniprot": "X8FMX6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMX6"
  },
  {
    "uniprot": "X8FMA0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMA0"
  },
  {
    "uniprot": "X8FCY2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCY2"
  },
  {
    "uniprot": "X8FID4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FID4"
  },
  {
    "uniprot": "X8FJR5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJR5"
  },
  {
    "uniprot": "X8FH46",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH46"
  },
  {
    "uniprot": "X8FES9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FES9"
  },
  {
    "uniprot": "X8FEE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEE2"
  },
  {
    "uniprot": "X8F670",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F670"
  },
  {
    "uniprot": "X8FJ56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ56"
  },
  {
    "uniprot": "X8FNX9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNX9"
  },
  {
    "uniprot": "X8F9Z8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Z8"
  },
  {
    "uniprot": "X8F0S1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0S1"
  },
  {
    "uniprot": "X8F4K1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4K1"
  },
  {
    "uniprot": "X8FHY0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHY0"
  },
  {
    "uniprot": "X8FLF5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLF5"
  },
  {
    "uniprot": "X8F8I5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8I5"
  },
  {
    "uniprot": "X8F6C0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6C0"
  },
  {
    "uniprot": "X8FAK7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAK7"
  },
  {
    "uniprot": "X8FED6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FED6"
  },
  {
    "uniprot": "X8FE37",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE37"
  },
  {
    "uniprot": "X8FBM0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBM0"
  },
  {
    "uniprot": "X8FAC5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAC5"
  },
  {
    "uniprot": "X8F7E4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7E4"
  },
  {
    "uniprot": "X8F3G8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3G8"
  },
  {
    "uniprot": "X8FGL3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGL3"
  },
  {
    "uniprot": "X8F1U9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1U9"
  },
  {
    "uniprot": "X8EZW1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZW1"
  },
  {
    "uniprot": "X8FH57",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH57"
  },
  {
    "uniprot": "X8F1P8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1P8"
  },
  {
    "uniprot": "X8F3V1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3V1"
  },
  {
    "uniprot": "X8FDF7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDF7"
  },
  {
    "uniprot": "X8FLB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLB3"
  },
  {
    "uniprot": "X8FGS8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGS8"
  },
  {
    "uniprot": "X8F347",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F347"
  },
  {
    "uniprot": "X8FHZ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHZ6"
  },
  {
    "uniprot": "X8F0Y3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Y3"
  },
  {
    "uniprot": "X8F468",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F468"
  },
  {
    "uniprot": "X8FFY8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFY8"
  },
  {
    "uniprot": "X8EXR3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXR3"
  },
  {
    "uniprot": "X8FCF2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCF2"
  },
  {
    "uniprot": "X8F0U1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0U1"
  },
  {
    "uniprot": "X8EZ67",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ67"
  },
  {
    "uniprot": "X8F7K8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7K8"
  },
  {
    "uniprot": "X8FMU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMU0"
  },
  {
    "uniprot": "X8F052",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F052"
  },
  {
    "uniprot": "X8FBB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBB3"
  },
  {
    "uniprot": "X8FD78",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD78"
  },
  {
    "uniprot": "X8FGQ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGQ1"
  },
  {
    "uniprot": "X8FBQ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBQ2"
  },
  {
    "uniprot": "X8F0I5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0I5"
  },
  {
    "uniprot": "X8FIA1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIA1"
  },
  {
    "uniprot": "X8FKU9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKU9"
  },
  {
    "uniprot": "X8FL31",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL31"
  },
  {
    "uniprot": "X8FH76",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH76"
  },
  {
    "uniprot": "X8F3C3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3C3"
  },
  {
    "uniprot": "X8F9T2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9T2"
  },
  {
    "uniprot": "X8FBQ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBQ5"
  },
  {
    "uniprot": "X8FLR9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLR9"
  },
  {
    "uniprot": "X8FNU5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNU5"
  },
  {
    "uniprot": "X8F619",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F619"
  },
  {
    "uniprot": "X8EWE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWE2"
  },
  {
    "uniprot": "X8FC37",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC37"
  },
  {
    "uniprot": "X8F5Z1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Z1"
  },
  {
    "uniprot": "X8F694",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F694"
  },
  {
    "uniprot": "X8F2B7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2B7"
  },
  {
    "uniprot": "X8F1J8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1J8"
  },
  {
    "uniprot": "X8F470",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F470"
  },
  {
    "uniprot": "X8FPT0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPT0"
  },
  {
    "uniprot": "X8FLU5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLU5"
  },
  {
    "uniprot": "X8F9W9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9W9"
  },
  {
    "uniprot": "X8FFF7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFF7"
  },
  {
    "uniprot": "X8FEB7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEB7"
  },
  {
    "uniprot": "X8F1F7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1F7"
  },
  {
    "uniprot": "X8EZH3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZH3"
  },
  {
    "uniprot": "X8FHM0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHM0"
  },
  {
    "uniprot": "X8F944",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F944"
  },
  {
    "uniprot": "X8FAC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAC0"
  },
  {
    "uniprot": "X8FAA8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAA8"
  },
  {
    "uniprot": "X8F7M1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7M1"
  },
  {
    "uniprot": "X8F013",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F013"
  },
  {
    "uniprot": "X8F4C7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4C7"
  },
  {
    "uniprot": "X8F725",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F725"
  },
  {
    "uniprot": "X8FI38",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI38"
  },
  {
    "uniprot": "X8FC60",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC60"
  },
  {
    "uniprot": "X8F0K9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0K9"
  },
  {
    "uniprot": "X8F4I0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4I0"
  },
  {
    "uniprot": "X8FLK6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLK6"
  },
  {
    "uniprot": "X8F754",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F754"
  },
  {
    "uniprot": "X8F9U9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9U9"
  },
  {
    "uniprot": "X8FEG3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEG3"
  },
  {
    "uniprot": "X8FJ16",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ16"
  },
  {
    "uniprot": "X8FG45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG45"
  },
  {
    "uniprot": "X8FE55",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE55"
  },
  {
    "uniprot": "X8F6X7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6X7"
  },
  {
    "uniprot": "X8EXY0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXY0"
  },
  {
    "uniprot": "X8FKN4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKN4"
  },
  {
    "uniprot": "X8FF96",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF96"
  },
  {
    "uniprot": "X8FJM0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJM0"
  },
  {
    "uniprot": "X8FJS7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJS7"
  },
  {
    "uniprot": "X8F290",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F290"
  },
  {
    "uniprot": "X8F5E0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5E0"
  },
  {
    "uniprot": "X8FME7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FME7"
  },
  {
    "uniprot": "X8FFZ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFZ2"
  },
  {
    "uniprot": "X8FLK5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLK5"
  },
  {
    "uniprot": "X8FFU2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFU2"
  },
  {
    "uniprot": "X8F5W2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5W2"
  },
  {
    "uniprot": "X8F1L4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1L4"
  },
  {
    "uniprot": "X8F0L5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0L5"
  },
  {
    "uniprot": "X8FB73",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB73"
  },
  {
    "uniprot": "X8FLX5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLX5"
  },
  {
    "uniprot": "X8F3K7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3K7"
  },
  {
    "uniprot": "X8FG99",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG99"
  },
  {
    "uniprot": "X8FAY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAY1"
  },
  {
    "uniprot": "X8FKX1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKX1"
  },
  {
    "uniprot": "X8FIX0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIX0"
  },
  {
    "uniprot": "X8FE10",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE10"
  },
  {
    "uniprot": "X8EYT8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYT8"
  },
  {
    "uniprot": "X8F9W0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9W0"
  },
  {
    "uniprot": "X8F513",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F513"
  },
  {
    "uniprot": "X8F103",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F103"
  },
  {
    "uniprot": "X8EZ92",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ92"
  },
  {
    "uniprot": "X8FP62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP62"
  },
  {
    "uniprot": "X8FK29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK29"
  },
  {
    "uniprot": "X8F3B6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3B6"
  },
  {
    "uniprot": "X8FHJ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHJ8"
  },
  {
    "uniprot": "X8FB10",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB10"
  },
  {
    "uniprot": "X8FPK2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPK2"
  },
  {
    "uniprot": "X8FEW8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEW8"
  },
  {
    "uniprot": "X8FIB7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIB7"
  },
  {
    "uniprot": "X8FNZ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNZ9"
  },
  {
    "uniprot": "X8FJR2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJR2"
  },
  {
    "uniprot": "X8FDL3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDL3"
  },
  {
    "uniprot": "X8FN07",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN07"
  },
  {
    "uniprot": "X8FAG2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAG2"
  },
  {
    "uniprot": "X8FI74",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI74"
  },
  {
    "uniprot": "X8FL55",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL55"
  },
  {
    "uniprot": "X8FDY2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDY2"
  },
  {
    "uniprot": "X8EXU2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXU2"
  },
  {
    "uniprot": "X8FG75",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG75"
  },
  {
    "uniprot": "X8FE45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE45"
  },
  {
    "uniprot": "X8F0N9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0N9"
  },
  {
    "uniprot": "X8FI98",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI98"
  },
  {
    "uniprot": "X8F1G6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1G6"
  },
  {
    "uniprot": "X8FFY0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFY0"
  },
  {
    "uniprot": "X8F2Q3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Q3"
  },
  {
    "uniprot": "X8FGX4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGX4"
  },
  {
    "uniprot": "X8FN66",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN66"
  },
  {
    "uniprot": "X8F2I1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2I1"
  },
  {
    "uniprot": "X8EXI4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXI4"
  },
  {
    "uniprot": "X8FH62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH62"
  },
  {
    "uniprot": "X8F0H3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0H3"
  },
  {
    "uniprot": "X8FEW9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEW9"
  },
  {
    "uniprot": "X8FL24",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL24"
  },
  {
    "uniprot": "X8FI89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI89"
  },
  {
    "uniprot": "X8FN70",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN70"
  },
  {
    "uniprot": "X8FGV5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGV5"
  },
  {
    "uniprot": "X8FMW0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMW0"
  },
  {
    "uniprot": "X8FCF5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCF5"
  },
  {
    "uniprot": "X8FIQ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIQ7"
  },
  {
    "uniprot": "X8F598",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F598"
  },
  {
    "uniprot": "X8F6E5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6E5"
  },
  {
    "uniprot": "X8FII8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FII8"
  },
  {
    "uniprot": "X8FHD5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHD5"
  },
  {
    "uniprot": "X8F2P7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2P7"
  },
  {
    "uniprot": "X8FDC3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDC3"
  },
  {
    "uniprot": "X8FAI6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAI6"
  },
  {
    "uniprot": "X8FJ85",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ85"
  },
  {
    "uniprot": "X8FNR8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNR8"
  },
  {
    "uniprot": "X8FF00",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF00"
  },
  {
    "uniprot": "X8FC14",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC14"
  },
  {
    "uniprot": "X8FJC6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJC6"
  },
  {
    "uniprot": "X8F2U8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2U8"
  },
  {
    "uniprot": "X8EXE8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXE8"
  },
  {
    "uniprot": "X8F4L9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4L9"
  },
  {
    "uniprot": "X8EZ10",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ10"
  },
  {
    "uniprot": "X8FIJ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIJ6"
  },
  {
    "uniprot": "X8F6Q5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Q5"
  },
  {
    "uniprot": "X8F685",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F685"
  },
  {
    "uniprot": "X8FC40",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC40"
  },
  {
    "uniprot": "X8FKK3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKK3"
  },
  {
    "uniprot": "X8FDY4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDY4"
  },
  {
    "uniprot": "X8FMS2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMS2"
  },
  {
    "uniprot": "X8FGL1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGL1"
  },
  {
    "uniprot": "X8FLN6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLN6"
  },
  {
    "uniprot": "X8FIL2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIL2"
  },
  {
    "uniprot": "X8F1C5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1C5"
  },
  {
    "uniprot": "X8FGL0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGL0"
  },
  {
    "uniprot": "X8FK59",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK59"
  },
  {
    "uniprot": "X8F4P8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4P8"
  },
  {
    "uniprot": "X8F818",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F818"
  },
  {
    "uniprot": "X8FHR4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHR4"
  },
  {
    "uniprot": "X8FNY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNY5"
  },
  {
    "uniprot": "X8FFB6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFB6"
  },
  {
    "uniprot": "X8FH22",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH22"
  },
  {
    "uniprot": "X8FG89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG89"
  },
  {
    "uniprot": "X8F2N0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2N0"
  },
  {
    "uniprot": "X8F0T3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0T3"
  },
  {
    "uniprot": "X8F289",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F289"
  },
  {
    "uniprot": "X8FEE8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEE8"
  },
  {
    "uniprot": "X8F7P9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7P9"
  },
  {
    "uniprot": "X8F4E6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4E6"
  },
  {
    "uniprot": "X8FC87",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC87"
  },
  {
    "uniprot": "X8F8W6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8W6"
  },
  {
    "uniprot": "X8F9S4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9S4"
  },
  {
    "uniprot": "X8F100",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F100"
  },
  {
    "uniprot": "X8F9T4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9T4"
  },
  {
    "uniprot": "X8F7I4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7I4"
  },
  {
    "uniprot": "X8FCG5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCG5"
  },
  {
    "uniprot": "X8F5H1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5H1"
  },
  {
    "uniprot": "X8FI29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI29"
  },
  {
    "uniprot": "X8FIH5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIH5"
  },
  {
    "uniprot": "X8FPV4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPV4"
  },
  {
    "uniprot": "X8F992",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F992"
  },
  {
    "uniprot": "X8F2E7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2E7"
  },
  {
    "uniprot": "X8FHP7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHP7"
  },
  {
    "uniprot": "X8F6L8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6L8"
  },
  {
    "uniprot": "X8FMB2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMB2"
  },
  {
    "uniprot": "X8FG41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG41"
  },
  {
    "uniprot": "X8FGM0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGM0"
  },
  {
    "uniprot": "X8FI78",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI78"
  },
  {
    "uniprot": "X8F6C5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6C5"
  },
  {
    "uniprot": "X8F7A5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7A5"
  },
  {
    "uniprot": "X8F920",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F920"
  },
  {
    "uniprot": "X8F7V8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7V8"
  },
  {
    "uniprot": "X8FB78",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB78"
  },
  {
    "uniprot": "X8FK20",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK20"
  },
  {
    "uniprot": "X8FH48",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH48"
  },
  {
    "uniprot": "X8FCY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCY1"
  },
  {
    "uniprot": "X8F208",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F208"
  },
  {
    "uniprot": "X8F4L5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4L5"
  },
  {
    "uniprot": "X8FJ77",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ77"
  },
  {
    "uniprot": "X8F721",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F721"
  },
  {
    "uniprot": "X8FLM3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLM3"
  },
  {
    "uniprot": "X8FCD9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCD9"
  },
  {
    "uniprot": "X8FCM8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCM8"
  },
  {
    "uniprot": "X8FJ24",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ24"
  },
  {
    "uniprot": "X8FBZ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBZ6"
  },
  {
    "uniprot": "X8F8D5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8D5"
  },
  {
    "uniprot": "X8FLL5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLL5"
  },
  {
    "uniprot": "X8FD73",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD73"
  },
  {
    "uniprot": "X8EXZ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXZ1"
  },
  {
    "uniprot": "X8FGA8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGA8"
  },
  {
    "uniprot": "X8FA83",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA83"
  },
  {
    "uniprot": "X8FEA6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEA6"
  },
  {
    "uniprot": "X8F1W5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1W5"
  },
  {
    "uniprot": "X8FJ38",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ38"
  },
  {
    "uniprot": "X8FLN9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLN9"
  },
  {
    "uniprot": "X8FFN3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFN3"
  },
  {
    "uniprot": "X8FL07",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL07"
  },
  {
    "uniprot": "X8F783",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F783"
  },
  {
    "uniprot": "X8FLQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLQ6"
  },
  {
    "uniprot": "X8FKX3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKX3"
  },
  {
    "uniprot": "X8F123",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F123"
  },
  {
    "uniprot": "X8F0M5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0M5"
  },
  {
    "uniprot": "X8FQ55",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQ55"
  },
  {
    "uniprot": "X8F3U2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3U2"
  },
  {
    "uniprot": "X8FKC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKC7"
  },
  {
    "uniprot": "X8FBY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBY9"
  },
  {
    "uniprot": "X8FPC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPC0"
  },
  {
    "uniprot": "X8F080",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F080"
  },
  {
    "uniprot": "X8F3P2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3P2"
  },
  {
    "uniprot": "X8F3N1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3N1"
  },
  {
    "uniprot": "X8F066",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F066"
  },
  {
    "uniprot": "X8F5J7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5J7"
  },
  {
    "uniprot": "X8FFZ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFZ4"
  },
  {
    "uniprot": "X8FCG0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCG0"
  },
  {
    "uniprot": "X8FA13",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA13"
  },
  {
    "uniprot": "X8F1B1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1B1"
  },
  {
    "uniprot": "X8FPP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPP9"
  },
  {
    "uniprot": "X8F7C1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7C1"
  },
  {
    "uniprot": "X8FM24",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM24"
  },
  {
    "uniprot": "X8FAZ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAZ8"
  },
  {
    "uniprot": "X8FHY8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHY8"
  },
  {
    "uniprot": "X8F8S2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8S2"
  },
  {
    "uniprot": "X8FAL9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAL9"
  },
  {
    "uniprot": "X8FAP7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAP7"
  },
  {
    "uniprot": "X8FCF8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCF8"
  },
  {
    "uniprot": "X8F383",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F383"
  },
  {
    "uniprot": "X8FE18",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE18"
  },
  {
    "uniprot": "X8F6V1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6V1"
  },
  {
    "uniprot": "X8FKK4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKK4"
  },
  {
    "uniprot": "X8FKQ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKQ9"
  },
  {
    "uniprot": "X8FBW5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBW5"
  },
  {
    "uniprot": "X8EYT3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYT3"
  },
  {
    "uniprot": "X8FI70",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI70"
  },
  {
    "uniprot": "X8F742",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F742"
  },
  {
    "uniprot": "X8FEI8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEI8"
  },
  {
    "uniprot": "X8FKS4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKS4"
  },
  {
    "uniprot": "X8FFK2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFK2"
  },
  {
    "uniprot": "X8FGY3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGY3"
  },
  {
    "uniprot": "X8EZU9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZU9"
  },
  {
    "uniprot": "X8EZC2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZC2"
  },
  {
    "uniprot": "X8F026",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F026"
  },
  {
    "uniprot": "X8FF47",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF47"
  },
  {
    "uniprot": "X8FJP0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJP0"
  },
  {
    "uniprot": "X8FCD5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCD5"
  },
  {
    "uniprot": "X8FFM3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFM3"
  },
  {
    "uniprot": "X8F2W3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2W3"
  },
  {
    "uniprot": "X8FGC9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGC9"
  },
  {
    "uniprot": "X8EWG7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWG7"
  },
  {
    "uniprot": "X8F4F0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4F0"
  },
  {
    "uniprot": "X8FQ43",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQ43"
  },
  {
    "uniprot": "X8FJP2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJP2"
  },
  {
    "uniprot": "X8FCT4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCT4"
  },
  {
    "uniprot": "X8F8M3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8M3"
  },
  {
    "uniprot": "X8F4W4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4W4"
  },
  {
    "uniprot": "X8F6G5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6G5"
  },
  {
    "uniprot": "X8F0S5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0S5"
  },
  {
    "uniprot": "X8F938",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F938"
  },
  {
    "uniprot": "X8F7R5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7R5"
  },
  {
    "uniprot": "X8FCF0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCF0"
  },
  {
    "uniprot": "X8F9G4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9G4"
  },
  {
    "uniprot": "X8F493",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F493"
  },
  {
    "uniprot": "X8F6K7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6K7"
  },
  {
    "uniprot": "X8FD89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD89"
  },
  {
    "uniprot": "X8FIR1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIR1"
  },
  {
    "uniprot": "X8F4S1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4S1"
  },
  {
    "uniprot": "X8FH10",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH10"
  },
  {
    "uniprot": "X8F8I2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8I2"
  },
  {
    "uniprot": "X8FCG2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCG2"
  },
  {
    "uniprot": "X8FAR6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAR6"
  },
  {
    "uniprot": "X8F7X1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7X1"
  },
  {
    "uniprot": "X8FKR9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKR9"
  },
  {
    "uniprot": "X8F9P1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9P1"
  },
  {
    "uniprot": "X8FC46",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC46"
  },
  {
    "uniprot": "X8EZG3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZG3"
  },
  {
    "uniprot": "X8F891",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F891"
  },
  {
    "uniprot": "X8FHP4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHP4"
  },
  {
    "uniprot": "X8F167",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F167"
  },
  {
    "uniprot": "X8F8T2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8T2"
  },
  {
    "uniprot": "X8FII2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FII2"
  },
  {
    "uniprot": "X8FGT9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGT9"
  },
  {
    "uniprot": "X8FGE1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGE1"
  },
  {
    "uniprot": "X8FN56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN56"
  },
  {
    "uniprot": "X8F473",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F473"
  },
  {
    "uniprot": "X8FBX3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBX3"
  },
  {
    "uniprot": "X8FJ55",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ55"
  },
  {
    "uniprot": "X8F6X9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6X9"
  },
  {
    "uniprot": "X8FFU5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFU5"
  },
  {
    "uniprot": "X8F4A7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4A7"
  },
  {
    "uniprot": "X8F6U2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6U2"
  },
  {
    "uniprot": "X8F422",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F422"
  },
  {
    "uniprot": "X8FC00",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC00"
  },
  {
    "uniprot": "X8FJG4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJG4"
  },
  {
    "uniprot": "X8FG07",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG07"
  },
  {
    "uniprot": "X8F8P4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8P4"
  },
  {
    "uniprot": "X8F6S4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6S4"
  },
  {
    "uniprot": "X8F5B6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5B6"
  },
  {
    "uniprot": "X8FHL6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHL6"
  },
  {
    "uniprot": "X8F1X6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1X6"
  },
  {
    "uniprot": "X8EXR1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXR1"
  },
  {
    "uniprot": "X8FIN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIN8"
  },
  {
    "uniprot": "X8F1F1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1F1"
  },
  {
    "uniprot": "X8FGE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGE2"
  },
  {
    "uniprot": "X8FHG8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHG8"
  },
  {
    "uniprot": "X8F9M6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9M6"
  },
  {
    "uniprot": "X8FCE5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCE5"
  },
  {
    "uniprot": "X8FK87",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK87"
  },
  {
    "uniprot": "X8FHS5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHS5"
  },
  {
    "uniprot": "X8FD83",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD83"
  },
  {
    "uniprot": "X8FDD1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDD1"
  },
  {
    "uniprot": "X8EYK9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYK9"
  },
  {
    "uniprot": "X8F7C6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7C6"
  },
  {
    "uniprot": "X8FCF6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCF6"
  },
  {
    "uniprot": "X8F503",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F503"
  },
  {
    "uniprot": "X8FJV4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJV4"
  },
  {
    "uniprot": "X8FBM8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBM8"
  },
  {
    "uniprot": "X8F803",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F803"
  },
  {
    "uniprot": "X8F6F2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6F2"
  },
  {
    "uniprot": "X8F548",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F548"
  },
  {
    "uniprot": "X8F678",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F678"
  },
  {
    "uniprot": "X8FQB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQB3"
  },
  {
    "uniprot": "X8F583",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F583"
  },
  {
    "uniprot": "X8F898",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F898"
  },
  {
    "uniprot": "X8F5N7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5N7"
  },
  {
    "uniprot": "X8FB71",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB71"
  },
  {
    "uniprot": "X8FDI6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDI6"
  },
  {
    "uniprot": "X8F2V5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2V5"
  },
  {
    "uniprot": "X8FG64",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG64"
  },
  {
    "uniprot": "X8F4W8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4W8"
  },
  {
    "uniprot": "X8F636",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F636"
  },
  {
    "uniprot": "X8FJ80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ80"
  },
  {
    "uniprot": "X8FB47",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB47"
  },
  {
    "uniprot": "X8F652",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F652"
  },
  {
    "uniprot": "X8EYP7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYP7"
  },
  {
    "uniprot": "X8FCZ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCZ2"
  },
  {
    "uniprot": "X8FBD6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBD6"
  },
  {
    "uniprot": "X8FH59",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH59"
  },
  {
    "uniprot": "X8FJB7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJB7"
  },
  {
    "uniprot": "X8F9Z1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Z1"
  },
  {
    "uniprot": "X8FIJ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIJ7"
  },
  {
    "uniprot": "X8FLT2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLT2"
  },
  {
    "uniprot": "X8FK26",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK26"
  },
  {
    "uniprot": "X8F2H1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2H1"
  },
  {
    "uniprot": "X8F0E8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0E8"
  },
  {
    "uniprot": "X8F829",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F829"
  },
  {
    "uniprot": "X8EWN1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWN1"
  },
  {
    "uniprot": "X8EZB4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZB4"
  },
  {
    "uniprot": "X8F6A9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6A9"
  },
  {
    "uniprot": "X8F7X3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7X3"
  },
  {
    "uniprot": "X8FHT1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHT1"
  },
  {
    "uniprot": "X8FAC9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAC9"
  },
  {
    "uniprot": "X8FM22",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM22"
  },
  {
    "uniprot": "X8EY00",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY00"
  },
  {
    "uniprot": "X8FAN6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAN6"
  },
  {
    "uniprot": "X8FFA6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFA6"
  },
  {
    "uniprot": "X8F4T0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4T0"
  },
  {
    "uniprot": "X8F2Z3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Z3"
  },
  {
    "uniprot": "X8F8K6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8K6"
  },
  {
    "uniprot": "X8F3W7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3W7"
  },
  {
    "uniprot": "X8F147",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F147"
  },
  {
    "uniprot": "X8FIS3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIS3"
  },
  {
    "uniprot": "X8FG91",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG91"
  },
  {
    "uniprot": "X8FM29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM29"
  },
  {
    "uniprot": "X8FJW6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJW6"
  },
  {
    "uniprot": "X8FNB4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNB4"
  },
  {
    "uniprot": "X8FHU6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHU6"
  },
  {
    "uniprot": "X8FFF1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFF1"
  },
  {
    "uniprot": "X8EZH7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZH7"
  },
  {
    "uniprot": "X8EZX8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZX8"
  },
  {
    "uniprot": "X8FMR8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMR8"
  },
  {
    "uniprot": "X8F601",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F601"
  },
  {
    "uniprot": "X8F182",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F182"
  },
  {
    "uniprot": "X8EYB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYB3"
  },
  {
    "uniprot": "X8FA14",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA14"
  },
  {
    "uniprot": "X8EX68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX68"
  },
  {
    "uniprot": "X8F2V8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2V8"
  },
  {
    "uniprot": "X8FAQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAQ6"
  },
  {
    "uniprot": "X8EZ59",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ59"
  },
  {
    "uniprot": "X8FIM9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIM9"
  },
  {
    "uniprot": "X8FLI3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLI3"
  },
  {
    "uniprot": "X8FIK2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIK2"
  },
  {
    "uniprot": "X8FB81",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB81"
  },
  {
    "uniprot": "X8F2V2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2V2"
  },
  {
    "uniprot": "X8F2Y9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Y9"
  },
  {
    "uniprot": "X8FA98",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA98"
  },
  {
    "uniprot": "X8FEQ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEQ2"
  },
  {
    "uniprot": "X8FK52",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK52"
  },
  {
    "uniprot": "X8EYU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYU0"
  },
  {
    "uniprot": "X8FCR6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCR6"
  },
  {
    "uniprot": "X8FJD0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJD0"
  },
  {
    "uniprot": "X8FN04",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN04"
  },
  {
    "uniprot": "X8FFH7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFH7"
  },
  {
    "uniprot": "X8FM82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM82"
  },
  {
    "uniprot": "X8FM74",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM74"
  },
  {
    "uniprot": "X8F2T1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2T1"
  },
  {
    "uniprot": "X8FDH9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDH9"
  },
  {
    "uniprot": "X8FMB6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMB6"
  },
  {
    "uniprot": "X8FAF0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAF0"
  },
  {
    "uniprot": "X8FKV9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKV9"
  },
  {
    "uniprot": "X8F3G9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3G9"
  },
  {
    "uniprot": "X8FPQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPQ6"
  },
  {
    "uniprot": "X8FN35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN35"
  },
  {
    "uniprot": "X8FB84",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB84"
  },
  {
    "uniprot": "X8FL36",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL36"
  },
  {
    "uniprot": "X8F1D8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1D8"
  },
  {
    "uniprot": "X8FGK6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGK6"
  },
  {
    "uniprot": "X8FAY6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAY6"
  },
  {
    "uniprot": "X8F5P4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5P4"
  },
  {
    "uniprot": "X8FJJ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJJ0"
  },
  {
    "uniprot": "X8F762",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F762"
  },
  {
    "uniprot": "X8FB45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB45"
  },
  {
    "uniprot": "X8EZ28",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ28"
  },
  {
    "uniprot": "X8FF18",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF18"
  },
  {
    "uniprot": "X8F9U3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9U3"
  },
  {
    "uniprot": "X8FJQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJQ6"
  },
  {
    "uniprot": "X8FEZ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEZ5"
  },
  {
    "uniprot": "X8FB97",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB97"
  },
  {
    "uniprot": "X8FNK8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNK8"
  },
  {
    "uniprot": "X8F4U8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4U8"
  },
  {
    "uniprot": "X8FGI8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGI8"
  },
  {
    "uniprot": "X8F374",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F374"
  },
  {
    "uniprot": "X8F4K2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4K2"
  },
  {
    "uniprot": "X8FC49",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC49"
  },
  {
    "uniprot": "X8F0B6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0B6"
  },
  {
    "uniprot": "X8F3Y7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Y7"
  },
  {
    "uniprot": "X8F557",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F557"
  },
  {
    "uniprot": "X8FD41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD41"
  },
  {
    "uniprot": "X8FC90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC90"
  },
  {
    "uniprot": "X8FIZ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIZ6"
  },
  {
    "uniprot": "X8F8W7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8W7"
  },
  {
    "uniprot": "X8FIF8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIF8"
  },
  {
    "uniprot": "X8F2B4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2B4"
  },
  {
    "uniprot": "X8EY50",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY50"
  },
  {
    "uniprot": "X8FKL4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKL4"
  },
  {
    "uniprot": "X8FH05",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH05"
  },
  {
    "uniprot": "X8FKU5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKU5"
  },
  {
    "uniprot": "X8F9L4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9L4"
  },
  {
    "uniprot": "X8F0B9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0B9"
  },
  {
    "uniprot": "X8FDX4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDX4"
  },
  {
    "uniprot": "X8F7E0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7E0"
  },
  {
    "uniprot": "X8FIM0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIM0"
  },
  {
    "uniprot": "X8FHL7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHL7"
  },
  {
    "uniprot": "X8F4Q0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Q0"
  },
  {
    "uniprot": "X8FAJ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAJ4"
  },
  {
    "uniprot": "X8FG10",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG10"
  },
  {
    "uniprot": "X8F6K2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6K2"
  },
  {
    "uniprot": "X8EXZ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXZ7"
  },
  {
    "uniprot": "X8F8I4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8I4"
  },
  {
    "uniprot": "X8FBS8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBS8"
  },
  {
    "uniprot": "X8FJF4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJF4"
  },
  {
    "uniprot": "X8F1A1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1A1"
  },
  {
    "uniprot": "X8FN16",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN16"
  },
  {
    "uniprot": "X8F823",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F823"
  },
  {
    "uniprot": "X8FC48",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC48"
  },
  {
    "uniprot": "X8FAI5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAI5"
  },
  {
    "uniprot": "X8F2F9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2F9"
  },
  {
    "uniprot": "X8F8F7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8F7"
  },
  {
    "uniprot": "X8F4D5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4D5"
  },
  {
    "uniprot": "X8FH84",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH84"
  },
  {
    "uniprot": "X8EZG5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZG5"
  },
  {
    "uniprot": "X8F3S6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3S6"
  },
  {
    "uniprot": "X8FJL2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJL2"
  },
  {
    "uniprot": "X8FF92",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF92"
  },
  {
    "uniprot": "X8F6P6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6P6"
  },
  {
    "uniprot": "X8F7E9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7E9"
  },
  {
    "uniprot": "X8FDA0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDA0"
  },
  {
    "uniprot": "X8FCI5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCI5"
  },
  {
    "uniprot": "X8FGF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGF3"
  },
  {
    "uniprot": "X8F8M8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8M8"
  },
  {
    "uniprot": "X8F408",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F408"
  },
  {
    "uniprot": "X8FPE0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPE0"
  },
  {
    "uniprot": "X8F0T9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0T9"
  },
  {
    "uniprot": "X8FJZ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJZ0"
  },
  {
    "uniprot": "X8F7K0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7K0"
  },
  {
    "uniprot": "X8F0P7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0P7"
  },
  {
    "uniprot": "X8FLZ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLZ1"
  },
  {
    "uniprot": "X8FL09",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL09"
  },
  {
    "uniprot": "X8FDR8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDR8"
  },
  {
    "uniprot": "X8FCT5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCT5"
  },
  {
    "uniprot": "X8FC44",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC44"
  },
  {
    "uniprot": "X8FGT8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGT8"
  },
  {
    "uniprot": "X8FM06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM06"
  },
  {
    "uniprot": "X8FDR9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDR9"
  },
  {
    "uniprot": "X8EZY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZY9"
  },
  {
    "uniprot": "X8F8T1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8T1"
  },
  {
    "uniprot": "X8FIN3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIN3"
  },
  {
    "uniprot": "X8FD29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD29"
  },
  {
    "uniprot": "X8FNC9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNC9"
  },
  {
    "uniprot": "X8FFN1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFN1"
  },
  {
    "uniprot": "X8F953",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F953"
  },
  {
    "uniprot": "X8EYT6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYT6"
  },
  {
    "uniprot": "X8F912",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F912"
  },
  {
    "uniprot": "X8F042",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F042"
  },
  {
    "uniprot": "X8F5S2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5S2"
  },
  {
    "uniprot": "X8F4D1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4D1"
  },
  {
    "uniprot": "X8FBE6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBE6"
  },
  {
    "uniprot": "X8F7T8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7T8"
  },
  {
    "uniprot": "X8F3I3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3I3"
  },
  {
    "uniprot": "X8FPF0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPF0"
  },
  {
    "uniprot": "X8FKN3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKN3"
  },
  {
    "uniprot": "X8EY84",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY84"
  },
  {
    "uniprot": "X8FNQ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNQ2"
  },
  {
    "uniprot": "X8F635",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F635"
  },
  {
    "uniprot": "X8F2Z6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Z6"
  },
  {
    "uniprot": "X8FL49",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL49"
  },
  {
    "uniprot": "X8F4D2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4D2"
  },
  {
    "uniprot": "X8F073",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F073"
  },
  {
    "uniprot": "X8FCM0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCM0"
  },
  {
    "uniprot": "X8FPK3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPK3"
  },
  {
    "uniprot": "X8F8W5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8W5"
  },
  {
    "uniprot": "X8FHA0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHA0"
  },
  {
    "uniprot": "X8F8M7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8M7"
  },
  {
    "uniprot": "X8F2D9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2D9"
  },
  {
    "uniprot": "X8F561",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F561"
  },
  {
    "uniprot": "X8F4R2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4R2"
  },
  {
    "uniprot": "X8F9D1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9D1"
  },
  {
    "uniprot": "X8FM70",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM70"
  },
  {
    "uniprot": "X8FQC0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQC0"
  },
  {
    "uniprot": "X8F4K4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4K4"
  },
  {
    "uniprot": "X8FGE9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGE9"
  },
  {
    "uniprot": "X8FGI1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGI1"
  },
  {
    "uniprot": "X8F005",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F005"
  },
  {
    "uniprot": "X8FAR1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAR1"
  },
  {
    "uniprot": "X8FKS6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKS6"
  },
  {
    "uniprot": "X8F2T7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2T7"
  },
  {
    "uniprot": "X8F5S6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5S6"
  },
  {
    "uniprot": "X8EX16",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX16"
  },
  {
    "uniprot": "X8FCK5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCK5"
  },
  {
    "uniprot": "X8FP80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP80"
  },
  {
    "uniprot": "X8FES1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FES1"
  },
  {
    "uniprot": "X8F957",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F957"
  },
  {
    "uniprot": "X8F5A9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5A9"
  },
  {
    "uniprot": "X8FJT4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJT4"
  },
  {
    "uniprot": "X8FL89",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL89"
  },
  {
    "uniprot": "X8FBR2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBR2"
  },
  {
    "uniprot": "X8FLQ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLQ7"
  },
  {
    "uniprot": "X8EZM7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZM7"
  },
  {
    "uniprot": "X8F465",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F465"
  },
  {
    "uniprot": "X8EZA5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZA5"
  },
  {
    "uniprot": "X8FD17",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD17"
  },
  {
    "uniprot": "X8F331",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F331"
  },
  {
    "uniprot": "X8FH70",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH70"
  },
  {
    "uniprot": "X8F593",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F593"
  },
  {
    "uniprot": "X8F6R1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6R1"
  },
  {
    "uniprot": "X8FFQ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFQ2"
  },
  {
    "uniprot": "X8FLF8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLF8"
  },
  {
    "uniprot": "X8F9V7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9V7"
  },
  {
    "uniprot": "X8F7F5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7F5"
  },
  {
    "uniprot": "X8F0V5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0V5"
  },
  {
    "uniprot": "X8EYM7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYM7"
  },
  {
    "uniprot": "X8FB36",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB36"
  },
  {
    "uniprot": "X8F070",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F070"
  },
  {
    "uniprot": "X8F5K9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5K9"
  },
  {
    "uniprot": "X8F2W0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2W0"
  },
  {
    "uniprot": "X8FN52",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN52"
  },
  {
    "uniprot": "X8FGL7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGL7"
  },
  {
    "uniprot": "X8F8M6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8M6"
  },
  {
    "uniprot": "X8FGD3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGD3"
  },
  {
    "uniprot": "X8FIP8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIP8"
  },
  {
    "uniprot": "X8FCF9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCF9"
  },
  {
    "uniprot": "X8FCH8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCH8"
  },
  {
    "uniprot": "X8FEN7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEN7"
  },
  {
    "uniprot": "X8FCK0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCK0"
  },
  {
    "uniprot": "X8FHG0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHG0"
  },
  {
    "uniprot": "X8F5T0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5T0"
  },
  {
    "uniprot": "X8F578",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F578"
  },
  {
    "uniprot": "X8F3J3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3J3"
  },
  {
    "uniprot": "X8FNQ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNQ1"
  },
  {
    "uniprot": "X8F003",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F003"
  },
  {
    "uniprot": "X8FMD2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMD2"
  },
  {
    "uniprot": "X8F163",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F163"
  },
  {
    "uniprot": "X8F4H0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4H0"
  },
  {
    "uniprot": "X8F4G4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4G4"
  },
  {
    "uniprot": "X8F7I0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7I0"
  },
  {
    "uniprot": "X8FGT1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGT1"
  },
  {
    "uniprot": "X8FHH1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHH1"
  },
  {
    "uniprot": "X8F230",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F230"
  },
  {
    "uniprot": "X8F5M9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5M9"
  },
  {
    "uniprot": "X8FNI0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNI0"
  },
  {
    "uniprot": "X8F0J0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0J0"
  },
  {
    "uniprot": "X8F7D7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7D7"
  },
  {
    "uniprot": "X8FC61",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC61"
  },
  {
    "uniprot": "X8FB96",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB96"
  },
  {
    "uniprot": "X8FQN7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQN7"
  },
  {
    "uniprot": "X8EZZ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZZ8"
  },
  {
    "uniprot": "X8FKI2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKI2"
  },
  {
    "uniprot": "X8F069",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F069"
  },
  {
    "uniprot": "X8FME0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FME0"
  },
  {
    "uniprot": "X8FI28",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI28"
  },
  {
    "uniprot": "X8EY62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY62"
  },
  {
    "uniprot": "X8FBV2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBV2"
  },
  {
    "uniprot": "X8F8P0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8P0"
  },
  {
    "uniprot": "X8F1U4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1U4"
  },
  {
    "uniprot": "X8EYZ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYZ7"
  },
  {
    "uniprot": "X8F0X6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0X6"
  },
  {
    "uniprot": "X8FM61",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM61"
  },
  {
    "uniprot": "X8F8J5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8J5"
  },
  {
    "uniprot": "X8EZN9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZN9"
  },
  {
    "uniprot": "X8F1D7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1D7"
  },
  {
    "uniprot": "X8EX03",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX03"
  },
  {
    "uniprot": "X8F0R2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0R2"
  },
  {
    "uniprot": "X8FL39",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL39"
  },
  {
    "uniprot": "X8F789",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F789"
  },
  {
    "uniprot": "X8EY53",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY53"
  },
  {
    "uniprot": "X8FNG1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNG1"
  },
  {
    "uniprot": "X8F2I6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2I6"
  },
  {
    "uniprot": "X8FAN4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAN4"
  },
  {
    "uniprot": "X8F822",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F822"
  },
  {
    "uniprot": "X8F504",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F504"
  },
  {
    "uniprot": "X8F489",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F489"
  },
  {
    "uniprot": "X8FD19",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD19"
  },
  {
    "uniprot": "X8FMW1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMW1"
  },
  {
    "uniprot": "X8FIQ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIQ4"
  },
  {
    "uniprot": "X8F5K3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5K3"
  },
  {
    "uniprot": "X8F9G9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9G9"
  },
  {
    "uniprot": "X8F231",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F231"
  },
  {
    "uniprot": "X8FH07",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH07"
  },
  {
    "uniprot": "X8EYC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYC7"
  },
  {
    "uniprot": "X8FQ73",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQ73"
  },
  {
    "uniprot": "X8FKU8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKU8"
  },
  {
    "uniprot": "X8F0E7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0E7"
  },
  {
    "uniprot": "X8FC27",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC27"
  },
  {
    "uniprot": "X8FIE6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIE6"
  },
  {
    "uniprot": "X8FDG8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDG8"
  },
  {
    "uniprot": "X8F3T4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3T4"
  },
  {
    "uniprot": "X8FBM4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBM4"
  },
  {
    "uniprot": "X8FES6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FES6"
  },
  {
    "uniprot": "X8FFJ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFJ2"
  },
  {
    "uniprot": "X8F5J3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5J3"
  },
  {
    "uniprot": "X8F733",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F733"
  },
  {
    "uniprot": "X8FMN4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMN4"
  },
  {
    "uniprot": "X8FG68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG68"
  },
  {
    "uniprot": "X8FFZ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFZ0"
  },
  {
    "uniprot": "X8F6R8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6R8"
  },
  {
    "uniprot": "X8F9X0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9X0"
  },
  {
    "uniprot": "X8EYE9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYE9"
  },
  {
    "uniprot": "X8FJH1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJH1"
  },
  {
    "uniprot": "X8FB22",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB22"
  },
  {
    "uniprot": "X8FJB0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJB0"
  },
  {
    "uniprot": "X8F963",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F963"
  },
  {
    "uniprot": "X8F184",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F184"
  },
  {
    "uniprot": "X8FN83",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN83"
  },
  {
    "uniprot": "X8F580",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F580"
  },
  {
    "uniprot": "X8FL45",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL45"
  },
  {
    "uniprot": "X8F9S3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9S3"
  },
  {
    "uniprot": "X8F5D2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5D2"
  },
  {
    "uniprot": "X8F440",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F440"
  },
  {
    "uniprot": "X8FCS9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCS9"
  },
  {
    "uniprot": "X8F5C2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5C2"
  },
  {
    "uniprot": "X8F458",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F458"
  },
  {
    "uniprot": "X8EZA1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZA1"
  },
  {
    "uniprot": "X8FH60",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH60"
  },
  {
    "uniprot": "X8FMK6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMK6"
  },
  {
    "uniprot": "X8FK12",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK12"
  },
  {
    "uniprot": "X8F2I4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2I4"
  },
  {
    "uniprot": "X8FFD8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFD8"
  },
  {
    "uniprot": "X8FDN5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDN5"
  },
  {
    "uniprot": "X8FJN7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJN7"
  },
  {
    "uniprot": "X8FIL3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIL3"
  },
  {
    "uniprot": "X8FP47",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP47"
  },
  {
    "uniprot": "X8FA99",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA99"
  },
  {
    "uniprot": "X8FMG7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMG7"
  },
  {
    "uniprot": "X8F704",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F704"
  },
  {
    "uniprot": "X8F8T6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8T6"
  },
  {
    "uniprot": "X8FFZ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFZ5"
  },
  {
    "uniprot": "X8F8N6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8N6"
  },
  {
    "uniprot": "X8F896",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F896"
  },
  {
    "uniprot": "X8F6T0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6T0"
  },
  {
    "uniprot": "X8EZ44",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ44"
  },
  {
    "uniprot": "X8FKI7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKI7"
  },
  {
    "uniprot": "X8FPJ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPJ0"
  },
  {
    "uniprot": "X8F848",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F848"
  },
  {
    "uniprot": "X8FNV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNV8"
  },
  {
    "uniprot": "X8FD61",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD61"
  },
  {
    "uniprot": "X8FCV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCV8"
  },
  {
    "uniprot": "X8FDF0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDF0"
  },
  {
    "uniprot": "X8F5R6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5R6"
  },
  {
    "uniprot": "X8EZ09",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ09"
  },
  {
    "uniprot": "X8FHX8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHX8"
  },
  {
    "uniprot": "X8EZB1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZB1"
  },
  {
    "uniprot": "X8FQC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQC8"
  },
  {
    "uniprot": "X8FEN9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEN9"
  },
  {
    "uniprot": "X8F1S1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1S1"
  },
  {
    "uniprot": "X8EZ29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ29"
  },
  {
    "uniprot": "X8F3F6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3F6"
  },
  {
    "uniprot": "X8F4E9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4E9"
  },
  {
    "uniprot": "X8FAR2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAR2"
  },
  {
    "uniprot": "X8FBS4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBS4"
  },
  {
    "uniprot": "X8FC42",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC42"
  },
  {
    "uniprot": "X8F522",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F522"
  },
  {
    "uniprot": "X8FMM7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMM7"
  },
  {
    "uniprot": "X8F9N6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9N6"
  },
  {
    "uniprot": "X8F0U6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0U6"
  },
  {
    "uniprot": "X8FAE1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAE1"
  },
  {
    "uniprot": "X8FD62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD62"
  },
  {
    "uniprot": "X8F033",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F033"
  },
  {
    "uniprot": "X8FEI6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEI6"
  },
  {
    "uniprot": "X8FF23",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF23"
  },
  {
    "uniprot": "X8FBM5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBM5"
  },
  {
    "uniprot": "X8F5G7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5G7"
  },
  {
    "uniprot": "X8EW93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EW93"
  },
  {
    "uniprot": "X8F0T2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0T2"
  },
  {
    "uniprot": "X8FMZ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMZ0"
  },
  {
    "uniprot": "X8F4Z6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Z6"
  },
  {
    "uniprot": "X8FMD3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMD3"
  },
  {
    "uniprot": "X8F1T5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1T5"
  },
  {
    "uniprot": "X8FGB6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGB6"
  },
  {
    "uniprot": "X8F8B6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8B6"
  },
  {
    "uniprot": "X8FEJ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEJ9"
  },
  {
    "uniprot": "X8FJ47",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ47"
  },
  {
    "uniprot": "X8F1G0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1G0"
  },
  {
    "uniprot": "X8FE58",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE58"
  },
  {
    "uniprot": "X8FCE8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCE8"
  },
  {
    "uniprot": "X8F2C5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2C5"
  },
  {
    "uniprot": "X8F1W0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1W0"
  },
  {
    "uniprot": "X8EYU5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYU5"
  },
  {
    "uniprot": "X8F5X7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5X7"
  },
  {
    "uniprot": "X8FA49",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA49"
  },
  {
    "uniprot": "X8F9Q6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Q6"
  },
  {
    "uniprot": "X8FLU3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLU3"
  },
  {
    "uniprot": "X8F312",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F312"
  },
  {
    "uniprot": "X8FPD2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPD2"
  },
  {
    "uniprot": "X8F0L8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0L8"
  },
  {
    "uniprot": "X8F0V4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0V4"
  },
  {
    "uniprot": "X8FI42",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI42"
  },
  {
    "uniprot": "X8FHI0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHI0"
  },
  {
    "uniprot": "X8FC82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC82"
  },
  {
    "uniprot": "X8F4U0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4U0"
  },
  {
    "uniprot": "X8FDY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDY9"
  },
  {
    "uniprot": "X8FM81",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM81"
  },
  {
    "uniprot": "X8F535",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F535"
  },
  {
    "uniprot": "X8F7Y2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Y2"
  },
  {
    "uniprot": "X8FAS8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAS8"
  },
  {
    "uniprot": "X8F1D6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1D6"
  },
  {
    "uniprot": "X8EYM5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYM5"
  },
  {
    "uniprot": "X8EY14",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY14"
  },
  {
    "uniprot": "X8FI69",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI69"
  },
  {
    "uniprot": "X8F4C4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4C4"
  },
  {
    "uniprot": "X8FML4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FML4"
  },
  {
    "uniprot": "X8FE86",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE86"
  },
  {
    "uniprot": "X8FIB5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIB5"
  },
  {
    "uniprot": "X8F844",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F844"
  },
  {
    "uniprot": "X8FCN3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCN3"
  },
  {
    "uniprot": "X8FAV3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAV3"
  },
  {
    "uniprot": "X8F9T3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9T3"
  },
  {
    "uniprot": "X8F476",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F476"
  },
  {
    "uniprot": "X8FBN2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBN2"
  },
  {
    "uniprot": "X8FAP4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAP4"
  },
  {
    "uniprot": "X8EZP4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZP4"
  },
  {
    "uniprot": "X8FN63",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN63"
  },
  {
    "uniprot": "X8F0T4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0T4"
  },
  {
    "uniprot": "X8F8C5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8C5"
  },
  {
    "uniprot": "X8F7P0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7P0"
  },
  {
    "uniprot": "X8EZB0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZB0"
  },
  {
    "uniprot": "X8F9C0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9C0"
  },
  {
    "uniprot": "X8FFK7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFK7"
  },
  {
    "uniprot": "X8F045",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F045"
  },
  {
    "uniprot": "X8FN24",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN24"
  },
  {
    "uniprot": "X8FGW5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGW5"
  },
  {
    "uniprot": "X8EZI3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZI3"
  },
  {
    "uniprot": "X8FH11",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH11"
  },
  {
    "uniprot": "X8F2H7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2H7"
  },
  {
    "uniprot": "X8F3L0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3L0"
  },
  {
    "uniprot": "X8F681",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F681"
  },
  {
    "uniprot": "X8F7I6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7I6"
  },
  {
    "uniprot": "X8FAP6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAP6"
  },
  {
    "uniprot": "X8FDU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDU0"
  },
  {
    "uniprot": "X8F8Q2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Q2"
  },
  {
    "uniprot": "X8FQ29",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQ29"
  },
  {
    "uniprot": "X8FJP7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJP7"
  },
  {
    "uniprot": "X8F5F7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5F7"
  },
  {
    "uniprot": "X8FDW9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDW9"
  },
  {
    "uniprot": "X8FIB4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIB4"
  },
  {
    "uniprot": "X8FL71",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL71"
  },
  {
    "uniprot": "X8FGN9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGN9"
  },
  {
    "uniprot": "X8F2N4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2N4"
  },
  {
    "uniprot": "X8FDI8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDI8"
  },
  {
    "uniprot": "X8FDM8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDM8"
  },
  {
    "uniprot": "X8F5Z4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Z4"
  },
  {
    "uniprot": "X8F5R1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5R1"
  },
  {
    "uniprot": "X8FGX9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGX9"
  },
  {
    "uniprot": "X8EZT8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZT8"
  },
  {
    "uniprot": "X8FFN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFN8"
  },
  {
    "uniprot": "X8F2P0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2P0"
  },
  {
    "uniprot": "X8F9W6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9W6"
  },
  {
    "uniprot": "X8FI09",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI09"
  },
  {
    "uniprot": "X8FBN3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBN3"
  },
  {
    "uniprot": "X8F7Q0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Q0"
  },
  {
    "uniprot": "X8F265",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F265"
  },
  {
    "uniprot": "X8FFY7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFY7"
  },
  {
    "uniprot": "X8EXU6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXU6"
  },
  {
    "uniprot": "X8FE93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE93"
  },
  {
    "uniprot": "X8FPN3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPN3"
  },
  {
    "uniprot": "X8EZ76",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ76"
  },
  {
    "uniprot": "X8FLW0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLW0"
  },
  {
    "uniprot": "X8F048",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F048"
  },
  {
    "uniprot": "X8F5D9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5D9"
  },
  {
    "uniprot": "X8F128",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F128"
  },
  {
    "uniprot": "X8F3L1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3L1"
  },
  {
    "uniprot": "X8FKG1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKG1"
  },
  {
    "uniprot": "X8F7C4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7C4"
  },
  {
    "uniprot": "X8F708",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F708"
  },
  {
    "uniprot": "X8FEV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEV0"
  },
  {
    "uniprot": "X8F5S7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5S7"
  },
  {
    "uniprot": "X8FJ32",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ32"
  },
  {
    "uniprot": "X8FE11",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE11"
  },
  {
    "uniprot": "X8F853",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F853"
  },
  {
    "uniprot": "X8FHS3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHS3"
  },
  {
    "uniprot": "X8FE94",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE94"
  },
  {
    "uniprot": "X8FCA2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCA2"
  },
  {
    "uniprot": "X8FHB1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHB1"
  },
  {
    "uniprot": "X8F4E0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4E0"
  },
  {
    "uniprot": "X8FBI6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBI6"
  },
  {
    "uniprot": "X8FBZ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBZ7"
  },
  {
    "uniprot": "X8FC15",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC15"
  },
  {
    "uniprot": "X8FKS2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKS2"
  },
  {
    "uniprot": "X8FJD5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJD5"
  },
  {
    "uniprot": "X8F5B0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5B0"
  },
  {
    "uniprot": "X8F1R3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1R3"
  },
  {
    "uniprot": "X8F134",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F134"
  },
  {
    "uniprot": "X8FMG5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMG5"
  },
  {
    "uniprot": "X8FLS8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLS8"
  },
  {
    "uniprot": "X8F781",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F781"
  },
  {
    "uniprot": "X8FA46",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA46"
  },
  {
    "uniprot": "X8F525",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F525"
  },
  {
    "uniprot": "X8FEJ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEJ4"
  },
  {
    "uniprot": "X8FFT1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFT1"
  },
  {
    "uniprot": "X8F7E6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7E6"
  },
  {
    "uniprot": "X8FEL7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEL7"
  },
  {
    "uniprot": "X8FA77",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA77"
  },
  {
    "uniprot": "X8FDE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDE2"
  },
  {
    "uniprot": "X8FHY3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHY3"
  },
  {
    "uniprot": "X8F023",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F023"
  },
  {
    "uniprot": "X8FM86",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM86"
  },
  {
    "uniprot": "X8FE09",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE09"
  },
  {
    "uniprot": "X8F0W7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0W7"
  },
  {
    "uniprot": "X8FH96",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH96"
  },
  {
    "uniprot": "X8FI35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI35"
  },
  {
    "uniprot": "X8FBJ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBJ8"
  },
  {
    "uniprot": "X8F756",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F756"
  },
  {
    "uniprot": "X8FJR7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJR7"
  },
  {
    "uniprot": "X8F0R8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0R8"
  },
  {
    "uniprot": "X8F9C2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9C2"
  },
  {
    "uniprot": "X8FAM6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAM6"
  },
  {
    "uniprot": "X8FJ98",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ98"
  },
  {
    "uniprot": "X8F6S2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6S2"
  },
  {
    "uniprot": "X8F099",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F099"
  },
  {
    "uniprot": "X8F8S4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8S4"
  },
  {
    "uniprot": "X8FHH8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHH8"
  },
  {
    "uniprot": "X8FBB8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBB8"
  },
  {
    "uniprot": "X8FDK4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDK4"
  },
  {
    "uniprot": "X8F348",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F348"
  },
  {
    "uniprot": "X8FA34",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA34"
  },
  {
    "uniprot": "X8F444",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F444"
  },
  {
    "uniprot": "X8FMP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMP9"
  },
  {
    "uniprot": "X8FA68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA68"
  },
  {
    "uniprot": "X8FG97",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG97"
  },
  {
    "uniprot": "X8EZ82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ82"
  },
  {
    "uniprot": "X8F6J0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6J0"
  },
  {
    "uniprot": "X8FJM9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJM9"
  },
  {
    "uniprot": "X8F5P8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5P8"
  },
  {
    "uniprot": "X8FM96",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM96"
  },
  {
    "uniprot": "X8FNB6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNB6"
  },
  {
    "uniprot": "X8FBD1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBD1"
  },
  {
    "uniprot": "X8FKH5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKH5"
  },
  {
    "uniprot": "X8F5L5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5L5"
  },
  {
    "uniprot": "X8FKF4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKF4"
  },
  {
    "uniprot": "X8FDQ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDQ5"
  },
  {
    "uniprot": "X8FHP3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHP3"
  },
  {
    "uniprot": "X8F419",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F419"
  },
  {
    "uniprot": "X8FDA5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDA5"
  },
  {
    "uniprot": "X8F9Q1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Q1"
  },
  {
    "uniprot": "X8F3W4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3W4"
  },
  {
    "uniprot": "X8FHS1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHS1"
  },
  {
    "uniprot": "X8EXT2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXT2"
  },
  {
    "uniprot": "X8FB42",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB42"
  },
  {
    "uniprot": "X8F1K2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1K2"
  },
  {
    "uniprot": "X8F2M3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2M3"
  },
  {
    "uniprot": "X8FI51",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI51"
  },
  {
    "uniprot": "X8FBG5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBG5"
  },
  {
    "uniprot": "X8EY76",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY76"
  },
  {
    "uniprot": "X8FBW8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBW8"
  },
  {
    "uniprot": "X8FMT3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMT3"
  },
  {
    "uniprot": "X8FD36",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD36"
  },
  {
    "uniprot": "X8FBY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBY5"
  },
  {
    "uniprot": "X8FN74",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN74"
  },
  {
    "uniprot": "X8FAB7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAB7"
  },
  {
    "uniprot": "X8FM99",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM99"
  },
  {
    "uniprot": "X8F425",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F425"
  },
  {
    "uniprot": "X8FHX7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHX7"
  },
  {
    "uniprot": "X8F4W6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4W6"
  },
  {
    "uniprot": "X8FM98",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM98"
  },
  {
    "uniprot": "X8FIP0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIP0"
  },
  {
    "uniprot": "X8F3R3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3R3"
  },
  {
    "uniprot": "X8F7S7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7S7"
  },
  {
    "uniprot": "X8EZL3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZL3"
  },
  {
    "uniprot": "X8F0Q2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Q2"
  },
  {
    "uniprot": "X8FM65",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM65"
  },
  {
    "uniprot": "X8FH23",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH23"
  },
  {
    "uniprot": "X8FFE6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFE6"
  },
  {
    "uniprot": "X8FFY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFY5"
  },
  {
    "uniprot": "X8F4Y8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Y8"
  },
  {
    "uniprot": "X8FBE3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBE3"
  },
  {
    "uniprot": "X8F2L3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2L3"
  },
  {
    "uniprot": "X8F909",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F909"
  },
  {
    "uniprot": "X8FCX1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCX1"
  },
  {
    "uniprot": "X8EZZ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZZ7"
  },
  {
    "uniprot": "X8F6T3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6T3"
  },
  {
    "uniprot": "X8F492",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F492"
  },
  {
    "uniprot": "X8FII9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FII9"
  },
  {
    "uniprot": "X8FEM1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEM1"
  },
  {
    "uniprot": "X8F4H9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4H9"
  },
  {
    "uniprot": "X8EZD5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZD5"
  },
  {
    "uniprot": "X8FG55",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG55"
  },
  {
    "uniprot": "X8F9T6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9T6"
  },
  {
    "uniprot": "X8FA67",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA67"
  },
  {
    "uniprot": "X8EWV4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWV4"
  },
  {
    "uniprot": "X8FK99",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK99"
  },
  {
    "uniprot": "X8F3A9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3A9"
  },
  {
    "uniprot": "X8FC74",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC74"
  },
  {
    "uniprot": "X8F0B4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0B4"
  },
  {
    "uniprot": "X8FFV3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFV3"
  },
  {
    "uniprot": "X8FE91",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE91"
  },
  {
    "uniprot": "X8FF20",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF20"
  },
  {
    "uniprot": "X8FN38",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN38"
  },
  {
    "uniprot": "X8FLB5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLB5"
  },
  {
    "uniprot": "X8FAE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAE2"
  },
  {
    "uniprot": "X8FBQ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBQ0"
  },
  {
    "uniprot": "X8FM93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM93"
  },
  {
    "uniprot": "X8FCP1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCP1"
  },
  {
    "uniprot": "X8FG35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG35"
  },
  {
    "uniprot": "X8FBT2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBT2"
  },
  {
    "uniprot": "X8F937",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F937"
  },
  {
    "uniprot": "X8F4K7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4K7"
  },
  {
    "uniprot": "X8FHL1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHL1"
  },
  {
    "uniprot": "X8FLI7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLI7"
  },
  {
    "uniprot": "X8F1W1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1W1"
  },
  {
    "uniprot": "X8FDY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDY5"
  },
  {
    "uniprot": "X8FNL1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNL1"
  },
  {
    "uniprot": "X8FK17",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK17"
  },
  {
    "uniprot": "X8FKJ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKJ6"
  },
  {
    "uniprot": "X8FN96",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN96"
  },
  {
    "uniprot": "X8F428",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F428"
  },
  {
    "uniprot": "X8FLS7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLS7"
  },
  {
    "uniprot": "X8F2R2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2R2"
  },
  {
    "uniprot": "X8FAB0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAB0"
  },
  {
    "uniprot": "X8F4G8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4G8"
  },
  {
    "uniprot": "X8F1T4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1T4"
  },
  {
    "uniprot": "X8FJ22",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ22"
  },
  {
    "uniprot": "X8F2C6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2C6"
  },
  {
    "uniprot": "X8FEZ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEZ3"
  },
  {
    "uniprot": "X8F9E5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9E5"
  },
  {
    "uniprot": "X8FDC9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDC9"
  },
  {
    "uniprot": "X8FC50",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC50"
  },
  {
    "uniprot": "X8F814",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F814"
  },
  {
    "uniprot": "X8F3V9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3V9"
  },
  {
    "uniprot": "X8EZJ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZJ1"
  },
  {
    "uniprot": "X8F0I9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0I9"
  },
  {
    "uniprot": "X8FGT5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGT5"
  },
  {
    "uniprot": "X8FB82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB82"
  },
  {
    "uniprot": "X8FD97",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD97"
  },
  {
    "uniprot": "X8FEC4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEC4"
  },
  {
    "uniprot": "X8F1P5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1P5"
  },
  {
    "uniprot": "X8FME2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FME2"
  },
  {
    "uniprot": "X8FFC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFC8"
  },
  {
    "uniprot": "X8F0H0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0H0"
  },
  {
    "uniprot": "X8FER8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FER8"
  },
  {
    "uniprot": "X8F9Y9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Y9"
  },
  {
    "uniprot": "X8EY49",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY49"
  },
  {
    "uniprot": "X8EZW0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZW0"
  },
  {
    "uniprot": "X8F4P1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4P1"
  },
  {
    "uniprot": "X8FBH4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBH4"
  },
  {
    "uniprot": "X8FCZ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCZ9"
  },
  {
    "uniprot": "X8F3C8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3C8"
  },
  {
    "uniprot": "X8FKX6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKX6"
  },
  {
    "uniprot": "X8F2R4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2R4"
  },
  {
    "uniprot": "X8EX33",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX33"
  },
  {
    "uniprot": "X8F637",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F637"
  },
  {
    "uniprot": "X8F6V9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6V9"
  },
  {
    "uniprot": "X8FIY6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIY6"
  },
  {
    "uniprot": "X8F1A6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1A6"
  },
  {
    "uniprot": "X8FG67",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG67"
  },
  {
    "uniprot": "X8F0P8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0P8"
  },
  {
    "uniprot": "X8FGJ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGJ7"
  },
  {
    "uniprot": "X8FAW9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAW9"
  },
  {
    "uniprot": "X8F0Z2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Z2"
  },
  {
    "uniprot": "X8FER4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FER4"
  },
  {
    "uniprot": "X8F3W0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3W0"
  },
  {
    "uniprot": "X8FFH4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFH4"
  },
  {
    "uniprot": "X8FF57",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF57"
  },
  {
    "uniprot": "X8F0H1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0H1"
  },
  {
    "uniprot": "X8F8E4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8E4"
  },
  {
    "uniprot": "X8FAK5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAK5"
  },
  {
    "uniprot": "X8FN42",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN42"
  },
  {
    "uniprot": "X8F5J9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5J9"
  },
  {
    "uniprot": "X8FKG3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKG3"
  },
  {
    "uniprot": "X8EYX3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYX3"
  },
  {
    "uniprot": "X8FGN0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGN0"
  },
  {
    "uniprot": "X8F284",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F284"
  },
  {
    "uniprot": "X8F565",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F565"
  },
  {
    "uniprot": "X8FDG6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDG6"
  },
  {
    "uniprot": "X8F2N7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2N7"
  },
  {
    "uniprot": "X8FH53",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH53"
  },
  {
    "uniprot": "X8F8X2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8X2"
  },
  {
    "uniprot": "X8F6Q0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Q0"
  },
  {
    "uniprot": "X8FM35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM35"
  },
  {
    "uniprot": "X8FK56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK56"
  },
  {
    "uniprot": "X8F864",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F864"
  },
  {
    "uniprot": "X8FPD0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPD0"
  },
  {
    "uniprot": "X8FD12",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD12"
  },
  {
    "uniprot": "X8F9S7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9S7"
  },
  {
    "uniprot": "X8FPP5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPP5"
  },
  {
    "uniprot": "X8FK07",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK07"
  },
  {
    "uniprot": "X8F395",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F395"
  },
  {
    "uniprot": "X8FKG4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKG4"
  },
  {
    "uniprot": "X8FGK2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGK2"
  },
  {
    "uniprot": "X8FBU5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBU5"
  },
  {
    "uniprot": "X8F964",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F964"
  },
  {
    "uniprot": "X8FAT4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAT4"
  },
  {
    "uniprot": "X8FB30",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB30"
  },
  {
    "uniprot": "X8FEK1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEK1"
  },
  {
    "uniprot": "X8FMT5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMT5"
  },
  {
    "uniprot": "X8F5G9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5G9"
  },
  {
    "uniprot": "X8F8A4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8A4"
  },
  {
    "uniprot": "X8F0C5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0C5"
  },
  {
    "uniprot": "X8FFD3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFD3"
  },
  {
    "uniprot": "X8F8R7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8R7"
  },
  {
    "uniprot": "X8F454",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F454"
  },
  {
    "uniprot": "X8FH32",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH32"
  },
  {
    "uniprot": "X8FPB2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPB2"
  },
  {
    "uniprot": "X8FCH7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCH7"
  },
  {
    "uniprot": "X8F9U5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9U5"
  },
  {
    "uniprot": "X8FGH8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGH8"
  },
  {
    "uniprot": "X8FL22",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL22"
  },
  {
    "uniprot": "X8FGR3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGR3"
  },
  {
    "uniprot": "X8F243",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F243"
  },
  {
    "uniprot": "X8F426",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F426"
  },
  {
    "uniprot": "X8FHU4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHU4"
  },
  {
    "uniprot": "X8F943",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F943"
  },
  {
    "uniprot": "X8F8C9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8C9"
  },
  {
    "uniprot": "X8FL43",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL43"
  },
  {
    "uniprot": "X8F9D8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9D8"
  },
  {
    "uniprot": "X8F6L4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6L4"
  },
  {
    "uniprot": "X8FB09",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB09"
  },
  {
    "uniprot": "X8F402",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F402"
  },
  {
    "uniprot": "X8F8B4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8B4"
  },
  {
    "uniprot": "X8EZC5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZC5"
  },
  {
    "uniprot": "X8F933",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F933"
  },
  {
    "uniprot": "X8F6E8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6E8"
  },
  {
    "uniprot": "X8FAY4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAY4"
  },
  {
    "uniprot": "X8EZI0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZI0"
  },
  {
    "uniprot": "X8F332",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F332"
  },
  {
    "uniprot": "X8FJI4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJI4"
  },
  {
    "uniprot": "X8F9Z3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Z3"
  },
  {
    "uniprot": "X8FP40",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP40"
  },
  {
    "uniprot": "X8FIX5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIX5"
  },
  {
    "uniprot": "X8F743",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F743"
  },
  {
    "uniprot": "X8FPJ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPJ9"
  },
  {
    "uniprot": "X8FCK4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCK4"
  },
  {
    "uniprot": "X8FDQ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDQ9"
  },
  {
    "uniprot": "X8F3T1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3T1"
  },
  {
    "uniprot": "X8FCH5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCH5"
  },
  {
    "uniprot": "X8F1P9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1P9"
  },
  {
    "uniprot": "X8F2Y1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Y1"
  },
  {
    "uniprot": "X8FH61",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH61"
  },
  {
    "uniprot": "X8EZX5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZX5"
  },
  {
    "uniprot": "X8FM76",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM76"
  },
  {
    "uniprot": "X8F9U2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9U2"
  },
  {
    "uniprot": "X8FHU5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHU5"
  },
  {
    "uniprot": "X8FN30",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN30"
  },
  {
    "uniprot": "X8F793",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F793"
  },
  {
    "uniprot": "X8F9K4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9K4"
  },
  {
    "uniprot": "X8F049",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F049"
  },
  {
    "uniprot": "X8FFG1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFG1"
  },
  {
    "uniprot": "X8F1S4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1S4"
  },
  {
    "uniprot": "X8FBK8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBK8"
  },
  {
    "uniprot": "X8FIW8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIW8"
  },
  {
    "uniprot": "X8FFS0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFS0"
  },
  {
    "uniprot": "X8FHQ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHQ8"
  },
  {
    "uniprot": "X8FHL4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHL4"
  },
  {
    "uniprot": "X8F1Q2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Q2"
  },
  {
    "uniprot": "X8F6U4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6U4"
  },
  {
    "uniprot": "X8F2Z4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Z4"
  },
  {
    "uniprot": "X8FJU6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJU6"
  },
  {
    "uniprot": "X8FF06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF06"
  },
  {
    "uniprot": "X8F993",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F993"
  },
  {
    "uniprot": "X8FDI1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDI1"
  },
  {
    "uniprot": "X8FG79",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG79"
  },
  {
    "uniprot": "X8FMV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMV8"
  },
  {
    "uniprot": "X8F191",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F191"
  },
  {
    "uniprot": "X8F305",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F305"
  },
  {
    "uniprot": "X8F7A6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7A6"
  },
  {
    "uniprot": "X8FB58",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB58"
  },
  {
    "uniprot": "X8F3H0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3H0"
  },
  {
    "uniprot": "X8FJK6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJK6"
  },
  {
    "uniprot": "X8F0K8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0K8"
  },
  {
    "uniprot": "X8F3M0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3M0"
  },
  {
    "uniprot": "X8F2R1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2R1"
  },
  {
    "uniprot": "X8EX96",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX96"
  },
  {
    "uniprot": "X8FIF1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIF1"
  },
  {
    "uniprot": "X8FM71",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM71"
  },
  {
    "uniprot": "X8F8D9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8D9"
  },
  {
    "uniprot": "X8FFP4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFP4"
  },
  {
    "uniprot": "X8FJW7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJW7"
  },
  {
    "uniprot": "X8FIP4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIP4"
  },
  {
    "uniprot": "X8FEQ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEQ7"
  },
  {
    "uniprot": "X8FC17",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC17"
  },
  {
    "uniprot": "X8FE52",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE52"
  },
  {
    "uniprot": "X8F5P7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5P7"
  },
  {
    "uniprot": "X8F6D3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6D3"
  },
  {
    "uniprot": "X8EYL3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYL3"
  },
  {
    "uniprot": "X8F0P4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0P4"
  },
  {
    "uniprot": "X8F882",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F882"
  },
  {
    "uniprot": "X8FLH9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLH9"
  },
  {
    "uniprot": "X8FL88",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL88"
  },
  {
    "uniprot": "X8FM19",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM19"
  },
  {
    "uniprot": "X8FHT0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHT0"
  },
  {
    "uniprot": "X8FH55",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH55"
  },
  {
    "uniprot": "X8F7B7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7B7"
  },
  {
    "uniprot": "X8EXF1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXF1"
  },
  {
    "uniprot": "X8F5D5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5D5"
  },
  {
    "uniprot": "X8FC56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC56"
  },
  {
    "uniprot": "X8F0X4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0X4"
  },
  {
    "uniprot": "X8FIV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIV8"
  },
  {
    "uniprot": "X8EZL5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZL5"
  },
  {
    "uniprot": "X8F2J4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2J4"
  },
  {
    "uniprot": "X8F7X2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7X2"
  },
  {
    "uniprot": "X8F7L3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7L3"
  },
  {
    "uniprot": "X8F1I4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1I4"
  },
  {
    "uniprot": "X8EYY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYY9"
  },
  {
    "uniprot": "X8F462",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F462"
  },
  {
    "uniprot": "X8F3D2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3D2"
  },
  {
    "uniprot": "X8FGR7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGR7"
  },
  {
    "uniprot": "X8FG16",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG16"
  },
  {
    "uniprot": "X8FHQ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHQ7"
  },
  {
    "uniprot": "X8F5F5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5F5"
  },
  {
    "uniprot": "X8FGK0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGK0"
  },
  {
    "uniprot": "X8F986",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F986"
  },
  {
    "uniprot": "X8FAY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAY5"
  },
  {
    "uniprot": "X8F751",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F751"
  },
  {
    "uniprot": "X8F2E5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2E5"
  },
  {
    "uniprot": "X8FKX4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKX4"
  },
  {
    "uniprot": "X8FBY6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBY6"
  },
  {
    "uniprot": "X8FHC7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHC7"
  },
  {
    "uniprot": "X8F4N1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4N1"
  },
  {
    "uniprot": "X8FKS5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKS5"
  },
  {
    "uniprot": "X8F5U6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5U6"
  },
  {
    "uniprot": "X8F1X9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1X9"
  },
  {
    "uniprot": "X8FDG5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDG5"
  },
  {
    "uniprot": "X8FMX8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMX8"
  },
  {
    "uniprot": "X8FB98",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB98"
  },
  {
    "uniprot": "X8FKM1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKM1"
  },
  {
    "uniprot": "X8EZT0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZT0"
  },
  {
    "uniprot": "X8FEB8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEB8"
  },
  {
    "uniprot": "X8FA35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA35"
  },
  {
    "uniprot": "X8FE07",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE07"
  },
  {
    "uniprot": "X8EYH4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYH4"
  },
  {
    "uniprot": "X8F9D3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9D3"
  },
  {
    "uniprot": "X8FHB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHB3"
  },
  {
    "uniprot": "X8FF11",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF11"
  },
  {
    "uniprot": "X8F4G0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4G0"
  },
  {
    "uniprot": "X8F6R5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6R5"
  },
  {
    "uniprot": "X8F987",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F987"
  },
  {
    "uniprot": "X8F663",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F663"
  },
  {
    "uniprot": "X8FLT5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLT5"
  },
  {
    "uniprot": "X8FG46",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG46"
  },
  {
    "uniprot": "X8EWP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWP9"
  },
  {
    "uniprot": "X8EXS9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXS9"
  },
  {
    "uniprot": "X8F9N0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9N0"
  },
  {
    "uniprot": "X8FJS9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJS9"
  },
  {
    "uniprot": "X8EYK4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYK4"
  },
  {
    "uniprot": "X8FI36",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI36"
  },
  {
    "uniprot": "X8F1Q5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Q5"
  },
  {
    "uniprot": "X8F7B0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7B0"
  },
  {
    "uniprot": "X8F9S0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9S0"
  },
  {
    "uniprot": "X8FMH8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMH8"
  },
  {
    "uniprot": "X8EZC4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZC4"
  },
  {
    "uniprot": "X8FCE7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCE7"
  },
  {
    "uniprot": "X8EZU5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZU5"
  },
  {
    "uniprot": "X8FJ34",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ34"
  },
  {
    "uniprot": "X8F2G2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2G2"
  },
  {
    "uniprot": "X8F6N0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6N0"
  },
  {
    "uniprot": "X8F8M4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8M4"
  },
  {
    "uniprot": "X8FMC3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMC3"
  },
  {
    "uniprot": "X8F960",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F960"
  },
  {
    "uniprot": "X8F8G4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8G4"
  },
  {
    "uniprot": "X8FD70",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD70"
  },
  {
    "uniprot": "X8F595",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F595"
  },
  {
    "uniprot": "X8F3H2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3H2"
  },
  {
    "uniprot": "X8F0Y8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Y8"
  },
  {
    "uniprot": "X8FGW6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGW6"
  },
  {
    "uniprot": "X8F0X9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0X9"
  },
  {
    "uniprot": "X8FK78",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK78"
  },
  {
    "uniprot": "X8F4T5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4T5"
  },
  {
    "uniprot": "X8EY64",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY64"
  },
  {
    "uniprot": "X8F2S6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2S6"
  },
  {
    "uniprot": "X8F3N0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3N0"
  },
  {
    "uniprot": "X8F3P0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3P0"
  },
  {
    "uniprot": "X8FNB5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNB5"
  },
  {
    "uniprot": "X8F0H8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0H8"
  },
  {
    "uniprot": "X8F0D2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0D2"
  },
  {
    "uniprot": "X8FCD0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCD0"
  },
  {
    "uniprot": "X8FJ73",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ73"
  },
  {
    "uniprot": "X8FIA7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIA7"
  },
  {
    "uniprot": "X8FLM7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLM7"
  },
  {
    "uniprot": "X8FFL2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFL2"
  },
  {
    "uniprot": "X8FJW9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJW9"
  },
  {
    "uniprot": "X8FJM5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJM5"
  },
  {
    "uniprot": "X8F9Z7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Z7"
  },
  {
    "uniprot": "X8FAP8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAP8"
  },
  {
    "uniprot": "X8FGZ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGZ4"
  },
  {
    "uniprot": "X8FCL2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCL2"
  },
  {
    "uniprot": "X8F0D0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0D0"
  },
  {
    "uniprot": "X8FFX5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFX5"
  },
  {
    "uniprot": "X8FE69",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE69"
  },
  {
    "uniprot": "X8EZ43",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ43"
  },
  {
    "uniprot": "X8FIS5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIS5"
  },
  {
    "uniprot": "X8FEP1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEP1"
  },
  {
    "uniprot": "X8F2F7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2F7"
  },
  {
    "uniprot": "X8EY68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY68"
  },
  {
    "uniprot": "X8F8C0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8C0"
  },
  {
    "uniprot": "X8FAY7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAY7"
  },
  {
    "uniprot": "X8EZ06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ06"
  },
  {
    "uniprot": "X8F948",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F948"
  },
  {
    "uniprot": "X8F6X6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6X6"
  },
  {
    "uniprot": "X8F911",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F911"
  },
  {
    "uniprot": "X8F0U8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0U8"
  },
  {
    "uniprot": "X8F7X9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7X9"
  },
  {
    "uniprot": "X8EXV3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXV3"
  },
  {
    "uniprot": "X8EY10",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY10"
  },
  {
    "uniprot": "X8FC83",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC83"
  },
  {
    "uniprot": "X8FKQ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKQ4"
  },
  {
    "uniprot": "X8FMH2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMH2"
  },
  {
    "uniprot": "X8FDM3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDM3"
  },
  {
    "uniprot": "X8F9J8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9J8"
  },
  {
    "uniprot": "X8FBL3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBL3"
  },
  {
    "uniprot": "X8F7Q5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Q5"
  },
  {
    "uniprot": "X8FFW0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFW0"
  },
  {
    "uniprot": "X8FAE9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAE9"
  },
  {
    "uniprot": "X8FLW1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLW1"
  },
  {
    "uniprot": "X8FCW2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCW2"
  },
  {
    "uniprot": "X8FG53",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG53"
  },
  {
    "uniprot": "X8F8Y5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Y5"
  },
  {
    "uniprot": "X8F430",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F430"
  },
  {
    "uniprot": "X8FBB6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBB6"
  },
  {
    "uniprot": "X8F2B0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2B0"
  },
  {
    "uniprot": "X8FPU5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPU5"
  },
  {
    "uniprot": "X8F0D6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0D6"
  },
  {
    "uniprot": "X8F2H8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2H8"
  },
  {
    "uniprot": "X8FL12",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL12"
  },
  {
    "uniprot": "X8F687",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F687"
  },
  {
    "uniprot": "X8FPM7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPM7"
  },
  {
    "uniprot": "X8FJ40",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ40"
  },
  {
    "uniprot": "X8FFT8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFT8"
  },
  {
    "uniprot": "X8F0Y0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Y0"
  },
  {
    "uniprot": "X8F2Z8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Z8"
  },
  {
    "uniprot": "X8EY23",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY23"
  },
  {
    "uniprot": "X8F5B2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5B2"
  },
  {
    "uniprot": "X8FCV1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCV1"
  },
  {
    "uniprot": "X8F3Q1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Q1"
  },
  {
    "uniprot": "X8FL66",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL66"
  },
  {
    "uniprot": "X8FME9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FME9"
  },
  {
    "uniprot": "X8F3K1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3K1"
  },
  {
    "uniprot": "X8F3B5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3B5"
  },
  {
    "uniprot": "X8FG69",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG69"
  },
  {
    "uniprot": "X8FJM4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJM4"
  },
  {
    "uniprot": "X8F981",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F981"
  },
  {
    "uniprot": "X8EZ88",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ88"
  },
  {
    "uniprot": "X8F2E8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2E8"
  },
  {
    "uniprot": "X8FK74",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK74"
  },
  {
    "uniprot": "X8FBL1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBL1"
  },
  {
    "uniprot": "X8FJC3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJC3"
  },
  {
    "uniprot": "X8FFL3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFL3"
  },
  {
    "uniprot": "X8F474",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F474"
  },
  {
    "uniprot": "X8FB04",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB04"
  },
  {
    "uniprot": "X8F378",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F378"
  },
  {
    "uniprot": "X8F7D1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7D1"
  },
  {
    "uniprot": "X8EZ71",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ71"
  },
  {
    "uniprot": "X8FCH1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCH1"
  },
  {
    "uniprot": "X8F4R0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4R0"
  },
  {
    "uniprot": "X8F8E0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8E0"
  },
  {
    "uniprot": "X8F2Q6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Q6"
  },
  {
    "uniprot": "X8FHQ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHQ1"
  },
  {
    "uniprot": "X8F6G8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6G8"
  },
  {
    "uniprot": "X8FJH6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJH6"
  },
  {
    "uniprot": "X8F613",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F613"
  },
  {
    "uniprot": "X8FD66",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD66"
  },
  {
    "uniprot": "X8FM39",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM39"
  },
  {
    "uniprot": "X8EZD9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZD9"
  },
  {
    "uniprot": "X8F7S6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7S6"
  },
  {
    "uniprot": "X8F662",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F662"
  },
  {
    "uniprot": "X8FFG5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFG5"
  },
  {
    "uniprot": "X8EYS2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYS2"
  },
  {
    "uniprot": "X8FN93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN93"
  },
  {
    "uniprot": "X8FEG9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEG9"
  },
  {
    "uniprot": "X8EZ19",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ19"
  },
  {
    "uniprot": "X8EZE0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZE0"
  },
  {
    "uniprot": "X8FDR6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDR6"
  },
  {
    "uniprot": "X8FCH2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCH2"
  },
  {
    "uniprot": "X8F3I7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3I7"
  },
  {
    "uniprot": "X8FIS9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIS9"
  },
  {
    "uniprot": "X8FQ22",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQ22"
  },
  {
    "uniprot": "X8F8W1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8W1"
  },
  {
    "uniprot": "X8FF73",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF73"
  },
  {
    "uniprot": "X8F3H1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3H1"
  },
  {
    "uniprot": "X8F4N7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4N7"
  },
  {
    "uniprot": "X8FE44",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE44"
  },
  {
    "uniprot": "X8F1V1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1V1"
  },
  {
    "uniprot": "X8F7Y9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Y9"
  },
  {
    "uniprot": "X8F5Q2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Q2"
  },
  {
    "uniprot": "X8FCV3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCV3"
  },
  {
    "uniprot": "X8FLX2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLX2"
  },
  {
    "uniprot": "X8F9J1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9J1"
  },
  {
    "uniprot": "X8FKI5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKI5"
  },
  {
    "uniprot": "X8FD26",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD26"
  },
  {
    "uniprot": "X8FBI8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBI8"
  },
  {
    "uniprot": "X8F3F9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3F9"
  },
  {
    "uniprot": "X8FPP4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPP4"
  },
  {
    "uniprot": "X8F6V7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6V7"
  },
  {
    "uniprot": "X8FF49",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF49"
  },
  {
    "uniprot": "X8FJQ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJQ5"
  },
  {
    "uniprot": "X8FKR5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKR5"
  },
  {
    "uniprot": "X8FBX5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBX5"
  },
  {
    "uniprot": "X8FI27",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI27"
  },
  {
    "uniprot": "X8FG48",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG48"
  },
  {
    "uniprot": "X8F031",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F031"
  },
  {
    "uniprot": "X8F0A9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0A9"
  },
  {
    "uniprot": "X8F339",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F339"
  },
  {
    "uniprot": "X8FK14",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK14"
  },
  {
    "uniprot": "X8F2R8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2R8"
  },
  {
    "uniprot": "X8F4Q3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Q3"
  },
  {
    "uniprot": "X8F448",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F448"
  },
  {
    "uniprot": "X8F4V7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4V7"
  },
  {
    "uniprot": "X8F6W8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6W8"
  },
  {
    "uniprot": "X8FGQ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGQ8"
  },
  {
    "uniprot": "X8F2J2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2J2"
  },
  {
    "uniprot": "X8FBA1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBA1"
  },
  {
    "uniprot": "X8F4R4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4R4"
  },
  {
    "uniprot": "X8F534",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F534"
  },
  {
    "uniprot": "X8FDZ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDZ5"
  },
  {
    "uniprot": "X8FI18",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI18"
  },
  {
    "uniprot": "X8F0M6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0M6"
  },
  {
    "uniprot": "X8F0I6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0I6"
  },
  {
    "uniprot": "X8FE31",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE31"
  },
  {
    "uniprot": "X8F8G0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8G0"
  },
  {
    "uniprot": "X8F8T5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8T5"
  },
  {
    "uniprot": "X8F3W5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3W5"
  },
  {
    "uniprot": "X8F2A8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2A8"
  },
  {
    "uniprot": "X8F0M8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0M8"
  },
  {
    "uniprot": "X8EZP8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZP8"
  },
  {
    "uniprot": "X8F9R4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9R4"
  },
  {
    "uniprot": "X8FHP8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHP8"
  },
  {
    "uniprot": "X8FBL5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBL5"
  },
  {
    "uniprot": "X8FPE1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPE1"
  },
  {
    "uniprot": "X8FC93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC93"
  },
  {
    "uniprot": "X8EXV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXV0"
  },
  {
    "uniprot": "X8F0R5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0R5"
  },
  {
    "uniprot": "X8FIF5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIF5"
  },
  {
    "uniprot": "X8EZ63",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ63"
  },
  {
    "uniprot": "X8EYV5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYV5"
  },
  {
    "uniprot": "X8F8I3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8I3"
  },
  {
    "uniprot": "X8F4U6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4U6"
  },
  {
    "uniprot": "X8F497",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F497"
  },
  {
    "uniprot": "X8FCY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCY5"
  },
  {
    "uniprot": "X8FDW8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDW8"
  },
  {
    "uniprot": "X8F8P3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8P3"
  },
  {
    "uniprot": "X8FI87",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI87"
  },
  {
    "uniprot": "X8F3T3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3T3"
  },
  {
    "uniprot": "X8FCX3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCX3"
  },
  {
    "uniprot": "X8FG70",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG70"
  },
  {
    "uniprot": "X8F7V0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7V0"
  },
  {
    "uniprot": "X8F5Y9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Y9"
  },
  {
    "uniprot": "X8FCF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCF3"
  },
  {
    "uniprot": "X8FB94",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB94"
  },
  {
    "uniprot": "X8FQ36",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQ36"
  },
  {
    "uniprot": "X8F686",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F686"
  },
  {
    "uniprot": "X8FN68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN68"
  },
  {
    "uniprot": "X8FJ49",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ49"
  },
  {
    "uniprot": "X8FDU6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDU6"
  },
  {
    "uniprot": "X8FCD1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCD1"
  },
  {
    "uniprot": "X8FFU7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFU7"
  },
  {
    "uniprot": "X8F677",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F677"
  },
  {
    "uniprot": "X8F2K8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2K8"
  },
  {
    "uniprot": "X8F141",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F141"
  },
  {
    "uniprot": "X8FGK1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGK1"
  },
  {
    "uniprot": "X8FMC5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMC5"
  },
  {
    "uniprot": "X8F7W1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7W1"
  },
  {
    "uniprot": "X8FEY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEY9"
  },
  {
    "uniprot": "X8F6Y8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Y8"
  },
  {
    "uniprot": "X8F665",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F665"
  },
  {
    "uniprot": "X8F427",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F427"
  },
  {
    "uniprot": "X8F4X3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4X3"
  },
  {
    "uniprot": "X8FKV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKV0"
  },
  {
    "uniprot": "X8FFQ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFQ7"
  },
  {
    "uniprot": "X8FA97",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA97"
  },
  {
    "uniprot": "X8F608",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F608"
  },
  {
    "uniprot": "X8F798",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F798"
  },
  {
    "uniprot": "X8F7A0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7A0"
  },
  {
    "uniprot": "X8FDH4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDH4"
  },
  {
    "uniprot": "X8EYL9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYL9"
  },
  {
    "uniprot": "X8EX00",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX00"
  },
  {
    "uniprot": "X8F768",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F768"
  },
  {
    "uniprot": "X8FIA4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIA4"
  },
  {
    "uniprot": "X8F3M7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3M7"
  },
  {
    "uniprot": "X8F1E0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1E0"
  },
  {
    "uniprot": "X8FNI8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNI8"
  },
  {
    "uniprot": "X8FIQ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIQ8"
  },
  {
    "uniprot": "X8EXL3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXL3"
  },
  {
    "uniprot": "X8F1N5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1N5"
  },
  {
    "uniprot": "X8FJ50",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ50"
  },
  {
    "uniprot": "X8FJ70",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ70"
  },
  {
    "uniprot": "X8FL73",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL73"
  },
  {
    "uniprot": "X8F2L2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2L2"
  },
  {
    "uniprot": "X8FJZ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJZ1"
  },
  {
    "uniprot": "X8F1K5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1K5"
  },
  {
    "uniprot": "X8F6E1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6E1"
  },
  {
    "uniprot": "X8FB77",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB77"
  },
  {
    "uniprot": "X8F3Z2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Z2"
  },
  {
    "uniprot": "X8FP97",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP97"
  },
  {
    "uniprot": "X8FI03",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI03"
  },
  {
    "uniprot": "X8FJI8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJI8"
  },
  {
    "uniprot": "X8F910",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F910"
  },
  {
    "uniprot": "X8F032",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F032"
  },
  {
    "uniprot": "X8FCN0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCN0"
  },
  {
    "uniprot": "X8F5M7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5M7"
  },
  {
    "uniprot": "X8FLU0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLU0"
  },
  {
    "uniprot": "X8F0E2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0E2"
  },
  {
    "uniprot": "X8F9C4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9C4"
  },
  {
    "uniprot": "X8FKM0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKM0"
  },
  {
    "uniprot": "X8EXY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXY1"
  },
  {
    "uniprot": "X8FHW9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHW9"
  },
  {
    "uniprot": "X8FNN4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNN4"
  },
  {
    "uniprot": "X8EWF6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWF6"
  },
  {
    "uniprot": "X8FB50",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB50"
  },
  {
    "uniprot": "X8FPT9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPT9"
  },
  {
    "uniprot": "X8FHG3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHG3"
  },
  {
    "uniprot": "X8F634",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F634"
  },
  {
    "uniprot": "X8F146",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F146"
  },
  {
    "uniprot": "X8F8C7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8C7"
  },
  {
    "uniprot": "X8F7J7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7J7"
  },
  {
    "uniprot": "X8F8Q1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Q1"
  },
  {
    "uniprot": "X8EWP2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWP2"
  },
  {
    "uniprot": "X8FFA4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFA4"
  },
  {
    "uniprot": "X8FE33",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE33"
  },
  {
    "uniprot": "X8F4J4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4J4"
  },
  {
    "uniprot": "X8FJA3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJA3"
  },
  {
    "uniprot": "X8FKD0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKD0"
  },
  {
    "uniprot": "X8F828",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F828"
  },
  {
    "uniprot": "X8FEI7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEI7"
  },
  {
    "uniprot": "X8F1I2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1I2"
  },
  {
    "uniprot": "X8F7T3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7T3"
  },
  {
    "uniprot": "X8FJT6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJT6"
  },
  {
    "uniprot": "X8FA55",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA55"
  },
  {
    "uniprot": "X8F7F8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7F8"
  },
  {
    "uniprot": "X8FAP5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAP5"
  },
  {
    "uniprot": "X8F418",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F418"
  },
  {
    "uniprot": "X8FL77",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL77"
  },
  {
    "uniprot": "X8FLE9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLE9"
  },
  {
    "uniprot": "X8FLK2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLK2"
  },
  {
    "uniprot": "X8FLW6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLW6"
  },
  {
    "uniprot": "X8FI86",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI86"
  },
  {
    "uniprot": "X8EWG3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWG3"
  },
  {
    "uniprot": "X8FDU4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDU4"
  },
  {
    "uniprot": "X8F5S9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5S9"
  },
  {
    "uniprot": "X8F5Z8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Z8"
  },
  {
    "uniprot": "X8FA75",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA75"
  },
  {
    "uniprot": "X8EWK7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWK7"
  },
  {
    "uniprot": "X8FM09",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM09"
  },
  {
    "uniprot": "X8FMD0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMD0"
  },
  {
    "uniprot": "X8FGZ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGZ6"
  },
  {
    "uniprot": "X8FDZ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDZ0"
  },
  {
    "uniprot": "X8F0R6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0R6"
  },
  {
    "uniprot": "X8F5B4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5B4"
  },
  {
    "uniprot": "X8FHL5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHL5"
  },
  {
    "uniprot": "X8FH65",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH65"
  },
  {
    "uniprot": "X8F098",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F098"
  },
  {
    "uniprot": "X8FEJ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEJ6"
  },
  {
    "uniprot": "X8F692",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F692"
  },
  {
    "uniprot": "X8EYZ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYZ5"
  },
  {
    "uniprot": "X8FHK6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHK6"
  },
  {
    "uniprot": "X8FLU8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLU8"
  },
  {
    "uniprot": "X8F279",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F279"
  },
  {
    "uniprot": "X8FLJ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLJ5"
  },
  {
    "uniprot": "X8FJS3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJS3"
  },
  {
    "uniprot": "X8F7P5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7P5"
  },
  {
    "uniprot": "X8F310",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F310"
  },
  {
    "uniprot": "X8F3X6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3X6"
  },
  {
    "uniprot": "X8FA16",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA16"
  },
  {
    "uniprot": "X8F1B6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1B6"
  },
  {
    "uniprot": "X8F3Z4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Z4"
  },
  {
    "uniprot": "X8F8G1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8G1"
  },
  {
    "uniprot": "X8FKE7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKE7"
  },
  {
    "uniprot": "X8FNV6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNV6"
  },
  {
    "uniprot": "X8FFI5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFI5"
  },
  {
    "uniprot": "X8FHU8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHU8"
  },
  {
    "uniprot": "X8F6V4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6V4"
  },
  {
    "uniprot": "X8FB27",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB27"
  },
  {
    "uniprot": "X8FJT9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJT9"
  },
  {
    "uniprot": "X8EZU8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZU8"
  },
  {
    "uniprot": "X8FK55",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK55"
  },
  {
    "uniprot": "X8FEH7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEH7"
  },
  {
    "uniprot": "X8FGG3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGG3"
  },
  {
    "uniprot": "X8FGI9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGI9"
  },
  {
    "uniprot": "X8EZ77",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ77"
  },
  {
    "uniprot": "X8F849",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F849"
  },
  {
    "uniprot": "X8F164",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F164"
  },
  {
    "uniprot": "X8F4J8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4J8"
  },
  {
    "uniprot": "X8EZG8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZG8"
  },
  {
    "uniprot": "X8FNS1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNS1"
  },
  {
    "uniprot": "X8FDI2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDI2"
  },
  {
    "uniprot": "X8FHF0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHF0"
  },
  {
    "uniprot": "X8FBR1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBR1"
  },
  {
    "uniprot": "X8FL56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL56"
  },
  {
    "uniprot": "X8FMM9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMM9"
  },
  {
    "uniprot": "X8FIW6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIW6"
  },
  {
    "uniprot": "X8F7F2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7F2"
  },
  {
    "uniprot": "X8F1R4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1R4"
  },
  {
    "uniprot": "X8EWB1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWB1"
  },
  {
    "uniprot": "X8F9G1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9G1"
  },
  {
    "uniprot": "X8FA51",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA51"
  },
  {
    "uniprot": "X8FPI1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPI1"
  },
  {
    "uniprot": "X8F691",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F691"
  },
  {
    "uniprot": "X8F375",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F375"
  },
  {
    "uniprot": "X8FE02",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE02"
  },
  {
    "uniprot": "X8F753",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F753"
  },
  {
    "uniprot": "X8FIJ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIJ0"
  },
  {
    "uniprot": "X8FJ03",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ03"
  },
  {
    "uniprot": "X8F0A6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0A6"
  },
  {
    "uniprot": "X8FCC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCC8"
  },
  {
    "uniprot": "X8F892",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F892"
  },
  {
    "uniprot": "X8EYI1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYI1"
  },
  {
    "uniprot": "X8F4V4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4V4"
  },
  {
    "uniprot": "X8F6Y2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Y2"
  },
  {
    "uniprot": "X8FK76",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK76"
  },
  {
    "uniprot": "X8FFT6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFT6"
  },
  {
    "uniprot": "X8FAJ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAJ8"
  },
  {
    "uniprot": "X8F8X1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8X1"
  },
  {
    "uniprot": "X8FJM8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJM8"
  },
  {
    "uniprot": "X8F7H3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7H3"
  },
  {
    "uniprot": "X8FDG3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDG3"
  },
  {
    "uniprot": "X8FEG0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEG0"
  },
  {
    "uniprot": "X8F900",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F900"
  },
  {
    "uniprot": "X8F7G3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7G3"
  },
  {
    "uniprot": "X8FFX7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFX7"
  },
  {
    "uniprot": "X8F2F2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2F2"
  },
  {
    "uniprot": "X8F9E4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9E4"
  },
  {
    "uniprot": "X8F897",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F897"
  },
  {
    "uniprot": "X8FJA7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJA7"
  },
  {
    "uniprot": "X8F3I9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3I9"
  },
  {
    "uniprot": "X8FLU1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLU1"
  },
  {
    "uniprot": "X8FCX9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCX9"
  },
  {
    "uniprot": "X8FB68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB68"
  },
  {
    "uniprot": "X8F8K4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8K4"
  },
  {
    "uniprot": "X8F4W7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4W7"
  },
  {
    "uniprot": "X8FH81",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH81"
  },
  {
    "uniprot": "X8FJJ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJJ6"
  },
  {
    "uniprot": "X8FIY0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIY0"
  },
  {
    "uniprot": "X8FL35",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL35"
  },
  {
    "uniprot": "X8F156",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F156"
  },
  {
    "uniprot": "X8F5S4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5S4"
  },
  {
    "uniprot": "X8EWX7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWX7"
  },
  {
    "uniprot": "X8FF62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF62"
  },
  {
    "uniprot": "X8FDW4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDW4"
  },
  {
    "uniprot": "X8FIZ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIZ3"
  },
  {
    "uniprot": "X8FC96",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC96"
  },
  {
    "uniprot": "X8F0F7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0F7"
  },
  {
    "uniprot": "X8FAZ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAZ0"
  },
  {
    "uniprot": "X8EXX6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXX6"
  },
  {
    "uniprot": "X8FF60",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF60"
  },
  {
    "uniprot": "X8F7W6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7W6"
  },
  {
    "uniprot": "X8F8D7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8D7"
  },
  {
    "uniprot": "X8F7I9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7I9"
  },
  {
    "uniprot": "X8FDS0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDS0"
  },
  {
    "uniprot": "X8F235",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F235"
  },
  {
    "uniprot": "X8EYB4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYB4"
  },
  {
    "uniprot": "X8FE19",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE19"
  },
  {
    "uniprot": "X8F0K3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0K3"
  },
  {
    "uniprot": "X8FGK4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGK4"
  },
  {
    "uniprot": "X8F252",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F252"
  },
  {
    "uniprot": "X8FH88",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH88"
  },
  {
    "uniprot": "X8FBI7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBI7"
  },
  {
    "uniprot": "X8EWM3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWM3"
  },
  {
    "uniprot": "X8FB28",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB28"
  },
  {
    "uniprot": "X8FAV9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAV9"
  },
  {
    "uniprot": "X8EX07",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX07"
  },
  {
    "uniprot": "X8EX62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX62"
  },
  {
    "uniprot": "X8F556",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F556"
  },
  {
    "uniprot": "X8FL75",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL75"
  },
  {
    "uniprot": "X8FJB3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJB3"
  },
  {
    "uniprot": "X8F4R7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4R7"
  },
  {
    "uniprot": "X8FDD0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDD0"
  },
  {
    "uniprot": "X8FBB1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBB1"
  },
  {
    "uniprot": "X8F2W4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2W4"
  },
  {
    "uniprot": "X8FCN9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCN9"
  },
  {
    "uniprot": "X8F1S8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1S8"
  },
  {
    "uniprot": "X8FDV1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDV1"
  },
  {
    "uniprot": "X8FNB7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNB7"
  },
  {
    "uniprot": "X8F7B6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7B6"
  },
  {
    "uniprot": "X8FGT2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGT2"
  },
  {
    "uniprot": "X8FKM4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKM4"
  },
  {
    "uniprot": "X8FJK1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJK1"
  },
  {
    "uniprot": "X8FHS9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHS9"
  },
  {
    "uniprot": "X8F1R1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1R1"
  },
  {
    "uniprot": "X8EXL4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXL4"
  },
  {
    "uniprot": "X8FKE9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKE9"
  },
  {
    "uniprot": "X8FEJ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEJ2"
  },
  {
    "uniprot": "X8FBV1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBV1"
  },
  {
    "uniprot": "X8FFM1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFM1"
  },
  {
    "uniprot": "X8FDH0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDH0"
  },
  {
    "uniprot": "X8F0I8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0I8"
  },
  {
    "uniprot": "X8EYA3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYA3"
  },
  {
    "uniprot": "X8EYM2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYM2"
  },
  {
    "uniprot": "X8FLV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLV0"
  },
  {
    "uniprot": "X8FG31",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG31"
  },
  {
    "uniprot": "X8F8M9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8M9"
  },
  {
    "uniprot": "X8FIT2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIT2"
  },
  {
    "uniprot": "X8F459",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F459"
  },
  {
    "uniprot": "X8FMH7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMH7"
  },
  {
    "uniprot": "X8F4F5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4F5"
  },
  {
    "uniprot": "X8F7Q3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Q3"
  },
  {
    "uniprot": "X8FA41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA41"
  },
  {
    "uniprot": "X8EZF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZF3"
  },
  {
    "uniprot": "X8F5J0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5J0"
  },
  {
    "uniprot": "X8F7J3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7J3"
  },
  {
    "uniprot": "X8F139",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F139"
  },
  {
    "uniprot": "X8F5D4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5D4"
  },
  {
    "uniprot": "X8FKU6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKU6"
  },
  {
    "uniprot": "X8FHE8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHE8"
  },
  {
    "uniprot": "X8FMN7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMN7"
  },
  {
    "uniprot": "X8FAX3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAX3"
  },
  {
    "uniprot": "X8F6C3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6C3"
  },
  {
    "uniprot": "X8F1K0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1K0"
  },
  {
    "uniprot": "X8F8S3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8S3"
  },
  {
    "uniprot": "X8F5F1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5F1"
  },
  {
    "uniprot": "X8FDM6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDM6"
  },
  {
    "uniprot": "X8FL23",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL23"
  },
  {
    "uniprot": "X8FGH0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGH0"
  },
  {
    "uniprot": "X8FF66",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF66"
  },
  {
    "uniprot": "X8FEA7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEA7"
  },
  {
    "uniprot": "X8F9X9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9X9"
  },
  {
    "uniprot": "X8FMT9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMT9"
  },
  {
    "uniprot": "X8F6R2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6R2"
  },
  {
    "uniprot": "X8EZC3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZC3"
  },
  {
    "uniprot": "X8FC70",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC70"
  },
  {
    "uniprot": "X8FBP1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBP1"
  },
  {
    "uniprot": "X8FI55",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI55"
  },
  {
    "uniprot": "X8EZG2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZG2"
  },
  {
    "uniprot": "X8FPH4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPH4"
  },
  {
    "uniprot": "X8FM40",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM40"
  },
  {
    "uniprot": "X8FFN0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFN0"
  },
  {
    "uniprot": "X8F6D6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6D6"
  },
  {
    "uniprot": "X8FMQ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMQ0"
  },
  {
    "uniprot": "X8F138",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F138"
  },
  {
    "uniprot": "X8FPZ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPZ8"
  },
  {
    "uniprot": "X8F3E6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3E6"
  },
  {
    "uniprot": "X8F997",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F997"
  },
  {
    "uniprot": "X8FM95",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM95"
  },
  {
    "uniprot": "X8F1I8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1I8"
  },
  {
    "uniprot": "X8F8Y1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Y1"
  },
  {
    "uniprot": "X8FDA7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDA7"
  },
  {
    "uniprot": "X8FH94",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH94"
  },
  {
    "uniprot": "X8FIK0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIK0"
  },
  {
    "uniprot": "X8F868",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F868"
  },
  {
    "uniprot": "X8EZD2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZD2"
  },
  {
    "uniprot": "X8F7E7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7E7"
  },
  {
    "uniprot": "X8FKS1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKS1"
  },
  {
    "uniprot": "X8F545",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F545"
  },
  {
    "uniprot": "X8FHD3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHD3"
  },
  {
    "uniprot": "X8EXC4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXC4"
  },
  {
    "uniprot": "X8F2Z9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Z9"
  },
  {
    "uniprot": "X8FNM9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNM9"
  },
  {
    "uniprot": "X8FNZ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNZ5"
  },
  {
    "uniprot": "X8F773",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F773"
  },
  {
    "uniprot": "X8F8X7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8X7"
  },
  {
    "uniprot": "X8FPY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPY5"
  },
  {
    "uniprot": "X8FJS2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJS2"
  },
  {
    "uniprot": "X8FK88",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK88"
  },
  {
    "uniprot": "X8F6T8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6T8"
  },
  {
    "uniprot": "X8FJG2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJG2"
  },
  {
    "uniprot": "X8F7S4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7S4"
  },
  {
    "uniprot": "X8FFM6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFM6"
  },
  {
    "uniprot": "X8F5I5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5I5"
  },
  {
    "uniprot": "X8EXK0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXK0"
  },
  {
    "uniprot": "X8FAU1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAU1"
  },
  {
    "uniprot": "X8F603",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F603"
  },
  {
    "uniprot": "X8F6S1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6S1"
  },
  {
    "uniprot": "X8FGE5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGE5"
  },
  {
    "uniprot": "X8FH18",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH18"
  },
  {
    "uniprot": "X8F876",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F876"
  },
  {
    "uniprot": "X8FJP3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJP3"
  },
  {
    "uniprot": "X8FQ82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FQ82"
  },
  {
    "uniprot": "X8FP17",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP17"
  },
  {
    "uniprot": "X8F9B5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9B5"
  },
  {
    "uniprot": "X8F2I3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2I3"
  },
  {
    "uniprot": "X8F5D3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5D3"
  },
  {
    "uniprot": "X8FE47",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE47"
  },
  {
    "uniprot": "X8F8C1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8C1"
  },
  {
    "uniprot": "X8FKQ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKQ8"
  },
  {
    "uniprot": "X8F5V7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5V7"
  },
  {
    "uniprot": "X8F8V3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8V3"
  },
  {
    "uniprot": "X8F5T5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5T5"
  },
  {
    "uniprot": "X8FCQ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCQ5"
  },
  {
    "uniprot": "X8FME3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FME3"
  },
  {
    "uniprot": "X8FKV2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKV2"
  },
  {
    "uniprot": "X8FCX5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCX5"
  },
  {
    "uniprot": "X8FHG1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHG1"
  },
  {
    "uniprot": "X8FIQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIQ6"
  },
  {
    "uniprot": "X8F8I7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8I7"
  },
  {
    "uniprot": "X8FLY8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLY8"
  },
  {
    "uniprot": "X8FGI0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGI0"
  },
  {
    "uniprot": "X8FCK9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCK9"
  },
  {
    "uniprot": "X8FIN6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIN6"
  },
  {
    "uniprot": "X8FIX2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIX2"
  },
  {
    "uniprot": "X8FML7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FML7"
  },
  {
    "uniprot": "X8EZA7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZA7"
  },
  {
    "uniprot": "X8F9X1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9X1"
  },
  {
    "uniprot": "X8F286",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F286"
  },
  {
    "uniprot": "X8FJJ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJJ5"
  },
  {
    "uniprot": "X8FLD4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLD4"
  },
  {
    "uniprot": "X8EZ32",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ32"
  },
  {
    "uniprot": "X8FEH2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEH2"
  },
  {
    "uniprot": "X8F0M9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0M9"
  },
  {
    "uniprot": "X8FAS5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAS5"
  },
  {
    "uniprot": "X8F0Z7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Z7"
  },
  {
    "uniprot": "X8FKD7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKD7"
  },
  {
    "uniprot": "X8F398",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F398"
  },
  {
    "uniprot": "X8FGM7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGM7"
  },
  {
    "uniprot": "X8EZD0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZD0"
  },
  {
    "uniprot": "X8F3Q9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Q9"
  },
  {
    "uniprot": "X8F717",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F717"
  },
  {
    "uniprot": "X8F411",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F411"
  },
  {
    "uniprot": "X8FMW8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMW8"
  },
  {
    "uniprot": "X8FKQ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKQ3"
  },
  {
    "uniprot": "X8FHW6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHW6"
  },
  {
    "uniprot": "X8FGB9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGB9"
  },
  {
    "uniprot": "X8F6R6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6R6"
  },
  {
    "uniprot": "X8FEV4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEV4"
  },
  {
    "uniprot": "X8FCI4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCI4"
  },
  {
    "uniprot": "X8EXS7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXS7"
  },
  {
    "uniprot": "X8F5V0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5V0"
  },
  {
    "uniprot": "X8F327",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F327"
  },
  {
    "uniprot": "X8F0C2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0C2"
  },
  {
    "uniprot": "X8F4H1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4H1"
  },
  {
    "uniprot": "X8F961",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F961"
  },
  {
    "uniprot": "X8FH49",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH49"
  },
  {
    "uniprot": "X8FFJ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFJ6"
  },
  {
    "uniprot": "X8F3R8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3R8"
  },
  {
    "uniprot": "X8FDL6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDL6"
  },
  {
    "uniprot": "X8FDS7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDS7"
  },
  {
    "uniprot": "X8FE62",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE62"
  },
  {
    "uniprot": "X8F4B9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4B9"
  },
  {
    "uniprot": "X8FHY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHY5"
  },
  {
    "uniprot": "X8FKU4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKU4"
  },
  {
    "uniprot": "X8FMF8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMF8"
  },
  {
    "uniprot": "X8FDC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDC8"
  },
  {
    "uniprot": "X8FBM2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBM2"
  },
  {
    "uniprot": "X8FNU1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNU1"
  },
  {
    "uniprot": "X8FIF7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIF7"
  },
  {
    "uniprot": "X8F4K6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4K6"
  },
  {
    "uniprot": "X8F7J0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7J0"
  },
  {
    "uniprot": "X8FL76",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL76"
  },
  {
    "uniprot": "X8FLP3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLP3"
  },
  {
    "uniprot": "X8F409",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F409"
  },
  {
    "uniprot": "X8FP59",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP59"
  },
  {
    "uniprot": "X8FDB6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDB6"
  },
  {
    "uniprot": "X8FPN2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPN2"
  },
  {
    "uniprot": "X8FDB5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDB5"
  },
  {
    "uniprot": "X8FE64",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE64"
  },
  {
    "uniprot": "X8FHY6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHY6"
  },
  {
    "uniprot": "X8FM18",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM18"
  },
  {
    "uniprot": "X8FES8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FES8"
  },
  {
    "uniprot": "X8F591",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F591"
  },
  {
    "uniprot": "X8F3J2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3J2"
  },
  {
    "uniprot": "X8F1A0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1A0"
  },
  {
    "uniprot": "X8F9Q7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Q7"
  },
  {
    "uniprot": "X8FFU8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFU8"
  },
  {
    "uniprot": "X8F7C5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7C5"
  },
  {
    "uniprot": "X8F707",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F707"
  },
  {
    "uniprot": "X8FJL3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJL3"
  },
  {
    "uniprot": "X8FCZ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCZ5"
  },
  {
    "uniprot": "X8F2T2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2T2"
  },
  {
    "uniprot": "X8FL16",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL16"
  },
  {
    "uniprot": "X8EX71",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX71"
  },
  {
    "uniprot": "X8FME8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FME8"
  },
  {
    "uniprot": "X8EY93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY93"
  },
  {
    "uniprot": "X8FDW6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDW6"
  },
  {
    "uniprot": "X8F333",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F333"
  },
  {
    "uniprot": "X8FLN3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLN3"
  },
  {
    "uniprot": "X8FH52",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH52"
  },
  {
    "uniprot": "X8FF09",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF09"
  },
  {
    "uniprot": "X8F224",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F224"
  },
  {
    "uniprot": "X8FJ26",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ26"
  },
  {
    "uniprot": "X8FL68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL68"
  },
  {
    "uniprot": "X8F815",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F815"
  },
  {
    "uniprot": "X8FFA1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFA1"
  },
  {
    "uniprot": "X8FGZ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGZ7"
  },
  {
    "uniprot": "X8EZU4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZU4"
  },
  {
    "uniprot": "X8F5Z7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Z7"
  },
  {
    "uniprot": "X8F0X0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0X0"
  },
  {
    "uniprot": "X8FLG2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLG2"
  },
  {
    "uniprot": "X8FPF9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPF9"
  },
  {
    "uniprot": "X8F074",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F074"
  },
  {
    "uniprot": "X8F1Q1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Q1"
  },
  {
    "uniprot": "X8F9F1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9F1"
  },
  {
    "uniprot": "X8EXF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXF3"
  },
  {
    "uniprot": "X8F5Y8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Y8"
  },
  {
    "uniprot": "X8FDM1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDM1"
  },
  {
    "uniprot": "X8FAN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAN8"
  },
  {
    "uniprot": "X8EZM5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZM5"
  },
  {
    "uniprot": "X8FDD8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDD8"
  },
  {
    "uniprot": "X8F9B4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9B4"
  },
  {
    "uniprot": "X8FAT9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAT9"
  },
  {
    "uniprot": "X8FMJ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMJ8"
  },
  {
    "uniprot": "X8FBX2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBX2"
  },
  {
    "uniprot": "X8F8U3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8U3"
  },
  {
    "uniprot": "X8F3L2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3L2"
  },
  {
    "uniprot": "X8F2U2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2U2"
  },
  {
    "uniprot": "X8F204",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F204"
  },
  {
    "uniprot": "X8FG71",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG71"
  },
  {
    "uniprot": "X8F8W9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8W9"
  },
  {
    "uniprot": "X8FAT7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAT7"
  },
  {
    "uniprot": "X8EYE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYE2"
  },
  {
    "uniprot": "X8FMH3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMH3"
  },
  {
    "uniprot": "X8FMZ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMZ1"
  },
  {
    "uniprot": "X8FL67",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL67"
  },
  {
    "uniprot": "X8EYQ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYQ7"
  },
  {
    "uniprot": "X8F2X0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2X0"
  },
  {
    "uniprot": "X8FAA2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAA2"
  },
  {
    "uniprot": "X8FFQ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFQ9"
  },
  {
    "uniprot": "X8F3U0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3U0"
  },
  {
    "uniprot": "X8F355",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F355"
  },
  {
    "uniprot": "X8FL70",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL70"
  },
  {
    "uniprot": "X8EXE2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXE2"
  },
  {
    "uniprot": "X8FBC5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBC5"
  },
  {
    "uniprot": "X8FA43",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA43"
  },
  {
    "uniprot": "X8FKG0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKG0"
  },
  {
    "uniprot": "X8FGW0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGW0"
  },
  {
    "uniprot": "X8FCW9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCW9"
  },
  {
    "uniprot": "X8F9M0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9M0"
  },
  {
    "uniprot": "X8FFH0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFH0"
  },
  {
    "uniprot": "X8FI37",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI37"
  },
  {
    "uniprot": "X8FNR1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNR1"
  },
  {
    "uniprot": "X8F3Z1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Z1"
  },
  {
    "uniprot": "X8F9C8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9C8"
  },
  {
    "uniprot": "X8F4M3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4M3"
  },
  {
    "uniprot": "X8FLA1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLA1"
  },
  {
    "uniprot": "X8F7P7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7P7"
  },
  {
    "uniprot": "X8F9M3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9M3"
  },
  {
    "uniprot": "X8EYA7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYA7"
  },
  {
    "uniprot": "X8F7W3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7W3"
  },
  {
    "uniprot": "X8FFK3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFK3"
  },
  {
    "uniprot": "X8FHH6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHH6"
  },
  {
    "uniprot": "X8F7D4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7D4"
  },
  {
    "uniprot": "X8F3C6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3C6"
  },
  {
    "uniprot": "X8FLT9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLT9"
  },
  {
    "uniprot": "X8FF12",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF12"
  },
  {
    "uniprot": "X8FAH7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAH7"
  },
  {
    "uniprot": "X8F3Y5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Y5"
  },
  {
    "uniprot": "X8F4U1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4U1"
  },
  {
    "uniprot": "X8F6Q3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Q3"
  },
  {
    "uniprot": "X8FAJ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAJ7"
  },
  {
    "uniprot": "X8EZY0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZY0"
  },
  {
    "uniprot": "X8FHE7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHE7"
  },
  {
    "uniprot": "X8F4R5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4R5"
  },
  {
    "uniprot": "X8F4I2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4I2"
  },
  {
    "uniprot": "X8FNM8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNM8"
  },
  {
    "uniprot": "X8FLZ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLZ6"
  },
  {
    "uniprot": "X8FLZ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLZ2"
  },
  {
    "uniprot": "X8F2S5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2S5"
  },
  {
    "uniprot": "X8FIJ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIJ2"
  },
  {
    "uniprot": "X8FLY7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLY7"
  },
  {
    "uniprot": "X8EYQ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYQ3"
  },
  {
    "uniprot": "X8FGG0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGG0"
  },
  {
    "uniprot": "X8FGY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGY1"
  },
  {
    "uniprot": "X8F0F2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0F2"
  },
  {
    "uniprot": "X8F8I0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8I0"
  },
  {
    "uniprot": "X8F4I4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4I4"
  },
  {
    "uniprot": "X8FPI5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPI5"
  },
  {
    "uniprot": "X8F457",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F457"
  },
  {
    "uniprot": "X8F2D0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2D0"
  },
  {
    "uniprot": "X8FJZ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJZ6"
  },
  {
    "uniprot": "X8F7H1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7H1"
  },
  {
    "uniprot": "X8FJ20",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ20"
  },
  {
    "uniprot": "X8EZT1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZT1"
  },
  {
    "uniprot": "X8FM52",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM52"
  },
  {
    "uniprot": "X8FNN0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNN0"
  },
  {
    "uniprot": "X8F6P4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6P4"
  },
  {
    "uniprot": "X8FII5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FII5"
  },
  {
    "uniprot": "X8FDN3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDN3"
  },
  {
    "uniprot": "X8FNQ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNQ4"
  },
  {
    "uniprot": "X8F9M5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9M5"
  },
  {
    "uniprot": "X8FJ18",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ18"
  },
  {
    "uniprot": "X8FKY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKY5"
  },
  {
    "uniprot": "X8F0E0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0E0"
  },
  {
    "uniprot": "X8F236",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F236"
  },
  {
    "uniprot": "X8FMW5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMW5"
  },
  {
    "uniprot": "X8FIP3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIP3"
  },
  {
    "uniprot": "X8F3J9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3J9"
  },
  {
    "uniprot": "X8F2G1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2G1"
  },
  {
    "uniprot": "X8FAH1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAH1"
  },
  {
    "uniprot": "X8FCW8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCW8"
  },
  {
    "uniprot": "X8FN09",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN09"
  },
  {
    "uniprot": "X8FBW7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBW7"
  },
  {
    "uniprot": "X8F6S3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6S3"
  },
  {
    "uniprot": "X8F9K8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9K8"
  },
  {
    "uniprot": "X8EYP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYP9"
  },
  {
    "uniprot": "X8F8H9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8H9"
  },
  {
    "uniprot": "X8F785",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F785"
  },
  {
    "uniprot": "X8FM67",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM67"
  },
  {
    "uniprot": "X8FHC5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHC5"
  },
  {
    "uniprot": "X8EXG1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXG1"
  },
  {
    "uniprot": "X8FEJ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEJ5"
  },
  {
    "uniprot": "X8FJW2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJW2"
  },
  {
    "uniprot": "X8FFF3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFF3"
  },
  {
    "uniprot": "X8F9G3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9G3"
  },
  {
    "uniprot": "X8F1R6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1R6"
  },
  {
    "uniprot": "X8FAT0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAT0"
  },
  {
    "uniprot": "X8EXK8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXK8"
  },
  {
    "uniprot": "X8F779",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F779"
  },
  {
    "uniprot": "X8F0C7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0C7"
  },
  {
    "uniprot": "X8F4Q8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Q8"
  },
  {
    "uniprot": "X8FMX3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMX3"
  },
  {
    "uniprot": "X8FPA5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPA5"
  },
  {
    "uniprot": "X8FEX4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEX4"
  },
  {
    "uniprot": "X8EZB7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZB7"
  },
  {
    "uniprot": "X8EZ16",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ16"
  },
  {
    "uniprot": "X8FKK2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKK2"
  },
  {
    "uniprot": "X8FCC5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCC5"
  },
  {
    "uniprot": "X8EZM3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZM3"
  },
  {
    "uniprot": "X8F3L3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3L3"
  },
  {
    "uniprot": "X8F6S7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6S7"
  },
  {
    "uniprot": "X8FKU2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKU2"
  },
  {
    "uniprot": "X8F160",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F160"
  },
  {
    "uniprot": "X8FM16",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM16"
  },
  {
    "uniprot": "X8FPC3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPC3"
  },
  {
    "uniprot": "X8EY74",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY74"
  },
  {
    "uniprot": "X8FNL9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNL9"
  },
  {
    "uniprot": "X8F082",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F082"
  },
  {
    "uniprot": "X8FMV6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMV6"
  },
  {
    "uniprot": "X8FIB9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIB9"
  },
  {
    "uniprot": "X8FD88",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD88"
  },
  {
    "uniprot": "X8FIN0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIN0"
  },
  {
    "uniprot": "X8FNA1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNA1"
  },
  {
    "uniprot": "X8F8R1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8R1"
  },
  {
    "uniprot": "X8F6R3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6R3"
  },
  {
    "uniprot": "X8F295",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F295"
  },
  {
    "uniprot": "X8FBL6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBL6"
  },
  {
    "uniprot": "X8FP14",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP14"
  },
  {
    "uniprot": "X8FBC4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBC4"
  },
  {
    "uniprot": "X8FL06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL06"
  },
  {
    "uniprot": "X8F318",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F318"
  },
  {
    "uniprot": "X8FB61",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB61"
  },
  {
    "uniprot": "X8EZG0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZG0"
  },
  {
    "uniprot": "X8F1L6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1L6"
  },
  {
    "uniprot": "X8FB06",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB06"
  },
  {
    "uniprot": "X8FF91",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF91"
  },
  {
    "uniprot": "X8F4H8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4H8"
  },
  {
    "uniprot": "X8FBJ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBJ7"
  },
  {
    "uniprot": "X8F8Z9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Z9"
  },
  {
    "uniprot": "X8FG23",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG23"
  },
  {
    "uniprot": "X8F642",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F642"
  },
  {
    "uniprot": "X8F8E2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8E2"
  },
  {
    "uniprot": "X8F1S2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1S2"
  },
  {
    "uniprot": "X8FF51",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF51"
  },
  {
    "uniprot": "X8FDD6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDD6"
  },
  {
    "uniprot": "X8FBH2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBH2"
  },
  {
    "uniprot": "X8F0I0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0I0"
  },
  {
    "uniprot": "X8FGB2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGB2"
  },
  {
    "uniprot": "X8F5M8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5M8"
  },
  {
    "uniprot": "X8F812",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F812"
  },
  {
    "uniprot": "X8FGU4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGU4"
  },
  {
    "uniprot": "X8FJB6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJB6"
  },
  {
    "uniprot": "X8F3I8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3I8"
  },
  {
    "uniprot": "X8FJ25",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ25"
  },
  {
    "uniprot": "X8F0S4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0S4"
  },
  {
    "uniprot": "X8EWQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWQ6"
  },
  {
    "uniprot": "X8FBH8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBH8"
  },
  {
    "uniprot": "X8F9N1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9N1"
  },
  {
    "uniprot": "X8FB59",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB59"
  },
  {
    "uniprot": "X8F0W1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0W1"
  },
  {
    "uniprot": "X8F079",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F079"
  },
  {
    "uniprot": "X8FDS1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDS1"
  },
  {
    "uniprot": "X8EZ36",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ36"
  },
  {
    "uniprot": "X8F6W1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6W1"
  },
  {
    "uniprot": "X8FD56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD56"
  },
  {
    "uniprot": "X8FII1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FII1"
  },
  {
    "uniprot": "X8F6C9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6C9"
  },
  {
    "uniprot": "X8FLV9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLV9"
  },
  {
    "uniprot": "X8FCU3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCU3"
  },
  {
    "uniprot": "X8FF08",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF08"
  },
  {
    "uniprot": "X8FG58",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG58"
  },
  {
    "uniprot": "X8FBX0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBX0"
  },
  {
    "uniprot": "X8FKB4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKB4"
  },
  {
    "uniprot": "X8F3K2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3K2"
  },
  {
    "uniprot": "X8F8P5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8P5"
  },
  {
    "uniprot": "X8FI25",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI25"
  },
  {
    "uniprot": "X8EX41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX41"
  },
  {
    "uniprot": "X8F7Q8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Q8"
  },
  {
    "uniprot": "X8F6F8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6F8"
  },
  {
    "uniprot": "X8FGM3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGM3"
  },
  {
    "uniprot": "X8FGZ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGZ8"
  },
  {
    "uniprot": "X8FJQ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJQ9"
  },
  {
    "uniprot": "X8FL42",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL42"
  },
  {
    "uniprot": "X8FCZ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCZ7"
  },
  {
    "uniprot": "X8F0C0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0C0"
  },
  {
    "uniprot": "X8FIJ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIJ1"
  },
  {
    "uniprot": "X8FGF1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGF1"
  },
  {
    "uniprot": "X8FH01",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH01"
  },
  {
    "uniprot": "X8F6B8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6B8"
  },
  {
    "uniprot": "X8F596",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F596"
  },
  {
    "uniprot": "X8FLA0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLA0"
  },
  {
    "uniprot": "X8F523",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F523"
  },
  {
    "uniprot": "X8FLT1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLT1"
  },
  {
    "uniprot": "X8FLK1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLK1"
  },
  {
    "uniprot": "X8FIU7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIU7"
  },
  {
    "uniprot": "X8FCR2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCR2"
  },
  {
    "uniprot": "X8FJY5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJY5"
  },
  {
    "uniprot": "X8F7G4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7G4"
  },
  {
    "uniprot": "X8FKL6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKL6"
  },
  {
    "uniprot": "X8F1L3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1L3"
  },
  {
    "uniprot": "X8F2D7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2D7"
  },
  {
    "uniprot": "X8EZG4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZG4"
  },
  {
    "uniprot": "X8EY07",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY07"
  },
  {
    "uniprot": "X8F152",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F152"
  },
  {
    "uniprot": "X8EXG2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXG2"
  },
  {
    "uniprot": "X8FKL9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKL9"
  },
  {
    "uniprot": "X8FH15",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH15"
  },
  {
    "uniprot": "X8FBR0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBR0"
  },
  {
    "uniprot": "X8FIV3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIV3"
  },
  {
    "uniprot": "X8FHY2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHY2"
  },
  {
    "uniprot": "X8EZ34",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ34"
  },
  {
    "uniprot": "X8FHR7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHR7"
  },
  {
    "uniprot": "X8FFQ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFQ5"
  },
  {
    "uniprot": "X8F9A8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9A8"
  },
  {
    "uniprot": "X8F4U5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4U5"
  },
  {
    "uniprot": "X8F6C8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6C8"
  },
  {
    "uniprot": "X8F1V5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1V5"
  },
  {
    "uniprot": "X8FDR1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDR1"
  },
  {
    "uniprot": "X8F9D0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9D0"
  },
  {
    "uniprot": "X8FMH6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMH6"
  },
  {
    "uniprot": "X8FM11",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM11"
  },
  {
    "uniprot": "X8F0G6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0G6"
  },
  {
    "uniprot": "X8F683",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F683"
  },
  {
    "uniprot": "X8F737",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F737"
  },
  {
    "uniprot": "X8EZX0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZX0"
  },
  {
    "uniprot": "X8F0P0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0P0"
  },
  {
    "uniprot": "X8F9U1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9U1"
  },
  {
    "uniprot": "X8F019",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F019"
  },
  {
    "uniprot": "X8F6G0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6G0"
  },
  {
    "uniprot": "X8FEU7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEU7"
  },
  {
    "uniprot": "X8F507",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F507"
  },
  {
    "uniprot": "X8FGN2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGN2"
  },
  {
    "uniprot": "X8F371",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F371"
  },
  {
    "uniprot": "X8FJN3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJN3"
  },
  {
    "uniprot": "X8EYW1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYW1"
  },
  {
    "uniprot": "X8EYJ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYJ9"
  },
  {
    "uniprot": "X8FIC4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIC4"
  },
  {
    "uniprot": "X8FF38",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF38"
  },
  {
    "uniprot": "X8FEH5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEH5"
  },
  {
    "uniprot": "X8F017",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F017"
  },
  {
    "uniprot": "X8EWN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWN8"
  },
  {
    "uniprot": "X8FEN0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEN0"
  },
  {
    "uniprot": "X8FHC6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHC6"
  },
  {
    "uniprot": "X8FL60",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL60"
  },
  {
    "uniprot": "X8FGL2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGL2"
  },
  {
    "uniprot": "X8FH51",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH51"
  },
  {
    "uniprot": "X8F2Z2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Z2"
  },
  {
    "uniprot": "X8FBG2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBG2"
  },
  {
    "uniprot": "X8FKK0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKK0"
  },
  {
    "uniprot": "X8F496",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F496"
  },
  {
    "uniprot": "X8FA39",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA39"
  },
  {
    "uniprot": "X8F6A6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6A6"
  },
  {
    "uniprot": "X8F5A3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5A3"
  },
  {
    "uniprot": "X8F826",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F826"
  },
  {
    "uniprot": "X8F7X0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7X0"
  },
  {
    "uniprot": "X8FG82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG82"
  },
  {
    "uniprot": "X8EZ75",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ75"
  },
  {
    "uniprot": "X8F7D3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7D3"
  },
  {
    "uniprot": "X8FLX8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLX8"
  },
  {
    "uniprot": "X8FBJ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBJ9"
  },
  {
    "uniprot": "X8FFD6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFD6"
  },
  {
    "uniprot": "X8F076",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F076"
  },
  {
    "uniprot": "X8F8F6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8F6"
  },
  {
    "uniprot": "X8F5I2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5I2"
  },
  {
    "uniprot": "X8F268",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F268"
  },
  {
    "uniprot": "X8F3B0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3B0"
  },
  {
    "uniprot": "X8FCJ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCJ3"
  },
  {
    "uniprot": "X8F550",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F550"
  },
  {
    "uniprot": "X8EYW6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYW6"
  },
  {
    "uniprot": "X8FIJ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIJ4"
  },
  {
    "uniprot": "X8FE30",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE30"
  },
  {
    "uniprot": "X8FE83",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE83"
  },
  {
    "uniprot": "X8FLQ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLQ3"
  },
  {
    "uniprot": "X8FME5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FME5"
  },
  {
    "uniprot": "X8FFV7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFV7"
  },
  {
    "uniprot": "X8EXH1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXH1"
  },
  {
    "uniprot": "X8F7X6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7X6"
  },
  {
    "uniprot": "X8F3G2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3G2"
  },
  {
    "uniprot": "X8F3W3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3W3"
  },
  {
    "uniprot": "X8FBG3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBG3"
  },
  {
    "uniprot": "X8F5I8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5I8"
  },
  {
    "uniprot": "X8FAZ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAZ6"
  },
  {
    "uniprot": "X8FEI0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEI0"
  },
  {
    "uniprot": "X8FAM7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAM7"
  },
  {
    "uniprot": "X8F6L9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6L9"
  },
  {
    "uniprot": "X8FK03",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK03"
  },
  {
    "uniprot": "X8EYH0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYH0"
  },
  {
    "uniprot": "X8FG61",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG61"
  },
  {
    "uniprot": "X8FES0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FES0"
  },
  {
    "uniprot": "X8F615",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F615"
  },
  {
    "uniprot": "X8FM53",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM53"
  },
  {
    "uniprot": "X8F3Q0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Q0"
  },
  {
    "uniprot": "X8FDT9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDT9"
  },
  {
    "uniprot": "X8FFA5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFA5"
  },
  {
    "uniprot": "X8FBB5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBB5"
  },
  {
    "uniprot": "X8FFE9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFE9"
  },
  {
    "uniprot": "X8FDZ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDZ9"
  },
  {
    "uniprot": "X8FC38",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC38"
  },
  {
    "uniprot": "X8FLH6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLH6"
  },
  {
    "uniprot": "X8F9E1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9E1"
  },
  {
    "uniprot": "X8FCJ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCJ7"
  },
  {
    "uniprot": "X8FF25",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF25"
  },
  {
    "uniprot": "X8FL33",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL33"
  },
  {
    "uniprot": "X8F809",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F809"
  },
  {
    "uniprot": "X8F488",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F488"
  },
  {
    "uniprot": "X8F759",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F759"
  },
  {
    "uniprot": "X8FB63",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB63"
  },
  {
    "uniprot": "X8EZ37",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ37"
  },
  {
    "uniprot": "X8F0U0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0U0"
  },
  {
    "uniprot": "X8FJI1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJI1"
  },
  {
    "uniprot": "X8F219",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F219"
  },
  {
    "uniprot": "X8FD51",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD51"
  },
  {
    "uniprot": "X8FC33",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC33"
  },
  {
    "uniprot": "X8F722",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F722"
  },
  {
    "uniprot": "X8F1N8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1N8"
  },
  {
    "uniprot": "X8F6L1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6L1"
  },
  {
    "uniprot": "X8FK11",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK11"
  },
  {
    "uniprot": "X8FKI1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKI1"
  },
  {
    "uniprot": "X8F8D1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8D1"
  },
  {
    "uniprot": "X8F5S5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5S5"
  },
  {
    "uniprot": "X8FIN1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIN1"
  },
  {
    "uniprot": "X8F9Z9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Z9"
  },
  {
    "uniprot": "X8F490",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F490"
  },
  {
    "uniprot": "X8FKT3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKT3"
  },
  {
    "uniprot": "X8F3J6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3J6"
  },
  {
    "uniprot": "X8FAL8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAL8"
  },
  {
    "uniprot": "X8FJT8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJT8"
  },
  {
    "uniprot": "X8FGL9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGL9"
  },
  {
    "uniprot": "X8FAR3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAR3"
  },
  {
    "uniprot": "X8FF13",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF13"
  },
  {
    "uniprot": "X8FD07",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD07"
  },
  {
    "uniprot": "X8FNF6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNF6"
  },
  {
    "uniprot": "X8EWV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWV8"
  },
  {
    "uniprot": "X8EZ61",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ61"
  },
  {
    "uniprot": "X8FJ36",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ36"
  },
  {
    "uniprot": "X8F6J5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6J5"
  },
  {
    "uniprot": "X8FCS3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCS3"
  },
  {
    "uniprot": "X8F7V6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7V6"
  },
  {
    "uniprot": "X8FHR9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHR9"
  },
  {
    "uniprot": "X8F4R1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4R1"
  },
  {
    "uniprot": "X8EYR9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYR9"
  },
  {
    "uniprot": "X8FC66",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC66"
  },
  {
    "uniprot": "X8FN05",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN05"
  },
  {
    "uniprot": "X8F8T8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8T8"
  },
  {
    "uniprot": "X8EXA6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXA6"
  },
  {
    "uniprot": "X8FEF1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEF1"
  },
  {
    "uniprot": "X8FK63",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK63"
  },
  {
    "uniprot": "X8F3G1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3G1"
  },
  {
    "uniprot": "X8FCP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCP9"
  },
  {
    "uniprot": "X8FGA1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGA1"
  },
  {
    "uniprot": "X8F9U0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9U0"
  },
  {
    "uniprot": "X8FGD9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGD9"
  },
  {
    "uniprot": "X8EZ86",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ86"
  },
  {
    "uniprot": "X8F8F2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8F2"
  },
  {
    "uniprot": "X8FH33",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH33"
  },
  {
    "uniprot": "X8F9V9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9V9"
  },
  {
    "uniprot": "X8FBP7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBP7"
  },
  {
    "uniprot": "X8FJW0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJW0"
  },
  {
    "uniprot": "X8F9N5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9N5"
  },
  {
    "uniprot": "X8FLB2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLB2"
  },
  {
    "uniprot": "X8FB90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB90"
  },
  {
    "uniprot": "X8FKM7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKM7"
  },
  {
    "uniprot": "X8F521",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F521"
  },
  {
    "uniprot": "X8FKX8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKX8"
  },
  {
    "uniprot": "X8FE75",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE75"
  },
  {
    "uniprot": "X8F8E6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8E6"
  },
  {
    "uniprot": "X8F0W6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0W6"
  },
  {
    "uniprot": "X8FER2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FER2"
  },
  {
    "uniprot": "X8FDX3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDX3"
  },
  {
    "uniprot": "X8FP72",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP72"
  },
  {
    "uniprot": "X8EYH1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYH1"
  },
  {
    "uniprot": "X8FIM2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIM2"
  },
  {
    "uniprot": "X8FA17",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA17"
  },
  {
    "uniprot": "X8FEA4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEA4"
  },
  {
    "uniprot": "X8FLD1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLD1"
  },
  {
    "uniprot": "X8F3X7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3X7"
  },
  {
    "uniprot": "X8FKJ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKJ1"
  },
  {
    "uniprot": "X8FGR2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGR2"
  },
  {
    "uniprot": "X8F696",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F696"
  },
  {
    "uniprot": "X8F594",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F594"
  },
  {
    "uniprot": "X8FC54",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC54"
  },
  {
    "uniprot": "X8FJX5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJX5"
  },
  {
    "uniprot": "X8FJF5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJF5"
  },
  {
    "uniprot": "X8FDW7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDW7"
  },
  {
    "uniprot": "X8F3F1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3F1"
  },
  {
    "uniprot": "X8FGP7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGP7"
  },
  {
    "uniprot": "X8FG98",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG98"
  },
  {
    "uniprot": "X8FJX0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJX0"
  },
  {
    "uniprot": "X8F6T6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6T6"
  },
  {
    "uniprot": "X8FKQ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKQ0"
  },
  {
    "uniprot": "X8F9T0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9T0"
  },
  {
    "uniprot": "X8F1Y9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1Y9"
  },
  {
    "uniprot": "X8FE56",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE56"
  },
  {
    "uniprot": "X8FJR3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJR3"
  },
  {
    "uniprot": "X8F939",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F939"
  },
  {
    "uniprot": "X8F2M8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2M8"
  },
  {
    "uniprot": "X8FJA0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJA0"
  },
  {
    "uniprot": "X8F5P5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5P5"
  },
  {
    "uniprot": "X8FF31",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FF31"
  },
  {
    "uniprot": "X8FIA2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIA2"
  },
  {
    "uniprot": "X8FEK3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEK3"
  },
  {
    "uniprot": "X8F3H5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3H5"
  },
  {
    "uniprot": "X8FKS3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKS3"
  },
  {
    "uniprot": "X8F7L9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7L9"
  },
  {
    "uniprot": "X8F176",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F176"
  },
  {
    "uniprot": "X8F9F3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9F3"
  },
  {
    "uniprot": "X8F7U4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7U4"
  },
  {
    "uniprot": "X8FFJ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFJ9"
  },
  {
    "uniprot": "X8FLY6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLY6"
  },
  {
    "uniprot": "X8FJA2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJA2"
  },
  {
    "uniprot": "X8FGL4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGL4"
  },
  {
    "uniprot": "X8FFQ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFQ6"
  },
  {
    "uniprot": "X8F908",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F908"
  },
  {
    "uniprot": "X8F0R7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0R7"
  },
  {
    "uniprot": "X8F901",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F901"
  },
  {
    "uniprot": "X8FEE3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEE3"
  },
  {
    "uniprot": "X8FGJ9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGJ9"
  },
  {
    "uniprot": "X8FJS4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJS4"
  },
  {
    "uniprot": "X8FBV7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBV7"
  },
  {
    "uniprot": "X8FPN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPN8"
  },
  {
    "uniprot": "X8F8L4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8L4"
  },
  {
    "uniprot": "X8F9Y6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Y6"
  },
  {
    "uniprot": "X8FPF8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPF8"
  },
  {
    "uniprot": "X8FPT6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPT6"
  },
  {
    "uniprot": "X8EYV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYV0"
  },
  {
    "uniprot": "X8FFC3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFC3"
  },
  {
    "uniprot": "X8F8P6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8P6"
  },
  {
    "uniprot": "X8F1A2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1A2"
  },
  {
    "uniprot": "X8FB23",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB23"
  },
  {
    "uniprot": "X8F5N6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5N6"
  },
  {
    "uniprot": "X8FJ59",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ59"
  },
  {
    "uniprot": "X8FHM4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHM4"
  },
  {
    "uniprot": "X8F5Y5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5Y5"
  },
  {
    "uniprot": "X8F982",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F982"
  },
  {
    "uniprot": "X8FE21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE21"
  },
  {
    "uniprot": "X8F7I1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7I1"
  },
  {
    "uniprot": "X8FMJ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMJ0"
  },
  {
    "uniprot": "X8EW26",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EW26"
  },
  {
    "uniprot": "X8FL63",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL63"
  },
  {
    "uniprot": "X8F6K3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6K3"
  },
  {
    "uniprot": "X8F3W2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3W2"
  },
  {
    "uniprot": "X8FEA2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEA2"
  },
  {
    "uniprot": "X8FEU9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEU9"
  },
  {
    "uniprot": "X8F7Y5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Y5"
  },
  {
    "uniprot": "X8F6Z1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Z1"
  },
  {
    "uniprot": "X8F8S1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8S1"
  },
  {
    "uniprot": "X8FHB8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHB8"
  },
  {
    "uniprot": "X8FGE8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGE8"
  },
  {
    "uniprot": "X8FAX6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAX6"
  },
  {
    "uniprot": "X8FBA9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBA9"
  },
  {
    "uniprot": "X8FEP8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEP8"
  },
  {
    "uniprot": "X8F796",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F796"
  },
  {
    "uniprot": "X8F8T0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8T0"
  },
  {
    "uniprot": "X8EZI8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZI8"
  },
  {
    "uniprot": "X8EWY2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWY2"
  },
  {
    "uniprot": "X8EZE9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZE9"
  },
  {
    "uniprot": "X8F5C7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5C7"
  },
  {
    "uniprot": "X8EZF0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZF0"
  },
  {
    "uniprot": "X8FL21",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FL21"
  },
  {
    "uniprot": "X8F8C6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8C6"
  },
  {
    "uniprot": "X8F6R0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6R0"
  },
  {
    "uniprot": "X8FP55",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP55"
  },
  {
    "uniprot": "X8F4E3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4E3"
  },
  {
    "uniprot": "X8F142",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F142"
  },
  {
    "uniprot": "X8F6C2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6C2"
  },
  {
    "uniprot": "X8F157",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F157"
  },
  {
    "uniprot": "X8F4U2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4U2"
  },
  {
    "uniprot": "X8FCN4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCN4"
  },
  {
    "uniprot": "X8EYD1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYD1"
  },
  {
    "uniprot": "X8F0E1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0E1"
  },
  {
    "uniprot": "X8FM37",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM37"
  },
  {
    "uniprot": "X8FJ82",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ82"
  },
  {
    "uniprot": "X8EYU8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYU8"
  },
  {
    "uniprot": "X8FFA9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFA9"
  },
  {
    "uniprot": "X8FBZ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBZ1"
  },
  {
    "uniprot": "X8F9T8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9T8"
  },
  {
    "uniprot": "X8FE13",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE13"
  },
  {
    "uniprot": "X8FNJ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNJ5"
  },
  {
    "uniprot": "X8F9H2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9H2"
  },
  {
    "uniprot": "X8F400",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F400"
  },
  {
    "uniprot": "X8FEC5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEC5"
  },
  {
    "uniprot": "X8FAU2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAU2"
  },
  {
    "uniprot": "X8EYT4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYT4"
  },
  {
    "uniprot": "X8FK42",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK42"
  },
  {
    "uniprot": "X8FEP7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEP7"
  },
  {
    "uniprot": "X8F0V9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0V9"
  },
  {
    "uniprot": "X8F8J4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8J4"
  },
  {
    "uniprot": "X8FDB4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDB4"
  },
  {
    "uniprot": "X8F4I5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4I5"
  },
  {
    "uniprot": "X8F0A5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0A5"
  },
  {
    "uniprot": "X8F064",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F064"
  },
  {
    "uniprot": "X8FGK7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGK7"
  },
  {
    "uniprot": "X8F291",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F291"
  },
  {
    "uniprot": "X8EYY3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYY3"
  },
  {
    "uniprot": "X8FMB7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMB7"
  },
  {
    "uniprot": "X8FKZ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKZ0"
  },
  {
    "uniprot": "X8FCI8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCI8"
  },
  {
    "uniprot": "X8F1E4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1E4"
  },
  {
    "uniprot": "X8FH63",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH63"
  },
  {
    "uniprot": "X8F2B6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2B6"
  },
  {
    "uniprot": "X8EZV5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZV5"
  },
  {
    "uniprot": "X8FNP2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNP2"
  },
  {
    "uniprot": "X8FCD6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCD6"
  },
  {
    "uniprot": "X8FKA6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKA6"
  },
  {
    "uniprot": "X8F2P3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2P3"
  },
  {
    "uniprot": "X8FHB6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHB6"
  },
  {
    "uniprot": "X8FDW0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDW0"
  },
  {
    "uniprot": "X8FIS1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIS1"
  },
  {
    "uniprot": "X8F886",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F886"
  },
  {
    "uniprot": "X8F4X2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4X2"
  },
  {
    "uniprot": "X8FP09",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP09"
  },
  {
    "uniprot": "X8F761",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F761"
  },
  {
    "uniprot": "X8F7G5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7G5"
  },
  {
    "uniprot": "X8F6N9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6N9"
  },
  {
    "uniprot": "X8FKL3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKL3"
  },
  {
    "uniprot": "X8F995",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F995"
  },
  {
    "uniprot": "X8FC92",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC92"
  },
  {
    "uniprot": "X8FJT0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJT0"
  },
  {
    "uniprot": "X8FCZ6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCZ6"
  },
  {
    "uniprot": "X8FFD9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFD9"
  },
  {
    "uniprot": "X8FJS5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJS5"
  },
  {
    "uniprot": "X8F8U6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8U6"
  },
  {
    "uniprot": "X8F8Z7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Z7"
  },
  {
    "uniprot": "X8F2U1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2U1"
  },
  {
    "uniprot": "X8FAQ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAQ7"
  },
  {
    "uniprot": "X8FG02",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG02"
  },
  {
    "uniprot": "X8F2G0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2G0"
  },
  {
    "uniprot": "X8F2K3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2K3"
  },
  {
    "uniprot": "X8F5L8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5L8"
  },
  {
    "uniprot": "X8F1V2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1V2"
  },
  {
    "uniprot": "X8F2C8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2C8"
  },
  {
    "uniprot": "X8EZI4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZI4"
  },
  {
    "uniprot": "X8FJ99",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ99"
  },
  {
    "uniprot": "X8F3Q7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3Q7"
  },
  {
    "uniprot": "X8FCQ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCQ1"
  },
  {
    "uniprot": "X8F3R0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3R0"
  },
  {
    "uniprot": "X8F244",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F244"
  },
  {
    "uniprot": "X8FIL8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIL8"
  },
  {
    "uniprot": "X8FNN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNN8"
  },
  {
    "uniprot": "X8F1D9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1D9"
  },
  {
    "uniprot": "X8F1M0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1M0"
  },
  {
    "uniprot": "X8FMQ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMQ1"
  },
  {
    "uniprot": "X8FFJ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFJ4"
  },
  {
    "uniprot": "X8FMA8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMA8"
  },
  {
    "uniprot": "X8FID2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FID2"
  },
  {
    "uniprot": "X8FBK1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBK1"
  },
  {
    "uniprot": "X8FM66",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM66"
  },
  {
    "uniprot": "X8F2N2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2N2"
  },
  {
    "uniprot": "X8FBU3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBU3"
  },
  {
    "uniprot": "X8FCR8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCR8"
  },
  {
    "uniprot": "X8FJG1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJG1"
  },
  {
    "uniprot": "X8F9C7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9C7"
  },
  {
    "uniprot": "X8F1T9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F1T9"
  },
  {
    "uniprot": "X8FKQ1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKQ1"
  },
  {
    "uniprot": "X8FKR0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKR0"
  },
  {
    "uniprot": "X8FA22",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA22"
  },
  {
    "uniprot": "X8F410",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F410"
  },
  {
    "uniprot": "X8F5G8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5G8"
  },
  {
    "uniprot": "X8FH75",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FH75"
  },
  {
    "uniprot": "X8FDG7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDG7"
  },
  {
    "uniprot": "X8F9A1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9A1"
  },
  {
    "uniprot": "X8F9J9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9J9"
  },
  {
    "uniprot": "X8FGU6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGU6"
  },
  {
    "uniprot": "X8FIW3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIW3"
  },
  {
    "uniprot": "X8F2G8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2G8"
  },
  {
    "uniprot": "X8FEF2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEF2"
  },
  {
    "uniprot": "X8FET3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FET3"
  },
  {
    "uniprot": "X8FDA8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDA8"
  },
  {
    "uniprot": "X8F9Z2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Z2"
  },
  {
    "uniprot": "X8F6D0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6D0"
  },
  {
    "uniprot": "X8FN12",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN12"
  },
  {
    "uniprot": "X8FCN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCN8"
  },
  {
    "uniprot": "X8FG88",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG88"
  },
  {
    "uniprot": "X8FKL5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKL5"
  },
  {
    "uniprot": "X8FBD4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBD4"
  },
  {
    "uniprot": "X8F9I6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9I6"
  },
  {
    "uniprot": "X8F7M5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7M5"
  },
  {
    "uniprot": "X8FAY9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAY9"
  },
  {
    "uniprot": "X8FJ93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ93"
  },
  {
    "uniprot": "X8FGC3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGC3"
  },
  {
    "uniprot": "X8EX50",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX50"
  },
  {
    "uniprot": "X8F2I5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2I5"
  },
  {
    "uniprot": "X8F9Q2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9Q2"
  },
  {
    "uniprot": "X8FG92",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG92"
  },
  {
    "uniprot": "X8F2L1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2L1"
  },
  {
    "uniprot": "X8FDL9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDL9"
  },
  {
    "uniprot": "X8FHW1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHW1"
  },
  {
    "uniprot": "X8F2N3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2N3"
  },
  {
    "uniprot": "X8FED9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FED9"
  },
  {
    "uniprot": "X8F0T6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0T6"
  },
  {
    "uniprot": "X8FCM6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCM6"
  },
  {
    "uniprot": "X8FKJ8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKJ8"
  },
  {
    "uniprot": "X8FKV8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKV8"
  },
  {
    "uniprot": "X8FP75",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP75"
  },
  {
    "uniprot": "X8F2I0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2I0"
  },
  {
    "uniprot": "X8F4Y2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Y2"
  },
  {
    "uniprot": "X8FEC8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEC8"
  },
  {
    "uniprot": "X8FJC1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJC1"
  },
  {
    "uniprot": "X8F0C8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0C8"
  },
  {
    "uniprot": "X8FA28",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA28"
  },
  {
    "uniprot": "X8EWS6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EWS6"
  },
  {
    "uniprot": "X8F4A0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4A0"
  },
  {
    "uniprot": "X8FFY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFY1"
  },
  {
    "uniprot": "X8FFV6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFV6"
  },
  {
    "uniprot": "X8FEX3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEX3"
  },
  {
    "uniprot": "X8FEX6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEX6"
  },
  {
    "uniprot": "X8F6L2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6L2"
  },
  {
    "uniprot": "X8FEV6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEV6"
  },
  {
    "uniprot": "X8F517",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F517"
  },
  {
    "uniprot": "X8FDV6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDV6"
  },
  {
    "uniprot": "X8EZQ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZQ0"
  },
  {
    "uniprot": "X8FJJ2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJJ2"
  },
  {
    "uniprot": "X8FM97",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM97"
  },
  {
    "uniprot": "X8FLH8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLH8"
  },
  {
    "uniprot": "X8F3J7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3J7"
  },
  {
    "uniprot": "X8EZ51",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ51"
  },
  {
    "uniprot": "X8FLY2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLY2"
  },
  {
    "uniprot": "X8FJ83",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ83"
  },
  {
    "uniprot": "X8FC86",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FC86"
  },
  {
    "uniprot": "X8FCI9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCI9"
  },
  {
    "uniprot": "X8F4I6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4I6"
  },
  {
    "uniprot": "X8FD00",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD00"
  },
  {
    "uniprot": "X8EZ65",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ65"
  },
  {
    "uniprot": "X8F388",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F388"
  },
  {
    "uniprot": "X8F2D5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2D5"
  },
  {
    "uniprot": "X8FFK9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFK9"
  },
  {
    "uniprot": "X8FCK1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCK1"
  },
  {
    "uniprot": "X8FFR1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFR1"
  },
  {
    "uniprot": "X8FHR6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHR6"
  },
  {
    "uniprot": "X8EZH1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZH1"
  },
  {
    "uniprot": "X8FBD0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBD0"
  },
  {
    "uniprot": "X8EXH6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXH6"
  },
  {
    "uniprot": "X8F6V3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6V3"
  },
  {
    "uniprot": "X8FP52",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FP52"
  },
  {
    "uniprot": "X8F386",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F386"
  },
  {
    "uniprot": "X8FKP8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKP8"
  },
  {
    "uniprot": "X8F604",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F604"
  },
  {
    "uniprot": "X8FHX9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHX9"
  },
  {
    "uniprot": "X8F4F6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4F6"
  },
  {
    "uniprot": "X8F3X9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3X9"
  },
  {
    "uniprot": "X8EX93",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX93"
  },
  {
    "uniprot": "X8FMG2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMG2"
  },
  {
    "uniprot": "X8F2Q4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Q4"
  },
  {
    "uniprot": "X8FPZ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FPZ4"
  },
  {
    "uniprot": "X8F7J5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7J5"
  },
  {
    "uniprot": "X8EZ13",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ13"
  },
  {
    "uniprot": "X8FFS6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFS6"
  },
  {
    "uniprot": "X8FB72",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB72"
  },
  {
    "uniprot": "X8EZZ5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZZ5"
  },
  {
    "uniprot": "X8FI44",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI44"
  },
  {
    "uniprot": "X8FN37",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN37"
  },
  {
    "uniprot": "X8EZY1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZY1"
  },
  {
    "uniprot": "X8FFM7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFM7"
  },
  {
    "uniprot": "X8F3I0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3I0"
  },
  {
    "uniprot": "X8FIH2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIH2"
  },
  {
    "uniprot": "X8FKM3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKM3"
  },
  {
    "uniprot": "X8FCZ0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCZ0"
  },
  {
    "uniprot": "X8F316",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F316"
  },
  {
    "uniprot": "X8F895",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F895"
  },
  {
    "uniprot": "X8EYL2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYL2"
  },
  {
    "uniprot": "X8F8K1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8K1"
  },
  {
    "uniprot": "X8FGN8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGN8"
  },
  {
    "uniprot": "X8F5C9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5C9"
  },
  {
    "uniprot": "X8EZ81",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ81"
  },
  {
    "uniprot": "X8F6Y1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Y1"
  },
  {
    "uniprot": "X8FI50",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI50"
  },
  {
    "uniprot": "X8FBC1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBC1"
  },
  {
    "uniprot": "X8EZU6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZU6"
  },
  {
    "uniprot": "X8FEN3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEN3"
  },
  {
    "uniprot": "X8FLQ4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLQ4"
  },
  {
    "uniprot": "X8F553",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F553"
  },
  {
    "uniprot": "X8FFV0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFV0"
  },
  {
    "uniprot": "X8F362",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F362"
  },
  {
    "uniprot": "X8FIP9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FIP9"
  },
  {
    "uniprot": "X8F6S9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6S9"
  },
  {
    "uniprot": "X8F7U7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7U7"
  },
  {
    "uniprot": "X8EZ68",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ68"
  },
  {
    "uniprot": "X8F263",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F263"
  },
  {
    "uniprot": "X8FLW9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLW9"
  },
  {
    "uniprot": "X8FE22",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE22"
  },
  {
    "uniprot": "X8F259",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F259"
  },
  {
    "uniprot": "X8FNK1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNK1"
  },
  {
    "uniprot": "X8F140",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F140"
  },
  {
    "uniprot": "X8F0N4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0N4"
  },
  {
    "uniprot": "X8F4P0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4P0"
  },
  {
    "uniprot": "X8F2X2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2X2"
  },
  {
    "uniprot": "X8FJ81",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ81"
  },
  {
    "uniprot": "X8F3L8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3L8"
  },
  {
    "uniprot": "X8F3G7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3G7"
  },
  {
    "uniprot": "X8FN03",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN03"
  },
  {
    "uniprot": "X8F4W1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4W1"
  },
  {
    "uniprot": "X8F9R3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F9R3"
  },
  {
    "uniprot": "X8FJY0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJY0"
  },
  {
    "uniprot": "X8FN22",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN22"
  },
  {
    "uniprot": "X8F117",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F117"
  },
  {
    "uniprot": "X8FN15",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN15"
  },
  {
    "uniprot": "X8F7G0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7G0"
  },
  {
    "uniprot": "X8F7E2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7E2"
  },
  {
    "uniprot": "X8FMK1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMK1"
  },
  {
    "uniprot": "X8FHC9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHC9"
  },
  {
    "uniprot": "X8FHN5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHN5"
  },
  {
    "uniprot": "X8F5M6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5M6"
  },
  {
    "uniprot": "X8F3N7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3N7"
  },
  {
    "uniprot": "X8FD90",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD90"
  },
  {
    "uniprot": "X8EX51",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX51"
  },
  {
    "uniprot": "X8F155",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F155"
  },
  {
    "uniprot": "X8FJ17",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ17"
  },
  {
    "uniprot": "X8F4Q9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4Q9"
  },
  {
    "uniprot": "X8FEM2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEM2"
  },
  {
    "uniprot": "X8F0A1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0A1"
  },
  {
    "uniprot": "X8FHT7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHT7"
  },
  {
    "uniprot": "X8FDG2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDG2"
  },
  {
    "uniprot": "X8F3L4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F3L4"
  },
  {
    "uniprot": "X8FG36",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FG36"
  },
  {
    "uniprot": "X8FMC1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FMC1"
  },
  {
    "uniprot": "X8F6Z3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6Z3"
  },
  {
    "uniprot": "X8FFT5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFT5"
  },
  {
    "uniprot": "X8F2T8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2T8"
  },
  {
    "uniprot": "X8FFJ3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFJ3"
  },
  {
    "uniprot": "X8EZ38",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ38"
  },
  {
    "uniprot": "X8FJ41",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJ41"
  },
  {
    "uniprot": "X8FBK2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBK2"
  },
  {
    "uniprot": "X8FKC1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKC1"
  },
  {
    "uniprot": "X8FNR4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNR4"
  },
  {
    "uniprot": "X8FK01",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FK01"
  },
  {
    "uniprot": "X8EYS4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EYS4"
  },
  {
    "uniprot": "X8FM23",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FM23"
  },
  {
    "uniprot": "X8FFZ7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFZ7"
  },
  {
    "uniprot": "X8FCS1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCS1"
  },
  {
    "uniprot": "X8EY75",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EY75"
  },
  {
    "uniprot": "X8FLT8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FLT8"
  },
  {
    "uniprot": "X8FA24",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA24"
  },
  {
    "uniprot": "X8FNM5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNM5"
  },
  {
    "uniprot": "X8FI73",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FI73"
  },
  {
    "uniprot": "X8F760",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F760"
  },
  {
    "uniprot": "X8FNB2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNB2"
  },
  {
    "uniprot": "X8FEF9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FEF9"
  },
  {
    "uniprot": "X8F7H8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7H8"
  },
  {
    "uniprot": "X8EXM5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXM5"
  },
  {
    "uniprot": "X8FHD2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHD2"
  },
  {
    "uniprot": "X8FNK6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FNK6"
  },
  {
    "uniprot": "X8F6W7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6W7"
  },
  {
    "uniprot": "X8F7Z1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F7Z1"
  },
  {
    "uniprot": "X8F8Q5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F8Q5"
  },
  {
    "uniprot": "X8FD76",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD76"
  },
  {
    "uniprot": "X8F5X8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5X8"
  },
  {
    "uniprot": "X8FN59",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN59"
  },
  {
    "uniprot": "X8EZC1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZC1"
  },
  {
    "uniprot": "X8F0H2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0H2"
  },
  {
    "uniprot": "X8F0A2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0A2"
  },
  {
    "uniprot": "X8FJX3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJX3"
  },
  {
    "uniprot": "X8FFL9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFL9"
  },
  {
    "uniprot": "X8FA80",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FA80"
  },
  {
    "uniprot": "X8EXT8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EXT8"
  },
  {
    "uniprot": "X8FD03",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FD03"
  },
  {
    "uniprot": "X8F860",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F860"
  },
  {
    "uniprot": "X8F6R4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F6R4"
  },
  {
    "uniprot": "X8F965",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F965"
  },
  {
    "uniprot": "X8EZU2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZU2"
  },
  {
    "uniprot": "X8FCD3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCD3"
  },
  {
    "uniprot": "X8F852",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F852"
  },
  {
    "uniprot": "X8FE73",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE73"
  },
  {
    "uniprot": "X8F2K1",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2K1"
  },
  {
    "uniprot": "X8FBY2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBY2"
  },
  {
    "uniprot": "X8FFW3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFW3"
  },
  {
    "uniprot": "X8FBD8",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBD8"
  },
  {
    "uniprot": "X8F2Y5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F2Y5"
  },
  {
    "uniprot": "X8FJR6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJR6"
  },
  {
    "uniprot": "X8F350",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F350"
  },
  {
    "uniprot": "X8FKN7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FKN7"
  },
  {
    "uniprot": "X8EX95",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EX95"
  },
  {
    "uniprot": "X8F563",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F563"
  },
  {
    "uniprot": "X8FHE5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHE5"
  },
  {
    "uniprot": "X8EZY7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZY7"
  },
  {
    "uniprot": "X8FB44",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FB44"
  },
  {
    "uniprot": "X8FHU7",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FHU7"
  },
  {
    "uniprot": "X8FCG6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FCG6"
  },
  {
    "uniprot": "X8F0Q3",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F0Q3"
  },
  {
    "uniprot": "X8FBA0",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FBA0"
  },
  {
    "uniprot": "X8F5X6",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F5X6"
  },
  {
    "uniprot": "X8FJR4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FJR4"
  },
  {
    "uniprot": "X8FE25",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FE25"
  },
  {
    "uniprot": "X8FDW5",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDW5"
  },
  {
    "uniprot": "X8F4C2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8F4C2"
  },
  {
    "uniprot": "X8FGV2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FGV2"
  },
  {
    "uniprot": "X8FN65",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FN65"
  },
  {
    "uniprot": "X8EZ57",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8EZ57"
  },
  {
    "uniprot": "X8FFD2",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FFD2"
  },
  {
    "uniprot": "X8FDA9",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FDA9"
  },
  {
    "uniprot": "X8FAF4",
    "species": "Mycobacterium ulcerans",
    "disease": "Buruli ulcer",
    "results": "/results/X8FAF4"
  },
  {
    "uniprot": "A0A3P7E5G6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5G6"
  },
  {
    "uniprot": "A0A3P7E096",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E096"
  },
  {
    "uniprot": "A0A3P7E4B7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4B7"
  },
  {
    "uniprot": "A0A183XT41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XT41"
  },
  {
    "uniprot": "A0A3P7E8Z8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8Z8"
  },
  {
    "uniprot": "A0A3P7FEG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEG7"
  },
  {
    "uniprot": "A0A3P7DT06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT06"
  },
  {
    "uniprot": "A0A3P7DR63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR63"
  },
  {
    "uniprot": "A0A3P7DXK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXK3"
  },
  {
    "uniprot": "A0A3P7DX63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX63"
  },
  {
    "uniprot": "A0A3P7FJ11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ11"
  },
  {
    "uniprot": "A0A3P7EIQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIQ2"
  },
  {
    "uniprot": "A0A3P7DII4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DII4"
  },
  {
    "uniprot": "A0A3P7E023",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E023"
  },
  {
    "uniprot": "A0A3P7DIF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIF3"
  },
  {
    "uniprot": "A0A3P7EWW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWW9"
  },
  {
    "uniprot": "A0A3P7DU84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU84"
  },
  {
    "uniprot": "A0A3P7EJZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJZ7"
  },
  {
    "uniprot": "A0A183XVS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XVS9"
  },
  {
    "uniprot": "A0A3P7DUY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUY1"
  },
  {
    "uniprot": "A0A3P7FIJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIJ1"
  },
  {
    "uniprot": "A0A3P7E5B0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5B0"
  },
  {
    "uniprot": "A0A3P7DY74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY74"
  },
  {
    "uniprot": "A0A3P7F1T6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1T6"
  },
  {
    "uniprot": "A0A3P7E455",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E455"
  },
  {
    "uniprot": "A0A3P7E8L1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8L1"
  },
  {
    "uniprot": "A0A3P7FLS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLS1"
  },
  {
    "uniprot": "J9EYL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYL1"
  },
  {
    "uniprot": "A0A3P7EBW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBW9"
  },
  {
    "uniprot": "A0A3P7EC63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC63"
  },
  {
    "uniprot": "A0A3P7G2T1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2T1"
  },
  {
    "uniprot": "A0A3P7G0J5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0J5"
  },
  {
    "uniprot": "A0A3P7E3W6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3W6"
  },
  {
    "uniprot": "A0A3P7DYT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYT9"
  },
  {
    "uniprot": "A0A3P7FZ77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ77"
  },
  {
    "uniprot": "A0A3P7DT83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT83"
  },
  {
    "uniprot": "A0A3P7G2N1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2N1"
  },
  {
    "uniprot": "A0A3P7E4W9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4W9"
  },
  {
    "uniprot": "A0A3P7E6G5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6G5"
  },
  {
    "uniprot": "A0A3P7FTA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTA0"
  },
  {
    "uniprot": "A0A3P7G610",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G610"
  },
  {
    "uniprot": "A0A3P7EBS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBS6"
  },
  {
    "uniprot": "A0A3P7E0H7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0H7"
  },
  {
    "uniprot": "A0A3P7EDL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDL7"
  },
  {
    "uniprot": "A0A3P7DJ73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ73"
  },
  {
    "uniprot": "A0A3P7DSA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSA5"
  },
  {
    "uniprot": "J9FA02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FA02"
  },
  {
    "uniprot": "A0A3P7EIU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIU2"
  },
  {
    "uniprot": "A0A3P7E7A9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7A9"
  },
  {
    "uniprot": "A0A3P7DKP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKP9"
  },
  {
    "uniprot": "A0A3P7G389",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G389"
  },
  {
    "uniprot": "A0A3P7E2E4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2E4"
  },
  {
    "uniprot": "A0A3P7FEN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEN7"
  },
  {
    "uniprot": "A0A3P7E4K3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4K3"
  },
  {
    "uniprot": "A0A183Y2T4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y2T4"
  },
  {
    "uniprot": "A0A3P7E4G7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4G7"
  },
  {
    "uniprot": "A0A3P7DY43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY43"
  },
  {
    "uniprot": "A0A3P7FH46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH46"
  },
  {
    "uniprot": "A0A3P7ET57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET57"
  },
  {
    "uniprot": "A0A3P7EH05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH05"
  },
  {
    "uniprot": "A0A3P7FZA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZA9"
  },
  {
    "uniprot": "A0A3P7E272",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E272"
  },
  {
    "uniprot": "A0A3P7EU70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU70"
  },
  {
    "uniprot": "A0A3P7DX86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX86"
  },
  {
    "uniprot": "A0A3P7DMS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMS9"
  },
  {
    "uniprot": "J9F972",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F972"
  },
  {
    "uniprot": "A0A3P7DV20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV20"
  },
  {
    "uniprot": "A0A3P7DKM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKM5"
  },
  {
    "uniprot": "A0A3P7DTC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTC2"
  },
  {
    "uniprot": "A0A3P7GGR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGR8"
  },
  {
    "uniprot": "A0A3P7FHE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHE0"
  },
  {
    "uniprot": "A0A3P7DLE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLE1"
  },
  {
    "uniprot": "A0A3P7FKQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKQ6"
  },
  {
    "uniprot": "A0A3P7E9H8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9H8"
  },
  {
    "uniprot": "A0A3P7EJY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJY2"
  },
  {
    "uniprot": "A0A3P7DPT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPT2"
  },
  {
    "uniprot": "J9EZZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZZ9"
  },
  {
    "uniprot": "A0A3P7E162",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E162"
  },
  {
    "uniprot": "A0A3P7F9X9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9X9"
  },
  {
    "uniprot": "A0A3P7GFE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFE4"
  },
  {
    "uniprot": "A0A3P7G2A2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2A2"
  },
  {
    "uniprot": "J9EX03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EX03"
  },
  {
    "uniprot": "A0A3P7EKM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKM6"
  },
  {
    "uniprot": "A0A3P7G253",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G253"
  },
  {
    "uniprot": "A0A3P7EYS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYS6"
  },
  {
    "uniprot": "A0A3P7ER19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ER19"
  },
  {
    "uniprot": "A0A3P7EAC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAC2"
  },
  {
    "uniprot": "A0A3P7FBJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBJ8"
  },
  {
    "uniprot": "A0A3P7EFQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFQ2"
  },
  {
    "uniprot": "A0A3P7E702",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E702"
  },
  {
    "uniprot": "A0A3P7EPW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPW9"
  },
  {
    "uniprot": "A0A3P7FUF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUF8"
  },
  {
    "uniprot": "A0A3P7E789",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E789"
  },
  {
    "uniprot": "A0A3P7ED39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED39"
  },
  {
    "uniprot": "A0A3P7DDI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDI7"
  },
  {
    "uniprot": "A0A3P7EFD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFD2"
  },
  {
    "uniprot": "A0A3P7DIV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIV0"
  },
  {
    "uniprot": "A0A3P7EIA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIA0"
  },
  {
    "uniprot": "A0A3P7E9V2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9V2"
  },
  {
    "uniprot": "A0A3P7G002",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G002"
  },
  {
    "uniprot": "A0A3P7EB75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB75"
  },
  {
    "uniprot": "A0A3P7EZY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZY9"
  },
  {
    "uniprot": "A0A3P7FUM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUM5"
  },
  {
    "uniprot": "A0A3P7ECH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECH6"
  },
  {
    "uniprot": "A0A183XG24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XG24"
  },
  {
    "uniprot": "A0A3P7G9V4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9V4"
  },
  {
    "uniprot": "A0A3P7DXB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXB6"
  },
  {
    "uniprot": "A0A3P7EMH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMH0"
  },
  {
    "uniprot": "A0A3P7FLK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLK9"
  },
  {
    "uniprot": "A0A3P7E7S6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7S6"
  },
  {
    "uniprot": "A0A3P7G3K7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3K7"
  },
  {
    "uniprot": "A0A3P7FD69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD69"
  },
  {
    "uniprot": "A0A3P7DFK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFK6"
  },
  {
    "uniprot": "A0A3P7FMS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMS6"
  },
  {
    "uniprot": "A0A3P7GKL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKL7"
  },
  {
    "uniprot": "A0A3P7EWC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWC9"
  },
  {
    "uniprot": "A0A3P7E961",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E961"
  },
  {
    "uniprot": "J9FLZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FLZ4"
  },
  {
    "uniprot": "A0A183XT20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XT20"
  },
  {
    "uniprot": "A0A3P7GE70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GE70"
  },
  {
    "uniprot": "A0A3P7FYV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYV9"
  },
  {
    "uniprot": "A0A3P7FKC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKC2"
  },
  {
    "uniprot": "A0A183XFJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XFJ3"
  },
  {
    "uniprot": "A0A3P7EAG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAG9"
  },
  {
    "uniprot": "A0A3P7E1J8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1J8"
  },
  {
    "uniprot": "A0A3P7DRD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRD4"
  },
  {
    "uniprot": "A0A3P7E344",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E344"
  },
  {
    "uniprot": "A0A3P7E4V4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4V4"
  },
  {
    "uniprot": "J9BAR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BAR2"
  },
  {
    "uniprot": "A0A3P7E391",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E391"
  },
  {
    "uniprot": "A0A3P7FSK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSK8"
  },
  {
    "uniprot": "A0A3P7EGR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGR4"
  },
  {
    "uniprot": "A0A3P7DJM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJM2"
  },
  {
    "uniprot": "A0A3P7DF85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF85"
  },
  {
    "uniprot": "A0A3P7E6E0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6E0"
  },
  {
    "uniprot": "A0A3P7EEN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEN6"
  },
  {
    "uniprot": "A0A3P7FKL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKL4"
  },
  {
    "uniprot": "A0A3P7FUB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUB7"
  },
  {
    "uniprot": "J9BH00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BH00"
  },
  {
    "uniprot": "A0A3P7ESX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESX1"
  },
  {
    "uniprot": "A0A3P7FS24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS24"
  },
  {
    "uniprot": "J9FC38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FC38"
  },
  {
    "uniprot": "J9ECP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ECP3"
  },
  {
    "uniprot": "A0A3P7DSV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSV4"
  },
  {
    "uniprot": "A0A3P7FHD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHD0"
  },
  {
    "uniprot": "A0A3P7E6C0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6C0"
  },
  {
    "uniprot": "A0A3P7E125",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E125"
  },
  {
    "uniprot": "A0A3P7E324",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E324"
  },
  {
    "uniprot": "A0A3P7FU46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU46"
  },
  {
    "uniprot": "A0A3P7DT21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT21"
  },
  {
    "uniprot": "A0A3P7FDU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDU5"
  },
  {
    "uniprot": "A0A3P7EAT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAT0"
  },
  {
    "uniprot": "A0A3P7FR79",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR79"
  },
  {
    "uniprot": "A0A3P7EW81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EW81"
  },
  {
    "uniprot": "A0A3P7DFN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFN3"
  },
  {
    "uniprot": "A0A3P7FTR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTR0"
  },
  {
    "uniprot": "A0A3P7ECZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECZ0"
  },
  {
    "uniprot": "A0A3P7DMF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMF2"
  },
  {
    "uniprot": "A0A183XKW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XKW9"
  },
  {
    "uniprot": "A0A183XM18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XM18"
  },
  {
    "uniprot": "A0A3P7E5I3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5I3"
  },
  {
    "uniprot": "A0A3P7FKM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKM4"
  },
  {
    "uniprot": "A0A3P7FPL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPL4"
  },
  {
    "uniprot": "A0A3P7FN15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN15"
  },
  {
    "uniprot": "A0A183XCX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCX6"
  },
  {
    "uniprot": "A0A3P7FD18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD18"
  },
  {
    "uniprot": "A0A3P7FWI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWI6"
  },
  {
    "uniprot": "A0A3P7E0S2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0S2"
  },
  {
    "uniprot": "A0A3P7DM98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM98"
  },
  {
    "uniprot": "A0A183XVI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XVI0"
  },
  {
    "uniprot": "A0A3P7FJ90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ90"
  },
  {
    "uniprot": "A0A3P7GMN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GMN7"
  },
  {
    "uniprot": "A0A3P7DXF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXF2"
  },
  {
    "uniprot": "A0A3P7G537",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G537"
  },
  {
    "uniprot": "A0A3P7FDT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDT8"
  },
  {
    "uniprot": "A0A3P7EJJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJJ7"
  },
  {
    "uniprot": "A0A3P7FGQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGQ0"
  },
  {
    "uniprot": "A0A3P7E221",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E221"
  },
  {
    "uniprot": "J9EMP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EMP3"
  },
  {
    "uniprot": "A0A3P7FUP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUP9"
  },
  {
    "uniprot": "A0A3P7FCC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCC0"
  },
  {
    "uniprot": "A0A3P7DEI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEI0"
  },
  {
    "uniprot": "A0A3P7GNY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GNY2"
  },
  {
    "uniprot": "A0A3P7EJK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJK6"
  },
  {
    "uniprot": "A0A3P7E8P2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8P2"
  },
  {
    "uniprot": "A0A3P7DAW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAW9"
  },
  {
    "uniprot": "J9BER7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BER7"
  },
  {
    "uniprot": "A0A3P7E564",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E564"
  },
  {
    "uniprot": "A0A3P7EBF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBF4"
  },
  {
    "uniprot": "A0A3P7DFY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFY0"
  },
  {
    "uniprot": "A0A3P7FJE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJE7"
  },
  {
    "uniprot": "A0A3P7FF46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF46"
  },
  {
    "uniprot": "A0A3P7GDM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDM5"
  },
  {
    "uniprot": "A0A3P7DCK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCK1"
  },
  {
    "uniprot": "A0A3P7DUT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUT9"
  },
  {
    "uniprot": "A0A3P7FTF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTF8"
  },
  {
    "uniprot": "A0A3P7DJ12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ12"
  },
  {
    "uniprot": "A0A3P7FWD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWD0"
  },
  {
    "uniprot": "A0A3P7E4Y1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4Y1"
  },
  {
    "uniprot": "A0A3P7EDF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDF3"
  },
  {
    "uniprot": "A0A3P7FG01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG01"
  },
  {
    "uniprot": "A0A3P7ELS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELS5"
  },
  {
    "uniprot": "A0A3P7DRZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRZ9"
  },
  {
    "uniprot": "A0A3P7EHW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHW2"
  },
  {
    "uniprot": "A0A3P7ERZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERZ5"
  },
  {
    "uniprot": "A0A3P7EL59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL59"
  },
  {
    "uniprot": "A0A3P7DYG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYG1"
  },
  {
    "uniprot": "A0A3P7DZE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZE6"
  },
  {
    "uniprot": "A0A3P7DKD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKD3"
  },
  {
    "uniprot": "A0A3P7F3Y3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3Y3"
  },
  {
    "uniprot": "A0A3P7FFD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFD1"
  },
  {
    "uniprot": "J9F6X3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F6X3"
  },
  {
    "uniprot": "J9ERW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERW6"
  },
  {
    "uniprot": "J9FBV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FBV1"
  },
  {
    "uniprot": "A0A3P7EJ16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ16"
  },
  {
    "uniprot": "A0A3P7EHT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHT6"
  },
  {
    "uniprot": "A0A3P7ENL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENL1"
  },
  {
    "uniprot": "A0A3P7DRK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRK3"
  },
  {
    "uniprot": "A0A3P7DNC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNC8"
  },
  {
    "uniprot": "A0A3P7G432",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G432"
  },
  {
    "uniprot": "A0A3P7FKY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKY7"
  },
  {
    "uniprot": "A0A3P7ECM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECM4"
  },
  {
    "uniprot": "A0A3P7GKG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKG5"
  },
  {
    "uniprot": "A0A183XLV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLV5"
  },
  {
    "uniprot": "J9F6X4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F6X4"
  },
  {
    "uniprot": "A0A3P7DNN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNN0"
  },
  {
    "uniprot": "A0A3P7DM07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM07"
  },
  {
    "uniprot": "A0A3P7GKY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKY3"
  },
  {
    "uniprot": "A0A3P7GMI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GMI3"
  },
  {
    "uniprot": "A0A3P7DS93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS93"
  },
  {
    "uniprot": "J9EV99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EV99"
  },
  {
    "uniprot": "A0A3P7GL00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GL00"
  },
  {
    "uniprot": "A0A3P7EAQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAQ1"
  },
  {
    "uniprot": "A0A3P7E142",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E142"
  },
  {
    "uniprot": "J9FDD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FDD9"
  },
  {
    "uniprot": "J9F7S4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F7S4"
  },
  {
    "uniprot": "A0A3P7GHD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHD5"
  },
  {
    "uniprot": "A0A3P7DVI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVI2"
  },
  {
    "uniprot": "A0A3P7EPB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPB3"
  },
  {
    "uniprot": "A0A3P7DJ61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ61"
  },
  {
    "uniprot": "A0A3P7FJA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJA9"
  },
  {
    "uniprot": "A0A3P7DJ04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ04"
  },
  {
    "uniprot": "A0A3P7FGK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGK8"
  },
  {
    "uniprot": "A0A3P7FPZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPZ4"
  },
  {
    "uniprot": "A0A3P7DN31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN31"
  },
  {
    "uniprot": "A0A3P7EQQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQQ6"
  },
  {
    "uniprot": "A0A3P7DJR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJR7"
  },
  {
    "uniprot": "A0A3P7FCD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCD4"
  },
  {
    "uniprot": "J9EQS4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQS4"
  },
  {
    "uniprot": "A0A3P7FM22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM22"
  },
  {
    "uniprot": "A0A3P7DL89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL89"
  },
  {
    "uniprot": "A0A3P7E9K5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9K5"
  },
  {
    "uniprot": "A0A3P7DKM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKM4"
  },
  {
    "uniprot": "A0A3P7FIB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIB2"
  },
  {
    "uniprot": "A0A183XLY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLY6"
  },
  {
    "uniprot": "A0A3P7DUK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUK6"
  },
  {
    "uniprot": "A0A3P7DXW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXW8"
  },
  {
    "uniprot": "A0A3P7EP12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP12"
  },
  {
    "uniprot": "J9FLI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FLI9"
  },
  {
    "uniprot": "A0A183XIB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XIB5"
  },
  {
    "uniprot": "A0A3P7G4L7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4L7"
  },
  {
    "uniprot": "A0A3P7EDR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDR0"
  },
  {
    "uniprot": "A0A3P7DTU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTU6"
  },
  {
    "uniprot": "A0A183XWK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XWK1"
  },
  {
    "uniprot": "A0A3P7EAL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAL9"
  },
  {
    "uniprot": "A0A3P7ECZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECZ7"
  },
  {
    "uniprot": "A0A3P7FBB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBB5"
  },
  {
    "uniprot": "A0A3P7DLJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLJ2"
  },
  {
    "uniprot": "A0A3P7FUV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUV2"
  },
  {
    "uniprot": "A0A3P7DZU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZU7"
  },
  {
    "uniprot": "A0A3P7FR62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR62"
  },
  {
    "uniprot": "A0A3P7FIN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIN0"
  },
  {
    "uniprot": "A0A3P7DAV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAV9"
  },
  {
    "uniprot": "A0A3P7DVX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVX0"
  },
  {
    "uniprot": "A0A3P7DHK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHK5"
  },
  {
    "uniprot": "A0A3P7DUW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUW8"
  },
  {
    "uniprot": "A0A3P7GF27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GF27"
  },
  {
    "uniprot": "A0A3P7FPQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPQ2"
  },
  {
    "uniprot": "A0A183XRI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XRI1"
  },
  {
    "uniprot": "J9F255",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F255"
  },
  {
    "uniprot": "A0A3P7EM93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM93"
  },
  {
    "uniprot": "A0A3P7DJM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJM6"
  },
  {
    "uniprot": "A0A3P7DTK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTK5"
  },
  {
    "uniprot": "J9EYZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYZ0"
  },
  {
    "uniprot": "A0A3P7FW92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW92"
  },
  {
    "uniprot": "A0A3P7FKN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKN0"
  },
  {
    "uniprot": "A0A3P7FZP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZP1"
  },
  {
    "uniprot": "A0A183XHN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XHN5"
  },
  {
    "uniprot": "A0A3P7FWU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWU7"
  },
  {
    "uniprot": "A0A3P7FMH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMH4"
  },
  {
    "uniprot": "A0A3P7FKA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKA2"
  },
  {
    "uniprot": "A0A3P7GJS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJS8"
  },
  {
    "uniprot": "A0A3P7DMU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMU8"
  },
  {
    "uniprot": "A0A3P7EC89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC89"
  },
  {
    "uniprot": "A0A3P7E062",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E062"
  },
  {
    "uniprot": "A0A3P7DI98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI98"
  },
  {
    "uniprot": "J9DKV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DKV7"
  },
  {
    "uniprot": "A0A3P7FWQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWQ5"
  },
  {
    "uniprot": "A0A3P7EB04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB04"
  },
  {
    "uniprot": "A0A3P7GI35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GI35"
  },
  {
    "uniprot": "A0A3P7ES57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ES57"
  },
  {
    "uniprot": "A0A3P7FMI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMI2"
  },
  {
    "uniprot": "J9F9G9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F9G9"
  },
  {
    "uniprot": "A0A3P7ED42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED42"
  },
  {
    "uniprot": "A0A3P7DBQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBQ0"
  },
  {
    "uniprot": "A0A3P7DVY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVY4"
  },
  {
    "uniprot": "A0A3P7DFT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFT6"
  },
  {
    "uniprot": "A0A3P7FWV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWV3"
  },
  {
    "uniprot": "A0A3P7ESZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESZ9"
  },
  {
    "uniprot": "J9B1E4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B1E4"
  },
  {
    "uniprot": "A0A183XE20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XE20"
  },
  {
    "uniprot": "A0A3P7EYP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYP6"
  },
  {
    "uniprot": "A0A3P7DZN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZN2"
  },
  {
    "uniprot": "A0A3P7DPC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPC0"
  },
  {
    "uniprot": "A0A3P7DY86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY86"
  },
  {
    "uniprot": "A0A3P7E3I5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3I5"
  },
  {
    "uniprot": "A0A3P7F090",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F090"
  },
  {
    "uniprot": "A0A3P7DXC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXC1"
  },
  {
    "uniprot": "A0A3P7F401",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F401"
  },
  {
    "uniprot": "A0A3P7EDR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDR8"
  },
  {
    "uniprot": "J9F1L2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1L2"
  },
  {
    "uniprot": "J9EWF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EWF7"
  },
  {
    "uniprot": "J9EY13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EY13"
  },
  {
    "uniprot": "A0A3P7DCJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCJ3"
  },
  {
    "uniprot": "A0A3P7G4R6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4R6"
  },
  {
    "uniprot": "A0A3P7DKJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKJ8"
  },
  {
    "uniprot": "A0A3P7DU61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU61"
  },
  {
    "uniprot": "A0A3P7DNW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNW9"
  },
  {
    "uniprot": "A0A3P7EUP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUP1"
  },
  {
    "uniprot": "A0A3P7E032",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E032"
  },
  {
    "uniprot": "A0A3P7FCM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCM1"
  },
  {
    "uniprot": "A0A3P7DMM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMM1"
  },
  {
    "uniprot": "A0A3P7DFP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFP2"
  },
  {
    "uniprot": "A0A3P7DXY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXY8"
  },
  {
    "uniprot": "A0A3P7EA55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA55"
  },
  {
    "uniprot": "A0A3P7FUR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUR3"
  },
  {
    "uniprot": "J9EP38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EP38"
  },
  {
    "uniprot": "A0A3P7E7P2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7P2"
  },
  {
    "uniprot": "A0A3P7E200",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E200"
  },
  {
    "uniprot": "A0A3P7FAR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAR3"
  },
  {
    "uniprot": "A0A3P7GLH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLH4"
  },
  {
    "uniprot": "A0A3P7DNP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNP0"
  },
  {
    "uniprot": "A0A3P7E288",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E288"
  },
  {
    "uniprot": "A0A3P7DEE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEE9"
  },
  {
    "uniprot": "A0A3P7EC42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC42"
  },
  {
    "uniprot": "A0A3P7DZU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZU9"
  },
  {
    "uniprot": "A0A3P7F7I4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7I4"
  },
  {
    "uniprot": "A0A3P7DTX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTX3"
  },
  {
    "uniprot": "A0A3P7EH59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH59"
  },
  {
    "uniprot": "A0A3P7DPP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPP5"
  },
  {
    "uniprot": "A0A3P7DX44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX44"
  },
  {
    "uniprot": "A0A3P7DU03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU03"
  },
  {
    "uniprot": "A0A3P7DSY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSY6"
  },
  {
    "uniprot": "A0A3P7EWX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWX7"
  },
  {
    "uniprot": "A0A3P7E133",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E133"
  },
  {
    "uniprot": "A0A3P7ENU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENU8"
  },
  {
    "uniprot": "A0A183XC10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XC10"
  },
  {
    "uniprot": "A0A3P7DPG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPG0"
  },
  {
    "uniprot": "A0A3P7E0J9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0J9"
  },
  {
    "uniprot": "A0A3P7FU43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU43"
  },
  {
    "uniprot": "A0A3P7EFR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFR0"
  },
  {
    "uniprot": "A0A3P7FC85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC85"
  },
  {
    "uniprot": "A0A3P7F3G4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3G4"
  },
  {
    "uniprot": "A0A3P7DFJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFJ2"
  },
  {
    "uniprot": "J9AWZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AWZ6"
  },
  {
    "uniprot": "A0A3P7FA37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FA37"
  },
  {
    "uniprot": "A0A3P7FR44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR44"
  },
  {
    "uniprot": "A0A3P7E8G7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8G7"
  },
  {
    "uniprot": "A0A3P7G1G2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1G2"
  },
  {
    "uniprot": "A0A3P7EYX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYX2"
  },
  {
    "uniprot": "A0A183XPK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XPK2"
  },
  {
    "uniprot": "A0A3P7FBS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBS0"
  },
  {
    "uniprot": "A0A3P7DV67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV67"
  },
  {
    "uniprot": "A0A3P7EMJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMJ8"
  },
  {
    "uniprot": "A0A3P7DMC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMC5"
  },
  {
    "uniprot": "A0A3P7FDR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDR4"
  },
  {
    "uniprot": "A0A3P7DLD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLD7"
  },
  {
    "uniprot": "A0A3P7EFX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFX8"
  },
  {
    "uniprot": "A0A3P7ED27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED27"
  },
  {
    "uniprot": "A0A3P7ELC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELC1"
  },
  {
    "uniprot": "A0A3P7EFH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFH5"
  },
  {
    "uniprot": "J9DVS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DVS3"
  },
  {
    "uniprot": "A0A3P7DNB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNB7"
  },
  {
    "uniprot": "A0A3P7FHD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHD8"
  },
  {
    "uniprot": "A0A3P7ETW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETW0"
  },
  {
    "uniprot": "A0A3P7FK25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK25"
  },
  {
    "uniprot": "A0A3P7EVT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVT0"
  },
  {
    "uniprot": "A0A3P7DQ47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ47"
  },
  {
    "uniprot": "A0A3P7EBG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBG4"
  },
  {
    "uniprot": "A0A3P7DU30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU30"
  },
  {
    "uniprot": "A0A3P7GCX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCX5"
  },
  {
    "uniprot": "A0A3P7FMZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMZ8"
  },
  {
    "uniprot": "J9EHG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EHG2"
  },
  {
    "uniprot": "A0A3P7EDK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDK5"
  },
  {
    "uniprot": "A0A3P7EBI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBI1"
  },
  {
    "uniprot": "A0A3P7FU87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU87"
  },
  {
    "uniprot": "A0A3P7FJQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJQ1"
  },
  {
    "uniprot": "A0A3P7FR09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR09"
  },
  {
    "uniprot": "A0A3P7E749",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E749"
  },
  {
    "uniprot": "A0A3P7GFD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFD5"
  },
  {
    "uniprot": "A0A3P7EN46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN46"
  },
  {
    "uniprot": "A0A3P7EA14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA14"
  },
  {
    "uniprot": "A0A3P7EH36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH36"
  },
  {
    "uniprot": "J9E1J0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E1J0"
  },
  {
    "uniprot": "A0A3P7DQH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQH3"
  },
  {
    "uniprot": "J9EPU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPU6"
  },
  {
    "uniprot": "A0A3P7EPJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPJ6"
  },
  {
    "uniprot": "A0A3P7E9W6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9W6"
  },
  {
    "uniprot": "A0A3P7EG30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG30"
  },
  {
    "uniprot": "A0A3P7FKW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKW9"
  },
  {
    "uniprot": "A0A3P7DZG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZG2"
  },
  {
    "uniprot": "A0A3P7E8R1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8R1"
  },
  {
    "uniprot": "A0A3P7DWP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWP2"
  },
  {
    "uniprot": "A0A3P7GCJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCJ0"
  },
  {
    "uniprot": "A0A3P7FY55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY55"
  },
  {
    "uniprot": "A0A3P7DUB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUB4"
  },
  {
    "uniprot": "A0A3P7FRM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRM5"
  },
  {
    "uniprot": "A0A3P7DXX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXX2"
  },
  {
    "uniprot": "A0A3P7E4M0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4M0"
  },
  {
    "uniprot": "A0A3P7EBD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBD8"
  },
  {
    "uniprot": "A0A3P7F2J5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2J5"
  },
  {
    "uniprot": "A0A3P7FQY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQY1"
  },
  {
    "uniprot": "A0A3P7E1T7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1T7"
  },
  {
    "uniprot": "A0A3P7DZD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZD4"
  },
  {
    "uniprot": "A0A3P7EHZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHZ9"
  },
  {
    "uniprot": "A0A3P7EVT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVT4"
  },
  {
    "uniprot": "A0A3P7EFV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFV1"
  },
  {
    "uniprot": "A0A3P7FN58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN58"
  },
  {
    "uniprot": "A0A3P7DDD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDD2"
  },
  {
    "uniprot": "A0A3P7E301",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E301"
  },
  {
    "uniprot": "A0A3P7FJ58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ58"
  },
  {
    "uniprot": "A0A3P7FV94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV94"
  },
  {
    "uniprot": "A0A3P7DY52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY52"
  },
  {
    "uniprot": "J9B4H7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B4H7"
  },
  {
    "uniprot": "A0A3P7ENT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENT3"
  },
  {
    "uniprot": "A0A3P7DNZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNZ5"
  },
  {
    "uniprot": "A0A3P7E1Y9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1Y9"
  },
  {
    "uniprot": "A0A3P7EAX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAX5"
  },
  {
    "uniprot": "A0A3P7G8K9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8K9"
  },
  {
    "uniprot": "A0A3P7E8M9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8M9"
  },
  {
    "uniprot": "A0A3P7EDZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDZ8"
  },
  {
    "uniprot": "A0A3P7DZR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZR3"
  },
  {
    "uniprot": "A0A3P7DNT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNT6"
  },
  {
    "uniprot": "A0A3P7DE81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE81"
  },
  {
    "uniprot": "A0A3P7E026",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E026"
  },
  {
    "uniprot": "A0A3P7ERX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERX8"
  },
  {
    "uniprot": "A0A183XLH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLH6"
  },
  {
    "uniprot": "A0A3P7EH80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH80"
  },
  {
    "uniprot": "A0A183XF56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XF56"
  },
  {
    "uniprot": "A0A3P7E4S9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4S9"
  },
  {
    "uniprot": "A0A3P7GIJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIJ1"
  },
  {
    "uniprot": "A0A3P7GAP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAP6"
  },
  {
    "uniprot": "A0A3P7FP83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP83"
  },
  {
    "uniprot": "A0A3P7DZ17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ17"
  },
  {
    "uniprot": "A0A3P7FEH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEH1"
  },
  {
    "uniprot": "A0A3P7E0R0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0R0"
  },
  {
    "uniprot": "J9EU37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EU37"
  },
  {
    "uniprot": "A0A3P7EZ51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZ51"
  },
  {
    "uniprot": "J9EAS7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EAS7"
  },
  {
    "uniprot": "A0A3P7DZA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZA1"
  },
  {
    "uniprot": "A0A3P7EAM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAM1"
  },
  {
    "uniprot": "A0A183XJG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XJG3"
  },
  {
    "uniprot": "A0A3P7FFD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFD3"
  },
  {
    "uniprot": "A0A3P7DYR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYR2"
  },
  {
    "uniprot": "A0A3P7GFS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFS5"
  },
  {
    "uniprot": "J9EA08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EA08"
  },
  {
    "uniprot": "A0A3P7FPX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPX6"
  },
  {
    "uniprot": "A0A3P7ENQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENQ8"
  },
  {
    "uniprot": "A0A3P7E5C4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5C4"
  },
  {
    "uniprot": "A0A3P7EDS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDS0"
  },
  {
    "uniprot": "A0A3P7E8B2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8B2"
  },
  {
    "uniprot": "A0A3P7DYZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYZ1"
  },
  {
    "uniprot": "J9EZK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZK1"
  },
  {
    "uniprot": "A0A3P7DFC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFC7"
  },
  {
    "uniprot": "A0A3P7E725",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E725"
  },
  {
    "uniprot": "J9F2H2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2H2"
  },
  {
    "uniprot": "A0A3P7F0U2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0U2"
  },
  {
    "uniprot": "A0A3P7FCY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCY2"
  },
  {
    "uniprot": "A0A3P7FQ37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ37"
  },
  {
    "uniprot": "A0A3P7DD34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD34"
  },
  {
    "uniprot": "J9FFV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FFV2"
  },
  {
    "uniprot": "A0A3P7F837",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F837"
  },
  {
    "uniprot": "A0A3P7DVZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVZ1"
  },
  {
    "uniprot": "A0A3P7DW85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW85"
  },
  {
    "uniprot": "A0A3P7FK82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK82"
  },
  {
    "uniprot": "A0A3P7G1B9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1B9"
  },
  {
    "uniprot": "A0A3P7GL70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GL70"
  },
  {
    "uniprot": "A0A3P7F827",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F827"
  },
  {
    "uniprot": "A0A3P7DZW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZW7"
  },
  {
    "uniprot": "A0A3P7EL75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL75"
  },
  {
    "uniprot": "A0A3P7EN26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN26"
  },
  {
    "uniprot": "A0A3P7FM74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM74"
  },
  {
    "uniprot": "A0A3P7FNR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNR0"
  },
  {
    "uniprot": "A0A3P7FT20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT20"
  },
  {
    "uniprot": "A0A3P7ER69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ER69"
  },
  {
    "uniprot": "A0A3P7DRG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRG6"
  },
  {
    "uniprot": "A0A3P7EE36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE36"
  },
  {
    "uniprot": "A0A3P7EJG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJG1"
  },
  {
    "uniprot": "A0A3P7FT80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT80"
  },
  {
    "uniprot": "A0A3P7E1L9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1L9"
  },
  {
    "uniprot": "A0A3P7DRF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRF9"
  },
  {
    "uniprot": "A0A3P7DBW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBW3"
  },
  {
    "uniprot": "A0A3P7E9H4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9H4"
  },
  {
    "uniprot": "J9AGW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AGW1"
  },
  {
    "uniprot": "A0A3P7DWT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWT5"
  },
  {
    "uniprot": "A0A3P7EDE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDE1"
  },
  {
    "uniprot": "A0A3P7DKS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKS0"
  },
  {
    "uniprot": "A0A3P7DP75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP75"
  },
  {
    "uniprot": "A0A3P7EIF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIF2"
  },
  {
    "uniprot": "A0A3P7DUZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUZ8"
  },
  {
    "uniprot": "A0A3P7E5W9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5W9"
  },
  {
    "uniprot": "A0A3P7E1D8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1D8"
  },
  {
    "uniprot": "A0A3P7FJF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJF0"
  },
  {
    "uniprot": "A0A3P7FBX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBX9"
  },
  {
    "uniprot": "A0A3P7DUS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUS8"
  },
  {
    "uniprot": "A0A3P7EGN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGN1"
  },
  {
    "uniprot": "A0A3P7E0C9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0C9"
  },
  {
    "uniprot": "A0A3P7FQF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQF1"
  },
  {
    "uniprot": "A0A3P7EPR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPR8"
  },
  {
    "uniprot": "A0A3P7FEL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEL1"
  },
  {
    "uniprot": "J9EAI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EAI1"
  },
  {
    "uniprot": "A0A3P7E178",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E178"
  },
  {
    "uniprot": "J9FJZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FJZ4"
  },
  {
    "uniprot": "A0A3P7FBK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBK7"
  },
  {
    "uniprot": "A0A3P7FXA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXA9"
  },
  {
    "uniprot": "A0A3P7DT70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT70"
  },
  {
    "uniprot": "J9E8N8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E8N8"
  },
  {
    "uniprot": "A0A183XVW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XVW6"
  },
  {
    "uniprot": "A0A3P7E0K2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0K2"
  },
  {
    "uniprot": "J9E9E6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E9E6"
  },
  {
    "uniprot": "A0A3P7FRS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRS1"
  },
  {
    "uniprot": "A0A3P7ETT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETT3"
  },
  {
    "uniprot": "A0A3P7E6M3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6M3"
  },
  {
    "uniprot": "A0A3P7EEV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEV5"
  },
  {
    "uniprot": "A0A3P7DVS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVS5"
  },
  {
    "uniprot": "A0A3P7F1N0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1N0"
  },
  {
    "uniprot": "A0A3P7DB76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DB76"
  },
  {
    "uniprot": "A0A3P7DVD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVD0"
  },
  {
    "uniprot": "A0A3P7EDW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDW9"
  },
  {
    "uniprot": "A0A3P7EP83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP83"
  },
  {
    "uniprot": "A0A3P7FX31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX31"
  },
  {
    "uniprot": "A0A3P7EN24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN24"
  },
  {
    "uniprot": "A0A3P7DZL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZL5"
  },
  {
    "uniprot": "A0A3P7FQ35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ35"
  },
  {
    "uniprot": "A0A3P7EZZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZZ8"
  },
  {
    "uniprot": "A0A3P7FNL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNL8"
  },
  {
    "uniprot": "A0A3P7DLC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLC8"
  },
  {
    "uniprot": "A0A3P7E3C5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3C5"
  },
  {
    "uniprot": "A0A3P7ETR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETR9"
  },
  {
    "uniprot": "A0A3P7DU20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU20"
  },
  {
    "uniprot": "A0A3P7DFK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFK3"
  },
  {
    "uniprot": "J9EYZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYZ8"
  },
  {
    "uniprot": "A0A3P7EE06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE06"
  },
  {
    "uniprot": "A0A3P7FEX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEX4"
  },
  {
    "uniprot": "J9F1V3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1V3"
  },
  {
    "uniprot": "A0A3P7EL12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL12"
  },
  {
    "uniprot": "A0A3P7EL24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL24"
  },
  {
    "uniprot": "A0A3P7EH11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH11"
  },
  {
    "uniprot": "J9AL31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AL31"
  },
  {
    "uniprot": "A0A3P7FH37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH37"
  },
  {
    "uniprot": "A0A3P7DMY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMY7"
  },
  {
    "uniprot": "A0A3P7FHA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHA4"
  },
  {
    "uniprot": "A0A3P7DSE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSE7"
  },
  {
    "uniprot": "A0A3P7DXQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXQ7"
  },
  {
    "uniprot": "A0A3P7E8Q9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8Q9"
  },
  {
    "uniprot": "A0A3P7EE11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE11"
  },
  {
    "uniprot": "A0A3P7E1W0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1W0"
  },
  {
    "uniprot": "A0A3P7FGX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGX3"
  },
  {
    "uniprot": "A0A3P7DRC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRC1"
  },
  {
    "uniprot": "A0A3P7GB10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GB10"
  },
  {
    "uniprot": "A0A3P7EDJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDJ3"
  },
  {
    "uniprot": "A0A3P7DBP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBP3"
  },
  {
    "uniprot": "A0A3P7E9T3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9T3"
  },
  {
    "uniprot": "A0A183XWP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XWP6"
  },
  {
    "uniprot": "A0A3P7EMG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMG0"
  },
  {
    "uniprot": "A0A3P7EIM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIM4"
  },
  {
    "uniprot": "A0A3P7E065",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E065"
  },
  {
    "uniprot": "A0A3P7FCY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCY4"
  },
  {
    "uniprot": "A0A3P7DJQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJQ3"
  },
  {
    "uniprot": "A0A3P7E1P9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1P9"
  },
  {
    "uniprot": "A0A3P7EDE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDE4"
  },
  {
    "uniprot": "A0A3P7FE05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE05"
  },
  {
    "uniprot": "A0A3P7DPU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPU8"
  },
  {
    "uniprot": "J9DZR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DZR6"
  },
  {
    "uniprot": "A0A3P7FDQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDQ1"
  },
  {
    "uniprot": "A0A3P7FPL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPL9"
  },
  {
    "uniprot": "A0A3P7ELM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELM0"
  },
  {
    "uniprot": "A0A3P7GB80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GB80"
  },
  {
    "uniprot": "A0A3P7E656",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E656"
  },
  {
    "uniprot": "A0A3P7DNV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNV2"
  },
  {
    "uniprot": "A0A3P7FPS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPS2"
  },
  {
    "uniprot": "A0A183XEH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XEH7"
  },
  {
    "uniprot": "A0A3P7DUT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUT6"
  },
  {
    "uniprot": "A0A183XGZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XGZ6"
  },
  {
    "uniprot": "A0A3P7DW05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW05"
  },
  {
    "uniprot": "A0A3P7DRY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRY1"
  },
  {
    "uniprot": "A0A3P7EA36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA36"
  },
  {
    "uniprot": "A0A3P7F0H6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0H6"
  },
  {
    "uniprot": "A0A3P7DU05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU05"
  },
  {
    "uniprot": "J9EK34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EK34"
  },
  {
    "uniprot": "A0A3P7E1Q6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1Q6"
  },
  {
    "uniprot": "A0A3P7E8V2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8V2"
  },
  {
    "uniprot": "A0A183XIW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XIW2"
  },
  {
    "uniprot": "J9BGJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BGJ8"
  },
  {
    "uniprot": "A0A3P7FUT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUT4"
  },
  {
    "uniprot": "A0A3P7DSR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSR6"
  },
  {
    "uniprot": "A0A3P7FIL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIL1"
  },
  {
    "uniprot": "A0A3P7FN53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN53"
  },
  {
    "uniprot": "A0A3P7FKF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKF0"
  },
  {
    "uniprot": "A0A3P7DUW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUW6"
  },
  {
    "uniprot": "A0A3P7ERV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERV9"
  },
  {
    "uniprot": "A0A3P7DCV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCV5"
  },
  {
    "uniprot": "A0A3P7FEA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEA9"
  },
  {
    "uniprot": "A0A3P7EA98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA98"
  },
  {
    "uniprot": "A0A3P7DLM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLM0"
  },
  {
    "uniprot": "A0A3P7DC40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC40"
  },
  {
    "uniprot": "J9EE03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EE03"
  },
  {
    "uniprot": "A0A3P7GG63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GG63"
  },
  {
    "uniprot": "A0A3P7FDE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDE4"
  },
  {
    "uniprot": "A0A3P7EGC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGC4"
  },
  {
    "uniprot": "A0A3P7FE34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE34"
  },
  {
    "uniprot": "A0A3P7G202",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G202"
  },
  {
    "uniprot": "A0A3P7DQ10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ10"
  },
  {
    "uniprot": "A0A3P7DXR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXR4"
  },
  {
    "uniprot": "A0A3P7FLV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLV5"
  },
  {
    "uniprot": "A0A3P7DK02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK02"
  },
  {
    "uniprot": "A0A3P7EE61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE61"
  },
  {
    "uniprot": "A0A3P7FVC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVC0"
  },
  {
    "uniprot": "A0A3P7G9B1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9B1"
  },
  {
    "uniprot": "A0A3P7FJ00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ00"
  },
  {
    "uniprot": "A0A3P7E076",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E076"
  },
  {
    "uniprot": "A0A3P7DE42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE42"
  },
  {
    "uniprot": "A0A3P7DWN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWN7"
  },
  {
    "uniprot": "A0A3P7EF50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF50"
  },
  {
    "uniprot": "A0A3P7EN69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN69"
  },
  {
    "uniprot": "A0A3P7EHL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHL3"
  },
  {
    "uniprot": "A0A3P7FVS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVS8"
  },
  {
    "uniprot": "A0A3P7FX88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX88"
  },
  {
    "uniprot": "A0A3P7EAG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAG4"
  },
  {
    "uniprot": "A0A3P7DU41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU41"
  },
  {
    "uniprot": "A0A3P7EPQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPQ7"
  },
  {
    "uniprot": "A0A3P7GFE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFE1"
  },
  {
    "uniprot": "A0A3P7EAW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAW7"
  },
  {
    "uniprot": "A0A3P7DFN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFN4"
  },
  {
    "uniprot": "A0A3P7E3C9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3C9"
  },
  {
    "uniprot": "A0A3P7DY97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY97"
  },
  {
    "uniprot": "A0A3P7EHP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHP7"
  },
  {
    "uniprot": "A0A3P7E1V1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1V1"
  },
  {
    "uniprot": "A0A3P7FCJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCJ7"
  },
  {
    "uniprot": "J9EFY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EFY6"
  },
  {
    "uniprot": "A0A3P7DET1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DET1"
  },
  {
    "uniprot": "A0A3P7GGE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGE2"
  },
  {
    "uniprot": "A0A3P7FKK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKK5"
  },
  {
    "uniprot": "A0A3P7FRQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRQ9"
  },
  {
    "uniprot": "A0A183XXW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XXW3"
  },
  {
    "uniprot": "A0A3P7DWS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWS6"
  },
  {
    "uniprot": "A0A3P7FPU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPU5"
  },
  {
    "uniprot": "A0A3P7DK45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK45"
  },
  {
    "uniprot": "A0A3P7EJ90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ90"
  },
  {
    "uniprot": "A0A3P7E2Q1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2Q1"
  },
  {
    "uniprot": "A0A3P7EFX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFX5"
  },
  {
    "uniprot": "A0A3P7ESE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESE9"
  },
  {
    "uniprot": "A0A3P7DWV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWV1"
  },
  {
    "uniprot": "A0A3P7EM68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM68"
  },
  {
    "uniprot": "A0A3P7FW28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW28"
  },
  {
    "uniprot": "A0A3P7EIR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIR0"
  },
  {
    "uniprot": "J9ERS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERS6"
  },
  {
    "uniprot": "A0A3P7G0K4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0K4"
  },
  {
    "uniprot": "A0A3P7FJK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJK9"
  },
  {
    "uniprot": "A0A3P7DMP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMP2"
  },
  {
    "uniprot": "A0A3P7EAB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAB0"
  },
  {
    "uniprot": "A0A3P7DSH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSH3"
  },
  {
    "uniprot": "A0A3P7E9D4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9D4"
  },
  {
    "uniprot": "A0A3P7DLL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLL0"
  },
  {
    "uniprot": "J9E8M1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E8M1"
  },
  {
    "uniprot": "A0A3P7FGD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGD1"
  },
  {
    "uniprot": "A0A3P7DVD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVD4"
  },
  {
    "uniprot": "A0A3P7E2Q7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2Q7"
  },
  {
    "uniprot": "A0A3P7DIQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIQ5"
  },
  {
    "uniprot": "J9EH27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EH27"
  },
  {
    "uniprot": "A0A3P7EFV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFV2"
  },
  {
    "uniprot": "J9ESS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ESS6"
  },
  {
    "uniprot": "A0A3P7FH11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH11"
  },
  {
    "uniprot": "J9ENQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ENQ8"
  },
  {
    "uniprot": "A0A3P7FAA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAA0"
  },
  {
    "uniprot": "A0A3P7DI45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI45"
  },
  {
    "uniprot": "A0A3P7ES08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ES08"
  },
  {
    "uniprot": "A0A3P7FEI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEI6"
  },
  {
    "uniprot": "J9FND8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FND8"
  },
  {
    "uniprot": "A0A3P7E1N0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1N0"
  },
  {
    "uniprot": "A0A3P7E0F8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0F8"
  },
  {
    "uniprot": "J9EW79",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EW79"
  },
  {
    "uniprot": "A0A183XCM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCM6"
  },
  {
    "uniprot": "A0A3P7DQV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQV9"
  },
  {
    "uniprot": "A0A3P7E970",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E970"
  },
  {
    "uniprot": "J9ECY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ECY1"
  },
  {
    "uniprot": "A0A183XM33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XM33"
  },
  {
    "uniprot": "A0A3P7GD63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GD63"
  },
  {
    "uniprot": "A0A3P7DFI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFI2"
  },
  {
    "uniprot": "A0A3P7F0R5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0R5"
  },
  {
    "uniprot": "A0A3P7E037",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E037"
  },
  {
    "uniprot": "A0A3P7DNR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNR8"
  },
  {
    "uniprot": "A0A3P7DE74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE74"
  },
  {
    "uniprot": "A0A3P7E1R2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1R2"
  },
  {
    "uniprot": "A0A3P7DTF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTF9"
  },
  {
    "uniprot": "A0A3P7DM77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM77"
  },
  {
    "uniprot": "A0A183XLR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLR4"
  },
  {
    "uniprot": "A0A3P7G0R9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0R9"
  },
  {
    "uniprot": "A0A3P7GD34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GD34"
  },
  {
    "uniprot": "A0A3P7E2E6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2E6"
  },
  {
    "uniprot": "A0A3P7FS37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS37"
  },
  {
    "uniprot": "A0A3P7EAK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAK2"
  },
  {
    "uniprot": "A0A3P7FI29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI29"
  },
  {
    "uniprot": "J9EDJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EDJ9"
  },
  {
    "uniprot": "A0A3P7FJQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJQ4"
  },
  {
    "uniprot": "A0A3P7DUZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUZ5"
  },
  {
    "uniprot": "A0A3P7FNF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNF0"
  },
  {
    "uniprot": "J9FCB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FCB7"
  },
  {
    "uniprot": "A0A3P7DRJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRJ9"
  },
  {
    "uniprot": "A0A3P7E2L7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2L7"
  },
  {
    "uniprot": "A0A3P7DTA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTA9"
  },
  {
    "uniprot": "A0A3P7FCB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCB4"
  },
  {
    "uniprot": "A0A3P7GJE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJE6"
  },
  {
    "uniprot": "A0A3P7FL49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL49"
  },
  {
    "uniprot": "A0A3P7FIK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIK3"
  },
  {
    "uniprot": "A0A3P7FR20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR20"
  },
  {
    "uniprot": "A0A3P7FRY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRY7"
  },
  {
    "uniprot": "A0A3P7DS28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS28"
  },
  {
    "uniprot": "A0A3P7DUR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUR2"
  },
  {
    "uniprot": "A0A3P7EA19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA19"
  },
  {
    "uniprot": "A0A3P7EC50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC50"
  },
  {
    "uniprot": "A0A3P7DRD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRD1"
  },
  {
    "uniprot": "A0A3P7FSC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSC7"
  },
  {
    "uniprot": "A0A3P7DFG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFG3"
  },
  {
    "uniprot": "A0A183XJR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XJR4"
  },
  {
    "uniprot": "A0A3P7E2K6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2K6"
  },
  {
    "uniprot": "A0A3P7F8S1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8S1"
  },
  {
    "uniprot": "A0A3P7DU74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU74"
  },
  {
    "uniprot": "A0A3P7DRC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRC5"
  },
  {
    "uniprot": "A0A3P7FW09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW09"
  },
  {
    "uniprot": "J9FFF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FFF0"
  },
  {
    "uniprot": "A0A3P7ENK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENK3"
  },
  {
    "uniprot": "A0A3P7DDL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDL9"
  },
  {
    "uniprot": "A0A3P7DIE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIE0"
  },
  {
    "uniprot": "A0A3P7DXK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXK1"
  },
  {
    "uniprot": "A0A3P7DHC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHC3"
  },
  {
    "uniprot": "A0A3P7E2D8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2D8"
  },
  {
    "uniprot": "A0A3P7FAE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAE7"
  },
  {
    "uniprot": "A0A3P7EK68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK68"
  },
  {
    "uniprot": "A0A3P7DY15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY15"
  },
  {
    "uniprot": "A0A3P7DG43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG43"
  },
  {
    "uniprot": "A0A3P7EJQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJQ8"
  },
  {
    "uniprot": "A0A3P7DJK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJK7"
  },
  {
    "uniprot": "A0A3P7FH83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH83"
  },
  {
    "uniprot": "A0A3P7DS80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS80"
  },
  {
    "uniprot": "A0A3P7EMR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMR9"
  },
  {
    "uniprot": "A0A3P7DE66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE66"
  },
  {
    "uniprot": "A0A3P7E6Z2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6Z2"
  },
  {
    "uniprot": "A0A3P7DYN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYN4"
  },
  {
    "uniprot": "J9BGB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BGB0"
  },
  {
    "uniprot": "A0A3P7EF87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF87"
  },
  {
    "uniprot": "A0A3P7E7Q9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7Q9"
  },
  {
    "uniprot": "A0A3P7DEC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEC3"
  },
  {
    "uniprot": "A0A3P7E730",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E730"
  },
  {
    "uniprot": "A0A3P7EE94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE94"
  },
  {
    "uniprot": "A0A3P7FVW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVW1"
  },
  {
    "uniprot": "A0A3P7FQB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQB4"
  },
  {
    "uniprot": "J9ETR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ETR8"
  },
  {
    "uniprot": "J9EEH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EEH3"
  },
  {
    "uniprot": "A0A3P7DXC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXC6"
  },
  {
    "uniprot": "A0A3P7E2R2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2R2"
  },
  {
    "uniprot": "A0A183XC51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XC51"
  },
  {
    "uniprot": "A0A3P7DPP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPP7"
  },
  {
    "uniprot": "A0A183XM70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XM70"
  },
  {
    "uniprot": "A0A3P7DTL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTL8"
  },
  {
    "uniprot": "A0A3P7E7C7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7C7"
  },
  {
    "uniprot": "A0A3P7FYS4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYS4"
  },
  {
    "uniprot": "A0A3P7FF66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF66"
  },
  {
    "uniprot": "J9ED34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ED34"
  },
  {
    "uniprot": "A0A3P7DTR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTR4"
  },
  {
    "uniprot": "A0A183XVT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XVT7"
  },
  {
    "uniprot": "A0A3P7DIW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIW0"
  },
  {
    "uniprot": "A0A3P7FSI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSI0"
  },
  {
    "uniprot": "A0A3P7FGL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGL5"
  },
  {
    "uniprot": "A0A3P7DDK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDK8"
  },
  {
    "uniprot": "A0A3P7ERV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERV2"
  },
  {
    "uniprot": "A0A3P7EG98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG98"
  },
  {
    "uniprot": "A0A3P7E563",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E563"
  },
  {
    "uniprot": "A0A183XD21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XD21"
  },
  {
    "uniprot": "J9EX92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EX92"
  },
  {
    "uniprot": "A0A3P7E249",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E249"
  },
  {
    "uniprot": "A0A3P7GAE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAE0"
  },
  {
    "uniprot": "A0A3P7DWD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWD5"
  },
  {
    "uniprot": "A0A3P7E1J3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1J3"
  },
  {
    "uniprot": "A0A3P7GGS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGS6"
  },
  {
    "uniprot": "A0A3P7E5N4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5N4"
  },
  {
    "uniprot": "A0A3P7DZ40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ40"
  },
  {
    "uniprot": "A0A3P7F3W4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3W4"
  },
  {
    "uniprot": "A0A3P7DLQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLQ1"
  },
  {
    "uniprot": "A0A3P7DTS7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTS7"
  },
  {
    "uniprot": "A0A3P7FAP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAP9"
  },
  {
    "uniprot": "A0A3P7FNG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNG7"
  },
  {
    "uniprot": "J9EM48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EM48"
  },
  {
    "uniprot": "A0A3P7EEE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEE1"
  },
  {
    "uniprot": "A0A3P7FET8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FET8"
  },
  {
    "uniprot": "A0A3P7EUB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUB8"
  },
  {
    "uniprot": "A0A3P7EH20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH20"
  },
  {
    "uniprot": "J9EN83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EN83"
  },
  {
    "uniprot": "A0A3P7ETG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETG0"
  },
  {
    "uniprot": "A0A3P7DN64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN64"
  },
  {
    "uniprot": "A0A3P7EVH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVH6"
  },
  {
    "uniprot": "A0A3P7EFG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFG3"
  },
  {
    "uniprot": "A0A3P7DKK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKK5"
  },
  {
    "uniprot": "A0A3P7F3R4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3R4"
  },
  {
    "uniprot": "A0A3P7DXT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXT5"
  },
  {
    "uniprot": "A0A183XJP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XJP2"
  },
  {
    "uniprot": "A0A3P7DVC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVC8"
  },
  {
    "uniprot": "A0A3P7DX07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX07"
  },
  {
    "uniprot": "A0A3P7DZ75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ75"
  },
  {
    "uniprot": "A0A3P7DI49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI49"
  },
  {
    "uniprot": "A0A3P7FIZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIZ0"
  },
  {
    "uniprot": "A0A3P7EML4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EML4"
  },
  {
    "uniprot": "A0A3P7EDA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDA2"
  },
  {
    "uniprot": "A0A3P7F9Z7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9Z7"
  },
  {
    "uniprot": "A0A3P7DQ52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ52"
  },
  {
    "uniprot": "A0A3P7EEW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEW2"
  },
  {
    "uniprot": "A0A3P7DZE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZE9"
  },
  {
    "uniprot": "A0A3P7DR70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR70"
  },
  {
    "uniprot": "A0A3P7DEP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEP9"
  },
  {
    "uniprot": "A0A3P7E0G9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0G9"
  },
  {
    "uniprot": "A0A3P7DY87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY87"
  },
  {
    "uniprot": "A0A3P7DK84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK84"
  },
  {
    "uniprot": "A0A3P7DZ48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ48"
  },
  {
    "uniprot": "A0A3P7DYF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYF6"
  },
  {
    "uniprot": "A0A3P7FBH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBH8"
  },
  {
    "uniprot": "A0A3P7EEV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEV7"
  },
  {
    "uniprot": "A0A3P7DV75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV75"
  },
  {
    "uniprot": "A0A3P7EHU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHU7"
  },
  {
    "uniprot": "A0A3P7FWX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWX8"
  },
  {
    "uniprot": "A0A3P7FXT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXT1"
  },
  {
    "uniprot": "A0A3P7FUK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUK6"
  },
  {
    "uniprot": "A0A3P7EU84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU84"
  },
  {
    "uniprot": "A0A3P7FPS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPS8"
  },
  {
    "uniprot": "A0A3P7FDP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDP6"
  },
  {
    "uniprot": "A0A3P7FWH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWH4"
  },
  {
    "uniprot": "A0A3P7DSH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSH6"
  },
  {
    "uniprot": "A0A3P7G055",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G055"
  },
  {
    "uniprot": "A0A183XQP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XQP8"
  },
  {
    "uniprot": "A0A3P7ENS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENS5"
  },
  {
    "uniprot": "A0A3P7F4H9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F4H9"
  },
  {
    "uniprot": "J9EJY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EJY8"
  },
  {
    "uniprot": "A0A3P7ECR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECR0"
  },
  {
    "uniprot": "A0A3P7G5S0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5S0"
  },
  {
    "uniprot": "A0A3P7F962",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F962"
  },
  {
    "uniprot": "A0A3P7FCA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCA2"
  },
  {
    "uniprot": "A0A3P7F3V5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3V5"
  },
  {
    "uniprot": "A0A3P7FTP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTP9"
  },
  {
    "uniprot": "A0A3P7EE54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE54"
  },
  {
    "uniprot": "A0A183XI43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XI43"
  },
  {
    "uniprot": "A0A3P7DLY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLY2"
  },
  {
    "uniprot": "A0A3P7ESX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESX7"
  },
  {
    "uniprot": "A0A3P7DH05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH05"
  },
  {
    "uniprot": "A0A3P7GL40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GL40"
  },
  {
    "uniprot": "J9EUA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUA9"
  },
  {
    "uniprot": "A0A3P7DWG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWG5"
  },
  {
    "uniprot": "A0A3P7DVE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVE4"
  },
  {
    "uniprot": "A0A3P7FTU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTU4"
  },
  {
    "uniprot": "A0A3P7E7R6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7R6"
  },
  {
    "uniprot": "A0A3P7E3R4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3R4"
  },
  {
    "uniprot": "A0A3P7DL49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL49"
  },
  {
    "uniprot": "A0A3P7DTV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTV0"
  },
  {
    "uniprot": "A0A3P7E9B2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9B2"
  },
  {
    "uniprot": "A0A3P7DWY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWY5"
  },
  {
    "uniprot": "A0A3P7DPC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPC1"
  },
  {
    "uniprot": "A0A3P7EAH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAH6"
  },
  {
    "uniprot": "A0A3P7FIH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIH5"
  },
  {
    "uniprot": "A0A3P7DZM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZM3"
  },
  {
    "uniprot": "A0A3P7FVG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVG1"
  },
  {
    "uniprot": "A0A3P7EEB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEB1"
  },
  {
    "uniprot": "A0A3P7EH48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH48"
  },
  {
    "uniprot": "A0A3P7GIL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIL3"
  },
  {
    "uniprot": "J9B5K1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B5K1"
  },
  {
    "uniprot": "A0A3P7FKH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKH0"
  },
  {
    "uniprot": "A0A3P7FYC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYC5"
  },
  {
    "uniprot": "A0A3P7EHL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHL2"
  },
  {
    "uniprot": "A0A3P7G939",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G939"
  },
  {
    "uniprot": "A0A3P7DF52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF52"
  },
  {
    "uniprot": "A0A3P7F299",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F299"
  },
  {
    "uniprot": "A0A3P7FW63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW63"
  },
  {
    "uniprot": "A0A3P7E6V7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6V7"
  },
  {
    "uniprot": "A0A3P7F1H4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1H4"
  },
  {
    "uniprot": "A0A3P7DMK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMK3"
  },
  {
    "uniprot": "A0A3P7EA25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA25"
  },
  {
    "uniprot": "A0A3P7EDQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDQ4"
  },
  {
    "uniprot": "A0A3P7GGZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGZ7"
  },
  {
    "uniprot": "A0A3P7EJD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJD0"
  },
  {
    "uniprot": "A0A3P7EUE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUE0"
  },
  {
    "uniprot": "A0A3P7GAT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAT1"
  },
  {
    "uniprot": "J9F457",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F457"
  },
  {
    "uniprot": "A0A3P7FEC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEC6"
  },
  {
    "uniprot": "A0A3P7EQ78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQ78"
  },
  {
    "uniprot": "A0A3P7FKN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKN2"
  },
  {
    "uniprot": "A0A3P7DIA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIA9"
  },
  {
    "uniprot": "A0A3P7FAL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAL1"
  },
  {
    "uniprot": "A0A3P7DL45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL45"
  },
  {
    "uniprot": "A0A3P7DNZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNZ7"
  },
  {
    "uniprot": "J9F7J8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F7J8"
  },
  {
    "uniprot": "A0A3P7DZT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZT0"
  },
  {
    "uniprot": "A0A3P7FMP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMP2"
  },
  {
    "uniprot": "A0A3P7DIE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIE2"
  },
  {
    "uniprot": "A0A3P7GLK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLK7"
  },
  {
    "uniprot": "J9EXN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXN6"
  },
  {
    "uniprot": "A0A3P7DJG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJG5"
  },
  {
    "uniprot": "A0A3P7DY85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY85"
  },
  {
    "uniprot": "A0A3P7E057",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E057"
  },
  {
    "uniprot": "A0A3P7G335",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G335"
  },
  {
    "uniprot": "A0A3P7F9X2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9X2"
  },
  {
    "uniprot": "A0A3P7FGW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGW6"
  },
  {
    "uniprot": "A0A3P7DG12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG12"
  },
  {
    "uniprot": "A0A3P7E6A4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6A4"
  },
  {
    "uniprot": "J9END9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9END9"
  },
  {
    "uniprot": "A0A3P7EMT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMT7"
  },
  {
    "uniprot": "A0A3P7EK42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK42"
  },
  {
    "uniprot": "A0A3P7F8E6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8E6"
  },
  {
    "uniprot": "A0A3P7FVL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVL7"
  },
  {
    "uniprot": "A0A3P7DTI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTI2"
  },
  {
    "uniprot": "A0A3P7G2Z8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2Z8"
  },
  {
    "uniprot": "A0A183XMV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XMV4"
  },
  {
    "uniprot": "J9DYM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DYM9"
  },
  {
    "uniprot": "J9EL83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EL83"
  },
  {
    "uniprot": "A0A3P7DSU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSU0"
  },
  {
    "uniprot": "A0A3P7E5I7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5I7"
  },
  {
    "uniprot": "A0A3P7FVR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVR7"
  },
  {
    "uniprot": "A0A3P7EV63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EV63"
  },
  {
    "uniprot": "A0A3P7E3X4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3X4"
  },
  {
    "uniprot": "A0A3P7E774",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E774"
  },
  {
    "uniprot": "A0A3P7DS34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS34"
  },
  {
    "uniprot": "A0A3P7DF53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF53"
  },
  {
    "uniprot": "A0A3P7DXA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXA3"
  },
  {
    "uniprot": "A0A3P7EUU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUU2"
  },
  {
    "uniprot": "J9B5V5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B5V5"
  },
  {
    "uniprot": "A0A3P7EH83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH83"
  },
  {
    "uniprot": "A0A3P7ET49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET49"
  },
  {
    "uniprot": "A0A3P7E0L6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0L6"
  },
  {
    "uniprot": "A0A3P7GBK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBK9"
  },
  {
    "uniprot": "A0A3P7DPQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPQ0"
  },
  {
    "uniprot": "A0A3P7EK57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK57"
  },
  {
    "uniprot": "A0A3P7E2H7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2H7"
  },
  {
    "uniprot": "A0A3P7FLH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLH0"
  },
  {
    "uniprot": "A0A3P7EQV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQV9"
  },
  {
    "uniprot": "A0A3P7E1H2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1H2"
  },
  {
    "uniprot": "A0A3P7ER88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ER88"
  },
  {
    "uniprot": "A0A3P7EMF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMF5"
  },
  {
    "uniprot": "A0A3P7ECV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECV8"
  },
  {
    "uniprot": "A0A3P7F0T0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0T0"
  },
  {
    "uniprot": "A0A183XCZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCZ8"
  },
  {
    "uniprot": "A0A3P7G9B6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9B6"
  },
  {
    "uniprot": "A0A3P7FLN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLN7"
  },
  {
    "uniprot": "A0A3P7DPG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPG8"
  },
  {
    "uniprot": "A0A3P7FQS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQS5"
  },
  {
    "uniprot": "A0A3P7F0G2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0G2"
  },
  {
    "uniprot": "J9EL44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EL44"
  },
  {
    "uniprot": "A0A3P7DZB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZB4"
  },
  {
    "uniprot": "A0A3P7ETB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETB7"
  },
  {
    "uniprot": "A0A3P7EDC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDC3"
  },
  {
    "uniprot": "A0A3P7FZW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZW0"
  },
  {
    "uniprot": "A0A3P7FW54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW54"
  },
  {
    "uniprot": "A0A3P7FT32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT32"
  },
  {
    "uniprot": "A0A3P7DXG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXG6"
  },
  {
    "uniprot": "J9EM89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EM89"
  },
  {
    "uniprot": "A0A3P7FS81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS81"
  },
  {
    "uniprot": "A0A183Y3T1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y3T1"
  },
  {
    "uniprot": "A0A3P7DT65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT65"
  },
  {
    "uniprot": "A0A3P7FWU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWU3"
  },
  {
    "uniprot": "J9E8G7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E8G7"
  },
  {
    "uniprot": "A0A3P7DAY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAY6"
  },
  {
    "uniprot": "A0A3P7DC72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC72"
  },
  {
    "uniprot": "A0A3P7ETY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETY3"
  },
  {
    "uniprot": "A0A3P7EUN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUN3"
  },
  {
    "uniprot": "A0A3P7DU62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU62"
  },
  {
    "uniprot": "A0A3P7DQA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQA0"
  },
  {
    "uniprot": "A0A3P7DVP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVP7"
  },
  {
    "uniprot": "A0A3P7EFF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFF4"
  },
  {
    "uniprot": "A0A3P7E0X4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0X4"
  },
  {
    "uniprot": "A0A3P7FJR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJR1"
  },
  {
    "uniprot": "A0A3P7DZ67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ67"
  },
  {
    "uniprot": "A0A3P7FI95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI95"
  },
  {
    "uniprot": "A0A3P7E6L4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6L4"
  },
  {
    "uniprot": "A0A3P7FPQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPQ9"
  },
  {
    "uniprot": "A0A3P7EQD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQD4"
  },
  {
    "uniprot": "A0A3P7DQX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQX9"
  },
  {
    "uniprot": "A0A3P7DTD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTD4"
  },
  {
    "uniprot": "A0A3P7FLX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLX2"
  },
  {
    "uniprot": "A0A3P7FHM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHM4"
  },
  {
    "uniprot": "A0A3P7DTA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTA1"
  },
  {
    "uniprot": "A0A3P7EES7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EES7"
  },
  {
    "uniprot": "A0A3P7EIY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIY9"
  },
  {
    "uniprot": "A0A3P7DKZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKZ5"
  },
  {
    "uniprot": "A0A3P7FPP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPP0"
  },
  {
    "uniprot": "A0A3P7E4X5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4X5"
  },
  {
    "uniprot": "A0A3P7FLG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLG5"
  },
  {
    "uniprot": "A0A3P7E834",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E834"
  },
  {
    "uniprot": "A0A3P7GE59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GE59"
  },
  {
    "uniprot": "A0A3P7EW40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EW40"
  },
  {
    "uniprot": "A0A3P7EG01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG01"
  },
  {
    "uniprot": "A0A3P7EC62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC62"
  },
  {
    "uniprot": "A0A3P7EIY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIY1"
  },
  {
    "uniprot": "J9EVA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EVA6"
  },
  {
    "uniprot": "A0A3P7DGG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGG4"
  },
  {
    "uniprot": "A0A3P7EFY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFY4"
  },
  {
    "uniprot": "A0A3P7EGJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGJ3"
  },
  {
    "uniprot": "A0A3P7DSB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSB3"
  },
  {
    "uniprot": "A0A3P7DSV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSV2"
  },
  {
    "uniprot": "A0A3P7GCB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCB2"
  },
  {
    "uniprot": "A0A3P7DJK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJK4"
  },
  {
    "uniprot": "A0A3P7DSG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSG8"
  },
  {
    "uniprot": "A0A3P7ECT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECT9"
  },
  {
    "uniprot": "A0A3P7EKI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKI7"
  },
  {
    "uniprot": "A0A183XU80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XU80"
  },
  {
    "uniprot": "A0A3P7G051",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G051"
  },
  {
    "uniprot": "A0A3P7DZP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZP0"
  },
  {
    "uniprot": "A0A3P7DDT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDT1"
  },
  {
    "uniprot": "A0A3P7E824",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E824"
  },
  {
    "uniprot": "A0A3P7EGY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGY8"
  },
  {
    "uniprot": "A0A3P7DYC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYC7"
  },
  {
    "uniprot": "A0A3P7DW90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW90"
  },
  {
    "uniprot": "A0A3P7DAU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAU8"
  },
  {
    "uniprot": "A0A3P7DSQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSQ1"
  },
  {
    "uniprot": "A0A3P7DX77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX77"
  },
  {
    "uniprot": "A0A3P7EBX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBX1"
  },
  {
    "uniprot": "A0A3P7EV28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EV28"
  },
  {
    "uniprot": "A0A3P7FIW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIW1"
  },
  {
    "uniprot": "A0A3P7E721",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E721"
  },
  {
    "uniprot": "J9ECU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ECU4"
  },
  {
    "uniprot": "A0A3P7DU29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU29"
  },
  {
    "uniprot": "A0A3P7EU14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU14"
  },
  {
    "uniprot": "A0A3P7E4N4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4N4"
  },
  {
    "uniprot": "A0A3P7DEH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEH2"
  },
  {
    "uniprot": "A0A3P7DJF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJF0"
  },
  {
    "uniprot": "J9ENK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ENK0"
  },
  {
    "uniprot": "A0A3P7EP68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP68"
  },
  {
    "uniprot": "A0A3P7DU83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU83"
  },
  {
    "uniprot": "J9F4Y1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F4Y1"
  },
  {
    "uniprot": "A0A3P7E217",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E217"
  },
  {
    "uniprot": "A0A3P7G500",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G500"
  },
  {
    "uniprot": "A0A3P7GBN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBN6"
  },
  {
    "uniprot": "A0A3P7E2Q6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2Q6"
  },
  {
    "uniprot": "A0A3P7FCH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCH2"
  },
  {
    "uniprot": "A0A3P7DG19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG19"
  },
  {
    "uniprot": "A0A3P7DGQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGQ5"
  },
  {
    "uniprot": "A0A3P7E0J8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0J8"
  },
  {
    "uniprot": "A0A3P7FTG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTG8"
  },
  {
    "uniprot": "A0A3P7E580",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E580"
  },
  {
    "uniprot": "A0A3P7E492",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E492"
  },
  {
    "uniprot": "A0A3P7EET6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EET6"
  },
  {
    "uniprot": "A0A3P7DUZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUZ9"
  },
  {
    "uniprot": "A0A3P7E2H0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2H0"
  },
  {
    "uniprot": "A0A3P7DEF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEF1"
  },
  {
    "uniprot": "A0A3P7DTN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTN6"
  },
  {
    "uniprot": "A0A183XQH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XQH7"
  },
  {
    "uniprot": "A0A3P7EC78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC78"
  },
  {
    "uniprot": "A0A3P7EK82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK82"
  },
  {
    "uniprot": "A0A3P7DP21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP21"
  },
  {
    "uniprot": "A0A3P7FKA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKA9"
  },
  {
    "uniprot": "A0A3P7EER9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EER9"
  },
  {
    "uniprot": "A0A3P7E7I8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7I8"
  },
  {
    "uniprot": "A0A3P7DWI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWI7"
  },
  {
    "uniprot": "A0A3P7FHN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHN5"
  },
  {
    "uniprot": "A0A3P7DVB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVB4"
  },
  {
    "uniprot": "A0A3P7EGK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGK8"
  },
  {
    "uniprot": "J9BBB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BBB1"
  },
  {
    "uniprot": "A0A3P7FHG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHG6"
  },
  {
    "uniprot": "A0A3P7DYN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYN0"
  },
  {
    "uniprot": "A0A3P7DXY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXY3"
  },
  {
    "uniprot": "A0A3P7DN08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN08"
  },
  {
    "uniprot": "A0A3P7EFZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFZ6"
  },
  {
    "uniprot": "A0A3P7DTM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTM3"
  },
  {
    "uniprot": "J9E9V5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E9V5"
  },
  {
    "uniprot": "A0A3P7E8L2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8L2"
  },
  {
    "uniprot": "J9FAY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FAY2"
  },
  {
    "uniprot": "J9FHD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FHD9"
  },
  {
    "uniprot": "A0A3P7DL59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL59"
  },
  {
    "uniprot": "A0A3P7GEB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEB9"
  },
  {
    "uniprot": "A0A3P7DB10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DB10"
  },
  {
    "uniprot": "A0A3P7DKV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKV7"
  },
  {
    "uniprot": "A0A3P7DTR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTR8"
  },
  {
    "uniprot": "A0A183XMA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XMA9"
  },
  {
    "uniprot": "A0A3P7E1B3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1B3"
  },
  {
    "uniprot": "A0A3P7E9T6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9T6"
  },
  {
    "uniprot": "J9AU42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AU42"
  },
  {
    "uniprot": "A0A3P7E4X9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4X9"
  },
  {
    "uniprot": "A0A3P7DS82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS82"
  },
  {
    "uniprot": "A0A3P7FU68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU68"
  },
  {
    "uniprot": "A0A3P7EB49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB49"
  },
  {
    "uniprot": "J9BBW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BBW0"
  },
  {
    "uniprot": "A0A3P7GCH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCH1"
  },
  {
    "uniprot": "A0A3P7EHA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHA1"
  },
  {
    "uniprot": "A0A3P7F1E8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1E8"
  },
  {
    "uniprot": "A0A3P7EBE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBE5"
  },
  {
    "uniprot": "A0A3P7EC16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC16"
  },
  {
    "uniprot": "A0A3P7E0S4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0S4"
  },
  {
    "uniprot": "A0A3P7E3Y3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3Y3"
  },
  {
    "uniprot": "J9EUG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUG6"
  },
  {
    "uniprot": "A0A3P7GEF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEF0"
  },
  {
    "uniprot": "A0A3P7DRC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRC9"
  },
  {
    "uniprot": "A0A3P7FD46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD46"
  },
  {
    "uniprot": "A0A3P7EGS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGS1"
  },
  {
    "uniprot": "A0A3P7FXC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXC8"
  },
  {
    "uniprot": "A0A3P7DEN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEN6"
  },
  {
    "uniprot": "A0A3P7FA27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FA27"
  },
  {
    "uniprot": "A0A3P7DJH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJH6"
  },
  {
    "uniprot": "A0A3P7E4I2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4I2"
  },
  {
    "uniprot": "A0A3P7DTP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTP5"
  },
  {
    "uniprot": "A0A3P7DE46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE46"
  },
  {
    "uniprot": "A0A3P7GKE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKE4"
  },
  {
    "uniprot": "A0A3P7EER7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EER7"
  },
  {
    "uniprot": "J9ESE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ESE7"
  },
  {
    "uniprot": "A0A3P7DXE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXE7"
  },
  {
    "uniprot": "A0A3P7FBD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBD6"
  },
  {
    "uniprot": "A0A3P7DX99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX99"
  },
  {
    "uniprot": "A0A3P7FDZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDZ0"
  },
  {
    "uniprot": "A0A3P7GCT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCT3"
  },
  {
    "uniprot": "A0A3P7DJ51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ51"
  },
  {
    "uniprot": "A0A3P7E6N8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6N8"
  },
  {
    "uniprot": "A0A3P7DQF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQF4"
  },
  {
    "uniprot": "A0A3P7DHB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHB9"
  },
  {
    "uniprot": "A0A3P7F8I3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8I3"
  },
  {
    "uniprot": "A0A3P7DQR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQR9"
  },
  {
    "uniprot": "A0A3P7DM23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM23"
  },
  {
    "uniprot": "J9FM14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FM14"
  },
  {
    "uniprot": "A0A3P7EBV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBV2"
  },
  {
    "uniprot": "J9EY04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EY04"
  },
  {
    "uniprot": "A0A3P7DW84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW84"
  },
  {
    "uniprot": "A0A3P7E0U0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0U0"
  },
  {
    "uniprot": "A0A3P7FT03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT03"
  },
  {
    "uniprot": "A0A183XPX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XPX8"
  },
  {
    "uniprot": "A0A3P7GBB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBB9"
  },
  {
    "uniprot": "A0A3P7EMD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMD2"
  },
  {
    "uniprot": "A0A3P7GIY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIY3"
  },
  {
    "uniprot": "A0A3P7DFB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFB3"
  },
  {
    "uniprot": "A0A3P7DYP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYP0"
  },
  {
    "uniprot": "A0A3P7G1J2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1J2"
  },
  {
    "uniprot": "A0A3P7E915",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E915"
  },
  {
    "uniprot": "A0A3P7FSN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSN0"
  },
  {
    "uniprot": "A0A3P7F416",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F416"
  },
  {
    "uniprot": "A0A3P7E1E1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1E1"
  },
  {
    "uniprot": "J9EZH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZH7"
  },
  {
    "uniprot": "A0A3P7E3M4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3M4"
  },
  {
    "uniprot": "A0A183XGQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XGQ8"
  },
  {
    "uniprot": "J9F9B9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F9B9"
  },
  {
    "uniprot": "A0A3P7EIG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIG0"
  },
  {
    "uniprot": "A0A3P7FFL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFL1"
  },
  {
    "uniprot": "A0A3P7E9X8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9X8"
  },
  {
    "uniprot": "A0A3P7GLJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLJ1"
  },
  {
    "uniprot": "A0A183XT18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XT18"
  },
  {
    "uniprot": "A0A3P7F2B0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2B0"
  },
  {
    "uniprot": "A0A3P7E2Q3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2Q3"
  },
  {
    "uniprot": "A0A3P7ECM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECM3"
  },
  {
    "uniprot": "A0A3P7ELS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELS0"
  },
  {
    "uniprot": "A0A3P7DQY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQY6"
  },
  {
    "uniprot": "A0A3P7FCX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCX2"
  },
  {
    "uniprot": "A0A3P7GKL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKL2"
  },
  {
    "uniprot": "A0A3P7DM22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM22"
  },
  {
    "uniprot": "A0A3P7DDG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDG8"
  },
  {
    "uniprot": "J9E3S3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E3S3"
  },
  {
    "uniprot": "J9E8R1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E8R1"
  },
  {
    "uniprot": "A0A3P7EZ13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZ13"
  },
  {
    "uniprot": "A0A3P7ENM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENM0"
  },
  {
    "uniprot": "A0A3P7E5X9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5X9"
  },
  {
    "uniprot": "A0A3P7DPS7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPS7"
  },
  {
    "uniprot": "A0A3P7DVA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVA7"
  },
  {
    "uniprot": "A0A3P7G0E7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0E7"
  },
  {
    "uniprot": "A0A3P7DES5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DES5"
  },
  {
    "uniprot": "A0A3P7DLV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLV4"
  },
  {
    "uniprot": "J9F3C5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F3C5"
  },
  {
    "uniprot": "A0A3P7DXY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXY1"
  },
  {
    "uniprot": "A0A3P7E755",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E755"
  },
  {
    "uniprot": "J9FMR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FMR1"
  },
  {
    "uniprot": "A0A3P7G9C9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9C9"
  },
  {
    "uniprot": "A0A183XZF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XZF9"
  },
  {
    "uniprot": "A0A3P7DVL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVL2"
  },
  {
    "uniprot": "J9AZX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AZX5"
  },
  {
    "uniprot": "A0A3P7FGV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGV1"
  },
  {
    "uniprot": "A0A3P7E7J7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7J7"
  },
  {
    "uniprot": "A0A3P7G7A2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G7A2"
  },
  {
    "uniprot": "A0A3P7GKR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKR6"
  },
  {
    "uniprot": "A0A183XBW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XBW9"
  },
  {
    "uniprot": "A0A3P7DRX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRX3"
  },
  {
    "uniprot": "A0A3P7FKJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKJ5"
  },
  {
    "uniprot": "A0A3P7FTY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTY4"
  },
  {
    "uniprot": "A0A3P7DN17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN17"
  },
  {
    "uniprot": "A0A3P7E1X7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1X7"
  },
  {
    "uniprot": "A0A3P7FVT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVT3"
  },
  {
    "uniprot": "A0A3P7FCJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCJ6"
  },
  {
    "uniprot": "J9F0Y7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F0Y7"
  },
  {
    "uniprot": "A0A3P7DSS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSS8"
  },
  {
    "uniprot": "J9EUL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUL6"
  },
  {
    "uniprot": "A0A3P7GCY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCY3"
  },
  {
    "uniprot": "A0A3P7FQS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQS8"
  },
  {
    "uniprot": "A0A3P7EBS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBS5"
  },
  {
    "uniprot": "A0A3P7E2B4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2B4"
  },
  {
    "uniprot": "A0A3P7FRJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRJ4"
  },
  {
    "uniprot": "A0A3P7EJ20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ20"
  },
  {
    "uniprot": "A0A3P7G5Q2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5Q2"
  },
  {
    "uniprot": "A0A3P7FCF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCF7"
  },
  {
    "uniprot": "A0A3P7DYR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYR4"
  },
  {
    "uniprot": "A0A3P7DCP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCP7"
  },
  {
    "uniprot": "A0A3P7FA89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FA89"
  },
  {
    "uniprot": "A0A3P7EDL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDL5"
  },
  {
    "uniprot": "A0A3P7F488",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F488"
  },
  {
    "uniprot": "J9F0R4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F0R4"
  },
  {
    "uniprot": "A0A3P7G8S7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8S7"
  },
  {
    "uniprot": "A0A3P7ERM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERM7"
  },
  {
    "uniprot": "A0A3P7EFJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFJ4"
  },
  {
    "uniprot": "A0A3P7DB68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DB68"
  },
  {
    "uniprot": "A0A3P7DNZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNZ0"
  },
  {
    "uniprot": "J9DW86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DW86"
  },
  {
    "uniprot": "A0A3P7EXG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXG7"
  },
  {
    "uniprot": "J9BFG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BFG9"
  },
  {
    "uniprot": "A0A3P7FCQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCQ9"
  },
  {
    "uniprot": "A0A3P7E1J0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1J0"
  },
  {
    "uniprot": "A0A3P7EED0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EED0"
  },
  {
    "uniprot": "A0A3P7E0V4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0V4"
  },
  {
    "uniprot": "A0A3P7FBC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBC1"
  },
  {
    "uniprot": "A0A183XPQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XPQ6"
  },
  {
    "uniprot": "A0A3P7ESZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESZ7"
  },
  {
    "uniprot": "A0A3P7FIH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIH8"
  },
  {
    "uniprot": "A0A3P7E0L5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0L5"
  },
  {
    "uniprot": "A0A3P7FX11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX11"
  },
  {
    "uniprot": "A0A3P7FN28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN28"
  },
  {
    "uniprot": "A0A3P7EFU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFU2"
  },
  {
    "uniprot": "A0A3P7E744",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E744"
  },
  {
    "uniprot": "A0A3P7E449",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E449"
  },
  {
    "uniprot": "A0A3P7ECC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECC5"
  },
  {
    "uniprot": "A0A3P7DZ09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ09"
  },
  {
    "uniprot": "A0A3P7EGC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGC9"
  },
  {
    "uniprot": "A0A3P7FM32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM32"
  },
  {
    "uniprot": "A0A3P7EFR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFR9"
  },
  {
    "uniprot": "A0A3P7E8M5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8M5"
  },
  {
    "uniprot": "A0A183XI41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XI41"
  },
  {
    "uniprot": "A0A183XII2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XII2"
  },
  {
    "uniprot": "A0A3P7GD15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GD15"
  },
  {
    "uniprot": "J9EAG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EAG5"
  },
  {
    "uniprot": "A0A3P7DD36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD36"
  },
  {
    "uniprot": "A0A3P7DLF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLF4"
  },
  {
    "uniprot": "A0A3P7DY34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY34"
  },
  {
    "uniprot": "A0A183Y5P9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y5P9"
  },
  {
    "uniprot": "A0A3P7EQ71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQ71"
  },
  {
    "uniprot": "J9FC15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FC15"
  },
  {
    "uniprot": "A0A3P7F375",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F375"
  },
  {
    "uniprot": "J9FEE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FEE2"
  },
  {
    "uniprot": "J9BM39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BM39"
  },
  {
    "uniprot": "A0A3P7DD95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD95"
  },
  {
    "uniprot": "A0A3P7EHU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHU4"
  },
  {
    "uniprot": "A0A3P7EHB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHB0"
  },
  {
    "uniprot": "A0A3P7FMT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMT0"
  },
  {
    "uniprot": "A0A3P7FMT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMT5"
  },
  {
    "uniprot": "J9EUU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUU9"
  },
  {
    "uniprot": "A0A3P7F0N9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0N9"
  },
  {
    "uniprot": "A0A3P7FSM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSM4"
  },
  {
    "uniprot": "J9E8I8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E8I8"
  },
  {
    "uniprot": "J9EEQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EEQ8"
  },
  {
    "uniprot": "A0A3P7GIU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIU9"
  },
  {
    "uniprot": "J9E2G7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E2G7"
  },
  {
    "uniprot": "A0A3P7EQ40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQ40"
  },
  {
    "uniprot": "A0A3P7E8K7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8K7"
  },
  {
    "uniprot": "A0A183XZX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XZX9"
  },
  {
    "uniprot": "A0A3P7E0W7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0W7"
  },
  {
    "uniprot": "A0A3P7EL02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL02"
  },
  {
    "uniprot": "A0A3P7E9Q6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9Q6"
  },
  {
    "uniprot": "A0A3P7DXV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXV2"
  },
  {
    "uniprot": "A0A3P7DZB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZB1"
  },
  {
    "uniprot": "A0A3P7FZF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZF9"
  },
  {
    "uniprot": "A0A3P7EUA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUA8"
  },
  {
    "uniprot": "A0A3P7EZN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZN4"
  },
  {
    "uniprot": "A0A3P7GEX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEX1"
  },
  {
    "uniprot": "A0A3P7EAR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAR5"
  },
  {
    "uniprot": "A0A3P7GJU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJU2"
  },
  {
    "uniprot": "A0A3P7FPN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPN9"
  },
  {
    "uniprot": "A0A3P7G0H2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0H2"
  },
  {
    "uniprot": "A0A3P7EH03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH03"
  },
  {
    "uniprot": "A0A3P7DUL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUL5"
  },
  {
    "uniprot": "A0A3P7FQY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQY7"
  },
  {
    "uniprot": "A0A3P7FQT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQT2"
  },
  {
    "uniprot": "A0A3P7EFD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFD4"
  },
  {
    "uniprot": "A0A3P7DS87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS87"
  },
  {
    "uniprot": "A0A3P7DWV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWV2"
  },
  {
    "uniprot": "J9F3P9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F3P9"
  },
  {
    "uniprot": "A0A3P7FF43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF43"
  },
  {
    "uniprot": "A0A3P7EJ17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ17"
  },
  {
    "uniprot": "A0A3P7DQ79",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ79"
  },
  {
    "uniprot": "A0A3P7G9F4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9F4"
  },
  {
    "uniprot": "A0A3P7G834",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G834"
  },
  {
    "uniprot": "A0A3P7FSW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSW1"
  },
  {
    "uniprot": "A0A3P7ERK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERK8"
  },
  {
    "uniprot": "A0A3P7DEY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEY4"
  },
  {
    "uniprot": "A0A3P7EBJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBJ2"
  },
  {
    "uniprot": "A0A3P7DRV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRV9"
  },
  {
    "uniprot": "A0A3P7FP04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP04"
  },
  {
    "uniprot": "A0A3P7F0Q3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0Q3"
  },
  {
    "uniprot": "A0A3P7DP84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP84"
  },
  {
    "uniprot": "A0A3P7FDW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDW6"
  },
  {
    "uniprot": "A0A3P7FNW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNW6"
  },
  {
    "uniprot": "A0A3P7FVK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVK7"
  },
  {
    "uniprot": "A0A3P7DZM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZM7"
  },
  {
    "uniprot": "A0A3P7GCI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCI9"
  },
  {
    "uniprot": "A0A3P7E670",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E670"
  },
  {
    "uniprot": "J9AIK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AIK6"
  },
  {
    "uniprot": "J9EZT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZT3"
  },
  {
    "uniprot": "A0A3P7DJ93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ93"
  },
  {
    "uniprot": "A0A3P7DHM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHM0"
  },
  {
    "uniprot": "A0A3P7ERU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERU8"
  },
  {
    "uniprot": "A0A183XKW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XKW4"
  },
  {
    "uniprot": "A0A3P7DZI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZI7"
  },
  {
    "uniprot": "J9FFH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FFH1"
  },
  {
    "uniprot": "A0A3P7DLS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLS9"
  },
  {
    "uniprot": "A0A3P7DNJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNJ9"
  },
  {
    "uniprot": "A0A3P7DWZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWZ5"
  },
  {
    "uniprot": "A0A3P7EKI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKI2"
  },
  {
    "uniprot": "A0A3P7FNQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNQ1"
  },
  {
    "uniprot": "A0A3P7FVG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVG9"
  },
  {
    "uniprot": "A0A3P7DCJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCJ4"
  },
  {
    "uniprot": "A0A3P7EWN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWN3"
  },
  {
    "uniprot": "A0A3P7FSG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSG3"
  },
  {
    "uniprot": "A0A3P7FPJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPJ0"
  },
  {
    "uniprot": "A0A3P7E1C3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1C3"
  },
  {
    "uniprot": "A0A3P7DQQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQQ6"
  },
  {
    "uniprot": "A0A3P7G069",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G069"
  },
  {
    "uniprot": "A0A3P7DEM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEM8"
  },
  {
    "uniprot": "A0A3P7FYL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYL9"
  },
  {
    "uniprot": "A0A3P7DW91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW91"
  },
  {
    "uniprot": "A0A3P7EXT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXT5"
  },
  {
    "uniprot": "A0A3P7DKX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKX8"
  },
  {
    "uniprot": "A0A3P7GI94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GI94"
  },
  {
    "uniprot": "A0A3P7FF26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF26"
  },
  {
    "uniprot": "A0A3P7DYW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYW2"
  },
  {
    "uniprot": "A0A3P7FVV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVV7"
  },
  {
    "uniprot": "A0A3P7DZN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZN5"
  },
  {
    "uniprot": "A0A183XCZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCZ6"
  },
  {
    "uniprot": "A0A3P7EC11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC11"
  },
  {
    "uniprot": "A0A3P7FW66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW66"
  },
  {
    "uniprot": "A0A3P7DXX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXX3"
  },
  {
    "uniprot": "A0A3P7E6C8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6C8"
  },
  {
    "uniprot": "A0A3P7DFH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFH4"
  },
  {
    "uniprot": "A0A3P7DMG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMG7"
  },
  {
    "uniprot": "A0A3P7EG15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG15"
  },
  {
    "uniprot": "A0A3P7DEK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEK0"
  },
  {
    "uniprot": "A0A3P7E4H7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4H7"
  },
  {
    "uniprot": "A0A3P7DQ40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ40"
  },
  {
    "uniprot": "A0A3P7DTL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTL3"
  },
  {
    "uniprot": "A0A3P7DW98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW98"
  },
  {
    "uniprot": "A0A3P7GD35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GD35"
  },
  {
    "uniprot": "A0A3P7ECF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECF6"
  },
  {
    "uniprot": "A0A3P7E304",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E304"
  },
  {
    "uniprot": "A0A3P7GF78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GF78"
  },
  {
    "uniprot": "A0A3P7EYZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYZ6"
  },
  {
    "uniprot": "J9F038",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F038"
  },
  {
    "uniprot": "A0A3P7E014",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E014"
  },
  {
    "uniprot": "A0A3P7E0E2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0E2"
  },
  {
    "uniprot": "A0A3P7DL17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL17"
  },
  {
    "uniprot": "A0A183XEF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XEF4"
  },
  {
    "uniprot": "A0A3P7E870",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E870"
  },
  {
    "uniprot": "A0A3P7FIP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIP1"
  },
  {
    "uniprot": "A0A3P7GD89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GD89"
  },
  {
    "uniprot": "A0A3P7EFI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFI1"
  },
  {
    "uniprot": "J9F6T9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F6T9"
  },
  {
    "uniprot": "A0A3P7FYQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYQ5"
  },
  {
    "uniprot": "J9BH56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BH56"
  },
  {
    "uniprot": "A0A3P7FI09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI09"
  },
  {
    "uniprot": "A0A3P7EB77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB77"
  },
  {
    "uniprot": "A0A3P7EDI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDI4"
  },
  {
    "uniprot": "A0A3P7FB05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB05"
  },
  {
    "uniprot": "A0A3P7E2E8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2E8"
  },
  {
    "uniprot": "A0A3P7FJ62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ62"
  },
  {
    "uniprot": "A0A3P7EDA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDA5"
  },
  {
    "uniprot": "A0A3P7DUX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUX9"
  },
  {
    "uniprot": "A0A3P7DYR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYR0"
  },
  {
    "uniprot": "J9F028",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F028"
  },
  {
    "uniprot": "A0A3P7E0U4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0U4"
  },
  {
    "uniprot": "A0A3P7GLL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLL5"
  },
  {
    "uniprot": "A0A3P7FNA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNA3"
  },
  {
    "uniprot": "A0A3P7EDN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDN6"
  },
  {
    "uniprot": "A0A3P7GKV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKV8"
  },
  {
    "uniprot": "A0A3P7EFT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFT1"
  },
  {
    "uniprot": "A0A3P7F8W5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8W5"
  },
  {
    "uniprot": "A0A3P7EFR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFR1"
  },
  {
    "uniprot": "A0A3P7EHV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHV1"
  },
  {
    "uniprot": "A0A3P7ECC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECC4"
  },
  {
    "uniprot": "A0A3P7EF28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF28"
  },
  {
    "uniprot": "A0A3P7E6P3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6P3"
  },
  {
    "uniprot": "A0A3P7DDA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDA7"
  },
  {
    "uniprot": "A0A3P7E797",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E797"
  },
  {
    "uniprot": "A0A3P7EW91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EW91"
  },
  {
    "uniprot": "A0A3P7EFX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFX0"
  },
  {
    "uniprot": "A0A3P7E327",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E327"
  },
  {
    "uniprot": "A0A3P7EW71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EW71"
  },
  {
    "uniprot": "A0A3P7DRF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRF5"
  },
  {
    "uniprot": "A0A3P7ECI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECI2"
  },
  {
    "uniprot": "A0A3P7EGZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGZ7"
  },
  {
    "uniprot": "A0A3P7DZP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZP4"
  },
  {
    "uniprot": "A0A3P7GN17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GN17"
  },
  {
    "uniprot": "A0A3P7EKU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKU5"
  },
  {
    "uniprot": "A0A3P7DQ64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ64"
  },
  {
    "uniprot": "A0A3P7GBY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBY9"
  },
  {
    "uniprot": "A0A3P7EEK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEK0"
  },
  {
    "uniprot": "A0A3P7DLL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLL1"
  },
  {
    "uniprot": "A0A3P7G412",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G412"
  },
  {
    "uniprot": "A0A3P7DPT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPT6"
  },
  {
    "uniprot": "A0A3P7DWM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWM4"
  },
  {
    "uniprot": "A0A3P7FBY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBY0"
  },
  {
    "uniprot": "J9EY35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EY35"
  },
  {
    "uniprot": "A0A3P7ECT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECT0"
  },
  {
    "uniprot": "A0A3P7DQK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQK5"
  },
  {
    "uniprot": "A0A3P7DRI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRI4"
  },
  {
    "uniprot": "A0A3P7E5W5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5W5"
  },
  {
    "uniprot": "A0A3P7FR45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR45"
  },
  {
    "uniprot": "A0A3P7EK43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK43"
  },
  {
    "uniprot": "A0A3P7GBM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBM6"
  },
  {
    "uniprot": "A0A3P7DGX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGX1"
  },
  {
    "uniprot": "A0A3P7E7G7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7G7"
  },
  {
    "uniprot": "A0A3P7DKY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKY5"
  },
  {
    "uniprot": "A0A3P7DMH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMH2"
  },
  {
    "uniprot": "A0A3P7EUG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUG2"
  },
  {
    "uniprot": "J9ER57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ER57"
  },
  {
    "uniprot": "A0A3P7EFZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFZ8"
  },
  {
    "uniprot": "J9F0J0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F0J0"
  },
  {
    "uniprot": "J9FMT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FMT5"
  },
  {
    "uniprot": "A0A3P7FVR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVR2"
  },
  {
    "uniprot": "A0A3P7EBU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBU5"
  },
  {
    "uniprot": "A0A3P7DT91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT91"
  },
  {
    "uniprot": "A0A3P7G996",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G996"
  },
  {
    "uniprot": "A0A3P7DD42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD42"
  },
  {
    "uniprot": "J9E452",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E452"
  },
  {
    "uniprot": "A0A3P7DVX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVX5"
  },
  {
    "uniprot": "J9EVD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EVD2"
  },
  {
    "uniprot": "A0A3P7GC19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GC19"
  },
  {
    "uniprot": "A0A3P7FI01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI01"
  },
  {
    "uniprot": "A0A3P7E9B8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9B8"
  },
  {
    "uniprot": "A0A3P7DZF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZF8"
  },
  {
    "uniprot": "A0A3P7EB41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB41"
  },
  {
    "uniprot": "A0A3P7DT86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT86"
  },
  {
    "uniprot": "A0A3P7EAN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAN2"
  },
  {
    "uniprot": "A0A3P7E748",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E748"
  },
  {
    "uniprot": "A0A3P7EWE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWE1"
  },
  {
    "uniprot": "A0A3P7EYL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYL5"
  },
  {
    "uniprot": "A0A3P7EKZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKZ0"
  },
  {
    "uniprot": "A0A3P7DRL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRL9"
  },
  {
    "uniprot": "J9EL40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EL40"
  },
  {
    "uniprot": "A0A3P7E1S8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1S8"
  },
  {
    "uniprot": "A0A3P7DVZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVZ3"
  },
  {
    "uniprot": "A0A3P7E8T8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8T8"
  },
  {
    "uniprot": "A0A3P7EVT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVT8"
  },
  {
    "uniprot": "A0A3P7G1U7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1U7"
  },
  {
    "uniprot": "A0A3P7GI56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GI56"
  },
  {
    "uniprot": "A0A3P7FVK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVK9"
  },
  {
    "uniprot": "A0A3P7GKQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKQ9"
  },
  {
    "uniprot": "A0A3P7DN72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN72"
  },
  {
    "uniprot": "A0A3P7E6U9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6U9"
  },
  {
    "uniprot": "A0A3P7GCC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCC2"
  },
  {
    "uniprot": "A0A3P7E959",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E959"
  },
  {
    "uniprot": "A0A3P7DQ99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ99"
  },
  {
    "uniprot": "A0A3P7EIT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIT3"
  },
  {
    "uniprot": "A0A3P7DRX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRX7"
  },
  {
    "uniprot": "A0A3P7DAX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAX8"
  },
  {
    "uniprot": "J9EUI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUI5"
  },
  {
    "uniprot": "A0A3P7ED81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED81"
  },
  {
    "uniprot": "A0A3P7EFL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFL5"
  },
  {
    "uniprot": "A0A3P7F1Z4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1Z4"
  },
  {
    "uniprot": "A0A3P7EGA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGA5"
  },
  {
    "uniprot": "A0A3P7E0D6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0D6"
  },
  {
    "uniprot": "A0A3P7DX05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX05"
  },
  {
    "uniprot": "J9EUP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUP6"
  },
  {
    "uniprot": "A0A3P7EE00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE00"
  },
  {
    "uniprot": "A0A3P7GDR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDR8"
  },
  {
    "uniprot": "A0A3P7GCP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCP8"
  },
  {
    "uniprot": "A0A3P7FFI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFI5"
  },
  {
    "uniprot": "A0A3P7DM33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM33"
  },
  {
    "uniprot": "A0A3P7EN70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN70"
  },
  {
    "uniprot": "J9E269",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E269"
  },
  {
    "uniprot": "A0A3P7FCZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCZ2"
  },
  {
    "uniprot": "A0A3P7ECR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECR2"
  },
  {
    "uniprot": "A0A3P7E395",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E395"
  },
  {
    "uniprot": "A0A3P7EAC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAC9"
  },
  {
    "uniprot": "A0A3P7DPV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPV1"
  },
  {
    "uniprot": "A0A3P7FG60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG60"
  },
  {
    "uniprot": "A0A3P7EM13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM13"
  },
  {
    "uniprot": "A0A3P7ENB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENB8"
  },
  {
    "uniprot": "A0A3P7FL86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL86"
  },
  {
    "uniprot": "A0A3P7FFL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFL5"
  },
  {
    "uniprot": "A0A3P7FWU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWU9"
  },
  {
    "uniprot": "J9BGI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BGI2"
  },
  {
    "uniprot": "A0A3P7E9B4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9B4"
  },
  {
    "uniprot": "A0A3P7DX10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX10"
  },
  {
    "uniprot": "A0A3P7DK08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK08"
  },
  {
    "uniprot": "A0A3P7GLR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLR4"
  },
  {
    "uniprot": "A0A3P7GM99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GM99"
  },
  {
    "uniprot": "A0A183XNW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XNW3"
  },
  {
    "uniprot": "A0A3P7DU81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU81"
  },
  {
    "uniprot": "A0A3P7E0W0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0W0"
  },
  {
    "uniprot": "A0A3P7EXT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXT1"
  },
  {
    "uniprot": "A0A3P7EIE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIE7"
  },
  {
    "uniprot": "A0A183XV24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XV24"
  },
  {
    "uniprot": "A0A3P7FTG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTG9"
  },
  {
    "uniprot": "A0A3P7FI49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI49"
  },
  {
    "uniprot": "A0A3P7E9V8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9V8"
  },
  {
    "uniprot": "A0A3P7DZR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZR8"
  },
  {
    "uniprot": "A0A3P7GKX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKX3"
  },
  {
    "uniprot": "A0A3P7FRW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRW3"
  },
  {
    "uniprot": "A0A3P7DPY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPY7"
  },
  {
    "uniprot": "A0A3P7DJP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJP8"
  },
  {
    "uniprot": "A0A3P7EXH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXH9"
  },
  {
    "uniprot": "J9BIP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BIP2"
  },
  {
    "uniprot": "A0A3P7GJY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJY6"
  },
  {
    "uniprot": "A0A3P7E5A7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5A7"
  },
  {
    "uniprot": "A0A3P7FJP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJP4"
  },
  {
    "uniprot": "A0A3P7E2G1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2G1"
  },
  {
    "uniprot": "A0A3P7E0X0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0X0"
  },
  {
    "uniprot": "A0A3P7ECE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECE5"
  },
  {
    "uniprot": "A0A3P7DQW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQW5"
  },
  {
    "uniprot": "A0A3P7FQR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQR5"
  },
  {
    "uniprot": "A0A3P7DPD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPD9"
  },
  {
    "uniprot": "A0A3P7DRI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRI3"
  },
  {
    "uniprot": "A0A3P7FH47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH47"
  },
  {
    "uniprot": "A0A3P7E715",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E715"
  },
  {
    "uniprot": "A0A3P7ENA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENA8"
  },
  {
    "uniprot": "A0A3P7ER89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ER89"
  },
  {
    "uniprot": "A0A3P7EFE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFE5"
  },
  {
    "uniprot": "A0A3P7DMD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMD5"
  },
  {
    "uniprot": "A0A3P7EAE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAE5"
  },
  {
    "uniprot": "J9F8A4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F8A4"
  },
  {
    "uniprot": "A0A3P7FCI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCI2"
  },
  {
    "uniprot": "A0A3P7DVF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVF8"
  },
  {
    "uniprot": "A0A3P7ETL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETL6"
  },
  {
    "uniprot": "A0A3P7ETY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETY0"
  },
  {
    "uniprot": "A0A3P7FEJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEJ5"
  },
  {
    "uniprot": "A0A3P7G1Q0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1Q0"
  },
  {
    "uniprot": "A0A3P7FR70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR70"
  },
  {
    "uniprot": "A0A3P7E1F4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1F4"
  },
  {
    "uniprot": "J9FBW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FBW7"
  },
  {
    "uniprot": "J9ESV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ESV3"
  },
  {
    "uniprot": "A0A3P7E7Y1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7Y1"
  },
  {
    "uniprot": "A0A3P7FG37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG37"
  },
  {
    "uniprot": "A0A3P7DGI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGI2"
  },
  {
    "uniprot": "A0A3P7DHW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHW1"
  },
  {
    "uniprot": "A0A183XFK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XFK1"
  },
  {
    "uniprot": "J9F4G2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F4G2"
  },
  {
    "uniprot": "A0A3P7DIJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIJ0"
  },
  {
    "uniprot": "A0A3P7FME7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FME7"
  },
  {
    "uniprot": "A0A3P7ETM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETM8"
  },
  {
    "uniprot": "A0A3P7DNA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNA8"
  },
  {
    "uniprot": "A0A3P7ETM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETM6"
  },
  {
    "uniprot": "A0A3P7EFU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFU1"
  },
  {
    "uniprot": "A0A3P7EPQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPQ0"
  },
  {
    "uniprot": "A0A3P7FI57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI57"
  },
  {
    "uniprot": "A0A183XNR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XNR2"
  },
  {
    "uniprot": "A0A3P7ELE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELE4"
  },
  {
    "uniprot": "A0A3P7G9S2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9S2"
  },
  {
    "uniprot": "J9BK31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BK31"
  },
  {
    "uniprot": "A0A3P7E4B3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4B3"
  },
  {
    "uniprot": "J9AD51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AD51"
  },
  {
    "uniprot": "A0A3P7DPB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPB2"
  },
  {
    "uniprot": "A0A3P7DQM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQM8"
  },
  {
    "uniprot": "A0A3P7E4H1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4H1"
  },
  {
    "uniprot": "A0A3P7GGF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGF4"
  },
  {
    "uniprot": "A0A3P7DY02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY02"
  },
  {
    "uniprot": "A0A3P7GLW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLW8"
  },
  {
    "uniprot": "A0A3P7DJY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJY8"
  },
  {
    "uniprot": "A0A3P7EQZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQZ0"
  },
  {
    "uniprot": "A0A3P7GN10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GN10"
  },
  {
    "uniprot": "A0A3P7FQ26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ26"
  },
  {
    "uniprot": "A0A183XRI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XRI8"
  },
  {
    "uniprot": "A0A3P7FUP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUP3"
  },
  {
    "uniprot": "A0A3P7DSD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSD7"
  },
  {
    "uniprot": "A0A3P7EF80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF80"
  },
  {
    "uniprot": "A0A3P7FDS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDS5"
  },
  {
    "uniprot": "A0A3P7DFB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFB5"
  },
  {
    "uniprot": "A0A3P7DNF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNF8"
  },
  {
    "uniprot": "A0A3P7DCL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCL5"
  },
  {
    "uniprot": "A0A3P7DC65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC65"
  },
  {
    "uniprot": "A0A3P7FSJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSJ8"
  },
  {
    "uniprot": "A0A3P7FU70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU70"
  },
  {
    "uniprot": "A0A3P7DR08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR08"
  },
  {
    "uniprot": "A0A3P7E7F4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7F4"
  },
  {
    "uniprot": "A0A3P7G9B8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9B8"
  },
  {
    "uniprot": "A0A3P7DAR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAR2"
  },
  {
    "uniprot": "A0A3P7EY72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY72"
  },
  {
    "uniprot": "J9AUU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AUU4"
  },
  {
    "uniprot": "A0A3P7EB66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB66"
  },
  {
    "uniprot": "J9ERX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERX5"
  },
  {
    "uniprot": "A0A3P7GKA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKA8"
  },
  {
    "uniprot": "A0A3P7EET9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EET9"
  },
  {
    "uniprot": "A0A3P7EUR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUR0"
  },
  {
    "uniprot": "A0A3P7GN86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GN86"
  },
  {
    "uniprot": "A0A3P7ENX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENX0"
  },
  {
    "uniprot": "A0A3P7DQP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQP5"
  },
  {
    "uniprot": "A0A3P7E9T7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9T7"
  },
  {
    "uniprot": "A0A3P7EA21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA21"
  },
  {
    "uniprot": "J9AYP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AYP8"
  },
  {
    "uniprot": "A0A3P7DXH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXH2"
  },
  {
    "uniprot": "A0A3P7E574",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E574"
  },
  {
    "uniprot": "A0A3P7DIP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIP2"
  },
  {
    "uniprot": "A0A3P7EJH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJH3"
  },
  {
    "uniprot": "A0A3P7FKM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKM3"
  },
  {
    "uniprot": "A0A3P7DZ03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ03"
  },
  {
    "uniprot": "A0A3P7DUJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUJ1"
  },
  {
    "uniprot": "A0A3P7G191",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G191"
  },
  {
    "uniprot": "A0A3P7DNI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNI1"
  },
  {
    "uniprot": "A0A3P7DPP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPP0"
  },
  {
    "uniprot": "A0A3P7EXB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXB5"
  },
  {
    "uniprot": "A0A3P7DV44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV44"
  },
  {
    "uniprot": "J9ALI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ALI2"
  },
  {
    "uniprot": "A0A3P7E0A4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0A4"
  },
  {
    "uniprot": "A0A3P7F3C9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3C9"
  },
  {
    "uniprot": "A0A3P7E839",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E839"
  },
  {
    "uniprot": "A0A3P7EBN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBN7"
  },
  {
    "uniprot": "A0A3P7DP60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP60"
  },
  {
    "uniprot": "A0A3P7FLF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLF4"
  },
  {
    "uniprot": "A0A3P7FQZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQZ9"
  },
  {
    "uniprot": "A0A3P7F439",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F439"
  },
  {
    "uniprot": "A0A3P7FD66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD66"
  },
  {
    "uniprot": "A0A3P7GFF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFF9"
  },
  {
    "uniprot": "J9EMF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EMF5"
  },
  {
    "uniprot": "A0A3P7F9R5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9R5"
  },
  {
    "uniprot": "J9F1K0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1K0"
  },
  {
    "uniprot": "A0A3P7DY45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY45"
  },
  {
    "uniprot": "A0A3P7E5S2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5S2"
  },
  {
    "uniprot": "A0A3P7E5K4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5K4"
  },
  {
    "uniprot": "A0A3P7FML6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FML6"
  },
  {
    "uniprot": "A0A3P7FLL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLL9"
  },
  {
    "uniprot": "A0A3P7FMC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMC1"
  },
  {
    "uniprot": "A0A183Y7I6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y7I6"
  },
  {
    "uniprot": "A0A3P7FEQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEQ3"
  },
  {
    "uniprot": "A0A3P7E6N2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6N2"
  },
  {
    "uniprot": "A0A183Y2P4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y2P4"
  },
  {
    "uniprot": "A0A3P7E654",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E654"
  },
  {
    "uniprot": "A0A3P7E094",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E094"
  },
  {
    "uniprot": "A0A3P7EA49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA49"
  },
  {
    "uniprot": "A0A3P7EFY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFY8"
  },
  {
    "uniprot": "J9FEX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FEX1"
  },
  {
    "uniprot": "A0A3P7EG26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG26"
  },
  {
    "uniprot": "A0A3P7FU77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU77"
  },
  {
    "uniprot": "A0A3P7ECE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECE3"
  },
  {
    "uniprot": "A0A3P7DQD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQD7"
  },
  {
    "uniprot": "A0A183XVZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XVZ3"
  },
  {
    "uniprot": "A0A3P7E537",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E537"
  },
  {
    "uniprot": "A0A3P7F1I6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1I6"
  },
  {
    "uniprot": "A0A3P7EEM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEM5"
  },
  {
    "uniprot": "A0A3P7DZJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZJ9"
  },
  {
    "uniprot": "A0A3P7DTE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTE2"
  },
  {
    "uniprot": "A0A3P7E6G7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6G7"
  },
  {
    "uniprot": "A0A3P7GG40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GG40"
  },
  {
    "uniprot": "A0A3P7E097",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E097"
  },
  {
    "uniprot": "A0A3P7EBH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBH0"
  },
  {
    "uniprot": "A0A3P7FS13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS13"
  },
  {
    "uniprot": "A0A3P7FIE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIE0"
  },
  {
    "uniprot": "A0A3P7DX48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX48"
  },
  {
    "uniprot": "A0A3P7FGI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGI8"
  },
  {
    "uniprot": "J9BLC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BLC6"
  },
  {
    "uniprot": "A0A3P7G640",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G640"
  },
  {
    "uniprot": "A0A3P7DI57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI57"
  },
  {
    "uniprot": "J9E5K1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E5K1"
  },
  {
    "uniprot": "A0A3P7E114",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E114"
  },
  {
    "uniprot": "A0A3P7FGY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGY3"
  },
  {
    "uniprot": "A0A3P7EF88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF88"
  },
  {
    "uniprot": "J9B5X6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B5X6"
  },
  {
    "uniprot": "A0A183XCN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCN3"
  },
  {
    "uniprot": "A0A3P7DTH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTH1"
  },
  {
    "uniprot": "A0A3P7F3D3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3D3"
  },
  {
    "uniprot": "A0A3P7FVE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVE2"
  },
  {
    "uniprot": "A0A3P7DUS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUS5"
  },
  {
    "uniprot": "A0A3P7FWL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWL1"
  },
  {
    "uniprot": "A0A3P7DZG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZG0"
  },
  {
    "uniprot": "A0A3P7DRJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRJ2"
  },
  {
    "uniprot": "A0A3P7EQB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQB5"
  },
  {
    "uniprot": "A0A3P7DFX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFX0"
  },
  {
    "uniprot": "A0A3P7EMI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMI1"
  },
  {
    "uniprot": "A0A3P7FGH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGH9"
  },
  {
    "uniprot": "A0A3P7GL89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GL89"
  },
  {
    "uniprot": "A0A3P7F1J6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1J6"
  },
  {
    "uniprot": "A0A3P7DYF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYF8"
  },
  {
    "uniprot": "A0A3P7E6W4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6W4"
  },
  {
    "uniprot": "A0A3P7DVG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVG0"
  },
  {
    "uniprot": "A0A3P7FWB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWB0"
  },
  {
    "uniprot": "A0A3P7DSC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSC7"
  },
  {
    "uniprot": "A0A3P7GFZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFZ2"
  },
  {
    "uniprot": "J9EDG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EDG7"
  },
  {
    "uniprot": "A0A3P7EZ06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZ06"
  },
  {
    "uniprot": "J9BKY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BKY8"
  },
  {
    "uniprot": "A0A3P7E9I9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9I9"
  },
  {
    "uniprot": "A0A3P7DWZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWZ1"
  },
  {
    "uniprot": "A0A3P7FK33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK33"
  },
  {
    "uniprot": "A0A3P7DN43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN43"
  },
  {
    "uniprot": "A0A3P7ECB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECB6"
  },
  {
    "uniprot": "A0A3P7FG59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG59"
  },
  {
    "uniprot": "A0A3P7E6U7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6U7"
  },
  {
    "uniprot": "A0A3P7GEG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEG9"
  },
  {
    "uniprot": "A0A3P7EQ95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQ95"
  },
  {
    "uniprot": "A0A3P7EK49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK49"
  },
  {
    "uniprot": "A0A3P7EEG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEG3"
  },
  {
    "uniprot": "A0A3P7EK90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK90"
  },
  {
    "uniprot": "J9EWG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EWG7"
  },
  {
    "uniprot": "A0A3P7FQJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQJ7"
  },
  {
    "uniprot": "A0A3P7GID4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GID4"
  },
  {
    "uniprot": "A0A3P7FAC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAC8"
  },
  {
    "uniprot": "A0A3P7FM65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM65"
  },
  {
    "uniprot": "A0A3P7FRS7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRS7"
  },
  {
    "uniprot": "A0A3P7DM13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM13"
  },
  {
    "uniprot": "A0A3P7DB44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DB44"
  },
  {
    "uniprot": "J9EJ17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EJ17"
  },
  {
    "uniprot": "J9B759",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B759"
  },
  {
    "uniprot": "A0A3P7FR57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR57"
  },
  {
    "uniprot": "A0A3P7GJB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJB8"
  },
  {
    "uniprot": "J9B5E7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B5E7"
  },
  {
    "uniprot": "J9B6E1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B6E1"
  },
  {
    "uniprot": "A0A3P7FYT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYT0"
  },
  {
    "uniprot": "A0A183XE43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XE43"
  },
  {
    "uniprot": "A0A3P7E9K3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9K3"
  },
  {
    "uniprot": "A0A3P7DK89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK89"
  },
  {
    "uniprot": "A0A3P7DLQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLQ7"
  },
  {
    "uniprot": "A0A3P7E794",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E794"
  },
  {
    "uniprot": "A0A3P7E5G4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5G4"
  },
  {
    "uniprot": "A0A3P7G0F0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0F0"
  },
  {
    "uniprot": "A0A3P7DFS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFS2"
  },
  {
    "uniprot": "A0A3P7EYF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYF5"
  },
  {
    "uniprot": "J9FEJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FEJ6"
  },
  {
    "uniprot": "A0A183XUQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XUQ2"
  },
  {
    "uniprot": "A0A3P7DLL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLL6"
  },
  {
    "uniprot": "A0A3P7DVR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVR7"
  },
  {
    "uniprot": "A0A3P7FTZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTZ3"
  },
  {
    "uniprot": "J9EFC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EFC7"
  },
  {
    "uniprot": "A0A3P7FLQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLQ7"
  },
  {
    "uniprot": "A0A3P7GI05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GI05"
  },
  {
    "uniprot": "A0A3P7FCL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCL3"
  },
  {
    "uniprot": "A0A3P7ETN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETN1"
  },
  {
    "uniprot": "A0A3P7E4L2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4L2"
  },
  {
    "uniprot": "A0A3P7DE41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE41"
  },
  {
    "uniprot": "A0A3P7E3P6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3P6"
  },
  {
    "uniprot": "A0A3P7E9P4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9P4"
  },
  {
    "uniprot": "A0A3P7EH28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH28"
  },
  {
    "uniprot": "A0A3P7DKT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKT0"
  },
  {
    "uniprot": "J9EPK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPK4"
  },
  {
    "uniprot": "A0A3P7E0T5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0T5"
  },
  {
    "uniprot": "A0A3P7FPT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPT6"
  },
  {
    "uniprot": "A0A3P7DVX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVX2"
  },
  {
    "uniprot": "J9B0M1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B0M1"
  },
  {
    "uniprot": "A0A3P7E657",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E657"
  },
  {
    "uniprot": "A0A3P7ESW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESW2"
  },
  {
    "uniprot": "A0A3P7DZH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZH6"
  },
  {
    "uniprot": "A0A3P7FC20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC20"
  },
  {
    "uniprot": "A0A3P7E445",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E445"
  },
  {
    "uniprot": "A0A3P7EFV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFV7"
  },
  {
    "uniprot": "A0A3P7FQN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQN8"
  },
  {
    "uniprot": "A0A3P7EI88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI88"
  },
  {
    "uniprot": "A0A3P7EES6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EES6"
  },
  {
    "uniprot": "A0A3P7GLP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLP8"
  },
  {
    "uniprot": "A0A3P7E1W5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1W5"
  },
  {
    "uniprot": "A0A3P7ENE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENE2"
  },
  {
    "uniprot": "A0A3P7E842",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E842"
  },
  {
    "uniprot": "A0A3P7EKN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKN1"
  },
  {
    "uniprot": "A0A3P7DLS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLS1"
  },
  {
    "uniprot": "A0A3P7FSR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSR4"
  },
  {
    "uniprot": "A0A3P7DXJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXJ5"
  },
  {
    "uniprot": "A0A3P7DZ50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ50"
  },
  {
    "uniprot": "A0A3P7FPN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPN6"
  },
  {
    "uniprot": "A0A3P7FTS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTS3"
  },
  {
    "uniprot": "A0A3P7FTM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTM7"
  },
  {
    "uniprot": "A0A3P7EKL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKL8"
  },
  {
    "uniprot": "A0A3P7FMW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMW7"
  },
  {
    "uniprot": "A0A3P7EKY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKY9"
  },
  {
    "uniprot": "A0A3P7EEA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEA5"
  },
  {
    "uniprot": "A0A3P7FIU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIU9"
  },
  {
    "uniprot": "A0A3P7EY08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY08"
  },
  {
    "uniprot": "A0A3P7F063",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F063"
  },
  {
    "uniprot": "A0A3P7F4N4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F4N4"
  },
  {
    "uniprot": "A0A3P7DE91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE91"
  },
  {
    "uniprot": "A0A3P7EMM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMM1"
  },
  {
    "uniprot": "A0A3P7EEC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEC5"
  },
  {
    "uniprot": "A0A3P7DZS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZS5"
  },
  {
    "uniprot": "A0A3P7DJT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJT6"
  },
  {
    "uniprot": "A0A3P7DRB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRB1"
  },
  {
    "uniprot": "A0A3P7DEL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEL0"
  },
  {
    "uniprot": "A0A3P7E257",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E257"
  },
  {
    "uniprot": "J9F745",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F745"
  },
  {
    "uniprot": "A0A3P7EH04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH04"
  },
  {
    "uniprot": "J9F6V4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F6V4"
  },
  {
    "uniprot": "A0A183XMJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XMJ8"
  },
  {
    "uniprot": "A0A3P7D9M3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7D9M3"
  },
  {
    "uniprot": "A0A3P7E242",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E242"
  },
  {
    "uniprot": "A0A3P7E8T5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8T5"
  },
  {
    "uniprot": "J9EJQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EJQ7"
  },
  {
    "uniprot": "A0A3P7F7C6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7C6"
  },
  {
    "uniprot": "A0A3P7E7E5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7E5"
  },
  {
    "uniprot": "J9AQS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AQS9"
  },
  {
    "uniprot": "A0A3P7FQR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQR6"
  },
  {
    "uniprot": "A0A3P7EGR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGR3"
  },
  {
    "uniprot": "J9B8E4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B8E4"
  },
  {
    "uniprot": "A0A3P7EB39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB39"
  },
  {
    "uniprot": "A0A3P7ES46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ES46"
  },
  {
    "uniprot": "A0A3P7EDK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDK6"
  },
  {
    "uniprot": "J9F386",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F386"
  },
  {
    "uniprot": "A0A3P7DPY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPY4"
  },
  {
    "uniprot": "A0A3P7DT59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT59"
  },
  {
    "uniprot": "A0A3P7E4N7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4N7"
  },
  {
    "uniprot": "A0A3P7DPD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPD7"
  },
  {
    "uniprot": "J9EN82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EN82"
  },
  {
    "uniprot": "A0A3P7DYG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYG7"
  },
  {
    "uniprot": "A0A3P7DZK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZK3"
  },
  {
    "uniprot": "A0A3P7F8E9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8E9"
  },
  {
    "uniprot": "J9ERM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERM8"
  },
  {
    "uniprot": "A0A3P7DJ31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ31"
  },
  {
    "uniprot": "A0A3P7DSG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSG9"
  },
  {
    "uniprot": "A0A3P7E0F9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0F9"
  },
  {
    "uniprot": "A0A3P7EIF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIF1"
  },
  {
    "uniprot": "A0A183Y066",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y066"
  },
  {
    "uniprot": "A0A3P7EVX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVX5"
  },
  {
    "uniprot": "A0A3P7EPS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPS5"
  },
  {
    "uniprot": "A0A3P7DMA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMA5"
  },
  {
    "uniprot": "A0A3P7FJY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJY0"
  },
  {
    "uniprot": "A0A3P7EZD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZD0"
  },
  {
    "uniprot": "A0A3P7DNV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNV0"
  },
  {
    "uniprot": "A0A3P7FBE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBE3"
  },
  {
    "uniprot": "A0A3P7DCA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCA3"
  },
  {
    "uniprot": "A0A3P7E0K8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0K8"
  },
  {
    "uniprot": "A0A3P7FE24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE24"
  },
  {
    "uniprot": "A0A3P7EXA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXA7"
  },
  {
    "uniprot": "A0A3P7DJM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJM9"
  },
  {
    "uniprot": "A0A3P7ECK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECK1"
  },
  {
    "uniprot": "J9E8H5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E8H5"
  },
  {
    "uniprot": "A0A3P7E2G9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2G9"
  },
  {
    "uniprot": "A0A3P7EFS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFS6"
  },
  {
    "uniprot": "A0A3P7E514",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E514"
  },
  {
    "uniprot": "A0A3P7DZ46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ46"
  },
  {
    "uniprot": "A0A3P7DBT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBT0"
  },
  {
    "uniprot": "A0A3P7DUJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUJ8"
  },
  {
    "uniprot": "A0A3P7GHG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHG2"
  },
  {
    "uniprot": "A0A3P7EAN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAN4"
  },
  {
    "uniprot": "J9EQT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQT1"
  },
  {
    "uniprot": "A0A3P7FUL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUL1"
  },
  {
    "uniprot": "A0A3P7EL80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL80"
  },
  {
    "uniprot": "A0A3P7EF64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF64"
  },
  {
    "uniprot": "A0A3P7GBU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBU3"
  },
  {
    "uniprot": "A0A3P7FFV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFV0"
  },
  {
    "uniprot": "A0A3P7G7W0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G7W0"
  },
  {
    "uniprot": "A0A3P7EDY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDY2"
  },
  {
    "uniprot": "A0A3P7E5Q8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5Q8"
  },
  {
    "uniprot": "A0A3P7DVQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVQ5"
  },
  {
    "uniprot": "A0A3P7DZV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZV8"
  },
  {
    "uniprot": "A0A3P7G342",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G342"
  },
  {
    "uniprot": "A0A3P7EFP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFP9"
  },
  {
    "uniprot": "A0A3P7E9N4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9N4"
  },
  {
    "uniprot": "J9FAI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FAI5"
  },
  {
    "uniprot": "A0A3P7DUC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUC6"
  },
  {
    "uniprot": "A0A3P7EUB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUB5"
  },
  {
    "uniprot": "J9F5U9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F5U9"
  },
  {
    "uniprot": "A0A3P7DYL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYL9"
  },
  {
    "uniprot": "A0A3P7DNG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNG6"
  },
  {
    "uniprot": "A0A3P7DAC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAC7"
  },
  {
    "uniprot": "A0A3P7DSN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSN5"
  },
  {
    "uniprot": "A0A3P7E606",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E606"
  },
  {
    "uniprot": "A0A3P7G4C1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4C1"
  },
  {
    "uniprot": "A0A3P7EH60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH60"
  },
  {
    "uniprot": "A0A3P7FTT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTT2"
  },
  {
    "uniprot": "A0A3P7FBM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBM7"
  },
  {
    "uniprot": "A0A3P7DU75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU75"
  },
  {
    "uniprot": "A0A3P7G4U4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4U4"
  },
  {
    "uniprot": "A0A3P7G0Q3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0Q3"
  },
  {
    "uniprot": "A0A3P7ECA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECA0"
  },
  {
    "uniprot": "A0A3P7GKI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKI2"
  },
  {
    "uniprot": "A0A3P7DIT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIT6"
  },
  {
    "uniprot": "A0A183XQF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XQF0"
  },
  {
    "uniprot": "A0A3P7FN87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN87"
  },
  {
    "uniprot": "A0A183XC89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XC89"
  },
  {
    "uniprot": "J9F0Y8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F0Y8"
  },
  {
    "uniprot": "A0A3P7DRH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRH5"
  },
  {
    "uniprot": "A0A3P7EZP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZP8"
  },
  {
    "uniprot": "A0A3P7FE58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE58"
  },
  {
    "uniprot": "A0A3P7DNC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNC9"
  },
  {
    "uniprot": "A0A3P7F0F6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0F6"
  },
  {
    "uniprot": "A0A3P7FES5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FES5"
  },
  {
    "uniprot": "A0A3P7DG72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG72"
  },
  {
    "uniprot": "A0A3P7DTE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTE9"
  },
  {
    "uniprot": "A0A3P7GH23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GH23"
  },
  {
    "uniprot": "A0A183XHK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XHK8"
  },
  {
    "uniprot": "A0A3P7DSW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSW8"
  },
  {
    "uniprot": "A0A183XHD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XHD6"
  },
  {
    "uniprot": "A0A3P7DBR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBR6"
  },
  {
    "uniprot": "A0A3P7DV42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV42"
  },
  {
    "uniprot": "A0A3P7GA32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GA32"
  },
  {
    "uniprot": "J9ENN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ENN9"
  },
  {
    "uniprot": "A0A3P7FG00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG00"
  },
  {
    "uniprot": "A0A3P7EFL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFL8"
  },
  {
    "uniprot": "J9EFX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EFX1"
  },
  {
    "uniprot": "A0A3P7GFT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFT6"
  },
  {
    "uniprot": "A0A183XRM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XRM0"
  },
  {
    "uniprot": "A0A3P7EEE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEE3"
  },
  {
    "uniprot": "A0A3P7E2I1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2I1"
  },
  {
    "uniprot": "A0A3P7DZS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZS8"
  },
  {
    "uniprot": "A0A3P7DNQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNQ9"
  },
  {
    "uniprot": "J9ESE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ESE9"
  },
  {
    "uniprot": "A0A183Y3M2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y3M2"
  },
  {
    "uniprot": "A0A3P7FFH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFH2"
  },
  {
    "uniprot": "A0A3P7DH30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH30"
  },
  {
    "uniprot": "A0A3P7E5J4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5J4"
  },
  {
    "uniprot": "J9FJ05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FJ05"
  },
  {
    "uniprot": "A0A3P7DL80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL80"
  },
  {
    "uniprot": "A0A3P7DVV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVV5"
  },
  {
    "uniprot": "A0A3P7G1K7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1K7"
  },
  {
    "uniprot": "A0A3P7DTP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTP6"
  },
  {
    "uniprot": "A0A3P7G083",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G083"
  },
  {
    "uniprot": "A0A3P7EAU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAU4"
  },
  {
    "uniprot": "A0A3P7D9L7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7D9L7"
  },
  {
    "uniprot": "J9BM32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BM32"
  },
  {
    "uniprot": "A0A3P7FIJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIJ2"
  },
  {
    "uniprot": "A0A3P7EEB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEB4"
  },
  {
    "uniprot": "A0A3P7FM17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM17"
  },
  {
    "uniprot": "A0A3P7DWE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWE5"
  },
  {
    "uniprot": "A0A3P7FC67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC67"
  },
  {
    "uniprot": "A0A3P7EPU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPU5"
  },
  {
    "uniprot": "J9F1D0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1D0"
  },
  {
    "uniprot": "A0A3P7E0N5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0N5"
  },
  {
    "uniprot": "A0A3P7FTS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTS1"
  },
  {
    "uniprot": "A0A3P7E3S5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3S5"
  },
  {
    "uniprot": "A0A3P7EAU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAU0"
  },
  {
    "uniprot": "A0A3P7EJN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJN5"
  },
  {
    "uniprot": "A0A3P7DVH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVH8"
  },
  {
    "uniprot": "A0A3P7GHQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHQ6"
  },
  {
    "uniprot": "A0A3P7DDX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDX3"
  },
  {
    "uniprot": "A0A3P7F556",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F556"
  },
  {
    "uniprot": "A0A3P7E9S2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9S2"
  },
  {
    "uniprot": "A0A3P7DG01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG01"
  },
  {
    "uniprot": "A0A3P7E8H8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8H8"
  },
  {
    "uniprot": "A0A3P7ECP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECP5"
  },
  {
    "uniprot": "A0A3P7ED23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED23"
  },
  {
    "uniprot": "J9EQL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQL3"
  },
  {
    "uniprot": "A0A3P7DW18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW18"
  },
  {
    "uniprot": "A0A3P7EIT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIT1"
  },
  {
    "uniprot": "A0A3P7E4M5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4M5"
  },
  {
    "uniprot": "A0A3P7DUW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUW1"
  },
  {
    "uniprot": "A0A3P7DRF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRF0"
  },
  {
    "uniprot": "J9F994",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F994"
  },
  {
    "uniprot": "A0A3P7DR57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR57"
  },
  {
    "uniprot": "A0A3P7FZG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZG9"
  },
  {
    "uniprot": "A0A3P7F387",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F387"
  },
  {
    "uniprot": "A0A3P7DN86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN86"
  },
  {
    "uniprot": "A0A3P7EDF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDF0"
  },
  {
    "uniprot": "A0A3P7EYU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYU6"
  },
  {
    "uniprot": "A0A3P7DTT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTT5"
  },
  {
    "uniprot": "A0A3P7E3R3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3R3"
  },
  {
    "uniprot": "A0A3P7EAJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAJ7"
  },
  {
    "uniprot": "J9EK87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EK87"
  },
  {
    "uniprot": "A0A3P7E764",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E764"
  },
  {
    "uniprot": "A0A3P7FWC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWC0"
  },
  {
    "uniprot": "A0A3P7DXL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXL9"
  },
  {
    "uniprot": "A0A3P7EC32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC32"
  },
  {
    "uniprot": "A0A3P7E903",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E903"
  },
  {
    "uniprot": "J9BMU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BMU8"
  },
  {
    "uniprot": "A0A3P7DW48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW48"
  },
  {
    "uniprot": "J9F7H2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F7H2"
  },
  {
    "uniprot": "A0A3P7DL06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL06"
  },
  {
    "uniprot": "A0A3P7EDF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDF8"
  },
  {
    "uniprot": "A0A3P7FBQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBQ9"
  },
  {
    "uniprot": "A0A3P7ELN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELN1"
  },
  {
    "uniprot": "A0A3P7DWD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWD9"
  },
  {
    "uniprot": "A0A3P7E124",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E124"
  },
  {
    "uniprot": "A0A3P7EVY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVY8"
  },
  {
    "uniprot": "A0A3P7G4S7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4S7"
  },
  {
    "uniprot": "A0A183XST7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XST7"
  },
  {
    "uniprot": "A0A3P7E9W7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9W7"
  },
  {
    "uniprot": "A0A3P7DBM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBM8"
  },
  {
    "uniprot": "A0A3P7EFN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFN8"
  },
  {
    "uniprot": "A0A3P7GA31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GA31"
  },
  {
    "uniprot": "A0A3P7DXJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXJ2"
  },
  {
    "uniprot": "A0A3P7DZ81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ81"
  },
  {
    "uniprot": "A0A3P7DXJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXJ9"
  },
  {
    "uniprot": "A0A3P7DHG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHG6"
  },
  {
    "uniprot": "A0A3P7FJF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJF7"
  },
  {
    "uniprot": "J9ASM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ASM8"
  },
  {
    "uniprot": "A0A3P7E609",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E609"
  },
  {
    "uniprot": "A0A3P7DUB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUB9"
  },
  {
    "uniprot": "A0A3P7DYJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYJ9"
  },
  {
    "uniprot": "A0A3P7DHW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHW6"
  },
  {
    "uniprot": "A0A3P7FZ87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ87"
  },
  {
    "uniprot": "A0A183XQZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XQZ6"
  },
  {
    "uniprot": "A0A3P7DT79",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT79"
  },
  {
    "uniprot": "A0A3P7DBS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBS2"
  },
  {
    "uniprot": "A0A3P7EN78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN78"
  },
  {
    "uniprot": "A0A3P7E3L5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3L5"
  },
  {
    "uniprot": "A0A3P7DVP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVP5"
  },
  {
    "uniprot": "A0A3P7E9M3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9M3"
  },
  {
    "uniprot": "A0A3P7DBQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBQ6"
  },
  {
    "uniprot": "A0A3P7DP97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP97"
  },
  {
    "uniprot": "A0A3P7G2M0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2M0"
  },
  {
    "uniprot": "A0A3P7E8W6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8W6"
  },
  {
    "uniprot": "A0A183XBZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XBZ3"
  },
  {
    "uniprot": "A0A3P7FUQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUQ0"
  },
  {
    "uniprot": "A0A3P7DFH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFH1"
  },
  {
    "uniprot": "A0A3P7FUI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUI3"
  },
  {
    "uniprot": "A0A3P7DWF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWF8"
  },
  {
    "uniprot": "A0A3P7E5X3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5X3"
  },
  {
    "uniprot": "A0A3P7EET0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EET0"
  },
  {
    "uniprot": "A0A3P7EKK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKK5"
  },
  {
    "uniprot": "A0A3P7DYD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYD5"
  },
  {
    "uniprot": "A0A3P7EDI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDI1"
  },
  {
    "uniprot": "A0A3P7DNP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNP5"
  },
  {
    "uniprot": "A0A183Y105",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y105"
  },
  {
    "uniprot": "A0A3P7ESN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESN3"
  },
  {
    "uniprot": "A0A3P7FNU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNU9"
  },
  {
    "uniprot": "J9DXX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DXX1"
  },
  {
    "uniprot": "A0A3P7DDC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDC1"
  },
  {
    "uniprot": "A0A3P7G156",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G156"
  },
  {
    "uniprot": "A0A3P7EPJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPJ5"
  },
  {
    "uniprot": "A0A3P7GIP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIP4"
  },
  {
    "uniprot": "A0A3P7DUF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUF5"
  },
  {
    "uniprot": "A0A3P7DTX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTX8"
  },
  {
    "uniprot": "A0A3P7FDY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDY3"
  },
  {
    "uniprot": "A0A183XWG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XWG6"
  },
  {
    "uniprot": "A0A3P7E7U4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7U4"
  },
  {
    "uniprot": "A0A3P7EUP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUP9"
  },
  {
    "uniprot": "A0A3P7DVS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVS2"
  },
  {
    "uniprot": "A0A3P7DY09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY09"
  },
  {
    "uniprot": "A0A3P7G425",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G425"
  },
  {
    "uniprot": "A0A3P7ETX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETX2"
  },
  {
    "uniprot": "A0A3P7DV83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV83"
  },
  {
    "uniprot": "A0A3P7DVW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVW5"
  },
  {
    "uniprot": "A0A3P7DXK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXK8"
  },
  {
    "uniprot": "A0A3P7ERT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERT5"
  },
  {
    "uniprot": "A0A3P7DD54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD54"
  },
  {
    "uniprot": "A0A3P7FLI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLI8"
  },
  {
    "uniprot": "A0A3P7DZ01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ01"
  },
  {
    "uniprot": "A0A3P7DNY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNY6"
  },
  {
    "uniprot": "A0A3P7FUN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUN0"
  },
  {
    "uniprot": "A0A3P7EPP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPP2"
  },
  {
    "uniprot": "J9F155",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F155"
  },
  {
    "uniprot": "A0A3P7E9B1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9B1"
  },
  {
    "uniprot": "A0A3P7G9G4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9G4"
  },
  {
    "uniprot": "A0A3P7FZU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZU2"
  },
  {
    "uniprot": "A0A3P7GM96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GM96"
  },
  {
    "uniprot": "A0A3P7DVU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVU3"
  },
  {
    "uniprot": "A0A3P7E1H3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1H3"
  },
  {
    "uniprot": "A0A3P7FU21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU21"
  },
  {
    "uniprot": "A0A3P7GDZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDZ1"
  },
  {
    "uniprot": "A0A3P7EC71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC71"
  },
  {
    "uniprot": "J9ERQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERQ3"
  },
  {
    "uniprot": "A0A3P7E496",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E496"
  },
  {
    "uniprot": "A0A3P7F2V7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2V7"
  },
  {
    "uniprot": "J9AU07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AU07"
  },
  {
    "uniprot": "A0A3P7GDG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDG9"
  },
  {
    "uniprot": "A0A3P7DWV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWV9"
  },
  {
    "uniprot": "J9FDN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FDN5"
  },
  {
    "uniprot": "A0A3P7G5L6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5L6"
  },
  {
    "uniprot": "A0A3P7DUZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUZ0"
  },
  {
    "uniprot": "A0A3P7FG51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG51"
  },
  {
    "uniprot": "A0A3P7E0M2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0M2"
  },
  {
    "uniprot": "A0A3P7FK96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK96"
  },
  {
    "uniprot": "A0A3P7FSI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSI3"
  },
  {
    "uniprot": "A0A3P7FDS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDS0"
  },
  {
    "uniprot": "A0A183XPK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XPK4"
  },
  {
    "uniprot": "A0A3P7FUU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUU3"
  },
  {
    "uniprot": "A0A3P7EG32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG32"
  },
  {
    "uniprot": "A0A3P7EVI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVI8"
  },
  {
    "uniprot": "A0A3P7ESI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESI8"
  },
  {
    "uniprot": "A0A3P7E439",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E439"
  },
  {
    "uniprot": "A0A3P7GJZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJZ9"
  },
  {
    "uniprot": "A0A3P7FW46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW46"
  },
  {
    "uniprot": "A0A3P7FWX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWX2"
  },
  {
    "uniprot": "A0A3P7DTT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTT9"
  },
  {
    "uniprot": "A0A3P7FWW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWW8"
  },
  {
    "uniprot": "A0A3P7DVQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVQ0"
  },
  {
    "uniprot": "A0A3P7FVT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVT8"
  },
  {
    "uniprot": "A0A3P7ERG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERG4"
  },
  {
    "uniprot": "A0A3P7EKG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKG7"
  },
  {
    "uniprot": "A0A3P7G213",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G213"
  },
  {
    "uniprot": "A0A3P7E5I2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5I2"
  },
  {
    "uniprot": "A0A3P7ED36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED36"
  },
  {
    "uniprot": "A0A3P7EH25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH25"
  },
  {
    "uniprot": "J9ELD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ELD7"
  },
  {
    "uniprot": "A0A3P7ELV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELV8"
  },
  {
    "uniprot": "A0A3P7DTJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTJ6"
  },
  {
    "uniprot": "A0A183XEF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XEF3"
  },
  {
    "uniprot": "A0A3P7FHV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHV9"
  },
  {
    "uniprot": "A0A3P7EGS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGS9"
  },
  {
    "uniprot": "A0A3P7DQB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQB1"
  },
  {
    "uniprot": "A0A3P7FGG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGG0"
  },
  {
    "uniprot": "A0A3P7DJW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJW4"
  },
  {
    "uniprot": "A0A3P7E0I1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0I1"
  },
  {
    "uniprot": "A0A3P7DUA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUA3"
  },
  {
    "uniprot": "A0A3P7G6N7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6N7"
  },
  {
    "uniprot": "A0A3P7EZM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZM8"
  },
  {
    "uniprot": "A0A3P7G8V5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8V5"
  },
  {
    "uniprot": "A0A3P7EJK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJK4"
  },
  {
    "uniprot": "A0A3P7DW67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW67"
  },
  {
    "uniprot": "A0A3P7F855",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F855"
  },
  {
    "uniprot": "A0A3P7DH35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH35"
  },
  {
    "uniprot": "A0A3P7DT04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT04"
  },
  {
    "uniprot": "A0A3P7EC98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC98"
  },
  {
    "uniprot": "A0A3P7DGI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGI0"
  },
  {
    "uniprot": "A0A3P7GDU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDU6"
  },
  {
    "uniprot": "A0A3P7E6L0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6L0"
  },
  {
    "uniprot": "J9FIC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FIC4"
  },
  {
    "uniprot": "A0A3P7DV08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV08"
  },
  {
    "uniprot": "A0A3P7FWR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWR9"
  },
  {
    "uniprot": "A0A3P7ECP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECP2"
  },
  {
    "uniprot": "A0A3P7EDR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDR9"
  },
  {
    "uniprot": "A0A3P7DM29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM29"
  },
  {
    "uniprot": "J9ECF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ECF6"
  },
  {
    "uniprot": "A0A3P7DAS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAS2"
  },
  {
    "uniprot": "A0A3P7FMZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMZ0"
  },
  {
    "uniprot": "A0A3P7FWZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWZ4"
  },
  {
    "uniprot": "A0A3P7GFV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFV2"
  },
  {
    "uniprot": "A0A3P7ENZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENZ5"
  },
  {
    "uniprot": "A0A3P7DZC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZC9"
  },
  {
    "uniprot": "A0A3P7DYS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYS5"
  },
  {
    "uniprot": "A0A3P7ECB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECB4"
  },
  {
    "uniprot": "A0A3P7DZ64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ64"
  },
  {
    "uniprot": "A0A3P7E4D9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4D9"
  },
  {
    "uniprot": "A0A3P7EWY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWY7"
  },
  {
    "uniprot": "A0A3P7E6U6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6U6"
  },
  {
    "uniprot": "A0A3P7EAW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAW5"
  },
  {
    "uniprot": "A0A3P7FLT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLT9"
  },
  {
    "uniprot": "A0A3P7F1I5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1I5"
  },
  {
    "uniprot": "A0A3P7DMX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMX3"
  },
  {
    "uniprot": "A0A3P7EI79",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI79"
  },
  {
    "uniprot": "A0A3P7DLU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLU8"
  },
  {
    "uniprot": "A0A3P7GEQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEQ9"
  },
  {
    "uniprot": "J9BMW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BMW3"
  },
  {
    "uniprot": "A0A3P7GG11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GG11"
  },
  {
    "uniprot": "A0A3P7F905",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F905"
  },
  {
    "uniprot": "A0A3P7EXR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXR1"
  },
  {
    "uniprot": "A0A3P7FXQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXQ1"
  },
  {
    "uniprot": "A0A3P7DFJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFJ6"
  },
  {
    "uniprot": "A0A3P7DT73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT73"
  },
  {
    "uniprot": "A0A3P7FR37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR37"
  },
  {
    "uniprot": "J9E572",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E572"
  },
  {
    "uniprot": "A0A3P7GEG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEG3"
  },
  {
    "uniprot": "A0A3P7FFK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFK4"
  },
  {
    "uniprot": "A0A3P7EC68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC68"
  },
  {
    "uniprot": "A0A183XL52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XL52"
  },
  {
    "uniprot": "J9FGK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FGK1"
  },
  {
    "uniprot": "A0A3P7EYK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYK3"
  },
  {
    "uniprot": "A0A3P7EJ53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ53"
  },
  {
    "uniprot": "J9EKT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EKT4"
  },
  {
    "uniprot": "A0A3P7E9D9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9D9"
  },
  {
    "uniprot": "A0A3P7F0D8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0D8"
  },
  {
    "uniprot": "A0A183XE64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XE64"
  },
  {
    "uniprot": "A0A3P7DX57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX57"
  },
  {
    "uniprot": "A0A3P7DKS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKS2"
  },
  {
    "uniprot": "A0A3P7FME3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FME3"
  },
  {
    "uniprot": "A0A3P7GBN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBN1"
  },
  {
    "uniprot": "A0A3P7F8A0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8A0"
  },
  {
    "uniprot": "A0A3P7GFT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFT8"
  },
  {
    "uniprot": "A0A3P7EEV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEV8"
  },
  {
    "uniprot": "J9EYY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYY9"
  },
  {
    "uniprot": "A0A3P7ET24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET24"
  },
  {
    "uniprot": "A0A3P7DNK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNK8"
  },
  {
    "uniprot": "A0A3P7EPH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPH1"
  },
  {
    "uniprot": "A0A3P7ECN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECN1"
  },
  {
    "uniprot": "A0A3P7DWC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWC0"
  },
  {
    "uniprot": "A0A3P7E456",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E456"
  },
  {
    "uniprot": "A0A3P7EFQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFQ5"
  },
  {
    "uniprot": "A0A3P7F1N3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1N3"
  },
  {
    "uniprot": "A0A3P7EFZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFZ9"
  },
  {
    "uniprot": "A0A3P7E4W0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4W0"
  },
  {
    "uniprot": "A0A3P7EHQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHQ5"
  },
  {
    "uniprot": "A0A3P7DVA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVA8"
  },
  {
    "uniprot": "A0A3P7E3Y9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3Y9"
  },
  {
    "uniprot": "A0A3P7GFJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFJ2"
  },
  {
    "uniprot": "A0A3P7G3H9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3H9"
  },
  {
    "uniprot": "A0A3P7E367",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E367"
  },
  {
    "uniprot": "A0A3P7EPY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPY5"
  },
  {
    "uniprot": "A0A3P7DY47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY47"
  },
  {
    "uniprot": "A0A3P7E9L4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9L4"
  },
  {
    "uniprot": "A0A3P7EXE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXE1"
  },
  {
    "uniprot": "J9F2B0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2B0"
  },
  {
    "uniprot": "A0A3P7E3T3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3T3"
  },
  {
    "uniprot": "A0A3P7DMT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMT3"
  },
  {
    "uniprot": "A0A3P7DIS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIS1"
  },
  {
    "uniprot": "J9EBV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EBV8"
  },
  {
    "uniprot": "A0A3P7DWI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWI0"
  },
  {
    "uniprot": "A0A3P7DXD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXD2"
  },
  {
    "uniprot": "A0A3P7EHB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHB3"
  },
  {
    "uniprot": "A0A3P7GIQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIQ2"
  },
  {
    "uniprot": "A0A3P7DS69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS69"
  },
  {
    "uniprot": "A0A3P7FZ97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ97"
  },
  {
    "uniprot": "A0A3P7EHS7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHS7"
  },
  {
    "uniprot": "A0A3P7EKU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKU2"
  },
  {
    "uniprot": "A0A3P7GAE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAE8"
  },
  {
    "uniprot": "A0A3P7E920",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E920"
  },
  {
    "uniprot": "A0A3P7FX37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX37"
  },
  {
    "uniprot": "A0A3P7EHP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHP8"
  },
  {
    "uniprot": "A0A3P7EJ30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ30"
  },
  {
    "uniprot": "A0A3P7EC88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC88"
  },
  {
    "uniprot": "A0A3P7ES42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ES42"
  },
  {
    "uniprot": "A0A183Y7I8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y7I8"
  },
  {
    "uniprot": "J9B167",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B167"
  },
  {
    "uniprot": "J9FER2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FER2"
  },
  {
    "uniprot": "A0A3P7E8U7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8U7"
  },
  {
    "uniprot": "A0A3P7FX47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX47"
  },
  {
    "uniprot": "A0A3P7FHW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHW2"
  },
  {
    "uniprot": "A0A3P7FAI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAI6"
  },
  {
    "uniprot": "A0A3P7DQ90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ90"
  },
  {
    "uniprot": "J9BAY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BAY3"
  },
  {
    "uniprot": "A0A3P7FE43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE43"
  },
  {
    "uniprot": "A0A3P7GM83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GM83"
  },
  {
    "uniprot": "A0A3P7EWK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWK1"
  },
  {
    "uniprot": "A0A3P7DKH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKH8"
  },
  {
    "uniprot": "A0A3P7EMK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMK7"
  },
  {
    "uniprot": "A0A3P7FDX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDX2"
  },
  {
    "uniprot": "A0A3P7E5P4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5P4"
  },
  {
    "uniprot": "A0A183XPX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XPX6"
  },
  {
    "uniprot": "A0A3P7E302",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E302"
  },
  {
    "uniprot": "A0A3P7E085",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E085"
  },
  {
    "uniprot": "A0A3P7DJ13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ13"
  },
  {
    "uniprot": "A0A3P7E0V1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0V1"
  },
  {
    "uniprot": "A0A3P7DUU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUU7"
  },
  {
    "uniprot": "A0A3P7DQA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQA3"
  },
  {
    "uniprot": "A0A3P7EER5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EER5"
  },
  {
    "uniprot": "A0A3P7FKG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKG3"
  },
  {
    "uniprot": "A0A3P7E6I3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6I3"
  },
  {
    "uniprot": "A0A3P7GKB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKB7"
  },
  {
    "uniprot": "A0A3P7E1P3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1P3"
  },
  {
    "uniprot": "A0A3P7ERC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERC4"
  },
  {
    "uniprot": "A0A3P7DSL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSL4"
  },
  {
    "uniprot": "A0A3P7FP26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP26"
  },
  {
    "uniprot": "A0A3P7DWA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWA8"
  },
  {
    "uniprot": "A0A3P7E1T3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1T3"
  },
  {
    "uniprot": "A0A3P7DE60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE60"
  },
  {
    "uniprot": "A0A3P7FE21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE21"
  },
  {
    "uniprot": "A0A3P7EAS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAS3"
  },
  {
    "uniprot": "A0A3P7G7X2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G7X2"
  },
  {
    "uniprot": "A0A3P7F355",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F355"
  },
  {
    "uniprot": "A0A3P7DPS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPS1"
  },
  {
    "uniprot": "J9FBM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FBM5"
  },
  {
    "uniprot": "A0A3P7EJB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJB9"
  },
  {
    "uniprot": "A0A3P7ET68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET68"
  },
  {
    "uniprot": "A0A3P7DUG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUG6"
  },
  {
    "uniprot": "A0A3P7DXA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXA5"
  },
  {
    "uniprot": "A0A3P7DUR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUR5"
  },
  {
    "uniprot": "A0A3P7FVK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVK1"
  },
  {
    "uniprot": "A0A3P7EEI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEI9"
  },
  {
    "uniprot": "A0A3P7DXD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXD8"
  },
  {
    "uniprot": "A0A3P7F8F2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8F2"
  },
  {
    "uniprot": "A0A3P7E141",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E141"
  },
  {
    "uniprot": "A0A3P7FZC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZC9"
  },
  {
    "uniprot": "A0A3P7F2J9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2J9"
  },
  {
    "uniprot": "A0A3P7ER44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ER44"
  },
  {
    "uniprot": "A0A3P7EAT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAT2"
  },
  {
    "uniprot": "A0A3P7EQZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQZ1"
  },
  {
    "uniprot": "A0A183XHU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XHU6"
  },
  {
    "uniprot": "J9F9A4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F9A4"
  },
  {
    "uniprot": "A0A3P7DZV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZV9"
  },
  {
    "uniprot": "A0A3P7DJY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJY5"
  },
  {
    "uniprot": "A0A3P7ENP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENP2"
  },
  {
    "uniprot": "A0A3P7FSP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSP2"
  },
  {
    "uniprot": "A0A3P7ECN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECN2"
  },
  {
    "uniprot": "A0A3P7E2I8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2I8"
  },
  {
    "uniprot": "A0A3P7EF72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF72"
  },
  {
    "uniprot": "A0A3P7EPR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPR5"
  },
  {
    "uniprot": "A0A3P7F100",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F100"
  },
  {
    "uniprot": "A0A3P7FXA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXA0"
  },
  {
    "uniprot": "A0A3P7E5B3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5B3"
  },
  {
    "uniprot": "A0A3P7EGJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGJ7"
  },
  {
    "uniprot": "A0A183XRG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XRG4"
  },
  {
    "uniprot": "A0A3P7DGF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGF4"
  },
  {
    "uniprot": "A0A183XI96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XI96"
  },
  {
    "uniprot": "A0A3P7FHJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHJ1"
  },
  {
    "uniprot": "A0A3P7EKE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKE3"
  },
  {
    "uniprot": "A0A3P7EJJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJJ2"
  },
  {
    "uniprot": "A0A3P7E489",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E489"
  },
  {
    "uniprot": "A0A3P7DFK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFK9"
  },
  {
    "uniprot": "A0A3P7EGL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGL9"
  },
  {
    "uniprot": "A0A183XXC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XXC8"
  },
  {
    "uniprot": "A0A3P7E4Z6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4Z6"
  },
  {
    "uniprot": "A0A3P7E7H3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7H3"
  },
  {
    "uniprot": "A0A3P7DQQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQQ4"
  },
  {
    "uniprot": "A0A3P7E485",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E485"
  },
  {
    "uniprot": "A0A3P7FRD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRD3"
  },
  {
    "uniprot": "A0A3P7FRB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRB7"
  },
  {
    "uniprot": "A0A3P7F0R9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0R9"
  },
  {
    "uniprot": "A0A3P7DPF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPF1"
  },
  {
    "uniprot": "A0A3P7GIY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIY0"
  },
  {
    "uniprot": "A0A3P7DSQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSQ7"
  },
  {
    "uniprot": "A0A3P7DWR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWR1"
  },
  {
    "uniprot": "A0A3P7EDE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDE5"
  },
  {
    "uniprot": "A0A3P7GDA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDA8"
  },
  {
    "uniprot": "A0A3P7DZ79",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ79"
  },
  {
    "uniprot": "A0A3P7EGL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGL0"
  },
  {
    "uniprot": "A0A3P7ERT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERT1"
  },
  {
    "uniprot": "A0A3P7DEM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEM2"
  },
  {
    "uniprot": "A0A3P7DHR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHR0"
  },
  {
    "uniprot": "A0A3P7FPB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPB1"
  },
  {
    "uniprot": "J9AFD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AFD3"
  },
  {
    "uniprot": "A0A3P7EKT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKT8"
  },
  {
    "uniprot": "A0A183XK07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XK07"
  },
  {
    "uniprot": "A0A3P7DPP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPP8"
  },
  {
    "uniprot": "A0A3P7GEP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEP8"
  },
  {
    "uniprot": "A0A3P7DSZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSZ9"
  },
  {
    "uniprot": "A0A3P7E5M1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5M1"
  },
  {
    "uniprot": "A0A3P7EPE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPE8"
  },
  {
    "uniprot": "A0A3P7GF96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GF96"
  },
  {
    "uniprot": "A0A3P7FHI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHI1"
  },
  {
    "uniprot": "A0A3P7DG29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG29"
  },
  {
    "uniprot": "A0A3P7DPX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPX2"
  },
  {
    "uniprot": "A0A3P7GIE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIE4"
  },
  {
    "uniprot": "A0A3P7DW08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW08"
  },
  {
    "uniprot": "A0A3P7E8N5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8N5"
  },
  {
    "uniprot": "A0A3P7E7I9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7I9"
  },
  {
    "uniprot": "A0A3P7EBM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBM9"
  },
  {
    "uniprot": "A0A3P7EW57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EW57"
  },
  {
    "uniprot": "A0A3P7FX41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX41"
  },
  {
    "uniprot": "A0A3P7DNW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNW8"
  },
  {
    "uniprot": "A0A3P7EQX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQX5"
  },
  {
    "uniprot": "A0A3P7DTL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTL2"
  },
  {
    "uniprot": "A0A3P7FNW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNW2"
  },
  {
    "uniprot": "A0A3P7FGG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGG2"
  },
  {
    "uniprot": "A0A3P7DJD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJD0"
  },
  {
    "uniprot": "A0A3P7DYS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYS9"
  },
  {
    "uniprot": "A0A3P7DXX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXX5"
  },
  {
    "uniprot": "A0A3P7E8T9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8T9"
  },
  {
    "uniprot": "A0A3P7EU89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU89"
  },
  {
    "uniprot": "A0A3P7E287",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E287"
  },
  {
    "uniprot": "A0A3P7EL92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL92"
  },
  {
    "uniprot": "A0A3P7DWP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWP7"
  },
  {
    "uniprot": "A0A183XTJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XTJ7"
  },
  {
    "uniprot": "A0A3P7EUV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUV3"
  },
  {
    "uniprot": "A0A3P7DD18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD18"
  },
  {
    "uniprot": "A0A3P7G4R7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4R7"
  },
  {
    "uniprot": "A0A3P7DRY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRY3"
  },
  {
    "uniprot": "J9EUB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUB9"
  },
  {
    "uniprot": "A0A3P7FZB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZB0"
  },
  {
    "uniprot": "A0A3P7FV71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV71"
  },
  {
    "uniprot": "A0A3P7DIZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIZ5"
  },
  {
    "uniprot": "A0A3P7DYV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYV7"
  },
  {
    "uniprot": "A0A3P7E720",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E720"
  },
  {
    "uniprot": "A0A3P7EA00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA00"
  },
  {
    "uniprot": "A0A3P7E167",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E167"
  },
  {
    "uniprot": "A0A3P7F819",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F819"
  },
  {
    "uniprot": "A0A3P7EKY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKY4"
  },
  {
    "uniprot": "A0A3P7DQK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQK8"
  },
  {
    "uniprot": "A0A3P7FFW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFW0"
  },
  {
    "uniprot": "A0A3P7FWT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWT9"
  },
  {
    "uniprot": "A0A3P7EIC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIC5"
  },
  {
    "uniprot": "A0A3P7FQL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQL1"
  },
  {
    "uniprot": "A0A3P7EKQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKQ7"
  },
  {
    "uniprot": "A0A183XB52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XB52"
  },
  {
    "uniprot": "A0A3P7DS86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS86"
  },
  {
    "uniprot": "A0A3P7E297",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E297"
  },
  {
    "uniprot": "A0A3P7DQA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQA2"
  },
  {
    "uniprot": "A0A3P7FL30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL30"
  },
  {
    "uniprot": "A0A3P7FB93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB93"
  },
  {
    "uniprot": "J9EDL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EDL8"
  },
  {
    "uniprot": "A0A3P7GMK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GMK4"
  },
  {
    "uniprot": "A0A3P7FUW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUW0"
  },
  {
    "uniprot": "A0A3P7F901",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F901"
  },
  {
    "uniprot": "A0A3P7E8I4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8I4"
  },
  {
    "uniprot": "A0A3P7DDX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDX6"
  },
  {
    "uniprot": "A0A3P7DDE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDE2"
  },
  {
    "uniprot": "A0A3P7DRT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRT3"
  },
  {
    "uniprot": "A0A3P7E8B4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8B4"
  },
  {
    "uniprot": "J9FGU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FGU9"
  },
  {
    "uniprot": "A0A3P7DUV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUV9"
  },
  {
    "uniprot": "A0A3P7E338",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E338"
  },
  {
    "uniprot": "J9F6J4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F6J4"
  },
  {
    "uniprot": "A0A3P7E8D5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8D5"
  },
  {
    "uniprot": "A0A3P7EVQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVQ1"
  },
  {
    "uniprot": "A0A3P7FFN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFN0"
  },
  {
    "uniprot": "A0A3P7EZ34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZ34"
  },
  {
    "uniprot": "A0A3P7DMT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMT8"
  },
  {
    "uniprot": "A0A3P7FX08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX08"
  },
  {
    "uniprot": "A0A3P7DWX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWX4"
  },
  {
    "uniprot": "A0A3P7FF51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF51"
  },
  {
    "uniprot": "A0A3P7F9A0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9A0"
  },
  {
    "uniprot": "A0A3P7EF01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF01"
  },
  {
    "uniprot": "A0A3P7DYT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYT4"
  },
  {
    "uniprot": "A0A3P7GC11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GC11"
  },
  {
    "uniprot": "J9ER77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ER77"
  },
  {
    "uniprot": "A0A3P7DGR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGR5"
  },
  {
    "uniprot": "A0A3P7DL93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL93"
  },
  {
    "uniprot": "A0A3P7EIK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIK7"
  },
  {
    "uniprot": "A0A3P7DBV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBV2"
  },
  {
    "uniprot": "A0A3P7DT54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT54"
  },
  {
    "uniprot": "A0A3P7E9S5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9S5"
  },
  {
    "uniprot": "A0A3P7E5C7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5C7"
  },
  {
    "uniprot": "A0A3P7DZP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZP3"
  },
  {
    "uniprot": "A0A3P7DUJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUJ5"
  },
  {
    "uniprot": "A0A3P7E525",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E525"
  },
  {
    "uniprot": "A0A3P7EHH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHH7"
  },
  {
    "uniprot": "A0A3P7FUN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUN9"
  },
  {
    "uniprot": "A0A3P7E2N6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2N6"
  },
  {
    "uniprot": "A0A3P7E145",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E145"
  },
  {
    "uniprot": "A0A3P7GAT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAT5"
  },
  {
    "uniprot": "A0A3P7FEI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEI2"
  },
  {
    "uniprot": "J9BM86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BM86"
  },
  {
    "uniprot": "A0A3P7E741",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E741"
  },
  {
    "uniprot": "A0A3P7DPU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPU1"
  },
  {
    "uniprot": "A0A3P7FC30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC30"
  },
  {
    "uniprot": "A0A3P7FLP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLP8"
  },
  {
    "uniprot": "A0A3P7DVL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVL5"
  },
  {
    "uniprot": "A0A3P7DRQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRQ4"
  },
  {
    "uniprot": "A0A3P7EEF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEF1"
  },
  {
    "uniprot": "A0A3P7ERH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERH6"
  },
  {
    "uniprot": "A0A3P7E2Z0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2Z0"
  },
  {
    "uniprot": "A0A3P7DMZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMZ5"
  },
  {
    "uniprot": "A0A3P7FSA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSA9"
  },
  {
    "uniprot": "A0A3P7EN08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN08"
  },
  {
    "uniprot": "A0A3P7FA07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FA07"
  },
  {
    "uniprot": "A0A3P7DVT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVT8"
  },
  {
    "uniprot": "A0A3P7ESE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESE0"
  },
  {
    "uniprot": "A0A3P7FA46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FA46"
  },
  {
    "uniprot": "A0A3P7EF75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF75"
  },
  {
    "uniprot": "A0A3P7EG05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG05"
  },
  {
    "uniprot": "A0A3P7E1R6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1R6"
  },
  {
    "uniprot": "A0A3P7DKF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKF3"
  },
  {
    "uniprot": "A0A3P7EPV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPV6"
  },
  {
    "uniprot": "A0A3P7G574",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G574"
  },
  {
    "uniprot": "J9E5B4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E5B4"
  },
  {
    "uniprot": "J9EVJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EVJ6"
  },
  {
    "uniprot": "A0A3P7DU79",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU79"
  },
  {
    "uniprot": "A0A3P7F137",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F137"
  },
  {
    "uniprot": "A0A3P7EBE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBE8"
  },
  {
    "uniprot": "A0A183XZ18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XZ18"
  },
  {
    "uniprot": "A0A3P7EMJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMJ2"
  },
  {
    "uniprot": "A0A3P7G979",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G979"
  },
  {
    "uniprot": "A0A3P7DFX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFX7"
  },
  {
    "uniprot": "A0A3P7ED48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED48"
  },
  {
    "uniprot": "A0A3P7E584",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E584"
  },
  {
    "uniprot": "J9ELJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ELJ2"
  },
  {
    "uniprot": "A0A3P7DYU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYU5"
  },
  {
    "uniprot": "J9BDP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BDP8"
  },
  {
    "uniprot": "J9F1F0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1F0"
  },
  {
    "uniprot": "A0A3P7DWB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWB3"
  },
  {
    "uniprot": "A0A3P7DY59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY59"
  },
  {
    "uniprot": "A0A183XR14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XR14"
  },
  {
    "uniprot": "A0A3P7GHX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHX3"
  },
  {
    "uniprot": "A0A3P7DSW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSW6"
  },
  {
    "uniprot": "A0A3P7E543",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E543"
  },
  {
    "uniprot": "J9EXA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXA4"
  },
  {
    "uniprot": "A0A3P7FM67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM67"
  },
  {
    "uniprot": "A0A3P7DZT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZT9"
  },
  {
    "uniprot": "A0A3P7E618",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E618"
  },
  {
    "uniprot": "A0A3P7FVY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVY1"
  },
  {
    "uniprot": "A0A3P7DUL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUL1"
  },
  {
    "uniprot": "A0A3P7FHH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHH3"
  },
  {
    "uniprot": "J9EM33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EM33"
  },
  {
    "uniprot": "A0A3P7DN70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN70"
  },
  {
    "uniprot": "J9EV96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EV96"
  },
  {
    "uniprot": "A0A3P7EDV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDV0"
  },
  {
    "uniprot": "A0A3P7EGV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGV6"
  },
  {
    "uniprot": "A0A3P7EJD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJD7"
  },
  {
    "uniprot": "A0A3P7DQF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQF6"
  },
  {
    "uniprot": "A0A3P7DKM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKM7"
  },
  {
    "uniprot": "A0A183XDB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDB7"
  },
  {
    "uniprot": "A0A3P7GC32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GC32"
  },
  {
    "uniprot": "A0A3P7DVL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVL7"
  },
  {
    "uniprot": "J9F3U1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F3U1"
  },
  {
    "uniprot": "J9EHM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EHM3"
  },
  {
    "uniprot": "J9BFT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BFT2"
  },
  {
    "uniprot": "A0A3P7E6J4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6J4"
  },
  {
    "uniprot": "A0A3P7G2D7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2D7"
  },
  {
    "uniprot": "A0A3P7DRM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRM8"
  },
  {
    "uniprot": "A0A3P7DQT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQT4"
  },
  {
    "uniprot": "A0A3P7DLV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLV2"
  },
  {
    "uniprot": "A0A3P7DD02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD02"
  },
  {
    "uniprot": "A0A3P7FWD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWD8"
  },
  {
    "uniprot": "J9B388",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B388"
  },
  {
    "uniprot": "A0A3P7DHD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHD8"
  },
  {
    "uniprot": "A0A3P7FCG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCG4"
  },
  {
    "uniprot": "A0A3P7DIP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIP7"
  },
  {
    "uniprot": "A0A3P7FHJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHJ5"
  },
  {
    "uniprot": "A0A3P7EU33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU33"
  },
  {
    "uniprot": "A0A3P7EM57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM57"
  },
  {
    "uniprot": "A0A3P7EDB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDB8"
  },
  {
    "uniprot": "A0A3P7E345",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E345"
  },
  {
    "uniprot": "A0A3P7DT33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT33"
  },
  {
    "uniprot": "A0A3P7E541",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E541"
  },
  {
    "uniprot": "A0A3P7ERT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERT9"
  },
  {
    "uniprot": "A0A3P7FD48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD48"
  },
  {
    "uniprot": "A0A3P7EAL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAL8"
  },
  {
    "uniprot": "A0A3P7E047",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E047"
  },
  {
    "uniprot": "A0A3P7E1K8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1K8"
  },
  {
    "uniprot": "A0A3P7EUW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUW6"
  },
  {
    "uniprot": "A0A3P7E402",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E402"
  },
  {
    "uniprot": "A0A3P7E9J5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9J5"
  },
  {
    "uniprot": "J9E195",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E195"
  },
  {
    "uniprot": "A0A183XKQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XKQ7"
  },
  {
    "uniprot": "A0A3P7DP05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP05"
  },
  {
    "uniprot": "A0A3P7EJ92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ92"
  },
  {
    "uniprot": "A0A3P7DWP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWP3"
  },
  {
    "uniprot": "J9B818",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B818"
  },
  {
    "uniprot": "A0A3P7E1L8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1L8"
  },
  {
    "uniprot": "A0A183Y9H7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y9H7"
  },
  {
    "uniprot": "A0A3P7FM49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM49"
  },
  {
    "uniprot": "A0A3P7FE78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE78"
  },
  {
    "uniprot": "A0A3P7DZ04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ04"
  },
  {
    "uniprot": "A0A3P7FF23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF23"
  },
  {
    "uniprot": "A0A3P7DM70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM70"
  },
  {
    "uniprot": "A0A3P7EH70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH70"
  },
  {
    "uniprot": "A0A3P7DS04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS04"
  },
  {
    "uniprot": "A0A3P7DS79",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS79"
  },
  {
    "uniprot": "A0A3P7DY37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY37"
  },
  {
    "uniprot": "A0A3P7GDT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDT4"
  },
  {
    "uniprot": "J9EKD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EKD7"
  },
  {
    "uniprot": "J9ESF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ESF8"
  },
  {
    "uniprot": "J9EWI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EWI2"
  },
  {
    "uniprot": "A0A3P7GBK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBK6"
  },
  {
    "uniprot": "A0A3P7DR86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR86"
  },
  {
    "uniprot": "A0A3P7GAM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAM4"
  },
  {
    "uniprot": "J9AWV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AWV6"
  },
  {
    "uniprot": "A0A3P7E3B1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3B1"
  },
  {
    "uniprot": "A0A3P7DK64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK64"
  },
  {
    "uniprot": "A0A3P7E907",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E907"
  },
  {
    "uniprot": "A0A3P7DTW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTW2"
  },
  {
    "uniprot": "A0A3P7FAY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAY9"
  },
  {
    "uniprot": "A0A3P7G058",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G058"
  },
  {
    "uniprot": "A0A3P7EAI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAI2"
  },
  {
    "uniprot": "A0A3P7DW27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW27"
  },
  {
    "uniprot": "J9EZP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZP5"
  },
  {
    "uniprot": "A0A3P7DEK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEK4"
  },
  {
    "uniprot": "A0A3P7FJ13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ13"
  },
  {
    "uniprot": "A0A3P7DNE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNE6"
  },
  {
    "uniprot": "A0A3P7DPL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPL1"
  },
  {
    "uniprot": "A0A3P7EMP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMP0"
  },
  {
    "uniprot": "A0A3P7DGW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGW5"
  },
  {
    "uniprot": "A0A3P7DLS7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLS7"
  },
  {
    "uniprot": "A0A3P7DKU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKU8"
  },
  {
    "uniprot": "A0A3P7FCL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCL2"
  },
  {
    "uniprot": "A0A3P7GCG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCG2"
  },
  {
    "uniprot": "A0A3P7G1C3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1C3"
  },
  {
    "uniprot": "A0A3P7E588",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E588"
  },
  {
    "uniprot": "A0A3P7DMN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMN1"
  },
  {
    "uniprot": "A0A3P7F7S5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7S5"
  },
  {
    "uniprot": "A0A3P7DFK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFK0"
  },
  {
    "uniprot": "A0A3P7DYJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYJ7"
  },
  {
    "uniprot": "A0A3P7FYR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYR9"
  },
  {
    "uniprot": "A0A3P7EW31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EW31"
  },
  {
    "uniprot": "A0A3P7GAX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAX9"
  },
  {
    "uniprot": "A0A3P7FV81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV81"
  },
  {
    "uniprot": "A0A3P7EM39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM39"
  },
  {
    "uniprot": "A0A3P7DAE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAE3"
  },
  {
    "uniprot": "A0A3P7E385",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E385"
  },
  {
    "uniprot": "A0A3P7DTE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTE0"
  },
  {
    "uniprot": "A0A3P7FL17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL17"
  },
  {
    "uniprot": "A0A3P7FH60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH60"
  },
  {
    "uniprot": "A0A3P7E197",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E197"
  },
  {
    "uniprot": "A0A3P7E3W7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3W7"
  },
  {
    "uniprot": "A0A3P7DSW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSW0"
  },
  {
    "uniprot": "J9BFQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BFQ3"
  },
  {
    "uniprot": "A0A3P7DQQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQQ0"
  },
  {
    "uniprot": "A0A3P7FHR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHR8"
  },
  {
    "uniprot": "A0A3P7EE34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE34"
  },
  {
    "uniprot": "A0A3P7EVN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVN5"
  },
  {
    "uniprot": "A0A3P7FYF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYF5"
  },
  {
    "uniprot": "A0A3P7GL98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GL98"
  },
  {
    "uniprot": "A0A3P7E2X1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2X1"
  },
  {
    "uniprot": "A0A3P7GHR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHR3"
  },
  {
    "uniprot": "A0A3P7EB15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB15"
  },
  {
    "uniprot": "A0A3P7E0T6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0T6"
  },
  {
    "uniprot": "A0A3P7ELV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELV4"
  },
  {
    "uniprot": "A0A3P7DTV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTV2"
  },
  {
    "uniprot": "A0A3P7FST9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FST9"
  },
  {
    "uniprot": "A0A3P7E7Y0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7Y0"
  },
  {
    "uniprot": "J9EDW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EDW3"
  },
  {
    "uniprot": "A0A3P7FD58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD58"
  },
  {
    "uniprot": "A0A3P7DHT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHT0"
  },
  {
    "uniprot": "A0A3P7F1A7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1A7"
  },
  {
    "uniprot": "A0A3P7DZ36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ36"
  },
  {
    "uniprot": "A0A3P7DSY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSY3"
  },
  {
    "uniprot": "J9EV43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EV43"
  },
  {
    "uniprot": "A0A3P7E7W4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7W4"
  },
  {
    "uniprot": "A0A3P7ETM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETM0"
  },
  {
    "uniprot": "J9DVR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DVR7"
  },
  {
    "uniprot": "A0A3P7DXI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXI3"
  },
  {
    "uniprot": "A0A3P7DUN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUN3"
  },
  {
    "uniprot": "A0A3P7FYI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYI8"
  },
  {
    "uniprot": "A0A3P7FZS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZS2"
  },
  {
    "uniprot": "A0A3P7G1D6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1D6"
  },
  {
    "uniprot": "A0A3P7FN77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN77"
  },
  {
    "uniprot": "A0A3P7EEE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEE7"
  },
  {
    "uniprot": "A0A3P7E5F5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5F5"
  },
  {
    "uniprot": "J9EQM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQM0"
  },
  {
    "uniprot": "A0A3P7FG65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG65"
  },
  {
    "uniprot": "A0A3P7DZU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZU8"
  },
  {
    "uniprot": "A0A3P7EIA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIA3"
  },
  {
    "uniprot": "A0A3P7EKX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKX6"
  },
  {
    "uniprot": "J9F2M7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2M7"
  },
  {
    "uniprot": "A0A3P7DTP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTP3"
  },
  {
    "uniprot": "A0A3P7FUI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUI8"
  },
  {
    "uniprot": "J9FAI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FAI0"
  },
  {
    "uniprot": "A0A3P7DLE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLE0"
  },
  {
    "uniprot": "A0A3P7E5L7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5L7"
  },
  {
    "uniprot": "A0A3P7GF44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GF44"
  },
  {
    "uniprot": "A0A3P7EH30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH30"
  },
  {
    "uniprot": "A0A3P7E3H6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3H6"
  },
  {
    "uniprot": "A0A3P7DTU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTU4"
  },
  {
    "uniprot": "A0A3P7FRI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRI8"
  },
  {
    "uniprot": "A0A3P7DZS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZS0"
  },
  {
    "uniprot": "A0A3P7E9N3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9N3"
  },
  {
    "uniprot": "J9DU84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DU84"
  },
  {
    "uniprot": "A0A3P7EK24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK24"
  },
  {
    "uniprot": "J9EY90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EY90"
  },
  {
    "uniprot": "A0A3P7E7E8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7E8"
  },
  {
    "uniprot": "A0A3P7E4F2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4F2"
  },
  {
    "uniprot": "A0A3P7DYF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYF1"
  },
  {
    "uniprot": "A0A3P7F1X1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1X1"
  },
  {
    "uniprot": "A0A3P7DY76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY76"
  },
  {
    "uniprot": "A0A3P7E4Q6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4Q6"
  },
  {
    "uniprot": "A0A3P7F1M4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1M4"
  },
  {
    "uniprot": "A0A3P7DT71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT71"
  },
  {
    "uniprot": "A0A3P7E9A7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9A7"
  },
  {
    "uniprot": "A0A3P7DAJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAJ5"
  },
  {
    "uniprot": "J9DWU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DWU4"
  },
  {
    "uniprot": "A0A3P7ERP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERP0"
  },
  {
    "uniprot": "A0A3P7GI85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GI85"
  },
  {
    "uniprot": "A0A3P7DMA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMA3"
  },
  {
    "uniprot": "A0A3P7DLH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLH0"
  },
  {
    "uniprot": "A0A3P7F1R0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1R0"
  },
  {
    "uniprot": "J9EJL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EJL4"
  },
  {
    "uniprot": "A0A3P7GHY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHY9"
  },
  {
    "uniprot": "A0A3P7FXF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXF9"
  },
  {
    "uniprot": "A0A3P7DYY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYY3"
  },
  {
    "uniprot": "A0A3P7EHL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHL1"
  },
  {
    "uniprot": "A0A3P7E4K8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4K8"
  },
  {
    "uniprot": "A0A3P7EBY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBY9"
  },
  {
    "uniprot": "A0A3P7G9Z8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9Z8"
  },
  {
    "uniprot": "A0A3P7DNP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNP7"
  },
  {
    "uniprot": "A0A3P7GDC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDC2"
  },
  {
    "uniprot": "A0A3P7EVN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVN0"
  },
  {
    "uniprot": "A0A3P7DC71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC71"
  },
  {
    "uniprot": "A0A3P7G1I5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1I5"
  },
  {
    "uniprot": "A0A3P7DTB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTB2"
  },
  {
    "uniprot": "A0A3P7GDV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDV2"
  },
  {
    "uniprot": "A0A3P7EWB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWB0"
  },
  {
    "uniprot": "A0A3P7EHH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHH5"
  },
  {
    "uniprot": "A0A3P7EHI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHI1"
  },
  {
    "uniprot": "A0A3P7EAK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAK0"
  },
  {
    "uniprot": "A0A183XZ11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XZ11"
  },
  {
    "uniprot": "A0A3P7DYQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYQ9"
  },
  {
    "uniprot": "A0A183XMN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XMN4"
  },
  {
    "uniprot": "A0A3P7FV52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV52"
  },
  {
    "uniprot": "A0A3P7DJW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJW8"
  },
  {
    "uniprot": "A0A3P7E2X2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2X2"
  },
  {
    "uniprot": "A0A3P7DYZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYZ5"
  },
  {
    "uniprot": "A0A3P7FY75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY75"
  },
  {
    "uniprot": "A0A3P7GMP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GMP5"
  },
  {
    "uniprot": "A0A3P7DN49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN49"
  },
  {
    "uniprot": "A0A3P7DC31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC31"
  },
  {
    "uniprot": "A0A3P7GMI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GMI4"
  },
  {
    "uniprot": "A0A3P7DQC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQC5"
  },
  {
    "uniprot": "A0A3P7EK47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK47"
  },
  {
    "uniprot": "A0A3P7E0N1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0N1"
  },
  {
    "uniprot": "A0A3P7EHE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHE8"
  },
  {
    "uniprot": "J9FMN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FMN2"
  },
  {
    "uniprot": "J9F956",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F956"
  },
  {
    "uniprot": "A0A183Y800",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y800"
  },
  {
    "uniprot": "A0A3P7DVQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVQ1"
  },
  {
    "uniprot": "A0A3P7FKJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKJ3"
  },
  {
    "uniprot": "A0A3P7E599",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E599"
  },
  {
    "uniprot": "A0A3P7GDQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDQ1"
  },
  {
    "uniprot": "J9F669",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F669"
  },
  {
    "uniprot": "A0A3P7DW66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW66"
  },
  {
    "uniprot": "A0A3P7DV07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV07"
  },
  {
    "uniprot": "A0A3P7DXU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXU0"
  },
  {
    "uniprot": "A0A3P7FV58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV58"
  },
  {
    "uniprot": "J9E4K7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E4K7"
  },
  {
    "uniprot": "J9EDW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EDW7"
  },
  {
    "uniprot": "A0A3P7FCP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCP2"
  },
  {
    "uniprot": "A0A3P7FY49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY49"
  },
  {
    "uniprot": "A0A3P7EA75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA75"
  },
  {
    "uniprot": "A0A3P7FBR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBR7"
  },
  {
    "uniprot": "A0A3P7EVZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVZ1"
  },
  {
    "uniprot": "A0A3P7DYQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYQ7"
  },
  {
    "uniprot": "A0A3P7FQI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQI6"
  },
  {
    "uniprot": "J9DVK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DVK9"
  },
  {
    "uniprot": "A0A3P7DR81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR81"
  },
  {
    "uniprot": "A0A3P7DG21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG21"
  },
  {
    "uniprot": "A0A3P7FKC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKC5"
  },
  {
    "uniprot": "A0A3P7DTG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTG3"
  },
  {
    "uniprot": "A0A3P7GEU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEU6"
  },
  {
    "uniprot": "J9ED92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ED92"
  },
  {
    "uniprot": "A0A3P7E2T2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2T2"
  },
  {
    "uniprot": "A0A3P7DH83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH83"
  },
  {
    "uniprot": "J9EHP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EHP5"
  },
  {
    "uniprot": "A0A3P7EHE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHE7"
  },
  {
    "uniprot": "A0A3P7EP81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP81"
  },
  {
    "uniprot": "A0A3P7EGE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGE9"
  },
  {
    "uniprot": "J9F552",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F552"
  },
  {
    "uniprot": "A0A3P7FFP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFP5"
  },
  {
    "uniprot": "J9E837",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E837"
  },
  {
    "uniprot": "A0A3P7DUG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUG9"
  },
  {
    "uniprot": "A0A3P7GMC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GMC5"
  },
  {
    "uniprot": "A0A3P7FV49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV49"
  },
  {
    "uniprot": "A0A3P7F7Z0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7Z0"
  },
  {
    "uniprot": "A0A3P7FYA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYA5"
  },
  {
    "uniprot": "A0A3P7EED2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EED2"
  },
  {
    "uniprot": "A0A3P7DGN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGN4"
  },
  {
    "uniprot": "A0A3P7DZY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZY4"
  },
  {
    "uniprot": "A0A3P7DYE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYE5"
  },
  {
    "uniprot": "J9FFH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FFH4"
  },
  {
    "uniprot": "A0A3P7E0A7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0A7"
  },
  {
    "uniprot": "A0A3P7FWP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWP0"
  },
  {
    "uniprot": "A0A3P7ECK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECK2"
  },
  {
    "uniprot": "A0A3P7DL05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL05"
  },
  {
    "uniprot": "A0A3P7E335",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E335"
  },
  {
    "uniprot": "A0A3P7EJL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJL0"
  },
  {
    "uniprot": "A0A3P7EZM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZM7"
  },
  {
    "uniprot": "A0A3P7DZ43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ43"
  },
  {
    "uniprot": "A0A3P7FE36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE36"
  },
  {
    "uniprot": "A0A3P7GAA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAA8"
  },
  {
    "uniprot": "A0A3P7DSJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSJ0"
  },
  {
    "uniprot": "A0A3P7EBT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBT2"
  },
  {
    "uniprot": "A0A3P7FGE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGE5"
  },
  {
    "uniprot": "A0A3P7GB66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GB66"
  },
  {
    "uniprot": "A0A3P7E4P7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4P7"
  },
  {
    "uniprot": "A0A3P7DQL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQL7"
  },
  {
    "uniprot": "A0A3P7F0R2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0R2"
  },
  {
    "uniprot": "A0A3P7DEE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEE4"
  },
  {
    "uniprot": "A0A3P7FZK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZK4"
  },
  {
    "uniprot": "A0A3P7DYH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYH6"
  },
  {
    "uniprot": "J9EN51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EN51"
  },
  {
    "uniprot": "A0A3P7DUH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUH7"
  },
  {
    "uniprot": "A0A3P7DWX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWX7"
  },
  {
    "uniprot": "A0A3P7E9N2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9N2"
  },
  {
    "uniprot": "J9EG24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EG24"
  },
  {
    "uniprot": "A0A3P7GC74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GC74"
  },
  {
    "uniprot": "A0A3P7F892",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F892"
  },
  {
    "uniprot": "A0A3P7DQH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQH8"
  },
  {
    "uniprot": "A0A3P7GEJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEJ6"
  },
  {
    "uniprot": "A0A3P7DY68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY68"
  },
  {
    "uniprot": "A0A3P7FSU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSU9"
  },
  {
    "uniprot": "A0A3P7GJU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJU4"
  },
  {
    "uniprot": "A0A3P7FVS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVS6"
  },
  {
    "uniprot": "A0A3P7DGM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGM1"
  },
  {
    "uniprot": "A0A3P7EBX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBX3"
  },
  {
    "uniprot": "A0A3P7DSL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSL8"
  },
  {
    "uniprot": "A0A3P7EEA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEA4"
  },
  {
    "uniprot": "A0A3P7GDJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDJ3"
  },
  {
    "uniprot": "A0A3P7EJQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJQ7"
  },
  {
    "uniprot": "A0A3P7FD87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD87"
  },
  {
    "uniprot": "A0A3P7EDT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDT9"
  },
  {
    "uniprot": "A0A183XDQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDQ4"
  },
  {
    "uniprot": "A0A3P7EAZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAZ2"
  },
  {
    "uniprot": "A0A3P7DKU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKU4"
  },
  {
    "uniprot": "A0A3P7FBK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBK6"
  },
  {
    "uniprot": "A0A3P7EGE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGE4"
  },
  {
    "uniprot": "A0A3P7F910",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F910"
  },
  {
    "uniprot": "A0A3P7DPL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPL5"
  },
  {
    "uniprot": "A0A3P7G949",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G949"
  },
  {
    "uniprot": "A0A3P7FJR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJR0"
  },
  {
    "uniprot": "A0A3P7FR22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR22"
  },
  {
    "uniprot": "J9EWF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EWF4"
  },
  {
    "uniprot": "A0A3P7ECH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECH9"
  },
  {
    "uniprot": "A0A3P7EB85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB85"
  },
  {
    "uniprot": "A0A3P7DQV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQV4"
  },
  {
    "uniprot": "A0A3P7GAV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAV4"
  },
  {
    "uniprot": "A0A3P7F9B5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9B5"
  },
  {
    "uniprot": "J9AUV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AUV8"
  },
  {
    "uniprot": "A0A3P7FCP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCP8"
  },
  {
    "uniprot": "A0A3P7DLJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLJ9"
  },
  {
    "uniprot": "A0A3P7FG89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG89"
  },
  {
    "uniprot": "A0A3P7E0T2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0T2"
  },
  {
    "uniprot": "A0A3P7G146",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G146"
  },
  {
    "uniprot": "J9EYA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYA3"
  },
  {
    "uniprot": "A0A3P7E6F9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6F9"
  },
  {
    "uniprot": "A0A3P7E911",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E911"
  },
  {
    "uniprot": "A0A3P7DCF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCF9"
  },
  {
    "uniprot": "J9F259",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F259"
  },
  {
    "uniprot": "A0A183XMT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XMT8"
  },
  {
    "uniprot": "A0A3P7E3A5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3A5"
  },
  {
    "uniprot": "A0A3P7DC94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC94"
  },
  {
    "uniprot": "J9BJ38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BJ38"
  },
  {
    "uniprot": "J9EW65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EW65"
  },
  {
    "uniprot": "A0A3P7GC20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GC20"
  },
  {
    "uniprot": "A0A3P7GJP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJP5"
  },
  {
    "uniprot": "A0A3P7EKC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKC7"
  },
  {
    "uniprot": "A0A3P7DZT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZT8"
  },
  {
    "uniprot": "A0A3P7EFB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFB7"
  },
  {
    "uniprot": "A0A3P7DJ57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ57"
  },
  {
    "uniprot": "A0A3P7E0S0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0S0"
  },
  {
    "uniprot": "A0A3P7GBX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBX0"
  },
  {
    "uniprot": "A0A3P7E5Z9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5Z9"
  },
  {
    "uniprot": "A0A3P7FYQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYQ7"
  },
  {
    "uniprot": "A0A3P7DJP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJP1"
  },
  {
    "uniprot": "A0A3P7DXR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXR7"
  },
  {
    "uniprot": "A0A3P7ERB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERB1"
  },
  {
    "uniprot": "A0A3P7FYL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYL8"
  },
  {
    "uniprot": "A0A3P7FS11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS11"
  },
  {
    "uniprot": "A0A3P7EEW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEW5"
  },
  {
    "uniprot": "A0A3P7E7I5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7I5"
  },
  {
    "uniprot": "A0A3P7ELV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELV5"
  },
  {
    "uniprot": "A0A3P7G6R1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6R1"
  },
  {
    "uniprot": "A0A3P7DLM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLM7"
  },
  {
    "uniprot": "A0A3P7EGK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGK4"
  },
  {
    "uniprot": "A0A3P7EVJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVJ3"
  },
  {
    "uniprot": "A0A3P7EKQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKQ2"
  },
  {
    "uniprot": "J9F341",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F341"
  },
  {
    "uniprot": "A0A3P7DKQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKQ6"
  },
  {
    "uniprot": "A0A3P7DV68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV68"
  },
  {
    "uniprot": "A0A183XPH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XPH2"
  },
  {
    "uniprot": "A0A3P7FUY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUY5"
  },
  {
    "uniprot": "A0A3P7E625",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E625"
  },
  {
    "uniprot": "A0A3P7DIU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIU0"
  },
  {
    "uniprot": "A0A3P7EMJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMJ1"
  },
  {
    "uniprot": "A0A3P7EFL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFL9"
  },
  {
    "uniprot": "A0A3P7E2W3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2W3"
  },
  {
    "uniprot": "A0A3P7EFD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFD8"
  },
  {
    "uniprot": "A0A3P7DU15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU15"
  },
  {
    "uniprot": "A0A3P7DSS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSS0"
  },
  {
    "uniprot": "A0A3P7FTU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTU8"
  },
  {
    "uniprot": "A0A3P7FFG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFG5"
  },
  {
    "uniprot": "A0A3P7FBW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBW1"
  },
  {
    "uniprot": "A0A3P7DAP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAP5"
  },
  {
    "uniprot": "A0A3P7ECL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECL2"
  },
  {
    "uniprot": "A0A3P7GD19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GD19"
  },
  {
    "uniprot": "A0A3P7DZZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZZ3"
  },
  {
    "uniprot": "A0A3P7DTY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTY1"
  },
  {
    "uniprot": "A0A3P7FK87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK87"
  },
  {
    "uniprot": "A0A3P7E7W1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7W1"
  },
  {
    "uniprot": "J9FJS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FJS1"
  },
  {
    "uniprot": "A0A3P7G8G6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8G6"
  },
  {
    "uniprot": "A0A3P7DNS7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNS7"
  },
  {
    "uniprot": "A0A3P7EEP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEP5"
  },
  {
    "uniprot": "J9EZP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZP0"
  },
  {
    "uniprot": "A0A3P7G2K1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2K1"
  },
  {
    "uniprot": "A0A3P7E9X0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9X0"
  },
  {
    "uniprot": "A0A3P7EGD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGD7"
  },
  {
    "uniprot": "J9EN92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EN92"
  },
  {
    "uniprot": "A0A3P7DZQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZQ2"
  },
  {
    "uniprot": "A0A3P7EUM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUM2"
  },
  {
    "uniprot": "J9E3T7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E3T7"
  },
  {
    "uniprot": "A0A3P7FPB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPB0"
  },
  {
    "uniprot": "A0A3P7EEB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEB2"
  },
  {
    "uniprot": "A0A3P7G2K8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2K8"
  },
  {
    "uniprot": "A0A3P7FNC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNC5"
  },
  {
    "uniprot": "A0A3P7EH96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH96"
  },
  {
    "uniprot": "A0A183XDK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDK9"
  },
  {
    "uniprot": "J9EK86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EK86"
  },
  {
    "uniprot": "A0A3P7E8E7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8E7"
  },
  {
    "uniprot": "A0A3P7ETT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETT2"
  },
  {
    "uniprot": "A0A3P7FCQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCQ8"
  },
  {
    "uniprot": "A0A3P7EBN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBN0"
  },
  {
    "uniprot": "A0A3P7EAE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAE9"
  },
  {
    "uniprot": "J9EQ71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQ71"
  },
  {
    "uniprot": "A0A3P7DG06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG06"
  },
  {
    "uniprot": "A0A3P7DBI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBI6"
  },
  {
    "uniprot": "A0A3P7DZT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZT7"
  },
  {
    "uniprot": "A0A3P7F3U2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3U2"
  },
  {
    "uniprot": "A0A3P7DBN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBN7"
  },
  {
    "uniprot": "A0A3P7EWV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWV3"
  },
  {
    "uniprot": "A0A3P7ENI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENI1"
  },
  {
    "uniprot": "A0A3P7FV19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV19"
  },
  {
    "uniprot": "J9E6D9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E6D9"
  },
  {
    "uniprot": "A0A3P7DLW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLW0"
  },
  {
    "uniprot": "A0A183Y469",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y469"
  },
  {
    "uniprot": "A0A3P7FRA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRA8"
  },
  {
    "uniprot": "A0A3P7E0Q2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0Q2"
  },
  {
    "uniprot": "A0A3P7FPQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPQ6"
  },
  {
    "uniprot": "A0A3P7DJ72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ72"
  },
  {
    "uniprot": "A0A3P7DZ47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ47"
  },
  {
    "uniprot": "A0A3P7EC01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC01"
  },
  {
    "uniprot": "A0A3P7FQT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQT8"
  },
  {
    "uniprot": "A0A3P7EAT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAT3"
  },
  {
    "uniprot": "A0A3P7GFK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFK4"
  },
  {
    "uniprot": "A0A3P7F8P5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8P5"
  },
  {
    "uniprot": "A0A3P7DNL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNL2"
  },
  {
    "uniprot": "J9FN50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FN50"
  },
  {
    "uniprot": "A0A3P7E2V3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2V3"
  },
  {
    "uniprot": "A0A3P7FYZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYZ5"
  },
  {
    "uniprot": "A0A3P7EQH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQH2"
  },
  {
    "uniprot": "A0A3P7FFA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFA2"
  },
  {
    "uniprot": "A0A3P7EAN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAN6"
  },
  {
    "uniprot": "A0A3P7E9N9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9N9"
  },
  {
    "uniprot": "A0A3P7E6J6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6J6"
  },
  {
    "uniprot": "A0A3P7DIJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIJ4"
  },
  {
    "uniprot": "A0A3P7E159",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E159"
  },
  {
    "uniprot": "A0A3P7DWK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWK1"
  },
  {
    "uniprot": "J9F4T9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F4T9"
  },
  {
    "uniprot": "A0A3P7EC94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC94"
  },
  {
    "uniprot": "A0A3P7DWE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWE9"
  },
  {
    "uniprot": "A0A3P7FBT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBT5"
  },
  {
    "uniprot": "A0A3P7DUQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUQ3"
  },
  {
    "uniprot": "A0A3P7EHF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHF2"
  },
  {
    "uniprot": "A0A3P7GDS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDS3"
  },
  {
    "uniprot": "A0A3P7FDI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDI1"
  },
  {
    "uniprot": "A0A3P7EIR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIR8"
  },
  {
    "uniprot": "A0A3P7DVN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVN4"
  },
  {
    "uniprot": "A0A183XXR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XXR1"
  },
  {
    "uniprot": "A0A3P7DUY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUY6"
  },
  {
    "uniprot": "A0A3P7G3P6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3P6"
  },
  {
    "uniprot": "A0A3P7F7M4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7M4"
  },
  {
    "uniprot": "A0A3P7EEW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEW8"
  },
  {
    "uniprot": "A0A3P7DV88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV88"
  },
  {
    "uniprot": "A0A3P7FLV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLV1"
  },
  {
    "uniprot": "A0A3P7DVH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVH9"
  },
  {
    "uniprot": "A0A3P7E4N8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4N8"
  },
  {
    "uniprot": "A0A3P7FT42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT42"
  },
  {
    "uniprot": "J9AQX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AQX5"
  },
  {
    "uniprot": "A0A3P7D9N6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7D9N6"
  },
  {
    "uniprot": "A0A3P7FPL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPL2"
  },
  {
    "uniprot": "A0A3P7FTI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTI5"
  },
  {
    "uniprot": "A0A3P7FYT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYT4"
  },
  {
    "uniprot": "A0A3P7EGL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGL2"
  },
  {
    "uniprot": "A0A3P7DKH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKH1"
  },
  {
    "uniprot": "A0A3P7EFI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFI2"
  },
  {
    "uniprot": "A0A3P7DGB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGB2"
  },
  {
    "uniprot": "A0A3P7EBU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBU6"
  },
  {
    "uniprot": "A0A3P7FDH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDH9"
  },
  {
    "uniprot": "A0A3P7E6V8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6V8"
  },
  {
    "uniprot": "A0A3P7EHP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHP5"
  },
  {
    "uniprot": "A0A3P7F857",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F857"
  },
  {
    "uniprot": "J9EER1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EER1"
  },
  {
    "uniprot": "A0A3P7EEY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEY3"
  },
  {
    "uniprot": "A0A3P7FIG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIG5"
  },
  {
    "uniprot": "J9B8R3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B8R3"
  },
  {
    "uniprot": "A0A3P7DWI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWI4"
  },
  {
    "uniprot": "A0A3P7DDY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDY7"
  },
  {
    "uniprot": "A0A3P7FM28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM28"
  },
  {
    "uniprot": "A0A3P7FW42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW42"
  },
  {
    "uniprot": "A0A3P7G9W1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9W1"
  },
  {
    "uniprot": "A0A3P7E634",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E634"
  },
  {
    "uniprot": "A0A3P7FTY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTY8"
  },
  {
    "uniprot": "A0A3P7DDS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDS9"
  },
  {
    "uniprot": "A0A3P7FJQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJQ3"
  },
  {
    "uniprot": "A0A3P7EMI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMI6"
  },
  {
    "uniprot": "A0A3P7DVV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVV7"
  },
  {
    "uniprot": "J9EKM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EKM5"
  },
  {
    "uniprot": "A0A3P7GMQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GMQ1"
  },
  {
    "uniprot": "A0A3P7E417",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E417"
  },
  {
    "uniprot": "A0A3P7EBX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBX5"
  },
  {
    "uniprot": "A0A3P7FYJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYJ5"
  },
  {
    "uniprot": "A0A3P7FJ03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ03"
  },
  {
    "uniprot": "J9ALD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ALD4"
  },
  {
    "uniprot": "A0A3P7FNB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNB0"
  },
  {
    "uniprot": "J9FB11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FB11"
  },
  {
    "uniprot": "A0A3P7EIS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIS0"
  },
  {
    "uniprot": "A0A3P7DXV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXV6"
  },
  {
    "uniprot": "J9EY01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EY01"
  },
  {
    "uniprot": "A0A3P7FKX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKX4"
  },
  {
    "uniprot": "A0A3P7DYE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYE8"
  },
  {
    "uniprot": "J9BMY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BMY7"
  },
  {
    "uniprot": "A0A3P7E538",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E538"
  },
  {
    "uniprot": "A0A3P7EH38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH38"
  },
  {
    "uniprot": "A0A3P7DDA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDA6"
  },
  {
    "uniprot": "A0A3P7DZG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZG6"
  },
  {
    "uniprot": "A0A3P7E8W3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8W3"
  },
  {
    "uniprot": "A0A3P7EHV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHV2"
  },
  {
    "uniprot": "A0A3P7E5G2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5G2"
  },
  {
    "uniprot": "A0A3P7EGU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGU7"
  },
  {
    "uniprot": "A0A3P7EUJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUJ6"
  },
  {
    "uniprot": "J9FE20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FE20"
  },
  {
    "uniprot": "A0A3P7E3U9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3U9"
  },
  {
    "uniprot": "A0A3P7FBE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBE1"
  },
  {
    "uniprot": "A0A3P7GCK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCK8"
  },
  {
    "uniprot": "A0A3P7EC00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC00"
  },
  {
    "uniprot": "A0A3P7EPI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPI8"
  },
  {
    "uniprot": "A0A183XN96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XN96"
  },
  {
    "uniprot": "A0A3P7GFE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFE7"
  },
  {
    "uniprot": "A0A3P7DJ22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ22"
  },
  {
    "uniprot": "A0A3P7E7N9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7N9"
  },
  {
    "uniprot": "A0A3P7DWZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWZ4"
  },
  {
    "uniprot": "A0A3P7FYI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYI6"
  },
  {
    "uniprot": "A0A3P7FKE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKE6"
  },
  {
    "uniprot": "A0A3P7EB00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB00"
  },
  {
    "uniprot": "A0A3P7DYB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYB7"
  },
  {
    "uniprot": "A0A3P7FQC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQC6"
  },
  {
    "uniprot": "A0A3P7GGM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGM5"
  },
  {
    "uniprot": "A0A3P7EB87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB87"
  },
  {
    "uniprot": "A0A3P7ENV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENV4"
  },
  {
    "uniprot": "A0A3P7DKV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKV9"
  },
  {
    "uniprot": "A0A3P7DZS4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZS4"
  },
  {
    "uniprot": "A0A3P7E4F5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4F5"
  },
  {
    "uniprot": "A0A3P7DNU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNU5"
  },
  {
    "uniprot": "A0A3P7DAG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAG4"
  },
  {
    "uniprot": "A0A3P7E9M1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9M1"
  },
  {
    "uniprot": "A0A3P7DH01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH01"
  },
  {
    "uniprot": "A0A3P7E8E9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8E9"
  },
  {
    "uniprot": "A0A3P7FS32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS32"
  },
  {
    "uniprot": "A0A183XHB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XHB5"
  },
  {
    "uniprot": "A0A3P7FBI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBI4"
  },
  {
    "uniprot": "A0A3P7DRQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRQ3"
  },
  {
    "uniprot": "A0A3P7EEC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEC3"
  },
  {
    "uniprot": "A0A183Y789",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y789"
  },
  {
    "uniprot": "A0A3P7FC63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC63"
  },
  {
    "uniprot": "A0A3P7EC96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC96"
  },
  {
    "uniprot": "A0A3P7E3A4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3A4"
  },
  {
    "uniprot": "A0A3P7E5L4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5L4"
  },
  {
    "uniprot": "A0A3P7GIQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIQ9"
  },
  {
    "uniprot": "A0A3P7DKJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKJ6"
  },
  {
    "uniprot": "A0A3P7E226",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E226"
  },
  {
    "uniprot": "J9EPZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPZ0"
  },
  {
    "uniprot": "A0A3P7DV90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV90"
  },
  {
    "uniprot": "J9F8M9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F8M9"
  },
  {
    "uniprot": "A0A3P7DN98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN98"
  },
  {
    "uniprot": "A0A3P7E4T1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4T1"
  },
  {
    "uniprot": "J9EG54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EG54"
  },
  {
    "uniprot": "A0A3P7DJS4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJS4"
  },
  {
    "uniprot": "A0A3P7GBK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBK0"
  },
  {
    "uniprot": "A0A3P7FRZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRZ1"
  },
  {
    "uniprot": "A0A3P7F9W8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9W8"
  },
  {
    "uniprot": "A0A3P7FVB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVB3"
  },
  {
    "uniprot": "A0A3P7E6R9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6R9"
  },
  {
    "uniprot": "A0A3P7DMV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMV4"
  },
  {
    "uniprot": "A0A3P7FXI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXI6"
  },
  {
    "uniprot": "A0A3P7GAI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAI4"
  },
  {
    "uniprot": "A0A3P7E4J7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4J7"
  },
  {
    "uniprot": "A0A3P7DWF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWF6"
  },
  {
    "uniprot": "A0A3P7DF42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF42"
  },
  {
    "uniprot": "A0A3P7DJX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJX9"
  },
  {
    "uniprot": "A0A183XY33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XY33"
  },
  {
    "uniprot": "A0A3P7E579",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E579"
  },
  {
    "uniprot": "A0A3P7DJZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJZ8"
  },
  {
    "uniprot": "A0A3P7DEN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEN3"
  },
  {
    "uniprot": "A0A3P7DYW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYW3"
  },
  {
    "uniprot": "A0A3P7E8V7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8V7"
  },
  {
    "uniprot": "A0A3P7DY29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY29"
  },
  {
    "uniprot": "A0A3P7DZB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZB5"
  },
  {
    "uniprot": "A0A3P7E6I5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6I5"
  },
  {
    "uniprot": "J9ET28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ET28"
  },
  {
    "uniprot": "A0A3P7EFT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFT5"
  },
  {
    "uniprot": "A0A3P7ES63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ES63"
  },
  {
    "uniprot": "A0A3P7FLE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLE9"
  },
  {
    "uniprot": "A0A3P7DQZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQZ8"
  },
  {
    "uniprot": "A0A3P7FRF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRF4"
  },
  {
    "uniprot": "A0A3P7DND9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DND9"
  },
  {
    "uniprot": "A0A3P7G2P1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2P1"
  },
  {
    "uniprot": "A0A3P7FN04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN04"
  },
  {
    "uniprot": "J9F418",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F418"
  },
  {
    "uniprot": "A0A3P7EDE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDE9"
  },
  {
    "uniprot": "A0A3P7EFN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFN0"
  },
  {
    "uniprot": "A0A3P7DKN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKN4"
  },
  {
    "uniprot": "A0A3P7GDY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDY3"
  },
  {
    "uniprot": "J9EKZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EKZ4"
  },
  {
    "uniprot": "A0A3P7FPH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPH0"
  },
  {
    "uniprot": "A0A3P7ESJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESJ5"
  },
  {
    "uniprot": "A0A3P7FY59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY59"
  },
  {
    "uniprot": "A0A3P7DZC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZC7"
  },
  {
    "uniprot": "A0A3P7DIE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIE3"
  },
  {
    "uniprot": "A0A3P7E7S8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7S8"
  },
  {
    "uniprot": "A0A3P7DXN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXN8"
  },
  {
    "uniprot": "A0A3P7EEL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEL8"
  },
  {
    "uniprot": "A0A3P7EMZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMZ7"
  },
  {
    "uniprot": "A0A3P7FZH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZH2"
  },
  {
    "uniprot": "A0A3P7ES67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ES67"
  },
  {
    "uniprot": "A0A3P7GEI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEI6"
  },
  {
    "uniprot": "A0A183XU01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XU01"
  },
  {
    "uniprot": "A0A3P7DSB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSB0"
  },
  {
    "uniprot": "A0A3P7EJ86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ86"
  },
  {
    "uniprot": "A0A3P7EME3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EME3"
  },
  {
    "uniprot": "A0A3P7DLL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLL2"
  },
  {
    "uniprot": "A0A3P7EH75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH75"
  },
  {
    "uniprot": "A0A3P7G3D4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3D4"
  },
  {
    "uniprot": "A0A3P7FMZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMZ3"
  },
  {
    "uniprot": "A0A3P7DIL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIL4"
  },
  {
    "uniprot": "A0A3P7EER1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EER1"
  },
  {
    "uniprot": "A0A3P7E2J8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2J8"
  },
  {
    "uniprot": "A0A3P7DVM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVM5"
  },
  {
    "uniprot": "A0A3P7GJ73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJ73"
  },
  {
    "uniprot": "A0A3P7EGT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGT5"
  },
  {
    "uniprot": "A0A3P7F148",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F148"
  },
  {
    "uniprot": "A0A3P7E0C6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0C6"
  },
  {
    "uniprot": "A0A3P7FRA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRA1"
  },
  {
    "uniprot": "A0A3P7EF23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF23"
  },
  {
    "uniprot": "A0A3P7FCM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCM6"
  },
  {
    "uniprot": "A0A3P7E286",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E286"
  },
  {
    "uniprot": "A0A3P7E785",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E785"
  },
  {
    "uniprot": "A0A3P7EKT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKT5"
  },
  {
    "uniprot": "A0A3P7FMW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMW8"
  },
  {
    "uniprot": "A0A3P7E0G1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0G1"
  },
  {
    "uniprot": "A0A3P7GE90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GE90"
  },
  {
    "uniprot": "A0A3P7FFT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFT3"
  },
  {
    "uniprot": "A0A3P7FTM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTM3"
  },
  {
    "uniprot": "J9ELN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ELN0"
  },
  {
    "uniprot": "A0A3P7FXL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXL2"
  },
  {
    "uniprot": "A0A3P7FM15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM15"
  },
  {
    "uniprot": "A0A3P7E0V5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0V5"
  },
  {
    "uniprot": "A0A3P7E6D7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6D7"
  },
  {
    "uniprot": "J9E9A3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E9A3"
  },
  {
    "uniprot": "A0A3P7DF03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF03"
  },
  {
    "uniprot": "A0A3P7DCW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCW7"
  },
  {
    "uniprot": "A0A3P7DUP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUP6"
  },
  {
    "uniprot": "A0A3P7FFR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFR0"
  },
  {
    "uniprot": "A0A3P7FP59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP59"
  },
  {
    "uniprot": "A0A3P7E7A0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7A0"
  },
  {
    "uniprot": "A0A3P7FCT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCT4"
  },
  {
    "uniprot": "A0A3P7FEN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEN9"
  },
  {
    "uniprot": "A0A3P7DWF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWF7"
  },
  {
    "uniprot": "A0A3P7DVS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVS9"
  },
  {
    "uniprot": "A0A3P7EL05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL05"
  },
  {
    "uniprot": "A0A3P7DS90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS90"
  },
  {
    "uniprot": "A0A3P7DZ69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ69"
  },
  {
    "uniprot": "A0A3P7EED3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EED3"
  },
  {
    "uniprot": "A0A3P7EZS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZS6"
  },
  {
    "uniprot": "A0A183XQL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XQL4"
  },
  {
    "uniprot": "A0A3P7EG61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG61"
  },
  {
    "uniprot": "A0A3P7ECN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECN3"
  },
  {
    "uniprot": "A0A3P7EI98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI98"
  },
  {
    "uniprot": "A0A3P7DSE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSE2"
  },
  {
    "uniprot": "A0A3P7DTB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTB8"
  },
  {
    "uniprot": "A0A183YC14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183YC14"
  },
  {
    "uniprot": "A0A3P7DLD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLD6"
  },
  {
    "uniprot": "A0A3P7EGY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGY4"
  },
  {
    "uniprot": "A0A3P7E237",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E237"
  },
  {
    "uniprot": "A0A3P7E0G3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0G3"
  },
  {
    "uniprot": "A0A3P7EAB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAB6"
  },
  {
    "uniprot": "A0A3P7FHG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHG2"
  },
  {
    "uniprot": "A0A3P7E064",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E064"
  },
  {
    "uniprot": "A0A3P7GCA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCA3"
  },
  {
    "uniprot": "J9F3S8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F3S8"
  },
  {
    "uniprot": "A0A3P7DAD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAD2"
  },
  {
    "uniprot": "A0A3P7DT82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT82"
  },
  {
    "uniprot": "J9FA36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FA36"
  },
  {
    "uniprot": "A0A3P7G8L4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8L4"
  },
  {
    "uniprot": "A0A3P7DP77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP77"
  },
  {
    "uniprot": "A0A3P7E052",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E052"
  },
  {
    "uniprot": "A0A3P7GF88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GF88"
  },
  {
    "uniprot": "A0A3P7DTC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTC3"
  },
  {
    "uniprot": "A0A3P7DMP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMP6"
  },
  {
    "uniprot": "A0A3P7DYS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYS3"
  },
  {
    "uniprot": "A0A3P7G479",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G479"
  },
  {
    "uniprot": "A0A3P7FVQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVQ4"
  },
  {
    "uniprot": "A0A3P7DZQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZQ5"
  },
  {
    "uniprot": "A0A3P7E8E5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8E5"
  },
  {
    "uniprot": "A0A3P7DD57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD57"
  },
  {
    "uniprot": "J9EXB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXB6"
  },
  {
    "uniprot": "A0A3P7E0R7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0R7"
  },
  {
    "uniprot": "A0A3P7DN20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN20"
  },
  {
    "uniprot": "J9EJ54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EJ54"
  },
  {
    "uniprot": "A0A3P7DKY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKY9"
  },
  {
    "uniprot": "A0A3P7G148",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G148"
  },
  {
    "uniprot": "J9ES05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ES05"
  },
  {
    "uniprot": "A0A3P7E1K4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1K4"
  },
  {
    "uniprot": "A0A3P7EJ61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ61"
  },
  {
    "uniprot": "A0A3P7E9C7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9C7"
  },
  {
    "uniprot": "A0A3P7EFP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFP1"
  },
  {
    "uniprot": "A0A3P7DQG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQG1"
  },
  {
    "uniprot": "A0A3P7FT38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT38"
  },
  {
    "uniprot": "A0A3P7DWT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWT6"
  },
  {
    "uniprot": "A0A3P7E8X3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8X3"
  },
  {
    "uniprot": "A0A3P7EIN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIN5"
  },
  {
    "uniprot": "A0A3P7DI58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI58"
  },
  {
    "uniprot": "A0A183XLU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLU6"
  },
  {
    "uniprot": "A0A3P7EE74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE74"
  },
  {
    "uniprot": "A0A3P7DSM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSM7"
  },
  {
    "uniprot": "A0A3P7DXS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXS9"
  },
  {
    "uniprot": "A0A3P7DV73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV73"
  },
  {
    "uniprot": "A0A3P7DTF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTF2"
  },
  {
    "uniprot": "A0A3P7FXQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXQ2"
  },
  {
    "uniprot": "J9F3F9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F3F9"
  },
  {
    "uniprot": "A0A3P7EBY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBY5"
  },
  {
    "uniprot": "A0A183Y704",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y704"
  },
  {
    "uniprot": "A0A3P7DUS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUS0"
  },
  {
    "uniprot": "A0A3P7DMW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMW7"
  },
  {
    "uniprot": "A0A3P7GAN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAN2"
  },
  {
    "uniprot": "A0A3P7E5Z7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5Z7"
  },
  {
    "uniprot": "A0A3P7DTY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTY2"
  },
  {
    "uniprot": "A0A3P7FPS7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPS7"
  },
  {
    "uniprot": "A0A3P7EP61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP61"
  },
  {
    "uniprot": "J9EBA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EBA1"
  },
  {
    "uniprot": "J9F1X3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1X3"
  },
  {
    "uniprot": "A0A3P7DRI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRI7"
  },
  {
    "uniprot": "A0A3P7DT49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT49"
  },
  {
    "uniprot": "A0A3P7FYZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYZ2"
  },
  {
    "uniprot": "A0A3P7GH52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GH52"
  },
  {
    "uniprot": "A0A3P7E5T9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5T9"
  },
  {
    "uniprot": "A0A3P7E4Z1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4Z1"
  },
  {
    "uniprot": "A0A3P7DYF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYF9"
  },
  {
    "uniprot": "A0A3P7EEN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEN3"
  },
  {
    "uniprot": "A0A3P7FTR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTR3"
  },
  {
    "uniprot": "A0A3P7DZL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZL3"
  },
  {
    "uniprot": "A0A3P7FEF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEF7"
  },
  {
    "uniprot": "A0A3P7EDZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDZ5"
  },
  {
    "uniprot": "A0A3P7GJT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJT6"
  },
  {
    "uniprot": "A0A3P7DIL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIL3"
  },
  {
    "uniprot": "A0A3P7DTS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTS5"
  },
  {
    "uniprot": "A0A3P7E1V3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1V3"
  },
  {
    "uniprot": "A0A3P7ED63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED63"
  },
  {
    "uniprot": "J9FA17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FA17"
  },
  {
    "uniprot": "A0A3P7FHJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHJ2"
  },
  {
    "uniprot": "J9F9N6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F9N6"
  },
  {
    "uniprot": "A0A3P7FJL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJL9"
  },
  {
    "uniprot": "A0A3P7GMD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GMD3"
  },
  {
    "uniprot": "A0A3P7ER61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ER61"
  },
  {
    "uniprot": "A0A3P7FC60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC60"
  },
  {
    "uniprot": "A0A3P7DGV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGV4"
  },
  {
    "uniprot": "A0A3P7FAJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAJ2"
  },
  {
    "uniprot": "A0A3P7E8N3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8N3"
  },
  {
    "uniprot": "A0A3P7DKA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKA0"
  },
  {
    "uniprot": "A0A3P7EIM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIM3"
  },
  {
    "uniprot": "A0A3P7E566",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E566"
  },
  {
    "uniprot": "A0A3P7FSL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSL0"
  },
  {
    "uniprot": "A0A3P7DQW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQW0"
  },
  {
    "uniprot": "A0A3P7EDW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDW1"
  },
  {
    "uniprot": "A0A3P7DQ84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ84"
  },
  {
    "uniprot": "A0A3P7E1J5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1J5"
  },
  {
    "uniprot": "A0A3P7E1W2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1W2"
  },
  {
    "uniprot": "A0A3P7GDW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDW2"
  },
  {
    "uniprot": "A0A183Y578",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y578"
  },
  {
    "uniprot": "J9FED9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FED9"
  },
  {
    "uniprot": "A0A3P7FRP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRP2"
  },
  {
    "uniprot": "J9AA84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AA84"
  },
  {
    "uniprot": "J9ELP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ELP5"
  },
  {
    "uniprot": "A0A3P7E203",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E203"
  },
  {
    "uniprot": "A0A3P7EBP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBP7"
  },
  {
    "uniprot": "A0A3P7GIS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIS8"
  },
  {
    "uniprot": "J9F197",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F197"
  },
  {
    "uniprot": "J9EDJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EDJ6"
  },
  {
    "uniprot": "A0A3P7EGU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGU2"
  },
  {
    "uniprot": "A0A3P7DCH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCH1"
  },
  {
    "uniprot": "A0A3P7FBC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBC7"
  },
  {
    "uniprot": "A0A3P7EHV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHV6"
  },
  {
    "uniprot": "A0A3P7DS37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS37"
  },
  {
    "uniprot": "J9EN87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EN87"
  },
  {
    "uniprot": "A0A3P7E8N9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8N9"
  },
  {
    "uniprot": "A0A3P7GKP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKP2"
  },
  {
    "uniprot": "J9E4P3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E4P3"
  },
  {
    "uniprot": "A0A3P7FG55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG55"
  },
  {
    "uniprot": "A0A3P7DYU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYU7"
  },
  {
    "uniprot": "A0A3P7DUC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUC8"
  },
  {
    "uniprot": "A0A3P7G024",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G024"
  },
  {
    "uniprot": "A0A3P7DVW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVW3"
  },
  {
    "uniprot": "A0A3P7FJT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJT7"
  },
  {
    "uniprot": "A0A3P7FJB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJB7"
  },
  {
    "uniprot": "A0A3P7ES69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ES69"
  },
  {
    "uniprot": "A0A3P7EHC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHC2"
  },
  {
    "uniprot": "A0A3P7FD40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD40"
  },
  {
    "uniprot": "A0A3P7GKK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKK8"
  },
  {
    "uniprot": "A0A3P7EPQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPQ6"
  },
  {
    "uniprot": "A0A3P7DDB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDB1"
  },
  {
    "uniprot": "A0A3P7E8V9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8V9"
  },
  {
    "uniprot": "A0A3P7DCF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCF3"
  },
  {
    "uniprot": "A0A3P7FYF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYF9"
  },
  {
    "uniprot": "A0A3P7DWK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWK8"
  },
  {
    "uniprot": "A0A3P7FXV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXV6"
  },
  {
    "uniprot": "A0A3P7DQC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQC9"
  },
  {
    "uniprot": "A0A3P7FGD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGD2"
  },
  {
    "uniprot": "A0A3P7DQH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQH2"
  },
  {
    "uniprot": "J9E821",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E821"
  },
  {
    "uniprot": "A0A3P7EL73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL73"
  },
  {
    "uniprot": "A0A3P7GJR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJR5"
  },
  {
    "uniprot": "A0A3P7FTM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTM0"
  },
  {
    "uniprot": "A0A3P7EC38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC38"
  },
  {
    "uniprot": "A0A3P7FNE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNE7"
  },
  {
    "uniprot": "A0A3P7EFS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFS3"
  },
  {
    "uniprot": "A0A3P7EAU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAU1"
  },
  {
    "uniprot": "A0A3P7FT49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT49"
  },
  {
    "uniprot": "A0A3P7E8X7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8X7"
  },
  {
    "uniprot": "A0A183XMX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XMX9"
  },
  {
    "uniprot": "A0A3P7EGC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGC1"
  },
  {
    "uniprot": "A0A3P7EPN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPN6"
  },
  {
    "uniprot": "A0A3P7EK05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK05"
  },
  {
    "uniprot": "A0A3P7EFD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFD1"
  },
  {
    "uniprot": "A0A3P7DWS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWS5"
  },
  {
    "uniprot": "A0A3P7FTL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTL6"
  },
  {
    "uniprot": "A0A3P7ECX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECX2"
  },
  {
    "uniprot": "A0A183XMC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XMC8"
  },
  {
    "uniprot": "A0A3P7E758",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E758"
  },
  {
    "uniprot": "A0A3P7DJ33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ33"
  },
  {
    "uniprot": "J9B546",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B546"
  },
  {
    "uniprot": "A0A3P7E6Y1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6Y1"
  },
  {
    "uniprot": "A0A3P7DQR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQR4"
  },
  {
    "uniprot": "A0A3P7EJM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJM9"
  },
  {
    "uniprot": "A0A3P7DR75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR75"
  },
  {
    "uniprot": "A0A3P7ED18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED18"
  },
  {
    "uniprot": "A0A3P7DS51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS51"
  },
  {
    "uniprot": "A0A3P7DTI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTI4"
  },
  {
    "uniprot": "A0A3P7EMB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMB6"
  },
  {
    "uniprot": "A0A183XZ44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XZ44"
  },
  {
    "uniprot": "A0A3P7EPW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPW3"
  },
  {
    "uniprot": "A0A3P7E919",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E919"
  },
  {
    "uniprot": "A0A3P7FEW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEW4"
  },
  {
    "uniprot": "A0A183Y671",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y671"
  },
  {
    "uniprot": "A0A3P7DYN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYN6"
  },
  {
    "uniprot": "A0A3P7DTX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTX6"
  },
  {
    "uniprot": "A0A3P7FGW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGW2"
  },
  {
    "uniprot": "A0A3P7GI75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GI75"
  },
  {
    "uniprot": "A0A183XTT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XTT8"
  },
  {
    "uniprot": "A0A3P7DKJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKJ7"
  },
  {
    "uniprot": "A0A3P7EHQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHQ4"
  },
  {
    "uniprot": "J9FEN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FEN6"
  },
  {
    "uniprot": "A0A3P7EXE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXE3"
  },
  {
    "uniprot": "A0A3P7ENM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENM5"
  },
  {
    "uniprot": "A0A3P7FBX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBX1"
  },
  {
    "uniprot": "A0A3P7DWD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWD2"
  },
  {
    "uniprot": "A0A3P7FHX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHX8"
  },
  {
    "uniprot": "A0A183XUV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XUV0"
  },
  {
    "uniprot": "A0A3P7FYB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYB4"
  },
  {
    "uniprot": "A0A3P7E719",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E719"
  },
  {
    "uniprot": "A0A3P7DDG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDG5"
  },
  {
    "uniprot": "A0A3P7F479",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F479"
  },
  {
    "uniprot": "A0A3P7EVE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVE1"
  },
  {
    "uniprot": "A0A3P7DJ63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ63"
  },
  {
    "uniprot": "A0A3P7FRG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRG5"
  },
  {
    "uniprot": "J9EP64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EP64"
  },
  {
    "uniprot": "A0A3P7DV09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV09"
  },
  {
    "uniprot": "A0A3P7GK24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GK24"
  },
  {
    "uniprot": "J9F981",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F981"
  },
  {
    "uniprot": "A0A3P7EBR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBR0"
  },
  {
    "uniprot": "A0A3P7F1P3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1P3"
  },
  {
    "uniprot": "A0A3P7G8N8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8N8"
  },
  {
    "uniprot": "A0A3P7FG32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG32"
  },
  {
    "uniprot": "A0A3P7FST7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FST7"
  },
  {
    "uniprot": "A0A3P7E5M8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5M8"
  },
  {
    "uniprot": "A0A3P7G2D2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2D2"
  },
  {
    "uniprot": "A0A3P7FCU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCU2"
  },
  {
    "uniprot": "J9BJN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BJN9"
  },
  {
    "uniprot": "A0A3P7EN98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN98"
  },
  {
    "uniprot": "A0A3P7EDU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDU9"
  },
  {
    "uniprot": "A0A3P7E6B2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6B2"
  },
  {
    "uniprot": "A0A3P7E4X7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4X7"
  },
  {
    "uniprot": "A0A3P7DV31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV31"
  },
  {
    "uniprot": "A0A3P7EBJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBJ0"
  },
  {
    "uniprot": "J9E9T5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E9T5"
  },
  {
    "uniprot": "A0A3P7DUM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUM9"
  },
  {
    "uniprot": "A0A3P7DQE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQE5"
  },
  {
    "uniprot": "A0A3P7E4T0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4T0"
  },
  {
    "uniprot": "J9DL65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DL65"
  },
  {
    "uniprot": "A0A3P7F7B6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7B6"
  },
  {
    "uniprot": "A0A3P7F8R0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8R0"
  },
  {
    "uniprot": "J9E9Q1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E9Q1"
  },
  {
    "uniprot": "A0A3P7DY27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY27"
  },
  {
    "uniprot": "A0A3P7DWF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWF0"
  },
  {
    "uniprot": "A0A3P7DHJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHJ0"
  },
  {
    "uniprot": "A0A3P7F1Y1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1Y1"
  },
  {
    "uniprot": "A0A3P7DZA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZA7"
  },
  {
    "uniprot": "A0A3P7DMR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMR1"
  },
  {
    "uniprot": "A0A3P7EVB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVB5"
  },
  {
    "uniprot": "A0A3P7FU11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU11"
  },
  {
    "uniprot": "A0A3P7DU64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU64"
  },
  {
    "uniprot": "A0A3P7E3T1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3T1"
  },
  {
    "uniprot": "A0A3P7E8Z4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8Z4"
  },
  {
    "uniprot": "A0A3P7DRH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRH1"
  },
  {
    "uniprot": "A0A3P7DS38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS38"
  },
  {
    "uniprot": "A0A3P7ERW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERW2"
  },
  {
    "uniprot": "A0A3P7GG92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GG92"
  },
  {
    "uniprot": "A0A3P7EAG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAG5"
  },
  {
    "uniprot": "A0A3P7FFY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFY8"
  },
  {
    "uniprot": "A0A3P7DVG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVG8"
  },
  {
    "uniprot": "A0A3P7EA99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA99"
  },
  {
    "uniprot": "A0A3P7ESG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESG0"
  },
  {
    "uniprot": "A0A3P7DM62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM62"
  },
  {
    "uniprot": "A0A3P7EVR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVR8"
  },
  {
    "uniprot": "J9BDR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BDR7"
  },
  {
    "uniprot": "A0A3P7FPU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPU2"
  },
  {
    "uniprot": "A0A3P7FJT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJT3"
  },
  {
    "uniprot": "A0A3P7FVL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVL4"
  },
  {
    "uniprot": "A0A3P7FBT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBT7"
  },
  {
    "uniprot": "A0A3P7DU31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU31"
  },
  {
    "uniprot": "A0A3P7DQ73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ73"
  },
  {
    "uniprot": "A0A3P7EZR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZR5"
  },
  {
    "uniprot": "A0A3P7DYX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYX4"
  },
  {
    "uniprot": "A0A3P7DRP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRP0"
  },
  {
    "uniprot": "A0A3P7DWC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWC4"
  },
  {
    "uniprot": "A0A3P7GE68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GE68"
  },
  {
    "uniprot": "A0A3P7E7A4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7A4"
  },
  {
    "uniprot": "A0A3P7ELH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELH1"
  },
  {
    "uniprot": "A0A3P7G8A5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8A5"
  },
  {
    "uniprot": "A0A183XEZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XEZ9"
  },
  {
    "uniprot": "A0A3P7E9S0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9S0"
  },
  {
    "uniprot": "A0A3P7GI40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GI40"
  },
  {
    "uniprot": "A0A3P7FV30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV30"
  },
  {
    "uniprot": "A0A3P7ELM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELM3"
  },
  {
    "uniprot": "A0A3P7GET5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GET5"
  },
  {
    "uniprot": "A0A3P7EEL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEL5"
  },
  {
    "uniprot": "A0A3P7D9R4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7D9R4"
  },
  {
    "uniprot": "A0A3P7EUT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUT4"
  },
  {
    "uniprot": "A0A3P7EHR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHR6"
  },
  {
    "uniprot": "A0A3P7DWW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWW5"
  },
  {
    "uniprot": "A0A3P7DCV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCV7"
  },
  {
    "uniprot": "A0A3P7E401",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E401"
  },
  {
    "uniprot": "A0A3P7ENX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENX1"
  },
  {
    "uniprot": "A0A3P7G229",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G229"
  },
  {
    "uniprot": "A0A3P7EPE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPE6"
  },
  {
    "uniprot": "A0A3P7DNK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNK1"
  },
  {
    "uniprot": "A0A3P7E754",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E754"
  },
  {
    "uniprot": "A0A3P7EQH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQH4"
  },
  {
    "uniprot": "A0A3P7DRX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRX1"
  },
  {
    "uniprot": "A0A3P7DVG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVG2"
  },
  {
    "uniprot": "A0A3P7ECU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECU9"
  },
  {
    "uniprot": "A0A3P7DSF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSF9"
  },
  {
    "uniprot": "A0A3P7DU69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU69"
  },
  {
    "uniprot": "A0A3P7E7U1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7U1"
  },
  {
    "uniprot": "A0A3P7FA64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FA64"
  },
  {
    "uniprot": "A0A3P7G8E7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8E7"
  },
  {
    "uniprot": "A0A3P7E8X8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8X8"
  },
  {
    "uniprot": "A0A3P7DMC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMC9"
  },
  {
    "uniprot": "A0A3P7FUC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUC6"
  },
  {
    "uniprot": "A0A3P7DXT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXT6"
  },
  {
    "uniprot": "A0A3P7FY36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY36"
  },
  {
    "uniprot": "A0A3P7FNB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNB3"
  },
  {
    "uniprot": "A0A3P7EGD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGD4"
  },
  {
    "uniprot": "A0A3P7GGV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGV5"
  },
  {
    "uniprot": "A0A3P7GHI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHI3"
  },
  {
    "uniprot": "J9A8K3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9A8K3"
  },
  {
    "uniprot": "A0A3P7F812",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F812"
  },
  {
    "uniprot": "A0A3P7DNP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNP9"
  },
  {
    "uniprot": "A0A3P7FM36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM36"
  },
  {
    "uniprot": "A0A3P7F3F5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3F5"
  },
  {
    "uniprot": "A0A3P7FQU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQU6"
  },
  {
    "uniprot": "A0A3P7DVL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVL8"
  },
  {
    "uniprot": "A0A3P7DZM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZM2"
  },
  {
    "uniprot": "A0A3P7DT55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT55"
  },
  {
    "uniprot": "A0A3P7EBC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBC5"
  },
  {
    "uniprot": "A0A3P7EF65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF65"
  },
  {
    "uniprot": "A0A3P7DB06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DB06"
  },
  {
    "uniprot": "A0A3P7E0Z2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0Z2"
  },
  {
    "uniprot": "A0A3P7FRL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRL3"
  },
  {
    "uniprot": "A0A3P7EEJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEJ9"
  },
  {
    "uniprot": "A0A3P7DWK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWK7"
  },
  {
    "uniprot": "A0A3P7DWE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWE7"
  },
  {
    "uniprot": "A0A3P7DGN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGN0"
  },
  {
    "uniprot": "A0A3P7E7J5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7J5"
  },
  {
    "uniprot": "A0A3P7EA12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA12"
  },
  {
    "uniprot": "A0A3P7G6Y9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6Y9"
  },
  {
    "uniprot": "A0A3P7GBA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBA9"
  },
  {
    "uniprot": "A0A3P7GFQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFQ7"
  },
  {
    "uniprot": "A0A3P7FBM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBM2"
  },
  {
    "uniprot": "A0A3P7EGD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGD1"
  },
  {
    "uniprot": "A0A3P7GJM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJM7"
  },
  {
    "uniprot": "J9E0R7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E0R7"
  },
  {
    "uniprot": "A0A3P7DHQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHQ7"
  },
  {
    "uniprot": "A0A3P7DJC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJC2"
  },
  {
    "uniprot": "A0A3P7ECY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECY0"
  },
  {
    "uniprot": "A0A3P7FXW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXW4"
  },
  {
    "uniprot": "A0A3P7EAT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAT4"
  },
  {
    "uniprot": "A0A183XKK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XKK0"
  },
  {
    "uniprot": "A0A3P7FB41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB41"
  },
  {
    "uniprot": "A0A3P7EAB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAB5"
  },
  {
    "uniprot": "A0A3P7DVR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVR9"
  },
  {
    "uniprot": "J9DRB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DRB8"
  },
  {
    "uniprot": "A0A3P7EMC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMC1"
  },
  {
    "uniprot": "A0A3P7DFR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFR4"
  },
  {
    "uniprot": "A0A3P7E973",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E973"
  },
  {
    "uniprot": "A0A3P7DCW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCW0"
  },
  {
    "uniprot": "A0A3P7EAY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAY8"
  },
  {
    "uniprot": "A0A3P7FP75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP75"
  },
  {
    "uniprot": "A0A3P7DRZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRZ1"
  },
  {
    "uniprot": "A0A3P7DWN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWN1"
  },
  {
    "uniprot": "A0A3P7FRH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRH4"
  },
  {
    "uniprot": "A0A3P7FML4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FML4"
  },
  {
    "uniprot": "A0A3P7FTN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTN1"
  },
  {
    "uniprot": "A0A3P7DUJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUJ4"
  },
  {
    "uniprot": "J9FE53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FE53"
  },
  {
    "uniprot": "A0A3P7EGW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGW2"
  },
  {
    "uniprot": "A0A3P7DVV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVV3"
  },
  {
    "uniprot": "A0A3P7EM03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM03"
  },
  {
    "uniprot": "A0A3P7FE83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE83"
  },
  {
    "uniprot": "A0A3P7EYW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYW0"
  },
  {
    "uniprot": "A0A3P7DTF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTF4"
  },
  {
    "uniprot": "A0A3P7FLA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLA4"
  },
  {
    "uniprot": "A0A3P7FYK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYK6"
  },
  {
    "uniprot": "A0A3P7F9M4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9M4"
  },
  {
    "uniprot": "A0A3P7E5Y9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5Y9"
  },
  {
    "uniprot": "A0A3P7E2T0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2T0"
  },
  {
    "uniprot": "A0A3P7E3E5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3E5"
  },
  {
    "uniprot": "A0A3P7DUF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUF2"
  },
  {
    "uniprot": "A0A3P7DU46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU46"
  },
  {
    "uniprot": "A0A3P7E879",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E879"
  },
  {
    "uniprot": "A0A3P7G675",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G675"
  },
  {
    "uniprot": "A0A3P7DG37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG37"
  },
  {
    "uniprot": "A0A3P7EBI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBI0"
  },
  {
    "uniprot": "A0A3P7E9Y8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9Y8"
  },
  {
    "uniprot": "A0A3P7E467",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E467"
  },
  {
    "uniprot": "J9FEF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FEF7"
  },
  {
    "uniprot": "A0A3P7DMD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMD7"
  },
  {
    "uniprot": "A0A3P7FZZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZZ7"
  },
  {
    "uniprot": "A0A3P7DII9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DII9"
  },
  {
    "uniprot": "A0A183XS13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XS13"
  },
  {
    "uniprot": "A0A3P7FRG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRG3"
  },
  {
    "uniprot": "A0A3P7ET37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET37"
  },
  {
    "uniprot": "J9F2M5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2M5"
  },
  {
    "uniprot": "A0A3P7FNY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNY1"
  },
  {
    "uniprot": "A0A3P7FE40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE40"
  },
  {
    "uniprot": "A0A3P7FEP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEP8"
  },
  {
    "uniprot": "A0A3P7ED88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED88"
  },
  {
    "uniprot": "A0A3P7DM41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM41"
  },
  {
    "uniprot": "A0A3P7DYB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYB0"
  },
  {
    "uniprot": "A0A3P7DXX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXX8"
  },
  {
    "uniprot": "A0A3P7FQP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQP8"
  },
  {
    "uniprot": "A0A3P7FI20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI20"
  },
  {
    "uniprot": "A0A3P7DNA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNA9"
  },
  {
    "uniprot": "A0A3P7DZK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZK9"
  },
  {
    "uniprot": "J9FBZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FBZ3"
  },
  {
    "uniprot": "A0A3P7EBC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBC1"
  },
  {
    "uniprot": "A0A3P7FA82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FA82"
  },
  {
    "uniprot": "A0A3P7FP40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP40"
  },
  {
    "uniprot": "A0A3P7EEC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEC7"
  },
  {
    "uniprot": "A0A3P7EGH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGH7"
  },
  {
    "uniprot": "A0A3P7DEZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEZ4"
  },
  {
    "uniprot": "A0A3P7DZQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZQ3"
  },
  {
    "uniprot": "A0A3P7FFS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFS8"
  },
  {
    "uniprot": "A0A3P7EGQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGQ8"
  },
  {
    "uniprot": "A0A3P7DZX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZX2"
  },
  {
    "uniprot": "A0A3P7EG95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG95"
  },
  {
    "uniprot": "A0A3P7GFP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFP6"
  },
  {
    "uniprot": "A0A3P7DTR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTR5"
  },
  {
    "uniprot": "A0A3P7E521",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E521"
  },
  {
    "uniprot": "A0A3P7FNY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNY6"
  },
  {
    "uniprot": "A0A3P7DJS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJS9"
  },
  {
    "uniprot": "A0A3P7FXG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXG5"
  },
  {
    "uniprot": "A0A3P7FFC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFC9"
  },
  {
    "uniprot": "A0A3P7EPL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPL6"
  },
  {
    "uniprot": "A0A3P7DRE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRE7"
  },
  {
    "uniprot": "A0A3P7F838",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F838"
  },
  {
    "uniprot": "A0A3P7GJR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJR3"
  },
  {
    "uniprot": "A0A3P7FAT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAT2"
  },
  {
    "uniprot": "J9F7Z3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F7Z3"
  },
  {
    "uniprot": "A0A3P7DZ31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ31"
  },
  {
    "uniprot": "A0A3P7EKV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKV1"
  },
  {
    "uniprot": "A0A3P7FMQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMQ7"
  },
  {
    "uniprot": "A0A3P7E0H3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0H3"
  },
  {
    "uniprot": "A0A3P7EY37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY37"
  },
  {
    "uniprot": "A0A3P7EJP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJP8"
  },
  {
    "uniprot": "J9B5N5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B5N5"
  },
  {
    "uniprot": "A0A3P7EVK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVK4"
  },
  {
    "uniprot": "A0A3P7E7B6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7B6"
  },
  {
    "uniprot": "A0A3P7EAM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAM8"
  },
  {
    "uniprot": "A0A3P7E9L0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9L0"
  },
  {
    "uniprot": "A0A3P7EDM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDM9"
  },
  {
    "uniprot": "A0A3P7E983",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E983"
  },
  {
    "uniprot": "J9F373",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F373"
  },
  {
    "uniprot": "A0A3P7DUT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUT8"
  },
  {
    "uniprot": "A0A3P7DH52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH52"
  },
  {
    "uniprot": "A0A3P7FV09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV09"
  },
  {
    "uniprot": "A0A3P7FW49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW49"
  },
  {
    "uniprot": "A0A3P7EB74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB74"
  },
  {
    "uniprot": "A0A3P7E4Y7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4Y7"
  },
  {
    "uniprot": "A0A3P7EU37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU37"
  },
  {
    "uniprot": "J9FBE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FBE4"
  },
  {
    "uniprot": "A0A3P7FE63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE63"
  },
  {
    "uniprot": "A0A3P7DXI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXI0"
  },
  {
    "uniprot": "A0A3P7EBS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBS9"
  },
  {
    "uniprot": "A0A3P7ELV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELV7"
  },
  {
    "uniprot": "A0A3P7DUE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUE7"
  },
  {
    "uniprot": "A0A3P7DEY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEY6"
  },
  {
    "uniprot": "A0A3P7FRQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRQ0"
  },
  {
    "uniprot": "A0A3P7DSN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSN1"
  },
  {
    "uniprot": "A0A3P7DQ39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ39"
  },
  {
    "uniprot": "A0A3P7EF82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF82"
  },
  {
    "uniprot": "A0A3P7ETW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETW8"
  },
  {
    "uniprot": "A0A3P7DKX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKX1"
  },
  {
    "uniprot": "A0A3P7E9P2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9P2"
  },
  {
    "uniprot": "A0A3P7E1A8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1A8"
  },
  {
    "uniprot": "A0A3P7FWG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWG9"
  },
  {
    "uniprot": "A0A3P7DDQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDQ3"
  },
  {
    "uniprot": "A0A3P7E845",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E845"
  },
  {
    "uniprot": "J9EFY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EFY1"
  },
  {
    "uniprot": "A0A3P7FTH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTH8"
  },
  {
    "uniprot": "A0A3P7EM82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM82"
  },
  {
    "uniprot": "A0A3P7FS92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS92"
  },
  {
    "uniprot": "J9BLN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BLN3"
  },
  {
    "uniprot": "A0A3P7E7X0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7X0"
  },
  {
    "uniprot": "A0A3P7DX40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX40"
  },
  {
    "uniprot": "A0A3P7DYN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYN3"
  },
  {
    "uniprot": "A0A3P7DMR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMR7"
  },
  {
    "uniprot": "A0A3P7EJL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJL4"
  },
  {
    "uniprot": "A0A3P7ETJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETJ9"
  },
  {
    "uniprot": "A0A3P7EDM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDM0"
  },
  {
    "uniprot": "A0A3P7ELQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELQ9"
  },
  {
    "uniprot": "A0A3P7DYJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYJ4"
  },
  {
    "uniprot": "A0A3P7FEM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEM8"
  },
  {
    "uniprot": "A0A3P7G5H4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5H4"
  },
  {
    "uniprot": "A0A3P7DP24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP24"
  },
  {
    "uniprot": "A0A3P7EKB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKB7"
  },
  {
    "uniprot": "A0A3P7EBZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBZ0"
  },
  {
    "uniprot": "A0A3P7DSW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSW9"
  },
  {
    "uniprot": "J9ELR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ELR2"
  },
  {
    "uniprot": "A0A3P7EL25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL25"
  },
  {
    "uniprot": "J9FMP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FMP6"
  },
  {
    "uniprot": "A0A3P7G1E9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1E9"
  },
  {
    "uniprot": "J9EUN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUN0"
  },
  {
    "uniprot": "J9B0R8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B0R8"
  },
  {
    "uniprot": "A0A3P7FRA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRA9"
  },
  {
    "uniprot": "A0A3P7DT10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT10"
  },
  {
    "uniprot": "A0A3P7E1J2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1J2"
  },
  {
    "uniprot": "A0A183XKT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XKT3"
  },
  {
    "uniprot": "A0A3P7FSU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSU6"
  },
  {
    "uniprot": "A0A3P7DCN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCN2"
  },
  {
    "uniprot": "A0A3P7DNM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNM6"
  },
  {
    "uniprot": "A0A3P7EG58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG58"
  },
  {
    "uniprot": "A0A3P7FKS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKS1"
  },
  {
    "uniprot": "J9B1Y5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B1Y5"
  },
  {
    "uniprot": "A0A3P7F9Y8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9Y8"
  },
  {
    "uniprot": "A0A3P7FXG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXG7"
  },
  {
    "uniprot": "A0A3P7DRN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRN1"
  },
  {
    "uniprot": "A0A3P7E8A3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8A3"
  },
  {
    "uniprot": "A0A3P7FC77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC77"
  },
  {
    "uniprot": "A0A3P7E4L7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4L7"
  },
  {
    "uniprot": "A0A3P7DKJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKJ4"
  },
  {
    "uniprot": "A0A3P7G2F5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2F5"
  },
  {
    "uniprot": "A0A3P7DCY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCY3"
  },
  {
    "uniprot": "A0A3P7DL95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL95"
  },
  {
    "uniprot": "A0A3P7FDB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDB3"
  },
  {
    "uniprot": "A0A3P7GC44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GC44"
  },
  {
    "uniprot": "A0A3P7EQD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQD9"
  },
  {
    "uniprot": "A0A3P7FN27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN27"
  },
  {
    "uniprot": "A0A3P7FKS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKS8"
  },
  {
    "uniprot": "A0A3P7DX85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX85"
  },
  {
    "uniprot": "A0A3P7DZC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZC5"
  },
  {
    "uniprot": "A0A3P7DUZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUZ2"
  },
  {
    "uniprot": "A0A3P7F256",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F256"
  },
  {
    "uniprot": "A0A3P7DSZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSZ0"
  },
  {
    "uniprot": "A0A3P7E883",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E883"
  },
  {
    "uniprot": "A0A3P7FVG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVG5"
  },
  {
    "uniprot": "A0A3P7FF14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF14"
  },
  {
    "uniprot": "A0A3P7EAP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAP5"
  },
  {
    "uniprot": "A0A3P7F3J9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3J9"
  },
  {
    "uniprot": "A0A3P7DU11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU11"
  },
  {
    "uniprot": "A0A3P7DS11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS11"
  },
  {
    "uniprot": "A0A3P7FVP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVP7"
  },
  {
    "uniprot": "A0A183XXV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XXV9"
  },
  {
    "uniprot": "J9ENI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ENI9"
  },
  {
    "uniprot": "A0A3P7FNI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNI0"
  },
  {
    "uniprot": "A0A3P7DXL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXL1"
  },
  {
    "uniprot": "A0A3P7E021",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E021"
  },
  {
    "uniprot": "A0A3P7E7Y7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7Y7"
  },
  {
    "uniprot": "A0A3P7EFY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFY5"
  },
  {
    "uniprot": "A0A3P7ED33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED33"
  },
  {
    "uniprot": "A0A3P7FKI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKI2"
  },
  {
    "uniprot": "A0A3P7E2B8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2B8"
  },
  {
    "uniprot": "A0A3P7EL61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL61"
  },
  {
    "uniprot": "A0A3P7DXQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXQ4"
  },
  {
    "uniprot": "A0A3P7FTF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTF2"
  },
  {
    "uniprot": "A0A3P7EK13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK13"
  },
  {
    "uniprot": "A0A183XUW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XUW6"
  },
  {
    "uniprot": "A0A3P7G6Y2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6Y2"
  },
  {
    "uniprot": "J9F8D0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F8D0"
  },
  {
    "uniprot": "A0A3P7EBH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBH5"
  },
  {
    "uniprot": "A0A3P7GBP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBP5"
  },
  {
    "uniprot": "A0A3P7EFT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFT6"
  },
  {
    "uniprot": "A0A3P7FY42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY42"
  },
  {
    "uniprot": "A0A3P7ELA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELA8"
  },
  {
    "uniprot": "A0A3P7E6L5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6L5"
  },
  {
    "uniprot": "A0A3P7DKQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKQ3"
  },
  {
    "uniprot": "A0A3P7FZP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZP9"
  },
  {
    "uniprot": "A0A3P7FAL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAL2"
  },
  {
    "uniprot": "J9FIB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FIB5"
  },
  {
    "uniprot": "A0A3P7EHM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHM3"
  },
  {
    "uniprot": "A0A3P7DV99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV99"
  },
  {
    "uniprot": "J9BDE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BDE6"
  },
  {
    "uniprot": "A0A3P7G0I7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0I7"
  },
  {
    "uniprot": "A0A3P7DKI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKI9"
  },
  {
    "uniprot": "A0A3P7F2J2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2J2"
  },
  {
    "uniprot": "A0A3P7FC43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC43"
  },
  {
    "uniprot": "A0A3P7ER66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ER66"
  },
  {
    "uniprot": "J9BHH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BHH8"
  },
  {
    "uniprot": "A0A3P7DWN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWN8"
  },
  {
    "uniprot": "A0A3P7FW15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW15"
  },
  {
    "uniprot": "A0A3P7FTX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTX2"
  },
  {
    "uniprot": "A0A3P7G2M1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2M1"
  },
  {
    "uniprot": "A0A3P7DZX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZX3"
  },
  {
    "uniprot": "J9FCK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FCK5"
  },
  {
    "uniprot": "A0A3P7E1Q8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1Q8"
  },
  {
    "uniprot": "A0A3P7EU46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU46"
  },
  {
    "uniprot": "A0A3P7FJV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJV4"
  },
  {
    "uniprot": "A0A3P7FDK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDK1"
  },
  {
    "uniprot": "A0A3P7G7T0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G7T0"
  },
  {
    "uniprot": "A0A3P7DAU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAU0"
  },
  {
    "uniprot": "A0A3P7FB82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB82"
  },
  {
    "uniprot": "A0A3P7EBG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBG5"
  },
  {
    "uniprot": "A0A3P7DF31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF31"
  },
  {
    "uniprot": "A0A3P7FGS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGS1"
  },
  {
    "uniprot": "A0A3P7FSY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSY3"
  },
  {
    "uniprot": "A0A3P7E848",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E848"
  },
  {
    "uniprot": "A0A3P7EXD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXD5"
  },
  {
    "uniprot": "A0A3P7FRG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRG0"
  },
  {
    "uniprot": "J9F0W0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F0W0"
  },
  {
    "uniprot": "A0A3P7FS98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS98"
  },
  {
    "uniprot": "A0A3P7GH10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GH10"
  },
  {
    "uniprot": "A0A3P7E2P0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2P0"
  },
  {
    "uniprot": "A0A3P7EBF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBF8"
  },
  {
    "uniprot": "J9B0Y2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B0Y2"
  },
  {
    "uniprot": "A0A3P7FT02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT02"
  },
  {
    "uniprot": "A0A3P7FZM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZM4"
  },
  {
    "uniprot": "A0A3P7DUC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUC0"
  },
  {
    "uniprot": "A0A3P7EC80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC80"
  },
  {
    "uniprot": "A0A3P7EFP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFP0"
  },
  {
    "uniprot": "A0A3P7E380",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E380"
  },
  {
    "uniprot": "A0A3P7DHK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHK0"
  },
  {
    "uniprot": "A0A3P7GKJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKJ8"
  },
  {
    "uniprot": "A0A3P7DLB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLB4"
  },
  {
    "uniprot": "J9EM52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EM52"
  },
  {
    "uniprot": "A0A3P7DYJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYJ8"
  },
  {
    "uniprot": "A0A3P7EVI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVI5"
  },
  {
    "uniprot": "A0A3P7GA40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GA40"
  },
  {
    "uniprot": "A0A3P7DW75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW75"
  },
  {
    "uniprot": "A0A3P7E362",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E362"
  },
  {
    "uniprot": "A0A3P7E1G8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1G8"
  },
  {
    "uniprot": "A0A3P7GKT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKT7"
  },
  {
    "uniprot": "A0A3P7E643",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E643"
  },
  {
    "uniprot": "A0A3P7FR54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR54"
  },
  {
    "uniprot": "J9EZ39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZ39"
  },
  {
    "uniprot": "A0A3P7FAN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAN3"
  },
  {
    "uniprot": "J9BCJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BCJ2"
  },
  {
    "uniprot": "A0A3P7E397",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E397"
  },
  {
    "uniprot": "A0A3P7EVX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVX4"
  },
  {
    "uniprot": "A0A3P7DEI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEI1"
  },
  {
    "uniprot": "A0A3P7E856",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E856"
  },
  {
    "uniprot": "A0A3P7FXB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXB1"
  },
  {
    "uniprot": "A0A3P7E7E9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7E9"
  },
  {
    "uniprot": "A0A3P7FB78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB78"
  },
  {
    "uniprot": "A0A3P7ELG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELG3"
  },
  {
    "uniprot": "A0A3P7E2P8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2P8"
  },
  {
    "uniprot": "A0A3P7E902",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E902"
  },
  {
    "uniprot": "A0A3P7ECH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECH7"
  },
  {
    "uniprot": "A0A183XCB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCB4"
  },
  {
    "uniprot": "A0A3P7DA16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DA16"
  },
  {
    "uniprot": "A0A3P7E831",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E831"
  },
  {
    "uniprot": "J9EX09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EX09"
  },
  {
    "uniprot": "A0A3P7DTZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTZ3"
  },
  {
    "uniprot": "A0A183Y1D4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y1D4"
  },
  {
    "uniprot": "A0A3P7FYI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYI7"
  },
  {
    "uniprot": "A0A3P7F2L7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2L7"
  },
  {
    "uniprot": "A0A3P7DP96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP96"
  },
  {
    "uniprot": "A0A3P7DNB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNB1"
  },
  {
    "uniprot": "A0A3P7DW40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW40"
  },
  {
    "uniprot": "A0A3P7EMN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMN0"
  },
  {
    "uniprot": "A0A3P7E5D8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5D8"
  },
  {
    "uniprot": "A0A3P7EH62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH62"
  },
  {
    "uniprot": "A0A3P7E6B9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6B9"
  },
  {
    "uniprot": "A0A3P7G3T2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3T2"
  },
  {
    "uniprot": "A0A3P7EDU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDU0"
  },
  {
    "uniprot": "A0A3P7E4P2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4P2"
  },
  {
    "uniprot": "J9FEL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FEL2"
  },
  {
    "uniprot": "A0A3P7FE26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE26"
  },
  {
    "uniprot": "A0A3P7DXR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXR5"
  },
  {
    "uniprot": "J9DZ25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DZ25"
  },
  {
    "uniprot": "A0A3P7EE33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE33"
  },
  {
    "uniprot": "A0A3P7DEV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEV5"
  },
  {
    "uniprot": "A0A3P7DWQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWQ3"
  },
  {
    "uniprot": "A0A3P7DKV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKV4"
  },
  {
    "uniprot": "A0A3P7EGG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGG0"
  },
  {
    "uniprot": "A0A3P7DYT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYT3"
  },
  {
    "uniprot": "A0A3P7EL53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL53"
  },
  {
    "uniprot": "A0A3P7EYX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYX7"
  },
  {
    "uniprot": "J9BIA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BIA6"
  },
  {
    "uniprot": "J9EFE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EFE0"
  },
  {
    "uniprot": "A0A3P7DTZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTZ4"
  },
  {
    "uniprot": "A0A3P7EIL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIL5"
  },
  {
    "uniprot": "A0A3P7DTK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTK6"
  },
  {
    "uniprot": "A0A3P7FBQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBQ0"
  },
  {
    "uniprot": "A0A3P7FDL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDL5"
  },
  {
    "uniprot": "A0A3P7FH15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH15"
  },
  {
    "uniprot": "A0A3P7DUG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUG4"
  },
  {
    "uniprot": "A0A3P7F1B2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1B2"
  },
  {
    "uniprot": "J9EG62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EG62"
  },
  {
    "uniprot": "A0A3P7DKW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKW8"
  },
  {
    "uniprot": "A0A3P7DGJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGJ2"
  },
  {
    "uniprot": "A0A3P7DXF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXF7"
  },
  {
    "uniprot": "A0A3P7F6Z1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F6Z1"
  },
  {
    "uniprot": "A0A3P7FVS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVS5"
  },
  {
    "uniprot": "A0A3P7FEB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEB0"
  },
  {
    "uniprot": "A0A3P7DSF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSF8"
  },
  {
    "uniprot": "A0A3P7FMF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMF0"
  },
  {
    "uniprot": "A0A3P7EQE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQE2"
  },
  {
    "uniprot": "A0A3P7EHB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHB9"
  },
  {
    "uniprot": "A0A3P7DL52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL52"
  },
  {
    "uniprot": "J9F003",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F003"
  },
  {
    "uniprot": "J9EY50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EY50"
  },
  {
    "uniprot": "J9BAL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BAL2"
  },
  {
    "uniprot": "A0A3P7ELX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELX7"
  },
  {
    "uniprot": "A0A183XZC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XZC9"
  },
  {
    "uniprot": "A0A3P7EMI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMI0"
  },
  {
    "uniprot": "A0A3P7EIH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIH6"
  },
  {
    "uniprot": "A0A3P7G2E0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2E0"
  },
  {
    "uniprot": "A0A3P7DUR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUR0"
  },
  {
    "uniprot": "A0A183XG62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XG62"
  },
  {
    "uniprot": "A0A3P7G5F7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5F7"
  },
  {
    "uniprot": "J9EYK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYK4"
  },
  {
    "uniprot": "A0A3P7FFW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFW2"
  },
  {
    "uniprot": "A0A3P7ETP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETP9"
  },
  {
    "uniprot": "A0A3P7FS01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS01"
  },
  {
    "uniprot": "A0A3P7F7T8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7T8"
  },
  {
    "uniprot": "A0A3P7DNS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNS6"
  },
  {
    "uniprot": "A0A3P7FH34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH34"
  },
  {
    "uniprot": "A0A3P7FEC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEC9"
  },
  {
    "uniprot": "A0A3P7G4K5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4K5"
  },
  {
    "uniprot": "A0A3P7EF46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF46"
  },
  {
    "uniprot": "A0A3P7DZ86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ86"
  },
  {
    "uniprot": "A0A3P7DSX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSX2"
  },
  {
    "uniprot": "A0A3P7DRS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRS9"
  },
  {
    "uniprot": "A0A3P7EL09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL09"
  },
  {
    "uniprot": "A0A3P7E6M1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6M1"
  },
  {
    "uniprot": "A0A3P7FCH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCH7"
  },
  {
    "uniprot": "A0A3P7DU00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU00"
  },
  {
    "uniprot": "A0A3P7FWB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWB9"
  },
  {
    "uniprot": "A0A3P7FHH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHH0"
  },
  {
    "uniprot": "A0A3P7E336",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E336"
  },
  {
    "uniprot": "A0A3P7DLN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLN9"
  },
  {
    "uniprot": "J9EMT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EMT6"
  },
  {
    "uniprot": "A0A3P7DL00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL00"
  },
  {
    "uniprot": "A0A3P7FLG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLG2"
  },
  {
    "uniprot": "A0A3P7DNN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNN6"
  },
  {
    "uniprot": "A0A3P7EJA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJA6"
  },
  {
    "uniprot": "A0A3P7EBD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBD2"
  },
  {
    "uniprot": "A0A3P7E2C1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2C1"
  },
  {
    "uniprot": "A0A3P7DTD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTD3"
  },
  {
    "uniprot": "A0A3P7E0T4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0T4"
  },
  {
    "uniprot": "A0A3P7EC02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC02"
  },
  {
    "uniprot": "A0A3P7DRJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRJ3"
  },
  {
    "uniprot": "A0A3P7E745",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E745"
  },
  {
    "uniprot": "A0A3P7DMQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMQ6"
  },
  {
    "uniprot": "A0A3P7DZY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZY9"
  },
  {
    "uniprot": "A0A3P7DUA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUA7"
  },
  {
    "uniprot": "A0A3P7EX86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EX86"
  },
  {
    "uniprot": "A0A3P7EHF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHF6"
  },
  {
    "uniprot": "A0A3P7EWV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWV8"
  },
  {
    "uniprot": "A0A3P7FFE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFE3"
  },
  {
    "uniprot": "A0A3P7FP58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP58"
  },
  {
    "uniprot": "A0A3P7DE28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE28"
  },
  {
    "uniprot": "A0A3P7DAT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAT2"
  },
  {
    "uniprot": "A0A3P7EGG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGG8"
  },
  {
    "uniprot": "A0A3P7E600",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E600"
  },
  {
    "uniprot": "A0A3P7E1K7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1K7"
  },
  {
    "uniprot": "A0A3P7EYY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYY7"
  },
  {
    "uniprot": "A0A3P7DZL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZL2"
  },
  {
    "uniprot": "A0A3P7DNA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNA0"
  },
  {
    "uniprot": "J9EK42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EK42"
  },
  {
    "uniprot": "A0A3P7EN22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN22"
  },
  {
    "uniprot": "A0A3P7DSF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSF2"
  },
  {
    "uniprot": "A0A3P7EZM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZM5"
  },
  {
    "uniprot": "A0A3P7DS78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS78"
  },
  {
    "uniprot": "A0A3P7DQ63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ63"
  },
  {
    "uniprot": "A0A3P7GJS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJS9"
  },
  {
    "uniprot": "A0A3P7E9E9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9E9"
  },
  {
    "uniprot": "J9F9W4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F9W4"
  },
  {
    "uniprot": "J9BMB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BMB6"
  },
  {
    "uniprot": "J9ELQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ELQ8"
  },
  {
    "uniprot": "A0A3P7E5V8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5V8"
  },
  {
    "uniprot": "A0A3P7DS08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS08"
  },
  {
    "uniprot": "J9EPZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPZ3"
  },
  {
    "uniprot": "J9BEL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BEL4"
  },
  {
    "uniprot": "A0A3P7EHM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHM2"
  },
  {
    "uniprot": "A0A3P7G1H5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1H5"
  },
  {
    "uniprot": "A0A3P7F394",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F394"
  },
  {
    "uniprot": "A0A3P7DH95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH95"
  },
  {
    "uniprot": "J9F177",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F177"
  },
  {
    "uniprot": "A0A3P7EIY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIY6"
  },
  {
    "uniprot": "A0A3P7DIG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIG7"
  },
  {
    "uniprot": "A0A3P7DU76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU76"
  },
  {
    "uniprot": "A0A3P7EC60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC60"
  },
  {
    "uniprot": "A0A3P7EFH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFH6"
  },
  {
    "uniprot": "A0A3P7FXD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXD8"
  },
  {
    "uniprot": "A0A3P7E2E2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2E2"
  },
  {
    "uniprot": "J9F1T3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1T3"
  },
  {
    "uniprot": "A0A3P7EZT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZT6"
  },
  {
    "uniprot": "J9EW07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EW07"
  },
  {
    "uniprot": "A0A3P7DPL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPL6"
  },
  {
    "uniprot": "A0A3P7EDV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDV1"
  },
  {
    "uniprot": "A0A3P7DY28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY28"
  },
  {
    "uniprot": "A0A3P7E9A8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9A8"
  },
  {
    "uniprot": "A0A3P7DP15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP15"
  },
  {
    "uniprot": "A0A3P7E7Q3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7Q3"
  },
  {
    "uniprot": "A0A3P7DIR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIR3"
  },
  {
    "uniprot": "A0A3P7DXG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXG8"
  },
  {
    "uniprot": "A0A3P7DWH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWH3"
  },
  {
    "uniprot": "A0A3P7E6F2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6F2"
  },
  {
    "uniprot": "A0A3P7DRH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRH8"
  },
  {
    "uniprot": "A0A3P7DRM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRM7"
  },
  {
    "uniprot": "A0A3P7E7I7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7I7"
  },
  {
    "uniprot": "A0A3P7FW00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW00"
  },
  {
    "uniprot": "A0A3P7EZ79",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZ79"
  },
  {
    "uniprot": "A0A3P7E5Y8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5Y8"
  },
  {
    "uniprot": "A0A3P7DVP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVP8"
  },
  {
    "uniprot": "A0A3P7DEZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEZ3"
  },
  {
    "uniprot": "A0A3P7FCT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCT1"
  },
  {
    "uniprot": "A0A3P7FQH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQH8"
  },
  {
    "uniprot": "J9B2Z1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B2Z1"
  },
  {
    "uniprot": "A0A3P7E176",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E176"
  },
  {
    "uniprot": "J9EIF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EIF5"
  },
  {
    "uniprot": "A0A3P7DSS7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSS7"
  },
  {
    "uniprot": "A0A3P7E4E8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4E8"
  },
  {
    "uniprot": "A0A3P7EMY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMY4"
  },
  {
    "uniprot": "A0A3P7E435",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E435"
  },
  {
    "uniprot": "A0A3P7FIC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIC1"
  },
  {
    "uniprot": "A0A3P7FBB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBB2"
  },
  {
    "uniprot": "J9EVH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EVH3"
  },
  {
    "uniprot": "A0A3P7DK99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK99"
  },
  {
    "uniprot": "A0A3P7DYN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYN2"
  },
  {
    "uniprot": "A0A3P7DZU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZU2"
  },
  {
    "uniprot": "A0A3P7DYK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYK4"
  },
  {
    "uniprot": "A0A3P7EBM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBM2"
  },
  {
    "uniprot": "A0A3P7EPM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPM9"
  },
  {
    "uniprot": "A0A3P7EA26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA26"
  },
  {
    "uniprot": "A0A3P7F3N5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3N5"
  },
  {
    "uniprot": "A0A3P7DLJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLJ1"
  },
  {
    "uniprot": "J9B3L2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B3L2"
  },
  {
    "uniprot": "A0A3P7EV50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EV50"
  },
  {
    "uniprot": "A0A3P7EHK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHK2"
  },
  {
    "uniprot": "A0A3P7FY93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY93"
  },
  {
    "uniprot": "A0A3P7E3R9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3R9"
  },
  {
    "uniprot": "J9EIQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EIQ3"
  },
  {
    "uniprot": "A0A3P7DR60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR60"
  },
  {
    "uniprot": "A0A3P7EG72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG72"
  },
  {
    "uniprot": "A0A3P7FBV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBV5"
  },
  {
    "uniprot": "J9FEK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FEK1"
  },
  {
    "uniprot": "A0A3P7ECD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECD4"
  },
  {
    "uniprot": "A0A3P7G9X2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9X2"
  },
  {
    "uniprot": "A0A3P7DXJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXJ3"
  },
  {
    "uniprot": "A0A3P7FG81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG81"
  },
  {
    "uniprot": "A0A3P7DXZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXZ8"
  },
  {
    "uniprot": "A0A3P7DN93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN93"
  },
  {
    "uniprot": "A0A3P7EX38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EX38"
  },
  {
    "uniprot": "A0A3P7FLB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLB7"
  },
  {
    "uniprot": "A0A3P7FLN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLN4"
  },
  {
    "uniprot": "A0A3P7DRS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRS0"
  },
  {
    "uniprot": "A0A3P7G6N0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6N0"
  },
  {
    "uniprot": "A0A3P7DMC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMC2"
  },
  {
    "uniprot": "A0A3P7EIK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIK8"
  },
  {
    "uniprot": "A0A3P7EPP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPP7"
  },
  {
    "uniprot": "A0A3P7DX80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX80"
  },
  {
    "uniprot": "A0A3P7F7Y0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7Y0"
  },
  {
    "uniprot": "A0A3P7ET35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET35"
  },
  {
    "uniprot": "A0A3P7DYF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYF7"
  },
  {
    "uniprot": "A0A3P7DRQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRQ7"
  },
  {
    "uniprot": "A0A3P7FF35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF35"
  },
  {
    "uniprot": "A0A3P7DNS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNS5"
  },
  {
    "uniprot": "A0A3P7E078",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E078"
  },
  {
    "uniprot": "J9ANU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ANU9"
  },
  {
    "uniprot": "A0A3P7DEP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEP3"
  },
  {
    "uniprot": "A0A3P7E2L5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2L5"
  },
  {
    "uniprot": "A0A3P7FHX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHX0"
  },
  {
    "uniprot": "A0A3P7E2R8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2R8"
  },
  {
    "uniprot": "A0A3P7DR25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR25"
  },
  {
    "uniprot": "A0A3P7FSK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSK1"
  },
  {
    "uniprot": "A0A3P7DKA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKA4"
  },
  {
    "uniprot": "A0A3P7ERW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERW6"
  },
  {
    "uniprot": "A0A3P7FCW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCW3"
  },
  {
    "uniprot": "A0A3P7DYL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYL4"
  },
  {
    "uniprot": "A0A3P7DYL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYL5"
  },
  {
    "uniprot": "A0A3P7EEA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEA3"
  },
  {
    "uniprot": "A0A3P7E9U0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9U0"
  },
  {
    "uniprot": "A0A3P7DKG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKG4"
  },
  {
    "uniprot": "A0A3P7DKY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKY7"
  },
  {
    "uniprot": "A0A3P7E0X3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0X3"
  },
  {
    "uniprot": "A0A3P7DK40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK40"
  },
  {
    "uniprot": "A0A3P7G255",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G255"
  },
  {
    "uniprot": "A0A3P7E4D7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4D7"
  },
  {
    "uniprot": "A0A3P7G0C1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0C1"
  },
  {
    "uniprot": "A0A3P7FUU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUU0"
  },
  {
    "uniprot": "A0A3P7DU56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU56"
  },
  {
    "uniprot": "A0A3P7FNM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNM1"
  },
  {
    "uniprot": "A0A3P7FQD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQD1"
  },
  {
    "uniprot": "A0A3P7EA89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA89"
  },
  {
    "uniprot": "A0A3P7EBR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBR8"
  },
  {
    "uniprot": "A0A3P7FQZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQZ0"
  },
  {
    "uniprot": "J9AID2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AID2"
  },
  {
    "uniprot": "J9FCN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FCN4"
  },
  {
    "uniprot": "A0A3P7EBW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBW1"
  },
  {
    "uniprot": "A0A3P7GAJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAJ4"
  },
  {
    "uniprot": "A0A183XBT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XBT2"
  },
  {
    "uniprot": "A0A3P7FJD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJD7"
  },
  {
    "uniprot": "A0A3P7DNK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNK6"
  },
  {
    "uniprot": "A0A3P7EJ40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ40"
  },
  {
    "uniprot": "A0A3P7ECS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECS5"
  },
  {
    "uniprot": "J9F272",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F272"
  },
  {
    "uniprot": "A0A3P7DNF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNF7"
  },
  {
    "uniprot": "A0A3P7ETP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETP1"
  },
  {
    "uniprot": "A0A3P7FNK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNK0"
  },
  {
    "uniprot": "A0A3P7FY53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY53"
  },
  {
    "uniprot": "A0A3P7DS59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS59"
  },
  {
    "uniprot": "A0A3P7DYM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYM6"
  },
  {
    "uniprot": "A0A3P7DZ20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ20"
  },
  {
    "uniprot": "A0A3P7EJG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJG7"
  },
  {
    "uniprot": "J9EYX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYX6"
  },
  {
    "uniprot": "A0A183XD29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XD29"
  },
  {
    "uniprot": "A0A3P7DES2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DES2"
  },
  {
    "uniprot": "A0A3P7DVA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVA3"
  },
  {
    "uniprot": "A0A3P7G4N9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4N9"
  },
  {
    "uniprot": "A0A3P7G749",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G749"
  },
  {
    "uniprot": "A0A3P7EZK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZK9"
  },
  {
    "uniprot": "J9EY80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EY80"
  },
  {
    "uniprot": "A0A3P7E9Y7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9Y7"
  },
  {
    "uniprot": "J9EU63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EU63"
  },
  {
    "uniprot": "J9EQY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQY5"
  },
  {
    "uniprot": "A0A3P7E277",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E277"
  },
  {
    "uniprot": "J9BAY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BAY5"
  },
  {
    "uniprot": "A0A3P7DZ07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ07"
  },
  {
    "uniprot": "A0A3P7G008",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G008"
  },
  {
    "uniprot": "J9EXF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXF8"
  },
  {
    "uniprot": "A0A3P7FHC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHC9"
  },
  {
    "uniprot": "A0A3P7DQN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQN8"
  },
  {
    "uniprot": "J9ETH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ETH4"
  },
  {
    "uniprot": "A0A3P7EDW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDW8"
  },
  {
    "uniprot": "A0A3P7FGA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGA7"
  },
  {
    "uniprot": "J9EKT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EKT5"
  },
  {
    "uniprot": "A0A3P7F4D3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F4D3"
  },
  {
    "uniprot": "A0A3P7DMC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMC0"
  },
  {
    "uniprot": "A0A183XU49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XU49"
  },
  {
    "uniprot": "A0A3P7EF86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF86"
  },
  {
    "uniprot": "A0A3P7ERT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERT8"
  },
  {
    "uniprot": "A0A3P7EGQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGQ2"
  },
  {
    "uniprot": "A0A3P7G7W3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G7W3"
  },
  {
    "uniprot": "A0A3P7ENC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENC6"
  },
  {
    "uniprot": "J9EPD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPD8"
  },
  {
    "uniprot": "A0A3P7DXM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXM8"
  },
  {
    "uniprot": "A0A3P7ED96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED96"
  },
  {
    "uniprot": "A0A3P7F265",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F265"
  },
  {
    "uniprot": "A0A3P7GFK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFK2"
  },
  {
    "uniprot": "A0A3P7DY60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY60"
  },
  {
    "uniprot": "A0A3P7EHW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHW6"
  },
  {
    "uniprot": "A0A3P7DHW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHW2"
  },
  {
    "uniprot": "J9BGA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BGA0"
  },
  {
    "uniprot": "A0A3P7E3S0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3S0"
  },
  {
    "uniprot": "A0A3P7FAM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAM3"
  },
  {
    "uniprot": "A0A3P7EEA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEA0"
  },
  {
    "uniprot": "J9AWG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AWG2"
  },
  {
    "uniprot": "A0A3P7EU91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU91"
  },
  {
    "uniprot": "A0A3P7ELU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELU0"
  },
  {
    "uniprot": "A0A3P7EEJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEJ6"
  },
  {
    "uniprot": "A0A3P7DZY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZY5"
  },
  {
    "uniprot": "A0A3P7DXX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXX4"
  },
  {
    "uniprot": "A0A3P7DEB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEB0"
  },
  {
    "uniprot": "A0A3P7EU56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU56"
  },
  {
    "uniprot": "A0A3P7E9K1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9K1"
  },
  {
    "uniprot": "A0A3P7FZY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZY0"
  },
  {
    "uniprot": "A0A3P7G9L3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9L3"
  },
  {
    "uniprot": "A0A183XVY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XVY6"
  },
  {
    "uniprot": "J9F049",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F049"
  },
  {
    "uniprot": "A0A3P7DCT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCT6"
  },
  {
    "uniprot": "A0A3P7FYU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYU2"
  },
  {
    "uniprot": "A0A3P7DVQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVQ7"
  },
  {
    "uniprot": "A0A3P7DI73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI73"
  },
  {
    "uniprot": "A0A3P7FY69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY69"
  },
  {
    "uniprot": "A0A3P7F8S0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8S0"
  },
  {
    "uniprot": "A0A3P7DGW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGW1"
  },
  {
    "uniprot": "J9F156",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F156"
  },
  {
    "uniprot": "A0A3P7DZA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZA5"
  },
  {
    "uniprot": "A0A3P7FQ92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ92"
  },
  {
    "uniprot": "A0A3P7E280",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E280"
  },
  {
    "uniprot": "A0A3P7DR06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR06"
  },
  {
    "uniprot": "A0A3P7DG93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG93"
  },
  {
    "uniprot": "A0A3P7FSY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSY9"
  },
  {
    "uniprot": "A0A183Y6P0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y6P0"
  },
  {
    "uniprot": "A0A3P7EBF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBF2"
  },
  {
    "uniprot": "A0A183XFC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XFC7"
  },
  {
    "uniprot": "A0A3P7EB69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB69"
  },
  {
    "uniprot": "A0A3P7DR65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR65"
  },
  {
    "uniprot": "J9F263",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F263"
  },
  {
    "uniprot": "A0A3P7DLG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLG3"
  },
  {
    "uniprot": "A0A3P7DMP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMP9"
  },
  {
    "uniprot": "A0A3P7DB27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DB27"
  },
  {
    "uniprot": "A0A183XPN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XPN1"
  },
  {
    "uniprot": "A0A3P7FSS4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSS4"
  },
  {
    "uniprot": "A0A3P7DY63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY63"
  },
  {
    "uniprot": "A0A3P7G9K2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9K2"
  },
  {
    "uniprot": "A0A3P7EFR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFR5"
  },
  {
    "uniprot": "A0A3P7GGT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGT9"
  },
  {
    "uniprot": "A0A3P7DZI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZI3"
  },
  {
    "uniprot": "A0A3P7FSM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSM1"
  },
  {
    "uniprot": "A0A3P7FNJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNJ1"
  },
  {
    "uniprot": "A0A3P7DWN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWN3"
  },
  {
    "uniprot": "A0A3P7DRY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRY0"
  },
  {
    "uniprot": "A0A3P7E3G3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3G3"
  },
  {
    "uniprot": "A0A3P7DW29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW29"
  },
  {
    "uniprot": "A0A3P7DUU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUU3"
  },
  {
    "uniprot": "A0A3P7DC48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC48"
  },
  {
    "uniprot": "A0A3P7DVY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVY2"
  },
  {
    "uniprot": "A0A3P7DR74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR74"
  },
  {
    "uniprot": "A0A3P7DH60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH60"
  },
  {
    "uniprot": "A0A3P7EFD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFD6"
  },
  {
    "uniprot": "A0A3P7FWV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWV7"
  },
  {
    "uniprot": "A0A3P7E7K1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7K1"
  },
  {
    "uniprot": "A0A3P7E3J4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3J4"
  },
  {
    "uniprot": "A0A3P7E6M6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6M6"
  },
  {
    "uniprot": "A0A3P7DVC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVC6"
  },
  {
    "uniprot": "A0A3P7DRZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRZ2"
  },
  {
    "uniprot": "A0A183XEF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XEF1"
  },
  {
    "uniprot": "A0A3P7GKE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKE6"
  },
  {
    "uniprot": "A0A3P7EEE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEE0"
  },
  {
    "uniprot": "A0A3P7DE10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE10"
  },
  {
    "uniprot": "A0A3P7E5Q0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5Q0"
  },
  {
    "uniprot": "A0A3P7DUI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUI0"
  },
  {
    "uniprot": "A0A3P7DZ32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ32"
  },
  {
    "uniprot": "J9F7B1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F7B1"
  },
  {
    "uniprot": "A0A183XFF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XFF9"
  },
  {
    "uniprot": "A0A3P7FFF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFF5"
  },
  {
    "uniprot": "A0A3P7DNB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNB2"
  },
  {
    "uniprot": "A0A3P7G4E6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4E6"
  },
  {
    "uniprot": "A0A3P7E1Y2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1Y2"
  },
  {
    "uniprot": "A0A3P7GBG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBG8"
  },
  {
    "uniprot": "A0A3P7DCF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCF0"
  },
  {
    "uniprot": "A0A3P7GCL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCL0"
  },
  {
    "uniprot": "A0A3P7G9G8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9G8"
  },
  {
    "uniprot": "A0A3P7FH05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH05"
  },
  {
    "uniprot": "A0A3P7DV13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV13"
  },
  {
    "uniprot": "A0A3P7DXA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXA8"
  },
  {
    "uniprot": "A0A3P7DTC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTC9"
  },
  {
    "uniprot": "A0A3P7ECA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECA2"
  },
  {
    "uniprot": "J9ETX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ETX0"
  },
  {
    "uniprot": "A0A3P7DU21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU21"
  },
  {
    "uniprot": "A0A3P7FJ25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ25"
  },
  {
    "uniprot": "A0A3P7F8Q4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8Q4"
  },
  {
    "uniprot": "A0A3P7EB37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB37"
  },
  {
    "uniprot": "A0A3P7E8L0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8L0"
  },
  {
    "uniprot": "A0A3P7FDJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDJ4"
  },
  {
    "uniprot": "A0A3P7DLN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLN5"
  },
  {
    "uniprot": "A0A3P7DSU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSU7"
  },
  {
    "uniprot": "A0A3P7DXD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXD6"
  },
  {
    "uniprot": "A0A3P7E1E4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1E4"
  },
  {
    "uniprot": "A0A3P7FAZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAZ1"
  },
  {
    "uniprot": "A0A3P7FB15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB15"
  },
  {
    "uniprot": "A0A3P7EIT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIT0"
  },
  {
    "uniprot": "A0A3P7DZ76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ76"
  },
  {
    "uniprot": "A0A3P7DSD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSD9"
  },
  {
    "uniprot": "A0A3P7ETS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETS8"
  },
  {
    "uniprot": "A0A3P7E3P1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3P1"
  },
  {
    "uniprot": "A0A3P7E9U4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9U4"
  },
  {
    "uniprot": "A0A3P7EFP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFP7"
  },
  {
    "uniprot": "A0A3P7E3N3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3N3"
  },
  {
    "uniprot": "A0A3P7FXM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXM4"
  },
  {
    "uniprot": "A0A183XCR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCR3"
  },
  {
    "uniprot": "A0A3P7DIG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIG0"
  },
  {
    "uniprot": "A0A183XDM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDM9"
  },
  {
    "uniprot": "A0A3P7EBT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBT7"
  },
  {
    "uniprot": "A0A3P7GGI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGI5"
  },
  {
    "uniprot": "A0A3P7FED2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FED2"
  },
  {
    "uniprot": "A0A3P7FL87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL87"
  },
  {
    "uniprot": "A0A3P7EA03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA03"
  },
  {
    "uniprot": "A0A3P7E1Y3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1Y3"
  },
  {
    "uniprot": "A0A3P7ED21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED21"
  },
  {
    "uniprot": "A0A3P7E6D4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6D4"
  },
  {
    "uniprot": "A0A3P7DH51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH51"
  },
  {
    "uniprot": "A0A3P7EH66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH66"
  },
  {
    "uniprot": "A0A3P7G585",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G585"
  },
  {
    "uniprot": "A0A183XMD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XMD7"
  },
  {
    "uniprot": "J9ERF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERF8"
  },
  {
    "uniprot": "A0A3P7DCB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCB9"
  },
  {
    "uniprot": "A0A3P7FI42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI42"
  },
  {
    "uniprot": "A0A3P7FQN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQN1"
  },
  {
    "uniprot": "J9DWA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DWA3"
  },
  {
    "uniprot": "A0A3P7GKB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKB3"
  },
  {
    "uniprot": "A0A3P7DM01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM01"
  },
  {
    "uniprot": "A0A3P7DWT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWT9"
  },
  {
    "uniprot": "A0A3P7E177",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E177"
  },
  {
    "uniprot": "A0A3P7EUE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUE5"
  },
  {
    "uniprot": "A0A3P7E2P3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2P3"
  },
  {
    "uniprot": "A0A3P7EQV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQV7"
  },
  {
    "uniprot": "A0A3P7EAY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAY9"
  },
  {
    "uniprot": "A0A3P7DXI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXI4"
  },
  {
    "uniprot": "A0A3P7DZR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZR4"
  },
  {
    "uniprot": "A0A3P7E610",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E610"
  },
  {
    "uniprot": "A0A3P7E426",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E426"
  },
  {
    "uniprot": "J9FAB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FAB5"
  },
  {
    "uniprot": "A0A3P7FRH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRH9"
  },
  {
    "uniprot": "A0A3P7DXL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXL2"
  },
  {
    "uniprot": "A0A3P7E9D0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9D0"
  },
  {
    "uniprot": "A0A3P7E9J8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9J8"
  },
  {
    "uniprot": "A0A3P7E1C9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1C9"
  },
  {
    "uniprot": "J9ERI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERI0"
  },
  {
    "uniprot": "A0A3P7DSV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSV6"
  },
  {
    "uniprot": "A0A3P7DGV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGV7"
  },
  {
    "uniprot": "A0A183XMM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XMM9"
  },
  {
    "uniprot": "A0A3P7EBP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBP1"
  },
  {
    "uniprot": "A0A3P7EDJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDJ0"
  },
  {
    "uniprot": "A0A3P7EFR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFR4"
  },
  {
    "uniprot": "A0A3P7G5E2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5E2"
  },
  {
    "uniprot": "A0A3P7E952",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E952"
  },
  {
    "uniprot": "J9ENS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ENS0"
  },
  {
    "uniprot": "A0A3P7EC48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC48"
  },
  {
    "uniprot": "A0A3P7DWL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWL2"
  },
  {
    "uniprot": "A0A3P7FRN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRN9"
  },
  {
    "uniprot": "A0A3P7DTT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTT8"
  },
  {
    "uniprot": "A0A3P7EZH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZH8"
  },
  {
    "uniprot": "A0A183XDC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDC4"
  },
  {
    "uniprot": "A0A3P7E835",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E835"
  },
  {
    "uniprot": "A0A3P7EN44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN44"
  },
  {
    "uniprot": "A0A3P7DFI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFI7"
  },
  {
    "uniprot": "A0A3P7EEK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEK8"
  },
  {
    "uniprot": "A0A3P7EB29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB29"
  },
  {
    "uniprot": "A0A3P7E561",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E561"
  },
  {
    "uniprot": "J9E5M1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E5M1"
  },
  {
    "uniprot": "A0A3P7DQU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQU4"
  },
  {
    "uniprot": "A0A3P7FHU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHU8"
  },
  {
    "uniprot": "A0A3P7E3S3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3S3"
  },
  {
    "uniprot": "A0A3P7E152",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E152"
  },
  {
    "uniprot": "A0A3P7DTG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTG0"
  },
  {
    "uniprot": "A0A3P7FHR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHR5"
  },
  {
    "uniprot": "A0A3P7G2A4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2A4"
  },
  {
    "uniprot": "A0A3P7DYX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYX2"
  },
  {
    "uniprot": "A0A3P7GIJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIJ8"
  },
  {
    "uniprot": "A0A3P7DUA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUA8"
  },
  {
    "uniprot": "A0A3P7EF83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF83"
  },
  {
    "uniprot": "A0A3P7DD93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD93"
  },
  {
    "uniprot": "A0A3P7E422",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E422"
  },
  {
    "uniprot": "A0A3P7FEQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEQ0"
  },
  {
    "uniprot": "A0A3P7DTK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTK1"
  },
  {
    "uniprot": "J9EKY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EKY1"
  },
  {
    "uniprot": "A0A3P7EE45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE45"
  },
  {
    "uniprot": "A0A3P7DXS4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXS4"
  },
  {
    "uniprot": "A0A3P7FI02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI02"
  },
  {
    "uniprot": "A0A3P7GJ90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJ90"
  },
  {
    "uniprot": "A0A3P7ELL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELL5"
  },
  {
    "uniprot": "A0A3P7DDB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDB9"
  },
  {
    "uniprot": "A0A3P7FYH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYH6"
  },
  {
    "uniprot": "J9BGH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BGH4"
  },
  {
    "uniprot": "A0A3P7E0X8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0X8"
  },
  {
    "uniprot": "A0A3P7GHY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHY8"
  },
  {
    "uniprot": "A0A3P7DRR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRR4"
  },
  {
    "uniprot": "A0A3P7ERU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERU9"
  },
  {
    "uniprot": "A0A3P7FT93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT93"
  },
  {
    "uniprot": "J9FDJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FDJ8"
  },
  {
    "uniprot": "A0A3P7DS43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS43"
  },
  {
    "uniprot": "A0A3P7EAW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAW2"
  },
  {
    "uniprot": "A0A3P7DMY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMY9"
  },
  {
    "uniprot": "A0A3P7FUY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUY1"
  },
  {
    "uniprot": "A0A3P7DXQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXQ1"
  },
  {
    "uniprot": "J9ESX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ESX4"
  },
  {
    "uniprot": "A0A3P7ECU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECU7"
  },
  {
    "uniprot": "A0A3P7E0Q3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0Q3"
  },
  {
    "uniprot": "A0A3P7EF94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF94"
  },
  {
    "uniprot": "A0A3P7EYW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYW2"
  },
  {
    "uniprot": "A0A3P7E189",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E189"
  },
  {
    "uniprot": "A0A3P7GJ38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJ38"
  },
  {
    "uniprot": "A0A3P7DUQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUQ1"
  },
  {
    "uniprot": "A0A3P7E5H9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5H9"
  },
  {
    "uniprot": "A0A3P7FLK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLK4"
  },
  {
    "uniprot": "A0A3P7E7B4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7B4"
  },
  {
    "uniprot": "A0A183XGF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XGF9"
  },
  {
    "uniprot": "A0A3P7EYY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYY0"
  },
  {
    "uniprot": "A0A3P7DQ34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ34"
  },
  {
    "uniprot": "A0A3P7FKS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKS6"
  },
  {
    "uniprot": "A0A3P7DFQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFQ4"
  },
  {
    "uniprot": "A0A183Y9F9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y9F9"
  },
  {
    "uniprot": "A0A3P7E7M8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7M8"
  },
  {
    "uniprot": "A0A3P7DYT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYT5"
  },
  {
    "uniprot": "A0A3P7GGQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGQ1"
  },
  {
    "uniprot": "A0A3P7DW82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW82"
  },
  {
    "uniprot": "A0A3P7EBJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBJ7"
  },
  {
    "uniprot": "J9BKT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BKT4"
  },
  {
    "uniprot": "A0A3P7DMI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMI1"
  },
  {
    "uniprot": "A0A3P7FBJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBJ6"
  },
  {
    "uniprot": "A0A3P7DI06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI06"
  },
  {
    "uniprot": "A0A3P7FA42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FA42"
  },
  {
    "uniprot": "A0A3P7GDE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDE8"
  },
  {
    "uniprot": "A0A3P7ECQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECQ7"
  },
  {
    "uniprot": "A0A3P7E7K8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7K8"
  },
  {
    "uniprot": "A0A3P7FQH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQH1"
  },
  {
    "uniprot": "A0A3P7E9Z0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9Z0"
  },
  {
    "uniprot": "A0A3P7DR80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR80"
  },
  {
    "uniprot": "A0A3P7GGL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGL7"
  },
  {
    "uniprot": "A0A3P7E8Q3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8Q3"
  },
  {
    "uniprot": "A0A3P7E9X9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9X9"
  },
  {
    "uniprot": "A0A3P7FSC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSC0"
  },
  {
    "uniprot": "A0A3P7DU73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU73"
  },
  {
    "uniprot": "J9BM48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BM48"
  },
  {
    "uniprot": "A0A3P7G9U2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9U2"
  },
  {
    "uniprot": "J9EX72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EX72"
  },
  {
    "uniprot": "A0A3P7EHU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHU3"
  },
  {
    "uniprot": "J9F0I0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F0I0"
  },
  {
    "uniprot": "J9EB60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EB60"
  },
  {
    "uniprot": "A0A3P7EAU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAU3"
  },
  {
    "uniprot": "A0A3P7DMX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMX7"
  },
  {
    "uniprot": "A0A3P7DSN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSN2"
  },
  {
    "uniprot": "A0A3P7DME2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DME2"
  },
  {
    "uniprot": "J9EGJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EGJ1"
  },
  {
    "uniprot": "A0A3P7DKC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKC9"
  },
  {
    "uniprot": "A0A3P7DR04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR04"
  },
  {
    "uniprot": "A0A3P7DZ74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ74"
  },
  {
    "uniprot": "A0A3P7EYW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYW3"
  },
  {
    "uniprot": "A0A3P7DEA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEA7"
  },
  {
    "uniprot": "A0A3P7EG41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG41"
  },
  {
    "uniprot": "A0A3P7DAA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAA7"
  },
  {
    "uniprot": "A0A3P7E892",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E892"
  },
  {
    "uniprot": "A0A183XCM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCM0"
  },
  {
    "uniprot": "A0A3P7DVX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVX3"
  },
  {
    "uniprot": "A0A3P7EY65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY65"
  },
  {
    "uniprot": "A0A3P7DPA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPA9"
  },
  {
    "uniprot": "A0A3P7DEP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEP6"
  },
  {
    "uniprot": "A0A3P7E1Z2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1Z2"
  },
  {
    "uniprot": "A0A3P7FQ46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ46"
  },
  {
    "uniprot": "A0A3P7DBK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBK7"
  },
  {
    "uniprot": "A0A3P7E212",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E212"
  },
  {
    "uniprot": "A0A3P7GIL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIL6"
  },
  {
    "uniprot": "A0A3P7DQI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQI7"
  },
  {
    "uniprot": "A0A3P7E871",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E871"
  },
  {
    "uniprot": "A0A3P7FIX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIX7"
  },
  {
    "uniprot": "J9EMT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EMT1"
  },
  {
    "uniprot": "A0A3P7DQS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQS3"
  },
  {
    "uniprot": "A0A3P7ESY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESY5"
  },
  {
    "uniprot": "A0A3P7GN94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GN94"
  },
  {
    "uniprot": "A0A3P7DTY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTY7"
  },
  {
    "uniprot": "A0A3P7FWF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWF2"
  },
  {
    "uniprot": "A0A3P7G304",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G304"
  },
  {
    "uniprot": "A0A3P7F307",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F307"
  },
  {
    "uniprot": "A0A3P7DR49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR49"
  },
  {
    "uniprot": "A0A3P7EBB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBB7"
  },
  {
    "uniprot": "A0A3P7EE19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE19"
  },
  {
    "uniprot": "A0A3P7FTY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTY2"
  },
  {
    "uniprot": "A0A3P7DY50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY50"
  },
  {
    "uniprot": "A0A3P7FG85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG85"
  },
  {
    "uniprot": "A0A3P7DTG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTG2"
  },
  {
    "uniprot": "A0A3P7GC85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GC85"
  },
  {
    "uniprot": "J9BEA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BEA2"
  },
  {
    "uniprot": "A0A3P7DS48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS48"
  },
  {
    "uniprot": "A0A3P7E9V4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9V4"
  },
  {
    "uniprot": "A0A3P7G4Q0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4Q0"
  },
  {
    "uniprot": "A0A3P7E503",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E503"
  },
  {
    "uniprot": "A0A3P7FVA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVA1"
  },
  {
    "uniprot": "A0A3P7EI65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI65"
  },
  {
    "uniprot": "A0A3P7FSJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSJ2"
  },
  {
    "uniprot": "A0A3P7E166",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E166"
  },
  {
    "uniprot": "J9BGY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BGY5"
  },
  {
    "uniprot": "A0A3P7END3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7END3"
  },
  {
    "uniprot": "A0A3P7ECE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECE7"
  },
  {
    "uniprot": "A0A3P7G015",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G015"
  },
  {
    "uniprot": "A0A3P7E9W9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9W9"
  },
  {
    "uniprot": "A0A3P7ECH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECH8"
  },
  {
    "uniprot": "A0A3P7FW82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW82"
  },
  {
    "uniprot": "A0A3P7EUL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUL6"
  },
  {
    "uniprot": "A0A3P7E2F9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2F9"
  },
  {
    "uniprot": "A0A3P7DXZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXZ1"
  },
  {
    "uniprot": "A0A3P7FDL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDL3"
  },
  {
    "uniprot": "A0A3P7DPG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPG6"
  },
  {
    "uniprot": "A0A3P7FBL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBL5"
  },
  {
    "uniprot": "A0A3P7EFB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFB2"
  },
  {
    "uniprot": "A0A3P7FM59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM59"
  },
  {
    "uniprot": "J9ADJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ADJ9"
  },
  {
    "uniprot": "A0A3P7ELT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELT1"
  },
  {
    "uniprot": "J9F968",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F968"
  },
  {
    "uniprot": "A0A183XLY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLY1"
  },
  {
    "uniprot": "A0A3P7E6A6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6A6"
  },
  {
    "uniprot": "J9DY47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DY47"
  },
  {
    "uniprot": "A0A3P7DW61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW61"
  },
  {
    "uniprot": "A0A3P7FZ01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ01"
  },
  {
    "uniprot": "J9FC95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FC95"
  },
  {
    "uniprot": "A0A3P7E897",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E897"
  },
  {
    "uniprot": "A0A3P7E4C6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4C6"
  },
  {
    "uniprot": "J9FGD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FGD3"
  },
  {
    "uniprot": "A0A3P7FYH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYH8"
  },
  {
    "uniprot": "A0A3P7DV28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV28"
  },
  {
    "uniprot": "A0A3P7FLT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLT5"
  },
  {
    "uniprot": "A0A3P7DLA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLA8"
  },
  {
    "uniprot": "J9DZ47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DZ47"
  },
  {
    "uniprot": "A0A3P7DXE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXE5"
  },
  {
    "uniprot": "A0A3P7DUS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUS3"
  },
  {
    "uniprot": "A0A3P7E863",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E863"
  },
  {
    "uniprot": "A0A3P7E0G6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0G6"
  },
  {
    "uniprot": "A0A3P7FFH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFH6"
  },
  {
    "uniprot": "A0A3P7FS18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS18"
  },
  {
    "uniprot": "A0A3P7DUK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUK7"
  },
  {
    "uniprot": "A0A3P7DXW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXW2"
  },
  {
    "uniprot": "A0A3P7E592",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E592"
  },
  {
    "uniprot": "A0A3P7DQP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQP7"
  },
  {
    "uniprot": "A0A3P7DVL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVL4"
  },
  {
    "uniprot": "A0A3P7DX59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX59"
  },
  {
    "uniprot": "A0A183XGZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XGZ0"
  },
  {
    "uniprot": "A0A3P7E2W7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2W7"
  },
  {
    "uniprot": "J9EQN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQN7"
  },
  {
    "uniprot": "A0A3P7DI15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI15"
  },
  {
    "uniprot": "A0A3P7EVB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVB1"
  },
  {
    "uniprot": "A0A3P7FN38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN38"
  },
  {
    "uniprot": "A0A3P7E2D6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2D6"
  },
  {
    "uniprot": "A0A3P7DDL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDL6"
  },
  {
    "uniprot": "J9FCM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FCM9"
  },
  {
    "uniprot": "A0A3P7DXL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXL4"
  },
  {
    "uniprot": "A0A3P7FNQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNQ3"
  },
  {
    "uniprot": "A0A3P7DBY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBY0"
  },
  {
    "uniprot": "A0A3P7EEG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEG0"
  },
  {
    "uniprot": "J9EVJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EVJ4"
  },
  {
    "uniprot": "A0A3P7GI27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GI27"
  },
  {
    "uniprot": "A0A3P7DV98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV98"
  },
  {
    "uniprot": "A0A3P7GJK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJK8"
  },
  {
    "uniprot": "A0A3P7DYX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYX7"
  },
  {
    "uniprot": "J9F2I7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2I7"
  },
  {
    "uniprot": "A0A3P7ET16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET16"
  },
  {
    "uniprot": "A0A3P7FEM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEM0"
  },
  {
    "uniprot": "J9ETZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ETZ4"
  },
  {
    "uniprot": "J9BGN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BGN8"
  },
  {
    "uniprot": "A0A3P7EBA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBA4"
  },
  {
    "uniprot": "A0A3P7E0P9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0P9"
  },
  {
    "uniprot": "A0A3P7DYI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYI6"
  },
  {
    "uniprot": "A0A3P7E1P5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1P5"
  },
  {
    "uniprot": "A0A3P7DXB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXB1"
  },
  {
    "uniprot": "A0A3P7FIP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIP0"
  },
  {
    "uniprot": "J9F0M4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F0M4"
  },
  {
    "uniprot": "J9BEG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BEG6"
  },
  {
    "uniprot": "A0A3P7FL52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL52"
  },
  {
    "uniprot": "A0A3P7E4A9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4A9"
  },
  {
    "uniprot": "A0A3P7FFW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFW9"
  },
  {
    "uniprot": "A0A3P7DC81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC81"
  },
  {
    "uniprot": "A0A3P7DKU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKU1"
  },
  {
    "uniprot": "A0A3P7E6K7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6K7"
  },
  {
    "uniprot": "A0A3P7DE18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE18"
  },
  {
    "uniprot": "A0A3P7EV35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EV35"
  },
  {
    "uniprot": "A0A3P7DYJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYJ2"
  },
  {
    "uniprot": "A0A3P7E9F7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9F7"
  },
  {
    "uniprot": "A0A3P7FFI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFI6"
  },
  {
    "uniprot": "A0A3P7EY95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY95"
  },
  {
    "uniprot": "A0A3P7DUX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUX8"
  },
  {
    "uniprot": "A0A3P7DW37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW37"
  },
  {
    "uniprot": "A0A3P7DHM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHM6"
  },
  {
    "uniprot": "A0A3P7F186",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F186"
  },
  {
    "uniprot": "A0A3P7FXJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXJ6"
  },
  {
    "uniprot": "A0A3P7GDX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDX3"
  },
  {
    "uniprot": "A0A3P7E3M5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3M5"
  },
  {
    "uniprot": "A0A3P7DME6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DME6"
  },
  {
    "uniprot": "A0A3P7EFE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFE4"
  },
  {
    "uniprot": "A0A3P7E8F0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8F0"
  },
  {
    "uniprot": "A0A3P7GE26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GE26"
  },
  {
    "uniprot": "A0A3P7EDK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDK0"
  },
  {
    "uniprot": "A0A3P7DKQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKQ2"
  },
  {
    "uniprot": "A0A3P7FYM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYM8"
  },
  {
    "uniprot": "A0A3P7F9L5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9L5"
  },
  {
    "uniprot": "A0A3P7DE58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE58"
  },
  {
    "uniprot": "A0A3P7DZE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZE3"
  },
  {
    "uniprot": "A0A3P7GAS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAS6"
  },
  {
    "uniprot": "A0A3P7FZV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZV2"
  },
  {
    "uniprot": "A0A3P7GHE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHE4"
  },
  {
    "uniprot": "A0A3P7EE87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE87"
  },
  {
    "uniprot": "J9FGU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FGU3"
  },
  {
    "uniprot": "A0A3P7E729",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E729"
  },
  {
    "uniprot": "A0A3P7ED65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED65"
  },
  {
    "uniprot": "A0A3P7E9A6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9A6"
  },
  {
    "uniprot": "A0A3P7E8Q2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8Q2"
  },
  {
    "uniprot": "A0A3P7E0V0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0V0"
  },
  {
    "uniprot": "A0A3P7FE90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE90"
  },
  {
    "uniprot": "A0A3P7F245",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F245"
  },
  {
    "uniprot": "A0A3P7G5P0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5P0"
  },
  {
    "uniprot": "A0A3P7ED78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED78"
  },
  {
    "uniprot": "A0A3P7FYE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYE4"
  },
  {
    "uniprot": "A0A3P7F545",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F545"
  },
  {
    "uniprot": "A0A3P7DIK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIK3"
  },
  {
    "uniprot": "A0A3P7FI96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI96"
  },
  {
    "uniprot": "A0A3P7DHB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHB4"
  },
  {
    "uniprot": "A0A3P7DXI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXI5"
  },
  {
    "uniprot": "A0A3P7FYD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYD3"
  },
  {
    "uniprot": "A0A3P7E9G3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9G3"
  },
  {
    "uniprot": "A0A3P7FU94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU94"
  },
  {
    "uniprot": "A0A3P7DWP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWP4"
  },
  {
    "uniprot": "A0A3P7DTV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTV7"
  },
  {
    "uniprot": "A0A3P7DL97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL97"
  },
  {
    "uniprot": "A0A3P7F7H3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7H3"
  },
  {
    "uniprot": "A0A3P7DU39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU39"
  },
  {
    "uniprot": "A0A3P7EDA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDA8"
  },
  {
    "uniprot": "A0A3P7DH16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH16"
  },
  {
    "uniprot": "A0A3P7FHN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHN3"
  },
  {
    "uniprot": "A0A3P7DBL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBL0"
  },
  {
    "uniprot": "A0A3P7FMH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMH2"
  },
  {
    "uniprot": "A0A183XI21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XI21"
  },
  {
    "uniprot": "A0A3P7FGT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGT2"
  },
  {
    "uniprot": "A0A3P7G7S2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G7S2"
  },
  {
    "uniprot": "A0A3P7E4P3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4P3"
  },
  {
    "uniprot": "J9FLF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FLF4"
  },
  {
    "uniprot": "A0A3P7E7M5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7M5"
  },
  {
    "uniprot": "J9EYR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYR7"
  },
  {
    "uniprot": "J9DPN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DPN6"
  },
  {
    "uniprot": "A0A3P7DZ92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ92"
  },
  {
    "uniprot": "A0A3P7DS03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS03"
  },
  {
    "uniprot": "A0A3P7EJX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJX0"
  },
  {
    "uniprot": "A0A3P7E8Q5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8Q5"
  },
  {
    "uniprot": "A0A3P7G3F8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3F8"
  },
  {
    "uniprot": "A0A3P7FLD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLD0"
  },
  {
    "uniprot": "A0A3P7FY15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY15"
  },
  {
    "uniprot": "A0A3P7E6G8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6G8"
  },
  {
    "uniprot": "A0A3P7EH08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH08"
  },
  {
    "uniprot": "A0A3P7EFK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFK6"
  },
  {
    "uniprot": "A0A3P7FXP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXP9"
  },
  {
    "uniprot": "A0A3P7DZN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZN6"
  },
  {
    "uniprot": "A0A3P7EIV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIV0"
  },
  {
    "uniprot": "A0A3P7GM37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GM37"
  },
  {
    "uniprot": "A0A3P7FFY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFY0"
  },
  {
    "uniprot": "A0A3P7ERN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERN3"
  },
  {
    "uniprot": "A0A3P7ELL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELL0"
  },
  {
    "uniprot": "A0A3P7DE61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE61"
  },
  {
    "uniprot": "A0A3P7E8L9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8L9"
  },
  {
    "uniprot": "A0A3P7EHT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHT7"
  },
  {
    "uniprot": "A0A3P7FKX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKX8"
  },
  {
    "uniprot": "A0A3P7EMU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMU9"
  },
  {
    "uniprot": "A0A183XEJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XEJ4"
  },
  {
    "uniprot": "A0A3P7FKR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKR9"
  },
  {
    "uniprot": "A0A3P7ED11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED11"
  },
  {
    "uniprot": "A0A3P7DEW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEW6"
  },
  {
    "uniprot": "A0A3P7DXW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXW6"
  },
  {
    "uniprot": "A0A3P7DJI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJI3"
  },
  {
    "uniprot": "A0A3P7ECQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECQ1"
  },
  {
    "uniprot": "A0A3P7DUB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUB5"
  },
  {
    "uniprot": "A0A3P7EE70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE70"
  },
  {
    "uniprot": "A0A3P7FRR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRR2"
  },
  {
    "uniprot": "A0A3P7DL62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL62"
  },
  {
    "uniprot": "A0A3P7EDM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDM5"
  },
  {
    "uniprot": "A0A3P7FGI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGI9"
  },
  {
    "uniprot": "A0A3P7EEH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEH2"
  },
  {
    "uniprot": "A0A3P7DFA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFA4"
  },
  {
    "uniprot": "A0A3P7E7E0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7E0"
  },
  {
    "uniprot": "A0A3P7EIU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIU3"
  },
  {
    "uniprot": "A0A3P7EF43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF43"
  },
  {
    "uniprot": "A0A3P7FTK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTK0"
  },
  {
    "uniprot": "A0A3P7GCE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCE9"
  },
  {
    "uniprot": "A0A3P7DW43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW43"
  },
  {
    "uniprot": "J9EDK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EDK3"
  },
  {
    "uniprot": "A0A3P7DDB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDB0"
  },
  {
    "uniprot": "A0A3P7DYZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYZ2"
  },
  {
    "uniprot": "J9FGW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FGW9"
  },
  {
    "uniprot": "A0A3P7EWH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWH1"
  },
  {
    "uniprot": "A0A3P7EIE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIE0"
  },
  {
    "uniprot": "A0A3P7ECH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECH3"
  },
  {
    "uniprot": "A0A3P7GMS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GMS0"
  },
  {
    "uniprot": "A0A3P7FTC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTC2"
  },
  {
    "uniprot": "A0A3P7F8W3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8W3"
  },
  {
    "uniprot": "A0A3P7DAF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAF3"
  },
  {
    "uniprot": "A0A3P7DAM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAM4"
  },
  {
    "uniprot": "A0A3P7FMC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMC6"
  },
  {
    "uniprot": "J9EMI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EMI7"
  },
  {
    "uniprot": "A0A3P7FQX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQX3"
  },
  {
    "uniprot": "A0A3P7DJY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJY4"
  },
  {
    "uniprot": "A0A3P7DTE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTE8"
  },
  {
    "uniprot": "A0A3P7DEG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEG6"
  },
  {
    "uniprot": "J9ERA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERA4"
  },
  {
    "uniprot": "A0A183XCH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCH0"
  },
  {
    "uniprot": "A0A3P7DSM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSM2"
  },
  {
    "uniprot": "A0A3P7DXC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXC2"
  },
  {
    "uniprot": "A0A3P7DZJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZJ2"
  },
  {
    "uniprot": "A0A3P7EFV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFV0"
  },
  {
    "uniprot": "A0A3P7ED94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED94"
  },
  {
    "uniprot": "A0A3P7EDK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDK2"
  },
  {
    "uniprot": "A0A3P7FKU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKU0"
  },
  {
    "uniprot": "A0A3P7FZF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZF2"
  },
  {
    "uniprot": "A0A3P7FGZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGZ6"
  },
  {
    "uniprot": "A0A3P7DFF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFF8"
  },
  {
    "uniprot": "J9ENJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ENJ4"
  },
  {
    "uniprot": "A0A3P7FT75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT75"
  },
  {
    "uniprot": "A0A3P7E081",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E081"
  },
  {
    "uniprot": "A0A3P7E1A2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1A2"
  },
  {
    "uniprot": "A0A3P7EZF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZF4"
  },
  {
    "uniprot": "A0A3P7EUJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUJ2"
  },
  {
    "uniprot": "A0A3P7FDF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDF7"
  },
  {
    "uniprot": "A0A3P7FG22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG22"
  },
  {
    "uniprot": "A0A3P7FEB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEB4"
  },
  {
    "uniprot": "A0A3P7F297",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F297"
  },
  {
    "uniprot": "A0A3P7DUF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUF0"
  },
  {
    "uniprot": "A0A183XK21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XK21"
  },
  {
    "uniprot": "A0A3P7DMD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMD4"
  },
  {
    "uniprot": "A0A3P7EE68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE68"
  },
  {
    "uniprot": "A0A3P7E3C3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3C3"
  },
  {
    "uniprot": "J9E9N1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E9N1"
  },
  {
    "uniprot": "A0A3P7EF93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF93"
  },
  {
    "uniprot": "A0A3P7E1B7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1B7"
  },
  {
    "uniprot": "A0A183XT38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XT38"
  },
  {
    "uniprot": "A0A3P7FJN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJN4"
  },
  {
    "uniprot": "A0A3P7FLF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLF9"
  },
  {
    "uniprot": "A0A3P7E066",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E066"
  },
  {
    "uniprot": "A0A3P7FZC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZC5"
  },
  {
    "uniprot": "A0A3P7EAD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAD8"
  },
  {
    "uniprot": "A0A3P7DSN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSN9"
  },
  {
    "uniprot": "J9BI14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BI14"
  },
  {
    "uniprot": "A0A3P7FBX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBX7"
  },
  {
    "uniprot": "A0A3P7E450",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E450"
  },
  {
    "uniprot": "A0A3P7DUH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUH5"
  },
  {
    "uniprot": "A0A3P7DRV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRV1"
  },
  {
    "uniprot": "A0A3P7FBD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBD4"
  },
  {
    "uniprot": "A0A3P7DCF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCF7"
  },
  {
    "uniprot": "J9EV04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EV04"
  },
  {
    "uniprot": "A0A3P7E1B8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1B8"
  },
  {
    "uniprot": "A0A3P7EB16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB16"
  },
  {
    "uniprot": "A0A3P7E4V1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4V1"
  },
  {
    "uniprot": "A0A3P7EKJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKJ4"
  },
  {
    "uniprot": "A0A3P7EU02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU02"
  },
  {
    "uniprot": "J9FKA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FKA4"
  },
  {
    "uniprot": "A0A183XDC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDC7"
  },
  {
    "uniprot": "A0A3P7ESW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESW6"
  },
  {
    "uniprot": "J9EAH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EAH5"
  },
  {
    "uniprot": "A0A3P7DWA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWA9"
  },
  {
    "uniprot": "A0A3P7DYF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYF0"
  },
  {
    "uniprot": "A0A3P7E817",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E817"
  },
  {
    "uniprot": "A0A3P7DP16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP16"
  },
  {
    "uniprot": "A0A3P7DWY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWY3"
  },
  {
    "uniprot": "A0A3P7FDJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDJ0"
  },
  {
    "uniprot": "A0A3P7E2T6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2T6"
  },
  {
    "uniprot": "J9F5S4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F5S4"
  },
  {
    "uniprot": "A0A3P7EXY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXY4"
  },
  {
    "uniprot": "A0A3P7FAN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAN1"
  },
  {
    "uniprot": "A0A3P7EMB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMB9"
  },
  {
    "uniprot": "J9F4K2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F4K2"
  },
  {
    "uniprot": "A0A3P7E1U6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1U6"
  },
  {
    "uniprot": "A0A3P7FFA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFA5"
  },
  {
    "uniprot": "A0A3P7FW97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW97"
  },
  {
    "uniprot": "A0A3P7FH02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH02"
  },
  {
    "uniprot": "A0A3P7E4Q7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4Q7"
  },
  {
    "uniprot": "A0A3P7E632",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E632"
  },
  {
    "uniprot": "J9EZU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZU4"
  },
  {
    "uniprot": "A0A3P7DTQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTQ8"
  },
  {
    "uniprot": "A0A3P7FY78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY78"
  },
  {
    "uniprot": "A0A3P7DRB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRB4"
  },
  {
    "uniprot": "A0A3P7FRW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRW4"
  },
  {
    "uniprot": "A0A3P7DEU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEU2"
  },
  {
    "uniprot": "A0A3P7DV18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV18"
  },
  {
    "uniprot": "A0A3P7DNT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNT2"
  },
  {
    "uniprot": "A0A3P7FUA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUA5"
  },
  {
    "uniprot": "A0A3P7EUE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUE2"
  },
  {
    "uniprot": "A0A3P7E6S7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6S7"
  },
  {
    "uniprot": "A0A3P7DUP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUP3"
  },
  {
    "uniprot": "J9EPM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPM5"
  },
  {
    "uniprot": "A0A3P7DQJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQJ4"
  },
  {
    "uniprot": "J9AUD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AUD2"
  },
  {
    "uniprot": "A0A3P7DE36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE36"
  },
  {
    "uniprot": "A0A3P7GCY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCY4"
  },
  {
    "uniprot": "A0A3P7EE93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE93"
  },
  {
    "uniprot": "A0A3P7EWC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWC4"
  },
  {
    "uniprot": "A0A3P7G3I2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3I2"
  },
  {
    "uniprot": "A0A3P7DXE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXE3"
  },
  {
    "uniprot": "A0A3P7G6Q3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6Q3"
  },
  {
    "uniprot": "A0A3P7DRK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRK7"
  },
  {
    "uniprot": "A0A3P7FBH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBH1"
  },
  {
    "uniprot": "A0A3P7DXK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXK9"
  },
  {
    "uniprot": "A0A3P7E8S4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8S4"
  },
  {
    "uniprot": "A0A3P7EJR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJR0"
  },
  {
    "uniprot": "A0A3P7DU28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU28"
  },
  {
    "uniprot": "A0A3P7E9S1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9S1"
  },
  {
    "uniprot": "A0A3P7DYE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYE7"
  },
  {
    "uniprot": "A0A3P7F321",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F321"
  },
  {
    "uniprot": "A0A3P7FRI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRI3"
  },
  {
    "uniprot": "J9FHS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FHS5"
  },
  {
    "uniprot": "A0A3P7FH91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH91"
  },
  {
    "uniprot": "A0A3P7FP95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP95"
  },
  {
    "uniprot": "J9EVQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EVQ4"
  },
  {
    "uniprot": "A0A3P7GDT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDT1"
  },
  {
    "uniprot": "A0A3P7FU62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU62"
  },
  {
    "uniprot": "A0A3P7DX58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX58"
  },
  {
    "uniprot": "A0A3P7E9Z5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9Z5"
  },
  {
    "uniprot": "A0A3P7F9M2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9M2"
  },
  {
    "uniprot": "A0A3P7EEZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEZ4"
  },
  {
    "uniprot": "A0A3P7DY64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY64"
  },
  {
    "uniprot": "A0A3P7EGP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGP2"
  },
  {
    "uniprot": "A0A3P7GN01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GN01"
  },
  {
    "uniprot": "A0A3P7E9F2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9F2"
  },
  {
    "uniprot": "A0A3P7EC73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC73"
  },
  {
    "uniprot": "A0A3P7DL55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL55"
  },
  {
    "uniprot": "A0A3P7DLW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLW2"
  },
  {
    "uniprot": "A0A3P7FGL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGL8"
  },
  {
    "uniprot": "A0A3P7E3Q7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3Q7"
  },
  {
    "uniprot": "A0A3P7E6I8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6I8"
  },
  {
    "uniprot": "A0A3P7DQZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQZ5"
  },
  {
    "uniprot": "A0A3P7FKK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKK4"
  },
  {
    "uniprot": "A0A3P7GF83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GF83"
  },
  {
    "uniprot": "A0A3P7E6T9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6T9"
  },
  {
    "uniprot": "A0A3P7FPX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPX5"
  },
  {
    "uniprot": "A0A3P7G844",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G844"
  },
  {
    "uniprot": "A0A3P7ENZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENZ6"
  },
  {
    "uniprot": "A0A3P7DBS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBS5"
  },
  {
    "uniprot": "A0A3P7DD50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD50"
  },
  {
    "uniprot": "A0A3P7FAC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAC1"
  },
  {
    "uniprot": "A0A3P7FC88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC88"
  },
  {
    "uniprot": "A0A3P7EBA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBA0"
  },
  {
    "uniprot": "A0A3P7E8J0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8J0"
  },
  {
    "uniprot": "A0A3P7E6D2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6D2"
  },
  {
    "uniprot": "A0A3P7FM00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM00"
  },
  {
    "uniprot": "A0A3P7EDA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDA3"
  },
  {
    "uniprot": "A0A3P7DJ91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ91"
  },
  {
    "uniprot": "A0A3P7EGB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGB0"
  },
  {
    "uniprot": "A0A3P7FJB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJB6"
  },
  {
    "uniprot": "J9FIV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FIV7"
  },
  {
    "uniprot": "A0A3P7GKS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKS3"
  },
  {
    "uniprot": "A0A3P7E9Q3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9Q3"
  },
  {
    "uniprot": "A0A3P7DRU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRU3"
  },
  {
    "uniprot": "A0A3P7E9U8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9U8"
  },
  {
    "uniprot": "A0A3P7D9T5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7D9T5"
  },
  {
    "uniprot": "J9F1K9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1K9"
  },
  {
    "uniprot": "A0A3P7FG93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG93"
  },
  {
    "uniprot": "A0A3P7ECU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECU1"
  },
  {
    "uniprot": "A0A3P7DQR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQR3"
  },
  {
    "uniprot": "A0A3P7FAZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAZ3"
  },
  {
    "uniprot": "J9B374",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B374"
  },
  {
    "uniprot": "A0A183XJ70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XJ70"
  },
  {
    "uniprot": "A0A3P7DK30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK30"
  },
  {
    "uniprot": "A0A3P7EDI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDI3"
  },
  {
    "uniprot": "A0A3P7DY78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY78"
  },
  {
    "uniprot": "A0A183XE25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XE25"
  },
  {
    "uniprot": "A0A3P7DU18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU18"
  },
  {
    "uniprot": "A0A3P7FC62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC62"
  },
  {
    "uniprot": "A0A3P7E0N7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0N7"
  },
  {
    "uniprot": "A0A3P7G2A8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2A8"
  },
  {
    "uniprot": "A0A3P7DT42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT42"
  },
  {
    "uniprot": "A0A3P7E5V5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5V5"
  },
  {
    "uniprot": "A0A3P7DLK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLK2"
  },
  {
    "uniprot": "A0A183XEW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XEW2"
  },
  {
    "uniprot": "A0A3P7EFS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFS9"
  },
  {
    "uniprot": "A0A3P7ER02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ER02"
  },
  {
    "uniprot": "A0A3P7FEA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEA4"
  },
  {
    "uniprot": "A0A183XF92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XF92"
  },
  {
    "uniprot": "A0A183XF41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XF41"
  },
  {
    "uniprot": "A0A183XD17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XD17"
  },
  {
    "uniprot": "A0A3P7DX23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX23"
  },
  {
    "uniprot": "A0A3P7ECF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECF0"
  },
  {
    "uniprot": "A0A3P7G358",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G358"
  },
  {
    "uniprot": "A0A3P7FPY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPY9"
  },
  {
    "uniprot": "A0A3P7EPF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPF1"
  },
  {
    "uniprot": "A0A3P7EEL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEL4"
  },
  {
    "uniprot": "A0A3P7E882",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E882"
  },
  {
    "uniprot": "A0A3P7E6H4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6H4"
  },
  {
    "uniprot": "A0A3P7FJX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJX3"
  },
  {
    "uniprot": "A0A3P7GEH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEH8"
  },
  {
    "uniprot": "A0A3P7DND2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DND2"
  },
  {
    "uniprot": "A0A3P7FU58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU58"
  },
  {
    "uniprot": "A0A183XGH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XGH5"
  },
  {
    "uniprot": "A0A3P7EI71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI71"
  },
  {
    "uniprot": "A0A3P7FNP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNP3"
  },
  {
    "uniprot": "J9ELI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ELI6"
  },
  {
    "uniprot": "A0A3P7EAF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAF1"
  },
  {
    "uniprot": "A0A3P7DU38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU38"
  },
  {
    "uniprot": "A0A3P7FC98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC98"
  },
  {
    "uniprot": "A0A3P7FGR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGR1"
  },
  {
    "uniprot": "A0A3P7F140",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F140"
  },
  {
    "uniprot": "A0A3P7DSG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSG1"
  },
  {
    "uniprot": "A0A3P7DR51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR51"
  },
  {
    "uniprot": "A0A3P7ELF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELF9"
  },
  {
    "uniprot": "A0A3P7FBK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBK3"
  },
  {
    "uniprot": "A0A3P7E6Y5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6Y5"
  },
  {
    "uniprot": "A0A3P7EBR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBR2"
  },
  {
    "uniprot": "A0A3P7DVW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVW6"
  },
  {
    "uniprot": "A0A3P7FM46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM46"
  },
  {
    "uniprot": "A0A3P7EGQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGQ4"
  },
  {
    "uniprot": "A0A3P7E3C4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3C4"
  },
  {
    "uniprot": "A0A3P7FSS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSS6"
  },
  {
    "uniprot": "A0A3P7E1Q4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1Q4"
  },
  {
    "uniprot": "J9EZA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZA6"
  },
  {
    "uniprot": "A0A3P7F397",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F397"
  },
  {
    "uniprot": "J9FNK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FNK5"
  },
  {
    "uniprot": "A0A3P7GHV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHV0"
  },
  {
    "uniprot": "A0A3P7FEH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEH2"
  },
  {
    "uniprot": "A0A3P7DK82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK82"
  },
  {
    "uniprot": "A0A3P7DB55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DB55"
  },
  {
    "uniprot": "A0A3P7ED40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED40"
  },
  {
    "uniprot": "A0A3P7F9L6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9L6"
  },
  {
    "uniprot": "J9BK57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BK57"
  },
  {
    "uniprot": "A0A3P7EG35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG35"
  },
  {
    "uniprot": "A0A3P7DR31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR31"
  },
  {
    "uniprot": "A0A3P7DH23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH23"
  },
  {
    "uniprot": "A0A3P7E985",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E985"
  },
  {
    "uniprot": "A0A3P7DZ27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ27"
  },
  {
    "uniprot": "A0A3P7EZH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZH0"
  },
  {
    "uniprot": "J9EF71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EF71"
  },
  {
    "uniprot": "A0A3P7FAH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAH3"
  },
  {
    "uniprot": "A0A3P7DAY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAY4"
  },
  {
    "uniprot": "A0A3P7F457",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F457"
  },
  {
    "uniprot": "A0A3P7FE10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE10"
  },
  {
    "uniprot": "A0A3P7DPP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPP1"
  },
  {
    "uniprot": "A0A3P7DNY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNY8"
  },
  {
    "uniprot": "A0A3P7E8R0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8R0"
  },
  {
    "uniprot": "A0A3P7FZA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZA6"
  },
  {
    "uniprot": "A0A3P7GLT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLT5"
  },
  {
    "uniprot": "A0A3P7EI49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI49"
  },
  {
    "uniprot": "A0A3P7E8P7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8P7"
  },
  {
    "uniprot": "A0A183XJJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XJJ3"
  },
  {
    "uniprot": "A0A3P7EAR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAR9"
  },
  {
    "uniprot": "A0A3P7E0Y7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0Y7"
  },
  {
    "uniprot": "J9FDN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FDN1"
  },
  {
    "uniprot": "A0A3P7GEH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEH4"
  },
  {
    "uniprot": "A0A3P7DE99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE99"
  },
  {
    "uniprot": "A0A3P7EJH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJH0"
  },
  {
    "uniprot": "A0A3P7ETH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETH9"
  },
  {
    "uniprot": "A0A3P7EMF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMF0"
  },
  {
    "uniprot": "A0A3P7E209",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E209"
  },
  {
    "uniprot": "A0A3P7EY89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY89"
  },
  {
    "uniprot": "A0A3P7DUF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUF6"
  },
  {
    "uniprot": "A0A183XLX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLX9"
  },
  {
    "uniprot": "A0A3P7DJQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJQ7"
  },
  {
    "uniprot": "A0A3P7DTT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTT1"
  },
  {
    "uniprot": "A0A3P7GKK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKK2"
  },
  {
    "uniprot": "A0A3P7GIH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIH9"
  },
  {
    "uniprot": "A0A3P7FY41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY41"
  },
  {
    "uniprot": "A0A3P7DQ15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ15"
  },
  {
    "uniprot": "A0A3P7DV86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV86"
  },
  {
    "uniprot": "A0A3P7ELF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELF0"
  },
  {
    "uniprot": "A0A3P7E5T1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5T1"
  },
  {
    "uniprot": "A0A3P7ELU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELU5"
  },
  {
    "uniprot": "A0A3P7DU17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU17"
  },
  {
    "uniprot": "A0A3P7EKL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKL4"
  },
  {
    "uniprot": "A0A3P7DU50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU50"
  },
  {
    "uniprot": "A0A3P7FR47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR47"
  },
  {
    "uniprot": "A0A3P7FC02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC02"
  },
  {
    "uniprot": "A0A3P7E650",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E650"
  },
  {
    "uniprot": "A0A3P7FY22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY22"
  },
  {
    "uniprot": "J9FEC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FEC7"
  },
  {
    "uniprot": "A0A3P7DBA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBA3"
  },
  {
    "uniprot": "A0A3P7DJ08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ08"
  },
  {
    "uniprot": "A0A3P7FWL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWL6"
  },
  {
    "uniprot": "A0A3P7EJW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJW4"
  },
  {
    "uniprot": "A0A3P7DI11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI11"
  },
  {
    "uniprot": "A0A3P7E8S0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8S0"
  },
  {
    "uniprot": "A0A3P7FE49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE49"
  },
  {
    "uniprot": "A0A3P7DEN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEN9"
  },
  {
    "uniprot": "A0A3P7E837",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E837"
  },
  {
    "uniprot": "A0A3P7EPX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPX8"
  },
  {
    "uniprot": "A0A3P7EFK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFK7"
  },
  {
    "uniprot": "J9AVU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AVU6"
  },
  {
    "uniprot": "A0A3P7EAY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAY5"
  },
  {
    "uniprot": "A0A3P7GH92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GH92"
  },
  {
    "uniprot": "A0A3P7DKR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKR1"
  },
  {
    "uniprot": "A0A3P7E832",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E832"
  },
  {
    "uniprot": "A0A3P7FYR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYR5"
  },
  {
    "uniprot": "A0A3P7EAE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAE4"
  },
  {
    "uniprot": "A0A3P7F350",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F350"
  },
  {
    "uniprot": "A0A3P7DAQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAQ3"
  },
  {
    "uniprot": "A0A3P7FWQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWQ2"
  },
  {
    "uniprot": "A0A3P7EV10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EV10"
  },
  {
    "uniprot": "A0A3P7DIH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIH7"
  },
  {
    "uniprot": "A0A3P7F2B1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2B1"
  },
  {
    "uniprot": "A0A3P7EJC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJC8"
  },
  {
    "uniprot": "A0A3P7EXZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXZ8"
  },
  {
    "uniprot": "A0A3P7FDH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDH5"
  },
  {
    "uniprot": "J9F588",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F588"
  },
  {
    "uniprot": "A0A3P7DLF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLF8"
  },
  {
    "uniprot": "J9DVQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DVQ9"
  },
  {
    "uniprot": "A0A183XPA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XPA1"
  },
  {
    "uniprot": "A0A3P7GM76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GM76"
  },
  {
    "uniprot": "A0A3P7FVN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVN1"
  },
  {
    "uniprot": "A0A3P7DM80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM80"
  },
  {
    "uniprot": "A0A3P7EQA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQA5"
  },
  {
    "uniprot": "J9F291",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F291"
  },
  {
    "uniprot": "A0A3P7G961",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G961"
  },
  {
    "uniprot": "A0A3P7DBI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBI8"
  },
  {
    "uniprot": "J9F0M2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F0M2"
  },
  {
    "uniprot": "A0A3P7FKV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKV9"
  },
  {
    "uniprot": "A0A3P7GEC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEC6"
  },
  {
    "uniprot": "J9BAP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BAP6"
  },
  {
    "uniprot": "A0A3P7ERQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERQ7"
  },
  {
    "uniprot": "A0A3P7DPH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPH3"
  },
  {
    "uniprot": "A0A3P7DIS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIS6"
  },
  {
    "uniprot": "A0A3P7EYE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYE7"
  },
  {
    "uniprot": "A0A3P7DY00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY00"
  },
  {
    "uniprot": "A0A3P7ELZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELZ1"
  },
  {
    "uniprot": "A0A3P7DZJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZJ7"
  },
  {
    "uniprot": "A0A3P7F0X4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0X4"
  },
  {
    "uniprot": "A0A183XE62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XE62"
  },
  {
    "uniprot": "J9E8P5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E8P5"
  },
  {
    "uniprot": "A0A3P7EG70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG70"
  },
  {
    "uniprot": "J9FE11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FE11"
  },
  {
    "uniprot": "A0A3P7EF73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF73"
  },
  {
    "uniprot": "A0A3P7FNU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNU0"
  },
  {
    "uniprot": "A0A3P7E7P7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7P7"
  },
  {
    "uniprot": "J9F023",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F023"
  },
  {
    "uniprot": "A0A3P7DZ91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ91"
  },
  {
    "uniprot": "A0A3P7DF84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF84"
  },
  {
    "uniprot": "A0A3P7E5D1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5D1"
  },
  {
    "uniprot": "A0A183XRS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XRS5"
  },
  {
    "uniprot": "A0A3P7DR94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR94"
  },
  {
    "uniprot": "A0A3P7FPX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPX0"
  },
  {
    "uniprot": "A0A3P7E7Z0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7Z0"
  },
  {
    "uniprot": "A0A3P7DPX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPX5"
  },
  {
    "uniprot": "J9EK85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EK85"
  },
  {
    "uniprot": "A0A3P7EG90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG90"
  },
  {
    "uniprot": "A0A3P7DYF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYF5"
  },
  {
    "uniprot": "A0A3P7G042",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G042"
  },
  {
    "uniprot": "A0A3P7FG96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG96"
  },
  {
    "uniprot": "A0A3P7GIG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIG1"
  },
  {
    "uniprot": "A0A3P7DNX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNX7"
  },
  {
    "uniprot": "A0A3P7E8T7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8T7"
  },
  {
    "uniprot": "A0A3P7DKD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKD8"
  },
  {
    "uniprot": "A0A3P7GHH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHH2"
  },
  {
    "uniprot": "A0A3P7FMV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMV0"
  },
  {
    "uniprot": "A0A3P7FDU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDU9"
  },
  {
    "uniprot": "A0A3P7E885",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E885"
  },
  {
    "uniprot": "A0A3P7EHN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHN6"
  },
  {
    "uniprot": "A0A3P7EM30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM30"
  },
  {
    "uniprot": "A0A3P7GCW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCW7"
  },
  {
    "uniprot": "A0A3P7FSD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSD5"
  },
  {
    "uniprot": "A0A3P7FCS7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCS7"
  },
  {
    "uniprot": "A0A3P7FLE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLE3"
  },
  {
    "uniprot": "J9EVW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EVW5"
  },
  {
    "uniprot": "A0A183XLG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLG8"
  },
  {
    "uniprot": "A0A3P7EHB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHB2"
  },
  {
    "uniprot": "A0A3P7E5E2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5E2"
  },
  {
    "uniprot": "A0A3P7EHK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHK0"
  },
  {
    "uniprot": "J9EXK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXK8"
  },
  {
    "uniprot": "A0A3P7E2N2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2N2"
  },
  {
    "uniprot": "A0A3P7FLJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLJ5"
  },
  {
    "uniprot": "A0A3P7FDH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDH1"
  },
  {
    "uniprot": "A0A3P7EFB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFB1"
  },
  {
    "uniprot": "J9AGY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AGY7"
  },
  {
    "uniprot": "A0A3P7DVJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVJ4"
  },
  {
    "uniprot": "J9EST8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EST8"
  },
  {
    "uniprot": "A0A3P7DE40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE40"
  },
  {
    "uniprot": "A0A3P7DWC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWC5"
  },
  {
    "uniprot": "A0A3P7EUI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUI5"
  },
  {
    "uniprot": "A0A3P7DRS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRS8"
  },
  {
    "uniprot": "A0A3P7FT65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT65"
  },
  {
    "uniprot": "A0A3P7F0B0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0B0"
  },
  {
    "uniprot": "A0A3P7DYE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYE0"
  },
  {
    "uniprot": "A0A3P7FL10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL10"
  },
  {
    "uniprot": "A0A3P7E7E7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7E7"
  },
  {
    "uniprot": "A0A183XIC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XIC3"
  },
  {
    "uniprot": "A0A3P7DUW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUW9"
  },
  {
    "uniprot": "A0A3P7FH81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH81"
  },
  {
    "uniprot": "A0A3P7FID1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FID1"
  },
  {
    "uniprot": "A0A3P7FGT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGT0"
  },
  {
    "uniprot": "A0A3P7FXL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXL0"
  },
  {
    "uniprot": "A0A3P7E6K6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6K6"
  },
  {
    "uniprot": "A0A3P7FZ28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ28"
  },
  {
    "uniprot": "A0A3P7EA57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA57"
  },
  {
    "uniprot": "A0A3P7FJA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJA0"
  },
  {
    "uniprot": "A0A3P7E191",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E191"
  },
  {
    "uniprot": "A0A3P7EG57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG57"
  },
  {
    "uniprot": "A0A3P7E0U6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0U6"
  },
  {
    "uniprot": "J9EDP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EDP1"
  },
  {
    "uniprot": "A0A3P7EYV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYV1"
  },
  {
    "uniprot": "A0A3P7E4B5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4B5"
  },
  {
    "uniprot": "A0A3P7ENH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENH2"
  },
  {
    "uniprot": "A0A3P7FGZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGZ1"
  },
  {
    "uniprot": "A0A3P7DT37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT37"
  },
  {
    "uniprot": "A0A3P7DPZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPZ5"
  },
  {
    "uniprot": "A0A3P7DJJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJJ8"
  },
  {
    "uniprot": "A0A3P7EMF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMF3"
  },
  {
    "uniprot": "A0A3P7F0Q5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0Q5"
  },
  {
    "uniprot": "A0A3P7E5P9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5P9"
  },
  {
    "uniprot": "A0A3P7DGS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGS9"
  },
  {
    "uniprot": "A0A3P7EIF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIF5"
  },
  {
    "uniprot": "A0A3P7DWR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWR9"
  },
  {
    "uniprot": "A0A3P7GGD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGD4"
  },
  {
    "uniprot": "A0A3P7DP53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP53"
  },
  {
    "uniprot": "A0A3P7EEP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEP4"
  },
  {
    "uniprot": "A0A3P7DYC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYC3"
  },
  {
    "uniprot": "A0A3P7FUR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUR4"
  },
  {
    "uniprot": "J9EK23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EK23"
  },
  {
    "uniprot": "A0A3P7E005",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E005"
  },
  {
    "uniprot": "J9EYV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYV3"
  },
  {
    "uniprot": "A0A3P7G318",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G318"
  },
  {
    "uniprot": "A0A3P7E010",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E010"
  },
  {
    "uniprot": "A0A3P7E6A2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6A2"
  },
  {
    "uniprot": "A0A3P7G4Z0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4Z0"
  },
  {
    "uniprot": "A0A3P7DQC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQC1"
  },
  {
    "uniprot": "A0A3P7FFR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFR9"
  },
  {
    "uniprot": "A0A183XMK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XMK3"
  },
  {
    "uniprot": "A0A183XD96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XD96"
  },
  {
    "uniprot": "A0A183XQ23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XQ23"
  },
  {
    "uniprot": "A0A3P7DV40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV40"
  },
  {
    "uniprot": "A0A3P7GKT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKT6"
  },
  {
    "uniprot": "A0A3P7EVX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVX6"
  },
  {
    "uniprot": "A0A3P7DXJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXJ8"
  },
  {
    "uniprot": "A0A3P7E663",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E663"
  },
  {
    "uniprot": "A0A3P7DRV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRV7"
  },
  {
    "uniprot": "A0A3P7DY32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY32"
  },
  {
    "uniprot": "A0A3P7FNF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNF6"
  },
  {
    "uniprot": "A0A3P7E9M8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9M8"
  },
  {
    "uniprot": "A0A3P7EAL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAL7"
  },
  {
    "uniprot": "A0A3P7EHY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHY5"
  },
  {
    "uniprot": "J9EGP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EGP0"
  },
  {
    "uniprot": "A0A3P7EDQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDQ3"
  },
  {
    "uniprot": "A0A3P7G211",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G211"
  },
  {
    "uniprot": "J9F894",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F894"
  },
  {
    "uniprot": "A0A3P7EBA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBA9"
  },
  {
    "uniprot": "A0A3P7DWR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWR8"
  },
  {
    "uniprot": "A0A3P7E3D8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3D8"
  },
  {
    "uniprot": "A0A3P7DX65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX65"
  },
  {
    "uniprot": "A0A3P7ENN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENN7"
  },
  {
    "uniprot": "A0A3P7FR98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR98"
  },
  {
    "uniprot": "A0A3P7EGS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGS3"
  },
  {
    "uniprot": "A0A3P7G1X5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1X5"
  },
  {
    "uniprot": "A0A3P7FZ38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ38"
  },
  {
    "uniprot": "A0A3P7E028",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E028"
  },
  {
    "uniprot": "A0A3P7DJ52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ52"
  },
  {
    "uniprot": "A0A3P7DXC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXC8"
  },
  {
    "uniprot": "A0A3P7DTM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTM8"
  },
  {
    "uniprot": "A0A3P7FP13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP13"
  },
  {
    "uniprot": "J9B4Z4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B4Z4"
  },
  {
    "uniprot": "A0A3P7DJG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJG1"
  },
  {
    "uniprot": "A0A3P7DWA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWA6"
  },
  {
    "uniprot": "A0A3P7G2R2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2R2"
  },
  {
    "uniprot": "A0A3P7DZD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZD9"
  },
  {
    "uniprot": "A0A3P7FQE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQE8"
  },
  {
    "uniprot": "A0A3P7FXY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXY8"
  },
  {
    "uniprot": "A0A3P7E7K7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7K7"
  },
  {
    "uniprot": "A0A3P7ETE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETE8"
  },
  {
    "uniprot": "A0A3P7EAY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAY0"
  },
  {
    "uniprot": "A0A3P7DGX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGX8"
  },
  {
    "uniprot": "A0A3P7DJV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJV1"
  },
  {
    "uniprot": "A0A3P7ERJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERJ7"
  },
  {
    "uniprot": "J9F2H9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2H9"
  },
  {
    "uniprot": "A0A3P7F1H3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1H3"
  },
  {
    "uniprot": "A0A183XHE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XHE6"
  },
  {
    "uniprot": "A0A3P7F9D8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9D8"
  },
  {
    "uniprot": "J9BFM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BFM0"
  },
  {
    "uniprot": "A0A3P7FNM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNM4"
  },
  {
    "uniprot": "A0A3P7GGW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGW9"
  },
  {
    "uniprot": "A0A3P7FLZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLZ5"
  },
  {
    "uniprot": "A0A3P7FGJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGJ7"
  },
  {
    "uniprot": "A0A3P7FRM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRM1"
  },
  {
    "uniprot": "A0A3P7EUS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUS1"
  },
  {
    "uniprot": "A0A3P7G2Z4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2Z4"
  },
  {
    "uniprot": "A0A183XT71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XT71"
  },
  {
    "uniprot": "A0A3P7FNX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNX8"
  },
  {
    "uniprot": "A0A3P7E268",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E268"
  },
  {
    "uniprot": "A0A3P7DSL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSL7"
  },
  {
    "uniprot": "A0A3P7DMJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMJ4"
  },
  {
    "uniprot": "A0A3P7EBA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBA3"
  },
  {
    "uniprot": "A0A3P7FBS4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBS4"
  },
  {
    "uniprot": "A0A3P7E9V5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9V5"
  },
  {
    "uniprot": "A0A183XDG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDG5"
  },
  {
    "uniprot": "A0A3P7FM35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM35"
  },
  {
    "uniprot": "A0A3P7DKN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKN3"
  },
  {
    "uniprot": "J9BM56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BM56"
  },
  {
    "uniprot": "A0A3P7DY94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY94"
  },
  {
    "uniprot": "A0A3P7E9U3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9U3"
  },
  {
    "uniprot": "A0A3P7EV21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EV21"
  },
  {
    "uniprot": "A0A3P7GIT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIT7"
  },
  {
    "uniprot": "A0A3P7F3Q1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3Q1"
  },
  {
    "uniprot": "J9F9V5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F9V5"
  },
  {
    "uniprot": "A0A3P7DGT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGT2"
  },
  {
    "uniprot": "A0A3P7DU93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU93"
  },
  {
    "uniprot": "A0A3P7E1I6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1I6"
  },
  {
    "uniprot": "A0A3P7DVK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVK7"
  },
  {
    "uniprot": "A0A183XF96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XF96"
  },
  {
    "uniprot": "J9FJF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FJF6"
  },
  {
    "uniprot": "A0A3P7DV03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV03"
  },
  {
    "uniprot": "A0A3P7EDC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDC7"
  },
  {
    "uniprot": "A0A3P7ELJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELJ3"
  },
  {
    "uniprot": "A0A3P7EIX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIX5"
  },
  {
    "uniprot": "A0A3P7GF05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GF05"
  },
  {
    "uniprot": "A0A3P7ED67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED67"
  },
  {
    "uniprot": "J9ET14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ET14"
  },
  {
    "uniprot": "A0A3P7EG91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG91"
  },
  {
    "uniprot": "A0A3P7G1N5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1N5"
  },
  {
    "uniprot": "A0A3P7E9C8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9C8"
  },
  {
    "uniprot": "A0A3P7EEJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEJ4"
  },
  {
    "uniprot": "A0A3P7EAK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAK7"
  },
  {
    "uniprot": "A0A183XJW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XJW2"
  },
  {
    "uniprot": "A0A3P7DQK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQK7"
  },
  {
    "uniprot": "A0A3P7DFD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFD7"
  },
  {
    "uniprot": "A0A3P7EP60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP60"
  },
  {
    "uniprot": "A0A3P7EN15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN15"
  },
  {
    "uniprot": "A0A3P7FU51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU51"
  },
  {
    "uniprot": "A0A3P7EAQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAQ5"
  },
  {
    "uniprot": "A0A3P7E1L5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1L5"
  },
  {
    "uniprot": "A0A3P7FMJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMJ2"
  },
  {
    "uniprot": "A0A3P7GCG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCG8"
  },
  {
    "uniprot": "A0A3P7EEX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEX7"
  },
  {
    "uniprot": "A0A3P7GDE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDE9"
  },
  {
    "uniprot": "A0A3P7F149",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F149"
  },
  {
    "uniprot": "A0A3P7DRR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRR6"
  },
  {
    "uniprot": "A0A3P7F8Y2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8Y2"
  },
  {
    "uniprot": "A0A3P7GA09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GA09"
  },
  {
    "uniprot": "A0A3P7DBX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBX8"
  },
  {
    "uniprot": "A0A3P7E7F8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7F8"
  },
  {
    "uniprot": "A0A183XUD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XUD9"
  },
  {
    "uniprot": "A0A3P7EAR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAR4"
  },
  {
    "uniprot": "A0A3P7FUV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUV0"
  },
  {
    "uniprot": "A0A3P7E0H2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0H2"
  },
  {
    "uniprot": "A0A3P7DIF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIF9"
  },
  {
    "uniprot": "A0A3P7FB97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB97"
  },
  {
    "uniprot": "A0A3P7E3C1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3C1"
  },
  {
    "uniprot": "A0A3P7DXN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXN7"
  },
  {
    "uniprot": "A0A3P7FAS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAS2"
  },
  {
    "uniprot": "A0A3P7DD32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD32"
  },
  {
    "uniprot": "A0A3P7GKI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKI3"
  },
  {
    "uniprot": "A0A3P7G237",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G237"
  },
  {
    "uniprot": "A0A3P7G6C8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6C8"
  },
  {
    "uniprot": "A0A3P7EC79",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC79"
  },
  {
    "uniprot": "A0A3P7E138",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E138"
  },
  {
    "uniprot": "J9EYD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYD9"
  },
  {
    "uniprot": "A0A3P7ES24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ES24"
  },
  {
    "uniprot": "A0A3P7E112",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E112"
  },
  {
    "uniprot": "A0A183XD74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XD74"
  },
  {
    "uniprot": "A0A183XZH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XZH7"
  },
  {
    "uniprot": "A0A3P7EBD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBD5"
  },
  {
    "uniprot": "A0A3P7FFV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFV1"
  },
  {
    "uniprot": "A0A3P7E295",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E295"
  },
  {
    "uniprot": "A0A3P7EXR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXR0"
  },
  {
    "uniprot": "A0A3P7DYT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYT1"
  },
  {
    "uniprot": "A0A3P7FYW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYW4"
  },
  {
    "uniprot": "J9F5V6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F5V6"
  },
  {
    "uniprot": "A0A3P7E1T8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1T8"
  },
  {
    "uniprot": "A0A3P7E171",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E171"
  },
  {
    "uniprot": "A0A3P7F7W2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7W2"
  },
  {
    "uniprot": "A0A3P7DXP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXP4"
  },
  {
    "uniprot": "A0A3P7EGC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGC2"
  },
  {
    "uniprot": "A0A3P7FBQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBQ4"
  },
  {
    "uniprot": "A0A183XEN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XEN0"
  },
  {
    "uniprot": "A0A3P7EDH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDH2"
  },
  {
    "uniprot": "A0A3P7ENF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENF2"
  },
  {
    "uniprot": "A0A3P7DIX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIX9"
  },
  {
    "uniprot": "A0A3P7E5D7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5D7"
  },
  {
    "uniprot": "A0A3P7DJB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJB2"
  },
  {
    "uniprot": "A0A3P7FCZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCZ3"
  },
  {
    "uniprot": "J9FG25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FG25"
  },
  {
    "uniprot": "A0A3P7ENF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENF3"
  },
  {
    "uniprot": "A0A3P7E597",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E597"
  },
  {
    "uniprot": "A0A3P7FJS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJS0"
  },
  {
    "uniprot": "A0A3P7FXR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXR4"
  },
  {
    "uniprot": "J9F235",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F235"
  },
  {
    "uniprot": "A0A3P7FZV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZV6"
  },
  {
    "uniprot": "A0A3P7E4L4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4L4"
  },
  {
    "uniprot": "A0A3P7DXJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXJ0"
  },
  {
    "uniprot": "A0A3P7DGC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGC0"
  },
  {
    "uniprot": "A0A3P7DX33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX33"
  },
  {
    "uniprot": "A0A3P7FPV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPV6"
  },
  {
    "uniprot": "A0A3P7ERL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERL8"
  },
  {
    "uniprot": "A0A3P7DRW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRW4"
  },
  {
    "uniprot": "A0A3P7E199",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E199"
  },
  {
    "uniprot": "A0A3P7FS78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS78"
  },
  {
    "uniprot": "A0A3P7FVT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVT5"
  },
  {
    "uniprot": "A0A183XEK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XEK3"
  },
  {
    "uniprot": "A0A3P7E113",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E113"
  },
  {
    "uniprot": "A0A3P7FHA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHA3"
  },
  {
    "uniprot": "A0A3P7E3P3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3P3"
  },
  {
    "uniprot": "A0A3P7GDE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDE0"
  },
  {
    "uniprot": "A0A3P7DBW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBW7"
  },
  {
    "uniprot": "A0A3P7DHA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHA6"
  },
  {
    "uniprot": "A0A3P7FQ11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ11"
  },
  {
    "uniprot": "A0A3P7F2U0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2U0"
  },
  {
    "uniprot": "A0A3P7FSQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSQ1"
  },
  {
    "uniprot": "A0A3P7DMI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMI8"
  },
  {
    "uniprot": "A0A3P7ED30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED30"
  },
  {
    "uniprot": "A0A3P7E5C6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5C6"
  },
  {
    "uniprot": "A0A3P7EH64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH64"
  },
  {
    "uniprot": "A0A3P7FA98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FA98"
  },
  {
    "uniprot": "A0A3P7DYZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYZ8"
  },
  {
    "uniprot": "A0A3P7E6S8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6S8"
  },
  {
    "uniprot": "A0A3P7FRC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRC1"
  },
  {
    "uniprot": "A0A3P7FPK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPK4"
  },
  {
    "uniprot": "A0A3P7DZ35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ35"
  },
  {
    "uniprot": "A0A3P7FK22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK22"
  },
  {
    "uniprot": "A0A3P7EB14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB14"
  },
  {
    "uniprot": "A0A3P7EW17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EW17"
  },
  {
    "uniprot": "A0A3P7FQX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQX4"
  },
  {
    "uniprot": "A0A3P7GK38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GK38"
  },
  {
    "uniprot": "A0A3P7DZ51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ51"
  },
  {
    "uniprot": "A0A3P7ECM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECM9"
  },
  {
    "uniprot": "A0A3P7DCY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCY6"
  },
  {
    "uniprot": "A0A3P7G1A2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1A2"
  },
  {
    "uniprot": "A0A3P7EDY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDY3"
  },
  {
    "uniprot": "A0A3P7E3V1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3V1"
  },
  {
    "uniprot": "A0A3P7FSY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSY0"
  },
  {
    "uniprot": "A0A3P7EGT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGT0"
  },
  {
    "uniprot": "J9F206",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F206"
  },
  {
    "uniprot": "J9EQU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQU9"
  },
  {
    "uniprot": "A0A3P7E3J7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3J7"
  },
  {
    "uniprot": "A0A3P7ECI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECI3"
  },
  {
    "uniprot": "A0A3P7EHY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHY2"
  },
  {
    "uniprot": "A0A3P7EJ80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ80"
  },
  {
    "uniprot": "A0A3P7FUX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUX2"
  },
  {
    "uniprot": "A0A3P7DUT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUT2"
  },
  {
    "uniprot": "A0A3P7ELQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELQ8"
  },
  {
    "uniprot": "A0A3P7EIC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIC7"
  },
  {
    "uniprot": "A0A3P7EHE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHE3"
  },
  {
    "uniprot": "A0A3P7DUE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUE2"
  },
  {
    "uniprot": "A0A3P7FXS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXS2"
  },
  {
    "uniprot": "A0A3P7DZN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZN9"
  },
  {
    "uniprot": "A0A3P7DXB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXB4"
  },
  {
    "uniprot": "A0A3P7DJ21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ21"
  },
  {
    "uniprot": "A0A3P7DGJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGJ9"
  },
  {
    "uniprot": "A0A3P7FFQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFQ1"
  },
  {
    "uniprot": "A0A3P7EIN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIN7"
  },
  {
    "uniprot": "A0A3P7DR93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR93"
  },
  {
    "uniprot": "A0A3P7EXM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXM7"
  },
  {
    "uniprot": "A0A3P7DG78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG78"
  },
  {
    "uniprot": "A0A3P7EJ03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ03"
  },
  {
    "uniprot": "A0A3P7DN19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN19"
  },
  {
    "uniprot": "A0A3P7DXM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXM0"
  },
  {
    "uniprot": "A0A3P7EF67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF67"
  },
  {
    "uniprot": "A0A3P7EUV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUV1"
  },
  {
    "uniprot": "A0A3P7GK91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GK91"
  },
  {
    "uniprot": "A0A3P7E5J7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5J7"
  },
  {
    "uniprot": "A0A3P7ETG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETG8"
  },
  {
    "uniprot": "A0A3P7G583",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G583"
  },
  {
    "uniprot": "A0A3P7E149",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E149"
  },
  {
    "uniprot": "A0A3P7EFX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFX1"
  },
  {
    "uniprot": "A0A3P7DSG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSG5"
  },
  {
    "uniprot": "J9B6Z7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B6Z7"
  },
  {
    "uniprot": "A0A183XBP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XBP2"
  },
  {
    "uniprot": "A0A3P7FC40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC40"
  },
  {
    "uniprot": "A0A3P7DQ16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ16"
  },
  {
    "uniprot": "A0A3P7DFE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFE9"
  },
  {
    "uniprot": "A0A3P7E9H9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9H9"
  },
  {
    "uniprot": "A0A3P7E9U2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9U2"
  },
  {
    "uniprot": "A0A3P7GBH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBH6"
  },
  {
    "uniprot": "A0A3P7EAN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAN0"
  },
  {
    "uniprot": "A0A3P7E989",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E989"
  },
  {
    "uniprot": "A0A3P7DIF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIF1"
  },
  {
    "uniprot": "A0A3P7EA09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA09"
  },
  {
    "uniprot": "A0A3P7FB00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB00"
  },
  {
    "uniprot": "A0A3P7FX75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX75"
  },
  {
    "uniprot": "A0A3P7ESZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESZ1"
  },
  {
    "uniprot": "A0A3P7DWZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWZ9"
  },
  {
    "uniprot": "A0A3P7DPC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPC6"
  },
  {
    "uniprot": "A0A3P7F4L1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F4L1"
  },
  {
    "uniprot": "A0A3P7F2N7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2N7"
  },
  {
    "uniprot": "A0A3P7DYY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYY4"
  },
  {
    "uniprot": "A0A3P7FL57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL57"
  },
  {
    "uniprot": "A0A3P7ETE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETE6"
  },
  {
    "uniprot": "A0A3P7ETC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETC6"
  },
  {
    "uniprot": "A0A3P7GBQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBQ8"
  },
  {
    "uniprot": "A0A3P7DY11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY11"
  },
  {
    "uniprot": "A0A3P7DUK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUK8"
  },
  {
    "uniprot": "A0A3P7G3E8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3E8"
  },
  {
    "uniprot": "A0A3P7EGV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGV5"
  },
  {
    "uniprot": "A0A3P7DIW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIW4"
  },
  {
    "uniprot": "J9EUQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUQ9"
  },
  {
    "uniprot": "A0A3P7FNW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNW9"
  },
  {
    "uniprot": "A0A3P7DZX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZX0"
  },
  {
    "uniprot": "A0A3P7EJN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJN7"
  },
  {
    "uniprot": "A0A3P7DZU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZU4"
  },
  {
    "uniprot": "A0A3P7DN29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN29"
  },
  {
    "uniprot": "A0A3P7ENB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENB4"
  },
  {
    "uniprot": "A0A3P7G8Y9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8Y9"
  },
  {
    "uniprot": "A0A3P7EHC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHC0"
  },
  {
    "uniprot": "A0A3P7EK76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK76"
  },
  {
    "uniprot": "A0A3P7DI87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI87"
  },
  {
    "uniprot": "A0A3P7DYR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYR7"
  },
  {
    "uniprot": "A0A3P7DTX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTX5"
  },
  {
    "uniprot": "A0A3P7EA39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA39"
  },
  {
    "uniprot": "A0A3P7EY32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY32"
  },
  {
    "uniprot": "A0A3P7FZH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZH7"
  },
  {
    "uniprot": "A0A3P7E348",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E348"
  },
  {
    "uniprot": "A0A3P7EP07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP07"
  },
  {
    "uniprot": "A0A3P7DGM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGM4"
  },
  {
    "uniprot": "A0A3P7GEA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEA3"
  },
  {
    "uniprot": "A0A3P7DU99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU99"
  },
  {
    "uniprot": "A0A3P7EWT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWT5"
  },
  {
    "uniprot": "A0A3P7EGF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGF5"
  },
  {
    "uniprot": "A0A3P7DY92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY92"
  },
  {
    "uniprot": "A0A3P7EEY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEY5"
  },
  {
    "uniprot": "A0A3P7EZ45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZ45"
  },
  {
    "uniprot": "A0A3P7DUW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUW5"
  },
  {
    "uniprot": "A0A3P7F9E7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9E7"
  },
  {
    "uniprot": "J9EYG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYG9"
  },
  {
    "uniprot": "A0A3P7DI31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI31"
  },
  {
    "uniprot": "A0A3P7EHY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHY8"
  },
  {
    "uniprot": "J9EHE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EHE8"
  },
  {
    "uniprot": "A0A183Y6Q1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y6Q1"
  },
  {
    "uniprot": "J9F035",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F035"
  },
  {
    "uniprot": "A0A3P7FLK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLK7"
  },
  {
    "uniprot": "J9FBH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FBH2"
  },
  {
    "uniprot": "A0A3P7F9B7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9B7"
  },
  {
    "uniprot": "A0A3P7E5A4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5A4"
  },
  {
    "uniprot": "A0A3P7E8C4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8C4"
  },
  {
    "uniprot": "A0A3P7DKF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKF4"
  },
  {
    "uniprot": "A0A3P7DVV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVV2"
  },
  {
    "uniprot": "A0A3P7DXW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXW0"
  },
  {
    "uniprot": "A0A3P7FXU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXU1"
  },
  {
    "uniprot": "A0A3P7E3U3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3U3"
  },
  {
    "uniprot": "A0A3P7DGY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGY0"
  },
  {
    "uniprot": "A0A3P7EQR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQR1"
  },
  {
    "uniprot": "A0A3P7DW45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW45"
  },
  {
    "uniprot": "J9BHH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BHH4"
  },
  {
    "uniprot": "A0A3P7EPG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPG5"
  },
  {
    "uniprot": "A0A3P7FZ58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ58"
  },
  {
    "uniprot": "A0A3P7FPB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPB2"
  },
  {
    "uniprot": "A0A3P7DY54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY54"
  },
  {
    "uniprot": "A0A3P7FJ41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ41"
  },
  {
    "uniprot": "A0A3P7DQK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQK3"
  },
  {
    "uniprot": "A0A183XHK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XHK0"
  },
  {
    "uniprot": "A0A3P7DIB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIB2"
  },
  {
    "uniprot": "J9F013",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F013"
  },
  {
    "uniprot": "A0A3P7DIX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIX2"
  },
  {
    "uniprot": "A0A3P7EFR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFR2"
  },
  {
    "uniprot": "A0A3P7EZ52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZ52"
  },
  {
    "uniprot": "J9E626",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E626"
  },
  {
    "uniprot": "A0A3P7E5H1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5H1"
  },
  {
    "uniprot": "A0A3P7DMK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMK7"
  },
  {
    "uniprot": "A0A3P7E2A6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2A6"
  },
  {
    "uniprot": "A0A3P7DVF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVF9"
  },
  {
    "uniprot": "A0A3P7E5P0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5P0"
  },
  {
    "uniprot": "A0A3P7FKD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKD6"
  },
  {
    "uniprot": "A0A3P7GHP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHP0"
  },
  {
    "uniprot": "A0A183XSS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XSS5"
  },
  {
    "uniprot": "A0A3P7FNE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNE0"
  },
  {
    "uniprot": "A0A3P7DBH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBH8"
  },
  {
    "uniprot": "A0A3P7E2U1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2U1"
  },
  {
    "uniprot": "A0A3P7EXS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXS2"
  },
  {
    "uniprot": "A0A3P7EHW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHW4"
  },
  {
    "uniprot": "A0A3P7FB25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB25"
  },
  {
    "uniprot": "J9EF20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EF20"
  },
  {
    "uniprot": "J9EVI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EVI4"
  },
  {
    "uniprot": "A0A3P7FQE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQE3"
  },
  {
    "uniprot": "A0A3P7DFX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFX9"
  },
  {
    "uniprot": "A0A3P7DS01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS01"
  },
  {
    "uniprot": "A0A3P7DZ97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ97"
  },
  {
    "uniprot": "A0A3P7DM63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM63"
  },
  {
    "uniprot": "A0A3P7GBY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBY0"
  },
  {
    "uniprot": "A0A3P7G0X2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0X2"
  },
  {
    "uniprot": "A0A3P7EH56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH56"
  },
  {
    "uniprot": "J9F126",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F126"
  },
  {
    "uniprot": "A0A3P7DHS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHS1"
  },
  {
    "uniprot": "A0A3P7DXD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXD5"
  },
  {
    "uniprot": "A0A3P7EAQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAQ9"
  },
  {
    "uniprot": "A0A3P7EBK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBK1"
  },
  {
    "uniprot": "A0A3P7FMU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMU0"
  },
  {
    "uniprot": "A0A183XJP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XJP1"
  },
  {
    "uniprot": "A0A3P7EIR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIR7"
  },
  {
    "uniprot": "A0A3P7E484",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E484"
  },
  {
    "uniprot": "J9ES40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ES40"
  },
  {
    "uniprot": "J9F0P2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F0P2"
  },
  {
    "uniprot": "A0A3P7E549",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E549"
  },
  {
    "uniprot": "A0A183XGQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XGQ0"
  },
  {
    "uniprot": "J9EUY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUY7"
  },
  {
    "uniprot": "A0A3P7E2Y7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2Y7"
  },
  {
    "uniprot": "A0A3P7EKJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKJ9"
  },
  {
    "uniprot": "A0A3P7E318",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E318"
  },
  {
    "uniprot": "A0A3P7EPR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPR3"
  },
  {
    "uniprot": "A0A3P7FX00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX00"
  },
  {
    "uniprot": "A0A3P7DYI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYI8"
  },
  {
    "uniprot": "A0A3P7DPG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPG1"
  },
  {
    "uniprot": "A0A3P7E0I3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0I3"
  },
  {
    "uniprot": "A0A3P7DU16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU16"
  },
  {
    "uniprot": "A0A3P7EY11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY11"
  },
  {
    "uniprot": "A0A3P7E1A1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1A1"
  },
  {
    "uniprot": "A0A3P7EJM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJM0"
  },
  {
    "uniprot": "A0A3P7FBH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBH7"
  },
  {
    "uniprot": "A0A3P7DVE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVE3"
  },
  {
    "uniprot": "A0A3P7E2C8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2C8"
  },
  {
    "uniprot": "A0A3P7E1L3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1L3"
  },
  {
    "uniprot": "A0A3P7E6L6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6L6"
  },
  {
    "uniprot": "A0A3P7EZW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZW2"
  },
  {
    "uniprot": "A0A3P7DML4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DML4"
  },
  {
    "uniprot": "A0A3P7DNY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNY2"
  },
  {
    "uniprot": "A0A3P7EQ67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQ67"
  },
  {
    "uniprot": "A0A3P7F2H7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2H7"
  },
  {
    "uniprot": "A0A3P7E701",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E701"
  },
  {
    "uniprot": "J9FBK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FBK8"
  },
  {
    "uniprot": "A0A3P7E1Z4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1Z4"
  },
  {
    "uniprot": "A0A3P7FLD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLD1"
  },
  {
    "uniprot": "A0A3P7DMB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMB0"
  },
  {
    "uniprot": "A0A3P7G4N1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4N1"
  },
  {
    "uniprot": "J9EPN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPN8"
  },
  {
    "uniprot": "A0A3P7E938",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E938"
  },
  {
    "uniprot": "A0A3P7E464",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E464"
  },
  {
    "uniprot": "A0A3P7FXP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXP3"
  },
  {
    "uniprot": "A0A3P7FFB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFB1"
  },
  {
    "uniprot": "A0A3P7DTB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTB1"
  },
  {
    "uniprot": "A0A183XBS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XBS9"
  },
  {
    "uniprot": "J9FAA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FAA0"
  },
  {
    "uniprot": "J9F7Y5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F7Y5"
  },
  {
    "uniprot": "J9ETH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ETH2"
  },
  {
    "uniprot": "A0A3P7FP68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP68"
  },
  {
    "uniprot": "A0A3P7FTW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTW4"
  },
  {
    "uniprot": "A0A3P7FQ85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ85"
  },
  {
    "uniprot": "A0A3P7E3P8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3P8"
  },
  {
    "uniprot": "A0A3P7EAC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAC4"
  },
  {
    "uniprot": "A0A3P7FZ98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ98"
  },
  {
    "uniprot": "A0A3P7DPS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPS5"
  },
  {
    "uniprot": "A0A3P7F1G0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1G0"
  },
  {
    "uniprot": "A0A3P7E2M4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2M4"
  },
  {
    "uniprot": "A0A3P7GJH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJH3"
  },
  {
    "uniprot": "A0A3P7E4M1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4M1"
  },
  {
    "uniprot": "A0A183Y405",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y405"
  },
  {
    "uniprot": "A0A3P7EA20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA20"
  },
  {
    "uniprot": "A0A3P7EZ21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZ21"
  },
  {
    "uniprot": "A0A3P7EMM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMM6"
  },
  {
    "uniprot": "A0A3P7EI08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI08"
  },
  {
    "uniprot": "A0A3P7D9U9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7D9U9"
  },
  {
    "uniprot": "A0A3P7ED44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED44"
  },
  {
    "uniprot": "A0A3P7DU57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU57"
  },
  {
    "uniprot": "A0A3P7EBM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBM1"
  },
  {
    "uniprot": "A0A3P7DX64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX64"
  },
  {
    "uniprot": "A0A3P7E0P3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0P3"
  },
  {
    "uniprot": "A0A3P7E5P3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5P3"
  },
  {
    "uniprot": "J9EUT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUT2"
  },
  {
    "uniprot": "A0A3P7FN97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN97"
  },
  {
    "uniprot": "A0A183XI08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XI08"
  },
  {
    "uniprot": "A0A3P7FK06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK06"
  },
  {
    "uniprot": "A0A3P7DWM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWM1"
  },
  {
    "uniprot": "A0A183XEV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XEV5"
  },
  {
    "uniprot": "A0A3P7EH65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH65"
  },
  {
    "uniprot": "A0A3P7FDG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDG5"
  },
  {
    "uniprot": "A0A3P7GJW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJW3"
  },
  {
    "uniprot": "A0A3P7EMY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMY2"
  },
  {
    "uniprot": "A0A3P7EDN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDN9"
  },
  {
    "uniprot": "A0A3P7DDR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDR2"
  },
  {
    "uniprot": "A0A3P7E4L6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4L6"
  },
  {
    "uniprot": "A0A3P7G0G3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0G3"
  },
  {
    "uniprot": "A0A3P7EQ29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQ29"
  },
  {
    "uniprot": "A0A3P7FKF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKF2"
  },
  {
    "uniprot": "A0A3P7GG87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GG87"
  },
  {
    "uniprot": "A0A3P7GDP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDP1"
  },
  {
    "uniprot": "A0A3P7FBC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBC3"
  },
  {
    "uniprot": "A0A3P7EH44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH44"
  },
  {
    "uniprot": "A0A3P7DXU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXU6"
  },
  {
    "uniprot": "A0A3P7DFG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFG9"
  },
  {
    "uniprot": "A0A3P7FK50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK50"
  },
  {
    "uniprot": "A0A3P7DVR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVR8"
  },
  {
    "uniprot": "A0A3P7FJA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJA7"
  },
  {
    "uniprot": "A0A3P7GJD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJD8"
  },
  {
    "uniprot": "A0A3P7EH85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH85"
  },
  {
    "uniprot": "A0A3P7FK54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK54"
  },
  {
    "uniprot": "A0A3P7DBN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBN6"
  },
  {
    "uniprot": "A0A3P7DWW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWW7"
  },
  {
    "uniprot": "A0A3P7DU45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU45"
  },
  {
    "uniprot": "A0A3P7ESN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESN4"
  },
  {
    "uniprot": "A0A3P7DQI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQI4"
  },
  {
    "uniprot": "A0A3P7GFM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFM9"
  },
  {
    "uniprot": "J9EZS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZS8"
  },
  {
    "uniprot": "A0A3P7EJR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJR8"
  },
  {
    "uniprot": "A0A3P7DIY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIY4"
  },
  {
    "uniprot": "A0A183XC56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XC56"
  },
  {
    "uniprot": "A0A3P7DTZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTZ9"
  },
  {
    "uniprot": "A0A3P7DWZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWZ2"
  },
  {
    "uniprot": "A0A3P7EH86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH86"
  },
  {
    "uniprot": "J9F1D2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1D2"
  },
  {
    "uniprot": "A0A3P7DKP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKP2"
  },
  {
    "uniprot": "A0A3P7DKI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKI4"
  },
  {
    "uniprot": "A0A3P7E482",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E482"
  },
  {
    "uniprot": "A0A3P7DYA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYA2"
  },
  {
    "uniprot": "A0A3P7GC09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GC09"
  },
  {
    "uniprot": "A0A3P7E9T1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9T1"
  },
  {
    "uniprot": "A0A3P7EGI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGI4"
  },
  {
    "uniprot": "A0A3P7F8F6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8F6"
  },
  {
    "uniprot": "A0A3P7E947",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E947"
  },
  {
    "uniprot": "A0A3P7E4S4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4S4"
  },
  {
    "uniprot": "A0A3P7EJJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJJ1"
  },
  {
    "uniprot": "A0A3P7GIK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIK0"
  },
  {
    "uniprot": "A0A3P7DT72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT72"
  },
  {
    "uniprot": "A0A3P7E6R5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6R5"
  },
  {
    "uniprot": "A0A3P7EEZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEZ1"
  },
  {
    "uniprot": "A0A3P7DTK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTK2"
  },
  {
    "uniprot": "A0A3P7DQ22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ22"
  },
  {
    "uniprot": "A0A3P7DCJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCJ0"
  },
  {
    "uniprot": "A0A3P7E8L8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8L8"
  },
  {
    "uniprot": "A0A3P7E2M5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2M5"
  },
  {
    "uniprot": "A0A3P7GEY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEY8"
  },
  {
    "uniprot": "A0A3P7DBP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBP7"
  },
  {
    "uniprot": "A0A3P7FFP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFP9"
  },
  {
    "uniprot": "A0A3P7EDV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDV6"
  },
  {
    "uniprot": "A0A3P7EF98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF98"
  },
  {
    "uniprot": "A0A3P7DRP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRP9"
  },
  {
    "uniprot": "A0A183XBL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XBL9"
  },
  {
    "uniprot": "A0A3P7DGV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGV6"
  },
  {
    "uniprot": "A0A3P7DFL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFL5"
  },
  {
    "uniprot": "A0A3P7DVX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVX9"
  },
  {
    "uniprot": "A0A3P7EFJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFJ8"
  },
  {
    "uniprot": "A0A3P7DRV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRV2"
  },
  {
    "uniprot": "A0A3P7DM05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM05"
  },
  {
    "uniprot": "A0A3P7E446",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E446"
  },
  {
    "uniprot": "A0A3P7DZ05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ05"
  },
  {
    "uniprot": "A0A3P7EAS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAS2"
  },
  {
    "uniprot": "A0A3P7DLI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLI2"
  },
  {
    "uniprot": "J9F8S5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F8S5"
  },
  {
    "uniprot": "J9F900",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F900"
  },
  {
    "uniprot": "A0A3P7DJI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJI1"
  },
  {
    "uniprot": "A0A3P7DMD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMD2"
  },
  {
    "uniprot": "A0A3P7FRI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRI4"
  },
  {
    "uniprot": "A0A3P7EJZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJZ6"
  },
  {
    "uniprot": "A0A3P7F1W1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1W1"
  },
  {
    "uniprot": "A0A3P7EFA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFA1"
  },
  {
    "uniprot": "A0A3P7DLR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLR1"
  },
  {
    "uniprot": "A0A3P7E2M1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2M1"
  },
  {
    "uniprot": "A0A3P7FPR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPR8"
  },
  {
    "uniprot": "A0A3P7ER26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ER26"
  },
  {
    "uniprot": "A0A3P7F1Y4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1Y4"
  },
  {
    "uniprot": "A0A3P7E193",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E193"
  },
  {
    "uniprot": "A0A3P7E765",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E765"
  },
  {
    "uniprot": "A0A3P7DUB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUB8"
  },
  {
    "uniprot": "A0A3P7DJA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJA4"
  },
  {
    "uniprot": "A0A3P7FV91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV91"
  },
  {
    "uniprot": "A0A3P7DXG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXG2"
  },
  {
    "uniprot": "A0A3P7E548",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E548"
  },
  {
    "uniprot": "A0A3P7E7N4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7N4"
  },
  {
    "uniprot": "A0A3P7DL14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL14"
  },
  {
    "uniprot": "A0A3P7DWE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWE1"
  },
  {
    "uniprot": "A0A3P7ECG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECG0"
  },
  {
    "uniprot": "A0A3P7EJE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJE0"
  },
  {
    "uniprot": "J9BEL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BEL8"
  },
  {
    "uniprot": "A0A3P7DJ62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ62"
  },
  {
    "uniprot": "J9FCH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FCH6"
  },
  {
    "uniprot": "A0A3P7DCX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCX6"
  },
  {
    "uniprot": "J9DVP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DVP8"
  },
  {
    "uniprot": "A0A3P7EM55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM55"
  },
  {
    "uniprot": "A0A183Y2Y0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y2Y0"
  },
  {
    "uniprot": "A0A183XNG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XNG5"
  },
  {
    "uniprot": "A0A3P7EH82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH82"
  },
  {
    "uniprot": "A0A3P7EM74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM74"
  },
  {
    "uniprot": "A0A3P7DU27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU27"
  },
  {
    "uniprot": "A0A3P7E9C3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9C3"
  },
  {
    "uniprot": "A0A3P7FRK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRK3"
  },
  {
    "uniprot": "A0A3P7EEK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEK9"
  },
  {
    "uniprot": "A0A3P7DHV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHV1"
  },
  {
    "uniprot": "A0A3P7DJG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJG9"
  },
  {
    "uniprot": "A0A3P7DXU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXU4"
  },
  {
    "uniprot": "A0A3P7EWK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWK8"
  },
  {
    "uniprot": "A0A3P7DCK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCK3"
  },
  {
    "uniprot": "A0A3P7ECD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECD2"
  },
  {
    "uniprot": "A0A3P7DTU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTU0"
  },
  {
    "uniprot": "A0A3P7DXT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXT8"
  },
  {
    "uniprot": "A0A3P7DDS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDS0"
  },
  {
    "uniprot": "J9B7D9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B7D9"
  },
  {
    "uniprot": "A0A3P7E4N1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4N1"
  },
  {
    "uniprot": "A0A3P7DCI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCI0"
  },
  {
    "uniprot": "A0A3P7E2D1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2D1"
  },
  {
    "uniprot": "A0A3P7E0B1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0B1"
  },
  {
    "uniprot": "A0A183XLP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLP3"
  },
  {
    "uniprot": "A0A3P7FQQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQQ0"
  },
  {
    "uniprot": "A0A3P7FTU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTU2"
  },
  {
    "uniprot": "A0A3P7E0K9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0K9"
  },
  {
    "uniprot": "A0A3P7EXQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXQ1"
  },
  {
    "uniprot": "A0A3P7EIK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIK2"
  },
  {
    "uniprot": "A0A3P7ENV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENV7"
  },
  {
    "uniprot": "A0A3P7DUN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUN4"
  },
  {
    "uniprot": "A0A3P7E1E8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1E8"
  },
  {
    "uniprot": "A0A3P7FPT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPT2"
  },
  {
    "uniprot": "A0A3P7DQP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQP1"
  },
  {
    "uniprot": "A0A3P7FN61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN61"
  },
  {
    "uniprot": "A0A3P7DFI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFI8"
  },
  {
    "uniprot": "A0A3P7EGR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGR2"
  },
  {
    "uniprot": "A0A3P7E530",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E530"
  },
  {
    "uniprot": "A0A3P7DVM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVM9"
  },
  {
    "uniprot": "A0A3P7DZF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZF2"
  },
  {
    "uniprot": "A0A3P7DYH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYH4"
  },
  {
    "uniprot": "A0A3P7DU40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU40"
  },
  {
    "uniprot": "A0A3P7DGJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGJ3"
  },
  {
    "uniprot": "A0A3P7FEK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEK1"
  },
  {
    "uniprot": "A0A3P7F3M7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3M7"
  },
  {
    "uniprot": "A0A3P7EST7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EST7"
  },
  {
    "uniprot": "A0A3P7E0C7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0C7"
  },
  {
    "uniprot": "A0A3P7DTA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTA4"
  },
  {
    "uniprot": "A0A3P7EBI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBI4"
  },
  {
    "uniprot": "A0A3P7E427",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E427"
  },
  {
    "uniprot": "A0A3P7EA93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA93"
  },
  {
    "uniprot": "A0A3P7E053",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E053"
  },
  {
    "uniprot": "A0A183XII7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XII7"
  },
  {
    "uniprot": "A0A3P7DB18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DB18"
  },
  {
    "uniprot": "A0A3P7DVG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVG4"
  },
  {
    "uniprot": "A0A3P7GJN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJN2"
  },
  {
    "uniprot": "A0A3P7FAM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAM1"
  },
  {
    "uniprot": "A0A3P7DHD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHD4"
  },
  {
    "uniprot": "A0A3P7EDW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDW5"
  },
  {
    "uniprot": "A0A3P7E361",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E361"
  },
  {
    "uniprot": "A0A3P7E5H3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5H3"
  },
  {
    "uniprot": "A0A3P7E218",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E218"
  },
  {
    "uniprot": "A0A3P7DFZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFZ0"
  },
  {
    "uniprot": "A0A3P7FTT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTT7"
  },
  {
    "uniprot": "A0A3P7DHT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHT4"
  },
  {
    "uniprot": "A0A3P7ET42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET42"
  },
  {
    "uniprot": "A0A3P7DVC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVC7"
  },
  {
    "uniprot": "A0A3P7DFD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFD5"
  },
  {
    "uniprot": "A0A3P7E3K4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3K4"
  },
  {
    "uniprot": "A0A3P7FD94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD94"
  },
  {
    "uniprot": "A0A3P7DQF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQF1"
  },
  {
    "uniprot": "J9ENY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ENY8"
  },
  {
    "uniprot": "A0A3P7EBJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBJ9"
  },
  {
    "uniprot": "A0A3P7G1Y4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1Y4"
  },
  {
    "uniprot": "A0A3P7FTX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTX3"
  },
  {
    "uniprot": "A0A3P7FWC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWC8"
  },
  {
    "uniprot": "A0A3P7FIS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIS1"
  },
  {
    "uniprot": "A0A3P7G9V9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9V9"
  },
  {
    "uniprot": "A0A3P7FF82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF82"
  },
  {
    "uniprot": "A0A3P7G1V3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1V3"
  },
  {
    "uniprot": "A0A3P7FF86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF86"
  },
  {
    "uniprot": "A0A3P7FQL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQL6"
  },
  {
    "uniprot": "A0A3P7DKE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKE7"
  },
  {
    "uniprot": "A0A3P7DL23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL23"
  },
  {
    "uniprot": "A0A3P7ELU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELU2"
  },
  {
    "uniprot": "A0A3P7ELD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELD4"
  },
  {
    "uniprot": "A0A3P7DNF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNF4"
  },
  {
    "uniprot": "A0A3P7EAZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAZ4"
  },
  {
    "uniprot": "A0A3P7DR42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR42"
  },
  {
    "uniprot": "A0A3P7F870",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F870"
  },
  {
    "uniprot": "A0A3P7FLZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLZ2"
  },
  {
    "uniprot": "A0A3P7EI40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI40"
  },
  {
    "uniprot": "A0A3P7DFY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFY5"
  },
  {
    "uniprot": "A0A183Y253",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y253"
  },
  {
    "uniprot": "A0A3P7F3V8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3V8"
  },
  {
    "uniprot": "A0A3P7ECF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECF9"
  },
  {
    "uniprot": "A0A3P7E7P8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7P8"
  },
  {
    "uniprot": "A0A3P7DSZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSZ4"
  },
  {
    "uniprot": "A0A3P7DXD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXD1"
  },
  {
    "uniprot": "A0A3P7F0V1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0V1"
  },
  {
    "uniprot": "J9F9X2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F9X2"
  },
  {
    "uniprot": "A0A3P7E8H6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8H6"
  },
  {
    "uniprot": "A0A3P7E963",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E963"
  },
  {
    "uniprot": "A0A3P7GGB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGB2"
  },
  {
    "uniprot": "A0A3P7E0Y1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0Y1"
  },
  {
    "uniprot": "A0A3P7DLJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLJ0"
  },
  {
    "uniprot": "A0A3P7DUK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUK1"
  },
  {
    "uniprot": "A0A3P7DTB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTB6"
  },
  {
    "uniprot": "A0A3P7GDB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDB0"
  },
  {
    "uniprot": "A0A3P7E966",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E966"
  },
  {
    "uniprot": "J9B591",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B591"
  },
  {
    "uniprot": "A0A3P7G2Q2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2Q2"
  },
  {
    "uniprot": "A0A3P7DN44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN44"
  },
  {
    "uniprot": "A0A3P7EP18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP18"
  },
  {
    "uniprot": "A0A3P7DT74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT74"
  },
  {
    "uniprot": "A0A3P7DV70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV70"
  },
  {
    "uniprot": "J9EWK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EWK8"
  },
  {
    "uniprot": "A0A3P7G006",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G006"
  },
  {
    "uniprot": "A0A3P7E850",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E850"
  },
  {
    "uniprot": "A0A3P7DTR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTR9"
  },
  {
    "uniprot": "A0A3P7FUN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUN7"
  },
  {
    "uniprot": "J9F2K1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2K1"
  },
  {
    "uniprot": "A0A3P7F4F4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F4F4"
  },
  {
    "uniprot": "J9AJF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AJF4"
  },
  {
    "uniprot": "A0A3P7EM45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM45"
  },
  {
    "uniprot": "A0A3P7FGG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGG9"
  },
  {
    "uniprot": "J9FM19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FM19"
  },
  {
    "uniprot": "A0A3P7EIU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIU7"
  },
  {
    "uniprot": "A0A3P7E9K9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9K9"
  },
  {
    "uniprot": "A0A3P7GF93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GF93"
  },
  {
    "uniprot": "A0A3P7DPL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPL9"
  },
  {
    "uniprot": "A0A3P7FEE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEE9"
  },
  {
    "uniprot": "A0A3P7G1N6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1N6"
  },
  {
    "uniprot": "A0A3P7EYE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYE1"
  },
  {
    "uniprot": "A0A3P7E8A6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8A6"
  },
  {
    "uniprot": "A0A3P7DNS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNS9"
  },
  {
    "uniprot": "A0A3P7E7H9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7H9"
  },
  {
    "uniprot": "A0A3P7EBW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBW6"
  },
  {
    "uniprot": "A0A3P7E4A0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4A0"
  },
  {
    "uniprot": "A0A3P7DQY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQY4"
  },
  {
    "uniprot": "A0A3P7DQD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQD6"
  },
  {
    "uniprot": "J9EW99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EW99"
  },
  {
    "uniprot": "A0A3P7E406",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E406"
  },
  {
    "uniprot": "A0A3P7FMJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMJ1"
  },
  {
    "uniprot": "A0A3P7FQG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQG3"
  },
  {
    "uniprot": "A0A3P7DHB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHB3"
  },
  {
    "uniprot": "A0A3P7ERF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERF3"
  },
  {
    "uniprot": "A0A3P7FNA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNA0"
  },
  {
    "uniprot": "A0A3P7FZQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZQ2"
  },
  {
    "uniprot": "A0A3P7DXH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXH0"
  },
  {
    "uniprot": "A0A3P7EV55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EV55"
  },
  {
    "uniprot": "A0A3P7E1B1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1B1"
  },
  {
    "uniprot": "A0A3P7DMS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMS0"
  },
  {
    "uniprot": "A0A3P7DZE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZE4"
  },
  {
    "uniprot": "A0A3P7ESM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESM1"
  },
  {
    "uniprot": "A0A3P7FC11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC11"
  },
  {
    "uniprot": "A0A3P7DT44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT44"
  },
  {
    "uniprot": "A0A3P7E6Q4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6Q4"
  },
  {
    "uniprot": "A0A3P7DEM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEM0"
  },
  {
    "uniprot": "A0A3P7EG78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG78"
  },
  {
    "uniprot": "A0A3P7DT43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT43"
  },
  {
    "uniprot": "A0A3P7E2S6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2S6"
  },
  {
    "uniprot": "J9ELL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ELL5"
  },
  {
    "uniprot": "A0A3P7G8E8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8E8"
  },
  {
    "uniprot": "A0A3P7EDX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDX9"
  },
  {
    "uniprot": "J9EC14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EC14"
  },
  {
    "uniprot": "A0A3P7FP86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP86"
  },
  {
    "uniprot": "A0A3P7E800",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E800"
  },
  {
    "uniprot": "A0A3P7FD36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD36"
  },
  {
    "uniprot": "A0A3P7ET08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET08"
  },
  {
    "uniprot": "A0A3P7DMY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMY1"
  },
  {
    "uniprot": "A0A3P7G9Z5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9Z5"
  },
  {
    "uniprot": "A0A3P7DL12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL12"
  },
  {
    "uniprot": "J9EY20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EY20"
  },
  {
    "uniprot": "A0A3P7FBU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBU5"
  },
  {
    "uniprot": "A0A3P7EAW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAW3"
  },
  {
    "uniprot": "A0A3P7ERK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERK4"
  },
  {
    "uniprot": "A0A3P7E8H0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8H0"
  },
  {
    "uniprot": "A0A3P7E6Z1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6Z1"
  },
  {
    "uniprot": "A0A3P7DNC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNC0"
  },
  {
    "uniprot": "A0A3P7FJC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJC8"
  },
  {
    "uniprot": "A0A3P7DPD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPD4"
  },
  {
    "uniprot": "A0A3P7FZ68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ68"
  },
  {
    "uniprot": "A0A3P7ED01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED01"
  },
  {
    "uniprot": "A0A3P7DQ21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ21"
  },
  {
    "uniprot": "A0A3P7FP23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP23"
  },
  {
    "uniprot": "A0A3P7EIM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIM2"
  },
  {
    "uniprot": "A0A3P7E0J0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0J0"
  },
  {
    "uniprot": "A0A3P7DY75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY75"
  },
  {
    "uniprot": "A0A3P7DAT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAT6"
  },
  {
    "uniprot": "A0A3P7E012",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E012"
  },
  {
    "uniprot": "A0A3P7DX00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX00"
  },
  {
    "uniprot": "A0A3P7GAZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAZ8"
  },
  {
    "uniprot": "A0A3P7DTY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTY3"
  },
  {
    "uniprot": "A0A3P7FGP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGP7"
  },
  {
    "uniprot": "A0A3P7EVG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVG0"
  },
  {
    "uniprot": "A0A3P7FHS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHS6"
  },
  {
    "uniprot": "A0A3P7DYU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYU3"
  },
  {
    "uniprot": "A0A3P7EGX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGX3"
  },
  {
    "uniprot": "A0A3P7E122",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E122"
  },
  {
    "uniprot": "A0A3P7ER62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ER62"
  },
  {
    "uniprot": "A0A3P7EU19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU19"
  },
  {
    "uniprot": "A0A3P7FD21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD21"
  },
  {
    "uniprot": "A0A3P7EWN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWN1"
  },
  {
    "uniprot": "A0A3P7ELY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELY7"
  },
  {
    "uniprot": "A0A3P7FK75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK75"
  },
  {
    "uniprot": "A0A3P7DTE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTE1"
  },
  {
    "uniprot": "A0A3P7E2I6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2I6"
  },
  {
    "uniprot": "J9F1B3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1B3"
  },
  {
    "uniprot": "A0A3P7DZ02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ02"
  },
  {
    "uniprot": "A0A3P7EI07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI07"
  },
  {
    "uniprot": "A0A3P7E6J9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6J9"
  },
  {
    "uniprot": "A0A3P7DTV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTV4"
  },
  {
    "uniprot": "A0A3P7DV85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV85"
  },
  {
    "uniprot": "A0A3P7FLE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLE1"
  },
  {
    "uniprot": "A0A3P7EG93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG93"
  },
  {
    "uniprot": "A0A3P7DH84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH84"
  },
  {
    "uniprot": "A0A3P7DNY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNY0"
  },
  {
    "uniprot": "A0A3P7EKW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKW9"
  },
  {
    "uniprot": "A0A3P7DRI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRI1"
  },
  {
    "uniprot": "A0A3P7FSJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSJ1"
  },
  {
    "uniprot": "A0A3P7FG71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG71"
  },
  {
    "uniprot": "A0A3P7E554",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E554"
  },
  {
    "uniprot": "A0A3P7ENU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENU5"
  },
  {
    "uniprot": "A0A183XBB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XBB8"
  },
  {
    "uniprot": "A0A3P7FU04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU04"
  },
  {
    "uniprot": "A0A3P7EBQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBQ4"
  },
  {
    "uniprot": "A0A3P7E6F0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6F0"
  },
  {
    "uniprot": "A0A3P7EKD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKD9"
  },
  {
    "uniprot": "A0A3P7F7X1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7X1"
  },
  {
    "uniprot": "A0A3P7EWG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWG2"
  },
  {
    "uniprot": "A0A3P7DYY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYY5"
  },
  {
    "uniprot": "A0A3P7EN38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN38"
  },
  {
    "uniprot": "A0A183Y746",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y746"
  },
  {
    "uniprot": "A0A3P7DX32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX32"
  },
  {
    "uniprot": "A0A3P7EGS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGS8"
  },
  {
    "uniprot": "J9BGY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BGY9"
  },
  {
    "uniprot": "J9BAT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BAT1"
  },
  {
    "uniprot": "A0A3P7DHZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHZ6"
  },
  {
    "uniprot": "A0A3P7EBK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBK8"
  },
  {
    "uniprot": "A0A3P7DU89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU89"
  },
  {
    "uniprot": "A0A3P7DSC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSC0"
  },
  {
    "uniprot": "A0A3P7EIR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIR5"
  },
  {
    "uniprot": "A0A3P7EEM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEM2"
  },
  {
    "uniprot": "A0A3P7F9H2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9H2"
  },
  {
    "uniprot": "A0A3P7EE17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE17"
  },
  {
    "uniprot": "A0A3P7DZS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZS1"
  },
  {
    "uniprot": "A0A3P7EGW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGW4"
  },
  {
    "uniprot": "A0A183XEM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XEM2"
  },
  {
    "uniprot": "A0A3P7E415",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E415"
  },
  {
    "uniprot": "A0A3P7FV41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV41"
  },
  {
    "uniprot": "J9DW61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DW61"
  },
  {
    "uniprot": "A0A3P7E7B7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7B7"
  },
  {
    "uniprot": "A0A3P7EIJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIJ3"
  },
  {
    "uniprot": "A0A3P7G2J7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2J7"
  },
  {
    "uniprot": "A0A3P7DJV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJV6"
  },
  {
    "uniprot": "J9EU21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EU21"
  },
  {
    "uniprot": "A0A183XZL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XZL5"
  },
  {
    "uniprot": "A0A3P7G6S2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6S2"
  },
  {
    "uniprot": "A0A3P7FXN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXN2"
  },
  {
    "uniprot": "A0A3P7G9J0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9J0"
  },
  {
    "uniprot": "A0A3P7F980",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F980"
  },
  {
    "uniprot": "A0A3P7DUU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUU1"
  },
  {
    "uniprot": "A0A3P7E2U5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2U5"
  },
  {
    "uniprot": "A0A3P7EFH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFH7"
  },
  {
    "uniprot": "A0A3P7GD70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GD70"
  },
  {
    "uniprot": "A0A3P7DJW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJW3"
  },
  {
    "uniprot": "A0A3P7F0W8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0W8"
  },
  {
    "uniprot": "A0A3P7DMG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMG6"
  },
  {
    "uniprot": "J9BDN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BDN3"
  },
  {
    "uniprot": "A0A3P7FDV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDV5"
  },
  {
    "uniprot": "A0A3P7FNQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNQ2"
  },
  {
    "uniprot": "A0A3P7DH79",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH79"
  },
  {
    "uniprot": "A0A3P7EE97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE97"
  },
  {
    "uniprot": "A0A3P7EIS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIS1"
  },
  {
    "uniprot": "A0A3P7EA27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA27"
  },
  {
    "uniprot": "A0A3P7DBF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBF9"
  },
  {
    "uniprot": "A0A3P7E7B3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7B3"
  },
  {
    "uniprot": "J9F9S6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F9S6"
  },
  {
    "uniprot": "A0A3P7E792",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E792"
  },
  {
    "uniprot": "A0A3P7DYV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYV3"
  },
  {
    "uniprot": "A0A3P7GDK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDK4"
  },
  {
    "uniprot": "A0A3P7GB41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GB41"
  },
  {
    "uniprot": "A0A3P7E7Q8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7Q8"
  },
  {
    "uniprot": "A0A3P7FMZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMZ4"
  },
  {
    "uniprot": "A0A3P7DQ38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ38"
  },
  {
    "uniprot": "A0A3P7E9U6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9U6"
  },
  {
    "uniprot": "A0A3P7FXQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXQ9"
  },
  {
    "uniprot": "A0A3P7E531",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E531"
  },
  {
    "uniprot": "A0A3P7FWW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWW7"
  },
  {
    "uniprot": "A0A3P7ER94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ER94"
  },
  {
    "uniprot": "A0A3P7DBA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBA5"
  },
  {
    "uniprot": "A0A3P7EJ64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ64"
  },
  {
    "uniprot": "A0A3P7EXB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXB7"
  },
  {
    "uniprot": "A0A3P7EEU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEU8"
  },
  {
    "uniprot": "J9BHQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BHQ6"
  },
  {
    "uniprot": "A0A3P7DJ84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ84"
  },
  {
    "uniprot": "A0A3P7GDW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDW3"
  },
  {
    "uniprot": "J9F3X2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F3X2"
  },
  {
    "uniprot": "A0A3P7ECJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECJ6"
  },
  {
    "uniprot": "A0A3P7DJB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJB8"
  },
  {
    "uniprot": "A0A3P7DPN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPN6"
  },
  {
    "uniprot": "A0A3P7FB06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB06"
  },
  {
    "uniprot": "A0A3P7FYY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYY5"
  },
  {
    "uniprot": "A0A3P7G484",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G484"
  },
  {
    "uniprot": "A0A3P7E8R9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8R9"
  },
  {
    "uniprot": "A0A3P7EG63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG63"
  },
  {
    "uniprot": "A0A3P7E4U5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4U5"
  },
  {
    "uniprot": "A0A3P7FQK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQK5"
  },
  {
    "uniprot": "A0A3P7E2Z9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2Z9"
  },
  {
    "uniprot": "A0A3P7ECE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECE4"
  },
  {
    "uniprot": "A0A3P7GDX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDX9"
  },
  {
    "uniprot": "A0A3P7FQY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQY3"
  },
  {
    "uniprot": "A0A3P7DKL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKL5"
  },
  {
    "uniprot": "A0A3P7DMM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMM7"
  },
  {
    "uniprot": "A0A3P7FGK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGK6"
  },
  {
    "uniprot": "A0A3P7EMN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMN3"
  },
  {
    "uniprot": "A0A3P7FMV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMV9"
  },
  {
    "uniprot": "A0A3P7DZT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZT5"
  },
  {
    "uniprot": "A0A3P7EN11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN11"
  },
  {
    "uniprot": "A0A183XQ07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XQ07"
  },
  {
    "uniprot": "A0A3P7E8G1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8G1"
  },
  {
    "uniprot": "J9DP74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DP74"
  },
  {
    "uniprot": "A0A3P7EIN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIN4"
  },
  {
    "uniprot": "A0A3P7DMG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMG5"
  },
  {
    "uniprot": "A0A3P7EE84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE84"
  },
  {
    "uniprot": "A0A3P7E1M4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1M4"
  },
  {
    "uniprot": "A0A3P7DMY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMY6"
  },
  {
    "uniprot": "A0A183XY24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XY24"
  },
  {
    "uniprot": "A0A183XEN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XEN3"
  },
  {
    "uniprot": "A0A3P7F1Y9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1Y9"
  },
  {
    "uniprot": "A0A3P7EGR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGR8"
  },
  {
    "uniprot": "A0A3P7FJH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJH1"
  },
  {
    "uniprot": "A0A3P7FTE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTE9"
  },
  {
    "uniprot": "A0A3P7DWH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWH6"
  },
  {
    "uniprot": "A0A3P7DUV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUV7"
  },
  {
    "uniprot": "A0A3P7F218",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F218"
  },
  {
    "uniprot": "A0A3P7DK90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK90"
  },
  {
    "uniprot": "A0A3P7G220",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G220"
  },
  {
    "uniprot": "A0A3P7EI45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI45"
  },
  {
    "uniprot": "A0A3P7DMU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMU9"
  },
  {
    "uniprot": "A0A3P7DYM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYM3"
  },
  {
    "uniprot": "A0A3P7FTJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTJ0"
  },
  {
    "uniprot": "A0A3P7DQH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQH0"
  },
  {
    "uniprot": "A0A3P7G3P3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3P3"
  },
  {
    "uniprot": "A0A3P7DPS4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPS4"
  },
  {
    "uniprot": "A0A3P7DRK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRK4"
  },
  {
    "uniprot": "A0A3P7GB36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GB36"
  },
  {
    "uniprot": "A0A3P7FVX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVX4"
  },
  {
    "uniprot": "A0A3P7FV63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV63"
  },
  {
    "uniprot": "A0A3P7EHA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHA7"
  },
  {
    "uniprot": "A0A3P7ECF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECF4"
  },
  {
    "uniprot": "J9EFT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EFT3"
  },
  {
    "uniprot": "A0A3P7DN85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN85"
  },
  {
    "uniprot": "A0A183XTR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XTR1"
  },
  {
    "uniprot": "J9ERD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERD3"
  },
  {
    "uniprot": "A0A3P7GIH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIH3"
  },
  {
    "uniprot": "J9BAB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BAB9"
  },
  {
    "uniprot": "A0A3P7DZ72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ72"
  },
  {
    "uniprot": "A0A3P7E868",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E868"
  },
  {
    "uniprot": "A0A3P7DEP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEP0"
  },
  {
    "uniprot": "A0A3P7DWF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWF5"
  },
  {
    "uniprot": "J9F140",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F140"
  },
  {
    "uniprot": "A0A3P7EBX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBX6"
  },
  {
    "uniprot": "A0A3P7F8J2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8J2"
  },
  {
    "uniprot": "A0A3P7EK95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK95"
  },
  {
    "uniprot": "A0A3P7FFW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFW3"
  },
  {
    "uniprot": "A0A3P7E4T6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4T6"
  },
  {
    "uniprot": "J9ETQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ETQ1"
  },
  {
    "uniprot": "A0A3P7G8T4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8T4"
  },
  {
    "uniprot": "A0A3P7EGX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGX8"
  },
  {
    "uniprot": "A0A3P7DSA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSA0"
  },
  {
    "uniprot": "A0A3P7DQ20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ20"
  },
  {
    "uniprot": "A0A3P7EN58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN58"
  },
  {
    "uniprot": "A0A3P7DV51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV51"
  },
  {
    "uniprot": "A0A3P7E9V6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9V6"
  },
  {
    "uniprot": "A0A3P7EKF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKF6"
  },
  {
    "uniprot": "J9FHM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FHM8"
  },
  {
    "uniprot": "A0A3P7DDL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDL8"
  },
  {
    "uniprot": "J9EPG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPG0"
  },
  {
    "uniprot": "J9EP42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EP42"
  },
  {
    "uniprot": "A0A3P7E370",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E370"
  },
  {
    "uniprot": "J9F1R2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1R2"
  },
  {
    "uniprot": "A0A3P7E4C3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4C3"
  },
  {
    "uniprot": "A0A3P7DN34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN34"
  },
  {
    "uniprot": "J9B7I3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B7I3"
  },
  {
    "uniprot": "A0A3P7EVC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVC8"
  },
  {
    "uniprot": "A0A3P7G684",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G684"
  },
  {
    "uniprot": "A0A3P7FC46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC46"
  },
  {
    "uniprot": "A0A3P7F847",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F847"
  },
  {
    "uniprot": "J9DRE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DRE1"
  },
  {
    "uniprot": "A0A3P7E4Z7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4Z7"
  },
  {
    "uniprot": "A0A3P7ESP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESP0"
  },
  {
    "uniprot": "A0A3P7E0N2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0N2"
  },
  {
    "uniprot": "A0A3P7GHQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHQ3"
  },
  {
    "uniprot": "A0A3P7EUY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUY8"
  },
  {
    "uniprot": "A0A3P7F711",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F711"
  },
  {
    "uniprot": "A0A3P7E251",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E251"
  },
  {
    "uniprot": "A0A3P7E0U5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0U5"
  },
  {
    "uniprot": "A0A3P7DKT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKT1"
  },
  {
    "uniprot": "A0A3P7FKT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKT1"
  },
  {
    "uniprot": "J9FAM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FAM7"
  },
  {
    "uniprot": "A0A3P7DGQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGQ7"
  },
  {
    "uniprot": "J9EXT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXT9"
  },
  {
    "uniprot": "A0A3P7DHG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHG2"
  },
  {
    "uniprot": "J9EC16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EC16"
  },
  {
    "uniprot": "A0A3P7E9Y5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9Y5"
  },
  {
    "uniprot": "J9EHU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EHU9"
  },
  {
    "uniprot": "A0A3P7DWD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWD1"
  },
  {
    "uniprot": "A0A3P7EDH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDH7"
  },
  {
    "uniprot": "A0A183Y116",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y116"
  },
  {
    "uniprot": "A0A3P7E8M1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8M1"
  },
  {
    "uniprot": "A0A3P7GFX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFX6"
  },
  {
    "uniprot": "A0A3P7G8Z3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8Z3"
  },
  {
    "uniprot": "A0A3P7DSU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSU5"
  },
  {
    "uniprot": "A0A3P7DRS7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRS7"
  },
  {
    "uniprot": "A0A3P7FK02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK02"
  },
  {
    "uniprot": "A0A3P7FD49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD49"
  },
  {
    "uniprot": "A0A3P7DS72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS72"
  },
  {
    "uniprot": "A0A3P7E0E9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0E9"
  },
  {
    "uniprot": "A0A3P7DT69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT69"
  },
  {
    "uniprot": "A0A3P7EDU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDU1"
  },
  {
    "uniprot": "A0A3P7DIS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIS8"
  },
  {
    "uniprot": "A0A3P7F8X5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8X5"
  },
  {
    "uniprot": "A0A3P7EDL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDL4"
  },
  {
    "uniprot": "A0A3P7DUI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUI3"
  },
  {
    "uniprot": "A0A3P7DUY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUY3"
  },
  {
    "uniprot": "A0A3P7EPZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPZ5"
  },
  {
    "uniprot": "A0A3P7EA83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA83"
  },
  {
    "uniprot": "A0A3P7DF95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF95"
  },
  {
    "uniprot": "A0A183XQT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XQT5"
  },
  {
    "uniprot": "J9FMM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FMM0"
  },
  {
    "uniprot": "A0A3P7FJP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJP2"
  },
  {
    "uniprot": "A0A3P7DVU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVU8"
  },
  {
    "uniprot": "A0A3P7EIU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIU1"
  },
  {
    "uniprot": "A0A3P7E6S5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6S5"
  },
  {
    "uniprot": "A0A3P7EFF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFF0"
  },
  {
    "uniprot": "A0A3P7FPU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPU8"
  },
  {
    "uniprot": "A0A3P7E8J3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8J3"
  },
  {
    "uniprot": "A0A3P7DWQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWQ9"
  },
  {
    "uniprot": "A0A3P7E2B1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2B1"
  },
  {
    "uniprot": "A0A3P7GDQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDQ0"
  },
  {
    "uniprot": "A0A3P7DE89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE89"
  },
  {
    "uniprot": "A0A3P7EEX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEX4"
  },
  {
    "uniprot": "A0A3P7DXU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXU1"
  },
  {
    "uniprot": "A0A3P7FR34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR34"
  },
  {
    "uniprot": "A0A3P7E6F4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6F4"
  },
  {
    "uniprot": "A0A3P7EA34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA34"
  },
  {
    "uniprot": "A0A3P7EHH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHH0"
  },
  {
    "uniprot": "A0A3P7EI47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI47"
  },
  {
    "uniprot": "A0A3P7EUZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUZ4"
  },
  {
    "uniprot": "A0A3P7E173",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E173"
  },
  {
    "uniprot": "A0A3P7EJJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJJ4"
  },
  {
    "uniprot": "A0A3P7FSB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSB9"
  },
  {
    "uniprot": "A0A183XRD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XRD3"
  },
  {
    "uniprot": "A0A3P7FH31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH31"
  },
  {
    "uniprot": "A0A3P7FIR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIR0"
  },
  {
    "uniprot": "A0A3P7DY58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY58"
  },
  {
    "uniprot": "A0A3P7EWA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWA2"
  },
  {
    "uniprot": "A0A3P7DYD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYD8"
  },
  {
    "uniprot": "A0A3P7FRT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRT3"
  },
  {
    "uniprot": "A0A3P7EAH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAH9"
  },
  {
    "uniprot": "A0A3P7FFN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFN1"
  },
  {
    "uniprot": "A0A3P7DTJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTJ2"
  },
  {
    "uniprot": "A0A3P7DQ56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ56"
  },
  {
    "uniprot": "A0A3P7DV74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV74"
  },
  {
    "uniprot": "A0A3P7GKX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKX0"
  },
  {
    "uniprot": "A0A3P7FB92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB92"
  },
  {
    "uniprot": "A0A3P7GLT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLT6"
  },
  {
    "uniprot": "A0A3P7FQE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQE0"
  },
  {
    "uniprot": "A0A3P7GG22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GG22"
  },
  {
    "uniprot": "J9ELY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ELY7"
  },
  {
    "uniprot": "A0A3P7DR20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR20"
  },
  {
    "uniprot": "J9EFZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EFZ3"
  },
  {
    "uniprot": "A0A3P7GMU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GMU7"
  },
  {
    "uniprot": "A0A3P7DP64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP64"
  },
  {
    "uniprot": "A0A3P7DG17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG17"
  },
  {
    "uniprot": "A0A3P7FJF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJF9"
  },
  {
    "uniprot": "A0A3P7DNN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNN1"
  },
  {
    "uniprot": "J9FGB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FGB0"
  },
  {
    "uniprot": "A0A3P7E2K4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2K4"
  },
  {
    "uniprot": "A0A3P7DN54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN54"
  },
  {
    "uniprot": "A0A3P7E3J2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3J2"
  },
  {
    "uniprot": "A0A3P7EI96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI96"
  },
  {
    "uniprot": "A0A183XKL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XKL9"
  },
  {
    "uniprot": "A0A3P7FVU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVU6"
  },
  {
    "uniprot": "A0A3P7FVL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVL8"
  },
  {
    "uniprot": "A0A3P7E5S0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5S0"
  },
  {
    "uniprot": "A0A3P7FB73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB73"
  },
  {
    "uniprot": "A0A3P7EHX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHX1"
  },
  {
    "uniprot": "A0A3P7FUT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUT1"
  },
  {
    "uniprot": "A0A3P7DYC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYC4"
  },
  {
    "uniprot": "A0A3P7DYW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYW5"
  },
  {
    "uniprot": "A0A3P7EJE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJE6"
  },
  {
    "uniprot": "J9BCB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BCB6"
  },
  {
    "uniprot": "A0A3P7DQ80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ80"
  },
  {
    "uniprot": "A0A3P7DS47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS47"
  },
  {
    "uniprot": "A0A3P7FL85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL85"
  },
  {
    "uniprot": "A0A3P7DK73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK73"
  },
  {
    "uniprot": "J9FCN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FCN1"
  },
  {
    "uniprot": "A0A3P7EF95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF95"
  },
  {
    "uniprot": "A0A3P7GI84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GI84"
  },
  {
    "uniprot": "A0A3P7ESA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESA7"
  },
  {
    "uniprot": "J9F3C4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F3C4"
  },
  {
    "uniprot": "J9AXG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AXG4"
  },
  {
    "uniprot": "A0A3P7E5Z4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5Z4"
  },
  {
    "uniprot": "A0A3P7EE24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE24"
  },
  {
    "uniprot": "A0A3P7DQG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQG0"
  },
  {
    "uniprot": "A0A3P7DYI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYI0"
  },
  {
    "uniprot": "A0A3P7DFF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFF3"
  },
  {
    "uniprot": "A0A3P7FHS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHS3"
  },
  {
    "uniprot": "A0A3P7DEL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEL5"
  },
  {
    "uniprot": "A0A183XN97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XN97"
  },
  {
    "uniprot": "A0A3P7DGU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGU8"
  },
  {
    "uniprot": "J9EYY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYY0"
  },
  {
    "uniprot": "A0A3P7G288",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G288"
  },
  {
    "uniprot": "A0A3P7DZJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZJ6"
  },
  {
    "uniprot": "A0A3P7E5U5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5U5"
  },
  {
    "uniprot": "A0A3P7FHR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHR0"
  },
  {
    "uniprot": "J9F496",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F496"
  },
  {
    "uniprot": "A0A3P7GEU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEU0"
  },
  {
    "uniprot": "A0A3P7DR92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR92"
  },
  {
    "uniprot": "A0A3P7E5B6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5B6"
  },
  {
    "uniprot": "A0A3P7GJ21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJ21"
  },
  {
    "uniprot": "A0A3P7DHN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHN5"
  },
  {
    "uniprot": "J9EYZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYZ4"
  },
  {
    "uniprot": "A0A3P7DCN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCN4"
  },
  {
    "uniprot": "A0A3P7E2C4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2C4"
  },
  {
    "uniprot": "A0A3P7F6Y3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F6Y3"
  },
  {
    "uniprot": "A0A3P7E8K3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8K3"
  },
  {
    "uniprot": "A0A3P7EAQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAQ8"
  },
  {
    "uniprot": "A0A3P7FIU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIU7"
  },
  {
    "uniprot": "A0A3P7GK67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GK67"
  },
  {
    "uniprot": "A0A3P7G494",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G494"
  },
  {
    "uniprot": "A0A3P7EMW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMW0"
  },
  {
    "uniprot": "A0A3P7DV12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV12"
  },
  {
    "uniprot": "J9EMH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EMH6"
  },
  {
    "uniprot": "A0A3P7GN34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GN34"
  },
  {
    "uniprot": "A0A3P7G0M2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0M2"
  },
  {
    "uniprot": "A0A3P7DLB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLB9"
  },
  {
    "uniprot": "A0A3P7ECT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECT6"
  },
  {
    "uniprot": "A0A3P7EPJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPJ4"
  },
  {
    "uniprot": "A0A3P7EIL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIL4"
  },
  {
    "uniprot": "J9FJ59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FJ59"
  },
  {
    "uniprot": "J9FGF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FGF3"
  },
  {
    "uniprot": "A0A3P7EGA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGA1"
  },
  {
    "uniprot": "A0A3P7GHW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHW4"
  },
  {
    "uniprot": "A0A3P7FID9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FID9"
  },
  {
    "uniprot": "A0A3P7DVW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVW7"
  },
  {
    "uniprot": "A0A3P7EJN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJN1"
  },
  {
    "uniprot": "A0A3P7EB13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB13"
  },
  {
    "uniprot": "A0A3P7E5R9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5R9"
  },
  {
    "uniprot": "A0A3P7EI64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI64"
  },
  {
    "uniprot": "A0A3P7DU66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU66"
  },
  {
    "uniprot": "A0A3P7E3Y5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3Y5"
  },
  {
    "uniprot": "A0A3P7EAZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAZ5"
  },
  {
    "uniprot": "A0A3P7EJG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJG5"
  },
  {
    "uniprot": "A0A3P7DKU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKU3"
  },
  {
    "uniprot": "A0A3P7DVY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVY3"
  },
  {
    "uniprot": "A0A3P7DPR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPR7"
  },
  {
    "uniprot": "A0A3P7E849",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E849"
  },
  {
    "uniprot": "A0A3P7ES72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ES72"
  },
  {
    "uniprot": "A0A3P7DKB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKB9"
  },
  {
    "uniprot": "A0A3P7EQJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQJ5"
  },
  {
    "uniprot": "A0A3P7DYA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYA8"
  },
  {
    "uniprot": "A0A3P7DTF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTF5"
  },
  {
    "uniprot": "A0A3P7DFP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFP0"
  },
  {
    "uniprot": "A0A3P7GHH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHH0"
  },
  {
    "uniprot": "A0A3P7DY12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY12"
  },
  {
    "uniprot": "A0A3P7G4M6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4M6"
  },
  {
    "uniprot": "A0A3P7DSX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSX1"
  },
  {
    "uniprot": "A0A3P7DQI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQI1"
  },
  {
    "uniprot": "A0A3P7FQJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQJ9"
  },
  {
    "uniprot": "A0A3P7GHT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHT0"
  },
  {
    "uniprot": "A0A3P7E4H5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4H5"
  },
  {
    "uniprot": "A0A3P7FPD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPD5"
  },
  {
    "uniprot": "A0A3P7DZN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZN1"
  },
  {
    "uniprot": "A0A3P7ETB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETB0"
  },
  {
    "uniprot": "A0A3P7GLA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLA9"
  },
  {
    "uniprot": "A0A183XHC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XHC9"
  },
  {
    "uniprot": "A0A3P7E852",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E852"
  },
  {
    "uniprot": "A0A3P7GEB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEB7"
  },
  {
    "uniprot": "A0A3P7FQF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQF7"
  },
  {
    "uniprot": "A0A3P7EDX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDX0"
  },
  {
    "uniprot": "A0A3P7FH77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH77"
  },
  {
    "uniprot": "A0A3P7E2H5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2H5"
  },
  {
    "uniprot": "A0A3P7FYK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYK9"
  },
  {
    "uniprot": "A0A3P7DZG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZG3"
  },
  {
    "uniprot": "A0A3P7ENJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENJ3"
  },
  {
    "uniprot": "A0A3P7DV77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV77"
  },
  {
    "uniprot": "A0A3P7DDZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDZ6"
  },
  {
    "uniprot": "A0A3P7FT54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT54"
  },
  {
    "uniprot": "A0A3P7EE80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE80"
  },
  {
    "uniprot": "A0A3P7DC76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC76"
  },
  {
    "uniprot": "A0A3P7DGF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGF5"
  },
  {
    "uniprot": "A0A3P7E0M9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0M9"
  },
  {
    "uniprot": "A0A3P7E3I8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3I8"
  },
  {
    "uniprot": "A0A3P7E4V7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4V7"
  },
  {
    "uniprot": "A0A3P7EDJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDJ4"
  },
  {
    "uniprot": "A0A3P7EJM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJM6"
  },
  {
    "uniprot": "A0A3P7DZ87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ87"
  },
  {
    "uniprot": "A0A3P7FRC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRC7"
  },
  {
    "uniprot": "A0A3P7DX51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX51"
  },
  {
    "uniprot": "A0A3P7DYL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYL1"
  },
  {
    "uniprot": "J9B888",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B888"
  },
  {
    "uniprot": "A0A3P7GIS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIS2"
  },
  {
    "uniprot": "A0A3P7E1I1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1I1"
  },
  {
    "uniprot": "A0A3P7G0F3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0F3"
  },
  {
    "uniprot": "A0A3P7E0M7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0M7"
  },
  {
    "uniprot": "A0A3P7EEC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEC0"
  },
  {
    "uniprot": "A0A3P7EHU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHU6"
  },
  {
    "uniprot": "A0A3P7ECK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECK3"
  },
  {
    "uniprot": "A0A3P7FB38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB38"
  },
  {
    "uniprot": "A0A3P7EG66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG66"
  },
  {
    "uniprot": "A0A3P7DV80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV80"
  },
  {
    "uniprot": "A0A3P7EHI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHI8"
  },
  {
    "uniprot": "A0A3P7EG89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG89"
  },
  {
    "uniprot": "A0A3P7ESP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESP1"
  },
  {
    "uniprot": "A0A3P7DG86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG86"
  },
  {
    "uniprot": "A0A3P7G1M9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1M9"
  },
  {
    "uniprot": "A0A3P7FUY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUY2"
  },
  {
    "uniprot": "A0A3P7DK20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK20"
  },
  {
    "uniprot": "A0A3P7E4R2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4R2"
  },
  {
    "uniprot": "A0A3P7FYX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYX4"
  },
  {
    "uniprot": "A0A3P7FJM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJM1"
  },
  {
    "uniprot": "A0A3P7GDK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDK3"
  },
  {
    "uniprot": "A0A3P7DJ40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ40"
  },
  {
    "uniprot": "A0A3P7E8X9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8X9"
  },
  {
    "uniprot": "A0A3P7FZM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZM0"
  },
  {
    "uniprot": "J9FDI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FDI1"
  },
  {
    "uniprot": "A0A3P7E3I9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3I9"
  },
  {
    "uniprot": "A0A3P7DWL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWL3"
  },
  {
    "uniprot": "A0A3P7EHK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHK7"
  },
  {
    "uniprot": "A0A3P7FWF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWF5"
  },
  {
    "uniprot": "A0A3P7FXX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXX5"
  },
  {
    "uniprot": "A0A3P7DWI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWI8"
  },
  {
    "uniprot": "A0A3P7EML6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EML6"
  },
  {
    "uniprot": "A0A3P7DYA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYA4"
  },
  {
    "uniprot": "A0A3P7FTZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTZ0"
  },
  {
    "uniprot": "A0A3P7DSY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSY1"
  },
  {
    "uniprot": "A0A3P7DQ46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ46"
  },
  {
    "uniprot": "A0A3P7FG05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG05"
  },
  {
    "uniprot": "A0A3P7FE66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE66"
  },
  {
    "uniprot": "J9EXK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXK4"
  },
  {
    "uniprot": "A0A3P7FF70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF70"
  },
  {
    "uniprot": "A0A3P7FM07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM07"
  },
  {
    "uniprot": "A0A3P7G0V8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0V8"
  },
  {
    "uniprot": "J9F274",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F274"
  },
  {
    "uniprot": "A0A3P7EZJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZJ4"
  },
  {
    "uniprot": "J9EMH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EMH7"
  },
  {
    "uniprot": "A0A3P7E376",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E376"
  },
  {
    "uniprot": "A0A3P7EEU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEU4"
  },
  {
    "uniprot": "J9EVB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EVB5"
  },
  {
    "uniprot": "A0A3P7DFP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFP3"
  },
  {
    "uniprot": "A0A3P7E4H2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4H2"
  },
  {
    "uniprot": "A0A3P7FNG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNG0"
  },
  {
    "uniprot": "A0A3P7DCC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCC9"
  },
  {
    "uniprot": "A0A3P7E215",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E215"
  },
  {
    "uniprot": "A0A3P7FM12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM12"
  },
  {
    "uniprot": "A0A3P7DQQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQQ8"
  },
  {
    "uniprot": "A0A3P7GJA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJA7"
  },
  {
    "uniprot": "J9EAV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EAV4"
  },
  {
    "uniprot": "A0A3P7FKB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKB8"
  },
  {
    "uniprot": "A0A3P7DNX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNX1"
  },
  {
    "uniprot": "A0A3P7DTL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTL6"
  },
  {
    "uniprot": "J9ENW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ENW8"
  },
  {
    "uniprot": "A0A3P7F994",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F994"
  },
  {
    "uniprot": "A0A3P7DXD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXD3"
  },
  {
    "uniprot": "A0A3P7DGN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGN1"
  },
  {
    "uniprot": "A0A3P7ENR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENR6"
  },
  {
    "uniprot": "A0A3P7FG31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG31"
  },
  {
    "uniprot": "A0A3P7DK69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK69"
  },
  {
    "uniprot": "A0A3P7DJR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJR9"
  },
  {
    "uniprot": "A0A3P7EM40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM40"
  },
  {
    "uniprot": "A0A3P7FRK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRK6"
  },
  {
    "uniprot": "A0A3P7DW72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW72"
  },
  {
    "uniprot": "A0A3P7G9F0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9F0"
  },
  {
    "uniprot": "A0A3P7EHR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHR7"
  },
  {
    "uniprot": "A0A3P7GH05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GH05"
  },
  {
    "uniprot": "A0A183Y0Q5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y0Q5"
  },
  {
    "uniprot": "A0A3P7F4G8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F4G8"
  },
  {
    "uniprot": "A0A3P7FEU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEU5"
  },
  {
    "uniprot": "A0A3P7FUW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUW2"
  },
  {
    "uniprot": "A0A3P7E323",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E323"
  },
  {
    "uniprot": "A0A3P7EB68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB68"
  },
  {
    "uniprot": "J9EHL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EHL5"
  },
  {
    "uniprot": "A0A3P7E7F9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7F9"
  },
  {
    "uniprot": "A0A3P7DSP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSP7"
  },
  {
    "uniprot": "A0A3P7EYU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYU3"
  },
  {
    "uniprot": "A0A3P7DX04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX04"
  },
  {
    "uniprot": "A0A3P7E4U6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4U6"
  },
  {
    "uniprot": "A0A3P7GKW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKW1"
  },
  {
    "uniprot": "A0A3P7DHH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHH2"
  },
  {
    "uniprot": "A0A3P7FBJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBJ3"
  },
  {
    "uniprot": "A0A3P7EX63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EX63"
  },
  {
    "uniprot": "A0A3P7EAK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAK4"
  },
  {
    "uniprot": "A0A3P7FWH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWH0"
  },
  {
    "uniprot": "A0A3P7DPJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPJ6"
  },
  {
    "uniprot": "A0A3P7G4I1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4I1"
  },
  {
    "uniprot": "J9EZ59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZ59"
  },
  {
    "uniprot": "A0A3P7EQC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQC4"
  },
  {
    "uniprot": "A0A3P7E8D2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8D2"
  },
  {
    "uniprot": "A0A3P7DH22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH22"
  },
  {
    "uniprot": "A0A3P7FQ76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ76"
  },
  {
    "uniprot": "A0A3P7F930",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F930"
  },
  {
    "uniprot": "A0A3P7FI82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI82"
  },
  {
    "uniprot": "A0A3P7FSF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSF0"
  },
  {
    "uniprot": "A0A3P7EA56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA56"
  },
  {
    "uniprot": "A0A3P7ELM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELM8"
  },
  {
    "uniprot": "A0A3P7FCK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCK1"
  },
  {
    "uniprot": "A0A3P7G7F9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G7F9"
  },
  {
    "uniprot": "J9BKG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BKG2"
  },
  {
    "uniprot": "A0A3P7EI09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI09"
  },
  {
    "uniprot": "A0A3P7FKL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKL2"
  },
  {
    "uniprot": "A0A3P7GBM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBM7"
  },
  {
    "uniprot": "A0A3P7FBI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBI5"
  },
  {
    "uniprot": "A0A3P7FV36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV36"
  },
  {
    "uniprot": "J9EDL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EDL9"
  },
  {
    "uniprot": "A0A3P7FMK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMK1"
  },
  {
    "uniprot": "A0A3P7E4M2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4M2"
  },
  {
    "uniprot": "A0A3P7DZ56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ56"
  },
  {
    "uniprot": "J9FEC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FEC2"
  },
  {
    "uniprot": "J9EY38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EY38"
  },
  {
    "uniprot": "A0A3P7E1A0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1A0"
  },
  {
    "uniprot": "A0A3P7FKR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKR0"
  },
  {
    "uniprot": "A0A3P7E7G8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7G8"
  },
  {
    "uniprot": "A0A3P7DHF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHF1"
  },
  {
    "uniprot": "A0A3P7FH56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH56"
  },
  {
    "uniprot": "A0A3P7DT22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT22"
  },
  {
    "uniprot": "A0A3P7EDD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDD9"
  },
  {
    "uniprot": "A0A3P7GEB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEB2"
  },
  {
    "uniprot": "A0A3P7FZH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZH8"
  },
  {
    "uniprot": "J9EY23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EY23"
  },
  {
    "uniprot": "A0A3P7DSC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSC8"
  },
  {
    "uniprot": "A0A183XGU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XGU6"
  },
  {
    "uniprot": "A0A3P7FRN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRN4"
  },
  {
    "uniprot": "A0A3P7ENH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENH6"
  },
  {
    "uniprot": "A0A3P7EHH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHH3"
  },
  {
    "uniprot": "A0A183XRG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XRG9"
  },
  {
    "uniprot": "A0A3P7EVV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVV5"
  },
  {
    "uniprot": "A0A3P7EEV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEV6"
  },
  {
    "uniprot": "A0A3P7F468",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F468"
  },
  {
    "uniprot": "A0A3P7DIK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIK7"
  },
  {
    "uniprot": "A0A3P7EDS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDS2"
  },
  {
    "uniprot": "A0A3P7GET3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GET3"
  },
  {
    "uniprot": "A0A3P7E0B4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0B4"
  },
  {
    "uniprot": "A0A3P7EII2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EII2"
  },
  {
    "uniprot": "A0A3P7EIB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIB7"
  },
  {
    "uniprot": "A0A3P7G2Z1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2Z1"
  },
  {
    "uniprot": "A0A3P7E1X3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1X3"
  },
  {
    "uniprot": "A0A3P7E423",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E423"
  },
  {
    "uniprot": "A0A3P7DNC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNC1"
  },
  {
    "uniprot": "A0A3P7EH57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH57"
  },
  {
    "uniprot": "A0A3P7FPR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPR0"
  },
  {
    "uniprot": "A0A3P7DB36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DB36"
  },
  {
    "uniprot": "A0A3P7DTH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTH3"
  },
  {
    "uniprot": "A0A3P7F0D1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0D1"
  },
  {
    "uniprot": "A0A3P7DM76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM76"
  },
  {
    "uniprot": "A0A183XTH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XTH5"
  },
  {
    "uniprot": "J9EYY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYY2"
  },
  {
    "uniprot": "A0A3P7G705",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G705"
  },
  {
    "uniprot": "A0A3P7E1S1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1S1"
  },
  {
    "uniprot": "A0A3P7GBS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBS5"
  },
  {
    "uniprot": "A0A3P7FLD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLD9"
  },
  {
    "uniprot": "A0A3P7E700",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E700"
  },
  {
    "uniprot": "A0A3P7GAC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAC8"
  },
  {
    "uniprot": "A0A3P7DX24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX24"
  },
  {
    "uniprot": "A0A3P7DSN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSN0"
  },
  {
    "uniprot": "A0A3P7EX72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EX72"
  },
  {
    "uniprot": "A0A183Y027",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y027"
  },
  {
    "uniprot": "A0A3P7DGR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGR2"
  },
  {
    "uniprot": "A0A3P7G921",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G921"
  },
  {
    "uniprot": "A0A3P7FUJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUJ3"
  },
  {
    "uniprot": "A0A3P7E9X2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9X2"
  },
  {
    "uniprot": "A0A3P7ET47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET47"
  },
  {
    "uniprot": "A0A3P7ESM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESM0"
  },
  {
    "uniprot": "A0A3P7DZ90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ90"
  },
  {
    "uniprot": "A0A3P7G7B2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G7B2"
  },
  {
    "uniprot": "A0A3P7FWZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWZ7"
  },
  {
    "uniprot": "A0A3P7DZ88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ88"
  },
  {
    "uniprot": "J9EJZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EJZ9"
  },
  {
    "uniprot": "A0A3P7E1E3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1E3"
  },
  {
    "uniprot": "A0A3P7EI92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI92"
  },
  {
    "uniprot": "A0A3P7DCK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCK5"
  },
  {
    "uniprot": "A0A3P7FRF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRF1"
  },
  {
    "uniprot": "A0A3P7DK56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK56"
  },
  {
    "uniprot": "A0A3P7GCV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCV1"
  },
  {
    "uniprot": "A0A3P7FHP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHP4"
  },
  {
    "uniprot": "A0A3P7DNR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNR5"
  },
  {
    "uniprot": "A0A3P7GHC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHC8"
  },
  {
    "uniprot": "A0A3P7FV92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV92"
  },
  {
    "uniprot": "A0A3P7E0Q6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0Q6"
  },
  {
    "uniprot": "A0A3P7FQ77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ77"
  },
  {
    "uniprot": "A0A3P7DS56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS56"
  },
  {
    "uniprot": "A0A3P7FUL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUL7"
  },
  {
    "uniprot": "A0A3P7EFG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFG7"
  },
  {
    "uniprot": "A0A3P7FY04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY04"
  },
  {
    "uniprot": "A0A3P7E5W3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5W3"
  },
  {
    "uniprot": "A0A3P7EEI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEI7"
  },
  {
    "uniprot": "A0A3P7GKG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKG3"
  },
  {
    "uniprot": "A0A3P7EI11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI11"
  },
  {
    "uniprot": "A0A3P7E4B2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4B2"
  },
  {
    "uniprot": "A0A3P7FH39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH39"
  },
  {
    "uniprot": "A0A3P7EP39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP39"
  },
  {
    "uniprot": "J9BFQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BFQ8"
  },
  {
    "uniprot": "A0A3P7DW77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW77"
  },
  {
    "uniprot": "A0A3P7FMG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMG2"
  },
  {
    "uniprot": "A0A3P7ECG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECG2"
  },
  {
    "uniprot": "A0A3P7DQA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQA4"
  },
  {
    "uniprot": "A0A3P7FJY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJY4"
  },
  {
    "uniprot": "A0A3P7DLT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLT1"
  },
  {
    "uniprot": "A0A3P7DN56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN56"
  },
  {
    "uniprot": "A0A3P7ETA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETA3"
  },
  {
    "uniprot": "J9EUA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUA6"
  },
  {
    "uniprot": "A0A3P7FUP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUP0"
  },
  {
    "uniprot": "A0A3P7DH59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH59"
  },
  {
    "uniprot": "A0A3P7GCR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCR4"
  },
  {
    "uniprot": "A0A3P7E8V4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8V4"
  },
  {
    "uniprot": "A0A3P7EC07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC07"
  },
  {
    "uniprot": "A0A3P7EB60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB60"
  },
  {
    "uniprot": "A0A183Y1W6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y1W6"
  },
  {
    "uniprot": "A0A3P7F0C2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0C2"
  },
  {
    "uniprot": "A0A3P7FMB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMB7"
  },
  {
    "uniprot": "A0A3P7DMA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMA9"
  },
  {
    "uniprot": "A0A3P7ED95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED95"
  },
  {
    "uniprot": "A0A3P7EJF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJF0"
  },
  {
    "uniprot": "A0A3P7G3X3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3X3"
  },
  {
    "uniprot": "A0A3P7DQD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQD5"
  },
  {
    "uniprot": "A0A3P7FPM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPM5"
  },
  {
    "uniprot": "J9ESQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ESQ7"
  },
  {
    "uniprot": "A0A3P7FA65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FA65"
  },
  {
    "uniprot": "A0A3P7EVU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVU7"
  },
  {
    "uniprot": "A0A3P7E9L6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9L6"
  },
  {
    "uniprot": "A0A3P7EGK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGK5"
  },
  {
    "uniprot": "A0A3P7ELP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELP2"
  },
  {
    "uniprot": "A0A3P7EGE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGE1"
  },
  {
    "uniprot": "A0A3P7GDS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDS8"
  },
  {
    "uniprot": "J9B9M2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B9M2"
  },
  {
    "uniprot": "A0A3P7FJJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJJ6"
  },
  {
    "uniprot": "A0A3P7DVG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVG7"
  },
  {
    "uniprot": "A0A3P7DJR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJR5"
  },
  {
    "uniprot": "A0A3P7EC18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC18"
  },
  {
    "uniprot": "A0A3P7DUR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUR1"
  },
  {
    "uniprot": "A0A3P7DX27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX27"
  },
  {
    "uniprot": "A0A3P7DW34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW34"
  },
  {
    "uniprot": "A0A3P7EFK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFK8"
  },
  {
    "uniprot": "A0A3P7DXN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXN2"
  },
  {
    "uniprot": "A0A3P7FLW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLW0"
  },
  {
    "uniprot": "A0A3P7DI96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI96"
  },
  {
    "uniprot": "A0A3P7EI51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI51"
  },
  {
    "uniprot": "A0A3P7DS41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS41"
  },
  {
    "uniprot": "A0A3P7FRH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRH3"
  },
  {
    "uniprot": "A0A3P7FW38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW38"
  },
  {
    "uniprot": "A0A3P7G573",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G573"
  },
  {
    "uniprot": "A0A3P7FS09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS09"
  },
  {
    "uniprot": "A0A3P7E0X9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0X9"
  },
  {
    "uniprot": "A0A3P7FUZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUZ5"
  },
  {
    "uniprot": "A0A3P7DWY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWY6"
  },
  {
    "uniprot": "A0A183XKW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XKW3"
  },
  {
    "uniprot": "A0A3P7FKM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKM1"
  },
  {
    "uniprot": "A0A3P7FN17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN17"
  },
  {
    "uniprot": "A0A3P7FXI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXI2"
  },
  {
    "uniprot": "A0A3P7EJ83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ83"
  },
  {
    "uniprot": "A0A3P7EHT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHT9"
  },
  {
    "uniprot": "A0A3P7FEW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEW2"
  },
  {
    "uniprot": "A0A3P7DBJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBJ7"
  },
  {
    "uniprot": "A0A3P7E528",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E528"
  },
  {
    "uniprot": "A0A3P7DIC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIC2"
  },
  {
    "uniprot": "A0A3P7E686",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E686"
  },
  {
    "uniprot": "A0A3P7DTX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTX9"
  },
  {
    "uniprot": "A0A3P7EWS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWS5"
  },
  {
    "uniprot": "A0A3P7F3U8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3U8"
  },
  {
    "uniprot": "A0A3P7FPI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPI4"
  },
  {
    "uniprot": "A0A3P7DK38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK38"
  },
  {
    "uniprot": "A0A3P7E4E0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4E0"
  },
  {
    "uniprot": "A0A183XWC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XWC0"
  },
  {
    "uniprot": "A0A183XM19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XM19"
  },
  {
    "uniprot": "A0A3P7EX71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EX71"
  },
  {
    "uniprot": "A0A3P7G1T3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1T3"
  },
  {
    "uniprot": "A0A3P7FSK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSK9"
  },
  {
    "uniprot": "A0A3P7E001",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E001"
  },
  {
    "uniprot": "A0A3P7EI17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI17"
  },
  {
    "uniprot": "A0A3P7FD08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD08"
  },
  {
    "uniprot": "A0A3P7FRX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRX1"
  },
  {
    "uniprot": "A0A3P7DPQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPQ8"
  },
  {
    "uniprot": "A0A3P7FPY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPY0"
  },
  {
    "uniprot": "A0A3P7GBC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBC8"
  },
  {
    "uniprot": "A0A3P7FLA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLA7"
  },
  {
    "uniprot": "A0A3P7E8I9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8I9"
  },
  {
    "uniprot": "A0A3P7FNJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNJ7"
  },
  {
    "uniprot": "J9F2G1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2G1"
  },
  {
    "uniprot": "A0A3P7FT46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT46"
  },
  {
    "uniprot": "A0A3P7DV54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV54"
  },
  {
    "uniprot": "A0A3P7G5F1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5F1"
  },
  {
    "uniprot": "A0A3P7E5N2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5N2"
  },
  {
    "uniprot": "A0A3P7E1P0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1P0"
  },
  {
    "uniprot": "A0A3P7DTD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTD1"
  },
  {
    "uniprot": "A0A3P7EAV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAV3"
  },
  {
    "uniprot": "A0A3P7EI53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI53"
  },
  {
    "uniprot": "J9F1L9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1L9"
  },
  {
    "uniprot": "A0A3P7EEG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEG1"
  },
  {
    "uniprot": "A0A3P7EF60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF60"
  },
  {
    "uniprot": "A0A3P7DB96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DB96"
  },
  {
    "uniprot": "A0A3P7DJG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJG7"
  },
  {
    "uniprot": "A0A3P7DM34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM34"
  },
  {
    "uniprot": "A0A3P7ECG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECG9"
  },
  {
    "uniprot": "A0A3P7DNS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNS0"
  },
  {
    "uniprot": "A0A3P7EM15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM15"
  },
  {
    "uniprot": "A0A3P7DUC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUC1"
  },
  {
    "uniprot": "A0A3P7D9K9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7D9K9"
  },
  {
    "uniprot": "A0A3P7EI76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI76"
  },
  {
    "uniprot": "A0A3P7DUF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUF7"
  },
  {
    "uniprot": "A0A3P7DR53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR53"
  },
  {
    "uniprot": "J9EQ11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQ11"
  },
  {
    "uniprot": "A0A3P7DF59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF59"
  },
  {
    "uniprot": "A0A3P7FBW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBW2"
  },
  {
    "uniprot": "A0A3P7FXL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXL4"
  },
  {
    "uniprot": "J9BID8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BID8"
  },
  {
    "uniprot": "A0A3P7E6H6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6H6"
  },
  {
    "uniprot": "A0A3P7FP89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP89"
  },
  {
    "uniprot": "A0A3P7DYS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYS6"
  },
  {
    "uniprot": "A0A3P7FYM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYM9"
  },
  {
    "uniprot": "A0A3P7DW87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW87"
  },
  {
    "uniprot": "A0A3P7FFB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFB4"
  },
  {
    "uniprot": "A0A3P7DN76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN76"
  },
  {
    "uniprot": "A0A3P7DPK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPK0"
  },
  {
    "uniprot": "A0A3P7DWA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWA5"
  },
  {
    "uniprot": "A0A3P7FBT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBT4"
  },
  {
    "uniprot": "A0A183XME8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XME8"
  },
  {
    "uniprot": "A0A3P7FYW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYW9"
  },
  {
    "uniprot": "A0A3P7DLU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLU7"
  },
  {
    "uniprot": "A0A3P7EF45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF45"
  },
  {
    "uniprot": "A0A3P7FET0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FET0"
  },
  {
    "uniprot": "A0A3P7FLJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLJ6"
  },
  {
    "uniprot": "A0A3P7DY18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY18"
  },
  {
    "uniprot": "A0A3P7F267",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F267"
  },
  {
    "uniprot": "A0A3P7DA12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DA12"
  },
  {
    "uniprot": "A0A3P7DJV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJV4"
  },
  {
    "uniprot": "A0A3P7G8X9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8X9"
  },
  {
    "uniprot": "A0A3P7EJB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJB4"
  },
  {
    "uniprot": "A0A3P7ECS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECS1"
  },
  {
    "uniprot": "A0A3P7FJ81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ81"
  },
  {
    "uniprot": "A0A183XG54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XG54"
  },
  {
    "uniprot": "A0A3P7DM86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM86"
  },
  {
    "uniprot": "A0A3P7ERM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERM5"
  },
  {
    "uniprot": "J9EAP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EAP0"
  },
  {
    "uniprot": "A0A3P7E713",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E713"
  },
  {
    "uniprot": "A0A3P7G2G9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2G9"
  },
  {
    "uniprot": "A0A3P7FMK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMK4"
  },
  {
    "uniprot": "A0A3P7E649",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E649"
  },
  {
    "uniprot": "A0A3P7FV02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV02"
  },
  {
    "uniprot": "A0A3P7G5W0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5W0"
  },
  {
    "uniprot": "A0A3P7EIP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIP4"
  },
  {
    "uniprot": "A0A3P7DRJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRJ4"
  },
  {
    "uniprot": "A0A3P7GBI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBI6"
  },
  {
    "uniprot": "A0A3P7DX89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX89"
  },
  {
    "uniprot": "A0A3P7DT30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT30"
  },
  {
    "uniprot": "A0A3P7DPH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPH6"
  },
  {
    "uniprot": "A0A3P7EP95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP95"
  },
  {
    "uniprot": "A0A3P7FCN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCN5"
  },
  {
    "uniprot": "A0A3P7EJ06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ06"
  },
  {
    "uniprot": "A0A3P7E926",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E926"
  },
  {
    "uniprot": "A0A3P7DKW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKW5"
  },
  {
    "uniprot": "A0A3P7FQP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQP1"
  },
  {
    "uniprot": "A0A3P7E087",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E087"
  },
  {
    "uniprot": "A0A3P7FPT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPT8"
  },
  {
    "uniprot": "A0A3P7DJF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJF7"
  },
  {
    "uniprot": "A0A3P7DEI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEI7"
  },
  {
    "uniprot": "A0A3P7FQ30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ30"
  },
  {
    "uniprot": "A0A3P7ED64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED64"
  },
  {
    "uniprot": "A0A3P7E8H7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8H7"
  },
  {
    "uniprot": "A0A3P7EY16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY16"
  },
  {
    "uniprot": "A0A3P7EGZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGZ1"
  },
  {
    "uniprot": "A0A3P7EEQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEQ4"
  },
  {
    "uniprot": "A0A3P7DRJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRJ5"
  },
  {
    "uniprot": "A0A3P7EQ62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQ62"
  },
  {
    "uniprot": "J9EK52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EK52"
  },
  {
    "uniprot": "A0A3P7FJU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJU3"
  },
  {
    "uniprot": "A0A3P7E4U7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4U7"
  },
  {
    "uniprot": "A0A3P7G134",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G134"
  },
  {
    "uniprot": "A0A3P7F121",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F121"
  },
  {
    "uniprot": "A0A3P7DWI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWI1"
  },
  {
    "uniprot": "A0A3P7FER0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FER0"
  },
  {
    "uniprot": "A0A3P7DSE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSE6"
  },
  {
    "uniprot": "A0A3P7FNK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNK5"
  },
  {
    "uniprot": "A0A3P7EIW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIW4"
  },
  {
    "uniprot": "A0A3P7DDH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDH0"
  },
  {
    "uniprot": "A0A183XDE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDE3"
  },
  {
    "uniprot": "A0A3P7E110",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E110"
  },
  {
    "uniprot": "A0A3P7FCD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCD9"
  },
  {
    "uniprot": "A0A3P7FP67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP67"
  },
  {
    "uniprot": "A0A3P7DY24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY24"
  },
  {
    "uniprot": "A0A3P7DM88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM88"
  },
  {
    "uniprot": "A0A3P7DH47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH47"
  },
  {
    "uniprot": "A0A3P7FYV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYV1"
  },
  {
    "uniprot": "A0A3P7ELI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELI4"
  },
  {
    "uniprot": "A0A3P7E4K9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4K9"
  },
  {
    "uniprot": "A0A3P7G0N3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0N3"
  },
  {
    "uniprot": "A0A3P7E8X6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8X6"
  },
  {
    "uniprot": "J9E9E7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E9E7"
  },
  {
    "uniprot": "A0A3P7GF90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GF90"
  },
  {
    "uniprot": "A0A183Y675",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y675"
  },
  {
    "uniprot": "A0A3P7DC53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC53"
  },
  {
    "uniprot": "A0A3P7F8R2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8R2"
  },
  {
    "uniprot": "A0A3P7E999",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E999"
  },
  {
    "uniprot": "A0A3P7DUP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUP9"
  },
  {
    "uniprot": "A0A3P7DUL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUL8"
  },
  {
    "uniprot": "J9B963",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B963"
  },
  {
    "uniprot": "A0A3P7E7M7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7M7"
  },
  {
    "uniprot": "A0A3P7E6A8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6A8"
  },
  {
    "uniprot": "A0A3P7FTA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTA4"
  },
  {
    "uniprot": "J9EC44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EC44"
  },
  {
    "uniprot": "A0A3P7DJJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJJ3"
  },
  {
    "uniprot": "A0A3P7FAX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAX5"
  },
  {
    "uniprot": "A0A3P7DW54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW54"
  },
  {
    "uniprot": "A0A3P7DQU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQU9"
  },
  {
    "uniprot": "A0A3P7DWH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWH8"
  },
  {
    "uniprot": "A0A3P7FRU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRU0"
  },
  {
    "uniprot": "A0A3P7DV38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV38"
  },
  {
    "uniprot": "A0A3P7G264",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G264"
  },
  {
    "uniprot": "A0A3P7FQ97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ97"
  },
  {
    "uniprot": "A0A3P7EHJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHJ8"
  },
  {
    "uniprot": "A0A3P7DV62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV62"
  },
  {
    "uniprot": "A0A3P7E054",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E054"
  },
  {
    "uniprot": "A0A3P7EKG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKG5"
  },
  {
    "uniprot": "A0A3P7DR00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR00"
  },
  {
    "uniprot": "A0A3P7FE89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE89"
  },
  {
    "uniprot": "A0A3P7FZV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZV7"
  },
  {
    "uniprot": "A0A3P7DBD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBD2"
  },
  {
    "uniprot": "A0A3P7E4J9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4J9"
  },
  {
    "uniprot": "A0A3P7DD15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD15"
  },
  {
    "uniprot": "A0A3P7GBV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBV3"
  },
  {
    "uniprot": "A0A3P7DX53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX53"
  },
  {
    "uniprot": "J9EL50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EL50"
  },
  {
    "uniprot": "A0A3P7EPQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPQ3"
  },
  {
    "uniprot": "A0A3P7DWG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWG8"
  },
  {
    "uniprot": "A0A3P7DUQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUQ7"
  },
  {
    "uniprot": "A0A3P7F1V3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1V3"
  },
  {
    "uniprot": "A0A3P7GLF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLF3"
  },
  {
    "uniprot": "A0A3P7FHQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHQ0"
  },
  {
    "uniprot": "A0A3P7ENK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENK0"
  },
  {
    "uniprot": "A0A3P7EGU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGU6"
  },
  {
    "uniprot": "A0A3P7DP66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP66"
  },
  {
    "uniprot": "A0A3P7DRM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRM6"
  },
  {
    "uniprot": "A0A3P7DT64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT64"
  },
  {
    "uniprot": "A0A3P7DQM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQM7"
  },
  {
    "uniprot": "A0A3P7DZ30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ30"
  },
  {
    "uniprot": "A0A3P7DTB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTB0"
  },
  {
    "uniprot": "A0A3P7DUD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUD3"
  },
  {
    "uniprot": "J9ERJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERJ9"
  },
  {
    "uniprot": "A0A3P7EGI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGI5"
  },
  {
    "uniprot": "A0A3P7ENY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENY6"
  },
  {
    "uniprot": "A0A3P7FE28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE28"
  },
  {
    "uniprot": "A0A3P7DZ42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ42"
  },
  {
    "uniprot": "A0A3P7FTH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTH1"
  },
  {
    "uniprot": "A0A3P7G967",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G967"
  },
  {
    "uniprot": "J9EQ55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQ55"
  },
  {
    "uniprot": "A0A3P7EAM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAM6"
  },
  {
    "uniprot": "J9BGQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BGQ9"
  },
  {
    "uniprot": "A0A3P7DRI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRI2"
  },
  {
    "uniprot": "A0A3P7DGS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGS6"
  },
  {
    "uniprot": "A0A3P7GKG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKG7"
  },
  {
    "uniprot": "A0A3P7E7U9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7U9"
  },
  {
    "uniprot": "A0A3P7DV76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV76"
  },
  {
    "uniprot": "A0A3P7DNT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNT9"
  },
  {
    "uniprot": "A0A3P7DT52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT52"
  },
  {
    "uniprot": "A0A3P7FBG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBG0"
  },
  {
    "uniprot": "A0A3P7FNN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNN1"
  },
  {
    "uniprot": "A0A3P7E1R8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1R8"
  },
  {
    "uniprot": "A0A3P7EV02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EV02"
  },
  {
    "uniprot": "J9EVR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EVR9"
  },
  {
    "uniprot": "J9EXI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXI9"
  },
  {
    "uniprot": "A0A3P7EET3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EET3"
  },
  {
    "uniprot": "A0A3P7DZB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZB3"
  },
  {
    "uniprot": "A0A3P7DUG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUG5"
  },
  {
    "uniprot": "A0A3P7EDC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDC4"
  },
  {
    "uniprot": "A0A3P7FZ51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ51"
  },
  {
    "uniprot": "A0A3P7E3J9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3J9"
  },
  {
    "uniprot": "A0A3P7DF72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF72"
  },
  {
    "uniprot": "A0A3P7DSK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSK6"
  },
  {
    "uniprot": "A0A3P7DJ25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ25"
  },
  {
    "uniprot": "A0A3P7DQT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQT6"
  },
  {
    "uniprot": "A0A3P7DAR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAR5"
  },
  {
    "uniprot": "A0A3P7EHG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHG0"
  },
  {
    "uniprot": "A0A3P7FNX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNX2"
  },
  {
    "uniprot": "A0A3P7DKL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKL2"
  },
  {
    "uniprot": "A0A3P7FK15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK15"
  },
  {
    "uniprot": "A0A3P7DU86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU86"
  },
  {
    "uniprot": "A0A3P7DXF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXF5"
  },
  {
    "uniprot": "A0A3P7DRQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRQ8"
  },
  {
    "uniprot": "J9BEQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BEQ7"
  },
  {
    "uniprot": "A0A3P7DAV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAV0"
  },
  {
    "uniprot": "A0A3P7DPH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPH8"
  },
  {
    "uniprot": "A0A3P7DYV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYV4"
  },
  {
    "uniprot": "A0A3P7FR89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR89"
  },
  {
    "uniprot": "A0A3P7E027",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E027"
  },
  {
    "uniprot": "A0A3P7E1U7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1U7"
  },
  {
    "uniprot": "A0A3P7EBL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBL4"
  },
  {
    "uniprot": "A0A3P7EEU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEU7"
  },
  {
    "uniprot": "A0A3P7DQS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQS9"
  },
  {
    "uniprot": "A0A3P7DTN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTN4"
  },
  {
    "uniprot": "A0A3P7FC96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC96"
  },
  {
    "uniprot": "J9EMX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EMX8"
  },
  {
    "uniprot": "A0A3P7FZC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZC7"
  },
  {
    "uniprot": "A0A3P7EB51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB51"
  },
  {
    "uniprot": "A0A3P7F9G4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9G4"
  },
  {
    "uniprot": "A0A3P7FGB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGB6"
  },
  {
    "uniprot": "A0A3P7E8X1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8X1"
  },
  {
    "uniprot": "A0A183XSI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XSI4"
  },
  {
    "uniprot": "A0A3P7FBE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBE5"
  },
  {
    "uniprot": "A0A3P7DW78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW78"
  },
  {
    "uniprot": "A0A3P7DGA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGA1"
  },
  {
    "uniprot": "A0A3P7DKW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKW2"
  },
  {
    "uniprot": "A0A3P7DQW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQW3"
  },
  {
    "uniprot": "A0A3P7E0M0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0M0"
  },
  {
    "uniprot": "A0A3P7DS46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS46"
  },
  {
    "uniprot": "A0A3P7DYB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYB2"
  },
  {
    "uniprot": "A0A3P7E289",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E289"
  },
  {
    "uniprot": "A0A3P7EGX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGX1"
  },
  {
    "uniprot": "A0A3P7FDI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDI9"
  },
  {
    "uniprot": "A0A3P7DRC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRC0"
  },
  {
    "uniprot": "A0A3P7DQV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQV5"
  },
  {
    "uniprot": "A0A3P7E0G2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0G2"
  },
  {
    "uniprot": "A0A3P7DMP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMP5"
  },
  {
    "uniprot": "A0A3P7DPA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPA3"
  },
  {
    "uniprot": "A0A3P7E3N1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3N1"
  },
  {
    "uniprot": "A0A3P7DXY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXY4"
  },
  {
    "uniprot": "A0A3P7DIC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIC4"
  },
  {
    "uniprot": "A0A3P7E5Y2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5Y2"
  },
  {
    "uniprot": "A0A3P7DVU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVU5"
  },
  {
    "uniprot": "A0A3P7EG22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG22"
  },
  {
    "uniprot": "J9AXM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AXM8"
  },
  {
    "uniprot": "A0A3P7G5M0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5M0"
  },
  {
    "uniprot": "A0A3P7DE32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE32"
  },
  {
    "uniprot": "A0A3P7EI83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI83"
  },
  {
    "uniprot": "A0A3P7DYQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYQ0"
  },
  {
    "uniprot": "J9EZ56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZ56"
  },
  {
    "uniprot": "A0A3P7EBQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBQ6"
  },
  {
    "uniprot": "A0A3P7EL93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL93"
  },
  {
    "uniprot": "A0A3P7DX82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX82"
  },
  {
    "uniprot": "A0A3P7DQ07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ07"
  },
  {
    "uniprot": "A0A3P7GM66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GM66"
  },
  {
    "uniprot": "A0A3P7ECL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECL6"
  },
  {
    "uniprot": "J9EFP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EFP3"
  },
  {
    "uniprot": "A0A3P7E965",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E965"
  },
  {
    "uniprot": "A0A3P7E6A1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6A1"
  },
  {
    "uniprot": "A0A3P7DVF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVF3"
  },
  {
    "uniprot": "A0A3P7FER4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FER4"
  },
  {
    "uniprot": "J9BCD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BCD6"
  },
  {
    "uniprot": "A0A3P7EAF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAF8"
  },
  {
    "uniprot": "A0A3P7DHE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHE5"
  },
  {
    "uniprot": "A0A3P7EU29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU29"
  },
  {
    "uniprot": "A0A3P7FPG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPG0"
  },
  {
    "uniprot": "A0A3P7FA74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FA74"
  },
  {
    "uniprot": "A0A3P7DX75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX75"
  },
  {
    "uniprot": "A0A3P7G4Y7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4Y7"
  },
  {
    "uniprot": "A0A3P7ECR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECR6"
  },
  {
    "uniprot": "A0A3P7EJQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJQ1"
  },
  {
    "uniprot": "A0A3P7EJA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJA2"
  },
  {
    "uniprot": "A0A3P7FU99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU99"
  },
  {
    "uniprot": "A0A3P7G593",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G593"
  },
  {
    "uniprot": "A0A3P7DSN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSN3"
  },
  {
    "uniprot": "A0A3P7FYL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYL6"
  },
  {
    "uniprot": "A0A3P7FEY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEY0"
  },
  {
    "uniprot": "J9EU26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EU26"
  },
  {
    "uniprot": "A0A3P7EHM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHM7"
  },
  {
    "uniprot": "A0A3P7E1W8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1W8"
  },
  {
    "uniprot": "A0A3P7E135",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E135"
  },
  {
    "uniprot": "J9EZJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZJ3"
  },
  {
    "uniprot": "J9EUS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUS9"
  },
  {
    "uniprot": "A0A3P7G9I2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9I2"
  },
  {
    "uniprot": "A0A3P7GL63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GL63"
  },
  {
    "uniprot": "A0A3P7E0E0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0E0"
  },
  {
    "uniprot": "A0A3P7FY00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY00"
  },
  {
    "uniprot": "A0A3P7EBN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBN3"
  },
  {
    "uniprot": "A0A3P7EVR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVR5"
  },
  {
    "uniprot": "A0A3P7DMG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMG1"
  },
  {
    "uniprot": "A0A3P7DVE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVE9"
  },
  {
    "uniprot": "A0A3P7G1L7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1L7"
  },
  {
    "uniprot": "A0A3P7GIZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIZ0"
  },
  {
    "uniprot": "A0A3P7FTA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTA3"
  },
  {
    "uniprot": "A0A3P7E5E6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5E6"
  },
  {
    "uniprot": "A0A3P7GE36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GE36"
  },
  {
    "uniprot": "J9F083",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F083"
  },
  {
    "uniprot": "A0A3P7FJD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJD6"
  },
  {
    "uniprot": "A0A3P7EDP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDP5"
  },
  {
    "uniprot": "A0A3P7GC95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GC95"
  },
  {
    "uniprot": "J9FIX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FIX4"
  },
  {
    "uniprot": "A0A3P7DP93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP93"
  },
  {
    "uniprot": "A0A3P7GEV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEV0"
  },
  {
    "uniprot": "J9BKV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BKV8"
  },
  {
    "uniprot": "A0A3P7DKW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKW7"
  },
  {
    "uniprot": "A0A3P7FKT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKT7"
  },
  {
    "uniprot": "A0A3P7EC81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC81"
  },
  {
    "uniprot": "A0A3P7DMM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMM3"
  },
  {
    "uniprot": "A0A3P7G1M7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1M7"
  },
  {
    "uniprot": "J9F531",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F531"
  },
  {
    "uniprot": "A0A183XKM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XKM0"
  },
  {
    "uniprot": "A0A3P7FEE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEE5"
  },
  {
    "uniprot": "J9EWX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EWX9"
  },
  {
    "uniprot": "A0A3P7DGW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGW8"
  },
  {
    "uniprot": "A0A3P7DQ89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ89"
  },
  {
    "uniprot": "A0A3P7EKE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKE1"
  },
  {
    "uniprot": "A0A3P7FVA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVA7"
  },
  {
    "uniprot": "A0A3P7FEX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEX3"
  },
  {
    "uniprot": "A0A3P7FGA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGA9"
  },
  {
    "uniprot": "A0A3P7EEG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEG9"
  },
  {
    "uniprot": "A0A3P7EKC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKC1"
  },
  {
    "uniprot": "A0A3P7FXF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXF2"
  },
  {
    "uniprot": "A0A3P7FZK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZK6"
  },
  {
    "uniprot": "A0A3P7DW01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW01"
  },
  {
    "uniprot": "A0A3P7FSJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSJ0"
  },
  {
    "uniprot": "A0A3P7DNC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNC2"
  },
  {
    "uniprot": "A0A3P7GID3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GID3"
  },
  {
    "uniprot": "J9ERI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERI2"
  },
  {
    "uniprot": "A0A3P7DJH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJH8"
  },
  {
    "uniprot": "A0A3P7FQA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQA2"
  },
  {
    "uniprot": "A0A3P7DSQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSQ8"
  },
  {
    "uniprot": "A0A3P7G0S5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0S5"
  },
  {
    "uniprot": "A0A3P7E841",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E841"
  },
  {
    "uniprot": "A0A3P7FK58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK58"
  },
  {
    "uniprot": "A0A3P7EUG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUG7"
  },
  {
    "uniprot": "A0A183XNI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XNI7"
  },
  {
    "uniprot": "J9BKT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BKT2"
  },
  {
    "uniprot": "A0A3P7F034",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F034"
  },
  {
    "uniprot": "A0A3P7DU96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU96"
  },
  {
    "uniprot": "A0A3P7FV85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV85"
  },
  {
    "uniprot": "A0A183XB41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XB41"
  },
  {
    "uniprot": "A0A3P7DYI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYI5"
  },
  {
    "uniprot": "A0A3P7G0U8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0U8"
  },
  {
    "uniprot": "A0A3P7EBC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBC2"
  },
  {
    "uniprot": "A0A3P7EJA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJA8"
  },
  {
    "uniprot": "A0A3P7DS92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS92"
  },
  {
    "uniprot": "A0A3P7EAH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAH8"
  },
  {
    "uniprot": "A0A3P7EFE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFE8"
  },
  {
    "uniprot": "A0A3P7DLT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLT0"
  },
  {
    "uniprot": "A0A3P7EEP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEP7"
  },
  {
    "uniprot": "A0A3P7FX25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX25"
  },
  {
    "uniprot": "A0A3P7FK11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK11"
  },
  {
    "uniprot": "A0A3P7EWW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWW3"
  },
  {
    "uniprot": "A0A3P7DMV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMV8"
  },
  {
    "uniprot": "J9ENA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ENA0"
  },
  {
    "uniprot": "A0A3P7EDL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDL3"
  },
  {
    "uniprot": "A0A3P7E4R8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4R8"
  },
  {
    "uniprot": "A0A3P7DZW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZW2"
  },
  {
    "uniprot": "A0A3P7DSB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSB6"
  },
  {
    "uniprot": "J9ET50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ET50"
  },
  {
    "uniprot": "A0A3P7EAX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAX0"
  },
  {
    "uniprot": "A0A3P7DY30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY30"
  },
  {
    "uniprot": "A0A3P7EGK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGK3"
  },
  {
    "uniprot": "A0A3P7DYH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYH7"
  },
  {
    "uniprot": "A0A3P7DZT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZT3"
  },
  {
    "uniprot": "A0A3P7DQA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQA8"
  },
  {
    "uniprot": "A0A3P7FHF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHF0"
  },
  {
    "uniprot": "A0A3P7D9P3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7D9P3"
  },
  {
    "uniprot": "A0A3P7E909",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E909"
  },
  {
    "uniprot": "A0A3P7FX48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX48"
  },
  {
    "uniprot": "A0A3P7DZ77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ77"
  },
  {
    "uniprot": "A0A3P7E996",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E996"
  },
  {
    "uniprot": "A0A3P7DZD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZD8"
  },
  {
    "uniprot": "A0A3P7E2J5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2J5"
  },
  {
    "uniprot": "A0A3P7E8F3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8F3"
  },
  {
    "uniprot": "A0A3P7E352",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E352"
  },
  {
    "uniprot": "A0A3P7GK21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GK21"
  },
  {
    "uniprot": "J9E6V2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E6V2"
  },
  {
    "uniprot": "A0A3P7DHJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHJ6"
  },
  {
    "uniprot": "A0A3P7DGT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGT8"
  },
  {
    "uniprot": "A0A3P7DBV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBV7"
  },
  {
    "uniprot": "J9F107",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F107"
  },
  {
    "uniprot": "A0A3P7FRW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRW7"
  },
  {
    "uniprot": "A0A3P7FBV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBV4"
  },
  {
    "uniprot": "A0A3P7E948",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E948"
  },
  {
    "uniprot": "A0A3P7FCF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCF2"
  },
  {
    "uniprot": "A0A3P7FC09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC09"
  },
  {
    "uniprot": "A0A3P7GED8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GED8"
  },
  {
    "uniprot": "A0A3P7DH13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH13"
  },
  {
    "uniprot": "A0A3P7FLV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLV0"
  },
  {
    "uniprot": "J9EHS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EHS2"
  },
  {
    "uniprot": "A0A3P7DED2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DED2"
  },
  {
    "uniprot": "A0A3P7ECA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECA7"
  },
  {
    "uniprot": "A0A3P7DX97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX97"
  },
  {
    "uniprot": "A0A3P7DJX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJX0"
  },
  {
    "uniprot": "A0A3P7DX66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX66"
  },
  {
    "uniprot": "A0A3P7FMA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMA8"
  },
  {
    "uniprot": "A0A3P7DX21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX21"
  },
  {
    "uniprot": "A0A3P7DNU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNU9"
  },
  {
    "uniprot": "A0A3P7FVN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVN8"
  },
  {
    "uniprot": "A0A3P7FY28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY28"
  },
  {
    "uniprot": "A0A3P7DNW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNW1"
  },
  {
    "uniprot": "J9E1M4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E1M4"
  },
  {
    "uniprot": "A0A3P7E0B9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0B9"
  },
  {
    "uniprot": "A0A3P7FAK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAK3"
  },
  {
    "uniprot": "A0A3P7ED91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED91"
  },
  {
    "uniprot": "A0A3P7DE90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE90"
  },
  {
    "uniprot": "A0A3P7E9E2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9E2"
  },
  {
    "uniprot": "A0A3P7E1C2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1C2"
  },
  {
    "uniprot": "A0A3P7EFW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFW0"
  },
  {
    "uniprot": "A0A3P7DJX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJX4"
  },
  {
    "uniprot": "A0A3P7EL91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL91"
  },
  {
    "uniprot": "A0A3P7DG30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG30"
  },
  {
    "uniprot": "A0A3P7DHL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHL6"
  },
  {
    "uniprot": "A0A3P7DTC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTC1"
  },
  {
    "uniprot": "J9FHY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FHY0"
  },
  {
    "uniprot": "A0A3P7EKH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKH5"
  },
  {
    "uniprot": "A0A3P7DGC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGC5"
  },
  {
    "uniprot": "A0A3P7EGB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGB9"
  },
  {
    "uniprot": "A0A3P7DJF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJF2"
  },
  {
    "uniprot": "A0A3P7DQM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQM6"
  },
  {
    "uniprot": "A0A3P7F8U4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8U4"
  },
  {
    "uniprot": "A0A3P7F8H0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8H0"
  },
  {
    "uniprot": "A0A3P7E8P3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8P3"
  },
  {
    "uniprot": "A0A3P7DNT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNT7"
  },
  {
    "uniprot": "A0A3P7EC26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC26"
  },
  {
    "uniprot": "A0A3P7DKC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKC0"
  },
  {
    "uniprot": "A0A3P7E0X7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0X7"
  },
  {
    "uniprot": "A0A3P7DC27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC27"
  },
  {
    "uniprot": "A0A3P7EID6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EID6"
  },
  {
    "uniprot": "A0A3P7FM96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM96"
  },
  {
    "uniprot": "J9B3V7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B3V7"
  },
  {
    "uniprot": "A0A3P7FJN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJN3"
  },
  {
    "uniprot": "A0A3P7G9K1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9K1"
  },
  {
    "uniprot": "J9EXE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXE5"
  },
  {
    "uniprot": "A0A3P7DVM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVM1"
  },
  {
    "uniprot": "J9EP74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EP74"
  },
  {
    "uniprot": "A0A3P7EUL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUL2"
  },
  {
    "uniprot": "A0A3P7E2L1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2L1"
  },
  {
    "uniprot": "A0A3P7EET7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EET7"
  },
  {
    "uniprot": "A0A3P7ECW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECW4"
  },
  {
    "uniprot": "A0A3P7DGQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGQ9"
  },
  {
    "uniprot": "A0A183XEJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XEJ8"
  },
  {
    "uniprot": "A0A3P7DH73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH73"
  },
  {
    "uniprot": "A0A3P7DV33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV33"
  },
  {
    "uniprot": "J9B7Q7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B7Q7"
  },
  {
    "uniprot": "A0A3P7FVF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVF0"
  },
  {
    "uniprot": "A0A3P7E6E3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6E3"
  },
  {
    "uniprot": "A0A3P7DSN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSN6"
  },
  {
    "uniprot": "A0A3P7EFB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFB9"
  },
  {
    "uniprot": "A0A3P7G7E5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G7E5"
  },
  {
    "uniprot": "A0A3P7DRQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRQ0"
  },
  {
    "uniprot": "J9F7G2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F7G2"
  },
  {
    "uniprot": "A0A3P7E4S0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4S0"
  },
  {
    "uniprot": "A0A3P7DC23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC23"
  },
  {
    "uniprot": "A0A3P7EAJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAJ9"
  },
  {
    "uniprot": "A0A3P7EKU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKU6"
  },
  {
    "uniprot": "A0A3P7EUK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUK8"
  },
  {
    "uniprot": "A0A3P7FTQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTQ2"
  },
  {
    "uniprot": "A0A3P7FRQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRQ4"
  },
  {
    "uniprot": "A0A3P7E5H7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5H7"
  },
  {
    "uniprot": "A0A3P7EGN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGN0"
  },
  {
    "uniprot": "A0A3P7FWJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWJ3"
  },
  {
    "uniprot": "A0A3P7FUJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUJ8"
  },
  {
    "uniprot": "A0A3P7ENE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENE4"
  },
  {
    "uniprot": "J9ELY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ELY5"
  },
  {
    "uniprot": "A0A3P7EJI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJI6"
  },
  {
    "uniprot": "A0A3P7DLZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLZ5"
  },
  {
    "uniprot": "A0A3P7EJM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJM3"
  },
  {
    "uniprot": "A0A3P7DNL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNL5"
  },
  {
    "uniprot": "A0A3P7DNQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNQ4"
  },
  {
    "uniprot": "A0A3P7E0S3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0S3"
  },
  {
    "uniprot": "A0A3P7DR98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR98"
  },
  {
    "uniprot": "J9BN20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BN20"
  },
  {
    "uniprot": "A0A3P7FQQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQQ8"
  },
  {
    "uniprot": "A0A3P7DUF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUF8"
  },
  {
    "uniprot": "A0A183XHG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XHG3"
  },
  {
    "uniprot": "A0A3P7G8R7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8R7"
  },
  {
    "uniprot": "A0A3P7GJ65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJ65"
  },
  {
    "uniprot": "A0A3P7FIP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIP9"
  },
  {
    "uniprot": "A0A3P7DJE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJE0"
  },
  {
    "uniprot": "A0A3P7F8Z6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8Z6"
  },
  {
    "uniprot": "A0A3P7ETD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETD9"
  },
  {
    "uniprot": "A0A3P7E9G6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9G6"
  },
  {
    "uniprot": "A0A3P7FDR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDR2"
  },
  {
    "uniprot": "A0A3P7DXE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXE9"
  },
  {
    "uniprot": "A0A3P7EGI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGI2"
  },
  {
    "uniprot": "A0A3P7EXV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXV7"
  },
  {
    "uniprot": "A0A3P7G281",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G281"
  },
  {
    "uniprot": "A0A3P7GAB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAB4"
  },
  {
    "uniprot": "A0A3P7DZR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZR1"
  },
  {
    "uniprot": "A0A3P7FRT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRT6"
  },
  {
    "uniprot": "J9FFR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FFR2"
  },
  {
    "uniprot": "A0A3P7E5T5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5T5"
  },
  {
    "uniprot": "J9BBT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BBT0"
  },
  {
    "uniprot": "A0A3P7E3T0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3T0"
  },
  {
    "uniprot": "A0A3P7DZT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZT2"
  },
  {
    "uniprot": "A0A3P7FPC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPC9"
  },
  {
    "uniprot": "A0A3P7FPH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPH5"
  },
  {
    "uniprot": "A0A3P7EYG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYG6"
  },
  {
    "uniprot": "A0A3P7GLF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLF9"
  },
  {
    "uniprot": "A0A3P7E9Y0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9Y0"
  },
  {
    "uniprot": "A0A3P7GI33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GI33"
  },
  {
    "uniprot": "A0A3P7EI95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI95"
  },
  {
    "uniprot": "A0A3P7E007",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E007"
  },
  {
    "uniprot": "A0A3P7DK60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK60"
  },
  {
    "uniprot": "A0A3P7DZQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZQ4"
  },
  {
    "uniprot": "A0A3P7DXB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXB0"
  },
  {
    "uniprot": "A0A3P7ELC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELC5"
  },
  {
    "uniprot": "A0A3P7FNL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNL4"
  },
  {
    "uniprot": "A0A3P7E3D3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3D3"
  },
  {
    "uniprot": "A0A3P7F2Y7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2Y7"
  },
  {
    "uniprot": "J9ET49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ET49"
  },
  {
    "uniprot": "A0A183XRJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XRJ2"
  },
  {
    "uniprot": "A0A3P7ECV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECV7"
  },
  {
    "uniprot": "J9F1K3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1K3"
  },
  {
    "uniprot": "A0A3P7F199",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F199"
  },
  {
    "uniprot": "A0A3P7DTJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTJ4"
  },
  {
    "uniprot": "A0A3P7E536",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E536"
  },
  {
    "uniprot": "J9FJX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FJX6"
  },
  {
    "uniprot": "A0A3P7GFZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFZ7"
  },
  {
    "uniprot": "A0A3P7DVA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVA9"
  },
  {
    "uniprot": "A0A3P7EFC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFC3"
  },
  {
    "uniprot": "J9AQH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AQH4"
  },
  {
    "uniprot": "A0A3P7F767",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F767"
  },
  {
    "uniprot": "A0A3P7FVK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVK3"
  },
  {
    "uniprot": "A0A3P7DJ17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ17"
  },
  {
    "uniprot": "J9EDX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EDX6"
  },
  {
    "uniprot": "J9EV58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EV58"
  },
  {
    "uniprot": "A0A3P7FCW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCW1"
  },
  {
    "uniprot": "A0A3P7FDC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDC5"
  },
  {
    "uniprot": "A0A3P7DWP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWP6"
  },
  {
    "uniprot": "A0A3P7FH57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH57"
  },
  {
    "uniprot": "A0A3P7DTS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTS6"
  },
  {
    "uniprot": "A0A3P7EX88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EX88"
  },
  {
    "uniprot": "A0A3P7DE75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE75"
  },
  {
    "uniprot": "J9EQB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQB9"
  },
  {
    "uniprot": "A0A3P7DFK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFK1"
  },
  {
    "uniprot": "J9FMK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FMK2"
  },
  {
    "uniprot": "A0A3P7FGR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGR0"
  },
  {
    "uniprot": "J9ESC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ESC6"
  },
  {
    "uniprot": "A0A3P7FPW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPW6"
  },
  {
    "uniprot": "A0A3P7E821",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E821"
  },
  {
    "uniprot": "A0A3P7EDM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDM7"
  },
  {
    "uniprot": "A0A183XCV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCV6"
  },
  {
    "uniprot": "A0A3P7DGC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGC4"
  },
  {
    "uniprot": "A0A3P7DNA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNA4"
  },
  {
    "uniprot": "A0A3P7FDV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDV2"
  },
  {
    "uniprot": "A0A3P7E8Z7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8Z7"
  },
  {
    "uniprot": "A0A3P7EHF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHF8"
  },
  {
    "uniprot": "A0A3P7FVD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVD9"
  },
  {
    "uniprot": "A0A3P7DKH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKH2"
  },
  {
    "uniprot": "A0A3P7EPC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPC2"
  },
  {
    "uniprot": "A0A3P7EI10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI10"
  },
  {
    "uniprot": "A0A3P7FUU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUU4"
  },
  {
    "uniprot": "A0A3P7DNU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNU0"
  },
  {
    "uniprot": "A0A3P7FKP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKP6"
  },
  {
    "uniprot": "A0A183Y074",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y074"
  },
  {
    "uniprot": "A0A3P7EXW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXW8"
  },
  {
    "uniprot": "J9ETH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ETH9"
  },
  {
    "uniprot": "A0A3P7ET90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET90"
  },
  {
    "uniprot": "J9EBF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EBF9"
  },
  {
    "uniprot": "A0A3P7DRW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRW6"
  },
  {
    "uniprot": "A0A3P7G6A1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6A1"
  },
  {
    "uniprot": "A0A3P7E169",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E169"
  },
  {
    "uniprot": "A0A3P7E8G4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8G4"
  },
  {
    "uniprot": "A0A3P7E9B3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9B3"
  },
  {
    "uniprot": "A0A3P7DYP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYP1"
  },
  {
    "uniprot": "A0A3P7E196",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E196"
  },
  {
    "uniprot": "A0A3P7FLR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLR1"
  },
  {
    "uniprot": "A0A3P7DJM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJM8"
  },
  {
    "uniprot": "A0A3P7F3U4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3U4"
  },
  {
    "uniprot": "A0A3P7DLP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLP2"
  },
  {
    "uniprot": "A0A3P7DN83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN83"
  },
  {
    "uniprot": "A0A3P7GKZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKZ4"
  },
  {
    "uniprot": "A0A3P7FYJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYJ8"
  },
  {
    "uniprot": "A0A3P7DGG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGG8"
  },
  {
    "uniprot": "A0A3P7FLC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLC7"
  },
  {
    "uniprot": "A0A3P7EAB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAB1"
  },
  {
    "uniprot": "A0A3P7DXA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXA2"
  },
  {
    "uniprot": "A0A3P7DS95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS95"
  },
  {
    "uniprot": "A0A3P7FZR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZR7"
  },
  {
    "uniprot": "A0A3P7DUC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUC5"
  },
  {
    "uniprot": "A0A3P7DVS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVS8"
  },
  {
    "uniprot": "A0A3P7FMS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMS1"
  },
  {
    "uniprot": "A0A3P7E0G0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0G0"
  },
  {
    "uniprot": "A0A3P7FX61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX61"
  },
  {
    "uniprot": "A0A3P7FUD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUD5"
  },
  {
    "uniprot": "A0A3P7FM25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM25"
  },
  {
    "uniprot": "A0A3P7DBG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBG9"
  },
  {
    "uniprot": "A0A3P7FI61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI61"
  },
  {
    "uniprot": "A0A3P7FNY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNY0"
  },
  {
    "uniprot": "A0A3P7DK04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK04"
  },
  {
    "uniprot": "A0A3P7E7B8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7B8"
  },
  {
    "uniprot": "A0A3P7EB70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB70"
  },
  {
    "uniprot": "A0A3P7E4R6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4R6"
  },
  {
    "uniprot": "A0A3P7DLZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLZ8"
  },
  {
    "uniprot": "A0A3P7EKF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKF1"
  },
  {
    "uniprot": "A0A3P7FJV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJV5"
  },
  {
    "uniprot": "A0A3P7E2A5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2A5"
  },
  {
    "uniprot": "A0A3P7DCY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCY5"
  },
  {
    "uniprot": "A0A3P7DMN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMN5"
  },
  {
    "uniprot": "A0A3P7FXG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXG3"
  },
  {
    "uniprot": "A0A3P7DT32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT32"
  },
  {
    "uniprot": "A0A3P7DW32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW32"
  },
  {
    "uniprot": "A0A3P7FA17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FA17"
  },
  {
    "uniprot": "A0A3P7EIX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIX0"
  },
  {
    "uniprot": "A0A3P7FU37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU37"
  },
  {
    "uniprot": "A0A3P7EH02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH02"
  },
  {
    "uniprot": "A0A3P7E0E5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0E5"
  },
  {
    "uniprot": "A0A3P7F9V8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9V8"
  },
  {
    "uniprot": "A0A3P7FMN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMN5"
  },
  {
    "uniprot": "A0A183XE90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XE90"
  },
  {
    "uniprot": "A0A3P7FY17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY17"
  },
  {
    "uniprot": "A0A3P7EF55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF55"
  },
  {
    "uniprot": "A0A3P7DEJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEJ4"
  },
  {
    "uniprot": "A0A3P7FHL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHL5"
  },
  {
    "uniprot": "A0A183XHA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XHA7"
  },
  {
    "uniprot": "A0A3P7FR11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR11"
  },
  {
    "uniprot": "A0A3P7G1W4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1W4"
  },
  {
    "uniprot": "A0A3P7ESD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESD5"
  },
  {
    "uniprot": "A0A3P7DVI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVI9"
  },
  {
    "uniprot": "A0A3P7FCH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCH5"
  },
  {
    "uniprot": "A0A3P7ENS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENS9"
  },
  {
    "uniprot": "A0A3P7DLG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLG1"
  },
  {
    "uniprot": "A0A3P7DUR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUR4"
  },
  {
    "uniprot": "A0A3P7DIB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIB1"
  },
  {
    "uniprot": "A0A3P7E578",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E578"
  },
  {
    "uniprot": "A0A3P7F9S5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9S5"
  },
  {
    "uniprot": "A0A3P7FL25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL25"
  },
  {
    "uniprot": "A0A3P7EDP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDP1"
  },
  {
    "uniprot": "A0A3P7GBY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBY2"
  },
  {
    "uniprot": "A0A3P7EEW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEW7"
  },
  {
    "uniprot": "A0A3P7E9Q7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9Q7"
  },
  {
    "uniprot": "A0A3P7E0J1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0J1"
  },
  {
    "uniprot": "A0A3P7E7S9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7S9"
  },
  {
    "uniprot": "A0A3P7FLI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLI6"
  },
  {
    "uniprot": "A0A3P7FUZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUZ1"
  },
  {
    "uniprot": "A0A3P7E0F6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0F6"
  },
  {
    "uniprot": "A0A3P7E368",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E368"
  },
  {
    "uniprot": "A0A3P7E3H1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3H1"
  },
  {
    "uniprot": "A0A3P7F8U9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8U9"
  },
  {
    "uniprot": "A0A3P7EF40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF40"
  },
  {
    "uniprot": "A0A3P7E5N9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5N9"
  },
  {
    "uniprot": "A0A3P7F276",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F276"
  },
  {
    "uniprot": "A0A3P7F8T9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8T9"
  },
  {
    "uniprot": "A0A3P7EUY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUY1"
  },
  {
    "uniprot": "A0A3P7EC55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC55"
  },
  {
    "uniprot": "A0A3P7FTW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTW6"
  },
  {
    "uniprot": "A0A3P7DMD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMD6"
  },
  {
    "uniprot": "J9EWR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EWR7"
  },
  {
    "uniprot": "A0A3P7FEX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEX1"
  },
  {
    "uniprot": "A0A3P7FE57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE57"
  },
  {
    "uniprot": "A0A3P7DLC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLC7"
  },
  {
    "uniprot": "A0A3P7EDZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDZ4"
  },
  {
    "uniprot": "A0A3P7DZH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZH4"
  },
  {
    "uniprot": "A0A3P7DXW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXW4"
  },
  {
    "uniprot": "A0A3P7E279",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E279"
  },
  {
    "uniprot": "J9ENT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ENT8"
  },
  {
    "uniprot": "A0A3P7FCI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCI3"
  },
  {
    "uniprot": "A0A3P7FHQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHQ2"
  },
  {
    "uniprot": "A0A3P7EPJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPJ1"
  },
  {
    "uniprot": "A0A183XCC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCC7"
  },
  {
    "uniprot": "A0A3P7EFJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFJ6"
  },
  {
    "uniprot": "A0A3P7DA50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DA50"
  },
  {
    "uniprot": "A0A3P7E4T8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4T8"
  },
  {
    "uniprot": "A0A3P7DMK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMK4"
  },
  {
    "uniprot": "A0A3P7EVW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVW6"
  },
  {
    "uniprot": "A0A3P7G8E9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8E9"
  },
  {
    "uniprot": "A0A3P7E270",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E270"
  },
  {
    "uniprot": "A0A3P7FGD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGD8"
  },
  {
    "uniprot": "A0A3P7FJ43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ43"
  },
  {
    "uniprot": "A0A3P7DUI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUI2"
  },
  {
    "uniprot": "J9AYM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AYM5"
  },
  {
    "uniprot": "A0A3P7GI48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GI48"
  },
  {
    "uniprot": "A0A3P7G8M1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8M1"
  },
  {
    "uniprot": "A0A3P7E2R5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2R5"
  },
  {
    "uniprot": "J9BKA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BKA2"
  },
  {
    "uniprot": "A0A3P7F2I0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2I0"
  },
  {
    "uniprot": "A0A3P7FQC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQC2"
  },
  {
    "uniprot": "A0A3P7EU04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU04"
  },
  {
    "uniprot": "A0A3P7G5D2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5D2"
  },
  {
    "uniprot": "A0A3P7DZ85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ85"
  },
  {
    "uniprot": "A0A3P7G915",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G915"
  },
  {
    "uniprot": "A0A3P7EXP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXP2"
  },
  {
    "uniprot": "A0A3P7FZ11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ11"
  },
  {
    "uniprot": "A0A3P7ERR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERR8"
  },
  {
    "uniprot": "A0A3P7FHE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHE9"
  },
  {
    "uniprot": "A0A3P7DZ15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ15"
  },
  {
    "uniprot": "A0A3P7DYH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYH1"
  },
  {
    "uniprot": "A0A3P7G9N4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9N4"
  },
  {
    "uniprot": "A0A3P7DPU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPU5"
  },
  {
    "uniprot": "A0A3P7EQC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQC3"
  },
  {
    "uniprot": "A0A3P7EEC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEC9"
  },
  {
    "uniprot": "A0A3P7EJV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJV8"
  },
  {
    "uniprot": "A0A3P7DPY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPY1"
  },
  {
    "uniprot": "A0A3P7EF42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF42"
  },
  {
    "uniprot": "A0A3P7DHP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHP5"
  },
  {
    "uniprot": "J9FCC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FCC1"
  },
  {
    "uniprot": "A0A183XN06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XN06"
  },
  {
    "uniprot": "A0A3P7EDQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDQ0"
  },
  {
    "uniprot": "A0A3P7EYX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYX0"
  },
  {
    "uniprot": "A0A3P7DL34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL34"
  },
  {
    "uniprot": "A0A3P7E459",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E459"
  },
  {
    "uniprot": "A0A3P7E300",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E300"
  },
  {
    "uniprot": "A0A3P7FWG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWG5"
  },
  {
    "uniprot": "A0A3P7DMV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMV1"
  },
  {
    "uniprot": "A0A3P7E3Z2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3Z2"
  },
  {
    "uniprot": "A0A3P7ELR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELR9"
  },
  {
    "uniprot": "A0A3P7DBV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBV3"
  },
  {
    "uniprot": "J9FN58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FN58"
  },
  {
    "uniprot": "A0A3P7DJN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJN9"
  },
  {
    "uniprot": "A0A3P7FQ20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ20"
  },
  {
    "uniprot": "A0A3P7EFZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFZ5"
  },
  {
    "uniprot": "A0A3P7EQN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQN4"
  },
  {
    "uniprot": "A0A183XCR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCR5"
  },
  {
    "uniprot": "A0A3P7G8M3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8M3"
  },
  {
    "uniprot": "A0A3P7DW83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW83"
  },
  {
    "uniprot": "A0A3P7FEW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEW0"
  },
  {
    "uniprot": "A0A3P7FBU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBU6"
  },
  {
    "uniprot": "A0A3P7EAK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAK8"
  },
  {
    "uniprot": "A0A3P7E2W2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2W2"
  },
  {
    "uniprot": "A0A3P7FIT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIT0"
  },
  {
    "uniprot": "A0A3P7GF41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GF41"
  },
  {
    "uniprot": "A0A3P7GJF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJF4"
  },
  {
    "uniprot": "A0A3P7DZX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZX4"
  },
  {
    "uniprot": "A0A3P7DDH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDH7"
  },
  {
    "uniprot": "A0A3P7DPA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPA4"
  },
  {
    "uniprot": "A0A3P7DVN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVN7"
  },
  {
    "uniprot": "J9EZM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZM1"
  },
  {
    "uniprot": "A0A3P7DU42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU42"
  },
  {
    "uniprot": "A0A3P7E4H6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4H6"
  },
  {
    "uniprot": "A0A183XKF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XKF1"
  },
  {
    "uniprot": "A0A3P7EVE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVE4"
  },
  {
    "uniprot": "A0A3P7EZR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZR0"
  },
  {
    "uniprot": "A0A3P7EEF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEF4"
  },
  {
    "uniprot": "A0A3P7DUJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUJ2"
  },
  {
    "uniprot": "A0A3P7GCV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCV9"
  },
  {
    "uniprot": "A0A3P7DSL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSL3"
  },
  {
    "uniprot": "A0A3P7EZP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZP4"
  },
  {
    "uniprot": "A0A3P7DXT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXT1"
  },
  {
    "uniprot": "A0A3P7F990",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F990"
  },
  {
    "uniprot": "A0A3P7EGD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGD8"
  },
  {
    "uniprot": "A0A3P7DEJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEJ8"
  },
  {
    "uniprot": "A0A3P7G694",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G694"
  },
  {
    "uniprot": "A0A3P7F268",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F268"
  },
  {
    "uniprot": "A0A3P7GB64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GB64"
  },
  {
    "uniprot": "A0A3P7DYM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYM4"
  },
  {
    "uniprot": "A0A3P7FVV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVV6"
  },
  {
    "uniprot": "A0A3P7EC74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC74"
  },
  {
    "uniprot": "A0A3P7FX57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX57"
  },
  {
    "uniprot": "A0A3P7E3L3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3L3"
  },
  {
    "uniprot": "A0A3P7EFB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFB5"
  },
  {
    "uniprot": "A0A3P7FS22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS22"
  },
  {
    "uniprot": "A0A3P7ECL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECL4"
  },
  {
    "uniprot": "A0A3P7E2B0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2B0"
  },
  {
    "uniprot": "A0A3P7DUP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUP0"
  },
  {
    "uniprot": "A0A3P7FPV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPV2"
  },
  {
    "uniprot": "A0A183XWW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XWW6"
  },
  {
    "uniprot": "A0A3P7EBE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBE1"
  },
  {
    "uniprot": "A0A3P7DUP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUP1"
  },
  {
    "uniprot": "A0A3P7E1I3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1I3"
  },
  {
    "uniprot": "A0A3P7F2W8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2W8"
  },
  {
    "uniprot": "A0A3P7G4I8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4I8"
  },
  {
    "uniprot": "A0A3P7GAH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAH0"
  },
  {
    "uniprot": "J9FGA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FGA4"
  },
  {
    "uniprot": "A0A3P7DYK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYK6"
  },
  {
    "uniprot": "A0A3P7DZ34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ34"
  },
  {
    "uniprot": "A0A3P7FV60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV60"
  },
  {
    "uniprot": "A0A3P7DR32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR32"
  },
  {
    "uniprot": "A0A3P7DMR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMR5"
  },
  {
    "uniprot": "A0A3P7FSI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSI2"
  },
  {
    "uniprot": "A0A3P7DLR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLR2"
  },
  {
    "uniprot": "A0A3P7DX39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX39"
  },
  {
    "uniprot": "A0A3P7DYR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYR5"
  },
  {
    "uniprot": "A0A3P7G6B8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6B8"
  },
  {
    "uniprot": "A0A3P7DVN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVN0"
  },
  {
    "uniprot": "J9EWF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EWF0"
  },
  {
    "uniprot": "A0A3P7EWU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWU8"
  },
  {
    "uniprot": "J9BFY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BFY6"
  },
  {
    "uniprot": "A0A3P7EA92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA92"
  },
  {
    "uniprot": "A0A3P7G8P2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8P2"
  },
  {
    "uniprot": "A0A3P7FGL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGL9"
  },
  {
    "uniprot": "A0A3P7DIE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIE9"
  },
  {
    "uniprot": "A0A3P7DZL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZL8"
  },
  {
    "uniprot": "A0A183XPX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XPX7"
  },
  {
    "uniprot": "J9EQE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQE0"
  },
  {
    "uniprot": "A0A3P7DYQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYQ4"
  },
  {
    "uniprot": "A0A3P7DQA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQA9"
  },
  {
    "uniprot": "J9EW55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EW55"
  },
  {
    "uniprot": "A0A3P7E945",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E945"
  },
  {
    "uniprot": "A0A183XIZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XIZ2"
  },
  {
    "uniprot": "A0A3P7E7N3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7N3"
  },
  {
    "uniprot": "A0A3P7GAR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAR1"
  },
  {
    "uniprot": "A0A3P7DW39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW39"
  },
  {
    "uniprot": "A0A3P7E6L9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6L9"
  },
  {
    "uniprot": "J9F2Y0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2Y0"
  },
  {
    "uniprot": "A0A3P7DUU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUU5"
  },
  {
    "uniprot": "A0A3P7DST9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DST9"
  },
  {
    "uniprot": "A0A183XXQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XXQ6"
  },
  {
    "uniprot": "A0A3P7E296",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E296"
  },
  {
    "uniprot": "A0A3P7E8B1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8B1"
  },
  {
    "uniprot": "A0A3P7DM10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM10"
  },
  {
    "uniprot": "A0A3P7FZG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZG2"
  },
  {
    "uniprot": "A0A3P7ELT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELT2"
  },
  {
    "uniprot": "A0A3P7DMJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMJ1"
  },
  {
    "uniprot": "A0A3P7E412",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E412"
  },
  {
    "uniprot": "A0A3P7E1U0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1U0"
  },
  {
    "uniprot": "A0A3P7FWM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWM0"
  },
  {
    "uniprot": "A0A3P7FHL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHL6"
  },
  {
    "uniprot": "A0A3P7DYU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYU8"
  },
  {
    "uniprot": "J9ENE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ENE2"
  },
  {
    "uniprot": "A0A3P7DMZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMZ1"
  },
  {
    "uniprot": "A0A3P7ENW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENW7"
  },
  {
    "uniprot": "A0A3P7DVK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVK2"
  },
  {
    "uniprot": "A0A3P7EAD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAD5"
  },
  {
    "uniprot": "A0A3P7G2C7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2C7"
  },
  {
    "uniprot": "A0A3P7FZ04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ04"
  },
  {
    "uniprot": "A0A3P7EDA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDA7"
  },
  {
    "uniprot": "J9BC69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BC69"
  },
  {
    "uniprot": "A0A3P7FPM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPM0"
  },
  {
    "uniprot": "J9EN93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EN93"
  },
  {
    "uniprot": "A0A3P7FPY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPY4"
  },
  {
    "uniprot": "A0A3P7G1R4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1R4"
  },
  {
    "uniprot": "A0A3P7FG12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG12"
  },
  {
    "uniprot": "A0A3P7DWM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWM9"
  },
  {
    "uniprot": "A0A3P7G891",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G891"
  },
  {
    "uniprot": "A0A3P7DTE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTE4"
  },
  {
    "uniprot": "A0A3P7EXF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXF1"
  },
  {
    "uniprot": "A0A3P7E247",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E247"
  },
  {
    "uniprot": "A0A3P7EGD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGD0"
  },
  {
    "uniprot": "A0A3P7GKF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKF7"
  },
  {
    "uniprot": "A0A3P7EFE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFE1"
  },
  {
    "uniprot": "A0A3P7DSA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSA2"
  },
  {
    "uniprot": "J9F279",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F279"
  },
  {
    "uniprot": "A0A3P7FXJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXJ2"
  },
  {
    "uniprot": "A0A3P7GMM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GMM1"
  },
  {
    "uniprot": "A0A3P7FWJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWJ9"
  },
  {
    "uniprot": "A0A3P7FPE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPE8"
  },
  {
    "uniprot": "A0A3P7FEY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEY2"
  },
  {
    "uniprot": "J9EWD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EWD9"
  },
  {
    "uniprot": "J9BI85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BI85"
  },
  {
    "uniprot": "A0A3P7GEC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEC7"
  },
  {
    "uniprot": "A0A3P7DZN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZN3"
  },
  {
    "uniprot": "A0A3P7DD64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD64"
  },
  {
    "uniprot": "A0A3P7E9L8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9L8"
  },
  {
    "uniprot": "A0A3P7DYM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYM1"
  },
  {
    "uniprot": "A0A183XI25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XI25"
  },
  {
    "uniprot": "A0A3P7DUH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUH4"
  },
  {
    "uniprot": "A0A3P7E6G1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6G1"
  },
  {
    "uniprot": "A0A3P7FES2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FES2"
  },
  {
    "uniprot": "A0A3P7ETA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETA6"
  },
  {
    "uniprot": "A0A3P7F9W1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9W1"
  },
  {
    "uniprot": "A0A3P7DZW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZW0"
  },
  {
    "uniprot": "A0A3P7GFF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFF6"
  },
  {
    "uniprot": "A0A3P7E6S2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6S2"
  },
  {
    "uniprot": "A0A3P7FY31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY31"
  },
  {
    "uniprot": "A0A3P7DYT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYT7"
  },
  {
    "uniprot": "A0A3P7ECJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECJ2"
  },
  {
    "uniprot": "A0A3P7DE20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE20"
  },
  {
    "uniprot": "A0A3P7FLB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLB6"
  },
  {
    "uniprot": "J9FG19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FG19"
  },
  {
    "uniprot": "A0A3P7EGB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGB7"
  },
  {
    "uniprot": "A0A3P7DIV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIV6"
  },
  {
    "uniprot": "J9EPD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPD4"
  },
  {
    "uniprot": "J9EVP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EVP0"
  },
  {
    "uniprot": "A0A3P7FV27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV27"
  },
  {
    "uniprot": "A0A183XGC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XGC3"
  },
  {
    "uniprot": "A0A3P7EHY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHY0"
  },
  {
    "uniprot": "A0A3P7EHW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHW9"
  },
  {
    "uniprot": "A0A3P7DIY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIY2"
  },
  {
    "uniprot": "A0A3P7EF07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF07"
  },
  {
    "uniprot": "A0A3P7DLK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLK0"
  },
  {
    "uniprot": "A0A3P7EPT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPT6"
  },
  {
    "uniprot": "A0A3P7GDD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDD2"
  },
  {
    "uniprot": "A0A3P7FTY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTY1"
  },
  {
    "uniprot": "A0A3P7EBS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBS1"
  },
  {
    "uniprot": "A0A3P7E3Y1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3Y1"
  },
  {
    "uniprot": "A0A3P7ET58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET58"
  },
  {
    "uniprot": "A0A3P7G158",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G158"
  },
  {
    "uniprot": "A0A3P7DX74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX74"
  },
  {
    "uniprot": "A0A3P7EJV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJV6"
  },
  {
    "uniprot": "A0A3P7E8T4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8T4"
  },
  {
    "uniprot": "A0A3P7EFI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFI8"
  },
  {
    "uniprot": "A0A3P7G2F0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2F0"
  },
  {
    "uniprot": "A0A3P7DWV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWV6"
  },
  {
    "uniprot": "A0A3P7FNZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNZ5"
  },
  {
    "uniprot": "A0A3P7FLR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLR9"
  },
  {
    "uniprot": "J9FGS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FGS8"
  },
  {
    "uniprot": "A0A3P7EYK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYK5"
  },
  {
    "uniprot": "A0A3P7DSQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSQ6"
  },
  {
    "uniprot": "A0A3P7EVL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVL2"
  },
  {
    "uniprot": "J9F5X1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F5X1"
  },
  {
    "uniprot": "A0A3P7E4Q2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4Q2"
  },
  {
    "uniprot": "A0A3P7DPQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPQ9"
  },
  {
    "uniprot": "A0A3P7GF29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GF29"
  },
  {
    "uniprot": "A0A3P7DY53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY53"
  },
  {
    "uniprot": "A0A3P7FB36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB36"
  },
  {
    "uniprot": "A0A3P7DD41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD41"
  },
  {
    "uniprot": "A0A3P7DYE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYE4"
  },
  {
    "uniprot": "A0A3P7FL07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL07"
  },
  {
    "uniprot": "A0A3P7FYP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYP8"
  },
  {
    "uniprot": "A0A3P7F2W1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2W1"
  },
  {
    "uniprot": "A0A3P7DKD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKD9"
  },
  {
    "uniprot": "A0A3P7DXQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXQ9"
  },
  {
    "uniprot": "A0A3P7EGU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGU3"
  },
  {
    "uniprot": "A0A3P7E1D3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1D3"
  },
  {
    "uniprot": "A0A3P7DJI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJI4"
  },
  {
    "uniprot": "A0A183XU17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XU17"
  },
  {
    "uniprot": "A0A3P7FI39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI39"
  },
  {
    "uniprot": "J9EZP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZP1"
  },
  {
    "uniprot": "A0A3P7E1G0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1G0"
  },
  {
    "uniprot": "A0A3P7DXQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXQ6"
  },
  {
    "uniprot": "A0A3P7FX98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX98"
  },
  {
    "uniprot": "A0A3P7DS13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS13"
  },
  {
    "uniprot": "A0A183XGM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XGM4"
  },
  {
    "uniprot": "A0A3P7FQ27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ27"
  },
  {
    "uniprot": "A0A183XHH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XHH9"
  },
  {
    "uniprot": "A0A3P7FBD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBD1"
  },
  {
    "uniprot": "A0A3P7EJE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJE5"
  },
  {
    "uniprot": "A0A3P7EBP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBP5"
  },
  {
    "uniprot": "A0A3P7DPZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPZ8"
  },
  {
    "uniprot": "A0A3P7E411",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E411"
  },
  {
    "uniprot": "A0A3P7FMB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMB0"
  },
  {
    "uniprot": "A0A3P7EFF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFF8"
  },
  {
    "uniprot": "A0A3P7FKP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKP9"
  },
  {
    "uniprot": "A0A3P7GGH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGH3"
  },
  {
    "uniprot": "A0A3P7FW74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW74"
  },
  {
    "uniprot": "A0A3P7DQ29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ29"
  },
  {
    "uniprot": "A0A3P7DZI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZI2"
  },
  {
    "uniprot": "A0A3P7EBG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBG0"
  },
  {
    "uniprot": "A0A3P7DEY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEY8"
  },
  {
    "uniprot": "A0A3P7E1L7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1L7"
  },
  {
    "uniprot": "A0A3P7DDU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDU0"
  },
  {
    "uniprot": "A0A3P7EDE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDE2"
  },
  {
    "uniprot": "A0A3P7GIT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIT1"
  },
  {
    "uniprot": "A0A3P7EHP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHP1"
  },
  {
    "uniprot": "A0A3P7DZF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZF0"
  },
  {
    "uniprot": "A0A3P7E688",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E688"
  },
  {
    "uniprot": "A0A3P7DNX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNX4"
  },
  {
    "uniprot": "A0A3P7DFE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFE5"
  },
  {
    "uniprot": "A0A3P7DIT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIT3"
  },
  {
    "uniprot": "J9FBE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FBE1"
  },
  {
    "uniprot": "A0A3P7ERJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERJ0"
  },
  {
    "uniprot": "A0A3P7DZV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZV4"
  },
  {
    "uniprot": "A0A3P7DSU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSU9"
  },
  {
    "uniprot": "A0A183Y255",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y255"
  },
  {
    "uniprot": "A0A3P7DUM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUM2"
  },
  {
    "uniprot": "A0A3P7DCW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCW3"
  },
  {
    "uniprot": "A0A3P7E6Y8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6Y8"
  },
  {
    "uniprot": "A0A3P7E5A6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5A6"
  },
  {
    "uniprot": "J9AAA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AAA2"
  },
  {
    "uniprot": "A0A3P7E0M3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0M3"
  },
  {
    "uniprot": "A0A183XLK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLK3"
  },
  {
    "uniprot": "A0A3P7GBT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBT5"
  },
  {
    "uniprot": "A0A3P7GBZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBZ0"
  },
  {
    "uniprot": "A0A3P7FYD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYD8"
  },
  {
    "uniprot": "A0A3P7FE71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE71"
  },
  {
    "uniprot": "A0A3P7E447",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E447"
  },
  {
    "uniprot": "A0A3P7DJA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJA8"
  },
  {
    "uniprot": "A0A3P7DR28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR28"
  },
  {
    "uniprot": "A0A3P7DDA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDA0"
  },
  {
    "uniprot": "A0A3P7G625",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G625"
  },
  {
    "uniprot": "J9F2P2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2P2"
  },
  {
    "uniprot": "A0A3P7E776",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E776"
  },
  {
    "uniprot": "A0A3P7FU85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU85"
  },
  {
    "uniprot": "J9FD08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FD08"
  },
  {
    "uniprot": "J9F5I8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F5I8"
  },
  {
    "uniprot": "A0A3P7F070",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F070"
  },
  {
    "uniprot": "A0A3P7DJ69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ69"
  },
  {
    "uniprot": "A0A183XQ65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XQ65"
  },
  {
    "uniprot": "A0A3P7DKR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKR3"
  },
  {
    "uniprot": "A0A3P7DTW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTW5"
  },
  {
    "uniprot": "A0A3P7E5A2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5A2"
  },
  {
    "uniprot": "A0A3P7GKJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKJ3"
  },
  {
    "uniprot": "A0A183XEK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XEK6"
  },
  {
    "uniprot": "A0A3P7FBP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBP3"
  },
  {
    "uniprot": "A0A3P7GH22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GH22"
  },
  {
    "uniprot": "A0A3P7DTQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTQ3"
  },
  {
    "uniprot": "J9BB91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BB91"
  },
  {
    "uniprot": "A0A3P7F1Q1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1Q1"
  },
  {
    "uniprot": "A0A3P7DFZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFZ3"
  },
  {
    "uniprot": "A0A3P7DZG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZG5"
  },
  {
    "uniprot": "A0A3P7EH53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH53"
  },
  {
    "uniprot": "A0A3P7DQ49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ49"
  },
  {
    "uniprot": "A0A183Y4L6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y4L6"
  },
  {
    "uniprot": "A0A3P7EU22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU22"
  },
  {
    "uniprot": "A0A3P7DNH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNH6"
  },
  {
    "uniprot": "A0A3P7DXF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXF9"
  },
  {
    "uniprot": "A0A3P7DUZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUZ7"
  },
  {
    "uniprot": "A0A3P7FY80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY80"
  },
  {
    "uniprot": "A0A3P7E1M0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1M0"
  },
  {
    "uniprot": "A0A3P7EFU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFU3"
  },
  {
    "uniprot": "A0A3P7G855",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G855"
  },
  {
    "uniprot": "A0A3P7E6W0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6W0"
  },
  {
    "uniprot": "A0A3P7EZ43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZ43"
  },
  {
    "uniprot": "A0A3P7DK10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK10"
  },
  {
    "uniprot": "A0A3P7FXA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXA8"
  },
  {
    "uniprot": "A0A3P7EDS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDS3"
  },
  {
    "uniprot": "A0A3P7EFG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFG9"
  },
  {
    "uniprot": "A0A3P7F2X9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2X9"
  },
  {
    "uniprot": "A0A3P7EWR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWR7"
  },
  {
    "uniprot": "A0A3P7G5Z7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5Z7"
  },
  {
    "uniprot": "A0A3P7DNH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNH2"
  },
  {
    "uniprot": "A0A3P7EID5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EID5"
  },
  {
    "uniprot": "A0A3P7E9R3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9R3"
  },
  {
    "uniprot": "A0A3P7DJ42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ42"
  },
  {
    "uniprot": "A0A3P7E5B5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5B5"
  },
  {
    "uniprot": "A0A3P7DMN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMN7"
  },
  {
    "uniprot": "A0A3P7FQL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQL0"
  },
  {
    "uniprot": "A0A3P7GIP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIP1"
  },
  {
    "uniprot": "A0A3P7FLM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLM5"
  },
  {
    "uniprot": "A0A183XQI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XQI8"
  },
  {
    "uniprot": "A0A3P7EB98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB98"
  },
  {
    "uniprot": "A0A3P7FHD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHD2"
  },
  {
    "uniprot": "A0A183XYH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XYH8"
  },
  {
    "uniprot": "A0A3P7E6U5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6U5"
  },
  {
    "uniprot": "A0A3P7FAR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAR8"
  },
  {
    "uniprot": "J9FEN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FEN4"
  },
  {
    "uniprot": "A0A3P7E8U8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8U8"
  },
  {
    "uniprot": "A0A3P7G0N7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0N7"
  },
  {
    "uniprot": "A0A3P7DPF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPF2"
  },
  {
    "uniprot": "A0A3P7DZY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZY6"
  },
  {
    "uniprot": "A0A3P7E0D9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0D9"
  },
  {
    "uniprot": "A0A3P7FMR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMR0"
  },
  {
    "uniprot": "A0A3P7EUQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUQ5"
  },
  {
    "uniprot": "A0A3P7FD28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD28"
  },
  {
    "uniprot": "A0A3P7EET8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EET8"
  },
  {
    "uniprot": "A0A3P7G295",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G295"
  },
  {
    "uniprot": "A0A3P7FH21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH21"
  },
  {
    "uniprot": "A0A3P7G7Q3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G7Q3"
  },
  {
    "uniprot": "A0A3P7FXC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXC1"
  },
  {
    "uniprot": "A0A3P7GMA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GMA9"
  },
  {
    "uniprot": "A0A3P7DPC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPC9"
  },
  {
    "uniprot": "A0A3P7DVU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVU4"
  },
  {
    "uniprot": "A0A3P7GCQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCQ4"
  },
  {
    "uniprot": "A0A3P7DYV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYV6"
  },
  {
    "uniprot": "A0A3P7E5S1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5S1"
  },
  {
    "uniprot": "A0A3P7FIA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIA6"
  },
  {
    "uniprot": "A0A3P7DUH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUH1"
  },
  {
    "uniprot": "A0A3P7FH66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH66"
  },
  {
    "uniprot": "A0A3P7FMJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMJ3"
  },
  {
    "uniprot": "A0A3P7E0Z3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0Z3"
  },
  {
    "uniprot": "A0A3P7F4K3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F4K3"
  },
  {
    "uniprot": "A0A3P7EI22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI22"
  },
  {
    "uniprot": "A0A3P7EYM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYM1"
  },
  {
    "uniprot": "A0A3P7E2B5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2B5"
  },
  {
    "uniprot": "A0A3P7E072",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E072"
  },
  {
    "uniprot": "A0A3P7DMH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMH9"
  },
  {
    "uniprot": "A0A3P7ECR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECR7"
  },
  {
    "uniprot": "A0A3P7G065",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G065"
  },
  {
    "uniprot": "A0A3P7DN62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN62"
  },
  {
    "uniprot": "A0A3P7E1Y0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1Y0"
  },
  {
    "uniprot": "A0A3P7FZI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZI9"
  },
  {
    "uniprot": "A0A3P7GLR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLR6"
  },
  {
    "uniprot": "A0A3P7E2N0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2N0"
  },
  {
    "uniprot": "J9FB84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FB84"
  },
  {
    "uniprot": "A0A3P7GBN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBN5"
  },
  {
    "uniprot": "A0A3P7ELW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELW6"
  },
  {
    "uniprot": "A0A3P7DCR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCR2"
  },
  {
    "uniprot": "A0A3P7DGR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGR9"
  },
  {
    "uniprot": "A0A183XGY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XGY7"
  },
  {
    "uniprot": "A0A3P7EKC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKC6"
  },
  {
    "uniprot": "J9BK35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BK35"
  },
  {
    "uniprot": "J9E2Q8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E2Q8"
  },
  {
    "uniprot": "A0A3P7E853",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E853"
  },
  {
    "uniprot": "A0A3P7FEU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEU7"
  },
  {
    "uniprot": "J9F2C6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2C6"
  },
  {
    "uniprot": "A0A3P7FNN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNN2"
  },
  {
    "uniprot": "A0A3P7E813",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E813"
  },
  {
    "uniprot": "J9B7E7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B7E7"
  },
  {
    "uniprot": "A0A183XB96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XB96"
  },
  {
    "uniprot": "A0A3P7E2V7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2V7"
  },
  {
    "uniprot": "A0A3P7FFQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFQ3"
  },
  {
    "uniprot": "A0A3P7EP76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP76"
  },
  {
    "uniprot": "A0A3P7E234",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E234"
  },
  {
    "uniprot": "J9FCA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FCA3"
  },
  {
    "uniprot": "A0A3P7FXE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXE2"
  },
  {
    "uniprot": "A0A3P7DPK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPK1"
  },
  {
    "uniprot": "A0A3P7EDK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDK8"
  },
  {
    "uniprot": "A0A3P7FMG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMG5"
  },
  {
    "uniprot": "A0A3P7E4Q1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4Q1"
  },
  {
    "uniprot": "A0A3P7DZH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZH3"
  },
  {
    "uniprot": "A0A3P7EHD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHD3"
  },
  {
    "uniprot": "A0A3P7FQB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQB7"
  },
  {
    "uniprot": "A0A3P7FDD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDD9"
  },
  {
    "uniprot": "A0A3P7FVQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVQ9"
  },
  {
    "uniprot": "A0A3P7DYK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYK5"
  },
  {
    "uniprot": "A0A3P7GI95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GI95"
  },
  {
    "uniprot": "A0A3P7EWW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWW0"
  },
  {
    "uniprot": "A0A3P7EMY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMY3"
  },
  {
    "uniprot": "A0A3P7EHA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHA9"
  },
  {
    "uniprot": "A0A3P7E6X2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6X2"
  },
  {
    "uniprot": "A0A3P7F0G4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0G4"
  },
  {
    "uniprot": "A0A3P7E6B8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6B8"
  },
  {
    "uniprot": "A0A3P7E3E2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3E2"
  },
  {
    "uniprot": "A0A3P7EYI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYI8"
  },
  {
    "uniprot": "A0A3P7FRC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRC5"
  },
  {
    "uniprot": "A0A3P7EEQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEQ5"
  },
  {
    "uniprot": "A0A3P7E088",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E088"
  },
  {
    "uniprot": "A0A3P7E7F6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7F6"
  },
  {
    "uniprot": "A0A3P7DYZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYZ3"
  },
  {
    "uniprot": "A0A3P7FM76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM76"
  },
  {
    "uniprot": "A0A3P7DU87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU87"
  },
  {
    "uniprot": "J9FG43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FG43"
  },
  {
    "uniprot": "A0A3P7G708",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G708"
  },
  {
    "uniprot": "A0A3P7FVZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVZ1"
  },
  {
    "uniprot": "J9B957",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B957"
  },
  {
    "uniprot": "J9EKA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EKA3"
  },
  {
    "uniprot": "A0A3P7E956",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E956"
  },
  {
    "uniprot": "A0A3P7FD81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD81"
  },
  {
    "uniprot": "A0A3P7E4N5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4N5"
  },
  {
    "uniprot": "A0A3P7FUA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUA1"
  },
  {
    "uniprot": "A0A3P7DTM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTM7"
  },
  {
    "uniprot": "A0A3P7E9H6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9H6"
  },
  {
    "uniprot": "A0A3P7EMW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMW8"
  },
  {
    "uniprot": "A0A3P7EAC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAC6"
  },
  {
    "uniprot": "A0A3P7E1U8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1U8"
  },
  {
    "uniprot": "A0A3P7DQB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQB0"
  },
  {
    "uniprot": "A0A3P7FZF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZF0"
  },
  {
    "uniprot": "J9F304",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F304"
  },
  {
    "uniprot": "A0A3P7DZB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZB0"
  },
  {
    "uniprot": "A0A3P7FDG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDG0"
  },
  {
    "uniprot": "J9EZE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZE6"
  },
  {
    "uniprot": "A0A3P7E4W2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4W2"
  },
  {
    "uniprot": "A0A3P7EGF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGF9"
  },
  {
    "uniprot": "A0A3P7DRW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRW2"
  },
  {
    "uniprot": "A0A3P7DBT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBT4"
  },
  {
    "uniprot": "A0A3P7ERS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERS1"
  },
  {
    "uniprot": "J9EEI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EEI8"
  },
  {
    "uniprot": "A0A3P7FPI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPI0"
  },
  {
    "uniprot": "A0A3P7EJ27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ27"
  },
  {
    "uniprot": "A0A3P7ELI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELI3"
  },
  {
    "uniprot": "A0A3P7DMV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMV0"
  },
  {
    "uniprot": "A0A3P7FLZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLZ6"
  },
  {
    "uniprot": "A0A3P7E0F0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0F0"
  },
  {
    "uniprot": "A0A3P7EDT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDT1"
  },
  {
    "uniprot": "A0A3P7DRK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRK8"
  },
  {
    "uniprot": "A0A183XB43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XB43"
  },
  {
    "uniprot": "A0A3P7F1V0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1V0"
  },
  {
    "uniprot": "A0A3P7DXN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXN4"
  },
  {
    "uniprot": "A0A3P7DVK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVK4"
  },
  {
    "uniprot": "A0A3P7E8Z9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8Z9"
  },
  {
    "uniprot": "A0A3P7EFM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFM9"
  },
  {
    "uniprot": "A0A3P7G6N3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6N3"
  },
  {
    "uniprot": "A0A3P7FQJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQJ8"
  },
  {
    "uniprot": "A0A3P7E4P4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4P4"
  },
  {
    "uniprot": "J9FA96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FA96"
  },
  {
    "uniprot": "J9FFS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FFS2"
  },
  {
    "uniprot": "A0A3P7DSU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSU4"
  },
  {
    "uniprot": "A0A3P7DVY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVY5"
  },
  {
    "uniprot": "A0A3P7GLN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLN8"
  },
  {
    "uniprot": "A0A3P7FIV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIV1"
  },
  {
    "uniprot": "A0A3P7DME9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DME9"
  },
  {
    "uniprot": "A0A3P7E413",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E413"
  },
  {
    "uniprot": "A0A183XNN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XNN4"
  },
  {
    "uniprot": "J9FBD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FBD6"
  },
  {
    "uniprot": "A0A3P7DMU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMU2"
  },
  {
    "uniprot": "A0A3P7EZ44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZ44"
  },
  {
    "uniprot": "J9EXX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXX1"
  },
  {
    "uniprot": "A0A3P7EG79",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG79"
  },
  {
    "uniprot": "A0A3P7E7I0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7I0"
  },
  {
    "uniprot": "A0A3P7DYE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYE9"
  },
  {
    "uniprot": "A0A3P7DAZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAZ5"
  },
  {
    "uniprot": "A0A3P7EN33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN33"
  },
  {
    "uniprot": "A0A3P7FWK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWK9"
  },
  {
    "uniprot": "A0A3P7E2A3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2A3"
  },
  {
    "uniprot": "A0A3P7DSP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSP8"
  },
  {
    "uniprot": "A0A3P7DWR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWR5"
  },
  {
    "uniprot": "A0A3P7DCN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCN9"
  },
  {
    "uniprot": "A0A3P7DWX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWX6"
  },
  {
    "uniprot": "A0A3P7FQL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQL9"
  },
  {
    "uniprot": "A0A3P7GEF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEF4"
  },
  {
    "uniprot": "A0A3P7EHF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHF3"
  },
  {
    "uniprot": "J9F1Y4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1Y4"
  },
  {
    "uniprot": "J9EPA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPA0"
  },
  {
    "uniprot": "A0A3P7DX94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX94"
  },
  {
    "uniprot": "A0A3P7DYK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYK2"
  },
  {
    "uniprot": "A0A3P7DNP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNP1"
  },
  {
    "uniprot": "A0A3P7EP02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP02"
  },
  {
    "uniprot": "A0A3P7EDG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDG5"
  },
  {
    "uniprot": "A0A3P7FI84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI84"
  },
  {
    "uniprot": "A0A3P7EKN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKN2"
  },
  {
    "uniprot": "A0A3P7G0T9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0T9"
  },
  {
    "uniprot": "A0A3P7DSZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSZ7"
  },
  {
    "uniprot": "A0A3P7DP43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP43"
  },
  {
    "uniprot": "A0A3P7ECY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECY2"
  },
  {
    "uniprot": "A0A3P7EC52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC52"
  },
  {
    "uniprot": "A0A3P7F3A3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3A3"
  },
  {
    "uniprot": "A0A3P7DG84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG84"
  },
  {
    "uniprot": "A0A3P7GHA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHA3"
  },
  {
    "uniprot": "A0A3P7EFC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFC7"
  },
  {
    "uniprot": "A0A3P7FU47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU47"
  },
  {
    "uniprot": "A0A3P7DI76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI76"
  },
  {
    "uniprot": "A0A3P7FLJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLJ1"
  },
  {
    "uniprot": "A0A3P7EFN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFN4"
  },
  {
    "uniprot": "A0A3P7DRN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRN5"
  },
  {
    "uniprot": "A0A3P7EDQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDQ8"
  },
  {
    "uniprot": "A0A3P7GBC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBC5"
  },
  {
    "uniprot": "A0A3P7FGQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGQ8"
  },
  {
    "uniprot": "A0A3P7G584",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G584"
  },
  {
    "uniprot": "A0A3P7GCY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCY2"
  },
  {
    "uniprot": "A0A3P7DAX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAX6"
  },
  {
    "uniprot": "A0A3P7E2N3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2N3"
  },
  {
    "uniprot": "A0A3P7EI29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI29"
  },
  {
    "uniprot": "A0A3P7E9P3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9P3"
  },
  {
    "uniprot": "A0A3P7DTU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTU9"
  },
  {
    "uniprot": "A0A3P7EEG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEG4"
  },
  {
    "uniprot": "A0A3P7E9C6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9C6"
  },
  {
    "uniprot": "A0A3P7DYQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYQ1"
  },
  {
    "uniprot": "A0A183Y952",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y952"
  },
  {
    "uniprot": "A0A3P7DZ28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ28"
  },
  {
    "uniprot": "A0A3P7E8V8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8V8"
  },
  {
    "uniprot": "A0A3P7E7W0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7W0"
  },
  {
    "uniprot": "J9F1E4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1E4"
  },
  {
    "uniprot": "J9BBW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BBW9"
  },
  {
    "uniprot": "A0A3P7EI55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI55"
  },
  {
    "uniprot": "A0A3P7E9L1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9L1"
  },
  {
    "uniprot": "A0A183Y0W9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y0W9"
  },
  {
    "uniprot": "A0A3P7FWI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWI3"
  },
  {
    "uniprot": "J9EKD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EKD4"
  },
  {
    "uniprot": "A0A3P7FTE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTE8"
  },
  {
    "uniprot": "A0A3P7DUB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUB2"
  },
  {
    "uniprot": "J9BHK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BHK9"
  },
  {
    "uniprot": "A0A3P7GAB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAB6"
  },
  {
    "uniprot": "A0A3P7DGL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGL1"
  },
  {
    "uniprot": "A0A3P7GK14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GK14"
  },
  {
    "uniprot": "A0A3P7GKS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKS2"
  },
  {
    "uniprot": "A0A3P7FSF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSF4"
  },
  {
    "uniprot": "A0A3P7GJT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJT2"
  },
  {
    "uniprot": "A0A3P7F1Q9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1Q9"
  },
  {
    "uniprot": "A0A3P7FTP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTP6"
  },
  {
    "uniprot": "A0A3P7DC92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC92"
  },
  {
    "uniprot": "A0A3P7E2J6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2J6"
  },
  {
    "uniprot": "A0A3P7EDS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDS9"
  },
  {
    "uniprot": "A0A3P7FIJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIJ3"
  },
  {
    "uniprot": "J9F366",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F366"
  },
  {
    "uniprot": "A0A3P7DED9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DED9"
  },
  {
    "uniprot": "A0A3P7DN88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN88"
  },
  {
    "uniprot": "A0A3P7ED10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED10"
  },
  {
    "uniprot": "A0A3P7DHY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHY4"
  },
  {
    "uniprot": "A0A3P7DRB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRB3"
  },
  {
    "uniprot": "A0A3P7GN65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GN65"
  },
  {
    "uniprot": "A0A183XDD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDD4"
  },
  {
    "uniprot": "A0A3P7DU37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU37"
  },
  {
    "uniprot": "A0A3P7ET52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET52"
  },
  {
    "uniprot": "A0A3P7DS88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS88"
  },
  {
    "uniprot": "A0A3P7ESK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESK7"
  },
  {
    "uniprot": "A0A3P7DIR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIR4"
  },
  {
    "uniprot": "A0A3P7E6I4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6I4"
  },
  {
    "uniprot": "A0A3P7EKC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKC4"
  },
  {
    "uniprot": "A0A3P7FDG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDG9"
  },
  {
    "uniprot": "A0A3P7EVP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVP7"
  },
  {
    "uniprot": "A0A3P7DP34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP34"
  },
  {
    "uniprot": "A0A3P7FZU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZU5"
  },
  {
    "uniprot": "A0A3P7FDA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDA5"
  },
  {
    "uniprot": "A0A183XKB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XKB0"
  },
  {
    "uniprot": "A0A3P7ELN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELN2"
  },
  {
    "uniprot": "A0A3P7GBS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBS0"
  },
  {
    "uniprot": "J9F0F3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F0F3"
  },
  {
    "uniprot": "A0A3P7E018",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E018"
  },
  {
    "uniprot": "A0A3P7GGD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGD9"
  },
  {
    "uniprot": "A0A3P7F1B5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1B5"
  },
  {
    "uniprot": "A0A3P7F0K9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0K9"
  },
  {
    "uniprot": "A0A3P7DJW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJW9"
  },
  {
    "uniprot": "A0A3P7F2S8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2S8"
  },
  {
    "uniprot": "A0A3P7EDG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDG7"
  },
  {
    "uniprot": "A0A3P7E3K3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3K3"
  },
  {
    "uniprot": "A0A3P7ELC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELC4"
  },
  {
    "uniprot": "A0A3P7E6N6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6N6"
  },
  {
    "uniprot": "A0A3P7EMY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMY8"
  },
  {
    "uniprot": "A0A3P7DGY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGY9"
  },
  {
    "uniprot": "A0A3P7DW47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW47"
  },
  {
    "uniprot": "A0A3P7DBG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBG0"
  },
  {
    "uniprot": "A0A3P7EVE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVE9"
  },
  {
    "uniprot": "A0A3P7DY65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY65"
  },
  {
    "uniprot": "A0A3P7GLJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLJ9"
  },
  {
    "uniprot": "A0A3P7EE03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE03"
  },
  {
    "uniprot": "A0A183XRW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XRW8"
  },
  {
    "uniprot": "A0A3P7FZH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZH9"
  },
  {
    "uniprot": "A0A3P7DQZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQZ0"
  },
  {
    "uniprot": "A0A3P7EAE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAE6"
  },
  {
    "uniprot": "A0A3P7ECG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECG5"
  },
  {
    "uniprot": "A0A3P7E762",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E762"
  },
  {
    "uniprot": "A0A3P7EST8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EST8"
  },
  {
    "uniprot": "A0A3P7DK55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK55"
  },
  {
    "uniprot": "A0A3P7DJD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJD7"
  },
  {
    "uniprot": "A0A3P7FTC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTC3"
  },
  {
    "uniprot": "A0A3P7E1W7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1W7"
  },
  {
    "uniprot": "A0A3P7FKR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKR8"
  },
  {
    "uniprot": "A0A3P7FLX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLX5"
  },
  {
    "uniprot": "A0A3P7DYX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYX0"
  },
  {
    "uniprot": "A0A3P7EDG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDG4"
  },
  {
    "uniprot": "A0A3P7E7T2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7T2"
  },
  {
    "uniprot": "A0A3P7ESI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESI7"
  },
  {
    "uniprot": "A0A3P7ETR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETR0"
  },
  {
    "uniprot": "A0A3P7DV05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV05"
  },
  {
    "uniprot": "A0A3P7DN52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN52"
  },
  {
    "uniprot": "A0A3P7FPH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPH6"
  },
  {
    "uniprot": "A0A3P7DW60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW60"
  },
  {
    "uniprot": "A0A3P7ED53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED53"
  },
  {
    "uniprot": "A0A3P7FLT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLT8"
  },
  {
    "uniprot": "A0A3P7FTZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTZ9"
  },
  {
    "uniprot": "A0A3P7E7B1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7B1"
  },
  {
    "uniprot": "A0A3P7F8J1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8J1"
  },
  {
    "uniprot": "A0A3P7E590",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E590"
  },
  {
    "uniprot": "A0A3P7EKB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKB0"
  },
  {
    "uniprot": "A0A3P7E1B9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1B9"
  },
  {
    "uniprot": "A0A3P7DYG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYG6"
  },
  {
    "uniprot": "J9FL53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FL53"
  },
  {
    "uniprot": "A0A3P7DU35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU35"
  },
  {
    "uniprot": "A0A3P7DJD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJD9"
  },
  {
    "uniprot": "A0A3P7EBP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBP6"
  },
  {
    "uniprot": "A0A3P7E480",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E480"
  },
  {
    "uniprot": "A0A183XBV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XBV6"
  },
  {
    "uniprot": "A0A3P7E3J1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3J1"
  },
  {
    "uniprot": "A0A3P7G0R1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0R1"
  },
  {
    "uniprot": "A0A3P7DHP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHP6"
  },
  {
    "uniprot": "J9AL22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AL22"
  },
  {
    "uniprot": "J9ERZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERZ8"
  },
  {
    "uniprot": "A0A3P7GIC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIC5"
  },
  {
    "uniprot": "J9BC04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BC04"
  },
  {
    "uniprot": "J9EPP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPP7"
  },
  {
    "uniprot": "A0A3P7GGF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGF0"
  },
  {
    "uniprot": "A0A3P7DD04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD04"
  },
  {
    "uniprot": "A0A3P7FS49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS49"
  },
  {
    "uniprot": "A0A3P7FX16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX16"
  },
  {
    "uniprot": "A0A3P7FKZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKZ6"
  },
  {
    "uniprot": "A0A3P7E8C7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8C7"
  },
  {
    "uniprot": "J9EUK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUK9"
  },
  {
    "uniprot": "A0A3P7FL96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL96"
  },
  {
    "uniprot": "A0A3P7E4G3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4G3"
  },
  {
    "uniprot": "A0A3P7DI67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI67"
  },
  {
    "uniprot": "A0A3P7EFK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFK3"
  },
  {
    "uniprot": "A0A183XCN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCN6"
  },
  {
    "uniprot": "A0A3P7FKD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKD1"
  },
  {
    "uniprot": "A0A3P7FR25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR25"
  },
  {
    "uniprot": "A0A3P7G6K1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6K1"
  },
  {
    "uniprot": "A0A3P7EB26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB26"
  },
  {
    "uniprot": "A0A3P7DBX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBX0"
  },
  {
    "uniprot": "A0A3P7E2D3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2D3"
  },
  {
    "uniprot": "A0A3P7EJ75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ75"
  },
  {
    "uniprot": "A0A3P7DQI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQI2"
  },
  {
    "uniprot": "A0A3P7FSE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSE5"
  },
  {
    "uniprot": "A0A183Y2H2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y2H2"
  },
  {
    "uniprot": "A0A3P7ECW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECW9"
  },
  {
    "uniprot": "A0A3P7E2J3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2J3"
  },
  {
    "uniprot": "A0A3P7FSF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSF9"
  },
  {
    "uniprot": "A0A3P7GHF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHF4"
  },
  {
    "uniprot": "J9F3M8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F3M8"
  },
  {
    "uniprot": "A0A3P7DFF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFF2"
  },
  {
    "uniprot": "A0A3P7DK16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK16"
  },
  {
    "uniprot": "A0A3P7DY04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY04"
  },
  {
    "uniprot": "A0A3P7DH42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH42"
  },
  {
    "uniprot": "A0A3P7EEY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEY7"
  },
  {
    "uniprot": "A0A3P7FGH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGH1"
  },
  {
    "uniprot": "A0A3P7FT60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT60"
  },
  {
    "uniprot": "A0A3P7FIF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIF8"
  },
  {
    "uniprot": "A0A3P7DIP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIP1"
  },
  {
    "uniprot": "A0A3P7E522",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E522"
  },
  {
    "uniprot": "A0A3P7E7H8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7H8"
  },
  {
    "uniprot": "A0A3P7DIZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIZ6"
  },
  {
    "uniprot": "A0A3P7F7L2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7L2"
  },
  {
    "uniprot": "A0A3P7GBL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBL9"
  },
  {
    "uniprot": "A0A3P7E5S5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5S5"
  },
  {
    "uniprot": "A0A3P7DD86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD86"
  },
  {
    "uniprot": "A0A3P7F8A3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8A3"
  },
  {
    "uniprot": "A0A3P7DEF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEF3"
  },
  {
    "uniprot": "A0A3P7E202",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E202"
  },
  {
    "uniprot": "A0A3P7EJS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJS8"
  },
  {
    "uniprot": "J9FFQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FFQ2"
  },
  {
    "uniprot": "A0A3P7EUF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUF9"
  },
  {
    "uniprot": "J9AMX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AMX5"
  },
  {
    "uniprot": "A0A3P7FTJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTJ9"
  },
  {
    "uniprot": "A0A3P7E4A8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4A8"
  },
  {
    "uniprot": "A0A3P7FIX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIX0"
  },
  {
    "uniprot": "A0A3P7DNJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNJ2"
  },
  {
    "uniprot": "A0A3P7GJF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJF9"
  },
  {
    "uniprot": "A0A3P7EKG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKG2"
  },
  {
    "uniprot": "A0A3P7EN09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN09"
  },
  {
    "uniprot": "J9BLC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BLC8"
  },
  {
    "uniprot": "A0A3P7FKL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKL1"
  },
  {
    "uniprot": "A0A3P7FRR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRR8"
  },
  {
    "uniprot": "A0A3P7EPC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPC4"
  },
  {
    "uniprot": "A0A3P7GK56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GK56"
  },
  {
    "uniprot": "A0A3P7DJ67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ67"
  },
  {
    "uniprot": "A0A3P7DRT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRT8"
  },
  {
    "uniprot": "A0A3P7E5P2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5P2"
  },
  {
    "uniprot": "A0A3P7FQM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQM7"
  },
  {
    "uniprot": "J9DZ85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DZ85"
  },
  {
    "uniprot": "A0A3P7GE93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GE93"
  },
  {
    "uniprot": "A0A3P7ER18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ER18"
  },
  {
    "uniprot": "A0A183XUI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XUI4"
  },
  {
    "uniprot": "A0A3P7F2V0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2V0"
  },
  {
    "uniprot": "A0A3P7G0Y1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0Y1"
  },
  {
    "uniprot": "A0A3P7FS34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS34"
  },
  {
    "uniprot": "A0A3P7E8C6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8C6"
  },
  {
    "uniprot": "A0A3P7E4C8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4C8"
  },
  {
    "uniprot": "A0A3P7DGD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGD9"
  },
  {
    "uniprot": "A0A183XF37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XF37"
  },
  {
    "uniprot": "A0A3P7DMQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMQ5"
  },
  {
    "uniprot": "A0A3P7E7A8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7A8"
  },
  {
    "uniprot": "A0A3P7G867",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G867"
  },
  {
    "uniprot": "A0A3P7EED4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EED4"
  },
  {
    "uniprot": "A0A3P7E517",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E517"
  },
  {
    "uniprot": "A0A3P7E205",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E205"
  },
  {
    "uniprot": "A0A3P7EBQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBQ7"
  },
  {
    "uniprot": "A0A3P7EBS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBS2"
  },
  {
    "uniprot": "A0A3P7G5C2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5C2"
  },
  {
    "uniprot": "A0A3P7FEM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEM1"
  },
  {
    "uniprot": "A0A3P7FRY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRY1"
  },
  {
    "uniprot": "A0A3P7DYP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYP5"
  },
  {
    "uniprot": "A0A3P7GB97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GB97"
  },
  {
    "uniprot": "A0A3P7DT80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT80"
  },
  {
    "uniprot": "A0A3P7DPM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPM8"
  },
  {
    "uniprot": "A0A3P7GMT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GMT1"
  },
  {
    "uniprot": "A0A3P7FGJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGJ8"
  },
  {
    "uniprot": "J9EME4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EME4"
  },
  {
    "uniprot": "A0A3P7DSK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSK7"
  },
  {
    "uniprot": "A0A3P7EIZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIZ5"
  },
  {
    "uniprot": "A0A3P7DXE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXE1"
  },
  {
    "uniprot": "A0A3P7EUM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUM6"
  },
  {
    "uniprot": "A0A3P7ECX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECX8"
  },
  {
    "uniprot": "A0A3P7EE14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE14"
  },
  {
    "uniprot": "J9F3P7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F3P7"
  },
  {
    "uniprot": "A0A3P7DV37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV37"
  },
  {
    "uniprot": "A0A3P7GDE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDE5"
  },
  {
    "uniprot": "A0A183XGH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XGH6"
  },
  {
    "uniprot": "A0A183Y4T7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y4T7"
  },
  {
    "uniprot": "A0A3P7E0R3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0R3"
  },
  {
    "uniprot": "A0A3P7EIW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIW2"
  },
  {
    "uniprot": "A0A3P7FQD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQD6"
  },
  {
    "uniprot": "A0A3P7FY64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY64"
  },
  {
    "uniprot": "A0A3P7FRN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRN3"
  },
  {
    "uniprot": "A0A3P7DN40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN40"
  },
  {
    "uniprot": "A0A3P7DZK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZK2"
  },
  {
    "uniprot": "A0A3P7E9V7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9V7"
  },
  {
    "uniprot": "A0A3P7F044",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F044"
  },
  {
    "uniprot": "A0A3P7EA44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA44"
  },
  {
    "uniprot": "A0A3P7EAD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAD4"
  },
  {
    "uniprot": "A0A3P7FQB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQB1"
  },
  {
    "uniprot": "A0A183XS22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XS22"
  },
  {
    "uniprot": "A0A3P7FCV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCV5"
  },
  {
    "uniprot": "A0A3P7DND6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DND6"
  },
  {
    "uniprot": "A0A183XCX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCX4"
  },
  {
    "uniprot": "A0A3P7DUM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUM7"
  },
  {
    "uniprot": "A0A3P7ED59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED59"
  },
  {
    "uniprot": "A0A3P7DA93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DA93"
  },
  {
    "uniprot": "J9E5Y0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E5Y0"
  },
  {
    "uniprot": "A0A3P7DHQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHQ4"
  },
  {
    "uniprot": "A0A3P7E147",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E147"
  },
  {
    "uniprot": "A0A3P7DUV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUV1"
  },
  {
    "uniprot": "A0A3P7E930",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E930"
  },
  {
    "uniprot": "A0A3P7DWW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWW3"
  },
  {
    "uniprot": "A0A3P7EG18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG18"
  },
  {
    "uniprot": "A0A3P7FJ99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ99"
  },
  {
    "uniprot": "A0A3P7G4C3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4C3"
  },
  {
    "uniprot": "A0A3P7EB95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB95"
  },
  {
    "uniprot": "A0A3P7FFH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFH5"
  },
  {
    "uniprot": "A0A3P7E811",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E811"
  },
  {
    "uniprot": "A0A183XHW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XHW0"
  },
  {
    "uniprot": "A0A3P7EE60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE60"
  },
  {
    "uniprot": "A0A3P7FS61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS61"
  },
  {
    "uniprot": "A0A3P7DS24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS24"
  },
  {
    "uniprot": "A0A3P7FL29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL29"
  },
  {
    "uniprot": "A0A3P7DJG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJG0"
  },
  {
    "uniprot": "A0A3P7DW51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW51"
  },
  {
    "uniprot": "A0A3P7DQV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQV2"
  },
  {
    "uniprot": "A0A3P7EPW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPW6"
  },
  {
    "uniprot": "A0A3P7DYY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYY0"
  },
  {
    "uniprot": "J9E9D0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E9D0"
  },
  {
    "uniprot": "A0A3P7DQU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQU3"
  },
  {
    "uniprot": "A0A3P7E8W4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8W4"
  },
  {
    "uniprot": "A0A3P7FLU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLU4"
  },
  {
    "uniprot": "A0A3P7DXS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXS6"
  },
  {
    "uniprot": "A0A3P7EDM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDM2"
  },
  {
    "uniprot": "A0A3P7DW17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW17"
  },
  {
    "uniprot": "A0A3P7EN97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN97"
  },
  {
    "uniprot": "A0A3P7F9Y9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9Y9"
  },
  {
    "uniprot": "A0A3P7DUL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUL6"
  },
  {
    "uniprot": "A0A3P7E9C9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9C9"
  },
  {
    "uniprot": "A0A3P7E695",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E695"
  },
  {
    "uniprot": "A0A3P7DY20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY20"
  },
  {
    "uniprot": "A0A3P7FKP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKP0"
  },
  {
    "uniprot": "A0A3P7E3V4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3V4"
  },
  {
    "uniprot": "J9EPB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPB4"
  },
  {
    "uniprot": "A0A3P7E5T0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5T0"
  },
  {
    "uniprot": "A0A3P7DXB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXB8"
  },
  {
    "uniprot": "A0A3P7G5I2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5I2"
  },
  {
    "uniprot": "J9ESP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ESP4"
  },
  {
    "uniprot": "A0A3P7DJV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJV9"
  },
  {
    "uniprot": "A0A3P7FTE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTE3"
  },
  {
    "uniprot": "A0A183Y1Y8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y1Y8"
  },
  {
    "uniprot": "A0A3P7EQ27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQ27"
  },
  {
    "uniprot": "A0A3P7E8L4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8L4"
  },
  {
    "uniprot": "A0A3P7E9Q2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9Q2"
  },
  {
    "uniprot": "A0A3P7F9Q4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9Q4"
  },
  {
    "uniprot": "A0A3P7F069",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F069"
  },
  {
    "uniprot": "A0A3P7EFB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFB3"
  },
  {
    "uniprot": "A0A3P7DSM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSM0"
  },
  {
    "uniprot": "A0A3P7DL67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL67"
  },
  {
    "uniprot": "A0A3P7ES61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ES61"
  },
  {
    "uniprot": "A0A3P7FMM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMM5"
  },
  {
    "uniprot": "J9ECP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ECP9"
  },
  {
    "uniprot": "A0A3P7GIX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIX0"
  },
  {
    "uniprot": "A0A3P7FS03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS03"
  },
  {
    "uniprot": "A0A3P7E804",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E804"
  },
  {
    "uniprot": "A0A3P7EFW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFW1"
  },
  {
    "uniprot": "A0A3P7DQF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQF3"
  },
  {
    "uniprot": "A0A3P7ECZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECZ9"
  },
  {
    "uniprot": "A0A3P7E4G0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4G0"
  },
  {
    "uniprot": "A0A3P7G8H6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8H6"
  },
  {
    "uniprot": "J9BFC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BFC9"
  },
  {
    "uniprot": "A0A3P7EBY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBY4"
  },
  {
    "uniprot": "A0A3P7DVF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVF4"
  },
  {
    "uniprot": "A0A3P7E121",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E121"
  },
  {
    "uniprot": "A0A3P7E2K5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2K5"
  },
  {
    "uniprot": "A0A3P7FW23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW23"
  },
  {
    "uniprot": "A0A3P7DVK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVK8"
  },
  {
    "uniprot": "A0A3P7DU08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU08"
  },
  {
    "uniprot": "A0A3P7FA08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FA08"
  },
  {
    "uniprot": "A0A3P7FT87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT87"
  },
  {
    "uniprot": "A0A3P7DW33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW33"
  },
  {
    "uniprot": "A0A3P7EN00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN00"
  },
  {
    "uniprot": "A0A3P7EDJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDJ6"
  },
  {
    "uniprot": "A0A3P7E2V1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2V1"
  },
  {
    "uniprot": "A0A3P7DC86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC86"
  },
  {
    "uniprot": "A0A3P7FMD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMD3"
  },
  {
    "uniprot": "A0A3P7FPA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPA1"
  },
  {
    "uniprot": "A0A3P7FC26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC26"
  },
  {
    "uniprot": "A0A3P7E2H1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2H1"
  },
  {
    "uniprot": "A0A3P7E753",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E753"
  },
  {
    "uniprot": "A0A3P7DQZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQZ7"
  },
  {
    "uniprot": "A0A3P7EYI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYI4"
  },
  {
    "uniprot": "J9B967",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B967"
  },
  {
    "uniprot": "A0A3P7DUD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUD7"
  },
  {
    "uniprot": "A0A3P7DXH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXH1"
  },
  {
    "uniprot": "A0A3P7ECB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECB7"
  },
  {
    "uniprot": "A0A3P7DVC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVC0"
  },
  {
    "uniprot": "A0A3P7E161",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E161"
  },
  {
    "uniprot": "A0A3P7GJT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJT5"
  },
  {
    "uniprot": "J9EN26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EN26"
  },
  {
    "uniprot": "J9BKR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BKR2"
  },
  {
    "uniprot": "A0A3P7F3J1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3J1"
  },
  {
    "uniprot": "J9FHF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FHF3"
  },
  {
    "uniprot": "A0A3P7FN57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN57"
  },
  {
    "uniprot": "A0A3P7FK38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK38"
  },
  {
    "uniprot": "A0A3P7DAN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAN8"
  },
  {
    "uniprot": "A0A3P7DJR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJR0"
  },
  {
    "uniprot": "A0A3P7E2P6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2P6"
  },
  {
    "uniprot": "A0A3P7E5P6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5P6"
  },
  {
    "uniprot": "A0A183XLA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLA0"
  },
  {
    "uniprot": "A0A3P7EXE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXE5"
  },
  {
    "uniprot": "J9EX42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EX42"
  },
  {
    "uniprot": "A0A3P7FHK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHK6"
  },
  {
    "uniprot": "A0A3P7FQ62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ62"
  },
  {
    "uniprot": "A0A3P7GCV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCV8"
  },
  {
    "uniprot": "A0A3P7FT64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT64"
  },
  {
    "uniprot": "A0A3P7DK92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK92"
  },
  {
    "uniprot": "J9EC21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EC21"
  },
  {
    "uniprot": "A0A3P7FYN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYN8"
  },
  {
    "uniprot": "A0A3P7EAL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAL1"
  },
  {
    "uniprot": "A0A3P7DUA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUA2"
  },
  {
    "uniprot": "J9B7T7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B7T7"
  },
  {
    "uniprot": "A0A3P7FT94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT94"
  },
  {
    "uniprot": "A0A3P7EAM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAM9"
  },
  {
    "uniprot": "A0A3P7DSB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSB4"
  },
  {
    "uniprot": "A0A3P7E7U6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7U6"
  },
  {
    "uniprot": "A0A3P7E9S3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9S3"
  },
  {
    "uniprot": "A0A3P7DWG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWG2"
  },
  {
    "uniprot": "A0A3P7EYL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYL4"
  },
  {
    "uniprot": "A0A3P7E425",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E425"
  },
  {
    "uniprot": "A0A3P7END7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7END7"
  },
  {
    "uniprot": "A0A3P7FIW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIW0"
  },
  {
    "uniprot": "A0A3P7GIU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIU3"
  },
  {
    "uniprot": "A0A3P7DZH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZH5"
  },
  {
    "uniprot": "A0A3P7GAI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAI7"
  },
  {
    "uniprot": "J9E504",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E504"
  },
  {
    "uniprot": "A0A3P7GJW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJW2"
  },
  {
    "uniprot": "A0A3P7FIC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIC8"
  },
  {
    "uniprot": "A0A3P7FF67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF67"
  },
  {
    "uniprot": "A0A3P7GJX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJX0"
  },
  {
    "uniprot": "A0A3P7EYS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYS5"
  },
  {
    "uniprot": "A0A3P7E0D0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0D0"
  },
  {
    "uniprot": "A0A3P7E5A0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5A0"
  },
  {
    "uniprot": "A0A3P7EA47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA47"
  },
  {
    "uniprot": "A0A3P7DHV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHV3"
  },
  {
    "uniprot": "A0A3P7EGC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGC8"
  },
  {
    "uniprot": "A0A3P7GCQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCQ6"
  },
  {
    "uniprot": "A0A3P7ELK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELK9"
  },
  {
    "uniprot": "J9F8M7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F8M7"
  },
  {
    "uniprot": "A0A3P7EJ84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ84"
  },
  {
    "uniprot": "A0A3P7DY25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY25"
  },
  {
    "uniprot": "J9EHW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EHW2"
  },
  {
    "uniprot": "A0A183XL78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XL78"
  },
  {
    "uniprot": "A0A3P7EC04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC04"
  },
  {
    "uniprot": "A0A3P7F2Q0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2Q0"
  },
  {
    "uniprot": "A0A3P7EIV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIV4"
  },
  {
    "uniprot": "A0A3P7E5U9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5U9"
  },
  {
    "uniprot": "A0A3P7E7U7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7U7"
  },
  {
    "uniprot": "J9B9B8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B9B8"
  },
  {
    "uniprot": "A0A3P7GCT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCT0"
  },
  {
    "uniprot": "A0A3P7EE53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE53"
  },
  {
    "uniprot": "A0A3P7F3D4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3D4"
  },
  {
    "uniprot": "A0A3P7E512",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E512"
  },
  {
    "uniprot": "A0A3P7DZM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZM4"
  },
  {
    "uniprot": "A0A3P7EA59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA59"
  },
  {
    "uniprot": "A0A3P7FDN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDN6"
  },
  {
    "uniprot": "A0A3P7DKQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKQ0"
  },
  {
    "uniprot": "A0A3P7EBN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBN5"
  },
  {
    "uniprot": "A0A3P7DW36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW36"
  },
  {
    "uniprot": "A0A3P7DIG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIG8"
  },
  {
    "uniprot": "A0A3P7FZZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZZ8"
  },
  {
    "uniprot": "A0A3P7E6N7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6N7"
  },
  {
    "uniprot": "A0A3P7E5D2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5D2"
  },
  {
    "uniprot": "A0A3P7DSF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSF0"
  },
  {
    "uniprot": "A0A3P7EJD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJD9"
  },
  {
    "uniprot": "A0A3P7EBB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBB0"
  },
  {
    "uniprot": "A0A3P7FAD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAD9"
  },
  {
    "uniprot": "A0A3P7EFH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFH8"
  },
  {
    "uniprot": "A0A3P7FIN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIN6"
  },
  {
    "uniprot": "A0A3P7FS50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS50"
  },
  {
    "uniprot": "A0A3P7GK84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GK84"
  },
  {
    "uniprot": "A0A3P7E8S2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8S2"
  },
  {
    "uniprot": "A0A3P7FMU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMU1"
  },
  {
    "uniprot": "A0A3P7FMF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMF4"
  },
  {
    "uniprot": "A0A3P7DRD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRD9"
  },
  {
    "uniprot": "A0A3P7EE42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE42"
  },
  {
    "uniprot": "A0A3P7E4S1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4S1"
  },
  {
    "uniprot": "J9EB50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EB50"
  },
  {
    "uniprot": "A0A183XR80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XR80"
  },
  {
    "uniprot": "A0A3P7E5F4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5F4"
  },
  {
    "uniprot": "A0A3P7EDM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDM6"
  },
  {
    "uniprot": "A0A3P7EP73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP73"
  },
  {
    "uniprot": "A0A3P7ESA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESA0"
  },
  {
    "uniprot": "A0A3P7EIX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIX9"
  },
  {
    "uniprot": "A0A183XDA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDA2"
  },
  {
    "uniprot": "A0A3P7E8H4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8H4"
  },
  {
    "uniprot": "A0A3P7EB59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB59"
  },
  {
    "uniprot": "A0A3P7DCX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCX7"
  },
  {
    "uniprot": "A0A3P7FIE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIE1"
  },
  {
    "uniprot": "A0A3P7DGK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGK5"
  },
  {
    "uniprot": "A0A3P7DB79",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DB79"
  },
  {
    "uniprot": "A0A3P7E693",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E693"
  },
  {
    "uniprot": "A0A3P7DXK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXK0"
  },
  {
    "uniprot": "A0A3P7DQW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQW9"
  },
  {
    "uniprot": "A0A3P7E935",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E935"
  },
  {
    "uniprot": "A0A3P7DUF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUF3"
  },
  {
    "uniprot": "A0A3P7E2Y4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2Y4"
  },
  {
    "uniprot": "A0A3P7EHI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHI3"
  },
  {
    "uniprot": "J9ESG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ESG7"
  },
  {
    "uniprot": "A0A3P7EAA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAA1"
  },
  {
    "uniprot": "A0A3P7DRR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRR8"
  },
  {
    "uniprot": "A0A3P7E4G6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4G6"
  },
  {
    "uniprot": "A0A3P7DIS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIS3"
  },
  {
    "uniprot": "A0A3P7E2F0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2F0"
  },
  {
    "uniprot": "A0A3P7DQ08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ08"
  },
  {
    "uniprot": "A0A3P7EAX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAX9"
  },
  {
    "uniprot": "A0A3P7DMQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMQ2"
  },
  {
    "uniprot": "A0A3P7E9T2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9T2"
  },
  {
    "uniprot": "A0A3P7E7L0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7L0"
  },
  {
    "uniprot": "A0A3P7DTR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTR1"
  },
  {
    "uniprot": "A0A3P7E282",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E282"
  },
  {
    "uniprot": "A0A3P7FHE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHE3"
  },
  {
    "uniprot": "A0A183XD75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XD75"
  },
  {
    "uniprot": "A0A183XHL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XHL8"
  },
  {
    "uniprot": "A0A3P7DGP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGP9"
  },
  {
    "uniprot": "A0A3P7EA97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA97"
  },
  {
    "uniprot": "A0A3P7E6C9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6C9"
  },
  {
    "uniprot": "A0A3P7EIY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIY4"
  },
  {
    "uniprot": "A0A3P7EDZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDZ3"
  },
  {
    "uniprot": "A0A183XC41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XC41"
  },
  {
    "uniprot": "J9EJD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EJD7"
  },
  {
    "uniprot": "A0A3P7FCT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCT7"
  },
  {
    "uniprot": "A0A3P7E8T3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8T3"
  },
  {
    "uniprot": "J9F376",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F376"
  },
  {
    "uniprot": "A0A3P7E7R7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7R7"
  },
  {
    "uniprot": "A0A3P7DXP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXP5"
  },
  {
    "uniprot": "A0A3P7EUG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUG1"
  },
  {
    "uniprot": "A0A3P7DQP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQP4"
  },
  {
    "uniprot": "A0A3P7G9N8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9N8"
  },
  {
    "uniprot": "A0A3P7EK44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK44"
  },
  {
    "uniprot": "A0A3P7DSV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSV8"
  },
  {
    "uniprot": "A0A3P7G247",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G247"
  },
  {
    "uniprot": "A0A3P7DKS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKS1"
  },
  {
    "uniprot": "A0A3P7DW68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW68"
  },
  {
    "uniprot": "J9FLM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FLM0"
  },
  {
    "uniprot": "A0A3P7FM53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM53"
  },
  {
    "uniprot": "A0A3P7DVE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVE7"
  },
  {
    "uniprot": "A0A3P7DT75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT75"
  },
  {
    "uniprot": "A0A3P7FWD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWD1"
  },
  {
    "uniprot": "A0A183XC38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XC38"
  },
  {
    "uniprot": "A0A3P7DSE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSE9"
  },
  {
    "uniprot": "A0A3P7EIE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIE5"
  },
  {
    "uniprot": "A0A3P7EW41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EW41"
  },
  {
    "uniprot": "A0A3P7F4U9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F4U9"
  },
  {
    "uniprot": "A0A183XTQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XTQ2"
  },
  {
    "uniprot": "A0A3P7E1Z8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1Z8"
  },
  {
    "uniprot": "J9EU40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EU40"
  },
  {
    "uniprot": "A0A3P7DSV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSV3"
  },
  {
    "uniprot": "A0A3P7E557",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E557"
  },
  {
    "uniprot": "J9FKW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FKW3"
  },
  {
    "uniprot": "A0A3P7FMX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMX1"
  },
  {
    "uniprot": "A0A3P7EVA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVA2"
  },
  {
    "uniprot": "A0A3P7EUD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUD5"
  },
  {
    "uniprot": "A0A3P7E4I5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4I5"
  },
  {
    "uniprot": "A0A3P7E0K7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0K7"
  },
  {
    "uniprot": "A0A3P7G2M6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2M6"
  },
  {
    "uniprot": "A0A3P7FLC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLC6"
  },
  {
    "uniprot": "A0A3P7FJ68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ68"
  },
  {
    "uniprot": "A0A3P7GB31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GB31"
  },
  {
    "uniprot": "A0A3P7E3F8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3F8"
  },
  {
    "uniprot": "J9FBY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FBY4"
  },
  {
    "uniprot": "A0A3P7G1P7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1P7"
  },
  {
    "uniprot": "A0A3P7DYY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYY1"
  },
  {
    "uniprot": "A0A3P7ERX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERX9"
  },
  {
    "uniprot": "A0A3P7EB30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB30"
  },
  {
    "uniprot": "A0A3P7DSV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSV9"
  },
  {
    "uniprot": "A0A3P7E4W8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4W8"
  },
  {
    "uniprot": "A0A3P7E3X6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3X6"
  },
  {
    "uniprot": "A0A3P7DDF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDF0"
  },
  {
    "uniprot": "A0A3P7FNV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNV9"
  },
  {
    "uniprot": "A0A3P7FXF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXF5"
  },
  {
    "uniprot": "A0A3P7FMU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMU7"
  },
  {
    "uniprot": "A0A3P7E170",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E170"
  },
  {
    "uniprot": "A0A3P7DJN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJN3"
  },
  {
    "uniprot": "A0A3P7E3I1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3I1"
  },
  {
    "uniprot": "J9BG21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BG21"
  },
  {
    "uniprot": "A0A3P7E1K1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1K1"
  },
  {
    "uniprot": "A0A3P7EE04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE04"
  },
  {
    "uniprot": "A0A3P7E0B7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0B7"
  },
  {
    "uniprot": "A0A3P7G3H5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3H5"
  },
  {
    "uniprot": "A0A3P7DW56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW56"
  },
  {
    "uniprot": "A0A3P7EF63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF63"
  },
  {
    "uniprot": "A0A3P7E9V9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9V9"
  },
  {
    "uniprot": "A0A3P7EE44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE44"
  },
  {
    "uniprot": "A0A3P7DGD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGD3"
  },
  {
    "uniprot": "A0A3P7EGI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGI9"
  },
  {
    "uniprot": "A0A3P7DPE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPE5"
  },
  {
    "uniprot": "A0A3P7DQS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQS6"
  },
  {
    "uniprot": "A0A3P7E5Q2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5Q2"
  },
  {
    "uniprot": "A0A3P7E6H5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6H5"
  },
  {
    "uniprot": "A0A3P7DUD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUD6"
  },
  {
    "uniprot": "A0A3P7E1Q2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1Q2"
  },
  {
    "uniprot": "A0A3P7DMI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMI0"
  },
  {
    "uniprot": "A0A3P7E6R7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6R7"
  },
  {
    "uniprot": "A0A3P7EBT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBT8"
  },
  {
    "uniprot": "A0A3P7EC77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC77"
  },
  {
    "uniprot": "A0A3P7FKW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKW0"
  },
  {
    "uniprot": "A0A3P7DDE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDE1"
  },
  {
    "uniprot": "A0A3P7F925",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F925"
  },
  {
    "uniprot": "A0A3P7DIY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIY7"
  },
  {
    "uniprot": "A0A3P7EK81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK81"
  },
  {
    "uniprot": "A0A3P7E6S3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6S3"
  },
  {
    "uniprot": "A0A3P7F3S5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3S5"
  },
  {
    "uniprot": "J9B2T9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B2T9"
  },
  {
    "uniprot": "A0A3P7EBC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBC0"
  },
  {
    "uniprot": "A0A3P7DX72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX72"
  },
  {
    "uniprot": "A0A3P7FL15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL15"
  },
  {
    "uniprot": "A0A3P7E3H0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3H0"
  },
  {
    "uniprot": "A0A3P7EJQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJQ5"
  },
  {
    "uniprot": "J9DQW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DQW5"
  },
  {
    "uniprot": "J9F037",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F037"
  },
  {
    "uniprot": "J9F1F7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1F7"
  },
  {
    "uniprot": "A0A3P7E798",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E798"
  },
  {
    "uniprot": "A0A3P7GA30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GA30"
  },
  {
    "uniprot": "A0A3P7DPR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPR6"
  },
  {
    "uniprot": "A0A3P7FYB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYB6"
  },
  {
    "uniprot": "A0A3P7FCK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCK9"
  },
  {
    "uniprot": "A0A183Y3D9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y3D9"
  },
  {
    "uniprot": "A0A3P7E6J8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6J8"
  },
  {
    "uniprot": "J9F5W7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F5W7"
  },
  {
    "uniprot": "A0A3P7E1D2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1D2"
  },
  {
    "uniprot": "J9F1M2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1M2"
  },
  {
    "uniprot": "A0A3P7DGL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGL2"
  },
  {
    "uniprot": "A0A3P7DPC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPC2"
  },
  {
    "uniprot": "A0A3P7E9H3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9H3"
  },
  {
    "uniprot": "A0A3P7EMR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMR0"
  },
  {
    "uniprot": "A0A183XUB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XUB6"
  },
  {
    "uniprot": "J9BEF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BEF7"
  },
  {
    "uniprot": "A0A3P7FCA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCA4"
  },
  {
    "uniprot": "A0A3P7EUK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUK6"
  },
  {
    "uniprot": "J9FEU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FEU4"
  },
  {
    "uniprot": "A0A3P7FRV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRV4"
  },
  {
    "uniprot": "A0A3P7DC14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC14"
  },
  {
    "uniprot": "A0A3P7EAW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAW0"
  },
  {
    "uniprot": "A0A3P7DKI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKI7"
  },
  {
    "uniprot": "A0A183XT04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XT04"
  },
  {
    "uniprot": "A0A3P7DX76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX76"
  },
  {
    "uniprot": "J9BB05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BB05"
  },
  {
    "uniprot": "A0A3P7DV24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV24"
  },
  {
    "uniprot": "A0A3P7E5T7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5T7"
  },
  {
    "uniprot": "A0A3P7EQY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQY0"
  },
  {
    "uniprot": "A0A3P7E502",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E502"
  },
  {
    "uniprot": "A0A3P7DYG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYG2"
  },
  {
    "uniprot": "A0A3P7EKW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKW8"
  },
  {
    "uniprot": "A0A3P7DVT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVT1"
  },
  {
    "uniprot": "A0A3P7FXU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXU3"
  },
  {
    "uniprot": "A0A3P7DYP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYP2"
  },
  {
    "uniprot": "A0A3P7E228",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E228"
  },
  {
    "uniprot": "A0A3P7FYW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYW1"
  },
  {
    "uniprot": "A0A3P7FK98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK98"
  },
  {
    "uniprot": "A0A3P7FD01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD01"
  },
  {
    "uniprot": "A0A3P7DHV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHV9"
  },
  {
    "uniprot": "J9F221",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F221"
  },
  {
    "uniprot": "A0A3P7G488",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G488"
  },
  {
    "uniprot": "A0A3P7DYM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYM2"
  },
  {
    "uniprot": "J9FMY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FMY3"
  },
  {
    "uniprot": "A0A3P7DSA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSA6"
  },
  {
    "uniprot": "A0A3P7EFY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFY2"
  },
  {
    "uniprot": "A0A3P7EH81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH81"
  },
  {
    "uniprot": "A0A3P7F9N0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9N0"
  },
  {
    "uniprot": "A0A183XDM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDM6"
  },
  {
    "uniprot": "A0A3P7FK43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK43"
  },
  {
    "uniprot": "A0A3P7FQW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQW4"
  },
  {
    "uniprot": "A0A3P7GGA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGA5"
  },
  {
    "uniprot": "J9BB80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BB80"
  },
  {
    "uniprot": "A0A3P7FGG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGG8"
  },
  {
    "uniprot": "A0A3P7FVM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVM3"
  },
  {
    "uniprot": "A0A3P7EBY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBY6"
  },
  {
    "uniprot": "A0A3P7DTT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTT4"
  },
  {
    "uniprot": "A0A3P7FHI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHI3"
  },
  {
    "uniprot": "A0A3P7DST6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DST6"
  },
  {
    "uniprot": "A0A3P7EGB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGB8"
  },
  {
    "uniprot": "A0A3P7DX71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX71"
  },
  {
    "uniprot": "A0A3P7ECW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECW1"
  },
  {
    "uniprot": "A0A3P7E418",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E418"
  },
  {
    "uniprot": "A0A3P7EGY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGY6"
  },
  {
    "uniprot": "J9F7D4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F7D4"
  },
  {
    "uniprot": "J9EX04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EX04"
  },
  {
    "uniprot": "A0A3P7DDQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDQ5"
  },
  {
    "uniprot": "A0A3P7FZ25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ25"
  },
  {
    "uniprot": "A0A3P7ECH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECH1"
  },
  {
    "uniprot": "A0A3P7EDP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDP3"
  },
  {
    "uniprot": "A0A3P7ETY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETY7"
  },
  {
    "uniprot": "A0A3P7DRL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRL3"
  },
  {
    "uniprot": "A0A3P7FGV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGV7"
  },
  {
    "uniprot": "A0A3P7FXX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXX3"
  },
  {
    "uniprot": "A0A3P7FBF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBF2"
  },
  {
    "uniprot": "A0A3P7FPW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPW4"
  },
  {
    "uniprot": "A0A3P7DCD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCD0"
  },
  {
    "uniprot": "A0A3P7F4D6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F4D6"
  },
  {
    "uniprot": "A0A3P7DHH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHH9"
  },
  {
    "uniprot": "A0A3P7E6P6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6P6"
  },
  {
    "uniprot": "A0A3P7EFA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFA9"
  },
  {
    "uniprot": "A0A3P7DXZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXZ7"
  },
  {
    "uniprot": "A0A3P7E4Y5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4Y5"
  },
  {
    "uniprot": "A0A3P7EJ09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ09"
  },
  {
    "uniprot": "A0A3P7FGN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGN9"
  },
  {
    "uniprot": "A0A3P7FRL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRL1"
  },
  {
    "uniprot": "A0A3P7DIT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIT5"
  },
  {
    "uniprot": "A0A3P7DFC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFC2"
  },
  {
    "uniprot": "A0A3P7DJ82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ82"
  },
  {
    "uniprot": "A0A3P7E5V2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5V2"
  },
  {
    "uniprot": "A0A3P7ECN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECN5"
  },
  {
    "uniprot": "A0A3P7EB36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB36"
  },
  {
    "uniprot": "A0A3P7DEV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEV2"
  },
  {
    "uniprot": "A0A3P7E060",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E060"
  },
  {
    "uniprot": "J9E5N4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E5N4"
  },
  {
    "uniprot": "A0A3P7FG97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG97"
  },
  {
    "uniprot": "A0A3P7EEA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEA7"
  },
  {
    "uniprot": "A0A3P7E1H0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1H0"
  },
  {
    "uniprot": "A0A3P7E513",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E513"
  },
  {
    "uniprot": "A0A3P7DKM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKM2"
  },
  {
    "uniprot": "A0A3P7FNN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNN4"
  },
  {
    "uniprot": "A0A3P7DJE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJE8"
  },
  {
    "uniprot": "A0A3P7G0L5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0L5"
  },
  {
    "uniprot": "A0A3P7E100",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E100"
  },
  {
    "uniprot": "J9FGB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FGB1"
  },
  {
    "uniprot": "A0A3P7F2D8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2D8"
  },
  {
    "uniprot": "A0A3P7FW35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW35"
  },
  {
    "uniprot": "A0A3P7ERX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERX2"
  },
  {
    "uniprot": "J9ETI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ETI2"
  },
  {
    "uniprot": "A0A3P7FWR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWR3"
  },
  {
    "uniprot": "A0A3P7EEH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEH1"
  },
  {
    "uniprot": "A0A3P7FGJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGJ2"
  },
  {
    "uniprot": "A0A3P7FSX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSX0"
  },
  {
    "uniprot": "A0A3P7E9E8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9E8"
  },
  {
    "uniprot": "A0A3P7EZI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZI8"
  },
  {
    "uniprot": "A0A3P7EF00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF00"
  },
  {
    "uniprot": "A0A3P7DRP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRP3"
  },
  {
    "uniprot": "J9EXF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXF5"
  },
  {
    "uniprot": "A0A3P7ET80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET80"
  },
  {
    "uniprot": "O61723",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/O61723"
  },
  {
    "uniprot": "J9F2G3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2G3"
  },
  {
    "uniprot": "A0A3P7G012",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G012"
  },
  {
    "uniprot": "A0A3P7E6C4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6C4"
  },
  {
    "uniprot": "J9F2H3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2H3"
  },
  {
    "uniprot": "A0A3P7FGN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGN0"
  },
  {
    "uniprot": "J9DU35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DU35"
  },
  {
    "uniprot": "A0A183XR34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XR34"
  },
  {
    "uniprot": "A0A3P7FJS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJS3"
  },
  {
    "uniprot": "A0A183YBI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183YBI4"
  },
  {
    "uniprot": "A0A3P7DUE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUE6"
  },
  {
    "uniprot": "A0A3P7DDN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDN3"
  },
  {
    "uniprot": "A0A3P7FXZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXZ6"
  },
  {
    "uniprot": "A0A3P7E033",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E033"
  },
  {
    "uniprot": "A0A3P7EJY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJY7"
  },
  {
    "uniprot": "A0A3P7ED98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED98"
  },
  {
    "uniprot": "J9AYU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AYU0"
  },
  {
    "uniprot": "A0A3P7FMV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMV7"
  },
  {
    "uniprot": "A0A3P7E1A7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1A7"
  },
  {
    "uniprot": "A0A3P7EKS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKS1"
  },
  {
    "uniprot": "A0A3P7DEG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEG0"
  },
  {
    "uniprot": "A0A3P7EFM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFM8"
  },
  {
    "uniprot": "A0A3P7EUD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUD1"
  },
  {
    "uniprot": "A0A3P7EN17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN17"
  },
  {
    "uniprot": "A0A3P7F503",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F503"
  },
  {
    "uniprot": "A0A3P7FHL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHL4"
  },
  {
    "uniprot": "A0A3P7DK25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK25"
  },
  {
    "uniprot": "A0A3P7EA82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA82"
  },
  {
    "uniprot": "A0A183XNK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XNK4"
  },
  {
    "uniprot": "A0A3P7E8T1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8T1"
  },
  {
    "uniprot": "A0A3P7E4D0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4D0"
  },
  {
    "uniprot": "A0A3P7EE90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE90"
  },
  {
    "uniprot": "A0A3P7G595",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G595"
  },
  {
    "uniprot": "A0A3P7E8N2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8N2"
  },
  {
    "uniprot": "A0A3P7G8A6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8A6"
  },
  {
    "uniprot": "J9EQK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQK5"
  },
  {
    "uniprot": "A0A3P7DIA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIA5"
  },
  {
    "uniprot": "A0A3P7F370",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F370"
  },
  {
    "uniprot": "A0A3P7FXD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXD1"
  },
  {
    "uniprot": "A0A3P7DWZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWZ6"
  },
  {
    "uniprot": "A0A3P7FZ90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ90"
  },
  {
    "uniprot": "A0A3P7DPJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPJ1"
  },
  {
    "uniprot": "A0A3P7DSI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSI3"
  },
  {
    "uniprot": "A0A3P7DEL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEL1"
  },
  {
    "uniprot": "A0A3P7FGN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGN7"
  },
  {
    "uniprot": "A0A3P7GHZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHZ1"
  },
  {
    "uniprot": "A0A3P7EFA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFA2"
  },
  {
    "uniprot": "A0A3P7EY61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY61"
  },
  {
    "uniprot": "A0A3P7EG09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG09"
  },
  {
    "uniprot": "A0A3P7DFT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFT9"
  },
  {
    "uniprot": "A0A3P7DTU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTU1"
  },
  {
    "uniprot": "A0A3P7E3E9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3E9"
  },
  {
    "uniprot": "A0A3P7DL02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL02"
  },
  {
    "uniprot": "A0A3P7EGY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGY7"
  },
  {
    "uniprot": "A0A3P7DPG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPG5"
  },
  {
    "uniprot": "A0A3P7FXM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXM6"
  },
  {
    "uniprot": "A0A3P7EBS7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBS7"
  },
  {
    "uniprot": "A0A3P7E0C4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0C4"
  },
  {
    "uniprot": "A0A3P7DKP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKP0"
  },
  {
    "uniprot": "A0A183XCY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCY3"
  },
  {
    "uniprot": "A0A3P7E7M2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7M2"
  },
  {
    "uniprot": "A0A3P7EA33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA33"
  },
  {
    "uniprot": "J9EL78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EL78"
  },
  {
    "uniprot": "J9EBF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EBF0"
  },
  {
    "uniprot": "A0A3P7E4P9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4P9"
  },
  {
    "uniprot": "J9FNW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FNW3"
  },
  {
    "uniprot": "A0A3P7DN68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN68"
  },
  {
    "uniprot": "A0A3P7FPZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPZ3"
  },
  {
    "uniprot": "J9EZQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZQ8"
  },
  {
    "uniprot": "A0A3P7FQW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQW1"
  },
  {
    "uniprot": "A0A3P7GH64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GH64"
  },
  {
    "uniprot": "J9BD64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BD64"
  },
  {
    "uniprot": "A0A3P7FKV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKV0"
  },
  {
    "uniprot": "A0A3P7DTN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTN7"
  },
  {
    "uniprot": "J9EY02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EY02"
  },
  {
    "uniprot": "A0A3P7DR23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR23"
  },
  {
    "uniprot": "A0A3P7DZF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZF6"
  },
  {
    "uniprot": "A0A3P7DP86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP86"
  },
  {
    "uniprot": "A0A3P7DWI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWI6"
  },
  {
    "uniprot": "A0A3P7FN67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN67"
  },
  {
    "uniprot": "A0A3P7FSF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSF6"
  },
  {
    "uniprot": "A0A3P7EYA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYA9"
  },
  {
    "uniprot": "A0A3P7DZB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZB2"
  },
  {
    "uniprot": "A0A3P7FPF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPF5"
  },
  {
    "uniprot": "A0A3P7DZ94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ94"
  },
  {
    "uniprot": "A0A3P7E9M7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9M7"
  },
  {
    "uniprot": "A0A3P7G564",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G564"
  },
  {
    "uniprot": "A0A3P7FL67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL67"
  },
  {
    "uniprot": "A0A3P7DXS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXS3"
  },
  {
    "uniprot": "A0A3P7E440",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E440"
  },
  {
    "uniprot": "A0A3P7G8V4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8V4"
  },
  {
    "uniprot": "A0A183XP93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XP93"
  },
  {
    "uniprot": "A0A3P7DTY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTY5"
  },
  {
    "uniprot": "A0A3P7E236",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E236"
  },
  {
    "uniprot": "A0A3P7FTK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTK8"
  },
  {
    "uniprot": "A0A3P7DRL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRL2"
  },
  {
    "uniprot": "A0A3P7FL74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL74"
  },
  {
    "uniprot": "J9E6Y9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E6Y9"
  },
  {
    "uniprot": "A0A3P7EEX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEX3"
  },
  {
    "uniprot": "A0A3P7G812",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G812"
  },
  {
    "uniprot": "A0A3P7DSQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSQ2"
  },
  {
    "uniprot": "A0A3P7E3T7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3T7"
  },
  {
    "uniprot": "A0A3P7DQM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQM1"
  },
  {
    "uniprot": "A0A3P7ENF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENF4"
  },
  {
    "uniprot": "A0A3P7EY48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY48"
  },
  {
    "uniprot": "A0A3P7E7X1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7X1"
  },
  {
    "uniprot": "A0A3P7GF59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GF59"
  },
  {
    "uniprot": "A0A3P7DRL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRL5"
  },
  {
    "uniprot": "A0A3P7DVI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVI8"
  },
  {
    "uniprot": "J9F5U0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F5U0"
  },
  {
    "uniprot": "A0A3P7EDH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDH3"
  },
  {
    "uniprot": "A0A3P7FHM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHM6"
  },
  {
    "uniprot": "A0A3P7EW95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EW95"
  },
  {
    "uniprot": "A0A3P7DV91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV91"
  },
  {
    "uniprot": "A0A3P7ELQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELQ0"
  },
  {
    "uniprot": "A0A3P7EF31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF31"
  },
  {
    "uniprot": "A0A3P7DX83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX83"
  },
  {
    "uniprot": "A0A3P7DCD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCD4"
  },
  {
    "uniprot": "A0A183XXA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XXA2"
  },
  {
    "uniprot": "A0A3P7EBV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBV9"
  },
  {
    "uniprot": "A0A3P7EIQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIQ5"
  },
  {
    "uniprot": "A0A3P7FBG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBG6"
  },
  {
    "uniprot": "A0A3P7FZX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZX1"
  },
  {
    "uniprot": "A0A3P7FID0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FID0"
  },
  {
    "uniprot": "A0A3P7FG21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG21"
  },
  {
    "uniprot": "A0A3P7DX84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX84"
  },
  {
    "uniprot": "A0A3P7DQZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQZ6"
  },
  {
    "uniprot": "J9F8H6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F8H6"
  },
  {
    "uniprot": "J9B9Z1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B9Z1"
  },
  {
    "uniprot": "J9E3E0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E3E0"
  },
  {
    "uniprot": "A0A3P7DSD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSD1"
  },
  {
    "uniprot": "A0A3P7ETR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETR2"
  },
  {
    "uniprot": "A0A3P7DB90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DB90"
  },
  {
    "uniprot": "A0A3P7DW65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW65"
  },
  {
    "uniprot": "A0A3P7G0A3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0A3"
  },
  {
    "uniprot": "A0A3P7EDU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDU2"
  },
  {
    "uniprot": "A0A3P7E0L7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0L7"
  },
  {
    "uniprot": "A0A3P7DU24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU24"
  },
  {
    "uniprot": "A0A3P7DVI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVI6"
  },
  {
    "uniprot": "A0A3P7DCR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCR5"
  },
  {
    "uniprot": "A0A3P7G245",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G245"
  },
  {
    "uniprot": "A0A3P7E710",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E710"
  },
  {
    "uniprot": "A0A3P7EBD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBD1"
  },
  {
    "uniprot": "A0A3P7DXZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXZ4"
  },
  {
    "uniprot": "A0A3P7GCF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCF1"
  },
  {
    "uniprot": "A0A183XFU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XFU9"
  },
  {
    "uniprot": "A0A3P7DLV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLV8"
  },
  {
    "uniprot": "A0A3P7EIF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIF6"
  },
  {
    "uniprot": "A0A3P7EFS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFS8"
  },
  {
    "uniprot": "A0A3P7FIQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIQ7"
  },
  {
    "uniprot": "A0A3P7EGA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGA7"
  },
  {
    "uniprot": "A0A3P7E2S7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2S7"
  },
  {
    "uniprot": "A0A3P7EUT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUT7"
  },
  {
    "uniprot": "A0A3P7GLZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLZ9"
  },
  {
    "uniprot": "A0A3P7E877",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E877"
  },
  {
    "uniprot": "A0A3P7GHE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHE1"
  },
  {
    "uniprot": "A0A3P7E5X1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5X1"
  },
  {
    "uniprot": "A0A3P7GKP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKP9"
  },
  {
    "uniprot": "A0A3P7DC09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC09"
  },
  {
    "uniprot": "A0A3P7EHT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHT1"
  },
  {
    "uniprot": "A0A3P7E313",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E313"
  },
  {
    "uniprot": "A0A3P7FDA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDA9"
  },
  {
    "uniprot": "A0A3P7DND8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DND8"
  },
  {
    "uniprot": "A0A3P7DEG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEG5"
  },
  {
    "uniprot": "A0A3P7GBD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBD5"
  },
  {
    "uniprot": "A0A3P7FFS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFS1"
  },
  {
    "uniprot": "A0A3P7EEW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEW4"
  },
  {
    "uniprot": "J9EC45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EC45"
  },
  {
    "uniprot": "J9BA10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BA10"
  },
  {
    "uniprot": "A0A3P7FN82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN82"
  },
  {
    "uniprot": "A0A3P7FJ22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ22"
  },
  {
    "uniprot": "A0A3P7E9M4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9M4"
  },
  {
    "uniprot": "A0A3P7EY19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY19"
  },
  {
    "uniprot": "A0A3P7FPV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPV9"
  },
  {
    "uniprot": "A0A3P7DP68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP68"
  },
  {
    "uniprot": "A0A3P7DLR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLR9"
  },
  {
    "uniprot": "A0A3P7DPN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPN7"
  },
  {
    "uniprot": "A0A3P7DXR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXR1"
  },
  {
    "uniprot": "A0A3P7E9C0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9C0"
  },
  {
    "uniprot": "A0A3P7EPL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPL9"
  },
  {
    "uniprot": "A0A3P7FH70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH70"
  },
  {
    "uniprot": "A0A3P7FA85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FA85"
  },
  {
    "uniprot": "A0A3P7EEN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEN7"
  },
  {
    "uniprot": "A0A3P7DSX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSX9"
  },
  {
    "uniprot": "A0A3P7FRJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRJ8"
  },
  {
    "uniprot": "A0A3P7FQE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQE1"
  },
  {
    "uniprot": "A0A3P7E3F9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3F9"
  },
  {
    "uniprot": "A0A3P7E5U4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5U4"
  },
  {
    "uniprot": "A0A3P7GGB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGB4"
  },
  {
    "uniprot": "A0A3P7EHW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHW3"
  },
  {
    "uniprot": "A0A3P7E5F9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5F9"
  },
  {
    "uniprot": "A0A3P7F7P6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7P6"
  },
  {
    "uniprot": "A0A3P7EFQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFQ4"
  },
  {
    "uniprot": "A0A3P7FUK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUK1"
  },
  {
    "uniprot": "A0A3P7E7H1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7H1"
  },
  {
    "uniprot": "A0A3P7GJ43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJ43"
  },
  {
    "uniprot": "A0A3P7FAT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAT7"
  },
  {
    "uniprot": "A0A3P7E015",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E015"
  },
  {
    "uniprot": "A0A3P7FIK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIK1"
  },
  {
    "uniprot": "A0A3P7F3F6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3F6"
  },
  {
    "uniprot": "A0A3P7DD14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD14"
  },
  {
    "uniprot": "A0A3P7ECC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECC2"
  },
  {
    "uniprot": "A0A3P7FKE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKE1"
  },
  {
    "uniprot": "A0A3P7EAG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAG1"
  },
  {
    "uniprot": "A0A3P7EA32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA32"
  },
  {
    "uniprot": "A0A3P7EA58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA58"
  },
  {
    "uniprot": "A0A3P7F7Z1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7Z1"
  },
  {
    "uniprot": "A0A3P7FIM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIM1"
  },
  {
    "uniprot": "A0A183XCJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCJ7"
  },
  {
    "uniprot": "A0A3P7DL50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL50"
  },
  {
    "uniprot": "A0A3P7FMW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMW1"
  },
  {
    "uniprot": "A0A3P7E2L3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2L3"
  },
  {
    "uniprot": "A0A3P7DU49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU49"
  },
  {
    "uniprot": "A0A183XI95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XI95"
  },
  {
    "uniprot": "A0A3P7E993",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E993"
  },
  {
    "uniprot": "A0A3P7EGA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGA3"
  },
  {
    "uniprot": "A0A3P7DFY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFY9"
  },
  {
    "uniprot": "A0A3P7FBL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBL3"
  },
  {
    "uniprot": "A0A3P7FEZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEZ1"
  },
  {
    "uniprot": "A0A3P7DV00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV00"
  },
  {
    "uniprot": "A0A3P7EH07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH07"
  },
  {
    "uniprot": "A0A3P7DD08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD08"
  },
  {
    "uniprot": "A0A3P7FSW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSW0"
  },
  {
    "uniprot": "A0A183XZW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XZW4"
  },
  {
    "uniprot": "A0A3P7FMX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMX8"
  },
  {
    "uniprot": "A0A3P7E6R4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6R4"
  },
  {
    "uniprot": "A0A3P7DMF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMF6"
  },
  {
    "uniprot": "A0A3P7FIE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIE9"
  },
  {
    "uniprot": "A0A3P7GJ26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJ26"
  },
  {
    "uniprot": "A0A3P7DQG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQG8"
  },
  {
    "uniprot": "A0A3P7ENK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENK8"
  },
  {
    "uniprot": "A0A3P7DCG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCG7"
  },
  {
    "uniprot": "A0A183XGU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XGU2"
  },
  {
    "uniprot": "A0A3P7DCT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCT5"
  },
  {
    "uniprot": "A0A3P7E2C2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2C2"
  },
  {
    "uniprot": "A0A3P7DHN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHN9"
  },
  {
    "uniprot": "A0A3P7DPK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPK7"
  },
  {
    "uniprot": "A0A3P7E4A5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4A5"
  },
  {
    "uniprot": "A0A3P7E941",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E941"
  },
  {
    "uniprot": "A0A3P7G328",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G328"
  },
  {
    "uniprot": "A0A3P7DRN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRN9"
  },
  {
    "uniprot": "A0A3P7DNZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNZ8"
  },
  {
    "uniprot": "A0A3P7DM12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM12"
  },
  {
    "uniprot": "A0A3P7EGU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGU1"
  },
  {
    "uniprot": "A0A3P7DMZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMZ6"
  },
  {
    "uniprot": "A0A3P7DGE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGE9"
  },
  {
    "uniprot": "A0A3P7DUJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUJ3"
  },
  {
    "uniprot": "A0A3P7FVP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVP0"
  },
  {
    "uniprot": "A0A3P7G2B9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2B9"
  },
  {
    "uniprot": "A0A3P7EJ29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ29"
  },
  {
    "uniprot": "A0A3P7GGN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGN1"
  },
  {
    "uniprot": "A0A3P7DYQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYQ2"
  },
  {
    "uniprot": "A0A3P7FLX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLX1"
  },
  {
    "uniprot": "A0A3P7EG53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG53"
  },
  {
    "uniprot": "A0A3P7E677",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E677"
  },
  {
    "uniprot": "A0A3P7FAF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAF4"
  },
  {
    "uniprot": "A0A3P7DL22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL22"
  },
  {
    "uniprot": "A0A3P7EM24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM24"
  },
  {
    "uniprot": "J9EGL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EGL5"
  },
  {
    "uniprot": "A0A3P7DZJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZJ0"
  },
  {
    "uniprot": "A0A3P7G038",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G038"
  },
  {
    "uniprot": "A0A3P7G6N2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6N2"
  },
  {
    "uniprot": "A0A3P7DJF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJF5"
  },
  {
    "uniprot": "A0A3P7DTU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTU8"
  },
  {
    "uniprot": "A0A3P7DVE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVE1"
  },
  {
    "uniprot": "J9EUI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUI2"
  },
  {
    "uniprot": "A0A3P7DNA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNA2"
  },
  {
    "uniprot": "A0A3P7FHV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHV8"
  },
  {
    "uniprot": "A0A3P7EHP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHP6"
  },
  {
    "uniprot": "A0A3P7F9A9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9A9"
  },
  {
    "uniprot": "A0A3P7DZH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZH0"
  },
  {
    "uniprot": "A0A3P7EEJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEJ1"
  },
  {
    "uniprot": "A0A3P7FKU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKU9"
  },
  {
    "uniprot": "A0A3P7DVN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVN9"
  },
  {
    "uniprot": "A0A3P7GI08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GI08"
  },
  {
    "uniprot": "A0A3P7DXC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXC7"
  },
  {
    "uniprot": "A0A3P7EB82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB82"
  },
  {
    "uniprot": "A0A3P7DUE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUE4"
  },
  {
    "uniprot": "A0A3P7DNV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNV6"
  },
  {
    "uniprot": "A0A3P7GGA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGA2"
  },
  {
    "uniprot": "A0A3P7FUI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUI6"
  },
  {
    "uniprot": "A0A3P7EHJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHJ5"
  },
  {
    "uniprot": "A0A3P7EDG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDG8"
  },
  {
    "uniprot": "A0A3P7DUU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUU0"
  },
  {
    "uniprot": "A0A3P7FIQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIQ1"
  },
  {
    "uniprot": "A0A3P7FJT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJT0"
  },
  {
    "uniprot": "A0A3P7DST7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DST7"
  },
  {
    "uniprot": "A0A3P7DRG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRG9"
  },
  {
    "uniprot": "A0A3P7E045",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E045"
  },
  {
    "uniprot": "A0A3P7E3D0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3D0"
  },
  {
    "uniprot": "A0A3P7EA17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA17"
  },
  {
    "uniprot": "A0A3P7DTQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTQ9"
  },
  {
    "uniprot": "J9EN37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EN37"
  },
  {
    "uniprot": "A0A3P7DC21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC21"
  },
  {
    "uniprot": "A0A3P7E0L2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0L2"
  },
  {
    "uniprot": "A0A3P7DLR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLR8"
  },
  {
    "uniprot": "A0A183XRF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XRF8"
  },
  {
    "uniprot": "A0A3P7DP03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP03"
  },
  {
    "uniprot": "A0A3P7DXY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXY9"
  },
  {
    "uniprot": "A0A3P7DUD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUD4"
  },
  {
    "uniprot": "A0A3P7F9X8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9X8"
  },
  {
    "uniprot": "A0A3P7E350",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E350"
  },
  {
    "uniprot": "J9EYN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYN9"
  },
  {
    "uniprot": "J9FL78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FL78"
  },
  {
    "uniprot": "A0A3P7FVI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVI3"
  },
  {
    "uniprot": "A0A3P7F7X0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7X0"
  },
  {
    "uniprot": "A0A3P7EK99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK99"
  },
  {
    "uniprot": "A0A3P7EE51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE51"
  },
  {
    "uniprot": "J9FG99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FG99"
  },
  {
    "uniprot": "A0A3P7DVC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVC4"
  },
  {
    "uniprot": "A0A183XBN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XBN0"
  },
  {
    "uniprot": "A0A3P7DWS4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWS4"
  },
  {
    "uniprot": "A0A3P7DZ13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ13"
  },
  {
    "uniprot": "A0A3P7DTY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTY8"
  },
  {
    "uniprot": "A0A3P7GC03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GC03"
  },
  {
    "uniprot": "A0A3P7FUS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUS0"
  },
  {
    "uniprot": "A0A3P7DQX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQX6"
  },
  {
    "uniprot": "A0A3P7FRE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRE9"
  },
  {
    "uniprot": "A0A3P7EW01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EW01"
  },
  {
    "uniprot": "A0A3P7EJH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJH5"
  },
  {
    "uniprot": "A0A3P7DYU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYU9"
  },
  {
    "uniprot": "A0A3P7DE24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE24"
  },
  {
    "uniprot": "J9E9C2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E9C2"
  },
  {
    "uniprot": "A0A3P7DID2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DID2"
  },
  {
    "uniprot": "A0A3P7DZP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZP9"
  },
  {
    "uniprot": "A0A3P7DYX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYX1"
  },
  {
    "uniprot": "A0A3P7G382",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G382"
  },
  {
    "uniprot": "A0A3P7DP27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP27"
  },
  {
    "uniprot": "A0A3P7G218",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G218"
  },
  {
    "uniprot": "J9BHK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BHK1"
  },
  {
    "uniprot": "A0A3P7E7X2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7X2"
  },
  {
    "uniprot": "A0A3P7EAU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAU2"
  },
  {
    "uniprot": "A0A3P7E7D8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7D8"
  },
  {
    "uniprot": "A0A3P7E379",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E379"
  },
  {
    "uniprot": "A0A3P7E7Z1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7Z1"
  },
  {
    "uniprot": "A0A3P7ELF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELF5"
  },
  {
    "uniprot": "A0A3P7FS75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS75"
  },
  {
    "uniprot": "A0A3P7F377",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F377"
  },
  {
    "uniprot": "A0A3P7DCE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCE2"
  },
  {
    "uniprot": "A0A3P7DU47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU47"
  },
  {
    "uniprot": "A0A3P7DZR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZR7"
  },
  {
    "uniprot": "J9F540",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F540"
  },
  {
    "uniprot": "A0A3P7EJQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJQ2"
  },
  {
    "uniprot": "A0A3P7DNM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNM0"
  },
  {
    "uniprot": "J9FEX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FEX5"
  },
  {
    "uniprot": "A0A3P7GFG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFG5"
  },
  {
    "uniprot": "A0A3P7DXM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXM7"
  },
  {
    "uniprot": "A0A3P7EA01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA01"
  },
  {
    "uniprot": "J9EUJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUJ2"
  },
  {
    "uniprot": "A0A3P7ENX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENX7"
  },
  {
    "uniprot": "J9F9C7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F9C7"
  },
  {
    "uniprot": "A0A3P7FTL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTL3"
  },
  {
    "uniprot": "A0A3P7F3L9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3L9"
  },
  {
    "uniprot": "A0A3P7DLH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLH9"
  },
  {
    "uniprot": "A0A3P7DN61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN61"
  },
  {
    "uniprot": "A0A3P7FCR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCR0"
  },
  {
    "uniprot": "A0A3P7ERS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERS6"
  },
  {
    "uniprot": "A0A3P7EGZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGZ4"
  },
  {
    "uniprot": "A0A3P7DW59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW59"
  },
  {
    "uniprot": "A0A3P7EWK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWK6"
  },
  {
    "uniprot": "A0A3P7DKB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKB0"
  },
  {
    "uniprot": "A0A3P7E4E3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4E3"
  },
  {
    "uniprot": "A0A3P7FP15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP15"
  },
  {
    "uniprot": "A0A3P7GE58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GE58"
  },
  {
    "uniprot": "A0A3P7E0N3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0N3"
  },
  {
    "uniprot": "A0A3P7DLG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLG6"
  },
  {
    "uniprot": "A0A3P7DRL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRL4"
  },
  {
    "uniprot": "A0A3P7FPG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPG5"
  },
  {
    "uniprot": "A0A3P7E2V9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2V9"
  },
  {
    "uniprot": "A0A3P7DH08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH08"
  },
  {
    "uniprot": "A0A3P7ECW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECW6"
  },
  {
    "uniprot": "A0A3P7DUY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUY8"
  },
  {
    "uniprot": "A0A3P7F8H8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8H8"
  },
  {
    "uniprot": "A0A3P7FEH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEH5"
  },
  {
    "uniprot": "J9F908",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F908"
  },
  {
    "uniprot": "A0A3P7DIX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIX3"
  },
  {
    "uniprot": "A0A3P7EAJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAJ3"
  },
  {
    "uniprot": "A0A3P7E1I0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1I0"
  },
  {
    "uniprot": "A0A3P7FMY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMY0"
  },
  {
    "uniprot": "A0A3P7FPD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPD8"
  },
  {
    "uniprot": "J9B1N3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B1N3"
  },
  {
    "uniprot": "A0A3P7FIU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIU0"
  },
  {
    "uniprot": "J9F7U1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F7U1"
  },
  {
    "uniprot": "A0A3P7FBH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBH0"
  },
  {
    "uniprot": "A0A183XJR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XJR9"
  },
  {
    "uniprot": "J9FN39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FN39"
  },
  {
    "uniprot": "A0A3P7E4F8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4F8"
  },
  {
    "uniprot": "A0A3P7F3C0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3C0"
  },
  {
    "uniprot": "A0A3P7DLE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLE4"
  },
  {
    "uniprot": "A0A3P7DP57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP57"
  },
  {
    "uniprot": "A0A3P7FLN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLN8"
  },
  {
    "uniprot": "A0A3P7DNK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNK9"
  },
  {
    "uniprot": "A0A3P7DF44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF44"
  },
  {
    "uniprot": "A0A3P7E298",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E298"
  },
  {
    "uniprot": "A0A3P7DT03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT03"
  },
  {
    "uniprot": "A0A3P7DVJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVJ1"
  },
  {
    "uniprot": "A0A3P7FN90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN90"
  },
  {
    "uniprot": "A0A3P7FJW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJW2"
  },
  {
    "uniprot": "A0A3P7FP87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP87"
  },
  {
    "uniprot": "A0A3P7EG54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG54"
  },
  {
    "uniprot": "A0A3P7FNR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNR2"
  },
  {
    "uniprot": "A0A3P7GJJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJJ2"
  },
  {
    "uniprot": "A0A3P7GKW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKW9"
  },
  {
    "uniprot": "A0A3P7FWN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWN5"
  },
  {
    "uniprot": "A0A3P7EFP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFP5"
  },
  {
    "uniprot": "A0A3P7ER27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ER27"
  },
  {
    "uniprot": "A0A183XF38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XF38"
  },
  {
    "uniprot": "A0A3P7FYH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYH9"
  },
  {
    "uniprot": "A0A3P7DHI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHI4"
  },
  {
    "uniprot": "A0A3P7ECR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECR5"
  },
  {
    "uniprot": "A0A3P7FU28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU28"
  },
  {
    "uniprot": "A0A3P7FRR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRR5"
  },
  {
    "uniprot": "A0A3P7FEQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEQ7"
  },
  {
    "uniprot": "A0A3P7DHY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHY3"
  },
  {
    "uniprot": "J9EW00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EW00"
  },
  {
    "uniprot": "A0A3P7DMX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMX4"
  },
  {
    "uniprot": "A0A183Y351",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y351"
  },
  {
    "uniprot": "A0A3P7FRD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRD5"
  },
  {
    "uniprot": "A0A3P7E4V2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4V2"
  },
  {
    "uniprot": "A0A3P7DE29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE29"
  },
  {
    "uniprot": "A0A3P7DT39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT39"
  },
  {
    "uniprot": "A0A3P7FSK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSK0"
  },
  {
    "uniprot": "A0A3P7DVV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVV4"
  },
  {
    "uniprot": "J9ETE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ETE6"
  },
  {
    "uniprot": "A0A3P7FHB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHB6"
  },
  {
    "uniprot": "A0A3P7GGM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGM3"
  },
  {
    "uniprot": "J9EDX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EDX2"
  },
  {
    "uniprot": "A0A3P7GCW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCW5"
  },
  {
    "uniprot": "A0A3P7DT14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT14"
  },
  {
    "uniprot": "A0A183XBJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XBJ6"
  },
  {
    "uniprot": "A0A3P7FYT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYT2"
  },
  {
    "uniprot": "A0A3P7DUR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUR3"
  },
  {
    "uniprot": "A0A3P7FM03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM03"
  },
  {
    "uniprot": "A0A3P7E4T4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4T4"
  },
  {
    "uniprot": "A0A3P7E0P0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0P0"
  },
  {
    "uniprot": "A0A3P7ED16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED16"
  },
  {
    "uniprot": "A0A3P7DRN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRN3"
  },
  {
    "uniprot": "A0A3P7E9K4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9K4"
  },
  {
    "uniprot": "J9FDW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FDW9"
  },
  {
    "uniprot": "A0A3P7EGU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGU4"
  },
  {
    "uniprot": "A0A3P7DP98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP98"
  },
  {
    "uniprot": "J9EEB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EEB2"
  },
  {
    "uniprot": "A0A3P7E8U5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8U5"
  },
  {
    "uniprot": "A0A3P7FLZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLZ9"
  },
  {
    "uniprot": "A0A3P7GEK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEK2"
  },
  {
    "uniprot": "A0A3P7FAU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAU3"
  },
  {
    "uniprot": "A0A3P7EAR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAR1"
  },
  {
    "uniprot": "A0A3P7F1L3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1L3"
  },
  {
    "uniprot": "A0A3P7GNW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GNW0"
  },
  {
    "uniprot": "J9F5R0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F5R0"
  },
  {
    "uniprot": "J9EDH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EDH5"
  },
  {
    "uniprot": "A0A3P7E465",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E465"
  },
  {
    "uniprot": "A0A3P7FDZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDZ3"
  },
  {
    "uniprot": "A0A3P7FD84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD84"
  },
  {
    "uniprot": "J9EX32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EX32"
  },
  {
    "uniprot": "A0A3P7E7P5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7P5"
  },
  {
    "uniprot": "A0A3P7G8W9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8W9"
  },
  {
    "uniprot": "J9F6Z0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F6Z0"
  },
  {
    "uniprot": "A0A3P7EF17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF17"
  },
  {
    "uniprot": "A0A3P7EUG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUG5"
  },
  {
    "uniprot": "A0A3P7DL41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL41"
  },
  {
    "uniprot": "A0A3P7EBB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBB3"
  },
  {
    "uniprot": "A0A3P7E1S4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1S4"
  },
  {
    "uniprot": "A0A3P7E5J8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5J8"
  },
  {
    "uniprot": "A0A3P7EW37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EW37"
  },
  {
    "uniprot": "A0A3P7E063",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E063"
  },
  {
    "uniprot": "A0A3P7FY95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY95"
  },
  {
    "uniprot": "A0A3P7E5R1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5R1"
  },
  {
    "uniprot": "A0A3P7DPE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPE7"
  },
  {
    "uniprot": "A0A3P7E3A0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3A0"
  },
  {
    "uniprot": "A0A3P7F9A2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9A2"
  },
  {
    "uniprot": "A0A3P7FVN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVN3"
  },
  {
    "uniprot": "A0A3P7DSZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSZ2"
  },
  {
    "uniprot": "A0A3P7ECP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECP0"
  },
  {
    "uniprot": "A0A3P7DK17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK17"
  },
  {
    "uniprot": "A0A3P7DXC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXC4"
  },
  {
    "uniprot": "A0A3P7E605",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E605"
  },
  {
    "uniprot": "A0A3P7DQ33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ33"
  },
  {
    "uniprot": "A0A3P7DZI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZI1"
  },
  {
    "uniprot": "A0A3P7DF24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF24"
  },
  {
    "uniprot": "A0A3P7F086",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F086"
  },
  {
    "uniprot": "A0A3P7FCN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCN4"
  },
  {
    "uniprot": "A0A3P7DBP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBP5"
  },
  {
    "uniprot": "A0A3P7E2A0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2A0"
  },
  {
    "uniprot": "A0A3P7GCN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCN4"
  },
  {
    "uniprot": "A0A3P7DQI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQI6"
  },
  {
    "uniprot": "A0A3P7GA60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GA60"
  },
  {
    "uniprot": "A0A3P7DZG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZG1"
  },
  {
    "uniprot": "A0A3P7DRU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRU9"
  },
  {
    "uniprot": "A0A3P7ECJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECJ4"
  },
  {
    "uniprot": "A0A3P7FKX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKX9"
  },
  {
    "uniprot": "A0A3P7FAW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAW9"
  },
  {
    "uniprot": "A0A3P7DVQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVQ9"
  },
  {
    "uniprot": "A0A3P7DRP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRP5"
  },
  {
    "uniprot": "A0A3P7G6F1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6F1"
  },
  {
    "uniprot": "A0A3P7DGX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGX9"
  },
  {
    "uniprot": "A0A3P7E0B3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0B3"
  },
  {
    "uniprot": "A0A3P7FP74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP74"
  },
  {
    "uniprot": "A0A3P7GA90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GA90"
  },
  {
    "uniprot": "A0A3P7EJ65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ65"
  },
  {
    "uniprot": "A0A3P7F8E0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8E0"
  },
  {
    "uniprot": "A0A3P7DXE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXE6"
  },
  {
    "uniprot": "A0A3P7FWJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWJ2"
  },
  {
    "uniprot": "A0A3P7ED86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED86"
  },
  {
    "uniprot": "A0A3P7GJ82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJ82"
  },
  {
    "uniprot": "A0A3P7FCF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCF9"
  },
  {
    "uniprot": "A0A3P7ER80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ER80"
  },
  {
    "uniprot": "A0A3P7ECF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECF7"
  },
  {
    "uniprot": "A0A3P7EFT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFT3"
  },
  {
    "uniprot": "A0A3P7GDC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDC6"
  },
  {
    "uniprot": "J9F5Y0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F5Y0"
  },
  {
    "uniprot": "A0A3P7DR62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR62"
  },
  {
    "uniprot": "A0A3P7DGM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGM3"
  },
  {
    "uniprot": "A0A3P7DS30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS30"
  },
  {
    "uniprot": "J9F605",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F605"
  },
  {
    "uniprot": "A0A3P7DXN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXN5"
  },
  {
    "uniprot": "A0A3P7DW07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW07"
  },
  {
    "uniprot": "A0A3P7GGX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGX5"
  },
  {
    "uniprot": "A0A3P7EHV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHV4"
  },
  {
    "uniprot": "A0A3P7F3S6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3S6"
  },
  {
    "uniprot": "A0A3P7DCD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCD5"
  },
  {
    "uniprot": "A0A3P7DCH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCH2"
  },
  {
    "uniprot": "A0A3P7DM02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM02"
  },
  {
    "uniprot": "A0A3P7DBE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBE1"
  },
  {
    "uniprot": "J9AYR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AYR0"
  },
  {
    "uniprot": "A0A3P7ECQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECQ8"
  },
  {
    "uniprot": "A0A3P7FKW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKW2"
  },
  {
    "uniprot": "A0A3P7E1H4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1H4"
  },
  {
    "uniprot": "A0A3P7DGU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGU6"
  },
  {
    "uniprot": "A0A3P7E0R1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0R1"
  },
  {
    "uniprot": "A0A3P7GGJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGJ8"
  },
  {
    "uniprot": "J9EIV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EIV4"
  },
  {
    "uniprot": "A0A3P7FS67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS67"
  },
  {
    "uniprot": "A0A3P7EAV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAV9"
  },
  {
    "uniprot": "A0A3P7DL64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL64"
  },
  {
    "uniprot": "A0A3P7FLF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLF2"
  },
  {
    "uniprot": "A0A3P7E9Y9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9Y9"
  },
  {
    "uniprot": "A0A3P7EE27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE27"
  },
  {
    "uniprot": "A0A3P7DMU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMU0"
  },
  {
    "uniprot": "A0A3P7DTQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTQ5"
  },
  {
    "uniprot": "A0A3P7FIA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIA3"
  },
  {
    "uniprot": "A0A3P7E1G4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1G4"
  },
  {
    "uniprot": "A0A3P7DVB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVB2"
  },
  {
    "uniprot": "A0A3P7DJD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJD1"
  },
  {
    "uniprot": "A0A3P7F024",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F024"
  },
  {
    "uniprot": "J9ETY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ETY9"
  },
  {
    "uniprot": "J9ETW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ETW4"
  },
  {
    "uniprot": "A0A3P7FWT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWT3"
  },
  {
    "uniprot": "A0A3P7GBX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBX5"
  },
  {
    "uniprot": "A0A3P7F2U7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2U7"
  },
  {
    "uniprot": "A0A3P7FWA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWA3"
  },
  {
    "uniprot": "A0A3P7GJL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJL5"
  },
  {
    "uniprot": "A0A3P7EXJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXJ6"
  },
  {
    "uniprot": "J9EP33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EP33"
  },
  {
    "uniprot": "A0A3P7F8Z2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8Z2"
  },
  {
    "uniprot": "A0A183Y3U6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y3U6"
  },
  {
    "uniprot": "A0A3P7GLJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLJ2"
  },
  {
    "uniprot": "A0A3P7E8V5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8V5"
  },
  {
    "uniprot": "A0A3P7DKV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKV6"
  },
  {
    "uniprot": "A0A3P7DZW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZW5"
  },
  {
    "uniprot": "A0A3P7ERE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERE4"
  },
  {
    "uniprot": "A0A3P7DK31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK31"
  },
  {
    "uniprot": "A0A3P7E6Y2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6Y2"
  },
  {
    "uniprot": "A0A3P7DXP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXP6"
  },
  {
    "uniprot": "A0A3P7EAN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAN8"
  },
  {
    "uniprot": "A0A3P7DYD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYD3"
  },
  {
    "uniprot": "A0A3P7E2Y5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2Y5"
  },
  {
    "uniprot": "A0A3P7E155",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E155"
  },
  {
    "uniprot": "A0A3P7E662",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E662"
  },
  {
    "uniprot": "A0A3P7E0S7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0S7"
  },
  {
    "uniprot": "A0A3P7FK28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK28"
  },
  {
    "uniprot": "A0A3P7DG46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG46"
  },
  {
    "uniprot": "A0A3P7E1N2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1N2"
  },
  {
    "uniprot": "A0A3P7DYB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYB6"
  },
  {
    "uniprot": "A0A3P7EE73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE73"
  },
  {
    "uniprot": "A0A3P7DRE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRE9"
  },
  {
    "uniprot": "A0A3P7DPW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPW1"
  },
  {
    "uniprot": "A0A3P7EKS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKS5"
  },
  {
    "uniprot": "A0A3P7GAW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAW0"
  },
  {
    "uniprot": "A0A3P7ELI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELI1"
  },
  {
    "uniprot": "A0A183Y227",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y227"
  },
  {
    "uniprot": "A0A3P7E092",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E092"
  },
  {
    "uniprot": "A0A3P7FSA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSA2"
  },
  {
    "uniprot": "J9EVX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EVX6"
  },
  {
    "uniprot": "A0A3P7E9I0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9I0"
  },
  {
    "uniprot": "A0A3P7E285",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E285"
  },
  {
    "uniprot": "A0A3P7DI82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI82"
  },
  {
    "uniprot": "A0A3P7G0Q5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0Q5"
  },
  {
    "uniprot": "A0A3P7G0H5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0H5"
  },
  {
    "uniprot": "A0A3P7DC56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC56"
  },
  {
    "uniprot": "A0A3P7EAE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAE0"
  },
  {
    "uniprot": "A0A3P7DP51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP51"
  },
  {
    "uniprot": "A0A3P7EBB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBB4"
  },
  {
    "uniprot": "A0A3P7G8D8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8D8"
  },
  {
    "uniprot": "A0A3P7F0G0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0G0"
  },
  {
    "uniprot": "A0A3P7DS98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS98"
  },
  {
    "uniprot": "A0A3P7E2T3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2T3"
  },
  {
    "uniprot": "A0A3P7DIH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIH9"
  },
  {
    "uniprot": "J9FBX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FBX3"
  },
  {
    "uniprot": "A0A3P7DZK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZK8"
  },
  {
    "uniprot": "A0A3P7G338",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G338"
  },
  {
    "uniprot": "A0A3P7E0W3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0W3"
  },
  {
    "uniprot": "A0A3P7DVM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVM6"
  },
  {
    "uniprot": "A0A3P7DQ28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ28"
  },
  {
    "uniprot": "A0A3P7E6J2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6J2"
  },
  {
    "uniprot": "A0A3P7FSB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSB8"
  },
  {
    "uniprot": "J9FBA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FBA2"
  },
  {
    "uniprot": "A0A3P7E4L8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4L8"
  },
  {
    "uniprot": "A0A3P7G1Q9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1Q9"
  },
  {
    "uniprot": "A0A3P7ED07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED07"
  },
  {
    "uniprot": "A0A3P7DTF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTF1"
  },
  {
    "uniprot": "A0A3P7E2E9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2E9"
  },
  {
    "uniprot": "J9FH63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FH63"
  },
  {
    "uniprot": "A0A3P7DV45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV45"
  },
  {
    "uniprot": "A0A3P7ENC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENC3"
  },
  {
    "uniprot": "A0A3P7F301",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F301"
  },
  {
    "uniprot": "A0A3P7F0T2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0T2"
  },
  {
    "uniprot": "A0A3P7DSA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSA7"
  },
  {
    "uniprot": "A0A3P7DVZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVZ2"
  },
  {
    "uniprot": "A0A3P7F8J6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8J6"
  },
  {
    "uniprot": "A0A3P7EL38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL38"
  },
  {
    "uniprot": "A0A3P7E3Y0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3Y0"
  },
  {
    "uniprot": "A0A3P7F7Q5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7Q5"
  },
  {
    "uniprot": "A0A3P7DCG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCG8"
  },
  {
    "uniprot": "A0A3P7FBS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBS5"
  },
  {
    "uniprot": "A0A3P7E921",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E921"
  },
  {
    "uniprot": "A0A183XFH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XFH5"
  },
  {
    "uniprot": "A0A3P7FX92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX92"
  },
  {
    "uniprot": "A0A3P7DZ12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ12"
  },
  {
    "uniprot": "A0A3P7DSY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSY8"
  },
  {
    "uniprot": "A0A3P7G6I3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6I3"
  },
  {
    "uniprot": "A0A3P7G3D5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3D5"
  },
  {
    "uniprot": "A0A3P7F1K7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1K7"
  },
  {
    "uniprot": "A0A183XIE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XIE7"
  },
  {
    "uniprot": "A0A3P7G912",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G912"
  },
  {
    "uniprot": "A0A3P7FLW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLW5"
  },
  {
    "uniprot": "A0A3P7DK33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK33"
  },
  {
    "uniprot": "A0A3P7FHB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHB7"
  },
  {
    "uniprot": "A0A3P7DI78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI78"
  },
  {
    "uniprot": "A0A3P7DX52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX52"
  },
  {
    "uniprot": "A0A3P7EG88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG88"
  },
  {
    "uniprot": "J9BNB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BNB2"
  },
  {
    "uniprot": "J9EIE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EIE3"
  },
  {
    "uniprot": "A0A3P7DWC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWC1"
  },
  {
    "uniprot": "A0A3P7EHU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHU2"
  },
  {
    "uniprot": "A0A3P7DQE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQE7"
  },
  {
    "uniprot": "A0A3P7EAH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAH4"
  },
  {
    "uniprot": "A0A3P7E8P1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8P1"
  },
  {
    "uniprot": "A0A3P7FYR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYR3"
  },
  {
    "uniprot": "A0A3P7EA54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA54"
  },
  {
    "uniprot": "A0A3P7EIG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIG7"
  },
  {
    "uniprot": "A0A3P7FSX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSX3"
  },
  {
    "uniprot": "J9F9D9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F9D9"
  },
  {
    "uniprot": "A0A3P7F2D9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2D9"
  },
  {
    "uniprot": "A0A3P7FGP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGP0"
  },
  {
    "uniprot": "A0A3P7FGU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGU6"
  },
  {
    "uniprot": "A0A3P7EC28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC28"
  },
  {
    "uniprot": "A0A3P7FK90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK90"
  },
  {
    "uniprot": "A0A3P7DI59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI59"
  },
  {
    "uniprot": "A0A183XM31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XM31"
  },
  {
    "uniprot": "A0A3P7EY26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY26"
  },
  {
    "uniprot": "A0A3P7E964",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E964"
  },
  {
    "uniprot": "A0A3P7FCI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCI8"
  },
  {
    "uniprot": "A0A3P7E6V6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6V6"
  },
  {
    "uniprot": "A0A3P7DYZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYZ6"
  },
  {
    "uniprot": "A0A3P7EMZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMZ1"
  },
  {
    "uniprot": "A0A3P7E0A3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0A3"
  },
  {
    "uniprot": "A0A3P7F730",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F730"
  },
  {
    "uniprot": "A0A3P7EEF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEF3"
  },
  {
    "uniprot": "A0A3P7DF76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF76"
  },
  {
    "uniprot": "A0A3P7E271",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E271"
  },
  {
    "uniprot": "A0A3P7EU95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU95"
  },
  {
    "uniprot": "A0A3P7E3W8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3W8"
  },
  {
    "uniprot": "A0A3P7EH92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH92"
  },
  {
    "uniprot": "A0A3P7EEC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEC2"
  },
  {
    "uniprot": "J9BLK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BLK5"
  },
  {
    "uniprot": "A0A3P7FTI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTI8"
  },
  {
    "uniprot": "A0A3P7DU71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU71"
  },
  {
    "uniprot": "A0A3P7FSM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSM2"
  },
  {
    "uniprot": "A0A3P7E0G7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0G7"
  },
  {
    "uniprot": "J9EHV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EHV2"
  },
  {
    "uniprot": "A0A3P7GM02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GM02"
  },
  {
    "uniprot": "A0A3P7E457",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E457"
  },
  {
    "uniprot": "A0A3P7DZC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZC2"
  },
  {
    "uniprot": "A0A3P7E303",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E303"
  },
  {
    "uniprot": "J9EY69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EY69"
  },
  {
    "uniprot": "A0A3P7DE03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE03"
  },
  {
    "uniprot": "A0A3P7F1X4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1X4"
  },
  {
    "uniprot": "A0A183XKP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XKP7"
  },
  {
    "uniprot": "A0A3P7E0R2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0R2"
  },
  {
    "uniprot": "A0A3P7F4Y0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F4Y0"
  },
  {
    "uniprot": "A0A3P7ET26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET26"
  },
  {
    "uniprot": "A0A3P7DWD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWD4"
  },
  {
    "uniprot": "A0A3P7ECD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECD5"
  },
  {
    "uniprot": "A0A3P7FJS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJS1"
  },
  {
    "uniprot": "A0A3P7E669",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E669"
  },
  {
    "uniprot": "A0A3P7DIV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIV3"
  },
  {
    "uniprot": "A0A3P7DU19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU19"
  },
  {
    "uniprot": "A0A3P7E2W8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2W8"
  },
  {
    "uniprot": "A0A3P7FJS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJS9"
  },
  {
    "uniprot": "A0A3P7GEQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEQ1"
  },
  {
    "uniprot": "A0A3P7DZA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZA0"
  },
  {
    "uniprot": "A0A3P7FXI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXI8"
  },
  {
    "uniprot": "A0A3P7DXG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXG3"
  },
  {
    "uniprot": "A0A3P7DT57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT57"
  },
  {
    "uniprot": "A0A3P7EDY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDY6"
  },
  {
    "uniprot": "A0A3P7FIV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIV8"
  },
  {
    "uniprot": "A0A3P7DQ12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ12"
  },
  {
    "uniprot": "A0A3P7DXT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXT0"
  },
  {
    "uniprot": "A0A3P7G4D1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4D1"
  },
  {
    "uniprot": "A0A3P7EHG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHG1"
  },
  {
    "uniprot": "A0A3P7DPV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPV4"
  },
  {
    "uniprot": "A0A3P7DDM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDM6"
  },
  {
    "uniprot": "A0A183XKD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XKD8"
  },
  {
    "uniprot": "A0A3P7ENB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENB9"
  },
  {
    "uniprot": "A0A3P7DJ50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ50"
  },
  {
    "uniprot": "A0A3P7ERF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERF7"
  },
  {
    "uniprot": "A0A3P7E9E0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9E0"
  },
  {
    "uniprot": "J9FDL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FDL0"
  },
  {
    "uniprot": "A0A3P7DRW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRW9"
  },
  {
    "uniprot": "A0A3P7ED89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED89"
  },
  {
    "uniprot": "A0A3P7GHT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHT5"
  },
  {
    "uniprot": "A0A3P7DTC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTC4"
  },
  {
    "uniprot": "A0A3P7E3G6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3G6"
  },
  {
    "uniprot": "A0A3P7EG81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG81"
  },
  {
    "uniprot": "A0A3P7FTN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTN2"
  },
  {
    "uniprot": "A0A3P7EBW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBW7"
  },
  {
    "uniprot": "A0A3P7E4F9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4F9"
  },
  {
    "uniprot": "A0A3P7DIC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIC1"
  },
  {
    "uniprot": "A0A3P7DSQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSQ9"
  },
  {
    "uniprot": "A0A3P7FSA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSA8"
  },
  {
    "uniprot": "A0A3P7EC57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC57"
  },
  {
    "uniprot": "A0A3P7DV96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV96"
  },
  {
    "uniprot": "A0A3P7EC56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC56"
  },
  {
    "uniprot": "A0A3P7EWZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWZ7"
  },
  {
    "uniprot": "A0A3P7FL95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL95"
  },
  {
    "uniprot": "A0A3P7EHE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHE6"
  },
  {
    "uniprot": "A0A3P7EEI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEI3"
  },
  {
    "uniprot": "A0A3P7ER74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ER74"
  },
  {
    "uniprot": "A0A3P7DVS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVS3"
  },
  {
    "uniprot": "A0A3P7EIK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIK3"
  },
  {
    "uniprot": "A0A3P7E1F6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1F6"
  },
  {
    "uniprot": "J9E6I2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E6I2"
  },
  {
    "uniprot": "A0A3P7DYL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYL8"
  },
  {
    "uniprot": "J9EK08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EK08"
  },
  {
    "uniprot": "A0A3P7FSP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSP0"
  },
  {
    "uniprot": "A0A3P7DMH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMH0"
  },
  {
    "uniprot": "A0A3P7FDP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDP7"
  },
  {
    "uniprot": "A0A3P7GCJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCJ6"
  },
  {
    "uniprot": "A0A3P7GNE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GNE8"
  },
  {
    "uniprot": "A0A3P7DS68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS68"
  },
  {
    "uniprot": "A0A3P7DIW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIW5"
  },
  {
    "uniprot": "A0A183XCU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCU7"
  },
  {
    "uniprot": "A0A3P7E551",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E551"
  },
  {
    "uniprot": "A0A3P7F9T7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9T7"
  },
  {
    "uniprot": "A0A3P7FND7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FND7"
  },
  {
    "uniprot": "A0A3P7EEQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEQ9"
  },
  {
    "uniprot": "A0A3P7EXJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXJ7"
  },
  {
    "uniprot": "A0A3P7G0S2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0S2"
  },
  {
    "uniprot": "A0A3P7G165",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G165"
  },
  {
    "uniprot": "A0A3P7E7D4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7D4"
  },
  {
    "uniprot": "A0A3P7FY39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY39"
  },
  {
    "uniprot": "A0A3P7ES82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ES82"
  },
  {
    "uniprot": "A0A3P7E2V8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2V8"
  },
  {
    "uniprot": "A0A3P7EWE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWE5"
  },
  {
    "uniprot": "A0A3P7FFR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFR8"
  },
  {
    "uniprot": "A0A3P7DI39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI39"
  },
  {
    "uniprot": "A0A3P7F485",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F485"
  },
  {
    "uniprot": "A0A3P7E844",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E844"
  },
  {
    "uniprot": "A0A3P7DVJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVJ0"
  },
  {
    "uniprot": "A0A3P7FC87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC87"
  },
  {
    "uniprot": "A0A3P7FF91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF91"
  },
  {
    "uniprot": "A0A3P7FJ60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ60"
  },
  {
    "uniprot": "A0A3P7EHC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHC5"
  },
  {
    "uniprot": "A0A3P7FQ91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ91"
  },
  {
    "uniprot": "J9EU62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EU62"
  },
  {
    "uniprot": "A0A3P7GCN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCN8"
  },
  {
    "uniprot": "A0A3P7DWX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWX3"
  },
  {
    "uniprot": "A0A3P7DKG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKG2"
  },
  {
    "uniprot": "A0A3P7G784",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G784"
  },
  {
    "uniprot": "A0A3P7FQT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQT4"
  },
  {
    "uniprot": "A0A3P7GG69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GG69"
  },
  {
    "uniprot": "A0A3P7DRU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRU6"
  },
  {
    "uniprot": "A0A3P7E3H3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3H3"
  },
  {
    "uniprot": "A0A3P7FEX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEX2"
  },
  {
    "uniprot": "A0A3P7FVW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVW6"
  },
  {
    "uniprot": "A0A3P7DXB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXB3"
  },
  {
    "uniprot": "A0A3P7DZ58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ58"
  },
  {
    "uniprot": "A0A3P7ESQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESQ3"
  },
  {
    "uniprot": "A0A3P7DXV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXV9"
  },
  {
    "uniprot": "A0A3P7E0X2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0X2"
  },
  {
    "uniprot": "A0A3P7DWY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWY8"
  },
  {
    "uniprot": "A0A3P7EA74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA74"
  },
  {
    "uniprot": "J9EM11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EM11"
  },
  {
    "uniprot": "A0A3P7DCS4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCS4"
  },
  {
    "uniprot": "A0A3P7E8I6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8I6"
  },
  {
    "uniprot": "A0A3P7E276",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E276"
  },
  {
    "uniprot": "A0A3P7DUS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUS6"
  },
  {
    "uniprot": "J9BKD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BKD0"
  },
  {
    "uniprot": "A0A3P7DSS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSS3"
  },
  {
    "uniprot": "A0A3P7DJN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJN7"
  },
  {
    "uniprot": "A0A3P7FHK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHK1"
  },
  {
    "uniprot": "A0A3P7FVM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVM5"
  },
  {
    "uniprot": "A0A183XZM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XZM7"
  },
  {
    "uniprot": "A0A3P7FT83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT83"
  },
  {
    "uniprot": "A0A3P7DN25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN25"
  },
  {
    "uniprot": "A0A3P7EFR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFR6"
  },
  {
    "uniprot": "A0A3P7FIZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIZ7"
  },
  {
    "uniprot": "A0A3P7DF40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF40"
  },
  {
    "uniprot": "A0A3P7DV82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV82"
  },
  {
    "uniprot": "A0A3P7E8K6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8K6"
  },
  {
    "uniprot": "A0A3P7ED41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED41"
  },
  {
    "uniprot": "A0A3P7GCP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCP3"
  },
  {
    "uniprot": "A0A3P7DDD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDD6"
  },
  {
    "uniprot": "A0A3P7FN48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN48"
  },
  {
    "uniprot": "A0A3P7DW41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW41"
  },
  {
    "uniprot": "A0A3P7E767",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E767"
  },
  {
    "uniprot": "A0A3P7GM64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GM64"
  },
  {
    "uniprot": "A0A3P7F3Z8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3Z8"
  },
  {
    "uniprot": "A0A3P7EW21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EW21"
  },
  {
    "uniprot": "A0A3P7DSR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSR8"
  },
  {
    "uniprot": "A0A3P7DPN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPN3"
  },
  {
    "uniprot": "J9EZR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZR8"
  },
  {
    "uniprot": "A0A3P7E6Q5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6Q5"
  },
  {
    "uniprot": "A0A3P7DHM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHM5"
  },
  {
    "uniprot": "A0A3P7FRZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRZ3"
  },
  {
    "uniprot": "A0A3P7FYY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYY4"
  },
  {
    "uniprot": "A0A3P7E2S3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2S3"
  },
  {
    "uniprot": "A0A3P7FXX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXX9"
  },
  {
    "uniprot": "A0A3P7EBH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBH1"
  },
  {
    "uniprot": "J9BHJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BHJ6"
  },
  {
    "uniprot": "J9AGM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AGM9"
  },
  {
    "uniprot": "A0A3P7EP53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP53"
  },
  {
    "uniprot": "A0A3P7FZL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZL5"
  },
  {
    "uniprot": "A0A3P7DK93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK93"
  },
  {
    "uniprot": "J9EY10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EY10"
  },
  {
    "uniprot": "A0A3P7DR72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR72"
  },
  {
    "uniprot": "A0A3P7G5D0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5D0"
  },
  {
    "uniprot": "A0A3P7E024",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E024"
  },
  {
    "uniprot": "A0A3P7DF82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF82"
  },
  {
    "uniprot": "A0A3P7EW07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EW07"
  },
  {
    "uniprot": "A0A3P7DD23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD23"
  },
  {
    "uniprot": "A0A3P7DY17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY17"
  },
  {
    "uniprot": "A0A3P7DFR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFR7"
  },
  {
    "uniprot": "A0A3P7EUI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUI6"
  },
  {
    "uniprot": "A0A3P7FLD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLD2"
  },
  {
    "uniprot": "A0A3P7G3J7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3J7"
  },
  {
    "uniprot": "A0A3P7FIH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIH7"
  },
  {
    "uniprot": "A0A3P7DC62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC62"
  },
  {
    "uniprot": "J9BK53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BK53"
  },
  {
    "uniprot": "A0A3P7EB58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB58"
  },
  {
    "uniprot": "A0A3P7DTS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTS9"
  },
  {
    "uniprot": "A0A3P7FQ59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ59"
  },
  {
    "uniprot": "A0A3P7FW06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW06"
  },
  {
    "uniprot": "A0A3P7E8P8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8P8"
  },
  {
    "uniprot": "A0A3P7FBD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBD0"
  },
  {
    "uniprot": "J9F4Z6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F4Z6"
  },
  {
    "uniprot": "A0A3P7FCA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCA9"
  },
  {
    "uniprot": "A0A3P7G7F8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G7F8"
  },
  {
    "uniprot": "A0A3P7EYP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYP8"
  },
  {
    "uniprot": "A0A3P7DT85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT85"
  },
  {
    "uniprot": "A0A3P7EL55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL55"
  },
  {
    "uniprot": "A0A3P7G2J6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2J6"
  },
  {
    "uniprot": "A0A3P7DU26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU26"
  },
  {
    "uniprot": "A0A3P7DNW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNW6"
  },
  {
    "uniprot": "J9ETS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ETS1"
  },
  {
    "uniprot": "A0A183XPX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XPX9"
  },
  {
    "uniprot": "J9FCG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FCG2"
  },
  {
    "uniprot": "A0A3P7DY06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY06"
  },
  {
    "uniprot": "A0A3P7G0A1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0A1"
  },
  {
    "uniprot": "A0A3P7DF02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF02"
  },
  {
    "uniprot": "A0A3P7FH48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH48"
  },
  {
    "uniprot": "A0A3P7FTF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTF9"
  },
  {
    "uniprot": "A0A3P7DVD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVD7"
  },
  {
    "uniprot": "A0A3P7DNN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNN2"
  },
  {
    "uniprot": "A0A3P7EQ30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQ30"
  },
  {
    "uniprot": "J9BKU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BKU6"
  },
  {
    "uniprot": "A0A3P7DWC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWC7"
  },
  {
    "uniprot": "A0A3P7DWM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWM8"
  },
  {
    "uniprot": "A0A3P7FL44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL44"
  },
  {
    "uniprot": "A0A3P7DC50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC50"
  },
  {
    "uniprot": "A0A3P7GGK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGK7"
  },
  {
    "uniprot": "J9ETW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ETW8"
  },
  {
    "uniprot": "A0A3P7DCE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCE6"
  },
  {
    "uniprot": "A0A3P7DXA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXA0"
  },
  {
    "uniprot": "A0A3P7E8F5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8F5"
  },
  {
    "uniprot": "A0A3P7E7C8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7C8"
  },
  {
    "uniprot": "A0A3P7DR73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR73"
  },
  {
    "uniprot": "A0A3P7DVI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVI0"
  },
  {
    "uniprot": "A0A3P7DL24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL24"
  },
  {
    "uniprot": "A0A3P7E4M8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4M8"
  },
  {
    "uniprot": "A0A3P7EMR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMR7"
  },
  {
    "uniprot": "A0A3P7G0D2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0D2"
  },
  {
    "uniprot": "A0A3P7DQQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQQ7"
  },
  {
    "uniprot": "A0A3P7ERZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERZ3"
  },
  {
    "uniprot": "A0A3P7DWQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWQ0"
  },
  {
    "uniprot": "A0A3P7E545",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E545"
  },
  {
    "uniprot": "A0A3P7ESL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESL0"
  },
  {
    "uniprot": "A0A3P7E4X3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4X3"
  },
  {
    "uniprot": "A0A3P7DC38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC38"
  },
  {
    "uniprot": "A0A3P7E8F7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8F7"
  },
  {
    "uniprot": "A0A3P7FQ17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ17"
  },
  {
    "uniprot": "A0A3P7DI25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI25"
  },
  {
    "uniprot": "A0A3P7E3B5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3B5"
  },
  {
    "uniprot": "A0A183XMS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XMS5"
  },
  {
    "uniprot": "A0A3P7ENG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENG9"
  },
  {
    "uniprot": "A0A3P7E890",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E890"
  },
  {
    "uniprot": "A0A3P7DPB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPB1"
  },
  {
    "uniprot": "A0A3P7E7V5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7V5"
  },
  {
    "uniprot": "A0A3P7ERY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERY8"
  },
  {
    "uniprot": "A0A3P7DTM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTM6"
  },
  {
    "uniprot": "A0A3P7EX62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EX62"
  },
  {
    "uniprot": "A0A3P7E7H0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7H0"
  },
  {
    "uniprot": "J9FNF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FNF0"
  },
  {
    "uniprot": "A0A3P7DYK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYK3"
  },
  {
    "uniprot": "A0A3P7FYE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYE8"
  },
  {
    "uniprot": "A0A3P7F3K9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3K9"
  },
  {
    "uniprot": "A0A3P7DCN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCN8"
  },
  {
    "uniprot": "A0A3P7FE72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE72"
  },
  {
    "uniprot": "A0A3P7FTG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTG3"
  },
  {
    "uniprot": "A0A3P7DN51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN51"
  },
  {
    "uniprot": "A0A3P7E2P7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2P7"
  },
  {
    "uniprot": "A0A3P7GFN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFN8"
  },
  {
    "uniprot": "A0A3P7DXP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXP8"
  },
  {
    "uniprot": "A0A3P7DS97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS97"
  },
  {
    "uniprot": "A0A3P7E118",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E118"
  },
  {
    "uniprot": "A0A3P7EGT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGT7"
  },
  {
    "uniprot": "A0A3P7E7D7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7D7"
  },
  {
    "uniprot": "A0A3P7ENJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENJ2"
  },
  {
    "uniprot": "A0A3P7FV45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV45"
  },
  {
    "uniprot": "A0A3P7FCR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCR5"
  },
  {
    "uniprot": "A0A3P7DVM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVM7"
  },
  {
    "uniprot": "A0A3P7EKB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKB4"
  },
  {
    "uniprot": "A0A3P7E328",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E328"
  },
  {
    "uniprot": "A0A3P7DGA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGA5"
  },
  {
    "uniprot": "A0A3P7DBN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBN3"
  },
  {
    "uniprot": "A0A3P7DX91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX91"
  },
  {
    "uniprot": "A0A3P7G2I6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2I6"
  },
  {
    "uniprot": "J9BIN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BIN2"
  },
  {
    "uniprot": "A0A3P7E1N5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1N5"
  },
  {
    "uniprot": "A0A3P7FUQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUQ4"
  },
  {
    "uniprot": "A0A3P7ET07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET07"
  },
  {
    "uniprot": "A0A3P7F7G3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7G3"
  },
  {
    "uniprot": "A0A3P7DWE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWE6"
  },
  {
    "uniprot": "A0A3P7ESH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESH6"
  },
  {
    "uniprot": "A0A3P7FZ89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ89"
  },
  {
    "uniprot": "A0A3P7EGE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGE7"
  },
  {
    "uniprot": "A0A3P7G113",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G113"
  },
  {
    "uniprot": "A0A3P7GBW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBW8"
  },
  {
    "uniprot": "A0A3P7FB51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB51"
  },
  {
    "uniprot": "A0A3P7FWS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWS3"
  },
  {
    "uniprot": "A0A3P7E860",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E860"
  },
  {
    "uniprot": "A0A3P7G4V6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4V6"
  },
  {
    "uniprot": "A0A3P7E4S5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4S5"
  },
  {
    "uniprot": "A0A3P7DP95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP95"
  },
  {
    "uniprot": "A0A3P7DFU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFU4"
  },
  {
    "uniprot": "A0A3P7DKE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKE3"
  },
  {
    "uniprot": "A0A3P7GKR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKR9"
  },
  {
    "uniprot": "J9EJG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EJG0"
  },
  {
    "uniprot": "A0A3P7G832",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G832"
  },
  {
    "uniprot": "J9FNS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FNS2"
  },
  {
    "uniprot": "J9FC30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FC30"
  },
  {
    "uniprot": "A0A3P7F2I3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2I3"
  },
  {
    "uniprot": "A0A3P7EJP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJP5"
  },
  {
    "uniprot": "A0A3P7DRD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRD6"
  },
  {
    "uniprot": "A0A3P7ESX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESX3"
  },
  {
    "uniprot": "A0A3P7DQ67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ67"
  },
  {
    "uniprot": "A0A3P7DZK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZK1"
  },
  {
    "uniprot": "A0A3P7EJM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJM2"
  },
  {
    "uniprot": "A0A3P7E3B0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3B0"
  },
  {
    "uniprot": "A0A3P7EFE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFE0"
  },
  {
    "uniprot": "A0A3P7DZN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZN0"
  },
  {
    "uniprot": "J9EX56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EX56"
  },
  {
    "uniprot": "A0A3P7EB90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB90"
  },
  {
    "uniprot": "A0A3P7FYE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYE6"
  },
  {
    "uniprot": "A0A3P7DWK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWK5"
  },
  {
    "uniprot": "A0A3P7EFZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFZ3"
  },
  {
    "uniprot": "A0A3P7EBB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBB2"
  },
  {
    "uniprot": "A0A3P7DIZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIZ9"
  },
  {
    "uniprot": "A0A3P7EK18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK18"
  },
  {
    "uniprot": "A0A3P7FSA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSA6"
  },
  {
    "uniprot": "A0A3P7FP01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP01"
  },
  {
    "uniprot": "J9BA84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BA84"
  },
  {
    "uniprot": "A0A3P7FCE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCE9"
  },
  {
    "uniprot": "A0A3P7EC20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC20"
  },
  {
    "uniprot": "J9B8I1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B8I1"
  },
  {
    "uniprot": "A0A3P7FK88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK88"
  },
  {
    "uniprot": "A0A3P7EA06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA06"
  },
  {
    "uniprot": "A0A3P7DKU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKU9"
  },
  {
    "uniprot": "A0A3P7EF41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF41"
  },
  {
    "uniprot": "A0A3P7E6T1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6T1"
  },
  {
    "uniprot": "A0A3P7E8H1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8H1"
  },
  {
    "uniprot": "A0A3P7G969",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G969"
  },
  {
    "uniprot": "A0A3P7FI27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI27"
  },
  {
    "uniprot": "A0A3P7DVD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVD3"
  },
  {
    "uniprot": "J9ERX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERX4"
  },
  {
    "uniprot": "J9ES92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ES92"
  },
  {
    "uniprot": "A0A3P7DTN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTN0"
  },
  {
    "uniprot": "A0A3P7E7C3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7C3"
  },
  {
    "uniprot": "A0A3P7FX22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX22"
  },
  {
    "uniprot": "A0A3P7E5Q5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5Q5"
  },
  {
    "uniprot": "A0A3P7DSJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSJ8"
  },
  {
    "uniprot": "A0A3P7DK79",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK79"
  },
  {
    "uniprot": "A0A3P7E0X1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0X1"
  },
  {
    "uniprot": "A0A183XWU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XWU4"
  },
  {
    "uniprot": "A0A3P7FPA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPA2"
  },
  {
    "uniprot": "A0A3P7DPI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPI6"
  },
  {
    "uniprot": "A0A3P7FXU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXU2"
  },
  {
    "uniprot": "A0A3P7EEU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEU3"
  },
  {
    "uniprot": "A0A3P7EUA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUA4"
  },
  {
    "uniprot": "A0A3P7EJA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJA7"
  },
  {
    "uniprot": "A0A3P7EBR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBR6"
  },
  {
    "uniprot": "A0A3P7DUK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUK9"
  },
  {
    "uniprot": "A0A3P7DH98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH98"
  },
  {
    "uniprot": "A0A3P7EV95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EV95"
  },
  {
    "uniprot": "A0A3P7DBL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBL7"
  },
  {
    "uniprot": "A0A3P7DVB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVB5"
  },
  {
    "uniprot": "A0A3P7EE75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE75"
  },
  {
    "uniprot": "A0A3P7GDS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDS2"
  },
  {
    "uniprot": "A0A3P7GI72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GI72"
  },
  {
    "uniprot": "A0A183XB32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XB32"
  },
  {
    "uniprot": "A0A3P7DRM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRM3"
  },
  {
    "uniprot": "A0A3P7E4K6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4K6"
  },
  {
    "uniprot": "A0A3P7DWJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWJ3"
  },
  {
    "uniprot": "A0A3P7EZX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZX7"
  },
  {
    "uniprot": "A0A3P7EDN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDN3"
  },
  {
    "uniprot": "A0A3P7E4I3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4I3"
  },
  {
    "uniprot": "A0A3P7E9H7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9H7"
  },
  {
    "uniprot": "A0A3P7GJS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJS1"
  },
  {
    "uniprot": "A0A3P7EDR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDR5"
  },
  {
    "uniprot": "A0A3P7ED34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED34"
  },
  {
    "uniprot": "A0A3P7EPU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPU6"
  },
  {
    "uniprot": "A0A3P7E5Z2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5Z2"
  },
  {
    "uniprot": "A0A3P7E8S5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8S5"
  },
  {
    "uniprot": "A0A3P7GBB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBB6"
  },
  {
    "uniprot": "A0A3P7EQP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQP4"
  },
  {
    "uniprot": "A0A3P7FIX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIX9"
  },
  {
    "uniprot": "A0A3P7E651",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E651"
  },
  {
    "uniprot": "A0A3P7FIW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIW8"
  },
  {
    "uniprot": "A0A3P7E6C1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6C1"
  },
  {
    "uniprot": "A0A3P7DQU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQU5"
  },
  {
    "uniprot": "A0A3P7EDZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDZ9"
  },
  {
    "uniprot": "A0A3P7FKN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKN5"
  },
  {
    "uniprot": "A0A3P7GJ34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJ34"
  },
  {
    "uniprot": "A0A183XBK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XBK7"
  },
  {
    "uniprot": "A0A3P7DNU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNU6"
  },
  {
    "uniprot": "A0A3P7F8K6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8K6"
  },
  {
    "uniprot": "A0A3P7FSH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSH3"
  },
  {
    "uniprot": "A0A3P7GHU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHU2"
  },
  {
    "uniprot": "J9ELR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ELR8"
  },
  {
    "uniprot": "A0A3P7EEG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEG7"
  },
  {
    "uniprot": "A0A3P7DAW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAW7"
  },
  {
    "uniprot": "A0A3P7FQU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQU1"
  },
  {
    "uniprot": "A0A3P7EGQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGQ6"
  },
  {
    "uniprot": "A0A3P7DIM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIM7"
  },
  {
    "uniprot": "A0A183XR05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XR05"
  },
  {
    "uniprot": "A0A3P7GDK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDK6"
  },
  {
    "uniprot": "A0A3P7FSE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSE4"
  },
  {
    "uniprot": "A0A3P7E6D1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6D1"
  },
  {
    "uniprot": "A0A3P7FH07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH07"
  },
  {
    "uniprot": "A0A3P7EG69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG69"
  },
  {
    "uniprot": "A0A3P7DZZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZZ9"
  },
  {
    "uniprot": "A0A3P7ECU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECU6"
  },
  {
    "uniprot": "A0A3P7EDX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDX5"
  },
  {
    "uniprot": "A0A3P7FFM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFM0"
  },
  {
    "uniprot": "A0A3P7E6T6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6T6"
  },
  {
    "uniprot": "A0A3P7DV69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV69"
  },
  {
    "uniprot": "A0A3P7FWW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWW3"
  },
  {
    "uniprot": "J9B6T8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B6T8"
  },
  {
    "uniprot": "A0A3P7G5Y5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5Y5"
  },
  {
    "uniprot": "J9EUR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUR6"
  },
  {
    "uniprot": "A0A3P7FD11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD11"
  },
  {
    "uniprot": "A0A3P7F9D0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9D0"
  },
  {
    "uniprot": "A0A3P7EFA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFA4"
  },
  {
    "uniprot": "A0A3P7GGU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGU6"
  },
  {
    "uniprot": "A0A3P7DU92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU92"
  },
  {
    "uniprot": "A0A3P7GG58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GG58"
  },
  {
    "uniprot": "A0A3P7ESN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESN8"
  },
  {
    "uniprot": "J9BIP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BIP5"
  },
  {
    "uniprot": "A0A3P7EX28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EX28"
  },
  {
    "uniprot": "J9BA14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BA14"
  },
  {
    "uniprot": "A0A3P7FZ80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ80"
  },
  {
    "uniprot": "A0A183XDX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDX1"
  },
  {
    "uniprot": "A0A3P7E7X5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7X5"
  },
  {
    "uniprot": "A0A3P7FVF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVF6"
  },
  {
    "uniprot": "A0A3P7E2M7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2M7"
  },
  {
    "uniprot": "A0A3P7DR90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR90"
  },
  {
    "uniprot": "A0A3P7FJU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJU6"
  },
  {
    "uniprot": "A0A3P7EDX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDX3"
  },
  {
    "uniprot": "A0A3P7F239",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F239"
  },
  {
    "uniprot": "A0A3P7G616",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G616"
  },
  {
    "uniprot": "A0A3P7G8V0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8V0"
  },
  {
    "uniprot": "A0A3P7E6L7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6L7"
  },
  {
    "uniprot": "A0A3P7DM35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM35"
  },
  {
    "uniprot": "A0A3P7GH71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GH71"
  },
  {
    "uniprot": "A0A3P7F3K4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3K4"
  },
  {
    "uniprot": "J9EW26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EW26"
  },
  {
    "uniprot": "A0A183XJI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XJI0"
  },
  {
    "uniprot": "A0A3P7EPG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPG9"
  },
  {
    "uniprot": "A0A3P7DDP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDP1"
  },
  {
    "uniprot": "A0A3P7DNM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNM5"
  },
  {
    "uniprot": "J9EVV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EVV3"
  },
  {
    "uniprot": "J9EYR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYR5"
  },
  {
    "uniprot": "A0A3P7DPM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPM6"
  },
  {
    "uniprot": "A0A3P7DWL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWL4"
  },
  {
    "uniprot": "A0A3P7EWP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWP1"
  },
  {
    "uniprot": "A0A3P7E0D1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0D1"
  },
  {
    "uniprot": "A0A3P7DWN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWN4"
  },
  {
    "uniprot": "A0A183XV84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XV84"
  },
  {
    "uniprot": "A0A3P7DWT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWT3"
  },
  {
    "uniprot": "J9ERG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERG4"
  },
  {
    "uniprot": "A0A3P7FV37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV37"
  },
  {
    "uniprot": "J9F178",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F178"
  },
  {
    "uniprot": "A0A3P7EPQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPQ4"
  },
  {
    "uniprot": "A0A3P7E0Z8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0Z8"
  },
  {
    "uniprot": "A0A3P7FG42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG42"
  },
  {
    "uniprot": "A0A3P7EE52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE52"
  },
  {
    "uniprot": "A0A3P7FYG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYG9"
  },
  {
    "uniprot": "A0A3P7ET09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET09"
  },
  {
    "uniprot": "J9FAU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FAU0"
  },
  {
    "uniprot": "A0A3P7G541",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G541"
  },
  {
    "uniprot": "A0A3P7FLH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLH1"
  },
  {
    "uniprot": "A0A3P7E623",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E623"
  },
  {
    "uniprot": "A0A3P7E612",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E612"
  },
  {
    "uniprot": "A0A3P7D9Y3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7D9Y3"
  },
  {
    "uniprot": "A0A3P7DX61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX61"
  },
  {
    "uniprot": "A0A3P7EJZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJZ9"
  },
  {
    "uniprot": "A0A3P7FMA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMA0"
  },
  {
    "uniprot": "A0A3P7EAD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAD0"
  },
  {
    "uniprot": "J9B947",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B947"
  },
  {
    "uniprot": "A0A3P7E168",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E168"
  },
  {
    "uniprot": "A0A183XS10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XS10"
  },
  {
    "uniprot": "A0A3P7E0I2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0I2"
  },
  {
    "uniprot": "A0A3P7GCT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCT1"
  },
  {
    "uniprot": "J9F334",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F334"
  },
  {
    "uniprot": "A0A3P7DKY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKY0"
  },
  {
    "uniprot": "A0A3P7E6L2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6L2"
  },
  {
    "uniprot": "A0A3P7E6Q9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6Q9"
  },
  {
    "uniprot": "A0A3P7ED51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED51"
  },
  {
    "uniprot": "A0A183XTW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XTW1"
  },
  {
    "uniprot": "A0A3P7EHD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHD5"
  },
  {
    "uniprot": "A0A3P7FNV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNV7"
  },
  {
    "uniprot": "J9FG77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FG77"
  },
  {
    "uniprot": "A0A3P7E7R9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7R9"
  },
  {
    "uniprot": "A0A3P7DYA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYA5"
  },
  {
    "uniprot": "A0A3P7DWB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWB8"
  },
  {
    "uniprot": "A0A3P7DIX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIX0"
  },
  {
    "uniprot": "A0A3P7E9S9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9S9"
  },
  {
    "uniprot": "A0A3P7GBJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBJ7"
  },
  {
    "uniprot": "A0A3P7ECE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECE2"
  },
  {
    "uniprot": "A0A3P7E2V0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2V0"
  },
  {
    "uniprot": "A0A3P7EE05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE05"
  },
  {
    "uniprot": "J9EWC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EWC3"
  },
  {
    "uniprot": "A0A3P7DYJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYJ1"
  },
  {
    "uniprot": "A0A3P7E0K6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0K6"
  },
  {
    "uniprot": "A0A3P7EGK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGK9"
  },
  {
    "uniprot": "A0A3P7DGF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGF0"
  },
  {
    "uniprot": "A0A3P7FX96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX96"
  },
  {
    "uniprot": "A0A3P7E1X5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1X5"
  },
  {
    "uniprot": "A0A3P7DP52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP52"
  },
  {
    "uniprot": "A0A3P7FCQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCQ0"
  },
  {
    "uniprot": "A0A3P7DWG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWG9"
  },
  {
    "uniprot": "A0A3P7E8Y8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8Y8"
  },
  {
    "uniprot": "A0A3P7DBQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBQ4"
  },
  {
    "uniprot": "A0A3P7F864",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F864"
  },
  {
    "uniprot": "A0A3P7F7V9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7V9"
  },
  {
    "uniprot": "A0A3P7DKK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKK7"
  },
  {
    "uniprot": "A0A3P7FVA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVA2"
  },
  {
    "uniprot": "A0A3P7EZC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZC7"
  },
  {
    "uniprot": "A0A3P7DTA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTA0"
  },
  {
    "uniprot": "A0A3P7F3B0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3B0"
  },
  {
    "uniprot": "A0A3P7ES44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ES44"
  },
  {
    "uniprot": "A0A3P7F3M1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3M1"
  },
  {
    "uniprot": "A0A3P7F2E7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2E7"
  },
  {
    "uniprot": "A0A3P7F9K6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9K6"
  },
  {
    "uniprot": "A0A3P7DV60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV60"
  },
  {
    "uniprot": "A0A3P7FC73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC73"
  },
  {
    "uniprot": "A0A3P7DYW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYW0"
  },
  {
    "uniprot": "A0A3P7FWK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWK8"
  },
  {
    "uniprot": "A0A3P7F248",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F248"
  },
  {
    "uniprot": "A0A3P7DRR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRR0"
  },
  {
    "uniprot": "A0A3P7DTW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTW9"
  },
  {
    "uniprot": "A0A3P7EEM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEM0"
  },
  {
    "uniprot": "A0A3P7ENI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENI4"
  },
  {
    "uniprot": "A0A3P7DQ77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ77"
  },
  {
    "uniprot": "A0A3P7FKB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKB1"
  },
  {
    "uniprot": "A0A3P7FKZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKZ5"
  },
  {
    "uniprot": "A0A3P7EHW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHW1"
  },
  {
    "uniprot": "A0A3P7ECL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECL3"
  },
  {
    "uniprot": "A0A3P7FDT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDT4"
  },
  {
    "uniprot": "A0A3P7DQM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQM4"
  },
  {
    "uniprot": "A0A3P7FFE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFE4"
  },
  {
    "uniprot": "J9FDE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FDE6"
  },
  {
    "uniprot": "J9FM89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FM89"
  },
  {
    "uniprot": "A0A3P7EDE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDE6"
  },
  {
    "uniprot": "A0A3P7E0H5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0H5"
  },
  {
    "uniprot": "A0A3P7ETT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETT9"
  },
  {
    "uniprot": "A0A3P7FHZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHZ8"
  },
  {
    "uniprot": "A0A3P7ELD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELD9"
  },
  {
    "uniprot": "A0A3P7E5V4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5V4"
  },
  {
    "uniprot": "A0A3P7DMK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMK6"
  },
  {
    "uniprot": "A0A3P7DXR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXR3"
  },
  {
    "uniprot": "A0A3P7FBR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBR4"
  },
  {
    "uniprot": "A0A3P7FL77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL77"
  },
  {
    "uniprot": "A0A3P7FJ80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ80"
  },
  {
    "uniprot": "A0A3P7FBF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBF0"
  },
  {
    "uniprot": "J9EI78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EI78"
  },
  {
    "uniprot": "A0A3P7EZE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZE6"
  },
  {
    "uniprot": "A0A3P7E7R1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7R1"
  },
  {
    "uniprot": "A0A3P7DD00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD00"
  },
  {
    "uniprot": "A0A3P7EB22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB22"
  },
  {
    "uniprot": "A0A3P7FX39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX39"
  },
  {
    "uniprot": "J9ENX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ENX7"
  },
  {
    "uniprot": "A0A183XND9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XND9"
  },
  {
    "uniprot": "A0A3P7EAG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAG6"
  },
  {
    "uniprot": "A0A3P7DVT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVT4"
  },
  {
    "uniprot": "A0A3P7EJL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJL9"
  },
  {
    "uniprot": "A0A3P7GEZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEZ1"
  },
  {
    "uniprot": "A0A3P7DPY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPY5"
  },
  {
    "uniprot": "A0A3P7DZC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZC1"
  },
  {
    "uniprot": "A0A3P7FQ67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ67"
  },
  {
    "uniprot": "A0A3P7DMV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMV6"
  },
  {
    "uniprot": "A0A3P7F1U0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1U0"
  },
  {
    "uniprot": "A0A3P7EEE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEE4"
  },
  {
    "uniprot": "A0A3P7DWN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWN6"
  },
  {
    "uniprot": "A0A3P7DXQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXQ5"
  },
  {
    "uniprot": "J9EL01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EL01"
  },
  {
    "uniprot": "A0A3P7FUM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUM0"
  },
  {
    "uniprot": "A0A3P7FB88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB88"
  },
  {
    "uniprot": "A0A183XBH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XBH0"
  },
  {
    "uniprot": "A0A3P7G3J6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3J6"
  },
  {
    "uniprot": "A0A3P7FUK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUK8"
  },
  {
    "uniprot": "A0A3P7DTM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTM4"
  },
  {
    "uniprot": "J9EQK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQK0"
  },
  {
    "uniprot": "J9FFJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FFJ3"
  },
  {
    "uniprot": "A0A3P7DMR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMR0"
  },
  {
    "uniprot": "A0A3P7ECC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECC7"
  },
  {
    "uniprot": "A0A3P7ENK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENK2"
  },
  {
    "uniprot": "J9E913",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E913"
  },
  {
    "uniprot": "A0A3P7E638",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E638"
  },
  {
    "uniprot": "A0A183XLU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLU7"
  },
  {
    "uniprot": "A0A3P7DTW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTW0"
  },
  {
    "uniprot": "J9F2G7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2G7"
  },
  {
    "uniprot": "A0A3P7E4C5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4C5"
  },
  {
    "uniprot": "A0A3P7E038",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E038"
  },
  {
    "uniprot": "A0A3P7E4W3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4W3"
  },
  {
    "uniprot": "A0A3P7DQC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQC7"
  },
  {
    "uniprot": "A0A3P7F2K6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2K6"
  },
  {
    "uniprot": "A0A3P7E3D4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3D4"
  },
  {
    "uniprot": "A0A3P7EGR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGR0"
  },
  {
    "uniprot": "A0A3P7EAI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAI4"
  },
  {
    "uniprot": "A0A3P7G0F7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0F7"
  },
  {
    "uniprot": "A0A3P7EN21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN21"
  },
  {
    "uniprot": "A0A3P7DUG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUG7"
  },
  {
    "uniprot": "A0A3P7EHA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHA5"
  },
  {
    "uniprot": "A0A3P7EYJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYJ3"
  },
  {
    "uniprot": "A0A3P7F9C7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9C7"
  },
  {
    "uniprot": "A0A3P7DQJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQJ5"
  },
  {
    "uniprot": "A0A3P7G9C6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9C6"
  },
  {
    "uniprot": "A0A3P7F1U2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1U2"
  },
  {
    "uniprot": "J9FBC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FBC9"
  },
  {
    "uniprot": "A0A183Y1H8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y1H8"
  },
  {
    "uniprot": "A0A3P7DI23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI23"
  },
  {
    "uniprot": "A0A3P7DYW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYW4"
  },
  {
    "uniprot": "A0A3P7EEP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEP2"
  },
  {
    "uniprot": "A0A3P7ECS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECS9"
  },
  {
    "uniprot": "A0A3P7G1A1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1A1"
  },
  {
    "uniprot": "A0A3P7FTZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTZ8"
  },
  {
    "uniprot": "A0A3P7FB74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB74"
  },
  {
    "uniprot": "A0A3P7ED22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED22"
  },
  {
    "uniprot": "A0A183XTS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XTS0"
  },
  {
    "uniprot": "A0A183XDT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDT9"
  },
  {
    "uniprot": "A0A3P7FTJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTJ6"
  },
  {
    "uniprot": "A0A3P7FYA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYA6"
  },
  {
    "uniprot": "A0A3P7DGD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGD4"
  },
  {
    "uniprot": "J9ESH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ESH1"
  },
  {
    "uniprot": "A0A3P7DT98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT98"
  },
  {
    "uniprot": "A0A3P7DVB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVB6"
  },
  {
    "uniprot": "A0A3P7DDZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDZ8"
  },
  {
    "uniprot": "J9EIA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EIA6"
  },
  {
    "uniprot": "A0A3P7FQ86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ86"
  },
  {
    "uniprot": "J9ERG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERG5"
  },
  {
    "uniprot": "A0A3P7DYD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYD2"
  },
  {
    "uniprot": "A0A3P7FFV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFV2"
  },
  {
    "uniprot": "A0A3P7GM15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GM15"
  },
  {
    "uniprot": "A0A3P7F0W3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0W3"
  },
  {
    "uniprot": "A0A183XSS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XSS8"
  },
  {
    "uniprot": "A0A3P7DRF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRF4"
  },
  {
    "uniprot": "A0A3P7D9W5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7D9W5"
  },
  {
    "uniprot": "J9E792",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E792"
  },
  {
    "uniprot": "A0A3P7DY41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY41"
  },
  {
    "uniprot": "A0A3P7FGB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGB7"
  },
  {
    "uniprot": "A0A3P7E250",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E250"
  },
  {
    "uniprot": "A0A3P7FPA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPA3"
  },
  {
    "uniprot": "J9EJN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EJN1"
  },
  {
    "uniprot": "A0A3P7DVR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVR0"
  },
  {
    "uniprot": "A0A3P7DYI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYI9"
  },
  {
    "uniprot": "A0A3P7DXA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXA9"
  },
  {
    "uniprot": "A0A3P7DX45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX45"
  },
  {
    "uniprot": "A0A3P7DSF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSF4"
  },
  {
    "uniprot": "A0A3P7EUB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUB6"
  },
  {
    "uniprot": "A0A3P7E9E6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9E6"
  },
  {
    "uniprot": "A0A3P7DR47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR47"
  },
  {
    "uniprot": "A0A3P7E7T0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7T0"
  },
  {
    "uniprot": "J9E583",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E583"
  },
  {
    "uniprot": "A0A3P7DFU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFU0"
  },
  {
    "uniprot": "A0A3P7FYD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYD6"
  },
  {
    "uniprot": "A0A3P7GBJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBJ0"
  },
  {
    "uniprot": "A0A3P7FXV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXV5"
  },
  {
    "uniprot": "A0A3P7FYU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYU9"
  },
  {
    "uniprot": "A0A3P7DMS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMS2"
  },
  {
    "uniprot": "A0A3P7GGP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGP1"
  },
  {
    "uniprot": "A0A3P7DZX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZX7"
  },
  {
    "uniprot": "A0A3P7GEA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEA8"
  },
  {
    "uniprot": "A0A3P7DUA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUA1"
  },
  {
    "uniprot": "A0A183XHD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XHD5"
  },
  {
    "uniprot": "A0A3P7DZ73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ73"
  },
  {
    "uniprot": "A0A3P7FP50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP50"
  },
  {
    "uniprot": "A0A3P7ETH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETH5"
  },
  {
    "uniprot": "A0A3P7FWA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWA0"
  },
  {
    "uniprot": "A0A3P7EC44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC44"
  },
  {
    "uniprot": "A0A3P7DT01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT01"
  },
  {
    "uniprot": "A0A183XDF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDF1"
  },
  {
    "uniprot": "A0A3P7DZD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZD3"
  },
  {
    "uniprot": "A0A3P7GMU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GMU0"
  },
  {
    "uniprot": "A0A3P7EG46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG46"
  },
  {
    "uniprot": "J9FL57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FL57"
  },
  {
    "uniprot": "A0A3P7DV27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV27"
  },
  {
    "uniprot": "A0A3P7FTK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTK6"
  },
  {
    "uniprot": "A0A3P7FQ45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ45"
  },
  {
    "uniprot": "J9AKZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AKZ5"
  },
  {
    "uniprot": "A0A3P7DV84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV84"
  },
  {
    "uniprot": "A0A3P7G8V9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8V9"
  },
  {
    "uniprot": "A0A3P7DTD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTD2"
  },
  {
    "uniprot": "A0A3P7FWT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWT8"
  },
  {
    "uniprot": "J9EXJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXJ3"
  },
  {
    "uniprot": "A0A3P7DUU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUU8"
  },
  {
    "uniprot": "A0A3P7G1Z3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1Z3"
  },
  {
    "uniprot": "J9B149",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B149"
  },
  {
    "uniprot": "A0A3P7FC06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC06"
  },
  {
    "uniprot": "A0A3P7EDI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDI7"
  },
  {
    "uniprot": "A0A3P7GJZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJZ3"
  },
  {
    "uniprot": "A0A3P7DFM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFM6"
  },
  {
    "uniprot": "A0A3P7DM90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM90"
  },
  {
    "uniprot": "A0A3P7FP46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP46"
  },
  {
    "uniprot": "A0A3P7E0L1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0L1"
  },
  {
    "uniprot": "A0A3P7GEL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEL0"
  },
  {
    "uniprot": "J9EYI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYI0"
  },
  {
    "uniprot": "A0A3P7FI72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI72"
  },
  {
    "uniprot": "A0A3P7FB59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB59"
  },
  {
    "uniprot": "J9F118",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F118"
  },
  {
    "uniprot": "A0A3P7FZJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZJ5"
  },
  {
    "uniprot": "A0A3P7EIJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIJ7"
  },
  {
    "uniprot": "A0A3P7F8X3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8X3"
  },
  {
    "uniprot": "A0A3P7E9W0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9W0"
  },
  {
    "uniprot": "A0A3P7ETE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETE3"
  },
  {
    "uniprot": "A0A3P7E4J5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4J5"
  },
  {
    "uniprot": "J9EE57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EE57"
  },
  {
    "uniprot": "J9E0D4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E0D4"
  },
  {
    "uniprot": "J9EXX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXX4"
  },
  {
    "uniprot": "A0A3P7ETQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETQ4"
  },
  {
    "uniprot": "A0A3P7EA81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA81"
  },
  {
    "uniprot": "A0A3P7DPM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPM1"
  },
  {
    "uniprot": "A0A3P7ECJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECJ1"
  },
  {
    "uniprot": "A0A3P7FVQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVQ8"
  },
  {
    "uniprot": "A0A3P7E3V0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3V0"
  },
  {
    "uniprot": "J9EIZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EIZ4"
  },
  {
    "uniprot": "A0A3P7GFW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFW5"
  },
  {
    "uniprot": "A0A3P7DEQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEQ8"
  },
  {
    "uniprot": "A0A3P7ELI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELI5"
  },
  {
    "uniprot": "A0A3P7FIM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIM0"
  },
  {
    "uniprot": "A0A3P7FI47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI47"
  },
  {
    "uniprot": "A0A3P7E5H8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5H8"
  },
  {
    "uniprot": "A0A3P7DAL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAL4"
  },
  {
    "uniprot": "A0A3P7F3T4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3T4"
  },
  {
    "uniprot": "A0A3P7FWM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWM6"
  },
  {
    "uniprot": "A0A3P7ECA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECA3"
  },
  {
    "uniprot": "A0A3P7EKU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKU4"
  },
  {
    "uniprot": "A0A3P7F961",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F961"
  },
  {
    "uniprot": "A0A3P7EBA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBA5"
  },
  {
    "uniprot": "A0A3P7FWH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWH7"
  },
  {
    "uniprot": "J9FEY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FEY6"
  },
  {
    "uniprot": "A0A3P7G8G8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8G8"
  },
  {
    "uniprot": "A0A3P7FX27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX27"
  },
  {
    "uniprot": "A0A3P7DUK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUK4"
  },
  {
    "uniprot": "A0A3P7DWJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWJ1"
  },
  {
    "uniprot": "A0A3P7EX03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EX03"
  },
  {
    "uniprot": "A0A3P7FMN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMN1"
  },
  {
    "uniprot": "A0A3P7DPW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPW3"
  },
  {
    "uniprot": "A0A3P7FDY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDY0"
  },
  {
    "uniprot": "A0A3P7GEN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEN7"
  },
  {
    "uniprot": "A0A3P7F0X2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0X2"
  },
  {
    "uniprot": "A0A3P7EBU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBU0"
  },
  {
    "uniprot": "A0A3P7FC15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC15"
  },
  {
    "uniprot": "A0A3P7E187",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E187"
  },
  {
    "uniprot": "A0A3P7DMA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMA1"
  },
  {
    "uniprot": "A0A3P7EDR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDR4"
  },
  {
    "uniprot": "A0A3P7DCC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCC7"
  },
  {
    "uniprot": "A0A3P7EU55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU55"
  },
  {
    "uniprot": "A0A3P7FG15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG15"
  },
  {
    "uniprot": "A0A3P7DL74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL74"
  },
  {
    "uniprot": "A0A3P7E4D4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4D4"
  },
  {
    "uniprot": "J9ET37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ET37"
  },
  {
    "uniprot": "A0A3P7FCX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCX1"
  },
  {
    "uniprot": "A0A3P7DUX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUX5"
  },
  {
    "uniprot": "A0A3P7GA70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GA70"
  },
  {
    "uniprot": "A0A3P7DGY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGY6"
  },
  {
    "uniprot": "A0A3P7G6D5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6D5"
  },
  {
    "uniprot": "A0A3P7FML1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FML1"
  },
  {
    "uniprot": "A0A3P7FPR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPR6"
  },
  {
    "uniprot": "A0A3P7E4B1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4B1"
  },
  {
    "uniprot": "A0A3P7DET5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DET5"
  },
  {
    "uniprot": "A0A3P7DNE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNE7"
  },
  {
    "uniprot": "A0A3P7DIN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIN3"
  },
  {
    "uniprot": "A0A3P7DTQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTQ7"
  },
  {
    "uniprot": "A0A3P7GEK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEK6"
  },
  {
    "uniprot": "J9FMG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FMG6"
  },
  {
    "uniprot": "A0A3P7FFM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFM1"
  },
  {
    "uniprot": "A0A3P7EGP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGP8"
  },
  {
    "uniprot": "A0A3P7DU78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU78"
  },
  {
    "uniprot": "A0A3P7EEG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEG5"
  },
  {
    "uniprot": "A0A3P7DQH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQH7"
  },
  {
    "uniprot": "A0A3P7EDD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDD2"
  },
  {
    "uniprot": "A0A3P7E904",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E904"
  },
  {
    "uniprot": "A0A3P7EU24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU24"
  },
  {
    "uniprot": "A0A3P7EBI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBI6"
  },
  {
    "uniprot": "A0A3P7E5C5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5C5"
  },
  {
    "uniprot": "A0A3P7ELI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELI8"
  },
  {
    "uniprot": "A0A3P7FQG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQG8"
  },
  {
    "uniprot": "A0A183XJ20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XJ20"
  },
  {
    "uniprot": "A0A3P7DGF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGF8"
  },
  {
    "uniprot": "A0A3P7EX98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EX98"
  },
  {
    "uniprot": "A0A3P7E7Z8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7Z8"
  },
  {
    "uniprot": "A0A3P7ESD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESD1"
  },
  {
    "uniprot": "A0A3P7FIF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIF3"
  },
  {
    "uniprot": "A0A3P7ECF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECF8"
  },
  {
    "uniprot": "A0A3P7DSX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSX6"
  },
  {
    "uniprot": "A0A3P7DTG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTG4"
  },
  {
    "uniprot": "J9BJS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BJS9"
  },
  {
    "uniprot": "A0A3P7DWQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWQ7"
  },
  {
    "uniprot": "A0A3P7EK37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK37"
  },
  {
    "uniprot": "A0A3P7E5V9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5V9"
  },
  {
    "uniprot": "J9EPB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPB8"
  },
  {
    "uniprot": "J9FC71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FC71"
  },
  {
    "uniprot": "J9B8V2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B8V2"
  },
  {
    "uniprot": "A0A3P7DPJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPJ7"
  },
  {
    "uniprot": "A0A3P7FRF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRF9"
  },
  {
    "uniprot": "J9EUF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUF0"
  },
  {
    "uniprot": "A0A3P7F9P1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9P1"
  },
  {
    "uniprot": "A0A3P7DDE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDE3"
  },
  {
    "uniprot": "A0A3P7FZY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZY4"
  },
  {
    "uniprot": "A0A3P7FR61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR61"
  },
  {
    "uniprot": "A0A183XMG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XMG8"
  },
  {
    "uniprot": "A0A3P7FDJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDJ9"
  },
  {
    "uniprot": "A0A3P7FXR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXR3"
  },
  {
    "uniprot": "A0A3P7DY44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY44"
  },
  {
    "uniprot": "A0A3P7DZY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZY8"
  },
  {
    "uniprot": "A0A3P7FDW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDW0"
  },
  {
    "uniprot": "A0A3P7DVH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVH2"
  },
  {
    "uniprot": "A0A3P7DNG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNG8"
  },
  {
    "uniprot": "A0A3P7DU34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU34"
  },
  {
    "uniprot": "A0A3P7EE02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE02"
  },
  {
    "uniprot": "A0A3P7E5N7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5N7"
  },
  {
    "uniprot": "J9F2J1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2J1"
  },
  {
    "uniprot": "A0A3P7EVS7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVS7"
  },
  {
    "uniprot": "A0A3P7FQN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQN0"
  },
  {
    "uniprot": "A0A3P7DRI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRI0"
  },
  {
    "uniprot": "A0A3P7EIZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIZ7"
  },
  {
    "uniprot": "A0A3P7DQB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQB4"
  },
  {
    "uniprot": "A0A3P7DZ18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ18"
  },
  {
    "uniprot": "A0A3P7ELP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELP3"
  },
  {
    "uniprot": "A0A3P7E914",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E914"
  },
  {
    "uniprot": "A0A3P7DFM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFM4"
  },
  {
    "uniprot": "A0A3P7G1B3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1B3"
  },
  {
    "uniprot": "J9FBK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FBK4"
  },
  {
    "uniprot": "A0A3P7EX15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EX15"
  },
  {
    "uniprot": "A0A3P7EQ55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQ55"
  },
  {
    "uniprot": "A0A3P7EM53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM53"
  },
  {
    "uniprot": "A0A3P7DQU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQU1"
  },
  {
    "uniprot": "A0A3P7DMW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMW0"
  },
  {
    "uniprot": "A0A3P7G5M4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5M4"
  },
  {
    "uniprot": "A0A3P7FEV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEV0"
  },
  {
    "uniprot": "A0A183XVS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XVS5"
  },
  {
    "uniprot": "A0A3P7DTN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTN5"
  },
  {
    "uniprot": "A0A3P7EJF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJF8"
  },
  {
    "uniprot": "A0A3P7DV01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV01"
  },
  {
    "uniprot": "A0A3P7G0S9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0S9"
  },
  {
    "uniprot": "A0A3P7DM55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM55"
  },
  {
    "uniprot": "A0A3P7E8M0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8M0"
  },
  {
    "uniprot": "A0A3P7ECA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECA6"
  },
  {
    "uniprot": "A0A3P7FN69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN69"
  },
  {
    "uniprot": "A0A3P7FIB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIB6"
  },
  {
    "uniprot": "A0A3P7EIV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIV1"
  },
  {
    "uniprot": "A0A3P7EYN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYN6"
  },
  {
    "uniprot": "A0A3P7EA13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA13"
  },
  {
    "uniprot": "A0A183XQB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XQB1"
  },
  {
    "uniprot": "A0A3P7FI53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI53"
  },
  {
    "uniprot": "A0A3P7FE00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE00"
  },
  {
    "uniprot": "A0A3P7GL57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GL57"
  },
  {
    "uniprot": "A0A3P7DWJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWJ5"
  },
  {
    "uniprot": "A0A3P7E3N5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3N5"
  },
  {
    "uniprot": "A0A3P7DVP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVP9"
  },
  {
    "uniprot": "A0A3P7DWJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWJ7"
  },
  {
    "uniprot": "A0A3P7E2J2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2J2"
  },
  {
    "uniprot": "A0A3P7EG24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG24"
  },
  {
    "uniprot": "A0A3P7DRV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRV4"
  },
  {
    "uniprot": "A0A3P7EKI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKI0"
  },
  {
    "uniprot": "A0A3P7ESC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESC9"
  },
  {
    "uniprot": "A0A3P7DUB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUB3"
  },
  {
    "uniprot": "A0A3P7GCM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCM9"
  },
  {
    "uniprot": "A0A3P7F093",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F093"
  },
  {
    "uniprot": "A0A3P7FD39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD39"
  },
  {
    "uniprot": "J9EYF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYF2"
  },
  {
    "uniprot": "A0A3P7G5W4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5W4"
  },
  {
    "uniprot": "A0A3P7E734",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E734"
  },
  {
    "uniprot": "A0A3P7ELY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELY3"
  },
  {
    "uniprot": "J9EVG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EVG0"
  },
  {
    "uniprot": "A0A3P7FU17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU17"
  },
  {
    "uniprot": "A0A3P7E0Y8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0Y8"
  },
  {
    "uniprot": "A0A3P7DXN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXN6"
  },
  {
    "uniprot": "A0A3P7GK39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GK39"
  },
  {
    "uniprot": "A0A3P7FNG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNG8"
  },
  {
    "uniprot": "A0A3P7DBH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBH6"
  },
  {
    "uniprot": "A0A3P7EJF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJF1"
  },
  {
    "uniprot": "A0A3P7DUG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUG8"
  },
  {
    "uniprot": "A0A3P7DF51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF51"
  },
  {
    "uniprot": "J9F9K3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F9K3"
  },
  {
    "uniprot": "A0A3P7FCU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCU6"
  },
  {
    "uniprot": "A0A3P7E9I2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9I2"
  },
  {
    "uniprot": "A0A3P7FW27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW27"
  },
  {
    "uniprot": "J9EPU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPU8"
  },
  {
    "uniprot": "A0A3P7FTQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTQ6"
  },
  {
    "uniprot": "J9AUG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AUG5"
  },
  {
    "uniprot": "A0A3P7DFS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFS9"
  },
  {
    "uniprot": "J9BKH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BKH7"
  },
  {
    "uniprot": "J9EYI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYI3"
  },
  {
    "uniprot": "A0A3P7DJY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJY0"
  },
  {
    "uniprot": "A0A3P7EBF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBF6"
  },
  {
    "uniprot": "A0A3P7E332",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E332"
  },
  {
    "uniprot": "A0A3P7FFP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFP0"
  },
  {
    "uniprot": "A0A3P7EK84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK84"
  },
  {
    "uniprot": "A0A3P7ELB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELB7"
  },
  {
    "uniprot": "A0A3P7GBV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBV6"
  },
  {
    "uniprot": "A0A3P7DYX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYX3"
  },
  {
    "uniprot": "A0A3P7GHI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHI8"
  },
  {
    "uniprot": "A0A3P7FMY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMY6"
  },
  {
    "uniprot": "A0A3P7DP12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP12"
  },
  {
    "uniprot": "A0A3P7EEE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEE8"
  },
  {
    "uniprot": "A0A3P7DNR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNR6"
  },
  {
    "uniprot": "A0A3P7E1P7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1P7"
  },
  {
    "uniprot": "A0A3P7DDZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDZ7"
  },
  {
    "uniprot": "A0A3P7DRB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRB5"
  },
  {
    "uniprot": "J9EX68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EX68"
  },
  {
    "uniprot": "A0A3P7GGW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGW5"
  },
  {
    "uniprot": "A0A3P7F4Y8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F4Y8"
  },
  {
    "uniprot": "A0A3P7ECN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECN7"
  },
  {
    "uniprot": "J9EYW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYW8"
  },
  {
    "uniprot": "A0A3P7DQC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQC8"
  },
  {
    "uniprot": "A0A3P7E322",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E322"
  },
  {
    "uniprot": "J9FLE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FLE5"
  },
  {
    "uniprot": "A0A3P7E000",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E000"
  },
  {
    "uniprot": "A0A3P7DWT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWT2"
  },
  {
    "uniprot": "A0A3P7DR85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR85"
  },
  {
    "uniprot": "A0A3P7EFN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFN5"
  },
  {
    "uniprot": "J9BAQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BAQ2"
  },
  {
    "uniprot": "A0A3P7EDU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDU6"
  },
  {
    "uniprot": "A0A3P7DEG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEG4"
  },
  {
    "uniprot": "A0A3P7ET99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET99"
  },
  {
    "uniprot": "A0A3P7EJB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJB8"
  },
  {
    "uniprot": "A0A3P7FH71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH71"
  },
  {
    "uniprot": "A0A3P7G8F6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8F6"
  },
  {
    "uniprot": "A0A3P7EFH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFH1"
  },
  {
    "uniprot": "A0A3P7G4P9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4P9"
  },
  {
    "uniprot": "A0A3P7EJW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJW2"
  },
  {
    "uniprot": "A0A3P7E436",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E436"
  },
  {
    "uniprot": "A0A3P7E2Z6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2Z6"
  },
  {
    "uniprot": "A0A3P7EJ98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ98"
  },
  {
    "uniprot": "A0A3P7ERD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERD2"
  },
  {
    "uniprot": "A0A3P7G0M1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0M1"
  },
  {
    "uniprot": "A0A3P7EVT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVT7"
  },
  {
    "uniprot": "A0A3P7E4U0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4U0"
  },
  {
    "uniprot": "A0A3P7FHU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHU2"
  },
  {
    "uniprot": "A0A3P7EM27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM27"
  },
  {
    "uniprot": "A0A3P7FRY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRY4"
  },
  {
    "uniprot": "A0A3P7FPQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPQ5"
  },
  {
    "uniprot": "A0A3P7E128",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E128"
  },
  {
    "uniprot": "A0A3P7GC36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GC36"
  },
  {
    "uniprot": "A0A3P7DQ72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ72"
  },
  {
    "uniprot": "A0A3P7FY96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY96"
  },
  {
    "uniprot": "A0A3P7DF63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF63"
  },
  {
    "uniprot": "A0A3P7EIZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIZ1"
  },
  {
    "uniprot": "J9F137",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F137"
  },
  {
    "uniprot": "A0A3P7EY46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY46"
  },
  {
    "uniprot": "A0A3P7GHP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHP5"
  },
  {
    "uniprot": "A0A3P7DDH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDH9"
  },
  {
    "uniprot": "A0A3P7DPH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPH5"
  },
  {
    "uniprot": "J9EFB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EFB3"
  },
  {
    "uniprot": "A0A3P7DEV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEV7"
  },
  {
    "uniprot": "A0A3P7EF74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF74"
  },
  {
    "uniprot": "A0A183XLH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLH7"
  },
  {
    "uniprot": "A0A3P7DS23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS23"
  },
  {
    "uniprot": "A0A3P7EKF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKF9"
  },
  {
    "uniprot": "A0A3P7FFI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFI0"
  },
  {
    "uniprot": "A0A3P7EAC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAC0"
  },
  {
    "uniprot": "A0A3P7FMS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMS0"
  },
  {
    "uniprot": "A0A3P7FLQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLQ4"
  },
  {
    "uniprot": "J9F1B7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1B7"
  },
  {
    "uniprot": "A0A3P7E3M3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3M3"
  },
  {
    "uniprot": "A0A3P7DUL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUL9"
  },
  {
    "uniprot": "A0A3P7DHU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHU0"
  },
  {
    "uniprot": "A0A3P7F3H4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3H4"
  },
  {
    "uniprot": "A0A3P7ERC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERC2"
  },
  {
    "uniprot": "A0A3P7FLK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLK1"
  },
  {
    "uniprot": "A0A3P7DJJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJJ0"
  },
  {
    "uniprot": "J9E4F3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E4F3"
  },
  {
    "uniprot": "A0A3P7DYR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYR8"
  },
  {
    "uniprot": "A0A3P7DWT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWT1"
  },
  {
    "uniprot": "A0A3P7DJB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJB4"
  },
  {
    "uniprot": "A0A3P7E711",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E711"
  },
  {
    "uniprot": "A0A3P7DXH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXH6"
  },
  {
    "uniprot": "A0A3P7EDY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDY8"
  },
  {
    "uniprot": "A0A3P7E8Z3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8Z3"
  },
  {
    "uniprot": "A0A3P7FJJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJJ9"
  },
  {
    "uniprot": "A0A3P7DR46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR46"
  },
  {
    "uniprot": "J9EFB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EFB2"
  },
  {
    "uniprot": "A0A3P7DA85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DA85"
  },
  {
    "uniprot": "A0A3P7EAV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAV5"
  },
  {
    "uniprot": "A0A3P7E042",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E042"
  },
  {
    "uniprot": "A0A3P7DYH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYH3"
  },
  {
    "uniprot": "A0A3P7EU44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU44"
  },
  {
    "uniprot": "J9BDK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BDK0"
  },
  {
    "uniprot": "A0A3P7DSL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSL2"
  },
  {
    "uniprot": "A0A3P7DGL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGL0"
  },
  {
    "uniprot": "A0A183XY65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XY65"
  },
  {
    "uniprot": "A0A3P7E0Y3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0Y3"
  },
  {
    "uniprot": "A0A3P7FT13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT13"
  },
  {
    "uniprot": "A0A183XPS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XPS1"
  },
  {
    "uniprot": "A0A3P7ECB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECB3"
  },
  {
    "uniprot": "A0A3P7EUL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUL5"
  },
  {
    "uniprot": "J9E5D6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E5D6"
  },
  {
    "uniprot": "J9DW06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DW06"
  },
  {
    "uniprot": "A0A3P7FM89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM89"
  },
  {
    "uniprot": "A0A3P7DX41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX41"
  },
  {
    "uniprot": "J9F4L1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F4L1"
  },
  {
    "uniprot": "A0A3P7EEA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEA8"
  },
  {
    "uniprot": "A0A3P7DZI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZI0"
  },
  {
    "uniprot": "A0A3P7ED82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED82"
  },
  {
    "uniprot": "A0A3P7DTX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTX0"
  },
  {
    "uniprot": "A0A3P7E266",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E266"
  },
  {
    "uniprot": "A0A3P7DT88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT88"
  },
  {
    "uniprot": "A0A3P7DZU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZU0"
  },
  {
    "uniprot": "A0A3P7EL97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL97"
  },
  {
    "uniprot": "A0A3P7FHM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHM5"
  },
  {
    "uniprot": "J9EY00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EY00"
  },
  {
    "uniprot": "J9BHP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BHP2"
  },
  {
    "uniprot": "A0A3P7FBE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBE8"
  },
  {
    "uniprot": "A0A3P7EF11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF11"
  },
  {
    "uniprot": "A0A3P7EPZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPZ7"
  },
  {
    "uniprot": "A0A3P7GLR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLR7"
  },
  {
    "uniprot": "A0A3P7DX50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX50"
  },
  {
    "uniprot": "A0A3P7DLS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLS3"
  },
  {
    "uniprot": "A0A3P7DHC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHC5"
  },
  {
    "uniprot": "A0A3P7FXH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXH3"
  },
  {
    "uniprot": "A0A3P7EZN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZN7"
  },
  {
    "uniprot": "A0A3P7G945",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G945"
  },
  {
    "uniprot": "J9FIN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FIN0"
  },
  {
    "uniprot": "A0A3P7G4H4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G4H4"
  },
  {
    "uniprot": "J9EY92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EY92"
  },
  {
    "uniprot": "A0A3P7EAA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAA7"
  },
  {
    "uniprot": "A0A3P7DSQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSQ3"
  },
  {
    "uniprot": "A0A3P7FT74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT74"
  },
  {
    "uniprot": "A0A3P7EAA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAA6"
  },
  {
    "uniprot": "A0A3P7DJU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJU3"
  },
  {
    "uniprot": "A0A3P7DTP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTP4"
  },
  {
    "uniprot": "A0A3P7DZX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZX6"
  },
  {
    "uniprot": "A0A3P7FSR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSR6"
  },
  {
    "uniprot": "A0A3P7EP16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP16"
  },
  {
    "uniprot": "J9E9L8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E9L8"
  },
  {
    "uniprot": "A0A3P7DG60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG60"
  },
  {
    "uniprot": "A0A3P7G0I6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0I6"
  },
  {
    "uniprot": "A0A3P7E6X3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6X3"
  },
  {
    "uniprot": "A0A3P7DQ55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ55"
  },
  {
    "uniprot": "A0A3P7DM92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM92"
  },
  {
    "uniprot": "A0A3P7E0Y5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0Y5"
  },
  {
    "uniprot": "A0A3P7DHU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHU4"
  },
  {
    "uniprot": "A0A3P7EL68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL68"
  },
  {
    "uniprot": "A0A3P7FTV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTV6"
  },
  {
    "uniprot": "A0A3P7E6H3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6H3"
  },
  {
    "uniprot": "A0A3P7DWD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWD3"
  },
  {
    "uniprot": "J9EUG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUG9"
  },
  {
    "uniprot": "A0A3P7FRX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRX4"
  },
  {
    "uniprot": "J9EQ68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQ68"
  },
  {
    "uniprot": "A0A3P7E374",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E374"
  },
  {
    "uniprot": "A0A3P7FK72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK72"
  },
  {
    "uniprot": "A0A3P7ED49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED49"
  },
  {
    "uniprot": "A0A3P7E2F5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2F5"
  },
  {
    "uniprot": "J9EYN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYN8"
  },
  {
    "uniprot": "A0A3P7DTW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTW3"
  },
  {
    "uniprot": "A0A3P7DSC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSC3"
  },
  {
    "uniprot": "A0A3P7E3F3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3F3"
  },
  {
    "uniprot": "A0A3P7E607",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E607"
  },
  {
    "uniprot": "A0A3P7DKF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKF7"
  },
  {
    "uniprot": "A0A3P7DWE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWE0"
  },
  {
    "uniprot": "A0A3P7EXL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXL5"
  },
  {
    "uniprot": "A0A3P7EHN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHN4"
  },
  {
    "uniprot": "A0A3P7GDF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDF8"
  },
  {
    "uniprot": "A0A3P7DE44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE44"
  },
  {
    "uniprot": "A0A3P7DZ93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ93"
  },
  {
    "uniprot": "A0A3P7DKA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKA2"
  },
  {
    "uniprot": "A0A3P7G5M5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5M5"
  },
  {
    "uniprot": "A0A3P7E2K3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2K3"
  },
  {
    "uniprot": "A0A3P7ECX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECX7"
  },
  {
    "uniprot": "A0A3P7FDT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDT0"
  },
  {
    "uniprot": "J9EZH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZH3"
  },
  {
    "uniprot": "J9ECQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ECQ6"
  },
  {
    "uniprot": "A0A3P7ELB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELB6"
  },
  {
    "uniprot": "A0A3P7E103",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E103"
  },
  {
    "uniprot": "A0A3P7ERD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERD4"
  },
  {
    "uniprot": "A0A3P7FXM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXM0"
  },
  {
    "uniprot": "A0A3P7DS20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS20"
  },
  {
    "uniprot": "A0A3P7EYK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYK7"
  },
  {
    "uniprot": "A0A3P7ED79",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED79"
  },
  {
    "uniprot": "J9F022",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F022"
  },
  {
    "uniprot": "A0A3P7DZ65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ65"
  },
  {
    "uniprot": "A0A3P7FJW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJW3"
  },
  {
    "uniprot": "J9B6P8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B6P8"
  },
  {
    "uniprot": "A0A3P7FLI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLI1"
  },
  {
    "uniprot": "A0A3P7EDI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDI6"
  },
  {
    "uniprot": "A0A3P7DR66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR66"
  },
  {
    "uniprot": "A0A3P7EUE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUE6"
  },
  {
    "uniprot": "A0A3P7DFV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFV9"
  },
  {
    "uniprot": "A0A3P7EBK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBK3"
  },
  {
    "uniprot": "A0A3P7DUJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUJ6"
  },
  {
    "uniprot": "A0A3P7E645",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E645"
  },
  {
    "uniprot": "A0A3P7DFW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFW7"
  },
  {
    "uniprot": "J9EQ51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQ51"
  },
  {
    "uniprot": "A0A3P7E0V6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0V6"
  },
  {
    "uniprot": "A0A3P7ENC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENC4"
  },
  {
    "uniprot": "A0A3P7F7Y2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7Y2"
  },
  {
    "uniprot": "A0A3P7EJ81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ81"
  },
  {
    "uniprot": "A0A3P7GJ31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJ31"
  },
  {
    "uniprot": "A0A3P7GM85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GM85"
  },
  {
    "uniprot": "J9AUW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AUW9"
  },
  {
    "uniprot": "A0A3P7DWR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWR6"
  },
  {
    "uniprot": "A0A3P7FXC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXC0"
  },
  {
    "uniprot": "A0A3P7E3M8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3M8"
  },
  {
    "uniprot": "J9FEU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FEU3"
  },
  {
    "uniprot": "A0A3P7FIM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIM8"
  },
  {
    "uniprot": "A0A3P7DJB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJB0"
  },
  {
    "uniprot": "A0A3P7DS63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS63"
  },
  {
    "uniprot": "A0A3P7FL62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL62"
  },
  {
    "uniprot": "J9ESW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ESW6"
  },
  {
    "uniprot": "A0A3P7F2T1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2T1"
  },
  {
    "uniprot": "J9EQ92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQ92"
  },
  {
    "uniprot": "A0A3P7DWU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWU8"
  },
  {
    "uniprot": "A0A3P7DSR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSR1"
  },
  {
    "uniprot": "A0A3P7EFB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFB4"
  },
  {
    "uniprot": "A0A3P7DHE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHE6"
  },
  {
    "uniprot": "J9F042",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F042"
  },
  {
    "uniprot": "A0A3P7EPD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPD2"
  },
  {
    "uniprot": "A0A3P7GEA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEA1"
  },
  {
    "uniprot": "A0A3P7E3F4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3F4"
  },
  {
    "uniprot": "J9BNC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BNC8"
  },
  {
    "uniprot": "A0A3P7GDB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDB2"
  },
  {
    "uniprot": "A0A3P7DVT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVT0"
  },
  {
    "uniprot": "A0A3P7E399",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E399"
  },
  {
    "uniprot": "A0A3P7DEZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEZ8"
  },
  {
    "uniprot": "A0A3P7E6T7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6T7"
  },
  {
    "uniprot": "A0A3P7E2R4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2R4"
  },
  {
    "uniprot": "A0A3P7DU54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU54"
  },
  {
    "uniprot": "A0A3P7EX42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EX42"
  },
  {
    "uniprot": "A0A3P7EAJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAJ2"
  },
  {
    "uniprot": "A0A3P7EP74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP74"
  },
  {
    "uniprot": "A0A3P7GC33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GC33"
  },
  {
    "uniprot": "A0A3P7EFH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFH3"
  },
  {
    "uniprot": "A0A3P7EU47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU47"
  },
  {
    "uniprot": "A0A3P7FBY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBY7"
  },
  {
    "uniprot": "A0A3P7G0P4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0P4"
  },
  {
    "uniprot": "A0A3P7DK66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK66"
  },
  {
    "uniprot": "A0A3P7DKG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKG3"
  },
  {
    "uniprot": "A0A3P7EJI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJI9"
  },
  {
    "uniprot": "A0A3P7EKW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKW4"
  },
  {
    "uniprot": "J9EMR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EMR5"
  },
  {
    "uniprot": "A0A3P7EJF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJF9"
  },
  {
    "uniprot": "A0A3P7EYV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYV2"
  },
  {
    "uniprot": "A0A3P7DWM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWM5"
  },
  {
    "uniprot": "A0A3P7FMH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMH1"
  },
  {
    "uniprot": "A0A3P7EY74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY74"
  },
  {
    "uniprot": "A0A3P7GB18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GB18"
  },
  {
    "uniprot": "A0A3P7DLU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLU3"
  },
  {
    "uniprot": "A0A3P7E8L3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8L3"
  },
  {
    "uniprot": "J9E2K5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E2K5"
  },
  {
    "uniprot": "A0A3P7DEX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEX3"
  },
  {
    "uniprot": "A0A3P7FB61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB61"
  },
  {
    "uniprot": "A0A3P7FYC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYC6"
  },
  {
    "uniprot": "A0A3P7EF51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF51"
  },
  {
    "uniprot": "A0A3P7DNM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNM4"
  },
  {
    "uniprot": "A0A3P7DHT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHT1"
  },
  {
    "uniprot": "A0A3P7DZD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZD1"
  },
  {
    "uniprot": "A0A3P7EEP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEP6"
  },
  {
    "uniprot": "A0A3P7DB86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DB86"
  },
  {
    "uniprot": "A0A3P7DS02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS02"
  },
  {
    "uniprot": "A0A3P7DS12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS12"
  },
  {
    "uniprot": "A0A3P7DUK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUK2"
  },
  {
    "uniprot": "A0A3P7E3P4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3P4"
  },
  {
    "uniprot": "A0A3P7FCS4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCS4"
  },
  {
    "uniprot": "A0A3P7DML6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DML6"
  },
  {
    "uniprot": "A0A183XST6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XST6"
  },
  {
    "uniprot": "A0A3P7E143",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E143"
  },
  {
    "uniprot": "A0A183XBH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XBH9"
  },
  {
    "uniprot": "A0A3P7FUJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUJ7"
  },
  {
    "uniprot": "A0A3P7GLV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLV6"
  },
  {
    "uniprot": "A0A3P7DB65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DB65"
  },
  {
    "uniprot": "A0A3P7GAD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAD8"
  },
  {
    "uniprot": "J9BCU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BCU3"
  },
  {
    "uniprot": "A0A3P7EKM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKM5"
  },
  {
    "uniprot": "A0A3P7DER7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DER7"
  },
  {
    "uniprot": "J9EEN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EEN3"
  },
  {
    "uniprot": "A0A3P7E0W5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0W5"
  },
  {
    "uniprot": "J9EKJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EKJ7"
  },
  {
    "uniprot": "A0A3P7DYG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYG5"
  },
  {
    "uniprot": "A0A3P7DFE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFE1"
  },
  {
    "uniprot": "A0A3P7DX98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX98"
  },
  {
    "uniprot": "A0A3P7EKY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKY2"
  },
  {
    "uniprot": "A0A3P7GG95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GG95"
  },
  {
    "uniprot": "A0A3P7E5F3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5F3"
  },
  {
    "uniprot": "A0A3P7EHX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHX6"
  },
  {
    "uniprot": "A0A3P7GDM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDM2"
  },
  {
    "uniprot": "A0A3P7EIA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIA4"
  },
  {
    "uniprot": "A0A3P7DXL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXL8"
  },
  {
    "uniprot": "A0A3P7F2X1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2X1"
  },
  {
    "uniprot": "A0A3P7G747",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G747"
  },
  {
    "uniprot": "J9F677",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F677"
  },
  {
    "uniprot": "A0A3P7DTD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTD7"
  },
  {
    "uniprot": "A0A3P7DU65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU65"
  },
  {
    "uniprot": "A0A3P7EEZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEZ5"
  },
  {
    "uniprot": "A0A3P7E2K1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2K1"
  },
  {
    "uniprot": "A0A3P7DV19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV19"
  },
  {
    "uniprot": "A0A3P7EEL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEL6"
  },
  {
    "uniprot": "A0A3P7DUG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUG1"
  },
  {
    "uniprot": "A0A3P7G8W4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8W4"
  },
  {
    "uniprot": "A0A3P7G5U0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5U0"
  },
  {
    "uniprot": "A0A3P7FQS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQS2"
  },
  {
    "uniprot": "J9FBI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FBI9"
  },
  {
    "uniprot": "J9ETL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ETL6"
  },
  {
    "uniprot": "A0A3P7DLN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLN2"
  },
  {
    "uniprot": "A0A3P7E8N1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8N1"
  },
  {
    "uniprot": "A0A3P7ECT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECT5"
  },
  {
    "uniprot": "J9ECU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ECU9"
  },
  {
    "uniprot": "A0A3P7E2X3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2X3"
  },
  {
    "uniprot": "A0A3P7DTL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTL5"
  },
  {
    "uniprot": "A0A3P7G5J2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5J2"
  },
  {
    "uniprot": "A0A3P7DMH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMH7"
  },
  {
    "uniprot": "A0A3P7GGS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGS0"
  },
  {
    "uniprot": "A0A3P7FQF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQF3"
  },
  {
    "uniprot": "A0A3P7EV87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EV87"
  },
  {
    "uniprot": "A0A3P7EHS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHS5"
  },
  {
    "uniprot": "A0A3P7F258",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F258"
  },
  {
    "uniprot": "J9E2L9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E2L9"
  },
  {
    "uniprot": "A0A183XPY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XPY6"
  },
  {
    "uniprot": "A0A3P7EC47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC47"
  },
  {
    "uniprot": "A0A3P7GBT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBT9"
  },
  {
    "uniprot": "A0A3P7FLS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLS8"
  },
  {
    "uniprot": "A0A3P7GNN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GNN0"
  },
  {
    "uniprot": "A0A3P7G3N6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3N6"
  },
  {
    "uniprot": "A0A3P7DYE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYE3"
  },
  {
    "uniprot": "A0A3P7E3R6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3R6"
  },
  {
    "uniprot": "A0A3P7DYU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYU4"
  },
  {
    "uniprot": "A0A3P7DN95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN95"
  },
  {
    "uniprot": "A0A3P7F0V2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0V2"
  },
  {
    "uniprot": "A0A3P7FBZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBZ3"
  },
  {
    "uniprot": "A0A3P7ESH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESH4"
  },
  {
    "uniprot": "A0A3P7GA46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GA46"
  },
  {
    "uniprot": "A0A3P7FQI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQI8"
  },
  {
    "uniprot": "A0A3P7EE98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE98"
  },
  {
    "uniprot": "A0A3P7DW19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW19"
  },
  {
    "uniprot": "A0A3P7EAX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAX4"
  },
  {
    "uniprot": "J9F7D9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F7D9"
  },
  {
    "uniprot": "A0A3P7FCM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCM5"
  },
  {
    "uniprot": "A0A3P7E861",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E861"
  },
  {
    "uniprot": "J9EFM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EFM7"
  },
  {
    "uniprot": "A0A3P7DY70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY70"
  },
  {
    "uniprot": "A0A3P7E075",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E075"
  },
  {
    "uniprot": "A0A3P7E8J6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8J6"
  },
  {
    "uniprot": "A0A3P7DQ66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ66"
  },
  {
    "uniprot": "A0A3P7G2L6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2L6"
  },
  {
    "uniprot": "A0A3P7FKU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKU1"
  },
  {
    "uniprot": "A0A3P7DC95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC95"
  },
  {
    "uniprot": "A0A3P7FWS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWS6"
  },
  {
    "uniprot": "A0A3P7ENR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENR0"
  },
  {
    "uniprot": "A0A3P7G1G3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1G3"
  },
  {
    "uniprot": "A0A3P7EJD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJD8"
  },
  {
    "uniprot": "A0A3P7EVD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVD5"
  },
  {
    "uniprot": "A0A3P7FU53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU53"
  },
  {
    "uniprot": "A0A3P7E3X1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3X1"
  },
  {
    "uniprot": "A0A3P7ENI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENI6"
  },
  {
    "uniprot": "A0A3P7ES75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ES75"
  },
  {
    "uniprot": "A0A3P7DKT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKT6"
  },
  {
    "uniprot": "A0A3P7G0T6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0T6"
  },
  {
    "uniprot": "A0A3P7E2C3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2C3"
  },
  {
    "uniprot": "A0A3P7DID1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DID1"
  },
  {
    "uniprot": "A0A3P7E967",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E967"
  },
  {
    "uniprot": "A0A3P7DSB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSB2"
  },
  {
    "uniprot": "A0A3P7EFP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFP4"
  },
  {
    "uniprot": "J9EPH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPH2"
  },
  {
    "uniprot": "A0A3P7FI74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI74"
  },
  {
    "uniprot": "A0A3P7FDC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDC7"
  },
  {
    "uniprot": "A0A3P7E3K5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3K5"
  },
  {
    "uniprot": "J9FL74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FL74"
  },
  {
    "uniprot": "A0A3P7G174",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G174"
  },
  {
    "uniprot": "J9EUW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUW1"
  },
  {
    "uniprot": "A0A3P7FWI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWI4"
  },
  {
    "uniprot": "J9FD73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FD73"
  },
  {
    "uniprot": "A0A3P7E5M3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5M3"
  },
  {
    "uniprot": "A0A3P7DVE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVE5"
  },
  {
    "uniprot": "A0A3P7EDF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDF9"
  },
  {
    "uniprot": "A0A3P7EKB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKB2"
  },
  {
    "uniprot": "A0A183XQT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XQT1"
  },
  {
    "uniprot": "J9EZW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZW9"
  },
  {
    "uniprot": "A0A3P7FTD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTD5"
  },
  {
    "uniprot": "A0A3P7E3L4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3L4"
  },
  {
    "uniprot": "A0A3P7E8P5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8P5"
  },
  {
    "uniprot": "A0A3P7EYG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYG7"
  },
  {
    "uniprot": "A0A3P7FBI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBI3"
  },
  {
    "uniprot": "A0A3P7EDD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDD3"
  },
  {
    "uniprot": "J9EBM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EBM2"
  },
  {
    "uniprot": "A0A3P7DVH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVH7"
  },
  {
    "uniprot": "A0A3P7DZV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZV3"
  },
  {
    "uniprot": "A0A3P7DW95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW95"
  },
  {
    "uniprot": "J9AN03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AN03"
  },
  {
    "uniprot": "A0A3P7DTX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTX2"
  },
  {
    "uniprot": "A0A3P7DX02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX02"
  },
  {
    "uniprot": "A0A3P7DX95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX95"
  },
  {
    "uniprot": "A0A3P7DBL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBL8"
  },
  {
    "uniprot": "A0A3P7FVU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVU2"
  },
  {
    "uniprot": "A0A3P7DVZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVZ9"
  },
  {
    "uniprot": "A0A3P7FJL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJL4"
  },
  {
    "uniprot": "A0A3P7GCT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCT7"
  },
  {
    "uniprot": "A0A3P7GMH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GMH2"
  },
  {
    "uniprot": "A0A3P7FNS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNS0"
  },
  {
    "uniprot": "A0A3P7DWA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWA4"
  },
  {
    "uniprot": "A0A3P7FXS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXS5"
  },
  {
    "uniprot": "A0A3P7EGF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGF3"
  },
  {
    "uniprot": "A0A3P7FJL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJL1"
  },
  {
    "uniprot": "A0A3P7EIG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIG4"
  },
  {
    "uniprot": "A0A3P7FSD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSD7"
  },
  {
    "uniprot": "A0A3P7DLM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLM2"
  },
  {
    "uniprot": "A0A3P7F3B5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3B5"
  },
  {
    "uniprot": "A0A3P7DXG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXG7"
  },
  {
    "uniprot": "A0A3P7E7G3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7G3"
  },
  {
    "uniprot": "A0A3P7DCB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCB8"
  },
  {
    "uniprot": "A0A3P7DVU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVU0"
  },
  {
    "uniprot": "A0A3P7GIN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIN6"
  },
  {
    "uniprot": "A0A3P7EBM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBM7"
  },
  {
    "uniprot": "A0A3P7EEB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEB6"
  },
  {
    "uniprot": "A0A3P7DNE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNE8"
  },
  {
    "uniprot": "A0A3P7DN36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN36"
  },
  {
    "uniprot": "A0A3P7FN06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN06"
  },
  {
    "uniprot": "A0A3P7FL66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL66"
  },
  {
    "uniprot": "A0A3P7DVT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVT3"
  },
  {
    "uniprot": "A0A3P7DZR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZR2"
  },
  {
    "uniprot": "A0A3P7FZ31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ31"
  },
  {
    "uniprot": "A0A3P7DX26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX26"
  },
  {
    "uniprot": "A0A3P7E4N2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4N2"
  },
  {
    "uniprot": "A0A3P7EKT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKT4"
  },
  {
    "uniprot": "J9EZS4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZS4"
  },
  {
    "uniprot": "A0A3P7E3J6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3J6"
  },
  {
    "uniprot": "A0A3P7E658",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E658"
  },
  {
    "uniprot": "A0A3P7FWE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWE6"
  },
  {
    "uniprot": "A0A3P7E8G8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8G8"
  },
  {
    "uniprot": "J9F078",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F078"
  },
  {
    "uniprot": "A0A3P7EUD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUD7"
  },
  {
    "uniprot": "A0A3P7DPI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPI4"
  },
  {
    "uniprot": "A0A3P7DVM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVM4"
  },
  {
    "uniprot": "A0A3P7FZ16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ16"
  },
  {
    "uniprot": "A0A3P7E5U7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5U7"
  },
  {
    "uniprot": "J9FGY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FGY1"
  },
  {
    "uniprot": "A0A3P7DS67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS67"
  },
  {
    "uniprot": "A0A3P7EFU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFU6"
  },
  {
    "uniprot": "A0A3P7E865",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E865"
  },
  {
    "uniprot": "A0A3P7DNC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNC7"
  },
  {
    "uniprot": "A0A3P7FBF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBF6"
  },
  {
    "uniprot": "A0A3P7G5K6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5K6"
  },
  {
    "uniprot": "A0A3P7FNU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNU1"
  },
  {
    "uniprot": "A0A3P7FX66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX66"
  },
  {
    "uniprot": "A0A3P7GEX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEX9"
  },
  {
    "uniprot": "A0A3P7F3I5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3I5"
  },
  {
    "uniprot": "A0A3P7ELH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELH3"
  },
  {
    "uniprot": "A0A183XJP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XJP3"
  },
  {
    "uniprot": "A0A3P7ED84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED84"
  },
  {
    "uniprot": "J9ETE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ETE0"
  },
  {
    "uniprot": "A0A183XGJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XGJ0"
  },
  {
    "uniprot": "A0A3P7GN22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GN22"
  },
  {
    "uniprot": "A0A3P7E7D6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7D6"
  },
  {
    "uniprot": "A0A3P7EDV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDV4"
  },
  {
    "uniprot": "A0A3P7DU63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU63"
  },
  {
    "uniprot": "A0A3P7E9P9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9P9"
  },
  {
    "uniprot": "A0A3P7DN75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN75"
  },
  {
    "uniprot": "A0A183XLN9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLN9"
  },
  {
    "uniprot": "J9EXE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXE0"
  },
  {
    "uniprot": "A0A3P7DP78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP78"
  },
  {
    "uniprot": "A0A3P7G1Z7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1Z7"
  },
  {
    "uniprot": "A0A3P7EMX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMX5"
  },
  {
    "uniprot": "A0A3P7DWQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWQ8"
  },
  {
    "uniprot": "A0A3P7E8I0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8I0"
  },
  {
    "uniprot": "A0A3P7DTW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTW8"
  },
  {
    "uniprot": "A0A3P7DHN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHN6"
  },
  {
    "uniprot": "A0A3P7G9S0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9S0"
  },
  {
    "uniprot": "A0A3P7EQS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQS8"
  },
  {
    "uniprot": "A0A3P7DUN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUN1"
  },
  {
    "uniprot": "A0A3P7DUN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUN5"
  },
  {
    "uniprot": "A0A3P7DUU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUU6"
  },
  {
    "uniprot": "A0A3P7EM05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM05"
  },
  {
    "uniprot": "A0A3P7DUH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUH6"
  },
  {
    "uniprot": "A0A3P7E6L8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6L8"
  },
  {
    "uniprot": "A0A3P7GL81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GL81"
  },
  {
    "uniprot": "A0A3P7GH50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GH50"
  },
  {
    "uniprot": "A0A3P7DW09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW09"
  },
  {
    "uniprot": "A0A3P7E086",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E086"
  },
  {
    "uniprot": "A0A3P7DYS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYS1"
  },
  {
    "uniprot": "A0A3P7EF03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF03"
  },
  {
    "uniprot": "A0A3P7EYA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYA8"
  },
  {
    "uniprot": "A0A3P7E3K7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3K7"
  },
  {
    "uniprot": "A0A3P7DP42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP42"
  },
  {
    "uniprot": "A0A3P7DL76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL76"
  },
  {
    "uniprot": "A0A3P7DQ05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ05"
  },
  {
    "uniprot": "A0A3P7E733",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E733"
  },
  {
    "uniprot": "A0A3P7DJJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJJ7"
  },
  {
    "uniprot": "A0A3P7G5L5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5L5"
  },
  {
    "uniprot": "A0A3P7G424",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G424"
  },
  {
    "uniprot": "A0A3P7EHG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHG3"
  },
  {
    "uniprot": "A0A3P7FV12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV12"
  },
  {
    "uniprot": "A0A3P7E3T2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3T2"
  },
  {
    "uniprot": "J9E0N7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E0N7"
  },
  {
    "uniprot": "A0A3P7DES6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DES6"
  },
  {
    "uniprot": "A0A3P7G6J8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6J8"
  },
  {
    "uniprot": "A0A3P7GBH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBH8"
  },
  {
    "uniprot": "A0A3P7DTN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTN3"
  },
  {
    "uniprot": "A0A3P7DQY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQY7"
  },
  {
    "uniprot": "A0A3P7FFC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFC6"
  },
  {
    "uniprot": "A0A3P7E950",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E950"
  },
  {
    "uniprot": "J9EVT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EVT1"
  },
  {
    "uniprot": "A0A3P7DZ53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ53"
  },
  {
    "uniprot": "A0A3P7DHJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHJ1"
  },
  {
    "uniprot": "A0A3P7F2T6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2T6"
  },
  {
    "uniprot": "A0A3P7E9X4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9X4"
  },
  {
    "uniprot": "A0A3P7FSG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSG5"
  },
  {
    "uniprot": "A0A3P7FUL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUL5"
  },
  {
    "uniprot": "A0A3P7DX79",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX79"
  },
  {
    "uniprot": "A0A3P7DEA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEA5"
  },
  {
    "uniprot": "A0A3P7GCS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCS3"
  },
  {
    "uniprot": "A0A3P7FV17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV17"
  },
  {
    "uniprot": "A0A3P7DFW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFW4"
  },
  {
    "uniprot": "A0A3P7FU34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU34"
  },
  {
    "uniprot": "A0A3P7FBR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBR2"
  },
  {
    "uniprot": "A0A3P7FWZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWZ0"
  },
  {
    "uniprot": "A0A3P7FNS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNS1"
  },
  {
    "uniprot": "A0A3P7GHL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHL7"
  },
  {
    "uniprot": "A0A3P7EMB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMB4"
  },
  {
    "uniprot": "J9F5D8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F5D8"
  },
  {
    "uniprot": "A0A3P7EEQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEQ2"
  },
  {
    "uniprot": "J9FIE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FIE9"
  },
  {
    "uniprot": "A0A3P7GA93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GA93"
  },
  {
    "uniprot": "A0A3P7E825",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E825"
  },
  {
    "uniprot": "A0A3P7DUE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUE3"
  },
  {
    "uniprot": "A0A3P7DLU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLU6"
  },
  {
    "uniprot": "A0A3P7ETI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETI1"
  },
  {
    "uniprot": "A0A3P7E6K2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6K2"
  },
  {
    "uniprot": "J9EGR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EGR0"
  },
  {
    "uniprot": "A0A3P7DY55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY55"
  },
  {
    "uniprot": "A0A3P7DXP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXP3"
  },
  {
    "uniprot": "A0A3P7G621",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G621"
  },
  {
    "uniprot": "A0A3P7EBH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBH4"
  },
  {
    "uniprot": "A0A3P7DTC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTC0"
  },
  {
    "uniprot": "J9BH62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BH62"
  },
  {
    "uniprot": "A0A3P7EA35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA35"
  },
  {
    "uniprot": "A0A3P7GD93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GD93"
  },
  {
    "uniprot": "A0A3P7EC09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC09"
  },
  {
    "uniprot": "A0A3P7FRK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRK5"
  },
  {
    "uniprot": "A0A3P7EEI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEI6"
  },
  {
    "uniprot": "A0A3P7FEM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEM4"
  },
  {
    "uniprot": "A0A3P7ECX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECX1"
  },
  {
    "uniprot": "A0A3P7FC55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC55"
  },
  {
    "uniprot": "A0A3P7GCV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCV2"
  },
  {
    "uniprot": "A0A3P7DQN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQN6"
  },
  {
    "uniprot": "J9B3M3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B3M3"
  },
  {
    "uniprot": "A0A3P7DSK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSK5"
  },
  {
    "uniprot": "A0A3P7FPY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPY2"
  },
  {
    "uniprot": "A0A3P7ETF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETF8"
  },
  {
    "uniprot": "J9E2B8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E2B8"
  },
  {
    "uniprot": "J9F7F8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F7F8"
  },
  {
    "uniprot": "A0A3P7EIU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIU9"
  },
  {
    "uniprot": "A0A3P7EDL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDL9"
  },
  {
    "uniprot": "A0A3P7DT34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT34"
  },
  {
    "uniprot": "A0A3P7DYA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYA6"
  },
  {
    "uniprot": "A0A3P7FQQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQQ6"
  },
  {
    "uniprot": "A0A3P7FMD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMD6"
  },
  {
    "uniprot": "A0A3P7DP71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP71"
  },
  {
    "uniprot": "A0A3P7DXX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXX0"
  },
  {
    "uniprot": "A0A3P7E3F1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3F1"
  },
  {
    "uniprot": "A0A3P7EHM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHM0"
  },
  {
    "uniprot": "A0A3P7FC19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC19"
  },
  {
    "uniprot": "A0A3P7G115",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G115"
  },
  {
    "uniprot": "A0A3P7FQP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQP0"
  },
  {
    "uniprot": "A0A3P7EPS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPS3"
  },
  {
    "uniprot": "A0A3P7E7Q7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7Q7"
  },
  {
    "uniprot": "A0A3P7ECG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECG7"
  },
  {
    "uniprot": "A0A3P7GJH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJH8"
  },
  {
    "uniprot": "A0A3P7FN37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN37"
  },
  {
    "uniprot": "A0A3P7DY89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY89"
  },
  {
    "uniprot": "A0A3P7EES3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EES3"
  },
  {
    "uniprot": "A0A3P7GLS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLS5"
  },
  {
    "uniprot": "A0A3P7FXE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXE0"
  },
  {
    "uniprot": "A0A3P7DYU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYU2"
  },
  {
    "uniprot": "J9EVK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EVK0"
  },
  {
    "uniprot": "A0A3P7DRY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRY2"
  },
  {
    "uniprot": "A0A3P7F9K4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9K4"
  },
  {
    "uniprot": "A0A3P7E619",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E619"
  },
  {
    "uniprot": "A0A3P7E9J4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9J4"
  },
  {
    "uniprot": "A0A3P7F4T1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F4T1"
  },
  {
    "uniprot": "A0A3P7DYG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYG8"
  },
  {
    "uniprot": "A0A3P7E6A9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6A9"
  },
  {
    "uniprot": "A0A3P7E5J1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5J1"
  },
  {
    "uniprot": "J9BK77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BK77"
  },
  {
    "uniprot": "A0A3P7DRT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRT9"
  },
  {
    "uniprot": "A0A3P7EGQ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGQ3"
  },
  {
    "uniprot": "A0A3P7EEK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEK5"
  },
  {
    "uniprot": "A0A3P7DRE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRE8"
  },
  {
    "uniprot": "A0A3P7G5R3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5R3"
  },
  {
    "uniprot": "A0A3P7EEF2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEF2"
  },
  {
    "uniprot": "J9F2T7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2T7"
  },
  {
    "uniprot": "A0A3P7E771",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E771"
  },
  {
    "uniprot": "A0A3P7EJI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJI4"
  },
  {
    "uniprot": "A0A3P7DLA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLA3"
  },
  {
    "uniprot": "A0A3P7ECA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECA5"
  },
  {
    "uniprot": "A0A3P7E1M6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1M6"
  },
  {
    "uniprot": "A0A3P7E9Z8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9Z8"
  },
  {
    "uniprot": "A0A3P7DT15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT15"
  },
  {
    "uniprot": "A0A3P7ENP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENP4"
  },
  {
    "uniprot": "A0A3P7FNC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNC0"
  },
  {
    "uniprot": "A0A3P7EJR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJR7"
  },
  {
    "uniprot": "A0A3P7E8M7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8M7"
  },
  {
    "uniprot": "A0A3P7DQ95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ95"
  },
  {
    "uniprot": "A0A3P7FV77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV77"
  },
  {
    "uniprot": "A0A3P7ESU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESU8"
  },
  {
    "uniprot": "A0A3P7DCW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCW1"
  },
  {
    "uniprot": "A0A3P7F1T5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1T5"
  },
  {
    "uniprot": "A0A3P7GFQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFQ0"
  },
  {
    "uniprot": "A0A3P7EW65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EW65"
  },
  {
    "uniprot": "A0A3P7FF09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF09"
  },
  {
    "uniprot": "A0A3P7FFB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFB2"
  },
  {
    "uniprot": "A0A3P7EEY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEY0"
  },
  {
    "uniprot": "A0A3P7DX55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX55"
  },
  {
    "uniprot": "J9ED45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ED45"
  },
  {
    "uniprot": "A0A3P7EE83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE83"
  },
  {
    "uniprot": "A0A3P7FFT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFT9"
  },
  {
    "uniprot": "A0A3P7EGZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGZ2"
  },
  {
    "uniprot": "A0A3P7E8N0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8N0"
  },
  {
    "uniprot": "A0A3P7FQ04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ04"
  },
  {
    "uniprot": "A0A3P7ED72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED72"
  },
  {
    "uniprot": "A0A3P7ESS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESS1"
  },
  {
    "uniprot": "A0A3P7FDM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDM7"
  },
  {
    "uniprot": "A0A3P7FVA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVA4"
  },
  {
    "uniprot": "A0A3P7E2T9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2T9"
  },
  {
    "uniprot": "A0A183XIE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XIE6"
  },
  {
    "uniprot": "A0A3P7F9S6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9S6"
  },
  {
    "uniprot": "A0A3P7EJJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJJ5"
  },
  {
    "uniprot": "A0A3P7E069",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E069"
  },
  {
    "uniprot": "A0A3P7E2R7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2R7"
  },
  {
    "uniprot": "J9FFT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FFT1"
  },
  {
    "uniprot": "A0A3P7DWC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWC9"
  },
  {
    "uniprot": "A0A3P7E0F4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0F4"
  },
  {
    "uniprot": "J9B558",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B558"
  },
  {
    "uniprot": "A0A3P7FFK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFK5"
  },
  {
    "uniprot": "A0A3P7FJX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJX6"
  },
  {
    "uniprot": "A0A3P7DKU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKU0"
  },
  {
    "uniprot": "J9FKS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FKS2"
  },
  {
    "uniprot": "A0A3P7FVC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVC8"
  },
  {
    "uniprot": "A0A3P7EBL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBL9"
  },
  {
    "uniprot": "A0A3P7EC49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC49"
  },
  {
    "uniprot": "A0A3P7DTI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTI0"
  },
  {
    "uniprot": "A0A3P7DSN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSN7"
  },
  {
    "uniprot": "A0A3P7DXK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXK6"
  },
  {
    "uniprot": "A0A3P7EFC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFC5"
  },
  {
    "uniprot": "A0A3P7E6P2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6P2"
  },
  {
    "uniprot": "A0A3P7DL99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL99"
  },
  {
    "uniprot": "A0A3P7E1B0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1B0"
  },
  {
    "uniprot": "J9DMW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DMW1"
  },
  {
    "uniprot": "A0A3P7FVZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVZ7"
  },
  {
    "uniprot": "A0A3P7ELT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELT3"
  },
  {
    "uniprot": "A0A3P7DKP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKP3"
  },
  {
    "uniprot": "A0A3P7E7A5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7A5"
  },
  {
    "uniprot": "A0A3P7DWW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWW4"
  },
  {
    "uniprot": "A0A3P7DPG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPG4"
  },
  {
    "uniprot": "A0A3P7EEK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEK3"
  },
  {
    "uniprot": "J9F3B8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F3B8"
  },
  {
    "uniprot": "A0A3P7EDA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDA6"
  },
  {
    "uniprot": "A0A3P7F1F0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1F0"
  },
  {
    "uniprot": "A0A3P7FH20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH20"
  },
  {
    "uniprot": "A0A3P7E1L1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1L1"
  },
  {
    "uniprot": "J9EJX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EJX9"
  },
  {
    "uniprot": "A0A3P7FQH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQH7"
  },
  {
    "uniprot": "A0A3P7DE72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE72"
  },
  {
    "uniprot": "A0A3P7F7E6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7E6"
  },
  {
    "uniprot": "A0A3P7G0M7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0M7"
  },
  {
    "uniprot": "A0A3P7G1L4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1L4"
  },
  {
    "uniprot": "A0A3P7E646",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E646"
  },
  {
    "uniprot": "A0A3P7FC97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC97"
  },
  {
    "uniprot": "A0A3P7FRU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRU3"
  },
  {
    "uniprot": "A0A3P7DXZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXZ5"
  },
  {
    "uniprot": "A0A3P7G8S8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8S8"
  },
  {
    "uniprot": "A0A3P7DVL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVL6"
  },
  {
    "uniprot": "A0A3P7DW25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW25"
  },
  {
    "uniprot": "A0A3P7FFU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFU2"
  },
  {
    "uniprot": "A0A3P7EKS4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKS4"
  },
  {
    "uniprot": "A0A3P7FJZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJZ2"
  },
  {
    "uniprot": "A0A3P7DSP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSP9"
  },
  {
    "uniprot": "A0A3P7FWN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWN1"
  },
  {
    "uniprot": "A0A3P7DVI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVI3"
  },
  {
    "uniprot": "A0A3P7DMN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMN0"
  },
  {
    "uniprot": "A0A183XHA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XHA1"
  },
  {
    "uniprot": "A0A3P7DX30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX30"
  },
  {
    "uniprot": "A0A3P7EUX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUX6"
  },
  {
    "uniprot": "A0A3P7DZZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZZ6"
  },
  {
    "uniprot": "A0A3P7DLD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLD1"
  },
  {
    "uniprot": "A0A3P7DUP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUP7"
  },
  {
    "uniprot": "A0A3P7DF62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF62"
  },
  {
    "uniprot": "A0A3P7ESF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESF5"
  },
  {
    "uniprot": "J9EA90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EA90"
  },
  {
    "uniprot": "A0A3P7EDB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDB9"
  },
  {
    "uniprot": "A0A3P7DPZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPZ4"
  },
  {
    "uniprot": "A0A3P7FE93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE93"
  },
  {
    "uniprot": "A0A3P7FGU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGU0"
  },
  {
    "uniprot": "A0A3P7FHP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHP3"
  },
  {
    "uniprot": "A0A3P7DRP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRP7"
  },
  {
    "uniprot": "A0A183XLT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLT6"
  },
  {
    "uniprot": "A0A3P7GCZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCZ0"
  },
  {
    "uniprot": "A0A3P7DZJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZJ4"
  },
  {
    "uniprot": "A0A3P7DJU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJU6"
  },
  {
    "uniprot": "J9ATS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ATS0"
  },
  {
    "uniprot": "A0A3P7E4B6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4B6"
  },
  {
    "uniprot": "A0A3P7G185",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G185"
  },
  {
    "uniprot": "A0A3P7FQA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQA1"
  },
  {
    "uniprot": "J9BHR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BHR4"
  },
  {
    "uniprot": "A0A3P7G6X2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6X2"
  },
  {
    "uniprot": "A0A3P7E501",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E501"
  },
  {
    "uniprot": "A0A3P7DUV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUV3"
  },
  {
    "uniprot": "A0A3P7DR33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR33"
  },
  {
    "uniprot": "A0A3P7EF81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF81"
  },
  {
    "uniprot": "A0A3P7DDN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDN4"
  },
  {
    "uniprot": "A0A3P7FNB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNB8"
  },
  {
    "uniprot": "A0A183XV28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XV28"
  },
  {
    "uniprot": "A0A3P7DWB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWB5"
  },
  {
    "uniprot": "A0A3P7E2M9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2M9"
  },
  {
    "uniprot": "A0A183XDJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDJ4"
  },
  {
    "uniprot": "A0A3P7DJQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJQ1"
  },
  {
    "uniprot": "J9FJJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FJJ4"
  },
  {
    "uniprot": "A0A3P7E1I8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1I8"
  },
  {
    "uniprot": "A0A3P7E570",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E570"
  },
  {
    "uniprot": "J9EV69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EV69"
  },
  {
    "uniprot": "A0A3P7E6G9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6G9"
  },
  {
    "uniprot": "A0A3P7EAN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAN7"
  },
  {
    "uniprot": "A0A183XDT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDT3"
  },
  {
    "uniprot": "A0A183XFF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XFF3"
  },
  {
    "uniprot": "A0A3P7EFG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFG0"
  },
  {
    "uniprot": "A0A3P7E220",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E220"
  },
  {
    "uniprot": "J9EYP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYP6"
  },
  {
    "uniprot": "A0A3P7E2Q5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2Q5"
  },
  {
    "uniprot": "A0A183XTB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XTB1"
  },
  {
    "uniprot": "A0A3P7FV01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV01"
  },
  {
    "uniprot": "A0A3P7DTZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTZ7"
  },
  {
    "uniprot": "A0A3P7EVZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVZ8"
  },
  {
    "uniprot": "A0A3P7G1L6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1L6"
  },
  {
    "uniprot": "A0A3P7FWX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWX9"
  },
  {
    "uniprot": "A0A3P7E858",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E858"
  },
  {
    "uniprot": "A0A3P7DY23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY23"
  },
  {
    "uniprot": "A0A3P7DZ60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ60"
  },
  {
    "uniprot": "A0A3P7DX37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX37"
  },
  {
    "uniprot": "A0A3P7DW76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW76"
  },
  {
    "uniprot": "A0A3P7E6E8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6E8"
  },
  {
    "uniprot": "J9BED9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BED9"
  },
  {
    "uniprot": "A0A3P7DYB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYB5"
  },
  {
    "uniprot": "A0A3P7E9P7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9P7"
  },
  {
    "uniprot": "A0A3P7EAJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAJ8"
  },
  {
    "uniprot": "A0A3P7E0K1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0K1"
  },
  {
    "uniprot": "A0A3P7GHG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHG1"
  },
  {
    "uniprot": "A0A3P7DL69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL69"
  },
  {
    "uniprot": "A0A3P7FYT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYT9"
  },
  {
    "uniprot": "A0A3P7E475",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E475"
  },
  {
    "uniprot": "A0A3P7E8Y4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8Y4"
  },
  {
    "uniprot": "A0A3P7FWK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWK0"
  },
  {
    "uniprot": "A0A3P7G327",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G327"
  },
  {
    "uniprot": "A0A3P7EDB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDB7"
  },
  {
    "uniprot": "A0A3P7GBR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBR5"
  },
  {
    "uniprot": "A0A3P7GAU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAU8"
  },
  {
    "uniprot": "A0A3P7E826",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E826"
  },
  {
    "uniprot": "A0A3P7DIU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIU6"
  },
  {
    "uniprot": "A0A3P7FWD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWD5"
  },
  {
    "uniprot": "J9ERY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERY0"
  },
  {
    "uniprot": "A0A3P7DGB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGB0"
  },
  {
    "uniprot": "A0A3P7DYK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYK1"
  },
  {
    "uniprot": "A0A3P7E873",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E873"
  },
  {
    "uniprot": "A0A3P7E3S6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3S6"
  },
  {
    "uniprot": "A0A183XQ03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XQ03"
  },
  {
    "uniprot": "A0A3P7DWT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWT8"
  },
  {
    "uniprot": "A0A183Y901",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y901"
  },
  {
    "uniprot": "A0A3P7EE88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE88"
  },
  {
    "uniprot": "A0A3P7EVR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVR0"
  },
  {
    "uniprot": "A0A3P7DPZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPZ9"
  },
  {
    "uniprot": "A0A3P7FGY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGY6"
  },
  {
    "uniprot": "A0A3P7DA61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DA61"
  },
  {
    "uniprot": "A0A3P7EYE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYE5"
  },
  {
    "uniprot": "A0A3P7E0W6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0W6"
  },
  {
    "uniprot": "J9FDJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FDJ6"
  },
  {
    "uniprot": "A0A3P7EFW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFW2"
  },
  {
    "uniprot": "A0A3P7FUT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUT3"
  },
  {
    "uniprot": "A0A3P7DA30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DA30"
  },
  {
    "uniprot": "A0A3P7GIS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIS9"
  },
  {
    "uniprot": "A0A3P7FZS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZS3"
  },
  {
    "uniprot": "A0A3P7FRE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRE5"
  },
  {
    "uniprot": "A0A3P7FWQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWQ1"
  },
  {
    "uniprot": "A0A3P7FY83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY83"
  },
  {
    "uniprot": "A0A3P7E2U0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2U0"
  },
  {
    "uniprot": "A0A3P7ELJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELJ1"
  },
  {
    "uniprot": "A0A3P7G872",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G872"
  },
  {
    "uniprot": "A0A3P7DWZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWZ0"
  },
  {
    "uniprot": "A0A3P7DCW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCW9"
  },
  {
    "uniprot": "A0A3P7EE30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE30"
  },
  {
    "uniprot": "A0A3P7DPZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPZ1"
  },
  {
    "uniprot": "A0A3P7GCS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCS1"
  },
  {
    "uniprot": "A0A3P7G682",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G682"
  },
  {
    "uniprot": "A0A3P7DP46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP46"
  },
  {
    "uniprot": "A0A3P7DV47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV47"
  },
  {
    "uniprot": "A0A3P7DWR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWR2"
  },
  {
    "uniprot": "A0A3P7ETN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETN4"
  },
  {
    "uniprot": "A0A3P7FPK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPK5"
  },
  {
    "uniprot": "A0A3P7DUE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUE0"
  },
  {
    "uniprot": "A0A3P7DTM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTM5"
  },
  {
    "uniprot": "A0A3P7ERA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERA1"
  },
  {
    "uniprot": "A0A3P7EGP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGP4"
  },
  {
    "uniprot": "A0A3P7DVB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVB7"
  },
  {
    "uniprot": "A0A3P7DEU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEU4"
  },
  {
    "uniprot": "A0A3P7DQ48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ48"
  },
  {
    "uniprot": "A0A3P7E8D4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8D4"
  },
  {
    "uniprot": "A0A3P7DDD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDD1"
  },
  {
    "uniprot": "A0A3P7EB24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB24"
  },
  {
    "uniprot": "A0A3P7FBM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBM3"
  },
  {
    "uniprot": "A0A3P7EZ12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZ12"
  },
  {
    "uniprot": "A0A3P7DR84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR84"
  },
  {
    "uniprot": "A0A3P7FPC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPC2"
  },
  {
    "uniprot": "A0A3P7FLY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLY5"
  },
  {
    "uniprot": "A0A3P7E404",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E404"
  },
  {
    "uniprot": "A0A3P7EKZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKZ6"
  },
  {
    "uniprot": "A0A3P7DLT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLT7"
  },
  {
    "uniprot": "A0A3P7GBZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBZ9"
  },
  {
    "uniprot": "A0A3P7DC11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC11"
  },
  {
    "uniprot": "A0A3P7GD92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GD92"
  },
  {
    "uniprot": "A0A3P7E9V1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9V1"
  },
  {
    "uniprot": "A0A3P7F198",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F198"
  },
  {
    "uniprot": "A0A3P7DEX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEX6"
  },
  {
    "uniprot": "A0A3P7EFQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFQ1"
  },
  {
    "uniprot": "A0A3P7DZ52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ52"
  },
  {
    "uniprot": "A0A3P7E0R9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0R9"
  },
  {
    "uniprot": "A0A3P7EM85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM85"
  },
  {
    "uniprot": "A0A3P7EJ39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ39"
  },
  {
    "uniprot": "A0A3P7GFK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFK8"
  },
  {
    "uniprot": "A0A3P7DDF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDF1"
  },
  {
    "uniprot": "A0A3P7G2U8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2U8"
  },
  {
    "uniprot": "A0A3P7EBX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBX8"
  },
  {
    "uniprot": "J9ESJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ESJ6"
  },
  {
    "uniprot": "A0A3P7EQB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQB6"
  },
  {
    "uniprot": "A0A3P7DRU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRU5"
  },
  {
    "uniprot": "J9F7X5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F7X5"
  },
  {
    "uniprot": "A0A3P7FNK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNK1"
  },
  {
    "uniprot": "A0A3P7EEU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEU9"
  },
  {
    "uniprot": "A0A3P7GAK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAK6"
  },
  {
    "uniprot": "A0A3P7DYC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYC5"
  },
  {
    "uniprot": "A0A3P7EPB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPB5"
  },
  {
    "uniprot": "A0A3P7EC53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC53"
  },
  {
    "uniprot": "A0A3P7DZ63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ63"
  },
  {
    "uniprot": "A0A3P7E8W8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8W8"
  },
  {
    "uniprot": "A0A3P7DCZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCZ4"
  },
  {
    "uniprot": "A0A3P7DYS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYS2"
  },
  {
    "uniprot": "J9E536",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E536"
  },
  {
    "uniprot": "A0A3P7DED1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DED1"
  },
  {
    "uniprot": "A0A3P7DVW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVW4"
  },
  {
    "uniprot": "A0A3P7DKZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKZ6"
  },
  {
    "uniprot": "A0A3P7DVY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVY9"
  },
  {
    "uniprot": "A0A3P7E9H2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9H2"
  },
  {
    "uniprot": "A0A183XCC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCC6"
  },
  {
    "uniprot": "A0A3P7DLC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLC0"
  },
  {
    "uniprot": "A0A183XGH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XGH8"
  },
  {
    "uniprot": "A0A3P7E1D9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1D9"
  },
  {
    "uniprot": "A0A3P7GD78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GD78"
  },
  {
    "uniprot": "A0A3P7EIJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIJ9"
  },
  {
    "uniprot": "A0A3P7FRZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRZ9"
  },
  {
    "uniprot": "A0A3P7DVQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVQ4"
  },
  {
    "uniprot": "A0A3P7DLF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLF6"
  },
  {
    "uniprot": "A0A3P7EPA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPA2"
  },
  {
    "uniprot": "A0A3P7E519",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E519"
  },
  {
    "uniprot": "A0A3P7FVD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVD2"
  },
  {
    "uniprot": "A0A3P7FX91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX91"
  },
  {
    "uniprot": "A0A3P7EFS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFS1"
  },
  {
    "uniprot": "A0A3P7FIC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIC0"
  },
  {
    "uniprot": "A0A3P7E108",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E108"
  },
  {
    "uniprot": "A0A3P7GME6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GME6"
  },
  {
    "uniprot": "A0A3P7G3M7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3M7"
  },
  {
    "uniprot": "A0A3P7DGH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGH1"
  },
  {
    "uniprot": "A0A3P7EJ21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ21"
  },
  {
    "uniprot": "A0A3P7DS77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS77"
  },
  {
    "uniprot": "A0A3P7F136",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F136"
  },
  {
    "uniprot": "A0A3P7DWB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWB1"
  },
  {
    "uniprot": "A0A3P7GF58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GF58"
  },
  {
    "uniprot": "A0A3P7DZ82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ82"
  },
  {
    "uniprot": "A0A3P7DQF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQF9"
  },
  {
    "uniprot": "A0A3P7DBJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBJ6"
  },
  {
    "uniprot": "A0A3P7EI63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI63"
  },
  {
    "uniprot": "A0A3P7E3A9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3A9"
  },
  {
    "uniprot": "A0A183XU11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XU11"
  },
  {
    "uniprot": "J9ERT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERT7"
  },
  {
    "uniprot": "A0A3P7ECT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECT1"
  },
  {
    "uniprot": "A0A3P7E9A5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9A5"
  },
  {
    "uniprot": "J9ERB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERB9"
  },
  {
    "uniprot": "A0A3P7DXU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXU9"
  },
  {
    "uniprot": "A0A3P7E120",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E120"
  },
  {
    "uniprot": "A0A3P7DNJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNJ8"
  },
  {
    "uniprot": "A0A3P7GD30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GD30"
  },
  {
    "uniprot": "A0A3P7FTV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTV3"
  },
  {
    "uniprot": "A0A3P7FS83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS83"
  },
  {
    "uniprot": "A0A3P7FGV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGV4"
  },
  {
    "uniprot": "A0A3P7EGT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGT6"
  },
  {
    "uniprot": "A0A3P7E2Y1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2Y1"
  },
  {
    "uniprot": "A0A3P7EDW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDW4"
  },
  {
    "uniprot": "A0A3P7ELA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELA1"
  },
  {
    "uniprot": "A0A3P7EJK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJK1"
  },
  {
    "uniprot": "A0A3P7E245",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E245"
  },
  {
    "uniprot": "A0A3P7E264",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E264"
  },
  {
    "uniprot": "A0A3P7DW23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW23"
  },
  {
    "uniprot": "A0A3P7E703",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E703"
  },
  {
    "uniprot": "A0A3P7FM94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM94"
  },
  {
    "uniprot": "A0A3P7FYU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYU1"
  },
  {
    "uniprot": "A0A3P7FTD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTD1"
  },
  {
    "uniprot": "A0A3P7E7Y3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7Y3"
  },
  {
    "uniprot": "A0A3P7EPC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPC5"
  },
  {
    "uniprot": "A0A3P7E074",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E074"
  },
  {
    "uniprot": "J9BJI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BJI0"
  },
  {
    "uniprot": "A0A3P7E1H9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1H9"
  },
  {
    "uniprot": "A0A3P7EG38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG38"
  },
  {
    "uniprot": "A0A3P7EN01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN01"
  },
  {
    "uniprot": "A0A3P7EGI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGI0"
  },
  {
    "uniprot": "A0A3P7EB50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB50"
  },
  {
    "uniprot": "A0A3P7FI36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI36"
  },
  {
    "uniprot": "A0A3P7FUM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUM6"
  },
  {
    "uniprot": "A0A3P7FC33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC33"
  },
  {
    "uniprot": "A0A3P7DW49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW49"
  },
  {
    "uniprot": "A0A3P7DP74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP74"
  },
  {
    "uniprot": "A0A3P7FJH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJH8"
  },
  {
    "uniprot": "A0A3P7DQD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQD2"
  },
  {
    "uniprot": "A0A3P7EWG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWG7"
  },
  {
    "uniprot": "A0A183XKC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XKC8"
  },
  {
    "uniprot": "A0A3P7FAC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAC9"
  },
  {
    "uniprot": "J9EA55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EA55"
  },
  {
    "uniprot": "A0A3P7DVU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVU2"
  },
  {
    "uniprot": "A0A3P7E9P6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9P6"
  },
  {
    "uniprot": "A0A3P7EL47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL47"
  },
  {
    "uniprot": "A0A3P7FJM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJM4"
  },
  {
    "uniprot": "J9E778",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E778"
  },
  {
    "uniprot": "A0A3P7FMY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMY8"
  },
  {
    "uniprot": "J9EMB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EMB4"
  },
  {
    "uniprot": "A0A3P7G8H4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8H4"
  },
  {
    "uniprot": "A0A3P7ES37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ES37"
  },
  {
    "uniprot": "A0A3P7DXA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXA1"
  },
  {
    "uniprot": "A0A3P7DHU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHU2"
  },
  {
    "uniprot": "A0A3P7FXK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXK3"
  },
  {
    "uniprot": "A0A3P7DXA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXA7"
  },
  {
    "uniprot": "A0A3P7ESR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESR3"
  },
  {
    "uniprot": "A0A3P7FWR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWR2"
  },
  {
    "uniprot": "A0A3P7EEJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEJ7"
  },
  {
    "uniprot": "A0A3P7E8A0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8A0"
  },
  {
    "uniprot": "A0A3P7G3R2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3R2"
  },
  {
    "uniprot": "A0A3P7EMA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMA3"
  },
  {
    "uniprot": "J9EIH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EIH8"
  },
  {
    "uniprot": "J9FDF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FDF5"
  },
  {
    "uniprot": "A0A3P7EE10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE10"
  },
  {
    "uniprot": "A0A3P7EMG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMG1"
  },
  {
    "uniprot": "A0A3P7DRG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRG0"
  },
  {
    "uniprot": "A0A3P7DXE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXE4"
  },
  {
    "uniprot": "A0A3P7DNL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNL0"
  },
  {
    "uniprot": "A0A3P7ELC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELC2"
  },
  {
    "uniprot": "A0A3P7EY38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY38"
  },
  {
    "uniprot": "A0A3P7FFG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFG4"
  },
  {
    "uniprot": "A0A3P7FX70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX70"
  },
  {
    "uniprot": "A0A3P7DS58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS58"
  },
  {
    "uniprot": "A0A3P7DLX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLX9"
  },
  {
    "uniprot": "J9EX65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EX65"
  },
  {
    "uniprot": "A0A3P7DYP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYP4"
  },
  {
    "uniprot": "A0A3P7FZR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZR3"
  },
  {
    "uniprot": "A0A3P7DTB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTB4"
  },
  {
    "uniprot": "A0A3P7DL60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL60"
  },
  {
    "uniprot": "A0A3P7FFX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFX1"
  },
  {
    "uniprot": "A0A3P7DUX6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUX6"
  },
  {
    "uniprot": "A0A3P7FQ16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ16"
  },
  {
    "uniprot": "A0A3P7E9F5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9F5"
  },
  {
    "uniprot": "A0A3P7EC36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC36"
  },
  {
    "uniprot": "A0A3P7EJ35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ35"
  },
  {
    "uniprot": "A0A3P7FRJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRJ3"
  },
  {
    "uniprot": "A0A3P7G9D6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9D6"
  },
  {
    "uniprot": "A0A3P7FLS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLS9"
  },
  {
    "uniprot": "J9AT86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AT86"
  },
  {
    "uniprot": "A0A3P7E9I6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9I6"
  },
  {
    "uniprot": "A0A3P7E8J4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8J4"
  },
  {
    "uniprot": "A0A3P7DA00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DA00"
  },
  {
    "uniprot": "A0A3P7FN24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN24"
  },
  {
    "uniprot": "A0A3P7DWQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWQ4"
  },
  {
    "uniprot": "A0A3P7E8Y9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8Y9"
  },
  {
    "uniprot": "A0A3P7E5R8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5R8"
  },
  {
    "uniprot": "A0A3P7G0Y2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0Y2"
  },
  {
    "uniprot": "A0A3P7DQV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQV0"
  },
  {
    "uniprot": "A0A3P7ELK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELK6"
  },
  {
    "uniprot": "A0A3P7DTJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTJ3"
  },
  {
    "uniprot": "A0A3P7ELN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELN6"
  },
  {
    "uniprot": "A0A3P7DC44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC44"
  },
  {
    "uniprot": "A0A3P7DWC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWC6"
  },
  {
    "uniprot": "A0A3P7G0Y6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0Y6"
  },
  {
    "uniprot": "A0A183XWS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XWS9"
  },
  {
    "uniprot": "A0A3P7F0E4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0E4"
  },
  {
    "uniprot": "J9E6K0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E6K0"
  },
  {
    "uniprot": "A0A3P7EI06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI06"
  },
  {
    "uniprot": "A0A3P7FNH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNH7"
  },
  {
    "uniprot": "A0A3P7FKA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKA0"
  },
  {
    "uniprot": "A0A3P7DPK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPK9"
  },
  {
    "uniprot": "A0A3P7EZZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZZ9"
  },
  {
    "uniprot": "A0A183XS33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XS33"
  },
  {
    "uniprot": "A0A3P7E254",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E254"
  },
  {
    "uniprot": "A0A3P7E478",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E478"
  },
  {
    "uniprot": "A0A3P7EF09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF09"
  },
  {
    "uniprot": "A0A3P7DML3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DML3"
  },
  {
    "uniprot": "A0A3P7DV94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV94"
  },
  {
    "uniprot": "A0A3P7EI01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI01"
  },
  {
    "uniprot": "A0A3P7G3B1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3B1"
  },
  {
    "uniprot": "A0A3P7DNI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNI4"
  },
  {
    "uniprot": "A0A3P7DDI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDI5"
  },
  {
    "uniprot": "J9ELB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ELB0"
  },
  {
    "uniprot": "A0A3P7GKQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKQ5"
  },
  {
    "uniprot": "J9EPF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPF8"
  },
  {
    "uniprot": "J9FH97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FH97"
  },
  {
    "uniprot": "A0A3P7DEC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEC5"
  },
  {
    "uniprot": "A0A3P7ECQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECQ4"
  },
  {
    "uniprot": "A0A3P7GER2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GER2"
  },
  {
    "uniprot": "J9FAB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FAB8"
  },
  {
    "uniprot": "A0A3P7DXI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXI6"
  },
  {
    "uniprot": "J9F0X2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F0X2"
  },
  {
    "uniprot": "A0A3P7FI18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI18"
  },
  {
    "uniprot": "A0A3P7DWX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWX2"
  },
  {
    "uniprot": "A0A3P7DXF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXF0"
  },
  {
    "uniprot": "A0A3P7FUE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUE2"
  },
  {
    "uniprot": "A0A3P7F883",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F883"
  },
  {
    "uniprot": "A0A3P7DVR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVR1"
  },
  {
    "uniprot": "A0A3P7DZV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZV0"
  },
  {
    "uniprot": "A0A3P7E5X6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5X6"
  },
  {
    "uniprot": "A0A3P7DGS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGS1"
  },
  {
    "uniprot": "A0A3P7EV01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EV01"
  },
  {
    "uniprot": "J9EZQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZQ7"
  },
  {
    "uniprot": "A0A3P7FA81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FA81"
  },
  {
    "uniprot": "A0A3P7EGY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGY1"
  },
  {
    "uniprot": "A0A3P7GK25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GK25"
  },
  {
    "uniprot": "A0A3P7EMI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMI9"
  },
  {
    "uniprot": "A0A3P7FQZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQZ2"
  },
  {
    "uniprot": "A0A3P7E0I4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0I4"
  },
  {
    "uniprot": "A0A3P7FFJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFJ6"
  },
  {
    "uniprot": "A0A3P7E3Q8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3Q8"
  },
  {
    "uniprot": "A0A3P7E0D3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0D3"
  },
  {
    "uniprot": "A0A3P7DY19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY19"
  },
  {
    "uniprot": "A0A3P7FQR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQR0"
  },
  {
    "uniprot": "A0A3P7DU44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU44"
  },
  {
    "uniprot": "A0A3P7DX69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX69"
  },
  {
    "uniprot": "A0A3P7DTH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTH5"
  },
  {
    "uniprot": "A0A3P7FPF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPF1"
  },
  {
    "uniprot": "A0A3P7EF62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF62"
  },
  {
    "uniprot": "A0A3P7FUH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUH2"
  },
  {
    "uniprot": "A0A3P7DCK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCK8"
  },
  {
    "uniprot": "J9F1C8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1C8"
  },
  {
    "uniprot": "A0A3P7E201",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E201"
  },
  {
    "uniprot": "A0A3P7FB83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB83"
  },
  {
    "uniprot": "J9DY35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DY35"
  },
  {
    "uniprot": "A0A3P7EEP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEP0"
  },
  {
    "uniprot": "A0A3P7DZL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZL9"
  },
  {
    "uniprot": "A0A3P7FP05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP05"
  },
  {
    "uniprot": "A0A3P7FC36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC36"
  },
  {
    "uniprot": "A0A183Y086",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y086"
  },
  {
    "uniprot": "A0A3P7DW69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW69"
  },
  {
    "uniprot": "A0A3P7E0P1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0P1"
  },
  {
    "uniprot": "A0A3P7GNK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GNK0"
  },
  {
    "uniprot": "A0A3P7DZ55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ55"
  },
  {
    "uniprot": "A0A3P7EDT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDT3"
  },
  {
    "uniprot": "A0A3P7DG92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG92"
  },
  {
    "uniprot": "A0A3P7EXR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXR2"
  },
  {
    "uniprot": "A0A3P7EF33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF33"
  },
  {
    "uniprot": "A0A3P7E8H9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8H9"
  },
  {
    "uniprot": "A0A3P7G475",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G475"
  },
  {
    "uniprot": "A0A3P7DWY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWY2"
  },
  {
    "uniprot": "A0A3P7E132",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E132"
  },
  {
    "uniprot": "A0A3P7DXI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXI9"
  },
  {
    "uniprot": "A0A3P7DDC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDC7"
  },
  {
    "uniprot": "A0A3P7E3D6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3D6"
  },
  {
    "uniprot": "J9FAJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FAJ5"
  },
  {
    "uniprot": "A0A3P7DX90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX90"
  },
  {
    "uniprot": "A0A3P7EJ31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ31"
  },
  {
    "uniprot": "A0A3P7E1X0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1X0"
  },
  {
    "uniprot": "A0A183XUZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XUZ5"
  },
  {
    "uniprot": "A0A3P7F3Q6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3Q6"
  },
  {
    "uniprot": "A0A3P7GND9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GND9"
  },
  {
    "uniprot": "A0A3P7EBK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBK9"
  },
  {
    "uniprot": "A0A3P7E0C1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0C1"
  },
  {
    "uniprot": "A0A183XV06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XV06"
  },
  {
    "uniprot": "A0A3P7EKH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKH9"
  },
  {
    "uniprot": "A0A3P7ECS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECS8"
  },
  {
    "uniprot": "A0A3P7EF16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF16"
  },
  {
    "uniprot": "A0A183XS08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XS08"
  },
  {
    "uniprot": "A0A3P7FY86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY86"
  },
  {
    "uniprot": "A0A3P7FYA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYA4"
  },
  {
    "uniprot": "A0A3P7DNV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNV9"
  },
  {
    "uniprot": "A0A3P7EA45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA45"
  },
  {
    "uniprot": "A0A3P7ENV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENV0"
  },
  {
    "uniprot": "A0A3P7DYY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYY7"
  },
  {
    "uniprot": "A0A3P7GGT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGT6"
  },
  {
    "uniprot": "A0A3P7FXW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXW6"
  },
  {
    "uniprot": "A0A3P7EGP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGP1"
  },
  {
    "uniprot": "A0A3P7G1G6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1G6"
  },
  {
    "uniprot": "A0A3P7FWK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWK2"
  },
  {
    "uniprot": "J9EXG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXG3"
  },
  {
    "uniprot": "A0A3P7EF84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF84"
  },
  {
    "uniprot": "A0A3P7DNH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNH8"
  },
  {
    "uniprot": "A0A3P7E8B9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8B9"
  },
  {
    "uniprot": "J9EC03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EC03"
  },
  {
    "uniprot": "A0A3P7EGW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGW1"
  },
  {
    "uniprot": "A0A3P7DZP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZP5"
  },
  {
    "uniprot": "A0A3P7E4B8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4B8"
  },
  {
    "uniprot": "A0A3P7DUS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUS2"
  },
  {
    "uniprot": "A0A3P7E002",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E002"
  },
  {
    "uniprot": "A0A3P7FKG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKG1"
  },
  {
    "uniprot": "A0A3P7DU10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU10"
  },
  {
    "uniprot": "A0A3P7FLM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLM8"
  },
  {
    "uniprot": "A0A3P7G6F2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6F2"
  },
  {
    "uniprot": "A0A3P7EDP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDP4"
  },
  {
    "uniprot": "A0A3P7G6E9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6E9"
  },
  {
    "uniprot": "A0A3P7DG87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG87"
  },
  {
    "uniprot": "J9F8Y2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F8Y2"
  },
  {
    "uniprot": "A0A3P7GAK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAK3"
  },
  {
    "uniprot": "A0A3P7EX08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EX08"
  },
  {
    "uniprot": "A0A3P7E8E1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8E1"
  },
  {
    "uniprot": "A0A3P7DXY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXY5"
  },
  {
    "uniprot": "A0A3P7EP19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP19"
  },
  {
    "uniprot": "A0A3P7DXX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXX9"
  },
  {
    "uniprot": "J9EYH6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYH6"
  },
  {
    "uniprot": "A0A3P7DUQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUQ4"
  },
  {
    "uniprot": "A0A3P7FV16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV16"
  },
  {
    "uniprot": "A0A3P7F7R5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7R5"
  },
  {
    "uniprot": "A0A3P7DLQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLQ2"
  },
  {
    "uniprot": "A0A3P7DWE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWE2"
  },
  {
    "uniprot": "A0A3P7ECV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECV1"
  },
  {
    "uniprot": "A0A3P7FEA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEA3"
  },
  {
    "uniprot": "A0A3P7DZZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZZ5"
  },
  {
    "uniprot": "A0A3P7DKS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKS6"
  },
  {
    "uniprot": "A0A3P7GLL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLL6"
  },
  {
    "uniprot": "A0A3P7F2P7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2P7"
  },
  {
    "uniprot": "A0A3P7EEF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEF7"
  },
  {
    "uniprot": "A0A3P7E3U2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3U2"
  },
  {
    "uniprot": "A0A3P7DJH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJH2"
  },
  {
    "uniprot": "A0A3P7EI23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI23"
  },
  {
    "uniprot": "A0A3P7E0Y2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0Y2"
  },
  {
    "uniprot": "A0A3P7FR80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR80"
  },
  {
    "uniprot": "A0A3P7DWF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWF1"
  },
  {
    "uniprot": "A0A3P7EBS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBS0"
  },
  {
    "uniprot": "A0A3P7E0K5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0K5"
  },
  {
    "uniprot": "J9F4U3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F4U3"
  },
  {
    "uniprot": "A0A3P7DC30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DC30"
  },
  {
    "uniprot": "J9B851",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B851"
  },
  {
    "uniprot": "A0A3P7E340",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E340"
  },
  {
    "uniprot": "J9BKJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BKJ5"
  },
  {
    "uniprot": "J9F212",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F212"
  },
  {
    "uniprot": "A0A3P7E7K3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7K3"
  },
  {
    "uniprot": "A0A3P7DVK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVK9"
  },
  {
    "uniprot": "A0A3P7EZD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZD4"
  },
  {
    "uniprot": "A0A3P7DUA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUA4"
  },
  {
    "uniprot": "A0A3P7E510",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E510"
  },
  {
    "uniprot": "A0A3P7FEN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEN0"
  },
  {
    "uniprot": "A0A3P7DRZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRZ3"
  },
  {
    "uniprot": "A0A3P7E788",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E788"
  },
  {
    "uniprot": "A0A3P7EWC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWC5"
  },
  {
    "uniprot": "A0A3P7FRY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRY9"
  },
  {
    "uniprot": "A0A3P7FMX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMX7"
  },
  {
    "uniprot": "A0A3P7E629",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E629"
  },
  {
    "uniprot": "A0A3P7GEZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEZ6"
  },
  {
    "uniprot": "A0A3P7EQA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQA6"
  },
  {
    "uniprot": "A0A3P7DEN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEN5"
  },
  {
    "uniprot": "A0A3P7E6F7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6F7"
  },
  {
    "uniprot": "A0A3P7DSH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSH7"
  },
  {
    "uniprot": "A0A3P7E359",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E359"
  },
  {
    "uniprot": "A0A3P7DD91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD91"
  },
  {
    "uniprot": "A0A3P7EA86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA86"
  },
  {
    "uniprot": "A0A3P7DF05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF05"
  },
  {
    "uniprot": "A0A3P7EEH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEH3"
  },
  {
    "uniprot": "A0A3P7G1B0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1B0"
  },
  {
    "uniprot": "A0A3P7DZL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZL7"
  },
  {
    "uniprot": "A0A183XKF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XKF7"
  },
  {
    "uniprot": "J9BM08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BM08"
  },
  {
    "uniprot": "A0A3P7E360",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E360"
  },
  {
    "uniprot": "A0A3P7G955",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G955"
  },
  {
    "uniprot": "A0A3P7F801",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F801"
  },
  {
    "uniprot": "A0A3P7EG51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG51"
  },
  {
    "uniprot": "J9EV62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EV62"
  },
  {
    "uniprot": "A0A3P7E1R4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1R4"
  },
  {
    "uniprot": "A0A3P7DXG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXG0"
  },
  {
    "uniprot": "A0A3P7FFY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFY9"
  },
  {
    "uniprot": "A0A3P7EM94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM94"
  },
  {
    "uniprot": "A0A183XP88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XP88"
  },
  {
    "uniprot": "A0A3P7FPC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPC5"
  },
  {
    "uniprot": "A0A3P7GH29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GH29"
  },
  {
    "uniprot": "A0A3P7FNA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNA7"
  },
  {
    "uniprot": "A0A3P7DQX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQX0"
  },
  {
    "uniprot": "A0A3P7ECD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECD3"
  },
  {
    "uniprot": "A0A3P7DEQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEQ6"
  },
  {
    "uniprot": "A0A3P7DQ59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ59"
  },
  {
    "uniprot": "A0A3P7DZV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZV7"
  },
  {
    "uniprot": "A0A3P7DZI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZI5"
  },
  {
    "uniprot": "A0A3P7FAB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAB9"
  },
  {
    "uniprot": "A0A3P7E3U1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3U1"
  },
  {
    "uniprot": "A0A3P7ERP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERP9"
  },
  {
    "uniprot": "A0A3P7DY40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY40"
  },
  {
    "uniprot": "A0A3P7EWM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWM4"
  },
  {
    "uniprot": "A0A3P7ECW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECW0"
  },
  {
    "uniprot": "J9BMS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BMS2"
  },
  {
    "uniprot": "A0A3P7FB21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB21"
  },
  {
    "uniprot": "A0A3P7FJW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJW4"
  },
  {
    "uniprot": "A0A3P7E738",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E738"
  },
  {
    "uniprot": "A0A3P7FP31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP31"
  },
  {
    "uniprot": "J9EZV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZV1"
  },
  {
    "uniprot": "J9EQY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQY1"
  },
  {
    "uniprot": "A0A3P7DQT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQT5"
  },
  {
    "uniprot": "A0A3P7G816",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G816"
  },
  {
    "uniprot": "A0A3P7E0L9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0L9"
  },
  {
    "uniprot": "A0A3P7DF30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF30"
  },
  {
    "uniprot": "A0A3P7EBV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBV6"
  },
  {
    "uniprot": "A0A3P7FXZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXZ8"
  },
  {
    "uniprot": "A0A3P7F8M0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8M0"
  },
  {
    "uniprot": "A0A3P7E4K1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4K1"
  },
  {
    "uniprot": "A0A3P7E589",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E589"
  },
  {
    "uniprot": "J9F1K2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1K2"
  },
  {
    "uniprot": "A0A3P7DQ76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ76"
  },
  {
    "uniprot": "A0A3P7EG13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG13"
  },
  {
    "uniprot": "A0A3P7E4Y3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4Y3"
  },
  {
    "uniprot": "A0A3P7DJK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJK8"
  },
  {
    "uniprot": "A0A3P7DH36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH36"
  },
  {
    "uniprot": "A0A3P7E2D7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2D7"
  },
  {
    "uniprot": "A0A3P7F2X0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2X0"
  },
  {
    "uniprot": "A0A3P7FF06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF06"
  },
  {
    "uniprot": "J9ESP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ESP0"
  },
  {
    "uniprot": "A0A3P7DVI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVI4"
  },
  {
    "uniprot": "A0A3P7DV30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV30"
  },
  {
    "uniprot": "A0A3P7EDV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDV9"
  },
  {
    "uniprot": "A0A3P7DCK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCK0"
  },
  {
    "uniprot": "A0A3P7E6M0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6M0"
  },
  {
    "uniprot": "A0A3P7DDT3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDT3"
  },
  {
    "uniprot": "A0A3P7FW60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW60"
  },
  {
    "uniprot": "A0A3P7EFM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFM2"
  },
  {
    "uniprot": "A0A3P7ENA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENA7"
  },
  {
    "uniprot": "A0A3P7DV93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV93"
  },
  {
    "uniprot": "A0A3P7F4N7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F4N7"
  },
  {
    "uniprot": "A0A3P7E184",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E184"
  },
  {
    "uniprot": "A0A3P7FZP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZP8"
  },
  {
    "uniprot": "J9EZ17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZ17"
  },
  {
    "uniprot": "A0A3P7EKM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKM3"
  },
  {
    "uniprot": "J9EYC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYC9"
  },
  {
    "uniprot": "A0A3P7DLM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLM3"
  },
  {
    "uniprot": "A0A3P7FAS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAS6"
  },
  {
    "uniprot": "A0A3P7DLX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLX2"
  },
  {
    "uniprot": "A0A3P7FBB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBB3"
  },
  {
    "uniprot": "A0A183XJQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XJQ7"
  },
  {
    "uniprot": "A0A3P7FPD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPD9"
  },
  {
    "uniprot": "A0A3P7EE35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE35"
  },
  {
    "uniprot": "A0A3P7EIX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIX1"
  },
  {
    "uniprot": "A0A3P7DGK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGK4"
  },
  {
    "uniprot": "A0A3P7FJ48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ48"
  },
  {
    "uniprot": "A0A3P7EBX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBX4"
  },
  {
    "uniprot": "A0A3P7E1R1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1R1"
  },
  {
    "uniprot": "A0A3P7E1J1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1J1"
  },
  {
    "uniprot": "A0A3P7FVJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVJ4"
  },
  {
    "uniprot": "A0A3P7DN09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN09"
  },
  {
    "uniprot": "A0A3P7DQD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQD3"
  },
  {
    "uniprot": "A0A3P7E1A3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1A3"
  },
  {
    "uniprot": "A0A3P7E6Y6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6Y6"
  },
  {
    "uniprot": "A0A3P7DU94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU94"
  },
  {
    "uniprot": "A0A3P7FCG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCG2"
  },
  {
    "uniprot": "A0A3P7EDT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDT8"
  },
  {
    "uniprot": "A0A3P7FAP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAP8"
  },
  {
    "uniprot": "A0A3P7E469",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E469"
  },
  {
    "uniprot": "A0A3P7EVN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVN4"
  },
  {
    "uniprot": "A0A3P7FN74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN74"
  },
  {
    "uniprot": "A0A3P7DRN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRN6"
  },
  {
    "uniprot": "A0A3P7DQ27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ27"
  },
  {
    "uniprot": "A0A3P7GBE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBE9"
  },
  {
    "uniprot": "A0A3P7GLC4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLC4"
  },
  {
    "uniprot": "A0A3P7FSQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSQ0"
  },
  {
    "uniprot": "A0A3P7DSW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSW4"
  },
  {
    "uniprot": "A0A3P7FV08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV08"
  },
  {
    "uniprot": "A0A3P7DDG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDG0"
  },
  {
    "uniprot": "A0A3P7DG77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG77"
  },
  {
    "uniprot": "A0A3P7E4M3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4M3"
  },
  {
    "uniprot": "A0A3P7DG26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG26"
  },
  {
    "uniprot": "A0A3P7DWU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWU6"
  },
  {
    "uniprot": "A0A3P7GC45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GC45"
  },
  {
    "uniprot": "A0A183XQD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XQD4"
  },
  {
    "uniprot": "A0A3P7DW93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW93"
  },
  {
    "uniprot": "A0A3P7DXM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXM4"
  },
  {
    "uniprot": "A0A3P7EL45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL45"
  },
  {
    "uniprot": "J9F3M3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F3M3"
  },
  {
    "uniprot": "A0A3P7DHY8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHY8"
  },
  {
    "uniprot": "A0A3P7E109",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E109"
  },
  {
    "uniprot": "A0A3P7EEJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEJ0"
  },
  {
    "uniprot": "J9FNV9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FNV9"
  },
  {
    "uniprot": "A0A3P7E4Z8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4Z8"
  },
  {
    "uniprot": "A0A3P7DXM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXM9"
  },
  {
    "uniprot": "A0A3P7EDL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDL0"
  },
  {
    "uniprot": "A0A3P7EBM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBM0"
  },
  {
    "uniprot": "A0A3P7FCD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCD7"
  },
  {
    "uniprot": "A0A3P7G2U0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2U0"
  },
  {
    "uniprot": "A0A3P7ENG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENG5"
  },
  {
    "uniprot": "A0A3P7E0E8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0E8"
  },
  {
    "uniprot": "A0A3P7F2S7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2S7"
  },
  {
    "uniprot": "J9ESX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ESX3"
  },
  {
    "uniprot": "A0A3P7E6Z3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6Z3"
  },
  {
    "uniprot": "A0A3P7F9R9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9R9"
  },
  {
    "uniprot": "A0A3P7FBZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBZ6"
  },
  {
    "uniprot": "A0A3P7E1H7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1H7"
  },
  {
    "uniprot": "A0A3P7EJU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJU3"
  },
  {
    "uniprot": "A0A3P7E273",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E273"
  },
  {
    "uniprot": "A0A3P7DJC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJC8"
  },
  {
    "uniprot": "A0A3P7EF21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF21"
  },
  {
    "uniprot": "A0A3P7G0K8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0K8"
  },
  {
    "uniprot": "A0A3P7E9G5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9G5"
  },
  {
    "uniprot": "A0A3P7FY29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY29"
  },
  {
    "uniprot": "J9EJL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EJL3"
  },
  {
    "uniprot": "A0A3P7DP26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP26"
  },
  {
    "uniprot": "A0A3P7FP24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP24"
  },
  {
    "uniprot": "A0A3P7DUQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUQ5"
  },
  {
    "uniprot": "J9EZM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZM4"
  },
  {
    "uniprot": "A0A183XDB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDB8"
  },
  {
    "uniprot": "A0A3P7GKE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKE0"
  },
  {
    "uniprot": "A0A3P7DTH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTH4"
  },
  {
    "uniprot": "A0A3P7DST0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DST0"
  },
  {
    "uniprot": "A0A3P7G5A0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5A0"
  },
  {
    "uniprot": "A0A3P7EUP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUP6"
  },
  {
    "uniprot": "A0A3P7F1T0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1T0"
  },
  {
    "uniprot": "J9EZF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZF0"
  },
  {
    "uniprot": "A0A3P7G5T0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5T0"
  },
  {
    "uniprot": "A0A3P7FEL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEL0"
  },
  {
    "uniprot": "A0A183XLG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLG6"
  },
  {
    "uniprot": "A0A3P7DLB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLB3"
  },
  {
    "uniprot": "A0A3P7DNP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNP4"
  },
  {
    "uniprot": "J9EXR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXR7"
  },
  {
    "uniprot": "A0A3P7FB47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB47"
  },
  {
    "uniprot": "A0A3P7ELP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELP0"
  },
  {
    "uniprot": "A0A3P7DWK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWK4"
  },
  {
    "uniprot": "A0A3P7DX15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX15"
  },
  {
    "uniprot": "A0A3P7FF02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF02"
  },
  {
    "uniprot": "A0A3P7DUN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUN7"
  },
  {
    "uniprot": "A0A3P7EA46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA46"
  },
  {
    "uniprot": "A0A3P7E1K0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1K0"
  },
  {
    "uniprot": "A0A3P7FS64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS64"
  },
  {
    "uniprot": "A0A3P7EG42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG42"
  },
  {
    "uniprot": "A0A3P7ECT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECT8"
  },
  {
    "uniprot": "A0A3P7FIG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIG8"
  },
  {
    "uniprot": "A0A3P7FUG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUG4"
  },
  {
    "uniprot": "A0A3P7EPG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPG3"
  },
  {
    "uniprot": "A0A3P7E230",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E230"
  },
  {
    "uniprot": "J9EYI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYI8"
  },
  {
    "uniprot": "A0A3P7EWK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWK7"
  },
  {
    "uniprot": "J9EQH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQH0"
  },
  {
    "uniprot": "A0A3P7DH58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH58"
  },
  {
    "uniprot": "A0A3P7G8A1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8A1"
  },
  {
    "uniprot": "A0A183XHL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XHL5"
  },
  {
    "uniprot": "A0A3P7DZF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZF1"
  },
  {
    "uniprot": "A0A3P7EIA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIA5"
  },
  {
    "uniprot": "A0A3P7E1S5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1S5"
  },
  {
    "uniprot": "A0A3P7DEA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEA3"
  },
  {
    "uniprot": "A0A3P7DZQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZQ8"
  },
  {
    "uniprot": "A0A3P7FIS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIS0"
  },
  {
    "uniprot": "J9BHX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BHX2"
  },
  {
    "uniprot": "A0A3P7DK50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK50"
  },
  {
    "uniprot": "A0A3P7EPY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPY7"
  },
  {
    "uniprot": "A0A3P7DZM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZM9"
  },
  {
    "uniprot": "A0A3P7DLX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLX3"
  },
  {
    "uniprot": "A0A3P7DYN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYN5"
  },
  {
    "uniprot": "A0A3P7GIM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIM5"
  },
  {
    "uniprot": "A0A3P7FJZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJZ7"
  },
  {
    "uniprot": "A0A3P7DRA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRA2"
  },
  {
    "uniprot": "A0A3P7E4D3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4D3"
  },
  {
    "uniprot": "A0A3P7GBU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBU4"
  },
  {
    "uniprot": "A0A3P7DQ13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ13"
  },
  {
    "uniprot": "A0A3P7EJF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJF7"
  },
  {
    "uniprot": "A0A3P7EDQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDQ6"
  },
  {
    "uniprot": "A0A3P7DSJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSJ6"
  },
  {
    "uniprot": "A0A3P7EV36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EV36"
  },
  {
    "uniprot": "A0A3P7GA99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GA99"
  },
  {
    "uniprot": "A0A3P7DXA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXA6"
  },
  {
    "uniprot": "A0A3P7FCR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCR9"
  },
  {
    "uniprot": "A0A3P7DRT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRT6"
  },
  {
    "uniprot": "A0A3P7F2Z9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2Z9"
  },
  {
    "uniprot": "A0A3P7DTL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTL4"
  },
  {
    "uniprot": "J9B516",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B516"
  },
  {
    "uniprot": "J9BJQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BJQ8"
  },
  {
    "uniprot": "A0A3P7DZ24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ24"
  },
  {
    "uniprot": "A0A183XDN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDN4"
  },
  {
    "uniprot": "A0A3P7FVY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVY4"
  },
  {
    "uniprot": "A0A3P7ECW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECW7"
  },
  {
    "uniprot": "A0A3P7EJ54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ54"
  },
  {
    "uniprot": "J9DZN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DZN2"
  },
  {
    "uniprot": "J9BI24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BI24"
  },
  {
    "uniprot": "A0A3P7EFQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFQ8"
  },
  {
    "uniprot": "A0A3P7FHC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHC0"
  },
  {
    "uniprot": "A0A3P7E617",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E617"
  },
  {
    "uniprot": "A0A3P7DTF8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTF8"
  },
  {
    "uniprot": "A0A3P7FDR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDR9"
  },
  {
    "uniprot": "J9AB39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AB39"
  },
  {
    "uniprot": "A0A3P7FDF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDF1"
  },
  {
    "uniprot": "A0A3P7DK61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK61"
  },
  {
    "uniprot": "J9F0U5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F0U5"
  },
  {
    "uniprot": "J9FER8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FER8"
  },
  {
    "uniprot": "A0A3P7EV27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EV27"
  },
  {
    "uniprot": "A0A3P7DHM9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHM9"
  },
  {
    "uniprot": "A0A3P7FDN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDN8"
  },
  {
    "uniprot": "A0A3P7E7T4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7T4"
  },
  {
    "uniprot": "A0A3P7DV57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV57"
  },
  {
    "uniprot": "A0A3P7G0B2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0B2"
  },
  {
    "uniprot": "A0A3P7FQY5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQY5"
  },
  {
    "uniprot": "A0A183XQ24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XQ24"
  },
  {
    "uniprot": "A0A3P7E8R4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8R4"
  },
  {
    "uniprot": "A0A3P7GMG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GMG6"
  },
  {
    "uniprot": "A0A3P7DV46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV46"
  },
  {
    "uniprot": "A0A3P7EWD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWD3"
  },
  {
    "uniprot": "A0A3P7DR50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR50"
  },
  {
    "uniprot": "A0A3P7DIB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIB8"
  },
  {
    "uniprot": "A0A3P7EMP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMP5"
  },
  {
    "uniprot": "A0A3P7DI02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI02"
  },
  {
    "uniprot": "A0A3P7EGH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGH2"
  },
  {
    "uniprot": "A0A3P7ED70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED70"
  },
  {
    "uniprot": "A0A183XTW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XTW7"
  },
  {
    "uniprot": "A0A3P7EGX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGX4"
  },
  {
    "uniprot": "A0A3P7EKV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKV4"
  },
  {
    "uniprot": "A0A3P7EKS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKS2"
  },
  {
    "uniprot": "A0A3P7EX18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EX18"
  },
  {
    "uniprot": "A0A3P7DUY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUY2"
  },
  {
    "uniprot": "J9F1C0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1C0"
  },
  {
    "uniprot": "J9DPG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DPG2"
  },
  {
    "uniprot": "A0A3P7E3W0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3W0"
  },
  {
    "uniprot": "A0A3P7G3K3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3K3"
  },
  {
    "uniprot": "A0A3P7E0A2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0A2"
  },
  {
    "uniprot": "A0A3P7FTS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTS5"
  },
  {
    "uniprot": "J9BK96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BK96"
  },
  {
    "uniprot": "A0A3P7EB32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB32"
  },
  {
    "uniprot": "A0A3P7EDR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDR1"
  },
  {
    "uniprot": "A0A3P7E5R2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5R2"
  },
  {
    "uniprot": "A0A3P7E146",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E146"
  },
  {
    "uniprot": "A0A3P7DUY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUY9"
  },
  {
    "uniprot": "A0A3P7E4X0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4X0"
  },
  {
    "uniprot": "J9F9K0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F9K0"
  },
  {
    "uniprot": "A0A3P7E0U1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0U1"
  },
  {
    "uniprot": "A0A3P7G0Z3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0Z3"
  },
  {
    "uniprot": "A0A3P7ECB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECB2"
  },
  {
    "uniprot": "A0A183XIA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XIA7"
  },
  {
    "uniprot": "A0A3P7EAL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAL0"
  },
  {
    "uniprot": "A0A3P7DWG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWG7"
  },
  {
    "uniprot": "A0A3P7FJE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJE6"
  },
  {
    "uniprot": "A0A3P7EUH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUH7"
  },
  {
    "uniprot": "A0A3P7DYA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYA1"
  },
  {
    "uniprot": "A0A3P7FCC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCC7"
  },
  {
    "uniprot": "J9EZC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZC3"
  },
  {
    "uniprot": "A0A3P7DSJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSJ3"
  },
  {
    "uniprot": "A0A3P7FNT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNT0"
  },
  {
    "uniprot": "A0A3P7E8J7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8J7"
  },
  {
    "uniprot": "A0A3P7DPU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPU3"
  },
  {
    "uniprot": "A0A3P7GDF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDF7"
  },
  {
    "uniprot": "A0A3P7DXV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXV0"
  },
  {
    "uniprot": "A0A3P7FJG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJG7"
  },
  {
    "uniprot": "A0A3P7E5J9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5J9"
  },
  {
    "uniprot": "J9F4P3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F4P3"
  },
  {
    "uniprot": "A0A3P7E043",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E043"
  },
  {
    "uniprot": "A0A3P7FBS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBS9"
  },
  {
    "uniprot": "A0A3P7FGF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGF5"
  },
  {
    "uniprot": "A0A3P7DQN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQN5"
  },
  {
    "uniprot": "A0A3P7EBN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBN4"
  },
  {
    "uniprot": "A0A3P7FZL4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZL4"
  },
  {
    "uniprot": "A0A3P7EG25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG25"
  },
  {
    "uniprot": "A0A3P7FFM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFM6"
  },
  {
    "uniprot": "A0A3P7EGF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGF6"
  },
  {
    "uniprot": "J9FJE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FJE4"
  },
  {
    "uniprot": "A0A3P7DD84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD84"
  },
  {
    "uniprot": "A0A3P7ED74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED74"
  },
  {
    "uniprot": "A0A3P7FWE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWE4"
  },
  {
    "uniprot": "A0A3P7FK60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK60"
  },
  {
    "uniprot": "A0A183XCD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCD6"
  },
  {
    "uniprot": "A0A3P7EBV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBV5"
  },
  {
    "uniprot": "A0A3P7E486",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E486"
  },
  {
    "uniprot": "A0A3P7DR21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR21"
  },
  {
    "uniprot": "A0A3P7DBV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBV1"
  },
  {
    "uniprot": "A0A3P7DSJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSJ9"
  },
  {
    "uniprot": "A0A3P7FRU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRU6"
  },
  {
    "uniprot": "A0A3P7E2C5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2C5"
  },
  {
    "uniprot": "A0A3P7GAA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAA3"
  },
  {
    "uniprot": "A0A3P7DZA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZA4"
  },
  {
    "uniprot": "A0A3P7EEM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEM7"
  },
  {
    "uniprot": "A0A3P7E613",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E613"
  },
  {
    "uniprot": "A0A3P7DWP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWP9"
  },
  {
    "uniprot": "A0A3P7DPT5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPT5"
  },
  {
    "uniprot": "A0A3P7E891",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E891"
  },
  {
    "uniprot": "J9F0T5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F0T5"
  },
  {
    "uniprot": "A0A3P7DN41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN41"
  },
  {
    "uniprot": "A0A3P7EJT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJT1"
  },
  {
    "uniprot": "A0A3P7DW03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW03"
  },
  {
    "uniprot": "A0A3P7GFC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFC1"
  },
  {
    "uniprot": "A0A3P7EHF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHF4"
  },
  {
    "uniprot": "A0A3P7FW86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW86"
  },
  {
    "uniprot": "A0A3P7DMW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMW3"
  },
  {
    "uniprot": "A0A3P7E265",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E265"
  },
  {
    "uniprot": "A0A3P7FS72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS72"
  },
  {
    "uniprot": "A0A3P7E7Q1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7Q1"
  },
  {
    "uniprot": "A0A3P7E6P7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6P7"
  },
  {
    "uniprot": "A0A3P7DGA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGA3"
  },
  {
    "uniprot": "A0A3P7DPI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPI3"
  },
  {
    "uniprot": "A0A3P7EI50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI50"
  },
  {
    "uniprot": "A0A3P7E0T8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0T8"
  },
  {
    "uniprot": "A0A3P7EJU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJU9"
  },
  {
    "uniprot": "A0A3P7E1V2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1V2"
  },
  {
    "uniprot": "A0A3P7DN27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN27"
  },
  {
    "uniprot": "A0A3P7G090",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G090"
  },
  {
    "uniprot": "A0A3P7DTX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTX1"
  },
  {
    "uniprot": "A0A3P7E4V9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4V9"
  },
  {
    "uniprot": "A0A3P7DHI0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHI0"
  },
  {
    "uniprot": "A0A3P7FKQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKQ1"
  },
  {
    "uniprot": "J9F754",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F754"
  },
  {
    "uniprot": "A0A3P7FTC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTC5"
  },
  {
    "uniprot": "A0A3P7E3E6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3E6"
  },
  {
    "uniprot": "A0A3P7E239",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E239"
  },
  {
    "uniprot": "A0A3P7EVP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVP3"
  },
  {
    "uniprot": "A0A3P7DT02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT02"
  },
  {
    "uniprot": "A0A3P7G2B8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2B8"
  },
  {
    "uniprot": "J9BHS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BHS9"
  },
  {
    "uniprot": "A0A3P7DSY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSY0"
  },
  {
    "uniprot": "A0A3P7DUB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUB1"
  },
  {
    "uniprot": "A0A3P7EAT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAT1"
  },
  {
    "uniprot": "A0A3P7EKV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKV3"
  },
  {
    "uniprot": "A0A3P7E1Y7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1Y7"
  },
  {
    "uniprot": "A0A3P7FVC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVC3"
  },
  {
    "uniprot": "A0A3P7FS97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS97"
  },
  {
    "uniprot": "A0A3P7G660",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G660"
  },
  {
    "uniprot": "A0A3P7DTU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTU7"
  },
  {
    "uniprot": "A0A183XV59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XV59"
  },
  {
    "uniprot": "A0A3P7DKK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKK1"
  },
  {
    "uniprot": "A0A3P7FEB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEB5"
  },
  {
    "uniprot": "A0A3P7DSI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSI7"
  },
  {
    "uniprot": "A0A3P7EXH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXH8"
  },
  {
    "uniprot": "A0A3P7FX34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX34"
  },
  {
    "uniprot": "A0A3P7E3N4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3N4"
  },
  {
    "uniprot": "A0A3P7F951",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F951"
  },
  {
    "uniprot": "A0A3P7DI32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI32"
  },
  {
    "uniprot": "A0A3P7GD74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GD74"
  },
  {
    "uniprot": "A0A3P7E1S7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1S7"
  },
  {
    "uniprot": "A0A3P7FWG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWG6"
  },
  {
    "uniprot": "A0A3P7FL76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL76"
  },
  {
    "uniprot": "A0A3P7GP50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GP50"
  },
  {
    "uniprot": "A0A3P7FVX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVX3"
  },
  {
    "uniprot": "A0A3P7DYN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYN1"
  },
  {
    "uniprot": "A0A3P7DZB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZB9"
  },
  {
    "uniprot": "A0A3P7DSP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSP4"
  },
  {
    "uniprot": "A0A3P7E681",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E681"
  },
  {
    "uniprot": "A0A3P7FCV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCV2"
  },
  {
    "uniprot": "A0A3P7EYQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYQ7"
  },
  {
    "uniprot": "J9EZG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZG1"
  },
  {
    "uniprot": "A0A3P7ECC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECC6"
  },
  {
    "uniprot": "A0A3P7E9F9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9F9"
  },
  {
    "uniprot": "A0A3P7DVF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVF1"
  },
  {
    "uniprot": "A0A3P7DZ14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ14"
  },
  {
    "uniprot": "A0A3P7DGU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGU7"
  },
  {
    "uniprot": "A0A3P7EFL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFL0"
  },
  {
    "uniprot": "A0A3P7DTT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTT0"
  },
  {
    "uniprot": "A0A3P7FHT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHT4"
  },
  {
    "uniprot": "A0A3P7EYL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYL9"
  },
  {
    "uniprot": "A0A3P7EAA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAA0"
  },
  {
    "uniprot": "A0A3P7DZB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZB8"
  },
  {
    "uniprot": "A0A3P7DVB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVB8"
  },
  {
    "uniprot": "A0A3P7G9C3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9C3"
  },
  {
    "uniprot": "J9EFC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EFC6"
  },
  {
    "uniprot": "A0A3P7DM93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM93"
  },
  {
    "uniprot": "A0A3P7EDH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDH4"
  },
  {
    "uniprot": "A0A3P7DNL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNL6"
  },
  {
    "uniprot": "A0A3P7E9C5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9C5"
  },
  {
    "uniprot": "A0A3P7DMB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMB1"
  },
  {
    "uniprot": "A0A3P7EE26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE26"
  },
  {
    "uniprot": "A0A3P7ERM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERM0"
  },
  {
    "uniprot": "J9F4R2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F4R2"
  },
  {
    "uniprot": "A0A3P7DV10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV10"
  },
  {
    "uniprot": "A0A3P7DS32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS32"
  },
  {
    "uniprot": "A0A3P7DR13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR13"
  },
  {
    "uniprot": "A0A3P7EDT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDT6"
  },
  {
    "uniprot": "A0A3P7DZ38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ38"
  },
  {
    "uniprot": "A0A3P7GAR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAR6"
  },
  {
    "uniprot": "A0A3P7E7J8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7J8"
  },
  {
    "uniprot": "J9F447",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F447"
  },
  {
    "uniprot": "A0A3P7DM83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM83"
  },
  {
    "uniprot": "A0A3P7EXC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXC9"
  },
  {
    "uniprot": "A0A3P7ECH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECH4"
  },
  {
    "uniprot": "A0A3P7ENM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENM7"
  },
  {
    "uniprot": "A0A3P7DFI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFI1"
  },
  {
    "uniprot": "A0A3P7E5X4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5X4"
  },
  {
    "uniprot": "A0A3P7E4J1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4J1"
  },
  {
    "uniprot": "A0A183XRX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XRX0"
  },
  {
    "uniprot": "A0A3P7DJY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJY6"
  },
  {
    "uniprot": "J9EIQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EIQ7"
  },
  {
    "uniprot": "A0A3P7FMK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMK2"
  },
  {
    "uniprot": "A0A3P7GKV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKV2"
  },
  {
    "uniprot": "A0A3P7FJ87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ87"
  },
  {
    "uniprot": "A0A3P7DZP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZP1"
  },
  {
    "uniprot": "A0A3P7E1B2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1B2"
  },
  {
    "uniprot": "A0A3P7G612",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G612"
  },
  {
    "uniprot": "A0A3P7EX61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EX61"
  },
  {
    "uniprot": "A0A3P7EAJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAJ0"
  },
  {
    "uniprot": "A0A3P7ESR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESR2"
  },
  {
    "uniprot": "A0A3P7F2F4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2F4"
  },
  {
    "uniprot": "A0A3P7E6G4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6G4"
  },
  {
    "uniprot": "A0A3P7FPI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPI7"
  },
  {
    "uniprot": "A0A3P7DRA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRA4"
  },
  {
    "uniprot": "J9FEH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FEH1"
  },
  {
    "uniprot": "A0A3P7DBC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBC0"
  },
  {
    "uniprot": "A0A3P7EIY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIY3"
  },
  {
    "uniprot": "A0A3P7DXV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXV7"
  },
  {
    "uniprot": "A0A3P7F0K6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0K6"
  },
  {
    "uniprot": "A0A3P7E6E9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6E9"
  },
  {
    "uniprot": "A0A3P7EBD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBD0"
  },
  {
    "uniprot": "A0A3P7FLQ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLQ9"
  },
  {
    "uniprot": "A0A3P7GB50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GB50"
  },
  {
    "uniprot": "A0A3P7DCR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCR7"
  },
  {
    "uniprot": "A0A3P7DZA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZA3"
  },
  {
    "uniprot": "A0A3P7E8E0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8E0"
  },
  {
    "uniprot": "A0A3P7DYT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYT8"
  },
  {
    "uniprot": "J9ERA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERA9"
  },
  {
    "uniprot": "A0A3P7DKN5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKN5"
  },
  {
    "uniprot": "A0A3P7GGY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGY9"
  },
  {
    "uniprot": "A0A3P7DWU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWU2"
  },
  {
    "uniprot": "A0A3P7DU95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU95"
  },
  {
    "uniprot": "A0A3P7DT62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT62"
  },
  {
    "uniprot": "A0A3P7FYG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYG8"
  },
  {
    "uniprot": "A0A3P7FXY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXY9"
  },
  {
    "uniprot": "A0A3P7DX70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX70"
  },
  {
    "uniprot": "A0A3P7DL07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL07"
  },
  {
    "uniprot": "A0A3P7DWQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWQ1"
  },
  {
    "uniprot": "A0A3P7F3F3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3F3"
  },
  {
    "uniprot": "A0A3P7GBG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBG5"
  },
  {
    "uniprot": "J9B7R3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B7R3"
  },
  {
    "uniprot": "A0A3P7FYK8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYK8"
  },
  {
    "uniprot": "A0A3P7DWH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWH1"
  },
  {
    "uniprot": "A0A3P7EE40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE40"
  },
  {
    "uniprot": "A0A3P7GNA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GNA8"
  },
  {
    "uniprot": "A0A3P7DZC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZC3"
  },
  {
    "uniprot": "A0A3P7DMS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMS6"
  },
  {
    "uniprot": "A0A183XIJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XIJ6"
  },
  {
    "uniprot": "A0A3P7E0T7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0T7"
  },
  {
    "uniprot": "A0A3P7DJS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJS0"
  },
  {
    "uniprot": "A0A3P7DY08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY08"
  },
  {
    "uniprot": "A0A3P7FJP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJP0"
  },
  {
    "uniprot": "A0A3P7EBU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBU2"
  },
  {
    "uniprot": "A0A3P7DYH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYH0"
  },
  {
    "uniprot": "A0A3P7DW13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW13"
  },
  {
    "uniprot": "A0A3P7DIR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIR5"
  },
  {
    "uniprot": "A0A3P7DGE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGE4"
  },
  {
    "uniprot": "A0A3P7EJP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJP1"
  },
  {
    "uniprot": "A0A3P7GJX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJX8"
  },
  {
    "uniprot": "A0A3P7DEI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEI8"
  },
  {
    "uniprot": "A0A3P7ED03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED03"
  },
  {
    "uniprot": "A0A3P7ECW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECW8"
  },
  {
    "uniprot": "J9FCK1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FCK1"
  },
  {
    "uniprot": "J9BLL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BLL7"
  },
  {
    "uniprot": "A0A3P7FQ68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ68"
  },
  {
    "uniprot": "J9BKU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BKU2"
  },
  {
    "uniprot": "A0A3P7FET1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FET1"
  },
  {
    "uniprot": "A0A3P7EGL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGL8"
  },
  {
    "uniprot": "A0A3P7ED29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED29"
  },
  {
    "uniprot": "A0A3P7F2M7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2M7"
  },
  {
    "uniprot": "A0A3P7DH40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH40"
  },
  {
    "uniprot": "A0A3P7DY03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY03"
  },
  {
    "uniprot": "J9FDG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FDG1"
  },
  {
    "uniprot": "A0A3P7E259",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E259"
  },
  {
    "uniprot": "A0A3P7E7Y5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7Y5"
  },
  {
    "uniprot": "A0A3P7E9W1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9W1"
  },
  {
    "uniprot": "J9FJR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FJR3"
  },
  {
    "uniprot": "A0A3P7DVF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVF6"
  },
  {
    "uniprot": "A0A3P7DWW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWW9"
  },
  {
    "uniprot": "A0A3P7DYH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYH8"
  },
  {
    "uniprot": "A0A3P7DLQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLQ8"
  },
  {
    "uniprot": "A0A3P7DSP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSP0"
  },
  {
    "uniprot": "A0A3P7FV26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV26"
  },
  {
    "uniprot": "A0A3P7E1M2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1M2"
  },
  {
    "uniprot": "A0A3P7EF56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF56"
  },
  {
    "uniprot": "A0A3P7DI12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI12"
  },
  {
    "uniprot": "A0A3P7DYM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYM5"
  },
  {
    "uniprot": "A0A3P7E490",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E490"
  },
  {
    "uniprot": "J9EYI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYI5"
  },
  {
    "uniprot": "J9FDW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FDW3"
  },
  {
    "uniprot": "A0A3P7FJV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJV0"
  },
  {
    "uniprot": "A0A3P7FDL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDL6"
  },
  {
    "uniprot": "J9F1C6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1C6"
  },
  {
    "uniprot": "A0A3P7DDH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDH4"
  },
  {
    "uniprot": "A0A3P7GD84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GD84"
  },
  {
    "uniprot": "A0A3P7G7N5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G7N5"
  },
  {
    "uniprot": "A0A3P7FGX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGX4"
  },
  {
    "uniprot": "A0A3P7EDP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDP8"
  },
  {
    "uniprot": "A0A3P7FGC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGC7"
  },
  {
    "uniprot": "A0A3P7FL55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL55"
  },
  {
    "uniprot": "A0A3P7DSC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSC2"
  },
  {
    "uniprot": "A0A3P7EDT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDT0"
  },
  {
    "uniprot": "A0A3P7GGE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGE9"
  },
  {
    "uniprot": "J9F194",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F194"
  },
  {
    "uniprot": "J9EM65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EM65"
  },
  {
    "uniprot": "A0A3P7E9A2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9A2"
  },
  {
    "uniprot": "A0A3P7E8F4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8F4"
  },
  {
    "uniprot": "A0A3P7DSX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSX7"
  },
  {
    "uniprot": "A0A3P7FJX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJX2"
  },
  {
    "uniprot": "A0A183XFD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XFD9"
  },
  {
    "uniprot": "J9EN14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EN14"
  },
  {
    "uniprot": "A0A3P7ED99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED99"
  },
  {
    "uniprot": "J9F265",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F265"
  },
  {
    "uniprot": "A0A3P7E757",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E757"
  },
  {
    "uniprot": "A0A3P7DRR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRR3"
  },
  {
    "uniprot": "A0A3P7FIF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIF0"
  },
  {
    "uniprot": "J9FFF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FFF6"
  },
  {
    "uniprot": "A0A3P7ED17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED17"
  },
  {
    "uniprot": "A0A3P7EFN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFN3"
  },
  {
    "uniprot": "A0A3P7DGP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGP6"
  },
  {
    "uniprot": "A0A3P7GFI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GFI3"
  },
  {
    "uniprot": "A0A3P7EBK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBK0"
  },
  {
    "uniprot": "A0A183XH59",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XH59"
  },
  {
    "uniprot": "A0A3P7FTV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTV4"
  },
  {
    "uniprot": "A0A3P7FU72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU72"
  },
  {
    "uniprot": "A0A3P7FJ71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ71"
  },
  {
    "uniprot": "A0A3P7F3Z3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3Z3"
  },
  {
    "uniprot": "A0A3P7ED00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED00"
  },
  {
    "uniprot": "A0A3P7GKR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKR3"
  },
  {
    "uniprot": "A0A3P7G0D4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0D4"
  },
  {
    "uniprot": "A0A3P7FGA2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGA2"
  },
  {
    "uniprot": "A0A3P7EZ85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZ85"
  },
  {
    "uniprot": "A0A3P7DM50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM50"
  },
  {
    "uniprot": "A0A3P7FMD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMD0"
  },
  {
    "uniprot": "A0A3P7FDB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDB6"
  },
  {
    "uniprot": "A0A3P7E6W3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6W3"
  },
  {
    "uniprot": "A0A3P7EH00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH00"
  },
  {
    "uniprot": "A0A3P7F881",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F881"
  },
  {
    "uniprot": "A0A3P7F720",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F720"
  },
  {
    "uniprot": "A0A3P7FGK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGK7"
  },
  {
    "uniprot": "A0A183XI58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XI58"
  },
  {
    "uniprot": "A0A3P7DSD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSD0"
  },
  {
    "uniprot": "J9FK76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FK76"
  },
  {
    "uniprot": "A0A3P7E8S6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8S6"
  },
  {
    "uniprot": "A0A3P7FSG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSG7"
  },
  {
    "uniprot": "A0A3P7FN47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN47"
  },
  {
    "uniprot": "A0A3P7DMU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMU1"
  },
  {
    "uniprot": "A0A3P7DRW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRW8"
  },
  {
    "uniprot": "A0A3P7DI85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI85"
  },
  {
    "uniprot": "A0A3P7DWJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWJ8"
  },
  {
    "uniprot": "A0A3P7DTH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTH2"
  },
  {
    "uniprot": "J9EQP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQP7"
  },
  {
    "uniprot": "A0A3P7E667",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E667"
  },
  {
    "uniprot": "A0A3P7DRV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRV8"
  },
  {
    "uniprot": "A0A3P7DJZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJZ5"
  },
  {
    "uniprot": "A0A3P7DD74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD74"
  },
  {
    "uniprot": "A0A3P7FMI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMI3"
  },
  {
    "uniprot": "A0A3P7DVG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVG3"
  },
  {
    "uniprot": "A0A183XPE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XPE5"
  },
  {
    "uniprot": "A0A3P7FND6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FND6"
  },
  {
    "uniprot": "A0A3P7F2C9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2C9"
  },
  {
    "uniprot": "A0A3P7DFE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFE2"
  },
  {
    "uniprot": "A0A3P7FS91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FS91"
  },
  {
    "uniprot": "J9EPI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPI2"
  },
  {
    "uniprot": "A0A183XP89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XP89"
  },
  {
    "uniprot": "A0A3P7DRZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRZ4"
  },
  {
    "uniprot": "J9FF55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FF55"
  },
  {
    "uniprot": "A0A3P7EJ51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJ51"
  },
  {
    "uniprot": "A0A3P7EPT7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPT7"
  },
  {
    "uniprot": "A0A3P7EG08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG08"
  },
  {
    "uniprot": "A0A3P7FR99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR99"
  },
  {
    "uniprot": "A0A3P7DSD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSD8"
  },
  {
    "uniprot": "A0A3P7FRM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRM8"
  },
  {
    "uniprot": "A0A3P7FQV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQV7"
  },
  {
    "uniprot": "J9EYE5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYE5"
  },
  {
    "uniprot": "A0A3P7EAP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAP4"
  },
  {
    "uniprot": "A0A3P7E312",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E312"
  },
  {
    "uniprot": "A0A3P7DTP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTP8"
  },
  {
    "uniprot": "A0A3P7DKX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKX7"
  },
  {
    "uniprot": "A0A3P7FIR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIR8"
  },
  {
    "uniprot": "A0A3P7GKP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKP3"
  },
  {
    "uniprot": "A0A3P7DRG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRG5"
  },
  {
    "uniprot": "A0A3P7FZU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZU7"
  },
  {
    "uniprot": "A0A3P7DY36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY36"
  },
  {
    "uniprot": "A0A3P7E6M8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6M8"
  },
  {
    "uniprot": "A0A3P7ER11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ER11"
  },
  {
    "uniprot": "A0A3P7G1U3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1U3"
  },
  {
    "uniprot": "A0A3P7FDQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDQ7"
  },
  {
    "uniprot": "A0A3P7EFX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFX7"
  },
  {
    "uniprot": "A0A3P7DKH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKH9"
  },
  {
    "uniprot": "A0A3P7FGS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGS0"
  },
  {
    "uniprot": "A0A3P7DSS4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSS4"
  },
  {
    "uniprot": "A0A3P7FTB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTB3"
  },
  {
    "uniprot": "A0A183XYF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XYF3"
  },
  {
    "uniprot": "A0A3P7EMU8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMU8"
  },
  {
    "uniprot": "A0A3P7DVA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVA4"
  },
  {
    "uniprot": "A0A3P7E668",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E668"
  },
  {
    "uniprot": "A0A3P7FEU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEU1"
  },
  {
    "uniprot": "A0A3P7EUG0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUG0"
  },
  {
    "uniprot": "J9FG45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FG45"
  },
  {
    "uniprot": "A0A3P7E7W6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7W6"
  },
  {
    "uniprot": "A0A3P7DTV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTV3"
  },
  {
    "uniprot": "A0A3P7FF58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF58"
  },
  {
    "uniprot": "A0A3P7DSC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSC1"
  },
  {
    "uniprot": "A0A183XKP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XKP5"
  },
  {
    "uniprot": "J9F1Z8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1Z8"
  },
  {
    "uniprot": "A0A3P7DT41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT41"
  },
  {
    "uniprot": "A0A3P7E4P8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4P8"
  },
  {
    "uniprot": "A0A3P7DET3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DET3"
  },
  {
    "uniprot": "A0A3P7E5Q9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5Q9"
  },
  {
    "uniprot": "A0A3P7E5K7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5K7"
  },
  {
    "uniprot": "A0A3P7E9I3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9I3"
  },
  {
    "uniprot": "A0A3P7FEL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEL7"
  },
  {
    "uniprot": "J9BAN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BAN6"
  },
  {
    "uniprot": "A0A3P7DE51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE51"
  },
  {
    "uniprot": "A0A3P7FX14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX14"
  },
  {
    "uniprot": "A0A3P7GME3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GME3"
  },
  {
    "uniprot": "A0A3P7DYV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYV5"
  },
  {
    "uniprot": "A0A3P7FZB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZB8"
  },
  {
    "uniprot": "A0A3P7FET5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FET5"
  },
  {
    "uniprot": "A0A3P7DTP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTP7"
  },
  {
    "uniprot": "A0A3P7EVK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVK2"
  },
  {
    "uniprot": "A0A3P7FVP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVP8"
  },
  {
    "uniprot": "A0A3P7DGZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGZ1"
  },
  {
    "uniprot": "A0A3P7EEK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEK6"
  },
  {
    "uniprot": "A0A3P7GCQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCQ2"
  },
  {
    "uniprot": "A0A3P7ENH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENH4"
  },
  {
    "uniprot": "J9EVX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EVX1"
  },
  {
    "uniprot": "A0A3P7EE78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE78"
  },
  {
    "uniprot": "A0A3P7ED19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED19"
  },
  {
    "uniprot": "A0A183XLX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLX5"
  },
  {
    "uniprot": "A0A3P7FJ27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ27"
  },
  {
    "uniprot": "A0A3P7DI22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI22"
  },
  {
    "uniprot": "A0A3P7ES13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ES13"
  },
  {
    "uniprot": "A0A3P7G978",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G978"
  },
  {
    "uniprot": "A0A3P7GI62",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GI62"
  },
  {
    "uniprot": "A0A3P7EDN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDN2"
  },
  {
    "uniprot": "J9FCJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FCJ1"
  },
  {
    "uniprot": "A0A3P7E4D6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4D6"
  },
  {
    "uniprot": "A0A3P7FWN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWN2"
  },
  {
    "uniprot": "A0A183Y7M7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y7M7"
  },
  {
    "uniprot": "A0A3P7DP32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP32"
  },
  {
    "uniprot": "A0A3P7GLF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLF5"
  },
  {
    "uniprot": "A0A3P7FB58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB58"
  },
  {
    "uniprot": "A0A3P7G0C3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0C3"
  },
  {
    "uniprot": "J9FCB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FCB3"
  },
  {
    "uniprot": "A0A183Y282",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y282"
  },
  {
    "uniprot": "A0A3P7EH31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH31"
  },
  {
    "uniprot": "J9F8S0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F8S0"
  },
  {
    "uniprot": "A0A3P7E779",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E779"
  },
  {
    "uniprot": "A0A3P7EPI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPI2"
  },
  {
    "uniprot": "A0A3P7DX09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX09"
  },
  {
    "uniprot": "A0A3P7E2I3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2I3"
  },
  {
    "uniprot": "A0A3P7FDI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDI5"
  },
  {
    "uniprot": "A0A3P7GMJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GMJ9"
  },
  {
    "uniprot": "A0A3P7EEH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEH7"
  },
  {
    "uniprot": "J9F174",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F174"
  },
  {
    "uniprot": "A0A3P7EE65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE65"
  },
  {
    "uniprot": "A0A3P7E058",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E058"
  },
  {
    "uniprot": "A0A3P7DNX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNX9"
  },
  {
    "uniprot": "A0A3P7DRF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRF6"
  },
  {
    "uniprot": "A0A3P7E843",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E843"
  },
  {
    "uniprot": "A0A3P7EC23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC23"
  },
  {
    "uniprot": "A0A3P7FAG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAG3"
  },
  {
    "uniprot": "A0A3P7DYD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYD6"
  },
  {
    "uniprot": "J9AII6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AII6"
  },
  {
    "uniprot": "A0A3P7FGP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGP9"
  },
  {
    "uniprot": "A0A3P7E3E4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3E4"
  },
  {
    "uniprot": "A0A3P7DEP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEP8"
  },
  {
    "uniprot": "A0A3P7F2Z6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F2Z6"
  },
  {
    "uniprot": "A0A3P7E4A3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4A3"
  },
  {
    "uniprot": "A0A3P7DNH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNH7"
  },
  {
    "uniprot": "J9EFU6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EFU6"
  },
  {
    "uniprot": "A0A3P7DMM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMM5"
  },
  {
    "uniprot": "A0A3P7ERK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ERK0"
  },
  {
    "uniprot": "A0A3P7F316",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F316"
  },
  {
    "uniprot": "A0A183XLS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLS8"
  },
  {
    "uniprot": "A0A3P7ET01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET01"
  },
  {
    "uniprot": "A0A3P7DV56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV56"
  },
  {
    "uniprot": "A0A3P7FT23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT23"
  },
  {
    "uniprot": "A0A3P7FUF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUF0"
  },
  {
    "uniprot": "A0A3P7DYT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYT2"
  },
  {
    "uniprot": "J9E361",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E361"
  },
  {
    "uniprot": "A0A3P7E3Y4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3Y4"
  },
  {
    "uniprot": "J9E6Z6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E6Z6"
  },
  {
    "uniprot": "A0A3P7EG33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG33"
  },
  {
    "uniprot": "A0A3P7FAB0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAB0"
  },
  {
    "uniprot": "A0A3P7EEM8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEM8"
  },
  {
    "uniprot": "A0A3P7E640",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E640"
  },
  {
    "uniprot": "A0A3P7DVK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVK3"
  },
  {
    "uniprot": "A0A3P7DXC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXC5"
  },
  {
    "uniprot": "A0A3P7FJF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJF3"
  },
  {
    "uniprot": "A0A3P7G7H0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G7H0"
  },
  {
    "uniprot": "J9AXA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AXA1"
  },
  {
    "uniprot": "A0A3P7G2K6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2K6"
  },
  {
    "uniprot": "A0A3P7E1Q0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1Q0"
  },
  {
    "uniprot": "A0A3P7DY81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY81"
  },
  {
    "uniprot": "A0A3P7EQQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQQ1"
  },
  {
    "uniprot": "A0A3P7DRC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRC3"
  },
  {
    "uniprot": "A0A3P7E9Q5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9Q5"
  },
  {
    "uniprot": "J9FA84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FA84"
  },
  {
    "uniprot": "A0A3P7E3N6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3N6"
  },
  {
    "uniprot": "A0A3P7EI54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI54"
  },
  {
    "uniprot": "A0A3P7GLH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLH2"
  },
  {
    "uniprot": "A0A3P7EE63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE63"
  },
  {
    "uniprot": "A0A183XRL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XRL2"
  },
  {
    "uniprot": "A0A3P7EF10",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF10"
  },
  {
    "uniprot": "A0A3P7EQN1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQN1"
  },
  {
    "uniprot": "A0A3P7DVA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVA0"
  },
  {
    "uniprot": "A0A3P7EB80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB80"
  },
  {
    "uniprot": "A0A3P7FFA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFA1"
  },
  {
    "uniprot": "A0A3P7E3V2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3V2"
  },
  {
    "uniprot": "A0A3P7DEW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEW5"
  },
  {
    "uniprot": "A0A3P7E4C7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4C7"
  },
  {
    "uniprot": "A0A3P7FZ07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ07"
  },
  {
    "uniprot": "A0A3P7DS25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS25"
  },
  {
    "uniprot": "A0A3P7FVT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVT9"
  },
  {
    "uniprot": "A0A3P7E691",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E691"
  },
  {
    "uniprot": "A0A3P7GIS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GIS1"
  },
  {
    "uniprot": "A0A3P7DQG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQG6"
  },
  {
    "uniprot": "A0A3P7DD09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD09"
  },
  {
    "uniprot": "A0A3P7DPV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPV7"
  },
  {
    "uniprot": "A0A3P7FHZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHZ1"
  },
  {
    "uniprot": "A0A3P7EL16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL16"
  },
  {
    "uniprot": "A0A3P7DSH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSH9"
  },
  {
    "uniprot": "A0A3P7EK34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK34"
  },
  {
    "uniprot": "A0A3P7G322",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G322"
  },
  {
    "uniprot": "A0A3P7ER60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ER60"
  },
  {
    "uniprot": "A0A3P7FG75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG75"
  },
  {
    "uniprot": "A0A183Y6I4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y6I4"
  },
  {
    "uniprot": "A0A3P7E2H6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2H6"
  },
  {
    "uniprot": "A0A3P7E1H1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1H1"
  },
  {
    "uniprot": "J9E0Y5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E0Y5"
  },
  {
    "uniprot": "A0A3P7DAI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAI5"
  },
  {
    "uniprot": "A0A3P7DBR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBR1"
  },
  {
    "uniprot": "A0A3P7DXH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXH9"
  },
  {
    "uniprot": "A0A3P7FGM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGM6"
  },
  {
    "uniprot": "A0A3P7FKZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKZ9"
  },
  {
    "uniprot": "A0A3P7FJC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJC9"
  },
  {
    "uniprot": "A0A3P7DH39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DH39"
  },
  {
    "uniprot": "A0A3P7DYB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYB8"
  },
  {
    "uniprot": "A0A3P7EGP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGP5"
  },
  {
    "uniprot": "A0A3P7E5S7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5S7"
  },
  {
    "uniprot": "A0A3P7DVZ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVZ0"
  },
  {
    "uniprot": "J9F8D9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F8D9"
  },
  {
    "uniprot": "A0A3P7E7L6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7L6"
  },
  {
    "uniprot": "A0A3P7DYD4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYD4"
  },
  {
    "uniprot": "A0A3P7F809",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F809"
  },
  {
    "uniprot": "A0A3P7EDV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDV5"
  },
  {
    "uniprot": "A0A3P7EH13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH13"
  },
  {
    "uniprot": "A0A3P7DM96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM96"
  },
  {
    "uniprot": "A0A3P7EY84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY84"
  },
  {
    "uniprot": "A0A3P7EWI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWI3"
  },
  {
    "uniprot": "A0A3P7DWL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWL7"
  },
  {
    "uniprot": "A0A3P7E573",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E573"
  },
  {
    "uniprot": "A0A3P7ED60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED60"
  },
  {
    "uniprot": "A0A3P7FWP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWP2"
  },
  {
    "uniprot": "A0A3P7ED52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED52"
  },
  {
    "uniprot": "A0A3P7DCL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCL2"
  },
  {
    "uniprot": "A0A3P7DVT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVT9"
  },
  {
    "uniprot": "A0A3P7E073",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E073"
  },
  {
    "uniprot": "A0A3P7GF49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GF49"
  },
  {
    "uniprot": "A0A183XKG7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XKG7"
  },
  {
    "uniprot": "A0A3P7DW57",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW57"
  },
  {
    "uniprot": "A0A3P7EE66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE66"
  },
  {
    "uniprot": "A0A3P7E3B2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3B2"
  },
  {
    "uniprot": "A0A3P7DR40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR40"
  },
  {
    "uniprot": "A0A3P7E4E6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4E6"
  },
  {
    "uniprot": "A0A3P7E1N7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1N7"
  },
  {
    "uniprot": "A0A3P7DV53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV53"
  },
  {
    "uniprot": "A0A3P7E572",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E572"
  },
  {
    "uniprot": "A0A3P7EB91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB91"
  },
  {
    "uniprot": "A0A3P7F3C4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3C4"
  },
  {
    "uniprot": "A0A3P7EZU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZU2"
  },
  {
    "uniprot": "A0A3P7EGR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGR6"
  },
  {
    "uniprot": "A0A3P7E954",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E954"
  },
  {
    "uniprot": "A0A183XEB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XEB1"
  },
  {
    "uniprot": "A0A3P7E3I6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3I6"
  },
  {
    "uniprot": "A0A3P7ETY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETY9"
  },
  {
    "uniprot": "A0A3P7FLR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLR8"
  },
  {
    "uniprot": "J9EUR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EUR5"
  },
  {
    "uniprot": "A0A3P7DI50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI50"
  },
  {
    "uniprot": "A0A3P7DSR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSR7"
  },
  {
    "uniprot": "A0A3P7FZT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZT2"
  },
  {
    "uniprot": "A0A3P7EE49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE49"
  },
  {
    "uniprot": "A0A3P7DQB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQB8"
  },
  {
    "uniprot": "A0A3P7GK03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GK03"
  },
  {
    "uniprot": "A0A3P7DAP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAP0"
  },
  {
    "uniprot": "A0A3P7G6L2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6L2"
  },
  {
    "uniprot": "A0A3P7FM77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM77"
  },
  {
    "uniprot": "J9B9J5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B9J5"
  },
  {
    "uniprot": "A0A3P7EB56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB56"
  },
  {
    "uniprot": "A0A3P7DUA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUA9"
  },
  {
    "uniprot": "A0A3P7DTW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTW1"
  },
  {
    "uniprot": "A0A3P7FJG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJG1"
  },
  {
    "uniprot": "A0A3P7E6B7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6B7"
  },
  {
    "uniprot": "A0A3P7FCB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCB7"
  },
  {
    "uniprot": "A0A3P7FR76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR76"
  },
  {
    "uniprot": "A0A3P7DLD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLD2"
  },
  {
    "uniprot": "A0A3P7F7U8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F7U8"
  },
  {
    "uniprot": "A0A3P7FI63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI63"
  },
  {
    "uniprot": "A0A3P7DLK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLK6"
  },
  {
    "uniprot": "J9F2D4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2D4"
  },
  {
    "uniprot": "A0A3P7FCC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCC9"
  },
  {
    "uniprot": "A0A3P7DKX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKX5"
  },
  {
    "uniprot": "A0A3P7EA72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA72"
  },
  {
    "uniprot": "A0A3P7DPI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPI8"
  },
  {
    "uniprot": "A0A3P7EIC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIC9"
  },
  {
    "uniprot": "A0A3P7G3S4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3S4"
  },
  {
    "uniprot": "J9EP12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EP12"
  },
  {
    "uniprot": "A0A3P7GDH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDH7"
  },
  {
    "uniprot": "A0A3P7DK21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK21"
  },
  {
    "uniprot": "A0A3P7FHY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHY7"
  },
  {
    "uniprot": "A0A3P7FJ37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ37"
  },
  {
    "uniprot": "A0A3P7EII6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EII6"
  },
  {
    "uniprot": "J9FKG9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FKG9"
  },
  {
    "uniprot": "A0A3P7DLE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLE6"
  },
  {
    "uniprot": "A0A3P7DW58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW58"
  },
  {
    "uniprot": "A0A3P7E4I1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4I1"
  },
  {
    "uniprot": "A0A3P7GBX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBX2"
  },
  {
    "uniprot": "A0A3P7ENT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENT4"
  },
  {
    "uniprot": "A0A3P7FBH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBH4"
  },
  {
    "uniprot": "J9FLX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FLX1"
  },
  {
    "uniprot": "A0A3P7EM22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM22"
  },
  {
    "uniprot": "A0A3P7EDP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDP7"
  },
  {
    "uniprot": "J9F863",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F863"
  },
  {
    "uniprot": "A0A3P7G3S9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3S9"
  },
  {
    "uniprot": "A0A3P7DWY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWY1"
  },
  {
    "uniprot": "A0A3P7FNV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNV0"
  },
  {
    "uniprot": "A0A3P7DXH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXH4"
  },
  {
    "uniprot": "A0A3P7DGX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DGX5"
  },
  {
    "uniprot": "A0A3P7DJC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJC0"
  },
  {
    "uniprot": "A0A3P7DCX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCX3"
  },
  {
    "uniprot": "A0A3P7GCE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCE3"
  },
  {
    "uniprot": "J9FLJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FLJ5"
  },
  {
    "uniprot": "A0A3P7GC77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GC77"
  },
  {
    "uniprot": "A0A3P7EK21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK21"
  },
  {
    "uniprot": "A0A3P7G899",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G899"
  },
  {
    "uniprot": "A0A3P7EPR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPR7"
  },
  {
    "uniprot": "A0A3P7E068",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E068"
  },
  {
    "uniprot": "A0A3P7FF19",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF19"
  },
  {
    "uniprot": "A0A3P7EEU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EEU2"
  },
  {
    "uniprot": "A0A3P7FKY4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKY4"
  },
  {
    "uniprot": "A0A3P7FYQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYQ4"
  },
  {
    "uniprot": "A0A3P7DJZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJZ4"
  },
  {
    "uniprot": "A0A3P7DAK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAK4"
  },
  {
    "uniprot": "A0A3P7EFV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFV4"
  },
  {
    "uniprot": "A0A3P7E0Q1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0Q1"
  },
  {
    "uniprot": "A0A3P7DJ87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ87"
  },
  {
    "uniprot": "A0A3P7G1U1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1U1"
  },
  {
    "uniprot": "A0A3P7F1G4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1G4"
  },
  {
    "uniprot": "J9E4D4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E4D4"
  },
  {
    "uniprot": "A0A3P7DXU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXU5"
  },
  {
    "uniprot": "A0A3P7FGA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGA6"
  },
  {
    "uniprot": "A0A3P7FAW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAW5"
  },
  {
    "uniprot": "A0A3P7FVH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVH5"
  },
  {
    "uniprot": "J9F2B2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2B2"
  },
  {
    "uniprot": "A0A3P7FL45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL45"
  },
  {
    "uniprot": "A0A3P7G8T5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8T5"
  },
  {
    "uniprot": "A0A3P7ES53",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ES53"
  },
  {
    "uniprot": "J9B1V8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B1V8"
  },
  {
    "uniprot": "A0A3P7FGR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGR8"
  },
  {
    "uniprot": "A0A3P7E077",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E077"
  },
  {
    "uniprot": "A0A3P7G284",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G284"
  },
  {
    "uniprot": "A0A3P7DX92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX92"
  },
  {
    "uniprot": "J9F601",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F601"
  },
  {
    "uniprot": "A0A3P7DK51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK51"
  },
  {
    "uniprot": "A0A3P7EFI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFI7"
  },
  {
    "uniprot": "A0A3P7DRU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRU2"
  },
  {
    "uniprot": "A0A3P7DX34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX34"
  },
  {
    "uniprot": "J9EPJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPJ3"
  },
  {
    "uniprot": "A0A3P7FG24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG24"
  },
  {
    "uniprot": "A0A3P7E756",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E756"
  },
  {
    "uniprot": "A0A3P7FGH8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGH8"
  },
  {
    "uniprot": "A0A3P7EBJ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBJ8"
  },
  {
    "uniprot": "A0A3P7E929",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E929"
  },
  {
    "uniprot": "A0A3P7EB12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB12"
  },
  {
    "uniprot": "A0A3P7DXW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXW3"
  },
  {
    "uniprot": "A0A3P7F1E4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1E4"
  },
  {
    "uniprot": "A0A3P7DKZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKZ7"
  },
  {
    "uniprot": "A0A3P7FD77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD77"
  },
  {
    "uniprot": "A0A3P7ED54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED54"
  },
  {
    "uniprot": "A0A3P7FTN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTN8"
  },
  {
    "uniprot": "J9F2R3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2R3"
  },
  {
    "uniprot": "J9F642",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F642"
  },
  {
    "uniprot": "A0A3P7DPK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPK6"
  },
  {
    "uniprot": "A0A3P7EE41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE41"
  },
  {
    "uniprot": "A0A3P7E9N1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9N1"
  },
  {
    "uniprot": "A0A3P7ENZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENZ3"
  },
  {
    "uniprot": "A0A3P7EYM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYM6"
  },
  {
    "uniprot": "A0A3P7FWA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWA8"
  },
  {
    "uniprot": "A0A3P7EF34",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF34"
  },
  {
    "uniprot": "A0A3P7GHW8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHW8"
  },
  {
    "uniprot": "A0A3P7G232",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G232"
  },
  {
    "uniprot": "J9ER85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ER85"
  },
  {
    "uniprot": "A0A3P7F874",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F874"
  },
  {
    "uniprot": "A0A183XBV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XBV4"
  },
  {
    "uniprot": "A0A3P7E534",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E534"
  },
  {
    "uniprot": "A0A3P7E2X6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2X6"
  },
  {
    "uniprot": "A0A3P7DNZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNZ6"
  },
  {
    "uniprot": "A0A3P7DU58",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU58"
  },
  {
    "uniprot": "A0A3P7E5Y3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5Y3"
  },
  {
    "uniprot": "A0A3P7FWG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWG2"
  },
  {
    "uniprot": "A0A3P7DVE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVE0"
  },
  {
    "uniprot": "A0A3P7EAC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAC5"
  },
  {
    "uniprot": "A0A3P7DUZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUZ4"
  },
  {
    "uniprot": "J9DXI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DXI2"
  },
  {
    "uniprot": "A0A3P7DMN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMN8"
  },
  {
    "uniprot": "A0A3P7FHX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHX9"
  },
  {
    "uniprot": "A0A3P7E3E1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3E1"
  },
  {
    "uniprot": "J9F1T5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F1T5"
  },
  {
    "uniprot": "A0A3P7DVH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVH3"
  },
  {
    "uniprot": "A0A3P7EAM7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAM7"
  },
  {
    "uniprot": "J9DNK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DNK6"
  },
  {
    "uniprot": "A0A3P7FD89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD89"
  },
  {
    "uniprot": "A0A3P7E1R7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1R7"
  },
  {
    "uniprot": "A0A3P7DTA3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTA3"
  },
  {
    "uniprot": "A0A3P7EIZ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIZ3"
  },
  {
    "uniprot": "A0A3P7DWV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWV8"
  },
  {
    "uniprot": "J9BBY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BBY1"
  },
  {
    "uniprot": "A0A3P7EFC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFC0"
  },
  {
    "uniprot": "A0A3P7EDB2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDB2"
  },
  {
    "uniprot": "A0A183XWV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XWV0"
  },
  {
    "uniprot": "A0A3P7EAX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAX1"
  },
  {
    "uniprot": "A0A3P7E8C5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8C5"
  },
  {
    "uniprot": "A0A3P7FXT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXT2"
  },
  {
    "uniprot": "A0A183XB90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XB90"
  },
  {
    "uniprot": "A0A3P7EKK7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKK7"
  },
  {
    "uniprot": "A0A3P7ET96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET96"
  },
  {
    "uniprot": "A0A3P7DQP2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQP2"
  },
  {
    "uniprot": "A0A3P7E211",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E211"
  },
  {
    "uniprot": "A0A3P7DQX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQX5"
  },
  {
    "uniprot": "A0A3P7EKJ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKJ2"
  },
  {
    "uniprot": "J9FE73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FE73"
  },
  {
    "uniprot": "A0A3P7FI92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI92"
  },
  {
    "uniprot": "A0A3P7FRP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRP1"
  },
  {
    "uniprot": "A0A3P7EGH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGH1"
  },
  {
    "uniprot": "J9ES38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ES38"
  },
  {
    "uniprot": "A0A3P7ECP8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECP8"
  },
  {
    "uniprot": "A0A3P7E476",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E476"
  },
  {
    "uniprot": "J9DPL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DPL3"
  },
  {
    "uniprot": "A0A3P7EH43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH43"
  },
  {
    "uniprot": "A0A3P7DEH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEH0"
  },
  {
    "uniprot": "A0A3P7FYB5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYB5"
  },
  {
    "uniprot": "A0A3P7G1E4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1E4"
  },
  {
    "uniprot": "A0A3P7FL35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL35"
  },
  {
    "uniprot": "A0A3P7EV48",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EV48"
  },
  {
    "uniprot": "J9B896",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B896"
  },
  {
    "uniprot": "J9F198",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F198"
  },
  {
    "uniprot": "A0A3P7G3Q0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3Q0"
  },
  {
    "uniprot": "A0A3P7FMU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMU9"
  },
  {
    "uniprot": "A0A3P7DV49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV49"
  },
  {
    "uniprot": "J9EGL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EGL0"
  },
  {
    "uniprot": "A0A3P7DCU5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCU5"
  },
  {
    "uniprot": "A0A3P7DKM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKM1"
  },
  {
    "uniprot": "A0A3P7F8M7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8M7"
  },
  {
    "uniprot": "A0A3P7F9H8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9H8"
  },
  {
    "uniprot": "A0A3P7FW76",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW76"
  },
  {
    "uniprot": "A0A3P7F006",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F006"
  },
  {
    "uniprot": "J9FCA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FCA5"
  },
  {
    "uniprot": "A0A3P7EL32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL32"
  },
  {
    "uniprot": "A0A183XS94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XS94"
  },
  {
    "uniprot": "A0A3P7E6K4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6K4"
  },
  {
    "uniprot": "A0A3P7DKR0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKR0"
  },
  {
    "uniprot": "A0A3P7FR88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR88"
  },
  {
    "uniprot": "A0A3P7DS39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS39"
  },
  {
    "uniprot": "A0A3P7EMV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMV5"
  },
  {
    "uniprot": "A0A3P7EAQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAQ2"
  },
  {
    "uniprot": "A0A3P7EE22",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EE22"
  },
  {
    "uniprot": "A0A183Y0I3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y0I3"
  },
  {
    "uniprot": "A0A3P7DWA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWA0"
  },
  {
    "uniprot": "A0A3P7DBW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBW0"
  },
  {
    "uniprot": "A0A3P7EMN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMN7"
  },
  {
    "uniprot": "A0A3P7FJ32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJ32"
  },
  {
    "uniprot": "A0A3P7DLX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLX1"
  },
  {
    "uniprot": "A0A3P7ELY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELY2"
  },
  {
    "uniprot": "A0A3P7DQ93",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ93"
  },
  {
    "uniprot": "A0A3P7GJ06",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJ06"
  },
  {
    "uniprot": "A0A3P7GGJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGJ4"
  },
  {
    "uniprot": "A0A3P7FHK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHK4"
  },
  {
    "uniprot": "A0A3P7E7B5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7B5"
  },
  {
    "uniprot": "A0A3P7DCM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCM4"
  },
  {
    "uniprot": "A0A3P7DUV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUV8"
  },
  {
    "uniprot": "A0A3P7ES18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ES18"
  },
  {
    "uniprot": "A0A3P7DPW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPW4"
  },
  {
    "uniprot": "A0A3P7EKB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKB3"
  },
  {
    "uniprot": "A0A3P7E6D9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6D9"
  },
  {
    "uniprot": "J9F103",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F103"
  },
  {
    "uniprot": "A0A3P7EC75",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC75"
  },
  {
    "uniprot": "A0A3P7FHW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHW6"
  },
  {
    "uniprot": "A0A3P7FD98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD98"
  },
  {
    "uniprot": "A0A3P7ESK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESK0"
  },
  {
    "uniprot": "A0A3P7GM26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GM26"
  },
  {
    "uniprot": "A0A3P7EF44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF44"
  },
  {
    "uniprot": "A0A3P7E131",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E131"
  },
  {
    "uniprot": "A0A3P7G0D8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0D8"
  },
  {
    "uniprot": "A0A3P7EAF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAF5"
  },
  {
    "uniprot": "A0A3P7FA55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FA55"
  },
  {
    "uniprot": "A0A3P7DTV8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTV8"
  },
  {
    "uniprot": "A0A3P7E2A1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2A1"
  },
  {
    "uniprot": "A0A3P7ETS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETS2"
  },
  {
    "uniprot": "J9DXA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DXA5"
  },
  {
    "uniprot": "A0A3P7DAS7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DAS7"
  },
  {
    "uniprot": "A0A3P7EGC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGC7"
  },
  {
    "uniprot": "A0A3P7DZF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZF4"
  },
  {
    "uniprot": "A0A3P7E2G8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2G8"
  },
  {
    "uniprot": "A0A3P7FGT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGT6"
  },
  {
    "uniprot": "A0A3P7DN03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN03"
  },
  {
    "uniprot": "A0A3P7DPX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPX7"
  },
  {
    "uniprot": "J9EJE1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EJE1"
  },
  {
    "uniprot": "A0A3P7DT47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT47"
  },
  {
    "uniprot": "A0A3P7DXS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXS5"
  },
  {
    "uniprot": "A0A3P7GLI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLI4"
  },
  {
    "uniprot": "A0A3P7FIS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIS9"
  },
  {
    "uniprot": "J9FEG5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FEG5"
  },
  {
    "uniprot": "A0A3P7FRL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRL7"
  },
  {
    "uniprot": "A0A3P7DZD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZD5"
  },
  {
    "uniprot": "A0A3P7DB26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DB26"
  },
  {
    "uniprot": "A0A3P7FQ03",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ03"
  },
  {
    "uniprot": "A0A3P7EAQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAQ6"
  },
  {
    "uniprot": "A0A3P7FN44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FN44"
  },
  {
    "uniprot": "J9DYC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DYC0"
  },
  {
    "uniprot": "A0A183XBX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XBX7"
  },
  {
    "uniprot": "A0A3P7DKL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKL1"
  },
  {
    "uniprot": "A0A3P7E2I9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2I9"
  },
  {
    "uniprot": "A0A3P7ETR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETR5"
  },
  {
    "uniprot": "A0A3P7DZ25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ25"
  },
  {
    "uniprot": "A0A3P7DTM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTM1"
  },
  {
    "uniprot": "A0A3P7EGD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGD6"
  },
  {
    "uniprot": "J9DPU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DPU4"
  },
  {
    "uniprot": "A0A3P7FTH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTH7"
  },
  {
    "uniprot": "A0A3P7DQ88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ88"
  },
  {
    "uniprot": "A0A3P7EKZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKZ8"
  },
  {
    "uniprot": "A0A3P7DJL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJL7"
  },
  {
    "uniprot": "J9B447",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B447"
  },
  {
    "uniprot": "A0A3P7E3N7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3N7"
  },
  {
    "uniprot": "J9F9X9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F9X9"
  },
  {
    "uniprot": "A0A3P7EC12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC12"
  },
  {
    "uniprot": "A0A3P7GGC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGC2"
  },
  {
    "uniprot": "A0A3P7GGI2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGI2"
  },
  {
    "uniprot": "A0A3P7GLD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLD6"
  },
  {
    "uniprot": "A0A3P7E3A1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3A1"
  },
  {
    "uniprot": "J9EMJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EMJ7"
  },
  {
    "uniprot": "A0A3P7DG44",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG44"
  },
  {
    "uniprot": "A0A3P7DHS4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHS4"
  },
  {
    "uniprot": "A0A3P7FXK5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXK5"
  },
  {
    "uniprot": "A0A3P7FB11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB11"
  },
  {
    "uniprot": "J9F3Q3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F3Q3"
  },
  {
    "uniprot": "A0A3P7DUQ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUQ2"
  },
  {
    "uniprot": "A0A3P7FKF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKF5"
  },
  {
    "uniprot": "A0A3P7FEG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEG4"
  },
  {
    "uniprot": "A0A3P7DZV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZV5"
  },
  {
    "uniprot": "A0A3P7E3H4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3H4"
  },
  {
    "uniprot": "A0A3P7F826",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F826"
  },
  {
    "uniprot": "A0A3P7FLA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLA6"
  },
  {
    "uniprot": "A0A3P7GL02",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GL02"
  },
  {
    "uniprot": "A0A3P7FDE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDE8"
  },
  {
    "uniprot": "A0A3P7GNB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GNB6"
  },
  {
    "uniprot": "A0A3P7DWH7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWH7"
  },
  {
    "uniprot": "A0A3P7FZA7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZA7"
  },
  {
    "uniprot": "A0A3P7F9U6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9U6"
  },
  {
    "uniprot": "A0A3P7FAA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAA9"
  },
  {
    "uniprot": "J9EZB8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZB8"
  },
  {
    "uniprot": "A0A3P7DR09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR09"
  },
  {
    "uniprot": "A0A3P7DTV1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTV1"
  },
  {
    "uniprot": "A0A3P7DYD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYD0"
  },
  {
    "uniprot": "A0A3P7E1L4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1L4"
  },
  {
    "uniprot": "A0A3P7G274",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G274"
  },
  {
    "uniprot": "A0A3P7DUV6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUV6"
  },
  {
    "uniprot": "A0A3P7F318",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F318"
  },
  {
    "uniprot": "A0A3P7DUR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUR6"
  },
  {
    "uniprot": "J9EL72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EL72"
  },
  {
    "uniprot": "A0A3P7ECD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECD6"
  },
  {
    "uniprot": "A0A3P7FPD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPD1"
  },
  {
    "uniprot": "A0A3P7FXD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXD0"
  },
  {
    "uniprot": "A0A3P7FP66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP66"
  },
  {
    "uniprot": "A0A3P7F754",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F754"
  },
  {
    "uniprot": "A0A3P7E232",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E232"
  },
  {
    "uniprot": "A0A183XWW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XWW9"
  },
  {
    "uniprot": "A0A3P7EN52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EN52"
  },
  {
    "uniprot": "J9EYW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYW0"
  },
  {
    "uniprot": "A0A3P7DVW0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVW0"
  },
  {
    "uniprot": "A0A3P7E0B6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0B6"
  },
  {
    "uniprot": "A0A3P7EIY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIY2"
  },
  {
    "uniprot": "A0A3P7GEL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEL2"
  },
  {
    "uniprot": "A0A3P7GCD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCD6"
  },
  {
    "uniprot": "A0A3P7E3I0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3I0"
  },
  {
    "uniprot": "A0A3P7FX78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX78"
  },
  {
    "uniprot": "A0A3P7EIJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIJ0"
  },
  {
    "uniprot": "A0A3P7DYI1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYI1"
  },
  {
    "uniprot": "A0A3P7EZQ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZQ8"
  },
  {
    "uniprot": "A0A3P7FBG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBG4"
  },
  {
    "uniprot": "A0A183Y3U2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y3U2"
  },
  {
    "uniprot": "A0A3P7F940",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F940"
  },
  {
    "uniprot": "A0A3P7E6X5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6X5"
  },
  {
    "uniprot": "A0A3P7DUX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUX4"
  },
  {
    "uniprot": "A0A3P7E901",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E901"
  },
  {
    "uniprot": "A0A3P7DVZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVZ7"
  },
  {
    "uniprot": "A0A3P7E6E2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6E2"
  },
  {
    "uniprot": "A0A3P7ELW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELW5"
  },
  {
    "uniprot": "J9E1H0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E1H0"
  },
  {
    "uniprot": "A0A3P7E773",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E773"
  },
  {
    "uniprot": "A0A3P7FWT0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWT0"
  },
  {
    "uniprot": "A0A3P7FSX4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSX4"
  },
  {
    "uniprot": "A0A183XDU0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDU0"
  },
  {
    "uniprot": "A0A3P7DP67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP67"
  },
  {
    "uniprot": "J9DTS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DTS2"
  },
  {
    "uniprot": "A0A3P7E5K6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5K6"
  },
  {
    "uniprot": "A0A3P7FQW2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQW2"
  },
  {
    "uniprot": "A0A3P7EMA1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMA1"
  },
  {
    "uniprot": "A0A3P7DJC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJC5"
  },
  {
    "uniprot": "A0A3P7EQ83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQ83"
  },
  {
    "uniprot": "A0A3P7G3M5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3M5"
  },
  {
    "uniprot": "J9F489",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F489"
  },
  {
    "uniprot": "A0A3P7DX20",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX20"
  },
  {
    "uniprot": "A0A3P7EGB1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGB1"
  },
  {
    "uniprot": "A0A3P7DXM1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXM1"
  },
  {
    "uniprot": "A0A3P7DJ83",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJ83"
  },
  {
    "uniprot": "A0A3P7G5W9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5W9"
  },
  {
    "uniprot": "A0A3P7E255",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E255"
  },
  {
    "uniprot": "A0A3P7G0W0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G0W0"
  },
  {
    "uniprot": "A0A3P7DQ37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQ37"
  },
  {
    "uniprot": "A0A3P7EXX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXX8"
  },
  {
    "uniprot": "A0A3P7DTS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTS0"
  },
  {
    "uniprot": "A0A3P7FZ40",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ40"
  },
  {
    "uniprot": "J9EGV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EGV2"
  },
  {
    "uniprot": "A0A3P7EI43",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI43"
  },
  {
    "uniprot": "A0A3P7E1T9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1T9"
  },
  {
    "uniprot": "A0A3P7G037",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G037"
  },
  {
    "uniprot": "A0A3P7FF36",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF36"
  },
  {
    "uniprot": "A0A183XIH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XIH3"
  },
  {
    "uniprot": "J9FE14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FE14"
  },
  {
    "uniprot": "A0A3P7DB46",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DB46"
  },
  {
    "uniprot": "A0A3P7EW60",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EW60"
  },
  {
    "uniprot": "A0A3P7EY39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY39"
  },
  {
    "uniprot": "A0A3P7E6H9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6H9"
  },
  {
    "uniprot": "A0A3P7E673",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E673"
  },
  {
    "uniprot": "A0A3P7E0V7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0V7"
  },
  {
    "uniprot": "A0A3P7E2L4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2L4"
  },
  {
    "uniprot": "A0A3P7FB26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FB26"
  },
  {
    "uniprot": "A0A3P7ET86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ET86"
  },
  {
    "uniprot": "A0A3P7GL80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GL80"
  },
  {
    "uniprot": "A0A3P7E8C2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8C2"
  },
  {
    "uniprot": "A0A3P7DZA9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZA9"
  },
  {
    "uniprot": "A0A3P7DWL6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWL6"
  },
  {
    "uniprot": "A0A3P7FXH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXH5"
  },
  {
    "uniprot": "A0A3P7FI68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FI68"
  },
  {
    "uniprot": "A0A183XCQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XCQ4"
  },
  {
    "uniprot": "A0A3P7FZI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZI7"
  },
  {
    "uniprot": "A0A3P7GGY7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GGY7"
  },
  {
    "uniprot": "A0A3P7EL74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL74"
  },
  {
    "uniprot": "A0A3P7FFC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFC0"
  },
  {
    "uniprot": "A0A3P7EX17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EX17"
  },
  {
    "uniprot": "A0A3P7E727",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E727"
  },
  {
    "uniprot": "A0A3P7DVD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVD2"
  },
  {
    "uniprot": "A0A3P7G1S4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1S4"
  },
  {
    "uniprot": "A0A3P7G3E2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3E2"
  },
  {
    "uniprot": "A0A3P7EK74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK74"
  },
  {
    "uniprot": "A0A3P7E244",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E244"
  },
  {
    "uniprot": "J9F296",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F296"
  },
  {
    "uniprot": "A0A3P7DLF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLF1"
  },
  {
    "uniprot": "A0A3P7DDY6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDY6"
  },
  {
    "uniprot": "A0A3P7DF99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF99"
  },
  {
    "uniprot": "A0A3P7FQA8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQA8"
  },
  {
    "uniprot": "A0A3P7DF11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF11"
  },
  {
    "uniprot": "A0A3P7FEN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEN6"
  },
  {
    "uniprot": "A0A3P7FAF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAF5"
  },
  {
    "uniprot": "A0A3P7DWS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWS1"
  },
  {
    "uniprot": "A0A3P7FJC6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJC6"
  },
  {
    "uniprot": "A0A3P7FJD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJD9"
  },
  {
    "uniprot": "A0A3P7E931",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E931"
  },
  {
    "uniprot": "A0A3P7FUS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUS3"
  },
  {
    "uniprot": "A0A3P7E1Y4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1Y4"
  },
  {
    "uniprot": "A0A3P7FRD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRD6"
  },
  {
    "uniprot": "A0A3P7E1V4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1V4"
  },
  {
    "uniprot": "A0A3P7DRT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRT2"
  },
  {
    "uniprot": "A0A3P7ECY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECY9"
  },
  {
    "uniprot": "A0A3P7EF32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF32"
  },
  {
    "uniprot": "A0A3P7FE16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE16"
  },
  {
    "uniprot": "A0A3P7FCL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCL7"
  },
  {
    "uniprot": "A0A3P7EAZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAZ6"
  },
  {
    "uniprot": "A0A3P7DQT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQT2"
  },
  {
    "uniprot": "A0A3P7FR66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FR66"
  },
  {
    "uniprot": "A0A3P7E353",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E353"
  },
  {
    "uniprot": "A0A3P7EJL3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJL3"
  },
  {
    "uniprot": "A0A3P7E046",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E046"
  },
  {
    "uniprot": "A0A3P7EA18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA18"
  },
  {
    "uniprot": "A0A3P7E6V5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6V5"
  },
  {
    "uniprot": "J9ED84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ED84"
  },
  {
    "uniprot": "A0A3P7DLU1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLU1"
  },
  {
    "uniprot": "A0A3P7DBD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBD0"
  },
  {
    "uniprot": "A0A3P7EHH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHH1"
  },
  {
    "uniprot": "A0A3P7DR96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR96"
  },
  {
    "uniprot": "A0A3P7EB05",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB05"
  },
  {
    "uniprot": "A0A3P7DX08",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX08"
  },
  {
    "uniprot": "A0A3P7EH73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH73"
  },
  {
    "uniprot": "A0A3P7DS21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS21"
  },
  {
    "uniprot": "A0A3P7FY65",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY65"
  },
  {
    "uniprot": "A0A3P7DRM2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRM2"
  },
  {
    "uniprot": "A0A3P7FKH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKH5"
  },
  {
    "uniprot": "A0A3P7FGS7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGS7"
  },
  {
    "uniprot": "A0A3P7ECZ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECZ5"
  },
  {
    "uniprot": "A0A3P7DWM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWM3"
  },
  {
    "uniprot": "A0A3P7DTZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTZ6"
  },
  {
    "uniprot": "A0A3P7E390",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E390"
  },
  {
    "uniprot": "A0A3P7ETV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETV5"
  },
  {
    "uniprot": "A0A3P7FM85",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM85"
  },
  {
    "uniprot": "A0A3P7DNI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNI8"
  },
  {
    "uniprot": "A0A3P7FV78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FV78"
  },
  {
    "uniprot": "A0A3P7DF94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF94"
  },
  {
    "uniprot": "A0A3P7FWC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWC3"
  },
  {
    "uniprot": "A0A3P7DYY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYY2"
  },
  {
    "uniprot": "A0A3P7FZE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZE2"
  },
  {
    "uniprot": "A0A3P7GF64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GF64"
  },
  {
    "uniprot": "A0A3P7E1K5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1K5"
  },
  {
    "uniprot": "J9FC04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FC04"
  },
  {
    "uniprot": "A0A3P7EPB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EPB9"
  },
  {
    "uniprot": "A0A3P7DJS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJS8"
  },
  {
    "uniprot": "A0A3P7EG64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG64"
  },
  {
    "uniprot": "A0A3P7FEC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEC5"
  },
  {
    "uniprot": "A0A3P7DQN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQN0"
  },
  {
    "uniprot": "A0A3P7DLZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLZ1"
  },
  {
    "uniprot": "A0A3P7FIL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIL8"
  },
  {
    "uniprot": "A0A3P7E414",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E414"
  },
  {
    "uniprot": "J9EM81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EM81"
  },
  {
    "uniprot": "A0A3P7FWB4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWB4"
  },
  {
    "uniprot": "A0A183XDZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XDZ1"
  },
  {
    "uniprot": "A0A3P7DSK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSK4"
  },
  {
    "uniprot": "A0A3P7E4F6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4F6"
  },
  {
    "uniprot": "A0A183Y5A1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y5A1"
  },
  {
    "uniprot": "A0A3P7DM51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM51"
  },
  {
    "uniprot": "J9BM12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BM12"
  },
  {
    "uniprot": "A0A3P7DZP6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZP6"
  },
  {
    "uniprot": "A0A3P7EAD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAD9"
  },
  {
    "uniprot": "A0A3P7E596",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E596"
  },
  {
    "uniprot": "J9BCC1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BCC1"
  },
  {
    "uniprot": "A0A3P7E2I5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2I5"
  },
  {
    "uniprot": "A0A3P7DXW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXW5"
  },
  {
    "uniprot": "A0A3P7DWR7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWR7"
  },
  {
    "uniprot": "A0A3P7GB56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GB56"
  },
  {
    "uniprot": "A0A3P7EFS2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFS2"
  },
  {
    "uniprot": "A0A3P7DX78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX78"
  },
  {
    "uniprot": "A0A3P7GCG1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCG1"
  },
  {
    "uniprot": "A0A3P7DW31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW31"
  },
  {
    "uniprot": "A0A3P7EG31",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG31"
  },
  {
    "uniprot": "A0A3P7FCR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCR6"
  },
  {
    "uniprot": "A0A183XD16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XD16"
  },
  {
    "uniprot": "A0A3P7FW18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW18"
  },
  {
    "uniprot": "A0A3P7E102",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E102"
  },
  {
    "uniprot": "A0A3P7FXE6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXE6"
  },
  {
    "uniprot": "A0A3P7DTE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTE3"
  },
  {
    "uniprot": "A0A3P7DV63",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV63"
  },
  {
    "uniprot": "A0A3P7DXB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXB7"
  },
  {
    "uniprot": "A0A3P7EF70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EF70"
  },
  {
    "uniprot": "A0A3P7DTP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTP0"
  },
  {
    "uniprot": "A0A3P7DHU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHU9"
  },
  {
    "uniprot": "A0A3P7E9J1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9J1"
  },
  {
    "uniprot": "A0A3P7FK61",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK61"
  },
  {
    "uniprot": "A0A3P7DHQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHQ0"
  },
  {
    "uniprot": "A0A3P7GC26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GC26"
  },
  {
    "uniprot": "A0A3P7DHL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHL5"
  },
  {
    "uniprot": "A0A3P7ELH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ELH0"
  },
  {
    "uniprot": "A0A3P7DKE9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKE9"
  },
  {
    "uniprot": "A0A3P7EAV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAV2"
  },
  {
    "uniprot": "A0A3P7GH38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GH38"
  },
  {
    "uniprot": "A0A183XR51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XR51"
  },
  {
    "uniprot": "A0A3P7DE37",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE37"
  },
  {
    "uniprot": "A0A3P7E8F9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8F9"
  },
  {
    "uniprot": "A0A3P7DUN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUN6"
  },
  {
    "uniprot": "A0A183XEY3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XEY3"
  },
  {
    "uniprot": "A0A3P7GCI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCI3"
  },
  {
    "uniprot": "A0A3P7GF13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GF13"
  },
  {
    "uniprot": "J9EZH5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EZH5"
  },
  {
    "uniprot": "A0A3P7GJC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GJC2"
  },
  {
    "uniprot": "A0A3P7FYZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYZ7"
  },
  {
    "uniprot": "A0A3P7EXG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXG2"
  },
  {
    "uniprot": "A0A3P7DUW7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUW7"
  },
  {
    "uniprot": "A0A3P7E8A2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8A2"
  },
  {
    "uniprot": "A0A3P7GEF5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEF5"
  },
  {
    "uniprot": "A0A3P7ECS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECS3"
  },
  {
    "uniprot": "A0A3P7DT92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT92"
  },
  {
    "uniprot": "A0A3P7DB01",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DB01"
  },
  {
    "uniprot": "J9F353",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F353"
  },
  {
    "uniprot": "A0A3P7DF27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF27"
  },
  {
    "uniprot": "A0A3P7DSM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSM3"
  },
  {
    "uniprot": "A0A3P7FFN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFN6"
  },
  {
    "uniprot": "A0A3P7FQI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQI9"
  },
  {
    "uniprot": "A0A3P7DVP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVP1"
  },
  {
    "uniprot": "A0A3P7E3W3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3W3"
  },
  {
    "uniprot": "A0A3P7E859",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E859"
  },
  {
    "uniprot": "A0A3P7D9Q3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7D9Q3"
  },
  {
    "uniprot": "A0A3P7E1V8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1V8"
  },
  {
    "uniprot": "A0A3P7DQE3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQE3"
  },
  {
    "uniprot": "J9EY15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EY15"
  },
  {
    "uniprot": "A0A3P7G263",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G263"
  },
  {
    "uniprot": "A0A3P7DRA5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRA5"
  },
  {
    "uniprot": "A0A3P7ETB3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETB3"
  },
  {
    "uniprot": "A0A3P7E011",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E011"
  },
  {
    "uniprot": "A0A3P7EAY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAY1"
  },
  {
    "uniprot": "J9ETZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ETZ9"
  },
  {
    "uniprot": "A0A3P7G6M0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G6M0"
  },
  {
    "uniprot": "A0A3P7E2R6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2R6"
  },
  {
    "uniprot": "A0A3P7ESX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESX9"
  },
  {
    "uniprot": "A0A3P7EXU7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EXU7"
  },
  {
    "uniprot": "A0A3P7DT94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT94"
  },
  {
    "uniprot": "A0A3P7GEK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEK9"
  },
  {
    "uniprot": "A0A3P7GDA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDA4"
  },
  {
    "uniprot": "A0A3P7EP94",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP94"
  },
  {
    "uniprot": "A0A3P7DY35",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY35"
  },
  {
    "uniprot": "A0A3P7F4I9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F4I9"
  },
  {
    "uniprot": "J9B918",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B918"
  },
  {
    "uniprot": "A0A3P7EIT6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIT6"
  },
  {
    "uniprot": "A0A3P7G3B8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3B8"
  },
  {
    "uniprot": "A0A3P7EKC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKC8"
  },
  {
    "uniprot": "A0A183XFD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XFD6"
  },
  {
    "uniprot": "A0A3P7E2G5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2G5"
  },
  {
    "uniprot": "A0A3P7DV26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DV26"
  },
  {
    "uniprot": "A0A183XUU2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XUU2"
  },
  {
    "uniprot": "A0A3P7DTI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTI7"
  },
  {
    "uniprot": "A0A3P7FCU4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCU4"
  },
  {
    "uniprot": "A0A3P7FNR5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNR5"
  },
  {
    "uniprot": "A0A3P7E8Y1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8Y1"
  },
  {
    "uniprot": "A0A3P7E679",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E679"
  },
  {
    "uniprot": "A0A3P7E6W7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6W7"
  },
  {
    "uniprot": "A0A183XD99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XD99"
  },
  {
    "uniprot": "A0A3P7ECP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECP4"
  },
  {
    "uniprot": "A0A3P7GBZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBZ1"
  },
  {
    "uniprot": "A0A3P7FHF4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FHF4"
  },
  {
    "uniprot": "A0A3P7DZQ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZQ0"
  },
  {
    "uniprot": "A0A3P7FFJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFJ4"
  },
  {
    "uniprot": "A0A3P7EIJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIJ6"
  },
  {
    "uniprot": "A0A3P7E1S6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1S6"
  },
  {
    "uniprot": "A0A3P7EG73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG73"
  },
  {
    "uniprot": "A0A3P7EYZ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYZ7"
  },
  {
    "uniprot": "A0A3P7EIA6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EIA6"
  },
  {
    "uniprot": "A0A3P7EME4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EME4"
  },
  {
    "uniprot": "A0A3P7EFM3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFM3"
  },
  {
    "uniprot": "A0A3P7E2P5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2P5"
  },
  {
    "uniprot": "A0A3P7FL42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FL42"
  },
  {
    "uniprot": "A0A3P7E0Z0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0Z0"
  },
  {
    "uniprot": "A0A3P7ESY2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ESY2"
  },
  {
    "uniprot": "A0A3P7EGD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGD9"
  },
  {
    "uniprot": "A0A3P7GAC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAC7"
  },
  {
    "uniprot": "A0A3P7DF73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF73"
  },
  {
    "uniprot": "A0A3P7EKR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKR2"
  },
  {
    "uniprot": "A0A3P7DIL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIL8"
  },
  {
    "uniprot": "J9EMH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EMH2"
  },
  {
    "uniprot": "A0A3P7FIZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIZ2"
  },
  {
    "uniprot": "A0A3P7E712",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E712"
  },
  {
    "uniprot": "A0A3P7DW11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW11"
  },
  {
    "uniprot": "J9AUS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AUS5"
  },
  {
    "uniprot": "A0A3P7DZI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZI4"
  },
  {
    "uniprot": "J9EKP3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EKP3"
  },
  {
    "uniprot": "A0A3P7E1T6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1T6"
  },
  {
    "uniprot": "A0A3P7FBG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBG3"
  },
  {
    "uniprot": "J9FI25",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FI25"
  },
  {
    "uniprot": "A0A3P7FBW9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FBW9"
  },
  {
    "uniprot": "A0A3P7EVE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVE0"
  },
  {
    "uniprot": "A0A183XRT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XRT9"
  },
  {
    "uniprot": "A0A3P7E0W2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0W2"
  },
  {
    "uniprot": "A0A3P7EDK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EDK4"
  },
  {
    "uniprot": "J9ECL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ECL2"
  },
  {
    "uniprot": "A0A3P7DP87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP87"
  },
  {
    "uniprot": "A0A3P7GLS6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLS6"
  },
  {
    "uniprot": "A0A3P7DXL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXL0"
  },
  {
    "uniprot": "A0A3P7EJJ3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJJ3"
  },
  {
    "uniprot": "A0A3P7EWD2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWD2"
  },
  {
    "uniprot": "A0A3P7DER0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DER0"
  },
  {
    "uniprot": "A0A3P7DTI3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTI3"
  },
  {
    "uniprot": "A0A3P7DMT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMT2"
  },
  {
    "uniprot": "A0A3P7E5B8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5B8"
  },
  {
    "uniprot": "A0A3P7EA68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA68"
  },
  {
    "uniprot": "A0A3P7DLF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLF0"
  },
  {
    "uniprot": "A0A3P7G2V7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2V7"
  },
  {
    "uniprot": "A0A3P7EBZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBZ2"
  },
  {
    "uniprot": "A0A3P7EH24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH24"
  },
  {
    "uniprot": "A0A3P7E0E3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0E3"
  },
  {
    "uniprot": "A0A3P7FU33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU33"
  },
  {
    "uniprot": "A0A3P7E3L8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3L8"
  },
  {
    "uniprot": "A0A3P7GAL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAL2"
  },
  {
    "uniprot": "A0A3P7E637",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E637"
  },
  {
    "uniprot": "A0A3P7ENH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENH0"
  },
  {
    "uniprot": "A0A3P7DNE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNE4"
  },
  {
    "uniprot": "A0A3P7EP45",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP45"
  },
  {
    "uniprot": "A0A3P7EAP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAP7"
  },
  {
    "uniprot": "A0A3P7FNP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNP1"
  },
  {
    "uniprot": "J9EJP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EJP7"
  },
  {
    "uniprot": "A0A3P7FXP1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXP1"
  },
  {
    "uniprot": "A0A3P7DHR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHR3"
  },
  {
    "uniprot": "J9EXN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXN2"
  },
  {
    "uniprot": "A0A3P7EL15",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EL15"
  },
  {
    "uniprot": "A0A3P7DZ99",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ99"
  },
  {
    "uniprot": "J9F2A5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F2A5"
  },
  {
    "uniprot": "A0A3P7ECG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECG8"
  },
  {
    "uniprot": "A0A3P7EWM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWM5"
  },
  {
    "uniprot": "A0A3P7E3Q1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3Q1"
  },
  {
    "uniprot": "A0A3P7EV97",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EV97"
  },
  {
    "uniprot": "A0A3P7E508",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E508"
  },
  {
    "uniprot": "A0A3P7FGE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FGE4"
  },
  {
    "uniprot": "A0A3P7GAF6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAF6"
  },
  {
    "uniprot": "A0A3P7FVU9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVU9"
  },
  {
    "uniprot": "A0A3P7E293",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E293"
  },
  {
    "uniprot": "A0A3P7DVH1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVH1"
  },
  {
    "uniprot": "A0A3P7FC50",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FC50"
  },
  {
    "uniprot": "A0A3P7FUX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUX5"
  },
  {
    "uniprot": "A0A3P7E5M4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5M4"
  },
  {
    "uniprot": "A0A3P7EAS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAS5"
  },
  {
    "uniprot": "A0A3P7F1S0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F1S0"
  },
  {
    "uniprot": "A0A3P7DPE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPE4"
  },
  {
    "uniprot": "J9EXW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EXW5"
  },
  {
    "uniprot": "J9E509",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9E509"
  },
  {
    "uniprot": "A0A3P7FTB6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTB6"
  },
  {
    "uniprot": "A0A3P7G8M9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G8M9"
  },
  {
    "uniprot": "A0A3P7GAZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAZ1"
  },
  {
    "uniprot": "A0A3P7G7K0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G7K0"
  },
  {
    "uniprot": "A0A3P7FVE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVE8"
  },
  {
    "uniprot": "A0A3P7FE74",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FE74"
  },
  {
    "uniprot": "A0A183XPH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XPH4"
  },
  {
    "uniprot": "A0A3P7DUM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUM5"
  },
  {
    "uniprot": "A0A3P7E185",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E185"
  },
  {
    "uniprot": "A0A3P7EUV7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUV7"
  },
  {
    "uniprot": "A0A3P7FU09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU09"
  },
  {
    "uniprot": "A0A3P7E723",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E723"
  },
  {
    "uniprot": "J9ERE0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ERE0"
  },
  {
    "uniprot": "A0A3P7ENQ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENQ5"
  },
  {
    "uniprot": "A0A3P7E319",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E319"
  },
  {
    "uniprot": "A0A183XJA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XJA4"
  },
  {
    "uniprot": "J9FHN3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FHN3"
  },
  {
    "uniprot": "A0A3P7EK55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EK55"
  },
  {
    "uniprot": "A0A3P7E8L5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8L5"
  },
  {
    "uniprot": "A0A3P7F9U3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F9U3"
  },
  {
    "uniprot": "A0A3P7E2Z2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2Z2"
  },
  {
    "uniprot": "A0A3P7GAX1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GAX1"
  },
  {
    "uniprot": "A0A3P7DML5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DML5"
  },
  {
    "uniprot": "A0A3P7ECW3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECW3"
  },
  {
    "uniprot": "A0A3P7DUI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUI7"
  },
  {
    "uniprot": "A0A3P7FTD8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FTD8"
  },
  {
    "uniprot": "J9BGZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BGZ6"
  },
  {
    "uniprot": "A0A3P7DY56",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY56"
  },
  {
    "uniprot": "A0A3P7E1F0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1F0"
  },
  {
    "uniprot": "A0A3P7FUW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUW4"
  },
  {
    "uniprot": "A0A3P7FH26",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FH26"
  },
  {
    "uniprot": "A0A3P7GCW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCW6"
  },
  {
    "uniprot": "A0A3P7FRV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRV0"
  },
  {
    "uniprot": "A0A3P7DQI5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQI5"
  },
  {
    "uniprot": "A0A3P7EHN2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHN2"
  },
  {
    "uniprot": "A0A3P7DUI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUI4"
  },
  {
    "uniprot": "A0A3P7FQ49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQ49"
  },
  {
    "uniprot": "A0A3P7DSN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSN8"
  },
  {
    "uniprot": "A0A3P7DL33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL33"
  },
  {
    "uniprot": "A0A183Y5Y3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y5Y3"
  },
  {
    "uniprot": "A0A3P7DCT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCT2"
  },
  {
    "uniprot": "A0A3P7FFX3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FFX3"
  },
  {
    "uniprot": "A0A3P7E4Z2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4Z2"
  },
  {
    "uniprot": "A0A3P7DS16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS16"
  },
  {
    "uniprot": "A0A3P7E556",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E556"
  },
  {
    "uniprot": "A0A3P7DUC7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUC7"
  },
  {
    "uniprot": "A0A3P7FCW5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FCW5"
  },
  {
    "uniprot": "A0A3P7FY13",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY13"
  },
  {
    "uniprot": "A0A3P7DMZ8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMZ8"
  },
  {
    "uniprot": "A0A3P7ETQ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ETQ1"
  },
  {
    "uniprot": "J9FHH9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FHH9"
  },
  {
    "uniprot": "A0A3P7DDW6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DDW6"
  },
  {
    "uniprot": "A0A3P7DR30",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR30"
  },
  {
    "uniprot": "A0A3P7GE91",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GE91"
  },
  {
    "uniprot": "A0A3P7EID1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EID1"
  },
  {
    "uniprot": "A0A3P7E4V3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4V3"
  },
  {
    "uniprot": "A0A3P7EW09",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EW09"
  },
  {
    "uniprot": "A0A3P7GBD1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GBD1"
  },
  {
    "uniprot": "A0A3P7F3H5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F3H5"
  },
  {
    "uniprot": "A0A3P7G102",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G102"
  },
  {
    "uniprot": "A0A3P7E0I0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0I0"
  },
  {
    "uniprot": "A0A3P7EWN4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWN4"
  },
  {
    "uniprot": "A0A3P7DPR8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPR8"
  },
  {
    "uniprot": "A0A3P7DM73",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM73"
  },
  {
    "uniprot": "A0A3P7DU07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU07"
  },
  {
    "uniprot": "A0A3P7F0J2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0J2"
  },
  {
    "uniprot": "A0A3P7DF89",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF89"
  },
  {
    "uniprot": "A0A183Y4R3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183Y4R3"
  },
  {
    "uniprot": "A0A3P7DL88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DL88"
  },
  {
    "uniprot": "A0A3P7DR82",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR82"
  },
  {
    "uniprot": "A0A3P7E5G7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5G7"
  },
  {
    "uniprot": "A0A3P7G9Z6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G9Z6"
  },
  {
    "uniprot": "J9FMS4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FMS4"
  },
  {
    "uniprot": "J9EPZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EPZ2"
  },
  {
    "uniprot": "A0A3P7DKH3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKH3"
  },
  {
    "uniprot": "A0A3P7E229",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E229"
  },
  {
    "uniprot": "A0A3P7DE54",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE54"
  },
  {
    "uniprot": "A0A3P7FX77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX77"
  },
  {
    "uniprot": "A0A3P7FXS4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXS4"
  },
  {
    "uniprot": "J9FJ00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FJ00"
  },
  {
    "uniprot": "A0A3P7DW00",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DW00"
  },
  {
    "uniprot": "A0A3P7DVD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVD5"
  },
  {
    "uniprot": "A0A3P7EY98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EY98"
  },
  {
    "uniprot": "A0A3P7GLL0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLL0"
  },
  {
    "uniprot": "J9F0L3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F0L3"
  },
  {
    "uniprot": "A0A183XIY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XIY0"
  },
  {
    "uniprot": "A0A3P7E2S4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2S4"
  },
  {
    "uniprot": "A0A3P7EBL1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBL1"
  },
  {
    "uniprot": "A0A3P7FQU3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQU3"
  },
  {
    "uniprot": "A0A3P7EC87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC87"
  },
  {
    "uniprot": "A0A3P7DZX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZX8"
  },
  {
    "uniprot": "A0A3P7EBT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBT4"
  },
  {
    "uniprot": "A0A3P7FY07",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FY07"
  },
  {
    "uniprot": "A0A3P7G884",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G884"
  },
  {
    "uniprot": "A0A3P7EI21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EI21"
  },
  {
    "uniprot": "A0A3P7EB47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EB47"
  },
  {
    "uniprot": "A0A3P7E051",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E051"
  },
  {
    "uniprot": "A0A3P7DM27",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM27"
  },
  {
    "uniprot": "A0A3P7DD79",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DD79"
  },
  {
    "uniprot": "J9EGF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EGF1"
  },
  {
    "uniprot": "A0A3P7DZY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZY0"
  },
  {
    "uniprot": "J9B986",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B986"
  },
  {
    "uniprot": "A0A3P7FZ70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZ70"
  },
  {
    "uniprot": "A0A3P7E0Z6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0Z6"
  },
  {
    "uniprot": "A0A3P7EZB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZB7"
  },
  {
    "uniprot": "A0A3P7E582",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E582"
  },
  {
    "uniprot": "A0A3P7DWS8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWS8"
  },
  {
    "uniprot": "A0A3P7DUC9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUC9"
  },
  {
    "uniprot": "A0A3P7DZS9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZS9"
  },
  {
    "uniprot": "A0A3P7EQ87",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EQ87"
  },
  {
    "uniprot": "J9AHJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9AHJ1"
  },
  {
    "uniprot": "A0A3P7E3G8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3G8"
  },
  {
    "uniprot": "A0A3P7EJZ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJZ1"
  },
  {
    "uniprot": "A0A3P7FXP0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXP0"
  },
  {
    "uniprot": "A0A3P7EJX9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJX9"
  },
  {
    "uniprot": "A0A3P7DJZ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJZ6"
  },
  {
    "uniprot": "A0A3P7DQL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQL5"
  },
  {
    "uniprot": "A0A3P7DP14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP14"
  },
  {
    "uniprot": "A0A3P7DBM5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DBM5"
  },
  {
    "uniprot": "A0A3P7DJP7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJP7"
  },
  {
    "uniprot": "A0A3P7EZL7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZL7"
  },
  {
    "uniprot": "A0A3P7DRJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRJ1"
  },
  {
    "uniprot": "A0A3P7EJG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJG8"
  },
  {
    "uniprot": "A0A3P7DHZ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHZ4"
  },
  {
    "uniprot": "A0A3P7E5L6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5L6"
  },
  {
    "uniprot": "J9ACE7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ACE7"
  },
  {
    "uniprot": "A0A3P7E0U2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0U2"
  },
  {
    "uniprot": "A0A3P7E5R0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5R0"
  },
  {
    "uniprot": "A0A3P7DLC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLC2"
  },
  {
    "uniprot": "J9EQT8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQT8"
  },
  {
    "uniprot": "A0A3P7EC17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EC17"
  },
  {
    "uniprot": "A0A3P7EFZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFZ2"
  },
  {
    "uniprot": "A0A3P7FW88",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FW88"
  },
  {
    "uniprot": "A0A3P7FRT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FRT2"
  },
  {
    "uniprot": "A0A3P7FLW1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLW1"
  },
  {
    "uniprot": "J9EV42",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EV42"
  },
  {
    "uniprot": "A0A3P7DWX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWX8"
  },
  {
    "uniprot": "A0A3P7E8Q6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8Q6"
  },
  {
    "uniprot": "A0A3P7FNH0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNH0"
  },
  {
    "uniprot": "J9EKS3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EKS3"
  },
  {
    "uniprot": "A0A3P7DUK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUK3"
  },
  {
    "uniprot": "J9FK11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FK11"
  },
  {
    "uniprot": "A0A3P7DI95",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DI95"
  },
  {
    "uniprot": "J9EJI4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EJI4"
  },
  {
    "uniprot": "A0A3P7DQB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQB7"
  },
  {
    "uniprot": "J9EIJ0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EIJ0"
  },
  {
    "uniprot": "A0A3P7ECI7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECI7"
  },
  {
    "uniprot": "A0A3P7DX28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DX28"
  },
  {
    "uniprot": "A0A3P7E6E7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6E7"
  },
  {
    "uniprot": "A0A3P7E274",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E274"
  },
  {
    "uniprot": "A0A3P7E7R8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7R8"
  },
  {
    "uniprot": "A0A3P7E990",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E990"
  },
  {
    "uniprot": "A0A3P7GKC2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GKC2"
  },
  {
    "uniprot": "A0A3P7E1P6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1P6"
  },
  {
    "uniprot": "A0A3P7EMT1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMT1"
  },
  {
    "uniprot": "J9FH51",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FH51"
  },
  {
    "uniprot": "J9B4N6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B4N6"
  },
  {
    "uniprot": "A0A3P7EYD6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EYD6"
  },
  {
    "uniprot": "A0A3P7DJL2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJL2"
  },
  {
    "uniprot": "A0A3P7DRX5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRX5"
  },
  {
    "uniprot": "A0A3P7G1P8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G1P8"
  },
  {
    "uniprot": "A0A3P7DLI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLI8"
  },
  {
    "uniprot": "A0A3P7FJC0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FJC0"
  },
  {
    "uniprot": "A0A3P7E9Z7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9Z7"
  },
  {
    "uniprot": "A0A3P7DU98",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DU98"
  },
  {
    "uniprot": "A0A3P7GLN6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GLN6"
  },
  {
    "uniprot": "A0A3P7GDR2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GDR2"
  },
  {
    "uniprot": "A0A3P7DS29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS29"
  },
  {
    "uniprot": "A0A3P7DTJ1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTJ1"
  },
  {
    "uniprot": "A0A3P7FEP5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FEP5"
  },
  {
    "uniprot": "A0A3P7DG67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DG67"
  },
  {
    "uniprot": "A0A3P7EAK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAK9"
  },
  {
    "uniprot": "A0A3P7E373",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E373"
  },
  {
    "uniprot": "A0A3P7EP69",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EP69"
  },
  {
    "uniprot": "A0A3P7EBF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBF0"
  },
  {
    "uniprot": "J9FH86",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FH86"
  },
  {
    "uniprot": "A0A3P7EMF1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EMF1"
  },
  {
    "uniprot": "A0A3P7G7J8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G7J8"
  },
  {
    "uniprot": "A0A3P7DE96",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DE96"
  },
  {
    "uniprot": "A0A3P7E4T3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4T3"
  },
  {
    "uniprot": "A0A3P7DNF9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNF9"
  },
  {
    "uniprot": "A0A3P7G308",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G308"
  },
  {
    "uniprot": "A0A3P7FVI8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FVI8"
  },
  {
    "uniprot": "A0A3P7EZK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZK6"
  },
  {
    "uniprot": "A0A3P7DTF0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTF0"
  },
  {
    "uniprot": "A0A3P7G2C2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2C2"
  },
  {
    "uniprot": "J9BL17",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BL17"
  },
  {
    "uniprot": "A0A3P7EGG3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGG3"
  },
  {
    "uniprot": "A0A3P7FLP4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLP4"
  },
  {
    "uniprot": "A0A3P7E913",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E913"
  },
  {
    "uniprot": "A0A3P7E2B3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E2B3"
  },
  {
    "uniprot": "A0A3P7E1R5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1R5"
  },
  {
    "uniprot": "J9EJ64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EJ64"
  },
  {
    "uniprot": "A0A3P7DWL5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWL5"
  },
  {
    "uniprot": "A0A3P7EFT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EFT2"
  },
  {
    "uniprot": "A0A3P7ECA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ECA4"
  },
  {
    "uniprot": "A0A3P7FPJ5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPJ5"
  },
  {
    "uniprot": "A0A3P7DPV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DPV3"
  },
  {
    "uniprot": "A0A3P7E778",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E778"
  },
  {
    "uniprot": "A0A3P7FX52",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX52"
  },
  {
    "uniprot": "A0A3P7FDW4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDW4"
  },
  {
    "uniprot": "A0A3P7E0S8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0S8"
  },
  {
    "uniprot": "A0A3P7E8B5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E8B5"
  },
  {
    "uniprot": "A0A3P7EM80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EM80"
  },
  {
    "uniprot": "A0A3P7E204",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E204"
  },
  {
    "uniprot": "A0A3P7EUS5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EUS5"
  },
  {
    "uniprot": "A0A3P7E6X6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6X6"
  },
  {
    "uniprot": "A0A3P7DTK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTK3"
  },
  {
    "uniprot": "A0A3P7E7T5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7T5"
  },
  {
    "uniprot": "A0A3P7FXE8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXE8"
  },
  {
    "uniprot": "A0A3P7FT71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT71"
  },
  {
    "uniprot": "A0A3P7DTC5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTC5"
  },
  {
    "uniprot": "A0A3P7DP90",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DP90"
  },
  {
    "uniprot": "A0A3P7E9N6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9N6"
  },
  {
    "uniprot": "A0A3P7EII0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EII0"
  },
  {
    "uniprot": "A0A3P7FDM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDM4"
  },
  {
    "uniprot": "A0A3P7F788",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F788"
  },
  {
    "uniprot": "A0A3P7E591",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E591"
  },
  {
    "uniprot": "A0A3P7DM24",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM24"
  },
  {
    "uniprot": "A0A3P7EJH2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EJH2"
  },
  {
    "uniprot": "A0A3P7DTD9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTD9"
  },
  {
    "uniprot": "A0A3P7FNC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNC8"
  },
  {
    "uniprot": "A0A3P7E6S4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6S4"
  },
  {
    "uniprot": "A0A3P7EH32",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH32"
  },
  {
    "uniprot": "J9END8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9END8"
  },
  {
    "uniprot": "A0A3P7DRS1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DRS1"
  },
  {
    "uniprot": "A0A3P7FPF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPF7"
  },
  {
    "uniprot": "A0A3P7DTQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTQ4"
  },
  {
    "uniprot": "A0A3P7DT29",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT29"
  },
  {
    "uniprot": "A0A3P7EHK3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHK3"
  },
  {
    "uniprot": "A0A3P7DHK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHK9"
  },
  {
    "uniprot": "A0A3P7DKT4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKT4"
  },
  {
    "uniprot": "A0A3P7DUR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUR9"
  },
  {
    "uniprot": "A0A3P7E0J2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E0J2"
  },
  {
    "uniprot": "A0A3P7DZ39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DZ39"
  },
  {
    "uniprot": "A0A3P7DTN8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTN8"
  },
  {
    "uniprot": "A0A3P7EHG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EHG8"
  },
  {
    "uniprot": "A0A3P7FDK6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FDK6"
  },
  {
    "uniprot": "A0A3P7FUR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FUR1"
  },
  {
    "uniprot": "A0A183XD33",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XD33"
  },
  {
    "uniprot": "A0A3P7DVH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVH4"
  },
  {
    "uniprot": "A0A3P7DKD5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKD5"
  },
  {
    "uniprot": "A0A3P7DMX8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DMX8"
  },
  {
    "uniprot": "A0A3P7E9R7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E9R7"
  },
  {
    "uniprot": "A0A3P7G5I9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5I9"
  },
  {
    "uniprot": "A0A3P7GHM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GHM4"
  },
  {
    "uniprot": "A0A3P7FAK2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FAK2"
  },
  {
    "uniprot": "A0A3P7F4M6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F4M6"
  },
  {
    "uniprot": "A0A3P7G5T8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G5T8"
  },
  {
    "uniprot": "A0A3P7DJQ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DJQ6"
  },
  {
    "uniprot": "A0A3P7FLP9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLP9"
  },
  {
    "uniprot": "A0A3P7DCA4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DCA4"
  },
  {
    "uniprot": "J9FNX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FNX2"
  },
  {
    "uniprot": "J9EYM6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EYM6"
  },
  {
    "uniprot": "A0A183XHD7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XHD7"
  },
  {
    "uniprot": "A0A3P7DK28",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DK28"
  },
  {
    "uniprot": "A0A3P7FM55",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FM55"
  },
  {
    "uniprot": "A0A3P7E1Y1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1Y1"
  },
  {
    "uniprot": "A0A3P7DS70",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DS70"
  },
  {
    "uniprot": "A0A3P7E1Q7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E1Q7"
  },
  {
    "uniprot": "A0A3P7FX04",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FX04"
  },
  {
    "uniprot": "A0A3P7E5K9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E5K9"
  },
  {
    "uniprot": "A0A3P7GD41",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GD41"
  },
  {
    "uniprot": "A0A3P7DYA0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYA0"
  },
  {
    "uniprot": "A0A3P7DT12",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DT12"
  },
  {
    "uniprot": "A0A3P7FZD3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FZD3"
  },
  {
    "uniprot": "A0A3P7DEL9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DEL9"
  },
  {
    "uniprot": "A0A3P7E6K5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6K5"
  },
  {
    "uniprot": "J9FAF3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FAF3"
  },
  {
    "uniprot": "A0A3P7E7Z7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7Z7"
  },
  {
    "uniprot": "A0A3P7E3R1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3R1"
  },
  {
    "uniprot": "A0A3P7FXV3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXV3"
  },
  {
    "uniprot": "A0A3P7DVS0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVS0"
  },
  {
    "uniprot": "A0A3P7EU80",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EU80"
  },
  {
    "uniprot": "A0A3P7DLG8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLG8"
  },
  {
    "uniprot": "A0A3P7FWV4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FWV4"
  },
  {
    "uniprot": "J9BBQ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9BBQ4"
  },
  {
    "uniprot": "A0A3P7F776",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F776"
  },
  {
    "uniprot": "A0A3P7DN92",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DN92"
  },
  {
    "uniprot": "A0A3P7FK81",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FK81"
  },
  {
    "uniprot": "A0A3P7E4Z0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4Z0"
  },
  {
    "uniprot": "J9F0A3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F0A3"
  },
  {
    "uniprot": "J9F9H8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F9H8"
  },
  {
    "uniprot": "A0A3P7FPN0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPN0"
  },
  {
    "uniprot": "A0A3P7FMR6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMR6"
  },
  {
    "uniprot": "A0A3P7DR38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DR38"
  },
  {
    "uniprot": "A0A3P7GEZ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GEZ9"
  },
  {
    "uniprot": "A0A3P7F0P2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F0P2"
  },
  {
    "uniprot": "A0A3P7DY77",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY77"
  },
  {
    "uniprot": "A0A3P7DWV5",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWV5"
  },
  {
    "uniprot": "A0A3P7E3T9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3T9"
  },
  {
    "uniprot": "J9DQ16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9DQ16"
  },
  {
    "uniprot": "A0A3P7DLY9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DLY9"
  },
  {
    "uniprot": "A0A3P7DF68",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DF68"
  },
  {
    "uniprot": "J9EQT2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EQT2"
  },
  {
    "uniprot": "A0A3P7FKB7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FKB7"
  },
  {
    "uniprot": "A0A3P7EGT9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EGT9"
  },
  {
    "uniprot": "A0A183XLE2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XLE2"
  },
  {
    "uniprot": "J9ESZ2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ESZ2"
  },
  {
    "uniprot": "A0A183XUI6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XUI6"
  },
  {
    "uniprot": "A0A3P7GF18",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GF18"
  },
  {
    "uniprot": "A0A3P7DXR9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DXR9"
  },
  {
    "uniprot": "A0A3P7ENG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ENG4"
  },
  {
    "uniprot": "A0A3P7FD72",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FD72"
  },
  {
    "uniprot": "A0A3P7EA38",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EA38"
  },
  {
    "uniprot": "A0A3P7E6B6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E6B6"
  },
  {
    "uniprot": "A0A3P7E7H4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E7H4"
  },
  {
    "uniprot": "A0A3P7DUX7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUX7"
  },
  {
    "uniprot": "A0A3P7FXK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FXK0"
  },
  {
    "uniprot": "A0A3P7FQD0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FQD0"
  },
  {
    "uniprot": "A0A3P7FNK9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FNK9"
  },
  {
    "uniprot": "A0A3P7DHJ9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DHJ9"
  },
  {
    "uniprot": "A0A3P7DWV0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DWV0"
  },
  {
    "uniprot": "A0A3P7FPJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FPJ6"
  },
  {
    "uniprot": "A0A3P7FU23",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FU23"
  },
  {
    "uniprot": "A0A183XIJ4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XIJ4"
  },
  {
    "uniprot": "A0A3P7E3V6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E3V6"
  },
  {
    "uniprot": "A0A3P7DSG6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSG6"
  },
  {
    "uniprot": "A0A3P7DVR4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVR4"
  },
  {
    "uniprot": "J9B242",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9B242"
  },
  {
    "uniprot": "A0A3P7G3V0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G3V0"
  },
  {
    "uniprot": "J9F7N9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F7N9"
  },
  {
    "uniprot": "A0A3P7DIJ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DIJ7"
  },
  {
    "uniprot": "A0A3P7FYN7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYN7"
  },
  {
    "uniprot": "A0A3P7DYB9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DYB9"
  },
  {
    "uniprot": "A0A3P7FT11",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FT11"
  },
  {
    "uniprot": "A0A3P7FYJ6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYJ6"
  },
  {
    "uniprot": "A0A3P7EAS7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EAS7"
  },
  {
    "uniprot": "A0A3P7ED66",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7ED66"
  },
  {
    "uniprot": "A0A3P7E4T2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E4T2"
  },
  {
    "uniprot": "A0A3P7FLY1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FLY1"
  },
  {
    "uniprot": "A0A3P7FSQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FSQ7"
  },
  {
    "uniprot": "A0A3P7EWY0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EWY0"
  },
  {
    "uniprot": "A0A3P7DUX0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DUX0"
  },
  {
    "uniprot": "J9EX39",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EX39"
  },
  {
    "uniprot": "A0A3P7EVH4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EVH4"
  },
  {
    "uniprot": "A0A3P7DY84",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY84"
  },
  {
    "uniprot": "A0A3P7FP78",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FP78"
  },
  {
    "uniprot": "A0A3P7FF16",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FF16"
  },
  {
    "uniprot": "A0A3P7DVR3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DVR3"
  },
  {
    "uniprot": "A0A3P7DSM4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DSM4"
  },
  {
    "uniprot": "J9EBI9",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EBI9"
  },
  {
    "uniprot": "A0A3P7EKK4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EKK4"
  },
  {
    "uniprot": "A0A3P7EZL8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EZL8"
  },
  {
    "uniprot": "A0A3P7DY49",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DY49"
  },
  {
    "uniprot": "A0A3P7DQF7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DQF7"
  },
  {
    "uniprot": "A0A3P7FG67",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FG67"
  },
  {
    "uniprot": "A0A3P7GCM0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GCM0"
  },
  {
    "uniprot": "A0A183XEG4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A183XEG4"
  },
  {
    "uniprot": "A0A3P7G2W8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7G2W8"
  },
  {
    "uniprot": "A0A3P7FMC3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FMC3"
  },
  {
    "uniprot": "A0A3P7GD14",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7GD14"
  },
  {
    "uniprot": "A0A3P7DFC8",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DFC8"
  },
  {
    "uniprot": "A0A3P7EBG2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EBG2"
  },
  {
    "uniprot": "A0A3P7E311",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E311"
  },
  {
    "uniprot": "A0A3P7F8F3",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7F8F3"
  },
  {
    "uniprot": "A0A3P7DM71",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DM71"
  },
  {
    "uniprot": "J9F6W6",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9F6W6"
  },
  {
    "uniprot": "J9EN64",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9EN64"
  },
  {
    "uniprot": "A0A3P7FIR1",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FIR1"
  },
  {
    "uniprot": "A0A3P7DTK0",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DTK0"
  },
  {
    "uniprot": "A0A3P7EG47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EG47"
  },
  {
    "uniprot": "J9FN21",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9FN21"
  },
  {
    "uniprot": "A0A3P7E082",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E082"
  },
  {
    "uniprot": "A0A3P7EH47",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7EH47"
  },
  {
    "uniprot": "A0A3P7DNQ7",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DNQ7"
  },
  {
    "uniprot": "A0A3P7FYX2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7FYX2"
  },
  {
    "uniprot": "A0A3P7DKE4",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7DKE4"
  },
  {
    "uniprot": "J9ENV2",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/J9ENV2"
  },
  {
    "uniprot": "A0A3P7E737",
    "species": "Wuchereria bancrofti",
    "disease": "Lymphatic filariasis",
    "results": "/results/A0A3P7E737"
  },
  {
    "uniprot": "A0A5K4F7V9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7V9"
  },
  {
    "uniprot": "A0A5K4F4R7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4R7"
  },
  {
    "uniprot": "A0A5K4FBU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBU1"
  },
  {
    "uniprot": "A0A3Q0KU35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU35"
  },
  {
    "uniprot": "A0A5K4F8J3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8J3"
  },
  {
    "uniprot": "A0A5K4F4B2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4B2"
  },
  {
    "uniprot": "G4VTR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTR7"
  },
  {
    "uniprot": "A0A3Q0KSJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSJ9"
  },
  {
    "uniprot": "A0A3Q0KHT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHT1"
  },
  {
    "uniprot": "A0A5K4EPL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPL0"
  },
  {
    "uniprot": "C4QUA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QUA1"
  },
  {
    "uniprot": "A0A5K4F2T5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2T5"
  },
  {
    "uniprot": "A0A5K4FBG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBG9"
  },
  {
    "uniprot": "A0A5K4FE86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE86"
  },
  {
    "uniprot": "C4QMG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QMG1"
  },
  {
    "uniprot": "G4VKT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKT1"
  },
  {
    "uniprot": "A0A5K4F8Z5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Z5"
  },
  {
    "uniprot": "A0A3Q0KPN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPN2"
  },
  {
    "uniprot": "Q8IT61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q8IT61"
  },
  {
    "uniprot": "G4V8A3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8A3"
  },
  {
    "uniprot": "G4VJD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJD7"
  },
  {
    "uniprot": "A0A5K4F451",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F451"
  },
  {
    "uniprot": "A0A5K4FAW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAW6"
  },
  {
    "uniprot": "G4VAQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAQ2"
  },
  {
    "uniprot": "A0A5K4FEK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEK2"
  },
  {
    "uniprot": "G4VRC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRC6"
  },
  {
    "uniprot": "A0A3Q0KUK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUK0"
  },
  {
    "uniprot": "A0A3Q0KVG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVG0"
  },
  {
    "uniprot": "A0A5K4FDW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDW5"
  },
  {
    "uniprot": "A0A3Q0KMF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMF6"
  },
  {
    "uniprot": "A0A5K4EQ57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ57"
  },
  {
    "uniprot": "G4VII0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VII0"
  },
  {
    "uniprot": "A0A5K4F1G8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1G8"
  },
  {
    "uniprot": "A0A5K4F1M8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1M8"
  },
  {
    "uniprot": "A0A5K4F1W0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1W0"
  },
  {
    "uniprot": "A0A5K4F4T6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4T6"
  },
  {
    "uniprot": "G4M0J1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0J1"
  },
  {
    "uniprot": "A0A5K4F6D8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6D8"
  },
  {
    "uniprot": "A0A3Q0KVH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVH9"
  },
  {
    "uniprot": "A0A5K4FDT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDT6"
  },
  {
    "uniprot": "A0A5K4ELG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELG7"
  },
  {
    "uniprot": "A0A3Q0KH52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH52"
  },
  {
    "uniprot": "G4LWQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWQ1"
  },
  {
    "uniprot": "A0A3Q0KKT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKT6"
  },
  {
    "uniprot": "G4LWX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWX4"
  },
  {
    "uniprot": "A0A146MIC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MIC5"
  },
  {
    "uniprot": "A0A3Q0KKX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKX6"
  },
  {
    "uniprot": "A0A3Q0KEE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEE1"
  },
  {
    "uniprot": "A0A5K4F3V1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3V1"
  },
  {
    "uniprot": "A0A3Q0KSX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSX1"
  },
  {
    "uniprot": "A0A3Q0KR16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR16"
  },
  {
    "uniprot": "A0A5K4EN06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN06"
  },
  {
    "uniprot": "C4QHH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QHH7"
  },
  {
    "uniprot": "A0A3Q0KSY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSY2"
  },
  {
    "uniprot": "A0A5K4F6J9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6J9"
  },
  {
    "uniprot": "A0A3Q0KIW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIW9"
  },
  {
    "uniprot": "C4QRY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QRY1"
  },
  {
    "uniprot": "P42638",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P42638"
  },
  {
    "uniprot": "A0A5K4F9F6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9F6"
  },
  {
    "uniprot": "A0A5K4EP32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP32"
  },
  {
    "uniprot": "A0A5K4F8S5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8S5"
  },
  {
    "uniprot": "A0A3Q0KMB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMB6"
  },
  {
    "uniprot": "A0A5K4E8I5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8I5"
  },
  {
    "uniprot": "A0A5K4F6C4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6C4"
  },
  {
    "uniprot": "G4V7L0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7L0"
  },
  {
    "uniprot": "A0A5K4EGS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGS1"
  },
  {
    "uniprot": "A0A3Q0KPA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPA3"
  },
  {
    "uniprot": "G4VCZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCZ9"
  },
  {
    "uniprot": "A0A5K4F1A5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1A5"
  },
  {
    "uniprot": "A0A5K4F1Q6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Q6"
  },
  {
    "uniprot": "A0A5K4ENM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENM0"
  },
  {
    "uniprot": "A0A3Q0KV22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV22"
  },
  {
    "uniprot": "G4VN23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VN23"
  },
  {
    "uniprot": "G4VDZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDZ8"
  },
  {
    "uniprot": "A0A5K4F368",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F368"
  },
  {
    "uniprot": "A0A5K4ECL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECL2"
  },
  {
    "uniprot": "G4VIE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIE4"
  },
  {
    "uniprot": "A0A5K4FDP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDP6"
  },
  {
    "uniprot": "A0A5K4ESM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESM0"
  },
  {
    "uniprot": "A0A3Q0KNJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNJ9"
  },
  {
    "uniprot": "A0A5K4FBA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBA3"
  },
  {
    "uniprot": "A0A3Q0KMT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMT5"
  },
  {
    "uniprot": "A0A5K4FDY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDY6"
  },
  {
    "uniprot": "A0A3Q0KNI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNI2"
  },
  {
    "uniprot": "A0A5K4F907",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F907"
  },
  {
    "uniprot": "G4LWT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWT0"
  },
  {
    "uniprot": "A0A5K4F3G1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3G1"
  },
  {
    "uniprot": "A0A5K4FCP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCP4"
  },
  {
    "uniprot": "A0A5K4FD88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD88"
  },
  {
    "uniprot": "G4V6P8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6P8"
  },
  {
    "uniprot": "A0A5K4F435",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F435"
  },
  {
    "uniprot": "A0A5K4ETS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETS2"
  },
  {
    "uniprot": "A0A5K4EGZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGZ1"
  },
  {
    "uniprot": "G4VIW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIW1"
  },
  {
    "uniprot": "G4VRT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRT6"
  },
  {
    "uniprot": "A0A3Q0KTM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTM9"
  },
  {
    "uniprot": "A0A5K4FF70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF70"
  },
  {
    "uniprot": "Q26586",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26586"
  },
  {
    "uniprot": "G4VQR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQR9"
  },
  {
    "uniprot": "G4VIU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIU6"
  },
  {
    "uniprot": "A0A5K4F535",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F535"
  },
  {
    "uniprot": "A0A3Q0KSZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSZ4"
  },
  {
    "uniprot": "A0A3Q0KTH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTH1"
  },
  {
    "uniprot": "B2D1S3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/B2D1S3"
  },
  {
    "uniprot": "A0A5K4EZX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZX8"
  },
  {
    "uniprot": "G4VKM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKM1"
  },
  {
    "uniprot": "A0A3Q0KMH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMH2"
  },
  {
    "uniprot": "G4V7Q8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7Q8"
  },
  {
    "uniprot": "A0A5K4F6J5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6J5"
  },
  {
    "uniprot": "A0A5K4F947",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F947"
  },
  {
    "uniprot": "G4VEN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEN1"
  },
  {
    "uniprot": "A0A3Q0KVJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVJ9"
  },
  {
    "uniprot": "A0A5K4F8X2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8X2"
  },
  {
    "uniprot": "A0A3Q0KMJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMJ0"
  },
  {
    "uniprot": "G4LZC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZC6"
  },
  {
    "uniprot": "A0A5K4EMT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMT9"
  },
  {
    "uniprot": "A0A5K4FB98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB98"
  },
  {
    "uniprot": "A0A3Q0KR42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR42"
  },
  {
    "uniprot": "G4VSJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSJ1"
  },
  {
    "uniprot": "G4V9R4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9R4"
  },
  {
    "uniprot": "A0A5K4EQ01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ01"
  },
  {
    "uniprot": "A0A5K4EA64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EA64"
  },
  {
    "uniprot": "A0A5K4F3U8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3U8"
  },
  {
    "uniprot": "A0A3Q0KBR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBR6"
  },
  {
    "uniprot": "G4VI66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI66"
  },
  {
    "uniprot": "A0A5K4F297",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F297"
  },
  {
    "uniprot": "A0A3Q0KV47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV47"
  },
  {
    "uniprot": "A0A3Q0KLZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLZ6"
  },
  {
    "uniprot": "A0A5K4F807",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F807"
  },
  {
    "uniprot": "A0A3Q0KSL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSL7"
  },
  {
    "uniprot": "G4VRN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRN3"
  },
  {
    "uniprot": "A0A5K4F013",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F013"
  },
  {
    "uniprot": "A0A3Q0KRP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRP0"
  },
  {
    "uniprot": "A0A5K4EX15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX15"
  },
  {
    "uniprot": "A0A3Q0KCR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCR6"
  },
  {
    "uniprot": "A0A5K4FBV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBV8"
  },
  {
    "uniprot": "A0A5K4F4N3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4N3"
  },
  {
    "uniprot": "A0A3Q0KLL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLL9"
  },
  {
    "uniprot": "A0A5K4F3L3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3L3"
  },
  {
    "uniprot": "A0A5K4F9G8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9G8"
  },
  {
    "uniprot": "A0A5K4F819",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F819"
  },
  {
    "uniprot": "G4LXG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXG6"
  },
  {
    "uniprot": "A0A3Q0KK42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK42"
  },
  {
    "uniprot": "A0A5K4F7Y3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Y3"
  },
  {
    "uniprot": "A0A3Q0KUV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUV5"
  },
  {
    "uniprot": "A0A5K4F137",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F137"
  },
  {
    "uniprot": "A0A5K4FB25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB25"
  },
  {
    "uniprot": "A0A5K4F944",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F944"
  },
  {
    "uniprot": "A0A5K4F0Z1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Z1"
  },
  {
    "uniprot": "A0A5K4F8I7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8I7"
  },
  {
    "uniprot": "A0A3Q0KMZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMZ2"
  },
  {
    "uniprot": "A0A5K4F0F9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0F9"
  },
  {
    "uniprot": "A0A5K4EH48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH48"
  },
  {
    "uniprot": "A0A3Q0KFL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFL1"
  },
  {
    "uniprot": "A0A3Q0KK97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK97"
  },
  {
    "uniprot": "A0A5K4EBQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBQ8"
  },
  {
    "uniprot": "A0A5K4F350",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F350"
  },
  {
    "uniprot": "A0A5K4FAM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAM2"
  },
  {
    "uniprot": "A0A5K4F052",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F052"
  },
  {
    "uniprot": "A0A5K4ELI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELI3"
  },
  {
    "uniprot": "G4VQN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQN0"
  },
  {
    "uniprot": "G4VGT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGT4"
  },
  {
    "uniprot": "A0A3Q0KQJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQJ2"
  },
  {
    "uniprot": "G4VN01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VN01"
  },
  {
    "uniprot": "G4V6U3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6U3"
  },
  {
    "uniprot": "A0A5K4F9Y5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Y5"
  },
  {
    "uniprot": "A0A5K4F3Z1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Z1"
  },
  {
    "uniprot": "G4V7B4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7B4"
  },
  {
    "uniprot": "A0A5K4F355",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F355"
  },
  {
    "uniprot": "A0A3Q0KH27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH27"
  },
  {
    "uniprot": "A0A3Q0KB97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KB97"
  },
  {
    "uniprot": "G4VG77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VG77"
  },
  {
    "uniprot": "A0A3Q0KKM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKM7"
  },
  {
    "uniprot": "A0A5K4E8T5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8T5"
  },
  {
    "uniprot": "A0A5K4EV45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV45"
  },
  {
    "uniprot": "A0A5K4FCU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCU1"
  },
  {
    "uniprot": "G4V6Q2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6Q2"
  },
  {
    "uniprot": "A0A5K4FFY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFY1"
  },
  {
    "uniprot": "A0A3Q0KQH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQH5"
  },
  {
    "uniprot": "A0A5K4FD08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD08"
  },
  {
    "uniprot": "P25824",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P25824"
  },
  {
    "uniprot": "G4VHC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHC2"
  },
  {
    "uniprot": "A0A5K4ESJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESJ4"
  },
  {
    "uniprot": "A0A5K4FC17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC17"
  },
  {
    "uniprot": "G4VPV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPV4"
  },
  {
    "uniprot": "G4LYK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYK7"
  },
  {
    "uniprot": "A0A5K4F359",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F359"
  },
  {
    "uniprot": "A0A3Q0KGE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGE6"
  },
  {
    "uniprot": "A0A3Q0KHR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHR7"
  },
  {
    "uniprot": "A0A5K4ELC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELC1"
  },
  {
    "uniprot": "A0A5K4FCZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCZ1"
  },
  {
    "uniprot": "A0A5K4F5A6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5A6"
  },
  {
    "uniprot": "A0A5K4FFE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFE9"
  },
  {
    "uniprot": "A0A5K4ER13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER13"
  },
  {
    "uniprot": "C4PXA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4PXA6"
  },
  {
    "uniprot": "A0A3Q0KBY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBY2"
  },
  {
    "uniprot": "A0A5K4EBY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBY9"
  },
  {
    "uniprot": "A0A5K4F3H3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3H3"
  },
  {
    "uniprot": "A0A5K4ECW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECW7"
  },
  {
    "uniprot": "A0A3Q0KSU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSU9"
  },
  {
    "uniprot": "A0A5K4EM12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM12"
  },
  {
    "uniprot": "A0A3Q0KQG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQG7"
  },
  {
    "uniprot": "A0A3Q0KEE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEE0"
  },
  {
    "uniprot": "A0A5K4F3J5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3J5"
  },
  {
    "uniprot": "A0A3Q0KRL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRL3"
  },
  {
    "uniprot": "A0A5K4EPN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPN6"
  },
  {
    "uniprot": "A0A5K4F4C4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4C4"
  },
  {
    "uniprot": "G4VL03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VL03"
  },
  {
    "uniprot": "A0A3Q0KPM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPM0"
  },
  {
    "uniprot": "A0A3Q0KNF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNF9"
  },
  {
    "uniprot": "A0A3Q0KQD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQD2"
  },
  {
    "uniprot": "A0A5K4EZ93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ93"
  },
  {
    "uniprot": "A0A5K4F370",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F370"
  },
  {
    "uniprot": "A0A3Q0KBU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBU2"
  },
  {
    "uniprot": "A0A5K4F5D5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5D5"
  },
  {
    "uniprot": "A0A5K4FEQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEQ8"
  },
  {
    "uniprot": "A0A3Q0KN76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN76"
  },
  {
    "uniprot": "A0A3Q0KB89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KB89"
  },
  {
    "uniprot": "A0A3Q0KSZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSZ3"
  },
  {
    "uniprot": "A0A3Q0KBN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBN1"
  },
  {
    "uniprot": "G4VHH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHH1"
  },
  {
    "uniprot": "A0A5K4FBM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBM0"
  },
  {
    "uniprot": "A0A5K4F1Q4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Q4"
  },
  {
    "uniprot": "G4VMG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMG2"
  },
  {
    "uniprot": "G4VK76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK76"
  },
  {
    "uniprot": "A0A5K4FB14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB14"
  },
  {
    "uniprot": "A0A3Q0KP49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP49"
  },
  {
    "uniprot": "G4LUT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUT6"
  },
  {
    "uniprot": "A0A3Q0KTS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTS4"
  },
  {
    "uniprot": "A0A5K4E8R4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8R4"
  },
  {
    "uniprot": "A0A5K4FAV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAV7"
  },
  {
    "uniprot": "A0A3Q0KE99",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE99"
  },
  {
    "uniprot": "A0A3Q0KKT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKT1"
  },
  {
    "uniprot": "G4VMN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMN6"
  },
  {
    "uniprot": "G4V969",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V969"
  },
  {
    "uniprot": "A0A3Q0KTE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTE9"
  },
  {
    "uniprot": "G4VGE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGE1"
  },
  {
    "uniprot": "G4V7G0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7G0"
  },
  {
    "uniprot": "A0A5K4F6M4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6M4"
  },
  {
    "uniprot": "A0A3Q0KCC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCC8"
  },
  {
    "uniprot": "A0A3Q0KRR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRR5"
  },
  {
    "uniprot": "A0A5K4F812",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F812"
  },
  {
    "uniprot": "A0A5K4EMG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMG8"
  },
  {
    "uniprot": "A0A5K4F925",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F925"
  },
  {
    "uniprot": "A0A3Q0KN77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN77"
  },
  {
    "uniprot": "A0A3Q0KRE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRE3"
  },
  {
    "uniprot": "G4VLB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLB4"
  },
  {
    "uniprot": "A0A5K4EHB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHB0"
  },
  {
    "uniprot": "A0A3Q0KNK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNK9"
  },
  {
    "uniprot": "G4VSA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSA8"
  },
  {
    "uniprot": "A0A5K4FAC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAC5"
  },
  {
    "uniprot": "A0A3Q0KIW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIW8"
  },
  {
    "uniprot": "A0A3Q0KNW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNW8"
  },
  {
    "uniprot": "A0A3Q0KIQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIQ7"
  },
  {
    "uniprot": "G4VN13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VN13"
  },
  {
    "uniprot": "A0A3Q0KKV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKV7"
  },
  {
    "uniprot": "A0A5K4FCX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCX2"
  },
  {
    "uniprot": "A0A3Q0KSJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSJ1"
  },
  {
    "uniprot": "A0A5K4FE98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE98"
  },
  {
    "uniprot": "A0A5K4FBN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBN4"
  },
  {
    "uniprot": "A0A5K4EIR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIR3"
  },
  {
    "uniprot": "A0A5K4EP65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP65"
  },
  {
    "uniprot": "A0A5K4F763",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F763"
  },
  {
    "uniprot": "A0A5K4FEZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEZ8"
  },
  {
    "uniprot": "G4LZ11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ11"
  },
  {
    "uniprot": "A0A3Q0KDM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDM5"
  },
  {
    "uniprot": "A0A3Q0KIQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIQ6"
  },
  {
    "uniprot": "A0A5K4EJ73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJ73"
  },
  {
    "uniprot": "A0A3Q0KRE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRE1"
  },
  {
    "uniprot": "A0A5K4F353",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F353"
  },
  {
    "uniprot": "A0A5K4FA61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA61"
  },
  {
    "uniprot": "A0A3Q0KNR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNR5"
  },
  {
    "uniprot": "A0A3Q0KFA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFA1"
  },
  {
    "uniprot": "A0A5K4F6S9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6S9"
  },
  {
    "uniprot": "A0A3Q0KCH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCH4"
  },
  {
    "uniprot": "A0A3Q0KJS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJS9"
  },
  {
    "uniprot": "A0A5K4F524",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F524"
  },
  {
    "uniprot": "A0A5K4FAX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAX7"
  },
  {
    "uniprot": "A0A3Q0KFC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFC2"
  },
  {
    "uniprot": "A0A5K4ERN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERN0"
  },
  {
    "uniprot": "A0A5K4FDG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDG8"
  },
  {
    "uniprot": "A0A5K4F579",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F579"
  },
  {
    "uniprot": "A0A5K4F6V7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6V7"
  },
  {
    "uniprot": "A0A5K4EID3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EID3"
  },
  {
    "uniprot": "A0A3Q0KTZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTZ5"
  },
  {
    "uniprot": "A0A5K4F6F6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6F6"
  },
  {
    "uniprot": "A0A5K4EXY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXY5"
  },
  {
    "uniprot": "A0A3Q0KL44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL44"
  },
  {
    "uniprot": "A0A5K4EW43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW43"
  },
  {
    "uniprot": "A0A5K4EK66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK66"
  },
  {
    "uniprot": "A0A3Q0KUH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUH9"
  },
  {
    "uniprot": "G4LYJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYJ9"
  },
  {
    "uniprot": "A0A3Q0KL74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL74"
  },
  {
    "uniprot": "G4VLJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLJ2"
  },
  {
    "uniprot": "A0A5K4F184",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F184"
  },
  {
    "uniprot": "A0A3Q0KE37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE37"
  },
  {
    "uniprot": "A0A3Q0KRC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRC5"
  },
  {
    "uniprot": "Q04702",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q04702"
  },
  {
    "uniprot": "E9LZR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/E9LZR8"
  },
  {
    "uniprot": "G4V9I3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9I3"
  },
  {
    "uniprot": "A0A5K4E8J8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8J8"
  },
  {
    "uniprot": "G4VBS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBS0"
  },
  {
    "uniprot": "A0A5K4F5J7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5J7"
  },
  {
    "uniprot": "A0A5K4F3G7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3G7"
  },
  {
    "uniprot": "A0A3Q0KS51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS51"
  },
  {
    "uniprot": "A0A5K4F3N1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3N1"
  },
  {
    "uniprot": "A0A5K4EPT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPT2"
  },
  {
    "uniprot": "G4VJX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJX1"
  },
  {
    "uniprot": "A0A3Q0KVF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVF1"
  },
  {
    "uniprot": "A0A3Q0KDY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDY6"
  },
  {
    "uniprot": "A0A5K4EXZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXZ3"
  },
  {
    "uniprot": "A0A5K4F8P3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8P3"
  },
  {
    "uniprot": "A0A3Q0KSZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSZ7"
  },
  {
    "uniprot": "G4V654",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V654"
  },
  {
    "uniprot": "A0A5K4F3F0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3F0"
  },
  {
    "uniprot": "G4LX46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX46"
  },
  {
    "uniprot": "A0A5K4EB28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB28"
  },
  {
    "uniprot": "A0A3Q0KP59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP59"
  },
  {
    "uniprot": "A0A3Q0KQJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQJ1"
  },
  {
    "uniprot": "A0A5K4EKZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKZ4"
  },
  {
    "uniprot": "A0A5K4EJX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJX8"
  },
  {
    "uniprot": "A0A5K4F871",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F871"
  },
  {
    "uniprot": "A0A5K4EZU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZU9"
  },
  {
    "uniprot": "A0A5K4FGQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGQ5"
  },
  {
    "uniprot": "A0A3Q0KLC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLC0"
  },
  {
    "uniprot": "G4LYJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYJ5"
  },
  {
    "uniprot": "A0A5K4F302",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F302"
  },
  {
    "uniprot": "A0A3Q0KLR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLR5"
  },
  {
    "uniprot": "A0A3Q0KUN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUN2"
  },
  {
    "uniprot": "A0A5K4F6X4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6X4"
  },
  {
    "uniprot": "A0A5K4FES2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FES2"
  },
  {
    "uniprot": "A0A5K4ET84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET84"
  },
  {
    "uniprot": "A0A5K4FBV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBV3"
  },
  {
    "uniprot": "A0A5K4F9J6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9J6"
  },
  {
    "uniprot": "G4VSB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSB1"
  },
  {
    "uniprot": "G4VSZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSZ7"
  },
  {
    "uniprot": "A0A3Q0KN23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN23"
  },
  {
    "uniprot": "A0A3Q0KVB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVB3"
  },
  {
    "uniprot": "A0A5K4FCG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCG3"
  },
  {
    "uniprot": "Q15ET4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q15ET4"
  },
  {
    "uniprot": "A0A5K4F7L1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7L1"
  },
  {
    "uniprot": "G4VR00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR00"
  },
  {
    "uniprot": "A0A5K4EDY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDY2"
  },
  {
    "uniprot": "A0A3Q0KGC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGC0"
  },
  {
    "uniprot": "A0A3Q0KUA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUA9"
  },
  {
    "uniprot": "A0A5K4FGF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGF4"
  },
  {
    "uniprot": "A0A5K4EB25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB25"
  },
  {
    "uniprot": "A0A3Q0KG65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG65"
  },
  {
    "uniprot": "A0A5K4F6I6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6I6"
  },
  {
    "uniprot": "A0A5K4FDJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDJ0"
  },
  {
    "uniprot": "G4V8Z5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8Z5"
  },
  {
    "uniprot": "A0A3Q0KVI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVI2"
  },
  {
    "uniprot": "A0A5K4F8Z7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Z7"
  },
  {
    "uniprot": "C4Q0P6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q0P6"
  },
  {
    "uniprot": "G4VRR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRR8"
  },
  {
    "uniprot": "A0A5K4ELS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELS3"
  },
  {
    "uniprot": "A0A3Q0KPV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPV8"
  },
  {
    "uniprot": "A0A5K4FAY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAY4"
  },
  {
    "uniprot": "A0A5K4EBX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBX0"
  },
  {
    "uniprot": "A0A5K4E925",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E925"
  },
  {
    "uniprot": "G4VK13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK13"
  },
  {
    "uniprot": "A0A5K4F8K8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8K8"
  },
  {
    "uniprot": "A0A5K4F990",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F990"
  },
  {
    "uniprot": "A0A3Q0KIJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIJ8"
  },
  {
    "uniprot": "A0A3Q0KRP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRP8"
  },
  {
    "uniprot": "A0A5K4F1D4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1D4"
  },
  {
    "uniprot": "A0A5K4F7M2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7M2"
  },
  {
    "uniprot": "A0A3Q0KNF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNF3"
  },
  {
    "uniprot": "A0A3Q0KUI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUI9"
  },
  {
    "uniprot": "A0A5K4ESI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESI8"
  },
  {
    "uniprot": "A0A5K4EE81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE81"
  },
  {
    "uniprot": "G4LWU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWU0"
  },
  {
    "uniprot": "A0A3Q0KNM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNM8"
  },
  {
    "uniprot": "A0A3Q0KGU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGU0"
  },
  {
    "uniprot": "A0A3Q0KQB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQB8"
  },
  {
    "uniprot": "A0A5K4FDT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDT4"
  },
  {
    "uniprot": "A0A5K4F980",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F980"
  },
  {
    "uniprot": "G4VHU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHU9"
  },
  {
    "uniprot": "G4VLH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLH6"
  },
  {
    "uniprot": "A0A5K4FA18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA18"
  },
  {
    "uniprot": "G4LYD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYD4"
  },
  {
    "uniprot": "A0A5K4ERJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERJ3"
  },
  {
    "uniprot": "G4V5H2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5H2"
  },
  {
    "uniprot": "A0A5K4F0S8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0S8"
  },
  {
    "uniprot": "G4VGK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGK8"
  },
  {
    "uniprot": "A0A3Q0KT38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT38"
  },
  {
    "uniprot": "A0A5K4FCE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCE4"
  },
  {
    "uniprot": "C4QH88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QH88"
  },
  {
    "uniprot": "A0A3Q0KCG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCG8"
  },
  {
    "uniprot": "A0A5K4FCW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCW5"
  },
  {
    "uniprot": "A0A3Q0KV11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV11"
  },
  {
    "uniprot": "A0A5K4EZ23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ23"
  },
  {
    "uniprot": "A0A5K4FGE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGE5"
  },
  {
    "uniprot": "A0A5K4ETG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETG3"
  },
  {
    "uniprot": "G4VTC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTC3"
  },
  {
    "uniprot": "A0A5K4F2D9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2D9"
  },
  {
    "uniprot": "A0A3Q0KIM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIM7"
  },
  {
    "uniprot": "A0A3Q0KIA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIA0"
  },
  {
    "uniprot": "A0A3Q0KLZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLZ4"
  },
  {
    "uniprot": "A0A5K4F8H6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8H6"
  },
  {
    "uniprot": "G4VST2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VST2"
  },
  {
    "uniprot": "G4VLK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLK9"
  },
  {
    "uniprot": "A0A3Q0KQN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQN9"
  },
  {
    "uniprot": "A0A5K4F0F6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0F6"
  },
  {
    "uniprot": "A0A5K4F7H8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7H8"
  },
  {
    "uniprot": "A0A5K4F744",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F744"
  },
  {
    "uniprot": "A0A5K4FA44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA44"
  },
  {
    "uniprot": "A0A5K4FAN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAN4"
  },
  {
    "uniprot": "A0A3Q0KBW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBW8"
  },
  {
    "uniprot": "A0A5K4FBI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBI6"
  },
  {
    "uniprot": "A0A5K4FD58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD58"
  },
  {
    "uniprot": "A0A5K4EHE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHE6"
  },
  {
    "uniprot": "A0A5K4F437",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F437"
  },
  {
    "uniprot": "G4LXZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXZ3"
  },
  {
    "uniprot": "A0A5K4F2U0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2U0"
  },
  {
    "uniprot": "G4VR23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR23"
  },
  {
    "uniprot": "G4V5S2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5S2"
  },
  {
    "uniprot": "G4LYV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYV2"
  },
  {
    "uniprot": "A0A5K4FA46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA46"
  },
  {
    "uniprot": "G4LYV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYV3"
  },
  {
    "uniprot": "A0A3Q0KQJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQJ4"
  },
  {
    "uniprot": "A0A3Q0KP37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP37"
  },
  {
    "uniprot": "A0A5K4EH49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH49"
  },
  {
    "uniprot": "A0A5K4FEG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEG2"
  },
  {
    "uniprot": "G4LX66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX66"
  },
  {
    "uniprot": "A0A3Q0KMW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMW6"
  },
  {
    "uniprot": "G4VGA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGA1"
  },
  {
    "uniprot": "A0A3Q0KQW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQW7"
  },
  {
    "uniprot": "A0A3Q0KGW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGW0"
  },
  {
    "uniprot": "A0A5K4F3Z3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Z3"
  },
  {
    "uniprot": "A0A5K4F461",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F461"
  },
  {
    "uniprot": "A0A5K4F0Y7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Y7"
  },
  {
    "uniprot": "A0A5K4F5W0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5W0"
  },
  {
    "uniprot": "A0A3Q0KTP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTP6"
  },
  {
    "uniprot": "G4VPT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPT3"
  },
  {
    "uniprot": "A0A5K4EKG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKG6"
  },
  {
    "uniprot": "G4LUM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUM3"
  },
  {
    "uniprot": "A0A5K4F3W0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3W0"
  },
  {
    "uniprot": "A0A5K4EFB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFB8"
  },
  {
    "uniprot": "G4LYM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYM8"
  },
  {
    "uniprot": "A0A3Q0KBE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBE1"
  },
  {
    "uniprot": "A0A5K4F047",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F047"
  },
  {
    "uniprot": "A0A5K4F9A4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9A4"
  },
  {
    "uniprot": "A0A5K4F2Y0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Y0"
  },
  {
    "uniprot": "A0A5K4FB09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB09"
  },
  {
    "uniprot": "A0A5K4FA86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA86"
  },
  {
    "uniprot": "A0A5K4ETY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETY2"
  },
  {
    "uniprot": "G4V7P4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7P4"
  },
  {
    "uniprot": "A0A5K4FAZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAZ1"
  },
  {
    "uniprot": "G4V9Z3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9Z3"
  },
  {
    "uniprot": "A0A5K4ENK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENK1"
  },
  {
    "uniprot": "A0A5K4F9I6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9I6"
  },
  {
    "uniprot": "A0A5K4F5J5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5J5"
  },
  {
    "uniprot": "A0A3Q0KPY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPY3"
  },
  {
    "uniprot": "A0A5K4FC57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC57"
  },
  {
    "uniprot": "A0A5K4F9V0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9V0"
  },
  {
    "uniprot": "A0A5K4EBT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBT6"
  },
  {
    "uniprot": "A0A5K4EP14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP14"
  },
  {
    "uniprot": "G4VR77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR77"
  },
  {
    "uniprot": "A0A5K4ELW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELW8"
  },
  {
    "uniprot": "A0A3Q0KKX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKX9"
  },
  {
    "uniprot": "A0A5K4EQH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQH8"
  },
  {
    "uniprot": "A0A5K4F2D6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2D6"
  },
  {
    "uniprot": "A0A5K4F624",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F624"
  },
  {
    "uniprot": "A0A3Q0KI86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI86"
  },
  {
    "uniprot": "G4VA20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA20"
  },
  {
    "uniprot": "A0A3Q0KMQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMQ3"
  },
  {
    "uniprot": "A0A5K4F5S7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5S7"
  },
  {
    "uniprot": "A0A5K4FC77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC77"
  },
  {
    "uniprot": "A0A3Q0KL03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL03"
  },
  {
    "uniprot": "A0A5K4F9R2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9R2"
  },
  {
    "uniprot": "A0A5K4ECQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECQ4"
  },
  {
    "uniprot": "A0A3Q0KKP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKP0"
  },
  {
    "uniprot": "G4VIT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIT3"
  },
  {
    "uniprot": "G4V6V0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6V0"
  },
  {
    "uniprot": "A0A5K4F9K4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9K4"
  },
  {
    "uniprot": "A0A5K4EU16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU16"
  },
  {
    "uniprot": "A0A3Q0KGS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGS8"
  },
  {
    "uniprot": "A0A5K4F6J0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6J0"
  },
  {
    "uniprot": "A0A5K4ED29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED29"
  },
  {
    "uniprot": "G4VHS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHS6"
  },
  {
    "uniprot": "A0A3Q0KNV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNV0"
  },
  {
    "uniprot": "A0A5K4ELR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELR4"
  },
  {
    "uniprot": "A0A5K4F862",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F862"
  },
  {
    "uniprot": "A0A5K4ETI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETI1"
  },
  {
    "uniprot": "A0A3Q0KVF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVF7"
  },
  {
    "uniprot": "A0A5K4F5U9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5U9"
  },
  {
    "uniprot": "A0A5K4EYS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYS9"
  },
  {
    "uniprot": "A0A5K4F1Q9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Q9"
  },
  {
    "uniprot": "A0A5K4FAE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAE4"
  },
  {
    "uniprot": "A0A5K4EEH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEH7"
  },
  {
    "uniprot": "A0A5K4EHZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHZ3"
  },
  {
    "uniprot": "A0A5K4F780",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F780"
  },
  {
    "uniprot": "A0A5K4FDJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDJ5"
  },
  {
    "uniprot": "A0A3Q0KJF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJF0"
  },
  {
    "uniprot": "A0A3Q0KDB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDB7"
  },
  {
    "uniprot": "A0A5K4F8U0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8U0"
  },
  {
    "uniprot": "A0A3Q0KC67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC67"
  },
  {
    "uniprot": "A0A5K4F7J8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7J8"
  },
  {
    "uniprot": "A0A5K4F276",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F276"
  },
  {
    "uniprot": "A0A5K4F4X1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4X1"
  },
  {
    "uniprot": "A0A5K4FAJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAJ5"
  },
  {
    "uniprot": "A0A3Q0KQQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQQ2"
  },
  {
    "uniprot": "Q06814",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q06814"
  },
  {
    "uniprot": "A0A5K4EGS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGS7"
  },
  {
    "uniprot": "A0A3Q0KTQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTQ4"
  },
  {
    "uniprot": "A0A3Q0KVD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVD3"
  },
  {
    "uniprot": "A0A3Q0KK58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK58"
  },
  {
    "uniprot": "A0A5K4F6K7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6K7"
  },
  {
    "uniprot": "A0A5K4ETH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETH3"
  },
  {
    "uniprot": "A0A3Q0KPL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPL9"
  },
  {
    "uniprot": "A0A5K4F945",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F945"
  },
  {
    "uniprot": "A0A5K4F2A1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2A1"
  },
  {
    "uniprot": "A0A5K4F0A0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0A0"
  },
  {
    "uniprot": "A0A5K4EMW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMW2"
  },
  {
    "uniprot": "A0A3Q0KK37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK37"
  },
  {
    "uniprot": "A0A3Q0KML1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KML1"
  },
  {
    "uniprot": "A0A5K4FB91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB91"
  },
  {
    "uniprot": "A0A5K4FBS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBS3"
  },
  {
    "uniprot": "A0A3Q0KSF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSF0"
  },
  {
    "uniprot": "A0A5K4F2P4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2P4"
  },
  {
    "uniprot": "A0A5K4F8T2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8T2"
  },
  {
    "uniprot": "A0A5K4FC55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC55"
  },
  {
    "uniprot": "A0A3Q0KV94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV94"
  },
  {
    "uniprot": "A0A5K4FCY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCY4"
  },
  {
    "uniprot": "A0A5K4F7Q5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Q5"
  },
  {
    "uniprot": "A0A5K4F5K9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5K9"
  },
  {
    "uniprot": "A0A5K4F3M0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3M0"
  },
  {
    "uniprot": "A0A3Q0KGK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGK6"
  },
  {
    "uniprot": "A0A3Q0KDL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDL6"
  },
  {
    "uniprot": "A0A3Q0KUU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUU9"
  },
  {
    "uniprot": "A0A5K4F4R4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4R4"
  },
  {
    "uniprot": "A0A5K4F352",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F352"
  },
  {
    "uniprot": "G4LUX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUX6"
  },
  {
    "uniprot": "A0A5K4EJZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJZ3"
  },
  {
    "uniprot": "A0A3Q0KQU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQU6"
  },
  {
    "uniprot": "A0A5K4F7R3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7R3"
  },
  {
    "uniprot": "A0A5K4EN25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN25"
  },
  {
    "uniprot": "A0A5K4FB86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB86"
  },
  {
    "uniprot": "A0A5K4F9K3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9K3"
  },
  {
    "uniprot": "A0A5K4F707",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F707"
  },
  {
    "uniprot": "A0A3Q0KUX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUX9"
  },
  {
    "uniprot": "G4LUW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUW7"
  },
  {
    "uniprot": "A0A5K4EL77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL77"
  },
  {
    "uniprot": "G4V5M3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5M3"
  },
  {
    "uniprot": "A0A3Q0KSH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSH8"
  },
  {
    "uniprot": "A0A5K4F2F0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2F0"
  },
  {
    "uniprot": "A0A5K4F424",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F424"
  },
  {
    "uniprot": "A0A3Q0KLV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLV5"
  },
  {
    "uniprot": "A0A3Q0KI44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI44"
  },
  {
    "uniprot": "G4V8G0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8G0"
  },
  {
    "uniprot": "A0A5K4ENN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENN7"
  },
  {
    "uniprot": "A0A5K4F837",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F837"
  },
  {
    "uniprot": "G4LVR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVR4"
  },
  {
    "uniprot": "A0A3Q0KMR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMR1"
  },
  {
    "uniprot": "A0A5K4F1D3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1D3"
  },
  {
    "uniprot": "G4VRP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRP0"
  },
  {
    "uniprot": "A0A3Q0KHG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHG9"
  },
  {
    "uniprot": "G4VTW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTW5"
  },
  {
    "uniprot": "A0A3Q0KMV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMV9"
  },
  {
    "uniprot": "G4VN60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VN60"
  },
  {
    "uniprot": "G4V8A8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8A8"
  },
  {
    "uniprot": "A0A5K4F4E3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4E3"
  },
  {
    "uniprot": "A0A5K4FA89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA89"
  },
  {
    "uniprot": "A0A5K4F2Y9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Y9"
  },
  {
    "uniprot": "A0A5K4E9M7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9M7"
  },
  {
    "uniprot": "A0A5K4FF22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF22"
  },
  {
    "uniprot": "A0A146MGF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MGF5"
  },
  {
    "uniprot": "A0A3Q0KLL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLL4"
  },
  {
    "uniprot": "A0A5K4FFX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFX0"
  },
  {
    "uniprot": "A0A5K4F8B8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8B8"
  },
  {
    "uniprot": "A0A5K4EKU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKU5"
  },
  {
    "uniprot": "A0A3Q0KFI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFI1"
  },
  {
    "uniprot": "A0A5K4FB88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB88"
  },
  {
    "uniprot": "A0A5K4F8Z8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Z8"
  },
  {
    "uniprot": "G4VCZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCZ2"
  },
  {
    "uniprot": "A0A3Q0KRA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRA6"
  },
  {
    "uniprot": "A0A5K4F1N9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1N9"
  },
  {
    "uniprot": "A0A5K4F3H0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3H0"
  },
  {
    "uniprot": "A0A5K4F713",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F713"
  },
  {
    "uniprot": "G4VPT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPT8"
  },
  {
    "uniprot": "A0A5K4FE36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE36"
  },
  {
    "uniprot": "G4VL69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VL69"
  },
  {
    "uniprot": "G4VMC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMC8"
  },
  {
    "uniprot": "A0A5K4EKZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKZ3"
  },
  {
    "uniprot": "A0A3Q0KUF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUF6"
  },
  {
    "uniprot": "A0A5K4FEY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEY5"
  },
  {
    "uniprot": "A0A5K4FB79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB79"
  },
  {
    "uniprot": "A0A5K4F9F4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9F4"
  },
  {
    "uniprot": "A0A5K4FAH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAH1"
  },
  {
    "uniprot": "A0A3Q0KG43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG43"
  },
  {
    "uniprot": "A0A3Q0KJ05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ05"
  },
  {
    "uniprot": "A0A5K4F3Y1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Y1"
  },
  {
    "uniprot": "G4VCD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCD9"
  },
  {
    "uniprot": "A0A5K4EY21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY21"
  },
  {
    "uniprot": "A0A5K4F0T8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0T8"
  },
  {
    "uniprot": "A0A5K4F5H6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5H6"
  },
  {
    "uniprot": "A0A3Q0KU79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU79"
  },
  {
    "uniprot": "A0A5K4FAZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAZ0"
  },
  {
    "uniprot": "A0A5K4F6S4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6S4"
  },
  {
    "uniprot": "A0A3Q0KKM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKM1"
  },
  {
    "uniprot": "G4VBK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBK7"
  },
  {
    "uniprot": "A0A3Q0KLQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLQ5"
  },
  {
    "uniprot": "G4V9V8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9V8"
  },
  {
    "uniprot": "A0A3Q0KCX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCX5"
  },
  {
    "uniprot": "A0A3Q0KTK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTK6"
  },
  {
    "uniprot": "A0A3Q0KFF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFF7"
  },
  {
    "uniprot": "G4LV39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV39"
  },
  {
    "uniprot": "A0A5K4F0K3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0K3"
  },
  {
    "uniprot": "A0A5K4F765",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F765"
  },
  {
    "uniprot": "A0A3Q0KMU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMU6"
  },
  {
    "uniprot": "A0A5K4F7X3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7X3"
  },
  {
    "uniprot": "A0A5K4F142",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F142"
  },
  {
    "uniprot": "A0A3Q0KCU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCU7"
  },
  {
    "uniprot": "Q86QS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q86QS6"
  },
  {
    "uniprot": "A0A5K4FD89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD89"
  },
  {
    "uniprot": "A0A3Q0KHR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHR6"
  },
  {
    "uniprot": "A0A5K4F732",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F732"
  },
  {
    "uniprot": "A0A3Q0KGD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGD6"
  },
  {
    "uniprot": "A0A3Q0KT43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT43"
  },
  {
    "uniprot": "A0A3Q0KLP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLP7"
  },
  {
    "uniprot": "A0A5K4F4Z2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Z2"
  },
  {
    "uniprot": "A0A5K4ET56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET56"
  },
  {
    "uniprot": "G4LUS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUS5"
  },
  {
    "uniprot": "G4V892",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V892"
  },
  {
    "uniprot": "A0A3Q0KI59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI59"
  },
  {
    "uniprot": "A0A3Q0KCE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCE4"
  },
  {
    "uniprot": "A0A3Q0KGI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGI6"
  },
  {
    "uniprot": "A0A3Q0KKQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKQ9"
  },
  {
    "uniprot": "A0A5K4F2R4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2R4"
  },
  {
    "uniprot": "A0A3Q0KHD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHD2"
  },
  {
    "uniprot": "A0A3Q0KBZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBZ7"
  },
  {
    "uniprot": "A0A3Q0KLF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLF4"
  },
  {
    "uniprot": "G4V6B1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6B1"
  },
  {
    "uniprot": "A0A3Q0KS33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS33"
  },
  {
    "uniprot": "C4Q8R4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q8R4"
  },
  {
    "uniprot": "A0A3Q0KUV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUV9"
  },
  {
    "uniprot": "A0A5K4FFA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFA6"
  },
  {
    "uniprot": "G4VIZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIZ0"
  },
  {
    "uniprot": "A0A3Q0KGE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGE4"
  },
  {
    "uniprot": "G4V6G9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6G9"
  },
  {
    "uniprot": "A0A5K4FBR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBR1"
  },
  {
    "uniprot": "A0A5K4FEK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEK0"
  },
  {
    "uniprot": "A0A5K4F2L9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2L9"
  },
  {
    "uniprot": "G4VHC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHC5"
  },
  {
    "uniprot": "A0A0U5KJN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A0U5KJN7"
  },
  {
    "uniprot": "A0A5K4F0D3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0D3"
  },
  {
    "uniprot": "G4VHF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHF6"
  },
  {
    "uniprot": "A0A5K4EQ20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ20"
  },
  {
    "uniprot": "A0A5K4EFZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFZ6"
  },
  {
    "uniprot": "A0A3Q0KNR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNR3"
  },
  {
    "uniprot": "A0A3Q0KRV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRV6"
  },
  {
    "uniprot": "A0A5K4F999",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F999"
  },
  {
    "uniprot": "G4V774",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V774"
  },
  {
    "uniprot": "G4VD50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD50"
  },
  {
    "uniprot": "A0A3Q0KQ84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ84"
  },
  {
    "uniprot": "A0A3Q0KRV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRV3"
  },
  {
    "uniprot": "A0A3Q0KQS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQS4"
  },
  {
    "uniprot": "G4VT66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT66"
  },
  {
    "uniprot": "A0A5K4F3N4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3N4"
  },
  {
    "uniprot": "A0A5K4F5L9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5L9"
  },
  {
    "uniprot": "A0A3Q0KCA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCA6"
  },
  {
    "uniprot": "G4M195",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M195"
  },
  {
    "uniprot": "G4LWN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWN8"
  },
  {
    "uniprot": "C4QTL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QTL1"
  },
  {
    "uniprot": "G4VMI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMI8"
  },
  {
    "uniprot": "A0A5K4E8Z5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8Z5"
  },
  {
    "uniprot": "A0A3Q0KPA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPA8"
  },
  {
    "uniprot": "A0A5K4F002",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F002"
  },
  {
    "uniprot": "A0A5K4EZ34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ34"
  },
  {
    "uniprot": "A0A3Q0KNL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNL6"
  },
  {
    "uniprot": "A0A5K4F376",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F376"
  },
  {
    "uniprot": "A0A5K4F4P1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4P1"
  },
  {
    "uniprot": "G4VH08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH08"
  },
  {
    "uniprot": "A0A3Q0KMD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMD1"
  },
  {
    "uniprot": "P32070",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P32070"
  },
  {
    "uniprot": "A0A3Q0KT26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT26"
  },
  {
    "uniprot": "G4LXA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXA7"
  },
  {
    "uniprot": "A0A5K4FG67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FG67"
  },
  {
    "uniprot": "A0A5K4EZV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZV5"
  },
  {
    "uniprot": "A0A5K4F3U4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3U4"
  },
  {
    "uniprot": "A0A5K4EA25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EA25"
  },
  {
    "uniprot": "G4M0R8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0R8"
  },
  {
    "uniprot": "A0A5K4FCC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCC1"
  },
  {
    "uniprot": "A0A3Q0KT87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT87"
  },
  {
    "uniprot": "A0A5K4F0H8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0H8"
  },
  {
    "uniprot": "A0A5K4F9H4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9H4"
  },
  {
    "uniprot": "A0A5K4F3Y4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Y4"
  },
  {
    "uniprot": "A0A5K4FAJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAJ1"
  },
  {
    "uniprot": "A0A5K4F639",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F639"
  },
  {
    "uniprot": "G4VJT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJT8"
  },
  {
    "uniprot": "A0A5K4EVC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVC4"
  },
  {
    "uniprot": "A0A5K4ETC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETC1"
  },
  {
    "uniprot": "A0A3Q0KL11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL11"
  },
  {
    "uniprot": "A0A3Q0KJL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJL5"
  },
  {
    "uniprot": "A0A5K4F7C6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7C6"
  },
  {
    "uniprot": "A0A5K4F8Q4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Q4"
  },
  {
    "uniprot": "G4V6H0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6H0"
  },
  {
    "uniprot": "A0A5K4F4W5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4W5"
  },
  {
    "uniprot": "A0A3Q0KPP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPP4"
  },
  {
    "uniprot": "A0A5K4FF86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF86"
  },
  {
    "uniprot": "G4V862",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V862"
  },
  {
    "uniprot": "A0A5K4EZM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZM8"
  },
  {
    "uniprot": "A0A3Q0KNA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNA4"
  },
  {
    "uniprot": "A0A3Q0KLC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLC4"
  },
  {
    "uniprot": "G4LW52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LW52"
  },
  {
    "uniprot": "A0A5K4EUA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUA1"
  },
  {
    "uniprot": "A0A5K4F6D4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6D4"
  },
  {
    "uniprot": "A0A5K4F0R8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0R8"
  },
  {
    "uniprot": "A0A5K4F771",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F771"
  },
  {
    "uniprot": "A0A5K4FAC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAC7"
  },
  {
    "uniprot": "G4VSW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSW1"
  },
  {
    "uniprot": "A0A5K4E9R8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9R8"
  },
  {
    "uniprot": "A0A3Q0KKZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKZ5"
  },
  {
    "uniprot": "A0A5K4EQP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQP5"
  },
  {
    "uniprot": "A0A5K4ERT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERT2"
  },
  {
    "uniprot": "A0A3Q0KF95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF95"
  },
  {
    "uniprot": "A0A3Q0KFU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFU7"
  },
  {
    "uniprot": "A0A5K4EPD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPD2"
  },
  {
    "uniprot": "G4VFN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFN5"
  },
  {
    "uniprot": "A0A3Q0KLH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLH1"
  },
  {
    "uniprot": "G4LZY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZY4"
  },
  {
    "uniprot": "A0A5K4F9V1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9V1"
  },
  {
    "uniprot": "A0A5K4EY52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY52"
  },
  {
    "uniprot": "G4VES1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VES1"
  },
  {
    "uniprot": "G4VC76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC76"
  },
  {
    "uniprot": "Q9XZG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q9XZG7"
  },
  {
    "uniprot": "A0A3Q0KSF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSF4"
  },
  {
    "uniprot": "A0A3Q0KU47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU47"
  },
  {
    "uniprot": "G4VDQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDQ0"
  },
  {
    "uniprot": "A0A5K4F156",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F156"
  },
  {
    "uniprot": "A0A5K4F6R4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6R4"
  },
  {
    "uniprot": "A0A5K4FH60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FH60"
  },
  {
    "uniprot": "A0A5K4FCU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCU8"
  },
  {
    "uniprot": "A0A3Q0KEF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEF3"
  },
  {
    "uniprot": "A0A5K4FC23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC23"
  },
  {
    "uniprot": "A0A5K4FD12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD12"
  },
  {
    "uniprot": "A0A3Q0KDR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDR4"
  },
  {
    "uniprot": "A0A5K4FBT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBT5"
  },
  {
    "uniprot": "G4V5T6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5T6"
  },
  {
    "uniprot": "A0A5K4EU08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU08"
  },
  {
    "uniprot": "A0A5K4EHT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHT7"
  },
  {
    "uniprot": "A0A5K4FCP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCP9"
  },
  {
    "uniprot": "A0A5K4FEM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEM0"
  },
  {
    "uniprot": "G4VTM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTM0"
  },
  {
    "uniprot": "A0A5K4F3G4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3G4"
  },
  {
    "uniprot": "A0A3Q0KL87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL87"
  },
  {
    "uniprot": "A0A3Q0KLU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLU6"
  },
  {
    "uniprot": "A0A3Q0KSP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSP4"
  },
  {
    "uniprot": "A0A5K4F0F2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0F2"
  },
  {
    "uniprot": "A0A5K4F2U6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2U6"
  },
  {
    "uniprot": "A0A5K4EL70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL70"
  },
  {
    "uniprot": "A0A5K4F983",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F983"
  },
  {
    "uniprot": "A0A3Q0KBI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBI4"
  },
  {
    "uniprot": "A0A3Q0KUI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUI0"
  },
  {
    "uniprot": "A0A5K4FGJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGJ9"
  },
  {
    "uniprot": "A0A3Q0KSI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSI4"
  },
  {
    "uniprot": "G4VDP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDP8"
  },
  {
    "uniprot": "A0A5K4FDI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDI5"
  },
  {
    "uniprot": "A0A5K4F1N1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1N1"
  },
  {
    "uniprot": "A0A3Q0KTZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTZ3"
  },
  {
    "uniprot": "A0A3Q0KH03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH03"
  },
  {
    "uniprot": "A0A5K4EP94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP94"
  },
  {
    "uniprot": "A0A5K4EFJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFJ0"
  },
  {
    "uniprot": "A0A5K4EAZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAZ7"
  },
  {
    "uniprot": "A0A3Q0KID3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KID3"
  },
  {
    "uniprot": "A0A3Q0KSL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSL5"
  },
  {
    "uniprot": "A0A5K4EDL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDL2"
  },
  {
    "uniprot": "A0A5K4FCJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCJ2"
  },
  {
    "uniprot": "A0A5K4EPC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPC8"
  },
  {
    "uniprot": "G4LYW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYW8"
  },
  {
    "uniprot": "A0A3Q0KPE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPE3"
  },
  {
    "uniprot": "A0A5K4EPM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPM4"
  },
  {
    "uniprot": "A0A5K4EN76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN76"
  },
  {
    "uniprot": "A0A3Q0KQE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQE5"
  },
  {
    "uniprot": "A0A5K4FCB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCB0"
  },
  {
    "uniprot": "A0A3Q0KP17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP17"
  },
  {
    "uniprot": "A0A3Q0KDP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDP0"
  },
  {
    "uniprot": "A0A5K4FBB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBB1"
  },
  {
    "uniprot": "A0A3Q0KNN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNN7"
  },
  {
    "uniprot": "A0A5K4E975",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E975"
  },
  {
    "uniprot": "A0A3Q0KQU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQU8"
  },
  {
    "uniprot": "A0A5K4F2X2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2X2"
  },
  {
    "uniprot": "A0A5K4FD07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD07"
  },
  {
    "uniprot": "A0A3Q0KN97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN97"
  },
  {
    "uniprot": "A0A5K4EYF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYF6"
  },
  {
    "uniprot": "A0A5K4F051",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F051"
  },
  {
    "uniprot": "A0A5K4EUE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUE4"
  },
  {
    "uniprot": "A0A3Q0KQC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQC8"
  },
  {
    "uniprot": "A0A3Q0KRW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRW3"
  },
  {
    "uniprot": "A0A5K4FB96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB96"
  },
  {
    "uniprot": "A0A3Q0KNN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNN0"
  },
  {
    "uniprot": "A0A3Q0KTS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTS7"
  },
  {
    "uniprot": "A0A5K4FBQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBQ6"
  },
  {
    "uniprot": "G4VHD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHD9"
  },
  {
    "uniprot": "A0A5K4EXF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXF8"
  },
  {
    "uniprot": "A0A3Q0KBI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBI3"
  },
  {
    "uniprot": "A0A5K4FED6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FED6"
  },
  {
    "uniprot": "A0A3Q0KRF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRF8"
  },
  {
    "uniprot": "A0A3Q0KBM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBM9"
  },
  {
    "uniprot": "A0A5K4FAB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAB0"
  },
  {
    "uniprot": "A0A5K4E8P8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8P8"
  },
  {
    "uniprot": "A0A3Q0KQC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQC0"
  },
  {
    "uniprot": "A0A3Q0KLY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLY3"
  },
  {
    "uniprot": "G4VMK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMK4"
  },
  {
    "uniprot": "A0A5K4F059",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F059"
  },
  {
    "uniprot": "G4V9E4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9E4"
  },
  {
    "uniprot": "G4VLV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLV1"
  },
  {
    "uniprot": "G4VR57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR57"
  },
  {
    "uniprot": "A0A5K4F9D3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9D3"
  },
  {
    "uniprot": "G4VA03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA03"
  },
  {
    "uniprot": "G4VQL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQL4"
  },
  {
    "uniprot": "A0A5K4FEQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEQ3"
  },
  {
    "uniprot": "A0A5K4F7P6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7P6"
  },
  {
    "uniprot": "A0A5K4F7Y1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Y1"
  },
  {
    "uniprot": "A0A3Q0KU65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU65"
  },
  {
    "uniprot": "A0A5K4F7Z0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Z0"
  },
  {
    "uniprot": "A0A5K4FDJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDJ7"
  },
  {
    "uniprot": "A0A5K4EW73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW73"
  },
  {
    "uniprot": "A0A5K4F8G0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8G0"
  },
  {
    "uniprot": "A0A5K4F8B9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8B9"
  },
  {
    "uniprot": "A0A3Q0KMD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMD6"
  },
  {
    "uniprot": "A0A5K4F8S6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8S6"
  },
  {
    "uniprot": "A0A5K4F4K7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4K7"
  },
  {
    "uniprot": "A0A5K4F6J8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6J8"
  },
  {
    "uniprot": "A0A3Q0KBW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBW7"
  },
  {
    "uniprot": "G4VHD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHD5"
  },
  {
    "uniprot": "A0A5K4EX70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX70"
  },
  {
    "uniprot": "A0A3Q0KLV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLV4"
  },
  {
    "uniprot": "G4VA06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA06"
  },
  {
    "uniprot": "A0A3Q0KVD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVD0"
  },
  {
    "uniprot": "A0A5K4E9Z0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9Z0"
  },
  {
    "uniprot": "A0A5K4FBW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBW3"
  },
  {
    "uniprot": "A0A5K4F9L5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9L5"
  },
  {
    "uniprot": "A0A5K4FF85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF85"
  },
  {
    "uniprot": "A0A5K4ENX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENX2"
  },
  {
    "uniprot": "G4VHL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHL8"
  },
  {
    "uniprot": "A0A5K4F2H3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2H3"
  },
  {
    "uniprot": "A0A3Q0KVE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVE9"
  },
  {
    "uniprot": "A0A5K4EYZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYZ5"
  },
  {
    "uniprot": "A0A5K4EXG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXG3"
  },
  {
    "uniprot": "A0A5K4FDI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDI7"
  },
  {
    "uniprot": "A0A5K4ERL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERL6"
  },
  {
    "uniprot": "A0A5K4FBB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBB7"
  },
  {
    "uniprot": "A0A3Q0KHK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHK0"
  },
  {
    "uniprot": "A0A5K4FFG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFG0"
  },
  {
    "uniprot": "A0A3Q0KDV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDV3"
  },
  {
    "uniprot": "A0A5K4ESR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESR7"
  },
  {
    "uniprot": "A0A5K4F942",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F942"
  },
  {
    "uniprot": "G4LUI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUI0"
  },
  {
    "uniprot": "A0A3Q0KEV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEV1"
  },
  {
    "uniprot": "A0A5K4EW25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW25"
  },
  {
    "uniprot": "A0A5K4F2Q1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Q1"
  },
  {
    "uniprot": "A0A3Q0KGB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGB5"
  },
  {
    "uniprot": "A0A3Q0KQG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQG3"
  },
  {
    "uniprot": "A0A3Q0KGI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGI5"
  },
  {
    "uniprot": "A0A5K4EQT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQT0"
  },
  {
    "uniprot": "A0A3Q0KS67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS67"
  },
  {
    "uniprot": "A0A3Q0KF71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF71"
  },
  {
    "uniprot": "A0A5K4FDD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDD0"
  },
  {
    "uniprot": "A0A3Q0KFL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFL5"
  },
  {
    "uniprot": "A0A3Q0KMQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMQ5"
  },
  {
    "uniprot": "A0A3Q0KVG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVG6"
  },
  {
    "uniprot": "A0A5K4FAK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAK7"
  },
  {
    "uniprot": "A0A5K4FCS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCS1"
  },
  {
    "uniprot": "A0A5K4F8D1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8D1"
  },
  {
    "uniprot": "A0A3Q0KGB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGB0"
  },
  {
    "uniprot": "A0A3Q0KIN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIN4"
  },
  {
    "uniprot": "A0A3Q0KLB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLB0"
  },
  {
    "uniprot": "Q4JHT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q4JHT3"
  },
  {
    "uniprot": "A0A5K4ER06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER06"
  },
  {
    "uniprot": "A0A5K4F3X2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3X2"
  },
  {
    "uniprot": "A0A5K4ES61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES61"
  },
  {
    "uniprot": "A0A5K4F753",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F753"
  },
  {
    "uniprot": "G4V5L5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5L5"
  },
  {
    "uniprot": "A0A5K4F805",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F805"
  },
  {
    "uniprot": "A0A5K4EL04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL04"
  },
  {
    "uniprot": "C4QCD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QCD2"
  },
  {
    "uniprot": "A0A5K4F030",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F030"
  },
  {
    "uniprot": "A0A3Q0KTD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTD8"
  },
  {
    "uniprot": "A0A3Q0KKT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKT2"
  },
  {
    "uniprot": "A0A3Q0KJ81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ81"
  },
  {
    "uniprot": "A0A5K4F2S5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2S5"
  },
  {
    "uniprot": "A0A5K4FAY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAY5"
  },
  {
    "uniprot": "A0A5K4EZW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZW4"
  },
  {
    "uniprot": "A0A3Q0KET7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KET7"
  },
  {
    "uniprot": "A0A5K4F223",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F223"
  },
  {
    "uniprot": "G4VSB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSB7"
  },
  {
    "uniprot": "A0A5K4F2V1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2V1"
  },
  {
    "uniprot": "A0A3Q0KSD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSD3"
  },
  {
    "uniprot": "A0A5K4F7A8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7A8"
  },
  {
    "uniprot": "A0A3Q0KBA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBA5"
  },
  {
    "uniprot": "A0A5K4EBP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBP0"
  },
  {
    "uniprot": "A0A3Q0KSS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSS7"
  },
  {
    "uniprot": "A0A5K4EN27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN27"
  },
  {
    "uniprot": "A0A5K4F3T5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3T5"
  },
  {
    "uniprot": "A0A5K4F511",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F511"
  },
  {
    "uniprot": "A0A5K4FBQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBQ3"
  },
  {
    "uniprot": "A0A5K4F4N6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4N6"
  },
  {
    "uniprot": "A0A5K4F3R2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3R2"
  },
  {
    "uniprot": "A0A5K4F811",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F811"
  },
  {
    "uniprot": "G4VKF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKF6"
  },
  {
    "uniprot": "G4VM90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM90"
  },
  {
    "uniprot": "A0A5K4FA16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA16"
  },
  {
    "uniprot": "G4VN19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VN19"
  },
  {
    "uniprot": "G4VMG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMG8"
  },
  {
    "uniprot": "A0A3Q0KMQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMQ1"
  },
  {
    "uniprot": "A0A3Q0KJY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJY9"
  },
  {
    "uniprot": "A0A3Q0KFG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFG9"
  },
  {
    "uniprot": "A0A5K4FF43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF43"
  },
  {
    "uniprot": "A0A5K4EZ40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ40"
  },
  {
    "uniprot": "A0A3Q0KCA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCA5"
  },
  {
    "uniprot": "A0A5K4EWL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWL0"
  },
  {
    "uniprot": "A0A5K4FAH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAH8"
  },
  {
    "uniprot": "A0A5K4EZ92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ92"
  },
  {
    "uniprot": "A0A3Q0KU22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU22"
  },
  {
    "uniprot": "G4VDS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDS7"
  },
  {
    "uniprot": "A0A5K4F3T8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3T8"
  },
  {
    "uniprot": "A0A3Q0KCP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCP0"
  },
  {
    "uniprot": "A0A5K4EZ59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ59"
  },
  {
    "uniprot": "A0A5K4F953",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F953"
  },
  {
    "uniprot": "A0A3Q0KQL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQL0"
  },
  {
    "uniprot": "A0A3Q0KGC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGC9"
  },
  {
    "uniprot": "A0A5K4FDN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDN7"
  },
  {
    "uniprot": "A0A3Q0KFP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFP1"
  },
  {
    "uniprot": "A0A3Q0KV02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV02"
  },
  {
    "uniprot": "A0A3Q0KRU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRU8"
  },
  {
    "uniprot": "A0A3Q0KCF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCF1"
  },
  {
    "uniprot": "O17307",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/O17307"
  },
  {
    "uniprot": "A0A5K4F0G3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0G3"
  },
  {
    "uniprot": "G4VKK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKK2"
  },
  {
    "uniprot": "A0A5K4F5T5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5T5"
  },
  {
    "uniprot": "A0A5K4EGQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGQ2"
  },
  {
    "uniprot": "A0A3Q0KSI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSI3"
  },
  {
    "uniprot": "A0A5K4EPH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPH3"
  },
  {
    "uniprot": "A0A3Q0KBY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBY7"
  },
  {
    "uniprot": "A0A5K4EWD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWD5"
  },
  {
    "uniprot": "A0A5K4F4K0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4K0"
  },
  {
    "uniprot": "A0A5K4E9G8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9G8"
  },
  {
    "uniprot": "A0A3Q0KTV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTV8"
  },
  {
    "uniprot": "G4VHX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHX9"
  },
  {
    "uniprot": "A0A5K4F1X9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1X9"
  },
  {
    "uniprot": "G4LY24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY24"
  },
  {
    "uniprot": "G4LVY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVY9"
  },
  {
    "uniprot": "A0A3Q0KHW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHW9"
  },
  {
    "uniprot": "A0A5K4FFQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFQ0"
  },
  {
    "uniprot": "G4VHV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHV5"
  },
  {
    "uniprot": "A0A3Q0KUW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUW3"
  },
  {
    "uniprot": "A0A3Q0KMB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMB9"
  },
  {
    "uniprot": "A0A3Q0KQ13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ13"
  },
  {
    "uniprot": "A0A146MGI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MGI0"
  },
  {
    "uniprot": "A0A5K4EP74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP74"
  },
  {
    "uniprot": "G4VJ07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ07"
  },
  {
    "uniprot": "G4VGT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGT0"
  },
  {
    "uniprot": "A0A3Q0KJ25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ25"
  },
  {
    "uniprot": "G4VGF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGF2"
  },
  {
    "uniprot": "A0A5K4F409",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F409"
  },
  {
    "uniprot": "A0A5K4F1W7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1W7"
  },
  {
    "uniprot": "A0A5K4F1G6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1G6"
  },
  {
    "uniprot": "A0A5K4FA26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA26"
  },
  {
    "uniprot": "A0A5K4FBY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBY5"
  },
  {
    "uniprot": "G4VMA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMA1"
  },
  {
    "uniprot": "G4VHB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHB7"
  },
  {
    "uniprot": "A0A5K4F8D2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8D2"
  },
  {
    "uniprot": "A0A5K4EVU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVU4"
  },
  {
    "uniprot": "A0A3Q0KN31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN31"
  },
  {
    "uniprot": "A0A3Q0KQA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQA2"
  },
  {
    "uniprot": "A0A5K4F7B4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7B4"
  },
  {
    "uniprot": "A0A3Q0KRX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRX1"
  },
  {
    "uniprot": "A0A3Q0KT14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT14"
  },
  {
    "uniprot": "A0A3Q0KSV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSV2"
  },
  {
    "uniprot": "A0A5K4EVK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVK6"
  },
  {
    "uniprot": "A0A5K4F453",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F453"
  },
  {
    "uniprot": "A0A3Q0KGJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGJ2"
  },
  {
    "uniprot": "G4VRY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRY8"
  },
  {
    "uniprot": "A0A5K4EGI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGI8"
  },
  {
    "uniprot": "G4VF21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF21"
  },
  {
    "uniprot": "A0A3Q0KE25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE25"
  },
  {
    "uniprot": "A0A3Q0KQ41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ41"
  },
  {
    "uniprot": "A0A5K4F3K3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3K3"
  },
  {
    "uniprot": "A0A5K4EB59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB59"
  },
  {
    "uniprot": "A0A5K4F658",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F658"
  },
  {
    "uniprot": "A0A5K4EKD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKD7"
  },
  {
    "uniprot": "A0A3Q0KVK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVK2"
  },
  {
    "uniprot": "A0A5K4F770",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F770"
  },
  {
    "uniprot": "G4M1B5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1B5"
  },
  {
    "uniprot": "A0A5K4EVY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVY4"
  },
  {
    "uniprot": "A0A5K4F874",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F874"
  },
  {
    "uniprot": "A0A5K4EUH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUH9"
  },
  {
    "uniprot": "A0A5K4F4J0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4J0"
  },
  {
    "uniprot": "A0A5K4ETE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETE6"
  },
  {
    "uniprot": "A0A3Q0KM97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM97"
  },
  {
    "uniprot": "G4VBG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBG0"
  },
  {
    "uniprot": "A0A3Q0KKU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKU8"
  },
  {
    "uniprot": "A0A5K4F9I8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9I8"
  },
  {
    "uniprot": "A0A5K4FC30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC30"
  },
  {
    "uniprot": "A0A5K4E9D4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9D4"
  },
  {
    "uniprot": "A0A5K4ELB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELB8"
  },
  {
    "uniprot": "A0A3Q0KLE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLE2"
  },
  {
    "uniprot": "A0A3Q0KS17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS17"
  },
  {
    "uniprot": "A0A3Q0KD61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD61"
  },
  {
    "uniprot": "A0A5K4EZP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZP3"
  },
  {
    "uniprot": "G4LVJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVJ7"
  },
  {
    "uniprot": "A0A5K4F393",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F393"
  },
  {
    "uniprot": "A0A5K4EK70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK70"
  },
  {
    "uniprot": "A0A5K4ESM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESM1"
  },
  {
    "uniprot": "A0A3Q0KJK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJK6"
  },
  {
    "uniprot": "A0A5K4F4D8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4D8"
  },
  {
    "uniprot": "A0A3Q0KSF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSF3"
  },
  {
    "uniprot": "A0A5K4F5F8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5F8"
  },
  {
    "uniprot": "A0A3Q0KVH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVH3"
  },
  {
    "uniprot": "A0A3Q0KHA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHA4"
  },
  {
    "uniprot": "A0A3Q0KRG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRG0"
  },
  {
    "uniprot": "A0A5K4EQ85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ85"
  },
  {
    "uniprot": "A0A3Q0KMT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMT9"
  },
  {
    "uniprot": "A0A3Q0KF36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF36"
  },
  {
    "uniprot": "P25320",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P25320"
  },
  {
    "uniprot": "A0A5K4F611",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F611"
  },
  {
    "uniprot": "A0A5K4F9W6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9W6"
  },
  {
    "uniprot": "A0A3Q0KLA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLA7"
  },
  {
    "uniprot": "P53442",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P53442"
  },
  {
    "uniprot": "G4M0U1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0U1"
  },
  {
    "uniprot": "A0A5K4EJP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJP3"
  },
  {
    "uniprot": "A0A5K4F379",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F379"
  },
  {
    "uniprot": "A0A5K4ECB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECB0"
  },
  {
    "uniprot": "A0A5K4EVA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVA4"
  },
  {
    "uniprot": "A0A3Q0KTB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTB8"
  },
  {
    "uniprot": "A0A5K4EM79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM79"
  },
  {
    "uniprot": "A0A3Q0KMZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMZ6"
  },
  {
    "uniprot": "A0A3Q0KKJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKJ4"
  },
  {
    "uniprot": "A0A5K4F8N1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8N1"
  },
  {
    "uniprot": "A0A5K4EHT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHT1"
  },
  {
    "uniprot": "C4QJ35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QJ35"
  },
  {
    "uniprot": "A0A3Q0KTT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTT9"
  },
  {
    "uniprot": "A0A3Q0KKH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKH5"
  },
  {
    "uniprot": "A0A5K4EBD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBD8"
  },
  {
    "uniprot": "A0A5K4ES56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES56"
  },
  {
    "uniprot": "A0A3Q0KQ61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ61"
  },
  {
    "uniprot": "A0A3Q0KP99",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP99"
  },
  {
    "uniprot": "A0A5K4FFA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFA3"
  },
  {
    "uniprot": "C4QSB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QSB3"
  },
  {
    "uniprot": "A0A5K4F4N4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4N4"
  },
  {
    "uniprot": "G4VH30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH30"
  },
  {
    "uniprot": "A0A3Q0KBY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBY6"
  },
  {
    "uniprot": "A0A3Q0KGX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGX6"
  },
  {
    "uniprot": "A0A5K4ETN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETN3"
  },
  {
    "uniprot": "A0A3Q0KM64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM64"
  },
  {
    "uniprot": "A0A3Q0KKN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKN8"
  },
  {
    "uniprot": "A0A3Q0KLA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLA4"
  },
  {
    "uniprot": "A0A5K4F2E2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2E2"
  },
  {
    "uniprot": "A0A5K4F480",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F480"
  },
  {
    "uniprot": "A0A3Q0KVE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVE2"
  },
  {
    "uniprot": "A0A5K4EVC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVC8"
  },
  {
    "uniprot": "G4M0P2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0P2"
  },
  {
    "uniprot": "A0A5K4F6L3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6L3"
  },
  {
    "uniprot": "A0A5K4FFS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFS9"
  },
  {
    "uniprot": "G4V7E3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7E3"
  },
  {
    "uniprot": "A0A3Q0KFC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFC5"
  },
  {
    "uniprot": "G4VP84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VP84"
  },
  {
    "uniprot": "A0A5K4F1D6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1D6"
  },
  {
    "uniprot": "G4VII4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VII4"
  },
  {
    "uniprot": "G4VAN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAN1"
  },
  {
    "uniprot": "A0A3Q0KI03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI03"
  },
  {
    "uniprot": "A0A5K4FAF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAF3"
  },
  {
    "uniprot": "A0A5K4EX98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX98"
  },
  {
    "uniprot": "A0A5K4EVW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVW1"
  },
  {
    "uniprot": "A0A5K4EWA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWA8"
  },
  {
    "uniprot": "A0A5K4F5R8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5R8"
  },
  {
    "uniprot": "A0A5K4F894",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F894"
  },
  {
    "uniprot": "A0A5K4EMW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMW0"
  },
  {
    "uniprot": "A0A3Q0KQ62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ62"
  },
  {
    "uniprot": "A0A5K4FAQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAQ3"
  },
  {
    "uniprot": "G4VNP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNP8"
  },
  {
    "uniprot": "A0A3Q0KL59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL59"
  },
  {
    "uniprot": "A0A5K4FBZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBZ7"
  },
  {
    "uniprot": "A0A5K4F4T8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4T8"
  },
  {
    "uniprot": "G4VJM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJM8"
  },
  {
    "uniprot": "A0A5K4ERL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERL5"
  },
  {
    "uniprot": "A0A3Q0KTP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTP4"
  },
  {
    "uniprot": "A0A3Q0KTC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTC4"
  },
  {
    "uniprot": "G4VSC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSC2"
  },
  {
    "uniprot": "A0A5K4FAR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAR3"
  },
  {
    "uniprot": "A0A3Q0KGF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGF7"
  },
  {
    "uniprot": "G4VLH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLH9"
  },
  {
    "uniprot": "A0A3Q0KQN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQN7"
  },
  {
    "uniprot": "A0A3Q0KCI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCI9"
  },
  {
    "uniprot": "A0A3Q0KF78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF78"
  },
  {
    "uniprot": "A0A5K4EWG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWG3"
  },
  {
    "uniprot": "A0A3Q0KS97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS97"
  },
  {
    "uniprot": "A0A3Q0KKS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKS7"
  },
  {
    "uniprot": "A0A5K4ERR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERR8"
  },
  {
    "uniprot": "G4VCH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCH5"
  },
  {
    "uniprot": "A0A5K4F5A9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5A9"
  },
  {
    "uniprot": "G4LX32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX32"
  },
  {
    "uniprot": "G4V8G5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8G5"
  },
  {
    "uniprot": "A0A3Q0KFB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFB6"
  },
  {
    "uniprot": "A0A3Q0KJ22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ22"
  },
  {
    "uniprot": "A0A3Q0KHX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHX4"
  },
  {
    "uniprot": "A0A5K4ELL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELL5"
  },
  {
    "uniprot": "G4V8K8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8K8"
  },
  {
    "uniprot": "A0A3Q0KJ78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ78"
  },
  {
    "uniprot": "A0A5K4F9B5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9B5"
  },
  {
    "uniprot": "A0A5K4F8F1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8F1"
  },
  {
    "uniprot": "A0A5K4ETD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETD4"
  },
  {
    "uniprot": "A0A5K4F085",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F085"
  },
  {
    "uniprot": "A0A5K4F678",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F678"
  },
  {
    "uniprot": "A0A5K4F510",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F510"
  },
  {
    "uniprot": "A0A3Q0KNP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNP8"
  },
  {
    "uniprot": "G4VPN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPN9"
  },
  {
    "uniprot": "A0A5K4F0V0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0V0"
  },
  {
    "uniprot": "A0A3Q0KD03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD03"
  },
  {
    "uniprot": "G4VEJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEJ5"
  },
  {
    "uniprot": "G4VH79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH79"
  },
  {
    "uniprot": "G4VEY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEY6"
  },
  {
    "uniprot": "A0A5K4F6B0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6B0"
  },
  {
    "uniprot": "A0A5K4EYX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYX4"
  },
  {
    "uniprot": "G4VC98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC98"
  },
  {
    "uniprot": "G4M149",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M149"
  },
  {
    "uniprot": "A0A5K4ESF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESF0"
  },
  {
    "uniprot": "G4VLR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLR7"
  },
  {
    "uniprot": "A0A5K4FA93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA93"
  },
  {
    "uniprot": "G4VK12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK12"
  },
  {
    "uniprot": "A0A3Q0KSG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSG7"
  },
  {
    "uniprot": "A0A5K4F0L8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0L8"
  },
  {
    "uniprot": "A0A3Q0KPT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPT3"
  },
  {
    "uniprot": "G4VKL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKL6"
  },
  {
    "uniprot": "A0A3Q0KSD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSD2"
  },
  {
    "uniprot": "A0A5K4ESF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESF3"
  },
  {
    "uniprot": "A0A3Q0KK90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK90"
  },
  {
    "uniprot": "A0A3Q0KLT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLT4"
  },
  {
    "uniprot": "A0A5K4FDC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDC9"
  },
  {
    "uniprot": "A0A3Q0KGV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGV3"
  },
  {
    "uniprot": "A0A5K4F9B0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9B0"
  },
  {
    "uniprot": "A0A3Q0KMK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMK8"
  },
  {
    "uniprot": "A0A5K4F1W1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1W1"
  },
  {
    "uniprot": "A0A3Q0KU28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU28"
  },
  {
    "uniprot": "A0A5K4FBW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBW9"
  },
  {
    "uniprot": "A0A5K4EXJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXJ1"
  },
  {
    "uniprot": "A0A5K4EYJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYJ5"
  },
  {
    "uniprot": "A0A5K4EZF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZF7"
  },
  {
    "uniprot": "G4VIF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIF5"
  },
  {
    "uniprot": "A0A3Q0KPA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPA7"
  },
  {
    "uniprot": "A0A5K4EGY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGY1"
  },
  {
    "uniprot": "A0A5K4FE73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE73"
  },
  {
    "uniprot": "G4V735",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V735"
  },
  {
    "uniprot": "A0A5K4F6X1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6X1"
  },
  {
    "uniprot": "G4M0R9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0R9"
  },
  {
    "uniprot": "A0A5K4E8L0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8L0"
  },
  {
    "uniprot": "A0A3Q0KPR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPR1"
  },
  {
    "uniprot": "A0A3Q0KDD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDD9"
  },
  {
    "uniprot": "A0A5K4F8F9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8F9"
  },
  {
    "uniprot": "A0A5K4EMI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMI0"
  },
  {
    "uniprot": "Q26561",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26561"
  },
  {
    "uniprot": "A0A3Q0KIY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIY2"
  },
  {
    "uniprot": "A0A5K4EPS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPS1"
  },
  {
    "uniprot": "A0A5K4F799",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F799"
  },
  {
    "uniprot": "A0A5K4F6B1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6B1"
  },
  {
    "uniprot": "G4VLA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLA9"
  },
  {
    "uniprot": "G4LVZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVZ7"
  },
  {
    "uniprot": "A0A5K4F365",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F365"
  },
  {
    "uniprot": "A0A5K4EZG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZG2"
  },
  {
    "uniprot": "G4LU85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LU85"
  },
  {
    "uniprot": "A0A3Q0KI09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI09"
  },
  {
    "uniprot": "G4M233",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M233"
  },
  {
    "uniprot": "A0A3Q0KET6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KET6"
  },
  {
    "uniprot": "A0A3Q0KKD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKD0"
  },
  {
    "uniprot": "G4VSC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSC8"
  },
  {
    "uniprot": "A0A5K4FDM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDM2"
  },
  {
    "uniprot": "A0A3Q0KEM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEM0"
  },
  {
    "uniprot": "A0A3Q0KEF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEF9"
  },
  {
    "uniprot": "A0A3Q0KHT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHT4"
  },
  {
    "uniprot": "A0A5K4F4X4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4X4"
  },
  {
    "uniprot": "A0A5K4EX03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX03"
  },
  {
    "uniprot": "A0A5K4F489",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F489"
  },
  {
    "uniprot": "A0A3Q0KRI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRI3"
  },
  {
    "uniprot": "A0A5K4F6K4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6K4"
  },
  {
    "uniprot": "A0A5K4EEI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEI6"
  },
  {
    "uniprot": "G4V9I5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9I5"
  },
  {
    "uniprot": "A0A3Q0KGD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGD7"
  },
  {
    "uniprot": "G4LUX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUX0"
  },
  {
    "uniprot": "A0A3Q0KP76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP76"
  },
  {
    "uniprot": "A0A5K4F6S5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6S5"
  },
  {
    "uniprot": "A0A5K4EZ45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ45"
  },
  {
    "uniprot": "A0A5K4ENR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENR0"
  },
  {
    "uniprot": "A0A5K4F277",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F277"
  },
  {
    "uniprot": "A0A5K4ETG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETG7"
  },
  {
    "uniprot": "A0A3Q0KQV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQV1"
  },
  {
    "uniprot": "A0A5K4FB54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB54"
  },
  {
    "uniprot": "A0A3Q0KFK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFK5"
  },
  {
    "uniprot": "G4VR87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR87"
  },
  {
    "uniprot": "G4V8R1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8R1"
  },
  {
    "uniprot": "G4V884",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V884"
  },
  {
    "uniprot": "G4VBK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBK5"
  },
  {
    "uniprot": "G4VNA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNA0"
  },
  {
    "uniprot": "G4V636",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V636"
  },
  {
    "uniprot": "A0A5K4EVW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVW0"
  },
  {
    "uniprot": "A0A5K4F4L4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4L4"
  },
  {
    "uniprot": "G4VLW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLW3"
  },
  {
    "uniprot": "A0A5K4FCM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCM0"
  },
  {
    "uniprot": "A0A5K4F986",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F986"
  },
  {
    "uniprot": "A0A3Q0KMR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMR7"
  },
  {
    "uniprot": "A0A5K4F2M8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2M8"
  },
  {
    "uniprot": "A0A5K4F2P1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2P1"
  },
  {
    "uniprot": "A0A3Q0KI89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI89"
  },
  {
    "uniprot": "Q27877",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q27877"
  },
  {
    "uniprot": "A0A5K4F0M9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0M9"
  },
  {
    "uniprot": "I0B503",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/I0B503"
  },
  {
    "uniprot": "A0A5K4F2G6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2G6"
  },
  {
    "uniprot": "G4VK90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK90"
  },
  {
    "uniprot": "A0A5K4E9X2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9X2"
  },
  {
    "uniprot": "A0A5K4EYT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYT7"
  },
  {
    "uniprot": "A0A5K4F9D4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9D4"
  },
  {
    "uniprot": "A0A146MIG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MIG6"
  },
  {
    "uniprot": "A0A5K4F433",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F433"
  },
  {
    "uniprot": "A0A5K4F4L3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4L3"
  },
  {
    "uniprot": "A0A3Q0KSA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSA4"
  },
  {
    "uniprot": "G4VRL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRL6"
  },
  {
    "uniprot": "A0A3Q0KD25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD25"
  },
  {
    "uniprot": "A0A3Q0KT53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT53"
  },
  {
    "uniprot": "G4LWX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWX5"
  },
  {
    "uniprot": "A0A3Q0KQ64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ64"
  },
  {
    "uniprot": "A0A3Q7K6N6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q7K6N6"
  },
  {
    "uniprot": "G4M0Y0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0Y0"
  },
  {
    "uniprot": "A0A5K4FD03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD03"
  },
  {
    "uniprot": "A0A5K4F0S4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0S4"
  },
  {
    "uniprot": "A0A3Q0KL32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL32"
  },
  {
    "uniprot": "A0A5K4F0D5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0D5"
  },
  {
    "uniprot": "A0A3Q0KP45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP45"
  },
  {
    "uniprot": "A0A5K4F2N8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2N8"
  },
  {
    "uniprot": "G4VFM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFM4"
  },
  {
    "uniprot": "A0A5K4EWZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWZ0"
  },
  {
    "uniprot": "A0A5K4FBR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBR7"
  },
  {
    "uniprot": "G4VGH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGH8"
  },
  {
    "uniprot": "G4V5A1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5A1"
  },
  {
    "uniprot": "A0A3Q0KHX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHX7"
  },
  {
    "uniprot": "A0A5K4EWH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWH9"
  },
  {
    "uniprot": "G4LX30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX30"
  },
  {
    "uniprot": "A0A5K4F8P1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8P1"
  },
  {
    "uniprot": "A0A5K4FDK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDK8"
  },
  {
    "uniprot": "A0A5K4FA67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA67"
  },
  {
    "uniprot": "A0A5K4EZ72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ72"
  },
  {
    "uniprot": "A0A3Q0KBE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBE7"
  },
  {
    "uniprot": "A0A5K4FAR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAR9"
  },
  {
    "uniprot": "G4VNZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNZ2"
  },
  {
    "uniprot": "A0A5K4F5X0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5X0"
  },
  {
    "uniprot": "G4VQ69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ69"
  },
  {
    "uniprot": "A0A5K4F691",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F691"
  },
  {
    "uniprot": "A0A5K4FCQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCQ7"
  },
  {
    "uniprot": "A0A5K4F7I9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7I9"
  },
  {
    "uniprot": "A0A5K4ER48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER48"
  },
  {
    "uniprot": "Q2VU48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q2VU48"
  },
  {
    "uniprot": "G4VEZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEZ6"
  },
  {
    "uniprot": "A0A5K4F1M0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1M0"
  },
  {
    "uniprot": "G4VAD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAD0"
  },
  {
    "uniprot": "A0A5K4ELT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELT2"
  },
  {
    "uniprot": "A0A3Q0KMG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMG0"
  },
  {
    "uniprot": "A0A3Q0KEC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEC8"
  },
  {
    "uniprot": "A0A5K4F2B5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2B5"
  },
  {
    "uniprot": "A0A5K4F1K7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1K7"
  },
  {
    "uniprot": "A0A5K4F5X5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5X5"
  },
  {
    "uniprot": "A0A5K4F414",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F414"
  },
  {
    "uniprot": "A0A5K4ELF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELF1"
  },
  {
    "uniprot": "Q26548",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26548"
  },
  {
    "uniprot": "A0A5K4ENA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENA4"
  },
  {
    "uniprot": "A0A3Q0KHD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHD9"
  },
  {
    "uniprot": "A0A5K4ELQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELQ1"
  },
  {
    "uniprot": "A0A3Q0KF16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF16"
  },
  {
    "uniprot": "A0A5K4EYN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYN8"
  },
  {
    "uniprot": "A0A5K4EQF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQF8"
  },
  {
    "uniprot": "A0A5K4ESS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESS7"
  },
  {
    "uniprot": "A0A3Q0KN10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN10"
  },
  {
    "uniprot": "A0A5K4F9S1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9S1"
  },
  {
    "uniprot": "A0A5K4EGW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGW2"
  },
  {
    "uniprot": "A0A5K4FDM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDM1"
  },
  {
    "uniprot": "A0A5K4F2X5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2X5"
  },
  {
    "uniprot": "A0A5K4EIC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIC2"
  },
  {
    "uniprot": "A0A5K4F4T2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4T2"
  },
  {
    "uniprot": "G4VQG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQG2"
  },
  {
    "uniprot": "A0A5K4EG07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EG07"
  },
  {
    "uniprot": "G4VFV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFV4"
  },
  {
    "uniprot": "A0A5K4F6S2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6S2"
  },
  {
    "uniprot": "A0A5K4FBF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBF5"
  },
  {
    "uniprot": "A0A3Q0KRS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRS1"
  },
  {
    "uniprot": "A0A3Q0KEL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEL6"
  },
  {
    "uniprot": "A0A5K4F814",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F814"
  },
  {
    "uniprot": "A0A5K4FAR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAR4"
  },
  {
    "uniprot": "A0A5K4EDV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDV6"
  },
  {
    "uniprot": "G4LWB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWB4"
  },
  {
    "uniprot": "A0A3Q0KJ24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ24"
  },
  {
    "uniprot": "A0A5K4FEM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEM7"
  },
  {
    "uniprot": "A0A3Q0KV68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV68"
  },
  {
    "uniprot": "A0A5K4EIU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIU6"
  },
  {
    "uniprot": "A0A5K4F2Y7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Y7"
  },
  {
    "uniprot": "A0A5K4F205",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F205"
  },
  {
    "uniprot": "A0A3Q0KLT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLT7"
  },
  {
    "uniprot": "A0A3Q0KGR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGR2"
  },
  {
    "uniprot": "A0A3Q0KTL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTL5"
  },
  {
    "uniprot": "G4V705",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V705"
  },
  {
    "uniprot": "A0A5K4EPM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPM2"
  },
  {
    "uniprot": "A0A3Q0KCU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCU2"
  },
  {
    "uniprot": "A0A5K4FFF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFF7"
  },
  {
    "uniprot": "A0A3Q0KJB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJB6"
  },
  {
    "uniprot": "A0A3Q0KDF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDF8"
  },
  {
    "uniprot": "A0A5K4F9K2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9K2"
  },
  {
    "uniprot": "A0A3Q0KS39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS39"
  },
  {
    "uniprot": "A0A5K4F7L0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7L0"
  },
  {
    "uniprot": "A0A5K4EHT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHT4"
  },
  {
    "uniprot": "A0A5K4F1Z8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Z8"
  },
  {
    "uniprot": "A0A3Q0KQR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQR4"
  },
  {
    "uniprot": "G4VDV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDV0"
  },
  {
    "uniprot": "A0A5K4EGU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGU5"
  },
  {
    "uniprot": "A0A5K4F9C9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9C9"
  },
  {
    "uniprot": "A0A5K4FBB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBB9"
  },
  {
    "uniprot": "A0A5K4F2S0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2S0"
  },
  {
    "uniprot": "A0A5K4F8B5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8B5"
  },
  {
    "uniprot": "A0A5K4ERF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERF5"
  },
  {
    "uniprot": "A0A3Q0KIM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIM9"
  },
  {
    "uniprot": "A0A5K4EPW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPW2"
  },
  {
    "uniprot": "A0A5K4EPW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPW4"
  },
  {
    "uniprot": "A0A5K4F6J4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6J4"
  },
  {
    "uniprot": "A0A3Q0KKE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKE8"
  },
  {
    "uniprot": "A0A5K4EMD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMD1"
  },
  {
    "uniprot": "G4LUD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUD6"
  },
  {
    "uniprot": "A0A5K4F708",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F708"
  },
  {
    "uniprot": "A0A5K4E8S2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8S2"
  },
  {
    "uniprot": "A0A3Q0KBT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBT1"
  },
  {
    "uniprot": "G4VEN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEN7"
  },
  {
    "uniprot": "A0A3Q0KVE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVE0"
  },
  {
    "uniprot": "A0A5K4F2F7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2F7"
  },
  {
    "uniprot": "A0A5K4F079",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F079"
  },
  {
    "uniprot": "A0A5K4FCU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCU6"
  },
  {
    "uniprot": "A0A3Q0KJC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJC6"
  },
  {
    "uniprot": "A0A3Q0KK75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK75"
  },
  {
    "uniprot": "A0A5K4FBR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBR2"
  },
  {
    "uniprot": "A0A5K4EWD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWD9"
  },
  {
    "uniprot": "A0A3Q0KD31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD31"
  },
  {
    "uniprot": "A0A5K4EW27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW27"
  },
  {
    "uniprot": "A0A3Q0KCV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCV6"
  },
  {
    "uniprot": "A0A5K4F541",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F541"
  },
  {
    "uniprot": "A0A5K4F6I9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6I9"
  },
  {
    "uniprot": "A0A5K4F7U5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7U5"
  },
  {
    "uniprot": "A0A5K4E9H7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9H7"
  },
  {
    "uniprot": "A0A3Q0KG91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG91"
  },
  {
    "uniprot": "A0A5K4FB12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB12"
  },
  {
    "uniprot": "A0A5K4F6U3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6U3"
  },
  {
    "uniprot": "A0A5K4F3I2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3I2"
  },
  {
    "uniprot": "A0A5K4FCE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCE2"
  },
  {
    "uniprot": "A0A3Q0KT48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT48"
  },
  {
    "uniprot": "A0A5K4E9W9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9W9"
  },
  {
    "uniprot": "A0A5K4FF45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF45"
  },
  {
    "uniprot": "G4VJL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJL7"
  },
  {
    "uniprot": "A0A3Q0KCK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCK1"
  },
  {
    "uniprot": "A0A5K4FFL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFL4"
  },
  {
    "uniprot": "A0A5K4F1N6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1N6"
  },
  {
    "uniprot": "A0A5K4FB77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB77"
  },
  {
    "uniprot": "A0A5K4F2L8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2L8"
  },
  {
    "uniprot": "Q27277",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q27277"
  },
  {
    "uniprot": "A0A5K4FA40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA40"
  },
  {
    "uniprot": "A0A5K4ESP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESP7"
  },
  {
    "uniprot": "A0A3Q0KS18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS18"
  },
  {
    "uniprot": "A0A3Q0KPZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPZ3"
  },
  {
    "uniprot": "A0A5K4EEE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEE6"
  },
  {
    "uniprot": "A0A5K4EKI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKI3"
  },
  {
    "uniprot": "C1M1S1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C1M1S1"
  },
  {
    "uniprot": "A0A5K4EDZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDZ8"
  },
  {
    "uniprot": "A0A3Q0KK61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK61"
  },
  {
    "uniprot": "G4VFS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFS7"
  },
  {
    "uniprot": "A0A5K4EZB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZB3"
  },
  {
    "uniprot": "T1VXV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/T1VXV7"
  },
  {
    "uniprot": "A0A3Q0KEG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEG6"
  },
  {
    "uniprot": "G4V9X5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9X5"
  },
  {
    "uniprot": "A0A5K4E9K5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9K5"
  },
  {
    "uniprot": "A0A5K4F6N2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6N2"
  },
  {
    "uniprot": "A0A3Q0KUB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUB7"
  },
  {
    "uniprot": "G4LYU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYU6"
  },
  {
    "uniprot": "A0A5K4F843",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F843"
  },
  {
    "uniprot": "A0A5K4EEQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEQ0"
  },
  {
    "uniprot": "A0A5K4F706",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F706"
  },
  {
    "uniprot": "A0A3Q0KNC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNC8"
  },
  {
    "uniprot": "A0A3Q0KFI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFI6"
  },
  {
    "uniprot": "A0A5K4FAG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAG8"
  },
  {
    "uniprot": "A0A3Q0KE90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE90"
  },
  {
    "uniprot": "A0A3Q0KN39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN39"
  },
  {
    "uniprot": "Q8MTB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q8MTB4"
  },
  {
    "uniprot": "A0A3Q0KIB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIB1"
  },
  {
    "uniprot": "A0A5K4F910",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F910"
  },
  {
    "uniprot": "A0A3Q0KII2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KII2"
  },
  {
    "uniprot": "A0A3Q0KDX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDX9"
  },
  {
    "uniprot": "G4LVL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVL5"
  },
  {
    "uniprot": "A0A5K4F886",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F886"
  },
  {
    "uniprot": "G4VLT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLT3"
  },
  {
    "uniprot": "G4VGP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGP6"
  },
  {
    "uniprot": "A0A5K4F4Q0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Q0"
  },
  {
    "uniprot": "A0A3Q0KN89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN89"
  },
  {
    "uniprot": "A0A3Q0KR25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR25"
  },
  {
    "uniprot": "A0A5K4F0K4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0K4"
  },
  {
    "uniprot": "A0A3Q0KQ78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ78"
  },
  {
    "uniprot": "A0A5K4EN73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN73"
  },
  {
    "uniprot": "A0A5K4F534",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F534"
  },
  {
    "uniprot": "A0A5K4F0R9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0R9"
  },
  {
    "uniprot": "A0A3Q0KKA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKA8"
  },
  {
    "uniprot": "A0A5K4F1R9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1R9"
  },
  {
    "uniprot": "A0A5K4FBS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBS7"
  },
  {
    "uniprot": "G4VQ91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ91"
  },
  {
    "uniprot": "G4LYR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYR8"
  },
  {
    "uniprot": "A0A5K4F633",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F633"
  },
  {
    "uniprot": "G4VDL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDL2"
  },
  {
    "uniprot": "A0A3Q0KNP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNP6"
  },
  {
    "uniprot": "G4V783",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V783"
  },
  {
    "uniprot": "A0A5K4ET21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET21"
  },
  {
    "uniprot": "A0A3Q0KD97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD97"
  },
  {
    "uniprot": "A0A3Q0KR24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR24"
  },
  {
    "uniprot": "A0A5K4FBF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBF6"
  },
  {
    "uniprot": "A0A3Q0KH82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH82"
  },
  {
    "uniprot": "A0A3Q0KGZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGZ4"
  },
  {
    "uniprot": "A0A3Q0KCK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCK2"
  },
  {
    "uniprot": "A0A5K4F835",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F835"
  },
  {
    "uniprot": "A0A3Q0KQI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQI9"
  },
  {
    "uniprot": "A0A3Q0KRZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRZ7"
  },
  {
    "uniprot": "G4VKP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKP4"
  },
  {
    "uniprot": "A0A0U5KKP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A0U5KKP6"
  },
  {
    "uniprot": "A0A5K4F1T9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1T9"
  },
  {
    "uniprot": "A0A5K4F384",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F384"
  },
  {
    "uniprot": "A0A3Q0KCE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCE9"
  },
  {
    "uniprot": "A0A5K4F6W3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6W3"
  },
  {
    "uniprot": "A0A5K4F9W0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9W0"
  },
  {
    "uniprot": "G4VNH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNH1"
  },
  {
    "uniprot": "A0A3Q0KGK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGK1"
  },
  {
    "uniprot": "A0A5K4FAW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAW5"
  },
  {
    "uniprot": "A0A5K4ER98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER98"
  },
  {
    "uniprot": "A0A5K4F9B7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9B7"
  },
  {
    "uniprot": "A0A5K4FA68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA68"
  },
  {
    "uniprot": "A0A3Q0KUT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUT1"
  },
  {
    "uniprot": "A0A5K4F887",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F887"
  },
  {
    "uniprot": "A0A5K4F0V1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0V1"
  },
  {
    "uniprot": "A0A3Q0KV06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV06"
  },
  {
    "uniprot": "A0A3Q0KQ01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ01"
  },
  {
    "uniprot": "A0A5K4EZZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZZ4"
  },
  {
    "uniprot": "A0A3Q0KQC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQC2"
  },
  {
    "uniprot": "A0A3Q0KJ74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ74"
  },
  {
    "uniprot": "A0A3Q0KPP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPP7"
  },
  {
    "uniprot": "A0A5K4EKW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKW2"
  },
  {
    "uniprot": "G4VQA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQA2"
  },
  {
    "uniprot": "A0A5K4EIT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIT0"
  },
  {
    "uniprot": "A0A5K4F5B5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5B5"
  },
  {
    "uniprot": "A0A5K4FAJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAJ3"
  },
  {
    "uniprot": "A0A3Q0KLC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLC3"
  },
  {
    "uniprot": "A0A5K4F7Q0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Q0"
  },
  {
    "uniprot": "A0A5K4F3Y5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Y5"
  },
  {
    "uniprot": "A0A5K4EV09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV09"
  },
  {
    "uniprot": "A0A5K4EPX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPX5"
  },
  {
    "uniprot": "A0A5K4ET03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET03"
  },
  {
    "uniprot": "A0A5K4F5L3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5L3"
  },
  {
    "uniprot": "A0A5K4EQ41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ41"
  },
  {
    "uniprot": "G4VA81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA81"
  },
  {
    "uniprot": "A0A5K4FB83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB83"
  },
  {
    "uniprot": "A0A5K4EHM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHM6"
  },
  {
    "uniprot": "A0A3Q0KU63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU63"
  },
  {
    "uniprot": "A0A5K4F8G7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8G7"
  },
  {
    "uniprot": "A0A5K4EJ59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJ59"
  },
  {
    "uniprot": "A0A5K4EZR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZR8"
  },
  {
    "uniprot": "A0A5K4EDX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDX8"
  },
  {
    "uniprot": "A0A3Q0KME6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KME6"
  },
  {
    "uniprot": "G4V616",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V616"
  },
  {
    "uniprot": "A0A5K4F0K1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0K1"
  },
  {
    "uniprot": "A0A5K4F5C7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5C7"
  },
  {
    "uniprot": "G4VFJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFJ9"
  },
  {
    "uniprot": "A0A3Q0KRI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRI2"
  },
  {
    "uniprot": "A0A5K4ER21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER21"
  },
  {
    "uniprot": "A0A5K4ERV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERV2"
  },
  {
    "uniprot": "G4V927",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V927"
  },
  {
    "uniprot": "A0A3Q0KHZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHZ2"
  },
  {
    "uniprot": "A0A5K4FAM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAM5"
  },
  {
    "uniprot": "G4LYN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYN1"
  },
  {
    "uniprot": "A0A5K4FA25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA25"
  },
  {
    "uniprot": "A0A3Q0KUA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUA4"
  },
  {
    "uniprot": "A0A5K4EW01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW01"
  },
  {
    "uniprot": "A0A5K4FCH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCH8"
  },
  {
    "uniprot": "A0A5K4FDF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDF6"
  },
  {
    "uniprot": "A0A5K4ESA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESA9"
  },
  {
    "uniprot": "A0A3Q0KHW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHW7"
  },
  {
    "uniprot": "A0A5K4F7S7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7S7"
  },
  {
    "uniprot": "G4VFP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFP2"
  },
  {
    "uniprot": "A0A5K4F201",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F201"
  },
  {
    "uniprot": "A0A5K4F8V1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8V1"
  },
  {
    "uniprot": "A0A3Q0KTS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTS8"
  },
  {
    "uniprot": "P06198",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P06198"
  },
  {
    "uniprot": "A0A5K4ESH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESH2"
  },
  {
    "uniprot": "A0A5K4F103",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F103"
  },
  {
    "uniprot": "G4VGY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGY7"
  },
  {
    "uniprot": "G4V661",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V661"
  },
  {
    "uniprot": "A0A5K4EQI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQI0"
  },
  {
    "uniprot": "A0A5K4F9E1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9E1"
  },
  {
    "uniprot": "A0A5K4FE34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE34"
  },
  {
    "uniprot": "A0A5K4F5G9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5G9"
  },
  {
    "uniprot": "A0A3Q0KGM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGM2"
  },
  {
    "uniprot": "G4VI74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI74"
  },
  {
    "uniprot": "A0A5K4FB05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB05"
  },
  {
    "uniprot": "A0A5K4EWW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWW8"
  },
  {
    "uniprot": "A0A5K4FCT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCT0"
  },
  {
    "uniprot": "A0A5K4EUB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUB4"
  },
  {
    "uniprot": "A0A5K4ET32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET32"
  },
  {
    "uniprot": "A0A5K4EVX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVX7"
  },
  {
    "uniprot": "A0A5K4EC78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EC78"
  },
  {
    "uniprot": "A0A5K4EQ02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ02"
  },
  {
    "uniprot": "A0A3Q0KS28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS28"
  },
  {
    "uniprot": "A0A5K4FGC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGC1"
  },
  {
    "uniprot": "G4LXT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXT7"
  },
  {
    "uniprot": "A0A5K4F940",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F940"
  },
  {
    "uniprot": "G4V6C8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6C8"
  },
  {
    "uniprot": "A0A5K4F3K5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3K5"
  },
  {
    "uniprot": "A0A5K4FG40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FG40"
  },
  {
    "uniprot": "A0A5K4ECM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECM2"
  },
  {
    "uniprot": "A0A5K4F7T9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7T9"
  },
  {
    "uniprot": "A0A5K4F538",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F538"
  },
  {
    "uniprot": "A0A3Q0KBF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBF9"
  },
  {
    "uniprot": "A0A5K4F7B1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7B1"
  },
  {
    "uniprot": "A0A5K4F388",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F388"
  },
  {
    "uniprot": "A0A5K4F6E1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6E1"
  },
  {
    "uniprot": "A0A5K4F5N9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5N9"
  },
  {
    "uniprot": "A0A5K4EHS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHS5"
  },
  {
    "uniprot": "A0A3Q0KTL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTL8"
  },
  {
    "uniprot": "G4LZT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZT3"
  },
  {
    "uniprot": "A0A5K4FEC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEC6"
  },
  {
    "uniprot": "A0A3Q0KSK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSK1"
  },
  {
    "uniprot": "A0A3Q0KR93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR93"
  },
  {
    "uniprot": "A0A3Q0KM32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM32"
  },
  {
    "uniprot": "A0A3Q0KTB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTB3"
  },
  {
    "uniprot": "A0A3Q0KL08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL08"
  },
  {
    "uniprot": "A0A5K4F704",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F704"
  },
  {
    "uniprot": "A0A5K4EAV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAV0"
  },
  {
    "uniprot": "A0A5K4EAQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAQ4"
  },
  {
    "uniprot": "A0A5K4EMT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMT8"
  },
  {
    "uniprot": "A0A5K4F7I1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7I1"
  },
  {
    "uniprot": "Q8ITD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q8ITD8"
  },
  {
    "uniprot": "A0A5K4FBC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBC0"
  },
  {
    "uniprot": "A0A5K4FCX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCX7"
  },
  {
    "uniprot": "A0A5K4EIQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIQ6"
  },
  {
    "uniprot": "A0A3Q0KUY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUY3"
  },
  {
    "uniprot": "A0A5K4F748",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F748"
  },
  {
    "uniprot": "A0A5K4EL42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL42"
  },
  {
    "uniprot": "A0A5K4F9L7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9L7"
  },
  {
    "uniprot": "A0A5K4F281",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F281"
  },
  {
    "uniprot": "A0A5K4FBU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBU5"
  },
  {
    "uniprot": "G4VGQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGQ0"
  },
  {
    "uniprot": "A0A3Q0KL81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL81"
  },
  {
    "uniprot": "A0A5K4F408",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F408"
  },
  {
    "uniprot": "A0A3Q0KPJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPJ1"
  },
  {
    "uniprot": "G4LVJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVJ1"
  },
  {
    "uniprot": "A0A5K4EQR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQR8"
  },
  {
    "uniprot": "A0A5K4FEJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEJ4"
  },
  {
    "uniprot": "A0A5K4F5R6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5R6"
  },
  {
    "uniprot": "A0A3Q0KNG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNG7"
  },
  {
    "uniprot": "A0A3Q0KKP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKP6"
  },
  {
    "uniprot": "A0A5K4F2B0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2B0"
  },
  {
    "uniprot": "A0A3Q0KQ23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ23"
  },
  {
    "uniprot": "A0A5K4ES66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES66"
  },
  {
    "uniprot": "A0A3Q0KCP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCP5"
  },
  {
    "uniprot": "A0A3Q0KPC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPC2"
  },
  {
    "uniprot": "A0A5K4FBC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBC1"
  },
  {
    "uniprot": "A0A3Q0KIU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIU6"
  },
  {
    "uniprot": "A0A5K4E825",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E825"
  },
  {
    "uniprot": "Q26537",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26537"
  },
  {
    "uniprot": "A0A3Q0KQ56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ56"
  },
  {
    "uniprot": "G4VF83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF83"
  },
  {
    "uniprot": "A0A5K4F0B6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0B6"
  },
  {
    "uniprot": "A0A5K4FC47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC47"
  },
  {
    "uniprot": "A0A5K4F5G0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5G0"
  },
  {
    "uniprot": "Q5UES2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q5UES2"
  },
  {
    "uniprot": "G4VG84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VG84"
  },
  {
    "uniprot": "A0A3Q0KL22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL22"
  },
  {
    "uniprot": "A0A5K4F4U2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4U2"
  },
  {
    "uniprot": "A0A3Q0KQ10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ10"
  },
  {
    "uniprot": "A0A3Q0KI75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI75"
  },
  {
    "uniprot": "A0A3Q0KC93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC93"
  },
  {
    "uniprot": "A0A3Q0KPE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPE2"
  },
  {
    "uniprot": "A0A5K4EZH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZH8"
  },
  {
    "uniprot": "A0A3Q0KQG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQG5"
  },
  {
    "uniprot": "A0A5K4F361",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F361"
  },
  {
    "uniprot": "A0A5K4F1N7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1N7"
  },
  {
    "uniprot": "A0A5K4EMR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMR3"
  },
  {
    "uniprot": "A0A5K4F7U4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7U4"
  },
  {
    "uniprot": "A0A3Q0KKB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKB3"
  },
  {
    "uniprot": "A0A3Q0KGE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGE9"
  },
  {
    "uniprot": "A0A3Q0KS13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS13"
  },
  {
    "uniprot": "G4VHS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHS5"
  },
  {
    "uniprot": "G4LZB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZB6"
  },
  {
    "uniprot": "A0A3Q0KV83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV83"
  },
  {
    "uniprot": "A0A5K4F0Y3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Y3"
  },
  {
    "uniprot": "A0A5K4F8R8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8R8"
  },
  {
    "uniprot": "A0A5K4F293",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F293"
  },
  {
    "uniprot": "A0A3Q0KVD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVD5"
  },
  {
    "uniprot": "A0A5K4F2T2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2T2"
  },
  {
    "uniprot": "A0A5K4EIV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIV9"
  },
  {
    "uniprot": "A0A5K4F5I0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5I0"
  },
  {
    "uniprot": "A0A5K4F498",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F498"
  },
  {
    "uniprot": "C4QKW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QKW9"
  },
  {
    "uniprot": "A0A5K4F172",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F172"
  },
  {
    "uniprot": "A0A3Q0KIF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIF8"
  },
  {
    "uniprot": "A0A3Q0KN63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN63"
  },
  {
    "uniprot": "A0A3Q0KD32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD32"
  },
  {
    "uniprot": "A0A5K4F9X6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9X6"
  },
  {
    "uniprot": "A0A5K4ETU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETU1"
  },
  {
    "uniprot": "A0A5K4F820",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F820"
  },
  {
    "uniprot": "A0A5K4F806",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F806"
  },
  {
    "uniprot": "A0A5K4EVE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVE9"
  },
  {
    "uniprot": "A0A5K4F210",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F210"
  },
  {
    "uniprot": "A0A5K4F3W4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3W4"
  },
  {
    "uniprot": "A0A3Q0KD27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD27"
  },
  {
    "uniprot": "A0A5K4F7D8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7D8"
  },
  {
    "uniprot": "G4V6F1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6F1"
  },
  {
    "uniprot": "A0A3Q0KUC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUC9"
  },
  {
    "uniprot": "A0A5K4EQT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQT4"
  },
  {
    "uniprot": "A0A3Q0KP66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP66"
  },
  {
    "uniprot": "A0A3Q0KIF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIF1"
  },
  {
    "uniprot": "A0A5K4ESC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESC0"
  },
  {
    "uniprot": "A0A5K4EQR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQR9"
  },
  {
    "uniprot": "G4VQ30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ30"
  },
  {
    "uniprot": "A0A5K4F6C0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6C0"
  },
  {
    "uniprot": "G4V6U2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6U2"
  },
  {
    "uniprot": "A0A5K4E9V9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9V9"
  },
  {
    "uniprot": "A0A5K4FFN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFN8"
  },
  {
    "uniprot": "A0A5K4EXA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXA1"
  },
  {
    "uniprot": "A0A3Q0KPB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPB4"
  },
  {
    "uniprot": "G4VS90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS90"
  },
  {
    "uniprot": "A0A3Q0KEA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEA0"
  },
  {
    "uniprot": "A0A5K4FCZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCZ5"
  },
  {
    "uniprot": "G4VQZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQZ3"
  },
  {
    "uniprot": "A0A5K4F1A2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1A2"
  },
  {
    "uniprot": "G4VKD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKD9"
  },
  {
    "uniprot": "A0A5K4F695",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F695"
  },
  {
    "uniprot": "A0A5K4FAP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAP8"
  },
  {
    "uniprot": "G4V660",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V660"
  },
  {
    "uniprot": "A0A5K4F8S8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8S8"
  },
  {
    "uniprot": "G4VTT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTT4"
  },
  {
    "uniprot": "A0A3Q0KN28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN28"
  },
  {
    "uniprot": "G4VBS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBS2"
  },
  {
    "uniprot": "G4V7Y4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7Y4"
  },
  {
    "uniprot": "G4LXB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXB4"
  },
  {
    "uniprot": "A0A5K4FF96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF96"
  },
  {
    "uniprot": "A0A5K4FCL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCL7"
  },
  {
    "uniprot": "A0A5K4FDQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDQ3"
  },
  {
    "uniprot": "A0A5K4FAS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAS5"
  },
  {
    "uniprot": "G4M0B9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0B9"
  },
  {
    "uniprot": "A0A5K4FC50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC50"
  },
  {
    "uniprot": "A0A5K4FDF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDF2"
  },
  {
    "uniprot": "A0A5K4EI95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EI95"
  },
  {
    "uniprot": "A0A5K4FFE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFE6"
  },
  {
    "uniprot": "A0A3Q0KCR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCR1"
  },
  {
    "uniprot": "G4VNM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNM5"
  },
  {
    "uniprot": "A0A5K4E9V3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9V3"
  },
  {
    "uniprot": "A0A5K4FCH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCH0"
  },
  {
    "uniprot": "A0A5K4F0U3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0U3"
  },
  {
    "uniprot": "A0A3Q0KLE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLE1"
  },
  {
    "uniprot": "A0A3Q0KEH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEH3"
  },
  {
    "uniprot": "A0A3Q0KFW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFW3"
  },
  {
    "uniprot": "A0A3Q0KMS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMS4"
  },
  {
    "uniprot": "A0A3Q0KPT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPT1"
  },
  {
    "uniprot": "G4V7I4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7I4"
  },
  {
    "uniprot": "A0A5K4E9U7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9U7"
  },
  {
    "uniprot": "A0A5K4EWJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWJ7"
  },
  {
    "uniprot": "A0A3Q0KPM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPM3"
  },
  {
    "uniprot": "G4VJF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJF7"
  },
  {
    "uniprot": "A0A5K4FD40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD40"
  },
  {
    "uniprot": "A0A5K4F2H2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2H2"
  },
  {
    "uniprot": "A0A5K4ERG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERG2"
  },
  {
    "uniprot": "A0A3Q0KGL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGL2"
  },
  {
    "uniprot": "A0A5K4FAG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAG9"
  },
  {
    "uniprot": "A0A3Q0KT13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT13"
  },
  {
    "uniprot": "A0A5K4F7W3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7W3"
  },
  {
    "uniprot": "A0A3Q0KN03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN03"
  },
  {
    "uniprot": "A0A3Q0KBD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBD5"
  },
  {
    "uniprot": "A0A3Q0KV39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV39"
  },
  {
    "uniprot": "O17453",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/O17453"
  },
  {
    "uniprot": "A0A3Q0KGV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGV0"
  },
  {
    "uniprot": "A0A5K4F3Q5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Q5"
  },
  {
    "uniprot": "A0A5K4EK41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK41"
  },
  {
    "uniprot": "A0A5K4F474",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F474"
  },
  {
    "uniprot": "G4V624",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V624"
  },
  {
    "uniprot": "A0A5K4F1S3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1S3"
  },
  {
    "uniprot": "A0A3Q0KSQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSQ7"
  },
  {
    "uniprot": "A0A5K4FFJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFJ0"
  },
  {
    "uniprot": "G4LX25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX25"
  },
  {
    "uniprot": "A0A3Q0KDJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDJ0"
  },
  {
    "uniprot": "A0A5K4F521",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F521"
  },
  {
    "uniprot": "A0A5K4EDT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDT8"
  },
  {
    "uniprot": "A0A5K4F0Z2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Z2"
  },
  {
    "uniprot": "G4VPE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPE1"
  },
  {
    "uniprot": "A0A5K4F6D3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6D3"
  },
  {
    "uniprot": "G4V6N0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6N0"
  },
  {
    "uniprot": "A0A3Q0KC69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC69"
  },
  {
    "uniprot": "A0A3Q0KFM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFM7"
  },
  {
    "uniprot": "A0A3Q0KKI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKI6"
  },
  {
    "uniprot": "A0A3Q0KP73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP73"
  },
  {
    "uniprot": "A0A5K4FCR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCR6"
  },
  {
    "uniprot": "A0A5K4ENB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENB2"
  },
  {
    "uniprot": "A0A5K4F2U1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2U1"
  },
  {
    "uniprot": "A0A5K4F4T9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4T9"
  },
  {
    "uniprot": "A0A3Q0KKW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKW9"
  },
  {
    "uniprot": "A0A5K4FDC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDC6"
  },
  {
    "uniprot": "A0A5K4EFH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFH9"
  },
  {
    "uniprot": "G4VT07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT07"
  },
  {
    "uniprot": "A0A3Q0KJ72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ72"
  },
  {
    "uniprot": "A0A3Q0KT50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT50"
  },
  {
    "uniprot": "G4VS33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS33"
  },
  {
    "uniprot": "A0A5K4EW42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW42"
  },
  {
    "uniprot": "A0A3Q0KDB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDB8"
  },
  {
    "uniprot": "A0A5K4EW16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW16"
  },
  {
    "uniprot": "A0A5K4F6R9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6R9"
  },
  {
    "uniprot": "A0A3Q0KR53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR53"
  },
  {
    "uniprot": "A0A3Q0KLJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLJ2"
  },
  {
    "uniprot": "A0A3Q0KG95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG95"
  },
  {
    "uniprot": "A0A3Q0KNM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNM0"
  },
  {
    "uniprot": "A0A3Q0KNN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNN6"
  },
  {
    "uniprot": "A0A5K4ETH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETH4"
  },
  {
    "uniprot": "A0A3Q0KNK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNK8"
  },
  {
    "uniprot": "A0A3Q0KLI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLI0"
  },
  {
    "uniprot": "A0A5K4EMF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMF2"
  },
  {
    "uniprot": "A0A5K4FED0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FED0"
  },
  {
    "uniprot": "G4VR43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR43"
  },
  {
    "uniprot": "G4LUX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUX8"
  },
  {
    "uniprot": "A0A5K4F0W2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0W2"
  },
  {
    "uniprot": "A0A5K4F4Y8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Y8"
  },
  {
    "uniprot": "G4LXC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXC4"
  },
  {
    "uniprot": "A0A3Q0KTH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTH2"
  },
  {
    "uniprot": "G4V6T6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6T6"
  },
  {
    "uniprot": "G4VE90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VE90"
  },
  {
    "uniprot": "A0A5K4EAL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAL9"
  },
  {
    "uniprot": "A0A5K4ELH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELH3"
  },
  {
    "uniprot": "A0A5K4FH83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FH83"
  },
  {
    "uniprot": "A0A5K4F446",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F446"
  },
  {
    "uniprot": "A0A5K4EN81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN81"
  },
  {
    "uniprot": "A0A3Q0KFF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFF1"
  },
  {
    "uniprot": "G4V793",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V793"
  },
  {
    "uniprot": "G4VTE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTE6"
  },
  {
    "uniprot": "A0A3Q0KS89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS89"
  },
  {
    "uniprot": "A0A5K4EFJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFJ8"
  },
  {
    "uniprot": "G4VDQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDQ2"
  },
  {
    "uniprot": "A0A3Q0KM67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM67"
  },
  {
    "uniprot": "A0A5K4F4G0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4G0"
  },
  {
    "uniprot": "A0A5K4ENX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENX9"
  },
  {
    "uniprot": "A0A5K4F4W8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4W8"
  },
  {
    "uniprot": "A0A5K4FAI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAI8"
  },
  {
    "uniprot": "A0A5K4EU44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU44"
  },
  {
    "uniprot": "A0A5K4ETS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETS1"
  },
  {
    "uniprot": "A0A3Q0KPE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPE1"
  },
  {
    "uniprot": "A0A5K4FAS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAS2"
  },
  {
    "uniprot": "G4V8L7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8L7"
  },
  {
    "uniprot": "A0A3Q0KU51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU51"
  },
  {
    "uniprot": "A0A3Q0KTM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTM0"
  },
  {
    "uniprot": "A0A3Q0KQX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQX1"
  },
  {
    "uniprot": "G4V701",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V701"
  },
  {
    "uniprot": "A0A3Q0KUL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUL0"
  },
  {
    "uniprot": "G4V5K9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5K9"
  },
  {
    "uniprot": "G4V7H0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7H0"
  },
  {
    "uniprot": "A0A3Q0KKX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKX1"
  },
  {
    "uniprot": "Q6WVP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q6WVP6"
  },
  {
    "uniprot": "A0A5K4F993",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F993"
  },
  {
    "uniprot": "A0A5K4FAT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAT4"
  },
  {
    "uniprot": "A0A3Q0KRD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRD8"
  },
  {
    "uniprot": "A0A5K4F2M2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2M2"
  },
  {
    "uniprot": "G4VGE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGE8"
  },
  {
    "uniprot": "A0A3Q0KBN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBN6"
  },
  {
    "uniprot": "A0A5K4ELX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELX6"
  },
  {
    "uniprot": "G4LY05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY05"
  },
  {
    "uniprot": "G4VLY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLY3"
  },
  {
    "uniprot": "A0A3Q0KTP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTP5"
  },
  {
    "uniprot": "G4VF37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF37"
  },
  {
    "uniprot": "A0A5K4F3A5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3A5"
  },
  {
    "uniprot": "A0A5K4FFP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFP5"
  },
  {
    "uniprot": "A0A5K4F607",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F607"
  },
  {
    "uniprot": "G4VC25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC25"
  },
  {
    "uniprot": "G4VSR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSR2"
  },
  {
    "uniprot": "A0A5K4ETE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETE9"
  },
  {
    "uniprot": "A0A5K4FAU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAU1"
  },
  {
    "uniprot": "A0A5K4F2Z4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Z4"
  },
  {
    "uniprot": "A0A5K4FAE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAE0"
  },
  {
    "uniprot": "A0A5K4E9S1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9S1"
  },
  {
    "uniprot": "A0A3Q0KJK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJK9"
  },
  {
    "uniprot": "A0A5K4F0S5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0S5"
  },
  {
    "uniprot": "A0A3Q0KF85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF85"
  },
  {
    "uniprot": "A0A5K4F7M7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7M7"
  },
  {
    "uniprot": "A0A5K4EMY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMY6"
  },
  {
    "uniprot": "A0A3Q0KJC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJC1"
  },
  {
    "uniprot": "A0A3Q0KRV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRV9"
  },
  {
    "uniprot": "A0A5K4EDJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDJ0"
  },
  {
    "uniprot": "A0A5K4FCD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCD6"
  },
  {
    "uniprot": "A0A5K4F7L6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7L6"
  },
  {
    "uniprot": "G4LVK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVK3"
  },
  {
    "uniprot": "A0A3Q0KPL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPL5"
  },
  {
    "uniprot": "A0A5K4F175",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F175"
  },
  {
    "uniprot": "A0A5K4E8E6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8E6"
  },
  {
    "uniprot": "A0A5K4F483",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F483"
  },
  {
    "uniprot": "A0A5K4F442",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F442"
  },
  {
    "uniprot": "A0A5K4FBD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBD7"
  },
  {
    "uniprot": "A0A5K4EWX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWX4"
  },
  {
    "uniprot": "A0A5K4EJ89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJ89"
  },
  {
    "uniprot": "A0A5K4F5Z0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Z0"
  },
  {
    "uniprot": "G4VJK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJK6"
  },
  {
    "uniprot": "A0A5K4FAL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAL1"
  },
  {
    "uniprot": "G4V5N3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5N3"
  },
  {
    "uniprot": "G4V721",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V721"
  },
  {
    "uniprot": "G4V6B2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6B2"
  },
  {
    "uniprot": "A0A5K4F3K4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3K4"
  },
  {
    "uniprot": "A0A5K4F6Y0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Y0"
  },
  {
    "uniprot": "A0A3Q0KL05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL05"
  },
  {
    "uniprot": "A0A5K4EQW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQW0"
  },
  {
    "uniprot": "C1LZY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C1LZY9"
  },
  {
    "uniprot": "A0A5K4F2E5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2E5"
  },
  {
    "uniprot": "A0A5K4EL30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL30"
  },
  {
    "uniprot": "A0A5K4F0T9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0T9"
  },
  {
    "uniprot": "A0A3Q0KU19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU19"
  },
  {
    "uniprot": "A0A5K4E906",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E906"
  },
  {
    "uniprot": "A0A5K4F1F9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1F9"
  },
  {
    "uniprot": "A0A5K4F3D1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3D1"
  },
  {
    "uniprot": "A0A3Q0KTA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTA7"
  },
  {
    "uniprot": "A0A5K4EQJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQJ2"
  },
  {
    "uniprot": "A0A5K4FA70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA70"
  },
  {
    "uniprot": "P16237",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P16237"
  },
  {
    "uniprot": "A0A3Q0KIP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIP8"
  },
  {
    "uniprot": "G4LVU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVU9"
  },
  {
    "uniprot": "A0A5K4F3G0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3G0"
  },
  {
    "uniprot": "C4QE42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QE42"
  },
  {
    "uniprot": "A0A3Q0KR63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR63"
  },
  {
    "uniprot": "G4V696",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V696"
  },
  {
    "uniprot": "A0A5K4EA28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EA28"
  },
  {
    "uniprot": "A0A5K4EEK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEK1"
  },
  {
    "uniprot": "A0A3Q0KE50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE50"
  },
  {
    "uniprot": "A0A3Q0KBG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBG1"
  },
  {
    "uniprot": "G4V7J8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7J8"
  },
  {
    "uniprot": "A0A5K4FC97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC97"
  },
  {
    "uniprot": "A0A5K4F8Q6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Q6"
  },
  {
    "uniprot": "A0A5K4EXF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXF5"
  },
  {
    "uniprot": "A0A3Q0KV75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV75"
  },
  {
    "uniprot": "A0A5K4EPE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPE2"
  },
  {
    "uniprot": "A0A5K4F4H3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4H3"
  },
  {
    "uniprot": "G4VPU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPU8"
  },
  {
    "uniprot": "A0A5K4F9W8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9W8"
  },
  {
    "uniprot": "A0A3Q0KF11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF11"
  },
  {
    "uniprot": "A0A3Q0KTD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTD6"
  },
  {
    "uniprot": "A0A5K4ELW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELW2"
  },
  {
    "uniprot": "G4LVV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVV7"
  },
  {
    "uniprot": "A0A5K4F4P2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4P2"
  },
  {
    "uniprot": "A0A5K4FDE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDE0"
  },
  {
    "uniprot": "A0A5K4F5H7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5H7"
  },
  {
    "uniprot": "A0A5K4EVF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVF7"
  },
  {
    "uniprot": "A0A5K4F540",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F540"
  },
  {
    "uniprot": "A0A5K4F1L3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1L3"
  },
  {
    "uniprot": "A0A5K4F2E4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2E4"
  },
  {
    "uniprot": "A0A5K4FGS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGS0"
  },
  {
    "uniprot": "A0A3Q0KL76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL76"
  },
  {
    "uniprot": "G4VHU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHU6"
  },
  {
    "uniprot": "G4LXB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXB9"
  },
  {
    "uniprot": "A0A5K4F471",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F471"
  },
  {
    "uniprot": "G4VC85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC85"
  },
  {
    "uniprot": "A0A5K4EYA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYA1"
  },
  {
    "uniprot": "A0A3Q0KKW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKW0"
  },
  {
    "uniprot": "G4VEU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEU7"
  },
  {
    "uniprot": "A0A5K4F783",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F783"
  },
  {
    "uniprot": "A0A5K4F902",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F902"
  },
  {
    "uniprot": "A0A5K4F046",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F046"
  },
  {
    "uniprot": "A0A3Q0KD55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD55"
  },
  {
    "uniprot": "G4LZK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZK0"
  },
  {
    "uniprot": "A0A5K4EIN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIN7"
  },
  {
    "uniprot": "G4VAZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAZ9"
  },
  {
    "uniprot": "A0A3Q0KDH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDH2"
  },
  {
    "uniprot": "A0A5K4F4Y9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Y9"
  },
  {
    "uniprot": "A0A3Q0KNC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNC2"
  },
  {
    "uniprot": "A0A3Q0KNZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNZ0"
  },
  {
    "uniprot": "A0A3Q0KB90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KB90"
  },
  {
    "uniprot": "G4VTT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTT7"
  },
  {
    "uniprot": "A0A5K4FD13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD13"
  },
  {
    "uniprot": "A0A3Q0KPY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPY9"
  },
  {
    "uniprot": "G4VMT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMT6"
  },
  {
    "uniprot": "A0A3Q0KJN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJN2"
  },
  {
    "uniprot": "A0A5K4FBT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBT8"
  },
  {
    "uniprot": "G4VJT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJT2"
  },
  {
    "uniprot": "A0A3Q0KJ00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ00"
  },
  {
    "uniprot": "A0A5K4EB89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB89"
  },
  {
    "uniprot": "A0A5K4FDS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDS2"
  },
  {
    "uniprot": "A0A3Q0KP91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP91"
  },
  {
    "uniprot": "A0A5K4EST9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EST9"
  },
  {
    "uniprot": "A0A3Q0KKX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKX2"
  },
  {
    "uniprot": "G4V9U9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9U9"
  },
  {
    "uniprot": "A0A3Q0KMZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMZ9"
  },
  {
    "uniprot": "A0A5K4F4S9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4S9"
  },
  {
    "uniprot": "A0A0R5RJC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A0R5RJC3"
  },
  {
    "uniprot": "A0A3Q0KE83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE83"
  },
  {
    "uniprot": "A0A5K4F636",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F636"
  },
  {
    "uniprot": "A0A5K4F4E4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4E4"
  },
  {
    "uniprot": "G4V8Z0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8Z0"
  },
  {
    "uniprot": "A0A5K4ERM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERM4"
  },
  {
    "uniprot": "A0A3Q0KJL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJL0"
  },
  {
    "uniprot": "A0A5K4ENT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENT7"
  },
  {
    "uniprot": "G4VNT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNT2"
  },
  {
    "uniprot": "A0A5K4ECH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECH4"
  },
  {
    "uniprot": "G4VG37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VG37"
  },
  {
    "uniprot": "G4VSN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSN5"
  },
  {
    "uniprot": "A0A5K4F826",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F826"
  },
  {
    "uniprot": "A0A5K4EL07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL07"
  },
  {
    "uniprot": "A0A3Q0KIQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIQ0"
  },
  {
    "uniprot": "A0A3Q0KRS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRS8"
  },
  {
    "uniprot": "A0A3Q0KMV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMV7"
  },
  {
    "uniprot": "A0A5K4FB82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB82"
  },
  {
    "uniprot": "A0A5K4EJ41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJ41"
  },
  {
    "uniprot": "A0A3Q0KRY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRY6"
  },
  {
    "uniprot": "A0A3Q0KM38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM38"
  },
  {
    "uniprot": "A0A3Q0KJB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJB8"
  },
  {
    "uniprot": "G4VHL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHL6"
  },
  {
    "uniprot": "A0A5K4EXC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXC0"
  },
  {
    "uniprot": "A0A5K4FDL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDL6"
  },
  {
    "uniprot": "A0A5K4F5H5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5H5"
  },
  {
    "uniprot": "A0A5K4FB19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB19"
  },
  {
    "uniprot": "G4VCA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCA6"
  },
  {
    "uniprot": "A0A3Q0KED5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KED5"
  },
  {
    "uniprot": "A0A5K4FAZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAZ4"
  },
  {
    "uniprot": "G4LVR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVR8"
  },
  {
    "uniprot": "A0A5K4FA38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA38"
  },
  {
    "uniprot": "A0A5K4EP86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP86"
  },
  {
    "uniprot": "A0A3Q0KRK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRK7"
  },
  {
    "uniprot": "A0A3Q0KR59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR59"
  },
  {
    "uniprot": "G4LUE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUE4"
  },
  {
    "uniprot": "G4VRL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRL5"
  },
  {
    "uniprot": "G4VKY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKY9"
  },
  {
    "uniprot": "A0A5K4FC09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC09"
  },
  {
    "uniprot": "A0A5K4FB11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB11"
  },
  {
    "uniprot": "A0A5K4EEQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEQ9"
  },
  {
    "uniprot": "A0A5K4F8U3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8U3"
  },
  {
    "uniprot": "A0A3Q0KUE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUE1"
  },
  {
    "uniprot": "G4VID2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VID2"
  },
  {
    "uniprot": "G4VK94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK94"
  },
  {
    "uniprot": "A0A3Q0KUB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUB8"
  },
  {
    "uniprot": "A0A3Q0KJZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJZ0"
  },
  {
    "uniprot": "G4VLG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLG3"
  },
  {
    "uniprot": "A0A5K4FB68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB68"
  },
  {
    "uniprot": "G4VMK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMK5"
  },
  {
    "uniprot": "A0A5K4EB47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB47"
  },
  {
    "uniprot": "A0A5K4F6W1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6W1"
  },
  {
    "uniprot": "G4VH15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH15"
  },
  {
    "uniprot": "A0A3Q0KL78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL78"
  },
  {
    "uniprot": "A0A5K4F2I4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2I4"
  },
  {
    "uniprot": "A0A5K4EWT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWT0"
  },
  {
    "uniprot": "A0A5K4FAQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAQ7"
  },
  {
    "uniprot": "A0A5K4F3H8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3H8"
  },
  {
    "uniprot": "A0A3Q0KC02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC02"
  },
  {
    "uniprot": "A0A3Q0KCV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCV9"
  },
  {
    "uniprot": "A0A3Q0KST7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KST7"
  },
  {
    "uniprot": "A0A3Q0KMD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMD0"
  },
  {
    "uniprot": "A0A5K4FAX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAX0"
  },
  {
    "uniprot": "A0A5K4F7N8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7N8"
  },
  {
    "uniprot": "A0A3Q0KRH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRH2"
  },
  {
    "uniprot": "G4VC87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC87"
  },
  {
    "uniprot": "A0A3Q0KHL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHL0"
  },
  {
    "uniprot": "A0A5K4FGL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGL3"
  },
  {
    "uniprot": "A0A3Q0KT46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT46"
  },
  {
    "uniprot": "A0A3Q0KU31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU31"
  },
  {
    "uniprot": "A0A5K4FEZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEZ5"
  },
  {
    "uniprot": "G4VK91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK91"
  },
  {
    "uniprot": "A0A5K4F5Z2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Z2"
  },
  {
    "uniprot": "A0A5K4F6P7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6P7"
  },
  {
    "uniprot": "A0A5K4F7W6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7W6"
  },
  {
    "uniprot": "A0A5K4F3P0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3P0"
  },
  {
    "uniprot": "A0A5K4F5R2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5R2"
  },
  {
    "uniprot": "A0A5K4F6K8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6K8"
  },
  {
    "uniprot": "A0A5K4F4G4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4G4"
  },
  {
    "uniprot": "A0A3Q0KI77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI77"
  },
  {
    "uniprot": "A0A5K4F682",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F682"
  },
  {
    "uniprot": "A0A5K4FBE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBE8"
  },
  {
    "uniprot": "A0A5K4ECB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECB7"
  },
  {
    "uniprot": "A0A3Q0KRR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRR1"
  },
  {
    "uniprot": "A0A5K4F2E6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2E6"
  },
  {
    "uniprot": "A0A3Q0KV46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV46"
  },
  {
    "uniprot": "A0A3Q0KSI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSI6"
  },
  {
    "uniprot": "A0A5K4F6K0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6K0"
  },
  {
    "uniprot": "G4VC88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC88"
  },
  {
    "uniprot": "A0A5K4ETW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETW5"
  },
  {
    "uniprot": "G4LV53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV53"
  },
  {
    "uniprot": "A0A5K4EVS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVS8"
  },
  {
    "uniprot": "A0A5K4F263",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F263"
  },
  {
    "uniprot": "A0A3Q0KCC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCC6"
  },
  {
    "uniprot": "A0A3Q0KCU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCU5"
  },
  {
    "uniprot": "A0A3Q0KJD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJD0"
  },
  {
    "uniprot": "A0A3Q0KS85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS85"
  },
  {
    "uniprot": "A0A3Q0KUI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUI2"
  },
  {
    "uniprot": "A0A5K4F0B1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0B1"
  },
  {
    "uniprot": "A0A5K4EYZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYZ8"
  },
  {
    "uniprot": "G4LYQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYQ6"
  },
  {
    "uniprot": "A0A5K4ESG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESG3"
  },
  {
    "uniprot": "A0A3Q0KG14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG14"
  },
  {
    "uniprot": "A0A5K4FDH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDH5"
  },
  {
    "uniprot": "G4VKA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKA6"
  },
  {
    "uniprot": "A0A5K4F217",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F217"
  },
  {
    "uniprot": "A0A3Q0KUM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUM2"
  },
  {
    "uniprot": "G4VHQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHQ1"
  },
  {
    "uniprot": "G4VHT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHT3"
  },
  {
    "uniprot": "A0A5K4F169",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F169"
  },
  {
    "uniprot": "G4VH74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH74"
  },
  {
    "uniprot": "A0A5K4F4R9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4R9"
  },
  {
    "uniprot": "A0A5K4F9U8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9U8"
  },
  {
    "uniprot": "A0A3Q0KEM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEM2"
  },
  {
    "uniprot": "A0A5K4F111",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F111"
  },
  {
    "uniprot": "A0A3Q0KEE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEE8"
  },
  {
    "uniprot": "A0A3Q0KIM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIM3"
  },
  {
    "uniprot": "A0A5K4FEH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEH1"
  },
  {
    "uniprot": "A0A5K4F7N0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7N0"
  },
  {
    "uniprot": "A0A5K4F7Y2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Y2"
  },
  {
    "uniprot": "A0A3Q0KMY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMY1"
  },
  {
    "uniprot": "A0A3Q0KFR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFR3"
  },
  {
    "uniprot": "A0A3Q0KFW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFW7"
  },
  {
    "uniprot": "A0A5K4FCA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCA4"
  },
  {
    "uniprot": "A0A5K4EBG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBG0"
  },
  {
    "uniprot": "A0A5K4F722",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F722"
  },
  {
    "uniprot": "A0A5K4FA71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA71"
  },
  {
    "uniprot": "A0A5K4F715",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F715"
  },
  {
    "uniprot": "A0A5K4F7F9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7F9"
  },
  {
    "uniprot": "A0A5K4FED7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FED7"
  },
  {
    "uniprot": "A0A3Q0KFB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFB2"
  },
  {
    "uniprot": "A0A5K4EUL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUL5"
  },
  {
    "uniprot": "G4VQX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQX4"
  },
  {
    "uniprot": "A0A5K4F1C8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1C8"
  },
  {
    "uniprot": "G4V9R9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9R9"
  },
  {
    "uniprot": "A0A3Q0KF20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF20"
  },
  {
    "uniprot": "A0A5K4FDL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDL7"
  },
  {
    "uniprot": "G4VRD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRD0"
  },
  {
    "uniprot": "G4V9K3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9K3"
  },
  {
    "uniprot": "G4V9S3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9S3"
  },
  {
    "uniprot": "A0A3Q0KRX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRX2"
  },
  {
    "uniprot": "A0A3Q0KT10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT10"
  },
  {
    "uniprot": "G4VNK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNK6"
  },
  {
    "uniprot": "A0A5K4FEG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEG5"
  },
  {
    "uniprot": "A0A5K4EUA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUA8"
  },
  {
    "uniprot": "A0A5K4F989",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F989"
  },
  {
    "uniprot": "A0A3Q0KH25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH25"
  },
  {
    "uniprot": "A0A3Q0KPL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPL7"
  },
  {
    "uniprot": "Q26603",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26603"
  },
  {
    "uniprot": "G4VKR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKR5"
  },
  {
    "uniprot": "A0A5K4ESF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESF8"
  },
  {
    "uniprot": "A0A5K4FFH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFH7"
  },
  {
    "uniprot": "A0A5K4EPJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPJ4"
  },
  {
    "uniprot": "A0A5K4F447",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F447"
  },
  {
    "uniprot": "G4VR33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR33"
  },
  {
    "uniprot": "A0A3Q0KJZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJZ4"
  },
  {
    "uniprot": "A0A5K4F3Q7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Q7"
  },
  {
    "uniprot": "A0A3Q0KQ97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ97"
  },
  {
    "uniprot": "A0A5K4FEP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEP0"
  },
  {
    "uniprot": "A0A3Q0KM09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM09"
  },
  {
    "uniprot": "A0A5K4F635",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F635"
  },
  {
    "uniprot": "A0A5K4EKB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKB7"
  },
  {
    "uniprot": "G4VQM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQM8"
  },
  {
    "uniprot": "G4LUW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUW4"
  },
  {
    "uniprot": "A0A3Q0KHT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHT7"
  },
  {
    "uniprot": "A0A5K4F3U9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3U9"
  },
  {
    "uniprot": "A0A5K4F4E6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4E6"
  },
  {
    "uniprot": "A0A5K4F7I0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7I0"
  },
  {
    "uniprot": "A0A5K4F4J5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4J5"
  },
  {
    "uniprot": "A0A3Q0KLJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLJ0"
  },
  {
    "uniprot": "A0A5K4FBY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBY6"
  },
  {
    "uniprot": "A0A5K4ELY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELY3"
  },
  {
    "uniprot": "A0A5K4F2H6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2H6"
  },
  {
    "uniprot": "A0A5K4EU13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU13"
  },
  {
    "uniprot": "G4VLQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLQ6"
  },
  {
    "uniprot": "A0A3Q0KM72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM72"
  },
  {
    "uniprot": "A0A5K4EPN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPN4"
  },
  {
    "uniprot": "A0A5K4F4U1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4U1"
  },
  {
    "uniprot": "A0A5K4FA75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA75"
  },
  {
    "uniprot": "A0A5K4F6C1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6C1"
  },
  {
    "uniprot": "G4LYA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYA2"
  },
  {
    "uniprot": "A0A5K4ESP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESP3"
  },
  {
    "uniprot": "A0A5K4FFN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFN0"
  },
  {
    "uniprot": "C4Q8W8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q8W8"
  },
  {
    "uniprot": "A0A3Q0KE84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE84"
  },
  {
    "uniprot": "A0A5K4F6M0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6M0"
  },
  {
    "uniprot": "A0A5K4ER83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER83"
  },
  {
    "uniprot": "A0A5K4F5F1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5F1"
  },
  {
    "uniprot": "A0A3Q0KGQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGQ0"
  },
  {
    "uniprot": "A0A3Q0KKX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKX7"
  },
  {
    "uniprot": "A0A5K4F9Y8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Y8"
  },
  {
    "uniprot": "A0A5K4F164",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F164"
  },
  {
    "uniprot": "A0A5K4F3D4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3D4"
  },
  {
    "uniprot": "A0A5K4FGY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGY4"
  },
  {
    "uniprot": "A0A5K4EYG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYG4"
  },
  {
    "uniprot": "A0A5K4EUZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUZ7"
  },
  {
    "uniprot": "A0A3Q0KKW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKW5"
  },
  {
    "uniprot": "G4V6I3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6I3"
  },
  {
    "uniprot": "G4VIE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIE3"
  },
  {
    "uniprot": "A0A3Q0KFK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFK3"
  },
  {
    "uniprot": "A0A5K4F039",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F039"
  },
  {
    "uniprot": "G4VFY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFY7"
  },
  {
    "uniprot": "G4V865",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V865"
  },
  {
    "uniprot": "G4VLZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLZ4"
  },
  {
    "uniprot": "A0A5K4FBI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBI4"
  },
  {
    "uniprot": "G4VL25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VL25"
  },
  {
    "uniprot": "A0A3Q0KFJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFJ8"
  },
  {
    "uniprot": "G4VMP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMP5"
  },
  {
    "uniprot": "A0A3Q0KRI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRI1"
  },
  {
    "uniprot": "I4EC25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/I4EC25"
  },
  {
    "uniprot": "A0A3Q0KQU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQU2"
  },
  {
    "uniprot": "A0A5K4EXH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXH5"
  },
  {
    "uniprot": "A0A5K4ERG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERG6"
  },
  {
    "uniprot": "A0A5K4F9D2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9D2"
  },
  {
    "uniprot": "A0A5K4F126",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F126"
  },
  {
    "uniprot": "G4VM28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM28"
  },
  {
    "uniprot": "G4VNR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNR3"
  },
  {
    "uniprot": "A0A5K4EAV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAV1"
  },
  {
    "uniprot": "A0A3Q0KV38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV38"
  },
  {
    "uniprot": "A0A5K4F5Q7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Q7"
  },
  {
    "uniprot": "A0A5K4F4Y4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Y4"
  },
  {
    "uniprot": "G4V9G8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9G8"
  },
  {
    "uniprot": "A0A5K4FD46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD46"
  },
  {
    "uniprot": "A0A5K4F664",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F664"
  },
  {
    "uniprot": "A0A3Q0KEE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEE9"
  },
  {
    "uniprot": "A0A5K4F133",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F133"
  },
  {
    "uniprot": "A0A5K4FC74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC74"
  },
  {
    "uniprot": "A0A5K4F7C5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7C5"
  },
  {
    "uniprot": "A0A3Q0KTA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTA3"
  },
  {
    "uniprot": "A0A3Q0KDJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDJ3"
  },
  {
    "uniprot": "A0A3Q0KMX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMX4"
  },
  {
    "uniprot": "A0A5K4FB64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB64"
  },
  {
    "uniprot": "A0A5K4F2G0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2G0"
  },
  {
    "uniprot": "G4VEM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEM1"
  },
  {
    "uniprot": "A0A3Q0KME5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KME5"
  },
  {
    "uniprot": "A0A5K4F1L1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1L1"
  },
  {
    "uniprot": "A0A5K4F5C9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5C9"
  },
  {
    "uniprot": "A0A3Q0KGM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGM1"
  },
  {
    "uniprot": "A0A3Q0KV52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV52"
  },
  {
    "uniprot": "G9HW02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G9HW02"
  },
  {
    "uniprot": "A0A5K4ERZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERZ8"
  },
  {
    "uniprot": "G4VLY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLY7"
  },
  {
    "uniprot": "A0A5K4FEJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEJ9"
  },
  {
    "uniprot": "G4VNH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNH9"
  },
  {
    "uniprot": "A0A3Q0KLW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLW3"
  },
  {
    "uniprot": "G4VDW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDW1"
  },
  {
    "uniprot": "G4VER2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VER2"
  },
  {
    "uniprot": "A0A3Q0KH72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH72"
  },
  {
    "uniprot": "A0A5K4FB22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB22"
  },
  {
    "uniprot": "A0A5K4F0P8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0P8"
  },
  {
    "uniprot": "A0A5K4F7F8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7F8"
  },
  {
    "uniprot": "A0A3Q0KM82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM82"
  },
  {
    "uniprot": "A0A5K4F0D4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0D4"
  },
  {
    "uniprot": "A0A3Q0KDI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDI7"
  },
  {
    "uniprot": "A0A5K4F8A9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8A9"
  },
  {
    "uniprot": "G4M0X2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0X2"
  },
  {
    "uniprot": "A0A3Q0KL02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL02"
  },
  {
    "uniprot": "G4VG38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VG38"
  },
  {
    "uniprot": "A0A5K4EEI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEI2"
  },
  {
    "uniprot": "A0A5K4F4I2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4I2"
  },
  {
    "uniprot": "A0A5K4ER46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER46"
  },
  {
    "uniprot": "A0A5K4F6D2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6D2"
  },
  {
    "uniprot": "G4VFS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFS6"
  },
  {
    "uniprot": "A0A3Q0KLQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLQ3"
  },
  {
    "uniprot": "A0A5K4F917",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F917"
  },
  {
    "uniprot": "P46508",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P46508"
  },
  {
    "uniprot": "A0A3Q0KN52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN52"
  },
  {
    "uniprot": "A0A5K4FFP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFP7"
  },
  {
    "uniprot": "A0A3Q0KH05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH05"
  },
  {
    "uniprot": "A0A3Q0KTQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTQ3"
  },
  {
    "uniprot": "A0A3Q0KDW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDW3"
  },
  {
    "uniprot": "A0A5K4F4M4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4M4"
  },
  {
    "uniprot": "A0A5K4F5A3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5A3"
  },
  {
    "uniprot": "A0A5K4F561",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F561"
  },
  {
    "uniprot": "A0A5K4EME8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EME8"
  },
  {
    "uniprot": "A0A5K4F0H7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0H7"
  },
  {
    "uniprot": "G4VK78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK78"
  },
  {
    "uniprot": "A0A5K4F9F5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9F5"
  },
  {
    "uniprot": "A0A5K4F7H3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7H3"
  },
  {
    "uniprot": "G4LYA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYA6"
  },
  {
    "uniprot": "A0A5K4FCZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCZ0"
  },
  {
    "uniprot": "A0A3Q0KHE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHE7"
  },
  {
    "uniprot": "A0A5K4F3H1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3H1"
  },
  {
    "uniprot": "A0A5K4EXN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXN7"
  },
  {
    "uniprot": "A0A3Q0KQ33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ33"
  },
  {
    "uniprot": "A0A3Q0KEP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEP3"
  },
  {
    "uniprot": "A0A3Q0KQN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQN6"
  },
  {
    "uniprot": "A0A3Q0KNH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNH3"
  },
  {
    "uniprot": "D7R925",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/D7R925"
  },
  {
    "uniprot": "G4V5W4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5W4"
  },
  {
    "uniprot": "A0A3Q0KNX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNX1"
  },
  {
    "uniprot": "A0A3Q0KDV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDV2"
  },
  {
    "uniprot": "G4VRE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRE2"
  },
  {
    "uniprot": "A0A3Q0KSV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSV6"
  },
  {
    "uniprot": "A0A5K4FFC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFC6"
  },
  {
    "uniprot": "A0A5K4EWY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWY4"
  },
  {
    "uniprot": "G4VDE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDE7"
  },
  {
    "uniprot": "A0A5K4F621",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F621"
  },
  {
    "uniprot": "G4VTA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTA4"
  },
  {
    "uniprot": "A0A5K4F6Q2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Q2"
  },
  {
    "uniprot": "G4VBI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBI3"
  },
  {
    "uniprot": "A0A5K4EGX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGX0"
  },
  {
    "uniprot": "A0A5K4F7I7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7I7"
  },
  {
    "uniprot": "A0A5K4EWT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWT5"
  },
  {
    "uniprot": "A0A3Q0KG42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG42"
  },
  {
    "uniprot": "A0A5K4F522",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F522"
  },
  {
    "uniprot": "A0A5K4F204",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F204"
  },
  {
    "uniprot": "A0A3Q0KMJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMJ3"
  },
  {
    "uniprot": "A0A3Q0KV56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV56"
  },
  {
    "uniprot": "A0A5K4F0V6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0V6"
  },
  {
    "uniprot": "Q1JPR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q1JPR1"
  },
  {
    "uniprot": "G4VQZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQZ4"
  },
  {
    "uniprot": "G4V6Q3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6Q3"
  },
  {
    "uniprot": "A0A3Q0KFL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFL4"
  },
  {
    "uniprot": "A0A5K4EQE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQE8"
  },
  {
    "uniprot": "A0A3Q0KQ92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ92"
  },
  {
    "uniprot": "A0A5K4F335",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F335"
  },
  {
    "uniprot": "A0A3Q0KP24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP24"
  },
  {
    "uniprot": "A0A3Q0KU39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU39"
  },
  {
    "uniprot": "A0A5K4FBZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBZ4"
  },
  {
    "uniprot": "A0A5K4F7E1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7E1"
  },
  {
    "uniprot": "Q4PLC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q4PLC2"
  },
  {
    "uniprot": "A0A5K4F3P2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3P2"
  },
  {
    "uniprot": "A0A5K4EH80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH80"
  },
  {
    "uniprot": "G4VEW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEW1"
  },
  {
    "uniprot": "A0A5K4FB70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB70"
  },
  {
    "uniprot": "A0A5K4ENW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENW6"
  },
  {
    "uniprot": "A0A5K4F2K3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2K3"
  },
  {
    "uniprot": "G4VCC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCC1"
  },
  {
    "uniprot": "A0A3Q0KKL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKL9"
  },
  {
    "uniprot": "A0A5K4EMR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMR5"
  },
  {
    "uniprot": "A0A3Q0KQV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQV4"
  },
  {
    "uniprot": "G4VEF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEF4"
  },
  {
    "uniprot": "G4VAN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAN4"
  },
  {
    "uniprot": "A0A5K4F266",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F266"
  },
  {
    "uniprot": "A0A5K4FA83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA83"
  },
  {
    "uniprot": "A0A3Q0KML6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KML6"
  },
  {
    "uniprot": "A0A3Q0KSC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSC7"
  },
  {
    "uniprot": "A0A3Q0KRP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRP4"
  },
  {
    "uniprot": "G4LXI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXI6"
  },
  {
    "uniprot": "G4LZG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZG8"
  },
  {
    "uniprot": "A0A3Q0KPN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPN7"
  },
  {
    "uniprot": "G4M060",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M060"
  },
  {
    "uniprot": "A0A5K4F824",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F824"
  },
  {
    "uniprot": "A0A5K4EVI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVI1"
  },
  {
    "uniprot": "A0A3Q0KN46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN46"
  },
  {
    "uniprot": "G4VTL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTL9"
  },
  {
    "uniprot": "A0A5K4EZU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZU0"
  },
  {
    "uniprot": "A0A5K4F3C3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3C3"
  },
  {
    "uniprot": "A0A5K4F178",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F178"
  },
  {
    "uniprot": "A0A3Q0KTK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTK9"
  },
  {
    "uniprot": "A0A3Q0KCJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCJ8"
  },
  {
    "uniprot": "A0A5K4F399",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F399"
  },
  {
    "uniprot": "G4M129",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M129"
  },
  {
    "uniprot": "A0A3Q0KL89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL89"
  },
  {
    "uniprot": "A0A3Q0KUW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUW8"
  },
  {
    "uniprot": "A0A5K4F7B3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7B3"
  },
  {
    "uniprot": "A0A5K4F3W6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3W6"
  },
  {
    "uniprot": "A0A5K4FEZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEZ1"
  },
  {
    "uniprot": "A0A3Q0KP94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP94"
  },
  {
    "uniprot": "G4V7A5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7A5"
  },
  {
    "uniprot": "A0A3Q0KLL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLL1"
  },
  {
    "uniprot": "A0A3Q0KI64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI64"
  },
  {
    "uniprot": "A0A5K4F2P0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2P0"
  },
  {
    "uniprot": "A0A5K4EP06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP06"
  },
  {
    "uniprot": "A0A3Q0KUY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUY1"
  },
  {
    "uniprot": "A0A3Q0KIL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIL3"
  },
  {
    "uniprot": "A0A5K4FGJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGJ0"
  },
  {
    "uniprot": "G4LWF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWF2"
  },
  {
    "uniprot": "G4VLC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLC2"
  },
  {
    "uniprot": "A0A3Q0KQH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQH1"
  },
  {
    "uniprot": "A0A5K4EL24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL24"
  },
  {
    "uniprot": "A0A3Q0KI52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI52"
  },
  {
    "uniprot": "A0A5K4F8N0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8N0"
  },
  {
    "uniprot": "A0A5K4FC56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC56"
  },
  {
    "uniprot": "G4VKK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKK3"
  },
  {
    "uniprot": "A0A5K4EN40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN40"
  },
  {
    "uniprot": "A0A3Q0KM06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM06"
  },
  {
    "uniprot": "A0A3Q0KQ67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ67"
  },
  {
    "uniprot": "A0A5K4F608",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F608"
  },
  {
    "uniprot": "A0A3Q0KRG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRG8"
  },
  {
    "uniprot": "A0A5K4F6M3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6M3"
  },
  {
    "uniprot": "A0A5K4FCI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCI9"
  },
  {
    "uniprot": "A0A3Q0KM26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM26"
  },
  {
    "uniprot": "A0A3Q0KRL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRL7"
  },
  {
    "uniprot": "A0A5K4F9Q0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Q0"
  },
  {
    "uniprot": "A0A5K4FBC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBC7"
  },
  {
    "uniprot": "A0A3Q0KC57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC57"
  },
  {
    "uniprot": "C4Q4G9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q4G9"
  },
  {
    "uniprot": "A0A3Q0KJJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJJ8"
  },
  {
    "uniprot": "G4VT40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT40"
  },
  {
    "uniprot": "A0A3Q0KH78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH78"
  },
  {
    "uniprot": "A0A3Q0KJY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJY7"
  },
  {
    "uniprot": "A0A5K4F6Y9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Y9"
  },
  {
    "uniprot": "A0A5K4F890",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F890"
  },
  {
    "uniprot": "G4VGU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGU1"
  },
  {
    "uniprot": "A0A3Q0KCY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCY8"
  },
  {
    "uniprot": "A0A3Q0KCY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCY0"
  },
  {
    "uniprot": "A0A5K4FC62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC62"
  },
  {
    "uniprot": "A0A5K4FCG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCG4"
  },
  {
    "uniprot": "A0A3Q0KVM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVM0"
  },
  {
    "uniprot": "G4V5M8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5M8"
  },
  {
    "uniprot": "A0A3Q0KL20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL20"
  },
  {
    "uniprot": "A0A5K4EYM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYM9"
  },
  {
    "uniprot": "A0A5K4F3X1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3X1"
  },
  {
    "uniprot": "G4VFW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFW0"
  },
  {
    "uniprot": "A0A5K4EUY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUY4"
  },
  {
    "uniprot": "A0A3Q0KPI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPI4"
  },
  {
    "uniprot": "A0A3Q0KU56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU56"
  },
  {
    "uniprot": "A0A5K4EAB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAB8"
  },
  {
    "uniprot": "A0A3Q0KMF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMF9"
  },
  {
    "uniprot": "A0A5K4FC83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC83"
  },
  {
    "uniprot": "A0A3Q0KLG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLG7"
  },
  {
    "uniprot": "A0A3Q0KFE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFE2"
  },
  {
    "uniprot": "A0A5K4EMM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMM3"
  },
  {
    "uniprot": "G4VHA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHA2"
  },
  {
    "uniprot": "A0A5K4EHY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHY0"
  },
  {
    "uniprot": "A0A5K4FAD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAD7"
  },
  {
    "uniprot": "A0A3Q0KVE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVE5"
  },
  {
    "uniprot": "G4VJ65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ65"
  },
  {
    "uniprot": "A0A5K4FB94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB94"
  },
  {
    "uniprot": "G4M0C1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0C1"
  },
  {
    "uniprot": "A0A5K4ED28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED28"
  },
  {
    "uniprot": "A0A5K4F6B5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6B5"
  },
  {
    "uniprot": "A0A5K4F298",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F298"
  },
  {
    "uniprot": "A0A5K4FAA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAA0"
  },
  {
    "uniprot": "G4V9S9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9S9"
  },
  {
    "uniprot": "A0A3Q0KLN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLN2"
  },
  {
    "uniprot": "A0A5K4F7T7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7T7"
  },
  {
    "uniprot": "E9LZR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/E9LZR7"
  },
  {
    "uniprot": "A0A3Q0KFP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFP2"
  },
  {
    "uniprot": "A0A5K4F7I3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7I3"
  },
  {
    "uniprot": "A0A5K4FC90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC90"
  },
  {
    "uniprot": "A0A5K4EM44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM44"
  },
  {
    "uniprot": "A0A3Q0KGA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGA1"
  },
  {
    "uniprot": "A0A5K4F9R1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9R1"
  },
  {
    "uniprot": "G4VI23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI23"
  },
  {
    "uniprot": "A0A5K4ELY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELY4"
  },
  {
    "uniprot": "A0A5K4FB87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB87"
  },
  {
    "uniprot": "A0A3Q0KKQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKQ2"
  },
  {
    "uniprot": "A0A3Q0KDP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDP7"
  },
  {
    "uniprot": "A0A5K4F690",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F690"
  },
  {
    "uniprot": "A0A5K4F6T5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6T5"
  },
  {
    "uniprot": "A0A5K4F7J2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7J2"
  },
  {
    "uniprot": "A0A5K4EAT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAT2"
  },
  {
    "uniprot": "A0A5K4F626",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F626"
  },
  {
    "uniprot": "A0A3Q0KCL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCL4"
  },
  {
    "uniprot": "A0A3Q0KRK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRK4"
  },
  {
    "uniprot": "A0A5K4F3K9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3K9"
  },
  {
    "uniprot": "G4V9Y3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9Y3"
  },
  {
    "uniprot": "G4VQW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQW2"
  },
  {
    "uniprot": "A0A3Q0KEU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEU9"
  },
  {
    "uniprot": "A0A5K4F407",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F407"
  },
  {
    "uniprot": "A0A5K4EXB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXB1"
  },
  {
    "uniprot": "A0A5K4EY67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY67"
  },
  {
    "uniprot": "A0A5K4F7I2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7I2"
  },
  {
    "uniprot": "A0A5K4FC28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC28"
  },
  {
    "uniprot": "A0A3Q0KKL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKL6"
  },
  {
    "uniprot": "C4PZQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4PZQ3"
  },
  {
    "uniprot": "G4VC56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC56"
  },
  {
    "uniprot": "A0A3Q0KPM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPM4"
  },
  {
    "uniprot": "G4VAK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAK0"
  },
  {
    "uniprot": "A0A5K4F3X4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3X4"
  },
  {
    "uniprot": "A0A5K4EJH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJH5"
  },
  {
    "uniprot": "G4VR71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR71"
  },
  {
    "uniprot": "A0A5K4EXW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXW9"
  },
  {
    "uniprot": "A0A5K4F448",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F448"
  },
  {
    "uniprot": "A0A5K4EPH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPH4"
  },
  {
    "uniprot": "A0A5K4EZL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZL5"
  },
  {
    "uniprot": "A0A3Q0KL98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL98"
  },
  {
    "uniprot": "A0A5K4EJH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJH8"
  },
  {
    "uniprot": "A0A5K4F102",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F102"
  },
  {
    "uniprot": "A0A3Q0KMW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMW0"
  },
  {
    "uniprot": "A0A3Q0KJC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJC4"
  },
  {
    "uniprot": "Q8I8A0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q8I8A0"
  },
  {
    "uniprot": "A0A5K4FA27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA27"
  },
  {
    "uniprot": "C4QIC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QIC0"
  },
  {
    "uniprot": "A0A5K4F056",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F056"
  },
  {
    "uniprot": "A0A5K4ENJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENJ7"
  },
  {
    "uniprot": "A0A5K4F0M3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0M3"
  },
  {
    "uniprot": "A0A5K4FBI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBI0"
  },
  {
    "uniprot": "A0A3Q0KLS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLS2"
  },
  {
    "uniprot": "A0A3Q0KBD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBD1"
  },
  {
    "uniprot": "A0A5K4EMQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMQ5"
  },
  {
    "uniprot": "A0A5K4FBK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBK1"
  },
  {
    "uniprot": "A0A5K4FCS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCS7"
  },
  {
    "uniprot": "A0A3Q0KUQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUQ0"
  },
  {
    "uniprot": "C4QKQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QKQ7"
  },
  {
    "uniprot": "G4LWE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWE2"
  },
  {
    "uniprot": "G4VS15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS15"
  },
  {
    "uniprot": "A0A5K4FBA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBA4"
  },
  {
    "uniprot": "G4VL63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VL63"
  },
  {
    "uniprot": "A0A5K4ECD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECD5"
  },
  {
    "uniprot": "A0A5K4FAV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAV1"
  },
  {
    "uniprot": "A0A5K4EVB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVB3"
  },
  {
    "uniprot": "A0A5K4FAH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAH0"
  },
  {
    "uniprot": "A0A5K4F2Z8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Z8"
  },
  {
    "uniprot": "A0A3Q0KIZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIZ9"
  },
  {
    "uniprot": "A0A5K4F8L2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8L2"
  },
  {
    "uniprot": "A0A3Q0KL45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL45"
  },
  {
    "uniprot": "A0A3Q0KH19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH19"
  },
  {
    "uniprot": "A0A5K4FBD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBD3"
  },
  {
    "uniprot": "A0A5K4FAS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAS0"
  },
  {
    "uniprot": "A0A5K4FCS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCS5"
  },
  {
    "uniprot": "A0A5K4FCC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCC4"
  },
  {
    "uniprot": "A0A5K4EZA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZA6"
  },
  {
    "uniprot": "A0A3Q0KDF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDF5"
  },
  {
    "uniprot": "G4LW45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LW45"
  },
  {
    "uniprot": "G4VSN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSN6"
  },
  {
    "uniprot": "A0A5K4F849",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F849"
  },
  {
    "uniprot": "A0A5K4F1G3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1G3"
  },
  {
    "uniprot": "A0A3Q0KDW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDW4"
  },
  {
    "uniprot": "A0A3Q0KMS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMS5"
  },
  {
    "uniprot": "A0A3Q0KKT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKT9"
  },
  {
    "uniprot": "A0A3Q0KHH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHH7"
  },
  {
    "uniprot": "A0A5K4EVE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVE3"
  },
  {
    "uniprot": "A0A3Q0KJI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJI3"
  },
  {
    "uniprot": "A0A3Q0KF26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF26"
  },
  {
    "uniprot": "A0A3Q0KNS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNS7"
  },
  {
    "uniprot": "A0A3Q0KQL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQL8"
  },
  {
    "uniprot": "A0A5K4F3C9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3C9"
  },
  {
    "uniprot": "A0A3Q0KDE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDE4"
  },
  {
    "uniprot": "A0A5K4F853",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F853"
  },
  {
    "uniprot": "A0A3Q0KPC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPC6"
  },
  {
    "uniprot": "A0A5K4F2I7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2I7"
  },
  {
    "uniprot": "A0A5K4FAA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAA5"
  },
  {
    "uniprot": "A0A3Q0KCW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCW7"
  },
  {
    "uniprot": "G4VE51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VE51"
  },
  {
    "uniprot": "A0A5K4F2M7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2M7"
  },
  {
    "uniprot": "A0A3Q0KQD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQD7"
  },
  {
    "uniprot": "A0A3Q0KUD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUD0"
  },
  {
    "uniprot": "A0A5K4EN30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN30"
  },
  {
    "uniprot": "G4LY33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY33"
  },
  {
    "uniprot": "A0A5K4F020",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F020"
  },
  {
    "uniprot": "A0A3Q0KQD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQD9"
  },
  {
    "uniprot": "A0A5K4F065",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F065"
  },
  {
    "uniprot": "A0A3Q0KF59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF59"
  },
  {
    "uniprot": "A0A5K4F6J2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6J2"
  },
  {
    "uniprot": "A0A5K4ENK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENK4"
  },
  {
    "uniprot": "A0A5K4F8Q3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Q3"
  },
  {
    "uniprot": "A0A5K4F672",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F672"
  },
  {
    "uniprot": "A0A5K4F4B3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4B3"
  },
  {
    "uniprot": "A0A3Q0KL75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL75"
  },
  {
    "uniprot": "A0A3Q0KPM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPM1"
  },
  {
    "uniprot": "A0A3Q0KKB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKB2"
  },
  {
    "uniprot": "A0A3Q0KT12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT12"
  },
  {
    "uniprot": "G4V8Y7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8Y7"
  },
  {
    "uniprot": "G4M1N9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1N9"
  },
  {
    "uniprot": "G4M1B2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1B2"
  },
  {
    "uniprot": "A0A3Q0KP62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP62"
  },
  {
    "uniprot": "A0A3Q0KSR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSR6"
  },
  {
    "uniprot": "G4VF33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF33"
  },
  {
    "uniprot": "A0A5K4F5M0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5M0"
  },
  {
    "uniprot": "A0A5K4EZL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZL1"
  },
  {
    "uniprot": "A0A3Q0KGL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGL7"
  },
  {
    "uniprot": "A0A5K4F8X9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8X9"
  },
  {
    "uniprot": "A0A5K4F2A3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2A3"
  },
  {
    "uniprot": "A0A5K4FBF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBF0"
  },
  {
    "uniprot": "G4VKD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKD6"
  },
  {
    "uniprot": "G4VQK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQK3"
  },
  {
    "uniprot": "G4M044",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M044"
  },
  {
    "uniprot": "A0A5K4F2A8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2A8"
  },
  {
    "uniprot": "G4LZ69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ69"
  },
  {
    "uniprot": "A0A3Q0KCJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCJ3"
  },
  {
    "uniprot": "G4V5E2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5E2"
  },
  {
    "uniprot": "A0A5K4F8C0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8C0"
  },
  {
    "uniprot": "A0A5K4F4I1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4I1"
  },
  {
    "uniprot": "G4LZ16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ16"
  },
  {
    "uniprot": "A0A3Q0KGZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGZ9"
  },
  {
    "uniprot": "A0A5K4F878",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F878"
  },
  {
    "uniprot": "A0A5K4F8W1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8W1"
  },
  {
    "uniprot": "A0A3Q0KRR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRR8"
  },
  {
    "uniprot": "A0A5K4F1P0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1P0"
  },
  {
    "uniprot": "G4VJE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJE7"
  },
  {
    "uniprot": "A0A3Q0KCQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCQ4"
  },
  {
    "uniprot": "A0A3Q0KMR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMR5"
  },
  {
    "uniprot": "A0A3Q0KG12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG12"
  },
  {
    "uniprot": "A0A5K4F0J3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0J3"
  },
  {
    "uniprot": "A0A3Q0KRP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRP3"
  },
  {
    "uniprot": "A0A5K4F1U3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1U3"
  },
  {
    "uniprot": "G4LWV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWV6"
  },
  {
    "uniprot": "A0A5K4EU95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU95"
  },
  {
    "uniprot": "A0A3Q0KM50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM50"
  },
  {
    "uniprot": "G4VB17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB17"
  },
  {
    "uniprot": "A0A5K4FBX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBX5"
  },
  {
    "uniprot": "G4VR88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR88"
  },
  {
    "uniprot": "A0A3Q0KCW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCW8"
  },
  {
    "uniprot": "A0A3Q0KNL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNL9"
  },
  {
    "uniprot": "A0A3Q0KG13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG13"
  },
  {
    "uniprot": "G4VEY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEY8"
  },
  {
    "uniprot": "A0A5K4EZY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZY6"
  },
  {
    "uniprot": "A0A3Q0KEQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEQ8"
  },
  {
    "uniprot": "G4LX03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX03"
  },
  {
    "uniprot": "A0A3Q0KIZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIZ5"
  },
  {
    "uniprot": "A0A3Q0KLT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLT1"
  },
  {
    "uniprot": "A0A5K4EUN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUN4"
  },
  {
    "uniprot": "G4VAB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAB5"
  },
  {
    "uniprot": "G4VPS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPS5"
  },
  {
    "uniprot": "A0A3Q0KQJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQJ0"
  },
  {
    "uniprot": "A0A5K4ENY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENY2"
  },
  {
    "uniprot": "A0A5K4F2S9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2S9"
  },
  {
    "uniprot": "A0A5K4F1B9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1B9"
  },
  {
    "uniprot": "C4Q5Z1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q5Z1"
  },
  {
    "uniprot": "A0A5K4EW18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW18"
  },
  {
    "uniprot": "A0A3Q0KU48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU48"
  },
  {
    "uniprot": "A0A3Q0KFG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFG8"
  },
  {
    "uniprot": "A0A3Q0KQW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQW0"
  },
  {
    "uniprot": "A0A5K4E9H8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9H8"
  },
  {
    "uniprot": "A0A3Q0KNS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNS5"
  },
  {
    "uniprot": "A0A5K4F2X4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2X4"
  },
  {
    "uniprot": "A0A3Q0KBI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBI6"
  },
  {
    "uniprot": "A0A5K4ENA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENA3"
  },
  {
    "uniprot": "A0A5K4FB46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB46"
  },
  {
    "uniprot": "A0A5K4FCC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCC9"
  },
  {
    "uniprot": "A0A5K4F249",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F249"
  },
  {
    "uniprot": "A0A3Q0KNZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNZ1"
  },
  {
    "uniprot": "A0A5K4EPT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPT8"
  },
  {
    "uniprot": "A0A5K4F1L9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1L9"
  },
  {
    "uniprot": "A0A5K4F8P5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8P5"
  },
  {
    "uniprot": "A0A5K4FFR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFR4"
  },
  {
    "uniprot": "A0A3Q0KTP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTP0"
  },
  {
    "uniprot": "A0A3Q0KR28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR28"
  },
  {
    "uniprot": "A0A3Q0KKD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKD3"
  },
  {
    "uniprot": "A0A3Q0KNU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNU1"
  },
  {
    "uniprot": "A0A5K4FD84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD84"
  },
  {
    "uniprot": "A0A5K4F1P6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1P6"
  },
  {
    "uniprot": "A0A3Q0KT54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT54"
  },
  {
    "uniprot": "A0A3Q0KD41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD41"
  },
  {
    "uniprot": "G4VLE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLE2"
  },
  {
    "uniprot": "A0A5K4EH41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH41"
  },
  {
    "uniprot": "A0A3Q0KC36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC36"
  },
  {
    "uniprot": "G4VJU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJU9"
  },
  {
    "uniprot": "A0A5K4FCS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCS9"
  },
  {
    "uniprot": "A0A5K4EK46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK46"
  },
  {
    "uniprot": "A0A3Q0KIH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIH6"
  },
  {
    "uniprot": "G4LVJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVJ0"
  },
  {
    "uniprot": "A0A5K4FA69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA69"
  },
  {
    "uniprot": "A0A3Q0KRT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRT1"
  },
  {
    "uniprot": "A0A5K4EPR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPR6"
  },
  {
    "uniprot": "A0A3Q0KEP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEP5"
  },
  {
    "uniprot": "G4VB66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB66"
  },
  {
    "uniprot": "A0A3Q0KDC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDC8"
  },
  {
    "uniprot": "A0A3Q0KKF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKF3"
  },
  {
    "uniprot": "A0A5K4F0H1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0H1"
  },
  {
    "uniprot": "A0A5K4EKK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKK6"
  },
  {
    "uniprot": "A0A5K4F9P9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9P9"
  },
  {
    "uniprot": "A0A5K4EQ52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ52"
  },
  {
    "uniprot": "G4VCC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCC7"
  },
  {
    "uniprot": "A0A3Q0KNK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNK4"
  },
  {
    "uniprot": "A0A5K4F5M2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5M2"
  },
  {
    "uniprot": "G4V7S3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7S3"
  },
  {
    "uniprot": "A0A5K4F443",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F443"
  },
  {
    "uniprot": "A0A5K4FAL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAL7"
  },
  {
    "uniprot": "A0A5K4F7N1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7N1"
  },
  {
    "uniprot": "A0A3Q0KNK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNK7"
  },
  {
    "uniprot": "A0A3Q0KG92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG92"
  },
  {
    "uniprot": "A0A3Q0KUM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUM7"
  },
  {
    "uniprot": "A0A3Q0KLG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLG8"
  },
  {
    "uniprot": "A0A3Q0KQ60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ60"
  },
  {
    "uniprot": "G4VGA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGA5"
  },
  {
    "uniprot": "A0A5K4F369",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F369"
  },
  {
    "uniprot": "G4M158",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M158"
  },
  {
    "uniprot": "G4VN93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VN93"
  },
  {
    "uniprot": "A0A3Q0KFS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFS3"
  },
  {
    "uniprot": "A0A3Q0KNH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNH6"
  },
  {
    "uniprot": "A0A3Q0KNJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNJ6"
  },
  {
    "uniprot": "G4VFV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFV9"
  },
  {
    "uniprot": "G4LZ71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ71"
  },
  {
    "uniprot": "A0A5K4ER50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER50"
  },
  {
    "uniprot": "A0A3Q0KL80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL80"
  },
  {
    "uniprot": "A0A3Q0KBP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBP0"
  },
  {
    "uniprot": "G4VLL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLL3"
  },
  {
    "uniprot": "B4XC67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/B4XC67"
  },
  {
    "uniprot": "A0A5K4FCS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCS0"
  },
  {
    "uniprot": "A0A3Q0KLI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLI9"
  },
  {
    "uniprot": "G4VPP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPP1"
  },
  {
    "uniprot": "A0A5K4EKU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKU3"
  },
  {
    "uniprot": "A0A5K4F122",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F122"
  },
  {
    "uniprot": "G4V8P1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8P1"
  },
  {
    "uniprot": "A0A5K4FAB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAB3"
  },
  {
    "uniprot": "A0A5K4ECH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECH1"
  },
  {
    "uniprot": "A0A5K4EET2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EET2"
  },
  {
    "uniprot": "A0A3Q0KJS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJS3"
  },
  {
    "uniprot": "A0A3Q0KT52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT52"
  },
  {
    "uniprot": "A0A3Q0KTI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTI0"
  },
  {
    "uniprot": "A0A5K4F7H1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7H1"
  },
  {
    "uniprot": "A0A5K4EQE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQE6"
  },
  {
    "uniprot": "A0A3Q0KKP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKP3"
  },
  {
    "uniprot": "A0A3Q0KER9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KER9"
  },
  {
    "uniprot": "A0A3Q0KP78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP78"
  },
  {
    "uniprot": "A0A5K4F5X8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5X8"
  },
  {
    "uniprot": "A0A5K4F9G6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9G6"
  },
  {
    "uniprot": "A0A5K4EQN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQN3"
  },
  {
    "uniprot": "A0A5K4EX88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX88"
  },
  {
    "uniprot": "A0A3Q0KBZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBZ6"
  },
  {
    "uniprot": "A0A3Q0KMH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMH4"
  },
  {
    "uniprot": "A0A3Q0KL95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL95"
  },
  {
    "uniprot": "A0A3Q0KSB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSB6"
  },
  {
    "uniprot": "A0A5K4F1N5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1N5"
  },
  {
    "uniprot": "A0A3Q0KKA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKA6"
  },
  {
    "uniprot": "A0A5K4FDA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDA9"
  },
  {
    "uniprot": "A0A5K4FDR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDR7"
  },
  {
    "uniprot": "A0A3Q0KKM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKM8"
  },
  {
    "uniprot": "G4V9D4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9D4"
  },
  {
    "uniprot": "A0A5K4F6G4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6G4"
  },
  {
    "uniprot": "G4V9B1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9B1"
  },
  {
    "uniprot": "A0A3Q0KS44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS44"
  },
  {
    "uniprot": "A0A5K4F5P0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5P0"
  },
  {
    "uniprot": "A0A3Q0KF86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF86"
  },
  {
    "uniprot": "A0A5K4ENG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENG9"
  },
  {
    "uniprot": "A0A5K4EGA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGA6"
  },
  {
    "uniprot": "C0SKM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C0SKM6"
  },
  {
    "uniprot": "A0A3Q0KLJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLJ3"
  },
  {
    "uniprot": "A0A3Q0KUG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUG7"
  },
  {
    "uniprot": "A0A3Q0KVE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVE6"
  },
  {
    "uniprot": "A0A5K4F9L2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9L2"
  },
  {
    "uniprot": "A0A3Q0KUI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUI8"
  },
  {
    "uniprot": "A0A5K4EVC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVC1"
  },
  {
    "uniprot": "A0A3Q0KSY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSY7"
  },
  {
    "uniprot": "A0A5K4EI21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EI21"
  },
  {
    "uniprot": "A0A5K4F0Q3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Q3"
  },
  {
    "uniprot": "A0A5K4F8V4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8V4"
  },
  {
    "uniprot": "A0A3Q0KV77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV77"
  },
  {
    "uniprot": "A0A5K4FBM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBM9"
  },
  {
    "uniprot": "A0A3Q0KS52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS52"
  },
  {
    "uniprot": "A0A5K4FBX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBX1"
  },
  {
    "uniprot": "A0A3Q0KES1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KES1"
  },
  {
    "uniprot": "A0A5K4F9G9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9G9"
  },
  {
    "uniprot": "A0A5K4FCQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCQ5"
  },
  {
    "uniprot": "A0A5K4F1K2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1K2"
  },
  {
    "uniprot": "A0A3Q0KDH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDH5"
  },
  {
    "uniprot": "A0A3Q0KTY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTY2"
  },
  {
    "uniprot": "A0A5K4F1M6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1M6"
  },
  {
    "uniprot": "A0A3Q0KPJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPJ9"
  },
  {
    "uniprot": "A0A5K4EDT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDT5"
  },
  {
    "uniprot": "A0A3Q0KFN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFN5"
  },
  {
    "uniprot": "G4VR73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR73"
  },
  {
    "uniprot": "A0A5K4F8X4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8X4"
  },
  {
    "uniprot": "A0A5K4FAQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAQ9"
  },
  {
    "uniprot": "G4V9X6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9X6"
  },
  {
    "uniprot": "G4M0F5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0F5"
  },
  {
    "uniprot": "A0A5K4ENW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENW1"
  },
  {
    "uniprot": "A0A5K4F5J1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5J1"
  },
  {
    "uniprot": "A0A5K4F0P2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0P2"
  },
  {
    "uniprot": "A0A3Q0KV87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV87"
  },
  {
    "uniprot": "C4QMF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QMF9"
  },
  {
    "uniprot": "G4LV96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV96"
  },
  {
    "uniprot": "G4LY60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY60"
  },
  {
    "uniprot": "A0A5K4FAM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAM8"
  },
  {
    "uniprot": "G4VAQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAQ7"
  },
  {
    "uniprot": "A0A5K4FBU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBU6"
  },
  {
    "uniprot": "A0A3Q0KU92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU92"
  },
  {
    "uniprot": "A0A3Q0KEQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEQ0"
  },
  {
    "uniprot": "A0A5K4F512",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F512"
  },
  {
    "uniprot": "A0A5K4E941",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E941"
  },
  {
    "uniprot": "A0A3Q0KGG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGG9"
  },
  {
    "uniprot": "A0A5K4EJJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJJ2"
  },
  {
    "uniprot": "G4LVI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVI0"
  },
  {
    "uniprot": "A0A3Q0KIB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIB4"
  },
  {
    "uniprot": "A0A5K4ENW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENW0"
  },
  {
    "uniprot": "G4VK04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK04"
  },
  {
    "uniprot": "A0A5K4F5B1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5B1"
  },
  {
    "uniprot": "A0A5K4F750",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F750"
  },
  {
    "uniprot": "A0A3Q0KL77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL77"
  },
  {
    "uniprot": "A0A5K4FF77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF77"
  },
  {
    "uniprot": "A0A3Q0KBZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBZ4"
  },
  {
    "uniprot": "A0A5K4F8Y2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Y2"
  },
  {
    "uniprot": "G4VRH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRH7"
  },
  {
    "uniprot": "A0A3Q0KQD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQD4"
  },
  {
    "uniprot": "A0A5K4F3I7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3I7"
  },
  {
    "uniprot": "A0A3Q0KBW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBW1"
  },
  {
    "uniprot": "A0A5K4F1X2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1X2"
  },
  {
    "uniprot": "A0A3Q0KKS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKS4"
  },
  {
    "uniprot": "A0A3Q0KJI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJI7"
  },
  {
    "uniprot": "A0A5K4FE72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE72"
  },
  {
    "uniprot": "A0A3Q0KDC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDC9"
  },
  {
    "uniprot": "A0A5K4F9M1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9M1"
  },
  {
    "uniprot": "A0A5K4EE10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE10"
  },
  {
    "uniprot": "A0A3Q0KQQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQQ7"
  },
  {
    "uniprot": "A0A5K4F3D8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3D8"
  },
  {
    "uniprot": "G4V7Z7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7Z7"
  },
  {
    "uniprot": "A0A3Q0KPH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPH3"
  },
  {
    "uniprot": "G4LYZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYZ1"
  },
  {
    "uniprot": "A0A3Q0KG93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG93"
  },
  {
    "uniprot": "G4VGD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGD0"
  },
  {
    "uniprot": "A0A5K4F2V2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2V2"
  },
  {
    "uniprot": "A0A3Q0KE41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE41"
  },
  {
    "uniprot": "G4VPW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPW8"
  },
  {
    "uniprot": "A0A5K4F1F5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1F5"
  },
  {
    "uniprot": "A0A3Q0KN58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN58"
  },
  {
    "uniprot": "A0A5K4FC38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC38"
  },
  {
    "uniprot": "A0A5K4FDH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDH9"
  },
  {
    "uniprot": "A0A5K4F3S2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3S2"
  },
  {
    "uniprot": "A0A3Q0KH31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH31"
  },
  {
    "uniprot": "A0A5K4F1I0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1I0"
  },
  {
    "uniprot": "G4VRB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRB5"
  },
  {
    "uniprot": "A0A5K4F4Z9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Z9"
  },
  {
    "uniprot": "A0A3Q0KND7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KND7"
  },
  {
    "uniprot": "A0A5K4EZE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZE2"
  },
  {
    "uniprot": "A0A3Q0KPG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPG2"
  },
  {
    "uniprot": "A0A5K4F440",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F440"
  },
  {
    "uniprot": "A0A3Q0KM77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM77"
  },
  {
    "uniprot": "A0A5K4EJM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJM6"
  },
  {
    "uniprot": "A0A5K4FFJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFJ7"
  },
  {
    "uniprot": "G4VB87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB87"
  },
  {
    "uniprot": "A0A5K4F973",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F973"
  },
  {
    "uniprot": "A0A5K4EEA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEA4"
  },
  {
    "uniprot": "A0A5K4ELP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELP7"
  },
  {
    "uniprot": "A0A3Q0KLQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLQ4"
  },
  {
    "uniprot": "A0A5K4F979",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F979"
  },
  {
    "uniprot": "A0A5K4F3C1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3C1"
  },
  {
    "uniprot": "A0A5K4EQ55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ55"
  },
  {
    "uniprot": "A0A3Q0KSM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSM4"
  },
  {
    "uniprot": "A0A5K4EZL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZL9"
  },
  {
    "uniprot": "A0A5K4FAB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAB7"
  },
  {
    "uniprot": "A0A5K4F8Q8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Q8"
  },
  {
    "uniprot": "A0A3Q0KK56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK56"
  },
  {
    "uniprot": "A0A3Q0KIN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIN6"
  },
  {
    "uniprot": "A0A5K4F680",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F680"
  },
  {
    "uniprot": "A0A5K4EH64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH64"
  },
  {
    "uniprot": "A0A5K4F6W5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6W5"
  },
  {
    "uniprot": "A0A5K4F9I4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9I4"
  },
  {
    "uniprot": "A0A3Q0KS24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS24"
  },
  {
    "uniprot": "G4VJJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJJ1"
  },
  {
    "uniprot": "G4VTA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTA5"
  },
  {
    "uniprot": "A0A5K4FEM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEM3"
  },
  {
    "uniprot": "A0A3Q0KMZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMZ8"
  },
  {
    "uniprot": "G4VS95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS95"
  },
  {
    "uniprot": "A0A5K4EPX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPX2"
  },
  {
    "uniprot": "A0A5K4F0T2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0T2"
  },
  {
    "uniprot": "A0A3Q0KES6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KES6"
  },
  {
    "uniprot": "A0A5K4EVP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVP4"
  },
  {
    "uniprot": "G4VR08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR08"
  },
  {
    "uniprot": "G4VQN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQN8"
  },
  {
    "uniprot": "A0A3Q0KV93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV93"
  },
  {
    "uniprot": "A0A3Q0KK67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK67"
  },
  {
    "uniprot": "A0A5K4ETL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETL5"
  },
  {
    "uniprot": "A0A5K4FA22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA22"
  },
  {
    "uniprot": "A0A5K4F181",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F181"
  },
  {
    "uniprot": "A0A3Q0KR20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR20"
  },
  {
    "uniprot": "A0A5K4F6P4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6P4"
  },
  {
    "uniprot": "A0A3Q0KVC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVC6"
  },
  {
    "uniprot": "A0A3Q0KST5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KST5"
  },
  {
    "uniprot": "A0A5K4EWB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWB4"
  },
  {
    "uniprot": "A0A3Q0KBT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBT3"
  },
  {
    "uniprot": "A0A3Q0KL67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL67"
  },
  {
    "uniprot": "A0A3Q0KBJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBJ6"
  },
  {
    "uniprot": "G4VM64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM64"
  },
  {
    "uniprot": "A0A5K4FBV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBV7"
  },
  {
    "uniprot": "A0A5K4FAU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAU9"
  },
  {
    "uniprot": "A0A3Q0KMM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMM9"
  },
  {
    "uniprot": "A0A5K4F2H1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2H1"
  },
  {
    "uniprot": "A0A3Q0KRL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRL4"
  },
  {
    "uniprot": "A0A5K4F590",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F590"
  },
  {
    "uniprot": "A0A3Q0KVB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVB0"
  },
  {
    "uniprot": "A0A5K4ECV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECV3"
  },
  {
    "uniprot": "A0A5K4FB26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB26"
  },
  {
    "uniprot": "A0A3Q0KGT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGT8"
  },
  {
    "uniprot": "A0A5K4EYY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYY7"
  },
  {
    "uniprot": "A0A5K4FBN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBN5"
  },
  {
    "uniprot": "A0A5K4FDT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDT7"
  },
  {
    "uniprot": "A0A3Q0KBW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBW2"
  },
  {
    "uniprot": "A0A5K4F3S9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3S9"
  },
  {
    "uniprot": "G4VK57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK57"
  },
  {
    "uniprot": "A0A3Q0KP92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP92"
  },
  {
    "uniprot": "A0A5K4FBD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBD8"
  },
  {
    "uniprot": "A0A3Q0KKQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKQ6"
  },
  {
    "uniprot": "A0A5K4F9T3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9T3"
  },
  {
    "uniprot": "A0A3Q0KNZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNZ2"
  },
  {
    "uniprot": "A0A5K4F8U8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8U8"
  },
  {
    "uniprot": "G4VCI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCI8"
  },
  {
    "uniprot": "A0A3Q0KR90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR90"
  },
  {
    "uniprot": "A0A3Q0KQ46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ46"
  },
  {
    "uniprot": "A0A5K4EMX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMX9"
  },
  {
    "uniprot": "A0A5K4EQI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQI4"
  },
  {
    "uniprot": "A0A5K4F5R1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5R1"
  },
  {
    "uniprot": "A0A3Q0KMJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMJ6"
  },
  {
    "uniprot": "A0A5K4EL95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL95"
  },
  {
    "uniprot": "G4VN33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VN33"
  },
  {
    "uniprot": "A9CBJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A9CBJ4"
  },
  {
    "uniprot": "A0A3Q0KEJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEJ9"
  },
  {
    "uniprot": "A0A5K4EKT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKT5"
  },
  {
    "uniprot": "A0A3Q0KQV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQV3"
  },
  {
    "uniprot": "A0A5K4EEG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEG9"
  },
  {
    "uniprot": "A0A3Q0KNU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNU9"
  },
  {
    "uniprot": "A0A5K4EQW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQW6"
  },
  {
    "uniprot": "A0A3Q0KHM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHM0"
  },
  {
    "uniprot": "A0A5K4EUJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUJ6"
  },
  {
    "uniprot": "A0A5K4EJ46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJ46"
  },
  {
    "uniprot": "A0A3Q0KFA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFA8"
  },
  {
    "uniprot": "A0A3Q0KB99",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KB99"
  },
  {
    "uniprot": "G4VFG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFG2"
  },
  {
    "uniprot": "A0A5K4FEY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEY6"
  },
  {
    "uniprot": "G4VS02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS02"
  },
  {
    "uniprot": "A0A5K4FE05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE05"
  },
  {
    "uniprot": "G4LW63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LW63"
  },
  {
    "uniprot": "A0A3Q0KED2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KED2"
  },
  {
    "uniprot": "A0A3Q0KL09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL09"
  },
  {
    "uniprot": "A0A5K4F0H4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0H4"
  },
  {
    "uniprot": "A0A5K4F7G1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7G1"
  },
  {
    "uniprot": "A0A5K4F9S4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9S4"
  },
  {
    "uniprot": "A0A5K4FCY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCY6"
  },
  {
    "uniprot": "A0A3Q0KGD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGD8"
  },
  {
    "uniprot": "G4V780",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V780"
  },
  {
    "uniprot": "A0A3Q0KTN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTN2"
  },
  {
    "uniprot": "A0A3Q0KLE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLE4"
  },
  {
    "uniprot": "A0A5K4E8F1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8F1"
  },
  {
    "uniprot": "A0A5K4F673",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F673"
  },
  {
    "uniprot": "A0A5K4F9N3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9N3"
  },
  {
    "uniprot": "A0A3Q0KPT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPT6"
  },
  {
    "uniprot": "A0A5K4FAV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAV4"
  },
  {
    "uniprot": "A0A3Q0KI55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI55"
  },
  {
    "uniprot": "A0A3Q0KSP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSP7"
  },
  {
    "uniprot": "A0A3Q0KTV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTV6"
  },
  {
    "uniprot": "A0A5K4F1B7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1B7"
  },
  {
    "uniprot": "G4VI62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI62"
  },
  {
    "uniprot": "A0A5K4F5H3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5H3"
  },
  {
    "uniprot": "A0A5K4F1G2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1G2"
  },
  {
    "uniprot": "A0A3Q0KPI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPI1"
  },
  {
    "uniprot": "A0A5K4FDJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDJ1"
  },
  {
    "uniprot": "A0A5K4F4Z8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Z8"
  },
  {
    "uniprot": "A0A3Q0KT20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT20"
  },
  {
    "uniprot": "G4VLF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLF9"
  },
  {
    "uniprot": "A0A5K4EE38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE38"
  },
  {
    "uniprot": "G4VGM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGM8"
  },
  {
    "uniprot": "A0A3Q0KJU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJU1"
  },
  {
    "uniprot": "A0A5K4F593",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F593"
  },
  {
    "uniprot": "A0A5K4F9P5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9P5"
  },
  {
    "uniprot": "G4V9X3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9X3"
  },
  {
    "uniprot": "A0A3Q0KS74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS74"
  },
  {
    "uniprot": "A0A5K4F2L2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2L2"
  },
  {
    "uniprot": "A0A5K4EVE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVE7"
  },
  {
    "uniprot": "A0A3Q0KLT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLT6"
  },
  {
    "uniprot": "A0A5K4EZ46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ46"
  },
  {
    "uniprot": "A0A5K4FCK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCK7"
  },
  {
    "uniprot": "A0A3Q0KIR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIR7"
  },
  {
    "uniprot": "G4V5L4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5L4"
  },
  {
    "uniprot": "A0A5K4FET1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FET1"
  },
  {
    "uniprot": "A0A3Q0KSG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSG9"
  },
  {
    "uniprot": "A0A3Q0KN05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN05"
  },
  {
    "uniprot": "A0A5K4F7U0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7U0"
  },
  {
    "uniprot": "A0A5K4EKJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKJ0"
  },
  {
    "uniprot": "A0A3Q0KNC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNC6"
  },
  {
    "uniprot": "A0A3Q0KLV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLV0"
  },
  {
    "uniprot": "G4V5V0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5V0"
  },
  {
    "uniprot": "A0A5K4F141",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F141"
  },
  {
    "uniprot": "A0A3Q0KTZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTZ1"
  },
  {
    "uniprot": "A0A3Q0KC44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC44"
  },
  {
    "uniprot": "A0A3Q0KTL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTL1"
  },
  {
    "uniprot": "A0A5K4F1G7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1G7"
  },
  {
    "uniprot": "A0A3Q0KIT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIT2"
  },
  {
    "uniprot": "A0A3Q0KJ67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ67"
  },
  {
    "uniprot": "A0A5K4F4L8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4L8"
  },
  {
    "uniprot": "A0A3Q0KH68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH68"
  },
  {
    "uniprot": "A0A5K4F1Y8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Y8"
  },
  {
    "uniprot": "A0A3Q0KE19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE19"
  },
  {
    "uniprot": "A0A5K4FD38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD38"
  },
  {
    "uniprot": "A0A5K4FBW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBW7"
  },
  {
    "uniprot": "A0A3Q0KHR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHR4"
  },
  {
    "uniprot": "A0A5K4EQ95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ95"
  },
  {
    "uniprot": "G4VIZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIZ2"
  },
  {
    "uniprot": "A0A5K4EIJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIJ8"
  },
  {
    "uniprot": "A0A5K4F5D2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5D2"
  },
  {
    "uniprot": "A0A5K4FBG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBG0"
  },
  {
    "uniprot": "A0A5K4F1B5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1B5"
  },
  {
    "uniprot": "A0A3Q0KLS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLS1"
  },
  {
    "uniprot": "G4VAG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAG4"
  },
  {
    "uniprot": "A0A5K4F506",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F506"
  },
  {
    "uniprot": "A0A5K4ER69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER69"
  },
  {
    "uniprot": "A0A5K4E9J4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9J4"
  },
  {
    "uniprot": "A0A3Q0KN30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN30"
  },
  {
    "uniprot": "A0A5K4FCU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCU7"
  },
  {
    "uniprot": "A0A5K4FB59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB59"
  },
  {
    "uniprot": "G4VI94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI94"
  },
  {
    "uniprot": "A0A5K4F0T6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0T6"
  },
  {
    "uniprot": "A0A5K4E9P2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9P2"
  },
  {
    "uniprot": "A0A3Q0KF28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF28"
  },
  {
    "uniprot": "A0A3Q0KIG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIG7"
  },
  {
    "uniprot": "A0A5K4F6E6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6E6"
  },
  {
    "uniprot": "A0A3Q0KJH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJH2"
  },
  {
    "uniprot": "A0A5K4F9F3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9F3"
  },
  {
    "uniprot": "A0A5K4EJ34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJ34"
  },
  {
    "uniprot": "A0A5K4F6F4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6F4"
  },
  {
    "uniprot": "A0A5K4EK14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK14"
  },
  {
    "uniprot": "G4VH38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH38"
  },
  {
    "uniprot": "A0A3Q0KMN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMN2"
  },
  {
    "uniprot": "A0A5K4FDR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDR8"
  },
  {
    "uniprot": "A0A5K4EIT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIT5"
  },
  {
    "uniprot": "A0A3Q0KU90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU90"
  },
  {
    "uniprot": "A0A5K4F1P1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1P1"
  },
  {
    "uniprot": "A0A5K4FBX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBX0"
  },
  {
    "uniprot": "A0A5K4F7R4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7R4"
  },
  {
    "uniprot": "A0A5K4F0S0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0S0"
  },
  {
    "uniprot": "A0A5K4FCD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCD4"
  },
  {
    "uniprot": "A0A5K4F470",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F470"
  },
  {
    "uniprot": "A0A3Q0KL66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL66"
  },
  {
    "uniprot": "A0A3Q0KKU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKU4"
  },
  {
    "uniprot": "A0A5K4EJN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJN9"
  },
  {
    "uniprot": "A0A3Q0KD90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD90"
  },
  {
    "uniprot": "A0A5K4F7Y5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Y5"
  },
  {
    "uniprot": "A0A3Q0KQV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQV8"
  },
  {
    "uniprot": "A0A5K4ES92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES92"
  },
  {
    "uniprot": "Q07167",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q07167"
  },
  {
    "uniprot": "A0A5K4F466",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F466"
  },
  {
    "uniprot": "A0A5K4EZQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZQ3"
  },
  {
    "uniprot": "A0A3Q0KL61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL61"
  },
  {
    "uniprot": "A0A3Q0KPE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPE9"
  },
  {
    "uniprot": "A0A5K4FB74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB74"
  },
  {
    "uniprot": "A0A3Q0KPI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPI8"
  },
  {
    "uniprot": "A0A5K4F9X3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9X3"
  },
  {
    "uniprot": "A0A5K4F988",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F988"
  },
  {
    "uniprot": "A0A5K4ELI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELI6"
  },
  {
    "uniprot": "A0A5K4F0P9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0P9"
  },
  {
    "uniprot": "A0A5K4EJ77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJ77"
  },
  {
    "uniprot": "A0A3Q0KH43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH43"
  },
  {
    "uniprot": "A0A5K4F654",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F654"
  },
  {
    "uniprot": "A0A5K4F1G1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1G1"
  },
  {
    "uniprot": "A0A3Q0KSK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSK7"
  },
  {
    "uniprot": "A0A3Q0KKW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKW1"
  },
  {
    "uniprot": "A0A5K4F774",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F774"
  },
  {
    "uniprot": "G4VRC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRC7"
  },
  {
    "uniprot": "A0A5K4F389",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F389"
  },
  {
    "uniprot": "A0A3Q0KQE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQE9"
  },
  {
    "uniprot": "A0A3Q0KG26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG26"
  },
  {
    "uniprot": "A0A5K4FF27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF27"
  },
  {
    "uniprot": "A0A3Q0KMV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMV0"
  },
  {
    "uniprot": "G4VLZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLZ3"
  },
  {
    "uniprot": "A0A5K4F640",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F640"
  },
  {
    "uniprot": "G4VKB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKB6"
  },
  {
    "uniprot": "G4V9H5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9H5"
  },
  {
    "uniprot": "A0A3Q0KJ09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ09"
  },
  {
    "uniprot": "A0A5K4F8D5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8D5"
  },
  {
    "uniprot": "A0A5K4EXR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXR4"
  },
  {
    "uniprot": "A0A5K4EXZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXZ2"
  },
  {
    "uniprot": "A0A5K4F7Q6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Q6"
  },
  {
    "uniprot": "A0A5K4FDS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDS3"
  },
  {
    "uniprot": "G4V978",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V978"
  },
  {
    "uniprot": "A0A5K4EEM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEM5"
  },
  {
    "uniprot": "C4Q4W0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q4W0"
  },
  {
    "uniprot": "A0A5K4ENY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENY7"
  },
  {
    "uniprot": "A0A3Q0KSR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSR1"
  },
  {
    "uniprot": "G4VQJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQJ3"
  },
  {
    "uniprot": "A0A5K4F313",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F313"
  },
  {
    "uniprot": "A0A3Q0KJ08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ08"
  },
  {
    "uniprot": "G4VC21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC21"
  },
  {
    "uniprot": "A0A3Q0KG29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG29"
  },
  {
    "uniprot": "A0A5K4FEL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEL8"
  },
  {
    "uniprot": "Q26563",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26563"
  },
  {
    "uniprot": "A0A3Q0KKQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKQ3"
  },
  {
    "uniprot": "A0A5K4FBY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBY1"
  },
  {
    "uniprot": "A0A5K4F2U7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2U7"
  },
  {
    "uniprot": "A0A5K4FEM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEM5"
  },
  {
    "uniprot": "A0A5K4EWI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWI8"
  },
  {
    "uniprot": "A0A5K4F2K5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2K5"
  },
  {
    "uniprot": "A0A5K4EBG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBG5"
  },
  {
    "uniprot": "A0A5K4F500",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F500"
  },
  {
    "uniprot": "G4VS51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS51"
  },
  {
    "uniprot": "A0A3Q0KR55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR55"
  },
  {
    "uniprot": "A0A5K4EMP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMP5"
  },
  {
    "uniprot": "A0A3Q0KQ39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ39"
  },
  {
    "uniprot": "A0A5K4F3E6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3E6"
  },
  {
    "uniprot": "A0A5K4F254",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F254"
  },
  {
    "uniprot": "A0A5K4EKM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKM7"
  },
  {
    "uniprot": "A0A3Q0KGR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGR1"
  },
  {
    "uniprot": "G4VEV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEV9"
  },
  {
    "uniprot": "G4VBN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBN6"
  },
  {
    "uniprot": "A0A5K4F2P5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2P5"
  },
  {
    "uniprot": "C4QPK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QPK6"
  },
  {
    "uniprot": "A0A5K4F9X5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9X5"
  },
  {
    "uniprot": "A0A3Q0KD33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD33"
  },
  {
    "uniprot": "G4VM93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM93"
  },
  {
    "uniprot": "A0A3Q0KRC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRC4"
  },
  {
    "uniprot": "A0A5K4EG06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EG06"
  },
  {
    "uniprot": "A0A3Q0KQS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQS5"
  },
  {
    "uniprot": "A0A5K4EQ32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ32"
  },
  {
    "uniprot": "A0A5K4EGI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGI1"
  },
  {
    "uniprot": "A0A5K4F6E8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6E8"
  },
  {
    "uniprot": "A0A3Q0KMW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMW3"
  },
  {
    "uniprot": "A0A5K4F4Z3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Z3"
  },
  {
    "uniprot": "A0A5K4F848",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F848"
  },
  {
    "uniprot": "A0A3Q0KKA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKA3"
  },
  {
    "uniprot": "A0A5K4F1T0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1T0"
  },
  {
    "uniprot": "A0A5K4F802",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F802"
  },
  {
    "uniprot": "A0A5K4EAL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAL7"
  },
  {
    "uniprot": "A0A5K4EA65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EA65"
  },
  {
    "uniprot": "A0A3Q0KDZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDZ3"
  },
  {
    "uniprot": "A0A5K4F425",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F425"
  },
  {
    "uniprot": "G4VB92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB92"
  },
  {
    "uniprot": "A0A3Q0KN95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN95"
  },
  {
    "uniprot": "A0A3Q0KHC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHC9"
  },
  {
    "uniprot": "A0A3Q0KKZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKZ7"
  },
  {
    "uniprot": "P14202",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P14202"
  },
  {
    "uniprot": "G4VQT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQT3"
  },
  {
    "uniprot": "G4VR59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR59"
  },
  {
    "uniprot": "A0A5K4FC82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC82"
  },
  {
    "uniprot": "A0A5K4E9N8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9N8"
  },
  {
    "uniprot": "A0A3Q0KTJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTJ4"
  },
  {
    "uniprot": "A0A5K4FC95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC95"
  },
  {
    "uniprot": "C4QM24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QM24"
  },
  {
    "uniprot": "A0A5K4FAW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAW4"
  },
  {
    "uniprot": "G4LZ82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ82"
  },
  {
    "uniprot": "G4V7W8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7W8"
  },
  {
    "uniprot": "G4VFH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFH7"
  },
  {
    "uniprot": "A0A5K4F7K3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7K3"
  },
  {
    "uniprot": "A0A3Q0KM91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM91"
  },
  {
    "uniprot": "A0A3Q0KR99",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR99"
  },
  {
    "uniprot": "A0A3Q0KR74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR74"
  },
  {
    "uniprot": "G4V8L9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8L9"
  },
  {
    "uniprot": "A0A5K4F3Z5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Z5"
  },
  {
    "uniprot": "A0A3Q0KD71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD71"
  },
  {
    "uniprot": "A0A5K4F4U0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4U0"
  },
  {
    "uniprot": "A0A3Q0KIQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIQ1"
  },
  {
    "uniprot": "A0A5K4F727",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F727"
  },
  {
    "uniprot": "G4VFU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFU5"
  },
  {
    "uniprot": "A0A3Q0KJK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJK2"
  },
  {
    "uniprot": "A0A5K4FCW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCW0"
  },
  {
    "uniprot": "A0A3Q0KUF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUF5"
  },
  {
    "uniprot": "A0A5K4ESS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESS3"
  },
  {
    "uniprot": "A0A5K4FFQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFQ3"
  },
  {
    "uniprot": "G4VS25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS25"
  },
  {
    "uniprot": "A0A5K4F2H5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2H5"
  },
  {
    "uniprot": "A0A3Q0KS46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS46"
  },
  {
    "uniprot": "A0A5K4ERV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERV5"
  },
  {
    "uniprot": "A0A3Q0KSP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSP6"
  },
  {
    "uniprot": "A0A5K4F2Y1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Y1"
  },
  {
    "uniprot": "A0A5K4F146",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F146"
  },
  {
    "uniprot": "A0A5K4EZN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZN9"
  },
  {
    "uniprot": "A0A5K4EDV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDV3"
  },
  {
    "uniprot": "A0A5K4F0H9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0H9"
  },
  {
    "uniprot": "A0A5K4FEK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEK8"
  },
  {
    "uniprot": "A0A5K4ER57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER57"
  },
  {
    "uniprot": "A0A3Q0KTE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTE4"
  },
  {
    "uniprot": "A0A5K4F036",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F036"
  },
  {
    "uniprot": "A0A3Q0KHF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHF1"
  },
  {
    "uniprot": "A0A5K4FBH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBH1"
  },
  {
    "uniprot": "A0A5K4FG60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FG60"
  },
  {
    "uniprot": "A0A3Q0KUM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUM8"
  },
  {
    "uniprot": "A0A5K4F9L6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9L6"
  },
  {
    "uniprot": "A0A3Q0KC66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC66"
  },
  {
    "uniprot": "A0A5K4EZ89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ89"
  },
  {
    "uniprot": "A0A3Q0KDK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDK8"
  },
  {
    "uniprot": "A0A5K4F3W9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3W9"
  },
  {
    "uniprot": "A0A3Q0KV28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV28"
  },
  {
    "uniprot": "A0A5K4F3S7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3S7"
  },
  {
    "uniprot": "A0A5K4EV89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV89"
  },
  {
    "uniprot": "G4VKG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKG8"
  },
  {
    "uniprot": "G4VNG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNG8"
  },
  {
    "uniprot": "G4VIV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIV5"
  },
  {
    "uniprot": "A0A5K4F5M8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5M8"
  },
  {
    "uniprot": "Q15ET2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q15ET2"
  },
  {
    "uniprot": "A0A5K4F4Z5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Z5"
  },
  {
    "uniprot": "A0A5K4E9G7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9G7"
  },
  {
    "uniprot": "A0A3Q0KQU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQU0"
  },
  {
    "uniprot": "A0A3Q0KM24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM24"
  },
  {
    "uniprot": "A0A5K4FG17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FG17"
  },
  {
    "uniprot": "A0A5K4F9H3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9H3"
  },
  {
    "uniprot": "A0A3Q0KM10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM10"
  },
  {
    "uniprot": "A0A5K4EQL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQL6"
  },
  {
    "uniprot": "A0A3Q0KLF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLF1"
  },
  {
    "uniprot": "A0A3Q0KGM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGM0"
  },
  {
    "uniprot": "G4VBL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBL2"
  },
  {
    "uniprot": "A0A5K4EXS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXS3"
  },
  {
    "uniprot": "A0A3Q0KLF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLF9"
  },
  {
    "uniprot": "G4LWI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWI3"
  },
  {
    "uniprot": "G4VPZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPZ1"
  },
  {
    "uniprot": "A0A5K4FDJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDJ9"
  },
  {
    "uniprot": "G4LWL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWL3"
  },
  {
    "uniprot": "A0A5K4EFY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFY9"
  },
  {
    "uniprot": "A0A5K4ECF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECF6"
  },
  {
    "uniprot": "G4VIA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIA6"
  },
  {
    "uniprot": "A0A3Q0KVC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVC7"
  },
  {
    "uniprot": "A0A5K4F977",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F977"
  },
  {
    "uniprot": "A0A5K4F513",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F513"
  },
  {
    "uniprot": "A0A5K4EWU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWU6"
  },
  {
    "uniprot": "A0A5K4F6H3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6H3"
  },
  {
    "uniprot": "A0A3Q0KMK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMK3"
  },
  {
    "uniprot": "A0A5K4FDE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDE8"
  },
  {
    "uniprot": "C4QPU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QPU2"
  },
  {
    "uniprot": "G4V9S8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9S8"
  },
  {
    "uniprot": "A0A5K4F8G5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8G5"
  },
  {
    "uniprot": "G4V9V5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9V5"
  },
  {
    "uniprot": "A0A5K4FAD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAD0"
  },
  {
    "uniprot": "G4VGC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGC6"
  },
  {
    "uniprot": "A0A3Q0KQD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQD1"
  },
  {
    "uniprot": "A0A5K4F7J1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7J1"
  },
  {
    "uniprot": "G4VCF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCF5"
  },
  {
    "uniprot": "A0A3Q0KNW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNW7"
  },
  {
    "uniprot": "A0A5K4ENU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENU1"
  },
  {
    "uniprot": "A0A3Q0KNF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNF8"
  },
  {
    "uniprot": "G4LYG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYG3"
  },
  {
    "uniprot": "A0A5K4F417",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F417"
  },
  {
    "uniprot": "A0A5K4F4W7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4W7"
  },
  {
    "uniprot": "A0A3Q0KST4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KST4"
  },
  {
    "uniprot": "A0A3Q0KTJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTJ3"
  },
  {
    "uniprot": "A0A5K4FBH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBH4"
  },
  {
    "uniprot": "A0A5K4F842",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F842"
  },
  {
    "uniprot": "A0A5K4FCU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCU0"
  },
  {
    "uniprot": "A0A5K4F6Z3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Z3"
  },
  {
    "uniprot": "A0A3Q0KGF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGF5"
  },
  {
    "uniprot": "A0A3Q0KLH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLH5"
  },
  {
    "uniprot": "Q7Z157",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q7Z157"
  },
  {
    "uniprot": "A0A3Q0KC37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC37"
  },
  {
    "uniprot": "A0A3Q0KDM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDM4"
  },
  {
    "uniprot": "A0A5K4EL53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL53"
  },
  {
    "uniprot": "A0A3Q0KF40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF40"
  },
  {
    "uniprot": "A0A5K4EZC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZC2"
  },
  {
    "uniprot": "A0A5K4F1A8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1A8"
  },
  {
    "uniprot": "A0A5K4FEL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEL0"
  },
  {
    "uniprot": "A0A5K4E8E8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8E8"
  },
  {
    "uniprot": "G4V8N6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8N6"
  },
  {
    "uniprot": "G4V605",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V605"
  },
  {
    "uniprot": "A0A5K4FCF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCF1"
  },
  {
    "uniprot": "A0A5K4F7Q8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Q8"
  },
  {
    "uniprot": "A0A3Q0KI67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI67"
  },
  {
    "uniprot": "A0A5K4EXB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXB0"
  },
  {
    "uniprot": "A0A5K4ETA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETA2"
  },
  {
    "uniprot": "G4VNZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNZ4"
  },
  {
    "uniprot": "A0A3Q0KKX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKX5"
  },
  {
    "uniprot": "A0A5K4ETF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETF7"
  },
  {
    "uniprot": "A0A5K4FBY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBY0"
  },
  {
    "uniprot": "A0A5K4EEY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEY6"
  },
  {
    "uniprot": "A0A3Q0KQ28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ28"
  },
  {
    "uniprot": "A0A3Q0KME3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KME3"
  },
  {
    "uniprot": "A0A5K4F496",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F496"
  },
  {
    "uniprot": "A0A5K4F1X3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1X3"
  },
  {
    "uniprot": "A0A5K4F5Z3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Z3"
  },
  {
    "uniprot": "A0A5K4EJ84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJ84"
  },
  {
    "uniprot": "G4V6I0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6I0"
  },
  {
    "uniprot": "A0A3Q0KLY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLY5"
  },
  {
    "uniprot": "A0A5K4F1H4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1H4"
  },
  {
    "uniprot": "A0A3Q0KRB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRB0"
  },
  {
    "uniprot": "A0A5K4F0Y5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Y5"
  },
  {
    "uniprot": "A0A3Q0KRS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRS4"
  },
  {
    "uniprot": "A0A5K4EPL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPL3"
  },
  {
    "uniprot": "A0A3Q0KHK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHK7"
  },
  {
    "uniprot": "G4VDQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDQ1"
  },
  {
    "uniprot": "A0A3Q0KRI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRI6"
  },
  {
    "uniprot": "A0A5K4F8C1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8C1"
  },
  {
    "uniprot": "A0A3Q0KEC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEC0"
  },
  {
    "uniprot": "A0A3Q0KRW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRW6"
  },
  {
    "uniprot": "A0A5K4EZF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZF8"
  },
  {
    "uniprot": "A0A3Q0KUD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUD5"
  },
  {
    "uniprot": "G4VR95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR95"
  },
  {
    "uniprot": "A0A5K4F9I9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9I9"
  },
  {
    "uniprot": "G4VRU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRU6"
  },
  {
    "uniprot": "A0A5K4FAE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAE1"
  },
  {
    "uniprot": "A0A5K4EXD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXD4"
  },
  {
    "uniprot": "A0A3Q0KP55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP55"
  },
  {
    "uniprot": "A0A5K4EUC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUC4"
  },
  {
    "uniprot": "A0A5K4F9C0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9C0"
  },
  {
    "uniprot": "A0A3Q0KJ06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ06"
  },
  {
    "uniprot": "A0A5K4EM06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM06"
  },
  {
    "uniprot": "G4M0Z8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0Z8"
  },
  {
    "uniprot": "A0A5K4E892",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E892"
  },
  {
    "uniprot": "A0A5K4ED06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED06"
  },
  {
    "uniprot": "A0A5K4F061",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F061"
  },
  {
    "uniprot": "A0A5K4ETT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETT0"
  },
  {
    "uniprot": "C4QKQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QKQ4"
  },
  {
    "uniprot": "G4V5S1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5S1"
  },
  {
    "uniprot": "A0A5K4EA31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EA31"
  },
  {
    "uniprot": "G4VEE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEE0"
  },
  {
    "uniprot": "A0A5K4FAX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAX3"
  },
  {
    "uniprot": "A0A3Q0KPH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPH9"
  },
  {
    "uniprot": "A0A3Q0KE04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE04"
  },
  {
    "uniprot": "A0A5K4FE45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE45"
  },
  {
    "uniprot": "A0A5K4F3S8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3S8"
  },
  {
    "uniprot": "A0A3Q0KDP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDP5"
  },
  {
    "uniprot": "A0A5K4FA76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA76"
  },
  {
    "uniprot": "G4VME0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VME0"
  },
  {
    "uniprot": "A0A3Q0KNH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNH2"
  },
  {
    "uniprot": "A0A5K4F8H7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8H7"
  },
  {
    "uniprot": "A0A3Q0KK09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK09"
  },
  {
    "uniprot": "A0A5K4EP99",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP99"
  },
  {
    "uniprot": "A0A3Q0KVG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVG3"
  },
  {
    "uniprot": "A0A5K4EZI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZI6"
  },
  {
    "uniprot": "A0A5K4F8P4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8P4"
  },
  {
    "uniprot": "A0A5K4F0X5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0X5"
  },
  {
    "uniprot": "A0A5K4EV34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV34"
  },
  {
    "uniprot": "G4VEU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEU8"
  },
  {
    "uniprot": "A0A5K4EM63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM63"
  },
  {
    "uniprot": "A0A5K4ES83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES83"
  },
  {
    "uniprot": "G4V8G4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8G4"
  },
  {
    "uniprot": "A0A5K4ERE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERE6"
  },
  {
    "uniprot": "A0A3Q0KS29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS29"
  },
  {
    "uniprot": "A0A3Q0KLV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLV9"
  },
  {
    "uniprot": "A0A3Q0KQD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQD0"
  },
  {
    "uniprot": "A0A3Q0KUD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUD3"
  },
  {
    "uniprot": "A0A3Q0KKW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKW2"
  },
  {
    "uniprot": "A0A3Q0KQS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQS1"
  },
  {
    "uniprot": "A0A5K4EJR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJR6"
  },
  {
    "uniprot": "A0A3Q0KM21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM21"
  },
  {
    "uniprot": "A0A5K4FF50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF50"
  },
  {
    "uniprot": "A0A5K4ELJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELJ5"
  },
  {
    "uniprot": "A0A5K4F4P3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4P3"
  },
  {
    "uniprot": "A0A5K4FD54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD54"
  },
  {
    "uniprot": "A0A3Q0KUC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUC6"
  },
  {
    "uniprot": "A0A5K4EEQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEQ3"
  },
  {
    "uniprot": "A0A5K4F410",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F410"
  },
  {
    "uniprot": "A0A3Q0KRZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRZ2"
  },
  {
    "uniprot": "A0A3Q0KCS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCS8"
  },
  {
    "uniprot": "A0A5K4ELI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELI2"
  },
  {
    "uniprot": "A0A3Q0KK85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK85"
  },
  {
    "uniprot": "A0A5K4FCF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCF2"
  },
  {
    "uniprot": "A0A5K4EUJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUJ1"
  },
  {
    "uniprot": "A0A5K4ET78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET78"
  },
  {
    "uniprot": "A0A5K4F0K6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0K6"
  },
  {
    "uniprot": "A0A3Q0KHN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHN9"
  },
  {
    "uniprot": "A0A3Q0KGT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGT1"
  },
  {
    "uniprot": "A0A3Q0KNB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNB3"
  },
  {
    "uniprot": "A0A5K4F7I8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7I8"
  },
  {
    "uniprot": "A0A3Q0KSK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSK6"
  },
  {
    "uniprot": "G4VAD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAD2"
  },
  {
    "uniprot": "A0A5K4F0D7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0D7"
  },
  {
    "uniprot": "G4VB10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB10"
  },
  {
    "uniprot": "A0A3Q0KRG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRG9"
  },
  {
    "uniprot": "A0A5K4F1W8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1W8"
  },
  {
    "uniprot": "A0A3Q0KBG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBG0"
  },
  {
    "uniprot": "A0A3Q0KEA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEA7"
  },
  {
    "uniprot": "A0A5K4FAW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAW9"
  },
  {
    "uniprot": "A0A5K4F3P3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3P3"
  },
  {
    "uniprot": "A0A5K4FGZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGZ1"
  },
  {
    "uniprot": "A0A3Q0KG84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG84"
  },
  {
    "uniprot": "A0A5K4EUY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUY5"
  },
  {
    "uniprot": "A0A3Q0KLM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLM9"
  },
  {
    "uniprot": "A0A5K4ET13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET13"
  },
  {
    "uniprot": "A0A3Q0KRU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRU5"
  },
  {
    "uniprot": "C4Q748",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q748"
  },
  {
    "uniprot": "G4M1D0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1D0"
  },
  {
    "uniprot": "A0A3Q0KLW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLW0"
  },
  {
    "uniprot": "A0A5K4EKW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKW3"
  },
  {
    "uniprot": "A0A5K4EW59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW59"
  },
  {
    "uniprot": "A0A5K4FBP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBP5"
  },
  {
    "uniprot": "A0A5K4F2D4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2D4"
  },
  {
    "uniprot": "A0A3Q0KQM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQM4"
  },
  {
    "uniprot": "A0A3Q0KC77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC77"
  },
  {
    "uniprot": "A0A5K4ENN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENN3"
  },
  {
    "uniprot": "A0A3Q0KLV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLV1"
  },
  {
    "uniprot": "A0A3Q0KN92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN92"
  },
  {
    "uniprot": "A0A5K4F236",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F236"
  },
  {
    "uniprot": "A0A5K4F5D0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5D0"
  },
  {
    "uniprot": "G4V8J3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8J3"
  },
  {
    "uniprot": "A0A3Q0KP10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP10"
  },
  {
    "uniprot": "A0A5K4EFR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFR6"
  },
  {
    "uniprot": "A0A5K4FB81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB81"
  },
  {
    "uniprot": "A0A3Q0KLR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLR3"
  },
  {
    "uniprot": "A0A5K4F0Z7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Z7"
  },
  {
    "uniprot": "G4LXB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXB6"
  },
  {
    "uniprot": "A0A5K4F8K6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8K6"
  },
  {
    "uniprot": "A0A3Q0KLN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLN3"
  },
  {
    "uniprot": "A0A5K4FBS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBS0"
  },
  {
    "uniprot": "G4VKC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKC6"
  },
  {
    "uniprot": "Q27778",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q27778"
  },
  {
    "uniprot": "A0A3Q0KCE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCE3"
  },
  {
    "uniprot": "A0A3Q0KRA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRA2"
  },
  {
    "uniprot": "A0A5K4F8U9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8U9"
  },
  {
    "uniprot": "A0A3Q0KPQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPQ6"
  },
  {
    "uniprot": "A0A5K4ERM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERM7"
  },
  {
    "uniprot": "A0A3Q0KRZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRZ9"
  },
  {
    "uniprot": "A0A5K4FB18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB18"
  },
  {
    "uniprot": "A0A3Q0KTH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTH6"
  },
  {
    "uniprot": "A0A5K4FDG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDG5"
  },
  {
    "uniprot": "A0A5K4F914",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F914"
  },
  {
    "uniprot": "G4V9H6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9H6"
  },
  {
    "uniprot": "A0A5K4FF01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF01"
  },
  {
    "uniprot": "A0A5K4F9K9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9K9"
  },
  {
    "uniprot": "A0A3Q0KJX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJX9"
  },
  {
    "uniprot": "A0A3Q0KET0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KET0"
  },
  {
    "uniprot": "A0A5K4FAQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAQ6"
  },
  {
    "uniprot": "G4V689",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V689"
  },
  {
    "uniprot": "G4VJB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJB8"
  },
  {
    "uniprot": "A0A5K4F0S6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0S6"
  },
  {
    "uniprot": "A0A5K4F257",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F257"
  },
  {
    "uniprot": "A0A5K4F6E7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6E7"
  },
  {
    "uniprot": "A0A5K4FEG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEG8"
  },
  {
    "uniprot": "A0A5K4F125",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F125"
  },
  {
    "uniprot": "G4LVB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVB6"
  },
  {
    "uniprot": "A0A5K4FCP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCP8"
  },
  {
    "uniprot": "G4VLA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLA4"
  },
  {
    "uniprot": "G4VHN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHN1"
  },
  {
    "uniprot": "A0A5K4EWW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWW4"
  },
  {
    "uniprot": "A0A3Q0KMI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMI4"
  },
  {
    "uniprot": "A0A5K4F6E9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6E9"
  },
  {
    "uniprot": "A0A5K4FEU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEU2"
  },
  {
    "uniprot": "A0A3Q0KQW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQW9"
  },
  {
    "uniprot": "A0A3Q0KTK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTK8"
  },
  {
    "uniprot": "G4LYJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYJ4"
  },
  {
    "uniprot": "A0A3Q0KRW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRW0"
  },
  {
    "uniprot": "A0A3Q0KQ89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ89"
  },
  {
    "uniprot": "A0A5K4E9B3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9B3"
  },
  {
    "uniprot": "A0A5K4F325",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F325"
  },
  {
    "uniprot": "G4LVT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVT9"
  },
  {
    "uniprot": "A0A3Q0KHV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHV1"
  },
  {
    "uniprot": "A0A5K4FEF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEF9"
  },
  {
    "uniprot": "A0A5K4F892",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F892"
  },
  {
    "uniprot": "A0A5K4FC00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC00"
  },
  {
    "uniprot": "A0A3Q0KNQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNQ8"
  },
  {
    "uniprot": "A0A3Q0KTA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTA0"
  },
  {
    "uniprot": "A0A5K4ETQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETQ4"
  },
  {
    "uniprot": "A0A5K4FEN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEN7"
  },
  {
    "uniprot": "A0A3Q0KNF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNF1"
  },
  {
    "uniprot": "G4VCF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCF8"
  },
  {
    "uniprot": "A0A5K4F1I3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1I3"
  },
  {
    "uniprot": "A0A3Q0KDL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDL3"
  },
  {
    "uniprot": "A0A3Q0KIG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIG2"
  },
  {
    "uniprot": "A0A5K4F9Y4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Y4"
  },
  {
    "uniprot": "G4LZX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZX5"
  },
  {
    "uniprot": "A0A5K4EM66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM66"
  },
  {
    "uniprot": "A0A5K4F5S0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5S0"
  },
  {
    "uniprot": "A0A3Q0KPD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPD0"
  },
  {
    "uniprot": "P91804",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P91804"
  },
  {
    "uniprot": "A0A5K4F7T4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7T4"
  },
  {
    "uniprot": "A0A3Q0KQZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQZ8"
  },
  {
    "uniprot": "A0A3Q0KT97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT97"
  },
  {
    "uniprot": "A0A3Q0KSL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSL0"
  },
  {
    "uniprot": "A0A5K4FBY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBY3"
  },
  {
    "uniprot": "A0A3Q0KHN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHN6"
  },
  {
    "uniprot": "A0A3Q0KTR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTR1"
  },
  {
    "uniprot": "A0A3Q0KT83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT83"
  },
  {
    "uniprot": "A0A5K4FBH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBH7"
  },
  {
    "uniprot": "A0A5K4F8A8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8A8"
  },
  {
    "uniprot": "G4LWW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWW2"
  },
  {
    "uniprot": "A0A5K4EV57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV57"
  },
  {
    "uniprot": "A0A3Q0KMY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMY7"
  },
  {
    "uniprot": "A0A5K4ERB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERB3"
  },
  {
    "uniprot": "A0A5K4F9D6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9D6"
  },
  {
    "uniprot": "A0A5K4F161",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F161"
  },
  {
    "uniprot": "A0A5K4EAM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAM3"
  },
  {
    "uniprot": "A0A3Q0KV14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV14"
  },
  {
    "uniprot": "A0A5K4FF09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF09"
  },
  {
    "uniprot": "A0A5K4FCI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCI4"
  },
  {
    "uniprot": "A0A3Q0KSK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSK0"
  },
  {
    "uniprot": "G4V795",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V795"
  },
  {
    "uniprot": "A0A3Q0KLX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLX7"
  },
  {
    "uniprot": "A0A5K4FAC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAC9"
  },
  {
    "uniprot": "A0A5K4ETX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETX1"
  },
  {
    "uniprot": "A0A5K4FAQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAQ8"
  },
  {
    "uniprot": "A0A5K4FA31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA31"
  },
  {
    "uniprot": "A0A3Q0KGL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGL5"
  },
  {
    "uniprot": "A0A5K4F028",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F028"
  },
  {
    "uniprot": "G4VMB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMB5"
  },
  {
    "uniprot": "A0A3Q0KFF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFF4"
  },
  {
    "uniprot": "A0A3Q0KTL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTL2"
  },
  {
    "uniprot": "A0A5K4F684",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F684"
  },
  {
    "uniprot": "A0A3Q0KGM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGM3"
  },
  {
    "uniprot": "A0A5K4F3E2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3E2"
  },
  {
    "uniprot": "A0A3Q0KQB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQB7"
  },
  {
    "uniprot": "G4VAX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAX3"
  },
  {
    "uniprot": "A0A5K4F4J1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4J1"
  },
  {
    "uniprot": "A0A3Q0KDH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDH1"
  },
  {
    "uniprot": "A0A5K4ENV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENV8"
  },
  {
    "uniprot": "A0A5K4FFE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFE2"
  },
  {
    "uniprot": "A0A5K4FFP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFP1"
  },
  {
    "uniprot": "A0A5K4EPP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPP9"
  },
  {
    "uniprot": "A0A5K4F0S7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0S7"
  },
  {
    "uniprot": "A0A3Q0KID8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KID8"
  },
  {
    "uniprot": "A0A3Q0KNF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNF7"
  },
  {
    "uniprot": "A0A5K4F168",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F168"
  },
  {
    "uniprot": "A0A3Q0KH73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH73"
  },
  {
    "uniprot": "A0A5K4ERI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERI8"
  },
  {
    "uniprot": "G4VRC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRC9"
  },
  {
    "uniprot": "A0A3Q0KC52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC52"
  },
  {
    "uniprot": "A0A5K4EEH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEH2"
  },
  {
    "uniprot": "A0A5K4FBV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBV4"
  },
  {
    "uniprot": "A0A5K4FBK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBK9"
  },
  {
    "uniprot": "A0A5K4F2B4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2B4"
  },
  {
    "uniprot": "A0A3Q0KG72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG72"
  },
  {
    "uniprot": "A0A3Q0KMV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMV2"
  },
  {
    "uniprot": "A0A5K4FC36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC36"
  },
  {
    "uniprot": "A0A5K4F2C0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2C0"
  },
  {
    "uniprot": "A0A5K4F4G7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4G7"
  },
  {
    "uniprot": "A0A5K4F2Z3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Z3"
  },
  {
    "uniprot": "G4LZZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZZ6"
  },
  {
    "uniprot": "A0A5K4F7F6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7F6"
  },
  {
    "uniprot": "G4VPT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPT4"
  },
  {
    "uniprot": "A0A5K4EHM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHM1"
  },
  {
    "uniprot": "G4V933",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V933"
  },
  {
    "uniprot": "A0A5K4F7P5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7P5"
  },
  {
    "uniprot": "A0A3Q0KTU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTU5"
  },
  {
    "uniprot": "A0A5K4E992",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E992"
  },
  {
    "uniprot": "A0A5K4EUY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUY8"
  },
  {
    "uniprot": "A0A5K4ESX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESX8"
  },
  {
    "uniprot": "A0A3Q0KNV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNV6"
  },
  {
    "uniprot": "A0A3Q0KLN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLN4"
  },
  {
    "uniprot": "A0A5K4FBV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBV5"
  },
  {
    "uniprot": "G4V5Q1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5Q1"
  },
  {
    "uniprot": "A0A5K4FDV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDV3"
  },
  {
    "uniprot": "A0A5K4EG01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EG01"
  },
  {
    "uniprot": "A0A5K4FAL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAL2"
  },
  {
    "uniprot": "A0A5K4FBS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBS2"
  },
  {
    "uniprot": "A0A5K4ECS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECS9"
  },
  {
    "uniprot": "A0A5K4F9Q8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Q8"
  },
  {
    "uniprot": "A0A5K4EN77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN77"
  },
  {
    "uniprot": "A0A5K4FFP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFP9"
  },
  {
    "uniprot": "A0A3Q0KRH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRH4"
  },
  {
    "uniprot": "A0A3Q0KU73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU73"
  },
  {
    "uniprot": "A0A5K4F5U2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5U2"
  },
  {
    "uniprot": "A0A5K4FAH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAH3"
  },
  {
    "uniprot": "A0A3Q0KLP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLP8"
  },
  {
    "uniprot": "A0A5K4FCA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCA9"
  },
  {
    "uniprot": "A0A5K4FAJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAJ7"
  },
  {
    "uniprot": "A0A5K4EPG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPG7"
  },
  {
    "uniprot": "A0A3Q0KD38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD38"
  },
  {
    "uniprot": "A0A5K4ERR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERR4"
  },
  {
    "uniprot": "A0A5K4ELH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELH7"
  },
  {
    "uniprot": "G4LYI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYI4"
  },
  {
    "uniprot": "A0A3Q0KSW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSW1"
  },
  {
    "uniprot": "A0A3Q0KFL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFL0"
  },
  {
    "uniprot": "G4VQZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQZ7"
  },
  {
    "uniprot": "A0A5K4FDV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDV7"
  },
  {
    "uniprot": "A0A3Q0KP56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP56"
  },
  {
    "uniprot": "G4VL53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VL53"
  },
  {
    "uniprot": "A0A3Q0KTR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTR6"
  },
  {
    "uniprot": "A0A5K4F3I4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3I4"
  },
  {
    "uniprot": "A0A5K4F8D9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8D9"
  },
  {
    "uniprot": "A0A5K4EJ90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJ90"
  },
  {
    "uniprot": "A0A5K4EY92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY92"
  },
  {
    "uniprot": "A0A5K4FFV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFV5"
  },
  {
    "uniprot": "A0A3Q0KGB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGB9"
  },
  {
    "uniprot": "A0A3Q0KLM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLM3"
  },
  {
    "uniprot": "A0A5K4ENT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENT0"
  },
  {
    "uniprot": "A0A5K4EAI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAI3"
  },
  {
    "uniprot": "A0A3Q0KMH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMH9"
  },
  {
    "uniprot": "A0A5K4F9J7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9J7"
  },
  {
    "uniprot": "A0A3Q0KD13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD13"
  },
  {
    "uniprot": "A0A5K4FC88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC88"
  },
  {
    "uniprot": "A0A3Q0KUF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUF4"
  },
  {
    "uniprot": "A0A5K4ENI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENI8"
  },
  {
    "uniprot": "G4V8Y9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8Y9"
  },
  {
    "uniprot": "A0A5K4EPD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPD4"
  },
  {
    "uniprot": "G4VJ11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ11"
  },
  {
    "uniprot": "A0A5K4EM71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM71"
  },
  {
    "uniprot": "A0A3Q0KNF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNF2"
  },
  {
    "uniprot": "Q4KXQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q4KXQ2"
  },
  {
    "uniprot": "A0A5K4E9N2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9N2"
  },
  {
    "uniprot": "A0A5K4F8H1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8H1"
  },
  {
    "uniprot": "A0A5K4FCQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCQ2"
  },
  {
    "uniprot": "A0A5K4F374",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F374"
  },
  {
    "uniprot": "A0A5K4F2M6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2M6"
  },
  {
    "uniprot": "G4VHN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHN9"
  },
  {
    "uniprot": "A0A5K4E9S9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9S9"
  },
  {
    "uniprot": "A0A3Q0KR19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR19"
  },
  {
    "uniprot": "A0A5K4EQ61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ61"
  },
  {
    "uniprot": "A0A5K4F0L6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0L6"
  },
  {
    "uniprot": "A0A3Q0KJE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJE7"
  },
  {
    "uniprot": "A0A3Q0KSC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSC9"
  },
  {
    "uniprot": "A0A5K4F563",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F563"
  },
  {
    "uniprot": "G4LYM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYM0"
  },
  {
    "uniprot": "G4M232",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M232"
  },
  {
    "uniprot": "A0A5K4F1L5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1L5"
  },
  {
    "uniprot": "A0A146MHE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MHE5"
  },
  {
    "uniprot": "A0A5K4EGZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGZ7"
  },
  {
    "uniprot": "A0A5K4FFI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFI5"
  },
  {
    "uniprot": "A0A5K4FDT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDT2"
  },
  {
    "uniprot": "G4LXP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXP9"
  },
  {
    "uniprot": "A0A3Q0KNF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNF4"
  },
  {
    "uniprot": "A0A3Q0KCT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCT5"
  },
  {
    "uniprot": "A0A5K4EAN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAN1"
  },
  {
    "uniprot": "A0A5K4F267",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F267"
  },
  {
    "uniprot": "A0A5K4F764",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F764"
  },
  {
    "uniprot": "A0A3Q0KTK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTK7"
  },
  {
    "uniprot": "A0A3Q0KUT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUT5"
  },
  {
    "uniprot": "A0A5K4ED45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED45"
  },
  {
    "uniprot": "A0A5K4F068",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F068"
  },
  {
    "uniprot": "A0A5K4FCM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCM5"
  },
  {
    "uniprot": "G4V734",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V734"
  },
  {
    "uniprot": "A0A5K4ENZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENZ8"
  },
  {
    "uniprot": "A0A3Q0KVJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVJ4"
  },
  {
    "uniprot": "A0A3Q0KS06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS06"
  },
  {
    "uniprot": "G4VBH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBH6"
  },
  {
    "uniprot": "A0A5K4FB65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB65"
  },
  {
    "uniprot": "G4V787",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V787"
  },
  {
    "uniprot": "G4VKB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKB7"
  },
  {
    "uniprot": "A0A3Q0KR40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR40"
  },
  {
    "uniprot": "A0A3Q0KM40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM40"
  },
  {
    "uniprot": "A0A5K4F5A4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5A4"
  },
  {
    "uniprot": "A0A5K4F868",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F868"
  },
  {
    "uniprot": "A0A3Q0KP30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP30"
  },
  {
    "uniprot": "A0A5K4EU43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU43"
  },
  {
    "uniprot": "A0A5K4ED79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED79"
  },
  {
    "uniprot": "A0A3Q0KLA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLA8"
  },
  {
    "uniprot": "A0A3Q0KD63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD63"
  },
  {
    "uniprot": "G4V9L4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9L4"
  },
  {
    "uniprot": "A0A3Q0KPG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPG0"
  },
  {
    "uniprot": "A0A5K4ELW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELW6"
  },
  {
    "uniprot": "A0A5K4ERH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERH1"
  },
  {
    "uniprot": "G4VFM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFM6"
  },
  {
    "uniprot": "A0A3Q0KMH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMH6"
  },
  {
    "uniprot": "A0A5K4F9A8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9A8"
  },
  {
    "uniprot": "A0A5K4ESM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESM8"
  },
  {
    "uniprot": "A0A3Q0KHA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHA9"
  },
  {
    "uniprot": "A0A3Q0KHB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHB6"
  },
  {
    "uniprot": "A0A3Q0KTV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTV3"
  },
  {
    "uniprot": "A0A3Q0KSY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSY6"
  },
  {
    "uniprot": "A0A3Q0KG51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG51"
  },
  {
    "uniprot": "A0A3Q0KDW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDW5"
  },
  {
    "uniprot": "A0A5K4F138",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F138"
  },
  {
    "uniprot": "A0A3Q0KUF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUF3"
  },
  {
    "uniprot": "A0A3Q0KRK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRK6"
  },
  {
    "uniprot": "A0A5K4ETY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETY1"
  },
  {
    "uniprot": "A0A3Q0KK73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK73"
  },
  {
    "uniprot": "A0A5K4F519",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F519"
  },
  {
    "uniprot": "A0A5K4F987",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F987"
  },
  {
    "uniprot": "A0A5K4EWC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWC8"
  },
  {
    "uniprot": "A0A3Q0KEJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEJ7"
  },
  {
    "uniprot": "G4VPZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPZ4"
  },
  {
    "uniprot": "G4VJL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJL9"
  },
  {
    "uniprot": "A0A5K4FC60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC60"
  },
  {
    "uniprot": "S5XLH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/S5XLH6"
  },
  {
    "uniprot": "A0A3Q0KT84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT84"
  },
  {
    "uniprot": "G4VBV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBV1"
  },
  {
    "uniprot": "A0A5K4F0E1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0E1"
  },
  {
    "uniprot": "G4V9R2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9R2"
  },
  {
    "uniprot": "A0A3Q0KSL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSL4"
  },
  {
    "uniprot": "G4V8Y8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8Y8"
  },
  {
    "uniprot": "A0A5K4EUG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUG3"
  },
  {
    "uniprot": "A0A5K4EZW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZW6"
  },
  {
    "uniprot": "A0A5K4EGN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGN8"
  },
  {
    "uniprot": "G4VC69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC69"
  },
  {
    "uniprot": "A0A3Q0KL38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL38"
  },
  {
    "uniprot": "A0A5K4F5H2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5H2"
  },
  {
    "uniprot": "G4LV59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV59"
  },
  {
    "uniprot": "A0A5K4FBZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBZ3"
  },
  {
    "uniprot": "G4VGR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGR5"
  },
  {
    "uniprot": "G4VB16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB16"
  },
  {
    "uniprot": "A0A3Q0KSM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSM2"
  },
  {
    "uniprot": "G4VDF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDF5"
  },
  {
    "uniprot": "A0A3Q0KUN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUN1"
  },
  {
    "uniprot": "A0A5K4ECP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECP8"
  },
  {
    "uniprot": "A0A3Q0KKR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKR7"
  },
  {
    "uniprot": "G4V8W3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8W3"
  },
  {
    "uniprot": "A0A5K4ER20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER20"
  },
  {
    "uniprot": "G4VRA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRA7"
  },
  {
    "uniprot": "A0A3Q0KNN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNN1"
  },
  {
    "uniprot": "A0A5K4F2N3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2N3"
  },
  {
    "uniprot": "A0A5K4ENZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENZ1"
  },
  {
    "uniprot": "A0A5K4FB84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB84"
  },
  {
    "uniprot": "A0A5K4FAA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAA2"
  },
  {
    "uniprot": "A0A3Q0KQM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQM0"
  },
  {
    "uniprot": "G4V7J6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7J6"
  },
  {
    "uniprot": "A0A5K4EVE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVE1"
  },
  {
    "uniprot": "A0A5K4F2Y3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Y3"
  },
  {
    "uniprot": "A0A5K4F526",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F526"
  },
  {
    "uniprot": "A0A3Q0KLA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLA5"
  },
  {
    "uniprot": "A0A5K4F4M2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4M2"
  },
  {
    "uniprot": "A0A3Q0KNZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNZ6"
  },
  {
    "uniprot": "A0A3Q0KUQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUQ9"
  },
  {
    "uniprot": "A0A5K4FG77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FG77"
  },
  {
    "uniprot": "A0A5K4ECK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECK1"
  },
  {
    "uniprot": "G4LWS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWS6"
  },
  {
    "uniprot": "A0A3Q0KSE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSE4"
  },
  {
    "uniprot": "A0A5K4ETV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETV0"
  },
  {
    "uniprot": "A0A5K4F749",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F749"
  },
  {
    "uniprot": "G4VCN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCN8"
  },
  {
    "uniprot": "G4LX43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX43"
  },
  {
    "uniprot": "A0A5K4EBC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBC5"
  },
  {
    "uniprot": "A0A3Q0KKT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKT7"
  },
  {
    "uniprot": "A0A3Q0KNE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNE3"
  },
  {
    "uniprot": "A0A5K4F062",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F062"
  },
  {
    "uniprot": "G4V794",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V794"
  },
  {
    "uniprot": "A0A5K4FBD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBD0"
  },
  {
    "uniprot": "A0A3Q0KT67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT67"
  },
  {
    "uniprot": "A0A5K4F739",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F739"
  },
  {
    "uniprot": "A0A5K4EYB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYB9"
  },
  {
    "uniprot": "A0A5K4F049",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F049"
  },
  {
    "uniprot": "A0A5K4F9G1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9G1"
  },
  {
    "uniprot": "A0A5K4FDE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDE4"
  },
  {
    "uniprot": "G4VEV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEV8"
  },
  {
    "uniprot": "A0A3Q0KLY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLY8"
  },
  {
    "uniprot": "A0A5K4EZI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZI5"
  },
  {
    "uniprot": "A0A3Q0KL85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL85"
  },
  {
    "uniprot": "A0A5K4F083",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F083"
  },
  {
    "uniprot": "A0A5K4EPH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPH1"
  },
  {
    "uniprot": "A0A5K4F099",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F099"
  },
  {
    "uniprot": "A0A5K4FBZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBZ6"
  },
  {
    "uniprot": "A0A3Q0KGP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGP3"
  },
  {
    "uniprot": "A0A5K4FA06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA06"
  },
  {
    "uniprot": "A0A5K4EXF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXF0"
  },
  {
    "uniprot": "A0A5K4F779",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F779"
  },
  {
    "uniprot": "A0A5K4F6A1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6A1"
  },
  {
    "uniprot": "A0A5K4F1C7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1C7"
  },
  {
    "uniprot": "A0A5K4FG55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FG55"
  },
  {
    "uniprot": "G4LX87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX87"
  },
  {
    "uniprot": "G4VRI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRI9"
  },
  {
    "uniprot": "A0A3Q0KUK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUK3"
  },
  {
    "uniprot": "A0A5K4ED71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED71"
  },
  {
    "uniprot": "A0A5K4FAI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAI9"
  },
  {
    "uniprot": "A0A5K4EK18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK18"
  },
  {
    "uniprot": "A0A3Q0KRX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRX6"
  },
  {
    "uniprot": "G4V869",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V869"
  },
  {
    "uniprot": "A0A5K4F0I6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0I6"
  },
  {
    "uniprot": "A0A3Q0KIT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIT8"
  },
  {
    "uniprot": "G4VJQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJQ3"
  },
  {
    "uniprot": "A0A3Q0KIM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIM4"
  },
  {
    "uniprot": "A0A3Q0KV33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV33"
  },
  {
    "uniprot": "A0A3Q0KQQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQQ1"
  },
  {
    "uniprot": "A0A3Q0KBP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBP6"
  },
  {
    "uniprot": "A0A5K4F7L7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7L7"
  },
  {
    "uniprot": "A0A5K4F6Q7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Q7"
  },
  {
    "uniprot": "G4VPB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPB1"
  },
  {
    "uniprot": "G4VJX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJX2"
  },
  {
    "uniprot": "A0A3Q0KP02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP02"
  },
  {
    "uniprot": "A0A3Q0KQZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQZ9"
  },
  {
    "uniprot": "A0A5K4FAF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAF2"
  },
  {
    "uniprot": "A0A5K4F8E7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8E7"
  },
  {
    "uniprot": "A0A5K4F6Z9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Z9"
  },
  {
    "uniprot": "A0A3Q0KEB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEB9"
  },
  {
    "uniprot": "C4QB42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QB42"
  },
  {
    "uniprot": "G4VGP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGP3"
  },
  {
    "uniprot": "G4LZY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZY0"
  },
  {
    "uniprot": "A0A5K4ENY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENY0"
  },
  {
    "uniprot": "A0A5K4EWQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWQ4"
  },
  {
    "uniprot": "A0A5K4EVC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVC0"
  },
  {
    "uniprot": "A0A5K4F3H4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3H4"
  },
  {
    "uniprot": "A0A3Q0KT91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT91"
  },
  {
    "uniprot": "A0A3Q0KQL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQL5"
  },
  {
    "uniprot": "A0A3Q0KVC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVC9"
  },
  {
    "uniprot": "A0A5K4FAV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAV2"
  },
  {
    "uniprot": "A0A5K4EWU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWU8"
  },
  {
    "uniprot": "A0A5K4EKL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKL7"
  },
  {
    "uniprot": "A0A5K4EQ18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ18"
  },
  {
    "uniprot": "G4M0D2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0D2"
  },
  {
    "uniprot": "G4VDQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDQ3"
  },
  {
    "uniprot": "A0A5K4F250",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F250"
  },
  {
    "uniprot": "A0A3Q0KKJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKJ6"
  },
  {
    "uniprot": "A0A5K4F7D7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7D7"
  },
  {
    "uniprot": "A0A3Q0KLD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLD7"
  },
  {
    "uniprot": "A0A5K4ERF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERF8"
  },
  {
    "uniprot": "A0A3Q0KQI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQI7"
  },
  {
    "uniprot": "A0A5K4EDY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDY1"
  },
  {
    "uniprot": "A0A5K4F5Y4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Y4"
  },
  {
    "uniprot": "A0A5K4EF28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EF28"
  },
  {
    "uniprot": "A0A3Q0KVJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVJ0"
  },
  {
    "uniprot": "A0A5K4FAK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAK2"
  },
  {
    "uniprot": "A0A5K4F3H6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3H6"
  },
  {
    "uniprot": "A0A5K4F931",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F931"
  },
  {
    "uniprot": "A0A5K4EPI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPI4"
  },
  {
    "uniprot": "A0A5K4F2I6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2I6"
  },
  {
    "uniprot": "A0A3Q0KPA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPA1"
  },
  {
    "uniprot": "A0A5K4F7Q3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Q3"
  },
  {
    "uniprot": "A0A3Q0KQP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQP5"
  },
  {
    "uniprot": "A0A3Q0KT79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT79"
  },
  {
    "uniprot": "A0A5K4ENQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENQ6"
  },
  {
    "uniprot": "C4QL66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QL66"
  },
  {
    "uniprot": "A0A5K4FEV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEV7"
  },
  {
    "uniprot": "A0A5K4EUI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUI8"
  },
  {
    "uniprot": "A0A3Q0KFJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFJ7"
  },
  {
    "uniprot": "A0A3Q0KM04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM04"
  },
  {
    "uniprot": "A0A3Q0KJ92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ92"
  },
  {
    "uniprot": "A0A3Q0KER6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KER6"
  },
  {
    "uniprot": "A0A5K4E978",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E978"
  },
  {
    "uniprot": "G4VB04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB04"
  },
  {
    "uniprot": "A0A5K4FD32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD32"
  },
  {
    "uniprot": "A0A3Q0KGS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGS9"
  },
  {
    "uniprot": "A0A5K4F881",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F881"
  },
  {
    "uniprot": "G4VR52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR52"
  },
  {
    "uniprot": "A0A5K4FA60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA60"
  },
  {
    "uniprot": "A0A3Q0KFR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFR5"
  },
  {
    "uniprot": "G4V790",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V790"
  },
  {
    "uniprot": "A0A3Q0KPU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPU7"
  },
  {
    "uniprot": "A0A5K4F6K1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6K1"
  },
  {
    "uniprot": "A0A3Q0KL41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL41"
  },
  {
    "uniprot": "G4VQU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQU6"
  },
  {
    "uniprot": "A0A3Q0KQN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQN0"
  },
  {
    "uniprot": "A0A5K4FD76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD76"
  },
  {
    "uniprot": "A0A3Q0KET5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KET5"
  },
  {
    "uniprot": "A0A3Q0KQS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQS2"
  },
  {
    "uniprot": "A0A5K4EDC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDC0"
  },
  {
    "uniprot": "A0A5K4FAG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAG3"
  },
  {
    "uniprot": "A0A5K4F9A7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9A7"
  },
  {
    "uniprot": "A0A5K4FB45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB45"
  },
  {
    "uniprot": "A0A5K4F7N6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7N6"
  },
  {
    "uniprot": "A0A5K4EB57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB57"
  },
  {
    "uniprot": "A0A3Q0KFU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFU4"
  },
  {
    "uniprot": "A0A3Q0KLC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLC7"
  },
  {
    "uniprot": "G4VAB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAB4"
  },
  {
    "uniprot": "A0A3Q0KQX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQX7"
  },
  {
    "uniprot": "A0A5K4F2L5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2L5"
  },
  {
    "uniprot": "G4V8V6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8V6"
  },
  {
    "uniprot": "A0A5K4F507",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F507"
  },
  {
    "uniprot": "A0A5K4EUV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUV8"
  },
  {
    "uniprot": "A0A3Q0KQR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQR6"
  },
  {
    "uniprot": "A0A3Q0KN32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN32"
  },
  {
    "uniprot": "G4LVB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVB5"
  },
  {
    "uniprot": "A0A5K4EKR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKR5"
  },
  {
    "uniprot": "A0A3Q0KHM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHM7"
  },
  {
    "uniprot": "A0A5K4EZ84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ84"
  },
  {
    "uniprot": "A0A5K4EK44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK44"
  },
  {
    "uniprot": "G4VHP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHP9"
  },
  {
    "uniprot": "A0A5K4F803",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F803"
  },
  {
    "uniprot": "A0A5K4ES70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES70"
  },
  {
    "uniprot": "A0A3Q0KJ27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ27"
  },
  {
    "uniprot": "A0A5K4EM38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM38"
  },
  {
    "uniprot": "A0A3Q0KJY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJY0"
  },
  {
    "uniprot": "A0A5K4F289",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F289"
  },
  {
    "uniprot": "A0A5K4FDN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDN4"
  },
  {
    "uniprot": "A0A3Q0KRC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRC8"
  },
  {
    "uniprot": "A0A5K4F0Q5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Q5"
  },
  {
    "uniprot": "A0A5K4FFY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFY7"
  },
  {
    "uniprot": "G4LYH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYH0"
  },
  {
    "uniprot": "A0A5K4F3A7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3A7"
  },
  {
    "uniprot": "A0A5K4EUC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUC0"
  },
  {
    "uniprot": "G4VK66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK66"
  },
  {
    "uniprot": "A0A5K4FCW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCW3"
  },
  {
    "uniprot": "A0A5K4F8Y1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Y1"
  },
  {
    "uniprot": "A0A3Q0KE28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE28"
  },
  {
    "uniprot": "A0A3Q0KC45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC45"
  },
  {
    "uniprot": "A0A5K4FD63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD63"
  },
  {
    "uniprot": "A0A3Q0KG27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG27"
  },
  {
    "uniprot": "A0A3Q0KCT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCT6"
  },
  {
    "uniprot": "A0A5K4FDZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDZ2"
  },
  {
    "uniprot": "A0A5K4EI27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EI27"
  },
  {
    "uniprot": "A0A5K4EIZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIZ7"
  },
  {
    "uniprot": "A0A5K4FCC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCC7"
  },
  {
    "uniprot": "G4VHT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHT8"
  },
  {
    "uniprot": "A0A3Q0KMK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMK5"
  },
  {
    "uniprot": "A0A5K4EU74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU74"
  },
  {
    "uniprot": "A0A5K4F9N2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9N2"
  },
  {
    "uniprot": "A0A3Q0KNU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNU4"
  },
  {
    "uniprot": "A0A5K4F8J5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8J5"
  },
  {
    "uniprot": "A0A5K4EPI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPI8"
  },
  {
    "uniprot": "A0A5K4EZK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZK6"
  },
  {
    "uniprot": "A0A5K4FC14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC14"
  },
  {
    "uniprot": "G4M0Q7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0Q7"
  },
  {
    "uniprot": "A0A3Q0KDM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDM6"
  },
  {
    "uniprot": "A0A3Q0KE60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE60"
  },
  {
    "uniprot": "A0A3Q0KLN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLN6"
  },
  {
    "uniprot": "A0A3Q0KQC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQC3"
  },
  {
    "uniprot": "G4VTG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTG7"
  },
  {
    "uniprot": "A0A3Q0KDK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDK6"
  },
  {
    "uniprot": "A0A3Q0KSH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSH3"
  },
  {
    "uniprot": "A0A3Q0KUK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUK7"
  },
  {
    "uniprot": "A0A3Q0KSU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSU3"
  },
  {
    "uniprot": "A0A5K4F481",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F481"
  },
  {
    "uniprot": "A0A5K4EUW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUW8"
  },
  {
    "uniprot": "G4VMW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMW9"
  },
  {
    "uniprot": "G4LVE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVE1"
  },
  {
    "uniprot": "G4V9S0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9S0"
  },
  {
    "uniprot": "G4VJN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJN6"
  },
  {
    "uniprot": "A0A3Q0KGT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGT7"
  },
  {
    "uniprot": "G4LZN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZN6"
  },
  {
    "uniprot": "G4LWE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWE7"
  },
  {
    "uniprot": "A0A5K4F9Y9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Y9"
  },
  {
    "uniprot": "A0A5K4E8T0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8T0"
  },
  {
    "uniprot": "A0A5K4ECV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECV7"
  },
  {
    "uniprot": "A0A3Q0KJN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJN6"
  },
  {
    "uniprot": "A0A5K4FBU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBU0"
  },
  {
    "uniprot": "A0A5K4EVJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVJ5"
  },
  {
    "uniprot": "G4VQ29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ29"
  },
  {
    "uniprot": "A0A3Q0KUK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUK8"
  },
  {
    "uniprot": "G4VJS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJS4"
  },
  {
    "uniprot": "A0A5K4EJL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJL2"
  },
  {
    "uniprot": "A0A3Q0KGP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGP4"
  },
  {
    "uniprot": "A0A3Q0KP19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP19"
  },
  {
    "uniprot": "A0A5K4EZQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZQ8"
  },
  {
    "uniprot": "A0A5K4EZR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZR2"
  },
  {
    "uniprot": "A0A5K4F4T0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4T0"
  },
  {
    "uniprot": "A0A5K4FDS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDS1"
  },
  {
    "uniprot": "A0A5K4FG50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FG50"
  },
  {
    "uniprot": "G4VDI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDI6"
  },
  {
    "uniprot": "A0A5K4F736",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F736"
  },
  {
    "uniprot": "G4V7H6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7H6"
  },
  {
    "uniprot": "A0A5K4FG26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FG26"
  },
  {
    "uniprot": "G4VPA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPA2"
  },
  {
    "uniprot": "A0A5K4ELR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELR8"
  },
  {
    "uniprot": "G4VT96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT96"
  },
  {
    "uniprot": "A0A5K4EGG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGG7"
  },
  {
    "uniprot": "A0A5K4F6I1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6I1"
  },
  {
    "uniprot": "A0A3Q0KUF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUF0"
  },
  {
    "uniprot": "A0A5K4FEC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEC7"
  },
  {
    "uniprot": "A0A5K4EGE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGE9"
  },
  {
    "uniprot": "A0A5K4FE25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE25"
  },
  {
    "uniprot": "G4VR92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR92"
  },
  {
    "uniprot": "A0A5K4F025",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F025"
  },
  {
    "uniprot": "A0A3Q0KTX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTX2"
  },
  {
    "uniprot": "A0A3Q0KKB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKB4"
  },
  {
    "uniprot": "A0A3Q0KLL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLL0"
  },
  {
    "uniprot": "G4VFE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFE1"
  },
  {
    "uniprot": "A0A5K4F8E9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8E9"
  },
  {
    "uniprot": "G4LWE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWE5"
  },
  {
    "uniprot": "A0A5K4EU61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU61"
  },
  {
    "uniprot": "A0A3Q0KEC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEC1"
  },
  {
    "uniprot": "G4VMZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMZ0"
  },
  {
    "uniprot": "A0A5K4EVE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVE0"
  },
  {
    "uniprot": "A0A5K4F136",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F136"
  },
  {
    "uniprot": "A0A3Q0KF56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF56"
  },
  {
    "uniprot": "A0A5K4EUT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUT0"
  },
  {
    "uniprot": "A0A5K4F5Y0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Y0"
  },
  {
    "uniprot": "A0A5K4EQX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQX5"
  },
  {
    "uniprot": "A0A5K4F3K0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3K0"
  },
  {
    "uniprot": "A0A5K4F7W2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7W2"
  },
  {
    "uniprot": "A0A5K4F7Q1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Q1"
  },
  {
    "uniprot": "A0A3Q0KHB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHB4"
  },
  {
    "uniprot": "A0A5K4EVQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVQ8"
  },
  {
    "uniprot": "G4VND7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VND7"
  },
  {
    "uniprot": "A0A5K4FA92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA92"
  },
  {
    "uniprot": "G4VT25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT25"
  },
  {
    "uniprot": "A0A3Q0KK47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK47"
  },
  {
    "uniprot": "G4VRQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRQ1"
  },
  {
    "uniprot": "A0A5K4ECA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECA8"
  },
  {
    "uniprot": "A0A3Q0KMA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMA9"
  },
  {
    "uniprot": "A0A5K4EIS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIS2"
  },
  {
    "uniprot": "A0A5K4FEA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEA1"
  },
  {
    "uniprot": "A0A3Q0KCW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCW3"
  },
  {
    "uniprot": "A0A5K4FES3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FES3"
  },
  {
    "uniprot": "A0A5K4F7R7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7R7"
  },
  {
    "uniprot": "A0A5K4EVQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVQ5"
  },
  {
    "uniprot": "G4VKD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKD5"
  },
  {
    "uniprot": "A0A5K4F6V3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6V3"
  },
  {
    "uniprot": "A0A5K4F6H7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6H7"
  },
  {
    "uniprot": "G4V9U7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9U7"
  },
  {
    "uniprot": "A0A3Q0KIF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIF2"
  },
  {
    "uniprot": "A0A3Q0KSF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSF7"
  },
  {
    "uniprot": "A0A3Q0KCC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCC2"
  },
  {
    "uniprot": "A0A3Q0KBF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBF7"
  },
  {
    "uniprot": "A0A5K4EEA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEA0"
  },
  {
    "uniprot": "A0A5K4EKT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKT1"
  },
  {
    "uniprot": "G4VQN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQN9"
  },
  {
    "uniprot": "G4VQB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQB1"
  },
  {
    "uniprot": "A0A3Q0KMF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMF5"
  },
  {
    "uniprot": "G4LZV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZV3"
  },
  {
    "uniprot": "A0A3Q0KGS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGS7"
  },
  {
    "uniprot": "A0A3Q0KP06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP06"
  },
  {
    "uniprot": "A0A5K4EMJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMJ2"
  },
  {
    "uniprot": "A0A5K4F1S1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1S1"
  },
  {
    "uniprot": "A0A5K4FCZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCZ3"
  },
  {
    "uniprot": "A0A3Q0KDM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDM0"
  },
  {
    "uniprot": "A0A5K4F0P0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0P0"
  },
  {
    "uniprot": "A0A3Q0KR10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR10"
  },
  {
    "uniprot": "A0A5K4E9F4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9F4"
  },
  {
    "uniprot": "A0A5K4FBQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBQ1"
  },
  {
    "uniprot": "A0A5K4FF04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF04"
  },
  {
    "uniprot": "G4V6E4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6E4"
  },
  {
    "uniprot": "A0A5K4F9U0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9U0"
  },
  {
    "uniprot": "A0A3Q0KPJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPJ6"
  },
  {
    "uniprot": "A0A5K4F9F2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9F2"
  },
  {
    "uniprot": "A0A3Q0KNK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNK5"
  },
  {
    "uniprot": "A0A3Q0KQA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQA3"
  },
  {
    "uniprot": "A0A5K4F794",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F794"
  },
  {
    "uniprot": "A0A5K4FG06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FG06"
  },
  {
    "uniprot": "G4VAJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAJ9"
  },
  {
    "uniprot": "A0A5K4FA02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA02"
  },
  {
    "uniprot": "A0A3Q0KPA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPA4"
  },
  {
    "uniprot": "A0A3Q0KQH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQH7"
  },
  {
    "uniprot": "G4V7H9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7H9"
  },
  {
    "uniprot": "G4VD97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD97"
  },
  {
    "uniprot": "A0A3Q0KSQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSQ2"
  },
  {
    "uniprot": "A0A3Q0KG74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG74"
  },
  {
    "uniprot": "A0A3Q0KD18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD18"
  },
  {
    "uniprot": "A0A3Q0KKY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKY5"
  },
  {
    "uniprot": "A0A3Q0KC09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC09"
  },
  {
    "uniprot": "A0A3Q0KNR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNR9"
  },
  {
    "uniprot": "G4M0N4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0N4"
  },
  {
    "uniprot": "A0A5K4FDZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDZ1"
  },
  {
    "uniprot": "A0A3Q0KND8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KND8"
  },
  {
    "uniprot": "A0A3Q0KBL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBL8"
  },
  {
    "uniprot": "A0A3Q0KCQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCQ8"
  },
  {
    "uniprot": "A0A5K4F569",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F569"
  },
  {
    "uniprot": "A0A5K4F964",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F964"
  },
  {
    "uniprot": "A0A5K4EDG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDG4"
  },
  {
    "uniprot": "A0A5K4EZK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZK3"
  },
  {
    "uniprot": "A0A5K4F7V1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7V1"
  },
  {
    "uniprot": "A0A5K4EE15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE15"
  },
  {
    "uniprot": "G4M0F3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0F3"
  },
  {
    "uniprot": "A0A5K4EVH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVH9"
  },
  {
    "uniprot": "G4LY09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY09"
  },
  {
    "uniprot": "G4VIG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIG1"
  },
  {
    "uniprot": "A0A5K4FCP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCP7"
  },
  {
    "uniprot": "A0A3Q0KF80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF80"
  },
  {
    "uniprot": "A0A5K4EFY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFY3"
  },
  {
    "uniprot": "A0A3Q0KNI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNI0"
  },
  {
    "uniprot": "A0A5K4ERL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERL7"
  },
  {
    "uniprot": "A0A5K4F8T5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8T5"
  },
  {
    "uniprot": "A0A3Q0KTW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTW4"
  },
  {
    "uniprot": "A0A5K4FBG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBG7"
  },
  {
    "uniprot": "G4VBZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBZ8"
  },
  {
    "uniprot": "A0A5K4EIA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIA2"
  },
  {
    "uniprot": "A0A5K4EJV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJV9"
  },
  {
    "uniprot": "G4VFD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFD7"
  },
  {
    "uniprot": "G4VQM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQM6"
  },
  {
    "uniprot": "A0A5K4F8T0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8T0"
  },
  {
    "uniprot": "A0A5K4EZH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZH0"
  },
  {
    "uniprot": "G4VLV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLV9"
  },
  {
    "uniprot": "A0A5K4FEB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEB9"
  },
  {
    "uniprot": "A0A3Q0KRL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRL8"
  },
  {
    "uniprot": "A0A5K4EPZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPZ8"
  },
  {
    "uniprot": "A0A5K4F5H4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5H4"
  },
  {
    "uniprot": "A0A3Q0KQ45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ45"
  },
  {
    "uniprot": "A0A3Q0KUF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUF8"
  },
  {
    "uniprot": "G4VEC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEC8"
  },
  {
    "uniprot": "A0A5K4F4F2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4F2"
  },
  {
    "uniprot": "A0A3Q0KPX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPX9"
  },
  {
    "uniprot": "A0A3Q0KVI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVI9"
  },
  {
    "uniprot": "A0A5K4FDX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDX0"
  },
  {
    "uniprot": "A0A3Q0KTD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTD5"
  },
  {
    "uniprot": "A0A3Q0KMG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMG2"
  },
  {
    "uniprot": "A0A5K4FAI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAI7"
  },
  {
    "uniprot": "A0A5K4F191",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F191"
  },
  {
    "uniprot": "G4VE56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VE56"
  },
  {
    "uniprot": "A0A5K4ESD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESD7"
  },
  {
    "uniprot": "G4LVP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVP4"
  },
  {
    "uniprot": "A0A3Q0KQ81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ81"
  },
  {
    "uniprot": "A0A5K4F8V9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8V9"
  },
  {
    "uniprot": "A0A3Q0KBF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBF5"
  },
  {
    "uniprot": "A0A5K4EC66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EC66"
  },
  {
    "uniprot": "G4VQQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQQ6"
  },
  {
    "uniprot": "A0A3Q0KVK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVK0"
  },
  {
    "uniprot": "A0A3Q0KD77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD77"
  },
  {
    "uniprot": "A0A5K4F312",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F312"
  },
  {
    "uniprot": "A0A3Q0KP79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP79"
  },
  {
    "uniprot": "A0A5K4F1T4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1T4"
  },
  {
    "uniprot": "A0A5K4F761",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F761"
  },
  {
    "uniprot": "A0A5K4F7C4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7C4"
  },
  {
    "uniprot": "A0A3Q0KQX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQX3"
  },
  {
    "uniprot": "A0A5K4EJG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJG6"
  },
  {
    "uniprot": "A0A5K4F0X2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0X2"
  },
  {
    "uniprot": "A0A5K4F3Y0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Y0"
  },
  {
    "uniprot": "G4VNF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNF5"
  },
  {
    "uniprot": "A0A5K4F7D5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7D5"
  },
  {
    "uniprot": "A0A3Q0KIA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIA7"
  },
  {
    "uniprot": "A0A5K4FB41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB41"
  },
  {
    "uniprot": "A0A5K4F3R3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3R3"
  },
  {
    "uniprot": "A0A5K4FDH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDH1"
  },
  {
    "uniprot": "A0A5K4FF59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF59"
  },
  {
    "uniprot": "A0A5K4F570",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F570"
  },
  {
    "uniprot": "A0A5K4FEE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEE2"
  },
  {
    "uniprot": "A0A5K4F3A4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3A4"
  },
  {
    "uniprot": "A0A3Q0KU99",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU99"
  },
  {
    "uniprot": "G4V778",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V778"
  },
  {
    "uniprot": "A0A3Q0KIK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIK6"
  },
  {
    "uniprot": "A0A3Q0KRR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRR0"
  },
  {
    "uniprot": "A0A5K4FEP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEP5"
  },
  {
    "uniprot": "A0A3Q0KMY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMY3"
  },
  {
    "uniprot": "A0A5K4EHY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHY3"
  },
  {
    "uniprot": "A0A5K4FAL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAL9"
  },
  {
    "uniprot": "A0A5K4FAZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAZ7"
  },
  {
    "uniprot": "A0A5K4ENZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENZ6"
  },
  {
    "uniprot": "G4VK61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK61"
  },
  {
    "uniprot": "G4VDF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDF8"
  },
  {
    "uniprot": "A0A5K4F5S3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5S3"
  },
  {
    "uniprot": "C4QLY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QLY6"
  },
  {
    "uniprot": "A0A5K4EUV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUV6"
  },
  {
    "uniprot": "A0A5K4F6K9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6K9"
  },
  {
    "uniprot": "A0A5K4FBU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBU4"
  },
  {
    "uniprot": "G4LZ74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ74"
  },
  {
    "uniprot": "A0A5K4F1C1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1C1"
  },
  {
    "uniprot": "A0A3Q0KLK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLK2"
  },
  {
    "uniprot": "A0A3Q0KF91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF91"
  },
  {
    "uniprot": "A0A5K4FDM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDM6"
  },
  {
    "uniprot": "G4VTG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTG6"
  },
  {
    "uniprot": "A0A5K4F4V5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4V5"
  },
  {
    "uniprot": "A0A5K4F2F3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2F3"
  },
  {
    "uniprot": "A0A3Q0KPK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPK6"
  },
  {
    "uniprot": "G4VJZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJZ4"
  },
  {
    "uniprot": "A0A5K4ESP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESP4"
  },
  {
    "uniprot": "A0A5K4EWJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWJ0"
  },
  {
    "uniprot": "A0A3Q0KQ69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ69"
  },
  {
    "uniprot": "A0A3Q0KTC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTC5"
  },
  {
    "uniprot": "A0A5K4FB28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB28"
  },
  {
    "uniprot": "A0A5K4F8S0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8S0"
  },
  {
    "uniprot": "A0A3Q0KRN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRN7"
  },
  {
    "uniprot": "A0A5K4F392",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F392"
  },
  {
    "uniprot": "A0A5K4FBQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBQ2"
  },
  {
    "uniprot": "A0A5K4F403",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F403"
  },
  {
    "uniprot": "A0A3Q0KM02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM02"
  },
  {
    "uniprot": "A0A5K4FE23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE23"
  },
  {
    "uniprot": "A0A5K4F9E7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9E7"
  },
  {
    "uniprot": "A0A3Q0KG94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG94"
  },
  {
    "uniprot": "G4VM27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM27"
  },
  {
    "uniprot": "A0A5K4EUS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUS1"
  },
  {
    "uniprot": "G4LUF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUF3"
  },
  {
    "uniprot": "A0A5K4FDD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDD8"
  },
  {
    "uniprot": "A0A3Q0KG57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG57"
  },
  {
    "uniprot": "A0A5K4F305",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F305"
  },
  {
    "uniprot": "A0A5K4F3V8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3V8"
  },
  {
    "uniprot": "A0A3Q0KGQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGQ6"
  },
  {
    "uniprot": "A0A5K4F9Z4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Z4"
  },
  {
    "uniprot": "A0A3Q0KBP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBP7"
  },
  {
    "uniprot": "A0A5K4F7F5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7F5"
  },
  {
    "uniprot": "Q2MK72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q2MK72"
  },
  {
    "uniprot": "A0A5K4ESB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESB0"
  },
  {
    "uniprot": "A0A5K4FDW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDW4"
  },
  {
    "uniprot": "A0A5K4F906",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F906"
  },
  {
    "uniprot": "A0A5K4FB72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB72"
  },
  {
    "uniprot": "A0A3Q0KBG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBG4"
  },
  {
    "uniprot": "A0A3Q0KQZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQZ7"
  },
  {
    "uniprot": "A0A5K4ESI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESI5"
  },
  {
    "uniprot": "G4VTP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTP7"
  },
  {
    "uniprot": "A0A5K4F790",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F790"
  },
  {
    "uniprot": "A0A5K4EVX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVX3"
  },
  {
    "uniprot": "A0A3Q0KNT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNT1"
  },
  {
    "uniprot": "A0A3Q0KSI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSI1"
  },
  {
    "uniprot": "A0A5K4F4W9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4W9"
  },
  {
    "uniprot": "G4VIL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIL3"
  },
  {
    "uniprot": "A0A5K4F0H2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0H2"
  },
  {
    "uniprot": "A0A5K4EHS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHS2"
  },
  {
    "uniprot": "A0A5K4EUT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUT3"
  },
  {
    "uniprot": "A0A3Q0KHH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHH2"
  },
  {
    "uniprot": "A0A5K4FBM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBM7"
  },
  {
    "uniprot": "A0A5K4F7E3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7E3"
  },
  {
    "uniprot": "G4LUM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUM0"
  },
  {
    "uniprot": "A0A5K4EU66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU66"
  },
  {
    "uniprot": "A0A5K4FAS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAS1"
  },
  {
    "uniprot": "A0A5K4F6W7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6W7"
  },
  {
    "uniprot": "A0A3Q0KEL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEL2"
  },
  {
    "uniprot": "G4VQB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQB7"
  },
  {
    "uniprot": "A0A5K4EQZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQZ2"
  },
  {
    "uniprot": "A0A5K4ET25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET25"
  },
  {
    "uniprot": "A0A3Q0KCN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCN7"
  },
  {
    "uniprot": "A0A5K4F908",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F908"
  },
  {
    "uniprot": "A0A5K4EAD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAD4"
  },
  {
    "uniprot": "A0A3Q0KMF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMF0"
  },
  {
    "uniprot": "G4VC05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC05"
  },
  {
    "uniprot": "G4VA64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA64"
  },
  {
    "uniprot": "A0A3Q0KQK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQK9"
  },
  {
    "uniprot": "A0A3Q0KKK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKK5"
  },
  {
    "uniprot": "A0A5K4FA62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA62"
  },
  {
    "uniprot": "A0A5K4EXG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXG9"
  },
  {
    "uniprot": "G4VTN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTN4"
  },
  {
    "uniprot": "A0A3Q0KE02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE02"
  },
  {
    "uniprot": "A0A3Q0KT63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT63"
  },
  {
    "uniprot": "A0A3Q0KJP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJP9"
  },
  {
    "uniprot": "G4V6X9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6X9"
  },
  {
    "uniprot": "A0A5K4F4H1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4H1"
  },
  {
    "uniprot": "A0A5K4EEZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEZ7"
  },
  {
    "uniprot": "A0A5K4FAR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAR1"
  },
  {
    "uniprot": "A0A3Q0KG38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG38"
  },
  {
    "uniprot": "A0A3Q0KFT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFT4"
  },
  {
    "uniprot": "O44125",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/O44125"
  },
  {
    "uniprot": "A0A5K4E928",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E928"
  },
  {
    "uniprot": "A0A3Q0KH75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH75"
  },
  {
    "uniprot": "G4LY02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY02"
  },
  {
    "uniprot": "Q15ER5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q15ER5"
  },
  {
    "uniprot": "A0A3Q0KKI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKI8"
  },
  {
    "uniprot": "A0A5K4F3Y3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Y3"
  },
  {
    "uniprot": "A0A5K4EWJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWJ8"
  },
  {
    "uniprot": "A0A5K4F0W8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0W8"
  },
  {
    "uniprot": "A0A5K4ERX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERX8"
  },
  {
    "uniprot": "A0A5K4F9N1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9N1"
  },
  {
    "uniprot": "G4VEI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEI6"
  },
  {
    "uniprot": "A0A5K4EJ33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJ33"
  },
  {
    "uniprot": "A0A5K4EMI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMI9"
  },
  {
    "uniprot": "A0A5K4F455",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F455"
  },
  {
    "uniprot": "A0A5K4EQM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQM2"
  },
  {
    "uniprot": "C4Q8P7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q8P7"
  },
  {
    "uniprot": "A0A3Q0KN64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN64"
  },
  {
    "uniprot": "A0A5K4EPV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPV4"
  },
  {
    "uniprot": "A0A5K4F956",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F956"
  },
  {
    "uniprot": "A0A5K4F7E7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7E7"
  },
  {
    "uniprot": "A0A3Q0KQF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQF6"
  },
  {
    "uniprot": "A0A5K4EHF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHF1"
  },
  {
    "uniprot": "A0A5K4EUQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUQ4"
  },
  {
    "uniprot": "G4VJ82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ82"
  },
  {
    "uniprot": "A0A5K4F9T5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9T5"
  },
  {
    "uniprot": "G4VR89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR89"
  },
  {
    "uniprot": "A0A5K4F153",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F153"
  },
  {
    "uniprot": "A0A5K4F796",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F796"
  },
  {
    "uniprot": "A0A5K4F4S0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4S0"
  },
  {
    "uniprot": "G4LXM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXM0"
  },
  {
    "uniprot": "A0A5K4F3S6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3S6"
  },
  {
    "uniprot": "A0A3Q0KJQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJQ2"
  },
  {
    "uniprot": "A0A3Q0KGT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGT9"
  },
  {
    "uniprot": "A0A3Q0KS56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS56"
  },
  {
    "uniprot": "A0A3Q0KJE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJE9"
  },
  {
    "uniprot": "B8Y6H3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/B8Y6H3"
  },
  {
    "uniprot": "A0A5K4F7G9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7G9"
  },
  {
    "uniprot": "A0A3Q0KGU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGU5"
  },
  {
    "uniprot": "A0A5K4EBY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBY7"
  },
  {
    "uniprot": "A0A3Q0KRP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRP7"
  },
  {
    "uniprot": "G4VSV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSV4"
  },
  {
    "uniprot": "A0A5K4ELB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELB7"
  },
  {
    "uniprot": "A0A5K4F6D9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6D9"
  },
  {
    "uniprot": "A0A5K4FBS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBS1"
  },
  {
    "uniprot": "A0A5K4EYW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYW9"
  },
  {
    "uniprot": "A0A5K4ERQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERQ7"
  },
  {
    "uniprot": "A0A5K4FDJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDJ3"
  },
  {
    "uniprot": "A0A5K4EQ51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ51"
  },
  {
    "uniprot": "G4VCW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCW4"
  },
  {
    "uniprot": "A0A3Q0KRF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRF9"
  },
  {
    "uniprot": "A0A5K4ER03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER03"
  },
  {
    "uniprot": "A0A3Q0KHN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHN5"
  },
  {
    "uniprot": "A0A5K4F735",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F735"
  },
  {
    "uniprot": "A0A5K4FE29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE29"
  },
  {
    "uniprot": "A0A5K4FC79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC79"
  },
  {
    "uniprot": "A0A3Q0KIG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIG8"
  },
  {
    "uniprot": "A0A5K4FC80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC80"
  },
  {
    "uniprot": "A0A3Q0KQJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQJ8"
  },
  {
    "uniprot": "G4VTQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTQ8"
  },
  {
    "uniprot": "A0A5K4FEE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEE7"
  },
  {
    "uniprot": "A0A3Q0KH87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH87"
  },
  {
    "uniprot": "A0A5K4F9I0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9I0"
  },
  {
    "uniprot": "A0A5K4EQ70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ70"
  },
  {
    "uniprot": "A0A5K4EHM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHM9"
  },
  {
    "uniprot": "A0A3Q0KUE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUE9"
  },
  {
    "uniprot": "A0A5K4EMQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMQ9"
  },
  {
    "uniprot": "G4LV03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV03"
  },
  {
    "uniprot": "A0A5K4EUD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUD7"
  },
  {
    "uniprot": "A0A3Q0KV99",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV99"
  },
  {
    "uniprot": "A0A5K4F3J7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3J7"
  },
  {
    "uniprot": "A0A3Q0KV12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV12"
  },
  {
    "uniprot": "A0A3Q0KJH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJH9"
  },
  {
    "uniprot": "G4LW36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LW36"
  },
  {
    "uniprot": "A0A5K4F5J4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5J4"
  },
  {
    "uniprot": "A0A5K4EZJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZJ1"
  },
  {
    "uniprot": "A0A5K4FAX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAX1"
  },
  {
    "uniprot": "A0A5K4F958",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F958"
  },
  {
    "uniprot": "A0A5K4F308",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F308"
  },
  {
    "uniprot": "C4PYV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4PYV8"
  },
  {
    "uniprot": "A0A5K4ESM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESM4"
  },
  {
    "uniprot": "A0A5K4F0E7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0E7"
  },
  {
    "uniprot": "A0A5K4ETU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETU2"
  },
  {
    "uniprot": "C4QJA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QJA1"
  },
  {
    "uniprot": "E2EAI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/E2EAI5"
  },
  {
    "uniprot": "G4VTU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTU6"
  },
  {
    "uniprot": "A0A5K4F588",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F588"
  },
  {
    "uniprot": "A0A3Q0KJ63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ63"
  },
  {
    "uniprot": "A0A3Q0KIV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIV7"
  },
  {
    "uniprot": "A0A5K4EUN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUN9"
  },
  {
    "uniprot": "A0A3Q0KE07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE07"
  },
  {
    "uniprot": "G4V8G2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8G2"
  },
  {
    "uniprot": "A0A5K4F7Y8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Y8"
  },
  {
    "uniprot": "A0A5K4F8I1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8I1"
  },
  {
    "uniprot": "A0A5K4ESM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESM2"
  },
  {
    "uniprot": "G4V8G7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8G7"
  },
  {
    "uniprot": "A0A3Q0KN60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN60"
  },
  {
    "uniprot": "A0A5K4ERV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERV3"
  },
  {
    "uniprot": "A0A5K4FCQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCQ3"
  },
  {
    "uniprot": "A0A5K4F9T1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9T1"
  },
  {
    "uniprot": "A0A5K4F1J4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1J4"
  },
  {
    "uniprot": "A0A3Q0KIS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIS4"
  },
  {
    "uniprot": "A0A5K4EZW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZW8"
  },
  {
    "uniprot": "A0A5K4FGZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGZ9"
  },
  {
    "uniprot": "A0A5K4EEL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEL0"
  },
  {
    "uniprot": "A0A5K4EA22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EA22"
  },
  {
    "uniprot": "A0A3Q0KSN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSN0"
  },
  {
    "uniprot": "A0A5K4F4S1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4S1"
  },
  {
    "uniprot": "A0A5K4FAX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAX2"
  },
  {
    "uniprot": "A0A3Q0KPR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPR6"
  },
  {
    "uniprot": "A0A5K4ES57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES57"
  },
  {
    "uniprot": "A0A5K4EZ85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ85"
  },
  {
    "uniprot": "A0A3Q0KJT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJT1"
  },
  {
    "uniprot": "A0A5K4FC29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC29"
  },
  {
    "uniprot": "A0A3Q0KN41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN41"
  },
  {
    "uniprot": "A0A3Q0KLX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLX8"
  },
  {
    "uniprot": "A0A5K4EI65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EI65"
  },
  {
    "uniprot": "A0A5K4EBV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBV4"
  },
  {
    "uniprot": "A0A5K4F040",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F040"
  },
  {
    "uniprot": "A0A3Q0KEB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEB8"
  },
  {
    "uniprot": "A0A3Q0KHC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHC1"
  },
  {
    "uniprot": "A0A5K4ECC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECC5"
  },
  {
    "uniprot": "A0A5K4EHQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHQ5"
  },
  {
    "uniprot": "A0A5K4ENV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENV0"
  },
  {
    "uniprot": "A0A3Q0KSR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSR8"
  },
  {
    "uniprot": "A0A3G6V9T0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3G6V9T0"
  },
  {
    "uniprot": "A0A5K4F724",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F724"
  },
  {
    "uniprot": "G4VLF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLF7"
  },
  {
    "uniprot": "A0A3Q0KRN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRN5"
  },
  {
    "uniprot": "A0A3Q0KM95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM95"
  },
  {
    "uniprot": "G4VCZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCZ0"
  },
  {
    "uniprot": "A0A3Q0KDU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDU0"
  },
  {
    "uniprot": "A0A5K4E976",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E976"
  },
  {
    "uniprot": "A0A5K4F7N5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7N5"
  },
  {
    "uniprot": "A0A3Q0KTJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTJ6"
  },
  {
    "uniprot": "G4VJC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJC7"
  },
  {
    "uniprot": "A0A5K4F334",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F334"
  },
  {
    "uniprot": "G4VEB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEB2"
  },
  {
    "uniprot": "A0A5K4EI11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EI11"
  },
  {
    "uniprot": "A0A3Q0KFC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFC1"
  },
  {
    "uniprot": "A0A5K4F7X8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7X8"
  },
  {
    "uniprot": "A0A3Q0KS20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS20"
  },
  {
    "uniprot": "G4M134",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M134"
  },
  {
    "uniprot": "A0A5K4F712",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F712"
  },
  {
    "uniprot": "A0A5K4F8W3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8W3"
  },
  {
    "uniprot": "A0A5K4F1I6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1I6"
  },
  {
    "uniprot": "A0A3Q0KNX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNX6"
  },
  {
    "uniprot": "A0A3Q0KCM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCM5"
  },
  {
    "uniprot": "A0A5K4EX48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX48"
  },
  {
    "uniprot": "A0A5K4FAM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAM3"
  },
  {
    "uniprot": "G4VF85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF85"
  },
  {
    "uniprot": "A0A5K4F324",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F324"
  },
  {
    "uniprot": "A0A3Q0KPF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPF5"
  },
  {
    "uniprot": "A0A5K4EYT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYT3"
  },
  {
    "uniprot": "C1M270",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C1M270"
  },
  {
    "uniprot": "A0A3Q0KVB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVB9"
  },
  {
    "uniprot": "A0A5K4F1Z0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Z0"
  },
  {
    "uniprot": "A0A5K4FDC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDC1"
  },
  {
    "uniprot": "A0A3Q0KSY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSY3"
  },
  {
    "uniprot": "A0A5K4F7A0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7A0"
  },
  {
    "uniprot": "A0A5K4EZL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZL7"
  },
  {
    "uniprot": "A0A3Q0KGB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGB3"
  },
  {
    "uniprot": "A0A5K4FCJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCJ9"
  },
  {
    "uniprot": "G4V6F3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6F3"
  },
  {
    "uniprot": "A0A5K4F135",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F135"
  },
  {
    "uniprot": "C4QLP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QLP6"
  },
  {
    "uniprot": "A0A5K4F1F7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1F7"
  },
  {
    "uniprot": "A0A3Q0KUH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUH7"
  },
  {
    "uniprot": "A0A3Q0KKP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKP9"
  },
  {
    "uniprot": "G4VFW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFW7"
  },
  {
    "uniprot": "A0A5K4F310",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F310"
  },
  {
    "uniprot": "G4VFS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFS3"
  },
  {
    "uniprot": "A0A3Q0KEZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEZ6"
  },
  {
    "uniprot": "C4QU02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QU02"
  },
  {
    "uniprot": "G4V7G3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7G3"
  },
  {
    "uniprot": "A0A3Q0KSA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSA0"
  },
  {
    "uniprot": "Q1WMM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q1WMM8"
  },
  {
    "uniprot": "A0A5K4EAX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAX8"
  },
  {
    "uniprot": "A0A5K4F430",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F430"
  },
  {
    "uniprot": "A0A5K4EUV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUV9"
  },
  {
    "uniprot": "A0A5K4F4G6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4G6"
  },
  {
    "uniprot": "A0A5K4FB33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB33"
  },
  {
    "uniprot": "A0A5K4FC70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC70"
  },
  {
    "uniprot": "G4VHS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHS0"
  },
  {
    "uniprot": "G4LW91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LW91"
  },
  {
    "uniprot": "A0A5K4EJ87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJ87"
  },
  {
    "uniprot": "A0A5K4F4J6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4J6"
  },
  {
    "uniprot": "A0A5K4F402",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F402"
  },
  {
    "uniprot": "A0A3Q0KMJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMJ2"
  },
  {
    "uniprot": "A0A5K4F6B8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6B8"
  },
  {
    "uniprot": "A0A5K4F9A2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9A2"
  },
  {
    "uniprot": "A0A5K4ELF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELF0"
  },
  {
    "uniprot": "A0A3Q0KMY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMY6"
  },
  {
    "uniprot": "A0A5K4FF57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF57"
  },
  {
    "uniprot": "A0A5K4F4C0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4C0"
  },
  {
    "uniprot": "A0A5K4EQM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQM7"
  },
  {
    "uniprot": "G4VCT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCT9"
  },
  {
    "uniprot": "A0A5K4F8T9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8T9"
  },
  {
    "uniprot": "B9UJC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/B9UJC8"
  },
  {
    "uniprot": "A0A3Q0KTU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTU2"
  },
  {
    "uniprot": "A0A5K4F9P0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9P0"
  },
  {
    "uniprot": "A0A3Q0KS07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS07"
  },
  {
    "uniprot": "A0A5K4EIA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIA9"
  },
  {
    "uniprot": "A0A3Q0KKL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKL4"
  },
  {
    "uniprot": "A0A5K4F5Z7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Z7"
  },
  {
    "uniprot": "G4VNL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNL6"
  },
  {
    "uniprot": "A0A5K4F1U4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1U4"
  },
  {
    "uniprot": "A0A5K4EQC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQC0"
  },
  {
    "uniprot": "G4V6M3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6M3"
  },
  {
    "uniprot": "A0A3Q0KD46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD46"
  },
  {
    "uniprot": "A0A3Q0KV49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV49"
  },
  {
    "uniprot": "A0A5K4EES4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EES4"
  },
  {
    "uniprot": "G4VD35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD35"
  },
  {
    "uniprot": "A0A5K4EQY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQY2"
  },
  {
    "uniprot": "A0A3Q0KS21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS21"
  },
  {
    "uniprot": "G4V8Y1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8Y1"
  },
  {
    "uniprot": "A0A5K4FCR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCR2"
  },
  {
    "uniprot": "A0A5K4EVH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVH7"
  },
  {
    "uniprot": "A0A5K4EV26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV26"
  },
  {
    "uniprot": "A0A5K4F6A0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6A0"
  },
  {
    "uniprot": "A0A5K4FC33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC33"
  },
  {
    "uniprot": "A0A5K4EWT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWT7"
  },
  {
    "uniprot": "G4V9Z8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9Z8"
  },
  {
    "uniprot": "A0A3Q0KUI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUI7"
  },
  {
    "uniprot": "A0A3Q0KDR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDR2"
  },
  {
    "uniprot": "G4VIK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIK8"
  },
  {
    "uniprot": "A0A5K4EQ63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ63"
  },
  {
    "uniprot": "A0A3Q0KPF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPF2"
  },
  {
    "uniprot": "A0A5K4F2Q3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Q3"
  },
  {
    "uniprot": "A0A3Q0KL40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL40"
  },
  {
    "uniprot": "A0A5K4F836",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F836"
  },
  {
    "uniprot": "G4VQ87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ87"
  },
  {
    "uniprot": "G4VCG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCG2"
  },
  {
    "uniprot": "A0A5K4FE20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE20"
  },
  {
    "uniprot": "A0A5K4FD35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD35"
  },
  {
    "uniprot": "A0A5K4ELU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELU4"
  },
  {
    "uniprot": "A0A3Q0KEV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEV8"
  },
  {
    "uniprot": "G4M023",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M023"
  },
  {
    "uniprot": "A0A5K4F7U8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7U8"
  },
  {
    "uniprot": "A0A3Q0KUA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUA5"
  },
  {
    "uniprot": "A0A5K4FAK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAK1"
  },
  {
    "uniprot": "A0A5K4EYN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYN9"
  },
  {
    "uniprot": "G4LVA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVA7"
  },
  {
    "uniprot": "A0A3Q0KFL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFL9"
  },
  {
    "uniprot": "A0A5K4F7Y7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Y7"
  },
  {
    "uniprot": "A0A5K4EPG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPG5"
  },
  {
    "uniprot": "A0A5K4F5U8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5U8"
  },
  {
    "uniprot": "A0A3Q0KHV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHV0"
  },
  {
    "uniprot": "A0A3Q0KI35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI35"
  },
  {
    "uniprot": "G4LUI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUI7"
  },
  {
    "uniprot": "A0A5K4EZA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZA2"
  },
  {
    "uniprot": "G4LY44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY44"
  },
  {
    "uniprot": "A0A3Q0KJZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJZ6"
  },
  {
    "uniprot": "A0A5K4F8S3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8S3"
  },
  {
    "uniprot": "A0A3Q0KUT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUT9"
  },
  {
    "uniprot": "G4V9D2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9D2"
  },
  {
    "uniprot": "A0A5K4ECT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECT8"
  },
  {
    "uniprot": "A0A5K4EAW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAW1"
  },
  {
    "uniprot": "A0A3Q0KDZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDZ7"
  },
  {
    "uniprot": "A0A5K4F6J6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6J6"
  },
  {
    "uniprot": "A0A5K4EMI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMI4"
  },
  {
    "uniprot": "A0A5K4FAK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAK4"
  },
  {
    "uniprot": "G4VIT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIT8"
  },
  {
    "uniprot": "A0A5K4FB20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB20"
  },
  {
    "uniprot": "A0A3Q0KDF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDF9"
  },
  {
    "uniprot": "G4VPC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPC4"
  },
  {
    "uniprot": "A0A5K4F815",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F815"
  },
  {
    "uniprot": "A0A5K4ECC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECC7"
  },
  {
    "uniprot": "A0A5K4ENG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENG2"
  },
  {
    "uniprot": "A0A5K4FAA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAA1"
  },
  {
    "uniprot": "G4VAT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAT9"
  },
  {
    "uniprot": "G4VST5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VST5"
  },
  {
    "uniprot": "A0A5K4F5M3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5M3"
  },
  {
    "uniprot": "A0A5K4F093",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F093"
  },
  {
    "uniprot": "A0A5K4F9D9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9D9"
  },
  {
    "uniprot": "P42637",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P42637"
  },
  {
    "uniprot": "G4VBL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBL7"
  },
  {
    "uniprot": "A0A5K4ENT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENT1"
  },
  {
    "uniprot": "A0A5K4F444",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F444"
  },
  {
    "uniprot": "A0A3Q0KC17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC17"
  },
  {
    "uniprot": "A0A5K4F4A9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4A9"
  },
  {
    "uniprot": "A0A3Q0KUA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUA6"
  },
  {
    "uniprot": "A0A5K4EKC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKC4"
  },
  {
    "uniprot": "A0A3Q0KT19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT19"
  },
  {
    "uniprot": "G4VQ01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ01"
  },
  {
    "uniprot": "A0A5K4EMS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMS2"
  },
  {
    "uniprot": "P13566",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P13566"
  },
  {
    "uniprot": "A0A5K4FBB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBB4"
  },
  {
    "uniprot": "A0A3Q0KC18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC18"
  },
  {
    "uniprot": "A0A3Q0KHX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHX9"
  },
  {
    "uniprot": "A0A5K4EUM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUM0"
  },
  {
    "uniprot": "A0A5K4F7U2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7U2"
  },
  {
    "uniprot": "A0A3Q0KUU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUU4"
  },
  {
    "uniprot": "G4VT56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT56"
  },
  {
    "uniprot": "A0A3Q0KEW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEW9"
  },
  {
    "uniprot": "A0A5K4EZE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZE3"
  },
  {
    "uniprot": "A0A3Q0KBC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBC3"
  },
  {
    "uniprot": "A0A5K4F982",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F982"
  },
  {
    "uniprot": "G4VLY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLY5"
  },
  {
    "uniprot": "A0A5K4EI55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EI55"
  },
  {
    "uniprot": "A0A3Q0KP46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP46"
  },
  {
    "uniprot": "A0A5K4ESK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESK1"
  },
  {
    "uniprot": "A0A3Q0KJZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJZ2"
  },
  {
    "uniprot": "A0A3Q0KVA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVA1"
  },
  {
    "uniprot": "A0A5K4F004",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F004"
  },
  {
    "uniprot": "A0A3Q0KF39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF39"
  },
  {
    "uniprot": "G4V9J3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9J3"
  },
  {
    "uniprot": "A0A5K4FFD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFD2"
  },
  {
    "uniprot": "A0A3Q0KU60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU60"
  },
  {
    "uniprot": "A0A3Q0KTR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTR2"
  },
  {
    "uniprot": "A0A5K4F606",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F606"
  },
  {
    "uniprot": "A0A5K4EZE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZE1"
  },
  {
    "uniprot": "G4VNF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNF7"
  },
  {
    "uniprot": "A0A5K4EQS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQS3"
  },
  {
    "uniprot": "A0A3Q0KT70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT70"
  },
  {
    "uniprot": "A0A5K4FF11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF11"
  },
  {
    "uniprot": "A0A3Q0KN02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN02"
  },
  {
    "uniprot": "A0A3Q0KFC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFC9"
  },
  {
    "uniprot": "A0A5K4EQ54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ54"
  },
  {
    "uniprot": "A0A3Q0KJC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJC5"
  },
  {
    "uniprot": "A0A3Q0KQS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQS8"
  },
  {
    "uniprot": "A0A5K4F714",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F714"
  },
  {
    "uniprot": "A0A3Q0KU59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU59"
  },
  {
    "uniprot": "A0A5K4F3F1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3F1"
  },
  {
    "uniprot": "A0A5K4F6N1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6N1"
  },
  {
    "uniprot": "A0A3Q0KLS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLS4"
  },
  {
    "uniprot": "A0A3Q0KMC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMC7"
  },
  {
    "uniprot": "A0A3Q0KNT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNT6"
  },
  {
    "uniprot": "G4VHH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHH7"
  },
  {
    "uniprot": "A0A5K4ERR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERR3"
  },
  {
    "uniprot": "A0A3Q0KJ32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ32"
  },
  {
    "uniprot": "A0A5K4FCT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCT5"
  },
  {
    "uniprot": "A0A3Q0KMA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMA2"
  },
  {
    "uniprot": "A0A3Q0KLT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLT9"
  },
  {
    "uniprot": "A0A3Q0KJR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJR8"
  },
  {
    "uniprot": "A0A3Q0KHD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHD8"
  },
  {
    "uniprot": "A0A3Q0KL86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL86"
  },
  {
    "uniprot": "A0A5K4EV10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV10"
  },
  {
    "uniprot": "G4VM61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM61"
  },
  {
    "uniprot": "G4LZT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZT4"
  },
  {
    "uniprot": "A0A3Q0KPH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPH4"
  },
  {
    "uniprot": "A0A3Q0KQY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQY9"
  },
  {
    "uniprot": "A0A3Q0KLD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLD0"
  },
  {
    "uniprot": "Q26554",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26554"
  },
  {
    "uniprot": "A0A5K4ELT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELT5"
  },
  {
    "uniprot": "A0A3Q0KTY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTY4"
  },
  {
    "uniprot": "A0A3Q0KFV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFV5"
  },
  {
    "uniprot": "A0A5K4FBJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBJ9"
  },
  {
    "uniprot": "A0A5K4EUH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUH7"
  },
  {
    "uniprot": "Q15ES6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q15ES6"
  },
  {
    "uniprot": "A0A3Q0KC39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC39"
  },
  {
    "uniprot": "A0A3Q0KJA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJA2"
  },
  {
    "uniprot": "A0A3Q0KS49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS49"
  },
  {
    "uniprot": "A0A5K4F5I2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5I2"
  },
  {
    "uniprot": "A0A3Q0KGY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGY0"
  },
  {
    "uniprot": "G4VLT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLT8"
  },
  {
    "uniprot": "G4VIA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIA8"
  },
  {
    "uniprot": "A0A5K4F2Q9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Q9"
  },
  {
    "uniprot": "A0A5K4F0T5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0T5"
  },
  {
    "uniprot": "A0A5K4FB44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB44"
  },
  {
    "uniprot": "A0A3Q0KPF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPF9"
  },
  {
    "uniprot": "G4VLH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLH2"
  },
  {
    "uniprot": "A0A5K4FD19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD19"
  },
  {
    "uniprot": "A0A5K4FH24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FH24"
  },
  {
    "uniprot": "A0A3Q0KDE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDE8"
  },
  {
    "uniprot": "A0A5K4F1B0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1B0"
  },
  {
    "uniprot": "A0A5K4EF87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EF87"
  },
  {
    "uniprot": "A0A5K4EX56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX56"
  },
  {
    "uniprot": "A0A5K4E9Z4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9Z4"
  },
  {
    "uniprot": "A0A3Q0KKG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKG6"
  },
  {
    "uniprot": "A0A5K4EM86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM86"
  },
  {
    "uniprot": "A0A3Q0KP33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP33"
  },
  {
    "uniprot": "A0A5K4FB56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB56"
  },
  {
    "uniprot": "A0A5K4EFF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFF1"
  },
  {
    "uniprot": "A0A5K4EIB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIB5"
  },
  {
    "uniprot": "G4VP55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VP55"
  },
  {
    "uniprot": "A0A3Q0KDS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDS4"
  },
  {
    "uniprot": "A0A3Q0KNA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNA3"
  },
  {
    "uniprot": "G4V831",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V831"
  },
  {
    "uniprot": "A0A3Q0KFW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFW6"
  },
  {
    "uniprot": "A0A5K4F2S7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2S7"
  },
  {
    "uniprot": "A0A3Q0KMD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMD8"
  },
  {
    "uniprot": "A0A5K4ELH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELH9"
  },
  {
    "uniprot": "A0A5K4F235",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F235"
  },
  {
    "uniprot": "A0A5K4F4V0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4V0"
  },
  {
    "uniprot": "A0A5K4ENC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENC3"
  },
  {
    "uniprot": "A0A5K4F4T1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4T1"
  },
  {
    "uniprot": "G4LWV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWV8"
  },
  {
    "uniprot": "A0A5K4FE30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE30"
  },
  {
    "uniprot": "A0A5K4FBS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBS8"
  },
  {
    "uniprot": "A0A5K4ECX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECX3"
  },
  {
    "uniprot": "A0A5K4F241",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F241"
  },
  {
    "uniprot": "A0A5K4F4K5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4K5"
  },
  {
    "uniprot": "A0A3Q0KTR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTR3"
  },
  {
    "uniprot": "A0A3Q0KUJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUJ4"
  },
  {
    "uniprot": "A0A5K4EXK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXK8"
  },
  {
    "uniprot": "A0A5K4F787",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F787"
  },
  {
    "uniprot": "G4M225",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M225"
  },
  {
    "uniprot": "A0A5K4F8Y4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Y4"
  },
  {
    "uniprot": "G4LXZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXZ4"
  },
  {
    "uniprot": "A0A3Q0KSX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSX0"
  },
  {
    "uniprot": "G4VI25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI25"
  },
  {
    "uniprot": "A0A3Q0KGX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGX2"
  },
  {
    "uniprot": "G4VMJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMJ2"
  },
  {
    "uniprot": "A0A5K4FE39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE39"
  },
  {
    "uniprot": "G4VTQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTQ0"
  },
  {
    "uniprot": "G4VKQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKQ8"
  },
  {
    "uniprot": "G4V8R3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8R3"
  },
  {
    "uniprot": "A0A5K4ER28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER28"
  },
  {
    "uniprot": "G4LWL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWL1"
  },
  {
    "uniprot": "A0A3Q0KQH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQH0"
  },
  {
    "uniprot": "A0A3Q0KIX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIX3"
  },
  {
    "uniprot": "A0A5K4EBE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBE7"
  },
  {
    "uniprot": "A0A5K4EHG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHG6"
  },
  {
    "uniprot": "A0A5K4ESX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESX3"
  },
  {
    "uniprot": "G4VSL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSL0"
  },
  {
    "uniprot": "A0A3Q0KSU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSU7"
  },
  {
    "uniprot": "G4LV66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV66"
  },
  {
    "uniprot": "A0A5K4F728",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F728"
  },
  {
    "uniprot": "A0A5K4F6M7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6M7"
  },
  {
    "uniprot": "G4VGD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGD7"
  },
  {
    "uniprot": "A0A5K4FEU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEU0"
  },
  {
    "uniprot": "A0A3Q0KH46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH46"
  },
  {
    "uniprot": "G4VH13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH13"
  },
  {
    "uniprot": "G4V6C9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6C9"
  },
  {
    "uniprot": "C4QPM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QPM1"
  },
  {
    "uniprot": "A0A5K4FBN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBN7"
  },
  {
    "uniprot": "G4VJK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJK8"
  },
  {
    "uniprot": "A0A5K4EBN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBN1"
  },
  {
    "uniprot": "A0A5K4ELK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELK1"
  },
  {
    "uniprot": "A0A5K4F7T3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7T3"
  },
  {
    "uniprot": "G4VAS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAS6"
  },
  {
    "uniprot": "A0A5K4EM43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM43"
  },
  {
    "uniprot": "G4VML7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VML7"
  },
  {
    "uniprot": "G4VSD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSD2"
  },
  {
    "uniprot": "G4VH82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH82"
  },
  {
    "uniprot": "A0A5K4EQ68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ68"
  },
  {
    "uniprot": "A0A3Q0KPQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPQ7"
  },
  {
    "uniprot": "A0A3Q0KQK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQK8"
  },
  {
    "uniprot": "A0A3Q0KBJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBJ8"
  },
  {
    "uniprot": "G4VTQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTQ6"
  },
  {
    "uniprot": "A0A3Q0KLI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLI3"
  },
  {
    "uniprot": "A0A5K4EZ58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ58"
  },
  {
    "uniprot": "A0A5K4FG03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FG03"
  },
  {
    "uniprot": "A0A3Q0KK00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK00"
  },
  {
    "uniprot": "A0A3Q0KG68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG68"
  },
  {
    "uniprot": "A0A5K4EQ50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ50"
  },
  {
    "uniprot": "A0A5K4EXT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXT5"
  },
  {
    "uniprot": "A0A5K4F618",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F618"
  },
  {
    "uniprot": "A0A3Q0KQ53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ53"
  },
  {
    "uniprot": "A0A5K4EGL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGL9"
  },
  {
    "uniprot": "A0A5K4FAF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAF7"
  },
  {
    "uniprot": "A0A5K4F896",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F896"
  },
  {
    "uniprot": "A0A3Q0KTN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTN5"
  },
  {
    "uniprot": "A0A5K4F9X0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9X0"
  },
  {
    "uniprot": "G4M183",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M183"
  },
  {
    "uniprot": "A0A5K4F731",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F731"
  },
  {
    "uniprot": "A0A3Q0KT88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT88"
  },
  {
    "uniprot": "A0A5K4F6E5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6E5"
  },
  {
    "uniprot": "A0A5K4FCI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCI8"
  },
  {
    "uniprot": "A0A5K4EFS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFS8"
  },
  {
    "uniprot": "A0A5K4EUW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUW1"
  },
  {
    "uniprot": "G4VJR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJR3"
  },
  {
    "uniprot": "A0A3Q0KCX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCX1"
  },
  {
    "uniprot": "C4QQN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QQN2"
  },
  {
    "uniprot": "A0A5K4E9T2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9T2"
  },
  {
    "uniprot": "A0A5K4F176",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F176"
  },
  {
    "uniprot": "A0A5K4F0R4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0R4"
  },
  {
    "uniprot": "A0A5K4FC18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC18"
  },
  {
    "uniprot": "A0A5K4F7Z2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Z2"
  },
  {
    "uniprot": "A0A3Q0KIK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIK5"
  },
  {
    "uniprot": "A0A3Q0KM76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM76"
  },
  {
    "uniprot": "G4LXM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXM5"
  },
  {
    "uniprot": "A0A5K4F8C9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8C9"
  },
  {
    "uniprot": "G4VIY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIY0"
  },
  {
    "uniprot": "A0A5K4EV40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV40"
  },
  {
    "uniprot": "A0A3Q0KP95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP95"
  },
  {
    "uniprot": "A0A3Q0KS71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS71"
  },
  {
    "uniprot": "G4VTU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTU0"
  },
  {
    "uniprot": "A0A3Q0KTK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTK2"
  },
  {
    "uniprot": "A0A3Q0KU11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU11"
  },
  {
    "uniprot": "A0A5K4FAP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAP9"
  },
  {
    "uniprot": "G4VR84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR84"
  },
  {
    "uniprot": "A0A5K4F4K1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4K1"
  },
  {
    "uniprot": "A0A3Q0KRV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRV0"
  },
  {
    "uniprot": "A0A3Q0KBK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBK9"
  },
  {
    "uniprot": "G4V985",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V985"
  },
  {
    "uniprot": "A0A5K4F737",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F737"
  },
  {
    "uniprot": "A0A5K4F4A0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4A0"
  },
  {
    "uniprot": "A0A5K4F637",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F637"
  },
  {
    "uniprot": "A0A5K4F323",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F323"
  },
  {
    "uniprot": "A0A5K4FF90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF90"
  },
  {
    "uniprot": "G4VLJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLJ3"
  },
  {
    "uniprot": "A0A5K4EZ49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ49"
  },
  {
    "uniprot": "A0A3Q0KLS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLS9"
  },
  {
    "uniprot": "A0A3Q0KR35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR35"
  },
  {
    "uniprot": "G4M1R1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1R1"
  },
  {
    "uniprot": "A0A5K4F477",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F477"
  },
  {
    "uniprot": "A0A3Q0KHE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHE9"
  },
  {
    "uniprot": "A0A3Q0KTZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTZ6"
  },
  {
    "uniprot": "A0A5K4EXC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXC2"
  },
  {
    "uniprot": "A0A5K4F598",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F598"
  },
  {
    "uniprot": "A0A5K4EKK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKK8"
  },
  {
    "uniprot": "G4VB91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB91"
  },
  {
    "uniprot": "G4VF41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF41"
  },
  {
    "uniprot": "A0A5K4EAF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAF8"
  },
  {
    "uniprot": "A0A3Q0KSU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSU0"
  },
  {
    "uniprot": "A0A5K4F163",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F163"
  },
  {
    "uniprot": "A0A5K4EWM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWM7"
  },
  {
    "uniprot": "A0A5K4F597",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F597"
  },
  {
    "uniprot": "A0A5K4F194",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F194"
  },
  {
    "uniprot": "E2EAJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/E2EAJ0"
  },
  {
    "uniprot": "A0A5K4FDQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDQ7"
  },
  {
    "uniprot": "A0A5K4F290",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F290"
  },
  {
    "uniprot": "A0A3Q0KN19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN19"
  },
  {
    "uniprot": "A0A5K4F0U6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0U6"
  },
  {
    "uniprot": "A0A5K4F9S0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9S0"
  },
  {
    "uniprot": "G4V5P5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5P5"
  },
  {
    "uniprot": "A0A5K4F9I1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9I1"
  },
  {
    "uniprot": "A0A5K4FCX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCX1"
  },
  {
    "uniprot": "A0A3Q0KLY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLY2"
  },
  {
    "uniprot": "A0A3Q0KLE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLE7"
  },
  {
    "uniprot": "A0A5K4FBI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBI1"
  },
  {
    "uniprot": "A0A5K4EFB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFB2"
  },
  {
    "uniprot": "A0A3Q0KL92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL92"
  },
  {
    "uniprot": "A0A5K4F4G3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4G3"
  },
  {
    "uniprot": "A0A5K4F8N8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8N8"
  },
  {
    "uniprot": "A0A3Q0KKU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKU1"
  },
  {
    "uniprot": "A0A5K4ESK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESK2"
  },
  {
    "uniprot": "A0A5K4ENC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENC7"
  },
  {
    "uniprot": "A0A5K4EFZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFZ3"
  },
  {
    "uniprot": "A0A3Q0KDJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDJ1"
  },
  {
    "uniprot": "A0A5K4FA51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA51"
  },
  {
    "uniprot": "A0A3Q0KBR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBR3"
  },
  {
    "uniprot": "Q26594",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26594"
  },
  {
    "uniprot": "A0A3Q0KPU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPU0"
  },
  {
    "uniprot": "A0A3Q0KU05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU05"
  },
  {
    "uniprot": "A0A5K4F2U4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2U4"
  },
  {
    "uniprot": "A0A5K4EQH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQH1"
  },
  {
    "uniprot": "A0A3Q0KNX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNX7"
  },
  {
    "uniprot": "A0A5K4EWS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWS5"
  },
  {
    "uniprot": "A0A5K4F5G5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5G5"
  },
  {
    "uniprot": "G4VB03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB03"
  },
  {
    "uniprot": "A0A5K4F9J2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9J2"
  },
  {
    "uniprot": "A0A5K4FFS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFS4"
  },
  {
    "uniprot": "A0A5K4F346",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F346"
  },
  {
    "uniprot": "G4VKF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKF3"
  },
  {
    "uniprot": "A0A3Q0KCJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCJ7"
  },
  {
    "uniprot": "G4VLI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLI9"
  },
  {
    "uniprot": "G4LVD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVD3"
  },
  {
    "uniprot": "A0A5K4F969",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F969"
  },
  {
    "uniprot": "A0A146MHP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MHP7"
  },
  {
    "uniprot": "A0A3Q0KNB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNB8"
  },
  {
    "uniprot": "A0A5K4EGR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGR0"
  },
  {
    "uniprot": "A0A5K4F3N8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3N8"
  },
  {
    "uniprot": "A0A3Q0KH76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH76"
  },
  {
    "uniprot": "A0A5K4F9E0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9E0"
  },
  {
    "uniprot": "A0A3Q0KND3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KND3"
  },
  {
    "uniprot": "A0A5K4EZX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZX5"
  },
  {
    "uniprot": "A0A3Q0KQF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQF2"
  },
  {
    "uniprot": "A0A5K4F3S5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3S5"
  },
  {
    "uniprot": "A0A5K4FAL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAL3"
  },
  {
    "uniprot": "G4VA79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA79"
  },
  {
    "uniprot": "A0A5K4F7V5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7V5"
  },
  {
    "uniprot": "A0A5K4EKI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKI0"
  },
  {
    "uniprot": "G4VAQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAQ9"
  },
  {
    "uniprot": "A0A3Q0KPY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPY6"
  },
  {
    "uniprot": "A0A5K4FDZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDZ9"
  },
  {
    "uniprot": "A0A5K4EED8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EED8"
  },
  {
    "uniprot": "A0A3Q0KLG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLG2"
  },
  {
    "uniprot": "A0A3Q0KSS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSS3"
  },
  {
    "uniprot": "G4M114",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M114"
  },
  {
    "uniprot": "A0A3Q0KGH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGH9"
  },
  {
    "uniprot": "G4VSL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSL7"
  },
  {
    "uniprot": "A0A3Q0KRW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRW7"
  },
  {
    "uniprot": "A0A5K4ELI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELI5"
  },
  {
    "uniprot": "A0A3Q0KKI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKI5"
  },
  {
    "uniprot": "G4VKX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKX2"
  },
  {
    "uniprot": "A0A5K4EP01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP01"
  },
  {
    "uniprot": "A0A5K4EWW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWW0"
  },
  {
    "uniprot": "A0A3Q0KN24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN24"
  },
  {
    "uniprot": "A0A5K4F3B4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3B4"
  },
  {
    "uniprot": "A0A5K4EJZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJZ4"
  },
  {
    "uniprot": "A0A3Q0KKE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKE6"
  },
  {
    "uniprot": "A0A5K4F839",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F839"
  },
  {
    "uniprot": "A0A146MGD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MGD5"
  },
  {
    "uniprot": "A0A0U5KJ28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A0U5KJ28"
  },
  {
    "uniprot": "A0A5K4EWM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWM6"
  },
  {
    "uniprot": "A0A5K4EU67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU67"
  },
  {
    "uniprot": "A0A3Q0KUW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUW2"
  },
  {
    "uniprot": "A0A3Q0KJA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJA7"
  },
  {
    "uniprot": "A0A3Q0KCC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCC9"
  },
  {
    "uniprot": "A0A3Q0KSH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSH5"
  },
  {
    "uniprot": "A0A3Q0KC71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC71"
  },
  {
    "uniprot": "A0A3Q0KLB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLB5"
  },
  {
    "uniprot": "A0A5K4F185",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F185"
  },
  {
    "uniprot": "A0A3Q0KQP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQP7"
  },
  {
    "uniprot": "A0A3Q0KPL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPL2"
  },
  {
    "uniprot": "A0A3Q0KV04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV04"
  },
  {
    "uniprot": "A0A3Q0KCJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCJ4"
  },
  {
    "uniprot": "A0A5K4FAP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAP6"
  },
  {
    "uniprot": "A0A5K4F7H4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7H4"
  },
  {
    "uniprot": "A0A5K4FDL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDL5"
  },
  {
    "uniprot": "A0A3Q0KP18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP18"
  },
  {
    "uniprot": "P46435",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P46435"
  },
  {
    "uniprot": "A0A3Q0KJ39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ39"
  },
  {
    "uniprot": "A0A5K4FDH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDH6"
  },
  {
    "uniprot": "A0A5K4FBL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBL4"
  },
  {
    "uniprot": "A0A3Q0KTL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTL0"
  },
  {
    "uniprot": "A0A3Q0KFG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFG6"
  },
  {
    "uniprot": "A0A5K4FEH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEH8"
  },
  {
    "uniprot": "A0A5K4F539",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F539"
  },
  {
    "uniprot": "A0A5K4EQ94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ94"
  },
  {
    "uniprot": "A0A3Q0KT76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT76"
  },
  {
    "uniprot": "A0A5K4F8R6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8R6"
  },
  {
    "uniprot": "A0A3Q0KHZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHZ4"
  },
  {
    "uniprot": "A0A5K4F7M6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7M6"
  },
  {
    "uniprot": "A0A5K4F3B9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3B9"
  },
  {
    "uniprot": "A0A5K4FC43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC43"
  },
  {
    "uniprot": "A0A5K4F4E8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4E8"
  },
  {
    "uniprot": "A0A3Q0KPQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPQ9"
  },
  {
    "uniprot": "A0A5K4EHD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHD9"
  },
  {
    "uniprot": "A0A0U5KEW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A0U5KEW2"
  },
  {
    "uniprot": "A0A3Q0KUG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUG4"
  },
  {
    "uniprot": "A0A3Q0KI80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI80"
  },
  {
    "uniprot": "A0A5K4F8I9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8I9"
  },
  {
    "uniprot": "A0A3Q0KV03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV03"
  },
  {
    "uniprot": "A0A5K4FCM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCM4"
  },
  {
    "uniprot": "A0A5K4F0X6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0X6"
  },
  {
    "uniprot": "A0A5K4EVG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVG2"
  },
  {
    "uniprot": "C4QPK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QPK4"
  },
  {
    "uniprot": "Q15ES7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q15ES7"
  },
  {
    "uniprot": "A0A5K4F8N7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8N7"
  },
  {
    "uniprot": "A0A3Q0KRK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRK3"
  },
  {
    "uniprot": "A0A3Q0KBH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBH8"
  },
  {
    "uniprot": "A0A3Q0KFZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFZ4"
  },
  {
    "uniprot": "G4VID1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VID1"
  },
  {
    "uniprot": "A0A3Q0KS65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS65"
  },
  {
    "uniprot": "G4VBF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBF9"
  },
  {
    "uniprot": "V9TJE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/V9TJE9"
  },
  {
    "uniprot": "A0A5K4F8G6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8G6"
  },
  {
    "uniprot": "G4VML2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VML2"
  },
  {
    "uniprot": "A0A3Q0KCZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCZ1"
  },
  {
    "uniprot": "A0A3Q0KM33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM33"
  },
  {
    "uniprot": "A0A5K4F6G8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6G8"
  },
  {
    "uniprot": "A0A3Q0KR46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR46"
  },
  {
    "uniprot": "C4Q5U5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q5U5"
  },
  {
    "uniprot": "A0A5K4FER3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FER3"
  },
  {
    "uniprot": "A0A3Q0KPC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPC4"
  },
  {
    "uniprot": "A0A5K4FD79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD79"
  },
  {
    "uniprot": "A0A3Q0KT36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT36"
  },
  {
    "uniprot": "A0A3Q0KE48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE48"
  },
  {
    "uniprot": "A0A3Q0KRP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRP2"
  },
  {
    "uniprot": "G4LVA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVA5"
  },
  {
    "uniprot": "A0A5K4EDL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDL0"
  },
  {
    "uniprot": "A0A3Q0KGT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGT3"
  },
  {
    "uniprot": "A0A3Q0KN99",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN99"
  },
  {
    "uniprot": "A0A3Q0KPF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPF7"
  },
  {
    "uniprot": "A0A3Q0KMN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMN3"
  },
  {
    "uniprot": "C4Q4S4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q4S4"
  },
  {
    "uniprot": "A0A5K4F525",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F525"
  },
  {
    "uniprot": "A0A5K4ENE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENE1"
  },
  {
    "uniprot": "A0A5K4EBA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBA1"
  },
  {
    "uniprot": "A0A5K4EZP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZP2"
  },
  {
    "uniprot": "A0A5K4F4A6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4A6"
  },
  {
    "uniprot": "A0A3Q0KPN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPN9"
  },
  {
    "uniprot": "A0A146MI62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MI62"
  },
  {
    "uniprot": "A0A5K4ENY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENY9"
  },
  {
    "uniprot": "A0A5K4FH19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FH19"
  },
  {
    "uniprot": "G4VMI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMI6"
  },
  {
    "uniprot": "A0A3Q0KKD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKD8"
  },
  {
    "uniprot": "A0A5K4EGV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGV6"
  },
  {
    "uniprot": "A0A5K4EIM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIM1"
  },
  {
    "uniprot": "A0A3Q0KTG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTG2"
  },
  {
    "uniprot": "A0A3Q0KUC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUC3"
  },
  {
    "uniprot": "G4VRJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRJ3"
  },
  {
    "uniprot": "G4M1D1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1D1"
  },
  {
    "uniprot": "A0A5K4EKW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKW8"
  },
  {
    "uniprot": "A0A3Q0KRR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRR9"
  },
  {
    "uniprot": "A0A5K4FA65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA65"
  },
  {
    "uniprot": "A0A5K4F5V0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5V0"
  },
  {
    "uniprot": "A0A5K4FCK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCK9"
  },
  {
    "uniprot": "G4VAY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAY7"
  },
  {
    "uniprot": "A0A5K4EFF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFF0"
  },
  {
    "uniprot": "A0A5K4FAS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAS4"
  },
  {
    "uniprot": "A0A5K4F7X5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7X5"
  },
  {
    "uniprot": "A0A5K4EJI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJI5"
  },
  {
    "uniprot": "A0A5K4EUS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUS6"
  },
  {
    "uniprot": "A0A5K4F804",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F804"
  },
  {
    "uniprot": "A0A5K4FD98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD98"
  },
  {
    "uniprot": "A0A5K4F4Y3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Y3"
  },
  {
    "uniprot": "A0A5K4EMT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMT7"
  },
  {
    "uniprot": "A0A5K4F294",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F294"
  },
  {
    "uniprot": "A0A3Q0KP57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP57"
  },
  {
    "uniprot": "A0A5K4F086",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F086"
  },
  {
    "uniprot": "A0A5K4F2H8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2H8"
  },
  {
    "uniprot": "A0A5K4F7J6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7J6"
  },
  {
    "uniprot": "P25792",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P25792"
  },
  {
    "uniprot": "A0A5K4F926",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F926"
  },
  {
    "uniprot": "G4VLM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLM6"
  },
  {
    "uniprot": "A0A3Q0KVE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVE8"
  },
  {
    "uniprot": "G4LWI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWI0"
  },
  {
    "uniprot": "A0A3Q0KTF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTF4"
  },
  {
    "uniprot": "A0A5K4FC21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC21"
  },
  {
    "uniprot": "A0A3Q0KU72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU72"
  },
  {
    "uniprot": "A0A3Q0KIP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIP7"
  },
  {
    "uniprot": "A0A5K4FEN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEN0"
  },
  {
    "uniprot": "G4VL13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VL13"
  },
  {
    "uniprot": "G4V8X9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8X9"
  },
  {
    "uniprot": "G4VIX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIX8"
  },
  {
    "uniprot": "G4VTM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTM4"
  },
  {
    "uniprot": "A0A5K4F2J7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2J7"
  },
  {
    "uniprot": "A0A5K4F6A4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6A4"
  },
  {
    "uniprot": "A0A5K4F420",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F420"
  },
  {
    "uniprot": "A0A5K4F9Q3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Q3"
  },
  {
    "uniprot": "C4QDD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QDD1"
  },
  {
    "uniprot": "A0A5K4EQ12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ12"
  },
  {
    "uniprot": "A0A5K4F1J3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1J3"
  },
  {
    "uniprot": "A0A5K4F693",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F693"
  },
  {
    "uniprot": "G4V7F1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7F1"
  },
  {
    "uniprot": "A0A5K4EKE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKE2"
  },
  {
    "uniprot": "A0A3Q0KQV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQV6"
  },
  {
    "uniprot": "A0A5K4FE15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE15"
  },
  {
    "uniprot": "A0A5K4F632",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F632"
  },
  {
    "uniprot": "A0A5K4FFI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFI7"
  },
  {
    "uniprot": "A0A5K4F6I8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6I8"
  },
  {
    "uniprot": "A0A5K4EZ12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ12"
  },
  {
    "uniprot": "A0A3Q0KP69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP69"
  },
  {
    "uniprot": "A0A5K4FAK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAK0"
  },
  {
    "uniprot": "G4VES8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VES8"
  },
  {
    "uniprot": "A0A5K4EIG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIG1"
  },
  {
    "uniprot": "A0A5K4EA90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EA90"
  },
  {
    "uniprot": "A0A5K4F7E8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7E8"
  },
  {
    "uniprot": "A0A5K4EKD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKD5"
  },
  {
    "uniprot": "A0A5K4ED18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED18"
  },
  {
    "uniprot": "A0A3Q0KT66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT66"
  },
  {
    "uniprot": "A0A5K4FBR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBR0"
  },
  {
    "uniprot": "A0A3Q0KTA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTA9"
  },
  {
    "uniprot": "A0A5K4F6P1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6P1"
  },
  {
    "uniprot": "A0A5K4FBT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBT2"
  },
  {
    "uniprot": "A0A3Q0KLP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLP9"
  },
  {
    "uniprot": "A0A5K4F291",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F291"
  },
  {
    "uniprot": "A0A3Q0KL42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL42"
  },
  {
    "uniprot": "A0A5K4EL83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL83"
  },
  {
    "uniprot": "C1LZ83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C1LZ83"
  },
  {
    "uniprot": "A0A5K4F795",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F795"
  },
  {
    "uniprot": "A0A5K4EHX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHX3"
  },
  {
    "uniprot": "A0A5K4F8K5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8K5"
  },
  {
    "uniprot": "G4LV41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV41"
  },
  {
    "uniprot": "A0A5K4FCR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCR3"
  },
  {
    "uniprot": "A0A5K4EGV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGV4"
  },
  {
    "uniprot": "G4VFV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFV5"
  },
  {
    "uniprot": "A0A3Q0KSS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSS9"
  },
  {
    "uniprot": "A0A3Q0KKG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKG2"
  },
  {
    "uniprot": "G4VR61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR61"
  },
  {
    "uniprot": "A0A5K4EHN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHN9"
  },
  {
    "uniprot": "A0A5K4F7B5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7B5"
  },
  {
    "uniprot": "A0A5K4FDA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDA1"
  },
  {
    "uniprot": "A0A3Q0KCI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCI0"
  },
  {
    "uniprot": "A0A3Q0KQH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQH4"
  },
  {
    "uniprot": "A0A5K4F6E3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6E3"
  },
  {
    "uniprot": "A0A3Q0KTE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTE3"
  },
  {
    "uniprot": "A0A3Q0KCJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCJ5"
  },
  {
    "uniprot": "A0A3Q0KPJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPJ2"
  },
  {
    "uniprot": "A0A5K4F2Q5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Q5"
  },
  {
    "uniprot": "A0A5K4EGB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGB0"
  },
  {
    "uniprot": "A0A5K4FFT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFT5"
  },
  {
    "uniprot": "A0A5K4FEE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEE1"
  },
  {
    "uniprot": "A0A5K4F8V7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8V7"
  },
  {
    "uniprot": "A0A5K4EKL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKL8"
  },
  {
    "uniprot": "G4V8N9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8N9"
  },
  {
    "uniprot": "A0A3Q0KDP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDP9"
  },
  {
    "uniprot": "A0A3Q0KVD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVD7"
  },
  {
    "uniprot": "A0A5K4FCT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCT4"
  },
  {
    "uniprot": "A0A3Q0KRD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRD1"
  },
  {
    "uniprot": "A0A5K4F0U5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0U5"
  },
  {
    "uniprot": "A0A3Q0KRS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRS2"
  },
  {
    "uniprot": "A0A5K4F543",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F543"
  },
  {
    "uniprot": "A0A5K4F872",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F872"
  },
  {
    "uniprot": "A0A5K4F9M5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9M5"
  },
  {
    "uniprot": "A0A5K4F2U8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2U8"
  },
  {
    "uniprot": "A0A5K4FAH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAH2"
  },
  {
    "uniprot": "A0A5K4F514",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F514"
  },
  {
    "uniprot": "A0A5K4F1N0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1N0"
  },
  {
    "uniprot": "A0A5K4EH66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH66"
  },
  {
    "uniprot": "A0A3Q0KHP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHP7"
  },
  {
    "uniprot": "A0A3Q0KTR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTR0"
  },
  {
    "uniprot": "A0A5K4EPU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPU9"
  },
  {
    "uniprot": "A0A3Q0KEM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEM7"
  },
  {
    "uniprot": "A0A5K4F2N2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2N2"
  },
  {
    "uniprot": "A0A5K4FAF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAF1"
  },
  {
    "uniprot": "A0A3Q0KEY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEY4"
  },
  {
    "uniprot": "A0A5K4EL57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL57"
  },
  {
    "uniprot": "A0A3Q0KUM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUM9"
  },
  {
    "uniprot": "G4VHP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHP5"
  },
  {
    "uniprot": "A0A5K4F645",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F645"
  },
  {
    "uniprot": "A0A3Q0KFA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFA9"
  },
  {
    "uniprot": "G4LYX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYX7"
  },
  {
    "uniprot": "A0A5K4F7G6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7G6"
  },
  {
    "uniprot": "A0A5K4FEC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEC2"
  },
  {
    "uniprot": "A0A5K4ECR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECR6"
  },
  {
    "uniprot": "A0A5K4F9E3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9E3"
  },
  {
    "uniprot": "A0A3Q0KKU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKU6"
  },
  {
    "uniprot": "A0A3Q0KHC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHC2"
  },
  {
    "uniprot": "A0A3Q0KQT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQT2"
  },
  {
    "uniprot": "A0A5K4F0Q2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Q2"
  },
  {
    "uniprot": "G4VK63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK63"
  },
  {
    "uniprot": "A0A5K4EXX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXX6"
  },
  {
    "uniprot": "A0A3Q0KLB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLB9"
  },
  {
    "uniprot": "A0A5K4FE65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE65"
  },
  {
    "uniprot": "A0A3Q0KPG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPG5"
  },
  {
    "uniprot": "A0A3Q0KJ07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ07"
  },
  {
    "uniprot": "A0A5K4F8S9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8S9"
  },
  {
    "uniprot": "G4VD46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD46"
  },
  {
    "uniprot": "G4VG79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VG79"
  },
  {
    "uniprot": "A0A5K4FAB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAB1"
  },
  {
    "uniprot": "A0A3Q0KP22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP22"
  },
  {
    "uniprot": "A0A3Q0KH14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH14"
  },
  {
    "uniprot": "A0A3Q0KDH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDH3"
  },
  {
    "uniprot": "A0A3Q0KQQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQQ3"
  },
  {
    "uniprot": "A0A5K4FD01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD01"
  },
  {
    "uniprot": "G4VJU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJU5"
  },
  {
    "uniprot": "A0A3Q0KLN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLN0"
  },
  {
    "uniprot": "G4V5G3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5G3"
  },
  {
    "uniprot": "A0A5K4E8Y7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8Y7"
  },
  {
    "uniprot": "A0A3Q0KMW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMW1"
  },
  {
    "uniprot": "A0A3Q0KD09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD09"
  },
  {
    "uniprot": "A0A3Q0KRD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRD3"
  },
  {
    "uniprot": "A0A3Q0KU43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU43"
  },
  {
    "uniprot": "A0A5K4F5P7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5P7"
  },
  {
    "uniprot": "A0A5K4FCN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCN4"
  },
  {
    "uniprot": "A0A5K4EDQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDQ5"
  },
  {
    "uniprot": "A0A5K4EDS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDS2"
  },
  {
    "uniprot": "A0A3Q0KHY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHY9"
  },
  {
    "uniprot": "A0A3Q0KKI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKI1"
  },
  {
    "uniprot": "A0A3Q0KM65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM65"
  },
  {
    "uniprot": "A0A5K4F7E6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7E6"
  },
  {
    "uniprot": "A0A3Q0KPN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPN8"
  },
  {
    "uniprot": "A0A5K4F9Z9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Z9"
  },
  {
    "uniprot": "G4VPJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPJ8"
  },
  {
    "uniprot": "A0A5K4EE12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE12"
  },
  {
    "uniprot": "A0A5K4FCB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCB5"
  },
  {
    "uniprot": "G4V650",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V650"
  },
  {
    "uniprot": "A0A3Q0KHX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHX5"
  },
  {
    "uniprot": "A0A5K4FEN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEN9"
  },
  {
    "uniprot": "G4VI16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI16"
  },
  {
    "uniprot": "G4V9W8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9W8"
  },
  {
    "uniprot": "A0A3Q0KKV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKV1"
  },
  {
    "uniprot": "A0A3Q0KFR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFR7"
  },
  {
    "uniprot": "A0A3Q0KPS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPS3"
  },
  {
    "uniprot": "A0A5K4ERW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERW3"
  },
  {
    "uniprot": "G4V8D5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8D5"
  },
  {
    "uniprot": "A0A3Q0KTV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTV7"
  },
  {
    "uniprot": "A0A5K4ERX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERX0"
  },
  {
    "uniprot": "A0A3Q0KQC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQC5"
  },
  {
    "uniprot": "A0A3Q0KKA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKA1"
  },
  {
    "uniprot": "A0A3Q0KCX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCX9"
  },
  {
    "uniprot": "A0A5K4F7E0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7E0"
  },
  {
    "uniprot": "G4VBF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBF5"
  },
  {
    "uniprot": "A0A5K4FAV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAV8"
  },
  {
    "uniprot": "A0A5K4FAB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAB5"
  },
  {
    "uniprot": "A0A5K4F2Y6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Y6"
  },
  {
    "uniprot": "A0A5K4F4P8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4P8"
  },
  {
    "uniprot": "A0A5K4F121",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F121"
  },
  {
    "uniprot": "A0A5K4ENJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENJ0"
  },
  {
    "uniprot": "A0A0U5KLL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A0U5KLL2"
  },
  {
    "uniprot": "G4V5I6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5I6"
  },
  {
    "uniprot": "A0A5K4F8Z0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Z0"
  },
  {
    "uniprot": "A0A5K4F3V4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3V4"
  },
  {
    "uniprot": "A0A5K4EWK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWK0"
  },
  {
    "uniprot": "A0A3Q0KCZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCZ3"
  },
  {
    "uniprot": "A0A3Q0KNT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNT9"
  },
  {
    "uniprot": "G4VKR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKR4"
  },
  {
    "uniprot": "A0A5K4FC98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC98"
  },
  {
    "uniprot": "A0A5K4EA79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EA79"
  },
  {
    "uniprot": "A0A3Q0KG11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG11"
  },
  {
    "uniprot": "A0A5K4FAI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAI3"
  },
  {
    "uniprot": "A0A5K4FA66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA66"
  },
  {
    "uniprot": "A0A5K4FA21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA21"
  },
  {
    "uniprot": "A0A5K4ECR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECR3"
  },
  {
    "uniprot": "A0A5K4EZP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZP5"
  },
  {
    "uniprot": "A0A3Q0KBN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBN9"
  },
  {
    "uniprot": "A0A5K4ESE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESE5"
  },
  {
    "uniprot": "A0A3Q0KKS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKS5"
  },
  {
    "uniprot": "A0A5K4FDV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDV1"
  },
  {
    "uniprot": "A0A5K4F5V4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5V4"
  },
  {
    "uniprot": "Q3ZFF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q3ZFF5"
  },
  {
    "uniprot": "G4M105",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M105"
  },
  {
    "uniprot": "A0A5K4EU96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU96"
  },
  {
    "uniprot": "A0A3Q0KBU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBU1"
  },
  {
    "uniprot": "A0A5K4F299",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F299"
  },
  {
    "uniprot": "G4VMQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMQ5"
  },
  {
    "uniprot": "A0A5K4F551",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F551"
  },
  {
    "uniprot": "A0A5K4F1A3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1A3"
  },
  {
    "uniprot": "A0A5K4F5X1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5X1"
  },
  {
    "uniprot": "A0A5K4EUY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUY0"
  },
  {
    "uniprot": "A0A5K4F7Q4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Q4"
  },
  {
    "uniprot": "A0A5K4EGK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGK6"
  },
  {
    "uniprot": "A0A5K4F652",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F652"
  },
  {
    "uniprot": "A0A5K4FDU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDU6"
  },
  {
    "uniprot": "G4VJG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJG3"
  },
  {
    "uniprot": "A0A5K4FFA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFA1"
  },
  {
    "uniprot": "A0A3Q0KRF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRF1"
  },
  {
    "uniprot": "A0A5K4EJY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJY4"
  },
  {
    "uniprot": "A0A5K4F562",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F562"
  },
  {
    "uniprot": "A0A5K4F3Q0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Q0"
  },
  {
    "uniprot": "A0A5K4EKU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKU2"
  },
  {
    "uniprot": "A0A5K4FE28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE28"
  },
  {
    "uniprot": "G4VBJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBJ0"
  },
  {
    "uniprot": "A0A5K4ESQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESQ0"
  },
  {
    "uniprot": "A0A5K4F6F5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6F5"
  },
  {
    "uniprot": "A0A5K4F090",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F090"
  },
  {
    "uniprot": "A0A5K4FC76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC76"
  },
  {
    "uniprot": "A0A3Q0KFE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFE7"
  },
  {
    "uniprot": "O77234",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/O77234"
  },
  {
    "uniprot": "A0A5K4EW40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW40"
  },
  {
    "uniprot": "G4VQ06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ06"
  },
  {
    "uniprot": "A0A5K4EIE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIE5"
  },
  {
    "uniprot": "A0A5K4EB14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB14"
  },
  {
    "uniprot": "A0A5K4FEH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEH9"
  },
  {
    "uniprot": "A0A5K4E8N9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8N9"
  },
  {
    "uniprot": "G4VAX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAX0"
  },
  {
    "uniprot": "A0A5K4F6W0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6W0"
  },
  {
    "uniprot": "A0A3Q0KG41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG41"
  },
  {
    "uniprot": "G4LXF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXF4"
  },
  {
    "uniprot": "A0A5K4FB31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB31"
  },
  {
    "uniprot": "A0A3Q0KNH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNH7"
  },
  {
    "uniprot": "A0A3Q0KUU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUU8"
  },
  {
    "uniprot": "G4VD86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD86"
  },
  {
    "uniprot": "A0A3Q0KS14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS14"
  },
  {
    "uniprot": "A0A5K4F726",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F726"
  },
  {
    "uniprot": "A0A5K4EA29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EA29"
  },
  {
    "uniprot": "A0A5K4FDX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDX5"
  },
  {
    "uniprot": "A0A3Q0KLB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLB2"
  },
  {
    "uniprot": "P53471",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P53471"
  },
  {
    "uniprot": "A0A5K4F6D5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6D5"
  },
  {
    "uniprot": "A0A3Q0KPN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPN4"
  },
  {
    "uniprot": "A0A5K4F240",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F240"
  },
  {
    "uniprot": "C4QM46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QM46"
  },
  {
    "uniprot": "G4M0L4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0L4"
  },
  {
    "uniprot": "A0A5K4F584",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F584"
  },
  {
    "uniprot": "G4VBM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBM1"
  },
  {
    "uniprot": "A0A3Q0KCL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCL7"
  },
  {
    "uniprot": "A0A5K4FAY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAY9"
  },
  {
    "uniprot": "Q26560",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26560"
  },
  {
    "uniprot": "A0A5K4F0E2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0E2"
  },
  {
    "uniprot": "A0A3Q0KEK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEK9"
  },
  {
    "uniprot": "P41759",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P41759"
  },
  {
    "uniprot": "A0A3Q0KJX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJX5"
  },
  {
    "uniprot": "A0A5K4F687",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F687"
  },
  {
    "uniprot": "A0A3Q0KF45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF45"
  },
  {
    "uniprot": "A0A5K4FAG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAG4"
  },
  {
    "uniprot": "G4VEH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEH2"
  },
  {
    "uniprot": "A0A5K4EE48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE48"
  },
  {
    "uniprot": "A0A5K4ED17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED17"
  },
  {
    "uniprot": "A0A3Q0KQ58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ58"
  },
  {
    "uniprot": "A0A3Q0KPB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPB5"
  },
  {
    "uniprot": "A0A5K4FBX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBX2"
  },
  {
    "uniprot": "A0A5K4F2B9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2B9"
  },
  {
    "uniprot": "A0A3Q0KMJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMJ9"
  },
  {
    "uniprot": "A0A3Q0KFE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFE5"
  },
  {
    "uniprot": "G4V848",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V848"
  },
  {
    "uniprot": "A0A5K4F808",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F808"
  },
  {
    "uniprot": "G4VP16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VP16"
  },
  {
    "uniprot": "A0A3Q0KS75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS75"
  },
  {
    "uniprot": "G4LVB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVB0"
  },
  {
    "uniprot": "G4V901",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V901"
  },
  {
    "uniprot": "A0A5K4EBP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBP8"
  },
  {
    "uniprot": "A0A5K4F4Y2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Y2"
  },
  {
    "uniprot": "A0A5K4FAZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAZ8"
  },
  {
    "uniprot": "A0A5K4FF65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF65"
  },
  {
    "uniprot": "A0A3Q0KU55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU55"
  },
  {
    "uniprot": "A0A5K4EM08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM08"
  },
  {
    "uniprot": "A0A3Q0KEP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEP2"
  },
  {
    "uniprot": "A0A5K4ESX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESX5"
  },
  {
    "uniprot": "A0A5K4F6J3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6J3"
  },
  {
    "uniprot": "G4VIH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIH0"
  },
  {
    "uniprot": "A0A5K4FGH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGH8"
  },
  {
    "uniprot": "A0A5K4FB21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB21"
  },
  {
    "uniprot": "A0A3Q0KME1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KME1"
  },
  {
    "uniprot": "A0A3Q0KV10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV10"
  },
  {
    "uniprot": "G4M0A0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0A0"
  },
  {
    "uniprot": "G4LVM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVM7"
  },
  {
    "uniprot": "A0A5K4EMU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMU0"
  },
  {
    "uniprot": "A0A5K4F571",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F571"
  },
  {
    "uniprot": "A0A5K4FEC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEC0"
  },
  {
    "uniprot": "A0A5K4FGS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGS6"
  },
  {
    "uniprot": "A0A3Q0KMN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMN7"
  },
  {
    "uniprot": "A0A5K4ECJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECJ4"
  },
  {
    "uniprot": "A0A5K4EXJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXJ3"
  },
  {
    "uniprot": "G4VJK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJK0"
  },
  {
    "uniprot": "G4LZH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZH0"
  },
  {
    "uniprot": "A0A5K4EQJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQJ9"
  },
  {
    "uniprot": "A0A5K4F8L6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8L6"
  },
  {
    "uniprot": "A0A3Q0KRG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRG2"
  },
  {
    "uniprot": "A0A3Q0KLZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLZ2"
  },
  {
    "uniprot": "A0A3Q0KNF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNF0"
  },
  {
    "uniprot": "A0A3Q0KMC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMC1"
  },
  {
    "uniprot": "A0A3Q0KRD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRD9"
  },
  {
    "uniprot": "A0A5K4F262",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F262"
  },
  {
    "uniprot": "A0A5K4EXM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXM4"
  },
  {
    "uniprot": "A0A3Q0KTY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTY3"
  },
  {
    "uniprot": "A0A5K4F6S6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6S6"
  },
  {
    "uniprot": "A0A5K4F4J7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4J7"
  },
  {
    "uniprot": "A0A3Q0KF32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF32"
  },
  {
    "uniprot": "A0A5K4FCJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCJ4"
  },
  {
    "uniprot": "A0A5K4F4B4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4B4"
  },
  {
    "uniprot": "G4VD01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD01"
  },
  {
    "uniprot": "A0A5K4FGE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGE8"
  },
  {
    "uniprot": "G4LYF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYF5"
  },
  {
    "uniprot": "A0A3Q0KRY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRY4"
  },
  {
    "uniprot": "G4VC15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC15"
  },
  {
    "uniprot": "G4VBJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBJ2"
  },
  {
    "uniprot": "A0A5K4F7U7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7U7"
  },
  {
    "uniprot": "A0A5K4ELR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELR1"
  },
  {
    "uniprot": "G4LZ30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ30"
  },
  {
    "uniprot": "A0A3Q0KJM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJM0"
  },
  {
    "uniprot": "A0A3Q0KFT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFT7"
  },
  {
    "uniprot": "A0A3Q0KKU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKU2"
  },
  {
    "uniprot": "A0A3Q0KKP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKP7"
  },
  {
    "uniprot": "A0A5K4F1S4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1S4"
  },
  {
    "uniprot": "A0A5K4F9F1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9F1"
  },
  {
    "uniprot": "G4VD88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD88"
  },
  {
    "uniprot": "A0A3Q0KIZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIZ7"
  },
  {
    "uniprot": "G4VGN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGN4"
  },
  {
    "uniprot": "G4LXY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXY1"
  },
  {
    "uniprot": "A0A5K4FGR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGR0"
  },
  {
    "uniprot": "G4VP93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VP93"
  },
  {
    "uniprot": "A0A5K4F572",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F572"
  },
  {
    "uniprot": "A0A3Q0KBU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBU7"
  },
  {
    "uniprot": "A0A3Q0KD45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD45"
  },
  {
    "uniprot": "A0A5K4EVX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVX9"
  },
  {
    "uniprot": "A0A3Q0KSB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSB1"
  },
  {
    "uniprot": "A0A5K4ENT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENT6"
  },
  {
    "uniprot": "G4LUS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUS0"
  },
  {
    "uniprot": "A0A5K4FB36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB36"
  },
  {
    "uniprot": "A0A5K4EXK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXK4"
  },
  {
    "uniprot": "A0A5K4EDP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDP2"
  },
  {
    "uniprot": "A0A3Q0KU77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU77"
  },
  {
    "uniprot": "A0A3Q0KKI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKI3"
  },
  {
    "uniprot": "A0A3Q0KE31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE31"
  },
  {
    "uniprot": "A0A3Q0KLA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLA0"
  },
  {
    "uniprot": "A0A5K4FGN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGN8"
  },
  {
    "uniprot": "A0A3Q0KEI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEI5"
  },
  {
    "uniprot": "A0A5K4FBJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBJ8"
  },
  {
    "uniprot": "A0A5K4F202",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F202"
  },
  {
    "uniprot": "A0A5K4FBD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBD2"
  },
  {
    "uniprot": "A0A3Q0KTV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTV0"
  },
  {
    "uniprot": "Q1X6L5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q1X6L5"
  },
  {
    "uniprot": "A0A3Q0KCU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCU1"
  },
  {
    "uniprot": "A0A5K4F0A3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0A3"
  },
  {
    "uniprot": "A0A5K4F2R0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2R0"
  },
  {
    "uniprot": "A0A5K4FFK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFK7"
  },
  {
    "uniprot": "A0A5K4F123",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F123"
  },
  {
    "uniprot": "A0A3Q0KMA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMA6"
  },
  {
    "uniprot": "A0A3Q0KV17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV17"
  },
  {
    "uniprot": "A0A5K4FAN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAN8"
  },
  {
    "uniprot": "A0A3Q0KPW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPW5"
  },
  {
    "uniprot": "A0A5K4F6L6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6L6"
  },
  {
    "uniprot": "A0A5K4ESA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESA6"
  },
  {
    "uniprot": "G4VLX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLX9"
  },
  {
    "uniprot": "G4VAZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAZ0"
  },
  {
    "uniprot": "A0A3Q0KUS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUS6"
  },
  {
    "uniprot": "A0A3Q0KGT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGT6"
  },
  {
    "uniprot": "A0A3Q0KE20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE20"
  },
  {
    "uniprot": "A0A5K4E9E9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9E9"
  },
  {
    "uniprot": "A0A3Q0KHY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHY4"
  },
  {
    "uniprot": "A0A5K4EMZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMZ3"
  },
  {
    "uniprot": "A0A5K4F5G6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5G6"
  },
  {
    "uniprot": "A0A5K4F9R8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9R8"
  },
  {
    "uniprot": "A0A3Q0KR71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR71"
  },
  {
    "uniprot": "A0A5K4F8M7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8M7"
  },
  {
    "uniprot": "A0A5K4EMZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMZ8"
  },
  {
    "uniprot": "A0A5K4F666",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F666"
  },
  {
    "uniprot": "A0A3Q0KRV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRV1"
  },
  {
    "uniprot": "G4LZA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZA8"
  },
  {
    "uniprot": "A0A5K4F360",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F360"
  },
  {
    "uniprot": "A0A5K4ECJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECJ3"
  },
  {
    "uniprot": "A0A5K4FD18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD18"
  },
  {
    "uniprot": "A0A5K4F7Q9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Q9"
  },
  {
    "uniprot": "A0A5K4F7M3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7M3"
  },
  {
    "uniprot": "A0A5K4ENV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENV5"
  },
  {
    "uniprot": "A0A3Q0KDV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDV5"
  },
  {
    "uniprot": "A0A3Q0KMV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMV8"
  },
  {
    "uniprot": "A0A5K4FD22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD22"
  },
  {
    "uniprot": "A0A5K4F8N2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8N2"
  },
  {
    "uniprot": "A0A3Q0KBQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBQ7"
  },
  {
    "uniprot": "A0A3Q0KLU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLU2"
  },
  {
    "uniprot": "A0A3Q0KHV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHV8"
  },
  {
    "uniprot": "A0A3Q0KTG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTG4"
  },
  {
    "uniprot": "A0A5K4F475",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F475"
  },
  {
    "uniprot": "A0A3Q0KFH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFH7"
  },
  {
    "uniprot": "A0A5K4EP20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP20"
  },
  {
    "uniprot": "A0A3Q0KLE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLE5"
  },
  {
    "uniprot": "A0A5K4EU98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU98"
  },
  {
    "uniprot": "A0A5K4EHB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHB5"
  },
  {
    "uniprot": "A0A3Q0KKX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKX0"
  },
  {
    "uniprot": "A0A5K4F6R6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6R6"
  },
  {
    "uniprot": "A0A3Q0KL13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL13"
  },
  {
    "uniprot": "A0A5K4FBT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBT9"
  },
  {
    "uniprot": "A0A5K4F0T1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0T1"
  },
  {
    "uniprot": "A0A5K4FEI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEI3"
  },
  {
    "uniprot": "A0A5K4EI56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EI56"
  },
  {
    "uniprot": "G4V626",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V626"
  },
  {
    "uniprot": "G4V6E6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6E6"
  },
  {
    "uniprot": "G4V5H1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5H1"
  },
  {
    "uniprot": "A0A5K4EQD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQD4"
  },
  {
    "uniprot": "A0A3Q0KTV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTV4"
  },
  {
    "uniprot": "A0A5K4F3Z0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Z0"
  },
  {
    "uniprot": "A0A5K4F7V0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7V0"
  },
  {
    "uniprot": "A0A5K4F426",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F426"
  },
  {
    "uniprot": "A0A3Q0KVF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVF3"
  },
  {
    "uniprot": "A0A3Q0KF37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF37"
  },
  {
    "uniprot": "A0A3Q0KST8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KST8"
  },
  {
    "uniprot": "A0A3Q0KN91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN91"
  },
  {
    "uniprot": "A0A5K4EBK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBK4"
  },
  {
    "uniprot": "A0A3Q0KC81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC81"
  },
  {
    "uniprot": "A0A3Q0KE92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE92"
  },
  {
    "uniprot": "A0A5K4EJD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJD3"
  },
  {
    "uniprot": "A0A3Q0KVK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVK8"
  },
  {
    "uniprot": "G4VSL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSL5"
  },
  {
    "uniprot": "A0A5K4EYE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYE3"
  },
  {
    "uniprot": "A0A3Q0KME9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KME9"
  },
  {
    "uniprot": "A0A3Q0KTN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTN4"
  },
  {
    "uniprot": "G4VTM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTM3"
  },
  {
    "uniprot": "C4QKR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QKR1"
  },
  {
    "uniprot": "G4LV80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV80"
  },
  {
    "uniprot": "A0A5K4FC99",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC99"
  },
  {
    "uniprot": "A0A5K4F1D1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1D1"
  },
  {
    "uniprot": "A0A5K4F8U5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8U5"
  },
  {
    "uniprot": "A0A3Q0KMJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMJ5"
  },
  {
    "uniprot": "G4VEX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEX3"
  },
  {
    "uniprot": "A0A3Q0KSS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSS6"
  },
  {
    "uniprot": "A0A3Q0KRX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRX5"
  },
  {
    "uniprot": "A0A3Q0KVE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVE4"
  },
  {
    "uniprot": "A0A3Q0KQA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQA6"
  },
  {
    "uniprot": "A0A3Q0KS93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS93"
  },
  {
    "uniprot": "A0A5K4F5Q5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Q5"
  },
  {
    "uniprot": "C4Q8S0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q8S0"
  },
  {
    "uniprot": "A0A3Q0KN70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN70"
  },
  {
    "uniprot": "A0A5K4FFJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFJ6"
  },
  {
    "uniprot": "A0A3Q0KPL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPL4"
  },
  {
    "uniprot": "A0A5K4F0U0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0U0"
  },
  {
    "uniprot": "G4LVC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVC5"
  },
  {
    "uniprot": "A0A3Q0KD89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD89"
  },
  {
    "uniprot": "G4V5P2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5P2"
  },
  {
    "uniprot": "A0A5K4F818",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F818"
  },
  {
    "uniprot": "A0A5K4F516",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F516"
  },
  {
    "uniprot": "G4VHT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHT5"
  },
  {
    "uniprot": "A0A3Q0KR83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR83"
  },
  {
    "uniprot": "A0A3Q0KRE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRE8"
  },
  {
    "uniprot": "A0A5K4F1U0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1U0"
  },
  {
    "uniprot": "A0A5K4ED86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED86"
  },
  {
    "uniprot": "A0A5K4EYV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYV7"
  },
  {
    "uniprot": "A0A3Q0KKU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKU7"
  },
  {
    "uniprot": "A0A5K4F9N8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9N8"
  },
  {
    "uniprot": "A0A5K4FAR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAR6"
  },
  {
    "uniprot": "G4LW76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LW76"
  },
  {
    "uniprot": "A0A3Q0KFN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFN7"
  },
  {
    "uniprot": "A0A5K4F8T3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8T3"
  },
  {
    "uniprot": "A0A3Q0KKR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKR9"
  },
  {
    "uniprot": "A0A3Q0KG97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG97"
  },
  {
    "uniprot": "A0A5K4F081",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F081"
  },
  {
    "uniprot": "G4VR11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR11"
  },
  {
    "uniprot": "A0A5K4EKV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKV5"
  },
  {
    "uniprot": "A0A3Q0KQX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQX0"
  },
  {
    "uniprot": "A0A5K4FC63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC63"
  },
  {
    "uniprot": "A0A5K4EJN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJN1"
  },
  {
    "uniprot": "G4LW71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LW71"
  },
  {
    "uniprot": "A0A5K4F434",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F434"
  },
  {
    "uniprot": "A0A5K4FCR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCR4"
  },
  {
    "uniprot": "A0A3Q0KH94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH94"
  },
  {
    "uniprot": "A0A3Q0KQ03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ03"
  },
  {
    "uniprot": "A0A3Q0KFG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFG0"
  },
  {
    "uniprot": "A0A5K4F8H0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8H0"
  },
  {
    "uniprot": "A0A3Q0KQL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQL3"
  },
  {
    "uniprot": "A0A5K4FAD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAD8"
  },
  {
    "uniprot": "A0A5K4F9Q7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Q7"
  },
  {
    "uniprot": "A0A5K4FA39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA39"
  },
  {
    "uniprot": "Q27779",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q27779"
  },
  {
    "uniprot": "A0A5K4EPC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPC1"
  },
  {
    "uniprot": "A0A3Q0KN29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN29"
  },
  {
    "uniprot": "A0A5K4F2C8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2C8"
  },
  {
    "uniprot": "A0A5K4F9H6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9H6"
  },
  {
    "uniprot": "A0A5K4EVM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVM2"
  },
  {
    "uniprot": "A0A5K4F5V3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5V3"
  },
  {
    "uniprot": "A0A5K4FAU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAU5"
  },
  {
    "uniprot": "A0A5K4F5Y8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Y8"
  },
  {
    "uniprot": "A0A5K4F5J8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5J8"
  },
  {
    "uniprot": "G4LU78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LU78"
  },
  {
    "uniprot": "G4VPT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPT0"
  },
  {
    "uniprot": "A0A3Q0KU85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU85"
  },
  {
    "uniprot": "A0A3Q0KBE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBE4"
  },
  {
    "uniprot": "A0A3Q0KL90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL90"
  },
  {
    "uniprot": "A0A5K4EUX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUX4"
  },
  {
    "uniprot": "A0A3Q0KDT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDT5"
  },
  {
    "uniprot": "A0A5K4EFF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFF3"
  },
  {
    "uniprot": "G4LZ25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ25"
  },
  {
    "uniprot": "G4VE41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VE41"
  },
  {
    "uniprot": "A0A5K4F949",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F949"
  },
  {
    "uniprot": "A0A5K4FD71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD71"
  },
  {
    "uniprot": "A0A5K4F226",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F226"
  },
  {
    "uniprot": "A0A5K4FC59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC59"
  },
  {
    "uniprot": "A0A5K4F817",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F817"
  },
  {
    "uniprot": "A0A5K4FBQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBQ9"
  },
  {
    "uniprot": "A0A5K4FE55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE55"
  },
  {
    "uniprot": "G4LYZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYZ0"
  },
  {
    "uniprot": "A0A5K4F5W1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5W1"
  },
  {
    "uniprot": "A0A5K4F0B9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0B9"
  },
  {
    "uniprot": "A0A5K4F4M6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4M6"
  },
  {
    "uniprot": "A0A5K4FAG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAG5"
  },
  {
    "uniprot": "A0A5K4F5B6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5B6"
  },
  {
    "uniprot": "A0A3Q0KPN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPN0"
  },
  {
    "uniprot": "A0A3Q0KN20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN20"
  },
  {
    "uniprot": "A0A5K4F113",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F113"
  },
  {
    "uniprot": "A0A5K4EU18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU18"
  },
  {
    "uniprot": "A0A3Q0KVK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVK6"
  },
  {
    "uniprot": "A0A5K4EVI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVI3"
  },
  {
    "uniprot": "A0A3Q0KSM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSM3"
  },
  {
    "uniprot": "A0A3Q0KGF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGF1"
  },
  {
    "uniprot": "A0A5K4EMV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMV2"
  },
  {
    "uniprot": "A0A3Q0KQ47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ47"
  },
  {
    "uniprot": "A0A5K4F8E1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8E1"
  },
  {
    "uniprot": "A0A5K4F978",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F978"
  },
  {
    "uniprot": "Q86QQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q86QQ6"
  },
  {
    "uniprot": "A0A5K4F6L2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6L2"
  },
  {
    "uniprot": "A0A3Q0KP53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP53"
  },
  {
    "uniprot": "A0A5K4EUD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUD2"
  },
  {
    "uniprot": "G4M0M1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0M1"
  },
  {
    "uniprot": "G4VE75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VE75"
  },
  {
    "uniprot": "A0A5K4F6T1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6T1"
  },
  {
    "uniprot": "A0A3Q0KM79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM79"
  },
  {
    "uniprot": "G4VJP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJP4"
  },
  {
    "uniprot": "G4V5J2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5J2"
  },
  {
    "uniprot": "A0A5K4EMD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMD8"
  },
  {
    "uniprot": "B8Y6H4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/B8Y6H4"
  },
  {
    "uniprot": "A0A5K4F8V5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8V5"
  },
  {
    "uniprot": "A0A3Q0KIB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIB8"
  },
  {
    "uniprot": "A0A5K4F3F7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3F7"
  },
  {
    "uniprot": "A0A3Q0KLZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLZ3"
  },
  {
    "uniprot": "A0A3Q0KTK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTK0"
  },
  {
    "uniprot": "G4VHC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHC6"
  },
  {
    "uniprot": "G4LWK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWK0"
  },
  {
    "uniprot": "A0A5K4F6R7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6R7"
  },
  {
    "uniprot": "A0A5K4F1E9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1E9"
  },
  {
    "uniprot": "A0A5K4F559",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F559"
  },
  {
    "uniprot": "G4VB37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB37"
  },
  {
    "uniprot": "A0A3Q0KTX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTX5"
  },
  {
    "uniprot": "A0A5K4ET54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET54"
  },
  {
    "uniprot": "A0A5K4F4R5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4R5"
  },
  {
    "uniprot": "A0A3Q0KR56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR56"
  },
  {
    "uniprot": "A0A3Q0KC86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC86"
  },
  {
    "uniprot": "A0A5K4F677",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F677"
  },
  {
    "uniprot": "A0A3Q0KNM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNM4"
  },
  {
    "uniprot": "A0A5K4FA12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA12"
  },
  {
    "uniprot": "A0A5K4F718",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F718"
  },
  {
    "uniprot": "G4VNL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNL4"
  },
  {
    "uniprot": "A0A5K4F8W4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8W4"
  },
  {
    "uniprot": "A0A3Q0KDG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDG6"
  },
  {
    "uniprot": "A0A5K4FAJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAJ9"
  },
  {
    "uniprot": "A0A5K4F729",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F729"
  },
  {
    "uniprot": "A0A5K4F050",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F050"
  },
  {
    "uniprot": "A0A3Q0KE09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE09"
  },
  {
    "uniprot": "A0A5K4FE46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE46"
  },
  {
    "uniprot": "G4V7U7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7U7"
  },
  {
    "uniprot": "A0A3Q0KBT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBT9"
  },
  {
    "uniprot": "A0A3Q0KTM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTM2"
  },
  {
    "uniprot": "A0A5K4F0G6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0G6"
  },
  {
    "uniprot": "A0A3Q0KLN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLN5"
  },
  {
    "uniprot": "A0A5K4F0F4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0F4"
  },
  {
    "uniprot": "A0A5K4F7H6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7H6"
  },
  {
    "uniprot": "C1M0Q3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C1M0Q3"
  },
  {
    "uniprot": "A0A3Q0KFL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFL8"
  },
  {
    "uniprot": "A0A5K4FDE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDE5"
  },
  {
    "uniprot": "A0A5K4F5S1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5S1"
  },
  {
    "uniprot": "A0A3Q0KL57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL57"
  },
  {
    "uniprot": "G4V8A2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8A2"
  },
  {
    "uniprot": "A0A5K4FBP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBP2"
  },
  {
    "uniprot": "A0A5K4F7F0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7F0"
  },
  {
    "uniprot": "A0A5K4ETJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETJ7"
  },
  {
    "uniprot": "A0A5K4EDH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDH9"
  },
  {
    "uniprot": "A0A5K4EGT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGT1"
  },
  {
    "uniprot": "A0A5K4EV95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV95"
  },
  {
    "uniprot": "A0A5K4F5U1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5U1"
  },
  {
    "uniprot": "G4VS08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS08"
  },
  {
    "uniprot": "C4Q5F5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q5F5"
  },
  {
    "uniprot": "A0A3Q0KNP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNP5"
  },
  {
    "uniprot": "C1M2M5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C1M2M5"
  },
  {
    "uniprot": "A0A5K4FAM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAM9"
  },
  {
    "uniprot": "A0A5K4F8F2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8F2"
  },
  {
    "uniprot": "A0A5K4EJN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJN6"
  },
  {
    "uniprot": "A0A3Q0KS43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS43"
  },
  {
    "uniprot": "A0A3Q0KCQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCQ5"
  },
  {
    "uniprot": "A0A5K4F2G1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2G1"
  },
  {
    "uniprot": "A0A3Q0KI42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI42"
  },
  {
    "uniprot": "A0A5K4EIV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIV3"
  },
  {
    "uniprot": "A0A5K4FAH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAH5"
  },
  {
    "uniprot": "A0A5K4EYQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYQ1"
  },
  {
    "uniprot": "A0A5K4FBA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBA2"
  },
  {
    "uniprot": "A0A5K4FDG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDG2"
  },
  {
    "uniprot": "A0A5K4ENA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENA8"
  },
  {
    "uniprot": "A0A3Q0KTN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTN0"
  },
  {
    "uniprot": "A0A5K4FAX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAX5"
  },
  {
    "uniprot": "G4V6T7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6T7"
  },
  {
    "uniprot": "A0A5K4EBV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBV3"
  },
  {
    "uniprot": "G4LXV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXV8"
  },
  {
    "uniprot": "A0A5K4FA10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA10"
  },
  {
    "uniprot": "A0A5K4FA35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA35"
  },
  {
    "uniprot": "A0A5K4F108",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F108"
  },
  {
    "uniprot": "A0A5K4F3P4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3P4"
  },
  {
    "uniprot": "A0A3Q0KRD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRD2"
  },
  {
    "uniprot": "A0A5K4EZS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZS0"
  },
  {
    "uniprot": "A0A5K4FEF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEF8"
  },
  {
    "uniprot": "P12546",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P12546"
  },
  {
    "uniprot": "A0A3Q0KF72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF72"
  },
  {
    "uniprot": "A0A5K4F9V7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9V7"
  },
  {
    "uniprot": "A0A5K4FB39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB39"
  },
  {
    "uniprot": "A0A3Q0KKZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKZ2"
  },
  {
    "uniprot": "A0A5K4FC08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC08"
  },
  {
    "uniprot": "A0A5K4F3B6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3B6"
  },
  {
    "uniprot": "A0A3Q0KQE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQE6"
  },
  {
    "uniprot": "A0A3Q0KDV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDV1"
  },
  {
    "uniprot": "G4LWW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWW0"
  },
  {
    "uniprot": "G4VS61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS61"
  },
  {
    "uniprot": "A0A5K4F951",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F951"
  },
  {
    "uniprot": "A0A5K4F7L2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7L2"
  },
  {
    "uniprot": "G4VSX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSX4"
  },
  {
    "uniprot": "A0A5K4FEW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEW4"
  },
  {
    "uniprot": "A0A3Q0KRA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRA0"
  },
  {
    "uniprot": "A0A5K4F2N7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2N7"
  },
  {
    "uniprot": "G4VT75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT75"
  },
  {
    "uniprot": "A0A5K4F1L2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1L2"
  },
  {
    "uniprot": "A0A3Q0KPS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPS7"
  },
  {
    "uniprot": "A0A5K4F271",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F271"
  },
  {
    "uniprot": "A0A5K4EG23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EG23"
  },
  {
    "uniprot": "A0A5K4FDK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDK4"
  },
  {
    "uniprot": "A0A3Q0KIX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIX7"
  },
  {
    "uniprot": "A0A5K4F4V2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4V2"
  },
  {
    "uniprot": "A0A3Q0KDP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDP6"
  },
  {
    "uniprot": "G4VDJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDJ1"
  },
  {
    "uniprot": "A0A5K4FFQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFQ4"
  },
  {
    "uniprot": "A0A5K4EUD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUD5"
  },
  {
    "uniprot": "A0A5K4FCD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCD9"
  },
  {
    "uniprot": "A0A3Q0KSC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSC6"
  },
  {
    "uniprot": "A0A5K4EXP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXP1"
  },
  {
    "uniprot": "G4LVS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVS2"
  },
  {
    "uniprot": "A0A5K4FE10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE10"
  },
  {
    "uniprot": "A0A3Q0KBC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBC4"
  },
  {
    "uniprot": "A0A3Q0KET3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KET3"
  },
  {
    "uniprot": "A0A5K4ENP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENP2"
  },
  {
    "uniprot": "G4LX07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX07"
  },
  {
    "uniprot": "G4V5X8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5X8"
  },
  {
    "uniprot": "A0A5K4EAA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAA0"
  },
  {
    "uniprot": "G4VDN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDN9"
  },
  {
    "uniprot": "A0A3Q0KB98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KB98"
  },
  {
    "uniprot": "A0A3Q0KM78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM78"
  },
  {
    "uniprot": "A0A5K4F8P8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8P8"
  },
  {
    "uniprot": "A0A3Q0KRB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRB5"
  },
  {
    "uniprot": "A0A5K4FG88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FG88"
  },
  {
    "uniprot": "A0A5K4F0E4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0E4"
  },
  {
    "uniprot": "A0A5K4ER74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER74"
  },
  {
    "uniprot": "A0A5K4EFL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFL5"
  },
  {
    "uniprot": "A0A5K4F8L5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8L5"
  },
  {
    "uniprot": "A0A3Q0KGK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGK4"
  },
  {
    "uniprot": "A0A5K4F1U8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1U8"
  },
  {
    "uniprot": "A0A5K4F3R5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3R5"
  },
  {
    "uniprot": "A0A5K4EZM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZM4"
  },
  {
    "uniprot": "A0A3Q0KQP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQP4"
  },
  {
    "uniprot": "A0A5K4EZC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZC6"
  },
  {
    "uniprot": "A0A3Q0KDX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDX0"
  },
  {
    "uniprot": "A0A5K4F2X9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2X9"
  },
  {
    "uniprot": "A0A3Q0KSJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSJ8"
  },
  {
    "uniprot": "A0A5K4F5Y5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Y5"
  },
  {
    "uniprot": "A0A5K4EXM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXM2"
  },
  {
    "uniprot": "A0A5K4EVL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVL6"
  },
  {
    "uniprot": "G4VQQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQQ9"
  },
  {
    "uniprot": "G4V8R6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8R6"
  },
  {
    "uniprot": "A0A3Q0KRA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRA9"
  },
  {
    "uniprot": "A0A5K4ELS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELS2"
  },
  {
    "uniprot": "A0A3Q0KQU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQU1"
  },
  {
    "uniprot": "A0A5K4F4S2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4S2"
  },
  {
    "uniprot": "A0A5K4F0Q4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Q4"
  },
  {
    "uniprot": "A0A5K4EHF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHF3"
  },
  {
    "uniprot": "A0A3Q0KUD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUD9"
  },
  {
    "uniprot": "A0A3Q0KI45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI45"
  },
  {
    "uniprot": "A0A5K4F7M0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7M0"
  },
  {
    "uniprot": "A0A5K4F1I1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1I1"
  },
  {
    "uniprot": "A0A5K4F2W1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2W1"
  },
  {
    "uniprot": "G4LZL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZL1"
  },
  {
    "uniprot": "A0A5K4FEB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEB6"
  },
  {
    "uniprot": "A0A3Q0KU67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU67"
  },
  {
    "uniprot": "A0A3Q0KPR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPR9"
  },
  {
    "uniprot": "G4VML4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VML4"
  },
  {
    "uniprot": "A0A5K4EQJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQJ6"
  },
  {
    "uniprot": "A0A5K4F128",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F128"
  },
  {
    "uniprot": "A0A5K4F5V5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5V5"
  },
  {
    "uniprot": "A0A5K4FEP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEP3"
  },
  {
    "uniprot": "A0A3Q0KPF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPF4"
  },
  {
    "uniprot": "A0A3Q0KMX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMX9"
  },
  {
    "uniprot": "G4VCG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCG3"
  },
  {
    "uniprot": "A0A5K4EUF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUF5"
  },
  {
    "uniprot": "A0A3Q0KU70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU70"
  },
  {
    "uniprot": "A0A3Q0KPZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPZ9"
  },
  {
    "uniprot": "A0A3Q0KQE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQE7"
  },
  {
    "uniprot": "A0A3Q0KPK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPK8"
  },
  {
    "uniprot": "G4V5D3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5D3"
  },
  {
    "uniprot": "A0A3Q0KUE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUE8"
  },
  {
    "uniprot": "A0A5K4FEN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEN5"
  },
  {
    "uniprot": "A0A5K4F1C5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1C5"
  },
  {
    "uniprot": "A0A5K4F9Q5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Q5"
  },
  {
    "uniprot": "A0A5K4F055",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F055"
  },
  {
    "uniprot": "A0A3Q0KEJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEJ5"
  },
  {
    "uniprot": "A0A5K4FCH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCH3"
  },
  {
    "uniprot": "A0A5K4F756",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F756"
  },
  {
    "uniprot": "A0A5K4ER35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER35"
  },
  {
    "uniprot": "A0A5K4FCW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCW2"
  },
  {
    "uniprot": "A0A5K4F2Y4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Y4"
  },
  {
    "uniprot": "G4LXI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXI9"
  },
  {
    "uniprot": "G4VFC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFC2"
  },
  {
    "uniprot": "G4VLL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLL6"
  },
  {
    "uniprot": "A0A3Q0KTP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTP2"
  },
  {
    "uniprot": "P23126",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P23126"
  },
  {
    "uniprot": "G4VBI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBI8"
  },
  {
    "uniprot": "A0A5K4EVW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVW6"
  },
  {
    "uniprot": "G4VGX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGX4"
  },
  {
    "uniprot": "A0A5K4EVZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVZ1"
  },
  {
    "uniprot": "C4QDC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QDC9"
  },
  {
    "uniprot": "A0A5K4F9M4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9M4"
  },
  {
    "uniprot": "G4VC44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC44"
  },
  {
    "uniprot": "A0A5K4F6Y5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Y5"
  },
  {
    "uniprot": "A0A3Q0KDG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDG5"
  },
  {
    "uniprot": "A0A3Q0KCW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCW2"
  },
  {
    "uniprot": "G4VJ35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ35"
  },
  {
    "uniprot": "A0A5K4ESE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESE1"
  },
  {
    "uniprot": "A0A5K4F119",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F119"
  },
  {
    "uniprot": "A0A3Q0KRL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRL0"
  },
  {
    "uniprot": "A0A5K4FAI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAI0"
  },
  {
    "uniprot": "A0A5K4EL79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL79"
  },
  {
    "uniprot": "A0A3Q0KE29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE29"
  },
  {
    "uniprot": "A0A3Q0KJH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJH3"
  },
  {
    "uniprot": "G4LVP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVP3"
  },
  {
    "uniprot": "A0A3Q0KPA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPA2"
  },
  {
    "uniprot": "A0A3Q0KJ04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ04"
  },
  {
    "uniprot": "G4VSA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSA0"
  },
  {
    "uniprot": "A0A3Q0KGK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGK8"
  },
  {
    "uniprot": "A0A5K4F7K5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7K5"
  },
  {
    "uniprot": "Q15EU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q15EU0"
  },
  {
    "uniprot": "A0A3Q0KPX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPX2"
  },
  {
    "uniprot": "A0A3Q0KN67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN67"
  },
  {
    "uniprot": "A0A3Q0KF19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF19"
  },
  {
    "uniprot": "A0A5K4ET71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET71"
  },
  {
    "uniprot": "A0A5K4F5Z9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Z9"
  },
  {
    "uniprot": "A0A3Q0KJ12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ12"
  },
  {
    "uniprot": "A0A5K4F5N6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5N6"
  },
  {
    "uniprot": "G4VAR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAR9"
  },
  {
    "uniprot": "A0A5K4FBU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBU3"
  },
  {
    "uniprot": "A0A5K4FGM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGM0"
  },
  {
    "uniprot": "G4VHS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHS1"
  },
  {
    "uniprot": "A0A5K4EEK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEK5"
  },
  {
    "uniprot": "A0A5K4F972",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F972"
  },
  {
    "uniprot": "A0A5K4F751",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F751"
  },
  {
    "uniprot": "G4V8E4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8E4"
  },
  {
    "uniprot": "C4QP66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QP66"
  },
  {
    "uniprot": "A0A5K4EPL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPL1"
  },
  {
    "uniprot": "A0A3Q0KBU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBU5"
  },
  {
    "uniprot": "A0A5K4FAS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAS8"
  },
  {
    "uniprot": "A0A5K4FCY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCY1"
  },
  {
    "uniprot": "G4VJL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJL1"
  },
  {
    "uniprot": "A0A3Q0KT04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT04"
  },
  {
    "uniprot": "A0A3Q0KKR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKR6"
  },
  {
    "uniprot": "G4VPU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPU9"
  },
  {
    "uniprot": "A0A5K4F001",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F001"
  },
  {
    "uniprot": "A0A5K4FD24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD24"
  },
  {
    "uniprot": "G4M0W7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0W7"
  },
  {
    "uniprot": "A0A3Q0KJ51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ51"
  },
  {
    "uniprot": "G4VAI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAI7"
  },
  {
    "uniprot": "A0A5K4F177",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F177"
  },
  {
    "uniprot": "A0A5K4F8Y3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Y3"
  },
  {
    "uniprot": "G4VCH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCH8"
  },
  {
    "uniprot": "A0A5K4FFE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFE7"
  },
  {
    "uniprot": "A0A5K4F8G1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8G1"
  },
  {
    "uniprot": "A0A5K4ESX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESX2"
  },
  {
    "uniprot": "A0A5K4F1X1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1X1"
  },
  {
    "uniprot": "A0A5K4F3Q6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Q6"
  },
  {
    "uniprot": "A0A3Q0KRS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRS5"
  },
  {
    "uniprot": "A0A3Q0KP87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP87"
  },
  {
    "uniprot": "G4VT24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT24"
  },
  {
    "uniprot": "A0A5K4FD81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD81"
  },
  {
    "uniprot": "G4VIY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIY7"
  },
  {
    "uniprot": "A0A3Q0KRJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRJ3"
  },
  {
    "uniprot": "A0A5K4F784",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F784"
  },
  {
    "uniprot": "A0A5K4FDQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDQ1"
  },
  {
    "uniprot": "A0A5K4F031",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F031"
  },
  {
    "uniprot": "G4VQH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQH7"
  },
  {
    "uniprot": "A0A3Q0KP44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP44"
  },
  {
    "uniprot": "A0A5K4F895",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F895"
  },
  {
    "uniprot": "A0A3Q0KQ80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ80"
  },
  {
    "uniprot": "A0A5K4ESJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESJ7"
  },
  {
    "uniprot": "A0A5K4F2K9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2K9"
  },
  {
    "uniprot": "A0A3Q0KDZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDZ1"
  },
  {
    "uniprot": "A0A5K4F7C0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7C0"
  },
  {
    "uniprot": "A0A5K4FC06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC06"
  },
  {
    "uniprot": "A0A3Q0KPX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPX1"
  },
  {
    "uniprot": "A0A3Q0KM22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM22"
  },
  {
    "uniprot": "A0A5K4F4A8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4A8"
  },
  {
    "uniprot": "A0A3Q0KVB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVB1"
  },
  {
    "uniprot": "A0A5K4F627",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F627"
  },
  {
    "uniprot": "A0A3Q0KQ76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ76"
  },
  {
    "uniprot": "A0A5K4FAV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAV5"
  },
  {
    "uniprot": "A0A5K4F7Y9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Y9"
  },
  {
    "uniprot": "A0A5K4F703",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F703"
  },
  {
    "uniprot": "A0A5K4EGD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGD2"
  },
  {
    "uniprot": "A0A3Q0KPH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPH0"
  },
  {
    "uniprot": "A0A5K4EX66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX66"
  },
  {
    "uniprot": "A0A3Q0KE26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE26"
  },
  {
    "uniprot": "A0A5K4FBB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBB5"
  },
  {
    "uniprot": "A0A5K4FGT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGT9"
  },
  {
    "uniprot": "A0A3Q0KIF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIF0"
  },
  {
    "uniprot": "A0A5K4F3R8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3R8"
  },
  {
    "uniprot": "C4QC82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QC82"
  },
  {
    "uniprot": "G4V6S6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6S6"
  },
  {
    "uniprot": "A0A5K4FBL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBL2"
  },
  {
    "uniprot": "A0A3Q0KU53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU53"
  },
  {
    "uniprot": "A0A3Q0KGA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGA6"
  },
  {
    "uniprot": "A0A3Q0KG77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG77"
  },
  {
    "uniprot": "A0A5K4FAT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAT6"
  },
  {
    "uniprot": "A0A3Q0KQB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQB1"
  },
  {
    "uniprot": "A0A5K4E8B4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8B4"
  },
  {
    "uniprot": "A0A3Q0KP77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP77"
  },
  {
    "uniprot": "G4VMG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMG7"
  },
  {
    "uniprot": "A0A5K4F933",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F933"
  },
  {
    "uniprot": "G4VS06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS06"
  },
  {
    "uniprot": "A0A5K4F6G1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6G1"
  },
  {
    "uniprot": "A0A5K4F4A4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4A4"
  },
  {
    "uniprot": "A0A5K4FBK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBK2"
  },
  {
    "uniprot": "G4VA75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA75"
  },
  {
    "uniprot": "A0A5K4ET18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET18"
  },
  {
    "uniprot": "A0A5K4EJD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJD9"
  },
  {
    "uniprot": "A0A3Q0KST0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KST0"
  },
  {
    "uniprot": "A0A5K4FBP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBP1"
  },
  {
    "uniprot": "G4V9G9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9G9"
  },
  {
    "uniprot": "A0A5K4FGJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGJ5"
  },
  {
    "uniprot": "A0A5K4F3M8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3M8"
  },
  {
    "uniprot": "A0A3Q0KT61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT61"
  },
  {
    "uniprot": "A0A3Q0KTI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTI4"
  },
  {
    "uniprot": "C4QDC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QDC5"
  },
  {
    "uniprot": "A0A5K4F9J1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9J1"
  },
  {
    "uniprot": "A0A5K4EFG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFG5"
  },
  {
    "uniprot": "A0A3Q0KHF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHF3"
  },
  {
    "uniprot": "A0A5K4F8L1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8L1"
  },
  {
    "uniprot": "A0A5K4EQG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQG3"
  },
  {
    "uniprot": "A0A3Q0KP80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP80"
  },
  {
    "uniprot": "G4VMT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMT8"
  },
  {
    "uniprot": "A0A3Q0KET8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KET8"
  },
  {
    "uniprot": "G4LW82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LW82"
  },
  {
    "uniprot": "G4V638",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V638"
  },
  {
    "uniprot": "G4VEG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEG1"
  },
  {
    "uniprot": "A0A5K4F5W6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5W6"
  },
  {
    "uniprot": "A0A5K4EL23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL23"
  },
  {
    "uniprot": "A0A3Q0KUE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUE7"
  },
  {
    "uniprot": "A0A3Q0KU21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU21"
  },
  {
    "uniprot": "A0A3Q0KM96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM96"
  },
  {
    "uniprot": "A0A3Q0KI50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI50"
  },
  {
    "uniprot": "A0A3Q0KJL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJL8"
  },
  {
    "uniprot": "A0A5K4F9U5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9U5"
  },
  {
    "uniprot": "A0A5K4F3C8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3C8"
  },
  {
    "uniprot": "A0A5K4FAM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAM4"
  },
  {
    "uniprot": "A0A3Q0KLE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLE8"
  },
  {
    "uniprot": "A0A5K4F9K0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9K0"
  },
  {
    "uniprot": "A0A5K4F7T1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7T1"
  },
  {
    "uniprot": "A0A3Q0KQM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQM9"
  },
  {
    "uniprot": "A0A5K4F412",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F412"
  },
  {
    "uniprot": "A0A5K4F699",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F699"
  },
  {
    "uniprot": "A0A3Q0KHM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHM5"
  },
  {
    "uniprot": "A0A5K4EN88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN88"
  },
  {
    "uniprot": "A0A3Q0KFC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFC3"
  },
  {
    "uniprot": "G4V7R9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7R9"
  },
  {
    "uniprot": "A0A5K4ERI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERI6"
  },
  {
    "uniprot": "A0A5K4FFN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFN2"
  },
  {
    "uniprot": "A0A5K4F544",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F544"
  },
  {
    "uniprot": "A0A3Q0KQ96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ96"
  },
  {
    "uniprot": "A0A3Q0KF43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF43"
  },
  {
    "uniprot": "A0A3Q0KL70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL70"
  },
  {
    "uniprot": "A0A5K4F7X9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7X9"
  },
  {
    "uniprot": "A0A5K4EZT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZT6"
  },
  {
    "uniprot": "A0A5K4F2U2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2U2"
  },
  {
    "uniprot": "A0A5K4EMB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMB4"
  },
  {
    "uniprot": "A0A5K4FBI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBI7"
  },
  {
    "uniprot": "A0A5K4FCF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCF8"
  },
  {
    "uniprot": "A0A5K4F3X7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3X7"
  },
  {
    "uniprot": "G4LY72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY72"
  },
  {
    "uniprot": "A0A3Q0KI92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI92"
  },
  {
    "uniprot": "A0A3Q0KQ57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ57"
  },
  {
    "uniprot": "A0A5K4F473",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F473"
  },
  {
    "uniprot": "A0A5K4F846",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F846"
  },
  {
    "uniprot": "A0A5K4F9K5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9K5"
  },
  {
    "uniprot": "A0A5K4ELI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELI1"
  },
  {
    "uniprot": "A0A3Q0KI10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI10"
  },
  {
    "uniprot": "A0A3Q0KM60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM60"
  },
  {
    "uniprot": "A0A3Q0KLS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLS0"
  },
  {
    "uniprot": "A0A3Q0KSP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSP0"
  },
  {
    "uniprot": "A0A3Q0KBP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBP8"
  },
  {
    "uniprot": "A0A3Q0KT03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT03"
  },
  {
    "uniprot": "A0A5K4F8T1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8T1"
  },
  {
    "uniprot": "A0A3Q0KRG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRG7"
  },
  {
    "uniprot": "A0A5K4F2S3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2S3"
  },
  {
    "uniprot": "A0A5K4E8W7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8W7"
  },
  {
    "uniprot": "A0A5K4FCK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCK1"
  },
  {
    "uniprot": "A0A5K4FEX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEX6"
  },
  {
    "uniprot": "G4V5E8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5E8"
  },
  {
    "uniprot": "G4LZC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZC7"
  },
  {
    "uniprot": "A0A5K4EWE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWE6"
  },
  {
    "uniprot": "A0A3Q0KL91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL91"
  },
  {
    "uniprot": "Q15ES3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q15ES3"
  },
  {
    "uniprot": "A0A5K4FD85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD85"
  },
  {
    "uniprot": "G4LWL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWL5"
  },
  {
    "uniprot": "A0A5K4EER6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EER6"
  },
  {
    "uniprot": "A0A3Q0KCM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCM0"
  },
  {
    "uniprot": "A0A3Q0KKZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKZ3"
  },
  {
    "uniprot": "A0A5K4EAP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAP4"
  },
  {
    "uniprot": "A0A3Q0KNZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNZ4"
  },
  {
    "uniprot": "G4VIB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIB2"
  },
  {
    "uniprot": "A0A5K4F6T2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6T2"
  },
  {
    "uniprot": "A0A5K4F5P1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5P1"
  },
  {
    "uniprot": "A0A5K4F2X6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2X6"
  },
  {
    "uniprot": "A0A5K4EJS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJS3"
  },
  {
    "uniprot": "A0A3Q0KTW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTW5"
  },
  {
    "uniprot": "G4V6J3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6J3"
  },
  {
    "uniprot": "A0A3Q0KK66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK66"
  },
  {
    "uniprot": "A0A5K4F8K1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8K1"
  },
  {
    "uniprot": "A0A3Q0KTH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTH8"
  },
  {
    "uniprot": "A0A5K4F397",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F397"
  },
  {
    "uniprot": "A0A5K4F580",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F580"
  },
  {
    "uniprot": "G4LYB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYB8"
  },
  {
    "uniprot": "A0A5K4E9T7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9T7"
  },
  {
    "uniprot": "G4VP31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VP31"
  },
  {
    "uniprot": "A0A5K4FFH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFH3"
  },
  {
    "uniprot": "G4VK02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK02"
  },
  {
    "uniprot": "Q26551",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26551"
  },
  {
    "uniprot": "A0A3Q0KU74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU74"
  },
  {
    "uniprot": "A0A5K4FGU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGU5"
  },
  {
    "uniprot": "G4VIB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIB7"
  },
  {
    "uniprot": "A0A146MI69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MI69"
  },
  {
    "uniprot": "G4VBL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBL5"
  },
  {
    "uniprot": "A0A3Q0KDD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDD1"
  },
  {
    "uniprot": "A0A3Q0KKY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKY8"
  },
  {
    "uniprot": "G4LX94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX94"
  },
  {
    "uniprot": "G4LVS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVS4"
  },
  {
    "uniprot": "A0A5K4F2B3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2B3"
  },
  {
    "uniprot": "A0A5K4FF32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF32"
  },
  {
    "uniprot": "A0A5K4F069",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F069"
  },
  {
    "uniprot": "A0A5K4EC22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EC22"
  },
  {
    "uniprot": "A0A5K4FDB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDB8"
  },
  {
    "uniprot": "A0A5K4F167",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F167"
  },
  {
    "uniprot": "G4VRP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRP8"
  },
  {
    "uniprot": "A0A3Q0KJ69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ69"
  },
  {
    "uniprot": "G4VFR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFR3"
  },
  {
    "uniprot": "A0A5K4F2S4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2S4"
  },
  {
    "uniprot": "G4VPP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPP3"
  },
  {
    "uniprot": "A0A5K4EZI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZI9"
  },
  {
    "uniprot": "A0A5K4FCU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCU2"
  },
  {
    "uniprot": "G4VC47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC47"
  },
  {
    "uniprot": "A0A5K4EQZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQZ6"
  },
  {
    "uniprot": "A0A5K4F7A6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7A6"
  },
  {
    "uniprot": "A0A3Q0KBG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBG3"
  },
  {
    "uniprot": "A0A5K4F745",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F745"
  },
  {
    "uniprot": "G4V9G5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9G5"
  },
  {
    "uniprot": "A0A5K4F7W7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7W7"
  },
  {
    "uniprot": "A0A5K4F9E2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9E2"
  },
  {
    "uniprot": "A0A5K4FA14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA14"
  },
  {
    "uniprot": "A0A5K4EG02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EG02"
  },
  {
    "uniprot": "A0A3Q0KHY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHY7"
  },
  {
    "uniprot": "A0A3Q0KQC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQC9"
  },
  {
    "uniprot": "A0A5K4FF39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF39"
  },
  {
    "uniprot": "G4VN21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VN21"
  },
  {
    "uniprot": "A0A3Q0KMQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMQ6"
  },
  {
    "uniprot": "A0A5K4ETU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETU3"
  },
  {
    "uniprot": "C4QP69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QP69"
  },
  {
    "uniprot": "G4LZ80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ80"
  },
  {
    "uniprot": "A0A3Q0KKT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKT0"
  },
  {
    "uniprot": "G4LYL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYL4"
  },
  {
    "uniprot": "E7BBH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/E7BBH9"
  },
  {
    "uniprot": "A0A3Q0KRB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRB2"
  },
  {
    "uniprot": "A0A5K4F9S3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9S3"
  },
  {
    "uniprot": "A0A3Q0KLF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLF5"
  },
  {
    "uniprot": "A0A5K4EU58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU58"
  },
  {
    "uniprot": "A0A5K4E9T0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9T0"
  },
  {
    "uniprot": "A0A5K4EZD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZD9"
  },
  {
    "uniprot": "A0A5K4EAR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAR5"
  },
  {
    "uniprot": "A0A3Q0KLU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLU0"
  },
  {
    "uniprot": "A0A5K4F3N0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3N0"
  },
  {
    "uniprot": "A0A3Q0KH29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH29"
  },
  {
    "uniprot": "A0A3Q0KKJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKJ9"
  },
  {
    "uniprot": "G4VHR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHR0"
  },
  {
    "uniprot": "A0A5K4ERL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERL2"
  },
  {
    "uniprot": "G4VK64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK64"
  },
  {
    "uniprot": "A0A5K4FFG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFG6"
  },
  {
    "uniprot": "G4VM15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM15"
  },
  {
    "uniprot": "C4Q5J8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q5J8"
  },
  {
    "uniprot": "A0A3Q0KKY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKY4"
  },
  {
    "uniprot": "A0A3Q0KMV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMV1"
  },
  {
    "uniprot": "A0A3Q0KIU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIU7"
  },
  {
    "uniprot": "G4V764",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V764"
  },
  {
    "uniprot": "G4VK24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK24"
  },
  {
    "uniprot": "G4VPM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPM7"
  },
  {
    "uniprot": "A0A5K4EUK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUK9"
  },
  {
    "uniprot": "A0A3Q0KIW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIW4"
  },
  {
    "uniprot": "A0A3Q0KMK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMK0"
  },
  {
    "uniprot": "A0A3Q0KT90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT90"
  },
  {
    "uniprot": "A0A3Q0KDF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDF2"
  },
  {
    "uniprot": "G4VHB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHB6"
  },
  {
    "uniprot": "A0A5K4E9F7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9F7"
  },
  {
    "uniprot": "G4VJ37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ37"
  },
  {
    "uniprot": "A0A5K4F528",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F528"
  },
  {
    "uniprot": "A0A5K4FBF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBF9"
  },
  {
    "uniprot": "G4VHK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHK6"
  },
  {
    "uniprot": "A0A3Q0KR32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR32"
  },
  {
    "uniprot": "A0A5K4EGG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGG5"
  },
  {
    "uniprot": "A0A5K4FCB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCB2"
  },
  {
    "uniprot": "A0A146MG44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MG44"
  },
  {
    "uniprot": "A0A3Q0KSV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSV1"
  },
  {
    "uniprot": "A0A5K4FAT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAT5"
  },
  {
    "uniprot": "G4VPQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPQ7"
  },
  {
    "uniprot": "A0A3Q0KGG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGG5"
  },
  {
    "uniprot": "A0A5K4ERA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERA4"
  },
  {
    "uniprot": "A0A3Q0KTQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTQ1"
  },
  {
    "uniprot": "A0A5K4EUW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUW6"
  },
  {
    "uniprot": "A0A3Q0KRT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRT5"
  },
  {
    "uniprot": "A0A3Q0KQM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQM6"
  },
  {
    "uniprot": "G4VAT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAT6"
  },
  {
    "uniprot": "A0A3Q0KP27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP27"
  },
  {
    "uniprot": "A0A5K4EC07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EC07"
  },
  {
    "uniprot": "G4VJE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJE1"
  },
  {
    "uniprot": "G4LWM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWM2"
  },
  {
    "uniprot": "A0A5K4ETA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETA7"
  },
  {
    "uniprot": "G4VLP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLP7"
  },
  {
    "uniprot": "A0A5K4EMB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMB2"
  },
  {
    "uniprot": "A0A5K4EX38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX38"
  },
  {
    "uniprot": "A0A5K4FCV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCV8"
  },
  {
    "uniprot": "A0A3Q0KR62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR62"
  },
  {
    "uniprot": "A0A5K4F901",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F901"
  },
  {
    "uniprot": "A0A3Q0KID2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KID2"
  },
  {
    "uniprot": "A0A5K4F0J8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0J8"
  },
  {
    "uniprot": "G4LWF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWF8"
  },
  {
    "uniprot": "A0A5K4EKZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKZ5"
  },
  {
    "uniprot": "A0A5K4F4S8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4S8"
  },
  {
    "uniprot": "G4LXT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXT0"
  },
  {
    "uniprot": "A0A5K4FA80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA80"
  },
  {
    "uniprot": "A0A5K4F9H9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9H9"
  },
  {
    "uniprot": "A0A5K4F082",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F082"
  },
  {
    "uniprot": "A0A5K4F6T9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6T9"
  },
  {
    "uniprot": "A0A3Q0KTM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTM8"
  },
  {
    "uniprot": "A0A5K4EM68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM68"
  },
  {
    "uniprot": "A0A3Q0KU46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU46"
  },
  {
    "uniprot": "O16154",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/O16154"
  },
  {
    "uniprot": "G4VBW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBW4"
  },
  {
    "uniprot": "A0A5K4F7T8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7T8"
  },
  {
    "uniprot": "A0A5K4ERP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERP5"
  },
  {
    "uniprot": "A0A3Q0KIG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIG0"
  },
  {
    "uniprot": "A0A5K4F9U1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9U1"
  },
  {
    "uniprot": "A0A5K4ERK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERK4"
  },
  {
    "uniprot": "A0A5K4F3M6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3M6"
  },
  {
    "uniprot": "A0A5K4EJK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJK4"
  },
  {
    "uniprot": "A0A5K4EYM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYM6"
  },
  {
    "uniprot": "A0A5K4FC31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC31"
  },
  {
    "uniprot": "A0A3Q0KUW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUW5"
  },
  {
    "uniprot": "A0A3Q0KEF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEF5"
  },
  {
    "uniprot": "A0A5K4F5L4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5L4"
  },
  {
    "uniprot": "A0A5K4F899",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F899"
  },
  {
    "uniprot": "G4VTK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTK8"
  },
  {
    "uniprot": "G4VQN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQN7"
  },
  {
    "uniprot": "A0A3Q0KE63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE63"
  },
  {
    "uniprot": "A0A5K4F320",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F320"
  },
  {
    "uniprot": "A0A5K4F6Z6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Z6"
  },
  {
    "uniprot": "A0A3Q0KKM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKM9"
  },
  {
    "uniprot": "A0A5K4FBC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBC9"
  },
  {
    "uniprot": "A0A5K4F1I7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1I7"
  },
  {
    "uniprot": "A0A5K4F9Q2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Q2"
  },
  {
    "uniprot": "A0A5K4F3E5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3E5"
  },
  {
    "uniprot": "A0A5K4FD17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD17"
  },
  {
    "uniprot": "C4QPR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QPR9"
  },
  {
    "uniprot": "A0A5K4F879",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F879"
  },
  {
    "uniprot": "G4VDV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDV2"
  },
  {
    "uniprot": "G4V631",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V631"
  },
  {
    "uniprot": "A0A5K4EKH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKH2"
  },
  {
    "uniprot": "A0A5K4FA54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA54"
  },
  {
    "uniprot": "A0A5K4EGW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGW8"
  },
  {
    "uniprot": "A0A5K4ER43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER43"
  },
  {
    "uniprot": "A0A3Q0KV58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV58"
  },
  {
    "uniprot": "A0A3Q0KTF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTF3"
  },
  {
    "uniprot": "A0A5K4EM56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM56"
  },
  {
    "uniprot": "A0A3Q0KS34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS34"
  },
  {
    "uniprot": "A0A3Q0KNS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNS8"
  },
  {
    "uniprot": "G4VEX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEX7"
  },
  {
    "uniprot": "G4LZC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZC0"
  },
  {
    "uniprot": "A0A3Q0KTF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTF6"
  },
  {
    "uniprot": "A0A5K4FE93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE93"
  },
  {
    "uniprot": "A0A5K4EUN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUN8"
  },
  {
    "uniprot": "A0A5K4FF34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF34"
  },
  {
    "uniprot": "A0A5K4EY01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY01"
  },
  {
    "uniprot": "A0A5K4FDK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDK2"
  },
  {
    "uniprot": "G4M1K4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1K4"
  },
  {
    "uniprot": "A0A3Q0KQM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQM3"
  },
  {
    "uniprot": "A0A5K4ELN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELN6"
  },
  {
    "uniprot": "A0A3Q0KLR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLR1"
  },
  {
    "uniprot": "G4VHH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHH4"
  },
  {
    "uniprot": "G4VPP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPP4"
  },
  {
    "uniprot": "A0A5K4F2C6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2C6"
  },
  {
    "uniprot": "A0A5K4F7A9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7A9"
  },
  {
    "uniprot": "G4VFT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFT6"
  },
  {
    "uniprot": "A0A5K4EZ81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ81"
  },
  {
    "uniprot": "A0A5K4FEK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEK4"
  },
  {
    "uniprot": "A0A5K4F7X1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7X1"
  },
  {
    "uniprot": "A0A3Q0KC43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC43"
  },
  {
    "uniprot": "A0A3Q0KLR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLR6"
  },
  {
    "uniprot": "A0A5K4F816",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F816"
  },
  {
    "uniprot": "A0A3Q0KD37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD37"
  },
  {
    "uniprot": "A0A5K4F760",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F760"
  },
  {
    "uniprot": "A0A5K4F4S4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4S4"
  },
  {
    "uniprot": "G4VD20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD20"
  },
  {
    "uniprot": "G4VNK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNK1"
  },
  {
    "uniprot": "A0A3Q0KRZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRZ6"
  },
  {
    "uniprot": "A0A5K4ESJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESJ6"
  },
  {
    "uniprot": "A0A3Q0KPQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPQ4"
  },
  {
    "uniprot": "A0A5K4ETP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETP2"
  },
  {
    "uniprot": "A0A5K4EJW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJW6"
  },
  {
    "uniprot": "G4V7N0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7N0"
  },
  {
    "uniprot": "A0A5K4F8D7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8D7"
  },
  {
    "uniprot": "A0A3Q0KV31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV31"
  },
  {
    "uniprot": "G4LYN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYN0"
  },
  {
    "uniprot": "A0A5K4F9S5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9S5"
  },
  {
    "uniprot": "A0A5K4F5N1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5N1"
  },
  {
    "uniprot": "A0A5K4FCF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCF9"
  },
  {
    "uniprot": "G4VJ18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ18"
  },
  {
    "uniprot": "A0A0U5FZ31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A0U5FZ31"
  },
  {
    "uniprot": "G4VIL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIL2"
  },
  {
    "uniprot": "G4VRF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRF4"
  },
  {
    "uniprot": "A0A5K4F304",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F304"
  },
  {
    "uniprot": "A0A5K4EVK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVK1"
  },
  {
    "uniprot": "G4V875",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V875"
  },
  {
    "uniprot": "A0A3Q0KFG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFG3"
  },
  {
    "uniprot": "A0A5K4F6N6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6N6"
  },
  {
    "uniprot": "A0A3Q0KEM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEM9"
  },
  {
    "uniprot": "A0A5K4EZ22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ22"
  },
  {
    "uniprot": "G4VBF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBF8"
  },
  {
    "uniprot": "A0A3Q0KHM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHM4"
  },
  {
    "uniprot": "G4LYE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYE0"
  },
  {
    "uniprot": "A0A3Q0KLX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLX1"
  },
  {
    "uniprot": "G4VJX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJX4"
  },
  {
    "uniprot": "A0A5K4F4C3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4C3"
  },
  {
    "uniprot": "A0A3Q0KS66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS66"
  },
  {
    "uniprot": "G4VLB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLB2"
  },
  {
    "uniprot": "A0A5K4F383",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F383"
  },
  {
    "uniprot": "A0A3Q0KGZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGZ1"
  },
  {
    "uniprot": "A0A5K4FCY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCY8"
  },
  {
    "uniprot": "A0A5K4EQL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQL5"
  },
  {
    "uniprot": "Q94758",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q94758"
  },
  {
    "uniprot": "A0A5K4F935",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F935"
  },
  {
    "uniprot": "A0A5K4EWY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWY6"
  },
  {
    "uniprot": "A0A5K4F7V7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7V7"
  },
  {
    "uniprot": "A0A3Q0KMU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMU8"
  },
  {
    "uniprot": "A0A5K4EX12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX12"
  },
  {
    "uniprot": "A0A5K4EQB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQB6"
  },
  {
    "uniprot": "A0A5K4F533",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F533"
  },
  {
    "uniprot": "A0A3Q0KRS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRS0"
  },
  {
    "uniprot": "G4LX53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX53"
  },
  {
    "uniprot": "A0A5K4EU14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU14"
  },
  {
    "uniprot": "G4VQD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQD4"
  },
  {
    "uniprot": "G4VFV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFV0"
  },
  {
    "uniprot": "A0A5K4EAJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAJ5"
  },
  {
    "uniprot": "A0A5K4FH92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FH92"
  },
  {
    "uniprot": "A0A3Q0KI25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI25"
  },
  {
    "uniprot": "G4LZ91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ91"
  },
  {
    "uniprot": "A0A3Q0KDC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDC2"
  },
  {
    "uniprot": "A0A3Q0KE49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE49"
  },
  {
    "uniprot": "A0A5K4F5F6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5F6"
  },
  {
    "uniprot": "A0A3Q0KSD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSD6"
  },
  {
    "uniprot": "A0A3Q0KJE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJE0"
  },
  {
    "uniprot": "A0A5K4EP85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP85"
  },
  {
    "uniprot": "A0A3Q0KBJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBJ2"
  },
  {
    "uniprot": "A0A5K4ECD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECD7"
  },
  {
    "uniprot": "A0A3Q0KQA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQA1"
  },
  {
    "uniprot": "A0A5K4EVU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVU7"
  },
  {
    "uniprot": "A0A3Q0KNA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNA1"
  },
  {
    "uniprot": "A0A5K4FBA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBA9"
  },
  {
    "uniprot": "A0A5K4EST7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EST7"
  },
  {
    "uniprot": "G4VPM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPM6"
  },
  {
    "uniprot": "A0A5K4EQV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQV3"
  },
  {
    "uniprot": "C4Q2Z0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q2Z0"
  },
  {
    "uniprot": "A0A5K4F2R2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2R2"
  },
  {
    "uniprot": "C4QPR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QPR8"
  },
  {
    "uniprot": "A0A5K4F9I2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9I2"
  },
  {
    "uniprot": "A0A5K4EKH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKH5"
  },
  {
    "uniprot": "A0A5K4F9H1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9H1"
  },
  {
    "uniprot": "A0A5K4FAK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAK8"
  },
  {
    "uniprot": "A0A3Q0KQY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQY8"
  },
  {
    "uniprot": "A0A5K4EEM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEM8"
  },
  {
    "uniprot": "A0A5K4ERU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERU0"
  },
  {
    "uniprot": "A0A5K4F5I1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5I1"
  },
  {
    "uniprot": "A0A3Q0KL84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL84"
  },
  {
    "uniprot": "A0A3Q0KFH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFH4"
  },
  {
    "uniprot": "A0A3Q0KUJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUJ8"
  },
  {
    "uniprot": "A0A5K4EIL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIL9"
  },
  {
    "uniprot": "G4VBY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBY2"
  },
  {
    "uniprot": "A0A3Q0KLA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLA3"
  },
  {
    "uniprot": "A0A5K4F9P7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9P7"
  },
  {
    "uniprot": "A0A3Q0KTJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTJ5"
  },
  {
    "uniprot": "G4VK07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK07"
  },
  {
    "uniprot": "A0A3Q0KQ68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ68"
  },
  {
    "uniprot": "A0A5K4F3T4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3T4"
  },
  {
    "uniprot": "A0A3Q0KBU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBU4"
  },
  {
    "uniprot": "C4QIH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QIH8"
  },
  {
    "uniprot": "A0A5K4F5V8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5V8"
  },
  {
    "uniprot": "A0A3Q0KUV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUV1"
  },
  {
    "uniprot": "A0A5K4EDP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDP5"
  },
  {
    "uniprot": "A0A5K4FAY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAY1"
  },
  {
    "uniprot": "A0A3Q0KQ26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ26"
  },
  {
    "uniprot": "A0A5K4FCD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCD3"
  },
  {
    "uniprot": "A0A5K4F5F5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5F5"
  },
  {
    "uniprot": "A0A5K4F131",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F131"
  },
  {
    "uniprot": "A0A5K4EQI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQI5"
  },
  {
    "uniprot": "A0A5K4F8E5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8E5"
  },
  {
    "uniprot": "A0A5K4F3A1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3A1"
  },
  {
    "uniprot": "G4VQC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQC3"
  },
  {
    "uniprot": "A0A3Q0KP85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP85"
  },
  {
    "uniprot": "A0A5K4F2Q7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Q7"
  },
  {
    "uniprot": "A0A5K4EPW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPW6"
  },
  {
    "uniprot": "A0A3Q0KTX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTX8"
  },
  {
    "uniprot": "G4M0S3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0S3"
  },
  {
    "uniprot": "A0A5K4F007",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F007"
  },
  {
    "uniprot": "A0A3Q0KRE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRE6"
  },
  {
    "uniprot": "A0A5K4F432",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F432"
  },
  {
    "uniprot": "A0A5K4F190",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F190"
  },
  {
    "uniprot": "A0A5K4FBF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBF7"
  },
  {
    "uniprot": "G4VPR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPR4"
  },
  {
    "uniprot": "A0A5K4ENQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENQ1"
  },
  {
    "uniprot": "A0A3Q0KUB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUB5"
  },
  {
    "uniprot": "A0A5K4F3J6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3J6"
  },
  {
    "uniprot": "A0A5K4FBZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBZ8"
  },
  {
    "uniprot": "A0A5K4EVB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVB9"
  },
  {
    "uniprot": "A0A3Q0KDS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDS3"
  },
  {
    "uniprot": "A0A3Q0KPG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPG3"
  },
  {
    "uniprot": "A0A3Q0KHK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHK1"
  },
  {
    "uniprot": "A0A5K4EKP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKP4"
  },
  {
    "uniprot": "A0A3Q0KMH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMH1"
  },
  {
    "uniprot": "G4VQZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQZ5"
  },
  {
    "uniprot": "A0A3Q0KT27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT27"
  },
  {
    "uniprot": "A0A5K4FB85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB85"
  },
  {
    "uniprot": "A0A5K4F219",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F219"
  },
  {
    "uniprot": "A0A5K4F7D9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7D9"
  },
  {
    "uniprot": "A0A5K4F5E8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5E8"
  },
  {
    "uniprot": "A0A5K4EZR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZR9"
  },
  {
    "uniprot": "A0A3Q0KT29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT29"
  },
  {
    "uniprot": "A0A5K4EMG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMG6"
  },
  {
    "uniprot": "A0A5K4ETK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETK7"
  },
  {
    "uniprot": "A0A3Q0KJB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJB4"
  },
  {
    "uniprot": "G4VH80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH80"
  },
  {
    "uniprot": "A0A5K4EAT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAT0"
  },
  {
    "uniprot": "A0A3Q0KRC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRC0"
  },
  {
    "uniprot": "A0A3Q0KC25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC25"
  },
  {
    "uniprot": "A0A5K4F229",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F229"
  },
  {
    "uniprot": "A0A5K4EAH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAH6"
  },
  {
    "uniprot": "G4VKD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKD1"
  },
  {
    "uniprot": "A0A3Q0KNV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNV5"
  },
  {
    "uniprot": "A0A5K4EX94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX94"
  },
  {
    "uniprot": "G4LXC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXC9"
  },
  {
    "uniprot": "G4VBV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBV9"
  },
  {
    "uniprot": "A0A5K4FD04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD04"
  },
  {
    "uniprot": "A0A5K4ELP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELP5"
  },
  {
    "uniprot": "A0A5K4EAX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAX0"
  },
  {
    "uniprot": "G4VJF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJF6"
  },
  {
    "uniprot": "A0A5K4F1H3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1H3"
  },
  {
    "uniprot": "A0A5K4FFU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFU4"
  },
  {
    "uniprot": "A0A3Q0KJS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJS7"
  },
  {
    "uniprot": "G4VQF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQF6"
  },
  {
    "uniprot": "A0A3Q0KC74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC74"
  },
  {
    "uniprot": "A0A5K4FDT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDT9"
  },
  {
    "uniprot": "A0A5K4F717",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F717"
  },
  {
    "uniprot": "G4VHP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHP1"
  },
  {
    "uniprot": "A0A3Q0KEJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEJ2"
  },
  {
    "uniprot": "A0A5K4F903",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F903"
  },
  {
    "uniprot": "A0A5K4EGX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGX2"
  },
  {
    "uniprot": "A0A3Q0KLE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLE0"
  },
  {
    "uniprot": "A0A3Q0KDW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDW0"
  },
  {
    "uniprot": "G4LUY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUY8"
  },
  {
    "uniprot": "A0A3Q0KQL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQL1"
  },
  {
    "uniprot": "A0A3Q0KK71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK71"
  },
  {
    "uniprot": "G4VQD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQD2"
  },
  {
    "uniprot": "A0A3Q0KSD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSD9"
  },
  {
    "uniprot": "A0A3Q0KBK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBK3"
  },
  {
    "uniprot": "A0A5K4FEW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEW9"
  },
  {
    "uniprot": "A0A3Q0KIS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIS6"
  },
  {
    "uniprot": "A0A5K4F7H2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7H2"
  },
  {
    "uniprot": "A0A3Q0KQG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQG2"
  },
  {
    "uniprot": "A0A3Q0KJW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJW8"
  },
  {
    "uniprot": "G4VFR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFR0"
  },
  {
    "uniprot": "A0A5K4EZW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZW1"
  },
  {
    "uniprot": "A0A3Q0KK10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK10"
  },
  {
    "uniprot": "A0A3Q0KEN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEN2"
  },
  {
    "uniprot": "A0A5K4EQ74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ74"
  },
  {
    "uniprot": "A0A5K4FD69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD69"
  },
  {
    "uniprot": "A0A3Q0KKE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKE1"
  },
  {
    "uniprot": "A0A3Q0KRN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRN9"
  },
  {
    "uniprot": "G4VLW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLW5"
  },
  {
    "uniprot": "A0A5K4EV87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV87"
  },
  {
    "uniprot": "G4VDU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDU5"
  },
  {
    "uniprot": "A0A5K4FFL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFL9"
  },
  {
    "uniprot": "A0A5K4EWV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWV7"
  },
  {
    "uniprot": "A0A3Q0KDJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDJ8"
  },
  {
    "uniprot": "A0A3Q0KPA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPA6"
  },
  {
    "uniprot": "A0A3Q0KEG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEG2"
  },
  {
    "uniprot": "A0A5K4F603",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F603"
  },
  {
    "uniprot": "G4VDU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDU6"
  },
  {
    "uniprot": "A0A3Q0KQF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQF4"
  },
  {
    "uniprot": "A0A3Q0KN96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN96"
  },
  {
    "uniprot": "A0A3Q0KSQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSQ5"
  },
  {
    "uniprot": "A0A3Q0KHS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHS4"
  },
  {
    "uniprot": "A0A5K4F0Y1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Y1"
  },
  {
    "uniprot": "A0A3Q0KJU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJU6"
  },
  {
    "uniprot": "A0A3Q0KSD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSD1"
  },
  {
    "uniprot": "A0A5K4F829",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F829"
  },
  {
    "uniprot": "O96370",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/O96370"
  },
  {
    "uniprot": "A0A5K4F9Z3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Z3"
  },
  {
    "uniprot": "A0A5K4ENE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENE0"
  },
  {
    "uniprot": "G4V9B4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9B4"
  },
  {
    "uniprot": "C1M124",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C1M124"
  },
  {
    "uniprot": "G4VTB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTB5"
  },
  {
    "uniprot": "G4LZ18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ18"
  },
  {
    "uniprot": "A0A3Q0KS08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS08"
  },
  {
    "uniprot": "A0A5K4F4W0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4W0"
  },
  {
    "uniprot": "A0A5K4F823",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F823"
  },
  {
    "uniprot": "A0A5K4EBG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBG6"
  },
  {
    "uniprot": "G4VBL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBL6"
  },
  {
    "uniprot": "C1M032",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C1M032"
  },
  {
    "uniprot": "A0A5K4FE44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE44"
  },
  {
    "uniprot": "G4VK74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK74"
  },
  {
    "uniprot": "G4VLT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLT9"
  },
  {
    "uniprot": "A0A5K4F231",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F231"
  },
  {
    "uniprot": "G4VTX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTX8"
  },
  {
    "uniprot": "G4VT15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT15"
  },
  {
    "uniprot": "A0A5K4FAE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAE5"
  },
  {
    "uniprot": "A0A3Q0KF62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF62"
  },
  {
    "uniprot": "G4VT03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT03"
  },
  {
    "uniprot": "A0A3Q0KSJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSJ4"
  },
  {
    "uniprot": "G4LY89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY89"
  },
  {
    "uniprot": "A0A5K4F339",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F339"
  },
  {
    "uniprot": "G4VNY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNY6"
  },
  {
    "uniprot": "A0A3Q0KR22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR22"
  },
  {
    "uniprot": "A0A3Q0KRM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRM3"
  },
  {
    "uniprot": "A0A5K4F107",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F107"
  },
  {
    "uniprot": "A0A3Q0KG87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG87"
  },
  {
    "uniprot": "A0A5K4FCK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCK4"
  },
  {
    "uniprot": "Q65A65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q65A65"
  },
  {
    "uniprot": "A0A5K4EZV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZV8"
  },
  {
    "uniprot": "A0A3Q0KVH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVH4"
  },
  {
    "uniprot": "A0A5K4EKS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKS2"
  },
  {
    "uniprot": "A0A5K4ESV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESV7"
  },
  {
    "uniprot": "A0A3Q0KS59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS59"
  },
  {
    "uniprot": "A0A5K4F3D0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3D0"
  },
  {
    "uniprot": "A0A5K4F9M9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9M9"
  },
  {
    "uniprot": "A0A3Q0KN36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN36"
  },
  {
    "uniprot": "A0A3Q0KNJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNJ4"
  },
  {
    "uniprot": "A0A5K4EXI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXI7"
  },
  {
    "uniprot": "A0A3Q0KBU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBU0"
  },
  {
    "uniprot": "G4V926",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V926"
  },
  {
    "uniprot": "A0A5K4EDH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDH8"
  },
  {
    "uniprot": "A0A5K4EUQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUQ7"
  },
  {
    "uniprot": "G4VNU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNU4"
  },
  {
    "uniprot": "A0A3Q0KDT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDT8"
  },
  {
    "uniprot": "A0A3Q0KQB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQB0"
  },
  {
    "uniprot": "A0A5K4F9V6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9V6"
  },
  {
    "uniprot": "A0A3Q0KTG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTG6"
  },
  {
    "uniprot": "A0A3Q0KGQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGQ2"
  },
  {
    "uniprot": "A0A3Q0KSW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSW8"
  },
  {
    "uniprot": "A0A3Q0KVC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVC3"
  },
  {
    "uniprot": "G4VEA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEA7"
  },
  {
    "uniprot": "A0A5K4FA91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA91"
  },
  {
    "uniprot": "G4LYN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYN2"
  },
  {
    "uniprot": "A0A3Q0KIL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIL4"
  },
  {
    "uniprot": "A0A3Q0KLM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLM2"
  },
  {
    "uniprot": "A0A5K4FAW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAW3"
  },
  {
    "uniprot": "A0A3Q0KPV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPV0"
  },
  {
    "uniprot": "A0A5K4F9E6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9E6"
  },
  {
    "uniprot": "G4VEV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEV6"
  },
  {
    "uniprot": "A0A5K4F2G8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2G8"
  },
  {
    "uniprot": "A0A5K4F356",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F356"
  },
  {
    "uniprot": "A0A3Q0KEE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEE5"
  },
  {
    "uniprot": "G4VMR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMR7"
  },
  {
    "uniprot": "A0A5K4ENW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENW8"
  },
  {
    "uniprot": "G4LVT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVT7"
  },
  {
    "uniprot": "A0A3Q0KVG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVG2"
  },
  {
    "uniprot": "A0A5K4EU40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU40"
  },
  {
    "uniprot": "G4VSF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSF3"
  },
  {
    "uniprot": "A0A5K4EIW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIW7"
  },
  {
    "uniprot": "G4VTD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTD0"
  },
  {
    "uniprot": "C4QK17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QK17"
  },
  {
    "uniprot": "A0A5K4EZP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZP6"
  },
  {
    "uniprot": "A0A5K4EBM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBM3"
  },
  {
    "uniprot": "A0A3Q0KRX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRX7"
  },
  {
    "uniprot": "A0A5K4F3E1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3E1"
  },
  {
    "uniprot": "A0A3Q0KVM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVM1"
  },
  {
    "uniprot": "A0A5K4F689",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F689"
  },
  {
    "uniprot": "G4VQB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQB9"
  },
  {
    "uniprot": "A0A5K4EQR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQR1"
  },
  {
    "uniprot": "C4Q5I8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q5I8"
  },
  {
    "uniprot": "A0A5K4F270",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F270"
  },
  {
    "uniprot": "A0A5K4EXX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXX8"
  },
  {
    "uniprot": "A0A5K4ETZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETZ7"
  },
  {
    "uniprot": "A0A5K4F6M1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6M1"
  },
  {
    "uniprot": "G4M062",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M062"
  },
  {
    "uniprot": "A0A3Q0KJV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJV5"
  },
  {
    "uniprot": "G4VHU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHU7"
  },
  {
    "uniprot": "A0A3Q0KMF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMF4"
  },
  {
    "uniprot": "A0A5K4EAU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAU9"
  },
  {
    "uniprot": "A0A3Q0KT72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT72"
  },
  {
    "uniprot": "A0A5K4EHD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHD2"
  },
  {
    "uniprot": "A0A5K4F660",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F660"
  },
  {
    "uniprot": "A0A5K4EAL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAL4"
  },
  {
    "uniprot": "A0A3Q0KHY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHY3"
  },
  {
    "uniprot": "A0A3Q0KTI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTI2"
  },
  {
    "uniprot": "A0A3Q0KJR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJR6"
  },
  {
    "uniprot": "G4VI93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI93"
  },
  {
    "uniprot": "A0A5K4F3C0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3C0"
  },
  {
    "uniprot": "A0A5K4F9U9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9U9"
  },
  {
    "uniprot": "A0A146MFR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MFR6"
  },
  {
    "uniprot": "A0A3Q0KQH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQH9"
  },
  {
    "uniprot": "A0A5K4FAA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAA8"
  },
  {
    "uniprot": "A0A5K4FB51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB51"
  },
  {
    "uniprot": "A0A3Q0KJP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJP7"
  },
  {
    "uniprot": "A0A3Q0KLL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLL2"
  },
  {
    "uniprot": "A0A3Q0KL23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL23"
  },
  {
    "uniprot": "A0A5K4FC64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC64"
  },
  {
    "uniprot": "A0A3Q0KT37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT37"
  },
  {
    "uniprot": "A0A3Q0KTW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTW2"
  },
  {
    "uniprot": "A0A5K4EMB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMB6"
  },
  {
    "uniprot": "A0A3Q0KR67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR67"
  },
  {
    "uniprot": "P15964",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P15964"
  },
  {
    "uniprot": "A0A3Q0KUW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUW7"
  },
  {
    "uniprot": "A0A3Q0KI66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI66"
  },
  {
    "uniprot": "G4VJQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJQ0"
  },
  {
    "uniprot": "A0A5K4EL61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL61"
  },
  {
    "uniprot": "A0A3Q0KDI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDI9"
  },
  {
    "uniprot": "Q26565",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26565"
  },
  {
    "uniprot": "A0A3Q0KBG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBG6"
  },
  {
    "uniprot": "A0A3Q0KTH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTH0"
  },
  {
    "uniprot": "G4LYL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYL3"
  },
  {
    "uniprot": "A0A3Q0KG18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG18"
  },
  {
    "uniprot": "A0A5K4ERT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERT5"
  },
  {
    "uniprot": "A0A5K4FB66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB66"
  },
  {
    "uniprot": "G4V9B3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9B3"
  },
  {
    "uniprot": "A0A5K4EQN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQN5"
  },
  {
    "uniprot": "A0A5K4FEX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEX9"
  },
  {
    "uniprot": "A0A3Q0KBR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBR4"
  },
  {
    "uniprot": "A0A5K4F830",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F830"
  },
  {
    "uniprot": "A0A3Q0KV43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV43"
  },
  {
    "uniprot": "A0A5K4EGJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGJ4"
  },
  {
    "uniprot": "G4V664",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V664"
  },
  {
    "uniprot": "A0A5K4F411",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F411"
  },
  {
    "uniprot": "A0A5K4EBW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBW1"
  },
  {
    "uniprot": "G4VIR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIR4"
  },
  {
    "uniprot": "A0A5K4F709",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F709"
  },
  {
    "uniprot": "A0A3Q0KJY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJY2"
  },
  {
    "uniprot": "A0A5K4FF61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF61"
  },
  {
    "uniprot": "A0A5K4ENZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENZ3"
  },
  {
    "uniprot": "A0A5K4FBD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBD1"
  },
  {
    "uniprot": "A0A5K4F7F2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7F2"
  },
  {
    "uniprot": "G4VFE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFE3"
  },
  {
    "uniprot": "A0A5K4F3M3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3M3"
  },
  {
    "uniprot": "A0A5K4FC40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC40"
  },
  {
    "uniprot": "A0A5K4F5E9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5E9"
  },
  {
    "uniprot": "A0A3Q0KQS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQS7"
  },
  {
    "uniprot": "A0A5K4F934",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F934"
  },
  {
    "uniprot": "A0A5K4F9T2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9T2"
  },
  {
    "uniprot": "A0A3Q0KPP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPP0"
  },
  {
    "uniprot": "A0A5K4FCP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCP3"
  },
  {
    "uniprot": "A0A3Q0KTE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTE0"
  },
  {
    "uniprot": "G4VEC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEC4"
  },
  {
    "uniprot": "A0A5K4EMR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMR9"
  },
  {
    "uniprot": "G4V6M5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6M5"
  },
  {
    "uniprot": "G4VA68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA68"
  },
  {
    "uniprot": "A0A5K4EW09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW09"
  },
  {
    "uniprot": "A0A3Q0KPS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPS6"
  },
  {
    "uniprot": "A0A3Q0KCY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCY5"
  },
  {
    "uniprot": "A0A5K4ESN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESN4"
  },
  {
    "uniprot": "A0A5K4FDM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDM5"
  },
  {
    "uniprot": "A0A3Q0KC68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC68"
  },
  {
    "uniprot": "A0A5K4EAK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAK9"
  },
  {
    "uniprot": "A0A3Q0KKK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKK4"
  },
  {
    "uniprot": "A0A5K4F1P2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1P2"
  },
  {
    "uniprot": "A0A5K4F5Y1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Y1"
  },
  {
    "uniprot": "G4VN20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VN20"
  },
  {
    "uniprot": "A0A3Q0KQH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQH8"
  },
  {
    "uniprot": "A0A3Q0KMC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMC2"
  },
  {
    "uniprot": "A0A146MGD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MGD6"
  },
  {
    "uniprot": "A0A5K4EVB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVB5"
  },
  {
    "uniprot": "A0A3Q0KR97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR97"
  },
  {
    "uniprot": "C1M053",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C1M053"
  },
  {
    "uniprot": "A0A5K4F192",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F192"
  },
  {
    "uniprot": "A0A5K4FDU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDU8"
  },
  {
    "uniprot": "A0A3Q0KJU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJU0"
  },
  {
    "uniprot": "A0A3Q0KII4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KII4"
  },
  {
    "uniprot": "G4VJM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJM1"
  },
  {
    "uniprot": "A0A3Q0KN86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN86"
  },
  {
    "uniprot": "A0A3Q0KD17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD17"
  },
  {
    "uniprot": "A0A3Q0KN17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN17"
  },
  {
    "uniprot": "G4VDJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDJ0"
  },
  {
    "uniprot": "A0A3Q0KRQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRQ0"
  },
  {
    "uniprot": "A0A5K4F6H2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6H2"
  },
  {
    "uniprot": "A0A5K4FFK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFK3"
  },
  {
    "uniprot": "A0A5K4ENR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENR7"
  },
  {
    "uniprot": "A0A5K4EGN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGN3"
  },
  {
    "uniprot": "A0A3Q0KH16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH16"
  },
  {
    "uniprot": "A0A3Q0KBW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBW3"
  },
  {
    "uniprot": "G4VSS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSS0"
  },
  {
    "uniprot": "A0A5K4EIR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIR6"
  },
  {
    "uniprot": "A0A3Q0KBS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBS3"
  },
  {
    "uniprot": "A0A146MG43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MG43"
  },
  {
    "uniprot": "A0A5K4F5L7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5L7"
  },
  {
    "uniprot": "A0A3Q0KNX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNX0"
  },
  {
    "uniprot": "A0A5K4EUJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUJ7"
  },
  {
    "uniprot": "A0A3Q0KIJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIJ7"
  },
  {
    "uniprot": "G4VRU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRU5"
  },
  {
    "uniprot": "A0A3Q0KLZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLZ7"
  },
  {
    "uniprot": "A0A5K4F937",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F937"
  },
  {
    "uniprot": "A0A3Q0KQY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQY3"
  },
  {
    "uniprot": "G4VL95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VL95"
  },
  {
    "uniprot": "G4VMJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMJ5"
  },
  {
    "uniprot": "A0A3Q0KG39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG39"
  },
  {
    "uniprot": "A0A5K4F625",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F625"
  },
  {
    "uniprot": "A0A3Q0KV09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV09"
  },
  {
    "uniprot": "A0A5K4FF98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF98"
  },
  {
    "uniprot": "A0A3Q0KT94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT94"
  },
  {
    "uniprot": "A0A3Q0KQ65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ65"
  },
  {
    "uniprot": "A0A3Q0KPS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPS5"
  },
  {
    "uniprot": "G4LW06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LW06"
  },
  {
    "uniprot": "A0A5K4F406",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F406"
  },
  {
    "uniprot": "A0A5K4F0N4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0N4"
  },
  {
    "uniprot": "A0A5K4ET53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET53"
  },
  {
    "uniprot": "G4VB33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB33"
  },
  {
    "uniprot": "A0A5K4F3P7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3P7"
  },
  {
    "uniprot": "A0A3Q0KT45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT45"
  },
  {
    "uniprot": "A0A3Q0KQ79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ79"
  },
  {
    "uniprot": "A0A5K4F0J4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0J4"
  },
  {
    "uniprot": "A0A3Q0KV90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV90"
  },
  {
    "uniprot": "A0A5K4EIJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIJ7"
  },
  {
    "uniprot": "A0A5K4F994",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F994"
  },
  {
    "uniprot": "G4VFF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFF6"
  },
  {
    "uniprot": "G4VAW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAW6"
  },
  {
    "uniprot": "A0A5K4EE55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE55"
  },
  {
    "uniprot": "G4LXF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXF1"
  },
  {
    "uniprot": "A0A5K4F529",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F529"
  },
  {
    "uniprot": "A0A5K4EUW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUW9"
  },
  {
    "uniprot": "A0A5K4ECA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECA5"
  },
  {
    "uniprot": "A0A5K4F0R1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0R1"
  },
  {
    "uniprot": "G4LUV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUV4"
  },
  {
    "uniprot": "A0A5K4FE84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE84"
  },
  {
    "uniprot": "A0A3Q0KG08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG08"
  },
  {
    "uniprot": "G4VHW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHW3"
  },
  {
    "uniprot": "A0A3Q0KFH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFH6"
  },
  {
    "uniprot": "A0A5K4F1V5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1V5"
  },
  {
    "uniprot": "A0A3Q0KMK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMK1"
  },
  {
    "uniprot": "A0A5K4F2M4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2M4"
  },
  {
    "uniprot": "A0A5K4FFF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFF3"
  },
  {
    "uniprot": "A0A3Q0KT82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT82"
  },
  {
    "uniprot": "A0A3Q0KVL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVL1"
  },
  {
    "uniprot": "G4VAZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAZ8"
  },
  {
    "uniprot": "A0A5K4EV07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV07"
  },
  {
    "uniprot": "A0A5K4FAD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAD9"
  },
  {
    "uniprot": "A0A5K4F4V9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4V9"
  },
  {
    "uniprot": "A0A5K4FEH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEH4"
  },
  {
    "uniprot": "A0A5K4F5S6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5S6"
  },
  {
    "uniprot": "A0A5K4F0S9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0S9"
  },
  {
    "uniprot": "G4M0A1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0A1"
  },
  {
    "uniprot": "A0A5K4EU33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU33"
  },
  {
    "uniprot": "A0A5K4F8T7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8T7"
  },
  {
    "uniprot": "A0A5K4ETT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETT8"
  },
  {
    "uniprot": "A0A5K4F5Z6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Z6"
  },
  {
    "uniprot": "G4VHK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHK0"
  },
  {
    "uniprot": "A0A5K4F6D7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6D7"
  },
  {
    "uniprot": "G4LZE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZE6"
  },
  {
    "uniprot": "A0A5K4F258",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F258"
  },
  {
    "uniprot": "G4M039",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M039"
  },
  {
    "uniprot": "A0A5K4EMZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMZ0"
  },
  {
    "uniprot": "G4VM52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM52"
  },
  {
    "uniprot": "A0A5K4EX75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX75"
  },
  {
    "uniprot": "A0A5K4EAH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAH8"
  },
  {
    "uniprot": "G4LUL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUL2"
  },
  {
    "uniprot": "G4V7K1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7K1"
  },
  {
    "uniprot": "A0A5K4F8X8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8X8"
  },
  {
    "uniprot": "A0A3Q0KL25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL25"
  },
  {
    "uniprot": "A0A3Q0KC41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC41"
  },
  {
    "uniprot": "G4VHA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHA4"
  },
  {
    "uniprot": "A0A5K4EPZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPZ4"
  },
  {
    "uniprot": "G4VJ49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ49"
  },
  {
    "uniprot": "A0A5K4EI18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EI18"
  },
  {
    "uniprot": "A0A5K4F768",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F768"
  },
  {
    "uniprot": "A0A5K4EST1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EST1"
  },
  {
    "uniprot": "A0A5K4F4R6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4R6"
  },
  {
    "uniprot": "A0A5K4EX52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX52"
  },
  {
    "uniprot": "G4VNU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNU0"
  },
  {
    "uniprot": "A0A5K4EAL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAL6"
  },
  {
    "uniprot": "A0A3Q0KP42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP42"
  },
  {
    "uniprot": "A0A5K4EZ08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ08"
  },
  {
    "uniprot": "A0A3Q0KSM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSM7"
  },
  {
    "uniprot": "Q26609",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26609"
  },
  {
    "uniprot": "G4M254",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M254"
  },
  {
    "uniprot": "A0A5K4EPR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPR2"
  },
  {
    "uniprot": "A0A5K4EIU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIU4"
  },
  {
    "uniprot": "A0A3Q0KK55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK55"
  },
  {
    "uniprot": "A0A5K4FDW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDW1"
  },
  {
    "uniprot": "A0A3Q0KCX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCX4"
  },
  {
    "uniprot": "A0A3Q0KJ48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ48"
  },
  {
    "uniprot": "G4VKZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKZ6"
  },
  {
    "uniprot": "A0A5K4ETS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETS7"
  },
  {
    "uniprot": "G4VND1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VND1"
  },
  {
    "uniprot": "A0A3Q0KS19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS19"
  },
  {
    "uniprot": "A0A3Q0KKQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKQ4"
  },
  {
    "uniprot": "A0A3Q0KNP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNP2"
  },
  {
    "uniprot": "A0A5K4F8G4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8G4"
  },
  {
    "uniprot": "A0A5K4F8S2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8S2"
  },
  {
    "uniprot": "A0A3Q0KLL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLL6"
  },
  {
    "uniprot": "G4VM12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM12"
  },
  {
    "uniprot": "A0A5K4F097",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F097"
  },
  {
    "uniprot": "A0A5K4E9U8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9U8"
  },
  {
    "uniprot": "A0A5K4FCZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCZ8"
  },
  {
    "uniprot": "A0A5K4F089",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F089"
  },
  {
    "uniprot": "A0A5K4EU90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU90"
  },
  {
    "uniprot": "A0A3Q0KJ20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ20"
  },
  {
    "uniprot": "G4VT80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT80"
  },
  {
    "uniprot": "A0A5K4F884",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F884"
  },
  {
    "uniprot": "A0A5K4EKL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKL1"
  },
  {
    "uniprot": "A0A3Q0KPF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPF1"
  },
  {
    "uniprot": "A0A3Q0KFD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFD2"
  },
  {
    "uniprot": "A0A5K4FAS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAS6"
  },
  {
    "uniprot": "A0A3Q0KL96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL96"
  },
  {
    "uniprot": "A0A5K4EIB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIB7"
  },
  {
    "uniprot": "A0A5K4EZE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZE6"
  },
  {
    "uniprot": "A0A3Q0KNV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNV4"
  },
  {
    "uniprot": "A0A3Q0KKT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKT5"
  },
  {
    "uniprot": "A0A3Q0KSF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSF8"
  },
  {
    "uniprot": "A0A5K4F2V4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2V4"
  },
  {
    "uniprot": "A0A5K4E8R7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8R7"
  },
  {
    "uniprot": "G4VHI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHI6"
  },
  {
    "uniprot": "A0A5K4EPH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPH5"
  },
  {
    "uniprot": "A0A5K4E8H5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8H5"
  },
  {
    "uniprot": "A0A3Q0KGX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGX3"
  },
  {
    "uniprot": "G4VSX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSX8"
  },
  {
    "uniprot": "A0A3Q0KSR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSR0"
  },
  {
    "uniprot": "A0A5K4EE20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE20"
  },
  {
    "uniprot": "A0A5K4EDI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDI9"
  },
  {
    "uniprot": "A0A3Q0KV48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV48"
  },
  {
    "uniprot": "A0A3Q0KQG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQG0"
  },
  {
    "uniprot": "A0A3Q0KQ12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ12"
  },
  {
    "uniprot": "G4LWX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWX8"
  },
  {
    "uniprot": "A0A3Q0KM43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM43"
  },
  {
    "uniprot": "G4V7R8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7R8"
  },
  {
    "uniprot": "A0A3Q0KNQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNQ3"
  },
  {
    "uniprot": "A0A5K4FA87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA87"
  },
  {
    "uniprot": "A0A5K4F5D8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5D8"
  },
  {
    "uniprot": "A0A3Q0KRG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRG1"
  },
  {
    "uniprot": "A0A5K4EWN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWN7"
  },
  {
    "uniprot": "A0A5K4F8E8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8E8"
  },
  {
    "uniprot": "A0A5K4F5E0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5E0"
  },
  {
    "uniprot": "G4VH19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH19"
  },
  {
    "uniprot": "A0A3Q0KUK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUK2"
  },
  {
    "uniprot": "A0A5K4F810",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F810"
  },
  {
    "uniprot": "G4VLM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLM3"
  },
  {
    "uniprot": "A0A5K4F482",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F482"
  },
  {
    "uniprot": "G4VSY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSY1"
  },
  {
    "uniprot": "A0A3Q0KBA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBA3"
  },
  {
    "uniprot": "A0A3Q0KUV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUV7"
  },
  {
    "uniprot": "G4V9Y8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9Y8"
  },
  {
    "uniprot": "A0A5K4EKI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKI6"
  },
  {
    "uniprot": "G4VP04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VP04"
  },
  {
    "uniprot": "A0A5K4EA57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EA57"
  },
  {
    "uniprot": "A0A5K4F0H0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0H0"
  },
  {
    "uniprot": "G4V7Q6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7Q6"
  },
  {
    "uniprot": "G4VSM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSM8"
  },
  {
    "uniprot": "A0A3Q0KV85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV85"
  },
  {
    "uniprot": "G4V6W1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6W1"
  },
  {
    "uniprot": "A0A5K4EV73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV73"
  },
  {
    "uniprot": "A0A5K4F6D6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6D6"
  },
  {
    "uniprot": "G4VTN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTN9"
  },
  {
    "uniprot": "A0A5K4EJW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJW3"
  },
  {
    "uniprot": "A0A3Q0KID1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KID1"
  },
  {
    "uniprot": "A0A5K4F3Q9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Q9"
  },
  {
    "uniprot": "A0A5K4EXE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXE4"
  },
  {
    "uniprot": "A0A3Q0KTE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTE7"
  },
  {
    "uniprot": "A0A5K4F8K7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8K7"
  },
  {
    "uniprot": "G4VSV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSV3"
  },
  {
    "uniprot": "A0A3Q0KV63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV63"
  },
  {
    "uniprot": "A0A5K4F2D7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2D7"
  },
  {
    "uniprot": "A0A5K4EG51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EG51"
  },
  {
    "uniprot": "A0A5K4EN31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN31"
  },
  {
    "uniprot": "G4LV35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV35"
  },
  {
    "uniprot": "C4Q4Q6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q4Q6"
  },
  {
    "uniprot": "A0A5K4F8V8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8V8"
  },
  {
    "uniprot": "A0A3Q0KE85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE85"
  },
  {
    "uniprot": "A0A5K4EFV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFV7"
  },
  {
    "uniprot": "G4VGE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGE7"
  },
  {
    "uniprot": "A0A5K4F762",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F762"
  },
  {
    "uniprot": "A0A5K4FBN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBN9"
  },
  {
    "uniprot": "A0A5K4ERH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERH6"
  },
  {
    "uniprot": "A0A3Q0KI74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI74"
  },
  {
    "uniprot": "A0A3Q0KHI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHI2"
  },
  {
    "uniprot": "A0A5K4F1Z4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Z4"
  },
  {
    "uniprot": "G4VI63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI63"
  },
  {
    "uniprot": "A0A3Q0KJV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJV4"
  },
  {
    "uniprot": "G4LYE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYE6"
  },
  {
    "uniprot": "G4V5V5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5V5"
  },
  {
    "uniprot": "A0A3Q0KJG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJG4"
  },
  {
    "uniprot": "A0A5K4FD80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD80"
  },
  {
    "uniprot": "A0A5K4ER88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER88"
  },
  {
    "uniprot": "A0A3Q0KI68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI68"
  },
  {
    "uniprot": "A0A5K4ENB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENB5"
  },
  {
    "uniprot": "A0A3Q0KET1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KET1"
  },
  {
    "uniprot": "A0A5K4F413",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F413"
  },
  {
    "uniprot": "A0A5K4ECZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECZ9"
  },
  {
    "uniprot": "A0A5K4ENF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENF2"
  },
  {
    "uniprot": "A0A3Q0KVH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVH0"
  },
  {
    "uniprot": "A0A5K4EU92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU92"
  },
  {
    "uniprot": "A0A3Q0KSB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSB8"
  },
  {
    "uniprot": "A0A5K4F1F2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1F2"
  },
  {
    "uniprot": "A0A3Q0KSS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSS8"
  },
  {
    "uniprot": "A0A5K4F048",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F048"
  },
  {
    "uniprot": "A0A3Q0KTB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTB6"
  },
  {
    "uniprot": "G4LYK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYK3"
  },
  {
    "uniprot": "A0A3Q0KSE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSE3"
  },
  {
    "uniprot": "A0A5K4F2E1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2E1"
  },
  {
    "uniprot": "A0A3Q0KLG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLG9"
  },
  {
    "uniprot": "A0A5K4FGD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGD3"
  },
  {
    "uniprot": "A0A5K4EL89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL89"
  },
  {
    "uniprot": "C4QPX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QPX1"
  },
  {
    "uniprot": "A0A5K4F822",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F822"
  },
  {
    "uniprot": "A0A3Q0KGQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGQ9"
  },
  {
    "uniprot": "A0A5K4F0C3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0C3"
  },
  {
    "uniprot": "G4V992",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V992"
  },
  {
    "uniprot": "A0A5K4FAF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAF4"
  },
  {
    "uniprot": "A0A5K4F3J1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3J1"
  },
  {
    "uniprot": "A0A3Q0KUM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUM3"
  },
  {
    "uniprot": "A0A5K4EQD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQD9"
  },
  {
    "uniprot": "G4VIK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIK1"
  },
  {
    "uniprot": "G4VGT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGT3"
  },
  {
    "uniprot": "A0A3Q0KNB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNB6"
  },
  {
    "uniprot": "G4VC52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC52"
  },
  {
    "uniprot": "A0A5K4F505",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F505"
  },
  {
    "uniprot": "A0A5K4ELU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELU2"
  },
  {
    "uniprot": "A0A5K4FAL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAL8"
  },
  {
    "uniprot": "G4VLR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLR0"
  },
  {
    "uniprot": "G4VI06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI06"
  },
  {
    "uniprot": "G4VDK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDK2"
  },
  {
    "uniprot": "A0A5K4EED9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EED9"
  },
  {
    "uniprot": "A0A3Q0KQP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQP2"
  },
  {
    "uniprot": "A0A3Q0KRJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRJ8"
  },
  {
    "uniprot": "A0A5K4F0S1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0S1"
  },
  {
    "uniprot": "A0A3Q0KSV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSV5"
  },
  {
    "uniprot": "A0A5K4FA29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA29"
  },
  {
    "uniprot": "A0A3Q0KNG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNG3"
  },
  {
    "uniprot": "A0A3Q0KL71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL71"
  },
  {
    "uniprot": "A0A3Q0KV23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV23"
  },
  {
    "uniprot": "A0A5K4END4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4END4"
  },
  {
    "uniprot": "A0A3Q0KSW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSW6"
  },
  {
    "uniprot": "A0A5K4F4U8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4U8"
  },
  {
    "uniprot": "A0A5K4EMG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMG5"
  },
  {
    "uniprot": "A0A5K4F3K8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3K8"
  },
  {
    "uniprot": "A0A5K4F5Y7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Y7"
  },
  {
    "uniprot": "A0A3Q0KNM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNM1"
  },
  {
    "uniprot": "G4LX23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX23"
  },
  {
    "uniprot": "G4V607",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V607"
  },
  {
    "uniprot": "A0A3Q0KCZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCZ7"
  },
  {
    "uniprot": "G4VB32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB32"
  },
  {
    "uniprot": "G4VJE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJE5"
  },
  {
    "uniprot": "A0A3Q0KHY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHY6"
  },
  {
    "uniprot": "G4V7Z5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7Z5"
  },
  {
    "uniprot": "A0A5K4ETW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETW2"
  },
  {
    "uniprot": "A0A5K4EZW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZW0"
  },
  {
    "uniprot": "A0A5K4F0W5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0W5"
  },
  {
    "uniprot": "A0A3Q0KMG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMG6"
  },
  {
    "uniprot": "G4VPP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPP5"
  },
  {
    "uniprot": "A0A5K4F6Y4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Y4"
  },
  {
    "uniprot": "A0A5K4F4F4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4F4"
  },
  {
    "uniprot": "A0A5K4ES64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES64"
  },
  {
    "uniprot": "G4VG24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VG24"
  },
  {
    "uniprot": "A0A3Q7K6P6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q7K6P6"
  },
  {
    "uniprot": "A0A3Q0KLF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLF6"
  },
  {
    "uniprot": "A0A5K4EYD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYD8"
  },
  {
    "uniprot": "G4VKH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKH0"
  },
  {
    "uniprot": "A0A5K4F8K3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8K3"
  },
  {
    "uniprot": "A0A5K4F2T7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2T7"
  },
  {
    "uniprot": "A0A3Q0KSY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSY9"
  },
  {
    "uniprot": "A0A5K4FDZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDZ0"
  },
  {
    "uniprot": "A0A5K4F279",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F279"
  },
  {
    "uniprot": "A0A3Q0KCK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCK6"
  },
  {
    "uniprot": "A0A5K4F3Z2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Z2"
  },
  {
    "uniprot": "A0A5K4F157",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F157"
  },
  {
    "uniprot": "A0A5K4FBC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBC3"
  },
  {
    "uniprot": "A0A3Q0KRJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRJ0"
  },
  {
    "uniprot": "A0A3Q0KUC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUC1"
  },
  {
    "uniprot": "A0A5K4EZY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZY1"
  },
  {
    "uniprot": "G4V975",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V975"
  },
  {
    "uniprot": "A0A3Q0KS04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS04"
  },
  {
    "uniprot": "A0A3Q0KKU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKU5"
  },
  {
    "uniprot": "A0A5K4ELS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELS0"
  },
  {
    "uniprot": "G4VKR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKR3"
  },
  {
    "uniprot": "A0A5K4EW02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW02"
  },
  {
    "uniprot": "A0A5K4F3G6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3G6"
  },
  {
    "uniprot": "A0A3Q0KQE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQE3"
  },
  {
    "uniprot": "A0A3Q0KC19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC19"
  },
  {
    "uniprot": "A0A5K4F9W3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9W3"
  },
  {
    "uniprot": "A0A5K4EU88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU88"
  },
  {
    "uniprot": "A0A5K4ESR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESR8"
  },
  {
    "uniprot": "A0A5K4ECC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECC6"
  },
  {
    "uniprot": "A0A3Q0KPE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPE0"
  },
  {
    "uniprot": "A0A3Q0KTH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTH3"
  },
  {
    "uniprot": "A0A5K4EPV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPV9"
  },
  {
    "uniprot": "G4M1G7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1G7"
  },
  {
    "uniprot": "A0A5K4EUN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUN5"
  },
  {
    "uniprot": "A0A5K4FCV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCV6"
  },
  {
    "uniprot": "A0A5K4FB63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB63"
  },
  {
    "uniprot": "G4VNJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNJ8"
  },
  {
    "uniprot": "G4VDH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDH7"
  },
  {
    "uniprot": "A0A5K4F1V8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1V8"
  },
  {
    "uniprot": "A0A3Q0KE52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE52"
  },
  {
    "uniprot": "A0A5K4F6E2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6E2"
  },
  {
    "uniprot": "A0A3Q0KM00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM00"
  },
  {
    "uniprot": "A0A3Q0KLE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLE9"
  },
  {
    "uniprot": "A0A5K4FCX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCX6"
  },
  {
    "uniprot": "A0A3Q0KJW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJW1"
  },
  {
    "uniprot": "A0A3Q0KGC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGC5"
  },
  {
    "uniprot": "A0A3Q0KJ30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ30"
  },
  {
    "uniprot": "A0A3Q0KBH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBH0"
  },
  {
    "uniprot": "G4V6X4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6X4"
  },
  {
    "uniprot": "G4M013",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M013"
  },
  {
    "uniprot": "A0A5K4F1Y0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Y0"
  },
  {
    "uniprot": "G4VBX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBX1"
  },
  {
    "uniprot": "A0A5K4EX30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX30"
  },
  {
    "uniprot": "A0A5K4F897",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F897"
  },
  {
    "uniprot": "A0A3Q0KNT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNT5"
  },
  {
    "uniprot": "G4VBL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBL9"
  },
  {
    "uniprot": "A0A3Q0KP60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP60"
  },
  {
    "uniprot": "A0A5K4F758",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F758"
  },
  {
    "uniprot": "A0A3Q0KMC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMC9"
  },
  {
    "uniprot": "A0A5K4EJS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJS2"
  },
  {
    "uniprot": "A0A3Q0KCR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCR9"
  },
  {
    "uniprot": "A0A5K4FGB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGB5"
  },
  {
    "uniprot": "G4LXS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXS5"
  },
  {
    "uniprot": "A0A3Q0KSZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSZ5"
  },
  {
    "uniprot": "G4M1S1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1S1"
  },
  {
    "uniprot": "A0A3Q0KGX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGX1"
  },
  {
    "uniprot": "A0A3Q0KCD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCD0"
  },
  {
    "uniprot": "A0A3Q0KNL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNL1"
  },
  {
    "uniprot": "A0A3Q0KS05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS05"
  },
  {
    "uniprot": "A0A3Q0KGR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGR3"
  },
  {
    "uniprot": "Q94757",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q94757"
  },
  {
    "uniprot": "A0A5K4F775",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F775"
  },
  {
    "uniprot": "A0A3Q0KK41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK41"
  },
  {
    "uniprot": "A0A5K4F554",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F554"
  },
  {
    "uniprot": "A0A5K4F604",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F604"
  },
  {
    "uniprot": "A0A3Q0KRI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRI7"
  },
  {
    "uniprot": "A0A3Q0KMJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMJ8"
  },
  {
    "uniprot": "A0A3Q0KMZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMZ1"
  },
  {
    "uniprot": "A0A5K4EN03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN03"
  },
  {
    "uniprot": "A0A5K4F0U1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0U1"
  },
  {
    "uniprot": "A0A5K4FGK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGK9"
  },
  {
    "uniprot": "A0A5K4ELX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELX3"
  },
  {
    "uniprot": "A0A3Q0KHF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHF4"
  },
  {
    "uniprot": "G4VCS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCS4"
  },
  {
    "uniprot": "A0A5K4FFU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFU0"
  },
  {
    "uniprot": "A0A3Q0KHA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHA3"
  },
  {
    "uniprot": "A0A5K4F1Q5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Q5"
  },
  {
    "uniprot": "A0A5K4F0Z5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Z5"
  },
  {
    "uniprot": "A0A3Q0KR49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR49"
  },
  {
    "uniprot": "A0A3Q0KRU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRU6"
  },
  {
    "uniprot": "A0A3Q0KJR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJR7"
  },
  {
    "uniprot": "A0A3Q0KPR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPR7"
  },
  {
    "uniprot": "G4VGR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGR1"
  },
  {
    "uniprot": "G4M024",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M024"
  },
  {
    "uniprot": "A0A3Q0KD94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD94"
  },
  {
    "uniprot": "A0A5K4ETZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETZ9"
  },
  {
    "uniprot": "A0A3Q0KNZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNZ8"
  },
  {
    "uniprot": "A0A5K4F1P4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1P4"
  },
  {
    "uniprot": "A0A3Q0KRN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRN1"
  },
  {
    "uniprot": "A0A5K4F5D4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5D4"
  },
  {
    "uniprot": "A0A5K4F2E8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2E8"
  },
  {
    "uniprot": "A0A5K4EU65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU65"
  },
  {
    "uniprot": "A0A5K4FBD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBD4"
  },
  {
    "uniprot": "A0A5K4F174",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F174"
  },
  {
    "uniprot": "A0A5K4F2R9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2R9"
  },
  {
    "uniprot": "A0A5K4F7F7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7F7"
  },
  {
    "uniprot": "A0A5K4FG35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FG35"
  },
  {
    "uniprot": "G4V672",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V672"
  },
  {
    "uniprot": "A0A5K4F6U6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6U6"
  },
  {
    "uniprot": "A0A3Q0KKG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKG3"
  },
  {
    "uniprot": "A0A5K4FA43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA43"
  },
  {
    "uniprot": "G4LWF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWF9"
  },
  {
    "uniprot": "A0A5K4EQ59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ59"
  },
  {
    "uniprot": "A0A5K4FBA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBA6"
  },
  {
    "uniprot": "A0A5K4F583",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F583"
  },
  {
    "uniprot": "A0A5K4EDN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDN5"
  },
  {
    "uniprot": "A0A3Q0KMQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMQ8"
  },
  {
    "uniprot": "A0A5K4FDF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDF8"
  },
  {
    "uniprot": "A0A5K4F104",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F104"
  },
  {
    "uniprot": "G4VLS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLS0"
  },
  {
    "uniprot": "G4VD96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD96"
  },
  {
    "uniprot": "A0A5K4EX67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX67"
  },
  {
    "uniprot": "A0A3Q0KKQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKQ7"
  },
  {
    "uniprot": "A0A5K4FEC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEC5"
  },
  {
    "uniprot": "A0A3Q0KDW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDW7"
  },
  {
    "uniprot": "C4Q5Y3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q5Y3"
  },
  {
    "uniprot": "A0A5K4EA81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EA81"
  },
  {
    "uniprot": "A0A5K4FD64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD64"
  },
  {
    "uniprot": "A0A5K4ES94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES94"
  },
  {
    "uniprot": "A0A3Q0KQ50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ50"
  },
  {
    "uniprot": "A0A3Q0KKH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKH0"
  },
  {
    "uniprot": "G4VMU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMU1"
  },
  {
    "uniprot": "G4LZ24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ24"
  },
  {
    "uniprot": "A0A3Q0KM69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM69"
  },
  {
    "uniprot": "Q2VJ10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q2VJ10"
  },
  {
    "uniprot": "A0A5K4F6P6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6P6"
  },
  {
    "uniprot": "G4LYC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYC3"
  },
  {
    "uniprot": "A0A5K4F8H3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8H3"
  },
  {
    "uniprot": "A0A3Q0KLY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLY4"
  },
  {
    "uniprot": "A0A5K4F2V7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2V7"
  },
  {
    "uniprot": "A0A5K4F7C3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7C3"
  },
  {
    "uniprot": "A0A5K4EBL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBL0"
  },
  {
    "uniprot": "A0A5K4EYU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYU1"
  },
  {
    "uniprot": "A0A5K4FCB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCB1"
  },
  {
    "uniprot": "A0A3Q0KIP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIP4"
  },
  {
    "uniprot": "A0A5K4EWI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWI5"
  },
  {
    "uniprot": "A0A5K4F6R3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6R3"
  },
  {
    "uniprot": "A0A3Q0KCS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCS1"
  },
  {
    "uniprot": "A0A3Q0KK80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK80"
  },
  {
    "uniprot": "G4VC75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC75"
  },
  {
    "uniprot": "A0A3Q0KS95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS95"
  },
  {
    "uniprot": "G4VPX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPX9"
  },
  {
    "uniprot": "A0A3Q0KEX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEX5"
  },
  {
    "uniprot": "A0A5K4FBH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBH5"
  },
  {
    "uniprot": "A0A5K4F5R5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5R5"
  },
  {
    "uniprot": "A0A5K4F501",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F501"
  },
  {
    "uniprot": "A0A5K4F4T5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4T5"
  },
  {
    "uniprot": "A0A5K4F8P9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8P9"
  },
  {
    "uniprot": "A0A5K4ELX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELX9"
  },
  {
    "uniprot": "A0A3Q0KCF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCF5"
  },
  {
    "uniprot": "G4VIC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIC1"
  },
  {
    "uniprot": "A0A5K4F5Z1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Z1"
  },
  {
    "uniprot": "A0A5K4E9B8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9B8"
  },
  {
    "uniprot": "A0A5K4ESZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESZ4"
  },
  {
    "uniprot": "A0A5K4F265",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F265"
  },
  {
    "uniprot": "A0A5K4F3F5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3F5"
  },
  {
    "uniprot": "A0A5K4F3Y9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Y9"
  },
  {
    "uniprot": "A0A3Q0KTD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTD7"
  },
  {
    "uniprot": "A0A5K4F8R7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8R7"
  },
  {
    "uniprot": "Q869D4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q869D4"
  },
  {
    "uniprot": "G4VGX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGX1"
  },
  {
    "uniprot": "A0A3Q0KRT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRT3"
  },
  {
    "uniprot": "A0A5K4FCM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCM2"
  },
  {
    "uniprot": "A0A3Q0KG89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG89"
  },
  {
    "uniprot": "G4VPU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPU6"
  },
  {
    "uniprot": "A0A5K4F7C8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7C8"
  },
  {
    "uniprot": "A0A5K4F0U8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0U8"
  },
  {
    "uniprot": "A0A5K4F997",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F997"
  },
  {
    "uniprot": "A0A5K4FAT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAT2"
  },
  {
    "uniprot": "A0A3Q0KNN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNN8"
  },
  {
    "uniprot": "A0A3Q0KKC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKC4"
  },
  {
    "uniprot": "G4V7L5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7L5"
  },
  {
    "uniprot": "A0A5K4ER37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER37"
  },
  {
    "uniprot": "G4VFY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFY4"
  },
  {
    "uniprot": "A0A3Q0KTX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTX9"
  },
  {
    "uniprot": "A0A3Q0KQ37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ37"
  },
  {
    "uniprot": "A0A5K4EDH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDH1"
  },
  {
    "uniprot": "A0A5K4EAV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAV8"
  },
  {
    "uniprot": "A0A3Q0KLR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLR4"
  },
  {
    "uniprot": "A0A5K4EP28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP28"
  },
  {
    "uniprot": "A0A3Q0KVA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVA4"
  },
  {
    "uniprot": "A0A5K4F996",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F996"
  },
  {
    "uniprot": "A0A5K4F8H8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8H8"
  },
  {
    "uniprot": "Q26595",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26595"
  },
  {
    "uniprot": "G4VJP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJP1"
  },
  {
    "uniprot": "A0A3Q0KQS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQS0"
  },
  {
    "uniprot": "A0A3Q0KEC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEC9"
  },
  {
    "uniprot": "A0A3Q0KHL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHL3"
  },
  {
    "uniprot": "A0A3Q0KPB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPB3"
  },
  {
    "uniprot": "A0A5K4F574",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F574"
  },
  {
    "uniprot": "A0A3Q0KQU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQU9"
  },
  {
    "uniprot": "A0A5K4F0N3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0N3"
  },
  {
    "uniprot": "G4VFI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFI4"
  },
  {
    "uniprot": "A0A5K4F6R5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6R5"
  },
  {
    "uniprot": "A0A3Q0KKP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKP8"
  },
  {
    "uniprot": "A0A3Q0KVF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVF5"
  },
  {
    "uniprot": "A0A3Q0KS54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS54"
  },
  {
    "uniprot": "A0A5K4FDG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDG1"
  },
  {
    "uniprot": "G4VMW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMW7"
  },
  {
    "uniprot": "A0A3Q0KIK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIK1"
  },
  {
    "uniprot": "A0A3Q0KQY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQY5"
  },
  {
    "uniprot": "A0A5K4F545",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F545"
  },
  {
    "uniprot": "G4V665",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V665"
  },
  {
    "uniprot": "A0A5K4F373",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F373"
  },
  {
    "uniprot": "G4VSQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSQ5"
  },
  {
    "uniprot": "A0A5K4EAB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAB0"
  },
  {
    "uniprot": "A0A5K4F1Z6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Z6"
  },
  {
    "uniprot": "A0A3Q0KNT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNT7"
  },
  {
    "uniprot": "G4VT69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT69"
  },
  {
    "uniprot": "A0A5K4EQF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQF7"
  },
  {
    "uniprot": "A0A3Q0KPK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPK0"
  },
  {
    "uniprot": "A0A3Q0KNM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNM2"
  },
  {
    "uniprot": "A0A5K4EL56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL56"
  },
  {
    "uniprot": "A0A5K4ET06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET06"
  },
  {
    "uniprot": "G4VBP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBP6"
  },
  {
    "uniprot": "A0A5K4FCC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCC2"
  },
  {
    "uniprot": "A0A3Q0KKL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKL8"
  },
  {
    "uniprot": "G4VME2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VME2"
  },
  {
    "uniprot": "A0A5K4EMH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMH9"
  },
  {
    "uniprot": "G4VA21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA21"
  },
  {
    "uniprot": "G4LVN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVN8"
  },
  {
    "uniprot": "A0A3Q0KV91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV91"
  },
  {
    "uniprot": "A0A3Q0KGC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGC4"
  },
  {
    "uniprot": "A0A3Q0KT33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT33"
  },
  {
    "uniprot": "A0A3Q0KRU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRU0"
  },
  {
    "uniprot": "G4VMR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMR3"
  },
  {
    "uniprot": "A0A5K4ESI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESI4"
  },
  {
    "uniprot": "A0A5K4F8A4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8A4"
  },
  {
    "uniprot": "A0A5K4F616",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F616"
  },
  {
    "uniprot": "A0A3Q0KN94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN94"
  },
  {
    "uniprot": "A0A5K4FFI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFI1"
  },
  {
    "uniprot": "A0A5K4EPT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPT3"
  },
  {
    "uniprot": "A0A3Q0KQ09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ09"
  },
  {
    "uniprot": "A0A3Q0KN88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN88"
  },
  {
    "uniprot": "A0A3Q0KPV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPV4"
  },
  {
    "uniprot": "A0A5K4EN90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN90"
  },
  {
    "uniprot": "A0A3Q0KEK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEK6"
  },
  {
    "uniprot": "A0A3Q0KL97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL97"
  },
  {
    "uniprot": "A0A5K4FC01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC01"
  },
  {
    "uniprot": "A0A5K4EG09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EG09"
  },
  {
    "uniprot": "A0A5K4ETN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETN9"
  },
  {
    "uniprot": "G4LV34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV34"
  },
  {
    "uniprot": "A0A5K4F6M5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6M5"
  },
  {
    "uniprot": "A0A3Q0KQT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQT8"
  },
  {
    "uniprot": "A0A5K4FBN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBN1"
  },
  {
    "uniprot": "G4VMP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMP1"
  },
  {
    "uniprot": "A0A5K4F139",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F139"
  },
  {
    "uniprot": "A0A5K4EUL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUL3"
  },
  {
    "uniprot": "G4V709",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V709"
  },
  {
    "uniprot": "A0A3Q0KEA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEA1"
  },
  {
    "uniprot": "A0A3Q0KQ74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ74"
  },
  {
    "uniprot": "A0A5K4E8G0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8G0"
  },
  {
    "uniprot": "G4LZ93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ93"
  },
  {
    "uniprot": "G4VFH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFH0"
  },
  {
    "uniprot": "A0A5K4FFK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFK8"
  },
  {
    "uniprot": "A0A5K4F449",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F449"
  },
  {
    "uniprot": "A0A3Q0KQT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQT0"
  },
  {
    "uniprot": "A0A5K4EIP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIP9"
  },
  {
    "uniprot": "A0A3Q0KNH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNH4"
  },
  {
    "uniprot": "G4V5V4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5V4"
  },
  {
    "uniprot": "A0A5K4ES76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES76"
  },
  {
    "uniprot": "A0A5K4FAP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAP2"
  },
  {
    "uniprot": "A0A3Q0KJ95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ95"
  },
  {
    "uniprot": "G4V5V3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5V3"
  },
  {
    "uniprot": "A0A5K4ED20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED20"
  },
  {
    "uniprot": "A0A3Q0KVG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVG4"
  },
  {
    "uniprot": "A0A3Q0KPD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPD6"
  },
  {
    "uniprot": "A0A3Q0KVK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVK7"
  },
  {
    "uniprot": "A0A3Q0KLS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLS3"
  },
  {
    "uniprot": "A0A5K4F457",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F457"
  },
  {
    "uniprot": "G4LUY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUY0"
  },
  {
    "uniprot": "A0A5K4FBK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBK3"
  },
  {
    "uniprot": "A0A3Q0KKT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKT8"
  },
  {
    "uniprot": "Q8MNY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q8MNY2"
  },
  {
    "uniprot": "A0A3Q0KV37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV37"
  },
  {
    "uniprot": "A0A3Q0KRR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRR3"
  },
  {
    "uniprot": "G4LWW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWW8"
  },
  {
    "uniprot": "A0A3Q0KS16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS16"
  },
  {
    "uniprot": "A0A5K4FD44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD44"
  },
  {
    "uniprot": "A0A5K4EES9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EES9"
  },
  {
    "uniprot": "A0A3Q0KLQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLQ2"
  },
  {
    "uniprot": "G4LXS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXS0"
  },
  {
    "uniprot": "A0A3Q0KS31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS31"
  },
  {
    "uniprot": "A0A5K4FD14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD14"
  },
  {
    "uniprot": "A0A3Q0KJA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJA1"
  },
  {
    "uniprot": "G4VS40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS40"
  },
  {
    "uniprot": "A0A5K4F9G2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9G2"
  },
  {
    "uniprot": "A0A3Q0KQB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQB5"
  },
  {
    "uniprot": "A0A3Q0KSF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSF5"
  },
  {
    "uniprot": "A0A3Q0KQN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQN8"
  },
  {
    "uniprot": "A0A5K4F6Q9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Q9"
  },
  {
    "uniprot": "A0A5K4F6B6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6B6"
  },
  {
    "uniprot": "G4VIN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIN2"
  },
  {
    "uniprot": "A0A3Q0KFR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFR2"
  },
  {
    "uniprot": "A0A5K4F8Y5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Y5"
  },
  {
    "uniprot": "A0A3Q0KT39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT39"
  },
  {
    "uniprot": "A0A5K4F2V5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2V5"
  },
  {
    "uniprot": "A0A3Q0KCR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCR5"
  },
  {
    "uniprot": "A0A5K4F586",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F586"
  },
  {
    "uniprot": "A0A0U5KI45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A0U5KI45"
  },
  {
    "uniprot": "A0A5K4E9R0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9R0"
  },
  {
    "uniprot": "A0A3Q0KF23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF23"
  },
  {
    "uniprot": "A0A5K4F9X2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9X2"
  },
  {
    "uniprot": "A0A3Q0KEL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEL0"
  },
  {
    "uniprot": "A0A3Q0KI17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI17"
  },
  {
    "uniprot": "A0A5K4F363",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F363"
  },
  {
    "uniprot": "A0A3Q0KMM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMM7"
  },
  {
    "uniprot": "A0A5K4FCG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCG0"
  },
  {
    "uniprot": "G4VAI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAI1"
  },
  {
    "uniprot": "A0A5K4ETM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETM9"
  },
  {
    "uniprot": "A0A3Q0KBP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBP5"
  },
  {
    "uniprot": "A0A5K4F2P3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2P3"
  },
  {
    "uniprot": "G4LXB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXB1"
  },
  {
    "uniprot": "G4VQL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQL7"
  },
  {
    "uniprot": "G4LWP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWP4"
  },
  {
    "uniprot": "A0A5K4FB35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB35"
  },
  {
    "uniprot": "A0A5K4E9H6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9H6"
  },
  {
    "uniprot": "A0A5K4F6U4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6U4"
  },
  {
    "uniprot": "A0A3Q0KG78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG78"
  },
  {
    "uniprot": "A0A3Q0KJ23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ23"
  },
  {
    "uniprot": "G4VLW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLW1"
  },
  {
    "uniprot": "A0A3Q0KTZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTZ0"
  },
  {
    "uniprot": "G4V658",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V658"
  },
  {
    "uniprot": "A0A5K4FBJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBJ5"
  },
  {
    "uniprot": "G4VJA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJA2"
  },
  {
    "uniprot": "A0A5K4F4H9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4H9"
  },
  {
    "uniprot": "A0A5K4F8R4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8R4"
  },
  {
    "uniprot": "A0A5K4FG21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FG21"
  },
  {
    "uniprot": "G4V6L4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6L4"
  },
  {
    "uniprot": "A0A5K4F1L8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1L8"
  },
  {
    "uniprot": "A0A5K4FE91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE91"
  },
  {
    "uniprot": "A0A3Q0KDL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDL4"
  },
  {
    "uniprot": "A0A5K4EP88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP88"
  },
  {
    "uniprot": "A0A5K4FD06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD06"
  },
  {
    "uniprot": "A0A5K4F6V2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6V2"
  },
  {
    "uniprot": "A0A5K4F5N3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5N3"
  },
  {
    "uniprot": "A0A5K4F3P6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3P6"
  },
  {
    "uniprot": "G4VEH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEH9"
  },
  {
    "uniprot": "A0A5K4ER08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER08"
  },
  {
    "uniprot": "G4VRK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRK1"
  },
  {
    "uniprot": "A0A3Q0KBK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBK2"
  },
  {
    "uniprot": "A0A3Q0KEK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEK0"
  },
  {
    "uniprot": "A0A3Q0KTS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTS9"
  },
  {
    "uniprot": "G4VIM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIM8"
  },
  {
    "uniprot": "A0A5K4F3U5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3U5"
  },
  {
    "uniprot": "A0A5K4F485",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F485"
  },
  {
    "uniprot": "A0A3Q0KIH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIH8"
  },
  {
    "uniprot": "A0A3Q0KMG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMG9"
  },
  {
    "uniprot": "A0A5K4EEB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEB0"
  },
  {
    "uniprot": "A0A5K4F6L8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6L8"
  },
  {
    "uniprot": "A0A5K4F5N2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5N2"
  },
  {
    "uniprot": "A0A3Q0KPK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPK9"
  },
  {
    "uniprot": "G4V6P4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6P4"
  },
  {
    "uniprot": "A0A5K4F1U7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1U7"
  },
  {
    "uniprot": "G4V8L0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8L0"
  },
  {
    "uniprot": "A0A3Q0KUG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUG9"
  },
  {
    "uniprot": "A0A5K4EZE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZE8"
  },
  {
    "uniprot": "A0A3Q0KCX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCX7"
  },
  {
    "uniprot": "A0A5K4FDE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDE9"
  },
  {
    "uniprot": "A0A3Q0KFE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFE4"
  },
  {
    "uniprot": "A0A5K4F2V3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2V3"
  },
  {
    "uniprot": "A0A5K4EFD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFD9"
  },
  {
    "uniprot": "A0A3Q0KLD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLD2"
  },
  {
    "uniprot": "A0A5K4EZH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZH9"
  },
  {
    "uniprot": "G4V8L2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8L2"
  },
  {
    "uniprot": "A0A3Q0KBC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBC2"
  },
  {
    "uniprot": "G4LZ54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ54"
  },
  {
    "uniprot": "G4VFC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFC5"
  },
  {
    "uniprot": "A0A5K4EZK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZK4"
  },
  {
    "uniprot": "G4VM57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM57"
  },
  {
    "uniprot": "A0A5K4F9Q6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Q6"
  },
  {
    "uniprot": "G4VBT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBT9"
  },
  {
    "uniprot": "A0A3Q0KLM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLM5"
  },
  {
    "uniprot": "A0A5K4F4D0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4D0"
  },
  {
    "uniprot": "A0A5K4EX84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX84"
  },
  {
    "uniprot": "A0A5K4FH65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FH65"
  },
  {
    "uniprot": "A0A3Q0KM66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM66"
  },
  {
    "uniprot": "G4VRC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRC1"
  },
  {
    "uniprot": "Q9U491",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q9U491"
  },
  {
    "uniprot": "A0A3Q0KFF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFF6"
  },
  {
    "uniprot": "A0A5K4F6E4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6E4"
  },
  {
    "uniprot": "A0A3Q0KKS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKS2"
  },
  {
    "uniprot": "G4VLM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLM8"
  },
  {
    "uniprot": "Q15ER1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q15ER1"
  },
  {
    "uniprot": "G4V6R4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6R4"
  },
  {
    "uniprot": "A0A5K4F4B7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4B7"
  },
  {
    "uniprot": "G4VSS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSS5"
  },
  {
    "uniprot": "A0A3Q0KCD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCD9"
  },
  {
    "uniprot": "A0A5K4FDS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDS8"
  },
  {
    "uniprot": "C4Q2F9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q2F9"
  },
  {
    "uniprot": "A0A3Q0KRI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRI5"
  },
  {
    "uniprot": "G4VPB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPB2"
  },
  {
    "uniprot": "A0A5K4F6B7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6B7"
  },
  {
    "uniprot": "A0A5K4FFP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFP2"
  },
  {
    "uniprot": "A0A3Q0KF46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF46"
  },
  {
    "uniprot": "A0A5K4F3B8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3B8"
  },
  {
    "uniprot": "G4V8H4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8H4"
  },
  {
    "uniprot": "A0A3Q0KRF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRF3"
  },
  {
    "uniprot": "A0A5K4E945",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E945"
  },
  {
    "uniprot": "A0A5K4F4E9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4E9"
  },
  {
    "uniprot": "A0A5K4F307",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F307"
  },
  {
    "uniprot": "A0A5K4EQD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQD3"
  },
  {
    "uniprot": "A0A3Q0KH54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH54"
  },
  {
    "uniprot": "A0A5K4EQA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQA7"
  },
  {
    "uniprot": "G4VM25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM25"
  },
  {
    "uniprot": "A0A5K4F7P1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7P1"
  },
  {
    "uniprot": "A0A3Q0KML8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KML8"
  },
  {
    "uniprot": "A0A3Q0KH90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH90"
  },
  {
    "uniprot": "A0A5K4ELT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELT9"
  },
  {
    "uniprot": "A0A5K4F9J3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9J3"
  },
  {
    "uniprot": "A0A5K4F5E4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5E4"
  },
  {
    "uniprot": "A0A5K4F3B2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3B2"
  },
  {
    "uniprot": "A0A5K4FBQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBQ7"
  },
  {
    "uniprot": "G4VJ99",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ99"
  },
  {
    "uniprot": "G4VDR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDR5"
  },
  {
    "uniprot": "A0A3Q0KD62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD62"
  },
  {
    "uniprot": "A0A5K4FB69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB69"
  },
  {
    "uniprot": "A0A5K4ES63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES63"
  },
  {
    "uniprot": "A0A3Q0KFY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFY1"
  },
  {
    "uniprot": "A0A5K4F613",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F613"
  },
  {
    "uniprot": "A0A5K4F7U3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7U3"
  },
  {
    "uniprot": "A0A5K4F2R8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2R8"
  },
  {
    "uniprot": "A0A5K4ELR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELR5"
  },
  {
    "uniprot": "G4LUA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUA8"
  },
  {
    "uniprot": "A0A3Q0KDE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDE9"
  },
  {
    "uniprot": "A0A5K4EFP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFP1"
  },
  {
    "uniprot": "A0A5K4F6L1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6L1"
  },
  {
    "uniprot": "A0A5K4FG73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FG73"
  },
  {
    "uniprot": "A0A5K4FAR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAR7"
  },
  {
    "uniprot": "A0A3Q0KND5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KND5"
  },
  {
    "uniprot": "A0A5K4ERP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERP1"
  },
  {
    "uniprot": "G4V9Q6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9Q6"
  },
  {
    "uniprot": "A0A5K4E956",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E956"
  },
  {
    "uniprot": "G4VLM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLM4"
  },
  {
    "uniprot": "A0A5K4FC02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC02"
  },
  {
    "uniprot": "G4LX34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX34"
  },
  {
    "uniprot": "G4VSD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSD4"
  },
  {
    "uniprot": "A0A5K4ERT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERT1"
  },
  {
    "uniprot": "A0A5K4EM25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM25"
  },
  {
    "uniprot": "A0A5K4EQF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQF6"
  },
  {
    "uniprot": "A0A5K4F428",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F428"
  },
  {
    "uniprot": "A0A3Q0KRZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRZ1"
  },
  {
    "uniprot": "G4VA47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA47"
  },
  {
    "uniprot": "A0A3Q7K6N0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q7K6N0"
  },
  {
    "uniprot": "A0A5K4E8U8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8U8"
  },
  {
    "uniprot": "A0A5K4F5W2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5W2"
  },
  {
    "uniprot": "A0A5K4F463",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F463"
  },
  {
    "uniprot": "A0A5K4EPD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPD6"
  },
  {
    "uniprot": "A0A5K4F4B5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4B5"
  },
  {
    "uniprot": "A0A5K4F9A9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9A9"
  },
  {
    "uniprot": "A0A3Q0KUJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUJ6"
  },
  {
    "uniprot": "A0A3Q0KRA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRA3"
  },
  {
    "uniprot": "A0A3Q0KRY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRY2"
  },
  {
    "uniprot": "A0A5K4F8N9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8N9"
  },
  {
    "uniprot": "G4V5Y0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5Y0"
  },
  {
    "uniprot": "A0A5K4F582",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F582"
  },
  {
    "uniprot": "A0A3Q0KF65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF65"
  },
  {
    "uniprot": "A0A5K4EQQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQQ8"
  },
  {
    "uniprot": "A0A5K4ESD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESD5"
  },
  {
    "uniprot": "A0A5K4EQH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQH7"
  },
  {
    "uniprot": "A0A3Q0KHZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHZ0"
  },
  {
    "uniprot": "G4VGF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGF0"
  },
  {
    "uniprot": "G4LXH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXH6"
  },
  {
    "uniprot": "A0A5K4EQM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQM4"
  },
  {
    "uniprot": "G4LVA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVA8"
  },
  {
    "uniprot": "A0A3Q0KDE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDE0"
  },
  {
    "uniprot": "A0A3Q0KKF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKF8"
  },
  {
    "uniprot": "A0A5K4EH51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH51"
  },
  {
    "uniprot": "A0A5K4F7U9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7U9"
  },
  {
    "uniprot": "A0A3Q0KVC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVC0"
  },
  {
    "uniprot": "A0A5K4ED31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED31"
  },
  {
    "uniprot": "A0A3Q0KIW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIW6"
  },
  {
    "uniprot": "A0A3Q0KIJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIJ2"
  },
  {
    "uniprot": "A0A3Q0KM36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM36"
  },
  {
    "uniprot": "G4VER7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VER7"
  },
  {
    "uniprot": "A0A5K4F165",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F165"
  },
  {
    "uniprot": "A0A5K4FA28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA28"
  },
  {
    "uniprot": "A0A3Q0KF73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF73"
  },
  {
    "uniprot": "A0A3Q0KQZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQZ4"
  },
  {
    "uniprot": "A0A5K4F348",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F348"
  },
  {
    "uniprot": "A0A5K4F840",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F840"
  },
  {
    "uniprot": "A0A5K4FEJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEJ6"
  },
  {
    "uniprot": "A0A3Q0KM16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM16"
  },
  {
    "uniprot": "A0A5K4F1H5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1H5"
  },
  {
    "uniprot": "A0A5K4F7R0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7R0"
  },
  {
    "uniprot": "A0A5K4EW65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW65"
  },
  {
    "uniprot": "A0A3Q0KHG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHG3"
  },
  {
    "uniprot": "A0A5K4ENQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENQ8"
  },
  {
    "uniprot": "A0A5K4F6Q5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Q5"
  },
  {
    "uniprot": "A0A3Q0KN55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN55"
  },
  {
    "uniprot": "A0A3Q0KV62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV62"
  },
  {
    "uniprot": "G4M1L3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1L3"
  },
  {
    "uniprot": "A0A3Q0KR51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR51"
  },
  {
    "uniprot": "A0A5K4EL28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL28"
  },
  {
    "uniprot": "A0A5K4F5T9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5T9"
  },
  {
    "uniprot": "A0A5K4F7N9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7N9"
  },
  {
    "uniprot": "A0A5K4FCB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCB9"
  },
  {
    "uniprot": "A0A5K4EN05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN05"
  },
  {
    "uniprot": "A0A3Q0KLX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLX2"
  },
  {
    "uniprot": "A0A5K4ETU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETU0"
  },
  {
    "uniprot": "A0A3Q0KP86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP86"
  },
  {
    "uniprot": "A0A5K4EBW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBW2"
  },
  {
    "uniprot": "G4VDV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDV7"
  },
  {
    "uniprot": "A0A5K4FAV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAV3"
  },
  {
    "uniprot": "A0A5K4EFH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFH7"
  },
  {
    "uniprot": "A0A5K4F3R7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3R7"
  },
  {
    "uniprot": "A0A3Q0KHQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHQ6"
  },
  {
    "uniprot": "A0A5K4F9C3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9C3"
  },
  {
    "uniprot": "A0A5K4EHC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHC2"
  },
  {
    "uniprot": "A0A5K4F8K0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8K0"
  },
  {
    "uniprot": "G4VG85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VG85"
  },
  {
    "uniprot": "A0A5K4F923",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F923"
  },
  {
    "uniprot": "A0A5K4FA52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA52"
  },
  {
    "uniprot": "A0A5K4ER96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER96"
  },
  {
    "uniprot": "A0A3Q0KMT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMT4"
  },
  {
    "uniprot": "A0A3Q0KEU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEU8"
  },
  {
    "uniprot": "A0A5K4F676",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F676"
  },
  {
    "uniprot": "A0A5K4EWL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWL5"
  },
  {
    "uniprot": "A0A5K4F8I2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8I2"
  },
  {
    "uniprot": "A0A3Q0KUJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUJ9"
  },
  {
    "uniprot": "A0A3Q0KBX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBX7"
  },
  {
    "uniprot": "A0A3Q0KBD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBD4"
  },
  {
    "uniprot": "A0A3Q0KG47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG47"
  },
  {
    "uniprot": "A0A3Q0KJG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJG7"
  },
  {
    "uniprot": "A0A3Q0KR57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR57"
  },
  {
    "uniprot": "A0A5K4F9R3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9R3"
  },
  {
    "uniprot": "A0A5K4F2D3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2D3"
  },
  {
    "uniprot": "A0A5K4FA13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA13"
  },
  {
    "uniprot": "A0A5K4F6J1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6J1"
  },
  {
    "uniprot": "A0A5K4EDH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDH5"
  },
  {
    "uniprot": "A0A5K4FC48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC48"
  },
  {
    "uniprot": "A0A5K4FCM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCM6"
  },
  {
    "uniprot": "A0A3Q0KN37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN37"
  },
  {
    "uniprot": "A0A5K4EBZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBZ1"
  },
  {
    "uniprot": "A0A3Q0KPC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPC7"
  },
  {
    "uniprot": "G4V8R9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8R9"
  },
  {
    "uniprot": "A0A3Q0KSW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSW9"
  },
  {
    "uniprot": "A0A5K4EBP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBP5"
  },
  {
    "uniprot": "A0A5K4EY94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY94"
  },
  {
    "uniprot": "A0A5K4F5S5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5S5"
  },
  {
    "uniprot": "A0A5K4EQP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQP7"
  },
  {
    "uniprot": "A0A5K4EAF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAF7"
  },
  {
    "uniprot": "A0A3Q0KFB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFB3"
  },
  {
    "uniprot": "A0A3Q0KPL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPL0"
  },
  {
    "uniprot": "A0A3Q0KFQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFQ7"
  },
  {
    "uniprot": "A0A3Q0KH17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH17"
  },
  {
    "uniprot": "A0A5K4EE29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE29"
  },
  {
    "uniprot": "A0A3Q0KQZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQZ6"
  },
  {
    "uniprot": "A0A3Q0KRG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRG3"
  },
  {
    "uniprot": "A0A3Q0KBM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBM0"
  },
  {
    "uniprot": "A0A5K4F105",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F105"
  },
  {
    "uniprot": "G4M178",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M178"
  },
  {
    "uniprot": "A0A5K4EP04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP04"
  },
  {
    "uniprot": "A0A5K4F272",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F272"
  },
  {
    "uniprot": "G4VG47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VG47"
  },
  {
    "uniprot": "A0A5K4ENR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENR1"
  },
  {
    "uniprot": "A0A5K4FCF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCF4"
  },
  {
    "uniprot": "A0A5K4FGG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGG9"
  },
  {
    "uniprot": "A0A3Q0KSB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSB0"
  },
  {
    "uniprot": "G4VJS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJS1"
  },
  {
    "uniprot": "G4V630",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V630"
  },
  {
    "uniprot": "A0A3Q0KHW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHW5"
  },
  {
    "uniprot": "A0A5K4F3X5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3X5"
  },
  {
    "uniprot": "A0A5K4F6B3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6B3"
  },
  {
    "uniprot": "G4V7Z9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7Z9"
  },
  {
    "uniprot": "A0A5K4FAU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAU3"
  },
  {
    "uniprot": "A0A5K4EER5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EER5"
  },
  {
    "uniprot": "A0A3Q0KKN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKN6"
  },
  {
    "uniprot": "A0A3Q0KN13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN13"
  },
  {
    "uniprot": "A0A5K4EE63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE63"
  },
  {
    "uniprot": "A0A5K4F6L4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6L4"
  },
  {
    "uniprot": "A0A5K4EIP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIP0"
  },
  {
    "uniprot": "A0A5K4F0A8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0A8"
  },
  {
    "uniprot": "A0A5K4F2C2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2C2"
  },
  {
    "uniprot": "A0A3Q0KP31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP31"
  },
  {
    "uniprot": "A0A3Q0KFD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFD5"
  },
  {
    "uniprot": "A0A5K4EIE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIE3"
  },
  {
    "uniprot": "A0A5K4EGP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGP4"
  },
  {
    "uniprot": "A0A5K4FB17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB17"
  },
  {
    "uniprot": "A0A3Q0KNV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNV1"
  },
  {
    "uniprot": "A0A3Q0KGZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGZ5"
  },
  {
    "uniprot": "A0A3Q0KKL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKL1"
  },
  {
    "uniprot": "A0A5K4FE11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE11"
  },
  {
    "uniprot": "A0A5K4FC87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC87"
  },
  {
    "uniprot": "A0A5K4EK00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK00"
  },
  {
    "uniprot": "A0A3Q0KTN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTN8"
  },
  {
    "uniprot": "A0A3Q0KCK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCK9"
  },
  {
    "uniprot": "A0A3Q0KCF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCF3"
  },
  {
    "uniprot": "G4V6Z7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6Z7"
  },
  {
    "uniprot": "A0A5K4FAD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAD3"
  },
  {
    "uniprot": "A0A5K4EMC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMC7"
  },
  {
    "uniprot": "A0A5K4F2X8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2X8"
  },
  {
    "uniprot": "A0A5K4E974",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E974"
  },
  {
    "uniprot": "A0A5K4FAB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAB2"
  },
  {
    "uniprot": "A0A5K4EK88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK88"
  },
  {
    "uniprot": "A0A3Q0KU07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU07"
  },
  {
    "uniprot": "A0A3Q0KLS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLS6"
  },
  {
    "uniprot": "A0A5K4F721",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F721"
  },
  {
    "uniprot": "A0A3Q0KQJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQJ5"
  },
  {
    "uniprot": "A0A5K4FBW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBW2"
  },
  {
    "uniprot": "A0A3Q0KMP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMP4"
  },
  {
    "uniprot": "A0A3Q0KGU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGU6"
  },
  {
    "uniprot": "G4LUX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUX9"
  },
  {
    "uniprot": "A0A5K4F911",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F911"
  },
  {
    "uniprot": "A0A3Q0KJK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJK7"
  },
  {
    "uniprot": "G4VIN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIN1"
  },
  {
    "uniprot": "A0A5K4EQI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQI3"
  },
  {
    "uniprot": "G4V9Q8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9Q8"
  },
  {
    "uniprot": "Q3HL76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q3HL76"
  },
  {
    "uniprot": "A0A5K4EZ83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ83"
  },
  {
    "uniprot": "A0A3Q0KRQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRQ5"
  },
  {
    "uniprot": "G4VED8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VED8"
  },
  {
    "uniprot": "G4VTP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTP3"
  },
  {
    "uniprot": "A0A5K4F8J6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8J6"
  },
  {
    "uniprot": "C4QCA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QCA2"
  },
  {
    "uniprot": "A0A3Q0KJG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJG3"
  },
  {
    "uniprot": "A0A5K4ELZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELZ8"
  },
  {
    "uniprot": "A0A5K4F247",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F247"
  },
  {
    "uniprot": "A0A3Q0KRT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRT7"
  },
  {
    "uniprot": "A0A5K4F1J8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1J8"
  },
  {
    "uniprot": "A0A5K4E8Q9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8Q9"
  },
  {
    "uniprot": "A0A5K4FFU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFU1"
  },
  {
    "uniprot": "A0A3Q0KKK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKK6"
  },
  {
    "uniprot": "A0A3Q0KCI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCI6"
  },
  {
    "uniprot": "G4VAV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAV3"
  },
  {
    "uniprot": "A0A5K4EEB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEB3"
  },
  {
    "uniprot": "A0A3Q0KHT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHT0"
  },
  {
    "uniprot": "A0A5K4FGK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGK4"
  },
  {
    "uniprot": "A0A5K4F261",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F261"
  },
  {
    "uniprot": "A0A5K4F7G5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7G5"
  },
  {
    "uniprot": "A0A3Q0KPE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPE4"
  },
  {
    "uniprot": "G4VI00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI00"
  },
  {
    "uniprot": "A0A3Q0KN06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN06"
  },
  {
    "uniprot": "A0A5K4F371",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F371"
  },
  {
    "uniprot": "A0A5K4F0R3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0R3"
  },
  {
    "uniprot": "G4VII5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VII5"
  },
  {
    "uniprot": "A0A5K4ELI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELI0"
  },
  {
    "uniprot": "G4VRN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRN7"
  },
  {
    "uniprot": "A0A5K4F4Q5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Q5"
  },
  {
    "uniprot": "A0A5K4F8L8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8L8"
  },
  {
    "uniprot": "A0A5K4EL75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL75"
  },
  {
    "uniprot": "G4M0U2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0U2"
  },
  {
    "uniprot": "A0A3Q0KCR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCR2"
  },
  {
    "uniprot": "A0A3Q0KU06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU06"
  },
  {
    "uniprot": "A0A3Q0KTY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTY7"
  },
  {
    "uniprot": "A0A5K4EUH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUH5"
  },
  {
    "uniprot": "A0A3Q0KKC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKC1"
  },
  {
    "uniprot": "A0A5K4EVH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVH0"
  },
  {
    "uniprot": "A0A5K4FB42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB42"
  },
  {
    "uniprot": "A0A5K4F5N0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5N0"
  },
  {
    "uniprot": "A0A5K4EPX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPX9"
  },
  {
    "uniprot": "A0A3Q0KRX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRX4"
  },
  {
    "uniprot": "G4LZC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZC1"
  },
  {
    "uniprot": "A0A5K4FFL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFL2"
  },
  {
    "uniprot": "A0A3Q0KJQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJQ1"
  },
  {
    "uniprot": "A0A5K4F387",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F387"
  },
  {
    "uniprot": "A0A3Q0KL88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL88"
  },
  {
    "uniprot": "A0A3Q0KUC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUC4"
  },
  {
    "uniprot": "G4M0F7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0F7"
  },
  {
    "uniprot": "A0A5K4EDE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDE5"
  },
  {
    "uniprot": "A0A3Q0KL21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL21"
  },
  {
    "uniprot": "G4VBU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBU0"
  },
  {
    "uniprot": "A0A5K4EI63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EI63"
  },
  {
    "uniprot": "A0A5K4F5T8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5T8"
  },
  {
    "uniprot": "A0A3Q0KLK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLK0"
  },
  {
    "uniprot": "A0A3Q0KGW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGW5"
  },
  {
    "uniprot": "A0A3Q0KS25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS25"
  },
  {
    "uniprot": "A0A5K4EPF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPF5"
  },
  {
    "uniprot": "G4VLY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLY0"
  },
  {
    "uniprot": "A0A5K4F520",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F520"
  },
  {
    "uniprot": "A0A3Q0KSJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSJ3"
  },
  {
    "uniprot": "A0A3Q0KM54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM54"
  },
  {
    "uniprot": "A0A5K4FAN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAN1"
  },
  {
    "uniprot": "A0A5K4EXI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXI6"
  },
  {
    "uniprot": "G4VJD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJD3"
  },
  {
    "uniprot": "A0A5K4F8P7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8P7"
  },
  {
    "uniprot": "A0A5K4F9B2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9B2"
  },
  {
    "uniprot": "G4VS58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS58"
  },
  {
    "uniprot": "A0A5K4EAC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAC7"
  },
  {
    "uniprot": "A0A3Q0KTB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTB2"
  },
  {
    "uniprot": "A0A3Q0KUB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUB9"
  },
  {
    "uniprot": "A0A5K4F9L9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9L9"
  },
  {
    "uniprot": "G4VR20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR20"
  },
  {
    "uniprot": "G4LZA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZA9"
  },
  {
    "uniprot": "A0A3Q0KQG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQG6"
  },
  {
    "uniprot": "A0A5K4FAU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAU8"
  },
  {
    "uniprot": "A0A3Q0KC75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC75"
  },
  {
    "uniprot": "Q15EU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q15EU2"
  },
  {
    "uniprot": "A0A5K4F5U6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5U6"
  },
  {
    "uniprot": "A0A5K4EFX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFX7"
  },
  {
    "uniprot": "A0A5K4F9X8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9X8"
  },
  {
    "uniprot": "A0A3Q0KQT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQT5"
  },
  {
    "uniprot": "A0A5K4F9Z8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Z8"
  },
  {
    "uniprot": "G4LV33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV33"
  },
  {
    "uniprot": "A0A3Q0KKB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKB6"
  },
  {
    "uniprot": "A0A5K4F110",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F110"
  },
  {
    "uniprot": "A0A5K4F0Z8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Z8"
  },
  {
    "uniprot": "A0A5K4F3R0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3R0"
  },
  {
    "uniprot": "A0A3Q0KMP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMP3"
  },
  {
    "uniprot": "A0A5K4F4S6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4S6"
  },
  {
    "uniprot": "A0A3Q0KI78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI78"
  },
  {
    "uniprot": "A0A3Q0KVA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVA2"
  },
  {
    "uniprot": "A0A3Q0KSY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSY5"
  },
  {
    "uniprot": "A0A3Q0KGL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGL8"
  },
  {
    "uniprot": "A0A5K4FAE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAE8"
  },
  {
    "uniprot": "A0A5K4EK47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK47"
  },
  {
    "uniprot": "G4LVB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVB1"
  },
  {
    "uniprot": "A0A3Q0KTU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTU9"
  },
  {
    "uniprot": "A0A5K4F6X9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6X9"
  },
  {
    "uniprot": "A0A5K4F7A3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7A3"
  },
  {
    "uniprot": "A0A3Q0KIE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIE2"
  },
  {
    "uniprot": "A0A5K4FAJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAJ8"
  },
  {
    "uniprot": "A0A5K4FFL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFL7"
  },
  {
    "uniprot": "G4VMR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMR4"
  },
  {
    "uniprot": "G4VSP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSP0"
  },
  {
    "uniprot": "A0A3Q0KUL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUL3"
  },
  {
    "uniprot": "A0A3Q0KI60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI60"
  },
  {
    "uniprot": "A0A3Q0KRC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRC6"
  },
  {
    "uniprot": "A0A5K4FBV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBV6"
  },
  {
    "uniprot": "A0A5K4F720",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F720"
  },
  {
    "uniprot": "A0A5K4EK08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK08"
  },
  {
    "uniprot": "A0A5K4FBJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBJ7"
  },
  {
    "uniprot": "G4V6W8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6W8"
  },
  {
    "uniprot": "A0A5K4FCU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCU5"
  },
  {
    "uniprot": "A0A3Q0KCB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCB6"
  },
  {
    "uniprot": "A0A5K4F338",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F338"
  },
  {
    "uniprot": "A0A5K4F4J9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4J9"
  },
  {
    "uniprot": "A0A3Q0KRL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRL9"
  },
  {
    "uniprot": "A0A3Q0KMP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMP8"
  },
  {
    "uniprot": "A0A5K4EV76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV76"
  },
  {
    "uniprot": "A0A3Q0KTG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTG9"
  },
  {
    "uniprot": "G4VQ81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ81"
  },
  {
    "uniprot": "A0A5K4F2N9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2N9"
  },
  {
    "uniprot": "A0A5K4F4Y6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Y6"
  },
  {
    "uniprot": "G4VK08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK08"
  },
  {
    "uniprot": "A0A5K4FAU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAU0"
  },
  {
    "uniprot": "A0A5K4EWF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWF7"
  },
  {
    "uniprot": "G4LYW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYW9"
  },
  {
    "uniprot": "G4M097",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M097"
  },
  {
    "uniprot": "A0A5K4FAM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAM0"
  },
  {
    "uniprot": "A0A5K4F698",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F698"
  },
  {
    "uniprot": "A0A5K4ECW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECW5"
  },
  {
    "uniprot": "G4VHQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHQ2"
  },
  {
    "uniprot": "G4LY23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY23"
  },
  {
    "uniprot": "A0A3Q0KLB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLB1"
  },
  {
    "uniprot": "A0A5K4F8R5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8R5"
  },
  {
    "uniprot": "A0A3Q0KHK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHK9"
  },
  {
    "uniprot": "A0A3Q0KBF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBF1"
  },
  {
    "uniprot": "G4VQS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQS1"
  },
  {
    "uniprot": "A0A5K4F2U3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2U3"
  },
  {
    "uniprot": "A0A5K4F0I5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0I5"
  },
  {
    "uniprot": "G4VQA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQA4"
  },
  {
    "uniprot": "A0A5K4F873",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F873"
  },
  {
    "uniprot": "A0A5K4EAP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAP7"
  },
  {
    "uniprot": "A0A3Q0KHP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHP9"
  },
  {
    "uniprot": "A0A5K4F776",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F776"
  },
  {
    "uniprot": "A0A3Q0KP97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP97"
  },
  {
    "uniprot": "G4M0Y6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0Y6"
  },
  {
    "uniprot": "A0A5K4FGD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGD9"
  },
  {
    "uniprot": "A0A5K4FE57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE57"
  },
  {
    "uniprot": "A0A5K4FEJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEJ5"
  },
  {
    "uniprot": "G4VK92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK92"
  },
  {
    "uniprot": "G4VRD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRD9"
  },
  {
    "uniprot": "A0A3Q0KMX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMX5"
  },
  {
    "uniprot": "A0A3Q0KUN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUN0"
  },
  {
    "uniprot": "A0A3Q0KV25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV25"
  },
  {
    "uniprot": "A0A3Q0KRB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRB8"
  },
  {
    "uniprot": "A0A5K4EUE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUE3"
  },
  {
    "uniprot": "A0A5K4F8B7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8B7"
  },
  {
    "uniprot": "A0A3Q0KMM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMM4"
  },
  {
    "uniprot": "A0A5K4F961",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F961"
  },
  {
    "uniprot": "A0A5K4F7D6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7D6"
  },
  {
    "uniprot": "A0A5K4F9D1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9D1"
  },
  {
    "uniprot": "A0A3Q0KQP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQP8"
  },
  {
    "uniprot": "A0A5K4FDY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDY8"
  },
  {
    "uniprot": "A0A5K4EM18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM18"
  },
  {
    "uniprot": "A0A5K4F8N5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8N5"
  },
  {
    "uniprot": "A0A3Q0KE96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE96"
  },
  {
    "uniprot": "A0A146MHV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MHV0"
  },
  {
    "uniprot": "A0A3Q0KUR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUR5"
  },
  {
    "uniprot": "G4VRK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRK3"
  },
  {
    "uniprot": "G4VJW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJW9"
  },
  {
    "uniprot": "A0A5K4ETQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETQ2"
  },
  {
    "uniprot": "A0A5K4FFM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFM3"
  },
  {
    "uniprot": "A0A3Q0KR06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR06"
  },
  {
    "uniprot": "G4VDJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDJ6"
  },
  {
    "uniprot": "A0A3Q0KV76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV76"
  },
  {
    "uniprot": "A0A5K4F1W3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1W3"
  },
  {
    "uniprot": "A0A3Q0KMG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMG7"
  },
  {
    "uniprot": "A0A5K4F0W6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0W6"
  },
  {
    "uniprot": "A0A5K4EXD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXD9"
  },
  {
    "uniprot": "A0A5K4EE66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE66"
  },
  {
    "uniprot": "A0A5K4EY29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY29"
  },
  {
    "uniprot": "A0A5K4F326",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F326"
  },
  {
    "uniprot": "A0A5K4F9S7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9S7"
  },
  {
    "uniprot": "A0A5K4FEV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEV9"
  },
  {
    "uniprot": "A0A5K4F0N6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0N6"
  },
  {
    "uniprot": "G4LUJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUJ6"
  },
  {
    "uniprot": "G4LWI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWI9"
  },
  {
    "uniprot": "A0A5K4F3G9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3G9"
  },
  {
    "uniprot": "A0A5K4ER68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER68"
  },
  {
    "uniprot": "A0A5K4ET86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET86"
  },
  {
    "uniprot": "A0A3Q0KLG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLG3"
  },
  {
    "uniprot": "G4VAF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAF2"
  },
  {
    "uniprot": "A0A5K4EMM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMM8"
  },
  {
    "uniprot": "A0A3Q0KIX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIX1"
  },
  {
    "uniprot": "A0A5K4FF81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF81"
  },
  {
    "uniprot": "G4VFG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFG9"
  },
  {
    "uniprot": "G4VTT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTT5"
  },
  {
    "uniprot": "A0A5K4F3E9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3E9"
  },
  {
    "uniprot": "A0A5K4EKF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKF4"
  },
  {
    "uniprot": "A0A5K4F9K6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9K6"
  },
  {
    "uniprot": "G4V5P7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5P7"
  },
  {
    "uniprot": "A0A5K4FBH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBH8"
  },
  {
    "uniprot": "G4V882",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V882"
  },
  {
    "uniprot": "A0A3Q0KUC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUC8"
  },
  {
    "uniprot": "A0A5K4EZ35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ35"
  },
  {
    "uniprot": "G4LYZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYZ2"
  },
  {
    "uniprot": "A0A5K4F9H0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9H0"
  },
  {
    "uniprot": "A0A5K4F8W6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8W6"
  },
  {
    "uniprot": "A0A5K4F9J8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9J8"
  },
  {
    "uniprot": "G4V851",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V851"
  },
  {
    "uniprot": "A0A5K4FAS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAS7"
  },
  {
    "uniprot": "G4M0Z5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0Z5"
  },
  {
    "uniprot": "A0A3Q0KEQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEQ4"
  },
  {
    "uniprot": "A0A5K4FB48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB48"
  },
  {
    "uniprot": "G4VSI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSI1"
  },
  {
    "uniprot": "A0A3Q0KKC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKC7"
  },
  {
    "uniprot": "A0A5K4F6Q6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Q6"
  },
  {
    "uniprot": "A0A5K4F4L0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4L0"
  },
  {
    "uniprot": "A0A5K4F7N7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7N7"
  },
  {
    "uniprot": "A0A5K4F515",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F515"
  },
  {
    "uniprot": "A0A5K4F1R8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1R8"
  },
  {
    "uniprot": "A0A3Q0KM20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM20"
  },
  {
    "uniprot": "A0A5K4EUH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUH8"
  },
  {
    "uniprot": "A0A3Q0KQ90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ90"
  },
  {
    "uniprot": "A0A3Q0KMA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMA8"
  },
  {
    "uniprot": "A0A5K4EMK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMK3"
  },
  {
    "uniprot": "A0A3Q0KC94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC94"
  },
  {
    "uniprot": "A0A5K4FCE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCE7"
  },
  {
    "uniprot": "A0A3Q0KG85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG85"
  },
  {
    "uniprot": "A0A5K4F8X5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8X5"
  },
  {
    "uniprot": "A0A3Q0KKN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKN0"
  },
  {
    "uniprot": "A0A5K4EQV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQV5"
  },
  {
    "uniprot": "A0A5K4FA45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA45"
  },
  {
    "uniprot": "A0A3Q0KSA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSA6"
  },
  {
    "uniprot": "A0A3Q0KHI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHI5"
  },
  {
    "uniprot": "A0A5K4ERH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERH4"
  },
  {
    "uniprot": "G4V870",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V870"
  },
  {
    "uniprot": "C4QPU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QPU9"
  },
  {
    "uniprot": "G4V5I9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5I9"
  },
  {
    "uniprot": "A0A5K4FGM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGM3"
  },
  {
    "uniprot": "A0A3Q0KU97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU97"
  },
  {
    "uniprot": "A0A3Q0KKJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKJ8"
  },
  {
    "uniprot": "A0A5K4F073",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F073"
  },
  {
    "uniprot": "A0A3Q0KUE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUE3"
  },
  {
    "uniprot": "A0A3Q0KDS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDS7"
  },
  {
    "uniprot": "A0A3Q0KKN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKN3"
  },
  {
    "uniprot": "A0A3Q0KUR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUR1"
  },
  {
    "uniprot": "A0A5K4FCL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCL1"
  },
  {
    "uniprot": "A0A3Q0KP82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP82"
  },
  {
    "uniprot": "A0A3Q0KR68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR68"
  },
  {
    "uniprot": "A0A5K4F3E7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3E7"
  },
  {
    "uniprot": "A0A3Q0KPH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPH8"
  },
  {
    "uniprot": "A0A3Q0KDU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDU8"
  },
  {
    "uniprot": "A0A5K4EK57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK57"
  },
  {
    "uniprot": "A0A5K4FAF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAF5"
  },
  {
    "uniprot": "A0A5K4EMX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMX2"
  },
  {
    "uniprot": "A0A5K4FAH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAH7"
  },
  {
    "uniprot": "A0A5K4F4X6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4X6"
  },
  {
    "uniprot": "A0A5K4F8C3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8C3"
  },
  {
    "uniprot": "A0A3Q0KS02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS02"
  },
  {
    "uniprot": "A0A5K4F7N3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7N3"
  },
  {
    "uniprot": "A0A3Q0KSV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSV7"
  },
  {
    "uniprot": "A0A3Q0KT51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT51"
  },
  {
    "uniprot": "G4LXD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXD3"
  },
  {
    "uniprot": "A0A5K4FCS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCS2"
  },
  {
    "uniprot": "A0A3Q0KKI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKI2"
  },
  {
    "uniprot": "A0A5K4F1Z3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Z3"
  },
  {
    "uniprot": "A0A5K4EMH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMH0"
  },
  {
    "uniprot": "A0A5K4EDN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDN2"
  },
  {
    "uniprot": "A0A3Q0KU27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU27"
  },
  {
    "uniprot": "G4VLD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLD7"
  },
  {
    "uniprot": "A0A3Q0KEQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEQ5"
  },
  {
    "uniprot": "A0A3Q0KSR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSR9"
  },
  {
    "uniprot": "A0A5K4F6S7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6S7"
  },
  {
    "uniprot": "G4V647",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V647"
  },
  {
    "uniprot": "G4LWS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWS5"
  },
  {
    "uniprot": "A0A5K4F390",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F390"
  },
  {
    "uniprot": "A0A3Q0KST1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KST1"
  },
  {
    "uniprot": "A0A5K4EZJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZJ5"
  },
  {
    "uniprot": "A0A5K4FD41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD41"
  },
  {
    "uniprot": "A0A5K4EWY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWY0"
  },
  {
    "uniprot": "A0A5K4F8A6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8A6"
  },
  {
    "uniprot": "A0A3Q0KNM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNM5"
  },
  {
    "uniprot": "G4VMX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMX6"
  },
  {
    "uniprot": "A0A3Q0KM90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM90"
  },
  {
    "uniprot": "A0A3Q0KV96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV96"
  },
  {
    "uniprot": "A0A5K4FCL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCL9"
  },
  {
    "uniprot": "G4LX16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX16"
  },
  {
    "uniprot": "G4VES0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VES0"
  },
  {
    "uniprot": "A0A3Q0KQR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQR9"
  },
  {
    "uniprot": "A0A5K4FDF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDF4"
  },
  {
    "uniprot": "A0A3Q0KTK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTK1"
  },
  {
    "uniprot": "G4VGQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGQ1"
  },
  {
    "uniprot": "A0A5K4F2Z6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Z6"
  },
  {
    "uniprot": "A0A5K4FDU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDU2"
  },
  {
    "uniprot": "A0A5K4EK89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK89"
  },
  {
    "uniprot": "A0A5K4F8T8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8T8"
  },
  {
    "uniprot": "G4VL77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VL77"
  },
  {
    "uniprot": "A0A146MID7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MID7"
  },
  {
    "uniprot": "A0A3Q0KQM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQM2"
  },
  {
    "uniprot": "A0A5K4F6Z1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Z1"
  },
  {
    "uniprot": "A0A5K4EZY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZY5"
  },
  {
    "uniprot": "A0A5K4ERU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERU5"
  },
  {
    "uniprot": "A0A5K4E9P0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9P0"
  },
  {
    "uniprot": "A0A3Q0KNW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNW1"
  },
  {
    "uniprot": "G4VM02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM02"
  },
  {
    "uniprot": "A0A5K4F5H1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5H1"
  },
  {
    "uniprot": "A0A5K4FAM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAM6"
  },
  {
    "uniprot": "A0A3Q0KNK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNK6"
  },
  {
    "uniprot": "A0A5K4EU04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU04"
  },
  {
    "uniprot": "G4VBS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBS1"
  },
  {
    "uniprot": "A0A5K4F9T8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9T8"
  },
  {
    "uniprot": "A0A5K4F464",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F464"
  },
  {
    "uniprot": "A0A3Q0KP96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP96"
  },
  {
    "uniprot": "G4LV42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV42"
  },
  {
    "uniprot": "A0A5K4F458",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F458"
  },
  {
    "uniprot": "G4VBG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBG5"
  },
  {
    "uniprot": "G4VMJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMJ9"
  },
  {
    "uniprot": "G4VCM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCM2"
  },
  {
    "uniprot": "A0A5K4EWU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWU7"
  },
  {
    "uniprot": "A0A3Q0KP67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP67"
  },
  {
    "uniprot": "A0A3Q0KC79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC79"
  },
  {
    "uniprot": "A0A5K4EVL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVL5"
  },
  {
    "uniprot": "A0A5K4F4M7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4M7"
  },
  {
    "uniprot": "A0A5K4F5P2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5P2"
  },
  {
    "uniprot": "A0A5K4EE61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE61"
  },
  {
    "uniprot": "A0A5K4ERT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERT6"
  },
  {
    "uniprot": "A0A3Q0KS94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS94"
  },
  {
    "uniprot": "A0A5K4F0T3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0T3"
  },
  {
    "uniprot": "A0A3Q0KNS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNS6"
  },
  {
    "uniprot": "A0A5K4F0I9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0I9"
  },
  {
    "uniprot": "A0A5K4F260",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F260"
  },
  {
    "uniprot": "A0A3Q0KCB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCB0"
  },
  {
    "uniprot": "Q7KPG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q7KPG1"
  },
  {
    "uniprot": "G4VB52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB52"
  },
  {
    "uniprot": "A0A3Q0KKJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKJ5"
  },
  {
    "uniprot": "A0A3Q0KMG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMG3"
  },
  {
    "uniprot": "A0A3Q0KFU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFU6"
  },
  {
    "uniprot": "A0A5K4EMG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMG0"
  },
  {
    "uniprot": "A0A5K4F723",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F723"
  },
  {
    "uniprot": "A0A3Q0KJ79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ79"
  },
  {
    "uniprot": "G4VJ59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ59"
  },
  {
    "uniprot": "G4VSK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSK8"
  },
  {
    "uniprot": "A0A3Q0KS96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS96"
  },
  {
    "uniprot": "A0A5K4FAW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAW0"
  },
  {
    "uniprot": "A0A5K4F8Q2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Q2"
  },
  {
    "uniprot": "A0A3Q0KDI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDI0"
  },
  {
    "uniprot": "A0A5K4F0G5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0G5"
  },
  {
    "uniprot": "G4VB58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB58"
  },
  {
    "uniprot": "A0A3Q0KLM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLM7"
  },
  {
    "uniprot": "A0A5K4F9X7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9X7"
  },
  {
    "uniprot": "A0A3Q0KTR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTR4"
  },
  {
    "uniprot": "A0A5K4E9U5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9U5"
  },
  {
    "uniprot": "A0A5K4F716",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F716"
  },
  {
    "uniprot": "A0A5K4FAT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAT9"
  },
  {
    "uniprot": "A0A3Q0KN12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN12"
  },
  {
    "uniprot": "A0A3Q0KT32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT32"
  },
  {
    "uniprot": "A0A3Q0KTF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTF7"
  },
  {
    "uniprot": "A0A5K4FCI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCI6"
  },
  {
    "uniprot": "P25319",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P25319"
  },
  {
    "uniprot": "A0A5K4F7R5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7R5"
  },
  {
    "uniprot": "A0A5K4F385",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F385"
  },
  {
    "uniprot": "G4VEH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEH5"
  },
  {
    "uniprot": "A0A5K4F9V9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9V9"
  },
  {
    "uniprot": "A0A5K4ESU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESU3"
  },
  {
    "uniprot": "G4LX41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX41"
  },
  {
    "uniprot": "G4M0W9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0W9"
  },
  {
    "uniprot": "A0A5K4EL72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL72"
  },
  {
    "uniprot": "A0A3Q0KT57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT57"
  },
  {
    "uniprot": "A0A3Q0KKS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKS1"
  },
  {
    "uniprot": "A0A5K4F362",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F362"
  },
  {
    "uniprot": "A0A3Q0KCE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCE2"
  },
  {
    "uniprot": "A0A5K4FBC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBC2"
  },
  {
    "uniprot": "A0A5K4EMI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMI8"
  },
  {
    "uniprot": "A0A3Q0KQ27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ27"
  },
  {
    "uniprot": "A0A3Q0KNE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNE5"
  },
  {
    "uniprot": "A0A3Q0KKF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKF1"
  },
  {
    "uniprot": "Q01137",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q01137"
  },
  {
    "uniprot": "A0A5K4EEJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEJ4"
  },
  {
    "uniprot": "G4VAD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAD7"
  },
  {
    "uniprot": "A0A3Q0KQI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQI0"
  },
  {
    "uniprot": "A0A3Q0KGA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGA3"
  },
  {
    "uniprot": "A0A5K4EQU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQU9"
  },
  {
    "uniprot": "A0A5K4FCL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCL4"
  },
  {
    "uniprot": "A0A5K4F2I3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2I3"
  },
  {
    "uniprot": "A0A3Q0KS76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS76"
  },
  {
    "uniprot": "G4LVC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVC7"
  },
  {
    "uniprot": "G4VFI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFI9"
  },
  {
    "uniprot": "A0A3Q0KCJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCJ0"
  },
  {
    "uniprot": "A0A5K4FC65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC65"
  },
  {
    "uniprot": "A0A5K4EAJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAJ8"
  },
  {
    "uniprot": "A0A5K4F1M3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1M3"
  },
  {
    "uniprot": "A0A3Q0KMS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMS9"
  },
  {
    "uniprot": "A0A5K4F008",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F008"
  },
  {
    "uniprot": "A0A5K4F2B8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2B8"
  },
  {
    "uniprot": "A0A3Q0KCT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCT0"
  },
  {
    "uniprot": "A0A3Q0KD44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD44"
  },
  {
    "uniprot": "A0A5K4FC73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC73"
  },
  {
    "uniprot": "A0A5K4F499",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F499"
  },
  {
    "uniprot": "A0A5K4FAT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAT1"
  },
  {
    "uniprot": "A0A5K4EDQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDQ1"
  },
  {
    "uniprot": "A0A3Q0KMA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMA4"
  },
  {
    "uniprot": "A0A5K4F5Z4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Z4"
  },
  {
    "uniprot": "A0A3Q0KSK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSK3"
  },
  {
    "uniprot": "A0A3Q0KFA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFA6"
  },
  {
    "uniprot": "A0A5K4F5E2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5E2"
  },
  {
    "uniprot": "A0A5K4EHA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHA0"
  },
  {
    "uniprot": "A0A3Q0KSM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSM5"
  },
  {
    "uniprot": "A0A5K4EB06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB06"
  },
  {
    "uniprot": "A0A5K4ERV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERV6"
  },
  {
    "uniprot": "A0A3Q0KEP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEP0"
  },
  {
    "uniprot": "A0A5K4F2D0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2D0"
  },
  {
    "uniprot": "G4VA94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA94"
  },
  {
    "uniprot": "G4V5V7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5V7"
  },
  {
    "uniprot": "A0A3Q0KKZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKZ4"
  },
  {
    "uniprot": "A0A5K4EQ66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ66"
  },
  {
    "uniprot": "A0A3Q0KMI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMI7"
  },
  {
    "uniprot": "A0A5K4F855",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F855"
  },
  {
    "uniprot": "A0A5K4F2H4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2H4"
  },
  {
    "uniprot": "G4VBY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBY6"
  },
  {
    "uniprot": "A0A3Q0KFR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFR4"
  },
  {
    "uniprot": "A0A3Q0KV51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV51"
  },
  {
    "uniprot": "A0A5K4F5C8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5C8"
  },
  {
    "uniprot": "G4LUC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUC1"
  },
  {
    "uniprot": "A0A3Q0KD47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD47"
  },
  {
    "uniprot": "A0A3Q0KTN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTN6"
  },
  {
    "uniprot": "A0A3Q0KG24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG24"
  },
  {
    "uniprot": "A0A3Q0KC95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC95"
  },
  {
    "uniprot": "A0A5K4EWN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWN0"
  },
  {
    "uniprot": "A0A3Q0KFC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFC0"
  },
  {
    "uniprot": "A0A3Q0KPR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPR8"
  },
  {
    "uniprot": "A0A3Q0KJ03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ03"
  },
  {
    "uniprot": "A0A3Q0KCM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCM7"
  },
  {
    "uniprot": "A0A5K4F6I4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6I4"
  },
  {
    "uniprot": "A0A5K4FC96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC96"
  },
  {
    "uniprot": "A0A3Q0KQK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQK2"
  },
  {
    "uniprot": "Q9GQN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q9GQN9"
  },
  {
    "uniprot": "A0A5K4ELF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELF3"
  },
  {
    "uniprot": "A0A5K4EFH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFH6"
  },
  {
    "uniprot": "A0A5K4F8N4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8N4"
  },
  {
    "uniprot": "A0A3Q0KQQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQQ9"
  },
  {
    "uniprot": "A0A3Q0KC53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC53"
  },
  {
    "uniprot": "G4V9R1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9R1"
  },
  {
    "uniprot": "A0A5K4FBR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBR8"
  },
  {
    "uniprot": "A0A5K4EFD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFD5"
  },
  {
    "uniprot": "A0A3Q0KVH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVH1"
  },
  {
    "uniprot": "A0A3Q0KLK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLK8"
  },
  {
    "uniprot": "A0A5K4F130",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F130"
  },
  {
    "uniprot": "A0A3Q0KJZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJZ8"
  },
  {
    "uniprot": "G4VAU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAU8"
  },
  {
    "uniprot": "A0A3Q0KCF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCF6"
  },
  {
    "uniprot": "A0A3Q0KQZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQZ1"
  },
  {
    "uniprot": "A0A5K4FAE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAE6"
  },
  {
    "uniprot": "A0A5K4F1C3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1C3"
  },
  {
    "uniprot": "A0A3Q0KES8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KES8"
  },
  {
    "uniprot": "A0A3Q0KNG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNG0"
  },
  {
    "uniprot": "G4VGH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGH0"
  },
  {
    "uniprot": "A0A5K4F581",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F581"
  },
  {
    "uniprot": "A0A3Q0KFJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFJ4"
  },
  {
    "uniprot": "A0A3Q0KVL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVL2"
  },
  {
    "uniprot": "G4VI28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI28"
  },
  {
    "uniprot": "A0A5K4EXK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXK2"
  },
  {
    "uniprot": "A0A5K4F280",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F280"
  },
  {
    "uniprot": "A0A5K4F503",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F503"
  },
  {
    "uniprot": "A0A5K4FCD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCD5"
  },
  {
    "uniprot": "A0A5K4FER0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FER0"
  },
  {
    "uniprot": "A0A3Q0KPC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPC5"
  },
  {
    "uniprot": "A0A3Q0KJ97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ97"
  },
  {
    "uniprot": "G4VPS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPS8"
  },
  {
    "uniprot": "A0A5K4EWG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWG1"
  },
  {
    "uniprot": "A0A3Q0KQU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQU7"
  },
  {
    "uniprot": "G4V8H9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8H9"
  },
  {
    "uniprot": "G4VN61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VN61"
  },
  {
    "uniprot": "A0A3Q0KNU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNU0"
  },
  {
    "uniprot": "A0A5K4FBG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBG6"
  },
  {
    "uniprot": "A0A5K4EIZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIZ2"
  },
  {
    "uniprot": "A0A3Q0KRR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRR4"
  },
  {
    "uniprot": "A0A3Q0KU13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU13"
  },
  {
    "uniprot": "G4V782",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V782"
  },
  {
    "uniprot": "A0A5K4F710",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F710"
  },
  {
    "uniprot": "A0A3Q0KJK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJK8"
  },
  {
    "uniprot": "A0A5K4ENN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENN9"
  },
  {
    "uniprot": "A0A3Q0KUI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUI3"
  },
  {
    "uniprot": "A0A5K4ERC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERC3"
  },
  {
    "uniprot": "A0A5K4FB50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB50"
  },
  {
    "uniprot": "A0A5K4F1Y6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Y6"
  },
  {
    "uniprot": "A0A5K4F1Z1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Z1"
  },
  {
    "uniprot": "A0A5K4FAX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAX8"
  },
  {
    "uniprot": "G4VK88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK88"
  },
  {
    "uniprot": "A0A5K4EPY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPY4"
  },
  {
    "uniprot": "G4VL82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VL82"
  },
  {
    "uniprot": "A0A3Q0KSA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSA9"
  },
  {
    "uniprot": "A0A5K4F2Q4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Q4"
  },
  {
    "uniprot": "A0A3Q0KCH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCH6"
  },
  {
    "uniprot": "A0A5K4EVZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVZ7"
  },
  {
    "uniprot": "A0A3Q0KCH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCH5"
  },
  {
    "uniprot": "C4QII0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QII0"
  },
  {
    "uniprot": "A0A5K4ESX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESX1"
  },
  {
    "uniprot": "G4VHA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHA9"
  },
  {
    "uniprot": "G4V7S4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7S4"
  },
  {
    "uniprot": "A0A3Q0KEY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEY1"
  },
  {
    "uniprot": "A0A5K4F0W0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0W0"
  },
  {
    "uniprot": "A0A3Q0KLU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLU9"
  },
  {
    "uniprot": "A0A5K4F8A3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8A3"
  },
  {
    "uniprot": "A0A5K4EVY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVY2"
  },
  {
    "uniprot": "A0A3Q0KTB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTB5"
  },
  {
    "uniprot": "G4VTU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTU1"
  },
  {
    "uniprot": "A0A3Q0KJX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJX7"
  },
  {
    "uniprot": "G4V889",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V889"
  },
  {
    "uniprot": "A0A3Q0KU26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU26"
  },
  {
    "uniprot": "G4VFD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFD9"
  },
  {
    "uniprot": "A0A3Q0KU18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU18"
  },
  {
    "uniprot": "A0A3Q0KT28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT28"
  },
  {
    "uniprot": "A0A5K4F303",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F303"
  },
  {
    "uniprot": "A0A5K4F3L7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3L7"
  },
  {
    "uniprot": "A0A3Q0KRK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRK0"
  },
  {
    "uniprot": "A0A5K4EB11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB11"
  },
  {
    "uniprot": "G4VKL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKL7"
  },
  {
    "uniprot": "A0A3Q0KNA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNA8"
  },
  {
    "uniprot": "A0A3Q0KBN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBN4"
  },
  {
    "uniprot": "A0A3Q0KUR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUR2"
  },
  {
    "uniprot": "A0A5K4EH13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH13"
  },
  {
    "uniprot": "A0A3Q0KU83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU83"
  },
  {
    "uniprot": "A0A3Q0KJV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJV7"
  },
  {
    "uniprot": "A0A5K4ER47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER47"
  },
  {
    "uniprot": "A0A5K4FBQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBQ8"
  },
  {
    "uniprot": "A0A3Q0KBQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBQ3"
  },
  {
    "uniprot": "A0A5K4FDR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDR2"
  },
  {
    "uniprot": "A0A5K4EV54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV54"
  },
  {
    "uniprot": "G4VM66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM66"
  },
  {
    "uniprot": "A0A5K4EGY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGY4"
  },
  {
    "uniprot": "A0A5K4EQH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQH2"
  },
  {
    "uniprot": "A0A3Q0KPI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPI0"
  },
  {
    "uniprot": "A0A5K4ED81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED81"
  },
  {
    "uniprot": "A0A5K4EI03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EI03"
  },
  {
    "uniprot": "A0A5K4E9I7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9I7"
  },
  {
    "uniprot": "A0A5K4F5U0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5U0"
  },
  {
    "uniprot": "A0A3Q0KI87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI87"
  },
  {
    "uniprot": "A0A3Q0KEG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEG1"
  },
  {
    "uniprot": "A0A5K4F5G2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5G2"
  },
  {
    "uniprot": "A0A5K4F3L4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3L4"
  },
  {
    "uniprot": "A0A5K4FBP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBP4"
  },
  {
    "uniprot": "A0A5K4F4C2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4C2"
  },
  {
    "uniprot": "A0A5K4FCR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCR7"
  },
  {
    "uniprot": "G4V825",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V825"
  },
  {
    "uniprot": "A0A5K4F766",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F766"
  },
  {
    "uniprot": "A0A3Q0KFW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFW9"
  },
  {
    "uniprot": "A0A5K4F239",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F239"
  },
  {
    "uniprot": "A0A5K4ESE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESE9"
  },
  {
    "uniprot": "A0A5K4F558",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F558"
  },
  {
    "uniprot": "A0A5K4EVU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVU8"
  },
  {
    "uniprot": "A0A5K4F547",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F547"
  },
  {
    "uniprot": "A0A5K4FBI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBI2"
  },
  {
    "uniprot": "A0A5K4ETY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETY8"
  },
  {
    "uniprot": "A0A3Q0KLQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLQ9"
  },
  {
    "uniprot": "A0A3Q0KQS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQS3"
  },
  {
    "uniprot": "G4V8Q8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8Q8"
  },
  {
    "uniprot": "G4VF91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF91"
  },
  {
    "uniprot": "A0A5K4EHZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHZ9"
  },
  {
    "uniprot": "A0A5K4FEN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEN2"
  },
  {
    "uniprot": "A0A3Q0KN82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN82"
  },
  {
    "uniprot": "A0A5K4EVX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVX4"
  },
  {
    "uniprot": "A0A3Q0KGC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGC3"
  },
  {
    "uniprot": "A0A5K4EVR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVR3"
  },
  {
    "uniprot": "G4VQ61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ61"
  },
  {
    "uniprot": "A0A5K4F7M1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7M1"
  },
  {
    "uniprot": "A0A5K4F3B1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3B1"
  },
  {
    "uniprot": "A0A5K4F4L5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4L5"
  },
  {
    "uniprot": "A0A3Q0KU76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU76"
  },
  {
    "uniprot": "A0A5K4FBT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBT6"
  },
  {
    "uniprot": "A0A5K4EBD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBD9"
  },
  {
    "uniprot": "A0A5K4E9S7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9S7"
  },
  {
    "uniprot": "A0A5K4FC44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC44"
  },
  {
    "uniprot": "G4VGS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGS4"
  },
  {
    "uniprot": "A0A3Q0KVB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVB8"
  },
  {
    "uniprot": "A0A5K4EZT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZT2"
  },
  {
    "uniprot": "G4LZ20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ20"
  },
  {
    "uniprot": "G4VI89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI89"
  },
  {
    "uniprot": "G4M0H3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0H3"
  },
  {
    "uniprot": "G4VC18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC18"
  },
  {
    "uniprot": "A0A5K4F2H7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2H7"
  },
  {
    "uniprot": "A0A5K4F4W6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4W6"
  },
  {
    "uniprot": "A0A5K4ENE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENE7"
  },
  {
    "uniprot": "G4VH48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH48"
  },
  {
    "uniprot": "A0A5K4EGT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGT7"
  },
  {
    "uniprot": "A0A3Q0KMR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMR3"
  },
  {
    "uniprot": "A0A5K4EQP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQP2"
  },
  {
    "uniprot": "A0A5K4F6Z5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Z5"
  },
  {
    "uniprot": "A0A5K4FB06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB06"
  },
  {
    "uniprot": "A0A3Q0KQA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQA0"
  },
  {
    "uniprot": "A0A5K4EFL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFL4"
  },
  {
    "uniprot": "A0A3Q0KUY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUY4"
  },
  {
    "uniprot": "A0A3Q0KDZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDZ0"
  },
  {
    "uniprot": "A0A5K4F7A4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7A4"
  },
  {
    "uniprot": "A0A5K4F2Q6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Q6"
  },
  {
    "uniprot": "A0A3Q0KD79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD79"
  },
  {
    "uniprot": "A0A5K4F1U9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1U9"
  },
  {
    "uniprot": "G4LYS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYS9"
  },
  {
    "uniprot": "A0A5K4F2L1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2L1"
  },
  {
    "uniprot": "A0A5K4EFD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFD3"
  },
  {
    "uniprot": "A0A5K4EU31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU31"
  },
  {
    "uniprot": "A0A5K4EY50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY50"
  },
  {
    "uniprot": "A0A5K4F4Q3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Q3"
  },
  {
    "uniprot": "A0A3Q0KDS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDS9"
  },
  {
    "uniprot": "A0A3Q0KLC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLC2"
  },
  {
    "uniprot": "A0A5K4F8D6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8D6"
  },
  {
    "uniprot": "A0A3Q0KRE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRE5"
  },
  {
    "uniprot": "A0A5K4ETQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETQ3"
  },
  {
    "uniprot": "G4VQL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQL5"
  },
  {
    "uniprot": "A0A5K4F6K3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6K3"
  },
  {
    "uniprot": "A0A3Q0KQ87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ87"
  },
  {
    "uniprot": "A0A5K4ELM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELM8"
  },
  {
    "uniprot": "A0A5K4F3C7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3C7"
  },
  {
    "uniprot": "A0A5K4F5K7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5K7"
  },
  {
    "uniprot": "P49965",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P49965"
  },
  {
    "uniprot": "A0A5K4F8Q5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Q5"
  },
  {
    "uniprot": "A0A5K4FCZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCZ7"
  },
  {
    "uniprot": "A0A5K4EXI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXI9"
  },
  {
    "uniprot": "A0A5K4F1Z7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Z7"
  },
  {
    "uniprot": "A0A5K4EDF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDF6"
  },
  {
    "uniprot": "A0A5K4FD99",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD99"
  },
  {
    "uniprot": "A0A3Q0KSG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSG0"
  },
  {
    "uniprot": "A0A5K4FFE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFE1"
  },
  {
    "uniprot": "G4LW01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LW01"
  },
  {
    "uniprot": "A0A5K4F347",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F347"
  },
  {
    "uniprot": "A0A5K4EBN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBN3"
  },
  {
    "uniprot": "G4M0F8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0F8"
  },
  {
    "uniprot": "A0A5K4EDF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDF9"
  },
  {
    "uniprot": "A0A3Q0KLI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLI8"
  },
  {
    "uniprot": "A0A5K4F6Z0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Z0"
  },
  {
    "uniprot": "G4VLA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLA7"
  },
  {
    "uniprot": "A0A5K4FAE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAE3"
  },
  {
    "uniprot": "A0A5K4EF40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EF40"
  },
  {
    "uniprot": "A0A3Q0KBG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBG7"
  },
  {
    "uniprot": "A0A5K4EHF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHF9"
  },
  {
    "uniprot": "G4VGQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGQ7"
  },
  {
    "uniprot": "A0A5K4F6C7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6C7"
  },
  {
    "uniprot": "A0A5K4F207",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F207"
  },
  {
    "uniprot": "A0A5K4FBA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBA5"
  },
  {
    "uniprot": "A0A5K4ER54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER54"
  },
  {
    "uniprot": "A0A3Q0KCT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCT3"
  },
  {
    "uniprot": "A0A5K4EDJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDJ8"
  },
  {
    "uniprot": "A0A5K4F488",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F488"
  },
  {
    "uniprot": "A0A3Q0KS80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS80"
  },
  {
    "uniprot": "Q95WA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q95WA2"
  },
  {
    "uniprot": "A0A3Q0KGG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGG3"
  },
  {
    "uniprot": "A0A3Q0KL47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL47"
  },
  {
    "uniprot": "A0A5K4E9Q6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9Q6"
  },
  {
    "uniprot": "A0A5K4FF92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF92"
  },
  {
    "uniprot": "A0A5K4ELQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELQ8"
  },
  {
    "uniprot": "A0A5K4F747",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F747"
  },
  {
    "uniprot": "A0A5K4FET6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FET6"
  },
  {
    "uniprot": "A0A5K4F3L0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3L0"
  },
  {
    "uniprot": "A0A5K4F8S7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8S7"
  },
  {
    "uniprot": "A0A3Q0KGF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGF3"
  },
  {
    "uniprot": "A0A5K4FB47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB47"
  },
  {
    "uniprot": "A0A5K4ED68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED68"
  },
  {
    "uniprot": "G4LX39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX39"
  },
  {
    "uniprot": "A0A3Q0KG64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG64"
  },
  {
    "uniprot": "A0A5K4F2K2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2K2"
  },
  {
    "uniprot": "A0A5K4F948",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F948"
  },
  {
    "uniprot": "A0A3Q0KR33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR33"
  },
  {
    "uniprot": "A0A5K4EZG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZG3"
  },
  {
    "uniprot": "A0A5K4EWS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWS7"
  },
  {
    "uniprot": "G4VHS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHS7"
  },
  {
    "uniprot": "A0A5K4F0C9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0C9"
  },
  {
    "uniprot": "G4LUL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUL4"
  },
  {
    "uniprot": "A0A3Q0KJZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJZ9"
  },
  {
    "uniprot": "A0A5K4EU77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU77"
  },
  {
    "uniprot": "A0A5K4EVI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVI7"
  },
  {
    "uniprot": "A0A5K4FB29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB29"
  },
  {
    "uniprot": "A0A5K4FBJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBJ0"
  },
  {
    "uniprot": "A0A5K4F9H5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9H5"
  },
  {
    "uniprot": "A0A3Q0KKX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKX3"
  },
  {
    "uniprot": "A0A3Q0KNB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNB7"
  },
  {
    "uniprot": "A0A5K4F9L1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9L1"
  },
  {
    "uniprot": "A0A5K4FCH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCH1"
  },
  {
    "uniprot": "A0A5K4FBC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBC5"
  },
  {
    "uniprot": "A0A3Q0KSZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSZ2"
  },
  {
    "uniprot": "Q26604",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26604"
  },
  {
    "uniprot": "A0A5K4F530",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F530"
  },
  {
    "uniprot": "A0A5K4EZK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZK0"
  },
  {
    "uniprot": "G4VC26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC26"
  },
  {
    "uniprot": "G4V7S8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7S8"
  },
  {
    "uniprot": "A0A3Q0KL51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL51"
  },
  {
    "uniprot": "C4QMH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QMH8"
  },
  {
    "uniprot": "A0A5K4F317",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F317"
  },
  {
    "uniprot": "A0A5K4F5N8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5N8"
  },
  {
    "uniprot": "A0A5K4EWE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWE8"
  },
  {
    "uniprot": "A0A5K4FEW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEW8"
  },
  {
    "uniprot": "A0A5K4EZ28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ28"
  },
  {
    "uniprot": "A0A5K4F9S9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9S9"
  },
  {
    "uniprot": "A0A5K4F5R9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5R9"
  },
  {
    "uniprot": "A0A3Q0KP93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP93"
  },
  {
    "uniprot": "A0A5K4FBI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBI3"
  },
  {
    "uniprot": "G4M0M9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0M9"
  },
  {
    "uniprot": "A0A5K4EZ41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ41"
  },
  {
    "uniprot": "A0A5K4ERQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERQ9"
  },
  {
    "uniprot": "A0A5K4F2I0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2I0"
  },
  {
    "uniprot": "C4QQC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QQC2"
  },
  {
    "uniprot": "A0A3Q0KSY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSY4"
  },
  {
    "uniprot": "A0A3Q0KV80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV80"
  },
  {
    "uniprot": "A0A5K4FES8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FES8"
  },
  {
    "uniprot": "A0A5K4F3P1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3P1"
  },
  {
    "uniprot": "A0A5K4F4Q2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Q2"
  },
  {
    "uniprot": "A0A3Q0KRV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRV2"
  },
  {
    "uniprot": "A0A5K4F8C6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8C6"
  },
  {
    "uniprot": "A0A3Q0KNE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNE0"
  },
  {
    "uniprot": "G4VKW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKW3"
  },
  {
    "uniprot": "A0A3Q0KNG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNG9"
  },
  {
    "uniprot": "A0A5K4F238",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F238"
  },
  {
    "uniprot": "A0A5K4F3C4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3C4"
  },
  {
    "uniprot": "A0A3Q0KDK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDK1"
  },
  {
    "uniprot": "A0A5K4F5C5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5C5"
  },
  {
    "uniprot": "A0A5K4F1C0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1C0"
  },
  {
    "uniprot": "A0A5K4ECP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECP6"
  },
  {
    "uniprot": "A0A5K4F2R1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2R1"
  },
  {
    "uniprot": "A0A3Q0KM73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM73"
  },
  {
    "uniprot": "A0A3Q0KTC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTC8"
  },
  {
    "uniprot": "A0A3Q0KDV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDV4"
  },
  {
    "uniprot": "A0A3Q0KTZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTZ2"
  },
  {
    "uniprot": "G4VJB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJB4"
  },
  {
    "uniprot": "A0A5K4EHS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHS1"
  },
  {
    "uniprot": "A0A3Q0KEG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEG9"
  },
  {
    "uniprot": "A0A5K4EW51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW51"
  },
  {
    "uniprot": "A0A5K4F7E5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7E5"
  },
  {
    "uniprot": "A0A5K4F6V5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6V5"
  },
  {
    "uniprot": "A0A3Q0KN93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN93"
  },
  {
    "uniprot": "A0A5K4F8I8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8I8"
  },
  {
    "uniprot": "G4LXR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXR4"
  },
  {
    "uniprot": "A0A3Q0KSG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSG2"
  },
  {
    "uniprot": "A0A5K4F692",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F692"
  },
  {
    "uniprot": "A0A5K4FC45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC45"
  },
  {
    "uniprot": "A0A5K4FBN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBN6"
  },
  {
    "uniprot": "G4V611",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V611"
  },
  {
    "uniprot": "A0A5K4ETA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETA1"
  },
  {
    "uniprot": "G4V828",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V828"
  },
  {
    "uniprot": "A0A5K4F6A6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6A6"
  },
  {
    "uniprot": "A0A3Q0KEZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEZ1"
  },
  {
    "uniprot": "A0A5K4EH78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH78"
  },
  {
    "uniprot": "A0A5K4F888",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F888"
  },
  {
    "uniprot": "A0A5K4EK53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK53"
  },
  {
    "uniprot": "A0A5K4E8C6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8C6"
  },
  {
    "uniprot": "A0A3Q0KR34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR34"
  },
  {
    "uniprot": "A0A5K4F8L9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8L9"
  },
  {
    "uniprot": "A0A5K4FDP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDP1"
  },
  {
    "uniprot": "P09841",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P09841"
  },
  {
    "uniprot": "A0A5K4F828",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F828"
  },
  {
    "uniprot": "A0A3Q0KBE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBE9"
  },
  {
    "uniprot": "G4LYQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYQ9"
  },
  {
    "uniprot": "A0A3Q0KMA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMA1"
  },
  {
    "uniprot": "A0A5K4FCV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCV0"
  },
  {
    "uniprot": "A0A5K4F4F5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4F5"
  },
  {
    "uniprot": "A0A5K4FDJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDJ8"
  },
  {
    "uniprot": "A0A5K4ES39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES39"
  },
  {
    "uniprot": "A0A5K4EWZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWZ4"
  },
  {
    "uniprot": "A0A5K4ETU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETU8"
  },
  {
    "uniprot": "A0A5K4EM61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM61"
  },
  {
    "uniprot": "A0A5K4ERA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERA7"
  },
  {
    "uniprot": "A0A5K4FC94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC94"
  },
  {
    "uniprot": "A0A3Q0KBD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBD0"
  },
  {
    "uniprot": "A0A5K4F2F4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2F4"
  },
  {
    "uniprot": "C4PY91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4PY91"
  },
  {
    "uniprot": "A0A3Q0KSK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSK2"
  },
  {
    "uniprot": "A0A3Q0KKF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKF0"
  },
  {
    "uniprot": "A0A5K4FD37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD37"
  },
  {
    "uniprot": "A0A5K4FAS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAS9"
  },
  {
    "uniprot": "A0A3Q0KPV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPV7"
  },
  {
    "uniprot": "A0A5K4F3R1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3R1"
  },
  {
    "uniprot": "A0A5K4ER73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER73"
  },
  {
    "uniprot": "A0A5K4EXB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXB2"
  },
  {
    "uniprot": "A0A3Q0KI43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI43"
  },
  {
    "uniprot": "A0A3Q0KL82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL82"
  },
  {
    "uniprot": "A0A3Q0KGM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGM5"
  },
  {
    "uniprot": "A0A5K4F2J3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2J3"
  },
  {
    "uniprot": "A0A5K4EHN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHN3"
  },
  {
    "uniprot": "A0A3Q0KTQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTQ6"
  },
  {
    "uniprot": "A0A3Q0KGV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGV8"
  },
  {
    "uniprot": "A0A3Q0KNH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNH1"
  },
  {
    "uniprot": "A0A5K4EYR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYR1"
  },
  {
    "uniprot": "A0A3Q0KS81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS81"
  },
  {
    "uniprot": "G4M051",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M051"
  },
  {
    "uniprot": "G4M078",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M078"
  },
  {
    "uniprot": "A0A3Q0KGY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGY7"
  },
  {
    "uniprot": "A0A3Q0KPF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPF0"
  },
  {
    "uniprot": "A0A5K4EQH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQH3"
  },
  {
    "uniprot": "A0A5K4FBF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBF3"
  },
  {
    "uniprot": "G4LZJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZJ7"
  },
  {
    "uniprot": "A0A5K4EZB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZB8"
  },
  {
    "uniprot": "A0A3Q0KL27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL27"
  },
  {
    "uniprot": "A0A5K4EL22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL22"
  },
  {
    "uniprot": "A0A5K4EQ14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ14"
  },
  {
    "uniprot": "A0A5K4F1X4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1X4"
  },
  {
    "uniprot": "A0A5K4ECB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECB3"
  },
  {
    "uniprot": "A0A5K4EZY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZY3"
  },
  {
    "uniprot": "A0A3Q0KHA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHA0"
  },
  {
    "uniprot": "A0A5K4EUP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUP6"
  },
  {
    "uniprot": "A0A5K4F847",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F847"
  },
  {
    "uniprot": "A0A3Q0KBF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBF2"
  },
  {
    "uniprot": "G4LW08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LW08"
  },
  {
    "uniprot": "A0A5K4EIB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIB9"
  },
  {
    "uniprot": "A0A3Q0KUH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUH8"
  },
  {
    "uniprot": "A0A5K4F1S9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1S9"
  },
  {
    "uniprot": "A0A3Q0KGE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGE3"
  },
  {
    "uniprot": "G4VEN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEN2"
  },
  {
    "uniprot": "A0A5K4F667",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F667"
  },
  {
    "uniprot": "A0A5K4EXX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXX0"
  },
  {
    "uniprot": "A0A5K4F9W2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9W2"
  },
  {
    "uniprot": "A0A5K4F6C3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6C3"
  },
  {
    "uniprot": "A0A3Q0KD16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD16"
  },
  {
    "uniprot": "A0A3Q0KM75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM75"
  },
  {
    "uniprot": "A0A5K4FAF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAF6"
  },
  {
    "uniprot": "A0A3Q0KPA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPA5"
  },
  {
    "uniprot": "A0A5K4EYM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYM8"
  },
  {
    "uniprot": "A0A5K4FEA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEA9"
  },
  {
    "uniprot": "A0A3Q0KKG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKG8"
  },
  {
    "uniprot": "A0A5K4F5M6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5M6"
  },
  {
    "uniprot": "A0A5K4FGP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGP4"
  },
  {
    "uniprot": "A0A3Q0KLQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLQ8"
  },
  {
    "uniprot": "A0A3Q0KKM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKM2"
  },
  {
    "uniprot": "A0A5K4F3M7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3M7"
  },
  {
    "uniprot": "A0A5K4EUA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUA3"
  },
  {
    "uniprot": "A0A5K4FC78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC78"
  },
  {
    "uniprot": "G4LV29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV29"
  },
  {
    "uniprot": "G4VIC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIC8"
  },
  {
    "uniprot": "A0A3Q0KS77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS77"
  },
  {
    "uniprot": "A0A3Q0KU23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU23"
  },
  {
    "uniprot": "A0A3Q0KKL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKL7"
  },
  {
    "uniprot": "A0A5K4EXT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXT8"
  },
  {
    "uniprot": "A0A5K4FBL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBL6"
  },
  {
    "uniprot": "C4QPU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QPU3"
  },
  {
    "uniprot": "G4VAD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAD4"
  },
  {
    "uniprot": "A0A3Q0KEH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEH4"
  },
  {
    "uniprot": "A0A3Q0KI36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI36"
  },
  {
    "uniprot": "A0A5K4F4I6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4I6"
  },
  {
    "uniprot": "G4VAT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAT2"
  },
  {
    "uniprot": "A0A5K4EHR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHR8"
  },
  {
    "uniprot": "A0A3Q0KR44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR44"
  },
  {
    "uniprot": "A0A3Q0KJI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJI4"
  },
  {
    "uniprot": "A0A3Q0KIS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIS8"
  },
  {
    "uniprot": "G4VSS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSS2"
  },
  {
    "uniprot": "A0A3Q0KMB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMB1"
  },
  {
    "uniprot": "G4LZ90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ90"
  },
  {
    "uniprot": "A0A5K4ELW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELW7"
  },
  {
    "uniprot": "G4V9X4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9X4"
  },
  {
    "uniprot": "G4VT90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT90"
  },
  {
    "uniprot": "G4VPK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPK2"
  },
  {
    "uniprot": "A0A3Q0KT62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT62"
  },
  {
    "uniprot": "A0A5K4EBR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBR4"
  },
  {
    "uniprot": "A0A5K4EPS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPS0"
  },
  {
    "uniprot": "G4VB63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB63"
  },
  {
    "uniprot": "A0A3Q0KER4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KER4"
  },
  {
    "uniprot": "A0A3Q0KQL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQL6"
  },
  {
    "uniprot": "A0A5K4EQI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQI1"
  },
  {
    "uniprot": "G4M0F4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0F4"
  },
  {
    "uniprot": "A0A3Q0KKB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKB1"
  },
  {
    "uniprot": "G4VFQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFQ1"
  },
  {
    "uniprot": "A0A3Q0KPU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPU5"
  },
  {
    "uniprot": "A0A5K4F1D5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1D5"
  },
  {
    "uniprot": "A0A5K4F7V3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7V3"
  },
  {
    "uniprot": "A0A3Q0KKC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKC0"
  },
  {
    "uniprot": "A0A3Q0KRG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRG6"
  },
  {
    "uniprot": "A0A3Q0KP07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP07"
  },
  {
    "uniprot": "A0A5K4FGR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGR5"
  },
  {
    "uniprot": "A0A5K4FCI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCI2"
  },
  {
    "uniprot": "A0A5K4EZD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZD4"
  },
  {
    "uniprot": "A0A5K4F3B7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3B7"
  },
  {
    "uniprot": "A0A3Q0KR43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR43"
  },
  {
    "uniprot": "G4VAV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAV4"
  },
  {
    "uniprot": "G4VJK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJK7"
  },
  {
    "uniprot": "A0A5K4FBP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBP7"
  },
  {
    "uniprot": "A0A5K4F5L6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5L6"
  },
  {
    "uniprot": "A0A5K4EL73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL73"
  },
  {
    "uniprot": "A0A3Q0KTM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTM5"
  },
  {
    "uniprot": "G4V6P0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6P0"
  },
  {
    "uniprot": "A0A3Q0KF84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF84"
  },
  {
    "uniprot": "A0A5K4F880",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F880"
  },
  {
    "uniprot": "A0A3Q0KQY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQY4"
  },
  {
    "uniprot": "A0A3Q0KNX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNX3"
  },
  {
    "uniprot": "A0A5K4F401",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F401"
  },
  {
    "uniprot": "A0A5K4EEV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEV0"
  },
  {
    "uniprot": "A0A5K4EM88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM88"
  },
  {
    "uniprot": "G4M1K6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1K6"
  },
  {
    "uniprot": "G4LUA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUA4"
  },
  {
    "uniprot": "G4M0G9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0G9"
  },
  {
    "uniprot": "G4VQX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQX3"
  },
  {
    "uniprot": "A0A3Q0KIF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIF4"
  },
  {
    "uniprot": "A0A5K4ETB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETB8"
  },
  {
    "uniprot": "A0A5K4F1M1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1M1"
  },
  {
    "uniprot": "A0A5K4F620",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F620"
  },
  {
    "uniprot": "A0A3Q0KT07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT07"
  },
  {
    "uniprot": "A0A3Q0KM12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM12"
  },
  {
    "uniprot": "A0A5K4ERV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERV0"
  },
  {
    "uniprot": "A0A5K4FBB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBB2"
  },
  {
    "uniprot": "A0A3Q0KM18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM18"
  },
  {
    "uniprot": "A0A5K4F8Q7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Q7"
  },
  {
    "uniprot": "A0A3Q0KH24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH24"
  },
  {
    "uniprot": "A0A5K4EBZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBZ6"
  },
  {
    "uniprot": "A0A5K4F3S1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3S1"
  },
  {
    "uniprot": "A0A5K4FE07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE07"
  },
  {
    "uniprot": "G4M1T7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1T7"
  },
  {
    "uniprot": "A0A5K4EQL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQL1"
  },
  {
    "uniprot": "G4V6U5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6U5"
  },
  {
    "uniprot": "A0A5K4EZL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZL3"
  },
  {
    "uniprot": "A0A5K4F9E9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9E9"
  },
  {
    "uniprot": "A0A3Q0KMP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMP2"
  },
  {
    "uniprot": "A0A3Q0KF67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF67"
  },
  {
    "uniprot": "A0A5K4EC43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EC43"
  },
  {
    "uniprot": "A0A3Q0KL58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL58"
  },
  {
    "uniprot": "G4VRU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRU9"
  },
  {
    "uniprot": "G4VND4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VND4"
  },
  {
    "uniprot": "G4V8N4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8N4"
  },
  {
    "uniprot": "A0A5K4EL91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL91"
  },
  {
    "uniprot": "A0A5K4F9Y2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Y2"
  },
  {
    "uniprot": "G4VI72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI72"
  },
  {
    "uniprot": "A0A5K4FB78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB78"
  },
  {
    "uniprot": "A0A5K4ECE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECE7"
  },
  {
    "uniprot": "A0A5K4F5X7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5X7"
  },
  {
    "uniprot": "A0A5K4F150",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F150"
  },
  {
    "uniprot": "A0A3Q0KJP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJP0"
  },
  {
    "uniprot": "A0A3Q0KGC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGC7"
  },
  {
    "uniprot": "A0A5K4FFZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFZ8"
  },
  {
    "uniprot": "A0A3Q0KED0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KED0"
  },
  {
    "uniprot": "A0A5K4EZB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZB9"
  },
  {
    "uniprot": "A0A5K4FE79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE79"
  },
  {
    "uniprot": "A0A5K4F3L6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3L6"
  },
  {
    "uniprot": "A0A5K4FDB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDB3"
  },
  {
    "uniprot": "A0A5K4FDR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDR6"
  },
  {
    "uniprot": "G4VBB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBB5"
  },
  {
    "uniprot": "A0A3Q0KJF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJF5"
  },
  {
    "uniprot": "G4VIZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIZ9"
  },
  {
    "uniprot": "A0A5K4FG94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FG94"
  },
  {
    "uniprot": "A0A5K4F3D6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3D6"
  },
  {
    "uniprot": "A0A3Q0KE12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE12"
  },
  {
    "uniprot": "A0A5K4F2A4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2A4"
  },
  {
    "uniprot": "A0A5K4FA53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA53"
  },
  {
    "uniprot": "G4VA83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA83"
  },
  {
    "uniprot": "A0A5K4F5K4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5K4"
  },
  {
    "uniprot": "A0A3Q0KUA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUA7"
  },
  {
    "uniprot": "A0A3Q0KNW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNW2"
  },
  {
    "uniprot": "A0A5K4FEE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEE8"
  },
  {
    "uniprot": "A0A5K4ETF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETF8"
  },
  {
    "uniprot": "A0A5K4F2C4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2C4"
  },
  {
    "uniprot": "A0A5K4EP71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP71"
  },
  {
    "uniprot": "G4M074",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M074"
  },
  {
    "uniprot": "A0A5K4F8I3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8I3"
  },
  {
    "uniprot": "A0A3Q0KVF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVF8"
  },
  {
    "uniprot": "G4V639",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V639"
  },
  {
    "uniprot": "A0A3Q0KB94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KB94"
  },
  {
    "uniprot": "A0A5K4F957",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F957"
  },
  {
    "uniprot": "A0A3Q0KEB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEB3"
  },
  {
    "uniprot": "A0A5K4ESZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESZ1"
  },
  {
    "uniprot": "A0A5K4EKY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKY9"
  },
  {
    "uniprot": "A0A5K4F8W0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8W0"
  },
  {
    "uniprot": "G4V6A5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6A5"
  },
  {
    "uniprot": "A0A5K4F3P8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3P8"
  },
  {
    "uniprot": "A0A5K4EI30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EI30"
  },
  {
    "uniprot": "A0A3Q0KKQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKQ0"
  },
  {
    "uniprot": "A0A3Q0KJL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJL2"
  },
  {
    "uniprot": "A0A5K4FAW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAW1"
  },
  {
    "uniprot": "A0A5K4ETF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETF1"
  },
  {
    "uniprot": "A0A5K4FA58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA58"
  },
  {
    "uniprot": "G4V7E0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7E0"
  },
  {
    "uniprot": "A0A5K4FBM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBM4"
  },
  {
    "uniprot": "G4V7E4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7E4"
  },
  {
    "uniprot": "A0A5K4F221",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F221"
  },
  {
    "uniprot": "A0A5K4F6H1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6H1"
  },
  {
    "uniprot": "A0A5K4EK33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK33"
  },
  {
    "uniprot": "A0A5K4FE76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE76"
  },
  {
    "uniprot": "A0A5K4F3I9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3I9"
  },
  {
    "uniprot": "A0A3Q0KCI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCI4"
  },
  {
    "uniprot": "A0A5K4F333",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F333"
  },
  {
    "uniprot": "A0A3Q0KSU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSU8"
  },
  {
    "uniprot": "A0A5K4F2T1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2T1"
  },
  {
    "uniprot": "G4VS13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS13"
  },
  {
    "uniprot": "C1M100",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C1M100"
  },
  {
    "uniprot": "A0A3Q0KEC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEC7"
  },
  {
    "uniprot": "A0A3Q0KLP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLP5"
  },
  {
    "uniprot": "A0A5K4EIX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIX0"
  },
  {
    "uniprot": "A0A5K4EEF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEF5"
  },
  {
    "uniprot": "A0A3Q0KLH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLH6"
  },
  {
    "uniprot": "A0A5K4FCC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCC0"
  },
  {
    "uniprot": "A0A5K4FCW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCW7"
  },
  {
    "uniprot": "A0A5K4F4F3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4F3"
  },
  {
    "uniprot": "A0A5K4FDI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDI4"
  },
  {
    "uniprot": "A0A5K4EZC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZC9"
  },
  {
    "uniprot": "A0A3Q0KJT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJT5"
  },
  {
    "uniprot": "A0A3Q0KHQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHQ3"
  },
  {
    "uniprot": "A0A3Q0KKD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKD2"
  },
  {
    "uniprot": "A0A3Q0KRV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRV8"
  },
  {
    "uniprot": "A0A3Q0KT77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT77"
  },
  {
    "uniprot": "A0A5K4F7W9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7W9"
  },
  {
    "uniprot": "A0A5K4EXN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXN9"
  },
  {
    "uniprot": "A0A5K4F3I1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3I1"
  },
  {
    "uniprot": "A0A3Q0KTX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTX3"
  },
  {
    "uniprot": "A0A5K4F158",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F158"
  },
  {
    "uniprot": "A0A3Q0KFM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFM9"
  },
  {
    "uniprot": "A0A5K4F9P8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9P8"
  },
  {
    "uniprot": "G4LZZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZZ9"
  },
  {
    "uniprot": "G4M1N6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1N6"
  },
  {
    "uniprot": "G4VME8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VME8"
  },
  {
    "uniprot": "A0A5K4F4E7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4E7"
  },
  {
    "uniprot": "A0A3Q0KHD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHD4"
  },
  {
    "uniprot": "A0A5K4FCN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCN3"
  },
  {
    "uniprot": "G4VJY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJY0"
  },
  {
    "uniprot": "A0A5K4F3T2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3T2"
  },
  {
    "uniprot": "G4LV38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV38"
  },
  {
    "uniprot": "A0A5K4FBK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBK5"
  },
  {
    "uniprot": "A0A5K4F5X2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5X2"
  },
  {
    "uniprot": "A0A3Q0KT01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT01"
  },
  {
    "uniprot": "A0A3Q0KUL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUL2"
  },
  {
    "uniprot": "A0A5K4EB50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB50"
  },
  {
    "uniprot": "A0A5K4FEA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEA2"
  },
  {
    "uniprot": "A0A3Q0KU71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU71"
  },
  {
    "uniprot": "A0A3Q0KVA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVA8"
  },
  {
    "uniprot": "A0A5K4EFP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFP2"
  },
  {
    "uniprot": "G4VTG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTG9"
  },
  {
    "uniprot": "G4VE67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VE67"
  },
  {
    "uniprot": "A0A3Q0KJS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJS6"
  },
  {
    "uniprot": "A0A3Q0KRI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRI4"
  },
  {
    "uniprot": "A0A5K4EPT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPT0"
  },
  {
    "uniprot": "G4VH66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH66"
  },
  {
    "uniprot": "A0A5K4F5P9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5P9"
  },
  {
    "uniprot": "A0A5K4F5Q1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Q1"
  },
  {
    "uniprot": "A0A5K4F657",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F657"
  },
  {
    "uniprot": "A0A5K4F0P1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0P1"
  },
  {
    "uniprot": "A0A5K4F9Z6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Z6"
  },
  {
    "uniprot": "A0A5K4FGN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGN3"
  },
  {
    "uniprot": "A0A5K4EQ47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ47"
  },
  {
    "uniprot": "A0A5K4F8B4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8B4"
  },
  {
    "uniprot": "A0A3Q0KEX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEX4"
  },
  {
    "uniprot": "A0A5K4EYG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYG1"
  },
  {
    "uniprot": "G4VL32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VL32"
  },
  {
    "uniprot": "A0A3Q0KLC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLC9"
  },
  {
    "uniprot": "A0A3Q0KLM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLM8"
  },
  {
    "uniprot": "C4QKW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QKW6"
  },
  {
    "uniprot": "A0A3Q0KL83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL83"
  },
  {
    "uniprot": "A0A5K4F4L9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4L9"
  },
  {
    "uniprot": "G4VB75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB75"
  },
  {
    "uniprot": "A0A3Q0KQQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQQ5"
  },
  {
    "uniprot": "A0A3Q0KBC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBC0"
  },
  {
    "uniprot": "A0A5K4ESP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESP0"
  },
  {
    "uniprot": "G4LUC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUC6"
  },
  {
    "uniprot": "G4VNQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNQ8"
  },
  {
    "uniprot": "A0A5K4FE50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE50"
  },
  {
    "uniprot": "A0A5K4FDN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDN2"
  },
  {
    "uniprot": "A0A3Q0KK33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK33"
  },
  {
    "uniprot": "G4VS89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS89"
  },
  {
    "uniprot": "A0A5K4EI14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EI14"
  },
  {
    "uniprot": "A0A5K4ERQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERQ5"
  },
  {
    "uniprot": "G4V6Z2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6Z2"
  },
  {
    "uniprot": "A0A5K4EB17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB17"
  },
  {
    "uniprot": "A0A5K4F7M5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7M5"
  },
  {
    "uniprot": "A0A3Q0KI04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI04"
  },
  {
    "uniprot": "A0A5K4FEP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEP7"
  },
  {
    "uniprot": "A0A3Q0KD14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD14"
  },
  {
    "uniprot": "A0A5K4EQ43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ43"
  },
  {
    "uniprot": "A0A3Q0KKE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKE9"
  },
  {
    "uniprot": "A0A3Q0KVB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVB5"
  },
  {
    "uniprot": "A0A5K4ESG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESG7"
  },
  {
    "uniprot": "A0A5K4FAN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAN7"
  },
  {
    "uniprot": "A0A3Q0KKK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKK3"
  },
  {
    "uniprot": "A0A3Q0KLM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLM0"
  },
  {
    "uniprot": "G4VJK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJK3"
  },
  {
    "uniprot": "A0A5K4F5U3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5U3"
  },
  {
    "uniprot": "A0A3Q0KN73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN73"
  },
  {
    "uniprot": "A0A3Q0KQ83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ83"
  },
  {
    "uniprot": "A0A3Q0KIP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIP1"
  },
  {
    "uniprot": "G4V7X1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7X1"
  },
  {
    "uniprot": "A0A5K4EKN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKN2"
  },
  {
    "uniprot": "A0A3Q0KG58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG58"
  },
  {
    "uniprot": "A0A5K4F5R0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5R0"
  },
  {
    "uniprot": "G4LZZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZZ3"
  },
  {
    "uniprot": "A0A5K4F8Z3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Z3"
  },
  {
    "uniprot": "A0A5K4FCF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCF6"
  },
  {
    "uniprot": "A0A3Q0KGB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGB2"
  },
  {
    "uniprot": "G4VRS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRS2"
  },
  {
    "uniprot": "A0A5K4EZ09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ09"
  },
  {
    "uniprot": "A0A3Q0KF38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF38"
  },
  {
    "uniprot": "A0A5K4F7S9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7S9"
  },
  {
    "uniprot": "A0A5K4F2J0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2J0"
  },
  {
    "uniprot": "G4LUR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUR0"
  },
  {
    "uniprot": "A0A5K4EWX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWX0"
  },
  {
    "uniprot": "A0A5K4EF21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EF21"
  },
  {
    "uniprot": "A0A5K4EQN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQN0"
  },
  {
    "uniprot": "G4VFQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFQ0"
  },
  {
    "uniprot": "A0A5K4FCN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCN5"
  },
  {
    "uniprot": "A0A5K4F4R0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4R0"
  },
  {
    "uniprot": "A0A3Q0KL26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL26"
  },
  {
    "uniprot": "A0A5K4EU11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU11"
  },
  {
    "uniprot": "A0A5K4FA24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA24"
  },
  {
    "uniprot": "A0A3Q0KKN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKN9"
  },
  {
    "uniprot": "A0A5K4F965",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F965"
  },
  {
    "uniprot": "A0A3Q0KP14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP14"
  },
  {
    "uniprot": "A0A5K4F8W2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8W2"
  },
  {
    "uniprot": "A0A5K4F860",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F860"
  },
  {
    "uniprot": "A0A5K4F1I5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1I5"
  },
  {
    "uniprot": "A0A5K4F8M5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8M5"
  },
  {
    "uniprot": "A0A5K4F1T6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1T6"
  },
  {
    "uniprot": "A0A3Q0KHH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHH6"
  },
  {
    "uniprot": "A0A5K4ELH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELH4"
  },
  {
    "uniprot": "A0A5K4EMH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMH1"
  },
  {
    "uniprot": "A0A3Q0KNJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNJ2"
  },
  {
    "uniprot": "A0A3Q0KKL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKL2"
  },
  {
    "uniprot": "A0A5K4FCG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCG8"
  },
  {
    "uniprot": "A0A3Q0KGB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGB4"
  },
  {
    "uniprot": "C4Q2I6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q2I6"
  },
  {
    "uniprot": "A0A5K4EFX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFX9"
  },
  {
    "uniprot": "A0A5K4EDV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDV7"
  },
  {
    "uniprot": "A0A5K4FAK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAK9"
  },
  {
    "uniprot": "A0A3Q0KC15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC15"
  },
  {
    "uniprot": "A0A5K4F643",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F643"
  },
  {
    "uniprot": "A0A5K4F4D3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4D3"
  },
  {
    "uniprot": "A0A5K4EPZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPZ6"
  },
  {
    "uniprot": "A0A5K4FCF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCF3"
  },
  {
    "uniprot": "A0A3Q0KJS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJS5"
  },
  {
    "uniprot": "A0A5K4F9S2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9S2"
  },
  {
    "uniprot": "A0A5K4FC42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC42"
  },
  {
    "uniprot": "G4VJ86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ86"
  },
  {
    "uniprot": "G4VBG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBG7"
  },
  {
    "uniprot": "A0A3Q0KK44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK44"
  },
  {
    "uniprot": "A0A5K4EBY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBY6"
  },
  {
    "uniprot": "G4VS32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS32"
  },
  {
    "uniprot": "A0A3Q0KUL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUL5"
  },
  {
    "uniprot": "A0A5K4F465",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F465"
  },
  {
    "uniprot": "A0A3Q0KDW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDW8"
  },
  {
    "uniprot": "A0A3Q0KTV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTV5"
  },
  {
    "uniprot": "A0A3Q0KP90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP90"
  },
  {
    "uniprot": "A0A5K4EET5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EET5"
  },
  {
    "uniprot": "A0A3Q0KKV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKV0"
  },
  {
    "uniprot": "A0A3Q0KF49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF49"
  },
  {
    "uniprot": "A0A5K4ENZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENZ2"
  },
  {
    "uniprot": "A0A3Q0KBA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBA7"
  },
  {
    "uniprot": "G4VIU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIU4"
  },
  {
    "uniprot": "A0A5K4F8U1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8U1"
  },
  {
    "uniprot": "A0A3Q0KIZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIZ3"
  },
  {
    "uniprot": "G4VGN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGN3"
  },
  {
    "uniprot": "A0A3Q0KQN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQN4"
  },
  {
    "uniprot": "A0A3Q0KP38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP38"
  },
  {
    "uniprot": "A0A3Q0KKP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKP2"
  },
  {
    "uniprot": "A0A5K4F283",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F283"
  },
  {
    "uniprot": "A0A5K4F2T3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2T3"
  },
  {
    "uniprot": "Q86D79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q86D79"
  },
  {
    "uniprot": "A0A5K4EME0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EME0"
  },
  {
    "uniprot": "A0A5K4EKG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKG1"
  },
  {
    "uniprot": "A0A3Q0KUD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUD7"
  },
  {
    "uniprot": "A0A5K4F145",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F145"
  },
  {
    "uniprot": "A0A5K4ET09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET09"
  },
  {
    "uniprot": "A0A3Q0KBJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBJ1"
  },
  {
    "uniprot": "G4VLX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLX1"
  },
  {
    "uniprot": "A0A5K4F228",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F228"
  },
  {
    "uniprot": "A0A3Q0KMM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMM5"
  },
  {
    "uniprot": "A0A3Q0KEK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEK5"
  },
  {
    "uniprot": "A0A5K4EN02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN02"
  },
  {
    "uniprot": "A0A5K4F459",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F459"
  },
  {
    "uniprot": "A0A5K4FB55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB55"
  },
  {
    "uniprot": "A0A5K4ERG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERG4"
  },
  {
    "uniprot": "A0A3Q0KM87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM87"
  },
  {
    "uniprot": "A0A5K4EVV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVV5"
  },
  {
    "uniprot": "G4LWN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWN4"
  },
  {
    "uniprot": "A0A5K4FBA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBA8"
  },
  {
    "uniprot": "A0A3Q0KRT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRT4"
  },
  {
    "uniprot": "A0A5K4F0M6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0M6"
  },
  {
    "uniprot": "A0A5K4F328",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F328"
  },
  {
    "uniprot": "G4V864",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V864"
  },
  {
    "uniprot": "G4VGT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGT2"
  },
  {
    "uniprot": "A0A3Q0KDJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDJ7"
  },
  {
    "uniprot": "C4QM85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QM85"
  },
  {
    "uniprot": "A0A5K4F8G2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8G2"
  },
  {
    "uniprot": "A0A3Q0KJL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJL7"
  },
  {
    "uniprot": "A0A5K4EKI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKI7"
  },
  {
    "uniprot": "A0A3Q0KJY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJY5"
  },
  {
    "uniprot": "G4LVM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVM9"
  },
  {
    "uniprot": "A0A3Q0KIW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIW1"
  },
  {
    "uniprot": "A0A3Q0KG63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG63"
  },
  {
    "uniprot": "A0A5K4F6Q1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Q1"
  },
  {
    "uniprot": "A0A5K4F4N9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4N9"
  },
  {
    "uniprot": "A0A5K4F234",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F234"
  },
  {
    "uniprot": "G4V7V8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7V8"
  },
  {
    "uniprot": "A0A5K4EP53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP53"
  },
  {
    "uniprot": "A0A3Q0KSG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSG4"
  },
  {
    "uniprot": "A0A3Q0KM25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM25"
  },
  {
    "uniprot": "A0A5K4ENX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENX3"
  },
  {
    "uniprot": "Q15ER7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q15ER7"
  },
  {
    "uniprot": "A0A5K4EJ27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJ27"
  },
  {
    "uniprot": "A0A5K4F9I5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9I5"
  },
  {
    "uniprot": "A0A3Q0KN25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN25"
  },
  {
    "uniprot": "A0A5K4F132",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F132"
  },
  {
    "uniprot": "G4VFK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFK8"
  },
  {
    "uniprot": "G4V7D2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7D2"
  },
  {
    "uniprot": "A0A5K4F9R0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9R0"
  },
  {
    "uniprot": "A0A5K4EFF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFF7"
  },
  {
    "uniprot": "A0A5K4FH32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FH32"
  },
  {
    "uniprot": "A0A3Q0KSL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSL8"
  },
  {
    "uniprot": "A0A3Q0KQR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQR5"
  },
  {
    "uniprot": "G4LVA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVA3"
  },
  {
    "uniprot": "G4VML9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VML9"
  },
  {
    "uniprot": "A0A5K4F5L1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5L1"
  },
  {
    "uniprot": "A0A5K4FA50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA50"
  },
  {
    "uniprot": "A0A3Q0KNE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNE9"
  },
  {
    "uniprot": "A0A5K4F8F0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8F0"
  },
  {
    "uniprot": "A0A5K4F1S8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1S8"
  },
  {
    "uniprot": "G4VPH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPH0"
  },
  {
    "uniprot": "A0A5K4F2A6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2A6"
  },
  {
    "uniprot": "A0A5K4ETT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETT4"
  },
  {
    "uniprot": "A0A5K4FC61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC61"
  },
  {
    "uniprot": "G4VMI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMI7"
  },
  {
    "uniprot": "A0A5K4F1X5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1X5"
  },
  {
    "uniprot": "A0A3Q0KBH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBH5"
  },
  {
    "uniprot": "A0A5K4F5T6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5T6"
  },
  {
    "uniprot": "A0A5K4F0M7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0M7"
  },
  {
    "uniprot": "A0A5K4FCJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCJ6"
  },
  {
    "uniprot": "A0A5K4F3W2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3W2"
  },
  {
    "uniprot": "A0A5K4FA32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA32"
  },
  {
    "uniprot": "A0A5K4EGT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGT6"
  },
  {
    "uniprot": "A0A5K4F351",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F351"
  },
  {
    "uniprot": "A0A5K4EXC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXC6"
  },
  {
    "uniprot": "G4VTX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTX6"
  },
  {
    "uniprot": "A0A5K4EMR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMR6"
  },
  {
    "uniprot": "G4LWY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWY9"
  },
  {
    "uniprot": "A0A5K4F3Z9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Z9"
  },
  {
    "uniprot": "G4LVL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVL0"
  },
  {
    "uniprot": "A0A5K4EKY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKY6"
  },
  {
    "uniprot": "A0A5K4FA96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA96"
  },
  {
    "uniprot": "A0A3Q0KBX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBX5"
  },
  {
    "uniprot": "A0A5K4EQY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQY8"
  },
  {
    "uniprot": "A0A3Q0KMM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMM0"
  },
  {
    "uniprot": "A0A5K4EL18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL18"
  },
  {
    "uniprot": "A0A3Q0KN08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN08"
  },
  {
    "uniprot": "A0A3Q0KRE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRE4"
  },
  {
    "uniprot": "A0A3Q0KS32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS32"
  },
  {
    "uniprot": "A0A3Q0KSC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSC5"
  },
  {
    "uniprot": "G4LY81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY81"
  },
  {
    "uniprot": "A0A5K4FAQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAQ5"
  },
  {
    "uniprot": "A0A3Q0KKV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKV4"
  },
  {
    "uniprot": "A0A3Q0KQ75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ75"
  },
  {
    "uniprot": "A0A3Q0KBC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBC6"
  },
  {
    "uniprot": "A0A5K4F5B9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5B9"
  },
  {
    "uniprot": "G4VPW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPW5"
  },
  {
    "uniprot": "A0A5K4FC34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC34"
  },
  {
    "uniprot": "A0A5K4FCA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCA5"
  },
  {
    "uniprot": "A0A5K4F566",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F566"
  },
  {
    "uniprot": "A0A5K4F602",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F602"
  },
  {
    "uniprot": "A0A3Q0KPI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPI3"
  },
  {
    "uniprot": "G4VFN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFN4"
  },
  {
    "uniprot": "A0A5K4E8Z4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8Z4"
  },
  {
    "uniprot": "A0A3Q0KC55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC55"
  },
  {
    "uniprot": "A0A3Q0KDM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDM8"
  },
  {
    "uniprot": "A0A3Q0KH22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH22"
  },
  {
    "uniprot": "A0A5K4F3R6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3R6"
  },
  {
    "uniprot": "G4LZE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZE2"
  },
  {
    "uniprot": "A0A5K4EC30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EC30"
  },
  {
    "uniprot": "A0A3Q0KJA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJA5"
  },
  {
    "uniprot": "A0A5K4F2P6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2P6"
  },
  {
    "uniprot": "A0A5K4FD75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD75"
  },
  {
    "uniprot": "A0A5K4FBU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBU9"
  },
  {
    "uniprot": "A0A5K4EZE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZE5"
  },
  {
    "uniprot": "A0A3Q0KKV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKV9"
  },
  {
    "uniprot": "A0A5K4F6H5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6H5"
  },
  {
    "uniprot": "A0A5K4F9L8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9L8"
  },
  {
    "uniprot": "A0A5K4FEB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEB5"
  },
  {
    "uniprot": "A0A5K4F7W4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7W4"
  },
  {
    "uniprot": "A0A5K4EEI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEI4"
  },
  {
    "uniprot": "A0A5K4F5W4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5W4"
  },
  {
    "uniprot": "A0A3Q0KU09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU09"
  },
  {
    "uniprot": "A0A5K4FDU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDU0"
  },
  {
    "uniprot": "A0A5K4E7M6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E7M6"
  },
  {
    "uniprot": "A0A5K4F4H7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4H7"
  },
  {
    "uniprot": "A0A146MGS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MGS0"
  },
  {
    "uniprot": "A0A5K4F452",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F452"
  },
  {
    "uniprot": "A0A3Q0KTJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTJ0"
  },
  {
    "uniprot": "A0A3Q0KUJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUJ7"
  },
  {
    "uniprot": "A0A5K4F4Y7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Y7"
  },
  {
    "uniprot": "A0A5K4F941",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F941"
  },
  {
    "uniprot": "A0A3Q0KMD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMD7"
  },
  {
    "uniprot": "A0A5K4FF74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF74"
  },
  {
    "uniprot": "A0A5K4F5T4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5T4"
  },
  {
    "uniprot": "G4LWM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWM6"
  },
  {
    "uniprot": "G4LYW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYW2"
  },
  {
    "uniprot": "A0A5K4F7A2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7A2"
  },
  {
    "uniprot": "A0A5K4F8Z9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Z9"
  },
  {
    "uniprot": "G4VQ51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ51"
  },
  {
    "uniprot": "A0A5K4F5Q3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Q3"
  },
  {
    "uniprot": "A0A5K4EM87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM87"
  },
  {
    "uniprot": "A0A5K4FEH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEH6"
  },
  {
    "uniprot": "A0A5K4EBI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBI6"
  },
  {
    "uniprot": "A0A5K4F5G7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5G7"
  },
  {
    "uniprot": "G4VGQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGQ3"
  },
  {
    "uniprot": "A0A3Q0KVE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVE3"
  },
  {
    "uniprot": "A0A5K4F797",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F797"
  },
  {
    "uniprot": "A0A3Q0KBF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBF4"
  },
  {
    "uniprot": "P16641",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P16641"
  },
  {
    "uniprot": "G4LZ76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ76"
  },
  {
    "uniprot": "A0A3Q0KMB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMB4"
  },
  {
    "uniprot": "A0A5K4EWB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWB0"
  },
  {
    "uniprot": "A0A3Q0KNJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNJ3"
  },
  {
    "uniprot": "A0A3Q0KCP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCP3"
  },
  {
    "uniprot": "A0A5K4F7T5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7T5"
  },
  {
    "uniprot": "A0A5K4F861",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F861"
  },
  {
    "uniprot": "G4V6D6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6D6"
  },
  {
    "uniprot": "A0A3Q0KG80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG80"
  },
  {
    "uniprot": "A0A3Q0KML4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KML4"
  },
  {
    "uniprot": "A0A5K4F2T9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2T9"
  },
  {
    "uniprot": "A0A5K4F7E2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7E2"
  },
  {
    "uniprot": "A0A3Q0KMN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMN9"
  },
  {
    "uniprot": "A0A5K4F5L2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5L2"
  },
  {
    "uniprot": "A0A5K4EH42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH42"
  },
  {
    "uniprot": "G4VNZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNZ6"
  },
  {
    "uniprot": "A0A3Q0KES4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KES4"
  },
  {
    "uniprot": "A0A3Q0KUW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUW1"
  },
  {
    "uniprot": "A0A5K4FDQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDQ6"
  },
  {
    "uniprot": "A0A5K4ESZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESZ8"
  },
  {
    "uniprot": "A0A3Q0KFN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFN3"
  },
  {
    "uniprot": "G4VLY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLY9"
  },
  {
    "uniprot": "G4V7J4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7J4"
  },
  {
    "uniprot": "G4VPD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPD9"
  },
  {
    "uniprot": "A0A3Q0KHZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHZ5"
  },
  {
    "uniprot": "G4VBA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBA0"
  },
  {
    "uniprot": "A0A5K4FFD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFD1"
  },
  {
    "uniprot": "A0A5K4F885",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F885"
  },
  {
    "uniprot": "A0A5K4F7V6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7V6"
  },
  {
    "uniprot": "C4QP07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QP07"
  },
  {
    "uniprot": "A0A5K4FAL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAL5"
  },
  {
    "uniprot": "G4V7K2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7K2"
  },
  {
    "uniprot": "A0A3Q0KDE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDE5"
  },
  {
    "uniprot": "A0A3Q0KKA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKA9"
  },
  {
    "uniprot": "A0A3Q0KEJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEJ1"
  },
  {
    "uniprot": "A0A5K4FD78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD78"
  },
  {
    "uniprot": "A0A3Q0KFH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFH3"
  },
  {
    "uniprot": "A0A3Q0KCV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCV8"
  },
  {
    "uniprot": "P13396",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P13396"
  },
  {
    "uniprot": "A0A3Q0KCC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCC5"
  },
  {
    "uniprot": "G4VHK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHK8"
  },
  {
    "uniprot": "A0A5K4F5U4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5U4"
  },
  {
    "uniprot": "G4VM11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM11"
  },
  {
    "uniprot": "A0A3Q0KGS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGS1"
  },
  {
    "uniprot": "A0A3Q0KQR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQR0"
  },
  {
    "uniprot": "A0A3Q0KDR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDR5"
  },
  {
    "uniprot": "A0A5K4FAB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAB6"
  },
  {
    "uniprot": "A0A3Q0KGY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGY9"
  },
  {
    "uniprot": "A0A3Q0KNE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNE4"
  },
  {
    "uniprot": "A0A3Q0KDC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDC5"
  },
  {
    "uniprot": "A0A5K4FD36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD36"
  },
  {
    "uniprot": "A0A5K4FBW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBW8"
  },
  {
    "uniprot": "D7PD52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/D7PD52"
  },
  {
    "uniprot": "A0A3Q0KLU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLU4"
  },
  {
    "uniprot": "A0A3Q0KM61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM61"
  },
  {
    "uniprot": "G4VSZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSZ6"
  },
  {
    "uniprot": "A0A5K4F7Z1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Z1"
  },
  {
    "uniprot": "A0A3Q0KBF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBF0"
  },
  {
    "uniprot": "A0A5K4F662",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F662"
  },
  {
    "uniprot": "A0A5K4EQ53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ53"
  },
  {
    "uniprot": "A0A5K4E8T7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8T7"
  },
  {
    "uniprot": "A0A5K4F6N3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6N3"
  },
  {
    "uniprot": "A0A5K4FAJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAJ4"
  },
  {
    "uniprot": "A0A5K4F8G8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8G8"
  },
  {
    "uniprot": "A0A5K4EKK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKK2"
  },
  {
    "uniprot": "A0A5K4EJM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJM3"
  },
  {
    "uniprot": "G4LWP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWP9"
  },
  {
    "uniprot": "A0A5K4EIC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIC7"
  },
  {
    "uniprot": "A0A3Q0KS79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS79"
  },
  {
    "uniprot": "A0A5K4EK07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK07"
  },
  {
    "uniprot": "A0A5K4F4Y5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Y5"
  },
  {
    "uniprot": "A0A5K4F591",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F591"
  },
  {
    "uniprot": "A0A5K4EXK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXK6"
  },
  {
    "uniprot": "A0A3Q0KNJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNJ7"
  },
  {
    "uniprot": "A0A5K4ENI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENI9"
  },
  {
    "uniprot": "A0A5K4F6V0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6V0"
  },
  {
    "uniprot": "A0A3Q0KN47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN47"
  },
  {
    "uniprot": "A0A3Q0KGU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGU1"
  },
  {
    "uniprot": "A0A5K4F7B9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7B9"
  },
  {
    "uniprot": "A0A5K4F7R1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7R1"
  },
  {
    "uniprot": "G4VF61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF61"
  },
  {
    "uniprot": "C4QIH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QIH2"
  },
  {
    "uniprot": "A0A5K4F8R1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8R1"
  },
  {
    "uniprot": "A0A3Q0KQQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQQ0"
  },
  {
    "uniprot": "A0A5K4EVQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVQ1"
  },
  {
    "uniprot": "Q2MK73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q2MK73"
  },
  {
    "uniprot": "A0A3Q0KQ22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ22"
  },
  {
    "uniprot": "A0A5K4F5C2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5C2"
  },
  {
    "uniprot": "A0A5K4FCZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCZ2"
  },
  {
    "uniprot": "A0A5K4F9T9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9T9"
  },
  {
    "uniprot": "C4QPR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QPR6"
  },
  {
    "uniprot": "G4VT19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT19"
  },
  {
    "uniprot": "A0A3Q0KR07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR07"
  },
  {
    "uniprot": "A0A5K4FEF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEF6"
  },
  {
    "uniprot": "A0A5K4FDX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDX9"
  },
  {
    "uniprot": "A0A5K4F3K1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3K1"
  },
  {
    "uniprot": "A0A5K4F7E9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7E9"
  },
  {
    "uniprot": "G4M079",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M079"
  },
  {
    "uniprot": "A0A3Q0KLN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLN8"
  },
  {
    "uniprot": "A0A5K4F1V7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1V7"
  },
  {
    "uniprot": "A0A5K4EMS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMS8"
  },
  {
    "uniprot": "A0A5K4F7Z9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Z9"
  },
  {
    "uniprot": "A0A3Q0KGI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGI1"
  },
  {
    "uniprot": "A0A5K4FD27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD27"
  },
  {
    "uniprot": "G4VP78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VP78"
  },
  {
    "uniprot": "A0A5K4F4B0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4B0"
  },
  {
    "uniprot": "A0A5K4EZM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZM2"
  },
  {
    "uniprot": "A0A5K4F3Q3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Q3"
  },
  {
    "uniprot": "G4VGG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGG1"
  },
  {
    "uniprot": "A0A3Q0KNH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNH8"
  },
  {
    "uniprot": "A0A3Q0KFI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFI8"
  },
  {
    "uniprot": "A0A3Q0KDU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDU1"
  },
  {
    "uniprot": "A0A5K4F0C4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0C4"
  },
  {
    "uniprot": "A0A3Q0KH18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH18"
  },
  {
    "uniprot": "A0A5K4F344",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F344"
  },
  {
    "uniprot": "G4VM54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM54"
  },
  {
    "uniprot": "A0A3Q0KHJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHJ7"
  },
  {
    "uniprot": "A0A5K4F088",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F088"
  },
  {
    "uniprot": "A0A5K4EB75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB75"
  },
  {
    "uniprot": "A0A3Q0KFK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFK0"
  },
  {
    "uniprot": "A0A5K4ETL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETL1"
  },
  {
    "uniprot": "A0A3Q0KMD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMD9"
  },
  {
    "uniprot": "A0A3Q0KML7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KML7"
  },
  {
    "uniprot": "G4LXI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXI7"
  },
  {
    "uniprot": "A0A5K4EUF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUF6"
  },
  {
    "uniprot": "A0A5K4ESW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESW0"
  },
  {
    "uniprot": "A0A3Q0KSM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSM1"
  },
  {
    "uniprot": "G4V7X8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7X8"
  },
  {
    "uniprot": "A0A5K4EEZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEZ2"
  },
  {
    "uniprot": "A0A5K4FH71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FH71"
  },
  {
    "uniprot": "A0A5K4FBF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBF8"
  },
  {
    "uniprot": "A0A5K4F928",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F928"
  },
  {
    "uniprot": "A0A5K4EYJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYJ0"
  },
  {
    "uniprot": "G4VPC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPC6"
  },
  {
    "uniprot": "A0A5K4F431",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F431"
  },
  {
    "uniprot": "G4VLJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLJ1"
  },
  {
    "uniprot": "A0A3Q0KGZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGZ2"
  },
  {
    "uniprot": "A0A5K4EZ77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ77"
  },
  {
    "uniprot": "A0A3Q0KC59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC59"
  },
  {
    "uniprot": "A0A5K4F0B2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0B2"
  },
  {
    "uniprot": "A0A5K4F4R1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4R1"
  },
  {
    "uniprot": "G4V841",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V841"
  },
  {
    "uniprot": "A0A5K4ESI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESI3"
  },
  {
    "uniprot": "A0A5K4FE89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE89"
  },
  {
    "uniprot": "A0A5K4FE60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE60"
  },
  {
    "uniprot": "A0A5K4F9M2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9M2"
  },
  {
    "uniprot": "A0A3Q0KU20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU20"
  },
  {
    "uniprot": "A0A5K4ET66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET66"
  },
  {
    "uniprot": "A0A3Q0KNI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNI7"
  },
  {
    "uniprot": "A0A5K4EHE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHE9"
  },
  {
    "uniprot": "A0A5K4FAI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAI6"
  },
  {
    "uniprot": "A0A3Q0KV97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV97"
  },
  {
    "uniprot": "A0A5K4FCK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCK5"
  },
  {
    "uniprot": "A0A3Q0KSX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSX9"
  },
  {
    "uniprot": "A0A3Q0KF09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF09"
  },
  {
    "uniprot": "A0A3Q0KDR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDR6"
  },
  {
    "uniprot": "A0A5K4ETG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETG6"
  },
  {
    "uniprot": "G4LYI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYI7"
  },
  {
    "uniprot": "A0A5K4F256",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F256"
  },
  {
    "uniprot": "A0A5K4EUC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUC3"
  },
  {
    "uniprot": "A0A3Q0KBH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBH2"
  },
  {
    "uniprot": "A0A5K4F9W7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9W7"
  },
  {
    "uniprot": "Q26579",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26579"
  },
  {
    "uniprot": "A0A3Q0KRU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRU4"
  },
  {
    "uniprot": "A0A3Q0KKB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKB7"
  },
  {
    "uniprot": "G4VBT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBT8"
  },
  {
    "uniprot": "A0A5K4EJS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJS8"
  },
  {
    "uniprot": "A0A5K4EWM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWM3"
  },
  {
    "uniprot": "A0A3Q0KV01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV01"
  },
  {
    "uniprot": "A0A5K4F6R2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6R2"
  },
  {
    "uniprot": "G4V9B5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9B5"
  },
  {
    "uniprot": "A0A5K4EW33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW33"
  },
  {
    "uniprot": "A0A3Q0KUE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUE6"
  },
  {
    "uniprot": "A0A5K4FBT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBT1"
  },
  {
    "uniprot": "G4M0B5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0B5"
  },
  {
    "uniprot": "A0A3Q0KLI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLI1"
  },
  {
    "uniprot": "A0A5K4EU39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU39"
  },
  {
    "uniprot": "A0A5K4F615",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F615"
  },
  {
    "uniprot": "A0A5K4F6G3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6G3"
  },
  {
    "uniprot": "A0A3Q0KQY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQY2"
  },
  {
    "uniprot": "A0A5K4EH62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH62"
  },
  {
    "uniprot": "A0A3Q0KP63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP63"
  },
  {
    "uniprot": "G4LXY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXY5"
  },
  {
    "uniprot": "A0A3Q0KJT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJT4"
  },
  {
    "uniprot": "G4VBU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBU3"
  },
  {
    "uniprot": "A0A3Q0KGF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGF8"
  },
  {
    "uniprot": "G4LXR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXR9"
  },
  {
    "uniprot": "A0A3Q0KVF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVF6"
  },
  {
    "uniprot": "A0A5K4F7F1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7F1"
  },
  {
    "uniprot": "A0A3Q0KLV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLV6"
  },
  {
    "uniprot": "C4QMR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QMR1"
  },
  {
    "uniprot": "A0A5K4ES01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES01"
  },
  {
    "uniprot": "A0A5K4ERA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERA2"
  },
  {
    "uniprot": "A0A5K4F3V9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3V9"
  },
  {
    "uniprot": "A0A5K4FEA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEA7"
  },
  {
    "uniprot": "A0A3Q0KSB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSB5"
  },
  {
    "uniprot": "A0A5K4EYF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYF0"
  },
  {
    "uniprot": "A0A3Q0KEN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEN6"
  },
  {
    "uniprot": "G4VBM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBM7"
  },
  {
    "uniprot": "A0A5K4EZP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZP8"
  },
  {
    "uniprot": "A0A5K4F8A2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8A2"
  },
  {
    "uniprot": "A0A5K4EIQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIQ0"
  },
  {
    "uniprot": "G4VKC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKC4"
  },
  {
    "uniprot": "G4VJ60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ60"
  },
  {
    "uniprot": "A0A3Q0KUB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUB4"
  },
  {
    "uniprot": "A0A5K4F230",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F230"
  },
  {
    "uniprot": "A0A3Q0KP16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP16"
  },
  {
    "uniprot": "A0A3Q0KN15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN15"
  },
  {
    "uniprot": "A0A5K4F975",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F975"
  },
  {
    "uniprot": "A0A5K4F7X7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7X7"
  },
  {
    "uniprot": "G4VLG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLG2"
  },
  {
    "uniprot": "A0A5K4F1J7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1J7"
  },
  {
    "uniprot": "A0A5K4EM48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM48"
  },
  {
    "uniprot": "A0A5K4F578",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F578"
  },
  {
    "uniprot": "A0A3Q0KK68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK68"
  },
  {
    "uniprot": "A0A5K4F7P4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7P4"
  },
  {
    "uniprot": "A0A5K4F1K4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1K4"
  },
  {
    "uniprot": "A0A3Q0KPS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPS0"
  },
  {
    "uniprot": "G4VC14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC14"
  },
  {
    "uniprot": "A0A3Q0KQL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQL2"
  },
  {
    "uniprot": "G4LZC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZC4"
  },
  {
    "uniprot": "A0A5K4EP41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP41"
  },
  {
    "uniprot": "A0A5K4ENG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENG1"
  },
  {
    "uniprot": "G4VEP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEP3"
  },
  {
    "uniprot": "A0A5K4FBM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBM3"
  },
  {
    "uniprot": "A0A5K4F198",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F198"
  },
  {
    "uniprot": "A0A3Q0KH66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH66"
  },
  {
    "uniprot": "A0A3Q0KMR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMR8"
  },
  {
    "uniprot": "A0A3Q0KN69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN69"
  },
  {
    "uniprot": "A0A3Q0KN14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN14"
  },
  {
    "uniprot": "A0A5K4EPU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPU0"
  },
  {
    "uniprot": "A0A5K4EUS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUS8"
  },
  {
    "uniprot": "A0A3Q0KCU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCU0"
  },
  {
    "uniprot": "A0A5K4EL52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL52"
  },
  {
    "uniprot": "A0A5K4F1Q2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Q2"
  },
  {
    "uniprot": "Q15ER3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q15ER3"
  },
  {
    "uniprot": "A0A3Q0KTL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTL6"
  },
  {
    "uniprot": "A0A5K4EM50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM50"
  },
  {
    "uniprot": "A0A5K4EMQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMQ4"
  },
  {
    "uniprot": "A0A3Q0KTX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTX6"
  },
  {
    "uniprot": "A0A5K4F7K1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7K1"
  },
  {
    "uniprot": "C4QF92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QF92"
  },
  {
    "uniprot": "A0A5K4ES32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES32"
  },
  {
    "uniprot": "A0A3Q0KM42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM42"
  },
  {
    "uniprot": "A0A5K4F2G9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2G9"
  },
  {
    "uniprot": "A0A3Q0KPQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPQ0"
  },
  {
    "uniprot": "A0A3Q0KKC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKC3"
  },
  {
    "uniprot": "Q26572",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26572"
  },
  {
    "uniprot": "A0A3Q0KTT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTT8"
  },
  {
    "uniprot": "A0A5K4ENF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENF8"
  },
  {
    "uniprot": "A0A3Q0KEM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEM3"
  },
  {
    "uniprot": "A0A5K4F8N6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8N6"
  },
  {
    "uniprot": "A0A5K4EIB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIB1"
  },
  {
    "uniprot": "A0A5K4FAA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAA9"
  },
  {
    "uniprot": "A0A5K4F171",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F171"
  },
  {
    "uniprot": "A0A3Q0KUV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUV0"
  },
  {
    "uniprot": "A0A5K4EDW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDW9"
  },
  {
    "uniprot": "G4VJY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJY4"
  },
  {
    "uniprot": "A0A3Q0KJ42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ42"
  },
  {
    "uniprot": "A0A5K4F4U3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4U3"
  },
  {
    "uniprot": "G4LYW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYW7"
  },
  {
    "uniprot": "A0A3Q0KCG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCG3"
  },
  {
    "uniprot": "A0A5K4FCY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCY3"
  },
  {
    "uniprot": "A0A3Q0KT42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT42"
  },
  {
    "uniprot": "A0A3Q0KQK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQK1"
  },
  {
    "uniprot": "A0A5K4EXN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXN1"
  },
  {
    "uniprot": "A0A5K4EKE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKE7"
  },
  {
    "uniprot": "A0A3Q0KSN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSN2"
  },
  {
    "uniprot": "A0A5K4F4G9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4G9"
  },
  {
    "uniprot": "G4VPI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPI9"
  },
  {
    "uniprot": "A0A5K4F932",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F932"
  },
  {
    "uniprot": "A0A3Q0KCQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCQ0"
  },
  {
    "uniprot": "C4QKE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QKE8"
  },
  {
    "uniprot": "A0A5K4F5Q8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Q8"
  },
  {
    "uniprot": "G4VIK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIK7"
  },
  {
    "uniprot": "A0A3Q0KQW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQW1"
  },
  {
    "uniprot": "A0A5K4EKY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKY4"
  },
  {
    "uniprot": "A0A3Q0KUB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUB2"
  },
  {
    "uniprot": "A0A5K4FBQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBQ5"
  },
  {
    "uniprot": "G4LX65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX65"
  },
  {
    "uniprot": "A0A3Q0KT73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT73"
  },
  {
    "uniprot": "A0A5K4F877",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F877"
  },
  {
    "uniprot": "A0A5K4EYG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYG2"
  },
  {
    "uniprot": "G4V868",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V868"
  },
  {
    "uniprot": "A0A5K4FAI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAI4"
  },
  {
    "uniprot": "C4Q5K0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q5K0"
  },
  {
    "uniprot": "A0A5K4EWL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWL3"
  },
  {
    "uniprot": "A0A3Q0KC91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC91"
  },
  {
    "uniprot": "A0A3Q0KKE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKE7"
  },
  {
    "uniprot": "A0A5K4EIB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIB8"
  },
  {
    "uniprot": "C4PZS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4PZS5"
  },
  {
    "uniprot": "G4VTW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTW8"
  },
  {
    "uniprot": "P37227",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P37227"
  },
  {
    "uniprot": "A0A3Q0KUS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUS0"
  },
  {
    "uniprot": "A0A5K4EPF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPF3"
  },
  {
    "uniprot": "A0A5K4FF49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF49"
  },
  {
    "uniprot": "A0A5K4EV61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV61"
  },
  {
    "uniprot": "A0A3Q0KBS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBS6"
  },
  {
    "uniprot": "A0A5K4FBT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBT7"
  },
  {
    "uniprot": "G4VLZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLZ9"
  },
  {
    "uniprot": "A0A5K4ERF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERF0"
  },
  {
    "uniprot": "A0A5K4EKX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKX9"
  },
  {
    "uniprot": "A0A5K4F5P6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5P6"
  },
  {
    "uniprot": "G4VKT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKT0"
  },
  {
    "uniprot": "A0A3Q0KP40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP40"
  },
  {
    "uniprot": "A0A3Q0KM07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM07"
  },
  {
    "uniprot": "A0A3Q0KV36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV36"
  },
  {
    "uniprot": "G4LY80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY80"
  },
  {
    "uniprot": "A0A5K4EPZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPZ7"
  },
  {
    "uniprot": "A0A5K4EZA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZA8"
  },
  {
    "uniprot": "A0A5K4E9I9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9I9"
  },
  {
    "uniprot": "A0A3Q0KK40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK40"
  },
  {
    "uniprot": "A0A5K4EBZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBZ0"
  },
  {
    "uniprot": "A0A3Q0KFD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFD8"
  },
  {
    "uniprot": "G4V6H6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6H6"
  },
  {
    "uniprot": "A0A5K4FCC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCC5"
  },
  {
    "uniprot": "A0A5K4F100",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F100"
  },
  {
    "uniprot": "A0A3Q0KTU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTU0"
  },
  {
    "uniprot": "A0A5K4F1Q3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Q3"
  },
  {
    "uniprot": "A0A3Q0KND9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KND9"
  },
  {
    "uniprot": "A0A5K4EHW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHW1"
  },
  {
    "uniprot": "A0A3Q0KTR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTR7"
  },
  {
    "uniprot": "A0A3Q0KT85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT85"
  },
  {
    "uniprot": "G4VSD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSD3"
  },
  {
    "uniprot": "A0A5K4F668",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F668"
  },
  {
    "uniprot": "A0A5K4F6I2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6I2"
  },
  {
    "uniprot": "G4LXI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXI5"
  },
  {
    "uniprot": "A0A5K4F1Y3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Y3"
  },
  {
    "uniprot": "G4VKM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKM6"
  },
  {
    "uniprot": "G4VR79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR79"
  },
  {
    "uniprot": "A0A5K4F697",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F697"
  },
  {
    "uniprot": "A0A5K4EZN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZN8"
  },
  {
    "uniprot": "G4M0X0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0X0"
  },
  {
    "uniprot": "A0A3Q0KFD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFD4"
  },
  {
    "uniprot": "A0A3Q0KCR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCR4"
  },
  {
    "uniprot": "A0A5K4F3W7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3W7"
  },
  {
    "uniprot": "A0A5K4FBZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBZ0"
  },
  {
    "uniprot": "A0A3Q0KRW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRW8"
  },
  {
    "uniprot": "G4VBY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBY8"
  },
  {
    "uniprot": "A0A5K4ENL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENL3"
  },
  {
    "uniprot": "A0A3Q0KRH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRH7"
  },
  {
    "uniprot": "A0A3Q0KGQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGQ7"
  },
  {
    "uniprot": "G4LYP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYP0"
  },
  {
    "uniprot": "A0A5K4F6Q4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Q4"
  },
  {
    "uniprot": "A0A5K4EPF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPF6"
  },
  {
    "uniprot": "A0A3Q0KQI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQI3"
  },
  {
    "uniprot": "A0A3Q0KJI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJI8"
  },
  {
    "uniprot": "A0A5K4F5G3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5G3"
  },
  {
    "uniprot": "G4VEL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEL6"
  },
  {
    "uniprot": "A0A5K4EC50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EC50"
  },
  {
    "uniprot": "A0A5K4ER56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER56"
  },
  {
    "uniprot": "G4VNV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNV1"
  },
  {
    "uniprot": "A0A5K4EZM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZM3"
  },
  {
    "uniprot": "A0A3Q0KR31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR31"
  },
  {
    "uniprot": "A0A3Q0KD05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD05"
  },
  {
    "uniprot": "A0A5K4FCA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCA8"
  },
  {
    "uniprot": "A0A3Q0KQ73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ73"
  },
  {
    "uniprot": "A0A3Q0KD20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD20"
  },
  {
    "uniprot": "G4VJM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJM9"
  },
  {
    "uniprot": "G4VQM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQM4"
  },
  {
    "uniprot": "A0A3Q0KJQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJQ9"
  },
  {
    "uniprot": "G4VG91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VG91"
  },
  {
    "uniprot": "A0A3Q0KBH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBH1"
  },
  {
    "uniprot": "G4VQ39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ39"
  },
  {
    "uniprot": "A0A5K4F6R1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6R1"
  },
  {
    "uniprot": "A0A5K4F2Y8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Y8"
  },
  {
    "uniprot": "A0A5K4E9V0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9V0"
  },
  {
    "uniprot": "A0A3Q0KUZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUZ7"
  },
  {
    "uniprot": "A0A3Q0KPD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPD1"
  },
  {
    "uniprot": "A0A5K4ENR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENR6"
  },
  {
    "uniprot": "G4VEY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEY7"
  },
  {
    "uniprot": "A0A5K4F5A7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5A7"
  },
  {
    "uniprot": "G4VT64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT64"
  },
  {
    "uniprot": "A0A3Q0KPS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPS1"
  },
  {
    "uniprot": "A0A5K4F1Y4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Y4"
  },
  {
    "uniprot": "A0A5K4F2F2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2F2"
  },
  {
    "uniprot": "A0A3Q0KMH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMH8"
  },
  {
    "uniprot": "A0A3Q0KF13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF13"
  },
  {
    "uniprot": "A0A5K4F7V2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7V2"
  },
  {
    "uniprot": "P27730",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P27730"
  },
  {
    "uniprot": "G4M1H4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1H4"
  },
  {
    "uniprot": "A0A5K4F4D7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4D7"
  },
  {
    "uniprot": "A0A3Q0KRT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRT6"
  },
  {
    "uniprot": "A0A5K4EZU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZU3"
  },
  {
    "uniprot": "A0A5K4FDG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDG0"
  },
  {
    "uniprot": "A0A5K4FA98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA98"
  },
  {
    "uniprot": "A0A5K4FDX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDX6"
  },
  {
    "uniprot": "A0A3Q0KBL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBL9"
  },
  {
    "uniprot": "A0A5K4F1V9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1V9"
  },
  {
    "uniprot": "A0A3Q0KV89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV89"
  },
  {
    "uniprot": "A0A5K4E9L7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9L7"
  },
  {
    "uniprot": "A0A5K4F4I7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4I7"
  },
  {
    "uniprot": "A0A5K4EWR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWR6"
  },
  {
    "uniprot": "A0A5K4EMZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMZ4"
  },
  {
    "uniprot": "A0A5K4FA47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA47"
  },
  {
    "uniprot": "A0A3Q0KI28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI28"
  },
  {
    "uniprot": "P16464",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P16464"
  },
  {
    "uniprot": "A0A3Q0KHT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHT5"
  },
  {
    "uniprot": "G4VT59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT59"
  },
  {
    "uniprot": "A0A5K4ER79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER79"
  },
  {
    "uniprot": "A0A3Q0KL56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL56"
  },
  {
    "uniprot": "G4VF55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF55"
  },
  {
    "uniprot": "A0A3Q0KR84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR84"
  },
  {
    "uniprot": "A0A3Q0KTT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTT1"
  },
  {
    "uniprot": "G4VF96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF96"
  },
  {
    "uniprot": "A0A5K4F0E0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0E0"
  },
  {
    "uniprot": "A0A5K4F5N4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5N4"
  },
  {
    "uniprot": "A0A5K4FFR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFR3"
  },
  {
    "uniprot": "A0A5K4F0H6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0H6"
  },
  {
    "uniprot": "A0A5K4F702",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F702"
  },
  {
    "uniprot": "A0A5K4FFM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFM2"
  },
  {
    "uniprot": "A0A5K4F4V4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4V4"
  },
  {
    "uniprot": "A0A3Q0KFY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFY2"
  },
  {
    "uniprot": "G4VJA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJA6"
  },
  {
    "uniprot": "A0A5K4F5G8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5G8"
  },
  {
    "uniprot": "A0A3Q0KDY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDY4"
  },
  {
    "uniprot": "A0A5K4EEP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEP2"
  },
  {
    "uniprot": "A0A5K4FAY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAY8"
  },
  {
    "uniprot": "A0A5K4EQG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQG0"
  },
  {
    "uniprot": "A0A3Q0KSR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSR2"
  },
  {
    "uniprot": "A0A5K4F5B2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5B2"
  },
  {
    "uniprot": "A0A5K4FCM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCM1"
  },
  {
    "uniprot": "A0A3Q0KT05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT05"
  },
  {
    "uniprot": "A0A5K4F968",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F968"
  },
  {
    "uniprot": "A0A3Q0KE94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE94"
  },
  {
    "uniprot": "A0A3Q0KLF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLF7"
  },
  {
    "uniprot": "A0A5K4F7I6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7I6"
  },
  {
    "uniprot": "G4LWL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWL4"
  },
  {
    "uniprot": "A0A5K4FCS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCS3"
  },
  {
    "uniprot": "A0A3Q0KRJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRJ2"
  },
  {
    "uniprot": "A0A3Q0KG40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG40"
  },
  {
    "uniprot": "A0A3Q0KMJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMJ1"
  },
  {
    "uniprot": "A0A0U5KFM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A0U5KFM1"
  },
  {
    "uniprot": "A0A5K4F9N0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9N0"
  },
  {
    "uniprot": "A0A5K4EN36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN36"
  },
  {
    "uniprot": "G4VQS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQS3"
  },
  {
    "uniprot": "A0A5K4EML5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EML5"
  },
  {
    "uniprot": "A0A5K4F212",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F212"
  },
  {
    "uniprot": "A0A5K4F456",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F456"
  },
  {
    "uniprot": "A0A3Q0KQV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQV2"
  },
  {
    "uniprot": "A0A3Q0KBV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBV7"
  },
  {
    "uniprot": "A0A3Q0KHL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHL1"
  },
  {
    "uniprot": "G4VH72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH72"
  },
  {
    "uniprot": "A0A3Q0KT18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT18"
  },
  {
    "uniprot": "A0A5K4EZZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZZ5"
  },
  {
    "uniprot": "A0A5K4ELD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELD1"
  },
  {
    "uniprot": "G4VBM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBM4"
  },
  {
    "uniprot": "A0A5K4F773",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F773"
  },
  {
    "uniprot": "G4VCG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCG5"
  },
  {
    "uniprot": "A0A5K4F497",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F497"
  },
  {
    "uniprot": "A0A5K4ECG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECG8"
  },
  {
    "uniprot": "A0A5K4FBB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBB0"
  },
  {
    "uniprot": "A0A5K4EUY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUY1"
  },
  {
    "uniprot": "A0A5K4F7R2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7R2"
  },
  {
    "uniprot": "A0A5K4F9W9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9W9"
  },
  {
    "uniprot": "A0A5K4F730",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F730"
  },
  {
    "uniprot": "G4LWL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWL0"
  },
  {
    "uniprot": "A0A5K4F4Z1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Z1"
  },
  {
    "uniprot": "A0A3Q0KLB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLB4"
  },
  {
    "uniprot": "A0A3Q0KSQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSQ9"
  },
  {
    "uniprot": "A0A5K4EK95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK95"
  },
  {
    "uniprot": "A0A3Q0KM03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM03"
  },
  {
    "uniprot": "G4V9U0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9U0"
  },
  {
    "uniprot": "A0A3Q0KT22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT22"
  },
  {
    "uniprot": "A0A5K4EVP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVP6"
  },
  {
    "uniprot": "A0A3Q0KKA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKA0"
  },
  {
    "uniprot": "A0A5K4ENC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENC9"
  },
  {
    "uniprot": "A0A5K4ERY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERY4"
  },
  {
    "uniprot": "A0A3Q0KNW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNW6"
  },
  {
    "uniprot": "A0A3Q0KR00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR00"
  },
  {
    "uniprot": "A0A5K4EXX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXX5"
  },
  {
    "uniprot": "A0A5K4EZ50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ50"
  },
  {
    "uniprot": "A0A5K4F0X7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0X7"
  },
  {
    "uniprot": "A0A3Q0KPS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPS2"
  },
  {
    "uniprot": "A0A5K4FAW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAW7"
  },
  {
    "uniprot": "G4LZ89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ89"
  },
  {
    "uniprot": "A0A5K4F7Q2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Q2"
  },
  {
    "uniprot": "A0A3Q0KGE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGE2"
  },
  {
    "uniprot": "G4V867",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V867"
  },
  {
    "uniprot": "A0A5K4F0L3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0L3"
  },
  {
    "uniprot": "A0A3Q0KP98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP98"
  },
  {
    "uniprot": "P35661",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P35661"
  },
  {
    "uniprot": "G4LZH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZH7"
  },
  {
    "uniprot": "A0A3Q0KSC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSC0"
  },
  {
    "uniprot": "A0A5K4EPK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPK8"
  },
  {
    "uniprot": "A0A3Q0KLV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLV7"
  },
  {
    "uniprot": "A0A5K4ENQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENQ0"
  },
  {
    "uniprot": "A0A5K4FAH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAH6"
  },
  {
    "uniprot": "G4LYL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYL7"
  },
  {
    "uniprot": "A0A5K4EXA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXA2"
  },
  {
    "uniprot": "A0A5K4F268",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F268"
  },
  {
    "uniprot": "G4VAK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAK1"
  },
  {
    "uniprot": "A0A5K4F3B3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3B3"
  },
  {
    "uniprot": "G4M1H9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1H9"
  },
  {
    "uniprot": "A0A3Q0KFI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFI7"
  },
  {
    "uniprot": "A0A3Q0KME2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KME2"
  },
  {
    "uniprot": "A0A3Q0KQV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQV7"
  },
  {
    "uniprot": "A0A3Q0KU66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU66"
  },
  {
    "uniprot": "A0A3Q0KI72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI72"
  },
  {
    "uniprot": "A0A5K4F1J1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1J1"
  },
  {
    "uniprot": "A0A5K4F3Y2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Y2"
  },
  {
    "uniprot": "G4VA62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA62"
  },
  {
    "uniprot": "A0A5K4F612",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F612"
  },
  {
    "uniprot": "A0A5K4E9B2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9B2"
  },
  {
    "uniprot": "A0A5K4FD33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD33"
  },
  {
    "uniprot": "A0A3Q0KFN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFN1"
  },
  {
    "uniprot": "A0A5K4F557",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F557"
  },
  {
    "uniprot": "A0A3Q0KPU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPU2"
  },
  {
    "uniprot": "A0A5K4EQS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQS9"
  },
  {
    "uniprot": "A0A3Q0KLM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLM4"
  },
  {
    "uniprot": "A0A5K4FA74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA74"
  },
  {
    "uniprot": "A0A5K4ESD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESD1"
  },
  {
    "uniprot": "G4V5H3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5H3"
  },
  {
    "uniprot": "A0A3Q0KHR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHR0"
  },
  {
    "uniprot": "A0A3Q0KNY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNY6"
  },
  {
    "uniprot": "A0A3Q0KDD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDD0"
  },
  {
    "uniprot": "A0A3Q0KPV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPV6"
  },
  {
    "uniprot": "A0A5K4EF93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EF93"
  },
  {
    "uniprot": "A0A3Q0KU64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU64"
  },
  {
    "uniprot": "G4VSF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSF6"
  },
  {
    "uniprot": "G4LYG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYG2"
  },
  {
    "uniprot": "A0A5K4EWS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWS2"
  },
  {
    "uniprot": "G4LWT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWT1"
  },
  {
    "uniprot": "A0A5K4F6B2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6B2"
  },
  {
    "uniprot": "A0A5K4F5W9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5W9"
  },
  {
    "uniprot": "A0A5K4ENE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENE3"
  },
  {
    "uniprot": "P20287",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P20287"
  },
  {
    "uniprot": "G4VQJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQJ2"
  },
  {
    "uniprot": "A0A5K4FBY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBY8"
  },
  {
    "uniprot": "A0A5K4ERG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERG1"
  },
  {
    "uniprot": "A0A5K4FC72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC72"
  },
  {
    "uniprot": "A0A5K4FCP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCP2"
  },
  {
    "uniprot": "A0A5K4F2G3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2G3"
  },
  {
    "uniprot": "A0A3Q0KEP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEP8"
  },
  {
    "uniprot": "G4V5B9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5B9"
  },
  {
    "uniprot": "A0A3Q0KDJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDJ2"
  },
  {
    "uniprot": "A0A3Q0KU58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU58"
  },
  {
    "uniprot": "A0A5K4F9Y0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Y0"
  },
  {
    "uniprot": "A0A3Q0KPE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPE5"
  },
  {
    "uniprot": "G4VMN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMN2"
  },
  {
    "uniprot": "A0A3Q0KHR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHR2"
  },
  {
    "uniprot": "A0A5K4EEI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEI8"
  },
  {
    "uniprot": "G4V9Q0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9Q0"
  },
  {
    "uniprot": "G4V846",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V846"
  },
  {
    "uniprot": "A0A3Q0KG07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG07"
  },
  {
    "uniprot": "A0A5K4EXT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXT2"
  },
  {
    "uniprot": "A0A3Q0KPW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPW3"
  },
  {
    "uniprot": "A0A5K4EJR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJR9"
  },
  {
    "uniprot": "A0A3Q0KNK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNK1"
  },
  {
    "uniprot": "A0A3Q0KEK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEK7"
  },
  {
    "uniprot": "A0A5K4F4K6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4K6"
  },
  {
    "uniprot": "A0A3Q0KVA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVA0"
  },
  {
    "uniprot": "A0A3Q0KK11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK11"
  },
  {
    "uniprot": "G4VBK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBK8"
  },
  {
    "uniprot": "A0A3Q0KN75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN75"
  },
  {
    "uniprot": "A0A5K4FCN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCN1"
  },
  {
    "uniprot": "A0A5K4EM67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM67"
  },
  {
    "uniprot": "G4VMC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMC2"
  },
  {
    "uniprot": "A0A5K4F6T4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6T4"
  },
  {
    "uniprot": "A0A3Q0KCP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCP9"
  },
  {
    "uniprot": "A0A3Q0KSV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSV9"
  },
  {
    "uniprot": "A0A5K4EMZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMZ9"
  },
  {
    "uniprot": "G4LUT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUT8"
  },
  {
    "uniprot": "A0A3Q0KNM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNM3"
  },
  {
    "uniprot": "G4LXG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXG8"
  },
  {
    "uniprot": "A0A5K4F394",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F394"
  },
  {
    "uniprot": "A0A5K4ETI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETI5"
  },
  {
    "uniprot": "A0A3Q0KM83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM83"
  },
  {
    "uniprot": "A0A5K4F0F7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0F7"
  },
  {
    "uniprot": "A0A3Q0KPC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPC3"
  },
  {
    "uniprot": "G4VBI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBI4"
  },
  {
    "uniprot": "A0A3Q0KHW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHW2"
  },
  {
    "uniprot": "A0A5K4EM54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM54"
  },
  {
    "uniprot": "A0A5K4F5Q4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Q4"
  },
  {
    "uniprot": "A0A3Q0KDG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDG1"
  },
  {
    "uniprot": "G4VPR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPR2"
  },
  {
    "uniprot": "A0A5K4EKP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKP9"
  },
  {
    "uniprot": "A0A5K4E969",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E969"
  },
  {
    "uniprot": "G4VAM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAM8"
  },
  {
    "uniprot": "G4V9B6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9B6"
  },
  {
    "uniprot": "A0A5K4EVT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVT8"
  },
  {
    "uniprot": "A0A5K4F9H8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9H8"
  },
  {
    "uniprot": "Q95PM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q95PM1"
  },
  {
    "uniprot": "A0A5K4F4F7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4F7"
  },
  {
    "uniprot": "A0A3Q0KNB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNB5"
  },
  {
    "uniprot": "G4VQ12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ12"
  },
  {
    "uniprot": "G4VHY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHY6"
  },
  {
    "uniprot": "A0A5K4FA05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA05"
  },
  {
    "uniprot": "G4VJH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJH4"
  },
  {
    "uniprot": "A0A3Q0KFA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFA7"
  },
  {
    "uniprot": "A0A5K4EQR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQR4"
  },
  {
    "uniprot": "A0A5K4FE40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE40"
  },
  {
    "uniprot": "A0A3Q0KS82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS82"
  },
  {
    "uniprot": "A0A5K4E984",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E984"
  },
  {
    "uniprot": "A0A3Q0KGB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGB6"
  },
  {
    "uniprot": "A0A3Q0KRP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRP1"
  },
  {
    "uniprot": "A0A5K4EMY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMY1"
  },
  {
    "uniprot": "A0A3Q0KNY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNY2"
  },
  {
    "uniprot": "A0A5K4ENT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENT5"
  },
  {
    "uniprot": "G4VFH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFH6"
  },
  {
    "uniprot": "G4VQJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQJ0"
  },
  {
    "uniprot": "A0A3Q0KN56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN56"
  },
  {
    "uniprot": "A0A3Q0KQ95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ95"
  },
  {
    "uniprot": "A0A3Q0KM52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM52"
  },
  {
    "uniprot": "A0A5K4ELM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELM6"
  },
  {
    "uniprot": "A0A5K4EMU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMU6"
  },
  {
    "uniprot": "A0A3Q0KKN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKN4"
  },
  {
    "uniprot": "A0A5K4EZF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZF0"
  },
  {
    "uniprot": "A0A5K4FCB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCB8"
  },
  {
    "uniprot": "A0A5K4ETC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETC0"
  },
  {
    "uniprot": "A0A3Q0KUA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUA2"
  },
  {
    "uniprot": "A0A3Q0KKX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKX4"
  },
  {
    "uniprot": "A0A3Q0KRW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRW5"
  },
  {
    "uniprot": "G4VTP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTP8"
  },
  {
    "uniprot": "A0A3Q0KFW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFW8"
  },
  {
    "uniprot": "G4V668",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V668"
  },
  {
    "uniprot": "A0A5K4EVQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVQ3"
  },
  {
    "uniprot": "A0A3Q0KQE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQE4"
  },
  {
    "uniprot": "A0A5K4F2S6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2S6"
  },
  {
    "uniprot": "A0A3Q0KG50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG50"
  },
  {
    "uniprot": "G4V6T9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6T9"
  },
  {
    "uniprot": "A0A5K4ETC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETC5"
  },
  {
    "uniprot": "G4VA92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA92"
  },
  {
    "uniprot": "A0A5K4EKW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKW7"
  },
  {
    "uniprot": "A0A5K4ETF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETF0"
  },
  {
    "uniprot": "A0A3Q0KUD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUD2"
  },
  {
    "uniprot": "A0A3Q0KKD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKD5"
  },
  {
    "uniprot": "A0A3Q0KLH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLH8"
  },
  {
    "uniprot": "G4V7B6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7B6"
  },
  {
    "uniprot": "A0A3Q0KDA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDA2"
  },
  {
    "uniprot": "A0A3Q0KRC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRC7"
  },
  {
    "uniprot": "A0A5K4F5R3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5R3"
  },
  {
    "uniprot": "A0A5K4FDX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDX2"
  },
  {
    "uniprot": "A0A5K4F2G2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2G2"
  },
  {
    "uniprot": "G4VE54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VE54"
  },
  {
    "uniprot": "A0A5K4FBZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBZ5"
  },
  {
    "uniprot": "A0A3Q0KLF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLF0"
  },
  {
    "uniprot": "A0A5K4FDA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDA7"
  },
  {
    "uniprot": "A0A5K4F1J9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1J9"
  },
  {
    "uniprot": "A0A3Q0KRA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRA4"
  },
  {
    "uniprot": "G4VR24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR24"
  },
  {
    "uniprot": "A0A5K4FA09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA09"
  },
  {
    "uniprot": "A0A5K4EE91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE91"
  },
  {
    "uniprot": "A0A5K4F0B8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0B8"
  },
  {
    "uniprot": "G4VL14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VL14"
  },
  {
    "uniprot": "A0A3Q0KM80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM80"
  },
  {
    "uniprot": "A0A5K4EPI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPI2"
  },
  {
    "uniprot": "A0A3Q0KMU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMU3"
  },
  {
    "uniprot": "A0A5K4F6H8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6H8"
  },
  {
    "uniprot": "A0A3Q0KE65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE65"
  },
  {
    "uniprot": "A0A5K4F3E0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3E0"
  },
  {
    "uniprot": "A0A5K4FBK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBK4"
  },
  {
    "uniprot": "A0A5K4F3V5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3V5"
  },
  {
    "uniprot": "G4VAW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAW8"
  },
  {
    "uniprot": "A0A3Q0KI20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI20"
  },
  {
    "uniprot": "A0A5K4F495",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F495"
  },
  {
    "uniprot": "A0A5K4F441",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F441"
  },
  {
    "uniprot": "G4VPA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPA5"
  },
  {
    "uniprot": "G4LUB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUB6"
  },
  {
    "uniprot": "A0A3Q0KEC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEC6"
  },
  {
    "uniprot": "A0A3Q0KPF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPF3"
  },
  {
    "uniprot": "A0A5K4EW97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW97"
  },
  {
    "uniprot": "A0A5K4EKK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKK0"
  },
  {
    "uniprot": "A0A5K4EK58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK58"
  },
  {
    "uniprot": "A0A3Q0KPE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPE8"
  },
  {
    "uniprot": "A0A5K4FF06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF06"
  },
  {
    "uniprot": "A0A5K4F7J7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7J7"
  },
  {
    "uniprot": "A0A5K4F7P2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7P2"
  },
  {
    "uniprot": "A0A5K4ELF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELF8"
  },
  {
    "uniprot": "A0A5K4ELU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELU3"
  },
  {
    "uniprot": "A0A3Q0KJ58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ58"
  },
  {
    "uniprot": "A0A5K4EEH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEH0"
  },
  {
    "uniprot": "G4VEL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEL0"
  },
  {
    "uniprot": "A0A5K4EIC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIC8"
  },
  {
    "uniprot": "A0A5K4FDP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDP8"
  },
  {
    "uniprot": "G4VGF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGF4"
  },
  {
    "uniprot": "A0A3Q0KQ86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ86"
  },
  {
    "uniprot": "G4VPI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPI1"
  },
  {
    "uniprot": "G4LVE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVE2"
  },
  {
    "uniprot": "A0A5K4F3P5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3P5"
  },
  {
    "uniprot": "A0A5K4F3Q4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Q4"
  },
  {
    "uniprot": "A0A5K4F4R3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4R3"
  },
  {
    "uniprot": "A0A5K4EM05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM05"
  },
  {
    "uniprot": "A0A5K4EQR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQR6"
  },
  {
    "uniprot": "A0A5K4F9E4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9E4"
  },
  {
    "uniprot": "A0A3Q0KQI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQI8"
  },
  {
    "uniprot": "A0A5K4FC84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC84"
  },
  {
    "uniprot": "A0A5K4F2M5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2M5"
  },
  {
    "uniprot": "G4LV43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV43"
  },
  {
    "uniprot": "A0A3Q0KN16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN16"
  },
  {
    "uniprot": "P48501",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P48501"
  },
  {
    "uniprot": "A0A5K4EJH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJH6"
  },
  {
    "uniprot": "A0A3Q0KI19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI19"
  },
  {
    "uniprot": "A0A5K4F7X0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7X0"
  },
  {
    "uniprot": "A0A5K4F2A0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2A0"
  },
  {
    "uniprot": "A0A3Q0KVL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVL5"
  },
  {
    "uniprot": "A0A5K4EF84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EF84"
  },
  {
    "uniprot": "A0A5K4E9F3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9F3"
  },
  {
    "uniprot": "A0A5K4FBU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBU8"
  },
  {
    "uniprot": "A0A3Q0KLX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLX6"
  },
  {
    "uniprot": "G4VCL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCL9"
  },
  {
    "uniprot": "A0A5K4FEL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEL4"
  },
  {
    "uniprot": "A0A3Q0KS38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS38"
  },
  {
    "uniprot": "A0A5K4F733",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F733"
  },
  {
    "uniprot": "A0A3Q0KQI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQI4"
  },
  {
    "uniprot": "A0A5K4F0A5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0A5"
  },
  {
    "uniprot": "A0A5K4F882",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F882"
  },
  {
    "uniprot": "G4V9P7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9P7"
  },
  {
    "uniprot": "A0A3Q0KTE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTE2"
  },
  {
    "uniprot": "A0A5K4ELJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELJ6"
  },
  {
    "uniprot": "A0A3Q0KED6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KED6"
  },
  {
    "uniprot": "A0A3Q0KTM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTM1"
  },
  {
    "uniprot": "A0A3Q0KJC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJC7"
  },
  {
    "uniprot": "G4VTB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTB2"
  },
  {
    "uniprot": "A0A5K4EZC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZC5"
  },
  {
    "uniprot": "A0A5K4EU15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU15"
  },
  {
    "uniprot": "A0A5K4FEQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEQ0"
  },
  {
    "uniprot": "A0A5K4F8I0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8I0"
  },
  {
    "uniprot": "A0A3Q0KSA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSA5"
  },
  {
    "uniprot": "G4LWW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWW6"
  },
  {
    "uniprot": "A0A5K4F4U7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4U7"
  },
  {
    "uniprot": "A0A3Q0KTW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTW8"
  },
  {
    "uniprot": "A0A3Q0KE55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE55"
  },
  {
    "uniprot": "A0A3Q0KCU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCU9"
  },
  {
    "uniprot": "A0A3Q0KC61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC61"
  },
  {
    "uniprot": "G4LVG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVG8"
  },
  {
    "uniprot": "A0A3Q0KSP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSP9"
  },
  {
    "uniprot": "A0A5K4EWL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWL6"
  },
  {
    "uniprot": "A0A5K4F609",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F609"
  },
  {
    "uniprot": "G4VRN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRN5"
  },
  {
    "uniprot": "A0A5K4E971",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E971"
  },
  {
    "uniprot": "A0A5K4ELQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELQ2"
  },
  {
    "uniprot": "A0A5K4F8Y6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Y6"
  },
  {
    "uniprot": "G4VF79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF79"
  },
  {
    "uniprot": "A0A3Q0KIJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIJ4"
  },
  {
    "uniprot": "A0A5K4FF14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF14"
  },
  {
    "uniprot": "G4M118",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M118"
  },
  {
    "uniprot": "A0A5K4F6W4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6W4"
  },
  {
    "uniprot": "A0A3Q0KHR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHR8"
  },
  {
    "uniprot": "A0A3Q0KP20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP20"
  },
  {
    "uniprot": "A0A5K4F2Q0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Q0"
  },
  {
    "uniprot": "A0A3Q0KMV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMV5"
  },
  {
    "uniprot": "A0A3Q0KPF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPF8"
  },
  {
    "uniprot": "A0A5K4F8T4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8T4"
  },
  {
    "uniprot": "A0A3Q0KIU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIU0"
  },
  {
    "uniprot": "A0A5K4F6L7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6L7"
  },
  {
    "uniprot": "A0A5K4F0N0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0N0"
  },
  {
    "uniprot": "A0A5K4F1R5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1R5"
  },
  {
    "uniprot": "A0A3Q0KC80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC80"
  },
  {
    "uniprot": "A0A3Q0KDR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDR0"
  },
  {
    "uniprot": "A0A5K4F357",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F357"
  },
  {
    "uniprot": "A0A5K4EJR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJR8"
  },
  {
    "uniprot": "A0A3Q0KE61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE61"
  },
  {
    "uniprot": "G4VRF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRF7"
  },
  {
    "uniprot": "A0A3Q0KJ37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ37"
  },
  {
    "uniprot": "A0A3Q0KMT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMT6"
  },
  {
    "uniprot": "A0A5K4F197",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F197"
  },
  {
    "uniprot": "A0A5K4F6G2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6G2"
  },
  {
    "uniprot": "A0A5K4F1K6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1K6"
  },
  {
    "uniprot": "A0A3Q0KME7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KME7"
  },
  {
    "uniprot": "A0A3Q0KGB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGB7"
  },
  {
    "uniprot": "A0A5K4FBY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBY7"
  },
  {
    "uniprot": "A0A5K4E959",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E959"
  },
  {
    "uniprot": "A0A3Q0KMG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMG5"
  },
  {
    "uniprot": "A0A5K4FGA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGA0"
  },
  {
    "uniprot": "G4LVS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVS0"
  },
  {
    "uniprot": "A0A5K4ECR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECR2"
  },
  {
    "uniprot": "A0A5K4ECX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECX9"
  },
  {
    "uniprot": "G4V850",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V850"
  },
  {
    "uniprot": "A0A3Q0KRZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRZ0"
  },
  {
    "uniprot": "A0A5K4F7A5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7A5"
  },
  {
    "uniprot": "A0A5K4EKS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKS9"
  },
  {
    "uniprot": "A0A3Q0KT44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT44"
  },
  {
    "uniprot": "A0A5K4EPR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPR5"
  },
  {
    "uniprot": "A0A3Q0KRB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRB6"
  },
  {
    "uniprot": "A0A5K4EW29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW29"
  },
  {
    "uniprot": "G4VS29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS29"
  },
  {
    "uniprot": "A0A3Q0KHU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHU4"
  },
  {
    "uniprot": "A0A5K4FCW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCW6"
  },
  {
    "uniprot": "A0A5K4EL80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL80"
  },
  {
    "uniprot": "A0A3Q0KR29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR29"
  },
  {
    "uniprot": "A0A5K4ECC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECC1"
  },
  {
    "uniprot": "A0A5K4EUU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUU6"
  },
  {
    "uniprot": "A0A3Q0KRQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRQ3"
  },
  {
    "uniprot": "G4VSW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSW3"
  },
  {
    "uniprot": "A0A3Q0KRL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRL2"
  },
  {
    "uniprot": "C4Q2F6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q2F6"
  },
  {
    "uniprot": "G4LXJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXJ8"
  },
  {
    "uniprot": "A0A5K4EMN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMN7"
  },
  {
    "uniprot": "A0A5K4F9K8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9K8"
  },
  {
    "uniprot": "A0A5K4F827",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F827"
  },
  {
    "uniprot": "G4VQH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQH5"
  },
  {
    "uniprot": "A0A3Q0KHZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHZ9"
  },
  {
    "uniprot": "A0A5K4FBK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBK7"
  },
  {
    "uniprot": "A0A5K4F2F6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2F6"
  },
  {
    "uniprot": "A0A5K4F769",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F769"
  },
  {
    "uniprot": "A0A5K4EQK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQK0"
  },
  {
    "uniprot": "A0A5K4EWL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWL8"
  },
  {
    "uniprot": "G4VAU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAU1"
  },
  {
    "uniprot": "A0A3Q0KQA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQA5"
  },
  {
    "uniprot": "A0A3Q0KIQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIQ2"
  },
  {
    "uniprot": "G4LXZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXZ2"
  },
  {
    "uniprot": "A0A5K4FCW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCW1"
  },
  {
    "uniprot": "A0A5K4EC33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EC33"
  },
  {
    "uniprot": "A0A5K4EHU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHU7"
  },
  {
    "uniprot": "A0A5K4FC68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC68"
  },
  {
    "uniprot": "A0A5K4ERU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERU8"
  },
  {
    "uniprot": "A0A3Q0KUN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUN8"
  },
  {
    "uniprot": "A0A3Q0KGY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGY5"
  },
  {
    "uniprot": "A0A5K4ERD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERD8"
  },
  {
    "uniprot": "A0A3Q0KPU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPU6"
  },
  {
    "uniprot": "G4LYJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYJ6"
  },
  {
    "uniprot": "A0A3Q0KVE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVE1"
  },
  {
    "uniprot": "A0A5K4F494",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F494"
  },
  {
    "uniprot": "G4V788",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V788"
  },
  {
    "uniprot": "A0A3Q0KDG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDG3"
  },
  {
    "uniprot": "A0A3Q0KMS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMS6"
  },
  {
    "uniprot": "A0A5K4F5P8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5P8"
  },
  {
    "uniprot": "G4LVE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVE7"
  },
  {
    "uniprot": "A0A5K4EDM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDM2"
  },
  {
    "uniprot": "G4VQX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQX9"
  },
  {
    "uniprot": "A0A5K4EAM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAM8"
  },
  {
    "uniprot": "G4VEK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEK3"
  },
  {
    "uniprot": "A0A3Q0KT08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT08"
  },
  {
    "uniprot": "A0A3Q0KBQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBQ4"
  },
  {
    "uniprot": "A0A3Q0KIU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIU1"
  },
  {
    "uniprot": "A0A5K4FAV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAV0"
  },
  {
    "uniprot": "A0A3Q0KK76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK76"
  },
  {
    "uniprot": "A0A5K4EDF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDF4"
  },
  {
    "uniprot": "A0A5K4FBX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBX4"
  },
  {
    "uniprot": "A0A3Q0KTN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTN7"
  },
  {
    "uniprot": "A0A3Q0KTW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTW9"
  },
  {
    "uniprot": "A0A5K4FBE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBE5"
  },
  {
    "uniprot": "A0A3Q0KN71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN71"
  },
  {
    "uniprot": "A0A5K4FDT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDT1"
  },
  {
    "uniprot": "A0A3Q0KCN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCN4"
  },
  {
    "uniprot": "A0A5K4FAZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAZ6"
  },
  {
    "uniprot": "A0A5K4F946",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F946"
  },
  {
    "uniprot": "A0A5K4EM80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM80"
  },
  {
    "uniprot": "A0A5K4EBA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBA7"
  },
  {
    "uniprot": "A0A3Q0KGJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGJ6"
  },
  {
    "uniprot": "G4VD53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD53"
  },
  {
    "uniprot": "A0A5K4EQG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQG2"
  },
  {
    "uniprot": "A0A5K4EU49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU49"
  },
  {
    "uniprot": "A0A146MJ81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MJ81"
  },
  {
    "uniprot": "G4VB67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB67"
  },
  {
    "uniprot": "G4LWC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWC0"
  },
  {
    "uniprot": "G4VJV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJV9"
  },
  {
    "uniprot": "A0A5K4FE77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE77"
  },
  {
    "uniprot": "A0A5K4F4C5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4C5"
  },
  {
    "uniprot": "A0A3Q0KH80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH80"
  },
  {
    "uniprot": "A0A3Q0KKH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKH7"
  },
  {
    "uniprot": "A0A3Q0KVF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVF0"
  },
  {
    "uniprot": "A0A3Q0KMY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMY9"
  },
  {
    "uniprot": "A0A5K4F386",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F386"
  },
  {
    "uniprot": "A0A5K4FD61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD61"
  },
  {
    "uniprot": "G4VTF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTF6"
  },
  {
    "uniprot": "G4VLV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLV8"
  },
  {
    "uniprot": "A0A5K4FAU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAU7"
  },
  {
    "uniprot": "A0A5K4F4Q4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Q4"
  },
  {
    "uniprot": "A0A3Q0KR02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR02"
  },
  {
    "uniprot": "A4K7Z0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A4K7Z0"
  },
  {
    "uniprot": "A0A5K4EGG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGG2"
  },
  {
    "uniprot": "G4V8T3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8T3"
  },
  {
    "uniprot": "A0A5K4FAY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAY6"
  },
  {
    "uniprot": "G4V8N2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8N2"
  },
  {
    "uniprot": "A0A3Q0KRX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRX8"
  },
  {
    "uniprot": "A0A5K4EH92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH92"
  },
  {
    "uniprot": "A0A5K4F3E3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3E3"
  },
  {
    "uniprot": "A0A3Q0KRY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRY5"
  },
  {
    "uniprot": "A0A5K4FDV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDV2"
  },
  {
    "uniprot": "G4V6Q1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6Q1"
  },
  {
    "uniprot": "A0A5K4F436",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F436"
  },
  {
    "uniprot": "G4LVW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVW2"
  },
  {
    "uniprot": "G4VDD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDD3"
  },
  {
    "uniprot": "A0A3Q0KU91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU91"
  },
  {
    "uniprot": "G4VIB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIB9"
  },
  {
    "uniprot": "A0A5K4F900",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F900"
  },
  {
    "uniprot": "A0A5K4F4N8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4N8"
  },
  {
    "uniprot": "Q15ET8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q15ET8"
  },
  {
    "uniprot": "Q26601",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26601"
  },
  {
    "uniprot": "A0A3Q0KK01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK01"
  },
  {
    "uniprot": "G4VNJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNJ1"
  },
  {
    "uniprot": "A0A5K4F711",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F711"
  },
  {
    "uniprot": "A0A5K4FBJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBJ4"
  },
  {
    "uniprot": "A0A3Q0KFF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFF9"
  },
  {
    "uniprot": "A0A5K4F6W6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6W6"
  },
  {
    "uniprot": "A0A5K4FCA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCA0"
  },
  {
    "uniprot": "A0A3Q0KT55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT55"
  },
  {
    "uniprot": "G4VJH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJH0"
  },
  {
    "uniprot": "A0A5K4F4P5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4P5"
  },
  {
    "uniprot": "G4V9F8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9F8"
  },
  {
    "uniprot": "G4VGY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGY6"
  },
  {
    "uniprot": "A0A3Q0KP01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP01"
  },
  {
    "uniprot": "A0A5K4F5K1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5K1"
  },
  {
    "uniprot": "A0A3Q0KNE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNE2"
  },
  {
    "uniprot": "G4VPC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPC5"
  },
  {
    "uniprot": "G4V655",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V655"
  },
  {
    "uniprot": "A0A3Q0KFP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFP7"
  },
  {
    "uniprot": "A0A3Q0KND6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KND6"
  },
  {
    "uniprot": "A0A3Q0KNG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNG5"
  },
  {
    "uniprot": "A0A5K4EFP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFP0"
  },
  {
    "uniprot": "A0A3Q0KTI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTI5"
  },
  {
    "uniprot": "A0A5K4EDU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDU1"
  },
  {
    "uniprot": "A0A5K4EYI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYI3"
  },
  {
    "uniprot": "A0A5K4FBN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBN8"
  },
  {
    "uniprot": "A0A3Q0KF48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF48"
  },
  {
    "uniprot": "A0A5K4F755",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F755"
  },
  {
    "uniprot": "A0A5K4FA57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA57"
  },
  {
    "uniprot": "A0A3Q0KPP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPP5"
  },
  {
    "uniprot": "A0A5K4FD28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD28"
  },
  {
    "uniprot": "A0A3Q0KTX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTX1"
  },
  {
    "uniprot": "A0A5K4FEI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEI4"
  },
  {
    "uniprot": "A0A5K4EX02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX02"
  },
  {
    "uniprot": "A0A3Q0KNP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNP0"
  },
  {
    "uniprot": "A0A5K4F4X5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4X5"
  },
  {
    "uniprot": "A0A5K4F5S9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5S9"
  },
  {
    "uniprot": "G4V771",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V771"
  },
  {
    "uniprot": "G4V7F4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7F4"
  },
  {
    "uniprot": "A0A3Q0KHF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHF0"
  },
  {
    "uniprot": "A0A5K4EFV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFV2"
  },
  {
    "uniprot": "A0A5K4FB80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB80"
  },
  {
    "uniprot": "G4VG80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VG80"
  },
  {
    "uniprot": "A0A3Q0KN62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN62"
  },
  {
    "uniprot": "G4VN62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VN62"
  },
  {
    "uniprot": "A0A5K4EQU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQU0"
  },
  {
    "uniprot": "A0A3Q0KS45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS45"
  },
  {
    "uniprot": "A0A5K4F0X0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0X0"
  },
  {
    "uniprot": "A0A5K4F8X7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8X7"
  },
  {
    "uniprot": "G4LZR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZR0"
  },
  {
    "uniprot": "A0A5K4ERB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERB9"
  },
  {
    "uniprot": "A0A3Q0KJX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJX6"
  },
  {
    "uniprot": "A0A5K4FAF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAF8"
  },
  {
    "uniprot": "A0A5K4ED91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED91"
  },
  {
    "uniprot": "A0A5K4F5X6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5X6"
  },
  {
    "uniprot": "A0A3Q0KM17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM17"
  },
  {
    "uniprot": "A0A5K4F0F3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0F3"
  },
  {
    "uniprot": "A0A5K4EZA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZA5"
  },
  {
    "uniprot": "A0A3Q0KLH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLH3"
  },
  {
    "uniprot": "A0A5K4F4I5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4I5"
  },
  {
    "uniprot": "G4VTK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTK4"
  },
  {
    "uniprot": "G4VFM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFM1"
  },
  {
    "uniprot": "A0A3Q0KSV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSV8"
  },
  {
    "uniprot": "A0A3Q0KMH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMH5"
  },
  {
    "uniprot": "A0A5K4FDQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDQ8"
  },
  {
    "uniprot": "A0A3Q0KR85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR85"
  },
  {
    "uniprot": "A0A5K4F0I8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0I8"
  },
  {
    "uniprot": "G4VN57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VN57"
  },
  {
    "uniprot": "A0A3Q0KM88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM88"
  },
  {
    "uniprot": "A0A5K4F789",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F789"
  },
  {
    "uniprot": "A0A5K4F1E1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1E1"
  },
  {
    "uniprot": "A0A5K4F9K1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9K1"
  },
  {
    "uniprot": "A0A3Q0KCB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCB7"
  },
  {
    "uniprot": "Q8T9N5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q8T9N5"
  },
  {
    "uniprot": "A0A5K4F2B2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2B2"
  },
  {
    "uniprot": "A0A5K4F854",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F854"
  },
  {
    "uniprot": "A0A5K4FC22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC22"
  },
  {
    "uniprot": "A0A3Q0KC96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC96"
  },
  {
    "uniprot": "A0A5K4FBZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBZ2"
  },
  {
    "uniprot": "A0A3Q0KD39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD39"
  },
  {
    "uniprot": "G4M1D8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1D8"
  },
  {
    "uniprot": "A0A5K4EQU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQU1"
  },
  {
    "uniprot": "A0A5K4F7X4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7X4"
  },
  {
    "uniprot": "A0A3Q0KRR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRR6"
  },
  {
    "uniprot": "A0A3Q0KNK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNK3"
  },
  {
    "uniprot": "A0A5K4F560",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F560"
  },
  {
    "uniprot": "A0A3Q0KC16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC16"
  },
  {
    "uniprot": "A0A3Q0KN22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN22"
  },
  {
    "uniprot": "A0A5K4F9W5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9W5"
  },
  {
    "uniprot": "A0A5K4F237",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F237"
  },
  {
    "uniprot": "A0A3Q0KR15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR15"
  },
  {
    "uniprot": "A0A5K4EWK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWK9"
  },
  {
    "uniprot": "A0A5K4ENW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENW3"
  },
  {
    "uniprot": "G4LXT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXT2"
  },
  {
    "uniprot": "A0A5K4F3M4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3M4"
  },
  {
    "uniprot": "A0A5K4FBY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBY2"
  },
  {
    "uniprot": "A0A5K4F555",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F555"
  },
  {
    "uniprot": "A0A5K4F647",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F647"
  },
  {
    "uniprot": "A0A3Q0KK32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK32"
  },
  {
    "uniprot": "A0A5K4FBL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBL0"
  },
  {
    "uniprot": "A0A3Q0KH70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH70"
  },
  {
    "uniprot": "A0A3Q0KD59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD59"
  },
  {
    "uniprot": "A0A3Q0KU15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU15"
  },
  {
    "uniprot": "A0A3Q0KRJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRJ4"
  },
  {
    "uniprot": "A0A5K4F1C2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1C2"
  },
  {
    "uniprot": "A0A3Q0KFS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFS9"
  },
  {
    "uniprot": "A0A5K4EEF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEF3"
  },
  {
    "uniprot": "A0A3Q0KSS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSS1"
  },
  {
    "uniprot": "A0A3Q0KNT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNT2"
  },
  {
    "uniprot": "C4Q7T4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q7T4"
  },
  {
    "uniprot": "A0A3Q0KK62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK62"
  },
  {
    "uniprot": "A0A3Q0KMY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMY2"
  },
  {
    "uniprot": "A0A5K4F3L5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3L5"
  },
  {
    "uniprot": "A0A3Q0KEI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEI7"
  },
  {
    "uniprot": "A0A5K4EAI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAI8"
  },
  {
    "uniprot": "G4VAS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAS8"
  },
  {
    "uniprot": "A0A3Q0KJE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJE8"
  },
  {
    "uniprot": "Q6RYS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q6RYS1"
  },
  {
    "uniprot": "G4VQK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQK1"
  },
  {
    "uniprot": "A0A5K4F1Q8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Q8"
  },
  {
    "uniprot": "A0A5K4FEI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEI5"
  },
  {
    "uniprot": "A0A3Q0KII0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KII0"
  },
  {
    "uniprot": "G4M0V9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0V9"
  },
  {
    "uniprot": "A0A5K4ERD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERD3"
  },
  {
    "uniprot": "A0A5K4F4P9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4P9"
  },
  {
    "uniprot": "G4VRR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRR5"
  },
  {
    "uniprot": "A0A3Q0KU52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU52"
  },
  {
    "uniprot": "A0A5K4F8Y7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Y7"
  },
  {
    "uniprot": "A0A5K4EII4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EII4"
  },
  {
    "uniprot": "A0A5K4F286",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F286"
  },
  {
    "uniprot": "A0A3Q0KLK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLK5"
  },
  {
    "uniprot": "A0A3Q0KPQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPQ2"
  },
  {
    "uniprot": "G4VNY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNY2"
  },
  {
    "uniprot": "G4VB14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB14"
  },
  {
    "uniprot": "A0A3Q0KM41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM41"
  },
  {
    "uniprot": "A0A5K4F976",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F976"
  },
  {
    "uniprot": "G4V9J2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9J2"
  },
  {
    "uniprot": "G4VJD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJD5"
  },
  {
    "uniprot": "A0A3Q0KMT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMT0"
  },
  {
    "uniprot": "A0A3Q0KEM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEM4"
  },
  {
    "uniprot": "G4VGI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGI8"
  },
  {
    "uniprot": "G4VML1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VML1"
  },
  {
    "uniprot": "G4VDL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDL0"
  },
  {
    "uniprot": "A0A3Q0KH45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH45"
  },
  {
    "uniprot": "A0A5K4F2V0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2V0"
  },
  {
    "uniprot": "A0A3Q0KFP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFP6"
  },
  {
    "uniprot": "A0A3Q0KI39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI39"
  },
  {
    "uniprot": "A0A3Q0KQS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQS6"
  },
  {
    "uniprot": "A0A5K4FCT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCT6"
  },
  {
    "uniprot": "A0A5K4F1B3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1B3"
  },
  {
    "uniprot": "G4V9B2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9B2"
  },
  {
    "uniprot": "G4LZQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZQ8"
  },
  {
    "uniprot": "A0A5K4EJM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJM5"
  },
  {
    "uniprot": "A0A5K4EY66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY66"
  },
  {
    "uniprot": "A0A5K4FGX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGX6"
  },
  {
    "uniprot": "A0A5K4F439",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F439"
  },
  {
    "uniprot": "A0A3Q0KRC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRC2"
  },
  {
    "uniprot": "A0A3Q0KMI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMI0"
  },
  {
    "uniprot": "A0A5K4F6L5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6L5"
  },
  {
    "uniprot": "A0A5K4F0N7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0N7"
  },
  {
    "uniprot": "A0A5K4FCT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCT3"
  },
  {
    "uniprot": "A0A3Q0KDW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDW9"
  },
  {
    "uniprot": "A0A3Q0KCD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCD7"
  },
  {
    "uniprot": "A0A5K4FCV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCV7"
  },
  {
    "uniprot": "A0A5K4FDW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDW7"
  },
  {
    "uniprot": "A0A5K4F5F9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5F9"
  },
  {
    "uniprot": "A0A5K4F0A7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0A7"
  },
  {
    "uniprot": "A0A3Q0KH08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH08"
  },
  {
    "uniprot": "P38658",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P38658"
  },
  {
    "uniprot": "A0A5K4EU38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU38"
  },
  {
    "uniprot": "A0A5K4F7F3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7F3"
  },
  {
    "uniprot": "A0A3Q0KNW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNW5"
  },
  {
    "uniprot": "A0A5K4EWB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWB2"
  },
  {
    "uniprot": "A0A5K4F6Y6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Y6"
  },
  {
    "uniprot": "A0A5K4FAF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAF9"
  },
  {
    "uniprot": "G4VTE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTE3"
  },
  {
    "uniprot": "A0A3Q0KCV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCV2"
  },
  {
    "uniprot": "A0A3Q0KKX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKX8"
  },
  {
    "uniprot": "G4VFW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFW1"
  },
  {
    "uniprot": "Q8I898",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q8I898"
  },
  {
    "uniprot": "A0A3Q0KEF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEF0"
  },
  {
    "uniprot": "G4VB23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB23"
  },
  {
    "uniprot": "A0A5K4F0F1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0F1"
  },
  {
    "uniprot": "A0A5K4F7U1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7U1"
  },
  {
    "uniprot": "G4V9U6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9U6"
  },
  {
    "uniprot": "A0A3Q0KPQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPQ3"
  },
  {
    "uniprot": "A0A5K4EWT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWT3"
  },
  {
    "uniprot": "A0A5K4FEB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEB0"
  },
  {
    "uniprot": "A0A3Q0KM37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM37"
  },
  {
    "uniprot": "A0A3Q0KN65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN65"
  },
  {
    "uniprot": "G4VBQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBQ2"
  },
  {
    "uniprot": "A0A5K4EJN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJN2"
  },
  {
    "uniprot": "A0A5K4ETZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETZ5"
  },
  {
    "uniprot": "G4V5R3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5R3"
  },
  {
    "uniprot": "A0A5K4ET10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET10"
  },
  {
    "uniprot": "G4VF32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF32"
  },
  {
    "uniprot": "A0A5K4F490",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F490"
  },
  {
    "uniprot": "A0A5K4F7G3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7G3"
  },
  {
    "uniprot": "A0A5K4F330",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F330"
  },
  {
    "uniprot": "G4LZB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZB1"
  },
  {
    "uniprot": "A0A5K4FBW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBW1"
  },
  {
    "uniprot": "G4VKK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKK8"
  },
  {
    "uniprot": "G4V910",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V910"
  },
  {
    "uniprot": "Q2MK71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q2MK71"
  },
  {
    "uniprot": "A0A3Q0KNL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNL7"
  },
  {
    "uniprot": "A0A3Q0KVL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVL7"
  },
  {
    "uniprot": "A0A5K4EP97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP97"
  },
  {
    "uniprot": "A0A5K4FBW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBW5"
  },
  {
    "uniprot": "A0A3Q0KFF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFF8"
  },
  {
    "uniprot": "A0A5K4EQ73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ73"
  },
  {
    "uniprot": "G4VSH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSH5"
  },
  {
    "uniprot": "A0A3Q0KN54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN54"
  },
  {
    "uniprot": "A0A3Q0KM53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM53"
  },
  {
    "uniprot": "G4VEY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEY1"
  },
  {
    "uniprot": "A0A5K4F623",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F623"
  },
  {
    "uniprot": "A0A5K4ET82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET82"
  },
  {
    "uniprot": "A0A3Q0KBA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBA8"
  },
  {
    "uniprot": "A0A5K4ERN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERN7"
  },
  {
    "uniprot": "A0A5K4EZ13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ13"
  },
  {
    "uniprot": "A0A5K4EGK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGK7"
  },
  {
    "uniprot": "A0A5K4F4Z4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Z4"
  },
  {
    "uniprot": "A0A5K4FBN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBN3"
  },
  {
    "uniprot": "A0A5K4F1N3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1N3"
  },
  {
    "uniprot": "A0A3Q0KUU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUU7"
  },
  {
    "uniprot": "A0A5K4EEL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEL7"
  },
  {
    "uniprot": "G4LW70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LW70"
  },
  {
    "uniprot": "A0A5K4EAY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAY8"
  },
  {
    "uniprot": "A0A5K4EJ70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJ70"
  },
  {
    "uniprot": "A0A5K4F8J8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8J8"
  },
  {
    "uniprot": "A0A3Q0KDD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDD5"
  },
  {
    "uniprot": "G4V6R6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6R6"
  },
  {
    "uniprot": "G4M0R1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0R1"
  },
  {
    "uniprot": "A0A3Q0KL63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL63"
  },
  {
    "uniprot": "G4VQS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQS2"
  },
  {
    "uniprot": "A0A5K4F858",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F858"
  },
  {
    "uniprot": "A0A3Q0KKN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKN2"
  },
  {
    "uniprot": "A0A3Q0KJT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJT2"
  },
  {
    "uniprot": "A0A3Q0KKW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKW3"
  },
  {
    "uniprot": "A0A5K4E9J7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9J7"
  },
  {
    "uniprot": "G4LVG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVG9"
  },
  {
    "uniprot": "A0A5K4F4I4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4I4"
  },
  {
    "uniprot": "G4VH78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH78"
  },
  {
    "uniprot": "G4VEX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEX6"
  },
  {
    "uniprot": "A0A5K4ELZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELZ0"
  },
  {
    "uniprot": "A0A5K4E8D1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8D1"
  },
  {
    "uniprot": "A0A5K4E980",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E980"
  },
  {
    "uniprot": "A0A3Q0KKF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKF6"
  },
  {
    "uniprot": "A0A3Q0KTC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTC2"
  },
  {
    "uniprot": "A0A5K4EUR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUR7"
  },
  {
    "uniprot": "A0A5K4FCN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCN8"
  },
  {
    "uniprot": "A0A5K4EN39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN39"
  },
  {
    "uniprot": "A0A3Q0KJQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJQ5"
  },
  {
    "uniprot": "G4VH02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH02"
  },
  {
    "uniprot": "A0A3Q0KVD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVD6"
  },
  {
    "uniprot": "A0A3Q0KPH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPH2"
  },
  {
    "uniprot": "A0A5K4F517",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F517"
  },
  {
    "uniprot": "G4VJX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJX3"
  },
  {
    "uniprot": "A0A5K4EKC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKC1"
  },
  {
    "uniprot": "G4VT02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT02"
  },
  {
    "uniprot": "A0A3Q0KLM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLM6"
  },
  {
    "uniprot": "G4VA29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA29"
  },
  {
    "uniprot": "A3RK86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A3RK86"
  },
  {
    "uniprot": "A0A5K4F2I1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2I1"
  },
  {
    "uniprot": "A0A5K4EWX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWX1"
  },
  {
    "uniprot": "G4LY35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY35"
  },
  {
    "uniprot": "G4VTJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTJ9"
  },
  {
    "uniprot": "A0A5K4EL66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL66"
  },
  {
    "uniprot": "A0A3Q0KRN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRN3"
  },
  {
    "uniprot": "A0A5K4F7S8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7S8"
  },
  {
    "uniprot": "A0A3Q0KIA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIA3"
  },
  {
    "uniprot": "A0A5K4F8F6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8F6"
  },
  {
    "uniprot": "A0A3Q0KQC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQC6"
  },
  {
    "uniprot": "A0A3Q0KQR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQR3"
  },
  {
    "uniprot": "G4VKU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKU3"
  },
  {
    "uniprot": "G4VME3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VME3"
  },
  {
    "uniprot": "A0A5K4FBP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBP0"
  },
  {
    "uniprot": "A0A3Q0KQP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQP6"
  },
  {
    "uniprot": "A0A5K4F3F6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3F6"
  },
  {
    "uniprot": "A0A5K4EZY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZY4"
  },
  {
    "uniprot": "A0A5K4FBJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBJ1"
  },
  {
    "uniprot": "A0A3Q0KTL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTL7"
  },
  {
    "uniprot": "A0A3Q0KGG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGG6"
  },
  {
    "uniprot": "A0A5K4FD52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD52"
  },
  {
    "uniprot": "A0A3Q0KJC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJC8"
  },
  {
    "uniprot": "A0A5K4FDI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDI2"
  },
  {
    "uniprot": "A0A5K4F7K4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7K4"
  },
  {
    "uniprot": "A0A3Q0KMB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMB7"
  },
  {
    "uniprot": "A0A5K4FFU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFU6"
  },
  {
    "uniprot": "A0A3Q0KNR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNR1"
  },
  {
    "uniprot": "A0A5K4FFB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFB5"
  },
  {
    "uniprot": "G4VHY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHY4"
  },
  {
    "uniprot": "A0A3Q0KDU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDU9"
  },
  {
    "uniprot": "G4M005",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M005"
  },
  {
    "uniprot": "A0A3Q0KNY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNY7"
  },
  {
    "uniprot": "A0A5K4F821",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F821"
  },
  {
    "uniprot": "A0A5K4EP57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP57"
  },
  {
    "uniprot": "A0A3Q0KPZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPZ8"
  },
  {
    "uniprot": "A0A3Q0KLQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLQ7"
  },
  {
    "uniprot": "P29498",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P29498"
  },
  {
    "uniprot": "G4VAB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAB3"
  },
  {
    "uniprot": "A0A3Q0KTR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTR5"
  },
  {
    "uniprot": "A0A5K4F865",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F865"
  },
  {
    "uniprot": "G4VBJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBJ7"
  },
  {
    "uniprot": "A0A3Q0KI07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI07"
  },
  {
    "uniprot": "A0A3Q0KEZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEZ9"
  },
  {
    "uniprot": "A0A3Q0KF87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF87"
  },
  {
    "uniprot": "A0A5K4FBJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBJ2"
  },
  {
    "uniprot": "A0A3Q0KRM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRM9"
  },
  {
    "uniprot": "A0A5K4FCT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCT2"
  },
  {
    "uniprot": "A0A3Q0KQX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQX2"
  },
  {
    "uniprot": "A0A3Q0KJH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJH8"
  },
  {
    "uniprot": "A0A3Q0KJM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJM9"
  },
  {
    "uniprot": "G4LYT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYT5"
  },
  {
    "uniprot": "A0A3Q0KCS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCS0"
  },
  {
    "uniprot": "A0A5K4F044",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F044"
  },
  {
    "uniprot": "A0A3Q0KMR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMR4"
  },
  {
    "uniprot": "C1M0Z0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C1M0Z0"
  },
  {
    "uniprot": "A0A5K4ET23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET23"
  },
  {
    "uniprot": "A0A5K4FAJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAJ2"
  },
  {
    "uniprot": "A0A3Q0KRU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRU2"
  },
  {
    "uniprot": "A0A3Q0KL31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL31"
  },
  {
    "uniprot": "A0A3Q0KR73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR73"
  },
  {
    "uniprot": "A0A5K4F962",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F962"
  },
  {
    "uniprot": "A0A5K4FET4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FET4"
  },
  {
    "uniprot": "A0A5K4FB24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB24"
  },
  {
    "uniprot": "A0A5K4ETN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETN4"
  },
  {
    "uniprot": "A0A5K4F2R3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2R3"
  },
  {
    "uniprot": "A0A5K4FAD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAD2"
  },
  {
    "uniprot": "A0A5K4FDW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDW9"
  },
  {
    "uniprot": "A0A5K4F0T0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0T0"
  },
  {
    "uniprot": "A0A5K4EXG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXG0"
  },
  {
    "uniprot": "A0A5K4F0Y6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Y6"
  },
  {
    "uniprot": "A0A5K4FCE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCE5"
  },
  {
    "uniprot": "A0A3Q0KVC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVC8"
  },
  {
    "uniprot": "G4VCR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCR6"
  },
  {
    "uniprot": "A0A5K4ES97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES97"
  },
  {
    "uniprot": "A0A5K4ECQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECQ7"
  },
  {
    "uniprot": "A0A5K4FA23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA23"
  },
  {
    "uniprot": "A0A146MJ75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MJ75"
  },
  {
    "uniprot": "A0A3Q0KS86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS86"
  },
  {
    "uniprot": "A0A3Q0KPR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPR0"
  },
  {
    "uniprot": "A0A5K4F4M0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4M0"
  },
  {
    "uniprot": "A0A5K4F9W4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9W4"
  },
  {
    "uniprot": "A0A3Q0KPX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPX5"
  },
  {
    "uniprot": "A0A3Q0KKB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKB8"
  },
  {
    "uniprot": "A0A3Q0KND2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KND2"
  },
  {
    "uniprot": "A0A3Q0KMJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMJ7"
  },
  {
    "uniprot": "A0A5K4FBT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBT4"
  },
  {
    "uniprot": "A0A5K4F8M4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8M4"
  },
  {
    "uniprot": "A0A5K4EZQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZQ5"
  },
  {
    "uniprot": "G4VQJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQJ4"
  },
  {
    "uniprot": "G4M014",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M014"
  },
  {
    "uniprot": "G4VKA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKA4"
  },
  {
    "uniprot": "A0A5K4F3S4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3S4"
  },
  {
    "uniprot": "A0A3Q0KRI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRI8"
  },
  {
    "uniprot": "A0A5K4FE83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE83"
  },
  {
    "uniprot": "G4LV64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV64"
  },
  {
    "uniprot": "C4Q500",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q500"
  },
  {
    "uniprot": "A0A5K4EZX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZX2"
  },
  {
    "uniprot": "A0A5K4ESW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESW9"
  },
  {
    "uniprot": "A0A3Q0KMX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMX8"
  },
  {
    "uniprot": "A0A3Q0KUJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUJ5"
  },
  {
    "uniprot": "A0A5K4F1W4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1W4"
  },
  {
    "uniprot": "A0A5K4ET69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET69"
  },
  {
    "uniprot": "A0A3Q0KGN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGN7"
  },
  {
    "uniprot": "A0A5K4F5W3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5W3"
  },
  {
    "uniprot": "A0A5K4EWY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWY1"
  },
  {
    "uniprot": "G4LWD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWD7"
  },
  {
    "uniprot": "A0A3Q0KHY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHY5"
  },
  {
    "uniprot": "A0A5K4F4Q6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Q6"
  },
  {
    "uniprot": "A0A5K4F9Y1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Y1"
  },
  {
    "uniprot": "A0A5K4F8E0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8E0"
  },
  {
    "uniprot": "A0A3Q0KUD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUD4"
  },
  {
    "uniprot": "A0A5K4EX40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX40"
  },
  {
    "uniprot": "A0A3Q0KFG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFG7"
  },
  {
    "uniprot": "A0A5K4F601",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F601"
  },
  {
    "uniprot": "A0A5K4F7L3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7L3"
  },
  {
    "uniprot": "A0A3Q0KIV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIV8"
  },
  {
    "uniprot": "A0A5K4EME1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EME1"
  },
  {
    "uniprot": "A0A5K4EWM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWM0"
  },
  {
    "uniprot": "G4VJV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJV3"
  },
  {
    "uniprot": "A0A5K4F023",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F023"
  },
  {
    "uniprot": "A0A3Q0KMZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMZ7"
  },
  {
    "uniprot": "A0A3Q0KTK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTK4"
  },
  {
    "uniprot": "A0A5K4FCV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCV4"
  },
  {
    "uniprot": "A0A3Q0KSX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSX8"
  },
  {
    "uniprot": "A0A3Q0KFT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFT6"
  },
  {
    "uniprot": "G4VTQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTQ2"
  },
  {
    "uniprot": "A0A5K4F7C9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7C9"
  },
  {
    "uniprot": "A0A3Q0KTT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTT7"
  },
  {
    "uniprot": "A0A5K4EDG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDG0"
  },
  {
    "uniprot": "A0A3Q0KTI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTI1"
  },
  {
    "uniprot": "A0A5K4F2X1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2X1"
  },
  {
    "uniprot": "A0A3Q0KSE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSE7"
  },
  {
    "uniprot": "A0A5K4ESG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESG1"
  },
  {
    "uniprot": "A0A5K4EDU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDU0"
  },
  {
    "uniprot": "A0A5K4EMQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMQ7"
  },
  {
    "uniprot": "A0A3Q0KRN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRN6"
  },
  {
    "uniprot": "G4VQ47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ47"
  },
  {
    "uniprot": "A0A5K4F0B4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0B4"
  },
  {
    "uniprot": "A0A5K4F6S0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6S0"
  },
  {
    "uniprot": "A0A3Q0KKB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKB0"
  },
  {
    "uniprot": "A0A5K4FC54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC54"
  },
  {
    "uniprot": "A0A3Q0KBA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBA9"
  },
  {
    "uniprot": "A0A5K4F650",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F650"
  },
  {
    "uniprot": "A0A3Q0KJM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJM2"
  },
  {
    "uniprot": "A0A5K4EQC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQC7"
  },
  {
    "uniprot": "A0A5K4FAZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAZ3"
  },
  {
    "uniprot": "A0A3Q0KC76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC76"
  },
  {
    "uniprot": "A0A5K4F1X7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1X7"
  },
  {
    "uniprot": "G4M0D7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0D7"
  },
  {
    "uniprot": "A0A3Q0KPJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPJ3"
  },
  {
    "uniprot": "A0A5K4FAH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAH9"
  },
  {
    "uniprot": "A0A5K4F6X8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6X8"
  },
  {
    "uniprot": "A0A5K4F6Y1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Y1"
  },
  {
    "uniprot": "A0A5K4F8T6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8T6"
  },
  {
    "uniprot": "A0A5K4FA63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA63"
  },
  {
    "uniprot": "G4V6X1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6X1"
  },
  {
    "uniprot": "A0A5K4EZQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZQ1"
  },
  {
    "uniprot": "A0A5K4FDI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDI9"
  },
  {
    "uniprot": "A0A3Q0KPW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPW4"
  },
  {
    "uniprot": "A0A5K4F918",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F918"
  },
  {
    "uniprot": "A0A3Q0KH21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH21"
  },
  {
    "uniprot": "A0A5K4F8R2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8R2"
  },
  {
    "uniprot": "A0A5K4EN21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN21"
  },
  {
    "uniprot": "A0A3Q0KKY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKY7"
  },
  {
    "uniprot": "C4QMW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QMW5"
  },
  {
    "uniprot": "A0A5K4FCB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCB7"
  },
  {
    "uniprot": "A0A5K4F679",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F679"
  },
  {
    "uniprot": "A0A5K4FED2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FED2"
  },
  {
    "uniprot": "A0A3Q0KQ08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ08"
  },
  {
    "uniprot": "A0A5K4EM24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM24"
  },
  {
    "uniprot": "G4M1E2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1E2"
  },
  {
    "uniprot": "A0A5K4F9N5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9N5"
  },
  {
    "uniprot": "A0A5K4F5T0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5T0"
  },
  {
    "uniprot": "A0A5K4EPI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPI1"
  },
  {
    "uniprot": "A0A5K4FCD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCD0"
  },
  {
    "uniprot": "A0A5K4E821",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E821"
  },
  {
    "uniprot": "G4LUU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUU2"
  },
  {
    "uniprot": "G4VEL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEL9"
  },
  {
    "uniprot": "A0A3Q0KLP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLP2"
  },
  {
    "uniprot": "A0A3Q0KPV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPV9"
  },
  {
    "uniprot": "A0A5K4FD66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD66"
  },
  {
    "uniprot": "A0A5K4F8X0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8X0"
  },
  {
    "uniprot": "A0A5K4ELK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELK6"
  },
  {
    "uniprot": "G4V6G5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6G5"
  },
  {
    "uniprot": "A0A5K4FAI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAI5"
  },
  {
    "uniprot": "A0A5K4EGE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGE2"
  },
  {
    "uniprot": "A0A5K4FE95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE95"
  },
  {
    "uniprot": "A0A5K4F9P6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9P6"
  },
  {
    "uniprot": "A0A5K4F5F3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5F3"
  },
  {
    "uniprot": "A0A5K4EF39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EF39"
  },
  {
    "uniprot": "G4VS24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS24"
  },
  {
    "uniprot": "A0A3Q0KJ41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ41"
  },
  {
    "uniprot": "G4VRY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRY2"
  },
  {
    "uniprot": "A0A3Q0KTC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTC9"
  },
  {
    "uniprot": "A0A3Q0KQZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQZ3"
  },
  {
    "uniprot": "A0A5K4F2Q8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Q8"
  },
  {
    "uniprot": "A0A5K4F2C5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2C5"
  },
  {
    "uniprot": "A0A5K4ERW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERW1"
  },
  {
    "uniprot": "G4LUH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUH0"
  },
  {
    "uniprot": "A0A3Q0KNA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNA9"
  },
  {
    "uniprot": "A0A3Q0KLL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLL8"
  },
  {
    "uniprot": "A0A3Q0KLD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLD1"
  },
  {
    "uniprot": "A0A5K4EKK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKK7"
  },
  {
    "uniprot": "G4LUC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUC7"
  },
  {
    "uniprot": "A0A3Q0KRN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRN8"
  },
  {
    "uniprot": "G4VSC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSC6"
  },
  {
    "uniprot": "G4VIP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIP4"
  },
  {
    "uniprot": "A0A5K4F5A5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5A5"
  },
  {
    "uniprot": "Q9Y0D3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q9Y0D3"
  },
  {
    "uniprot": "A0A3Q0KQX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQX4"
  },
  {
    "uniprot": "A0A5K4FEK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEK7"
  },
  {
    "uniprot": "A0A5K4FF55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF55"
  },
  {
    "uniprot": "G4VL86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VL86"
  },
  {
    "uniprot": "A0A5K4FH52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FH52"
  },
  {
    "uniprot": "A0A3Q0KKU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKU0"
  },
  {
    "uniprot": "A0A3Q0KVG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVG9"
  },
  {
    "uniprot": "G4LYH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYH7"
  },
  {
    "uniprot": "A0A3Q0KJG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJG9"
  },
  {
    "uniprot": "A0A5K4F1P5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1P5"
  },
  {
    "uniprot": "A0A3Q0KR98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR98"
  },
  {
    "uniprot": "A0A5K4F5X4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5X4"
  },
  {
    "uniprot": "A0A5K4F8C7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8C7"
  },
  {
    "uniprot": "A0A5K4EX16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX16"
  },
  {
    "uniprot": "A0A5K4F1W6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1W6"
  },
  {
    "uniprot": "A0A3Q0KQE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQE2"
  },
  {
    "uniprot": "G4LUN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUN6"
  },
  {
    "uniprot": "G4VQ97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ97"
  },
  {
    "uniprot": "A0A5K4F1P8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1P8"
  },
  {
    "uniprot": "A0A3Q0KFX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFX6"
  },
  {
    "uniprot": "G4VSU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSU7"
  },
  {
    "uniprot": "G4M0A6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0A6"
  },
  {
    "uniprot": "G4VGZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGZ1"
  },
  {
    "uniprot": "A0A5K4EYB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYB5"
  },
  {
    "uniprot": "A0A5K4EZ79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ79"
  },
  {
    "uniprot": "A0A5K4EEF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEF6"
  },
  {
    "uniprot": "A0A3Q0KK08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK08"
  },
  {
    "uniprot": "A0A3Q0KT16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT16"
  },
  {
    "uniprot": "A0A3Q0KKZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKZ8"
  },
  {
    "uniprot": "G4V7W5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7W5"
  },
  {
    "uniprot": "A0A5K4F6F0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6F0"
  },
  {
    "uniprot": "A0A3Q0KNX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNX2"
  },
  {
    "uniprot": "G4V7D8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7D8"
  },
  {
    "uniprot": "A0A5K4FB37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB37"
  },
  {
    "uniprot": "A0A5K4FH78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FH78"
  },
  {
    "uniprot": "A0A3Q0KQW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQW5"
  },
  {
    "uniprot": "A0A5K4FD30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD30"
  },
  {
    "uniprot": "A0A5K4F2J4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2J4"
  },
  {
    "uniprot": "A0A5K4EMI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMI1"
  },
  {
    "uniprot": "A0A5K4ERY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERY2"
  },
  {
    "uniprot": "A0A5K4FDN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDN1"
  },
  {
    "uniprot": "A0A3Q0KSU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSU5"
  },
  {
    "uniprot": "G4VBB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBB1"
  },
  {
    "uniprot": "G4VBW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBW2"
  },
  {
    "uniprot": "A0A5K4EQI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQI7"
  },
  {
    "uniprot": "A0A5K4FFH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFH1"
  },
  {
    "uniprot": "G4VLU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLU1"
  },
  {
    "uniprot": "G4VBX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBX5"
  },
  {
    "uniprot": "A9NI53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A9NI53"
  },
  {
    "uniprot": "G4V7E7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7E7"
  },
  {
    "uniprot": "A0A3Q0KPK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPK2"
  },
  {
    "uniprot": "A0A5K4FAR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAR0"
  },
  {
    "uniprot": "G4LWW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWW9"
  },
  {
    "uniprot": "A0A5K4F8M8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8M8"
  },
  {
    "uniprot": "A0A3Q0KM86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM86"
  },
  {
    "uniprot": "A0A5K4FDI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDI0"
  },
  {
    "uniprot": "A0A5K4FC46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC46"
  },
  {
    "uniprot": "A0A3Q0KKA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKA2"
  },
  {
    "uniprot": "A0A5K4F7P9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7P9"
  },
  {
    "uniprot": "G4VD08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD08"
  },
  {
    "uniprot": "G4V5D2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5D2"
  },
  {
    "uniprot": "A0A5K4F033",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F033"
  },
  {
    "uniprot": "G4V8S5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8S5"
  },
  {
    "uniprot": "A0A3Q0KQ02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ02"
  },
  {
    "uniprot": "A0A3Q0KD57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD57"
  },
  {
    "uniprot": "A0A5K4EQG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQG8"
  },
  {
    "uniprot": "A0A3Q0KSI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSI8"
  },
  {
    "uniprot": "A0A3Q0KHU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHU5"
  },
  {
    "uniprot": "A0A5K4F4J4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4J4"
  },
  {
    "uniprot": "A0A3Q0KQF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQF9"
  },
  {
    "uniprot": "G4VH46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH46"
  },
  {
    "uniprot": "A0A5K4F3K7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3K7"
  },
  {
    "uniprot": "A0A3Q0KID0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KID0"
  },
  {
    "uniprot": "G4LWH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWH9"
  },
  {
    "uniprot": "A0A5K4F1W2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1W2"
  },
  {
    "uniprot": "A0A5K4F5Y2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Y2"
  },
  {
    "uniprot": "A0A3Q0KP43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP43"
  },
  {
    "uniprot": "A0A3Q0KMU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMU5"
  },
  {
    "uniprot": "A0A5K4EK31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK31"
  },
  {
    "uniprot": "A0A3Q0KCW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCW4"
  },
  {
    "uniprot": "A0A5K4F781",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F781"
  },
  {
    "uniprot": "A0A3Q0KIU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIU8"
  },
  {
    "uniprot": "G4VD51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD51"
  },
  {
    "uniprot": "A0A3Q0KK24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK24"
  },
  {
    "uniprot": "A0A3Q0KP48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP48"
  },
  {
    "uniprot": "A0A3Q0KVJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVJ2"
  },
  {
    "uniprot": "G4VHW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHW0"
  },
  {
    "uniprot": "A0A3Q0KTG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTG1"
  },
  {
    "uniprot": "A0A3Q0KI13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI13"
  },
  {
    "uniprot": "A0A3Q0KTJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTJ8"
  },
  {
    "uniprot": "A0A5K4EWE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWE0"
  },
  {
    "uniprot": "A0A5K4F7Z6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Z6"
  },
  {
    "uniprot": "A0A5K4FC35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC35"
  },
  {
    "uniprot": "A0A3Q0KUL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUL8"
  },
  {
    "uniprot": "A0A3Q0KJ87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ87"
  },
  {
    "uniprot": "A0A3Q0KPR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPR5"
  },
  {
    "uniprot": "A0A3Q0KIE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIE8"
  },
  {
    "uniprot": "A0A5K4F4M1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4M1"
  },
  {
    "uniprot": "A0A3Q0KI05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI05"
  },
  {
    "uniprot": "A0A3Q0KS22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS22"
  },
  {
    "uniprot": "A0A5K4F851",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F851"
  },
  {
    "uniprot": "G4VPZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPZ0"
  },
  {
    "uniprot": "A0A5K4F898",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F898"
  },
  {
    "uniprot": "Q27236",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q27236"
  },
  {
    "uniprot": "A0A5K4F696",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F696"
  },
  {
    "uniprot": "A0A5K4F7R9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7R9"
  },
  {
    "uniprot": "A0A5K4FD68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD68"
  },
  {
    "uniprot": "A0A5K4ES41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES41"
  },
  {
    "uniprot": "A0A3Q0KKM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKM0"
  },
  {
    "uniprot": "A0A5K4F3D2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3D2"
  },
  {
    "uniprot": "A0A5K4ENB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENB7"
  },
  {
    "uniprot": "G4VJX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJX8"
  },
  {
    "uniprot": "A0A3Q0KP72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP72"
  },
  {
    "uniprot": "G4LVD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVD9"
  },
  {
    "uniprot": "A0A5K4F1B6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1B6"
  },
  {
    "uniprot": "A0A3Q0KFX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFX5"
  },
  {
    "uniprot": "A0A5K4F8W8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8W8"
  },
  {
    "uniprot": "G4VPB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPB3"
  },
  {
    "uniprot": "A0A3Q0KKG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKG4"
  },
  {
    "uniprot": "A0A3Q0KTD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTD9"
  },
  {
    "uniprot": "G4LVG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVG4"
  },
  {
    "uniprot": "G4VIU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIU0"
  },
  {
    "uniprot": "G4LVS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVS5"
  },
  {
    "uniprot": "A0A3Q0KC14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC14"
  },
  {
    "uniprot": "G4VN30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VN30"
  },
  {
    "uniprot": "A0A3Q0KUH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUH5"
  },
  {
    "uniprot": "A0A5K4FCA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCA2"
  },
  {
    "uniprot": "A0A3Q0KM01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM01"
  },
  {
    "uniprot": "A0A5K4EVX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVX0"
  },
  {
    "uniprot": "A0A5K4FAV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAV6"
  },
  {
    "uniprot": "A0A5K4EZI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZI3"
  },
  {
    "uniprot": "A0A3Q0KNY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNY1"
  },
  {
    "uniprot": "A0A3Q0KU41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU41"
  },
  {
    "uniprot": "A0A5K4F232",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F232"
  },
  {
    "uniprot": "A0A3Q0KFS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFS0"
  },
  {
    "uniprot": "G4VM35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM35"
  },
  {
    "uniprot": "A0A5K4END2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4END2"
  },
  {
    "uniprot": "A0A5K4EJX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJX1"
  },
  {
    "uniprot": "A0A3Q0KS87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS87"
  },
  {
    "uniprot": "A0A5K4F9C5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9C5"
  },
  {
    "uniprot": "A0A5K4F929",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F929"
  },
  {
    "uniprot": "G4VC17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC17"
  },
  {
    "uniprot": "A0A5K4ELC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELC6"
  },
  {
    "uniprot": "A0A5K4F3E8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3E8"
  },
  {
    "uniprot": "A0A5K4EKV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKV7"
  },
  {
    "uniprot": "A0A3Q0KQ31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ31"
  },
  {
    "uniprot": "A0A5K4FEI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEI9"
  },
  {
    "uniprot": "C4QJB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QJB5"
  },
  {
    "uniprot": "A0A5K4F9G5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9G5"
  },
  {
    "uniprot": "A0A5K4F6I3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6I3"
  },
  {
    "uniprot": "A0A5K4EH65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH65"
  },
  {
    "uniprot": "A0A5K4F5A2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5A2"
  },
  {
    "uniprot": "A0A5K4F3F8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3F8"
  },
  {
    "uniprot": "A0A5K4FCP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCP5"
  },
  {
    "uniprot": "A0A5K4F725",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F725"
  },
  {
    "uniprot": "A0A3Q0KSK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSK9"
  },
  {
    "uniprot": "A0A5K4FCZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCZ9"
  },
  {
    "uniprot": "A0A5K4FBF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBF1"
  },
  {
    "uniprot": "A0A3Q0KCY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCY9"
  },
  {
    "uniprot": "A0A3Q0KSD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSD0"
  },
  {
    "uniprot": "G4LV21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV21"
  },
  {
    "uniprot": "A0A5K4FCK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCK6"
  },
  {
    "uniprot": "A0A3Q0KSZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSZ8"
  },
  {
    "uniprot": "A0A3Q0KI24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI24"
  },
  {
    "uniprot": "G4V6G6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6G6"
  },
  {
    "uniprot": "G4VL76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VL76"
  },
  {
    "uniprot": "A0A5K4EPW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPW5"
  },
  {
    "uniprot": "G4VLE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLE5"
  },
  {
    "uniprot": "G4VAW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAW0"
  },
  {
    "uniprot": "A0A5K4EID0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EID0"
  },
  {
    "uniprot": "A0A3Q0KR89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR89"
  },
  {
    "uniprot": "G4V628",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V628"
  },
  {
    "uniprot": "A0A5K4EYD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYD9"
  },
  {
    "uniprot": "A0A5K4F7G2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7G2"
  },
  {
    "uniprot": "A0A5K4EPG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPG4"
  },
  {
    "uniprot": "A0A3Q0KBB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBB9"
  },
  {
    "uniprot": "A0A3Q0KTI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTI3"
  },
  {
    "uniprot": "A0A5K4F3M1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3M1"
  },
  {
    "uniprot": "A0A3Q0KM46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM46"
  },
  {
    "uniprot": "A0A3Q0KG70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG70"
  },
  {
    "uniprot": "A0A3Q0KTP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTP3"
  },
  {
    "uniprot": "A0A3Q0KNU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNU8"
  },
  {
    "uniprot": "G4VS85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS85"
  },
  {
    "uniprot": "A0A5K4EEM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEM1"
  },
  {
    "uniprot": "A0A3Q0KPI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPI7"
  },
  {
    "uniprot": "A0A5K4EYI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYI0"
  },
  {
    "uniprot": "A0A3Q0KQG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQG9"
  },
  {
    "uniprot": "A0A5K4F8A0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8A0"
  },
  {
    "uniprot": "A0A3Q0KEB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEB6"
  },
  {
    "uniprot": "G4V7L9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7L9"
  },
  {
    "uniprot": "A0A5K4EJI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJI9"
  },
  {
    "uniprot": "G4VAK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAK6"
  },
  {
    "uniprot": "A0A5K4F127",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F127"
  },
  {
    "uniprot": "A0A3Q0KD96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD96"
  },
  {
    "uniprot": "A0A5K4EUM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUM5"
  },
  {
    "uniprot": "G4V9Y5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9Y5"
  },
  {
    "uniprot": "A0A5K4F4P7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4P7"
  },
  {
    "uniprot": "G4VPP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPP9"
  },
  {
    "uniprot": "A0A5K4FBX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBX7"
  },
  {
    "uniprot": "A0A5K4FBX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBX8"
  },
  {
    "uniprot": "A0A3Q0KQ43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ43"
  },
  {
    "uniprot": "A0A5K4EJR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJR4"
  },
  {
    "uniprot": "G4VFB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFB1"
  },
  {
    "uniprot": "A0A5K4E905",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E905"
  },
  {
    "uniprot": "A0A5K4F2D5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2D5"
  },
  {
    "uniprot": "A0A3Q0KP75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP75"
  },
  {
    "uniprot": "A0A5K4F3C2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3C2"
  },
  {
    "uniprot": "A0A5K4F9C8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9C8"
  },
  {
    "uniprot": "G4V775",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V775"
  },
  {
    "uniprot": "A0A5K4FB43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB43"
  },
  {
    "uniprot": "G4VB00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB00"
  },
  {
    "uniprot": "A0A5K4ERP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERP9"
  },
  {
    "uniprot": "A0A3Q0KMU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMU0"
  },
  {
    "uniprot": "A0A2H4UCT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A2H4UCT2"
  },
  {
    "uniprot": "A0A3Q0KDS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDS2"
  },
  {
    "uniprot": "C4Q4S5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q4S5"
  },
  {
    "uniprot": "A0A3Q0KRI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRI9"
  },
  {
    "uniprot": "A0A5K4F273",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F273"
  },
  {
    "uniprot": "A0A3Q0KGC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGC1"
  },
  {
    "uniprot": "A0A5K4F0P6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0P6"
  },
  {
    "uniprot": "A0A3Q0KKT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKT3"
  },
  {
    "uniprot": "A0A5K4F1V0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1V0"
  },
  {
    "uniprot": "G4VD11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD11"
  },
  {
    "uniprot": "A0A3Q0KLU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLU7"
  },
  {
    "uniprot": "A0A5K4EQS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQS2"
  },
  {
    "uniprot": "A0A5K4EVT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVT3"
  },
  {
    "uniprot": "C4PYP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4PYP8"
  },
  {
    "uniprot": "A0A5K4F8C2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8C2"
  },
  {
    "uniprot": "A0A5K4F6S1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6S1"
  },
  {
    "uniprot": "A0A5K4FB67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB67"
  },
  {
    "uniprot": "A0A3Q0KPA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPA9"
  },
  {
    "uniprot": "G4VQL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQL3"
  },
  {
    "uniprot": "Q95W36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q95W36"
  },
  {
    "uniprot": "A0A5K4F2A9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2A9"
  },
  {
    "uniprot": "A0A5K4FBG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBG8"
  },
  {
    "uniprot": "A0A5K4FCM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCM9"
  },
  {
    "uniprot": "A0A3Q0KP32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP32"
  },
  {
    "uniprot": "G4VKR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKR1"
  },
  {
    "uniprot": "A0A3Q0KT60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT60"
  },
  {
    "uniprot": "A0A3Q0KTE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTE8"
  },
  {
    "uniprot": "G4VS44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS44"
  },
  {
    "uniprot": "A0A5K4F396",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F396"
  },
  {
    "uniprot": "A0A3Q0KMP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMP0"
  },
  {
    "uniprot": "G4VDK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDK0"
  },
  {
    "uniprot": "G4V8U3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8U3"
  },
  {
    "uniprot": "A0A3Q0KPF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPF6"
  },
  {
    "uniprot": "A0A5K4F4D4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4D4"
  },
  {
    "uniprot": "A0A5K4F4B9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4B9"
  },
  {
    "uniprot": "A0A5K4FDH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDH3"
  },
  {
    "uniprot": "A0A3Q0KQK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQK0"
  },
  {
    "uniprot": "G4VBU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBU4"
  },
  {
    "uniprot": "A0A5K4F8I6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8I6"
  },
  {
    "uniprot": "G4VCI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCI2"
  },
  {
    "uniprot": "A0A5K4EPJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPJ7"
  },
  {
    "uniprot": "G4VRX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRX2"
  },
  {
    "uniprot": "G4VD59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD59"
  },
  {
    "uniprot": "A0A5K4F1K8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1K8"
  },
  {
    "uniprot": "A0A5K4EPW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPW1"
  },
  {
    "uniprot": "A0A3Q0KUU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUU2"
  },
  {
    "uniprot": "A0A3Q0KS63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS63"
  },
  {
    "uniprot": "A0A3Q0KUG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUG5"
  },
  {
    "uniprot": "G4VFZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFZ6"
  },
  {
    "uniprot": "A0A5K4F8U7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8U7"
  },
  {
    "uniprot": "A0A5K4E9R9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9R9"
  },
  {
    "uniprot": "C1M0L0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C1M0L0"
  },
  {
    "uniprot": "A0A3Q0KIL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIL5"
  },
  {
    "uniprot": "G4VKS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKS3"
  },
  {
    "uniprot": "A0A5K4FAP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAP4"
  },
  {
    "uniprot": "A0A5K4F9D8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9D8"
  },
  {
    "uniprot": "A0A5K4ESE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESE6"
  },
  {
    "uniprot": "G4V7H8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7H8"
  },
  {
    "uniprot": "A0A3Q0KLT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLT2"
  },
  {
    "uniprot": "A0A3Q0KJC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJC9"
  },
  {
    "uniprot": "A0A5K4EZ29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ29"
  },
  {
    "uniprot": "A0A3Q0KRF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRF0"
  },
  {
    "uniprot": "A0A3Q0KRM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRM7"
  },
  {
    "uniprot": "A0A5K4EY43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY43"
  },
  {
    "uniprot": "A0A3Q0KPU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPU4"
  },
  {
    "uniprot": "G4VAQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAQ6"
  },
  {
    "uniprot": "A0A5K4EUQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUQ5"
  },
  {
    "uniprot": "A0A5K4FBV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBV9"
  },
  {
    "uniprot": "G4VE77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VE77"
  },
  {
    "uniprot": "A0A5K4EY18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY18"
  },
  {
    "uniprot": "A0A3Q0KEX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEX9"
  },
  {
    "uniprot": "A0A3Q0KT40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT40"
  },
  {
    "uniprot": "A0A5K4F196",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F196"
  },
  {
    "uniprot": "G4VK46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK46"
  },
  {
    "uniprot": "A0A5K4EC53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EC53"
  },
  {
    "uniprot": "A0A3Q0KN35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN35"
  },
  {
    "uniprot": "G4VIU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIU5"
  },
  {
    "uniprot": "A0A5K4F3J4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3J4"
  },
  {
    "uniprot": "A0A5K4FAN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAN2"
  },
  {
    "uniprot": "G4VA23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA23"
  },
  {
    "uniprot": "A0A5K4ESE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESE2"
  },
  {
    "uniprot": "A0A3Q0KDC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDC4"
  },
  {
    "uniprot": "G4VRY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRY5"
  },
  {
    "uniprot": "A0A3Q0KKI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKI0"
  },
  {
    "uniprot": "G4VAC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAC0"
  },
  {
    "uniprot": "A0A5K4F269",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F269"
  },
  {
    "uniprot": "A0A5K4F0G0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0G0"
  },
  {
    "uniprot": "A0A5K4EV41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV41"
  },
  {
    "uniprot": "Q26597",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26597"
  },
  {
    "uniprot": "A0A5K4E9E6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9E6"
  },
  {
    "uniprot": "A0A5K4F5C6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5C6"
  },
  {
    "uniprot": "A0A3Q0KTG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTG5"
  },
  {
    "uniprot": "A0A3Q0KPW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPW2"
  },
  {
    "uniprot": "A0A5K4F288",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F288"
  },
  {
    "uniprot": "A0A5K4FCS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCS4"
  },
  {
    "uniprot": "A0A3Q0KD34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD34"
  },
  {
    "uniprot": "G4LVI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVI8"
  },
  {
    "uniprot": "A0A5K4F4J2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4J2"
  },
  {
    "uniprot": "A0A5K4FBI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBI9"
  },
  {
    "uniprot": "G4VQ76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ76"
  },
  {
    "uniprot": "G4VLH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLH7"
  },
  {
    "uniprot": "A0A5K4F5G1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5G1"
  },
  {
    "uniprot": "G4V7P1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7P1"
  },
  {
    "uniprot": "G4VFB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFB4"
  },
  {
    "uniprot": "A0A5K4EKR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKR2"
  },
  {
    "uniprot": "A0A3Q0KS00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS00"
  },
  {
    "uniprot": "A0A5K4F576",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F576"
  },
  {
    "uniprot": "A0A5K4F4A5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4A5"
  },
  {
    "uniprot": "A0A3Q0KU37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU37"
  },
  {
    "uniprot": "C4PYV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4PYV9"
  },
  {
    "uniprot": "A0A5K4F893",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F893"
  },
  {
    "uniprot": "G4VKX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKX8"
  },
  {
    "uniprot": "A0A5K4F6A5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6A5"
  },
  {
    "uniprot": "A0A5K4EP37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP37"
  },
  {
    "uniprot": "A0A5K4F9P2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9P2"
  },
  {
    "uniprot": "A0A3Q0KTD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTD2"
  },
  {
    "uniprot": "A0A3Q0KIK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIK8"
  },
  {
    "uniprot": "A0A3Q0KS90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS90"
  },
  {
    "uniprot": "A0A5K4FEJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEJ0"
  },
  {
    "uniprot": "G4VRF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRF8"
  },
  {
    "uniprot": "A0A3Q0KJR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJR2"
  },
  {
    "uniprot": "A0A3Q0KV98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV98"
  },
  {
    "uniprot": "A0A5K4FBI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBI8"
  },
  {
    "uniprot": "A0A5K4F5Q2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Q2"
  },
  {
    "uniprot": "A0A5K4F6Q8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Q8"
  },
  {
    "uniprot": "A0A3Q0KCJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCJ1"
  },
  {
    "uniprot": "A0A3Q0KL52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL52"
  },
  {
    "uniprot": "A0A5K4F0Y8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Y8"
  },
  {
    "uniprot": "A0A5K4EN91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN91"
  },
  {
    "uniprot": "A0A3Q0KEG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEG3"
  },
  {
    "uniprot": "A0A5K4F2N5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2N5"
  },
  {
    "uniprot": "A0A5K4EQ98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ98"
  },
  {
    "uniprot": "A0A3Q0KDI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDI3"
  },
  {
    "uniprot": "G4VDM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDM2"
  },
  {
    "uniprot": "A0A5K4FEM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEM6"
  },
  {
    "uniprot": "A0A5K4F8F5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8F5"
  },
  {
    "uniprot": "A0A5K4F916",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F916"
  },
  {
    "uniprot": "A0A3Q0KBT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBT5"
  },
  {
    "uniprot": "A0A5K4ERP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERP3"
  },
  {
    "uniprot": "A0A5K4FBC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBC6"
  },
  {
    "uniprot": "A0A5K4FFR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFR5"
  },
  {
    "uniprot": "A0A5K4EYZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYZ0"
  },
  {
    "uniprot": "G4VND5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VND5"
  },
  {
    "uniprot": "A0A3Q0KBV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBV4"
  },
  {
    "uniprot": "A0A3Q0KPD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPD2"
  },
  {
    "uniprot": "A0A3Q0KN18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN18"
  },
  {
    "uniprot": "A0A5K4FCY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCY2"
  },
  {
    "uniprot": "A0A3Q0KNT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNT4"
  },
  {
    "uniprot": "A0A3Q0KLS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLS8"
  },
  {
    "uniprot": "A0A3Q0KCI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCI5"
  },
  {
    "uniprot": "A0A5K4F801",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F801"
  },
  {
    "uniprot": "A0A3Q0KTV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTV1"
  },
  {
    "uniprot": "A0A3Q0KJ13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ13"
  },
  {
    "uniprot": "A0A5K4F683",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F683"
  },
  {
    "uniprot": "A0A3Q0KBU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBU6"
  },
  {
    "uniprot": "A0A5K4F332",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F332"
  },
  {
    "uniprot": "A0A5K4EKF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKF2"
  },
  {
    "uniprot": "A0A5K4EFD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFD7"
  },
  {
    "uniprot": "G4VQX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQX1"
  },
  {
    "uniprot": "G4VKN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKN0"
  },
  {
    "uniprot": "A0A5K4EPB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPB5"
  },
  {
    "uniprot": "A0A5K4F2W3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2W3"
  },
  {
    "uniprot": "A0A5K4EPA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPA6"
  },
  {
    "uniprot": "A0A5K4EXY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXY3"
  },
  {
    "uniprot": "G4LUP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUP9"
  },
  {
    "uniprot": "A0A5K4F8Q9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Q9"
  },
  {
    "uniprot": "G4VQ23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ23"
  },
  {
    "uniprot": "A0A3Q0KGP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGP1"
  },
  {
    "uniprot": "A0A5K4FCN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCN6"
  },
  {
    "uniprot": "A0A3Q0KLH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLH0"
  },
  {
    "uniprot": "A0A5K4ESG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESG9"
  },
  {
    "uniprot": "A0A5K4F5G4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5G4"
  },
  {
    "uniprot": "A0A3Q0KF47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF47"
  },
  {
    "uniprot": "A0A3Q0KVG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVG7"
  },
  {
    "uniprot": "A0A5K4EVX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVX6"
  },
  {
    "uniprot": "A0A3Q0KD40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD40"
  },
  {
    "uniprot": "A0A5K4F6V6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6V6"
  },
  {
    "uniprot": "A0A5K4F1Y9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Y9"
  },
  {
    "uniprot": "G4LWF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWF4"
  },
  {
    "uniprot": "A0A5K4EYU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYU0"
  },
  {
    "uniprot": "G4VKV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKV0"
  },
  {
    "uniprot": "G4LY82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY82"
  },
  {
    "uniprot": "T1VXA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/T1VXA1"
  },
  {
    "uniprot": "G4VTJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTJ6"
  },
  {
    "uniprot": "G4V632",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V632"
  },
  {
    "uniprot": "G4V772",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V772"
  },
  {
    "uniprot": "G4VFE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFE8"
  },
  {
    "uniprot": "A0A5K4F182",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F182"
  },
  {
    "uniprot": "A0A3Q0KQ00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ00"
  },
  {
    "uniprot": "A0A5K4EZS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZS5"
  },
  {
    "uniprot": "G4LUU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUU3"
  },
  {
    "uniprot": "A0A5K4F1G9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1G9"
  },
  {
    "uniprot": "G4VCD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCD7"
  },
  {
    "uniprot": "A0A5K4F614",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F614"
  },
  {
    "uniprot": "A0A5K4EJY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJY1"
  },
  {
    "uniprot": "A0A5K4F6I7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6I7"
  },
  {
    "uniprot": "A0A5K4F9R9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9R9"
  },
  {
    "uniprot": "A0A3Q0KDG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDG7"
  },
  {
    "uniprot": "A0A5K4F245",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F245"
  },
  {
    "uniprot": "A0A5K4FCD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCD2"
  },
  {
    "uniprot": "A0A5K4FB57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB57"
  },
  {
    "uniprot": "A0A5K4F9Y6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Y6"
  },
  {
    "uniprot": "A0A3Q0KQD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQD3"
  },
  {
    "uniprot": "A0A5K4F7C7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7C7"
  },
  {
    "uniprot": "A0A5K4ECP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECP1"
  },
  {
    "uniprot": "A0A5K4ERA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERA1"
  },
  {
    "uniprot": "A0A5K4FA11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA11"
  },
  {
    "uniprot": "A0A5K4EKJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKJ6"
  },
  {
    "uniprot": "G4VCX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCX9"
  },
  {
    "uniprot": "G4V5N2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5N2"
  },
  {
    "uniprot": "A0A3Q0KNQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNQ2"
  },
  {
    "uniprot": "A0A5K4F7J0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7J0"
  },
  {
    "uniprot": "A0A5K4FCQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCQ0"
  },
  {
    "uniprot": "A0A5K4FD49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD49"
  },
  {
    "uniprot": "A0A5K4FFB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFB3"
  },
  {
    "uniprot": "A0A3Q0KKS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKS9"
  },
  {
    "uniprot": "A0A5K4EZ25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ25"
  },
  {
    "uniprot": "A0A3Q0KL07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL07"
  },
  {
    "uniprot": "G4VH55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH55"
  },
  {
    "uniprot": "A0A5K4ER23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER23"
  },
  {
    "uniprot": "A0A5K4F832",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F832"
  },
  {
    "uniprot": "A0A5K4FFM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFM7"
  },
  {
    "uniprot": "A0A5K4ET29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET29"
  },
  {
    "uniprot": "A0A3Q0KHI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHI3"
  },
  {
    "uniprot": "A0A3Q0KF54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF54"
  },
  {
    "uniprot": "G4LYD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYD1"
  },
  {
    "uniprot": "A0A3Q0KQI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQI6"
  },
  {
    "uniprot": "G4VFA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFA0"
  },
  {
    "uniprot": "A0A5K4EAV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAV3"
  },
  {
    "uniprot": "Q71SU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q71SU7"
  },
  {
    "uniprot": "A0A3Q0KNY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNY0"
  },
  {
    "uniprot": "A0A146MGC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MGC6"
  },
  {
    "uniprot": "A0A5K4F6X0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6X0"
  },
  {
    "uniprot": "A0A3Q0KN81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN81"
  },
  {
    "uniprot": "A0A5K4F4Z0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Z0"
  },
  {
    "uniprot": "Q26540",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26540"
  },
  {
    "uniprot": "A0A5K4F3U2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3U2"
  },
  {
    "uniprot": "A0A5K4EZX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZX3"
  },
  {
    "uniprot": "A0A5K4F8L7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8L7"
  },
  {
    "uniprot": "A0A5K4F460",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F460"
  },
  {
    "uniprot": "A0A3Q0KSK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSK5"
  },
  {
    "uniprot": "A0A3Q0KBD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBD2"
  },
  {
    "uniprot": "G4VA66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA66"
  },
  {
    "uniprot": "G4VKV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKV4"
  },
  {
    "uniprot": "A0A3Q0KR30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR30"
  },
  {
    "uniprot": "A0A5K4EQ40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ40"
  },
  {
    "uniprot": "A0A3Q0KMS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMS7"
  },
  {
    "uniprot": "A0A3Q0KD76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD76"
  },
  {
    "uniprot": "G4VSL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSL2"
  },
  {
    "uniprot": "A0A5K4F5H9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5H9"
  },
  {
    "uniprot": "G4VI82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI82"
  },
  {
    "uniprot": "A0A5K4E9D9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9D9"
  },
  {
    "uniprot": "G4V5Q0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5Q0"
  },
  {
    "uniprot": "A0A5K4EBM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBM5"
  },
  {
    "uniprot": "A0A5K4FGM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGM8"
  },
  {
    "uniprot": "G4VKC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKC2"
  },
  {
    "uniprot": "A0A5K4EH63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH63"
  },
  {
    "uniprot": "A0A3Q0KL10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL10"
  },
  {
    "uniprot": "A0A3Q0KRK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRK1"
  },
  {
    "uniprot": "G4VD39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD39"
  },
  {
    "uniprot": "A0A3Q0KI18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI18"
  },
  {
    "uniprot": "A0A5K4F8M1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8M1"
  },
  {
    "uniprot": "G4VFX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFX0"
  },
  {
    "uniprot": "A0A3Q0KM45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM45"
  },
  {
    "uniprot": "C1M002",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C1M002"
  },
  {
    "uniprot": "A0A3Q0KN07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN07"
  },
  {
    "uniprot": "G4VHZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHZ6"
  },
  {
    "uniprot": "A0A5K4F9B3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9B3"
  },
  {
    "uniprot": "G4VS68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS68"
  },
  {
    "uniprot": "A0A3Q0KCR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCR0"
  },
  {
    "uniprot": "A0A3Q0KNS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNS3"
  },
  {
    "uniprot": "A0A3Q0KCF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCF2"
  },
  {
    "uniprot": "A0A5K4F3Q2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Q2"
  },
  {
    "uniprot": "A0A5K4F0L2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0L2"
  },
  {
    "uniprot": "A0A5K4F6C8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6C8"
  },
  {
    "uniprot": "A0A5K4FAR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAR5"
  },
  {
    "uniprot": "A0A5K4EX51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX51"
  },
  {
    "uniprot": "A0A5K4F7J3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7J3"
  },
  {
    "uniprot": "A0A5K4F7D0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7D0"
  },
  {
    "uniprot": "A0A5K4FD90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD90"
  },
  {
    "uniprot": "A0A5K4FB75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB75"
  },
  {
    "uniprot": "A0A146MJ29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MJ29"
  },
  {
    "uniprot": "A0A3Q0KD67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD67"
  },
  {
    "uniprot": "A0A5K4F0K9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0K9"
  },
  {
    "uniprot": "A0A3Q0KBB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBB4"
  },
  {
    "uniprot": "A0A5K4EVT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVT1"
  },
  {
    "uniprot": "A0A5K4F0V3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0V3"
  },
  {
    "uniprot": "A0A3Q0KHI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHI1"
  },
  {
    "uniprot": "A0A3Q0KP04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP04"
  },
  {
    "uniprot": "A0A5K4EU45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU45"
  },
  {
    "uniprot": "A0A5K4EE90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE90"
  },
  {
    "uniprot": "A0A3Q0KT69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT69"
  },
  {
    "uniprot": "A0A5K4ETD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETD8"
  },
  {
    "uniprot": "G4VMM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMM9"
  },
  {
    "uniprot": "A0A3Q0KSI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSI0"
  },
  {
    "uniprot": "G4M006",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M006"
  },
  {
    "uniprot": "A0A5K4F7G4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7G4"
  },
  {
    "uniprot": "G4VH76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH76"
  },
  {
    "uniprot": "A0A5K4EFD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFD4"
  },
  {
    "uniprot": "A0A5K4F4E2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4E2"
  },
  {
    "uniprot": "A0A3Q0KD07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD07"
  },
  {
    "uniprot": "A0A5K4EVU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVU9"
  },
  {
    "uniprot": "Q26444",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26444"
  },
  {
    "uniprot": "A0A3Q0KP08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP08"
  },
  {
    "uniprot": "A0A3Q0KTT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTT2"
  },
  {
    "uniprot": "A0A5K4FDY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDY0"
  },
  {
    "uniprot": "A0A3Q0KNE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNE8"
  },
  {
    "uniprot": "A0A5K4ER61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER61"
  },
  {
    "uniprot": "A0A5K4F0L5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0L5"
  },
  {
    "uniprot": "G4VM09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM09"
  },
  {
    "uniprot": "G4VQ56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ56"
  },
  {
    "uniprot": "A0A5K4F8B0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8B0"
  },
  {
    "uniprot": "A0A3Q0KRP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRP9"
  },
  {
    "uniprot": "A0A5K4EYZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYZ4"
  },
  {
    "uniprot": "G4M0T7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0T7"
  },
  {
    "uniprot": "A0A5K4FFB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFB8"
  },
  {
    "uniprot": "A0A3Q0KBV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBV3"
  },
  {
    "uniprot": "A0A3Q0KLY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLY1"
  },
  {
    "uniprot": "A0A5K4F203",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F203"
  },
  {
    "uniprot": "A0A5K4EZN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZN3"
  },
  {
    "uniprot": "A0A3Q0KTI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTI8"
  },
  {
    "uniprot": "G4M1T0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1T0"
  },
  {
    "uniprot": "A0A5K4F2T0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2T0"
  },
  {
    "uniprot": "A0A3Q0KEK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEK2"
  },
  {
    "uniprot": "A0A5K4F3F9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3F9"
  },
  {
    "uniprot": "A0A5K4FCJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCJ7"
  },
  {
    "uniprot": "A0A3Q0KL00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL00"
  },
  {
    "uniprot": "A0A5K4FFZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFZ2"
  },
  {
    "uniprot": "A0A5K4F4C8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4C8"
  },
  {
    "uniprot": "A0A3Q0KQK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQK4"
  },
  {
    "uniprot": "A0A3Q0KGN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGN2"
  },
  {
    "uniprot": "A0A3Q0KN42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN42"
  },
  {
    "uniprot": "A0A3Q0KR17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR17"
  },
  {
    "uniprot": "A0A5K4F938",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F938"
  },
  {
    "uniprot": "G4VLE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLE3"
  },
  {
    "uniprot": "A0A5K4F573",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F573"
  },
  {
    "uniprot": "A0A5K4F3T7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3T7"
  },
  {
    "uniprot": "A0A3Q0KJD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJD4"
  },
  {
    "uniprot": "A0A5K4EW37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW37"
  },
  {
    "uniprot": "A0A3Q0KCH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCH8"
  },
  {
    "uniprot": "G4LUE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUE8"
  },
  {
    "uniprot": "A0A5K4FBX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBX6"
  },
  {
    "uniprot": "G4VSN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSN3"
  },
  {
    "uniprot": "G4LVF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVF9"
  },
  {
    "uniprot": "A0A3Q0KSP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSP3"
  },
  {
    "uniprot": "A0A5K4EBB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBB4"
  },
  {
    "uniprot": "A0A3Q0KHQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHQ9"
  },
  {
    "uniprot": "G4VPT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPT6"
  },
  {
    "uniprot": "A0A3Q0KLS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLS5"
  },
  {
    "uniprot": "A0A5K4ET65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET65"
  },
  {
    "uniprot": "A0A5K4F619",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F619"
  },
  {
    "uniprot": "A0A5K4F6Z8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Z8"
  },
  {
    "uniprot": "A0A3Q0KKS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKS0"
  },
  {
    "uniprot": "A0A5K4F4S7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4S7"
  },
  {
    "uniprot": "G4VLQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLQ2"
  },
  {
    "uniprot": "A0A5K4FDQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDQ2"
  },
  {
    "uniprot": "A0A3Q0KMX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMX3"
  },
  {
    "uniprot": "C4Q310",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q310"
  },
  {
    "uniprot": "A0A5K4FEB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEB4"
  },
  {
    "uniprot": "G4V7Z2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7Z2"
  },
  {
    "uniprot": "G4VTA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTA1"
  },
  {
    "uniprot": "A0A5K4F063",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F063"
  },
  {
    "uniprot": "A0A3Q0KBF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBF8"
  },
  {
    "uniprot": "A0A5K4F6N8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6N8"
  },
  {
    "uniprot": "G4VRD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRD2"
  },
  {
    "uniprot": "A0A3Q0KPP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPP1"
  },
  {
    "uniprot": "A0A5K4EVC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVC2"
  },
  {
    "uniprot": "A0A3Q0KMU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMU9"
  },
  {
    "uniprot": "A0A5K4FBX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBX9"
  },
  {
    "uniprot": "A0A5K4F5T7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5T7"
  },
  {
    "uniprot": "G4VGX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGX2"
  },
  {
    "uniprot": "G4VBA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBA9"
  },
  {
    "uniprot": "A0A5K4F4U4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4U4"
  },
  {
    "uniprot": "A0A3Q0KSX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSX4"
  },
  {
    "uniprot": "A0A5K4F275",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F275"
  },
  {
    "uniprot": "A0A5K4FEV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEV4"
  },
  {
    "uniprot": "A0A5K4FCQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCQ8"
  },
  {
    "uniprot": "A0A5K4F3D5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3D5"
  },
  {
    "uniprot": "A0A5K4ELJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELJ0"
  },
  {
    "uniprot": "G4V6L6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6L6"
  },
  {
    "uniprot": "A0A3Q0KL29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL29"
  },
  {
    "uniprot": "A0A5K4F6G7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6G7"
  },
  {
    "uniprot": "A0A5K4EE06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE06"
  },
  {
    "uniprot": "A0A3Q0KMY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMY4"
  },
  {
    "uniprot": "A0A3Q0KBR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBR2"
  },
  {
    "uniprot": "G4VK58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK58"
  },
  {
    "uniprot": "A0A3Q0KPN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPN1"
  },
  {
    "uniprot": "A0A5K4EDB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDB5"
  },
  {
    "uniprot": "G4VGN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGN6"
  },
  {
    "uniprot": "A0A3Q0KFS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFS1"
  },
  {
    "uniprot": "G4VB51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB51"
  },
  {
    "uniprot": "A0A5K4F1E0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1E0"
  },
  {
    "uniprot": "A0A5K4E8Z6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8Z6"
  },
  {
    "uniprot": "G4VTC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTC9"
  },
  {
    "uniprot": "A0A3Q0KV57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV57"
  },
  {
    "uniprot": "A0A3Q0KS42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS42"
  },
  {
    "uniprot": "A0A3Q0KUB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUB3"
  },
  {
    "uniprot": "A0A5K4EA33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EA33"
  },
  {
    "uniprot": "G4VG23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VG23"
  },
  {
    "uniprot": "A0A5K4EKQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKQ4"
  },
  {
    "uniprot": "A0A3Q0KSB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSB3"
  },
  {
    "uniprot": "G4VKQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKQ6"
  },
  {
    "uniprot": "A0A3Q0KPG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPG1"
  },
  {
    "uniprot": "A0A3Q0KUU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUU0"
  },
  {
    "uniprot": "A0A3Q0KTZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTZ8"
  },
  {
    "uniprot": "G4VNR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNR0"
  },
  {
    "uniprot": "A0A5K4ER72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER72"
  },
  {
    "uniprot": "A0A5K4EZ05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ05"
  },
  {
    "uniprot": "G4VAJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAJ6"
  },
  {
    "uniprot": "A0A3Q0KUV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUV2"
  },
  {
    "uniprot": "A0A5K4EGY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGY9"
  },
  {
    "uniprot": "A0A3Q0KH23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH23"
  },
  {
    "uniprot": "A0A5K4F278",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F278"
  },
  {
    "uniprot": "A0A3Q0KV65",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV65"
  },
  {
    "uniprot": "A0A5K4F5Q0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Q0"
  },
  {
    "uniprot": "A0A5K4F7P8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7P8"
  },
  {
    "uniprot": "A0A3Q0KSZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSZ1"
  },
  {
    "uniprot": "A0A5K4F5X3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5X3"
  },
  {
    "uniprot": "A0A3Q0KM28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM28"
  },
  {
    "uniprot": "G4VJ97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ97"
  },
  {
    "uniprot": "A0A5K4F4L6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4L6"
  },
  {
    "uniprot": "A0A3Q0KHM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHM8"
  },
  {
    "uniprot": "A0A3Q0KDL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDL8"
  },
  {
    "uniprot": "G4LYP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYP5"
  },
  {
    "uniprot": "A0A5K4F077",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F077"
  },
  {
    "uniprot": "A0A3Q0KP64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP64"
  },
  {
    "uniprot": "A0A3Q0KI37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI37"
  },
  {
    "uniprot": "A0A5K4FED5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FED5"
  },
  {
    "uniprot": "A0A3Q0KHH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHH5"
  },
  {
    "uniprot": "G4LUA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUA3"
  },
  {
    "uniprot": "A0A5K4F2M1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2M1"
  },
  {
    "uniprot": "A0A5K4F7T6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7T6"
  },
  {
    "uniprot": "A0A5K4F9Z1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Z1"
  },
  {
    "uniprot": "A0A5K4ELB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELB3"
  },
  {
    "uniprot": "A0A3Q0KEA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEA6"
  },
  {
    "uniprot": "A0A5K4EZX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZX9"
  },
  {
    "uniprot": "A0A5K4FDZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDZ4"
  },
  {
    "uniprot": "A0A5K4EX62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX62"
  },
  {
    "uniprot": "A0A5K4ES85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES85"
  },
  {
    "uniprot": "G4VSA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSA5"
  },
  {
    "uniprot": "A0A3Q0KFV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFV2"
  },
  {
    "uniprot": "A0A5K4F5J0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5J0"
  },
  {
    "uniprot": "A0A5K4F6K2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6K2"
  },
  {
    "uniprot": "A0A3Q0KSR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSR3"
  },
  {
    "uniprot": "A0A3Q0KDD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDD8"
  },
  {
    "uniprot": "A0A3Q0KRE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRE0"
  },
  {
    "uniprot": "A0A5K4EZS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZS8"
  },
  {
    "uniprot": "A0A5K4F0W4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0W4"
  },
  {
    "uniprot": "A0A5K4F233",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F233"
  },
  {
    "uniprot": "A0A5K4F2H9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2H9"
  },
  {
    "uniprot": "A0A5K4F0Q6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Q6"
  },
  {
    "uniprot": "A0A5K4EAR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAR4"
  },
  {
    "uniprot": "A0A3Q0KR37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR37"
  },
  {
    "uniprot": "A0A3Q0KLJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLJ7"
  },
  {
    "uniprot": "A0A5K4FAG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAG2"
  },
  {
    "uniprot": "A0A5K4EKY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKY5"
  },
  {
    "uniprot": "A0A3Q0KL50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL50"
  },
  {
    "uniprot": "A0A3Q0KFG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFG1"
  },
  {
    "uniprot": "A0A3Q0KJ43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ43"
  },
  {
    "uniprot": "A0A5K4F9A5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9A5"
  },
  {
    "uniprot": "A0A3Q0KDY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDY8"
  },
  {
    "uniprot": "A0A5K4E8H0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8H0"
  },
  {
    "uniprot": "A0A5K4FBV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBV2"
  },
  {
    "uniprot": "A0A3Q0KQ85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ85"
  },
  {
    "uniprot": "G4VB31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB31"
  },
  {
    "uniprot": "A0A5K4F4D2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4D2"
  },
  {
    "uniprot": "A0A5K4F6A9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6A9"
  },
  {
    "uniprot": "A0A5K4F035",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F035"
  },
  {
    "uniprot": "A0A3Q0KPU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPU8"
  },
  {
    "uniprot": "G4LYN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYN8"
  },
  {
    "uniprot": "A0A5K4F5U7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5U7"
  },
  {
    "uniprot": "A0A3Q0KSB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSB7"
  },
  {
    "uniprot": "A0A5K4EPR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPR9"
  },
  {
    "uniprot": "A0A3Q0KHN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHN0"
  },
  {
    "uniprot": "A0A3Q0KKC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKC2"
  },
  {
    "uniprot": "A0A3Q0KL19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL19"
  },
  {
    "uniprot": "A0A3Q0KUL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUL1"
  },
  {
    "uniprot": "A0A5K4F1J2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1J2"
  },
  {
    "uniprot": "A0A5K4F6Z2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Z2"
  },
  {
    "uniprot": "A0A5K4F282",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F282"
  },
  {
    "uniprot": "A0A3Q0KEP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEP1"
  },
  {
    "uniprot": "A0A3Q0KQZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQZ0"
  },
  {
    "uniprot": "A0A5K4F0Z4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Z4"
  },
  {
    "uniprot": "A0A5K4F7Z8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Z8"
  },
  {
    "uniprot": "A0A5K4F2F1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2F1"
  },
  {
    "uniprot": "A0A5K4EPE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPE9"
  },
  {
    "uniprot": "A0A5K4FFC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFC2"
  },
  {
    "uniprot": "A0A3Q0KBC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBC1"
  },
  {
    "uniprot": "A0A5K4FE02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE02"
  },
  {
    "uniprot": "A0A5K4F3B5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3B5"
  },
  {
    "uniprot": "A0A5K4F5P3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5P3"
  },
  {
    "uniprot": "A0A3Q0KUF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUF1"
  },
  {
    "uniprot": "A0A3Q0KHX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHX3"
  },
  {
    "uniprot": "A0A5K4F8F3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8F3"
  },
  {
    "uniprot": "A0A5K4F3V6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3V6"
  },
  {
    "uniprot": "A0A5K4F5D7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5D7"
  },
  {
    "uniprot": "A0A3Q0KN49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN49"
  },
  {
    "uniprot": "G4VHK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHK9"
  },
  {
    "uniprot": "A0A5K4FB40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB40"
  },
  {
    "uniprot": "A0A5K4FA03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA03"
  },
  {
    "uniprot": "A0A5K4EYE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYE1"
  },
  {
    "uniprot": "G4LY63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY63"
  },
  {
    "uniprot": "A0A5K4EV20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV20"
  },
  {
    "uniprot": "A0A5K4F1A6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1A6"
  },
  {
    "uniprot": "A0A5K4F9L0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9L0"
  },
  {
    "uniprot": "A0A3Q0KK49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK49"
  },
  {
    "uniprot": "A0A3Q0KHU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHU3"
  },
  {
    "uniprot": "A0A3Q0KI58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI58"
  },
  {
    "uniprot": "A0A5K4EL74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL74"
  },
  {
    "uniprot": "A0A5K4FAN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAN3"
  },
  {
    "uniprot": "G4VG40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VG40"
  },
  {
    "uniprot": "A0A5K4F831",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F831"
  },
  {
    "uniprot": "G4V827",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V827"
  },
  {
    "uniprot": "A0A5K4F8J4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8J4"
  },
  {
    "uniprot": "A0A5K4F8A1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8A1"
  },
  {
    "uniprot": "A0A5K4F5P4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5P4"
  },
  {
    "uniprot": "A0A5K4EH32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH32"
  },
  {
    "uniprot": "G4V7P0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7P0"
  },
  {
    "uniprot": "A0A5K4F3A2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3A2"
  },
  {
    "uniprot": "A0A3Q0KSH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSH7"
  },
  {
    "uniprot": "G4V830",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V830"
  },
  {
    "uniprot": "C4QC83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QC83"
  },
  {
    "uniprot": "A0A5K4FA81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA81"
  },
  {
    "uniprot": "A0A5K4F3Y8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Y8"
  },
  {
    "uniprot": "A0A5K4F556",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F556"
  },
  {
    "uniprot": "A0A5K4EQR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQR2"
  },
  {
    "uniprot": "A0A3Q0KBB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBB1"
  },
  {
    "uniprot": "A0A3Q0KN50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN50"
  },
  {
    "uniprot": "A0A5K4EQI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQI2"
  },
  {
    "uniprot": "G4LYH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYH3"
  },
  {
    "uniprot": "G4LWW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWW4"
  },
  {
    "uniprot": "A0A5K4EHR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHR1"
  },
  {
    "uniprot": "A0A5K4F646",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F646"
  },
  {
    "uniprot": "A0A5K4EGD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGD6"
  },
  {
    "uniprot": "G4V9U1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9U1"
  },
  {
    "uniprot": "A0A5K4FFC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFC7"
  },
  {
    "uniprot": "A0A3Q0KCA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCA4"
  },
  {
    "uniprot": "A0A5K4EV71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV71"
  },
  {
    "uniprot": "G4VPJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPJ1"
  },
  {
    "uniprot": "A0A5K4F8J2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8J2"
  },
  {
    "uniprot": "A0A5K4FAD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAD1"
  },
  {
    "uniprot": "A0A5K4ERD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERD0"
  },
  {
    "uniprot": "A0A5K4FES7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FES7"
  },
  {
    "uniprot": "A0A3Q0KL06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL06"
  },
  {
    "uniprot": "A0A5K4F7M8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7M8"
  },
  {
    "uniprot": "A0A3Q0KS83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS83"
  },
  {
    "uniprot": "A0A5K4F1V6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1V6"
  },
  {
    "uniprot": "A0A5K4EQT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQT3"
  },
  {
    "uniprot": "A0A5K4FDP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDP7"
  },
  {
    "uniprot": "A0A3Q0KU17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU17"
  },
  {
    "uniprot": "A0A3Q0KNI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNI3"
  },
  {
    "uniprot": "A0A5K4FED9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FED9"
  },
  {
    "uniprot": "A0A5K4FER8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FER8"
  },
  {
    "uniprot": "A0A3Q0KV72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV72"
  },
  {
    "uniprot": "A0A3Q0KB93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KB93"
  },
  {
    "uniprot": "A0A5K4FBE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBE1"
  },
  {
    "uniprot": "A0A3Q0KR18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR18"
  },
  {
    "uniprot": "A0A5K4EYA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYA8"
  },
  {
    "uniprot": "A0A3Q0KUA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUA0"
  },
  {
    "uniprot": "A0A3Q0KSS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSS4"
  },
  {
    "uniprot": "A0A5K4FCT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCT7"
  },
  {
    "uniprot": "G4VLV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLV7"
  },
  {
    "uniprot": "A0A5K4ER07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER07"
  },
  {
    "uniprot": "A0A5K4F6M6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6M6"
  },
  {
    "uniprot": "A0A5K4F788",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F788"
  },
  {
    "uniprot": "A0A5K4F6P9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6P9"
  },
  {
    "uniprot": "A0A5K4F4N1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4N1"
  },
  {
    "uniprot": "A0A5K4F1T2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1T2"
  },
  {
    "uniprot": "G4V8H8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8H8"
  },
  {
    "uniprot": "A0A5K4F2P8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2P8"
  },
  {
    "uniprot": "A0A5K4F5R7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5R7"
  },
  {
    "uniprot": "A0A3Q0KC98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC98"
  },
  {
    "uniprot": "A0A3Q0KSW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSW7"
  },
  {
    "uniprot": "G4VFZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFZ4"
  },
  {
    "uniprot": "A0A3Q0KLD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLD4"
  },
  {
    "uniprot": "G4VLD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLD8"
  },
  {
    "uniprot": "A0A5K4FCY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCY7"
  },
  {
    "uniprot": "A0A3Q0KUB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUB0"
  },
  {
    "uniprot": "A0A5K4FAQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAQ4"
  },
  {
    "uniprot": "A0A5K4F7X2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7X2"
  },
  {
    "uniprot": "G4VA50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA50"
  },
  {
    "uniprot": "A0A5K4EJX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJX7"
  },
  {
    "uniprot": "A0A3Q0KFK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFK2"
  },
  {
    "uniprot": "A0A5K4F686",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F686"
  },
  {
    "uniprot": "A0A5K4F8W5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8W5"
  },
  {
    "uniprot": "A0A3Q0KTF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTF8"
  },
  {
    "uniprot": "P53470",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P53470"
  },
  {
    "uniprot": "A0A5K4F5A1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5A1"
  },
  {
    "uniprot": "A0A5K4EKB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKB2"
  },
  {
    "uniprot": "A0A3Q0KP12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP12"
  },
  {
    "uniprot": "A0A5K4F6M2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6M2"
  },
  {
    "uniprot": "A0A3Q0KKK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKK9"
  },
  {
    "uniprot": "A0A3Q0KNC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNC3"
  },
  {
    "uniprot": "A0A5K4EKJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKJ9"
  },
  {
    "uniprot": "A0A5K4F6I5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6I5"
  },
  {
    "uniprot": "G4V7V1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7V1"
  },
  {
    "uniprot": "A0A5K4F8X1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8X1"
  },
  {
    "uniprot": "A0A5K4F3G3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3G3"
  },
  {
    "uniprot": "A0A3Q0KPC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPC0"
  },
  {
    "uniprot": "A0A5K4FB27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB27"
  },
  {
    "uniprot": "A0A5K4EB35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB35"
  },
  {
    "uniprot": "A0A5K4FBB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBB6"
  },
  {
    "uniprot": "G4VHA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHA3"
  },
  {
    "uniprot": "A0A5K4F1N4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1N4"
  },
  {
    "uniprot": "G4VL73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VL73"
  },
  {
    "uniprot": "A0A3Q0KU10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU10"
  },
  {
    "uniprot": "A0A5K4F5E7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5E7"
  },
  {
    "uniprot": "G4LUE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUE2"
  },
  {
    "uniprot": "G4LYV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYV6"
  },
  {
    "uniprot": "A0A5K4F1H6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1H6"
  },
  {
    "uniprot": "G4V981",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V981"
  },
  {
    "uniprot": "A0A5K4EFY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFY7"
  },
  {
    "uniprot": "A0A5K4FAP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAP3"
  },
  {
    "uniprot": "A0A3Q0KCV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCV0"
  },
  {
    "uniprot": "A0A3Q0KSG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSG6"
  },
  {
    "uniprot": "A0A5K4F5M9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5M9"
  },
  {
    "uniprot": "A0A5K4EL62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL62"
  },
  {
    "uniprot": "A0A5K4FBW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBW6"
  },
  {
    "uniprot": "G4V7X9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7X9"
  },
  {
    "uniprot": "G4LY06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY06"
  },
  {
    "uniprot": "A0A3Q0KR47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR47"
  },
  {
    "uniprot": "G4V5C0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5C0"
  },
  {
    "uniprot": "A0A5K4F7D3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7D3"
  },
  {
    "uniprot": "A0A5K4E9U3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9U3"
  },
  {
    "uniprot": "A0A5K4FAC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAC8"
  },
  {
    "uniprot": "A0A5K4F3N9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3N9"
  },
  {
    "uniprot": "A0A5K4F7P0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7P0"
  },
  {
    "uniprot": "A0A3Q0KIH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIH3"
  },
  {
    "uniprot": "A0A5K4EPN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPN2"
  },
  {
    "uniprot": "A0A5K4EUL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUL9"
  },
  {
    "uniprot": "A0A5K4F1Q0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Q0"
  },
  {
    "uniprot": "C4QB35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QB35"
  },
  {
    "uniprot": "A0A3Q0KQK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQK3"
  },
  {
    "uniprot": "A0A5K4ENE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENE8"
  },
  {
    "uniprot": "A0A3Q0KMR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMR6"
  },
  {
    "uniprot": "G4LUD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUD9"
  },
  {
    "uniprot": "A0A5K4EJW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJW2"
  },
  {
    "uniprot": "A0A3Q0KT59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT59"
  },
  {
    "uniprot": "A0A5K4F8P6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8P6"
  },
  {
    "uniprot": "A0A3Q0KS48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS48"
  },
  {
    "uniprot": "G4V6P7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6P7"
  },
  {
    "uniprot": "A0A3Q0KQP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQP3"
  },
  {
    "uniprot": "A0A5K4F9F0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9F0"
  },
  {
    "uniprot": "G4VL70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VL70"
  },
  {
    "uniprot": "A0A5K4F2C3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2C3"
  },
  {
    "uniprot": "A0A5K4F0Y2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Y2"
  },
  {
    "uniprot": "G4V6K8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6K8"
  },
  {
    "uniprot": "G4V9D6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9D6"
  },
  {
    "uniprot": "G4LVD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVD2"
  },
  {
    "uniprot": "G4LX22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX22"
  },
  {
    "uniprot": "A0A5K4ESS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESS1"
  },
  {
    "uniprot": "G4VIR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIR8"
  },
  {
    "uniprot": "A0A3Q0KBK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBK4"
  },
  {
    "uniprot": "A0A5K4F6F1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6F1"
  },
  {
    "uniprot": "A0A3Q0KJI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJI1"
  },
  {
    "uniprot": "A0A5K4F4W3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4W3"
  },
  {
    "uniprot": "A0A3Q0KS92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS92"
  },
  {
    "uniprot": "A0A3Q0KD81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD81"
  },
  {
    "uniprot": "A0A3Q0KSH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSH6"
  },
  {
    "uniprot": "A0A5K4EVN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVN5"
  },
  {
    "uniprot": "A0A5K4FDA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDA6"
  },
  {
    "uniprot": "A0A3Q0KUD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUD6"
  },
  {
    "uniprot": "A0A5K4FDC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDC4"
  },
  {
    "uniprot": "G4M0T3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0T3"
  },
  {
    "uniprot": "A0A5K4F467",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F467"
  },
  {
    "uniprot": "A0A5K4ET02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET02"
  },
  {
    "uniprot": "A0A3Q0KDU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDU5"
  },
  {
    "uniprot": "A0A3Q0KBD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBD6"
  },
  {
    "uniprot": "A0A3Q0KK21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK21"
  },
  {
    "uniprot": "A0A5K4EH61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH61"
  },
  {
    "uniprot": "A0A3Q0KQ34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ34"
  },
  {
    "uniprot": "A0A5K4F7I4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7I4"
  },
  {
    "uniprot": "A0A3Q0KUN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUN3"
  },
  {
    "uniprot": "A0A3Q0KVF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVF4"
  },
  {
    "uniprot": "A0A5K4F337",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F337"
  },
  {
    "uniprot": "A0A3Q0KHA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHA7"
  },
  {
    "uniprot": "A0A3Q0KNL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNL3"
  },
  {
    "uniprot": "A0A3Q0KFQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFQ5"
  },
  {
    "uniprot": "A0A3Q0KCA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCA9"
  },
  {
    "uniprot": "A0A5K4EPU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPU3"
  },
  {
    "uniprot": "A0A5K4F7W8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7W8"
  },
  {
    "uniprot": "A0A5K4F296",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F296"
  },
  {
    "uniprot": "A0A3Q0KB92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KB92"
  },
  {
    "uniprot": "A0A5K4FGV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGV6"
  },
  {
    "uniprot": "A0A3Q0KRW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRW4"
  },
  {
    "uniprot": "A0A3Q0KDQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDQ5"
  },
  {
    "uniprot": "A0A3Q0KDE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDE6"
  },
  {
    "uniprot": "A0A5K4EKA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKA3"
  },
  {
    "uniprot": "A0A3Q0KSN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSN1"
  },
  {
    "uniprot": "G4LX89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX89"
  },
  {
    "uniprot": "A0A5K4FB53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB53"
  },
  {
    "uniprot": "G4VNK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNK3"
  },
  {
    "uniprot": "A0A3Q0KR12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR12"
  },
  {
    "uniprot": "A0A3Q0KRA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRA8"
  },
  {
    "uniprot": "A0A5K4F295",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F295"
  },
  {
    "uniprot": "G4VH57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH57"
  },
  {
    "uniprot": "A0A3Q0KDB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDB9"
  },
  {
    "uniprot": "G4M011",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M011"
  },
  {
    "uniprot": "A0A3Q0KKW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKW4"
  },
  {
    "uniprot": "A0A5K4F1I9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1I9"
  },
  {
    "uniprot": "A0A5K4F2A7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2A7"
  },
  {
    "uniprot": "G4LX15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX15"
  },
  {
    "uniprot": "A0A5K4EFU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFU2"
  },
  {
    "uniprot": "A0A3Q0KEG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEG8"
  },
  {
    "uniprot": "G4V834",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V834"
  },
  {
    "uniprot": "G4V6V3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6V3"
  },
  {
    "uniprot": "O18501",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/O18501"
  },
  {
    "uniprot": "A0A5K4ER44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER44"
  },
  {
    "uniprot": "A0A3Q0KPT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPT5"
  },
  {
    "uniprot": "G4LZ70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ70"
  },
  {
    "uniprot": "G4VNU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNU9"
  },
  {
    "uniprot": "A0A3Q0KPB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPB9"
  },
  {
    "uniprot": "A0A3Q0KEL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEL9"
  },
  {
    "uniprot": "A0A5K4FAW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAW2"
  },
  {
    "uniprot": "A0A5K4EB37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB37"
  },
  {
    "uniprot": "G4M0D3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0D3"
  },
  {
    "uniprot": "A0A5K4F4H2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4H2"
  },
  {
    "uniprot": "A0A3Q0KV59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV59"
  },
  {
    "uniprot": "G4VCC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCC0"
  },
  {
    "uniprot": "A0A5K4F2Z7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Z7"
  },
  {
    "uniprot": "A0A5K4F5T3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5T3"
  },
  {
    "uniprot": "G4M1J2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1J2"
  },
  {
    "uniprot": "G4VK45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK45"
  },
  {
    "uniprot": "G4VNB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNB2"
  },
  {
    "uniprot": "A0A3Q0KL46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL46"
  },
  {
    "uniprot": "A0A5K4EYV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYV9"
  },
  {
    "uniprot": "A0A3Q0KM81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM81"
  },
  {
    "uniprot": "G4VNL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNL7"
  },
  {
    "uniprot": "A0A5K4FA97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA97"
  },
  {
    "uniprot": "A0A3Q0KPD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPD8"
  },
  {
    "uniprot": "C4QG05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QG05"
  },
  {
    "uniprot": "A0A5K4F0N8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0N8"
  },
  {
    "uniprot": "A0A5K4ESJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESJ8"
  },
  {
    "uniprot": "A0A3Q0KLJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLJ9"
  },
  {
    "uniprot": "A0A5K4FER5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FER5"
  },
  {
    "uniprot": "A0A3Q0KM93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM93"
  },
  {
    "uniprot": "A0A3Q0KP26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP26"
  },
  {
    "uniprot": "A0A3Q0KMC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMC6"
  },
  {
    "uniprot": "A0A3Q0KBM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBM3"
  },
  {
    "uniprot": "A0A3Q0KMY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMY5"
  },
  {
    "uniprot": "A0A3Q0KQP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQP1"
  },
  {
    "uniprot": "A0A5K4EZZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZZ1"
  },
  {
    "uniprot": "A0A5K4FBL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBL3"
  },
  {
    "uniprot": "G4VST4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VST4"
  },
  {
    "uniprot": "A0A5K4EYT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYT5"
  },
  {
    "uniprot": "A0A5K4F5T2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5T2"
  },
  {
    "uniprot": "A0A5K4FCB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCB6"
  },
  {
    "uniprot": "A0A3Q0KCB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCB3"
  },
  {
    "uniprot": "G4VPE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPE5"
  },
  {
    "uniprot": "A0A5K4EE86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE86"
  },
  {
    "uniprot": "A0A3Q0KMB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMB2"
  },
  {
    "uniprot": "A0A3Q0KTG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTG0"
  },
  {
    "uniprot": "A0A146MIU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MIU7"
  },
  {
    "uniprot": "A0A5K4F225",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F225"
  },
  {
    "uniprot": "A0A5K4F7B8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7B8"
  },
  {
    "uniprot": "A0A3Q0KUS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUS5"
  },
  {
    "uniprot": "A0A3Q0KP15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP15"
  },
  {
    "uniprot": "A0A3Q0KQT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQT3"
  },
  {
    "uniprot": "P09383",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P09383"
  },
  {
    "uniprot": "A0A5K4ERC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERC5"
  },
  {
    "uniprot": "G4LYV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYV0"
  },
  {
    "uniprot": "A0A3Q0KNR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNR2"
  },
  {
    "uniprot": "A0A5K4FAA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAA7"
  },
  {
    "uniprot": "A0A5K4FBL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBL5"
  },
  {
    "uniprot": "Q26591",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26591"
  },
  {
    "uniprot": "G4LX28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX28"
  },
  {
    "uniprot": "A0A5K4EAX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAX5"
  },
  {
    "uniprot": "G4VJB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJB5"
  },
  {
    "uniprot": "A0A5K4F1D0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1D0"
  },
  {
    "uniprot": "A0A3Q0KQ93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ93"
  },
  {
    "uniprot": "G4VJT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJT0"
  },
  {
    "uniprot": "G4VJC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJC1"
  },
  {
    "uniprot": "G4VIX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIX1"
  },
  {
    "uniprot": "A0A3Q0KFX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFX0"
  },
  {
    "uniprot": "A0A5K4F6P8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6P8"
  },
  {
    "uniprot": "A0A5K4F9D7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9D7"
  },
  {
    "uniprot": "A0A5K4FDD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDD5"
  },
  {
    "uniprot": "A0A5K4F9E5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9E5"
  },
  {
    "uniprot": "A0A3Q0KQS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQS9"
  },
  {
    "uniprot": "A0A3Q0KR52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR52"
  },
  {
    "uniprot": "A0A3Q0KNP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNP1"
  },
  {
    "uniprot": "A0A3Q0KK74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK74"
  },
  {
    "uniprot": "A0A3Q0KC48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC48"
  },
  {
    "uniprot": "A0A5K4EDW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDW5"
  },
  {
    "uniprot": "A0A3Q0KP03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP03"
  },
  {
    "uniprot": "G4V9L1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9L1"
  },
  {
    "uniprot": "A0A5K4EWC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWC7"
  },
  {
    "uniprot": "A0A5K4EFC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFC4"
  },
  {
    "uniprot": "A0A5K4F7W0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7W0"
  },
  {
    "uniprot": "A0A3Q0KCC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCC0"
  },
  {
    "uniprot": "A0A5K4F253",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F253"
  },
  {
    "uniprot": "A0A3Q0KST3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KST3"
  },
  {
    "uniprot": "A0A5K4FD02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD02"
  },
  {
    "uniprot": "G4LYU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYU7"
  },
  {
    "uniprot": "A0A3Q0KKJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKJ7"
  },
  {
    "uniprot": "A0A5K4F329",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F329"
  },
  {
    "uniprot": "G4VIW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIW9"
  },
  {
    "uniprot": "A0A3Q0KDW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDW2"
  },
  {
    "uniprot": "A0A5K4E8I9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8I9"
  },
  {
    "uniprot": "A0A5K4F0J7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0J7"
  },
  {
    "uniprot": "V5WB23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/V5WB23"
  },
  {
    "uniprot": "A0A3Q0KLT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLT3"
  },
  {
    "uniprot": "A0A5K4FA41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA41"
  },
  {
    "uniprot": "A0A5K4EMI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMI6"
  },
  {
    "uniprot": "G4M1E8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1E8"
  },
  {
    "uniprot": "G4V6K0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6K0"
  },
  {
    "uniprot": "A0A5K4F8B3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8B3"
  },
  {
    "uniprot": "A0A5K4F4A3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4A3"
  },
  {
    "uniprot": "A0A5K4F5P5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5P5"
  },
  {
    "uniprot": "A0A5K4EN34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN34"
  },
  {
    "uniprot": "A0A3Q0KP41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP41"
  },
  {
    "uniprot": "A0A3Q0KLN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLN7"
  },
  {
    "uniprot": "A0A3Q0KST9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KST9"
  },
  {
    "uniprot": "A0A3Q0KHW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHW0"
  },
  {
    "uniprot": "A0A5K4F2P2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2P2"
  },
  {
    "uniprot": "A0A5K4F0I3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0I3"
  },
  {
    "uniprot": "A0A5K4F2W8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2W8"
  },
  {
    "uniprot": "A0A3Q0KCH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCH9"
  },
  {
    "uniprot": "A0A3Q0KHB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHB7"
  },
  {
    "uniprot": "A0A3Q0KD88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD88"
  },
  {
    "uniprot": "A0A5K4F316",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F316"
  },
  {
    "uniprot": "G4M0G7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0G7"
  },
  {
    "uniprot": "A0A3Q0KVC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVC4"
  },
  {
    "uniprot": "A0A5K4FA48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA48"
  },
  {
    "uniprot": "A0A5K4FC92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC92"
  },
  {
    "uniprot": "A0A5K4FCX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCX0"
  },
  {
    "uniprot": "A0A5K4EV56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV56"
  },
  {
    "uniprot": "G4V5B3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5B3"
  },
  {
    "uniprot": "A0A3Q0KS98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS98"
  },
  {
    "uniprot": "A0A3Q0KLH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLH2"
  },
  {
    "uniprot": "A0A5K4F2R6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2R6"
  },
  {
    "uniprot": "A0A3Q0KMF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMF1"
  },
  {
    "uniprot": "A0A3Q0KMW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMW4"
  },
  {
    "uniprot": "G4VKH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKH6"
  },
  {
    "uniprot": "A0A3Q0KK72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK72"
  },
  {
    "uniprot": "A0A5K4ETB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETB9"
  },
  {
    "uniprot": "A0A5K4EPN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPN5"
  },
  {
    "uniprot": "A0A5K4F206",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F206"
  },
  {
    "uniprot": "P83914",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P83914"
  },
  {
    "uniprot": "A0A5K4F222",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F222"
  },
  {
    "uniprot": "A0A3Q0KPG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPG6"
  },
  {
    "uniprot": "A0A3Q0KSF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSF6"
  },
  {
    "uniprot": "A0A5K4EZ02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ02"
  },
  {
    "uniprot": "A0A5K4EEF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEF2"
  },
  {
    "uniprot": "A0A5K4F8A7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8A7"
  },
  {
    "uniprot": "A0A3Q0KV73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV73"
  },
  {
    "uniprot": "A0A5K4EPQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPQ8"
  },
  {
    "uniprot": "A0A3Q0KKE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKE2"
  },
  {
    "uniprot": "A0A5K4ENI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENI0"
  },
  {
    "uniprot": "G4V7C7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7C7"
  },
  {
    "uniprot": "A0A5K4EPK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPK2"
  },
  {
    "uniprot": "A0A5K4F741",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F741"
  },
  {
    "uniprot": "A0A3Q0KTR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTR8"
  },
  {
    "uniprot": "G4LU92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LU92"
  },
  {
    "uniprot": "A0A5K4E9I8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9I8"
  },
  {
    "uniprot": "A0A3Q0KKR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKR2"
  },
  {
    "uniprot": "G4VH14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH14"
  },
  {
    "uniprot": "A0A3Q0KT34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT34"
  },
  {
    "uniprot": "A0A5K4F4E0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4E0"
  },
  {
    "uniprot": "A0A5K4F4P4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4P4"
  },
  {
    "uniprot": "A0A5K4F4G5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4G5"
  },
  {
    "uniprot": "A0A3Q0KQT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQT1"
  },
  {
    "uniprot": "A0A3Q0KPV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPV3"
  },
  {
    "uniprot": "A0A3Q0KME0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KME0"
  },
  {
    "uniprot": "A0A3Q0KGN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGN3"
  },
  {
    "uniprot": "A0A5K4FDK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDK6"
  },
  {
    "uniprot": "A0A5K4FA30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA30"
  },
  {
    "uniprot": "A0A5K4EMI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMI7"
  },
  {
    "uniprot": "A0A5K4F2W5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2W5"
  },
  {
    "uniprot": "G4LZ63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ63"
  },
  {
    "uniprot": "G4LY04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY04"
  },
  {
    "uniprot": "A0A5K4EFI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFI2"
  },
  {
    "uniprot": "A0A5K4F8V6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8V6"
  },
  {
    "uniprot": "A0A3Q0KM47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM47"
  },
  {
    "uniprot": "G4VDY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDY7"
  },
  {
    "uniprot": "A0A3Q0KS55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS55"
  },
  {
    "uniprot": "A0A3Q0KBG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBG5"
  },
  {
    "uniprot": "A0A5K4EL47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL47"
  },
  {
    "uniprot": "A0A3Q0KVL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVL9"
  },
  {
    "uniprot": "A0A5K4EJV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJV4"
  },
  {
    "uniprot": "A0A3Q0KRT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRT8"
  },
  {
    "uniprot": "A0A3Q0KT11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT11"
  },
  {
    "uniprot": "A0A5K4EMU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMU8"
  },
  {
    "uniprot": "A0A5K4F971",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F971"
  },
  {
    "uniprot": "A0A3Q0KPW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPW0"
  },
  {
    "uniprot": "A0A5K4F3L1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3L1"
  },
  {
    "uniprot": "A0A5K4EKL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKL2"
  },
  {
    "uniprot": "A0A5K4F0T4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0T4"
  },
  {
    "uniprot": "A0A5K4F1H8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1H8"
  },
  {
    "uniprot": "A0A3Q0KBY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBY0"
  },
  {
    "uniprot": "A0A3Q0KFQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFQ6"
  },
  {
    "uniprot": "G4VDI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDI2"
  },
  {
    "uniprot": "A0A5K4F9T4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9T4"
  },
  {
    "uniprot": "A0A5K4F5C4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5C4"
  },
  {
    "uniprot": "A0A5K4EXM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXM3"
  },
  {
    "uniprot": "A0A5K4F3U3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3U3"
  },
  {
    "uniprot": "G4LZ79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ79"
  },
  {
    "uniprot": "A0A5K4F0V4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0V4"
  },
  {
    "uniprot": "A0A5K4F3V7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3V7"
  },
  {
    "uniprot": "G4LY37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY37"
  },
  {
    "uniprot": "A0A5K4EQ29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ29"
  },
  {
    "uniprot": "G4VH52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH52"
  },
  {
    "uniprot": "A0A5K4F1L7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1L7"
  },
  {
    "uniprot": "A0A3Q0KKK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKK7"
  },
  {
    "uniprot": "A0A3Q0KR94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR94"
  },
  {
    "uniprot": "A0A5K4EW95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW95"
  },
  {
    "uniprot": "G4VHU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHU3"
  },
  {
    "uniprot": "G4LU86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LU86"
  },
  {
    "uniprot": "G4VSU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSU0"
  },
  {
    "uniprot": "A0A3Q0KCN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCN0"
  },
  {
    "uniprot": "G4V5Q4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5Q4"
  },
  {
    "uniprot": "A0A3Q0KGP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGP0"
  },
  {
    "uniprot": "A0A5K4F6N9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6N9"
  },
  {
    "uniprot": "A0A3Q0KIH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIH7"
  },
  {
    "uniprot": "A0A5K4EAS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAS9"
  },
  {
    "uniprot": "G4V9N3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9N3"
  },
  {
    "uniprot": "A0A5K4ED23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED23"
  },
  {
    "uniprot": "G4VLN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLN8"
  },
  {
    "uniprot": "A0A5K4F6N0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6N0"
  },
  {
    "uniprot": "G4LXE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXE7"
  },
  {
    "uniprot": "A0A3Q0KDN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDN2"
  },
  {
    "uniprot": "P16463",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P16463"
  },
  {
    "uniprot": "G4VKI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKI9"
  },
  {
    "uniprot": "A0A3Q0KSN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSN7"
  },
  {
    "uniprot": "G4LYR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYR1"
  },
  {
    "uniprot": "A0A3Q0KQ55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ55"
  },
  {
    "uniprot": "A0A5K4EL08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL08"
  },
  {
    "uniprot": "Q86MW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q86MW9"
  },
  {
    "uniprot": "A0A5K4EBJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBJ2"
  },
  {
    "uniprot": "A0A3Q0KRF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRF2"
  },
  {
    "uniprot": "A0A3Q0KMU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMU1"
  },
  {
    "uniprot": "A0A5K4EJ55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJ55"
  },
  {
    "uniprot": "G4LZ15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ15"
  },
  {
    "uniprot": "Q2KMJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q2KMJ3"
  },
  {
    "uniprot": "A0A5K4F9V4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9V4"
  },
  {
    "uniprot": "A0A5K4F9F7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9F7"
  },
  {
    "uniprot": "A0A5K4ETV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETV5"
  },
  {
    "uniprot": "A0A5K4FD70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD70"
  },
  {
    "uniprot": "G4LWY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWY8"
  },
  {
    "uniprot": "A0A5K4FD11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD11"
  },
  {
    "uniprot": "A0A5K4EQ24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ24"
  },
  {
    "uniprot": "A0A5K4FE12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE12"
  },
  {
    "uniprot": "A0A3Q0KLJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLJ8"
  },
  {
    "uniprot": "A0A3Q0KQC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQC7"
  },
  {
    "uniprot": "A0A5K4EXL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXL5"
  },
  {
    "uniprot": "G4LY29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY29"
  },
  {
    "uniprot": "G4LV60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV60"
  },
  {
    "uniprot": "Q1WIQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q1WIQ4"
  },
  {
    "uniprot": "A0A3Q0KLY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLY0"
  },
  {
    "uniprot": "A0A5K4FB34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB34"
  },
  {
    "uniprot": "A0A5K4FGV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGV1"
  },
  {
    "uniprot": "G4LXX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXX5"
  },
  {
    "uniprot": "G4VG01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VG01"
  },
  {
    "uniprot": "A0A3Q0KG30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG30"
  },
  {
    "uniprot": "A0A3Q0KFS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFS7"
  },
  {
    "uniprot": "A0A5K4FCQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCQ4"
  },
  {
    "uniprot": "A0A5K4EUL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUL8"
  },
  {
    "uniprot": "G4VJ62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ62"
  },
  {
    "uniprot": "G4LW66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LW66"
  },
  {
    "uniprot": "A0A3Q0KP21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP21"
  },
  {
    "uniprot": "A0A3Q0KUT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUT2"
  },
  {
    "uniprot": "A0A5K4F5E5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5E5"
  },
  {
    "uniprot": "A0A3Q0KPT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPT7"
  },
  {
    "uniprot": "A0A3Q0KTW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTW3"
  },
  {
    "uniprot": "A0A5K4ERA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERA6"
  },
  {
    "uniprot": "A0A5K4F778",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F778"
  },
  {
    "uniprot": "A0A3Q0KLZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLZ0"
  },
  {
    "uniprot": "A0A3Q0KSH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSH2"
  },
  {
    "uniprot": "A0A5K4FEX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEX4"
  },
  {
    "uniprot": "A0A3Q0KIY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIY5"
  },
  {
    "uniprot": "A0A5K4EGN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGN2"
  },
  {
    "uniprot": "G4V7I9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7I9"
  },
  {
    "uniprot": "A0A3Q0KIU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIU5"
  },
  {
    "uniprot": "G4VNB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNB1"
  },
  {
    "uniprot": "A0A3Q0KE53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE53"
  },
  {
    "uniprot": "A0A5K4EVG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVG5"
  },
  {
    "uniprot": "A0A5K4EKR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKR7"
  },
  {
    "uniprot": "A0A5K4F9I3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9I3"
  },
  {
    "uniprot": "A0A5K4FB04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB04"
  },
  {
    "uniprot": "A0A3Q0KGS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGS5"
  },
  {
    "uniprot": "A0A3Q0KNB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNB0"
  },
  {
    "uniprot": "A0A3Q0KBX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBX2"
  },
  {
    "uniprot": "A0A3Q0KLH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLH9"
  },
  {
    "uniprot": "G4V6I2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6I2"
  },
  {
    "uniprot": "A0A3Q0KQU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQU5"
  },
  {
    "uniprot": "A0A5K4F2T4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2T4"
  },
  {
    "uniprot": "A0A5K4F1T7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1T7"
  },
  {
    "uniprot": "A0A3Q0KGC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGC6"
  },
  {
    "uniprot": "A0A5K4F4H5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4H5"
  },
  {
    "uniprot": "A0A5K4FAK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAK6"
  },
  {
    "uniprot": "A0A5K4EKN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKN5"
  },
  {
    "uniprot": "A0A3Q0KCW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCW9"
  },
  {
    "uniprot": "A0A5K4FC19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC19"
  },
  {
    "uniprot": "G4VKB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKB5"
  },
  {
    "uniprot": "A0A5K4EUV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUV3"
  },
  {
    "uniprot": "A0A5K4EMR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMR0"
  },
  {
    "uniprot": "A0A5K4F095",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F095"
  },
  {
    "uniprot": "A0A3Q0KR72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR72"
  },
  {
    "uniprot": "A0A5K4F991",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F991"
  },
  {
    "uniprot": "A0A3Q0KMS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMS1"
  },
  {
    "uniprot": "B8Y6H5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/B8Y6H5"
  },
  {
    "uniprot": "A0A3Q0KPW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPW7"
  },
  {
    "uniprot": "A0A3Q0KNL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNL4"
  },
  {
    "uniprot": "A0A5K4F959",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F959"
  },
  {
    "uniprot": "A0A5K4F7W1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7W1"
  },
  {
    "uniprot": "A0A5K4EQV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQV8"
  },
  {
    "uniprot": "A0A5K4EGB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGB5"
  },
  {
    "uniprot": "A0A5K4EBL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBL2"
  },
  {
    "uniprot": "A0A3Q0KNC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNC5"
  },
  {
    "uniprot": "Q26600",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26600"
  },
  {
    "uniprot": "A0A5K4FCX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCX8"
  },
  {
    "uniprot": "A0A5K4F919",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F919"
  },
  {
    "uniprot": "A0A5K4FFQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFQ8"
  },
  {
    "uniprot": "A0A5K4F4L7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4L7"
  },
  {
    "uniprot": "A0A5K4ELI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELI8"
  },
  {
    "uniprot": "A0A3Q0KUV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUV3"
  },
  {
    "uniprot": "G4V604",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V604"
  },
  {
    "uniprot": "A0A3Q0KVD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVD4"
  },
  {
    "uniprot": "G4VE02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VE02"
  },
  {
    "uniprot": "G4VRX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRX9"
  },
  {
    "uniprot": "G4M103",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M103"
  },
  {
    "uniprot": "A0A5K4EF97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EF97"
  },
  {
    "uniprot": "A0A3Q0KRY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRY9"
  },
  {
    "uniprot": "A0A3Q0KU25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU25"
  },
  {
    "uniprot": "A0A5K4F8S1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8S1"
  },
  {
    "uniprot": "A0A3Q0KSU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSU2"
  },
  {
    "uniprot": "A0A5K4FBN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBN2"
  },
  {
    "uniprot": "G4VCG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCG8"
  },
  {
    "uniprot": "A0A5K4FBW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBW0"
  },
  {
    "uniprot": "A0A3Q0KR26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR26"
  },
  {
    "uniprot": "A0A3Q0KEQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEQ9"
  },
  {
    "uniprot": "G4VP62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VP62"
  },
  {
    "uniprot": "A0A5K4ET95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET95"
  },
  {
    "uniprot": "A0A3Q0KHJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHJ1"
  },
  {
    "uniprot": "A0A3Q0KTE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTE1"
  },
  {
    "uniprot": "A0A3Q0KGV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGV2"
  },
  {
    "uniprot": "A0A3Q0KMD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMD2"
  },
  {
    "uniprot": "A0A3Q0KFB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFB4"
  },
  {
    "uniprot": "A0A5K4F2L0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2L0"
  },
  {
    "uniprot": "A0A5K4ETM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETM1"
  },
  {
    "uniprot": "A0A5K4F1M5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1M5"
  },
  {
    "uniprot": "G4V9T7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9T7"
  },
  {
    "uniprot": "A0A3Q0KQL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQL4"
  },
  {
    "uniprot": "A0A5K4F4G8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4G8"
  },
  {
    "uniprot": "G4VHH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHH2"
  },
  {
    "uniprot": "A0A5K4FB76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB76"
  },
  {
    "uniprot": "A0A3Q0KVL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVL4"
  },
  {
    "uniprot": "A0A3Q0KF02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF02"
  },
  {
    "uniprot": "A0A5K4EP73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP73"
  },
  {
    "uniprot": "G4M1U8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M1U8"
  },
  {
    "uniprot": "G4VN39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VN39"
  },
  {
    "uniprot": "A0A5K4F343",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F343"
  },
  {
    "uniprot": "A0A5K4FD51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD51"
  },
  {
    "uniprot": "A0A5K4F2F8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2F8"
  },
  {
    "uniprot": "A0A5K4F462",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F462"
  },
  {
    "uniprot": "A0A5K4F4F6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4F6"
  },
  {
    "uniprot": "A0A3Q0KL60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL60"
  },
  {
    "uniprot": "A0A5K4F7N4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7N4"
  },
  {
    "uniprot": "A0A5K4FBZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBZ9"
  },
  {
    "uniprot": "A0A5K4FBF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBF2"
  },
  {
    "uniprot": "A0A5K4EPT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPT1"
  },
  {
    "uniprot": "A0A5K4EXT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXT6"
  },
  {
    "uniprot": "A0A5K4F1L4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1L4"
  },
  {
    "uniprot": "A0A5K4EW05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW05"
  },
  {
    "uniprot": "A0A5K4F8M2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8M2"
  },
  {
    "uniprot": "A0A5K4EFG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFG7"
  },
  {
    "uniprot": "A0A3Q0KRS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRS9"
  },
  {
    "uniprot": "G4VH77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH77"
  },
  {
    "uniprot": "A0A3Q0KNL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNL5"
  },
  {
    "uniprot": "A0A5K4F1W9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1W9"
  },
  {
    "uniprot": "A0A3Q0KE47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE47"
  },
  {
    "uniprot": "A0A5K4F585",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F585"
  },
  {
    "uniprot": "G4LW69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LW69"
  },
  {
    "uniprot": "G4VBI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBI1"
  },
  {
    "uniprot": "G4V683",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V683"
  },
  {
    "uniprot": "G4VHJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHJ2"
  },
  {
    "uniprot": "A0A5K4E9H2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9H2"
  },
  {
    "uniprot": "G4V6L7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6L7"
  },
  {
    "uniprot": "A0A5K4FAI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAI1"
  },
  {
    "uniprot": "A0A5K4ET22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET22"
  },
  {
    "uniprot": "A0A5K4EWY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWY8"
  },
  {
    "uniprot": "A0A5K4F318",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F318"
  },
  {
    "uniprot": "A0A3Q0KNA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNA0"
  },
  {
    "uniprot": "A0A5K4F1F1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1F1"
  },
  {
    "uniprot": "A0A5K4F3I3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3I3"
  },
  {
    "uniprot": "A0A5K4FE68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE68"
  },
  {
    "uniprot": "A0A3Q0KRM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRM4"
  },
  {
    "uniprot": "A0A5K4EXP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXP8"
  },
  {
    "uniprot": "A0A5K4EXL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXL6"
  },
  {
    "uniprot": "A0A3Q0KRQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRQ4"
  },
  {
    "uniprot": "G4VAL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAL9"
  },
  {
    "uniprot": "A0A5K4F3N3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3N3"
  },
  {
    "uniprot": "G4VS05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS05"
  },
  {
    "uniprot": "G4VRK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRK9"
  },
  {
    "uniprot": "A0A3Q0KD51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD51"
  },
  {
    "uniprot": "G4VGV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGV1"
  },
  {
    "uniprot": "A0A5K4EMA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMA1"
  },
  {
    "uniprot": "A5H659",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A5H659"
  },
  {
    "uniprot": "A0A3Q0KQM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQM5"
  },
  {
    "uniprot": "A0A3Q0KTU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTU1"
  },
  {
    "uniprot": "A3RK85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A3RK85"
  },
  {
    "uniprot": "G4VSP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSP2"
  },
  {
    "uniprot": "A0A5K4EHI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHI6"
  },
  {
    "uniprot": "A0A3Q0KMX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMX6"
  },
  {
    "uniprot": "A0A5K4FD20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD20"
  },
  {
    "uniprot": "A0A5K4F7S4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7S4"
  },
  {
    "uniprot": "A0A5K4F9C2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9C2"
  },
  {
    "uniprot": "A0A3Q0KG69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG69"
  },
  {
    "uniprot": "G4VMM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMM4"
  },
  {
    "uniprot": "A0A5K4FCD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCD8"
  },
  {
    "uniprot": "G4VLH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLH1"
  },
  {
    "uniprot": "A0A5K4EUR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUR4"
  },
  {
    "uniprot": "A0A5K4EWJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWJ9"
  },
  {
    "uniprot": "A0A146MG53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MG53"
  },
  {
    "uniprot": "A0A3Q0KR05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR05"
  },
  {
    "uniprot": "A0A5K4EU70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU70"
  },
  {
    "uniprot": "A0A5K4FAX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAX9"
  },
  {
    "uniprot": "A0A3Q0KIX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIX9"
  },
  {
    "uniprot": "G4VLR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLR8"
  },
  {
    "uniprot": "A0A5K4F9A1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9A1"
  },
  {
    "uniprot": "G4V5Z8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5Z8"
  },
  {
    "uniprot": "A0A5K4F3C5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3C5"
  },
  {
    "uniprot": "A0A5K4ESN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESN2"
  },
  {
    "uniprot": "A0A5K4FAB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAB8"
  },
  {
    "uniprot": "G4VRL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRL9"
  },
  {
    "uniprot": "G4LWX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWX6"
  },
  {
    "uniprot": "A0A5K4EY11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY11"
  },
  {
    "uniprot": "A0A5K4F0R5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0R5"
  },
  {
    "uniprot": "A0A5K4EKV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKV4"
  },
  {
    "uniprot": "A0A5K4ED62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED62"
  },
  {
    "uniprot": "A0A3Q0KGI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGI4"
  },
  {
    "uniprot": "A0A3Q0KUS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUS2"
  },
  {
    "uniprot": "A0A5K4FAK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAK3"
  },
  {
    "uniprot": "A0A5K4F218",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F218"
  },
  {
    "uniprot": "A0A5K4F8Z6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Z6"
  },
  {
    "uniprot": "Q26605",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26605"
  },
  {
    "uniprot": "G4VP71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VP71"
  },
  {
    "uniprot": "A0A5K4FB60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB60"
  },
  {
    "uniprot": "A0A3Q0KS12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS12"
  },
  {
    "uniprot": "A0A5K4EYY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYY6"
  },
  {
    "uniprot": "A0A3Q0KBB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBB7"
  },
  {
    "uniprot": "A0A3Q0KRN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRN4"
  },
  {
    "uniprot": "A0A5K4F838",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F838"
  },
  {
    "uniprot": "G4VT12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT12"
  },
  {
    "uniprot": "A0A5K4EMG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMG4"
  },
  {
    "uniprot": "A0A5K4EMA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMA8"
  },
  {
    "uniprot": "A0A5K4F4G2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4G2"
  },
  {
    "uniprot": "A0A3Q0KCF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCF9"
  },
  {
    "uniprot": "G4VPD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPD0"
  },
  {
    "uniprot": "G4VDS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDS9"
  },
  {
    "uniprot": "G4LVT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVT3"
  },
  {
    "uniprot": "A0A3Q0KER3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KER3"
  },
  {
    "uniprot": "G4LYD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYD0"
  },
  {
    "uniprot": "A0A3Q0KCQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCQ9"
  },
  {
    "uniprot": "A0A3Q0KTA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTA6"
  },
  {
    "uniprot": "A0A5K4F214",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F214"
  },
  {
    "uniprot": "A0A5K4EMK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMK2"
  },
  {
    "uniprot": "A0A5K4ECW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECW3"
  },
  {
    "uniprot": "G4LVG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVG1"
  },
  {
    "uniprot": "A0A5K4F3N7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3N7"
  },
  {
    "uniprot": "G4VEP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEP8"
  },
  {
    "uniprot": "A0A3Q0KGG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGG8"
  },
  {
    "uniprot": "G4VCL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCL1"
  },
  {
    "uniprot": "G4LWA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWA9"
  },
  {
    "uniprot": "A0A5K4F7V4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7V4"
  },
  {
    "uniprot": "A0A3Q0KNV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNV9"
  },
  {
    "uniprot": "A0A3Q0KRP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRP5"
  },
  {
    "uniprot": "A0A5K4F8L4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8L4"
  },
  {
    "uniprot": "A0A3Q0KVJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVJ7"
  },
  {
    "uniprot": "A0A5K4EF77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EF77"
  },
  {
    "uniprot": "A0A3Q0KPS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPS9"
  },
  {
    "uniprot": "A0A3Q0KJL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJL9"
  },
  {
    "uniprot": "G4LXG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXG3"
  },
  {
    "uniprot": "A0A3Q0KK53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK53"
  },
  {
    "uniprot": "A0A5K4F2X7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2X7"
  },
  {
    "uniprot": "A0A5K4F9A0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9A0"
  },
  {
    "uniprot": "Q6BC92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q6BC92"
  },
  {
    "uniprot": "A0A5K4ENK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENK8"
  },
  {
    "uniprot": "A0A5K4FA85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA85"
  },
  {
    "uniprot": "A0A5K4FD59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD59"
  },
  {
    "uniprot": "A0A5K4FBD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBD6"
  },
  {
    "uniprot": "A0A3Q0KQ40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ40"
  },
  {
    "uniprot": "A0A5K4F6T0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6T0"
  },
  {
    "uniprot": "A0A5K4EBQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBQ3"
  },
  {
    "uniprot": "A0A5K4F8H4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8H4"
  },
  {
    "uniprot": "A0A5K4EW98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW98"
  },
  {
    "uniprot": "A0A3Q0KVF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVF9"
  },
  {
    "uniprot": "A0A3Q0KVF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVF2"
  },
  {
    "uniprot": "A0A5K4EW70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW70"
  },
  {
    "uniprot": "A0A3Q0KHB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHB0"
  },
  {
    "uniprot": "G4VJ83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ83"
  },
  {
    "uniprot": "A0A5K4EQK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQK9"
  },
  {
    "uniprot": "A0A5K4FBT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBT0"
  },
  {
    "uniprot": "G4LZC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZC5"
  },
  {
    "uniprot": "A0A3Q0KHJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHJ2"
  },
  {
    "uniprot": "A0A3Q0KQ49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ49"
  },
  {
    "uniprot": "A0A3Q0KF27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF27"
  },
  {
    "uniprot": "G4VMR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMR9"
  },
  {
    "uniprot": "A0A5K4EZH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZH3"
  },
  {
    "uniprot": "O01374",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/O01374"
  },
  {
    "uniprot": "G4VK53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK53"
  },
  {
    "uniprot": "A0A3Q0KR86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR86"
  },
  {
    "uniprot": "A0A5K4EXW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXW8"
  },
  {
    "uniprot": "A0A3Q0KGV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGV6"
  },
  {
    "uniprot": "A0A5K4EBL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBL9"
  },
  {
    "uniprot": "G4VRA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRA5"
  },
  {
    "uniprot": "G4VK98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK98"
  },
  {
    "uniprot": "A0A5K4EBY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBY5"
  },
  {
    "uniprot": "A0A5K4F5I7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5I7"
  },
  {
    "uniprot": "G4VS07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS07"
  },
  {
    "uniprot": "A0A5K4F1Y7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Y7"
  },
  {
    "uniprot": "A0A3Q0KD53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD53"
  },
  {
    "uniprot": "A0A5K4EL05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL05"
  },
  {
    "uniprot": "A0A5K4FAL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAL0"
  },
  {
    "uniprot": "A0A3Q0KIM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIM6"
  },
  {
    "uniprot": "A0A5K4F9N7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9N7"
  },
  {
    "uniprot": "G4VIJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIJ5"
  },
  {
    "uniprot": "A0A3Q0KM68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM68"
  },
  {
    "uniprot": "Q7YW72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q7YW72"
  },
  {
    "uniprot": "A0A5K4EUF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUF9"
  },
  {
    "uniprot": "A0A3Q0KF18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF18"
  },
  {
    "uniprot": "A0A5K4EZX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZX1"
  },
  {
    "uniprot": "A0A3Q0KN83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN83"
  },
  {
    "uniprot": "A0A5K4F395",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F395"
  },
  {
    "uniprot": "A0A3Q0KVH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVH7"
  },
  {
    "uniprot": "A0A5K4F3A6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3A6"
  },
  {
    "uniprot": "A0A5K4FFW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFW5"
  },
  {
    "uniprot": "E2EAJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/E2EAJ1"
  },
  {
    "uniprot": "A0A5K4F6B4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6B4"
  },
  {
    "uniprot": "G4V6M4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6M4"
  },
  {
    "uniprot": "C7DPD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C7DPD5"
  },
  {
    "uniprot": "G4VE87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VE87"
  },
  {
    "uniprot": "A0A3Q0KFP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFP0"
  },
  {
    "uniprot": "O02197",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/O02197"
  },
  {
    "uniprot": "A0A3Q0KLI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLI5"
  },
  {
    "uniprot": "A0A5K4EPW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPW7"
  },
  {
    "uniprot": "A0A3Q0KHH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHH9"
  },
  {
    "uniprot": "A0A5K4ER12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER12"
  },
  {
    "uniprot": "A0A5K4EXR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXR0"
  },
  {
    "uniprot": "A0A5K4EF00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EF00"
  },
  {
    "uniprot": "A0A5K4EFX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFX6"
  },
  {
    "uniprot": "A0A5K4FGA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGA5"
  },
  {
    "uniprot": "A0A5K4EA32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EA32"
  },
  {
    "uniprot": "A0A5K4ERK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERK2"
  },
  {
    "uniprot": "G4VGP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGP2"
  },
  {
    "uniprot": "A0A5K4FB71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB71"
  },
  {
    "uniprot": "A0A5K4EPY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPY9"
  },
  {
    "uniprot": "A0A5K4FCH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCH4"
  },
  {
    "uniprot": "A0A5K4F6T8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6T8"
  },
  {
    "uniprot": "A0A3Q0KN48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN48"
  },
  {
    "uniprot": "G4VLL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLL1"
  },
  {
    "uniprot": "A0A5K4F2N6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2N6"
  },
  {
    "uniprot": "A0A3Q0KVI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVI1"
  },
  {
    "uniprot": "A0A3Q0KIB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIB7"
  },
  {
    "uniprot": "A0A3Q0KPK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPK7"
  },
  {
    "uniprot": "A0A5K4FFS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFS5"
  },
  {
    "uniprot": "A0A5K4ENM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENM1"
  },
  {
    "uniprot": "G4VI10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI10"
  },
  {
    "uniprot": "A0A5K4F5T1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5T1"
  },
  {
    "uniprot": "A0A5K4EZK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZK2"
  },
  {
    "uniprot": "A0A5K4FBY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBY9"
  },
  {
    "uniprot": "A0A5K4F2N0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2N0"
  },
  {
    "uniprot": "A0A5K4F785",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F785"
  },
  {
    "uniprot": "A0A5K4ELU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELU5"
  },
  {
    "uniprot": "G4LXQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXQ2"
  },
  {
    "uniprot": "A0A5K4EWR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWR2"
  },
  {
    "uniprot": "A0A5K4FA59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA59"
  },
  {
    "uniprot": "A0A5K4F2E7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2E7"
  },
  {
    "uniprot": "G4LWF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWF7"
  },
  {
    "uniprot": "G4VDB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDB2"
  },
  {
    "uniprot": "A0A5K4F7K8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7K8"
  },
  {
    "uniprot": "Q26571",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26571"
  },
  {
    "uniprot": "A0A5K4EDL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDL3"
  },
  {
    "uniprot": "A0A5K4F6A2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6A2"
  },
  {
    "uniprot": "A0A5K4F419",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F419"
  },
  {
    "uniprot": "A0A5K4F7A7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7A7"
  },
  {
    "uniprot": "G4VQY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQY8"
  },
  {
    "uniprot": "A0A5K4F2N4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2N4"
  },
  {
    "uniprot": "A0A3Q0KH96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH96"
  },
  {
    "uniprot": "G4V8W2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8W2"
  },
  {
    "uniprot": "A0A3Q0KQR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQR2"
  },
  {
    "uniprot": "A0A5K4EPV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPV7"
  },
  {
    "uniprot": "A0A5K4F5M1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5M1"
  },
  {
    "uniprot": "G4VG55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VG55"
  },
  {
    "uniprot": "G4VBZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBZ2"
  },
  {
    "uniprot": "A0A5K4F8R0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8R0"
  },
  {
    "uniprot": "A0A5K4EYA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYA5"
  },
  {
    "uniprot": "A0A5K4EDV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDV2"
  },
  {
    "uniprot": "A0A5K4FA84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA84"
  },
  {
    "uniprot": "A0A5K4EHM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHM3"
  },
  {
    "uniprot": "A0A5K4FBH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBH6"
  },
  {
    "uniprot": "A0A5K4F3G8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3G8"
  },
  {
    "uniprot": "A0A3Q0KI93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI93"
  },
  {
    "uniprot": "C4Q820",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q820"
  },
  {
    "uniprot": "A0A5K4EYW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYW5"
  },
  {
    "uniprot": "A0A3Q0KJA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJA8"
  },
  {
    "uniprot": "G4VBN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBN1"
  },
  {
    "uniprot": "A0A5K4FAY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAY2"
  },
  {
    "uniprot": "A0A5K4ELZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELZ4"
  },
  {
    "uniprot": "G4LYY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYY5"
  },
  {
    "uniprot": "A0A3Q0KU78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU78"
  },
  {
    "uniprot": "A0A5K4FGI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGI5"
  },
  {
    "uniprot": "A0A5K4F552",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F552"
  },
  {
    "uniprot": "A0A5K4FC66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC66"
  },
  {
    "uniprot": "A0A5K4F2W9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2W9"
  },
  {
    "uniprot": "A0A3Q0KQF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQF5"
  },
  {
    "uniprot": "A0A5K4FB10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB10"
  },
  {
    "uniprot": "A0A3Q0KNN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNN4"
  },
  {
    "uniprot": "A0A3Q0KV92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV92"
  },
  {
    "uniprot": "G4LVC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVC1"
  },
  {
    "uniprot": "A0A5K4ER92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER92"
  },
  {
    "uniprot": "G4VHC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHC3"
  },
  {
    "uniprot": "A0A3Q0KJI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJI0"
  },
  {
    "uniprot": "A0A5K4EZP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZP4"
  },
  {
    "uniprot": "A0A5K4F5B0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5B0"
  },
  {
    "uniprot": "A0A3Q0KRI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRI0"
  },
  {
    "uniprot": "A0A5K4EER4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EER4"
  },
  {
    "uniprot": "G4V817",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V817"
  },
  {
    "uniprot": "C4QHC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QHC6"
  },
  {
    "uniprot": "A0A5K4F743",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F743"
  },
  {
    "uniprot": "A0A3Q0KKE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKE3"
  },
  {
    "uniprot": "A0A5K4F4H0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4H0"
  },
  {
    "uniprot": "A0A5K4FGA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGA9"
  },
  {
    "uniprot": "G4VBT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBT3"
  },
  {
    "uniprot": "G4V5Q2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5Q2"
  },
  {
    "uniprot": "A0A5K4F2K4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2K4"
  },
  {
    "uniprot": "G4VIK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIK4"
  },
  {
    "uniprot": "A0A5K4ES96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES96"
  },
  {
    "uniprot": "A0A3Q0KHB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHB1"
  },
  {
    "uniprot": "A0A5K4F7L9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7L9"
  },
  {
    "uniprot": "A0A3Q0KTF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTF5"
  },
  {
    "uniprot": "A0A5K4F9C1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9C1"
  },
  {
    "uniprot": "G4VEY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEY5"
  },
  {
    "uniprot": "A0A3Q0KKF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKF9"
  },
  {
    "uniprot": "A0A5K4EKT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKT2"
  },
  {
    "uniprot": "A0A3Q0KK89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK89"
  },
  {
    "uniprot": "G4LYI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYI8"
  },
  {
    "uniprot": "A0A5K4F8E6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8E6"
  },
  {
    "uniprot": "A0A146MJF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MJF6"
  },
  {
    "uniprot": "A0A5K4F5K8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5K8"
  },
  {
    "uniprot": "A0A5K4F0Q9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Q9"
  },
  {
    "uniprot": "A0A3Q0KN00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN00"
  },
  {
    "uniprot": "G4VQN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQN2"
  },
  {
    "uniprot": "G4VKM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKM0"
  },
  {
    "uniprot": "A0A5K4FBZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBZ1"
  },
  {
    "uniprot": "G4VRU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRU2"
  },
  {
    "uniprot": "G4LXW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXW0"
  },
  {
    "uniprot": "A0A3Q0KSJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSJ2"
  },
  {
    "uniprot": "A0A5K4F0U2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0U2"
  },
  {
    "uniprot": "A0A3Q0KRY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRY0"
  },
  {
    "uniprot": "A0A5K4E9X3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9X3"
  },
  {
    "uniprot": "A0A5K4FAN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAN0"
  },
  {
    "uniprot": "A0A5K4F6K5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6K5"
  },
  {
    "uniprot": "A0A3Q0KTE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTE5"
  },
  {
    "uniprot": "G4VM07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM07"
  },
  {
    "uniprot": "A0A5K4F6U1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6U1"
  },
  {
    "uniprot": "A0A5K4F759",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F759"
  },
  {
    "uniprot": "A0A5K4EBF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBF9"
  },
  {
    "uniprot": "A0A5K4EYD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYD4"
  },
  {
    "uniprot": "A0A5K4EWS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWS9"
  },
  {
    "uniprot": "G4LWQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWQ5"
  },
  {
    "uniprot": "A0A5K4FB03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB03"
  },
  {
    "uniprot": "A0A3Q0KSE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSE8"
  },
  {
    "uniprot": "A0A3Q0KDE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDE7"
  },
  {
    "uniprot": "A0A5K4F9F8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9F8"
  },
  {
    "uniprot": "A0A5K4F1S5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1S5"
  },
  {
    "uniprot": "A0A3Q0KVL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVL3"
  },
  {
    "uniprot": "A0A5K4EWY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWY2"
  },
  {
    "uniprot": "A0A5K4F850",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F850"
  },
  {
    "uniprot": "A0A5K4F813",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F813"
  },
  {
    "uniprot": "A0A3Q0KNW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNW4"
  },
  {
    "uniprot": "Q9NGC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q9NGC4"
  },
  {
    "uniprot": "A0A3Q0KFS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFS2"
  },
  {
    "uniprot": "A0A5K4ET94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET94"
  },
  {
    "uniprot": "A0A3Q0KRH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRH3"
  },
  {
    "uniprot": "A0A3Q0KUW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUW0"
  },
  {
    "uniprot": "A0A3Q0KM15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM15"
  },
  {
    "uniprot": "A0A3Q0KLX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLX9"
  },
  {
    "uniprot": "A0A5K4F4Z6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Z6"
  },
  {
    "uniprot": "G4VAW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAW9"
  },
  {
    "uniprot": "G4V7P2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7P2"
  },
  {
    "uniprot": "A0A3Q0KRK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRK9"
  },
  {
    "uniprot": "A0A3Q0KGV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGV9"
  },
  {
    "uniprot": "A0A5K4F366",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F366"
  },
  {
    "uniprot": "A0A3Q0KL35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL35"
  },
  {
    "uniprot": "A0A5K4EER0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EER0"
  },
  {
    "uniprot": "G4VJB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJB9"
  },
  {
    "uniprot": "A0A3Q0KE75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE75"
  },
  {
    "uniprot": "A0A5K4F4L1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4L1"
  },
  {
    "uniprot": "A0A3Q0KPY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPY0"
  },
  {
    "uniprot": "G4LX29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX29"
  },
  {
    "uniprot": "G4VA28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA28"
  },
  {
    "uniprot": "A0A5K4F057",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F057"
  },
  {
    "uniprot": "A0A5K4F3J8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3J8"
  },
  {
    "uniprot": "A0A5K4ELW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELW5"
  },
  {
    "uniprot": "G4LXE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXE1"
  },
  {
    "uniprot": "A0A5K4F8G9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8G9"
  },
  {
    "uniprot": "A0A5K4F6T7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6T7"
  },
  {
    "uniprot": "A0A3Q0KU93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU93"
  },
  {
    "uniprot": "G4VIX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIX3"
  },
  {
    "uniprot": "A0A3Q0KSQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSQ0"
  },
  {
    "uniprot": "G4VDY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDY8"
  },
  {
    "uniprot": "C4QJ46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QJ46"
  },
  {
    "uniprot": "A0A3Q0KUI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUI5"
  },
  {
    "uniprot": "G4V8W8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8W8"
  },
  {
    "uniprot": "A0A5K4EID2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EID2"
  },
  {
    "uniprot": "A0A5K4F536",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F536"
  },
  {
    "uniprot": "A0A5K4F0Y9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Y9"
  },
  {
    "uniprot": "A0A5K4FA56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA56"
  },
  {
    "uniprot": "A0A5K4FCG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCG5"
  },
  {
    "uniprot": "A0A5K4F0D2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0D2"
  },
  {
    "uniprot": "A0A3Q0KQI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQI1"
  },
  {
    "uniprot": "G4VGJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGJ7"
  },
  {
    "uniprot": "A0A5K4F7I5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7I5"
  },
  {
    "uniprot": "G4V886",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V886"
  },
  {
    "uniprot": "A0A5K4F589",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F589"
  },
  {
    "uniprot": "A0A3Q0KIL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIL1"
  },
  {
    "uniprot": "A0A3Q0KUZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUZ5"
  },
  {
    "uniprot": "G4VRU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRU0"
  },
  {
    "uniprot": "A0A3Q0KLY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLY7"
  },
  {
    "uniprot": "A0A5K4F641",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F641"
  },
  {
    "uniprot": "A0A5K4F3Y6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Y6"
  },
  {
    "uniprot": "A0A3Q0KR21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR21"
  },
  {
    "uniprot": "G4VIT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIT7"
  },
  {
    "uniprot": "A0A5K4FAX6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAX6"
  },
  {
    "uniprot": "A0A5K4FCR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCR0"
  },
  {
    "uniprot": "A0A5K4EHI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHI1"
  },
  {
    "uniprot": "A0A5K4E8T4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8T4"
  },
  {
    "uniprot": "A0A3Q0KIZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIZ6"
  },
  {
    "uniprot": "A0A3Q0KRM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRM5"
  },
  {
    "uniprot": "A0A3Q0KGL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGL3"
  },
  {
    "uniprot": "A0A5K4F0P3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0P3"
  },
  {
    "uniprot": "G4VEX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEX1"
  },
  {
    "uniprot": "A0A3Q0KRX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRX3"
  },
  {
    "uniprot": "A0A3Q0KKZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKZ0"
  },
  {
    "uniprot": "A0A3Q0KLL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLL3"
  },
  {
    "uniprot": "A0A5K4F845",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F845"
  },
  {
    "uniprot": "G4VLW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLW6"
  },
  {
    "uniprot": "A0A5K4ES52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES52"
  },
  {
    "uniprot": "A0A3Q0KIV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIV2"
  },
  {
    "uniprot": "A0A5K4F549",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F549"
  },
  {
    "uniprot": "G4LXP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXP3"
  },
  {
    "uniprot": "A0A3Q0KHZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHZ1"
  },
  {
    "uniprot": "G4VLP3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLP3"
  },
  {
    "uniprot": "A0A5K4F246",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F246"
  },
  {
    "uniprot": "A0A5K4F8R9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8R9"
  },
  {
    "uniprot": "A0A5K4ES25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES25"
  },
  {
    "uniprot": "A0A3Q0KFS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFS8"
  },
  {
    "uniprot": "A0A5K4F9P4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9P4"
  },
  {
    "uniprot": "A0A5K4F6A7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6A7"
  },
  {
    "uniprot": "A0A3Q0KQ70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ70"
  },
  {
    "uniprot": "A0A3Q0KQH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQH3"
  },
  {
    "uniprot": "A0A3Q0KMX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMX0"
  },
  {
    "uniprot": "A0A3Q0KMW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMW5"
  },
  {
    "uniprot": "A0A5K4ECI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECI5"
  },
  {
    "uniprot": "A0A5K4F252",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F252"
  },
  {
    "uniprot": "A0A3Q0KFQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFQ4"
  },
  {
    "uniprot": "A0A3Q0KL69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL69"
  },
  {
    "uniprot": "A0A5K4F504",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F504"
  },
  {
    "uniprot": "A0A3Q0KK91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK91"
  },
  {
    "uniprot": "A0A3Q0KVC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVC2"
  },
  {
    "uniprot": "A0A5K4F809",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F809"
  },
  {
    "uniprot": "A0A5K4F7H5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7H5"
  },
  {
    "uniprot": "A0A3Q0KQ52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ52"
  },
  {
    "uniprot": "A0A5K4EIR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIR5"
  },
  {
    "uniprot": "P12812",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P12812"
  },
  {
    "uniprot": "A0A5K4EQ89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ89"
  },
  {
    "uniprot": "A0A5K4EQX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQX2"
  },
  {
    "uniprot": "A0A5K4FFG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFG5"
  },
  {
    "uniprot": "A0A5K4ETX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETX3"
  },
  {
    "uniprot": "G4LX21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX21"
  },
  {
    "uniprot": "G4VMA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMA5"
  },
  {
    "uniprot": "B1AAM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/B1AAM6"
  },
  {
    "uniprot": "C4Q592",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q592"
  },
  {
    "uniprot": "A0A5K4F4F9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4F9"
  },
  {
    "uniprot": "A0A5K4EFL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFL6"
  },
  {
    "uniprot": "G4LZB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZB3"
  },
  {
    "uniprot": "A0A5K4EZ37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ37"
  },
  {
    "uniprot": "G4VA69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA69"
  },
  {
    "uniprot": "A0A5K4F0Q1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Q1"
  },
  {
    "uniprot": "A0A3Q0KBL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBL3"
  },
  {
    "uniprot": "G4VGZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGZ9"
  },
  {
    "uniprot": "G4VQQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQQ8"
  },
  {
    "uniprot": "G4VPH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPH5"
  },
  {
    "uniprot": "A0A3Q0KMS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMS2"
  },
  {
    "uniprot": "G4VAU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAU9"
  },
  {
    "uniprot": "A0A5K4F7H9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7H9"
  },
  {
    "uniprot": "A0A5K4FEU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEU8"
  },
  {
    "uniprot": "A0A3Q0KIX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIX8"
  },
  {
    "uniprot": "G4VJB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJB3"
  },
  {
    "uniprot": "A0A5K4EFQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFQ9"
  },
  {
    "uniprot": "A0A5K4FD55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD55"
  },
  {
    "uniprot": "A0A5K4ETP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETP1"
  },
  {
    "uniprot": "G4VM63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM63"
  },
  {
    "uniprot": "A0A5K4F4Q1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Q1"
  },
  {
    "uniprot": "A0A5K4F9L4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9L4"
  },
  {
    "uniprot": "A0A5K4EB61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB61"
  },
  {
    "uniprot": "G4VF67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF67"
  },
  {
    "uniprot": "A0A5K4FC51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC51"
  },
  {
    "uniprot": "A0A3Q0KVK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVK3"
  },
  {
    "uniprot": "A0A3Q0KIR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIR9"
  },
  {
    "uniprot": "A0A5K4FCP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCP1"
  },
  {
    "uniprot": "A0A5K4FDM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDM9"
  },
  {
    "uniprot": "G4LWW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWW1"
  },
  {
    "uniprot": "A0A5K4EIB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIB4"
  },
  {
    "uniprot": "A0A5K4FBY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBY4"
  },
  {
    "uniprot": "A0A5K4F2P9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2P9"
  },
  {
    "uniprot": "A0A3Q0KBP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBP2"
  },
  {
    "uniprot": "A0A3Q0KPN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPN5"
  },
  {
    "uniprot": "A0A5K4F074",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F074"
  },
  {
    "uniprot": "A0A3Q0KCL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCL1"
  },
  {
    "uniprot": "A0A5K4EY98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY98"
  },
  {
    "uniprot": "A0A3Q0KQT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQT7"
  },
  {
    "uniprot": "A0A5K4EP43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP43"
  },
  {
    "uniprot": "A0A3Q0KSN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSN3"
  },
  {
    "uniprot": "A0A5K4F0M4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0M4"
  },
  {
    "uniprot": "A0A5K4EMF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMF8"
  },
  {
    "uniprot": "A0A3Q0KD80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD80"
  },
  {
    "uniprot": "G4V6E9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6E9"
  },
  {
    "uniprot": "G4M143",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M143"
  },
  {
    "uniprot": "A0A5K4EUS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUS5"
  },
  {
    "uniprot": "A0A5K4FAG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAG0"
  },
  {
    "uniprot": "G4VCQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCQ2"
  },
  {
    "uniprot": "A0A5K4F0B5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0B5"
  },
  {
    "uniprot": "A0A5K4F3J3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3J3"
  },
  {
    "uniprot": "A0A3Q0KPL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPL3"
  },
  {
    "uniprot": "G4LVD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVD8"
  },
  {
    "uniprot": "G4LZZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZZ8"
  },
  {
    "uniprot": "A0A5K4FCA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCA1"
  },
  {
    "uniprot": "G4V6D7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6D7"
  },
  {
    "uniprot": "A0A3Q0KTC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTC7"
  },
  {
    "uniprot": "C0L926",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C0L926"
  },
  {
    "uniprot": "A0A5K4F565",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F565"
  },
  {
    "uniprot": "A0A5K4FEA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEA5"
  },
  {
    "uniprot": "A0A3Q0KI95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI95"
  },
  {
    "uniprot": "G4V5N4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5N4"
  },
  {
    "uniprot": "A0A3Q0KMT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMT3"
  },
  {
    "uniprot": "G4V9Q9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9Q9"
  },
  {
    "uniprot": "A0A3Q0KM31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM31"
  },
  {
    "uniprot": "A0A5K4EFW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFW0"
  },
  {
    "uniprot": "A0A5K4EVZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVZ0"
  },
  {
    "uniprot": "C1M1E5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C1M1E5"
  },
  {
    "uniprot": "G4VTR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTR5"
  },
  {
    "uniprot": "A0A5K4FAB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAB9"
  },
  {
    "uniprot": "A0A5K4ENY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENY5"
  },
  {
    "uniprot": "A0A5K4F2W4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2W4"
  },
  {
    "uniprot": "A0A3Q0KCG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCG1"
  },
  {
    "uniprot": "G4VKY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKY4"
  },
  {
    "uniprot": "A0A5K4F300",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F300"
  },
  {
    "uniprot": "A0A5K4FE71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE71"
  },
  {
    "uniprot": "G4VIB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIB3"
  },
  {
    "uniprot": "A0A5K4F5V9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5V9"
  },
  {
    "uniprot": "A0A3Q0KQ32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ32"
  },
  {
    "uniprot": "A0A5K4F4D1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4D1"
  },
  {
    "uniprot": "A0A5K4FBG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBG1"
  },
  {
    "uniprot": "A0A5K4F4Q8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Q8"
  },
  {
    "uniprot": "A0A3Q0KL37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL37"
  },
  {
    "uniprot": "A0A5K4FB23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB23"
  },
  {
    "uniprot": "A0A5K4ED00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED00"
  },
  {
    "uniprot": "A0A5K4EBC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBC8"
  },
  {
    "uniprot": "A0A5K4EIL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIL3"
  },
  {
    "uniprot": "A0A3Q0KNY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNY8"
  },
  {
    "uniprot": "A0A5K4F6K6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6K6"
  },
  {
    "uniprot": "G4LUZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUZ0"
  },
  {
    "uniprot": "A0A5K4EXJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXJ6"
  },
  {
    "uniprot": "A0A5K4F2K7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2K7"
  },
  {
    "uniprot": "G4V9A3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9A3"
  },
  {
    "uniprot": "A0A5K4FG12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FG12"
  },
  {
    "uniprot": "A0A3Q0KDZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDZ9"
  },
  {
    "uniprot": "A0A5K4FBN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBN0"
  },
  {
    "uniprot": "A0A5K4F2V9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2V9"
  },
  {
    "uniprot": "A0A5K4F8K4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8K4"
  },
  {
    "uniprot": "A0A3Q0KM62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM62"
  },
  {
    "uniprot": "A0A3Q0KNA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNA5"
  },
  {
    "uniprot": "A0A3Q0KSM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSM6"
  },
  {
    "uniprot": "A0A3Q0KTI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTI9"
  },
  {
    "uniprot": "A0A3Q0KSW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSW4"
  },
  {
    "uniprot": "A0A5K4F109",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F109"
  },
  {
    "uniprot": "G4V7T7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7T7"
  },
  {
    "uniprot": "A0A5K4F2A2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2A2"
  },
  {
    "uniprot": "A0A5K4FB49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB49"
  },
  {
    "uniprot": "A0A5K4F4Y0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Y0"
  },
  {
    "uniprot": "G4VF95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF95"
  },
  {
    "uniprot": "G4VEZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEZ0"
  },
  {
    "uniprot": "A0A5K4F7J5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7J5"
  },
  {
    "uniprot": "G4VQV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQV0"
  },
  {
    "uniprot": "A0A5K4F1D8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1D8"
  },
  {
    "uniprot": "A0A5K4EVM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVM0"
  },
  {
    "uniprot": "A0A3Q0KIM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIM0"
  },
  {
    "uniprot": "A0A5K4F592",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F592"
  },
  {
    "uniprot": "G4LYK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYK6"
  },
  {
    "uniprot": "A0A3Q0KPK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPK4"
  },
  {
    "uniprot": "A0A3Q0KCF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCF8"
  },
  {
    "uniprot": "A0A5K4EJ12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJ12"
  },
  {
    "uniprot": "A0A3Q0KUG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUG0"
  },
  {
    "uniprot": "A0A5K4EHV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHV1"
  },
  {
    "uniprot": "G4VG69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VG69"
  },
  {
    "uniprot": "A0A3Q0KVJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVJ6"
  },
  {
    "uniprot": "G4VEZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEZ9"
  },
  {
    "uniprot": "A0A5K4FAE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAE7"
  },
  {
    "uniprot": "A0A5K4F1J5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1J5"
  },
  {
    "uniprot": "A0A5K4ES79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES79"
  },
  {
    "uniprot": "A0A5K4EKT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKT6"
  },
  {
    "uniprot": "A0A3Q0KH02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH02"
  },
  {
    "uniprot": "A0A5K4FCN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCN9"
  },
  {
    "uniprot": "A0A3Q0KM59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM59"
  },
  {
    "uniprot": "A0A5K4F3X6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3X6"
  },
  {
    "uniprot": "A0A5K4ER30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER30"
  },
  {
    "uniprot": "A0A5K4EDK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDK5"
  },
  {
    "uniprot": "A0A3Q0KJR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJR3"
  },
  {
    "uniprot": "A0A5K4EMS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMS5"
  },
  {
    "uniprot": "A0A5K4F7D1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7D1"
  },
  {
    "uniprot": "G4VGD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGD1"
  },
  {
    "uniprot": "G4V891",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V891"
  },
  {
    "uniprot": "A0A5K4F2S2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2S2"
  },
  {
    "uniprot": "A0A5K4F1D9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1D9"
  },
  {
    "uniprot": "A0A3Q0KG28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG28"
  },
  {
    "uniprot": "A0A3Q0KKW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKW6"
  },
  {
    "uniprot": "A0A3Q0KU01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU01"
  },
  {
    "uniprot": "A0A5K4FDE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDE1"
  },
  {
    "uniprot": "A0A5K4F195",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F195"
  },
  {
    "uniprot": "A0A5K4F4J8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4J8"
  },
  {
    "uniprot": "A0A5K4F0K7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0K7"
  },
  {
    "uniprot": "A0A5K4EXA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXA0"
  },
  {
    "uniprot": "A0A5K4EU82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU82"
  },
  {
    "uniprot": "A0A3Q0KC34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC34"
  },
  {
    "uniprot": "A0A3Q0KG86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG86"
  },
  {
    "uniprot": "G4VJ36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ36"
  },
  {
    "uniprot": "G4V6D5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6D5"
  },
  {
    "uniprot": "A0A3Q0KMW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMW9"
  },
  {
    "uniprot": "A0A3Q0KQ91",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ91"
  },
  {
    "uniprot": "A0A5K4EWI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWI2"
  },
  {
    "uniprot": "A0A3Q0KLU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLU8"
  },
  {
    "uniprot": "G4VCN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCN1"
  },
  {
    "uniprot": "A0A5K4ET08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET08"
  },
  {
    "uniprot": "A0A3Q0KTM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTM4"
  },
  {
    "uniprot": "A0A5K4ESL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESL7"
  },
  {
    "uniprot": "G4LXF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXF2"
  },
  {
    "uniprot": "A0A5K4FEQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEQ4"
  },
  {
    "uniprot": "A0A5K4FDM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDM0"
  },
  {
    "uniprot": "A0A5K4F416",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F416"
  },
  {
    "uniprot": "A0A5K4F1E7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1E7"
  },
  {
    "uniprot": "A0A3Q0KUZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUZ1"
  },
  {
    "uniprot": "A0A5K4EZB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZB2"
  },
  {
    "uniprot": "G4VBC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBC4"
  },
  {
    "uniprot": "A0A3Q0KT86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT86"
  },
  {
    "uniprot": "A0A3Q0KRS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRS7"
  },
  {
    "uniprot": "A0A3Q0KF51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF51"
  },
  {
    "uniprot": "G4LZX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZX8"
  },
  {
    "uniprot": "A0A3Q0KTH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTH4"
  },
  {
    "uniprot": "A0A5K4FB15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB15"
  },
  {
    "uniprot": "A0A3Q0KVI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVI0"
  },
  {
    "uniprot": "A0A5K4FCI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCI3"
  },
  {
    "uniprot": "A0A3Q0KBX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBX9"
  },
  {
    "uniprot": "A0A5K4F920",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F920"
  },
  {
    "uniprot": "A0A5K4EV06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV06"
  },
  {
    "uniprot": "A0A5K4EK15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK15"
  },
  {
    "uniprot": "A0A5K4ET34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET34"
  },
  {
    "uniprot": "A0A5K4FDN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDN6"
  },
  {
    "uniprot": "A0A5K4EP05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP05"
  },
  {
    "uniprot": "A0A5K4ETR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETR8"
  },
  {
    "uniprot": "A0A5K4F2U9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2U9"
  },
  {
    "uniprot": "A0A3Q0KLG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLG0"
  },
  {
    "uniprot": "A0A3Q0KQN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQN1"
  },
  {
    "uniprot": "G4VN09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VN09"
  },
  {
    "uniprot": "G4V6S5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6S5"
  },
  {
    "uniprot": "A0A3Q0KDL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDL1"
  },
  {
    "uniprot": "A0A3Q0KDZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDZ2"
  },
  {
    "uniprot": "G4VLL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLL8"
  },
  {
    "uniprot": "A0A5K4EU94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU94"
  },
  {
    "uniprot": "A0A3Q0KB96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KB96"
  },
  {
    "uniprot": "A0A5K4F450",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F450"
  },
  {
    "uniprot": "A0A5K4FDU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDU3"
  },
  {
    "uniprot": "A0A5K4F3G2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3G2"
  },
  {
    "uniprot": "A0A5K4F1T3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1T3"
  },
  {
    "uniprot": "A0A3Q0KU29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU29"
  },
  {
    "uniprot": "A0A3Q0KNS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNS1"
  },
  {
    "uniprot": "A0A5K4FAL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAL6"
  },
  {
    "uniprot": "A0A3Q0KII1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KII1"
  },
  {
    "uniprot": "A0A3Q0KPJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPJ5"
  },
  {
    "uniprot": "A0A3Q0KCZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCZ6"
  },
  {
    "uniprot": "A0A5K4ER62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER62"
  },
  {
    "uniprot": "A0A3Q0KEH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEH2"
  },
  {
    "uniprot": "A0A5K4F0Y0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Y0"
  },
  {
    "uniprot": "A0A5K4FCK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCK2"
  },
  {
    "uniprot": "A0A3Q0KVD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVD1"
  },
  {
    "uniprot": "A0A5K4FCD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCD1"
  },
  {
    "uniprot": "A0A5K4EZN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZN6"
  },
  {
    "uniprot": "G4VLJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLJ0"
  },
  {
    "uniprot": "A0A3Q0KFV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFV6"
  },
  {
    "uniprot": "A0A5K4F0D8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0D8"
  },
  {
    "uniprot": "A0A5K4ET47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET47"
  },
  {
    "uniprot": "A0A3Q0KH30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH30"
  },
  {
    "uniprot": "A0A3Q0KFM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFM8"
  },
  {
    "uniprot": "A0A5K4F4T7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4T7"
  },
  {
    "uniprot": "A0A3Q0KM57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM57"
  },
  {
    "uniprot": "G4V7E6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7E6"
  },
  {
    "uniprot": "A0A3Q0KMJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMJ4"
  },
  {
    "uniprot": "G4VG83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VG83"
  },
  {
    "uniprot": "G4VQK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQK6"
  },
  {
    "uniprot": "A0A3Q0KLD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLD8"
  },
  {
    "uniprot": "G4V805",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V805"
  },
  {
    "uniprot": "A0A3Q0KPJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPJ0"
  },
  {
    "uniprot": "G4VHC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHC4"
  },
  {
    "uniprot": "A0A5K4EE28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE28"
  },
  {
    "uniprot": "A0A5K4F327",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F327"
  },
  {
    "uniprot": "A0A5K4E7T8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E7T8"
  },
  {
    "uniprot": "A0A5K4F7J9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7J9"
  },
  {
    "uniprot": "A0A5K4FBR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBR5"
  },
  {
    "uniprot": "A0A5K4F5D3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5D3"
  },
  {
    "uniprot": "A0A5K4F8D4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8D4"
  },
  {
    "uniprot": "A0A5K4F2B7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2B7"
  },
  {
    "uniprot": "G4LZ86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ86"
  },
  {
    "uniprot": "A0A5K4FB30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB30"
  },
  {
    "uniprot": "A0A3Q0KK87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK87"
  },
  {
    "uniprot": "W8GKU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/W8GKU3"
  },
  {
    "uniprot": "A0A3Q0KUL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUL7"
  },
  {
    "uniprot": "A0A3Q0KEZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEZ3"
  },
  {
    "uniprot": "A0A3Q0KEX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEX7"
  },
  {
    "uniprot": "A0A5K4EL37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL37"
  },
  {
    "uniprot": "A0A5K4FB93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB93"
  },
  {
    "uniprot": "A0A5K4F859",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F859"
  },
  {
    "uniprot": "A0A3Q0KT56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT56"
  },
  {
    "uniprot": "A0A3Q0KSC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSC1"
  },
  {
    "uniprot": "A0A5K4F1Y2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Y2"
  },
  {
    "uniprot": "A0A5K4F2L4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2L4"
  },
  {
    "uniprot": "A0A5K4FA78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA78"
  },
  {
    "uniprot": "A0A5K4FAX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAX4"
  },
  {
    "uniprot": "A0A3Q0KRA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRA1"
  },
  {
    "uniprot": "A0A3Q0KLB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLB7"
  },
  {
    "uniprot": "A0A5K4F4X8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4X8"
  },
  {
    "uniprot": "A0A5K4F227",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F227"
  },
  {
    "uniprot": "G4VDI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDI1"
  },
  {
    "uniprot": "A0A5K4EMN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMN5"
  },
  {
    "uniprot": "A0A5K4FAG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAG7"
  },
  {
    "uniprot": "A0A3Q0KPB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPB6"
  },
  {
    "uniprot": "A0A5K4ERI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERI9"
  },
  {
    "uniprot": "A0A3Q0KS99",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS99"
  },
  {
    "uniprot": "A0A5K4F6H0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6H0"
  },
  {
    "uniprot": "A0A5K4EJ31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJ31"
  },
  {
    "uniprot": "A0A1C9A1H6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A1C9A1H6"
  },
  {
    "uniprot": "A0A5K4EBR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBR1"
  },
  {
    "uniprot": "A0A3Q0KUP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUP2"
  },
  {
    "uniprot": "A0A3Q0KSN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSN6"
  },
  {
    "uniprot": "A0A3Q0KSW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSW0"
  },
  {
    "uniprot": "G4VH69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH69"
  },
  {
    "uniprot": "A0A3Q0KEC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEC5"
  },
  {
    "uniprot": "A0A5K4EX29",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX29"
  },
  {
    "uniprot": "G4VGT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGT9"
  },
  {
    "uniprot": "A0A5K4E9W4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9W4"
  },
  {
    "uniprot": "A0A5K4FAP7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAP7"
  },
  {
    "uniprot": "A0A5K4EVF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVF5"
  },
  {
    "uniprot": "G4VSM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSM9"
  },
  {
    "uniprot": "A0A5K4F2E9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2E9"
  },
  {
    "uniprot": "A0A3Q0KN34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN34"
  },
  {
    "uniprot": "A0A5K4EF75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EF75"
  },
  {
    "uniprot": "A0A3Q0KU88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU88"
  },
  {
    "uniprot": "A0A3Q0KGK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGK5"
  },
  {
    "uniprot": "A0A5K4EX83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX83"
  },
  {
    "uniprot": "G4LVF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVF8"
  },
  {
    "uniprot": "A0A5K4F5I5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5I5"
  },
  {
    "uniprot": "A0A5K4F0V5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0V5"
  },
  {
    "uniprot": "A0A3Q0KUH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUH2"
  },
  {
    "uniprot": "A0A5K4EJV5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJV5"
  },
  {
    "uniprot": "A0A3Q0KCB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCB8"
  },
  {
    "uniprot": "A0A5K4EQ80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ80"
  },
  {
    "uniprot": "A0A5K4FBT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBT3"
  },
  {
    "uniprot": "A0A3Q0KKH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKH6"
  },
  {
    "uniprot": "A0A3Q0KUT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUT3"
  },
  {
    "uniprot": "G4VPR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPR7"
  },
  {
    "uniprot": "A0A3Q0KTU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTU8"
  },
  {
    "uniprot": "G4LUD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUD8"
  },
  {
    "uniprot": "A0A3Q0KRZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRZ3"
  },
  {
    "uniprot": "A0A5K4F1S0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1S0"
  },
  {
    "uniprot": "G4VD81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD81"
  },
  {
    "uniprot": "A0A5K4FB90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB90"
  },
  {
    "uniprot": "G4VM34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VM34"
  },
  {
    "uniprot": "A0A5K4F7Y0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Y0"
  },
  {
    "uniprot": "G4VAC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAC5"
  },
  {
    "uniprot": "A0A5K4FAE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAE9"
  },
  {
    "uniprot": "A0A5K4EKP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKP2"
  },
  {
    "uniprot": "A0A3Q0KC72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC72"
  },
  {
    "uniprot": "A0A3Q0KD23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD23"
  },
  {
    "uniprot": "A0A5K4FA94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA94"
  },
  {
    "uniprot": "G4VBH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBH2"
  },
  {
    "uniprot": "A0A3Q0KP35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP35"
  },
  {
    "uniprot": "A0A5K4F7B7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7B7"
  },
  {
    "uniprot": "A0A5K4FA36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA36"
  },
  {
    "uniprot": "A0A5K4EUX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUX3"
  },
  {
    "uniprot": "C4QQ82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QQ82"
  },
  {
    "uniprot": "G4VRL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRL8"
  },
  {
    "uniprot": "A0A3Q0KPQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPQ8"
  },
  {
    "uniprot": "G4V896",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V896"
  },
  {
    "uniprot": "A0A3Q0KTD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTD4"
  },
  {
    "uniprot": "A0A5K4FB02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB02"
  },
  {
    "uniprot": "A0A5K4FB52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB52"
  },
  {
    "uniprot": "A0A5K4EEM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEM9"
  },
  {
    "uniprot": "A0A3Q0KPA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPA0"
  },
  {
    "uniprot": "A0A5K4F8N3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8N3"
  },
  {
    "uniprot": "G4VD04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD04"
  },
  {
    "uniprot": "G4VN34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VN34"
  },
  {
    "uniprot": "A0A5K4FDU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDU7"
  },
  {
    "uniprot": "Q26534",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26534"
  },
  {
    "uniprot": "A0A3Q0KQQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQQ4"
  },
  {
    "uniprot": "G4VDI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDI4"
  },
  {
    "uniprot": "A0A5K4EV94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV94"
  },
  {
    "uniprot": "A0A5K4EPA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPA9"
  },
  {
    "uniprot": "G4V5J1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5J1"
  },
  {
    "uniprot": "A0A5K4F9Y7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Y7"
  },
  {
    "uniprot": "G4VHM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHM9"
  },
  {
    "uniprot": "A0A3Q0KM34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM34"
  },
  {
    "uniprot": "A0A5K4F0F8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0F8"
  },
  {
    "uniprot": "A0A5K4F2R7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2R7"
  },
  {
    "uniprot": "A0A5K4EDA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDA2"
  },
  {
    "uniprot": "C4QDY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QDY4"
  },
  {
    "uniprot": "A0A3Q0KDW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDW1"
  },
  {
    "uniprot": "A0A3Q0KUQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUQ2"
  },
  {
    "uniprot": "A0A5K4F6M9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6M9"
  },
  {
    "uniprot": "G4M0I5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0I5"
  },
  {
    "uniprot": "A0A3Q0KSK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSK4"
  },
  {
    "uniprot": "A0A5K4F1C6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1C6"
  },
  {
    "uniprot": "G4LWR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWR5"
  },
  {
    "uniprot": "A0A5K4FD23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD23"
  },
  {
    "uniprot": "A0A3Q0KCB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCB2"
  },
  {
    "uniprot": "A0A5K4FA33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA33"
  },
  {
    "uniprot": "G4LUL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUL1"
  },
  {
    "uniprot": "A0A5K4F7T0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7T0"
  },
  {
    "uniprot": "A0A5K4EBU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBU7"
  },
  {
    "uniprot": "A0A5K4ENY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENY8"
  },
  {
    "uniprot": "G4VF49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF49"
  },
  {
    "uniprot": "A0A5K4F9I7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9I7"
  },
  {
    "uniprot": "G4VPM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPM2"
  },
  {
    "uniprot": "A0A5K4FDY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDY1"
  },
  {
    "uniprot": "A0A5K4F4U9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4U9"
  },
  {
    "uniprot": "A0A3Q0KSJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSJ5"
  },
  {
    "uniprot": "A0A5K4FC05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC05"
  },
  {
    "uniprot": "A0A146MHS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MHS2"
  },
  {
    "uniprot": "A0A5K4ES43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES43"
  },
  {
    "uniprot": "G4LVE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVE3"
  },
  {
    "uniprot": "A0A5K4F491",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F491"
  },
  {
    "uniprot": "A0A5K4ES59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES59"
  },
  {
    "uniprot": "A0A5K4FEN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEN3"
  },
  {
    "uniprot": "A0A5K4F600",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F600"
  },
  {
    "uniprot": "A0A5K4ERF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERF3"
  },
  {
    "uniprot": "A0A5K4E8C9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8C9"
  },
  {
    "uniprot": "G4VK41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK41"
  },
  {
    "uniprot": "A0A5K4F998",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F998"
  },
  {
    "uniprot": "G4LXR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXR8"
  },
  {
    "uniprot": "A0A3Q0KTH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTH7"
  },
  {
    "uniprot": "A0A3Q0KTF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTF2"
  },
  {
    "uniprot": "A0A5K4FE88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE88"
  },
  {
    "uniprot": "A0A3Q0KGG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGG0"
  },
  {
    "uniprot": "A0A3Q0KJW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJW6"
  },
  {
    "uniprot": "A0A5K4ESS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESS8"
  },
  {
    "uniprot": "A0A3Q0KL94",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL94"
  },
  {
    "uniprot": "A0A5K4EPF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPF9"
  },
  {
    "uniprot": "A0A3Q0KLB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLB3"
  },
  {
    "uniprot": "G4V8B8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8B8"
  },
  {
    "uniprot": "G4VPX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPX8"
  },
  {
    "uniprot": "A0A3Q0KHR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHR9"
  },
  {
    "uniprot": "A0A5K4F043",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F043"
  },
  {
    "uniprot": "A0A5K4EY39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY39"
  },
  {
    "uniprot": "A0A3Q0KL62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL62"
  },
  {
    "uniprot": "A0A3Q0KNR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNR8"
  },
  {
    "uniprot": "A0A3Q0KPU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPU9"
  },
  {
    "uniprot": "G4V7H1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7H1"
  },
  {
    "uniprot": "A0A3Q0KED8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KED8"
  },
  {
    "uniprot": "A0A3Q0KQ42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ42"
  },
  {
    "uniprot": "Q15ES5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q15ES5"
  },
  {
    "uniprot": "A0A5K4F3J0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3J0"
  },
  {
    "uniprot": "A0A5K4FF17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF17"
  },
  {
    "uniprot": "A0A3Q0KMI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMI8"
  },
  {
    "uniprot": "A0A3Q0KSU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSU4"
  },
  {
    "uniprot": "A0A3Q0KF90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF90"
  },
  {
    "uniprot": "A0A5K4EGB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGB2"
  },
  {
    "uniprot": "A0A5K4FC58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC58"
  },
  {
    "uniprot": "G4VFB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFB0"
  },
  {
    "uniprot": "A0A3Q0KJ62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ62"
  },
  {
    "uniprot": "A0A3Q0KR61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR61"
  },
  {
    "uniprot": "A0A3Q0KJ11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ11"
  },
  {
    "uniprot": "A0A3Q0KQY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQY7"
  },
  {
    "uniprot": "A0A5K4EW88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW88"
  },
  {
    "uniprot": "A0A3Q0KMX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMX1"
  },
  {
    "uniprot": "G4V904",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V904"
  },
  {
    "uniprot": "A0A5K4EQE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQE4"
  },
  {
    "uniprot": "A0A5K4FBG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBG4"
  },
  {
    "uniprot": "A0A5K4F243",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F243"
  },
  {
    "uniprot": "A0A5K4FD45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD45"
  },
  {
    "uniprot": "A0A3Q0KN61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN61"
  },
  {
    "uniprot": "G4VNI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNI6"
  },
  {
    "uniprot": "A0A5K4F6G0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6G0"
  },
  {
    "uniprot": "A0A5K4F0N1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0N1"
  },
  {
    "uniprot": "A0A3Q0KR78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR78"
  },
  {
    "uniprot": "A0A5K4F6I0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6I0"
  },
  {
    "uniprot": "A0A5K4ED42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED42"
  },
  {
    "uniprot": "A0A3Q0KBM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBM8"
  },
  {
    "uniprot": "A0A5K4F152",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F152"
  },
  {
    "uniprot": "A0A5K4F9T6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9T6"
  },
  {
    "uniprot": "A0A3Q0KUT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUT8"
  },
  {
    "uniprot": "A0A5K4EX10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX10"
  },
  {
    "uniprot": "A0A5K4FFK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFK2"
  },
  {
    "uniprot": "A0A5K4F200",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F200"
  },
  {
    "uniprot": "A0A5K4EW81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW81"
  },
  {
    "uniprot": "G4V740",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V740"
  },
  {
    "uniprot": "A0A5K4F117",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F117"
  },
  {
    "uniprot": "A0A5K4EZX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZX0"
  },
  {
    "uniprot": "A0A3Q0KG53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG53"
  },
  {
    "uniprot": "A0A5K4FAT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAT8"
  },
  {
    "uniprot": "A0A5K4F4C6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4C6"
  },
  {
    "uniprot": "A0A3Q0KMM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMM3"
  },
  {
    "uniprot": "A0A3Q0KRD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRD6"
  },
  {
    "uniprot": "A0A3Q0KGW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGW8"
  },
  {
    "uniprot": "G4VBG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBG6"
  },
  {
    "uniprot": "A0A3Q0KRQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRQ9"
  },
  {
    "uniprot": "A0A3Q0KK51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK51"
  },
  {
    "uniprot": "P09792",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P09792"
  },
  {
    "uniprot": "A0A5K4F378",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F378"
  },
  {
    "uniprot": "A0A3Q0KMN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMN4"
  },
  {
    "uniprot": "A0A5K4EX11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX11"
  },
  {
    "uniprot": "A0A5K4F0X8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0X8"
  },
  {
    "uniprot": "A0A3Q0KTL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTL3"
  },
  {
    "uniprot": "A0A3Q0KTK3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTK3"
  },
  {
    "uniprot": "A0A5K4EHF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHF8"
  },
  {
    "uniprot": "A0A3Q0KUI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUI4"
  },
  {
    "uniprot": "A0A5K4FC39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC39"
  },
  {
    "uniprot": "A0A3Q0KMR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMR2"
  },
  {
    "uniprot": "A0A3Q0KRH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRH5"
  },
  {
    "uniprot": "A0A5K4EBH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBH0"
  },
  {
    "uniprot": "A0A5K4EQG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQG4"
  },
  {
    "uniprot": "A0A3Q0KQJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQJ3"
  },
  {
    "uniprot": "A0A3Q0KM19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM19"
  },
  {
    "uniprot": "A0A5K4F9U4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9U4"
  },
  {
    "uniprot": "A0A5K4EE00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE00"
  },
  {
    "uniprot": "A0A5K4FAU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAU4"
  },
  {
    "uniprot": "A0A5K4F7G0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7G0"
  },
  {
    "uniprot": "A0A5K4ELY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELY9"
  },
  {
    "uniprot": "G4VJE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJE6"
  },
  {
    "uniprot": "A0A3Q0KPZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPZ0"
  },
  {
    "uniprot": "G4VI20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI20"
  },
  {
    "uniprot": "A0A5K4F2X0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2X0"
  },
  {
    "uniprot": "A0A5K4E873",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E873"
  },
  {
    "uniprot": "A0A5K4F0G4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0G4"
  },
  {
    "uniprot": "A0A3Q0KME4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KME4"
  },
  {
    "uniprot": "G4VSD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSD6"
  },
  {
    "uniprot": "A0A3Q0KU82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU82"
  },
  {
    "uniprot": "A0A3Q0KTS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTS1"
  },
  {
    "uniprot": "A0A3Q0KHS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHS1"
  },
  {
    "uniprot": "A0A3Q0KTY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTY8"
  },
  {
    "uniprot": "G4VK93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK93"
  },
  {
    "uniprot": "A0A3Q0KTA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTA4"
  },
  {
    "uniprot": "A0A5K4F0D9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0D9"
  },
  {
    "uniprot": "A0A5K4F4R2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4R2"
  },
  {
    "uniprot": "A0A3Q0KV40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV40"
  },
  {
    "uniprot": "A0A5K4F6G9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6G9"
  },
  {
    "uniprot": "Q95043",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q95043"
  },
  {
    "uniprot": "A0A5K4EGW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGW4"
  },
  {
    "uniprot": "A0A5K4F1M4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1M4"
  },
  {
    "uniprot": "A0A5K4EI37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EI37"
  },
  {
    "uniprot": "A0A5K4EUB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUB1"
  },
  {
    "uniprot": "A0A3Q0KFT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFT0"
  },
  {
    "uniprot": "A0A3Q0KKH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKH4"
  },
  {
    "uniprot": "A0A5K4F186",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F186"
  },
  {
    "uniprot": "A0A3Q0KML5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KML5"
  },
  {
    "uniprot": "A0A3Q0KC07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC07"
  },
  {
    "uniprot": "G4LWZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWZ6"
  },
  {
    "uniprot": "G4LYQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYQ4"
  },
  {
    "uniprot": "A0A5K4F9M7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9M7"
  },
  {
    "uniprot": "G4VIW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIW8"
  },
  {
    "uniprot": "A0A3Q0KFZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFZ7"
  },
  {
    "uniprot": "A0A3Q0KMT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMT1"
  },
  {
    "uniprot": "A0A5K4F398",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F398"
  },
  {
    "uniprot": "G4LYK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYK2"
  },
  {
    "uniprot": "A0A5K4FB07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB07"
  },
  {
    "uniprot": "G4VTF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTF1"
  },
  {
    "uniprot": "G4VH00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH00"
  },
  {
    "uniprot": "A0A3Q0KHC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHC0"
  },
  {
    "uniprot": "C1M1H8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C1M1H8"
  },
  {
    "uniprot": "A0A5K4F0V8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0V8"
  },
  {
    "uniprot": "A0A5K4F3V2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3V2"
  },
  {
    "uniprot": "G4M0V2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0V2"
  },
  {
    "uniprot": "A0A3Q0KQD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQD5"
  },
  {
    "uniprot": "A0A5K4FB32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB32"
  },
  {
    "uniprot": "A0A5K4F3T3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3T3"
  },
  {
    "uniprot": "A0A5K4FA00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA00"
  },
  {
    "uniprot": "A0A3Q0KME8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KME8"
  },
  {
    "uniprot": "A0A5K4F2E0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2E0"
  },
  {
    "uniprot": "A0A5K4F6W2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6W2"
  },
  {
    "uniprot": "A0A5K4F1R3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1R3"
  },
  {
    "uniprot": "A0A3Q0KKV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKV3"
  },
  {
    "uniprot": "A0A3Q0KMF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMF7"
  },
  {
    "uniprot": "G4LUJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUJ1"
  },
  {
    "uniprot": "A0A5K4EDD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDD3"
  },
  {
    "uniprot": "A0A5K4EV70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV70"
  },
  {
    "uniprot": "A0A5K4F6L0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6L0"
  },
  {
    "uniprot": "G4VMD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMD6"
  },
  {
    "uniprot": "G4VSR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSR3"
  },
  {
    "uniprot": "A0A5K4EMS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMS9"
  },
  {
    "uniprot": "A0A3Q0KB95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KB95"
  },
  {
    "uniprot": "G4VK52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK52"
  },
  {
    "uniprot": "A0A3Q0KNR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNR4"
  },
  {
    "uniprot": "A0A5K4F9W1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9W1"
  },
  {
    "uniprot": "A0A5K4EUN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUN3"
  },
  {
    "uniprot": "G4V9T4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9T4"
  },
  {
    "uniprot": "A0A3Q0KQC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQC1"
  },
  {
    "uniprot": "A0A5K4EA46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EA46"
  },
  {
    "uniprot": "A0A3Q0KEU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEU7"
  },
  {
    "uniprot": "A0A5K4EV80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV80"
  },
  {
    "uniprot": "A0A3Q0KSM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSM9"
  },
  {
    "uniprot": "A0A3Q0KH81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH81"
  },
  {
    "uniprot": "A0A3Q0KMU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMU4"
  },
  {
    "uniprot": "A0A3Q0KHF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHF6"
  },
  {
    "uniprot": "A0A3Q0KL55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL55"
  },
  {
    "uniprot": "A0A5K4F8E3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8E3"
  },
  {
    "uniprot": "A0A3Q0KLR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLR9"
  },
  {
    "uniprot": "A0A5K4F6X6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6X6"
  },
  {
    "uniprot": "G4VID9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VID9"
  },
  {
    "uniprot": "A0A5K4F0A6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0A6"
  },
  {
    "uniprot": "A0A5K4F863",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F863"
  },
  {
    "uniprot": "G4VFZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFZ8"
  },
  {
    "uniprot": "A0A3Q0KHA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHA1"
  },
  {
    "uniprot": "A0A3Q0KJ19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ19"
  },
  {
    "uniprot": "G4VIU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIU2"
  },
  {
    "uniprot": "A0A3Q0KPC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPC8"
  },
  {
    "uniprot": "A0A5K4FE52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE52"
  },
  {
    "uniprot": "A0A3Q0KUA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUA8"
  },
  {
    "uniprot": "A0A5K4FC93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC93"
  },
  {
    "uniprot": "A0A5K4F876",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F876"
  },
  {
    "uniprot": "G4LV63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV63"
  },
  {
    "uniprot": "A0A3Q0KRC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRC1"
  },
  {
    "uniprot": "C4QPK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QPK0"
  },
  {
    "uniprot": "G4VNM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNM8"
  },
  {
    "uniprot": "A0A5K4FD92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD92"
  },
  {
    "uniprot": "A0A5K4ED92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED92"
  },
  {
    "uniprot": "A0A3Q0KNJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNJ8"
  },
  {
    "uniprot": "A0A3Q0KIM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIM8"
  },
  {
    "uniprot": "A0A5K4F0A4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0A4"
  },
  {
    "uniprot": "A0A5K4F6X3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6X3"
  },
  {
    "uniprot": "A0A3Q0KQX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQX9"
  },
  {
    "uniprot": "A0A3Q0KD12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD12"
  },
  {
    "uniprot": "A0A3Q0KKP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKP1"
  },
  {
    "uniprot": "C4QJY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QJY1"
  },
  {
    "uniprot": "A0A5K4EFW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFW1"
  },
  {
    "uniprot": "A0A5K4EMX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMX5"
  },
  {
    "uniprot": "G4V968",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V968"
  },
  {
    "uniprot": "A0A5K4FA55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA55"
  },
  {
    "uniprot": "A0A5K4F0J6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0J6"
  },
  {
    "uniprot": "C4QMI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QMI1"
  },
  {
    "uniprot": "A0A5K4F856",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F856"
  },
  {
    "uniprot": "A0A5K4F2W0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2W0"
  },
  {
    "uniprot": "A0A3Q0KP25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP25"
  },
  {
    "uniprot": "A0A3Q0KP58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP58"
  },
  {
    "uniprot": "A0A3Q0KHP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHP5"
  },
  {
    "uniprot": "A0A5K4F7P3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7P3"
  },
  {
    "uniprot": "G4VSU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSU9"
  },
  {
    "uniprot": "A0A5K4EUD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUD0"
  },
  {
    "uniprot": "A0A5K4EC25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EC25"
  },
  {
    "uniprot": "A0A3Q0KNI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNI6"
  },
  {
    "uniprot": "A0A3Q0KG66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG66"
  },
  {
    "uniprot": "A0A5K4EKX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKX0"
  },
  {
    "uniprot": "A0A5K4F7G7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7G7"
  },
  {
    "uniprot": "A0A5K4F791",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F791"
  },
  {
    "uniprot": "A0A3Q0KNL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNL0"
  },
  {
    "uniprot": "A0A5K4ENZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENZ0"
  },
  {
    "uniprot": "A0A3Q0KLG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLG4"
  },
  {
    "uniprot": "A0A3Q0KMC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMC4"
  },
  {
    "uniprot": "G4VC95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC95"
  },
  {
    "uniprot": "A0A3Q0KSS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSS2"
  },
  {
    "uniprot": "A0A3Q0KPY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPY5"
  },
  {
    "uniprot": "A0A3Q0KHY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHY2"
  },
  {
    "uniprot": "A0A3Q0KBT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBT4"
  },
  {
    "uniprot": "A0A3Q0KNB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNB4"
  },
  {
    "uniprot": "A0A3Q0KNU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNU5"
  },
  {
    "uniprot": "A0A5K4FB97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB97"
  },
  {
    "uniprot": "A0A5K4FA19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA19"
  },
  {
    "uniprot": "A0A3Q0KTA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTA1"
  },
  {
    "uniprot": "A0A5K4F9H7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9H7"
  },
  {
    "uniprot": "A0A5K4EWF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWF2"
  },
  {
    "uniprot": "A0A3Q0KBN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBN8"
  },
  {
    "uniprot": "A0A5K4ETZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETZ0"
  },
  {
    "uniprot": "G4VFF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFF2"
  },
  {
    "uniprot": "G4LZA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZA2"
  },
  {
    "uniprot": "G4LVZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVZ8"
  },
  {
    "uniprot": "G4VB74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB74"
  },
  {
    "uniprot": "Q15ET1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q15ET1"
  },
  {
    "uniprot": "A0A3Q0KKM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKM4"
  },
  {
    "uniprot": "A0A5K4EWB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWB9"
  },
  {
    "uniprot": "A0A3Q0KT58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT58"
  },
  {
    "uniprot": "A0A3Q0KJZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJZ7"
  },
  {
    "uniprot": "A0A5K4FGQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGQ0"
  },
  {
    "uniprot": "A0A3Q0KRK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRK8"
  },
  {
    "uniprot": "A0A3Q0KKS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKS8"
  },
  {
    "uniprot": "A0A5K4EJP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJP5"
  },
  {
    "uniprot": "A0A5K4F8K2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8K2"
  },
  {
    "uniprot": "G4LZQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZQ4"
  },
  {
    "uniprot": "A0A5K4F336",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F336"
  },
  {
    "uniprot": "A0A5K4FC13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC13"
  },
  {
    "uniprot": "A0A5K4F985",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F985"
  },
  {
    "uniprot": "A0A5K4EAS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAS6"
  },
  {
    "uniprot": "A0A5K4F1G4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1G4"
  },
  {
    "uniprot": "A0A5K4EUV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUV4"
  },
  {
    "uniprot": "G4VGU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGU2"
  },
  {
    "uniprot": "A0A5K4F0E6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0E6"
  },
  {
    "uniprot": "A0A3Q0KBL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBL5"
  },
  {
    "uniprot": "A0A5K4F115",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F115"
  },
  {
    "uniprot": "A0A5K4ESL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESL0"
  },
  {
    "uniprot": "A0A5K4F5F7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5F7"
  },
  {
    "uniprot": "C4QUE4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QUE4"
  },
  {
    "uniprot": "A0A3Q0KS61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS61"
  },
  {
    "uniprot": "A0A3Q0KMB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMB8"
  },
  {
    "uniprot": "A0A3Q0KMH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMH3"
  },
  {
    "uniprot": "A0A5K4F1V4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1V4"
  },
  {
    "uniprot": "A0A3Q0KKK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKK1"
  },
  {
    "uniprot": "G4VT14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT14"
  },
  {
    "uniprot": "A0A3Q0KCD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCD8"
  },
  {
    "uniprot": "A0A5K4FEJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEJ3"
  },
  {
    "uniprot": "G4VL17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VL17"
  },
  {
    "uniprot": "A0A5K4F629",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F629"
  },
  {
    "uniprot": "A0A5K4FH46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FH46"
  },
  {
    "uniprot": "A0A5K4F4M9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4M9"
  },
  {
    "uniprot": "A0A3Q0KLZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLZ8"
  },
  {
    "uniprot": "A0A3Q0KP54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP54"
  },
  {
    "uniprot": "A0A5K4ERH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERH0"
  },
  {
    "uniprot": "A0A3Q0KF34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF34"
  },
  {
    "uniprot": "A0A3Q0KL15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL15"
  },
  {
    "uniprot": "A0A5K4F5I3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5I3"
  },
  {
    "uniprot": "A0A3Q0KF15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF15"
  },
  {
    "uniprot": "A0A5K4EGP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGP6"
  },
  {
    "uniprot": "A0A5K4FCC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCC8"
  },
  {
    "uniprot": "A0A5K4F5Y3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Y3"
  },
  {
    "uniprot": "A0A5K4F3R4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3R4"
  },
  {
    "uniprot": "A0A5K4F6S3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6S3"
  },
  {
    "uniprot": "A0A3Q0KFJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFJ5"
  },
  {
    "uniprot": "A0A5K4ECR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECR9"
  },
  {
    "uniprot": "A0A3Q0KBX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBX1"
  },
  {
    "uniprot": "A0A5K4EUF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUF0"
  },
  {
    "uniprot": "A0A5K4F9R4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9R4"
  },
  {
    "uniprot": "G4V5N9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5N9"
  },
  {
    "uniprot": "A0A5K4EN38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN38"
  },
  {
    "uniprot": "G4V6S2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6S2"
  },
  {
    "uniprot": "A0A5K4F3F4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3F4"
  },
  {
    "uniprot": "A0A5K4F1Q7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Q7"
  },
  {
    "uniprot": "A0A5K4F5K3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5K3"
  },
  {
    "uniprot": "A0A5K4FAC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAC0"
  },
  {
    "uniprot": "A0A3Q0KMG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMG1"
  },
  {
    "uniprot": "A0A5K4F1R4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1R4"
  },
  {
    "uniprot": "A0A3Q0KEE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEE2"
  },
  {
    "uniprot": "A0A5K4FCD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCD7"
  },
  {
    "uniprot": "P12796",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P12796"
  },
  {
    "uniprot": "A0A5K4F9C4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9C4"
  },
  {
    "uniprot": "G4M0P7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0P7"
  },
  {
    "uniprot": "A0A5K4F8C8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8C8"
  },
  {
    "uniprot": "A0A3Q0KL93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL93"
  },
  {
    "uniprot": "A0A5K4F564",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F564"
  },
  {
    "uniprot": "A0A3Q0KBV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBV0"
  },
  {
    "uniprot": "A0A3Q0KMK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMK6"
  },
  {
    "uniprot": "A0A3Q0KKP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKP5"
  },
  {
    "uniprot": "A0A5K4FBJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBJ3"
  },
  {
    "uniprot": "A0A5K4F5J6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5J6"
  },
  {
    "uniprot": "A0A3Q0KR09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR09"
  },
  {
    "uniprot": "G4LZ77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ77"
  },
  {
    "uniprot": "A0A5K4ECU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECU1"
  },
  {
    "uniprot": "A0A3Q0KJE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJE1"
  },
  {
    "uniprot": "A0A3Q0KMD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMD3"
  },
  {
    "uniprot": "A0A5K4EL87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL87"
  },
  {
    "uniprot": "A0A5K4F143",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F143"
  },
  {
    "uniprot": "A0A5K4F6N4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6N4"
  },
  {
    "uniprot": "A0A3Q0KTS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTS6"
  },
  {
    "uniprot": "A0A5K4F4F0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4F0"
  },
  {
    "uniprot": "G4LV62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV62"
  },
  {
    "uniprot": "A0A5K4F4A2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4A2"
  },
  {
    "uniprot": "A0A5K4F575",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F575"
  },
  {
    "uniprot": "A0A5K4F6P3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6P3"
  },
  {
    "uniprot": "G4LW67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LW67"
  },
  {
    "uniprot": "A0A5K4FCF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCF0"
  },
  {
    "uniprot": "A0A5K4ELH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELH6"
  },
  {
    "uniprot": "A0A5K4EWD0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWD0"
  },
  {
    "uniprot": "A0A3Q0KLK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLK9"
  },
  {
    "uniprot": "G4VDE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDE3"
  },
  {
    "uniprot": "G4VFB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFB6"
  },
  {
    "uniprot": "A0A3Q0KNY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNY3"
  },
  {
    "uniprot": "A0A3Q0KMA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMA7"
  },
  {
    "uniprot": "A0A5K4F6U5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6U5"
  },
  {
    "uniprot": "A0A3Q0KH10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH10"
  },
  {
    "uniprot": "G4V5W1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5W1"
  },
  {
    "uniprot": "A0A5K4EW04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW04"
  },
  {
    "uniprot": "A0A5K4F8Y9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Y9"
  },
  {
    "uniprot": "A0A3Q0KJM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJM3"
  },
  {
    "uniprot": "A0A5K4F4N5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4N5"
  },
  {
    "uniprot": "A0A3Q0KFB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFB9"
  },
  {
    "uniprot": "A0A3Q0KVI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVI3"
  },
  {
    "uniprot": "A0A3Q0KNU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNU6"
  },
  {
    "uniprot": "A0A3Q0KV34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV34"
  },
  {
    "uniprot": "A0A3Q0KDB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDB2"
  },
  {
    "uniprot": "A0A5K4EKS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKS3"
  },
  {
    "uniprot": "A0A3Q0KIK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIK2"
  },
  {
    "uniprot": "A0A5K4EFF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFF2"
  },
  {
    "uniprot": "A0A5K4F4E1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4E1"
  },
  {
    "uniprot": "A0A3Q0KDD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDD7"
  },
  {
    "uniprot": "A0A5K4FB62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB62"
  },
  {
    "uniprot": "A0A5K4FA04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA04"
  },
  {
    "uniprot": "A0A3Q0KU44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU44"
  },
  {
    "uniprot": "A0A5K4F5F0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5F0"
  },
  {
    "uniprot": "G4LVH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVH5"
  },
  {
    "uniprot": "A0A5K4EEI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEI9"
  },
  {
    "uniprot": "A0A3Q0KNS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNS9"
  },
  {
    "uniprot": "A0A5K4EQD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQD6"
  },
  {
    "uniprot": "A0A3Q0KS15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS15"
  },
  {
    "uniprot": "G4LWP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWP8"
  },
  {
    "uniprot": "A0A5K4EY80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY80"
  },
  {
    "uniprot": "A0A3Q0KLD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLD5"
  },
  {
    "uniprot": "A0A3Q0KQ77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ77"
  },
  {
    "uniprot": "A0A3Q0KPG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPG4"
  },
  {
    "uniprot": "A0A5K4F8W9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8W9"
  },
  {
    "uniprot": "A0A5K4ER01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER01"
  },
  {
    "uniprot": "A0A3Q0KD42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD42"
  },
  {
    "uniprot": "A0A3Q0KLK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLK1"
  },
  {
    "uniprot": "A0A5K4FH38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FH38"
  },
  {
    "uniprot": "A0A3Q0KSX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSX5"
  },
  {
    "uniprot": "A0A3Q0KPZ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPZ7"
  },
  {
    "uniprot": "A0A5K4F1S6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1S6"
  },
  {
    "uniprot": "A0A5K4EYW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYW3"
  },
  {
    "uniprot": "A0A5K4F6F3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6F3"
  },
  {
    "uniprot": "A0A5K4FE61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE61"
  },
  {
    "uniprot": "G4VKU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKU2"
  },
  {
    "uniprot": "A0A5K4F248",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F248"
  },
  {
    "uniprot": "A0A5K4FCG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCG9"
  },
  {
    "uniprot": "G4VK59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK59"
  },
  {
    "uniprot": "A0A5K4F3Z8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3Z8"
  },
  {
    "uniprot": "A0A3Q0KPW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPW6"
  },
  {
    "uniprot": "A0A5K4F5F2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5F2"
  },
  {
    "uniprot": "A0A5K4F9B6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9B6"
  },
  {
    "uniprot": "A0A3Q0KRU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRU7"
  },
  {
    "uniprot": "G4VLI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLI4"
  },
  {
    "uniprot": "A0A3Q0KPX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPX7"
  },
  {
    "uniprot": "A0A3Q0KEF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEF7"
  },
  {
    "uniprot": "A0A5K4FBB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBB3"
  },
  {
    "uniprot": "A0A5K4FCF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCF5"
  },
  {
    "uniprot": "A0A5K4FBS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBS6"
  },
  {
    "uniprot": "G4VSL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSL8"
  },
  {
    "uniprot": "G4M0Q4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0Q4"
  },
  {
    "uniprot": "A0A3Q0KKY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKY9"
  },
  {
    "uniprot": "A0A3Q0KRJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRJ1"
  },
  {
    "uniprot": "A0A5K4E8K6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8K6"
  },
  {
    "uniprot": "A0A5K4FD47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD47"
  },
  {
    "uniprot": "A0A5K4EXZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXZ9"
  },
  {
    "uniprot": "A0A5K4EKP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKP8"
  },
  {
    "uniprot": "A0A3Q0KKR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKR8"
  },
  {
    "uniprot": "A0A5K4F5I9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5I9"
  },
  {
    "uniprot": "A0A5K4EME7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EME7"
  },
  {
    "uniprot": "A0A3Q0KPJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPJ4"
  },
  {
    "uniprot": "A0A5K4FBL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBL1"
  },
  {
    "uniprot": "G4LVY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVY8"
  },
  {
    "uniprot": "A0A5K4FE22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE22"
  },
  {
    "uniprot": "A0A3Q0KGI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGI7"
  },
  {
    "uniprot": "A0A5K4EYR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYR3"
  },
  {
    "uniprot": "G4VIY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIY8"
  },
  {
    "uniprot": "A0A3Q0KL43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL43"
  },
  {
    "uniprot": "A0A3Q0KU80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU80"
  },
  {
    "uniprot": "A0A5K4EVH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVH5"
  },
  {
    "uniprot": "A0A3Q0KRM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRM8"
  },
  {
    "uniprot": "G4V7P9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7P9"
  },
  {
    "uniprot": "A0A5K4FFX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFX5"
  },
  {
    "uniprot": "A0A3Q0KM05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM05"
  },
  {
    "uniprot": "G4VIJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIJ1"
  },
  {
    "uniprot": "A0A3Q0KPR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPR4"
  },
  {
    "uniprot": "Q26536",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26536"
  },
  {
    "uniprot": "G4VTG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTG8"
  },
  {
    "uniprot": "A0A5K4F5S8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5S8"
  },
  {
    "uniprot": "A0A5K4EX53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX53"
  },
  {
    "uniprot": "A0A5K4F8W7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8W7"
  },
  {
    "uniprot": "A0A5K4FGT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGT2"
  },
  {
    "uniprot": "A0A5K4F5C0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5C0"
  },
  {
    "uniprot": "A0A3Q0KJG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJG6"
  },
  {
    "uniprot": "A0A5K4F382",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F382"
  },
  {
    "uniprot": "G4LV55",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV55"
  },
  {
    "uniprot": "A0A5K4FCE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCE0"
  },
  {
    "uniprot": "A0A5K4ERM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERM2"
  },
  {
    "uniprot": "A0A5K4F4L2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4L2"
  },
  {
    "uniprot": "A0A5K4F0G9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0G9"
  },
  {
    "uniprot": "A0A3Q0KHC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHC6"
  },
  {
    "uniprot": "G4V946",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V946"
  },
  {
    "uniprot": "G4LWL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWL6"
  },
  {
    "uniprot": "A0A3Q0KGE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGE7"
  },
  {
    "uniprot": "A0A5K4FDB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDB7"
  },
  {
    "uniprot": "A0A3Q0KKY2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKY2"
  },
  {
    "uniprot": "A0A3Q0KUX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUX2"
  },
  {
    "uniprot": "G4VCW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCW3"
  },
  {
    "uniprot": "A0A5K4F651",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F651"
  },
  {
    "uniprot": "A0A5K4F155",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F155"
  },
  {
    "uniprot": "A0A5K4ED01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED01"
  },
  {
    "uniprot": "A0A5K4F1W5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1W5"
  },
  {
    "uniprot": "A0A5K4F1E8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1E8"
  },
  {
    "uniprot": "A0A5K4F936",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F936"
  },
  {
    "uniprot": "A0A3Q0KGY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGY3"
  },
  {
    "uniprot": "A0A5K4EFU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFU7"
  },
  {
    "uniprot": "A0A5K4FF38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF38"
  },
  {
    "uniprot": "G4LVP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVP0"
  },
  {
    "uniprot": "G4LXQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXQ3"
  },
  {
    "uniprot": "A0A5K4F7G8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7G8"
  },
  {
    "uniprot": "A0A5K4EYE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYE6"
  },
  {
    "uniprot": "A0A3Q0KJT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJT7"
  },
  {
    "uniprot": "A0A5K4FAH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAH4"
  },
  {
    "uniprot": "A0A5K4F630",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F630"
  },
  {
    "uniprot": "A0A5K4FCA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCA3"
  },
  {
    "uniprot": "A0A3Q0KS72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS72"
  },
  {
    "uniprot": "G4V6J1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6J1"
  },
  {
    "uniprot": "G4VQT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQT2"
  },
  {
    "uniprot": "A0A3Q0KK31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK31"
  },
  {
    "uniprot": "A0A3Q0KF42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF42"
  },
  {
    "uniprot": "A0A5K4F486",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F486"
  },
  {
    "uniprot": "G4V7X3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7X3"
  },
  {
    "uniprot": "A0A5K4FBQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBQ0"
  },
  {
    "uniprot": "A0A5K4EVN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVN4"
  },
  {
    "uniprot": "A0A3Q0KVH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVH5"
  },
  {
    "uniprot": "A0A5K4FDL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDL0"
  },
  {
    "uniprot": "A0A3Q0KEE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEE7"
  },
  {
    "uniprot": "G4LXF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXF8"
  },
  {
    "uniprot": "A0A3Q0KLK6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLK6"
  },
  {
    "uniprot": "A0A3Q0KT68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT68"
  },
  {
    "uniprot": "A0A5K4EPX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPX8"
  },
  {
    "uniprot": "A0A5K4FCH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCH9"
  },
  {
    "uniprot": "A0A5K4F2L7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2L7"
  },
  {
    "uniprot": "A0A5K4E9Y0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9Y0"
  },
  {
    "uniprot": "A0A5K4F631",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F631"
  },
  {
    "uniprot": "A0A5K4FAB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAB4"
  },
  {
    "uniprot": "A0A5K4ETL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETL7"
  },
  {
    "uniprot": "A0A5K4EV64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV64"
  },
  {
    "uniprot": "A0A3Q0KCS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCS4"
  },
  {
    "uniprot": "A0A3Q0KSC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSC3"
  },
  {
    "uniprot": "A0A5K4F1E2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1E2"
  },
  {
    "uniprot": "A0A5K4EDJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDJ1"
  },
  {
    "uniprot": "A0A5K4ES74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES74"
  },
  {
    "uniprot": "A0A3Q0KJD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJD6"
  },
  {
    "uniprot": "A0A3Q0KCM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCM9"
  },
  {
    "uniprot": "G4VC03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC03"
  },
  {
    "uniprot": "A0A3Q0KMQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMQ9"
  },
  {
    "uniprot": "A0A5K4FC27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC27"
  },
  {
    "uniprot": "A0A5K4EP13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP13"
  },
  {
    "uniprot": "A0A5K4EP27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP27"
  },
  {
    "uniprot": "A0A5K4FE62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE62"
  },
  {
    "uniprot": "A0A5K4F1X0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1X0"
  },
  {
    "uniprot": "A0A5K4FC89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC89"
  },
  {
    "uniprot": "A0A5K4F9J4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9J4"
  },
  {
    "uniprot": "A0A3Q0KQ36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ36"
  },
  {
    "uniprot": "G4VTC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTC5"
  },
  {
    "uniprot": "A0A5K4EH50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH50"
  },
  {
    "uniprot": "A0A5K4EBD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBD7"
  },
  {
    "uniprot": "A0A5K4F3U1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3U1"
  },
  {
    "uniprot": "A0A3Q0KDN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDN0"
  },
  {
    "uniprot": "A0A3Q0KDY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDY9"
  },
  {
    "uniprot": "G4LZI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZI3"
  },
  {
    "uniprot": "A0A3Q0KJ53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ53"
  },
  {
    "uniprot": "A0A5K4F4Y1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Y1"
  },
  {
    "uniprot": "A0A5K4F468",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F468"
  },
  {
    "uniprot": "A0A5K4F0I0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0I0"
  },
  {
    "uniprot": "A0A5K4FD00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD00"
  },
  {
    "uniprot": "A0A3Q0KQN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQN3"
  },
  {
    "uniprot": "A0A3Q0KKL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKL0"
  },
  {
    "uniprot": "A0A5K4F7Q7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Q7"
  },
  {
    "uniprot": "A0A3Q0KPL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPL1"
  },
  {
    "uniprot": "A0A3Q0KMD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMD5"
  },
  {
    "uniprot": "A0A5K4F6F7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6F7"
  },
  {
    "uniprot": "A0A3Q0KBC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBC8"
  },
  {
    "uniprot": "A0A3Q0KTH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTH5"
  },
  {
    "uniprot": "A0A3Q0KQ04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ04"
  },
  {
    "uniprot": "A0A3Q0KLU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLU5"
  },
  {
    "uniprot": "A0A5K4F6T6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6T6"
  },
  {
    "uniprot": "A0A5K4F315",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F315"
  },
  {
    "uniprot": "A0A5K4F018",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F018"
  },
  {
    "uniprot": "A0A3Q0KLW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLW9"
  },
  {
    "uniprot": "A0A3Q0KLW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLW7"
  },
  {
    "uniprot": "A0A5K4ERC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERC1"
  },
  {
    "uniprot": "A0A3Q0KGP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGP2"
  },
  {
    "uniprot": "A0A5K4F5U5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5U5"
  },
  {
    "uniprot": "A0A5K4EPJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPJ1"
  },
  {
    "uniprot": "A0A3Q0KP89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP89"
  },
  {
    "uniprot": "A0A5K4F9U2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9U2"
  },
  {
    "uniprot": "A0A5K4EAL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAL3"
  },
  {
    "uniprot": "A0A5K4F2T8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2T8"
  },
  {
    "uniprot": "G4VR32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR32"
  },
  {
    "uniprot": "A0A5K4F8Y0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Y0"
  },
  {
    "uniprot": "G4VHI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHI8"
  },
  {
    "uniprot": "A0A5K4FBM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBM1"
  },
  {
    "uniprot": "A0A5K4F793",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F793"
  },
  {
    "uniprot": "A0A5K4FB38",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB38"
  },
  {
    "uniprot": "A0A3Q0KNG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNG2"
  },
  {
    "uniprot": "A0A5K4F3W5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3W5"
  },
  {
    "uniprot": "A0A5K4EFX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFX3"
  },
  {
    "uniprot": "A0A3Q0KUF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUF7"
  },
  {
    "uniprot": "A0A5K4F4X2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4X2"
  },
  {
    "uniprot": "A0A5K4FBP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBP8"
  },
  {
    "uniprot": "A0A5K4F6X5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6X5"
  },
  {
    "uniprot": "G4V849",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V849"
  },
  {
    "uniprot": "A0A5K4EZZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZZ9"
  },
  {
    "uniprot": "A0A5K4F0C1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0C1"
  },
  {
    "uniprot": "G4VTX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTX1"
  },
  {
    "uniprot": "A0A5K4F2J2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2J2"
  },
  {
    "uniprot": "A0A5K4EYC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYC5"
  },
  {
    "uniprot": "A0A5K4F6H6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6H6"
  },
  {
    "uniprot": "A0A3Q0KGM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGM9"
  },
  {
    "uniprot": "A0A3Q0KR41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR41"
  },
  {
    "uniprot": "A0A3Q0KEI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEI3"
  },
  {
    "uniprot": "G4VFT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFT3"
  },
  {
    "uniprot": "A0A5K4EKN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKN3"
  },
  {
    "uniprot": "A0A3Q0KDK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDK5"
  },
  {
    "uniprot": "A0A3Q0KNZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNZ9"
  },
  {
    "uniprot": "G4VS96",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS96"
  },
  {
    "uniprot": "A0A5K4F3S3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3S3"
  },
  {
    "uniprot": "A0A5K4FCZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCZ4"
  },
  {
    "uniprot": "A0A3Q0KR27",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR27"
  },
  {
    "uniprot": "A0A5K4ESI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESI2"
  },
  {
    "uniprot": "A0A3Q0KMV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMV6"
  },
  {
    "uniprot": "A0A5K4EHP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHP8"
  },
  {
    "uniprot": "G4VQ50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQ50"
  },
  {
    "uniprot": "P30669",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P30669"
  },
  {
    "uniprot": "G4VJC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJC0"
  },
  {
    "uniprot": "A0A3Q0KKQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKQ8"
  },
  {
    "uniprot": "A0A3Q0KNN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNN5"
  },
  {
    "uniprot": "A0A3Q0KLC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLC8"
  },
  {
    "uniprot": "A0A5K4EZL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZL4"
  },
  {
    "uniprot": "A0A3Q0KLC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLC1"
  },
  {
    "uniprot": "A0A3Q0KNW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNW3"
  },
  {
    "uniprot": "G4VQS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQS9"
  },
  {
    "uniprot": "G4VHI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHI0"
  },
  {
    "uniprot": "A0A3Q0KC05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC05"
  },
  {
    "uniprot": "A0A5K4FBQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBQ4"
  },
  {
    "uniprot": "G4VHC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHC0"
  },
  {
    "uniprot": "A0A3Q0KRY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRY8"
  },
  {
    "uniprot": "A0A5K4EL46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL46"
  },
  {
    "uniprot": "A0A3Q0KMA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMA3"
  },
  {
    "uniprot": "G4V9U4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9U4"
  },
  {
    "uniprot": "A0A3Q0KRU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRU9"
  },
  {
    "uniprot": "A0A5K4ERS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERS7"
  },
  {
    "uniprot": "A0A5K4FAY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAY0"
  },
  {
    "uniprot": "A0A3Q0KIB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIB0"
  },
  {
    "uniprot": "A0A5K4F701",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F701"
  },
  {
    "uniprot": "A0A3Q0KS58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS58"
  },
  {
    "uniprot": "A0A5K4E9Z6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9Z6"
  },
  {
    "uniprot": "A0A5K4F6U0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6U0"
  },
  {
    "uniprot": "A0A3Q0KJV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJV0"
  },
  {
    "uniprot": "A0A5K4EN82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN82"
  },
  {
    "uniprot": "A0A5K4F292",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F292"
  },
  {
    "uniprot": "A0A5K4FCJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCJ3"
  },
  {
    "uniprot": "A0A3Q0KM71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM71"
  },
  {
    "uniprot": "A0A5K4F358",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F358"
  },
  {
    "uniprot": "A0A5K4EMK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMK9"
  },
  {
    "uniprot": "G4VPA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPA4"
  },
  {
    "uniprot": "A0A5K4FCT9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCT9"
  },
  {
    "uniprot": "A0A5K4F1H0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1H0"
  },
  {
    "uniprot": "A0A3Q0KSA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSA3"
  },
  {
    "uniprot": "A0A5K4F6U8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6U8"
  },
  {
    "uniprot": "G4VGY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGY9"
  },
  {
    "uniprot": "A0A5K4EK69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK69"
  },
  {
    "uniprot": "G4VCP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCP6"
  },
  {
    "uniprot": "A0A3Q0KD50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD50"
  },
  {
    "uniprot": "A0A5K4FAZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAZ2"
  },
  {
    "uniprot": "A0A3Q0KFH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFH0"
  },
  {
    "uniprot": "A0A5K4EJS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJS1"
  },
  {
    "uniprot": "A0A3Q0KH04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH04"
  },
  {
    "uniprot": "A0A3Q0KE69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE69"
  },
  {
    "uniprot": "A0A5K4F0Q8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Q8"
  },
  {
    "uniprot": "A0A3Q0KFZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFZ1"
  },
  {
    "uniprot": "A0A3Q0KV19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV19"
  },
  {
    "uniprot": "A0A5K4EYQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYQ8"
  },
  {
    "uniprot": "A0A5K4FAW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAW8"
  },
  {
    "uniprot": "A0A3Q0KQY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQY6"
  },
  {
    "uniprot": "G4LWB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWB6"
  },
  {
    "uniprot": "C4QUG0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QUG0"
  },
  {
    "uniprot": "A0A3Q0KRX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRX9"
  },
  {
    "uniprot": "G4VTS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTS1"
  },
  {
    "uniprot": "A0A5K4EL67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL67"
  },
  {
    "uniprot": "A0A3Q0KNL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNL2"
  },
  {
    "uniprot": "A0A3Q0KQ06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ06"
  },
  {
    "uniprot": "A0A5K4FBG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBG3"
  },
  {
    "uniprot": "A0A5K4F2F5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2F5"
  },
  {
    "uniprot": "G4VH28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH28"
  },
  {
    "uniprot": "A0A3Q0KCW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCW1"
  },
  {
    "uniprot": "A0A3Q0KIJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIJ3"
  },
  {
    "uniprot": "A0A3Q0KPG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPG7"
  },
  {
    "uniprot": "A0A5K4FAJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAJ6"
  },
  {
    "uniprot": "A0A3Q0KMT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMT8"
  },
  {
    "uniprot": "A0A5K4E9K7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9K7"
  },
  {
    "uniprot": "A0A3Q0KUH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUH4"
  },
  {
    "uniprot": "A0A5K4F5K6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5K6"
  },
  {
    "uniprot": "A0A5K4FBA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBA7"
  },
  {
    "uniprot": "A0A5K4F4C9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4C9"
  },
  {
    "uniprot": "A0A5K4F377",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F377"
  },
  {
    "uniprot": "A0A5K4FBM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBM6"
  },
  {
    "uniprot": "A0A3Q0KSA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSA2"
  },
  {
    "uniprot": "Q7YTA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q7YTA4"
  },
  {
    "uniprot": "G4M056",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M056"
  },
  {
    "uniprot": "A0A5K4FBU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBU2"
  },
  {
    "uniprot": "A0A3Q0KFI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFI5"
  },
  {
    "uniprot": "A0A3Q0KGX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGX4"
  },
  {
    "uniprot": "A0A5K4EN42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN42"
  },
  {
    "uniprot": "G4VJD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJD9"
  },
  {
    "uniprot": "C4QPS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QPS0"
  },
  {
    "uniprot": "A0A5K4F7S0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7S0"
  },
  {
    "uniprot": "G4VET4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VET4"
  },
  {
    "uniprot": "C4QBN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QBN1"
  },
  {
    "uniprot": "A0A5K4F5B3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5B3"
  },
  {
    "uniprot": "G4VF05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF05"
  },
  {
    "uniprot": "A0A3Q0KTS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTS5"
  },
  {
    "uniprot": "A0A3Q0KJ52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ52"
  },
  {
    "uniprot": "A0A3Q0KQJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQJ9"
  },
  {
    "uniprot": "A0A5K4EW85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW85"
  },
  {
    "uniprot": "A0A5K4F675",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F675"
  },
  {
    "uniprot": "A0A3Q0KPR2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPR2"
  },
  {
    "uniprot": "A0A5K4F8K9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8K9"
  },
  {
    "uniprot": "G4VE72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VE72"
  },
  {
    "uniprot": "A0A3Q0KR75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR75"
  },
  {
    "uniprot": "A0A3Q0KGZ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGZ8"
  },
  {
    "uniprot": "A0A5K4FE67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE67"
  },
  {
    "uniprot": "A0A3Q0KPC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPC1"
  },
  {
    "uniprot": "A0A3Q0KST2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KST2"
  },
  {
    "uniprot": "A0A3Q0KCR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCR8"
  },
  {
    "uniprot": "A0A5K4F1E4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1E4"
  },
  {
    "uniprot": "A0A3Q0KRF6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRF6"
  },
  {
    "uniprot": "A0A3Q0KF79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF79"
  },
  {
    "uniprot": "A0A3Q0KMZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMZ0"
  },
  {
    "uniprot": "A0A3Q0KGN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGN9"
  },
  {
    "uniprot": "A0A3Q0KG54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG54"
  },
  {
    "uniprot": "A0A5K4F754",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F754"
  },
  {
    "uniprot": "G4VE33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VE33"
  },
  {
    "uniprot": "A0A3Q0KM63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM63"
  },
  {
    "uniprot": "A0A5K4EXK1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXK1"
  },
  {
    "uniprot": "A0A3Q0KQ19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ19"
  },
  {
    "uniprot": "A0A5K4F1J6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1J6"
  },
  {
    "uniprot": "G4V6W4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6W4"
  },
  {
    "uniprot": "A0A5K4E9M8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9M8"
  },
  {
    "uniprot": "A0A3Q0KH09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH09"
  },
  {
    "uniprot": "A0A5K4F4U6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4U6"
  },
  {
    "uniprot": "A0A3Q0KUJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUJ2"
  },
  {
    "uniprot": "A0A3Q0KMM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMM8"
  },
  {
    "uniprot": "A0A5K4F0L1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0L1"
  },
  {
    "uniprot": "G4LYR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYR7"
  },
  {
    "uniprot": "A0A5K4FC25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC25"
  },
  {
    "uniprot": "A0A5K4F0R6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0R6"
  },
  {
    "uniprot": "A0A5K4ES77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES77"
  },
  {
    "uniprot": "A0A5K4EX47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX47"
  },
  {
    "uniprot": "A0A5K4F0H3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0H3"
  },
  {
    "uniprot": "G4VCJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCJ3"
  },
  {
    "uniprot": "A0A5K4EQE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQE0"
  },
  {
    "uniprot": "A0A5K4ELL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELL0"
  },
  {
    "uniprot": "G4VCW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCW5"
  },
  {
    "uniprot": "A0A3Q0KGF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGF2"
  },
  {
    "uniprot": "A0A3Q0KC78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC78"
  },
  {
    "uniprot": "Q15EU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q15EU1"
  },
  {
    "uniprot": "A0A5K4FE33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE33"
  },
  {
    "uniprot": "P08418",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P08418"
  },
  {
    "uniprot": "A0A5K4F1R0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1R0"
  },
  {
    "uniprot": "A0A5K4ENY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENY1"
  },
  {
    "uniprot": "A0A5K4FAP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAP0"
  },
  {
    "uniprot": "A0A3Q0KJJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJJ0"
  },
  {
    "uniprot": "A0A5K4FAU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAU6"
  },
  {
    "uniprot": "A0A3Q0KCS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCS6"
  },
  {
    "uniprot": "A0A5K4FAZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAZ9"
  },
  {
    "uniprot": "A0A5K4EC87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EC87"
  },
  {
    "uniprot": "A0A5K4EYQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYQ6"
  },
  {
    "uniprot": "Q6B963",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q6B963"
  },
  {
    "uniprot": "A0A5K4FAV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAV9"
  },
  {
    "uniprot": "A0A5K4EKM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKM0"
  },
  {
    "uniprot": "A0A3Q0KPS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPS4"
  },
  {
    "uniprot": "G4VEN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEN8"
  },
  {
    "uniprot": "A0A3Q0KK46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK46"
  },
  {
    "uniprot": "A0A3Q0KCZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCZ4"
  },
  {
    "uniprot": "A0A5K4EA83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EA83"
  },
  {
    "uniprot": "A0A5K4EBG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EBG8"
  },
  {
    "uniprot": "A0A3Q0KP61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP61"
  },
  {
    "uniprot": "A0A3Q0KP34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP34"
  },
  {
    "uniprot": "A0A5K4F2S1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2S1"
  },
  {
    "uniprot": "A0A5K4F866",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F866"
  },
  {
    "uniprot": "A0A3Q0KE68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE68"
  },
  {
    "uniprot": "A0A5K4F8X3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8X3"
  },
  {
    "uniprot": "A0A5K4F2N1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2N1"
  },
  {
    "uniprot": "A0A3Q0KUG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUG1"
  },
  {
    "uniprot": "G4LUQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUQ7"
  },
  {
    "uniprot": "A0A5K4FAQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAQ1"
  },
  {
    "uniprot": "A0A5K4F7Z7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Z7"
  },
  {
    "uniprot": "A0A5K4F1B8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1B8"
  },
  {
    "uniprot": "O96527",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/O96527"
  },
  {
    "uniprot": "A0A5K4F3D9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3D9"
  },
  {
    "uniprot": "G4M043",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M043"
  },
  {
    "uniprot": "Q94748",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q94748"
  },
  {
    "uniprot": "G4VRA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRA6"
  },
  {
    "uniprot": "A0A5K4F752",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F752"
  },
  {
    "uniprot": "A0A3Q0KPS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPS8"
  },
  {
    "uniprot": "A0A5K4EYG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYG5"
  },
  {
    "uniprot": "A0A5K4EV33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EV33"
  },
  {
    "uniprot": "A0A5K4FCR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCR8"
  },
  {
    "uniprot": "A0A5K4F5S4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5S4"
  },
  {
    "uniprot": "A0A5K4EFE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFE7"
  },
  {
    "uniprot": "G4VDQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDQ6"
  },
  {
    "uniprot": "A0A5K4F5M4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5M4"
  },
  {
    "uniprot": "A0A3Q0KQB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQB2"
  },
  {
    "uniprot": "A0A3Q0KL68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL68"
  },
  {
    "uniprot": "G4VEB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEB8"
  },
  {
    "uniprot": "A0A5K4EL98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL98"
  },
  {
    "uniprot": "G4VKC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKC5"
  },
  {
    "uniprot": "A0A5K4EVB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVB1"
  },
  {
    "uniprot": "A0A5K4ELH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELH8"
  },
  {
    "uniprot": "G4LYI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYI6"
  },
  {
    "uniprot": "G4VBQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBQ4"
  },
  {
    "uniprot": "A0A5K4F857",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F857"
  },
  {
    "uniprot": "A0A3Q0KLZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLZ9"
  },
  {
    "uniprot": "A0A3Q0KE08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE08"
  },
  {
    "uniprot": "Q86D97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q86D97"
  },
  {
    "uniprot": "A0A5K4FCS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCS6"
  },
  {
    "uniprot": "G4LX62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX62"
  },
  {
    "uniprot": "A0A3Q0KM56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM56"
  },
  {
    "uniprot": "A0A3Q0KE80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE80"
  },
  {
    "uniprot": "A0A5K4F6F2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6F2"
  },
  {
    "uniprot": "G4VJW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJW0"
  },
  {
    "uniprot": "A0A5K4F3M5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3M5"
  },
  {
    "uniprot": "G4VKL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VKL5"
  },
  {
    "uniprot": "A0A5K4F285",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F285"
  },
  {
    "uniprot": "A0A3Q0KGQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGQ5"
  },
  {
    "uniprot": "A0A5K4F7U6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7U6"
  },
  {
    "uniprot": "A0A3Q0KT21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT21"
  },
  {
    "uniprot": "A6XDL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A6XDL3"
  },
  {
    "uniprot": "A0A5K4F4I3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4I3"
  },
  {
    "uniprot": "A0A5K4F0E9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0E9"
  },
  {
    "uniprot": "A0A5K4ERX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERX3"
  },
  {
    "uniprot": "A0A5K4F2G7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2G7"
  },
  {
    "uniprot": "A0A5K4EZK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZK7"
  },
  {
    "uniprot": "A0A5K4FAG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAG6"
  },
  {
    "uniprot": "G4VTG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTG3"
  },
  {
    "uniprot": "A0A5K4FAA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAA6"
  },
  {
    "uniprot": "G4VIT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIT0"
  },
  {
    "uniprot": "G4V9V4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9V4"
  },
  {
    "uniprot": "A0A5K4F6Y8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Y8"
  },
  {
    "uniprot": "A0A3Q0KT09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT09"
  },
  {
    "uniprot": "A0A5K4FA90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA90"
  },
  {
    "uniprot": "A0A3Q0KLJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLJ1"
  },
  {
    "uniprot": "A0A5K4F596",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F596"
  },
  {
    "uniprot": "A0A5K4E9E2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9E2"
  },
  {
    "uniprot": "A0A5K4F3W1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3W1"
  },
  {
    "uniprot": "A0A5K4FBM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBM8"
  },
  {
    "uniprot": "G4VSJ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSJ2"
  },
  {
    "uniprot": "G4VK19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK19"
  },
  {
    "uniprot": "G4VG42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VG42"
  },
  {
    "uniprot": "A0A5K4F8U2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8U2"
  },
  {
    "uniprot": "G4VA58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA58"
  },
  {
    "uniprot": "A0A5K4F844",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F844"
  },
  {
    "uniprot": "A0A5K4FFR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFR0"
  },
  {
    "uniprot": "A0A5K4EQD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQD8"
  },
  {
    "uniprot": "A0A5K4FDR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDR3"
  },
  {
    "uniprot": "A0A3Q0KNH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNH9"
  },
  {
    "uniprot": "A0A3Q0KNG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNG1"
  },
  {
    "uniprot": "A0A5K4F2C9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2C9"
  },
  {
    "uniprot": "A0A5K4ENI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENI7"
  },
  {
    "uniprot": "A0A3Q0KLS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLS7"
  },
  {
    "uniprot": "A0A3Q0KQ59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ59"
  },
  {
    "uniprot": "A0A3Q0KHK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHK8"
  },
  {
    "uniprot": "A0A5K4EI77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EI77"
  },
  {
    "uniprot": "A0A3Q0KP09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP09"
  },
  {
    "uniprot": "A0A3Q0KVH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVH8"
  },
  {
    "uniprot": "A0A3Q0KSE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSE9"
  },
  {
    "uniprot": "A0A5K4EVQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVQ9"
  },
  {
    "uniprot": "A0A5K4F9D0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9D0"
  },
  {
    "uniprot": "A0A5K4EQL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQL9"
  },
  {
    "uniprot": "A0A5K4ERE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERE2"
  },
  {
    "uniprot": "A0A3Q0KMC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMC8"
  },
  {
    "uniprot": "A0A5K4EK52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK52"
  },
  {
    "uniprot": "A0A5K4ELX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELX8"
  },
  {
    "uniprot": "A0A5K4FEI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEI0"
  },
  {
    "uniprot": "A0A5K4E9H4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9H4"
  },
  {
    "uniprot": "A0A5K4F2Z9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Z9"
  },
  {
    "uniprot": "A0A5K4F3M2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3M2"
  },
  {
    "uniprot": "A0A5K4FA20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA20"
  },
  {
    "uniprot": "A0A3Q0KLL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLL5"
  },
  {
    "uniprot": "G4VD18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD18"
  },
  {
    "uniprot": "A0A5K4F7S6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7S6"
  },
  {
    "uniprot": "A0A3Q0KE15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE15"
  },
  {
    "uniprot": "C4PYC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4PYC2"
  },
  {
    "uniprot": "A0A3Q0KQJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQJ7"
  },
  {
    "uniprot": "G4V9Y2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9Y2"
  },
  {
    "uniprot": "A0A5K4F688",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F688"
  },
  {
    "uniprot": "A0A5K4F6E0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6E0"
  },
  {
    "uniprot": "A0A5K4ESV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESV8"
  },
  {
    "uniprot": "A0A3Q0KHD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHD3"
  },
  {
    "uniprot": "A0A3Q0KKY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKY6"
  },
  {
    "uniprot": "A0A3Q0KMK2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMK2"
  },
  {
    "uniprot": "G4VJN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJN9"
  },
  {
    "uniprot": "A0A3Q0KLQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLQ0"
  },
  {
    "uniprot": "A0A5K4F3N6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3N6"
  },
  {
    "uniprot": "C4Q9B6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q9B6"
  },
  {
    "uniprot": "A0A5K4ET00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET00"
  },
  {
    "uniprot": "A0A5K4F1N8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1N8"
  },
  {
    "uniprot": "A0A5K4EPF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPF1"
  },
  {
    "uniprot": "A0A3Q0KPP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPP2"
  },
  {
    "uniprot": "A0A5K4EW13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW13"
  },
  {
    "uniprot": "G4LWV7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWV7"
  },
  {
    "uniprot": "A0A3Q0KQ98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ98"
  },
  {
    "uniprot": "A0A5K4ES81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES81"
  },
  {
    "uniprot": "G4V8F7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8F7"
  },
  {
    "uniprot": "A0A5K4F634",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F634"
  },
  {
    "uniprot": "A0A5K4F1E5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1E5"
  },
  {
    "uniprot": "A0A5K4F927",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F927"
  },
  {
    "uniprot": "A0A3Q0KTV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTV2"
  },
  {
    "uniprot": "A0A5K4F4K4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4K4"
  },
  {
    "uniprot": "G4VA08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA08"
  },
  {
    "uniprot": "A0A5K4EYB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYB2"
  },
  {
    "uniprot": "A0A5K4F1F6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1F6"
  },
  {
    "uniprot": "A0A5K4EWC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWC0"
  },
  {
    "uniprot": "A0A5K4ERY9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERY9"
  },
  {
    "uniprot": "A0A3Q0KDD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDD4"
  },
  {
    "uniprot": "A0A3Q0KND4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KND4"
  },
  {
    "uniprot": "A0A5K4F6W8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6W8"
  },
  {
    "uniprot": "A0A3Q0KII8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KII8"
  },
  {
    "uniprot": "A0A5K4FCG6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCG6"
  },
  {
    "uniprot": "A0A3Q0KKV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKV8"
  },
  {
    "uniprot": "A0A3Q0KJD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJD8"
  },
  {
    "uniprot": "A0A3Q0KT71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT71"
  },
  {
    "uniprot": "A0A3Q0KUG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUG3"
  },
  {
    "uniprot": "A0A5K4FBE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBE0"
  },
  {
    "uniprot": "A0A5K4EP17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP17"
  },
  {
    "uniprot": "A0A5K4EJZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJZ5"
  },
  {
    "uniprot": "A0A5K4EQN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQN9"
  },
  {
    "uniprot": "A0A3Q0KS68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS68"
  },
  {
    "uniprot": "A0A5K4ELN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELN9"
  },
  {
    "uniprot": "A0A5K4F014",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F014"
  },
  {
    "uniprot": "A0A3Q0KU69",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU69"
  },
  {
    "uniprot": "O17446",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/O17446"
  },
  {
    "uniprot": "A0A3Q0KMS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMS8"
  },
  {
    "uniprot": "G4VMC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMC1"
  },
  {
    "uniprot": "A0A3Q0KT06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT06"
  },
  {
    "uniprot": "A0A5K4ESX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESX4"
  },
  {
    "uniprot": "A0A3Q0KBK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBK7"
  },
  {
    "uniprot": "A0A5K4F224",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F224"
  },
  {
    "uniprot": "G4V7E5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7E5"
  },
  {
    "uniprot": "A0A5K4ENT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENT4"
  },
  {
    "uniprot": "A0A5K4EJV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJV3"
  },
  {
    "uniprot": "A0A5K4F7S5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7S5"
  },
  {
    "uniprot": "A0A5K4F9J5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9J5"
  },
  {
    "uniprot": "A0A3Q0KMK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMK7"
  },
  {
    "uniprot": "A0A5K4EPN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPN1"
  },
  {
    "uniprot": "A0A3Q0KLZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLZ5"
  },
  {
    "uniprot": "A0A5K4FBE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBE7"
  },
  {
    "uniprot": "A0A5K4F6G6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6G6"
  },
  {
    "uniprot": "A0A5K4F6V8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6V8"
  },
  {
    "uniprot": "A0A3Q0KNX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNX9"
  },
  {
    "uniprot": "A0A3Q0KG44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG44"
  },
  {
    "uniprot": "A0A5K4EEP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEP8"
  },
  {
    "uniprot": "A0A3Q0KPJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPJ8"
  },
  {
    "uniprot": "A0A3Q0KV26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV26"
  },
  {
    "uniprot": "A0A5K4F9R6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9R6"
  },
  {
    "uniprot": "A0A5K4EFT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFT8"
  },
  {
    "uniprot": "G4VIG3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIG3"
  },
  {
    "uniprot": "A0A3Q0KKP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKP4"
  },
  {
    "uniprot": "A0A3Q0KDT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDT0"
  },
  {
    "uniprot": "A0A5K4EZD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZD7"
  },
  {
    "uniprot": "A0A5K4EPU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPU5"
  },
  {
    "uniprot": "A0A5K4F415",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F415"
  },
  {
    "uniprot": "A0A3Q0KQ15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ15"
  },
  {
    "uniprot": "A0A5K4F610",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F610"
  },
  {
    "uniprot": "A0A5K4F7K9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7K9"
  },
  {
    "uniprot": "A0A5K4F740",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F740"
  },
  {
    "uniprot": "A0A5K4FD50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD50"
  },
  {
    "uniprot": "G4VK54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VK54"
  },
  {
    "uniprot": "A0A3Q0KTJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTJ7"
  },
  {
    "uniprot": "G4VSB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSB8"
  },
  {
    "uniprot": "A0A3Q0KIY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIY6"
  },
  {
    "uniprot": "A0A5K4F8V0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8V0"
  },
  {
    "uniprot": "G4V6T5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6T5"
  },
  {
    "uniprot": "A0A5K4F7B6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7B6"
  },
  {
    "uniprot": "A0A5K4F6Y2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Y2"
  },
  {
    "uniprot": "A0A5K4F4H6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4H6"
  },
  {
    "uniprot": "A0A5K4F4X7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4X7"
  },
  {
    "uniprot": "A0A5K4F0B3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0B3"
  },
  {
    "uniprot": "A0A3Q0KQ20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ20"
  },
  {
    "uniprot": "A0A5K4FBL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBL9"
  },
  {
    "uniprot": "A0A3Q0KPD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPD3"
  },
  {
    "uniprot": "A0A5K4F0P4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0P4"
  },
  {
    "uniprot": "A0A3Q0KE59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE59"
  },
  {
    "uniprot": "A0A5K4F091",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F091"
  },
  {
    "uniprot": "A0A5K4FA34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA34"
  },
  {
    "uniprot": "A0A3Q0KE42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KE42"
  },
  {
    "uniprot": "A0A3Q0KDJ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDJ4"
  },
  {
    "uniprot": "A0A5K4F116",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F116"
  },
  {
    "uniprot": "A0A3Q0KPZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPZ2"
  },
  {
    "uniprot": "A0A5K4F484",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F484"
  },
  {
    "uniprot": "G4VDH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDH3"
  },
  {
    "uniprot": "A0A3Q0KU08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU08"
  },
  {
    "uniprot": "A0A5K4EW17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EW17"
  },
  {
    "uniprot": "A0A5K4F548",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F548"
  },
  {
    "uniprot": "A0A3Q0KTX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTX7"
  },
  {
    "uniprot": "A0A5K4EG59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EG59"
  },
  {
    "uniprot": "A0A5K4ENH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENH8"
  },
  {
    "uniprot": "A0A3Q0KSQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSQ3"
  },
  {
    "uniprot": "A0A3Q0KR39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR39"
  },
  {
    "uniprot": "G4VRI3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRI3"
  },
  {
    "uniprot": "A0A5K4F954",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F954"
  },
  {
    "uniprot": "A0A3Q0KUJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUJ1"
  },
  {
    "uniprot": "A0A3Q0KRY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRY3"
  },
  {
    "uniprot": "G4LZ88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ88"
  },
  {
    "uniprot": "A0A3Q0KSX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSX3"
  },
  {
    "uniprot": "A0A3Q0KTT6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTT6"
  },
  {
    "uniprot": "G4VE31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VE31"
  },
  {
    "uniprot": "A0A3Q0KF58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF58"
  },
  {
    "uniprot": "A0A5K4EJB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJB5"
  },
  {
    "uniprot": "A0A3Q0KM13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM13"
  },
  {
    "uniprot": "G4V9T6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9T6"
  },
  {
    "uniprot": "A0A3Q0KKC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKC9"
  },
  {
    "uniprot": "A0A5K4EVL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVL8"
  },
  {
    "uniprot": "A0A5K4E8X1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E8X1"
  },
  {
    "uniprot": "A0A5K4EAZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAZ5"
  },
  {
    "uniprot": "A0A0U5DPH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A0U5DPH3"
  },
  {
    "uniprot": "G4VA12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VA12"
  },
  {
    "uniprot": "A0A3Q0KQQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQQ8"
  },
  {
    "uniprot": "A0A5K4EUI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUI0"
  },
  {
    "uniprot": "A0A5K4EZJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZJ6"
  },
  {
    "uniprot": "A0A5K4F242",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F242"
  },
  {
    "uniprot": "A0A3Q0KQW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQW6"
  },
  {
    "uniprot": "A0A5K4ELM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELM1"
  },
  {
    "uniprot": "A0A5K4EXL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXL1"
  },
  {
    "uniprot": "A0A5K4ED87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ED87"
  },
  {
    "uniprot": "A0A5K4F685",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F685"
  },
  {
    "uniprot": "A0A3Q0KQG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQG8"
  },
  {
    "uniprot": "A0A5K4FCE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCE1"
  },
  {
    "uniprot": "G4VL28",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VL28"
  },
  {
    "uniprot": "G4VS23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VS23"
  },
  {
    "uniprot": "A0A5K4FD95",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD95"
  },
  {
    "uniprot": "A0A5K4FF80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FF80"
  },
  {
    "uniprot": "A0A5K4F3E4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3E4"
  },
  {
    "uniprot": "G4V5X9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5X9"
  },
  {
    "uniprot": "A0A5K4ETJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETJ9"
  },
  {
    "uniprot": "G4LYA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYA4"
  },
  {
    "uniprot": "A0A5K4F1M9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1M9"
  },
  {
    "uniprot": "G4VH39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH39"
  },
  {
    "uniprot": "A0A5K4FCA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCA6"
  },
  {
    "uniprot": "A0A3Q0KMK9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMK9"
  },
  {
    "uniprot": "A0A5K4FC04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC04"
  },
  {
    "uniprot": "G4VMD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMD4"
  },
  {
    "uniprot": "A0A5K4F6Y7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Y7"
  },
  {
    "uniprot": "A0A5K4F3U7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3U7"
  },
  {
    "uniprot": "A0A5K4F4G1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4G1"
  },
  {
    "uniprot": "A0A5K4ERN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERN5"
  },
  {
    "uniprot": "A0A5K4ES84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES84"
  },
  {
    "uniprot": "A0A3Q0KN21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN21"
  },
  {
    "uniprot": "A0A5K4EVQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVQ4"
  },
  {
    "uniprot": "A0A5K4F6C2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6C2"
  },
  {
    "uniprot": "A0A3Q0KCB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCB9"
  },
  {
    "uniprot": "P15845",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P15845"
  },
  {
    "uniprot": "A0A3Q0KPY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPY1"
  },
  {
    "uniprot": "A0A3Q0KNA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNA7"
  },
  {
    "uniprot": "G4LVW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVW1"
  },
  {
    "uniprot": "A0A5K4EM75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM75"
  },
  {
    "uniprot": "A0A3Q0KFX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFX1"
  },
  {
    "uniprot": "A0A3Q0KNR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNR6"
  },
  {
    "uniprot": "A0A3Q0KNQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNQ1"
  },
  {
    "uniprot": "A0A5K4F454",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F454"
  },
  {
    "uniprot": "A0A5K4FAC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAC2"
  },
  {
    "uniprot": "A0A5K4EX08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX08"
  },
  {
    "uniprot": "A0A3Q0KTE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTE6"
  },
  {
    "uniprot": "G4VGM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGM1"
  },
  {
    "uniprot": "A0A5K4F0M2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0M2"
  },
  {
    "uniprot": "G4VBE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBE5"
  },
  {
    "uniprot": "G4VT82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VT82"
  },
  {
    "uniprot": "A0A3Q0KM39",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM39"
  },
  {
    "uniprot": "A0A3Q0KUT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUT0"
  },
  {
    "uniprot": "A0A5K4F5B7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5B7"
  },
  {
    "uniprot": "A0A5K4EVV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVV6"
  },
  {
    "uniprot": "A0A5K4F2W2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2W2"
  },
  {
    "uniprot": "A0A3Q0KMF2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMF2"
  },
  {
    "uniprot": "G4V9W0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9W0"
  },
  {
    "uniprot": "G4VME9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VME9"
  },
  {
    "uniprot": "A0A3Q0KV00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV00"
  },
  {
    "uniprot": "A0A5K4ET59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET59"
  },
  {
    "uniprot": "A0A5K4ENY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENY6"
  },
  {
    "uniprot": "A0A5K4FA37",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA37"
  },
  {
    "uniprot": "A0A5K4FD93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD93"
  },
  {
    "uniprot": "A0A5K4F0K2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0K2"
  },
  {
    "uniprot": "A0A5K4ES18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ES18"
  },
  {
    "uniprot": "A0A5K4FE97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE97"
  },
  {
    "uniprot": "G4VE80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VE80"
  },
  {
    "uniprot": "A0A5K4F6D0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6D0"
  },
  {
    "uniprot": "A0A5K4F6P0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6P0"
  },
  {
    "uniprot": "A0A3Q0KML2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KML2"
  },
  {
    "uniprot": "A0A3Q0KGF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGF9"
  },
  {
    "uniprot": "A0A5K4F3V0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3V0"
  },
  {
    "uniprot": "G4VBH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBH0"
  },
  {
    "uniprot": "A0A5K4EPS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPS4"
  },
  {
    "uniprot": "A0A5K4F264",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F264"
  },
  {
    "uniprot": "A0A3Q0KK25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK25"
  },
  {
    "uniprot": "A0A3Q0KNY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNY5"
  },
  {
    "uniprot": "A0A3Q0KI63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI63"
  },
  {
    "uniprot": "A0A5K4EYM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYM0"
  },
  {
    "uniprot": "A0A3Q0KRT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRT2"
  },
  {
    "uniprot": "A0A5K4FEV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEV2"
  },
  {
    "uniprot": "G4V5D9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5D9"
  },
  {
    "uniprot": "A0A5K4FD26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD26"
  },
  {
    "uniprot": "G4LWN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWN5"
  },
  {
    "uniprot": "A0A5K4F2T6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2T6"
  },
  {
    "uniprot": "A0A3Q0KPH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPH6"
  },
  {
    "uniprot": "A0A5K4EF24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EF24"
  },
  {
    "uniprot": "A0A5K4ETF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETF5"
  },
  {
    "uniprot": "A0A5K4EZV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZV2"
  },
  {
    "uniprot": "A0A5K4FB01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB01"
  },
  {
    "uniprot": "A0A5K4ET58",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET58"
  },
  {
    "uniprot": "A0A5K4F6S8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6S8"
  },
  {
    "uniprot": "A0A3Q0KFW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFW2"
  },
  {
    "uniprot": "A0A5K4F4I0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4I0"
  },
  {
    "uniprot": "A0A3Q0KSP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSP5"
  },
  {
    "uniprot": "A0A3Q0KG83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG83"
  },
  {
    "uniprot": "A0A3Q0KD48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD48"
  },
  {
    "uniprot": "A0A3Q0KSD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSD5"
  },
  {
    "uniprot": "A0A5K4EAU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAU7"
  },
  {
    "uniprot": "A0A5K4EU76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EU76"
  },
  {
    "uniprot": "A0A3Q0KUC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUC0"
  },
  {
    "uniprot": "G4M0Z6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0Z6"
  },
  {
    "uniprot": "A0A5K4F869",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F869"
  },
  {
    "uniprot": "A0A5K4F427",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F427"
  },
  {
    "uniprot": "A0A5K4F331",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F331"
  },
  {
    "uniprot": "A0A3Q0KJW2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJW2"
  },
  {
    "uniprot": "G4VIN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIN5"
  },
  {
    "uniprot": "A0A5K4F0T7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0T7"
  },
  {
    "uniprot": "A0A3Q0KJB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJB0"
  },
  {
    "uniprot": "A0A5K4FFH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFH8"
  },
  {
    "uniprot": "A0A5K4EAN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAN3"
  },
  {
    "uniprot": "A0A3Q0KLP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLP6"
  },
  {
    "uniprot": "A0A5K4F767",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F767"
  },
  {
    "uniprot": "C4QHD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QHD7"
  },
  {
    "uniprot": "A0A3Q0KDQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDQ1"
  },
  {
    "uniprot": "A0A3Q0KT00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT00"
  },
  {
    "uniprot": "C4Q352",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q352"
  },
  {
    "uniprot": "A0A5K4F5V1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5V1"
  },
  {
    "uniprot": "A0A3Q0KUH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUH6"
  },
  {
    "uniprot": "A0A5K4F187",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F187"
  },
  {
    "uniprot": "A0A5K4F372",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F372"
  },
  {
    "uniprot": "A0A3Q0KR70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR70"
  },
  {
    "uniprot": "A0A5K4F9G4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9G4"
  },
  {
    "uniprot": "A0A3Q0KTA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTA8"
  },
  {
    "uniprot": "G4M032",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M032"
  },
  {
    "uniprot": "A0A5K4ENR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENR5"
  },
  {
    "uniprot": "A0A5K4F7D2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7D2"
  },
  {
    "uniprot": "A0A5K4F6Q3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Q3"
  },
  {
    "uniprot": "A0A3Q0KPM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPM7"
  },
  {
    "uniprot": "A0A3Q0KNQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNQ6"
  },
  {
    "uniprot": "G4VEA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEA9"
  },
  {
    "uniprot": "A0A3Q0KGT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGT2"
  },
  {
    "uniprot": "G4VEU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEU6"
  },
  {
    "uniprot": "A0A3Q0KHN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHN2"
  },
  {
    "uniprot": "A0A3Q0KK50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK50"
  },
  {
    "uniprot": "A0A3Q0KSS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSS5"
  },
  {
    "uniprot": "A0A5K4EUQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUQ0"
  },
  {
    "uniprot": "A0A5K4F0M8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0M8"
  },
  {
    "uniprot": "G4LYF9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYF9"
  },
  {
    "uniprot": "A0A5K4FCU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCU3"
  },
  {
    "uniprot": "A0A3Q0KN44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN44"
  },
  {
    "uniprot": "A0A3Q0KBA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBA2"
  },
  {
    "uniprot": "A0A3Q0KSH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSH9"
  },
  {
    "uniprot": "A0A3Q0KKB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKB5"
  },
  {
    "uniprot": "Q1X6L4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q1X6L4"
  },
  {
    "uniprot": "A0A5K4FC15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC15"
  },
  {
    "uniprot": "A0A5K4F550",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F550"
  },
  {
    "uniprot": "G4LUH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUH2"
  },
  {
    "uniprot": "A0A5K4EMF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMF3"
  },
  {
    "uniprot": "G4V800",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V800"
  },
  {
    "uniprot": "A0A5K4F6Y3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6Y3"
  },
  {
    "uniprot": "P19331",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P19331"
  },
  {
    "uniprot": "A0A5K4F0G8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0G8"
  },
  {
    "uniprot": "A0A5K4F3L9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3L9"
  },
  {
    "uniprot": "A0A5K4EIH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIH9"
  },
  {
    "uniprot": "A0A5K4F7V8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7V8"
  },
  {
    "uniprot": "A0A3Q0KSH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSH0"
  },
  {
    "uniprot": "A0A5K4EWX5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWX5"
  },
  {
    "uniprot": "A0A5K4ERH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERH5"
  },
  {
    "uniprot": "A0A3Q0KTS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTS3"
  },
  {
    "uniprot": "G4V8T5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8T5"
  },
  {
    "uniprot": "G4VCM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCM4"
  },
  {
    "uniprot": "A0A3Q0KCM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCM1"
  },
  {
    "uniprot": "A0A5K4FDY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDY3"
  },
  {
    "uniprot": "A0A5K4F0U4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0U4"
  },
  {
    "uniprot": "A0A5K4F8Z2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Z2"
  },
  {
    "uniprot": "A0A5K4FDV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDV9"
  },
  {
    "uniprot": "A0A3Q0KTZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTZ9"
  },
  {
    "uniprot": "A0A5K4FAA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAA4"
  },
  {
    "uniprot": "A0A5K4F509",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F509"
  },
  {
    "uniprot": "A0A5K4F027",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F027"
  },
  {
    "uniprot": "A0A3Q0KG59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KG59"
  },
  {
    "uniprot": "G4VAX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAX9"
  },
  {
    "uniprot": "A0A3Q0KQ18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ18"
  },
  {
    "uniprot": "A0A5K4E9E0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9E0"
  },
  {
    "uniprot": "A0A5K4EZ03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ03"
  },
  {
    "uniprot": "A0A3Q0KDH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDH6"
  },
  {
    "uniprot": "A0A3Q0KU54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU54"
  },
  {
    "uniprot": "G4VDP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDP5"
  },
  {
    "uniprot": "C4QJA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QJA6"
  },
  {
    "uniprot": "A0A3Q0KI71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI71"
  },
  {
    "uniprot": "A0A5K4EVS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVS6"
  },
  {
    "uniprot": "A0A5K4EZ06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ06"
  },
  {
    "uniprot": "A0A5K4ESI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESI9"
  },
  {
    "uniprot": "A0A3Q0KI48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI48"
  },
  {
    "uniprot": "A0A3Q0KTL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTL9"
  },
  {
    "uniprot": "A0A3Q0KGP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGP9"
  },
  {
    "uniprot": "A0A5K4FCT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCT1"
  },
  {
    "uniprot": "A0A3Q0KBZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBZ0"
  },
  {
    "uniprot": "C4Q2I8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q2I8"
  },
  {
    "uniprot": "G4VHQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHQ8"
  },
  {
    "uniprot": "A0A5K4E9L4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9L4"
  },
  {
    "uniprot": "G4LX01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LX01"
  },
  {
    "uniprot": "G4VSZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSZ9"
  },
  {
    "uniprot": "A0A5K4FAY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAY3"
  },
  {
    "uniprot": "A0A5K4EQX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQX8"
  },
  {
    "uniprot": "A0A3Q0KQH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQH2"
  },
  {
    "uniprot": "A0A5K4ECY1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECY1"
  },
  {
    "uniprot": "A0A3Q0KQ82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ82"
  },
  {
    "uniprot": "A0A3Q0KR54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR54"
  },
  {
    "uniprot": "A0A3Q0KJ59",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ59"
  },
  {
    "uniprot": "A0A3Q0KM99",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM99"
  },
  {
    "uniprot": "G4VN15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VN15"
  },
  {
    "uniprot": "A0A3Q0KUP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUP4"
  },
  {
    "uniprot": "A0A5K4EFC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFC2"
  },
  {
    "uniprot": "A0A3Q0KU16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU16"
  },
  {
    "uniprot": "A0A3Q0KRF7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRF7"
  },
  {
    "uniprot": "A0A5K4F8Z1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Z1"
  },
  {
    "uniprot": "A0A5K4FAG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAG1"
  },
  {
    "uniprot": "A0A5K4F8D3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8D3"
  },
  {
    "uniprot": "A0A5K4FFR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFR9"
  },
  {
    "uniprot": "A0A3Q0KSE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSE5"
  },
  {
    "uniprot": "A0A3Q0KLG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLG5"
  },
  {
    "uniprot": "A0A5K4F955",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F955"
  },
  {
    "uniprot": "A0A5K4F6T3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6T3"
  },
  {
    "uniprot": "A0A5K4ENI1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENI1"
  },
  {
    "uniprot": "A0A5K4EYW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYW6"
  },
  {
    "uniprot": "A0A5K4F2C7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2C7"
  },
  {
    "uniprot": "A0A5K4EXQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXQ4"
  },
  {
    "uniprot": "G4V9A8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9A8"
  },
  {
    "uniprot": "A0A3Q0KKZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKZ1"
  },
  {
    "uniprot": "A0A3Q0KSP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSP8"
  },
  {
    "uniprot": "A0A3Q0KQ07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ07"
  },
  {
    "uniprot": "A0A5K4F114",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F114"
  },
  {
    "uniprot": "A0A3Q0KDV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDV9"
  },
  {
    "uniprot": "A0A5K4EDL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDL9"
  },
  {
    "uniprot": "A0A3Q0KHN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHN3"
  },
  {
    "uniprot": "A0A5K4FDX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDX4"
  },
  {
    "uniprot": "G4LYA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYA9"
  },
  {
    "uniprot": "G4VCI7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCI7"
  },
  {
    "uniprot": "G4LZ05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ05"
  },
  {
    "uniprot": "A0A5K4F9K7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9K7"
  },
  {
    "uniprot": "A0A3Q0KK93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK93"
  },
  {
    "uniprot": "A0A5K4F777",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F777"
  },
  {
    "uniprot": "A0A3Q0KNA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNA6"
  },
  {
    "uniprot": "A0A5K4F8A5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8A5"
  },
  {
    "uniprot": "G4LZ44",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZ44"
  },
  {
    "uniprot": "A0A3Q0KQU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQU4"
  },
  {
    "uniprot": "G4LVD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVD7"
  },
  {
    "uniprot": "A0A5K4EVP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVP9"
  },
  {
    "uniprot": "A0A5K4ETB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETB1"
  },
  {
    "uniprot": "A0A5K4EY51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY51"
  },
  {
    "uniprot": "A0A5K4FA73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA73"
  },
  {
    "uniprot": "A0A5K4FGG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGG4"
  },
  {
    "uniprot": "A0A5K4FCA7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCA7"
  },
  {
    "uniprot": "A0A3Q0KLP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLP0"
  },
  {
    "uniprot": "A0A3Q0KER0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KER0"
  },
  {
    "uniprot": "A0A5K4FAI2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAI2"
  },
  {
    "uniprot": "G4LUH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUH1"
  },
  {
    "uniprot": "A0A3Q0KD75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD75"
  },
  {
    "uniprot": "A0A5K4FCL2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCL2"
  },
  {
    "uniprot": "A0A5K4F4X3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4X3"
  },
  {
    "uniprot": "G4LUL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUL7"
  },
  {
    "uniprot": "A0A3Q0KKL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKL3"
  },
  {
    "uniprot": "A0A3Q0KNT8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNT8"
  },
  {
    "uniprot": "A0A3Q0KKQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKQ1"
  },
  {
    "uniprot": "A0A5K4F6X7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6X7"
  },
  {
    "uniprot": "A0A3Q0KF97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF97"
  },
  {
    "uniprot": "A0A5K4EZN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZN2"
  },
  {
    "uniprot": "A0A5K4FBH0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBH0"
  },
  {
    "uniprot": "A0A3Q0KU14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU14"
  },
  {
    "uniprot": "A0A5K4EMV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMV8"
  },
  {
    "uniprot": "A0A5K4EM47",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM47"
  },
  {
    "uniprot": "A0A3Q0KCL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCL6"
  },
  {
    "uniprot": "A0A5K4FCG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCG2"
  },
  {
    "uniprot": "A0A5K4ELY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELY8"
  },
  {
    "uniprot": "A0A5K4F7L8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7L8"
  },
  {
    "uniprot": "A0A3Q0KRQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRQ7"
  },
  {
    "uniprot": "A0A3Q0KGS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGS2"
  },
  {
    "uniprot": "A0A5K4E9N1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4E9N1"
  },
  {
    "uniprot": "G4VQR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQR7"
  },
  {
    "uniprot": "A0A3Q0KCZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCZ9"
  },
  {
    "uniprot": "A0A5K4EZJ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZJ0"
  },
  {
    "uniprot": "A0A5K4F5W8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5W8"
  },
  {
    "uniprot": "A0A3Q0KV18",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV18"
  },
  {
    "uniprot": "A0A3Q0KQ35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ35"
  },
  {
    "uniprot": "G4VFN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFN2"
  },
  {
    "uniprot": "A0A3Q0KUP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUP0"
  },
  {
    "uniprot": "A0A5K4FCV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCV1"
  },
  {
    "uniprot": "A0A5K4FEU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEU7"
  },
  {
    "uniprot": "A0A3Q0KKH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKH8"
  },
  {
    "uniprot": "A0A5K4F1K0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1K0"
  },
  {
    "uniprot": "A0A146MGE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MGE2"
  },
  {
    "uniprot": "A0A5K4FAC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAC6"
  },
  {
    "uniprot": "A0A3Q0KS30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS30"
  },
  {
    "uniprot": "A0A5K4F445",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F445"
  },
  {
    "uniprot": "A0A3Q0KFN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFN0"
  },
  {
    "uniprot": "A0A5K4FGH3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGH3"
  },
  {
    "uniprot": "A0A3Q0KQM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQM8"
  },
  {
    "uniprot": "A0A5K4EY36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY36"
  },
  {
    "uniprot": "A0A3Q0KJ89",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ89"
  },
  {
    "uniprot": "A0A5K4F960",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F960"
  },
  {
    "uniprot": "A0A5K4FC32",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC32"
  },
  {
    "uniprot": "A0A5K4ECX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECX7"
  },
  {
    "uniprot": "A0A5K4F2D8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2D8"
  },
  {
    "uniprot": "G4VD99",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD99"
  },
  {
    "uniprot": "C4Q726",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q726"
  },
  {
    "uniprot": "A0A5K4F4Z7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4Z7"
  },
  {
    "uniprot": "A0A5K4EAQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAQ9"
  },
  {
    "uniprot": "A0A5K4F3G5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3G5"
  },
  {
    "uniprot": "A0A5K4FAL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAL4"
  },
  {
    "uniprot": "A0A5K4F429",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F429"
  },
  {
    "uniprot": "A0A5K4EZI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZI4"
  },
  {
    "uniprot": "A0A3Q0KD87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD87"
  },
  {
    "uniprot": "A0A3Q0KCX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCX0"
  },
  {
    "uniprot": "A0A5K4EPM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPM0"
  },
  {
    "uniprot": "A0A3Q0KLC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLC5"
  },
  {
    "uniprot": "A0A3Q0KV84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV84"
  },
  {
    "uniprot": "G4LY79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY79"
  },
  {
    "uniprot": "A0A5K4EX77",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX77"
  },
  {
    "uniprot": "A0A3Q0KDX8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDX8"
  },
  {
    "uniprot": "A0A3Q0KIV9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIV9"
  },
  {
    "uniprot": "A0A3Q0KM84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM84"
  },
  {
    "uniprot": "G4VJ06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ06"
  },
  {
    "uniprot": "A0A5K4FD60",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD60"
  },
  {
    "uniprot": "A0A3Q0KPX4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPX4"
  },
  {
    "uniprot": "A0A5K4F9X9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9X9"
  },
  {
    "uniprot": "A0A3Q0KTB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTB0"
  },
  {
    "uniprot": "G4LVS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LVS7"
  },
  {
    "uniprot": "A0A5K4F209",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F209"
  },
  {
    "uniprot": "A0A5K4EA68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EA68"
  },
  {
    "uniprot": "A0A5K4F913",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F913"
  },
  {
    "uniprot": "A0A5K4FFD7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFD7"
  },
  {
    "uniprot": "A0A3Q0KMX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMX2"
  },
  {
    "uniprot": "A0A3Q0KES3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KES3"
  },
  {
    "uniprot": "A0A3Q0KU34",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU34"
  },
  {
    "uniprot": "A0A3Q0KDV8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDV8"
  },
  {
    "uniprot": "G4VQT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQT7"
  },
  {
    "uniprot": "G4VAP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAP6"
  },
  {
    "uniprot": "A0A3Q0KQF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQF3"
  },
  {
    "uniprot": "A0A3Q0KHG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHG5"
  },
  {
    "uniprot": "A0A5K4FBE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBE3"
  },
  {
    "uniprot": "A0A5K4ESV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESV2"
  },
  {
    "uniprot": "G4V8I0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8I0"
  },
  {
    "uniprot": "A0A3Q0KET4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KET4"
  },
  {
    "uniprot": "A0A5K4FE41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE41"
  },
  {
    "uniprot": "A0A5K4EHW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHW4"
  },
  {
    "uniprot": "A0A5K4F1T8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1T8"
  },
  {
    "uniprot": "A0A5K4F9G7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9G7"
  },
  {
    "uniprot": "A0A5K4FBS5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBS5"
  },
  {
    "uniprot": "A0A5K4FG83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FG83"
  },
  {
    "uniprot": "G4VGI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGI0"
  },
  {
    "uniprot": "G4VJT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJT4"
  },
  {
    "uniprot": "A0A5K4F2G5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2G5"
  },
  {
    "uniprot": "A0A3Q0KDM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDM3"
  },
  {
    "uniprot": "G4VF13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF13"
  },
  {
    "uniprot": "G4VB90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB90"
  },
  {
    "uniprot": "A0A3Q0KJC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJC3"
  },
  {
    "uniprot": "A0A3Q0KDN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDN4"
  },
  {
    "uniprot": "G4VLC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLC5"
  },
  {
    "uniprot": "A0A5K4FH05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FH05"
  },
  {
    "uniprot": "G4VDR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDR4"
  },
  {
    "uniprot": "A0A3Q0KJS8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJS8"
  },
  {
    "uniprot": "A0A5K4FDS7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDS7"
  },
  {
    "uniprot": "A0A5K4ECB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECB9"
  },
  {
    "uniprot": "A0A5K4EEX3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEX3"
  },
  {
    "uniprot": "A0A5K4FAD4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAD4"
  },
  {
    "uniprot": "A0A5K4ELB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELB1"
  },
  {
    "uniprot": "G4M0U8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0U8"
  },
  {
    "uniprot": "A0A3Q0KH84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH84"
  },
  {
    "uniprot": "A0A5K4ENM4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENM4"
  },
  {
    "uniprot": "G4VFH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFH1"
  },
  {
    "uniprot": "A0A5K4EAG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAG2"
  },
  {
    "uniprot": "A0A3Q0KM51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM51"
  },
  {
    "uniprot": "A0A3Q0KTQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTQ5"
  },
  {
    "uniprot": "A0A5K4EQA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQA8"
  },
  {
    "uniprot": "G4VMH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VMH7"
  },
  {
    "uniprot": "A0A5K4F8Z4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8Z4"
  },
  {
    "uniprot": "A0A3Q0KQA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQA9"
  },
  {
    "uniprot": "A0A5K4F8M9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8M9"
  },
  {
    "uniprot": "G4VF81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF81"
  },
  {
    "uniprot": "A0A3Q0KUC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUC7"
  },
  {
    "uniprot": "A0A5K4F9B1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9B1"
  },
  {
    "uniprot": "G4V7W3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7W3"
  },
  {
    "uniprot": "G4VBP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBP5"
  },
  {
    "uniprot": "G4VTW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTW0"
  },
  {
    "uniprot": "A0A5K4F1R1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1R1"
  },
  {
    "uniprot": "A0A5K4F2M3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2M3"
  },
  {
    "uniprot": "A0A3Q0KT41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT41"
  },
  {
    "uniprot": "A0A3Q0KJY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJY8"
  },
  {
    "uniprot": "A0A5K4F8U6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8U6"
  },
  {
    "uniprot": "G4VC71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC71"
  },
  {
    "uniprot": "G4LZA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZA1"
  },
  {
    "uniprot": "A0A3Q0KIL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIL0"
  },
  {
    "uniprot": "P13492",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P13492"
  },
  {
    "uniprot": "C4QGM3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QGM3"
  },
  {
    "uniprot": "A0A3Q0KR76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR76"
  },
  {
    "uniprot": "A0A5K4F2M9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2M9"
  },
  {
    "uniprot": "A0A3Q0KBB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBB2"
  },
  {
    "uniprot": "A0A3Q0KNW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNW9"
  },
  {
    "uniprot": "A0A3Q0KV30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV30"
  },
  {
    "uniprot": "A0A3Q0KSG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSG5"
  },
  {
    "uniprot": "A0A5K4F542",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F542"
  },
  {
    "uniprot": "A0A5K4EQQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQQ0"
  },
  {
    "uniprot": "A0A3Q0KC51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC51"
  },
  {
    "uniprot": "A0A3Q0KMQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMQ2"
  },
  {
    "uniprot": "A0A5K4EKD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKD8"
  },
  {
    "uniprot": "A0A5K4EDW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDW4"
  },
  {
    "uniprot": "A0A5K4EAI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAI9"
  },
  {
    "uniprot": "G4VJ45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ45"
  },
  {
    "uniprot": "A0A5K4FDS9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDS9"
  },
  {
    "uniprot": "A0A5K4EY06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY06"
  },
  {
    "uniprot": "A0A5K4F8H5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8H5"
  },
  {
    "uniprot": "A0A3Q0KIN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIN3"
  },
  {
    "uniprot": "A0A5K4FAY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAY7"
  },
  {
    "uniprot": "A0A5K4FC24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC24"
  },
  {
    "uniprot": "A0A5K4F3A0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3A0"
  },
  {
    "uniprot": "A0A5K4F6U9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6U9"
  },
  {
    "uniprot": "A0A3Q0KMQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMQ4"
  },
  {
    "uniprot": "A0A3Q0KIN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIN2"
  },
  {
    "uniprot": "A0A3Q0KQ21",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ21"
  },
  {
    "uniprot": "A0A3Q0KKW8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKW8"
  },
  {
    "uniprot": "A0A5K4F9M8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9M8"
  },
  {
    "uniprot": "A0A3Q0KJR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJR1"
  },
  {
    "uniprot": "A0A3Q0KKM5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKM5"
  },
  {
    "uniprot": "A0A3Q0KEN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEN9"
  },
  {
    "uniprot": "A0A3Q0KLY6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLY6"
  },
  {
    "uniprot": "A0A5K4FC26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC26"
  },
  {
    "uniprot": "A0A3Q0KCT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCT4"
  },
  {
    "uniprot": "A0A5K4EZG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZG7"
  },
  {
    "uniprot": "A0A5K4F943",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F943"
  },
  {
    "uniprot": "A0A5K4EWA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWA2"
  },
  {
    "uniprot": "A0A3Q0KVC5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVC5"
  },
  {
    "uniprot": "G4VHW7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHW7"
  },
  {
    "uniprot": "G4VAG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAG9"
  },
  {
    "uniprot": "A0A5K4EZZ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZZ0"
  },
  {
    "uniprot": "A0A5K4EPF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPF8"
  },
  {
    "uniprot": "A0A3Q0KM14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM14"
  },
  {
    "uniprot": "A0A5K4EKG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKG2"
  },
  {
    "uniprot": "A0A3Q0KV61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV61"
  },
  {
    "uniprot": "A0A5K4FA42",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA42"
  },
  {
    "uniprot": "G4V5M1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5M1"
  },
  {
    "uniprot": "A0A5K4F2P7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2P7"
  },
  {
    "uniprot": "A0A5K4F0X1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0X1"
  },
  {
    "uniprot": "C4PYI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4PYI6"
  },
  {
    "uniprot": "A0A5K4EH71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EH71"
  },
  {
    "uniprot": "A0A5K4F6R0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6R0"
  },
  {
    "uniprot": "A0A3Q0KHN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHN7"
  },
  {
    "uniprot": "A0A3Q0KS50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS50"
  },
  {
    "uniprot": "G4VSQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VSQ8"
  },
  {
    "uniprot": "A0A5K4F6N7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6N7"
  },
  {
    "uniprot": "G4VQM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VQM9"
  },
  {
    "uniprot": "A0A3Q0KKR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKR3"
  },
  {
    "uniprot": "G4VRD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRD1"
  },
  {
    "uniprot": "G4VP86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VP86"
  },
  {
    "uniprot": "A0A5K4EHD8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHD8"
  },
  {
    "uniprot": "A0A5K4FEP6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEP6"
  },
  {
    "uniprot": "A0A5K4F4T3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4T3"
  },
  {
    "uniprot": "G4VH67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH67"
  },
  {
    "uniprot": "A0A5K4F7B0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7B0"
  },
  {
    "uniprot": "A0A3Q0KMF3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMF3"
  },
  {
    "uniprot": "A0A5K4ENR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENR4"
  },
  {
    "uniprot": "G4VTB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTB6"
  },
  {
    "uniprot": "G4VB07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB07"
  },
  {
    "uniprot": "A0A3Q0KC63",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC63"
  },
  {
    "uniprot": "A0A5K4F6V4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6V4"
  },
  {
    "uniprot": "A0A3Q0KEJ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEJ8"
  },
  {
    "uniprot": "A0A5K4FFD6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFD6"
  },
  {
    "uniprot": "A0A5K4EZV4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZV4"
  },
  {
    "uniprot": "A0A5K4F8X6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8X6"
  },
  {
    "uniprot": "A0A3Q0KRT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRT0"
  },
  {
    "uniprot": "A0A5K4F422",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F422"
  },
  {
    "uniprot": "G4VR86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VR86"
  },
  {
    "uniprot": "A0A5K4EF05",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EF05"
  },
  {
    "uniprot": "A0A5K4F5L5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5L5"
  },
  {
    "uniprot": "A0A3Q0KM85",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM85"
  },
  {
    "uniprot": "A0A5K4F642",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F642"
  },
  {
    "uniprot": "A0A5K4EHZ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHZ1"
  },
  {
    "uniprot": "A0A3Q0KIW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIW0"
  },
  {
    "uniprot": "A0A5K4F492",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F492"
  },
  {
    "uniprot": "A0A5K4FAK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAK5"
  },
  {
    "uniprot": "A0A3Q0KMN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMN6"
  },
  {
    "uniprot": "A0A5K4F587",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F587"
  },
  {
    "uniprot": "A0A3Q0KC46",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC46"
  },
  {
    "uniprot": "A0A3Q0KTW0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTW0"
  },
  {
    "uniprot": "A0A5K4FEL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEL7"
  },
  {
    "uniprot": "A0A5K4EPB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPB8"
  },
  {
    "uniprot": "A0A3Q0KT23",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT23"
  },
  {
    "uniprot": "A0A5K4FGX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGX1"
  },
  {
    "uniprot": "A0A5K4F9M0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9M0"
  },
  {
    "uniprot": "A0A5K4F3L2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3L2"
  },
  {
    "uniprot": "A0A5K4F0L7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0L7"
  },
  {
    "uniprot": "A0A5K4F274",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F274"
  },
  {
    "uniprot": "A0A3Q0KLV2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLV2"
  },
  {
    "uniprot": "A0A5K4EB83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB83"
  },
  {
    "uniprot": "A0A5K4EKG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKG5"
  },
  {
    "uniprot": "A0A3Q0KID4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KID4"
  },
  {
    "uniprot": "A0A3Q0KFQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFQ1"
  },
  {
    "uniprot": "A0A5K4F8C4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8C4"
  },
  {
    "uniprot": "A0A5K4FEE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEE5"
  },
  {
    "uniprot": "A0A3Q0KM92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM92"
  },
  {
    "uniprot": "A0A5K4F4M3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4M3"
  },
  {
    "uniprot": "A0A5K4F7B2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7B2"
  },
  {
    "uniprot": "A0A5K4FBD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBD5"
  },
  {
    "uniprot": "A0A3Q0KJF4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJF4"
  },
  {
    "uniprot": "G4LXH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXH7"
  },
  {
    "uniprot": "A0A5K4F2H0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2H0"
  },
  {
    "uniprot": "A0A5K4EEB8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEB8"
  },
  {
    "uniprot": "A0A5K4EYH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYH7"
  },
  {
    "uniprot": "A0A3Q0KMN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMN1"
  },
  {
    "uniprot": "A0A5K4F076",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F076"
  },
  {
    "uniprot": "A0A5K4F9S6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9S6"
  },
  {
    "uniprot": "A0A5K4FEC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FEC1"
  },
  {
    "uniprot": "A0A5K4F3W3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3W3"
  },
  {
    "uniprot": "A0A5K4EL19",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL19"
  },
  {
    "uniprot": "A0A5K4FAT7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAT7"
  },
  {
    "uniprot": "A0A3Q0KS88",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS88"
  },
  {
    "uniprot": "A0A5K4F1H9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1H9"
  },
  {
    "uniprot": "A0A3Q0KRS3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRS3"
  },
  {
    "uniprot": "A0A3Q0KM70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM70"
  },
  {
    "uniprot": "A0A5K4F7A1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7A1"
  },
  {
    "uniprot": "A0A3Q0KKU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKU3"
  },
  {
    "uniprot": "A0A3Q0KNP4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNP4"
  },
  {
    "uniprot": "A0A5K4F3M9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3M9"
  },
  {
    "uniprot": "A0A3Q0KLU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLU3"
  },
  {
    "uniprot": "A0A5K4FCU9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCU9"
  },
  {
    "uniprot": "A0A5K4FBS4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBS4"
  },
  {
    "uniprot": "A0A5K4F476",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F476"
  },
  {
    "uniprot": "A0A3Q0KR80",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR80"
  },
  {
    "uniprot": "A0A3Q0KQ30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ30"
  },
  {
    "uniprot": "A0A5K4F656",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F656"
  },
  {
    "uniprot": "A0A3Q0KEU5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEU5"
  },
  {
    "uniprot": "G4VC84",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC84"
  },
  {
    "uniprot": "A0A5K4F4F1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4F1"
  },
  {
    "uniprot": "A0A3Q0KMN0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMN0"
  },
  {
    "uniprot": "A0A3Q0KMH7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMH7"
  },
  {
    "uniprot": "A0A3Q0KTP8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTP8"
  },
  {
    "uniprot": "A0A5K4FBW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBW4"
  },
  {
    "uniprot": "G4V6B9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6B9"
  },
  {
    "uniprot": "A0A3Q0KK06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK06"
  },
  {
    "uniprot": "A0A3Q0KI12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI12"
  },
  {
    "uniprot": "A0A5K4FD16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD16"
  },
  {
    "uniprot": "A0A5K4FCJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCJ1"
  },
  {
    "uniprot": "A0A5K4EDJ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDJ9"
  },
  {
    "uniprot": "G4V9M8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V9M8"
  },
  {
    "uniprot": "G4LWZ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWZ9"
  },
  {
    "uniprot": "A0A3Q0KQZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQZ2"
  },
  {
    "uniprot": "A0A5K4EZS1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZS1"
  },
  {
    "uniprot": "A0A5K4F9D5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9D5"
  },
  {
    "uniprot": "A0A5K4EC52",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EC52"
  },
  {
    "uniprot": "A0A5K4F8I5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8I5"
  },
  {
    "uniprot": "G4M0K4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0K4"
  },
  {
    "uniprot": "A0A3Q0KN57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN57"
  },
  {
    "uniprot": "A0A5K4F6F9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6F9"
  },
  {
    "uniprot": "A0A5K4FCC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCC3"
  },
  {
    "uniprot": "A0A5K4EZT5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZT5"
  },
  {
    "uniprot": "G4VPL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPL7"
  },
  {
    "uniprot": "G4VBG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBG8"
  },
  {
    "uniprot": "A0A5K4FAC1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAC1"
  },
  {
    "uniprot": "A0A3Q0KUE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUE0"
  },
  {
    "uniprot": "A0A5K4F0L9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0L9"
  },
  {
    "uniprot": "G4VLE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VLE1"
  },
  {
    "uniprot": "A0A5K4F974",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F974"
  },
  {
    "uniprot": "A0A5K4F719",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F719"
  },
  {
    "uniprot": "A0A5K4EQB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQB2"
  },
  {
    "uniprot": "A0A3Q0KTF1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTF1"
  },
  {
    "uniprot": "G4VC00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC00"
  },
  {
    "uniprot": "C4QRV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QRV0"
  },
  {
    "uniprot": "A0A5K4F5V7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5V7"
  },
  {
    "uniprot": "G4LV76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LV76"
  },
  {
    "uniprot": "A0A5K4F1Y5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Y5"
  },
  {
    "uniprot": "G4VRQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRQ0"
  },
  {
    "uniprot": "A0A5K4FD56",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD56"
  },
  {
    "uniprot": "A0A5K4EYC2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYC2"
  },
  {
    "uniprot": "A0A3Q0KFR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFR8"
  },
  {
    "uniprot": "A0A5K4EF01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EF01"
  },
  {
    "uniprot": "P16026",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P16026"
  },
  {
    "uniprot": "G4M0L1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0L1"
  },
  {
    "uniprot": "A0A5K4FGW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGW4"
  },
  {
    "uniprot": "A0A5K4F0U7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0U7"
  },
  {
    "uniprot": "A0A5K4EZU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZU7"
  },
  {
    "uniprot": "A0A5K4F523",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F523"
  },
  {
    "uniprot": "A0A5K4EXN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXN2"
  },
  {
    "uniprot": "A0A5K4F0D0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0D0"
  },
  {
    "uniprot": "A0A3Q0KU30",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU30"
  },
  {
    "uniprot": "A0A5K4F705",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F705"
  },
  {
    "uniprot": "A0A3Q0KL79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL79"
  },
  {
    "uniprot": "A0A5K4EL82",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL82"
  },
  {
    "uniprot": "A0A5K4F147",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F147"
  },
  {
    "uniprot": "A0A5K4F922",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F922"
  },
  {
    "uniprot": "A0A3Q0KRJ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRJ6"
  },
  {
    "uniprot": "A0A3Q0KJL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJL4"
  },
  {
    "uniprot": "G4VHF0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHF0"
  },
  {
    "uniprot": "A0A5K4EPN8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPN8"
  },
  {
    "uniprot": "A0A5K4EYQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYQ2"
  },
  {
    "uniprot": "A0A5K4F5A0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5A0"
  },
  {
    "uniprot": "A0A3Q0KNQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNQ0"
  },
  {
    "uniprot": "A0A5K4F5J3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5J3"
  },
  {
    "uniprot": "A0A5K4F009",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F009"
  },
  {
    "uniprot": "A0A5K4F7R8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7R8"
  },
  {
    "uniprot": "G4M196",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M196"
  },
  {
    "uniprot": "A0A5K4F9U3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9U3"
  },
  {
    "uniprot": "A0A3Q0KJN4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJN4"
  },
  {
    "uniprot": "A0A3Q0KU24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU24"
  },
  {
    "uniprot": "A0A3Q0KGA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGA2"
  },
  {
    "uniprot": "A0A5K4EDU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDU4"
  },
  {
    "uniprot": "A0A3Q0KR36",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR36"
  },
  {
    "uniprot": "A0A5K4EKV6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKV6"
  },
  {
    "uniprot": "A0A3Q0KJ86",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ86"
  },
  {
    "uniprot": "G4VBG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBG9"
  },
  {
    "uniprot": "A0A3Q0KLM1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLM1"
  },
  {
    "uniprot": "A0A5K4FC49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC49"
  },
  {
    "uniprot": "A0A5K4F478",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F478"
  },
  {
    "uniprot": "A0A5K4FC07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC07"
  },
  {
    "uniprot": "A0A3Q0KKF5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKF5"
  },
  {
    "uniprot": "G4LUT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUT0"
  },
  {
    "uniprot": "A0A5K4FAQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAQ2"
  },
  {
    "uniprot": "G4LZZ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZZ2"
  },
  {
    "uniprot": "G4VTR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTR6"
  },
  {
    "uniprot": "A0A5K4EQL3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQL3"
  },
  {
    "uniprot": "A0A5K4FCC6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCC6"
  },
  {
    "uniprot": "A0A5K4F7S3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7S3"
  },
  {
    "uniprot": "G4VPH9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPH9"
  },
  {
    "uniprot": "A0A5K4F8J9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8J9"
  },
  {
    "uniprot": "G4V6B8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V6B8"
  },
  {
    "uniprot": "A0A3Q0KEK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEK4"
  },
  {
    "uniprot": "G4VBT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBT2"
  },
  {
    "uniprot": "A0A5K4FAZ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAZ5"
  },
  {
    "uniprot": "A0A5K4F3H2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3H2"
  },
  {
    "uniprot": "A0A3Q0KKZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKZ6"
  },
  {
    "uniprot": "A0A5K4EM09",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM09"
  },
  {
    "uniprot": "A0A3Q0KVJ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVJ5"
  },
  {
    "uniprot": "A0A3Q0KSH4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSH4"
  },
  {
    "uniprot": "A0A5K4EDR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDR3"
  },
  {
    "uniprot": "A0A5K4F1L0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1L0"
  },
  {
    "uniprot": "G4VCN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCN3"
  },
  {
    "uniprot": "A0A5K4F889",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F889"
  },
  {
    "uniprot": "G4VH75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VH75"
  },
  {
    "uniprot": "A0A3Q0KMV3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMV3"
  },
  {
    "uniprot": "A0A5K4F5S2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5S2"
  },
  {
    "uniprot": "A0A3Q0KSQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSQ6"
  },
  {
    "uniprot": "A0A3Q0KU03",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU03"
  },
  {
    "uniprot": "G4VRT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRT0"
  },
  {
    "uniprot": "G4VDB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDB4"
  },
  {
    "uniprot": "G4VI15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VI15"
  },
  {
    "uniprot": "A0A3Q0KFH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFH8"
  },
  {
    "uniprot": "A0A5K4ECU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECU3"
  },
  {
    "uniprot": "G4V620",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V620"
  },
  {
    "uniprot": "G4LYG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LYG9"
  },
  {
    "uniprot": "A0A5K4EB54",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB54"
  },
  {
    "uniprot": "A0A5K4F4B1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4B1"
  },
  {
    "uniprot": "G4VB35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VB35"
  },
  {
    "uniprot": "A0A3Q0KJR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJR0"
  },
  {
    "uniprot": "A0A5K4F400",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F400"
  },
  {
    "uniprot": "A0A5K4EN24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EN24"
  },
  {
    "uniprot": "A0A146MHL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A146MHL9"
  },
  {
    "uniprot": "A0A3Q0KJD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJD3"
  },
  {
    "uniprot": "G4VDE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDE9"
  },
  {
    "uniprot": "G4LZI8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZI8"
  },
  {
    "uniprot": "A0A3Q0KMS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMS0"
  },
  {
    "uniprot": "A0A5K4EDR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EDR6"
  },
  {
    "uniprot": "A0A5K4EPP1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPP1"
  },
  {
    "uniprot": "A0A3Q0KNU2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNU2"
  },
  {
    "uniprot": "A0A5K4F617",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F617"
  },
  {
    "uniprot": "A0A5K4EGU8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGU8"
  },
  {
    "uniprot": "A0A5K4FAR8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAR8"
  },
  {
    "uniprot": "A0A3Q0KED4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KED4"
  },
  {
    "uniprot": "A0A5K4FD97",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD97"
  },
  {
    "uniprot": "A0A3Q0KVA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KVA6"
  },
  {
    "uniprot": "A0A3Q0KHI9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHI9"
  },
  {
    "uniprot": "A0A5K4FCE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCE6"
  },
  {
    "uniprot": "A0A5K4F1D2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1D2"
  },
  {
    "uniprot": "A0A3Q0KSU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSU1"
  },
  {
    "uniprot": "A0A3Q0KIP2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIP2"
  },
  {
    "uniprot": "A0A3Q0KPD9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPD9"
  },
  {
    "uniprot": "G4LXG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LXG4"
  },
  {
    "uniprot": "A0A3Q0KMX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMX7"
  },
  {
    "uniprot": "G4VAK5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAK5"
  },
  {
    "uniprot": "A0A5K4EI66",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EI66"
  },
  {
    "uniprot": "A0A5K4F4E5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4E5"
  },
  {
    "uniprot": "A0A5K4EIQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIQ2"
  },
  {
    "uniprot": "Q1WIQ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q1WIQ3"
  },
  {
    "uniprot": "A0A3Q0KQ17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ17"
  },
  {
    "uniprot": "A0A3Q0KR45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR45"
  },
  {
    "uniprot": "A0A5K4F8J0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8J0"
  },
  {
    "uniprot": "A0A5K4EZA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZA4"
  },
  {
    "uniprot": "A0A3Q0KRE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRE9"
  },
  {
    "uniprot": "A0A5K4F6V1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6V1"
  },
  {
    "uniprot": "A0A5K4FGF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FGF8"
  },
  {
    "uniprot": "A0A5K4F0P5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0P5"
  },
  {
    "uniprot": "A0A5K4EK50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EK50"
  },
  {
    "uniprot": "A0A5K4EPR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPR3"
  },
  {
    "uniprot": "A0A5K4FBP9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBP9"
  },
  {
    "uniprot": "A0A5K4FA01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA01"
  },
  {
    "uniprot": "A0A5K4EQL0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQL0"
  },
  {
    "uniprot": "G4M0G6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0G6"
  },
  {
    "uniprot": "A0A5K4EVJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVJ3"
  },
  {
    "uniprot": "Q9NJ24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q9NJ24"
  },
  {
    "uniprot": "A0A5K4F6H4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6H4"
  },
  {
    "uniprot": "A0A3Q0KST6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KST6"
  },
  {
    "uniprot": "A0A5K4EXX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXX7"
  },
  {
    "uniprot": "A0A3Q0KP74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP74"
  },
  {
    "uniprot": "A0A3Q0KFR0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFR0"
  },
  {
    "uniprot": "A0A5K4F0Z3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0Z3"
  },
  {
    "uniprot": "G4VHB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHB4"
  },
  {
    "uniprot": "A0A5K4FB00",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB00"
  },
  {
    "uniprot": "G4VC92",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VC92"
  },
  {
    "uniprot": "A0A5K4FFA9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFA9"
  },
  {
    "uniprot": "A0A5K4EVQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVQ6"
  },
  {
    "uniprot": "A0A5K4EJE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EJE6"
  },
  {
    "uniprot": "A0A5K4F7Y4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7Y4"
  },
  {
    "uniprot": "C4QQL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QQL6"
  },
  {
    "uniprot": "A0A5K4FBR4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBR4"
  },
  {
    "uniprot": "G4VCI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCI6"
  },
  {
    "uniprot": "A0A5K4FBV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBV1"
  },
  {
    "uniprot": "A0A3Q0KIT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIT4"
  },
  {
    "uniprot": "A0A5K4FC20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC20"
  },
  {
    "uniprot": "A0A5K4F0G2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0G2"
  },
  {
    "uniprot": "A0A5K4F1V3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1V3"
  },
  {
    "uniprot": "A0A5K4EX24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX24"
  },
  {
    "uniprot": "A0A5K4F9V3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9V3"
  },
  {
    "uniprot": "A0A5K4F665",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F665"
  },
  {
    "uniprot": "A0A5K4EQW3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQW3"
  },
  {
    "uniprot": "A0A5K4F746",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F746"
  },
  {
    "uniprot": "A0A3Q0KEJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEJ3"
  },
  {
    "uniprot": "A0A3Q0KHK4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHK4"
  },
  {
    "uniprot": "A0A5K4ERR6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERR6"
  },
  {
    "uniprot": "A0A3Q0KD26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD26"
  },
  {
    "uniprot": "A0A3Q0KQR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQR1"
  },
  {
    "uniprot": "A0A5K4ECW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ECW6"
  },
  {
    "uniprot": "A0A3Q0KLE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLE6"
  },
  {
    "uniprot": "A0A5K4EQQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQQ9"
  },
  {
    "uniprot": "G4VAX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAX2"
  },
  {
    "uniprot": "A0A3Q0KMA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMA5"
  },
  {
    "uniprot": "A0A5K4F354",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F354"
  },
  {
    "uniprot": "G4V8Z2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8Z2"
  },
  {
    "uniprot": "Q15ES0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q15ES0"
  },
  {
    "uniprot": "A0A3Q0KMI5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMI5"
  },
  {
    "uniprot": "A0A3Q0KJD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJD5"
  },
  {
    "uniprot": "G4LUC8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LUC8"
  },
  {
    "uniprot": "A0A5K4F0P7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0P7"
  },
  {
    "uniprot": "A0A5K4EGY8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EGY8"
  },
  {
    "uniprot": "A0A5K4F5D6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5D6"
  },
  {
    "uniprot": "A0A5K4FB16",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB16"
  },
  {
    "uniprot": "A0A5K4FFW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFW1"
  },
  {
    "uniprot": "G4VIZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIZ3"
  },
  {
    "uniprot": "A0A5K4F5Y6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5Y6"
  },
  {
    "uniprot": "A0A3Q0KPN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPN6"
  },
  {
    "uniprot": "A0A3Q0KPX0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPX0"
  },
  {
    "uniprot": "A0A3Q0KK20",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK20"
  },
  {
    "uniprot": "G4M0K6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0K6"
  },
  {
    "uniprot": "G4VRE1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRE1"
  },
  {
    "uniprot": "G4VDD2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDD2"
  },
  {
    "uniprot": "A0A5K4FCL5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCL5"
  },
  {
    "uniprot": "A0A3Q0KSL9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSL9"
  },
  {
    "uniprot": "A4VC64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A4VC64"
  },
  {
    "uniprot": "A0A3Q0KMZ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMZ4"
  },
  {
    "uniprot": "A0A5K4F0V2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0V2"
  },
  {
    "uniprot": "A0A5K4ERA5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERA5"
  },
  {
    "uniprot": "A0A3Q0KLA6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLA6"
  },
  {
    "uniprot": "G4VDH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDH8"
  },
  {
    "uniprot": "A0A5K4F019",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F019"
  },
  {
    "uniprot": "G4VPR1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPR1"
  },
  {
    "uniprot": "A0A5K4FDB9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDB9"
  },
  {
    "uniprot": "A0A5K4EG93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EG93"
  },
  {
    "uniprot": "A0A5K4F2I2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2I2"
  },
  {
    "uniprot": "A0A3Q0KFW4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFW4"
  },
  {
    "uniprot": "G4LZP0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZP0"
  },
  {
    "uniprot": "A0A5K4F9N4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9N4"
  },
  {
    "uniprot": "A0A3Q0KTT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTT4"
  },
  {
    "uniprot": "A0A5K4F3K2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3K2"
  },
  {
    "uniprot": "A0A5K4EX41",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX41"
  },
  {
    "uniprot": "G4VPX7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPX7"
  },
  {
    "uniprot": "A0A3Q0KK43",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK43"
  },
  {
    "uniprot": "A0A5K4FDZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDZ6"
  },
  {
    "uniprot": "G4V700",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V700"
  },
  {
    "uniprot": "A0A5K4F9S8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9S8"
  },
  {
    "uniprot": "A0A5K4EWR5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWR5"
  },
  {
    "uniprot": "A0A3Q0KMG4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMG4"
  },
  {
    "uniprot": "A0A5K4F421",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F421"
  },
  {
    "uniprot": "A0A5K4F3X9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3X9"
  },
  {
    "uniprot": "G4VRQ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VRQ7"
  },
  {
    "uniprot": "A0A3Q0KJS0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJS0"
  },
  {
    "uniprot": "G4VEY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VEY4"
  },
  {
    "uniprot": "A0A3Q0KTI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTI6"
  },
  {
    "uniprot": "A0A3Q0KSH1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSH1"
  },
  {
    "uniprot": "A0A3Q0KJ57",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ57"
  },
  {
    "uniprot": "A0A5K4EP70",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EP70"
  },
  {
    "uniprot": "A0A3Q0KNT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNT0"
  },
  {
    "uniprot": "A0A3Q0KNE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNE6"
  },
  {
    "uniprot": "A0A3Q0KU33",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU33"
  },
  {
    "uniprot": "A0A3Q0KJU7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJU7"
  },
  {
    "uniprot": "A0A5K4EPC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EPC9"
  },
  {
    "uniprot": "Q26602",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26602"
  },
  {
    "uniprot": "G4VPE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPE3"
  },
  {
    "uniprot": "G4LY75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LY75"
  },
  {
    "uniprot": "A0A3Q0KSV0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSV0"
  },
  {
    "uniprot": "A0A5K4FH87",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FH87"
  },
  {
    "uniprot": "G4VFF8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFF8"
  },
  {
    "uniprot": "A0A3Q0KKY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKY3"
  },
  {
    "uniprot": "A0A5K4F3V3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3V3"
  },
  {
    "uniprot": "A0A5K4F319",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F319"
  },
  {
    "uniprot": "A0A5K4FBR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBR3"
  },
  {
    "uniprot": "A0A5K4EQ45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQ45"
  },
  {
    "uniprot": "A0A5K4F4B8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4B8"
  },
  {
    "uniprot": "A0A5K4EEJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEJ3"
  },
  {
    "uniprot": "A0A5K4F5W5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5W5"
  },
  {
    "uniprot": "G4LZG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LZG1"
  },
  {
    "uniprot": "A0A5K4ET74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET74"
  },
  {
    "uniprot": "A0A5K4F3L8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3L8"
  },
  {
    "uniprot": "A0A5K4FD31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD31"
  },
  {
    "uniprot": "A0A5K4ESU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESU1"
  },
  {
    "uniprot": "A0A5K4ENG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENG5"
  },
  {
    "uniprot": "A0A3Q0KTJ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTJ1"
  },
  {
    "uniprot": "A0A5K4F0U9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0U9"
  },
  {
    "uniprot": "A0A5K4F0J2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0J2"
  },
  {
    "uniprot": "A0A5K4FAE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAE2"
  },
  {
    "uniprot": "A0A5K4EI68",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EI68"
  },
  {
    "uniprot": "A0A3Q0KK07",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK07"
  },
  {
    "uniprot": "A0A5K4FFJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFJ3"
  },
  {
    "uniprot": "A0A3Q0KUB1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUB1"
  },
  {
    "uniprot": "A0A5K4FE06",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE06"
  },
  {
    "uniprot": "A0A5K4FAP5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAP5"
  },
  {
    "uniprot": "A0A3Q0KPH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPH5"
  },
  {
    "uniprot": "G4VFD1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VFD1"
  },
  {
    "uniprot": "A0A5K4F244",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F244"
  },
  {
    "uniprot": "A0A3Q0KH61",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH61"
  },
  {
    "uniprot": "A0A3Q0KRM2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRM2"
  },
  {
    "uniprot": "A0A5K4F8C5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8C5"
  },
  {
    "uniprot": "A0A5K4EL02",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EL02"
  },
  {
    "uniprot": "A0A5K4FAD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAD5"
  },
  {
    "uniprot": "A0A5K4EYC0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYC0"
  },
  {
    "uniprot": "A0A3Q0KTU6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTU6"
  },
  {
    "uniprot": "A0A5K4ERA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERA3"
  },
  {
    "uniprot": "A0A5K4EZ01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ01"
  },
  {
    "uniprot": "G4VGJ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGJ3"
  },
  {
    "uniprot": "A0A5K4F9X1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9X1"
  },
  {
    "uniprot": "A0A5K4EYI4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYI4"
  },
  {
    "uniprot": "A0A5K4F1B1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1B1"
  },
  {
    "uniprot": "A0A3Q0KS10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS10"
  },
  {
    "uniprot": "A0A3Q0KV50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KV50"
  },
  {
    "uniprot": "A0A3Q0KEX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEX1"
  },
  {
    "uniprot": "A0A3Q0KKT4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKT4"
  },
  {
    "uniprot": "A0A5K4EMW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMW9"
  },
  {
    "uniprot": "A0A5K4EXN5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EXN5"
  },
  {
    "uniprot": "A0A5K4EKV1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKV1"
  },
  {
    "uniprot": "A0A3Q0KF74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KF74"
  },
  {
    "uniprot": "G4VNI0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VNI0"
  },
  {
    "uniprot": "A0A5K4FA15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA15"
  },
  {
    "uniprot": "A0A5K4ER93",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER93"
  },
  {
    "uniprot": "A0A3Q0KEB0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEB0"
  },
  {
    "uniprot": "G4VF75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF75"
  },
  {
    "uniprot": "A0A5K4F8F4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8F4"
  },
  {
    "uniprot": "A0A5K4F6D1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6D1"
  },
  {
    "uniprot": "A0A3Q0KSR7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSR7"
  },
  {
    "uniprot": "A0A5K4EEX2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EEX2"
  },
  {
    "uniprot": "A0A5K4FBA1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBA1"
  },
  {
    "uniprot": "G4VAW5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAW5"
  },
  {
    "uniprot": "A0A5K4FCL6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCL6"
  },
  {
    "uniprot": "G4V7R6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V7R6"
  },
  {
    "uniprot": "A0A3Q0KRC9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRC9"
  },
  {
    "uniprot": "A0A3Q0KH15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KH15"
  },
  {
    "uniprot": "A0A3Q0KJ01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJ01"
  },
  {
    "uniprot": "A0A3Q0KPD5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPD5"
  },
  {
    "uniprot": "A0A3Q0KP50",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP50"
  },
  {
    "uniprot": "A0A5K4F7L4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7L4"
  },
  {
    "uniprot": "A0A5K4EUU0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUU0"
  },
  {
    "uniprot": "A0A5K4F5H0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5H0"
  },
  {
    "uniprot": "A0A5K4F2Y2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Y2"
  },
  {
    "uniprot": "A0A5K4F5B4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5B4"
  },
  {
    "uniprot": "A0A5K4FE49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE49"
  },
  {
    "uniprot": "A0A3Q0KNT3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNT3"
  },
  {
    "uniprot": "A0A5K4FA64",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FA64"
  },
  {
    "uniprot": "A0A5K4F7J4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7J4"
  },
  {
    "uniprot": "A0A5K4ENN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENN1"
  },
  {
    "uniprot": "A0A5K4FAC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAC4"
  },
  {
    "uniprot": "A0A3Q0KBQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBQ1"
  },
  {
    "uniprot": "A0A5K4F259",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F259"
  },
  {
    "uniprot": "A0A5K4F518",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F518"
  },
  {
    "uniprot": "A0A5K4ESU4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESU4"
  },
  {
    "uniprot": "A0A3Q0KUN7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUN7"
  },
  {
    "uniprot": "G4VF10",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VF10"
  },
  {
    "uniprot": "A0A5K4ELE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELE0"
  },
  {
    "uniprot": "A0A3Q0KRM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRM6"
  },
  {
    "uniprot": "A0A5K4F9B8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9B8"
  },
  {
    "uniprot": "A0A5K4F2I8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2I8"
  },
  {
    "uniprot": "A0A5K4F3K6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3K6"
  },
  {
    "uniprot": "A0A5K4FCE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCE9"
  },
  {
    "uniprot": "A0A5K4F2F9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2F9"
  },
  {
    "uniprot": "A0A5K4F5I6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5I6"
  },
  {
    "uniprot": "A0A5K4F0M1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0M1"
  },
  {
    "uniprot": "A0A5K4EC24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EC24"
  },
  {
    "uniprot": "A0A5K4ET24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ET24"
  },
  {
    "uniprot": "A0A5K4F5K2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5K2"
  },
  {
    "uniprot": "A0A5K4F661",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F661"
  },
  {
    "uniprot": "A0A5K4FBK0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBK0"
  },
  {
    "uniprot": "A0A3Q0KN72",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN72"
  },
  {
    "uniprot": "G4VD90",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VD90"
  },
  {
    "uniprot": "A0A3Q0KDD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDD3"
  },
  {
    "uniprot": "A0A5K4ENM8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENM8"
  },
  {
    "uniprot": "G4VGM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGM0"
  },
  {
    "uniprot": "A0A3Q0KKE5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KKE5"
  },
  {
    "uniprot": "A0A5K4F1B2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1B2"
  },
  {
    "uniprot": "A0A3Q0KM35",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM35"
  },
  {
    "uniprot": "A0A5K4F834",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F834"
  },
  {
    "uniprot": "A0A5K4F3X0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3X0"
  },
  {
    "uniprot": "A0A3Q0KUQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUQ4"
  },
  {
    "uniprot": "A0A5K4F2J9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2J9"
  },
  {
    "uniprot": "A0A5K4FDB4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDB4"
  },
  {
    "uniprot": "A0A5K4FC81",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC81"
  },
  {
    "uniprot": "P08016",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P08016"
  },
  {
    "uniprot": "A0A5K4F5W7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5W7"
  },
  {
    "uniprot": "A0A3Q0KFH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFH5"
  },
  {
    "uniprot": "A0A5K4F199",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F199"
  },
  {
    "uniprot": "G4V5G1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5G1"
  },
  {
    "uniprot": "G4VJ24",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJ24"
  },
  {
    "uniprot": "A0A5K4EMM0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMM0"
  },
  {
    "uniprot": "A0A3Q0KEU1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KEU1"
  },
  {
    "uniprot": "A0A5K4F2Z1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2Z1"
  },
  {
    "uniprot": "A0A3Q0KNB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNB2"
  },
  {
    "uniprot": "A0A5K4F9G0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9G0"
  },
  {
    "uniprot": "A0A5K4ER11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ER11"
  },
  {
    "uniprot": "A0A5K4EC12",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EC12"
  },
  {
    "uniprot": "A0A3Q0KUE2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUE2"
  },
  {
    "uniprot": "A0A3Q0KUZ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KUZ6"
  },
  {
    "uniprot": "A0A5K4FBH2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBH2"
  },
  {
    "uniprot": "Q26549",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/Q26549"
  },
  {
    "uniprot": "A0A3Q0KT25",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT25"
  },
  {
    "uniprot": "A0A3Q0KTN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KTN3"
  },
  {
    "uniprot": "A0A5K4EM15",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EM15"
  },
  {
    "uniprot": "A0A5K4EZ11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZ11"
  },
  {
    "uniprot": "A0A3Q0KJY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJY3"
  },
  {
    "uniprot": "A0A5K4ETM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ETM7"
  },
  {
    "uniprot": "A0A5K4ESB6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESB6"
  },
  {
    "uniprot": "C4QFX9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QFX9"
  },
  {
    "uniprot": "A0A5K4FDA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDA2"
  },
  {
    "uniprot": "G4VBQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBQ0"
  },
  {
    "uniprot": "A0A3Q0KJM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJM6"
  },
  {
    "uniprot": "A0A5K4EUG5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EUG5"
  },
  {
    "uniprot": "A0A5K4EE67",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EE67"
  },
  {
    "uniprot": "A0A3Q0KRJ7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KRJ7"
  },
  {
    "uniprot": "A0A3Q0KSY0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSY0"
  },
  {
    "uniprot": "G4V8Q5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V8Q5"
  },
  {
    "uniprot": "A0A5K4F0C8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0C8"
  },
  {
    "uniprot": "A0A5K4EAY7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAY7"
  },
  {
    "uniprot": "A0A3Q0KMI6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KMI6"
  },
  {
    "uniprot": "A0A3Q0KSQ4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KSQ4"
  },
  {
    "uniprot": "A0A5K4FE17",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FE17"
  },
  {
    "uniprot": "A0A5K4F4W4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4W4"
  },
  {
    "uniprot": "A0A5K4F075",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F075"
  },
  {
    "uniprot": "A0A3Q0KR79",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR79"
  },
  {
    "uniprot": "A0A3Q0KDL7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KDL7"
  },
  {
    "uniprot": "A0A5K4ERY3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERY3"
  },
  {
    "uniprot": "C4QPQ0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4QPQ0"
  },
  {
    "uniprot": "A0A5K4ENB3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ENB3"
  },
  {
    "uniprot": "A0A5K4F904",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F904"
  },
  {
    "uniprot": "A0A5K4F160",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F160"
  },
  {
    "uniprot": "A0A5K4EB74",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EB74"
  },
  {
    "uniprot": "A0A3Q0KI14",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KI14"
  },
  {
    "uniprot": "A0A3Q0KIA2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KIA2"
  },
  {
    "uniprot": "A0A3Q0KC04",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC04"
  },
  {
    "uniprot": "A0A5K4FD83",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FD83"
  },
  {
    "uniprot": "A0A5K4ELW9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELW9"
  },
  {
    "uniprot": "A0A5K4F1X8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1X8"
  },
  {
    "uniprot": "A0A5K4F970",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F970"
  },
  {
    "uniprot": "C4Q2F2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/C4Q2F2"
  },
  {
    "uniprot": "A0A3Q0KBG9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBG9"
  },
  {
    "uniprot": "A0A3Q0KNM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNM7"
  },
  {
    "uniprot": "A0A5K4ERQ1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ERQ1"
  },
  {
    "uniprot": "A0A5K4F9V2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9V2"
  },
  {
    "uniprot": "A0A3Q0KT31",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KT31"
  },
  {
    "uniprot": "A0A5K4EFC7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EFC7"
  },
  {
    "uniprot": "A0A3Q0KML9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KML9"
  },
  {
    "uniprot": "A0A5K4F8P2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8P2"
  },
  {
    "uniprot": "A0A3Q0KS26",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS26"
  },
  {
    "uniprot": "A0A3Q0KL48",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KL48"
  },
  {
    "uniprot": "G4VPH8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPH8"
  },
  {
    "uniprot": "A0A3Q0KPL8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPL8"
  },
  {
    "uniprot": "A0A5K4F094",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F094"
  },
  {
    "uniprot": "A0A3Q0KU62",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KU62"
  },
  {
    "uniprot": "A0A5K4EMM9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EMM9"
  },
  {
    "uniprot": "G4VBZ3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBZ3"
  },
  {
    "uniprot": "A0A3Q0KK22",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KK22"
  },
  {
    "uniprot": "A0A5K4F8F8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8F8"
  },
  {
    "uniprot": "G4VGB5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VGB5"
  },
  {
    "uniprot": "A0A3Q0KJR9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJR9"
  },
  {
    "uniprot": "A0A3Q0KQC4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQC4"
  },
  {
    "uniprot": "G4V5F8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4V5F8"
  },
  {
    "uniprot": "A0A3Q0KPB2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPB2"
  },
  {
    "uniprot": "A0A5K4F3H5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3H5"
  },
  {
    "uniprot": "A0A5K4ELG8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELG8"
  },
  {
    "uniprot": "A0A3Q0KQ71",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ71"
  },
  {
    "uniprot": "A0A5K4F8L0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8L0"
  },
  {
    "uniprot": "A0A5K4FBK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBK8"
  },
  {
    "uniprot": "A0A5K4F3I8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3I8"
  },
  {
    "uniprot": "G4M164",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M164"
  },
  {
    "uniprot": "A0A5K4ESA0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ESA0"
  },
  {
    "uniprot": "A0A5K4F215",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F215"
  },
  {
    "uniprot": "A0A5K4F7M9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F7M9"
  },
  {
    "uniprot": "A0A5K4F1Q1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1Q1"
  },
  {
    "uniprot": "A0A3Q0KHG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KHG1"
  },
  {
    "uniprot": "A0A3Q0KFK8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KFK8"
  },
  {
    "uniprot": "G4VDQ9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VDQ9"
  },
  {
    "uniprot": "A0A5K4F301",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F301"
  },
  {
    "uniprot": "A0A5K4F6U7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F6U7"
  },
  {
    "uniprot": "A0A5K4F4R8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4R8"
  },
  {
    "uniprot": "A0A3Q0KN45",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN45"
  },
  {
    "uniprot": "A0A3Q0KCY4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KCY4"
  },
  {
    "uniprot": "A0A5K4F915",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F915"
  },
  {
    "uniprot": "G4VPT2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPT2"
  },
  {
    "uniprot": "A0A5K4EZS2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZS2"
  },
  {
    "uniprot": "A0A5K4FC53",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC53"
  },
  {
    "uniprot": "A0A5K4F8D8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8D8"
  },
  {
    "uniprot": "A0A5K4EVU3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EVU3"
  },
  {
    "uniprot": "A0A3Q0KN40",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KN40"
  },
  {
    "uniprot": "A0A5K4EZR3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZR3"
  },
  {
    "uniprot": "A0A3Q0KQ51",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KQ51"
  },
  {
    "uniprot": "A0A5K4ELK7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4ELK7"
  },
  {
    "uniprot": "A0A3Q0KJL1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJL1"
  },
  {
    "uniprot": "A0A3Q0KBE3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBE3"
  },
  {
    "uniprot": "A0A5K4FAM7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FAM7"
  },
  {
    "uniprot": "A0A5K4EZT1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EZT1"
  },
  {
    "uniprot": "A0A5K4EYD3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYD3"
  },
  {
    "uniprot": "G4VJG1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJG1"
  },
  {
    "uniprot": "A0A5K4EAA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EAA4"
  },
  {
    "uniprot": "A0A5K4EX76",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EX76"
  },
  {
    "uniprot": "A0A3Q0KPB7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPB7"
  },
  {
    "uniprot": "A0A3Q0KPW1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPW1"
  },
  {
    "uniprot": "A0A5K4F345",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F345"
  },
  {
    "uniprot": "A0A5K4EHA3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EHA3"
  },
  {
    "uniprot": "G4VCL4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCL4"
  },
  {
    "uniprot": "A0A3Q0KC01",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KC01"
  },
  {
    "uniprot": "A0A5K4EKN1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EKN1"
  },
  {
    "uniprot": "A0A5K4EG78",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EG78"
  },
  {
    "uniprot": "A0A5K4FDY5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FDY5"
  },
  {
    "uniprot": "A0A3Q0KLT0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLT0"
  },
  {
    "uniprot": "G4M0H4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4M0H4"
  },
  {
    "uniprot": "A0A5K4F1A0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F1A0"
  },
  {
    "uniprot": "A0A5K4F5H8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5H8"
  },
  {
    "uniprot": "A0A5K4FB08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB08"
  },
  {
    "uniprot": "A0A3Q0KM98",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KM98"
  },
  {
    "uniprot": "A0A5K4EQN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQN2"
  },
  {
    "uniprot": "A0A5K4FCH6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCH6"
  },
  {
    "uniprot": "A0A3Q0KS11",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KS11"
  },
  {
    "uniprot": "A0A5K4F2L3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2L3"
  },
  {
    "uniprot": "A0A5K4FFC3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FFC3"
  },
  {
    "uniprot": "A0A5K4FCN2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FCN2"
  },
  {
    "uniprot": "A0A5K4F502",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F502"
  },
  {
    "uniprot": "G4VIQ2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VIQ2"
  },
  {
    "uniprot": "G4VPE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VPE9"
  },
  {
    "uniprot": "A0A5K4FC75",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FC75"
  },
  {
    "uniprot": "A0A5K4F546",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F546"
  },
  {
    "uniprot": "A0A3Q0KP13",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KP13"
  },
  {
    "uniprot": "A0A5K4FBE6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBE6"
  },
  {
    "uniprot": "A0A3Q0KPE7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KPE7"
  },
  {
    "uniprot": "A0A5K4F4V3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4V3"
  },
  {
    "uniprot": "A0A5K4F8E2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8E2"
  },
  {
    "uniprot": "A0A3Q0KNN3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KNN3"
  },
  {
    "uniprot": "A0A5K4F4K2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4K2"
  },
  {
    "uniprot": "A0A5K4EQG7",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EQG7"
  },
  {
    "uniprot": "G4VHM6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VHM6"
  },
  {
    "uniprot": "A0A5K4F8B2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F8B2"
  },
  {
    "uniprot": "A0A3Q0KBW6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KBW6"
  },
  {
    "uniprot": "A0A5K4F9M3",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9M3"
  },
  {
    "uniprot": "A0A3Q0KD49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KD49"
  },
  {
    "uniprot": "G4VAE9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAE9"
  },
  {
    "uniprot": "G4VBH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VBH5"
  },
  {
    "uniprot": "A0A3Q0KJH5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KJH5"
  },
  {
    "uniprot": "A0A5K4F3R9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F3R9"
  },
  {
    "uniprot": "G4VAQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VAQ8"
  },
  {
    "uniprot": "A0A5K4EWN6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EWN6"
  },
  {
    "uniprot": "G4VJS6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VJS6"
  },
  {
    "uniprot": "A0A5K4FBG2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FBG2"
  },
  {
    "uniprot": "A0A3Q0KGQ8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGQ8"
  },
  {
    "uniprot": "G4LWA8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4LWA8"
  },
  {
    "uniprot": "A0A5K4EY49",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EY49"
  },
  {
    "uniprot": "A0A5K4F4D9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F4D9"
  },
  {
    "uniprot": "A0A3Q0KGE8",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KGE8"
  },
  {
    "uniprot": "A0A3Q0KLQ6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLQ6"
  },
  {
    "uniprot": "A0A5K4F9X4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9X4"
  },
  {
    "uniprot": "A0A5K4F5M5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F5M5"
  },
  {
    "uniprot": "A0A5K4FB73",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4FB73"
  },
  {
    "uniprot": "A0A5K4EIA4",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EIA4"
  },
  {
    "uniprot": "A0A5K4F2K6",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F2K6"
  },
  {
    "uniprot": "A0A5K4F0I2",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F0I2"
  },
  {
    "uniprot": "G4VCE0",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VCE0"
  },
  {
    "uniprot": "G4VTQ5",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/G4VTQ5"
  },
  {
    "uniprot": "A0A5K4F9Q1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4F9Q1"
  },
  {
    "uniprot": "A0A3Q0KLN9",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KLN9"
  },
  {
    "uniprot": "A0A3Q0KR08",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A3Q0KR08"
  },
  {
    "uniprot": "A0A5K4EYX1",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/A0A5K4EYX1"
  },
  {
    "uniprot": "P06649",
    "species": "Schistosoma mansoni",
    "disease": "Schistosomiasis",
    "results": "/results/P06649"
  },
  {
    "uniprot": "A0A044V2W4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2W4"
  },
  {
    "uniprot": "A0A044S4Z2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4Z2"
  },
  {
    "uniprot": "A0A044UI08",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI08"
  },
  {
    "uniprot": "A0A2K6W1S3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1S3"
  },
  {
    "uniprot": "A0A044RVL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVL9"
  },
  {
    "uniprot": "A0A044U3X3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3X3"
  },
  {
    "uniprot": "A0A044U0J3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0J3"
  },
  {
    "uniprot": "A0A044QMX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMX8"
  },
  {
    "uniprot": "A0A044RIV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIV9"
  },
  {
    "uniprot": "A0A2K6VMJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMJ4"
  },
  {
    "uniprot": "A0A044V0W3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0W3"
  },
  {
    "uniprot": "A0A2K6W3R9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3R9"
  },
  {
    "uniprot": "A0A044UE63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE63"
  },
  {
    "uniprot": "A0A044UVY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVY6"
  },
  {
    "uniprot": "A0A044U589",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U589"
  },
  {
    "uniprot": "A0A044V403",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V403"
  },
  {
    "uniprot": "A0A044S0V5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0V5"
  },
  {
    "uniprot": "A0A044UTL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTL6"
  },
  {
    "uniprot": "A0A044TM26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TM26"
  },
  {
    "uniprot": "A0A158N865",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N865"
  },
  {
    "uniprot": "A0A044S6M5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6M5"
  },
  {
    "uniprot": "A0A2K6W5S5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W5S5"
  },
  {
    "uniprot": "A0A044U0Y3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0Y3"
  },
  {
    "uniprot": "A0A044R8C4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8C4"
  },
  {
    "uniprot": "A0A044UYW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYW2"
  },
  {
    "uniprot": "A0A044SWD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWD1"
  },
  {
    "uniprot": "A0A044UCT3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCT3"
  },
  {
    "uniprot": "A0A044V844",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V844"
  },
  {
    "uniprot": "A0A044THM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THM7"
  },
  {
    "uniprot": "A0A044RVD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVD3"
  },
  {
    "uniprot": "A0A044TSI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSI9"
  },
  {
    "uniprot": "A0A044S8H6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8H6"
  },
  {
    "uniprot": "A0A044T8U8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8U8"
  },
  {
    "uniprot": "A0A044R3W2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3W2"
  },
  {
    "uniprot": "A0A044TG42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TG42"
  },
  {
    "uniprot": "A0A044SWJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWJ1"
  },
  {
    "uniprot": "A0A044UBK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBK0"
  },
  {
    "uniprot": "A0A044UDU2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDU2"
  },
  {
    "uniprot": "A0A2K6WJM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJM4"
  },
  {
    "uniprot": "A0A044TX34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TX34"
  },
  {
    "uniprot": "A0A044SFC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFC8"
  },
  {
    "uniprot": "A0A044TBR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBR3"
  },
  {
    "uniprot": "A0A2K6WKX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKX7"
  },
  {
    "uniprot": "A0A044QJZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJZ5"
  },
  {
    "uniprot": "A0A044TK42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK42"
  },
  {
    "uniprot": "A0A044RQR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQR6"
  },
  {
    "uniprot": "A0A2K6WJJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJJ4"
  },
  {
    "uniprot": "A0A044SE81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SE81"
  },
  {
    "uniprot": "A0A2K6WAD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAD7"
  },
  {
    "uniprot": "A0A2K6WAV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAV2"
  },
  {
    "uniprot": "A0A044UH11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UH11"
  },
  {
    "uniprot": "A0A044V9K8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9K8"
  },
  {
    "uniprot": "A0A2K6W152",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W152"
  },
  {
    "uniprot": "A0A044TPG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPG7"
  },
  {
    "uniprot": "A0A044RKR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKR8"
  },
  {
    "uniprot": "A0A044SZF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZF0"
  },
  {
    "uniprot": "A0A044SSN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSN3"
  },
  {
    "uniprot": "A0A044V9D1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9D1"
  },
  {
    "uniprot": "A0A044RYB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYB1"
  },
  {
    "uniprot": "A0A158N7Z0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Z0"
  },
  {
    "uniprot": "A0A044QVQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVQ6"
  },
  {
    "uniprot": "A0A2K6VIB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIB3"
  },
  {
    "uniprot": "A0A044SGT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGT7"
  },
  {
    "uniprot": "A0A044SPC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPC9"
  },
  {
    "uniprot": "A0A044RY58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY58"
  },
  {
    "uniprot": "A0A044VJA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJA5"
  },
  {
    "uniprot": "A0A2K6WG66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WG66"
  },
  {
    "uniprot": "A0A044RWF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWF1"
  },
  {
    "uniprot": "A0A2K6WLU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLU9"
  },
  {
    "uniprot": "A0A044V2B0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2B0"
  },
  {
    "uniprot": "A0A044V2L4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2L4"
  },
  {
    "uniprot": "A0A044T4P5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4P5"
  },
  {
    "uniprot": "A0A044R1E0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1E0"
  },
  {
    "uniprot": "A0A044QUN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUN8"
  },
  {
    "uniprot": "A0A044RKF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKF4"
  },
  {
    "uniprot": "A0A044TFH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFH6"
  },
  {
    "uniprot": "A0A044RIZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIZ9"
  },
  {
    "uniprot": "A0A044VDX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDX9"
  },
  {
    "uniprot": "A0A044QR19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QR19"
  },
  {
    "uniprot": "A0A044SE40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SE40"
  },
  {
    "uniprot": "A0A044R058",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R058"
  },
  {
    "uniprot": "A0A044UFR5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFR5"
  },
  {
    "uniprot": "A0A044SNR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNR4"
  },
  {
    "uniprot": "A0A044TTJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTJ3"
  },
  {
    "uniprot": "A0A044TTP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTP1"
  },
  {
    "uniprot": "A0A044U669",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U669"
  },
  {
    "uniprot": "A0A044TN24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN24"
  },
  {
    "uniprot": "A0A044RZS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZS0"
  },
  {
    "uniprot": "A0A044QXN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXN9"
  },
  {
    "uniprot": "A0A2K6WGI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGI1"
  },
  {
    "uniprot": "A0A044TLZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLZ8"
  },
  {
    "uniprot": "A0A044RP47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RP47"
  },
  {
    "uniprot": "A0A044UR33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UR33"
  },
  {
    "uniprot": "A0A2K6W1I1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1I1"
  },
  {
    "uniprot": "A0A044QZ64",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ64"
  },
  {
    "uniprot": "A0A044V0R1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0R1"
  },
  {
    "uniprot": "A0A044UTQ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTQ2"
  },
  {
    "uniprot": "A0A2K6WB76",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WB76"
  },
  {
    "uniprot": "A0A044T4S5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4S5"
  },
  {
    "uniprot": "A0A044U517",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U517"
  },
  {
    "uniprot": "A0A044V4I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4I9"
  },
  {
    "uniprot": "A0A044S7Q8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7Q8"
  },
  {
    "uniprot": "A0A044V316",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V316"
  },
  {
    "uniprot": "A0A044UEX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEX4"
  },
  {
    "uniprot": "A0A2K6VIW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIW7"
  },
  {
    "uniprot": "A0A2K6VLZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLZ6"
  },
  {
    "uniprot": "A0A044V4C6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4C6"
  },
  {
    "uniprot": "A0A044UWJ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWJ8"
  },
  {
    "uniprot": "A0A044SVC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVC6"
  },
  {
    "uniprot": "A0A044VC91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC91"
  },
  {
    "uniprot": "A0A2K6WF68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WF68"
  },
  {
    "uniprot": "A0A044TM87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TM87"
  },
  {
    "uniprot": "A0A044SGB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGB4"
  },
  {
    "uniprot": "A0A044TM45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TM45"
  },
  {
    "uniprot": "A0A044QUC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUC6"
  },
  {
    "uniprot": "A0A044S5G2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5G2"
  },
  {
    "uniprot": "A0A044R430",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R430"
  },
  {
    "uniprot": "A0A044VHR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHR6"
  },
  {
    "uniprot": "A0A044UAM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAM5"
  },
  {
    "uniprot": "A0A044V9E2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9E2"
  },
  {
    "uniprot": "A0A044RK28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RK28"
  },
  {
    "uniprot": "A0A044SHJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHJ7"
  },
  {
    "uniprot": "A0A044SPQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPQ6"
  },
  {
    "uniprot": "A0A044RX86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RX86"
  },
  {
    "uniprot": "A0A044UC90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC90"
  },
  {
    "uniprot": "A0A044RGT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGT7"
  },
  {
    "uniprot": "A0A044SKX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKX4"
  },
  {
    "uniprot": "A0A044UVS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVS7"
  },
  {
    "uniprot": "A0A044V347",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V347"
  },
  {
    "uniprot": "A0A044TMT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMT6"
  },
  {
    "uniprot": "A0A2K6VHC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHC2"
  },
  {
    "uniprot": "A0A044R9C9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9C9"
  },
  {
    "uniprot": "A0A044V1T2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1T2"
  },
  {
    "uniprot": "A0A044RE58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RE58"
  },
  {
    "uniprot": "A0A044TXN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXN6"
  },
  {
    "uniprot": "A0A044SMT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMT2"
  },
  {
    "uniprot": "A0A044R9M3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9M3"
  },
  {
    "uniprot": "A0A044UK13",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UK13"
  },
  {
    "uniprot": "A0A044RL87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RL87"
  },
  {
    "uniprot": "A0A044T1S4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1S4"
  },
  {
    "uniprot": "A0A044VG32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VG32"
  },
  {
    "uniprot": "A0A044QSQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSQ0"
  },
  {
    "uniprot": "A0A044UR39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UR39"
  },
  {
    "uniprot": "A0A044U2Q8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2Q8"
  },
  {
    "uniprot": "A0A044STT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STT5"
  },
  {
    "uniprot": "A0A044ULG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULG8"
  },
  {
    "uniprot": "A0A044UWQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWQ0"
  },
  {
    "uniprot": "A0A044RKX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKX4"
  },
  {
    "uniprot": "A0A044R3I2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3I2"
  },
  {
    "uniprot": "A0A044TVA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVA9"
  },
  {
    "uniprot": "A0A044V9F7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9F7"
  },
  {
    "uniprot": "A0A044U2K3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2K3"
  },
  {
    "uniprot": "A0A044V2G2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2G2"
  },
  {
    "uniprot": "A0A2K6VI24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VI24"
  },
  {
    "uniprot": "A0A044S582",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S582"
  },
  {
    "uniprot": "A0A2K6VFD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFD7"
  },
  {
    "uniprot": "A0A044S6T2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6T2"
  },
  {
    "uniprot": "A0A044UKH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKH5"
  },
  {
    "uniprot": "A0A044QKA2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKA2"
  },
  {
    "uniprot": "A0A044RGB2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGB2"
  },
  {
    "uniprot": "A0A044SUD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUD3"
  },
  {
    "uniprot": "A0A044QM00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QM00"
  },
  {
    "uniprot": "A0A044RTJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTJ7"
  },
  {
    "uniprot": "A0A044R6E3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6E3"
  },
  {
    "uniprot": "A0A044RY36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY36"
  },
  {
    "uniprot": "A0A044VEF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEF0"
  },
  {
    "uniprot": "A0A2K6VEJ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VEJ2"
  },
  {
    "uniprot": "A0A2K6VV45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VV45"
  },
  {
    "uniprot": "A0A044R7H0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7H0"
  },
  {
    "uniprot": "A0A044U3B5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3B5"
  },
  {
    "uniprot": "A0A044TI82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TI82"
  },
  {
    "uniprot": "A0A044SM05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM05"
  },
  {
    "uniprot": "A0A044V3L1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3L1"
  },
  {
    "uniprot": "A0A044V4A3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4A3"
  },
  {
    "uniprot": "A0A2K6VLC7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLC7"
  },
  {
    "uniprot": "A0A044QZ38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ38"
  },
  {
    "uniprot": "A0A044UT04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT04"
  },
  {
    "uniprot": "A0A2K6VKE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKE6"
  },
  {
    "uniprot": "A0A044QQT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQT2"
  },
  {
    "uniprot": "A0A044R0H2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0H2"
  },
  {
    "uniprot": "A0A044VDT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDT1"
  },
  {
    "uniprot": "A0A044V036",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V036"
  },
  {
    "uniprot": "A0A2K6W9B2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9B2"
  },
  {
    "uniprot": "A0A2K6WBI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBI8"
  },
  {
    "uniprot": "A0A044TX60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TX60"
  },
  {
    "uniprot": "A0A044T2T7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2T7"
  },
  {
    "uniprot": "A0A044TTM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTM3"
  },
  {
    "uniprot": "A0A044SH69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SH69"
  },
  {
    "uniprot": "A0A044TBW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBW9"
  },
  {
    "uniprot": "A0A044SBI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBI8"
  },
  {
    "uniprot": "A0A044TXU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXU4"
  },
  {
    "uniprot": "A0A044TG25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TG25"
  },
  {
    "uniprot": "A0A044S6Z5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6Z5"
  },
  {
    "uniprot": "A0A044R940",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R940"
  },
  {
    "uniprot": "A0A044TUS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUS3"
  },
  {
    "uniprot": "A0A044T7H3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7H3"
  },
  {
    "uniprot": "A0A2K6WJ71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJ71"
  },
  {
    "uniprot": "A0A044TRU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRU1"
  },
  {
    "uniprot": "A0A044S1G5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1G5"
  },
  {
    "uniprot": "A0A044TGS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGS7"
  },
  {
    "uniprot": "A0A044UAP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAP2"
  },
  {
    "uniprot": "A0A044UQ37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQ37"
  },
  {
    "uniprot": "A0A044R346",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R346"
  },
  {
    "uniprot": "A0A044TUN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUN2"
  },
  {
    "uniprot": "A0A044U2I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2I9"
  },
  {
    "uniprot": "A0A044RDP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDP7"
  },
  {
    "uniprot": "A0A044UCB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCB0"
  },
  {
    "uniprot": "A0A044QXX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXX6"
  },
  {
    "uniprot": "A0A044V729",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V729"
  },
  {
    "uniprot": "A0A044RAF9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAF9"
  },
  {
    "uniprot": "A0A044UA97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA97"
  },
  {
    "uniprot": "A0A2K6VHI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHI9"
  },
  {
    "uniprot": "A0A044SDU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDU9"
  },
  {
    "uniprot": "A0A044RUV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUV7"
  },
  {
    "uniprot": "A0A2K6VY29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VY29"
  },
  {
    "uniprot": "A0A044V5K0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5K0"
  },
  {
    "uniprot": "A0A044V8W6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8W6"
  },
  {
    "uniprot": "A0A044RP22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RP22"
  },
  {
    "uniprot": "A0A044TBT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBT1"
  },
  {
    "uniprot": "A0A044U5M2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5M2"
  },
  {
    "uniprot": "A0A044RRS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRS4"
  },
  {
    "uniprot": "A0A044R6Z6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6Z6"
  },
  {
    "uniprot": "A0A044UDW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDW0"
  },
  {
    "uniprot": "A0A044UBS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBS2"
  },
  {
    "uniprot": "A0A044VJ69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJ69"
  },
  {
    "uniprot": "A0A044SD12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD12"
  },
  {
    "uniprot": "A0A044RZW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZW5"
  },
  {
    "uniprot": "A0A2K6VWX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWX7"
  },
  {
    "uniprot": "A0A044UZ50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ50"
  },
  {
    "uniprot": "A0A044V701",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V701"
  },
  {
    "uniprot": "A0A2K6WL35",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WL35"
  },
  {
    "uniprot": "A0A044S9E1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9E1"
  },
  {
    "uniprot": "A0A2K6VE84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VE84"
  },
  {
    "uniprot": "A0A044UXI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXI4"
  },
  {
    "uniprot": "A0A044T3Q7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3Q7"
  },
  {
    "uniprot": "A0A044RGZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGZ5"
  },
  {
    "uniprot": "A0A044U5P1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5P1"
  },
  {
    "uniprot": "A0A044TFL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFL0"
  },
  {
    "uniprot": "P21250",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P21250"
  },
  {
    "uniprot": "A0A044T9V2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9V2"
  },
  {
    "uniprot": "A0A2K6WIR9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIR9"
  },
  {
    "uniprot": "A0A044V125",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V125"
  },
  {
    "uniprot": "A0A044R2X5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2X5"
  },
  {
    "uniprot": "A0A044TRD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRD3"
  },
  {
    "uniprot": "A0A158N842",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N842"
  },
  {
    "uniprot": "A0A044VH55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH55"
  },
  {
    "uniprot": "A0A044SMZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMZ1"
  },
  {
    "uniprot": "A0A2K6WDP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDP5"
  },
  {
    "uniprot": "A0A044RSB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSB3"
  },
  {
    "uniprot": "A0A044T6K3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6K3"
  },
  {
    "uniprot": "A0A044S276",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S276"
  },
  {
    "uniprot": "A0A2K6VR29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VR29"
  },
  {
    "uniprot": "A0A044ULD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULD7"
  },
  {
    "uniprot": "A0A044R3S8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3S8"
  },
  {
    "uniprot": "A0A158N7P3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7P3"
  },
  {
    "uniprot": "A0A044RVN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVN5"
  },
  {
    "uniprot": "A0A2K6WCS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WCS3"
  },
  {
    "uniprot": "A0A044SB60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB60"
  },
  {
    "uniprot": "A0A2K6VRA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRA7"
  },
  {
    "uniprot": "A0A044V2F2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2F2"
  },
  {
    "uniprot": "A0A2K6WIT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIT9"
  },
  {
    "uniprot": "A0A044UHL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHL6"
  },
  {
    "uniprot": "A0A044TLA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLA5"
  },
  {
    "uniprot": "A0A044RDR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDR2"
  },
  {
    "uniprot": "A0A044V5I4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5I4"
  },
  {
    "uniprot": "A0A044R5S2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5S2"
  },
  {
    "uniprot": "A0A044S4N9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4N9"
  },
  {
    "uniprot": "A0A044V261",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V261"
  },
  {
    "uniprot": "A0A044R383",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R383"
  },
  {
    "uniprot": "A0A044S3Q3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3Q3"
  },
  {
    "uniprot": "A0A044UVP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVP7"
  },
  {
    "uniprot": "A0A044VEM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEM4"
  },
  {
    "uniprot": "A0A044UCC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCC4"
  },
  {
    "uniprot": "A0A044S3F6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3F6"
  },
  {
    "uniprot": "A0A2K6W0V6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0V6"
  },
  {
    "uniprot": "A0A044V573",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V573"
  },
  {
    "uniprot": "A0A044SEG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEG2"
  },
  {
    "uniprot": "A0A044UFH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFH3"
  },
  {
    "uniprot": "A0A044T7A1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7A1"
  },
  {
    "uniprot": "A0A044QQ23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQ23"
  },
  {
    "uniprot": "A0A044U723",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U723"
  },
  {
    "uniprot": "A0A044RSI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSI9"
  },
  {
    "uniprot": "A0A2K6W0E4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0E4"
  },
  {
    "uniprot": "A0A044QP20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP20"
  },
  {
    "uniprot": "A0A2K6WN74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WN74"
  },
  {
    "uniprot": "A0A044V8S6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8S6"
  },
  {
    "uniprot": "A0A044T0U8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0U8"
  },
  {
    "uniprot": "A0A044UJT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJT5"
  },
  {
    "uniprot": "A0A044SZ88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ88"
  },
  {
    "uniprot": "A0A044UW40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UW40"
  },
  {
    "uniprot": "A0A044TIW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIW9"
  },
  {
    "uniprot": "A0A044S421",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S421"
  },
  {
    "uniprot": "A0A044UMU2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMU2"
  },
  {
    "uniprot": "A0A044U6H2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6H2"
  },
  {
    "uniprot": "A0A2K6VU89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VU89"
  },
  {
    "uniprot": "A0A044V992",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V992"
  },
  {
    "uniprot": "B3VT30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/B3VT30"
  },
  {
    "uniprot": "A0A044V9S9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9S9"
  },
  {
    "uniprot": "A0A044UL16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL16"
  },
  {
    "uniprot": "A0A044RCJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCJ4"
  },
  {
    "uniprot": "A0A044UNY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNY8"
  },
  {
    "uniprot": "A0A044QUJ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUJ8"
  },
  {
    "uniprot": "A0A044SRP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRP9"
  },
  {
    "uniprot": "A0A044U6N7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6N7"
  },
  {
    "uniprot": "A0A044RPP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPP8"
  },
  {
    "uniprot": "A0A044QSR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSR8"
  },
  {
    "uniprot": "A0A044R525",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R525"
  },
  {
    "uniprot": "A0A044T3I2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3I2"
  },
  {
    "uniprot": "A0A044RRB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRB6"
  },
  {
    "uniprot": "A0A044S9M2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9M2"
  },
  {
    "uniprot": "A0A044THS1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THS1"
  },
  {
    "uniprot": "A0A044VHE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHE9"
  },
  {
    "uniprot": "A0A044U8T8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8T8"
  },
  {
    "uniprot": "A0A044RI93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RI93"
  },
  {
    "uniprot": "A0A044RI70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RI70"
  },
  {
    "uniprot": "A0A044QQB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQB1"
  },
  {
    "uniprot": "A0A044QYV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYV8"
  },
  {
    "uniprot": "A0A044QZN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZN3"
  },
  {
    "uniprot": "A0A044V438",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V438"
  },
  {
    "uniprot": "A0A044RFK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFK3"
  },
  {
    "uniprot": "A0A044TY94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TY94"
  },
  {
    "uniprot": "A0A044T800",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T800"
  },
  {
    "uniprot": "A0A2K6W3X2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3X2"
  },
  {
    "uniprot": "A0A044R0T9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0T9"
  },
  {
    "uniprot": "A0A044TR15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR15"
  },
  {
    "uniprot": "A0A044UNM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNM1"
  },
  {
    "uniprot": "A0A044RCI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCI6"
  },
  {
    "uniprot": "A0A044R709",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R709"
  },
  {
    "uniprot": "A0A044V0U8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0U8"
  },
  {
    "uniprot": "A0A044S2Y7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2Y7"
  },
  {
    "uniprot": "A0A2K6VSJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSJ9"
  },
  {
    "uniprot": "A0A044RMC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMC6"
  },
  {
    "uniprot": "A0A044S506",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S506"
  },
  {
    "uniprot": "A0A044RZC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZC6"
  },
  {
    "uniprot": "A0A044V9N1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9N1"
  },
  {
    "uniprot": "A0A044U852",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U852"
  },
  {
    "uniprot": "A0A044RMC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMC3"
  },
  {
    "uniprot": "A0A044V9H1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9H1"
  },
  {
    "uniprot": "A0A044UCT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCT9"
  },
  {
    "uniprot": "A0A044V6Z7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Z7"
  },
  {
    "uniprot": "A0A044SXL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXL9"
  },
  {
    "uniprot": "A0A044U9K6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9K6"
  },
  {
    "uniprot": "A0A044R0J9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0J9"
  },
  {
    "uniprot": "A0A044TTG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTG1"
  },
  {
    "uniprot": "A0A044QSS1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSS1"
  },
  {
    "uniprot": "A0A044SW55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SW55"
  },
  {
    "uniprot": "A0A044TDQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDQ3"
  },
  {
    "uniprot": "A0A044SE75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SE75"
  },
  {
    "uniprot": "A0A044TSZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSZ0"
  },
  {
    "uniprot": "A0A044V298",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V298"
  },
  {
    "uniprot": "A0A044RNE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNE9"
  },
  {
    "uniprot": "P54188",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P54188"
  },
  {
    "uniprot": "A0A158N831",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N831"
  },
  {
    "uniprot": "A0A044TYT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYT5"
  },
  {
    "uniprot": "A0A044UTK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTK0"
  },
  {
    "uniprot": "A0A044SCX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCX1"
  },
  {
    "uniprot": "A0A2K6VLY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLY3"
  },
  {
    "uniprot": "A0A044UYZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYZ2"
  },
  {
    "uniprot": "A0A044RHE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHE5"
  },
  {
    "uniprot": "A0A044TI05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TI05"
  },
  {
    "uniprot": "A0A2K6WF90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WF90"
  },
  {
    "uniprot": "A0A044UNJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNJ3"
  },
  {
    "uniprot": "A0A044SGP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGP3"
  },
  {
    "uniprot": "A0A044U5V1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5V1"
  },
  {
    "uniprot": "A0A044V942",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V942"
  },
  {
    "uniprot": "A0A044SHE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHE5"
  },
  {
    "uniprot": "A0A044SM81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM81"
  },
  {
    "uniprot": "A0A044UTN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTN4"
  },
  {
    "uniprot": "A0A044S7P2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7P2"
  },
  {
    "uniprot": "A0A044QMR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMR6"
  },
  {
    "uniprot": "A0A044V4G2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4G2"
  },
  {
    "uniprot": "A0A2K6VYX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYX8"
  },
  {
    "uniprot": "A0A044TPM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPM4"
  },
  {
    "uniprot": "A0A044UJX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJX8"
  },
  {
    "uniprot": "A0A044SIE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIE0"
  },
  {
    "uniprot": "A0A044R287",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R287"
  },
  {
    "uniprot": "A0A044QSK7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSK7"
  },
  {
    "uniprot": "A0A044UBG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBG6"
  },
  {
    "uniprot": "A0A2K6VTJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VTJ1"
  },
  {
    "uniprot": "A0A044SS06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS06"
  },
  {
    "uniprot": "A0A044URR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URR2"
  },
  {
    "uniprot": "A0A044V715",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V715"
  },
  {
    "uniprot": "A0A2K6VUI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUI0"
  },
  {
    "uniprot": "A0A044SV22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SV22"
  },
  {
    "uniprot": "A0A044QVN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVN5"
  },
  {
    "uniprot": "A0A044RI31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RI31"
  },
  {
    "uniprot": "A0A044VJ53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJ53"
  },
  {
    "uniprot": "A0A044VFB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFB9"
  },
  {
    "uniprot": "A0A044TYB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYB7"
  },
  {
    "uniprot": "A0A158N838",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N838"
  },
  {
    "uniprot": "A0A2K6VN03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VN03"
  },
  {
    "uniprot": "A0A2K6VRG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRG6"
  },
  {
    "uniprot": "A0A044QUS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUS9"
  },
  {
    "uniprot": "A0A044VA30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA30"
  },
  {
    "uniprot": "A0A044RX92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RX92"
  },
  {
    "uniprot": "A0A044RNV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNV4"
  },
  {
    "uniprot": "A0A2K6WAE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAE7"
  },
  {
    "uniprot": "A0A044U2C5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2C5"
  },
  {
    "uniprot": "A0A044UZ95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ95"
  },
  {
    "uniprot": "A0A044TTC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTC4"
  },
  {
    "uniprot": "A0A2K6W1P9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1P9"
  },
  {
    "uniprot": "A0A044QQ10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQ10"
  },
  {
    "uniprot": "A0A044TV48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV48"
  },
  {
    "uniprot": "A0A044TPA2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPA2"
  },
  {
    "uniprot": "A0A044R0V0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0V0"
  },
  {
    "uniprot": "A0A044SGP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGP8"
  },
  {
    "uniprot": "A0A044VJF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJF0"
  },
  {
    "uniprot": "A0A044SNZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNZ8"
  },
  {
    "uniprot": "A0A158N7W0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7W0"
  },
  {
    "uniprot": "A0A2K6VLK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLK3"
  },
  {
    "uniprot": "A0A158N868",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N868"
  },
  {
    "uniprot": "A0A2K6W034",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W034"
  },
  {
    "uniprot": "A0A044SP40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SP40"
  },
  {
    "uniprot": "A0A2K6WKP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKP6"
  },
  {
    "uniprot": "A0A044SS39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS39"
  },
  {
    "uniprot": "A0A044UD25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UD25"
  },
  {
    "uniprot": "A0A044RRK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRK0"
  },
  {
    "uniprot": "A0A044TE24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TE24"
  },
  {
    "uniprot": "A0A044SNQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNQ4"
  },
  {
    "uniprot": "A0A044SQQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQQ5"
  },
  {
    "uniprot": "A0A044SI51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI51"
  },
  {
    "uniprot": "A0A044T5G5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5G5"
  },
  {
    "uniprot": "A0A044RAI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAI7"
  },
  {
    "uniprot": "A0A044UCU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCU3"
  },
  {
    "uniprot": "A0A2K6W0F3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0F3"
  },
  {
    "uniprot": "A0A044T9X4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9X4"
  },
  {
    "uniprot": "A0A044UQ16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQ16"
  },
  {
    "uniprot": "A0A044TIX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIX2"
  },
  {
    "uniprot": "A0A044U8K9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8K9"
  },
  {
    "uniprot": "A0A044UWP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWP9"
  },
  {
    "uniprot": "A0A044RDD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDD7"
  },
  {
    "uniprot": "A0A044TC34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC34"
  },
  {
    "uniprot": "A0A2K6VIQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIQ1"
  },
  {
    "uniprot": "A0A044S8N4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8N4"
  },
  {
    "uniprot": "A0A044VFT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFT7"
  },
  {
    "uniprot": "A0A044V4C2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4C2"
  },
  {
    "uniprot": "A0A044TAM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAM3"
  },
  {
    "uniprot": "A0A044RCH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCH5"
  },
  {
    "uniprot": "A0A044TK16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK16"
  },
  {
    "uniprot": "A0A044UKJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKJ7"
  },
  {
    "uniprot": "A0A044T2A6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2A6"
  },
  {
    "uniprot": "A0A044T699",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T699"
  },
  {
    "uniprot": "A0A044T456",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T456"
  },
  {
    "uniprot": "A0A044TTH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTH5"
  },
  {
    "uniprot": "A0A044TJE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJE2"
  },
  {
    "uniprot": "A0A044U4A3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4A3"
  },
  {
    "uniprot": "A0A2K6VWF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWF3"
  },
  {
    "uniprot": "A0A044QQP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQP5"
  },
  {
    "uniprot": "A0A044V963",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V963"
  },
  {
    "uniprot": "A0A044VG49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VG49"
  },
  {
    "uniprot": "A0A044VIJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIJ9"
  },
  {
    "uniprot": "A0A044V9V0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9V0"
  },
  {
    "uniprot": "A0A044R6R5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6R5"
  },
  {
    "uniprot": "A0A044V9H4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9H4"
  },
  {
    "uniprot": "A0A044QLH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLH4"
  },
  {
    "uniprot": "A0A044UC42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC42"
  },
  {
    "uniprot": "A0A044UY02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UY02"
  },
  {
    "uniprot": "A0A044TZS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZS9"
  },
  {
    "uniprot": "A0A044VHD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHD1"
  },
  {
    "uniprot": "A0A044S0A1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0A1"
  },
  {
    "uniprot": "A0A044SCB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCB7"
  },
  {
    "uniprot": "A0A044SLY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLY4"
  },
  {
    "uniprot": "A0A044QV33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QV33"
  },
  {
    "uniprot": "A0A044U7A0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7A0"
  },
  {
    "uniprot": "A0A044S5T3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5T3"
  },
  {
    "uniprot": "A0A044SN82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN82"
  },
  {
    "uniprot": "A0A044TH88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH88"
  },
  {
    "uniprot": "A0A044UDM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDM8"
  },
  {
    "uniprot": "A0A044U2E6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2E6"
  },
  {
    "uniprot": "A0A044RAN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAN3"
  },
  {
    "uniprot": "A0A2K6VHC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHC1"
  },
  {
    "uniprot": "A0A2K6VG54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VG54"
  },
  {
    "uniprot": "A0A044RY06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY06"
  },
  {
    "uniprot": "A0A044TN99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN99"
  },
  {
    "uniprot": "A0A044TM39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TM39"
  },
  {
    "uniprot": "A0A044VHG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHG9"
  },
  {
    "uniprot": "A0A044UYZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYZ1"
  },
  {
    "uniprot": "A0A044RPI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPI9"
  },
  {
    "uniprot": "A0A044RRV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRV2"
  },
  {
    "uniprot": "A0A044UZ14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ14"
  },
  {
    "uniprot": "A0A2K6VGR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VGR8"
  },
  {
    "uniprot": "A0A044TCF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCF3"
  },
  {
    "uniprot": "A0A044UKC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKC4"
  },
  {
    "uniprot": "A0A044RGJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGJ9"
  },
  {
    "uniprot": "A0A044SDN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDN3"
  },
  {
    "uniprot": "A0A2K6VS75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VS75"
  },
  {
    "uniprot": "A0A044UF50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF50"
  },
  {
    "uniprot": "A0A044RRX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRX1"
  },
  {
    "uniprot": "A0A044TEN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEN8"
  },
  {
    "uniprot": "A0A044SHJ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHJ5"
  },
  {
    "uniprot": "A0A044RG63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RG63"
  },
  {
    "uniprot": "A0A2K6WCC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WCC2"
  },
  {
    "uniprot": "A0A044RUE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUE1"
  },
  {
    "uniprot": "A0A044TM76",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TM76"
  },
  {
    "uniprot": "A0A044TRU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRU9"
  },
  {
    "uniprot": "A0A044TA29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TA29"
  },
  {
    "uniprot": "A0A044RV21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RV21"
  },
  {
    "uniprot": "A0A044U8K3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8K3"
  },
  {
    "uniprot": "A0A044T4W4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4W4"
  },
  {
    "uniprot": "A0A044SWA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWA6"
  },
  {
    "uniprot": "A0A044S669",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S669"
  },
  {
    "uniprot": "A0A158N852",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N852"
  },
  {
    "uniprot": "A0A044S0E3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0E3"
  },
  {
    "uniprot": "A0A044V770",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V770"
  },
  {
    "uniprot": "A0A044S1M8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1M8"
  },
  {
    "uniprot": "A0A044S8B8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8B8"
  },
  {
    "uniprot": "A0A044V2D3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2D3"
  },
  {
    "uniprot": "A0A044T518",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T518"
  },
  {
    "uniprot": "A0A044UFK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFK6"
  },
  {
    "uniprot": "A0A044QM12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QM12"
  },
  {
    "uniprot": "A0A044S7A0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7A0"
  },
  {
    "uniprot": "A0A2K6VW73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VW73"
  },
  {
    "uniprot": "A0A044UHH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHH6"
  },
  {
    "uniprot": "A0A2K6VEL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VEL0"
  },
  {
    "uniprot": "A0A044VD29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD29"
  },
  {
    "uniprot": "A0A2K6WC83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WC83"
  },
  {
    "uniprot": "A0A044V8I6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8I6"
  },
  {
    "uniprot": "A0A044RLJ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLJ5"
  },
  {
    "uniprot": "A0A044TTM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTM6"
  },
  {
    "uniprot": "A0A044U2S2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2S2"
  },
  {
    "uniprot": "A0A044UVK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVK0"
  },
  {
    "uniprot": "A0A044TRG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRG6"
  },
  {
    "uniprot": "A0A044SLD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLD6"
  },
  {
    "uniprot": "A0A044RCR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCR1"
  },
  {
    "uniprot": "A0A044RCK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCK4"
  },
  {
    "uniprot": "A0A044UEQ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEQ2"
  },
  {
    "uniprot": "A0A044RGU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGU5"
  },
  {
    "uniprot": "A0A044QQG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQG7"
  },
  {
    "uniprot": "A0A044U9V0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9V0"
  },
  {
    "uniprot": "A0A044R436",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R436"
  },
  {
    "uniprot": "A0A044REE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REE6"
  },
  {
    "uniprot": "A0A044U9S3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9S3"
  },
  {
    "uniprot": "A0A2K6VDT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDT1"
  },
  {
    "uniprot": "A0A044TWI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWI1"
  },
  {
    "uniprot": "A0A044U5H0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5H0"
  },
  {
    "uniprot": "A0A044TEC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEC4"
  },
  {
    "uniprot": "A0A044QT78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT78"
  },
  {
    "uniprot": "A0A044RZP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZP1"
  },
  {
    "uniprot": "A0A044RU13",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU13"
  },
  {
    "uniprot": "A0A044V9I5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9I5"
  },
  {
    "uniprot": "A0A044RZI5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZI5"
  },
  {
    "uniprot": "A0A044VC48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC48"
  },
  {
    "uniprot": "A0A2K6WA57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WA57"
  },
  {
    "uniprot": "A0A044UUI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUI1"
  },
  {
    "uniprot": "A0A2K6VIK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIK8"
  },
  {
    "uniprot": "A0A044T5Y8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5Y8"
  },
  {
    "uniprot": "A0A044TUX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUX7"
  },
  {
    "uniprot": "A0A044R773",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R773"
  },
  {
    "uniprot": "A0A044RMX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMX3"
  },
  {
    "uniprot": "A0A044U5G1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5G1"
  },
  {
    "uniprot": "A0A2K6VWJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWJ6"
  },
  {
    "uniprot": "A0A044V6Z5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Z5"
  },
  {
    "uniprot": "A0A044RES8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RES8"
  },
  {
    "uniprot": "A0A044V9I6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9I6"
  },
  {
    "uniprot": "A0A044V414",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V414"
  },
  {
    "uniprot": "A0A2K6WDB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDB0"
  },
  {
    "uniprot": "A0A044TK23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK23"
  },
  {
    "uniprot": "A0A2K6VQR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQR1"
  },
  {
    "uniprot": "A0A044S566",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S566"
  },
  {
    "uniprot": "A0A044V155",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V155"
  },
  {
    "uniprot": "A0A044R1P3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1P3"
  },
  {
    "uniprot": "A0A044T7R2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7R2"
  },
  {
    "uniprot": "A0A2K6VK86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VK86"
  },
  {
    "uniprot": "A0A044SHN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHN3"
  },
  {
    "uniprot": "A0A044TBI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBI3"
  },
  {
    "uniprot": "A0A044UNI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNI9"
  },
  {
    "uniprot": "A0A044SUV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUV3"
  },
  {
    "uniprot": "A0A044QQA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQA4"
  },
  {
    "uniprot": "A0A044TNT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNT0"
  },
  {
    "uniprot": "A0A044RKP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKP0"
  },
  {
    "uniprot": "A0A044S6A7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6A7"
  },
  {
    "uniprot": "A0A044U4Z9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4Z9"
  },
  {
    "uniprot": "A0A044SYC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYC9"
  },
  {
    "uniprot": "A0A2K6W1J5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1J5"
  },
  {
    "uniprot": "A0A044SCI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCI1"
  },
  {
    "uniprot": "A0A2K6W009",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W009"
  },
  {
    "uniprot": "A0A044SHS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHS3"
  },
  {
    "uniprot": "A0A044TBQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBQ3"
  },
  {
    "uniprot": "A0A044SAQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAQ1"
  },
  {
    "uniprot": "A0A044U836",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U836"
  },
  {
    "uniprot": "A0A044VBN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBN8"
  },
  {
    "uniprot": "A0A044SN57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN57"
  },
  {
    "uniprot": "A0A044V5K4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5K4"
  },
  {
    "uniprot": "A0A044S346",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S346"
  },
  {
    "uniprot": "A0A044SEF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEF6"
  },
  {
    "uniprot": "A0A044RP81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RP81"
  },
  {
    "uniprot": "A0A044TJQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJQ9"
  },
  {
    "uniprot": "A0A2K6VLX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLX1"
  },
  {
    "uniprot": "A0A044UKZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKZ0"
  },
  {
    "uniprot": "A0A044SPW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPW7"
  },
  {
    "uniprot": "A0A044UZ58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ58"
  },
  {
    "uniprot": "A0A044V8X7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8X7"
  },
  {
    "uniprot": "A0A044V0R5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0R5"
  },
  {
    "uniprot": "A0A044U2L4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2L4"
  },
  {
    "uniprot": "A0A044UKQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKQ0"
  },
  {
    "uniprot": "A0A044TWD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWD7"
  },
  {
    "uniprot": "A0A044TKL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKL9"
  },
  {
    "uniprot": "A0A044UL26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL26"
  },
  {
    "uniprot": "A0A044UA77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA77"
  },
  {
    "uniprot": "A0A2K6VJZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJZ9"
  },
  {
    "uniprot": "A0A044UT23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT23"
  },
  {
    "uniprot": "A0A044R5Q7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5Q7"
  },
  {
    "uniprot": "A0A044R3H5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3H5"
  },
  {
    "uniprot": "A0A044UT94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT94"
  },
  {
    "uniprot": "A0A044R4N1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4N1"
  },
  {
    "uniprot": "A0A044TH49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH49"
  },
  {
    "uniprot": "A0A044TQV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQV4"
  },
  {
    "uniprot": "A0A044QPK7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPK7"
  },
  {
    "uniprot": "A0A044U6T4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6T4"
  },
  {
    "uniprot": "A0A044V700",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V700"
  },
  {
    "uniprot": "A0A044RIX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIX1"
  },
  {
    "uniprot": "A0A044VF75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VF75"
  },
  {
    "uniprot": "A0A2K6VZM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZM4"
  },
  {
    "uniprot": "A0A044S2C9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2C9"
  },
  {
    "uniprot": "A0A044SHF7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHF7"
  },
  {
    "uniprot": "A0A044T7N5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7N5"
  },
  {
    "uniprot": "A0A044TSX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSX5"
  },
  {
    "uniprot": "A0A044RKB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKB7"
  },
  {
    "uniprot": "A0A044SMM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMM6"
  },
  {
    "uniprot": "A0A044RCM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCM4"
  },
  {
    "uniprot": "A0A044T381",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T381"
  },
  {
    "uniprot": "A0A044S7I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7I9"
  },
  {
    "uniprot": "A0A044ULA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULA0"
  },
  {
    "uniprot": "A0A044SV73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SV73"
  },
  {
    "uniprot": "A0A044TNS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNS2"
  },
  {
    "uniprot": "A0A044SP79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SP79"
  },
  {
    "uniprot": "A0A044SJP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJP6"
  },
  {
    "uniprot": "A0A2K6VJI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJI1"
  },
  {
    "uniprot": "A0A044SMC7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMC7"
  },
  {
    "uniprot": "A0A044ULQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULQ4"
  },
  {
    "uniprot": "A0A044UB42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UB42"
  },
  {
    "uniprot": "A0A044V7M4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7M4"
  },
  {
    "uniprot": "A0A2K6WMV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMV0"
  },
  {
    "uniprot": "A0A044RLG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLG9"
  },
  {
    "uniprot": "A0A044QYV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYV4"
  },
  {
    "uniprot": "A0A044UMS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMS0"
  },
  {
    "uniprot": "A0A2K6W0J7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0J7"
  },
  {
    "uniprot": "A0A044UVZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVZ1"
  },
  {
    "uniprot": "A0A044V937",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V937"
  },
  {
    "uniprot": "A0A158N820",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N820"
  },
  {
    "uniprot": "A0A044R866",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R866"
  },
  {
    "uniprot": "A0A044UQ03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQ03"
  },
  {
    "uniprot": "A0A044RFS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFS2"
  },
  {
    "uniprot": "A0A044T729",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T729"
  },
  {
    "uniprot": "A0A2K6WN85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WN85"
  },
  {
    "uniprot": "A0A044RCI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCI9"
  },
  {
    "uniprot": "A0A044T635",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T635"
  },
  {
    "uniprot": "A0A044RWQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWQ7"
  },
  {
    "uniprot": "A0A044UN60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UN60"
  },
  {
    "uniprot": "A0A044V429",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V429"
  },
  {
    "uniprot": "A0A044STL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STL4"
  },
  {
    "uniprot": "A0A044TS42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS42"
  },
  {
    "uniprot": "A0A044TXW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXW9"
  },
  {
    "uniprot": "A0A044VDV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDV4"
  },
  {
    "uniprot": "A0A044U355",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U355"
  },
  {
    "uniprot": "A0A044V742",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V742"
  },
  {
    "uniprot": "A0A044V0U1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0U1"
  },
  {
    "uniprot": "A0A044SPH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPH5"
  },
  {
    "uniprot": "A0A2K6W2N0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2N0"
  },
  {
    "uniprot": "A0A044SWU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWU7"
  },
  {
    "uniprot": "A0A044SGM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGM6"
  },
  {
    "uniprot": "A0A044VAA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAA1"
  },
  {
    "uniprot": "A0A044VBF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBF5"
  },
  {
    "uniprot": "A0A044SEI5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEI5"
  },
  {
    "uniprot": "A0A044R118",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R118"
  },
  {
    "uniprot": "A0A2K6VJ98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJ98"
  },
  {
    "uniprot": "A0A044TF45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TF45"
  },
  {
    "uniprot": "A0A044TFU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFU8"
  },
  {
    "uniprot": "A0A044V0M7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0M7"
  },
  {
    "uniprot": "A0A044UTM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTM5"
  },
  {
    "uniprot": "A0A2K6VKV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKV0"
  },
  {
    "uniprot": "A0A044VIZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIZ0"
  },
  {
    "uniprot": "A0A2K6WCP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WCP8"
  },
  {
    "uniprot": "A0A044UVQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVQ6"
  },
  {
    "uniprot": "A0A044TJ40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJ40"
  },
  {
    "uniprot": "A0A044QYY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYY0"
  },
  {
    "uniprot": "A0A044T212",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T212"
  },
  {
    "uniprot": "A0A044S394",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S394"
  },
  {
    "uniprot": "A0A044VJ29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJ29"
  },
  {
    "uniprot": "A0A044QK65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK65"
  },
  {
    "uniprot": "A0A044QMK7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMK7"
  },
  {
    "uniprot": "A0A044S2S5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2S5"
  },
  {
    "uniprot": "A0A2K6W7S1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7S1"
  },
  {
    "uniprot": "A0A044TAF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAF6"
  },
  {
    "uniprot": "A0A2K6VXG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXG4"
  },
  {
    "uniprot": "A0A044V5E9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5E9"
  },
  {
    "uniprot": "A0A2K6VYU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYU9"
  },
  {
    "uniprot": "A0A044RVV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVV3"
  },
  {
    "uniprot": "A0A044SMZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMZ7"
  },
  {
    "uniprot": "A0A158N8K8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8K8"
  },
  {
    "uniprot": "A0A044RTL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTL0"
  },
  {
    "uniprot": "A0A2K6VZK7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZK7"
  },
  {
    "uniprot": "A0A044V1G8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1G8"
  },
  {
    "uniprot": "A0A044VET1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VET1"
  },
  {
    "uniprot": "A0A044TC35",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC35"
  },
  {
    "uniprot": "A0A044RQX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQX7"
  },
  {
    "uniprot": "A0A044UV21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UV21"
  },
  {
    "uniprot": "A0A044SZ61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ61"
  },
  {
    "uniprot": "A0A044TFV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFV2"
  },
  {
    "uniprot": "A0A044RND6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RND6"
  },
  {
    "uniprot": "A0A2K6VKE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKE4"
  },
  {
    "uniprot": "A0A044RH02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH02"
  },
  {
    "uniprot": "A0A044R2C8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2C8"
  },
  {
    "uniprot": "A0A044TKH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKH3"
  },
  {
    "uniprot": "A0A044U7F2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7F2"
  },
  {
    "uniprot": "A0A044UAA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAA7"
  },
  {
    "uniprot": "A0A044V9W3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9W3"
  },
  {
    "uniprot": "A0A044U2M8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2M8"
  },
  {
    "uniprot": "A0A2K6W283",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W283"
  },
  {
    "uniprot": "A0A044UX34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX34"
  },
  {
    "uniprot": "A0A044T719",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T719"
  },
  {
    "uniprot": "A0A044URN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URN5"
  },
  {
    "uniprot": "A0A044R366",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R366"
  },
  {
    "uniprot": "A0A044QPL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPL1"
  },
  {
    "uniprot": "A0A044S1T8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1T8"
  },
  {
    "uniprot": "A0A044R1H3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1H3"
  },
  {
    "uniprot": "A0A044SF40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SF40"
  },
  {
    "uniprot": "A0A044S3H3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3H3"
  },
  {
    "uniprot": "A0A044UCA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCA6"
  },
  {
    "uniprot": "A0A044QVW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVW2"
  },
  {
    "uniprot": "A0A044TR56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR56"
  },
  {
    "uniprot": "A0A044RTS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTS2"
  },
  {
    "uniprot": "A0A044UPA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPA9"
  },
  {
    "uniprot": "A0A044QV94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QV94"
  },
  {
    "uniprot": "A0A044R7M2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7M2"
  },
  {
    "uniprot": "A0A044RIW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIW3"
  },
  {
    "uniprot": "A0A044U5S5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5S5"
  },
  {
    "uniprot": "A0A044TR52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR52"
  },
  {
    "uniprot": "A0A044UBU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBU0"
  },
  {
    "uniprot": "A0A044SMK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMK9"
  },
  {
    "uniprot": "A0A044RIW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIW7"
  },
  {
    "uniprot": "A0A044QN65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QN65"
  },
  {
    "uniprot": "A0A044QQT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQT9"
  },
  {
    "uniprot": "A0A044UGW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGW7"
  },
  {
    "uniprot": "A0A044U4A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4A9"
  },
  {
    "uniprot": "A0A044TKM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKM4"
  },
  {
    "uniprot": "A0A044UDZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDZ4"
  },
  {
    "uniprot": "A0A044V5Q4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5Q4"
  },
  {
    "uniprot": "A0A044RJ14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJ14"
  },
  {
    "uniprot": "A0A044V198",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V198"
  },
  {
    "uniprot": "A0A044QLM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLM3"
  },
  {
    "uniprot": "A0A044RT82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RT82"
  },
  {
    "uniprot": "A0A044UF46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF46"
  },
  {
    "uniprot": "A0A044TFU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFU4"
  },
  {
    "uniprot": "A0A158N843",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N843"
  },
  {
    "uniprot": "A0A044V4R6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4R6"
  },
  {
    "uniprot": "A0A044QL72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL72"
  },
  {
    "uniprot": "A0A2K6VHM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHM6"
  },
  {
    "uniprot": "A0A158N863",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N863"
  },
  {
    "uniprot": "A0A044U9E4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9E4"
  },
  {
    "uniprot": "A0A044RPM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPM0"
  },
  {
    "uniprot": "A0A044SI79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI79"
  },
  {
    "uniprot": "A0A2K6W2T6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2T6"
  },
  {
    "uniprot": "A0A044UYI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYI8"
  },
  {
    "uniprot": "A0A044RFB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFB9"
  },
  {
    "uniprot": "A0A044UYV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYV1"
  },
  {
    "uniprot": "A0A044RI52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RI52"
  },
  {
    "uniprot": "A0A2K6VRT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRT0"
  },
  {
    "uniprot": "A0A2K6WLA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLA7"
  },
  {
    "uniprot": "A0A044SCD2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCD2"
  },
  {
    "uniprot": "A0A044RQS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQS2"
  },
  {
    "uniprot": "A0A2K6W132",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W132"
  },
  {
    "uniprot": "A0A044RBE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBE7"
  },
  {
    "uniprot": "A0A044TNB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNB4"
  },
  {
    "uniprot": "A0A044QP81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP81"
  },
  {
    "uniprot": "A0A044RWZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWZ3"
  },
  {
    "uniprot": "A0A044RBV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBV4"
  },
  {
    "uniprot": "A0A044V8Y5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Y5"
  },
  {
    "uniprot": "A0A044TXY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXY0"
  },
  {
    "uniprot": "A0A044UT34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT34"
  },
  {
    "uniprot": "A0A044UTA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTA5"
  },
  {
    "uniprot": "A0A2K6W6V9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6V9"
  },
  {
    "uniprot": "A0A044VE59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VE59"
  },
  {
    "uniprot": "A0A044TYM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYM2"
  },
  {
    "uniprot": "A0A044S9B4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9B4"
  },
  {
    "uniprot": "A0A2K6W9P5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9P5"
  },
  {
    "uniprot": "A0A044VBR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBR1"
  },
  {
    "uniprot": "A0A044V1Q4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1Q4"
  },
  {
    "uniprot": "A0A2K6VSD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSD8"
  },
  {
    "uniprot": "A0A044UAP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAP7"
  },
  {
    "uniprot": "A0A044TDP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDP0"
  },
  {
    "uniprot": "A0A044QT19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT19"
  },
  {
    "uniprot": "A0A044V8G7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8G7"
  },
  {
    "uniprot": "A0A2K6WLP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLP7"
  },
  {
    "uniprot": "A0A044SCF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCF0"
  },
  {
    "uniprot": "A0A044SS11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS11"
  },
  {
    "uniprot": "A0A044RGI5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGI5"
  },
  {
    "uniprot": "A0A044QLX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLX4"
  },
  {
    "uniprot": "A0A2K6WJ86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJ86"
  },
  {
    "uniprot": "A0A2K6VPC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPC8"
  },
  {
    "uniprot": "A0A044THC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THC9"
  },
  {
    "uniprot": "A0A044QVL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVL5"
  },
  {
    "uniprot": "A0A044SKC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKC5"
  },
  {
    "uniprot": "A0A044TQJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQJ3"
  },
  {
    "uniprot": "A0A044V0J5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0J5"
  },
  {
    "uniprot": "A0A2K6VSY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSY0"
  },
  {
    "uniprot": "A0A044QP92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP92"
  },
  {
    "uniprot": "A0A044QR69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QR69"
  },
  {
    "uniprot": "A0A044TJL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJL6"
  },
  {
    "uniprot": "A0A044RCT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCT5"
  },
  {
    "uniprot": "A0A044V378",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V378"
  },
  {
    "uniprot": "A0A044QVZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVZ6"
  },
  {
    "uniprot": "A0A2K6VVD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVD7"
  },
  {
    "uniprot": "A0A044RBV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBV0"
  },
  {
    "uniprot": "A0A044SFQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFQ4"
  },
  {
    "uniprot": "A0A044R9D4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9D4"
  },
  {
    "uniprot": "A0A044T513",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T513"
  },
  {
    "uniprot": "A0A044V2V3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2V3"
  },
  {
    "uniprot": "A0A044UC74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC74"
  },
  {
    "uniprot": "A0A044UIW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIW6"
  },
  {
    "uniprot": "A0A044SI20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI20"
  },
  {
    "uniprot": "A0A044RTS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTS5"
  },
  {
    "uniprot": "A0A044UIB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIB7"
  },
  {
    "uniprot": "A0A044TVH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVH1"
  },
  {
    "uniprot": "A0A044RCS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCS4"
  },
  {
    "uniprot": "A0A044RNF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNF3"
  },
  {
    "uniprot": "A0A044TN62",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN62"
  },
  {
    "uniprot": "A0A044ULP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULP8"
  },
  {
    "uniprot": "A0A044QX98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QX98"
  },
  {
    "uniprot": "A0A044V218",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V218"
  },
  {
    "uniprot": "A0A044TWL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWL9"
  },
  {
    "uniprot": "A0A044SJK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJK5"
  },
  {
    "uniprot": "A0A044RW47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW47"
  },
  {
    "uniprot": "A0A044URW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URW5"
  },
  {
    "uniprot": "A0A044V9W5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9W5"
  },
  {
    "uniprot": "A0A044UEF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEF6"
  },
  {
    "uniprot": "A0A044QT45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT45"
  },
  {
    "uniprot": "A0A044U360",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U360"
  },
  {
    "uniprot": "A0A044RPH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPH5"
  },
  {
    "uniprot": "A0A044SC25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SC25"
  },
  {
    "uniprot": "A0A044SRG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRG5"
  },
  {
    "uniprot": "A0A044T684",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T684"
  },
  {
    "uniprot": "A0A044QXA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXA6"
  },
  {
    "uniprot": "A0A044TDM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDM6"
  },
  {
    "uniprot": "A0A2K6VXM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXM8"
  },
  {
    "uniprot": "A0A044V3Z6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3Z6"
  },
  {
    "uniprot": "A0A044RYV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYV3"
  },
  {
    "uniprot": "A0A044V341",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V341"
  },
  {
    "uniprot": "A0A158N8L9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8L9"
  },
  {
    "uniprot": "A0A044TMX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMX4"
  },
  {
    "uniprot": "A0A044SJI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJI8"
  },
  {
    "uniprot": "A0A044TI60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TI60"
  },
  {
    "uniprot": "A0A044RKQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKQ4"
  },
  {
    "uniprot": "A0A2K6WA20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WA20"
  },
  {
    "uniprot": "A0A044R3K3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3K3"
  },
  {
    "uniprot": "A0A044QXV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXV7"
  },
  {
    "uniprot": "A0A044TDX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDX1"
  },
  {
    "uniprot": "A0A044UKW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKW6"
  },
  {
    "uniprot": "A0A044UMJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMJ9"
  },
  {
    "uniprot": "A0A044RFD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFD3"
  },
  {
    "uniprot": "A0A044RAI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAI2"
  },
  {
    "uniprot": "A0A044U734",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U734"
  },
  {
    "uniprot": "A0A2K6W5K9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W5K9"
  },
  {
    "uniprot": "A0A044VD99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD99"
  },
  {
    "uniprot": "A0A044QJS1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJS1"
  },
  {
    "uniprot": "A0A044VFU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFU0"
  },
  {
    "uniprot": "A0A044SFM9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFM9"
  },
  {
    "uniprot": "A0A044QZY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZY5"
  },
  {
    "uniprot": "A0A044V9A8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9A8"
  },
  {
    "uniprot": "A0A044UR81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UR81"
  },
  {
    "uniprot": "A0A044T4I1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4I1"
  },
  {
    "uniprot": "A0A044R624",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R624"
  },
  {
    "uniprot": "A0A044TXT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXT2"
  },
  {
    "uniprot": "A0A044VDX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDX6"
  },
  {
    "uniprot": "A0A044QLT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLT7"
  },
  {
    "uniprot": "A0A044S188",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S188"
  },
  {
    "uniprot": "A0A2K6VFI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFI3"
  },
  {
    "uniprot": "A0A044UIL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIL9"
  },
  {
    "uniprot": "A0A044R1I7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1I7"
  },
  {
    "uniprot": "A0A044RTM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTM8"
  },
  {
    "uniprot": "A0A044VCH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCH6"
  },
  {
    "uniprot": "A0A044RVU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVU1"
  },
  {
    "uniprot": "A0A044SEL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEL6"
  },
  {
    "uniprot": "A0A044RM85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RM85"
  },
  {
    "uniprot": "A0A044T5P0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5P0"
  },
  {
    "uniprot": "A0A044U9I1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9I1"
  },
  {
    "uniprot": "A0A044UDQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDQ3"
  },
  {
    "uniprot": "A0A044QYP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYP4"
  },
  {
    "uniprot": "A0A044R1A2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1A2"
  },
  {
    "uniprot": "A0A044UX02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX02"
  },
  {
    "uniprot": "A0A044T3L2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3L2"
  },
  {
    "uniprot": "A0A044RTS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTS9"
  },
  {
    "uniprot": "A0A2K6VJ88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJ88"
  },
  {
    "uniprot": "A0A044UM79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UM79"
  },
  {
    "uniprot": "A0A044RVI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVI9"
  },
  {
    "uniprot": "A0A044TS57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS57"
  },
  {
    "uniprot": "A0A044R6J0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6J0"
  },
  {
    "uniprot": "A0A044UEP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEP7"
  },
  {
    "uniprot": "A0A044SLY1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLY1"
  },
  {
    "uniprot": "A0A044TZI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZI9"
  },
  {
    "uniprot": "A0A044V9I3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9I3"
  },
  {
    "uniprot": "A0A044VDJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDJ3"
  },
  {
    "uniprot": "A0A044UBD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBD6"
  },
  {
    "uniprot": "A0A044UCI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCI1"
  },
  {
    "uniprot": "A0A044UNG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNG9"
  },
  {
    "uniprot": "A0A2K6W0U1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0U1"
  },
  {
    "uniprot": "A0A2K6WG77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WG77"
  },
  {
    "uniprot": "A0A044R0J4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0J4"
  },
  {
    "uniprot": "A0A044UUJ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUJ2"
  },
  {
    "uniprot": "A0A044S4J3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4J3"
  },
  {
    "uniprot": "A0A044RDU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDU9"
  },
  {
    "uniprot": "A0A044RM65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RM65"
  },
  {
    "uniprot": "A0A044V603",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V603"
  },
  {
    "uniprot": "A0A044TQN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQN1"
  },
  {
    "uniprot": "A0A044V736",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V736"
  },
  {
    "uniprot": "A0A044QP88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP88"
  },
  {
    "uniprot": "A0A044TTE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTE4"
  },
  {
    "uniprot": "A0A044SSB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSB7"
  },
  {
    "uniprot": "A0A044UIG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIG0"
  },
  {
    "uniprot": "A0A044UVZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVZ9"
  },
  {
    "uniprot": "A0A044VBL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBL5"
  },
  {
    "uniprot": "A0A044TW56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TW56"
  },
  {
    "uniprot": "A0A044V176",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V176"
  },
  {
    "uniprot": "A0A044SS24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS24"
  },
  {
    "uniprot": "A0A044SGL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGL1"
  },
  {
    "uniprot": "A0A044VGQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGQ4"
  },
  {
    "uniprot": "A0A044UKI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKI3"
  },
  {
    "uniprot": "A0A044T580",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T580"
  },
  {
    "uniprot": "A0A044U1N1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1N1"
  },
  {
    "uniprot": "A0A044RYD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYD7"
  },
  {
    "uniprot": "A0A044RJJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJJ6"
  },
  {
    "uniprot": "A0A044TN43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN43"
  },
  {
    "uniprot": "A0A2K6VNV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VNV9"
  },
  {
    "uniprot": "A0A044V327",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V327"
  },
  {
    "uniprot": "A0A044VI38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VI38"
  },
  {
    "uniprot": "A0A044V286",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V286"
  },
  {
    "uniprot": "A0A044UTH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTH6"
  },
  {
    "uniprot": "A0A044V9N0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9N0"
  },
  {
    "uniprot": "A0A044UTQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTQ0"
  },
  {
    "uniprot": "A0A044VE71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VE71"
  },
  {
    "uniprot": "A0A044UZ28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ28"
  },
  {
    "uniprot": "A0A044TAB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAB8"
  },
  {
    "uniprot": "A0A044UB67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UB67"
  },
  {
    "uniprot": "A0A044US53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044US53"
  },
  {
    "uniprot": "A0A044R8G9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8G9"
  },
  {
    "uniprot": "A0A044UPL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPL1"
  },
  {
    "uniprot": "A0A044QQX0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQX0"
  },
  {
    "uniprot": "A0A044S2S9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2S9"
  },
  {
    "uniprot": "A0A044SGN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGN7"
  },
  {
    "uniprot": "A0A044R2V4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2V4"
  },
  {
    "uniprot": "A0A044SMX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMX8"
  },
  {
    "uniprot": "A0A044QLC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLC9"
  },
  {
    "uniprot": "A0A044V4F4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4F4"
  },
  {
    "uniprot": "A0A044RY95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY95"
  },
  {
    "uniprot": "A0A044U9X1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9X1"
  },
  {
    "uniprot": "A0A044VFN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFN8"
  },
  {
    "uniprot": "A0A044THC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THC6"
  },
  {
    "uniprot": "A0A044SHP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHP1"
  },
  {
    "uniprot": "A0A044RG68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RG68"
  },
  {
    "uniprot": "A0A044QWI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWI4"
  },
  {
    "uniprot": "A0A2K6W860",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W860"
  },
  {
    "uniprot": "A0A2K6VPU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPU3"
  },
  {
    "uniprot": "A0A044RG36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RG36"
  },
  {
    "uniprot": "A0A044V920",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V920"
  },
  {
    "uniprot": "A0A044V1I3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1I3"
  },
  {
    "uniprot": "A0A044VAJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAJ6"
  },
  {
    "uniprot": "A0A044TJ05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJ05"
  },
  {
    "uniprot": "A0A044TFE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFE7"
  },
  {
    "uniprot": "A0A044TDA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDA1"
  },
  {
    "uniprot": "A0A044TZJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZJ6"
  },
  {
    "uniprot": "A0A044UR20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UR20"
  },
  {
    "uniprot": "A0A044UR62",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UR62"
  },
  {
    "uniprot": "A0A044UUL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUL8"
  },
  {
    "uniprot": "A0A044UE07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE07"
  },
  {
    "uniprot": "A0A044TIR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIR4"
  },
  {
    "uniprot": "A0A2K6W411",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W411"
  },
  {
    "uniprot": "A0A044TRW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRW6"
  },
  {
    "uniprot": "A0A044S7M5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7M5"
  },
  {
    "uniprot": "A0A044UZ56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ56"
  },
  {
    "uniprot": "A0A044S5F7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5F7"
  },
  {
    "uniprot": "A0A044SQI5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQI5"
  },
  {
    "uniprot": "A0A044S628",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S628"
  },
  {
    "uniprot": "A0A044TII1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TII1"
  },
  {
    "uniprot": "A0A044RTT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTT1"
  },
  {
    "uniprot": "A0A044TQT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQT0"
  },
  {
    "uniprot": "A0A044VA72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA72"
  },
  {
    "uniprot": "A0A044UXE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXE1"
  },
  {
    "uniprot": "A0A044UM18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UM18"
  },
  {
    "uniprot": "A0A044TNR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNR2"
  },
  {
    "uniprot": "A0A044TTI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTI9"
  },
  {
    "uniprot": "A0A2K6VIP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIP4"
  },
  {
    "uniprot": "A0A044V5G1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5G1"
  },
  {
    "uniprot": "A0A044V1N6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1N6"
  },
  {
    "uniprot": "A0A158N7T5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7T5"
  },
  {
    "uniprot": "A0A2K6WJE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJE2"
  },
  {
    "uniprot": "A0A044QYK7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYK7"
  },
  {
    "uniprot": "A0A044SHB2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHB2"
  },
  {
    "uniprot": "A0A044R2Q6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2Q6"
  },
  {
    "uniprot": "A0A044TPJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPJ7"
  },
  {
    "uniprot": "A0A044T7U7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7U7"
  },
  {
    "uniprot": "A0A044VDR5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDR5"
  },
  {
    "uniprot": "A0A2K6VNU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VNU8"
  },
  {
    "uniprot": "A0A044SVJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVJ1"
  },
  {
    "uniprot": "A0A158N7Z1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Z1"
  },
  {
    "uniprot": "A0A044VA84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA84"
  },
  {
    "uniprot": "A0A044SXE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXE0"
  },
  {
    "uniprot": "A0A044S7S6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7S6"
  },
  {
    "uniprot": "A0A044TX28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TX28"
  },
  {
    "uniprot": "A0A044R1U3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1U3"
  },
  {
    "uniprot": "A0A044QZ68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ68"
  },
  {
    "uniprot": "A0A044UJS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJS5"
  },
  {
    "uniprot": "A0A044R8U2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8U2"
  },
  {
    "uniprot": "P38544",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P38544"
  },
  {
    "uniprot": "A0A044V9W6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9W6"
  },
  {
    "uniprot": "A0A044VI97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VI97"
  },
  {
    "uniprot": "A0A044UHY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHY5"
  },
  {
    "uniprot": "A0A044R854",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R854"
  },
  {
    "uniprot": "A0A044SY74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY74"
  },
  {
    "uniprot": "A0A044VB49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VB49"
  },
  {
    "uniprot": "A0A044RPJ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPJ8"
  },
  {
    "uniprot": "A0A044V9T6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9T6"
  },
  {
    "uniprot": "A0A044TZT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZT8"
  },
  {
    "uniprot": "A0A044TP02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TP02"
  },
  {
    "uniprot": "A0A044VHK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHK0"
  },
  {
    "uniprot": "A0A044VA23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA23"
  },
  {
    "uniprot": "A0A044RVT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVT1"
  },
  {
    "uniprot": "A0A044S763",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S763"
  },
  {
    "uniprot": "A0A044QJV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJV5"
  },
  {
    "uniprot": "A0A044TDC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDC0"
  },
  {
    "uniprot": "A0A044TWI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWI6"
  },
  {
    "uniprot": "A0A044TUF9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUF9"
  },
  {
    "uniprot": "A0A044UV05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UV05"
  },
  {
    "uniprot": "A0A044QZ98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ98"
  },
  {
    "uniprot": "A0A044RC50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC50"
  },
  {
    "uniprot": "A0A044TCA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCA0"
  },
  {
    "uniprot": "A0A044RSU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSU0"
  },
  {
    "uniprot": "A0A044T596",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T596"
  },
  {
    "uniprot": "A0A044QV05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QV05"
  },
  {
    "uniprot": "A0A044RRM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRM2"
  },
  {
    "uniprot": "A0A044T7P8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7P8"
  },
  {
    "uniprot": "A0A044TR94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR94"
  },
  {
    "uniprot": "A0A044R4Z0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4Z0"
  },
  {
    "uniprot": "A0A044RBH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBH3"
  },
  {
    "uniprot": "A0A044UU21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UU21"
  },
  {
    "uniprot": "A0A044U9I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9I9"
  },
  {
    "uniprot": "A0A044UNB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNB6"
  },
  {
    "uniprot": "A0A044SV31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SV31"
  },
  {
    "uniprot": "A0A044TSC7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSC7"
  },
  {
    "uniprot": "A0A2K6VQN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQN9"
  },
  {
    "uniprot": "A0A044TMG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMG9"
  },
  {
    "uniprot": "A0A2K6VQ86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQ86"
  },
  {
    "uniprot": "A0A044TFU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFU1"
  },
  {
    "uniprot": "A0A044TWQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWQ6"
  },
  {
    "uniprot": "A0A044TNL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNL4"
  },
  {
    "uniprot": "A0A044USC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USC1"
  },
  {
    "uniprot": "A0A044TNZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNZ5"
  },
  {
    "uniprot": "A0A044T3C6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3C6"
  },
  {
    "uniprot": "A0A044U586",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U586"
  },
  {
    "uniprot": "A0A044T2N1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2N1"
  },
  {
    "uniprot": "A0A158N8G2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8G2"
  },
  {
    "uniprot": "A0A044SSC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSC1"
  },
  {
    "uniprot": "A0A044QST6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QST6"
  },
  {
    "uniprot": "A0A044RWS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWS6"
  },
  {
    "uniprot": "A0A044SFC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFC1"
  },
  {
    "uniprot": "A0A044UER5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UER5"
  },
  {
    "uniprot": "A0A044VB57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VB57"
  },
  {
    "uniprot": "A0A044T9L1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9L1"
  },
  {
    "uniprot": "A0A044SJ74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJ74"
  },
  {
    "uniprot": "A0A044SAT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAT5"
  },
  {
    "uniprot": "A0A2K6WIF7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIF7"
  },
  {
    "uniprot": "A0A044SWB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWB4"
  },
  {
    "uniprot": "A0A044UDN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDN5"
  },
  {
    "uniprot": "A0A044TG89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TG89"
  },
  {
    "uniprot": "A0A044U8A2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8A2"
  },
  {
    "uniprot": "A0A044UNT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNT0"
  },
  {
    "uniprot": "A0A044RHQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHQ4"
  },
  {
    "uniprot": "A0A158N884",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N884"
  },
  {
    "uniprot": "A0A044R8Q0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8Q0"
  },
  {
    "uniprot": "A0A044UXS1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXS1"
  },
  {
    "uniprot": "A0A2K6VPT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPT9"
  },
  {
    "uniprot": "A0A2K6W2S6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2S6"
  },
  {
    "uniprot": "A0A044STS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STS7"
  },
  {
    "uniprot": "A0A158N7Y6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Y6"
  },
  {
    "uniprot": "A0A044UZ51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ51"
  },
  {
    "uniprot": "A0A044RK48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RK48"
  },
  {
    "uniprot": "A0A044U972",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U972"
  },
  {
    "uniprot": "A0A044RJS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJS3"
  },
  {
    "uniprot": "A0A044RZC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZC3"
  },
  {
    "uniprot": "A0A044U1A3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1A3"
  },
  {
    "uniprot": "A0A044T1L3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1L3"
  },
  {
    "uniprot": "A0A2K6VSX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSX2"
  },
  {
    "uniprot": "A0A044TTZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTZ2"
  },
  {
    "uniprot": "A0A044U2J3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2J3"
  },
  {
    "uniprot": "A0A044SKF9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKF9"
  },
  {
    "uniprot": "A0A044SNY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNY4"
  },
  {
    "uniprot": "A0A044TGW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGW0"
  },
  {
    "uniprot": "A0A044TYG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYG1"
  },
  {
    "uniprot": "A0A044T3W5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3W5"
  },
  {
    "uniprot": "A0A044TAR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAR1"
  },
  {
    "uniprot": "A0A044S742",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S742"
  },
  {
    "uniprot": "A0A044RSI5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSI5"
  },
  {
    "uniprot": "A0A044RZQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZQ7"
  },
  {
    "uniprot": "A0A044VIT3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIT3"
  },
  {
    "uniprot": "A0A044RIU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIU7"
  },
  {
    "uniprot": "A0A044S5W5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5W5"
  },
  {
    "uniprot": "A0A044UJF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJF6"
  },
  {
    "uniprot": "A0A044RH23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH23"
  },
  {
    "uniprot": "A0A044R7I8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7I8"
  },
  {
    "uniprot": "A0A2K6VFI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFI2"
  },
  {
    "uniprot": "A0A044SQZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQZ0"
  },
  {
    "uniprot": "A0A044S266",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S266"
  },
  {
    "uniprot": "A0A044RQX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQX1"
  },
  {
    "uniprot": "A0A044USD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USD8"
  },
  {
    "uniprot": "A0A044QQY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQY5"
  },
  {
    "uniprot": "A0A2K6WIT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIT5"
  },
  {
    "uniprot": "A0A044R4S8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4S8"
  },
  {
    "uniprot": "A0A044SRB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRB9"
  },
  {
    "uniprot": "A0A044V3Q6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3Q6"
  },
  {
    "uniprot": "A0A044TY06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TY06"
  },
  {
    "uniprot": "A0A044TE50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TE50"
  },
  {
    "uniprot": "A0A044V346",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V346"
  },
  {
    "uniprot": "A0A2K6W8Y3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8Y3"
  },
  {
    "uniprot": "A0A044V8D5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8D5"
  },
  {
    "uniprot": "A0A044S1I2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1I2"
  },
  {
    "uniprot": "A0A044R3D4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3D4"
  },
  {
    "uniprot": "A0A044V478",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V478"
  },
  {
    "uniprot": "A0A044S1J9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1J9"
  },
  {
    "uniprot": "A0A044STP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STP4"
  },
  {
    "uniprot": "A0A044SKK7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKK7"
  },
  {
    "uniprot": "A0A044UBH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBH2"
  },
  {
    "uniprot": "A0A044UV74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UV74"
  },
  {
    "uniprot": "A0A044QNT3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNT3"
  },
  {
    "uniprot": "A0A044TW18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TW18"
  },
  {
    "uniprot": "A0A044SQV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQV8"
  },
  {
    "uniprot": "A0A044SFG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFG6"
  },
  {
    "uniprot": "A0A044TUU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUU6"
  },
  {
    "uniprot": "A0A044S087",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S087"
  },
  {
    "uniprot": "A0A044SI24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI24"
  },
  {
    "uniprot": "A0A044SWU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWU1"
  },
  {
    "uniprot": "A0A044UI53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI53"
  },
  {
    "uniprot": "A0A044R3A1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3A1"
  },
  {
    "uniprot": "A0A044UL36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL36"
  },
  {
    "uniprot": "A0A044TAY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAY2"
  },
  {
    "uniprot": "A0A044SGM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGM0"
  },
  {
    "uniprot": "A0A044SZI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZI4"
  },
  {
    "uniprot": "A0A044UZ78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ78"
  },
  {
    "uniprot": "A0A044U2L8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2L8"
  },
  {
    "uniprot": "A0A2K6VDX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDX8"
  },
  {
    "uniprot": "A0A044UPB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPB9"
  },
  {
    "uniprot": "A0A044SH27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SH27"
  },
  {
    "uniprot": "A0A044UXG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXG5"
  },
  {
    "uniprot": "A0A044UQA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQA0"
  },
  {
    "uniprot": "A0A044SAL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAL9"
  },
  {
    "uniprot": "A0A044UA91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA91"
  },
  {
    "uniprot": "A0A044SEP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEP0"
  },
  {
    "uniprot": "A0A044UMF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMF4"
  },
  {
    "uniprot": "A0A044R2P0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2P0"
  },
  {
    "uniprot": "A0A044S871",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S871"
  },
  {
    "uniprot": "A0A044U356",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U356"
  },
  {
    "uniprot": "A0A044VGH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGH5"
  },
  {
    "uniprot": "A0A044UAS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAS5"
  },
  {
    "uniprot": "A0A044UT72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT72"
  },
  {
    "uniprot": "A0A044QPS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPS7"
  },
  {
    "uniprot": "A0A044UMM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMM5"
  },
  {
    "uniprot": "A0A044USL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USL0"
  },
  {
    "uniprot": "A0A044RNH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNH3"
  },
  {
    "uniprot": "A0A158N7W2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7W2"
  },
  {
    "uniprot": "A0A044UBJ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBJ5"
  },
  {
    "uniprot": "A0A044V028",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V028"
  },
  {
    "uniprot": "A0A044SDW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDW7"
  },
  {
    "uniprot": "A0A044URN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URN8"
  },
  {
    "uniprot": "A0A2K6VWQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWQ9"
  },
  {
    "uniprot": "A0A2K6VH37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VH37"
  },
  {
    "uniprot": "A0A044SCZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCZ9"
  },
  {
    "uniprot": "A0A044TTT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTT6"
  },
  {
    "uniprot": "A0A044QQN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQN2"
  },
  {
    "uniprot": "A0A044V956",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V956"
  },
  {
    "uniprot": "A0A044TFF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFF2"
  },
  {
    "uniprot": "A0A044VFQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFQ9"
  },
  {
    "uniprot": "A0A2K6VKM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKM5"
  },
  {
    "uniprot": "A0A2K6VRN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRN4"
  },
  {
    "uniprot": "A0A044UIC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIC1"
  },
  {
    "uniprot": "A0A044V2U6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2U6"
  },
  {
    "uniprot": "A0A044UUJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUJ7"
  },
  {
    "uniprot": "A0A044TFC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFC4"
  },
  {
    "uniprot": "A0A044SY05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY05"
  },
  {
    "uniprot": "A0A044R333",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R333"
  },
  {
    "uniprot": "A0A044V8H4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8H4"
  },
  {
    "uniprot": "A0A2K6VPU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPU4"
  },
  {
    "uniprot": "A0A044U2T1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2T1"
  },
  {
    "uniprot": "A0A044UZ64",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ64"
  },
  {
    "uniprot": "A0A2K6WAC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAC9"
  },
  {
    "uniprot": "A0A044S280",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S280"
  },
  {
    "uniprot": "A0A044SEX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEX9"
  },
  {
    "uniprot": "A0A2K6VUW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUW9"
  },
  {
    "uniprot": "A0A044U3S2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3S2"
  },
  {
    "uniprot": "A0A2K6VTW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VTW7"
  },
  {
    "uniprot": "A0A044U3I4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3I4"
  },
  {
    "uniprot": "A0A044RMU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMU5"
  },
  {
    "uniprot": "A0A2K6W8M4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8M4"
  },
  {
    "uniprot": "A0A044QQ69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQ69"
  },
  {
    "uniprot": "A0A2K6VS90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VS90"
  },
  {
    "uniprot": "A0A158N8U1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8U1"
  },
  {
    "uniprot": "A0A2K6WK11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WK11"
  },
  {
    "uniprot": "A0A044RGY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGY3"
  },
  {
    "uniprot": "A0A044RP68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RP68"
  },
  {
    "uniprot": "A0A044SBS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBS9"
  },
  {
    "uniprot": "A0A044V1A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1A9"
  },
  {
    "uniprot": "A0A044V725",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V725"
  },
  {
    "uniprot": "A0A044TR45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR45"
  },
  {
    "uniprot": "A0A044RIK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIK8"
  },
  {
    "uniprot": "A0A044UDI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDI8"
  },
  {
    "uniprot": "A0A044T497",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T497"
  },
  {
    "uniprot": "A0A044UC78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC78"
  },
  {
    "uniprot": "A0A044R1X5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1X5"
  },
  {
    "uniprot": "A0A044R6P6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6P6"
  },
  {
    "uniprot": "A0A2K6VL16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VL16"
  },
  {
    "uniprot": "A0A044UXK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXK4"
  },
  {
    "uniprot": "Q25633",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q25633"
  },
  {
    "uniprot": "A0A2K6W679",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W679"
  },
  {
    "uniprot": "A0A044V9S1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9S1"
  },
  {
    "uniprot": "A0A044U923",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U923"
  },
  {
    "uniprot": "A0A044RYG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYG8"
  },
  {
    "uniprot": "A0A044S9Z5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9Z5"
  },
  {
    "uniprot": "A0A2K6VQ62",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQ62"
  },
  {
    "uniprot": "A0A044TZI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZI2"
  },
  {
    "uniprot": "A0A044QWP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWP1"
  },
  {
    "uniprot": "A0A044S9P3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9P3"
  },
  {
    "uniprot": "A0A044VG57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VG57"
  },
  {
    "uniprot": "A0A044TE35",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TE35"
  },
  {
    "uniprot": "A0A2K6VTT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VTT6"
  },
  {
    "uniprot": "A0A044V345",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V345"
  },
  {
    "uniprot": "A0A044R7I3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7I3"
  },
  {
    "uniprot": "A0A044UAV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAV4"
  },
  {
    "uniprot": "A0A044SQH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQH9"
  },
  {
    "uniprot": "A0A044V986",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V986"
  },
  {
    "uniprot": "A0A2K6WDZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDZ3"
  },
  {
    "uniprot": "A0A044UTN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTN2"
  },
  {
    "uniprot": "A0A044R6T0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6T0"
  },
  {
    "uniprot": "A0A044TBF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBF4"
  },
  {
    "uniprot": "A0A044QPJ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPJ2"
  },
  {
    "uniprot": "A0A2K6W6T2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6T2"
  },
  {
    "uniprot": "A0A044QMC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMC5"
  },
  {
    "uniprot": "A0A044RV85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RV85"
  },
  {
    "uniprot": "A0A044V9E4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9E4"
  },
  {
    "uniprot": "A0A044R1N4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1N4"
  },
  {
    "uniprot": "A0A044VBV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBV0"
  },
  {
    "uniprot": "A0A044S2T5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2T5"
  },
  {
    "uniprot": "A0A044VEC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEC5"
  },
  {
    "uniprot": "A0A2K6WD85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WD85"
  },
  {
    "uniprot": "A0A158N8L2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8L2"
  },
  {
    "uniprot": "A0A044V9N6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9N6"
  },
  {
    "uniprot": "A0A2K6WGE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGE3"
  },
  {
    "uniprot": "A0A044TLD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLD8"
  },
  {
    "uniprot": "A0A044S3L8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3L8"
  },
  {
    "uniprot": "A0A044T3R4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3R4"
  },
  {
    "uniprot": "A0A044V8T9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8T9"
  },
  {
    "uniprot": "A0A044SMY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMY6"
  },
  {
    "uniprot": "A0A044T1S9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1S9"
  },
  {
    "uniprot": "A0A044UCE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCE3"
  },
  {
    "uniprot": "A0A2K6W775",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W775"
  },
  {
    "uniprot": "A0A044SII3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SII3"
  },
  {
    "uniprot": "A0A044RBQ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBQ8"
  },
  {
    "uniprot": "A0A044RGM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGM6"
  },
  {
    "uniprot": "A0A044TDX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDX5"
  },
  {
    "uniprot": "A0A044V479",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V479"
  },
  {
    "uniprot": "A0A044UXW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXW5"
  },
  {
    "uniprot": "A0A044VAT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAT6"
  },
  {
    "uniprot": "A0A044SX64",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SX64"
  },
  {
    "uniprot": "A0A044UA59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA59"
  },
  {
    "uniprot": "A0A044RWZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWZ9"
  },
  {
    "uniprot": "A0A044QTS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTS0"
  },
  {
    "uniprot": "A0A044TNW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNW3"
  },
  {
    "uniprot": "A0A044SV19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SV19"
  },
  {
    "uniprot": "A0A044RTU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTU7"
  },
  {
    "uniprot": "A0A2K6W6B9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6B9"
  },
  {
    "uniprot": "A0A044R5A4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5A4"
  },
  {
    "uniprot": "A0A044V8L0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8L0"
  },
  {
    "uniprot": "A0A044UGX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGX7"
  },
  {
    "uniprot": "A0A044V9A3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9A3"
  },
  {
    "uniprot": "A0A044T8K8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8K8"
  },
  {
    "uniprot": "A0A044UGX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGX1"
  },
  {
    "uniprot": "A0A044SC30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SC30"
  },
  {
    "uniprot": "A0A044TUF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUF4"
  },
  {
    "uniprot": "A0A2K6VX29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VX29"
  },
  {
    "uniprot": "A0A044U4W0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4W0"
  },
  {
    "uniprot": "A0A044V7T3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7T3"
  },
  {
    "uniprot": "A0A044T039",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T039"
  },
  {
    "uniprot": "A0A044V6X6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6X6"
  },
  {
    "uniprot": "A0A044RZJ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZJ5"
  },
  {
    "uniprot": "A0A044T5E7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5E7"
  },
  {
    "uniprot": "A0A044SZ11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ11"
  },
  {
    "uniprot": "A0A044SWP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWP0"
  },
  {
    "uniprot": "A0A044VE37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VE37"
  },
  {
    "uniprot": "A0A044S2W4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2W4"
  },
  {
    "uniprot": "A0A044UES2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UES2"
  },
  {
    "uniprot": "A0A044QX42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QX42"
  },
  {
    "uniprot": "A0A044V156",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V156"
  },
  {
    "uniprot": "A0A044SNH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNH6"
  },
  {
    "uniprot": "A0A044UWM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWM6"
  },
  {
    "uniprot": "A0A044TYX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYX1"
  },
  {
    "uniprot": "Q94638",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q94638"
  },
  {
    "uniprot": "A0A044TPT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPT6"
  },
  {
    "uniprot": "A0A044QTQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTQ7"
  },
  {
    "uniprot": "A0A044RM57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RM57"
  },
  {
    "uniprot": "A0A044RNX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNX4"
  },
  {
    "uniprot": "A0A044V9C9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9C9"
  },
  {
    "uniprot": "A0A044R8D5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8D5"
  },
  {
    "uniprot": "A0A044VHD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHD6"
  },
  {
    "uniprot": "A0A044RBZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBZ6"
  },
  {
    "uniprot": "A0A044T766",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T766"
  },
  {
    "uniprot": "A0A044VIE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIE6"
  },
  {
    "uniprot": "A0A044U301",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U301"
  },
  {
    "uniprot": "A0A044RKD9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKD9"
  },
  {
    "uniprot": "A0A044UGC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGC5"
  },
  {
    "uniprot": "A0A044UXP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXP9"
  },
  {
    "uniprot": "A0A044V738",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V738"
  },
  {
    "uniprot": "A0A044U5Z7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5Z7"
  },
  {
    "uniprot": "A0A044UPX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPX1"
  },
  {
    "uniprot": "A0A044SS66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS66"
  },
  {
    "uniprot": "A0A044RMA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMA7"
  },
  {
    "uniprot": "A0A044V494",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V494"
  },
  {
    "uniprot": "A0A2K6VIF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIF1"
  },
  {
    "uniprot": "A0A044UDX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDX3"
  },
  {
    "uniprot": "A0A044V9E8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9E8"
  },
  {
    "uniprot": "A0A044V4G1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4G1"
  },
  {
    "uniprot": "A0A044QW10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW10"
  },
  {
    "uniprot": "A0A044S836",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S836"
  },
  {
    "uniprot": "A0A044UME6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UME6"
  },
  {
    "uniprot": "A0A044T6J3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6J3"
  },
  {
    "uniprot": "A0A044S0A6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0A6"
  },
  {
    "uniprot": "A0A044RDH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDH6"
  },
  {
    "uniprot": "A0A044RGY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGY9"
  },
  {
    "uniprot": "A0A044SWV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWV3"
  },
  {
    "uniprot": "A0A044R6S1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6S1"
  },
  {
    "uniprot": "A0A044SZA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZA9"
  },
  {
    "uniprot": "A0A044QQT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQT7"
  },
  {
    "uniprot": "A0A044QLV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLV6"
  },
  {
    "uniprot": "A0A2K6VXF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXF2"
  },
  {
    "uniprot": "A0A044ULW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULW4"
  },
  {
    "uniprot": "A0A044TKV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKV8"
  },
  {
    "uniprot": "A0A044SVA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVA7"
  },
  {
    "uniprot": "A0A044QL93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL93"
  },
  {
    "uniprot": "A0A044QW38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW38"
  },
  {
    "uniprot": "A0A2K6W8I1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8I1"
  },
  {
    "uniprot": "A0A044TBD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBD5"
  },
  {
    "uniprot": "A0A044RYH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYH2"
  },
  {
    "uniprot": "A0A2K6W190",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W190"
  },
  {
    "uniprot": "A0A044UM30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UM30"
  },
  {
    "uniprot": "A0A044UNE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNE6"
  },
  {
    "uniprot": "A0A044SLL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLL4"
  },
  {
    "uniprot": "A0A044TG74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TG74"
  },
  {
    "uniprot": "A0A044VB93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VB93"
  },
  {
    "uniprot": "A0A044V9V8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9V8"
  },
  {
    "uniprot": "A0A2K6VMB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMB5"
  },
  {
    "uniprot": "A0A044QXX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXX9"
  },
  {
    "uniprot": "A0A044V9G4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9G4"
  },
  {
    "uniprot": "A0A044QRR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRR3"
  },
  {
    "uniprot": "A0A044R3Q3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3Q3"
  },
  {
    "uniprot": "A0A044S7I3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7I3"
  },
  {
    "uniprot": "A0A044S9W3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9W3"
  },
  {
    "uniprot": "A0A044RF39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RF39"
  },
  {
    "uniprot": "A0A044V177",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V177"
  },
  {
    "uniprot": "A0A044S541",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S541"
  },
  {
    "uniprot": "A0A044V0I2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0I2"
  },
  {
    "uniprot": "A0A044V4A8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4A8"
  },
  {
    "uniprot": "A0A044U3V8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3V8"
  },
  {
    "uniprot": "A0A044US13",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044US13"
  },
  {
    "uniprot": "A0A044SHQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHQ4"
  },
  {
    "uniprot": "A0A2K6VYP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYP4"
  },
  {
    "uniprot": "A0A044S247",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S247"
  },
  {
    "uniprot": "A0A044UV89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UV89"
  },
  {
    "uniprot": "A0A2K6WJB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJB0"
  },
  {
    "uniprot": "A0A044UW50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UW50"
  },
  {
    "uniprot": "A0A044SU44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU44"
  },
  {
    "uniprot": "A0A044V431",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V431"
  },
  {
    "uniprot": "A0A044SEH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEH9"
  },
  {
    "uniprot": "A0A2K6VS35",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VS35"
  },
  {
    "uniprot": "A0A044TP58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TP58"
  },
  {
    "uniprot": "A0A044UE02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE02"
  },
  {
    "uniprot": "A0A044T9A1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9A1"
  },
  {
    "uniprot": "A0A044SFL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFL4"
  },
  {
    "uniprot": "A0A044SEG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEG9"
  },
  {
    "uniprot": "A0A044ULT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULT2"
  },
  {
    "uniprot": "A0A044T6M2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6M2"
  },
  {
    "uniprot": "A0A044R0R7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0R7"
  },
  {
    "uniprot": "A0A044QK20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK20"
  },
  {
    "uniprot": "A0A044UVF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVF5"
  },
  {
    "uniprot": "A0A044V5W2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5W2"
  },
  {
    "uniprot": "A0A044V9L0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9L0"
  },
  {
    "uniprot": "A0A044RWS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWS4"
  },
  {
    "uniprot": "A0A044SEV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEV7"
  },
  {
    "uniprot": "A0A044U327",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U327"
  },
  {
    "uniprot": "A0A044U6R2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6R2"
  },
  {
    "uniprot": "A0A044TAD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAD5"
  },
  {
    "uniprot": "A0A044V6X7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6X7"
  },
  {
    "uniprot": "A0A2K6WDU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDU3"
  },
  {
    "uniprot": "A0A2K6WKG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKG1"
  },
  {
    "uniprot": "A0A044T480",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T480"
  },
  {
    "uniprot": "A0A044TEU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEU6"
  },
  {
    "uniprot": "A0A044V5F6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5F6"
  },
  {
    "uniprot": "A0A044QT34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT34"
  },
  {
    "uniprot": "A0A044U945",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U945"
  },
  {
    "uniprot": "A0A044TRW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRW0"
  },
  {
    "uniprot": "A0A044TSK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSK9"
  },
  {
    "uniprot": "A0A044UC02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC02"
  },
  {
    "uniprot": "A0A044S1M2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1M2"
  },
  {
    "uniprot": "A0A044TYI5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYI5"
  },
  {
    "uniprot": "A0A044TN53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN53"
  },
  {
    "uniprot": "A0A044U8S1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8S1"
  },
  {
    "uniprot": "A0A044QTZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTZ5"
  },
  {
    "uniprot": "A0A044QUC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUC2"
  },
  {
    "uniprot": "A0A044R2N4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2N4"
  },
  {
    "uniprot": "A0A044T9S4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9S4"
  },
  {
    "uniprot": "A0A044V1B7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1B7"
  },
  {
    "uniprot": "A0A044T1T9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1T9"
  },
  {
    "uniprot": "A0A044V1I6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1I6"
  },
  {
    "uniprot": "A0A044QS26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QS26"
  },
  {
    "uniprot": "A0A044U8X5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8X5"
  },
  {
    "uniprot": "A0A044USH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USH7"
  },
  {
    "uniprot": "A0A044TPK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPK0"
  },
  {
    "uniprot": "A0A044US47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044US47"
  },
  {
    "uniprot": "A0A044RQ45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQ45"
  },
  {
    "uniprot": "A0A044V9F0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9F0"
  },
  {
    "uniprot": "A0A044T655",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T655"
  },
  {
    "uniprot": "A0A044TF56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TF56"
  },
  {
    "uniprot": "A0A044RMV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMV1"
  },
  {
    "uniprot": "A0A044UEW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEW6"
  },
  {
    "uniprot": "A0A044R6F6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6F6"
  },
  {
    "uniprot": "A0A044RBD4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBD4"
  },
  {
    "uniprot": "A0A044UUX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUX7"
  },
  {
    "uniprot": "A0A2K6VMT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMT2"
  },
  {
    "uniprot": "A0A044UEL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEL0"
  },
  {
    "uniprot": "A0A044SCP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCP8"
  },
  {
    "uniprot": "A0A044V2G6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2G6"
  },
  {
    "uniprot": "A0A044SW50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SW50"
  },
  {
    "uniprot": "A0A044ULC7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULC7"
  },
  {
    "uniprot": "A0A2K6VRC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRC3"
  },
  {
    "uniprot": "A0A044SN18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN18"
  },
  {
    "uniprot": "A0A044SU97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU97"
  },
  {
    "uniprot": "A0A044VHR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHR0"
  },
  {
    "uniprot": "A0A2K6WAE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAE9"
  },
  {
    "uniprot": "A0A044RLT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLT5"
  },
  {
    "uniprot": "A0A044S3V7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3V7"
  },
  {
    "uniprot": "A0A044R6N8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6N8"
  },
  {
    "uniprot": "A0A044SDY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDY4"
  },
  {
    "uniprot": "A0A044R831",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R831"
  },
  {
    "uniprot": "A0A044T8A8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8A8"
  },
  {
    "uniprot": "A0A044TAB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAB4"
  },
  {
    "uniprot": "A0A044R0M0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0M0"
  },
  {
    "uniprot": "A0A044S5C2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5C2"
  },
  {
    "uniprot": "A0A044V481",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V481"
  },
  {
    "uniprot": "A0A044RU97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU97"
  },
  {
    "uniprot": "A0A044U023",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U023"
  },
  {
    "uniprot": "A0A044RYC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYC2"
  },
  {
    "uniprot": "A0A044V734",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V734"
  },
  {
    "uniprot": "A0A044VD82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD82"
  },
  {
    "uniprot": "A0A044SWG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWG8"
  },
  {
    "uniprot": "A0A044SNZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNZ2"
  },
  {
    "uniprot": "A0A2K6VLM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLM3"
  },
  {
    "uniprot": "A0A044SYQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYQ6"
  },
  {
    "uniprot": "A0A044SX33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SX33"
  },
  {
    "uniprot": "A0A2K6VN15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VN15"
  },
  {
    "uniprot": "A0A044SPG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPG6"
  },
  {
    "uniprot": "A0A044U3U8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3U8"
  },
  {
    "uniprot": "A0A044UVA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVA9"
  },
  {
    "uniprot": "A0A044UY23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UY23"
  },
  {
    "uniprot": "A0A044TPX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPX9"
  },
  {
    "uniprot": "A0A044RRM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRM8"
  },
  {
    "uniprot": "A0A044U5R4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5R4"
  },
  {
    "uniprot": "A0A2K6WE13",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WE13"
  },
  {
    "uniprot": "A0A044T163",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T163"
  },
  {
    "uniprot": "A0A044UCE8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCE8"
  },
  {
    "uniprot": "A0A2K6WGL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGL6"
  },
  {
    "uniprot": "A0A044TI97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TI97"
  },
  {
    "uniprot": "A0A044TAE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAE2"
  },
  {
    "uniprot": "A0A044UZ65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ65"
  },
  {
    "uniprot": "A0A2K6WN68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WN68"
  },
  {
    "uniprot": "A0A044TAN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAN6"
  },
  {
    "uniprot": "A0A044STA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STA6"
  },
  {
    "uniprot": "A0A044QQM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQM7"
  },
  {
    "uniprot": "A0A2K6W2Z8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2Z8"
  },
  {
    "uniprot": "A0A044T2Y9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2Y9"
  },
  {
    "uniprot": "A0A044T0R0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0R0"
  },
  {
    "uniprot": "A0A044R0N6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0N6"
  },
  {
    "uniprot": "A0A044T714",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T714"
  },
  {
    "uniprot": "A0A2K6WE82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WE82"
  },
  {
    "uniprot": "A0A044VBH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBH2"
  },
  {
    "uniprot": "A0A044QYD2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYD2"
  },
  {
    "uniprot": "A0A2K6VTT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VTT7"
  },
  {
    "uniprot": "A0A2K6WJL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJL3"
  },
  {
    "uniprot": "A0A2K6VRP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRP2"
  },
  {
    "uniprot": "A0A044UPQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPQ4"
  },
  {
    "uniprot": "A0A044QMU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMU1"
  },
  {
    "uniprot": "A0A044VIW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIW9"
  },
  {
    "uniprot": "A0A044TBG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBG0"
  },
  {
    "uniprot": "A0A2K6W470",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W470"
  },
  {
    "uniprot": "A0A2K6W2E6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2E6"
  },
  {
    "uniprot": "A0A044SFS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFS7"
  },
  {
    "uniprot": "A0A044QWW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWW9"
  },
  {
    "uniprot": "A0A2K6WAS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAS3"
  },
  {
    "uniprot": "A0A044V9S0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9S0"
  },
  {
    "uniprot": "A0A044V4E0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4E0"
  },
  {
    "uniprot": "A0A044SJ51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJ51"
  },
  {
    "uniprot": "A0A044TIR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIR0"
  },
  {
    "uniprot": "A0A044SCM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCM6"
  },
  {
    "uniprot": "A0A044S517",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S517"
  },
  {
    "uniprot": "A0A044T1D9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1D9"
  },
  {
    "uniprot": "A0A044SWX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWX6"
  },
  {
    "uniprot": "A0A2K6VNB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VNB6"
  },
  {
    "uniprot": "A0A044TVA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVA1"
  },
  {
    "uniprot": "A0A044V0N8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0N8"
  },
  {
    "uniprot": "A0A044U859",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U859"
  },
  {
    "uniprot": "A0A044U6Q7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6Q7"
  },
  {
    "uniprot": "A0A2K6VQ13",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQ13"
  },
  {
    "uniprot": "A0A044UJW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJW8"
  },
  {
    "uniprot": "A0A044QQ57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQ57"
  },
  {
    "uniprot": "A0A044V4T9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4T9"
  },
  {
    "uniprot": "A0A044UXT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXT5"
  },
  {
    "uniprot": "A0A044SJJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJJ9"
  },
  {
    "uniprot": "A0A044S9M6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9M6"
  },
  {
    "uniprot": "A0A044UGP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGP2"
  },
  {
    "uniprot": "A0A044TMX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMX7"
  },
  {
    "uniprot": "A0A044SGW1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGW1"
  },
  {
    "uniprot": "A0A044SHJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHJ9"
  },
  {
    "uniprot": "A0A044VDB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDB0"
  },
  {
    "uniprot": "A0A044SJT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJT9"
  },
  {
    "uniprot": "A0A044QWH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWH3"
  },
  {
    "uniprot": "A0A044RY32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY32"
  },
  {
    "uniprot": "A0A044UAL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAL0"
  },
  {
    "uniprot": "A0A2K6VN16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VN16"
  },
  {
    "uniprot": "A0A044QU39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QU39"
  },
  {
    "uniprot": "A0A044V166",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V166"
  },
  {
    "uniprot": "A0A044VJG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJG1"
  },
  {
    "uniprot": "A0A2K6WEY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEY4"
  },
  {
    "uniprot": "A0A044UKP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKP8"
  },
  {
    "uniprot": "A0A044RHI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHI0"
  },
  {
    "uniprot": "A0A044SAJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAJ1"
  },
  {
    "uniprot": "A0A044RYQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYQ3"
  },
  {
    "uniprot": "A0A044SIY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIY2"
  },
  {
    "uniprot": "A0A044UA04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA04"
  },
  {
    "uniprot": "A0A044SV88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SV88"
  },
  {
    "uniprot": "A0A2K6VRU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRU3"
  },
  {
    "uniprot": "A0A2K6WMD2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMD2"
  },
  {
    "uniprot": "A0A044S1A5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1A5"
  },
  {
    "uniprot": "A0A2K6WID9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WID9"
  },
  {
    "uniprot": "A0A044SSG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSG9"
  },
  {
    "uniprot": "A0A2K6WGQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGQ1"
  },
  {
    "uniprot": "A0A044V411",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V411"
  },
  {
    "uniprot": "A0A044VD07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD07"
  },
  {
    "uniprot": "A0A044TEA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEA1"
  },
  {
    "uniprot": "A0A044V9C2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9C2"
  },
  {
    "uniprot": "A0A044V6Y4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Y4"
  },
  {
    "uniprot": "A0A044TXY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXY9"
  },
  {
    "uniprot": "A0A044SLQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLQ4"
  },
  {
    "uniprot": "A0A044RBC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBC6"
  },
  {
    "uniprot": "A0A044TBK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBK9"
  },
  {
    "uniprot": "A0A044SLN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLN2"
  },
  {
    "uniprot": "A0A044TV22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV22"
  },
  {
    "uniprot": "A0A044S545",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S545"
  },
  {
    "uniprot": "A0A044V9U1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9U1"
  },
  {
    "uniprot": "A0A044RWC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWC3"
  },
  {
    "uniprot": "A0A044QWY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWY3"
  },
  {
    "uniprot": "A0A2K6WAV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAV6"
  },
  {
    "uniprot": "A0A044QU07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QU07"
  },
  {
    "uniprot": "A0A044V711",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V711"
  },
  {
    "uniprot": "A0A044VGU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGU6"
  },
  {
    "uniprot": "A0A044SLM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLM2"
  },
  {
    "uniprot": "A0A044QUE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUE3"
  },
  {
    "uniprot": "A0A044UZ27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ27"
  },
  {
    "uniprot": "A0A044VD00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD00"
  },
  {
    "uniprot": "A0A044S1V8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1V8"
  },
  {
    "uniprot": "A0A044S2D3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2D3"
  },
  {
    "uniprot": "A0A044RYU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYU6"
  },
  {
    "uniprot": "A0A044TR09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR09"
  },
  {
    "uniprot": "A0A044SXB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXB3"
  },
  {
    "uniprot": "A0A158N8M2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8M2"
  },
  {
    "uniprot": "A0A044QT54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT54"
  },
  {
    "uniprot": "A0A044SPC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPC1"
  },
  {
    "uniprot": "A0A044SKR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKR7"
  },
  {
    "uniprot": "A0A044R2C0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2C0"
  },
  {
    "uniprot": "A0A044V175",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V175"
  },
  {
    "uniprot": "A0A044V721",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V721"
  },
  {
    "uniprot": "A0A2K6VK55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VK55"
  },
  {
    "uniprot": "A0A044RJY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJY2"
  },
  {
    "uniprot": "A0A044TG67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TG67"
  },
  {
    "uniprot": "A0A044U748",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U748"
  },
  {
    "uniprot": "A0A044TPK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPK6"
  },
  {
    "uniprot": "A0A044SB91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB91"
  },
  {
    "uniprot": "A0A2K6VLS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLS9"
  },
  {
    "uniprot": "A0A044SEF7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEF7"
  },
  {
    "uniprot": "A0A044UVD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVD8"
  },
  {
    "uniprot": "A0A2K6VDW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDW0"
  },
  {
    "uniprot": "A0A044UB88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UB88"
  },
  {
    "uniprot": "A0A044UCP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCP9"
  },
  {
    "uniprot": "A0A044RE53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RE53"
  },
  {
    "uniprot": "A0A044S441",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S441"
  },
  {
    "uniprot": "A0A044UIA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIA4"
  },
  {
    "uniprot": "A0A044UTS8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTS8"
  },
  {
    "uniprot": "A0A158N855",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N855"
  },
  {
    "uniprot": "A0A044TKU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKU0"
  },
  {
    "uniprot": "A0A044QWY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWY9"
  },
  {
    "uniprot": "A0A044QTS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTS6"
  },
  {
    "uniprot": "A0A044V7H1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7H1"
  },
  {
    "uniprot": "A0A158N829",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N829"
  },
  {
    "uniprot": "A0A044QQQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQQ4"
  },
  {
    "uniprot": "A0A044TRV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRV4"
  },
  {
    "uniprot": "A0A2K6W2M0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2M0"
  },
  {
    "uniprot": "A0A044UVK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVK5"
  },
  {
    "uniprot": "A0A044VHE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHE3"
  },
  {
    "uniprot": "A0A044UGY1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGY1"
  },
  {
    "uniprot": "A0A044SB29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB29"
  },
  {
    "uniprot": "A0A044V419",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V419"
  },
  {
    "uniprot": "A0A044RW74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW74"
  },
  {
    "uniprot": "A0A044S5Y8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5Y8"
  },
  {
    "uniprot": "A0A2K6VLE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLE7"
  },
  {
    "uniprot": "A0A044QME6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QME6"
  },
  {
    "uniprot": "A0A044SJ06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJ06"
  },
  {
    "uniprot": "A0A044TJG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJG4"
  },
  {
    "uniprot": "A0A044V6K1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6K1"
  },
  {
    "uniprot": "A0A044SL60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SL60"
  },
  {
    "uniprot": "A0A044UNT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNT9"
  },
  {
    "uniprot": "A0A044RL53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RL53"
  },
  {
    "uniprot": "A0A044SA07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SA07"
  },
  {
    "uniprot": "A0A044TPL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPL2"
  },
  {
    "uniprot": "A0A044R0F1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0F1"
  },
  {
    "uniprot": "A0A044TB31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TB31"
  },
  {
    "uniprot": "A0A2K6WF64",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WF64"
  },
  {
    "uniprot": "A0A044SND9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SND9"
  },
  {
    "uniprot": "A0A044SZA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZA6"
  },
  {
    "uniprot": "A0A2K6VDQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDQ4"
  },
  {
    "uniprot": "A0A044V9L4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9L4"
  },
  {
    "uniprot": "A0A044QMM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMM0"
  },
  {
    "uniprot": "A0A2K6W2R5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2R5"
  },
  {
    "uniprot": "A0A044UXL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXL1"
  },
  {
    "uniprot": "A0A044RZL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZL4"
  },
  {
    "uniprot": "A0A044RMI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMI3"
  },
  {
    "uniprot": "A0A044R9Y0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9Y0"
  },
  {
    "uniprot": "A0A044UBM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBM3"
  },
  {
    "uniprot": "A0A044T9X9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9X9"
  },
  {
    "uniprot": "A0A044U7V1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7V1"
  },
  {
    "uniprot": "A0A044S169",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S169"
  },
  {
    "uniprot": "A0A044S6H9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6H9"
  },
  {
    "uniprot": "A0A044RRI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRI8"
  },
  {
    "uniprot": "A0A044V153",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V153"
  },
  {
    "uniprot": "A0A044SBT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBT5"
  },
  {
    "uniprot": "A0A044US19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044US19"
  },
  {
    "uniprot": "A0A044U4D5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4D5"
  },
  {
    "uniprot": "A0A044SBY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBY6"
  },
  {
    "uniprot": "A0A2K6WD08",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WD08"
  },
  {
    "uniprot": "A0A044V3F6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3F6"
  },
  {
    "uniprot": "A0A044R8S0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8S0"
  },
  {
    "uniprot": "A0A044T503",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T503"
  },
  {
    "uniprot": "A0A044QKW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKW9"
  },
  {
    "uniprot": "A0A044R764",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R764"
  },
  {
    "uniprot": "A0A044RWB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWB3"
  },
  {
    "uniprot": "A0A2K6VY02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VY02"
  },
  {
    "uniprot": "A0A044TEX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEX1"
  },
  {
    "uniprot": "A0A044THG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THG2"
  },
  {
    "uniprot": "A0A044TMV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMV8"
  },
  {
    "uniprot": "A0A044UDL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDL1"
  },
  {
    "uniprot": "A0A044SY43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY43"
  },
  {
    "uniprot": "A0A044TPN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPN1"
  },
  {
    "uniprot": "A0A044RLQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLQ1"
  },
  {
    "uniprot": "A0A044RN72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RN72"
  },
  {
    "uniprot": "A0A044TY84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TY84"
  },
  {
    "uniprot": "A0A044ULN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULN3"
  },
  {
    "uniprot": "A0A158N857",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N857"
  },
  {
    "uniprot": "A0A044R6G1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6G1"
  },
  {
    "uniprot": "A0A2K6W1X5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1X5"
  },
  {
    "uniprot": "A0A044T642",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T642"
  },
  {
    "uniprot": "A0A044TW96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TW96"
  },
  {
    "uniprot": "A0A044RMZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMZ3"
  },
  {
    "uniprot": "A0A044SZY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZY4"
  },
  {
    "uniprot": "A0A044TTR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTR7"
  },
  {
    "uniprot": "A0A044V544",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V544"
  },
  {
    "uniprot": "A0A044V7T8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7T8"
  },
  {
    "uniprot": "A0A044TTL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTL7"
  },
  {
    "uniprot": "A0A044V952",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V952"
  },
  {
    "uniprot": "A0A044V4D9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4D9"
  },
  {
    "uniprot": "A0A2K6VGP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VGP7"
  },
  {
    "uniprot": "A0A044QTY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTY9"
  },
  {
    "uniprot": "A0A044RM49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RM49"
  },
  {
    "uniprot": "A0A044V9A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9A9"
  },
  {
    "uniprot": "A0A044U041",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U041"
  },
  {
    "uniprot": "A0A044V0V1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0V1"
  },
  {
    "uniprot": "A0A044TEH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEH1"
  },
  {
    "uniprot": "A0A044QN91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QN91"
  },
  {
    "uniprot": "A0A2K6WJN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJN4"
  },
  {
    "uniprot": "A0A044TD59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TD59"
  },
  {
    "uniprot": "A0A044V9A6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9A6"
  },
  {
    "uniprot": "A0A044RJG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJG2"
  },
  {
    "uniprot": "A0A044U2F9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2F9"
  },
  {
    "uniprot": "A0A044SEA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEA3"
  },
  {
    "uniprot": "A0A044V212",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V212"
  },
  {
    "uniprot": "A0A044V9X1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9X1"
  },
  {
    "uniprot": "A0A044SX34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SX34"
  },
  {
    "uniprot": "A0A044V2C7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2C7"
  },
  {
    "uniprot": "A0A2K6VYY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYY5"
  },
  {
    "uniprot": "A0A044QSU2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSU2"
  },
  {
    "uniprot": "A0A044S6J5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6J5"
  },
  {
    "uniprot": "A0A044VB34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VB34"
  },
  {
    "uniprot": "A0A044R8F3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8F3"
  },
  {
    "uniprot": "A0A044RX61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RX61"
  },
  {
    "uniprot": "A0A044SY31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY31"
  },
  {
    "uniprot": "A0A044TSY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSY0"
  },
  {
    "uniprot": "A0A044RMB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMB6"
  },
  {
    "uniprot": "A0A044S1L6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1L6"
  },
  {
    "uniprot": "A0A044SQR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQR0"
  },
  {
    "uniprot": "A0A044TT32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TT32"
  },
  {
    "uniprot": "A0A044STS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STS0"
  },
  {
    "uniprot": "A0A044T5H2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5H2"
  },
  {
    "uniprot": "A0A2K6WDW1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDW1"
  },
  {
    "uniprot": "A0A044SU60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU60"
  },
  {
    "uniprot": "A0A044T4P7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4P7"
  },
  {
    "uniprot": "A0A044U366",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U366"
  },
  {
    "uniprot": "A0A044TS24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS24"
  },
  {
    "uniprot": "A0A044TZQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZQ5"
  },
  {
    "uniprot": "A0A044QXN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXN6"
  },
  {
    "uniprot": "A0A044U928",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U928"
  },
  {
    "uniprot": "A0A044TBP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBP3"
  },
  {
    "uniprot": "O18532",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/O18532"
  },
  {
    "uniprot": "A0A044QK91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK91"
  },
  {
    "uniprot": "A0A044R4B8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4B8"
  },
  {
    "uniprot": "A0A044UPY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPY3"
  },
  {
    "uniprot": "A0A044SCS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCS9"
  },
  {
    "uniprot": "A0A044TAG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAG9"
  },
  {
    "uniprot": "A0A044TDN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDN4"
  },
  {
    "uniprot": "A0A044TEV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEV6"
  },
  {
    "uniprot": "Q963F7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q963F7"
  },
  {
    "uniprot": "A0A044V994",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V994"
  },
  {
    "uniprot": "A0A044UYZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYZ4"
  },
  {
    "uniprot": "A0A044SZB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZB3"
  },
  {
    "uniprot": "A0A044V1Z2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1Z2"
  },
  {
    "uniprot": "A0A044TSA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSA4"
  },
  {
    "uniprot": "A0A044V178",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V178"
  },
  {
    "uniprot": "A0A044SA38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SA38"
  },
  {
    "uniprot": "A0A2K6VLI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLI9"
  },
  {
    "uniprot": "A0A044UQV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQV0"
  },
  {
    "uniprot": "A0A044V9G7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9G7"
  },
  {
    "uniprot": "A0A2K6WL65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WL65"
  },
  {
    "uniprot": "A0A2K6VNG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VNG4"
  },
  {
    "uniprot": "A0A044TZU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZU3"
  },
  {
    "uniprot": "A0A044RLG3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLG3"
  },
  {
    "uniprot": "A0A044TWE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWE0"
  },
  {
    "uniprot": "A0A044R5D8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5D8"
  },
  {
    "uniprot": "A0A044QV88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QV88"
  },
  {
    "uniprot": "A0A044QP96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP96"
  },
  {
    "uniprot": "A0A044URF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URF2"
  },
  {
    "uniprot": "A0A044TAC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAC5"
  },
  {
    "uniprot": "A0A044V133",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V133"
  },
  {
    "uniprot": "A0A2K6VQX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQX6"
  },
  {
    "uniprot": "A0A044RIU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIU9"
  },
  {
    "uniprot": "A0A044UKE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKE6"
  },
  {
    "uniprot": "A0A044SJ94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJ94"
  },
  {
    "uniprot": "A0A2K6VLR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLR2"
  },
  {
    "uniprot": "A0A044VIX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIX7"
  },
  {
    "uniprot": "A0A044TVD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVD6"
  },
  {
    "uniprot": "A0A044UU27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UU27"
  },
  {
    "uniprot": "A0A044TGB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGB6"
  },
  {
    "uniprot": "A0A044SU24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU24"
  },
  {
    "uniprot": "A0A044U183",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U183"
  },
  {
    "uniprot": "A0A044T5J7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5J7"
  },
  {
    "uniprot": "A0A044STY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STY3"
  },
  {
    "uniprot": "A0A044RLJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLJ3"
  },
  {
    "uniprot": "A0A044TTP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTP5"
  },
  {
    "uniprot": "A0A044VBM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBM7"
  },
  {
    "uniprot": "A0A044TAT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAT4"
  },
  {
    "uniprot": "A0A044RYB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYB5"
  },
  {
    "uniprot": "A0A044SJ01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJ01"
  },
  {
    "uniprot": "A0A044V7E0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7E0"
  },
  {
    "uniprot": "A0A044RZR5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZR5"
  },
  {
    "uniprot": "A0A044TE30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TE30"
  },
  {
    "uniprot": "A0A044UFT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFT9"
  },
  {
    "uniprot": "A0A044SWF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWF8"
  },
  {
    "uniprot": "A0A044S445",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S445"
  },
  {
    "uniprot": "A0A044RM96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RM96"
  },
  {
    "uniprot": "A0A044SE03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SE03"
  },
  {
    "uniprot": "A0A044V731",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V731"
  },
  {
    "uniprot": "A0A044SZ86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ86"
  },
  {
    "uniprot": "A0A044TIU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIU1"
  },
  {
    "uniprot": "A0A044RVS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVS2"
  },
  {
    "uniprot": "A0A044RDN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDN4"
  },
  {
    "uniprot": "A0A044R2A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2A9"
  },
  {
    "uniprot": "A0A2K6WF56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WF56"
  },
  {
    "uniprot": "A0A044UHS8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHS8"
  },
  {
    "uniprot": "A0A044TPP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPP4"
  },
  {
    "uniprot": "A0A044SUX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUX1"
  },
  {
    "uniprot": "A0A044TLC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLC9"
  },
  {
    "uniprot": "A0A044UPD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPD8"
  },
  {
    "uniprot": "A0A044SXT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXT1"
  },
  {
    "uniprot": "A0A044RQM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQM5"
  },
  {
    "uniprot": "A0A044U4U1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4U1"
  },
  {
    "uniprot": "A0A044UYZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYZ9"
  },
  {
    "uniprot": "A0A044RJM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJM2"
  },
  {
    "uniprot": "A0A044SY36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY36"
  },
  {
    "uniprot": "A0A044TZK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZK3"
  },
  {
    "uniprot": "A0A044V182",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V182"
  },
  {
    "uniprot": "A0A044SVI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVI0"
  },
  {
    "uniprot": "A0A044VFF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFF3"
  },
  {
    "uniprot": "A0A044UMZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMZ7"
  },
  {
    "uniprot": "A0A044V795",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V795"
  },
  {
    "uniprot": "A0A044V7V2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7V2"
  },
  {
    "uniprot": "A0A044SI09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI09"
  },
  {
    "uniprot": "A0A044V329",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V329"
  },
  {
    "uniprot": "A0A044RMF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMF5"
  },
  {
    "uniprot": "A0A044RXF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXF1"
  },
  {
    "uniprot": "A0A044VI60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VI60"
  },
  {
    "uniprot": "A0A044V142",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V142"
  },
  {
    "uniprot": "A0A044UWC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWC0"
  },
  {
    "uniprot": "A0A044RX04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RX04"
  },
  {
    "uniprot": "A0A044TRK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRK2"
  },
  {
    "uniprot": "A0A044S6K4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6K4"
  },
  {
    "uniprot": "A0A044TMI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMI9"
  },
  {
    "uniprot": "A0A044RAL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAL6"
  },
  {
    "uniprot": "A0A044UFD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFD5"
  },
  {
    "uniprot": "A0A044SIT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIT6"
  },
  {
    "uniprot": "A0A044RN68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RN68"
  },
  {
    "uniprot": "A0A044TIV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIV2"
  },
  {
    "uniprot": "A0A044RWX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWX4"
  },
  {
    "uniprot": "A0A044TIQ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIQ8"
  },
  {
    "uniprot": "A0A2K6WLR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLR7"
  },
  {
    "uniprot": "A0A044SSB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSB1"
  },
  {
    "uniprot": "A0A044R2B2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2B2"
  },
  {
    "uniprot": "A0A044UIK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIK0"
  },
  {
    "uniprot": "A0A2K6W8C3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8C3"
  },
  {
    "uniprot": "A0A2K6WIU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIU9"
  },
  {
    "uniprot": "A0A044TT21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TT21"
  },
  {
    "uniprot": "A0A044UYI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYI3"
  },
  {
    "uniprot": "A0A044QN35",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QN35"
  },
  {
    "uniprot": "A0A044TRI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRI9"
  },
  {
    "uniprot": "A0A044SZ27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ27"
  },
  {
    "uniprot": "A0A044TYL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYL7"
  },
  {
    "uniprot": "A0A044QJM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJM2"
  },
  {
    "uniprot": "A0A044RZR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZR6"
  },
  {
    "uniprot": "A0A2K6VHR5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHR5"
  },
  {
    "uniprot": "A0A2K6VY04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VY04"
  },
  {
    "uniprot": "A0A044V187",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V187"
  },
  {
    "uniprot": "A0A044V975",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V975"
  },
  {
    "uniprot": "A0A044QUH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUH5"
  },
  {
    "uniprot": "A0A044T9Z8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9Z8"
  },
  {
    "uniprot": "A0A044U623",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U623"
  },
  {
    "uniprot": "A0A044V8L4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8L4"
  },
  {
    "uniprot": "A0A2K6VJU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJU6"
  },
  {
    "uniprot": "A0A044SYS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYS9"
  },
  {
    "uniprot": "A0A044UUT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUT1"
  },
  {
    "uniprot": "A0A044QVK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVK1"
  },
  {
    "uniprot": "A0A044UJT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJT1"
  },
  {
    "uniprot": "A0A044SMZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMZ8"
  },
  {
    "uniprot": "A0A044TNI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNI4"
  },
  {
    "uniprot": "A0A044RKJ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKJ0"
  },
  {
    "uniprot": "A0A044V9X0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9X0"
  },
  {
    "uniprot": "A0A044QW58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW58"
  },
  {
    "uniprot": "A0A044R5V9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5V9"
  },
  {
    "uniprot": "A0A2K6VTF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VTF0"
  },
  {
    "uniprot": "A0A2K6W1T3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1T3"
  },
  {
    "uniprot": "A0A044TZK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZK9"
  },
  {
    "uniprot": "A0A044TIN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIN5"
  },
  {
    "uniprot": "A0A044QNI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNI0"
  },
  {
    "uniprot": "A0A044V1M0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1M0"
  },
  {
    "uniprot": "A0A044V1Q9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1Q9"
  },
  {
    "uniprot": "A0A044UZ32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ32"
  },
  {
    "uniprot": "A0A044V6L5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6L5"
  },
  {
    "uniprot": "A0A044TFT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFT6"
  },
  {
    "uniprot": "A0A044UUX0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUX0"
  },
  {
    "uniprot": "A0A044UNX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNX6"
  },
  {
    "uniprot": "A0A044UK30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UK30"
  },
  {
    "uniprot": "A0A044QRF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRF6"
  },
  {
    "uniprot": "A0A044SRA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRA6"
  },
  {
    "uniprot": "A0A044T492",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T492"
  },
  {
    "uniprot": "A0A044THE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THE4"
  },
  {
    "uniprot": "A0A044V7A0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7A0"
  },
  {
    "uniprot": "A0A044UCR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCR7"
  },
  {
    "uniprot": "A0A044VCD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCD6"
  },
  {
    "uniprot": "A0A044U7H2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7H2"
  },
  {
    "uniprot": "A0A044U429",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U429"
  },
  {
    "uniprot": "A0A044QSW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSW9"
  },
  {
    "uniprot": "A0A044TLZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLZ4"
  },
  {
    "uniprot": "A0A044SDK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDK0"
  },
  {
    "uniprot": "A0A044T3E2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3E2"
  },
  {
    "uniprot": "A0A2K6WF36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WF36"
  },
  {
    "uniprot": "A0A2K6VF54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VF54"
  },
  {
    "uniprot": "A0A158N8P4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8P4"
  },
  {
    "uniprot": "A0A044UYL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYL4"
  },
  {
    "uniprot": "A0A044VGL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGL1"
  },
  {
    "uniprot": "A0A044SNR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNR0"
  },
  {
    "uniprot": "A0A044SQS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQS6"
  },
  {
    "uniprot": "A0A044T3F0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3F0"
  },
  {
    "uniprot": "A0A044V2H1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2H1"
  },
  {
    "uniprot": "A0A044UAU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAU4"
  },
  {
    "uniprot": "A0A044UPT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPT6"
  },
  {
    "uniprot": "A0A2K6VDT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDT2"
  },
  {
    "uniprot": "A0A044USP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USP0"
  },
  {
    "uniprot": "A0A044SXN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXN2"
  },
  {
    "uniprot": "A0A044V498",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V498"
  },
  {
    "uniprot": "A0A044TF01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TF01"
  },
  {
    "uniprot": "A0A044UW04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UW04"
  },
  {
    "uniprot": "A0A044SK28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK28"
  },
  {
    "uniprot": "A0A044TPC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPC2"
  },
  {
    "uniprot": "A0A044V356",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V356"
  },
  {
    "uniprot": "A0A044TL79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TL79"
  },
  {
    "uniprot": "A0A044QL85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL85"
  },
  {
    "uniprot": "Q9GPN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q9GPN4"
  },
  {
    "uniprot": "A0A044V1B9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1B9"
  },
  {
    "uniprot": "A0A044URI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URI8"
  },
  {
    "uniprot": "A0A044R4B1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4B1"
  },
  {
    "uniprot": "A0A044V129",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V129"
  },
  {
    "uniprot": "A0A2K6VHU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHU9"
  },
  {
    "uniprot": "A0A044SEN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEN6"
  },
  {
    "uniprot": "A0A158N7U8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7U8"
  },
  {
    "uniprot": "A0A044R7F2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7F2"
  },
  {
    "uniprot": "A0A044QU31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QU31"
  },
  {
    "uniprot": "A0A2K6WI18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WI18"
  },
  {
    "uniprot": "A0A044VC36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC36"
  },
  {
    "uniprot": "A0A044VHQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHQ4"
  },
  {
    "uniprot": "A0A044V4E8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4E8"
  },
  {
    "uniprot": "A0A044RAV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAV7"
  },
  {
    "uniprot": "A0A044S9V2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9V2"
  },
  {
    "uniprot": "A0A044S3K3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3K3"
  },
  {
    "uniprot": "A0A044TS90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS90"
  },
  {
    "uniprot": "A0A2K6VM59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VM59"
  },
  {
    "uniprot": "A0A2K6VSK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSK2"
  },
  {
    "uniprot": "A0A044U4Y2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4Y2"
  },
  {
    "uniprot": "A0A044VAS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAS9"
  },
  {
    "uniprot": "A0A044UX86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX86"
  },
  {
    "uniprot": "A0A044TFN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFN6"
  },
  {
    "uniprot": "A0A044RQV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQV0"
  },
  {
    "uniprot": "A0A044V4I5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4I5"
  },
  {
    "uniprot": "A0A044VA68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA68"
  },
  {
    "uniprot": "A0A044UD31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UD31"
  },
  {
    "uniprot": "A0A044VID9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VID9"
  },
  {
    "uniprot": "A0A044S023",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S023"
  },
  {
    "uniprot": "A0A044TS84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS84"
  },
  {
    "uniprot": "A0A044R9U0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9U0"
  },
  {
    "uniprot": "A0A044UZ60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ60"
  },
  {
    "uniprot": "A0A044TCB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCB9"
  },
  {
    "uniprot": "A0A044SBL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBL4"
  },
  {
    "uniprot": "A0A044SX81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SX81"
  },
  {
    "uniprot": "A0A044VEP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEP2"
  },
  {
    "uniprot": "A0A044TQ59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQ59"
  },
  {
    "uniprot": "A0A044UY65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UY65"
  },
  {
    "uniprot": "A0A044TJD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJD6"
  },
  {
    "uniprot": "A0A044QMZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMZ3"
  },
  {
    "uniprot": "A0A044V1A2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1A2"
  },
  {
    "uniprot": "A0A044TLQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLQ7"
  },
  {
    "uniprot": "A0A044V6D5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6D5"
  },
  {
    "uniprot": "A0A2K6WG84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WG84"
  },
  {
    "uniprot": "A0A044RMP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMP9"
  },
  {
    "uniprot": "A0A044TD96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TD96"
  },
  {
    "uniprot": "A0A044RPQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPQ3"
  },
  {
    "uniprot": "A0A2K6VSE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSE3"
  },
  {
    "uniprot": "A0A044RI98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RI98"
  },
  {
    "uniprot": "A0A044VAX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAX2"
  },
  {
    "uniprot": "Q1I1D5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q1I1D5"
  },
  {
    "uniprot": "A0A044T059",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T059"
  },
  {
    "uniprot": "A0A044V4F1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4F1"
  },
  {
    "uniprot": "A0A044TJ85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJ85"
  },
  {
    "uniprot": "A0A2K6VYS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYS3"
  },
  {
    "uniprot": "A0A044U4F7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4F7"
  },
  {
    "uniprot": "A0A044QPK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPK1"
  },
  {
    "uniprot": "A0A044RWW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWW9"
  },
  {
    "uniprot": "A0A044RGW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGW9"
  },
  {
    "uniprot": "A0A044T8U2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8U2"
  },
  {
    "uniprot": "A0A044R044",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R044"
  },
  {
    "uniprot": "A0A2K6WI56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WI56"
  },
  {
    "uniprot": "A0A044THW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THW2"
  },
  {
    "uniprot": "A0A044RC46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC46"
  },
  {
    "uniprot": "A0A044RMS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMS4"
  },
  {
    "uniprot": "A0A044S5Z6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5Z6"
  },
  {
    "uniprot": "A0A044RHT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHT1"
  },
  {
    "uniprot": "A0A044RL84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RL84"
  },
  {
    "uniprot": "A0A044SU10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU10"
  },
  {
    "uniprot": "A0A044TYT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYT9"
  },
  {
    "uniprot": "A0A044RL46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RL46"
  },
  {
    "uniprot": "A0A2K6VP09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VP09"
  },
  {
    "uniprot": "A0A044TWR9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWR9"
  },
  {
    "uniprot": "A0A044SZ96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ96"
  },
  {
    "uniprot": "A0A044URL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URL2"
  },
  {
    "uniprot": "A0A044UFG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFG5"
  },
  {
    "uniprot": "A0A2K6VKJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKJ7"
  },
  {
    "uniprot": "A0A044QYM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYM1"
  },
  {
    "uniprot": "A0A044U6T0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6T0"
  },
  {
    "uniprot": "A0A044TPY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPY5"
  },
  {
    "uniprot": "A0A044TIM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIM5"
  },
  {
    "uniprot": "A0A044V4D3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4D3"
  },
  {
    "uniprot": "A0A044V9S3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9S3"
  },
  {
    "uniprot": "A0A044SB89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB89"
  },
  {
    "uniprot": "A0A044S6R1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6R1"
  },
  {
    "uniprot": "A0A2K6VPV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPV4"
  },
  {
    "uniprot": "A0A044QUH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUH0"
  },
  {
    "uniprot": "A0A044SAD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAD7"
  },
  {
    "uniprot": "A0A044RDQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDQ6"
  },
  {
    "uniprot": "A0A044T5M4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5M4"
  },
  {
    "uniprot": "A0A044RW09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW09"
  },
  {
    "uniprot": "A0A2K6W412",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W412"
  },
  {
    "uniprot": "A0A044UN25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UN25"
  },
  {
    "uniprot": "A0A044UFM9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFM9"
  },
  {
    "uniprot": "A0A158N870",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N870"
  },
  {
    "uniprot": "A0A044SJ16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJ16"
  },
  {
    "uniprot": "A0A044VEV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEV7"
  },
  {
    "uniprot": "A0A044S9J1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9J1"
  },
  {
    "uniprot": "A0A044RHK7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHK7"
  },
  {
    "uniprot": "A0A044T651",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T651"
  },
  {
    "uniprot": "A0A044SZE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZE2"
  },
  {
    "uniprot": "A0A044RWT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWT9"
  },
  {
    "uniprot": "A0A044R2B6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2B6"
  },
  {
    "uniprot": "A0A2K6WIM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIM2"
  },
  {
    "uniprot": "A0A044RZQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZQ4"
  },
  {
    "uniprot": "A0A044SBQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBQ5"
  },
  {
    "uniprot": "A0A044UYH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYH3"
  },
  {
    "uniprot": "A0A044UTH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTH3"
  },
  {
    "uniprot": "A0A044SM32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM32"
  },
  {
    "uniprot": "A0A044SZR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZR2"
  },
  {
    "uniprot": "A0A044S4Z6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4Z6"
  },
  {
    "uniprot": "A0A044UM25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UM25"
  },
  {
    "uniprot": "A0A2K6WKJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKJ1"
  },
  {
    "uniprot": "A0A044STY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STY4"
  },
  {
    "uniprot": "G8Z3I4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/G8Z3I4"
  },
  {
    "uniprot": "A0A044V0Q7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0Q7"
  },
  {
    "uniprot": "A0A044THH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THH7"
  },
  {
    "uniprot": "A0A044TGK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGK2"
  },
  {
    "uniprot": "A0A044RKY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKY0"
  },
  {
    "uniprot": "A0A2K6VG73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VG73"
  },
  {
    "uniprot": "A0A044V1M9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1M9"
  },
  {
    "uniprot": "A0A044UT43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT43"
  },
  {
    "uniprot": "A0A044TZP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZP5"
  },
  {
    "uniprot": "A0A044RH56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH56"
  },
  {
    "uniprot": "A0A044UYQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYQ6"
  },
  {
    "uniprot": "A0A044RGU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGU1"
  },
  {
    "uniprot": "A0A2K6WHZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WHZ9"
  },
  {
    "uniprot": "A0A044V732",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V732"
  },
  {
    "uniprot": "A0A044S0Z7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0Z7"
  },
  {
    "uniprot": "A0A2K6VY75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VY75"
  },
  {
    "uniprot": "A0A044VH58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH58"
  },
  {
    "uniprot": "A0A044V470",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V470"
  },
  {
    "uniprot": "A0A044QZG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZG7"
  },
  {
    "uniprot": "A0A044VHA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHA7"
  },
  {
    "uniprot": "A0A2K6WBX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBX4"
  },
  {
    "uniprot": "A0A044TZL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZL9"
  },
  {
    "uniprot": "A0A044RNL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNL1"
  },
  {
    "uniprot": "A0A044TUJ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUJ8"
  },
  {
    "uniprot": "A0A044R4Z6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4Z6"
  },
  {
    "uniprot": "A0A2K6VP33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VP33"
  },
  {
    "uniprot": "A0A044U309",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U309"
  },
  {
    "uniprot": "A0A044SKY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKY8"
  },
  {
    "uniprot": "A0A044S0H5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0H5"
  },
  {
    "uniprot": "A0A044UQH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQH9"
  },
  {
    "uniprot": "A0A044TLQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLQ5"
  },
  {
    "uniprot": "A0A044T8E8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8E8"
  },
  {
    "uniprot": "A0A2K6W815",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W815"
  },
  {
    "uniprot": "A0A044RP43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RP43"
  },
  {
    "uniprot": "A0A044V420",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V420"
  },
  {
    "uniprot": "A0A044TX11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TX11"
  },
  {
    "uniprot": "A0A044TC74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC74"
  },
  {
    "uniprot": "A0A044UC99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC99"
  },
  {
    "uniprot": "A0A044RPN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPN9"
  },
  {
    "uniprot": "A0A044RGH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGH6"
  },
  {
    "uniprot": "A0A2K6VE51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VE51"
  },
  {
    "uniprot": "A0A044SAN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAN4"
  },
  {
    "uniprot": "Q9NJD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q9NJD8"
  },
  {
    "uniprot": "A0A044VIS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIS6"
  },
  {
    "uniprot": "A0A044QM17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QM17"
  },
  {
    "uniprot": "A0A044R0P6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0P6"
  },
  {
    "uniprot": "A0A044TNY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNY2"
  },
  {
    "uniprot": "A0A044RZV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZV1"
  },
  {
    "uniprot": "A0A044UGI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGI6"
  },
  {
    "uniprot": "A0A044RGB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGB7"
  },
  {
    "uniprot": "A0A044QJJ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJJ0"
  },
  {
    "uniprot": "A0A2K6WHR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WHR8"
  },
  {
    "uniprot": "A0A2K6WJ54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJ54"
  },
  {
    "uniprot": "A0A044QRE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRE9"
  },
  {
    "uniprot": "A0A2K6WLI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLI4"
  },
  {
    "uniprot": "A0A044V5M3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5M3"
  },
  {
    "uniprot": "A0A044V5I7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5I7"
  },
  {
    "uniprot": "A0A044RT88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RT88"
  },
  {
    "uniprot": "A0A044U3D2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3D2"
  },
  {
    "uniprot": "A0A044SM80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM80"
  },
  {
    "uniprot": "A0A044QWA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWA5"
  },
  {
    "uniprot": "A0A044UTG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTG6"
  },
  {
    "uniprot": "A0A2K6WKL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKL3"
  },
  {
    "uniprot": "A0A044T2W7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2W7"
  },
  {
    "uniprot": "A0A044SY19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY19"
  },
  {
    "uniprot": "A0A044QV53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QV53"
  },
  {
    "uniprot": "A0A044RKL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKL3"
  },
  {
    "uniprot": "A0A044U9B8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9B8"
  },
  {
    "uniprot": "A0A044SEC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEC6"
  },
  {
    "uniprot": "A0A2K6VQH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQH4"
  },
  {
    "uniprot": "A0A044TDT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDT8"
  },
  {
    "uniprot": "A0A044RQ14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQ14"
  },
  {
    "uniprot": "A0A044VAR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAR0"
  },
  {
    "uniprot": "A0A044V450",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V450"
  },
  {
    "uniprot": "A0A044T5A5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5A5"
  },
  {
    "uniprot": "A0A044UE77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE77"
  },
  {
    "uniprot": "A0A044VAC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAC4"
  },
  {
    "uniprot": "A0A044SBJ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBJ2"
  },
  {
    "uniprot": "A0A044V9J0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9J0"
  },
  {
    "uniprot": "A0A044T9K7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9K7"
  },
  {
    "uniprot": "A0A044RPW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPW4"
  },
  {
    "uniprot": "A0A044U172",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U172"
  },
  {
    "uniprot": "A0A044SA18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SA18"
  },
  {
    "uniprot": "A0A044URX0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URX0"
  },
  {
    "uniprot": "A0A044RQU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQU0"
  },
  {
    "uniprot": "A0A044V387",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V387"
  },
  {
    "uniprot": "A0A044UF99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF99"
  },
  {
    "uniprot": "A0A044RCQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCQ6"
  },
  {
    "uniprot": "A0A044SUU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUU8"
  },
  {
    "uniprot": "A0A044VFG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFG4"
  },
  {
    "uniprot": "A0A2K6VN57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VN57"
  },
  {
    "uniprot": "A0A044UHZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHZ0"
  },
  {
    "uniprot": "A0A044SAZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAZ8"
  },
  {
    "uniprot": "A0A044T4U4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4U4"
  },
  {
    "uniprot": "A0A044TWM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWM4"
  },
  {
    "uniprot": "A0A044TKE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKE6"
  },
  {
    "uniprot": "A0A044V580",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V580"
  },
  {
    "uniprot": "A0A044U9W5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9W5"
  },
  {
    "uniprot": "A0A044R1Y3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1Y3"
  },
  {
    "uniprot": "A0A044VHU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHU9"
  },
  {
    "uniprot": "A0A044VD90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD90"
  },
  {
    "uniprot": "A0A044TEW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEW0"
  },
  {
    "uniprot": "A0A044SQ51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ51"
  },
  {
    "uniprot": "A0A044TPC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPC6"
  },
  {
    "uniprot": "A0A044UKP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKP1"
  },
  {
    "uniprot": "A0A2K6W2L3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2L3"
  },
  {
    "uniprot": "A0A044UG14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UG14"
  },
  {
    "uniprot": "A0A044QM06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QM06"
  },
  {
    "uniprot": "A0A044SWT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWT0"
  },
  {
    "uniprot": "A0A2K6VEM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VEM8"
  },
  {
    "uniprot": "A0A044RPJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPJ3"
  },
  {
    "uniprot": "A0A044RGT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGT0"
  },
  {
    "uniprot": "A0A044V121",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V121"
  },
  {
    "uniprot": "A0A044QU92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QU92"
  },
  {
    "uniprot": "A0A044TA51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TA51"
  },
  {
    "uniprot": "A0A044QVC7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVC7"
  },
  {
    "uniprot": "A0A044SZB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZB6"
  },
  {
    "uniprot": "A0A044STY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STY6"
  },
  {
    "uniprot": "A0A044TVL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVL7"
  },
  {
    "uniprot": "A0A044VHB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHB8"
  },
  {
    "uniprot": "A0A044SC46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SC46"
  },
  {
    "uniprot": "A0A044RJR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJR4"
  },
  {
    "uniprot": "A0A044UC07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC07"
  },
  {
    "uniprot": "A0A2K6W3L2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3L2"
  },
  {
    "uniprot": "A0A044V0X3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0X3"
  },
  {
    "uniprot": "A0A044RAT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAT2"
  },
  {
    "uniprot": "A0A044UCY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCY3"
  },
  {
    "uniprot": "A0A044SRE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRE5"
  },
  {
    "uniprot": "A0A044QL66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL66"
  },
  {
    "uniprot": "A0A044SXG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXG6"
  },
  {
    "uniprot": "A0A044UAV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAV2"
  },
  {
    "uniprot": "A0A044UGH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGH9"
  },
  {
    "uniprot": "A0A044SXH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXH6"
  },
  {
    "uniprot": "A0A044SUH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUH2"
  },
  {
    "uniprot": "A0A044QYU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYU1"
  },
  {
    "uniprot": "A0A044SAZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAZ1"
  },
  {
    "uniprot": "A0A044S4X6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4X6"
  },
  {
    "uniprot": "A0A044TY30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TY30"
  },
  {
    "uniprot": "A0A044SZ31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ31"
  },
  {
    "uniprot": "A0A044THT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THT7"
  },
  {
    "uniprot": "A0A044TLW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLW6"
  },
  {
    "uniprot": "A0A044VD57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD57"
  },
  {
    "uniprot": "A0A044TCI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCI6"
  },
  {
    "uniprot": "A0A044V5I8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5I8"
  },
  {
    "uniprot": "A0A044S200",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S200"
  },
  {
    "uniprot": "A0A044T773",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T773"
  },
  {
    "uniprot": "A0A044RPW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPW8"
  },
  {
    "uniprot": "A0A044SL15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SL15"
  },
  {
    "uniprot": "A0A044RWL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWL2"
  },
  {
    "uniprot": "A0A044RKH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKH2"
  },
  {
    "uniprot": "A0A044T7H6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7H6"
  },
  {
    "uniprot": "A0A044UST6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UST6"
  },
  {
    "uniprot": "A0A044QR81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QR81"
  },
  {
    "uniprot": "A0A044SDD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDD7"
  },
  {
    "uniprot": "A0A044V0V6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0V6"
  },
  {
    "uniprot": "A0A044QT72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT72"
  },
  {
    "uniprot": "A0A044V4E1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4E1"
  },
  {
    "uniprot": "A0A044QL95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL95"
  },
  {
    "uniprot": "A0A044RB78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RB78"
  },
  {
    "uniprot": "A0A044V127",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V127"
  },
  {
    "uniprot": "A0A044SBN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBN3"
  },
  {
    "uniprot": "A0A044V4A6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4A6"
  },
  {
    "uniprot": "A0A2K6VLR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLR8"
  },
  {
    "uniprot": "A0A2K6W3W1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3W1"
  },
  {
    "uniprot": "A0A044SS26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS26"
  },
  {
    "uniprot": "A0A044VA52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA52"
  },
  {
    "uniprot": "A0A2K6WNB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WNB5"
  },
  {
    "uniprot": "A0A044RVG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVG0"
  },
  {
    "uniprot": "A0A044SAY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAY7"
  },
  {
    "uniprot": "A0A044S747",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S747"
  },
  {
    "uniprot": "A0A044U256",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U256"
  },
  {
    "uniprot": "A0A044TLE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLE7"
  },
  {
    "uniprot": "A0A044S0M8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0M8"
  },
  {
    "uniprot": "A0A044SMI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMI1"
  },
  {
    "uniprot": "A0A044T6E0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6E0"
  },
  {
    "uniprot": "A0A044U9T4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9T4"
  },
  {
    "uniprot": "A0A044SHD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHD6"
  },
  {
    "uniprot": "A0A044RZV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZV6"
  },
  {
    "uniprot": "A0A044RXW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXW9"
  },
  {
    "uniprot": "A0A044SIV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIV3"
  },
  {
    "uniprot": "A0A044S9S0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9S0"
  },
  {
    "uniprot": "A0A2K6VTU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VTU5"
  },
  {
    "uniprot": "A0A044QYF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYF6"
  },
  {
    "uniprot": "A0A044RXR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXR2"
  },
  {
    "uniprot": "A0A044U5Q0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5Q0"
  },
  {
    "uniprot": "A0A044TWR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWR2"
  },
  {
    "uniprot": "A0A044RCL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCL9"
  },
  {
    "uniprot": "A0A044SPX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPX6"
  },
  {
    "uniprot": "A0A158N7V9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7V9"
  },
  {
    "uniprot": "A0A044RKN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKN4"
  },
  {
    "uniprot": "A0A044TUS8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUS8"
  },
  {
    "uniprot": "A0A044R0J0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0J0"
  },
  {
    "uniprot": "A0A044QS95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QS95"
  },
  {
    "uniprot": "A0A044TKT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKT1"
  },
  {
    "uniprot": "A0A044QNE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNE3"
  },
  {
    "uniprot": "A0A044V0U4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0U4"
  },
  {
    "uniprot": "A0A044QJP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJP1"
  },
  {
    "uniprot": "A0A044V996",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V996"
  },
  {
    "uniprot": "A0A044R7V4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7V4"
  },
  {
    "uniprot": "A0A044QRW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRW8"
  },
  {
    "uniprot": "A0A044RAL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAL0"
  },
  {
    "uniprot": "A0A044V6T6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6T6"
  },
  {
    "uniprot": "A0A044RRL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRL6"
  },
  {
    "uniprot": "A0A044QV04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QV04"
  },
  {
    "uniprot": "A0A044U555",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U555"
  },
  {
    "uniprot": "A0A044R025",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R025"
  },
  {
    "uniprot": "A0A044TUA2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUA2"
  },
  {
    "uniprot": "A0A044S0E9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0E9"
  },
  {
    "uniprot": "P54186",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P54186"
  },
  {
    "uniprot": "A0A044SCH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCH6"
  },
  {
    "uniprot": "A0A044ULZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULZ3"
  },
  {
    "uniprot": "A0A2K6VPT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPT2"
  },
  {
    "uniprot": "A0A044SLB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLB5"
  },
  {
    "uniprot": "A0A044QML4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QML4"
  },
  {
    "uniprot": "A0A044TCM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCM6"
  },
  {
    "uniprot": "A0A044U402",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U402"
  },
  {
    "uniprot": "A0A044TMD0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMD0"
  },
  {
    "uniprot": "A0A044RLS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLS7"
  },
  {
    "uniprot": "A0A044ULR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULR8"
  },
  {
    "uniprot": "A0A044RFY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFY7"
  },
  {
    "uniprot": "A0A2K6WFL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFL9"
  },
  {
    "uniprot": "A0A044SXQ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXQ2"
  },
  {
    "uniprot": "A0A2K6W9P3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9P3"
  },
  {
    "uniprot": "A0A044QNP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNP6"
  },
  {
    "uniprot": "A0A044TUB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUB3"
  },
  {
    "uniprot": "A0A044SG92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG92"
  },
  {
    "uniprot": "A0A044U8R5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8R5"
  },
  {
    "uniprot": "A0A2K6VXS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXS5"
  },
  {
    "uniprot": "A0A044SWQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWQ6"
  },
  {
    "uniprot": "A0A2K6VPS8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPS8"
  },
  {
    "uniprot": "A0A044RWN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWN7"
  },
  {
    "uniprot": "A0A044TGT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGT9"
  },
  {
    "uniprot": "A0A044TJW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJW6"
  },
  {
    "uniprot": "A0A044R1X9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1X9"
  },
  {
    "uniprot": "A0A044V459",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V459"
  },
  {
    "uniprot": "A0A2K6VKM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKM1"
  },
  {
    "uniprot": "A0A044R0H5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0H5"
  },
  {
    "uniprot": "A0A044TE17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TE17"
  },
  {
    "uniprot": "A0A044U243",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U243"
  },
  {
    "uniprot": "A0A044UY32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UY32"
  },
  {
    "uniprot": "A0A044UIN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIN8"
  },
  {
    "uniprot": "A0A044U7R5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7R5"
  },
  {
    "uniprot": "A0A044RSI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSI4"
  },
  {
    "uniprot": "A0A044S852",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S852"
  },
  {
    "uniprot": "A0A158N816",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N816"
  },
  {
    "uniprot": "A0A044SQK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQK4"
  },
  {
    "uniprot": "A0A044UZ49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ49"
  },
  {
    "uniprot": "A0A044R3U6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3U6"
  },
  {
    "uniprot": "A0A044U6U9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6U9"
  },
  {
    "uniprot": "A0A044QVR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVR1"
  },
  {
    "uniprot": "A0A044UJF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJF5"
  },
  {
    "uniprot": "A0A044V6T4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6T4"
  },
  {
    "uniprot": "A0A2K6VGE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VGE6"
  },
  {
    "uniprot": "A0A044S8U4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8U4"
  },
  {
    "uniprot": "A0A044QMK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMK6"
  },
  {
    "uniprot": "A0A044SRZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRZ1"
  },
  {
    "uniprot": "A0A044RYX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYX3"
  },
  {
    "uniprot": "A0A044TTH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTH1"
  },
  {
    "uniprot": "A0A2K6WLW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLW6"
  },
  {
    "uniprot": "A0A044RGD4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGD4"
  },
  {
    "uniprot": "A0A044V8N9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8N9"
  },
  {
    "uniprot": "A0A044UXX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXX8"
  },
  {
    "uniprot": "A0A044T0M7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0M7"
  },
  {
    "uniprot": "A0A2K6VFU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFU4"
  },
  {
    "uniprot": "A0A044TL46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TL46"
  },
  {
    "uniprot": "A0A2K6W6R0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6R0"
  },
  {
    "uniprot": "A0A044V1H0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1H0"
  },
  {
    "uniprot": "A0A2K6WL63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WL63"
  },
  {
    "uniprot": "A0A044TF11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TF11"
  },
  {
    "uniprot": "A0A044UKL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKL1"
  },
  {
    "uniprot": "A0A044QRE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRE1"
  },
  {
    "uniprot": "A0A044RE83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RE83"
  },
  {
    "uniprot": "A0A044SGF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGF1"
  },
  {
    "uniprot": "A0A044THG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THG5"
  },
  {
    "uniprot": "A0A044U6D8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6D8"
  },
  {
    "uniprot": "A0A044ULU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULU9"
  },
  {
    "uniprot": "A0A044US63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044US63"
  },
  {
    "uniprot": "A0A044TA10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TA10"
  },
  {
    "uniprot": "A0A044UPV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPV6"
  },
  {
    "uniprot": "A0A2K6VTP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VTP4"
  },
  {
    "uniprot": "A0A044SJT3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJT3"
  },
  {
    "uniprot": "A0A044UCH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCH0"
  },
  {
    "uniprot": "A0A044RRC7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRC7"
  },
  {
    "uniprot": "A0A2K6VYR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYR1"
  },
  {
    "uniprot": "A0A044UI39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI39"
  },
  {
    "uniprot": "A0A044TVV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVV5"
  },
  {
    "uniprot": "A0A044UK53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UK53"
  },
  {
    "uniprot": "A0A044RNX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNX7"
  },
  {
    "uniprot": "A0A044QYR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYR6"
  },
  {
    "uniprot": "A0A044S992",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S992"
  },
  {
    "uniprot": "A0A044RL31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RL31"
  },
  {
    "uniprot": "A0A044SI32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI32"
  },
  {
    "uniprot": "A0A044SGI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGI3"
  },
  {
    "uniprot": "A0A2K6VJU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJU8"
  },
  {
    "uniprot": "A0A044UA24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA24"
  },
  {
    "uniprot": "A0A044R6Q6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6Q6"
  },
  {
    "uniprot": "A0A044VA34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA34"
  },
  {
    "uniprot": "A0A044S3J3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3J3"
  },
  {
    "uniprot": "A0A2K6VJJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJJ4"
  },
  {
    "uniprot": "A0A044UC71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC71"
  },
  {
    "uniprot": "A0A2K6W8Z8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8Z8"
  },
  {
    "uniprot": "A0A044QJV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJV9"
  },
  {
    "uniprot": "A0A044UZ22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ22"
  },
  {
    "uniprot": "A0A044R1K2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1K2"
  },
  {
    "uniprot": "A0A044V933",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V933"
  },
  {
    "uniprot": "A0A044RSH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSH7"
  },
  {
    "uniprot": "A0A044TIC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIC3"
  },
  {
    "uniprot": "A0A044TEQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEQ9"
  },
  {
    "uniprot": "A0A044ULU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULU8"
  },
  {
    "uniprot": "A0A044RT06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RT06"
  },
  {
    "uniprot": "A0A044SZQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZQ1"
  },
  {
    "uniprot": "A0A044UFE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFE9"
  },
  {
    "uniprot": "A0A2K6WKE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKE0"
  },
  {
    "uniprot": "A0A044RMF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMF0"
  },
  {
    "uniprot": "A0A044V9M5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9M5"
  },
  {
    "uniprot": "A0A044SCR5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCR5"
  },
  {
    "uniprot": "A0A044V8U3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8U3"
  },
  {
    "uniprot": "A0A044SPG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPG9"
  },
  {
    "uniprot": "A0A044SD79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD79"
  },
  {
    "uniprot": "A0A044QZ18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ18"
  },
  {
    "uniprot": "A0A044QMM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMM3"
  },
  {
    "uniprot": "A0A044RU43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU43"
  },
  {
    "uniprot": "A0A044V9P0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9P0"
  },
  {
    "uniprot": "A0A044QR76",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QR76"
  },
  {
    "uniprot": "A0A044TBW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBW7"
  },
  {
    "uniprot": "A0A044TCG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCG9"
  },
  {
    "uniprot": "A0A044UEB2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEB2"
  },
  {
    "uniprot": "A0A2K6VDL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDL9"
  },
  {
    "uniprot": "A0A044S4I2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4I2"
  },
  {
    "uniprot": "A0A044TH27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH27"
  },
  {
    "uniprot": "A0A044T3A2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3A2"
  },
  {
    "uniprot": "A0A044V7X3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7X3"
  },
  {
    "uniprot": "A0A2K6WI08",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WI08"
  },
  {
    "uniprot": "A0A044V117",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V117"
  },
  {
    "uniprot": "A0A044QXL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXL9"
  },
  {
    "uniprot": "A0A044TN34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN34"
  },
  {
    "uniprot": "A0A2K6WBM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBM8"
  },
  {
    "uniprot": "A0A044UXB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXB5"
  },
  {
    "uniprot": "A0A158N7Y0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Y0"
  },
  {
    "uniprot": "A0A044S9Y8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9Y8"
  },
  {
    "uniprot": "A0A044UBU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBU6"
  },
  {
    "uniprot": "A0A044S8C1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8C1"
  },
  {
    "uniprot": "A0A2K6W9Y7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9Y7"
  },
  {
    "uniprot": "A0A044UGD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGD1"
  },
  {
    "uniprot": "A0A044STV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STV0"
  },
  {
    "uniprot": "A0A044UJF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJF8"
  },
  {
    "uniprot": "A0A044QZD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZD1"
  },
  {
    "uniprot": "A0A044UI15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI15"
  },
  {
    "uniprot": "A0A044R8S8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8S8"
  },
  {
    "uniprot": "A0A044VBJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBJ6"
  },
  {
    "uniprot": "A0A044TYV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYV3"
  },
  {
    "uniprot": "A0A044UQH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQH8"
  },
  {
    "uniprot": "A0A044RIC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIC2"
  },
  {
    "uniprot": "A0A044TU54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TU54"
  },
  {
    "uniprot": "A0A044V6Y7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Y7"
  },
  {
    "uniprot": "A0A044T5J2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5J2"
  },
  {
    "uniprot": "A0A044RNP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNP9"
  },
  {
    "uniprot": "A0A044V0K1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0K1"
  },
  {
    "uniprot": "A0A044S878",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S878"
  },
  {
    "uniprot": "A0A044QZ42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ42"
  },
  {
    "uniprot": "A0A044R6Z4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6Z4"
  },
  {
    "uniprot": "A0A044TVW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVW8"
  },
  {
    "uniprot": "A0A044UPE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPE3"
  },
  {
    "uniprot": "A0A044RE04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RE04"
  },
  {
    "uniprot": "A0A044T7W1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7W1"
  },
  {
    "uniprot": "A0A044UAQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAQ6"
  },
  {
    "uniprot": "A0A044S314",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S314"
  },
  {
    "uniprot": "A0A044QZ57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ57"
  },
  {
    "uniprot": "A0A044T348",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T348"
  },
  {
    "uniprot": "A0A2K6VEH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VEH2"
  },
  {
    "uniprot": "A0A044SQM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQM5"
  },
  {
    "uniprot": "A0A044RJ25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJ25"
  },
  {
    "uniprot": "A0A044TAA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAA7"
  },
  {
    "uniprot": "A0A044VIJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIJ6"
  },
  {
    "uniprot": "A0A2K6W9X0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9X0"
  },
  {
    "uniprot": "A0A044SRK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRK8"
  },
  {
    "uniprot": "A0A044SY80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY80"
  },
  {
    "uniprot": "A0A044V744",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V744"
  },
  {
    "uniprot": "A0A044UF61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF61"
  },
  {
    "uniprot": "A0A2K6WN90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WN90"
  },
  {
    "uniprot": "A0A044RJN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJN3"
  },
  {
    "uniprot": "A0A044UWQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWQ7"
  },
  {
    "uniprot": "A0A044SQW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQW3"
  },
  {
    "uniprot": "A0A044V9B1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9B1"
  },
  {
    "uniprot": "A0A044U8Z0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8Z0"
  },
  {
    "uniprot": "A0A044TU78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TU78"
  },
  {
    "uniprot": "A0A044UPD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPD7"
  },
  {
    "uniprot": "A0A044RZP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZP7"
  },
  {
    "uniprot": "A0A044TS16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS16"
  },
  {
    "uniprot": "A0A044UYM9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYM9"
  },
  {
    "uniprot": "A0A044UHS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHS3"
  },
  {
    "uniprot": "A0A044SCV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCV7"
  },
  {
    "uniprot": "A0A044S1Z4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1Z4"
  },
  {
    "uniprot": "A0A044U9S0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9S0"
  },
  {
    "uniprot": "A0A044QVH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVH1"
  },
  {
    "uniprot": "A0A044S3G9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3G9"
  },
  {
    "uniprot": "A0A044UWM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWM0"
  },
  {
    "uniprot": "A0A044QJN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJN0"
  },
  {
    "uniprot": "A0A044TRA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRA3"
  },
  {
    "uniprot": "A0A2K6W981",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W981"
  },
  {
    "uniprot": "A0A044RWL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWL7"
  },
  {
    "uniprot": "A0A2K6WD07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WD07"
  },
  {
    "uniprot": "A0A044V1V5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1V5"
  },
  {
    "uniprot": "A0A044T3D1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3D1"
  },
  {
    "uniprot": "A0A044R4I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4I9"
  },
  {
    "uniprot": "A0A044TUX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUX2"
  },
  {
    "uniprot": "A0A2K6VJ94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJ94"
  },
  {
    "uniprot": "A0A044SJF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJF6"
  },
  {
    "uniprot": "A0A044TJ45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJ45"
  },
  {
    "uniprot": "A0A044UIZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIZ7"
  },
  {
    "uniprot": "A0A044RFQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFQ1"
  },
  {
    "uniprot": "A0A2K6WKN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKN1"
  },
  {
    "uniprot": "A0A044T1K0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1K0"
  },
  {
    "uniprot": "A0A044TFA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFA5"
  },
  {
    "uniprot": "A0A2K6WBD2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBD2"
  },
  {
    "uniprot": "A0A044TSD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSD7"
  },
  {
    "uniprot": "A0A044SAV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAV6"
  },
  {
    "uniprot": "A0A044U1Q1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1Q1"
  },
  {
    "uniprot": "A0A044TQ34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQ34"
  },
  {
    "uniprot": "A0A044V398",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V398"
  },
  {
    "uniprot": "A0A044U1K7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1K7"
  },
  {
    "uniprot": "A0A044UCK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCK4"
  },
  {
    "uniprot": "A0A044SQR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQR3"
  },
  {
    "uniprot": "A0A044VCA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCA3"
  },
  {
    "uniprot": "A0A044TLM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLM7"
  },
  {
    "uniprot": "A0A044RDQ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDQ2"
  },
  {
    "uniprot": "A0A044TNK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNK8"
  },
  {
    "uniprot": "A0A044SXP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXP0"
  },
  {
    "uniprot": "A0A044T550",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T550"
  },
  {
    "uniprot": "A0A044TPV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPV3"
  },
  {
    "uniprot": "A0A044RDY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDY9"
  },
  {
    "uniprot": "A0A044R2Z4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2Z4"
  },
  {
    "uniprot": "A0A044TGI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGI0"
  },
  {
    "uniprot": "A0A2K6WM80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WM80"
  },
  {
    "uniprot": "A0A044RGC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGC2"
  },
  {
    "uniprot": "A0A044RH52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH52"
  },
  {
    "uniprot": "A0A044V0Z5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0Z5"
  },
  {
    "uniprot": "A0A044RXP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXP7"
  },
  {
    "uniprot": "A0A044TL52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TL52"
  },
  {
    "uniprot": "A0A044SNF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNF0"
  },
  {
    "uniprot": "A0A044U753",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U753"
  },
  {
    "uniprot": "A0A044U0N1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0N1"
  },
  {
    "uniprot": "A0A044TP20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TP20"
  },
  {
    "uniprot": "A0A044S8N0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8N0"
  },
  {
    "uniprot": "A0A2K6W1Z6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1Z6"
  },
  {
    "uniprot": "A0A044R9B4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9B4"
  },
  {
    "uniprot": "A0A044VGK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGK1"
  },
  {
    "uniprot": "A0A044QPG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPG5"
  },
  {
    "uniprot": "A0A044UUE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUE5"
  },
  {
    "uniprot": "A0A044SL55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SL55"
  },
  {
    "uniprot": "A0A044QLA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLA3"
  },
  {
    "uniprot": "A0A044SZX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZX4"
  },
  {
    "uniprot": "A0A044REL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REL9"
  },
  {
    "uniprot": "A0A044SRU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRU0"
  },
  {
    "uniprot": "A0A158N8S9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8S9"
  },
  {
    "uniprot": "A0A044TZN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZN6"
  },
  {
    "uniprot": "A0A044UB83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UB83"
  },
  {
    "uniprot": "A0A044V9N9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9N9"
  },
  {
    "uniprot": "A0A044SAS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAS5"
  },
  {
    "uniprot": "A0A2K6VN76",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VN76"
  },
  {
    "uniprot": "A0A044RKC7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKC7"
  },
  {
    "uniprot": "A0A044TB68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TB68"
  },
  {
    "uniprot": "A0A044V6U9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6U9"
  },
  {
    "uniprot": "A0A044R9M9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9M9"
  },
  {
    "uniprot": "A0A2K6W970",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W970"
  },
  {
    "uniprot": "A0A044RXX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXX1"
  },
  {
    "uniprot": "A0A044VH71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH71"
  },
  {
    "uniprot": "A0A044VEP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEP7"
  },
  {
    "uniprot": "A0A044TWZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWZ4"
  },
  {
    "uniprot": "A0A044TVL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVL3"
  },
  {
    "uniprot": "A0A044RW39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW39"
  },
  {
    "uniprot": "A0A2K6W9J1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9J1"
  },
  {
    "uniprot": "A0A044S8W2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8W2"
  },
  {
    "uniprot": "A0A044SLC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLC1"
  },
  {
    "uniprot": "A0A044UXA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXA1"
  },
  {
    "uniprot": "A0A044S858",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S858"
  },
  {
    "uniprot": "A0A044VJJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJJ7"
  },
  {
    "uniprot": "A0A044RAC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAC1"
  },
  {
    "uniprot": "A0A044TB99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TB99"
  },
  {
    "uniprot": "A0A044VC30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC30"
  },
  {
    "uniprot": "A0A044SQW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQW2"
  },
  {
    "uniprot": "A0A044RVL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVL8"
  },
  {
    "uniprot": "A0A044UAX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAX5"
  },
  {
    "uniprot": "A0A044R3F5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3F5"
  },
  {
    "uniprot": "A0A044S5X4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5X4"
  },
  {
    "uniprot": "A0A044VAE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAE4"
  },
  {
    "uniprot": "A0A044TRZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRZ1"
  },
  {
    "uniprot": "A0A044QR43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QR43"
  },
  {
    "uniprot": "A0A044SRT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRT4"
  },
  {
    "uniprot": "A0A044SSZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSZ6"
  },
  {
    "uniprot": "A0A044RR44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RR44"
  },
  {
    "uniprot": "A0A044SID1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SID1"
  },
  {
    "uniprot": "A0A044UYJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYJ7"
  },
  {
    "uniprot": "A0A2K6W2E9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2E9"
  },
  {
    "uniprot": "A0A044V9F5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9F5"
  },
  {
    "uniprot": "A0A044URI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URI4"
  },
  {
    "uniprot": "A0A044S1I6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1I6"
  },
  {
    "uniprot": "A0A044RFK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFK9"
  },
  {
    "uniprot": "A0A044QZP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZP2"
  },
  {
    "uniprot": "A0A2K6VHV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHV1"
  },
  {
    "uniprot": "A0A044QZL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZL4"
  },
  {
    "uniprot": "A0A044VB63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VB63"
  },
  {
    "uniprot": "A0A044S0W3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0W3"
  },
  {
    "uniprot": "A0A2K6VIK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIK3"
  },
  {
    "uniprot": "A0A044V759",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V759"
  },
  {
    "uniprot": "A0A044VJF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJF5"
  },
  {
    "uniprot": "A0A044SRS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRS7"
  },
  {
    "uniprot": "A0A044S809",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S809"
  },
  {
    "uniprot": "A0A044V951",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V951"
  },
  {
    "uniprot": "A0A044TC28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC28"
  },
  {
    "uniprot": "A0A044TH10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH10"
  },
  {
    "uniprot": "A0A044R0R1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0R1"
  },
  {
    "uniprot": "A0A044VH77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH77"
  },
  {
    "uniprot": "A0A2K6WM87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WM87"
  },
  {
    "uniprot": "A0A2K6WAG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAG1"
  },
  {
    "uniprot": "A0A044T0G9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0G9"
  },
  {
    "uniprot": "A0A044V807",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V807"
  },
  {
    "uniprot": "A0A044R4F1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4F1"
  },
  {
    "uniprot": "A0A044TUV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUV1"
  },
  {
    "uniprot": "A0A044RV65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RV65"
  },
  {
    "uniprot": "A0A044T601",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T601"
  },
  {
    "uniprot": "A0A044VA48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA48"
  },
  {
    "uniprot": "A0A044QMS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMS0"
  },
  {
    "uniprot": "A0A044UJI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJI2"
  },
  {
    "uniprot": "A0A044QW01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW01"
  },
  {
    "uniprot": "A0A044S6B8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6B8"
  },
  {
    "uniprot": "A0A044RFA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFA9"
  },
  {
    "uniprot": "A0A044RZG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZG6"
  },
  {
    "uniprot": "A0A044UB11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UB11"
  },
  {
    "uniprot": "A0A044TGA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGA9"
  },
  {
    "uniprot": "A0A044TRG3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRG3"
  },
  {
    "uniprot": "A0A2K6VJ25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJ25"
  },
  {
    "uniprot": "A0A044R248",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R248"
  },
  {
    "uniprot": "A0A044TTJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTJ9"
  },
  {
    "uniprot": "A0A044SS46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS46"
  },
  {
    "uniprot": "A0A044TIS1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIS1"
  },
  {
    "uniprot": "A0A2K6VYH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYH1"
  },
  {
    "uniprot": "A0A044TIP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIP2"
  },
  {
    "uniprot": "A0A044QLP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLP2"
  },
  {
    "uniprot": "A0A044SWL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWL8"
  },
  {
    "uniprot": "A0A044R1C0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1C0"
  },
  {
    "uniprot": "A0A044UKA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKA7"
  },
  {
    "uniprot": "A0A044SQU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQU0"
  },
  {
    "uniprot": "A0A044T6G9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6G9"
  },
  {
    "uniprot": "A0A2K6WEE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEE1"
  },
  {
    "uniprot": "A0A044THF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THF1"
  },
  {
    "uniprot": "A0A044QL98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL98"
  },
  {
    "uniprot": "A0A044V5J4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5J4"
  },
  {
    "uniprot": "A0A2K6WBN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBN6"
  },
  {
    "uniprot": "A0A044TFZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFZ1"
  },
  {
    "uniprot": "A0A044SF01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SF01"
  },
  {
    "uniprot": "A0A158N881",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N881"
  },
  {
    "uniprot": "A0A044TSU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSU4"
  },
  {
    "uniprot": "A0A044U7V7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7V7"
  },
  {
    "uniprot": "A0A044VBR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBR4"
  },
  {
    "uniprot": "A0A044UYN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYN9"
  },
  {
    "uniprot": "A0A044TMW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMW5"
  },
  {
    "uniprot": "A0A044TQ03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQ03"
  },
  {
    "uniprot": "A0A2K6W3J0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3J0"
  },
  {
    "uniprot": "A0A2K6VUN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUN0"
  },
  {
    "uniprot": "A0A044RXZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXZ3"
  },
  {
    "uniprot": "A0A044SZZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZZ3"
  },
  {
    "uniprot": "A0A044SF06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SF06"
  },
  {
    "uniprot": "A0A044V9S4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9S4"
  },
  {
    "uniprot": "A0A044RYL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYL9"
  },
  {
    "uniprot": "A0A044SD38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD38"
  },
  {
    "uniprot": "A0A044US87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044US87"
  },
  {
    "uniprot": "A0A2K6WGK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGK2"
  },
  {
    "uniprot": "A0A044UHP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHP9"
  },
  {
    "uniprot": "A0A044TRK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRK8"
  },
  {
    "uniprot": "A0A044UP74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UP74"
  },
  {
    "uniprot": "A0A044VAC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAC1"
  },
  {
    "uniprot": "A0A044UID1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UID1"
  },
  {
    "uniprot": "A0A044SY55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY55"
  },
  {
    "uniprot": "A0A044V3R9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3R9"
  },
  {
    "uniprot": "A0A158N7P7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7P7"
  },
  {
    "uniprot": "A0A044TUI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUI8"
  },
  {
    "uniprot": "A0A044U1G8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1G8"
  },
  {
    "uniprot": "A0A044V0F0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0F0"
  },
  {
    "uniprot": "A0A044TX48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TX48"
  },
  {
    "uniprot": "A0A044SPV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPV0"
  },
  {
    "uniprot": "A0A044QZL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZL9"
  },
  {
    "uniprot": "A0A044V162",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V162"
  },
  {
    "uniprot": "A0A044RZR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZR1"
  },
  {
    "uniprot": "A0A044UTT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTT2"
  },
  {
    "uniprot": "A0A044R4D0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4D0"
  },
  {
    "uniprot": "A0A044S4Y3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4Y3"
  },
  {
    "uniprot": "A0A044UC89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC89"
  },
  {
    "uniprot": "A0A2K6WMB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMB6"
  },
  {
    "uniprot": "A0A044UF72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF72"
  },
  {
    "uniprot": "A0A044SCS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCS0"
  },
  {
    "uniprot": "A0A044SWY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWY2"
  },
  {
    "uniprot": "A0A044T4E6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4E6"
  },
  {
    "uniprot": "A0A044RPS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPS3"
  },
  {
    "uniprot": "A0A044TTU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTU1"
  },
  {
    "uniprot": "A0A044V351",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V351"
  },
  {
    "uniprot": "A0A044TSB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSB3"
  },
  {
    "uniprot": "A0A044UJR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJR4"
  },
  {
    "uniprot": "A0A044SM49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM49"
  },
  {
    "uniprot": "A0A044VCU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCU7"
  },
  {
    "uniprot": "A0A044TIY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIY8"
  },
  {
    "uniprot": "A0A044SBQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBQ7"
  },
  {
    "uniprot": "A0A044RA66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RA66"
  },
  {
    "uniprot": "A0A044S4W0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4W0"
  },
  {
    "uniprot": "A0A044UKV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKV9"
  },
  {
    "uniprot": "A0A044SDM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDM7"
  },
  {
    "uniprot": "A0A044QYS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYS5"
  },
  {
    "uniprot": "A0A044V8W9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8W9"
  },
  {
    "uniprot": "A0A044UMU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMU8"
  },
  {
    "uniprot": "A0A044R4S0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4S0"
  },
  {
    "uniprot": "A0A2K6VRX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRX3"
  },
  {
    "uniprot": "A0A044UUW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUW7"
  },
  {
    "uniprot": "A0A044R3N9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3N9"
  },
  {
    "uniprot": "A0A044SXK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXK3"
  },
  {
    "uniprot": "A0A2K6WG37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WG37"
  },
  {
    "uniprot": "A0A044UL49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL49"
  },
  {
    "uniprot": "A0A044RQ54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQ54"
  },
  {
    "uniprot": "A0A044UDQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDQ6"
  },
  {
    "uniprot": "A0A044RW64",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW64"
  },
  {
    "uniprot": "A0A044QUB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUB5"
  },
  {
    "uniprot": "A0A044R6V4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6V4"
  },
  {
    "uniprot": "A0A044TU79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TU79"
  },
  {
    "uniprot": "A0A044VGE8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGE8"
  },
  {
    "uniprot": "A0A2K6WG96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WG96"
  },
  {
    "uniprot": "A0A044R6Z3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6Z3"
  },
  {
    "uniprot": "A0A044RLT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLT8"
  },
  {
    "uniprot": "A0A044T0Q9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0Q9"
  },
  {
    "uniprot": "A0A044V6Y5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Y5"
  },
  {
    "uniprot": "A0A044V9P8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9P8"
  },
  {
    "uniprot": "A0A044SF12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SF12"
  },
  {
    "uniprot": "A0A044TB43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TB43"
  },
  {
    "uniprot": "A0A044R2C4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2C4"
  },
  {
    "uniprot": "A0A044UPV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPV9"
  },
  {
    "uniprot": "A0A044SNK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNK6"
  },
  {
    "uniprot": "A0A044QP47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP47"
  },
  {
    "uniprot": "Q99099",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q99099"
  },
  {
    "uniprot": "A0A044QSP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSP0"
  },
  {
    "uniprot": "A0A044SNS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNS4"
  },
  {
    "uniprot": "A0A2K6WJU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJU5"
  },
  {
    "uniprot": "A0A158N7Q3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Q3"
  },
  {
    "uniprot": "A0A044T7L3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7L3"
  },
  {
    "uniprot": "A0A044S6N8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6N8"
  },
  {
    "uniprot": "A0A044UI66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI66"
  },
  {
    "uniprot": "A0A044RUJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUJ3"
  },
  {
    "uniprot": "A0A158N7W9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7W9"
  },
  {
    "uniprot": "A0A044TQG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQG2"
  },
  {
    "uniprot": "A0A044V7A2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7A2"
  },
  {
    "uniprot": "A0A044UPT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPT0"
  },
  {
    "uniprot": "A0A044QL51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL51"
  },
  {
    "uniprot": "A0A044UWL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWL0"
  },
  {
    "uniprot": "A0A044S3J9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3J9"
  },
  {
    "uniprot": "A0A044TS79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS79"
  },
  {
    "uniprot": "A0A044TL65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TL65"
  },
  {
    "uniprot": "A0A044R1L6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1L6"
  },
  {
    "uniprot": "A0A044QWT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWT0"
  },
  {
    "uniprot": "A0A044RA28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RA28"
  },
  {
    "uniprot": "A0A2K6VWH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWH0"
  },
  {
    "uniprot": "A0A044QXE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXE5"
  },
  {
    "uniprot": "A0A044V723",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V723"
  },
  {
    "uniprot": "A0A044VFD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFD8"
  },
  {
    "uniprot": "A0A044UTG3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTG3"
  },
  {
    "uniprot": "A0A044R107",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R107"
  },
  {
    "uniprot": "A0A2K6VRR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRR4"
  },
  {
    "uniprot": "A0A044UII2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UII2"
  },
  {
    "uniprot": "A0A044RS31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RS31"
  },
  {
    "uniprot": "A0A044VFQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFQ0"
  },
  {
    "uniprot": "A0A044RY63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY63"
  },
  {
    "uniprot": "A0A2K6WKZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKZ6"
  },
  {
    "uniprot": "A0A2K6W6Z9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6Z9"
  },
  {
    "uniprot": "A0A2K6W5D8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W5D8"
  },
  {
    "uniprot": "A0A044R4W9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4W9"
  },
  {
    "uniprot": "A0A2K6VND2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VND2"
  },
  {
    "uniprot": "A0A044TSV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSV0"
  },
  {
    "uniprot": "A0A044UHN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHN1"
  },
  {
    "uniprot": "A0A044SI25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI25"
  },
  {
    "uniprot": "A0A2K6VIR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIR3"
  },
  {
    "uniprot": "A0A044VDA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDA4"
  },
  {
    "uniprot": "A0A044QYI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYI6"
  },
  {
    "uniprot": "A0A044RXZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXZ7"
  },
  {
    "uniprot": "A0A044QZV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZV7"
  },
  {
    "uniprot": "A0A044UD20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UD20"
  },
  {
    "uniprot": "A0A044SD03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD03"
  },
  {
    "uniprot": "A0A044RFX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFX5"
  },
  {
    "uniprot": "A0A2K6WC14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WC14"
  },
  {
    "uniprot": "A0A044T8A1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8A1"
  },
  {
    "uniprot": "A0A2K6WF22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WF22"
  },
  {
    "uniprot": "A0A044V6U4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6U4"
  },
  {
    "uniprot": "A0A044UUH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUH1"
  },
  {
    "uniprot": "A0A2K6VYF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYF5"
  },
  {
    "uniprot": "A0A044R5H0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5H0"
  },
  {
    "uniprot": "A0A044V145",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V145"
  },
  {
    "uniprot": "A0A2K6WJZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJZ1"
  },
  {
    "uniprot": "A0A2K6W2A3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2A3"
  },
  {
    "uniprot": "A0A2K6W5F1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W5F1"
  },
  {
    "uniprot": "A0A044UNC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNC8"
  },
  {
    "uniprot": "P24706",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P24706"
  },
  {
    "uniprot": "A0A044RUB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUB3"
  },
  {
    "uniprot": "A0A044RE24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RE24"
  },
  {
    "uniprot": "A0A044RBZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBZ8"
  },
  {
    "uniprot": "A0A044TTD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTD3"
  },
  {
    "uniprot": "A0A044SB05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB05"
  },
  {
    "uniprot": "A0A044U198",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U198"
  },
  {
    "uniprot": "A0A044SHM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHM4"
  },
  {
    "uniprot": "A0A2K6W0W6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0W6"
  },
  {
    "uniprot": "A0A044V6V9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6V9"
  },
  {
    "uniprot": "A0A044SKA2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKA2"
  },
  {
    "uniprot": "A0A158N7S9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7S9"
  },
  {
    "uniprot": "A0A044TEJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEJ1"
  },
  {
    "uniprot": "A0A044UVU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVU4"
  },
  {
    "uniprot": "A0A044UT52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT52"
  },
  {
    "uniprot": "A0A044SXK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXK6"
  },
  {
    "uniprot": "A0A044SSL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSL2"
  },
  {
    "uniprot": "A0A2K6W602",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W602"
  },
  {
    "uniprot": "A0A044SH79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SH79"
  },
  {
    "uniprot": "A0A044T6T3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6T3"
  },
  {
    "uniprot": "A0A044R3D7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3D7"
  },
  {
    "uniprot": "A0A044QN74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QN74"
  },
  {
    "uniprot": "A0A044V9V7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9V7"
  },
  {
    "uniprot": "A0A044VE39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VE39"
  },
  {
    "uniprot": "Q8I6Y7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q8I6Y7"
  },
  {
    "uniprot": "A0A044TQX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQX5"
  },
  {
    "uniprot": "A0A044UR04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UR04"
  },
  {
    "uniprot": "A0A158N7Z3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Z3"
  },
  {
    "uniprot": "A0A044QRA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRA4"
  },
  {
    "uniprot": "A0A044TD92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TD92"
  },
  {
    "uniprot": "A0A2K6W354",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W354"
  },
  {
    "uniprot": "A0A044RIT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIT8"
  },
  {
    "uniprot": "A0A044RXM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXM5"
  },
  {
    "uniprot": "A0A044SMY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMY0"
  },
  {
    "uniprot": "A0A044QRB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRB4"
  },
  {
    "uniprot": "A0A2K6VVQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVQ9"
  },
  {
    "uniprot": "A0A044TIV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIV1"
  },
  {
    "uniprot": "A0A044QPE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPE1"
  },
  {
    "uniprot": "A0A044SQF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQF3"
  },
  {
    "uniprot": "A0A044RVI5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVI5"
  },
  {
    "uniprot": "A0A044R253",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R253"
  },
  {
    "uniprot": "A0A2K6W5W9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W5W9"
  },
  {
    "uniprot": "A0A044URA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URA3"
  },
  {
    "uniprot": "A0A044U7I4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7I4"
  },
  {
    "uniprot": "A0A044TCW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCW0"
  },
  {
    "uniprot": "A0A044RTY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTY8"
  },
  {
    "uniprot": "A0A044U2S6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2S6"
  },
  {
    "uniprot": "A0A2K6VVZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVZ0"
  },
  {
    "uniprot": "A0A2K6VVW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVW4"
  },
  {
    "uniprot": "A0A044V9E6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9E6"
  },
  {
    "uniprot": "A0A044VHM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHM7"
  },
  {
    "uniprot": "A0A044TL67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TL67"
  },
  {
    "uniprot": "A0A044S6D7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6D7"
  },
  {
    "uniprot": "A0A2K6VV20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VV20"
  },
  {
    "uniprot": "A0A044R0E1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0E1"
  },
  {
    "uniprot": "A0A044U8X0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8X0"
  },
  {
    "uniprot": "A0A044SLZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLZ2"
  },
  {
    "uniprot": "A0A044QJV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJV1"
  },
  {
    "uniprot": "A0A044SEB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEB3"
  },
  {
    "uniprot": "A0A044TIM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIM8"
  },
  {
    "uniprot": "A0A044R945",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R945"
  },
  {
    "uniprot": "A0A2K6VTN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VTN2"
  },
  {
    "uniprot": "A0A158N837",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N837"
  },
  {
    "uniprot": "A0A044T8W6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8W6"
  },
  {
    "uniprot": "A0A044RX39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RX39"
  },
  {
    "uniprot": "A0A044TA92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TA92"
  },
  {
    "uniprot": "A0A044UDU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDU8"
  },
  {
    "uniprot": "A0A044VH86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH86"
  },
  {
    "uniprot": "A0A044V3J5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3J5"
  },
  {
    "uniprot": "A0A158N847",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N847"
  },
  {
    "uniprot": "A0A044U1D4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1D4"
  },
  {
    "uniprot": "A0A044R2H0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2H0"
  },
  {
    "uniprot": "A0A044RXU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXU5"
  },
  {
    "uniprot": "A0A2K6W7Y7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7Y7"
  },
  {
    "uniprot": "A0A044S379",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S379"
  },
  {
    "uniprot": "A0A044R2M1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2M1"
  },
  {
    "uniprot": "A0A044U2F6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2F6"
  },
  {
    "uniprot": "A0A044S0C4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0C4"
  },
  {
    "uniprot": "A0A044T4I8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4I8"
  },
  {
    "uniprot": "A0A044V4H5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4H5"
  },
  {
    "uniprot": "A0A044R1Z4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1Z4"
  },
  {
    "uniprot": "A0A044VDN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDN6"
  },
  {
    "uniprot": "A0A2K6VX77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VX77"
  },
  {
    "uniprot": "A0A044TYU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYU6"
  },
  {
    "uniprot": "A0A2K6VZ99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZ99"
  },
  {
    "uniprot": "A0A044V9F1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9F1"
  },
  {
    "uniprot": "A0A2K6VYR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYR6"
  },
  {
    "uniprot": "A0A044UZV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZV1"
  },
  {
    "uniprot": "A0A044V910",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V910"
  },
  {
    "uniprot": "A0A044TPA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPA6"
  },
  {
    "uniprot": "A0A044RM88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RM88"
  },
  {
    "uniprot": "A0A158N8S5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8S5"
  },
  {
    "uniprot": "A0A044TLI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLI0"
  },
  {
    "uniprot": "A0A044V4C3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4C3"
  },
  {
    "uniprot": "A0A2K6WFB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFB4"
  },
  {
    "uniprot": "A0A044UJA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJA6"
  },
  {
    "uniprot": "A0A2K6WH23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WH23"
  },
  {
    "uniprot": "A0A044V4I1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4I1"
  },
  {
    "uniprot": "A0A044R3H0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3H0"
  },
  {
    "uniprot": "A0A044S2E4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2E4"
  },
  {
    "uniprot": "A0A044V5R2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5R2"
  },
  {
    "uniprot": "A0A044TUK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUK3"
  },
  {
    "uniprot": "A0A044TRS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRS6"
  },
  {
    "uniprot": "A0A044SYP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYP3"
  },
  {
    "uniprot": "A0A044RIZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIZ6"
  },
  {
    "uniprot": "A0A044SIP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIP3"
  },
  {
    "uniprot": "A0A044RW15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW15"
  },
  {
    "uniprot": "A0A044RMK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMK4"
  },
  {
    "uniprot": "A0A044T6N7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6N7"
  },
  {
    "uniprot": "A0A044V2L8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2L8"
  },
  {
    "uniprot": "A0A044SJZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJZ6"
  },
  {
    "uniprot": "A0A2K6VEJ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VEJ0"
  },
  {
    "uniprot": "A0A044QY08",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY08"
  },
  {
    "uniprot": "A0A044S061",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S061"
  },
  {
    "uniprot": "Q9GZ74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q9GZ74"
  },
  {
    "uniprot": "A0A044U4L2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4L2"
  },
  {
    "uniprot": "A0A044TNN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNN3"
  },
  {
    "uniprot": "A0A044R7E2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7E2"
  },
  {
    "uniprot": "A0A044SSJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSJ4"
  },
  {
    "uniprot": "A0A044S2D9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2D9"
  },
  {
    "uniprot": "A0A044S230",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S230"
  },
  {
    "uniprot": "A0A044UZ16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ16"
  },
  {
    "uniprot": "A0A044V3F0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3F0"
  },
  {
    "uniprot": "A0A2K6VPZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPZ5"
  },
  {
    "uniprot": "A0A044TVI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVI1"
  },
  {
    "uniprot": "A0A044TBV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBV4"
  },
  {
    "uniprot": "A0A044V7R3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7R3"
  },
  {
    "uniprot": "A0A044SLA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLA3"
  },
  {
    "uniprot": "A0A044RMG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMG6"
  },
  {
    "uniprot": "A0A044RVR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVR7"
  },
  {
    "uniprot": "A0A044V186",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V186"
  },
  {
    "uniprot": "A0A2K6VGU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VGU8"
  },
  {
    "uniprot": "A0A044T4M5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4M5"
  },
  {
    "uniprot": "A0A044UBL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBL0"
  },
  {
    "uniprot": "A0A044TNF9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNF9"
  },
  {
    "uniprot": "A0A044QTN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTN3"
  },
  {
    "uniprot": "A0A044T0S4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0S4"
  },
  {
    "uniprot": "A0A044SZM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZM3"
  },
  {
    "uniprot": "A0A2K6WNV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WNV8"
  },
  {
    "uniprot": "A0A044QND9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QND9"
  },
  {
    "uniprot": "A0A044U470",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U470"
  },
  {
    "uniprot": "A0A044RPR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPR7"
  },
  {
    "uniprot": "A0A044RHX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHX4"
  },
  {
    "uniprot": "A0A044S9F1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9F1"
  },
  {
    "uniprot": "A0A044SSI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSI7"
  },
  {
    "uniprot": "A0A044TWF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWF8"
  },
  {
    "uniprot": "A0A044QN59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QN59"
  },
  {
    "uniprot": "A0A044RWX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWX9"
  },
  {
    "uniprot": "A0A044UED0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UED0"
  },
  {
    "uniprot": "A0A158N817",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N817"
  },
  {
    "uniprot": "A0A2K6W993",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W993"
  },
  {
    "uniprot": "A0A044T354",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T354"
  },
  {
    "uniprot": "A0A044V225",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V225"
  },
  {
    "uniprot": "A0A044TC45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC45"
  },
  {
    "uniprot": "A0A044R7M0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7M0"
  },
  {
    "uniprot": "A0A044V3A4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3A4"
  },
  {
    "uniprot": "A0A044QUT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUT9"
  },
  {
    "uniprot": "A0A044UHT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHT4"
  },
  {
    "uniprot": "A0A044UK15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UK15"
  },
  {
    "uniprot": "A0A044RFM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFM4"
  },
  {
    "uniprot": "A0A044SJR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJR2"
  },
  {
    "uniprot": "A0A044SCU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCU5"
  },
  {
    "uniprot": "A0A044SM21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM21"
  },
  {
    "uniprot": "A0A044V5C5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5C5"
  },
  {
    "uniprot": "A0A044SJK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJK9"
  },
  {
    "uniprot": "A0A044SEW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEW5"
  },
  {
    "uniprot": "A0A044RAJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAJ4"
  },
  {
    "uniprot": "A0A044V7J3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7J3"
  },
  {
    "uniprot": "A0A044UHX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHX1"
  },
  {
    "uniprot": "A0A044TK75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK75"
  },
  {
    "uniprot": "A0A044T8H3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8H3"
  },
  {
    "uniprot": "A0A2K6VNU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VNU9"
  },
  {
    "uniprot": "A0A044SB54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB54"
  },
  {
    "uniprot": "A0A044U5T5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5T5"
  },
  {
    "uniprot": "A0A044SER8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SER8"
  },
  {
    "uniprot": "A0A044T8T0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8T0"
  },
  {
    "uniprot": "A0A044SFZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFZ0"
  },
  {
    "uniprot": "A0A044UCF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCF6"
  },
  {
    "uniprot": "A0A044UP56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UP56"
  },
  {
    "uniprot": "A0A044QLR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLR8"
  },
  {
    "uniprot": "A0A044QTA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTA1"
  },
  {
    "uniprot": "A0A044SHG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHG1"
  },
  {
    "uniprot": "A0A044TSN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSN1"
  },
  {
    "uniprot": "A0A044TQY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQY6"
  },
  {
    "uniprot": "A0A044QY95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY95"
  },
  {
    "uniprot": "A0A044S4G2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4G2"
  },
  {
    "uniprot": "A0A044VHN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHN4"
  },
  {
    "uniprot": "A0A044TTS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTS7"
  },
  {
    "uniprot": "A0A044UCN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCN5"
  },
  {
    "uniprot": "A0A044T4D2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4D2"
  },
  {
    "uniprot": "A0A044U0K0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0K0"
  },
  {
    "uniprot": "A0A044R479",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R479"
  },
  {
    "uniprot": "A0A044TUZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUZ4"
  },
  {
    "uniprot": "A0A044TIC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIC9"
  },
  {
    "uniprot": "A0A044RR08",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RR08"
  },
  {
    "uniprot": "A0A044R4P6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4P6"
  },
  {
    "uniprot": "A0A044SGR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGR8"
  },
  {
    "uniprot": "A0A044TN02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN02"
  },
  {
    "uniprot": "A0A158N873",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N873"
  },
  {
    "uniprot": "A0A044V9U4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9U4"
  },
  {
    "uniprot": "A0A044RPA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPA3"
  },
  {
    "uniprot": "A0A044VAZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAZ3"
  },
  {
    "uniprot": "A0A2K6VMG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMG7"
  },
  {
    "uniprot": "A0A044V4C9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4C9"
  },
  {
    "uniprot": "A0A044UCV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCV1"
  },
  {
    "uniprot": "A0A044T933",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T933"
  },
  {
    "uniprot": "A0A044S6N4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6N4"
  },
  {
    "uniprot": "A0A044UYD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYD1"
  },
  {
    "uniprot": "A0A044U493",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U493"
  },
  {
    "uniprot": "A0A2K6WMI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMI7"
  },
  {
    "uniprot": "A0A044V5U5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5U5"
  },
  {
    "uniprot": "A0A2K6WLZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLZ6"
  },
  {
    "uniprot": "A0A044RCG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCG1"
  },
  {
    "uniprot": "A0A044SY49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY49"
  },
  {
    "uniprot": "A0A044V7I6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7I6"
  },
  {
    "uniprot": "A0A044UBX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBX9"
  },
  {
    "uniprot": "A0A044UN91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UN91"
  },
  {
    "uniprot": "A0A044UB77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UB77"
  },
  {
    "uniprot": "A0A158N898",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N898"
  },
  {
    "uniprot": "A0A044RGZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGZ9"
  },
  {
    "uniprot": "A0A044RR49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RR49"
  },
  {
    "uniprot": "A0A044UQX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQX4"
  },
  {
    "uniprot": "A0A044QUG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUG4"
  },
  {
    "uniprot": "A0A044TFQ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFQ2"
  },
  {
    "uniprot": "A0A044TID8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TID8"
  },
  {
    "uniprot": "A0A044RB47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RB47"
  },
  {
    "uniprot": "A0A044TQJ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQJ2"
  },
  {
    "uniprot": "A0A044QL28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL28"
  },
  {
    "uniprot": "A0A044V6D0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6D0"
  },
  {
    "uniprot": "A0A044UFA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFA7"
  },
  {
    "uniprot": "A0A044QVD2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVD2"
  },
  {
    "uniprot": "A0A6F1C427",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A6F1C427"
  },
  {
    "uniprot": "A0A044SNJ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNJ5"
  },
  {
    "uniprot": "A0A2K6VUU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUU7"
  },
  {
    "uniprot": "A0A2K6VUW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUW7"
  },
  {
    "uniprot": "A0A044UVK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVK9"
  },
  {
    "uniprot": "A0A044UQZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQZ8"
  },
  {
    "uniprot": "A0A044UVG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVG1"
  },
  {
    "uniprot": "A0A044T8L3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8L3"
  },
  {
    "uniprot": "A0A044T8Y6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8Y6"
  },
  {
    "uniprot": "A0A044UJW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJW4"
  },
  {
    "uniprot": "A0A044U0A4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0A4"
  },
  {
    "uniprot": "A0A044UYV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYV9"
  },
  {
    "uniprot": "A0A2K6WLC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLC1"
  },
  {
    "uniprot": "A0A044S0N4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0N4"
  },
  {
    "uniprot": "A0A2K6VPW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPW6"
  },
  {
    "uniprot": "A0A044V8A3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8A3"
  },
  {
    "uniprot": "A0A044SN33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN33"
  },
  {
    "uniprot": "A0A044QX37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QX37"
  },
  {
    "uniprot": "A0A044VAB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAB8"
  },
  {
    "uniprot": "A0A2K6VZQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZQ0"
  },
  {
    "uniprot": "A0A044V2F8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2F8"
  },
  {
    "uniprot": "A0A2K6W8S8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8S8"
  },
  {
    "uniprot": "A0A044SZ81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ81"
  },
  {
    "uniprot": "A0A044U8K7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8K7"
  },
  {
    "uniprot": "A0A044R276",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R276"
  },
  {
    "uniprot": "A0A044VCB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCB8"
  },
  {
    "uniprot": "A0A044QQS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQS6"
  },
  {
    "uniprot": "A0A044R821",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R821"
  },
  {
    "uniprot": "A0A044T658",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T658"
  },
  {
    "uniprot": "A0A044TRH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRH5"
  },
  {
    "uniprot": "A0A044QXN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXN0"
  },
  {
    "uniprot": "A0A044RID1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RID1"
  },
  {
    "uniprot": "A0A044UL71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL71"
  },
  {
    "uniprot": "A0A044UBE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBE2"
  },
  {
    "uniprot": "A0A044QW63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW63"
  },
  {
    "uniprot": "A0A044RUH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUH3"
  },
  {
    "uniprot": "A0A044UWC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWC6"
  },
  {
    "uniprot": "A0A044S2I6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2I6"
  },
  {
    "uniprot": "A0A2K6W1Y9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1Y9"
  },
  {
    "uniprot": "A0A044RDJ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDJ0"
  },
  {
    "uniprot": "A0A044UNW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNW8"
  },
  {
    "uniprot": "A0A044RR39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RR39"
  },
  {
    "uniprot": "A0A044UXN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXN3"
  },
  {
    "uniprot": "A0A044R7A5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7A5"
  },
  {
    "uniprot": "A0A044UBV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBV2"
  },
  {
    "uniprot": "A0A044R230",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R230"
  },
  {
    "uniprot": "A0A044UIP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIP4"
  },
  {
    "uniprot": "A0A044TAK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAK8"
  },
  {
    "uniprot": "A0A044T5B5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5B5"
  },
  {
    "uniprot": "A0A044QYJ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYJ2"
  },
  {
    "uniprot": "A0A044QZJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZJ4"
  },
  {
    "uniprot": "A0A044QP74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP74"
  },
  {
    "uniprot": "A0A044T6K8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6K8"
  },
  {
    "uniprot": "A0A044SIE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIE5"
  },
  {
    "uniprot": "A0A044RF80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RF80"
  },
  {
    "uniprot": "A0A044SSR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSR4"
  },
  {
    "uniprot": "A0A2K6WG98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WG98"
  },
  {
    "uniprot": "A0A044S2A3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2A3"
  },
  {
    "uniprot": "A0A044SKT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKT1"
  },
  {
    "uniprot": "A0A044SHI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHI4"
  },
  {
    "uniprot": "A0A044TNR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNR6"
  },
  {
    "uniprot": "A0A044TKG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKG8"
  },
  {
    "uniprot": "A0A158N866",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N866"
  },
  {
    "uniprot": "A0A044RUM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUM3"
  },
  {
    "uniprot": "A0A044SIF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIF5"
  },
  {
    "uniprot": "A0A044SJY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJY6"
  },
  {
    "uniprot": "A0A044T2K5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2K5"
  },
  {
    "uniprot": "A0A044TWP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWP2"
  },
  {
    "uniprot": "A0A044RJB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJB5"
  },
  {
    "uniprot": "A0A044R4X1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4X1"
  },
  {
    "uniprot": "A0A044TL31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TL31"
  },
  {
    "uniprot": "A0A044SU83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU83"
  },
  {
    "uniprot": "A0A044V9T7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9T7"
  },
  {
    "uniprot": "A0A044U4H3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4H3"
  },
  {
    "uniprot": "A0A044S8N9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8N9"
  },
  {
    "uniprot": "A0A044UZ23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ23"
  },
  {
    "uniprot": "A0A044SE34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SE34"
  },
  {
    "uniprot": "A0A044SAQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAQ6"
  },
  {
    "uniprot": "A0A044UPY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPY0"
  },
  {
    "uniprot": "A0A044SNI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNI4"
  },
  {
    "uniprot": "A0A044U7Q2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7Q2"
  },
  {
    "uniprot": "A0A044R843",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R843"
  },
  {
    "uniprot": "A0A044TDZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDZ0"
  },
  {
    "uniprot": "A0A044T7T9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7T9"
  },
  {
    "uniprot": "A0A044RML5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RML5"
  },
  {
    "uniprot": "A0A044SP90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SP90"
  },
  {
    "uniprot": "A0A044V9N4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9N4"
  },
  {
    "uniprot": "A0A044UX58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX58"
  },
  {
    "uniprot": "A0A044RBG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBG6"
  },
  {
    "uniprot": "A0A044TAH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAH4"
  },
  {
    "uniprot": "A0A044QY62",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY62"
  },
  {
    "uniprot": "A0A044V6E8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6E8"
  },
  {
    "uniprot": "A0A044T3P3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3P3"
  },
  {
    "uniprot": "A0A044V406",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V406"
  },
  {
    "uniprot": "A0A2K6WC30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WC30"
  },
  {
    "uniprot": "A0A044SFD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFD3"
  },
  {
    "uniprot": "A0A044UQ59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQ59"
  },
  {
    "uniprot": "A0A044SM13",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM13"
  },
  {
    "uniprot": "A0A044SJL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJL4"
  },
  {
    "uniprot": "A0A044SD01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD01"
  },
  {
    "uniprot": "A0A044R137",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R137"
  },
  {
    "uniprot": "A0A2K6W003",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W003"
  },
  {
    "uniprot": "A0A044TME3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TME3"
  },
  {
    "uniprot": "A0A044SJB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJB9"
  },
  {
    "uniprot": "A0A044VBB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBB3"
  },
  {
    "uniprot": "A0A2K6VX37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VX37"
  },
  {
    "uniprot": "A0A044TF96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TF96"
  },
  {
    "uniprot": "A0A044RYF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYF2"
  },
  {
    "uniprot": "A0A044TPF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPF4"
  },
  {
    "uniprot": "A0A044QVJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVJ3"
  },
  {
    "uniprot": "A0A044R3T7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3T7"
  },
  {
    "uniprot": "A0A2K6VZD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZD1"
  },
  {
    "uniprot": "A0A2K6WIL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIL6"
  },
  {
    "uniprot": "A0A044TLX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLX7"
  },
  {
    "uniprot": "A0A044U3W9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3W9"
  },
  {
    "uniprot": "A0A2K6VI97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VI97"
  },
  {
    "uniprot": "A0A044VDY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDY9"
  },
  {
    "uniprot": "A0A044T2J9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2J9"
  },
  {
    "uniprot": "A0A044UGW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGW2"
  },
  {
    "uniprot": "A0A044TQC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQC8"
  },
  {
    "uniprot": "A0A044QN15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QN15"
  },
  {
    "uniprot": "A0A044RNJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNJ1"
  },
  {
    "uniprot": "A0A044V424",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V424"
  },
  {
    "uniprot": "A0A044VIE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIE3"
  },
  {
    "uniprot": "A0A044UYB2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYB2"
  },
  {
    "uniprot": "A0A2K6VPX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPX1"
  },
  {
    "uniprot": "A0A044V635",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V635"
  },
  {
    "uniprot": "A0A044TXN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXN0"
  },
  {
    "uniprot": "A0A044TPA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPA4"
  },
  {
    "uniprot": "A0A044TJ94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJ94"
  },
  {
    "uniprot": "A0A044V4B0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4B0"
  },
  {
    "uniprot": "A0A044TJ36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJ36"
  },
  {
    "uniprot": "A0A044S9Q4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9Q4"
  },
  {
    "uniprot": "A0A044RMQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMQ9"
  },
  {
    "uniprot": "A0A044SAT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAT2"
  },
  {
    "uniprot": "A0A2K6VUF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUF0"
  },
  {
    "uniprot": "A0A044SRL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRL8"
  },
  {
    "uniprot": "A0A044RVI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVI1"
  },
  {
    "uniprot": "A0A044VHA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHA0"
  },
  {
    "uniprot": "A0A2K6WBJ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBJ0"
  },
  {
    "uniprot": "A0A044S9X6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9X6"
  },
  {
    "uniprot": "A0A044UUG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUG6"
  },
  {
    "uniprot": "A0A044UIT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIT0"
  },
  {
    "uniprot": "A0A044UCZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCZ4"
  },
  {
    "uniprot": "A0A044ULT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULT9"
  },
  {
    "uniprot": "A0A2K6VT79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VT79"
  },
  {
    "uniprot": "A0A2K6WG31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WG31"
  },
  {
    "uniprot": "A0A044QM86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QM86"
  },
  {
    "uniprot": "A0A044TDF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDF5"
  },
  {
    "uniprot": "A0A044RXX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXX7"
  },
  {
    "uniprot": "A0A044UIU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIU3"
  },
  {
    "uniprot": "A0A044QSY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSY4"
  },
  {
    "uniprot": "A0A044USW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USW5"
  },
  {
    "uniprot": "A0A044V9T3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9T3"
  },
  {
    "uniprot": "A0A044ULB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULB7"
  },
  {
    "uniprot": "A0A044SV63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SV63"
  },
  {
    "uniprot": "A0A158N814",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N814"
  },
  {
    "uniprot": "A0A158N7Y3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Y3"
  },
  {
    "uniprot": "A0A2K6WKV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKV9"
  },
  {
    "uniprot": "A0A044STB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STB3"
  },
  {
    "uniprot": "A0A044T0Z5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0Z5"
  },
  {
    "uniprot": "A0A044RUB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUB6"
  },
  {
    "uniprot": "A0A044SCD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCD6"
  },
  {
    "uniprot": "A0A2K6VS30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VS30"
  },
  {
    "uniprot": "A0A044T887",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T887"
  },
  {
    "uniprot": "A0A044QLT3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLT3"
  },
  {
    "uniprot": "A0A044V4I4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4I4"
  },
  {
    "uniprot": "A0A044RDF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDF6"
  },
  {
    "uniprot": "A0A2K6W2T0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2T0"
  },
  {
    "uniprot": "A0A044V552",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V552"
  },
  {
    "uniprot": "A0A2K6VLL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLL2"
  },
  {
    "uniprot": "A0A044VE43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VE43"
  },
  {
    "uniprot": "A0A044QZ89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ89"
  },
  {
    "uniprot": "A0A044V115",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V115"
  },
  {
    "uniprot": "A0A044SLK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLK9"
  },
  {
    "uniprot": "A0A044SGE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGE6"
  },
  {
    "uniprot": "A0A044R4H6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4H6"
  },
  {
    "uniprot": "A0A2K6VU87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VU87"
  },
  {
    "uniprot": "A0A044UZ36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ36"
  },
  {
    "uniprot": "A0A044UUZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUZ4"
  },
  {
    "uniprot": "A0A044QRY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRY4"
  },
  {
    "uniprot": "A0A044VBK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBK1"
  },
  {
    "uniprot": "A0A044R9F6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9F6"
  },
  {
    "uniprot": "A0A044V8S2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8S2"
  },
  {
    "uniprot": "A0A044VIU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIU8"
  },
  {
    "uniprot": "A0A044TY45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TY45"
  },
  {
    "uniprot": "A0A044UU97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UU97"
  },
  {
    "uniprot": "A0A044V4T1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4T1"
  },
  {
    "uniprot": "A0A158N7T7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7T7"
  },
  {
    "uniprot": "A0A044RY22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY22"
  },
  {
    "uniprot": "A0A044UNH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNH4"
  },
  {
    "uniprot": "A0A044SNU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNU0"
  },
  {
    "uniprot": "A0A044TGJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGJ7"
  },
  {
    "uniprot": "A0A044QKN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKN0"
  },
  {
    "uniprot": "A0A044V998",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V998"
  },
  {
    "uniprot": "A0A044ST10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ST10"
  },
  {
    "uniprot": "A0A2K6W1X9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1X9"
  },
  {
    "uniprot": "A0A158N7U4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7U4"
  },
  {
    "uniprot": "A0A2K6WJQ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJQ8"
  },
  {
    "uniprot": "A0A044UN56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UN56"
  },
  {
    "uniprot": "A0A044VCG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCG4"
  },
  {
    "uniprot": "A0A044UFH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFH0"
  },
  {
    "uniprot": "A0A2K6VWV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWV1"
  },
  {
    "uniprot": "A0A044QWW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWW4"
  },
  {
    "uniprot": "A0A044UC25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC25"
  },
  {
    "uniprot": "A0A2K6WDJ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDJ2"
  },
  {
    "uniprot": "A0A044R0B8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0B8"
  },
  {
    "uniprot": "A0A044V436",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V436"
  },
  {
    "uniprot": "A0A044UP27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UP27"
  },
  {
    "uniprot": "A0A044QZW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZW6"
  },
  {
    "uniprot": "A0A044U202",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U202"
  },
  {
    "uniprot": "A0A044RRR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRR8"
  },
  {
    "uniprot": "A0A044UC73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC73"
  },
  {
    "uniprot": "A0A044UGV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGV1"
  },
  {
    "uniprot": "A0A044R6B3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6B3"
  },
  {
    "uniprot": "A0A044V787",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V787"
  },
  {
    "uniprot": "A0A044UXX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXX1"
  },
  {
    "uniprot": "A0A158N803",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N803"
  },
  {
    "uniprot": "A0A044SZW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZW2"
  },
  {
    "uniprot": "A0A044VDG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDG5"
  },
  {
    "uniprot": "A0A044TWK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWK9"
  },
  {
    "uniprot": "A0A044RXS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXS4"
  },
  {
    "uniprot": "A0A2K6W686",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W686"
  },
  {
    "uniprot": "A0A2K6W3C5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3C5"
  },
  {
    "uniprot": "A0A044U6T8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6T8"
  },
  {
    "uniprot": "A0A044S5C7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5C7"
  },
  {
    "uniprot": "A0A044V4G7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4G7"
  },
  {
    "uniprot": "A0A044RDT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDT4"
  },
  {
    "uniprot": "A0A044TWN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWN3"
  },
  {
    "uniprot": "A0A044QMW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMW5"
  },
  {
    "uniprot": "A0A044QS72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QS72"
  },
  {
    "uniprot": "A0A044UC80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC80"
  },
  {
    "uniprot": "A0A044R8A1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8A1"
  },
  {
    "uniprot": "A0A044VC66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC66"
  },
  {
    "uniprot": "A0A044V9A2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9A2"
  },
  {
    "uniprot": "A0A044RIU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIU5"
  },
  {
    "uniprot": "A0A044V1H5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1H5"
  },
  {
    "uniprot": "A0A044VHK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHK9"
  },
  {
    "uniprot": "A0A044S0W9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0W9"
  },
  {
    "uniprot": "A0A044V1A5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1A5"
  },
  {
    "uniprot": "A0A044THH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THH0"
  },
  {
    "uniprot": "A0A044SNG3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNG3"
  },
  {
    "uniprot": "A0A044UMR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMR4"
  },
  {
    "uniprot": "A0A044S0T5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0T5"
  },
  {
    "uniprot": "A0A044TV08",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV08"
  },
  {
    "uniprot": "A0A044QUY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUY4"
  },
  {
    "uniprot": "A0A044VIV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIV6"
  },
  {
    "uniprot": "A0A044TPU2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPU2"
  },
  {
    "uniprot": "A0A044THQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THQ7"
  },
  {
    "uniprot": "A0A044UU98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UU98"
  },
  {
    "uniprot": "A0A044T170",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T170"
  },
  {
    "uniprot": "A0A044SG87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG87"
  },
  {
    "uniprot": "A0A044V8Y7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Y7"
  },
  {
    "uniprot": "A0A2K6W6S1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6S1"
  },
  {
    "uniprot": "A0A044UEF9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEF9"
  },
  {
    "uniprot": "A0A044UVG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVG8"
  },
  {
    "uniprot": "A0A044R758",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R758"
  },
  {
    "uniprot": "A0A044RX55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RX55"
  },
  {
    "uniprot": "A0A044V0N2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0N2"
  },
  {
    "uniprot": "A0A044V4Q9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4Q9"
  },
  {
    "uniprot": "A0A044RVL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVL0"
  },
  {
    "uniprot": "A0A044T2U3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2U3"
  },
  {
    "uniprot": "A0A044SL96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SL96"
  },
  {
    "uniprot": "A0A044S5A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5A9"
  },
  {
    "uniprot": "A0A044TK21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK21"
  },
  {
    "uniprot": "A0A044SVW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVW3"
  },
  {
    "uniprot": "A0A044SI04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI04"
  },
  {
    "uniprot": "A0A044V4A0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4A0"
  },
  {
    "uniprot": "A0A044TSZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSZ1"
  },
  {
    "uniprot": "A0A2K6WIX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIX3"
  },
  {
    "uniprot": "A0A044UI27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI27"
  },
  {
    "uniprot": "A0A044V8N6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8N6"
  },
  {
    "uniprot": "A0A044R0Q1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0Q1"
  },
  {
    "uniprot": "A0A044UKS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKS0"
  },
  {
    "uniprot": "A0A044TXA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXA3"
  },
  {
    "uniprot": "A0A044RZN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZN9"
  },
  {
    "uniprot": "A0A044QSL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSL0"
  },
  {
    "uniprot": "A0A044RYM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYM3"
  },
  {
    "uniprot": "A0A044SZ09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ09"
  },
  {
    "uniprot": "A0A044TK79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK79"
  },
  {
    "uniprot": "A0A044SLR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLR7"
  },
  {
    "uniprot": "A0A044SLF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLF6"
  },
  {
    "uniprot": "A0A044QJU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJU3"
  },
  {
    "uniprot": "A0A044SHT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHT9"
  },
  {
    "uniprot": "A0A044TXI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXI3"
  },
  {
    "uniprot": "A0A044S068",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S068"
  },
  {
    "uniprot": "A0A044SJQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJQ7"
  },
  {
    "uniprot": "A0A044S2K5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2K5"
  },
  {
    "uniprot": "A0A044R112",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R112"
  },
  {
    "uniprot": "A0A044SVV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVV1"
  },
  {
    "uniprot": "A0A044UVR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVR6"
  },
  {
    "uniprot": "A0A044QK32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK32"
  },
  {
    "uniprot": "A0A158N7T1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7T1"
  },
  {
    "uniprot": "A0A044T8I3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8I3"
  },
  {
    "uniprot": "A0A044UM37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UM37"
  },
  {
    "uniprot": "A0A044SV10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SV10"
  },
  {
    "uniprot": "A0A2K6WD61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WD61"
  },
  {
    "uniprot": "A0A044VF13",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VF13"
  },
  {
    "uniprot": "A0A044V9J1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9J1"
  },
  {
    "uniprot": "A0A044T0V0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0V0"
  },
  {
    "uniprot": "A0A044VI72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VI72"
  },
  {
    "uniprot": "A0A044TGI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGI7"
  },
  {
    "uniprot": "A0A044RGV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGV7"
  },
  {
    "uniprot": "A0A044V9Q2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9Q2"
  },
  {
    "uniprot": "A0A044SYN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYN1"
  },
  {
    "uniprot": "A0A044T125",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T125"
  },
  {
    "uniprot": "A0A044RP53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RP53"
  },
  {
    "uniprot": "A0A044UMP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMP4"
  },
  {
    "uniprot": "A0A044TW85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TW85"
  },
  {
    "uniprot": "A0A044S112",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S112"
  },
  {
    "uniprot": "A0A044RC03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC03"
  },
  {
    "uniprot": "A0A044R014",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R014"
  },
  {
    "uniprot": "A0A044SA65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SA65"
  },
  {
    "uniprot": "A0A044U139",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U139"
  },
  {
    "uniprot": "A0A044U702",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U702"
  },
  {
    "uniprot": "A0A044T5Q9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5Q9"
  },
  {
    "uniprot": "A0A044SS96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS96"
  },
  {
    "uniprot": "A0A044SN61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN61"
  },
  {
    "uniprot": "A0A044TLN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLN5"
  },
  {
    "uniprot": "A0A044U458",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U458"
  },
  {
    "uniprot": "A0A044TWZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWZ2"
  },
  {
    "uniprot": "A0A044S0W6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0W6"
  },
  {
    "uniprot": "A0A044U7M3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7M3"
  },
  {
    "uniprot": "A0A044QQD0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQD0"
  },
  {
    "uniprot": "A0A044QMR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMR3"
  },
  {
    "uniprot": "A0A2K6WKW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKW7"
  },
  {
    "uniprot": "A0A044SHC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHC9"
  },
  {
    "uniprot": "A0A158N800",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N800"
  },
  {
    "uniprot": "A0A044U3C1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3C1"
  },
  {
    "uniprot": "A0A044RX66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RX66"
  },
  {
    "uniprot": "A0A044TJA8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJA8"
  },
  {
    "uniprot": "A0A044TJK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJK1"
  },
  {
    "uniprot": "A0A044VI90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VI90"
  },
  {
    "uniprot": "A0A044VH83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH83"
  },
  {
    "uniprot": "A0A044UEU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEU8"
  },
  {
    "uniprot": "A0A044QUL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUL0"
  },
  {
    "uniprot": "A0A2K6VDK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDK5"
  },
  {
    "uniprot": "A0A044VET5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VET5"
  },
  {
    "uniprot": "A0A044QSA2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSA2"
  },
  {
    "uniprot": "A0A044UZ80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ80"
  },
  {
    "uniprot": "A0A044QZP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZP7"
  },
  {
    "uniprot": "A0A044V532",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V532"
  },
  {
    "uniprot": "A0A044QXP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXP6"
  },
  {
    "uniprot": "A0A044U2B4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2B4"
  },
  {
    "uniprot": "A0A044SCN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCN3"
  },
  {
    "uniprot": "A0A044S791",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S791"
  },
  {
    "uniprot": "A0A044SM39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM39"
  },
  {
    "uniprot": "A0A2K6VGY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VGY5"
  },
  {
    "uniprot": "A0A044U4L8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4L8"
  },
  {
    "uniprot": "A0A044RL93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RL93"
  },
  {
    "uniprot": "A0A044V364",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V364"
  },
  {
    "uniprot": "A0A044S2M0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2M0"
  },
  {
    "uniprot": "A0A044V3B3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3B3"
  },
  {
    "uniprot": "A0A044SY10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY10"
  },
  {
    "uniprot": "A0A044TRF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRF8"
  },
  {
    "uniprot": "A0A044SZ20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ20"
  },
  {
    "uniprot": "A0A044UQN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQN9"
  },
  {
    "uniprot": "A0A044R2Y8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2Y8"
  },
  {
    "uniprot": "A0A044TZH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZH7"
  },
  {
    "uniprot": "A0A044TVP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVP1"
  },
  {
    "uniprot": "A0A044SCA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCA6"
  },
  {
    "uniprot": "A0A044TRQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRQ1"
  },
  {
    "uniprot": "A0A044TGP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGP9"
  },
  {
    "uniprot": "A0A044R8H5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8H5"
  },
  {
    "uniprot": "A0A044VC09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC09"
  },
  {
    "uniprot": "A0A044VF87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VF87"
  },
  {
    "uniprot": "A0A044V273",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V273"
  },
  {
    "uniprot": "A0A044RUF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUF1"
  },
  {
    "uniprot": "A0A044RXU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXU1"
  },
  {
    "uniprot": "A0A044SW86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SW86"
  },
  {
    "uniprot": "A0A044UZ69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ69"
  },
  {
    "uniprot": "A0A044S609",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S609"
  },
  {
    "uniprot": "A0A2K6VY94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VY94"
  },
  {
    "uniprot": "A0A044S3I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3I9"
  },
  {
    "uniprot": "A0A044UZ12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ12"
  },
  {
    "uniprot": "A0A044VDZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDZ6"
  },
  {
    "uniprot": "A0A044TF51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TF51"
  },
  {
    "uniprot": "A0A044VA21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA21"
  },
  {
    "uniprot": "A0A044UVN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVN7"
  },
  {
    "uniprot": "A0A044U019",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U019"
  },
  {
    "uniprot": "A0A2K6VP71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VP71"
  },
  {
    "uniprot": "A0A044V8Y1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Y1"
  },
  {
    "uniprot": "A0A044T985",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T985"
  },
  {
    "uniprot": "A0A2K6VKI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKI1"
  },
  {
    "uniprot": "A0A044REX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REX9"
  },
  {
    "uniprot": "A0A044TAR5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAR5"
  },
  {
    "uniprot": "A0A044V726",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V726"
  },
  {
    "uniprot": "A0A044V9M3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9M3"
  },
  {
    "uniprot": "A0A2K6W944",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W944"
  },
  {
    "uniprot": "A0A044R679",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R679"
  },
  {
    "uniprot": "A0A044U440",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U440"
  },
  {
    "uniprot": "A0A044SAR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAR2"
  },
  {
    "uniprot": "A0A044UGS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGS2"
  },
  {
    "uniprot": "A0A044TVD0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVD0"
  },
  {
    "uniprot": "A0A044TLD4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLD4"
  },
  {
    "uniprot": "A0A044U8N0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8N0"
  },
  {
    "uniprot": "A0A044UCR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCR6"
  },
  {
    "uniprot": "A0A044R6Y8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6Y8"
  },
  {
    "uniprot": "A0A044TZS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZS6"
  },
  {
    "uniprot": "A0A158N7Z4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Z4"
  },
  {
    "uniprot": "A0A044TIU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIU5"
  },
  {
    "uniprot": "A0A044QQJ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQJ0"
  },
  {
    "uniprot": "A0A044RM78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RM78"
  },
  {
    "uniprot": "A0A044RL38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RL38"
  },
  {
    "uniprot": "A0A044V9S5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9S5"
  },
  {
    "uniprot": "A0A044TJ50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJ50"
  },
  {
    "uniprot": "A0A2K6VU16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VU16"
  },
  {
    "uniprot": "A0A044R393",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R393"
  },
  {
    "uniprot": "A0A2K6VY95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VY95"
  },
  {
    "uniprot": "A0A044SD50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD50"
  },
  {
    "uniprot": "A0A044QVJ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVJ2"
  },
  {
    "uniprot": "A0A044TTG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTG5"
  },
  {
    "uniprot": "A0A044TVW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVW3"
  },
  {
    "uniprot": "A0A044UDD9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDD9"
  },
  {
    "uniprot": "A0A044QNY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNY6"
  },
  {
    "uniprot": "A0A044TXV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXV9"
  },
  {
    "uniprot": "A0A044RFL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFL6"
  },
  {
    "uniprot": "A0A044UAT3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAT3"
  },
  {
    "uniprot": "A0A044QW48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW48"
  },
  {
    "uniprot": "A0A044UUU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUU1"
  },
  {
    "uniprot": "A0A2K6VQM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQM2"
  },
  {
    "uniprot": "A0A044V9K3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9K3"
  },
  {
    "uniprot": "A0A044TXD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXD6"
  },
  {
    "uniprot": "A0A044RTY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTY5"
  },
  {
    "uniprot": "A0A044RG32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RG32"
  },
  {
    "uniprot": "A0A044UBY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBY6"
  },
  {
    "uniprot": "A0A044SJW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJW9"
  },
  {
    "uniprot": "A0A2K6VF79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VF79"
  },
  {
    "uniprot": "A0A158N845",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N845"
  },
  {
    "uniprot": "A0A2K6WGV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGV4"
  },
  {
    "uniprot": "A0A044R2G0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2G0"
  },
  {
    "uniprot": "A0A044RG19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RG19"
  },
  {
    "uniprot": "A0A044STJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STJ4"
  },
  {
    "uniprot": "A0A044TEG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEG0"
  },
  {
    "uniprot": "A0A044TD52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TD52"
  },
  {
    "uniprot": "A0A044V4D8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4D8"
  },
  {
    "uniprot": "A0A2K6VHT3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHT3"
  },
  {
    "uniprot": "A0A044VIP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIP7"
  },
  {
    "uniprot": "A0A044T2M5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2M5"
  },
  {
    "uniprot": "A0A044QSW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSW4"
  },
  {
    "uniprot": "A0A044VIQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIQ4"
  },
  {
    "uniprot": "A0A2K6W6G2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6G2"
  },
  {
    "uniprot": "A0A044S9E6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9E6"
  },
  {
    "uniprot": "A0A044SZ99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ99"
  },
  {
    "uniprot": "A0A044RPX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPX9"
  },
  {
    "uniprot": "A0A044U2K0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2K0"
  },
  {
    "uniprot": "A0A044V503",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V503"
  },
  {
    "uniprot": "A0A044QLI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLI8"
  },
  {
    "uniprot": "A0A044TNF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNF2"
  },
  {
    "uniprot": "A0A044V978",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V978"
  },
  {
    "uniprot": "A0A158N8P5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8P5"
  },
  {
    "uniprot": "A0A044S694",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S694"
  },
  {
    "uniprot": "A0A044TI16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TI16"
  },
  {
    "uniprot": "A0A044TGP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGP3"
  },
  {
    "uniprot": "A0A044VDH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDH8"
  },
  {
    "uniprot": "A0A044VG91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VG91"
  },
  {
    "uniprot": "A0A044V9Q9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9Q9"
  },
  {
    "uniprot": "A0A044TP95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TP95"
  },
  {
    "uniprot": "A0A044S1T2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1T2"
  },
  {
    "uniprot": "A0A044QQW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQW0"
  },
  {
    "uniprot": "A0A2K6W9V4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9V4"
  },
  {
    "uniprot": "A0A2K6VWA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWA1"
  },
  {
    "uniprot": "A0A044V380",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V380"
  },
  {
    "uniprot": "A0A044UG69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UG69"
  },
  {
    "uniprot": "A0A044SGD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGD8"
  },
  {
    "uniprot": "A0A044V7I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7I9"
  },
  {
    "uniprot": "A0A044QLW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLW3"
  },
  {
    "uniprot": "A0A044SNB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNB0"
  },
  {
    "uniprot": "A0A044V3E0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3E0"
  },
  {
    "uniprot": "A0A044QTS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTS7"
  },
  {
    "uniprot": "A0A044T372",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T372"
  },
  {
    "uniprot": "A0A044UID6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UID6"
  },
  {
    "uniprot": "A0A044V488",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V488"
  },
  {
    "uniprot": "A0A044RLW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLW3"
  },
  {
    "uniprot": "A0A044U026",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U026"
  },
  {
    "uniprot": "A0A044V3X8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3X8"
  },
  {
    "uniprot": "A0A044T2J4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2J4"
  },
  {
    "uniprot": "A0A044RSY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSY2"
  },
  {
    "uniprot": "A0A044T3H7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3H7"
  },
  {
    "uniprot": "A0A044RU18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU18"
  },
  {
    "uniprot": "A0A044SRM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRM4"
  },
  {
    "uniprot": "A0A044TM54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TM54"
  },
  {
    "uniprot": "A0A044U373",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U373"
  },
  {
    "uniprot": "A0A044T100",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T100"
  },
  {
    "uniprot": "A0A044SIH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIH8"
  },
  {
    "uniprot": "A0A044TLK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLK2"
  },
  {
    "uniprot": "A0A044TWD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWD3"
  },
  {
    "uniprot": "A0A044U6G1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6G1"
  },
  {
    "uniprot": "A0A2K6VEG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VEG2"
  },
  {
    "uniprot": "A0A044TMU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMU0"
  },
  {
    "uniprot": "A0A044S7Q9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7Q9"
  },
  {
    "uniprot": "A0A044RHY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHY7"
  },
  {
    "uniprot": "A0A044UNP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNP1"
  },
  {
    "uniprot": "A0A2K6VYG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYG6"
  },
  {
    "uniprot": "A0A044QW55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW55"
  },
  {
    "uniprot": "A0A044RJR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJR7"
  },
  {
    "uniprot": "A0A2K6W789",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W789"
  },
  {
    "uniprot": "A0A2K6W0S0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0S0"
  },
  {
    "uniprot": "A0A044T6E6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6E6"
  },
  {
    "uniprot": "A0A044VEL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEL3"
  },
  {
    "uniprot": "A0A044R2R5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2R5"
  },
  {
    "uniprot": "A0A044RZR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZR7"
  },
  {
    "uniprot": "A0A2K6WCT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WCT5"
  },
  {
    "uniprot": "P11012",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P11012"
  },
  {
    "uniprot": "P36991",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P36991"
  },
  {
    "uniprot": "A0A044V931",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V931"
  },
  {
    "uniprot": "A0A044U5A3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5A3"
  },
  {
    "uniprot": "A0A044UF09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF09"
  },
  {
    "uniprot": "A0A2K6W3D3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3D3"
  },
  {
    "uniprot": "A0A044V9U0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9U0"
  },
  {
    "uniprot": "A0A044RFP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFP6"
  },
  {
    "uniprot": "A0A044V3Z5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3Z5"
  },
  {
    "uniprot": "A0A044RU88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU88"
  },
  {
    "uniprot": "A0A044RKL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKL7"
  },
  {
    "uniprot": "A0A044RGS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGS6"
  },
  {
    "uniprot": "A0A044QX16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QX16"
  },
  {
    "uniprot": "A0A2K6W8X2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8X2"
  },
  {
    "uniprot": "A0A044S368",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S368"
  },
  {
    "uniprot": "A0A2K6W0R0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0R0"
  },
  {
    "uniprot": "A0A044T0N2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0N2"
  },
  {
    "uniprot": "A0A044S9T7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9T7"
  },
  {
    "uniprot": "A0A044RQP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQP7"
  },
  {
    "uniprot": "A0A044UCX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCX2"
  },
  {
    "uniprot": "A0A044RYJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYJ3"
  },
  {
    "uniprot": "A0A044QWA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWA9"
  },
  {
    "uniprot": "A0A044V5B3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5B3"
  },
  {
    "uniprot": "A0A044RB14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RB14"
  },
  {
    "uniprot": "A0A044RXI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXI4"
  },
  {
    "uniprot": "A0A044TZR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZR4"
  },
  {
    "uniprot": "A0A044SSU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSU0"
  },
  {
    "uniprot": "A0A044T9Z3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9Z3"
  },
  {
    "uniprot": "A0A044QPZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPZ9"
  },
  {
    "uniprot": "A0A044T1H4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1H4"
  },
  {
    "uniprot": "A0A044SNH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNH4"
  },
  {
    "uniprot": "A0A044RX28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RX28"
  },
  {
    "uniprot": "A0A044T6A7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6A7"
  },
  {
    "uniprot": "A0A2K6VX20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VX20"
  },
  {
    "uniprot": "A0A044RJD0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJD0"
  },
  {
    "uniprot": "A0A044REF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REF6"
  },
  {
    "uniprot": "A0A2K6VY38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VY38"
  },
  {
    "uniprot": "A0A044UQ47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQ47"
  },
  {
    "uniprot": "A0A044U217",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U217"
  },
  {
    "uniprot": "A0A2K6VF75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VF75"
  },
  {
    "uniprot": "A0A044TE86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TE86"
  },
  {
    "uniprot": "A0A2K6VYI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYI8"
  },
  {
    "uniprot": "A0A2K6VQY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQY0"
  },
  {
    "uniprot": "A0A044TZV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZV5"
  },
  {
    "uniprot": "A0A044QX71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QX71"
  },
  {
    "uniprot": "A0A158N8R5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8R5"
  },
  {
    "uniprot": "A0A044URR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URR6"
  },
  {
    "uniprot": "A0A044RUG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUG8"
  },
  {
    "uniprot": "A0A044UJL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJL5"
  },
  {
    "uniprot": "A0A044TMU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMU6"
  },
  {
    "uniprot": "A0A044VCU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCU3"
  },
  {
    "uniprot": "A0A044UIQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIQ6"
  },
  {
    "uniprot": "A0A044RWD0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWD0"
  },
  {
    "uniprot": "A0A044UHZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHZ3"
  },
  {
    "uniprot": "A0A044SSS1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSS1"
  },
  {
    "uniprot": "A0A044R6T5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6T5"
  },
  {
    "uniprot": "A0A044TTC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTC6"
  },
  {
    "uniprot": "A0A044TMG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMG1"
  },
  {
    "uniprot": "A0A044UE82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE82"
  },
  {
    "uniprot": "A0A044UVN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVN5"
  },
  {
    "uniprot": "A0A044S0D7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0D7"
  },
  {
    "uniprot": "A0A044SHX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHX1"
  },
  {
    "uniprot": "A0A044R1F0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1F0"
  },
  {
    "uniprot": "A0A044T6H4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6H4"
  },
  {
    "uniprot": "A0A044R863",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R863"
  },
  {
    "uniprot": "A0A044S9G2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9G2"
  },
  {
    "uniprot": "A0A044SY93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY93"
  },
  {
    "uniprot": "A0A044TJD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJD7"
  },
  {
    "uniprot": "A0A044R8S5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8S5"
  },
  {
    "uniprot": "A0A044UNN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNN7"
  },
  {
    "uniprot": "A0A2K6W6S2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6S2"
  },
  {
    "uniprot": "A0A044SZE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZE1"
  },
  {
    "uniprot": "A0A044RYI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYI8"
  },
  {
    "uniprot": "A0A044UTU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTU3"
  },
  {
    "uniprot": "A0A044S7Q1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7Q1"
  },
  {
    "uniprot": "A0A044T5U0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5U0"
  },
  {
    "uniprot": "A0A044UJR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJR1"
  },
  {
    "uniprot": "A0A044RIP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIP9"
  },
  {
    "uniprot": "A0A044QQ46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQ46"
  },
  {
    "uniprot": "A0A2K6WHK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WHK2"
  },
  {
    "uniprot": "A0A044R2P5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2P5"
  },
  {
    "uniprot": "A0A044TSE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSE4"
  },
  {
    "uniprot": "A0A044R5J4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5J4"
  },
  {
    "uniprot": "A0A044SG76",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG76"
  },
  {
    "uniprot": "A0A044UGZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGZ1"
  },
  {
    "uniprot": "A0A158N7X3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7X3"
  },
  {
    "uniprot": "A0A044UUQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUQ3"
  },
  {
    "uniprot": "A0A044SPF7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPF7"
  },
  {
    "uniprot": "A0A044T4W1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4W1"
  },
  {
    "uniprot": "A0A044S5U4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5U4"
  },
  {
    "uniprot": "A0A044T2G5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2G5"
  },
  {
    "uniprot": "A0A044R3R3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3R3"
  },
  {
    "uniprot": "A0A044TR49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR49"
  },
  {
    "uniprot": "A0A044VAR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAR6"
  },
  {
    "uniprot": "A0A2K6WB62",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WB62"
  },
  {
    "uniprot": "A0A044U4I5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4I5"
  },
  {
    "uniprot": "A0A044UZD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZD1"
  },
  {
    "uniprot": "A0A044TSZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSZ6"
  },
  {
    "uniprot": "A0A044U6R9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6R9"
  },
  {
    "uniprot": "A0A044S7X4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7X4"
  },
  {
    "uniprot": "A0A044VCX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCX2"
  },
  {
    "uniprot": "A0A044UV48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UV48"
  },
  {
    "uniprot": "A0A044T0F9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0F9"
  },
  {
    "uniprot": "A0A044SD89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD89"
  },
  {
    "uniprot": "A0A2K6WGM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGM5"
  },
  {
    "uniprot": "A0A044SD69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD69"
  },
  {
    "uniprot": "A0A044TPJ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPJ2"
  },
  {
    "uniprot": "A0A2K6VNI5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VNI5"
  },
  {
    "uniprot": "A0A044SCT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCT9"
  },
  {
    "uniprot": "A0A044SAB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAB0"
  },
  {
    "uniprot": "A0A2K6VXB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXB7"
  },
  {
    "uniprot": "A0A044RV93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RV93"
  },
  {
    "uniprot": "A0A2K6VI23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VI23"
  },
  {
    "uniprot": "A0A2K6WI25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WI25"
  },
  {
    "uniprot": "A0A158N8P8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8P8"
  },
  {
    "uniprot": "A0A044RW67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW67"
  },
  {
    "uniprot": "A0A2K6WB92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WB92"
  },
  {
    "uniprot": "A0A044T2B5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2B5"
  },
  {
    "uniprot": "A0A044SVX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVX8"
  },
  {
    "uniprot": "A0A044R8W7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8W7"
  },
  {
    "uniprot": "A0A044VEJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEJ1"
  },
  {
    "uniprot": "A0A2K6W850",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W850"
  },
  {
    "uniprot": "A0A044U3M5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3M5"
  },
  {
    "uniprot": "A0A044TH32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH32"
  },
  {
    "uniprot": "A0A044URP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URP5"
  },
  {
    "uniprot": "A0A044V5I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5I9"
  },
  {
    "uniprot": "A0A044T7X1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7X1"
  },
  {
    "uniprot": "A0A2K6WCN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WCN0"
  },
  {
    "uniprot": "A0A044U420",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U420"
  },
  {
    "uniprot": "A0A044VJ56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJ56"
  },
  {
    "uniprot": "A0A044QVT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVT2"
  },
  {
    "uniprot": "A0A044UDP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDP4"
  },
  {
    "uniprot": "A0A044U3T7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3T7"
  },
  {
    "uniprot": "A0A044RJC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJC4"
  },
  {
    "uniprot": "A0A044S6H7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6H7"
  },
  {
    "uniprot": "A0A044V6P9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6P9"
  },
  {
    "uniprot": "A0A044UHI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHI2"
  },
  {
    "uniprot": "A0A044R316",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R316"
  },
  {
    "uniprot": "A0A044TTX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTX9"
  },
  {
    "uniprot": "A0A044SJU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJU4"
  },
  {
    "uniprot": "A0A044U1N7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1N7"
  },
  {
    "uniprot": "A0A044U002",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U002"
  },
  {
    "uniprot": "A0A044V961",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V961"
  },
  {
    "uniprot": "A0A044TVB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVB1"
  },
  {
    "uniprot": "A0A044TLF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLF3"
  },
  {
    "uniprot": "A0A044SHP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHP7"
  },
  {
    "uniprot": "A0A044U5Q5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5Q5"
  },
  {
    "uniprot": "A0A044QND5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QND5"
  },
  {
    "uniprot": "A0A044UBN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBN7"
  },
  {
    "uniprot": "A0A044V4A5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4A5"
  },
  {
    "uniprot": "A0A044QMG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMG4"
  },
  {
    "uniprot": "A0A044UD64",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UD64"
  },
  {
    "uniprot": "A0A044SLR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLR1"
  },
  {
    "uniprot": "A0A044UH20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UH20"
  },
  {
    "uniprot": "A0A044UZ04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ04"
  },
  {
    "uniprot": "A0A044TXV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXV3"
  },
  {
    "uniprot": "A0A2K6WJ67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJ67"
  },
  {
    "uniprot": "A0A158N802",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N802"
  },
  {
    "uniprot": "A0A044VHB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHB0"
  },
  {
    "uniprot": "A0A044UDK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDK8"
  },
  {
    "uniprot": "A0A044RLR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLR3"
  },
  {
    "uniprot": "A0A044V9X4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9X4"
  },
  {
    "uniprot": "A0A044V122",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V122"
  },
  {
    "uniprot": "A0A044T6A2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6A2"
  },
  {
    "uniprot": "A0A044US02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044US02"
  },
  {
    "uniprot": "A0A044TAS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAS4"
  },
  {
    "uniprot": "A0A044SXJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXJ7"
  },
  {
    "uniprot": "A0A044S3N9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3N9"
  },
  {
    "uniprot": "A0A044V971",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V971"
  },
  {
    "uniprot": "A0A044QZ16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ16"
  },
  {
    "uniprot": "A0A044U010",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U010"
  },
  {
    "uniprot": "A0A044UR61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UR61"
  },
  {
    "uniprot": "A0A044QQ97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQ97"
  },
  {
    "uniprot": "A0A044QRQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRQ0"
  },
  {
    "uniprot": "A0A044UJE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJE9"
  },
  {
    "uniprot": "A0A044TD69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TD69"
  },
  {
    "uniprot": "A0A044R521",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R521"
  },
  {
    "uniprot": "A0A044RQT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQT9"
  },
  {
    "uniprot": "A0A044TES7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TES7"
  },
  {
    "uniprot": "A0A2K6W4Z9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W4Z9"
  },
  {
    "uniprot": "A0A044SPM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPM4"
  },
  {
    "uniprot": "A0A158N8N3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8N3"
  },
  {
    "uniprot": "A0A2K6VSF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSF4"
  },
  {
    "uniprot": "A0A044QM72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QM72"
  },
  {
    "uniprot": "A0A044UTA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTA0"
  },
  {
    "uniprot": "A0A044U1P5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1P5"
  },
  {
    "uniprot": "A0A044RH04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH04"
  },
  {
    "uniprot": "A0A044V6X9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6X9"
  },
  {
    "uniprot": "A0A2K6VJ78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJ78"
  },
  {
    "uniprot": "A0A044T4C2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4C2"
  },
  {
    "uniprot": "A0A044V730",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V730"
  },
  {
    "uniprot": "A0A044SJD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJD6"
  },
  {
    "uniprot": "A0A044U1L7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1L7"
  },
  {
    "uniprot": "A0A044SBU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBU1"
  },
  {
    "uniprot": "A0A044UL03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL03"
  },
  {
    "uniprot": "A0A044V974",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V974"
  },
  {
    "uniprot": "A0A044S2V6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2V6"
  },
  {
    "uniprot": "A0A044U2Q3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2Q3"
  },
  {
    "uniprot": "A0A044TJT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJT8"
  },
  {
    "uniprot": "A0A044T0K7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0K7"
  },
  {
    "uniprot": "A0A044TH70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH70"
  },
  {
    "uniprot": "A0A044TEY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEY5"
  },
  {
    "uniprot": "A0A2K6VUC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUC9"
  },
  {
    "uniprot": "A0A044URH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URH1"
  },
  {
    "uniprot": "A0A044U6W9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6W9"
  },
  {
    "uniprot": "A0A044TQU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQU0"
  },
  {
    "uniprot": "A0A044T0E6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0E6"
  },
  {
    "uniprot": "A0A044RFF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFF4"
  },
  {
    "uniprot": "A0A2K6W7N6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7N6"
  },
  {
    "uniprot": "A0A2K6WL31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WL31"
  },
  {
    "uniprot": "A0A044QKG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKG6"
  },
  {
    "uniprot": "A0A044UZ05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ05"
  },
  {
    "uniprot": "A0A044QWX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWX3"
  },
  {
    "uniprot": "A0A044T0T3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0T3"
  },
  {
    "uniprot": "A0A044SWR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWR0"
  },
  {
    "uniprot": "A0A044RJ97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJ97"
  },
  {
    "uniprot": "A0A044TY10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TY10"
  },
  {
    "uniprot": "A0A044TB12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TB12"
  },
  {
    "uniprot": "A0A044V9G3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9G3"
  },
  {
    "uniprot": "A0A044U548",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U548"
  },
  {
    "uniprot": "A0A044V425",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V425"
  },
  {
    "uniprot": "A0A044VC58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC58"
  },
  {
    "uniprot": "A0A044RNK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNK5"
  },
  {
    "uniprot": "A0A2K6W371",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W371"
  },
  {
    "uniprot": "A0A044V9J2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9J2"
  },
  {
    "uniprot": "A0A044UFA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFA9"
  },
  {
    "uniprot": "A0A044V320",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V320"
  },
  {
    "uniprot": "A0A044U704",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U704"
  },
  {
    "uniprot": "A0A044QU18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QU18"
  },
  {
    "uniprot": "A0A044UGL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGL5"
  },
  {
    "uniprot": "A0A044SFD9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFD9"
  },
  {
    "uniprot": "A0A044TZW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZW9"
  },
  {
    "uniprot": "A0A044TK35",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK35"
  },
  {
    "uniprot": "A0A044SLY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLY9"
  },
  {
    "uniprot": "A0A2K6VKI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKI0"
  },
  {
    "uniprot": "A0A044TYF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYF6"
  },
  {
    "uniprot": "A0A044RUE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUE7"
  },
  {
    "uniprot": "A0A044T875",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T875"
  },
  {
    "uniprot": "A0A044QZ73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ73"
  },
  {
    "uniprot": "A0A2K6VRB2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRB2"
  },
  {
    "uniprot": "A0A044T938",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T938"
  },
  {
    "uniprot": "A0A044SML8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SML8"
  },
  {
    "uniprot": "A0A044SEL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEL9"
  },
  {
    "uniprot": "A0A2K6VJH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJH1"
  },
  {
    "uniprot": "A0A044TLT3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLT3"
  },
  {
    "uniprot": "A0A044UQZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQZ1"
  },
  {
    "uniprot": "A0A044SHW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHW7"
  },
  {
    "uniprot": "A0A044S2M8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2M8"
  },
  {
    "uniprot": "A0A044S0K5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0K5"
  },
  {
    "uniprot": "A0A044R322",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R322"
  },
  {
    "uniprot": "A0A044SZE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZE0"
  },
  {
    "uniprot": "A0A044SWE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWE2"
  },
  {
    "uniprot": "A0A044S496",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S496"
  },
  {
    "uniprot": "A0A044R7G5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7G5"
  },
  {
    "uniprot": "A0A044RGE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGE9"
  },
  {
    "uniprot": "A0A044UKF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKF2"
  },
  {
    "uniprot": "A0A2K6VKP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKP7"
  },
  {
    "uniprot": "A0A044S0J8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0J8"
  },
  {
    "uniprot": "A0A044T1M8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1M8"
  },
  {
    "uniprot": "A0A044T4F4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4F4"
  },
  {
    "uniprot": "A0A044VJ87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJ87"
  },
  {
    "uniprot": "A0A044TSS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSS3"
  },
  {
    "uniprot": "A0A044RTT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTT5"
  },
  {
    "uniprot": "A0A044S8D7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8D7"
  },
  {
    "uniprot": "A0A2K6VDS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDS3"
  },
  {
    "uniprot": "A0A044V195",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V195"
  },
  {
    "uniprot": "A0A044R444",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R444"
  },
  {
    "uniprot": "A0A044R1S7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1S7"
  },
  {
    "uniprot": "A0A2K6W1N9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1N9"
  },
  {
    "uniprot": "A0A044RZK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZK0"
  },
  {
    "uniprot": "A0A2K6WBK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBK9"
  },
  {
    "uniprot": "A0A044U605",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U605"
  },
  {
    "uniprot": "A0A044S7U7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7U7"
  },
  {
    "uniprot": "A0A044SQX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQX3"
  },
  {
    "uniprot": "A0A044QQK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQK3"
  },
  {
    "uniprot": "A0A044T9J5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9J5"
  },
  {
    "uniprot": "A0A2K6VFZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFZ5"
  },
  {
    "uniprot": "A0A044QNL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNL9"
  },
  {
    "uniprot": "A0A044TKP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKP8"
  },
  {
    "uniprot": "A0A044QL43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL43"
  },
  {
    "uniprot": "A0A044V9V1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9V1"
  },
  {
    "uniprot": "A0A044RC98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC98"
  },
  {
    "uniprot": "A0A044T4G6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4G6"
  },
  {
    "uniprot": "A0A044V710",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V710"
  },
  {
    "uniprot": "A0A044URM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URM6"
  },
  {
    "uniprot": "A0A044R8Q7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8Q7"
  },
  {
    "uniprot": "A0A044TQH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQH8"
  },
  {
    "uniprot": "A0A044V4E7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4E7"
  },
  {
    "uniprot": "A0A044TBA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBA5"
  },
  {
    "uniprot": "A0A2K6VLQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLQ9"
  },
  {
    "uniprot": "A0A044VHM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHM1"
  },
  {
    "uniprot": "A0A044V9J7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9J7"
  },
  {
    "uniprot": "A0A044S7H5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7H5"
  },
  {
    "uniprot": "A0A044V309",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V309"
  },
  {
    "uniprot": "A0A044SPH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPH8"
  },
  {
    "uniprot": "A0A044UBW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBW8"
  },
  {
    "uniprot": "A0A044QW14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW14"
  },
  {
    "uniprot": "A0A044T7D9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7D9"
  },
  {
    "uniprot": "A0A044TAN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAN2"
  },
  {
    "uniprot": "A0A044TIZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIZ7"
  },
  {
    "uniprot": "A0A044RF49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RF49"
  },
  {
    "uniprot": "A0A044UVV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVV1"
  },
  {
    "uniprot": "A0A044U6D3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6D3"
  },
  {
    "uniprot": "A0A044RSA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSA0"
  },
  {
    "uniprot": "A0A044SIT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIT2"
  },
  {
    "uniprot": "A0A044QUG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUG0"
  },
  {
    "uniprot": "A0A2K6WFT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFT5"
  },
  {
    "uniprot": "A0A044UVI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVI2"
  },
  {
    "uniprot": "A0A044V9B0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9B0"
  },
  {
    "uniprot": "A0A044S2L1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2L1"
  },
  {
    "uniprot": "A0A044SVA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVA6"
  },
  {
    "uniprot": "A0A044QTT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTT9"
  },
  {
    "uniprot": "A0A044TXA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXA7"
  },
  {
    "uniprot": "A0A044SE09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SE09"
  },
  {
    "uniprot": "A0A044VFN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFN5"
  },
  {
    "uniprot": "A0A044RTF7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTF7"
  },
  {
    "uniprot": "A0A2K6WE31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WE31"
  },
  {
    "uniprot": "A0A044TZH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZH4"
  },
  {
    "uniprot": "A0A044V0E5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0E5"
  },
  {
    "uniprot": "A0A044TWY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWY2"
  },
  {
    "uniprot": "A0A044TAQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAQ6"
  },
  {
    "uniprot": "A0A044T755",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T755"
  },
  {
    "uniprot": "A0A044TBT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBT7"
  },
  {
    "uniprot": "A0A044VFR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFR8"
  },
  {
    "uniprot": "A0A044UEL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEL7"
  },
  {
    "uniprot": "A0A044VCZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCZ6"
  },
  {
    "uniprot": "A0A044V4K7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4K7"
  },
  {
    "uniprot": "A0A044R0I1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0I1"
  },
  {
    "uniprot": "A0A044R1Q1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1Q1"
  },
  {
    "uniprot": "A0A044S269",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S269"
  },
  {
    "uniprot": "A0A044SNE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNE2"
  },
  {
    "uniprot": "A0A044R6W2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6W2"
  },
  {
    "uniprot": "A0A044QLQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLQ7"
  },
  {
    "uniprot": "A0A044TDD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDD1"
  },
  {
    "uniprot": "A0A044RUW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUW4"
  },
  {
    "uniprot": "A0A044SFV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFV6"
  },
  {
    "uniprot": "A0A044SFX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFX9"
  },
  {
    "uniprot": "A0A044QWC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWC8"
  },
  {
    "uniprot": "A0A044V4A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4A9"
  },
  {
    "uniprot": "A0A044SN49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN49"
  },
  {
    "uniprot": "A0A044TTX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTX4"
  },
  {
    "uniprot": "A0A044S432",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S432"
  },
  {
    "uniprot": "A0A2K6VS99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VS99"
  },
  {
    "uniprot": "A0A044TDL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDL2"
  },
  {
    "uniprot": "A0A044UF05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF05"
  },
  {
    "uniprot": "A0A2K6VRG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRG7"
  },
  {
    "uniprot": "A0A044U6R3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6R3"
  },
  {
    "uniprot": "A0A044UEG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEG6"
  },
  {
    "uniprot": "A0A044SP11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SP11"
  },
  {
    "uniprot": "A0A044UFV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFV7"
  },
  {
    "uniprot": "A0A044TYR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYR8"
  },
  {
    "uniprot": "A0A044RLF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLF6"
  },
  {
    "uniprot": "A0A044UE15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE15"
  },
  {
    "uniprot": "A0A044UPG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPG5"
  },
  {
    "uniprot": "A0A044RAK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAK9"
  },
  {
    "uniprot": "A0A044R2A5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2A5"
  },
  {
    "uniprot": "A0A044T6C5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6C5"
  },
  {
    "uniprot": "A0A044U9H5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9H5"
  },
  {
    "uniprot": "A0A044S866",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S866"
  },
  {
    "uniprot": "A0A044RZU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZU1"
  },
  {
    "uniprot": "A0A044RR55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RR55"
  },
  {
    "uniprot": "A0A044ULH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULH7"
  },
  {
    "uniprot": "A0A044S4C5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4C5"
  },
  {
    "uniprot": "A0A044V263",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V263"
  },
  {
    "uniprot": "A0A044T9B6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9B6"
  },
  {
    "uniprot": "A0A044TG33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TG33"
  },
  {
    "uniprot": "A0A044RTG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTG4"
  },
  {
    "uniprot": "A0A044V667",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V667"
  },
  {
    "uniprot": "A0A044S2F3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2F3"
  },
  {
    "uniprot": "A0A044TAS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAS0"
  },
  {
    "uniprot": "A0A2K6WC85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WC85"
  },
  {
    "uniprot": "A0A2K6W3Y3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3Y3"
  },
  {
    "uniprot": "A0A044S8L7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8L7"
  },
  {
    "uniprot": "A0A044S774",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S774"
  },
  {
    "uniprot": "A0A2K6VJI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJI2"
  },
  {
    "uniprot": "A0A2K6WKA2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKA2"
  },
  {
    "uniprot": "A0A044SSY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSY5"
  },
  {
    "uniprot": "A0A044QZF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZF2"
  },
  {
    "uniprot": "A0A044UPD0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPD0"
  },
  {
    "uniprot": "A0A2K6VVR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVR3"
  },
  {
    "uniprot": "A0A044RDZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDZ3"
  },
  {
    "uniprot": "A0A044U084",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U084"
  },
  {
    "uniprot": "A0A2K6WPE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WPE5"
  },
  {
    "uniprot": "A0A044TU94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TU94"
  },
  {
    "uniprot": "A0A044RY15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY15"
  },
  {
    "uniprot": "A0A044RX97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RX97"
  },
  {
    "uniprot": "A0A044QXU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXU4"
  },
  {
    "uniprot": "A0A044S1F5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1F5"
  },
  {
    "uniprot": "A0A158N7T9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7T9"
  },
  {
    "uniprot": "A0A044V471",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V471"
  },
  {
    "uniprot": "A0A044UE30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE30"
  },
  {
    "uniprot": "A0A044UJN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJN5"
  },
  {
    "uniprot": "A0A044VHT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHT6"
  },
  {
    "uniprot": "A0A044R912",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R912"
  },
  {
    "uniprot": "A0A044V9N7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9N7"
  },
  {
    "uniprot": "A0A044T143",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T143"
  },
  {
    "uniprot": "A0A044S5K2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5K2"
  },
  {
    "uniprot": "A0A044RP95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RP95"
  },
  {
    "uniprot": "A0A044UZA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZA4"
  },
  {
    "uniprot": "A0A044TZI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZI4"
  },
  {
    "uniprot": "A0A044U4M3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4M3"
  },
  {
    "uniprot": "A0A044QM24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QM24"
  },
  {
    "uniprot": "A0A044QYE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYE3"
  },
  {
    "uniprot": "A0A044QTD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTD7"
  },
  {
    "uniprot": "A0A044UVA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVA4"
  },
  {
    "uniprot": "A0A158N8H2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8H2"
  },
  {
    "uniprot": "A0A044S6A4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6A4"
  },
  {
    "uniprot": "A0A044VF02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VF02"
  },
  {
    "uniprot": "A0A044T643",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T643"
  },
  {
    "uniprot": "A0A044UMQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMQ4"
  },
  {
    "uniprot": "A0A044RVH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVH6"
  },
  {
    "uniprot": "A0A044RIL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIL5"
  },
  {
    "uniprot": "A0A044RDM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDM6"
  },
  {
    "uniprot": "A0A044THK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THK0"
  },
  {
    "uniprot": "A0A044V735",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V735"
  },
  {
    "uniprot": "A0A044V199",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V199"
  },
  {
    "uniprot": "A0A044SCN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCN8"
  },
  {
    "uniprot": "A0A044UUR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUR7"
  },
  {
    "uniprot": "A0A044SHE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHE0"
  },
  {
    "uniprot": "A0A044R7W8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7W8"
  },
  {
    "uniprot": "A0A044UZV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZV8"
  },
  {
    "uniprot": "A0A044V9J3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9J3"
  },
  {
    "uniprot": "A0A044UNN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNN9"
  },
  {
    "uniprot": "A0A044TYN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYN7"
  },
  {
    "uniprot": "A0A044R472",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R472"
  },
  {
    "uniprot": "A0A044URT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URT1"
  },
  {
    "uniprot": "A0A044S9W7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9W7"
  },
  {
    "uniprot": "A0A2K6VQ82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQ82"
  },
  {
    "uniprot": "A0A044QZ07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ07"
  },
  {
    "uniprot": "A0A044V598",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V598"
  },
  {
    "uniprot": "A0A044SNJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNJ7"
  },
  {
    "uniprot": "A0A044V4B7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4B7"
  },
  {
    "uniprot": "A0A044S4N3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4N3"
  },
  {
    "uniprot": "A0A044RHE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHE0"
  },
  {
    "uniprot": "A0A044UUY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUY9"
  },
  {
    "uniprot": "A0A044UQS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQS0"
  },
  {
    "uniprot": "A0A044UBS8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBS8"
  },
  {
    "uniprot": "A0A044V0L0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0L0"
  },
  {
    "uniprot": "A0A044S8I7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8I7"
  },
  {
    "uniprot": "A0A044T1C4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1C4"
  },
  {
    "uniprot": "A0A044VBV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBV4"
  },
  {
    "uniprot": "P31730",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P31730"
  },
  {
    "uniprot": "A0A158N8R0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8R0"
  },
  {
    "uniprot": "A0A044V143",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V143"
  },
  {
    "uniprot": "A0A044T957",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T957"
  },
  {
    "uniprot": "A0A044RJ29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJ29"
  },
  {
    "uniprot": "A0A044SX16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SX16"
  },
  {
    "uniprot": "A0A044S8F4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8F4"
  },
  {
    "uniprot": "A0A2K6WJK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJK5"
  },
  {
    "uniprot": "A0A044TTQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTQ5"
  },
  {
    "uniprot": "A0A044U0Z3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0Z3"
  },
  {
    "uniprot": "A0A044RQM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQM0"
  },
  {
    "uniprot": "A0A044UXX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXX7"
  },
  {
    "uniprot": "A0A2K6WDP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDP0"
  },
  {
    "uniprot": "A0A044SHK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHK9"
  },
  {
    "uniprot": "A0A044VH96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH96"
  },
  {
    "uniprot": "A0A044VD21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD21"
  },
  {
    "uniprot": "A0A044TQU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQU7"
  },
  {
    "uniprot": "A0A2K6WAA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAA5"
  },
  {
    "uniprot": "A0A044V949",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V949"
  },
  {
    "uniprot": "A0A044SSH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSH9"
  },
  {
    "uniprot": "A0A044T7V6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7V6"
  },
  {
    "uniprot": "A0A044V733",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V733"
  },
  {
    "uniprot": "A0A044TKC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKC8"
  },
  {
    "uniprot": "A0A044UMV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMV3"
  },
  {
    "uniprot": "A0A044QKX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKX5"
  },
  {
    "uniprot": "A0A044R2L1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2L1"
  },
  {
    "uniprot": "A0A044TU88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TU88"
  },
  {
    "uniprot": "A0A2K6VIS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIS3"
  },
  {
    "uniprot": "A0A044V953",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V953"
  },
  {
    "uniprot": "A0A044RDE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDE4"
  },
  {
    "uniprot": "A0A2K6W9E5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9E5"
  },
  {
    "uniprot": "A0A044R5Y9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5Y9"
  },
  {
    "uniprot": "A0A044SP86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SP86"
  },
  {
    "uniprot": "A0A158N7Y9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Y9"
  },
  {
    "uniprot": "A0A044QV16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QV16"
  },
  {
    "uniprot": "A0A2K6W614",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W614"
  },
  {
    "uniprot": "A0A044V464",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V464"
  },
  {
    "uniprot": "A0A044U4H9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4H9"
  },
  {
    "uniprot": "A0A044SFW1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFW1"
  },
  {
    "uniprot": "A0A044TQJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQJ9"
  },
  {
    "uniprot": "A0A044TN04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN04"
  },
  {
    "uniprot": "A0A044S4Y5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4Y5"
  },
  {
    "uniprot": "O16855",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/O16855"
  },
  {
    "uniprot": "A0A044VAI5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAI5"
  },
  {
    "uniprot": "A0A044RQ69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQ69"
  },
  {
    "uniprot": "A0A044TB54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TB54"
  },
  {
    "uniprot": "A0A2K6VK40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VK40"
  },
  {
    "uniprot": "A0A044SJC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJC9"
  },
  {
    "uniprot": "A0A2K6VXC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXC3"
  },
  {
    "uniprot": "A0A044TPB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPB4"
  },
  {
    "uniprot": "A0A044SZF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZF5"
  },
  {
    "uniprot": "A0A2K6WM89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WM89"
  },
  {
    "uniprot": "A0A044V6P1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6P1"
  },
  {
    "uniprot": "A0A044SII4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SII4"
  },
  {
    "uniprot": "A0A044VH26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH26"
  },
  {
    "uniprot": "A0A044SS63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS63"
  },
  {
    "uniprot": "A0A044URS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URS0"
  },
  {
    "uniprot": "A0A2K6VSN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSN9"
  },
  {
    "uniprot": "A0A044V9U2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9U2"
  },
  {
    "uniprot": "A0A044SCL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCL5"
  },
  {
    "uniprot": "A0A158N8K4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8K4"
  },
  {
    "uniprot": "A0A044U5A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5A9"
  },
  {
    "uniprot": "A0A044SRV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRV0"
  },
  {
    "uniprot": "A0A044V454",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V454"
  },
  {
    "uniprot": "A0A044U4U5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4U5"
  },
  {
    "uniprot": "A0A2K6VVT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVT8"
  },
  {
    "uniprot": "A0A044U911",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U911"
  },
  {
    "uniprot": "A0A044TQU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQU4"
  },
  {
    "uniprot": "A0A044SSK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSK0"
  },
  {
    "uniprot": "A0A044R0Y9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0Y9"
  },
  {
    "uniprot": "A0A044V966",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V966"
  },
  {
    "uniprot": "A0A044TNX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNX5"
  },
  {
    "uniprot": "A0A044UE12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE12"
  },
  {
    "uniprot": "A0A044RGY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGY6"
  },
  {
    "uniprot": "A0A2K6VD99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VD99"
  },
  {
    "uniprot": "A0A044SWJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWJ6"
  },
  {
    "uniprot": "A0A2K6W1M7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1M7"
  },
  {
    "uniprot": "A0A044T304",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T304"
  },
  {
    "uniprot": "A0A044UX51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX51"
  },
  {
    "uniprot": "A0A044UVF7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVF7"
  },
  {
    "uniprot": "A0A044SU19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU19"
  },
  {
    "uniprot": "A0A044V164",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V164"
  },
  {
    "uniprot": "A0A044R808",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R808"
  },
  {
    "uniprot": "A0A2K6WFS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFS2"
  },
  {
    "uniprot": "A0A044T9C1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9C1"
  },
  {
    "uniprot": "A0A044UK59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UK59"
  },
  {
    "uniprot": "A0A044RHI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHI9"
  },
  {
    "uniprot": "A0A044QJW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJW4"
  },
  {
    "uniprot": "A0A044S4W5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4W5"
  },
  {
    "uniprot": "A0A044S8M5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8M5"
  },
  {
    "uniprot": "A0A044SCL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCL2"
  },
  {
    "uniprot": "A0A044S3S2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3S2"
  },
  {
    "uniprot": "A0A044TEZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEZ7"
  },
  {
    "uniprot": "A0A044QLB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLB4"
  },
  {
    "uniprot": "A0A044V5T7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5T7"
  },
  {
    "uniprot": "A0A044T3F9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3F9"
  },
  {
    "uniprot": "A0A044TMY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMY9"
  },
  {
    "uniprot": "A0A044V3K0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3K0"
  },
  {
    "uniprot": "A0A044SJI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJI2"
  },
  {
    "uniprot": "A0A044T0V6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0V6"
  },
  {
    "uniprot": "A0A044SZC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZC5"
  },
  {
    "uniprot": "A0A044UW77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UW77"
  },
  {
    "uniprot": "A0A158N858",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N858"
  },
  {
    "uniprot": "A0A044UG38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UG38"
  },
  {
    "uniprot": "A0A044SUU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUU0"
  },
  {
    "uniprot": "A0A044RPR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPR2"
  },
  {
    "uniprot": "A0A044V9H2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9H2"
  },
  {
    "uniprot": "A0A044RCD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCD3"
  },
  {
    "uniprot": "A0A044R372",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R372"
  },
  {
    "uniprot": "A0A044UCY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCY7"
  },
  {
    "uniprot": "A0A044V8N1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8N1"
  },
  {
    "uniprot": "A0A044UR15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UR15"
  },
  {
    "uniprot": "A0A044UZ08",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ08"
  },
  {
    "uniprot": "A0A2K6VJR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJR4"
  },
  {
    "uniprot": "A0A044VEX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEX9"
  },
  {
    "uniprot": "A0A044RER3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RER3"
  },
  {
    "uniprot": "A0A044V9V3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9V3"
  },
  {
    "uniprot": "A0A044RSV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSV7"
  },
  {
    "uniprot": "A0A044UAW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAW3"
  },
  {
    "uniprot": "A0A044T6S7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6S7"
  },
  {
    "uniprot": "A0A044U766",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U766"
  },
  {
    "uniprot": "A0A044UUT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUT7"
  },
  {
    "uniprot": "A0A044VG01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VG01"
  },
  {
    "uniprot": "A0A044U1B1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1B1"
  },
  {
    "uniprot": "A0A044TR68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR68"
  },
  {
    "uniprot": "A0A044R0Q7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0Q7"
  },
  {
    "uniprot": "A0A044UAL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAL4"
  },
  {
    "uniprot": "A0A044UFP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFP9"
  },
  {
    "uniprot": "A0A044V3Z4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3Z4"
  },
  {
    "uniprot": "A0A044TWG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWG8"
  },
  {
    "uniprot": "A0A044VBR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBR3"
  },
  {
    "uniprot": "A0A044TCK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCK3"
  },
  {
    "uniprot": "A0A044QP80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP80"
  },
  {
    "uniprot": "A0A044RCX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCX9"
  },
  {
    "uniprot": "A0A044RVD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVD1"
  },
  {
    "uniprot": "A0A044VDT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDT4"
  },
  {
    "uniprot": "A0A044TVN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVN0"
  },
  {
    "uniprot": "A0A044UTH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTH8"
  },
  {
    "uniprot": "A0A044V9W1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9W1"
  },
  {
    "uniprot": "A0A044R3C1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3C1"
  },
  {
    "uniprot": "A0A044TAC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAC8"
  },
  {
    "uniprot": "A0A044SLN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLN7"
  },
  {
    "uniprot": "A0A044RNR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNR3"
  },
  {
    "uniprot": "A0A044TV14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV14"
  },
  {
    "uniprot": "A0A044RLB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLB0"
  },
  {
    "uniprot": "A0A044V529",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V529"
  },
  {
    "uniprot": "A0A044T7P5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7P5"
  },
  {
    "uniprot": "A0A044QM65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QM65"
  },
  {
    "uniprot": "A0A044T6F5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6F5"
  },
  {
    "uniprot": "A0A044ULW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULW9"
  },
  {
    "uniprot": "A0A044TTG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTG7"
  },
  {
    "uniprot": "A0A044UHX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHX7"
  },
  {
    "uniprot": "A0A044SK62",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK62"
  },
  {
    "uniprot": "A0A2K6WMR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMR8"
  },
  {
    "uniprot": "A0A044UGT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGT6"
  },
  {
    "uniprot": "A0A2K6VJF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJF4"
  },
  {
    "uniprot": "A0A044V126",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V126"
  },
  {
    "uniprot": "A0A044SW10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SW10"
  },
  {
    "uniprot": "A0A044QP15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP15"
  },
  {
    "uniprot": "A0A044VI80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VI80"
  },
  {
    "uniprot": "A0A044TBB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBB6"
  },
  {
    "uniprot": "A0A044UNH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNH1"
  },
  {
    "uniprot": "A0A044R083",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R083"
  },
  {
    "uniprot": "A0A044QZQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZQ3"
  },
  {
    "uniprot": "A0A044T360",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T360"
  },
  {
    "uniprot": "A0A044SVB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVB5"
  },
  {
    "uniprot": "A0A044SB94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB94"
  },
  {
    "uniprot": "A0A044V1T7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1T7"
  },
  {
    "uniprot": "A0A044T811",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T811"
  },
  {
    "uniprot": "A0A044TDI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDI9"
  },
  {
    "uniprot": "A0A044UAG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAG1"
  },
  {
    "uniprot": "A0A044SG60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG60"
  },
  {
    "uniprot": "A0A044TMT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMT5"
  },
  {
    "uniprot": "A0A2K6VGZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VGZ4"
  },
  {
    "uniprot": "A0A044VGM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGM4"
  },
  {
    "uniprot": "A0A044RTE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTE4"
  },
  {
    "uniprot": "A0A044QK01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK01"
  },
  {
    "uniprot": "A0A044RU35",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU35"
  },
  {
    "uniprot": "A0A044QL37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL37"
  },
  {
    "uniprot": "A0A044RKF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKF8"
  },
  {
    "uniprot": "A0A044TQX0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQX0"
  },
  {
    "uniprot": "A0A044T8D8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8D8"
  },
  {
    "uniprot": "A0A044VA12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA12"
  },
  {
    "uniprot": "A0A044TJ65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJ65"
  },
  {
    "uniprot": "A0A044V6Y3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Y3"
  },
  {
    "uniprot": "A0A044UCF9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCF9"
  },
  {
    "uniprot": "A0A2K6W270",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W270"
  },
  {
    "uniprot": "A0A044SPU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPU3"
  },
  {
    "uniprot": "A0A044U6V4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6V4"
  },
  {
    "uniprot": "A0A044S7B3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7B3"
  },
  {
    "uniprot": "A0A044R580",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R580"
  },
  {
    "uniprot": "A0A044TVC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVC5"
  },
  {
    "uniprot": "A0A044UKX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKX1"
  },
  {
    "uniprot": "A0A044SSQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSQ0"
  },
  {
    "uniprot": "A0A044S3E8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3E8"
  },
  {
    "uniprot": "A0A044RKS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKS3"
  },
  {
    "uniprot": "A0A044VIB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIB5"
  },
  {
    "uniprot": "A0A044U8D0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8D0"
  },
  {
    "uniprot": "A0A044QKC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKC5"
  },
  {
    "uniprot": "A0A044SDU2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDU2"
  },
  {
    "uniprot": "A0A044UHC7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHC7"
  },
  {
    "uniprot": "A0A044SVT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVT8"
  },
  {
    "uniprot": "A0A044RSH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSH0"
  },
  {
    "uniprot": "A0A044U1W7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1W7"
  },
  {
    "uniprot": "A0A044U814",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U814"
  },
  {
    "uniprot": "A0A044UM51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UM51"
  },
  {
    "uniprot": "A0A044R1G8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1G8"
  },
  {
    "uniprot": "A0A044R0K5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0K5"
  },
  {
    "uniprot": "A0A044STS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STS2"
  },
  {
    "uniprot": "A0A044RB82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RB82"
  },
  {
    "uniprot": "A0A044UTP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTP0"
  },
  {
    "uniprot": "A0A044RKJ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKJ8"
  },
  {
    "uniprot": "A0A044VI49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VI49"
  },
  {
    "uniprot": "A0A2K6VLU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLU7"
  },
  {
    "uniprot": "A0A044SAM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAM8"
  },
  {
    "uniprot": "A0A044SSM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSM8"
  },
  {
    "uniprot": "A0A044VFT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFT2"
  },
  {
    "uniprot": "A0A044TYT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYT1"
  },
  {
    "uniprot": "A0A2K6W7D8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7D8"
  },
  {
    "uniprot": "A0A044QNJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNJ3"
  },
  {
    "uniprot": "A0A044RTU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTU4"
  },
  {
    "uniprot": "A0A044SPT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPT1"
  },
  {
    "uniprot": "A0A158N8R1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8R1"
  },
  {
    "uniprot": "A0A044R9P1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9P1"
  },
  {
    "uniprot": "A0A044R4X6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4X6"
  },
  {
    "uniprot": "A0A044UCN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCN9"
  },
  {
    "uniprot": "A0A044U6Y1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6Y1"
  },
  {
    "uniprot": "A0A044SXV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXV9"
  },
  {
    "uniprot": "A0A044RJK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJK1"
  },
  {
    "uniprot": "A0A044UTM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTM2"
  },
  {
    "uniprot": "A0A044TH80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH80"
  },
  {
    "uniprot": "A0A044TA91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TA91"
  },
  {
    "uniprot": "A0A044S0S5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0S5"
  },
  {
    "uniprot": "A0A044TPS1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPS1"
  },
  {
    "uniprot": "A0A044UN21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UN21"
  },
  {
    "uniprot": "A0A044UR97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UR97"
  },
  {
    "uniprot": "A0A2K6WEM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEM5"
  },
  {
    "uniprot": "A0A044V458",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V458"
  },
  {
    "uniprot": "A0A044TG79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TG79"
  },
  {
    "uniprot": "A0A044R0A3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0A3"
  },
  {
    "uniprot": "A0A044V7L2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7L2"
  },
  {
    "uniprot": "A0A044T6P1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6P1"
  },
  {
    "uniprot": "A0A044T0E8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0E8"
  },
  {
    "uniprot": "A0A044RQ60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQ60"
  },
  {
    "uniprot": "A0A044TQ39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQ39"
  },
  {
    "uniprot": "A0A044TQC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQC0"
  },
  {
    "uniprot": "A0A044QKH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKH7"
  },
  {
    "uniprot": "A0A044QM92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QM92"
  },
  {
    "uniprot": "A0A044SW69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SW69"
  },
  {
    "uniprot": "A0A044SS53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS53"
  },
  {
    "uniprot": "A0A044UCQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCQ4"
  },
  {
    "uniprot": "A0A044V9T1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9T1"
  },
  {
    "uniprot": "A0A044QSX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSX9"
  },
  {
    "uniprot": "A0A044RJ10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJ10"
  },
  {
    "uniprot": "A0A044RKI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKI3"
  },
  {
    "uniprot": "A0A044RKT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKT6"
  },
  {
    "uniprot": "A0A044RUY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUY9"
  },
  {
    "uniprot": "A0A044TTG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTG8"
  },
  {
    "uniprot": "A0A044SNG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNG0"
  },
  {
    "uniprot": "A0A044TXS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXS9"
  },
  {
    "uniprot": "A0A044UB93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UB93"
  },
  {
    "uniprot": "A0A044VIT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIT6"
  },
  {
    "uniprot": "A0A044U8D8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8D8"
  },
  {
    "uniprot": "A0A044TSX0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSX0"
  },
  {
    "uniprot": "A0A2K6VZ56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZ56"
  },
  {
    "uniprot": "A0A044RG77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RG77"
  },
  {
    "uniprot": "A0A044TSP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSP0"
  },
  {
    "uniprot": "A0A044UWV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWV6"
  },
  {
    "uniprot": "A0A044TQ71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQ71"
  },
  {
    "uniprot": "A0A044S6I0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6I0"
  },
  {
    "uniprot": "A0A044UX98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX98"
  },
  {
    "uniprot": "A0A2K6VQJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQJ1"
  },
  {
    "uniprot": "A0A044UPN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPN7"
  },
  {
    "uniprot": "A0A2K6WC45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WC45"
  },
  {
    "uniprot": "A0A044S3U2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3U2"
  },
  {
    "uniprot": "A0A158N7W3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7W3"
  },
  {
    "uniprot": "A0A044V107",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V107"
  },
  {
    "uniprot": "A0A044V7A7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7A7"
  },
  {
    "uniprot": "A0A044RSJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSJ7"
  },
  {
    "uniprot": "A0A044RSH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSH3"
  },
  {
    "uniprot": "A0A2K6VIN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIN9"
  },
  {
    "uniprot": "A0A044V4H0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4H0"
  },
  {
    "uniprot": "A0A044T645",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T645"
  },
  {
    "uniprot": "A0A044T639",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T639"
  },
  {
    "uniprot": "A0A044STV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STV4"
  },
  {
    "uniprot": "A0A044V9S7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9S7"
  },
  {
    "uniprot": "A0A044UM45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UM45"
  },
  {
    "uniprot": "A0A044QTC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTC5"
  },
  {
    "uniprot": "A0A044URV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URV1"
  },
  {
    "uniprot": "A0A044TJT3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJT3"
  },
  {
    "uniprot": "A0A2K6VUA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUA7"
  },
  {
    "uniprot": "A0A044S511",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S511"
  },
  {
    "uniprot": "A0A044SH40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SH40"
  },
  {
    "uniprot": "A0A044SR04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SR04"
  },
  {
    "uniprot": "A0A044TAA2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAA2"
  },
  {
    "uniprot": "A0A044TFD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFD5"
  },
  {
    "uniprot": "P37801",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P37801"
  },
  {
    "uniprot": "A0A044UFI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFI4"
  },
  {
    "uniprot": "A0A044T367",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T367"
  },
  {
    "uniprot": "A0A2K6WF57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WF57"
  },
  {
    "uniprot": "A0A044U9I5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9I5"
  },
  {
    "uniprot": "A0A044T2E8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2E8"
  },
  {
    "uniprot": "A0A044V4F7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4F7"
  },
  {
    "uniprot": "A0A044UGK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGK8"
  },
  {
    "uniprot": "A0A044TZY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZY6"
  },
  {
    "uniprot": "A0A044S4J8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4J8"
  },
  {
    "uniprot": "A0A044TXH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXH1"
  },
  {
    "uniprot": "A0A044V6Z9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Z9"
  },
  {
    "uniprot": "A0A044QWK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWK9"
  },
  {
    "uniprot": "A0A044SFY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFY3"
  },
  {
    "uniprot": "A0A044RKI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKI7"
  },
  {
    "uniprot": "A0A044U317",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U317"
  },
  {
    "uniprot": "A0A044RFE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFE1"
  },
  {
    "uniprot": "A0A044U8F7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8F7"
  },
  {
    "uniprot": "A0A044S261",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S261"
  },
  {
    "uniprot": "A0A044TC32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC32"
  },
  {
    "uniprot": "A0A044UX49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX49"
  },
  {
    "uniprot": "A0A044SJ21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJ21"
  },
  {
    "uniprot": "A0A044UIG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIG6"
  },
  {
    "uniprot": "A0A044V112",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V112"
  },
  {
    "uniprot": "A0A044S0X2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0X2"
  },
  {
    "uniprot": "A0A2K6VU02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VU02"
  },
  {
    "uniprot": "A0A044UY38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UY38"
  },
  {
    "uniprot": "A0A044RQ72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQ72"
  },
  {
    "uniprot": "A0A044SVN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVN1"
  },
  {
    "uniprot": "A0A044RQQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQQ4"
  },
  {
    "uniprot": "A0A044SN38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN38"
  },
  {
    "uniprot": "A0A044QNA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNA6"
  },
  {
    "uniprot": "A0A044RHB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHB3"
  },
  {
    "uniprot": "A0A044R5G1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5G1"
  },
  {
    "uniprot": "A0A044RLK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLK2"
  },
  {
    "uniprot": "A0A044THD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THD7"
  },
  {
    "uniprot": "A0A044UT56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT56"
  },
  {
    "uniprot": "A0A044V767",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V767"
  },
  {
    "uniprot": "A0A044S935",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S935"
  },
  {
    "uniprot": "A0A044TCX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCX6"
  },
  {
    "uniprot": "A0A044QJK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJK5"
  },
  {
    "uniprot": "A0A044UM33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UM33"
  },
  {
    "uniprot": "A0A044SVE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVE5"
  },
  {
    "uniprot": "A0A2K6VNZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VNZ2"
  },
  {
    "uniprot": "A0A2K6WBX0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBX0"
  },
  {
    "uniprot": "A0A044UF28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF28"
  },
  {
    "uniprot": "A0A2K6WHA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WHA3"
  },
  {
    "uniprot": "A0A044UW89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UW89"
  },
  {
    "uniprot": "A0A044SZZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZZ9"
  },
  {
    "uniprot": "A0A044QNB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNB6"
  },
  {
    "uniprot": "A0A044T106",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T106"
  },
  {
    "uniprot": "A0A044R0E4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0E4"
  },
  {
    "uniprot": "A0A044V086",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V086"
  },
  {
    "uniprot": "A0A044RS96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RS96"
  },
  {
    "uniprot": "A0A044V591",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V591"
  },
  {
    "uniprot": "A0A044SCK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCK0"
  },
  {
    "uniprot": "A0A2K6WNH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WNH8"
  },
  {
    "uniprot": "A0A044TM65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TM65"
  },
  {
    "uniprot": "A0A044S6E9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6E9"
  },
  {
    "uniprot": "A0A044UVV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVV8"
  },
  {
    "uniprot": "A0A044UZM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZM0"
  },
  {
    "uniprot": "A0A044UWU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWU6"
  },
  {
    "uniprot": "A0A044R2D4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2D4"
  },
  {
    "uniprot": "A0A044QPP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPP3"
  },
  {
    "uniprot": "A0A044T3C0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3C0"
  },
  {
    "uniprot": "A0A044TQD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQD3"
  },
  {
    "uniprot": "A0A044TFL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFL5"
  },
  {
    "uniprot": "A0A044RMA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMA4"
  },
  {
    "uniprot": "A0A044T923",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T923"
  },
  {
    "uniprot": "A0A044U7Z1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7Z1"
  },
  {
    "uniprot": "A0A044U0E2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0E2"
  },
  {
    "uniprot": "A0A044QTX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTX5"
  },
  {
    "uniprot": "A0A044UUB2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUB2"
  },
  {
    "uniprot": "A0A044TV39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV39"
  },
  {
    "uniprot": "A0A044RF89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RF89"
  },
  {
    "uniprot": "A0A044UV64",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UV64"
  },
  {
    "uniprot": "A0A044UTX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTX9"
  },
  {
    "uniprot": "A0A044SX25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SX25"
  },
  {
    "uniprot": "A0A044RE18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RE18"
  },
  {
    "uniprot": "A0A044V9V4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9V4"
  },
  {
    "uniprot": "A0A044U061",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U061"
  },
  {
    "uniprot": "A0A044T9S9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9S9"
  },
  {
    "uniprot": "A0A044RJ57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJ57"
  },
  {
    "uniprot": "A0A2K6W8K2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8K2"
  },
  {
    "uniprot": "A0A044S2Z2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2Z2"
  },
  {
    "uniprot": "A0A2K6WH34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WH34"
  },
  {
    "uniprot": "A0A044R448",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R448"
  },
  {
    "uniprot": "A0A044SED8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SED8"
  },
  {
    "uniprot": "A0A044SLN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLN4"
  },
  {
    "uniprot": "A0A044R9B1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9B1"
  },
  {
    "uniprot": "A0A044VEA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEA9"
  },
  {
    "uniprot": "A0A044URZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URZ4"
  },
  {
    "uniprot": "A0A2K6VUR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUR3"
  },
  {
    "uniprot": "A0A044TEL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEL2"
  },
  {
    "uniprot": "A0A044SSH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSH4"
  },
  {
    "uniprot": "A0A044RW19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW19"
  },
  {
    "uniprot": "A0A044RQZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQZ1"
  },
  {
    "uniprot": "A0A044SVL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVL9"
  },
  {
    "uniprot": "A0A044RD22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RD22"
  },
  {
    "uniprot": "A0A044SUF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUF3"
  },
  {
    "uniprot": "A0A044RAU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAU8"
  },
  {
    "uniprot": "A0A044UBR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBR6"
  },
  {
    "uniprot": "A0A2K6VI00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VI00"
  },
  {
    "uniprot": "A0A044SX42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SX42"
  },
  {
    "uniprot": "A0A044V457",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V457"
  },
  {
    "uniprot": "A0A044UKB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKB3"
  },
  {
    "uniprot": "A0A2K6WFH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFH1"
  },
  {
    "uniprot": "A0A044T7J0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7J0"
  },
  {
    "uniprot": "A0A044VBV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBV8"
  },
  {
    "uniprot": "A0A044R5A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5A9"
  },
  {
    "uniprot": "A0A044V6C3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6C3"
  },
  {
    "uniprot": "A0A044V0W0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0W0"
  },
  {
    "uniprot": "A0A2K6WLI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLI0"
  },
  {
    "uniprot": "A0A044SJ40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJ40"
  },
  {
    "uniprot": "A0A044UYY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYY5"
  },
  {
    "uniprot": "A0A044UQA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQA9"
  },
  {
    "uniprot": "A0A044RWM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWM6"
  },
  {
    "uniprot": "A0A044U747",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U747"
  },
  {
    "uniprot": "A0A044U523",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U523"
  },
  {
    "uniprot": "A0A044T6W2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6W2"
  },
  {
    "uniprot": "A0A044TKI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKI8"
  },
  {
    "uniprot": "A0A044VDK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDK0"
  },
  {
    "uniprot": "A0A044RM09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RM09"
  },
  {
    "uniprot": "A0A2K6W3F7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3F7"
  },
  {
    "uniprot": "A0A044U5T1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5T1"
  },
  {
    "uniprot": "A0A044RSG3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSG3"
  },
  {
    "uniprot": "A0A044R2S7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2S7"
  },
  {
    "uniprot": "A0A044V6Q7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Q7"
  },
  {
    "uniprot": "A0A044SC10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SC10"
  },
  {
    "uniprot": "A0A2K6VDT3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDT3"
  },
  {
    "uniprot": "A0A044RY83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY83"
  },
  {
    "uniprot": "A0A044UQY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQY5"
  },
  {
    "uniprot": "A0A044USB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USB1"
  },
  {
    "uniprot": "A0A044SBK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBK4"
  },
  {
    "uniprot": "A0A2K6W4N5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W4N5"
  },
  {
    "uniprot": "A0A044VEN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEN5"
  },
  {
    "uniprot": "A0A2K6VNG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VNG6"
  },
  {
    "uniprot": "A0A044U079",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U079"
  },
  {
    "uniprot": "A0A044RAG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAG8"
  },
  {
    "uniprot": "A0A158N8U4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8U4"
  },
  {
    "uniprot": "A0A044QLC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLC5"
  },
  {
    "uniprot": "A0A044V7I8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7I8"
  },
  {
    "uniprot": "A0A044UJI5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJI5"
  },
  {
    "uniprot": "A0A044STL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STL9"
  },
  {
    "uniprot": "A0A044UI01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI01"
  },
  {
    "uniprot": "A0A044VFY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFY4"
  },
  {
    "uniprot": "A0A044TN56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN56"
  },
  {
    "uniprot": "A0A044RAY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAY7"
  },
  {
    "uniprot": "A0A2K6VI59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VI59"
  },
  {
    "uniprot": "A0A044UUV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUV7"
  },
  {
    "uniprot": "A0A2K6VZ09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZ09"
  },
  {
    "uniprot": "A0A044SPU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPU6"
  },
  {
    "uniprot": "A0A044R745",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R745"
  },
  {
    "uniprot": "A0A044SC54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SC54"
  },
  {
    "uniprot": "A0A2K6WIW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIW0"
  },
  {
    "uniprot": "A0A044V9F6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9F6"
  },
  {
    "uniprot": "A0A2K6WMR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMR6"
  },
  {
    "uniprot": "A0A2K6W8Y8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8Y8"
  },
  {
    "uniprot": "A0A158N850",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N850"
  },
  {
    "uniprot": "A0A044T2B1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2B1"
  },
  {
    "uniprot": "A0A044S6C8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6C8"
  },
  {
    "uniprot": "A0A044QLD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLD3"
  },
  {
    "uniprot": "A0A044QXW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXW2"
  },
  {
    "uniprot": "A0A044T1Z6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1Z6"
  },
  {
    "uniprot": "A0A044QPY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPY9"
  },
  {
    "uniprot": "A0A044S5M2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5M2"
  },
  {
    "uniprot": "A0A044RNG3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNG3"
  },
  {
    "uniprot": "A0A044TFJ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFJ2"
  },
  {
    "uniprot": "A0A044U099",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U099"
  },
  {
    "uniprot": "A0A044TIA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIA1"
  },
  {
    "uniprot": "A0A044R9X0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9X0"
  },
  {
    "uniprot": "A0A044T2J1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2J1"
  },
  {
    "uniprot": "A0A044VD16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD16"
  },
  {
    "uniprot": "A0A2K6WH32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WH32"
  },
  {
    "uniprot": "A0A044SKV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKV6"
  },
  {
    "uniprot": "A0A044T4A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4A9"
  },
  {
    "uniprot": "A0A044SGV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGV4"
  },
  {
    "uniprot": "A0A044U398",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U398"
  },
  {
    "uniprot": "A0A044TX54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TX54"
  },
  {
    "uniprot": "A0A044UDA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDA0"
  },
  {
    "uniprot": "A0A044TN03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN03"
  },
  {
    "uniprot": "A0A044VDF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDF2"
  },
  {
    "uniprot": "A0A044TVK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVK8"
  },
  {
    "uniprot": "A0A044T8N3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8N3"
  },
  {
    "uniprot": "A0A044UFN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFN4"
  },
  {
    "uniprot": "A0A044R389",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R389"
  },
  {
    "uniprot": "P30163",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P30163"
  },
  {
    "uniprot": "A0A044V739",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V739"
  },
  {
    "uniprot": "A0A044TLK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLK9"
  },
  {
    "uniprot": "A0A044QSN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSN1"
  },
  {
    "uniprot": "A0A044VCK7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCK7"
  },
  {
    "uniprot": "A0A044V262",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V262"
  },
  {
    "uniprot": "A0A044SK21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK21"
  },
  {
    "uniprot": "A0A2K6VRW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRW0"
  },
  {
    "uniprot": "A0A044RFN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFN3"
  },
  {
    "uniprot": "A0A044UDW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDW4"
  },
  {
    "uniprot": "A0A044VGC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGC2"
  },
  {
    "uniprot": "A0A044R9Y5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9Y5"
  },
  {
    "uniprot": "A0A044TGW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGW5"
  },
  {
    "uniprot": "A0A044RSN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSN3"
  },
  {
    "uniprot": "A0A044QXD2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXD2"
  },
  {
    "uniprot": "A0A044RQ39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQ39"
  },
  {
    "uniprot": "A0A044S4T1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4T1"
  },
  {
    "uniprot": "A0A044SPZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPZ9"
  },
  {
    "uniprot": "A0A044T990",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T990"
  },
  {
    "uniprot": "A0A044TWT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWT5"
  },
  {
    "uniprot": "A0A2K6W7J4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7J4"
  },
  {
    "uniprot": "A0A044TTN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTN5"
  },
  {
    "uniprot": "A0A044SN26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN26"
  },
  {
    "uniprot": "A0A044QJK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJK0"
  },
  {
    "uniprot": "A0A044UI93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI93"
  },
  {
    "uniprot": "A0A044QK07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK07"
  },
  {
    "uniprot": "A0A044S650",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S650"
  },
  {
    "uniprot": "A0A044UDG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDG8"
  },
  {
    "uniprot": "A0A044UC30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC30"
  },
  {
    "uniprot": "A0A044U6P9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6P9"
  },
  {
    "uniprot": "A0A2K6WI12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WI12"
  },
  {
    "uniprot": "A0A044USY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USY2"
  },
  {
    "uniprot": "A0A044UQT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQT6"
  },
  {
    "uniprot": "A0A044R7I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7I9"
  },
  {
    "uniprot": "A0A044UYQ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYQ2"
  },
  {
    "uniprot": "A0A044RT57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RT57"
  },
  {
    "uniprot": "A0A044T592",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T592"
  },
  {
    "uniprot": "A0A044QZM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZM3"
  },
  {
    "uniprot": "A0A044RUC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUC8"
  },
  {
    "uniprot": "A0A044RDK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDK3"
  },
  {
    "uniprot": "A0A044RMG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMG1"
  },
  {
    "uniprot": "A0A044U3Q7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3Q7"
  },
  {
    "uniprot": "A0A044RVC7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVC7"
  },
  {
    "uniprot": "A0A044TPL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPL9"
  },
  {
    "uniprot": "A0A044SIX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIX6"
  },
  {
    "uniprot": "A0A2K6VJW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJW6"
  },
  {
    "uniprot": "A0A044UJX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJX3"
  },
  {
    "uniprot": "A0A2K6VSP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSP4"
  },
  {
    "uniprot": "A0A044S6I1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6I1"
  },
  {
    "uniprot": "A0A044UZ55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ55"
  },
  {
    "uniprot": "A0A044TR47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR47"
  },
  {
    "uniprot": "A0A044U0K7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0K7"
  },
  {
    "uniprot": "A0A044R8X9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8X9"
  },
  {
    "uniprot": "A0A044RA89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RA89"
  },
  {
    "uniprot": "A0A044RBI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBI9"
  },
  {
    "uniprot": "A0A2K6VIG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIG6"
  },
  {
    "uniprot": "A0A044RPY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPY4"
  },
  {
    "uniprot": "A0A2K6W6L5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6L5"
  },
  {
    "uniprot": "A0A158N818",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N818"
  },
  {
    "uniprot": "A0A044RRH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRH5"
  },
  {
    "uniprot": "A0A044VAG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAG6"
  },
  {
    "uniprot": "A0A044UTL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTL4"
  },
  {
    "uniprot": "A0A044V516",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V516"
  },
  {
    "uniprot": "A0A044S7A6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7A6"
  },
  {
    "uniprot": "A0A2K6VXL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXL2"
  },
  {
    "uniprot": "A0A044RWS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWS7"
  },
  {
    "uniprot": "A0A044R8V3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8V3"
  },
  {
    "uniprot": "A0A044US27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044US27"
  },
  {
    "uniprot": "A0A044RSE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSE1"
  },
  {
    "uniprot": "A0A044SK23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK23"
  },
  {
    "uniprot": "A0A044RRG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRG7"
  },
  {
    "uniprot": "A0A044SKH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKH8"
  },
  {
    "uniprot": "A0A2K6VMI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMI6"
  },
  {
    "uniprot": "A0A044SAL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAL5"
  },
  {
    "uniprot": "A0A044QZS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZS0"
  },
  {
    "uniprot": "A0A044ULU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULU4"
  },
  {
    "uniprot": "A0A044TF06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TF06"
  },
  {
    "uniprot": "A0A044UYM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYM6"
  },
  {
    "uniprot": "A0A044UP51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UP51"
  },
  {
    "uniprot": "A0A044UTH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTH5"
  },
  {
    "uniprot": "A0A044SEF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEF4"
  },
  {
    "uniprot": "A0A044SYX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYX9"
  },
  {
    "uniprot": "A0A044RPK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPK2"
  },
  {
    "uniprot": "A0A044V914",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V914"
  },
  {
    "uniprot": "A0A2K6VKZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKZ9"
  },
  {
    "uniprot": "A0A044SY66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY66"
  },
  {
    "uniprot": "A0A044RWN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWN3"
  },
  {
    "uniprot": "A0A044SR42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SR42"
  },
  {
    "uniprot": "A0A044TN84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN84"
  },
  {
    "uniprot": "A0A044RPB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPB0"
  },
  {
    "uniprot": "A0A044UTZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTZ4"
  },
  {
    "uniprot": "A0A158N8B5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8B5"
  },
  {
    "uniprot": "A0A158N844",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N844"
  },
  {
    "uniprot": "A0A044UAE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAE3"
  },
  {
    "uniprot": "A0A044VIU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIU0"
  },
  {
    "uniprot": "A0A044R7Q2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7Q2"
  },
  {
    "uniprot": "A0A044V9H7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9H7"
  },
  {
    "uniprot": "A0A044SAK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAK5"
  },
  {
    "uniprot": "A0A044SIS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIS6"
  },
  {
    "uniprot": "A0A044U131",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U131"
  },
  {
    "uniprot": "A0A044SMS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMS5"
  },
  {
    "uniprot": "A0A044TPU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPU4"
  },
  {
    "uniprot": "A0A044UI72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI72"
  },
  {
    "uniprot": "A0A044S464",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S464"
  },
  {
    "uniprot": "A0A044SB08",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB08"
  },
  {
    "uniprot": "A0A044UQS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQS5"
  },
  {
    "uniprot": "A0A044TEL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEL4"
  },
  {
    "uniprot": "A0A044QJS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJS9"
  },
  {
    "uniprot": "A0A044S0Z3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0Z3"
  },
  {
    "uniprot": "A0A2K6VHF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHF4"
  },
  {
    "uniprot": "A0A044V466",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V466"
  },
  {
    "uniprot": "A0A044T8E3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8E3"
  },
  {
    "uniprot": "A0A044QQJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQJ3"
  },
  {
    "uniprot": "A0A044RI08",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RI08"
  },
  {
    "uniprot": "A0A044UAX0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAX0"
  },
  {
    "uniprot": "A0A044RDH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDH8"
  },
  {
    "uniprot": "A0A044RBU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBU3"
  },
  {
    "uniprot": "A0A2K6W060",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W060"
  },
  {
    "uniprot": "A0A044QWC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWC4"
  },
  {
    "uniprot": "A0A044TS10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS10"
  },
  {
    "uniprot": "A0A044QL57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL57"
  },
  {
    "uniprot": "A0A044V4G8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4G8"
  },
  {
    "uniprot": "A0A044SLS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLS0"
  },
  {
    "uniprot": "A0A044TAZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAZ9"
  },
  {
    "uniprot": "A0A2K6W383",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W383"
  },
  {
    "uniprot": "A0A044TU07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TU07"
  },
  {
    "uniprot": "A0A044RUA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUA1"
  },
  {
    "uniprot": "A0A044RZE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZE0"
  },
  {
    "uniprot": "A0A044U3T2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3T2"
  },
  {
    "uniprot": "A0A044VDQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDQ0"
  },
  {
    "uniprot": "A0A044SBN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBN9"
  },
  {
    "uniprot": "A0A2K6VLG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLG9"
  },
  {
    "uniprot": "A0A044QWG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWG2"
  },
  {
    "uniprot": "A0A044RFR5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFR5"
  },
  {
    "uniprot": "A0A044RSB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSB9"
  },
  {
    "uniprot": "A0A044RVF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVF2"
  },
  {
    "uniprot": "A0A044SQ63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ63"
  },
  {
    "uniprot": "A0A044UAG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAG6"
  },
  {
    "uniprot": "A0A044U6Z7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6Z7"
  },
  {
    "uniprot": "A0A044VAH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAH3"
  },
  {
    "uniprot": "A0A044UJU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJU5"
  },
  {
    "uniprot": "A0A044UCN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCN2"
  },
  {
    "uniprot": "A0A044U7S2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7S2"
  },
  {
    "uniprot": "A0A044S9L7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9L7"
  },
  {
    "uniprot": "A0A044VB20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VB20"
  },
  {
    "uniprot": "A0A044QST1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QST1"
  },
  {
    "uniprot": "A0A044S384",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S384"
  },
  {
    "uniprot": "A0A044TZZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZZ4"
  },
  {
    "uniprot": "A0A044SX86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SX86"
  },
  {
    "uniprot": "A0A044RAJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAJ9"
  },
  {
    "uniprot": "A0A044V6H3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6H3"
  },
  {
    "uniprot": "A0A2K6W6Z7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6Z7"
  },
  {
    "uniprot": "A0A044U1Z5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1Z5"
  },
  {
    "uniprot": "A0A044TAL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAL7"
  },
  {
    "uniprot": "A0A044TFZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFZ5"
  },
  {
    "uniprot": "A0A044QLJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLJ3"
  },
  {
    "uniprot": "A0A044UI58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI58"
  },
  {
    "uniprot": "A0A2K6WAI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAI4"
  },
  {
    "uniprot": "A0A044RKL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKL1"
  },
  {
    "uniprot": "A0A044RUF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUF5"
  },
  {
    "uniprot": "A0A044V982",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V982"
  },
  {
    "uniprot": "A0A044V260",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V260"
  },
  {
    "uniprot": "A0A044R128",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R128"
  },
  {
    "uniprot": "A0A044UJM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJM7"
  },
  {
    "uniprot": "A0A044U2Z6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2Z6"
  },
  {
    "uniprot": "A0A044S1D3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1D3"
  },
  {
    "uniprot": "A0A044S2H0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2H0"
  },
  {
    "uniprot": "A0A044VAK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAK8"
  },
  {
    "uniprot": "A0A044VC14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC14"
  },
  {
    "uniprot": "A0A044UIT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIT6"
  },
  {
    "uniprot": "A0A044REW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REW2"
  },
  {
    "uniprot": "A0A044S989",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S989"
  },
  {
    "uniprot": "A0A044S8E3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8E3"
  },
  {
    "uniprot": "A0A044V3Z7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3Z7"
  },
  {
    "uniprot": "A0A044RBZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBZ1"
  },
  {
    "uniprot": "A0A044SHT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHT7"
  },
  {
    "uniprot": "A0A044SS34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS34"
  },
  {
    "uniprot": "A0A044VBZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBZ9"
  },
  {
    "uniprot": "A0A044SM24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM24"
  },
  {
    "uniprot": "A0A044QPU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPU7"
  },
  {
    "uniprot": "A0A2K6VI78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VI78"
  },
  {
    "uniprot": "A0A044QTI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTI4"
  },
  {
    "uniprot": "A0A044SJ68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJ68"
  },
  {
    "uniprot": "A0A044R559",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R559"
  },
  {
    "uniprot": "A0A044RLA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLA4"
  },
  {
    "uniprot": "A0A044QXM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXM7"
  },
  {
    "uniprot": "A0A044RLJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLJ6"
  },
  {
    "uniprot": "A0A044R8L0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8L0"
  },
  {
    "uniprot": "A0A044SU00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU00"
  },
  {
    "uniprot": "A0A044UFE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFE4"
  },
  {
    "uniprot": "A0A044QTW1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTW1"
  },
  {
    "uniprot": "A0A044SL42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SL42"
  },
  {
    "uniprot": "A0A044RKY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKY6"
  },
  {
    "uniprot": "A0A044QJU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJU8"
  },
  {
    "uniprot": "A0A044TT72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TT72"
  },
  {
    "uniprot": "A0A044TW81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TW81"
  },
  {
    "uniprot": "A0A044U4T6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4T6"
  },
  {
    "uniprot": "A0A044V835",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V835"
  },
  {
    "uniprot": "A0A044SS41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS41"
  },
  {
    "uniprot": "A0A044VA62",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA62"
  },
  {
    "uniprot": "A0A044S055",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S055"
  },
  {
    "uniprot": "A0A044RGU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGU8"
  },
  {
    "uniprot": "A0A044THU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THU4"
  },
  {
    "uniprot": "A0A044SWS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWS4"
  },
  {
    "uniprot": "A0A044UQ42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQ42"
  },
  {
    "uniprot": "A0A2K6W8R1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8R1"
  },
  {
    "uniprot": "A0A044SF19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SF19"
  },
  {
    "uniprot": "A0A158N7V3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7V3"
  },
  {
    "uniprot": "A0A044TYA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYA0"
  },
  {
    "uniprot": "A0A044T407",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T407"
  },
  {
    "uniprot": "A0A044TX17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TX17"
  },
  {
    "uniprot": "A0A044S6S5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6S5"
  },
  {
    "uniprot": "A0A044UU46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UU46"
  },
  {
    "uniprot": "A0A044U1Y4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1Y4"
  },
  {
    "uniprot": "A0A044TKC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKC3"
  },
  {
    "uniprot": "A0A044R235",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R235"
  },
  {
    "uniprot": "A0A044TAM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAM7"
  },
  {
    "uniprot": "A0A044QP26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP26"
  },
  {
    "uniprot": "A0A044RLM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLM1"
  },
  {
    "uniprot": "A0A044RNL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNL9"
  },
  {
    "uniprot": "A0A044RUD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUD6"
  },
  {
    "uniprot": "A0A044U8I8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8I8"
  },
  {
    "uniprot": "A0A044UTH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTH7"
  },
  {
    "uniprot": "A0A044V9F4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9F4"
  },
  {
    "uniprot": "A0A044TKK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKK0"
  },
  {
    "uniprot": "A0A044S6P8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6P8"
  },
  {
    "uniprot": "A0A044V728",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V728"
  },
  {
    "uniprot": "A0A044UTQ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTQ8"
  },
  {
    "uniprot": "A0A044VFM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFM8"
  },
  {
    "uniprot": "A0A044RAG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAG5"
  },
  {
    "uniprot": "A0A044SIN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIN9"
  },
  {
    "uniprot": "A0A044TDA8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDA8"
  },
  {
    "uniprot": "A0A044UAY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAY0"
  },
  {
    "uniprot": "A0A044S5J1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5J1"
  },
  {
    "uniprot": "A0A044RY03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY03"
  },
  {
    "uniprot": "A0A044R6M7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6M7"
  },
  {
    "uniprot": "A0A2K6WHQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WHQ9"
  },
  {
    "uniprot": "A0A044TQM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQM4"
  },
  {
    "uniprot": "A0A044V2R2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2R2"
  },
  {
    "uniprot": "A0A2K6VX07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VX07"
  },
  {
    "uniprot": "A0A2K6VNB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VNB1"
  },
  {
    "uniprot": "A0A044U7C5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7C5"
  },
  {
    "uniprot": "A0A044V8X8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8X8"
  },
  {
    "uniprot": "A0A044TGL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGL4"
  },
  {
    "uniprot": "A0A044TJ12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJ12"
  },
  {
    "uniprot": "A0A044RZB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZB0"
  },
  {
    "uniprot": "A0A2K6WE03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WE03"
  },
  {
    "uniprot": "A0A158N811",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N811"
  },
  {
    "uniprot": "A0A044U9E1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9E1"
  },
  {
    "uniprot": "A0A044RBK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBK9"
  },
  {
    "uniprot": "A0A044UQW1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQW1"
  },
  {
    "uniprot": "A0A2K6VFL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFL4"
  },
  {
    "uniprot": "A0A044QMR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMR1"
  },
  {
    "uniprot": "A0A044RZI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZI8"
  },
  {
    "uniprot": "A0A044QQL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQL9"
  },
  {
    "uniprot": "A0A044VB99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VB99"
  },
  {
    "uniprot": "A0A044RYJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYJ7"
  },
  {
    "uniprot": "A0A044SY67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY67"
  },
  {
    "uniprot": "A0A2K6VJD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJD3"
  },
  {
    "uniprot": "A0A044R6E0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6E0"
  },
  {
    "uniprot": "A0A044T8J7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8J7"
  },
  {
    "uniprot": "A0A044RET9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RET9"
  },
  {
    "uniprot": "A0A044UC13",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC13"
  },
  {
    "uniprot": "A0A2K6VPE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPE9"
  },
  {
    "uniprot": "A0A2K6VQB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQB1"
  },
  {
    "uniprot": "A0A044TIT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIT4"
  },
  {
    "uniprot": "A0A044U5D0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5D0"
  },
  {
    "uniprot": "A0A044RZC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZC1"
  },
  {
    "uniprot": "A0A044SXT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXT4"
  },
  {
    "uniprot": "A0A044SCS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCS4"
  },
  {
    "uniprot": "A0A044TBL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBL9"
  },
  {
    "uniprot": "A0A044VG14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VG14"
  },
  {
    "uniprot": "A0A044U4Z2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4Z2"
  },
  {
    "uniprot": "A0A044TKX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKX4"
  },
  {
    "uniprot": "A0A044TMZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMZ6"
  },
  {
    "uniprot": "A0A044RWR9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWR9"
  },
  {
    "uniprot": "A0A044QQQ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQQ8"
  },
  {
    "uniprot": "A0A044TRE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRE9"
  },
  {
    "uniprot": "A0A044S6X4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6X4"
  },
  {
    "uniprot": "A0A044SZE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZE5"
  },
  {
    "uniprot": "A0A044R360",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R360"
  },
  {
    "uniprot": "A0A044TWT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWT0"
  },
  {
    "uniprot": "A0A044QJW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJW7"
  },
  {
    "uniprot": "A0A044SWQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWQ0"
  },
  {
    "uniprot": "A0A044QUF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUF1"
  },
  {
    "uniprot": "A0A044UF95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF95"
  },
  {
    "uniprot": "A0A044USL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USL6"
  },
  {
    "uniprot": "A0A044V0Q1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0Q1"
  },
  {
    "uniprot": "A0A044UPD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPD6"
  },
  {
    "uniprot": "A0A044QN00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QN00"
  },
  {
    "uniprot": "A0A044UZ57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ57"
  },
  {
    "uniprot": "A0A044RXM9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXM9"
  },
  {
    "uniprot": "A0A2K6WCW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WCW9"
  },
  {
    "uniprot": "A0A044UM60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UM60"
  },
  {
    "uniprot": "A0A044SVA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVA9"
  },
  {
    "uniprot": "A0A044VHH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHH3"
  },
  {
    "uniprot": "A0A044SZ54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ54"
  },
  {
    "uniprot": "A0A044RJF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJF2"
  },
  {
    "uniprot": "A0A2K6VVE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVE2"
  },
  {
    "uniprot": "A0A044SD09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD09"
  },
  {
    "uniprot": "A0A044VC60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC60"
  },
  {
    "uniprot": "A0A044RSD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSD5"
  },
  {
    "uniprot": "A0A044S489",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S489"
  },
  {
    "uniprot": "A0A044QZH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZH2"
  },
  {
    "uniprot": "A0A044V183",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V183"
  },
  {
    "uniprot": "A0A044TH35",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH35"
  },
  {
    "uniprot": "A0A2K6WDF7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDF7"
  },
  {
    "uniprot": "A0A044SCZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCZ5"
  },
  {
    "uniprot": "A0A044T6N5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6N5"
  },
  {
    "uniprot": "A0A044QY12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY12"
  },
  {
    "uniprot": "A0A044V1B1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1B1"
  },
  {
    "uniprot": "A0A044UI41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI41"
  },
  {
    "uniprot": "A0A044UNK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNK0"
  },
  {
    "uniprot": "A0A044T3D7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3D7"
  },
  {
    "uniprot": "A0A044UH70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UH70"
  },
  {
    "uniprot": "A0A044RQY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQY6"
  },
  {
    "uniprot": "P31729",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P31729"
  },
  {
    "uniprot": "A0A044R0N3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0N3"
  },
  {
    "uniprot": "A0A044R6A2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6A2"
  },
  {
    "uniprot": "A0A044TIR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIR8"
  },
  {
    "uniprot": "A0A2K6WC74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WC74"
  },
  {
    "uniprot": "A0A044S4B4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4B4"
  },
  {
    "uniprot": "A0A044R8E1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8E1"
  },
  {
    "uniprot": "A0A044UW28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UW28"
  },
  {
    "uniprot": "A0A044S931",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S931"
  },
  {
    "uniprot": "A0A044T9W4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9W4"
  },
  {
    "uniprot": "A0A044U021",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U021"
  },
  {
    "uniprot": "A0A044R915",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R915"
  },
  {
    "uniprot": "A0A2K6VYZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYZ7"
  },
  {
    "uniprot": "A0A044TT09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TT09"
  },
  {
    "uniprot": "A0A044SSG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSG0"
  },
  {
    "uniprot": "A0A044URW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URW0"
  },
  {
    "uniprot": "A0A2K6VXE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXE0"
  },
  {
    "uniprot": "A0A044V9B4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9B4"
  },
  {
    "uniprot": "A0A044S123",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S123"
  },
  {
    "uniprot": "A0A044TX03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TX03"
  },
  {
    "uniprot": "A0A044S5R4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5R4"
  },
  {
    "uniprot": "A0A044RH63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH63"
  },
  {
    "uniprot": "A0A044U175",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U175"
  },
  {
    "uniprot": "A0A044R4W5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4W5"
  },
  {
    "uniprot": "A0A044S2R1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2R1"
  },
  {
    "uniprot": "A0A2K6W0I5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0I5"
  },
  {
    "uniprot": "A0A2K6VTB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VTB9"
  },
  {
    "uniprot": "A0A044QTG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTG9"
  },
  {
    "uniprot": "A0A044RH12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH12"
  },
  {
    "uniprot": "A0A2K6VV83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VV83"
  },
  {
    "uniprot": "A0A044U1F8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1F8"
  },
  {
    "uniprot": "A0A044V118",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V118"
  },
  {
    "uniprot": "A0A044QY90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY90"
  },
  {
    "uniprot": "A0A044S334",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S334"
  },
  {
    "uniprot": "A0A044UEH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEH0"
  },
  {
    "uniprot": "A0A044T9E7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9E7"
  },
  {
    "uniprot": "A0A044SXI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXI1"
  },
  {
    "uniprot": "A0A044QKL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKL9"
  },
  {
    "uniprot": "A0A044UZY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZY2"
  },
  {
    "uniprot": "A0A044UEI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEI8"
  },
  {
    "uniprot": "A0A044SSQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSQ1"
  },
  {
    "uniprot": "A0A044V707",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V707"
  },
  {
    "uniprot": "A0A044SXF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXF6"
  },
  {
    "uniprot": "A0A044SB63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB63"
  },
  {
    "uniprot": "A0A044RKW1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKW1"
  },
  {
    "uniprot": "A0A044S9T2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9T2"
  },
  {
    "uniprot": "A0A044T0X9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0X9"
  },
  {
    "uniprot": "A0A044VEZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEZ6"
  },
  {
    "uniprot": "A0A044TM04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TM04"
  },
  {
    "uniprot": "A0A044TDG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDG0"
  },
  {
    "uniprot": "A0A044SN97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN97"
  },
  {
    "uniprot": "A0A2K6WFT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFT7"
  },
  {
    "uniprot": "A0A044RGG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGG6"
  },
  {
    "uniprot": "A0A044U3Z1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3Z1"
  },
  {
    "uniprot": "A0A044TV25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV25"
  },
  {
    "uniprot": "A0A044TLX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLX6"
  },
  {
    "uniprot": "A0A044RIZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIZ7"
  },
  {
    "uniprot": "A0A044RIK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIK4"
  },
  {
    "uniprot": "A0A044RL99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RL99"
  },
  {
    "uniprot": "A0A044QNK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNK1"
  },
  {
    "uniprot": "A0A044V8Q1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Q1"
  },
  {
    "uniprot": "A0A044ULV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULV1"
  },
  {
    "uniprot": "A0A2K6VIT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIT7"
  },
  {
    "uniprot": "A0A044V9H3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9H3"
  },
  {
    "uniprot": "A0A044TEJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEJ9"
  },
  {
    "uniprot": "A0A044TLP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLP4"
  },
  {
    "uniprot": "A0A044SZ23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ23"
  },
  {
    "uniprot": "A0A044THR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THR1"
  },
  {
    "uniprot": "A0A2K6WEX0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEX0"
  },
  {
    "uniprot": "A0A044UT19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT19"
  },
  {
    "uniprot": "A0A044TJJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJJ4"
  },
  {
    "uniprot": "P22085",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P22085"
  },
  {
    "uniprot": "A0A044U192",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U192"
  },
  {
    "uniprot": "A0A044UYY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYY4"
  },
  {
    "uniprot": "A0A044QPA8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPA8"
  },
  {
    "uniprot": "A0A2K6W794",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W794"
  },
  {
    "uniprot": "A0A044TTE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTE9"
  },
  {
    "uniprot": "A0A044V9I1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9I1"
  },
  {
    "uniprot": "A0A044US81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044US81"
  },
  {
    "uniprot": "A0A044V0C7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0C7"
  },
  {
    "uniprot": "A0A044RL64",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RL64"
  },
  {
    "uniprot": "A0A044TBN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBN7"
  },
  {
    "uniprot": "A0A044VHL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHL1"
  },
  {
    "uniprot": "A0A2K6W9S8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9S8"
  },
  {
    "uniprot": "A0A2K6WCW1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WCW1"
  },
  {
    "uniprot": "A0A044TC03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC03"
  },
  {
    "uniprot": "A0A044QKP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKP8"
  },
  {
    "uniprot": "A0A044TKE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKE1"
  },
  {
    "uniprot": "A0A044TY78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TY78"
  },
  {
    "uniprot": "A0A044SQ43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ43"
  },
  {
    "uniprot": "A0A044SZD4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZD4"
  },
  {
    "uniprot": "A0A044SNN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNN2"
  },
  {
    "uniprot": "A0A2K6VH75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VH75"
  },
  {
    "uniprot": "A0A044URU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URU6"
  },
  {
    "uniprot": "A0A044T7F7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7F7"
  },
  {
    "uniprot": "A0A044RRZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRZ8"
  },
  {
    "uniprot": "A0A044T5D6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5D6"
  },
  {
    "uniprot": "A0A044TNC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNC0"
  },
  {
    "uniprot": "A0A044T2S6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2S6"
  },
  {
    "uniprot": "A0A044RNV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNV0"
  },
  {
    "uniprot": "A0A044S1P8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1P8"
  },
  {
    "uniprot": "A0A044SZ45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ45"
  },
  {
    "uniprot": "A0A044UKW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKW3"
  },
  {
    "uniprot": "A0A044R816",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R816"
  },
  {
    "uniprot": "A0A044STM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STM1"
  },
  {
    "uniprot": "A0A044URJ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URJ2"
  },
  {
    "uniprot": "A0A044U1Q6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1Q6"
  },
  {
    "uniprot": "A0A044SIC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIC0"
  },
  {
    "uniprot": "A0A044R3X9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3X9"
  },
  {
    "uniprot": "A0A044STR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STR4"
  },
  {
    "uniprot": "A0A044TLL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLL6"
  },
  {
    "uniprot": "A0A044UQ54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQ54"
  },
  {
    "uniprot": "A0A044TJN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJN4"
  },
  {
    "uniprot": "A0A044SK94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK94"
  },
  {
    "uniprot": "A0A044SZ68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ68"
  },
  {
    "uniprot": "A0A044UJM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJM3"
  },
  {
    "uniprot": "A0A044UV58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UV58"
  },
  {
    "uniprot": "A0A044SN85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN85"
  },
  {
    "uniprot": "A0A044V6C7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6C7"
  },
  {
    "uniprot": "A0A044QVP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVP9"
  },
  {
    "uniprot": "A0A044U577",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U577"
  },
  {
    "uniprot": "A0A2K6WEB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEB8"
  },
  {
    "uniprot": "A0A044V9W7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9W7"
  },
  {
    "uniprot": "A0A044U1V0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1V0"
  },
  {
    "uniprot": "A0A044QWL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWL9"
  },
  {
    "uniprot": "A0A044VHC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHC2"
  },
  {
    "uniprot": "A0A044S950",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S950"
  },
  {
    "uniprot": "A0A044UBA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBA3"
  },
  {
    "uniprot": "A0A2K6WCG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WCG9"
  },
  {
    "uniprot": "A0A044TE09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TE09"
  },
  {
    "uniprot": "A0A044UNI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNI7"
  },
  {
    "uniprot": "A0A044SIZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIZ7"
  },
  {
    "uniprot": "A0A044RE14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RE14"
  },
  {
    "uniprot": "A0A044THC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THC2"
  },
  {
    "uniprot": "A0A044RV97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RV97"
  },
  {
    "uniprot": "A0A044SDQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDQ0"
  },
  {
    "uniprot": "A0A044UKV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKV1"
  },
  {
    "uniprot": "A0A044S9L1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9L1"
  },
  {
    "uniprot": "A0A044TSH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSH8"
  },
  {
    "uniprot": "A0A044QVY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVY6"
  },
  {
    "uniprot": "A0A044TUH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUH8"
  },
  {
    "uniprot": "A0A044V965",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V965"
  },
  {
    "uniprot": "A0A044UX82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX82"
  },
  {
    "uniprot": "A0A2K6W3J9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3J9"
  },
  {
    "uniprot": "A0A044T1T5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1T5"
  },
  {
    "uniprot": "A0A044V9C7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9C7"
  },
  {
    "uniprot": "A0A2K6WHA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WHA6"
  },
  {
    "uniprot": "A0A044R6P1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6P1"
  },
  {
    "uniprot": "A0A044V463",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V463"
  },
  {
    "uniprot": "A0A044TPS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPS6"
  },
  {
    "uniprot": "A0A2K6VJW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJW7"
  },
  {
    "uniprot": "A0A044VIS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIS0"
  },
  {
    "uniprot": "A0A044TKU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKU5"
  },
  {
    "uniprot": "A0A044V909",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V909"
  },
  {
    "uniprot": "A0A2K6VMP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMP6"
  },
  {
    "uniprot": "A0A044TQV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQV9"
  },
  {
    "uniprot": "A0A044V324",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V324"
  },
  {
    "uniprot": "A0A044SIN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIN0"
  },
  {
    "uniprot": "A0A044R3S2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3S2"
  },
  {
    "uniprot": "A0A044TPD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPD5"
  },
  {
    "uniprot": "A0A044TPB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPB0"
  },
  {
    "uniprot": "A0A044S7V6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7V6"
  },
  {
    "uniprot": "A0A044UEA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEA1"
  },
  {
    "uniprot": "A0A044S3S7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3S7"
  },
  {
    "uniprot": "A0A044SQM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQM1"
  },
  {
    "uniprot": "A0A044QVR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVR8"
  },
  {
    "uniprot": "A0A158N871",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N871"
  },
  {
    "uniprot": "A0A044QKR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKR6"
  },
  {
    "uniprot": "A0A2K6VZ55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZ55"
  },
  {
    "uniprot": "A0A2K6WBD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBD8"
  },
  {
    "uniprot": "A0A044TBL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBL3"
  },
  {
    "uniprot": "A0A044TTT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTT2"
  },
  {
    "uniprot": "A0A044V196",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V196"
  },
  {
    "uniprot": "A0A044U0S8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0S8"
  },
  {
    "uniprot": "A0A044SM28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM28"
  },
  {
    "uniprot": "A0A044TC79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC79"
  },
  {
    "uniprot": "A0A044UNN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNN6"
  },
  {
    "uniprot": "A0A2K6VKA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKA3"
  },
  {
    "uniprot": "A0A044UBC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBC4"
  },
  {
    "uniprot": "A0A2K6W156",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W156"
  },
  {
    "uniprot": "A0A044UFI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFI9"
  },
  {
    "uniprot": "A0A044V4D1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4D1"
  },
  {
    "uniprot": "A0A044TWZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWZ8"
  },
  {
    "uniprot": "A0A044TBE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBE2"
  },
  {
    "uniprot": "A0A044RIR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIR1"
  },
  {
    "uniprot": "A0A044V0H5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0H5"
  },
  {
    "uniprot": "A0A044RQL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQL0"
  },
  {
    "uniprot": "A0A044TKH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKH8"
  },
  {
    "uniprot": "A0A044T0R5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0R5"
  },
  {
    "uniprot": "A0A044V9Q0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9Q0"
  },
  {
    "uniprot": "A0A044TWV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWV0"
  },
  {
    "uniprot": "A0A044THA8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THA8"
  },
  {
    "uniprot": "A0A044V3U4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3U4"
  },
  {
    "uniprot": "A0A044TJS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJS2"
  },
  {
    "uniprot": "A0A044T1K7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1K7"
  },
  {
    "uniprot": "A0A044T653",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T653"
  },
  {
    "uniprot": "A0A044S3F3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3F3"
  },
  {
    "uniprot": "A0A044RG39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RG39"
  },
  {
    "uniprot": "A0A044S711",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S711"
  },
  {
    "uniprot": "A0A044R858",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R858"
  },
  {
    "uniprot": "A0A044STF7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STF7"
  },
  {
    "uniprot": "A0A044U2P8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2P8"
  },
  {
    "uniprot": "A0A044T6L7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6L7"
  },
  {
    "uniprot": "A0A2K6VDV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDV6"
  },
  {
    "uniprot": "A0A044U5E4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5E4"
  },
  {
    "uniprot": "A0A2K6VEU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VEU3"
  },
  {
    "uniprot": "A0A044RUF9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUF9"
  },
  {
    "uniprot": "A0A044SLA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLA9"
  },
  {
    "uniprot": "A0A044VFE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFE4"
  },
  {
    "uniprot": "A0A044VC79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC79"
  },
  {
    "uniprot": "A0A044UXR5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXR5"
  },
  {
    "uniprot": "A0A2K6VDW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDW9"
  },
  {
    "uniprot": "A0A044U407",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U407"
  },
  {
    "uniprot": "A0A044SHY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHY6"
  },
  {
    "uniprot": "A0A2K6VS67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VS67"
  },
  {
    "uniprot": "A0A044TST4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TST4"
  },
  {
    "uniprot": "A0A044T4H2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4H2"
  },
  {
    "uniprot": "A0A044QN05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QN05"
  },
  {
    "uniprot": "A0A044UPG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPG0"
  },
  {
    "uniprot": "A0A044TKG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKG5"
  },
  {
    "uniprot": "A0A044T3L8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3L8"
  },
  {
    "uniprot": "A0A044U1A8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1A8"
  },
  {
    "uniprot": "A0A044TDG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDG8"
  },
  {
    "uniprot": "A0A044V9E5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9E5"
  },
  {
    "uniprot": "A0A2K6VHJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHJ7"
  },
  {
    "uniprot": "A0A044QR53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QR53"
  },
  {
    "uniprot": "A0A044URB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URB4"
  },
  {
    "uniprot": "A0A2K6WLB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLB3"
  },
  {
    "uniprot": "A0A158N875",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N875"
  },
  {
    "uniprot": "A0A044V443",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V443"
  },
  {
    "uniprot": "A0A044TP99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TP99"
  },
  {
    "uniprot": "A0A044STI5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STI5"
  },
  {
    "uniprot": "A0A044U8W5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8W5"
  },
  {
    "uniprot": "A0A044T314",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T314"
  },
  {
    "uniprot": "A0A2K6VYJ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYJ2"
  },
  {
    "uniprot": "A0A044S4M6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4M6"
  },
  {
    "uniprot": "A0A044R7U2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7U2"
  },
  {
    "uniprot": "A0A044R7Y8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7Y8"
  },
  {
    "uniprot": "A0A158N7Y2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Y2"
  },
  {
    "uniprot": "A0A044U9W0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9W0"
  },
  {
    "uniprot": "A0A044VC42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC42"
  },
  {
    "uniprot": "A0A044TW06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TW06"
  },
  {
    "uniprot": "A0A044VBY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBY3"
  },
  {
    "uniprot": "A0A2K6WKR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKR4"
  },
  {
    "uniprot": "A0A044QNX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNX8"
  },
  {
    "uniprot": "A0A044VI65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VI65"
  },
  {
    "uniprot": "A0A044RH58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH58"
  },
  {
    "uniprot": "A0A044RBK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBK2"
  },
  {
    "uniprot": "A0A2K6WD32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WD32"
  },
  {
    "uniprot": "A0A044QRU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRU6"
  },
  {
    "uniprot": "A0A044UKZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKZ2"
  },
  {
    "uniprot": "A0A044TCJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCJ1"
  },
  {
    "uniprot": "A0A044TUL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUL5"
  },
  {
    "uniprot": "A0A044SB66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB66"
  },
  {
    "uniprot": "A0A044VBW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBW2"
  },
  {
    "uniprot": "A0A044ULP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULP2"
  },
  {
    "uniprot": "A0A044SUY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUY9"
  },
  {
    "uniprot": "A0A044R0T3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0T3"
  },
  {
    "uniprot": "A0A044UGY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGY6"
  },
  {
    "uniprot": "A0A044SP47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SP47"
  },
  {
    "uniprot": "A0A044RIZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIZ4"
  },
  {
    "uniprot": "A0A044SRW1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRW1"
  },
  {
    "uniprot": "A0A044VG97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VG97"
  },
  {
    "uniprot": "A0A044VI09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VI09"
  },
  {
    "uniprot": "A0A044VF81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VF81"
  },
  {
    "uniprot": "A0A044VF51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VF51"
  },
  {
    "uniprot": "A0A044V180",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V180"
  },
  {
    "uniprot": "A0A044V984",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V984"
  },
  {
    "uniprot": "A0A044SG07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG07"
  },
  {
    "uniprot": "A0A044QJQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJQ4"
  },
  {
    "uniprot": "A0A2K6VR74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VR74"
  },
  {
    "uniprot": "A0A044SXC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXC4"
  },
  {
    "uniprot": "A0A2K6VDY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDY7"
  },
  {
    "uniprot": "A0A044SG51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG51"
  },
  {
    "uniprot": "A0A2K6VLD4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLD4"
  },
  {
    "uniprot": "A0A044QJX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJX2"
  },
  {
    "uniprot": "A0A044R7R7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7R7"
  },
  {
    "uniprot": "A0A044RV49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RV49"
  },
  {
    "uniprot": "A0A044TP84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TP84"
  },
  {
    "uniprot": "A0A044TVT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVT7"
  },
  {
    "uniprot": "A0A044TCU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCU7"
  },
  {
    "uniprot": "A0A044TJE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJE6"
  },
  {
    "uniprot": "A0A044VDG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDG0"
  },
  {
    "uniprot": "A0A044U1C1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1C1"
  },
  {
    "uniprot": "A0A044THK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THK1"
  },
  {
    "uniprot": "A0A2K6W3P1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3P1"
  },
  {
    "uniprot": "A0A044RGL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGL4"
  },
  {
    "uniprot": "A0A044TP78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TP78"
  },
  {
    "uniprot": "A0A044SUV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUV8"
  },
  {
    "uniprot": "A0A044SEM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEM2"
  },
  {
    "uniprot": "A0A044R8L5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8L5"
  },
  {
    "uniprot": "A0A2K6VET2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VET2"
  },
  {
    "uniprot": "A0A044QLW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLW9"
  },
  {
    "uniprot": "A0A044TIB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIB0"
  },
  {
    "uniprot": "A0A044UNS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNS5"
  },
  {
    "uniprot": "A0A2K6W7M0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7M0"
  },
  {
    "uniprot": "A0A044TEV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEV2"
  },
  {
    "uniprot": "A0A044UG63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UG63"
  },
  {
    "uniprot": "A0A2K6VIN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIN8"
  },
  {
    "uniprot": "A0A044TME1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TME1"
  },
  {
    "uniprot": "A0A044RG80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RG80"
  },
  {
    "uniprot": "A0A044ULH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULH2"
  },
  {
    "uniprot": "A0A044V473",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V473"
  },
  {
    "uniprot": "A0A044S574",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S574"
  },
  {
    "uniprot": "A0A2K6VRZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRZ3"
  },
  {
    "uniprot": "A0A044UAS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAS6"
  },
  {
    "uniprot": "A0A044VHI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHI7"
  },
  {
    "uniprot": "A0A044RY18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY18"
  },
  {
    "uniprot": "A0A2K6W0A6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0A6"
  },
  {
    "uniprot": "A0A044SQA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQA3"
  },
  {
    "uniprot": "A0A2K6WL58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WL58"
  },
  {
    "uniprot": "A0A044T7R7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7R7"
  },
  {
    "uniprot": "A0A044V2Q2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2Q2"
  },
  {
    "uniprot": "A0A044S4K7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4K7"
  },
  {
    "uniprot": "A0A044REV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REV5"
  },
  {
    "uniprot": "A0A044UJL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJL9"
  },
  {
    "uniprot": "A0A2K6WIP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIP7"
  },
  {
    "uniprot": "A0A044S563",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S563"
  },
  {
    "uniprot": "A0A044U965",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U965"
  },
  {
    "uniprot": "A0A044U898",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U898"
  },
  {
    "uniprot": "A0A2K6VTT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VTT8"
  },
  {
    "uniprot": "A0A044SUA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUA7"
  },
  {
    "uniprot": "A0A044RF10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RF10"
  },
  {
    "uniprot": "A0A044SAZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAZ5"
  },
  {
    "uniprot": "A0A044UNQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNQ7"
  },
  {
    "uniprot": "A0A044UAY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAY5"
  },
  {
    "uniprot": "A0A044R5V3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5V3"
  },
  {
    "uniprot": "A0A044U6W6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6W6"
  },
  {
    "uniprot": "A0A2K6VJP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJP1"
  },
  {
    "uniprot": "A0A044RKA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKA0"
  },
  {
    "uniprot": "A0A044T662",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T662"
  },
  {
    "uniprot": "A0A044UVD0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVD0"
  },
  {
    "uniprot": "A0A2K6WD70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WD70"
  },
  {
    "uniprot": "A0A044SX36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SX36"
  },
  {
    "uniprot": "A0A044TRP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRP0"
  },
  {
    "uniprot": "A0A044V926",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V926"
  },
  {
    "uniprot": "A0A044S0X8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0X8"
  },
  {
    "uniprot": "A0A044UK47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UK47"
  },
  {
    "uniprot": "A0A044RHK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHK2"
  },
  {
    "uniprot": "A0A2K6VSA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSA9"
  },
  {
    "uniprot": "A0A044SKU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKU1"
  },
  {
    "uniprot": "A0A044T9G8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9G8"
  },
  {
    "uniprot": "A0A044TYY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYY0"
  },
  {
    "uniprot": "A0A044SV01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SV01"
  },
  {
    "uniprot": "A0A044SCT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCT4"
  },
  {
    "uniprot": "A0A044SXH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXH1"
  },
  {
    "uniprot": "A0A044TIQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIQ1"
  },
  {
    "uniprot": "A0A044TK65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK65"
  },
  {
    "uniprot": "A0A044SHF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHF3"
  },
  {
    "uniprot": "A0A158N819",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N819"
  },
  {
    "uniprot": "A0A044UFC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFC0"
  },
  {
    "uniprot": "A0A044RHV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHV1"
  },
  {
    "uniprot": "A0A044V2E6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2E6"
  },
  {
    "uniprot": "A0A044V1S5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1S5"
  },
  {
    "uniprot": "A0A044RKG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKG8"
  },
  {
    "uniprot": "A0A044QPS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPS0"
  },
  {
    "uniprot": "A0A044TGS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGS2"
  },
  {
    "uniprot": "A0A044VA77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA77"
  },
  {
    "uniprot": "A0A044S3M1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3M1"
  },
  {
    "uniprot": "A0A044R089",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R089"
  },
  {
    "uniprot": "A0A044T0Y2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0Y2"
  },
  {
    "uniprot": "A0A044U6C6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6C6"
  },
  {
    "uniprot": "A0A044V651",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V651"
  },
  {
    "uniprot": "A0A044ST93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ST93"
  },
  {
    "uniprot": "A0A2K6W1V0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1V0"
  },
  {
    "uniprot": "A0A044RCA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCA9"
  },
  {
    "uniprot": "A0A044UBK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBK5"
  },
  {
    "uniprot": "A0A044UCJ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCJ5"
  },
  {
    "uniprot": "A0A044UJZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJZ4"
  },
  {
    "uniprot": "A0A044UMB2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMB2"
  },
  {
    "uniprot": "A0A2K6W2Y2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2Y2"
  },
  {
    "uniprot": "A0A044USF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USF3"
  },
  {
    "uniprot": "A0A044UXY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXY5"
  },
  {
    "uniprot": "A0A044UQM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQM8"
  },
  {
    "uniprot": "A0A044V2K7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2K7"
  },
  {
    "uniprot": "A0A044TYP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYP6"
  },
  {
    "uniprot": "A0A044UZ68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ68"
  },
  {
    "uniprot": "A0A044SGS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGS5"
  },
  {
    "uniprot": "A0A044RGJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGJ4"
  },
  {
    "uniprot": "A0A044RK73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RK73"
  },
  {
    "uniprot": "A0A044TE58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TE58"
  },
  {
    "uniprot": "A0A044T398",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T398"
  },
  {
    "uniprot": "A0A2K6WFY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFY7"
  },
  {
    "uniprot": "A0A044QY25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY25"
  },
  {
    "uniprot": "A0A044U8G6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8G6"
  },
  {
    "uniprot": "A0A044U1V9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1V9"
  },
  {
    "uniprot": "A0A044V4P7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4P7"
  },
  {
    "uniprot": "A0A044TDY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDY6"
  },
  {
    "uniprot": "A0A044RXM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXM0"
  },
  {
    "uniprot": "A0A044UWJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWJ3"
  },
  {
    "uniprot": "A0A044U1T6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1T6"
  },
  {
    "uniprot": "A0A2K6VU71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VU71"
  },
  {
    "uniprot": "A0A2K6WH52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WH52"
  },
  {
    "uniprot": "A0A044UK70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UK70"
  },
  {
    "uniprot": "A0A044TDH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDH1"
  },
  {
    "uniprot": "A0A044RQN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQN1"
  },
  {
    "uniprot": "A0A044RCQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCQ1"
  },
  {
    "uniprot": "A0A044THR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THR7"
  },
  {
    "uniprot": "A0A044TIB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIB6"
  },
  {
    "uniprot": "A0A044SPR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPR1"
  },
  {
    "uniprot": "A0A044TZH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZH8"
  },
  {
    "uniprot": "A0A044TDU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDU7"
  },
  {
    "uniprot": "A0A044QMV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMV5"
  },
  {
    "uniprot": "A0A044RB09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RB09"
  },
  {
    "uniprot": "A0A044V9L9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9L9"
  },
  {
    "uniprot": "A0A044QU79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QU79"
  },
  {
    "uniprot": "A0A2K6WMM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMM3"
  },
  {
    "uniprot": "A0A044QX84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QX84"
  },
  {
    "uniprot": "A0A044SZ75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ75"
  },
  {
    "uniprot": "A0A044QVB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVB0"
  },
  {
    "uniprot": "A0A158N864",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N864"
  },
  {
    "uniprot": "A0A044QZ34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ34"
  },
  {
    "uniprot": "A0A044TG05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TG05"
  },
  {
    "uniprot": "A0A044TJ55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJ55"
  },
  {
    "uniprot": "A0A044SCQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCQ4"
  },
  {
    "uniprot": "A0A044V950",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V950"
  },
  {
    "uniprot": "A0A044SXE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXE6"
  },
  {
    "uniprot": "A0A2K6VZV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZV8"
  },
  {
    "uniprot": "A0A044TIT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIT8"
  },
  {
    "uniprot": "A0A044UX78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX78"
  },
  {
    "uniprot": "A0A2K6WF35",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WF35"
  },
  {
    "uniprot": "A0A044TA23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TA23"
  },
  {
    "uniprot": "A0A044SW03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SW03"
  },
  {
    "uniprot": "A0A044V8J1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8J1"
  },
  {
    "uniprot": "A0A044RNY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNY2"
  },
  {
    "uniprot": "A0A044UJD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJD3"
  },
  {
    "uniprot": "A0A2K6VW42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VW42"
  },
  {
    "uniprot": "A0A044UMI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMI4"
  },
  {
    "uniprot": "A0A2K6VT25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VT25"
  },
  {
    "uniprot": "A0A044SBU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBU9"
  },
  {
    "uniprot": "A0A044V158",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V158"
  },
  {
    "uniprot": "A0A044QZS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZS5"
  },
  {
    "uniprot": "A0A044U0G7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0G7"
  },
  {
    "uniprot": "A0A044RS62",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RS62"
  },
  {
    "uniprot": "A0A044V0B5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0B5"
  },
  {
    "uniprot": "A0A044TND0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TND0"
  },
  {
    "uniprot": "A0A044T370",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T370"
  },
  {
    "uniprot": "A0A044TUT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUT9"
  },
  {
    "uniprot": "A0A044QPZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPZ4"
  },
  {
    "uniprot": "A0A044V9Q8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9Q8"
  },
  {
    "uniprot": "A0A044UX15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX15"
  },
  {
    "uniprot": "A0A044R0F9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0F9"
  },
  {
    "uniprot": "A0A044T0H5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0H5"
  },
  {
    "uniprot": "A0A044UQV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQV5"
  },
  {
    "uniprot": "A0A044RNE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNE4"
  },
  {
    "uniprot": "A0A044UL97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL97"
  },
  {
    "uniprot": "A0A044UMZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMZ1"
  },
  {
    "uniprot": "A0A044RR09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RR09"
  },
  {
    "uniprot": "A0A044V704",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V704"
  },
  {
    "uniprot": "A0A044TR03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR03"
  },
  {
    "uniprot": "A0A044SZX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZX9"
  },
  {
    "uniprot": "A0A044V312",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V312"
  },
  {
    "uniprot": "A0A2K6VHW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHW8"
  },
  {
    "uniprot": "A0A044S8V6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8V6"
  },
  {
    "uniprot": "A0A044UNQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNQ3"
  },
  {
    "uniprot": "A0A044UFC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFC5"
  },
  {
    "uniprot": "A0A044SKJ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKJ5"
  },
  {
    "uniprot": "A0A044TS52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS52"
  },
  {
    "uniprot": "A0A2K6VIU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIU8"
  },
  {
    "uniprot": "A0A044V703",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V703"
  },
  {
    "uniprot": "A0A044RLI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLI1"
  },
  {
    "uniprot": "A0A2K6VDK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDK6"
  },
  {
    "uniprot": "A0A044TVQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVQ5"
  },
  {
    "uniprot": "A0A044S1G9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1G9"
  },
  {
    "uniprot": "A0A044T393",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T393"
  },
  {
    "uniprot": "A0A044V9Z2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9Z2"
  },
  {
    "uniprot": "A0A044VCC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCC3"
  },
  {
    "uniprot": "A0A158N810",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N810"
  },
  {
    "uniprot": "A0A044T0N6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0N6"
  },
  {
    "uniprot": "A0A158N8F7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8F7"
  },
  {
    "uniprot": "A0A044V4C1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4C1"
  },
  {
    "uniprot": "A0A044QL11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL11"
  },
  {
    "uniprot": "A0A044R9Z5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9Z5"
  },
  {
    "uniprot": "A0A044TAZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAZ3"
  },
  {
    "uniprot": "A0A044RVE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVE3"
  },
  {
    "uniprot": "A0A044U0M2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0M2"
  },
  {
    "uniprot": "A0A044QPJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPJ6"
  },
  {
    "uniprot": "A0A2K6WC29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WC29"
  },
  {
    "uniprot": "A0A044U799",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U799"
  },
  {
    "uniprot": "A0A044RVN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVN9"
  },
  {
    "uniprot": "A0A044U3U1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3U1"
  },
  {
    "uniprot": "A0A158N8N6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8N6"
  },
  {
    "uniprot": "A0A044S972",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S972"
  },
  {
    "uniprot": "A0A044QVB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVB4"
  },
  {
    "uniprot": "A0A044V108",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V108"
  },
  {
    "uniprot": "A0A044TGT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGT4"
  },
  {
    "uniprot": "A0A044S708",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S708"
  },
  {
    "uniprot": "A0A044V357",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V357"
  },
  {
    "uniprot": "A0A044T1M0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1M0"
  },
  {
    "uniprot": "A0A044S8Q8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8Q8"
  },
  {
    "uniprot": "A0A044UWQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWQ1"
  },
  {
    "uniprot": "A0A044R926",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R926"
  },
  {
    "uniprot": "A0A044V8H9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8H9"
  },
  {
    "uniprot": "A0A044SZQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZQ6"
  },
  {
    "uniprot": "A0A044T1F8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1F8"
  },
  {
    "uniprot": "A0A044UAK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAK1"
  },
  {
    "uniprot": "A0A044RAP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAP0"
  },
  {
    "uniprot": "A0A044VFW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFW9"
  },
  {
    "uniprot": "A0A044THL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THL7"
  },
  {
    "uniprot": "A0A044S2W5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2W5"
  },
  {
    "uniprot": "A0A044UKD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKD1"
  },
  {
    "uniprot": "A0A044SGR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGR0"
  },
  {
    "uniprot": "A0A044SCW1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCW1"
  },
  {
    "uniprot": "A0A044UNV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNV3"
  },
  {
    "uniprot": "A0A044U8A7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8A7"
  },
  {
    "uniprot": "A0A044TBR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBR6"
  },
  {
    "uniprot": "A0A044V7J0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7J0"
  },
  {
    "uniprot": "A0A2K6VNE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VNE6"
  },
  {
    "uniprot": "A0A044UGV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGV6"
  },
  {
    "uniprot": "A0A044SK39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK39"
  },
  {
    "uniprot": "A0A044REG3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REG3"
  },
  {
    "uniprot": "A0A044TTZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTZ9"
  },
  {
    "uniprot": "A0A044RBW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBW7"
  },
  {
    "uniprot": "A0A044UJD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJD7"
  },
  {
    "uniprot": "A0A044R490",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R490"
  },
  {
    "uniprot": "A0A044TU65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TU65"
  },
  {
    "uniprot": "A0A044TSD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSD5"
  },
  {
    "uniprot": "A0A044T7J7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7J7"
  },
  {
    "uniprot": "A0A044QNI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNI6"
  },
  {
    "uniprot": "A0A044RX18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RX18"
  },
  {
    "uniprot": "A0A044UDM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDM5"
  },
  {
    "uniprot": "A0A044TS22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS22"
  },
  {
    "uniprot": "A0A044SRW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRW7"
  },
  {
    "uniprot": "A0A044S7M9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7M9"
  },
  {
    "uniprot": "A0A044UCH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCH7"
  },
  {
    "uniprot": "A0A044QK16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK16"
  },
  {
    "uniprot": "A0A044RU91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU91"
  },
  {
    "uniprot": "A0A158N848",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N848"
  },
  {
    "uniprot": "A0A044SUN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUN4"
  },
  {
    "uniprot": "A0A2K6VRB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRB1"
  },
  {
    "uniprot": "A0A2K6VFC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFC8"
  },
  {
    "uniprot": "A0A044QSI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSI7"
  },
  {
    "uniprot": "A0A044SK90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK90"
  },
  {
    "uniprot": "A0A044S804",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S804"
  },
  {
    "uniprot": "A0A044SNS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNS0"
  },
  {
    "uniprot": "A0A044UZ11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ11"
  },
  {
    "uniprot": "A0A044U810",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U810"
  },
  {
    "uniprot": "A0A044TBC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBC2"
  },
  {
    "uniprot": "A0A2K6VVG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVG0"
  },
  {
    "uniprot": "A0A044R585",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R585"
  },
  {
    "uniprot": "A0A044UMX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMX3"
  },
  {
    "uniprot": "A0A044RMJ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMJ0"
  },
  {
    "uniprot": "A0A044UF56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF56"
  },
  {
    "uniprot": "A0A044UNZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNZ4"
  },
  {
    "uniprot": "A0A2K6WK77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WK77"
  },
  {
    "uniprot": "A0A044U2K8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2K8"
  },
  {
    "uniprot": "A0A2K6W8L3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8L3"
  },
  {
    "uniprot": "A0A158N8Q3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8Q3"
  },
  {
    "uniprot": "A0A044SHV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHV8"
  },
  {
    "uniprot": "A0A044RJG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJG7"
  },
  {
    "uniprot": "A0A044TP14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TP14"
  },
  {
    "uniprot": "A0A044S8L1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8L1"
  },
  {
    "uniprot": "A0A044SBV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBV5"
  },
  {
    "uniprot": "A0A044UHI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHI7"
  },
  {
    "uniprot": "A0A044SIM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIM1"
  },
  {
    "uniprot": "A0A044TF86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TF86"
  },
  {
    "uniprot": "A0A044TI27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TI27"
  },
  {
    "uniprot": "A0A044SZC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZC0"
  },
  {
    "uniprot": "A0A044TTG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTG9"
  },
  {
    "uniprot": "A0A044S191",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S191"
  },
  {
    "uniprot": "A0A044QNR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNR3"
  },
  {
    "uniprot": "A0A044V3Z9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3Z9"
  },
  {
    "uniprot": "A0A2K6W8T7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8T7"
  },
  {
    "uniprot": "A0A044V078",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V078"
  },
  {
    "uniprot": "A0A2K6VF12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VF12"
  },
  {
    "uniprot": "A0A044RD38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RD38"
  },
  {
    "uniprot": "A0A044TAT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAT9"
  },
  {
    "uniprot": "A0A044UAV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAV9"
  },
  {
    "uniprot": "A0A044TMU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMU3"
  },
  {
    "uniprot": "A0A044UGB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGB8"
  },
  {
    "uniprot": "A0A044RVC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVC3"
  },
  {
    "uniprot": "A0A044SRY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRY5"
  },
  {
    "uniprot": "A0A044RZ84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZ84"
  },
  {
    "uniprot": "A0A044SRA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRA0"
  },
  {
    "uniprot": "A0A044V171",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V171"
  },
  {
    "uniprot": "A0A044S442",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S442"
  },
  {
    "uniprot": "A0A044VG38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VG38"
  },
  {
    "uniprot": "A0A044UN40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UN40"
  },
  {
    "uniprot": "A0A044S4E4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4E4"
  },
  {
    "uniprot": "A0A044SW47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SW47"
  },
  {
    "uniprot": "A0A044V959",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V959"
  },
  {
    "uniprot": "A0A2K6VSY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSY6"
  },
  {
    "uniprot": "A0A044UFZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFZ2"
  },
  {
    "uniprot": "A0A044THX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THX4"
  },
  {
    "uniprot": "A0A044RES3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RES3"
  },
  {
    "uniprot": "A0A2K6W4W0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W4W0"
  },
  {
    "uniprot": "A0A044SB83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB83"
  },
  {
    "uniprot": "A0A044TMU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMU9"
  },
  {
    "uniprot": "A0A044U270",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U270"
  },
  {
    "uniprot": "A0A044UZ67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ67"
  },
  {
    "uniprot": "A0A044V9F8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9F8"
  },
  {
    "uniprot": "A0A2K6WAG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAG9"
  },
  {
    "uniprot": "A0A044UKY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKY3"
  },
  {
    "uniprot": "A0A2K6WEG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEG9"
  },
  {
    "uniprot": "A0A2K6W7C6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7C6"
  },
  {
    "uniprot": "A0A2K6WDC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDC2"
  },
  {
    "uniprot": "A0A044T7A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7A9"
  },
  {
    "uniprot": "A0A044QR93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QR93"
  },
  {
    "uniprot": "A0A044UKQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKQ5"
  },
  {
    "uniprot": "A0A044T928",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T928"
  },
  {
    "uniprot": "A0A044QVH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVH6"
  },
  {
    "uniprot": "A0A044U5U6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5U6"
  },
  {
    "uniprot": "A0A2K6WN23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WN23"
  },
  {
    "uniprot": "A0A2K6VYL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYL2"
  },
  {
    "uniprot": "P27592",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P27592"
  },
  {
    "uniprot": "A0A158N854",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N854"
  },
  {
    "uniprot": "A0A044UY80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UY80"
  },
  {
    "uniprot": "A0A044TZZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZZ8"
  },
  {
    "uniprot": "A0A044UVP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVP1"
  },
  {
    "uniprot": "A0A044RA83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RA83"
  },
  {
    "uniprot": "A0A044SKU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKU8"
  },
  {
    "uniprot": "A0A044TGK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGK8"
  },
  {
    "uniprot": "A0A2K6WGV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGV2"
  },
  {
    "uniprot": "A0A044RKV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKV0"
  },
  {
    "uniprot": "A0A044TBN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBN9"
  },
  {
    "uniprot": "A0A044RI01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RI01"
  },
  {
    "uniprot": "A0A044SKM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKM5"
  },
  {
    "uniprot": "A0A044UX42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX42"
  },
  {
    "uniprot": "A0A044SHZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHZ0"
  },
  {
    "uniprot": "A0A158N887",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N887"
  },
  {
    "uniprot": "A0A044UKT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKT5"
  },
  {
    "uniprot": "A0A044V402",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V402"
  },
  {
    "uniprot": "A0A044UJK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJK8"
  },
  {
    "uniprot": "A0A044SSV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSV9"
  },
  {
    "uniprot": "A0A2K6WAH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAH2"
  },
  {
    "uniprot": "A0A044T4N9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4N9"
  },
  {
    "uniprot": "A0A044V569",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V569"
  },
  {
    "uniprot": "A0A044RCU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCU1"
  },
  {
    "uniprot": "A0A044QTJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTJ4"
  },
  {
    "uniprot": "A0A044TNU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNU4"
  },
  {
    "uniprot": "A0A2K6VSR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSR3"
  },
  {
    "uniprot": "A0A044V238",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V238"
  },
  {
    "uniprot": "A0A2K6VQG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQG8"
  },
  {
    "uniprot": "A0A044QKT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKT6"
  },
  {
    "uniprot": "A0A044SG19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG19"
  },
  {
    "uniprot": "A0A044R7T5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7T5"
  },
  {
    "uniprot": "A0A044QZ47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ47"
  },
  {
    "uniprot": "A0A044SLK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLK2"
  },
  {
    "uniprot": "A0A044TWL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWL4"
  },
  {
    "uniprot": "A0A044UL77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL77"
  },
  {
    "uniprot": "A0A044SQ22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ22"
  },
  {
    "uniprot": "A0A044T2M1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2M1"
  },
  {
    "uniprot": "A0A044UH53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UH53"
  },
  {
    "uniprot": "A0A044RQK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQK0"
  },
  {
    "uniprot": "A0A044UJ48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJ48"
  },
  {
    "uniprot": "A0A044SVA8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVA8"
  },
  {
    "uniprot": "A0A044TKM9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKM9"
  },
  {
    "uniprot": "A0A044UYX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYX8"
  },
  {
    "uniprot": "A0A044RF58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RF58"
  },
  {
    "uniprot": "A0A044T821",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T821"
  },
  {
    "uniprot": "A0A044UDI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDI1"
  },
  {
    "uniprot": "A0A044S457",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S457"
  },
  {
    "uniprot": "A0A044THA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THA0"
  },
  {
    "uniprot": "A0A2K6VZ00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZ00"
  },
  {
    "uniprot": "A0A044UUM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUM4"
  },
  {
    "uniprot": "A0A2K6VEW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VEW5"
  },
  {
    "uniprot": "A0A044R3U1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3U1"
  },
  {
    "uniprot": "A0A044QZQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZQ6"
  },
  {
    "uniprot": "A0A044URM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URM1"
  },
  {
    "uniprot": "A0A044R0W0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0W0"
  },
  {
    "uniprot": "A0A044UFU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFU4"
  },
  {
    "uniprot": "A0A044S6K8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6K8"
  },
  {
    "uniprot": "A0A044QQ79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQ79"
  },
  {
    "uniprot": "A0A044S3W2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3W2"
  },
  {
    "uniprot": "A0A044SIF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIF1"
  },
  {
    "uniprot": "A0A044TVN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVN6"
  },
  {
    "uniprot": "A0A044R7L4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7L4"
  },
  {
    "uniprot": "A0A044S5M7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5M7"
  },
  {
    "uniprot": "A0A044QKH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKH1"
  },
  {
    "uniprot": "A0A044QPY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPY2"
  },
  {
    "uniprot": "A0A044QUU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUU7"
  },
  {
    "uniprot": "A0A044SQ27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ27"
  },
  {
    "uniprot": "A0A044TDM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDM0"
  },
  {
    "uniprot": "A0A044TAC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAC2"
  },
  {
    "uniprot": "A0A044T240",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T240"
  },
  {
    "uniprot": "A0A044U020",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U020"
  },
  {
    "uniprot": "A0A044VI74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VI74"
  },
  {
    "uniprot": "A0A044QXQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXQ4"
  },
  {
    "uniprot": "A0A044QVI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVI2"
  },
  {
    "uniprot": "A0A044U4R4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4R4"
  },
  {
    "uniprot": "A0A044R3X2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3X2"
  },
  {
    "uniprot": "A0A044T3Y4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3Y4"
  },
  {
    "uniprot": "A0A044SZG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZG8"
  },
  {
    "uniprot": "A0A044TZ03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZ03"
  },
  {
    "uniprot": "A0A044SNM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNM2"
  },
  {
    "uniprot": "A0A2K6WCL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WCL0"
  },
  {
    "uniprot": "A0A044SDT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDT0"
  },
  {
    "uniprot": "A0A044R292",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R292"
  },
  {
    "uniprot": "A0A044TXZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXZ7"
  },
  {
    "uniprot": "A0A044R990",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R990"
  },
  {
    "uniprot": "A0A2K6WCQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WCQ0"
  },
  {
    "uniprot": "A0A044RAF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAF6"
  },
  {
    "uniprot": "A0A044QN55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QN55"
  },
  {
    "uniprot": "A0A044STG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STG1"
  },
  {
    "uniprot": "A0A044U3E8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3E8"
  },
  {
    "uniprot": "A0A044V7W5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7W5"
  },
  {
    "uniprot": "A0A044TBZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBZ6"
  },
  {
    "uniprot": "A0A044REZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REZ6"
  },
  {
    "uniprot": "A0A044QMY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMY3"
  },
  {
    "uniprot": "A0A044T9Y9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9Y9"
  },
  {
    "uniprot": "A0A044RSD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSD8"
  },
  {
    "uniprot": "A0A044T0J2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0J2"
  },
  {
    "uniprot": "A0A044S0P1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0P1"
  },
  {
    "uniprot": "A0A044V2U1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2U1"
  },
  {
    "uniprot": "A0A044V8Y6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Y6"
  },
  {
    "uniprot": "A0A044SET4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SET4"
  },
  {
    "uniprot": "A0A044U2G5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2G5"
  },
  {
    "uniprot": "A0A044T9U3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9U3"
  },
  {
    "uniprot": "A0A044QY47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY47"
  },
  {
    "uniprot": "A0A044VEC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEC0"
  },
  {
    "uniprot": "A0A2K6WJU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJU4"
  },
  {
    "uniprot": "A0A044SDX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDX9"
  },
  {
    "uniprot": "A0A044R5X3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5X3"
  },
  {
    "uniprot": "A0A044V070",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V070"
  },
  {
    "uniprot": "A0A044T473",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T473"
  },
  {
    "uniprot": "A0A044TNB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNB0"
  },
  {
    "uniprot": "A0A044T272",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T272"
  },
  {
    "uniprot": "A0A044TM70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TM70"
  },
  {
    "uniprot": "A0A044QK96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK96"
  },
  {
    "uniprot": "A0A044SA01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SA01"
  },
  {
    "uniprot": "A0A044RED4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RED4"
  },
  {
    "uniprot": "A0A044TP46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TP46"
  },
  {
    "uniprot": "A0A158N882",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N882"
  },
  {
    "uniprot": "A0A044UYF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYF8"
  },
  {
    "uniprot": "A0A044V936",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V936"
  },
  {
    "uniprot": "A0A044SG96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG96"
  },
  {
    "uniprot": "A0A044V230",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V230"
  },
  {
    "uniprot": "A0A044SDV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDV5"
  },
  {
    "uniprot": "A0A044VGN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGN0"
  },
  {
    "uniprot": "A0A044RN94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RN94"
  },
  {
    "uniprot": "A0A044U5V8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5V8"
  },
  {
    "uniprot": "A0A044UM93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UM93"
  },
  {
    "uniprot": "A0A044SXL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXL3"
  },
  {
    "uniprot": "A0A044SL03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SL03"
  },
  {
    "uniprot": "A0A044R0I8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0I8"
  },
  {
    "uniprot": "A0A044QTR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTR4"
  },
  {
    "uniprot": "A0A044UAZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAZ3"
  },
  {
    "uniprot": "A0A044QJQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJQ3"
  },
  {
    "uniprot": "A0A044R948",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R948"
  },
  {
    "uniprot": "A0A2K6WB67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WB67"
  },
  {
    "uniprot": "A0A044UKR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKR1"
  },
  {
    "uniprot": "A0A044TYD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYD3"
  },
  {
    "uniprot": "A0A044T9K1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9K1"
  },
  {
    "uniprot": "A0A044SDK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDK9"
  },
  {
    "uniprot": "A0A044U682",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U682"
  },
  {
    "uniprot": "A0A044S7Y3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7Y3"
  },
  {
    "uniprot": "A0A044RNB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNB7"
  },
  {
    "uniprot": "A0A044UAE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAE4"
  },
  {
    "uniprot": "A0A044S3P5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3P5"
  },
  {
    "uniprot": "A0A044V9R2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9R2"
  },
  {
    "uniprot": "A0A044QSH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSH7"
  },
  {
    "uniprot": "A0A044RQN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQN7"
  },
  {
    "uniprot": "A0A044VF40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VF40"
  },
  {
    "uniprot": "A0A044RZ24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZ24"
  },
  {
    "uniprot": "A0A044QYR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYR1"
  },
  {
    "uniprot": "A0A044R1I4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1I4"
  },
  {
    "uniprot": "A0A044SZ38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ38"
  },
  {
    "uniprot": "A0A044RVS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVS6"
  },
  {
    "uniprot": "A0A044T644",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T644"
  },
  {
    "uniprot": "A0A044QZJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZJ9"
  },
  {
    "uniprot": "A0A044R8A7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8A7"
  },
  {
    "uniprot": "A0A044V9E9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9E9"
  },
  {
    "uniprot": "A0A044RXL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXL2"
  },
  {
    "uniprot": "A0A044RFW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFW9"
  },
  {
    "uniprot": "A0A044U4W3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4W3"
  },
  {
    "uniprot": "A0A044SD04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD04"
  },
  {
    "uniprot": "A0A044TLP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLP0"
  },
  {
    "uniprot": "A0A044TFB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFB0"
  },
  {
    "uniprot": "A0A044U043",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U043"
  },
  {
    "uniprot": "A0A044V4D0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4D0"
  },
  {
    "uniprot": "A0A044V001",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V001"
  },
  {
    "uniprot": "A0A044V5N3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5N3"
  },
  {
    "uniprot": "A0A044VGP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGP3"
  },
  {
    "uniprot": "A0A044VA15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA15"
  },
  {
    "uniprot": "A0A044TES0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TES0"
  },
  {
    "uniprot": "A0A044R6C9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6C9"
  },
  {
    "uniprot": "A0A044SNC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNC2"
  },
  {
    "uniprot": "A0A044RW86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW86"
  },
  {
    "uniprot": "A0A2K6VDA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDA9"
  },
  {
    "uniprot": "A0A044SVS8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVS8"
  },
  {
    "uniprot": "A0A2K6W4F6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W4F6"
  },
  {
    "uniprot": "A0A044SG35",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG35"
  },
  {
    "uniprot": "A0A044TLI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLI6"
  },
  {
    "uniprot": "A0A044UZ63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ63"
  },
  {
    "uniprot": "A0A044QSZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSZ9"
  },
  {
    "uniprot": "A0A044UF91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF91"
  },
  {
    "uniprot": "A0A2K6VHH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHH5"
  },
  {
    "uniprot": "A0A044RNC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNC4"
  },
  {
    "uniprot": "A0A044QN70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QN70"
  },
  {
    "uniprot": "A0A044S4F0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4F0"
  },
  {
    "uniprot": "A0A044QPA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPA5"
  },
  {
    "uniprot": "A0A044SVI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVI6"
  },
  {
    "uniprot": "A0A044RUU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUU6"
  },
  {
    "uniprot": "A0A044SMJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMJ4"
  },
  {
    "uniprot": "A0A044U862",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U862"
  },
  {
    "uniprot": "A0A044RJ42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJ42"
  },
  {
    "uniprot": "A0A044QLL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLL7"
  },
  {
    "uniprot": "A0A044R4T5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4T5"
  },
  {
    "uniprot": "A0A044UF86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF86"
  },
  {
    "uniprot": "A0A044SA83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SA83"
  },
  {
    "uniprot": "A0A044UEH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEH7"
  },
  {
    "uniprot": "A0A044V9W8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9W8"
  },
  {
    "uniprot": "A0A2K6WH74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WH74"
  },
  {
    "uniprot": "A0A044SXZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXZ0"
  },
  {
    "uniprot": "A0A044VDP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDP1"
  },
  {
    "uniprot": "A0A044SNB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNB8"
  },
  {
    "uniprot": "A0A044VI25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VI25"
  },
  {
    "uniprot": "A0A044R9L5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9L5"
  },
  {
    "uniprot": "A0A044SHN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHN7"
  },
  {
    "uniprot": "A0A044TMA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMA9"
  },
  {
    "uniprot": "A0A158N8L4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8L4"
  },
  {
    "uniprot": "A0A044TYB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYB1"
  },
  {
    "uniprot": "A0A044SET8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SET8"
  },
  {
    "uniprot": "A0A044TDZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDZ5"
  },
  {
    "uniprot": "A0A044R2D9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2D9"
  },
  {
    "uniprot": "A0A044U7A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7A9"
  },
  {
    "uniprot": "A0A044V9C1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9C1"
  },
  {
    "uniprot": "A0A044SCK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCK6"
  },
  {
    "uniprot": "A0A044USM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USM2"
  },
  {
    "uniprot": "A0A044S7Q5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7Q5"
  },
  {
    "uniprot": "A0A044T964",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T964"
  },
  {
    "uniprot": "A0A044R6D4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6D4"
  },
  {
    "uniprot": "A0A044T7K4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7K4"
  },
  {
    "uniprot": "A0A044R703",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R703"
  },
  {
    "uniprot": "A0A044T219",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T219"
  },
  {
    "uniprot": "A0A044T4N1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4N1"
  },
  {
    "uniprot": "A0A044T122",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T122"
  },
  {
    "uniprot": "A0A044T781",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T781"
  },
  {
    "uniprot": "A0A044VCT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCT2"
  },
  {
    "uniprot": "A0A044R1J3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1J3"
  },
  {
    "uniprot": "A0A044TY25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TY25"
  },
  {
    "uniprot": "A0A044S549",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S549"
  },
  {
    "uniprot": "A0A044VAB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAB4"
  },
  {
    "uniprot": "A0A044V907",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V907"
  },
  {
    "uniprot": "A0A044QTV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTV2"
  },
  {
    "uniprot": "A0A044V962",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V962"
  },
  {
    "uniprot": "A0A044SAA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAA5"
  },
  {
    "uniprot": "A0A044UQ63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQ63"
  },
  {
    "uniprot": "A0A044QYL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYL4"
  },
  {
    "uniprot": "A0A044VGW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGW6"
  },
  {
    "uniprot": "A0A044T112",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T112"
  },
  {
    "uniprot": "A0A044TD24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TD24"
  },
  {
    "uniprot": "A0A044T2V6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2V6"
  },
  {
    "uniprot": "A0A044U730",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U730"
  },
  {
    "uniprot": "A0A044S2J9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2J9"
  },
  {
    "uniprot": "A0A044UE41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE41"
  },
  {
    "uniprot": "A0A2K6VXY1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXY1"
  },
  {
    "uniprot": "A0A044RZP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZP8"
  },
  {
    "uniprot": "A0A044SFX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFX6"
  },
  {
    "uniprot": "A0A044TIJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIJ4"
  },
  {
    "uniprot": "A0A044U5B7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5B7"
  },
  {
    "uniprot": "A0A044S8R4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8R4"
  },
  {
    "uniprot": "A0A044SVY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVY3"
  },
  {
    "uniprot": "A0A044S5T8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5T8"
  },
  {
    "uniprot": "A0A044TH84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH84"
  },
  {
    "uniprot": "A0A044URY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URY5"
  },
  {
    "uniprot": "A0A044V7D6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7D6"
  },
  {
    "uniprot": "A0A044TCZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCZ7"
  },
  {
    "uniprot": "A0A044VCF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCF0"
  },
  {
    "uniprot": "A0A044TZ29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZ29"
  },
  {
    "uniprot": "A0A044UQK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQK4"
  },
  {
    "uniprot": "A0A044S1B6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1B6"
  },
  {
    "uniprot": "A0A2K6W7U3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7U3"
  },
  {
    "uniprot": "A0A2K6WK32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WK32"
  },
  {
    "uniprot": "A0A044U5D6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5D6"
  },
  {
    "uniprot": "A0A044QKR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKR0"
  },
  {
    "uniprot": "A0A044SFM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFM3"
  },
  {
    "uniprot": "A0A044TTI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTI6"
  },
  {
    "uniprot": "A0A044RSR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSR2"
  },
  {
    "uniprot": "A0A044T6J8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6J8"
  },
  {
    "uniprot": "A0A044TVG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVG0"
  },
  {
    "uniprot": "A0A2K6VV78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VV78"
  },
  {
    "uniprot": "A0A044SBU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBU6"
  },
  {
    "uniprot": "A0A044SWC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWC3"
  },
  {
    "uniprot": "A0A044SEI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEI2"
  },
  {
    "uniprot": "A0A044U1K2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1K2"
  },
  {
    "uniprot": "A0A044T613",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T613"
  },
  {
    "uniprot": "A0A044V4J3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4J3"
  },
  {
    "uniprot": "A0A044T390",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T390"
  },
  {
    "uniprot": "A0A044V9K9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9K9"
  },
  {
    "uniprot": "A0A044SUR9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUR9"
  },
  {
    "uniprot": "A0A044SPZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPZ3"
  },
  {
    "uniprot": "A0A044V0C3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0C3"
  },
  {
    "uniprot": "A0A044RHL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHL3"
  },
  {
    "uniprot": "A0A2K6VD45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VD45"
  },
  {
    "uniprot": "A0A044U4P8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4P8"
  },
  {
    "uniprot": "A0A044UZ61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ61"
  },
  {
    "uniprot": "A0A044UPY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPY6"
  },
  {
    "uniprot": "A0A044SWR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWR7"
  },
  {
    "uniprot": "A0A044UBX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBX4"
  },
  {
    "uniprot": "A0A044RE71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RE71"
  },
  {
    "uniprot": "A0A2K6WB85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WB85"
  },
  {
    "uniprot": "A0A044UX19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX19"
  },
  {
    "uniprot": "A0A044RR86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RR86"
  },
  {
    "uniprot": "A0A2K6VPV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPV0"
  },
  {
    "uniprot": "A0A044RAH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAH4"
  },
  {
    "uniprot": "A0A044SLX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLX2"
  },
  {
    "uniprot": "A0A2K6VLK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLK5"
  },
  {
    "uniprot": "A0A044UJF7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJF7"
  },
  {
    "uniprot": "A0A044QV60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QV60"
  },
  {
    "uniprot": "A0A044V0I8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0I8"
  },
  {
    "uniprot": "A0A044TV28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV28"
  },
  {
    "uniprot": "A0A044UN50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UN50"
  },
  {
    "uniprot": "A0A044SKN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKN6"
  },
  {
    "uniprot": "A0A044TNY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNY9"
  },
  {
    "uniprot": "A0A044UCL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCL2"
  },
  {
    "uniprot": "A0A2K6VL40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VL40"
  },
  {
    "uniprot": "A0A044RDJ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDJ8"
  },
  {
    "uniprot": "A0A044SMB2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMB2"
  },
  {
    "uniprot": "A0A044QML0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QML0"
  },
  {
    "uniprot": "A0A044R2H5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2H5"
  },
  {
    "uniprot": "A0A044TBC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBC9"
  },
  {
    "uniprot": "A0A044U8E9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8E9"
  },
  {
    "uniprot": "A0A044V4B8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4B8"
  },
  {
    "uniprot": "A0A044V2J0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2J0"
  },
  {
    "uniprot": "A0A044TW79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TW79"
  },
  {
    "uniprot": "A0A044SA50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SA50"
  },
  {
    "uniprot": "A0A044SP97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SP97"
  },
  {
    "uniprot": "A0A044V6E3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6E3"
  },
  {
    "uniprot": "A0A044TA36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TA36"
  },
  {
    "uniprot": "A0A044R4V4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4V4"
  },
  {
    "uniprot": "A0A044QK02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK02"
  },
  {
    "uniprot": "A0A044TU60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TU60"
  },
  {
    "uniprot": "A0A044U0Z0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0Z0"
  },
  {
    "uniprot": "A0A044UZ75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ75"
  },
  {
    "uniprot": "A0A044TF64",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TF64"
  },
  {
    "uniprot": "A0A044UE35",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE35"
  },
  {
    "uniprot": "A0A044TRD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRD8"
  },
  {
    "uniprot": "A0A044QN25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QN25"
  },
  {
    "uniprot": "A0A044SNH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNH7"
  },
  {
    "uniprot": "A0A044UN32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UN32"
  },
  {
    "uniprot": "A0A044UK42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UK42"
  },
  {
    "uniprot": "A0A044R420",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R420"
  },
  {
    "uniprot": "A0A044SU30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU30"
  },
  {
    "uniprot": "A0A044SDJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDJ4"
  },
  {
    "uniprot": "A0A044UZ47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ47"
  },
  {
    "uniprot": "A0A044QVM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVM2"
  },
  {
    "uniprot": "A0A044SX92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SX92"
  },
  {
    "uniprot": "A0A044VE23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VE23"
  },
  {
    "uniprot": "A0A044V940",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V940"
  },
  {
    "uniprot": "A0A044UE18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE18"
  },
  {
    "uniprot": "A0A044SIH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIH4"
  },
  {
    "uniprot": "A0A044SHX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHX7"
  },
  {
    "uniprot": "A0A044S3B3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3B3"
  },
  {
    "uniprot": "A0A044V7G7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7G7"
  },
  {
    "uniprot": "A0A044RUY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUY8"
  },
  {
    "uniprot": "A0A044R2R1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2R1"
  },
  {
    "uniprot": "A0A044SA55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SA55"
  },
  {
    "uniprot": "A0A044T575",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T575"
  },
  {
    "uniprot": "A0A044VCS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCS6"
  },
  {
    "uniprot": "A0A044S9F6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9F6"
  },
  {
    "uniprot": "A0A044TJD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJD8"
  },
  {
    "uniprot": "A0A044THP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THP6"
  },
  {
    "uniprot": "P31732",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P31732"
  },
  {
    "uniprot": "A0A044SXM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXM6"
  },
  {
    "uniprot": "A0A044UWT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWT9"
  },
  {
    "uniprot": "A0A044RKE8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKE8"
  },
  {
    "uniprot": "A0A044RC67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC67"
  },
  {
    "uniprot": "A0A044RRN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRN8"
  },
  {
    "uniprot": "A0A044V9G9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9G9"
  },
  {
    "uniprot": "A0A044S8D3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8D3"
  },
  {
    "uniprot": "A0A044RLW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLW0"
  },
  {
    "uniprot": "A0A2K6W9P4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9P4"
  },
  {
    "uniprot": "A0A044QUI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUI0"
  },
  {
    "uniprot": "A0A044U3H2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3H2"
  },
  {
    "uniprot": "A0A044VF08",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VF08"
  },
  {
    "uniprot": "A0A044U7G7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7G7"
  },
  {
    "uniprot": "A0A044T4Q1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4Q1"
  },
  {
    "uniprot": "A0A2K6VRJ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRJ0"
  },
  {
    "uniprot": "A0A044S5V2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5V2"
  },
  {
    "uniprot": "A0A044T4M1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4M1"
  },
  {
    "uniprot": "A0A044SDR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDR1"
  },
  {
    "uniprot": "A0A044SI99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI99"
  },
  {
    "uniprot": "A0A044UXS8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXS8"
  },
  {
    "uniprot": "A0A044RQX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQX9"
  },
  {
    "uniprot": "A0A044U464",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U464"
  },
  {
    "uniprot": "A0A044RAB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAB4"
  },
  {
    "uniprot": "A0A044RIT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIT5"
  },
  {
    "uniprot": "A0A044UL06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL06"
  },
  {
    "uniprot": "A0A044V938",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V938"
  },
  {
    "uniprot": "A0A044V4H1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4H1"
  },
  {
    "uniprot": "A0A2K6VMD0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMD0"
  },
  {
    "uniprot": "A0A044U7W4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7W4"
  },
  {
    "uniprot": "A0A044QTQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTQ0"
  },
  {
    "uniprot": "A0A044U3H7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3H7"
  },
  {
    "uniprot": "A0A044UZ66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ66"
  },
  {
    "uniprot": "A0A044UBA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBA7"
  },
  {
    "uniprot": "A0A044TXP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXP3"
  },
  {
    "uniprot": "A0A044QX12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QX12"
  },
  {
    "uniprot": "A0A044TXK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXK0"
  },
  {
    "uniprot": "A0A044V943",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V943"
  },
  {
    "uniprot": "A0A044RIE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIE0"
  },
  {
    "uniprot": "A0A2K6VT43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VT43"
  },
  {
    "uniprot": "A0A044S7D3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7D3"
  },
  {
    "uniprot": "A0A2K6VF97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VF97"
  },
  {
    "uniprot": "A0A2K6W2B3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2B3"
  },
  {
    "uniprot": "A0A044S827",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S827"
  },
  {
    "uniprot": "A0A044SD45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD45"
  },
  {
    "uniprot": "A0A044TMZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMZ2"
  },
  {
    "uniprot": "A0A044SL50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SL50"
  },
  {
    "uniprot": "A0A044UXC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXC3"
  },
  {
    "uniprot": "A0A044VBS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBS6"
  },
  {
    "uniprot": "A0A044QNM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNM6"
  },
  {
    "uniprot": "A0A044RD95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RD95"
  },
  {
    "uniprot": "A0A044RFG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFG9"
  },
  {
    "uniprot": "A0A044SGC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGC5"
  },
  {
    "uniprot": "A0A2K6VE16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VE16"
  },
  {
    "uniprot": "A0A044U4C8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4C8"
  },
  {
    "uniprot": "A0A044SEU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEU3"
  },
  {
    "uniprot": "A0A044TN68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN68"
  },
  {
    "uniprot": "A0A044RDL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDL5"
  },
  {
    "uniprot": "A0A044S555",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S555"
  },
  {
    "uniprot": "A0A044RAT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAT5"
  },
  {
    "uniprot": "A0A2K6WGU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGU3"
  },
  {
    "uniprot": "A0A044SJ27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJ27"
  },
  {
    "uniprot": "A0A044R0X0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0X0"
  },
  {
    "uniprot": "A0A044RZH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZH0"
  },
  {
    "uniprot": "A0A044U5F5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5F5"
  },
  {
    "uniprot": "A0A044RDA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDA7"
  },
  {
    "uniprot": "A0A2K6VR39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VR39"
  },
  {
    "uniprot": "A0A044V4H4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4H4"
  },
  {
    "uniprot": "A0A044RBN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBN6"
  },
  {
    "uniprot": "A0A044QLY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLY7"
  },
  {
    "uniprot": "A0A044STF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STF4"
  },
  {
    "uniprot": "A0A044RTW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTW8"
  },
  {
    "uniprot": "A0A044RKZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKZ3"
  },
  {
    "uniprot": "A0A2K6WL54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WL54"
  },
  {
    "uniprot": "A0A044TW40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TW40"
  },
  {
    "uniprot": "A0A044SX54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SX54"
  },
  {
    "uniprot": "A0A044SVR5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVR5"
  },
  {
    "uniprot": "A0A044T2W2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2W2"
  },
  {
    "uniprot": "A0A158N7S1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7S1"
  },
  {
    "uniprot": "A0A044RP57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RP57"
  },
  {
    "uniprot": "A0A044UW39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UW39"
  },
  {
    "uniprot": "A0A044SLQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLQ0"
  },
  {
    "uniprot": "A0A044SXX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXX3"
  },
  {
    "uniprot": "A0A158N834",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N834"
  },
  {
    "uniprot": "A0A044R5L7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5L7"
  },
  {
    "uniprot": "A0A044TNM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNM6"
  },
  {
    "uniprot": "A0A044V0T3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0T3"
  },
  {
    "uniprot": "A0A044S7S1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7S1"
  },
  {
    "uniprot": "A0A044SVK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVK4"
  },
  {
    "uniprot": "A0A044SL10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SL10"
  },
  {
    "uniprot": "A0A044RGD2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGD2"
  },
  {
    "uniprot": "A0A044RTX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTX6"
  },
  {
    "uniprot": "A0A044UKT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKT2"
  },
  {
    "uniprot": "A0A044RY89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY89"
  },
  {
    "uniprot": "A0A044V930",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V930"
  },
  {
    "uniprot": "A0A2K6W3P3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3P3"
  },
  {
    "uniprot": "A0A044QL62",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL62"
  },
  {
    "uniprot": "A0A044SH61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SH61"
  },
  {
    "uniprot": "A0A044RVJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVJ6"
  },
  {
    "uniprot": "A0A044QU88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QU88"
  },
  {
    "uniprot": "A0A044SG80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG80"
  },
  {
    "uniprot": "A0A044TX96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TX96"
  },
  {
    "uniprot": "A0A044TTC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTC0"
  },
  {
    "uniprot": "A0A044RXY1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXY1"
  },
  {
    "uniprot": "A0A044UHJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHJ3"
  },
  {
    "uniprot": "A0A044QL01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL01"
  },
  {
    "uniprot": "A0A2K6VY22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VY22"
  },
  {
    "uniprot": "A0A044R9T5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9T5"
  },
  {
    "uniprot": "A0A044TDW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDW8"
  },
  {
    "uniprot": "A0A044RCE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCE4"
  },
  {
    "uniprot": "A0A044THJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THJ7"
  },
  {
    "uniprot": "A0A044UTK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTK8"
  },
  {
    "uniprot": "A0A044V467",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V467"
  },
  {
    "uniprot": "A0A044T2N7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2N7"
  },
  {
    "uniprot": "A0A044QZB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZB8"
  },
  {
    "uniprot": "A0A044QUX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUX4"
  },
  {
    "uniprot": "A0A044TSW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSW5"
  },
  {
    "uniprot": "A0A044QY38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY38"
  },
  {
    "uniprot": "A0A044RCC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCC3"
  },
  {
    "uniprot": "A0A044UYF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYF4"
  },
  {
    "uniprot": "A0A044SNK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNK2"
  },
  {
    "uniprot": "A0A044U817",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U817"
  },
  {
    "uniprot": "A0A044TWE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWE9"
  },
  {
    "uniprot": "A0A044T9I5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9I5"
  },
  {
    "uniprot": "A0A044RJ74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJ74"
  },
  {
    "uniprot": "A0A044SBW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBW8"
  },
  {
    "uniprot": "A0A044V9A1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9A1"
  },
  {
    "uniprot": "A0A044TE75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TE75"
  },
  {
    "uniprot": "A0A044UPU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPU3"
  },
  {
    "uniprot": "A0A044QJX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJX6"
  },
  {
    "uniprot": "A0A044TII8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TII8"
  },
  {
    "uniprot": "A0A044QZZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZZ2"
  },
  {
    "uniprot": "A0A044RSM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSM0"
  },
  {
    "uniprot": "A0A044QXL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXL5"
  },
  {
    "uniprot": "A0A044R9J2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9J2"
  },
  {
    "uniprot": "A0A2K6VMI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMI2"
  },
  {
    "uniprot": "A0A044STG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STG5"
  },
  {
    "uniprot": "A0A044UZ38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ38"
  },
  {
    "uniprot": "A0A044ULV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULV7"
  },
  {
    "uniprot": "A0A044RXC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXC4"
  },
  {
    "uniprot": "A0A044R871",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R871"
  },
  {
    "uniprot": "A0A044VAK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAK0"
  },
  {
    "uniprot": "A0A044V3M8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3M8"
  },
  {
    "uniprot": "A0A044UN97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UN97"
  },
  {
    "uniprot": "A0A044S9H0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9H0"
  },
  {
    "uniprot": "A0A044QRC7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRC7"
  },
  {
    "uniprot": "A0A044SPA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPA6"
  },
  {
    "uniprot": "A0A044TCA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCA6"
  },
  {
    "uniprot": "A0A044RNY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNY8"
  },
  {
    "uniprot": "A0A044QTU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTU5"
  },
  {
    "uniprot": "A0A044RJ67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJ67"
  },
  {
    "uniprot": "A0A044SS76",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS76"
  },
  {
    "uniprot": "A0A044V1K7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1K7"
  },
  {
    "uniprot": "A0A044SJU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJU9"
  },
  {
    "uniprot": "A0A044V4H6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4H6"
  },
  {
    "uniprot": "A0A044T0F5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0F5"
  },
  {
    "uniprot": "A0A2K6WH12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WH12"
  },
  {
    "uniprot": "A0A044UPE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPE9"
  },
  {
    "uniprot": "A0A044RCU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCU9"
  },
  {
    "uniprot": "A0A044SDD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDD1"
  },
  {
    "uniprot": "A0A044RFQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFQ6"
  },
  {
    "uniprot": "A0A044TQN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQN4"
  },
  {
    "uniprot": "A0A044UP86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UP86"
  },
  {
    "uniprot": "A0A158N7Z9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Z9"
  },
  {
    "uniprot": "A0A044RGM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGM2"
  },
  {
    "uniprot": "A0A044V866",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V866"
  },
  {
    "uniprot": "A0A044S602",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S602"
  },
  {
    "uniprot": "A0A2K6VMG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMG0"
  },
  {
    "uniprot": "A0A044U1E3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1E3"
  },
  {
    "uniprot": "A0A044V404",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V404"
  },
  {
    "uniprot": "A0A044V150",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V150"
  },
  {
    "uniprot": "A0A2K6WHJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WHJ1"
  },
  {
    "uniprot": "A0A044SUY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUY4"
  },
  {
    "uniprot": "A0A044UCS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCS0"
  },
  {
    "uniprot": "A0A2K6W0A7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0A7"
  },
  {
    "uniprot": "A0A044SAU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAU9"
  },
  {
    "uniprot": "A0A044TJ76",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJ76"
  },
  {
    "uniprot": "A0A2K6W6L6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6L6"
  },
  {
    "uniprot": "A0A044T8G8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8G8"
  },
  {
    "uniprot": "A0A044RWH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWH8"
  },
  {
    "uniprot": "A0A044SLL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLL5"
  },
  {
    "uniprot": "A0A044UUN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUN3"
  },
  {
    "uniprot": "A0A044U425",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U425"
  },
  {
    "uniprot": "A0A044V8B1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8B1"
  },
  {
    "uniprot": "A0A044V2K2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2K2"
  },
  {
    "uniprot": "A0A044V9M4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9M4"
  },
  {
    "uniprot": "A0A044SZD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZD8"
  },
  {
    "uniprot": "A0A044RKT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKT2"
  },
  {
    "uniprot": "A0A044T4N8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4N8"
  },
  {
    "uniprot": "A0A044RLR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLR6"
  },
  {
    "uniprot": "A0A044RR67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RR67"
  },
  {
    "uniprot": "A0A044S583",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S583"
  },
  {
    "uniprot": "A0A044V3H7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3H7"
  },
  {
    "uniprot": "A0A044VHC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHC4"
  },
  {
    "uniprot": "A0A044TRJ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRJ5"
  },
  {
    "uniprot": "A0A044S5Y0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5Y0"
  },
  {
    "uniprot": "A0A044TSN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSN4"
  },
  {
    "uniprot": "A0A044U881",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U881"
  },
  {
    "uniprot": "A0A044TYM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYM7"
  },
  {
    "uniprot": "A0A044SXC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXC9"
  },
  {
    "uniprot": "A0A044UKU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKU6"
  },
  {
    "uniprot": "A0A044QPT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPT4"
  },
  {
    "uniprot": "A0A044V9R7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9R7"
  },
  {
    "uniprot": "A0A044V496",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V496"
  },
  {
    "uniprot": "A0A044V168",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V168"
  },
  {
    "uniprot": "A0A044U8L5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8L5"
  },
  {
    "uniprot": "A0A044UVG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVG5"
  },
  {
    "uniprot": "A0A044SKD9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKD9"
  },
  {
    "uniprot": "A0A044SR48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SR48"
  },
  {
    "uniprot": "A0A044SBB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBB9"
  },
  {
    "uniprot": "Q25632",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q25632"
  },
  {
    "uniprot": "A0A044SJC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJC4"
  },
  {
    "uniprot": "A0A044U786",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U786"
  },
  {
    "uniprot": "A0A044UA54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA54"
  },
  {
    "uniprot": "A0A044QW95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW95"
  },
  {
    "uniprot": "A0A044RD27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RD27"
  },
  {
    "uniprot": "A0A044UYU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYU7"
  },
  {
    "uniprot": "A0A044SAT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAT0"
  },
  {
    "uniprot": "A0A044T2H1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2H1"
  },
  {
    "uniprot": "A0A044USX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USX4"
  },
  {
    "uniprot": "A0A044RH28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH28"
  },
  {
    "uniprot": "A0A044UTM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTM8"
  },
  {
    "uniprot": "A0A2K6VJ30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJ30"
  },
  {
    "uniprot": "A0A044TTC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTC9"
  },
  {
    "uniprot": "A0A044V9U8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9U8"
  },
  {
    "uniprot": "A0A044TJ80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJ80"
  },
  {
    "uniprot": "A0A044R4N8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4N8"
  },
  {
    "uniprot": "A0A044TPU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPU1"
  },
  {
    "uniprot": "A0A044QZR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZR0"
  },
  {
    "uniprot": "A0A044TD37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TD37"
  },
  {
    "uniprot": "A0A044RNW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNW4"
  },
  {
    "uniprot": "A0A044TR48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR48"
  },
  {
    "uniprot": "A0A2K6VVP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVP8"
  },
  {
    "uniprot": "A0A044U8S8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8S8"
  },
  {
    "uniprot": "A0A044RFY1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFY1"
  },
  {
    "uniprot": "A0A2K6W508",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W508"
  },
  {
    "uniprot": "A0A2K6W491",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W491"
  },
  {
    "uniprot": "A0A044TM82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TM82"
  },
  {
    "uniprot": "A0A044V9G1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9G1"
  },
  {
    "uniprot": "A0A044UVE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVE5"
  },
  {
    "uniprot": "A0A2K6W307",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W307"
  },
  {
    "uniprot": "A0A2K6VMH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMH9"
  },
  {
    "uniprot": "A0A044VIL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIL0"
  },
  {
    "uniprot": "A0A044R694",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R694"
  },
  {
    "uniprot": "A0A044UYC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYC8"
  },
  {
    "uniprot": "A0A044TA42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TA42"
  },
  {
    "uniprot": "A0A044V4J1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4J1"
  },
  {
    "uniprot": "A0A044VIN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIN3"
  },
  {
    "uniprot": "A0A044URN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URN4"
  },
  {
    "uniprot": "A0A044SES5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SES5"
  },
  {
    "uniprot": "A0A044U4N5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4N5"
  },
  {
    "uniprot": "A0A044SGA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGA9"
  },
  {
    "uniprot": "A0A044TS06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS06"
  },
  {
    "uniprot": "A0A044V3T1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3T1"
  },
  {
    "uniprot": "A0A044UTM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTM4"
  },
  {
    "uniprot": "A0A2K6W2W3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2W3"
  },
  {
    "uniprot": "A0A044U9F8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9F8"
  },
  {
    "uniprot": "A0A044TEL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEL5"
  },
  {
    "uniprot": "A0A044UZ26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ26"
  },
  {
    "uniprot": "A0A044VHF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHF4"
  },
  {
    "uniprot": "A0A044QP54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP54"
  },
  {
    "uniprot": "A0A044VHX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHX7"
  },
  {
    "uniprot": "A0A044TUL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUL8"
  },
  {
    "uniprot": "A0A044UDB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDB9"
  },
  {
    "uniprot": "A0A044UYY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYY0"
  },
  {
    "uniprot": "A0A044UV10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UV10"
  },
  {
    "uniprot": "A0A044RST2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RST2"
  },
  {
    "uniprot": "A0A044V370",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V370"
  },
  {
    "uniprot": "A0A044S1K4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1K4"
  },
  {
    "uniprot": "A0A044T3S7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3S7"
  },
  {
    "uniprot": "A0A044UV15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UV15"
  },
  {
    "uniprot": "A0A044R4J3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4J3"
  },
  {
    "uniprot": "A0A044R918",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R918"
  },
  {
    "uniprot": "A0A2K6W300",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W300"
  },
  {
    "uniprot": "A0A044U760",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U760"
  },
  {
    "uniprot": "A0A044VI31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VI31"
  },
  {
    "uniprot": "A0A044S356",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S356"
  },
  {
    "uniprot": "A0A044V9S2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9S2"
  },
  {
    "uniprot": "A0A2K6VHY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHY0"
  },
  {
    "uniprot": "A0A044RPD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPD7"
  },
  {
    "uniprot": "A0A044TK37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK37"
  },
  {
    "uniprot": "A0A044R4J1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4J1"
  },
  {
    "uniprot": "A0A044S732",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S732"
  },
  {
    "uniprot": "A0A2K6VIQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIQ3"
  },
  {
    "uniprot": "A0A044UYL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYL7"
  },
  {
    "uniprot": "A0A044U5Z1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5Z1"
  },
  {
    "uniprot": "A0A044U412",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U412"
  },
  {
    "uniprot": "A0A044SJ78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJ78"
  },
  {
    "uniprot": "A0A2K6WCG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WCG8"
  },
  {
    "uniprot": "A0A044TLE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLE9"
  },
  {
    "uniprot": "A0A044QYN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYN8"
  },
  {
    "uniprot": "A0A044UHL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHL0"
  },
  {
    "uniprot": "A0A044V0K9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0K9"
  },
  {
    "uniprot": "A0A044SWY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWY8"
  },
  {
    "uniprot": "A0A044R534",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R534"
  },
  {
    "uniprot": "A0A044TBZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBZ1"
  },
  {
    "uniprot": "A0A044U7X1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7X1"
  },
  {
    "uniprot": "A0A044VJE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJE3"
  },
  {
    "uniprot": "A0A044SSM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSM2"
  },
  {
    "uniprot": "A0A044RIJ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIJ8"
  },
  {
    "uniprot": "A0A044T8F8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8F8"
  },
  {
    "uniprot": "A0A044UCK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCK1"
  },
  {
    "uniprot": "A0A044RWT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWT4"
  },
  {
    "uniprot": "A0A044SB57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB57"
  },
  {
    "uniprot": "A0A044RWK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWK1"
  },
  {
    "uniprot": "A0A044TZH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZH6"
  },
  {
    "uniprot": "A0A044UYI5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYI5"
  },
  {
    "uniprot": "A0A044SK37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK37"
  },
  {
    "uniprot": "A0A2K6WFG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFG5"
  },
  {
    "uniprot": "A0A044S721",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S721"
  },
  {
    "uniprot": "A0A044RFD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFD8"
  },
  {
    "uniprot": "A0A044UQR5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQR5"
  },
  {
    "uniprot": "A0A158N861",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N861"
  },
  {
    "uniprot": "A0A044V791",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V791"
  },
  {
    "uniprot": "A0A044SU79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU79"
  },
  {
    "uniprot": "A0A044T6D4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6D4"
  },
  {
    "uniprot": "A0A044UEE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEE6"
  },
  {
    "uniprot": "A0A044V9N5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9N5"
  },
  {
    "uniprot": "A0A044V483",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V483"
  },
  {
    "uniprot": "A0A044T1U0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1U0"
  },
  {
    "uniprot": "A0A044RKQ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKQ8"
  },
  {
    "uniprot": "A0A044V5S9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5S9"
  },
  {
    "uniprot": "A0A044RI77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RI77"
  },
  {
    "uniprot": "A0A044TZS1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZS1"
  },
  {
    "uniprot": "A0A044V5I0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5I0"
  },
  {
    "uniprot": "A0A044T3H3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3H3"
  },
  {
    "uniprot": "A0A2K6WIE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIE6"
  },
  {
    "uniprot": "A0A044V857",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V857"
  },
  {
    "uniprot": "A0A044SAW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAW3"
  },
  {
    "uniprot": "A0A044U134",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U134"
  },
  {
    "uniprot": "A0A2K6VW13",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VW13"
  },
  {
    "uniprot": "A0A044SQ73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ73"
  },
  {
    "uniprot": "A0A044UKQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKQ4"
  },
  {
    "uniprot": "A0A044VIM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIM5"
  },
  {
    "uniprot": "A0A044U6P4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6P4"
  },
  {
    "uniprot": "A0A044TWV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWV8"
  },
  {
    "uniprot": "A0A2K6W4S3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W4S3"
  },
  {
    "uniprot": "A0A2K6WG30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WG30"
  },
  {
    "uniprot": "A0A044U6I6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6I6"
  },
  {
    "uniprot": "A0A044VFP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFP6"
  },
  {
    "uniprot": "A0A044R983",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R983"
  },
  {
    "uniprot": "A0A044T0Z2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0Z2"
  },
  {
    "uniprot": "A0A044STF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STF6"
  },
  {
    "uniprot": "A0A044V474",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V474"
  },
  {
    "uniprot": "A0A044SZ66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ66"
  },
  {
    "uniprot": "A0A044V3X2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3X2"
  },
  {
    "uniprot": "A0A044S3A5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3A5"
  },
  {
    "uniprot": "A0A044R930",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R930"
  },
  {
    "uniprot": "A0A044V362",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V362"
  },
  {
    "uniprot": "A0A044RRV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRV8"
  },
  {
    "uniprot": "A0A044VBZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBZ0"
  },
  {
    "uniprot": "A0A044QMA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMA4"
  },
  {
    "uniprot": "A0A044SV26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SV26"
  },
  {
    "uniprot": "A0A158N7T4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7T4"
  },
  {
    "uniprot": "A0A044TB24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TB24"
  },
  {
    "uniprot": "A0A158N7Z8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Z8"
  },
  {
    "uniprot": "A0A044RXY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXY3"
  },
  {
    "uniprot": "A0A044THE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THE1"
  },
  {
    "uniprot": "A0A044TD32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TD32"
  },
  {
    "uniprot": "A0A044TUN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUN8"
  },
  {
    "uniprot": "A0A044UUK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUK3"
  },
  {
    "uniprot": "A0A158N7X4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7X4"
  },
  {
    "uniprot": "A0A044V9H5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9H5"
  },
  {
    "uniprot": "A0A044VB06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VB06"
  },
  {
    "uniprot": "A0A044SNG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNG8"
  },
  {
    "uniprot": "A0A044TR88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR88"
  },
  {
    "uniprot": "A0A044TI99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TI99"
  },
  {
    "uniprot": "A0A044TR99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR99"
  },
  {
    "uniprot": "A0A044S7X9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7X9"
  },
  {
    "uniprot": "A0A044S941",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S941"
  },
  {
    "uniprot": "A0A044UGA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGA9"
  },
  {
    "uniprot": "A0A044T1I6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1I6"
  },
  {
    "uniprot": "A0A044R7V8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7V8"
  },
  {
    "uniprot": "A0A044TAL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAL2"
  },
  {
    "uniprot": "A0A044UI76",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI76"
  },
  {
    "uniprot": "A0A044SB02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB02"
  },
  {
    "uniprot": "A0A044SS44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS44"
  },
  {
    "uniprot": "A0A044U830",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U830"
  },
  {
    "uniprot": "A0A044RZU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZU6"
  },
  {
    "uniprot": "A0A044V8T4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8T4"
  },
  {
    "uniprot": "A0A044TV65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV65"
  },
  {
    "uniprot": "A0A044V9D8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9D8"
  },
  {
    "uniprot": "A0A044UIC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIC6"
  },
  {
    "uniprot": "A0A044SV14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SV14"
  },
  {
    "uniprot": "A0A044SI15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI15"
  },
  {
    "uniprot": "A0A044R7F8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7F8"
  },
  {
    "uniprot": "A0A044R2M7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2M7"
  },
  {
    "uniprot": "A0A044UZE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZE7"
  },
  {
    "uniprot": "A0A044S2C0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2C0"
  },
  {
    "uniprot": "A0A044SFT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFT4"
  },
  {
    "uniprot": "A0A044R0L0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0L0"
  },
  {
    "uniprot": "A0A044SI02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI02"
  },
  {
    "uniprot": "A0A044S1G1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1G1"
  },
  {
    "uniprot": "A0A044VE97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VE97"
  },
  {
    "uniprot": "A0A044UVJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVJ7"
  },
  {
    "uniprot": "A0A044QWE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWE6"
  },
  {
    "uniprot": "A0A044UZ03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ03"
  },
  {
    "uniprot": "A0A044RV61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RV61"
  },
  {
    "uniprot": "A0A044UII7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UII7"
  },
  {
    "uniprot": "A0A044UTD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTD6"
  },
  {
    "uniprot": "A0A044TAQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAQ0"
  },
  {
    "uniprot": "A0A044VHS1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHS1"
  },
  {
    "uniprot": "A0A044V6Z2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Z2"
  },
  {
    "uniprot": "A0A044SP17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SP17"
  },
  {
    "uniprot": "A0A044SSD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSD1"
  },
  {
    "uniprot": "A0A044T179",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T179"
  },
  {
    "uniprot": "A0A044URL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URL7"
  },
  {
    "uniprot": "A0A044SN53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN53"
  },
  {
    "uniprot": "A0A044SEQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEQ6"
  },
  {
    "uniprot": "A0A044TEU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEU1"
  },
  {
    "uniprot": "A0A044V8E5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8E5"
  },
  {
    "uniprot": "A0A044SP83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SP83"
  },
  {
    "uniprot": "A0A044TFW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFW6"
  },
  {
    "uniprot": "A0A044QQZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQZ0"
  },
  {
    "uniprot": "A0A044R545",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R545"
  },
  {
    "uniprot": "A0A044V504",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V504"
  },
  {
    "uniprot": "A0A044TM97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TM97"
  },
  {
    "uniprot": "A0A044QPQ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPQ8"
  },
  {
    "uniprot": "A0A044R4X9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4X9"
  },
  {
    "uniprot": "A0A044UVI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVI8"
  },
  {
    "uniprot": "A0A044TMA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMA3"
  },
  {
    "uniprot": "A0A044TXQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXQ5"
  },
  {
    "uniprot": "A0A044V4W3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4W3"
  },
  {
    "uniprot": "A0A044SD73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD73"
  },
  {
    "uniprot": "A0A044RGZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGZ3"
  },
  {
    "uniprot": "A0A044V6G1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6G1"
  },
  {
    "uniprot": "A0A044TDK7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDK7"
  },
  {
    "uniprot": "A0A044UNR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNR6"
  },
  {
    "uniprot": "A0A044URY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URY0"
  },
  {
    "uniprot": "A0A044V249",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V249"
  },
  {
    "uniprot": "A0A044T857",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T857"
  },
  {
    "uniprot": "A0A044SG49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG49"
  },
  {
    "uniprot": "A0A044UF17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF17"
  },
  {
    "uniprot": "A0A044T958",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T958"
  },
  {
    "uniprot": "A0A044UYD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYD8"
  },
  {
    "uniprot": "Q963F6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q963F6"
  },
  {
    "uniprot": "A0A044T0W5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0W5"
  },
  {
    "uniprot": "A0A044TIP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIP7"
  },
  {
    "uniprot": "A0A044V9Q5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9Q5"
  },
  {
    "uniprot": "A0A044TCG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCG2"
  },
  {
    "uniprot": "A0A044UNK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNK5"
  },
  {
    "uniprot": "A0A044RU30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU30"
  },
  {
    "uniprot": "A0A044V1J9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1J9"
  },
  {
    "uniprot": "A0A044UM47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UM47"
  },
  {
    "uniprot": "A0A044S302",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S302"
  },
  {
    "uniprot": "A0A044V9D4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9D4"
  },
  {
    "uniprot": "A0A044RN43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RN43"
  },
  {
    "uniprot": "A0A044SK76",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK76"
  },
  {
    "uniprot": "A0A044SQ77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ77"
  },
  {
    "uniprot": "A0A044RFV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFV0"
  },
  {
    "uniprot": "A0A044SZJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZJ4"
  },
  {
    "uniprot": "A0A044RP29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RP29"
  },
  {
    "uniprot": "A0A044TIQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIQ3"
  },
  {
    "uniprot": "A0A2K6WGZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGZ5"
  },
  {
    "uniprot": "A0A044S4X8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4X8"
  },
  {
    "uniprot": "A0A044ST32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ST32"
  },
  {
    "uniprot": "P29778",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P29778"
  },
  {
    "uniprot": "A0A044VF25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VF25"
  },
  {
    "uniprot": "A0A044V6H6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6H6"
  },
  {
    "uniprot": "A0A044TDT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDT2"
  },
  {
    "uniprot": "A0A044UPZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPZ0"
  },
  {
    "uniprot": "A0A044S8B9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8B9"
  },
  {
    "uniprot": "A0A044QW72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW72"
  },
  {
    "uniprot": "A0A2K6WG44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WG44"
  },
  {
    "uniprot": "A0A044RCC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCC8"
  },
  {
    "uniprot": "A0A044QS85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QS85"
  },
  {
    "uniprot": "A0A044UZ53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ53"
  },
  {
    "uniprot": "A0A044QX58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QX58"
  },
  {
    "uniprot": "A0A044V9R0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9R0"
  },
  {
    "uniprot": "A0A044V7F5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7F5"
  },
  {
    "uniprot": "A0A2K6VE78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VE78"
  },
  {
    "uniprot": "A0A044SYI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYI1"
  },
  {
    "uniprot": "A0A044VI14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VI14"
  },
  {
    "uniprot": "A0A044UG76",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UG76"
  },
  {
    "uniprot": "A0A044RSK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSK1"
  },
  {
    "uniprot": "A0A044VIC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIC8"
  },
  {
    "uniprot": "A0A044T530",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T530"
  },
  {
    "uniprot": "A0A044R4K2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4K2"
  },
  {
    "uniprot": "A0A2K6W0R2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0R2"
  },
  {
    "uniprot": "A0A044SX74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SX74"
  },
  {
    "uniprot": "A0A2K6VVP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVP9"
  },
  {
    "uniprot": "A0A158N823",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N823"
  },
  {
    "uniprot": "A0A044UWY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWY4"
  },
  {
    "uniprot": "A0A044VC26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC26"
  },
  {
    "uniprot": "A0A2K6WMU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMU0"
  },
  {
    "uniprot": "A0A2K6VIJ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIJ5"
  },
  {
    "uniprot": "A0A044V4I7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4I7"
  },
  {
    "uniprot": "A0A044UFV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFV0"
  },
  {
    "uniprot": "A0A044R4C5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4C5"
  },
  {
    "uniprot": "A0A044V2Y0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2Y0"
  },
  {
    "uniprot": "A0A044RDI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDI6"
  },
  {
    "uniprot": "A0A044RF26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RF26"
  },
  {
    "uniprot": "A0A044UI74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI74"
  },
  {
    "uniprot": "A0A044TZL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZL7"
  },
  {
    "uniprot": "A0A044VHV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHV5"
  },
  {
    "uniprot": "A0A044SB48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB48"
  },
  {
    "uniprot": "A0A044TNE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNE4"
  },
  {
    "uniprot": "A0A044UJP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJP5"
  },
  {
    "uniprot": "A0A044QPV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPV6"
  },
  {
    "uniprot": "A0A044TBB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBB1"
  },
  {
    "uniprot": "A0A044QZ31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ31"
  },
  {
    "uniprot": "A0A044SVF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVF0"
  },
  {
    "uniprot": "A0A044TCT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCT1"
  },
  {
    "uniprot": "A0A044TAV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAV1"
  },
  {
    "uniprot": "A0A044QW82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW82"
  },
  {
    "uniprot": "A0A2K6VIF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIF0"
  },
  {
    "uniprot": "A0A044UX46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX46"
  },
  {
    "uniprot": "A0A044T053",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T053"
  },
  {
    "uniprot": "A0A2K6W4V6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W4V6"
  },
  {
    "uniprot": "A0A044R0A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0A9"
  },
  {
    "uniprot": "A0A044RNC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNC6"
  },
  {
    "uniprot": "A0A044R2A1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2A1"
  },
  {
    "uniprot": "A0A044QRT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRT7"
  },
  {
    "uniprot": "A0A044V6F5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6F5"
  },
  {
    "uniprot": "A0A044V9C6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9C6"
  },
  {
    "uniprot": "A0A044ST46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ST46"
  },
  {
    "uniprot": "A0A044QY83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY83"
  },
  {
    "uniprot": "A0A044UT99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT99"
  },
  {
    "uniprot": "A0A2K6VLN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLN8"
  },
  {
    "uniprot": "A0A044SHV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHV3"
  },
  {
    "uniprot": "A0A044RE88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RE88"
  },
  {
    "uniprot": "A0A044RTQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTQ4"
  },
  {
    "uniprot": "A0A044UTK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTK2"
  },
  {
    "uniprot": "A0A044TV01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV01"
  },
  {
    "uniprot": "A0A044S6V0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6V0"
  },
  {
    "uniprot": "A0A044RA41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RA41"
  },
  {
    "uniprot": "A0A044TVX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVX7"
  },
  {
    "uniprot": "A0A044UYT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYT9"
  },
  {
    "uniprot": "A0A044RV68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RV68"
  },
  {
    "uniprot": "A0A044TKR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKR8"
  },
  {
    "uniprot": "A0A044R0B2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0B2"
  },
  {
    "uniprot": "A0A044T1W6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1W6"
  },
  {
    "uniprot": "A0A2K6WMT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMT6"
  },
  {
    "uniprot": "A0A044S7F4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7F4"
  },
  {
    "uniprot": "A0A044UYN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYN4"
  },
  {
    "uniprot": "A0A044T6S3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6S3"
  },
  {
    "uniprot": "A0A2K6VJT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJT9"
  },
  {
    "uniprot": "A0A044VFI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFI7"
  },
  {
    "uniprot": "A0A044T607",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T607"
  },
  {
    "uniprot": "A0A044THL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THL2"
  },
  {
    "uniprot": "A0A044SAU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAU3"
  },
  {
    "uniprot": "A0A044R2E5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2E5"
  },
  {
    "uniprot": "A0A044QWZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWZ7"
  },
  {
    "uniprot": "A0A044QK85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK85"
  },
  {
    "uniprot": "A0A2K6VGM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VGM0"
  },
  {
    "uniprot": "A0A044VFE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFE9"
  },
  {
    "uniprot": "A0A044RC80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC80"
  },
  {
    "uniprot": "A0A2K6WFX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFX7"
  },
  {
    "uniprot": "A0A158N869",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N869"
  },
  {
    "uniprot": "A0A044ULR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULR2"
  },
  {
    "uniprot": "A0A2K6WKI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKI6"
  },
  {
    "uniprot": "A0A044RDX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDX4"
  },
  {
    "uniprot": "A0A044UUC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUC3"
  },
  {
    "uniprot": "A0A044TW88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TW88"
  },
  {
    "uniprot": "A0A044QVG3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVG3"
  },
  {
    "uniprot": "A0A044UK76",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UK76"
  },
  {
    "uniprot": "A0A044V1A1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1A1"
  },
  {
    "uniprot": "A0A044SBX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBX3"
  },
  {
    "uniprot": "A0A044V7P6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7P6"
  },
  {
    "uniprot": "A0A044SM02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM02"
  },
  {
    "uniprot": "A0A044UC72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC72"
  },
  {
    "uniprot": "A0A044R8I1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8I1"
  },
  {
    "uniprot": "A0A158N890",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N890"
  },
  {
    "uniprot": "A0A044R246",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R246"
  },
  {
    "uniprot": "A0A044T7L8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7L8"
  },
  {
    "uniprot": "A0A044RGN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGN9"
  },
  {
    "uniprot": "A0A044R5N0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5N0"
  },
  {
    "uniprot": "A0A044U482",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U482"
  },
  {
    "uniprot": "A0A044VHL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHL0"
  },
  {
    "uniprot": "A0A044UT14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT14"
  },
  {
    "uniprot": "A0A044T6G5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6G5"
  },
  {
    "uniprot": "A0A044SFW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFW9"
  },
  {
    "uniprot": "A0A044SNA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNA1"
  },
  {
    "uniprot": "A0A044VA87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA87"
  },
  {
    "uniprot": "A0A044V9L6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9L6"
  },
  {
    "uniprot": "A0A044UU54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UU54"
  },
  {
    "uniprot": "A0A158N8R8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8R8"
  },
  {
    "uniprot": "A0A044TJ20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJ20"
  },
  {
    "uniprot": "A0A044T7M6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7M6"
  },
  {
    "uniprot": "A0A044T9Q1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9Q1"
  },
  {
    "uniprot": "A0A2K6VYN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYN4"
  },
  {
    "uniprot": "A0A044RVY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVY8"
  },
  {
    "uniprot": "A0A044T9A5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9A5"
  },
  {
    "uniprot": "A0A044SLH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLH6"
  },
  {
    "uniprot": "A0A044QR37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QR37"
  },
  {
    "uniprot": "A0A044T5S8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5S8"
  },
  {
    "uniprot": "A0A044UKG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKG8"
  },
  {
    "uniprot": "A0A044SL89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SL89"
  },
  {
    "uniprot": "A0A2K6WF75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WF75"
  },
  {
    "uniprot": "A0A044TYJ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYJ0"
  },
  {
    "uniprot": "A0A2K6VLM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLM8"
  },
  {
    "uniprot": "A0A044SA71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SA71"
  },
  {
    "uniprot": "A0A044SN46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN46"
  },
  {
    "uniprot": "A0A044UD92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UD92"
  },
  {
    "uniprot": "A0A044RZK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZK3"
  },
  {
    "uniprot": "A0A044UGM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGM0"
  },
  {
    "uniprot": "A0A044UJ71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJ71"
  },
  {
    "uniprot": "A0A2K6VPZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPZ8"
  },
  {
    "uniprot": "A0A044UTH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTH4"
  },
  {
    "uniprot": "A0A044T555",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T555"
  },
  {
    "uniprot": "A0A044QW86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW86"
  },
  {
    "uniprot": "A0A044V110",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V110"
  },
  {
    "uniprot": "A0A044S570",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S570"
  },
  {
    "uniprot": "A0A044RFJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFJ1"
  },
  {
    "uniprot": "A0A044U9Q9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9Q9"
  },
  {
    "uniprot": "A0A044UR75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UR75"
  },
  {
    "uniprot": "A0A044UZ72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ72"
  },
  {
    "uniprot": "A0A044RCV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCV3"
  },
  {
    "uniprot": "A0A044TGJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGJ3"
  },
  {
    "uniprot": "A0A044QUL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUL7"
  },
  {
    "uniprot": "A0A044TPH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPH9"
  },
  {
    "uniprot": "A0A044RCA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCA3"
  },
  {
    "uniprot": "A0A044V1V1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1V1"
  },
  {
    "uniprot": "A0A044U0B6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0B6"
  },
  {
    "uniprot": "A0A044RVR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVR2"
  },
  {
    "uniprot": "A0A158N8N8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8N8"
  },
  {
    "uniprot": "A0A044USB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USB8"
  },
  {
    "uniprot": "A0A044T0P3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0P3"
  },
  {
    "uniprot": "A0A044T365",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T365"
  },
  {
    "uniprot": "A0A2K6VSF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSF6"
  },
  {
    "uniprot": "A0A044TK36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK36"
  },
  {
    "uniprot": "A0A044V1W9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1W9"
  },
  {
    "uniprot": "A0A044TZM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZM4"
  },
  {
    "uniprot": "A0A044V981",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V981"
  },
  {
    "uniprot": "A0A044T5C0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5C0"
  },
  {
    "uniprot": "A0A044V5Z9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5Z9"
  },
  {
    "uniprot": "A0A044TS70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS70"
  },
  {
    "uniprot": "A0A044V1R5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1R5"
  },
  {
    "uniprot": "A0A044U4E0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4E0"
  },
  {
    "uniprot": "A0A044S6U8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6U8"
  },
  {
    "uniprot": "A0A2K6W1Q2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1Q2"
  },
  {
    "uniprot": "A0A044R3Y6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3Y6"
  },
  {
    "uniprot": "A0A044SRN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRN1"
  },
  {
    "uniprot": "A0A044SH14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SH14"
  },
  {
    "uniprot": "A0A2K6WKF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKF3"
  },
  {
    "uniprot": "A0A044QSI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSI0"
  },
  {
    "uniprot": "A0A044TGY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGY9"
  },
  {
    "uniprot": "A0A044T641",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T641"
  },
  {
    "uniprot": "A0A044QY16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY16"
  },
  {
    "uniprot": "A0A2K6WBM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBM5"
  },
  {
    "uniprot": "A0A044S289",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S289"
  },
  {
    "uniprot": "A0A044TM33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TM33"
  },
  {
    "uniprot": "A0A044RC94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC94"
  },
  {
    "uniprot": "A0A044URV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URV5"
  },
  {
    "uniprot": "A0A2K6VPP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPP9"
  },
  {
    "uniprot": "A0A044S309",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S309"
  },
  {
    "uniprot": "A0A044UKE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKE0"
  },
  {
    "uniprot": "A0A044TGA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGA4"
  },
  {
    "uniprot": "A0A044VDL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDL0"
  },
  {
    "uniprot": "A0A044SNT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNT4"
  },
  {
    "uniprot": "A0A2K6VER0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VER0"
  },
  {
    "uniprot": "A0A044UZ83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ83"
  },
  {
    "uniprot": "A0A044V980",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V980"
  },
  {
    "uniprot": "A0A044T5T4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5T4"
  },
  {
    "uniprot": "A0A044R457",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R457"
  },
  {
    "uniprot": "A0A044T8C7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8C7"
  },
  {
    "uniprot": "A0A044UL91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL91"
  },
  {
    "uniprot": "A0A2K6VVU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVU5"
  },
  {
    "uniprot": "A0A044RK84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RK84"
  },
  {
    "uniprot": "A0A044TMH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMH6"
  },
  {
    "uniprot": "A0A044S6U2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6U2"
  },
  {
    "uniprot": "A0A044RH16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH16"
  },
  {
    "uniprot": "A0A044UH78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UH78"
  },
  {
    "uniprot": "A0A044RBA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBA9"
  },
  {
    "uniprot": "A0A044TIV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIV3"
  },
  {
    "uniprot": "A0A044V7C2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7C2"
  },
  {
    "uniprot": "A0A044SQB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQB5"
  },
  {
    "uniprot": "A0A044UE20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE20"
  },
  {
    "uniprot": "A0A044VGC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGC9"
  },
  {
    "uniprot": "A0A044TDK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDK2"
  },
  {
    "uniprot": "A0A044TL41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TL41"
  },
  {
    "uniprot": "A0A044TDX0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDX0"
  },
  {
    "uniprot": "A0A044TSV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSV6"
  },
  {
    "uniprot": "A0A044UYX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYX9"
  },
  {
    "uniprot": "A0A044V4E4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4E4"
  },
  {
    "uniprot": "A0A044UQ20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQ20"
  },
  {
    "uniprot": "A0A044URX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URX4"
  },
  {
    "uniprot": "A0A044R280",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R280"
  },
  {
    "uniprot": "A0A044QPC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPC0"
  },
  {
    "uniprot": "A0A044RW45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW45"
  },
  {
    "uniprot": "A0A044S8A8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8A8"
  },
  {
    "uniprot": "A0A044UUF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUF0"
  },
  {
    "uniprot": "A0A044SLN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLN5"
  },
  {
    "uniprot": "A0A044V1B6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1B6"
  },
  {
    "uniprot": "A0A044V0Y4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0Y4"
  },
  {
    "uniprot": "A0A044SKV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKV9"
  },
  {
    "uniprot": "A0A044RXB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXB9"
  },
  {
    "uniprot": "A0A044SAA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAA0"
  },
  {
    "uniprot": "A0A044TT26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TT26"
  },
  {
    "uniprot": "A0A044VFS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFS9"
  },
  {
    "uniprot": "A0A044USR9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USR9"
  },
  {
    "uniprot": "A0A044T462",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T462"
  },
  {
    "uniprot": "A0A044R0W6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0W6"
  },
  {
    "uniprot": "A0A044TIE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIE5"
  },
  {
    "uniprot": "A0A2K6VW50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VW50"
  },
  {
    "uniprot": "A0A044U9G2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9G2"
  },
  {
    "uniprot": "A0A044TR61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR61"
  },
  {
    "uniprot": "A0A044RBA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBA5"
  },
  {
    "uniprot": "A0A044R236",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R236"
  },
  {
    "uniprot": "A0A044RG93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RG93"
  },
  {
    "uniprot": "A0A044QZ85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ85"
  },
  {
    "uniprot": "A0A044UY59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UY59"
  },
  {
    "uniprot": "A0A044T1V3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1V3"
  },
  {
    "uniprot": "A0A2K6WMK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMK1"
  },
  {
    "uniprot": "A0A044QKS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKS0"
  },
  {
    "uniprot": "A0A044V9T8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9T8"
  },
  {
    "uniprot": "A0A2K6VIY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIY9"
  },
  {
    "uniprot": "A0A044SJD4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJD4"
  },
  {
    "uniprot": "A0A2K6WJ38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJ38"
  },
  {
    "uniprot": "A0A044R440",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R440"
  },
  {
    "uniprot": "A0A044U7K6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7K6"
  },
  {
    "uniprot": "A0A158N840",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N840"
  },
  {
    "uniprot": "A0A044TA90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TA90"
  },
  {
    "uniprot": "A0A044USH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USH1"
  },
  {
    "uniprot": "A0A044TD67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TD67"
  },
  {
    "uniprot": "A0A044T6I4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6I4"
  },
  {
    "uniprot": "A0A044QQC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQC6"
  },
  {
    "uniprot": "A0A158N815",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N815"
  },
  {
    "uniprot": "A0A2K6WB53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WB53"
  },
  {
    "uniprot": "A0A158N851",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N851"
  },
  {
    "uniprot": "A0A044RDA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDA4"
  },
  {
    "uniprot": "A0A2K6W2U4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2U4"
  },
  {
    "uniprot": "A0A044SK85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK85"
  },
  {
    "uniprot": "A0A044S4Z8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4Z8"
  },
  {
    "uniprot": "A0A044UWK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWK4"
  },
  {
    "uniprot": "A0A044UC83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC83"
  },
  {
    "uniprot": "A0A044V538",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V538"
  },
  {
    "uniprot": "A0A044R639",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R639"
  },
  {
    "uniprot": "A0A044UKQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKQ3"
  },
  {
    "uniprot": "A0A044RNW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNW6"
  },
  {
    "uniprot": "A0A044UMG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMG0"
  },
  {
    "uniprot": "A0A044S691",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S691"
  },
  {
    "uniprot": "A0A044UZ00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ00"
  },
  {
    "uniprot": "A0A044TDB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDB7"
  },
  {
    "uniprot": "A0A044SAP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAP6"
  },
  {
    "uniprot": "A0A044TSP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSP4"
  },
  {
    "uniprot": "A0A044T8V2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8V2"
  },
  {
    "uniprot": "A0A044TKV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKV2"
  },
  {
    "uniprot": "A0A2K6W6D9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6D9"
  },
  {
    "uniprot": "A0A044SVR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVR4"
  },
  {
    "uniprot": "A0A044T4R0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4R0"
  },
  {
    "uniprot": "A0A044R0G8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0G8"
  },
  {
    "uniprot": "A0A044S4R8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4R8"
  },
  {
    "uniprot": "A0A044S6P3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6P3"
  },
  {
    "uniprot": "A0A044TPE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPE5"
  },
  {
    "uniprot": "A0A044QP41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP41"
  },
  {
    "uniprot": "A0A044S8M1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8M1"
  },
  {
    "uniprot": "A0A044TKD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKD7"
  },
  {
    "uniprot": "A0A044RTK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTK4"
  },
  {
    "uniprot": "A0A044U5I5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5I5"
  },
  {
    "uniprot": "A0A044S1Q4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1Q4"
  },
  {
    "uniprot": "A0A044U9Z9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9Z9"
  },
  {
    "uniprot": "A0A044RMN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMN1"
  },
  {
    "uniprot": "A0A044SCM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCM7"
  },
  {
    "uniprot": "A0A044RJD4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJD4"
  },
  {
    "uniprot": "A0A044QZ37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ37"
  },
  {
    "uniprot": "A0A044UL63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL63"
  },
  {
    "uniprot": "A0A2K6WBE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBE2"
  },
  {
    "uniprot": "A0A2K6W0U5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0U5"
  },
  {
    "uniprot": "A0A044TUT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUT5"
  },
  {
    "uniprot": "A0A044TXB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXB8"
  },
  {
    "uniprot": "A0A044SAJ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAJ5"
  },
  {
    "uniprot": "A0A2K6VGF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VGF4"
  },
  {
    "uniprot": "A0A044TYW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYW0"
  },
  {
    "uniprot": "A0A044RJV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJV8"
  },
  {
    "uniprot": "A0A044UMD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMD7"
  },
  {
    "uniprot": "A0A044TJH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJH6"
  },
  {
    "uniprot": "A0A044U5N2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5N2"
  },
  {
    "uniprot": "A0A2K6WK33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WK33"
  },
  {
    "uniprot": "A0A2K6VIQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIQ4"
  },
  {
    "uniprot": "A0A044SWZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWZ3"
  },
  {
    "uniprot": "A0A044QT05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT05"
  },
  {
    "uniprot": "A0A044TV93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV93"
  },
  {
    "uniprot": "A0A044RJX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJX7"
  },
  {
    "uniprot": "A0A044UFL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFL5"
  },
  {
    "uniprot": "A0A044TTQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTQ1"
  },
  {
    "uniprot": "A0A044RHD2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHD2"
  },
  {
    "uniprot": "A0A2K6WAT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAT0"
  },
  {
    "uniprot": "A0A044RIV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIV6"
  },
  {
    "uniprot": "A0A044V9Q3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9Q3"
  },
  {
    "uniprot": "A0A044SLE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLE2"
  },
  {
    "uniprot": "A0A044SE57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SE57"
  },
  {
    "uniprot": "A0A044RC12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC12"
  },
  {
    "uniprot": "A0A044SI36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI36"
  },
  {
    "uniprot": "A0A044V7S0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7S0"
  },
  {
    "uniprot": "A0A044SN89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN89"
  },
  {
    "uniprot": "A0A044SKI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKI4"
  },
  {
    "uniprot": "A0A044RUZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUZ2"
  },
  {
    "uniprot": "A0A044TDA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDA4"
  },
  {
    "uniprot": "A0A044SQW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQW8"
  },
  {
    "uniprot": "A0A044RJT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJT8"
  },
  {
    "uniprot": "A0A044UTP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTP6"
  },
  {
    "uniprot": "A0A044TE05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TE05"
  },
  {
    "uniprot": "A0A044SEG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEG4"
  },
  {
    "uniprot": "A0A044RM31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RM31"
  },
  {
    "uniprot": "A0A044USF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USF8"
  },
  {
    "uniprot": "A0A044SDK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDK4"
  },
  {
    "uniprot": "A0A044QLA8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLA8"
  },
  {
    "uniprot": "A0A044ULI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULI9"
  },
  {
    "uniprot": "A0A044QYG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYG7"
  },
  {
    "uniprot": "A0A044RNI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNI7"
  },
  {
    "uniprot": "A0A044QQ35",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQ35"
  },
  {
    "uniprot": "A0A2K6VTR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VTR3"
  },
  {
    "uniprot": "A0A044S035",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S035"
  },
  {
    "uniprot": "A0A2K6VG65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VG65"
  },
  {
    "uniprot": "A0A044VE63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VE63"
  },
  {
    "uniprot": "A0A044TP91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TP91"
  },
  {
    "uniprot": "A0A044UBL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBL7"
  },
  {
    "uniprot": "A0A044RIH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIH2"
  },
  {
    "uniprot": "A0A044VE51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VE51"
  },
  {
    "uniprot": "A0A044TI66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TI66"
  },
  {
    "uniprot": "A0A044RF75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RF75"
  },
  {
    "uniprot": "A0A044SHB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHB6"
  },
  {
    "uniprot": "A0A2K6VP43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VP43"
  },
  {
    "uniprot": "A0A044RLV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLV3"
  },
  {
    "uniprot": "A0A044S8K5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8K5"
  },
  {
    "uniprot": "A0A044RHN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHN1"
  },
  {
    "uniprot": "A0A044VHF7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHF7"
  },
  {
    "uniprot": "A0A044SG85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG85"
  },
  {
    "uniprot": "A0A044STV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STV8"
  },
  {
    "uniprot": "A0A044SQ02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ02"
  },
  {
    "uniprot": "A0A044TXS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXS3"
  },
  {
    "uniprot": "A0A044RJE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJE7"
  },
  {
    "uniprot": "A0A044V9E7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9E7"
  },
  {
    "uniprot": "A0A044S963",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S963"
  },
  {
    "uniprot": "A0A044VHE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHE7"
  },
  {
    "uniprot": "A0A044SZ47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ47"
  },
  {
    "uniprot": "A0A044RB35",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RB35"
  },
  {
    "uniprot": "A0A044V8H2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8H2"
  },
  {
    "uniprot": "A0A044V7A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7A9"
  },
  {
    "uniprot": "A0A044QXQ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXQ2"
  },
  {
    "uniprot": "A0A044UI06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI06"
  },
  {
    "uniprot": "A0A044RZF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZF5"
  },
  {
    "uniprot": "A0A044S702",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S702"
  },
  {
    "uniprot": "A0A044UCQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCQ9"
  },
  {
    "uniprot": "A0A044U952",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U952"
  },
  {
    "uniprot": "A0A044RSA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSA9"
  },
  {
    "uniprot": "A0A044V368",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V368"
  },
  {
    "uniprot": "A0A044SR75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SR75"
  },
  {
    "uniprot": "A0A044RZY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZY9"
  },
  {
    "uniprot": "A0A044UIF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIF3"
  },
  {
    "uniprot": "A0A044TSF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSF8"
  },
  {
    "uniprot": "A0A044TUC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUC5"
  },
  {
    "uniprot": "A0A044UNP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNP4"
  },
  {
    "uniprot": "A0A044RAM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAM8"
  },
  {
    "uniprot": "A0A044T1Z0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1Z0"
  },
  {
    "uniprot": "A0A044UR53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UR53"
  },
  {
    "uniprot": "A0A044TH73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH73"
  },
  {
    "uniprot": "A0A044QNC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNC5"
  },
  {
    "uniprot": "A0A044UF12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF12"
  },
  {
    "uniprot": "A0A044VJ36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJ36"
  },
  {
    "uniprot": "A0A044R3A6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3A6"
  },
  {
    "uniprot": "A0A044V480",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V480"
  },
  {
    "uniprot": "A0A044TBH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBH1"
  },
  {
    "uniprot": "A0A2K6WJU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJU9"
  },
  {
    "uniprot": "A0A044R590",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R590"
  },
  {
    "uniprot": "A0A044SID5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SID5"
  },
  {
    "uniprot": "A0A044SH07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SH07"
  },
  {
    "uniprot": "A0A044TKN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKN4"
  },
  {
    "uniprot": "A0A044UFL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFL9"
  },
  {
    "uniprot": "A0A044QW53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW53"
  },
  {
    "uniprot": "A0A044SFE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFE6"
  },
  {
    "uniprot": "A0A2K6WHM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WHM3"
  },
  {
    "uniprot": "A0A044U769",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U769"
  },
  {
    "uniprot": "A0A044QXP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXP3"
  },
  {
    "uniprot": "A0A044T1R2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1R2"
  },
  {
    "uniprot": "A0A044SLK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLK0"
  },
  {
    "uniprot": "A0A044SKD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKD6"
  },
  {
    "uniprot": "A0A044TQI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQI4"
  },
  {
    "uniprot": "A0A044S6G9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6G9"
  },
  {
    "uniprot": "A0A044TR12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR12"
  },
  {
    "uniprot": "A0A044UC18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC18"
  },
  {
    "uniprot": "A0A044TTL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTL1"
  },
  {
    "uniprot": "A0A044REH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REH7"
  },
  {
    "uniprot": "A0A044V4B5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4B5"
  },
  {
    "uniprot": "A0A044V485",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V485"
  },
  {
    "uniprot": "A0A158N7Y8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Y8"
  },
  {
    "uniprot": "A0A044UJK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJK3"
  },
  {
    "uniprot": "A0A044RTD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTD8"
  },
  {
    "uniprot": "A0A044TW07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TW07"
  },
  {
    "uniprot": "A0A2K6W493",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W493"
  },
  {
    "uniprot": "A0A044VFP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFP1"
  },
  {
    "uniprot": "A0A2K6VYL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYL6"
  },
  {
    "uniprot": "A0A044TRM9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRM9"
  },
  {
    "uniprot": "A0A158N8B1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8B1"
  },
  {
    "uniprot": "A0A044V1E3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1E3"
  },
  {
    "uniprot": "A0A044T1X1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1X1"
  },
  {
    "uniprot": "A0A044RHW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHW9"
  },
  {
    "uniprot": "A0A044QX20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QX20"
  },
  {
    "uniprot": "A0A044SWH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWH5"
  },
  {
    "uniprot": "A0A044SYU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYU0"
  },
  {
    "uniprot": "A0A044V1P6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1P6"
  },
  {
    "uniprot": "A0A044UZ76",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ76"
  },
  {
    "uniprot": "A0A044TGG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGG0"
  },
  {
    "uniprot": "A0A044RBH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBH9"
  },
  {
    "uniprot": "A0A044U8K8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8K8"
  },
  {
    "uniprot": "A0A044RCL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCL3"
  },
  {
    "uniprot": "Q07449",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q07449"
  },
  {
    "uniprot": "A0A044V4M1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4M1"
  },
  {
    "uniprot": "A0A2K6WAA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAA9"
  },
  {
    "uniprot": "A0A044RRN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRN4"
  },
  {
    "uniprot": "A0A2K6VLA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLA1"
  },
  {
    "uniprot": "A0A044T0H4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0H4"
  },
  {
    "uniprot": "A0A044UW38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UW38"
  },
  {
    "uniprot": "A0A044UCG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCG4"
  },
  {
    "uniprot": "A0A044VHL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHL7"
  },
  {
    "uniprot": "A0A044SHC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHC5"
  },
  {
    "uniprot": "A0A044V1X5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1X5"
  },
  {
    "uniprot": "A0A2K6VQD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQD8"
  },
  {
    "uniprot": "A0A2K6VS27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VS27"
  },
  {
    "uniprot": "A0A044VBM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBM3"
  },
  {
    "uniprot": "A0A044RZS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZS7"
  },
  {
    "uniprot": "A0A2K6VD54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VD54"
  },
  {
    "uniprot": "A0A044RAK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAK6"
  },
  {
    "uniprot": "A0A044V9A0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9A0"
  },
  {
    "uniprot": "A0A044TTB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTB7"
  },
  {
    "uniprot": "A0A044S5S1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5S1"
  },
  {
    "uniprot": "A0A044UVI5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVI5"
  },
  {
    "uniprot": "A0A2K6W070",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W070"
  },
  {
    "uniprot": "A0A044T0U2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0U2"
  },
  {
    "uniprot": "A0A044SVE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVE0"
  },
  {
    "uniprot": "A0A044SEV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEV3"
  },
  {
    "uniprot": "A0A044RCD9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCD9"
  },
  {
    "uniprot": "A0A044QXB2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXB2"
  },
  {
    "uniprot": "A0A044V3I4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3I4"
  },
  {
    "uniprot": "A0A044UFP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFP0"
  },
  {
    "uniprot": "A0A044V915",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V915"
  },
  {
    "uniprot": "A0A044V8J8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8J8"
  },
  {
    "uniprot": "A0A044TXE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXE5"
  },
  {
    "uniprot": "A0A044QUI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUI8"
  },
  {
    "uniprot": "A0A044THF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THF8"
  },
  {
    "uniprot": "A0A044SSC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSC8"
  },
  {
    "uniprot": "A0A044QZI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZI8"
  },
  {
    "uniprot": "A0A044V179",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V179"
  },
  {
    "uniprot": "A0A044UT78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT78"
  },
  {
    "uniprot": "A0A044SA13",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SA13"
  },
  {
    "uniprot": "A0A044QYB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYB3"
  },
  {
    "uniprot": "O01360",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/O01360"
  },
  {
    "uniprot": "A0A044V9I8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9I8"
  },
  {
    "uniprot": "A0A044R4L9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4L9"
  },
  {
    "uniprot": "A0A044V9V5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9V5"
  },
  {
    "uniprot": "A0A044U6Y3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6Y3"
  },
  {
    "uniprot": "A0A044TRE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRE4"
  },
  {
    "uniprot": "A0A2K6WGG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGG6"
  },
  {
    "uniprot": "A0A044SHM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHM1"
  },
  {
    "uniprot": "A0A044RC07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC07"
  },
  {
    "uniprot": "A0A044UVA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVA0"
  },
  {
    "uniprot": "A0A044RJI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJI4"
  },
  {
    "uniprot": "A0A044SG31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG31"
  },
  {
    "uniprot": "A0A044S4W9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4W9"
  },
  {
    "uniprot": "A0A2K6VQL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQL2"
  },
  {
    "uniprot": "A0A158N7V6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7V6"
  },
  {
    "uniprot": "A0A2K6W6V3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6V3"
  },
  {
    "uniprot": "A0A044T4L8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4L8"
  },
  {
    "uniprot": "A0A044T996",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T996"
  },
  {
    "uniprot": "A0A044QMU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMU7"
  },
  {
    "uniprot": "A0A044VBE8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBE8"
  },
  {
    "uniprot": "A0A044T4T4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4T4"
  },
  {
    "uniprot": "A0A044VAB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAB7"
  },
  {
    "uniprot": "A0A044U3Q1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3Q1"
  },
  {
    "uniprot": "A0A2K6WDY1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDY1"
  },
  {
    "uniprot": "A0A044VD52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD52"
  },
  {
    "uniprot": "A0A044UL58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL58"
  },
  {
    "uniprot": "A0A044QV69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QV69"
  },
  {
    "uniprot": "A0A044QNK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNK3"
  },
  {
    "uniprot": "A0A044R2Q0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2Q0"
  },
  {
    "uniprot": "A0A044S0L2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0L2"
  },
  {
    "uniprot": "A0A044V964",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V964"
  },
  {
    "uniprot": "A0A044QUN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUN5"
  },
  {
    "uniprot": "A0A044SZK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZK3"
  },
  {
    "uniprot": "A0A044SSE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSE1"
  },
  {
    "uniprot": "A0A044UT30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT30"
  },
  {
    "uniprot": "A0A044V3H1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3H1"
  },
  {
    "uniprot": "A0A044S7N8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7N8"
  },
  {
    "uniprot": "A0A044QRZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRZ2"
  },
  {
    "uniprot": "A0A044V9U3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9U3"
  },
  {
    "uniprot": "A0A044RAS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAS7"
  },
  {
    "uniprot": "A0A044U6G8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6G8"
  },
  {
    "uniprot": "A0A044TG09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TG09"
  },
  {
    "uniprot": "A0A044TB93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TB93"
  },
  {
    "uniprot": "A0A044RDT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDT9"
  },
  {
    "uniprot": "A0A044T6Z3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6Z3"
  },
  {
    "uniprot": "A0A2K6VLT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLT0"
  },
  {
    "uniprot": "A0A044TDE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDE4"
  },
  {
    "uniprot": "A0A044V4D2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4D2"
  },
  {
    "uniprot": "A0A044TSC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSC2"
  },
  {
    "uniprot": "A0A044S182",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S182"
  },
  {
    "uniprot": "A0A044R6U0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6U0"
  },
  {
    "uniprot": "A0A044V9X3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9X3"
  },
  {
    "uniprot": "A0A044TGU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGU8"
  },
  {
    "uniprot": "A0A044V9T4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9T4"
  },
  {
    "uniprot": "A0A044SVA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVA1"
  },
  {
    "uniprot": "A0A044RN90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RN90"
  },
  {
    "uniprot": "A0A044URN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URN3"
  },
  {
    "uniprot": "A0A044VEG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEG4"
  },
  {
    "uniprot": "A0A044STQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STQ0"
  },
  {
    "uniprot": "A0A044R698",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R698"
  },
  {
    "uniprot": "A0A044V9M7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9M7"
  },
  {
    "uniprot": "A0A044SFI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFI6"
  },
  {
    "uniprot": "A0A044VIK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIK3"
  },
  {
    "uniprot": "A0A044QZA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZA9"
  },
  {
    "uniprot": "A0A044QLS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLS7"
  },
  {
    "uniprot": "A0A2K6WJC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJC0"
  },
  {
    "uniprot": "A0A158N8G5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8G5"
  },
  {
    "uniprot": "A0A044U5L7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5L7"
  },
  {
    "uniprot": "A0A044QS32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QS32"
  },
  {
    "uniprot": "A0A2K6WB73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WB73"
  },
  {
    "uniprot": "A0A044UUI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUI6"
  },
  {
    "uniprot": "A0A158N8D8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8D8"
  },
  {
    "uniprot": "A0A044THI5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THI5"
  },
  {
    "uniprot": "A0A044UE54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE54"
  },
  {
    "uniprot": "A0A044RXF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXF8"
  },
  {
    "uniprot": "A0A044UH87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UH87"
  },
  {
    "uniprot": "A0A044VF31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VF31"
  },
  {
    "uniprot": "A0A044QSP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSP5"
  },
  {
    "uniprot": "A0A2K6VGV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VGV2"
  },
  {
    "uniprot": "A0A044U4D4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4D4"
  },
  {
    "uniprot": "A0A044QUD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUD5"
  },
  {
    "uniprot": "A0A044UTH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTH1"
  },
  {
    "uniprot": "A0A044S8S7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8S7"
  },
  {
    "uniprot": "A0A044UJC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJC9"
  },
  {
    "uniprot": "A0A044V969",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V969"
  },
  {
    "uniprot": "A0A044T760",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T760"
  },
  {
    "uniprot": "A0A044RTC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTC5"
  },
  {
    "uniprot": "A0A044T6I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6I9"
  },
  {
    "uniprot": "A0A044V756",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V756"
  },
  {
    "uniprot": "A0A044UGQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGQ0"
  },
  {
    "uniprot": "A0A044SS13",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS13"
  },
  {
    "uniprot": "A0A044SSE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSE4"
  },
  {
    "uniprot": "A0A2K6VZN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZN8"
  },
  {
    "uniprot": "A0A2K6W109",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W109"
  },
  {
    "uniprot": "A0A044TDN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDN2"
  },
  {
    "uniprot": "A0A044V6I4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6I4"
  },
  {
    "uniprot": "A0A044S6K0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6K0"
  },
  {
    "uniprot": "A0A044QQV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQV4"
  },
  {
    "uniprot": "A0A044UPI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPI7"
  },
  {
    "uniprot": "A0A2K6VXD4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXD4"
  },
  {
    "uniprot": "A0A044S6D4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6D4"
  },
  {
    "uniprot": "A0A044T0R2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0R2"
  },
  {
    "uniprot": "A0A044QY52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY52"
  },
  {
    "uniprot": "P46434",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P46434"
  },
  {
    "uniprot": "A0A044SNN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNN9"
  },
  {
    "uniprot": "A0A044RAP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAP8"
  },
  {
    "uniprot": "A0A044SC77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SC77"
  },
  {
    "uniprot": "A0A044R301",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R301"
  },
  {
    "uniprot": "A0A044VG69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VG69"
  },
  {
    "uniprot": "A0A044UA18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA18"
  },
  {
    "uniprot": "A0A044V4E5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4E5"
  },
  {
    "uniprot": "A0A044SBC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBC4"
  },
  {
    "uniprot": "A0A044VIV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIV9"
  },
  {
    "uniprot": "A0A2K6VHS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHS4"
  },
  {
    "uniprot": "A0A044TYH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYH3"
  },
  {
    "uniprot": "A0A044V913",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V913"
  },
  {
    "uniprot": "A0A044TFG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFG4"
  },
  {
    "uniprot": "A0A044VG24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VG24"
  },
  {
    "uniprot": "A0A044V9A4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9A4"
  },
  {
    "uniprot": "A0A044VIF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIF2"
  },
  {
    "uniprot": "A0A044SUH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUH8"
  },
  {
    "uniprot": "A0A044S240",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S240"
  },
  {
    "uniprot": "A0A2K6W1P3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1P3"
  },
  {
    "uniprot": "A0A044TQ96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQ96"
  },
  {
    "uniprot": "A0A044TG62",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TG62"
  },
  {
    "uniprot": "A0A044S5S5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5S5"
  },
  {
    "uniprot": "A0A2K6W0F7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0F7"
  },
  {
    "uniprot": "A0A044V923",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V923"
  },
  {
    "uniprot": "A0A044ST80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ST80"
  },
  {
    "uniprot": "A0A044TDF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDF1"
  },
  {
    "uniprot": "A0A044TMJ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMJ5"
  },
  {
    "uniprot": "A0A044UKZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKZ7"
  },
  {
    "uniprot": "A0A044URG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URG2"
  },
  {
    "uniprot": "A0A044U9M1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9M1"
  },
  {
    "uniprot": "A0A044V539",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V539"
  },
  {
    "uniprot": "A0A044UMT3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMT3"
  },
  {
    "uniprot": "A0A044U4V0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4V0"
  },
  {
    "uniprot": "A0A044QY77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY77"
  },
  {
    "uniprot": "A0A2K6WLZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLZ2"
  },
  {
    "uniprot": "A0A044U1S5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1S5"
  },
  {
    "uniprot": "A0A044T6R9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6R9"
  },
  {
    "uniprot": "A0A2K6WK61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WK61"
  },
  {
    "uniprot": "A0A044UZS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZS3"
  },
  {
    "uniprot": "A0A044UGE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGE7"
  },
  {
    "uniprot": "A0A2K6VQS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQS6"
  },
  {
    "uniprot": "A0A044SWF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWF3"
  },
  {
    "uniprot": "A0A044TBU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBU6"
  },
  {
    "uniprot": "A0A044TLV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLV6"
  },
  {
    "uniprot": "A0A044TY61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TY61"
  },
  {
    "uniprot": "A0A044QKG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKG1"
  },
  {
    "uniprot": "A0A044V5E4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5E4"
  },
  {
    "uniprot": "A0A044S2A4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2A4"
  },
  {
    "uniprot": "A0A044T566",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T566"
  },
  {
    "uniprot": "A0A044TEY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEY0"
  },
  {
    "uniprot": "A0A044TEQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEQ0"
  },
  {
    "uniprot": "A0A2K6WDY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDY2"
  },
  {
    "uniprot": "A0A044TBT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBT0"
  },
  {
    "uniprot": "A0A044SS33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS33"
  },
  {
    "uniprot": "A0A044TLS8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLS8"
  },
  {
    "uniprot": "A0A044VJ14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJ14"
  },
  {
    "uniprot": "A0A044V0K6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0K6"
  },
  {
    "uniprot": "A0A044V8E0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8E0"
  },
  {
    "uniprot": "A0A044RDW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDW8"
  },
  {
    "uniprot": "A0A044QL75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL75"
  },
  {
    "uniprot": "A0A044SLT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLT2"
  },
  {
    "uniprot": "A0A044TT39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TT39"
  },
  {
    "uniprot": "A0A044S7S9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7S9"
  },
  {
    "uniprot": "A0A2K6VQB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQB4"
  },
  {
    "uniprot": "A0A044T4M8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4M8"
  },
  {
    "uniprot": "A0A2K6VFE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFE0"
  },
  {
    "uniprot": "A0A044UT37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT37"
  },
  {
    "uniprot": "A0A044TBR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBR7"
  },
  {
    "uniprot": "A0A044TPZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPZ7"
  },
  {
    "uniprot": "A0A044VD34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD34"
  },
  {
    "uniprot": "A0A044VIY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIY2"
  },
  {
    "uniprot": "A0A044U822",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U822"
  },
  {
    "uniprot": "A0A044R603",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R603"
  },
  {
    "uniprot": "O61358",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/O61358"
  },
  {
    "uniprot": "A0A044RCF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCF1"
  },
  {
    "uniprot": "A0A044RK54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RK54"
  },
  {
    "uniprot": "A0A044UL44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL44"
  },
  {
    "uniprot": "A0A044R077",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R077"
  },
  {
    "uniprot": "A0A044RSP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSP0"
  },
  {
    "uniprot": "A0A044U697",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U697"
  },
  {
    "uniprot": "A0A044V0T0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0T0"
  },
  {
    "uniprot": "A0A044QT84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT84"
  },
  {
    "uniprot": "A0A044SDI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDI7"
  },
  {
    "uniprot": "A0A2K6WM08",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WM08"
  },
  {
    "uniprot": "A0A044V6Q4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Q4"
  },
  {
    "uniprot": "A0A044UDN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDN9"
  },
  {
    "uniprot": "A0A044V0D6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0D6"
  },
  {
    "uniprot": "A0A044UUS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUS6"
  },
  {
    "uniprot": "A0A044RSR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSR8"
  },
  {
    "uniprot": "A0A044S2R4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2R4"
  },
  {
    "uniprot": "A0A044US93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044US93"
  },
  {
    "uniprot": "A0A2K6W2N9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2N9"
  },
  {
    "uniprot": "A0A044UX65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX65"
  },
  {
    "uniprot": "A0A044SVP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVP4"
  },
  {
    "uniprot": "A0A044UTK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTK5"
  },
  {
    "uniprot": "A0A044R960",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R960"
  },
  {
    "uniprot": "A0A044UP46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UP46"
  },
  {
    "uniprot": "A0A044QKK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKK8"
  },
  {
    "uniprot": "A0A044TKL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKL0"
  },
  {
    "uniprot": "A0A044T1G7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1G7"
  },
  {
    "uniprot": "A0A044RMT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMT7"
  },
  {
    "uniprot": "A0A044REK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REK6"
  },
  {
    "uniprot": "A0A2K6VT83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VT83"
  },
  {
    "uniprot": "A0A044VA40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA40"
  },
  {
    "uniprot": "A0A2K6WKI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKI7"
  },
  {
    "uniprot": "A0A044TKJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKJ1"
  },
  {
    "uniprot": "A0A044QSX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSX6"
  },
  {
    "uniprot": "A0A044U4X6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4X6"
  },
  {
    "uniprot": "A0A044VA19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA19"
  },
  {
    "uniprot": "A0A044RKC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKC4"
  },
  {
    "uniprot": "A0A044UU06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UU06"
  },
  {
    "uniprot": "A0A044TWL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWL1"
  },
  {
    "uniprot": "A0A044QX50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QX50"
  },
  {
    "uniprot": "A0A044U670",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U670"
  },
  {
    "uniprot": "A0A044TBK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBK1"
  },
  {
    "uniprot": "A0A044ULD2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULD2"
  },
  {
    "uniprot": "A0A044U0I0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0I0"
  },
  {
    "uniprot": "A0A044UK11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UK11"
  },
  {
    "uniprot": "A0A044TPQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPQ3"
  },
  {
    "uniprot": "A0A044TSH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSH4"
  },
  {
    "uniprot": "A0A044TNV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNV6"
  },
  {
    "uniprot": "A0A044UYE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYE3"
  },
  {
    "uniprot": "A0A044V338",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V338"
  },
  {
    "uniprot": "A0A044T8U5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8U5"
  },
  {
    "uniprot": "A0A044SCR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCR0"
  },
  {
    "uniprot": "A0A044RSV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSV2"
  },
  {
    "uniprot": "A0A044TVE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVE1"
  },
  {
    "uniprot": "A0A044STI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STI9"
  },
  {
    "uniprot": "A0A044RWM9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWM9"
  },
  {
    "uniprot": "A0A044RZ67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZ67"
  },
  {
    "uniprot": "A0A044QW33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW33"
  },
  {
    "uniprot": "A0A044U7T5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7T5"
  },
  {
    "uniprot": "A0A044V8P4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8P4"
  },
  {
    "uniprot": "A0A044U1L2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1L2"
  },
  {
    "uniprot": "A0A044U994",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U994"
  },
  {
    "uniprot": "A0A044SDE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDE7"
  },
  {
    "uniprot": "A0A044THB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THB5"
  },
  {
    "uniprot": "A0A2K6VYH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYH7"
  },
  {
    "uniprot": "A0A044VDR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDR0"
  },
  {
    "uniprot": "A0A044UIZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIZ2"
  },
  {
    "uniprot": "A0A044TAU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAU4"
  },
  {
    "uniprot": "A0A044SGE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGE1"
  },
  {
    "uniprot": "A0A044SNM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNM6"
  },
  {
    "uniprot": "A0A044TS98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS98"
  },
  {
    "uniprot": "A0A044UB24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UB24"
  },
  {
    "uniprot": "A0A044UWP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWP3"
  },
  {
    "uniprot": "A0A044T560",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T560"
  },
  {
    "uniprot": "A0A044URG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URG5"
  },
  {
    "uniprot": "A0A044SI95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI95"
  },
  {
    "uniprot": "A0A044SKD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKD5"
  },
  {
    "uniprot": "A0A044RIM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIM0"
  },
  {
    "uniprot": "A0A044UYW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYW7"
  },
  {
    "uniprot": "A0A044TZP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZP0"
  },
  {
    "uniprot": "A0A044S5F1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5F1"
  },
  {
    "uniprot": "A0A044TV98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV98"
  },
  {
    "uniprot": "A0A044STM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STM6"
  },
  {
    "uniprot": "A0A044RX75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RX75"
  },
  {
    "uniprot": "A0A044REX0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REX0"
  },
  {
    "uniprot": "A0A044U712",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U712"
  },
  {
    "uniprot": "A0A044TJZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJZ9"
  },
  {
    "uniprot": "A0A044SME1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SME1"
  },
  {
    "uniprot": "A0A044S525",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S525"
  },
  {
    "uniprot": "A0A044SJD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJD5"
  },
  {
    "uniprot": "A0A044QKU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKU9"
  },
  {
    "uniprot": "A0A044VA28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA28"
  },
  {
    "uniprot": "A0A044R7B1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7B1"
  },
  {
    "uniprot": "A0A044STJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STJ6"
  },
  {
    "uniprot": "A0A044UYV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYV5"
  },
  {
    "uniprot": "A0A044U9V5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9V5"
  },
  {
    "uniprot": "A0A044VEF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEF4"
  },
  {
    "uniprot": "A0A044VGN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGN7"
  },
  {
    "uniprot": "A0A2K6VHT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHT9"
  },
  {
    "uniprot": "A0A2K6W4T7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W4T7"
  },
  {
    "uniprot": "A0A044T6D7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6D7"
  },
  {
    "uniprot": "A0A044UMD2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMD2"
  },
  {
    "uniprot": "A0A044UYI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYI6"
  },
  {
    "uniprot": "A0A044UPK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPK6"
  },
  {
    "uniprot": "A0A044SDY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDY7"
  },
  {
    "uniprot": "A0A044U652",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U652"
  },
  {
    "uniprot": "A0A044SIW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIW0"
  },
  {
    "uniprot": "A0A2K6WJP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJP6"
  },
  {
    "uniprot": "A0A2K6VSU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSU9"
  },
  {
    "uniprot": "A0A044U738",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U738"
  },
  {
    "uniprot": "A0A044RX71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RX71"
  },
  {
    "uniprot": "A0A2K6VW64",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VW64"
  },
  {
    "uniprot": "A0A2K6W938",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W938"
  },
  {
    "uniprot": "A0A044TPP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPP7"
  },
  {
    "uniprot": "A0A044VIJ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIJ0"
  },
  {
    "uniprot": "A0A2K6VPV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPV3"
  },
  {
    "uniprot": "A0A044S3N5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3N5"
  },
  {
    "uniprot": "A0A044SZ92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ92"
  },
  {
    "uniprot": "A0A044QZK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZK8"
  },
  {
    "uniprot": "A0A2K6VJT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJT4"
  },
  {
    "uniprot": "A0A2K6WIP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIP5"
  },
  {
    "uniprot": "A0A2K6VUK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUK6"
  },
  {
    "uniprot": "A0A044SFR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFR3"
  },
  {
    "uniprot": "A0A2K6W7G5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7G5"
  },
  {
    "uniprot": "A0A044S9N2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9N2"
  },
  {
    "uniprot": "A0A044UK89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UK89"
  },
  {
    "uniprot": "A0A044TW47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TW47"
  },
  {
    "uniprot": "A0A044UYZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYZ0"
  },
  {
    "uniprot": "A0A044TS66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS66"
  },
  {
    "uniprot": "A0A044TL73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TL73"
  },
  {
    "uniprot": "A0A158N825",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N825"
  },
  {
    "uniprot": "A0A044TCL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCL7"
  },
  {
    "uniprot": "A0A044SPW1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPW1"
  },
  {
    "uniprot": "A0A044TB80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TB80"
  },
  {
    "uniprot": "A0A044QME0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QME0"
  },
  {
    "uniprot": "A0A044SF87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SF87"
  },
  {
    "uniprot": "A0A044RAU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAU4"
  },
  {
    "uniprot": "A0A044SNC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNC8"
  },
  {
    "uniprot": "A0A044RNB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNB1"
  },
  {
    "uniprot": "A0A044UV79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UV79"
  },
  {
    "uniprot": "A0A044QR25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QR25"
  },
  {
    "uniprot": "A0A044T1U4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1U4"
  },
  {
    "uniprot": "A0A044RY69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY69"
  },
  {
    "uniprot": "A0A158N8N2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8N2"
  },
  {
    "uniprot": "A0A044R4W1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4W1"
  },
  {
    "uniprot": "A0A2K6VH46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VH46"
  },
  {
    "uniprot": "A0A2K6WEB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEB6"
  },
  {
    "uniprot": "A0A044U710",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U710"
  },
  {
    "uniprot": "A0A044TN48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN48"
  },
  {
    "uniprot": "A0A044TC37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC37"
  },
  {
    "uniprot": "A0A044T7E4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7E4"
  },
  {
    "uniprot": "A0A044U5H5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5H5"
  },
  {
    "uniprot": "A0A044UD86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UD86"
  },
  {
    "uniprot": "A0A044TRQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRQ7"
  },
  {
    "uniprot": "A0A044V448",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V448"
  },
  {
    "uniprot": "A0A044S149",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S149"
  },
  {
    "uniprot": "A0A044RX44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RX44"
  },
  {
    "uniprot": "A0A044V8Z3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Z3"
  },
  {
    "uniprot": "A0A044RII1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RII1"
  },
  {
    "uniprot": "A0A044T4X4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4X4"
  },
  {
    "uniprot": "A0A044R3R8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3R8"
  },
  {
    "uniprot": "A0A044V4F0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4F0"
  },
  {
    "uniprot": "A0A044TND5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TND5"
  },
  {
    "uniprot": "A0A2K6WEG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEG4"
  },
  {
    "uniprot": "P11503",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P11503"
  },
  {
    "uniprot": "A0A044UZ48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ48"
  },
  {
    "uniprot": "A0A044TK52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK52"
  },
  {
    "uniprot": "A0A044TRN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRN5"
  },
  {
    "uniprot": "A0A044UN11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UN11"
  },
  {
    "uniprot": "A0A044V9T2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9T2"
  },
  {
    "uniprot": "A0A044V9N8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9N8"
  },
  {
    "uniprot": "A0A158N806",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N806"
  },
  {
    "uniprot": "A0A044UWA2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWA2"
  },
  {
    "uniprot": "A0A044SLC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLC9"
  },
  {
    "uniprot": "A0A044TEG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEG5"
  },
  {
    "uniprot": "A0A044VH09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH09"
  },
  {
    "uniprot": "A0A044URP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URP2"
  },
  {
    "uniprot": "A0A044SIT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIT9"
  },
  {
    "uniprot": "A0A044S9K5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9K5"
  },
  {
    "uniprot": "A0A044S924",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S924"
  },
  {
    "uniprot": "A0A044U5A6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5A6"
  },
  {
    "uniprot": "A0A044TBK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBK4"
  },
  {
    "uniprot": "A0A044QQX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQX5"
  },
  {
    "uniprot": "A0A044U036",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U036"
  },
  {
    "uniprot": "A0A044QJS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJS4"
  },
  {
    "uniprot": "A0A044UXV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXV7"
  },
  {
    "uniprot": "A0A044UE69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE69"
  },
  {
    "uniprot": "A0A044UTG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTG8"
  },
  {
    "uniprot": "A0A044V475",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V475"
  },
  {
    "uniprot": "A0A044T9F8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9F8"
  },
  {
    "uniprot": "A0A044RDW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDW3"
  },
  {
    "uniprot": "A0A044TVP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVP8"
  },
  {
    "uniprot": "P13263",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P13263"
  },
  {
    "uniprot": "A0A044S2A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2A9"
  },
  {
    "uniprot": "A0A044RM72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RM72"
  },
  {
    "uniprot": "A0A044V518",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V518"
  },
  {
    "uniprot": "A0A044S4P6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4P6"
  },
  {
    "uniprot": "A0A044SFZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFZ5"
  },
  {
    "uniprot": "A0A044T9H3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9H3"
  },
  {
    "uniprot": "A0A2K6W5H1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W5H1"
  },
  {
    "uniprot": "A0A044RRY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRY2"
  },
  {
    "uniprot": "A0A044T419",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T419"
  },
  {
    "uniprot": "A0A044SD24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD24"
  },
  {
    "uniprot": "A0A044T2V3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2V3"
  },
  {
    "uniprot": "A0A044TGF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGF4"
  },
  {
    "uniprot": "A0A044RER0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RER0"
  },
  {
    "uniprot": "A0A044SE27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SE27"
  },
  {
    "uniprot": "A0A044UYC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYC1"
  },
  {
    "uniprot": "A0A044TCS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCS6"
  },
  {
    "uniprot": "A0A044SYL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYL7"
  },
  {
    "uniprot": "A0A044S6Q7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6Q7"
  },
  {
    "uniprot": "A0A044U9U5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9U5"
  },
  {
    "uniprot": "A0A044TGV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGV4"
  },
  {
    "uniprot": "A0A044RVT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVT6"
  },
  {
    "uniprot": "A0A044UB17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UB17"
  },
  {
    "uniprot": "A0A044TNH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNH0"
  },
  {
    "uniprot": "A0A044V0E0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0E0"
  },
  {
    "uniprot": "A0A044QM98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QM98"
  },
  {
    "uniprot": "A0A2K6WMJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMJ6"
  },
  {
    "uniprot": "A0A044VH93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH93"
  },
  {
    "uniprot": "A0A044SJN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJN6"
  },
  {
    "uniprot": "A0A044TEQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEQ4"
  },
  {
    "uniprot": "A0A044U7P6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7P6"
  },
  {
    "uniprot": "A0A044SVR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVR8"
  },
  {
    "uniprot": "A0A044SFA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFA3"
  },
  {
    "uniprot": "A0A044V5N9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5N9"
  },
  {
    "uniprot": "A0A044SD18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD18"
  },
  {
    "uniprot": "A0A044TLY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLY8"
  },
  {
    "uniprot": "A0A044SHK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHK6"
  },
  {
    "uniprot": "A0A044R065",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R065"
  },
  {
    "uniprot": "A0A044SKE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKE4"
  },
  {
    "uniprot": "A0A044V7Z2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7Z2"
  },
  {
    "uniprot": "A0A044V4I6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4I6"
  },
  {
    "uniprot": "A0A044RAZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAZ3"
  },
  {
    "uniprot": "A0A044TSM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSM1"
  },
  {
    "uniprot": "A0A044RC53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC53"
  },
  {
    "uniprot": "A0A044V152",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V152"
  },
  {
    "uniprot": "A0A044R8F8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8F8"
  },
  {
    "uniprot": "A0A044UVT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVT8"
  },
  {
    "uniprot": "A0A044STY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STY2"
  },
  {
    "uniprot": "A0A044UDR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDR4"
  },
  {
    "uniprot": "A0A044SG03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG03"
  },
  {
    "uniprot": "A0A044S389",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S389"
  },
  {
    "uniprot": "A0A044RGR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGR0"
  },
  {
    "uniprot": "A0A044SZP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZP1"
  },
  {
    "uniprot": "A0A044SMP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMP1"
  },
  {
    "uniprot": "A0A158N7V8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7V8"
  },
  {
    "uniprot": "A0A044R530",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R530"
  },
  {
    "uniprot": "A0A2K6WDI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDI0"
  },
  {
    "uniprot": "A0A044UA33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA33"
  },
  {
    "uniprot": "A0A2K6W547",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W547"
  },
  {
    "uniprot": "A0A044V9D6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9D6"
  },
  {
    "uniprot": "A0A044SPY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPY7"
  },
  {
    "uniprot": "A0A044SG39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG39"
  },
  {
    "uniprot": "A0A044V4D7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4D7"
  },
  {
    "uniprot": "A0A044S236",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S236"
  },
  {
    "uniprot": "A0A044QPS1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPS1"
  },
  {
    "uniprot": "A0A044UWN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWN2"
  },
  {
    "uniprot": "A0A044SK16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK16"
  },
  {
    "uniprot": "A0A2K6VHF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHF5"
  },
  {
    "uniprot": "A0A044RMN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMN2"
  },
  {
    "uniprot": "A0A044V279",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V279"
  },
  {
    "uniprot": "A0A044UAN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAN8"
  },
  {
    "uniprot": "A0A044V169",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V169"
  },
  {
    "uniprot": "A0A044SRH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRH8"
  },
  {
    "uniprot": "A0A044QWR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWR1"
  },
  {
    "uniprot": "A0A044TN14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN14"
  },
  {
    "uniprot": "A0A044U674",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U674"
  },
  {
    "uniprot": "A0A2K6VRE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRE4"
  },
  {
    "uniprot": "A0A044U5S0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5S0"
  },
  {
    "uniprot": "A0A2K6W1U3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1U3"
  },
  {
    "uniprot": "A0A044VE78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VE78"
  },
  {
    "uniprot": "A0A044RSA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSA7"
  },
  {
    "uniprot": "A0A044TDU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDU3"
  },
  {
    "uniprot": "A0A044T4Q6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4Q6"
  },
  {
    "uniprot": "A0A044SG71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG71"
  },
  {
    "uniprot": "A0A044V157",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V157"
  },
  {
    "uniprot": "A0A2K6VGE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VGE0"
  },
  {
    "uniprot": "A0A2K6W3J5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3J5"
  },
  {
    "uniprot": "P52570",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P52570"
  },
  {
    "uniprot": "A0A044V2B6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2B6"
  },
  {
    "uniprot": "A0A2K6WKE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKE3"
  },
  {
    "uniprot": "A0A044T7K9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7K9"
  },
  {
    "uniprot": "A0A044T8Y2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8Y2"
  },
  {
    "uniprot": "A0A2K6VZ59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZ59"
  },
  {
    "uniprot": "A0A044UNI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNI1"
  },
  {
    "uniprot": "A0A044SKK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKK1"
  },
  {
    "uniprot": "A0A044THI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THI9"
  },
  {
    "uniprot": "A0A044V1C4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1C4"
  },
  {
    "uniprot": "A0A2K6VKB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKB6"
  },
  {
    "uniprot": "A0A044URI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URI1"
  },
  {
    "uniprot": "A0A044R2V9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2V9"
  },
  {
    "uniprot": "A0A044TJR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJR6"
  },
  {
    "uniprot": "A0A044T016",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T016"
  },
  {
    "uniprot": "A0A044UBJ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBJ0"
  },
  {
    "uniprot": "A0A044S954",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S954"
  },
  {
    "uniprot": "A0A044R470",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R470"
  },
  {
    "uniprot": "A0A044S4S4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4S4"
  },
  {
    "uniprot": "A0A044S121",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S121"
  },
  {
    "uniprot": "A0A044UAN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAN2"
  },
  {
    "uniprot": "A0A044T8J1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8J1"
  },
  {
    "uniprot": "A0A044UXZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXZ4"
  },
  {
    "uniprot": "A0A044V0G9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0G9"
  },
  {
    "uniprot": "A0A044ST65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ST65"
  },
  {
    "uniprot": "A0A044T8Z0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8Z0"
  },
  {
    "uniprot": "A0A044UTB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTB5"
  },
  {
    "uniprot": "A0A044U1C5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1C5"
  },
  {
    "uniprot": "A0A044SCG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCG0"
  },
  {
    "uniprot": "A0A044RGP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGP9"
  },
  {
    "uniprot": "A0A044TC25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC25"
  },
  {
    "uniprot": "A0A044UWN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWN8"
  },
  {
    "uniprot": "A0A044T6Y1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6Y1"
  },
  {
    "uniprot": "A0A2K6VWY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWY3"
  },
  {
    "uniprot": "A0A044UMH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMH4"
  },
  {
    "uniprot": "A0A044QK04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK04"
  },
  {
    "uniprot": "A0A2K6VG60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VG60"
  },
  {
    "uniprot": "A0A044TMX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMX2"
  },
  {
    "uniprot": "A0A2K6W8Y5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8Y5"
  },
  {
    "uniprot": "A0A044RHS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHS4"
  },
  {
    "uniprot": "A0A044R6S7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6S7"
  },
  {
    "uniprot": "A0A044V9S8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9S8"
  },
  {
    "uniprot": "A0A044RH86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH86"
  },
  {
    "uniprot": "A0A044V5A3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5A3"
  },
  {
    "uniprot": "A0A044TDH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDH5"
  },
  {
    "uniprot": "A0A044RT43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RT43"
  },
  {
    "uniprot": "A0A044R207",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R207"
  },
  {
    "uniprot": "A0A2K6W7Y4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7Y4"
  },
  {
    "uniprot": "A0A044TIV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIV4"
  },
  {
    "uniprot": "A0A044R9Q4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9Q4"
  },
  {
    "uniprot": "A0A044SUW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUW6"
  },
  {
    "uniprot": "A0A044SZV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZV6"
  },
  {
    "uniprot": "A0A044UKY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKY5"
  },
  {
    "uniprot": "A0A2K6VN81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VN81"
  },
  {
    "uniprot": "A0A044V432",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V432"
  },
  {
    "uniprot": "A0A044V3B7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3B7"
  },
  {
    "uniprot": "A0A044UCX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCX7"
  },
  {
    "uniprot": "A0A044QP51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP51"
  },
  {
    "uniprot": "A0A044REE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REE1"
  },
  {
    "uniprot": "A0A2K6W734",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W734"
  },
  {
    "uniprot": "A0A044TLG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLG9"
  },
  {
    "uniprot": "A0A044S1H6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1H6"
  },
  {
    "uniprot": "A0A044UZB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZB6"
  },
  {
    "uniprot": "A0A2K6W3F0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3F0"
  },
  {
    "uniprot": "A0A2K6VJ13",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJ13"
  },
  {
    "uniprot": "A0A2K6VLH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLH9"
  },
  {
    "uniprot": "A0A2K6VIJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIJ6"
  },
  {
    "uniprot": "A0A044U8B5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8B5"
  },
  {
    "uniprot": "A0A2K6WDK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDK9"
  },
  {
    "uniprot": "A0A2K6VST7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VST7"
  },
  {
    "uniprot": "A0A044V8E7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8E7"
  },
  {
    "uniprot": "A0A044SLS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLS9"
  },
  {
    "uniprot": "A0A044UMJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMJ7"
  },
  {
    "uniprot": "A0A158N7Q9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Q9"
  },
  {
    "uniprot": "A0A2K6WLL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLL7"
  },
  {
    "uniprot": "A0A044RW53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW53"
  },
  {
    "uniprot": "A0A044RAL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAL1"
  },
  {
    "uniprot": "A0A2K6WCX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WCX1"
  },
  {
    "uniprot": "A0A044R3N1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3N1"
  },
  {
    "uniprot": "A0A044TC55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC55"
  },
  {
    "uniprot": "Q27883",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q27883"
  },
  {
    "uniprot": "A0A044QZ41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ41"
  },
  {
    "uniprot": "A0A044R9P7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9P7"
  },
  {
    "uniprot": "A0A044U337",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U337"
  },
  {
    "uniprot": "A0A044T294",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T294"
  },
  {
    "uniprot": "A0A044QXZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXZ7"
  },
  {
    "uniprot": "A0A044SSF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSF5"
  },
  {
    "uniprot": "A0A044SQ93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ93"
  },
  {
    "uniprot": "A0A044SS80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS80"
  },
  {
    "uniprot": "A0A2K6WE83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WE83"
  },
  {
    "uniprot": "A0A044R6C0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6C0"
  },
  {
    "uniprot": "A0A044UTR9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTR9"
  },
  {
    "uniprot": "A0A044SD54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD54"
  },
  {
    "uniprot": "A0A044RR25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RR25"
  },
  {
    "uniprot": "A0A2K6WBE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBE1"
  },
  {
    "uniprot": "A0A044SBY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBY0"
  },
  {
    "uniprot": "A0A044SPA2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPA2"
  },
  {
    "uniprot": "A0A044UU69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UU69"
  },
  {
    "uniprot": "A0A2K6VMV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMV0"
  },
  {
    "uniprot": "A0A044RWI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWI9"
  },
  {
    "uniprot": "A0A044SZS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZS0"
  },
  {
    "uniprot": "A0A044R3G3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3G3"
  },
  {
    "uniprot": "A0A044V536",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V536"
  },
  {
    "uniprot": "A0A044RMJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMJ7"
  },
  {
    "uniprot": "A0A2K6VQT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQT7"
  },
  {
    "uniprot": "A0A044UIJ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIJ2"
  },
  {
    "uniprot": "A0A044RPX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPX3"
  },
  {
    "uniprot": "A0A044S0W0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0W0"
  },
  {
    "uniprot": "A0A044V904",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V904"
  },
  {
    "uniprot": "A0A044TDE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDE1"
  },
  {
    "uniprot": "A0A044UPW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPW6"
  },
  {
    "uniprot": "A0A044V4S3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4S3"
  },
  {
    "uniprot": "A0A044S727",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S727"
  },
  {
    "uniprot": "A0A044VAF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAF2"
  },
  {
    "uniprot": "A0A044V916",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V916"
  },
  {
    "uniprot": "A0A2K6VL60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VL60"
  },
  {
    "uniprot": "A0A044S615",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S615"
  },
  {
    "uniprot": "A0A044SVZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVZ7"
  },
  {
    "uniprot": "A0A2K6VFJ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFJ8"
  },
  {
    "uniprot": "A0A044TFY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFY6"
  },
  {
    "uniprot": "A0A2K6VQ42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQ42"
  },
  {
    "uniprot": "A0A044QZX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZX8"
  },
  {
    "uniprot": "A0A044REV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REV2"
  },
  {
    "uniprot": "A0A044RVB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVB1"
  },
  {
    "uniprot": "A0A044S3Z8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3Z8"
  },
  {
    "uniprot": "A0A044VAB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAB9"
  },
  {
    "uniprot": "A0A044SCB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCB0"
  },
  {
    "uniprot": "A0A044SKW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKW8"
  },
  {
    "uniprot": "A0A2K6WHW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WHW9"
  },
  {
    "uniprot": "A0A044QUS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUS3"
  },
  {
    "uniprot": "A0A044V708",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V708"
  },
  {
    "uniprot": "A0A044RSC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSC4"
  },
  {
    "uniprot": "A0A044RSW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSW4"
  },
  {
    "uniprot": "A0A044UGU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGU6"
  },
  {
    "uniprot": "A0A044TUS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUS0"
  },
  {
    "uniprot": "A0A044RQB2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQB2"
  },
  {
    "uniprot": "A0A044SF80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SF80"
  },
  {
    "uniprot": "A0A044RXX0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXX0"
  },
  {
    "uniprot": "A0A044R0K6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0K6"
  },
  {
    "uniprot": "A0A044VA90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA90"
  },
  {
    "uniprot": "A0A044UDG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDG7"
  },
  {
    "uniprot": "A0A044SQ69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ69"
  },
  {
    "uniprot": "A0A044U9Z0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9Z0"
  },
  {
    "uniprot": "A0A044T2I6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2I6"
  },
  {
    "uniprot": "A0A044TK06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK06"
  },
  {
    "uniprot": "A0A044RNP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNP3"
  },
  {
    "uniprot": "A0A044RRK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRK6"
  },
  {
    "uniprot": "A0A044VJC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJC9"
  },
  {
    "uniprot": "A0A044UIL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIL3"
  },
  {
    "uniprot": "A0A044R0Y3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0Y3"
  },
  {
    "uniprot": "A0A044VAG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAG2"
  },
  {
    "uniprot": "A0A044R4E4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4E4"
  },
  {
    "uniprot": "A0A044RIZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIZ5"
  },
  {
    "uniprot": "A0A044SQT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQT1"
  },
  {
    "uniprot": "A0A044U8I2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8I2"
  },
  {
    "uniprot": "A0A044QZA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZA4"
  },
  {
    "uniprot": "A0A044QZG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZG1"
  },
  {
    "uniprot": "A0A044UL54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL54"
  },
  {
    "uniprot": "A0A044U1U8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1U8"
  },
  {
    "uniprot": "A0A044R9D1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9D1"
  },
  {
    "uniprot": "A0A044T736",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T736"
  },
  {
    "uniprot": "A0A044TCR5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCR5"
  },
  {
    "uniprot": "A0A044TYW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYW5"
  },
  {
    "uniprot": "A0A2K6VP74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VP74"
  },
  {
    "uniprot": "A0A044V489",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V489"
  },
  {
    "uniprot": "A0A2K6WJM9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJM9"
  },
  {
    "uniprot": "A0A044QXC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXC6"
  },
  {
    "uniprot": "A0A158N8Q7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8Q7"
  },
  {
    "uniprot": "A0A2K6VIY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIY6"
  },
  {
    "uniprot": "A0A044UGA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGA4"
  },
  {
    "uniprot": "A0A044T6L2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6L2"
  },
  {
    "uniprot": "A0A044SAQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAQ9"
  },
  {
    "uniprot": "A0A158N8M4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8M4"
  },
  {
    "uniprot": "A0A044RAA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAA1"
  },
  {
    "uniprot": "A0A044S2I1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2I1"
  },
  {
    "uniprot": "A0A044T2P7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2P7"
  },
  {
    "uniprot": "A0A044SUY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUY0"
  },
  {
    "uniprot": "A0A2K6W8U9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8U9"
  },
  {
    "uniprot": "A0A2K6W9D0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9D0"
  },
  {
    "uniprot": "A0A044V9F9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9F9"
  },
  {
    "uniprot": "A0A044ULI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULI3"
  },
  {
    "uniprot": "A0A2K6VR71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VR71"
  },
  {
    "uniprot": "A0A044VFI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFI9"
  },
  {
    "uniprot": "A0A044VJ98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJ98"
  },
  {
    "uniprot": "A0A044UMC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMC8"
  },
  {
    "uniprot": "A0A044S5H1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5H1"
  },
  {
    "uniprot": "A0A044VCW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCW0"
  },
  {
    "uniprot": "A0A044V4C4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4C4"
  },
  {
    "uniprot": "A0A044RA77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RA77"
  },
  {
    "uniprot": "A0A044S4Z0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4Z0"
  },
  {
    "uniprot": "A0A044V983",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V983"
  },
  {
    "uniprot": "A0A044TDI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDI6"
  },
  {
    "uniprot": "A0A044R0Y4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0Y4"
  },
  {
    "uniprot": "A0A044UZ44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ44"
  },
  {
    "uniprot": "A0A2K6W1M6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1M6"
  },
  {
    "uniprot": "A0A044V6Z1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Z1"
  },
  {
    "uniprot": "A0A044S6E3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6E3"
  },
  {
    "uniprot": "A0A2K6VI63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VI63"
  },
  {
    "uniprot": "A0A2K6W1C8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1C8"
  },
  {
    "uniprot": "A0A044RIT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIT0"
  },
  {
    "uniprot": "A0A044QXU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXU9"
  },
  {
    "uniprot": "A0A044VFH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFH5"
  },
  {
    "uniprot": "A0A044SK46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK46"
  },
  {
    "uniprot": "A0A044SGJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGJ4"
  },
  {
    "uniprot": "A0A044TVH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVH7"
  },
  {
    "uniprot": "A0A044VH67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH67"
  },
  {
    "uniprot": "A0A044RE97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RE97"
  },
  {
    "uniprot": "A0A044QJK7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJK7"
  },
  {
    "uniprot": "A0A044R3M4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3M4"
  },
  {
    "uniprot": "A0A044TCH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCH5"
  },
  {
    "uniprot": "A0A044UHW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHW5"
  },
  {
    "uniprot": "A0A044QL80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL80"
  },
  {
    "uniprot": "A0A2K6W3U5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3U5"
  },
  {
    "uniprot": "A0A2K6VWE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWE7"
  },
  {
    "uniprot": "A0A044QNL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNL1"
  },
  {
    "uniprot": "A0A2K6W4T6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W4T6"
  },
  {
    "uniprot": "A0A044V737",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V737"
  },
  {
    "uniprot": "A0A044VDD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDD5"
  },
  {
    "uniprot": "A0A044RBP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBP4"
  },
  {
    "uniprot": "A0A044QNJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNJ9"
  },
  {
    "uniprot": "A0A044T3Q2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3Q2"
  },
  {
    "uniprot": "A0A044RIH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIH1"
  },
  {
    "uniprot": "A0A044U7H8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7H8"
  },
  {
    "uniprot": "A0A044TMB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMB8"
  },
  {
    "uniprot": "A0A2K6VEA8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VEA8"
  },
  {
    "uniprot": "A0A158N7W4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7W4"
  },
  {
    "uniprot": "A0A044V9J4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9J4"
  },
  {
    "uniprot": "A0A044SZ15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ15"
  },
  {
    "uniprot": "A0A044RDH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDH1"
  },
  {
    "uniprot": "A0A044RFG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFG0"
  },
  {
    "uniprot": "A0A044UVD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVD5"
  },
  {
    "uniprot": "A0A044R241",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R241"
  },
  {
    "uniprot": "A0A044UNU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNU4"
  },
  {
    "uniprot": "A0A044TEL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEL7"
  },
  {
    "uniprot": "A0A044RIN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIN5"
  },
  {
    "uniprot": "A0A044RL25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RL25"
  },
  {
    "uniprot": "A0A044QTF9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTF9"
  },
  {
    "uniprot": "A0A044UZ10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ10"
  },
  {
    "uniprot": "A0A044U159",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U159"
  },
  {
    "uniprot": "A0A044V1D4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1D4"
  },
  {
    "uniprot": "A0A044TMM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMM0"
  },
  {
    "uniprot": "A0A044SDS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDS3"
  },
  {
    "uniprot": "A0A2K6VQD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQD7"
  },
  {
    "uniprot": "A0A044RQ88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQ88"
  },
  {
    "uniprot": "A0A044QPI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPI2"
  },
  {
    "uniprot": "A0A044SZS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZS4"
  },
  {
    "uniprot": "A0A2K6VPG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPG9"
  },
  {
    "uniprot": "A0A044R8M5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8M5"
  },
  {
    "uniprot": "A0A044SSE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSE9"
  },
  {
    "uniprot": "A0A044RK41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RK41"
  },
  {
    "uniprot": "A0A044R411",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R411"
  },
  {
    "uniprot": "A0A044UL15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL15"
  },
  {
    "uniprot": "A0A044T3Y9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3Y9"
  },
  {
    "uniprot": "A0A044REB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REB0"
  },
  {
    "uniprot": "A0A2K6VXR9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXR9"
  },
  {
    "uniprot": "A0A2K6VP90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VP90"
  },
  {
    "uniprot": "A0A044THY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THY3"
  },
  {
    "uniprot": "A0A044SAD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAD1"
  },
  {
    "uniprot": "A0A044UI88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI88"
  },
  {
    "uniprot": "A0A044U854",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U854"
  },
  {
    "uniprot": "A0A2K6VDK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDK4"
  },
  {
    "uniprot": "A0A044RM92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RM92"
  },
  {
    "uniprot": "A0A044V7N3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7N3"
  },
  {
    "uniprot": "A0A044RB03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RB03"
  },
  {
    "uniprot": "A0A044SPR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPR7"
  },
  {
    "uniprot": "A0A044R1G3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1G3"
  },
  {
    "uniprot": "A0A044U3E2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3E2"
  },
  {
    "uniprot": "A0A044QT90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT90"
  },
  {
    "uniprot": "A0A044QVA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVA4"
  },
  {
    "uniprot": "A0A2K6VG06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VG06"
  },
  {
    "uniprot": "A0A044UTP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTP2"
  },
  {
    "uniprot": "A0A044QUF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUF5"
  },
  {
    "uniprot": "A0A044V1C9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1C9"
  },
  {
    "uniprot": "A0A044VD96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD96"
  },
  {
    "uniprot": "A0A044R8X3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8X3"
  },
  {
    "uniprot": "A0A044UL82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL82"
  },
  {
    "uniprot": "A0A044RF53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RF53"
  },
  {
    "uniprot": "A0A158N7R5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7R5"
  },
  {
    "uniprot": "A0A2K6W851",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W851"
  },
  {
    "uniprot": "A0A044THK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THK8"
  },
  {
    "uniprot": "A0A044SD32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD32"
  },
  {
    "uniprot": "A0A044RW46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW46"
  },
  {
    "uniprot": "A0A044V2X4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2X4"
  },
  {
    "uniprot": "A0A044R3G0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3G0"
  },
  {
    "uniprot": "A0A2K6WJ00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJ00"
  },
  {
    "uniprot": "A0A044SHU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHU9"
  },
  {
    "uniprot": "A0A2K6VN05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VN05"
  },
  {
    "uniprot": "A0A044TDR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDR3"
  },
  {
    "uniprot": "A0A158N8A5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8A5"
  },
  {
    "uniprot": "A0A158N804",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N804"
  },
  {
    "uniprot": "A0A044UT57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT57"
  },
  {
    "uniprot": "A0A044T980",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T980"
  },
  {
    "uniprot": "A0A044TPU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPU3"
  },
  {
    "uniprot": "A0A044RJA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJA9"
  },
  {
    "uniprot": "A0A044R4S4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4S4"
  },
  {
    "uniprot": "A0A044SF54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SF54"
  },
  {
    "uniprot": "A0A044SQP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQP3"
  },
  {
    "uniprot": "A0A044UKF9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKF9"
  },
  {
    "uniprot": "A0A044UF77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF77"
  },
  {
    "uniprot": "A0A044SBZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBZ3"
  },
  {
    "uniprot": "A0A2K6W1X2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1X2"
  },
  {
    "uniprot": "A0A044RPG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPG1"
  },
  {
    "uniprot": "A0A044U593",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U593"
  },
  {
    "uniprot": "P29779",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P29779"
  },
  {
    "uniprot": "A0A044QLM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLM8"
  },
  {
    "uniprot": "A0A044VD66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD66"
  },
  {
    "uniprot": "A0A044SMZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMZ4"
  },
  {
    "uniprot": "A0A044RAH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAH9"
  },
  {
    "uniprot": "A0A044R0I4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0I4"
  },
  {
    "uniprot": "A0A044RH19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH19"
  },
  {
    "uniprot": "A0A044R7Z7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7Z7"
  },
  {
    "uniprot": "A0A044U6J8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6J8"
  },
  {
    "uniprot": "A0A044TGC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGC4"
  },
  {
    "uniprot": "A0A044V428",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V428"
  },
  {
    "uniprot": "A0A044S443",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S443"
  },
  {
    "uniprot": "A0A2K6VK87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VK87"
  },
  {
    "uniprot": "A0A044V109",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V109"
  },
  {
    "uniprot": "A0A044R921",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R921"
  },
  {
    "uniprot": "A0A044U9H3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9H3"
  },
  {
    "uniprot": "A0A2K6WLL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLL9"
  },
  {
    "uniprot": "A0A044QRM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRM5"
  },
  {
    "uniprot": "A0A044TAB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAB1"
  },
  {
    "uniprot": "A0A044R9L9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9L9"
  },
  {
    "uniprot": "A0A044V990",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V990"
  },
  {
    "uniprot": "A0A044TK90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK90"
  },
  {
    "uniprot": "A0A044RYK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYK2"
  },
  {
    "uniprot": "A0A044V2M9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2M9"
  },
  {
    "uniprot": "A0A044QWB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWB3"
  },
  {
    "uniprot": "A0A044U878",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U878"
  },
  {
    "uniprot": "A0A158N8D4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8D4"
  },
  {
    "uniprot": "A0A044V643",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V643"
  },
  {
    "uniprot": "A0A044UY74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UY74"
  },
  {
    "uniprot": "A0A044V8Y4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Y4"
  },
  {
    "uniprot": "A0A044R1B5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1B5"
  },
  {
    "uniprot": "A0A044VCR9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCR9"
  },
  {
    "uniprot": "A0A044RHM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHM5"
  },
  {
    "uniprot": "A0A044R999",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R999"
  },
  {
    "uniprot": "A0A044R672",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R672"
  },
  {
    "uniprot": "A0A044SB24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB24"
  },
  {
    "uniprot": "A0A044SV42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SV42"
  },
  {
    "uniprot": "A0A044SCM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCM5"
  },
  {
    "uniprot": "A0A044SN10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN10"
  },
  {
    "uniprot": "A0A044R1F7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1F7"
  },
  {
    "uniprot": "A0A044U4V5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4V5"
  },
  {
    "uniprot": "A0A044VHN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHN8"
  },
  {
    "uniprot": "A0A044T4X8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4X8"
  },
  {
    "uniprot": "A0A158N8G6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8G6"
  },
  {
    "uniprot": "A0A044TWA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWA3"
  },
  {
    "uniprot": "A0A044TZT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZT5"
  },
  {
    "uniprot": "A0A044TGN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGN1"
  },
  {
    "uniprot": "A0A044R3N4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3N4"
  },
  {
    "uniprot": "A0A044V410",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V410"
  },
  {
    "uniprot": "A0A044UEJ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEJ5"
  },
  {
    "uniprot": "A0A044VBF9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBF9"
  },
  {
    "uniprot": "A0A2K6WBS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBS6"
  },
  {
    "uniprot": "A0A044U2M2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2M2"
  },
  {
    "uniprot": "A0A044RI82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RI82"
  },
  {
    "uniprot": "A0A044VGT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGT6"
  },
  {
    "uniprot": "A0A044U6S3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6S3"
  },
  {
    "uniprot": "A0A044V4B2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4B2"
  },
  {
    "uniprot": "A0A044V5Z1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5Z1"
  },
  {
    "uniprot": "A0A044R355",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R355"
  },
  {
    "uniprot": "A0A044T836",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T836"
  },
  {
    "uniprot": "A0A2K6W3M1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3M1"
  },
  {
    "uniprot": "A0A044VJ48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJ48"
  },
  {
    "uniprot": "A0A044T6B8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6B8"
  },
  {
    "uniprot": "A0A2K6VE81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VE81"
  },
  {
    "uniprot": "A0A2K6VT82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VT82"
  },
  {
    "uniprot": "A0A044QMF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMF1"
  },
  {
    "uniprot": "A0A044SB85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB85"
  },
  {
    "uniprot": "A0A044SYL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYL1"
  },
  {
    "uniprot": "A0A044VB74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VB74"
  },
  {
    "uniprot": "A0A044SNH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNH5"
  },
  {
    "uniprot": "A0A044TQP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQP1"
  },
  {
    "uniprot": "A0A044TFY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFY2"
  },
  {
    "uniprot": "A0A044UV38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UV38"
  },
  {
    "uniprot": "A0A044TKF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKF0"
  },
  {
    "uniprot": "A0A044TAV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAV6"
  },
  {
    "uniprot": "A0A044RP74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RP74"
  },
  {
    "uniprot": "A0A044V8K6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8K6"
  },
  {
    "uniprot": "A0A044S3Y1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3Y1"
  },
  {
    "uniprot": "A0A044REN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REN1"
  },
  {
    "uniprot": "A0A044TZY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZY9"
  },
  {
    "uniprot": "A0A044R021",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R021"
  },
  {
    "uniprot": "A0A044V5L3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5L3"
  },
  {
    "uniprot": "A0A044V243",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V243"
  },
  {
    "uniprot": "A0A044RXR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXR8"
  },
  {
    "uniprot": "A0A044TNT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNT7"
  },
  {
    "uniprot": "A0A044V751",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V751"
  },
  {
    "uniprot": "A0A044T2X2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2X2"
  },
  {
    "uniprot": "A0A2K6VMM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMM0"
  },
  {
    "uniprot": "A0A044U313",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U313"
  },
  {
    "uniprot": "A0A044UMT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMT7"
  },
  {
    "uniprot": "A0A044RE92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RE92"
  },
  {
    "uniprot": "A0A044UKU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKU4"
  },
  {
    "uniprot": "A0A044SQ17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ17"
  },
  {
    "uniprot": "A0A044U180",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U180"
  },
  {
    "uniprot": "A0A044T3T4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3T4"
  },
  {
    "uniprot": "A0A044SLX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLX7"
  },
  {
    "uniprot": "A0A044SQV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQV5"
  },
  {
    "uniprot": "A0A044SH33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SH33"
  },
  {
    "uniprot": "A0A044T707",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T707"
  },
  {
    "uniprot": "A0A044RR92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RR92"
  },
  {
    "uniprot": "A0A044QT28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT28"
  },
  {
    "uniprot": "A0A044S6L7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6L7"
  },
  {
    "uniprot": "A0A044SZ03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ03"
  },
  {
    "uniprot": "A0A044TQT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQT7"
  },
  {
    "uniprot": "A0A044UNW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNW2"
  },
  {
    "uniprot": "A0A044RMB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMB1"
  },
  {
    "uniprot": "A0A044TFX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFX9"
  },
  {
    "uniprot": "A0A044V3S5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3S5"
  },
  {
    "uniprot": "A0A044TR31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR31"
  },
  {
    "uniprot": "A0A044S450",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S450"
  },
  {
    "uniprot": "A0A044QK24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK24"
  },
  {
    "uniprot": "A0A044V979",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V979"
  },
  {
    "uniprot": "A0A044VIQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIQ0"
  },
  {
    "uniprot": "A0A044T896",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T896"
  },
  {
    "uniprot": "A0A2K6WK78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WK78"
  },
  {
    "uniprot": "A0A044V3I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3I9"
  },
  {
    "uniprot": "A0A044TYD9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYD9"
  },
  {
    "uniprot": "A0A044U9E7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9E7"
  },
  {
    "uniprot": "A0A044TTW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTW8"
  },
  {
    "uniprot": "A0A044VBY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBY7"
  },
  {
    "uniprot": "A0A044TQY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQY9"
  },
  {
    "uniprot": "A0A2K6VXD9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXD9"
  },
  {
    "uniprot": "A0A044RHY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHY6"
  },
  {
    "uniprot": "A0A044SR31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SR31"
  },
  {
    "uniprot": "A0A044QU84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QU84"
  },
  {
    "uniprot": "Q9U9A1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q9U9A1"
  },
  {
    "uniprot": "A0A044UH93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UH93"
  },
  {
    "uniprot": "A0A044T1Y5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1Y5"
  },
  {
    "uniprot": "A0A044SCV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCV2"
  },
  {
    "uniprot": "A0A044VET6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VET6"
  },
  {
    "uniprot": "A0A044THN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THN2"
  },
  {
    "uniprot": "A0A2K6VKL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKL0"
  },
  {
    "uniprot": "A0A044R9I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9I9"
  },
  {
    "uniprot": "A0A044RAQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAQ7"
  },
  {
    "uniprot": "A0A044V099",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V099"
  },
  {
    "uniprot": "A0A044SVN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVN9"
  },
  {
    "uniprot": "A0A044T3V8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3V8"
  },
  {
    "uniprot": "A0A044TH16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH16"
  },
  {
    "uniprot": "A0A044TIL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIL8"
  },
  {
    "uniprot": "A0A044RPT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPT5"
  },
  {
    "uniprot": "A0A044U5J7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5J7"
  },
  {
    "uniprot": "A0A044U7U2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7U2"
  },
  {
    "uniprot": "A0A044R4T2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4T2"
  },
  {
    "uniprot": "A0A2K6VS32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VS32"
  },
  {
    "uniprot": "A0A044QX78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QX78"
  },
  {
    "uniprot": "A0A044U1P2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1P2"
  },
  {
    "uniprot": "A0A044V987",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V987"
  },
  {
    "uniprot": "A0A044RFI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFI7"
  },
  {
    "uniprot": "A0A044V6Y9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Y9"
  },
  {
    "uniprot": "A0A044RYF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYF8"
  },
  {
    "uniprot": "A0A044S257",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S257"
  },
  {
    "uniprot": "A0A044VCZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCZ2"
  },
  {
    "uniprot": "A0A044UF24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF24"
  },
  {
    "uniprot": "A0A044UX61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX61"
  },
  {
    "uniprot": "A0A044TN10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN10"
  },
  {
    "uniprot": "A0A044SG93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG93"
  },
  {
    "uniprot": "A0A044V9I2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9I2"
  },
  {
    "uniprot": "A0A044RGR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGR7"
  },
  {
    "uniprot": "A0A044TFB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFB3"
  },
  {
    "uniprot": "A0A044QYJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYJ7"
  },
  {
    "uniprot": "A0A044UAF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAF4"
  },
  {
    "uniprot": "A0A044V6L9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6L9"
  },
  {
    "uniprot": "A0A044RBL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBL6"
  },
  {
    "uniprot": "A0A044SPB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPB7"
  },
  {
    "uniprot": "A0A044VCN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCN4"
  },
  {
    "uniprot": "A0A044TF36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TF36"
  },
  {
    "uniprot": "A0A044TN05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN05"
  },
  {
    "uniprot": "A0A2K6VWZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWZ5"
  },
  {
    "uniprot": "A0A044VGE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGE2"
  },
  {
    "uniprot": "A0A044T3Z5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3Z5"
  },
  {
    "uniprot": "A0A044USY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USY5"
  },
  {
    "uniprot": "A0A044QWT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWT8"
  },
  {
    "uniprot": "A0A2K6VX95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VX95"
  },
  {
    "uniprot": "A0A044R887",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R887"
  },
  {
    "uniprot": "A0A044R783",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R783"
  },
  {
    "uniprot": "A0A2K6VWZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWZ6"
  },
  {
    "uniprot": "A0A044QYQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYQ5"
  },
  {
    "uniprot": "A0A2K6WMI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMI1"
  },
  {
    "uniprot": "A0A044RWY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWY9"
  },
  {
    "uniprot": "A0A044SK57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK57"
  },
  {
    "uniprot": "A0A2K6WJA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJA5"
  },
  {
    "uniprot": "A0A044SM85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM85"
  },
  {
    "uniprot": "A0A044V697",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V697"
  },
  {
    "uniprot": "A0A044U9P9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9P9"
  },
  {
    "uniprot": "A0A044TC63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC63"
  },
  {
    "uniprot": "A0A2K6VSR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSR6"
  },
  {
    "uniprot": "A0A044T7A8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7A8"
  },
  {
    "uniprot": "A0A2K6WKJ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKJ8"
  },
  {
    "uniprot": "A0A2K6VXF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXF0"
  },
  {
    "uniprot": "A0A2K6VPL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPL7"
  },
  {
    "uniprot": "A0A2K6VG68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VG68"
  },
  {
    "uniprot": "A0A044SAN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAN5"
  },
  {
    "uniprot": "A0A044T288",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T288"
  },
  {
    "uniprot": "A0A044V1B2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1B2"
  },
  {
    "uniprot": "A0A044TDC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDC8"
  },
  {
    "uniprot": "A0A2K6WH95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WH95"
  },
  {
    "uniprot": "A0A044TUS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUS5"
  },
  {
    "uniprot": "A0A044V713",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V713"
  },
  {
    "uniprot": "A0A2K6WFE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFE5"
  },
  {
    "uniprot": "A0A044TFX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFX4"
  },
  {
    "uniprot": "A0A2K6VIT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIT5"
  },
  {
    "uniprot": "A0A044SUB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUB3"
  },
  {
    "uniprot": "A0A044RN61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RN61"
  },
  {
    "uniprot": "A0A044REN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REN4"
  },
  {
    "uniprot": "A0A044RSS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSS6"
  },
  {
    "uniprot": "A0A044S0U5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0U5"
  },
  {
    "uniprot": "A0A044UR11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UR11"
  },
  {
    "uniprot": "A0A044TWY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWY7"
  },
  {
    "uniprot": "A0A2K6WEX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEX1"
  },
  {
    "uniprot": "A0A044VDV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDV8"
  },
  {
    "uniprot": "A0A044UFL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFL0"
  },
  {
    "uniprot": "A0A044SN93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN93"
  },
  {
    "uniprot": "A0A044R5H6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5H6"
  },
  {
    "uniprot": "A0A044T805",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T805"
  },
  {
    "uniprot": "A0A158N7X2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7X2"
  },
  {
    "uniprot": "A0A044V9D2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9D2"
  },
  {
    "uniprot": "A0A044U5M5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5M5"
  },
  {
    "uniprot": "A0A044VBE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBE5"
  },
  {
    "uniprot": "A0A044V5A5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5A5"
  },
  {
    "uniprot": "A0A044SEW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEW9"
  },
  {
    "uniprot": "A0A044UP09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UP09"
  },
  {
    "uniprot": "A0A044UCH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCH5"
  },
  {
    "uniprot": "A0A044THF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THF4"
  },
  {
    "uniprot": "A0A044V4F9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4F9"
  },
  {
    "uniprot": "A0A044UMW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMW8"
  },
  {
    "uniprot": "A0A044VEY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEY9"
  },
  {
    "uniprot": "A0A044RLV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLV6"
  },
  {
    "uniprot": "A0A044SGZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGZ9"
  },
  {
    "uniprot": "A0A044TSQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSQ3"
  },
  {
    "uniprot": "A0A044UVF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVF2"
  },
  {
    "uniprot": "A0A044RJX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJX1"
  },
  {
    "uniprot": "A0A044QUR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUR1"
  },
  {
    "uniprot": "A0A044UGE8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGE8"
  },
  {
    "uniprot": "A0A044UI98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI98"
  },
  {
    "uniprot": "A0A044VH73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH73"
  },
  {
    "uniprot": "P21249",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P21249"
  },
  {
    "uniprot": "A0A044TV33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV33"
  },
  {
    "uniprot": "A0A044TCV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCV3"
  },
  {
    "uniprot": "A0A044UT65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT65"
  },
  {
    "uniprot": "A0A2K6WJ29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJ29"
  },
  {
    "uniprot": "A0A044TPI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPI7"
  },
  {
    "uniprot": "A0A044UEZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEZ7"
  },
  {
    "uniprot": "A0A044RP61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RP61"
  },
  {
    "uniprot": "A0A044THS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THS7"
  },
  {
    "uniprot": "A0A044TGX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGX1"
  },
  {
    "uniprot": "A0A044RVE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVE6"
  },
  {
    "uniprot": "A0A044R426",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R426"
  },
  {
    "uniprot": "A0A2K6VJD2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJD2"
  },
  {
    "uniprot": "A0A044TU27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TU27"
  },
  {
    "uniprot": "A0A044QKP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKP3"
  },
  {
    "uniprot": "A0A044V9P7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9P7"
  },
  {
    "uniprot": "A0A044RF22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RF22"
  },
  {
    "uniprot": "A0A044V3R2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3R2"
  },
  {
    "uniprot": "A0A044V511",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V511"
  },
  {
    "uniprot": "A0A2K6WD96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WD96"
  },
  {
    "uniprot": "A0A044SBA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBA6"
  },
  {
    "uniprot": "A0A044RCZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCZ9"
  },
  {
    "uniprot": "A0A044RXB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXB1"
  },
  {
    "uniprot": "A0A044RN74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RN74"
  },
  {
    "uniprot": "A0A044QYM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYM7"
  },
  {
    "uniprot": "A0A044UYZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYZ7"
  },
  {
    "uniprot": "A0A044TYK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYK1"
  },
  {
    "uniprot": "A0A044RBU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBU7"
  },
  {
    "uniprot": "A0A044QP31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP31"
  },
  {
    "uniprot": "A0A044TJ90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJ90"
  },
  {
    "uniprot": "A0A044V9D5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9D5"
  },
  {
    "uniprot": "A0A044UKP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKP3"
  },
  {
    "uniprot": "A0A2K6VMR9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMR9"
  },
  {
    "uniprot": "A0A044QZ26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ26"
  },
  {
    "uniprot": "A0A044S4H7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4H7"
  },
  {
    "uniprot": "A0A158N7Y7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Y7"
  },
  {
    "uniprot": "A0A044TMV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMV5"
  },
  {
    "uniprot": "A0A044TXP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXP8"
  },
  {
    "uniprot": "A0A044TQW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQW3"
  },
  {
    "uniprot": "A0A044TQT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQT1"
  },
  {
    "uniprot": "A0A044R803",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R803"
  },
  {
    "uniprot": "A0A2K6WEV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEV9"
  },
  {
    "uniprot": "A0A044RES0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RES0"
  },
  {
    "uniprot": "A0A044VGD4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGD4"
  },
  {
    "uniprot": "A0A2K6WLN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLN8"
  },
  {
    "uniprot": "A0A044UJE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJE5"
  },
  {
    "uniprot": "A0A044VBN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBN1"
  },
  {
    "uniprot": "A0A044QNV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNV7"
  },
  {
    "uniprot": "A0A044V5X7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5X7"
  },
  {
    "uniprot": "A0A044SB82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB82"
  },
  {
    "uniprot": "A0A044UVX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVX5"
  },
  {
    "uniprot": "A0A044QL23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL23"
  },
  {
    "uniprot": "A0A044R257",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R257"
  },
  {
    "uniprot": "A0A044UQB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQB4"
  },
  {
    "uniprot": "A0A044VIR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIR6"
  },
  {
    "uniprot": "A0A044QNA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNA1"
  },
  {
    "uniprot": "A0A044TEM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEM6"
  },
  {
    "uniprot": "A0A044QZD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZD5"
  },
  {
    "uniprot": "A0A2K6VRL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRL2"
  },
  {
    "uniprot": "A0A2K6W579",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W579"
  },
  {
    "uniprot": "A0A2K6WMX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMX9"
  },
  {
    "uniprot": "P46427",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P46427"
  },
  {
    "uniprot": "A0A044R8S4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8S4"
  },
  {
    "uniprot": "A0A044RUR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUR3"
  },
  {
    "uniprot": "A0A044UD06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UD06"
  },
  {
    "uniprot": "A0A044TLI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLI4"
  },
  {
    "uniprot": "A0A044S890",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S890"
  },
  {
    "uniprot": "A0A044RWS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWS9"
  },
  {
    "uniprot": "A0A044SMQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMQ5"
  },
  {
    "uniprot": "A0A044RE47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RE47"
  },
  {
    "uniprot": "A0A044QYN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYN3"
  },
  {
    "uniprot": "A0A044RTA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTA5"
  },
  {
    "uniprot": "A0A044R6Q2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6Q2"
  },
  {
    "uniprot": "A0A044U7D9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7D9"
  },
  {
    "uniprot": "A0A044QKB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKB7"
  },
  {
    "uniprot": "A0A044VED0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VED0"
  },
  {
    "uniprot": "A0A044R570",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R570"
  },
  {
    "uniprot": "A0A044T7J4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7J4"
  },
  {
    "uniprot": "A0A044VC74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC74"
  },
  {
    "uniprot": "A0A158N877",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N877"
  },
  {
    "uniprot": "A0A044V6P5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6P5"
  },
  {
    "uniprot": "A0A044RWL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWL0"
  },
  {
    "uniprot": "A0A044SND5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SND5"
  },
  {
    "uniprot": "A0A044V9S6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9S6"
  },
  {
    "uniprot": "A0A044TD40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TD40"
  },
  {
    "uniprot": "A0A044UE16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE16"
  },
  {
    "uniprot": "A0A044TRT3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRT3"
  },
  {
    "uniprot": "A0A044T7P0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7P0"
  },
  {
    "uniprot": "A0A044T4Z9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4Z9"
  },
  {
    "uniprot": "A0A044RJZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJZ0"
  },
  {
    "uniprot": "A0A044TPF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPF1"
  },
  {
    "uniprot": "A0A044RVD9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVD9"
  },
  {
    "uniprot": "A0A044UBI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBI4"
  },
  {
    "uniprot": "A0A044S3L4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3L4"
  },
  {
    "uniprot": "A0A044V5L7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5L7"
  },
  {
    "uniprot": "A0A2K6VW52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VW52"
  },
  {
    "uniprot": "A0A044S5I1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5I1"
  },
  {
    "uniprot": "A0A2K6WEF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEF3"
  },
  {
    "uniprot": "A0A044VG44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VG44"
  },
  {
    "uniprot": "A0A044QRJ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRJ8"
  },
  {
    "uniprot": "A0A044UPM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPM6"
  },
  {
    "uniprot": "A0A2K6W3Q7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3Q7"
  },
  {
    "uniprot": "A0A044REY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REY3"
  },
  {
    "uniprot": "A0A044RHP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHP2"
  },
  {
    "uniprot": "A0A044V919",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V919"
  },
  {
    "uniprot": "A0A044T1A2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1A2"
  },
  {
    "uniprot": "A0A044T7S1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7S1"
  },
  {
    "uniprot": "A0A044S6G4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6G4"
  },
  {
    "uniprot": "A0A044RF05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RF05"
  },
  {
    "uniprot": "A0A044V255",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V255"
  },
  {
    "uniprot": "A0A044UXJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXJ1"
  },
  {
    "uniprot": "A0A044STH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STH6"
  },
  {
    "uniprot": "A0A2K6VZC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZC1"
  },
  {
    "uniprot": "A0A044TES4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TES4"
  },
  {
    "uniprot": "A0A044U5Y0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5Y0"
  },
  {
    "uniprot": "A0A044VEE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEE4"
  },
  {
    "uniprot": "A0A044RVM9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVM9"
  },
  {
    "uniprot": "A0A044RLT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLT1"
  },
  {
    "uniprot": "A0A044SCC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCC3"
  },
  {
    "uniprot": "A0A044SXV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXV4"
  },
  {
    "uniprot": "A0A044RVF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVF6"
  },
  {
    "uniprot": "A0A044VDU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDU5"
  },
  {
    "uniprot": "A0A044UHJ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHJ8"
  },
  {
    "uniprot": "A0A2K6VFR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFR7"
  },
  {
    "uniprot": "A0A044UVE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVE3"
  },
  {
    "uniprot": "A0A044T9B0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9B0"
  },
  {
    "uniprot": "A0A044SMY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMY4"
  },
  {
    "uniprot": "A0A044SK56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK56"
  },
  {
    "uniprot": "A0A2K6WN39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WN39"
  },
  {
    "uniprot": "A0A044SQ33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ33"
  },
  {
    "uniprot": "A0A044TLF9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLF9"
  },
  {
    "uniprot": "A0A044V141",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V141"
  },
  {
    "uniprot": "A0A044T880",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T880"
  },
  {
    "uniprot": "A0A158N8D3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8D3"
  },
  {
    "uniprot": "A0A044R123",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R123"
  },
  {
    "uniprot": "A0A044SNA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNA6"
  },
  {
    "uniprot": "A0A044VAL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAL3"
  },
  {
    "uniprot": "A0A044S0I3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0I3"
  },
  {
    "uniprot": "A0A2K6VEA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VEA7"
  },
  {
    "uniprot": "A0A044TYZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYZ2"
  },
  {
    "uniprot": "A0A044V4E6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4E6"
  },
  {
    "uniprot": "A0A044T6B4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6B4"
  },
  {
    "uniprot": "A0A2K6WJK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJK2"
  },
  {
    "uniprot": "A0A044SKB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKB9"
  },
  {
    "uniprot": "A0A044UZ20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ20"
  },
  {
    "uniprot": "A0A044U4J4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4J4"
  },
  {
    "uniprot": "A0A044SAT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAT9"
  },
  {
    "uniprot": "A0A044URL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URL5"
  },
  {
    "uniprot": "A0A2K6VZR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZR2"
  },
  {
    "uniprot": "A0A044UQF7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQF7"
  },
  {
    "uniprot": "A0A044U3D7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3D7"
  },
  {
    "uniprot": "A0A044VDX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDX1"
  },
  {
    "uniprot": "A0A044QRJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRJ7"
  },
  {
    "uniprot": "A0A044QUT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUT4"
  },
  {
    "uniprot": "A0A044R161",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R161"
  },
  {
    "uniprot": "A0A2K6VEU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VEU1"
  },
  {
    "uniprot": "A0A044RCR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCR8"
  },
  {
    "uniprot": "A0A044UG09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UG09"
  },
  {
    "uniprot": "A0A044TWP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWP7"
  },
  {
    "uniprot": "A0A044REM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REM0"
  },
  {
    "uniprot": "A0A044R5Q6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5Q6"
  },
  {
    "uniprot": "A0A044SQH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQH2"
  },
  {
    "uniprot": "A0A044SYK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYK6"
  },
  {
    "uniprot": "A0A044UT16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT16"
  },
  {
    "uniprot": "A0A044VJB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJB0"
  },
  {
    "uniprot": "A0A044TXU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXU7"
  },
  {
    "uniprot": "A0A044SKN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKN0"
  },
  {
    "uniprot": "A0A044S0D1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0D1"
  },
  {
    "uniprot": "A0A044UB45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UB45"
  },
  {
    "uniprot": "A0A044V472",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V472"
  },
  {
    "uniprot": "A0A2K6VVD0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVD0"
  },
  {
    "uniprot": "A0A044RA72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RA72"
  },
  {
    "uniprot": "A0A044V565",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V565"
  },
  {
    "uniprot": "A0A044VBR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBR7"
  },
  {
    "uniprot": "A0A044URA8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URA8"
  },
  {
    "uniprot": "A0A044TLH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLH2"
  },
  {
    "uniprot": "A0A044RZK7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZK7"
  },
  {
    "uniprot": "A0A044ULL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULL6"
  },
  {
    "uniprot": "A0A044SS40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS40"
  },
  {
    "uniprot": "A0A044T0T8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0T8"
  },
  {
    "uniprot": "A0A2K6VXY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXY5"
  },
  {
    "uniprot": "A0A044RSC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSC8"
  },
  {
    "uniprot": "A0A044TDJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDJ7"
  },
  {
    "uniprot": "A0A044U2X0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2X0"
  },
  {
    "uniprot": "A0A158N8T3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8T3"
  },
  {
    "uniprot": "A0A044UFE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFE0"
  },
  {
    "uniprot": "A0A044T8X7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8X7"
  },
  {
    "uniprot": "A0A044SAN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAN2"
  },
  {
    "uniprot": "A0A044R2E9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2E9"
  },
  {
    "uniprot": "A0A044SY86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY86"
  },
  {
    "uniprot": "A0A044UNA8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNA8"
  },
  {
    "uniprot": "A0A044U0U9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0U9"
  },
  {
    "uniprot": "A0A2K6WLZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLZ8"
  },
  {
    "uniprot": "A0A044VH32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH32"
  },
  {
    "uniprot": "A0A044UG55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UG55"
  },
  {
    "uniprot": "A0A044V1D8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1D8"
  },
  {
    "uniprot": "A0A2K6W9N3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9N3"
  },
  {
    "uniprot": "A0A044V427",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V427"
  },
  {
    "uniprot": "A0A2K6VT58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VT58"
  },
  {
    "uniprot": "A0A044VID2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VID2"
  },
  {
    "uniprot": "A0A044U5X1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5X1"
  },
  {
    "uniprot": "A0A044V9E0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9E0"
  },
  {
    "uniprot": "A0A044QU01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QU01"
  },
  {
    "uniprot": "A0A044T2T9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2T9"
  },
  {
    "uniprot": "A0A044VF98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VF98"
  },
  {
    "uniprot": "A0A044V537",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V537"
  },
  {
    "uniprot": "A0A044TDW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDW3"
  },
  {
    "uniprot": "A0A044V801",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V801"
  },
  {
    "uniprot": "A0A2K6WEW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEW9"
  },
  {
    "uniprot": "A0A044RI20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RI20"
  },
  {
    "uniprot": "A0A044V4U5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4U5"
  },
  {
    "uniprot": "A0A044RUL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUL6"
  },
  {
    "uniprot": "A0A044QSN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSN6"
  },
  {
    "uniprot": "A0A2K6VP94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VP94"
  },
  {
    "uniprot": "A0A044VBT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBT7"
  },
  {
    "uniprot": "A0A044U120",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U120"
  },
  {
    "uniprot": "A0A044SL71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SL71"
  },
  {
    "uniprot": "A0A044V170",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V170"
  },
  {
    "uniprot": "A0A044U053",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U053"
  },
  {
    "uniprot": "A0A044VIP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIP4"
  },
  {
    "uniprot": "A0A044TS75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS75"
  },
  {
    "uniprot": "A0A044RZS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZS5"
  },
  {
    "uniprot": "A0A044SL23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SL23"
  },
  {
    "uniprot": "A0A158N880",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N880"
  },
  {
    "uniprot": "A0A044UYT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYT5"
  },
  {
    "uniprot": "A0A044RZF9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZF9"
  },
  {
    "uniprot": "A0A044RCE8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCE8"
  },
  {
    "uniprot": "A0A044RSX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSX7"
  },
  {
    "uniprot": "A0A044SLJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLJ7"
  },
  {
    "uniprot": "A0A044TK27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK27"
  },
  {
    "uniprot": "A0A044SVF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVF4"
  },
  {
    "uniprot": "A0A158N821",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N821"
  },
  {
    "uniprot": "A0A044UCJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCJ4"
  },
  {
    "uniprot": "A0A044QX91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QX91"
  },
  {
    "uniprot": "A0A044SVJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVJ9"
  },
  {
    "uniprot": "A0A2K6VMZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMZ2"
  },
  {
    "uniprot": "A0A044QXW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXW6"
  },
  {
    "uniprot": "A0A044TF60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TF60"
  },
  {
    "uniprot": "A0A044RW93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW93"
  },
  {
    "uniprot": "A0A044SLU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLU3"
  },
  {
    "uniprot": "A0A044RI25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RI25"
  },
  {
    "uniprot": "A0A044UNN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNN2"
  },
  {
    "uniprot": "A0A044TIW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIW5"
  },
  {
    "uniprot": "A0A044UNT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNT5"
  },
  {
    "uniprot": "A0A044VH89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH89"
  },
  {
    "uniprot": "A0A044RD81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RD81"
  },
  {
    "uniprot": "A0A044UZP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZP3"
  },
  {
    "uniprot": "A0A044RAH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAH2"
  },
  {
    "uniprot": "A0A044R5C4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5C4"
  },
  {
    "uniprot": "A0A044U6J2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6J2"
  },
  {
    "uniprot": "A0A044U536",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U536"
  },
  {
    "uniprot": "A0A044RHH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHH0"
  },
  {
    "uniprot": "A0A044V2S4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2S4"
  },
  {
    "uniprot": "A0A044UFF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFF5"
  },
  {
    "uniprot": "A0A044S250",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S250"
  },
  {
    "uniprot": "A0A044S1C5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1C5"
  },
  {
    "uniprot": "A0A044U1C0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1C0"
  },
  {
    "uniprot": "A0A044VGX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGX9"
  },
  {
    "uniprot": "A0A044RP87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RP87"
  },
  {
    "uniprot": "A0A044RQ05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQ05"
  },
  {
    "uniprot": "A0A044RGL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGL8"
  },
  {
    "uniprot": "A0A044V4C7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4C7"
  },
  {
    "uniprot": "A0A2K6WDQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDQ3"
  },
  {
    "uniprot": "A0A044UNS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNS0"
  },
  {
    "uniprot": "A0A044U8L1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8L1"
  },
  {
    "uniprot": "A0A044TD30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TD30"
  },
  {
    "uniprot": "A0A044SU92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU92"
  },
  {
    "uniprot": "A0A2K6VWD9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWD9"
  },
  {
    "uniprot": "A0A044V3K5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3K5"
  },
  {
    "uniprot": "A0A044QSX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSX1"
  },
  {
    "uniprot": "A0A044U104",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U104"
  },
  {
    "uniprot": "A0A044RVL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVL5"
  },
  {
    "uniprot": "A0A044V8F2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8F2"
  },
  {
    "uniprot": "A0A044TY51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TY51"
  },
  {
    "uniprot": "A0A044V967",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V967"
  },
  {
    "uniprot": "A0A044RTW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTW4"
  },
  {
    "uniprot": "A0A044RZM9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZM9"
  },
  {
    "uniprot": "A0A044V9A5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9A5"
  },
  {
    "uniprot": "A0A044UAU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAU7"
  },
  {
    "uniprot": "A0A044RT52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RT52"
  },
  {
    "uniprot": "A0A044T1A8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1A8"
  },
  {
    "uniprot": "A0A044V173",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V173"
  },
  {
    "uniprot": "A0A044SD85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD85"
  },
  {
    "uniprot": "A0A158N8J1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8J1"
  },
  {
    "uniprot": "A0A044V9M1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9M1"
  },
  {
    "uniprot": "A0A044TAJ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAJ8"
  },
  {
    "uniprot": "A0A044S759",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S759"
  },
  {
    "uniprot": "A0A044UA45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA45"
  },
  {
    "uniprot": "A0A044VHZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHZ6"
  },
  {
    "uniprot": "A0A044V6Z8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Z8"
  },
  {
    "uniprot": "A0A044TUH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUH2"
  },
  {
    "uniprot": "A0A2K6VYI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYI7"
  },
  {
    "uniprot": "A0A044SLU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLU7"
  },
  {
    "uniprot": "A0A044RUC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUC3"
  },
  {
    "uniprot": "A0A044T6C2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6C2"
  },
  {
    "uniprot": "A0A2K6VVX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVX5"
  },
  {
    "uniprot": "A0A044UMH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMH8"
  },
  {
    "uniprot": "A0A044UB28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UB28"
  },
  {
    "uniprot": "A0A044R5R0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5R0"
  },
  {
    "uniprot": "A0A044TVK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVK3"
  },
  {
    "uniprot": "A0A044U8H1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8H1"
  },
  {
    "uniprot": "A0A044UH65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UH65"
  },
  {
    "uniprot": "A0A044RFC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFC3"
  },
  {
    "uniprot": "A0A044R891",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R891"
  },
  {
    "uniprot": "A0A044TH92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH92"
  },
  {
    "uniprot": "A0A044TJB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJB8"
  },
  {
    "uniprot": "A0A044SUF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUF8"
  },
  {
    "uniprot": "A0A044SYE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYE9"
  },
  {
    "uniprot": "A0A044QML7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QML7"
  },
  {
    "uniprot": "A0A044T320",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T320"
  },
  {
    "uniprot": "A0A044T3S1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3S1"
  },
  {
    "uniprot": "A0A044V5Y3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5Y3"
  },
  {
    "uniprot": "A0A044SJE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJE4"
  },
  {
    "uniprot": "A0A044SGT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGT1"
  },
  {
    "uniprot": "A0A2K6WGT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGT9"
  },
  {
    "uniprot": "A0A044RZE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZE7"
  },
  {
    "uniprot": "A0A044QP79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP79"
  },
  {
    "uniprot": "A0A044S0G8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0G8"
  },
  {
    "uniprot": "A0A044V4V6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4V6"
  },
  {
    "uniprot": "A0A044TDE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDE5"
  },
  {
    "uniprot": "A0A044SEK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEK9"
  },
  {
    "uniprot": "A0A044SUG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUG6"
  },
  {
    "uniprot": "A0A044TDV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDV2"
  },
  {
    "uniprot": "A0A2K6VT49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VT49"
  },
  {
    "uniprot": "A0A2K6WF88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WF88"
  },
  {
    "uniprot": "A0A044S119",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S119"
  },
  {
    "uniprot": "A0A044TF30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TF30"
  },
  {
    "uniprot": "A0A044V945",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V945"
  },
  {
    "uniprot": "A0A044UCC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCC1"
  },
  {
    "uniprot": "A0A158N7W1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7W1"
  },
  {
    "uniprot": "A0A044S5N3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5N3"
  },
  {
    "uniprot": "A0A044RFV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFV6"
  },
  {
    "uniprot": "A0A044V487",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V487"
  },
  {
    "uniprot": "A0A044UGS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGS7"
  },
  {
    "uniprot": "A0A044SJA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJA4"
  },
  {
    "uniprot": "A0A2K6W776",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W776"
  },
  {
    "uniprot": "A0A044V1C1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1C1"
  },
  {
    "uniprot": "A0A044RXA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXA6"
  },
  {
    "uniprot": "A0A044R1Z7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1Z7"
  },
  {
    "uniprot": "A0A2K6WGE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGE0"
  },
  {
    "uniprot": "A0A044S5J6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5J6"
  },
  {
    "uniprot": "A0A158N812",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N812"
  },
  {
    "uniprot": "A0A044S7B9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7B9"
  },
  {
    "uniprot": "A0A044T5A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5A9"
  },
  {
    "uniprot": "A0A044SU50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU50"
  },
  {
    "uniprot": "A0A044RRI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRI2"
  },
  {
    "uniprot": "A0A044UBF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBF5"
  },
  {
    "uniprot": "A0A044QZN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZN9"
  },
  {
    "uniprot": "A0A2K6WEE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEE6"
  },
  {
    "uniprot": "A0A044QLS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLS4"
  },
  {
    "uniprot": "A0A044VFQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFQ3"
  },
  {
    "uniprot": "A0A044SWX0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWX0"
  },
  {
    "uniprot": "A0A044RPZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPZ8"
  },
  {
    "uniprot": "A0A044QRY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRY0"
  },
  {
    "uniprot": "A0A044S4A8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4A8"
  },
  {
    "uniprot": "A0A044R1B0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1B0"
  },
  {
    "uniprot": "A0A044TMV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMV3"
  },
  {
    "uniprot": "A0A2K6VLD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLD5"
  },
  {
    "uniprot": "A0A044T7K1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7K1"
  },
  {
    "uniprot": "A0A044S1U4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1U4"
  },
  {
    "uniprot": "A0A2K6VR63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VR63"
  },
  {
    "uniprot": "A0A044RGW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGW5"
  },
  {
    "uniprot": "A0A044U8B8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8B8"
  },
  {
    "uniprot": "A0A2K6WAT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAT4"
  },
  {
    "uniprot": "A0A044SXZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXZ5"
  },
  {
    "uniprot": "A0A044S2S0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2S0"
  },
  {
    "uniprot": "A0A044V778",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V778"
  },
  {
    "uniprot": "A0A044SHE8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHE8"
  },
  {
    "uniprot": "A0A044SSI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSI4"
  },
  {
    "uniprot": "A0A158N836",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N836"
  },
  {
    "uniprot": "A0A044T546",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T546"
  },
  {
    "uniprot": "A0A044TSW1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSW1"
  },
  {
    "uniprot": "A0A044UZA8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZA8"
  },
  {
    "uniprot": "A0A044V486",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V486"
  },
  {
    "uniprot": "A0A044UN15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UN15"
  },
  {
    "uniprot": "A0A044RN88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RN88"
  },
  {
    "uniprot": "A0A044V317",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V317"
  },
  {
    "uniprot": "A0A044RI36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RI36"
  },
  {
    "uniprot": "A0A044VIC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIC1"
  },
  {
    "uniprot": "A0A044U293",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U293"
  },
  {
    "uniprot": "P30162",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P30162"
  },
  {
    "uniprot": "A0A2K6WAM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAM8"
  },
  {
    "uniprot": "A0A044TXD0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXD0"
  },
  {
    "uniprot": "A0A158N805",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N805"
  },
  {
    "uniprot": "O18483",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/O18483"
  },
  {
    "uniprot": "A0A044RCW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCW4"
  },
  {
    "uniprot": "A0A044TEL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEL6"
  },
  {
    "uniprot": "A0A044VHU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHU3"
  },
  {
    "uniprot": "A0A044RJ37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJ37"
  },
  {
    "uniprot": "A0A044VAU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAU1"
  },
  {
    "uniprot": "A0A044UB54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UB54"
  },
  {
    "uniprot": "A0A044RXZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXZ1"
  },
  {
    "uniprot": "A0A044TCQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCQ4"
  },
  {
    "uniprot": "A0A044VIZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIZ6"
  },
  {
    "uniprot": "A0A044TCN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCN1"
  },
  {
    "uniprot": "A0A044TV56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV56"
  },
  {
    "uniprot": "A0A158N7P8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7P8"
  },
  {
    "uniprot": "A0A044USV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USV7"
  },
  {
    "uniprot": "A0A044R555",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R555"
  },
  {
    "uniprot": "A0A044UIR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIR8"
  },
  {
    "uniprot": "A0A044T0J9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0J9"
  },
  {
    "uniprot": "A0A2K6VQV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQV6"
  },
  {
    "uniprot": "A0A2K6VY46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VY46"
  },
  {
    "uniprot": "A0A044TT14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TT14"
  },
  {
    "uniprot": "A0A044T1I0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1I0"
  },
  {
    "uniprot": "A0A044QW19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW19"
  },
  {
    "uniprot": "A0A044ULY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULY5"
  },
  {
    "uniprot": "A0A044RU07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU07"
  },
  {
    "uniprot": "A0A044V8Q6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Q6"
  },
  {
    "uniprot": "A0A044S7T8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7T8"
  },
  {
    "uniprot": "A0A044S2H5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2H5"
  },
  {
    "uniprot": "A0A044RI58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RI58"
  },
  {
    "uniprot": "A0A044UZ87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ87"
  },
  {
    "uniprot": "A0A044TCJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCJ7"
  },
  {
    "uniprot": "A0A044SPN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPN8"
  },
  {
    "uniprot": "A0A2K6WJR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJR2"
  },
  {
    "uniprot": "A0A044SUE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUE7"
  },
  {
    "uniprot": "A0A044UQ72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQ72"
  },
  {
    "uniprot": "A0A044RPI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPI0"
  },
  {
    "uniprot": "A0A044VII3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VII3"
  },
  {
    "uniprot": "A0A2K6VR94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VR94"
  },
  {
    "uniprot": "A0A044R3M0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3M0"
  },
  {
    "uniprot": "A0A044QXH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXH0"
  },
  {
    "uniprot": "A0A044U0Q7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0Q7"
  },
  {
    "uniprot": "A0A044V6G4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6G4"
  },
  {
    "uniprot": "A0A044T7F3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7F3"
  },
  {
    "uniprot": "A0A044S2N4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2N4"
  },
  {
    "uniprot": "A0A044U1X2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1X2"
  },
  {
    "uniprot": "A0A044T6V8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6V8"
  },
  {
    "uniprot": "A0A044TPK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPK9"
  },
  {
    "uniprot": "A0A044SSQ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSQ2"
  },
  {
    "uniprot": "A0A044QPG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPG0"
  },
  {
    "uniprot": "A0A044S584",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S584"
  },
  {
    "uniprot": "A0A044TYY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYY3"
  },
  {
    "uniprot": "A0A044TL91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TL91"
  },
  {
    "uniprot": "A0A044RWY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWY4"
  },
  {
    "uniprot": "A0A044S093",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S093"
  },
  {
    "uniprot": "A0A044RC41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC41"
  },
  {
    "uniprot": "A0A2K6WB40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WB40"
  },
  {
    "uniprot": "A0A044T308",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T308"
  },
  {
    "uniprot": "A0A044TJM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJM1"
  },
  {
    "uniprot": "A0A044UU49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UU49"
  },
  {
    "uniprot": "A0A044V7S7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7S7"
  },
  {
    "uniprot": "A0A044RAD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAD3"
  },
  {
    "uniprot": "A0A2K6W4W3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W4W3"
  },
  {
    "uniprot": "A0A044UF68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF68"
  },
  {
    "uniprot": "A0A044QLG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLG4"
  },
  {
    "uniprot": "A0A044UPV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPV7"
  },
  {
    "uniprot": "A0A044SSS8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSS8"
  },
  {
    "uniprot": "A0A044TN36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN36"
  },
  {
    "uniprot": "A0A044SZK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZK8"
  },
  {
    "uniprot": "A0A044SK25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK25"
  },
  {
    "uniprot": "A0A044V384",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V384"
  },
  {
    "uniprot": "A0A044U187",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U187"
  },
  {
    "uniprot": "A0A044QNC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNC0"
  },
  {
    "uniprot": "A0A044UDL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDL5"
  },
  {
    "uniprot": "A0A044T413",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T413"
  },
  {
    "uniprot": "A0A044QLI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLI2"
  },
  {
    "uniprot": "A0A044RIB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIB1"
  },
  {
    "uniprot": "A0A044U3R7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3R7"
  },
  {
    "uniprot": "A0A044UCP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCP4"
  },
  {
    "uniprot": "A0A044QK53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK53"
  },
  {
    "uniprot": "A0A044SQV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQV9"
  },
  {
    "uniprot": "A0A044R2Q7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2Q7"
  },
  {
    "uniprot": "A0A044RGV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGV4"
  },
  {
    "uniprot": "A0A044REC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REC0"
  },
  {
    "uniprot": "A0A044RNN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNN8"
  },
  {
    "uniprot": "A0A2K6W7A5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7A5"
  },
  {
    "uniprot": "A0A044V4G5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4G5"
  },
  {
    "uniprot": "A0A044VHI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHI0"
  },
  {
    "uniprot": "A0A044R739",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R739"
  },
  {
    "uniprot": "A0A044RQ94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQ94"
  },
  {
    "uniprot": "A0A044R8Y5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8Y5"
  },
  {
    "uniprot": "A0A044QND0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QND0"
  },
  {
    "uniprot": "A0A044TTY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTY6"
  },
  {
    "uniprot": "A0A2K6VIC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIC5"
  },
  {
    "uniprot": "A0A044QNF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNF6"
  },
  {
    "uniprot": "A0A044V3C9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3C9"
  },
  {
    "uniprot": "A0A044T8G4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8G4"
  },
  {
    "uniprot": "A0A044U047",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U047"
  },
  {
    "uniprot": "A0A044RRT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRT7"
  },
  {
    "uniprot": "A0A044SVH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVH4"
  },
  {
    "uniprot": "A0A044UWH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWH4"
  },
  {
    "uniprot": "A0A044RH09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH09"
  },
  {
    "uniprot": "A0A2K6VZ69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZ69"
  },
  {
    "uniprot": "A0A044V9E3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9E3"
  },
  {
    "uniprot": "A0A044TV07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV07"
  },
  {
    "uniprot": "A0A044SRS1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRS1"
  },
  {
    "uniprot": "A0A044UGJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGJ6"
  },
  {
    "uniprot": "A0A044T1S2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1S2"
  },
  {
    "uniprot": "A0A044U0P5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0P5"
  },
  {
    "uniprot": "A0A044RTQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTQ9"
  },
  {
    "uniprot": "A0A044SYW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYW6"
  },
  {
    "uniprot": "A0A044QZE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZE7"
  },
  {
    "uniprot": "A0A044UBB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBB3"
  },
  {
    "uniprot": "A0A044RHC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHC3"
  },
  {
    "uniprot": "A0A044UQE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQE7"
  },
  {
    "uniprot": "A0A2K6WM31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WM31"
  },
  {
    "uniprot": "A0A2K6VHV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHV9"
  },
  {
    "uniprot": "A0A044R0G4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0G4"
  },
  {
    "uniprot": "A0A158N7Z7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Z7"
  },
  {
    "uniprot": "A0A044TAP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAP5"
  },
  {
    "uniprot": "A0A044TQ09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQ09"
  },
  {
    "uniprot": "A0A044UZ35",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ35"
  },
  {
    "uniprot": "A0A044TA63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TA63"
  },
  {
    "uniprot": "A0A044V6Z3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Z3"
  },
  {
    "uniprot": "A0A044VAA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAA4"
  },
  {
    "uniprot": "A0A044UKK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKK4"
  },
  {
    "uniprot": "A0A044RLC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLC2"
  },
  {
    "uniprot": "A0A044S299",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S299"
  },
  {
    "uniprot": "A0A044U1E9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1E9"
  },
  {
    "uniprot": "A0A044SL30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SL30"
  },
  {
    "uniprot": "A0A044TFP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFP9"
  },
  {
    "uniprot": "A0A044RR20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RR20"
  },
  {
    "uniprot": "A0A044R308",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R308"
  },
  {
    "uniprot": "A0A044QMX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMX2"
  },
  {
    "uniprot": "A0A044SIL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIL7"
  },
  {
    "uniprot": "A0A044RBX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBX9"
  },
  {
    "uniprot": "A0A2K6W7A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7A9"
  },
  {
    "uniprot": "A0A044S0S2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0S2"
  },
  {
    "uniprot": "A0A044T0J0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0J0"
  },
  {
    "uniprot": "A0A044RFW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFW2"
  },
  {
    "uniprot": "A0A044RE28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RE28"
  },
  {
    "uniprot": "A0A044RAK7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAK7"
  },
  {
    "uniprot": "A0A044S9R4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9R4"
  },
  {
    "uniprot": "A0A044V0X6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0X6"
  },
  {
    "uniprot": "A0A044REV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REV9"
  },
  {
    "uniprot": "A0A044V435",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V435"
  },
  {
    "uniprot": "A0A2K6VR60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VR60"
  },
  {
    "uniprot": "A0A2K6VT73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VT73"
  },
  {
    "uniprot": "A0A044V140",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V140"
  },
  {
    "uniprot": "A0A044V8G4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8G4"
  },
  {
    "uniprot": "A0A044UGF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGF6"
  },
  {
    "uniprot": "A0A044TKK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKK6"
  },
  {
    "uniprot": "A0A044UTB2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTB2"
  },
  {
    "uniprot": "A0A044UJ61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJ61"
  },
  {
    "uniprot": "A0A044R9V4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9V4"
  },
  {
    "uniprot": "A0A044UUP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUP3"
  },
  {
    "uniprot": "A0A044TCN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCN8"
  },
  {
    "uniprot": "A0A2K6WNY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WNY2"
  },
  {
    "uniprot": "A0A044V4H2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4H2"
  },
  {
    "uniprot": "A0A044U224",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U224"
  },
  {
    "uniprot": "A0A044SJX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJX4"
  },
  {
    "uniprot": "A0A044T3X0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3X0"
  },
  {
    "uniprot": "A0A2K6VJF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJF2"
  },
  {
    "uniprot": "A0A2K6W1B7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1B7"
  },
  {
    "uniprot": "A0A044SJS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJS3"
  },
  {
    "uniprot": "A0A044TXN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXN9"
  },
  {
    "uniprot": "A0A044TTV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTV9"
  },
  {
    "uniprot": "A0A044V3P3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3P3"
  },
  {
    "uniprot": "A0A044U6N5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6N5"
  },
  {
    "uniprot": "A0A044SQL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQL7"
  },
  {
    "uniprot": "A0A044UD46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UD46"
  },
  {
    "uniprot": "A0A2K6VQA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQA4"
  },
  {
    "uniprot": "A0A044V465",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V465"
  },
  {
    "uniprot": "A0A2K6VR75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VR75"
  },
  {
    "uniprot": "A0A2K6WE15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WE15"
  },
  {
    "uniprot": "A0A044V1A6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1A6"
  },
  {
    "uniprot": "A0A044UV53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UV53"
  },
  {
    "uniprot": "A0A044RS75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RS75"
  },
  {
    "uniprot": "A0A044VEJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEJ7"
  },
  {
    "uniprot": "A0A044SNV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNV1"
  },
  {
    "uniprot": "A0A044R0U5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0U5"
  },
  {
    "uniprot": "A0A044R6H7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6H7"
  },
  {
    "uniprot": "A0A044TBY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBY8"
  },
  {
    "uniprot": "A0A044SH21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SH21"
  },
  {
    "uniprot": "A0A044V3Z1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3Z1"
  },
  {
    "uniprot": "A0A044V2Q7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2Q7"
  },
  {
    "uniprot": "A0A044SA44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SA44"
  },
  {
    "uniprot": "A0A044V148",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V148"
  },
  {
    "uniprot": "A0A044V135",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V135"
  },
  {
    "uniprot": "A0A044UA87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA87"
  },
  {
    "uniprot": "A0A044RNA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNA7"
  },
  {
    "uniprot": "A0A044V9L8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9L8"
  },
  {
    "uniprot": "A0A044R237",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R237"
  },
  {
    "uniprot": "A0A2K6WG13",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WG13"
  },
  {
    "uniprot": "A0A044RN84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RN84"
  },
  {
    "uniprot": "A0A044TZW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZW4"
  },
  {
    "uniprot": "A0A044TVF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVF3"
  },
  {
    "uniprot": "A0A044UZZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZZ9"
  },
  {
    "uniprot": "A0A044T257",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T257"
  },
  {
    "uniprot": "A0A044UE59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE59"
  },
  {
    "uniprot": "A0A044V9P4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9P4"
  },
  {
    "uniprot": "A0A044RCG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCG8"
  },
  {
    "uniprot": "A0A2K6WH89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WH89"
  },
  {
    "uniprot": "A0A044TVC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVC2"
  },
  {
    "uniprot": "A0A044SNP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNP9"
  },
  {
    "uniprot": "A0A044V7F0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7F0"
  },
  {
    "uniprot": "A0A044S7R6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7R6"
  },
  {
    "uniprot": "A0A044SVG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVG0"
  },
  {
    "uniprot": "A0A044SV92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SV92"
  },
  {
    "uniprot": "A0A044U4G7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4G7"
  },
  {
    "uniprot": "A0A044R769",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R769"
  },
  {
    "uniprot": "A0A2K6VQS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQS0"
  },
  {
    "uniprot": "A0A044RPQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPQ9"
  },
  {
    "uniprot": "A0A044S174",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S174"
  },
  {
    "uniprot": "A0A044VCJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCJ1"
  },
  {
    "uniprot": "A0A044RP02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RP02"
  },
  {
    "uniprot": "A0A044R5T9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5T9"
  },
  {
    "uniprot": "A0A044V4C5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4C5"
  },
  {
    "uniprot": "A0A044T6P5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6P5"
  },
  {
    "uniprot": "A0A044S0I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0I9"
  },
  {
    "uniprot": "A0A044R0H8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0H8"
  },
  {
    "uniprot": "A0A044SL67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SL67"
  },
  {
    "uniprot": "A0A044USC7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USC7"
  },
  {
    "uniprot": "A0A044QNR9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNR9"
  },
  {
    "uniprot": "A0A2K6VEI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VEI4"
  },
  {
    "uniprot": "A0A044V901",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V901"
  },
  {
    "uniprot": "A0A044U1B7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1B7"
  },
  {
    "uniprot": "A0A044TM92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TM92"
  },
  {
    "uniprot": "A0A2K6VL39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VL39"
  },
  {
    "uniprot": "A0A044VDF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDF5"
  },
  {
    "uniprot": "A0A044V8Z7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Z7"
  },
  {
    "uniprot": "A0A2K6VZ85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZ85"
  },
  {
    "uniprot": "A0A044QTN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTN9"
  },
  {
    "uniprot": "A0A044UJ66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJ66"
  },
  {
    "uniprot": "A0A044RYQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYQ9"
  },
  {
    "uniprot": "A0A044RLZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLZ9"
  },
  {
    "uniprot": "A0A2K6WI66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WI66"
  },
  {
    "uniprot": "A0A044VB00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VB00"
  },
  {
    "uniprot": "A0A044SHL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHL5"
  },
  {
    "uniprot": "A0A044TDJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDJ3"
  },
  {
    "uniprot": "A0A044TAK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAK3"
  },
  {
    "uniprot": "A0A044V9B3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9B3"
  },
  {
    "uniprot": "A0A044TAD0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAD0"
  },
  {
    "uniprot": "A0A044QYI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYI0"
  },
  {
    "uniprot": "A0A2K6VJG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJG0"
  },
  {
    "uniprot": "A0A044S1X2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1X2"
  },
  {
    "uniprot": "A0A044SJ57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJ57"
  },
  {
    "uniprot": "A0A044QQ42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQ42"
  },
  {
    "uniprot": "A0A044U0H5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0H5"
  },
  {
    "uniprot": "A0A044SJ46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJ46"
  },
  {
    "uniprot": "A0A2K6VXC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXC1"
  },
  {
    "uniprot": "A0A044SLS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLS3"
  },
  {
    "uniprot": "A0A044UM41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UM41"
  },
  {
    "uniprot": "A0A2K6W155",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W155"
  },
  {
    "uniprot": "A0A044U595",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U595"
  },
  {
    "uniprot": "A0A2K6WN75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WN75"
  },
  {
    "uniprot": "A0A044VHH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHH7"
  },
  {
    "uniprot": "A0A044VEG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEG9"
  },
  {
    "uniprot": "A0A044R9K4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9K4"
  },
  {
    "uniprot": "A0A044UYU2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYU2"
  },
  {
    "uniprot": "A0A044V090",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V090"
  },
  {
    "uniprot": "A0A044SQX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQX9"
  },
  {
    "uniprot": "A0A2K6VT32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VT32"
  },
  {
    "uniprot": "A0A044UBE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBE9"
  },
  {
    "uniprot": "A0A044TUW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUW8"
  },
  {
    "uniprot": "A0A2K6WKW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKW9"
  },
  {
    "uniprot": "A0A044TH22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH22"
  },
  {
    "uniprot": "A0A044TJA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJA0"
  },
  {
    "uniprot": "A0A044RL20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RL20"
  },
  {
    "uniprot": "A0A044TNW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNW9"
  },
  {
    "uniprot": "A0A044TZM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZM8"
  },
  {
    "uniprot": "A0A2K6VWJ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWJ0"
  },
  {
    "uniprot": "A0A044RU56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU56"
  },
  {
    "uniprot": "A0A044V5G5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5G5"
  },
  {
    "uniprot": "A0A158N8I0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8I0"
  },
  {
    "uniprot": "A0A044RAV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAV4"
  },
  {
    "uniprot": "A0A044SVL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVL5"
  },
  {
    "uniprot": "A0A044U8P4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8P4"
  },
  {
    "uniprot": "A0A044QVF7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVF7"
  },
  {
    "uniprot": "A0A044TV88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV88"
  },
  {
    "uniprot": "A0A044S5B7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5B7"
  },
  {
    "uniprot": "A0A044QQL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQL6"
  },
  {
    "uniprot": "A0A044V2I4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2I4"
  },
  {
    "uniprot": "A0A044UNP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNP0"
  },
  {
    "uniprot": "A0A044RGQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGQ5"
  },
  {
    "uniprot": "A0A044RG47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RG47"
  },
  {
    "uniprot": "A0A044QPQ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPQ2"
  },
  {
    "uniprot": "A0A044UG29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UG29"
  },
  {
    "uniprot": "A0A044SBR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBR2"
  },
  {
    "uniprot": "A0A044T137",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T137"
  },
  {
    "uniprot": "A0A044UZA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZA0"
  },
  {
    "uniprot": "A0A044TJB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJB3"
  },
  {
    "uniprot": "A0A044SSF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSF2"
  },
  {
    "uniprot": "A0A044R1L0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1L0"
  },
  {
    "uniprot": "A0A044UMS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMS4"
  },
  {
    "uniprot": "A0A044SXR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXR6"
  },
  {
    "uniprot": "A0A044SCE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCE6"
  },
  {
    "uniprot": "A0A044QYH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYH4"
  },
  {
    "uniprot": "A0A044SIR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIR6"
  },
  {
    "uniprot": "A0A2K6VQJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQJ4"
  },
  {
    "uniprot": "A0A044SD28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD28"
  },
  {
    "uniprot": "A0A044TH05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH05"
  },
  {
    "uniprot": "A0A044ST38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ST38"
  },
  {
    "uniprot": "A0A044V925",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V925"
  },
  {
    "uniprot": "A0A044R576",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R576"
  },
  {
    "uniprot": "A0A044UH08",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UH08"
  },
  {
    "uniprot": "A0A2K6W591",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W591"
  },
  {
    "uniprot": "A0A044VA94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA94"
  },
  {
    "uniprot": "A0A044UPI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPI1"
  },
  {
    "uniprot": "A0A044T954",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T954"
  },
  {
    "uniprot": "A0A044V8Y3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Y3"
  },
  {
    "uniprot": "A0A044VH37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH37"
  },
  {
    "uniprot": "A0A044SYR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYR8"
  },
  {
    "uniprot": "A0A158N8S0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8S0"
  },
  {
    "uniprot": "A0A044SNP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNP5"
  },
  {
    "uniprot": "A0A158N8K6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8K6"
  },
  {
    "uniprot": "A0A044TWN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWN8"
  },
  {
    "uniprot": "A0A044RTR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTR8"
  },
  {
    "uniprot": "A0A044QSL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSL8"
  },
  {
    "uniprot": "A0A044S813",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S813"
  },
  {
    "uniprot": "A0A044UA49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA49"
  },
  {
    "uniprot": "A0A044UGL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGL2"
  },
  {
    "uniprot": "A0A044QJN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJN8"
  },
  {
    "uniprot": "A0A044RHP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHP9"
  },
  {
    "uniprot": "A0A044R537",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R537"
  },
  {
    "uniprot": "A0A044SP04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SP04"
  },
  {
    "uniprot": "A0A044S005",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S005"
  },
  {
    "uniprot": "A0A044SUM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUM5"
  },
  {
    "uniprot": "A0A044SNL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNL3"
  },
  {
    "uniprot": "A0A044QQH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQH1"
  },
  {
    "uniprot": "A0A044R453",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R453"
  },
  {
    "uniprot": "A0A044R1H9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1H9"
  },
  {
    "uniprot": "A0A044SD63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD63"
  },
  {
    "uniprot": "A0A044V6J3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6J3"
  },
  {
    "uniprot": "A0A044UYY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYY9"
  },
  {
    "uniprot": "A0A044T2A0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2A0"
  },
  {
    "uniprot": "A0A044TQN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQN8"
  },
  {
    "uniprot": "A0A044U8F4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8F4"
  },
  {
    "uniprot": "A0A158N7X7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7X7"
  },
  {
    "uniprot": "A0A044VIA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIA9"
  },
  {
    "uniprot": "A0A044T2R8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2R8"
  },
  {
    "uniprot": "A0A044URT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URT7"
  },
  {
    "uniprot": "A0A044SCX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCX7"
  },
  {
    "uniprot": "A0A044U8E5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8E5"
  },
  {
    "uniprot": "A0A2K6VM49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VM49"
  },
  {
    "uniprot": "A0A044R9U6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9U6"
  },
  {
    "uniprot": "A0A044SNW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNW2"
  },
  {
    "uniprot": "A0A044UWB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWB4"
  },
  {
    "uniprot": "A0A2K6VJH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJH6"
  },
  {
    "uniprot": "A0A044T267",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T267"
  },
  {
    "uniprot": "A0A044RHU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHU5"
  },
  {
    "uniprot": "A0A044SEF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEF3"
  },
  {
    "uniprot": "A0A044S2N7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2N7"
  },
  {
    "uniprot": "A0A044VE28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VE28"
  },
  {
    "uniprot": "A0A044SWI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWI0"
  },
  {
    "uniprot": "A0A044SCY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCY2"
  },
  {
    "uniprot": "A0A044SUS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUS7"
  },
  {
    "uniprot": "A0A2K6VGM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VGM4"
  },
  {
    "uniprot": "A0A044RVV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVV7"
  },
  {
    "uniprot": "A0A044S914",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S914"
  },
  {
    "uniprot": "A0A044SGK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGK5"
  },
  {
    "uniprot": "A0A044U8N9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8N9"
  },
  {
    "uniprot": "A0A044R8D0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8D0"
  },
  {
    "uniprot": "A0A2K6VZX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZX2"
  },
  {
    "uniprot": "A0A044V6J6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6J6"
  },
  {
    "uniprot": "A0A044V6Z4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Z4"
  },
  {
    "uniprot": "A0A044V3Q0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3Q0"
  },
  {
    "uniprot": "A0A044TUG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUG4"
  },
  {
    "uniprot": "A0A044UGR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGR3"
  },
  {
    "uniprot": "A0A044TVM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVM4"
  },
  {
    "uniprot": "A0A044S141",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S141"
  },
  {
    "uniprot": "A0A044SHR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHR3"
  },
  {
    "uniprot": "A0A044V922",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V922"
  },
  {
    "uniprot": "A0A044TNE8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNE8"
  },
  {
    "uniprot": "A0A044SW16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SW16"
  },
  {
    "uniprot": "A0A044S7N3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7N3"
  },
  {
    "uniprot": "A0A044V5D2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5D2"
  },
  {
    "uniprot": "A0A044TZJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZJ9"
  },
  {
    "uniprot": "A0A044RME0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RME0"
  },
  {
    "uniprot": "A0A044VEB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEB5"
  },
  {
    "uniprot": "A0A044QNG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNG9"
  },
  {
    "uniprot": "A0A044UX75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX75"
  },
  {
    "uniprot": "A0A044R864",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R864"
  },
  {
    "uniprot": "A0A2K6VHN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHN7"
  },
  {
    "uniprot": "A0A044T7S9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7S9"
  },
  {
    "uniprot": "A0A044SC99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SC99"
  },
  {
    "uniprot": "A0A044SXA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXA9"
  },
  {
    "uniprot": "A0A044R5K0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5K0"
  },
  {
    "uniprot": "A0A044RC33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC33"
  },
  {
    "uniprot": "A0A044UT83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT83"
  },
  {
    "uniprot": "A0A044U475",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U475"
  },
  {
    "uniprot": "A0A044QK47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK47"
  },
  {
    "uniprot": "A0A044RVL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVL7"
  },
  {
    "uniprot": "A0A044RTH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTH0"
  },
  {
    "uniprot": "A0A044S6Q2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6Q2"
  },
  {
    "uniprot": "A0A044VIW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIW4"
  },
  {
    "uniprot": "A0A044UHE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHE2"
  },
  {
    "uniprot": "A0A044THH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THH2"
  },
  {
    "uniprot": "A0A044TUP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUP5"
  },
  {
    "uniprot": "A0A044TQB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQB3"
  },
  {
    "uniprot": "A0A044QKI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKI3"
  },
  {
    "uniprot": "A0A044UX37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX37"
  },
  {
    "uniprot": "A0A044TEJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEJ6"
  },
  {
    "uniprot": "A0A044S2J6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2J6"
  },
  {
    "uniprot": "A0A044RGA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGA3"
  },
  {
    "uniprot": "A0A044S4I7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4I7"
  },
  {
    "uniprot": "A0A044QXU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXU1"
  },
  {
    "uniprot": "A0A044V0F3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0F3"
  },
  {
    "uniprot": "A0A044QM31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QM31"
  },
  {
    "uniprot": "A0A044SA51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SA51"
  },
  {
    "uniprot": "A0A2K6WMM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMM6"
  },
  {
    "uniprot": "A0A044T861",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T861"
  },
  {
    "uniprot": "A0A044SXN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXN7"
  },
  {
    "uniprot": "A0A2K6VTQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VTQ1"
  },
  {
    "uniprot": "A0A044UJ31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJ31"
  },
  {
    "uniprot": "A0A044TMD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMD5"
  },
  {
    "uniprot": "A0A044SQ97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ97"
  },
  {
    "uniprot": "A0A044T0R1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0R1"
  },
  {
    "uniprot": "A0A044RK68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RK68"
  },
  {
    "uniprot": "A0A044SPB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPB4"
  },
  {
    "uniprot": "A0A044VAN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAN4"
  },
  {
    "uniprot": "A0A044T009",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T009"
  },
  {
    "uniprot": "A0A044S622",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S622"
  },
  {
    "uniprot": "A0A044SJP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJP0"
  },
  {
    "uniprot": "A0A044SLR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLR4"
  },
  {
    "uniprot": "A0A044SDL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDL5"
  },
  {
    "uniprot": "A0A044VBQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBQ0"
  },
  {
    "uniprot": "A0A044QR87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QR87"
  },
  {
    "uniprot": "A0A044QQ74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQ74"
  },
  {
    "uniprot": "A0A044R3E0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3E0"
  },
  {
    "uniprot": "A0A044RQC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQC4"
  },
  {
    "uniprot": "A0A044SU88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU88"
  },
  {
    "uniprot": "A0A044VF69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VF69"
  },
  {
    "uniprot": "A0A044SQV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQV0"
  },
  {
    "uniprot": "A0A044RXD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXD8"
  },
  {
    "uniprot": "A0A044R328",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R328"
  },
  {
    "uniprot": "A0A044UUS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUS2"
  },
  {
    "uniprot": "A0A2K6WJ30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJ30"
  },
  {
    "uniprot": "A0A044RAX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAX8"
  },
  {
    "uniprot": "A0A044UB37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UB37"
  },
  {
    "uniprot": "A0A2K6WG20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WG20"
  },
  {
    "uniprot": "A0A044SLN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLN3"
  },
  {
    "uniprot": "A0A044T3G8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3G8"
  },
  {
    "uniprot": "A0A2K6VQW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQW7"
  },
  {
    "uniprot": "A0A044V763",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V763"
  },
  {
    "uniprot": "A0A044UT20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT20"
  },
  {
    "uniprot": "A0A044V119",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V119"
  },
  {
    "uniprot": "A0A044RIX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIX6"
  },
  {
    "uniprot": "A0A044RES7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RES7"
  },
  {
    "uniprot": "A0A044VHK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHK4"
  },
  {
    "uniprot": "A0A044UTM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTM7"
  },
  {
    "uniprot": "A0A2K6W982",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W982"
  },
  {
    "uniprot": "A0A2K6WDK7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDK7"
  },
  {
    "uniprot": "A0A2K6WL27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WL27"
  },
  {
    "uniprot": "A0A044RVU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVU7"
  },
  {
    "uniprot": "A0A044UD00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UD00"
  },
  {
    "uniprot": "A0A044TDC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDC4"
  },
  {
    "uniprot": "A0A044RWI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWI2"
  },
  {
    "uniprot": "A0A044QT91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT91"
  },
  {
    "uniprot": "A0A044RA00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RA00"
  },
  {
    "uniprot": "A0A044T4B6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4B6"
  },
  {
    "uniprot": "A0A044S945",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S945"
  },
  {
    "uniprot": "A0A044TIY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIY0"
  },
  {
    "uniprot": "A0A044UHK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHK4"
  },
  {
    "uniprot": "A0A044V895",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V895"
  },
  {
    "uniprot": "A0A044TFZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFZ9"
  },
  {
    "uniprot": "A0A044RUI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUI0"
  },
  {
    "uniprot": "A0A044T4H6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4H6"
  },
  {
    "uniprot": "A0A044QU96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QU96"
  },
  {
    "uniprot": "A0A044UUB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUB8"
  },
  {
    "uniprot": "A0A044T130",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T130"
  },
  {
    "uniprot": "A0A044RYE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYE1"
  },
  {
    "uniprot": "A0A044UK99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UK99"
  },
  {
    "uniprot": "A0A044RD17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RD17"
  },
  {
    "uniprot": "A0A044UDA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDA5"
  },
  {
    "uniprot": "A0A044V7H5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7H5"
  },
  {
    "uniprot": "A0A044STW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STW7"
  },
  {
    "uniprot": "A0A044SDX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDX3"
  },
  {
    "uniprot": "A0A044UML0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UML0"
  },
  {
    "uniprot": "A0A044UBP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBP7"
  },
  {
    "uniprot": "A0A044R3L2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3L2"
  },
  {
    "uniprot": "A0A044V8Z6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Z6"
  },
  {
    "uniprot": "A0A2K6VGX0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VGX0"
  },
  {
    "uniprot": "A0A044R6C8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6C8"
  },
  {
    "uniprot": "A0A044T7Q3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7Q3"
  },
  {
    "uniprot": "A0A044RK22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RK22"
  },
  {
    "uniprot": "A0A044RLX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLX1"
  },
  {
    "uniprot": "A0A044T816",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T816"
  },
  {
    "uniprot": "A0A044SD21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD21"
  },
  {
    "uniprot": "A0A044RFG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFG2"
  },
  {
    "uniprot": "A0A044V903",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V903"
  },
  {
    "uniprot": "A0A044T4Y2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4Y2"
  },
  {
    "uniprot": "A0A044SQK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQK8"
  },
  {
    "uniprot": "A0A044R183",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R183"
  },
  {
    "uniprot": "A0A2K6VPA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPA3"
  },
  {
    "uniprot": "A0A2K6VX17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VX17"
  },
  {
    "uniprot": "A0A044RND2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RND2"
  },
  {
    "uniprot": "A0A044TAZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAZ2"
  },
  {
    "uniprot": "A0A2K6VH38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VH38"
  },
  {
    "uniprot": "A0A044S8X7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8X7"
  },
  {
    "uniprot": "A0A044VFZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFZ3"
  },
  {
    "uniprot": "A0A044T0M3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0M3"
  },
  {
    "uniprot": "A0A044RBF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBF1"
  },
  {
    "uniprot": "A0A044UTI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTI1"
  },
  {
    "uniprot": "A0A044RM17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RM17"
  },
  {
    "uniprot": "A0A2K6W8L9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8L9"
  },
  {
    "uniprot": "A0A044V4J0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4J0"
  },
  {
    "uniprot": "A0A2K6VEV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VEV7"
  },
  {
    "uniprot": "A0A044QR99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QR99"
  },
  {
    "uniprot": "A0A044U154",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U154"
  },
  {
    "uniprot": "A0A044RG85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RG85"
  },
  {
    "uniprot": "A0A044VDW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDW8"
  },
  {
    "uniprot": "A0A044UQB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQB8"
  },
  {
    "uniprot": "A0A044UP68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UP68"
  },
  {
    "uniprot": "A0A044QKM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKM5"
  },
  {
    "uniprot": "A0A044UAT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAT0"
  },
  {
    "uniprot": "A0A044SB27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB27"
  },
  {
    "uniprot": "A0A044SEL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEL2"
  },
  {
    "uniprot": "A0A044SL36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SL36"
  },
  {
    "uniprot": "A0A044V306",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V306"
  },
  {
    "uniprot": "A0A044RRZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRZ9"
  },
  {
    "uniprot": "A0A044U2J9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2J9"
  },
  {
    "uniprot": "A0A044UZ59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ59"
  },
  {
    "uniprot": "A0A044SPJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPJ6"
  },
  {
    "uniprot": "A0A2K6VLN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLN9"
  },
  {
    "uniprot": "A0A044RAU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAU0"
  },
  {
    "uniprot": "A0A044RSN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSN4"
  },
  {
    "uniprot": "A0A044UI80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI80"
  },
  {
    "uniprot": "A0A044RNF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNF8"
  },
  {
    "uniprot": "A0A044V2V8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2V8"
  },
  {
    "uniprot": "A0A044V954",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V954"
  },
  {
    "uniprot": "A0A044UYP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYP2"
  },
  {
    "uniprot": "A0A044SFP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFP2"
  },
  {
    "uniprot": "A0A044QKT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKT0"
  },
  {
    "uniprot": "A0A044V8I3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8I3"
  },
  {
    "uniprot": "A0A2K6VIP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIP0"
  },
  {
    "uniprot": "A0A044T0L1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0L1"
  },
  {
    "uniprot": "A0A2K6WL98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WL98"
  },
  {
    "uniprot": "A0A044S9Q9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9Q9"
  },
  {
    "uniprot": "A0A044SZI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZI9"
  },
  {
    "uniprot": "A0A044RPP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPP5"
  },
  {
    "uniprot": "A0A044ULE8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULE8"
  },
  {
    "uniprot": "A0A044RKM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKM6"
  },
  {
    "uniprot": "A0A044R7L7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7L7"
  },
  {
    "uniprot": "A0A044U847",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U847"
  },
  {
    "uniprot": "A0A044VHV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHV9"
  },
  {
    "uniprot": "A0A044VA65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA65"
  },
  {
    "uniprot": "A0A044UJV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJV4"
  },
  {
    "uniprot": "A0A044R6G7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6G7"
  },
  {
    "uniprot": "A0A044TT60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TT60"
  },
  {
    "uniprot": "A0A044VJ06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJ06"
  },
  {
    "uniprot": "A0A044U1I6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1I6"
  },
  {
    "uniprot": "A0A044UVQ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVQ2"
  },
  {
    "uniprot": "A0A044RZL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZL8"
  },
  {
    "uniprot": "A0A044TKQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKQ4"
  },
  {
    "uniprot": "A0A044RPN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPN0"
  },
  {
    "uniprot": "A0A044TV44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV44"
  },
  {
    "uniprot": "A0A044R2J2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2J2"
  },
  {
    "uniprot": "A0A158N807",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N807"
  },
  {
    "uniprot": "A0A044UYG3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYG3"
  },
  {
    "uniprot": "A0A044T1B8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1B8"
  },
  {
    "uniprot": "A0A044SB80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB80"
  },
  {
    "uniprot": "A0A2K6VRV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRV9"
  },
  {
    "uniprot": "A0A044RIU2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIU2"
  },
  {
    "uniprot": "A0A044SP24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SP24"
  },
  {
    "uniprot": "A0A044RCF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCF6"
  },
  {
    "uniprot": "A0A044V423",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V423"
  },
  {
    "uniprot": "A0A044QNB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNB0"
  },
  {
    "uniprot": "A0A044QQ64",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQ64"
  },
  {
    "uniprot": "A0A2K6WCY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WCY6"
  },
  {
    "uniprot": "A0A044T8X1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8X1"
  },
  {
    "uniprot": "A0A044RDS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDS9"
  },
  {
    "uniprot": "A0A044TMW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMW9"
  },
  {
    "uniprot": "A0A044T277",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T277"
  },
  {
    "uniprot": "A0A044TEI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEI1"
  },
  {
    "uniprot": "A0A2K6W2Z9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2Z9"
  },
  {
    "uniprot": "A0A2K6VU00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VU00"
  },
  {
    "uniprot": "A0A044R3Z1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3Z1"
  },
  {
    "uniprot": "A0A044V8Z0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Z0"
  },
  {
    "uniprot": "A0A044UZ42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ42"
  },
  {
    "uniprot": "A0A044T787",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T787"
  },
  {
    "uniprot": "A0A044U2X6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2X6"
  },
  {
    "uniprot": "A0A2K6WBW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBW6"
  },
  {
    "uniprot": "A0A044U379",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U379"
  },
  {
    "uniprot": "A0A2K6W0P8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0P8"
  },
  {
    "uniprot": "A0A044RVX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVX3"
  },
  {
    "uniprot": "A0A2K6VEK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VEK5"
  },
  {
    "uniprot": "A0A2K6W6N2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6N2"
  },
  {
    "uniprot": "A0A044TPQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPQ7"
  },
  {
    "uniprot": "A0A2K6VF21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VF21"
  },
  {
    "uniprot": "A0A044SDI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDI3"
  },
  {
    "uniprot": "A0A044SDZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDZ8"
  },
  {
    "uniprot": "A0A2K6VXH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXH9"
  },
  {
    "uniprot": "A0A044TE66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TE66"
  },
  {
    "uniprot": "A0A2K6VIB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIB0"
  },
  {
    "uniprot": "A0A044RZ72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZ72"
  },
  {
    "uniprot": "A0A044TAD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAD8"
  },
  {
    "uniprot": "A0A2K6W4X5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W4X5"
  },
  {
    "uniprot": "A0A044R0L4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0L4"
  },
  {
    "uniprot": "A0A044TT55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TT55"
  },
  {
    "uniprot": "A0A044RY78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY78"
  },
  {
    "uniprot": "A0A158N8C1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8C1"
  },
  {
    "uniprot": "A0A2K6VTU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VTU6"
  },
  {
    "uniprot": "A0A2K6W421",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W421"
  },
  {
    "uniprot": "A0A044UJN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJN1"
  },
  {
    "uniprot": "A0A044SUI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUI8"
  },
  {
    "uniprot": "A0A044STK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STK0"
  },
  {
    "uniprot": "A0A044RP25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RP25"
  },
  {
    "uniprot": "A0A044V716",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V716"
  },
  {
    "uniprot": "A0A044T7D5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7D5"
  },
  {
    "uniprot": "A0A044TED5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TED5"
  },
  {
    "uniprot": "A0A044TR19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR19"
  },
  {
    "uniprot": "P54247",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P54247"
  },
  {
    "uniprot": "A0A044U109",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U109"
  },
  {
    "uniprot": "A0A044TG70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TG70"
  },
  {
    "uniprot": "A0A044SCJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCJ1"
  },
  {
    "uniprot": "A0A044VFC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFC0"
  },
  {
    "uniprot": "A0A044R0M5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0M5"
  },
  {
    "uniprot": "A0A044UNJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNJ6"
  },
  {
    "uniprot": "A0A044VI02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VI02"
  },
  {
    "uniprot": "A0A044TCS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCS5"
  },
  {
    "uniprot": "A0A044VJL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJL5"
  },
  {
    "uniprot": "A0A044SZ01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ01"
  },
  {
    "uniprot": "A0A044TUJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUJ3"
  },
  {
    "uniprot": "A0A044UK83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UK83"
  },
  {
    "uniprot": "A0A2K6VIZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIZ4"
  },
  {
    "uniprot": "A0A2K6VPZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPZ7"
  },
  {
    "uniprot": "A0A044T7S4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7S4"
  },
  {
    "uniprot": "A0A044R176",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R176"
  },
  {
    "uniprot": "A0A044V0A7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0A7"
  },
  {
    "uniprot": "A0A2K6WMU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMU1"
  },
  {
    "uniprot": "A0A044SLP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLP3"
  },
  {
    "uniprot": "A0A044VH79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH79"
  },
  {
    "uniprot": "A0A044U168",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U168"
  },
  {
    "uniprot": "A0A044SRU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRU4"
  },
  {
    "uniprot": "A0A044R2K4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2K4"
  },
  {
    "uniprot": "A0A044R9K9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9K9"
  },
  {
    "uniprot": "A0A044V5M8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5M8"
  },
  {
    "uniprot": "A0A044TF79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TF79"
  },
  {
    "uniprot": "A0A044VAJ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAJ0"
  },
  {
    "uniprot": "A0A044TQ21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQ21"
  },
  {
    "uniprot": "A0A044UCD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCD1"
  },
  {
    "uniprot": "A0A044UPL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPL6"
  },
  {
    "uniprot": "A0A2K6WJ87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJ87"
  },
  {
    "uniprot": "A0A044QWV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWV8"
  },
  {
    "uniprot": "A0A044RA22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RA22"
  },
  {
    "uniprot": "A0A044RC90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC90"
  },
  {
    "uniprot": "A0A044TM10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TM10"
  },
  {
    "uniprot": "A0A044SN69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN69"
  },
  {
    "uniprot": "A0A2K6VHM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHM3"
  },
  {
    "uniprot": "A0A044V5V5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5V5"
  },
  {
    "uniprot": "A0A044QPL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPL5"
  },
  {
    "uniprot": "A0A044UI49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI49"
  },
  {
    "uniprot": "A0A044RA54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RA54"
  },
  {
    "uniprot": "A0A044U9N1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9N1"
  },
  {
    "uniprot": "A0A158N7X0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7X0"
  },
  {
    "uniprot": "A0A044R1R0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1R0"
  },
  {
    "uniprot": "A0A044RYE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYE7"
  },
  {
    "uniprot": "A0A044RCS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCS9"
  },
  {
    "uniprot": "A0A044TUX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUX9"
  },
  {
    "uniprot": "A0A044RAF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAF2"
  },
  {
    "uniprot": "A0A044UQQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQQ5"
  },
  {
    "uniprot": "A0A044U3U5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3U5"
  },
  {
    "uniprot": "A0A044RYX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYX8"
  },
  {
    "uniprot": "A0A044T3K8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3K8"
  },
  {
    "uniprot": "A0A2K6WH63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WH63"
  },
  {
    "uniprot": "A0A044QZC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZC6"
  },
  {
    "uniprot": "A0A044TDQ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDQ8"
  },
  {
    "uniprot": "A0A044TLX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLX2"
  },
  {
    "uniprot": "A0A2K6W1X8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1X8"
  },
  {
    "uniprot": "A0A044U512",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U512"
  },
  {
    "uniprot": "A0A158N8B7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8B7"
  },
  {
    "uniprot": "A0A044V1J4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1J4"
  },
  {
    "uniprot": "A0A044R778",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R778"
  },
  {
    "uniprot": "A0A044UA09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA09"
  },
  {
    "uniprot": "A0A044UKJ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKJ0"
  },
  {
    "uniprot": "A0A044T007",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T007"
  },
  {
    "uniprot": "A0A044UTN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTN0"
  },
  {
    "uniprot": "A0A044SDR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDR7"
  },
  {
    "uniprot": "A0A2K6VFW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFW3"
  },
  {
    "uniprot": "A0A044SU55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU55"
  },
  {
    "uniprot": "A0A044QP82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP82"
  },
  {
    "uniprot": "A0A044SU65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU65"
  },
  {
    "uniprot": "A0A044TQV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQV1"
  },
  {
    "uniprot": "A0A044THK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THK3"
  },
  {
    "uniprot": "A0A044TL06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TL06"
  },
  {
    "uniprot": "A0A044RVW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVW7"
  },
  {
    "uniprot": "A0A044SSC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSC3"
  },
  {
    "uniprot": "A0A044SU14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU14"
  },
  {
    "uniprot": "A0A044SRD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRD8"
  },
  {
    "uniprot": "A0A2K6VG14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VG14"
  },
  {
    "uniprot": "A0A044VIA2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIA2"
  },
  {
    "uniprot": "A0A044QL34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL34"
  },
  {
    "uniprot": "A0A158N8F5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8F5"
  },
  {
    "uniprot": "A0A044TFF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFF8"
  },
  {
    "uniprot": "A0A044U9U7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9U7"
  },
  {
    "uniprot": "A0A044RJL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJL7"
  },
  {
    "uniprot": "A0A044V8V8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8V8"
  },
  {
    "uniprot": "A0A044SEW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEW2"
  },
  {
    "uniprot": "A0A044VFR5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFR5"
  },
  {
    "uniprot": "A0A044TLJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLJ9"
  },
  {
    "uniprot": "A0A2K6WJ57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJ57"
  },
  {
    "uniprot": "A0A044UZE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZE0"
  },
  {
    "uniprot": "A0A044TY39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TY39"
  },
  {
    "uniprot": "A0A044RD91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RD91"
  },
  {
    "uniprot": "A0A044QNU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNU5"
  },
  {
    "uniprot": "A0A2K6VN42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VN42"
  },
  {
    "uniprot": "A0A044SJH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJH1"
  },
  {
    "uniprot": "A0A2K6VUB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUB7"
  },
  {
    "uniprot": "A0A2K6WFG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFG1"
  },
  {
    "uniprot": "A0A044SF60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SF60"
  },
  {
    "uniprot": "A0A044U7X8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7X8"
  },
  {
    "uniprot": "A0A044TP52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TP52"
  },
  {
    "uniprot": "A0A044TAH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAH7"
  },
  {
    "uniprot": "A0A044SZ52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ52"
  },
  {
    "uniprot": "A0A044SS83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS83"
  },
  {
    "uniprot": "A0A044RRB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRB0"
  },
  {
    "uniprot": "A0A2K6W9C3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9C3"
  },
  {
    "uniprot": "A0A158N7Z6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Z6"
  },
  {
    "uniprot": "A0A044V7V8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7V8"
  },
  {
    "uniprot": "A0A044UPH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPH7"
  },
  {
    "uniprot": "A0A044V043",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V043"
  },
  {
    "uniprot": "A0A044V374",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V374"
  },
  {
    "uniprot": "A0A044V9V6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9V6"
  },
  {
    "uniprot": "A0A044RYR5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYR5"
  },
  {
    "uniprot": "A0A044V4G0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4G0"
  },
  {
    "uniprot": "A0A044UPB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPB4"
  },
  {
    "uniprot": "A0A044TN28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN28"
  },
  {
    "uniprot": "A0A044UAX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAX3"
  },
  {
    "uniprot": "A0A044TGM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGM6"
  },
  {
    "uniprot": "A0A044VJ83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJ83"
  },
  {
    "uniprot": "A0A2K6WBT3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBT3"
  },
  {
    "uniprot": "A0A2K6VDW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDW5"
  },
  {
    "uniprot": "A0A044QX30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QX30"
  },
  {
    "uniprot": "A0A044TPF9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPF9"
  },
  {
    "uniprot": "A0A2K6VME2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VME2"
  },
  {
    "uniprot": "A0A044UYE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYE5"
  },
  {
    "uniprot": "A0A044RGP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGP4"
  },
  {
    "uniprot": "A0A044R1M6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1M6"
  },
  {
    "uniprot": "A0A044V484",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V484"
  },
  {
    "uniprot": "A0A044U757",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U757"
  },
  {
    "uniprot": "A0A2K6W7Y6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7Y6"
  },
  {
    "uniprot": "A0A044T3F4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3F4"
  },
  {
    "uniprot": "A0A044U5D2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5D2"
  },
  {
    "uniprot": "A0A044RZ36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZ36"
  },
  {
    "uniprot": "A0A044UFP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFP3"
  },
  {
    "uniprot": "A0A044R6D8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6D8"
  },
  {
    "uniprot": "A0A044TGZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGZ9"
  },
  {
    "uniprot": "A0A044V1U3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1U3"
  },
  {
    "uniprot": "A0A044SER2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SER2"
  },
  {
    "uniprot": "A0A044SPE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPE3"
  },
  {
    "uniprot": "A0A044U1H2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1H2"
  },
  {
    "uniprot": "A0A2K6WD29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WD29"
  },
  {
    "uniprot": "A0A044TMB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMB3"
  },
  {
    "uniprot": "A0A044SHT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHT1"
  },
  {
    "uniprot": "A0A044TTQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTQ9"
  },
  {
    "uniprot": "A0A044RTQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTQ6"
  },
  {
    "uniprot": "A0A044S7Z1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7Z1"
  },
  {
    "uniprot": "A0A044V445",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V445"
  },
  {
    "uniprot": "A0A2K6WFR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFR7"
  },
  {
    "uniprot": "A0A044V3T8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3T8"
  },
  {
    "uniprot": "A0A044V944",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V944"
  },
  {
    "uniprot": "A0A044SF97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SF97"
  },
  {
    "uniprot": "A0A044TYE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYE4"
  },
  {
    "uniprot": "A0A044SKF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKF4"
  },
  {
    "uniprot": "A0A044T8P0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8P0"
  },
  {
    "uniprot": "Q9NFN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q9NFN6"
  },
  {
    "uniprot": "A0A044RR01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RR01"
  },
  {
    "uniprot": "A0A044TV52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV52"
  },
  {
    "uniprot": "A0A044V1A7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1A7"
  },
  {
    "uniprot": "A0A044V030",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V030"
  },
  {
    "uniprot": "A0A044V7D3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7D3"
  },
  {
    "uniprot": "A0A044TFM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFM1"
  },
  {
    "uniprot": "A0A044S655",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S655"
  },
  {
    "uniprot": "A0A044V149",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V149"
  },
  {
    "uniprot": "A0A044SPX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPX2"
  },
  {
    "uniprot": "A0A044RG91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RG91"
  },
  {
    "uniprot": "A0A2K6VSZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSZ3"
  },
  {
    "uniprot": "A0A044UX22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX22"
  },
  {
    "uniprot": "A0A044T9G4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9G4"
  },
  {
    "uniprot": "A0A044VIQ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIQ8"
  },
  {
    "uniprot": "A0A044V0P4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0P4"
  },
  {
    "uniprot": "A0A044R7U0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7U0"
  },
  {
    "uniprot": "A0A044VIX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIX2"
  },
  {
    "uniprot": "A0A044SCE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCE0"
  },
  {
    "uniprot": "A0A044U9P0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9P0"
  },
  {
    "uniprot": "A0A044S985",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S985"
  },
  {
    "uniprot": "A0A044TA87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TA87"
  },
  {
    "uniprot": "A0A044RBY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBY2"
  },
  {
    "uniprot": "A0A044V132",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V132"
  },
  {
    "uniprot": "A0A044RY99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY99"
  },
  {
    "uniprot": "A0A2K6W1E2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1E2"
  },
  {
    "uniprot": "A0A2K6VUT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUT6"
  },
  {
    "uniprot": "A0A044TMT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMT1"
  },
  {
    "uniprot": "A0A044TQM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQM6"
  },
  {
    "uniprot": "A0A044RKF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKF2"
  },
  {
    "uniprot": "A0A044TYR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYR1"
  },
  {
    "uniprot": "A0A044RCX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCX5"
  },
  {
    "uniprot": "A0A2K6VF96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VF96"
  },
  {
    "uniprot": "A0A044STU2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STU2"
  },
  {
    "uniprot": "A0A044SW21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SW21"
  },
  {
    "uniprot": "A0A044UN34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UN34"
  },
  {
    "uniprot": "A0A044TSY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSY5"
  },
  {
    "uniprot": "A0A044RPH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPH1"
  },
  {
    "uniprot": "A0A044RVJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVJ3"
  },
  {
    "uniprot": "A0A044S7W5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7W5"
  },
  {
    "uniprot": "A0A2K6WJB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJB1"
  },
  {
    "uniprot": "A0A044V973",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V973"
  },
  {
    "uniprot": "A0A044V9P2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9P2"
  },
  {
    "uniprot": "A0A044RAG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAG2"
  },
  {
    "uniprot": "A0A044QQP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQP9"
  },
  {
    "uniprot": "A0A044TJV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJV1"
  },
  {
    "uniprot": "A0A044SV50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SV50"
  },
  {
    "uniprot": "A0A044TWB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWB7"
  },
  {
    "uniprot": "A0A2K6WG45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WG45"
  },
  {
    "uniprot": "A0A044TTK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTK3"
  },
  {
    "uniprot": "A0A044VFJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFJ4"
  },
  {
    "uniprot": "A0A044SFK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFK8"
  },
  {
    "uniprot": "A0A044RIP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIP5"
  },
  {
    "uniprot": "A0A2K6W6T1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6T1"
  },
  {
    "uniprot": "A0A044U9H9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9H9"
  },
  {
    "uniprot": "A0A044SGL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGL6"
  },
  {
    "uniprot": "A0A2K6WN09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WN09"
  },
  {
    "uniprot": "A0A044SYT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYT5"
  },
  {
    "uniprot": "A0A044QUV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUV2"
  },
  {
    "uniprot": "A0A044SQM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQM7"
  },
  {
    "uniprot": "A0A044VD86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD86"
  },
  {
    "uniprot": "A0A2K6W555",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W555"
  },
  {
    "uniprot": "A0A044V163",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V163"
  },
  {
    "uniprot": "A0A044U8T2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8T2"
  },
  {
    "uniprot": "A0A044RDD0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDD0"
  },
  {
    "uniprot": "A0A044UYH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYH8"
  },
  {
    "uniprot": "A0A044VD77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD77"
  },
  {
    "uniprot": "A0A2K6W9K5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9K5"
  },
  {
    "uniprot": "A0A044UXV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXV0"
  },
  {
    "uniprot": "A0A044VE01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VE01"
  },
  {
    "uniprot": "A0A044SIX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIX4"
  },
  {
    "uniprot": "A0A044VAD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAD8"
  },
  {
    "uniprot": "A0A044SKT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKT6"
  },
  {
    "uniprot": "A0A2K6VHS8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHS8"
  },
  {
    "uniprot": "A0A044UAE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAE9"
  },
  {
    "uniprot": "A0A044T4N3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4N3"
  },
  {
    "uniprot": "A0A044SB39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB39"
  },
  {
    "uniprot": "A0A044RGK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGK4"
  },
  {
    "uniprot": "A0A044U391",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U391"
  },
  {
    "uniprot": "A0A044S101",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S101"
  },
  {
    "uniprot": "A0A044T968",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T968"
  },
  {
    "uniprot": "A0A044QPD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPD8"
  },
  {
    "uniprot": "A0A044UM73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UM73"
  },
  {
    "uniprot": "A0A044VJD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJD5"
  },
  {
    "uniprot": "A0A044QW77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW77"
  },
  {
    "uniprot": "A0A044TKN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKN7"
  },
  {
    "uniprot": "A0A044S475",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S475"
  },
  {
    "uniprot": "A0A044UDL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDL8"
  },
  {
    "uniprot": "A0A044RFC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFC8"
  },
  {
    "uniprot": "C7DRP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/C7DRP1"
  },
  {
    "uniprot": "A0A044U1D0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1D0"
  },
  {
    "uniprot": "A0A044V2P6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2P6"
  },
  {
    "uniprot": "A0A2K6W8Q9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8Q9"
  },
  {
    "uniprot": "A0A044UGH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGH5"
  },
  {
    "uniprot": "A0A044SIB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIB4"
  },
  {
    "uniprot": "A0A044S3A0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3A0"
  },
  {
    "uniprot": "A0A044UVD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVD1"
  },
  {
    "uniprot": "A0A044TT02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TT02"
  },
  {
    "uniprot": "A0A044VJB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJB7"
  },
  {
    "uniprot": "A0A2K6W2Y1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2Y1"
  },
  {
    "uniprot": "A0A044S6M9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6M9"
  },
  {
    "uniprot": "A0A044RHH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHH4"
  },
  {
    "uniprot": "A0A044TN07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN07"
  },
  {
    "uniprot": "A0A044SUS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUS3"
  },
  {
    "uniprot": "A0A044RH69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH69"
  },
  {
    "uniprot": "A0A2K6WBR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBR0"
  },
  {
    "uniprot": "A0A044RZM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZM6"
  },
  {
    "uniprot": "A0A044RMM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMM1"
  },
  {
    "uniprot": "A0A044S558",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S558"
  },
  {
    "uniprot": "A0A044QPC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPC8"
  },
  {
    "uniprot": "A0A044UZ46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ46"
  },
  {
    "uniprot": "A0A044T9Y4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9Y4"
  },
  {
    "uniprot": "A0A044SUX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUX6"
  },
  {
    "uniprot": "A0A2K6WFI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFI3"
  },
  {
    "uniprot": "A0A044T0J7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0J7"
  },
  {
    "uniprot": "A0A044UWX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWX9"
  },
  {
    "uniprot": "A0A044UNM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNM4"
  },
  {
    "uniprot": "A0A044S3G4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3G4"
  },
  {
    "uniprot": "A0A044RVG3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVG3"
  },
  {
    "uniprot": "A0A044T654",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T654"
  },
  {
    "uniprot": "A0A044V9V2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9V2"
  },
  {
    "uniprot": "A0A044R3A8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3A8"
  },
  {
    "uniprot": "A0A044TG93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TG93"
  },
  {
    "uniprot": "A0A044T2L1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2L1"
  },
  {
    "uniprot": "A0A044SZL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZL3"
  },
  {
    "uniprot": "A0A2K6W4S1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W4S1"
  },
  {
    "uniprot": "A0A044TWW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWW7"
  },
  {
    "uniprot": "A0A044SPK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPK6"
  },
  {
    "uniprot": "A0A044RFS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFS7"
  },
  {
    "uniprot": "A0A044VJM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJM7"
  },
  {
    "uniprot": "A0A044S0R9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0R9"
  },
  {
    "uniprot": "A0A044UGP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGP6"
  },
  {
    "uniprot": "A0A044UUD9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUD9"
  },
  {
    "uniprot": "A0A044UUM9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUM9"
  },
  {
    "uniprot": "A0A044UJJ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJJ5"
  },
  {
    "uniprot": "A0A2K6WFC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFC9"
  },
  {
    "uniprot": "A0A044QRV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRV7"
  },
  {
    "uniprot": "A0A044U936",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U936"
  },
  {
    "uniprot": "A0A2K6W540",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W540"
  },
  {
    "uniprot": "A0A044S2X0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2X0"
  },
  {
    "uniprot": "A0A044QWC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWC9"
  },
  {
    "uniprot": "A0A044THB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THB9"
  },
  {
    "uniprot": "A0A044TWB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWB1"
  },
  {
    "uniprot": "A0A044T385",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T385"
  },
  {
    "uniprot": "A0A044V160",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V160"
  },
  {
    "uniprot": "A0A044S6W3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6W3"
  },
  {
    "uniprot": "A0A044RC15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC15"
  },
  {
    "uniprot": "A0A044SXQ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXQ8"
  },
  {
    "uniprot": "A0A044T3J0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3J0"
  },
  {
    "uniprot": "A0A044RLY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLY4"
  },
  {
    "uniprot": "A0A044RTF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTF1"
  },
  {
    "uniprot": "A0A044V181",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V181"
  },
  {
    "uniprot": "A0A044R6F2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6F2"
  },
  {
    "uniprot": "A0A044UG82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UG82"
  },
  {
    "uniprot": "A0A044SQC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQC1"
  },
  {
    "uniprot": "A0A044S1I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1I9"
  },
  {
    "uniprot": "A0A044UJ04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJ04"
  },
  {
    "uniprot": "A0A044RM40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RM40"
  },
  {
    "uniprot": "A0A044QNX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNX1"
  },
  {
    "uniprot": "A0A044UH40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UH40"
  },
  {
    "uniprot": "A0A044T2Q6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2Q6"
  },
  {
    "uniprot": "A0A044SGC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGC9"
  },
  {
    "uniprot": "A0A044SYD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYD6"
  },
  {
    "uniprot": "A0A044QTT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTT2"
  },
  {
    "uniprot": "A0A044UAT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAT8"
  },
  {
    "uniprot": "A0A044VCR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCR6"
  },
  {
    "uniprot": "A0A044RW81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW81"
  },
  {
    "uniprot": "A0A044TNP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNP0"
  },
  {
    "uniprot": "A0A044RZT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZT1"
  },
  {
    "uniprot": "A0A044S768",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S768"
  },
  {
    "uniprot": "A0A044V972",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V972"
  },
  {
    "uniprot": "A0A044SEY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEY3"
  },
  {
    "uniprot": "A0A044UGA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGA0"
  },
  {
    "uniprot": "A0A2K6VT40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VT40"
  },
  {
    "uniprot": "A0A044V783",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V783"
  },
  {
    "uniprot": "A0A044SNS8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNS8"
  },
  {
    "uniprot": "A0A2K6WIS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIS9"
  },
  {
    "uniprot": "A0A044UWW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWW2"
  },
  {
    "uniprot": "A0A044R4D8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4D8"
  },
  {
    "uniprot": "A0A044TDL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDL6"
  },
  {
    "uniprot": "A0A2K6VID3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VID3"
  },
  {
    "uniprot": "A0A044T351",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T351"
  },
  {
    "uniprot": "A0A044UCI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCI7"
  },
  {
    "uniprot": "A0A044U827",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U827"
  },
  {
    "uniprot": "A0A044R795",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R795"
  },
  {
    "uniprot": "A0A044RSI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSI1"
  },
  {
    "uniprot": "A0A044SWP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWP5"
  },
  {
    "uniprot": "A0A044STX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STX8"
  },
  {
    "uniprot": "A0A044U5A0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5A0"
  },
  {
    "uniprot": "A0A044RHP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHP4"
  },
  {
    "uniprot": "A0A044SJG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJG6"
  },
  {
    "uniprot": "A0A044RJN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJN7"
  },
  {
    "uniprot": "A0A2K6VKZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKZ8"
  },
  {
    "uniprot": "A0A2K6VGV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VGV8"
  },
  {
    "uniprot": "A0A044TT79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TT79"
  },
  {
    "uniprot": "A0A044VCC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCC9"
  },
  {
    "uniprot": "A0A044V4W9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4W9"
  },
  {
    "uniprot": "A0A044UZ74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ74"
  },
  {
    "uniprot": "A0A044SX31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SX31"
  },
  {
    "uniprot": "A0A044UM23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UM23"
  },
  {
    "uniprot": "A0A044SD58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD58"
  },
  {
    "uniprot": "A0A044RDS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDS4"
  },
  {
    "uniprot": "A0A044SAT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAT4"
  },
  {
    "uniprot": "A0A044RAA8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAA8"
  },
  {
    "uniprot": "A0A044SDY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDY5"
  },
  {
    "uniprot": "A0A044UP40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UP40"
  },
  {
    "uniprot": "A0A044SB21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB21"
  },
  {
    "uniprot": "A0A044R7Y3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7Y3"
  },
  {
    "uniprot": "A0A044S341",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S341"
  },
  {
    "uniprot": "A0A044QM54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QM54"
  },
  {
    "uniprot": "A0A044V0L9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0L9"
  },
  {
    "uniprot": "A0A044U4C5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4C5"
  },
  {
    "uniprot": "A0A044U0T6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0T6"
  },
  {
    "uniprot": "A0A044RAN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAN6"
  },
  {
    "uniprot": "A0A044VJ45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJ45"
  },
  {
    "uniprot": "A0A044TD44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TD44"
  },
  {
    "uniprot": "A0A044QQB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQB8"
  },
  {
    "uniprot": "A0A044ST03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ST03"
  },
  {
    "uniprot": "A0A044V0Y9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0Y9"
  },
  {
    "uniprot": "A0A044R5B3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5B3"
  },
  {
    "uniprot": "A0A2K6WFQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFQ0"
  },
  {
    "uniprot": "A0A044TXZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXZ3"
  },
  {
    "uniprot": "A0A044TUL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUL0"
  },
  {
    "uniprot": "A0A044U889",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U889"
  },
  {
    "uniprot": "A0A044UAZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAZ0"
  },
  {
    "uniprot": "A0A044R263",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R263"
  },
  {
    "uniprot": "A0A2K6W0D4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0D4"
  },
  {
    "uniprot": "A0A044UR59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UR59"
  },
  {
    "uniprot": "A0A044SYA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYA7"
  },
  {
    "uniprot": "A0A044SAK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAK8"
  },
  {
    "uniprot": "A0A044UFZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFZ7"
  },
  {
    "uniprot": "A0A044TWJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWJ9"
  },
  {
    "uniprot": "A0A044TND9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TND9"
  },
  {
    "uniprot": "A0A044TBX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBX7"
  },
  {
    "uniprot": "A0A044REB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REB5"
  },
  {
    "uniprot": "A0A2K6VW60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VW60"
  },
  {
    "uniprot": "A0A044RHN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHN6"
  },
  {
    "uniprot": "A0A2K6W7Z9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7Z9"
  },
  {
    "uniprot": "A0A044TWS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWS5"
  },
  {
    "uniprot": "A0A044UR68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UR68"
  },
  {
    "uniprot": "A0A044T008",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T008"
  },
  {
    "uniprot": "A0A044REU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REU7"
  },
  {
    "uniprot": "A0A044U250",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U250"
  },
  {
    "uniprot": "A0A044T1N2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1N2"
  },
  {
    "uniprot": "A0A044R9I3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9I3"
  },
  {
    "uniprot": "A0A044SW64",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SW64"
  },
  {
    "uniprot": "A0A044TNR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNR3"
  },
  {
    "uniprot": "A0A044RF43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RF43"
  },
  {
    "uniprot": "A0A044T487",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T487"
  },
  {
    "uniprot": "A0A044V391",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V391"
  },
  {
    "uniprot": "A0A044RGE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGE0"
  },
  {
    "uniprot": "A0A2K6WM07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WM07"
  },
  {
    "uniprot": "A0A158N8A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8A9"
  },
  {
    "uniprot": "A0A044SZ07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ07"
  },
  {
    "uniprot": "A0A044R3U9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3U9"
  },
  {
    "uniprot": "A0A044UG18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UG18"
  },
  {
    "uniprot": "A0A044TTV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTV1"
  },
  {
    "uniprot": "A0A044TY02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TY02"
  },
  {
    "uniprot": "A0A044S959",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S959"
  },
  {
    "uniprot": "A0A044VIY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIY6"
  },
  {
    "uniprot": "A0A044U6E4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6E4"
  },
  {
    "uniprot": "A0A044R296",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R296"
  },
  {
    "uniprot": "A0A044TL86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TL86"
  },
  {
    "uniprot": "A0A044RMX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMX9"
  },
  {
    "uniprot": "A0A044SH87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SH87"
  },
  {
    "uniprot": "A0A044TAS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAS9"
  },
  {
    "uniprot": "A0A044RGF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGF3"
  },
  {
    "uniprot": "A0A044U032",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U032"
  },
  {
    "uniprot": "A0A044UQ51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQ51"
  },
  {
    "uniprot": "A0A044U9U2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9U2"
  },
  {
    "uniprot": "A0A044REX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REX3"
  },
  {
    "uniprot": "A0A044TPT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPT1"
  },
  {
    "uniprot": "A0A044THA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THA4"
  },
  {
    "uniprot": "A0A044SZ34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ34"
  },
  {
    "uniprot": "A0A044V9W2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9W2"
  },
  {
    "uniprot": "A0A044V9Q6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9Q6"
  },
  {
    "uniprot": "A0A044V343",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V343"
  },
  {
    "uniprot": "A0A044RWM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWM1"
  },
  {
    "uniprot": "A0A044VCY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCY6"
  },
  {
    "uniprot": "A0A044T361",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T361"
  },
  {
    "uniprot": "A0A044U9M6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9M6"
  },
  {
    "uniprot": "A0A044RRC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRC1"
  },
  {
    "uniprot": "A0A044SXP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXP5"
  },
  {
    "uniprot": "A0A044S147",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S147"
  },
  {
    "uniprot": "A0A044VIM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIM1"
  },
  {
    "uniprot": "A0A044TRM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRM0"
  },
  {
    "uniprot": "A0A044SD05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD05"
  },
  {
    "uniprot": "A0A044S1K5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1K5"
  },
  {
    "uniprot": "A0A044VHP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHP4"
  },
  {
    "uniprot": "A0A044TYZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYZ8"
  },
  {
    "uniprot": "A0A044RPV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPV0"
  },
  {
    "uniprot": "A0A044QRS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRS5"
  },
  {
    "uniprot": "A0A044T7Q9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7Q9"
  },
  {
    "uniprot": "A0A044QT60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT60"
  },
  {
    "uniprot": "A0A044S426",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S426"
  },
  {
    "uniprot": "A0A044V9I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9I9"
  },
  {
    "uniprot": "A0A044RLC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLC6"
  },
  {
    "uniprot": "A0A044TQ15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQ15"
  },
  {
    "uniprot": "A0A044VAB2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAB2"
  },
  {
    "uniprot": "A0A044V9G5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9G5"
  },
  {
    "uniprot": "A0A044T7N0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7N0"
  },
  {
    "uniprot": "A0A2K6WMZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMZ9"
  },
  {
    "uniprot": "A0A2K6VFU2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFU2"
  },
  {
    "uniprot": "A0A044SGW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGW9"
  },
  {
    "uniprot": "A0A044SPT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPT6"
  },
  {
    "uniprot": "A0A2K6WJ83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJ83"
  },
  {
    "uniprot": "A0A044UV33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UV33"
  },
  {
    "uniprot": "A0A044T9U7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9U7"
  },
  {
    "uniprot": "A0A044R2I1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2I1"
  },
  {
    "uniprot": "A0A044RHY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHY8"
  },
  {
    "uniprot": "A0A044TA17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TA17"
  },
  {
    "uniprot": "A0A044V292",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V292"
  },
  {
    "uniprot": "A0A044UP80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UP80"
  },
  {
    "uniprot": "A0A044U8E2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8E2"
  },
  {
    "uniprot": "A0A044V172",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V172"
  },
  {
    "uniprot": "A0A044VAS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAS3"
  },
  {
    "uniprot": "A0A044V976",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V976"
  },
  {
    "uniprot": "A0A2K6VT45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VT45"
  },
  {
    "uniprot": "A0A044TBP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBP7"
  },
  {
    "uniprot": "A0A2K6VWZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWZ9"
  },
  {
    "uniprot": "A0A044QWN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWN0"
  },
  {
    "uniprot": "A0A2K6WKF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKF4"
  },
  {
    "uniprot": "A0A044T438",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T438"
  },
  {
    "uniprot": "A0A044TLW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLW0"
  },
  {
    "uniprot": "A0A2K6VU12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VU12"
  },
  {
    "uniprot": "A0A044V395",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V395"
  },
  {
    "uniprot": "A0A044T3I7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3I7"
  },
  {
    "uniprot": "A0A044SYU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYU7"
  },
  {
    "uniprot": "A0A044RZ41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZ41"
  },
  {
    "uniprot": "A0A044TVA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVA7"
  },
  {
    "uniprot": "A0A044T8F3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8F3"
  },
  {
    "uniprot": "A0A044QPA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPA1"
  },
  {
    "uniprot": "A0A044SCI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCI7"
  },
  {
    "uniprot": "A0A044UDG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDG0"
  },
  {
    "uniprot": "A0A044RQW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQW6"
  },
  {
    "uniprot": "A0A044S4R1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4R1"
  },
  {
    "uniprot": "A0A044RHV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHV6"
  },
  {
    "uniprot": "A0A044UHB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHB3"
  },
  {
    "uniprot": "A0A2K6W2M1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2M1"
  },
  {
    "uniprot": "A0A044RKD2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKD2"
  },
  {
    "uniprot": "A0A044RG61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RG61"
  },
  {
    "uniprot": "A0A044UYP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYP8"
  },
  {
    "uniprot": "A0A044S5A4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5A4"
  },
  {
    "uniprot": "A0A044U9L6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9L6"
  },
  {
    "uniprot": "A0A2K6W3M2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3M2"
  },
  {
    "uniprot": "A0A044RSD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSD1"
  },
  {
    "uniprot": "A0A044QZ39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ39"
  },
  {
    "uniprot": "A0A044R3X4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3X4"
  },
  {
    "uniprot": "A0A044UHD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHD7"
  },
  {
    "uniprot": "A0A044TNG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNG4"
  },
  {
    "uniprot": "A0A044SYX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYX2"
  },
  {
    "uniprot": "A0A044QRN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRN8"
  },
  {
    "uniprot": "A0A2K6VMY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMY0"
  },
  {
    "uniprot": "A0A044QZM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZM8"
  },
  {
    "uniprot": "A0A044TF90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TF90"
  },
  {
    "uniprot": "A0A044T3U0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3U0"
  },
  {
    "uniprot": "A0A044SHI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHI0"
  },
  {
    "uniprot": "A0A044V9P6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9P6"
  },
  {
    "uniprot": "A0A044QQJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQJ6"
  },
  {
    "uniprot": "A0A044RTT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTT0"
  },
  {
    "uniprot": "A0A044QW04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW04"
  },
  {
    "uniprot": "A0A2K6VI13",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VI13"
  },
  {
    "uniprot": "A0A044S595",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S595"
  },
  {
    "uniprot": "A0A044SG16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG16"
  },
  {
    "uniprot": "A0A044V1Y1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1Y1"
  },
  {
    "uniprot": "A0A2K6W4Q4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W4Q4"
  },
  {
    "uniprot": "A0A044UXM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXM7"
  },
  {
    "uniprot": "A0A044TSD9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSD9"
  },
  {
    "uniprot": "A0A044SRQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRQ3"
  },
  {
    "uniprot": "A0A044VIP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIP0"
  },
  {
    "uniprot": "A0A044VEX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEX5"
  },
  {
    "uniprot": "A0A044TG21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TG21"
  },
  {
    "uniprot": "A0A044R5Y3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5Y3"
  },
  {
    "uniprot": "A0A044QXY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXY7"
  },
  {
    "uniprot": "A0A044S787",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S787"
  },
  {
    "uniprot": "A0A044SPY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPY2"
  },
  {
    "uniprot": "A0A044TQY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQY2"
  },
  {
    "uniprot": "A0A2K6W536",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W536"
  },
  {
    "uniprot": "A0A044RR76",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RR76"
  },
  {
    "uniprot": "A0A044QKD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKD1"
  },
  {
    "uniprot": "A0A044UZL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZL4"
  },
  {
    "uniprot": "A0A044QLY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLY0"
  },
  {
    "uniprot": "A0A158N8G8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8G8"
  },
  {
    "uniprot": "A0A044V702",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V702"
  },
  {
    "uniprot": "A0A158N8S4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8S4"
  },
  {
    "uniprot": "A0A044U6U2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6U2"
  },
  {
    "uniprot": "A0A044UTT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTT7"
  },
  {
    "uniprot": "A0A044R153",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R153"
  },
  {
    "uniprot": "A0A044SGG3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGG3"
  },
  {
    "uniprot": "A0A044UE95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE95"
  },
  {
    "uniprot": "A0A044THE8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THE8"
  },
  {
    "uniprot": "A0A044V3C3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3C3"
  },
  {
    "uniprot": "A0A044V562",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V562"
  },
  {
    "uniprot": "A0A044UKS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKS4"
  },
  {
    "uniprot": "A0A044RGA8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGA8"
  },
  {
    "uniprot": "A0A044R2U8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2U8"
  },
  {
    "uniprot": "A0A044UZB2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZB2"
  },
  {
    "uniprot": "A0A044S361",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S361"
  },
  {
    "uniprot": "A0A2K6VW44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VW44"
  },
  {
    "uniprot": "A0A044T9Q7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9Q7"
  },
  {
    "uniprot": "A0A2K6VZM9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZM9"
  },
  {
    "uniprot": "A0A044R690",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R690"
  },
  {
    "uniprot": "A0A044RYC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYC6"
  },
  {
    "uniprot": "A0A044V413",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V413"
  },
  {
    "uniprot": "A0A044QNW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNW3"
  },
  {
    "uniprot": "A0A044RKB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKB0"
  },
  {
    "uniprot": "A0A044S3T6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3T6"
  },
  {
    "uniprot": "A0A2K6WBX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBX5"
  },
  {
    "uniprot": "A0A158N7W6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7W6"
  },
  {
    "uniprot": "A0A044UZ90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ90"
  },
  {
    "uniprot": "A0A044T9M8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9M8"
  },
  {
    "uniprot": "A0A044T183",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T183"
  },
  {
    "uniprot": "A0A044VCS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCS3"
  },
  {
    "uniprot": "A0A044UA12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA12"
  },
  {
    "uniprot": "A0A044SUL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUL1"
  },
  {
    "uniprot": "A0A044S586",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S586"
  },
  {
    "uniprot": "A0A044VE55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VE55"
  },
  {
    "uniprot": "A0A044UTR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTR4"
  },
  {
    "uniprot": "A0A044QLU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLU8"
  },
  {
    "uniprot": "A0A044RMN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMN6"
  },
  {
    "uniprot": "A0A044TTF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTF1"
  },
  {
    "uniprot": "A0A044SGH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGH5"
  },
  {
    "uniprot": "A0A044RIP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIP0"
  },
  {
    "uniprot": "A0A044U579",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U579"
  },
  {
    "uniprot": "A0A044V415",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V415"
  },
  {
    "uniprot": "A0A044SXQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXQ4"
  },
  {
    "uniprot": "A0A044STL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STL1"
  },
  {
    "uniprot": "A0A044T526",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T526"
  },
  {
    "uniprot": "A0A044R5N6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5N6"
  },
  {
    "uniprot": "A0A044U639",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U639"
  },
  {
    "uniprot": "A0A158N8J7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8J7"
  },
  {
    "uniprot": "A0A044VEI5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEI5"
  },
  {
    "uniprot": "A0A044UWX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWX6"
  },
  {
    "uniprot": "A0A044V4F8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4F8"
  },
  {
    "uniprot": "A0A044QPL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPL9"
  },
  {
    "uniprot": "A0A044RHU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHU0"
  },
  {
    "uniprot": "A0A044V3G3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3G3"
  },
  {
    "uniprot": "A0A044RE78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RE78"
  },
  {
    "uniprot": "A0A044RPN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPN5"
  },
  {
    "uniprot": "A0A044UIU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIU9"
  },
  {
    "uniprot": "A0A044VC18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC18"
  },
  {
    "uniprot": "A0A044V629",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V629"
  },
  {
    "uniprot": "A0A044SYI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYI7"
  },
  {
    "uniprot": "A0A2K6WL38",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WL38"
  },
  {
    "uniprot": "A0A044TGG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGG8"
  },
  {
    "uniprot": "A0A044UQH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQH6"
  },
  {
    "uniprot": "A0A044TJ15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJ15"
  },
  {
    "uniprot": "A0A044T6B0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6B0"
  },
  {
    "uniprot": "A0A044RBM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBM4"
  },
  {
    "uniprot": "A0A044TJK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJK8"
  },
  {
    "uniprot": "A0A2K6WET7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WET7"
  },
  {
    "uniprot": "A0A2K6WDE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDE3"
  },
  {
    "uniprot": "A0A044T3K3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3K3"
  },
  {
    "uniprot": "A0A044VFN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFN1"
  },
  {
    "uniprot": "A0A044SRK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRK2"
  },
  {
    "uniprot": "A0A044RZH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZH3"
  },
  {
    "uniprot": "A0A044TZQ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZQ2"
  },
  {
    "uniprot": "A0A044QVY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVY0"
  },
  {
    "uniprot": "A0A044TEH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEH4"
  },
  {
    "uniprot": "A0A044T3B9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3B9"
  },
  {
    "uniprot": "A0A044QVP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVP1"
  },
  {
    "uniprot": "A0A2K6W8E3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8E3"
  },
  {
    "uniprot": "A0A2K6WB10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WB10"
  },
  {
    "uniprot": "A0A044SFB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFB6"
  },
  {
    "uniprot": "A0A044U6U6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6U6"
  },
  {
    "uniprot": "A0A044R142",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R142"
  },
  {
    "uniprot": "A0A044RLY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLY8"
  },
  {
    "uniprot": "A0A2K6VHX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHX5"
  },
  {
    "uniprot": "A0A044TMQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMQ3"
  },
  {
    "uniprot": "A0A044SJB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJB4"
  },
  {
    "uniprot": "A0A044UIW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIW0"
  },
  {
    "uniprot": "A0A044TDD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDD6"
  },
  {
    "uniprot": "A0A044V714",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V714"
  },
  {
    "uniprot": "A0A2K6VM61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VM61"
  },
  {
    "uniprot": "A0A044U6Q3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6Q3"
  },
  {
    "uniprot": "A0A044R4U7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4U7"
  },
  {
    "uniprot": "A0A044SDG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDG7"
  },
  {
    "uniprot": "A0A044TMN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMN4"
  },
  {
    "uniprot": "A0A044RQ50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQ50"
  },
  {
    "uniprot": "A0A044UXQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXQ7"
  },
  {
    "uniprot": "A0A044SWL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWL2"
  },
  {
    "uniprot": "A0A044R4F7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4F7"
  },
  {
    "uniprot": "A0A044UWE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWE4"
  },
  {
    "uniprot": "A0A2K6W5P4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W5P4"
  },
  {
    "uniprot": "A0A044S7Y8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7Y8"
  },
  {
    "uniprot": "A0A044U5P6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5P6"
  },
  {
    "uniprot": "A0A044QKU2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKU2"
  },
  {
    "uniprot": "A0A044RM44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RM44"
  },
  {
    "uniprot": "A0A044VBU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBU1"
  },
  {
    "uniprot": "A0A044U2R2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2R2"
  },
  {
    "uniprot": "A0A2K6VZI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZI7"
  },
  {
    "uniprot": "A0A158N8P0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8P0"
  },
  {
    "uniprot": "A0A044SXT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXT7"
  },
  {
    "uniprot": "A0A044UPS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPS3"
  },
  {
    "uniprot": "A0A044S6Z0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6Z0"
  },
  {
    "uniprot": "A0A044UCV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCV6"
  },
  {
    "uniprot": "A0A044STZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STZ1"
  },
  {
    "uniprot": "A0A044V101",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V101"
  },
  {
    "uniprot": "A0A044TI11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TI11"
  },
  {
    "uniprot": "A0A044SIZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIZ2"
  },
  {
    "uniprot": "A0A044UAH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAH2"
  },
  {
    "uniprot": "A0A044UWT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWT8"
  },
  {
    "uniprot": "A0A044S662",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S662"
  },
  {
    "uniprot": "A0A044VH19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH19"
  },
  {
    "uniprot": "A0A044TK60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK60"
  },
  {
    "uniprot": "A0A044V4G4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4G4"
  },
  {
    "uniprot": "A0A044U843",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U843"
  },
  {
    "uniprot": "A0A2K6W5D5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W5D5"
  },
  {
    "uniprot": "A0A044RW00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW00"
  },
  {
    "uniprot": "A0A044SER6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SER6"
  },
  {
    "uniprot": "A0A2K6VZW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZW8"
  },
  {
    "uniprot": "A0A044QWU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWU9"
  },
  {
    "uniprot": "A0A044R6V0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6V0"
  },
  {
    "uniprot": "A0A044SJF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJF0"
  },
  {
    "uniprot": "A0A044V709",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V709"
  },
  {
    "uniprot": "A0A044SS50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS50"
  },
  {
    "uniprot": "A0A044V9W0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9W0"
  },
  {
    "uniprot": "A0A044UNL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNL3"
  },
  {
    "uniprot": "A0A044U5C2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5C2"
  },
  {
    "uniprot": "A0A2K6WC09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WC09"
  },
  {
    "uniprot": "A0A044UTI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTI7"
  },
  {
    "uniprot": "A0A044TP39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TP39"
  },
  {
    "uniprot": "A0A044V7E3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7E3"
  },
  {
    "uniprot": "A0A044RD07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RD07"
  },
  {
    "uniprot": "A0A044QPW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPW3"
  },
  {
    "uniprot": "A0A044SHH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHH3"
  },
  {
    "uniprot": "A0A044QKD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKD6"
  },
  {
    "uniprot": "A0A158N8A4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8A4"
  },
  {
    "uniprot": "A0A2K6VWG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWG0"
  },
  {
    "uniprot": "A0A044T9D2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9D2"
  },
  {
    "uniprot": "A0A044QZ52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ52"
  },
  {
    "uniprot": "A0A044RQ68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQ68"
  },
  {
    "uniprot": "A0A044QMV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMV9"
  },
  {
    "uniprot": "A0A044RIY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIY9"
  },
  {
    "uniprot": "A0A044UQC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQC5"
  },
  {
    "uniprot": "A0A044RAW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAW9"
  },
  {
    "uniprot": "A0A044UIS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIS2"
  },
  {
    "uniprot": "A0A044RTU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTU0"
  },
  {
    "uniprot": "A0A044VEE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEE0"
  },
  {
    "uniprot": "A0A2K6WFB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFB9"
  },
  {
    "uniprot": "A0A044SS19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS19"
  },
  {
    "uniprot": "A0A044VF37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VF37"
  },
  {
    "uniprot": "A0A044V7E8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7E8"
  },
  {
    "uniprot": "A0A044QXT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXT0"
  },
  {
    "uniprot": "A0A044UKS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKS6"
  },
  {
    "uniprot": "A0A044T3M8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3M8"
  },
  {
    "uniprot": "A0A2K6W5H3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W5H3"
  },
  {
    "uniprot": "A0A044TFT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFT4"
  },
  {
    "uniprot": "A0A044V8Y0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Y0"
  },
  {
    "uniprot": "A0A044V1W5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1W5"
  },
  {
    "uniprot": "A0A158N8H1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8H1"
  },
  {
    "uniprot": "A0A044TWG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWG2"
  },
  {
    "uniprot": "A0A044V1H9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1H9"
  },
  {
    "uniprot": "A0A044TDS8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDS8"
  },
  {
    "uniprot": "A0A044QLF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLF3"
  },
  {
    "uniprot": "A0A044TL01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TL01"
  },
  {
    "uniprot": "A0A044R227",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R227"
  },
  {
    "uniprot": "A0A044UMQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMQ0"
  },
  {
    "uniprot": "A0A044UBN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBN8"
  },
  {
    "uniprot": "A0A044QX24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QX24"
  },
  {
    "uniprot": "A0A044VHY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHY9"
  },
  {
    "uniprot": "A0A044RG44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RG44"
  },
  {
    "uniprot": "A0A044RM60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RM60"
  },
  {
    "uniprot": "A0A2K6W3C2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3C2"
  },
  {
    "uniprot": "A0A044UJI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJI9"
  },
  {
    "uniprot": "A0A044T691",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T691"
  },
  {
    "uniprot": "A0A044RAW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAW3"
  },
  {
    "uniprot": "A0A044V5X1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5X1"
  },
  {
    "uniprot": "A0A044T356",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T356"
  },
  {
    "uniprot": "A0A044SMA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMA4"
  },
  {
    "uniprot": "A0A044RLU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLU9"
  },
  {
    "uniprot": "A0A044TEK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEK4"
  },
  {
    "uniprot": "A0A044RZ47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZ47"
  },
  {
    "uniprot": "A0A044RV13",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RV13"
  },
  {
    "uniprot": "A0A044RKV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKV6"
  },
  {
    "uniprot": "A0A044SHW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHW3"
  },
  {
    "uniprot": "A0A044UH98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UH98"
  },
  {
    "uniprot": "A0A158N894",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N894"
  },
  {
    "uniprot": "A0A044T2P2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2P2"
  },
  {
    "uniprot": "A0A044U658",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U658"
  },
  {
    "uniprot": "A0A044RJP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJP9"
  },
  {
    "uniprot": "A0A044TX69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TX69"
  },
  {
    "uniprot": "A0A044THY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THY8"
  },
  {
    "uniprot": "A0A044RNU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNU1"
  },
  {
    "uniprot": "A0A044QLE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLE0"
  },
  {
    "uniprot": "A0A044URG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URG8"
  },
  {
    "uniprot": "A0A044S8K1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8K1"
  },
  {
    "uniprot": "A0A2K6WKZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKZ7"
  },
  {
    "uniprot": "A0A044S3J6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3J6"
  },
  {
    "uniprot": "A0A044S6W1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6W1"
  },
  {
    "uniprot": "A0A044QPE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPE5"
  },
  {
    "uniprot": "A0A044V476",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V476"
  },
  {
    "uniprot": "A0A044VJ59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJ59"
  },
  {
    "uniprot": "A0A044VA37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA37"
  },
  {
    "uniprot": "A0A044QRI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRI9"
  },
  {
    "uniprot": "A0A044UYZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYZ6"
  },
  {
    "uniprot": "A0A044T7M2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7M2"
  },
  {
    "uniprot": "A0A044UZC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZC0"
  },
  {
    "uniprot": "A0A044S502",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S502"
  },
  {
    "uniprot": "A0A044V447",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V447"
  },
  {
    "uniprot": "A0A044QYT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYT2"
  },
  {
    "uniprot": "A0A044VIK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIK6"
  },
  {
    "uniprot": "A0A044RSM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSM7"
  },
  {
    "uniprot": "A0A044QXM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXM3"
  },
  {
    "uniprot": "A0A044SZN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZN0"
  },
  {
    "uniprot": "A0A044V948",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V948"
  },
  {
    "uniprot": "A0A2K6VD48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VD48"
  },
  {
    "uniprot": "A0A044RSP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSP8"
  },
  {
    "uniprot": "A0A044UW34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UW34"
  },
  {
    "uniprot": "A0A158N7Q5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Q5"
  },
  {
    "uniprot": "A0A044V719",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V719"
  },
  {
    "uniprot": "A0A044TM20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TM20"
  },
  {
    "uniprot": "A0A044RF18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RF18"
  },
  {
    "uniprot": "A0A044SIG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIG1"
  },
  {
    "uniprot": "A0A044SC86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SC86"
  },
  {
    "uniprot": "A0A044RJE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJE1"
  },
  {
    "uniprot": "A0A044V4H3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4H3"
  },
  {
    "uniprot": "A0A044SA77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SA77"
  },
  {
    "uniprot": "A0A044QZ40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ40"
  },
  {
    "uniprot": "A0A044TRM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRM5"
  },
  {
    "uniprot": "A0A044RJQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJQ9"
  },
  {
    "uniprot": "A0A044U162",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U162"
  },
  {
    "uniprot": "A0A2K6W5X9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W5X9"
  },
  {
    "uniprot": "A0A044UHF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHF6"
  },
  {
    "uniprot": "A0A044TD81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TD81"
  },
  {
    "uniprot": "A0A044UMJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMJ1"
  },
  {
    "uniprot": "A0A044T4L7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4L7"
  },
  {
    "uniprot": "A0A044RDU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDU4"
  },
  {
    "uniprot": "A0A044QRK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRK6"
  },
  {
    "uniprot": "A0A044S3R8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3R8"
  },
  {
    "uniprot": "A0A044QYI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYI3"
  },
  {
    "uniprot": "A0A044R5G5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5G5"
  },
  {
    "uniprot": "A0A044TY20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TY20"
  },
  {
    "uniprot": "A0A044V3M2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3M2"
  },
  {
    "uniprot": "A0A044RV45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RV45"
  },
  {
    "uniprot": "A0A044RKT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKT8"
  },
  {
    "uniprot": "A0A044SFF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFF3"
  },
  {
    "uniprot": "A0A044V589",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V589"
  },
  {
    "uniprot": "A0A044UZW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZW8"
  },
  {
    "uniprot": "A0A2K6VR97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VR97"
  },
  {
    "uniprot": "A0A044QJY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJY2"
  },
  {
    "uniprot": "A0A044R6N2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6N2"
  },
  {
    "uniprot": "A0A2K6W5X5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W5X5"
  },
  {
    "uniprot": "A0A044TQS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQS3"
  },
  {
    "uniprot": "A0A2K6W092",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W092"
  },
  {
    "uniprot": "A0A044RNG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNG7"
  },
  {
    "uniprot": "A0A044TL16",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TL16"
  },
  {
    "uniprot": "A0A044RB25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RB25"
  },
  {
    "uniprot": "A0A044T2L5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2L5"
  },
  {
    "uniprot": "A0A044RIG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIG9"
  },
  {
    "uniprot": "A0A2K6VET4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VET4"
  },
  {
    "uniprot": "A0A044URQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URQ3"
  },
  {
    "uniprot": "A0A044UZ71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ71"
  },
  {
    "uniprot": "A0A044T9P0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9P0"
  },
  {
    "uniprot": "A0A044V453",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V453"
  },
  {
    "uniprot": "A0A044SJM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJM0"
  },
  {
    "uniprot": "A0A044RS49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RS49"
  },
  {
    "uniprot": "A0A044SCZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCZ7"
  },
  {
    "uniprot": "A0A044UXB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXB8"
  },
  {
    "uniprot": "A0A044UMI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMI6"
  },
  {
    "uniprot": "A0A044SXB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXB9"
  },
  {
    "uniprot": "A0A044SLP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLP8"
  },
  {
    "uniprot": "A0A044V165",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V165"
  },
  {
    "uniprot": "A0A044SQ37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ37"
  },
  {
    "uniprot": "A0A044TJI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJI1"
  },
  {
    "uniprot": "A0A044RVH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVH2"
  },
  {
    "uniprot": "A0A044UX09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX09"
  },
  {
    "uniprot": "A0A2K6WLM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLM4"
  },
  {
    "uniprot": "A0A044QV79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QV79"
  },
  {
    "uniprot": "A0A044RJP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJP2"
  },
  {
    "uniprot": "A0A044S3E3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3E3"
  },
  {
    "uniprot": "A0A044RZG3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZG3"
  },
  {
    "uniprot": "A0A2K6WDN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDN1"
  },
  {
    "uniprot": "A0A044RMW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMW5"
  },
  {
    "uniprot": "A0A044T6H9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6H9"
  },
  {
    "uniprot": "A0A044RUT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUT9"
  },
  {
    "uniprot": "A0A044SZW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZW7"
  },
  {
    "uniprot": "A0A2K6VW69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VW69"
  },
  {
    "uniprot": "A0A044U1Z0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1Z0"
  },
  {
    "uniprot": "A0A2K6VRV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRV0"
  },
  {
    "uniprot": "A0A044S9J8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9J8"
  },
  {
    "uniprot": "A0A044R8J2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8J2"
  },
  {
    "uniprot": "A0A044VCE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCE2"
  },
  {
    "uniprot": "A0A044TAW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAW2"
  },
  {
    "uniprot": "A0A044UN31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UN31"
  },
  {
    "uniprot": "A0A158N8G0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8G0"
  },
  {
    "uniprot": "A0A044R268",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R268"
  },
  {
    "uniprot": "A0A044T252",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T252"
  },
  {
    "uniprot": "A0A044V0J3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0J3"
  },
  {
    "uniprot": "A0A044T4G0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4G0"
  },
  {
    "uniprot": "A0A044RLU2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLU2"
  },
  {
    "uniprot": "A0A044UW41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UW41"
  },
  {
    "uniprot": "A0A044USE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USE3"
  },
  {
    "uniprot": "A0A2K6VXM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXM5"
  },
  {
    "uniprot": "A0A044UZ02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ02"
  },
  {
    "uniprot": "A0A2K6VIH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIH2"
  },
  {
    "uniprot": "A0A044UIH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIH1"
  },
  {
    "uniprot": "A0A044S6L2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6L2"
  },
  {
    "uniprot": "A0A044T748",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T748"
  },
  {
    "uniprot": "A0A044US75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044US75"
  },
  {
    "uniprot": "A0A044UCA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCA4"
  },
  {
    "uniprot": "A0A044RS24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RS24"
  },
  {
    "uniprot": "A0A044S1R0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1R0"
  },
  {
    "uniprot": "A0A044U0U3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0U3"
  },
  {
    "uniprot": "A0A044S122",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S122"
  },
  {
    "uniprot": "A0A044QXR5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXR5"
  },
  {
    "uniprot": "A0A044UFS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFS2"
  },
  {
    "uniprot": "A0A044VHP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHP8"
  },
  {
    "uniprot": "A0A044RI42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RI42"
  },
  {
    "uniprot": "A0A044V9X2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9X2"
  },
  {
    "uniprot": "A0A2K6VKZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKZ7"
  },
  {
    "uniprot": "A0A044T0Y8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0Y8"
  },
  {
    "uniprot": "A0A044T0P0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0P0"
  },
  {
    "uniprot": "A0A044U5X5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5X5"
  },
  {
    "uniprot": "A0A2K6WJF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJF0"
  },
  {
    "uniprot": "A0A044TC20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC20"
  },
  {
    "uniprot": "A0A044RM28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RM28"
  },
  {
    "uniprot": "A0A044URF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URF8"
  },
  {
    "uniprot": "A0A044T9V8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9V8"
  },
  {
    "uniprot": "A0A044U690",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U690"
  },
  {
    "uniprot": "A0A044U4Y1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4Y1"
  },
  {
    "uniprot": "A0A044R683",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R683"
  },
  {
    "uniprot": "A0A158N7W8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7W8"
  },
  {
    "uniprot": "A0A044R556",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R556"
  },
  {
    "uniprot": "A0A044RBE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBE0"
  },
  {
    "uniprot": "A0A044V0G6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0G6"
  },
  {
    "uniprot": "A0A044UMN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMN6"
  },
  {
    "uniprot": "A0A044T908",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T908"
  },
  {
    "uniprot": "A0A044RZN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZN3"
  },
  {
    "uniprot": "A0A044RLI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLI7"
  },
  {
    "uniprot": "A0A044T3A5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3A5"
  },
  {
    "uniprot": "A0A044S5B2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5B2"
  },
  {
    "uniprot": "A0A044TXB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXB3"
  },
  {
    "uniprot": "A0A044RH32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH32"
  },
  {
    "uniprot": "A0A044REU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REU3"
  },
  {
    "uniprot": "A0A2K6WBK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBK3"
  },
  {
    "uniprot": "A0A044RPG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPG2"
  },
  {
    "uniprot": "A0A044RPG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPG7"
  },
  {
    "uniprot": "A0A044RNW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNW9"
  },
  {
    "uniprot": "A0A044VGY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGY5"
  },
  {
    "uniprot": "A0A044RDL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDL3"
  },
  {
    "uniprot": "A0A044SFX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFX1"
  },
  {
    "uniprot": "A0A044VFJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFJ9"
  },
  {
    "uniprot": "A0A044SVZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVZ2"
  },
  {
    "uniprot": "A0A044VIM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIM7"
  },
  {
    "uniprot": "A0A044V000",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V000"
  },
  {
    "uniprot": "A0A044R3E8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3E8"
  },
  {
    "uniprot": "A0A044TRX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRX2"
  },
  {
    "uniprot": "A0A044RRA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRA6"
  },
  {
    "uniprot": "A0A044V151",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V151"
  },
  {
    "uniprot": "A0A044T670",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T670"
  },
  {
    "uniprot": "A0A044UGQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGQ5"
  },
  {
    "uniprot": "A0A044VAA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAA7"
  },
  {
    "uniprot": "A0A044TAY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAY8"
  },
  {
    "uniprot": "A0A044SMH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMH4"
  },
  {
    "uniprot": "A0A044SE20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SE20"
  },
  {
    "uniprot": "A0A044UX94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX94"
  },
  {
    "uniprot": "A0A044VJM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJM2"
  },
  {
    "uniprot": "A0A044V905",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V905"
  },
  {
    "uniprot": "A0A044SJ88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJ88"
  },
  {
    "uniprot": "A0A044SBP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBP3"
  },
  {
    "uniprot": "A0A2K6VVT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVT1"
  },
  {
    "uniprot": "A0A044RS55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RS55"
  },
  {
    "uniprot": "A0A044S3C7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3C7"
  },
  {
    "uniprot": "A0A044SL72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SL72"
  },
  {
    "uniprot": "A0A044U151",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U151"
  },
  {
    "uniprot": "A0A044QKF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKF4"
  },
  {
    "uniprot": "A0A2K6VU42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VU42"
  },
  {
    "uniprot": "A0A044S7Z7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7Z7"
  },
  {
    "uniprot": "A0A2K6WIH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIH0"
  },
  {
    "uniprot": "A0A044T045",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T045"
  },
  {
    "uniprot": "A0A044RZL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZL0"
  },
  {
    "uniprot": "A0A044R8K4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8K4"
  },
  {
    "uniprot": "A0A044T7H0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7H0"
  },
  {
    "uniprot": "A0A2K6VUN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUN4"
  },
  {
    "uniprot": "A0A2K6VS56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VS56"
  },
  {
    "uniprot": "A0A044RQD0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQD0"
  },
  {
    "uniprot": "A0A044RGX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGX2"
  },
  {
    "uniprot": "A0A044UYX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYX2"
  },
  {
    "uniprot": "A0A044S5E2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5E2"
  },
  {
    "uniprot": "A0A044V7M9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7M9"
  },
  {
    "uniprot": "A0A044SFK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFK0"
  },
  {
    "uniprot": "A0A044QLS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLS0"
  },
  {
    "uniprot": "A0A044SNL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNL0"
  },
  {
    "uniprot": "A0A158N8U3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8U3"
  },
  {
    "uniprot": "A0A044UKV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKV4"
  },
  {
    "uniprot": "A0A044RG99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RG99"
  },
  {
    "uniprot": "A0A044SW97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SW97"
  },
  {
    "uniprot": "A0A044TH40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH40"
  },
  {
    "uniprot": "A0A044UET4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UET4"
  },
  {
    "uniprot": "A0A044UFC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFC9"
  },
  {
    "uniprot": "A0A044RHB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHB9"
  },
  {
    "uniprot": "A0A044STN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STN3"
  },
  {
    "uniprot": "A0A044UCE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCE0"
  },
  {
    "uniprot": "A0A044SAS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAS9"
  },
  {
    "uniprot": "A0A044S976",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S976"
  },
  {
    "uniprot": "A0A044UAD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAD3"
  },
  {
    "uniprot": "A0A044RHL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHL6"
  },
  {
    "uniprot": "A0A044UU01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UU01"
  },
  {
    "uniprot": "A0A044SI92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI92"
  },
  {
    "uniprot": "A0A044SA24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SA24"
  },
  {
    "uniprot": "A0A044QJN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJN4"
  },
  {
    "uniprot": "A0A044UML6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UML6"
  },
  {
    "uniprot": "A0A044QUZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUZ4"
  },
  {
    "uniprot": "A0A2K6VI36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VI36"
  },
  {
    "uniprot": "A0A044V460",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V460"
  },
  {
    "uniprot": "A0A158N8J3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8J3"
  },
  {
    "uniprot": "A0A2K6W413",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W413"
  },
  {
    "uniprot": "A0A044V741",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V741"
  },
  {
    "uniprot": "A0A044V477",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V477"
  },
  {
    "uniprot": "A0A044U6A9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6A9"
  },
  {
    "uniprot": "A0A044V6M2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6M2"
  },
  {
    "uniprot": "A0A044SW94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SW94"
  },
  {
    "uniprot": "A0A2K6W082",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W082"
  },
  {
    "uniprot": "A0A2K6W459",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W459"
  },
  {
    "uniprot": "A0A044QSR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSR3"
  },
  {
    "uniprot": "A0A044SN29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN29"
  },
  {
    "uniprot": "A0A044TYJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYJ6"
  },
  {
    "uniprot": "A0A044R6D0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6D0"
  },
  {
    "uniprot": "A0A044QQR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQR3"
  },
  {
    "uniprot": "A0A044S2F7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2F7"
  },
  {
    "uniprot": "A0A044T4S0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4S0"
  },
  {
    "uniprot": "A0A044V4A4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4A4"
  },
  {
    "uniprot": "A0A044U917",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U917"
  },
  {
    "uniprot": "A0A044SQQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQQ1"
  },
  {
    "uniprot": "A0A2K6W0U6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0U6"
  },
  {
    "uniprot": "A0A044QZQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZQ7"
  },
  {
    "uniprot": "A0A044T224",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T224"
  },
  {
    "uniprot": "A0A044SY25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY25"
  },
  {
    "uniprot": "A0A044TSM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSM7"
  },
  {
    "uniprot": "A0A044U3X7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3X7"
  },
  {
    "uniprot": "A0A044RD74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RD74"
  },
  {
    "uniprot": "A0A044V555",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V555"
  },
  {
    "uniprot": "A0A044TWI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWI9"
  },
  {
    "uniprot": "A0A044VGZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGZ1"
  },
  {
    "uniprot": "A0A044SEA8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEA8"
  },
  {
    "uniprot": "A0A044SK44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK44"
  },
  {
    "uniprot": "A0A044T2F9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2F9"
  },
  {
    "uniprot": "A0A044SHC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHC1"
  },
  {
    "uniprot": "A0A044U8S4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8S4"
  },
  {
    "uniprot": "A0A044TM49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TM49"
  },
  {
    "uniprot": "A0A044SUW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUW2"
  },
  {
    "uniprot": "A0A044S8X2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8X2"
  },
  {
    "uniprot": "A0A044UYK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYK1"
  },
  {
    "uniprot": "A0A044TIU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIU9"
  },
  {
    "uniprot": "A0A2K6W2I5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2I5"
  },
  {
    "uniprot": "A0A044V452",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V452"
  },
  {
    "uniprot": "A0A044V433",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V433"
  },
  {
    "uniprot": "A0A044T082",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T082"
  },
  {
    "uniprot": "A0A044V8X6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8X6"
  },
  {
    "uniprot": "A0A044QJR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJR0"
  },
  {
    "uniprot": "A0A2K6VI85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VI85"
  },
  {
    "uniprot": "A0A044UEA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEA6"
  },
  {
    "uniprot": "A0A044V5S3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5S3"
  },
  {
    "uniprot": "A0A2K6WK34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WK34"
  },
  {
    "uniprot": "A0A044RY73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY73"
  },
  {
    "uniprot": "A0A044V935",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V935"
  },
  {
    "uniprot": "A0A044QK28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK28"
  },
  {
    "uniprot": "A0A044R3H8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3H8"
  },
  {
    "uniprot": "A0A2K6VT96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VT96"
  },
  {
    "uniprot": "A0A044QVE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVE9"
  },
  {
    "uniprot": "A0A2K6VYY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYY9"
  },
  {
    "uniprot": "A0A044SSU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSU6"
  },
  {
    "uniprot": "A0A044U7D2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7D2"
  },
  {
    "uniprot": "A0A044V4N4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4N4"
  },
  {
    "uniprot": "A0A044T5F9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5F9"
  },
  {
    "uniprot": "A0A044R6C7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6C7"
  },
  {
    "uniprot": "A0A2K6VY59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VY59"
  },
  {
    "uniprot": "A0A044RKK7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKK7"
  },
  {
    "uniprot": "A0A2K6WJE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJE9"
  },
  {
    "uniprot": "A0A044UFM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFM5"
  },
  {
    "uniprot": "A0A044QN39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QN39"
  },
  {
    "uniprot": "A0A044UNZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNZ8"
  },
  {
    "uniprot": "A0A044RXX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXX2"
  },
  {
    "uniprot": "A0A044SCF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCF4"
  },
  {
    "uniprot": "A0A044SFR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFR0"
  },
  {
    "uniprot": "A0A044QVA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVA0"
  },
  {
    "uniprot": "A0A044UFF9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFF9"
  },
  {
    "uniprot": "A0A044QUL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUL3"
  },
  {
    "uniprot": "A0A044SV05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SV05"
  },
  {
    "uniprot": "A0A044VBG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBG8"
  },
  {
    "uniprot": "A0A044VAD2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAD2"
  },
  {
    "uniprot": "A0A2K6WBV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBV2"
  },
  {
    "uniprot": "A0A044VFS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFS3"
  },
  {
    "uniprot": "A0A044UEB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEB5"
  },
  {
    "uniprot": "A0A2K6WEY1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEY1"
  },
  {
    "uniprot": "A0A044SEH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEH1"
  },
  {
    "uniprot": "A0A044QUB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUB0"
  },
  {
    "uniprot": "A0A044V888",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V888"
  },
  {
    "uniprot": "A0A044V9G2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9G2"
  },
  {
    "uniprot": "A0A044RQ09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQ09"
  },
  {
    "uniprot": "A0A044SHR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHR7"
  },
  {
    "uniprot": "A0A044R865",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R865"
  },
  {
    "uniprot": "A0A044S2Q6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2Q6"
  },
  {
    "uniprot": "A0A044SVH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVH2"
  },
  {
    "uniprot": "A0A044S1Y0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1Y0"
  },
  {
    "uniprot": "A0A044S438",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S438"
  },
  {
    "uniprot": "A0A044V3E5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3E5"
  },
  {
    "uniprot": "A0A2K6VSX0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSX0"
  },
  {
    "uniprot": "A0A044R9E9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9E9"
  },
  {
    "uniprot": "A0A044RHY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHY5"
  },
  {
    "uniprot": "A0A044VDJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDJ7"
  },
  {
    "uniprot": "A0A044TWC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWC1"
  },
  {
    "uniprot": "A0A044S4L3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4L3"
  },
  {
    "uniprot": "A0A044TVS1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVS1"
  },
  {
    "uniprot": "A0A044S134",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S134"
  },
  {
    "uniprot": "A0A2K6VHC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHC3"
  },
  {
    "uniprot": "A0A044S5H6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5H6"
  },
  {
    "uniprot": "A0A044US24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044US24"
  },
  {
    "uniprot": "A0A044VDH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDH3"
  },
  {
    "uniprot": "A0A044R9G9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9G9"
  },
  {
    "uniprot": "A0A044U5Y5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5Y5"
  },
  {
    "uniprot": "A0A044VFI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFI1"
  },
  {
    "uniprot": "A0A044TBJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBJ4"
  },
  {
    "uniprot": "A0A044SJN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJN1"
  },
  {
    "uniprot": "Q6PN98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q6PN98"
  },
  {
    "uniprot": "A0A044UMK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMK6"
  },
  {
    "uniprot": "A0A044S5Q5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5Q5"
  },
  {
    "uniprot": "A0A044VGF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGF5"
  },
  {
    "uniprot": "A0A044QLB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLB9"
  },
  {
    "uniprot": "A0A2K6VWT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWT7"
  },
  {
    "uniprot": "A0A044QMG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMG9"
  },
  {
    "uniprot": "A0A044SUE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUE1"
  },
  {
    "uniprot": "A0A044SS43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS43"
  },
  {
    "uniprot": "A0A044S0B8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0B8"
  },
  {
    "uniprot": "A0A044U487",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U487"
  },
  {
    "uniprot": "A0A044QQP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQP1"
  },
  {
    "uniprot": "A0A044T2T2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2T2"
  },
  {
    "uniprot": "A0A044SB98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB98"
  },
  {
    "uniprot": "A0A044V5D7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5D7"
  },
  {
    "uniprot": "A0A044UH42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UH42"
  },
  {
    "uniprot": "A0A2K6WED0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WED0"
  },
  {
    "uniprot": "A0A2K6VV77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VV77"
  },
  {
    "uniprot": "A0A044U6X6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6X6"
  },
  {
    "uniprot": "A0A044QPU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPU0"
  },
  {
    "uniprot": "A0A044URK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URK6"
  },
  {
    "uniprot": "A0A044SPK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPK1"
  },
  {
    "uniprot": "A0A044R6K2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6K2"
  },
  {
    "uniprot": "A0A044VDJ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDJ8"
  },
  {
    "uniprot": "A0A044UXX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXX9"
  },
  {
    "uniprot": "A0A044R3Z5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3Z5"
  },
  {
    "uniprot": "A0A044UI34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI34"
  },
  {
    "uniprot": "A0A044UTM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTM0"
  },
  {
    "uniprot": "A0A2K6VJN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJN1"
  },
  {
    "uniprot": "A0A044QK72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK72"
  },
  {
    "uniprot": "A0A044SLZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLZ6"
  },
  {
    "uniprot": "A0A044US99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044US99"
  },
  {
    "uniprot": "A0A044TGN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGN7"
  },
  {
    "uniprot": "A0A044T673",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T673"
  },
  {
    "uniprot": "A0A044U8J3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8J3"
  },
  {
    "uniprot": "A0A044UAX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAX6"
  },
  {
    "uniprot": "A0A2K6VUP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUP3"
  },
  {
    "uniprot": "A0A044UF41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF41"
  },
  {
    "uniprot": "A0A2K6WE26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WE26"
  },
  {
    "uniprot": "A0A044T7W6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7W6"
  },
  {
    "uniprot": "A0A044U1T8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1T8"
  },
  {
    "uniprot": "A0A044V9L2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9L2"
  },
  {
    "uniprot": "A0A044UFR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFR0"
  },
  {
    "uniprot": "A0A044QVK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVK8"
  },
  {
    "uniprot": "A0A158N7V7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7V7"
  },
  {
    "uniprot": "A0A044TPH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPH4"
  },
  {
    "uniprot": "A0A044U872",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U872"
  },
  {
    "uniprot": "A0A044TCZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCZ0"
  },
  {
    "uniprot": "A0A044VIU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIU3"
  },
  {
    "uniprot": "A0A044RQJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQJ1"
  },
  {
    "uniprot": "A0A044TAW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAW8"
  },
  {
    "uniprot": "A0A044U116",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U116"
  },
  {
    "uniprot": "A0A044RRG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRG2"
  },
  {
    "uniprot": "A0A044STR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STR1"
  },
  {
    "uniprot": "A0A044V4V1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4V1"
  },
  {
    "uniprot": "A0A044VJI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJI2"
  },
  {
    "uniprot": "A0A044TDP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDP6"
  },
  {
    "uniprot": "A0A044T4D9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4D9"
  },
  {
    "uniprot": "A0A044SMT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMT9"
  },
  {
    "uniprot": "A0A044UA65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA65"
  },
  {
    "uniprot": "A0A044UPF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPF5"
  },
  {
    "uniprot": "A0A044SB15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB15"
  },
  {
    "uniprot": "A0A044R6I6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6I6"
  },
  {
    "uniprot": "A0A044VJ23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJ23"
  },
  {
    "uniprot": "A0A044R3G7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3G7"
  },
  {
    "uniprot": "A0A044U1H7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1H7"
  },
  {
    "uniprot": "A0A044SDF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDF2"
  },
  {
    "uniprot": "A0A044UPW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPW0"
  },
  {
    "uniprot": "A0A044QWA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWA0"
  },
  {
    "uniprot": "A0A044VCM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCM0"
  },
  {
    "uniprot": "A0A044S158",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S158"
  },
  {
    "uniprot": "A0A044V881",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V881"
  },
  {
    "uniprot": "A0A044SXP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXP7"
  },
  {
    "uniprot": "A0A044U8R0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8R0"
  },
  {
    "uniprot": "A0A044RZX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZX2"
  },
  {
    "uniprot": "A0A044RXH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXH1"
  },
  {
    "uniprot": "A0A044RAY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAY3"
  },
  {
    "uniprot": "A0A044UZ54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ54"
  },
  {
    "uniprot": "A0A044SKP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKP2"
  },
  {
    "uniprot": "A0A044RZJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZJ3"
  },
  {
    "uniprot": "A0A044TVU7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVU7"
  },
  {
    "uniprot": "A0A044U4F2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4F2"
  },
  {
    "uniprot": "A0A044V4E9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4E9"
  },
  {
    "uniprot": "A0A044UW71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UW71"
  },
  {
    "uniprot": "A0A044SS59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS59"
  },
  {
    "uniprot": "A0A2K6WGN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGN0"
  },
  {
    "uniprot": "A0A2K6W646",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W646"
  },
  {
    "uniprot": "A0A2K6W7M1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7M1"
  },
  {
    "uniprot": "A0A2K6WLL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLL8"
  },
  {
    "uniprot": "A0A044UJE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJE1"
  },
  {
    "uniprot": "A0A044U1R6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1R6"
  },
  {
    "uniprot": "A0A044VGK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGK6"
  },
  {
    "uniprot": "A0A044SEK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEK5"
  },
  {
    "uniprot": "A0A044R514",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R514"
  },
  {
    "uniprot": "A0A2K6VPJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPJ6"
  },
  {
    "uniprot": "A0A044T7Y0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7Y0"
  },
  {
    "uniprot": "A0A044RNZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNZ2"
  },
  {
    "uniprot": "A0A044QZ81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ81"
  },
  {
    "uniprot": "A0A044QW28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW28"
  },
  {
    "uniprot": "A0A044SWC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWC5"
  },
  {
    "uniprot": "A0A044UU63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UU63"
  },
  {
    "uniprot": "A0A044RTM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTM4"
  },
  {
    "uniprot": "A0A2K6VWF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWF8"
  },
  {
    "uniprot": "A0A044R103",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R103"
  },
  {
    "uniprot": "A0A158N7R3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7R3"
  },
  {
    "uniprot": "A0A2K6WDD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDD3"
  },
  {
    "uniprot": "A0A044R8E7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8E7"
  },
  {
    "uniprot": "A0A044RT60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RT60"
  },
  {
    "uniprot": "A0A2K6VFQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFQ5"
  },
  {
    "uniprot": "A0A044UHT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHT9"
  },
  {
    "uniprot": "A0A044RU22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU22"
  },
  {
    "uniprot": "A0A044SC81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SC81"
  },
  {
    "uniprot": "A0A044SWB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWB9"
  },
  {
    "uniprot": "A0A044TJS8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJS8"
  },
  {
    "uniprot": "A0A2K6VJ45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJ45"
  },
  {
    "uniprot": "A0A044SSA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSA9"
  },
  {
    "uniprot": "A0A044QLP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLP6"
  },
  {
    "uniprot": "A0A044TLU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLU4"
  },
  {
    "uniprot": "A0A044UJU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJU9"
  },
  {
    "uniprot": "A0A044RFR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFR0"
  },
  {
    "uniprot": "A0A044V421",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V421"
  },
  {
    "uniprot": "A0A044S0T2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0T2"
  },
  {
    "uniprot": "A0A044V9M0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9M0"
  },
  {
    "uniprot": "A0A044V9U9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9U9"
  },
  {
    "uniprot": "A0A044TU13",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TU13"
  },
  {
    "uniprot": "A0A044VDZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDZ0"
  },
  {
    "uniprot": "A0A044VBX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBX1"
  },
  {
    "uniprot": "A0A044V9R4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9R4"
  },
  {
    "uniprot": "A0A044THK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THK2"
  },
  {
    "uniprot": "A0A2K6VXL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXL8"
  },
  {
    "uniprot": "A0A044SIV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIV5"
  },
  {
    "uniprot": "A0A044ULG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULG2"
  },
  {
    "uniprot": "A0A044S881",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S881"
  },
  {
    "uniprot": "A0A044RY55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY55"
  },
  {
    "uniprot": "A0A044V1S0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1S0"
  },
  {
    "uniprot": "A0A044QYI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYI4"
  },
  {
    "uniprot": "A0A044REC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REC5"
  },
  {
    "uniprot": "A0A044TNJ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNJ5"
  },
  {
    "uniprot": "A0A044US37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044US37"
  },
  {
    "uniprot": "A0A044V194",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V194"
  },
  {
    "uniprot": "A0A044R1E4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1E4"
  },
  {
    "uniprot": "A0A044V441",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V441"
  },
  {
    "uniprot": "A0A044VAL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAL8"
  },
  {
    "uniprot": "A0A044UC49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC49"
  },
  {
    "uniprot": "A0A044V9D0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9D0"
  },
  {
    "uniprot": "A0A044TJ71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJ71"
  },
  {
    "uniprot": "A0A2K6WFY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFY3"
  },
  {
    "uniprot": "A0A044RC25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC25"
  },
  {
    "uniprot": "A0A044TX79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TX79"
  },
  {
    "uniprot": "A0A2K6WIJ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIJ2"
  },
  {
    "uniprot": "A0A044VJ01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJ01"
  },
  {
    "uniprot": "A0A044R4V9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4V9"
  },
  {
    "uniprot": "A0A044UZ19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ19"
  },
  {
    "uniprot": "A0A2K6WDJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDJ7"
  },
  {
    "uniprot": "A0A044TUY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUY7"
  },
  {
    "uniprot": "A0A044V9L7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9L7"
  },
  {
    "uniprot": "A0A044R222",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R222"
  },
  {
    "uniprot": "A0A044TNP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNP7"
  },
  {
    "uniprot": "A0A044V1B0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1B0"
  },
  {
    "uniprot": "A0A044U238",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U238"
  },
  {
    "uniprot": "A0A044UZ07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ07"
  },
  {
    "uniprot": "A0A044TUE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUE2"
  },
  {
    "uniprot": "A0A2K6VVV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVV3"
  },
  {
    "uniprot": "A0A044UG43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UG43"
  },
  {
    "uniprot": "A0A044RC29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC29"
  },
  {
    "uniprot": "A0A044UDN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDN2"
  },
  {
    "uniprot": "A0A2K6W9F4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9F4"
  },
  {
    "uniprot": "A0A044TNK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNK2"
  },
  {
    "uniprot": "A0A044T539",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T539"
  },
  {
    "uniprot": "A0A044QR48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QR48"
  },
  {
    "uniprot": "A0A044R0F7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0F7"
  },
  {
    "uniprot": "A0A044S521",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S521"
  },
  {
    "uniprot": "A0A158N7S0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7S0"
  },
  {
    "uniprot": "A0A044TWW3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWW3"
  },
  {
    "uniprot": "A0A2K6VQN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQN1"
  },
  {
    "uniprot": "A0A2K6WNG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WNG9"
  },
  {
    "uniprot": "A0A044SIP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIP6"
  },
  {
    "uniprot": "A0A044SS29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS29"
  },
  {
    "uniprot": "A0A044RKU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKU4"
  },
  {
    "uniprot": "A0A044SRM9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRM9"
  },
  {
    "uniprot": "A0A044S6W8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6W8"
  },
  {
    "uniprot": "A0A044RWU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWU8"
  },
  {
    "uniprot": "A0A044TNM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNM0"
  },
  {
    "uniprot": "A0A044V139",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V139"
  },
  {
    "uniprot": "A0A044SHJ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHJ6"
  },
  {
    "uniprot": "A0A044QTL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTL8"
  },
  {
    "uniprot": "A0A2K6VZI5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZI5"
  },
  {
    "uniprot": "A0A044RW03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW03"
  },
  {
    "uniprot": "A0A044RZB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZB3"
  },
  {
    "uniprot": "A0A044S1E7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1E7"
  },
  {
    "uniprot": "A0A044S920",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S920"
  },
  {
    "uniprot": "A0A044R071",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R071"
  },
  {
    "uniprot": "A0A2K6VUA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUA6"
  },
  {
    "uniprot": "A0A044V4H9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4H9"
  },
  {
    "uniprot": "A0A044R0S3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0S3"
  },
  {
    "uniprot": "A0A044STF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STF2"
  },
  {
    "uniprot": "A0A044RQZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQZ5"
  },
  {
    "uniprot": "A0A044UZ18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ18"
  },
  {
    "uniprot": "A0A044RSQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSQ9"
  },
  {
    "uniprot": "A0A044U5B6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5B6"
  },
  {
    "uniprot": "A0A044T432",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T432"
  },
  {
    "uniprot": "P54187",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P54187"
  },
  {
    "uniprot": "A0A044R3F3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3F3"
  },
  {
    "uniprot": "A0A044V6I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6I9"
  },
  {
    "uniprot": "A0A044SI75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI75"
  },
  {
    "uniprot": "A0A044TL21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TL21"
  },
  {
    "uniprot": "A0A044RFE8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFE8"
  },
  {
    "uniprot": "A0A044TUA8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUA8"
  },
  {
    "uniprot": "A0A044RBR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBR4"
  },
  {
    "uniprot": "A0A044QX08",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QX08"
  },
  {
    "uniprot": "A0A158N7S3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7S3"
  },
  {
    "uniprot": "A0A044RPG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPG4"
  },
  {
    "uniprot": "A0A044V0B1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0B1"
  },
  {
    "uniprot": "A0A044V7Y7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7Y7"
  },
  {
    "uniprot": "A0A044RSM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSM4"
  },
  {
    "uniprot": "A0A2K6WMK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMK5"
  },
  {
    "uniprot": "A0A044TC48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC48"
  },
  {
    "uniprot": "A0A044SRN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRN8"
  },
  {
    "uniprot": "A0A044RJ51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJ51"
  },
  {
    "uniprot": "A0A044SMU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMU5"
  },
  {
    "uniprot": "A0A044V8Y9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Y9"
  },
  {
    "uniprot": "A0A044UXB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXB1"
  },
  {
    "uniprot": "A0A044SWV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWV8"
  },
  {
    "uniprot": "A0A044UCC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCC9"
  },
  {
    "uniprot": "A0A044TW24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TW24"
  },
  {
    "uniprot": "A0A044V9F3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9F3"
  },
  {
    "uniprot": "A0A044URQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URQ1"
  },
  {
    "uniprot": "A0A044SPC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPC5"
  },
  {
    "uniprot": "A0A044UTD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTD3"
  },
  {
    "uniprot": "A0A044V6N3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6N3"
  },
  {
    "uniprot": "A0A044T534",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T534"
  },
  {
    "uniprot": "A0A044V038",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V038"
  },
  {
    "uniprot": "A0A044SB51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB51"
  },
  {
    "uniprot": "A0A044V6Y8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Y8"
  },
  {
    "uniprot": "A0A044U4Q8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4Q8"
  },
  {
    "uniprot": "A0A044RXW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXW7"
  },
  {
    "uniprot": "A0A044UYZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYZ3"
  },
  {
    "uniprot": "A0A044R0E6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0E6"
  },
  {
    "uniprot": "A0A2K6WIQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIQ7"
  },
  {
    "uniprot": "A0A044U9Y5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9Y5"
  },
  {
    "uniprot": "A0A2K6VUP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUP0"
  },
  {
    "uniprot": "A0A044QY04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY04"
  },
  {
    "uniprot": "A0A2K6WGN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGN9"
  },
  {
    "uniprot": "A0A158N841",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N841"
  },
  {
    "uniprot": "A0A044RNM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNM5"
  },
  {
    "uniprot": "A0A2K6WFU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFU6"
  },
  {
    "uniprot": "A0A2K6WIA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIA1"
  },
  {
    "uniprot": "A0A044U6W2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6W2"
  },
  {
    "uniprot": "A0A044V2T0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2T0"
  },
  {
    "uniprot": "A0A044UHM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHM2"
  },
  {
    "uniprot": "A0A044U7F7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7F7"
  },
  {
    "uniprot": "A0A044UIY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIY6"
  },
  {
    "uniprot": "A0A2K6VDR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDR0"
  },
  {
    "uniprot": "A0A044ULM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULM1"
  },
  {
    "uniprot": "A0A044S0S9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0S9"
  },
  {
    "uniprot": "A0A044S8S1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8S1"
  },
  {
    "uniprot": "A0A044V422",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V422"
  },
  {
    "uniprot": "A0A044S6I7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6I7"
  },
  {
    "uniprot": "A0A044TTD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTD6"
  },
  {
    "uniprot": "A0A2K6VV79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VV79"
  },
  {
    "uniprot": "A0A044UV27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UV27"
  },
  {
    "uniprot": "A0A044TQE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQE0"
  },
  {
    "uniprot": "A0A158N860",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N860"
  },
  {
    "uniprot": "A0A044QTM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTM8"
  },
  {
    "uniprot": "A0A044QPK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPK5"
  },
  {
    "uniprot": "A0A044U6L0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6L0"
  },
  {
    "uniprot": "A0A044QWD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWD6"
  },
  {
    "uniprot": "A0A044TCW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCW8"
  },
  {
    "uniprot": "A0A044V9I7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9I7"
  },
  {
    "uniprot": "A0A044UQU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQU3"
  },
  {
    "uniprot": "A0A044TP26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TP26"
  },
  {
    "uniprot": "A0A044TU82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TU82"
  },
  {
    "uniprot": "A0A2K6VLJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLJ9"
  },
  {
    "uniprot": "A0A044UL10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL10"
  },
  {
    "uniprot": "A0A044V720",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V720"
  },
  {
    "uniprot": "A0A044U050",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U050"
  },
  {
    "uniprot": "A0A044V174",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V174"
  },
  {
    "uniprot": "A0A044TWH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWH3"
  },
  {
    "uniprot": "A0A044T5N1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5N1"
  },
  {
    "uniprot": "A0A044U611",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U611"
  },
  {
    "uniprot": "A0A044UC96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UC96"
  },
  {
    "uniprot": "A0A044T1V7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1V7"
  },
  {
    "uniprot": "A0A044U0C8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0C8"
  },
  {
    "uniprot": "A0A044S482",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S482"
  },
  {
    "uniprot": "A0A2K6WL67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WL67"
  },
  {
    "uniprot": "A0A2K6VVT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVT2"
  },
  {
    "uniprot": "A0A044TET3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TET3"
  },
  {
    "uniprot": "A0A044U323",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U323"
  },
  {
    "uniprot": "A0A044UT40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT40"
  },
  {
    "uniprot": "A0A158N833",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N833"
  },
  {
    "uniprot": "A0A044TD61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TD61"
  },
  {
    "uniprot": "A0A044UE88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE88"
  },
  {
    "uniprot": "A0A044SWC7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWC7"
  },
  {
    "uniprot": "A0A044VBA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBA7"
  },
  {
    "uniprot": "A0A2K6VH36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VH36"
  },
  {
    "uniprot": "A0A044SGY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGY4"
  },
  {
    "uniprot": "A0A044SD06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD06"
  },
  {
    "uniprot": "A0A044QJH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJH4"
  },
  {
    "uniprot": "A0A2K6VKU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKU0"
  },
  {
    "uniprot": "A0A044T724",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T724"
  },
  {
    "uniprot": "A0A044TMH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMH2"
  },
  {
    "uniprot": "A0A044SQP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQP7"
  },
  {
    "uniprot": "A0A044TYQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYQ5"
  },
  {
    "uniprot": "A0A2K6VUT3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUT3"
  },
  {
    "uniprot": "A0A044SRF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRF2"
  },
  {
    "uniprot": "A0A044V7U4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7U4"
  },
  {
    "uniprot": "A0A044VD04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD04"
  },
  {
    "uniprot": "A0A044V9L1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9L1"
  },
  {
    "uniprot": "A0A044RAZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAZ9"
  },
  {
    "uniprot": "A0A044QT51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT51"
  },
  {
    "uniprot": "A0A044R8N6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8N6"
  },
  {
    "uniprot": "A0A044TNU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNU9"
  },
  {
    "uniprot": "A0A044SQ14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ14"
  },
  {
    "uniprot": "A0A044V444",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V444"
  },
  {
    "uniprot": "A0A044V7P0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7P0"
  },
  {
    "uniprot": "A0A044UYR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYR4"
  },
  {
    "uniprot": "A0A044UF83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF83"
  },
  {
    "uniprot": "A0A044QUD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUD1"
  },
  {
    "uniprot": "A0A044TWX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWX2"
  },
  {
    "uniprot": "A0A158N813",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N813"
  },
  {
    "uniprot": "A0A044TPH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPH2"
  },
  {
    "uniprot": "A0A044SFH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFH9"
  },
  {
    "uniprot": "A0A044T974",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T974"
  },
  {
    "uniprot": "A0A044TM15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TM15"
  },
  {
    "uniprot": "A0A044UYY1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYY1"
  },
  {
    "uniprot": "A0A044UU30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UU30"
  },
  {
    "uniprot": "A0A044V6Z6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Z6"
  },
  {
    "uniprot": "A0A044TC85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC85"
  },
  {
    "uniprot": "A0A2K6VRM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRM2"
  },
  {
    "uniprot": "A0A044UZ21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ21"
  },
  {
    "uniprot": "A0A044QLQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLQ0"
  },
  {
    "uniprot": "A0A044V058",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V058"
  },
  {
    "uniprot": "A0A044RZR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZR4"
  },
  {
    "uniprot": "A0A044QPX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPX6"
  },
  {
    "uniprot": "A0A044V9C4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9C4"
  },
  {
    "uniprot": "A0A044S2P2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2P2"
  },
  {
    "uniprot": "A0A044RD84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RD84"
  },
  {
    "uniprot": "A0A044SZD0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZD0"
  },
  {
    "uniprot": "A0A044V941",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V941"
  },
  {
    "uniprot": "A0A044R1C6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1C6"
  },
  {
    "uniprot": "A0A044QY43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY43"
  },
  {
    "uniprot": "A0A044UMJ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMJ8"
  },
  {
    "uniprot": "A0A044TBP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBP5"
  },
  {
    "uniprot": "A0A044T7I1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7I1"
  },
  {
    "uniprot": "A0A044S4D8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4D8"
  },
  {
    "uniprot": "A0A044SWH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWH2"
  },
  {
    "uniprot": "A0A044V4A2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4A2"
  },
  {
    "uniprot": "A0A044T4N5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4N5"
  },
  {
    "uniprot": "A0A044TLS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLS4"
  },
  {
    "uniprot": "A0A044UGZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGZ6"
  },
  {
    "uniprot": "A0A044T117",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T117"
  },
  {
    "uniprot": "A0A2K6VQ85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQ85"
  },
  {
    "uniprot": "A0A2K6WGK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGK1"
  },
  {
    "uniprot": "A0A044V932",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V932"
  },
  {
    "uniprot": "A0A044UG23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UG23"
  },
  {
    "uniprot": "A0A044UH02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UH02"
  },
  {
    "uniprot": "A0A2K6VVN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVN6"
  },
  {
    "uniprot": "A0A044T9W9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9W9"
  },
  {
    "uniprot": "A0A044TIS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIS7"
  },
  {
    "uniprot": "A0A044V9K0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9K0"
  },
  {
    "uniprot": "A0A044V9D7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9D7"
  },
  {
    "uniprot": "A0A044RXE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXE4"
  },
  {
    "uniprot": "A0A2K6W818",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W818"
  },
  {
    "uniprot": "A0A044TKD2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKD2"
  },
  {
    "uniprot": "A0A044V4E2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4E2"
  },
  {
    "uniprot": "A0A044QYS1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYS1"
  },
  {
    "uniprot": "A0A044TU21",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TU21"
  },
  {
    "uniprot": "A0A044UTL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTL2"
  },
  {
    "uniprot": "A0A044QRH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRH1"
  },
  {
    "uniprot": "A0A044UWU9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWU9"
  },
  {
    "uniprot": "A0A044U5I1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5I1"
  },
  {
    "uniprot": "A0A2K6VH90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VH90"
  },
  {
    "uniprot": "A0A044UZ43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ43"
  },
  {
    "uniprot": "A0A044R3L6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3L6"
  },
  {
    "uniprot": "A0A044U0D5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0D5"
  },
  {
    "uniprot": "A0A2K6WF24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WF24"
  },
  {
    "uniprot": "A0A044SPP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPP1"
  },
  {
    "uniprot": "A0A044UTL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTL8"
  },
  {
    "uniprot": "A0A044VEK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEK8"
  },
  {
    "uniprot": "A0A044V7Y0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7Y0"
  },
  {
    "uniprot": "A0A044TSD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSD1"
  },
  {
    "uniprot": "A0A2K6VHI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHI6"
  },
  {
    "uniprot": "A0A044SEZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEZ0"
  },
  {
    "uniprot": "A0A044UHM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHM4"
  },
  {
    "uniprot": "A0A044RGN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGN5"
  },
  {
    "uniprot": "A0A044VIS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIS7"
  },
  {
    "uniprot": "A0A044R0X6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0X6"
  },
  {
    "uniprot": "A0A2K6VWE8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWE8"
  },
  {
    "uniprot": "A0A044SUJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUJ7"
  },
  {
    "uniprot": "A0A044RRR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRR2"
  },
  {
    "uniprot": "A0A2K6WLR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLR2"
  },
  {
    "uniprot": "A0A044RHJ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHJ8"
  },
  {
    "uniprot": "A0A044SP18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SP18"
  },
  {
    "uniprot": "A0A044UZX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZX5"
  },
  {
    "uniprot": "A0A044SRH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRH1"
  },
  {
    "uniprot": "A0A044SIU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIU8"
  },
  {
    "uniprot": "A0A044VEU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEU0"
  },
  {
    "uniprot": "A0A044TFP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFP5"
  },
  {
    "uniprot": "A0A044UA29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA29"
  },
  {
    "uniprot": "A0A044QZF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZF6"
  },
  {
    "uniprot": "A0A044U0B0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0B0"
  },
  {
    "uniprot": "A0A044TBI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBI8"
  },
  {
    "uniprot": "A0A044SK15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK15"
  },
  {
    "uniprot": "A0A044SQC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQC5"
  },
  {
    "uniprot": "A0A044V462",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V462"
  },
  {
    "uniprot": "A0A2K6WIY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIY2"
  },
  {
    "uniprot": "A0A044UVH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVH8"
  },
  {
    "uniprot": "A0A044SKY1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKY1"
  },
  {
    "uniprot": "A0A044U2D8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2D8"
  },
  {
    "uniprot": "A0A044TUS1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUS1"
  },
  {
    "uniprot": "A0A044V917",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V917"
  },
  {
    "uniprot": "A0A044SBM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBM8"
  },
  {
    "uniprot": "A0A044UN46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UN46"
  },
  {
    "uniprot": "A0A044QY34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY34"
  },
  {
    "uniprot": "A0A044T6W9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6W9"
  },
  {
    "uniprot": "A0A044UQ07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQ07"
  },
  {
    "uniprot": "A0A2K6VJ81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJ81"
  },
  {
    "uniprot": "A0A044TLJ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLJ5"
  },
  {
    "uniprot": "P41981",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P41981"
  },
  {
    "uniprot": "A0A044UA82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA82"
  },
  {
    "uniprot": "A0A044SX11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SX11"
  },
  {
    "uniprot": "A0A044UUD4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUD4"
  },
  {
    "uniprot": "A0A044RMY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMY7"
  },
  {
    "uniprot": "A0A044TIM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIM1"
  },
  {
    "uniprot": "A0A044U935",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U935"
  },
  {
    "uniprot": "A0A044R9C8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9C8"
  },
  {
    "uniprot": "A0A044RW71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW71"
  },
  {
    "uniprot": "A0A044VB68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VB68"
  },
  {
    "uniprot": "A0A044V4B9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4B9"
  },
  {
    "uniprot": "A0A044TPM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPM8"
  },
  {
    "uniprot": "A0A044RJV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJV1"
  },
  {
    "uniprot": "A0A044TMY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMY5"
  },
  {
    "uniprot": "A0A044R996",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R996"
  },
  {
    "uniprot": "A0A044SI60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI60"
  },
  {
    "uniprot": "A0A044SC19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SC19"
  },
  {
    "uniprot": "A0A044UYX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYX5"
  },
  {
    "uniprot": "A0A2K6VKE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKE9"
  },
  {
    "uniprot": "A0A044UYY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYY3"
  },
  {
    "uniprot": "A0A044U5R0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5R0"
  },
  {
    "uniprot": "A0A2K6VR43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VR43"
  },
  {
    "uniprot": "A0A2K6VHA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHA5"
  },
  {
    "uniprot": "A0A044RBQ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBQ2"
  },
  {
    "uniprot": "A0A044QNK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNK0"
  },
  {
    "uniprot": "A0A158N8C5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8C5"
  },
  {
    "uniprot": "A0A044TG46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TG46"
  },
  {
    "uniprot": "A0A044SAX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAX3"
  },
  {
    "uniprot": "A0A044TTK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTK9"
  },
  {
    "uniprot": "A0A044UVW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVW4"
  },
  {
    "uniprot": "A0A044QK00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK00"
  },
  {
    "uniprot": "A0A044RA12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RA12"
  },
  {
    "uniprot": "A0A044SJ83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJ83"
  },
  {
    "uniprot": "A0A044V6G8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6G8"
  },
  {
    "uniprot": "A0A044TXG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXG2"
  },
  {
    "uniprot": "A0A044UXN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXN9"
  },
  {
    "uniprot": "A0A044QYX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYX2"
  },
  {
    "uniprot": "A0A044R6K6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6K6"
  },
  {
    "uniprot": "A0A044V9J8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9J8"
  },
  {
    "uniprot": "A0A044RRB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRB7"
  },
  {
    "uniprot": "A0A044T3B3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3B3"
  },
  {
    "uniprot": "A0A044QQU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQU5"
  },
  {
    "uniprot": "A0A044QT67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT67"
  },
  {
    "uniprot": "A0A044V8Z1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Z1"
  },
  {
    "uniprot": "A0A044U5K9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5K9"
  },
  {
    "uniprot": "A0A044SM17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM17"
  },
  {
    "uniprot": "A0A044UHR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHR8"
  },
  {
    "uniprot": "A0A044T450",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T450"
  },
  {
    "uniprot": "A0A2K6VMF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMF6"
  },
  {
    "uniprot": "A0A044TQ28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQ28"
  },
  {
    "uniprot": "A0A044SYR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYR2"
  },
  {
    "uniprot": "A0A044T9C7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9C7"
  },
  {
    "uniprot": "A0A044U3Z7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3Z7"
  },
  {
    "uniprot": "A0A044T0A5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0A5"
  },
  {
    "uniprot": "A0A044UEC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEC0"
  },
  {
    "uniprot": "A0A044TGB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGB9"
  },
  {
    "uniprot": "A0A2K6WC95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WC95"
  },
  {
    "uniprot": "A0A044QZV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZV1"
  },
  {
    "uniprot": "A0A044SI23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI23"
  },
  {
    "uniprot": "A0A044V0Y1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0Y1"
  },
  {
    "uniprot": "A0A044U979",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U979"
  },
  {
    "uniprot": "A0A044RS17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RS17"
  },
  {
    "uniprot": "A0A044VB87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VB87"
  },
  {
    "uniprot": "A0A044VBZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBZ6"
  },
  {
    "uniprot": "A0A044U9N5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9N5"
  },
  {
    "uniprot": "A0A044SLH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLH9"
  },
  {
    "uniprot": "A0A158N828",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N828"
  },
  {
    "uniprot": "A0A044UYZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYZ5"
  },
  {
    "uniprot": "A0A044U6S7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6S7"
  },
  {
    "uniprot": "A0A044SR53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SR53"
  },
  {
    "uniprot": "A0A044S224",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S224"
  },
  {
    "uniprot": "A0A044RYU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYU0"
  },
  {
    "uniprot": "A0A044QYK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYK3"
  },
  {
    "uniprot": "A0A044TBV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBV2"
  },
  {
    "uniprot": "A0A044TK85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK85"
  },
  {
    "uniprot": "A0A044UI31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI31"
  },
  {
    "uniprot": "A0A044RZ08",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZ08"
  },
  {
    "uniprot": "A0A044V136",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V136"
  },
  {
    "uniprot": "A0A044UEW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEW0"
  },
  {
    "uniprot": "A0A044S5L3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5L3"
  },
  {
    "uniprot": "A0A044TAZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAZ4"
  },
  {
    "uniprot": "A0A044ULJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULJ4"
  },
  {
    "uniprot": "A0A044T666",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T666"
  },
  {
    "uniprot": "A0A044TWE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWE5"
  },
  {
    "uniprot": "A0A044SPD4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPD4"
  },
  {
    "uniprot": "A0A044RZX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZX7"
  },
  {
    "uniprot": "A0A044RE33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RE33"
  },
  {
    "uniprot": "A0A044VIF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIF8"
  },
  {
    "uniprot": "A0A2K6WD94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WD94"
  },
  {
    "uniprot": "A0A044V197",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V197"
  },
  {
    "uniprot": "A0A044SBB2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBB2"
  },
  {
    "uniprot": "A0A044TA57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TA57"
  },
  {
    "uniprot": "A0A044V9C5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9C5"
  },
  {
    "uniprot": "A0A044TJC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJC3"
  },
  {
    "uniprot": "A0A044U959",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U959"
  },
  {
    "uniprot": "A0A044SY62",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY62"
  },
  {
    "uniprot": "A0A044UJC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJC2"
  },
  {
    "uniprot": "A0A044VDW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDW4"
  },
  {
    "uniprot": "A0A2K6VFH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFH4"
  },
  {
    "uniprot": "A0A044SK49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK49"
  },
  {
    "uniprot": "A0A2K6WHH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WHH1"
  },
  {
    "uniprot": "A0A2K6VSW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSW4"
  },
  {
    "uniprot": "A0A2K6W7P9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7P9"
  },
  {
    "uniprot": "A0A044TPD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPD8"
  },
  {
    "uniprot": "A0A2K6VZG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZG0"
  },
  {
    "uniprot": "A0A2K6WML1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WML1"
  },
  {
    "uniprot": "A0A044QQZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQZ6"
  },
  {
    "uniprot": "A0A044V6W6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6W6"
  },
  {
    "uniprot": "A0A044QLE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLE5"
  },
  {
    "uniprot": "A0A044V7Q0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7Q0"
  },
  {
    "uniprot": "A0A044QUR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUR6"
  },
  {
    "uniprot": "A0A044T246",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T246"
  },
  {
    "uniprot": "A0A044SE92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SE92"
  },
  {
    "uniprot": "A0A2K6VJE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJE1"
  },
  {
    "uniprot": "A0A044SAR9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAR9"
  },
  {
    "uniprot": "A0A044S884",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S884"
  },
  {
    "uniprot": "A0A2K6VTX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VTX7"
  },
  {
    "uniprot": "A0A2K6W9E6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9E6"
  },
  {
    "uniprot": "A0A044UFJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFJ3"
  },
  {
    "uniprot": "A0A044QLV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLV2"
  },
  {
    "uniprot": "A0A044URH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URH4"
  },
  {
    "uniprot": "A0A044UI61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI61"
  },
  {
    "uniprot": "A0A044T0G2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0G2"
  },
  {
    "uniprot": "A0A158N835",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N835"
  },
  {
    "uniprot": "A0A2K6WGR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGR0"
  },
  {
    "uniprot": "A0A044V045",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V045"
  },
  {
    "uniprot": "A0A2K6VFS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFS9"
  },
  {
    "uniprot": "A0A2K6W2B2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2B2"
  },
  {
    "uniprot": "A0A044T193",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T193"
  },
  {
    "uniprot": "A0A044VJH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJH5"
  },
  {
    "uniprot": "A0A044V446",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V446"
  },
  {
    "uniprot": "A0A044V8Z4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Z4"
  },
  {
    "uniprot": "A0A044RGI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGI0"
  },
  {
    "uniprot": "A0A044VEF9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEF9"
  },
  {
    "uniprot": "A0A044SR37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SR37"
  },
  {
    "uniprot": "A0A2K6VP82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VP82"
  },
  {
    "uniprot": "A0A044UBD0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBD0"
  },
  {
    "uniprot": "A0A044RFI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFI1"
  },
  {
    "uniprot": "A0A044V912",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V912"
  },
  {
    "uniprot": "A0A044VJP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJP0"
  },
  {
    "uniprot": "A0A044UE26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE26"
  },
  {
    "uniprot": "A0A044RD33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RD33"
  },
  {
    "uniprot": "A0A044QUK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUK1"
  },
  {
    "uniprot": "A0A158N862",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N862"
  },
  {
    "uniprot": "Q02171",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q02171"
  },
  {
    "uniprot": "A0A2K6W2M4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2M4"
  },
  {
    "uniprot": "A0A044V4D6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4D6"
  },
  {
    "uniprot": "A0A2K6VIU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIU5"
  },
  {
    "uniprot": "A0A2K6WJ17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJ17"
  },
  {
    "uniprot": "A0A044UJ36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJ36"
  },
  {
    "uniprot": "A0A044SZV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZV1"
  },
  {
    "uniprot": "A0A044VHJ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHJ5"
  },
  {
    "uniprot": "A0A158N7R7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7R7"
  },
  {
    "uniprot": "A0A2K6WM05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WM05"
  },
  {
    "uniprot": "A0A044V0L2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0L2"
  },
  {
    "uniprot": "A0A044VEA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEA4"
  },
  {
    "uniprot": "A0A044RCY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCY4"
  },
  {
    "uniprot": "A0A044SFR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFR4"
  },
  {
    "uniprot": "A0A2K6VQE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQE7"
  },
  {
    "uniprot": "A0A2K6W1K4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1K4"
  },
  {
    "uniprot": "I6LDA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/I6LDA6"
  },
  {
    "uniprot": "A0A044TG54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TG54"
  },
  {
    "uniprot": "A0A044UNK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNK8"
  },
  {
    "uniprot": "A0A044U8U3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8U3"
  },
  {
    "uniprot": "A0A044UTZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTZ0"
  },
  {
    "uniprot": "A0A044R0F2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0F2"
  },
  {
    "uniprot": "A0A044SKG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKG5"
  },
  {
    "uniprot": "A0A044UFW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFW5"
  },
  {
    "uniprot": "A0A044SSP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSP5"
  },
  {
    "uniprot": "A0A2K6WM99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WM99"
  },
  {
    "uniprot": "A0A044TW11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TW11"
  },
  {
    "uniprot": "A0A044QMF9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMF9"
  },
  {
    "uniprot": "A0A044V344",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V344"
  },
  {
    "uniprot": "A0A044UME2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UME2"
  },
  {
    "uniprot": "A0A044RSJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSJ4"
  },
  {
    "uniprot": "A0A044T1G2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1G2"
  },
  {
    "uniprot": "A0A044UH34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UH34"
  },
  {
    "uniprot": "A0A044ST73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ST73"
  },
  {
    "uniprot": "A0A044QWF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWF2"
  },
  {
    "uniprot": "A0A044TDE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDE6"
  },
  {
    "uniprot": "A0A2K6VE74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VE74"
  },
  {
    "uniprot": "A0A044UGT1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGT1"
  },
  {
    "uniprot": "A0A044VAV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAV2"
  },
  {
    "uniprot": "A0A2K6W5I7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W5I7"
  },
  {
    "uniprot": "A0A044R1T5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1T5"
  },
  {
    "uniprot": "A0A044R1R9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R1R9"
  },
  {
    "uniprot": "A0A044U3Y3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3Y3"
  },
  {
    "uniprot": "A0A044R988",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R988"
  },
  {
    "uniprot": "A0A044R4I2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4I2"
  },
  {
    "uniprot": "A0A044RL12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RL12"
  },
  {
    "uniprot": "A0A158N8Q5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8Q5"
  },
  {
    "uniprot": "A0A044UV44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UV44"
  },
  {
    "uniprot": "A0A044UTL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTL1"
  },
  {
    "uniprot": "A0A044T8V8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8V8"
  },
  {
    "uniprot": "A0A2K6VUI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUI4"
  },
  {
    "uniprot": "A0A044SKP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKP8"
  },
  {
    "uniprot": "A0A044TFE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFE0"
  },
  {
    "uniprot": "A0A044TMQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMQ9"
  },
  {
    "uniprot": "A0A2K6WBI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBI0"
  },
  {
    "uniprot": "A0A044REC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REC9"
  },
  {
    "uniprot": "A0A044V063",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V063"
  },
  {
    "uniprot": "A0A044V2N4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2N4"
  },
  {
    "uniprot": "A0A044R210",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R210"
  },
  {
    "uniprot": "A0A044V8Z5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Z5"
  },
  {
    "uniprot": "A0A044T904",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T904"
  },
  {
    "uniprot": "A0A044SM43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM43"
  },
  {
    "uniprot": "A0A044VAD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAD5"
  },
  {
    "uniprot": "A0A044SNB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNB4"
  },
  {
    "uniprot": "A0A2K6VUI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUI2"
  },
  {
    "uniprot": "A0A044UNM9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNM9"
  },
  {
    "uniprot": "A0A2K6W5H4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W5H4"
  },
  {
    "uniprot": "A0A2K6WN95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WN95"
  },
  {
    "uniprot": "A0A044SLM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLM6"
  },
  {
    "uniprot": "A0A044SMN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMN3"
  },
  {
    "uniprot": "A0A044VDS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDS0"
  },
  {
    "uniprot": "A0A044QSS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSS6"
  },
  {
    "uniprot": "A0A044VHA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHA3"
  },
  {
    "uniprot": "A0A044QVX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVX2"
  },
  {
    "uniprot": "A0A044TSL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSL5"
  },
  {
    "uniprot": "A0A044SX98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SX98"
  },
  {
    "uniprot": "A0A044SKS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKS4"
  },
  {
    "uniprot": "A0A044VCR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCR1"
  },
  {
    "uniprot": "A0A044V9G6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9G6"
  },
  {
    "uniprot": "A0A044TW04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TW04"
  },
  {
    "uniprot": "A0A044TQE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQE7"
  },
  {
    "uniprot": "A0A044S5X3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5X3"
  },
  {
    "uniprot": "A0A2K6VDG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDG1"
  },
  {
    "uniprot": "A0A2K6VU20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VU20"
  },
  {
    "uniprot": "A0A044SAE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAE3"
  },
  {
    "uniprot": "A0A044UP23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UP23"
  },
  {
    "uniprot": "A0A044UDB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDB1"
  },
  {
    "uniprot": "A0A044REL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REL4"
  },
  {
    "uniprot": "A0A044URY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URY9"
  },
  {
    "uniprot": "A0A2K6VMG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMG8"
  },
  {
    "uniprot": "A0A044UPX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPX5"
  },
  {
    "uniprot": "A0A044RUX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUX7"
  },
  {
    "uniprot": "A0A044TV68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV68"
  },
  {
    "uniprot": "A0A044T6P9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6P9"
  },
  {
    "uniprot": "A0A044UVL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVL8"
  },
  {
    "uniprot": "A0A044SS69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS69"
  },
  {
    "uniprot": "A0A044S2Z7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2Z7"
  },
  {
    "uniprot": "A0A044UEE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEE1"
  },
  {
    "uniprot": "A0A044U3F5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3F5"
  },
  {
    "uniprot": "A0A044UL41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL41"
  },
  {
    "uniprot": "A0A044SXR9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXR9"
  },
  {
    "uniprot": "A0A044V495",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V495"
  },
  {
    "uniprot": "A0A044T2H6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2H6"
  },
  {
    "uniprot": "A0A044RJB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJB9"
  },
  {
    "uniprot": "A0A044U5U1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5U1"
  },
  {
    "uniprot": "A0A044RTL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTL8"
  },
  {
    "uniprot": "A0A044SJV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJV5"
  },
  {
    "uniprot": "A0A044UIX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIX3"
  },
  {
    "uniprot": "A0A044RD51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RD51"
  },
  {
    "uniprot": "A0A044U631",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U631"
  },
  {
    "uniprot": "A0A044UES9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UES9"
  },
  {
    "uniprot": "A0A044TPV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPV7"
  },
  {
    "uniprot": "A0A044TWH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWH5"
  },
  {
    "uniprot": "A0A2K6VQ71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQ71"
  },
  {
    "uniprot": "A0A044UWZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWZ1"
  },
  {
    "uniprot": "A0A044RAM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAM4"
  },
  {
    "uniprot": "A0A044T8M2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8M2"
  },
  {
    "uniprot": "A0A044TLR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLR3"
  },
  {
    "uniprot": "A0A044VB28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VB28"
  },
  {
    "uniprot": "A0A044U3K9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3K9"
  },
  {
    "uniprot": "A0A158N895",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N895"
  },
  {
    "uniprot": "A0A044UJQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJQ0"
  },
  {
    "uniprot": "A0A044T841",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T841"
  },
  {
    "uniprot": "A0A044R731",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R731"
  },
  {
    "uniprot": "A0A158N8R7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8R7"
  },
  {
    "uniprot": "A0A044U8G1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8G1"
  },
  {
    "uniprot": "A0A044T7B0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7B0"
  },
  {
    "uniprot": "A0A044TB49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TB49"
  },
  {
    "uniprot": "A0A044RBY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBY8"
  },
  {
    "uniprot": "A0A2K6VSM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSM1"
  },
  {
    "uniprot": "A0A044UA39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA39"
  },
  {
    "uniprot": "A0A044SJV6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJV6"
  },
  {
    "uniprot": "A0A044S5A0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5A0"
  },
  {
    "uniprot": "A0A044SXA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXA4"
  },
  {
    "uniprot": "A0A044TDB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDB1"
  },
  {
    "uniprot": "A0A044URS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URS5"
  },
  {
    "uniprot": "A0A2K6VM57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VM57"
  },
  {
    "uniprot": "A0A044VBC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBC1"
  },
  {
    "uniprot": "A0A2K6VYD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYD3"
  },
  {
    "uniprot": "A0A044SDA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDA3"
  },
  {
    "uniprot": "A0A2K6W565",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W565"
  },
  {
    "uniprot": "A0A2K6VMV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMV1"
  },
  {
    "uniprot": "A0A044TMI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMI2"
  },
  {
    "uniprot": "A0A044QZI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZI3"
  },
  {
    "uniprot": "A0A044VIL7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIL7"
  },
  {
    "uniprot": "A0A044S233",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S233"
  },
  {
    "uniprot": "A0A044QLJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLJ9"
  },
  {
    "uniprot": "A0A044TL27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TL27"
  },
  {
    "uniprot": "A0A044RZH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZH8"
  },
  {
    "uniprot": "A0A2K6WAG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAG2"
  },
  {
    "uniprot": "A0A2K6W162",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W162"
  },
  {
    "uniprot": "A0A044SM66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM66"
  },
  {
    "uniprot": "A0A044RAR1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAR1"
  },
  {
    "uniprot": "A0A044T2C7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2C7"
  },
  {
    "uniprot": "A0A044S1Y6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1Y6"
  },
  {
    "uniprot": "A0A044V9R9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9R9"
  },
  {
    "uniprot": "A0A044VCV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCV3"
  },
  {
    "uniprot": "A0A044T3G3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3G3"
  },
  {
    "uniprot": "A0A2K6VFN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFN5"
  },
  {
    "uniprot": "A0A044UZ41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ41"
  },
  {
    "uniprot": "A0A044U6A3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6A3"
  },
  {
    "uniprot": "A0A044RIF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIF0"
  },
  {
    "uniprot": "A0A2K6W825",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W825"
  },
  {
    "uniprot": "A0A044SM92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM92"
  },
  {
    "uniprot": "A0A158N859",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N859"
  },
  {
    "uniprot": "A0A044QY29",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY29"
  },
  {
    "uniprot": "A0A044VJC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJC3"
  },
  {
    "uniprot": "A0A044RLS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLS0"
  },
  {
    "uniprot": "A0A044R7B7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7B7"
  },
  {
    "uniprot": "A0A044RMM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMM8"
  },
  {
    "uniprot": "A0A044S080",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S080"
  },
  {
    "uniprot": "A0A044T0S0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0S0"
  },
  {
    "uniprot": "A0A2K6W7S2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7S2"
  },
  {
    "uniprot": "A0A044VE48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VE48"
  },
  {
    "uniprot": "A0A044SB07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB07"
  },
  {
    "uniprot": "A0A044SD15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD15"
  },
  {
    "uniprot": "A0A044RNC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNC5"
  },
  {
    "uniprot": "A0A044SN00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN00"
  },
  {
    "uniprot": "A0A044STK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STK3"
  },
  {
    "uniprot": "A0A044V900",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V900"
  },
  {
    "uniprot": "A0A044SDG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDG2"
  },
  {
    "uniprot": "A0A044T175",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T175"
  },
  {
    "uniprot": "A0A2K6W2X4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2X4"
  },
  {
    "uniprot": "A0A044TS93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS93"
  },
  {
    "uniprot": "A0A044SPP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPP7"
  },
  {
    "uniprot": "A0A044U6Z4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6Z4"
  },
  {
    "uniprot": "A0A044TI00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TI00"
  },
  {
    "uniprot": "A0A044QY01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY01"
  },
  {
    "uniprot": "A0A044R849",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R849"
  },
  {
    "uniprot": "A0A044QLF9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLF9"
  },
  {
    "uniprot": "A0A044QZ12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ12"
  },
  {
    "uniprot": "A0A2K6WFF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFF0"
  },
  {
    "uniprot": "A0A044SN41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN41"
  },
  {
    "uniprot": "A0A044TN20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN20"
  },
  {
    "uniprot": "A0A044QKV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKV4"
  },
  {
    "uniprot": "A0A044SAC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAC1"
  },
  {
    "uniprot": "A0A044S351",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S351"
  },
  {
    "uniprot": "A0A044V1B5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1B5"
  },
  {
    "uniprot": "A0A044RA47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RA47"
  },
  {
    "uniprot": "A0A044T1U9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1U9"
  },
  {
    "uniprot": "A0A044TGU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGU3"
  },
  {
    "uniprot": "A0A044V491",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V491"
  },
  {
    "uniprot": "A0A044RF34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RF34"
  },
  {
    "uniprot": "A0A044RJL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJL0"
  },
  {
    "uniprot": "A0A044UTN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTN6"
  },
  {
    "uniprot": "A0A2K6WBA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBA1"
  },
  {
    "uniprot": "A0A044SB18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB18"
  },
  {
    "uniprot": "A0A2K6VYJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYJ7"
  },
  {
    "uniprot": "A0A158N808",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N808"
  },
  {
    "uniprot": "A0A044R5I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5I9"
  },
  {
    "uniprot": "A0A044SKF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKF0"
  },
  {
    "uniprot": "A0A044R7X3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7X3"
  },
  {
    "uniprot": "A0A044QKB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKB1"
  },
  {
    "uniprot": "A0A044T2D8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2D8"
  },
  {
    "uniprot": "A0A044REP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REP1"
  },
  {
    "uniprot": "A0A044SC65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SC65"
  },
  {
    "uniprot": "A0A044T5P7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5P7"
  },
  {
    "uniprot": "A0A044R9D0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9D0"
  },
  {
    "uniprot": "A0A044UL22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL22"
  },
  {
    "uniprot": "A0A044SVC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVC0"
  },
  {
    "uniprot": "A0A044V5B0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5B0"
  },
  {
    "uniprot": "A0A044ULS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULS5"
  },
  {
    "uniprot": "A0A2K6WLC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLC9"
  },
  {
    "uniprot": "A0A044SQ82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ82"
  },
  {
    "uniprot": "A0A044V4I8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4I8"
  },
  {
    "uniprot": "A0A044TKN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKN6"
  },
  {
    "uniprot": "A0A044TR37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR37"
  },
  {
    "uniprot": "A0A2K6WFB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFB8"
  },
  {
    "uniprot": "A0A044U8C3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8C3"
  },
  {
    "uniprot": "A0A044V989",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V989"
  },
  {
    "uniprot": "A0A044TEK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEK9"
  },
  {
    "uniprot": "A0A044SYV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYV8"
  },
  {
    "uniprot": "A0A044U1U9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1U9"
  },
  {
    "uniprot": "Q25619",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q25619"
  },
  {
    "uniprot": "A0A044SC04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SC04"
  },
  {
    "uniprot": "A0A044SNI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNI0"
  },
  {
    "uniprot": "A0A044SQS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQS3"
  },
  {
    "uniprot": "A0A044QWE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWE2"
  },
  {
    "uniprot": "A0A044SHI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHI9"
  },
  {
    "uniprot": "A0A044TYS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYS3"
  },
  {
    "uniprot": "A0A044URK2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URK2"
  },
  {
    "uniprot": "A0A044SG43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG43"
  },
  {
    "uniprot": "A0A044SHG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHG4"
  },
  {
    "uniprot": "A0A044TX37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TX37"
  },
  {
    "uniprot": "A0A044SQL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQL5"
  },
  {
    "uniprot": "A0A044UQS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQS9"
  },
  {
    "uniprot": "A0A044QUE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUE0"
  },
  {
    "uniprot": "A0A044USE8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USE8"
  },
  {
    "uniprot": "A0A044RMP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMP3"
  },
  {
    "uniprot": "A0A044T742",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T742"
  },
  {
    "uniprot": "A0A044UMN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMN9"
  },
  {
    "uniprot": "A0A2K6WKT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKT4"
  },
  {
    "uniprot": "A0A044S9A5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9A5"
  },
  {
    "uniprot": "A0A044UG50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UG50"
  },
  {
    "uniprot": "A0A044VFK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFK4"
  },
  {
    "uniprot": "A0A044VE93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VE93"
  },
  {
    "uniprot": "A0A044S917",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S917"
  },
  {
    "uniprot": "A0A044UZ17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ17"
  },
  {
    "uniprot": "A0A044V497",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V497"
  },
  {
    "uniprot": "A0A044U2H0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2H0"
  },
  {
    "uniprot": "A0A044SDZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDZ3"
  },
  {
    "uniprot": "A0A2K6VYP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYP0"
  },
  {
    "uniprot": "A0A044RDK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDK8"
  },
  {
    "uniprot": "A0A044TUS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUS2"
  },
  {
    "uniprot": "A0A044VAQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAQ4"
  },
  {
    "uniprot": "A0A044U3J4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3J4"
  },
  {
    "uniprot": "A0A044QU24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QU24"
  },
  {
    "uniprot": "A0A2K6WEZ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEZ4"
  },
  {
    "uniprot": "A0A044QXK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXK6"
  },
  {
    "uniprot": "A0A044SVS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVS3"
  },
  {
    "uniprot": "A0A044SNL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNL8"
  },
  {
    "uniprot": "A0A044SDF7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDF7"
  },
  {
    "uniprot": "A0A044V111",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V111"
  },
  {
    "uniprot": "A0A044SN12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN12"
  },
  {
    "uniprot": "A0A044SJS8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJS8"
  },
  {
    "uniprot": "A0A044TD47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TD47"
  },
  {
    "uniprot": "A0A044TQP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQP9"
  },
  {
    "uniprot": "A0A044TBZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBZ8"
  },
  {
    "uniprot": "A0A044RB72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RB72"
  },
  {
    "uniprot": "A0A044RB88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RB88"
  },
  {
    "uniprot": "A0A044RWV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWV3"
  },
  {
    "uniprot": "A0A2K6VEB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VEB6"
  },
  {
    "uniprot": "A0A044UWW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWW9"
  },
  {
    "uniprot": "A0A044UH59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UH59"
  },
  {
    "uniprot": "A0A044UNU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNU8"
  },
  {
    "uniprot": "A0A044QNN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNN2"
  },
  {
    "uniprot": "A0A044RSZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSZ0"
  },
  {
    "uniprot": "A0A044U7J9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7J9"
  },
  {
    "uniprot": "A0A2K6WG42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WG42"
  },
  {
    "uniprot": "A0A044SM60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM60"
  },
  {
    "uniprot": "A0A044V4H7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4H7"
  },
  {
    "uniprot": "A0A044RJF7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJF7"
  },
  {
    "uniprot": "A0A044V1A4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1A4"
  },
  {
    "uniprot": "A0A044UWY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWY7"
  },
  {
    "uniprot": "A0A044SKA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKA7"
  },
  {
    "uniprot": "A0A044S2G5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2G5"
  },
  {
    "uniprot": "A0A044UKQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKQ6"
  },
  {
    "uniprot": "A0A044U2B1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2B1"
  },
  {
    "uniprot": "A0A044U7M9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7M9"
  },
  {
    "uniprot": "A0A044TTR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTR3"
  },
  {
    "uniprot": "A0A044SAR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAR4"
  },
  {
    "uniprot": "A0A044TUV4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUV4"
  },
  {
    "uniprot": "A0A044TH98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH98"
  },
  {
    "uniprot": "A0A044V6L0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6L0"
  },
  {
    "uniprot": "A0A158N883",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N883"
  },
  {
    "uniprot": "A0A044QMQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMQ7"
  },
  {
    "uniprot": "A0A044VDL6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDL6"
  },
  {
    "uniprot": "A0A044SES1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SES1"
  },
  {
    "uniprot": "A0A044REH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REH1"
  },
  {
    "uniprot": "A0A044V596",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V596"
  },
  {
    "uniprot": "A0A044VG09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VG09"
  },
  {
    "uniprot": "A0A044TQR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQR3"
  },
  {
    "uniprot": "A0A044RZ30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZ30"
  },
  {
    "uniprot": "A0A044VJ94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJ94"
  },
  {
    "uniprot": "A0A044V9P1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9P1"
  },
  {
    "uniprot": "A0A044QN20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QN20"
  },
  {
    "uniprot": "A0A044T7Y9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7Y9"
  },
  {
    "uniprot": "A0A2K6WFY9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFY9"
  },
  {
    "uniprot": "A0A044S3Q8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3Q8"
  },
  {
    "uniprot": "A0A044RDM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDM1"
  },
  {
    "uniprot": "A0A044TP87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TP87"
  },
  {
    "uniprot": "A0A158N8H4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8H4"
  },
  {
    "uniprot": "A0A044T3J4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3J4"
  },
  {
    "uniprot": "A0A158N8S7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8S7"
  },
  {
    "uniprot": "A0A044QWQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWQ0"
  },
  {
    "uniprot": "A0A044UAW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAW6"
  },
  {
    "uniprot": "A0A044TWR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWR6"
  },
  {
    "uniprot": "A0A044STQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STQ7"
  },
  {
    "uniprot": "A0A044RS68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RS68"
  },
  {
    "uniprot": "A0A044QM59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QM59"
  },
  {
    "uniprot": "A0A044SFY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFY8"
  },
  {
    "uniprot": "A0A044V7B6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7B6"
  },
  {
    "uniprot": "A0A044S407",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S407"
  },
  {
    "uniprot": "A0A044T801",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T801"
  },
  {
    "uniprot": "A0A044RNA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNA1"
  },
  {
    "uniprot": "A0A044UQ24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQ24"
  },
  {
    "uniprot": "A0A2K6VXG3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXG3"
  },
  {
    "uniprot": "A0A044U4E6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4E6"
  },
  {
    "uniprot": "A0A2K6W4F7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W4F7"
  },
  {
    "uniprot": "A0A044V0S0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0S0"
  },
  {
    "uniprot": "A0A044RYS1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYS1"
  },
  {
    "uniprot": "A0A044SE44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SE44"
  },
  {
    "uniprot": "A0A044S9G6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9G6"
  },
  {
    "uniprot": "A0A044SXX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXX8"
  },
  {
    "uniprot": "A0A044QWB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWB9"
  },
  {
    "uniprot": "A0A044TTE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTE1"
  },
  {
    "uniprot": "A0A044SQK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQK3"
  },
  {
    "uniprot": "A0A044TX07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TX07"
  },
  {
    "uniprot": "A0A2K6VHY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHY7"
  },
  {
    "uniprot": "A0A044QN85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QN85"
  },
  {
    "uniprot": "A0A044QTE8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTE8"
  },
  {
    "uniprot": "A0A044S011",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S011"
  },
  {
    "uniprot": "A0A044S637",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S637"
  },
  {
    "uniprot": "A0A044T680",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T680"
  },
  {
    "uniprot": "A0A044V131",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V131"
  },
  {
    "uniprot": "A0A044R3W8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3W8"
  },
  {
    "uniprot": "A0A044S3G6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3G6"
  },
  {
    "uniprot": "A0A044SUU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUU4"
  },
  {
    "uniprot": "A0A2K6WEF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEF5"
  },
  {
    "uniprot": "A0A044R593",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R593"
  },
  {
    "uniprot": "A0A044V4G6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4G6"
  },
  {
    "uniprot": "A0A2K6W254",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W254"
  },
  {
    "uniprot": "A0A044SPL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPL8"
  },
  {
    "uniprot": "A0A044UPJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPJ3"
  },
  {
    "uniprot": "A0A044URE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URE2"
  },
  {
    "uniprot": "A0A044SSX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSX9"
  },
  {
    "uniprot": "A0A044SF46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SF46"
  },
  {
    "uniprot": "A0A044RV77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RV77"
  },
  {
    "uniprot": "A0A044U0R4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0R4"
  },
  {
    "uniprot": "A0A044SIQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIQ1"
  },
  {
    "uniprot": "A0A044V0D2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0D2"
  },
  {
    "uniprot": "A0A044UTC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTC6"
  },
  {
    "uniprot": "A0A044T6V2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6V2"
  },
  {
    "uniprot": "A0A044UIA8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIA8"
  },
  {
    "uniprot": "A0A044VI55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VI55"
  },
  {
    "uniprot": "A0A044RPV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPV5"
  },
  {
    "uniprot": "A0A044SCL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCL9"
  },
  {
    "uniprot": "A0A044TQX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQX7"
  },
  {
    "uniprot": "A0A044QZK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZK3"
  },
  {
    "uniprot": "A0A044V3Z3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3Z3"
  },
  {
    "uniprot": "A0A044UNC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNC3"
  },
  {
    "uniprot": "A0A044U7I9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7I9"
  },
  {
    "uniprot": "A0A044UI71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI71"
  },
  {
    "uniprot": "A0A044UBB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBB8"
  },
  {
    "uniprot": "A0A044U2P3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2P3"
  },
  {
    "uniprot": "A0A044VAM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAM3"
  },
  {
    "uniprot": "A0A044RPU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPU5"
  },
  {
    "uniprot": "A0A2K6VIS9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VIS9"
  },
  {
    "uniprot": "A0A044UJ83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJ83"
  },
  {
    "uniprot": "A0A158N7P4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7P4"
  },
  {
    "uniprot": "A0A044SRD2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRD2"
  },
  {
    "uniprot": "A0A044U434",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U434"
  },
  {
    "uniprot": "A0A044S782",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S782"
  },
  {
    "uniprot": "A0A044TGG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGG4"
  },
  {
    "uniprot": "A0A2K6VHF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHF3"
  },
  {
    "uniprot": "A0A044S4W2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4W2"
  },
  {
    "uniprot": "A0A044U1D9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1D9"
  },
  {
    "uniprot": "A0A044V5B7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5B7"
  },
  {
    "uniprot": "A0A044V8V3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8V3"
  },
  {
    "uniprot": "A0A044T0B2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0B2"
  },
  {
    "uniprot": "A0A044U8N6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8N6"
  },
  {
    "uniprot": "A0A044VHX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHX2"
  },
  {
    "uniprot": "A0A044SXD4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXD4"
  },
  {
    "uniprot": "A0A044SX70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SX70"
  },
  {
    "uniprot": "A0A2K6WD49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WD49"
  },
  {
    "uniprot": "A0A044SHY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHY2"
  },
  {
    "uniprot": "A0A044V689",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V689"
  },
  {
    "uniprot": "A0A2K6W2U0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2U0"
  },
  {
    "uniprot": "A0A044RW25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RW25"
  },
  {
    "uniprot": "A0A044V9W4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9W4"
  },
  {
    "uniprot": "A0A2K6WEC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEC9"
  },
  {
    "uniprot": "A0A044THH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THH9"
  },
  {
    "uniprot": "A0A044TUB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUB9"
  },
  {
    "uniprot": "A0A044QVS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVS5"
  },
  {
    "uniprot": "A0A044TMS8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMS8"
  },
  {
    "uniprot": "A0A2K6VXE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXE3"
  },
  {
    "uniprot": "A0A044V2P0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2P0"
  },
  {
    "uniprot": "A0A044R675",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R675"
  },
  {
    "uniprot": "A0A044UAX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAX4"
  },
  {
    "uniprot": "A0A044T377",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T377"
  },
  {
    "uniprot": "A0A044TQQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQQ9"
  },
  {
    "uniprot": "A0A044TP81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TP81"
  },
  {
    "uniprot": "A0A2K6VS39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VS39"
  },
  {
    "uniprot": "A0A2K6W8Q6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8Q6"
  },
  {
    "uniprot": "A0A044V082",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V082"
  },
  {
    "uniprot": "A0A044VEU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEU6"
  },
  {
    "uniprot": "A0A044R7X9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7X9"
  },
  {
    "uniprot": "A0A2K6VL98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VL98"
  },
  {
    "uniprot": "A0A044RBU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBU1"
  },
  {
    "uniprot": "A0A044T064",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T064"
  },
  {
    "uniprot": "A0A044TXY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXY4"
  },
  {
    "uniprot": "A0A044QPP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPP7"
  },
  {
    "uniprot": "A0A044U894",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U894"
  },
  {
    "uniprot": "A0A044V946",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V946"
  },
  {
    "uniprot": "A0A044UZM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZM7"
  },
  {
    "uniprot": "A0A044UWR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWR3"
  },
  {
    "uniprot": "A0A2K6W7T0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7T0"
  },
  {
    "uniprot": "A0A044U1M2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1M2"
  },
  {
    "uniprot": "A0A044V9H0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9H0"
  },
  {
    "uniprot": "A0A044SF73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SF73"
  },
  {
    "uniprot": "A0A044TZR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZR8"
  },
  {
    "uniprot": "A0A2K6VWH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWH5"
  },
  {
    "uniprot": "A0A044V144",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V144"
  },
  {
    "uniprot": "A0A044UEC3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEC3"
  },
  {
    "uniprot": "A0A044U6L5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6L5"
  },
  {
    "uniprot": "A0A044URH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URH8"
  },
  {
    "uniprot": "A0A044V5K8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5K8"
  },
  {
    "uniprot": "A0A044RH41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH41"
  },
  {
    "uniprot": "A0A044S1K9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1K9"
  },
  {
    "uniprot": "A0A044TBE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBE7"
  },
  {
    "uniprot": "A0A044R721",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R721"
  },
  {
    "uniprot": "A0A044RSE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSE5"
  },
  {
    "uniprot": "A0A044R5D2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5D2"
  },
  {
    "uniprot": "A0A044TQS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQS4"
  },
  {
    "uniprot": "A0A044SAQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAQ4"
  },
  {
    "uniprot": "A0A044RSI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSI3"
  },
  {
    "uniprot": "A0A044TC59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC59"
  },
  {
    "uniprot": "Q25604",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q25604"
  },
  {
    "uniprot": "A0A044V154",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V154"
  },
  {
    "uniprot": "A0A044R169",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R169"
  },
  {
    "uniprot": "A0A044RT30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RT30"
  },
  {
    "uniprot": "A0A044VB14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VB14"
  },
  {
    "uniprot": "A0A044SR00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SR00"
  },
  {
    "uniprot": "A0A044REY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REY8"
  },
  {
    "uniprot": "A0A044STX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STX1"
  },
  {
    "uniprot": "A0A044U7A4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7A4"
  },
  {
    "uniprot": "A0A044SGB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGB7"
  },
  {
    "uniprot": "A0A044V1K2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1K2"
  },
  {
    "uniprot": "A0A044V407",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V407"
  },
  {
    "uniprot": "A0A044SPK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPK9"
  },
  {
    "uniprot": "A0A044SIN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIN4"
  },
  {
    "uniprot": "A0A044UP17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UP17"
  },
  {
    "uniprot": "A0A044VBP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBP3"
  },
  {
    "uniprot": "A0A044QQ18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQ18"
  },
  {
    "uniprot": "A0A044TLG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLG1"
  },
  {
    "uniprot": "A0A044QJZ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJZ8"
  },
  {
    "uniprot": "A0A044TQH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQH0"
  },
  {
    "uniprot": "A0A158N874",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N874"
  },
  {
    "uniprot": "A0A044TGX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGX7"
  },
  {
    "uniprot": "A0A044TSE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSE9"
  },
  {
    "uniprot": "A0A044SW70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SW70"
  },
  {
    "uniprot": "A0A044T1U1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1U1"
  },
  {
    "uniprot": "A0A044UZZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZZ0"
  },
  {
    "uniprot": "A0A044RKG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKG0"
  },
  {
    "uniprot": "A0A044V334",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V334"
  },
  {
    "uniprot": "A0A044REF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REF1"
  },
  {
    "uniprot": "A0A044SPQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPQ3"
  },
  {
    "uniprot": "A0A044QZ60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ60"
  },
  {
    "uniprot": "A0A044R6U4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6U4"
  },
  {
    "uniprot": "A0A044S736",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S736"
  },
  {
    "uniprot": "A0A2K6VLF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLF4"
  },
  {
    "uniprot": "A0A044TEW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEW6"
  },
  {
    "uniprot": "A0A044RC37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC37"
  },
  {
    "uniprot": "A0A044TYH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYH9"
  },
  {
    "uniprot": "A0A2K6VYI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYI0"
  },
  {
    "uniprot": "A0A2K6W426",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W426"
  },
  {
    "uniprot": "A0A044QUM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUM3"
  },
  {
    "uniprot": "A0A044V138",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V138"
  },
  {
    "uniprot": "A0A044TMG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMG4"
  },
  {
    "uniprot": "A0A044UVH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVH1"
  },
  {
    "uniprot": "A0A044U1G3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1G3"
  },
  {
    "uniprot": "A0A044UNN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNN8"
  },
  {
    "uniprot": "A0A044VF57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VF57"
  },
  {
    "uniprot": "A0A044T4V0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4V0"
  },
  {
    "uniprot": "A0A044RU40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU40"
  },
  {
    "uniprot": "A0A044VFB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFB3"
  },
  {
    "uniprot": "A0A044RCW9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCW9"
  },
  {
    "uniprot": "A0A044SWB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWB1"
  },
  {
    "uniprot": "A0A044T3P6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3P6"
  },
  {
    "uniprot": "A0A044UMX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMX8"
  },
  {
    "uniprot": "A0A2K6VX70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VX70"
  },
  {
    "uniprot": "A0A044U6I0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6I0"
  },
  {
    "uniprot": "A0A2K6WE11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WE11"
  },
  {
    "uniprot": "A0A044TLL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLL2"
  },
  {
    "uniprot": "A0A044VDS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDS5"
  },
  {
    "uniprot": "A0A044SG12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG12"
  },
  {
    "uniprot": "A0A2K6VYP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYP7"
  },
  {
    "uniprot": "A0A044V409",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V409"
  },
  {
    "uniprot": "A0A044RAX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAX3"
  },
  {
    "uniprot": "A0A044TK33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK33"
  },
  {
    "uniprot": "A0A2K6VQY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQY6"
  },
  {
    "uniprot": "A0A044TXT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXT9"
  },
  {
    "uniprot": "A0A044RWR5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWR5"
  },
  {
    "uniprot": "A0A044UZQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZQ6"
  },
  {
    "uniprot": "A0A044TJP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJP2"
  },
  {
    "uniprot": "A0A044RU62",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU62"
  },
  {
    "uniprot": "A0A044STH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STH9"
  },
  {
    "uniprot": "A0A044TCS3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCS3"
  },
  {
    "uniprot": "A0A044QTM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTM3"
  },
  {
    "uniprot": "A0A044RTB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTB8"
  },
  {
    "uniprot": "A0A2K6W6C9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6C9"
  },
  {
    "uniprot": "A0A044RMV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMV8"
  },
  {
    "uniprot": "A0A044U0X8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0X8"
  },
  {
    "uniprot": "A0A044TEZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEZ1"
  },
  {
    "uniprot": "A0A044QSB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSB0"
  },
  {
    "uniprot": "A0A044U505",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U505"
  },
  {
    "uniprot": "A0A044VAP5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAP5"
  },
  {
    "uniprot": "A0A044UCF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCF1"
  },
  {
    "uniprot": "A0A044UWT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWT2"
  },
  {
    "uniprot": "A0A044T261",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T261"
  },
  {
    "uniprot": "Q9NHZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q9NHZ5"
  },
  {
    "uniprot": "A0A2K6VP10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VP10"
  },
  {
    "uniprot": "A0A044UHX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHX4"
  },
  {
    "uniprot": "A0A044R752",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R752"
  },
  {
    "uniprot": "A0A044V469",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V469"
  },
  {
    "uniprot": "A0A044RXX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXX5"
  },
  {
    "uniprot": "A0A044V509",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V509"
  },
  {
    "uniprot": "A0A044SGZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGZ2"
  },
  {
    "uniprot": "A0A044T0S7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0S7"
  },
  {
    "uniprot": "A0A044TWF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWF4"
  },
  {
    "uniprot": "A0A044V9R3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9R3"
  },
  {
    "uniprot": "A0A2K6W9H5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9H5"
  },
  {
    "uniprot": "A0A044UM84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UM84"
  },
  {
    "uniprot": "A0A2K6VUE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VUE1"
  },
  {
    "uniprot": "A0A2K6VL71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VL71"
  },
  {
    "uniprot": "A0A044RAK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAK8"
  },
  {
    "uniprot": "A0A044UI44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI44"
  },
  {
    "uniprot": "A0A044RT13",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RT13"
  },
  {
    "uniprot": "A0A044V0Z4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0Z4"
  },
  {
    "uniprot": "A0A044UV94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UV94"
  },
  {
    "uniprot": "A0A044V482",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V482"
  },
  {
    "uniprot": "A0A044TJ59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJ59"
  },
  {
    "uniprot": "A0A044S4C0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4C0"
  },
  {
    "uniprot": "A0A044VFP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFP4"
  },
  {
    "uniprot": "A0A044QYB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYB9"
  },
  {
    "uniprot": "A0A044UVH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVH5"
  },
  {
    "uniprot": "A0A044R952",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R952"
  },
  {
    "uniprot": "A0A044VGA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGA7"
  },
  {
    "uniprot": "A0A044U1J2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1J2"
  },
  {
    "uniprot": "A0A044V9T9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9T9"
  },
  {
    "uniprot": "A0A044U4G1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4G1"
  },
  {
    "uniprot": "A0A2K6W5W8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W5W8"
  },
  {
    "uniprot": "A0A044UG04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UG04"
  },
  {
    "uniprot": "A0A044UG93",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UG93"
  },
  {
    "uniprot": "A0A044UHM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHM1"
  },
  {
    "uniprot": "Q25605",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/Q25605"
  },
  {
    "uniprot": "A0A044UYI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYI4"
  },
  {
    "uniprot": "A0A044V8Y2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Y2"
  },
  {
    "uniprot": "A0A044VCV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCV5"
  },
  {
    "uniprot": "A0A044U2D3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2D3"
  },
  {
    "uniprot": "A0A044VAF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAF8"
  },
  {
    "uniprot": "A0A044U3W2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3W2"
  },
  {
    "uniprot": "A0A044V147",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V147"
  },
  {
    "uniprot": "A0A2K6VXA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXA1"
  },
  {
    "uniprot": "A0A044VFH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFH0"
  },
  {
    "uniprot": "A0A044SDE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDE2"
  },
  {
    "uniprot": "A0A044SJQ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJQ2"
  },
  {
    "uniprot": "A0A044S9Y2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9Y2"
  },
  {
    "uniprot": "A0A044QUA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUA6"
  },
  {
    "uniprot": "A0A044SBQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBQ0"
  },
  {
    "uniprot": "A0A044RMR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMR6"
  },
  {
    "uniprot": "A0A044V747",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V747"
  },
  {
    "uniprot": "A0A044SB77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB77"
  },
  {
    "uniprot": "A0A044UZ37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ37"
  },
  {
    "uniprot": "A0A044S1N6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1N6"
  },
  {
    "uniprot": "A0A044TI33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TI33"
  },
  {
    "uniprot": "A0A044T2Q0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2Q0"
  },
  {
    "uniprot": "A0A044S4Q5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4Q5"
  },
  {
    "uniprot": "A0A044SNI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNI9"
  },
  {
    "uniprot": "A0A044T2D3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2D3"
  },
  {
    "uniprot": "A0A044U2B9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2B9"
  },
  {
    "uniprot": "A0A044TUD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUD6"
  },
  {
    "uniprot": "A0A044QKN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKN8"
  },
  {
    "uniprot": "A0A044VB80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VB80"
  },
  {
    "uniprot": "A0A044V3W5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3W5"
  },
  {
    "uniprot": "A0A044T6T9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6T9"
  },
  {
    "uniprot": "A0A2K6VPX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VPX7"
  },
  {
    "uniprot": "A0A158N7U2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7U2"
  },
  {
    "uniprot": "A0A158N7Y4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Y4"
  },
  {
    "uniprot": "A0A044V4J6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4J6"
  },
  {
    "uniprot": "A0A044QSM4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSM4"
  },
  {
    "uniprot": "A0A044V4B6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4B6"
  },
  {
    "uniprot": "A0A044UXL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXL9"
  },
  {
    "uniprot": "A0A044UQD9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQD9"
  },
  {
    "uniprot": "A0A044V947",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V947"
  },
  {
    "uniprot": "A0A2K6WPE1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WPE1"
  },
  {
    "uniprot": "A0A2K6WAG3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAG3"
  },
  {
    "uniprot": "A0A044V929",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V929"
  },
  {
    "uniprot": "A0A044UYA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYA9"
  },
  {
    "uniprot": "A0A158N7X8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7X8"
  },
  {
    "uniprot": "A0A044UK65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UK65"
  },
  {
    "uniprot": "A0A044RK34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RK34"
  },
  {
    "uniprot": "A0A044TG50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TG50"
  },
  {
    "uniprot": "A0A044RD96",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RD96"
  },
  {
    "uniprot": "A0A044USI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USI4"
  },
  {
    "uniprot": "A0A044T1T1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1T1"
  },
  {
    "uniprot": "A0A2K6VF09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VF09"
  },
  {
    "uniprot": "A0A2K6WMM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMM2"
  },
  {
    "uniprot": "A0A044RH48",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH48"
  },
  {
    "uniprot": "A0A044UIH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIH6"
  },
  {
    "uniprot": "A0A044RFA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFA3"
  },
  {
    "uniprot": "A0A2K6WHK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WHK4"
  },
  {
    "uniprot": "A0A044U9T8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9T8"
  },
  {
    "uniprot": "A0A044RDC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDC0"
  },
  {
    "uniprot": "A0A158N7Y5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Y5"
  },
  {
    "uniprot": "A0A044RMT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMT2"
  },
  {
    "uniprot": "A0A2K6WEB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEB1"
  },
  {
    "uniprot": "A0A044SXU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXU1"
  },
  {
    "uniprot": "A0A044UM55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UM55"
  },
  {
    "uniprot": "A0A044RN79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RN79"
  },
  {
    "uniprot": "A0A044VAY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAY5"
  },
  {
    "uniprot": "A0A044T1F1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1F1"
  },
  {
    "uniprot": "A0A2K6VZG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZG6"
  },
  {
    "uniprot": "A0A044TF70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TF70"
  },
  {
    "uniprot": "A0A2K6VD84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VD84"
  },
  {
    "uniprot": "A0A044UKX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKX6"
  },
  {
    "uniprot": "A0A044T6R3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6R3"
  },
  {
    "uniprot": "A0A044T6Y6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6Y6"
  },
  {
    "uniprot": "A0A044R7P9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7P9"
  },
  {
    "uniprot": "A0A2K6VQS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQS2"
  },
  {
    "uniprot": "A0A044TI40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TI40"
  },
  {
    "uniprot": "A0A044UWL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWL5"
  },
  {
    "uniprot": "A0A044V1A8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1A8"
  },
  {
    "uniprot": "A0A044R714",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R714"
  },
  {
    "uniprot": "A0A044RCM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCM8"
  },
  {
    "uniprot": "A0A044USA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USA5"
  },
  {
    "uniprot": "A0A044U9J9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9J9"
  },
  {
    "uniprot": "A0A044S6V5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6V5"
  },
  {
    "uniprot": "A0A044SAM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAM6"
  },
  {
    "uniprot": "A0A044RNV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNV9"
  },
  {
    "uniprot": "A0A044UHF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHF2"
  },
  {
    "uniprot": "A0A044RM24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RM24"
  },
  {
    "uniprot": "A0A044TZH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZH9"
  },
  {
    "uniprot": "A0A2K6VKD2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VKD2"
  },
  {
    "uniprot": "A0A044RSL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSL2"
  },
  {
    "uniprot": "A0A2K6VWL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWL1"
  },
  {
    "uniprot": "A0A044V740",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V740"
  },
  {
    "uniprot": "A0A044TZL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZL1"
  },
  {
    "uniprot": "A0A044V557",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V557"
  },
  {
    "uniprot": "A0A044RL70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RL70"
  },
  {
    "uniprot": "A0A044RS37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RS37"
  },
  {
    "uniprot": "A0A2K6WHV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WHV0"
  },
  {
    "uniprot": "A0A044SIM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIM6"
  },
  {
    "uniprot": "A0A044RYV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYV9"
  },
  {
    "uniprot": "A0A044US32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044US32"
  },
  {
    "uniprot": "A0A044SJ12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJ12"
  },
  {
    "uniprot": "A0A044SKB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKB6"
  },
  {
    "uniprot": "A0A044VF63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VF63"
  },
  {
    "uniprot": "A0A044QMH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QMH3"
  },
  {
    "uniprot": "A0A044RZ53",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZ53"
  },
  {
    "uniprot": "A0A044R8R3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8R3"
  },
  {
    "uniprot": "A0A044SN76",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN76"
  },
  {
    "uniprot": "A0A044SZF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZF8"
  },
  {
    "uniprot": "A0A044UZ15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ15"
  },
  {
    "uniprot": "A0A044SUL9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUL9"
  },
  {
    "uniprot": "A0A044T956",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T956"
  },
  {
    "uniprot": "A0A044THB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THB1"
  },
  {
    "uniprot": "A0A044TVB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVB6"
  },
  {
    "uniprot": "A0A044T467",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T467"
  },
  {
    "uniprot": "A0A044V418",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V418"
  },
  {
    "uniprot": "A0A044U7L5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7L5"
  },
  {
    "uniprot": "A0A044SP32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SP32"
  },
  {
    "uniprot": "A0A044TMZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMZ9"
  },
  {
    "uniprot": "A0A2K6VTA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VTA5"
  },
  {
    "uniprot": "A0A044SL77",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SL77"
  },
  {
    "uniprot": "A0A044UW11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UW11"
  },
  {
    "uniprot": "A0A044U905",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U905"
  },
  {
    "uniprot": "A0A044UVL4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVL4"
  },
  {
    "uniprot": "A0A044QSL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSL5"
  },
  {
    "uniprot": "A0A044SR15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SR15"
  },
  {
    "uniprot": "A0A158N8B3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8B3"
  },
  {
    "uniprot": "A0A044UBR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBR0"
  },
  {
    "uniprot": "A0A2K6VK03",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VK03"
  },
  {
    "uniprot": "A0A044R9R5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9R5"
  },
  {
    "uniprot": "A0A044UD12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UD12"
  },
  {
    "uniprot": "A0A044UER0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UER0"
  },
  {
    "uniprot": "A0A044THM3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THM3"
  },
  {
    "uniprot": "A0A2K6WHL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WHL1"
  },
  {
    "uniprot": "A0A044V492",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V492"
  },
  {
    "uniprot": "A0A044QKS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKS5"
  },
  {
    "uniprot": "A0A044QL90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL90"
  },
  {
    "uniprot": "A0A2K6W0Y4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0Y4"
  },
  {
    "uniprot": "A0A044VDI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDI8"
  },
  {
    "uniprot": "A0A2K6WMY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMY3"
  },
  {
    "uniprot": "A0A044T8D1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8D1"
  },
  {
    "uniprot": "A0A044TEH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEH8"
  },
  {
    "uniprot": "A0A044URF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URF5"
  },
  {
    "uniprot": "A0A044VDB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDB8"
  },
  {
    "uniprot": "A0A044TCE6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCE6"
  },
  {
    "uniprot": "A0A044TIG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIG7"
  },
  {
    "uniprot": "A0A044TA99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TA99"
  },
  {
    "uniprot": "A0A044SY15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY15"
  },
  {
    "uniprot": "A0A044RD46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RD46"
  },
  {
    "uniprot": "A0A044UBH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBH8"
  },
  {
    "uniprot": "A0A044RRL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRL1"
  },
  {
    "uniprot": "A0A044VHT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHT2"
  },
  {
    "uniprot": "A0A044SVQ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVQ8"
  },
  {
    "uniprot": "A0A044SZI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZI1"
  },
  {
    "uniprot": "A0A044V9J5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9J5"
  },
  {
    "uniprot": "A0A044RI64",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RI64"
  },
  {
    "uniprot": "A0A044TT49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TT49"
  },
  {
    "uniprot": "A0A044UMA2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMA2"
  },
  {
    "uniprot": "A0A044SUC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUC0"
  },
  {
    "uniprot": "A0A044V8B7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8B7"
  },
  {
    "uniprot": "A0A044U305",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U305"
  },
  {
    "uniprot": "A0A044V968",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V968"
  },
  {
    "uniprot": "A0A2K6VVQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVQ3"
  },
  {
    "uniprot": "A0A044SKZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKZ5"
  },
  {
    "uniprot": "A0A044TZW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZW0"
  },
  {
    "uniprot": "A0A044TS47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS47"
  },
  {
    "uniprot": "A0A044UWI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWI0"
  },
  {
    "uniprot": "A0A044V6W3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6W3"
  },
  {
    "uniprot": "A0A2K6WAS1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WAS1"
  },
  {
    "uniprot": "A0A044UBZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBZ2"
  },
  {
    "uniprot": "A0A044UK19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UK19"
  },
  {
    "uniprot": "A0A2K6VL63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VL63"
  },
  {
    "uniprot": "A0A044SXS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXS2"
  },
  {
    "uniprot": "A0A044RKK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKK3"
  },
  {
    "uniprot": "A0A158N7X5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7X5"
  },
  {
    "uniprot": "A0A044TP32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TP32"
  },
  {
    "uniprot": "A0A044R2W5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2W5"
  },
  {
    "uniprot": "A0A044SZ73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ73"
  },
  {
    "uniprot": "A0A044VGI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGI1"
  },
  {
    "uniprot": "A0A044UHA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHA4"
  },
  {
    "uniprot": "A0A044UTJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTJ7"
  },
  {
    "uniprot": "A0A044TJV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJV7"
  },
  {
    "uniprot": "A0A044SE86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SE86"
  },
  {
    "uniprot": "A0A2K6WJ33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJ33"
  },
  {
    "uniprot": "A0A044SZN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZN6"
  },
  {
    "uniprot": "A0A044RU00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU00"
  },
  {
    "uniprot": "A0A044RHZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHZ5"
  },
  {
    "uniprot": "A0A044UH47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UH47"
  },
  {
    "uniprot": "A0A044VFW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFW4"
  },
  {
    "uniprot": "A0A044QXA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXA5"
  },
  {
    "uniprot": "A0A044UBM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBM7"
  },
  {
    "uniprot": "A0A2K6WIG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WIG9"
  },
  {
    "uniprot": "A0A044TK34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK34"
  },
  {
    "uniprot": "A0A044UVB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVB3"
  },
  {
    "uniprot": "A0A044QR02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QR02"
  },
  {
    "uniprot": "A0A044UFV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFV1"
  },
  {
    "uniprot": "A0A044QR64",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QR64"
  },
  {
    "uniprot": "A0A044TMC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMC4"
  },
  {
    "uniprot": "A0A044TSZ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSZ2"
  },
  {
    "uniprot": "A0A044UJB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJB9"
  },
  {
    "uniprot": "A0A044UMG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMG4"
  },
  {
    "uniprot": "A0A044QRA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRA9"
  },
  {
    "uniprot": "A0A044RGS1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGS1"
  },
  {
    "uniprot": "A0A044RXA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXA1"
  },
  {
    "uniprot": "A0A044SS42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS42"
  },
  {
    "uniprot": "A0A044RHT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHT5"
  },
  {
    "uniprot": "A0A044SQD0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQD0"
  },
  {
    "uniprot": "A0A044VF92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VF92"
  },
  {
    "uniprot": "A0A044T6Q9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6Q9"
  },
  {
    "uniprot": "A0A044VD47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD47"
  },
  {
    "uniprot": "A0A044QWX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWX8"
  },
  {
    "uniprot": "A0A044TB74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TB74"
  },
  {
    "uniprot": "A0A044T5C7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5C7"
  },
  {
    "uniprot": "A0A044VGG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGG8"
  },
  {
    "uniprot": "A0A044R2L7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2L7"
  },
  {
    "uniprot": "A0A044S7E7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7E7"
  },
  {
    "uniprot": "A0A044RIJ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIJ2"
  },
  {
    "uniprot": "A0A044R6A6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6A6"
  },
  {
    "uniprot": "A0A044T5L8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5L8"
  },
  {
    "uniprot": "A0A044UCM7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCM7"
  },
  {
    "uniprot": "A0A044TED0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TED0"
  },
  {
    "uniprot": "A0A044TKR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKR4"
  },
  {
    "uniprot": "A0A044T9P6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9P6"
  },
  {
    "uniprot": "A0A044R5F2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5F2"
  },
  {
    "uniprot": "A0A044UP63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UP63"
  },
  {
    "uniprot": "A0A044SJ34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJ34"
  },
  {
    "uniprot": "A0A044R9X6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9X6"
  },
  {
    "uniprot": "A0A044UJU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJU0"
  },
  {
    "uniprot": "A0A044U4B9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4B9"
  },
  {
    "uniprot": "P13262",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P13262"
  },
  {
    "uniprot": "A0A044RAR6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAR6"
  },
  {
    "uniprot": "A0A044UUA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUA6"
  },
  {
    "uniprot": "A0A044U2C2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2C2"
  },
  {
    "uniprot": "A0A044RVZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVZ5"
  },
  {
    "uniprot": "A0A044RLB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLB6"
  },
  {
    "uniprot": "A0A044TKF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKF5"
  },
  {
    "uniprot": "A0A044U7Q7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7Q7"
  },
  {
    "uniprot": "A0A044U530",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U530"
  },
  {
    "uniprot": "A0A044V7L7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7L7"
  },
  {
    "uniprot": "A0A044R5F6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5F6"
  },
  {
    "uniprot": "A0A2K6VW95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VW95"
  },
  {
    "uniprot": "A0A044RJH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJH9"
  },
  {
    "uniprot": "A0A044UVB2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVB2"
  },
  {
    "uniprot": "A0A044SQB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQB1"
  },
  {
    "uniprot": "A0A044V4G9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4G9"
  },
  {
    "uniprot": "A0A044SSW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSW5"
  },
  {
    "uniprot": "A0A044U0I5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0I5"
  },
  {
    "uniprot": "A0A044SQA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQA9"
  },
  {
    "uniprot": "A0A044STN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STN7"
  },
  {
    "uniprot": "A0A044UD57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UD57"
  },
  {
    "uniprot": "A0A044V908",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V908"
  },
  {
    "uniprot": "A0A044S7K2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7K2"
  },
  {
    "uniprot": "A0A044SLV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLV5"
  },
  {
    "uniprot": "A0A044QQR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQR7"
  },
  {
    "uniprot": "A0A044V7G3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7G3"
  },
  {
    "uniprot": "A0A2K6W6K4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6K4"
  },
  {
    "uniprot": "A0A2K6VRH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRH7"
  },
  {
    "uniprot": "A0A044TTH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTH0"
  },
  {
    "uniprot": "A0A2K6VSV9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSV9"
  },
  {
    "uniprot": "A0A044TR83",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR83"
  },
  {
    "uniprot": "A0A044UE17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE17"
  },
  {
    "uniprot": "A0A044S5I5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5I5"
  },
  {
    "uniprot": "A0A2K6WHT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WHT2"
  },
  {
    "uniprot": "A0A044QWF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QWF6"
  },
  {
    "uniprot": "A0A044UZI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZI4"
  },
  {
    "uniprot": "A0A044V927",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V927"
  },
  {
    "uniprot": "A0A2K6VTH3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VTH3"
  },
  {
    "uniprot": "A0A044V7H8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7H8"
  },
  {
    "uniprot": "A0A044VGA2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGA2"
  },
  {
    "uniprot": "A0A044R6H3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6H3"
  },
  {
    "uniprot": "A0A044S138",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S138"
  },
  {
    "uniprot": "A0A044UZ09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ09"
  },
  {
    "uniprot": "A0A044VGL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGL8"
  },
  {
    "uniprot": "A0A044SE99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SE99"
  },
  {
    "uniprot": "A0A044VBG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBG1"
  },
  {
    "uniprot": "A0A044SU74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU74"
  },
  {
    "uniprot": "A0A044V439",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V439"
  },
  {
    "uniprot": "A0A2K6VH78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VH78"
  },
  {
    "uniprot": "A0A044UPC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPC4"
  },
  {
    "uniprot": "A0A044V490",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V490"
  },
  {
    "uniprot": "A0A044ULE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULE2"
  },
  {
    "uniprot": "A0A044TYY8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYY8"
  },
  {
    "uniprot": "A0A044UU92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UU92"
  },
  {
    "uniprot": "A0A044SUI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUI6"
  },
  {
    "uniprot": "A0A044TLP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLP9"
  },
  {
    "uniprot": "A0A044T0Q3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0Q3"
  },
  {
    "uniprot": "A0A044UPH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPH1"
  },
  {
    "uniprot": "A0A044SEQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEQ9"
  },
  {
    "uniprot": "A0A044SFN5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFN5"
  },
  {
    "uniprot": "A0A2K6W4L0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W4L0"
  },
  {
    "uniprot": "A0A044QLH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLH0"
  },
  {
    "uniprot": "A0A044TUE8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUE8"
  },
  {
    "uniprot": "A0A044RCN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCN8"
  },
  {
    "uniprot": "A0A044TBW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBW0"
  },
  {
    "uniprot": "A0A044RQK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQK5"
  },
  {
    "uniprot": "A0A044RDV7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDV7"
  },
  {
    "uniprot": "A0A044RR30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RR30"
  },
  {
    "uniprot": "A0A044VBP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBP6"
  },
  {
    "uniprot": "A0A044V167",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V167"
  },
  {
    "uniprot": "A0A044SB37",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB37"
  },
  {
    "uniprot": "A0A044QYA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYA7"
  },
  {
    "uniprot": "A0A044RZZ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZZ5"
  },
  {
    "uniprot": "A0A044VBX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBX6"
  },
  {
    "uniprot": "A0A044V440",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V440"
  },
  {
    "uniprot": "A0A2K6VV84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VV84"
  },
  {
    "uniprot": "A0A044UPX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPX7"
  },
  {
    "uniprot": "A0A044TAI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAI1"
  },
  {
    "uniprot": "A0A044QUA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUA1"
  },
  {
    "uniprot": "A0A044UJQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJQ4"
  },
  {
    "uniprot": "A0A044RYZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYZ0"
  },
  {
    "uniprot": "A0A044RU04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU04"
  },
  {
    "uniprot": "A0A044QNQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNQ5"
  },
  {
    "uniprot": "A0A044UAM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAM0"
  },
  {
    "uniprot": "A0A044UFY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFY7"
  },
  {
    "uniprot": "A0A044QQ85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQ85"
  },
  {
    "uniprot": "A0A044V2H7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2H7"
  },
  {
    "uniprot": "A0A044UX68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UX68"
  },
  {
    "uniprot": "A0A044S4V5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S4V5"
  },
  {
    "uniprot": "A0A044RSQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSQ1"
  },
  {
    "uniprot": "A0A044QKQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKQ4"
  },
  {
    "uniprot": "A0A044UBW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBW2"
  },
  {
    "uniprot": "A0A044UVM9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVM9"
  },
  {
    "uniprot": "A0A044TRC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRC2"
  },
  {
    "uniprot": "A0A044RV06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RV06"
  },
  {
    "uniprot": "A0A044UL68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL68"
  },
  {
    "uniprot": "A0A044SMG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMG8"
  },
  {
    "uniprot": "A0A044RCB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCB4"
  },
  {
    "uniprot": "A0A044R3T1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3T1"
  },
  {
    "uniprot": "A0A044TTS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTS2"
  },
  {
    "uniprot": "A0A044V159",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V159"
  },
  {
    "uniprot": "A0A044SI46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI46"
  },
  {
    "uniprot": "A0A044TSG9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSG9"
  },
  {
    "uniprot": "A0A044R8G3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8G3"
  },
  {
    "uniprot": "A0A044SYM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYM5"
  },
  {
    "uniprot": "A0A2K6VMQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMQ6"
  },
  {
    "uniprot": "A0A044QPD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPD3"
  },
  {
    "uniprot": "A0A044TPU8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPU8"
  },
  {
    "uniprot": "A0A044SHG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHG8"
  },
  {
    "uniprot": "A0A044UKX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKX9"
  },
  {
    "uniprot": "A0A044UVY0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVY0"
  },
  {
    "uniprot": "A0A044SDQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDQ5"
  },
  {
    "uniprot": "A0A044QT23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT23"
  },
  {
    "uniprot": "A0A044UZ06",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ06"
  },
  {
    "uniprot": "A0A044UNP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNP8"
  },
  {
    "uniprot": "A0A2K6VGW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VGW0"
  },
  {
    "uniprot": "A0A044QYC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYC5"
  },
  {
    "uniprot": "A0A044UYK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYK6"
  },
  {
    "uniprot": "A0A044QM42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QM42"
  },
  {
    "uniprot": "A0A044VGV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGV3"
  },
  {
    "uniprot": "A0A044SIL1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIL1"
  },
  {
    "uniprot": "A0A2K6VZT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZT4"
  },
  {
    "uniprot": "A0A044SAI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAI4"
  },
  {
    "uniprot": "A0A044USB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USB7"
  },
  {
    "uniprot": "A0A044UNA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNA3"
  },
  {
    "uniprot": "A0A044S590",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S590"
  },
  {
    "uniprot": "A0A044QXX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXX1"
  },
  {
    "uniprot": "A0A044TMY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMY2"
  },
  {
    "uniprot": "A0A044V499",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V499"
  },
  {
    "uniprot": "A0A2K6VRY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRY2"
  },
  {
    "uniprot": "A0A044TUZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUZ9"
  },
  {
    "uniprot": "A0A044VBH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBH5"
  },
  {
    "uniprot": "A0A044V985",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V985"
  },
  {
    "uniprot": "A0A044V9U6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9U6"
  },
  {
    "uniprot": "A0A2K6VMX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VMX8"
  },
  {
    "uniprot": "A0A2K6VFT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFT8"
  },
  {
    "uniprot": "A0A044RFJ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFJ7"
  },
  {
    "uniprot": "A0A044US57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044US57"
  },
  {
    "uniprot": "A0A2K6VK68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VK68"
  },
  {
    "uniprot": "A0A044T3N3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3N3"
  },
  {
    "uniprot": "A0A044T8L7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8L7"
  },
  {
    "uniprot": "A0A044SA61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SA61"
  },
  {
    "uniprot": "A0A044RUI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUI6"
  },
  {
    "uniprot": "A0A044R009",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R009"
  },
  {
    "uniprot": "A0A044V9L5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9L5"
  },
  {
    "uniprot": "A0A044RHG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHG5"
  },
  {
    "uniprot": "A0A044T8Q4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8Q4"
  },
  {
    "uniprot": "A0A044TJC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJC8"
  },
  {
    "uniprot": "A0A044UI84",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UI84"
  },
  {
    "uniprot": "A0A044UB00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UB00"
  },
  {
    "uniprot": "A0A044T802",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T802"
  },
  {
    "uniprot": "A0A2K6WJL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJL5"
  },
  {
    "uniprot": "A0A044V9U7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9U7"
  },
  {
    "uniprot": "A0A044SQ08",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ08"
  },
  {
    "uniprot": "A0A044SI86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI86"
  },
  {
    "uniprot": "A0A044R030",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R030"
  },
  {
    "uniprot": "A0A044S823",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S823"
  },
  {
    "uniprot": "A0A044S7G0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7G0"
  },
  {
    "uniprot": "A0A044SI27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI27"
  },
  {
    "uniprot": "A0A044VC22",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC22"
  },
  {
    "uniprot": "A0A044VCM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCM8"
  },
  {
    "uniprot": "A0A044SBR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SBR8"
  },
  {
    "uniprot": "A0A044T7E8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7E8"
  },
  {
    "uniprot": "A0A2K6WK43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WK43"
  },
  {
    "uniprot": "A0A044TSP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSP9"
  },
  {
    "uniprot": "A0A044V9M8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9M8"
  },
  {
    "uniprot": "A0A2K6VD69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VD69"
  },
  {
    "uniprot": "A0A044QPC2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPC2"
  },
  {
    "uniprot": "A0A044SRN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRN0"
  },
  {
    "uniprot": "A0A044V9H8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9H8"
  },
  {
    "uniprot": "A0A044V161",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V161"
  },
  {
    "uniprot": "A0A044UHD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHD1"
  },
  {
    "uniprot": "A0A2K6W8P0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8P0"
  },
  {
    "uniprot": "A0A044RJW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJW4"
  },
  {
    "uniprot": "A0A044TK46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TK46"
  },
  {
    "uniprot": "A0A044UF33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UF33"
  },
  {
    "uniprot": "A0A044V8Y8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8Y8"
  },
  {
    "uniprot": "A0A044QTW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QTW7"
  },
  {
    "uniprot": "A0A044T8Z5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8Z5"
  },
  {
    "uniprot": "A0A158N8C6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8C6"
  },
  {
    "uniprot": "A0A044SDC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDC6"
  },
  {
    "uniprot": "A0A044TST9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TST9"
  },
  {
    "uniprot": "A0A044SXN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXN1"
  },
  {
    "uniprot": "A0A044V5F3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5F3"
  },
  {
    "uniprot": "A0A044U7L1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7L1"
  },
  {
    "uniprot": "A0A044U9G9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9G9"
  },
  {
    "uniprot": "A0A044UEU0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEU0"
  },
  {
    "uniprot": "A0A044QZ23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ23"
  },
  {
    "uniprot": "A0A044TB87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TB87"
  },
  {
    "uniprot": "A0A044SME7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SME7"
  },
  {
    "uniprot": "A0A044RZ79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZ79"
  },
  {
    "uniprot": "A0A2K6W6P0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W6P0"
  },
  {
    "uniprot": "A0A044V9P9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9P9"
  },
  {
    "uniprot": "A0A044U7B5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7B5"
  },
  {
    "uniprot": "A0A044QXG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXG5"
  },
  {
    "uniprot": "A0A044T7I6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7I6"
  },
  {
    "uniprot": "A0A044S5D2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5D2"
  },
  {
    "uniprot": "A0A044U8J8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8J8"
  },
  {
    "uniprot": "A0A044RT01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RT01"
  },
  {
    "uniprot": "A0A2K6VED3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VED3"
  },
  {
    "uniprot": "A0A044U9P5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9P5"
  },
  {
    "uniprot": "A0A158N8F0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8F0"
  },
  {
    "uniprot": "A0A044SZG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZG4"
  },
  {
    "uniprot": "A0A044RKM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKM0"
  },
  {
    "uniprot": "A0A044V9C3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9C3"
  },
  {
    "uniprot": "A0A044V1B8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1B8"
  },
  {
    "uniprot": "A0A044UCB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCB7"
  },
  {
    "uniprot": "A0A044SYG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYG8"
  },
  {
    "uniprot": "A0A044V416",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V416"
  },
  {
    "uniprot": "A0A044TJZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJZ6"
  },
  {
    "uniprot": "A0A044UUX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUX2"
  },
  {
    "uniprot": "A0A044RIM5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIM5"
  },
  {
    "uniprot": "A0A2K6VH17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VH17"
  },
  {
    "uniprot": "A0A2K6W5C8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W5C8"
  },
  {
    "uniprot": "A0A044V7C9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7C9"
  },
  {
    "uniprot": "A0A044U4M9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4M9"
  },
  {
    "uniprot": "A0A044R0V5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0V5"
  },
  {
    "uniprot": "A0A044V2A5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2A5"
  },
  {
    "uniprot": "A0A044TR73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR73"
  },
  {
    "uniprot": "A0A044RET2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RET2"
  },
  {
    "uniprot": "A0A044TC65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC65"
  },
  {
    "uniprot": "A0A044UCS7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCS7"
  },
  {
    "uniprot": "A0A044QQW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQW6"
  },
  {
    "uniprot": "A0A2K6VYV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYV5"
  },
  {
    "uniprot": "A0A044R9Z6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9Z6"
  },
  {
    "uniprot": "A0A044V873",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V873"
  },
  {
    "uniprot": "A0A044U2N3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2N3"
  },
  {
    "uniprot": "A0A2K6VWQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWQ5"
  },
  {
    "uniprot": "A0A044U4W9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4W9"
  },
  {
    "uniprot": "A0A044SHU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SHU4"
  },
  {
    "uniprot": "A0A2K6VEC1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VEC1"
  },
  {
    "uniprot": "A0A044SJR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJR8"
  },
  {
    "uniprot": "P52816",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/P52816"
  },
  {
    "uniprot": "A0A044STA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STA1"
  },
  {
    "uniprot": "A0A2K6VYK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYK8"
  },
  {
    "uniprot": "A0A044SM55",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM55"
  },
  {
    "uniprot": "A0A044RE09",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RE09"
  },
  {
    "uniprot": "A0A044RJQ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJQ3"
  },
  {
    "uniprot": "A0A044V7G0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7G0"
  },
  {
    "uniprot": "A0A044URU1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URU1"
  },
  {
    "uniprot": "A0A044RU26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU26"
  },
  {
    "uniprot": "A0A044SFG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFG0"
  },
  {
    "uniprot": "A0A044V0A3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0A3"
  },
  {
    "uniprot": "A0A044VCB0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VCB0"
  },
  {
    "uniprot": "A0A044TUI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUI3"
  },
  {
    "uniprot": "A0A044UDF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDF2"
  },
  {
    "uniprot": "A0A044RSZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RSZ6"
  },
  {
    "uniprot": "A0A044SIR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIR2"
  },
  {
    "uniprot": "A0A044TL59",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TL59"
  },
  {
    "uniprot": "A0A044TY14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TY14"
  },
  {
    "uniprot": "A0A044RF70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RF70"
  },
  {
    "uniprot": "A0A044V8M4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8M4"
  },
  {
    "uniprot": "A0A044TIW1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIW1"
  },
  {
    "uniprot": "A0A044TKY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKY5"
  },
  {
    "uniprot": "A0A044R196",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R196"
  },
  {
    "uniprot": "A0A044T0I6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0I6"
  },
  {
    "uniprot": "A0A044V7B4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V7B4"
  },
  {
    "uniprot": "A0A044RI49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RI49"
  },
  {
    "uniprot": "A0A044U617",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U617"
  },
  {
    "uniprot": "A0A044UQ88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQ88"
  },
  {
    "uniprot": "A0A044TPE3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPE3"
  },
  {
    "uniprot": "A0A044QU12",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QU12"
  },
  {
    "uniprot": "A0A044RMH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMH6"
  },
  {
    "uniprot": "A0A044R564",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R564"
  },
  {
    "uniprot": "A0A044RPG3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPG3"
  },
  {
    "uniprot": "A0A044U2X9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2X9"
  },
  {
    "uniprot": "A0A044S5R0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5R0"
  },
  {
    "uniprot": "A0A044RDB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDB5"
  },
  {
    "uniprot": "A0A044VEH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEH5"
  },
  {
    "uniprot": "A0A044TB05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TB05"
  },
  {
    "uniprot": "A0A044TXF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXF6"
  },
  {
    "uniprot": "A0A044UCD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCD6"
  },
  {
    "uniprot": "A0A044UMA7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMA7"
  },
  {
    "uniprot": "A0A044TPR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPR4"
  },
  {
    "uniprot": "A0A044UUV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUV1"
  },
  {
    "uniprot": "A0A044SLP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLP0"
  },
  {
    "uniprot": "A0A044TUD1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TUD1"
  },
  {
    "uniprot": "A0A044S6M0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6M0"
  },
  {
    "uniprot": "A0A044TLX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLX5"
  },
  {
    "uniprot": "A0A044TS35",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TS35"
  },
  {
    "uniprot": "A0A2K6VRP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRP4"
  },
  {
    "uniprot": "A0A044VFQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFQ7"
  },
  {
    "uniprot": "A0A044TFQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFQ0"
  },
  {
    "uniprot": "A0A044QJY4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJY4"
  },
  {
    "uniprot": "A0A044RJ80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJ80"
  },
  {
    "uniprot": "A0A044QQK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQK6"
  },
  {
    "uniprot": "A0A044SWN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWN1"
  },
  {
    "uniprot": "A0A044TL98",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TL98"
  },
  {
    "uniprot": "A0A044TIH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIH4"
  },
  {
    "uniprot": "A0A2K6VS02",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VS02"
  },
  {
    "uniprot": "A0A044V4E3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4E3"
  },
  {
    "uniprot": "A0A044TCI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCI1"
  },
  {
    "uniprot": "A0A044TEP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEP4"
  },
  {
    "uniprot": "A0A2K6VU46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VU46"
  },
  {
    "uniprot": "A0A044USR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USR4"
  },
  {
    "uniprot": "A0A044UTN8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UTN8"
  },
  {
    "uniprot": "A0A044TI74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TI74"
  },
  {
    "uniprot": "A0A044T6N1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6N1"
  },
  {
    "uniprot": "A0A2K6VJN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJN9"
  },
  {
    "uniprot": "A0A044TV73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV73"
  },
  {
    "uniprot": "A0A044TGM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGM0"
  },
  {
    "uniprot": "A0A044SUZ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUZ1"
  },
  {
    "uniprot": "A0A044TDI0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDI0"
  },
  {
    "uniprot": "A0A044SC60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SC60"
  },
  {
    "uniprot": "A0A044ULM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULM8"
  },
  {
    "uniprot": "A0A044UE74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE74"
  },
  {
    "uniprot": "A0A044TGH4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGH4"
  },
  {
    "uniprot": "A0A044UL85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL85"
  },
  {
    "uniprot": "A0A044V9G8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9G8"
  },
  {
    "uniprot": "A0A044U4T1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4T1"
  },
  {
    "uniprot": "A0A044VBR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBR2"
  },
  {
    "uniprot": "A0A044R7Z1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7Z1"
  },
  {
    "uniprot": "A0A044SR20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SR20"
  },
  {
    "uniprot": "A0A044RZZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZZ9"
  },
  {
    "uniprot": "A0A044TZV2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZV2"
  },
  {
    "uniprot": "A0A044SY00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY00"
  },
  {
    "uniprot": "A0A044VA74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VA74"
  },
  {
    "uniprot": "A0A044R340",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R340"
  },
  {
    "uniprot": "A0A044RHI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHI4"
  },
  {
    "uniprot": "A0A044V9M2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9M2"
  },
  {
    "uniprot": "A0A044TD54",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TD54"
  },
  {
    "uniprot": "A0A044TEI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEI6"
  },
  {
    "uniprot": "A0A044SN01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN01"
  },
  {
    "uniprot": "A0A044TAF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAF1"
  },
  {
    "uniprot": "A0A044TDG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TDG2"
  },
  {
    "uniprot": "A0A044SF33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SF33"
  },
  {
    "uniprot": "A0A2K6VJX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VJX8"
  },
  {
    "uniprot": "A0A044S8T8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8T8"
  },
  {
    "uniprot": "A0A044STT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STT8"
  },
  {
    "uniprot": "A0A044QJM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJM6"
  },
  {
    "uniprot": "A0A044TLR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLR8"
  },
  {
    "uniprot": "A0A044R0Z5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0Z5"
  },
  {
    "uniprot": "A0A044UQ11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQ11"
  },
  {
    "uniprot": "A0A044STN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STN0"
  },
  {
    "uniprot": "A0A044SGG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGG7"
  },
  {
    "uniprot": "A0A044RJR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJR3"
  },
  {
    "uniprot": "A0A044RQ65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQ65"
  },
  {
    "uniprot": "A0A044UU66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UU66"
  },
  {
    "uniprot": "A0A044V0S5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0S5"
  },
  {
    "uniprot": "A0A044R0S9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0S9"
  },
  {
    "uniprot": "A0A044SYV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYV3"
  },
  {
    "uniprot": "A0A044SCP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCP2"
  },
  {
    "uniprot": "A0A044V1L4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1L4"
  },
  {
    "uniprot": "A0A2K6VWE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VWE9"
  },
  {
    "uniprot": "A0A044S2V0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2V0"
  },
  {
    "uniprot": "A0A2K6VS81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VS81"
  },
  {
    "uniprot": "A0A2K6VS73",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VS73"
  },
  {
    "uniprot": "A0A044UNY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNY2"
  },
  {
    "uniprot": "A0A044QUK6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUK6"
  },
  {
    "uniprot": "A0A044TFA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TFA0"
  },
  {
    "uniprot": "A0A044QRL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QRL3"
  },
  {
    "uniprot": "A0A044RTT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTT2"
  },
  {
    "uniprot": "A0A044VBG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBG5"
  },
  {
    "uniprot": "A0A044R3Q8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3Q8"
  },
  {
    "uniprot": "A0A044V5R6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V5R6"
  },
  {
    "uniprot": "A0A044RBS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBS4"
  },
  {
    "uniprot": "A0A044REG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044REG0"
  },
  {
    "uniprot": "A0A044TNA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNA4"
  },
  {
    "uniprot": "A0A044RQQ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQQ0"
  },
  {
    "uniprot": "A0A044QXL2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXL2"
  },
  {
    "uniprot": "A0A044T6G0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6G0"
  },
  {
    "uniprot": "A0A044T4Z0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4Z0"
  },
  {
    "uniprot": "A0A044T206",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T206"
  },
  {
    "uniprot": "A0A044RNU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNU6"
  },
  {
    "uniprot": "A0A044TPG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPG4"
  },
  {
    "uniprot": "A0A044SIX0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIX0"
  },
  {
    "uniprot": "A0A044RTN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTN3"
  },
  {
    "uniprot": "A0A044TWX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWX8"
  },
  {
    "uniprot": "A0A044RXY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXY6"
  },
  {
    "uniprot": "A0A044TMF3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMF3"
  },
  {
    "uniprot": "A0A044TLH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLH7"
  },
  {
    "uniprot": "A0A044QPR5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPR5"
  },
  {
    "uniprot": "A0A044SD07",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SD07"
  },
  {
    "uniprot": "A0A044RZI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZI1"
  },
  {
    "uniprot": "A0A044VH62",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH62"
  },
  {
    "uniprot": "A0A044QKL3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKL3"
  },
  {
    "uniprot": "A0A044SVT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVT2"
  },
  {
    "uniprot": "A0A044RT47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RT47"
  },
  {
    "uniprot": "A0A044SWT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWT5"
  },
  {
    "uniprot": "A0A044R9A4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9A4"
  },
  {
    "uniprot": "A0A044R611",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R611"
  },
  {
    "uniprot": "A0A2K6WM30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WM30"
  },
  {
    "uniprot": "A0A044U015",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U015"
  },
  {
    "uniprot": "A0A044S1E3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1E3"
  },
  {
    "uniprot": "A0A044UHZ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHZ9"
  },
  {
    "uniprot": "A0A044VH14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH14"
  },
  {
    "uniprot": "A0A044S9C7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9C7"
  },
  {
    "uniprot": "A0A2K6WM36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WM36"
  },
  {
    "uniprot": "A0A044TTW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTW4"
  },
  {
    "uniprot": "A0A2K6W8L1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8L1"
  },
  {
    "uniprot": "A0A044V850",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V850"
  },
  {
    "uniprot": "A0A044SM79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SM79"
  },
  {
    "uniprot": "A0A044RB19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RB19"
  },
  {
    "uniprot": "A0A044TEF7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TEF7"
  },
  {
    "uniprot": "A0A044SS72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS72"
  },
  {
    "uniprot": "A0A2K6VVF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVF0"
  },
  {
    "uniprot": "A0A044RZY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZY3"
  },
  {
    "uniprot": "A0A044QW42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QW42"
  },
  {
    "uniprot": "A0A044TRC9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRC9"
  },
  {
    "uniprot": "A0A044T1R6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1R6"
  },
  {
    "uniprot": "A0A044QSQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSQ5"
  },
  {
    "uniprot": "A0A044TR41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR41"
  },
  {
    "uniprot": "A0A044UYM1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYM1"
  },
  {
    "uniprot": "A0A044RDM9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDM9"
  },
  {
    "uniprot": "A0A044QNT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNT8"
  },
  {
    "uniprot": "A0A044V939",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V939"
  },
  {
    "uniprot": "A0A2K6VSK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VSK9"
  },
  {
    "uniprot": "A0A044TNI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNI9"
  },
  {
    "uniprot": "A0A044QP71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP71"
  },
  {
    "uniprot": "A0A044SB25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB25"
  },
  {
    "uniprot": "A0A044UEK3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEK3"
  },
  {
    "uniprot": "A0A044UMR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMR0"
  },
  {
    "uniprot": "A0A2K6WJS8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJS8"
  },
  {
    "uniprot": "A0A044U4J0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4J0"
  },
  {
    "uniprot": "A0A044SK78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK78"
  },
  {
    "uniprot": "A0A044V3L6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3L6"
  },
  {
    "uniprot": "A0A044TIN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIN9"
  },
  {
    "uniprot": "A0A044RYM9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYM9"
  },
  {
    "uniprot": "A0A044ULX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULX6"
  },
  {
    "uniprot": "A0A044UYY6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYY6"
  },
  {
    "uniprot": "A0A044RY46",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY46"
  },
  {
    "uniprot": "A0A044QZH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZH7"
  },
  {
    "uniprot": "A0A2K6W8N6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8N6"
  },
  {
    "uniprot": "A0A044QL49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QL49"
  },
  {
    "uniprot": "A0A044TG01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TG01"
  },
  {
    "uniprot": "A0A044QK58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QK58"
  },
  {
    "uniprot": "A0A044RZT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZT5"
  },
  {
    "uniprot": "A0A044SS87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS87"
  },
  {
    "uniprot": "A0A044RC85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC85"
  },
  {
    "uniprot": "A0A044RHJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHJ3"
  },
  {
    "uniprot": "A0A044SPG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPG2"
  },
  {
    "uniprot": "A0A044SMI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMI8"
  },
  {
    "uniprot": "A0A044RG27",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RG27"
  },
  {
    "uniprot": "A0A044RYP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYP1"
  },
  {
    "uniprot": "A0A044TR79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR79"
  },
  {
    "uniprot": "A0A044S720",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S720"
  },
  {
    "uniprot": "A0A044SPS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPS5"
  },
  {
    "uniprot": "A0A044V2W9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V2W9"
  },
  {
    "uniprot": "A0A044R6I2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6I2"
  },
  {
    "uniprot": "A0A044VDI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDI3"
  },
  {
    "uniprot": "A0A044TX23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TX23"
  },
  {
    "uniprot": "A0A044SDP3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDP3"
  },
  {
    "uniprot": "A0A044RU70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU70"
  },
  {
    "uniprot": "A0A044UPZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPZ7"
  },
  {
    "uniprot": "A0A044VJN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VJN4"
  },
  {
    "uniprot": "A0A044UKT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKT9"
  },
  {
    "uniprot": "A0A044T4V7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4V7"
  },
  {
    "uniprot": "A0A044TCT8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCT8"
  },
  {
    "uniprot": "A0A2K6VRB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRB3"
  },
  {
    "uniprot": "A0A044RB30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RB30"
  },
  {
    "uniprot": "A0A044UHW7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHW7"
  },
  {
    "uniprot": "A0A044S8V0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8V0"
  },
  {
    "uniprot": "A0A044SIC5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIC5"
  },
  {
    "uniprot": "A0A044SGH9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGH9"
  },
  {
    "uniprot": "A0A044RGC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGC6"
  },
  {
    "uniprot": "A0A044RYG2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYG2"
  },
  {
    "uniprot": "A0A044RIY1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIY1"
  },
  {
    "uniprot": "A0A2K6WEZ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEZ6"
  },
  {
    "uniprot": "A0A044TP10",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TP10"
  },
  {
    "uniprot": "A0A044VFF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFF8"
  },
  {
    "uniprot": "A0A044SAB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAB6"
  },
  {
    "uniprot": "A0A044VIN6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIN6"
  },
  {
    "uniprot": "A0A2K6WBX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WBX3"
  },
  {
    "uniprot": "A0A044RVG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVG8"
  },
  {
    "uniprot": "A0A044TYG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYG7"
  },
  {
    "uniprot": "A0A044V456",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V456"
  },
  {
    "uniprot": "A0A044ULA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULA6"
  },
  {
    "uniprot": "A0A044RV57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RV57"
  },
  {
    "uniprot": "A0A2K6WG33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WG33"
  },
  {
    "uniprot": "A0A2K6W568",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W568"
  },
  {
    "uniprot": "A0A044VFW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFW0"
  },
  {
    "uniprot": "A0A044TT43",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TT43"
  },
  {
    "uniprot": "A0A044QP33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP33"
  },
  {
    "uniprot": "A0A044TGD0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGD0"
  },
  {
    "uniprot": "A0A044UBG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBG0"
  },
  {
    "uniprot": "A0A044TR25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR25"
  },
  {
    "uniprot": "A0A044R549",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R549"
  },
  {
    "uniprot": "A0A044TCC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCC8"
  },
  {
    "uniprot": "A0A044S9U2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9U2"
  },
  {
    "uniprot": "A0A044QYG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QYG1"
  },
  {
    "uniprot": "A0A044SPF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPF1"
  },
  {
    "uniprot": "A0A044RDA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDA1"
  },
  {
    "uniprot": "A0A044RQ35",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQ35"
  },
  {
    "uniprot": "A0A044U147",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U147"
  },
  {
    "uniprot": "A0A044SK69",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK69"
  },
  {
    "uniprot": "A0A044T2S1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2S1"
  },
  {
    "uniprot": "A0A044T522",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T522"
  },
  {
    "uniprot": "A0A044SF66",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SF66"
  },
  {
    "uniprot": "A0A044TBX5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBX5"
  },
  {
    "uniprot": "A0A044QN50",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QN50"
  },
  {
    "uniprot": "A0A2K6WLV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLV8"
  },
  {
    "uniprot": "A0A044RQP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQP6"
  },
  {
    "uniprot": "A0A044RWS8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RWS8"
  },
  {
    "uniprot": "A0A044ULA1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044ULA1"
  },
  {
    "uniprot": "A0A044UKB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UKB9"
  },
  {
    "uniprot": "A0A2K6WMG1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMG1"
  },
  {
    "uniprot": "A0A044SEK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEK1"
  },
  {
    "uniprot": "A0A2K6VDN4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDN4"
  },
  {
    "uniprot": "A0A2K6W1D4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1D4"
  },
  {
    "uniprot": "A0A044TXC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXC4"
  },
  {
    "uniprot": "A0A044RXI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RXI9"
  },
  {
    "uniprot": "A0A044RGT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGT4"
  },
  {
    "uniprot": "A0A044TPX2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPX2"
  },
  {
    "uniprot": "A0A044SK99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK99"
  },
  {
    "uniprot": "A0A044R6A8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6A8"
  },
  {
    "uniprot": "A0A044TXT6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXT6"
  },
  {
    "uniprot": "A0A044UBZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBZ7"
  },
  {
    "uniprot": "A0A044T5S1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5S1"
  },
  {
    "uniprot": "A0A044VEA0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEA0"
  },
  {
    "uniprot": "A0A044UA71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UA71"
  },
  {
    "uniprot": "A0A044T571",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T571"
  },
  {
    "uniprot": "A0A044V9J9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9J9"
  },
  {
    "uniprot": "A0A044R3V3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R3V3"
  },
  {
    "uniprot": "A0A044TYC6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYC6"
  },
  {
    "uniprot": "A0A044U7J4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7J4"
  },
  {
    "uniprot": "A0A044U5N7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5N7"
  },
  {
    "uniprot": "A0A044THJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THJ3"
  },
  {
    "uniprot": "A0A044SN05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN05"
  },
  {
    "uniprot": "A0A044T7T4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T7T4"
  },
  {
    "uniprot": "A0A044R5W9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5W9"
  },
  {
    "uniprot": "A0A044QPX0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPX0"
  },
  {
    "uniprot": "A0A044SGF4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGF4"
  },
  {
    "uniprot": "A0A044UZ45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ45"
  },
  {
    "uniprot": "A0A044QJL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJL8"
  },
  {
    "uniprot": "A0A044TV36",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TV36"
  },
  {
    "uniprot": "A0A044U9R4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9R4"
  },
  {
    "uniprot": "A0A044SKD4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKD4"
  },
  {
    "uniprot": "A0A044SRI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRI9"
  },
  {
    "uniprot": "A0A044R7S2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7S2"
  },
  {
    "uniprot": "A0A044UDU6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDU6"
  },
  {
    "uniprot": "A0A2K6W7K1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W7K1"
  },
  {
    "uniprot": "A0A044V8X4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8X4"
  },
  {
    "uniprot": "A0A044RKP8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKP8"
  },
  {
    "uniprot": "A0A044QP23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QP23"
  },
  {
    "uniprot": "A0A044TSD8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSD8"
  },
  {
    "uniprot": "A0A2K6WGH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WGH0"
  },
  {
    "uniprot": "A0A158N7Q7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7Q7"
  },
  {
    "uniprot": "A0A044RTH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTH8"
  },
  {
    "uniprot": "A0A044V9R8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9R8"
  },
  {
    "uniprot": "A0A044SC45",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SC45"
  },
  {
    "uniprot": "A0A044V682",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V682"
  },
  {
    "uniprot": "A0A044TTH8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTH8"
  },
  {
    "uniprot": "A0A044U354",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U354"
  },
  {
    "uniprot": "A0A044SPA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPA9"
  },
  {
    "uniprot": "A0A044TZR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZR0"
  },
  {
    "uniprot": "A0A044VBK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBK4"
  },
  {
    "uniprot": "A0A044QT89",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT89"
  },
  {
    "uniprot": "A0A044SYF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYF5"
  },
  {
    "uniprot": "A0A044TW91",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TW91"
  },
  {
    "uniprot": "A0A2K6WK68",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WK68"
  },
  {
    "uniprot": "A0A044RDI2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDI2"
  },
  {
    "uniprot": "A0A044TZJ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZJ2"
  },
  {
    "uniprot": "A0A044RME6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RME6"
  },
  {
    "uniprot": "A0A044SC47",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SC47"
  },
  {
    "uniprot": "A0A044T9R3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9R3"
  },
  {
    "uniprot": "A0A044VAW6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAW6"
  },
  {
    "uniprot": "A0A044VC61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC61"
  },
  {
    "uniprot": "A0A044U742",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U742"
  },
  {
    "uniprot": "A0A044TIN3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIN3"
  },
  {
    "uniprot": "A0A044V6Y0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6Y0"
  },
  {
    "uniprot": "A0A044U415",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U415"
  },
  {
    "uniprot": "A0A044RH44",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RH44"
  },
  {
    "uniprot": "A0A044RA34",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RA34"
  },
  {
    "uniprot": "A0A2K6VXP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXP6"
  },
  {
    "uniprot": "A0A044QQH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQH5"
  },
  {
    "uniprot": "A0A044R097",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R097"
  },
  {
    "uniprot": "A0A044RKT5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKT5"
  },
  {
    "uniprot": "A0A044R5W3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5W3"
  },
  {
    "uniprot": "A0A044UFB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFB5"
  },
  {
    "uniprot": "A0A044R725",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R725"
  },
  {
    "uniprot": "A0A044RHC8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHC8"
  },
  {
    "uniprot": "A0A044UAQ2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAQ2"
  },
  {
    "uniprot": "A0A044V437",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V437"
  },
  {
    "uniprot": "A0A044VBS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBS0"
  },
  {
    "uniprot": "A0A044R788",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R788"
  },
  {
    "uniprot": "A0A044U0C3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0C3"
  },
  {
    "uniprot": "A0A044V918",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V918"
  },
  {
    "uniprot": "A0A044QXF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXF2"
  },
  {
    "uniprot": "A0A044TW32",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TW32"
  },
  {
    "uniprot": "A0A044V9A7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9A7"
  },
  {
    "uniprot": "A0A044S3X3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S3X3"
  },
  {
    "uniprot": "A0A044VG28",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VG28"
  },
  {
    "uniprot": "A0A044UXF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UXF8"
  },
  {
    "uniprot": "A0A044V4F6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4F6"
  },
  {
    "uniprot": "A0A044R8N1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8N1"
  },
  {
    "uniprot": "A0A044SNF6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNF6"
  },
  {
    "uniprot": "A0A044USP6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044USP6"
  },
  {
    "uniprot": "A0A044SKI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SKI9"
  },
  {
    "uniprot": "A0A044S8B3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8B3"
  },
  {
    "uniprot": "A0A044SK64",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SK64"
  },
  {
    "uniprot": "A0A044UM88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UM88"
  },
  {
    "uniprot": "A0A044RCZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCZ0"
  },
  {
    "uniprot": "A0A044U4P1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4P1"
  },
  {
    "uniprot": "A0A2K6VRN0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VRN0"
  },
  {
    "uniprot": "A0A044QT95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT95"
  },
  {
    "uniprot": "A0A044S2P7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2P7"
  },
  {
    "uniprot": "A0A044VD72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD72"
  },
  {
    "uniprot": "A0A2K6WDX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WDX1"
  },
  {
    "uniprot": "A0A2K6VW31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VW31"
  },
  {
    "uniprot": "A0A044RKA6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKA6"
  },
  {
    "uniprot": "A0A044RNC7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RNC7"
  },
  {
    "uniprot": "A0A044TU72",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TU72"
  },
  {
    "uniprot": "A0A044SYS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SYS4"
  },
  {
    "uniprot": "A0A044UDW8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDW8"
  },
  {
    "uniprot": "A0A044R598",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R598"
  },
  {
    "uniprot": "A0A044U7Y4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U7Y4"
  },
  {
    "uniprot": "A0A158N876",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N876"
  },
  {
    "uniprot": "A0A044U986",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U986"
  },
  {
    "uniprot": "A0A044U0Y5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U0Y5"
  },
  {
    "uniprot": "A0A044RKB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RKB3"
  },
  {
    "uniprot": "A0A044UFQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFQ5"
  },
  {
    "uniprot": "A0A044SPE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPE7"
  },
  {
    "uniprot": "A0A044TR00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TR00"
  },
  {
    "uniprot": "A0A158N856",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N856"
  },
  {
    "uniprot": "A0A044UU11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UU11"
  },
  {
    "uniprot": "A0A044SI56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI56"
  },
  {
    "uniprot": "A0A044QVZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVZ0"
  },
  {
    "uniprot": "A0A044T134",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T134"
  },
  {
    "uniprot": "A0A044R7M6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R7M6"
  },
  {
    "uniprot": "A0A044RBB5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBB5"
  },
  {
    "uniprot": "A0A044QZA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZA3"
  },
  {
    "uniprot": "A0A044VDM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDM0"
  },
  {
    "uniprot": "A0A044U6F5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6F5"
  },
  {
    "uniprot": "A0A044R0J8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0J8"
  },
  {
    "uniprot": "A0A044UP04",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UP04"
  },
  {
    "uniprot": "A0A044UBV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBV8"
  },
  {
    "uniprot": "A0A044V1Z9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1Z9"
  },
  {
    "uniprot": "A0A044S284",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S284"
  },
  {
    "uniprot": "A0A044U725",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U725"
  },
  {
    "uniprot": "A0A044QT40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QT40"
  },
  {
    "uniprot": "A0A044QJP7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QJP7"
  },
  {
    "uniprot": "A0A158N872",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N872"
  },
  {
    "uniprot": "A0A044V960",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V960"
  },
  {
    "uniprot": "A0A044RFT9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFT9"
  },
  {
    "uniprot": "A0A044TBU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TBU5"
  },
  {
    "uniprot": "A0A044S074",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S074"
  },
  {
    "uniprot": "A0A044RLS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLS4"
  },
  {
    "uniprot": "A0A044S2B5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2B5"
  },
  {
    "uniprot": "A0A044VFV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFV5"
  },
  {
    "uniprot": "A0A044R966",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R966"
  },
  {
    "uniprot": "A0A044TQP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQP4"
  },
  {
    "uniprot": "A0A044QPB4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QPB4"
  },
  {
    "uniprot": "A0A044THT3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THT3"
  },
  {
    "uniprot": "A0A158N8N0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8N0"
  },
  {
    "uniprot": "A0A044SRP4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRP4"
  },
  {
    "uniprot": "A0A044UAB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAB3"
  },
  {
    "uniprot": "A0A044TN79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TN79"
  },
  {
    "uniprot": "A0A2K6VVH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VVH1"
  },
  {
    "uniprot": "A0A044RAM0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAM0"
  },
  {
    "uniprot": "A0A044S5X5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5X5"
  },
  {
    "uniprot": "A0A044SAK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SAK1"
  },
  {
    "uniprot": "A0A044S6H4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6H4"
  },
  {
    "uniprot": "A0A2K6WJ41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WJ41"
  },
  {
    "uniprot": "A0A044SEI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SEI9"
  },
  {
    "uniprot": "A0A044V8F9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8F9"
  },
  {
    "uniprot": "A0A044RMD2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMD2"
  },
  {
    "uniprot": "A0A044UZC7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZC7"
  },
  {
    "uniprot": "A0A044UFT2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UFT2"
  },
  {
    "uniprot": "A0A044R687",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R687"
  },
  {
    "uniprot": "A0A044QUJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUJ4"
  },
  {
    "uniprot": "A0A044QNV1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNV1"
  },
  {
    "uniprot": "A0A044R2Y2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2Y2"
  },
  {
    "uniprot": "A0A044VC86",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC86"
  },
  {
    "uniprot": "A0A044U3C7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3C7"
  },
  {
    "uniprot": "A0A044VHG4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHG4"
  },
  {
    "uniprot": "A0A044T229",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T229"
  },
  {
    "uniprot": "A0A044T6E3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6E3"
  },
  {
    "uniprot": "A0A044RJ19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RJ19"
  },
  {
    "uniprot": "A0A044TZN2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZN2"
  },
  {
    "uniprot": "A0A044TY33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TY33"
  },
  {
    "uniprot": "A0A044THN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THN7"
  },
  {
    "uniprot": "A0A044SZ41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ41"
  },
  {
    "uniprot": "A0A044S028",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S028"
  },
  {
    "uniprot": "A0A044UHN7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHN7"
  },
  {
    "uniprot": "A0A044RUG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUG6"
  },
  {
    "uniprot": "A0A044U9B3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U9B3"
  },
  {
    "uniprot": "A0A044SQW1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQW1"
  },
  {
    "uniprot": "A0A2K6VQE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQE0"
  },
  {
    "uniprot": "A0A044RVW1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RVW1"
  },
  {
    "uniprot": "A0A044RY11",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RY11"
  },
  {
    "uniprot": "A0A2K6WEK9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WEK9"
  },
  {
    "uniprot": "A0A044S8Q1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S8Q1"
  },
  {
    "uniprot": "A0A044RZ60",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZ60"
  },
  {
    "uniprot": "A0A044TX88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TX88"
  },
  {
    "uniprot": "A0A044U2C8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2C8"
  },
  {
    "uniprot": "A0A2K6WD31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WD31"
  },
  {
    "uniprot": "A0A2K6VGE2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VGE2"
  },
  {
    "uniprot": "A0A044QSN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSN9"
  },
  {
    "uniprot": "A0A044UUA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUA9"
  },
  {
    "uniprot": "A0A044RCI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RCI1"
  },
  {
    "uniprot": "A0A044RMD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RMD7"
  },
  {
    "uniprot": "A0A044RBS0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RBS0"
  },
  {
    "uniprot": "A0A044TCB2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCB2"
  },
  {
    "uniprot": "A0A044TYX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYX6"
  },
  {
    "uniprot": "A0A044U665",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U665"
  },
  {
    "uniprot": "A0A044QS56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QS56"
  },
  {
    "uniprot": "A0A044SLS5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLS5"
  },
  {
    "uniprot": "A0A044RUQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUQ4"
  },
  {
    "uniprot": "A0A044SVX1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVX1"
  },
  {
    "uniprot": "A0A158N899",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N899"
  },
  {
    "uniprot": "A0A044VC33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC33"
  },
  {
    "uniprot": "A0A044UIB1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIB1"
  },
  {
    "uniprot": "A0A044T1Y1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1Y1"
  },
  {
    "uniprot": "A0A044T9F2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9F2"
  },
  {
    "uniprot": "A0A044SZJ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZJ8"
  },
  {
    "uniprot": "A0A044SUR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SUR2"
  },
  {
    "uniprot": "A0A044UMG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMG8"
  },
  {
    "uniprot": "A0A044VBU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBU5"
  },
  {
    "uniprot": "A0A158N8E0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8E0"
  },
  {
    "uniprot": "A0A044QZ78",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZ78"
  },
  {
    "uniprot": "A0A044UGH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGH2"
  },
  {
    "uniprot": "A0A2K6WLI1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLI1"
  },
  {
    "uniprot": "A0A044VD25",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VD25"
  },
  {
    "uniprot": "A0A044V137",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V137"
  },
  {
    "uniprot": "A0A044U8B0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8B0"
  },
  {
    "uniprot": "A0A044UZ52",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ52"
  },
  {
    "uniprot": "A0A044QQ51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQ51"
  },
  {
    "uniprot": "A0A044TY57",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TY57"
  },
  {
    "uniprot": "A0A044UBT4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UBT4"
  },
  {
    "uniprot": "A0A044RYA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYA5"
  },
  {
    "uniprot": "A0A044SU70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SU70"
  },
  {
    "uniprot": "A0A044SQ87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQ87"
  },
  {
    "uniprot": "A0A044QKI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKI7"
  },
  {
    "uniprot": "A0A044VAW1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VAW1"
  },
  {
    "uniprot": "A0A2K6W3G9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W3G9"
  },
  {
    "uniprot": "A0A044RIV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIV3"
  },
  {
    "uniprot": "A0A158N878",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N878"
  },
  {
    "uniprot": "A0A044QXS6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXS6"
  },
  {
    "uniprot": "A0A044SIG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIG7"
  },
  {
    "uniprot": "A0A044RF99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RF99"
  },
  {
    "uniprot": "A0A044TYA5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYA5"
  },
  {
    "uniprot": "A0A044RQL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RQL5"
  },
  {
    "uniprot": "A0A044SZU3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZU3"
  },
  {
    "uniprot": "A0A044V8P8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V8P8"
  },
  {
    "uniprot": "A0A044TIF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIF2"
  },
  {
    "uniprot": "A0A044VBI9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBI9"
  },
  {
    "uniprot": "A0A044SW74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SW74"
  },
  {
    "uniprot": "A0A044T4T0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4T0"
  },
  {
    "uniprot": "A0A044S5D8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5D8"
  },
  {
    "uniprot": "A0A044TXX4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXX4"
  },
  {
    "uniprot": "A0A044TTI3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTI3"
  },
  {
    "uniprot": "A0A044V9J6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9J6"
  },
  {
    "uniprot": "A0A044V9X6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9X6"
  },
  {
    "uniprot": "A0A044TAJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TAJ4"
  },
  {
    "uniprot": "A0A2K6WMV3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WMV3"
  },
  {
    "uniprot": "A0A044QXF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXF8"
  },
  {
    "uniprot": "A0A044V9C8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9C8"
  },
  {
    "uniprot": "A0A044U1R1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1R1"
  },
  {
    "uniprot": "A0A044V113",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V113"
  },
  {
    "uniprot": "A0A044R407",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R407"
  },
  {
    "uniprot": "A0A2K6WNZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WNZ7"
  },
  {
    "uniprot": "A0A044SN74",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN74"
  },
  {
    "uniprot": "A0A044SG94",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG94"
  },
  {
    "uniprot": "A0A044SSQ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SSQ7"
  },
  {
    "uniprot": "A0A044QUE4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUE4"
  },
  {
    "uniprot": "A0A044STX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STX6"
  },
  {
    "uniprot": "A0A044UND4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UND4"
  },
  {
    "uniprot": "A0A2K6WB08",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WB08"
  },
  {
    "uniprot": "A0A044TC41",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TC41"
  },
  {
    "uniprot": "A0A044T6Q4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6Q4"
  },
  {
    "uniprot": "A0A044VH51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VH51"
  },
  {
    "uniprot": "A0A044UGG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGG8"
  },
  {
    "uniprot": "A0A044U5K2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5K2"
  },
  {
    "uniprot": "A0A044UAI4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UAI4"
  },
  {
    "uniprot": "A0A044TMW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TMW2"
  },
  {
    "uniprot": "A0A044RX23",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RX23"
  },
  {
    "uniprot": "A0A2K6W8W3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8W3"
  },
  {
    "uniprot": "A0A044V206",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V206"
  },
  {
    "uniprot": "A0A044SJH6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJH6"
  },
  {
    "uniprot": "A0A158N7W7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N7W7"
  },
  {
    "uniprot": "A0A044S2Q2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S2Q2"
  },
  {
    "uniprot": "A0A044T8K4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8K4"
  },
  {
    "uniprot": "A0A158N8L6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8L6"
  },
  {
    "uniprot": "A0A044TIX7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TIX7"
  },
  {
    "uniprot": "A0A044SXF1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXF1"
  },
  {
    "uniprot": "A0A044SFJ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SFJ3"
  },
  {
    "uniprot": "A0A044U8M5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8M5"
  },
  {
    "uniprot": "A0A044QLF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLF0"
  },
  {
    "uniprot": "A0A044TTN1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TTN1"
  },
  {
    "uniprot": "A0A044R6W7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R6W7"
  },
  {
    "uniprot": "A0A044TQR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TQR7"
  },
  {
    "uniprot": "A0A044U3V4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3V4"
  },
  {
    "uniprot": "A0A2K6W1X7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W1X7"
  },
  {
    "uniprot": "A0A044R465",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R465"
  },
  {
    "uniprot": "A0A044TJH0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJH0"
  },
  {
    "uniprot": "A0A044TRB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRB8"
  },
  {
    "uniprot": "A0A044T8T4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8T4"
  },
  {
    "uniprot": "A0A044TKS4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TKS4"
  },
  {
    "uniprot": "A0A044U790",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U790"
  },
  {
    "uniprot": "A0A044U4Y6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4Y6"
  },
  {
    "uniprot": "A0A044TVA8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVA8"
  },
  {
    "uniprot": "A0A044UWG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UWG8"
  },
  {
    "uniprot": "A0A2K6VQP2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VQP2"
  },
  {
    "uniprot": "A0A2K6VZQ5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZQ5"
  },
  {
    "uniprot": "A0A2K6W9K0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9K0"
  },
  {
    "uniprot": "A0A044RDX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RDX8"
  },
  {
    "uniprot": "A0A044UT67",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UT67"
  },
  {
    "uniprot": "A0A044T0H9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0H9"
  },
  {
    "uniprot": "A0A2K6VR95",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VR95"
  },
  {
    "uniprot": "A0A044SMF5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SMF5"
  },
  {
    "uniprot": "A0A044QZU4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZU4"
  },
  {
    "uniprot": "A0A044QY20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QY20"
  },
  {
    "uniprot": "A0A044U866",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U866"
  },
  {
    "uniprot": "A0A2K6VY26",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VY26"
  },
  {
    "uniprot": "A0A044UE92",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UE92"
  },
  {
    "uniprot": "A0A044UEK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UEK4"
  },
  {
    "uniprot": "A0A044SZ79",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SZ79"
  },
  {
    "uniprot": "A0A044TH14",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH14"
  },
  {
    "uniprot": "A0A044U1U3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1U3"
  },
  {
    "uniprot": "A0A044UHW0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UHW0"
  },
  {
    "uniprot": "A0A044SN20",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SN20"
  },
  {
    "uniprot": "A0A2K6W9G4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W9G4"
  },
  {
    "uniprot": "A0A044UUR3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUR3"
  },
  {
    "uniprot": "A0A044SWK1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWK1"
  },
  {
    "uniprot": "A0A044TNC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNC4"
  },
  {
    "uniprot": "A0A044RFN9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RFN9"
  },
  {
    "uniprot": "A0A044RB71",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RB71"
  },
  {
    "uniprot": "A0A044R003",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R003"
  },
  {
    "uniprot": "A0A044V4X5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4X5"
  },
  {
    "uniprot": "A0A044SI82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI82"
  },
  {
    "uniprot": "A0A044S1K6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1K6"
  },
  {
    "uniprot": "A0A044TSB8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSB8"
  },
  {
    "uniprot": "A0A044S6Y5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6Y5"
  },
  {
    "uniprot": "A0A044TVG5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TVG5"
  },
  {
    "uniprot": "A0A044QXT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXT7"
  },
  {
    "uniprot": "A0A044SI40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SI40"
  },
  {
    "uniprot": "A0A044R9H9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9H9"
  },
  {
    "uniprot": "A0A044UJK0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJK0"
  },
  {
    "uniprot": "A0A044UZ70",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ70"
  },
  {
    "uniprot": "A0A044SGH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGH2"
  },
  {
    "uniprot": "A0A044S7P7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7P7"
  },
  {
    "uniprot": "A0A044UQF2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQF2"
  },
  {
    "uniprot": "A0A044RU51",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RU51"
  },
  {
    "uniprot": "A0A044RN65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RN65"
  },
  {
    "uniprot": "A0A044UYH1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYH1"
  },
  {
    "uniprot": "A0A044T9T1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9T1"
  },
  {
    "uniprot": "A0A044V999",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V999"
  },
  {
    "uniprot": "A0A2K6VDQ6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VDQ6"
  },
  {
    "uniprot": "A0A044UQR0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQR0"
  },
  {
    "uniprot": "A0A044UJ42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJ42"
  },
  {
    "uniprot": "A0A044U1S0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1S0"
  },
  {
    "uniprot": "A0A044UCR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCR4"
  },
  {
    "uniprot": "A0A044U4S6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U4S6"
  },
  {
    "uniprot": "A0A044TPR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPR2"
  },
  {
    "uniprot": "A0A2K6WE65",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WE65"
  },
  {
    "uniprot": "A0A044T5R5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5R5"
  },
  {
    "uniprot": "A0A044TH15",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TH15"
  },
  {
    "uniprot": "A0A044R4Y7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4Y7"
  },
  {
    "uniprot": "A0A044S5Q0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5Q0"
  },
  {
    "uniprot": "A0A044RRX6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RRX6"
  },
  {
    "uniprot": "A0A044QUB6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QUB6"
  },
  {
    "uniprot": "A0A044QLR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QLR2"
  },
  {
    "uniprot": "A0A2K6WLC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WLC0"
  },
  {
    "uniprot": "A0A044UQD5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UQD5"
  },
  {
    "uniprot": "A0A044V1P1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V1P1"
  },
  {
    "uniprot": "A0A044RN19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RN19"
  },
  {
    "uniprot": "A0A2K6VLD6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VLD6"
  },
  {
    "uniprot": "A0A044TGY3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TGY3"
  },
  {
    "uniprot": "A0A044V9M6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V9M6"
  },
  {
    "uniprot": "A0A044UCK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UCK8"
  },
  {
    "uniprot": "A0A044RPZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPZ0"
  },
  {
    "uniprot": "A0A044UIE9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIE9"
  },
  {
    "uniprot": "A0A044V4A7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V4A7"
  },
  {
    "uniprot": "A0A2K6VK90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VK90"
  },
  {
    "uniprot": "A0A044R5Z6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5Z6"
  },
  {
    "uniprot": "A0A044RTZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTZ7"
  },
  {
    "uniprot": "A0A044SST5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SST5"
  },
  {
    "uniprot": "A0A2K6WKP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WKP1"
  },
  {
    "uniprot": "A0A044STY5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STY5"
  },
  {
    "uniprot": "A0A044UDT7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UDT7"
  },
  {
    "uniprot": "A0A044V6S9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6S9"
  },
  {
    "uniprot": "A0A044SY97",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SY97"
  },
  {
    "uniprot": "A0A2K6W0R1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W0R1"
  },
  {
    "uniprot": "A0A044UNR2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UNR2"
  },
  {
    "uniprot": "A0A044R5R5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R5R5"
  },
  {
    "uniprot": "A0A044V0M5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0M5"
  },
  {
    "uniprot": "A0A2K6VFP9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VFP9"
  },
  {
    "uniprot": "A0A2K6VM80",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VM80"
  },
  {
    "uniprot": "A0A044UZJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZJ1"
  },
  {
    "uniprot": "A0A044SB31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SB31"
  },
  {
    "uniprot": "A0A2K6W2F8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W2F8"
  },
  {
    "uniprot": "A0A044TRL5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TRL5"
  },
  {
    "uniprot": "A0A044S5G7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S5G7"
  },
  {
    "uniprot": "A0A044TYR4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYR4"
  },
  {
    "uniprot": "A0A044VEV0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VEV0"
  },
  {
    "uniprot": "A0A044UJP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJP1"
  },
  {
    "uniprot": "A0A044R2J7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2J7"
  },
  {
    "uniprot": "A0A044TCQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TCQ9"
  },
  {
    "uniprot": "A0A044S1R5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1R5"
  },
  {
    "uniprot": "A0A044TZH5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZH5"
  },
  {
    "uniprot": "A0A2K6WHF7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WHF7"
  },
  {
    "uniprot": "A0A044TPZ0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TPZ0"
  },
  {
    "uniprot": "A0A044UIK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UIK5"
  },
  {
    "uniprot": "A0A044U8Z4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8Z4"
  },
  {
    "uniprot": "A0A044RUD3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUD3"
  },
  {
    "uniprot": "A0A044UGR7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UGR7"
  },
  {
    "uniprot": "A0A044THP1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THP1"
  },
  {
    "uniprot": "A0A044SLK7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SLK7"
  },
  {
    "uniprot": "A0A044TXW4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TXW4"
  },
  {
    "uniprot": "A0A044U8V9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U8V9"
  },
  {
    "uniprot": "A0A044T831",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T831"
  },
  {
    "uniprot": "A0A044SDH2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDH2"
  },
  {
    "uniprot": "A0A044T0K3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0K3"
  },
  {
    "uniprot": "A0A044T586",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T586"
  },
  {
    "uniprot": "A0A044R506",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R506"
  },
  {
    "uniprot": "A0A044QZX3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZX3"
  },
  {
    "uniprot": "A0A044SW90",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SW90"
  },
  {
    "uniprot": "A0A044R4K8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R4K8"
  },
  {
    "uniprot": "A0A044T8I6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8I6"
  },
  {
    "uniprot": "A0A2K6W829",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W829"
  },
  {
    "uniprot": "A0A044UVJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UVJ4"
  },
  {
    "uniprot": "A0A044T9L7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T9L7"
  },
  {
    "uniprot": "A0A044QVC0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QVC0"
  },
  {
    "uniprot": "A0A044RTI5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RTI5"
  },
  {
    "uniprot": "A0A044TSJ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TSJ9"
  },
  {
    "uniprot": "A0A044RGI8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RGI8"
  },
  {
    "uniprot": "A0A044T2Z5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T2Z5"
  },
  {
    "uniprot": "A0A044UYY7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYY7"
  },
  {
    "uniprot": "A0A044R503",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R503"
  },
  {
    "uniprot": "A0A044U124",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U124"
  },
  {
    "uniprot": "A0A044T0M0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T0M0"
  },
  {
    "uniprot": "A0A044VIJ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VIJ4"
  },
  {
    "uniprot": "A0A044RYZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RYZ7"
  },
  {
    "uniprot": "A0A2K6VXE5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VXE5"
  },
  {
    "uniprot": "A0A044SIA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SIA9"
  },
  {
    "uniprot": "A0A044R0Y1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R0Y1"
  },
  {
    "uniprot": "A0A2K6VT81",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VT81"
  },
  {
    "uniprot": "A0A044RG24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RG24"
  },
  {
    "uniprot": "A0A044RLQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RLQ9"
  },
  {
    "uniprot": "A0A044QZR5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZR5"
  },
  {
    "uniprot": "A0A044U496",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U496"
  },
  {
    "uniprot": "A0A044SPM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SPM6"
  },
  {
    "uniprot": "A0A044U3L9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3L9"
  },
  {
    "uniprot": "A0A044QKW2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QKW2"
  },
  {
    "uniprot": "A0A044U6X4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U6X4"
  },
  {
    "uniprot": "A0A044UZ39",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ39"
  },
  {
    "uniprot": "A0A044STT0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044STT0"
  },
  {
    "uniprot": "A0A044UYR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYR8"
  },
  {
    "uniprot": "A0A044VHD7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VHD7"
  },
  {
    "uniprot": "A0A044SGI7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGI7"
  },
  {
    "uniprot": "A0A044T1U3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T1U3"
  },
  {
    "uniprot": "A0A044VBX9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VBX9"
  },
  {
    "uniprot": "A0A2K6VU85",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VU85"
  },
  {
    "uniprot": "A0A044S7U1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S7U1"
  },
  {
    "uniprot": "A0A044U2E3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2E3"
  },
  {
    "uniprot": "A0A044UMF0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UMF0"
  },
  {
    "uniprot": "A0A044S513",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S513"
  },
  {
    "uniprot": "A0A044RIB7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIB7"
  },
  {
    "uniprot": "A0A044SE63",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SE63"
  },
  {
    "uniprot": "A0A044SJM6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJM6"
  },
  {
    "uniprot": "A0A044SRX8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SRX8"
  },
  {
    "uniprot": "A0A044V525",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V525"
  },
  {
    "uniprot": "A0A044TLG6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TLG6"
  },
  {
    "uniprot": "A0A044SWC4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SWC4"
  },
  {
    "uniprot": "A0A044R2T3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R2T3"
  },
  {
    "uniprot": "A0A044TB18",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TB18"
  },
  {
    "uniprot": "A0A044RF01",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RF01"
  },
  {
    "uniprot": "A0A044VGZ7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VGZ7"
  },
  {
    "uniprot": "A0A044U3M4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U3M4"
  },
  {
    "uniprot": "A0A044SJE0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SJE0"
  },
  {
    "uniprot": "A0A2K6VYG0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VYG0"
  },
  {
    "uniprot": "A0A044T8P7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T8P7"
  },
  {
    "uniprot": "A0A044U870",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U870"
  },
  {
    "uniprot": "A0A044U5E0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U5E0"
  },
  {
    "uniprot": "A0A044T5H8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T5H8"
  },
  {
    "uniprot": "A0A044VE31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VE31"
  },
  {
    "uniprot": "A0A044QXR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXR8"
  },
  {
    "uniprot": "A0A044QS31",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QS31"
  },
  {
    "uniprot": "A0A158N832",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N832"
  },
  {
    "uniprot": "A0A044S9J9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9J9"
  },
  {
    "uniprot": "A0A044TME8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TME8"
  },
  {
    "uniprot": "A0A2K6W8P9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W8P9"
  },
  {
    "uniprot": "A0A044RB61",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RB61"
  },
  {
    "uniprot": "A0A044V995",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V995"
  },
  {
    "uniprot": "A0A044RHR8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RHR8"
  },
  {
    "uniprot": "A0A2K6VK19",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VK19"
  },
  {
    "uniprot": "A0A044SGU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SGU5"
  },
  {
    "uniprot": "A0A044VG75",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VG75"
  },
  {
    "uniprot": "A0A044RAJ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RAJ1"
  },
  {
    "uniprot": "A0A2K6VZB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZB9"
  },
  {
    "uniprot": "A0A044U542",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U542"
  },
  {
    "uniprot": "A0A044T6C9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T6C9"
  },
  {
    "uniprot": "A0A2K6VP08",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VP08"
  },
  {
    "uniprot": "A0A044VDK5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VDK5"
  },
  {
    "uniprot": "A0A044RPL8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RPL8"
  },
  {
    "uniprot": "A0A044R8P4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R8P4"
  },
  {
    "uniprot": "A0A044UB49",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UB49"
  },
  {
    "uniprot": "A0A044S6T7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S6T7"
  },
  {
    "uniprot": "A0A044THV8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044THV8"
  },
  {
    "uniprot": "A0A2K6VHA3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VHA3"
  },
  {
    "uniprot": "A0A044V718",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V718"
  },
  {
    "uniprot": "A0A044V997",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V997"
  },
  {
    "uniprot": "A0A044RUB9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUB9"
  },
  {
    "uniprot": "A0A044UPW5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UPW5"
  },
  {
    "uniprot": "A0A044SNE7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SNE7"
  },
  {
    "uniprot": "A0A044UUQ9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUQ9"
  },
  {
    "uniprot": "A0A044UUK8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UUK8"
  },
  {
    "uniprot": "A0A044SDH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDH7"
  },
  {
    "uniprot": "A0A044T4V3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T4V3"
  },
  {
    "uniprot": "A0A044URJ8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044URJ8"
  },
  {
    "uniprot": "A0A044QXS2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QXS2"
  },
  {
    "uniprot": "A0A044UD40",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UD40"
  },
  {
    "uniprot": "A0A044RUP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RUP0"
  },
  {
    "uniprot": "A0A044S1B9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S1B9"
  },
  {
    "uniprot": "A0A044TYQ1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TYQ1"
  },
  {
    "uniprot": "A0A044UL30",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UL30"
  },
  {
    "uniprot": "A0A044SVQ4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SVQ4"
  },
  {
    "uniprot": "A0A044SDA9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SDA9"
  },
  {
    "uniprot": "A0A044U166",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U166"
  },
  {
    "uniprot": "A0A044SCC7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SCC7"
  },
  {
    "uniprot": "A0A044UZ33",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZ33"
  },
  {
    "uniprot": "A0A044V0L6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V0L6"
  },
  {
    "uniprot": "A0A044QSZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSZ3"
  },
  {
    "uniprot": "A0A044UW17",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UW17"
  },
  {
    "uniprot": "A0A044SXI6",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SXI6"
  },
  {
    "uniprot": "A0A044U1M7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U1M7"
  },
  {
    "uniprot": "A0A044V493",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V493"
  },
  {
    "uniprot": "A0A044TJM8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJM8"
  },
  {
    "uniprot": "A0A044S9P9",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S9P9"
  },
  {
    "uniprot": "A0A044RX82",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RX82"
  },
  {
    "uniprot": "A0A044RC58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RC58"
  },
  {
    "uniprot": "A0A044UZH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UZH7"
  },
  {
    "uniprot": "A0A2K6VZ42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VZ42"
  },
  {
    "uniprot": "A0A044T3P5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044T3P5"
  },
  {
    "uniprot": "A0A044TWV5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TWV5"
  },
  {
    "uniprot": "A0A044TJF8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TJF8"
  },
  {
    "uniprot": "A0A044VFA4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VFA4"
  },
  {
    "uniprot": "A0A044U2W5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U2W5"
  },
  {
    "uniprot": "A0A044TNH7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TNH7"
  },
  {
    "uniprot": "A0A044UYL0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UYL0"
  },
  {
    "uniprot": "A0A044V6U1",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V6U1"
  },
  {
    "uniprot": "A0A044RIG3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIG3"
  },
  {
    "uniprot": "A0A044QSK4",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QSK4"
  },
  {
    "uniprot": "A0A044U885",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044U885"
  },
  {
    "uniprot": "A0A2K6W079",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6W079"
  },
  {
    "uniprot": "A0A2K6VE42",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6VE42"
  },
  {
    "uniprot": "A0A044RT58",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RT58"
  },
  {
    "uniprot": "A0A044V120",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V120"
  },
  {
    "uniprot": "A0A044SQU5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SQU5"
  },
  {
    "uniprot": "A0A044VC99",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044VC99"
  },
  {
    "uniprot": "A0A044V3Z2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044V3Z2"
  },
  {
    "uniprot": "A0A044RD00",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RD00"
  },
  {
    "uniprot": "A0A044SG24",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SG24"
  },
  {
    "uniprot": "A0A044QNP0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QNP0"
  },
  {
    "uniprot": "A0A044RZM2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RZM2"
  },
  {
    "uniprot": "A0A2K6WFG8",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A2K6WFG8"
  },
  {
    "uniprot": "A0A044QQ05",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QQ05"
  },
  {
    "uniprot": "A0A044UJG7",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UJG7"
  },
  {
    "uniprot": "A0A044S166",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S166"
  },
  {
    "uniprot": "A0A044S0Y5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0Y5"
  },
  {
    "uniprot": "A0A044QZB3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044QZB3"
  },
  {
    "uniprot": "A0A044UG88",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044UG88"
  },
  {
    "uniprot": "A0A044S0F5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044S0F5"
  },
  {
    "uniprot": "A0A158N8Q0",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A158N8Q0"
  },
  {
    "uniprot": "A0A044R9W5",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R9W5"
  },
  {
    "uniprot": "A0A044RIZ3",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044RIZ3"
  },
  {
    "uniprot": "A0A044SR87",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SR87"
  },
  {
    "uniprot": "A0A044R038",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044R038"
  },
  {
    "uniprot": "A0A044SS56",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044SS56"
  },
  {
    "uniprot": "A0A044TZY2",
    "species": "Onchocerca volvulus",
    "disease": "Onchocerciasis",
    "results": "/results/A0A044TZY2"
  }
]
  
export default pythonStyleRows;